/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const LogicNot: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M6.25 17.25V24.1682C6.25 25.1109 7.25388 25.7144 8.08644 25.2721L22.5663 17.5797C22.7913 17.4601 22.9595 17.2883 23.0702 17.0908C23.3357 18.1864 24.3228 19 25.5 19C26.6194 19 27.567 18.2643 27.8855 17.25H30C30.4142 17.25 30.75 16.9142 30.75 16.5C30.75 16.0858 30.4142 15.75 30 15.75H27.8855C27.567 14.7357 26.6194 14 25.5 14C24.3349 14 23.3559 14.797 23.0786 15.8756C22.9768 15.6885 22.8235 15.5229 22.618 15.401L8.13817 6.80354C7.30493 6.30881 6.25 6.90932 6.25 7.87836V15.75H2C1.58579 15.75 1.25 16.0858 1.25 16.5C1.25 16.9142 1.58579 17.25 2 17.25H6.25ZM7.75 8.31755L21.4698 16.4637L7.75 23.7523V8.31755ZM25.5 17.5C26.0523 17.5 26.5 17.0523 26.5 16.5C26.5 15.9477 26.0523 15.5 25.5 15.5C24.9477 15.5 24.5 15.9477 24.5 16.5C24.5 17.0523 24.9477 17.5 25.5 17.5Z"
      fill={props.fill}
    />
  </svg>
);

LogicNot.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default LogicNot;
