import { t } from '@lingui/macro';
import { FORBIDDEN_NAMES } from 'app/transformers/uniqueNameGenerators';
import { isValidBranchName } from 'util/gitUtils';

// Use a lenient email regex to make sure we don't reject valid emails.
const emailRegex = /.+@.+[.].+/;
export interface ValidationRule {
  predicate: (value: string) => boolean;
  message: string;
}

/**
 * Checks a value against a set of validation rules (which are evaluated in order)
 * and returns an error message if the value is not valid.
 * @param value
 * @param validationRules
 * @returns a validation error message (if invalid), otherwise empty string (if valid)
 */
export function checkIsValid(
  value: string,
  validationRules?: ValidationRule[],
): string {
  if (validationRules) {
    for (let i = 0; i < validationRules.length; i++) {
      const validationRule = validationRules[i];
      const passesRule = validationRule.predicate(value);
      if (!passesRule) {
        return validationRule.message;
      }
    }
  }
  return '';
}

/// Section: Standard Rules

export const isRequiredRule: ValidationRule = {
  predicate: (value: string) => {
    if (!value) {
      return false;
    }

    return value.trim() !== '';
  },
  message: t({
    id: 'input.validationMessage.required',
    message: 'Required',
  }),
};

export const isUpTo64CharactersRule: ValidationRule = {
  predicate: (value: string) => {
    if (!value) {
      return true;
    }

    return value.length <= 64;
  },
  message: t({
    id: 'input.validationMessage.isUpTo64Characters',
    message: 'Cannot exceed 64 characters',
  }),
};

export const isUpTo256CharactersRule: ValidationRule = {
  predicate: (value: string) => {
    if (!value) {
      return true;
    }

    return value.length <= 256;
  },
  message: t({
    id: 'input.validationMessage.isUpTo256Characters',
    message: 'Cannot exceed 256 characters',
  }),
};

export const isEmailRule: ValidationRule = {
  predicate: (value: string) => {
    if (!value) {
      return true;
    }

    return emailRegex.test(value);
  },
  message: t({
    id: 'input.validationMessage.isEmail',
    message: 'Must be a valid email',
  }),
};

export const isNumberRule: ValidationRule = {
  predicate: (value: string) => {
    if (!value) {
      return true;
    }

    try {
      const numberValue = Number(value);
      return !isNaN(numberValue);
    } catch (error) {
      return false;
    }
  },
  message: t({
    id: 'input.validationMessage.isNumber',
    message: 'Must be a number',
  }),
};

export const isNonNegativeNumberRule: ValidationRule = {
  predicate: (value: string) => {
    if (!value) {
      return true;
    }

    try {
      const numberValue = Number(value);
      if (isNaN(numberValue)) {
        return false;
      }
      return numberValue >= 0;
    } catch (error) {
      return false;
    }
  },
  message: t({
    id: 'input.validationMessage.isNonNegativeNumber',
    message: 'Must be a number greater than or equal to zero',
  }),
};

export const isPositiveNumberRule: ValidationRule = {
  predicate: (value: string) => {
    if (!value) {
      return true;
    }

    try {
      const numberValue = Number(value);
      if (isNaN(numberValue)) {
        return false;
      }
      return numberValue > 0;
    } catch (error) {
      return false;
    }
  },
  message: t({
    id: 'input.validationMessage.isPositiveNumber',
    message: 'Must be a number greater than zero',
  }),
};

export const isPositiveNumberOrBlankRule: ValidationRule = {
  predicate: (value: string) => {
    if (!value) {
      return true;
    }

    try {
      const numberValue = Number(value);
      if (isNaN(numberValue)) {
        return false;
      }
      return numberValue > 0;
    } catch (error) {
      return false;
    }
  },
  message: t({
    id: 'input.validationMessage.isPositiveNumberOrBlank',
    message: 'Must be a number greater than zero or blank',
  }),
};

export const cannotStartWithDoubleUnderscoreRule: ValidationRule = {
  predicate: (value: string) =>
    !(value.substring(0, 2) === '__' && value.substring(0, 3) !== '___'),
  message: t({
    id: 'input.validationMessage.cannotStartWithDoubleUnderscore',
    message: 'Cannot start with a double underscore',
  }),
};
export function isDuplicated<T>(
  values: T[],
  resolver: (a: T) => string,
): ValidationRule {
  return {
    predicate: (value: string) => !values.some((v) => resolver(v) === value),
    message: t({
      id: 'input.validationMessage.nameMustBeUnique',
      message: 'Name must be unique',
    }),
  };
}

export const isNotForbiddenNameRule: ValidationRule = {
  predicate: (value: string) => {
    if (!value) {
      return true;
    }

    const lowerCaseNewName = value.toLowerCase();
    return !FORBIDDEN_NAMES.includes(lowerCaseNewName);
  },
  message: t({
    id: 'input.validationMessage.isNotAForbiddenName',
    message: 'Cannot be a forbidden name',
  }),
};

const startsWithALetterOrUnderscoreRegex = /^[a-zA-Z_]/;
export const startsWithALetterOrUnderscoreRule: ValidationRule = {
  predicate: (value: string) => {
    if (!value) {
      return true;
    }

    return startsWithALetterOrUnderscoreRegex.test(value);
  },
  message: t({
    id: 'input.validationMessage.startsWithALetterOrUnderscore',
    message: 'Must start with a letter or underscore',
  }),
};

const containsOnlyLettersNumbersAndUnderscoresRegex =
  /^[a-zA-Z_][a-zA-Z0-9_]*$/;
export const containsOnlyLettersNumbersAndUnderscoresRule: ValidationRule = {
  predicate: (value: string) => {
    if (!value) {
      return true;
    }

    return containsOnlyLettersNumbersAndUnderscoresRegex.test(value);
  },
  message: t({
    id: 'input.validationMessage.containsOnlyLettersNumbersAndUnderscores',
    message: 'Must contain only letters, numbers, and underscores',
  }),
};

export function isInRange(
  min?: number,
  max?: number,
  inclusive?: boolean,
): ValidationRule {
  return {
    predicate: (value: string) => {
      const numValue = Number(value);
      if (isNaN(numValue)) return false;
      if (min !== undefined && inclusive && numValue < min) return false;
      if (max !== undefined && inclusive && numValue > max) return false;
      if (min !== undefined && !inclusive && numValue <= min) return false;
      if (max !== undefined && !inclusive && numValue >= max) return false;
      return true;
    },
    message: t({
      id: 'input.validationMessage.valueNotInRange',
      message: 'Value must be within range',
    }),
  };
}

export function validBranchNameRule(
  existingBranches?: string[],
): ValidationRule {
  return {
    predicate: (value: string) => isValidBranchName(value, existingBranches),
    message: t({
      id: 'input.validationMessage.notValidBranchName',
      message: 'Invalid branch name',
    }),
  };
}

/// Section: Standard Rule Sets

export const requiredRules = [isRequiredRule];

export const dimensionParameterRules = [isPositiveNumberOrBlankRule];

export const validNameRules = [isRequiredRule, isUpTo256CharactersRule];
export const validNameRulesForOptionalField = [isUpTo256CharactersRule];

export const validIndentifierNotRequiredRules = [
  isUpTo64CharactersRule,
  isNotForbiddenNameRule,
  cannotStartWithDoubleUnderscoreRule,
  startsWithALetterOrUnderscoreRule,
  containsOnlyLettersNumbersAndUnderscoresRule,
];

export const validIdentifierRules = [
  isRequiredRule,
  ...validIndentifierNotRequiredRules,
];

export const emailRules = [isRequiredRule, isEmailRule];

export const numberRules = [isRequiredRule, isNumberRule];

export const nonNegativeNumberRules = [isRequiredRule, isNonNegativeNumberRule];

export const positiveNumberRules = [isRequiredRule, isPositiveNumberRule];

export function isPositiveNumberOrNullRuleSet(
  nullDisplayString: string,
): ValidationRule[] {
  return [
    {
      predicate: (value: string) => {
        if (!value) {
          return true;
        }

        if (value === nullDisplayString) {
          return true;
        }

        try {
          const numberValue = Number(value);
          if (isNaN(numberValue)) {
            return false;
          }
          return numberValue > 0;
        } catch (error) {
          return false;
        }
      },
      message: t({
        id: 'input.validationMessage.isPositiveNumberOrNull',
        message: `Must be "${nullDisplayString}" or a number greater than zero`,
      }),
    },
  ];
}

export function isDuplicatedRuleSet<T>(
  values: T[],
  resolver: (a: T) => string,
): ValidationRule[] {
  return [
    isRequiredRule,
    isUpTo256CharactersRule,
    isDuplicated(values, resolver),
  ];
}
