import {
  SignedIn as ClerkSignedIn,
  SignedOut as ClerkSignedOut,
  UserButton as ClerkUserButton,
  useUser as clerkUseUser,
} from '@clerk/clerk-react';
import { UserResource } from '@clerk/types';
import React, { PropsWithChildren } from 'react';

// Copied from clerk useUser.
type UseUserReturn =
  | {
      isLoaded: false;
      isSignedIn: undefined;
      user: undefined;
    }
  | {
      isLoaded: true;
      isSignedIn: false;
      user: null;
    }
  | {
      isLoaded: true;
      isSignedIn: true;
      user: UserResource;
    };

export const SignedIn: React.FC<PropsWithChildren<unknown>> = ({
  children,
}) => {
  if (process.env.REACT_APP_IS_OFFLINE === 'true') {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
  }
  return <ClerkSignedIn>{children}</ClerkSignedIn>;
};

export const SignedOut: React.FC<PropsWithChildren<unknown>> = ({
  children,
}) => {
  if (process.env.REACT_APP_IS_OFFLINE === 'true') {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
  }
  return <ClerkSignedOut>{children}</ClerkSignedOut>;
};

export const RedirectToSignIn: React.FC = () => {
  React.useEffect(() => {
    if (process.env.REACT_APP_IS_OFFLINE !== 'true') {
      // we want a hard-refresh here to ensure all app state is totally cleared out
      window.location.href = '/login/sign-in';
    }
  }, []);

  return null;
};

export const UserButton: React.FC = () => {
  if (process.env.REACT_APP_IS_OFFLINE === 'true') {
    return null;
  }
  return <ClerkUserButton />;
};

export function useUser(): UseUserReturn {
  if (process.env.REACT_APP_IS_OFFLINE === 'true') {
    return {
      isLoaded: false,
      isSignedIn: undefined,
      user: undefined,
    };
  }
  return clerkUseUser();
}

// This component will do a navigation and hard page refresh if the user just
// logged out. This ensures that the state of the app is consistent. Weirdly,
// previous attempts with components wrapped inside SignedOut did not yield
// good results (as in: children of SignedOut would not be rendered, thus had
// no chance to effect a reload).
export const RefreshIfSignedOut: React.FC = () => {
  const clerkUserId = useUser().user?.id;
  const [savedUserId, setSavedUserId] = React.useState<string | undefined>();

  React.useEffect(() => {
    if (process.env.REACT_APP_IS_OFFLINE === 'true') return;

    if (savedUserId !== clerkUserId && savedUserId && !clerkUserId) {
      // we want a hard-refresh here to ensure all app state is totally cleared out
      window.location.href = '/login/sign-in';
      window.location.reload();
    }
    setSavedUserId(clerkUserId);
  }, [savedUserId, clerkUserId]);

  return null;
};
