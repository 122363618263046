import styled from '@emotion/styled/macro';
import { Coordinate } from 'app/common_types/Coordinate';
import { StateLinkInstance } from 'app/generated_types/SimulationModel';
import { useAppDispatch } from 'app/hooks';
import { modelActions } from 'app/slices/modelSlice';
import { uiFlagsActions } from 'app/slices/uiFlagsSlice';
import React, { MutableRefObject } from 'react';
import { angleBetweenPoints } from 'util/angleBetweenPoints';
import { SMERefsObjTy, StateNodeSide } from './SMETypes';
import {
  frameLimitSMEMouseFn,
  getMouseWorldAndScreenCoords,
  SMEAllMouseEvent,
} from './StateMachineEdInputManager';

const arrowPositions = [0.25, 0.75, 1];

const MultiInputAnchor = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  pointer-events: none;

  > * {
    position: relative !important;
    left: auto !important;
    right: auto !important;
    top: auto !important;
    bottom: auto !important;
    pointer-events: auto;
    margin-top: 4px;
  }

  &:first-child {
    top: -34px;
  }

  top: 7px;
`;

const GuardActionAnchor = styled.div<{ x: number; y: number }>(({ x, y }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'absolute',
  left: x,
  top: y,
  width: 1,
  height: 1,
  pointerEvents: 'all',
}));

const GuardActionInputContainer = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  flex-shrink: 0;
  white-space: nowrap;
  top: 11px;
  height: 20px;
  padding: 3px 5px;
  background: lightgrey;

  &:first-child {
    top: -30px;
  }
`;

const StyledLinkUIInputElement = styled.input<{ width: number }>`
  ${({ width }) => `width: ${width}px;`}
  background: none;
  border: none;
  outline: none;

  &:focus {
    background: #ffffff;.
    box-shadow: 0px 0px 0px 1px ${({ theme }) =>
      theme.colors.brand.primary.lighter};
  }
`;

const LinkUIInput = (props: any) => {
  const inputElRef = React.useRef<HTMLInputElement | null>(null);
  const { autoFocus } = props;

  React.useEffect(() => {
    if (autoFocus && inputElRef.current) {
      inputElRef.current.focus();
    }
  }, [autoFocus]);

  return <StyledLinkUIInputElement {...props} ref={inputElRef} />;
};

const HiddenWidthDetector = styled.span`
  position: absolute;
  visibility: hidden;
  pointer-events: none;
  padding: 0 3px;
  white-space: pre;
`;

const GuardActionInput = ({
  startGlyph,
  endGlyph,
  onChange,
  placeholder,
  value,
  readOnly,
  autoFocus,
  addStateLinkAction,
  deleteStateLinkAction,
}: {
  startGlyph?: string;
  endGlyph?: string;
  onChange: (newValue: string) => void;
  placeholder: string;
  value?: string;
  readOnly?: boolean;
  autoFocus?: boolean;
  addStateLinkAction?: () => void;
  deleteStateLinkAction?: () => void;
}) => {
  const dispatch = useAppDispatch();

  const valueForWidth = value || placeholder;

  const spanWidthElRef = React.useRef<HTMLDivElement | null>(null);

  const [visWidth, setVisWidth] = React.useState(0);

  React.useEffect(() => {
    if (!spanWidthElRef.current) return;

    spanWidthElRef.current.innerText = value || '';
    setVisWidth(spanWidthElRef.current.offsetWidth);
  }, [spanWidthElRef.current]); // eslint-disable-line

  const internalOnChange = React.useCallback(
    (newVal: string) => {
      onChange(newVal);

      if (spanWidthElRef.current) {
        spanWidthElRef.current.innerText = newVal;
        setVisWidth(spanWidthElRef.current.offsetWidth);
      }
    },
    [onChange, spanWidthElRef],
  );

  // makes doubly sure our width doesn't get messed up
  React.useEffect(() => {
    if (spanWidthElRef.current) {
      spanWidthElRef.current.innerText = valueForWidth;
      setVisWidth(spanWidthElRef.current.offsetWidth);
    }
  }, [valueForWidth]);

  return (
    <GuardActionInputContainer
      onMouseDown={(e) => e.stopPropagation()}
      onClick={(e) => e.stopPropagation()}>
      <HiddenWidthDetector ref={spanWidthElRef}>
        {valueForWidth}
      </HiddenWidthDetector>
      {startGlyph}
      <LinkUIInput
        onFocus={() => {
          dispatch(uiFlagsActions.setUIFlag({ textInputFocused: true }));
        }}
        onBlur={() => {
          dispatch(uiFlagsActions.setUIFlag({ textInputFocused: false }));
        }}
        onClick={(e: React.MouseEvent) => e.stopPropagation()}
        onKeyDown={(e: React.KeyboardEvent) => {
          if (e.key === 'Enter' && addStateLinkAction && value) {
            addStateLinkAction();
          }

          if (e.key === 'Backspace' && deleteStateLinkAction && !value) {
            deleteStateLinkAction();
          }
        }}
        onChange={(e: any) => internalOnChange(e.target.value)}
        value={value}
        placeholder={placeholder}
        width={visWidth}
        disabled={readOnly}
        autoFocus={autoFocus}
      />
      {endGlyph}
    </GuardActionInputContainer>
  );
};

const bezier = (
  t: number,
  p0: Coordinate,
  p1: Coordinate,
  p2: Coordinate,
  p3: Coordinate,
) => {
  const cX = 3 * (p1.x - p0.x);
  const bX = 3 * (p2.x - p1.x) - cX;
  const aX = p3.x - p0.x - cX - bX;

  const cY = 3 * (p1.y - p0.y);
  const bY = 3 * (p2.y - p1.y) - cY;
  const aY = p3.y - p0.y - cY - bY;

  const x = aX * t ** 3 + bX * t ** 2 + cX * t + p0.x;
  const y = aY * t ** 3 + bY * t ** 2 + cY * t + p0.y;

  return { x, y };
};

type CommonProps = {
  link?: StateLinkInstance;
  startSide?: StateNodeSide;
  endSide?: StateNodeSide;
  start: Coordinate;
  end: Coordinate;
  stateMachineId: string;
  readOnly?: boolean;
  ignoreStartAnchor?: boolean;
  ignoreEndAnchor?: boolean;
  hideCurveDeviator?: boolean;
};

type Props = CommonProps & {
  onSelect?: (nodeId: string) => void;
  selected?: boolean;
  refsObj: MutableRefObject<SMERefsObjTy>;
  onDragStartPoint?: (linkId: string, connectedNodeId?: string) => void;
  onDragEndPoint?: (linkId?: string, connectedNodeId?: string) => void;
  disableMouse?: boolean;
};

const getAnchors = (
  start: Coordinate,
  end: Coordinate,
  deviation: Coordinate,
  startSide?: StateNodeSide,
  endSide?: StateNodeSide,
) => {
  const pointsDistance = Math.hypot(end.x - start.x, end.y - start.y);
  const halfDist = pointsDistance / 2;
  let startAnchor = start;
  let endAnchor = end;
  const correctedStart = {
    x: start.x + deviation.x,
    y: start.y + deviation.y,
  };
  const correctedEnd = {
    x: end.x + deviation.x,
    y: end.y + deviation.y,
  };

  switch (startSide) {
    case 'top':
      startAnchor = { x: correctedStart.x, y: correctedStart.y - halfDist };
      break;
    case 'right':
      startAnchor = { x: correctedStart.x + halfDist, y: correctedStart.y };
      break;
    case 'down':
      startAnchor = { x: correctedStart.x, y: correctedStart.y + halfDist };
      break;
    case 'left':
      startAnchor = { x: correctedStart.x - halfDist, y: correctedStart.y };
      break;
  }
  switch (endSide) {
    case 'top':
      endAnchor = { x: correctedEnd.x, y: correctedEnd.y - halfDist };
      break;
    case 'right':
      endAnchor = { x: correctedEnd.x + halfDist, y: correctedEnd.y };
      break;
    case 'down':
      endAnchor = { x: correctedEnd.x, y: correctedEnd.y + halfDist };
      break;
    case 'left':
      endAnchor = { x: correctedEnd.x - halfDist, y: correctedEnd.y };
      break;
  }

  return [startAnchor, endAnchor];
};

const isLinkAligned = (link: StateLinkInstance): boolean => {
  if (link.uiprops.sourceSide === link.uiprops.destSide) return false;

  if (
    ((link.uiprops.sourceSide == 'left' ||
      link.uiprops.sourceSide == 'right') &&
      (link.uiprops.destSide == 'left' || link.uiprops.destSide == 'right')) ||
    ((link.uiprops.sourceSide == 'top' || link.uiprops.sourceSide == 'down') &&
      (link.uiprops.destSide == 'top' || link.uiprops.destSide == 'down'))
  ) {
    return link.uiprops.sourceCoord === link.uiprops.destCoord;
  }

  return false;
};

const previousCurveDragCoord = {
  x: 0,
  y: 0,
};

export const StateMachineLinkUI = ({
  link,
  start,
  end,
  startSide,
  endSide,
  stateMachineId,
  onSelect,
  selected,
  readOnly,
  refsObj,
  onDragStartPoint,
  onDragEndPoint,
  ignoreStartAnchor,
  ignoreEndAnchor,
  hideCurveDeviator,
  disableMouse,
}: Props) => {
  const dispatch = useAppDispatch();

  let pathString = `M${start.x} ${start.y}`;

  let startAnchor = start;
  let endAnchor = end;

  const deviation = link?.uiprops.curveDeviation || { x: 0, y: 0 };

  if (deviation.x === 0 && deviation.y == 0 && link && isLinkAligned(link)) {
    pathString = `${pathString} L${end.x} ${end.y}`;
  } else {
    const pointsDistance = Math.hypot(end.x - start.x, end.y - start.y);
    const precision = Math.max(10, Math.floor(pointsDistance / 10));

    const [realStartAnchor, realEndAnchor] = getAnchors(
      start,
      end,
      deviation,
      startSide,
      endSide,
    );
    startAnchor = realStartAnchor;
    endAnchor = realEndAnchor;

    for (let i = 0; i <= precision; i++) {
      const bStep = bezier(i / precision, start, startAnchor, endAnchor, end);
      pathString += `L${bStep.x} ${bStep.y}`;
    }
  }

  const [hovering, setHovering] = React.useState<boolean>(false);
  const [dragging, setDragging] = React.useState<boolean>(false);

  const showAnchor = hovering || dragging;
  const highlighted = showAnchor || selected;

  const { x: halfX, y: halfY } = bezier(
    0.5,
    start,
    startAnchor,
    endAnchor,
    end,
  );

  const linkUuid = link?.uuid;

  const mouseDrag = React.useCallback(
    (e: SMEAllMouseEvent) =>
      frameLimitSMEMouseFn('dragCurveShape', (e: SMEAllMouseEvent) => {
        const { world: mouseWorldCoord } = getMouseWorldAndScreenCoords(
          e,
          refsObj,
        );

        const movement = {
          x: mouseWorldCoord.x - previousCurveDragCoord.x,
          y: mouseWorldCoord.y - previousCurveDragCoord.y,
        };
        previousCurveDragCoord.x = mouseWorldCoord.x;
        previousCurveDragCoord.y = mouseWorldCoord.y;

        if (!linkUuid || readOnly) return;

        dispatch(
          modelActions.moveStateLinkCurveDeviationByDelta({
            stateMachineUuid: stateMachineId,
            linkId: linkUuid,
            delta: {
              x: movement.x / refsObj.current.camera.zoom,
              y: movement.y / refsObj.current.camera.zoom,
            },
          }),
        );
      })(e),
    [dispatch, linkUuid, stateMachineId, readOnly, refsObj],
  );
  const mouseOver = React.useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      setHovering(true);
    },
    [setHovering],
  );
  const mouseOut = React.useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      setHovering(false);
    },
    [setHovering],
  );
  const anchorMouseUp = React.useCallback(
    (e: MouseEvent) => {
      e.stopPropagation();
      if (readOnly) return;
      setDragging(false);
      document.removeEventListener('mouseup', anchorMouseUp);
      document.removeEventListener('mousemove', mouseDrag);
    },
    [mouseDrag, setDragging, readOnly],
  );
  const anchorMouseDown = React.useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      if (readOnly) return;
      setDragging(true);
      document.addEventListener('mouseup', anchorMouseUp);
      document.addEventListener('mousemove', mouseDrag);
      const { world: mouseWorldCoord } = getMouseWorldAndScreenCoords(
        e,
        refsObj,
      );
      previousCurveDragCoord.x = mouseWorldCoord.x;
      previousCurveDragCoord.y = mouseWorldCoord.y;
    },
    [anchorMouseUp, mouseDrag, setDragging, readOnly, refsObj],
  );

  React.useEffect(
    () => () => {
      document.removeEventListener('mouseup', anchorMouseUp);
      document.removeEventListener('mousemove', mouseDrag);
    },
    [anchorMouseUp, mouseDrag],
  );

  let endAngle: number =
    {
      down: 270,
      left: 0,
      top: 90,
      right: 180,
      none: undefined,
    }[endSide || 'none'] || 0;

  if (!endSide) {
    const compareStep = bezier(0.95, start, startAnchor, endAnchor, end);
    endAngle = angleBetweenPoints(compareStep, end);
  }

  const handleLinkClick = React.useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      if (!link || !onSelect) return;
      onSelect(link.uuid);
    },
    [onSelect, link],
  );

  const linkColor = highlighted ? 'rgb(105, 225, 219)' : 'black';

  const startPointRef = React.useRef<SVGCircleElement>(null);
  const endPointRef = React.useRef<SVGCircleElement>(null);
  React.useEffect(() => {
    if (!startPointRef.current || !endPointRef.current) return;

    const dragStartPt = (e: MouseEvent) => {
      if (!startPointRef.current || !link) return;
      e.stopPropagation();
      e.preventDefault();
      startPointRef.current.removeEventListener('mousemove', dragStartPt);
      if (onDragStartPoint) {
        onDragStartPoint(link.uuid, link.destNodeId);
      }
    };
    const mousedownStartPt = (e: MouseEvent) => {
      if (!startPointRef.current) return;
      e.stopPropagation();
      e.preventDefault();
      startPointRef.current.addEventListener('mousemove', dragStartPt);
    };
    startPointRef.current.addEventListener('mousedown', mousedownStartPt);

    const dragEndPt = (e: MouseEvent) => {
      if (!endPointRef.current) return;
      e.stopPropagation();
      e.preventDefault();
      endPointRef.current.removeEventListener('mousemove', dragEndPt);
      if (onDragEndPoint) {
        onDragEndPoint(link?.uuid, link?.sourceNodeId);
      }
    };
    const mousedownEndPt = (e: MouseEvent) => {
      if (!endPointRef.current) return;
      e.stopPropagation();
      e.preventDefault();
      endPointRef.current.addEventListener('mousemove', dragEndPt);
    };
    endPointRef.current.addEventListener('mousedown', mousedownEndPt);

    const cleanupStartPointRef = startPointRef.current;
    const cleanupEndPointRef = endPointRef.current;

    return () => {
      if (cleanupStartPointRef) {
        cleanupStartPointRef.removeEventListener('mousedown', mousedownStartPt);
      }
      if (cleanupEndPointRef) {
        cleanupEndPointRef.removeEventListener('mousedown', mousedownEndPt);
      }
    };
  }, [link, onDragEndPoint, onDragStartPoint]);

  return (
    <g
      className={disableMouse ? 'nopointer' : ''}
      onMouseOver={mouseOver}
      onMouseOut={mouseOut}
      onMouseDown={(e) => e.stopPropagation()}
      onClick={(e) => e.stopPropagation()}>
      <path
        d={pathString}
        stroke="transparent"
        strokeWidth={10}
        fill="none"
        onMouseDown={(e) => e.stopPropagation()}
        onClick={handleLinkClick}
        className={disableMouse ? 'nopointer' : ''}
      />
      <path d={pathString} stroke={linkColor} strokeWidth={1} fill="none" />
      <circle cx={start.x} cy={start.y} r={3} fill={linkColor} />
      <circle
        cx={start.x}
        cy={start.y}
        r={10}
        fill="transparent"
        ref={startPointRef}
        className={disableMouse ? 'nopointer' : ''}
      />
      {!hideCurveDeviator && (
        <circle
          cx={halfX}
          cy={halfY}
          r={5}
          stroke={linkColor}
          strokeWidth={1}
          fill="lightgrey"
          onMouseDown={anchorMouseDown}
          style={showAnchor ? { display: 'block' } : { display: 'none' }}
        />
      )}
      <path
        d="M0 0 L7 7 L0 14"
        transform={`translate(${end.x} ${end.y}) rotate(${endAngle} 0 0) translate(-7 -7)`}
        stroke={linkColor}
        strokeWidth={2}
        fill="none"
      />
      <circle
        cx={end.x}
        cy={end.y}
        r={10}
        fill="transparent"
        ref={endPointRef}
        className={disableMouse ? 'nopointer' : ''}
      />
    </g>
  );
};

export const StateMachineEntryLinkInput = ({
  start,
  end,
  startSide,
  endSide,
  entryPointActions,
  stateMachineId,
  readOnly,
}: {
  start: Coordinate;
  end: Coordinate;
  startSide?: StateNodeSide;
  endSide?: StateNodeSide;
  entryPointActions: string[];
  stateMachineId: string;
  readOnly?: boolean;
}) => {
  const dispatch = useAppDispatch();

  const [startAnchor] = getAnchors(
    start,
    end,
    { x: 0, y: 0 },
    startSide,
    endSide,
  );
  let endAnchor = end;

  const { x: halfX, y: halfY } = bezier(
    0.5,
    start,
    startAnchor,
    endAnchor,
    end,
  );

  const getSetAction = React.useCallback(
    (index: number) => (newAction: string) => {
      if (readOnly) return;

      dispatch(
        modelActions.setStateMachineEntryPointAction({
          stateMachineUuid: stateMachineId,
          newAction,
          actionIndex: index,
        }),
      );
    },
    [dispatch, stateMachineId, readOnly],
  );

  const autoFocusActionIdxRef = React.useRef<number | undefined>();

  const addNewStateLinkAction = React.useCallback(() => {
    if (readOnly) return;

    dispatch(
      modelActions.addNewStateMachineEntryPointAction({
        stateMachineUuid: stateMachineId,
      }),
    );

    autoFocusActionIdxRef.current = entryPointActions?.length;
  }, [dispatch, stateMachineId, readOnly, entryPointActions]);

  const getDeleteStateLinkAction = React.useCallback(
    (index: number) => () => {
      if (readOnly) return;

      dispatch(
        modelActions.deleteStateMachineEntryPointAction({
          stateMachineUuid: stateMachineId,
          index,
        }),
      );

      autoFocusActionIdxRef.current = index - 1;
    },
    [dispatch, stateMachineId, readOnly],
  );

  return (
    <GuardActionAnchor x={halfX} y={halfY}>
      <MultiInputAnchor>
        {(entryPointActions.length > 0 ? entryPointActions : ['']).map(
          (ac, i) => (
            <GuardActionInput
              startGlyph="/"
              placeholder=" "
              onChange={getSetAction(i)}
              value={ac}
              readOnly={readOnly}
              autoFocus={autoFocusActionIdxRef.current === i}
              addStateLinkAction={addNewStateLinkAction}
              deleteStateLinkAction={getDeleteStateLinkAction(i)}
            />
          ),
        )}
      </MultiInputAnchor>
    </GuardActionAnchor>
  );
};

export const StateMachineLinkInputs = ({
  link,
  start,
  end,
  startSide,
  endSide,
  stateMachineId,
  readOnly,
}: CommonProps) => {
  const dispatch = useAppDispatch();

  let startAnchor = start;
  let endAnchor = end;
  const deviation = link?.uiprops.curveDeviation || { x: 0, y: 0 };

  if (
    deviation.x !== 0 ||
    deviation.y !== 0 ||
    (link && !isLinkAligned(link))
  ) {
    const [realStartAnchor, realEndAnchor] = getAnchors(
      start,
      end,
      deviation,
      startSide,
      endSide,
    );
    startAnchor = realStartAnchor;
    endAnchor = realEndAnchor;
  }

  const { x: halfX, y: halfY } = bezier(
    0.5,
    start,
    startAnchor,
    endAnchor,
    end,
  );

  const setGuard = React.useCallback(
    (newGuard: string) => {
      if (!link || readOnly) return;

      dispatch(
        modelActions.setStateLinkGuard({
          stateMachineUuid: stateMachineId,
          linkId: link.uuid,
          newGuard,
        }),
      );
    },
    [dispatch, link, stateMachineId, readOnly],
  );
  const getSetAction = React.useCallback(
    (index: number) => (newAction: string) => {
      if (!link || readOnly) return;

      dispatch(
        modelActions.setStateLinkAction({
          stateMachineUuid: stateMachineId,
          linkId: link.uuid,
          newAction,
          actionIndex: index,
        }),
      );
    },
    [dispatch, link, stateMachineId, readOnly],
  );

  const autoFocusActionIdxRef = React.useRef<number | undefined>();

  const addNewStateLinkAction = React.useCallback(() => {
    if (!link || readOnly) return;

    dispatch(
      modelActions.addNewStateLinkAction({
        stateMachineUuid: stateMachineId,
        linkId: link.uuid,
      }),
    );

    autoFocusActionIdxRef.current = link.actions?.length;
  }, [dispatch, link, stateMachineId, readOnly]);

  const getDeleteStateLinkAction = React.useCallback(
    (index: number) => () => {
      if (!link || readOnly) return;

      dispatch(
        modelActions.deleteStateLinkAction({
          stateMachineUuid: stateMachineId,
          linkId: link.uuid,
          index,
        }),
      );

      autoFocusActionIdxRef.current = index - 1;
    },
    [dispatch, link, stateMachineId, readOnly],
  );

  return (
    <GuardActionAnchor x={halfX} y={halfY}>
      <GuardActionInput
        startGlyph="["
        endGlyph="]"
        placeholder=" "
        onChange={setGuard}
        value={link?.guard}
        readOnly={readOnly}
      />
      <MultiInputAnchor>
        {(link?.actions?.length || 0) > 0 ? (
          (link?.actions || []).map((ac, i) => (
            <GuardActionInput
              startGlyph="/"
              placeholder=" "
              onChange={getSetAction(i)}
              value={ac}
              readOnly={readOnly}
              autoFocus={autoFocusActionIdxRef.current === i}
              addStateLinkAction={addNewStateLinkAction}
              deleteStateLinkAction={getDeleteStateLinkAction(i)}
            />
          ))
        ) : (
          <GuardActionInput
            startGlyph="/"
            placeholder=" "
            onChange={getSetAction(0)}
            readOnly={readOnly}
          />
        )}
      </MultiInputAnchor>
    </GuardActionAnchor>
  );
};
