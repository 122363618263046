// !!!! -- DO NOT MANUALLY EDIT -- !!!!
// This file was generated by something in scripts/generateDefaultBlockData.js
// If you want to change the contents of this file, it should be automated
//
// (You can edit this file locally, but it may not be checked into the codebase -
// PRs with a manual edit of this file are forbidden from being merged.)

import { computationBlockDefaults } from 'app/generated_blocks/computationBlockDefaults';
import type { ComputationBlockClass } from 'app/generated_types/ComputationBlockClass';
import deepmerge from 'deepmerge';

export const QuanserQubeServoModel: ComputationBlockClass = deepmerge(
  computationBlockDefaults,
  {
    "base": {
      "name": "QubeServoModel",
      "namespace": "quanser",
      "description": "Idealized model of the Qube Servo rotary pendulum.",
      "help_url": "https://docs.collimator.ai/using-model-editors/block-library/",
      "feature_level": "dev"
    },
    "modes": {
      "time": "agnostic"
    },
    "ports": {
      "inputs": {
        "static": [
          {
            "name": "u"
          }
        ]
      },
      "outputs": {
        "static": [
          {
            "name": "y"
          }
        ]
      }
    },
    "parameter_definitions": [
      {
        "name": "x0",
        "data_type": "float",
        "default_value": "[0.0, 0.0, 0.0, 0.0]",
        "description": "Initial state vector [theta, alpha, theta_dot, alpha_dot]"
      },
      {
        "name": "Rm",
        "data_type": "float",
        "default_value": "8.4",
        "description": "Motor resistance (Ohms)"
      },
      {
        "name": "km",
        "data_type": "float",
        "default_value": "0.042",
        "description": "Back-EMF constant (V-s/rad)"
      },
      {
        "name": "mr",
        "data_type": "float",
        "default_value": "0.095",
        "description": "Rotor arm mass (kg)"
      },
      {
        "name": "Lr",
        "data_type": "float",
        "default_value": "0.085",
        "description": "Rotor arm length (m)"
      },
      {
        "name": "br",
        "data_type": "float",
        "default_value": "0.0005",
        "description": "Damping constant for the rotor arm (N-m-s/rad)"
      },
      {
        "name": "mp",
        "data_type": "float",
        "default_value": "0.024",
        "description": "Mass of the pendulum arm (kg)"
      },
      {
        "name": "Lp",
        "data_type": "float",
        "default_value": "0.129",
        "description": "Length of the pendulum arm (m)"
      },
      {
        "name": "bp",
        "data_type": "float",
        "default_value": "0.000025",
        "description": "Damping constant for the pendulum arm (N-m-s/rad)"
      },
      {
        "name": "g",
        "data_type": "float",
        "default_value": "9.81",
        "description": "Gravity constant (m/s^2)"
      },
      {
        "name": "kr",
        "data_type": "float",
        "default_value": "0.0",
        "description": "Proportional gain modeling tendency to return to zero position"
      },
      {
        "name": "full_state_output",
        "data_type": "bool",
        "default_value": "false",
        "description": "Output the full state instead of only the angular positions"
      }
    ]
  }
);
