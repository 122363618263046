// !!!! -- DO NOT MANUALLY EDIT -- !!!!
// This file was generated by something in scripts/generateDefaultBlockData.js
// If you want to change the contents of this file, it should be automated
//
// (You can edit this file locally, but it may not be checked into the codebase -
// PRs with a manual edit of this file are forbidden from being merged.)

import { computationBlockDefaults } from 'app/generated_blocks/computationBlockDefaults';
import type { ComputationBlockClass } from 'app/generated_types/ComputationBlockClass';
import deepmerge from 'deepmerge';

export const AcausalElectricalIdealMotor: ComputationBlockClass = deepmerge(
  computationBlockDefaults,
  {
    "base": {
      "name": "IdealMotor",
      "namespace": "acausal.electrical",
      "description": "Electrical Motor with basic relationships."
    },
    "modes": {
      "time": "agnostic"
    },
    "ports": {
      "inputs": {
        "static": [
          {
            "name": "pos",
            "variant": {
              "variant_kind": "acausal",
              "acausal_domain": "electrical"
            }
          },
          {
            "name": "neg",
            "variant": {
              "variant_kind": "acausal",
              "acausal_domain": "electrical"
            }
          }
        ]
      },
      "outputs": {
        "static": [
          {
            "name": "shaft",
            "variant": {
              "variant_kind": "acausal",
              "acausal_domain": "rotational"
            }
          }
        ],
        "conditional": [
          {
            "name": "heat",
            "condition_bool_parameter": "enable_heat_port",
            "order": 1,
            "variant": {
              "variant_kind": "acausal",
              "acausal_domain": "thermal"
            }
          }
        ]
      }
    },
    "parameter_definitions": [
      {
        "name": "R",
        "data_type": "float",
        "default_value": "1.0",
        "description": "Series resistance of the rotor winding."
      },
      {
        "name": "K",
        "data_type": "float",
        "default_value": "1.0",
        "description": "Torque constant K in torque = K*rotor_current. Also Back EMF constant E in BackEMF = E*rotor_rotational_speed."
      },
      {
        "name": "L",
        "data_type": "float",
        "default_value": "1e-6",
        "description": "Series inductance of the rotor winding."
      },
      {
        "name": "J",
        "data_type": "float",
        "default_value": "0.1",
        "description": "Rotor inertia."
      },
      {
        "name": "initial_angle",
        "data_type": "float",
        "default_value": "0.0",
        "description": "Initial angular position."
      },
      {
        "name": "initial_angle_fixed",
        "data_type": "bool",
        "default_value": "False",
        "description": "Whether initial angle condition is fixed or not."
      },
      {
        "name": "initial_velocity",
        "data_type": "float",
        "default_value": "0.0",
        "description": "Initial angular velocity."
      },
      {
        "name": "initial_velocity_fixed",
        "data_type": "bool",
        "default_value": "False",
        "description": "Whether initial velocity condition is fixed or not."
      },
      {
        "name": "initial_current",
        "data_type": "float",
        "default_value": "0.0",
        "description": "Initial electrical current."
      },
      {
        "name": "initial_current_fixed",
        "data_type": "bool",
        "default_value": "False",
        "description": "Whether initial current condition is fixed or not."
      },
      {
        "name": "enable_heat_port",
        "data_type": "bool",
        "default_value": "false",
        "description": "User selection for enabling thermal heat port."
      }
    ]
  }
);
