// !!!! -- DO NOT MANUALLY EDIT -- !!!!
// This file was generated by something in scripts/generateDefaultBlockData.js
// If you want to change the contents of this file, it should be automated
//
// (You can edit this file locally, but it may not be checked into the codebase -
// PRs with a manual edit of this file are forbidden from being merged.)

import { computationBlockDefaults } from 'app/generated_blocks/computationBlockDefaults';
import type { ComputationBlockClass } from 'app/generated_types/ComputationBlockClass';
import deepmerge from 'deepmerge';

export const AcausalElectricalBattery: ComputationBlockClass = deepmerge(
  computationBlockDefaults,
  {
    "base": {
      "name": "Battery",
      "namespace": "acausal.electrical",
      "description": "Equivalent circuit battery cell model."
    },
    "modes": {
      "time": "agnostic"
    },
    "ports": {
      "inputs": {
        "static": [
          {
            "name": "p",
            "variant": {
              "variant_kind": "acausal",
              "acausal_domain": "electrical"
            }
          }
        ]
      },
      "outputs": {
        "static": [
          {
            "name": "n",
            "variant": {
              "variant_kind": "acausal",
              "acausal_domain": "electrical"
            }
          }
        ],
        "conditional": [
          {
            "name": "soc",
            "condition_bool_parameter": "enable_soc_port",
            "order": 1
          },
          {
            "name": "Up",
            "condition_bool_parameter": "enable_Up_port",
            "order": 2
          },
          {
            "name": "ocv",
            "condition_bool_parameter": "enable_ocv_port",
            "order": 3
          }
        ]
      }
    },
    "parameter_definitions": [
      {
        "name": "AH",
        "data_type": "float",
        "default_value": "1.0",
        "description": "Capacity in Amp-Hours."
      },
      {
        "name": "OCV_soc",
        "data_type": "any",
        "default_value": "[0.0, 1.0]",
        "description": "SOC breakpoints for the SOC-OCV curve."
      },
      {
        "name": "OCV_volts",
        "data_type": "any",
        "default_value": "[10.0, 15.0]",
        "description": "Open Circuit Voltage values for SOC-OCV curve."
      },
      {
        "name": "R",
        "data_type": "any",
        "default_value": "0.01",
        "description": "Internal resistance in Ohms. Can be scalar or array."
      },
      {
        "name": "Rp",
        "data_type": "any",
        "default_value": "1e-6",
        "description": "Polarization resistance in Ohms. Can be scalar or array."
      },
      {
        "name": "Cp",
        "data_type": "any",
        "default_value": "1e-6",
        "description": "Polarization capacitance in Farads. Can be scalar or array."
      },
      {
        "name": "SOC_v",
        "data_type": "any",
        "description": "SOC breakpoints for when R, Rp, Cp are lookup tables of SOC."
      },
      {
        "name": "initial_soc",
        "data_type": "float",
        "default_value": "0.5",
        "description": "Initial SOC."
      },
      {
        "name": "initial_soc_fixed",
        "data_type": "bool",
        "default_value": "False",
        "description": "Whether initial SOC condition is fixed or not."
      },
      {
        "name": "enable_soc_port",
        "data_type": "bool",
        "default_value": "false",
        "description": "User selection for enabling the SOC output"
      },
      {
        "name": "enable_Up_port",
        "data_type": "bool",
        "default_value": "false",
        "description": "User selection for enabling the polarization voltage output"
      },
      {
        "name": "enable_ocv_port",
        "data_type": "bool",
        "default_value": "false",
        "description": "User selection for enabling the OCV output"
      }
    ]
  }
);
