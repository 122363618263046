import { NodeInstance } from '@collimator/model-schemas-ts';
import { useAppSelector } from 'app/hooks';
import { findNodeInDiagram, getNestedNode } from 'app/utils/modelDiagramUtils';
import React from 'react';
import { shallowEqual } from 'react-redux';

// This hook uses shallowEqual to reduce unnecessary re-renders.

export const useSelectedNode = (
  parentPath: string[],
  nodeId?: string,
): {
  node: NodeInstance | undefined;
  isInCurrentDiagram: boolean;
} => {
  const nodeInCurrentDiagram = useAppSelector((state) => {
    if (!nodeId) return undefined;
    return findNodeInDiagram(
      state.model.present.rootModel.nodes,
      state.model.present.submodels,
      nodeId,
    );
  }, shallowEqual);

  const nodeNested = useAppSelector((state) => {
    if (nodeInCurrentDiagram || !nodeId) return undefined;
    return getNestedNode(
      state.model.present.rootModel.nodes,
      state.model.present.submodels,
      parentPath,
      nodeId,
    );
  }, shallowEqual);

  const foundNode = nodeInCurrentDiagram ?? nodeNested;
  const isInCurrentDiagram = !!nodeInCurrentDiagram;

  const [nodeMemo, setNodeMemo] = React.useState(foundNode);
  React.useEffect(() => {
    if (shallowEqual(foundNode, nodeMemo)) return;
    setNodeMemo(foundNode);
  }, [nodeMemo, foundNode]);

  return React.useMemo(
    () => ({
      node: nodeMemo,
      isInCurrentDiagram,
    }),
    [nodeMemo, isInCurrentDiagram],
  );
};
