/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Tile: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.5 5.25C7.91421 5.25 8.25 5.58579 8.25 6C8.25 6.41421 7.91421 6.75 7.5 6.75H5.25V25.25H7.5C7.91421 25.25 8.25 25.5858 8.25 26C8.25 26.4142 7.91421 26.75 7.5 26.75H5C4.30964 26.75 3.75 26.1904 3.75 25.5V6.5C3.75 5.80964 4.30964 5.25 5 5.25H7.5Z"
      fill={props.fill}
    />
    <path
      d="M24.5 5.25C24.0858 5.25 23.75 5.58579 23.75 6C23.75 6.41421 24.0858 6.75 24.5 6.75H26.75V25.25H24.5C24.0858 25.25 23.75 25.5858 23.75 26C23.75 26.4142 24.0858 26.75 24.5 26.75H27C27.6904 26.75 28.25 26.1904 28.25 25.5V6.5C28.25 5.80964 27.6904 5.25 27 5.25H24.5Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M13.288 15C13.2213 15 13.178 14.9667 13.158 14.9L12.858 13.92C12.8447 13.8933 12.828 13.88 12.808 13.88H10.348C10.328 13.88 10.3113 13.8933 10.298 13.92L10.008 14.9C9.988 14.9667 9.94467 15 9.878 15H8.608C8.568 15 8.538 14.99 8.518 14.97C8.498 14.9433 8.49467 14.9067 8.508 14.86L10.668 8.1C10.688 8.03333 10.7313 8 10.798 8H12.368C12.4347 8 12.478 8.03333 12.498 8.1L14.658 14.86C14.6647 14.8733 14.668 14.89 14.668 14.91C14.668 14.97 14.6313 15 14.558 15H13.288ZM10.658 12.73C10.6513 12.77 10.6647 12.79 10.698 12.79H12.458C12.498 12.79 12.5113 12.77 12.498 12.73L11.598 9.76C11.5913 9.73333 11.5813 9.72333 11.568 9.73C11.5547 9.73 11.5447 9.74 11.538 9.76L10.658 12.73Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M22.12 15C22.0533 15 22.01 14.9667 21.99 14.9L21.69 13.92C21.6767 13.8933 21.66 13.88 21.64 13.88H19.18C19.16 13.88 19.1433 13.8933 19.13 13.92L18.84 14.9C18.82 14.9667 18.7767 15 18.71 15H17.44C17.4 15 17.37 14.99 17.35 14.97C17.33 14.9433 17.3267 14.9067 17.34 14.86L19.5 8.1C19.52 8.03333 19.5633 8 19.63 8H21.2C21.2667 8 21.31 8.03333 21.33 8.1L23.49 14.86C23.4967 14.8733 23.5 14.89 23.5 14.91C23.5 14.97 23.4633 15 23.39 15H22.12ZM19.49 12.73C19.4833 12.77 19.4967 12.79 19.53 12.79H21.29C21.33 12.79 21.3433 12.77 21.33 12.73L20.43 9.76C20.4233 9.73333 20.4133 9.72333 20.4 9.73C20.3867 9.73 20.3767 9.74 20.37 9.76L19.49 12.73Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M13.288 24C13.2213 24 13.178 23.9667 13.158 23.9L12.858 22.92C12.8447 22.8933 12.828 22.88 12.808 22.88H10.348C10.328 22.88 10.3113 22.8933 10.298 22.92L10.008 23.9C9.988 23.9667 9.94467 24 9.878 24H8.608C8.568 24 8.538 23.99 8.518 23.97C8.498 23.9433 8.49467 23.9067 8.508 23.86L10.668 17.1C10.688 17.0333 10.7313 17 10.798 17H12.368C12.4347 17 12.478 17.0333 12.498 17.1L14.658 23.86C14.6647 23.8733 14.668 23.89 14.668 23.91C14.668 23.97 14.6313 24 14.558 24H13.288ZM10.658 21.73C10.6513 21.77 10.6647 21.79 10.698 21.79H12.458C12.498 21.79 12.5113 21.77 12.498 21.73L11.598 18.76C11.5913 18.7333 11.5813 18.7233 11.568 18.73C11.5547 18.73 11.5447 18.74 11.538 18.76L10.658 21.73Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M22.12 24C22.0533 24 22.01 23.9667 21.99 23.9L21.69 22.92C21.6767 22.8933 21.66 22.88 21.64 22.88H19.18C19.16 22.88 19.1433 22.8933 19.13 22.92L18.84 23.9C18.82 23.9667 18.7767 24 18.71 24H17.44C17.4 24 17.37 23.99 17.35 23.97C17.33 23.9433 17.3267 23.9067 17.34 23.86L19.5 17.1C19.52 17.0333 19.5633 17 19.63 17H21.2C21.2667 17 21.31 17.0333 21.33 17.1L23.49 23.86C23.4967 23.8733 23.5 23.89 23.5 23.91C23.5 23.97 23.4633 24 23.39 24H22.12ZM19.49 21.73C19.4833 21.77 19.4967 21.79 19.53 21.79H21.29C21.33 21.79 21.3433 21.77 21.33 21.73L20.43 18.76C20.4233 18.7333 20.4133 18.7233 20.4 18.73C20.3867 18.73 20.3767 18.74 20.37 18.76L19.49 21.73Z"
      fill={props.fill}
    />
  </svg>
);

Tile.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Tile;
