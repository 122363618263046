/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  cx?: any;
  cy?: any;
  r?: any;
  stroke?: any;
  strokeWidth?: any;
  strokeLinecap?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const AcausalHydraulicPressuresensor: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <circle
      cx={props.cx}
      cy={props.cy}
      r="11.25"
      stroke={props.stroke}
      strokeWidth={props.strokeWidth}
    />
    <path
      d="M16 5.5V7.5"
      stroke={props.stroke}
      strokeWidth={props.strokeWidth}
      strokeLinecap={props.strokeLinecap}
    />
    <path
      d="M25.0938 10.75L23.3617 11.75"
      stroke={props.stroke}
      strokeWidth={props.strokeWidth}
      strokeLinecap={props.strokeLinecap}
    />
    <path
      d="M6.90625 10.75L8.6383 11.75"
      stroke={props.stroke}
      strokeWidth={props.strokeWidth}
      strokeLinecap={props.strokeLinecap}
    />
    <path
      d="M10.75 6.90673L11.75 8.63879"
      stroke={props.stroke}
      strokeWidth={props.strokeWidth}
      strokeLinecap={props.strokeLinecap}
    />
    <path
      d="M21.25 6.90673L20.25 8.63879"
      stroke={props.stroke}
      strokeWidth={props.strokeWidth}
      strokeLinecap={props.strokeLinecap}
    />
    <circle cx={props.cx} cy={props.cy} r={props.r} stroke={props.stroke} />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M14.7278 7.8181C14.6273 7.41625 14.2201 7.17193 13.8183 7.2724C13.4164 7.37286 13.1721 7.78006 13.2726 8.1819L14.8229 14.3831C15.1532 14.1422 15.5601 14 16.0002 14C16.0945 14 16.1872 14.0065 16.278 14.0191L14.7278 7.8181Z"
      fill={props.fill}
    />
    <path
      d="M2 16L4 16"
      stroke={props.stroke}
      strokeWidth={props.strokeWidth}
      strokeLinecap={props.strokeLinecap}
    />
    <path
      d="M28 16L30 16"
      stroke={props.stroke}
      strokeWidth={props.strokeWidth}
      strokeLinecap={props.strokeLinecap}
    />
    <path
      d="M17.5 23.1071C17.5 23.5682 17.3429 23.8996 17.114 24.1176C16.8818 24.3388 16.5152 24.5 16 24.5C15.4848 24.5 15.1182 24.3388 14.886 24.1176C14.6571 23.8996 14.5 23.5682 14.5 23.1071C14.5 22.6766 14.727 22.1156 15.1079 21.5151C15.3933 21.0652 15.7263 20.6526 16 20.3414C16.2737 20.6526 16.6067 21.0652 16.8921 21.5151C17.273 22.1156 17.5 22.6766 17.5 23.1071Z"
      stroke={props.stroke}
      strokeWidth={props.strokeWidth}
    />
  </svg>
);

AcausalHydraulicPressuresensor.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  cx: '16',
  cy: '16',
  r: '1.5',
  stroke: '#1D3131',
  strokeWidth: '1.5',
  strokeLinecap: 'round',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default AcausalHydraulicPressuresensor;
