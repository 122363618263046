import styled from '@emotion/styled/macro';
import { useOutsideClick } from 'app/hooks';
import React from 'react';

import {
  TooltipPlacement,
  TooltipTriggerMode,
} from 'ui/common/Tooltip/tooltipTypes';
import { useTimer } from 'ui/common/timer/useTimer';
import TooltipPointer from './TooltipPointer';
import { getTooltipPosition } from './utils';

const BUTTON_TOOLTIP_OPEN_DELAY_IN_MS = 1000;

interface Props {
  children: React.ReactNode;
  content?: React.FC<{ closeTooltip: () => void }>;
  contentText?: string;
  placement?: TooltipPlacement;
  className?: string;
  startOpen?: boolean;
  triggerMode?: TooltipTriggerMode;
  showPointer?: boolean;
  invertedColor?: boolean;
  overlay?: boolean;
  noMargin?: boolean;
  onClose?: () => void;
  onActive?: (isActive: boolean) => void;
  onClickOutside?: (hide: () => void) => void;
}

const Wrapper = styled.section`
  position: relative;
`;
const Content = styled.div`
  background-color: ${(props) => props.theme.colors.grey[2]};
  border-radius: ${(props) => props.theme.spacing.small};
  box-shadow: ${(props) => props.theme.shadows.higher};
`;

const SimpleTextTooltip = styled.div<{
  invertedColor: boolean;
}>`
  padding: ${(props) => props.theme.spacing.normal};
  white-space: nowrap;
  border-radius: 2px;
  font-weight: 600;
  height: 32px;
  ${({ theme, invertedColor }) => {
    if (invertedColor) {
      return `
        background-color: ${theme.colors.grey[85]};
        color: ${theme.colors.grey[5]};
        `;
    }
    return `
      background-color: ${theme.colors.grey[2]};
      `;
  }}
`;

const Overlay = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #ffffff88;
`;
const TooltipWrapper = styled.div<{
  visible: boolean;
  top: number;
  left: number;
}>`
  position: absolute;
  ${({ visible, top, left }) => {
    if (visible) {
      return `
        opacity: 1;
        top: ${top}px;
        left: ${left}px;
        z-index: 1000;
      `;
    }
    return `
        opacity: 0;
        left: -10000px;
        z-index: -1000;
      `;
  }}
  transition: opacity 0.3s;
`;

const Tooltip: React.FC<Props> = ({
  children,
  content: C,
  contentText,
  placement = TooltipPlacement.TOP_RIGHT,
  className = '',
  startOpen = false,
  triggerMode = TooltipTriggerMode.HOVER,
  showPointer = true,
  invertedColor = false,
  overlay = false,
  noMargin = false,
  onClose,
  onActive,
  onClickOutside,
}) => {
  const triggerEl = React.useRef<HTMLDivElement>(null);
  const contentEl = React.useRef<HTMLDivElement>(null);
  const wrapperRef = React.useRef<HTMLDivElement>(null);
  const triggerRect = triggerEl?.current?.getBoundingClientRect();
  const contentRect = contentEl?.current?.getBoundingClientRect();
  const [visible, setVisible] = React.useState(!!startOpen);

  const { startTimer, stopTimer } = useTimer();

  const isButtonTooltip = !!contentText;
  const tooltipPos = getTooltipPosition(
    triggerRect,
    contentRect,
    placement,
    showPointer,
    isButtonTooltip,
    noMargin,
  );

  const cleanup = () => {
    setVisible(false);
    onActive?.(false);
  };

  const show = () => {
    stopTimer();
    if (!visible) {
      setVisible(true);
      onActive?.(true);
    }
  };
  const hide = () => {
    stopTimer();
    if (visible) {
      onClose?.();
      cleanup();
    }
  };

  useOutsideClick(
    wrapperRef,
    () => !startOpen && setTimeout(() => onClickOutside?.(cleanup), 1),
    visible,
  );

  const triggerShow = () => {
    if (isButtonTooltip) {
      // If the user mouses over a button tooltip quickly, it
      // should not flash, so set a delay before opening the tooltip.
      startTimer(show, BUTTON_TOOLTIP_OPEN_DELAY_IN_MS);
    } else {
      show();
    }
  };
  const triggerHide = () => {
    hide();
  };
  const toggle = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    stopTimer();
    setVisible(!visible);
    onActive?.(!visible);
    e.stopPropagation();
  };

  const renderTrigger = () => {
    const events: {
      ref: React.RefObject<HTMLDivElement>;
      onClick?: React.MouseEventHandler<HTMLDivElement>;
      onMouseEnter?: () => void;
      onMouseLeave?: () => void;
    } = { ref: triggerEl };
    switch (triggerMode) {
      case TooltipTriggerMode.HOVER:
        events.onMouseEnter = triggerShow;
        events.onMouseLeave = triggerHide;
        break;
      case TooltipTriggerMode.CLICK:
        events.onClick = toggle;
        break;
      default:
        break;
    }
    return <div {...events}>{children}</div>;
  };
  return (
    <>
      {overlay && visible ? <Overlay /> : null}
      <Wrapper className={className} ref={wrapperRef}>
        <TooltipWrapper
          visible={visible}
          top={tooltipPos.top}
          left={tooltipPos.left}>
          <Content ref={contentEl}>
            {/* TODO: Don't render this content until the tooltip is visible
            (or just switch all tooltips to use
            PortalTooltip.tsx which already solves this problem). */}
            {C ? (
              <C closeTooltip={triggerHide} />
            ) : (
              <SimpleTextTooltip invertedColor={invertedColor}>
                {contentText}
              </SimpleTextTooltip>
            )}
          </Content>
          {!!(showPointer && triggerRect && contentRect) && (
            <TooltipPointer
              invertedColor={invertedColor}
              placement={placement}
              triggerRect={triggerRect}
              contentRect={contentRect}
            />
          )}
        </TooltipWrapper>
        {renderTrigger()}
      </Wrapper>
    </>
  );
};

export default Tooltip;
