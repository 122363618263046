/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  className?: string;
}

const CodeDownload: React.FC<Props> = (props) => (
  <svg viewBox="0 0 24 24" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.9561 4.50602C14.3522 4.64242 14.5662 5.08472 14.4342 5.49393L10.9342 16.9939C10.8022 17.4031 10.3741 17.6243 9.97807 17.4879C9.58201 17.3515 9.36797 16.9092 9.49999 16.5L13 4.99998C13.132 4.59077 13.5601 4.36962 13.9561 4.50602Z"
      fill={props.fill}
    />
    <path
      d="M8.07322 7.30166C8.3237 6.95659 8.25602 6.46705 7.92204 6.20824C7.58806 5.94944 7.11425 6.01937 6.86377 6.36444L4.32487 10.4727C4.12576 10.7949 4.12576 11.202 4.32487 11.5242L6.86377 15.6325C7.11425 15.9775 7.58806 16.0475 7.92204 15.7887C8.25602 15.5299 8.3237 15.0403 8.07322 14.6952L5.88975 10.9985L8.07322 7.30166Z"
      fill={props.fill}
    />
    <path
      d="M16.0779 6.20824C15.744 6.46705 15.6763 6.95659 15.9268 7.30166L18.1102 10.9985L17.5347 11.9728C17.512 12.0113 17.5 12.0552 17.5 12.0999V14.9828C17.5 14.9923 17.5077 15.0001 17.5172 15.0001C17.5233 15.0002 17.5289 14.997 17.5321 14.9919L19.6751 11.5242C19.8742 11.202 19.8742 10.7949 19.6751 10.4727L17.1362 6.36444C16.8857 6.01937 16.4119 5.94944 16.0779 6.20824Z"
      fill={props.fill}
    />
    <path
      d="M16.25 11C16.25 10.5858 15.9142 10.25 15.5 10.25C15.0858 10.25 14.75 10.5858 14.75 11V17.1893L14.0303 16.4697C13.7374 16.1768 13.2626 16.1768 12.9697 16.4697C12.6768 16.7626 12.6768 17.2374 12.9697 17.5303L15.3232 19.8839C15.4209 19.9815 15.5791 19.9815 15.6768 19.8839L18.0303 17.5303C18.3232 17.2374 18.3232 16.7626 18.0303 16.4697C17.7374 16.1768 17.2626 16.1768 16.9697 16.4697L16.25 17.1893V11Z"
      fill={props.fill}
    />
  </svg>
);

CodeDownload.defaultProps = {
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  className: '',
};

export default CodeDownload;
