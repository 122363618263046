import { useTheme } from '@emotion/react';
import styled from '@emotion/styled/macro';
import React from 'react';
import Button from 'ui/common/Button/Button';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import { WatchInVisualizer } from 'ui/common/Icons/Standard';

export const ButtonBackground = styled.div`
  background: ${({ theme }) => theme.colors.grey[2]};
  position: sticky;
  right: 0;
`;

interface Props {
  path: string;
  isToggledOn: boolean;
  toggleVisualization: () => void;
  canEditProject: boolean;
}

export const RightPinnedVisualizerButton: React.FC<Props> = ({
  path,
  isToggledOn,
  toggleVisualization,
  canEditProject,
}) => {
  const theme = useTheme();

  return (
    <>
      {/* <ButtonSpaceHolder /> */}
      <ButtonBackground>
        <Button
          testId={`data-explorer-visualizer-button-${path}`}
          tint={isToggledOn ? theme.colors.brand.primary.base : undefined}
          onClick={toggleVisualization}
          variant={ButtonVariants.LargeTertiary}
          Icon={WatchInVisualizer}
        />
      </ButtonBackground>
    </>
  );
};
