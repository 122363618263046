/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  className?: string;
}

const Pause: React.FC<Props> = (props) => (
  <svg viewBox="0 0 24 24" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8 17.5C8 17.7761 8.22386 18 8.5 18H9.5C9.77614 18 10 17.7761 10 17.5V6.5C10 6.22386 9.77614 6 9.5 6H8.5C8.22386 6 8 6.22386 8 6.5V17.5Z"
      fill={props.fill}
    />
    <path
      d="M14 17.5C14 17.7761 14.2239 18 14.5 18H15.5C15.7761 18 16 17.7761 16 17.5V6.5C16 6.22386 15.7761 6 15.5 6H14.5C14.2239 6 14 6.22386 14 6.5V17.5Z"
      fill={props.fill}
    />
  </svg>
);

Pause.defaultProps = {
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  className: '',
};

export default Pause;
