// !!!! -- DO NOT MANUALLY EDIT -- !!!!
// This file was generated by something in scripts/generateDefaultBlockData.js
// If you want to change the contents of this file, it should be automated
//
// (You can edit this file locally, but it may not be checked into the codebase -
// PRs with a manual edit of this file are forbidden from being merged.)

import { computationBlockDefaults } from 'app/generated_blocks/computationBlockDefaults';
import type { ComputationBlockClass } from 'app/generated_types/ComputationBlockClass';
import deepmerge from 'deepmerge';

export const CustomLeafSystem: ComputationBlockClass = deepmerge(
  computationBlockDefaults,
  {
    "base": {
      "name": "CustomLeafSystem",
      "namespace": "core",
      "description": "Custom pycollimator LeafSystem.",
      "keywords": [
        "leaf",
        "system",
        "pycollimator",
        "custom"
      ],
      "extra_parameters": true,
      "help_url": "",
      "feature_level": "dev"
    },
    "modes": {
      "time": "any"
    },
    "ports": {
      "inputs": {
        "dynamic": {
          "min_count": 0,
          "max_count": -1,
          "default_count": 0
        }
      },
      "outputs": {
        "dynamic": {
          "min_count": 0,
          "max_count": -1,
          "default_count": 0
        }
      }
    },
    "parameter_definitions": [
      {
        "name": "file_path",
        "description": "Path to the custom LeafSystem class. Must be a path relative to the project root.",
        "data_type": "file",
        "file_formats": [
          "*.py"
        ],
        "default_value": ""
      },
      {
        "name": "source_code",
        "description": "Implementation of the custom LeafSystem.",
        "data_type": "string",
        "default_value": "from collimator import LeafSystem, parameters, ports\nfrom collimator.logging import logger\n\n\n@ports(inputs=[\"in_0\"], outputs=1)\n@parameters(dynamic=[\"dyn_param\"], static=[\"static_param\"])\nclass MySystem(LeafSystem):\n    \n    def __init__(self, dyn_param, static_param, *args, **kwargs):\n        super().__init__(*args, **kwargs)\n\n    def initialize(self, dyn_param, static_param):\n        \n        def _callback(time, state, u, dyn_param):\n            logger.info(\"hello world\")\n            return u\n\n        self.configure_output_port(\n            0,\n            _callback,\n            requires_inputs=True,\n        )",
        "hidden": true
      },
      {
        "name": "class_name",
        "description": "Name of the custom LeafSystem class, will be determined automatically during compilation if implementation is inlined.",
        "data_type": "string",
        "default_value": ""
      },
      {
        "name": "inline",
        "description": "Whether the implementation of the custom LeafSystem is inlined in the parameter source_code.",
        "data_type": "bool",
        "default_value": "false"
      }
    ]
  }
);
