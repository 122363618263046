import { Remove } from 'ui/common/Icons/Standard';
import SectionHeading from 'ui/common/Inputs/SectionHeading';
import SelectInput, { SelectInputOption } from 'ui/common/SelectInput';
import { OptimizerInputGroup, ParamSelectRow } from './optimizerModalUtils';

const ConstrainsSelector = ({
  signalOptions,
  constraints,
  onUpdate,
}: {
  signalOptions: Array<SelectInputOption>;
  constraints: Array<string | undefined>;
  onUpdate: (constraints: Array<string | undefined>) => void;
}) => {
  const addConstraint = () => onUpdate([...constraints, undefined]);

  const onSelectConstraint = (newVal: string, index: number) => {
    onUpdate(constraints.map((c, i) => (i === index ? newVal : c)));
  };

  const removeConstraint = (index: number) =>
    onUpdate(constraints.filter((_, i) => i !== index));

  return (
    <>
      <SectionHeading noBorder onButtonClick={addConstraint}>
        Constraints
      </SectionHeading>
      {constraints.map((c, i) => (
        <OptimizerInputGroup key={i}>
          <ParamSelectRow key={i}>
            <SelectInput
              placeholder="Choose a signal to constrain solution (not allowed to go negative)"
              options={signalOptions}
              onSelectValue={(val) => onSelectConstraint(val, i)}
              currentValue={c}
              isOptionDisabled={(option) =>
                constraints.includes(option.value) && option.value !== c
              }
            />
            <Remove onClick={() => removeConstraint(i)} />
          </ParamSelectRow>
        </OptimizerInputGroup>
      ))}
    </>
  );
};

export default ConstrainsSelector;
