/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  className?: string;
}

const Pause: React.FC<Props> = (props) => (
  <svg viewBox="0 0 16 16" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.33333 4H5.66667C5.29848 4 5 4.29848 5 4.66667V11.3333C5 11.7015 5.29848 12 5.66667 12H6.33333C6.70152 12 7 11.7015 7 11.3333V4.66667C7 4.29848 6.70152 4 6.33333 4Z"
      fill={props.fill}
    />
    <path
      d="M10.3333 4H9.66667C9.29848 4 9 4.29848 9 4.66667V11.3333C9 11.7015 9.29848 12 9.66667 12H10.3333C10.7015 12 11 11.7015 11 11.3333V4.66667C11 4.29848 10.7015 4 10.3333 4Z"
      fill={props.fill}
    />
  </svg>
);

Pause.defaultProps = {
  width: '16',
  height: '16',
  viewBox: '0 0 16 16',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  className: '',
};

export default Pause;
