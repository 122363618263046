import styled from '@emotion/styled';
import { t } from '@lingui/macro';
import { useGetSimulationArtifactsReadByUuidQuery } from 'app/apiGenerated/generatedApi';
import { GetSimulationArtifactsReadByUuidApiArg } from 'app/apiGenerated/generatedApiTypes';
import { FileOutput } from 'app/generated_types/SimulationModel';
import { useAppSelector } from 'app/hooks';
import React, { ReactElement } from 'react';
import { Message, Title } from 'ui/modelEditor/Visualizer/states/commonStyles';

const Wrapper = styled.div`
  overflow: auto;
  height: 100%;
  background-color: #ffffff;
`;

const Row = styled.div<{ columns: number }>`
  display: grid;
  grid-template-columns: repeat(${({ columns }) => columns}, minmax(0, 1fr));
  column-gap: ${({ theme }) => theme.spacing.small};
`;

const Visualizer = styled.div`
  font-family: monospace;
  font-size: 12px;
  line-height: 1.5;
  color: #000000;
  background-color: #ffffff; // TODO see if we can change this to a theme color
  overflow: scroll;
  padding: 1em;
  width: 100%;
  margin-bottom: 64px;
`;

const NoMediaState: React.FC = ({}) => (
  <Wrapper>
    <Message>
      <Title>
        {t({
          id: 'visualizer.noSignalSelected.title',
          message: 'No data to display.',
        })}
      </Title>
    </Message>
  </Wrapper>
);

function replaceIdentifiers(block_name: string, files: FileOutput[]): string[] {
  return files
    .map(
      (file) => file.filename?.replaceAll('{{block_name}}', block_name) || '',
    )
    .filter((filename) => filename !== '');
}

const MediaVisualizer = (): ReactElement => {
  const { simulationSummary } = useAppSelector((state) => state.project);

  const requestBody: GetSimulationArtifactsReadByUuidApiArg = {
    modelUuid: simulationSummary?.model_uuid || '',
    simulationUuid: simulationSummary?.uuid || '',
  };

  const { data: files } = useGetSimulationArtifactsReadByUuidQuery(
    requestBody,
    {
      skip: !simulationSummary || simulationSummary?.status !== 'completed',
    },
  );

  if (files?.s3_urls.length == 0 || !simulationSummary) {
    return <NoMediaState />;
  }

  return (
    <Wrapper>
      <Visualizer>
        {files?.s3_urls
          .filter((url) => !url?.url?.endsWith('.mp4'))
          .map((s3Url, i) => {
            if (!s3Url.url) {
              return (
                <Row key={i} columns={1}>
                  <div>{s3Url.error}</div>
                </Row>
              );
            }
            return (
              <Row key={i} columns={1}>
                <div>{s3Url.key?.split('/').reverse()[0]}</div>
                <video width="480" height="270" controls>
                  <track kind="captions" />
                  <source src={s3Url.url} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </Row>
            );
          })}
      </Visualizer>
    </Wrapper>
  );
};

export default MediaVisualizer;
