import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DiagramVersionFull } from 'app/apiTransformers/convertGetSnapshotReadByUuid';
import { ModelKind } from 'app/apiGenerated/generatedApiTypes';
import { VersionTagValues } from 'app/apiTransformers/convertPostSubmodelsFetch';
import {
  getActualVersionId,
  getReferenceSubmodelById,
} from 'app/utils/submodelUtils';
import { isSubmodelRefAvailable } from 'app/sliceRefAccess/SubmodelRef';
import { SubmodelFullUI } from 'app/apiTransformers/convertGetSubmodel';

export function getModelData(
  modelId: string,
  versionId: string,
  modelIdToVersionIdToModelData: Record<
    string,
    Record<string, DiagramVersionFull>
  >,
): DiagramVersionFull | undefined {
  // LATEST_VERSION and LATEST_TAGGED_VERSION will only work in the model editor
  // where we've already loaded the idToLatestTaggedVersionId
  // data that is needed to resolve the actual version id.
  const actualVersionId =
    (isSubmodelRefAvailable()
      ? getActualVersionId(modelId, versionId)
      : versionId) || VersionTagValues.LATEST_VERSION;

  const versionIdToModelData = modelIdToVersionIdToModelData[modelId];
  if (versionIdToModelData) {
    const modelData = versionIdToModelData[versionId];
    if (modelData) {
      return modelData;
    }
  }

  // Try to find the submodel in the submodel cache if it is not in the model version cache.
  if (isSubmodelRefAvailable()) {
    const submodel: SubmodelFullUI | undefined = getReferenceSubmodelById(
      modelId,
      actualVersionId,
    );
    if (submodel) {
      return {
        id: submodel.id,
        modelId: submodel.id,
        name: submodel.name,
        diagram: submodel.diagram,
        submodelsSection: submodel.submodels,
        editId: submodel.editId,
        kind: 'Submodel',
        updatedAt: submodel.updatedAt,
        submodel,
      };
    }
  }
}

function clearModelVersionRequestInternal(
  state: ModelVersionsState,
  request: ModelVersionRequestData,
) {
  const versionIdRequestData =
    state.modelIdToVersionIdRequestData[request.modelId];
  if (versionIdRequestData) {
    delete versionIdRequestData[request.versionId];
    if (
      Object.values(state.modelIdToVersionIdRequestData[request.modelId])
        .length === 0
    ) {
      delete state.modelIdToVersionIdRequestData[request.modelId];
    }
  }
}

export interface ModelVersionRequestData {
  modelId: string;
  versionId: string;
  kind: ModelKind;
}

interface ModelVersionsState {
  modelIdToVersionIdRequestData: Record<
    string,
    Record<string, ModelVersionRequestData>
  >;
  modelIdToVersionIdToModelData: Record<
    string,
    Record<string, DiagramVersionFull>
  >;
}

const initialState: ModelVersionsState = {
  modelIdToVersionIdRequestData: {},
  modelIdToVersionIdToModelData: {},
};

const modelVersionsSlice = createSlice({
  name: 'modelVersionsSlice',
  initialState,
  reducers: {
    requestModelVersion(
      state,
      action: PayloadAction<{
        modelId: string;
        versionId: string;
        kind: ModelKind;
      }>,
    ) {
      const { modelId, versionId, kind } = action.payload;

      // If the model version was already loaded, we don't need to request it.
      const modelData = getModelData(
        modelId,
        versionId,
        state.modelIdToVersionIdToModelData,
      );
      if (modelData) {
        return;
      }

      // Add the request for the model version that we haven't loaded yet.
      if (!state.modelIdToVersionIdRequestData[modelId]) {
        state.modelIdToVersionIdRequestData[modelId] = {};
      }
      state.modelIdToVersionIdRequestData[modelId][versionId] = {
        modelId,
        versionId,
        kind,
      };
    },

    onModelVersionLoaded(state, action: PayloadAction<DiagramVersionFull>) {
      const modelData = action.payload;

      // Add model (or submodel or experiment...) version to the cache.
      if (!state.modelIdToVersionIdToModelData[modelData.modelId]) {
        state.modelIdToVersionIdToModelData[modelData.modelId] = {};
      }
      state.modelIdToVersionIdToModelData[modelData.modelId][modelData.id] =
        modelData;

      // If this model version was requested, remove the request.
      clearModelVersionRequestInternal(state, {
        modelId: modelData.modelId,
        versionId: modelData.id,
        kind: modelData.kind,
      });
    },

    clearModelVersionRequest(
      state,
      action: PayloadAction<ModelVersionRequestData>,
    ) {
      const request = action.payload;

      // If this model version was requested, remove the request.
      clearModelVersionRequestInternal(state, request);
    },
  },
});

export const modelVersionsActions = modelVersionsSlice.actions;

export default modelVersionsSlice;
