import {
  GetSnapshotReadByUuidApiResponse,
  ModelConfiguration,
  SnapshotComplete,
  ParameterDefinition,
  SubmodelPortDefinition,
  ModelKind,
  SubmodelConfiguration,
} from 'app/apiGenerated/generatedApiTypes';
import {
  ModelDiagram,
  StateMachineDiagram,
  SubmodelsSection,
} from 'app/generated_types/SimulationModel';
import { ModelLevelParameters } from 'app/apiData';
import {
  SubmodelFullUI,
  convertAPISubmodelFullToSubmodelFullUI,
} from 'app/apiTransformers/convertGetSubmodel';

export interface DiagramVersionFull {
  id: string;
  modelId: string;
  name: string;
  diagram: ModelDiagram;
  submodelsSection: SubmodelsSection;
  stateMachines?: { [k: string]: StateMachineDiagram };
  modelParameters?: ModelLevelParameters;
  parameterDefinitions?: ParameterDefinition[];
  portDefinitionsInputs?: SubmodelPortDefinition[];
  portDefinitionsOutputs?: SubmodelPortDefinition[];
  submodelConfiguration?: SubmodelConfiguration;
  configuration?: ModelConfiguration;
  editId: string | number;
  kind: ModelKind;
  updatedAt: string;
  submodel?: SubmodelFullUI;
}

function convertAPISnapshotToDiagramVersionFull(
  apiSnapshot: SnapshotComplete,
): DiagramVersionFull {
  if (apiSnapshot.model) {
    return {
      id: apiSnapshot.summary.uuid,
      modelId: apiSnapshot.summary.model_uuid,
      name: apiSnapshot.summary.model_name || '',
      diagram: apiSnapshot.model.diagram as ModelDiagram,
      submodelsSection: apiSnapshot.model.submodels as SubmodelsSection,
      stateMachines: apiSnapshot.model.state_machines as {
        [k: string]: StateMachineDiagram;
      },
      modelParameters: apiSnapshot.model.parameters as ModelLevelParameters,
      configuration: apiSnapshot.model.configuration,
      editId: apiSnapshot.model.version,
      updatedAt: apiSnapshot.model.updated_at,
      kind: apiSnapshot.summary.model_kind,
    };
  }

  if (apiSnapshot.submodel) {
    const submodelFull = convertAPISubmodelFullToSubmodelFullUI(
      apiSnapshot.submodel,
    );

    // TODO remove this hack when the API returns the correct id.
    // Use the submodel id instead of version id for the id of the submodel
    submodelFull.id = apiSnapshot.summary.model_uuid;

    return {
      id: apiSnapshot.summary.uuid,
      modelId: apiSnapshot.summary.model_uuid,
      name: apiSnapshot.summary.model_name || '',
      diagram: apiSnapshot.submodel.diagram as ModelDiagram,
      submodelsSection: apiSnapshot.submodel.submodels as SubmodelsSection,
      stateMachines: apiSnapshot.submodel.state_machines as {
        [k: string]: StateMachineDiagram;
      },
      parameterDefinitions: apiSnapshot.submodel.parameter_definitions,
      portDefinitionsInputs: apiSnapshot.submodel.port_definitions.inputs,
      portDefinitionsOutputs: apiSnapshot.submodel.port_definitions.outputs,
      submodelConfiguration: apiSnapshot.submodel.configuration,
      editId: apiSnapshot.submodel.edit_id,
      updatedAt: apiSnapshot.submodel.updated_at || '',
      kind: apiSnapshot.summary.model_kind,
      submodel: submodelFull,
    };
  }

  console.error(
    `SnapshotComplete did not contain a model or submodel: ${JSON.stringify(
      apiSnapshot,
      null,
      2,
    )}`,
  );

  // This should not happen, but create an empty version full to satisfy the types.
  return {
    id: apiSnapshot.summary.uuid,
    modelId: apiSnapshot.summary.model_uuid,
    name: apiSnapshot.summary.model_name || '',
    diagram: {
      nodes: [],
      links: [],
    },
    submodelsSection: {
      diagrams: {},
      references: {},
    },
    editId: apiSnapshot.summary.model_edit_id || '',
    updatedAt: '',
    kind: apiSnapshot.summary.model_kind,
  };
}

export function convertGetSnapshotReadByUuid(
  apiResponse: GetSnapshotReadByUuidApiResponse,
): DiagramVersionFull {
  const diagramVersionFull =
    convertAPISnapshotToDiagramVersionFull(apiResponse);
  return diagramVersionFull;
}
