// !!!! -- DO NOT MANUALLY EDIT -- !!!!
// This file was generated by something in scripts/generateDefaultBlockData.js
// If you want to change the contents of this file, it should be automated
//
// (You can edit this file locally, but it may not be checked into the codebase -
// PRs with a manual edit of this file are forbidden from being merged.)

import { computationBlockDefaults } from 'app/generated_blocks/computationBlockDefaults';
import type { ComputationBlockClass } from 'app/generated_types/ComputationBlockClass';
import deepmerge from 'deepmerge';

export const AcausalHydraulicHydraulicProperties: ComputationBlockClass = deepmerge(
  computationBlockDefaults,
  {
    "base": {
      "name": "HydraulicProperties",
      "namespace": "acausal.hydraulic",
      "description": "Fluid properties block for hydraulic domain."
    },
    "modes": {
      "time": "agnostic"
    },
    "ports": {
      "outputs": {
        "static": [
          {
            "name": "port",
            "variant": {
              "variant_kind": "acausal",
              "acausal_domain": "hydraulic"
            }
          }
        ]
      }
    },
    "parameter_definitions": [
      {
        "name": "fluid_name",
        "description": "Select the fluid type.",
        "data_type": "string",
        "default_value": "water",
        "allowed_values": [
          "water",
          "hydraulicfluid"
        ]
      }
    ]
  }
);
