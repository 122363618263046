/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Saturate: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M21.376 6.58397C21.5151 6.37533 21.7492 6.25 22 6.25H30C30.4142 6.25 30.75 6.58579 30.75 7C30.75 7.41421 30.4142 7.75 30 7.75H22.4014L10.624 25.416C10.4849 25.6247 10.2508 25.75 10 25.75H2C1.58579 25.75 1.25 25.4142 1.25 25C1.25 24.5858 1.58579 24.25 2 24.25H9.59861L21.376 6.58397Z"
      fill={props.fill}
    />
  </svg>
);

Saturate.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Saturate;
