import {
  ModelDiagram,
  SimulationModel,
  transformBackendParameterDefinitions,
  transformBackendSimulationModel,
} from '@collimator/model-schemas-ts';
import { useModels } from 'app/api/useModels';
import { ModelLevelParameters } from 'app/apiData';
import { ModelUpdateRequest } from 'app/apiGenerated/generatedApiTypes';
import { runAutolayout } from 'app/autolayout';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { modelActions } from 'app/slices/modelSlice';
import {
  consumeAutoLayoutRaw,
  postAutoLayoutRaw,
} from 'app/utils/autolayoutUtils';
import React from 'react';
import { useNotifications } from 'ui/common/notifications/useNotifications';
import { rendererState } from 'ui/modelRendererInternals/modelRenderer';
import { scaleCameraToFitModel } from 'ui/modelRendererInternals/shortcutKeyConfig';
import { useChatContext } from './ChatContextProvider';
import { EditorMode, useModelEditorInfo } from './useModelEditorInfo';

export const cleanUpInvalidGenaiLinks = (diagram: ModelDiagram | undefined) => {
  if (!diagram) return;

  const existenceMap: { [portHash: string]: Array<string> } = {};

  diagram.links.filter((currentLink) => {
    if (!currentLink.src || !currentLink.dst) return;

    const srcSide = currentLink.src.port_side === 'inputs' ? 'i' : 'o';
    const srcHash = `${currentLink.src.node}_${srcSide}_${currentLink.src.port}`;

    const dstSide = currentLink.dst.port_side === 'outputs' ? 'o' : 'i';
    const dstHash = `${currentLink.dst.node}_${dstSide}_${currentLink.dst.port}`;

    if (srcHash === dstHash) {
      return false;
    }

    const srcHashConnections = existenceMap[srcHash] || [];
    const dstHashConnections = existenceMap[dstHash] || [];

    if (
      srcHashConnections.includes(dstHash) ||
      dstHashConnections.includes(srcHash)
    ) {
      return false;
    }

    if (!existenceMap[srcHash]) {
      existenceMap[srcHash] = [dstHash];
    } else {
      existenceMap[srcHash].push(dstHash);
    }

    return true;
  });
};

export const useUiModelUpdater = () => {
  const dispatch = useAppDispatch();
  const { showError, showInfo } = useNotifications();

  const { groupBlockUuid, isModelReadOnly } = useModelEditorInfo();
  const modelName = useAppSelector((state) => state.model.present.name);
  const configuration = useAppSelector(
    (state) => state.model.present.configuration,
  );

  const autoLayoutAll = React.useCallback(
    async (simulationModel: SimulationModel) => {
      // autolayout on main model
      if (simulationModel.diagram) {
        cleanUpInvalidGenaiLinks(simulationModel.diagram);

        const layoutPayload = await runAutolayout(
          simulationModel.diagram.nodes,
          simulationModel.diagram.links,
        );
        if (layoutPayload) {
          consumeAutoLayoutRaw(simulationModel.diagram, layoutPayload);
          postAutoLayoutRaw(simulationModel.diagram);
        }
      }
      // autolayout on groups
      if (simulationModel.submodels) {
        const diagrams = simulationModel.submodels.diagrams;
        const references = simulationModel.submodels.references;
        const promises = Object.keys(simulationModel.submodels.references).map(
          (groupBlockUuid) => {
            const groupUuid = references?.[groupBlockUuid]?.diagram_uuid;
            const diagram = groupUuid ? diagrams?.[groupUuid] : undefined;
            cleanUpInvalidGenaiLinks(diagram);

            if (diagram) {
              return runAutolayout(diagram.nodes, diagram.links).then(
                (layoutPayload) => {
                  if (layoutPayload) {
                    consumeAutoLayoutRaw(diagram, layoutPayload);
                    postAutoLayoutRaw(diagram);
                    dispatch(
                      modelActions.loadGroupContent({
                        diagram,
                        groupBlockUuid,
                      }),
                    );
                  }
                },
              );
            }
            return Promise.resolve();
          },
        );
        return Promise.all(promises);
      }
    },
    [dispatch],
  );

  const importSubmodelModelData = React.useCallback(
    async (modelData: any, autoLayout: boolean) => {
      const parameterDefinitions = transformBackendParameterDefinitions(
        modelData.parameters,
      );

      const rootModel = modelData.diagram || modelData.rootModel;
      if (!rootModel) return;

      const sm = transformBackendSimulationModel({
        uuid: modelData.uuid,
        name: modelName, // Do not change the model name
        diagram: rootModel,
        configuration, // Do not change the model configuration
        submodels: modelData.submodels || { diagrams: {}, references: {} },
      });

      if (autoLayout) {
        try {
          await autoLayoutAll(sm);
        } catch (e) {
          if (e instanceof Error) {
            console.error('Auto-layout failed:', e.message);
            console.error('Stack trace:', e.stack);
          } else {
            console.error('Auto-layout failed:', e);
          }
        }
      }

      dispatch(
        modelActions.loadSubmodelContent({
          ...sm,
          parameterDefinitions,
          preventSendingUpdateData: false,
        }),
      );
      return sm;
    },
    [modelName, configuration, dispatch, autoLayoutAll],
  );

  const importModelData = React.useCallback(
    async (modelData: any, autoLayout: boolean, isGroup: boolean) => {
      if (modelData.unprocessed) {
        modelData = modelData.unprocessed;
      }

      const rootModel = modelData.diagram || modelData.rootModel;
      if (!rootModel) return;

      const sm = transformBackendSimulationModel({
        uuid: rootModel.uuid,
        name: modelName, // Do not change the model name
        diagram: rootModel,
        configuration, // Do not change the model configuration
        submodels: modelData.submodels || { diagrams: {}, references: {} },
      });

      let parameters: ModelLevelParameters = modelData.parameters
        ? Array.isArray(modelData.parameters)
          ? modelData.parameters.reduce(
              (acc: ModelLevelParameters, curr: any) =>
                curr.name && curr.value
                  ? {
                      ...acc,
                      [curr.name]: { value: curr.value },
                    }
                  : acc,
              {},
            )
          : modelData.parameters
        : {};

      if (process.env.NODE_ENV === 'development') {
        // eslint-disable-next-line no-console
        console.debug(`loading model content:\n${sm.diagram}`);
      }

      if (autoLayout) {
        try {
          await autoLayoutAll(sm);
        } catch (e) {
          if (e instanceof Error) {
            console.error('Auto-layout failed:', e.message);
            console.error('Stack trace:', e.stack);
          } else {
            console.error('Auto-layout failed:', e);
          }
        }
      }

      if (isGroup) {
        dispatch(
          modelActions.loadGroupContent({
            diagram: sm.diagram,
            groupBlockUuid,
          }),
        );
      } else {
        dispatch(
          modelActions.loadModelContent({
            ...sm,
            parameters,
            preventSendingUpdateData: false,
          }),
        );
      }
      return sm;
    },
    [modelName, configuration, autoLayoutAll, dispatch, groupBlockUuid],
  );

  const { updateModelContent } = useModels();
  const loadedModelId = useAppSelector(
    (state) => state.modelMetadata.loadedModelId,
  );
  const editId = useAppSelector((state) => state.modelMetadata.editId);
  const { editIds: actualEditIds } = useChatContext();

  const updateUiModel = React.useCallback(
    async (jsonModel: string, editorMode: EditorMode, autoLayout?: boolean) => {
      if (isModelReadOnly) {
        showError('The editor is in read-only mode.');
        return;
      }
      try {
        const modelData = JSON.parse(jsonModel);
        let simulationModel: SimulationModel | undefined;
        if (editorMode === EditorMode.Group) {
          simulationModel = await importModelData(
            modelData,
            !!autoLayout,
            true,
          );
        } else if (editorMode === EditorMode.Submodel) {
          simulationModel = await importSubmodelModelData(
            modelData,
            !!autoLayout,
          );
        } else {
          simulationModel = await importModelData(
            modelData,
            !!autoLayout,
            false,
          );
        }

        if (rendererState) {
          scaleCameraToFitModel(rendererState);
        }

        // Update the DB with the new model content so that subsequent calls to
        // updateUiModel will work correctly without waiting for redux to update.
        if (simulationModel?.diagram) {
          const version = Math.max(
            actualEditIds.current.get(loadedModelId) || 0,
            Number(editId),
          );
          const updateRequest: ModelUpdateRequest = {
            version,
            diagram: simulationModel.diagram as any,
            submodels: simulationModel.submodels,
          };
          await updateModelContent(
            loadedModelId,
            updateRequest,
            false,
            false,
            true,
          );
        }

        showInfo('Model imported successfully.');
      } catch (e) {
        if (e instanceof Error) {
          console.error('Stack trace:', e.stack);
        }
        showError('Could not import model from the generated code.', e);
        throw e;
      }
    },
    [
      actualEditIds,
      editId,
      importModelData,
      importSubmodelModelData,
      isModelReadOnly,
      loadedModelId,
      showError,
      showInfo,
      updateModelContent,
    ],
  );

  return updateUiModel;
};
