/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Tune: React.FC<Props> = (props) => (
  <svg viewBox="0 0 24 24" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M18.5 7.5C19.6046 7.5 20.5 6.60457 20.5 5.5C20.5 4.39543 19.6046 3.5 18.5 3.5C17.3954 3.5 16.5 4.39543 16.5 5.5C16.5 5.96735 16.6603 6.39725 16.9289 6.73774L15.6827 8.91858C15.3268 8.36597 14.7062 8 14 8C12.8954 8 12 8.89543 12 10C12 10.3883 12.1107 10.7508 12.3022 11.0576L10.4368 10.498C9.68941 10.2738 8.88385 10.5715 8.46189 11.2279L5.37192 16.0345C5.25141 16.0119 5.12709 16 5 16C3.89543 16 3 16.8954 3 18C3 19.1046 3.89543 20 5 20C6.10457 20 7 19.1046 7 18C7 17.5701 6.86437 17.1719 6.63357 16.8458L9.72366 12.039C9.78394 11.9453 9.89902 11.9027 10.0058 11.9348L13.5232 12.99C14.3032 13.224 15.1415 12.8891 15.5455 12.182L18.2312 7.48209C18.3191 7.4939 18.4088 7.5 18.5 7.5ZM18.5 6C18.7761 6 19 5.77614 19 5.5C19 5.22386 18.7761 5 18.5 5C18.2239 5 18 5.22386 18 5.5C18 5.77614 18.2239 6 18.5 6ZM5 18.5C5.27614 18.5 5.5 18.2761 5.5 18C5.5 17.7239 5.27614 17.5 5 17.5C4.72386 17.5 4.5 17.7239 4.5 18C4.5 18.2761 4.72386 18.5 5 18.5ZM14.5 10C14.5 10.2761 14.2761 10.5 14 10.5C13.7239 10.5 13.5 10.2761 13.5 10C13.5 9.72386 13.7239 9.5 14 9.5C14.2761 9.5 14.5 9.72386 14.5 10Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M9.5 10C10.6046 10 11.5 9.10457 11.5 8C11.5 6.89543 10.6046 6 9.5 6C8.39543 6 7.5 6.89543 7.5 8C7.5 9.10457 8.39543 10 9.5 10ZM9.5 8.5C9.77614 8.5 10 8.27614 10 8C10 7.72386 9.77614 7.5 9.5 7.5C9.22386 7.5 9 7.72386 9 8C9 8.27614 9.22386 8.5 9.5 8.5Z"
      fill={props.fill}
    />
  </svg>
);

Tune.defaultProps = {
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Tune;
