// !!!! -- DO NOT MANUALLY EDIT -- !!!!
// This file was generated by something in scripts/generateDefaultBlockData.js
// If you want to change the contents of this file, it should be automated
//
// (You can edit this file locally, but it may not be checked into the codebase -
// PRs with a manual edit of this file are forbidden from being merged.)

import { computationBlockDefaults } from 'app/generated_blocks/computationBlockDefaults';
import type { ComputationBlockClass } from 'app/generated_types/ComputationBlockClass';
import deepmerge from 'deepmerge';

export const MuJoCo: ComputationBlockClass = deepmerge(
  computationBlockDefaults,
  {
    "base": {
      "name": "MuJoCo",
      "namespace": "core",
      "description": "Embed a MuJoCo robot model as a block.",
      "feature_level": "dev",
      "keywords": [
        "mujoco",
        "robot",
        "mjcf",
        "mjx"
      ]
    },
    "modes": {
      "time": "agnostic"
    },
    "ports": {
      "inputs": {
        "static": [
          {
            "name": "control"
          }
        ],
        "conditional": [
          {
            "name": "mocap_pos",
            "order": 1,
            "condition_bool_parameter": "enable_mocap_pos",
            "default_enabled": false
          }
        ]
      },
      "outputs": {
        "static": [
          {
            "name": "qpos"
          },
          {
            "name": "qvel"
          },
          {
            "name": "act"
          }
        ],
        "conditional": [
          {
            "name": "sensor_data",
            "order": 3,
            "condition_bool_parameter": "enable_sensor_data",
            "default_enabled": false
          },
          {
            "name": "video",
            "order": 4,
            "condition_bool_parameter": "enable_video_output",
            "default_enabled": false
          }
        ],
        "dynamic": {
          "default_count": 0,
          "min_count": 0,
          "max_count": -1,
          "parameter_definitions": [
            {
              "name": "script",
              "description": "Inline script to execute to compute this output",
              "data_type": "code",
              "default_value": "output_fn = lambda: 0",
              "display_full_width": true
            }
          ]
        }
      }
    },
    "parameter_definitions": [
      {
        "name": "use_mjx",
        "data_type": "bool",
        "default_value": "true",
        "description": "Whether to use the JAX (MJX) or C++ implementation"
      },
      {
        "name": "file_name",
        "display_name": "XML file",
        "description": "Select the root XML robot model to use, must be MJCF or URDF format",
        "data_type": "file",
        "file_formats": [
          "*.xml",
          "*.mjcf",
          "*.urdf"
        ],
        "default_value": ""
      },
      {
        "name": "dt",
        "display_name": "Δt",
        "description": "The time interval between each simulation step, only respected by the non-mjx variant. If not set, defaults to the global discrete interval",
        "data_type": "float",
        "default_value": "",
        "optional": true
      },
      {
        "name": "key_frame_0",
        "description": "The keyframe to initialize the model from",
        "data_type": "int",
        "optional": true,
        "default_value": ""
      },
      {
        "name": "qpos_0",
        "description": "The initial generalized position coordinates",
        "data_type": "any",
        "optional": true,
        "default_value": ""
      },
      {
        "name": "qvel_0",
        "description": "The initial generalized velocity coordinates",
        "data_type": "any",
        "optional": true,
        "default_value": ""
      },
      {
        "name": "act_0",
        "description": "TThe initial actuator coordinates",
        "data_type": "any",
        "optional": true,
        "default_value": ""
      },
      {
        "name": "enable_sensor_data",
        "description": "Whether to output the sensor data",
        "data_type": "bool",
        "default_value": "false"
      },
      {
        "name": "enable_mocap_pos",
        "description": "Enable the mocap_pos input port",
        "data_type": "bool",
        "default_value": "false"
      },
      {
        "name": "enable_video_output",
        "description": "Whether to output the video data",
        "data_type": "bool",
        "default_value": "false"
      },
      {
        "name": "video_size",
        "description": "The size of the video output, as a Python shape: (H,W)",
        "default_value": "(480, 640)",
        "data_type": "any",
        "optional": true
      }
    ]
  }
);
