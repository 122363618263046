import { useProjectOrRedirect } from 'app/behaviorHooks/useProjectOrRedirect';
import { useAppSelector } from 'app/hooks';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Spinner, SpinnerWrapper } from 'ui/common/Spinner';
import { useAppParams } from 'util/useAppParams';
import { DataExplorer } from './DataExplorer';

export const ValidateRouteDataExplorer: React.FC = () => {
  const { projectId, explorationId } = useAppParams();

  const { isLoadingProject: isProjectLoading, project } =
    useProjectOrRedirect();

  const navigate = useNavigate();

  const areUserOptionsLoaded = useAppSelector(
    (state) => state.userOptions.areUserOptionsLoaded,
  );

  const { dataExplorerEnabled } = useAppSelector(
    (state) => state.userOptions.options,
  );

  React.useEffect(() => {
    if (
      !projectId ||
      !explorationId ||
      (areUserOptionsLoaded && !dataExplorerEnabled)
    ) {
      if (!dataExplorerEnabled) {
        console.error('Data explorer not enabled.');
      }

      navigate('/');
    }
  }, [
    navigate,
    projectId,
    explorationId,
    areUserOptionsLoaded,
    dataExplorerEnabled,
  ]);

  if (isProjectLoading || !areUserOptionsLoaded) {
    return (
      <SpinnerWrapper>
        <Spinner />
      </SpinnerWrapper>
    );
  }

  if (
    !projectId ||
    !explorationId ||
    (areUserOptionsLoaded && !dataExplorerEnabled)
  ) {
    return null;
  }

  if (!project) {
    return null;
  }

  return <DataExplorer projectId={projectId} explorationId={explorationId} />;
};
