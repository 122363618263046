/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const ForwardArrow: React.FC<Props> = (props) => (
  <svg viewBox="0 0 24 24" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M13.7841 5.46967C13.4912 5.76256 13.4912 6.23744 13.7841 6.53033L16.3275 9.07375C11.3217 8.82069 8.28128 10.1078 6.76261 12.0359C5.04474 14.217 5.45782 17.0185 6.96966 18.5303C7.26256 18.8232 7.73743 18.8232 8.03032 18.5303C8.32322 18.2374 8.32322 17.7626 8.03032 17.4697C7.04216 16.4815 6.70525 14.533 7.94099 12.9641C9.06482 11.5372 11.6415 10.2588 16.6585 10.5953L13.7841 13.4697C13.4912 13.7626 13.4912 14.2374 13.7841 14.5303C14.077 14.8232 14.5519 14.8232 14.8448 14.5303L19.3751 10L14.8448 5.46967C14.5519 5.17678 14.077 5.17678 13.7841 5.46967Z"
      fill={props.fill}
    />
  </svg>
);

ForwardArrow.defaultProps = {
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default ForwardArrow;
