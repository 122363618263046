import { t } from '@lingui/macro';
import React from 'react';
import { Simulations } from 'ui/common/Icons/Standard';
import { NavbarDropdownButton } from './NavbarButtons';
import SimulationsMenu from './SimulationsMenu';

export const SimulationTools: React.FC = () => (
  <>
    {/* Running simulations dropdown button */}
    <NavbarDropdownButton
      tooltipText={t({
        id: 'navbar.simulations.simulationsManagerTooltip',
        message: 'Simulations',
      })}
      Icon={Simulations}
      DropdownContent={SimulationsMenu}
      isEnabled
      testId="navbar-simulations-dropdown-button"
    />
  </>
);
