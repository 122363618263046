/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Add: React.FC<Props> = (props) => (
  <svg viewBox="0 0 16 16" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M8 2.25C8.41421 2.25 8.75 2.58579 8.75 3V7.04167H13C13.4142 7.04167 13.75 7.37745 13.75 7.79167C13.75 8.20588 13.4142 8.54167 13 8.54167H8.75V13C8.75 13.4142 8.41421 13.75 8 13.75C7.58579 13.75 7.25 13.4142 7.25 13V8.54167H3C2.58579 8.54167 2.25 8.20588 2.25 7.79167C2.25 7.37745 2.58579 7.04167 3 7.04167H7.25V3C7.25 2.58579 7.58579 2.25 8 2.25Z"
      fill={props.fill}
    />
  </svg>
);

Add.defaultProps = {
  width: '16',
  height: '16',
  viewBox: '0 0 16 16',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Add;
