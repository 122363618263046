/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  className?: string;
}

const Transferfunctiondiscrete: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.002 9.296C9.002 9.176 9.062 9.116 9.182 9.116H10.55C10.67 9.116 10.73 9.176 10.73 9.296V21.536C10.73 21.656 10.67 21.716 10.55 21.716H9.182C9.062 21.716 9.002 21.656 9.002 21.536V16.19C9.002 16.142 8.978 16.118 8.93 16.118H3.8C3.752 16.118 3.728 16.142 3.728 16.19V21.536C3.728 21.656 3.668 21.716 3.548 21.716H2.18C2.06 21.716 2 21.656 2 21.536V9.296C2 9.176 2.06 9.116 2.18 9.116H3.548C3.668 9.116 3.728 9.176 3.728 9.296V14.552C3.728 14.6 3.752 14.624 3.8 14.624H8.93C8.978 14.624 9.002 14.6 9.002 14.552V9.296Z"
      fill={props.fill}
    />
    <path
      d="M15.3756 23.516C15.2916 23.516 15.2196 23.48 15.1596 23.408C14.5356 22.496 14.0316 21.38 13.6476 20.06C13.2636 18.74 13.0716 17.324 13.0716 15.812C13.0716 14.264 13.2636 12.824 13.6476 11.492C14.0436 10.148 14.5596 9.02 15.1956 8.108C15.2436 8.036 15.3156 8 15.4116 8H16.7076C16.7796 8 16.8276 8.024 16.8516 8.072C16.8756 8.108 16.8696 8.156 16.8336 8.216C16.3056 9.164 15.8676 10.298 15.5196 11.618C15.1836 12.926 15.0156 14.318 15.0156 15.794C15.0156 17.27 15.1836 18.656 15.5196 19.952C15.8676 21.248 16.3056 22.364 16.8336 23.3C16.8576 23.348 16.8696 23.384 16.8696 23.408C16.8696 23.48 16.8156 23.516 16.7076 23.516H15.3756Z"
      fill={props.fill}
    />
    <path
      d="M18.0838 21.716C17.9638 21.716 17.9038 21.656 17.9038 21.536V20.276C17.9038 20.192 17.9278 20.12 17.9758 20.06L22.7458 14.228C22.7698 14.204 22.7758 14.186 22.7638 14.174C22.7518 14.15 22.7338 14.138 22.7098 14.138H18.1738C18.0538 14.138 17.9938 14.078 17.9938 13.958V12.734C17.9938 12.614 18.0538 12.554 18.1738 12.554H24.6718C24.7918 12.554 24.8518 12.614 24.8518 12.734V14.012C24.8518 14.096 24.8278 14.168 24.7798 14.228L19.9378 20.06C19.9138 20.084 19.9078 20.108 19.9198 20.132C19.9318 20.144 19.9498 20.15 19.9738 20.15H24.6898C24.8098 20.15 24.8698 20.21 24.8698 20.33V21.536C24.8698 21.656 24.8098 21.716 24.6898 21.716H18.0838Z"
      fill={props.fill}
    />
    <path
      d="M27.4729 8C27.5569 8 27.6289 8.036 27.6889 8.108C28.3249 9.02 28.8349 10.136 29.2189 11.456C29.6029 12.776 29.7949 14.192 29.7949 15.704C29.7949 17.24 29.5969 18.68 29.2009 20.024C28.8169 21.356 28.3069 22.484 27.6709 23.408C27.6229 23.48 27.5509 23.516 27.4549 23.516H26.1409C26.0689 23.516 26.0209 23.492 25.9969 23.444C25.9729 23.408 25.9789 23.36 26.0149 23.3C26.5549 22.352 26.9929 21.224 27.3289 19.916C27.6769 18.596 27.8509 17.198 27.8509 15.722C27.8509 14.258 27.6769 12.878 27.3289 11.582C26.9929 10.274 26.5549 9.152 26.0149 8.216C25.9909 8.168 25.9789 8.132 25.9789 8.108C25.9789 8.036 26.0329 8 26.1409 8H27.4729Z"
      fill={props.fill}
    />
  </svg>
);

Transferfunctiondiscrete.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  className: '',
};

export default Transferfunctiondiscrete;
