import { t } from '@lingui/macro';
import React from 'react';
import Browser from 'ui/Browser/Browser';
import { CommandMenu } from 'ui/common/Icons/Standard';
import { NavbarDropdownButton, NavbarSpace } from './NavbarButtons';

const ProjectTools: React.FC = () => (
  <>
    {/* Project browser */}
    <NavbarDropdownButton
      tooltipText={t({
        id: 'navBar.projectBrowserTooltip',
        message: 'Project browser',
      })}
      Icon={CommandMenu}
      DropdownContent={Browser}
      isEnabled
      testId="navbar-project-browser-button"
    />

    <NavbarSpace />
  </>
);

export default ProjectTools;
