/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Vectorizer: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.5 2.75C17.5 2.05964 18.0596 1.5 18.75 1.5H21.25C21.6642 1.5 22 1.83579 22 2.25C22 2.66421 21.6642 3 21.25 3H19V29H21.25C21.6642 29 22 29.3358 22 29.75C22 30.1642 21.6642 30.5 21.25 30.5H18.75C18.0596 30.5 17.5 29.9404 17.5 29.25V2.75Z"
      fill={props.fill}
    />
    <path
      d="M28.75 1.5C29.4404 1.5 30 2.05964 30 2.75V29.25C30 29.9404 29.4404 30.5 28.75 30.5H26.25C25.8358 30.5 25.5 30.1642 25.5 29.75C25.5 29.3358 25.8358 29 26.25 29H28.5V3H26.25C25.8358 3 25.5 2.66421 25.5 2.25C25.5 1.83579 25.8358 1.5 26.25 1.5H28.75Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M6.18031 4.53C5.86031 4.38333 5.49031 4.31 5.07031 4.31C4.49031 4.31 4.00698 4.44 3.62031 4.7C3.24031 4.96 3.02365 5.29333 2.97031 5.7C2.96365 5.72666 2.97031 5.75 2.99031 5.77C3.01698 5.78333 3.04698 5.79333 3.08031 5.8L4.05031 5.92C4.12365 5.93333 4.17031 5.9 4.19031 5.82C4.23031 5.66 4.32698 5.53666 4.48031 5.45C4.64031 5.35666 4.84698 5.31 5.10031 5.31C5.40698 5.31 5.63698 5.38333 5.79031 5.53C5.94365 5.67 6.02031 5.87 6.02031 6.13V6.37C6.02031 6.40333 6.00365 6.42 5.97031 6.42H4.90031C4.24031 6.42 3.72698 6.56666 3.36031 6.86C3.00031 7.14666 2.82031 7.55 2.82031 8.07C2.82031 8.57 2.98031 8.94666 3.30031 9.2C3.62698 9.45333 4.04031 9.58 4.54031 9.58C5.16031 9.58 5.63365 9.4 5.96031 9.04C5.97365 9.02666 5.98698 9.02333 6.00031 9.03C6.01365 9.03 6.02031 9.04 6.02031 9.06V9.38C6.02031 9.41333 6.03031 9.44333 6.05031 9.47C6.07698 9.49 6.10698 9.5 6.14031 9.5H7.06031C7.09365 9.5 7.12031 9.49 7.14031 9.47C7.16698 9.44333 7.18031 9.41333 7.18031 9.38V6.01C7.18031 5.68333 7.09365 5.39333 6.92031 5.14C6.74698 4.88 6.50031 4.67666 6.18031 4.53ZM5.67031 8.4C5.43698 8.57333 5.15698 8.66 4.83031 8.66C4.58365 8.66 4.38031 8.60333 4.22031 8.49C4.06698 8.37 3.99031 8.20333 3.99031 7.99C3.99031 7.74333 4.09031 7.55333 4.29031 7.42C4.49698 7.28666 4.77698 7.22 5.13031 7.22H5.97031C6.00365 7.22 6.02031 7.23666 6.02031 7.27V7.74C6.02031 8.00666 5.90365 8.22666 5.67031 8.4Z"
      fill={props.fill}
    />
    <path
      d="M10.6396 11.9697C10.9325 11.6768 11.4074 11.6768 11.7003 11.9697L14.3467 14.6161C14.8349 15.1043 14.8349 15.8957 14.3467 16.3839L11.7003 19.0303C11.4074 19.3232 10.9325 19.3232 10.6396 19.0303C10.3467 18.7374 10.3467 18.2626 10.6396 17.9697L13.1093 15.5L10.6396 13.0303C10.3467 12.7374 10.3467 12.2626 10.6396 11.9697Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M23.75 4.31C24.17 4.31 24.54 4.38333 24.86 4.53C25.18 4.67666 25.4267 4.88 25.6 5.14C25.7733 5.39333 25.86 5.68333 25.86 6.01V9.38C25.86 9.41333 25.8467 9.44333 25.82 9.47C25.8 9.49 25.7733 9.5 25.74 9.5H24.82C24.7867 9.5 24.7567 9.49 24.73 9.47C24.71 9.44333 24.7 9.41333 24.7 9.38V9.06C24.7 9.04 24.6933 9.03 24.68 9.03C24.6667 9.02333 24.6533 9.02666 24.64 9.04C24.3133 9.4 23.84 9.58 23.22 9.58C22.72 9.58 22.3067 9.45333 21.98 9.2C21.66 8.94666 21.5 8.57 21.5 8.07C21.5 7.55 21.68 7.14666 22.04 6.86C22.4067 6.56666 22.92 6.42 23.58 6.42H24.65C24.6833 6.42 24.7 6.40333 24.7 6.37V6.13C24.7 5.87 24.6233 5.67 24.47 5.53C24.3167 5.38333 24.0867 5.31 23.78 5.31C23.5267 5.31 23.32 5.35666 23.16 5.45C23.0067 5.53666 22.91 5.66 22.87 5.82C22.85 5.9 22.8033 5.93333 22.73 5.92L21.76 5.8C21.7267 5.79333 21.6967 5.78333 21.67 5.77C21.65 5.75 21.6433 5.72666 21.65 5.7C21.7033 5.29333 21.92 4.96 22.3 4.7C22.6867 4.44 23.17 4.31 23.75 4.31ZM23.51 8.66C23.8367 8.66 24.1167 8.57333 24.35 8.4C24.5833 8.22666 24.7 8.00666 24.7 7.74V7.27C24.7 7.23666 24.6833 7.22 24.65 7.22H23.81C23.4567 7.22 23.1767 7.28666 22.97 7.42C22.77 7.55333 22.67 7.74333 22.67 7.99C22.67 8.20333 22.7467 8.37 22.9 8.49C23.06 8.60333 23.2633 8.66 23.51 8.66Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M7.59988 16.43C7.59988 15.99 7.54322 15.5833 7.42988 15.21C7.27655 14.77 7.03655 14.4267 6.70988 14.18C6.38988 13.9333 5.98322 13.81 5.48988 13.81C5.01655 13.81 4.63655 13.98 4.34988 14.32C4.33655 14.3333 4.32322 14.3367 4.30988 14.33C4.29655 14.3233 4.28988 14.31 4.28988 14.29V12.12C4.28988 12.0867 4.27655 12.06 4.24988 12.04C4.22988 12.0133 4.20322 12 4.16988 12H3.24988C3.21655 12 3.18655 12.0133 3.15988 12.04C3.13988 12.06 3.12988 12.0867 3.12988 12.12V18.88C3.12988 18.9133 3.13988 18.9433 3.15988 18.97C3.18655 18.99 3.21655 19 3.24988 19H4.16988C4.20322 19 4.22988 18.99 4.24988 18.97C4.27655 18.9433 4.28988 18.9133 4.28988 18.88V18.57C4.28988 18.55 4.29655 18.5367 4.30988 18.53C4.32322 18.5233 4.33655 18.53 4.34988 18.55C4.64988 18.9033 5.03322 19.08 5.49988 19.08C5.97322 19.08 6.37322 18.9567 6.69988 18.71C7.02655 18.4633 7.26322 18.12 7.40988 17.68C7.53655 17.32 7.59988 16.9033 7.59988 16.43ZM6.40988 16.45C6.40988 16.9367 6.32988 17.32 6.16988 17.6C6.08322 17.7533 5.96655 17.8733 5.81988 17.96C5.67322 18.04 5.50655 18.08 5.31988 18.08C4.97322 18.08 4.71322 17.92 4.53988 17.6C4.38655 17.34 4.30988 16.9533 4.30988 16.44C4.30988 15.92 4.39322 15.5233 4.55988 15.25C4.73322 14.9567 4.98322 14.81 5.30988 14.81C5.66322 14.81 5.92988 14.95 6.10988 15.23C6.30988 15.5233 6.40988 15.93 6.40988 16.45Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M26.1096 15.21C26.2229 15.5833 26.2796 15.99 26.2796 16.43C26.2796 16.9033 26.2162 17.32 26.0896 17.68C25.9429 18.12 25.7062 18.4633 25.3796 18.71C25.0529 18.9567 24.6529 19.08 24.1796 19.08C23.7129 19.08 23.3296 18.9033 23.0296 18.55C23.0162 18.53 23.0029 18.5233 22.9896 18.53C22.9762 18.5367 22.9696 18.55 22.9696 18.57V18.88C22.9696 18.9133 22.9562 18.9433 22.9296 18.97C22.9096 18.99 22.8829 19 22.8496 19H21.9296C21.8962 19 21.8662 18.99 21.8396 18.97C21.8196 18.9433 21.8096 18.9133 21.8096 18.88V12.12C21.8096 12.0867 21.8196 12.06 21.8396 12.04C21.8662 12.0133 21.8962 12 21.9296 12H22.8496C22.8829 12 22.9096 12.0133 22.9296 12.04C22.9562 12.06 22.9696 12.0867 22.9696 12.12V14.29C22.9696 14.31 22.9762 14.3233 22.9896 14.33C23.0029 14.3367 23.0162 14.3333 23.0296 14.32C23.3162 13.98 23.6962 13.81 24.1696 13.81C24.6629 13.81 25.0696 13.9333 25.3896 14.18C25.7162 14.4267 25.9562 14.77 26.1096 15.21ZM24.8496 17.6C25.0096 17.32 25.0896 16.9367 25.0896 16.45C25.0896 15.93 24.9896 15.5233 24.7896 15.23C24.6096 14.95 24.3429 14.81 23.9896 14.81C23.6629 14.81 23.4129 14.9567 23.2396 15.25C23.0729 15.5233 22.9896 15.92 22.9896 16.44C22.9896 16.9533 23.0662 17.34 23.2196 17.6C23.3929 17.92 23.6529 18.08 23.9996 18.08C24.1862 18.08 24.3529 18.04 24.4996 17.96C24.6462 17.8733 24.7629 17.7533 24.8496 17.6Z"
      fill={props.fill}
    />
    <path
      d="M3.92047 27.19C4.2938 27.45 4.73714 27.58 5.25047 27.58C5.77047 27.58 6.2238 27.4567 6.61047 27.21C6.99714 26.9567 7.2538 26.6167 7.38047 26.19C7.3938 26.1433 7.41047 26.0667 7.43047 25.96C7.43714 25.92 7.42714 25.89 7.40047 25.87C7.38047 25.8433 7.3538 25.8267 7.32047 25.82L6.42047 25.68C6.34714 25.6667 6.30047 25.7 6.28047 25.78C6.28047 25.7933 6.27714 25.8033 6.27047 25.81C6.27047 25.83 6.26714 25.8467 6.26047 25.86C6.20714 26.08 6.08714 26.2567 5.90047 26.39C5.72047 26.5167 5.5038 26.58 5.25047 26.58C4.99047 26.58 4.7738 26.5133 4.60047 26.38C4.42714 26.24 4.30714 26.0533 4.24047 25.82C4.18047 25.5933 4.15047 25.2967 4.15047 24.93C4.15047 24.55 4.1838 24.2533 4.25047 24.04C4.31047 23.8133 4.42714 23.6367 4.60047 23.51C4.78047 23.3767 4.99714 23.31 5.25047 23.31C5.49714 23.31 5.71047 23.37 5.89047 23.49C6.07047 23.61 6.19047 23.7633 6.25047 23.95C6.2638 23.99 6.27714 24.0367 6.29047 24.09C6.29714 24.11 6.30047 24.1367 6.30047 24.17C6.32047 24.2367 6.36047 24.27 6.42047 24.27H6.44047L7.34047 24.14C7.4138 24.1267 7.45047 24.0867 7.45047 24.02V24C7.43714 23.8933 7.4138 23.7867 7.38047 23.68C7.2538 23.2867 6.99714 22.96 6.61047 22.7C6.2238 22.44 5.77047 22.31 5.25047 22.31C4.73714 22.31 4.29047 22.44 3.91047 22.7C3.53714 22.9533 3.28047 23.3 3.14047 23.74C3.0338 24.0467 2.98047 24.44 2.98047 24.92C2.98047 25.42 3.0338 25.82 3.14047 26.12C3.28714 26.5667 3.54714 26.9233 3.92047 27.19Z"
      fill={props.fill}
    />
    <path
      d="M23.9302 27.58C23.4168 27.58 22.9735 27.45 22.6002 27.19C22.2268 26.9233 21.9668 26.5667 21.8202 26.12C21.7135 25.82 21.6602 25.42 21.6602 24.92C21.6602 24.44 21.7135 24.0467 21.8202 23.74C21.9602 23.3 22.2168 22.9533 22.5902 22.7C22.9702 22.44 23.4168 22.31 23.9302 22.31C24.4502 22.31 24.9035 22.44 25.2902 22.7C25.6768 22.96 25.9335 23.2867 26.0602 23.68C26.0935 23.7867 26.1168 23.8933 26.1302 24V24.02C26.1302 24.0867 26.0935 24.1267 26.0202 24.14L25.1202 24.27H25.1002C25.0402 24.27 25.0002 24.2367 24.9802 24.17C24.9802 24.1367 24.9768 24.11 24.9702 24.09C24.9568 24.0367 24.9435 23.99 24.9302 23.95C24.8702 23.7633 24.7502 23.61 24.5702 23.49C24.3902 23.37 24.1768 23.31 23.9302 23.31C23.6768 23.31 23.4602 23.3767 23.2802 23.51C23.1068 23.6367 22.9902 23.8133 22.9302 24.04C22.8635 24.2533 22.8302 24.55 22.8302 24.93C22.8302 25.2967 22.8602 25.5933 22.9202 25.82C22.9868 26.0533 23.1068 26.24 23.2802 26.38C23.4535 26.5133 23.6702 26.58 23.9302 26.58C24.1835 26.58 24.4002 26.5167 24.5802 26.39C24.7668 26.2567 24.8868 26.08 24.9402 25.86C24.9468 25.8467 24.9502 25.83 24.9502 25.81C24.9568 25.8033 24.9602 25.7933 24.9602 25.78C24.9802 25.7 25.0268 25.6667 25.1002 25.68L26.0002 25.82C26.0335 25.8267 26.0602 25.8433 26.0802 25.87C26.1068 25.89 26.1168 25.92 26.1102 25.96C26.0902 26.0667 26.0735 26.1433 26.0602 26.19C25.9335 26.6167 25.6768 26.9567 25.2902 27.21C24.9035 27.4567 24.4502 27.58 23.9302 27.58Z"
      fill={props.fill}
    />
  </svg>
);

Vectorizer.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Vectorizer;
