// !!!! -- DO NOT MANUALLY EDIT -- !!!!
// This file was generated by something in scripts/generateDefaultBlockData.js
// If you want to change the contents of this file, it should be automated
//
// (You can edit this file locally, but it may not be checked into the codebase -
// PRs with a manual edit of this file are forbidden from being merged.)

import { computationBlockDefaults } from 'app/generated_blocks/computationBlockDefaults';
import type { ComputationBlockClass } from 'app/generated_types/ComputationBlockClass';
import deepmerge from 'deepmerge';

export const AcausalRotationalEngine: ComputationBlockClass = deepmerge(
  computationBlockDefaults,
  {
    "base": {
      "name": "Engine",
      "namespace": "acausal.rotational",
      "description": "Basic engine modeled as modulated torque source."
    },
    "modes": {
      "time": "agnostic"
    },
    "ports": {
      "inputs": {
        "static": [
          {
            "name": "thr"
          }
        ]
      },
      "outputs": {
        "static": [
          {
            "name": "flange",
            "variant": {
              "variant_kind": "acausal",
              "acausal_domain": "rotational"
            }
          }
        ]
      }
    },
    "parameter_definitions": [
      {
        "name": "peak_trq_w",
        "data_type": "any",
        "default_value": "[0.0, 99, 100, 500, 600]",
        "description": "Speed break points for peak torque curve, in radians/second."
      },
      {
        "name": "peak_trq_t",
        "data_type": "any",
        "default_value": "[-1e6, 1e6]",
        "description": "Torque points for peak torque curve, in Nm."
      }
    ]
  }
);
