/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Group: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M8 9C8 8.44772 8.44772 8 9 8H16C16.5523 8 17 8.44772 17 9V11.25H19V9C19 8.44772 19.4477 8 20 8H23C23.5523 8 24 8.44772 24 9V15C24 15.5523 23.5523 16 23 16H20C19.4477 16 19 15.5523 19 15V12.75H17V15C17 15.5523 16.5523 16 16 16H13.25V20.75H16V20C16 19.4477 16.4477 19 17 19H23C23.5523 19 24 19.4477 24 20V23C24 23.5523 23.5523 24 23 24H17C16.4477 24 16 23.5523 16 23V22.25H12.5C12.0858 22.25 11.75 21.9142 11.75 21.5V16H9C8.44772 16 8 15.5523 8 15V9ZM9.5 14.5V9.5H15.5V14.5H9.5ZM20.5 9.5H22.5V14.5H20.5V9.5ZM17.5 20.5H22.5V22.5H17.5V20.5Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M4 5.5C4 4.67157 4.67157 4 5.5 4H26.5C27.3284 4 28 4.67157 28 5.5V26.5C28 27.3284 27.3284 28 26.5 28H5.5C4.67157 28 4 27.3284 4 26.5V5.5ZM5.5 5.5H26.5V26.5H5.5V5.5Z"
      fill={props.fill}
    />
  </svg>
);

Group.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Group;
