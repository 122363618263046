import styled from '@emotion/styled';

export const NotSignedInWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  overflow: auto;
  background: ${({ theme }) => theme.colors.ui.background};
`;
