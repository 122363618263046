import { NodeInstance, SubmodelsSection } from 'types';

export interface WithNodes {
  nodes: NodeInstance[];
}

export function mapAllNodes(
  func: (node: NodeInstance) => NodeInstance,
  diagram: WithNodes,
  subdiagrams?: SubmodelsSection,
) {
  diagram.nodes = diagram.nodes.map(func);
  if (subdiagrams) {
    Object.values(subdiagrams.diagrams).forEach(
      (d) => d && mapAllNodes(func, d),
    );
  }
}
