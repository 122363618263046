import styled from '@emotion/styled';
import { useDrag } from 'react-dnd';
import {
  DataExplorerDragType,
  SignalDragItem,
} from 'ui/dataExplorer/dataExplorerTypes';

const DraggableSignalTreeItemWrapper = styled.div<{ isDragging: boolean }>`
  ${({ theme, isDragging }) => `
  background-color: ${isDragging ? theme.colors.ui.tint2 : ''};
  padding-right: ${theme.spacing.small};
  border-radius: ${theme.spacing.small};
  `}
  display: flex;
  align-items: center;
`;

type Props = {
  traceSpecs: SignalDragItem['traceSpecs'];
  children: React.ReactNode;
};

function DraggableSignalTreeItem({ traceSpecs, children }: Props) {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: DataExplorerDragType.SignalTree,
    item: {
      traceSpecs,
    },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  return (
    <DraggableSignalTreeItemWrapper ref={drag} isDragging={isDragging}>
      {children}
    </DraggableSignalTreeItemWrapper>
  );
}

export default DraggableSignalTreeItem;
