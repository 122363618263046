import {
  NodeInstance,
  LinkInstance,
} from 'app/generated_types/SimulationModel';
import { getPortWorldCoordinate } from 'app/utils/getPortOffsetCoordinate';
import { PortSide } from 'app/common_types/PortTypes';
import { RendererState } from 'ui/modelRendererInternals/modelRenderer';
import type { Coordinate } from 'app/common_types/Coordinate';
import { getLinkTapCoordinate } from 'app/utils/tapLinkToRenderData';

export function getLinkSourceWorldCoordinate(
  rs: RendererState,
  link: LinkInstance,
  blocks: NodeInstance[],
  blocksIndexLUT: { [k: string]: number },
): Coordinate | undefined {
  if (!link.src) return;

  const node = blocks[blocksIndexLUT[link.src.node]];

  if (link.uiprops.link_type.connection_method === 'link_tap') {
    return getLinkTapCoordinate(rs, link);
  }
  return getPortWorldCoordinate(node, PortSide.Output, link.src);
}

export function getLinkDestWorldCoordinate(
  link: LinkInstance,
  blocks: NodeInstance[],
  blocksIndexLUT: { [k: string]: number },
): Coordinate | undefined {
  if (!link.dst) return;

  const node = blocks[blocksIndexLUT[link.dst.node]];

  return getPortWorldCoordinate(node, PortSide.Input, link.dst);
}
