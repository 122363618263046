/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Dimensions: React.FC<Props> = (props) => (
  <svg viewBox="0 0 16 16" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.00002 1.95956L2.31313 3.64645C2.11787 3.84171 2.11787 4.1583 2.31313 4.35356C2.5084 4.54882 2.82498 4.54882 3.02024 4.35356L3.50002 3.87378V11.6667C3.50002 12.1269 3.87312 12.5 4.33335 12.5H12.1262L11.6465 12.9798C11.4512 13.175 11.4512 13.4916 11.6465 13.6869C11.8417 13.8822 12.1583 13.8822 12.3536 13.6869L14.0405 12L12.3536 10.3131C12.1583 10.1179 11.8417 10.1179 11.6465 10.3131C11.4512 10.5084 11.4512 10.825 11.6465 11.0202L12.1262 11.5H4.50002V3.87378L4.9798 4.35356C5.17506 4.54882 5.49164 4.54882 5.68691 4.35356C5.88217 4.1583 5.88217 3.84171 5.68691 3.64645L4.00002 1.95956Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M5.66669 5.66667C5.66669 5.29848 5.96516 5 6.33335 5H10.3334C10.7015 5 11 5.29848 11 5.66667V9.66667C11 10.0349 10.7015 10.3333 10.3334 10.3333H6.33335C5.96516 10.3333 5.66669 10.0349 5.66669 9.66667V5.66667ZM6.33335 5.66667H10.3334V9.66667H6.33335V5.66667Z"
      fill={props.fill}
    />
  </svg>
);

Dimensions.defaultProps = {
  width: '16',
  height: '16',
  viewBox: '0 0 16 16',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Dimensions;
