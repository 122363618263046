import { NodeInstance } from '@collimator/model-schemas-ts';
import { t } from '@lingui/macro';
import {
  generatedApi,
  usePostJobCreateMutation,
} from 'app/apiGenerated/generatedApi';
import { GetJobSummaryApiArg } from 'app/apiGenerated/generatedApiTypes';
import { FitSindyResult } from 'app/generated_types/collimator/dashboard/serialization/ui_types.gen';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { modelActions } from 'app/slices/modelSlice';
import { uiFlagsActions } from 'app/slices/uiFlagsSlice';
import React from 'react';
import Button from 'ui/common/Button/Button';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import { Tuned } from 'ui/common/Icons/Small';
import { Reset } from 'ui/common/Icons/Standard';
import { useNotifications } from 'ui/common/notifications/useNotifications';
import { TrainingParamNames } from './BlockParameterDetails/SindyBlockParameterDetails';

type SindyFitStore = {
  [blockUUID: string]:
    | {
        pollTimeoutID: number;
      }
    | undefined;
};

export const sindyFitTimeoutssStore: SindyFitStore = {};

// we need to do this polling "manually" because we need:
// - to not rely on hooks or a specific component being mounted
// - to be able to arbitrarily cancel
// i would consider this an ad-hoc hack, though it is not extensive
// and is easily replaceable should we come up with a better
// "global pattern" for this sort of thing (should we ever need it)
// (also it is definitely better than the "redux as obfuscated call stack" pattern)
const kickoffSindyFitSummaryPolling = (
  blockUuid: string,
  jobUuid: string,
  triggerGetSummary: (args: GetJobSummaryApiArg) => any,
  onSuccess: (result: FitSindyResult) => void,
  onError: (reason: string) => void,
) => {
  const poll = async () => {
    const summaryRequestResult = await triggerGetSummary({
      jobUuid,
    });
    const { data: summary } = summaryRequestResult;

    if (!summary || sindyFitTimeoutssStore[blockUuid] == undefined) return;
    if (summary.status === 'failed') {
      onError(summary.fail_reason ?? t`Unknown error`);

      if (sindyFitTimeoutssStore[blockUuid] !== undefined) {
        sindyFitTimeoutssStore[blockUuid] = undefined;
      }
    } else if (
      summary.status === 'completed' &&
      sindyFitTimeoutssStore[blockUuid] !== undefined
    ) {
      if (summary.results) {
        onSuccess(summary.results);
      } else {
        onError(t`Results not found`);
      }

      if (sindyFitTimeoutssStore[blockUuid] !== undefined) {
        sindyFitTimeoutssStore[blockUuid] = undefined;
      }
    } else {
      sindyFitTimeoutssStore[blockUuid] = {
        pollTimeoutID: window.setTimeout(poll, 1000),
      };
    }
  };

  sindyFitTimeoutssStore[blockUuid] = {
    pollTimeoutID: -1,
  };

  poll();
};

export const SindyFitButton = ({
  selectedNode,
}: {
  selectedNode: NodeInstance;
}) => {
  const { showError, showCompletion, showInfo } = useNotifications();
  const modelUuid = useAppSelector(
    (state) => state.modelMetadata.loadedModelId,
  );
  const dispatch = useAppDispatch();
  const selectionParentPath = useAppSelector(
    (state) => state.model.present?.selectionParentPath,
  );

  const isExecuting = useAppSelector(
    (state) => state.uiFlags.autoFittingOrTuningBlockUUIDs[selectedNode.uuid],
  );

  const [callPostFitSindyModelMutationApi] = usePostJobCreateMutation();

  const [getFitSindyModelSummaryTrigger] =
    generatedApi.endpoints.getJobSummary.useLazyQuery();

  const makeFitSindyRequest = React.useCallback(
    (selectedNode: NodeInstance) => {
      if (!selectedNode.parameters.file_name?.value) {
        showError(t`Please select a data file`);
        return;
      }
      if (
        !selectedNode.parameters.state_columns?.value ||
        selectedNode.parameters.state_columns.value.length === 0
      ) {
        showError(t`Please select at least one state column`);
        return;
      }

      const request: any = {};

      TrainingParamNames.filter(
        (paramName) =>
          selectedNode.parameters[paramName] !== undefined &&
          selectedNode.parameters[paramName]?.value &&
          selectedNode.parameters[paramName]?.value !== 'None',
      ).forEach((paramName) => {
        const value = selectedNode.parameters[paramName]?.value;
        if (value === undefined) {
          return;
        }
        request[paramName] = value;
      });
      return request;
    },
    [showError],
  );

  const onExecute = React.useCallback(async () => {
    if (!modelUuid) return;

    const fitSindyRequest = makeFitSindyRequest(selectedNode);

    if (!fitSindyRequest) {
      return;
    }

    showInfo(t`SINDy training started`);
    dispatch(uiFlagsActions.markBlockAutoFittingOrTuning(selectedNode.uuid));

    const processSuccessResult = (results: FitSindyResult) => {
      showCompletion(t`Model identified and trained successfully`);
      dispatch(
        uiFlagsActions.unmarkBlockAutoFittingOrTuning(selectedNode.uuid),
      );

      const params = [
        { name: 'coefficients', value: JSON.stringify(results.coefficients) },
        {
          name: 'base_feature_names',
          value: JSON.stringify(results.base_feature_names),
        },
        { name: 'feature_names', value: JSON.stringify(results.feature_names) },
        { name: 'equations', value: JSON.stringify(results.equations) },
        {
          name: 'has_control_input',
          value: results.has_control_input.toString(),
        },
      ];

      params.forEach((param) => {
        dispatch(
          modelActions.changeBlockParameter({
            parentPath: selectionParentPath,
            nodeUuid: selectedNode.uuid,
            paramName: param.name,
            value: param.value,
          }),
        );
      });

      dispatch(
        modelActions.setNodeAutotuned({
          uuid: selectedNode.uuid,
          tuned: true,
        }),
      );
    };

    callPostFitSindyModelMutationApi({
      jobCreateRequest: {
        model_id: modelUuid,
        request: fitSindyRequest,
        kind: 'SindyFit',
      },
    })
      .unwrap()
      .then((summary) => {
        kickoffSindyFitSummaryPolling(
          selectedNode.uuid,
          summary.uuid,
          getFitSindyModelSummaryTrigger,
          processSuccessResult,
          (errorReason: string) => {
            dispatch(
              uiFlagsActions.unmarkBlockAutoFittingOrTuning(selectedNode.uuid),
            );
            showError(`Failed to fit SINDy model: ${errorReason}`);
          },
        );
      });
  }, [
    makeFitSindyRequest,
    selectedNode,
    showInfo,
    dispatch,
    callPostFitSindyModelMutationApi,
    modelUuid,
    showCompletion,
    selectionParentPath,
    getFitSindyModelSummaryTrigger,
    showError,
  ]);

  const icon = selectedNode.uiprops.is_autotuned ? Reset : Tuned;
  const text = isExecuting ? t`Training in progress` : t`Identify and train`;

  return (
    <Button
      Icon={icon}
      variant={ButtonVariants.SmallTertiary}
      onClick={onExecute}
      disabled={isExecuting}>
      {text}
    </Button>
  );
};
