/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  clipPath?: any;
  fillRule?: any;
  clipRule?: any;
  id?: any;
  className?: string;
}

const Add: React.FC<Props> = (props) => (
  <svg viewBox="0 0 8 8" {...props} xmlns="http://www.w3.org/2000/svg">
    <g clipPath={props.clipPath}>
      <path
        fillRule={props.fillRule}
        clipRule={props.clipRule}
        d="M4 0.25C4.41421 0.25 4.75 0.585786 4.75 1V3.25H7C7.41421 3.25 7.75 3.58579 7.75 4C7.75 4.41421 7.41421 4.75 7 4.75H4.75V7C4.75 7.41421 4.41421 7.75 4 7.75C3.58579 7.75 3.25 7.41421 3.25 7V4.75H1C0.585786 4.75 0.25 4.41421 0.25 4C0.25 3.58579 0.585786 3.25 1 3.25H3.25V1C3.25 0.585786 3.58579 0.25 4 0.25Z"
        fill="#1D3131"
      />
    </g>
    <defs>
      <clipPath id={props.id}>
        <rect width={props.width} height={props.height} fill={props.fill} />
      </clipPath>
    </defs>
  </svg>
);

Add.defaultProps = {
  width: '8',
  height: '8',
  viewBox: '0 0 8 8',
  fill: 'white',
  xmlns: 'http://www.w3.org/2000/svg',
  clipPath: 'url(#clip0_1061_3230)',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  id: 'clip0_1061_3230',
  className: '',
};

export default Add;
