/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Imagesource: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M22 12.5C22 13.6046 21.1046 14.5 20 14.5C18.8954 14.5 18 13.6046 18 12.5C18 11.3954 18.8954 10.5 20 10.5C21.1046 10.5 22 11.3954 22 12.5Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M5 8C4.44772 8 4 8.44772 4 9V23C4 23.5523 4.44772 24 5 24H27C27.5523 24 28 23.5523 28 23V9C28 8.44772 27.5523 8 27 8H5ZM27 9H5V18.4393L10.2139 13.2255C10.9297 12.5097 12.1017 12.5487 12.7683 13.3105L18.3606 19.7017C18.4558 19.8105 18.6232 19.8161 18.7255 19.7139L21.2626 17.1768C21.946 16.4934 23.054 16.4934 23.7374 17.1768L27 20.4393V9ZM27 22.559C26.9897 22.5498 26.9796 22.5402 26.9697 22.5303L22.6768 18.2374C22.5791 18.1398 22.4209 18.1398 22.3232 18.2374L19.7861 20.7745C19.0703 21.4903 17.8983 21.4513 17.2317 20.6895L11.6394 14.2983C11.5442 14.1895 11.3768 14.1839 11.2745 14.2862L5.03033 20.5303C5.02042 20.5402 5.01031 20.5498 5 20.559V23H27V22.559Z"
      fill={props.fill}
    />
  </svg>
);

Imagesource.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Imagesource;
