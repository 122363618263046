/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const LogOut: React.FC<Props> = (props) => (
  <svg viewBox="0 0 24 24" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M6.25 8C6.25 7.0335 7.0335 6.25 8 6.25H10C10.4142 6.25 10.75 6.58579 10.75 7C10.75 7.41421 10.4142 7.75 10 7.75H8C7.86193 7.75 7.75 7.86193 7.75 8V16C7.75 16.1381 7.86193 16.25 8 16.25H10C10.4142 16.25 10.75 16.5858 10.75 17C10.75 17.4142 10.4142 17.75 10 17.75H8C7.0335 17.75 6.25 16.9665 6.25 16V8ZM13.6256 8.4569C13.9256 8.17123 14.4003 8.18281 14.686 8.48276L18.0357 12L14.686 15.5172C14.4003 15.8172 13.9256 15.8288 13.6256 15.5431C13.3257 15.2574 13.3141 14.7827 13.5998 14.4828L15.25 12.75H10C9.58579 12.75 9.25 12.4142 9.25 12C9.25 11.5858 9.58579 11.25 10 11.25H15.25L13.5998 9.51724C13.3141 9.21729 13.3257 8.74256 13.6256 8.4569Z"
      fill={props.fill}
    />
  </svg>
);

LogOut.defaultProps = {
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default LogOut;
