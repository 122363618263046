/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Info: React.FC<Props> = (props) => (
  <svg viewBox="0 0 16 16" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M8 12C10.2091 12 12 10.2091 12 8C12 5.79086 10.2091 4 8 4C5.79086 4 4 5.79086 4 8C4 10.2091 5.79086 12 8 12ZM8 13C10.7614 13 13 10.7614 13 8C13 5.23858 10.7614 3 8 3C5.23858 3 3 5.23858 3 8C3 10.7614 5.23858 13 8 13Z"
      fill={props.fill}
    />
    <path
      d="M7.99867 6.5C7.79467 6.5 7.62367 6.434 7.48567 6.302C7.35367 6.164 7.28767 5.993 7.28767 5.789C7.28767 5.579 7.35367 5.408 7.48567 5.276C7.61767 5.144 7.78867 5.078 7.99867 5.078C8.20867 5.078 8.37967 5.144 8.51167 5.276C8.64367 5.408 8.70967 5.579 8.70967 5.789C8.70967 5.993 8.64067 6.164 8.50267 6.302C8.37067 6.434 8.20267 6.5 7.99867 6.5ZM7.45867 10.648C7.42867 10.648 7.40167 10.639 7.37767 10.621C7.35967 10.597 7.35067 10.57 7.35067 10.54V7.121C7.35067 7.091 7.35967 7.067 7.37767 7.049C7.40167 7.025 7.42867 7.013 7.45867 7.013H8.51167C8.54167 7.013 8.56567 7.025 8.58367 7.049C8.60767 7.067 8.61967 7.091 8.61967 7.121V10.54C8.61967 10.57 8.60767 10.597 8.58367 10.621C8.56567 10.639 8.54167 10.648 8.51167 10.648H7.45867Z"
      fill={props.fill}
    />
  </svg>
);

Info.defaultProps = {
  width: '16',
  height: '16',
  viewBox: '0 0 16 16',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Info;
