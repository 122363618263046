import * as NVG from 'nanovg-js';
import { getDrawContext } from './drawPrimitives';
import { RendererState } from './modelRenderer';

export const multiRadiusRect = (
  rs: RendererState,
  nvg: NVG.Context,
  x: number,
  y: number,
  w: number,
  h: number,
  r1: number,
  r2: number,
  r3: number,
  r4: number,
  fillColor: NVG.Color | undefined,
  strokeColor?: NVG.Color | undefined,
  strokeWidth?: number | undefined,
) => {
  const ctx = getDrawContext(rs, nvg);

  ctx.beginPath();
  if (r1 < 0.1 && r2 < 0.1 && r3 < 0.1 && r4 < 0.1) {
    ctx.rect(x, y, w, h);
  } else {
    // Length proportional to radius of a cubic bezier handle
    // for 90deg arcs.
    const KAPPA90 = 0.5522847493;

    const r1x = Math.min(r1, Math.abs(w) * 0.5) * Math.sign(w);
    const r1y = Math.min(r1, Math.abs(h) * 0.5) * Math.sign(h);
    const r2x = Math.min(r2, Math.abs(w) * 0.5) * Math.sign(w);
    const r2y = Math.min(r2, Math.abs(h) * 0.5) * Math.sign(h);
    const r3x = Math.min(r3, Math.abs(w) * 0.5) * Math.sign(w);
    const r3y = Math.min(r3, Math.abs(h) * 0.5) * Math.sign(h);
    const r4x = Math.min(r4, Math.abs(w) * 0.5) * Math.sign(w);
    const r4y = Math.min(r4, Math.abs(h) * 0.5) * Math.sign(h);
    ctx.moveTo(x, y + r4y);
    ctx.lineTo(x, y + h - r1y);
    ctx.bezierTo(
      x,
      y + h - r1y * (1 - KAPPA90),
      x + r1x * (1 - KAPPA90),
      y + h,
      x + r1x,
      y + h,
    );
    ctx.lineTo(x + w - r2x, y + h);
    ctx.bezierTo(
      x + w - r2x * (1 - KAPPA90),
      y + h,
      x + w,
      y + h - r2y * (1 - KAPPA90),
      x + w,
      y + h - r2y,
    );
    ctx.lineTo(x + w, y + r3y);
    ctx.bezierTo(
      x + w,
      y + r3y * (1 - KAPPA90),
      x + w - r3x * (1 - KAPPA90),
      y,
      x + w - r3x,
      y,
    );
    ctx.lineTo(x + r4x, y);
    ctx.bezierTo(
      x + r4x * (1 - KAPPA90),
      y,
      x,
      y + r4y * (1 - KAPPA90),
      x,
      y + r4y,
    );
    ctx.closePath();
  }

  if (fillColor) {
    ctx.fillColor(fillColor);
    ctx.fill();
  }

  if (strokeColor && strokeWidth) {
    ctx.strokeColor(strokeColor);
    ctx.strokeWidth(strokeWidth);
    ctx.stroke();
  }
};
