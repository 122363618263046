/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Pneumatic: React.FC<Props> = (props) => (
  <svg viewBox="0 0 8 8" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M1.41152 2.74924L4.40259 2.74972L5.81272 2.74995C5.82977 2.70038 5.84275 2.63042 5.83732 2.5514C5.82994 2.44389 5.79324 2.37662 5.75827 2.34146C5.73385 2.31691 5.65528 2.25015 5.42717 2.25018C5.01295 2.25024 4.67712 1.9145 4.67706 1.50029C4.677 1.08607 5.01274 0.750241 5.42696 0.750183C5.98977 0.750104 6.47327 0.933267 6.82178 1.28366C7.15975 1.62344 7.30684 2.05613 7.3338 2.4486C7.36037 2.83548 7.2752 3.24093 7.076 3.57315C6.877 3.90505 6.49953 4.24998 5.95072 4.24996L4.40217 4.24972L1.41135 4.24924C0.99714 4.24919 0.661392 3.91337 0.661438 3.49915C0.661484 3.08494 0.997308 2.74919 1.41152 2.74924ZM1.41144 4.74982C0.997224 4.74982 0.661438 5.08561 0.661438 5.49982C0.661438 5.91404 0.997224 6.24982 1.41144 6.24982L3.99999 6.25001H4C4.11615 6.25001 4.2101 6.25843 4.28562 6.27096C4.26249 6.34316 4.25 6.42013 4.25 6.50001C4.25 6.91422 4.58579 7.25001 5 7.25001C5.40849 7.25001 5.77877 7.02344 5.94312 6.63781C6.09252 6.28727 6.02684 5.91945 5.87554 5.64146C5.55921 5.06029 4.8601 4.75001 4.00001 4.75001H4L1.41144 4.74982Z"
      fill={props.fill}
    />
  </svg>
);

Pneumatic.defaultProps = {
  width: '8',
  height: '8',
  viewBox: '0 0 8 8',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Pneumatic;
