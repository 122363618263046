import { SimulationResultsS3Url } from 'app/apiGenerated/generatedApiTypes';
import ndarray from 'ndarray';

export type CellType = 'plot' | 'text' | 'image';

export type PlotType = 'line' | 'scatter' | 'step';

export type TextType = 'markdown' | 'python' | 'text' | 'csv';

export type MarkedPoint = {
  x: number;
  y: number;
};

export interface DataExploration {
  id: string;
  projectId: string;
  title: string;
  description: string;
  cellRowIds: string[];
  idToCell: Record<string, CellMetadata>;
  idToPlotCell: Record<string, PlotCellMetadata>;
  idToTextCell: Record<string, TextCellMetadata>;
  idToImageCell: Record<string, ImageCellMetadata>;
  idToTrace: Record<string, TraceMetadata>;
  traceIdToMarkedPoints: Record<string, MarkedPoint[]>;
  createdAt: string;
  updatedAt: string;
}

export interface CellRow {
  id: string;
  rowHeight: number;
  cellIds: string[];
}

export interface CellMetadata {
  id: string;
  cellType: CellType;
}

/**
 * FIXME we never supported y-axis zoom, hence the optional Y properties.
 */
export interface PartialDataBounds {
  startX: number;
  endX: number;
  startY?: number;
  endY?: number;
}

export type DataBounds = Required<PartialDataBounds>;

export interface PlotCellMetadata {
  id: string; // matches id of corresponding CellMetadata
  traceIds: string[];
  initialBounds?: DataBounds; // The bounds of all data from all traces. Only changes when traceIds change.
  zoomBounds?: PartialDataBounds; // The bounds for the current zoomed view. Does not get lost even if traceIds change.
}

/**
 * Trace entity in a data explorer
 */
export type TraceMetadata = {
  // Unique per trace in a plot context.
  // These ids are maintained between simulation runs when used in the model editor visualizer.
  // These ids are newly created for all traces when used in the data visualizer.
  id: string;
  modelId: string;

  // This is the same as the trace path for scalar signals,
  // but for vector signals, the tracePath will be the signalPath
  // with the port index appended, for example: signalPath[1]
  signalPath: string;
  portIndex?: number;

  // Unique per trace for a given model version.
  // Can be duplicated across different simulation runs.
  tracePath: string;

  // If the port produces a vector output, this is the index of that vector output.
  // FIXME: this is very fragile because it depends on signal_types.json.
  // This is not used anymore. Will be cleaned up. DASH-1751
  vectorIndex?: number;

  // The simulationUuid for a specific eploration. Note that this is likely
  // broken after refactor of the visualizer (2024/08)
  explorationSimId?: string;

  // TODO update legend display name for case when converting to TraceMetadata
  // because the legend display name should be updated to match the signal name if
  // it is set, and if it is unset, we should revert back to using the tracePath.
  // FIXME: this should not exist here: just use a transform function on signalPath
  // It's OK if we lose a plot when renaming a block.
  displayName: string;

  // Leave undefined for auto.
  plotType?: PlotType;

  // If the user specifies a color, store it here,
  // otherwise use default colors to prevent multiple traces
  // from having the same color.
  color?: string;
};

export interface TextCellMetadata {
  id: string; // matches id of corresponding CellMetadata
  text: string;
  textType: TextType;
}

export interface ImageCellMetadata {
  id: string; // matches id of corresponding CellMetadata
  imageSourceUrl: string;
}

export type PlotDataFetchArgs = {
  traces: TraceMetadata[];
  fromTime?: number;
  toTime?: number;
};

// FIXME: remove this and the 'kind' field below. After cleanup. DASH-1751
// https://echarts.apache.org/en/option.html#dataset.source
export type PlotDataRow = { time: number; [tracePath: string]: number };

export type PlotCellArrayData = {
  traceId: string;
  array: ndarray.NdArray;
  kind: 'array'; // remove. DASH-1751
  // TODO: if a common time column can be used for the entire vector, no need
  // to interleave every other column with time. This could be true for short
  // simulation (less than 5000 data points) or well aligned downsampled data
  // (eg. uniform).
  // interleavedTimeColumns?: boolean;
};

export type PlotCellData =
  | PlotCellArrayData
  // FIXME: DASH-1751, remove
  | {
      traceId: string;
      rows: PlotDataRow[];
      kind: 'rows';
    };

// Represents the intermediate load state for a trace.
export interface TraceLoadState {
  traceId: string;
  tracePath: string;
  errorMessage?: string;
  s3_url?: SimulationResultsS3Url;
}

export interface TraceResult {
  traceId: string;
  tracePath: string;
  data?: Response;
  errorMessage?: string;
}

export interface TraceContentResult {
  traceId: string;
  tracePath: string;
  content?: ndarray.NdArray;
  errorMessage?: string;
}

export interface TraceContentError {
  traceId: string;
  tracePath: string;
  errorMessage: string;
}

/**
 * Intermediary trace spec used in signal tree drag.
 * Once a trace is added to a chart, it'll be of type `TraceMetadata`.
 *
 * Contains all trace data necessary to create a TraceMetadata.
 */
export type TraceSpec = Omit<TraceMetadata, 'id' | 'color'>;

export enum DataExplorerDragType {
  SignalTree = 'signal tree',
  TraceLegend = 'trace legend',
}

export enum SignalDropArea {
  Top = 'top',
  Bottom = 'bottom',
  Left = 'left',
  Right = 'right',
  Self = 'self',
}

export type SignalDragItem = {
  traceSpecs: TraceSpec[];
};
