import styled from '@emotion/styled';
import { t } from '@lingui/macro';
import Editor from '@monaco-editor/react';
import { CallbackResult, GptFunction } from 'app/chat/responseStreamParser';
import * as monaco from 'monaco-editor/esm/vs/editor/editor.api';
import React, { ReactElement, useEffect, useRef, useState } from 'react';
import Button from 'ui/common/Button/Button';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import { Play } from 'ui/common/Icons/Small';
import { Eye, EyeCrossed } from 'ui/common/Icons/Standard';
import { usePython } from 'ui/common/PythonProvider';
import { Spinner } from 'ui/common/Spinner';

const TEXT_WIDTH = 756;

const CODE_EDITOR_DEFAULT_HEIGHT = 200;
const CODE_EDITOR_MIN_HEIGHT = 80;
const CODE_EDITOR_MAX_HEIGHT = 800;

const CodeWrapper = styled.div`
  display: grid;
  height: 100%;
  width: 100%;
  margin-top: ${({ theme }) => theme.spacing.normal};
`;

const EditorWrapper = styled.div<{ show: boolean }>`
  display: ${({ show }) => (show ? 'block' : 'none')};

  height: ${CODE_EDITOR_DEFAULT_HEIGHT}px;
  width: ${TEXT_WIDTH}px;
  min-height: ${CODE_EDITOR_MIN_HEIGHT}px;
  max-height: ${CODE_EDITOR_MAX_HEIGHT}px;
  resize: vertical;
  overflow: auto;

  box-shadow: ${({ theme }) => theme.shadows.standard};
`;

const EditorToolbarWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 0px 10px 0px;

  > button {
    margin-right: 10px;
  }
`;

const CodeStdout = styled.div`
  color: ${({ theme }) => theme.colors.grey[70]};
  font-size: ${({ theme }) => theme.typography.font.small.size};
  font-family: ${({ theme }) => theme.typography.font.code.fontFamily};
  font-weight: ${({ theme }) => theme.typography.font.code.weight};
  margin-bottom: ${({ theme }) => theme.spacing.normal};
`;

const CodeError = styled.div`
  color: red;
  font-size: ${({ theme }) => theme.typography.font.small.size};
  font-family: ${({ theme }) => theme.typography.font.code.fontFamily};
  font-weight: ${({ theme }) => theme.typography.font.code.weight};
  margin-bottom: ${({ theme }) => theme.spacing.normal};
`;

interface PythonCodeProps {
  value: string;
  id: string;

  readOnly?: boolean;
  enableExecution?: boolean;
  showStdOut?: boolean;
  error?: string;
  stdout?: string;
  executeFn?: GptFunction;
  onOutputChange?: (code: string, stdout?: string, stderr?: string) => void;
}

const PythonCode = ({
  value,
  id,
  readOnly,
  onOutputChange,
  executeFn,
  enableExecution,
  showStdOut,
  error = '',
  stdout = '',
}: PythonCodeProps): ReactElement => {
  const python = usePython();

  const [code, setCode] = useState(value);
  const [codeWidth, setCodeWidth] = useState(TEXT_WIDTH);
  const [codeHeight, setCodeHeight] = useState(CODE_EDITOR_DEFAULT_HEIGHT);
  const [codeError, setCodeError] = useState(error);
  const [codeStdout, setCodeStdout] = useState(stdout);
  const [showCode, setShowCode] = useState(false);
  const [isExecuting, setIsExecuting] = useState(false);
  const containerRef = useRef(null);

  useEffect(() => {
    setCode(value);
  }, [value]);

  useEffect(() => {
    const observeTarget = containerRef.current;

    if (!observeTarget) return;

    // Define the observing function
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        const { width, height } = entry.contentRect;
        setCodeWidth(width);
        setCodeHeight(height);
      }
    });

    // Start observing
    resizeObserver.observe(observeTarget);

    // Clean up the observer on component unmount
    return () => {
      resizeObserver.unobserve(observeTarget);
    };
  }, [containerRef]);

  const editorRef = React.useRef<monaco.editor.IStandaloneCodeEditor | null>(
    null,
  );

  const onEditorMount = React.useCallback(
    (editor: monaco.editor.IStandaloneCodeEditor) => {
      editorRef.current = editor;

      const contentHeight = Math.min(
        Math.max(editor.getContentHeight(), CODE_EDITOR_MIN_HEIGHT),
        CODE_EDITOR_MAX_HEIGHT,
      );
      setCodeHeight(contentHeight);
    },
    [],
  );

  const onEditorCodeContentChange = React.useCallback(
    (code: string | undefined) => {
      if (!code) return;

      setCode(code);

      // Auto-scroll to bottom - only when read-only, ie. when ChatGPT is talking
      if (readOnly && editorRef.current) {
        const numberOfLines = editorRef.current.getModel()?.getLineCount();
        if (numberOfLines) {
          editorRef.current.revealLines(numberOfLines - 1, numberOfLines);
        }
      }
    },
    [readOnly],
  );

  const onExecutionComplete = React.useCallback(
    (output: CallbackResult) => {
      setIsExecuting(false);
      setCodeStdout(output.result || '');
      setCodeError(output.error || '');
      onOutputChange?.(code, output.result, output.error);
    },
    [code, onOutputChange],
  );

  const onExecute = React.useCallback(
    (code: string) => {
      setIsExecuting(true);
      setCodeStdout('');
      setCodeError('');
      executeFn?.({ code, allowDirty: true }, onExecutionComplete);
    },
    [executeFn, onExecutionComplete],
  );

  // manually cleanup monaco editor models when component unmounts.
  // FIXME: it shouldn't be necessary but there is a bug here:
  // https://github.com/suren-atoyan/monaco-react/issues/493
  React.useEffect(
    () => () => {
      editorRef.current?.getModel()?.dispose();
      editorRef.current?.dispose();
    },
    [],
  );

  return (
    <CodeWrapper id="code-wrapper" data-test-id="code-wrapper">
      <EditorWrapper ref={containerRef} show={showCode}>
        <Editor
          value={code}
          language={readOnly ? 'text' : 'python'}
          onMount={onEditorMount}
          onChange={onEditorCodeContentChange}
          path={id}
          width={codeWidth}
          height={codeHeight}
          options={{
            minimap: { enabled: false },
            readOnly,
            scrollBeyondLastLine: false,
            scrollbar: { vertical: 'visible', horizontal: 'visible' },
          }}
        />
      </EditorWrapper>
      {showCode || (
        <Button
          variant={ButtonVariants.SmallSecondary}
          Icon={Eye}
          testId="show-code-button"
          onClick={() => setShowCode(true)}>
          {t`Show code`}
        </Button>
      )}
      <EditorToolbarWrapper>
        {enableExecution && (
          <Button
            hidden
            variant={ButtonVariants.SmallPrimary}
            disabled={readOnly || !python.isReady || isExecuting}
            Icon={readOnly || !python.isReady || isExecuting ? Spinner : Play}
            onClick={() => onExecute(code)}>
            {t`Execute`}
          </Button>
        )}
        {showCode && (
          <Button
            variant={ButtonVariants.SmallSecondary}
            Icon={EyeCrossed}
            onClick={() => setShowCode(false)}>
            {t`Hide code`}
          </Button>
        )}
      </EditorToolbarWrapper>
      {codeStdout && showStdOut && <CodeStdout>{codeStdout}</CodeStdout>}
      {codeError && (
        <>
          {t`The Python code raised the following error:`}
          <CodeError>{codeError}</CodeError>
        </>
      )}
    </CodeWrapper>
  );
};

export default PythonCode;
