import styled from '@emotion/styled/macro';
import { t } from '@lingui/macro';
import {
  useGetProjectGitRemoteQuery,
  usePostProjectGitPushToUpstreamMutation,
} from 'app/apiGenerated/generatedApi';
import { PostProjectGitPushToUpstreamApiArg } from 'app/apiGenerated/generatedApiTypes';
import React from 'react';
import Button from 'ui/common/Button/Button';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import { Checkbox } from 'ui/common/Checkbox';
import { Share } from 'ui/common/Icons/Standard';
import Input from 'ui/common/Input/Input';
import Label from 'ui/common/Label';
import {
  ActionButtonContainer,
  ModalInputGroup,
  SmallFormContainer,
} from 'ui/common/Modal/Modal';
import { useModal } from 'ui/common/Modal/useModal';
import { Spinner } from 'ui/common/Spinner';
import Textarea from 'ui/common/Textarea';
import { useNotifications } from 'ui/common/notifications/useNotifications';
import { isValidBranchName } from 'util/gitUtils';
import inputStringCallback from 'util/inputStringCallback';
import { useAppParams } from 'util/useAppParams';

const CheckboxWithLabel = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing.normal};
`;

const Spacer = styled.div`
  height: ${({ theme }) => theme.spacing.xlarge};
`;

const StyledForm = styled(SmallFormContainer)`
  label {
    color: ${({ theme }) => theme.colors.text.secondary};
  }
`;

interface Props {
  currentBranch: string;
}

export const GitPushChangesModal = ({ currentBranch }: Props) => {
  const { closeModal } = useModal();
  const { projectId } = useAppParams();
  const { createShowError, showInfo } = useNotifications();

  const [pushingChanges, setPushingChanges] = React.useState(false);
  const [commitMsg, setCommitMsg] = React.useState<string | undefined>();
  const [targetBranch, setTargetBranch] = React.useState(currentBranch);

  // TODO: these are not actually implemented in the backend
  const [abortIfDiverged, setAbortIfDiverged] = React.useState<boolean>(false);
  const [deleteFiles, setDeleteFiles] = React.useState<boolean>(false);

  // This allows creating new branches on push
  const { data: remoteInfo, isLoading } = useGetProjectGitRemoteQuery(
    { projectUuid: projectId || '', expand: ['branches', 'remote_branches'] },
    { skip: !projectId, refetchOnMountOrArgChange: true },
  );
  const remoteBranchNames = React.useMemo(
    () => remoteInfo?.remote_branches?.map((branch) => branch.name) || [],
    [remoteInfo],
  );

  const [callPushApi] = usePostProjectGitPushToUpstreamMutation();

  const onPushChanges = () => {
    if (!projectId) return;

    setPushingChanges(true);

    const payload: PostProjectGitPushToUpstreamApiArg = {
      projectGitPushToUpstreamRequest: {
        branch: targetBranch,
        base_ref_if_missing: `refs/heads/${currentBranch}`,
        commit_message: commitMsg,
        delete_files: deleteFiles,
        abort_if_diverged: abortIfDiverged,
      },
      projectUuid: projectId,
    };

    callPushApi(payload)
      .unwrap()
      .then((resp) => {
        showInfo(
          t({
            id: 'pushGitChangesModal.pushSuccess.label',
            message:
              'Pushed changes to branch {branch}: {files} file(s) updated',
            values: {
              branch: resp.branch,
              files: resp.files.length,
            },
          }),
        );
      })
      .catch(
        createShowError(
          t({
            id: 'pushGitChangesModal.pushFailed.label',
            message: 'Failed to push changes to branch {branch}',
          }),
        ),
      )
      .finally(() => {
        setPushingChanges(false);
        closeModal();
      });
  };

  return (
    <StyledForm
      onSubmit={(e) => {
        e?.preventDefault();
        onPushChanges();
      }}>
      <ModalInputGroup>
        <Label>
          {t({
            id: 'pushGitChangesModal.branchNameInfo.label',
            message: 'Target branch:',
          })}
        </Label>
        <Input value={targetBranch} onChangeText={setTargetBranch} />
      </ModalInputGroup>
      <ModalInputGroup>
        <Label>
          {t({
            id: 'pushGitChangesModal.commitMessage.label',
            message: 'Add a commit message',
          })}
        </Label>
        <Textarea
          value={commitMsg}
          onChange={inputStringCallback(setCommitMsg)}
          placeholder="collimator: update %{FILE}"
        />
      </ModalInputGroup>
      <CheckboxWithLabel>
        <Label>
          {t({
            id: 'pushGitChangesModal.deleteFiles.label',
            message: 'Delete files in the remote branch?',
          })}
        </Label>
        <Checkbox value={deleteFiles} onChange={setDeleteFiles} isDisabled />
      </CheckboxWithLabel>
      <CheckboxWithLabel>
        <Label>
          {t({
            id: 'pushGitChangesModal.forcePush.label',
            message: 'Attempt to push even if remote has changed?',
          })}
        </Label>
        <Checkbox
          value={abortIfDiverged}
          onChange={setAbortIfDiverged}
          isDisabled
        />
      </CheckboxWithLabel>
      <Spacer />
      <ActionButtonContainer>
        <Button
          type="button"
          onClick={closeModal}
          variant={ButtonVariants.LargeSecondary}>
          {t({
            id: 'pushGitChangesModal.cancelButton.label',
            message: 'Cancel',
          })}
        </Button>

        <Button
          type="submit"
          Icon={pushingChanges || isLoading ? Spinner : Share}
          variant={ButtonVariants.LargePrimary}
          disabled={
            pushingChanges ||
            isLoading ||
            !isValidBranchName(targetBranch, remoteBranchNames)
          }>
          {t({
            id: 'pushGitChangesModal.pushButton.label',
            message: 'Push changes',
          })}
        </Button>
      </ActionButtonContainer>
    </StyledForm>
  );
};
