import styled from '@emotion/styled/macro';
import React from 'react';
import { TooltipPlacement } from 'ui/common/Tooltip/tooltipTypes';
import Menu from '../Menu';
import { MENU_WIDTH } from '../menuConstants';
import { ChangedItem, MenuItemConfig } from '../menuItemTypes';
import MenuItemButton from './MenuItemButton';

const SubMenuWrapper = styled.div`
  position: absolute;
  left: ${MENU_WIDTH}px;
  top: -8px;
`;

interface Props {
  items: MenuItemConfig[];
  onSubmitValue?: (changed: ChangedItem) => void;
  onClick: () => void;
  opened: boolean;
  text: string;
}

const Submenu: React.FC<Props> = ({
  items,
  onSubmitValue,
  onClick,
  opened,
  text,
}) => (
  <>
    <MenuItemButton content={text} onClick={() => onClick()} />
    {opened ? (
      <SubMenuWrapper>
        <Menu
          items={items}
          startOpen
          placement={TooltipPlacement.BOTTOM_LEFT}
          onSubmitValue={onSubmitValue}
        />
      </SubMenuWrapper>
    ) : null}
  </>
);

export default Submenu;
