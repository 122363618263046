/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Variant: React.FC<Props> = (props) => (
  <svg viewBox="0 0 16 16" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M5.15811 2.53918C5.05548 2.50497 4.94452 2.50497 4.84189 2.53918L2.84189 3.20584C2.63772 3.2739 2.5 3.46497 2.5 3.68019V6.31981C2.5 6.53502 2.63772 6.72609 2.84189 6.79415L4.84189 7.46081C4.94452 7.49503 5.05548 7.49503 5.15811 7.46081L7.15811 6.79415C7.36228 6.72609 7.5 6.53502 7.5 6.31981V3.68019C7.5 3.46497 7.36228 3.2739 7.15811 3.20584L5.15811 2.53918ZM3 6.31981V3.84685L4.75 4.43019V6.90314L3 6.31981ZM5.25 6.90314V4.43019L7 3.84685V6.31981L5.25 6.90314ZM5 3.98647L6.45943 3.5L5 3.01352L3.54057 3.5L5 3.98647Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M11.1581 2.53918C11.0555 2.50497 10.9445 2.50497 10.8419 2.53918L8.84189 3.20584C8.63772 3.2739 8.5 3.46497 8.5 3.68019V6.31981C8.5 6.53502 8.63771 6.72609 8.84189 6.79415L10.8419 7.46081C10.9445 7.49503 11.0555 7.49503 11.1581 7.46081L13.1581 6.79415C13.3623 6.72609 13.5 6.53502 13.5 6.31981V3.68019C13.5 3.46497 13.3623 3.2739 13.1581 3.20584L11.1581 2.53918ZM9 4.06981V3.84685L10.75 4.43019V4.65314L9 4.06981ZM11.25 4.65314V4.43019L13 3.84685V4.06981L11.25 4.65314ZM11 3.98647L12.4594 3.5L12.125 3.38852L10.6656 3.875L11 3.98647ZM11 3.01352L11.3344 3.125L9.875 3.61147L9.54057 3.5L11 3.01352ZM11.25 5.40697V5.18019L13 4.59685V4.84408L11.25 5.40697ZM11.25 6.15506V5.9322L13 5.36931V5.58194L11.25 6.15506ZM11.25 6.90314V6.68119L13 6.10807V6.31981L11.25 6.90314ZM10.75 6.68019V6.90314L9 6.31981V6.09685L10.75 6.68019ZM10.75 6.15314L9 5.56981V5.34685L10.75 5.93019V6.15314ZM10.75 5.40314L9 4.81981V4.59685L10.75 5.18019V5.40314Z"
      fill={props.fill}
    />
    <path
      d="M4.75 11C4.75 11.2761 4.52614 11.5 4.25 11.5C3.97386 11.5 3.75 11.2761 3.75 11C3.75 10.7239 3.97386 10.5 4.25 10.5C4.52614 10.5 4.75 10.7239 4.75 11Z"
      fill={props.fill}
    />
    <path
      d="M3 11.75C3 11.4739 3.22386 11.25 3.5 11.25C3.77614 11.25 4 11.4739 4 11.75C4 12.0261 3.77614 12.25 3.5 12.25C3.22386 12.25 3 12.0261 3 11.75Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M5.15811 8.53918L7.15811 9.20584C7.36228 9.2739 7.5 9.46497 7.5 9.68019V12.3198C7.5 12.535 7.36228 12.7261 7.15811 12.7941L5.15811 13.4608C5.05548 13.495 4.94452 13.495 4.84189 13.4608L2.84189 12.7941C2.63772 12.7261 2.5 12.535 2.5 12.3198V9.68019C2.5 9.46497 2.63772 9.2739 2.84189 9.20584L4.84189 8.53918C4.94452 8.50497 5.05548 8.50497 5.15811 8.53918ZM3 9.84685V10.0669C3.07354 10.0243 3.15893 10 3.25 10C3.52614 10 3.75 10.2239 3.75 10.5C3.75 10.7761 3.52614 11 3.25 11C3.15893 11 3.07354 10.9756 3 10.9331L3 11.75L3 12.3198L4.75 12.9031V12.6831C4.67646 12.7256 4.59107 12.75 4.5 12.75C4.22386 12.75 4 12.5261 4 12.25C4 11.9739 4.22386 11.75 4.5 11.75C4.59107 11.75 4.67646 11.7743 4.75 11.8169V11V10.4302L3 9.84685ZM5.25 11.4331V12.2495V12.9031L5.71339 12.7487C5.4545 12.7299 5.25025 12.5131 5.25 12.2495C5.25027 11.9736 5.47402 11.75 5.75 11.75C6.02614 11.75 6.25 11.9739 6.25 12.25C6.25 12.4004 6.18358 12.5353 6.07846 12.627L7 12.3198V12.1831C6.92646 12.2256 6.84107 12.25 6.75 12.25C6.47386 12.25 6.25 12.0261 6.25 11.75C6.25 11.4739 6.47386 11.25 6.75 11.25C6.84107 11.25 6.92646 11.2743 7 11.3169V10.5V9.84685L6.53661 10.0013C6.79566 10.0201 7 10.2362 7 10.5C7 10.7761 6.77614 11 6.5 11C6.22386 11 6 10.7761 6 10.5C6 10.3496 6.06642 10.2147 6.17154 10.123L5.25 10.4302V10.5669C5.32354 10.5243 5.40893 10.5 5.5 10.5C5.77614 10.5 6 10.7239 6 11C6 11.2761 5.77614 11.5 5.5 11.5C5.40893 11.5 5.32354 11.4756 5.25 11.4331ZM4.52489 9.8281C4.50874 9.80353 4.5 9.77727 4.5 9.75C4.5 9.61193 4.72386 9.5 5 9.5C5.27614 9.5 5.5 9.61193 5.5 9.75C5.5 9.77727 5.49126 9.80353 5.47511 9.8281L6.45943 9.5L5 9.01352L4.96668 9.02463C5.13431 9.06499 5.25 9.15074 5.25 9.25C5.25 9.38807 5.02614 9.5 4.75 9.5C4.48278 9.5 4.26451 9.39518 4.25069 9.26329L3.54057 9.5L4.52489 9.8281Z"
      fill={props.fill}
    />
    <path
      d="M11 10.25L9.1756 9.62285C9.03927 9.57599 9.04109 9.38256 9.17827 9.33826L10.9223 8.77509C10.9728 8.75878 11.0272 8.75896 11.0775 8.77561L12.7814 9.33856C12.9173 9.38346 12.9191 9.57501 12.7841 9.62249L11 10.25Z"
      fill={props.fill}
    />
    <path
      d="M10.75 10.57L9.08364 9.97841C8.92091 9.92064 8.75 10.0413 8.75 10.214V12.2584C8.75 12.3618 8.81367 12.4545 8.91018 12.4917L10.4102 13.0692C10.5739 13.1322 10.75 13.0113 10.75 12.8359V10.57Z"
      fill={props.fill}
    />
    <path
      d="M11.25 12.8324C11.25 13.0088 11.4278 13.1298 11.5919 13.0649L13.041 12.4925C13.1364 12.4548 13.1991 12.3626 13.1991 12.26V10.2171C13.1991 10.0436 13.0266 9.9228 12.8635 9.98221L11.25 10.57V12.8324Z"
      fill={props.fill}
    />
  </svg>
);

Variant.defaultProps = {
  width: '16',
  height: '16',
  viewBox: '0 0 16 16',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Variant;
