import React from 'react';
import { useAppParams } from 'util/useAppParams';
import ModelVersion from './ModelVersion';

const ModelVersionLoader: React.FC = () => {
  const { projectId, modelId, versionId } = useAppParams();

  if (!projectId || !modelId || !versionId) return null;

  return (
    <ModelVersion
      projectId={projectId}
      modelId={modelId}
      versionId={versionId}
    />
  );
};

export default ModelVersionLoader;
