/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  className?: string;
}

const Divide: React.FC<Props> = (props) => (
  <svg viewBox="0 0 8 8" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5 1C5 1.55228 4.55228 2 4 2C3.44772 2 3 1.55228 3 1C3 0.447715 3.44772 0 4 0C4.55228 0 5 0.447715 5 1Z"
      fill={props.fill}
    />
    <path
      d="M0.25 4C0.25 3.58579 0.585786 3.25 1 3.25H7C7.41421 3.25 7.75 3.58579 7.75 4C7.75 4.41421 7.41421 4.75 7 4.75H1C0.585786 4.75 0.25 4.41421 0.25 4Z"
      fill={props.fill}
    />
    <path
      d="M4 8C4.55228 8 5 7.55228 5 7C5 6.44772 4.55228 6 4 6C3.44772 6 3 6.44772 3 7C3 7.55228 3.44772 8 4 8Z"
      fill={props.fill}
    />
  </svg>
);

Divide.defaultProps = {
  width: '8',
  height: '8',
  viewBox: '0 0 8 8',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  className: '',
};

export default Divide;
