/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Continuous: React.FC<Props> = (props) => (
  <svg viewBox="0 0 16 16" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M3.61012 7.61553C3.56692 7.76621 3.5237 7.91698 3.47977 8.06666C3.40201 8.33163 3.12417 8.48338 2.85921 8.40562C2.59424 8.32786 2.44248 8.05002 2.52024 7.78505C2.55956 7.65108 2.59954 7.51121 2.64065 7.36737C2.85131 6.63029 3.09174 5.78909 3.42565 5.10478C3.82002 4.29659 4.44641 3.5 5.50001 3.5C6.55476 3.5 7.18094 4.29804 7.57539 5.11616C7.91879 5.82838 8.16235 6.71095 8.37773 7.49145C8.41291 7.61894 8.44734 7.7437 8.48133 7.86455C8.51804 7.99508 8.5543 8.12609 8.59051 8.2569C8.80866 9.04509 9.02478 9.82595 9.3254 10.4495C9.68095 11.1869 10.0548 11.5 10.5 11.5C10.9441 11.5 11.3181 11.1884 11.6736 10.4423C11.9834 9.79214 12.2043 8.96565 12.4289 8.12512C12.4582 8.01537 12.4876 7.90539 12.5173 7.79552C12.5893 7.52892 12.8637 7.37116 13.1303 7.44314C13.3969 7.51512 13.5547 7.78959 13.4827 8.05619C13.4541 8.16234 13.4251 8.27093 13.3957 8.38129C13.1756 9.20722 12.9291 10.1322 12.5764 10.8724C12.1819 11.7004 11.5559 12.5 10.5 12.5C9.44524 12.5 8.81907 11.7019 8.42462 10.8838C8.08124 10.1715 7.83769 9.28898 7.62231 8.50849C7.58712 8.38098 7.55268 8.25618 7.51868 8.1353C7.48198 8.00482 7.44573 7.87385 7.40954 7.74308C7.19138 6.95486 6.97526 6.17398 6.67463 5.55046C6.31908 4.81305 5.94525 4.5 5.50001 4.5C5.05361 4.5 4.68 4.8145 4.32437 5.54332C4.03292 6.1406 3.82179 6.87709 3.61012 7.61553Z"
      fill={props.fill}
    />
  </svg>
);

Continuous.defaultProps = {
  width: '16',
  height: '16',
  viewBox: '0 0 16 16',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Continuous;
