import { Theme } from '@emotion/react';
import styled from '@emotion/styled/macro';
import { t } from '@lingui/macro';
import { ExtraSmall } from 'ui/common/typography/Typography';

export enum BadgeType {
  New,
  ReadOnly,
  Public,
  Shared,
}

export function getBadgeColor(theme: Theme, badgeType: BadgeType): string {
  switch (badgeType) {
    case BadgeType.Shared:
      return theme.colors.brand.secondary.darker;
    case BadgeType.Public:
      return theme.colors.tint.base;
    case BadgeType.ReadOnly:
      return theme.colors.brand.tertiary.lighter;
    case BadgeType.New:
    default:
      return theme.colors.brand.tertiary.darker;
  }
}

export function getBadgeText(badgeType: BadgeType) {
  switch (badgeType) {
    case BadgeType.Shared:
      return t({
        id: 'badge.isShared.textContent',
        message: 'Shared',
      });
    case BadgeType.Public:
      return t({
        id: 'badge.isPublic.textContent',
        message: 'Public',
      });
    case BadgeType.ReadOnly:
      return t({
        id: 'badge.isReadOnly.textContent',
        message: 'Read Only',
      });
    case BadgeType.New:
    default:
      return t({
        id: 'badge.isNew.textContent',
        message: 'New',
      });
  }
}

const BadgeWrapper = styled(ExtraSmall)<{ badgeType: BadgeType }>`
  margin: 0 ${({ theme }) => theme.spacing.small};
  background-color: ${({ theme, badgeType }) =>
    getBadgeColor(theme, badgeType)};
  border-radius: 2px;
  color: white;
  padding: 0 ${({ theme }) => theme.spacing.small};
  height: ${({ theme }) => theme.spacing.large};
  display: flex;
  align-self: center;
`;

interface Props {
  text?: string;
  badgeType: BadgeType;
}

export const Badge: React.FC<Props> = ({ text, badgeType }) => (
  <BadgeWrapper badgeType={badgeType}>
    {text || getBadgeText(badgeType)}
  </BadgeWrapper>
);
