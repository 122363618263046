import styled from '@emotion/styled/macro';
import { Coordinate } from 'app/common_types/Coordinate';
import {
  StateLinkInstance,
  StateNodeInstance,
} from 'app/generated_types/SimulationModel';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { MAXIMUM_ZOOM, MINIMUM_ZOOM } from 'app/slices/cameraSlice';
import { modelActions } from 'app/slices/modelSlice';
import { snapNumberToGrid } from 'app/utils/modelDataUtils';
import {
  copyStateMachineEntities,
  dispatchPasteStateMachineEntities,
} from 'app/utils/stateMachineUtils';
import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { ActionCreators as UndoRedoActionCreators } from 'redux-undo';
import ModelEditorBreadcrumb from 'ui/modelEditor/ModelEditorBreadcrumb';
import { useAppParams } from 'util/useAppParams';
import { v4 as makeUuid } from 'uuid';
import {
  SMECamera,
  SMEInteractionTag,
  SMEInteractionTy,
  SMERefsObjTy,
  StateNodeSide,
} from './SMETypes';
import {
  coordsToSizeBox,
  getCoordsForLink,
  getNodeSideWorldCoord,
} from './SMEUtils';
import { registerAllSMEMouseInput } from './StateMachineEdInputManager';
import {
  StateMachineEntryLinkInput,
  StateMachineLinkInputs,
  StateMachineLinkUI,
} from './StateMachineLinkUI';
import {
  STATENODE_HEIGHT,
  STATENODE_WIDTH,
  StateMachineNodeUI,
} from './StateMachineNodeUI';

export const entryPointStartCoord = { x: 60, y: 60 };

const getFitZoomedCameraSME = (
  smeElement: HTMLDivElement,
  nodes: StateNodeInstance[],
  links: StateLinkInstance[],
): SMECamera => {
  const screenW = smeElement.offsetWidth;
  const screenH = smeElement.offsetHeight;

  let maxX = Number.MIN_SAFE_INTEGER;
  let minX = Number.MAX_SAFE_INTEGER;
  let maxY = Number.MIN_SAFE_INTEGER;
  let minY = Number.MAX_SAFE_INTEGER;

  for (let i = 0; i < nodes.length; i++) {
    const node = nodes[i];

    const leftEdge = node.uiprops.x;
    const rightEdge = leftEdge + STATENODE_WIDTH;
    const topEdge = node.uiprops.y;
    const bottomEdge = topEdge + STATENODE_HEIGHT;

    minX = Math.min(leftEdge, minX);
    maxX = Math.max(rightEdge, maxX);

    minY = Math.min(topEdge, minY);
    maxY = Math.max(bottomEdge, maxY);
  }

  minX = Math.min(entryPointStartCoord.x, minX);
  maxX = Math.max(entryPointStartCoord.x, maxX);

  minY = Math.min(entryPointStartCoord.y, minY);
  maxY = Math.max(entryPointStartCoord.y, maxY);

  const pad = 30;

  const modelW = maxX - minX + pad * 2;
  const modelH = maxY - minY + pad * 2;

  const targetZoom = Math.max(
    MINIMUM_ZOOM,
    Math.min(MAXIMUM_ZOOM, Math.min(screenW / modelW, screenH / modelH)),
  );

  const camX = minX - pad - screenW / 2 / targetZoom + modelW / 2;
  const camY = minY - pad - screenH / 2 / targetZoom + modelH / 2;

  return {
    x: -camX,
    y: -camY,
    zoom: targetZoom,
  };
};

export const STATE_MACHINE_EDITOR_BLOCK_QUERY_PARAM = 'state_machine_node_id';

const StateMachineBG = styled.div({
  width: '100%',
  height: '100%',
  background: 'rgb(234, 235, 245)',
  position: 'relative',
  pointerEvents: 'auto',
  userSelect: 'none',
});

const LinkSVGContainer = styled.svg`
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;

  > g {
    pointer-events: none;
  }

  > g > * {
    pointer-events: auto;
  }

  > g > .nopointer {
    pointer-events: none !important;
  }
`;

export const initialRefState: SMERefsObjTy = {
  readOnly: false,
  stateMachineId: '',
  setInteraction: () => {},
  camera: { x: 0, y: 0, zoom: 1 },
  setCamera: () => {},
  mouseCoords: { x: 0, y: 0 },
  setMouseCoords: () => {},
  stateNodes: [],
  nodeLUT: {},
  stateLinks: [],
  selectedNodeIds: [],
  selectedLinkIds: [],
  setSelectedNodeIds: () => {},
  setSelectedLinkIds: () => {},
  interactionState: { tag: SMEInteractionTag.None },
  stateMachineBgRef: null,
};

function getTransform(camera: SMECamera) {
  return `scale(${camera.zoom}) translateX(${camera.x}px) translateY(${camera.y}px)`;
}

export const StateMachineEditor = ({ readOnly }: { readOnly: boolean }) => {
  const smeRefsObj = React.useRef<SMERefsObjTy>(initialRefState);
  smeRefsObj.current.readOnly = readOnly;

  const dispatch = useAppDispatch();
  smeRefsObj.current.dispatch = dispatch;

  const [searchParams] = useSearchParams();
  const [_stateMachineBlockId, stateMachineId] = (
    searchParams.get(STATE_MACHINE_EDITOR_BLOCK_QUERY_PARAM) || ''
  ).split('.');
  smeRefsObj.current.stateMachineId = stateMachineId;

  const { modelId } = useAppParams();
  const referenceSubmodelId = useAppSelector(
    (state) => state.modelMetadata.currentDiagramSubmodelReferenceId,
  );

  const stateMachine = useAppSelector((state) => {
    if (referenceSubmodelId && referenceSubmodelId !== modelId) {
      const submodelData =
        state.submodels.idToVersionIdToSubmodelFull[referenceSubmodelId]
          ?.latest;
      return (submodelData?.stateMachines || {})[stateMachineId || ''];
    }

    return (state.model.present.stateMachines || {})[stateMachineId || ''];
  });

  const SMLinksDivRef = React.useRef<HTMLDivElement>(null);
  const SMNodesDivRef = React.useRef<HTMLDivElement>(null);
  const SMSVGRef = React.useRef<SVGSVGElement>(null);

  const setCamera = React.useCallback(
    (newState: SMECamera) => {
      smeRefsObj.current.camera = newState;
      const transform = getTransform(newState);

      const nodesDiv = SMNodesDivRef.current;
      if (nodesDiv) nodesDiv.style.transform = transform;

      const linksDiv = SMSVGRef.current;
      if (linksDiv) linksDiv.style.transform = transform;

      const svgDiv = SMLinksDivRef.current;
      if (svgDiv) svgDiv.style.transform = transform;
    },
    [smeRefsObj, SMNodesDivRef, SMSVGRef, SMLinksDivRef],
  );
  smeRefsObj.current.setCamera = setCamera;

  const { nodes, links } = stateMachine || {
    nodes: [] as Array<StateNodeInstance>,
    links: [] as Array<StateLinkInstance>,
  };
  smeRefsObj.current.stateNodes = nodes;
  smeRefsObj.current.stateLinks = links;

  const [selectedLinkIds, setSelectedLinkIds_raw] = React.useState<
    Array<string>
  >([]);
  const setSelectedLinkIds = React.useCallback(
    (ids: Array<string>) => {
      smeRefsObj.current.selectedLinkIds = ids;
      setSelectedLinkIds_raw(ids);
    },
    [setSelectedLinkIds_raw],
  );
  const [selectedNodeIds, setSelectedNodeIds_raw] = React.useState<
    Array<string>
  >([]);
  const setSelectedNodeIds = React.useCallback(
    (ids: Array<string>) => {
      smeRefsObj.current.selectedNodeIds = ids;
      setSelectedNodeIds_raw(ids);
    },
    [setSelectedNodeIds_raw],
  );
  smeRefsObj.current.selectedNodeIds = selectedNodeIds;
  smeRefsObj.current.selectedLinkIds = selectedLinkIds;
  smeRefsObj.current.setSelectedNodeIds = setSelectedNodeIds;
  smeRefsObj.current.setSelectedLinkIds = setSelectedLinkIds;

  const nodeLUT = React.useMemo(
    () =>
      nodes.reduce<{ [id: string]: StateNodeInstance }>(
        (acc, node) => ({ ...acc, [node.uuid]: node }),
        {},
      ),
    [nodes],
  );
  smeRefsObj.current.nodeLUT = nodeLUT;

  const [interaction, setInteraction_raw] = React.useState<SMEInteractionTy>({
    tag: SMEInteractionTag.None,
  });
  smeRefsObj.current.interactionState = interaction;
  const setInteraction = React.useCallback(
    (newState: SMEInteractionTy) => {
      smeRefsObj.current.interactionState = newState;
      setInteraction_raw(newState);
    },
    [setInteraction_raw],
  );
  smeRefsObj.current.setInteraction = setInteraction;

  smeRefsObj.current.stateMachineBgRef = React.useRef<HTMLDivElement>(null);

  const [mouseCoords, setMouseCoords_raw] = React.useState<Coordinate>({
    x: 0,
    y: 0,
  });
  smeRefsObj.current.mouseCoords = mouseCoords;
  const setMouseCoords = (newCoords: Coordinate) => {
    setMouseCoords_raw(newCoords);
    smeRefsObj.current.mouseCoords = newCoords;
  };
  smeRefsObj.current.setMouseCoords = setMouseCoords;

  React.useEffect(() => {
    const stateMachineBgEl = smeRefsObj.current.stateMachineBgRef?.current;
    if (!stateMachineBgEl) return;

    return registerAllSMEMouseInput(smeRefsObj);
  }, []);

  const onLinkFunc = (nodeId: string, side: StateNodeSide, coord: number) => {
    if (readOnly) return;

    if (interaction.tag === SMEInteractionTag.None) {
      setInteraction({
        tag: SMEInteractionTag.DrawingLink,
        from: {
          nodeId,
          side,
          coord,
        },
      });
    }

    if (interaction.tag === SMEInteractionTag.ReDraggingLink) {
      if (stateMachineId) {
        dispatch(
          modelActions.repositionStateLink({
            stateMachineUuid: stateMachineId,
            linkId: interaction.linkId,
            toNodeId: nodeId,
            side,
            coord: snapNumberToGrid(coord),
            forStart: interaction.fromStart,
          }),
        );
      }

      setInteraction({ tag: SMEInteractionTag.None });
    }

    if (interaction.tag === SMEInteractionTag.DraggingEntryPoint) {
      if (stateMachineId) {
        dispatch(
          modelActions.setStateMachineEntryPointConnection({
            stateMachineUuid: stateMachineId,
            toNodeId: nodeId,
            side,
            coord: snapNumberToGrid(coord),
          }),
        );
      }

      setInteraction({ tag: SMEInteractionTag.None });
    }

    if (interaction.tag === SMEInteractionTag.DrawingLink) {
      let destCoord = coord;
      const onXAxis =
        interaction.from.side === 'top' || interaction.from.side === 'down';
      // TODO: make this more nuanced, this does not consider visual ordering
      const matchingSides =
        (interaction.from.side === 'top' && side === 'down') ||
        (interaction.from.side === 'down' && side === 'top') ||
        (interaction.from.side === 'left' && side === 'right') ||
        (interaction.from.side === 'right' && side === 'left');

      // attempt to straighten the dest coord
      const sourceNode = nodeLUT[interaction.from.nodeId];
      const destNode = nodeLUT[nodeId];
      if (sourceNode && destNode && matchingSides) {
        const worldCoord = getNodeSideWorldCoord(
          sourceNode,
          interaction.from.side,
          interaction.from.coord,
        ) || { x: 0, y: 0 };
        if (
          onXAxis &&
          worldCoord.x > destNode.uiprops.x &&
          worldCoord.x < destNode.uiprops.x + STATENODE_WIDTH
        ) {
          destCoord = worldCoord.x - destNode.uiprops.x;
        } else if (
          worldCoord.y > destNode.uiprops.y &&
          worldCoord.y < destNode.uiprops.y + STATENODE_HEIGHT
        ) {
          destCoord = worldCoord.y - destNode.uiprops.y;
        }
      }

      if (stateMachineId) {
        dispatch(
          modelActions.addStateLink({
            stateMachineUuid: stateMachineId,
            newStateLink: {
              uuid: makeUuid(),
              sourceNodeId: interaction.from.nodeId,
              destNodeId: nodeId,
              uiprops: {
                sourceSide: interaction.from.side,
                sourceCoord: snapNumberToGrid(interaction.from.coord),
                destSide: side,
                destCoord: snapNumberToGrid(destCoord),
                curveDeviation: { x: 0, y: 0 },
              },
            },
          }),
        );
      }

      setInteraction({ tag: SMEInteractionTag.None });
    }
  };
  // necessary because of async nature of events handling
  // and hooks within entity (node, link) components
  const onLinkFuncRef = React.useRef(onLinkFunc);
  onLinkFuncRef.current = onLinkFunc;
  const onLinkNode = React.useCallback(
    (nodeId: string, side: StateNodeSide, coord: number) => {
      onLinkFuncRef.current(nodeId, side, coord);
    },
    [onLinkFuncRef],
  );

  const textInputFocused = useAppSelector(
    (state) => state.uiFlags.textInputFocused,
  );

  // keys
  React.useEffect(() => {
    const onKey = (e: KeyboardEvent) => {
      switch (e.key) {
        case 'z':
          if (e.metaKey || e.ctrlKey) {
            if (e.shiftKey) {
              dispatch(UndoRedoActionCreators.redo());
            } else {
              dispatch(UndoRedoActionCreators.undo());
            }
          }
          break;
        case 'Escape':
          setInteraction({ tag: SMEInteractionTag.None });
          break;
        case 'Backspace':
        case 'Delete':
          if (readOnly || textInputFocused) return;
          dispatch(
            modelActions.deleteStateMachineEntities({
              stateMachineUuid: stateMachineId,
              stateLinkIdsToDel: selectedLinkIds,
              stateNodeIdsToDel: selectedNodeIds,
            }),
          );
          break;
        case ' ':
          if (textInputFocused) return;
          if (smeRefsObj.current.stateMachineBgRef?.current) {
            const newCamera = getFitZoomedCameraSME(
              smeRefsObj.current.stateMachineBgRef?.current,
              smeRefsObj.current.stateNodes,
              smeRefsObj.current.stateLinks,
            );
            setCamera(newCamera);
          }
          break;
        case 'c':
          if (e.ctrlKey || e.metaKey) {
            copyStateMachineEntities(dispatch, {
              stateMachineUuid: smeRefsObj.current.stateMachineId,
              nodes: smeRefsObj.current.stateNodes.filter((node) =>
                smeRefsObj.current.selectedNodeIds.includes(node.uuid),
              ),
              links: smeRefsObj.current.stateLinks.filter((link) =>
                smeRefsObj.current.selectedLinkIds.includes(link.uuid),
              ),
              cut: false,
            });
          }
          break;
        case 'x':
          copyStateMachineEntities(dispatch, {
            stateMachineUuid: smeRefsObj.current.stateMachineId,
            nodes: smeRefsObj.current.stateNodes.filter((node) =>
              smeRefsObj.current.selectedNodeIds.includes(node.uuid),
            ),
            links: smeRefsObj.current.stateLinks.filter((link) =>
              smeRefsObj.current.selectedLinkIds.includes(link.uuid),
            ),
            cut: true,
          });
          break;
        case 'v':
          dispatchPasteStateMachineEntities(dispatch, {
            stateMachineUuid: smeRefsObj.current.stateMachineId,
            x: smeRefsObj.current.mouseCoords.x,
            y: smeRefsObj.current.mouseCoords.y,
          });
          break;
      }
    };

    document.addEventListener('keydown', onKey);

    return () => {
      document.removeEventListener('keydown', onKey);
    };
  }, [
    readOnly,
    setInteraction,
    selectedLinkIds,
    selectedNodeIds,
    stateMachineId,
    textInputFocused,
    setCamera,
    smeRefsObj,
    dispatch,
  ]);

  const onSelectNode = React.useCallback(
    (id: string) => {
      setSelectedNodeIds([id]);
      setSelectedLinkIds([]);
    },
    [setSelectedNodeIds, setSelectedLinkIds],
  );

  const onSelectLink = React.useCallback(
    (id: string) => {
      setSelectedLinkIds([id]);
      setSelectedNodeIds([]);
    },
    [setSelectedNodeIds, setSelectedLinkIds],
  );

  const linkReDragStartPoint = React.useCallback(
    (linkId: string, connectedNodeId?: string) => {
      setInteraction({
        tag: SMEInteractionTag.ReDraggingLink,
        linkId,
        fromStart: true,
        connectedNodeId,
      });
    },
    [setInteraction],
  );
  const linkReDragEndPoint = React.useCallback(
    (linkId?: string, connectedNodeId?: string) => {
      if (!linkId) return;
      setInteraction({
        tag: SMEInteractionTag.ReDraggingLink,
        linkId,
        fromStart: false,
        connectedNodeId,
      });
    },
    [setInteraction],
  );

  const entryPointEndCoordDefault = {
    x: entryPointStartCoord.x + 20,
    y: entryPointStartCoord.y + 20,
  };
  const entryPointEndCoord: Coordinate =
    interaction.tag === SMEInteractionTag.DraggingEntryPoint
      ? mouseCoords
      : stateMachine?.entry_point.dest_id
      ? getNodeSideWorldCoord(
          nodeLUT[stateMachine.entry_point.dest_id],
          stateMachine.entry_point.dest_side,
          stateMachine.entry_point.dest_coord,
        ) || entryPointEndCoordDefault
      : entryPointEndCoordDefault;
  const entryPointStartSide: StateNodeSide =
    entryPointEndCoord.x > entryPointStartCoord.x ? 'right' : 'left';

  const startDragEntryPoint = React.useCallback(() => {
    setInteraction({
      tag: SMEInteractionTag.DraggingEntryPoint,
    });
  }, [setInteraction]);

  const onStartDragNode = React.useCallback(() => {
    if (smeRefsObj.current.interactionState.tag === SMEInteractionTag.None) {
      setInteraction({
        tag: SMEInteractionTag.DraggingNodes,
      });
    }
  }, [setInteraction]);

  if (!stateMachineId) return null;

  const cameraTransform = getTransform(smeRefsObj.current.camera);

  let selectionBox = { x: 0, y: 0, width: 0, height: 0 };

  if (interaction.tag === SMEInteractionTag.SelectDragRect) {
    selectionBox = coordsToSizeBox(interaction.startCoord, mouseCoords);
  }

  return (
    <StateMachineBG ref={smeRefsObj.current.stateMachineBgRef}>
      <div
        ref={SMNodesDivRef}
        style={{ position: 'absolute', transform: cameraTransform }}>
        {nodes.map((node: StateNodeInstance) => (
          <StateMachineNodeUI
            key={node.uuid}
            refsObj={smeRefsObj}
            node={node}
            onLink={onLinkNode}
            canSingleClickLink={
              interaction.tag === SMEInteractionTag.DrawingLink ||
              interaction.tag === SMEInteractionTag.ReDraggingLink
            }
            stateMachineId={stateMachineId}
            onSelect={onSelectNode}
            selected={selectedNodeIds.includes(node.uuid)}
            onStartDragNode={onStartDragNode}
            readOnly={readOnly}
          />
        ))}
      </div>
      <LinkSVGContainer
        onWheel={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}>
        <g ref={SMSVGRef} style={{ transform: cameraTransform }}>
          {/* entry-point link */}
          <StateMachineLinkUI
            key="entrypoint_link"
            hideCurveDeviator
            refsObj={smeRefsObj}
            disableMouse={
              interaction.tag === SMEInteractionTag.DraggingEntryPoint
            }
            start={entryPointStartCoord}
            end={entryPointEndCoord}
            startSide={entryPointStartSide}
            endSide={stateMachine?.entry_point.dest_side}
            stateMachineId={stateMachineId}
            readOnly={readOnly}
            onDragEndPoint={startDragEntryPoint}
          />
          {/* actual links */}
          {links.map((link: StateLinkInstance) => {
            const draggingStart =
              interaction.tag === SMEInteractionTag.ReDraggingLink &&
              interaction.fromStart &&
              interaction.linkId === link.uuid;
            const draggingEnd =
              interaction.tag === SMEInteractionTag.ReDraggingLink &&
              !interaction.fromStart &&
              interaction.linkId === link.uuid;

            const start = draggingStart
              ? mouseCoords
              : getNodeSideWorldCoord(
                  nodeLUT[link.sourceNodeId || ''],
                  link.uiprops.sourceSide,
                  link.uiprops.sourceCoord,
                ) || { x: 0, y: 0 };
            const end = draggingEnd
              ? mouseCoords
              : getNodeSideWorldCoord(
                  nodeLUT[link.destNodeId || ''],
                  link.uiprops.destSide,
                  link.uiprops.destCoord,
                ) || { x: 0, y: 0 };
            return (
              <StateMachineLinkUI
                disableMouse={
                  interaction.tag === SMEInteractionTag.ReDraggingLink
                }
                key={link.uuid}
                refsObj={smeRefsObj}
                link={link}
                start={start}
                end={end}
                ignoreStartAnchor={
                  interaction.tag === SMEInteractionTag.ReDraggingLink &&
                  interaction.linkId === link.uuid &&
                  interaction.fromStart
                }
                ignoreEndAnchor={
                  interaction.tag === SMEInteractionTag.ReDraggingLink &&
                  interaction.linkId === link.uuid &&
                  !interaction.fromStart
                }
                startSide={link.uiprops.sourceSide}
                endSide={link.uiprops.destSide}
                stateMachineId={stateMachineId}
                onSelect={onSelectLink}
                selected={selectedLinkIds.includes(link.uuid)}
                readOnly={readOnly}
                onDragStartPoint={linkReDragStartPoint}
                onDragEndPoint={linkReDragEndPoint}
              />
            );
          })}
          {interaction.tag === SMEInteractionTag.DrawingLink && (
            <StateMachineLinkUI
              disableMouse
              key="dragginglink"
              refsObj={smeRefsObj}
              start={
                getNodeSideWorldCoord(
                  nodeLUT[interaction.from.nodeId],
                  interaction.from.side,
                  interaction.from.coord,
                ) || { x: 0, y: 0 }
              }
              end={mouseCoords}
              ignoreEndAnchor
              startSide={interaction.from.side}
              stateMachineId={stateMachineId}
            />
          )}
          {interaction.tag === SMEInteractionTag.SelectDragRect && (
            <rect
              fill="none"
              strokeWidth={1}
              stroke="black"
              x={selectionBox.x}
              y={selectionBox.y}
              width={selectionBox.width}
              height={selectionBox.height}
            />
          )}
        </g>
      </LinkSVGContainer>
      <div
        ref={SMLinksDivRef}
        style={{
          position: 'absolute',
          transform: cameraTransform,
          pointerEvents: 'none',
        }}>
        <StateMachineEntryLinkInput
          start={entryPointStartCoord}
          end={entryPointEndCoord}
          startSide={entryPointStartSide}
          endSide={stateMachine?.entry_point.dest_side}
          entryPointActions={stateMachine?.entry_point.actions || []}
          stateMachineId={stateMachineId}
          readOnly={readOnly}
        />
        {links.map((link: StateLinkInstance) => {
          const { start, end } = getCoordsForLink(link, nodeLUT);
          return (
            <StateMachineLinkInputs
              key={link.uuid}
              link={link}
              start={start}
              end={end}
              ignoreStartAnchor={
                interaction.tag === SMEInteractionTag.ReDraggingLink &&
                interaction.linkId === link.uuid &&
                interaction.fromStart
              }
              ignoreEndAnchor={
                interaction.tag === SMEInteractionTag.ReDraggingLink &&
                interaction.linkId === link.uuid &&
                !interaction.fromStart
              }
              startSide={link.uiprops.sourceSide}
              endSide={link.uiprops.destSide}
              stateMachineId={stateMachineId}
              readOnly={readOnly}
            />
          );
        })}
      </div>
      <ModelEditorBreadcrumb />
    </StateMachineBG>
  );
};
