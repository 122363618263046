import styled from '@emotion/styled';
import { OptimizationDesignParam } from 'app/api/custom_types/optimizations';
import { ModelParameters } from 'app/utils/modelDataUtils';
import React from 'react';
import { Remove } from 'ui/common/Icons/Standard';
import Input from 'ui/common/Input/Input';
import SectionHeading from 'ui/common/Inputs/SectionHeading';
import SelectInput from 'ui/common/SelectInput';
import {
  OptimizerInputLabel,
  ParamContainer,
  ParamInputGroup,
  ParamSelectRow,
} from './optimizerModalUtils';

const NoBoundsSpacer = styled.div`
  width: 100%;
  display: flex;
  flex: 2;
  padding-right: 8px;
`;

type OptimizationDesignParamWithHidden = OptimizationDesignParam & {
  hidden?: boolean;
};

const DesignParamFields = ({
  designParams,
  onUpdate,
  supportsBounds,
  modelParameters,
}: {
  designParams: OptimizationDesignParam[];
  onUpdate: (fields: OptimizationDesignParam[]) => void;
  supportsBounds?: boolean;
  modelParameters: ModelParameters;
}) => {
  const paramOptions = React.useMemo(
    () =>
      modelParameters.map((param) => ({
        label: param.name,
        value: param.name,
      })),
    [modelParameters],
  );

  const addDesignParam = React.useCallback(() => {
    onUpdate([...designParams, {}]);
  }, [onUpdate, designParams]);

  const removeDesignParam = React.useCallback(
    (index: number) => {
      onUpdate(designParams.filter((_, i) => i !== index));
    },
    [onUpdate, designParams],
  );

  const defaultInitialValues: Record<string, string> = React.useMemo(
    () =>
      modelParameters.reduce(
        (acc, param) => ({ ...acc, [param.name]: param.value }),
        {},
      ),
    [modelParameters],
  );

  const filteredParams: OptimizationDesignParamWithHidden[] = React.useMemo(
    () =>
      designParams.map((p) => {
        if (
          Object.keys(p).length === 0 ||
          modelParameters.find((mp) => mp.name === p.param_name)
        ) {
          return { ...p, hidden: false };
        }
        return { ...p, hidden: true };
      }),
    [designParams, modelParameters],
  );

  const updateParam = React.useCallback(
    (index: number, param: Partial<OptimizationDesignParam>) =>
      onUpdate(
        filteredParams.map((p, i) => (i === index ? { ...p, ...param } : p)),
      ),
    [onUpdate, filteredParams],
  );

  const selectParamName = React.useCallback(
    (index: number, newVal: string) =>
      updateParam(index, {
        param_name: newVal,
        initial: defaultInitialValues[newVal] || '',
      }),
    [updateParam, defaultInitialValues],
  );

  const setParamMin = React.useCallback(
    (index: number, newVal: string) => updateParam(index, { min: newVal }),
    [updateParam],
  );

  const setParamMax = React.useCallback(
    (index: number, newVal: string) => updateParam(index, { max: newVal }),
    [updateParam],
  );

  const setInitial = React.useCallback(
    (index: number, newVal: string) => updateParam(index, { initial: newVal }),
    [updateParam],
  );

  React.useEffect(() => {
    if (!supportsBounds) {
      onUpdate(
        designParams.map((param) => ({
          ...param,
          min: undefined,
          max: undefined,
        })),
      );
    }
  }, [supportsBounds, designParams, onUpdate]);

  return (
    <>
      <SectionHeading noBorder onButtonClick={addDesignParam}>
        Design parameters
      </SectionHeading>
      {filteredParams.map((param, i) => {
        if (param.hidden) {
          return null;
        }

        return (
          <ParamContainer key={`param_${i}`}>
            <ParamSelectRow>
              <SelectInput
                currentValue={param.param_name}
                onSelectValue={(newVal) => selectParamName(i, newVal)}
                options={paramOptions}
                isOptionDisabled={(option) =>
                  // TODO: include stochastic parameters in disabled list
                  option.value !== param.param_name &&
                  designParams.map((p) => p.param_name).includes(option.value)
                }
              />
              <Remove onClick={() => removeDesignParam(i)} />
            </ParamSelectRow>
            <ParamInputGroup>
              <ParamInputGroup>
                <OptimizerInputLabel>Initial</OptimizerInputLabel>
                <Input
                  value={param.initial}
                  onChangeText={(newVal) => setInitial(i, newVal)}
                  rightIconIsResetButton
                  onClickRightIcon={() =>
                    setInitial(
                      i,
                      defaultInitialValues[param.param_name || ''] || '',
                    )
                  }
                  defaultValue={
                    defaultInitialValues[param.param_name || ''] || ''
                  }
                  hasBorder
                />
              </ParamInputGroup>
              {supportsBounds ? (
                <>
                  <ParamInputGroup>
                    <OptimizerInputLabel>Minimum</OptimizerInputLabel>
                    <Input
                      value={param.min}
                      onChangeText={(newVal) => setParamMin(i, newVal)}
                      defaultValue=""
                      placeholder="-inf"
                      rightIconIsResetButton
                      onClickRightIcon={() => setParamMin(i, '')}
                      hasBorder
                    />
                  </ParamInputGroup>
                  <ParamInputGroup>
                    <OptimizerInputLabel>Maximum</OptimizerInputLabel>
                    <Input
                      value={param.max}
                      onChangeText={(newVal) => setParamMax(i, newVal)}
                      defaultValue=""
                      placeholder="+inf"
                      rightIconIsResetButton
                      onClickRightIcon={() => setParamMax(i, '')}
                      hasBorder
                    />
                  </ParamInputGroup>
                </>
              ) : (
                <NoBoundsSpacer />
              )}
            </ParamInputGroup>
          </ParamContainer>
        );
      })}
    </>
  );
};

export default DesignParamFields;
