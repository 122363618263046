/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  className?: string;
}

const Unitdelay: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M22.904 7.49994C22.824 7.49994 22.764 7.51194 22.724 7.53594L21.464 8.12394C21.392 8.15594 21.356 8.21194 21.356 8.29194L21.392 9.32394C21.392 9.41194 21.428 9.45594 21.5 9.45594L21.548 9.44394L22.736 9.11994C22.752 9.11194 22.768 9.11594 22.784 9.13194C22.8 9.14794 22.808 9.16394 22.808 9.17994V15.7559C22.808 15.7959 22.82 15.8319 22.844 15.8639C22.876 15.8879 22.912 15.8999 22.952 15.8999H24.356C24.396 15.8999 24.428 15.8879 24.452 15.8639C24.484 15.8319 24.5 15.7959 24.5 15.7559V7.64394C24.5 7.60394 24.484 7.57194 24.452 7.54794C24.428 7.51594 24.396 7.49994 24.356 7.49994H22.904Z"
      fill={props.fill}
    />
    <path
      d="M15.9179 13.0439C15.9499 13.0679 15.9859 13.0799 16.0259 13.0799H19.9499C19.9899 13.0799 20.0219 13.0679 20.0459 13.0439C20.0779 13.0119 20.0939 12.9759 20.0939 12.9359V11.7719C20.0939 11.7319 20.0779 11.6999 20.0459 11.6759C20.0219 11.6439 19.9899 11.6279 19.9499 11.6279H16.0259C15.9859 11.6279 15.9499 11.6439 15.9179 11.6759C15.8939 11.6999 15.8819 11.7319 15.8819 11.7719V12.9359C15.8819 12.9759 15.8939 13.0119 15.9179 13.0439Z"
      fill={props.fill}
    />
    <path
      d="M7.47978 24.392C7.52778 24.428 7.58178 24.446 7.64178 24.446H14.3378C14.3978 24.446 14.4458 24.428 14.4818 24.392C14.5298 24.344 14.5538 24.29 14.5538 24.23V22.772C14.5538 22.712 14.5298 22.664 14.4818 22.628C14.4458 22.58 14.3978 22.556 14.3378 22.556H9.92778C9.84378 22.556 9.82578 22.52 9.87378 22.448L14.3918 17.246C14.4638 17.174 14.4998 17.09 14.4998 16.994V15.464C14.4998 15.404 14.4758 15.356 14.4278 15.32C14.3918 15.272 14.3438 15.248 14.2838 15.248H7.73178C7.67178 15.248 7.61778 15.272 7.56978 15.32C7.53378 15.356 7.51578 15.404 7.51578 15.464V16.922C7.51578 16.982 7.53378 17.036 7.56978 17.084C7.61778 17.12 7.67178 17.138 7.73178 17.138H11.8898C11.9858 17.138 12.0038 17.174 11.9438 17.246L7.51578 22.448C7.45578 22.508 7.42578 22.592 7.42578 22.7V24.23C7.42578 24.29 7.44378 24.344 7.47978 24.392Z"
      fill={props.fill}
    />
  </svg>
);

Unitdelay.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  className: '',
};

export default Unitdelay;
