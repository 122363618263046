import styled from '@emotion/styled/macro';
import React from 'react';
import { MenuItemWrapper } from 'ui/common/Menu/items/MenuItemWrapper';

const MenuItemTitleWrapper = styled(MenuItemWrapper)`
  display: flex;
  justify-content: space-between;
  cursor: default;
  color: ${(props) => props.theme.colors.text.tertiary};
`;

interface Props {
  text: string;
}

const MenuItemTitle: React.FC<Props> = ({ text }) => (
  <MenuItemTitleWrapper>
    <span>{text}</span>
  </MenuItemTitleWrapper>
);

export default MenuItemTitle;
