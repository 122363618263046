import * as NVG from 'nanovg-js';
import { NVGcolor } from 'nanovg-js/bind-nanovg';

export const nvgColorToHex = (color: NVGcolor): string =>
  [color.r, color.g, color.b, color.a]
    .map((n) => {
      const hex = Math.floor(n * 255).toString(16);
      if (hex.length === 1) return `0${hex}`;
      return hex;
    })
    .join('');

export const nvgColorToCSS = (color: NVGcolor): string =>
  `#${nvgColorToHex(color)}`;

export function hexToNvgColor(hex: string): NVG.Color {
  if (hex.length === 9) {
    return NVG.RGBA(
      parseInt(hex.slice(1, 3), 16),
      parseInt(hex.slice(3, 5), 16),
      parseInt(hex.slice(5, 7), 16),
      parseInt(hex.slice(7, 9), 16),
    );
  }
  return NVG.RGB(
    parseInt(hex.slice(1, 3), 16),
    parseInt(hex.slice(3, 5), 16),
    parseInt(hex.slice(5, 7), 16),
  );
}
