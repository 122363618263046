import styled from '@emotion/styled/macro';
import React from 'react';
import { MenuItemWrapper } from 'ui/common/Menu/items/MenuItemWrapper';

const SelectOptionWrapper = styled(MenuItemWrapper)`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  &:hover {
    background-color: ${(props) => props.theme.colors.tint.tint2};
  }
`;

const Check = () => (
  <svg width="10" height="10" viewBox="0 0 10 10" fill="none">
    <path
      d="M1 6L4.19854 8.79872C4.31744 8.90276 4.50184 8.87179 4.58022 8.73461L9 1"
      stroke="#7E9A9A"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
);

export interface Props {
  id: string;
  content: React.ReactNode;
  onClick: (id: string) => void;
  selected?: boolean;
}

const SelectOption: React.FC<Props> = ({ id, content, onClick, selected }) => (
  <SelectOptionWrapper onClick={() => onClick(id)}>
    {content}
    {selected ? <Check /> : null}
  </SelectOptionWrapper>
);

export default SelectOption;
