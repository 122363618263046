import styled from '@emotion/styled/macro';
import { t } from '@lingui/macro';
import { useAppSelector } from 'app/hooks';
import React from 'react';
import Button from 'ui/common/Button/Button';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import { ChevronRight, Close } from 'ui/common/Icons/Standard';
import { CloseButtonContainer, Header, Title } from 'ui/common/Modal/Modal';
import { useModal } from 'ui/common/Modal/useModal';
import { customStripeContext } from 'ui/common/CustomStripeProvider';
import { ApiKeys3rdPartyModalContents } from './ApiKeys3rdPartyModalContents';
import { SubscriptionManagementModalContents } from './SubscriptionManagementModalContents';
import { ApiKeysCollimatorTokenContents } from './ApiKeysCollimatorTokenContents';
import { ApiKeysGitHubPATModalContents } from './ApiKeysGitHubPATModalContents';
import { ApiKeysOpenAiModalContents } from './ApiKeysOpenAiModalContents';

// Shared styles
export const TableWrapper = styled.div`
  margin-top: 12px;
  margin-bottom: ${(props) => props.theme.spacing.large};
  min-width: 610px;
`;

export const DATE_WIDTH_PX = 100;
export const TOKEN_WIDTH_PX = 270;

const ApiKeysModalOuterContainer = styled.div`
  width: 100%;
  min-width: 812px;
  min-height: 390px;
  display: flex;
  flex-direction: column;
`;

const ApiKeysModalTitle = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 56px;
  ${({ theme }) => `
    font-size: ${theme.typography.font.title.size};
    padding: 0 ${theme.spacing.large};
    background: ${theme.colors.grey[5]};
    border-bottom: 1px solid ${theme.colors.grey[10]};
  `}
`;

// Local styles
const ApiKeysModalInnerContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  background: white;
`;

const ApiKeysModalTabList = styled.div`
  display: flex;
  flex-direction: column;
  width: 170px;
  flex-shrink: 0;
  border-right: 1px solid ${({ theme }) => theme.colors.grey[10]};
  background: white;
  padding-top: ${({ theme }) => theme.spacing.large};
`;

const ApiKeysModalTab = styled.div<{ highlighted?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 24px;
  padding: 0 8px 0 16px;
  margin-bottom: 12px;
  font-size: 12px;
  cursor: pointer;
  background: ${({ highlighted, theme }) =>
    highlighted ? theme.colors.brand.primary.lighter : 'transparent'};
  font-weight: ${({ highlighted }) => (highlighted ? '600' : 'normal')};
`;

const ApiKeysModalContent = styled.div`
  padding-left: 16px;
  padding: ${({ theme }) => theme.spacing.large};
  width: 100%;
`;

const CloseButton = styled(Button)`
  margin-right: 0;
`;

export type TabName =
  | 'apiKeys'
  | 'openAiKey'
  | 'githubPat'
  | 'thirdPartyCreds'
  | 'subscription';

const ApiKeysModal: React.FC<{
  tab?: TabName;
}> = ({ tab }) => {
  const { closeModal } = useModal();
  const { developerModeEnabled } = useAppSelector(
    (state) => state.userOptions.options,
  );
  const { subscriptionStatus } = React.useContext(customStripeContext);

  type TabObject = {
    contentTitle?: string;
    tabTitle: string;
    hidden?: boolean;
  };

  const tabs: Record<TabName, TabObject> = {
    apiKeys: {
      contentTitle: t`Manage API keys`,
      tabTitle: t`Collimator API Keys`,
    },
    subscription: {
      tabTitle: t`Subscription`,
    },
    openAiKey: {
      contentTitle: t`OpenAI API Key`,
      tabTitle: t`OpenAI API Key`,
    },
    githubPat: {
      contentTitle: t`GitHub PAT`,
      tabTitle: t`GitHub PAT`,
    },

    // Generic 3rd Party Credentials hidden by default since other tabs handle
    // all the use cases. Keep it because it's useful for debugging, and we might
    // need it again anyway. -- @jp, 2023-08-03
    thirdPartyCreds: {
      contentTitle: t`Manage 3rd Party Credentials`,
      tabTitle: t`3rd Party Credentials`,
      hidden: !developerModeEnabled,
    },
  };

  const [currentTab, setCurrentTab] = React.useState<TabName>(tab || 'apiKeys');

  return (
    <ApiKeysModalOuterContainer>
      <ApiKeysModalTitle>
        Settings
        <Button
          Icon={Close}
          onClick={closeModal}
          variant={ButtonVariants.LargeTertiary}
        />
      </ApiKeysModalTitle>
      <ApiKeysModalInnerContainer>
        <ApiKeysModalTabList>
          {(Object.keys(tabs) as Array<TabName>)
            .filter((tabKey) => {
              if (tabKey === 'subscription' && subscriptionStatus?.exempt) {
                return false;
              }
              return !tabs[tabKey].hidden;
            })
            .map((tabKey) => (
              <ApiKeysModalTab
                key={tabKey}
                highlighted={currentTab === tabKey}
                onClick={() => setCurrentTab(tabKey)}>
                {tabs[tabKey].tabTitle}
                <ChevronRight fill="#839595" />
              </ApiKeysModalTab>
            ))}
        </ApiKeysModalTabList>
        <ApiKeysModalContent>
          {tabs[currentTab].contentTitle && (
            <Header>
              <Title>{tabs[currentTab].contentTitle}</Title>
            </Header>
          )}
          <form
            onSubmit={(e) => {
              e?.preventDefault();
              closeModal();
            }}>
            {currentTab === 'apiKeys' && <ApiKeysCollimatorTokenContents />}
            {currentTab === 'subscription' && (
              <SubscriptionManagementModalContents />
            )}
            {currentTab === 'githubPat' && <ApiKeysGitHubPATModalContents />}
            {currentTab === 'openAiKey' && <ApiKeysOpenAiModalContents />}
            {currentTab === 'thirdPartyCreds' && (
              <ApiKeys3rdPartyModalContents />
            )}
          </form>
        </ApiKeysModalContent>
      </ApiKeysModalInnerContainer>
    </ApiKeysModalOuterContainer>
  );
};

export default ApiKeysModal;
