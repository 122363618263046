import { MouseActions } from 'app/common_types/MouseTypes';
import { renderConstants } from 'app/utils/renderConstants';
import * as NVG from 'nanovg-js';
import { drawLink } from 'ui/modelRendererInternals/drawLink';
import { drawNode } from 'ui/modelRendererInternals/drawNode';
import {
  PortConnLUTType,
  RendererState,
} from 'ui/modelRendererInternals/modelRenderer';
import { LINE_COLORS } from './coloring';
import { drawAnnotation } from './drawAnnotation';
import { drawDebuggingInfo } from './drawDebug';
import { getDrawContext } from './drawPrimitives';
import { multiRadiusRect } from './multiRadiusRect';

let SHOW_SCENE_BOUNDING_BOX = false;

export function drawScene(
  nvg: NVG.Context,
  rs: RendererState,
  connectedPortsLUT: PortConnLUTType,
  selectedBlockIds: string[],
  width: number,
  height: number,
): void {
  const ctx = getDrawContext(rs, nvg);

  const clipMargin = renderConstants.GRID_UNIT_PXSIZE * 2 * rs.zoom;

  // World coordinates
  const sceneBoundingBox = {
    x1: -rs.camera.x - clipMargin,
    y1: -rs.camera.y - clipMargin,
    x2: -rs.camera.x + width + clipMargin,
    y2: -rs.camera.y + height + clipMargin,
  };

  if (SHOW_SCENE_BOUNDING_BOX) {
    multiRadiusRect(
      rs,
      nvg,
      (sceneBoundingBox.x1 + rs.camera.x) * rs.zoom,
      (sceneBoundingBox.y1 + rs.camera.y) * rs.zoom,
      (sceneBoundingBox.x2 - sceneBoundingBox.x1) * rs.zoom,
      (sceneBoundingBox.y2 - sceneBoundingBox.y1) * rs.zoom,
      2,
      2,
      2,
      2,
      NVG.RGBA(0, 255, 0, 64),
    );
  }

  for (let i = 0; i < rs.refs.current.annotations.length; i++) {
    const annoData = rs.refs.current.annotations[i];
    const selected = rs.refs.current.selectedAnnotationIds.includes(
      annoData.uuid,
    );
    drawAnnotation(nvg, rs, annoData, selected);
  }

  const t0 = performance.now();
  for (let i = 0; i < rs.linksRenderFrameData.length; i++) {
    const linkData = rs.linksRenderFrameData[i];
    drawLink(
      nvg,
      rs, // TODO: decouple renderer state from primitive render funcs
      linkData,
      sceneBoundingBox,
    );
  }
  const t1 = performance.now();

  for (let i = 0; i < rs.refs.current.nodes.length; i++) {
    const node = rs.refs.current.nodes[i];
    drawNode(
      nvg,
      rs,
      node,
      connectedPortsLUT[node.uuid],
      selectedBlockIds.includes(node.uuid),
      sceneBoundingBox,
    );
  }
  const t2 = performance.now();

  if (
    rs.mouseState.state === MouseActions.MakingSelection ||
    rs.mouseState.state === MouseActions.DefiningAnnotationBox
  ) {
    const isForAnnotation =
      rs.mouseState.state === MouseActions.DefiningAnnotationBox;
    const { rawScreenCursorStartX, rawScreenCursorStartY } = rs.mouseState;
    const minX = Math.min(rs.screenCursorRaw.x, rawScreenCursorStartX);
    const maxX = Math.max(rs.screenCursorRaw.x, rawScreenCursorStartX);
    const minY = Math.min(rs.screenCursorRaw.y, rawScreenCursorStartY);
    const maxY = Math.max(rs.screenCursorRaw.y, rawScreenCursorStartY);

    const w = maxX - minX;
    const h = maxY - minY;
    const widthSteps = w / 10;
    const heightSteps = h / 10;

    ctx.strokeWidth(1);
    ctx.strokeColor(
      isForAnnotation
        ? LINE_COLORS.annotation_rectangle
        : LINE_COLORS.selection_rectangle,
    );

    for (let i = 0; i < widthSteps; i++) {
      const lineStart = minX + i * 10;
      const lineEnd = Math.min(lineStart + 5, maxX);
      // beginning side line
      ctx.beginPath();
      ctx.moveTo(lineStart, minY);
      ctx.lineTo(lineEnd, minY);
      ctx.stroke();
      // ending side line
      ctx.beginPath();
      ctx.moveTo(lineStart, maxY);
      ctx.lineTo(lineEnd, maxY);
      ctx.stroke();
    }

    for (let i = 0; i < heightSteps; i++) {
      const lineStart = minY + i * 10;
      const lineEnd = Math.min(lineStart + 5, maxY);
      // beginning side line
      ctx.beginPath();
      ctx.moveTo(minX, lineStart);
      ctx.lineTo(minX, lineEnd);
      ctx.stroke();
      // ending side line
      ctx.beginPath();
      ctx.moveTo(maxX, lineStart);
      ctx.lineTo(maxX, lineEnd);
      ctx.stroke();
    }
  }

  drawDebuggingInfo(rs, nvg, t2 - t1, t1 - t0);
}
