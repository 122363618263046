import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'app/store';

export type TOUR_TYPE = 'onboarding';
export type TOUR_STATUS = 'idle' | 'active';

interface TourState {
  triggers: { [key in TOUR_TYPE]?: boolean };
  statuses: { [key in TOUR_TYPE]?: TOUR_STATUS };
}

const initialState: TourState = {
  triggers: {},
  statuses: {},
};

const tourSlice = createSlice({
  name: 'tourSlice',
  initialState,
  reducers: {
    setTrigger(
      state,
      action: PayloadAction<{ tour: TOUR_TYPE; value: boolean }>,
    ) {
      const { tour, value } = action.payload;
      state.triggers[tour] = value;
    },
    setStatus(
      state,
      action: PayloadAction<{ tour: TOUR_TYPE; value: TOUR_STATUS }>,
    ) {
      const { tour, value } = action.payload;
      state.statuses[tour] = value;
    },
  },
});

export const tourActions = tourSlice.actions;

export default tourSlice;
