import { Coordinate } from 'app/common_types/Coordinate';
import {
  LinkInstance,
  NodeInstance,
} from 'app/generated_types/SimulationModel';
import { renderConstants } from 'app/utils/renderConstants';

export function waitUntilVisible(selector: string) {
  return new Promise((resolve) => {
    if (document.querySelector(selector)) {
      resolve(true);
    } else {
      const observer = new MutationObserver(() => {
        if (document.querySelector(selector)) {
          resolve(document.querySelector(selector));
          observer.disconnect();
        }
      });

      observer.observe(document.body, {
        childList: true,
        subtree: true,
      });
    }
  });
}

export function waitUntilInvisible(selector: string) {
  return new Promise((resolve) => {
    let wasPresent = false;
    const observer = new MutationObserver(() => {
      if (document.querySelector(selector)) {
        wasPresent = true;
      }
      if (wasPresent && !document.querySelector(selector)) {
        resolve(document.querySelector(selector));
        observer.disconnect();
      }
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });
  });
}
export function getRelevantNodes(nodes: NodeInstance[]) {
  let signal1: NodeInstance | undefined;
  let signal2: NodeInstance | undefined;
  let gain: NodeInstance | undefined;
  let adder: NodeInstance | undefined;
  for (let i = 0; i < nodes.length; i++) {
    const node = nodes[i];
    if (node.name === 'Signal_1') {
      signal1 = node;
    }
    if (node.name === 'Signal_2') {
      signal2 = node;
    }
    if (node.name === 'Gain_0') {
      gain = node;
    }
    if (node.name === 'Adder_0') {
      adder = node;
    }
  }

  return {
    signal1,
    signal2,
    gain,
    adder,
  };
}

export function getRelevantLinks(
  links: LinkInstance[],
  signal1?: NodeInstance,
  signal2?: NodeInstance,
  adder?: NodeInstance,
  gain?: NodeInstance,
) {
  let signal1Link: LinkInstance | null = null;
  let signal2AdderLink: LinkInstance | null = null;
  let signal2GainLink: LinkInstance | null = null;
  let gain2AdderLink: LinkInstance | null = null;

  for (let i = 0; i < links.length; i++) {
    const link = links[i];
    if (link.src?.node === signal1?.uuid) {
      signal1Link = link;
    }
    if (link.src?.node === signal2?.uuid && link.dst?.node === adder?.uuid) {
      signal2AdderLink = link;
    }
    if (link.src?.node === signal2?.uuid && link.dst?.node === gain?.uuid) {
      signal2GainLink = link;
    }
    if (link.src?.node === gain?.uuid && link.dst?.node === adder?.uuid) {
      gain2AdderLink = link;
    }
  }

  return {
    signal1Link,
    signal2AdderLink,
    signal2GainLink,
    gain2AdderLink,
  };
}

export function calculateNodeCoords(camera: Coordinate, node: NodeInstance) {
  return {
    left: node.uiprops.x + camera.x + renderConstants.BLOCK_MIN_WIDTH,
    top: node.uiprops.y + camera.y + renderConstants.BLOCK_MIN_HEIGHT / 2,
  };
}

export function calculateVisualizerCoords(
  camera: Coordinate,
  node: NodeInstance,
) {
  const nodeCoords = calculateNodeCoords(camera, node);
  return {
    left: nodeCoords.left + renderConstants.SIGNAL_PLOTTER_WIDTH,
    top:
      nodeCoords.top -
      renderConstants.SIGNAL_PLOTTER_WIDTH / 2 -
      renderConstants.BLOCK_MIN_HEIGHT / 2,
  };
}
