/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Gain: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M26.625 15.25L7.47885 4.80665C6.81248 4.44318 6 4.92549 6 5.68455L6 15.25H2C1.58579 15.25 1.25 15.5858 1.25 16C1.25 16.4142 1.58579 16.75 2 16.75H6L6 26.3155C6 27.0745 6.81248 27.5568 7.47885 27.1934L26.625 16.75H30C30.4142 16.75 30.75 16.4142 30.75 16C30.75 15.5858 30.4142 15.25 30 15.25H26.625ZM24.8675 16L7.5 6.52682L7.5 25.4732L24.8675 16Z"
      fill={props.fill}
    />
  </svg>
);

Gain.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Gain;
