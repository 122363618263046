import React from 'react';
import { useLocation } from 'react-router-dom';
import Modal from './Modal';
import { ModalContext } from './modalTypes';
import { useModalState } from './useModal';

const modalContext = React.createContext<ModalContext>({
  showModal: false,
  allowOverflow: false,
  disableCloseControls: false,
  setDisableCloseControls: () => {},
  triggerModal: () => {},
  closeModal: () => {},
  content: '',
  title: '',
});

const ModalProvider = ({
  children,
}: {
  children: React.ReactChild;
}): React.ReactElement => {
  const location = useLocation();

  const [locationHasChanged, setLocationHasChanged] =
    React.useState<boolean>(false);

  const {
    showModal,
    allowOverflow,
    disableCloseControls,
    setDisableCloseControls,
    triggerModal,
    content,
    title,
    closeModal,
  } = useModalState();

  const contextValue = React.useMemo(
    () => ({
      showModal,
      allowOverflow,
      disableCloseControls,
      setDisableCloseControls,
      triggerModal,
      content,
      title,
      closeModal,
    }),
    [
      showModal,
      allowOverflow,
      disableCloseControls,
      setDisableCloseControls,
      triggerModal,
      content,
      title,
      closeModal,
    ],
  );

  // Monitor whether the URL changes
  React.useEffect(() => {
    setLocationHasChanged(true);
  }, [location]);

  // If the URL changes and a modal is open, close it.
  React.useEffect(() => {
    if (locationHasChanged) {
      if (showModal) {
        closeModal();
      }
      setLocationHasChanged(false);
    }
  }, [locationHasChanged, showModal, closeModal]);

  return (
    <modalContext.Provider value={contextValue}>
      <Modal />
      {children}
    </modalContext.Provider>
  );
};

export { ModalProvider, modalContext };
