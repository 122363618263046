import styled from '@emotion/styled/macro';
import { t } from '@lingui/macro';
import { useModelSimulationRequests } from 'app/api/useModelSimulationRequests';
import { useProjects } from 'app/api/useProjects';
import { useSimulations } from 'app/api/useSimulations';
import { JobKind } from 'app/apiGenerated/generatedApiTypes';
import { Project } from 'app/apiTransformers/convertAPIProjectToProject';
import {
  SimulationSummaryUI,
  getSimulationNameAsFormattedCreationDate,
} from 'app/apiTransformers/convertGetSimulationReadAll';
import React, { ReactElement } from 'react';
import ListItem from 'ui/Browser/ListItem';
import TooltipButton from 'ui/common/Button/TooltipButton';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import { Simulations, Stop } from 'ui/common/Icons/Standard';
import { Spinner } from 'ui/common/Spinner';
import { H5 } from 'ui/common/typography/Typography';
import { DownloadResultsButton } from 'ui/navbar/DownloadResultsButton';
import { SingleLineSpinnerWrapper } from 'ui/objectBrowser/sections/ReferenceSubmodelTreeContent';
import { v4 as uuid } from 'uuid';
import { NavbarMenuBackground } from './NavbarButtons';

const SimulationsMenuWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: ${({ theme }) => theme.spacing.normal};
  padding-bottom: ${({ theme }) => theme.spacing.small};
  padding-left: ${({ theme }) => theme.spacing.large};
  padding-right: ${({ theme }) => theme.spacing.normal};
  width: 100%;
`;

const TitleWrapper = styled(H5)`
  flex-grow: 1;
  color: ${({ theme }) => theme.colors.text.tertiary};
`;

const kindToString = (kind?: JobKind) => {
  switch (kind) {
    case 'Model':
      return 'simulation';
    case 'EnsembleSimulation':
      return 'ensemble';
    case 'Optimization':
      return 'optimization';
    case 'SindyFit':
      return 'sindy fit';
    case 'Validation':
      return 'compilation';
    default:
      return 'unknown';
  }
};

function getModelName(
  simulation: SimulationSummaryUI,
  projects: Project[] | undefined,
) {
  const project = projects?.find(
    (project: Project) => project.uuid === simulation.projectId,
  );
  let modelName: string;
  if (project) {
    const model = project.models.find(
      (model) => model.uuid === simulation.modelId,
    );
    if (model) {
      modelName = model.name;
    } else {
      modelName = t({
        id: 'simulationSummary.ModelNotFound.modelName',
        message: 'Unknown Model',
      });
    }
  } else {
    modelName = t({
      id: 'simulationSummary.ProjectNotFound.modelName',
      message: 'Model From Unknown Project',
    });
  }
  return modelName;
}

function getSimulationName(simulation: SimulationSummaryUI, modelName: string) {
  const formattedCreationDate =
    getSimulationNameAsFormattedCreationDate(simulation);
  const kind = kindToString(simulation.kind);
  return `${formattedCreationDate} ${modelName} (${kind})`;
}

const SimulationList = ({
  simulations,
  projects,
}: {
  simulations?: SimulationSummaryUI[];
  projects?: Project[];
}): ReactElement => {
  const { stopSimulation } = useModelSimulationRequests();

  const IconButton = React.useCallback(
    (simulation: SimulationSummaryUI, modelName: string) => {
      switch (simulation.status) {
        case 'created':
        case 'pending':
        case 'waiting_for_resources':
        case 'compilation_in_progress':
        case 'post_processing_in_progress':
        case 'simulation_in_progress':
          return (
            <TooltipButton
              tooltip={t({
                id: 'simulationSummary.simulationButton.Stop.tooltip',
                message: 'Stop simulation',
              })}
              Icon={Stop}
              onClick={() => stopSimulation(simulation.id, uuid())}
              variant={ButtonVariants.LargeTertiary}
            />
          );

        case 'failed':
        case 'cancelled':
        case 'completed':
          // TODO: results for ensemble sims

          return (
            <DownloadResultsButton
              simulationId={simulation.id}
              modelId={simulation.modelId}
              modelName={modelName}
              areResultsAvailable={
                simulation.areResultsAvailable && simulation.kind === 'Model'
              }
              signalNames={['continuous_results.csv']}
            />
          );
      }
    },
    [stopSimulation],
  );

  const simList = simulations?.map((simulation) => {
    const modelName = getModelName(simulation, projects);

    return (
      <ListItem
        Icon={Simulations}
        key={simulation.id}
        IconButton={() => IconButton(simulation, modelName)}>
        {getSimulationName(simulation, modelName)}
      </ListItem>
    );
  });

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {simList && simList.length > 0 ? (
        simList
      ) : (
        <ListItem>
          {t({
            id: 'simulationSummary.noSimulations',
            message: 'No simulations found',
          })}
        </ListItem>
      )}
    </>
  );
};

const SimulationsMenu: React.FC = () => {
  const { projects, isLoadingProjects } = useProjects();
  const { isLoadingSimulations, simulations } = useSimulations();

  const isLoading = isLoadingProjects || isLoadingSimulations || false;

  const runningSims = SimulationList({
    simulations: simulations?.runningSimulations,
    projects,
  });
  const queuedSims = SimulationList({
    simulations: simulations?.queuedSimulations,
    projects,
  });
  const pastSims = SimulationList({
    simulations: simulations?.pastSimulations,
    projects,
  });

  return (
    <NavbarMenuBackground wide>
      {isLoading ? (
        <SingleLineSpinnerWrapper>
          <Spinner />
        </SingleLineSpinnerWrapper>
      ) : (
        <>
          <SimulationsMenuWrapper>
            <TitleWrapper>
              {t({
                id: 'navBar.runningSimulationsMenu.title',
                message: 'Running Simulations',
              })}
            </TitleWrapper>
          </SimulationsMenuWrapper>
          {runningSims}

          <SimulationsMenuWrapper>
            <TitleWrapper>
              {t({
                id: 'navBar.queuedSimulationsMenu.title',
                message: 'Queued Simulations',
              })}
            </TitleWrapper>
          </SimulationsMenuWrapper>
          {queuedSims}

          <SimulationsMenuWrapper>
            <TitleWrapper>
              {t({
                id: 'navBar.pastSimulationsMenu.title',
                message: 'Past Simulations',
              })}
            </TitleWrapper>
          </SimulationsMenuWrapper>
          {pastSims}
        </>
      )}
    </NavbarMenuBackground>
  );
};

export default SimulationsMenu;
