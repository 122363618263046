import { BrowserTracing } from '@sentry/browser';
import { CaptureConsole } from '@sentry/integrations';
import * as Sentry from '@sentry/react';
import { globalSessionId } from 'app/apiGenerated/emptyApi';
import {
  DISABLE_SENTRY,
  SENTRY_HACK_FOR_MATPLOTLIB,
} from 'app/config/globalApplicationConfig';

// matplotlib (from pyodide) is broken for some really weird reason when
// sentry applies its TryCatch integration.
// Note that for some reason, jupyterlite does not suffer from the issue.
const defaultIntegrations = Sentry.defaultIntegrations;
const defaultIntegrationsWithoutBrokenMatplotlib = defaultIntegrations.filter(
  (integration) => integration.name !== 'TryCatch',
);

export function initErrorTracking({
  disableBrokenIntegrationForMatplotlib = SENTRY_HACK_FOR_MATPLOTLIB,
}: { disableBrokenIntegrationForMatplotlib?: boolean } = {}) {
  if (DISABLE_SENTRY) return;

  const envName = process.env.REACT_APP_ENVIRONMENT || 'prod';
  const nameToSentryEnv: Record<string, string> = {
    prod: 'production',
    test: 'test',
    dev: 'dev',
    local: 'local',
  };
  const environment = nameToSentryEnv[envName];

  Sentry.init({
    dsn: 'https://702c95ba5d0147028a99fc940badeb22@o1273799.ingest.sentry.io/6468400',
    environment,

    defaultIntegrations: false,
    integrations: [
      new BrowserTracing(),
      new CaptureConsole({ levels: ['error'] }),
      ...(disableBrokenIntegrationForMatplotlib
        ? defaultIntegrationsWithoutBrokenMatplotlib
        : defaultIntegrations),
    ],

    tunnel: '/api/v0/sentry',

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,

    // Enable debug if you need to test Sentry from localhost
    // You'll also need to change the "Inbound Filters" and the
    // "Allowed Domains" in the Sentry project settings.
    // debug: true,
  });

  Sentry.configureScope((scope) => {
    scope.setTag('session_id', globalSessionId);
  });
}
