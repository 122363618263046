/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  className?: string;
}

const Outport: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.284 19.336C9.652 19.336 9.108 19.176 8.652 18.856C8.204 18.536 7.888 18.092 7.704 17.524C7.568 17.092 7.5 16.636 7.5 16.156C7.5 15.644 7.564 15.188 7.692 14.788C7.876 14.228 8.196 13.792 8.652 13.48C9.108 13.168 9.656 13.012 10.296 13.012C10.904 13.012 11.428 13.168 11.868 13.48C12.316 13.792 12.632 14.224 12.816 14.776C12.96 15.16 13.032 15.616 13.032 16.144C13.032 16.648 12.964 17.104 12.828 17.512C12.644 18.08 12.328 18.528 11.88 18.856C11.432 19.176 10.9 19.336 10.284 19.336ZM10.284 18.136C10.58 18.136 10.832 18.052 11.04 17.884C11.256 17.716 11.412 17.48 11.508 17.176C11.588 16.912 11.628 16.576 11.628 16.168C11.628 15.736 11.588 15.4 11.508 15.16C11.42 14.856 11.268 14.624 11.052 14.464C10.836 14.296 10.572 14.212 10.26 14.212C9.964 14.212 9.708 14.296 9.492 14.464C9.276 14.624 9.12 14.856 9.024 15.16C8.944 15.44 8.904 15.776 8.904 16.168C8.904 16.56 8.944 16.896 9.024 17.176C9.112 17.48 9.264 17.716 9.48 17.884C9.704 18.052 9.972 18.136 10.284 18.136Z"
      fill={props.fill}
    />
    <path
      d="M17.972 13.252C17.972 13.212 17.984 13.18 18.008 13.156C18.04 13.124 18.076 13.108 18.116 13.108H19.208C19.248 13.108 19.28 13.124 19.304 13.156C19.336 13.18 19.352 13.212 19.352 13.252V19.096C19.352 19.136 19.336 19.172 19.304 19.204C19.28 19.228 19.248 19.24 19.208 19.24H18.116C18.076 19.24 18.04 19.228 18.008 19.204C17.984 19.172 17.972 19.136 17.972 19.096V18.712C17.972 18.688 17.964 18.672 17.948 18.664C17.932 18.656 17.916 18.664 17.9 18.688C17.572 19.112 17.08 19.324 16.424 19.324C15.816 19.324 15.304 19.148 14.888 18.796C14.48 18.444 14.276 17.944 14.276 17.296V13.252C14.276 13.212 14.288 13.18 14.312 13.156C14.344 13.124 14.38 13.108 14.42 13.108H15.524C15.564 13.108 15.596 13.124 15.62 13.156C15.652 13.18 15.668 13.212 15.668 13.252V16.9C15.668 17.276 15.764 17.576 15.956 17.8C16.156 18.024 16.432 18.136 16.784 18.136C17.144 18.136 17.432 18.024 17.648 17.8C17.864 17.576 17.972 17.276 17.972 16.9V13.252Z"
      fill={props.fill}
    />
    <path
      d="M24.0922 14.092C24.0922 14.132 24.0762 14.168 24.0442 14.2C24.0202 14.224 23.9882 14.236 23.9482 14.236H22.7242C22.6842 14.236 22.6642 14.256 22.6642 14.296V17.188C22.6642 17.492 22.7282 17.712 22.8562 17.848C22.9922 17.984 23.2042 18.052 23.4922 18.052H23.8522C23.8922 18.052 23.9242 18.068 23.9482 18.1C23.9802 18.124 23.9962 18.156 23.9962 18.196V19.096C23.9962 19.184 23.9482 19.236 23.8522 19.252L23.2282 19.264C22.5962 19.264 22.1242 19.156 21.8122 18.94C21.5002 18.724 21.3402 18.316 21.3322 17.716V14.296C21.3322 14.256 21.3122 14.236 21.2722 14.236H20.5882C20.5482 14.236 20.5122 14.224 20.4802 14.2C20.4562 14.168 20.4442 14.132 20.4442 14.092V13.252C20.4442 13.212 20.4562 13.18 20.4802 13.156C20.5122 13.124 20.5482 13.108 20.5882 13.108H21.2722C21.3122 13.108 21.3322 13.088 21.3322 13.048V11.644C21.3322 11.604 21.3442 11.572 21.3682 11.548C21.4002 11.516 21.4362 11.5 21.4762 11.5H22.5202C22.5602 11.5 22.5922 11.516 22.6162 11.548C22.6482 11.572 22.6642 11.604 22.6642 11.644V13.048C22.6642 13.088 22.6842 13.108 22.7242 13.108H23.9482C23.9882 13.108 24.0202 13.124 24.0442 13.156C24.0762 13.18 24.0922 13.212 24.0922 13.252V14.092Z"
      fill={props.fill}
    />
  </svg>
);

Outport.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  className: '',
};

export default Outport;
