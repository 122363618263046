/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  stroke?: any;
  strokeWidth?: any;
  strokeLinecap?: any;
  strokeLinejoin?: any;
  className?: string;
}

const Bandlimitednoise: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1 14.6917L1.75 13.457L2.5 11.0929L3.25 14.501L4 19.5362L4.75 15.8587L5.5 18.944L6.25 12.057L7 7.13143L7.75 27L8.5 23.2368L9.25 12.3052L10 11.1866L10.75 12.6836L11.5 17.8463L12.25 12.6744L13 13.4499L13.75 16.458L14.5 7.73546L15.25 19.2574L16 9.08902L16.75 14.3652L17.5 15.7133L18.25 23.3672L19 14.7217L19.75 16.923L20.5 5L21.25 8.59058L22 7.53124L22.75 13.9353L23.5 7.24232L24.25 21.317L25 10.0637L25.75 16.094L26.5 25.8745L27.25 7.38622L28 11.2654L28.75 22.872L29.5 8.69138L30.25 7.61665L31 13.8085"
      stroke={props.stroke}
      strokeWidth={props.strokeWidth}
      strokeLinecap={props.strokeLinecap}
      strokeLinejoin={props.strokeLinejoin}
    />
  </svg>
);

Bandlimitednoise.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: 'none',
  xmlns: 'http://www.w3.org/2000/svg',
  stroke: '#1D3131',
  strokeWidth: '1.5',
  strokeLinecap: 'round',
  strokeLinejoin: 'round',
  className: '',
};

export default Bandlimitednoise;
