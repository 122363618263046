import { Coordinate } from 'app/common_types/Coordinate';
import React from 'react';

export type GenericComponentType =
  | React.FC<any>
  | React.LazyExoticComponent<React.FC<any>>;

export type DragStateType<C extends GenericComponentType> =
  | {
      dragging: false;
    }
  | {
      dragging: true;
      category: string;
      dragPreviewComp: C;
      dragPreviewCompProps: React.ComponentProps<C>;
      cursorElementOffset: Coordinate;
      extraData: any;
    };

export type DragContextValueType<C extends GenericComponentType> = {
  startDrag: (
    category: string,
    component: C,
    componentProps: React.ComponentProps<C>,
    cursorElementOffset: Coordinate,
    extraData?: any,
  ) => void;
  endDrag: () => void;
  state: DragStateType<C>;
};

const initialContextState: DragStateType<GenericComponentType> = {
  dragging: false,
};

export const DragContext = React.createContext<
  DragContextValueType<GenericComponentType>
>({
  startDrag: (
    _cat: string,
    _comp: GenericComponentType,
    _props: React.ComponentProps<GenericComponentType>,
    _coord: Coordinate,
    _extra?: any,
  ) => {},
  endDrag: () => {},
  state: initialContextState,
});

export const DragProvider = ({ children }: { children: React.ReactNode }) => {
  const [state, setStateValue] =
    React.useState<DragStateType<GenericComponentType>>(initialContextState);

  const startDrag = React.useCallback(
    <C extends GenericComponentType>(
      category: string,
      component: C,
      props: React.ComponentProps<C>,
      offset: Coordinate,
      extraData?: any,
    ) => {
      setStateValue({
        category,
        dragging: true,
        dragPreviewComp: component,
        dragPreviewCompProps: props,
        cursorElementOffset: offset,
        extraData,
      });
    },
    [setStateValue],
  );

  const endDrag = React.useCallback(
    () => setStateValue({ dragging: false }),
    [setStateValue],
  );

  const contextValue = React.useMemo(
    () => ({
      startDrag,
      endDrag,
      state,
    }),
    [startDrag, endDrag, state],
  );

  return (
    <DragContext.Provider value={contextValue}>{children}</DragContext.Provider>
  );
};
