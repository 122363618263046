/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const MatrixoperatorConcatenationvertical: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9 5.25C8.30964 5.25 7.75 5.80964 7.75 6.5V25.5C7.75 26.1904 8.30965 26.75 9 26.75H11.5C11.9142 26.75 12.25 26.4142 12.25 26C12.25 25.5858 11.9142 25.25 11.5 25.25H9.25V6.75H11.5C11.9142 6.75 12.25 6.41421 12.25 6C12.25 5.58579 11.9142 5.25 11.5 5.25H9Z"
      fill={props.fill}
    />
    <path
      d="M24.25 6.5C24.25 5.80964 23.6904 5.25 23 5.25H20.5C20.0858 5.25 19.75 5.58579 19.75 6C19.75 6.41421 20.0858 6.75 20.5 6.75H22.75V25.25H20.5C20.0858 25.25 19.75 25.5858 19.75 26C19.75 26.4142 20.0858 26.75 20.5 26.75H23C23.6904 26.75 24.25 26.1904 24.25 25.5V6.5Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M17.928 14.88C17.952 14.96 18.004 15 18.084 15H19.284C19.372 15 19.416 14.964 19.416 14.892C19.416 14.868 19.412 14.848 19.404 14.832L16.788 6.72C16.764 6.64 16.712 6.6 16.632 6.6H15.132C15.052 6.6 15 6.64 14.976 6.72L12.372 14.832C12.356 14.888 12.36 14.932 12.384 14.964C12.408 14.988 12.444 15 12.492 15H13.692C13.772 15 13.824 14.96 13.848 14.88L14.244 13.596C14.26 13.564 14.28 13.548 14.304 13.548H17.472C17.496 13.548 17.516 13.564 17.532 13.596L17.928 14.88ZM14.664 12.456C14.624 12.456 14.608 12.432 14.616 12.384L15.84 8.364C15.848 8.332 15.86 8.316 15.876 8.316C15.892 8.316 15.904 8.332 15.912 8.364L17.148 12.384C17.164 12.432 17.148 12.456 17.1 12.456H14.664Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M18.068 20.692C18.02 20.668 18.02 20.644 18.068 20.62C18.804 20.292 19.172 19.708 19.172 18.868C19.172 18.108 18.936 17.54 18.464 17.164C17.992 16.788 17.32 16.6 16.448 16.6H13.52C13.48 16.6 13.444 16.616 13.412 16.648C13.388 16.672 13.376 16.704 13.376 16.744V24.856C13.376 24.896 13.388 24.932 13.412 24.964C13.444 24.988 13.48 25 13.52 25H16.544C17.36 25 18.02 24.804 18.524 24.412C19.036 24.012 19.292 23.432 19.292 22.672C19.292 21.736 18.884 21.076 18.068 20.692ZM14.768 17.86C14.768 17.82 14.788 17.8 14.828 17.8H16.448C16.872 17.8 17.204 17.908 17.444 18.124C17.692 18.332 17.816 18.62 17.816 18.988C17.816 19.348 17.692 19.632 17.444 19.84C17.204 20.04 16.872 20.14 16.448 20.14H14.828C14.788 20.14 14.768 20.12 14.768 20.08V17.86ZM17.528 23.452C17.288 23.676 16.96 23.788 16.544 23.788H14.828C14.788 23.788 14.768 23.768 14.768 23.728V21.352C14.768 21.312 14.788 21.292 14.828 21.292H16.52C16.944 21.292 17.28 21.404 17.528 21.628C17.776 21.844 17.9 22.148 17.9 22.54C17.9 22.924 17.776 23.228 17.528 23.452Z"
      fill={props.fill}
    />
  </svg>
);

MatrixoperatorConcatenationvertical.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default MatrixoperatorConcatenationvertical;
