import { useAppSelector } from 'app/hooks';
import React from 'react';
import SimulationOutputDetails from 'ui/modelEditor/SimulationOutputDetails';
import SimulationRecordingDetails from 'ui/modelEditor/SimulationRecordingDetails';
import { DetailsContainer } from './DetailsComponents';
import DeveloperOptionsDetails from './DeveloperOptionsDetails';

const ModelOutputSidebar: React.FC = () => {
  const topLevelModelType = useAppSelector(
    (state) => state.submodels.topLevelModelType,
  );

  return topLevelModelType === 'Model' ? (
    <DetailsContainer>
      <SimulationOutputDetails />
      <SimulationRecordingDetails />
      <DeveloperOptionsDetails />
    </DetailsContainer>
  ) : null;
};

export default ModelOutputSidebar;
