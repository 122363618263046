import { PortSide } from 'app/common_types/PortTypes';
import { blockClassLookup } from 'app/generated_blocks';
import { NodeInstance, Port } from 'app/generated_types/SimulationModel';
import {
  nodeTypeIsCode,
  nodeTypeIsDynamicBlock,
  nodeTypeIsSubdiagram,
} from 'app/helpers';
import { getPortConditions } from 'app/utils/portConditionUtils';

// Note: max count = -1 means unlimited number of ports
export const maybeUnlimited = (n?: number): number | undefined =>
  n === -1 ? Infinity : n;

export const canRenamePort = (
  selectedNode: NodeInstance,
  port?: Port,
): boolean => {
  if (selectedNode.type === 'core.MuJoCo') {
    return !['control', 'qpos', 'qvel', 'act', 'sensor_data', 'video'].includes(
      port?.name || '',
    );
  }

  return (
    nodeTypeIsCode(selectedNode.type) ||
    selectedNode.type === 'core.StateMachine' ||
    selectedNode.type === 'core.Mux' ||
    selectedNode.type === 'core.Demux' ||
    selectedNode.type === 'core.PyTorch' ||
    selectedNode.type === 'core.TensorFlow'
  );
};

const getDynamicPortCount = (selectedNode: NodeInstance, side: PortSide) => {
  const ports =
    side === PortSide.Input ? selectedNode.inputs : selectedNode.outputs;
  return ports.filter(
    (port) => port.kind !== 'conditional' && port.kind !== 'static',
  ).length;
};

export const canDeletePort = (
  selectedNode: NodeInstance,
  minDynamicPortCount: number,
  port: Port,
  side: PortSide,
): boolean => {
  if (
    nodeTypeIsSubdiagram(selectedNode.type) ||
    nodeTypeIsDynamicBlock(selectedNode)
  )
    return false;

  if (port.kind === 'conditional' || port.kind === 'static') {
    return false;
  }

  const dynamicPortCount = getDynamicPortCount(selectedNode, side);
  return dynamicPortCount > minDynamicPortCount;
};

export const canAddPort = (
  selectedNode: NodeInstance,
  side: PortSide,
): boolean => {
  const portConditions = getPortConditions(selectedNode.type);
  let canAddDynamicPorts = true;
  portConditions?.forEach((portCondition) => {
    if (!portCondition || !portCondition.appliesToDynamicPorts) return;
    if (portCondition.side !== side) return;

    if (!portCondition.shouldHavePort(selectedNode)) {
      canAddDynamicPorts = false;
    }
  });
  if (!canAddDynamicPorts) return false;

  const blockClass = blockClassLookup(selectedNode.type);
  if (!blockClass) return false;

  let dynamicPortMaxCount;
  let ports: Port[];

  if (side === PortSide.Input) {
    dynamicPortMaxCount = blockClass.ports.inputs?.dynamic?.max_count;
    ports = selectedNode.inputs;
  } else if (side === PortSide.Output) {
    dynamicPortMaxCount = blockClass.ports.outputs?.dynamic?.max_count;
    ports = selectedNode.outputs;
  } else {
    return false;
  }

  // Check if we've reached the limit for dynamic ports specifically.
  const dynamicPortCount = ports.filter((p) => p.kind === 'dynamic').length;
  if (dynamicPortCount >= (maybeUnlimited(dynamicPortMaxCount) || 0)) {
    return false;
  }

  return true;
};
