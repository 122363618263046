import styled from '@emotion/styled';
import { t } from '@lingui/macro';
import { useProject } from 'app/api/useProject';
import {
  useGetFileDownloadByUuidQuery,
  useGetFileReadByUuidQuery,
  usePutFileContentUpdateByUuidMutation,
} from 'app/apiGenerated/generatedApi';
import { useAppDispatch } from 'app/hooks';
import { NavbarContext, uiFlagsActions } from 'app/slices/uiFlagsSlice';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { CodeEditor, CodeLanguage } from 'ui/codeEditor/CodeEditor';
import Breadcrumb from 'ui/common/Breadcrumb/Breadcrumb';
import Button from 'ui/common/Button/Button';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import {
  ArrowRight,
  Data,
  Eye,
  File,
  Project,
  PythonFile,
} from 'ui/common/Icons/Standard';
import { Spinner } from 'ui/common/Spinner';
import { AppContentWithFooterWrapper } from 'ui/common/layout/appLayout';
import { useNotifications } from 'ui/common/notifications/useNotifications';
import { fetchFileContents } from 'util/fileUtils';

const Loader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: absolute;
  background: ${({ theme }) => theme.colors.grey[10]};
  top: 0;
  left: 0;
`;

const EditorPageContentWrap = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.grey['5']};
  align-items: center;
`;

const EditorCodeWrap = styled.div`
  width: 900px;
  height: 100%;
  padding-bottom: ${({ theme }) => theme.spacing.normal};

  &.stretch {
    width: 100%;
    padding-left: ${({ theme }) => theme.spacing.normal};
    padding-right: ${({ theme }) => theme.spacing.normal};
  }
`;

const TopBar = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  padding: ${({ theme }) => theme.spacing.normal};
  margin-bottom: ${({ theme }) => theme.spacing.normal};
`;

const ButtonWrapper = styled.div`
  pointer-events: all;
`;

const Spacer = styled.div`
  flex: 1;
  height: ${({ theme }) => theme.spacing.xxlarge};
`;

// Supports various text/code files like md, txt, csv and python.
export const CodeEditorPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { createShowError } = useNotifications();

  React.useEffect(() => {
    dispatch(uiFlagsActions.setNavbarContext(NavbarContext.ScriptEditor));
    return () => {
      dispatch(uiFlagsActions.setNavbarContext(NavbarContext.None));
    };
  }, [dispatch]);

  const { projectId, fileUuid } = useParams();
  const { project } = useProject();

  const [callFileContentUpdateApi] = usePutFileContentUpdateByUuidMutation();

  const [loaded, setLoaded] = React.useState<boolean>(false);

  const [initiallyLoadedScriptValue, setInitialScriptContents] = React.useState<
    string | undefined
  >();

  React.useEffect(() => {
    setLoaded(false);
  }, [projectId, fileUuid]);

  const { data: fileProps } = useGetFileReadByUuidQuery(
    {
      projectUuid: projectId || '',
      fileUuid: fileUuid || '',
    },
    {
      skip: !projectId || !fileUuid,
    },
  );

  const downloadData = useGetFileDownloadByUuidQuery(
    {
      projectUuid: projectId || '',
      fileUuid: fileUuid || '',
    },
    {
      skip: !projectId || !fileUuid,
    },
  );

  const filePath = fileProps?.summary.name.split('/');
  const dirname = filePath?.slice(0, -1);
  const fileName = filePath?.pop() || '';
  const fileExt = fileName?.split('.').pop()?.toLowerCase() || '';

  const LANGUAGES: Record<string, CodeLanguage> = {
    py: 'python',
    md: 'markdown',
    txt: 'plaintext',
    csv: 'plaintext',
  };
  const language = LANGUAGES[fileExt] || 'text';
  const stretch = fileExt === 'csv';

  React.useEffect(() => {
    if (!downloadData.data) return;

    fetchFileContents(downloadData.data.download_link, 5 * 1024 * 1024)
      .then((txt) => {
        setInitialScriptContents(txt);
        setLoaded(true);
      })
      .catch((e) => {
        createShowError(t`Could not load file in editor:`)(e);
        navigate(`/projects/${projectId}`);
      });
  }, [downloadData.data, projectId, navigate, createShowError]);

  const codeChangeTimerId = React.useRef<number | null>(null);
  const onChangeCode = async (newCode: string | undefined) => {
    if (projectId && fileUuid && newCode) {
      if (codeChangeTimerId.current) {
        window.clearTimeout(codeChangeTimerId.current);
      }

      codeChangeTimerId.current = window.setTimeout(async () => {
        const body = new Blob([newCode]);
        const request = {
          projectUuid: projectId,
          fileUuid,
          fileContentUpdateRequest: { size: body.size },
        };

        const uploadApiData = await callFileContentUpdateApi(request).unwrap();
        if (uploadApiData) {
          const method = 'PUT';
          const headers: { [key: string]: string } = {
            'Content-Type': uploadApiData.summary.content_type,
          };
          const url = uploadApiData.put_presigned_url;
          return fetch(url, { method, headers, body });
        }
      }, 100);
    }
  };

  return (
    <AppContentWithFooterWrapper>
      {!loaded ? (
        <Loader>
          <Spinner width="36" height="36" />
        </Loader>
      ) : (
        <EditorPageContentWrap>
          <TopBar>
            <Breadcrumb stretch>
              <Button
                variant={ButtonVariants.SmallTertiary}
                onClick={() => navigate(`/projects/${projectId}`)}>
                <Project />
                {project?.title}
              </Button>
              {dirname?.map((p, i) => {
                const subpath = dirname.slice(0, i + 1).join('/');
                return (
                  <Button
                    key={`${dirname}-${i}`}
                    variant={ButtonVariants.SmallTertiary}
                    onClick={() =>
                      navigate(`/projects/${projectId}?path=${subpath}`)
                    }>
                    <ArrowRight />
                    {p}
                  </Button>
                );
              }) || []}
              <Button variant={ButtonVariants.SmallTertiary}>
                {fileExt === 'py' ? (
                  <PythonFile />
                ) : fileExt === 'csv' ? (
                  <Data />
                ) : (
                  <File />
                )}
                {fileName}
              </Button>
            </Breadcrumb>
            <Spacer />
            {fileExt === 'md' && (
              <ButtonWrapper>
                <Button
                  variant={ButtonVariants.LargeSecondary}
                  onClick={() =>
                    navigate(`/projects/${projectId}/markdown/${fileUuid}`)
                  }>
                  <Eye />
                  {t`Preview`}
                </Button>
              </ButtonWrapper>
            )}
          </TopBar>
          <EditorCodeWrap className={stretch ? 'stretch' : ''}>
            <CodeEditor
              noTopMargin
              noBreadcrumb
              defaultValue={initiallyLoadedScriptValue}
              onChangeCode={onChangeCode}
              editorDisplayPath={fileName || ''}
              language={language}
            />
          </EditorCodeWrap>
        </EditorPageContentWrap>
      )}
    </AppContentWithFooterWrapper>
  );
};
