import { Coordinate } from 'app/common_types/Coordinate';
import { FakeSegmentType } from 'app/common_types/SegmentTypes';
import {
  FakeTappedSegmentType,
  LinkInstance,
  LinkSegmentType,
  LinkTypeType,
} from 'app/generated_types/SimulationModel';
import {
  ModelState,
  getCurrentlyEditingModelFromState,
} from 'app/modelState/ModelState';
import {
  reifyLink_mut,
  removeAlignedLinkSegments,
  setLinkSourceAndDependentTapSources,
} from 'app/utils/linkMutationUtils';
import {
  LinkVertex,
  VertexSegmentType,
  linkToRenderData,
} from 'app/utils/linkToRenderData';
import { LinkPayload, fakeSegmentStringMap } from 'app/utils/modelDataUtils';
import { getVisualNodeWidth } from 'ui/modelRendererInternals/getVisualNodeWidth';
import { rendererState } from 'ui/modelRendererInternals/modelRenderer';
import { pointToLineDistance } from 'util/pointToLineDistance';
import { v4 as makeUuid } from 'uuid';
import { getLinkTapCoordinate } from './tapLinkToRenderData';

export function setLinkTap(
  state: ModelState,
  linkUuid: string,
  tapped_link_uuid: string,
  tapped_segment:
    | {
        segment_type: 'real';
        tapped_segment_index: number;
        tapped_segment_direction: 'horiz' | 'vert';
      }
    | {
        segment_type: FakeTappedSegmentType;
        tapped_segment_direction: 'horiz' | 'vert';
      },
  tap_coordinate: number,
) {
  const model = getCurrentlyEditingModelFromState(state);
  if (!model) return;

  const link = model.links.find((l) => l.uuid === linkUuid);
  const tappedLink = model.links.find((l) => l.uuid === tapped_link_uuid);

  if (!link || !tappedLink) return;

  link.uiprops.link_type = {
    connection_method: 'link_tap',
    tapped_link_uuid,
    tapped_segment,
    tap_coordinate,
  };

  setLinkSourceAndDependentTapSources(
    link.uuid,
    tappedLink.src,
    model.links,
    rendererState?.refs?.current?.linksIndexLUT,
  );
}

export function insertNodeIntoLink(
  state: ModelState,
  linkUuid: string,
  nodeUuid: string,
  insertPointVertexData: LinkVertex,
  nextVertexData: LinkVertex,
) {
  if (!rendererState) return;

  const model = getCurrentlyEditingModelFromState(state);
  if (!model) return;

  const node = model.nodes.find((n) => n.uuid === nodeUuid);
  const link = model.links.find((l) => l.uuid === linkUuid);

  if (!node || !link) return;

  const nodeWidth = getVisualNodeWidth(node);

  const taps = model.links.filter(
    (mt) =>
      mt.uiprops.link_type.connection_method === 'link_tap' &&
      mt.uiprops.link_type.tapped_link_uuid === linkUuid,
  );

  const allTapCoordinates: { [k: string]: Coordinate } = taps.reduce(
    (acc, tap: LinkInstance) => {
      if (rendererState) {
        return {
          ...acc,
          [tap.uuid]: getLinkTapCoordinate(rendererState, tap),
        };
      }

      return acc;
    },
    {},
  );

  const inputLinkTaps = [];
  const outputLinkTaps = [];
  let shouldAbort = false;

  // gather taps that should be beofre or after the inserted block
  // and abort if the block is on top of any taps
  for (let i = 0; i < taps.length; i++) {
    const currentTap = taps[i];
    if (
      currentTap.uiprops.link_type.connection_method !== 'link_tap' ||
      currentTap.uiprops.link_type.tapped_segment.segment_type !== 'real'
    ) {
      continue;
    }

    if (insertPointVertexData.segmentType === VertexSegmentType.Fake) {
      // it's not possible to enter a state in the app
      // with a tap actually on a fake segment,
      // so we can just assume to put them all after or before
      // based on what type of fake segment it is.
      // S-shaped segments are irrelevant because you can't have
      // taps on a fake S-shaped link. (it gets reified after tapping)
      switch (insertPointVertexData.fakeSegmentType) {
        case FakeSegmentType.Start:
          outputLinkTaps.push(currentTap);
          break;
        case FakeSegmentType.End:
          inputLinkTaps.push(currentTap);
          break;
      }
    } else if (insertPointVertexData.segmentType === VertexSegmentType.Real) {
      const currentTapSegmentIdx =
        currentTap.uiprops.link_type.tapped_segment.tapped_segment_index;
      const currentTapOrientation =
        currentTap.uiprops.link_type.tapped_segment.tapped_segment_direction;
      const currentTapCoord = currentTap.uiprops.link_type.tap_coordinate;

      if (currentTapSegmentIdx === insertPointVertexData.segmentIndex) {
        if (currentTapOrientation === 'horiz') {
          if (currentTapCoord < node.uiprops.x) {
            if (node.uiprops.directionality === 'left') {
              outputLinkTaps.push(currentTap);
            } else {
              inputLinkTaps.push(currentTap);
            }
          } else if (currentTapCoord > node.uiprops.x + nodeWidth) {
            if (node.uiprops.directionality === 'left') {
              inputLinkTaps.push(currentTap);
            } else {
              outputLinkTaps.push(currentTap);
            }
          } else {
            // abort everything if we try to insert on top of a tap
            shouldAbort = true;
          }
        } else {
          // NOTE: we can't insert a node into a vertical segment
          // because we don't have vertically oriented nodes (yet?)
          shouldAbort = true;
        }
      } else if (currentTapSegmentIdx < insertPointVertexData.segmentIndex) {
        if (node.uiprops.directionality === 'left') {
          outputLinkTaps.push(currentTap);
        } else {
          inputLinkTaps.push(currentTap);
        }
      } else if (currentTapSegmentIdx > insertPointVertexData.segmentIndex) {
        if (node.uiprops.directionality === 'left') {
          inputLinkTaps.push(currentTap);
        } else {
          outputLinkTaps.push(currentTap);
        }
      }
    }
  }

  if (shouldAbort) return;

  let sourceSegments = link.uiprops.segments;
  let destSegments: typeof link.uiprops.segments = [];

  if (insertPointVertexData.segmentType === VertexSegmentType.Real) {
    sourceSegments = link.uiprops.segments.slice(
      0,
      insertPointVertexData.segmentIndex,
    );
    destSegments = link.uiprops.segments.slice(
      insertPointVertexData.segmentIndex + 1,
      link.uiprops.segments.length,
    );
  } else if (insertPointVertexData.segmentType === VertexSegmentType.Fake) {
    switch (insertPointVertexData.fakeSegmentType) {
      case FakeSegmentType.Start:
        sourceSegments = [];
        destSegments = link.uiprops.segments;
        break;
      case FakeSegmentType.End:
        sourceSegments = link.uiprops.segments;
        destSegments = [];
        break;
    }
  }

  const newOutputLinkUuid = makeUuid();
  const newOutputLink: LinkInstance = {
    uuid: newOutputLinkUuid,
    src: {
      node: nodeUuid,
      port: 0,
    },
    dst: link.dst
      ? {
          node: link.dst.node,
          port: link.dst.port,
        }
      : undefined,
    uiprops: {
      ...link.uiprops,
      link_type: { connection_method: 'direct_to_block' },
      segments: destSegments,
    },
  };

  model.links.push(newOutputLink);

  link.uiprops.segments = sourceSegments;
  if (!link.dst) {
    link.dst = {
      node: nodeUuid,
      port: 0,
    };
  } else {
    link.dst.node = nodeUuid;
    link.dst.port = 0;
  }

  // do not pass in the actual taps argument
  // to these removeAlignedLinkSegments calls
  // so that we can properly simplify the underlying links.
  // taps will be re-added right after.
  const inputLinkSrc = model.nodes.find((n) => n.uuid === link.src?.node);
  const outputLinkDst = model.nodes.find(
    (n) => n.uuid === newOutputLink.dst?.node,
  );
  removeAlignedLinkSegments(link, inputLinkSrc, node, []);
  removeAlignedLinkSegments(newOutputLink, node, outputLinkDst, []);
  reifyLink_mut(link, false, true);
  reifyLink_mut(link, true, true);
  reifyLink_mut(newOutputLink, false, true);
  reifyLink_mut(newOutputLink, true, true);

  // move taps where they belong

  const inputLinkRenderData = linkToRenderData(
    rendererState,
    link,
    rendererState.refs.current.nodes,
    rendererState.refs.current.nodesIndexLUT,
    rendererState.camera.x,
    rendererState.camera.y,
  );
  const outputLinkRenderData = linkToRenderData(
    rendererState,
    newOutputLink,
    rendererState.refs.current.nodes,
    rendererState.refs.current.nodesIndexLUT,
    rendererState.camera.x,
    rendererState.camera.y,
  );

  for (let i = 0; i < inputLinkTaps.length; i++) {
    const tapPointCoord = allTapCoordinates[inputLinkTaps[i].uuid];
    if (!tapPointCoord) continue;

    let closestSegmentIdx = -1;
    let closestDistance = Number.MAX_SAFE_INTEGER;

    for (let j = 0; j < inputLinkRenderData.vertexData.length - 1; j++) {
      const currentVertex = inputLinkRenderData.vertexData[j];
      const nextVertex = inputLinkRenderData.vertexData[j + 1];

      const pointDistance = pointToLineDistance(
        tapPointCoord,
        { x: currentVertex.coordinate[0], y: currentVertex.coordinate[1] },
        { x: nextVertex.coordinate[0], y: nextVertex.coordinate[1] },
      );

      if (
        pointDistance < closestDistance &&
        currentVertex.segmentType === VertexSegmentType.Real
      ) {
        closestSegmentIdx = currentVertex.segmentIndex;
      }
      closestDistance = Math.min(closestDistance, pointDistance);
    }

    // NOTE: we don't have vertically oriented blocks,
    // so we don't have to account for the vertical case
    if (closestSegmentIdx > -1) {
      setLinkTap(
        state,
        inputLinkTaps[i].uuid,
        link.uuid,
        {
          segment_type: 'real',
          tapped_segment_index: closestSegmentIdx,
          tapped_segment_direction: 'horiz',
        },
        tapPointCoord.x,
      );
    }
  }

  for (let i = 0; i < outputLinkTaps.length; i++) {
    const tapPointCoord = allTapCoordinates[outputLinkTaps[i].uuid];
    if (!tapPointCoord) continue;

    let closestSegmentIdx = -1;
    let closestDistance = Number.MAX_SAFE_INTEGER;

    for (let j = 0; j < outputLinkRenderData.vertexData.length - 1; j++) {
      const currentVertex = outputLinkRenderData.vertexData[j];
      const nextVertex = outputLinkRenderData.vertexData[j + 1];

      const pointDistance = pointToLineDistance(
        tapPointCoord,
        { x: currentVertex.coordinate[0], y: currentVertex.coordinate[1] },
        { x: nextVertex.coordinate[0], y: nextVertex.coordinate[1] },
      );

      if (
        pointDistance < closestDistance &&
        currentVertex.segmentType === VertexSegmentType.Real
      ) {
        closestSegmentIdx = currentVertex.segmentIndex;
      }
      closestDistance = Math.min(closestDistance, pointDistance);
    }

    // NOTE: we don't have vertically oriented blocks,
    // so we don't have to account for the vertical case
    if (closestSegmentIdx > -1) {
      setLinkTap(
        state,
        outputLinkTaps[i].uuid,
        newOutputLink.uuid,
        {
          segment_type: 'real',
          tapped_segment_index: closestSegmentIdx,
          tapped_segment_direction: 'horiz',
        },
        tapPointCoord.x,
      );
    }
  }
}

// TODO: probably needs overhaul
export function connectLinkToNode(
  state: ModelState,
  payload: {
    parentPath: string[];
    linkUuid: string;
    linkPayload: LinkPayload;
  },
) {
  const { linkUuid, linkPayload } = payload;

  // When connecting a port, we only care about the new connection here
  const { source, destination } = linkPayload;

  const model = getCurrentlyEditingModelFromState(state);
  if (!model) return;

  let link = model.links.find((l) => l.uuid === linkUuid);
  if (!link) return;

  // link is already fully connected
  if (link.src && link.dst) return;

  const usingSource = source || {
    node: link.src ? link.src.node : undefined,
    port: link.src ? link.src.port : undefined,
    port_side: link.src?.port_side,
  };
  const usingDest = destination || {
    node: link.dst ? link.dst.node : undefined,
    port: link.dst ? link.dst.port : undefined,
    port_side: link.dst?.port_side,
  };

  // used for acausal port-interaction-allowance ux "hacks"
  let acausalAllowed = false;
  if (rendererState) {
    const srcNodeIndex =
      rendererState.refs.current.nodesIndexLUT[usingSource.node || ''];
    const srcNode = rendererState.refs.current.nodes[srcNodeIndex];
    const srcPortsList =
      usingDest.port_side === 'inputs' ? srcNode.inputs : srcNode.inputs;
    const srcPort = srcPortsList[usingSource.port ?? -1];

    const dstNodeIndex =
      rendererState.refs.current.nodesIndexLUT[usingDest.node || ''];
    const dstNode = rendererState.refs.current.nodes[dstNodeIndex];
    const dstPortsList =
      usingDest.port_side === 'outputs' ? dstNode.outputs : dstNode.inputs;
    const dstPort = dstPortsList[usingDest.port ?? -1];

    if (
      srcPort?.variant?.variant_kind === 'acausal' &&
      dstPort?.variant?.variant_kind === 'acausal' &&
      srcPort.variant.acausal_domain === dstPort.variant.acausal_domain
    ) {
      acausalAllowed = true;
    }
  }

  // if there is a block with a connection to our desired destination port,
  // disallow multiple connections to the same causal input port (acausal allow)
  const forbiddenToLink =
    !acausalAllowed &&
    Boolean(
      model.links.find(
        (l) =>
          l.uuid !== linkUuid &&
          usingDest &&
          l.dst &&
          l.dst.node === usingDest.node &&
          l.dst.port === usingDest.port &&
          (!l.dst.port_side || l.dst.port_side === 'inputs'),
      ),
    );

  if (forbiddenToLink) return;

  if (usingSource.node && usingSource.port !== undefined) {
    setLinkSourceAndDependentTapSources(
      link.uuid,
      {
        node: usingSource.node || '',
        port: usingSource.port || 0,
        port_side: usingSource.port_side,
      },
      model.links,
      rendererState?.refs?.current?.linksIndexLUT,
    );
  }

  if (usingDest.node && usingDest.port !== undefined) {
    link.dst = {
      node: usingDest.node || '',
      port: usingDest.port || 0,
      port_side: usingDest.port_side,
    };
  }
}

export function moveFakeSegmentTapsToRealSegment(
  state: ModelState,
  tappedLinkUuid: string,
  fakeSegmentType: FakeSegmentType,
  realSegmentIndex: number,
) {
  const model = getCurrentlyEditingModelFromState(state);
  if (!model) return;

  const fakeTapLinks = model.links.filter(
    (l) =>
      l.uiprops.link_type.connection_method === 'link_tap' &&
      l.uiprops.link_type.tapped_link_uuid === tappedLinkUuid &&
      l.uiprops.link_type.tapped_segment.segment_type ===
        fakeSegmentStringMap[fakeSegmentType],
  );

  for (let i = 0; i < fakeTapLinks.length; i++) {
    const tapLink = fakeTapLinks[i];

    if (tapLink.uiprops.link_type.connection_method === 'link_tap') {
      tapLink.uiprops.link_type.tapped_segment = {
        segment_type: 'real',
        tapped_segment_index: realSegmentIndex,
      };
    }
  }
}

export function adjustTapLinkSegments(
  state: ModelState,
  tappedLinkUuid: string,
  adjustAmount: number,
) {
  const model = getCurrentlyEditingModelFromState(state);
  if (!model) return;

  const tapLinks = model.links.filter(
    (l) =>
      l.uiprops.link_type.connection_method === 'link_tap' &&
      l.uiprops.link_type.tapped_link_uuid === tappedLinkUuid,
  );

  for (let i = 0; i < tapLinks.length; i++) {
    const tapLink = tapLinks[i];

    if (
      tapLink.uiprops.link_type.connection_method === 'link_tap' &&
      tapLink.uiprops.link_type.tapped_segment.segment_type === 'real'
    ) {
      tapLink.uiprops.link_type.tapped_segment.tapped_segment_index +=
        adjustAmount;
    }
  }
}

export function addNewLinkToModel(
  state: ModelState,
  payload: {
    uuid: string;
    linkPayload: LinkPayload;
    linkType: LinkTypeType;
  },
) {
  const { uuid: newLinkUuid, linkPayload, linkType } = payload;
  const { source, destination } = linkPayload;

  const model = getCurrentlyEditingModelFromState(state);
  if (!model) return;

  model.links.push({
    uuid: newLinkUuid,
    src: source,
    dst: destination,
    uiprops: {
      link_type: linkType,
      segments: [],
    },
  });
}

export function addSegmentsToLink(
  state: ModelState,
  linkUuid: string,
  segmentsData: LinkSegmentType[],
  prepend: boolean,
) {
  const model = getCurrentlyEditingModelFromState(state);
  if (!model) return;

  const link = model.links.find((l) => l.uuid === linkUuid);

  if (link) {
    if (prepend) {
      link.uiprops.segments = segmentsData.concat(link.uiprops.segments);
    } else {
      link.uiprops.segments = link.uiprops.segments.concat(segmentsData);
    }
  }
}

export function simplifyLinkSegments(state: ModelState, linkUuid: string) {
  const model = getCurrentlyEditingModelFromState(state);
  if (!model) return;

  const link = model.links.find((l) => l.uuid === linkUuid);
  if (!link) return;
  const taps = model.links.filter(
    (l) =>
      l.uiprops.link_type.connection_method === 'link_tap' &&
      l.uiprops.link_type.tapped_link_uuid === link.uuid,
  );

  const linkSrcNode = model.nodes.find((l) => l.uuid === link.src?.node);
  const linkDstNode = model.nodes.find((l) => l.uuid === link.dst?.node);
  // we run this twice because there are some rare edge cases
  // that could result in a not-perfectly-simplified link.
  // the second pass handles all of those cases.
  // this also greatly simplifies the algorithm at minimal additional cost.
  removeAlignedLinkSegments(link, linkSrcNode, linkDstNode, taps);
  removeAlignedLinkSegments(link, linkSrcNode, linkDstNode, taps);

  // See comment about "jumping glitch" in mouseInputClick.ts
  if (linkSrcNode && linkDstNode) {
    delete link.uiprops.hang_coord_end;
    delete link.uiprops.hang_coord_start;
  }
}

export function simplifyBlocksLinkSegments(
  state: ModelState,
  blockUuids: string[],
) {
  if (blockUuids.length === 0) return;

  const model = getCurrentlyEditingModelFromState(state);
  if (!model) return;

  const links = model.links.filter(
    (l) =>
      blockUuids.includes(l.src?.node || '') ||
      blockUuids.includes(l.dst?.node || ''),
  );

  for (let i = 0; i < links.length; i++) {
    const link = links[i];
    if (!link) continue;
    const taps = model.links.filter(
      (l) =>
        l.uiprops.link_type.connection_method === 'link_tap' &&
        l.uiprops.link_type.tapped_link_uuid === link.uuid,
    );

    if (link) {
      const linkSrcNode = model.nodes.find((l) => l.uuid === link.src?.node);
      const linkDstNode = model.nodes.find((l) => l.uuid === link.dst?.node);
      // we run this twice because there are some rare edge cases
      // that could result in a not-perfectly-simplified link.
      // the second pass handles all of those cases.
      // this also greatly simplifies the algorithm at minimal additional cost.
      removeAlignedLinkSegments(link, linkSrcNode, linkDstNode, taps);
      removeAlignedLinkSegments(link, linkSrcNode, linkDstNode, taps);
    }
  }
}
export const getLinkTreeParent = (linkId: string): LinkInstance | undefined => {
  let linkTreeParent: LinkInstance | undefined = rendererState
    ? rendererState.refs.current.links[
        rendererState.refs.current.linksIndexLUT[linkId]
      ]
    : undefined;

  while (
    linkTreeParent &&
    rendererState &&
    linkTreeParent.uiprops.link_type.connection_method === 'link_tap'
  ) {
    const newLinkTreeParentUuid: string =
      linkTreeParent.uiprops.link_type.tapped_link_uuid;
    const newLinkTreeParentIndex: number | undefined =
      rendererState.refs.current.linksIndexLUT[newLinkTreeParentUuid];
    const newLinkTreeParent: LinkInstance | undefined =
      rendererState.refs.current.links[newLinkTreeParentIndex];
    if (newLinkTreeParent) linkTreeParent = newLinkTreeParent;
    else break;
  }

  return linkTreeParent;
};

export const getAllLinkTreeUuidsFromParent = (
  linkTreeParent: LinkInstance,
): string[] => {
  const linkTreeUuids: string[] = linkTreeParent ? [linkTreeParent.uuid] : [];
  if (linkTreeParent && rendererState) {
    // as mentioned elsewhere, regular for-loop is the fastest way to copy an array here.
    // necessary because we should of course not mutate data in our refs object.
    const dependentLinkUuids =
      rendererState.refs.current.linksRenderingDependencyTree[
        linkTreeParent.uuid
      ] || [];
    for (let i = 0; i < dependentLinkUuids.length; i++) {
      linkTreeUuids.push(dependentLinkUuids[i]);
    }
  }

  return linkTreeUuids;
};

export const getAllLinkTreeUuids = (linkId: string): string[] => {
  const linkTreeParent = getLinkTreeParent(linkId);

  if (!linkTreeParent) return [];

  return getAllLinkTreeUuidsFromParent(linkTreeParent);
};

export const getLinkName = (linkUuid: string): string | null => {
  if (!rendererState) return null;

  const linkIndex = rendererState.refs.current.linksIndexLUT[linkUuid || ''];
  const link = rendererState.refs.current.links[linkIndex];

  if (!link.src) return null;
  const originNodeUuid = link.src.node;
  const outputId = link.src.port;

  const linkTreeParent = link ? getLinkTreeParent(linkUuid) : undefined;

  const originNodeIndex =
    rendererState.refs.current.nodesIndexLUT[originNodeUuid];
  const originNode = rendererState.refs.current.nodes[originNodeIndex];

  if (!originNode) {
    console.error('cannot find origin node for link');
    return null;
  }

  const linkName =
    linkTreeParent && linkTreeParent.name
      ? linkTreeParent.name
      : originNode.outputs[outputId] !== undefined
      ? `${originNode?.name}.${originNode?.outputs[outputId].name}`
      : null;

  return linkName;
};
