import styled from '@emotion/styled/macro';
import { useModelVersionData } from 'app/api/useModelVersionData';
import { useSubmodel } from 'app/api/useSubmodel';
import { VersionTagValues } from 'app/apiTransformers/convertPostSubmodelsFetch';
import React from 'react';
import { Spinner } from 'ui/common/Spinner';
import DiagramTreeContent from 'ui/objectBrowser/sections/DiagramTreeContent';

export const SingleLineSpinnerWrapper = styled.div`
  width: 100%;
  height: ${({ theme }) => theme.spacing.xlarge};
  display: flex;
  align-items: center;
  justify-content: center;

  & * {
    fill: ${({ theme }) => theme.colors.grey[70]};
  }
`;

interface Props {
  projectId: string;
  testIdPath: string;
  submodelInstanceId: string;
  submodelReferenceId: string;
  submodelVersionId: string;
  parentPath: string[];
  searchString?: string;
}

const ReferenceSubmodelTreeContent: React.FC<Props> = ({
  projectId,
  testIdPath,
  submodelInstanceId,
  submodelReferenceId,
  submodelVersionId,
  parentPath,
  searchString,
}) => {
  const { versionFull } = useModelVersionData(
    submodelReferenceId,
    submodelVersionId,
    'Submodel',
    {},
  );

  const submodelLoadOptions = React.useMemo(
    () => ({
      skip:
        submodelVersionId !== VersionTagValues.LATEST_VERSION || !parentPath,
    }),
    [submodelVersionId, parentPath],
  );

  const { submodel } = useSubmodel(
    projectId,
    submodelReferenceId,
    submodelLoadOptions,
  );

  const submodelFull =
    submodelVersionId === VersionTagValues.LATEST_VERSION
      ? submodel
      : versionFull?.submodel;

  if (!submodelFull) {
    return (
      <SingleLineSpinnerWrapper>
        <Spinner />
      </SingleLineSpinnerWrapper>
    );
  }

  return (
    <DiagramTreeContent
      projectId={projectId}
      testIdPath={testIdPath}
      parentPath={[...parentPath, submodelInstanceId]}
      searchString={searchString}
      diagram={submodelFull.diagram}
    />
  );
};

export default ReferenceSubmodelTreeContent;
