import { t } from '@lingui/macro';
import { useProjectActions } from 'app/api/useProjectActions';
import { useProjectItems } from 'app/api/useProjectItems';
import { Project } from 'app/apiTransformers/convertAPIProjectToProject';
import React from 'react';
import Button from 'ui/common/Button/Button';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import Input from 'ui/common/Input/Input';
import {
  isDuplicatedRuleSet,
  isRequiredRule,
} from 'ui/common/Input/inputValidation';
import Label from 'ui/common/Label';
import {
  ActionButtonContainer,
  ModalInputGroup,
  SmallFormContainer,
} from 'ui/common/Modal/Modal';
import { useModal } from 'ui/common/Modal/useModal';
import SelectInput from 'ui/common/SelectInput';
import { splitFullName } from 'util/fileUtils';
import { ProjectItemType } from '../dashboardTypes';

interface Props {
  project: Project;
}

const formatToContentType: { [key: string]: string } = {
  png: 'image/png',
  jpg: 'image/jpeg',
  jpeg: 'image/jpeg',
  csv: 'text/csv',
  txt: 'text/plain',
  mp4: 'application/octet-stream',
  mpeg4: 'application/octet-stream',
  avi: 'application/octet-stream',
};

const ImportFromUrlModal: React.FC<Props> = ({ project }) => {
  const { closeModal } = useModal();
  const { uploadFileFromUrl } = useProjectActions();
  const { projectItems } = useProjectItems(project);
  const [urlPath, setUrlPath] = React.useState('');
  const [name, setName] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const [format, setFormat] = React.useState('');
  const [isUrlPathValid, setIsUrlPathValid] = React.useState(false);
  const [isNameValid, setIsNameValid] = React.useState(false);
  const options = [
    { value: 'png', label: 'png' },
    { value: 'jpg', label: 'jpg' },
    { value: 'jpeg', label: 'jpeg' },
    { value: 'csv', label: 'csv' },
    { value: 'txt', label: 'txt' },
    { value: 'mp4', label: 'mp4' },
    { value: 'mpeg4', label: 'mpeg4' },
    { value: 'avi', label: 'avi' },
  ];

  const fullName = `${name}.${options.find((o) => o.value === format)?.label}`;

  const projectFiles = projectItems.filter(
    (it) => it.type === ProjectItemType.PROJECTFILE,
  );

  const onCancel = () => {
    closeModal();
  };

  const onConfirm = () => {
    closeModal();
  };

  return (
    <SmallFormContainer
      onSubmit={(e) => {
        e?.preventDefault();
        setIsLoading(true);
        uploadFileFromUrl({
          projectUuid: project.uuid,
          fileCreateRequest: {
            content_type: formatToContentType[format],
            name: fullName,
            external_link: urlPath,
          },
        }).then(() => {
          setIsLoading(false);
          onConfirm();
        });
      }}>
      <ModalInputGroup>
        <Label>
          {t({
            id: 'importFromUrlModal.urlPath.label',
            message: 'Enter a valid, public path to the file',
          })}
        </Label>
        <Input
          hasBorder
          autoFocus
          onChangeText={(v, isValid) => {
            setUrlPath(v);
            const parts = v.split('.');
            if (parts.length > 0) {
              const extension = parts[parts.length - 1];
              if (extension) {
                const format = options.find(
                  (o) => o.label === extension,
                )?.value;
                if (format) {
                  setFormat(format);
                } else {
                  setFormat('');
                }
              } else {
                setFormat('');
              }
            }
            setIsUrlPathValid(isValid);
          }}
          value={urlPath}
          validationRules={[isRequiredRule]}
        />
      </ModalInputGroup>
      <ModalInputGroup>
        <Label>
          {t({
            id: 'importFromUrlModal.fileType.label',
            message: 'Choose file type of the file',
          })}
        </Label>
        <SelectInput
          onSelectValue={setFormat}
          currentValue={format}
          options={options}
        />
      </ModalInputGroup>
      <ModalInputGroup>
        <Label>
          {t({
            id: 'importFromUrlModal.name.label',
            message: 'Give the file a unique name',
          })}
        </Label>
        <Input
          hasBorder
          onChangeText={(v, isValid) => {
            setName(v);
            setIsNameValid(isValid);
          }}
          value={name}
          validationRules={isDuplicatedRuleSet(
            projectFiles,
            (f) => splitFullName(f.name)[0],
          )}
        />
      </ModalInputGroup>
      <ActionButtonContainer>
        {/* Cancel button */}
        <Button
          type="button"
          onClick={onCancel}
          variant={ButtonVariants.LargeSecondary}>
          {t({
            id: 'importFromUrlModal.cancelButton.label',
            message: 'Cancel',
          })}
        </Button>

        {/* Download button */}
        <Button
          type="submit"
          loading={isLoading}
          variant={ButtonVariants.LargePrimary}
          disabled={!isUrlPathValid || !isNameValid || format === ''}>
          {t({
            id: 'importFromUrlModal.importButton.label',
            message: 'Import',
          })}
        </Button>
      </ActionButtonContainer>
    </SmallFormContainer>
  );
};

export default ImportFromUrlModal;
