/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  className?: string;
}

const Outport: React.FC<Props> = (props) => (
  <svg viewBox="0 0 16 16" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.9169 4.5C8.51506 4.5 8.99997 4.98491 8.99997 5.58307V7H7.99997V5.58307C7.99997 5.53719 7.96278 5.5 7.9169 5.5H4.08304C4.03716 5.5 3.99997 5.53719 3.99997 5.58307V11.4169C3.99997 11.4628 4.03716 11.5 4.08304 11.5H7.9169C7.96278 11.5 7.99997 11.4628 7.99997 11.4169V10H8.99997V11.4169C8.99997 12.0151 8.51506 12.5 7.9169 12.5H4.08304C3.48488 12.5 2.99997 12.0151 2.99997 11.4169V5.58307C2.99997 4.98491 3.48488 4.5 4.08304 4.5H7.9169Z"
      fill={props.fill}
    />
    <path
      d="M11.3535 6.14645C11.1583 5.95118 10.8417 5.95118 10.6464 6.14645C10.4512 6.34171 10.4512 6.65829 10.6464 6.85355L11.7929 8H7.99997C7.72383 8 7.49997 8.22386 7.49997 8.5C7.49997 8.77614 7.72383 9 7.99997 9H11.7929L10.6464 10.1464C10.4512 10.3417 10.4512 10.6583 10.6464 10.8536C10.8417 11.0488 11.1583 11.0488 11.3535 10.8536L13.3535 8.85355C13.5488 8.65829 13.5488 8.34171 13.3535 8.14645L11.3535 6.14645Z"
      fill={props.fill}
    />
  </svg>
);

Outport.defaultProps = {
  width: '16',
  height: '16',
  viewBox: '0 0 16 16',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  className: '',
};

export default Outport;
