import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { JobSummary } from 'app/apiGenerated/generatedApiTypes';

export enum OutputLogLevel {
  UNKNOWN = 'UNKNOWN',
  DBG = 'DBG',
  INF = 'INF',
  WRN = 'WRN',
  ERR = 'ERR',
  FATAL = 'FATAL',

  WARNING = 'WARNING',
  DEBUG = 'DEBUG',
  ERROR = 'ERROR',
  INFO = 'INFO',
}

export interface ModelLogLine {
  message?: string;
  level?: OutputLogLevel;
  timestamp?: number;
  [key: string]: any;
}

export type SimulationLogs = ModelLogLine[];
export type CompilationLogs = ModelLogLine[];

export interface SimulationStatistics {
  sim_time?: number;
  sim_time_target?: number;
  sim_time_percentage?: number;
  clock_time_start?: string; // ISO-8601
  clock_time_current?: string; // ISO-8601
  clock_time_end?: string; // ISO-8601
}

export interface SimulationLogCounts {
  info: number;
  warn: number;
  error: number;
}

interface SimResultsState {
  simulationLogs: ModelLogLine[];
  simulationLogCounts: SimulationLogCounts;
}

export const countSimLogs = (
  buildLogs?: ModelLogLine[],
  simLogs?: ModelLogLine[],
): SimulationLogCounts => {
  const reduceFn = (acc: SimulationLogCounts, log: ModelLogLine) => {
    switch (log.level?.toUpperCase() || 'OTHER') {
      case 'INF':
      case 'INFO':
        return { ...acc, info: acc.info + 1 };
      case 'WRN':
      case 'WARNING':
        return { ...acc, warn: acc.warn + 1 };
      case 'ERR':
      case 'ERROR':
      case 'FATAL':
        return { ...acc, error: acc.error + 1 };
      default:
        return acc;
    }
  };

  const zero = { info: 0, warn: 0, error: 0 };
  const buildCounts = (buildLogs || []).reduce(reduceFn, zero);
  return (simLogs || []).reduce(reduceFn, buildCounts);
};

export interface SimulationRunResult {
  summary: JobSummary;
  logs?: ModelLogLine[];
}

const initialState: SimResultsState = {
  simulationLogs: [],
  simulationLogCounts: { info: 0, warn: 0, error: 0 },
};

const simResultsSlice = createSlice({
  name: 'simResultsSlice',
  initialState,
  reducers: {
    resetSimResultsState: () => initialState,
    setSimulationResult(state, action: PayloadAction<SimulationRunResult>) {
      const { summary, logs } = action.payload;
      state.simulationLogs = logs || [];
      state.simulationLogCounts = countSimLogs(
        logs ? [] : summary.compilation_logs,
        logs,
      );
    },
  },
});

export const simResultsActions = simResultsSlice.actions;

export default simResultsSlice;
