/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  cx?: any;
  cy?: any;
  r?: any;
  stroke?: any;
  strokeWidth?: any;
  strokeLinecap?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const AcausalTranslationalMotionsensor: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <circle
      cx={props.cx}
      cy={props.cy}
      r="11.25"
      stroke={props.stroke}
      strokeWidth={props.strokeWidth}
    />
    <path
      d="M16 5.5V7.5"
      stroke={props.stroke}
      strokeWidth={props.strokeWidth}
      strokeLinecap={props.strokeLinecap}
    />
    <path
      d="M25.0938 10.75L23.3617 11.75"
      stroke={props.stroke}
      strokeWidth={props.strokeWidth}
      strokeLinecap={props.strokeLinecap}
    />
    <path
      d="M6.90625 10.75L8.6383 11.75"
      stroke={props.stroke}
      strokeWidth={props.strokeWidth}
      strokeLinecap={props.strokeLinecap}
    />
    <path
      d="M10.75 6.90673L11.75 8.63879"
      stroke={props.stroke}
      strokeWidth={props.strokeWidth}
      strokeLinecap={props.strokeLinecap}
    />
    <path
      d="M21.25 6.90673L20.25 8.63879"
      stroke={props.stroke}
      strokeWidth={props.strokeWidth}
      strokeLinecap={props.strokeLinecap}
    />
    <circle cx={props.cx} cy={props.cy} r={props.r} stroke={props.stroke} />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M14.7278 7.8181C14.6273 7.41625 14.2201 7.17193 13.8183 7.27239C13.4164 7.37285 13.1721 7.78006 13.2726 8.1819L14.8229 14.3831C15.1532 14.1422 15.5601 14 16.0002 14C16.0945 14 16.1872 14.0065 16.278 14.0191L14.7278 7.8181Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M13.75 19.25C14.3023 19.25 14.75 19.6977 14.75 20.25V21.25H17.3469L16.6853 20.4939C16.4125 20.1822 16.4441 19.7083 16.7558 19.4356C17.0675 19.1628 17.5414 19.1944 17.8141 19.5061L19.5641 21.5061C19.8115 21.7889 19.8115 22.2111 19.5641 22.4939L17.8141 24.4939C17.5414 24.8056 17.0675 24.8372 16.7558 24.5644C16.4441 24.2917 16.4125 23.8178 16.6853 23.5061L17.3469 22.75H14.75V23.75C14.75 24.3023 14.3023 24.75 13.75 24.75C13.1977 24.75 12.75 24.3023 12.75 23.75V20.25C12.75 19.6977 13.1977 19.25 13.75 19.25Z"
      fill={props.fill}
    />
    <path
      d="M2 16L4 16"
      stroke={props.stroke}
      strokeWidth={props.strokeWidth}
      strokeLinecap={props.strokeLinecap}
    />
    <path
      d="M28 16L30 16"
      stroke={props.stroke}
      strokeWidth={props.strokeWidth}
      strokeLinecap={props.strokeLinecap}
    />
  </svg>
);

AcausalTranslationalMotionsensor.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  cx: '16',
  cy: '16',
  r: '1.5',
  stroke: '#1D3131',
  strokeWidth: '1.5',
  strokeLinecap: 'round',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default AcausalTranslationalMotionsensor;
