/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Mux: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M13.059 5.19098C12.2279 4.77542 11.25 5.37979 11.25 6.30902V9.75H8C7.58579 9.75 7.25 10.0858 7.25 10.5C7.25 10.9142 7.58579 11.25 8 11.25H11.25V15.25H8C7.58579 15.25 7.25 15.5858 7.25 16C7.25 16.4142 7.58579 16.75 8 16.75H11.25V20.75H8C7.58579 20.75 7.25 21.0858 7.25 21.5C7.25 21.9142 7.58579 22.25 8 22.25H11.25V25.691C11.25 26.6202 12.2279 27.2246 13.059 26.809L20.059 23.309C20.4825 23.0973 20.75 22.6644 20.75 22.191V17.25H25C25.2761 17.25 25.5 17.0261 25.5 16.75C25.5 16.4739 25.2761 16.25 25 16.25H20.75V15.75H25C25.2761 15.75 25.5 15.5261 25.5 15.25C25.5 14.9739 25.2761 14.75 25 14.75H20.75V9.80902C20.75 9.33555 20.4825 8.90272 20.059 8.69098L13.059 5.19098ZM12.75 25.2865V6.71352L19.25 9.96352V22.0365L12.75 25.2865Z"
      fill={props.fill}
    />
  </svg>
);

Mux.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Mux;
