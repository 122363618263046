import { getCurrentModelRef } from 'app/sliceRefAccess/CurrentModelRef';
import { getSimulationRef } from 'app/sliceRefAccess/SimulationRef';
import { MINIMUM_ZOOM } from 'app/slices/cameraSlice';
import { SignalIndividualTypeAndTimeInfo } from 'app/slices/compilationAnalysisDataSlice';
import { renderConstants } from 'app/utils/renderConstants';
import * as NVG from 'nanovg-js';
import { getNodePathName } from 'ui/modelEditor/portPathNameUtils';
import { RendererState } from 'ui/modelRendererInternals/modelRenderer';
import { calculateTextSize } from 'util/calculateTextSize';
import { LINE_COLORS, withAlphaf } from './coloring';
import { getDrawContext } from './drawPrimitives';

const LABEL_FONTSIZE = 8;
const LABEL_SCALEDOWN_THRESHOLD = 0.7;

const labelMarginLeft = renderConstants.GRID_UNIT_PXSIZE * 0.5;
const innerPaddingLeft = renderConstants.GRID_UNIT_PXSIZE * 0.5;
const innerPaddingRight = renderConstants.GRID_UNIT_PXSIZE * 0.5;
const baseYOffset = 1;

const drawLabel = (
  nvg: NVG.Context,
  rs: RendererState,
  rX: number,
  rY: number,
  text: string,
  fadeText?: boolean,
  flipped?: boolean,
) => {
  const ctx = getDrawContext(rs, nvg);

  const fontSize = rs.zoom * LABEL_FONTSIZE;

  const rawLabelOpacity =
    (rs.zoom - MINIMUM_ZOOM * 2) /
    (LABEL_SCALEDOWN_THRESHOLD - MINIMUM_ZOOM * 2);
  const labelOpacity = Math.max(0, Math.min(1, rawLabelOpacity));

  const { width: textWidthRaw } = calculateTextSize(text, {
    font: 'Archivo',
    fontSize: `${fontSize * 0.9}px`,
  });

  const textWidth = textWidthRaw * 1.1;

  const labelVPadding = 1;
  const labelHeight = fontSize + labelVPadding * 2 * rs.zoom;
  const labelTextOffset = labelVPadding;

  ctx.fontSize(fontSize);
  ctx.fontFace('archivo');
  ctx.textAlign(NVG.Align.LEFT | NVG.Align.TOP);

  const zoomedHPadding = innerPaddingLeft + innerPaddingRight * rs.zoom;
  const rectWidth = textWidth + zoomedHPadding;

  const rectX = flipped
    ? (rX - labelMarginLeft) * rs.zoom - rectWidth
    : (rX + labelMarginLeft) * rs.zoom;
  const rectY = (rY - baseYOffset) * rs.zoom;

  ctx.beginPath();
  ctx.roundedRect(rectX, rectY, rectWidth, labelHeight, 3);
  ctx.fillColor(ctx.RGBA(241, 243, 243, 255 * labelOpacity * 0.7));
  ctx.fill();

  const textX = flipped
    ? (rX - labelMarginLeft) * rs.zoom - textWidth
    : (rX + labelMarginLeft) * rs.zoom;

  const textY = (rY - baseYOffset + labelTextOffset) * rs.zoom;

  ctx.fillColor(
    withAlphaf(LINE_COLORS.text_secondary, (fadeText ? 0.5 : 1) * labelOpacity),
  );
  ctx.text(textX, textY, text);
};

export const signalTypeToDisplayType = (
  sigTy?: SignalIndividualTypeAndTimeInfo,
): string => {
  if (!sigTy) return 'Unknown';
  if (sigTy.dimension.length > 0) return `${sigTy.dtype} (${sigTy.dimension})`;
  return sigTy.dtype;
};

export function drawSignalLabels(
  nvg: NVG.Context,
  rs: RendererState,
  worldX: number,
  worldY: number,
  offsetX: number,
  offsetY: number,
  nodeId: string,
  outputId: number,
): void {
  if (!rs.refs.current.uiFlags.showDatatypesInModel) return;

  const originNode =
    rs.refs.current.nodes[rs.refs.current.nodesIndexLUT[nodeId]];
  if (!originNode) return;

  const nodePathName = getNodePathName(
    getCurrentModelRef().topLevelNodes,
    getCurrentModelRef().submodels,
    { parentPath: getCurrentModelRef().submodelPath, nodeId },
  );

  const { datatypeAndDimensions } = (nodePathName &&
    getSimulationRef().compilationData.signalsData[nodePathName]) || {
    datatypeAndDimensions: undefined,
  };

  const portDataTypeAndDimensions = (datatypeAndDimensions || [])[outputId];
  const displayType = signalTypeToDisplayType(portDataTypeAndDimensions);

  const flipped = originNode.uiprops.directionality === 'left';

  drawLabel(
    nvg,
    rs,
    worldX + offsetX,
    worldY + offsetY,
    displayType,
    !datatypeAndDimensions || displayType === 'Unknown',
    flipped,
  );
}
