/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  className?: string;
}

const Input: React.FC<Props> = (props) => (
  <svg viewBox="0 0 16 16" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.08307 4.49999C7.48491 4.49999 7 4.9849 7 5.58307L7.58104 6.11584C7.74138 6.26286 8 6.14912 8 5.93157V5.58307C8 5.53719 8.03719 5.49999 8.08307 5.49999H11.9169C11.9628 5.49999 12 5.53719 12 5.58307V11.4169C12 11.4628 11.9628 11.5 11.9169 11.5H8.08307C8.03719 11.5 8 11.4628 8 11.4169V11.0684C8 10.8509 7.74138 10.7371 7.58104 10.8841L7 11.4169C7 12.0151 7.48491 12.5 8.08307 12.5H11.9169C12.5151 12.5 13 12.0151 13 11.4169V5.58307C13 4.9849 12.5151 4.49999 11.9169 4.49999H8.08307Z"
      fill={props.fill}
    />
    <path
      d="M6.35355 6.14644C6.15829 5.95118 5.84171 5.95118 5.64645 6.14644C5.45118 6.3417 5.45118 6.65828 5.64645 6.85355L6.79289 7.99999H3C2.72386 7.99999 2.5 8.22385 2.5 8.49999C2.5 8.77613 2.72386 8.99999 3 8.99999H6.79289L5.64645 10.1464C5.45118 10.3417 5.45118 10.6583 5.64645 10.8535C5.84171 11.0488 6.15829 11.0488 6.35355 10.8535L8.35355 8.85355C8.54882 8.65828 8.54882 8.3417 8.35355 8.14644L6.35355 6.14644Z"
      fill={props.fill}
    />
  </svg>
);

Input.defaultProps = {
  width: '16',
  height: '16',
  viewBox: '0 0 16 16',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  className: '',
};

export default Input;
