/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Eye: React.FC<Props> = (props) => (
  <svg viewBox="0 0 16 16" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M5.1197 8C5.71528 9.00687 6.789 9.66666 8 9.66666C9.211 9.66666 10.2847 9.00686 10.8803 7.99999C10.2847 6.99312 9.211 6.33333 8 6.33333C6.789 6.33333 5.71528 6.99312 5.1197 8ZM12 7.99999C11.3227 6.42955 9.78654 5.33333 8 5.33333C6.21346 5.33333 4.67734 6.42955 4 8C4.67734 9.57044 6.21346 10.6667 8 10.6667C9.78654 10.6667 11.3227 9.57043 12 7.99999Z"
      fill={props.fill}
    />
    <path
      d="M9.33333 7.66666C9.33333 8.40303 8.73638 8.99999 8 8.99999C7.26362 8.99999 6.66667 8.40303 6.66667 7.66666C6.66667 6.93028 7.26362 6.33333 8 6.33333C8.73638 6.33333 9.33333 6.93028 9.33333 7.66666Z"
      fill={props.fill}
    />
  </svg>
);

Eye.defaultProps = {
  width: '16',
  height: '16',
  viewBox: '0 0 16 16',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Eye;
