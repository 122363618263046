import { useGetUserOptionsQuery } from 'app/apiGenerated/generatedApi';
import { UserProfileOptionsResponse } from 'app/apiGenerated/generatedApiTypes';
import { OpenAiModels } from 'app/chat/models';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
  UserOptions,
  initialState,
  userOptionsActions,
} from 'app/slices/userOptionsSlice';
import React from 'react';
import { isAdminUser } from 'ui/auth/utils';
import { AutocompleteModel } from 'ui/codeEditor/AutocompleteHandler';

function hasOptionEnabled(
  data: UserProfileOptionsResponse,
  optionKey: string,
  defValue = false,
) {
  if (!data) return false;
  if (!data[optionKey]) return defValue;
  if (data[optionKey].toLowerCase() === 'false') return false;
  if (data[optionKey].toLowerCase() === 'true') return true;
  return defValue;
}

export function useUserOptions() {
  const dispatch = useAppDispatch();

  const { data } = useGetUserOptionsQuery();

  const areUserOptionsLoaded = useAppSelector(
    (state) => state.userOptions.areUserOptionsLoaded,
  );

  const boolOpt = React.useCallback(
    (key: string, def = false): boolean =>
      data ? hasOptionEnabled(data, key, def) : def,
    [data],
  );

  React.useEffect(() => {
    // Only set user options once when the user signs in.
    if (!areUserOptionsLoaded && data) {
      const options: UserOptions = {
        developerModeEnabled: boolOpt('developer_mode_enabled', isAdminUser()),
        simIgnoreCache: boolOpt('sim_ignore_cache'),
        maxQueuedSims:
          parseInt(data?.max_queued_sims) ?? initialState.options.maxQueuedSims,
        workerTypeSelectionEnabled: boolOpt('worker_type_selection_enabled'),
        pythonConsoleEnabled: boolOpt('python_console_enabled'),
        aiAssistantEnabled: boolOpt('ai_assistant_enabled'),
        dataIntegrationsEnabled: boolOpt('data_integrations_enabled'),
        fmuBlocksEnabled: boolOpt('fmu_blocks_enabled'),
        mediaVisualizerEnabled: boolOpt('media_visualizer_enabled'),
        dataExplorerEnabled: boolOpt('data_explorer_enabled'),
        codexAutocompleteEnabled: boolOpt('codex_autocomplete_enabled'),
        codexAutocompleteModel:
          (data?.codex_autocomplete_model as AutocompleteModel) ??
          'GPT3Dot5Turbo',
        uploadMlModelsEnabled: boolOpt('upload_ml_models_enabled'),
        openAiKey: data?.openai_key ?? '',
        requirementsEnabled: boolOpt('requirements_enabled'),
        gitEnabled: boolOpt('git_enabled'),
        chatCommonKeyMaxCallCount:
          parseInt(data?.chat_common_key_max_call_count) ?? 10,
        chatFunctionsV2: boolOpt('chat_functions_v2'),
        multiplayer: boolOpt('multiplayer'),
        updateBannerEnabled: boolOpt('update_banner_enabled'),
        updateBannerContent: data?.update_banner_content ?? '',
        defaultChatAiModel: data?.default_chat_ai_model ?? OpenAiModels[0].id,
        twinBlocksEnabled: boolOpt('twin_blocks_enabled'),
        projectReadmeEnabled: boolOpt('project_readme_enabled'),
        allowUploadDirectories: boolOpt('allow_upload_directories'),
        optimizationEnabled: boolOpt('optimization_enabled', false),
        acausalModelingEnabled: boolOpt('acausal_modeling_enabled', true),
      };

      dispatch(userOptionsActions.setUserOptions({ options }));
    }
  }, [dispatch, data, areUserOptionsLoaded, boolOpt]);
}

export function useUserOption<T extends keyof UserOptions>(
  optionKey: T,
): UserOptions[T] {
  return useAppSelector((state) => state.userOptions.options[optionKey]);
}
