/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Speedsensor: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.7497 7.52091C13.7143 7.38746 13.5775 7.3085 13.4442 7.34453C13.311 7.38057 13.2316 7.51797 13.2671 7.65141L13.8613 9.88918C13.8967 10.0226 14.0335 10.1016 14.1668 10.0656C14.3001 10.0295 14.3794 9.89212 14.3439 9.75867L13.7497 7.52091Z"
      fill={props.fill}
    />
    <path
      d="M22.7113 24.1577C22.8085 24.2558 22.9661 24.2558 23.0633 24.1577C23.1605 24.0597 23.1605 23.9007 23.0633 23.8026L22.8476 23.5849C23.3745 23.037 23.8339 22.4276 24.2158 21.7698L22.9185 21.0167C22.603 21.5602 22.2246 22.0644 21.7913 22.5191L21.4399 22.1644C21.3427 22.0664 21.1851 22.0664 21.0879 22.1644C20.9907 22.2625 20.9907 22.4215 21.0879 22.5196L22.7113 24.1577Z"
      fill={props.fill}
    />
    <path
      d="M25.6423 19.6527C25.6069 19.7861 25.4701 19.8651 25.3368 19.829L25.0896 19.7622C24.9241 20.3066 24.71 20.8355 24.4495 21.3424L23.1153 20.6568C23.3276 20.2437 23.5034 19.8133 23.6407 19.3704L23.1192 19.2294C22.9859 19.1934 22.9066 19.056 22.942 18.9225C22.9775 18.7891 23.1143 18.7101 23.2475 18.7462L23.7739 18.8885C23.7814 18.8577 23.7887 18.827 23.7958 18.7961L23.7998 18.7783C23.8301 18.6455 23.857 18.5119 23.8805 18.3776L25.3581 18.6359C25.3302 18.7953 25.2983 18.954 25.2623 19.1117C25.2495 19.168 25.2362 19.2241 25.2223 19.2801L25.4651 19.3458C25.5984 19.3818 25.6778 19.5192 25.6423 19.6527Z"
      fill={props.fill}
    />
    <path
      d="M25.4651 14.7567C25.5984 14.7207 25.6778 14.5833 25.6423 14.4498C25.6069 14.3164 25.4701 14.2374 25.3368 14.2734L23.1192 14.8731C22.9859 14.9091 22.9066 15.0465 22.942 15.1799C22.9775 15.3134 23.1143 15.3923 23.2475 15.3563L23.7967 15.2078C23.8318 15.3606 23.8624 15.5143 23.8885 15.6688L25.3676 15.4193C25.3334 15.2169 25.2927 15.0158 25.2456 14.8161L25.4651 14.7567Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M23.0633 9.94473C23.1605 10.0428 23.1605 10.2018 23.0633 10.2999L21.684 11.6918C21.8083 11.9678 21.7571 12.3036 21.5303 12.5303L17.8417 16.2189C17.9436 16.4589 18 16.7229 18 17C18 18.1046 17.1046 19 16 19C15.7229 19 15.4589 18.9436 15.2189 18.8417L14.5303 19.5303C14.2374 19.8232 13.7626 19.8232 13.4697 19.5303C13.1768 19.2374 13.1768 18.7626 13.4697 18.4697L14.1583 17.7811C14.0564 17.5411 14 17.2771 14 17C14 15.8954 14.8954 15 16 15C16.2771 15 16.5411 15.0564 16.7811 15.1583L20.4697 11.4697C20.7047 11.2347 21.0569 11.1882 21.3382 11.3304L21.488 11.1792C21.4637 11.1563 21.4393 11.1336 21.4148 11.111L22.4301 10.0069C22.4684 10.0421 22.5064 10.0776 22.5441 10.1135L22.7113 9.94473C22.8085 9.84666 22.9661 9.84666 23.0633 9.94473ZM17 17C17 17.5523 16.5523 18 16 18C15.4477 18 15 17.5523 15 17C15 16.4477 15.4477 16 16 16C16.5523 16 17 16.4477 17 17Z"
      fill={props.fill}
    />
    <path
      d="M18.7751 7.65141C18.8106 7.51797 18.7312 7.38057 18.598 7.34453C18.4647 7.3085 18.3279 7.38746 18.2925 7.52091L17.6983 9.75867C17.6628 9.89212 17.7421 10.0295 17.8754 10.0656C18.0087 10.1016 18.1455 10.0226 18.1809 9.88918L18.7751 7.65141Z"
      fill={props.fill}
    />
    <path
      d="M10.9539 11.9381C10.8567 12.0361 10.6992 12.0361 10.602 11.9381L8.97856 10.2999C8.88137 10.2018 8.88137 10.0428 8.97856 9.94476C9.07575 9.84669 9.23332 9.84669 9.33051 9.94476L10.9539 11.5829C11.0511 11.681 11.0511 11.84 10.9539 11.9381Z"
      fill={props.fill}
    />
    <path
      d="M8.79483 15.3563C8.92812 15.3924 9.06489 15.3134 9.10033 15.18C9.13576 15.0465 9.05644 14.9091 8.92315 14.8731L6.70554 14.2735C6.57226 14.2374 6.43548 14.3164 6.40005 14.4498C6.36461 14.5833 6.44393 14.7207 6.57722 14.7567L8.79483 15.3563Z"
      fill={props.fill}
    />
    <path
      d="M9.10033 18.9226C9.13576 19.056 9.05644 19.1934 8.92315 19.2295L6.70554 19.8291C6.57226 19.8651 6.43548 19.7861 6.40005 19.6527C6.36461 19.5192 6.44393 19.3819 6.57722 19.3458L8.79483 18.7462C8.92812 18.7102 9.06489 18.7891 9.10033 18.9226Z"
      fill={props.fill}
    />
    <path
      d="M10.9539 22.5196C11.0511 22.4215 11.0511 22.2625 10.9539 22.1644C10.8567 22.0663 10.6992 22.0663 10.602 22.1644L8.97856 23.8026C8.88137 23.9006 8.88137 24.0597 8.97856 24.1577C9.07575 24.2558 9.23332 24.2558 9.33051 24.1577L10.9539 22.5196Z"
      fill={props.fill}
    />
    <path
      d="M21.4885 9.24586C21.4201 9.19744 21.351 9.14993 21.2814 9.10334L20.4475 10.3502C20.5061 10.3894 20.5643 10.4294 20.6219 10.4702L21.4885 9.24586Z"
      fill={props.fill}
    />
    <path
      d="M21.8998 9.55403C21.9655 9.60607 22.0305 9.65898 22.0947 9.71274L21.1324 10.8634C21.0783 10.8181 21.0236 10.7735 20.9682 10.7297L21.8998 9.55403Z"
      fill={props.fill}
    />
    <path
      d="M23.3427 10.9721C23.4439 11.0954 23.542 11.2213 23.6369 11.3495L22.4311 12.2417C22.3511 12.1337 22.2685 12.0277 22.1833 11.9239L23.3427 10.9721Z"
      fill={props.fill}
    />
    <path
      d="M24.2272 12.25C24.1475 12.1118 24.0642 11.9757 23.9776 11.8417L22.718 12.6562C22.7909 12.769 22.861 12.8837 22.9282 13L24.2272 12.25Z"
      fill={props.fill}
    />
    <path
      d="M24.6479 13.0676C24.7485 13.2889 24.8406 13.514 24.924 13.7424L23.515 14.2568C23.4448 14.0644 23.3672 13.8749 23.2824 13.6885L24.6479 13.0676Z"
      fill={props.fill}
    />
    <path
      d="M25.4289 15.8403C25.4576 16.0735 25.4776 16.3076 25.489 16.5423L23.9907 16.6145C23.9812 16.4169 23.9643 16.2198 23.9402 16.0234L25.4289 15.8403Z"
      fill={props.fill}
    />
    <path
      d="M25.4198 18.2318C25.4676 17.8665 25.4941 17.4987 25.4991 17.1303L23.9992 17.1097C23.995 17.4199 23.9727 17.7297 23.9325 18.0373L25.4198 18.2318Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M23.3324 26.5C26.1716 24.3054 28 20.8662 28 17C28 10.3726 22.6274 5 16 5C9.37258 5 4 10.3726 4 17C4 20.8662 5.82837 24.3054 8.66761 26.5H23.3324ZM5.5 17C5.5 20.2047 6.93568 23.074 9.19894 25H22.8011C25.0643 23.074 26.5 20.2047 26.5 17C26.5 11.201 21.799 6.5 16 6.5C10.201 6.5 5.5 11.201 5.5 17Z"
      fill={props.fill}
    />
  </svg>
);

Speedsensor.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Speedsensor;
