/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  cx?: any;
  cy?: any;
  r?: any;
  stroke?: any;
  strokeWidth?: any;
  strokeLinecap?: any;
  className?: string;
}

const AcausalHydraulicPressuresource: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <circle
      cx={props.cx}
      cy={props.cy}
      r={props.r}
      stroke={props.stroke}
      strokeWidth={props.strokeWidth}
    />
    <path
      d="M2 16L4 16"
      stroke={props.stroke}
      strokeWidth={props.strokeWidth}
      strokeLinecap={props.strokeLinecap}
    />
    <path
      d="M28 16L30 16"
      stroke={props.stroke}
      strokeWidth={props.strokeWidth}
      strokeLinecap={props.strokeLinecap}
    />
    <path
      d="M19.75 17.7143C19.75 18.8182 19.3679 19.6853 18.7453 20.2783C18.1193 20.8745 17.1902 21.25 16 21.25C14.8098 21.25 13.8807 20.8745 13.2547 20.2783C12.6321 19.6853 12.25 18.8182 12.25 17.7143C12.25 16.5217 12.9195 15.1037 13.8139 13.7745C14.5843 12.6295 15.4566 11.6445 16 11.0695C16.5434 11.6445 17.4157 12.6295 18.1861 13.7745C19.0805 15.1037 19.75 16.5217 19.75 17.7143Z"
      stroke={props.stroke}
      strokeWidth={props.strokeWidth}
    />
  </svg>
);

AcausalHydraulicPressuresource.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: 'none',
  xmlns: 'http://www.w3.org/2000/svg',
  cx: '16',
  cy: '16',
  r: '11.25',
  stroke: '#1D3131',
  strokeWidth: '1.5',
  strokeLinecap: 'round',
  className: '',
};

export default AcausalHydraulicPressuresource;
