import { t } from '@lingui/macro';
import { DownsamplingAlgorithm } from 'app/apiGenerated/generatedApiTypes';
import React from 'react';
import TooltipButton from 'ui/common/Button/TooltipButton';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import {
  getDownloadDataButtonIcon,
  useDownloadResults,
} from 'ui/navbar/useDownloadResults';

interface Props {
  simulationId: string;
  modelId: string;
  modelName: string;
  signalNames: string[];
  downsamplingAlgorithm?: DownsamplingAlgorithm;
  areResultsAvailable: boolean;
}

export const DownloadResultsButton: React.FC<Props> = ({
  simulationId,
  modelId,
  modelName,
  signalNames,
  downsamplingAlgorithm,
  areResultsAvailable,
}) => {
  const { dataDownloadLoading, downloadData } = useDownloadResults();

  const isEnabled = areResultsAvailable;

  return (
    <TooltipButton
      tooltip={t({
        id: 'simulationSummary.simulationButton.DownloadSimulationResults.tooltip',
        message: 'Download simulation results',
      })}
      Icon={getDownloadDataButtonIcon(isEnabled, dataDownloadLoading)}
      disabled={!isEnabled}
      onClick={() =>
        downloadData({
          simulationId,
          modelId,
          modelName,
          signalNames,
          downsamplingAlgorithm,
        })
      }
      testId="simulations-menu-download-simulation-results-button"
      variant={ButtonVariants.LargeTertiary}
    />
  );
};
