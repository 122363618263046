import blockTypeNameToInstanceDefaults from 'app/blockClassNameToInstanceDefaults';
import {
  BlockClassName,
  ModelDiagram,
} from 'app/generated_types/SimulationModel';
import { SubdiagramBlockClassName, nodeTypeIsConditional } from 'app/helpers';
import { createDirectLink } from 'app/utils/insertNodeUtils';
import { v4 as uuid } from 'uuid';

const DEFAULT_DISTANCE_BETWEEN_INPORT_AND_OUTPORT_BLOCKS = 200;

/** Skip the 0th inport block if we are in a conditional container.
 *  It has a static port `enabled`. */
export function inportMinIndexForSubdiagram(
  nodeType: BlockClassName | undefined,
): number {
  return nodeTypeIsConditional(nodeType) ? 1 : 0;
}

export function createDefaultSubdiagram(
  subdiagramType: SubdiagramBlockClassName,
): ModelDiagram {
  const defaultInport = blockTypeNameToInstanceDefaults('core.Inport');
  // HACK: non-refsubmodel blocks choke on this parameter - should be fixed on sim side
  // (also present in subdiagramUtils.ts)
  if (subdiagramType !== 'core.ReferenceSubmodel') {
    delete defaultInport.parameters.default_value;
  }
  defaultInport.name = `Inport_${inportMinIndexForSubdiagram(subdiagramType)}`;
  defaultInport.uiprops = {
    x: 0,
    y: 0,
  };

  const defaultOutport = blockTypeNameToInstanceDefaults('core.Outport');
  defaultOutport.name = 'Outport_0';
  defaultOutport.uiprops = {
    x: DEFAULT_DISTANCE_BETWEEN_INPORT_AND_OUTPORT_BLOCKS,
    y: 0,
  };

  const defaultLink = createDirectLink(
    {
      node: defaultInport.uuid,
      port: 0,
    },
    {
      node: defaultOutport.uuid,
      port: 0,
    },
  );

  const diagram: ModelDiagram = {
    uuid: uuid(),
    nodes: [defaultInport, defaultOutport],
    links: [defaultLink],
  };

  return diagram;
}
