import styled from '@emotion/styled/macro';
import React from 'react';
import MenuItemButton from 'ui/common/Menu/items/MenuItemButton';
import MenuItemInput from 'ui/common/Menu/items/MenuItemInput';
import MenuItemTitle from 'ui/common/Menu/items/MenuItemTitle';
import Select from 'ui/common/Menu/items/Select';
import Separator from 'ui/common/Menu/items/Separator';
import Submenu from 'ui/common/Menu/items/Submenu';
import {
  ChangedItem,
  ItemType,
  MenuItemConfig,
  SelectConfig,
} from 'ui/common/Menu/menuItemTypes';
import { MENU_WIDTH } from './menuConstants';

const MenuContentWrapper = styled.div`
  position: relative;
  width: ${MENU_WIDTH}px;
  font-size: ${({ theme }) => theme.typography.font.base.size};
  font-weight: ${({ theme }) => theme.typography.font.base.weight};
  color: ${({ theme }) => theme.colors.text.primary};
  border: 1px solid ${({ theme }) => theme.colors.grey[10]};
`;

const MenuSeparator = styled(Separator)`
  margin-bottom: ${({ theme }) => theme.spacing.small};
`;

interface Props {
  /** List of items to appear inside the menu */
  items: MenuItemConfig[];

  /** Callback that will collect all the changed items on the menu */
  onSubmitValue?: (changed: ChangedItem) => void;

  /** Callback that will be called when the menu is closed */
  isMenuOpen: boolean;

  /** Function to trigger the menu to close */
  triggerCloseMenu: () => void;
}

const MenuContent: React.FC<Props> = ({
  items,
  onSubmitValue,
  isMenuOpen,
  triggerCloseMenu,
}) => {
  const [selections, setSelections] = React.useState<ChangedItem>(() => {
    let initialSelections: ChangedItem = {};
    items
      .filter((i): i is SelectConfig => i.type === ItemType.Select)
      .forEach((i: SelectConfig) => {
        initialSelections[i.id] = [i.initialSelected || ''];
      });
    return initialSelections;
  });
  const [submenuOpened, setSubmenuOpened] = React.useState('');

  React.useEffect(() => {
    if (!isMenuOpen && submenuOpened) {
      setSubmenuOpened('');
    }
  }, [isMenuOpen, submenuOpened]);

  const onSelectItem = (
    itemType: ItemType,
    itemId: string,
    selectionId: string,
  ) => {
    let newSelection = {};
    switch (itemType) {
      case ItemType.Select:
        newSelection = { ...selections, [itemId]: [selectionId] };
        break;
      default:
        break;
    }
    setSelections(newSelection);
    onSubmitValue?.(newSelection);
  };

  // These should be individual components. Those components would then be composable.
  // Even better... use chakra-ui or material-ui with built in interactions.
  // See ProjectMenu for where this builder approach gets unsustainable.
  // - 2023/05/01 jay
  const renderItem = (item: MenuItemConfig, idx: number) => {
    switch (item.type) {
      case ItemType.Button:
        return (
          <MenuItemButton
            key={`${item.type}_${idx}`}
            shortcut={item.shortcut}
            content={item.content}
            disabled={item.disabled}
            onClick={(e) => {
              if (!item?.disabled) {
                item?.onClick?.(e);
                triggerCloseMenu();
              }
            }}
          />
        );
      case ItemType.Input:
        return (
          <MenuItemInput
            key={`${item.type}_${idx}`}
            text={item.text}
            hasBorder={item.hasBorder}
            pattern={item.pattern}
            value={item.value}
            onSubmitValue={(val) => onSubmitValue?.({ [item.id]: [val] })}
            validationRules={item.validationRules}
          />
        );
      case ItemType.Separator:
        return <MenuSeparator key={`${item.type}_${idx}`} />;
      case ItemType.Select:
        return (
          <Select
            key={item.id || `${item.type}_${idx}`}
            options={item.options}
            onChange={(id) => onSelectItem(item.type, item.id, id)}
            selectionId={selections[item.id]?.[0]}
          />
        );
      case ItemType.Submenu:
        const onSubMenuClick = () => {
          if (submenuOpened === `${item.type}_${idx}`) {
            setSubmenuOpened('');
          } else {
            setSubmenuOpened(`${item.type}_${idx}`);
          }
        };
        return (
          <Submenu
            key={`${item.type}_${idx}`}
            items={item.items}
            text={item.text}
            onSubmitValue={onSubmitValue}
            opened={submenuOpened === `${item.type}_${idx}`}
            onClick={onSubMenuClick}
          />
        );
      case ItemType.Title:
        return <MenuItemTitle key={`${item.type}_${idx}`} text={item.text} />;
      default:
        return null;
    }
  };

  return (
    <MenuContentWrapper>
      {items.map((item, idx) => renderItem(item, idx))}
    </MenuContentWrapper>
  );
};

export default MenuContent;
