import styled from '@emotion/styled/macro';
import {
  OptimalParameterJson as OptimalParameter,
  OptimizationMetricJson,
  OptimizationResultsJson as OptimizationResults,
} from 'app/generated_types/collimator/dashboard/serialization/ui_types.gen';
import { ModelLogLine, OutputLogLevel } from 'app/slices/simResultsSlice';
import React from 'react';
import { OutputContainer, fetchBokehLib } from 'ui/appBottomBar/Output';
import OutputLogLine from 'ui/appBottomBar/OutputLogLine';
import { ArrowRight } from 'ui/common/Icons/Standard';
import Input from 'ui/common/Input/Input';
import SectionHeading from 'ui/common/Inputs/SectionHeading';
import { ExpandableDetailsContainer } from './ExpandableDetailsContainer';

const ResultsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const OptimalParamsWrapper = styled.div`
  margin-left: ${({ theme }) => theme.spacing.normal};
  margin-bottom: ${({ theme }) => theme.spacing.normal};
  display: flex;
  flex-direction: column;
`;

const OptimalParamRow = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;
  padding-bottom: ${({ theme }) => theme.spacing.normal};
`;

const OptimalParamName = styled.span`
  margin-left: ${({ theme }) => theme.spacing.small};
  width: 50%;
`;

const OptimalParamValue = styled.div`
  width: 50%;
`;

const MetricPlotContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const FixedHeight = styled.div`
  height: 300px;
  overflow-y: auto;
`;

const MinHeight = styled.div`
  min-height: 300px;
`;

const createFigure = (
  Bokeh: any,
  title: string,
  x_range: number[],
  y_range: number[],
  width?: number,
  height?: number,
) => {
  const figure = Bokeh.Plotting.figure({
    title,
    x_range,
    y_range,
    height: height ?? 250,
    width: width ?? 600,
    tools: 'pan,crosshair,wheel_zoom,box_zoom,reset,save',
  });

  return figure;
};

// this adjusts the range to be non-zero even with very large values
function adjustRange(range: number[]): [number, number] {
  if (range[0] === range[1]) {
    range[0] -= range[0] / 1e6 + 1;
    range[1] += range[1] / 1e6 + 1;
  }
  return [range[0], range[1]];
}

const SimpleMetricsGraph: React.FC<{ metric: OptimizationMetricJson }> = ({
  metric,
}) => {
  const bokehOutEl = React.useRef<HTMLDivElement | null>(null);

  const [bokehSource, setBokehSource] = React.useState<any>(null);
  const [bokehFigure, setBokehFigure] = React.useState<any>(null);
  const [initialMetricsData, setInitialMetricsData] = React.useState<
    OptimizationMetricJson | undefined
  >();

  React.useEffect(() => {
    async function initBokehPlot(metric: OptimizationMetricJson | undefined) {
      const Bokeh = await fetchBokehLib();
      if (!bokehOutEl.current || !metric) return;
      if (!Array.isArray(metric.value) || metric.value.length <= 0) return;

      bokehOutEl.current
        .querySelectorAll('.bk-root')
        .forEach((el) => el.remove());

      const array = metric.value as number[];
      if (array.length === 1) array.push(array[0]);

      const x_range = [0, array.length - 1];
      const y_range = [Math.min(...array), Math.max(...array)];
      adjustRange(x_range);
      adjustRange(y_range);

      const width = bokehOutEl.current.clientWidth;
      const figure = createFigure(Bokeh, metric.name, x_range, y_range, width);
      setBokehFigure(figure);

      const source = new Bokeh.ColumnDataSource({
        data: { x: Object.keys(array), y: array },
      });
      setBokehSource(source);

      figure.line({
        x: { field: 'x' },
        y: { field: 'y' },
        source,
        line_color: 'blue',
        line_width: 2,
      });

      const hover = new Bokeh.HoverTool();
      hover.tooltips = [[metric.name, '@y']];
      figure.add_tools(hover);

      Bokeh.Plotting.show(figure, bokehOutEl.current);
    }
    initBokehPlot(initialMetricsData);
  }, [initialMetricsData, setBokehSource, setBokehFigure]);

  React.useEffect(() => {
    if (!initialMetricsData && metric) {
      setInitialMetricsData(metric);
    }
  }, [metric, initialMetricsData, setInitialMetricsData]);

  React.useEffect(() => {
    if (!metric || !bokehSource || !bokehFigure) return;
    if (!Array.isArray(metric.value) || metric.value.length <= 0) return;

    const array = metric.value as number[];
    if (array.length === 1) array.push(array[0]);

    const x_range = [0, array.length - 1];
    const y_range = [Math.min(...array), Math.max(...array)];
    adjustRange(x_range);
    adjustRange(y_range);

    bokehFigure.x_range.start = x_range[0];
    bokehFigure.x_range.end = x_range[1];
    bokehFigure.y_range.start = y_range[0];
    bokehFigure.y_range.end = y_range[1];
    bokehSource.data = { x: Object.keys(array), y: array };
  }, [metric, bokehSource, bokehFigure]);

  return <MetricPlotContainer ref={bokehOutEl} />;
};

const SimpleMetric: React.FC<{ metric: OptimizationMetricJson }> = ({
  metric,
}) => (
  <div>
    <strong>{metric.name}</strong>
    <span>{metric.value}</span>
  </div>
);

export const OptimizationMetrics: React.FC<{
  metrics: OptimizationMetricJson[];
}> = ({ metrics }) => (
  <div>
    {metrics.length ? (
      metrics.map((metric, i) =>
        Array.isArray(metric.value) ? (
          <SimpleMetricsGraph key={i} metric={metric} />
        ) : (
          <SimpleMetric key={i} metric={metric} />
        ),
      )
    ) : (
      <span>No metrics available</span>
    )}
  </div>
);

const OptimalParam: React.FC<{
  param: OptimalParameter;
  onApply: (param: OptimalParameter) => void;
}> = ({ param, onApply }) => (
  <OptimalParamRow>
    <OptimalParamName>
      {param.block_namepath
        ? `${param.block_namepath.join('.')}.${param.param_name}`
        : param.param_name}
    </OptimalParamName>
    <OptimalParamValue>
      <Input
        value={param.optimal_value}
        hasBorder
        RightIcon={ArrowRight}
        onClickRightIcon={() => onApply(param)}
      />
    </OptimalParamValue>
  </OptimalParamRow>
);

const OptimizationResultsContent: React.FC<{
  logs: ModelLogLine[];
  results?: OptimizationResults;
  metrics?: OptimizationMetricJson[];
  error?: string;
  onApplySingleParameter: (param: OptimalParameter) => void;
}> = ({ results, error, logs, metrics, onApplySingleParameter }) => {
  const logsToShow = React.useMemo(() => {
    if (!error) return logs;
    return [...logs, { level: OutputLogLevel.ERR, message: error }];
  }, [logs, error]);

  return (
    <ResultsWrapper>
      <SectionHeading noBorder>Optimal Parameter Values</SectionHeading>
      {results?.optimal_parameters ? (
        <OptimalParamsWrapper>
          {results.optimal_parameters.map((param) => (
            <OptimalParam param={param} onApply={onApplySingleParameter} />
          ))}
        </OptimalParamsWrapper>
      ) : (
        <p>No optimal parameters found, check the logs</p>
      )}
      <MinHeight>
        <SectionHeading noBorder>Optimization Performance</SectionHeading>
        {metrics && metrics.length > 0 ? (
          <OptimizationMetrics metrics={metrics} />
        ) : (
          <p>No performance data was recorded</p>
        )}
      </MinHeight>
      <ExpandableDetailsContainer label="Show logs" defaultExpanded={!!error}>
        <FixedHeight>
          <OutputContainer noBottomSpace border>
            {logsToShow.map((log, i) => (
              <OutputLogLine key={i} rawLog={log} />
            ))}
          </OutputContainer>
        </FixedHeight>
      </ExpandableDetailsContainer>
    </ResultsWrapper>
  );
};

export default OptimizationResultsContent;
