import { t } from '@lingui/macro';
import React from 'react';
import TooltipButton from 'ui/common/Button/TooltipButton';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import { Download } from 'ui/common/Icons/Standard';
import { ProjectItemType } from 'ui/dashboard/dashboardTypes';
import { useProjectDetailActions } from 'ui/dashboard/projectDetail/useProjectDetailActions';
import { ButtonProps } from './ButtonProps';

export const ProjectElementDownloadFileButton: React.FC<ButtonProps> = ({
  selectedItems,
}) => {
  const { downloadDataFile, type } = useProjectDetailActions(selectedItems);

  const hasValidType = type === ProjectItemType.PROJECTFILE;

  const tooltip = t({
    id: 'dashboard.projectsDetailActionButtons.downloadDataButton.Label',
    message: 'Download Datafile',
  });

  if (hasValidType) {
    return (
      <TooltipButton
        tooltip={tooltip}
        variant={ButtonVariants.SmallSecondary}
        testId="project-element-rename-button"
        Icon={Download}
        largeIcon
        onClick={downloadDataFile}
      />
    );
  }

  return null;
};
