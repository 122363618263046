/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const DataTypes: React.FC<Props> = (props) => (
  <svg viewBox="0 0 24 24" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M5 13.75C5 13.4739 5.22386 13.25 5.5 13.25H10.5C10.7761 13.25 11 13.4739 11 13.75V18.75C11 19.0261 10.7761 19.25 10.5 19.25H5.5C5.22386 19.25 5 19.0261 5 18.75V13.75ZM6.5 17.75V14.75H9.5V17.75H6.5Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M11.5 8.25C11.5 10.183 9.933 11.75 8 11.75C6.067 11.75 4.5 10.183 4.5 8.25C4.5 6.317 6.067 4.75 8 4.75C9.933 4.75 11.5 6.317 11.5 8.25ZM10 8.25C10 9.35457 9.10457 10.25 8 10.25C6.89543 10.25 6 9.35457 6 8.25C6 7.14543 6.89543 6.25 8 6.25C9.10457 6.25 10 7.14543 10 8.25Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M16.4402 5.56758C16.2515 5.21698 15.7485 5.21698 15.5598 5.56758L12.8969 10.513C12.7175 10.8461 12.9588 11.25 13.3371 11.25H18.6629C19.0412 11.25 19.2825 10.8461 19.1031 10.513L16.4402 5.56758ZM16 7.63889L14.8333 9.80556H17.1667L16 7.63889Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M15.7061 12.6797C15.8814 12.5524 16.1186 12.5524 16.2939 12.6797L19.2726 14.8438C19.4478 14.9712 19.5211 15.1969 19.4542 15.4029L18.3165 18.9045C18.2495 19.1105 18.0575 19.25 17.8409 19.25H14.1591C13.9425 19.25 13.7505 19.1105 13.6835 18.9045L12.5458 15.4029C12.4789 15.1969 12.5522 14.9712 12.7274 14.8438L15.7061 12.6797ZM16 14.3203L17.8031 15.6303L17.1144 17.75H14.8856L14.1969 15.6303L16 14.3203Z"
      fill={props.fill}
    />
  </svg>
);

DataTypes.defaultProps = {
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default DataTypes;
