import { PortSide } from 'app/common_types/PortTypes';
import { PortVariant } from 'app/generated_types/SimulationModel';
import * as NVG from 'nanovg-js';
import { LINE_COLORS } from './coloring';
import { drawImage } from './drawImage';
import { fillCircle, fillHalfCircle, getDrawContext } from './drawPrimitives';
import { RendererState } from './modelRenderer';
import {
  RasterLoadState,
  getOrInitLoadImageFromStore,
} from './rasterTextureStore';

const d2r = (d: number) => d * (Math.PI / 180);

export function drawPort(
  nvg: NVG.Context,
  rs: RendererState,
  x: number,
  y: number,
  zoom: number,
  side: PortSide,
  portHasLink: boolean,
  signalConnected: boolean,
  hovering: boolean,
  flipped: boolean,
  portConnectedColor: NVG.Color | undefined,
  userDrawingAttachedLink: boolean,
  hollow: boolean,
  hasError: boolean,
  acausalDomain?: Extract<
    PortVariant,
    { variant_kind: 'acausal' }
  >['acausal_domain'],
): void {
  if (acausalDomain) {
    const portColor: NVG.Color = LINE_COLORS[acausalDomain];
    fillCircle(rs, nvg, x, y, 6, portColor);

    const scale = 4;
    const rasterID = `ac_port_${acausalDomain}_${scale}x`;
    const rasterMeta = getOrInitLoadImageFromStore(
      nvg,
      `${process.env.PUBLIC_URL}/assets/${rasterID}.png`,
      rasterID,
      scale,
    );

    if (rasterMeta?.loadState === RasterLoadState.Loaded) {
      const rw = (rasterMeta.width / scale) * rs.zoom;
      const rh = (rasterMeta.height / scale) * rs.zoom;
      const rx = x * rs.zoom - rw / 2;
      const ry = y * rs.zoom - rh / 2;
      drawImage(rs, nvg, rasterMeta.id, rx, ry, rw, rh, 0, 1);
    }

    return;
  }

  if (side === PortSide.Output) {
    let portColor: NVG.Color;
    if (portHasLink) {
      if (!signalConnected && !userDrawingAttachedLink) {
        portColor = LINE_COLORS.link_disconnected;
      } else {
        portColor = portConnectedColor || LINE_COLORS.normal;
      }
    } else if (hovering) {
      portColor = LINE_COLORS.unconnected_port_hovering;
    } else {
      portColor = LINE_COLORS.unconnected_port;
    }

    fillHalfCircle(
      rs,
      nvg,
      x,
      y,
      hovering && !portHasLink ? 6 : 4,
      portHasLink ? 0 : d2r(flipped ? 90 : -90),
      portHasLink ? Math.PI * 2 : d2r(flipped ? -90 : 90),
      portColor,
    );
  }

  if (side === PortSide.Input) {
    let portColor: NVG.NVGcolor = LINE_COLORS.input_port;

    const hoverScale = hovering && !portHasLink ? 1.5 : 1;
    const rawScale = Math.round(window.devicePixelRatio * rs.zoom * hoverScale);
    const scale = rawScale > 2 ? 4 : rawScale < 1 ? 1 : rawScale;
    const suffix = !portHasLink && hollow ? '_trigger' : '';

    if (
      (portHasLink && !signalConnected && !userDrawingAttachedLink) ||
      hasError
    ) {
      portColor = LINE_COLORS.link_disconnected;
    } else if (portHasLink && portConnectedColor) {
      portColor = portConnectedColor;
    }

    const baseRasterID = `${
      portHasLink || hasError ? 'link_end_input_blank' : 'input_port'
    }${suffix}`;
    const scaledRasterID = `${baseRasterID}_${scale}x`;

    const rasterMeta = getOrInitLoadImageFromStore(
      nvg,
      `${process.env.PUBLIC_URL}/assets/${scaledRasterID}.png`,
      scaledRasterID,
      scale,
    );

    if (rasterMeta?.loadState === RasterLoadState.Loaded) {
      const raw_w = (rasterMeta.width * hoverScale) / scale;
      const raw_h = (rasterMeta.height * hoverScale) / scale;
      const raw_x = !portHasLink ? x : flipped ? x + 2 : x - 2;
      const raw_y = y - raw_h / 2;

      const rx = raw_x * rs.zoom;
      const ry = raw_y * rs.zoom;
      const rw = flipped ? -raw_w * rs.zoom : raw_w * rs.zoom;
      const rh = raw_h * rs.zoom;

      const alpha = !portHasLink && !hovering && !hasError ? 0.4 : 1;

      drawImage(rs, nvg, rasterMeta.id, rx, ry, rw, rh, 0, alpha, portColor);

      if (portHasLink && !userDrawingAttachedLink) {
        // this is drawing a tiny bit of link that does not exactly
        // touch the port raster image.
        const ctx = getDrawContext(rs, nvg);
        ctx.beginPath();
        ctx.rect(
          (flipped ? x + 1 : x - 2) * rs.zoom,
          (y - 1) * rs.zoom,
          2 * rs.zoom,
          2 * rs.zoom,
        );
        ctx.fillColor(portColor);
        ctx.fill();
      }
    }
  }
}
