type OpenAiModel = {
  name: string;
  id: string;
  maxTokens: number;
};

export const OpenAiModels: OpenAiModel[] = [
  // The first entry is the default
  { id: 'gpt-4o', name: 'GPT-4o', maxTokens: 128000 },
  { id: 'gpt-4-turbo', name: 'GPT-4-Turbo', maxTokens: 128000 },
  { id: 'gpt-4-0125-preview', name: 'GPT-4-Turbo (01/25)', maxTokens: 128000 },
  {
    id: 'gpt-4-turbo-preview',
    name: 'GPT-4-Turbo preview (latest)',
    maxTokens: 128000,
  },
  { id: 'gpt-4-1106-preview', name: 'GPT-4-Turbo (11/06)', maxTokens: 128000 },
  { id: 'gpt-4-0613', name: 'GPT-4 (06/13)', maxTokens: 8192 },
  {
    id: 'gpt-3.5-turbo-16k-0613',
    name: 'GPT-3.5 Turbo 16K (06/13)',
    maxTokens: 16384,
  },
  { id: 'gpt-3.5-turbo-0613', name: 'GPT-3.5 Turbo (06/13)', maxTokens: 4096 },
  { id: 'gemini-1.5-pro-latest', name: 'Gemini 1.5 Pro', maxTokens: 1048576 },
  { id: 'none', name: '(None)', maxTokens: 0 },

  // Do not support functions
  // { id: 'gpt-4', name: 'GPT-4', maxTokens: 8192 },
  // { id: 'gpt-3.5-turbo', name: 'GPT-3.5 Turbo', maxTokens: 4096 },

  // 404 not found:
  // { id: 'gpt-4-32k', name: 'GPT-4 32k', maxTokens: 32768 },
  // { id: 'gpt-4-32k-0314', name: 'GPT-4 32k (03/14)', maxTokens: 32768 },

  // Not chat models:
  // { id: 'code-davinci-002', name: 'Davinci 002 (Code)', maxTokens: 8001 },
  // { id: 'text-davinci-002', name: 'Davinci 002', maxTokens: 4097 },
  // { id: 'text-davinci-003', name: 'Davinci 003', maxTokens: 4097 },
];
