/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Conditional: React.FC<Props> = (props) => (
  <svg viewBox="0 0 24 24" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.5 10.75C7.5 10.4739 7.72386 10.25 8 10.25H11C11.2761 10.25 11.5 10.4739 11.5 10.75V13.25C11.5 13.5261 11.2761 13.75 11 13.75H8C7.72386 13.75 7.5 13.5261 7.5 13.25V10.75Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M12.5 10.75C12.5 10.4739 12.7239 10.25 13 10.25H16C16.2761 10.25 16.5 10.4739 16.5 10.75V13.25C16.5 13.5261 16.2761 13.75 16 13.75H13C12.7239 13.75 12.5 13.5261 12.5 13.25V10.75ZM13.5 12.75V11.25H15.5V12.75H13.5Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M4 5.00001C4 4.44772 4.44771 4.00001 5 4.00001H19C19.5523 4.00001 20 4.44772 20 5.00001V19C20 19.5523 19.5523 20 19 20H5C4.44771 20 4 19.5523 4 19V5.00001ZM5.5 18.5V5.50001H18.5V18.5H5.5Z"
      fill={props.fill}
    />
  </svg>
);

Conditional.defaultProps = {
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Conditional;
