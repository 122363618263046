import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Coordinate } from 'app/common_types/Coordinate';
import {
  AnnotationInstance,
  LinkInstance,
  NodeInstance,
  StateLinkInstance,
  StateMachineDiagram,
  StateNodeInstance,
  SubmodelsSection,
} from 'app/generated_types/SimulationModel';
import { ModelCommentAnchorType } from 'ui/modelEditor/ModelCommentsDisplay';

export enum NavbarContext {
  None,
  Dashboard,
  ModelEditor,
  DataExplorer,
  ScriptEditor,
  BusTypeEditor,
  Requirements,
  NotebookViewer,
  MarkdownViewer,
}

export enum BrowserSection {
  None,
  Project,
}

export enum RightSidebarSection {
  Properties,
  Simulation,
  Outputs,
  VersionHistory,
}

export enum DiagramFooterTab {
  None,
  Visualizer,
  Output,
  Console,
  AiAssistant,
  MediaVisualizer,
}

export type InAppClipboard = {
  projectId: string;
  copiedSubmodelsSection: SubmodelsSection;
  copiedStateMachines: { [oldId: string]: StateMachineDiagram };
  nodes: NodeInstance[];
  links: LinkInstance[];
  annotations: AnnotationInstance[];
  stateMachineNodes: StateNodeInstance[];
  stateMachineLinks: StateLinkInstance[];
};

export interface UIFlagsState {
  textInputFocused: boolean;
  htmlTextSelected: boolean;
  editingNodeNameUUID: string | null;
  editingAnnotationTextUUID: string | null;
  // this is temporary until actual clipboard support is more reasonable
  inAppClipboard: InAppClipboard;
  hideLibraryDrag: boolean;
  modelEditorSection: BrowserSection;
  isLeftSidebarOpen: boolean;
  currentRightSidebarSection: RightSidebarSection;
  isRightSidebarOpen: boolean;
  diagramFooterTab: DiagramFooterTab;
  // Used in lieu of having react router context
  navbarContext: NavbarContext;
  showSignalNamesInModel: boolean;
  showDatatypesInModel: boolean;
  showSignalDomainsInModel: boolean;
  showAllDiscreteLevelsInModel: boolean;
  rendererStateInitialized: boolean;
  addingAnnotation: boolean;
  allowConsoleTerminal: boolean;
  showingCommandPalette: boolean;
  commandPaletteCoordWorldSpace: Coordinate;
  commandPaletteCoordScreenSpace: Coordinate;
  canEditModel: boolean;

  // Dynamically load pyodide
  requestLoadPython: boolean;

  // flag to help with opening output tab
  // on ensemle sims completion
  forceOpenOutputTabOnSimComplete: boolean;

  // SINDy or PID blocks that are currently being auto-fit / tuned
  autoFittingOrTuningBlockUUIDs: { [blockUUID: string]: boolean };

  // helpers for sim control buttons
  modelDataUpdating: boolean;
  simConfigDataUpdating: boolean;

  // easy ad-hoc enabling of onboarding tour state
  onboardingStartedManually: boolean;

  commenting:
    | { addingNew: false }
    | { addingNew: true; commentAnchorData: ModelCommentAnchorType };
  addCommentToolActive: boolean;
  modelSourceCodeCache?: string;

  ensemblePlotOptions: {
    overlay: boolean;
    standard_deviations: boolean;
    iqr_error: boolean;
  };

  renderDebug: boolean;
  renderCanvas2d: boolean;
}

const initialState: UIFlagsState = {
  textInputFocused: false,
  htmlTextSelected: false,
  editingNodeNameUUID: null,
  editingAnnotationTextUUID: null,
  inAppClipboard: {
    projectId: '',
    nodes: [],
    links: [],
    annotations: [],
    copiedSubmodelsSection: { references: {}, diagrams: {} },
    copiedStateMachines: {},
    stateMachineNodes: [],
    stateMachineLinks: [],
  },
  hideLibraryDrag: false,
  modelEditorSection: BrowserSection.None,
  isLeftSidebarOpen: true,
  currentRightSidebarSection: RightSidebarSection.Properties,
  isRightSidebarOpen: true,
  diagramFooterTab: DiagramFooterTab.None,
  navbarContext: NavbarContext.None,
  showSignalNamesInModel: false,
  showDatatypesInModel: false,
  showSignalDomainsInModel: false,
  showAllDiscreteLevelsInModel: false,
  rendererStateInitialized: false,
  addingAnnotation: false,
  allowConsoleTerminal: false,
  showingCommandPalette: false,
  commandPaletteCoordWorldSpace: { x: 0, y: 0 },
  commandPaletteCoordScreenSpace: { x: 0, y: 0 },
  canEditModel: false,
  requestLoadPython: false,
  forceOpenOutputTabOnSimComplete: false,
  autoFittingOrTuningBlockUUIDs: {},
  modelDataUpdating: false,
  simConfigDataUpdating: false,
  onboardingStartedManually: false,
  commenting: { addingNew: false },
  addCommentToolActive: false,
  ensemblePlotOptions: {
    overlay: true,
    standard_deviations: true,
    iqr_error: true,
  },
  renderDebug: false,

  // Highly experimental
  renderCanvas2d: false,
};

type UIFlagUpdatePayload = {
  [key in keyof UIFlagsState]?: UIFlagsState[key];
};

const uiFlagsSlice = createSlice({
  name: 'uiFlags',
  initialState,
  reducers: {
    setUIFlag(state, action: PayloadAction<UIFlagUpdatePayload>) {
      const hasAChange = (
        Object.keys(action.payload) as (keyof UIFlagsState)[]
      ).some((key) => state[key] !== action.payload[key]);

      if (hasAChange) {
        return {
          ...state,
          ...action.payload,
        };
      }
    },
    toggleUIFlag(state, action: PayloadAction<keyof UIFlagsState>) {
      if (typeof state[action.payload] !== 'boolean') return;

      return {
        ...state,
        [action.payload]: !state[action.payload],
      };
    },
    allLayersOff(state) {
      state.showSignalNamesInModel = false;
      state.showSignalDomainsInModel = false;
      state.showDatatypesInModel = false;
    },
    setNavbarContext(state, action: PayloadAction<NavbarContext>) {
      if (state.navbarContext !== action.payload) {
        state.navbarContext = action.payload;
      }
    },
    toggleModelEditorSection(state, action: PayloadAction<BrowserSection>) {
      if (state.modelEditorSection === action.payload) {
        state.modelEditorSection = BrowserSection.None;
      } else {
        state.modelEditorSection = action.payload;
      }
    },
    toggleLeftSidebarOpen(state) {
      state.isLeftSidebarOpen = !state.isRightSidebarOpen;
    },
    setRightSidebarTab(state, action: PayloadAction<RightSidebarSection>) {
      state.currentRightSidebarSection = action.payload;
    },
    toggleRightSidebarOpen(state) {
      state.isRightSidebarOpen = !state.isRightSidebarOpen;
    },
    closeFooter(state) {
      if (state.navbarContext === NavbarContext.ModelEditor) {
        state.diagramFooterTab = DiagramFooterTab.None;
      }
    },
    setDiagramFooterTab(state, action: PayloadAction<DiagramFooterTab>) {
      if (state.diagramFooterTab !== action.payload) {
        state.diagramFooterTab = action.payload;
      }
    },
    showVisualizerFooterTab(state) {
      if (state.diagramFooterTab === DiagramFooterTab.Output) {
        state.diagramFooterTab = DiagramFooterTab.Visualizer;
      }
    },
    requestLoadPython(state) {
      state.requestLoadPython = true;
    },
    markBlockAutoFittingOrTuning(state, action: PayloadAction<string>) {
      state.autoFittingOrTuningBlockUUIDs[action.payload] = true;
    },
    unmarkBlockAutoFittingOrTuning(state, action: PayloadAction<string>) {
      state.autoFittingOrTuningBlockUUIDs[action.payload] = false;
    },
  },
});

export const uiFlagsActions = uiFlagsSlice.actions;

export default uiFlagsSlice;
