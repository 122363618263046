import { useTheme } from '@emotion/react';
import styled from '@emotion/styled/macro';
import {
  DataEditor,
  EditableGridCell,
  GridCell,
  GridCellKind,
  GridColumn,
  GridSelection,
  Item,
  getDefaultTheme,
} from '@glideapps/glide-data-grid';
// eslint-disable-next-line import/no-unassigned-import
import '@glideapps/glide-data-grid/dist/index.css';
import {
  BusTypeSignal,
  ProjectScopeId,
} from 'app/apiGenerated/generatedApiTypes';
import { usePutBusTypeUpdateMutation } from 'app/enhancedApi';
import { useCallback, useRef, useState } from 'react';

// Seems the child needs to be 100% width to make the editor expand.
const BusTypeSpreadsheetWrapper = styled.div`
  width: 100%;
  > div:first-of-type {
    width: 100%;
  }
  border: 1px solid ${(props) => props.theme.colors.grey[10]};
`;

// Grid columns may also provide icon, overlayIcon, menu, style, and theme overrides
// Hard coded for now
const columns: GridColumn[] = [
  { title: 'Signal name', id: 'object_identifier', grow: 1 },
  { title: 'Description', id: 'object_number', grow: 3 },
  { title: 'Default value', id: 'object_heading', grow: 1 },
];

// Used for editing the data
const indexes: (keyof BusTypeSignal)[] = [
  'name',
  'description',
  'default_value',
];

type BusTypeSpreadsheetProps = {
  projectUuid: string;
  busTypeId: ProjectScopeId;
  signals: BusTypeSignal[];
};

const BusTypeSpreadsheet = ({
  projectUuid,
  busTypeId,
  signals,
}: BusTypeSpreadsheetProps) => {
  // useRef to let the DataEditor manage rendering for the most part.
  // Create shallow copy of RTK data for editing. onQueryStarted allows for optimisitic updates, but it's not part of our codegen.
  const signalsRef = useRef<BusTypeSignal[]>([...signals]);
  const [numRows, setNumRows] = useState(signals.length);

  const editorTheme = getDefaultTheme();
  const appTheme = useTheme();

  const [updateBusType] = usePutBusTypeUpdateMutation();
  const callUpdateBusType = useCallback(async () => {
    await updateBusType({
      projectUuid,
      busTypeId,
      busTypeUpdateRequest: {
        definition: {
          signals: signalsRef.current,
        },
      },
    });
  }, [busTypeId, projectUuid, updateBusType]);

  editorTheme.fontFamily = 'Barlow, sans-serif';
  editorTheme.baseFontStyle = appTheme.typography.font.standard.size;
  editorTheme.editorFontSize = appTheme.typography.font.standard.size;
  editorTheme.headerFontStyle = `700 ${appTheme.typography.font.standard.size}`;

  const getCellContent = useCallback(([col, row]: Item): GridCell => {
    const signal = signalsRef.current[row];
    if (!signal) {
      // Library breaks selection after edit on last row.
      // Return empty cell for now, which allows for the create row to be selected.
      return {
        kind: GridCellKind.Text,
        allowOverlay: true,
        data: '',
        displayData: '',
      };
    }

    // TODO style the empty state
    const name = signal.name;
    const description = signal.description;
    const defaultValue = signal.default_value;

    if (col === 0) {
      return {
        kind: GridCellKind.Text,
        allowOverlay: true,
        data: name,
        displayData: name,
      };
    }
    if (col === 1) {
      return {
        kind: GridCellKind.Text,
        allowOverlay: true,
        displayData: description,
        data: description,
      };
    }
    if (col === 2) {
      return {
        kind: GridCellKind.Text,
        data: defaultValue,
        allowOverlay: true,
        displayData: defaultValue,
      };
    }
    throw new Error();
  }, []);

  const onRowAppended = useCallback(() => {
    const newLen = signalsRef.current.push({
      name: '',
      description: '',
      default_value: '',
    });
    // Number of rows to display is a controlled input, so update it.
    setNumRows(newLen);
  }, []);

  const onCellEdited = useCallback((cell: Item, newValue: EditableGridCell) => {
    if (newValue.kind !== GridCellKind.Text) {
      console.error('Unexpected cell kind', newValue);
      return;
    }
    const [colNum, rowNum] = cell;
    const key = indexes[colNum];

    // Copy only the signal that is mutated
    const signalCopy = { ...signalsRef.current[rowNum] };
    signalCopy[key] = newValue.data.trim();

    signalsRef.current[rowNum] = signalCopy;
  }, []);

  // Batch the API call to update the bus type definition.
  const onCellsEdited = useCallback(() => {
    callUpdateBusType();
  }, [callUpdateBusType]);

  const validateCell = useCallback(
    (cell: Item, newValue: EditableGridCell, prevValue: GridCell) => {
      if (
        newValue.kind !== GridCellKind.Text ||
        prevValue.kind !== GridCellKind.Text
      ) {
        console.error('Unexpected cell kind', newValue);
        return false;
      }
      const [colNum, rowNum] = cell;
      const key = indexes[colNum];

      if (newValue.data === '') {
        // If it's empty, could be part of editing. Too noisy to error.
        return true;
      }

      if (key === 'name') {
        const otherNames = signalsRef.current.map((s, index) =>
          index === rowNum ? '' : s[key],
        );
        if (otherNames.includes(newValue.data)) {
          return false;
        }
      }

      return true;
    },
    [],
  );

  const onDelete = useCallback(
    (sel: GridSelection) => {
      if (!sel.current) {
        // Entire rows selected, remove the rows instead of clearing cells
        signalsRef.current = signalsRef.current.filter(
          (_, index) => !sel.rows.hasIndex(index),
        );
        setNumRows(signalsRef.current.length);
        callUpdateBusType();
        return false;
      }
      return true;
    },
    [callUpdateBusType],
  );

  return (
    <BusTypeSpreadsheetWrapper>
      <DataEditor
        getCellContent={getCellContent}
        columns={columns}
        rows={numRows}
        // Use the default implementation since data is small, we're not paginating.
        // https://grid.glideapps.com/docs/interfaces/DataEditorProps.html#getCellsForSelection
        getCellsForSelection
        onPaste
        theme={editorTheme}
        keybindings={{ search: true }}
        trailingRowOptions={{
          sticky: true,
          tint: true,
          hint: 'Add a new signal',
        }}
        onRowAppended={onRowAppended}
        onCellEdited={onCellEdited}
        onCellsEdited={onCellsEdited}
        validateCell={validateCell}
        onDelete={onDelete}
        rowMarkers="both"
      />
    </BusTypeSpreadsheetWrapper>
  );
};

export default BusTypeSpreadsheet;
