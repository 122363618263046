import { useProjectOrRedirect } from 'app/behaviorHooks/useProjectOrRedirect';
import React from 'react';
import { Spinner, SpinnerWrapper } from 'ui/common/Spinner';
import ModelEditor from 'ui/modelEditor/ModelEditor';
import { TopLevelModelTracker } from 'ui/modelEditor/TopLevelModelTracker';
import { useAppParams } from 'util/useAppParams';

let bufferTimeoutId = -1;

const ValidateRouteProjectModel: React.FC = () => {
  const { modelId } = useAppParams();
  const [bufferMounting, setBufferMounting] = React.useState(true);

  React.useEffect(() => {
    if (modelId) {
      setBufferMounting(true);
    }

    window.clearTimeout(bufferTimeoutId);
    bufferTimeoutId = window.setTimeout(() => {
      setBufferMounting(false);
    }, 100);

    return () => {
      window.clearTimeout(bufferTimeoutId);
    };
  }, [modelId]);

  const { isLoadingProject: isProjectLoading, project } =
    useProjectOrRedirect();

  if (isProjectLoading || bufferMounting) {
    return (
      <SpinnerWrapper>
        <Spinner />
      </SpinnerWrapper>
    );
  }

  if (!project || !modelId) {
    return null;
  }

  return (
    <>
      <TopLevelModelTracker project={project} modelId={modelId} />
      <ModelEditor />
    </>
  );
};

export default ValidateRouteProjectModel;
