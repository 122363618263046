import { ThemeProvider } from '@emotion/react';
import React from 'react';

import GlobalStyles from './GlobalStyles';
import { getTheme } from './themes';
import { ThemeContext } from './ThemeStore';

const Theme = ({
  children,
}: {
  children: React.ReactNode;
}): React.ReactElement => {
  const { theme } = React.useContext(ThemeContext);

  return (
    <ThemeProvider theme={getTheme(theme)}>
      <GlobalStyles />
      {children}
    </ThemeProvider>
  );
};

export default Theme;
