import { t } from '@lingui/macro';
import React from 'react';
import Button from 'ui/common/Button/Button';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import {
  ActionButtonContainer,
  ModalMessageContainer,
  ModalMessageText,
  SmallFormContainer,
} from 'ui/common/Modal/Modal';
import { useModal } from 'ui/common/Modal/useModal';

interface Props {
  onConfirm: () => void;
}

const ReimportRequirementsModal: React.FC<Props> = ({ onConfirm }) => {
  const { closeModal } = useModal();

  return (
    <SmallFormContainer
      onSubmit={(e) => {
        e?.preventDefault();
        onConfirm();
        closeModal();
      }}>
      <ModalMessageContainer data-test-id="reimport-requirements-modal-message">
        <ModalMessageText>
          {t({
            id: 'reimportRequirementsModal.messageContent.text',
            message:
              'Existing requirements will be updated, and links to requirements that no longer exist will be deleted.',
          })}
        </ModalMessageText>
      </ModalMessageContainer>

      <ActionButtonContainer>
        {/* Cancel button */}
        <Button
          type="button"
          onClick={closeModal}
          variant={ButtonVariants.LargeSecondary}
          testId="reimport-requirements-modal-cancel-button">
          {t({
            id: 'reimportRequirementsModal.cancelButton.label',
            message: 'Cancel',
          })}
        </Button>

        {/* Reimport button */}
        <Button
          type="submit"
          variant={ButtonVariants.LargePrimary}
          testId="reimport-requirements-modal-leave-button">
          {t({
            id: 'reimportRequirementsModal.importButton.label',
            message: 'Import',
          })}
        </Button>
      </ActionButtonContainer>
    </SmallFormContainer>
  );
};

export default ReimportRequirementsModal;
