/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Pythonscript: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.25 5.5C6.25 4.25736 7.25736 3.25 8.5 3.25H23.5C24.7426 3.25 25.75 4.25736 25.75 5.5V15C25.75 14 24.85 13 24.25 13V5.5C24.25 5.08579 23.9142 4.75 23.5 4.75H8.5C8.08579 4.75 7.75 5.08579 7.75 5.5V26.5C7.75 26.9142 8.08579 27.25 8.5 27.25H23.5C23.9142 27.25 24.25 26.9142 24.25 26.5C24.85 26.5 25.75 25.5 25.75 25V26.5C25.75 27.7426 24.7426 28.75 23.5 28.75H8.5C7.25736 28.75 6.25 27.7426 6.25 26.5V5.5Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M20.5114 14.1162C20.9241 14.0439 21.3931 14.0024 21.8842 14.0001C22.3753 13.9978 22.8873 14.0348 23.3808 14.1162C24.1604 14.245 24.8172 14.8247 24.8172 15.5963V18.3076C24.8172 19.1027 24.1796 19.7545 23.3808 19.7545H20.5114C19.5372 19.7545 18.7167 20.5835 18.7167 21.5233V22.8242H17.729C16.8941 22.8242 16.4064 22.2236 16.2022 21.3806C15.9268 20.2482 15.9385 19.5714 16.2022 18.4868C16.4309 17.5406 17.162 17.0432 17.9969 17.0432H21.9478V16.6815H19.075V15.5963C19.075 14.7747 19.2957 14.3291 20.5114 14.1162ZM20.8696 15.4171C20.8696 15.117 20.6273 14.8729 20.3306 14.8729C20.0328 14.8729 19.7915 15.117 19.7915 15.4171C19.7915 15.7162 20.0328 15.9581 20.3306 15.9581C20.6273 15.9581 20.8696 15.7162 20.8696 15.4171Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M25.1755 18.3076V17.0432H26.2536C27.0895 17.0432 27.4836 17.663 27.69 18.4868C27.9771 19.631 27.9899 20.4887 27.69 21.3806C27.3996 22.247 27.0885 22.8242 26.2536 22.8242H21.9478V23.1859H24.8172V24.2711C24.8172 25.0928 24.104 25.5105 23.3808 25.718C22.2928 26.0309 21.4207 25.983 20.5114 25.718C19.752 25.4966 19.075 25.0428 19.075 24.2711V21.5598C19.075 20.7797 19.7254 20.1129 20.5114 20.1129H23.3808C24.337 20.1129 25.1755 19.2879 25.1755 18.3076ZM24.1007 24.4503C24.1007 24.1512 23.8594 23.9094 23.5616 23.9094C23.2649 23.9094 23.0225 24.1512 23.0225 24.4503C23.0225 24.7505 23.2649 24.9946 23.5616 24.9946C23.8594 24.9946 24.1007 24.7505 24.1007 24.4503Z"
      fill={props.fill}
    />
    <path
      d="M10 7.5C9.72386 7.5 9.5 7.72386 9.5 8C9.5 8.27614 9.72386 8.5 10 8.5H22C22.2761 8.5 22.5 8.27614 22.5 8C22.5 7.72386 22.2761 7.5 22 7.5H10Z"
      fill={props.fill}
    />
    <path
      d="M10 11C9.72386 11 9.5 11.2239 9.5 11.5C9.5 11.7761 9.72386 12 10 12H22C22.2761 12 22.5 11.7761 22.5 11.5C22.5 11.2239 22.2761 11 22 11H10Z"
      fill={props.fill}
    />
    <path
      d="M10 14.5C9.72386 14.5 9.5 14.7239 9.5 15C9.5 15.2761 9.72386 15.5 10 15.5H14C14.2761 15.5 14.5 15.2761 14.5 15C14.5 14.7239 14.2761 14.5 14 14.5H10Z"
      fill={props.fill}
    />
  </svg>
);

Pythonscript.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Pythonscript;
