// Associated Entity Id: modelId
export const CHAT_PREFS_V1_KEY = 'CHAT_PREFS_V1';

export interface ChatPrefsV1 {
  aiModelId?: string;
  temperature: number;
  seed?: number;
  useModelBuilderWithAcausal?: boolean; // FIXME: deprecated, use acausalModelingEnabled user option instead
}

export const defaultChatPrefsV1: ChatPrefsV1 = {
  aiModelId: undefined,
  temperature: 0.1,
  seed: undefined,
  useModelBuilderWithAcausal: true,
};
