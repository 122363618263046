// !!!! -- DO NOT MANUALLY EDIT -- !!!!
// This file was generated by something in scripts/generateDefaultBlockData.js
// If you want to change the contents of this file, it should be automated
//
// (You can edit this file locally, but it may not be checked into the codebase -
// PRs with a manual edit of this file are forbidden from being merged.)

import { computationBlockDefaults } from 'app/generated_blocks/computationBlockDefaults';
import type { ComputationBlockClass } from 'app/generated_types/ComputationBlockClass';
import deepmerge from 'deepmerge';

export const DeadZone: ComputationBlockClass = deepmerge(
  computationBlockDefaults,
  {
    "base": {
      "name": "DeadZone",
      "namespace": "core",
      "description": "Sets outputs to zero within a given range centered on a given value.",
      "help_url": "https://docs.collimator.ai/using-model-editors/block-library/agnostic-blocks#dead-zone"
    },
    "modes": {
      "time": "agnostic"
    },
    "events": {
      "count": 2
    },
    "ports": {
      "inputs": {
        "static": [
          {
            "name": "in_0"
          }
        ]
      },
      "outputs": {
        "static": [
          {
            "name": "out_0"
          }
        ]
      }
    },
    "parameter_definitions": [
      {
        "name": "half_range",
        "description": "the full range of the dead zone is 2*half_range. the dead zone will extend half_range in each direction from zero",
        "data_type": "float",
        "default_value": "0.5"
      }
    ]
  }
);
