import styled from '@emotion/styled';
import { t } from '@lingui/macro';
import { useProjectActions } from 'app/api/useProjectActions';
import { Project } from 'app/apiTransformers/convertAPIProjectToProject';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Bus, File } from 'ui/common/Icons/Standard';
import { ItemType, MenuItemConfig } from 'ui/common/Menu/menuItemTypes';
import ListItem from './ListItem';
import ListTitle from './ListTitle';

interface Props {
  project: Project;
  triggerClose: () => void;
}

enum OtherFileType {
  PROJECT = 'PROJECT', // aka data files (incl. python scripts)
  BUS_TYPE = 'BUS_TYPE',
}

interface OtherFile {
  name: string;
  type: OtherFileType;
  uuid?: string;
}

const UploadFileInput = styled.input`
  visibility: hidden;
  position: absolute;
  z-index: -100;
  width: 10px;
`;

const Other: React.FC<Props> = ({ project, triggerClose }) => {
  const uploadDataRef = React.useRef<HTMLInputElement>(null);
  const navigate = useNavigate();
  const { createFile, downloadFile } = useProjectActions();

  let otherFiles: OtherFile[] = project.files.map((f) => ({
    name: f.name,
    type: OtherFileType.PROJECT,
    uuid: f.uuid,
  }));
  let busTypes: OtherFile[] =
    project.busTypes?.map((f) => ({
      name: f.name,
      type: OtherFileType.BUS_TYPE,
      uuid: f.id,
    })) || [];
  const nonDiagramFiles = [...otherFiles, ...busTypes];

  const uploadDataFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const projectId = project?.uuid;
    const file = e.target?.files?.[0];
    if (!projectId || !file) {
      return null;
    }

    createFile(
      {
        projectUuid: projectId,
        fileCreateRequest: {
          name: file.name,
          content_type: file.type,
          size: file.size,
        },
      },
      file,
    );
  };

  let otherFilesMenu: MenuItemConfig[] = [];
  otherFilesMenu.push({
    type: ItemType.Button,
    content: t({
      id: 'browser.uploadDataFile',
      message: 'Upload a data file...',
    }),
    onClick: () => uploadDataRef?.current?.click?.(),
  });

  const fileAction = (file: OtherFile) => {
    if (file.type === OtherFileType.BUS_TYPE) {
      navigate(`/projects/${project.uuid}/bus_types/${file.uuid}`);
    } else if (file.uuid) {
      const isPythonFile = file.name.split('.').pop() === 'py';
      if (isPythonFile) {
        navigate(`/projects/${project.uuid}/code_editor/${file.uuid}`);
      } else {
        downloadFile(file.name, {
          fileUuid: file.uuid,
          projectUuid: project.uuid,
        });
      }
    }
  };

  return (
    <>
      <ListTitle>Other</ListTitle>
      {nonDiagramFiles.map((f: OtherFile) => (
        <ListItem
          key={f.name}
          Icon={f.type === OtherFileType.BUS_TYPE ? Bus : File}
          isActive={false} // note: was true only for notebooks
          onClick={() => {
            fileAction(f);
            triggerClose();
          }}>
          {f.name}
        </ListItem>
      ))}
      <UploadFileInput
        type="file"
        style={{ visibility: 'hidden' }}
        ref={uploadDataRef}
        onChange={(e) => {
          uploadDataFile(e);
          e.target.value = '';
        }}
      />
    </>
  );
};

export default Other;
