/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Simulations: React.FC<Props> = (props) => (
  <svg viewBox="0 0 16 16" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.83331 6.99233L6.14677 8.16925C6.0273 8.37407 5.80803 8.50001 5.57092 8.50001H4.99998C4.72384 8.50001 4.49998 8.27615 4.49998 8.00001C4.49998 7.72387 4.72384 7.50001 4.99998 7.50001H5.37946L6.25746 5.99487C6.51471 5.55386 7.15191 5.55386 7.40917 5.99487L9.16665 9.00769L9.85319 7.83076C9.97266 7.62595 10.1919 7.50001 10.429 7.50001H11C11.2761 7.50001 11.5 7.72387 11.5 8.00001C11.5 8.27615 11.2761 8.50001 11 8.50001H10.6205L9.7425 10.0051C9.48525 10.4462 8.84805 10.4462 8.59079 10.0051L6.83331 6.99233Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M14.6666 8.00001C14.6666 11.6819 11.6819 14.6667 7.99998 14.6667C4.31808 14.6667 1.33331 11.6819 1.33331 8.00001C1.33331 4.31811 4.31808 1.33334 7.99998 1.33334C11.6819 1.33334 14.6666 4.31811 14.6666 8.00001ZM13.6666 8.00001C13.6666 11.1296 11.1296 13.6667 7.99998 13.6667C4.87037 13.6667 2.33331 11.1296 2.33331 8.00001C2.33331 4.8704 4.87037 2.33334 7.99998 2.33334C11.1296 2.33334 13.6666 4.8704 13.6666 8.00001Z"
      fill={props.fill}
    />
  </svg>
);

Simulations.defaultProps = {
  width: '16',
  height: '16',
  viewBox: '0 0 16 16',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Simulations;
