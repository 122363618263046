/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  className?: string;
}

const Requirements: React.FC<Props> = (props) => (
  <svg viewBox="0 0 24 24" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.43595 5.8897C8.77301 6.13045 8.85108 6.59887 8.61032 6.93593L6.39171 10.042C5.99957 10.591 5.24253 10.7297 4.68117 10.3555L3.58399 9.62403C3.23935 9.39427 3.14622 8.92862 3.37598 8.58397C3.60575 8.23933 4.0714 8.1462 4.41604 8.37596L5.31181 8.97314L7.38972 6.06407C7.63048 5.72701 8.09889 5.64894 8.43595 5.8897Z"
      fill={props.fill}
    />
    <path
      d="M19.75 8.75C19.75 9.16421 19.4142 9.5 19 9.5L10.75 9.5C10.3358 9.5 10 9.16421 10 8.75C10 8.33578 10.3358 8 10.75 8L19 8C19.4142 8 19.75 8.33578 19.75 8.75Z"
      fill={props.fill}
    />
    <path
      d="M19.75 15.75C19.75 16.1642 19.4142 16.5 19 16.5L10.75 16.5C10.3358 16.5 10 16.1642 10 15.75C10 15.3358 10.3358 15 10.75 15H19C19.4142 15 19.75 15.3358 19.75 15.75Z"
      fill={props.fill}
    />
    <path
      d="M8.61032 13.9359C8.85108 13.5989 8.77301 13.1305 8.43595 12.8897C8.09889 12.6489 7.63048 12.727 7.38972 13.0641L5.31181 15.9731L4.41604 15.376C4.0714 15.1462 3.60575 15.2393 3.37598 15.584C3.14622 15.9286 3.23935 16.3943 3.58399 16.624L4.68117 17.3555C5.24253 17.7297 5.99957 17.591 6.39171 17.042L8.61032 13.9359Z"
      fill={props.fill}
    />
  </svg>
);

Requirements.defaultProps = {
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  className: '',
};

export default Requirements;
