/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const CoordinaterotationconversionDcmtoquaternion: React.FC<Props> = (
  props,
) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20.2003 11.9697C19.9074 11.6768 19.4325 11.6768 19.1396 11.9697C18.8467 12.2626 18.8467 12.7374 19.1396 13.0303L21.6093 15.5L19.1396 17.9697C18.8467 18.2626 18.8467 18.7374 19.1396 19.0303C19.4325 19.3232 19.9074 19.3232 20.2003 19.0303L22.8467 16.3839C23.3349 15.8957 23.3349 15.1043 22.8467 14.6161L20.2003 11.9697Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M29.51 13.01C29.51 12.9767 29.52 12.95 29.54 12.93C29.5666 12.9033 29.5966 12.89 29.63 12.89H30.55C30.5833 12.89 30.61 12.9033 30.63 12.93C30.6566 12.95 30.67 12.9767 30.67 13.01V19.77C30.67 19.8033 30.6566 19.83 30.63 19.85C30.61 19.8767 30.5833 19.89 30.55 19.89H29.63C29.5966 19.89 29.5666 19.8767 29.54 19.85C29.52 19.83 29.51 19.8033 29.51 19.77V17.6C29.51 17.58 29.5033 17.57 29.49 17.57C29.4766 17.5633 29.4633 17.5667 29.45 17.58C29.1566 17.9133 28.78 18.08 28.32 18.08C27.8266 18.08 27.4166 17.9567 27.09 17.71C26.7633 17.4633 26.5266 17.12 26.38 16.68C26.2666 16.34 26.21 15.9333 26.21 15.46C26.21 14.9933 26.27 14.5767 26.39 14.21C26.5366 13.77 26.7733 13.4267 27.1 13.18C27.4266 12.9333 27.8266 12.81 28.3 12.81C28.7733 12.81 29.1566 12.9833 29.45 13.33C29.4633 13.35 29.4766 13.3567 29.49 13.35C29.5033 13.3433 29.51 13.33 29.51 13.31V13.01ZM29.25 16.64C29.4166 16.3667 29.5 15.9667 29.5 15.44C29.5 14.94 29.4233 14.5533 29.27 14.28C29.0833 13.96 28.82 13.8 28.48 13.8C28.1 13.8 27.8166 13.9633 27.63 14.29C27.47 14.59 27.39 14.9733 27.39 15.44C27.39 15.9467 27.49 16.3533 27.69 16.66C27.8833 16.94 28.15 17.08 28.49 17.08C28.8166 17.08 29.07 16.9333 29.25 16.64Z"
      fill={props.fill}
    />
    <path
      d="M8.27562 18.564C7.85962 18.564 7.49428 18.484 7.17962 18.324C6.86495 18.1587 6.62228 17.9293 6.45162 17.636C6.28095 17.3427 6.19562 17.004 6.19562 16.62V14.772C6.19562 14.388 6.28095 14.0493 6.45162 13.756C6.62228 13.4627 6.86495 13.236 7.17962 13.076C7.49428 12.916 7.85962 12.836 8.27562 12.836C8.68628 12.836 9.04628 12.9133 9.35562 13.068C9.67028 13.2173 9.91295 13.4333 10.0836 13.716C10.2596 13.9933 10.3476 14.316 10.3476 14.684C10.3476 14.732 10.3156 14.7613 10.2516 14.772L9.31562 14.828H9.29962C9.24628 14.828 9.21962 14.7987 9.21962 14.74C9.21962 14.4573 9.13162 14.2307 8.95562 14.06C8.78495 13.8893 8.55828 13.804 8.27562 13.804C7.98762 13.804 7.75562 13.8893 7.57962 14.06C7.40895 14.2307 7.32362 14.4573 7.32362 14.74V16.668C7.32362 16.9453 7.40895 17.1693 7.57962 17.34C7.75562 17.5107 7.98762 17.596 8.27562 17.596C8.55828 17.596 8.78495 17.5107 8.95562 17.34C9.13162 17.1693 9.21962 16.9453 9.21962 16.668C9.21962 16.6093 9.25162 16.58 9.31562 16.58L10.2516 16.62C10.2783 16.62 10.2996 16.628 10.3156 16.644C10.337 16.66 10.3476 16.6787 10.3476 16.7C10.3476 17.068 10.2596 17.3933 10.0836 17.676C9.91295 17.9587 9.67028 18.1773 9.35562 18.332C9.04628 18.4867 8.68628 18.564 8.27562 18.564Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M1.46612 18.5C1.43945 18.5 1.41545 18.492 1.39412 18.476C1.37812 18.4547 1.37012 18.4307 1.37012 18.404V12.996C1.37012 12.9693 1.37812 12.948 1.39412 12.932C1.41545 12.9107 1.43945 12.9 1.46612 12.9H3.46612C3.86612 12.9 4.21545 12.972 4.51412 13.116C4.81812 13.2547 5.05278 13.452 5.21812 13.708C5.38345 13.964 5.46612 14.26 5.46612 14.596V16.804C5.46612 17.14 5.38345 17.436 5.21812 17.692C5.05278 17.948 4.81812 18.148 4.51412 18.292C4.21545 18.4307 3.86612 18.5 3.46612 18.5H1.46612ZM2.49812 17.492C2.49812 17.5187 2.51145 17.532 2.53812 17.532H3.50612C3.75145 17.532 3.94878 17.4493 4.09812 17.284C4.25278 17.1187 4.33278 16.8973 4.33812 16.62V14.78C4.33812 14.5027 4.26078 14.2813 4.10612 14.116C3.95678 13.9507 3.75412 13.868 3.49812 13.868H2.53812C2.51145 13.868 2.49812 13.8813 2.49812 13.908V17.492Z"
      fill={props.fill}
    />
    <path
      d="M14.8868 12.9C14.8388 12.9 14.8015 12.9213 14.7748 12.964L13.5668 14.828C13.5508 14.86 13.5348 14.86 13.5188 14.828L12.3188 12.964C12.2921 12.9213 12.2548 12.9 12.2068 12.9H11.2708C11.2441 12.9 11.2201 12.9107 11.1988 12.932C11.1828 12.948 11.1748 12.9693 11.1748 12.996V18.404C11.1748 18.4307 11.1828 18.4547 11.1988 18.476C11.2201 18.492 11.2441 18.5 11.2708 18.5H12.2068C12.2335 18.5 12.2548 18.492 12.2708 18.476C12.2921 18.4547 12.3028 18.4307 12.3028 18.404V14.884C12.3028 14.8627 12.3081 14.852 12.3188 14.852C12.3295 14.8467 12.3401 14.852 12.3508 14.868L13.1988 16.196C13.2255 16.2387 13.2628 16.26 13.3108 16.26H13.7828C13.8308 16.26 13.8681 16.2387 13.8948 16.196L14.7428 14.868C14.7535 14.852 14.7641 14.844 14.7748 14.844C14.7855 14.844 14.7908 14.8547 14.7908 14.876V18.404C14.7908 18.4307 14.7988 18.4547 14.8148 18.476C14.8361 18.492 14.8601 18.5 14.8868 18.5H15.8228C15.8495 18.5 15.8708 18.492 15.8868 18.476C15.9081 18.4547 15.9188 18.4307 15.9188 18.404V12.996C15.9188 12.9693 15.9081 12.948 15.8868 12.932C15.8708 12.9107 15.8495 12.9 15.8228 12.9H14.8868Z"
      fill={props.fill}
    />
  </svg>
);

CoordinaterotationconversionDcmtoquaternion.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default CoordinaterotationconversionDcmtoquaternion;
