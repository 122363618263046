/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  className?: string;
}

const VariableInspector: React.FC<Props> = (props) => (
  <svg viewBox="0 0 16 16" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.88599 13.18C9.40599 13.18 9.04599 13.03 8.80599 12.73C8.57799 12.418 8.39799 11.968 8.26599 11.38L7.76199 9.328L7.58199 9.04L7.04199 7.204C6.89799 6.7 6.74799 6.31 6.59199 6.034C6.44799 5.758 6.26199 5.566 6.03399 5.458C5.81799 5.35 5.52399 5.296 5.15199 5.296C5.04399 5.296 4.94199 5.302 4.84599 5.314C4.74999 5.326 4.65999 5.338 4.57599 5.35V4.738L7.04199 4.45H7.68999C7.89399 4.702 8.06799 5.008 8.21199 5.368C8.36799 5.716 8.50599 6.136 8.62599 6.628L8.85999 7.744L9.03999 8.122L9.48999 9.598C9.72999 10.342 9.93999 10.882 10.12 11.218C10.312 11.554 10.534 11.722 10.786 11.722C11.026 11.722 11.242 11.632 11.434 11.452C11.626 11.272 11.83 11.032 12.046 10.732L12.478 11.038C12.358 11.242 12.208 11.47 12.028 11.722C11.86 11.962 11.668 12.196 11.452 12.424C11.236 12.64 10.996 12.82 10.732 12.964C10.48 13.108 10.198 13.18 9.88599 13.18ZM4.43199 13.18C4.04799 13.18 3.77199 13.084 3.60399 12.892C3.43599 12.688 3.35199 12.472 3.35199 12.244C3.35199 12.028 3.42399 11.842 3.56799 11.686C3.69999 11.518 3.87999 11.434 4.10799 11.434C4.28799 11.434 4.47999 11.476 4.68399 11.56C4.89999 11.632 5.07999 11.668 5.22399 11.668C5.28399 11.668 5.35599 11.65 5.43999 11.614C5.52399 11.578 5.63199 11.494 5.76399 11.362C5.90799 11.23 6.07599 11.014 6.26799 10.714L7.63599 8.644L8.03199 9.58H7.72599L6.64599 11.434C6.38199 11.902 6.08199 12.31 5.74599 12.658C5.42199 13.006 4.98399 13.18 4.43199 13.18ZM10.174 6.628L8.98599 8.5L8.62599 7.546H8.89599L9.74199 6.088C9.90999 5.788 10.096 5.512 10.3 5.26C10.516 4.996 10.762 4.78 11.038 4.612C11.326 4.444 11.656 4.36 12.028 4.36C12.376 4.36 12.622 4.438 12.766 4.594C12.922 4.738 13 4.942 13 5.206C13 5.482 12.916 5.704 12.748 5.872C12.58 6.028 12.376 6.106 12.136 6.106C11.944 6.106 11.77 6.07 11.614 5.998C11.458 5.926 11.296 5.89 11.128 5.89C10.936 5.89 10.768 5.95 10.624 6.07C10.48 6.19 10.33 6.376 10.174 6.628Z"
      fill={props.fill}
    />
  </svg>
);

VariableInspector.defaultProps = {
  width: '16',
  height: '16',
  viewBox: '0 0 16 16',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  className: '',
};

export default VariableInspector;
