import React from 'react';

import { useAppDispatch, useAppSelector } from 'app/hooks';
import { uiFlagsActions } from 'app/slices/uiFlagsSlice';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import { ChevronLeft, ChevronRight } from 'ui/common/Icons/Small';
import {
  LeftExpandCollapseButton,
  WideLeftSidebar,
} from 'ui/common/layout/appLayout';
import { DataExplorerLeftSidebarContent } from 'ui/dataExplorer/DataExplorerLeftSidebarContent';

interface Props {
  projectId: string;
  explorationId: string;
  canEditProject: boolean;
}

export const DataExplorerLeftSidebar: React.FC<Props> = ({
  projectId,
  explorationId,
  canEditProject,
}) => {
  const dispatch = useAppDispatch();
  const isExpanded = useAppSelector((state) => state.uiFlags.isLeftSidebarOpen);
  const setIsExpanded = (isExpanded: boolean) => {
    dispatch(uiFlagsActions.setUIFlag({ isLeftSidebarOpen: isExpanded }));
  };

  return (
    <>
      <WideLeftSidebar isExpanded={isExpanded}>
        <DataExplorerLeftSidebarContent
          projectId={projectId}
          explorationId={explorationId}
          canEditProject={canEditProject}
        />
      </WideLeftSidebar>
      <LeftExpandCollapseButton
        testId="toggle-model-left-sidebar-button"
        Icon={isExpanded ? ChevronLeft : ChevronRight}
        variant={ButtonVariants.LargeSecondary}
        onClick={() => setIsExpanded(!isExpanded)}
      />
    </>
  );
};
