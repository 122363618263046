import { useAppSelector } from 'app/hooks';
import { getIsCurrentDiagramReadonly } from 'app/utils/modelDiagramUtils';
import { useModelPermission } from 'ui/permission/useModelPermission';
import { useAppParams } from 'util/useAppParams';

export enum EditorMode {
  Model = 'model',
  Group = 'group',
  Submodel = 'submodel',
}

export const useModelEditorInfo = () => {
  const topLevelModelType = useAppSelector(
    (state) => state.submodels.topLevelModelType,
  );
  const loadedModelId = useAppSelector(
    (state) => state.modelMetadata.loadedModelId,
  );
  const referenceSubmodelId = useAppSelector(
    (state) => state.modelMetadata.currentDiagramSubmodelReferenceId,
  );

  const currentSubmodelPath = useAppSelector(
    (state) => state.model.present?.currentSubmodelPath,
  );

  const modelInEditorUuid = referenceSubmodelId || loadedModelId;

  let editorMode = EditorMode.Model;
  if (!referenceSubmodelId && currentSubmodelPath.length > 0) {
    editorMode = EditorMode.Group;
  } else if (
    topLevelModelType === 'Submodel' ||
    referenceSubmodelId !== undefined
  ) {
    editorMode = EditorMode.Submodel;
  }

  const groupBlockUuid = currentSubmodelPath[currentSubmodelPath.length - 1];

  const { modelId, projectId, versionId } = useAppParams();

  const { canEditCurrentModelVersion, arePermissionsLoaded } =
    useModelPermission(projectId, modelId, versionId);

  const isModelReadOnly = getIsCurrentDiagramReadonly({
    modelId,
    loadedModelId,
    referenceSubmodelId,
    arePermissionsLoaded,
    canEditCurrentModelVersion,
  });

  return {
    modelInEditorUuid,
    editorMode,
    groupBlockUuid,
    isModelReadOnly,
  };
};
