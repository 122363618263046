import { t } from '@lingui/macro';
import { useProject } from 'app/api/useProject';
import { useGetFileReadByUuidQuery } from 'app/apiGenerated/generatedApi';
import { BlockInstance } from 'app/generated_types/SimulationModel';
import { useAppDispatch } from 'app/hooks';
import { modelActions } from 'app/slices/modelSlice';
import { fmiModelDescription } from 'app/third_party_types/fmi-2.0-modelDescription';
import React, { useEffect } from 'react';
import { batch } from 'react-redux';
import { DynamicVariableCommon } from 'util/dynamicBlockUtils';
import { useAppParams } from 'util/useAppParams';
import { useNotifications } from '../notifications/useNotifications';
import DataFileParameter from './DataFileParameter';

type Props = {
  parentPath: string[];
  selectedBlock: BlockInstance;
  currentValue: string;
  onSelectValue: (newValue: string) => void;
};

/**
 * Dedicated FMU selection dropdown to handle the dynamic block configuration.
 * Ports and params are derived from the ingested FMU file.
 */
const FmuFileParameter: React.FC<Props> = ({
  parentPath,
  selectedBlock,
  currentValue,
  onSelectValue,
}) => {
  const dispatch = useAppDispatch();
  const { showError } = useNotifications();

  const { projectId } = useAppParams();
  const { project } = useProject();

  const fmuFileName = selectedBlock.parameters.file_name?.value || ''; // unselected file has value empty string, but types don't know that.

  // Find file UUID here for now. Existing param dropdown doesn't save file UUID.
  const projectFiles =
    project?.files.filter(
      (file) => !file.error && !(file.status === 'processing_in_progress'),
    ) || [];
  const fileUuid = projectFiles.find(
    (projectFile) => projectFile.name === fmuFileName,
  )?.uuid;

  const { data: fileData, isFetching } = useGetFileReadByUuidQuery(
    {
      projectUuid: projectId || '',
      fileUuid: fileUuid || '',
    },
    {
      skip: !(!!projectId && !!fileUuid),
    },
  );

  const [blockNeedsUpdate, setBlockNeedsUpdate] = React.useState(false);

  // Reconfigure the block on file_name param change.
  // Note: does not cover the case where the file contents change after selection.
  useEffect(() => {
    if (blockNeedsUpdate) {
      // With the most up to date file info
      if (fileData && !isFetching) {
        const fmiDesc = fileData.properties as fmiModelDescription;
        const modelVariables = fmiDesc?.ModelVariables;
        if (!modelVariables) {
          // Should not happen since the file is processed on upload.
          showError(
            t({
              id: 'fmuBlockDetails.invalidFmuFileMessage',
              message: `Model variables missing from selected file ${fileData.summary.uuid}`,
            }),
          );
          return;
        }

        const dynamicVariables: DynamicVariableCommon[] = modelVariables.map(
          (fmiVariable) => {
            const id = fmiVariable.cml_name;
            const causality = fmiVariable.causality;
            const start =
              fmiVariable.Boolean?.start ??
              fmiVariable.Integer?.start ??
              fmiVariable.Real?.start ??
              fmiVariable.String?.start ??
              // TODO: Add drop-downs for enum types
              fmiVariable.Enumeration?.start ??
              undefined;

            return {
              id,
              causality,
              defaultValue: start !== undefined ? `${start}` : undefined,
            };
          },
        );

        batch(() => {
          dispatch(
            modelActions.resetDynamicBlock({ parentPath, node: selectedBlock }),
          );
          dispatch(
            modelActions.configureDynamicBlock({
              parentPath,
              node: selectedBlock,
              dynamicVariables,
            }),
          );
        });
        setBlockNeedsUpdate(false);
      }
    }
  }, [
    blockNeedsUpdate,
    dispatch,
    fileData,
    fmuFileName,
    isFetching,
    parentPath,
    selectedBlock,
    showError,
  ]);

  return (
    <DataFileParameter
      datafileType="core.ModelicaFMU"
      onSelectValue={(v) => {
        onSelectValue(v);
        setBlockNeedsUpdate(true);
      }}
      currentValue={currentValue}
    />
  );
};

export default FmuFileParameter;
