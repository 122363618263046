import {
  BlockClassName,
  LinkInstance,
  SubmodelInstance,
} from '@collimator/model-schemas-ts';
import { HoverEntityType, MouseActions } from 'app/common_types/MouseTypes';
import { PortSide } from 'app/common_types/PortTypes';
import { blockClassLookup } from 'app/generated_blocks';
import { ComputationBlockClass } from 'app/generated_types/ComputationBlockClass';
import {
  NodeInstance,
  Port,
  PortVariant,
} from 'app/generated_types/SimulationModel';
import {
  getSubmodelPortIdOfIoNode,
  nodeClassWithoutNamespace_displayOnly,
} from 'app/helpers';
import { getCurrentModelRef } from 'app/sliceRefAccess/CurrentModelRef';
import {
  getSubmodelRef,
  isSubmodelRefAvailable,
} from 'app/sliceRefAccess/SubmodelRef';
import { SignalsData } from 'app/slices/compilationAnalysisDataSlice';
import { ErrorTreeNode } from 'app/slices/errorsSlice';
import { getPortNodeLocalCoordinate } from 'app/utils/getPortOffsetCoordinate';
import { renderConstants } from 'app/utils/renderConstants';
import { getSpecificReferenceSubmodelByNode } from 'app/utils/submodelUtils';
import * as NVG from 'nanovg-js';
import { SPACING } from 'theme/styleConstants';
import { drawPort } from 'ui/modelRendererInternals/drawPort';
import {
  PortConnListType,
  RendererState,
} from 'ui/modelRendererInternals/modelRenderer';
import { calculateTextSize } from 'util/calculateTextSize';
import { flippableIconIDs, getBlockIconID } from 'util/getBlockIconID';
import {
  BLOCK_FILL_COLORS,
  LINE_COLORS,
  LineColorType,
  withAlphaf,
} from './coloring';
import { drawImage } from './drawImage';
import { getLinkColor, getTimeModeColor } from './drawLink';
import { BLOCK_GLYPH_MAP, drawPortGlyph } from './drawPortLabel';
import { getDrawContext } from './drawPrimitives';
import { drawSignalLabels } from './drawSignalLabels';
import { drawSignalPlotter } from './drawSignalPlotter';
import { BoundingBox, boundingBoxIntersects } from './getVertexHitboxForIndex';
import { PORT_BLOCK_YOFFSET, getVisualNodeHeight } from './getVisualNodeHeight';
import { GLYPH_MARGIN, getVisualNodeWidth } from './getVisualNodeWidth';
import { multiRadiusRect } from './multiRadiusRect';
import {
  RasterLoadState,
  getOrInitLoadImageFromStore,
} from './rasterTextureStore';
import { getFontOpacity, getFontSize } from './textRenderUtils';

const BLOCK_FILL_TYPE_MAP: { [k in BlockClassName]?: NVG.NVGcolor } = {
  'core.Iterator': BLOCK_FILL_COLORS.iterator,
  'core.LoopBreak': BLOCK_FILL_COLORS.inside_iterator,
  'core.LoopCounter': BLOCK_FILL_COLORS.inside_iterator,
  'core.LoopMemory': BLOCK_FILL_COLORS.inside_iterator,
  'core.LinearizedSystem': BLOCK_FILL_COLORS.linearizer,
};

const getNodeErrorAndColorFromTree = (
  rs: RendererState,
  node: NodeInstance,
  parentPath: string[],
): {
  nodeError: ErrorTreeNode | undefined;
  errorColor: LineColorType | undefined;
} => {
  let errorColor: LineColorType | undefined;
  let nodeError: ErrorTreeNode | undefined;
  let iteratingErrorNode: ErrorTreeNode = rs.refs.current.errorsState.rootNode;

  for (let i = 0; i < parentPath.length + 1; i++) {
    if (i == parentPath.length) {
      nodeError = iteratingErrorNode.children[node.uuid];
      if (nodeError?.errorKind) {
        errorColor = 'error';
      } else if (nodeError) {
        errorColor = 'error_in_child';
      }
      break;
    }

    const nextNode = iteratingErrorNode.children[parentPath[i]];
    if (!nextNode) break;

    iteratingErrorNode = nextNode;
  }

  return { nodeError, errorColor };
};

export function buildNodeBorderColorLUT(
  rs: RendererState,
  parentPath: string[],
  parentNamePath: string[],
  nodes: NodeInstance[],
  signalsData?: SignalsData,
  showDataTypesInModel?: boolean,
  showSignalDomainsInModel?: boolean,
): { [k: string]: NVG.Color } {
  const lut: { [k: string]: NVG.Color } = {};

  for (let i = 0; i < nodes.length; i++) {
    const node = nodes[i];

    const { errorColor } = getNodeErrorAndColorFromTree(rs, node, parentPath);
    if (errorColor) {
      lut[node.uuid] = LINE_COLORS[errorColor];
      continue;
    }

    const [nsRoot, nsSub, ...rest] = node.type.split('.');
    const isAcausal = nsRoot === 'acausal';

    if (showDataTypesInModel) {
      lut[node.uuid] = LINE_COLORS.normal;
      continue;
    }

    if (isAcausal) {
      lut[node.uuid] =
        LINE_COLORS[nsSub as LineColorType] || LINE_COLORS.normal;
    } else if (showSignalDomainsInModel) {
      const nodePathName = [...parentNamePath, node.name].join('.');
      const { timeMode } =
        (nodePathName && signalsData && signalsData[nodePathName]) || {};

      lut[node.uuid] = timeMode
        ? getTimeModeColor(rs, timeMode)
        : LINE_COLORS.normal;
    }
  }

  return lut;
}

// NOTE: eventually we should just pass the enum when we have more port visuals
// but it doesn't really mean anything to do that right now since there's only
// one other visual, so here's this convenience function.
// (please use very careful judgement if you decide to reuse this, because it really shouldn't be.)

const isPortHollowVis = (
  port: Port,
  portIndex: number,
  blockClass: ComputationBlockClass,
): boolean => {
  if (blockClass.ports.inputs) {
    if (port.kind === 'conditional' && blockClass.ports.inputs.conditional) {
      for (let j = 0; j < blockClass.ports.inputs.conditional.length; j++) {
        const condPortDef = blockClass.ports.inputs.conditional[j];

        if (
          condPortDef.order === portIndex &&
          condPortDef.name === port.name &&
          condPortDef.appearance === 'hollow'
        ) {
          return true;
        }
      }
    } else if (port.kind === 'static' && blockClass.ports.inputs.static) {
      for (let j = 0; j < blockClass.ports.inputs.static.length; j++) {
        const staticPortDef = blockClass.ports.inputs.static[j];

        if (
          staticPortDef.name === port.name &&
          staticPortDef.appearance === 'hollow'
        ) {
          return true;
        }
      }
    }
  }

  return false;
};

const renderTextOverflowGradient = (
  nvg: NVG.Context,
  rs: RendererState,
  textX: number,
  textY: number,
  renderingTextWidth: number,
  textHeight: number,
) => {
  const rawScale = Math.round(window.devicePixelRatio * rs.zoom);
  const scale = rawScale > 2 ? 4 : rawScale < 1 ? 1 : rawScale;
  const scaledRasterID = `text_fader_${scale}x`;
  const rasterMeta = getOrInitLoadImageFromStore(
    nvg,
    `${process.env.PUBLIC_URL}/assets/${scaledRasterID}.png`,
    scaledRasterID,
    scale,
  );
  if (rasterMeta?.loadState === RasterLoadState.Loaded) {
    const rw = Math.min(
      renderingTextWidth,
      (rasterMeta.width / scale) * rs.zoom,
    );
    const rh = textHeight;
    const rx = textX + renderingTextWidth - rw + 0.5;
    const ry = textY - textHeight / 2;
    drawImage(rs, nvg, rasterMeta.id, rx, ry, rw, rh, 0, 1);
  }
};

export function drawNode(
  nvg: NVG.Context,
  rs: RendererState,
  node: NodeInstance,
  connectedPorts: PortConnListType | undefined,
  selected: boolean,
  sceneBoundingBox?: BoundingBox,
  renderX?: number,
  renderY_raw?: number,
): void {
  const ctx = getDrawContext(rs, nvg);

  renderX = renderX ?? node.uiprops.x + rs.camera.x;
  renderY_raw = renderY_raw ?? node.uiprops.y + rs.camera.y;

  // Clip (skip) blocks completely outside of the view
  if (sceneBoundingBox) {
    const x1 = node.uiprops.x;
    const y1 = node.uiprops.y;
    const signalPlotterMargin = 2;
    const gw = node.uiprops.grid_width ?? renderConstants.BLOCK_MIN_GRID_WIDTH;
    const gh =
      node.uiprops.grid_height ?? renderConstants.BLOCK_MIN_GRID_HEIGHT;
    const x2 =
      x1 + (gw + signalPlotterMargin) * renderConstants.GRID_UNIT_PXSIZE;
    const y2 = y1 + gh * renderConstants.GRID_UNIT_PXSIZE;
    const nodeBoundingBox = { x1, y1, x2, y2 };

    if (!boundingBoxIntersects(nodeBoundingBox, sceneBoundingBox)) return;
  }

  const isInportBlock = node.type === 'core.Inport';
  const isOutportBlock = node.type === 'core.Outport';
  const isIOBlock = isInportBlock || isOutportBlock;

  const hovering =
    rs.hoveringEntity !== undefined &&
    (((rs.hoveringEntity.entityType === HoverEntityType.Node ||
      rs.hoveringEntity.entityType === HoverEntityType.NodeName) &&
      rs.hoveringEntity.block.uuid === node.uuid) ||
      (rs.hoveringEntity.entityType === HoverEntityType.NodeResizeEdge &&
        rs.hoveringEntity.nodeUuid === node.uuid));

  const nodeFlipped = node.uiprops.directionality === 'left';

  const currentNodeHeight = getVisualNodeHeight(node);
  const halfHeight = currentNodeHeight / 2;
  const zoomedHeight = currentNodeHeight * rs.zoom;
  const halfZoomedHeight = halfHeight * rs.zoom;
  const currentNodeWidth = getVisualNodeWidth(node);
  const zoomedWidth = currentNodeWidth * rs.zoom;

  // FIXME: The PORT_BLOCK_YOFFSET is quite the ugly hack... Fixing this
  // actually requires migrating the existing JSON models (exactly once).
  const renderY = isIOBlock ? renderY_raw + PORT_BLOCK_YOFFSET : renderY_raw;

  const glyphMap = BLOCK_GLYPH_MAP[node.type];
  const nodeHasGlyphs = Boolean(glyphMap);

  const ioCurveLeft =
    (isInportBlock && !nodeFlipped) || (isOutportBlock && nodeFlipped);
  const ioCurveRight =
    (isOutportBlock && !nodeFlipped) || (isInportBlock && nodeFlipped);

  const blockFillColor =
    BLOCK_FILL_TYPE_MAP[node.type] || BLOCK_FILL_COLORS.normal;

  const parentPath = getCurrentModelRef().submodelPath;
  const { nodeError } = getNodeErrorAndColorFromTree(rs, node, parentPath);

  // draw actual block rect
  {
    const normalRadius = renderConstants.BLOCK_CORNER_RADIUS * rs.zoom;
    const ioRadius = halfZoomedHeight;

    multiRadiusRect(
      rs,
      nvg,
      renderX * rs.zoom,
      renderY * rs.zoom,
      zoomedWidth,
      zoomedHeight,
      ioCurveLeft ? ioRadius : normalRadius,
      ioCurveRight ? ioRadius : normalRadius,
      ioCurveRight ? ioRadius : normalRadius,
      ioCurveLeft ? ioRadius : normalRadius,
      blockFillColor,
    );
  }

  const nodeBorderColor =
    rs.refs.current.nodeBorderColorLUT[node.uuid] || LINE_COLORS.normal;
  {
    const nodeStrokeWidth = 1.5 * rs.zoom;
    const halfNodeStroke = nodeStrokeWidth / 2;
    const normalRadius =
      renderConstants.BLOCK_CORNER_RADIUS * rs.zoom - halfNodeStroke;
    const ioRadius = isIOBlock
      ? halfZoomedHeight - halfNodeStroke
      : normalRadius;

    multiRadiusRect(
      rs,
      nvg,
      renderX * rs.zoom + halfNodeStroke,
      renderY * rs.zoom + halfNodeStroke,
      zoomedWidth - nodeStrokeWidth,
      zoomedHeight - nodeStrokeWidth,
      ioCurveLeft ? ioRadius : normalRadius,
      ioCurveRight ? ioRadius : normalRadius,
      ioCurveRight ? ioRadius : normalRadius,
      ioCurveLeft ? ioRadius : normalRadius,
      undefined,
      nodeBorderColor,
      nodeStrokeWidth,
    );
  }

  const highlight = hovering || selected;
  if (highlight) {
    const highlightStrokeWidth = 2 * rs.zoom;
    const halfHighlightStroke = highlightStrokeWidth / 2;
    const normalRadius =
      renderConstants.BLOCK_CORNER_RADIUS * rs.zoom + halfHighlightStroke;
    const ioRadius = isIOBlock
      ? halfZoomedHeight + halfHighlightStroke
      : normalRadius;

    multiRadiusRect(
      rs,
      nvg,
      renderX * rs.zoom - halfHighlightStroke,
      renderY * rs.zoom - halfHighlightStroke,
      zoomedWidth + highlightStrokeWidth,
      zoomedHeight + highlightStrokeWidth,
      ioCurveLeft ? ioRadius : normalRadius,
      ioCurveRight ? ioRadius : normalRadius,
      ioCurveRight ? ioRadius : normalRadius,
      ioCurveLeft ? ioRadius : normalRadius,
      undefined,
      selected ? LINE_COLORS.selected : LINE_COLORS.highlight,
      highlightStrokeWidth,
    );
  }

  const { name } = node;
  const blockClass = blockClassLookup(node.type);
  const {
    base: { name: baseClassName },
  } = blockClass;

  const printableClassName = nodeClassWithoutNamespace_displayOnly(node.type);
  const fontSize = getFontSize(rs.zoom);
  const nameOpacity = getFontOpacity(rs.zoom);

  const blockLabelY =
    node.uiprops.label_position === 'top'
      ? (renderY - SPACING / 3) * rs.zoom - fontSize
      : (renderY + currentNodeHeight + SPACING / 3) * rs.zoom;

  if (
    nameOpacity > 0 &&
    rs.refs.current.uiFlags.editingNodeNameUUID !== node.uuid
  ) {
    // FIXME need convenient text rendering utilities
    ctx.fontSize(fontSize);
    ctx.fontFace('archivo');
    ctx.textAlign(NVG.Align.CENTER | NVG.Align.TOP);
    ctx.fillColor(withAlphaf(LINE_COLORS.text, nameOpacity));
    ctx.text(
      (renderX + currentNodeWidth / 2) * rs.zoom,
      blockLabelY,
      name || '',
      null,
    );
  }

  const shouldUseShortName =
    blockClass.base.namespace === 'core' ||
    blockClass.base.namespace === 'quanser';
  const baseRasterIconID = getBlockIconID(
    shouldUseShortName ? printableClassName : node.type,
    node,
  );
  const rawIconScale = Math.round(window.devicePixelRatio * rs.zoom);
  const iconScale = rawIconScale > 2 ? 4 : rawIconScale < 1 ? 1 : rawIconScale;
  const scaledIconID = `renderer_icon_rasters/${baseRasterIconID}_${iconScale}x`;
  let rasterMeta = getOrInitLoadImageFromStore(
    nvg,
    `${process.env.PUBLIC_URL}/assets/${scaledIconID}.png`,
    scaledIconID,
    iconScale,
  );

  const fallbackIconID = `renderer_icon_rasters/generic_${iconScale}x`;
  const fallbackRasterMeta = getOrInitLoadImageFromStore(
    nvg,
    `${process.env.PUBLIC_URL}/assets/${fallbackIconID}.png`,
    fallbackIconID,
    iconScale,
  );

  rasterMeta =
    rasterMeta?.loadState === RasterLoadState.Failed
      ? fallbackRasterMeta?.loadState === RasterLoadState.Loaded
        ? fallbackRasterMeta
        : rasterMeta
      : rasterMeta;

  const flipIcon = nodeFlipped
    ? flippableIconIDs.includes(baseClassName.toLowerCase())
    : false;

  const underlyingIoPortId =
    node.type === 'core.Inport' || node.type === 'core.Outport'
      ? getSubmodelPortIdOfIoNode(node)
      : NaN;

  let ioPortId = underlyingIoPortId;
  const ioOrderMap =
    node.type === 'core.Inport'
      ? rs.refs.current.subdocumentParentNode?.uiprops.inport_order
      : rs.refs.current.subdocumentParentNode?.uiprops.outport_order;
  if (!isNaN(underlyingIoPortId) && ioOrderMap) {
    ioPortId = ioOrderMap[underlyingIoPortId];
  }

  let renderedIconWidth = 0;

  const isSmallConstantBlock =
    node.type === 'core.Constant' &&
    currentNodeHeight < renderConstants.BLOCK_MIN_HEIGHT;

  if (isSmallConstantBlock) {
    const valueTextFontSize = 10 * rs.zoom;
    const constNodeValue =
      rs.refs.current.constantBlockDisplayValues[node.uuid] ||
      `${node.parameters.value?.value}` ||
      'No value';
    const maxValueTextWidth =
      (currentNodeWidth - renderConstants.GRID_UNIT_PXSIZE * 2) * rs.zoom;

    const { width: valueTextWidth } = calculateTextSize(constNodeValue, {
      font: 'Archivo',
      fontSize: `${valueTextFontSize}px`,
    });

    const clipValueText = maxValueTextWidth < valueTextWidth;

    const valueTextX =
      nodeFlipped || clipValueText
        ? renderX + renderConstants.GRID_UNIT_PXSIZE
        : renderX + currentNodeWidth - renderConstants.GRID_UNIT_PXSIZE;

    if (clipValueText) {
      ctx.save();
      ctx.scissor(
        (renderX + renderConstants.GRID_UNIT_PXSIZE) * rs.zoom,
        renderY * rs.zoom,
        maxValueTextWidth,
        currentNodeHeight * rs.zoom,
      );
    }

    ctx.fontSize(valueTextFontSize);
    ctx.fontFace('archivo');
    if (clipValueText || nodeFlipped) {
      ctx.textAlign(NVG.Align.LEFT | NVG.Align.MIDDLE);
    } else {
      ctx.textAlign(NVG.Align.RIGHT | NVG.Align.MIDDLE);
    }
    ctx.fillColor(LINE_COLORS.text);
    ctx.text(
      valueTextX * rs.zoom,
      (renderY + currentNodeHeight / 2) * rs.zoom,
      constNodeValue,
      null,
    );

    if (clipValueText) {
      ctx.restore();

      renderTextOverflowGradient(
        nvg,
        rs,
        (renderX + renderConstants.GRID_UNIT_PXSIZE) * rs.zoom,
        (renderY + currentNodeHeight / 2) * rs.zoom,
        maxValueTextWidth,
        (currentNodeHeight - 3) * rs.zoom,
      );
    }
  } else if (!isNaN(ioPortId)) {
    // Similar comment as above: this special case shows the
    // port_id as label for Inport and Outport blocks.
    // TODO: https://collimator.atlassian.net/browse/UI-249
    ctx.fontSize(10 * rs.zoom);
    ctx.fontFace('archivo');
    ctx.textAlign(NVG.Align.CENTER | NVG.Align.MIDDLE);
    ctx.fillColor(LINE_COLORS.text);
    ctx.text(
      (renderX + currentNodeWidth / 2) * rs.zoom,
      (renderY + currentNodeHeight / 2) * rs.zoom,
      `${ioPortId}`,
      null,
    );
  } else if (rasterMeta?.loadState === RasterLoadState.Loaded) {
    const raw_w = rasterMeta.width / iconScale;
    const raw_h = rasterMeta.height / iconScale;
    const raw_x = renderX + (currentNodeWidth / 2 - raw_w / 2);
    const raw_y = renderY + (currentNodeHeight / 2 - raw_h / 2);

    const rx = flipIcon ? (raw_x + raw_w) * rs.zoom : raw_x * rs.zoom;
    const ry = raw_y * rs.zoom;
    const rw = flipIcon ? -raw_w * rs.zoom : raw_w * rs.zoom;
    const rh = raw_h * rs.zoom;

    renderedIconWidth = rw;

    drawImage(rs, nvg, rasterMeta.id, rx, ry, rw, rh, 0, 1);
  }

  const baseLabelMargin = renderConstants.GRID_UNIT_PXSIZE;
  const glyphOffsetLabelMargin =
    baseLabelMargin + (nodeHasGlyphs ? GLYPH_MARGIN : 0);

  const maxInputPortTextWidth =
    (currentNodeWidth * rs.zoom - renderedIconWidth) / 2 -
    glyphOffsetLabelMargin * rs.zoom;
  const maxOutputPortTextWidth =
    (currentNodeWidth * rs.zoom - renderedIconWidth) / 2 -
    baseLabelMargin * rs.zoom;

  const submodelInstance = node as SubmodelInstance;
  const isActuallyRefSubmodel =
    submodelInstance.type === 'core.ReferenceSubmodel';
  let submodelInfo;
  if (
    isActuallyRefSubmodel &&
    submodelInstance.submodel_reference_uuid &&
    isSubmodelRefAvailable()
  ) {
    const submodelSliceRef = getSubmodelRef();
    submodelInfo = getSpecificReferenceSubmodelByNode(
      submodelInstance,
      submodelSliceRef.idToVersionIdToSubmodelInfo,
    );
  }

  for (let i = 0; i < node.inputs.length; i++) {
    const portCoords = getPortNodeLocalCoordinate(node, PortSide.Input, {
      node: node.uuid,
      port: i,
    });

    if (!portCoords) continue;

    const portConnection = connectedPorts
      ? connectedPorts.find((p) => p.side === PortSide.Input && p.portId === i)
      : undefined;
    const portHasLink = Boolean(portConnection);
    const signalConnected = Boolean(portConnection?.fullyConnected);

    const portIsInLoopError = Boolean(
      portConnection &&
        rs.refs.current.algebraicLoopLinkIdSet.has(portConnection.linkUuid),
    );

    const connectedLinkIndex =
      rs.refs.current.linksIndexLUT[portConnection?.linkUuid || ''];
    const connectedLink: LinkInstance | undefined =
      rs.refs.current.links[connectedLinkIndex];

    const port = node.inputs[i];
    let acausalLinkData:
      | { isAcausal: false }
      | {
          isAcausal: true;
          domain: Extract<
            PortVariant,
            { variant_kind: 'acausal' }
          >['acausal_domain'];
        } = { isAcausal: false };

    if (port.variant?.variant_kind === 'acausal') {
      acausalLinkData = {
        isAcausal: true,
        domain: port.variant.acausal_domain,
      };
    }

    const portConnectionSrcNode =
      rs.refs.current.nodes[
        rs.refs.current.nodesIndexLUT[connectedLink?.src?.node || '']
      ];

    const dontShowAcausalColors = rs.refs.current.uiFlags.showDatatypesInModel;

    const currentPortConnectedColor = connectedLink
      ? getLinkColor(
          rs,
          connectedLink,
          acausalLinkData,
          portIsInLoopError,
          portConnectionSrcNode,
        )
      : acausalLinkData.isAcausal && !dontShowAcausalColors
      ? LINE_COLORS[acausalLinkData.domain]
      : LINE_COLORS.normal;

    const portHovering =
      rs.refs.current.uiFlags.canEditModel &&
      rs.hoveringEntity !== undefined &&
      rs.hoveringEntity.entityType === HoverEntityType.Port &&
      rs.hoveringEntity.port.blockUuid === node.uuid &&
      rs.hoveringEntity.port.side === PortSide.Input &&
      rs.hoveringEntity.port.portId === i;

    const portX = portCoords.x + renderX;
    const portY = isIOBlock
      ? portCoords.y + renderY_raw
      : portCoords.y + renderY;

    const userDrawingPortsLink =
      (rs.mouseState.state === MouseActions.DrawingLinkFromStart ||
        rs.mouseState.state === MouseActions.DrawingLinkFromEnd) &&
      rs.mouseState.linkUuid === portConnection?.linkUuid;

    let isHollow =
      isPortHollowVis(node.inputs[i], i, blockClass) ||
      Boolean(
        submodelInfo && submodelInfo.portDefinitionsInputs[i]?.default_value,
      );

    if (node.uiprops.show_port_name_labels) {
      const labelText = node.inputs[i].name || '';

      const { width: textWidthRaw, height: textHeight } = calculateTextSize(
        labelText,
        {
          font: 'Archivo',
          fontSize: `${fontSize}px`,
        },
      );

      const clipText = maxInputPortTextWidth < textWidthRaw;
      const renderingTextWidth = clipText
        ? maxInputPortTextWidth
        : textWidthRaw;

      const textX = nodeFlipped
        ? (portX - glyphOffsetLabelMargin) * rs.zoom - renderingTextWidth
        : (portX + glyphOffsetLabelMargin) * rs.zoom;
      const textY = portY * rs.zoom;

      if (clipText) {
        ctx.save();
        ctx.scissor(
          textX,
          textY - textHeight / 2,
          maxInputPortTextWidth,
          textHeight,
        );
      }
      ctx.fontSize(fontSize);
      ctx.fontFace('archivo');
      ctx.textAlign(NVG.Align.LEFT | NVG.Align.MIDDLE);
      ctx.fillColor(withAlphaf(LINE_COLORS.text, nameOpacity));
      ctx.text(textX, textY, labelText, null);
      if (clipText) {
        ctx.restore();

        renderTextOverflowGradient(
          nvg,
          rs,
          textX,
          textY,
          renderingTextWidth,
          textHeight,
        );
      }
    }

    const hasError =
      Boolean(nodeError?.inputPorts?.includes(i)) || portIsInLoopError;

    drawPort(
      nvg,
      rs,
      portX,
      portY,
      rs.zoom,
      PortSide.Input,
      portHasLink,
      signalConnected,
      portHovering,
      node.uiprops.directionality === 'left',
      currentPortConnectedColor,
      userDrawingPortsLink,
      isHollow,
      hasError,
      acausalLinkData.isAcausal ? acausalLinkData.domain : undefined,
    );

    drawPortGlyph(rs, nvg, portX, portY, rs.zoom, i, node);
  }

  for (let i = 0; i < node.outputs.length; i++) {
    const portCoords = getPortNodeLocalCoordinate(node, PortSide.Output, {
      node: node.uuid,
      port: i,
    });

    if (!portCoords) continue;

    const portConnection = connectedPorts
      ? connectedPorts.find((p) => p.side === PortSide.Output && p.portId === i)
      : undefined;
    const portHasLink = Boolean(portConnection);
    const signalConnected = Boolean(portConnection?.fullyConnected);

    const portIsInLoopError = Boolean(
      portConnection &&
        rs.refs.current.algebraicLoopLinkIdSet.has(portConnection.linkUuid),
    );

    const port = node.outputs[i];
    let acausalLinkData:
      | { isAcausal: false }
      | {
          isAcausal: true;
          domain: Extract<
            PortVariant,
            { variant_kind: 'acausal' }
          >['acausal_domain'];
        } = { isAcausal: false };

    if (port.variant?.variant_kind === 'acausal') {
      acausalLinkData = {
        isAcausal: true,
        domain: port.variant.acausal_domain,
      };
    }

    const connectedLinkIndex =
      rs.refs.current.linksIndexLUT[portConnection?.linkUuid || ''];
    const connectedLink: LinkInstance | undefined =
      rs.refs.current.links[connectedLinkIndex];

    const portConnectionSrcNode =
      rs.refs.current.nodes[
        rs.refs.current.nodesIndexLUT[connectedLink?.src?.node || '']
      ];

    const dontShowAcausalColors = rs.refs.current.uiFlags.showDatatypesInModel;

    let currentPortConnectedColor = connectedLink
      ? getLinkColor(
          rs,
          connectedLink,
          acausalLinkData,
          portIsInLoopError,
          portConnectionSrcNode,
        )
      : acausalLinkData.isAcausal && !dontShowAcausalColors
      ? LINE_COLORS[acausalLinkData.domain]
      : LINE_COLORS.normal;

    const portHovering =
      rs.refs.current.uiFlags.canEditModel &&
      rs.hoveringEntity !== undefined &&
      rs.hoveringEntity.entityType === HoverEntityType.Port &&
      rs.hoveringEntity.port.blockUuid === node.uuid &&
      rs.hoveringEntity.port.side === PortSide.Output &&
      rs.hoveringEntity.port.portId === i;

    const portX = portCoords.x + renderX;
    const portY = isIOBlock
      ? portCoords.y + renderY_raw
      : portCoords.y + renderY;

    const userDrawingPortsLink =
      (rs.mouseState.state === MouseActions.DrawingLinkFromStart ||
        rs.mouseState.state === MouseActions.DrawingLinkFromEnd) &&
      rs.mouseState.linkUuid === portConnection?.linkUuid;

    if (node.uiprops.show_port_name_labels) {
      const labelText = node.outputs[i].name || '';

      const { width: textWidthRaw, height: textHeight } = calculateTextSize(
        labelText,
        {
          font: 'Archivo',
          fontSize: `${fontSize}px`,
        },
      );

      const clipText = maxOutputPortTextWidth < textWidthRaw;
      const renderingTextWidth = clipText
        ? maxOutputPortTextWidth
        : textWidthRaw;

      const textX = nodeFlipped
        ? (portX + baseLabelMargin) * rs.zoom
        : (portX - baseLabelMargin) * rs.zoom - renderingTextWidth;
      const textY = portY * rs.zoom;

      if (clipText) {
        ctx.save();
        ctx.scissor(
          textX,
          textY - textHeight / 2,
          maxOutputPortTextWidth,
          textHeight,
        );
      }
      ctx.fontSize(fontSize);
      ctx.fontFace('archivo');
      ctx.textAlign(NVG.Align.LEFT | NVG.Align.MIDDLE);
      ctx.fillColor(withAlphaf(LINE_COLORS.text, nameOpacity));
      ctx.text(textX, textY, labelText, null);
      if (clipText) {
        ctx.restore();

        renderTextOverflowGradient(
          nvg,
          rs,
          textX,
          textY,
          renderingTextWidth,
          textHeight,
        );
      }
    }

    drawPort(
      nvg,
      rs,
      portX,
      portY,
      rs.zoom,
      PortSide.Output,
      portHasLink,
      signalConnected,
      portHovering,
      node.uiprops.directionality === 'left',
      currentPortConnectedColor,
      userDrawingPortsLink,
      false, // hollow
      portIsInLoopError, // hasError
      acausalLinkData.isAcausal ? acausalLinkData.domain : undefined,
    );

    // NOTE: this is basically used as an in-place label drawing function.
    // at one point it was used to render all labels, but now it's just per-port.
    // "labels" is a misnomer but cleaning this up properly would make the current
    // task take way longer, so leaving this note here.
    drawSignalLabels(nvg, rs, portX, portY + 4, 0, 0, node.uuid, i);
  }

  if (node.uiprops.is_autotuned) {
    const rawScale = Math.round(window.devicePixelRatio * rs.zoom);
    const scale = rawScale > 2 ? 4 : rawScale < 1 ? 1 : rawScale;
    const scaledRasterID = `tuned_indicator_${scale}x`;
    const tunedRaster = getOrInitLoadImageFromStore(
      nvg,
      `${process.env.PUBLIC_URL}/assets/${scaledRasterID}.png`,
      scaledRasterID,
      scale,
    );
    if (tunedRaster?.loadState === RasterLoadState.Loaded) {
      const rw = (tunedRaster.width / scale) * rs.zoom;
      const rh = (tunedRaster.height / scale) * rs.zoom;
      const rx =
        (renderX + currentNodeWidth - tunedRaster.width / scale + 4) * rs.zoom;
      const ry = (renderY - 4) * rs.zoom;
      // 58% of the base color of the image (which is "gray 85%")
      // which brings it to approximately "gray 50%", as specified in designs
      drawImage(rs, nvg, tunedRaster.id, rx, ry, rw, rh, 0, 0.58);
    }
  }

  const signalPlotterToggleState =
    rs.refs.current.signalPlotterStatesLUT[node.uuid];
  drawSignalPlotter(
    nvg,
    rs,
    renderX,
    renderY,
    currentNodeWidth,
    signalPlotterToggleState,
    nodeFlipped,
  );
}
