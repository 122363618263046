/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Selector: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21 10C20.7239 10 20.5 10.2239 20.5 10.5V11.5C20.5 11.7761 20.7239 12 21 12H22C22.2761 12 22.5 11.7761 22.5 11.5C22.5 11.2239 22.2761 11 22 11H21.5V10.5C21.5 10.2239 21.2761 10 21 10Z"
      fill={props.fill}
    />
    <path
      d="M20.5 8.5C20.5 8.77614 20.7239 9 21 9C21.2761 9 21.5 8.77614 21.5 8.5V8H22C22.2761 8 22.5 7.77614 22.5 7.5C22.5 7.22386 22.2761 7 22 7H21C20.7239 7 20.5 7.22386 20.5 7.5V8.5Z"
      fill={props.fill}
    />
    <path
      d="M24 7C23.7239 7 23.5 7.22386 23.5 7.5C23.5 7.77614 23.7239 8 24 8H24.5V8.5C24.5 8.77614 24.7239 9 25 9C25.2761 9 25.5 8.77614 25.5 8.5V7.5C25.5 7.22386 25.2761 7 25 7H24Z"
      fill={props.fill}
    />
    <path
      d="M25.5 10.5C25.5 10.2239 25.2761 10 25 10C24.7239 10 24.5 10.2239 24.5 10.5V11H24C23.7239 11 23.5 11.2239 23.5 11.5C23.5 11.7761 23.7239 12 24 12H25C25.2761 12 25.5 11.7761 25.5 11.5V10.5Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M17.6464 7.14645C17.8417 6.95118 18.1583 6.95118 18.3536 7.14645L20.7071 9.5L18.3536 11.8536C18.1583 12.0488 17.8417 12.0488 17.6464 11.8536C17.4512 11.6583 17.4512 11.3417 17.6464 11.1464L18.7929 10H14C13.4477 10 13 10.4477 13 11V16H14.5C14.7761 16 15 16.2239 15 16.5V18H17V16.5C17 16.2239 17.2239 16 17.5 16H21.5C21.7761 16 22 16.2239 22 16.5V18H24V16.5C24 16.2239 24.2239 16 24.5 16H28.5C28.7761 16 29 16.2239 29 16.5V20.5C29 20.7761 28.7761 21 28.5 21H24.5C24.2239 21 24 20.7761 24 20.5V19H22V20.5C22 20.7761 21.7761 21 21.5 21H17.5C17.2239 21 17 20.7761 17 20.5V19H15V20.5C15 20.7761 14.7761 21 14.5 21H10.5C10.2239 21 10 20.7761 10 20.5V19H8V20.5C8 20.7761 7.77614 21 7.5 21H3.5C3.22386 21 3 20.7761 3 20.5V16.5C3 16.2239 3.22386 16 3.5 16H7.5C7.77614 16 8 16.2239 8 16.5V18H10V16.5C10 16.2239 10.2239 16 10.5 16H12V11C12 9.89543 12.8954 9 14 9H18.7929L17.6464 7.85355C17.4512 7.65829 17.4512 7.34171 17.6464 7.14645ZM4.5 17.5V19.5H6.5V17.5H4.5ZM13.5 19.5H11.5V17.5H13.5V19.5ZM20.5 17.5H18.5V19.5H20.5V17.5ZM27.5 19.5H25.5V17.5H27.5V19.5Z"
      fill={props.fill}
    />
  </svg>
);

Selector.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Selector;
