/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Loader: React.FC<Props> = (props) => (
  <svg viewBox="0 0 24 24" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M16.4584 12.7101C16.4565 12.7199 16.4547 12.7298 16.453 12.7397C16.3241 13.5156 15.9939 14.249 15.4894 14.8637C14.8269 15.671 13.9049 16.2236 12.8806 16.4273C11.8564 16.6311 10.7931 16.4733 9.8721 15.981C8.95107 15.4887 8.22923 14.6923 7.82957 13.7275C7.42992 12.7626 7.37718 11.689 7.68034 10.6897C7.98349 9.69028 8.62379 8.82693 9.49214 8.24672C10.3605 7.66652 11.4031 7.40534 12.4425 7.50771C13.2338 7.58565 13.9859 7.87067 14.6258 8.32822C14.6339 8.33405 14.6421 8.33975 14.6505 8.3453C14.694 8.3744 14.7396 8.39967 14.7867 8.42096C15.098 8.56184 15.4714 8.52864 15.7173 8.28273C16.0074 7.99258 16.0099 7.5179 15.6861 7.2658C15.6217 7.21566 15.5564 7.16689 15.4901 7.11951C15.455 7.09441 15.4197 7.0697 15.384 7.04538C14.557 6.48054 13.5965 6.12822 12.5881 6.0289C11.2067 5.89284 9.82077 6.23999 8.66658 7.01119C7.51239 7.7824 6.66131 8.92994 6.25836 10.2583C5.85541 11.5867 5.92551 13.0136 6.45672 14.2961C6.98794 15.5786 7.9474 16.6372 9.17162 17.2915C10.3958 17.9459 11.8091 18.1555 13.1705 17.8847C14.532 17.6139 15.7574 16.8794 16.6381 15.8064C17.2809 15.0231 17.711 14.0948 17.8963 13.1106C17.9043 13.0682 17.9118 13.0257 17.9189 12.9832C17.9323 12.9028 17.944 12.8221 17.9541 12.7411C18.0047 12.3339 17.6674 12 17.257 12C16.9093 12 16.6218 12.2405 16.5013 12.5603C16.483 12.6086 16.4686 12.6587 16.4584 12.7101Z"
      fill={props.fill}
    />
  </svg>
);

Loader.defaultProps = {
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Loader;
