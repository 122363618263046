/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Ccode: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.25 5.5C6.25 4.25736 7.25736 3.25 8.5 3.25H23.5C24.7426 3.25 25.75 4.25736 25.75 5.5V14.4125L24.25 13.5V5.5C24.25 5.08579 23.9142 4.75 23.5 4.75H8.5C8.08579 4.75 7.75 5.08579 7.75 5.5V26.5C7.75 26.9142 8.08579 27.25 8.5 27.25H23.5C23.9142 27.25 24.25 26.9142 24.25 26.5L25.75 25.6654V26.5C25.75 27.7426 24.7426 28.75 23.5 28.75H8.5C7.25736 28.75 6.25 27.7426 6.25 26.5V5.5Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M28 22.6954C28 22.9132 27.879 23.1143 27.6824 23.2232L25.9378 24.1898C24.767 24.8385 23.4185 25.5856 22.8172 25.9186C22.6207 26.0274 22.3787 26.0274 22.1824 25.9186L20.4262 24.9456C19.2581 24.2984 17.9165 23.555 17.3173 23.2232C17.121 23.1143 17 22.9134 17 22.6957V17.2846C17 17.0681 17.1207 16.8681 17.3167 16.7601C18.2844 16.2252 21.1971 14.6152 22.1648 14.0801C22.2722 14.0207 22.4004 13.9946 22.5267 14.0009C22.5705 14.0027 22.6141 14.0088 22.6569 14.0193C22.7151 14.033 22.771 14.0541 22.8222 14.0824C23.306 14.3498 24.2737 14.8826 25.2414 15.4154C26.209 15.9482 27.1766 16.4809 27.6604 16.7484C27.7386 16.7918 27.7982 16.8441 27.8515 16.9129C27.8564 16.9186 27.861 16.9244 27.8657 16.9302C27.9218 16.9877 27.954 17.056 27.9725 17.1275C27.9905 17.1842 28 17.2439 28 17.3049V22.6954ZM18.9476 21.9257C18.6287 21.3544 18.4473 20.698 18.4473 20.0001C18.4473 17.7924 20.2631 16.0001 22.4999 16.0001C23.9829 16.0001 25.2809 16.788 25.9873 17.9624L25.9893 17.9657L24.2295 18.9582L24.2267 18.9536C23.8699 18.3817 23.2297 18.0001 22.4999 18.0001C21.5212 18.0001 20.7038 18.6861 20.5148 19.5972C20.4878 19.7273 20.4736 19.8621 20.4736 20.0001C20.4736 20.3375 20.5584 20.6555 20.7082 20.9345C21.0484 21.568 21.7235 22.0001 22.4999 22.0001C23.2438 22.0001 23.8947 21.6034 24.2471 21.0129L26.023 21.9772C25.3252 23.185 24.0082 24.0001 22.4999 24.0001C20.9702 24.0001 19.6374 23.1618 18.9476 21.9257Z"
      fill={props.fill}
    />
    <path
      d="M10 7.5C9.72386 7.5 9.5 7.72386 9.5 8C9.5 8.27614 9.72386 8.5 10 8.5H22C22.2761 8.5 22.5 8.27614 22.5 8C22.5 7.72386 22.2761 7.5 22 7.5H10Z"
      fill={props.fill}
    />
    <path
      d="M10 11C9.72386 11 9.5 11.2239 9.5 11.5C9.5 11.7761 9.72386 12 10 12H22C22.2761 12 22.5 11.7761 22.5 11.5C22.5 11.2239 22.2761 11 22 11H10Z"
      fill={props.fill}
    />
    <path
      d="M10 14.5C9.72386 14.5 9.5 14.7239 9.5 15C9.5 15.2761 9.72386 15.5 10 15.5H14C14.2761 15.5 14.5 15.2761 14.5 15C14.5 14.7239 14.2761 14.5 14 14.5H10Z"
      fill={props.fill}
    />
  </svg>
);

Ccode.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Ccode;
