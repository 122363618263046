/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Play: React.FC<Props> = (props) => (
  <svg viewBox="0 0 16 16" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M4.5 3.90213C4.5 3.11669 5.36395 2.63785 6.03 3.05413L12.5866 7.15201C13.2133 7.54367 13.2133 8.45633 12.5866 8.848L6.03 12.9459C5.36395 13.3622 4.5 12.8833 4.5 12.0979V3.90213ZM12.0566 8L5.5 3.90213V12.0979L12.0566 8Z"
      fill={props.fill}
    />
  </svg>
);

Play.defaultProps = {
  width: '16',
  height: '16',
  viewBox: '0 0 16 16',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Play;
