import { t } from '@lingui/macro';
import { useCreateSubmodel } from 'app/api/useCreateSubmodel';
import {
  ModelDiagram,
  SubmodelsSection,
} from 'app/generated_types/SimulationModel';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { submodelsActions } from 'app/slices/submodelsSlice';
import {
  SubmodelConfiguration,
  SubmodelFull,
} from 'app/apiGenerated/generatedApiTypes';
import { createDefaultSubdiagram } from 'app/utils/subdiagramUtils';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useNotifications } from 'ui/common/notifications/useNotifications';
import { nodeTypeIsLocalSubdiagram } from 'app/helpers';
import { specialGetStateMachineNodeInstanceId } from 'app/utils/insertNodeUtils';
import { StateMachineDiagram } from '@collimator/model-schemas-ts';
import { Statemachine } from 'ui/common/Icons/Block';

/**
 * A hook that coordinates the RTK Query data and the redux store state
 * when creating a reference submodel.
 */
export function useCreateReferenceSubmodel() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { createSubmodel } = useCreateSubmodel();

  const { createShowError } = useNotifications();

  const allStateMachines = useAppSelector(
    (state) => state.model.present.stateMachines,
  );

  const createReferenceSubmodel = React.useCallback(
    (
      projectId: string,
      referenceSubmodelName: string,
      diagram?: ModelDiagram,
      submodels?: SubmodelsSection,
      configuration?: SubmodelConfiguration,
    ) => {
      const submodelsToCreate = (submodels || {
        references: {},
        diagrams: {},
      }) as any;

      let copiedStateMachines: { [id: string]: StateMachineDiagram } = {};

      // gather all state machines
      if (diagram) {
        const checkingDiagrams = [diagram];

        while (checkingDiagrams.length) {
          const currentDiagram = checkingDiagrams.pop();
          if (!currentDiagram) continue;

          for (let i = 0; i < currentDiagram.nodes.length; i++) {
            const node = currentDiagram?.nodes[i];

            if (nodeTypeIsLocalSubdiagram(node.type) && submodels) {
              const diagramUuid = submodels.references[node.uuid]?.diagram_uuid;
              const diagram = diagramUuid
                ? submodels.diagrams[diagramUuid]
                : undefined;

              if (diagram) checkingDiagrams.push(diagram);
            }

            if (node.type === 'core.StateMachine' && allStateMachines) {
              const smid = specialGetStateMachineNodeInstanceId(node);
              if (!smid) continue;
              const stateMachine = allStateMachines[smid];

              if (stateMachine) {
                copiedStateMachines[smid] = stateMachine;
              }
            }
          }
        }
      }

      const diagramToCreate =
        diagram || createDefaultSubdiagram('core.ReferenceSubmodel');

      // If the new submodel is not tied to the current diagram,
      // we can directly navigate to it once it is created,
      // which is also likely what the user intended because
      // the submodel will be an empty starting point with no instances yet.
      const navigateToNewSubmodel = !diagram && !submodels;

      createSubmodel({
        projectUuid: projectId,
        submodelCreateRequest: {
          name: referenceSubmodelName,
          diagram: diagramToCreate,
          submodels: submodelsToCreate,
          state_machines: copiedStateMachines,
          configuration,
        },
      })
        .then((apiSubmodel: SubmodelFull) => {
          dispatch(submodelsActions.submodelPublishCompleted(apiSubmodel));
          dispatch(submodelsActions.requestSubmodels(projectId));

          if (navigateToNewSubmodel) {
            // We are creating the submodel from the dashboard,
            // so navigate to the submodel when it is created.
            navigate(`/projects/${projectId}/models/${apiSubmodel.uuid}`);
          }
        })
        .catch((...args) => {
          // just in case the convert-to-submodel's state management
          // lets an invalid conversion slip through,
          // we clear this so that the modal state doesn't get stuck.
          dispatch(submodelsActions.clearGroupToConvertToSubmodel());

          createShowError(
            t({
              id: 'submodelApi.createSubmodelError',
              message: 'Unable to create submodel.',
            }),
          )(...args);
        });

      if (!navigateToNewSubmodel) {
        dispatch(submodelsActions.submodelPublishStarted());
      }
    },
    [createSubmodel, dispatch, createShowError, navigate, allStateMachines],
  );

  return {
    createReferenceSubmodel,
  };
}
