import { t } from '@lingui/macro';
import { useProjectInvitation } from 'app/api/useProjectInvitation';
import { useAppDispatch } from 'app/hooks';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useNotifications } from 'ui/common/notifications/useNotifications';

const ClaimInvite: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { acceptProjectInvite } = useProjectInvitation();

  const { showError } = useNotifications();

  React.useEffect(() => {
    const query = window.location.search;
    const params = new URLSearchParams(query);
    const projectId = params.get('project_id');
    const permissionId = params.get('permission_id');
    const inviteToken = params.get('invite_token');

    if (projectId && permissionId && inviteToken) {
      acceptProjectInvite(projectId, permissionId, inviteToken);
    } else {
      if (projectId) {
        navigate(`/projects/${projectId}`);
      } else {
        navigate(`/projects`);
      }
      showError(
        t({
          id: 'projectApi.invalidProjectShareInviteLink',
          message: 'Invalid project share invite link',
        }),
      );
    }
  }, [navigate, dispatch, showError, acceptProjectInvite]);
  return <div />;
};

export default ClaimInvite;
