import { useModelVersionData } from 'app/api/useModelVersionData';
import { useSubmodel } from 'app/api/useSubmodel';
import { VersionTagValues } from 'app/apiTransformers/convertPostSubmodelsFetch';
import React from 'react';

interface Props {
  projectId: string;
  submodelReferenceId: string;
  submodelVersionId: string;
  loadReferencedSubmodelInfos?: boolean;
}

export const SubmodelItemLoader: React.FC<Props> = ({
  projectId,
  submodelReferenceId,
  submodelVersionId,
  loadReferencedSubmodelInfos,
}) => {
  useModelVersionData(submodelReferenceId, submodelVersionId, 'Submodel', {
    loadReferencedSubmodelInfos: loadReferencedSubmodelInfos === true,
  });

  const submodelLoadOptions = React.useMemo(
    () => ({
      loadReferencedSubmodelInfos: loadReferencedSubmodelInfos === true,
      skip:
        !submodelReferenceId ||
        submodelVersionId !== VersionTagValues.LATEST_VERSION,
    }),
    [submodelReferenceId, submodelVersionId, loadReferencedSubmodelInfos],
  );

  useSubmodel(projectId, submodelReferenceId, submodelLoadOptions);

  return null;
};
