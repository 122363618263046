import styled from '@emotion/styled/macro';
import { t } from '@lingui/macro';
import { useGetProjectGitRemoteQuery } from 'app/apiGenerated/generatedApi';
import React from 'react';
import { SplitButton } from 'ui/common/Button/SplitButton';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import { Share } from 'ui/common/Icons/Standard';
import { ItemType, MenuItemConfig } from 'ui/common/Menu/menuItemTypes';
import { useModal } from 'ui/common/Modal/useModal';
import SelectInput, { SelectInputOption } from 'ui/common/SelectInput';
import { useAppParams } from 'util/useAppParams';
import { GitCheckoutBranchModal } from './GitCheckoutBranch';
import { GitDeleteBranchModal } from './GitDeleteBranchModal';
import { GitPushChangesModal } from './GitPushChangesModal';
import { GitResetBranchModal } from './GitResetBranchModal';

const GitBranchCommandsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
  padding: ${({ theme }) => theme.spacing.normal};
  background: ${({ theme }) => theme.colors.grey[10]};

  > button {
    margin-right: ${({ theme }) => theme.spacing.normal};
  }
`;

const BranchSwitcherContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 1;

  > label {
    margin-right: ${({ theme }) => theme.spacing.normal};
    color: ${({ theme }) => theme.colors.text.secondary};
  }
`;

const StyledSelectInput = styled(SelectInput)`
  margin-right: ${({ theme }) => theme.spacing.normal};

  .selectinput__control {
    background-color: white;
  }
`;

// Note: ':otherbranch:' is not a valid git branch name, so it is safe to use here
const OTHER_BRANCH_OPT_VAL = ':otherbranch:';

export const ProjectGitBranchCommands = () => {
  const { triggerModal } = useModal();
  const { projectId } = useAppParams();

  const { data: remoteInfo, isLoading: isLoadingRemote } =
    useGetProjectGitRemoteQuery(
      { projectUuid: projectId || '', expand: ['branches'] },
      { skip: !projectId },
    );

  // NOTE: this should be replaced with backend data determining whether the branch has changes to push
  let hasChangesToPush = true;

  const branches = React.useMemo(
    () => remoteInfo?.branches?.map((branch) => branch.name) || [],
    [remoteInfo],
  );
  const defaultBranch = remoteInfo?.default_branch;

  const branchOptions: SelectInputOption[] = React.useMemo(() => {
    const realOptions = branches.map((branch) => ({
      label: branch,
      value: branch,
    }));

    return [
      ...realOptions,
      {
        label: t({
          id: 'dashboard.projectsDetailGitBranchCommands.otherBranch.Label',
          message: 'Other branch...',
        }),
        value: OTHER_BRANCH_OPT_VAL,
      },
    ];
  }, [branches]);

  const [selectedBranch, rawSetSelectedBranch] = React.useState<
    string | undefined
  >();
  React.useEffect(() => {
    if (!defaultBranch || isLoadingRemote) return;
    rawSetSelectedBranch(defaultBranch);
  }, [defaultBranch, isLoadingRemote]);

  const openCheckoutBranchModal = () => {
    triggerModal(
      <GitCheckoutBranchModal />,
      t({
        id: 'projectDetails.gitCheckoutBranchModal.title',
        message: 'Checkout branch',
      }),
    );
  };

  const setSelectedBranch = (newValue: string) => {
    if (newValue === OTHER_BRANCH_OPT_VAL) {
      openCheckoutBranchModal();
      return;
    }

    rawSetSelectedBranch(newValue);
  };

  const openPushChangesModal = React.useCallback(() => {
    if (!selectedBranch) return;

    triggerModal(
      <GitPushChangesModal currentBranch={selectedBranch} />,
      t({
        id: 'projectDetails.gitPushChangesModal.title',
        message: 'Push changes',
      }),
    );
  }, [triggerModal, selectedBranch]);

  const openResetBranchModal = React.useCallback(() => {
    triggerModal(
      <GitResetBranchModal branch={selectedBranch} />,
      t({
        id: 'projectDetails.gitResetBranchModal.title',
        message: 'Reset branch',
      }),
    );
  }, [triggerModal, selectedBranch]);

  const openDeleteBranchModal = React.useCallback(() => {
    triggerModal(
      <GitDeleteBranchModal />,
      t({
        id: 'projectDetails.gitDeleteBranchModal.title',
        message: 'Delete branch',
      }),
    );
  }, [triggerModal]);

  const otherCommandsMenuItems: MenuItemConfig[] = React.useMemo(
    () => [
      {
        type: ItemType.Button,
        content: t({
          id: 'projectDetails.gitResetBranchMenuItem.label',
          message: 'Reset branch',
        }),
        onClick: openResetBranchModal,
      },
      {
        type: ItemType.Button,
        content: t({
          id: 'projectDetails.gitDeleteBranchMenuItem.label',
          message: 'Delete branch',
        }),
        onClick: openDeleteBranchModal,
      },
    ],
    [openDeleteBranchModal, openResetBranchModal],
  );

  return (
    <GitBranchCommandsWrapper>
      <BranchSwitcherContainer>
        <label>
          {t({
            id: 'dashboard.projectsDetailGitBranchCommands.branchSwitcher.Label',
            message: 'Branch:',
          })}
        </label>
        <StyledSelectInput
          options={branchOptions}
          onSelectValue={setSelectedBranch}
          currentValue={selectedBranch}
        />
      </BranchSwitcherContainer>
      <SplitButton
        mainTestId="project-detail-push-git-changes-button"
        mainLabel={t({
          id: 'dashboard.projectsDetailGitBranchCommands.pushChanges.Label',
          message: 'Push changes',
        })}
        mainButtonProps={{
          disabled: !hasChangesToPush || !selectedBranch,
          Icon: Share,
          variant: ButtonVariants.SmallPrimary,
          onClick: openPushChangesModal,
        }}
        menuProps={{
          items: otherCommandsMenuItems,
        }}
      />
    </GitBranchCommandsWrapper>
  );
};
