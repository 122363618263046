import { NodeInstance } from 'types';
import { mapAllNodes } from './migrationHelper';
import { MigratedModelV3 } from './modelMigrations_v3';

export interface MigratedModelV4 extends MigratedModelV3 {
  schema_version: '4';
}

function fixMatrixMultiplication(node: NodeInstance): NodeInstance {
  if (node.type !== 'core.MatrixMultiplication') return node;

  // Wildcat block does not need explicit parameters
  return {
    ...node,
    parameters: {},
  };
}

function fixPIDDiscrete(node: NodeInstance): NodeInstance {
  if (node.type !== 'core.PID_Discrete') return node;

  const parameters = { ...node.parameters };
  if (parameters['filter_type'] === undefined) {
    parameters['filter_type'] = { value: 'none', is_string: true };
  }
  if (parameters['filter_coefficient'] === undefined) {
    parameters['filter_coefficient'] = { value: '1.0' };
  }

  return { ...node, parameters };
}

function fixRandomNormal(node: NodeInstance): NodeInstance {
  if ((node.type as string) !== 'core.RandomNormal') return node;

  // Convert shape port parameter to block parameter
  const out_0 = node.outputs[0];
  const shape = out_0?.parameters?.shape
    ? { value: out_0.parameters.shape.value || '()' }
    : { value: '()' };

  return {
    ...node,
    type: 'core.RandomNumber',
    parameters: { shape },
    outputs: [{ name: 'out_0' }],
  };
}

export function migrateModelDataV3toV4(
  modelData: MigratedModelV3,
): MigratedModelV4 {
  const data = { ...modelData, schema_version: '4' } as MigratedModelV4;

  const fixAll = (node: NodeInstance) => {
    node = fixMatrixMultiplication(node);
    node = fixPIDDiscrete(node);
    node = fixRandomNormal(node);
    return node;
  };

  mapAllNodes(fixAll, data.diagram, data.subdiagrams);

  return data;
}
