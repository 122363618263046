export const onBrowserFocusChange = (
  hideCb: () => void,
  showCb?: () => void,
): void => {
  if (hideCb) {
    window.addEventListener('blur', hideCb);
  }

  if (showCb) {
    window.addEventListener('focus', showCb);
  }
};
