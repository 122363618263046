import { t } from '@lingui/macro';
import styled from '@emotion/styled/macro';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { modelActions } from 'app/slices/modelSlice';
import React from 'react';
import Button from 'ui/common/Button/Button';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import Input from 'ui/common/Input/Input';
import { Plus, Remove } from 'ui/common/Icons/Standard';
import { Close, Search } from 'ui/common/Icons/Small';
import { requiredRules } from 'ui/common/Input/inputValidation';
import { isValidSubmodelParameterNameRuleSet } from 'ui/common/Input/inputValidationForModels';
import SectionHeading from 'ui/common/Inputs/SectionHeading';
import {
  DetailInputRowsSection,
  DetailsDarkGroup,
  DetailsDarkGroupLabel,
  DetailsInput,
  DetailsRow,
  DetailsSection,
} from 'ui/modelEditor/DetailsComponents';

const DarkenedInputWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.small};

  input,
  input:focus {
    background: #f1f3f3;
  }

  input::placeholder {
    color: #b6bbbb;
  }

  svg {
    fill: #b6bbbb !important;
  }

  &:hover {
    svg {
      fill: #5c6f70 !important;
    }

    input::placeholder {
      color: #5c6f70 !important;
    }
  }

  svg.focused {
    fill: #082426 !important;
  }
  input.focused::placeholder {
    color: #082426 !important;
  }
`;

interface Props {
  canEdit: boolean;
}

const ParameterDefinitionDetails: React.FC<Props> = ({ canEdit }: Props) => {
  const dispatch = useAppDispatch();

  const [parameterSearchValue, setParameterSearchValue] = React.useState('');
  const parameterDefinitions = useAppSelector(
    (state) => state.model.present.parameterDefinitions,
  );
  const filteredParamDefs = React.useMemo(() => {
    const trimmedSearchVal = parameterSearchValue.trim();

    if (trimmedSearchVal === '')
      return parameterDefinitions.map((paramData) => ({
        display: true,
        paramData,
      }));

    const lowercaseSearchVal = trimmedSearchVal.toLowerCase();
    return parameterDefinitions.map((paramData) => ({
      display: paramData.name.toLowerCase().indexOf(lowercaseSearchVal) !== -1,
      paramData,
    }));
  }, [parameterDefinitions, parameterSearchValue]);

  const portDefinitionsInputs = useAppSelector(
    (state) => state.model.present.portDefinitionsInputs,
  );
  const portDefinitionsOutputs = useAppSelector(
    (state) => state.model.present.portDefinitionsOutputs,
  );

  // ref allows us to set this without needing an extra state tick.
  // the re-render happens as a result of adding another param.
  const autoFocusUnlocked = React.useRef(false);

  const addParameterDefinition = () => {
    if (!canEdit) return;

    autoFocusUnlocked.current = true;
    dispatch(modelActions.addParameterDefinition({}));
  };

  const removeParameterDefinition = (paramDefId: string) => {
    dispatch(
      modelActions.removeParameterDefinition({
        paramDefId,
      }),
    );
  };

  const updateParameterName = (paramDefId: string, newName: string) => {
    dispatch(
      modelActions.updateParameterDefinitionName({
        paramDefId,
        newName,
      }),
    );
  };

  const updateParameterDefaultValue = (
    paramDefId: string,
    newDefaultValue: string,
    inputHeight?: string,
  ) => {
    dispatch(
      modelActions.updateParameterDefinitionDefaultValue({
        paramDefId,
        newDefaultValue,
        inputHeight,
      }),
    );
  };

  const updateParameterDescription = (
    paramDefId: string,
    newDescription: string,
    inputHeight?: string,
  ) => {
    dispatch(
      modelActions.updateParameterDefinitionDescription({
        paramDefId,
        newDescription,
        inputHeight,
      }),
    );
  };

  return (
    <>
      <SectionHeading testId="parameter-definition-details">
        {t({
          id: 'referenceSubmodelDetails.parameterDefinitionsTitle',
          message: 'Submodel Parameters',
        })}
      </SectionHeading>
      <DetailInputRowsSection>
        {parameterDefinitions.length > 0 && (
          <DarkenedInputWrapper>
            <Input
              value={parameterSearchValue}
              RightIcon={parameterSearchValue.trim() ? Close : undefined}
              noLeftIconColor
              LeftIcon={Search}
              onClickRightIcon={() => setParameterSearchValue('')}
              onChangeText={setParameterSearchValue}
            />
          </DarkenedInputWrapper>
        )}
        {filteredParamDefs.map((param) => (
          <DetailsSection
            key={param.paramData.uuid}
            vertical
            style={{ display: param.display ? undefined : 'none' }}>
            <DetailsRow>
              <DetailsInput
                grow
                autoFocus={autoFocusUnlocked.current}
                testId={`extra-param-name-${param.paramData.uuid}`}
                value={param.paramData.name}
                onSubmitValue={(newName) =>
                  updateParameterName(param.paramData.uuid, newName)
                }
                disabled={!canEdit}
                validationRules={isValidSubmodelParameterNameRuleSet(
                  parameterDefinitions,
                  portDefinitionsInputs,
                  portDefinitionsOutputs,
                  param.paramData.uuid,
                )}
              />
              {canEdit && (
                <Button
                  variant={ButtonVariants.LargeTertiary}
                  Icon={Remove}
                  onClick={() =>
                    removeParameterDefinition(param.paramData.uuid)
                  }
                />
              )}
            </DetailsRow>
            <DetailsDarkGroup>
              <DetailsDarkGroupLabel>
                {t({
                  id: 'referenceSubmodelDetails.parameter.defaultValue.label',
                  message: 'Default value',
                })}
              </DetailsDarkGroupLabel>
              <DetailsRow>
                <DetailsInput
                  grow
                  placeholder={t({
                    id: 'referenceSubmodelDetails.parameter.defaultValue.noValue',
                    message: 'None',
                  })}
                  testId={`extra-param-default-value-${param.paramData.uuid}`}
                  value={param.paramData.default_value}
                  onSubmitValue={(defaultValue, { inputHeight }) =>
                    updateParameterDefaultValue(
                      param.paramData.uuid,
                      defaultValue,
                      inputHeight,
                    )
                  }
                  multilineHeight={
                    param.paramData.uiprops?.default_value_height
                  }
                  disabled={!canEdit}
                  hasBorder
                  validationRules={requiredRules}
                  allowMultiline
                  isMonospaced
                />
              </DetailsRow>
              <DetailsDarkGroupLabel>
                {t({
                  id: 'referenceSubmodelDetails.parameter.description.placeholder',
                  message: 'Description',
                })}
              </DetailsDarkGroupLabel>
              <DetailsRow>
                <DetailsInput
                  grow
                  placeholderTextColorDark
                  testId={`extra-param-description-${param.paramData.uuid}`}
                  value={param.paramData.description}
                  onSubmitValue={(description, { inputHeight }) =>
                    updateParameterDescription(
                      param.paramData.uuid,
                      description,
                      inputHeight,
                    )
                  }
                  multilineHeight={param.paramData.uiprops?.description_height}
                  disabled={!canEdit}
                  hasBorder
                  allowMultiline
                  placeholder="None"
                />
              </DetailsRow>
            </DetailsDarkGroup>
          </DetailsSection>
        ))}
        {canEdit && (
          <Button
            variant={ButtonVariants.SmallTertiary}
            Icon={Plus}
            onClick={addParameterDefinition}>
            {t`Add parameter`}
          </Button>
        )}
      </DetailInputRowsSection>
    </>
  );
};

export default ParameterDefinitionDetails;
