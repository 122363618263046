import { useAppSelector } from 'app/hooks';
import React from 'react';
import { ChartPosition } from 'util/visualizerUtils';

interface Props {
  rowId?: string;
  cellId: string;
  isDraggingRow: boolean;
  onDragEnd?: (
    e: React.MouseEvent<HTMLDivElement>,
    position: ChartPosition,
  ) => void;
}

export const DataExplorerImageCell: React.FC<Props> = ({
  rowId,
  cellId,
  isDraggingRow,
  onDragEnd,
}) => {
  const imageCell = useAppSelector(
    (state) => state.dataExplorer.idToImageCell[cellId],
  );

  if (!imageCell) {
    return null;
  }

  // TODO render image for image cell type.
  return (
    <div>
      <img src={imageCell.imageSourceUrl} />
    </div>
  );
};
