import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { useAdminApis } from 'app/api/useAdminApis';
import { AdminUserInfo } from 'app/apiGenerated/generatedApiTypes';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Cell, Column } from 'react-table';
import Button from 'ui/common/Button/Button';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import { BackArrow, Plus, Remove } from 'ui/common/Icons/Standard';
import Input from 'ui/common/Input/Input';
import { useModal } from 'ui/common/Modal/useModal';
import Table from 'ui/common/Table/Table';
import TabGroup from 'ui/common/tabs/TabGroup';
import { TabInfo } from 'ui/common/tabs/tabTypes';
import * as Headings from 'ui/common/typography/Typography';
import { useAppParams } from 'util/useAppParams';
import NewUserOptionOverrideModal from './NewUserOptionOverrideModal';

type Tab = 'userOptionOverrides';

const Head = styled.header(({ theme }) => ({
  display: 'flex',
  width: ' 100%',
  columnGap: 8,
}));

const Title = styled(Headings.H2)(({ theme }) => ({
  width: '100%',
}));

const UserDetailWrapper = styled.div(({ theme }) => ({
  width: `calc(100% - ${theme.spacing.xxlarge} - ${theme.spacing.xxlarge})`,
  height: `calc(100% - ${theme.spacing.xxlarge} - ${theme.spacing.xxlarge})`,
  margin: theme.spacing.xxlarge,
  padding: theme.spacing.xlarge,
  background: theme.colors.grey[5],
  borderRadius: theme.spacing.small,
  overflowX: 'auto',
  overflowY: 'auto',
}));

const UserOptionActions = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '24px',
});

const ActionSection = styled.div(({ theme }) => ({
  marginBottom: theme.spacing.large,
  display: 'flex',
  width: '100%',
  columnGap: '8px',
}));

interface UserOptionsOverridesTableData
  extends Record<string, string | boolean> {
  optionUuid: string;
  overrideUuid: string;
  override: string;
  defaultValue: string;
  name: string;
  description: string;
  createdAt: string;
  updatedAt: string;
}

const ActionCell = ({
  cell,
}: {
  cell: Cell<UserOptionsOverridesTableData>;
}) => {
  const theme = useTheme();
  const { deleteUserOptionOverride } = useAdminApis();
  const { overrideUuid } = cell.row.original;
  return (
    <UserOptionActions>
      <Button
        variant={ButtonVariants.SmallTertiary}
        title="Delete user option"
        Icon={Remove}
        tint={theme.colors.ui.error}
        onClick={() => deleteUserOptionOverride(overrideUuid)}
      />
    </UserOptionActions>
  );
};

const ValueCell = ({ cell }: { cell: Cell<UserOptionsOverridesTableData> }) => {
  const { updateUserOptionOverride } = useAdminApis();
  const { overrideUuid, override } = cell.row.original;
  return (
    <Input
      value={cell.value}
      onSubmitValue={(v) => {
        if (v !== override) {
          updateUserOptionOverride(overrideUuid, v);
        }
      }}
    />
  );
};

const UserOptionsOverrides: React.FC<{ user: AdminUserInfo }> = ({ user }) => {
  const { userOptions, userOptionsOverrides } = useAdminApis();
  const { triggerModal } = useModal();
  const navigate = useNavigate();

  if (!userOptions || !userOptionsOverrides) return <span>Loading</span>;
  const tableData: UserOptionsOverridesTableData[] =
    userOptionsOverrides.user_option_overrides
      .filter((override) => override.user_uuid === user.uuid)
      .map((override) => {
        const userOption = userOptions.user_options.find(
          (option) => option.uuid === override.user_option_uuid,
        );
        return {
          name: userOption?.name || '',
          defaultValue: userOption?.value || '',
          description: userOption?.description || '',
          createdAt: override?.created_at,
          updatedAt: override?.updated_at,
          overrideUuid: override?.uuid || '',
          optionUuid: userOption?.uuid || '',
          override: override?.value || '',
        };
      });

  const tableColumns: Column<UserOptionsOverridesTableData>[] = [
    {
      id: 'name',
      Header: 'Name',
      accessor: 'name',
    },
    {
      id: 'description',
      Header: 'Description',
      accessor: 'description',
      minWidth: 250,
    },
    {
      id: 'value',
      Header: 'Default value',
      accessor: 'defaultValue',
    },
    {
      id: 'override',
      Header: 'Override',
      accessor: 'override',
      Cell: ValueCell,
    },
    {
      id: 'action',
      Header: 'Actions',
      accessor: 'status',
      Cell: ActionCell,
    },
  ];

  const onRowDoubleClick = (row: UserOptionsOverridesTableData) => {
    navigate(`/admin/user_options/${row.optionUuid}`);
  };

  return (
    <>
      <ActionSection>
        <Button
          variant={ButtonVariants.LargeSecondary}
          Icon={BackArrow}
          onClick={() => navigate('/admin')}>
          Admin portal
        </Button>
        <Button onClick={() => navigate('/admin/user_options')}>
          Global user options
        </Button>
        <Button
          variant={ButtonVariants.LargePrimary}
          Icon={Plus}
          onClick={() =>
            triggerModal(
              <NewUserOptionOverrideModal user={user} />,
              'Create new user option override',
            )
          }>
          Add override
        </Button>
      </ActionSection>
      <Table
        data={tableData}
        columns={tableColumns}
        onRowDoubleClick={onRowDoubleClick}
      />
    </>
  );
};

const UserDetail: React.FC = ({}) => {
  const { userId } = useAppParams();
  const { users } = useAdminApis();

  const [activeTab, setActiveTab] = React.useState<Tab>('userOptionOverrides');
  if (!userId || !users)
    return <UserDetailWrapper>User not found</UserDetailWrapper>;

  const user = users.users.find((u) => u.uuid === userId);
  if (!user) return <UserDetailWrapper>User not found</UserDetailWrapper>;

  const tabs: TabInfo[] = [
    {
      id: 'userOptionOverrides',
      isSelected: () => activeTab === 'userOptionOverrides',
      displayName: 'User option overrides',
      onClick: () => setActiveTab('userOptionOverrides'),
    },
  ];

  return (
    <UserDetailWrapper>
      <Head>
        <Title>{user.display_name}</Title>
      </Head>
      <TabGroup tabs={tabs} />
      {activeTab === 'userOptionOverrides' && (
        <UserOptionsOverrides user={user} />
      )}
    </UserDetailWrapper>
  );
};

export default UserDetail;
