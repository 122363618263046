import { t } from '@lingui/macro';
import React from 'react';
import { DataIntegrationCreateRequest } from 'app/apiGenerated/generatedApiTypes';
import {
  useGetDataIntegrationsReadAllQuery,
  useDeleteDataIntegrationsByUuidMutation,
  usePostDataIntegrationsCreateMutation,
} from 'app/apiGenerated/generatedApi';
import { useNotifications } from 'ui/common/notifications/useNotifications';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { projectActions } from 'app/slices/projectSlice';
import { useAppParams } from 'util/useAppParams';

export function useDataIntegrations() {
  const dispatch = useAppDispatch();
  const { projectId } = useAppParams();

  const { createShowError } = useNotifications();

  const [callCreateDataIntegrationApi] =
    usePostDataIntegrationsCreateMutation();

  const forceRefetchDataIntegrations = useAppSelector(
    (state) => state.project.forceRefetchDataIntegrations,
  );

  const {
    data: dataIntegrations,
    isFetching: isFetchingDataIntegrations,
    refetch: refetchDataIntegrations,
  } = useGetDataIntegrationsReadAllQuery(
    {
      projectUuid: projectId || '',
    },
    {
      skip: !projectId,
    },
  );

  const [callDeleteDataIntegrationApi] =
    useDeleteDataIntegrationsByUuidMutation();

  // Make sure we refetch the data integrations after another part of the system requests it.
  React.useEffect(() => {
    if (forceRefetchDataIntegrations) {
      refetchDataIntegrations();
      dispatch(projectActions.clearRequestRefetchDataIntegrations());
    }
  }, [forceRefetchDataIntegrations, refetchDataIntegrations, dispatch]);

  const createDataIntegration = (
    projectUuid: string,
    dataIntegrationCreateRequest: DataIntegrationCreateRequest,
  ) =>
    callCreateDataIntegrationApi({
      projectUuid,
      dataIntegrationCreateRequest,
    })
      .unwrap()
      .then(() => {
        dispatch(projectActions.requestRefetchDataIntegrations());
      })
      .catch(
        createShowError(
          t({
            id: 'projectApi.createDataIntegration',
            message: 'Unable to create data integration.',
          }),
        ),
      );

  const deleteDataIntegration = (
    projectUuid: string,
    dataIntegrationUuid: string,
  ) =>
    callDeleteDataIntegrationApi({
      projectUuid,
      dataIntegrationUuid,
    })
      .unwrap()
      .then(() => {
        dispatch(projectActions.requestRefetchDataIntegrations());
      })
      .catch(
        createShowError(
          t({
            id: 'projectApi.deleteDataIntegration',
            message: 'Unable to delete data integration.',
          }),
        ),
      );

  return {
    createDataIntegration,
    dataIntegrations,
    isFetchingDataIntegrations,
    refetchDataIntegrations,
    deleteDataIntegration,
  };
}
