import { CallbackResult, GptFunction } from 'app/chat/responseStreamParser';
import { Agent } from 'app/third_party_types/chat-types';
import React from 'react';
import { useChatContext } from '../ChatContextProvider';
import { useUpdateModel } from './UpdateModel';

type CustomBlock = {
  name: string;
  input_ports: string[];
  output_ports: string[];
};

export const useCustomBlockBuilderFunctions = (): {
  [key: string]: GptFunction;
} => {
  const customBlocks = React.useRef<CustomBlock[]>([]);

  const updateModelCallback = useUpdateModel();
  const { setAgent } = useChatContext();

  const addCustomBlockCallback = React.useCallback(
    async (args: any, onComplete: (r: CallbackResult) => void) => {
      const { block_name, input_ports, output_ports, code } = args;
      const inputNames = JSON.stringify(input_ports);
      const outputNames = JSON.stringify(output_ports);
      // code is a string containing special characters like \n, so we need to escape them
      const escapedCode = JSON.stringify(code);
      const addPythonScriptCmd = `${block_name} = core.PythonScript(input_names=${inputNames}, output_names=${outputNames}, user_statements=${escapedCode})`;
      await updateModelCallback(
        { code: addPythonScriptCmd, is_new: false },
        (r: CallbackResult) => onComplete({ ...r, toolChoice: 'required' }),
      );
      customBlocks.current.push({
        name: block_name,
        input_ports,
        output_ports,
      });
    },
    [updateModelCallback],
  );

  const jobSucceededCallback = React.useCallback(
    (args: any, onComplete: (r: CallbackResult) => void) => {
      const blocksCreated = customBlocks.current.map(
        (block) => `${JSON.stringify(block)}`,
      );
      const blocksCreatedStr = blocksCreated.join('\n');
      setAgent(Agent.ModelBuilder);
      onComplete({
        result: `Blocks created:\n${blocksCreatedStr}`,
      });
      customBlocks.current = [];
    },
    [setAgent],
  );

  const jobFailedCallback = React.useCallback(
    (args: any, onComplete: (r: CallbackResult) => void) => {
      setAgent(Agent.ModelBuilder);
      onComplete({
        error: `Failed to create custom block.`,
      });
      customBlocks.current = [];
    },
    [setAgent],
  );

  return {
    add_custom_block: addCustomBlockCallback,
    job_succeeded: jobSucceededCallback,
    job_failed: jobFailedCallback,
  };
};
