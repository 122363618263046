import { useProjects } from 'app/api/useProjects';
import React from 'react';

export function useProjectPermission(projectId: string | undefined) {
  // TODO: switch to useProject({withPermissions: true})
  const { projects, isLoadingProjects } = useProjects();

  const hasProjectEditPermission = React.useMemo(() => {
    if (!projectId) return false;

    const currentProject = projects?.find(
      (project) => project.uuid === projectId,
    );
    if (!currentProject) return false;

    return currentProject.role === 'admin' || currentProject.role === 'editor';
  }, [projectId, projects]);

  const hasProjectAdminPermission = React.useMemo(() => {
    if (!projectId) return false;

    const currentProject = projects?.find(
      (project) => project.uuid === projectId,
    );
    if (!currentProject) return false;

    return currentProject.role === 'admin';
  }, [projectId, projects]);

  return {
    arePermissionsLoaded: !isLoadingProjects,

    /**
     * Does the current user have permission to add models, other files, or delete the project?
     */
    canEditProject: hasProjectEditPermission,

    /**
     * Does the current user have permission to edit project permissions or delete the project?
     */
    canAdministrateProject: hasProjectAdminPermission,
  };
}
