import { useTheme } from '@emotion/react';
import { t } from '@lingui/macro';
import { useProjectPermissions } from 'app/api/useProjectPermissions';
import { ProjectPermission } from 'app/apiTransformers/convertGetProjectPermissionReadAll';
import { useAppSelector } from 'app/hooks';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from 'ui/common/Button/Button';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import {
  ActionButtonContainer,
  ModalMessageContainer,
  ModalMessageText,
  SmallFormContainer,
} from 'ui/common/Modal/Modal';
import { useModal } from 'ui/common/Modal/useModal';

interface Props {
  projectId: string;
}

const LeaveProjectModal: React.FC<Props> = ({ projectId }) => {
  const { closeModal } = useModal();
  const theme = useTheme();
  const navigate = useNavigate();

  const { projectPermissions, deleteProjectPermission } =
    useProjectPermissions(projectId);
  const userId = useAppSelector((state) => state.user.userId);
  const permission: ProjectPermission | undefined = projectPermissions?.find(
    (p) => p.memberUuid === userId,
  );

  const onLeave = async () => {
    if (permission) {
      await deleteProjectPermission({
        projectUuid: projectId,
        permissionUuid: permission.id,
      });
    }
    navigate(`/projects`);
    closeModal();
  };

  return (
    <SmallFormContainer
      onSubmit={(e) => {
        e?.preventDefault();
        onLeave();
      }}>
      <ModalMessageContainer data-test-id="leave-project-modal-message">
        <ModalMessageText>
          {t({
            id: 'leaveProjectModal.messageContent.text',
            message: 'Are you sure you want to leave this project?',
          })}
        </ModalMessageText>
      </ModalMessageContainer>

      <ActionButtonContainer>
        {/* Cancel button */}
        <Button
          type="button"
          onClick={closeModal}
          variant={ButtonVariants.LargeSecondary}
          testId="leave-project-modal-cancel-button">
          {t({
            id: 'leaveProjectModal.cancelButton.label',
            message: 'Cancel',
          })}
        </Button>

        {/* Leave button */}
        <Button
          type="submit"
          variant={ButtonVariants.LargePrimary}
          tint={theme.colors.ui.error}
          testId="leave-project-modal-leave-button">
          {t({
            id: 'leaveProjectModal.leaveButton.label',
            message: 'Leave',
          })}
        </Button>
      </ActionButtonContainer>
    </SmallFormContainer>
  );
};

export default LeaveProjectModal;
