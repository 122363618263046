import { t } from '@lingui/macro';
import { useDataIntegrations } from 'app/api/useDataIntegrations';
import React from 'react';
import SelectInput from 'ui/common/SelectInput';
import { useAppParams } from 'util/useAppParams';

type Props = {
  currentValue: string;
  onSelectValue: (newValue: string) => void;
  allowEmpty?: boolean;
};

const DataIntegrationIdParameter: React.FC<Props> = ({
  currentValue,
  onSelectValue,
  allowEmpty = false,
}) => {
  const { dataIntegrations } = useDataIntegrations();
  const { projectId } = useAppParams();

  const [isSelectedOptionValidOrEmpty, setIsSelectedOptionValidOrEmpty] =
    React.useState<boolean>(true);

  const options = React.useMemo(() => {
    if (!projectId) return [];

    const projectDataIntegrations = dataIntegrations?.data_integrations || [];

    const options = projectDataIntegrations.map((integration) => ({
      value: integration.uuid,
      label: integration.name,
    }));

    if (currentValue) {
      const currentValueIsValid = options.some(
        (option) => option.value === currentValue,
      );
      if (currentValueIsValid) {
        setIsSelectedOptionValidOrEmpty(true);
      } else {
        options.push({
          value: currentValue,
          label: t({
            id: 'modelRenderer.parameters.integrationNotFound.label',
            message: '{integration} (Not found)',
            values: {
              integration: currentValue,
            },
          }),
        });
        setIsSelectedOptionValidOrEmpty(false);
      }
    } else {
      // The current value is empty so we should not show an error.
      setIsSelectedOptionValidOrEmpty(true);
    }

    if (allowEmpty) {
      return [
        {
          value: '',
          label: t({
            id: 'modelRenderer.parameters.noIntegration.label',
            message: '(None)',
          }),
        },
        ...options,
      ];
    }

    return options;
  }, [allowEmpty, dataIntegrations, projectId, currentValue]);

  return (
    <SelectInput
      currentValue={currentValue}
      options={options}
      onSelectValue={onSelectValue}
      isInvalid={!isSelectedOptionValidOrEmpty}
    />
  );
};

export default DataIntegrationIdParameter;
