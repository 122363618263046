import React from 'react';
import BreadcrumbItem from './BreadcrumbItem';

interface Props {
  Icon?: React.FC<any>;
  onClick?: () => void;
  displayName: string;
}

const BreadcrumbLink: React.FC<Props> = ({ displayName, Icon, onClick }) => {
  const triggerClick = (e: React.MouseEvent) => {
    onClick?.();
    e.stopPropagation();
  };
  return (
    <BreadcrumbItem
      onClick={onClick && triggerClick}
      Icon={Icon && <Icon />}
      displayName={displayName}
    />
  );
};

export default BreadcrumbLink;
