import { t } from '@lingui/macro';
import {
  useGetTemplateProjectsReadAllQuery,
  usePostTemplateProjectsCopyByUuidMutation,
} from 'app/apiGenerated/generatedApi';
import { PostTemplateProjectsCopyByUuidApiArg } from 'app/apiGenerated/generatedApiTypes';
import { useNotifications } from 'ui/common/notifications/useNotifications';

export function useTemplateProjects() {
  const { createShowError } = useNotifications();

  const { data, isLoading } = useGetTemplateProjectsReadAllQuery();

  const [callCopyFromTemplateProject] =
    usePostTemplateProjectsCopyByUuidMutation();

  const copyFromTemplateProject = (
    request: PostTemplateProjectsCopyByUuidApiArg,
  ) =>
    callCopyFromTemplateProject(request)
      .unwrap()
      .catch(
        createShowError(
          t({
            id: 'templateProjectsApi.copyFromTemplateProject',
            message: 'Unable to create a project from the template.',
          }),
        ),
      );

  return {
    isLoadingTemplateProjects: isLoading,
    templateProjects: data,
    copyFromTemplateProject,
  };
}
