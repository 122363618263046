/* tslint:disable */

export enum FinishReason {
  Stop = 'stop',
  Length = 'length',
  ToolCalls = 'tool_calls',
  ContentFilter = 'content_filter',
  EOF = 'EOF',
  Error = 'error',
  Timeout = 'timeout',
}

export enum ChatMessageRole {
  System = 'system',
  User = 'user',
  Assistant = 'assistant',
  Tool = 'tool',
  Function = 'function', // deprecated, use Tool.
}

export enum Agent {
  Main = 'main',
  ModelBuilder = 'model_builder',
  ModelBuilderWithAcausal = 'model_builder_with_acausal',
  CustomBlockBuilder = 'custom_block_builder',
}

export interface ToolCall {
  name: string;
  arguments: string;
  type: string;
  tool_id?: string;
  index?: number;
}

export type GptFunctionName = string;

export interface ChatMessageContent {
  type: 'text' | 'image_url';
  text?: string;
  image_url?: { url: string };

  // used in the UI
  error?: string;
}

export interface ChatMessage {
  role: ChatMessageRole;
  content: ChatMessageContent[];

  b64Images?: string[];
  automaticMessageContent?: string;

  toolCalls?: ToolCall[];
  toolCallId?: string;

  toolChoice?: string;

  // Only for Assistant role.
  agentId?: Agent;

  // Whether the message should be hidden from the user in the UI
  hidden?: boolean;
}

export interface ChatCompleteRequestPayload {
  messages: ChatMessage[];
  temperature: number;
  seed?: number;
  genAiModel: string;
  systemPrompt?: string;
  tools?: string;
  agentId?: Agent;
  diagram?: string;
}

export interface ChatCompleteStartStreamPayload {
  streamUuid: string;
}

export interface ChatCompleteResponsePayload {
  streamUuid: string;
  content?: string;
  finishReason?: FinishReason;
  functionCall?: ToolCall;
}

export enum ChatCompleteErrorTypes {
  ChatSessionNotFound = 'CHAT_SESSION_NOT_FOUND',
  ChatNotAvailable = 'CHAT_NOT_AVAILABLE',
  ChatCallCountExceeded = 'CHAT_CALL_COUNT_EXCEEDED',
  ChatInternalError = 'CHAT_INTERNAL_ERROR',
  ChatImageInvalid = 'CHAT_IMAGE_INVALID',
  JsonError = 'JSON_ERROR',
  OpenAiKeyNotFound = 'OPENAI_KEY_NOT_FOUND',
}

export interface ChatCompleteErrorPayload {
  streamUuid: string;
  error: string;
  type: ChatCompleteErrorTypes;
}

export interface Plot {
  id: string;
  value: string; // b64 encoded png
}

// snake_case because it's used in the API
export interface ChatSession {
  session_id: string;
  messages: ChatMessage[];
  plots?: Plot[];
  is_finished?: boolean;
}
// DO NOT EDIT THIS FILE HERE, SEE src/lib/model-schemas/schemas/v2
