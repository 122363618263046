/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  stroke?: any;
  strokeWidth?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Videosource: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20 16H24.75C24.8881 16 25 15.8881 25 15.75V13.6036C25 13.3808 25.2693 13.2693 25.4268 13.4268L29.3125 17.3125C29.4142 17.4142 29.4093 17.5806 29.3018 17.6761L25.4161 21.1301C25.2549 21.2735 25 21.159 25 20.9433V19.25C25 19.1119 24.8881 19 24.75 19H20"
      stroke={props.stroke}
      strokeWidth={props.strokeWidth}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M5 8C4.44772 8 4 8.44771 4 9V23C4 23.5523 4.44772 24 5 24H27C27.5523 24 28 23.5523 28 23V20.8403L26.4126 22.2513C25.2841 23.2544 23.5 22.4533 23.5 20.9433V20.5H22.5V22C22.5 22.2761 22.2761 22.5 22 22.5H10C9.72386 22.5 9.5 22.2761 9.5 22V10C9.5 9.72386 9.72386 9.5 10 9.5H22C22.2761 9.5 22.5 9.72386 22.5 10V14.5H23.5V13.6036C23.5 12.0445 25.385 11.2637 26.4874 12.3661L28 13.8787V9C28 8.44771 27.5523 8 27 8H5ZM8 22H5.5V20.5H8V22ZM8 18.5H5.5V17H8V18.5ZM8 15H5.5V13.5H8V15ZM8 11.5H5.5V10H8V11.5ZM24 10H26.5V11.5H24V10Z"
      fill={props.fill}
    />
  </svg>
);

Videosource.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  stroke: '#1D3131',
  strokeWidth: '1.5',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Videosource;
