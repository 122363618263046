/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const CppFunction: React.FC<Props> = (props) => (
  <svg viewBox="0 0 16 16" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M13.3601 4.99999C13.2737 4.85502 13.1547 4.72842 13.0237 4.6551L8.47631 2.10998C8.21436 1.96334 7.78564 1.96334 7.52369 2.10998L2.97631 4.6551C2.71432 4.8017 2.5 5.16162 2.5 5.45487V10.5451C2.5 10.6918 2.5536 10.8551 2.63994 11C2.72626 11.1449 2.84534 11.2716 2.97629 11.3449L7.52368 13.89C7.78563 14.0367 8.21434 14.0367 8.47629 13.89L13.0237 11.3449C13.1547 11.2716 13.2737 11.145 13.36 11C13.4464 10.8551 13.5 10.6918 13.5 10.5451V5.45487C13.5 5.30823 13.4464 5.14493 13.3601 4.99999ZM11.1753 6.22163C10.5213 5.12607 9.30462 4.44548 8 4.44548C5.97819 4.44548 4.33333 6.04004 4.33333 8.00002C4.33333 8.64685 4.51248 9.25388 4.8252 9.77693C5.46006 10.8388 6.64543 11.5546 8 11.5546C9.30469 11.5546 10.5214 10.8739 11.1754 9.77826L9.58811 8.88888C9.26067 9.43692 8.65222 9.77728 8 9.77728C7.32271 9.77728 6.73003 9.4194 6.4126 8.88848C6.25624 8.62695 6.16667 8.32343 6.16667 8.00002C6.16667 7.02001 6.98908 6.22275 8 6.22275C8.6522 6.22275 9.26069 6.56317 9.58808 7.11117L11.1753 6.22163ZM11.6667 7.80253H11.2592V7.4076H10.8519V7.80253H10.4444V8.19747H10.8519V8.59244H11.2592V8.19747H11.6667V7.80253ZM12.787 7.80253H13.1944V8.19747H12.787V8.59244H12.3797V8.19747H11.9722V7.80253H12.3797V7.4076H12.787V7.80253Z"
      fill={props.fill}
    />
  </svg>
);

CppFunction.defaultProps = {
  width: '16',
  height: '16',
  viewBox: '0 0 16 16',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default CppFunction;
