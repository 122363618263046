import styled from '@emotion/styled';
import { PortSide } from 'app/common_types/PortTypes';
import {
  BlockParameterDefinition,
  BlockParameterDefinitions,
} from 'app/generated_types/ComputationBlockClass';
import {
  NodeInstance,
  Parameter,
  Parameters,
  Port,
} from 'app/generated_types/SimulationModel';
import { useAppDispatch } from 'app/hooks';
import { modelDataTypeValues } from 'app/modelDataTypeValues';
import { modelActions } from 'app/slices/modelSlice';
import React from 'react';
import { TextInputAlign } from 'ui/common/Input/inputTypes';
import { requiredRules } from 'ui/common/Input/inputValidation';
import SelectInput from 'ui/common/SelectInput';
import {
  DetailsInput,
  DetailsLabel,
  DetailsSection,
} from './DetailsComponents';
import { getParameterDisplayName } from './blockParameterDisplay';

const PortDetailsLabel = styled(DetailsLabel)`
  margin-left: ${({ theme }) => theme.spacing.normal};
`;

type Props = {
  parentPath: string[];
  selectedNode: NodeInstance;
  canEdit: boolean;
  port: Port;
  index: number;
  portSide: PortSide;
  paramDefinitions: BlockParameterDefinitions | undefined;
  onParameterChanged?: (
    paramDef: BlockParameterDefinition,
    oldValue: string,
    newValue: string,
  ) => void;
};

const BlockPortParameters: React.FC<Props> = ({
  parentPath,
  selectedNode,
  canEdit,
  port,
  index,
  portSide,
  paramDefinitions,
  onParameterChanged,
}) => {
  const dispatch = useAppDispatch();

  const setPortParameter = (
    portId: number,
    paramDef: BlockParameterDefinition,
    value: string,
  ) => {
    const oldValue = (port?.parameters?.[paramDef.name] as Parameter)?.value;
    const parameters: Parameters = {};
    const parameter: Parameter = { value };
    if (
      paramDef.data_type === 'string' ||
      paramDef.data_type === 'code' ||
      paramDef.data_type === 'file'
    ) {
      parameter.is_string = true;
    }
    parameters[paramDef.name] = parameter;
    dispatch(
      modelActions.setPortParameters({
        parentPath,
        nodeUuid: selectedNode.uuid,
        portSide,
        portId,
        parameters,
      }),
    );

    if (onParameterChanged) onParameterChanged(paramDef, oldValue, value);
  };

  if (!paramDefinitions) {
    return null;
  }

  return (
    <>
      {paramDefinitions.map((paramDef) => {
        const param = port?.parameters?.[paramDef.name];
        const paramName = paramDef.name;
        const paramDisplayName = getParameterDisplayName(
          selectedNode.type,
          paramDef,
        );

        const paramValue = param?.value ?? paramDef.default_value;
        const paramDescription = paramDef.description;
        const allowedValues = paramDef.value_is_a_type
          ? modelDataTypeValues
          : paramDef.allowed_values;

        return (
          <DetailsSection
            key={paramName || `port_param_${index}}`}
            vertical={!!paramDef.display_full_width}>
            <PortDetailsLabel title={paramDescription}>
              {paramDisplayName}
            </PortDetailsLabel>
            {allowedValues ? (
              <SelectInput
                onSelectValue={(s) => setPortParameter(index, paramDef, s)}
                currentValue={paramValue}
                options={allowedValues.map((allowedValue) => ({
                  value: allowedValue,
                  label: allowedValue,
                }))}
                isDisabled={!canEdit}
              />
            ) : (
              <DetailsInput
                onSubmitValue={(s) => setPortParameter(index, paramDef, s)}
                value={paramValue}
                validationRules={requiredRules}
                align={
                  paramDef.data_type === 'code'
                    ? TextInputAlign.Left
                    : TextInputAlign.Right
                }
                disabled={!canEdit}
                grow={!!paramDef.display_full_width}
                allowMultiline={paramDef.data_type === 'code'}
                hasBorder={paramDef.data_type === 'code'}
              />
            )}
          </DetailsSection>
        );
      })}
    </>
  );
};

export default BlockPortParameters;
