/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Image: React.FC<Props> = (props) => (
  <svg viewBox="0 0 24 24" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M16 13C16 15.2091 14.2091 17 12 17C9.79086 17 8 15.2091 8 13C8 10.7909 9.79086 9 12 9C14.2091 9 16 10.7909 16 13ZM14.5 13C14.5 14.3807 13.3807 15.5 12 15.5C10.6193 15.5 9.5 14.3807 9.5 13C9.5 11.6193 10.6193 10.5 12 10.5C13.3807 10.5 14.5 11.6193 14.5 13Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M2 10C2 8.89543 2.89543 8 4 8H4.92963C5.59834 8 6.2228 7.6658 6.59373 7.1094L7.40627 5.8906C7.7772 5.3342 8.40166 5 9.07037 5H14.9296C15.5983 5 16.2228 5.3342 16.5937 5.8906L17.4063 7.1094C17.7772 7.6658 18.4017 8 19.0704 8H20C21.1046 8 22 8.89543 22 10V18C22 19.1046 21.1046 20 20 20H4C2.89543 20 2 19.1046 2 18V10ZM20.5 10V18C20.5 18.2761 20.2761 18.5 20 18.5H4C3.72386 18.5 3.5 18.2761 3.5 18V10C3.5 9.72386 3.72386 9.5 4 9.5H4.92963C6.09987 9.5 7.19268 8.91515 7.84181 7.94145L8.65434 6.72265C8.74708 6.58355 8.90319 6.5 9.07037 6.5H14.9296C15.0968 6.5 15.2529 6.58355 15.3457 6.72265L16.1582 7.94145C16.8073 8.91515 17.9001 9.5 19.0704 9.5H20C20.2761 9.5 20.5 9.72386 20.5 10Z"
      fill={props.fill}
    />
  </svg>
);

Image.defaultProps = {
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Image;
