import { Theme, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { Trans, t } from '@lingui/macro';
import { useGetUserStatisticsQuery } from 'app/apiGenerated/generatedApi';
import { useAppDispatch } from 'app/hooks';
import { DiagramFooterTab, uiFlagsActions } from 'app/slices/uiFlagsSlice';
import chatPreview from 'assets/chatPreview.png';
import { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Button from 'ui/common/Button/Button';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import { ArrowRight, Remove } from 'ui/common/Icons/Standard';
import PortalTooltip from 'ui/common/Tooltip/PortalTooltip';
import { TooltipPlacement } from 'ui/common/Tooltip/tooltipTypes';
import { H3, SmallEmphasis, Standard } from 'ui/common/typography/Typography';

const ChatOnboardingDiv = styled.div`
  line-height: ${({ theme }) => theme.typography.font.standard.lineHeight};
  padding: ${(props) => props.theme.spacing.large};
  border-radius: 2px;
  font-weight: 600;
  min-height: 32px;
  background-color: ${({ theme }) => theme.colors.grey[2]};
  max-width: 305px;
`;

const ChatOnboardingHeader = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: ${({ theme }) => theme.spacing.large};
`;

interface ChatOnboardingSectionProps {
  justify?: string;
  paddingBottom?: string;
  theme?: Theme | undefined;
}

const ChatOnboardingSection = styled.div`
  display: flex;
  justify-content: ${(props: ChatOnboardingSectionProps) =>
    props.justify ? props.justify : 'flex-start'};
  padding-bottom: ${(props: ChatOnboardingSectionProps) =>
    props.paddingBottom ? props.paddingBottom : props.theme?.spacing.large};
  color: ${({ theme }) => theme.colors.text.secondary};
`;

const CloseButton = styled(Button)`
  position: absolute;
  top: ${({ theme }) => theme.spacing.large};
  right: ${({ theme }) => theme.spacing.normal};
`;

const SHOW_CHAT_ONBOARDING_LS_KEY = 'show-chat-onboarding';

export const ChatOnboarding = ({
  anchor,
}: {
  anchor: HTMLDivElement | null;
}) => {
  const dispatch = useAppDispatch();
  const [showOnboarding, setShowOnboarding] = useState(true);
  const theme = useTheme();

  const { data: userStatistics } = useGetUserStatisticsQuery();

  const tooltipContainer: HTMLElement | null =
    document.getElementById('tooltips');

  const showOnboardingLs = JSON.parse(
    localStorage.getItem(SHOW_CHAT_ONBOARDING_LS_KEY) || 'true',
  );

  const hideOnboarding = useCallback(() => {
    setShowOnboarding(false);
  }, []);

  const goToChatConsole = useCallback(() => {
    hideOnboarding();
    dispatch(uiFlagsActions.setDiagramFooterTab(DiagramFooterTab.AiAssistant));
  }, [dispatch, hideOnboarding]);

  useEffect(() => {
    if (
      !showOnboardingLs ||
      !showOnboarding ||
      (userStatistics?.chat_call_count && userStatistics?.chat_call_count > 0)
    ) {
      localStorage.setItem(SHOW_CHAT_ONBOARDING_LS_KEY, false.toString());
      setShowOnboarding(false);
    }
  }, [showOnboarding, showOnboardingLs, userStatistics?.chat_call_count]);

  if (!tooltipContainer) {
    return null;
  }

  return anchor && showOnboarding ? (
    <PortalTooltip
      triggerEl={anchor}
      placement={TooltipPlacement.TOP_CENTER}
      invertedColor={false}>
      <ChatOnboardingDiv>
        <ChatOnboardingHeader>
          <H3>Chat is here!</H3>
          <CloseButton
            Icon={Remove}
            variant={ButtonVariants.SmallTertiary}
            tint={theme.colors.grey[10]}
            onClick={hideOnboarding}
          />
        </ChatOnboardingHeader>
        <ChatOnboardingSection>
          <img src={chatPreview} alt="Chat preview" />
        </ChatOnboardingSection>
        <ChatOnboardingSection>
          <Standard>
            Generate submodels, ask questions, and get advice, all in one place.
            Powered by ChatGPT.
          </Standard>
        </ChatOnboardingSection>
        <ChatOnboardingSection>
          <SmallEmphasis>
            Collimator Chat is a beta product (
            <Link to="/tos">
              <Trans id="tosModal.tosLink">Terms of Service</Trans>
            </Link>
            )
          </SmallEmphasis>
        </ChatOnboardingSection>
        <ChatOnboardingSection justify="flex-end" paddingBottom="0">
          <Button
            type="button"
            variant={ButtonVariants.LargePrimary}
            Icon={ArrowRight}
            onClick={goToChatConsole}>
            {t({
              id: 'chatOnboarding.tryItNow',
              message: 'Try it now',
            })}
          </Button>
        </ChatOnboardingSection>
      </ChatOnboardingDiv>
    </PortalTooltip>
  ) : null;
};
