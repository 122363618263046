import { createSlice, PayloadAction, AnyAction } from '@reduxjs/toolkit';

import type { Coordinate } from 'app/common_types/Coordinate';
import { RootState } from 'app/store';

const TIME_DELETION_THRESHOLD = 15 * 24 * 60 * 60 * 1000; // 15 days

export const USER_PREFERENCE_VERSION = 1;

interface Preference {
  lastUpdated: number;
}

interface CameraPreferences {
  coord: Coordinate;
  zoom: number;
}
export interface ModelEditorPreference extends Preference {
  preferenceId: string;
  camera: CameraPreferences;
}

export interface UserPreferencesState {
  modelEditor: ModelEditorPreference[];
  _currentModelId: string;
  _currentSubmodelPath: string[];
  shouldLoadModelEditorPrefs: boolean;
  loadVisualizer: boolean;
  version: number;
}

export const initialState: UserPreferencesState = {
  modelEditor: [],
  _currentModelId: '',
  _currentSubmodelPath: [],
  shouldLoadModelEditorPrefs: false,
  loadVisualizer: false,
  version: USER_PREFERENCE_VERSION,
};

const isCameraChanged = (action: AnyAction) =>
  action.type === 'cameraSlice/setEntireTransform';

const getPreferenceId = (
  currentSubmodelPath: string[],
  currentModelId: string,
) =>
  currentSubmodelPath?.length
    ? currentSubmodelPath[currentSubmodelPath.length - 1]
    : currentModelId;

function cleanOldUserPreferences(state: typeof initialState) {
  const now = Date.now();
  const cleanPreference = (item: Preference) =>
    !item.lastUpdated || now - item.lastUpdated < TIME_DELETION_THRESHOLD;

  state.modelEditor = state.modelEditor.filter(cleanPreference);
}

const userPreferencesSlice = createSlice({
  name: 'userPreferencesSlice',
  initialState,
  reducers: {
    initializeUserPreferences(
      state,
      action: PayloadAction<UserPreferencesState | undefined>,
    ) {
      state.modelEditor =
        action?.payload?.modelEditor || initialState.modelEditor;
      state.version = action?.payload?.version || initialState.version;
      cleanOldUserPreferences(state);
    },
    unsetLoadModelEditor(state) {
      state.shouldLoadModelEditorPrefs = false;
    },
    setLoadModelEditor(state) {
      state.shouldLoadModelEditorPrefs = true;
    },
    unsetLoadVisualizer(state) {
      state.loadVisualizer = false;
    },
    setLoadVisualizer(state) {
      state.loadVisualizer = true;
    },
    setSubmodelPath(state, action: PayloadAction<string[]>) {
      state._currentSubmodelPath = action.payload;
      state.shouldLoadModelEditorPrefs = true;
    },
    setModelId(state, action: PayloadAction<string>) {
      state._currentModelId = action.payload;
      state.shouldLoadModelEditorPrefs = true;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(isCameraChanged, (state, action) => {
      const { coord, zoom } = action.payload;
      const preferenceId = getPreferenceId(
        state._currentSubmodelPath,
        state._currentModelId,
      );
      const newState = {
        camera: { coord, zoom },
        preferenceId,
        lastUpdated: Date.now(),
      };
      const prefIndex = state.modelEditor.findIndex(
        (v) => v.preferenceId === preferenceId,
      );
      if (prefIndex === -1) {
        state.modelEditor.push(newState);
      } else {
        state.modelEditor[prefIndex] = newState;
      }
    });
  },
});

export const userPreferencesActions = userPreferencesSlice.actions;
export const userPreferencesSelectors = {
  selectModelEditorPreferences: (state: RootState) => {
    const preferenceId = getPreferenceId(
      state.userPreferences._currentSubmodelPath,
      state.userPreferences._currentModelId,
    );
    return state.userPreferences.modelEditor.find(
      (m) => m.preferenceId === preferenceId,
    );
  },
  selectUserPreferences: (state: RootState) => state.userPreferences,
  selectVersion: (state: RootState) => state.userPreferences.version,
  selectPreferenceId: (state: RootState) =>
    getPreferenceId(
      state.userPreferences._currentSubmodelPath,
      state.userPreferences._currentModelId,
    ),
};
export default userPreferencesSlice;
