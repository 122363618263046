// !!!! -- DO NOT MANUALLY EDIT -- !!!!
// This file was generated by something in scripts/generateDefaultBlockData.js
// If you want to change the contents of this file, it should be automated
//
// (You can edit this file locally, but it may not be checked into the codebase -
// PRs with a manual edit of this file are forbidden from being merged.)

import { computationBlockDefaults } from 'app/generated_blocks/computationBlockDefaults';
import type { ComputationBlockClass } from 'app/generated_types/ComputationBlockClass';
import deepmerge from 'deepmerge';

export const Submodel: ComputationBlockClass = deepmerge(
  computationBlockDefaults,
  {
    "base": {
      "name": "Submodel",
      "namespace": "core",
      "description": "This is a stub to allow the submodel to render",
      "extra_parameters": true,
      "help_url": "https://docs.collimator.ai/using-model-editors/using-submodels",
      "hidden": true
    },
    "modes": {
      "time": "any"
    },
    "ports": {
      "has_automatic_ports": true
    }
  }
);
