/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const CreateIterator: React.FC<Props> = (props) => (
  <svg viewBox="0 0 24 24" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21.0303 11.0303C21.3232 10.7374 21.3232 10.2626 21.0303 9.96967C20.7374 9.67678 20.2626 9.67678 19.9697 9.96967L18.75 11.1893V6C18.75 5.0335 17.9665 4.25 17 4.25H6C5.0335 4.25 4.25 5.0335 4.25 6V17C4.25 17.9665 5.0335 18.75 6 18.75H9.24999C9.6642 18.75 9.99999 18.4142 9.99999 18C9.99999 17.5858 9.6642 17.25 9.24999 17.25H6C5.86193 17.25 5.75 17.1381 5.75 17L5.75 6C5.75 5.86193 5.86193 5.75 6 5.75H17C17.1381 5.75 17.25 5.86193 17.25 6V11.1893L16.0303 9.96967C15.7374 9.67678 15.2626 9.67678 14.9697 9.96967C14.6768 10.2626 14.6768 10.7374 14.9697 11.0303L18 14.0607L21.0303 11.0303Z"
      fill={props.fill}
    />
    <path
      d="M20 18.75C20.4142 18.75 20.75 18.4142 20.75 18C20.75 17.5858 20.4142 17.25 20 17.25H18.75V16C18.75 15.5858 18.4142 15.25 18 15.25C17.5858 15.25 17.25 15.5858 17.25 16V17.25H16C15.5858 17.25 15.25 17.5858 15.25 18C15.25 18.4142 15.5858 18.75 16 18.75H17.2616L17.2813 20.0117C17.2878 20.4259 17.6288 20.7564 18.043 20.7499C18.4571 20.7434 18.7876 20.4024 18.7812 19.9883L18.7618 18.75H20Z"
      fill={props.fill}
    />
    <path
      d="M13.25 18.75C13.6642 18.75 14 18.4142 14 18C14 17.5858 13.6642 17.25 13.25 17.25H11.75C11.3358 17.25 11 17.5858 11 18C11 18.4142 11.3358 18.75 11.75 18.75H13.25Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M8.49999 7.5C7.94771 7.5 7.49999 7.94772 7.49999 8.5V10.5C7.49999 11.0523 7.94771 11.5 8.49999 11.5H9.44444V13.4991C9.44444 14.3276 10.116 14.9991 10.9444 14.9991H11.7073C11.8801 15.2985 12.2036 15.5 12.5741 15.5H14.4853C15.0375 15.5 15.4853 15.0523 15.4853 14.5V13.9736C15.4853 13.4213 15.0375 12.9736 14.4853 12.9736H12.5741C12.0218 12.9736 11.5741 13.4213 11.5741 13.9736V13.9991H10.9444C10.6683 13.9991 10.4444 13.7753 10.4444 13.4991V11.5H11.5C12.0523 11.5 12.5 11.0523 12.5 10.5V8.5C12.5 7.94772 12.0523 7.5 11.5 7.5H8.49999ZM11.5 8.5H8.49999L8.49999 10.5H11.5V8.5ZM12.5741 13.9736H14.4853L14.4853 14.5H12.5741V13.9736Z"
      fill={props.fill}
    />
  </svg>
);

CreateIterator.defaultProps = {
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default CreateIterator;
