import { useProjects } from 'app/api/useProjects';
import { VersionTagValues } from 'app/apiTransformers/convertPostSubmodelsFetch';
import { useAppSelector } from 'app/hooks';
import { getSpecificReferenceSubmodelById } from 'app/utils/submodelUtils';

export function useLatestModel(
  projectId: string,
  modelId: string,
  versionId?: string,
) {
  const { projects } = useProjects();
  const project = projects?.find((p) => p.uuid === projectId);
  const latestModel = project?.models.find((m) => m.uuid === modelId);

  const latestSubmodel = useAppSelector((state) =>
    getSpecificReferenceSubmodelById(
      modelId,
      VersionTagValues.LATEST_VERSION,
      state.submodels.idToVersionIdToSubmodelInfoLite,
    ),
  );

  const editId = useAppSelector((state) => state.modelMetadata.editId);

  const latestEditId = versionId
    ? latestSubmodel?.editId || latestModel?.version
    : editId;

  return {
    latestEditId,
  };
}
