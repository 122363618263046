import { useTheme } from '@emotion/react';
import styled from '@emotion/styled/macro';
import { blockClassLookup } from 'app/generated_blocks';
import {
  getCodeIcon,
  nodeClassWithoutNamespace_displayOnly,
  nodeTypeIsCode,
  nodeTypeIsLocalSubdiagram,
  nodeTypeIsReferencedSubmodel,
  nodeTypeIsSubdiagram,
} from 'app/helpers';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { modelActions } from 'app/slices/modelSlice';
import React, { useEffect } from 'react';
import {
  TreeArrowCollapsed,
  TreeArrowExpanded,
} from 'ui/common/Icons/Standard';
import { getBlockIconID } from 'util/getBlockIconID';
/* eslint import/namespace: [2, { allowComputed: true }] */
import { BlockClassName, Parameters } from '@collimator/model-schemas-ts';
import { VersionTagValues } from 'app/apiTransformers/convertPostSubmodelsFetch';
import { wildcatClassTable } from 'app/generated_wildcat_cache/wildcatClassStrings';
import { navigationActions } from 'app/slices/navigationSlice';
import {
  setNavigationURLParams,
  setSelectionURLParams,
} from 'app/utils/URLParamsUtils';
import { isSamePath } from 'app/utils/parentPathUtils';
import { useSearchParams } from 'react-router-dom';
import {
  CODE_EDITOR_BLOCK_QUERY_PARAM,
  MODEL_SOURCE_CODE_VIEWING_QUERY_PARAM,
} from 'ui/codeEditor/CodeEditor';
import * as SmallIcons from 'ui/common/Icons/Small';
import {
  ItemSection,
  ModelBlockItemText,
  ModelTreeIcon,
} from 'ui/objectBrowser/ModelTreeParts';
import { useIsBlockNavState } from 'ui/requirements/blockNav';
import EmptyBlockIcon from './EmptyBlockIcon';
import LocalSubmodelTreeContent from './LocalSubmodelTreeContent';
import ReferenceSubmodelTreeContent from './ReferenceSubmodelTreeContent';

const BlockTargeterWrapper = styled.div`
  width: 24px;
  height: 24px;
  margin-left: ${({ theme }) => theme.spacing.normal};
  margin-right: ${({ theme }) => theme.spacing.normal};
  flex-shrink: 0;
`;

const BlockTargeter = styled.div`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  height: 24px;
  color: white;
  opacity: 0.3;

  &:hover {
    opacity: 6;
  }

  img {
    height: 12px;
  }
`;

const BLOCKS_WITH_ICONS = [
  'Submodel',
  'CCode',
  'PythonCode',
  'Code',
  'Inport',
  'Outport',
];

// This is a subset of the properties of NodeInstance that are used in this file.
// Using this avoids expensive re-renders when a block is being moved.
export interface NodeInstanceLite {
  uuid: string;
  type: BlockClassName;
  name: string;
  parameters: Parameters;
  submodel_reference_uuid?: string;
}

const getNodeName = (node: NodeInstanceLite) => {
  const baseClassName = node ? blockClassLookup(node.type)?.base?.name : null;

  return node?.name || baseClassName || '';
};

const getIconName = (blockIconId: string) =>
  blockIconId[0].toUpperCase() +
  blockIconId.slice(1).replace(/\.(\S)/, (v) => v[1].toUpperCase());

const getNodeIconComponent = (node: NodeInstanceLite, fill: string) => {
  const printableClassName = nodeClassWithoutNamespace_displayOnly(node.type);
  const blockClass = blockClassLookup(node.type);
  const shouldUseShortName =
    blockClass.base.namespace === 'core' ||
    blockClass.base.namespace === 'quanser';
  const blockIconId = getBlockIconID(
    shouldUseShortName ? printableClassName : node.type,
    node,
  );
  const iconName = getIconName(blockIconId);

  if (nodeTypeIsCode(node.type)) {
    const Comp = getCodeIcon(node.type);

    if (Comp) {
      return <Comp width={18} height={18} fill={fill} />;
    }
  }

  if (BLOCKS_WITH_ICONS.includes(iconName)) {
    const Comp = SmallIcons?.[iconName as keyof typeof SmallIcons];
    if (Comp) {
      return <Comp width={18} height={18} fill={fill} />;
    }
  }

  return <EmptyBlockIcon fill={fill} />;
};

const shouldSeeNode = (node: NodeInstanceLite, searchString?: string) =>
  getNodeName(node)
    .toLowerCase()
    .indexOf((searchString || '').toLowerCase()) > -1;

interface Props {
  projectId: string;
  testIdPath: string;
  node: NodeInstanceLite;
  parentPath: string[];
  searchString?: string;
}

const ModelBlockItem: React.FC<Props> = ({
  projectId,
  testIdPath,
  node,
  parentPath,
  searchString,
}) => {
  const theme = useTheme();

  const [isHovered, setIsHovered] = React.useState(false);
  const [isExpanded, setIsExpanded] = React.useState(
    nodeTypeIsLocalSubdiagram(node.type) && parentPath.length < 10,
  );
  const dispatch = useAppDispatch();
  const currentSubmodelPath = useAppSelector(
    (state) => state.model.present.currentSubmodelPath,
  );

  // Select and focus on block if specified
  const { isBlockNavState, blockInstanceUuid: navBlockStateUuid } =
    useIsBlockNavState();
  useEffect(() => {
    if (!isBlockNavState) return;
    if (navBlockStateUuid === node.uuid) {
      // More delay to cover up the fact that we don't know when submodels finish loading.
      // This conditional happens when user navs from Requirements to Model Editor,
      // which takes longer than a node focus action within the editor.
      setTimeout(() => {
        // Should have been able construct a URL with the selections
        // in order to focus & select a block.
        // However, the useEffects only consider the camera view path, not selections,
        // so it doesn't work for blocks in the top level where the path doesn't change.
        // See `ModelEditorURLParamaterTracker` for more details.

        // Navigates to the subdiagram and focuses on node
        dispatch(
          navigationActions.requestFocusOnNode({
            nodeId: node.uuid,
            parentPath,
          }),
        );

        // Set the selection after the camera has focused on the block.
        dispatch(
          modelActions.setSelections({
            selectionParentPath: parentPath,
            selectedBlockIds: [node.uuid],
            selectedLinkIds: [],
            selectedAnnotationIds: [],
          }),
        );

        // Clear state without triggering a re-render.
        window.history.replaceState({}, '');
      }, 500); // https://github.com/collimator-ai/collimator/pull/4488#discussion_r1232619066
    }
  }, [dispatch, isBlockNavState, navBlockStateUuid, node.uuid, parentPath]);

  const selectionParentPath = useAppSelector(
    (state) => state.model.present?.selectionParentPath,
  );
  const selectedBlockIds = useAppSelector(
    (state) => state.model.present.selectedBlockIds,
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const openTabNodeUuid = searchParams.get(CODE_EDITOR_BLOCK_QUERY_PARAM);
  const sourceCodeOpen = !!searchParams.get(
    MODEL_SOURCE_CODE_VIEWING_QUERY_PARAM,
  );

  const isCurrentDiagramParent = isSamePath(currentSubmodelPath, [
    ...parentPath,
    node.uuid,
  ]);

  const getBlockSelectCallback =
    (node: NodeInstanceLite, parentPath: string[]) =>
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      event.preventDefault();
      event.stopPropagation();

      if (event.shiftKey || event.ctrlKey) {
        if (selectedBlockIds.includes(node.uuid)) {
          // Remove from selection
          dispatch(modelActions.unselectNode({ nodeUuid: node.uuid }));
          if (openTabNodeUuid && openTabNodeUuid === node.uuid) {
            searchParams.delete(CODE_EDITOR_BLOCK_QUERY_PARAM);
            setSearchParams(searchParams);
          }
        } else {
          // Add to selection
          dispatch(
            modelActions.selectAdditionalNodes({
              parentPath,
              blockIds: [node.uuid],
            }),
          );
        }
      } else {
        // Replace selection
        if (sourceCodeOpen) {
          // HACK: should not ship to end-users
          // need a better stable source of truth for wildcat-class-to-frontend-block-type mapping
          // (probably in the form of an agreed-upon spec)
          const bareNodeType = nodeClassWithoutNamespace_displayOnly(node.type);
          if (wildcatClassTable[bareNodeType]) {
            searchParams.set(
              MODEL_SOURCE_CODE_VIEWING_QUERY_PARAM,
              bareNodeType,
            );
            setSearchParams(searchParams);
          }
        } else if (openTabNodeUuid && node.uuid !== openTabNodeUuid) {
          searchParams.delete(CODE_EDITOR_BLOCK_QUERY_PARAM);
          setSearchParams(searchParams);
        }
        dispatch(
          modelActions.setSelections({
            selectionParentPath: parentPath,
            selectedBlockIds: [node.uuid],
            selectedLinkIds: [],
            selectedAnnotationIds: [],
          }),
        );
      }
    };

  // Works on some nodes but not others. Difference is not clear.
  const getBlockNavigateCallback =
    (node: NodeInstanceLite, parentPath: string[]) =>
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      event.preventDefault();
      event.stopPropagation();

      const newSearchParams = new URLSearchParams();

      // First navigate to the diagram that corresponds to the node.
      // If the node is a submodel, include the node id in the parent path
      // so we navigate into the submodel diagram itself (rather than just
      // going to the diagram that contains this submodel).
      const nextParentPath = nodeTypeIsSubdiagram(node.type)
        ? [...parentPath, node.uuid]
        : parentPath;
      if (!isSamePath(currentSubmodelPath, nextParentPath)) {
        setNavigationURLParams(newSearchParams, { parentPath: nextParentPath });
        setSelectionURLParams(newSearchParams, {
          selectionParentPath: nextParentPath,
        });
      }

      // If the node is a code node, we will need to open the code editor
      // in addition to going to the correct diagram.
      if (nodeTypeIsCode(node.type)) {
        newSearchParams.append(CODE_EDITOR_BLOCK_QUERY_PARAM, node.uuid);
      }

      setSearchParams(newSearchParams);
    };

  const targetBlock = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.preventDefault();
    event.stopPropagation();

    dispatch(
      navigationActions.requestFocusOnNode({
        nodeId: node.uuid,
        parentPath,
      }),
    );
  };

  const toggleExpandedState = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    event.preventDefault();
    event.stopPropagation();

    setIsExpanded(!isExpanded);
  };

  const isSelectedAsAParent =
    isCurrentDiagramParent && selectedBlockIds.length === 0;
  const isSelectedAsChild =
    isSamePath(selectionParentPath, parentPath) &&
    selectedBlockIds.includes(node.uuid);
  const isSelected = isSelectedAsAParent || isSelectedAsChild;

  const iconFill = isSelected
    ? theme.colors.text.primary
    : theme.colors.text.secondary;

  const canNodeBeOpened =
    nodeTypeIsCode(node.type) || nodeTypeIsSubdiagram(node.type);

  const nodeIcon = getNodeIconComponent(node, iconFill);

  return (
    <>
      {shouldSeeNode(node, searchString) && (
        <ItemSection
          nestedLayer={parentPath.length}
          data-test-id={`model-tree-block-item-${testIdPath}`}
          selected={isSelected}
          onDoubleClick={
            canNodeBeOpened
              ? getBlockNavigateCallback(node, parentPath)
              : undefined
          }
          onClick={getBlockSelectCallback(node, parentPath)}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}>
          {nodeTypeIsSubdiagram(node.type) ? (
            <ModelTreeIcon onClick={toggleExpandedState}>
              {isExpanded ? <TreeArrowExpanded /> : <TreeArrowCollapsed />}
            </ModelTreeIcon>
          ) : (
            <ModelTreeIcon />
          )}

          <ModelTreeIcon>{nodeIcon}</ModelTreeIcon>
          <div data-test-id={`model-tree-block-item-text-${testIdPath}`}>
            <ModelBlockItemText>{getNodeName(node)}</ModelBlockItemText>
          </div>
          <BlockTargeterWrapper>
            {isHovered && (
              <BlockTargeter onClick={targetBlock}>
                <img
                  src={`${process.env.PUBLIC_URL}/assets/block_target_icon.png`}
                />
              </BlockTargeter>
            )}
          </BlockTargeterWrapper>
        </ItemSection>
      )}

      {isExpanded && nodeTypeIsLocalSubdiagram(node.type) && (
        <LocalSubmodelTreeContent
          projectId={projectId}
          testIdPath={testIdPath}
          nodeId={node.uuid}
          parentPath={parentPath}
          searchString={searchString}
        />
      )}

      {isExpanded && nodeTypeIsReferencedSubmodel(node.type) && (
        <ReferenceSubmodelTreeContent
          projectId={projectId}
          testIdPath={testIdPath}
          submodelInstanceId={node.uuid}
          submodelReferenceId={node.submodel_reference_uuid || ''}
          submodelVersionId={VersionTagValues.LATEST_VERSION}
          parentPath={parentPath}
          searchString={searchString}
        />
      )}
    </>
  );
};

export default ModelBlockItem;
