import styled from '@emotion/styled/macro';
import React from 'react';

const BreadcrumbItemWrapper = styled.div<{
  actionable?: boolean;
  isActive?: boolean;
}>`
  height: ${({ theme }) => theme.spacing.xlarge};
  padding: ${({ theme }) => theme.spacing.small};
  overflow: hidden;
  display: flex;
  background: ${({ theme, isActive }) =>
    isActive ? theme.colors.grey[30] : 'transparent'};
  align-items: center;
  border-radius: 2px;
  user-select: none;
  color: ${({ theme }) => theme.colors.text.secondary};
  & * {
    fill: ${({ theme }) => theme.colors.text.secondary};
  }
  line-height: ${({ theme }) => theme.typography.font.smallEmphasis.lineHeight};
  font-weight: ${({ theme }) => theme.typography.font.smallEmphasis.weight};
  font-size: ${({ theme }) => theme.typography.font.smallEmphasis.size};
  ${({ theme, actionable }) =>
    actionable &&
    `
    cursor: pointer;
    :hover {
      background-color: ${theme.colors.grey[10]};
      color: ${theme.colors.text.primary};
      & * {
        fill: ${theme.colors.text.primary};
      }
    }
    &:active {
      background: ${theme.colors.grey[30]};
      color: ${theme.colors.text.primary};
      & * {
        fill: ${theme.colors.text.primary};
      }
    }
  `}
`;

const IconWrapper = styled.div`
  display: flex;
  margin-right: ${({ theme }) => theme.spacing.small};
`;

const DisplayNameWrapper = styled.span`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

interface Props {
  displayName: string;
  Icon?: React.ReactElement;
  onClick?: (e: React.MouseEvent) => void;
  isActive?: boolean;
}

const BreadcrumbItem: React.FC<Props> = ({ Icon, displayName, onClick }) => (
  <BreadcrumbItemWrapper onClick={onClick} actionable={!!onClick}>
    {Icon && <IconWrapper>{Icon}</IconWrapper>}
    <DisplayNameWrapper>{displayName}</DisplayNameWrapper>
  </BreadcrumbItemWrapper>
);

export default BreadcrumbItem;
