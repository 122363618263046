/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Archive: React.FC<Props> = (props) => (
  <svg viewBox="0 0 24 24" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10 11.75C9.58579 11.75 9.25 12.0858 9.25 12.5C9.25 12.9142 9.58579 13.25 10 13.25H14C14.4142 13.25 14.75 12.9142 14.75 12.5C14.75 12.0858 14.4142 11.75 14 11.75H10Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M4 8C4 8.55228 4.44772 9 5 9V18.5C5 19.0523 5.44772 19.5 6 19.5H18C18.5523 19.5 19 19.0523 19 18.5V9C19.5523 9 20 8.55228 20 8V5.5C20 4.94772 19.5523 4.5 19 4.5H5C4.44772 4.5 4 4.94772 4 5.5V8ZM5.5 6V7.5H18.5V6H5.5ZM6.5 9H17.5V18H6.5V9Z"
      fill={props.fill}
    />
  </svg>
);

Archive.defaultProps = {
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Archive;
