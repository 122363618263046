/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const ArrowRight: React.FC<Props> = (props) => (
  <svg viewBox="0 0 24 24" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M11.4697 6.46967C11.7626 6.17678 12.2374 6.17678 12.5303 6.46967L18.0607 12L12.5303 17.5303C12.2374 17.8232 11.7626 17.8232 11.4697 17.5303C11.1768 17.2374 11.1768 16.7626 11.4697 16.4697L15.1893 12.75H7C6.58579 12.75 6.25 12.4142 6.25 12C6.25 11.5858 6.58579 11.25 7 11.25H15.1893L11.4697 7.53033C11.1768 7.23744 11.1768 6.76256 11.4697 6.46967Z"
      fill={props.fill}
    />
  </svg>
);

ArrowRight.defaultProps = {
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default ArrowRight;
