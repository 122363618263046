/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const File: React.FC<Props> = (props) => (
  <svg viewBox="0 0 16 16" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M11.5 4H5L5 12H11.5V4ZM5 3C4.44772 3 4 3.44772 4 4V12C4 12.5523 4.44772 13 5 13H11.5C12.0523 13 12.5 12.5523 12.5 12V4C12.5 3.44772 12.0523 3 11.5 3H5Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M6 5.5H10.5V6.5H6V5.5ZM6 7.5H10.5V8.5H6V7.5ZM6 9.5H10.5V10.5H6V9.5Z"
      fill={props.fill}
    />
  </svg>
);

File.defaultProps = {
  width: '16',
  height: '16',
  viewBox: '0 0 16 16',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default File;
