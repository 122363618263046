/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const CoordinaterotationconversionQuaterniontodcm: React.FC<Props> = (
  props,
) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.46967 11.9697C9.76256 11.6768 10.2374 11.6768 10.5303 11.9697L13.1768 14.6161C13.6649 15.1043 13.6649 15.8957 13.1768 16.3839L10.5303 19.0303C10.2374 19.3232 9.76256 19.3232 9.46967 19.0303C9.17678 18.7374 9.17678 18.2626 9.46967 17.9697L11.9393 15.5L9.46967 13.0303C9.17678 12.7374 9.17678 12.2626 9.46967 11.9697Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M5.00285 12.93C4.98285 12.95 4.97285 12.9767 4.97285 13.01V13.31C4.97285 13.33 4.96618 13.3433 4.95285 13.35C4.93952 13.3567 4.92619 13.35 4.91285 13.33C4.61952 12.9833 4.23619 12.81 3.76285 12.81C3.28952 12.81 2.88952 12.9333 2.56285 13.18C2.23618 13.4267 1.99952 13.77 1.85285 14.21C1.73285 14.5767 1.67285 14.9933 1.67285 15.46C1.67285 15.9333 1.72952 16.34 1.84285 16.68C1.98952 17.12 2.22618 17.4633 2.55285 17.71C2.87952 17.9567 3.28952 18.08 3.78285 18.08C4.24285 18.08 4.61952 17.9133 4.91285 17.58C4.92619 17.5667 4.93952 17.5633 4.95285 17.57C4.96618 17.57 4.97285 17.58 4.97285 17.6V19.77C4.97285 19.8033 4.98285 19.83 5.00285 19.85C5.02952 19.8767 5.05952 19.89 5.09285 19.89H6.01285C6.04619 19.89 6.07285 19.8767 6.09285 19.85C6.11952 19.83 6.13285 19.8033 6.13285 19.77V13.01C6.13285 12.9767 6.11952 12.95 6.09285 12.93C6.07285 12.9033 6.04619 12.89 6.01285 12.89H5.09285C5.05952 12.89 5.02952 12.9033 5.00285 12.93ZM4.96285 15.44C4.96285 15.9667 4.87952 16.3667 4.71285 16.64C4.53285 16.9333 4.27952 17.08 3.95285 17.08C3.61285 17.08 3.34618 16.94 3.15285 16.66C2.95285 16.3533 2.85285 15.9467 2.85285 15.44C2.85285 14.9733 2.93285 14.59 3.09285 14.29C3.27952 13.9633 3.56285 13.8 3.94285 13.8C4.28285 13.8 4.54619 13.96 4.73285 14.28C4.88619 14.5533 4.96285 14.94 4.96285 15.44Z"
      fill={props.fill}
    />
    <path
      d="M23.3938 18.564C22.9778 18.564 22.6124 18.484 22.2978 18.324C21.9831 18.1587 21.7404 17.9293 21.5698 17.636C21.3991 17.3427 21.3138 17.004 21.3138 16.62V14.772C21.3138 14.388 21.3991 14.0493 21.5698 13.756C21.7404 13.4627 21.9831 13.236 22.2978 13.076C22.6124 12.916 22.9778 12.836 23.3938 12.836C23.8044 12.836 24.1644 12.9133 24.4738 13.068C24.7884 13.2173 25.0311 13.4333 25.2018 13.716C25.3778 13.9933 25.4658 14.316 25.4658 14.684C25.4658 14.732 25.4338 14.7613 25.3698 14.772L24.4338 14.828H24.4178C24.3644 14.828 24.3378 14.7987 24.3378 14.74C24.3378 14.4573 24.2498 14.2307 24.0738 14.06C23.9031 13.8893 23.6764 13.804 23.3938 13.804C23.1058 13.804 22.8738 13.8893 22.6978 14.06C22.5271 14.2307 22.4418 14.4573 22.4418 14.74V16.668C22.4418 16.9453 22.5271 17.1693 22.6978 17.34C22.8738 17.5107 23.1058 17.596 23.3938 17.596C23.6764 17.596 23.9031 17.5107 24.0738 17.34C24.2498 17.1693 24.3378 16.9453 24.3378 16.668C24.3378 16.6093 24.3698 16.58 24.4338 16.58L25.3698 16.62C25.3964 16.62 25.4178 16.628 25.4338 16.644C25.4551 16.66 25.4658 16.6787 25.4658 16.7C25.4658 17.068 25.3778 17.3933 25.2018 17.676C25.0311 17.9587 24.7884 18.1773 24.4738 18.332C24.1644 18.4867 23.8044 18.564 23.3938 18.564Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M16.5843 18.5C16.5576 18.5 16.5336 18.492 16.5123 18.476C16.4963 18.4547 16.4883 18.4307 16.4883 18.404V12.996C16.4883 12.9693 16.4963 12.948 16.5123 12.932C16.5336 12.9107 16.5576 12.9 16.5843 12.9H18.5843C18.9843 12.9 19.3336 12.972 19.6323 13.116C19.9363 13.2547 20.1709 13.452 20.3363 13.708C20.5016 13.964 20.5843 14.26 20.5843 14.596V16.804C20.5843 17.14 20.5016 17.436 20.3363 17.692C20.1709 17.948 19.9363 18.148 19.6323 18.292C19.3336 18.4307 18.9843 18.5 18.5843 18.5H16.5843ZM17.6163 17.492C17.6163 17.5187 17.6296 17.532 17.6563 17.532H18.6243C18.8696 17.532 19.0669 17.4493 19.2163 17.284C19.3709 17.1187 19.4509 16.8973 19.4563 16.62V14.78C19.4563 14.5027 19.3789 14.2813 19.2243 14.116C19.0749 13.9507 18.8723 13.868 18.6163 13.868H17.6563C17.6296 13.868 17.6163 13.8813 17.6163 13.908V17.492Z"
      fill={props.fill}
    />
    <path
      d="M30.005 12.9C29.957 12.9 29.9196 12.9213 29.893 12.964L28.685 14.828C28.669 14.86 28.653 14.86 28.637 14.828L27.437 12.964C27.4103 12.9213 27.373 12.9 27.325 12.9H26.389C26.3623 12.9 26.3383 12.9107 26.317 12.932C26.301 12.948 26.293 12.9693 26.293 12.996V18.404C26.293 18.4307 26.301 18.4547 26.317 18.476C26.3383 18.492 26.3623 18.5 26.389 18.5H27.325C27.3516 18.5 27.373 18.492 27.389 18.476C27.4103 18.4547 27.421 18.4307 27.421 18.404V14.884C27.421 14.8627 27.4263 14.852 27.437 14.852C27.4476 14.8467 27.4583 14.852 27.469 14.868L28.317 16.196C28.3436 16.2387 28.381 16.26 28.429 16.26H28.901C28.949 16.26 28.9863 16.2387 29.013 16.196L29.861 14.868C29.8716 14.852 29.8823 14.844 29.893 14.844C29.9036 14.844 29.909 14.8547 29.909 14.876V18.404C29.909 18.4307 29.917 18.4547 29.933 18.476C29.9543 18.492 29.9783 18.5 30.005 18.5H30.941C30.9676 18.5 30.989 18.492 31.005 18.476C31.0263 18.4547 31.037 18.4307 31.037 18.404V12.996C31.037 12.9693 31.0263 12.948 31.005 12.932C30.989 12.9107 30.9676 12.9 30.941 12.9H30.005Z"
      fill={props.fill}
    />
  </svg>
);

CoordinaterotationconversionQuaterniontodcm.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default CoordinaterotationconversionQuaterniontodcm;
