/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const LogicAnd: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M3.5 12.25H6.75V7.5C6.75 6.80931 7.31176 6.24733 8.00528 6.25149C12.415 6.2779 16.6791 6.66042 19.8596 8.10403C21.4646 8.83251 22.8238 9.8442 23.7786 11.2421C24.6212 12.476 25.1204 13.9691 25.2279 15.75H28.5C28.9142 15.75 29.25 16.0858 29.25 16.5C29.25 16.9142 28.9142 17.25 28.5 17.25H25.2271C25.1175 19.0155 24.6172 20.4634 23.764 21.6301C22.7953 22.955 21.4201 23.8509 19.8123 24.4573C16.6353 25.6555 12.3702 25.7841 7.988 25.744C7.30288 25.7377 6.75 25.1803 6.75 24.4947V20.75H3.5C3.08579 20.75 2.75 20.4142 2.75 20C2.75 19.5858 3.08579 19.25 3.5 19.25H6.75V13.75H3.5C3.08579 13.75 2.75 13.4142 2.75 13C2.75 12.5858 3.08579 12.25 3.5 12.25ZM8.25 7.75339V24.2461C12.5693 24.2787 16.4615 24.1179 19.283 23.0538C20.7014 22.5189 21.8024 21.7716 22.5532 20.7447C23.3009 19.7223 23.75 18.3563 23.75 16.4941C23.75 14.6333 23.3013 13.2029 22.5399 12.0881C21.7757 10.9693 20.6597 10.1145 19.2397 9.46991C16.4255 8.19258 12.5381 7.79313 8.25 7.75339Z"
      fill={props.fill}
    />
  </svg>
);

LogicAnd.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default LogicAnd;
