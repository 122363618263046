import { useTheme } from '@emotion/react';
import styled from '@emotion/styled/macro';
import React from 'react';
import PortalTextTooltip from 'ui/common/Tooltip/PortalTextTooltip';
import { TooltipPlacement } from 'ui/common/Tooltip/tooltipTypes';
import { usePortalTooltip } from 'ui/common/Tooltip/usePortalTooltip';
import { SmallEmphasis } from 'ui/common/typography/Typography';

const FooterButtonWrapper = styled.div<{ selected: boolean }>`
  position: relative;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: 100%;
  padding: 0 ${({ theme }) => theme.spacing.small};
  user-select: none;

  &:hover {
    ${({ selected, theme }) =>
      selected
        ? ''
        : `
    background: ${theme.colors.brand.tertiary.darker};
    color: ${theme.colors.grey[5]};
    `}
  }
  ${({ selected, theme }) =>
    selected
      ? `
    background: ${theme.colors.brand.tertiary.base};
    color: ${theme.colors.grey[5]};
    `
      : `
    color: ${theme.colors.grey[30]};
    `}
`;

const FooterButtonText = styled(SmallEmphasis)`
  margin-left: ${({ theme }) => theme.spacing.small};
  margin-right: ${({ theme }) => theme.spacing.small};
`;

interface Props {
  name?: string;
  detailText?: string;
  Icon: React.FC<any>;
  isSelected?: boolean;
  onClickHandler: () => void;
  testId: string;
  tooltipText: string;
  tooltipPlacement?: TooltipPlacement;
}

const FooterButton: React.FC<Props> = ({
  name,
  detailText,
  Icon,
  isSelected,
  onClickHandler,
  testId,
  tooltipText,
  tooltipPlacement = TooltipPlacement.TOP_RIGHT,
}) => {
  const theme = useTheme();

  const { triggerElRef, isTooltipOpen } = usePortalTooltip<HTMLDivElement>();

  const [isHovered, setIsHovered] = React.useState(false);

  const buttonText = name
    ? `${name}${detailText ? `: ${detailText}` : ''}`
    : null;

  return (
    <>
      <FooterButtonWrapper
        data-test-id={testId}
        ref={triggerElRef}
        selected={isSelected || false}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={onClickHandler}>
        <Icon
          fill={
            isSelected
              ? theme.colors.grey[5]
              : isHovered
              ? theme.colors.grey[5]
              : theme.colors.grey[30]
          }
        />

        {buttonText && <FooterButtonText>{buttonText}</FooterButtonText>}
      </FooterButtonWrapper>
      {isTooltipOpen && triggerElRef.current && (
        <PortalTextTooltip
          triggerEl={triggerElRef.current}
          contentText={tooltipText}
          placement={tooltipPlacement}
        />
      )}
    </>
  );
};

export default FooterButton;
