/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  cx?: any;
  cy?: any;
  rx?: any;
  ry?: any;
  stroke?: any;
  strokeWidth?: any;
  className?: string;
}

const AcausalHydraulicAccumulator: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M6.70743 13.75H4C3.58579 13.75 3.25 14.0858 3.25 14.5C3.25 14.9142 3.58579 15.25 4 15.25H6.75H9.5C9.91421 15.25 10.25 14.9142 10.25 14.5C10.25 14.0858 9.91421 13.75 9.5 13.75H8.39385C9.1451 12.5485 10.4801 11.75 12.0003 11.75H12.4831C14.2399 12.3629 15.5003 14.0342 15.5003 16C15.5003 17.9658 14.2398 19.6371 12.4831 20.25H12.0003C10.4801 20.25 9.1451 19.4515 8.39385 18.25H9.5C9.91421 18.25 10.25 17.9142 10.25 17.5C10.25 17.0858 9.91421 16.75 9.5 16.75H6.75H4C3.58579 16.75 3.25 17.0858 3.25 17.5C3.25 17.9142 3.58579 18.25 4 18.25H6.70743C7.58292 20.3068 9.62224 21.75 12.0003 21.75H20.0003C22.3775 21.75 24.4177 20.3075 25.2934 18.25H26.5H28C28.4142 18.25 28.75 17.9142 28.75 17.5C28.75 17.0858 28.4142 16.75 28 16.75H26.5H25.7018C25.7338 16.5045 25.7503 16.2542 25.7503 16C25.7503 15.7458 25.7338 15.4955 25.7018 15.25H26.5H28C28.4142 15.25 28.75 14.9142 28.75 14.5C28.75 14.0858 28.4142 13.75 28 13.75H26.5H25.2934C24.4177 11.6925 22.3775 10.25 20.0003 10.25H12.0003C9.62224 10.25 7.58292 11.6932 6.70743 13.75ZM14.4916 11.75C15.7181 12.7587 16.5003 14.288 16.5003 16C16.5003 17.712 15.7181 19.2413 14.4916 20.25H19.3129C20.8564 19.6444 22.0003 18.0002 22.0003 16C22.0003 13.9998 20.8564 12.3556 19.3129 11.75H14.4916ZM24.2503 16C24.2503 17.8457 23.0738 19.4165 21.4299 20.0036C22.4021 18.9925 23.0003 17.5609 23.0003 16C23.0003 14.4391 22.4021 13.0075 21.4299 11.9964C23.0738 12.5835 24.2503 14.1543 24.2503 16Z"
      fill={props.fill}
    />
    <ellipse
      cx={props.cx}
      cy={props.cy}
      rx={props.rx}
      ry={props.ry}
      stroke={props.stroke}
      strokeWidth={props.strokeWidth}
    />
    <path
      d="M9.5 17.5C10.0523 17.5 10.5 16.8284 10.5 16C10.5 15.1716 10.0523 14.5 9.5 14.5"
      stroke={props.stroke}
      strokeWidth={props.strokeWidth}
    />
    <path
      d="M28 17.5C28.5523 17.5 29 16.8284 29 16C29 15.1716 28.5523 14.5 28 14.5"
      stroke={props.stroke}
      strokeWidth={props.strokeWidth}
    />
  </svg>
);

AcausalHydraulicAccumulator.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  cx: '4',
  cy: '16',
  rx: '1',
  ry: '1.5',
  stroke: '#1D3131',
  strokeWidth: '1.5',
  className: '',
};

export default AcausalHydraulicAccumulator;
