/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  stroke?: any;
  strokeWidth?: any;
  strokeLinecap?: any;
  strokeLinejoin?: any;
  className?: string;
}

const Unscentedkalmanfilter: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M24 23.9951C18.4777 26.0057 10.981 24.9967 10.981 20.9906C10.981 16.9845 19.4286 15.983 19.4286 11.9769C19.4286 7.97084 13.7143 4.46553 8 9.47312"
      stroke={props.stroke}
      strokeWidth="1.5"
      strokeLinecap={props.strokeLinecap}
    />
    <path
      d="M8 8.72425V5.28571L12.3241 10.4286L10.1619 5.71429L9.29697 12.1429L11.8916 4L13.6214 8.28571L11.8916 5.71429L14.0538 4.85714L11.8916 8.28571L15.7836 6.14286V9.57143L14.9187 4L18.8107 7L17.0809 10V6.57143L21.4053 9.57143L15.7836 12.5714L14.4863 8.72425L21.4053 12.1429L17.5134 13L21.4053 17.2857L16.6485 10.8571L17.5134 16.4286L20.5405 13.4286L14.0538 12.5714L19.6756 17.2857L13.6214 14.7143L21.8378 15.5714L15.3512 18.1429L12.3241 13L17.9458 18.5714L12.7565 16L14.9187 18.5714L9.29697 16.4286L16.216 19.8571L11.0267 15.5714L10.1619 19L13.6214 19.8571L8.86452 20.7143L8.43208 18.1429L12.7565 22.4286L13.1889 21.1429L8 23.7143L13.6214 22.8571L9.72941 21.1429L11.0267 24.1429L17.0809 22.8571L11.0267 25.4286L15.3512 22L14.0538 27.5714L17.9458 23.7143L12.7565 25.8571L17.9458 27.1429L18.8107 24.1429L17.0809 26.2857H18.8107L17.9458 22.8571L15.3512 28L20.108 22.4286L19.2431 28L20.5405 27.5714L20.9729 21.5714L23.5676 28L22.7027 22.8571L24 25.4286L21.8378 26.7143L22.2702 21.5714"
      stroke={props.stroke}
      strokeWidth={props.strokeWidth}
      strokeLinecap={props.strokeLinecap}
      strokeLinejoin={props.strokeLinejoin}
    />
  </svg>
);

Unscentedkalmanfilter.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: 'none',
  xmlns: 'http://www.w3.org/2000/svg',
  stroke: '#1D3131',
  strokeWidth: '0.5',
  strokeLinecap: 'round',
  strokeLinejoin: 'round',
  className: '',
};

export default Unscentedkalmanfilter;
