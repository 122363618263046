import { useGetChatSessionByUuidQuery } from 'app/apiGenerated/generatedApi';
import React from 'react';
import ChatContent from 'ui/appBottomBar/assistant/ChatContent';
import { usePlotCache } from 'ui/appBottomBar/assistant/usePlotCache';
import { useAppParams } from 'util/useAppParams';
import { ActionSection, Head, PageWrapper, Title } from '../styles';

const ChatSession: React.FC = () => {
  const { chatSessionId } = useAppParams();

  const { data: chatSession } = useGetChatSessionByUuidQuery(
    {
      chatSessionUuid: chatSessionId || '',
    },
    { skip: !chatSessionId },
  );
  const { plots, setPlots } = usePlotCache();

  React.useEffect(() => {
    setPlots(chatSession?.plots?.map((p) => p.value) || []);
  }, [chatSession?.plots, setPlots]);

  return (
    <PageWrapper>
      <ActionSection>
        <Head>
          <Title>Chat Session</Title>
        </Head>
      </ActionSection>
      {chatSession ? (
        <ChatContent
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          output={chatSession.messages}
          showAdvancedOptions
          plots={plots}
          addPlot={() => 0}
        />
      ) : (
        <span>Loading</span>
      )}
    </PageWrapper>
  );
};

export default ChatSession;
