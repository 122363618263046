import { t } from '@lingui/macro';
import { usePostGitHubCallbackMutation } from 'app/apiGenerated/generatedApi';
import { useAppDispatch } from 'app/hooks';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useNotifications } from 'ui/common/notifications/useNotifications';

export const GITHUB_POST_LOGIN_REDIRECT_URL_LS_KEY =
  'after-github-login-redirect-url';

const GitHubCallback: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { showError, showInfo } = useNotifications();

  const [callGitHubCallbackApi] = usePostGitHubCallbackMutation();

  React.useEffect(() => {
    const query = window.location.search;
    const params = new URLSearchParams(query);
    const code = params.get('code');

    if (!code) {
      showError(t`Unable to complete login with GitHub`);
    } else {
      callGitHubCallbackApi({ gitHubCallbackRequest: { code } })
        .unwrap()
        .then(() => {
          showInfo(t`Successfully logged in with GitHub`);
        })
        .catch((error) => {
          showError(t`Unable to complete login with GitHub`);
        });
    }

    const redirectUrl = localStorage.getItem(
      GITHUB_POST_LOGIN_REDIRECT_URL_LS_KEY,
    );
    localStorage.removeItem(GITHUB_POST_LOGIN_REDIRECT_URL_LS_KEY);

    navigate(redirectUrl ?? '/projects');
  }, [navigate, dispatch, showError, showInfo, callGitHubCallbackApi]);

  return <div />;
};

export default GitHubCallback;
