/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  className?: string;
}

const Code: React.FC<Props> = (props) => (
  <svg viewBox="0 0 24 24" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.4342 7.49393C14.5662 7.08472 14.3522 6.64242 13.9562 6.50602C13.5601 6.36962 13.132 6.59077 13 6.99998L9.5 18.5C9.36798 18.9092 9.58203 19.3515 9.97808 19.4879C10.3741 19.6243 10.8022 19.4031 10.9342 18.9939L14.4342 7.49393Z"
      fill={props.fill}
    />
    <path
      d="M7.92205 8.20824C8.25603 8.46705 8.32371 8.95659 8.07323 9.30166L5.88976 12.9985L8.07323 16.6952C8.32371 17.0403 8.25603 17.5299 7.92205 17.7887C7.58807 18.0475 7.11426 17.9775 6.86378 17.6325L4.32488 13.5242C4.12577 13.202 4.12577 12.7949 4.32488 12.4727L6.86378 8.36444C7.11426 8.01937 7.58807 7.94944 7.92205 8.20824Z"
      fill={props.fill}
    />
    <path
      d="M16.078 8.20824C15.744 8.46705 15.6763 8.95659 15.9268 9.30166L18.1102 12.9985L15.9268 16.6952C15.6763 17.0403 15.744 17.5299 16.078 17.7887C16.4119 18.0475 16.8857 17.9775 17.1362 17.6325L19.6751 13.5242C19.8742 13.202 19.8742 12.7949 19.6751 12.4727L17.1362 8.36444C16.8857 8.01937 16.4119 7.94944 16.078 8.20824Z"
      fill={props.fill}
    />
  </svg>
);

Code.defaultProps = {
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  className: '',
};

export default Code;
