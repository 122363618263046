/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  stroke?: any;
  className?: string;
}

const Mux: React.FC<Props> = (props) => (
  <svg viewBox="0 0 16 16" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.5 6.5C2.5 6.22386 2.72386 6 3 6C3.85323 6 4.74135 6.28233 5.60932 6.66809C6.47619 7.05337 7.36935 7.56375 8.23524 8.05855L8.24807 8.06588C9.12817 8.56879 9.98047 9.05527 10.7968 9.41809C11.6163 9.78233 12.3532 10 13 10C13.2761 10 13.5 10.2239 13.5 10.5C13.5 10.7761 13.2761 11 13 11C12.1468 11 11.2587 10.7177 10.3907 10.3319C9.52381 9.94663 8.63065 9.43625 7.76476 8.94145L7.75193 8.93412C6.87183 8.43121 6.01953 7.94473 5.20318 7.58191C4.38365 7.21767 3.64677 7 3 7C2.72386 7 2.5 6.77614 2.5 6.5Z"
      fill={props.fill}
    />
    <path
      d="M13 7C12.4033 7 11.7299 7.18528 10.9856 7.5H8.75252C9.30015 7.19681 9.85052 6.90817 10.3907 6.66809C11.2587 6.28233 12.1468 6 13 6C13.2761 6 13.5 6.22386 13.5 6.5C13.5 6.77614 13.2761 7 13 7Z"
      fill={props.fill}
    />
    <path
      d="M2.5 10.5C2.5 10.7761 2.72386 11 3 11C3.85323 11 4.74135 10.7177 5.60932 10.3319C6.14948 10.0918 6.69985 9.80319 7.24748 9.5H5.01438C4.27013 9.81472 3.59671 10 3 10C2.72386 10 2.5 10.2239 2.5 10.5Z"
      fill={props.fill}
    />
    <path
      d="M3 8H4.91177C4.94103 8.0127 4.97048 8.02564 5.00011 8.03881C5.59376 8.30266 6.21043 8.63575 6.85648 9H3C2.72386 9 2.5 8.77614 2.5 8.5C2.5 8.22386 2.72386 8 3 8Z"
      fill={props.fill}
    />
    <path
      d="M13 9H11.0882C11.059 8.9873 11.0295 8.97436 10.9999 8.96119C10.4062 8.69734 9.78957 8.36425 9.14352 8H13C13.2761 8 13.5 8.22386 13.5 8.5C13.5 8.77614 13.2761 9 13 9Z"
      fill={props.fill}
    />
    <path
      d="M2.5 6.5C2.5 6.22386 2.72386 6 3 6C3.85323 6 4.74135 6.28233 5.60932 6.66809C6.47619 7.05337 7.36935 7.56375 8.23524 8.05855L8.24807 8.06588C9.12817 8.56879 9.98047 9.05527 10.7968 9.41809C11.6163 9.78233 12.3532 10 13 10C13.2761 10 13.5 10.2239 13.5 10.5C13.5 10.7761 13.2761 11 13 11C12.1468 11 11.2587 10.7177 10.3907 10.3319C9.52381 9.94663 8.63065 9.43625 7.76476 8.94145L7.75193 8.93412C6.87183 8.43121 6.01953 7.94473 5.20318 7.58191C4.38365 7.21767 3.64677 7 3 7C2.72386 7 2.5 6.77614 2.5 6.5Z"
      stroke={props.stroke}
    />
    <path
      d="M13 7C12.4033 7 11.7299 7.18528 10.9856 7.5H8.75252C9.30015 7.19681 9.85052 6.90817 10.3907 6.66809C11.2587 6.28233 12.1468 6 13 6C13.2761 6 13.5 6.22386 13.5 6.5C13.5 6.77614 13.2761 7 13 7Z"
      stroke={props.stroke}
    />
    <path
      d="M2.5 10.5C2.5 10.7761 2.72386 11 3 11C3.85323 11 4.74135 10.7177 5.60932 10.3319C6.14948 10.0918 6.69985 9.80319 7.24748 9.5H5.01438C4.27013 9.81472 3.59671 10 3 10C2.72386 10 2.5 10.2239 2.5 10.5Z"
      stroke={props.stroke}
    />
    <path
      d="M3 8H4.91177C4.94103 8.0127 4.97048 8.02564 5.00011 8.03881C5.59376 8.30266 6.21043 8.63575 6.85648 9H3C2.72386 9 2.5 8.77614 2.5 8.5C2.5 8.22386 2.72386 8 3 8Z"
      stroke={props.stroke}
    />
    <path
      d="M13 9H11.0882C11.059 8.9873 11.0295 8.97436 10.9999 8.96119C10.4062 8.69734 9.78957 8.36425 9.14352 8H13C13.2761 8 13.5 8.22386 13.5 8.5C13.5 8.77614 13.2761 9 13 9Z"
      stroke={props.stroke}
    />
  </svg>
);

Mux.defaultProps = {
  width: '16',
  height: '16',
  viewBox: '0 0 16 16',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  stroke: '#020606',
  className: '',
};

export default Mux;
