import { ClerkLoaded, ClerkProvider } from '@clerk/clerk-react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  RedirectToSignIn,
  RefreshIfSignedOut,
  SignedOut,
} from './AuthComponents';

const clerkPubKey = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY || '';

// This is a wrapper around ClerkProvider to manage the Auth state after a user
// has authenticated with Clerk. Auth flow itself (sign in, sign up, etc) is
// happening in the LoginProvider component.
const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const navigate = useNavigate();

  return (
    <ClerkProvider publishableKey={clerkPubKey} navigate={(to) => navigate(to)}>
      <ClerkLoaded>{children}</ClerkLoaded>
      <SignedOut>
        <RedirectToSignIn />
      </SignedOut>
      <RefreshIfSignedOut />
    </ClerkProvider>
  );
};

export default AuthProvider;
