import { t } from '@lingui/macro';
import { PostAuthTokensApiArg } from 'app/apiGenerated/generatedApiTypes';
import {
  useDeleteAuthTokenByUuidMutation,
  useGetAuthTokensReadAllQuery,
  usePostAuthTokensMutation,
} from 'app/enhancedApi';
import { useNotifications } from 'ui/common/notifications/useNotifications';

export function useAuthTokens() {
  const { createShowError } = useNotifications();

  const {
    data: authTokens,
    isLoading: isLoadingAuthTokens,
    isFetching: isFetchingAuthTokens,
  } = useGetAuthTokensReadAllQuery();

  const [callCreateAuthTokenApi] = usePostAuthTokensMutation();

  const [callDeleteAuthTokenApi] = useDeleteAuthTokenByUuidMutation();

  const createAuthToken = (request: PostAuthTokensApiArg) =>
    callCreateAuthTokenApi(request)
      .unwrap()
      .then((model) => model)
      .catch(
        createShowError(
          t({
            id: 'authTokensApi.createAuthTokenError',
            message: 'Unable to generate auth token.',
          }),
        ),
      );

  const deleteAuthToken = (authTokenUuid: string) =>
    callDeleteAuthTokenApi({ authTokenUuid }).catch(
      createShowError(
        t({
          id: 'authTokensApi.deleteAuthTokenError',
          message: 'Unable to delete auth token.',
        }),
      ),
    );

  return {
    isLoadingAuthTokens,
    isFetchingAuthTokens,
    authTokens,
    createAuthToken,
    deleteAuthToken,
  };
}
