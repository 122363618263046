/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  x?: any;
  y?: any;
  rx?: any;
  stroke?: any;
  strokeWidth?: any;
  strokeLinecap?: any;
  className?: string;
}

const AcausalThermalRadiation: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <rect
      x="7.75"
      y={props.y}
      width={props.width}
      height={props.height}
      rx={props.rx}
      stroke={props.stroke}
      strokeWidth={props.strokeWidth}
    />
    <rect
      x={props.x}
      y={props.y}
      width={props.width}
      height={props.height}
      rx={props.rx}
      stroke={props.stroke}
      strokeWidth={props.strokeWidth}
    />
    <path
      d="M18 20.5C17 21.9999 15 19.5001 14 21"
      stroke={props.stroke}
      strokeWidth={props.strokeWidth}
      strokeLinecap={props.strokeLinecap}
    />
    <path
      d="M18 10.5C17 11.9999 15 9.50012 14 11"
      stroke={props.stroke}
      strokeWidth={props.strokeWidth}
      strokeLinecap={props.strokeLinecap}
    />
    <path
      d="M18 15.5C17 16.9999 15 14.5001 14 16"
      stroke={props.stroke}
      strokeWidth={props.strokeWidth}
      strokeLinecap={props.strokeLinecap}
    />
  </svg>
);

AcausalThermalRadiation.defaultProps = {
  width: '3.5',
  height: '16.5',
  viewBox: '0 0 32 32',
  fill: 'none',
  xmlns: 'http://www.w3.org/2000/svg',
  x: '20.75',
  y: '7.75',
  rx: '0.75',
  stroke: '#1D3131',
  strokeWidth: '1.5',
  strokeLinecap: 'round',
  className: '',
};

export default AcausalThermalRadiation;
