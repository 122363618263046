import { getTheme } from 'theme/themes';

const POINT_VALUE_PRECISION = 6;

const theme = getTheme('base');

export const LOW_PERFORMANCE_THRESHOLD = 100000;

export const BASE_PLOT_CONF: echarts.EChartsOption = {
  legend: {
    id: 'legend',
    orient: 'horizontal',
    right: 16,
    top: 8,
    // 140: enough space for the menu & tools in top-left corner
    padding: [5, 5, 5, 140],
    icon: 'rect',
    align: 'right',
    type: 'scroll',
  },
  tooltip: {
    trigger: 'axis',
    confine: true,
  },
  dataZoom: [],
  grid: {
    left: 48,
    right: 20,
    top: 40,
    bottom: 24,
  },
  animation: false,
  toolbox: {
    show: true,
    left: 50,
    feature: {
      dataZoom: {
        filterMode: 'none',
      },
      restore: {},
    },
  },
};

export const PLOT_CONF_WITH_SLIDER_ZOOM: echarts.EChartsOption = {
  grid: {
    left: 48,
    right: 16,
    top: 40,
    bottom: 64,
  },
};

export const X_AXIS: echarts.EChartsOption = {
  type: 'value',
  axisPointer: {
    show: true,
    triggerTooltip: true,
    label: {
      show: true,
    },
  },

  axisLabel: {
    formatter: (value: any) =>
      Number(parseFloat(value).toPrecision(POINT_VALUE_PRECISION)).toString(),
  },
};

export const Y_AXIS: echarts.EChartsOption = {
  type: 'value',
  axisPointer: {
    show: true,
    triggerTooltip: false,
    label: {
      show: true,
    },
  },
  splitLine: { show: false },
  // TODO: ask in sync
  // splitArea: { show: true },
  axisLabel: {
    hideOverlap: true,
    formatter: (value: any) =>
      Number(parseFloat(value).toPrecision(POINT_VALUE_PRECISION)).toString(),
  },
};

export const SERIES_BLOCK_CONF = {
  type: 'line',
  symbolSize: 4,
  showSymbol: false,
  cursor: 'pointer',
  triggerLineEvent: true,
  seriesLayoutBy: 'row',
};

export const MARKPOINT_PLOT_CONF = {
  symbol: 'circle',
  emphasis: { label: { show: true } },
  symbolSize: 8,
  itemStyle: {
    color: '#ffffff',
    borderWidth: 1.5,
    borderColor: theme.colors.grey[70],
  },
  label: {
    position: 'top',
    shadowColor: 'rgba(0, 0, 0, 0.15)',
    shadowOffsetX: 0,
    shadowOffsetY: 2,
    shadowBlur: 3,
    borderWidth: 2,
    width: 101,
    height: 48,
    verticalAlign: 'middle',
    offset: [0, -40],
    lineHeight: 20,
    padding: 4,
    color: theme.colors.text.secondary,
    backgroundColor: theme.colors.grey[2],
    formatter: (param: any) => {
      const [x, y] = param.data.coord;
      return `x = ${parseFloat(x).toPrecision(
        POINT_VALUE_PRECISION,
      )}\ny = ${parseFloat(y).toPrecision(POINT_VALUE_PRECISION)}`;
    },
  },
};

export const SIGNAL_COLORS = [
  '#18ACAA',
  '#7281E5',
  '#C99E5F',
  '#A5D8D7',
  '#AEB4EA',
  '#E6CEAA',
  '#0C8381',
  '#4660E8',
  '#AB782A',
];
