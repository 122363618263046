/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Typeconversion: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M7 22C10.3137 22 13 19.3137 13 16C13 12.6863 10.3137 10 7 10C3.68629 10 1 12.6863 1 16C1 19.3137 3.68629 22 7 22ZM7 20.5C9.48528 20.5 11.5 18.4853 11.5 16C11.5 13.5147 9.48528 11.5 7 11.5C4.51472 11.5 2.5 13.5147 2.5 16C2.5 18.4853 4.51472 20.5 7 20.5Z"
      fill={props.fill}
    />
    <path
      d="M14.5 13.7366C14.5 13.5262 14.7789 13.4209 14.942 13.5696L17.4242 15.833C17.5253 15.9252 17.5253 16.0748 17.4242 16.167L14.942 18.4304C14.7789 18.5791 14.5 18.4738 14.5 18.2634V13.7366Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M24.1586 10.4337C24.5106 10.1756 24.9894 10.1756 25.3414 10.4337L30.4185 14.1572C30.7658 14.4118 30.911 14.8601 30.7791 15.27L28.836 21.3064C28.7029 21.7198 28.3183 22 27.8841 22H21.6159C21.1817 22 20.7971 21.7198 20.664 21.3064L18.7209 15.27C18.589 14.8601 18.7342 14.4118 19.0815 14.1572L24.1586 10.4337ZM20.2593 15.1535L24.75 11.8601L29.2407 15.1535L27.5198 20.5H21.9802L20.2593 15.1535Z"
      fill={props.fill}
    />
  </svg>
);

Typeconversion.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Typeconversion;
