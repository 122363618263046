import styled from '@emotion/styled/macro';
import Button from 'ui/common/Button/Button';

export const AppContentWithFooterWrapper = styled.div`
  isolation: isolate;
  display: flex;
  height: 100%;
  overflow: hidden;
  flex-shrink: 0;
  flex-grow: 0;
  flex-direction: column;
  position: relative;
  pointer-events: none;
`;

export const AppContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-grow: 1;
  flex-shrink: 1;
  width: 100%;
  overflow: hidden;
  position: relative;
`;

const ExpandCollapseButton = styled(Button)`
  position: absolute;
  bottom: ${({ theme }) => theme.spacing.xxlarge};
  pointer-events: auto;
  z-index: 3;
  padding: ${({ theme }) => theme.spacing.small};
`;

export const LeftExpandCollapseButton = styled(ExpandCollapseButton)`
  border-radius: 0 2px 2px 0;
  margin-left: -1px;
`;

export const RightExpandCollapseButton = styled(ExpandCollapseButton)`
  right: 0;
  border-radius: 2px 0 0 2px;
  margin-right: -1px;
`;

const Sidebar = styled.div<{ isExpanded: boolean }>`
  flex-grow: 0;
  flex-shrink: 0;
  overflow: hidden;
  height: 100%;
  pointer-events: auto;
  background-color: ${({ isExpanded, theme }) =>
    isExpanded ? theme.colors.grey[2] : theme.colors.grey[50]};
  transition: margin 0.15s ease-in 0s,
    background-color 0s ease-in
      ${({ isExpanded }) => (isExpanded ? '0s' : '.15s')},
    padding 0s ease-in ${({ isExpanded }) => (isExpanded ? '0s' : '.15s')};
  z-index: 2;
  display: flex;
  flex-direction: column;
`;

export const LeftSidebar = styled(Sidebar)<{ isExpanded: boolean }>`
  width: ${({ isExpanded }) => (isExpanded ? '240px' : '248px')};
  margin-left: ${({ isExpanded }) => (isExpanded ? '0' : '-240px')};
  padding-right: ${({ isExpanded, theme }) =>
    isExpanded ? '0' : theme.spacing.normal};
`;

export const WideLeftSidebar = styled(Sidebar)<{ isExpanded: boolean }>`
  width: ${({ isExpanded }) => (isExpanded ? '256px' : '264px')};
  margin-left: ${({ isExpanded }) => (isExpanded ? '0' : '-256px')};
  padding-right: ${({ isExpanded, theme }) =>
    isExpanded ? '0' : theme.spacing.normal};
`;

export const RightSidebar = styled(Sidebar)<{ isExpanded: boolean }>`
  width: ${({ isExpanded }) => (isExpanded ? '256px' : '264px')};
  margin-right: ${({ isExpanded }) => (isExpanded ? '0' : '-256px')};
  padding-left: ${({ isExpanded, theme }) =>
    isExpanded ? '0' : theme.spacing.normal};
`;

// Note: The bottom padding is to give space for the feedback button and the collapse buttons
// so they do not cover up content.
export const SidebarScrollContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1 1 50px;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const SidebarTabGroupWrapper = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey[10]};
`;
