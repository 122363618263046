import styled from '@emotion/styled';

export const EmptyStateBorderBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px;
  gap: 16px;

  flex-grow: 1;
  height: 256px;

  background-color: ${({ theme }) => theme.colors.grey[2]};
  border: 2px solid ${({ theme }) => theme.colors.grey[10]};
  box-shadow: 0px 1.7761286497116089px 1.4566044807434082px 0px
      rgba(0, 0, 0, 0.0382),
    0px 2.6805734634399414px 4.118751049041748px 0px rgba(0, 0, 0, 0.0544),
    0px 5px 11px 0px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
`;
