/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  stroke?: any;
  strokeWidth?: any;
  fillRule?: any;
  clipRule?: any;
  x?: any;
  y?: any;
  rx?: any;
  className?: string;
}

const AcausalHydraulicHydraulicproperties: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.5 23.3571C17.5 23.8182 17.3429 24.1496 17.114 24.3676C16.8818 24.5888 16.5152 24.75 16 24.75C15.4848 24.75 15.1182 24.5888 14.886 24.3676C14.6571 24.1496 14.5 23.8182 14.5 23.3571C14.5 22.9266 14.727 22.3656 15.1079 21.7651C15.3933 21.3152 15.7263 20.9026 16 20.5914C16.2737 20.9026 16.6067 21.3152 16.8921 21.7651C17.273 22.3656 17.5 22.9266 17.5 23.3571Z"
      stroke={props.stroke}
      strokeWidth={props.strokeWidth}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M24 11C24.4142 11 24.75 10.6642 24.75 10.25C24.75 9.83579 24.4142 9.5 24 9.5L23 9.5C22.9685 9.5 22.9374 9.50194 22.907 9.50572C22.5759 8.2089 21.3999 7.25 20 7.25C18.6001 7.25 17.4241 8.2089 17.093 9.50572C17.0626 9.50194 17.0315 9.5 17 9.5L8 9.5C7.58579 9.5 7.25 9.83579 7.25 10.25C7.25 10.6642 7.58579 11 8 11L17 11C17.0315 11 17.0626 10.9981 17.093 10.9943C17.4241 12.2911 18.6001 13.25 20 13.25C21.3999 13.25 22.5759 12.2911 22.907 10.9943C22.9374 10.9981 22.9685 11 23 11L24 11ZM20 11.75C19.1716 11.75 18.5 11.0784 18.5 10.25C18.5 9.42157 19.1716 8.75 20 8.75C20.8284 8.75 21.5 9.42157 21.5 10.25C21.5 11.0784 20.8284 11.75 20 11.75Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M12 19.25C10.6001 19.25 9.42409 18.2911 9.09305 16.9943C9.06256 16.9981 9.03151 17 9 17L8 17C7.58579 17 7.25 16.6642 7.25 16.25C7.25 15.8358 7.58579 15.5 8 15.5L9 15.5C9.03151 15.5 9.06256 15.5019 9.09305 15.5057C9.42409 14.2089 10.6001 13.25 12 13.25C13.3999 13.25 14.5759 14.2089 14.907 15.5057C14.9374 15.5019 14.9685 15.5 15 15.5L24 15.5C24.4142 15.5 24.75 15.8358 24.75 16.25C24.75 16.6642 24.4142 17 24 17L15 17C14.9685 17 14.9374 16.9981 14.907 16.9943C14.5759 18.2911 13.3999 19.25 12 19.25ZM12 17.75C11.1716 17.75 10.5 17.0784 10.5 16.25C10.5 15.4216 11.1716 14.75 12 14.75C12.8284 14.75 13.5 15.4216 13.5 16.25C13.5 17.0784 12.8284 17.75 12 17.75Z"
      fill={props.fill}
    />
    <rect
      x={props.x}
      y={props.y}
      width={props.width}
      height={props.height}
      rx={props.rx}
      stroke={props.stroke}
    />
  </svg>
);

AcausalHydraulicHydraulicproperties.defaultProps = {
  width: '23',
  height: '23',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  stroke: '#1D3131',
  strokeWidth: '1.5',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  x: '4.5',
  y: '4.5',
  rx: '1.5',
  className: '',
};

export default AcausalHydraulicHydraulicproperties;
