/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  className?: string;
}

const DropdownSmall: React.FC<Props> = (props) => (
  <svg viewBox="0 0 16 16" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.17678 10.3232L10.5732 7.92678C10.7307 7.76929 10.6192 7.5 10.3964 7.5L5.60355 7.5C5.38083 7.5 5.26929 7.76929 5.42678 7.92678L7.82322 10.3232C7.92085 10.4209 8.07915 10.4209 8.17678 10.3232Z"
      fill={props.fill}
    />
  </svg>
);

DropdownSmall.defaultProps = {
  width: '16',
  height: '16',
  viewBox: '0 0 16 16',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  className: '',
};

export default DropdownSmall;
