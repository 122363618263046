import { useAppDispatch, useAppSelector } from 'app/hooks';
import { dataExplorerActions } from 'app/slices/dataExplorerSlice';
import { modelVersionsActions } from 'app/slices/modelVersionsSlice';
import React from 'react';
import { useNotifications } from 'ui/common/notifications/useNotifications';
import { AddSignalCancelRequest, NewPlotRequest } from 'util/plotCellUtils';
import {
  SignalTracesResult,
  getSignalTraces,
} from 'util/simulationSignalsTraces';

/**
 * Loader that notices when the user makes a request to add a signal and
 * makes sure the necessary model versions are loaded (loading them if needed).
 * For each add signal request, once all the model versions are loaded, this loader
 * will find the traces associated to the signal and add them to a new plot cell
 * in the current exploration.
 */
export const DataExplorerSignalLoader: React.FC = () => {
  const dispatch = useAppDispatch();

  const { showError } = useNotifications();

  const idToAddSignalRequest = useAppSelector(
    (state) => state.dataExplorer.idToAddSignalRequest,
  );

  const modelIdToVersionIdToModelData = useAppSelector(
    (state) => state.modelVersions.modelIdToVersionIdToModelData,
  );

  // When add signal requests are created or when model version data is loaded
  // see what we need to do next to fulfill the add signal request.
  // Note that if a signal is nested in submodels, that single add signal request
  // will be processed multiple times in this loop in order to get the top level model
  // version and all submodel versions loaded.
  // Version id of 'latest' or 'latest_tagged' are not supported for the data explorer,
  // so we will cancel these requests and show an error message.
  React.useEffect(() => {
    const newPlots: NewPlotRequest[] = [];
    const signalRequestsToCancel: AddSignalCancelRequest[] = [];
    let lastErrorResult: SignalTracesResult | undefined;

    Object.values(idToAddSignalRequest).forEach((request) => {
      const result: SignalTracesResult = getSignalTraces(
        request,
        modelIdToVersionIdToModelData,
      );
      if (result.modelVersionToRequest) {
        dispatch(
          modelVersionsActions.requestModelVersion(
            result.modelVersionToRequest,
          ),
        );
      } else if (result.traces) {
        newPlots.push({
          traces: result.traces,
          addSignalRequestId: request.id,
        });
      } else if (result.errorMessage) {
        lastErrorResult = result;
        signalRequestsToCancel.push({
          addSignalRequestId: request.id,
          unsupportedSignalPath: request.signalPath,
        });
      }
    });

    if (newPlots.length > 0) {
      dispatch(
        dataExplorerActions.addTracesInNewPlotCells({
          newPlots,
          signalRequestsToCancel,
        }),
      );
    }

    if (lastErrorResult && lastErrorResult.errorMessage) {
      showError(lastErrorResult.errorMessage, null, {
        isExpectedError: lastErrorResult.isExpectedError,
      });
    }
  }, [
    dispatch,
    showError,
    idToAddSignalRequest,
    modelIdToVersionIdToModelData,
  ]);

  return null;
};
