import { MouseActions } from 'app/common_types/MouseTypes';
import { PortSide } from 'app/common_types/PortTypes';
import { PortIdentifier } from 'app/common_types/SegmentTypes';
import { getCurrentModelRef } from 'app/sliceRefAccess/CurrentModelRef';
import { modelActions } from 'app/slices/modelSlice';
import { LinkPayload } from 'app/utils/modelDataUtils';
import { v4 as uuid } from 'uuid';
import { RendererState } from '../modelRenderer';
import { transitionMouseState } from '../transitionMouseState';

export const drawNewLinkFromPort = (
  rs: RendererState,
  hoverPort: PortIdentifier,
  draggingMode?: boolean,
  forceFromEnd?: boolean,
) => {
  const newLinkUuid = uuid();

  rs.dispatch(
    modelActions.setSelections({
      selectionParentPath: getCurrentModelRef().submodelPath,
      selectedBlockIds: [hoverPort.blockUuid],
      selectedLinkIds: [],
      selectedAnnotationIds: [],
    }),
  );

  const hoveringOutput = hoverPort.side === PortSide.Output;
  const startAtDest = forceFromEnd || !hoveringOutput;

  const port_side: 'outputs' | 'inputs' = hoveringOutput ? 'outputs' : 'inputs';
  const portPayload = {
    node: hoverPort.blockUuid,
    port: hoverPort.portId,
    port_side,
  };
  const linkPayload: LinkPayload = startAtDest
    ? { destination: portPayload }
    : { source: portPayload };

  rs.dispatch(
    modelActions.addNewLinkToModel({
      uuid: newLinkUuid,
      linkPayload,
      linkType: {
        connection_method: 'direct_to_block',
      },
    }),
  );

  transitionMouseState(rs, {
    state: startAtDest
      ? MouseActions.DrawingLinkFromStart
      : MouseActions.DrawingLinkFromEnd,
    linkUuid: newLinkUuid,
    draggingMode,
  });
};
