import { useTheme } from '@emotion/react';
import styled from '@emotion/styled/macro';
import React from 'react';
import { TooltipPlacement } from 'ui/common/Tooltip/tooltipTypes';
import { getPointerPosition } from './utils';

const Pointer = styled.div<{
  top: number;
  left: number;
  placement: TooltipPlacement;
  fillColor: string;
}>`
  position: absolute;
  width: 0;
  height: 0;
  top: ${({ top }) => top}px;
  left: ${({ left }) => left}px;
  ${({ theme, placement, fillColor }) => {
    const transparent = `${theme.spacing.normal} solid transparent;`;
    switch (placement) {
      case TooltipPlacement.BOTTOM_LEFT:
      case TooltipPlacement.BOTTOM_CENTER:
      case TooltipPlacement.BOTTOM_RIGHT:
        return `
            border-bottom: ${fillColor};
            border-right: ${transparent};
            border-left: ${transparent};
        `;
      case TooltipPlacement.TOP_LEFT:
      case TooltipPlacement.TOP_CENTER:
      case TooltipPlacement.TOP_RIGHT:
        return `
            border-top: ${fillColor};
            border-right: ${transparent};
            border-left: ${transparent};
        `;
      default:
        return '';
    }
  }}
`;

interface Props {
  triggerRect: DOMRect;
  contentRect: DOMRect;
  placement: TooltipPlacement;
  invertedColor: boolean;
  showBorder?: boolean;
}

const TooltipPointer: React.FC<Props> = ({
  triggerRect,
  contentRect,
  placement,
  invertedColor,
  showBorder,
}) => {
  const theme = useTheme();

  const pointerPos = getPointerPosition(triggerRect, contentRect, placement);

  const fillColor = invertedColor
    ? `${theme.spacing.normal} solid ${theme.colors.grey[85]};`
    : `${theme.spacing.normal} solid ${theme.colors.grey[2]};`;

  const borderColor = invertedColor
    ? `${theme.spacing.normal} solid ${theme.colors.grey[98]};` // Note this style is not currently used, so its value probably needs an update if we go to use it.
    : `${theme.spacing.normal} solid ${theme.colors.grey[10]};`;

  if (showBorder) {
    return (
      <>
        <Pointer
          fillColor={borderColor}
          placement={placement}
          top={pointerPos.top}
          left={pointerPos.left}
        />
        <Pointer
          fillColor={fillColor}
          placement={placement}
          top={pointerPos.top + 1}
          left={pointerPos.left}
        />
      </>
    );
  }

  return (
    <Pointer
      fillColor={fillColor}
      placement={placement}
      top={pointerPos.top}
      left={pointerPos.left}
    />
  );
};

export default TooltipPointer;
