import { MouseActions, MouseState } from 'app/common_types/MouseTypes';
import { RENDERER_EXPERIMENTAL_SMOOTH_ENTITY_MOVE } from 'app/config/globalApplicationConfig';
import { modelActions } from 'app/slices/modelSlice';
import { uiFlagsActions } from 'app/slices/uiFlagsSlice';
import { MutableRefObject } from 'react';
import { RendererRefsType } from 'ui/modelEditor/ModelRendererWrapper';
import { RendererState } from 'ui/modelRendererInternals/modelRenderer';
import { autoInsertNodesIntoLinks } from './autoInsertNodesIntoLinks';

const readOnlyValidStates = [
  MouseActions.Idle,
  MouseActions.Panning,
  MouseActions.MakingSelection,
];

export function ensureEntitiesAreMutable(
  refsObject: MutableRefObject<RendererRefsType>,
) {
  if (refsObject.current.entitiesAreMutable) return;

  refsObject.current.nodes = structuredClone(refsObject.current.nodes);
  refsObject.current.links = structuredClone(refsObject.current.links);
  refsObject.current.annotations = structuredClone(
    refsObject.current.annotations,
  );
  refsObject.current.entitiesAreMutable = true;
}

export const transitionMouseState = (
  rs: RendererState,
  newState: MouseState,
) => {
  if (
    !rs.refs.current.uiFlags.canEditModel &&
    !readOnlyValidStates.includes(newState.state)
  ) {
    rs.mouseState = {
      state: MouseActions.Idle,
    };

    return;
  }

  const movingToAnnotations =
    newState.state === MouseActions.DefiningAnnotationBox ||
    newState.state === MouseActions.ReadyToDefineAnnotation;

  const preivouslyInAnnotations =
    rs.mouseState.state === MouseActions.DefiningAnnotationBox ||
    rs.mouseState.state === MouseActions.ReadyToDefineAnnotation;

  if (!movingToAnnotations && preivouslyInAnnotations) {
    rs.dispatch(uiFlagsActions.setUIFlag({ addingAnnotation: false }));
  }

  if (
    rs.mouseState.state === MouseActions.DrawingLinkFromEnd ||
    rs.mouseState.state === MouseActions.DrawingLinkFromStart
  ) {
    rs.dispatch(
      modelActions.removeEntitiesFromModel({
        linkUuids: [rs.mouseState.linkUuid],
      }),
    );
  }

  if (rs.mouseState.state === MouseActions.DraggingLinkSegment) {
    // snap segment to grid
    rs.dispatch(
      modelActions.snapEntitiesToGrid({
        linkUuids: [rs.mouseState.linkUuid],
      }),
    );

    rs.dispatch(
      modelActions.simplifyLinkSegments({ linkUuid: rs.mouseState.linkUuid }),
    );
  }

  if (
    rs.mouseState.state === MouseActions.DraggingSelected &&
    newState.state === MouseActions.Idle
  ) {
    if (RENDERER_EXPERIMENTAL_SMOOTH_ENTITY_MOVE) {
      // drop moving entities
      const deltaX = rs.screenCursorZoomed.x - rs.mouseState.startCursorX;
      const deltaY = rs.screenCursorZoomed.y - rs.mouseState.startCursorY;

      const blockUuids = rs.mouseState.selectionOverride
        ? rs.mouseState.selectionOverride.nodeUuids || []
        : rs.refs.current.selectedNodeIds;
      const linkUuids = rs.mouseState.selectionOverride
        ? rs.mouseState.selectionOverride.linkUuids || []
        : rs.refs.current.selectedLinkIds;
      const annotationUuids = rs.mouseState.selectionOverride
        ? rs.mouseState.selectionOverride.annotationUuids || []
        : rs.refs.current.selectedAnnotationIds;

      rs.dispatch(
        modelActions.finalizeMoveEntities({
          blockUuids,
          linkUuids,
          annotationUuids,
          deltaX,
          deltaY,
          snap: true,
          simplifyLinks: true,
        }),
      );
    } else {
      rs.dispatch(
        modelActions.finalizeMoveEntities({
          blockUuids: rs.refs.current.selectedNodeIds,
          linkUuids: rs.refs.current.selectedLinkIds,
          annotationUuids: rs.refs.current.selectedAnnotationIds,
          snap: true,
          simplifyLinks: true,
        }),
      );
    }

    autoInsertNodesIntoLinks(rs);
  }

  if (newState.state === MouseActions.DraggingSelected) {
    if (RENDERER_EXPERIMENTAL_SMOOTH_ENTITY_MOVE) {
      ensureEntitiesAreMutable(rs.refs);
    }
  }

  rs.mouseState = newState;
};
