/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  className?: string;
}

const RandomdistributionBernoulli: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path d="M2.25 25.75V6H1.75V26.25H30V25.75H2.25Z" fill={props.fill} />
    <path d="M15 25H4V20H15V25Z" fill={props.fill} />
    <path d="M17 25H28V8H17V25Z" fill={props.fill} />
  </svg>
);

RandomdistributionBernoulli.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  className: '',
};

export default RandomdistributionBernoulli;
