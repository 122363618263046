/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Product: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M16 4.75C9.7868 4.75 4.75 9.7868 4.75 16C4.75 22.2132 9.7868 27.25 16 27.25C22.2132 27.25 27.25 22.2132 27.25 16C27.25 9.7868 22.2132 4.75 16 4.75ZM3.25 16C3.25 8.95837 8.95837 3.25 16 3.25C23.0416 3.25 28.75 8.95837 28.75 16C28.75 23.0416 23.0416 28.75 16 28.75C8.95837 28.75 3.25 23.0416 3.25 16ZM10.8414 10.8413C11.1343 10.5484 11.6091 10.5484 11.902 10.8413L16 14.9393L20.098 10.8413C20.3909 10.5484 20.8658 10.5484 21.1587 10.8413C21.4516 11.1342 21.4516 11.6091 21.1587 11.902L17.0607 16L21.1587 20.098C21.4516 20.3909 21.4516 20.8658 21.1587 21.1587C20.8658 21.4516 20.3909 21.4516 20.098 21.1587L16 17.0607L11.902 21.1587C11.6091 21.4516 11.1343 21.4516 10.8414 21.1587C10.5485 20.8658 10.5485 20.3909 10.8414 20.098L14.9394 16L10.8414 11.902C10.5485 11.6091 10.5485 11.1342 10.8414 10.8413Z"
      fill={props.fill}
    />
  </svg>
);

Product.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Product;
