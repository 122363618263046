/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const File: React.FC<Props> = (props) => (
  <svg viewBox="0 0 24 24" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M16.5882 6.4H7.41176L7.41176 17.6H16.5882V6.4ZM7.41176 5C6.63207 5 6 5.6268 6 6.4V17.6C6 18.3732 6.63207 19 7.41176 19H16.5882C17.3679 19 18 18.3732 18 17.6V6.4C18 5.6268 17.3679 5 16.5882 5H7.41176Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M8.82353 8.5H15.1765V9.9H8.82353V8.5ZM8.82353 11.3H15.1765V12.7H8.82353V11.3ZM8.82353 14.1H15.1765V15.5H8.82353V14.1Z"
      fill={props.fill}
    />
  </svg>
);

File.defaultProps = {
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default File;
