import React from 'react';
import { useAppDispatch } from 'app/hooks';
import { usePostSubmodelCreateMutation } from 'app/apiGenerated/generatedApi';
import { PostSubmodelCreateApiArg } from 'app/apiGenerated/generatedApiTypes';
import { submodelsActions } from 'app/slices/submodelsSlice';

export function useCreateSubmodel() {
  const dispatch = useAppDispatch();

  const [callCreateSubmodelApi] = usePostSubmodelCreateMutation();

  const createSubmodel = React.useCallback(
    (request: PostSubmodelCreateApiArg) =>
      callCreateSubmodelApi(request)
        .unwrap()
        .finally(() => {
          // Update the project detail list when the call returns.
          // TODO update the model as well.
          dispatch(submodelsActions.requestSubmodels(request.projectUuid));
        }),
    [callCreateSubmodelApi, dispatch],
  );

  return {
    createSubmodel,
  };
}
