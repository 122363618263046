import { Project } from 'app/apiTransformers/convertAPIProjectToProject';
import { useAppSelector } from 'app/hooks';
import React from 'react';
import {
  ProjectItemType,
  ProjectsDetailTableData,
} from 'ui/dashboard/dashboardTypes';

/**
 * Get all the items in a project (including models, submodels and files).
 * Note: only the first file that's processing is returned in `fileIdToWatch`.
 */
export function useProjectItems(project?: Project) {
  const submodels = useAppSelector(
    (state) =>
      state.submodels.projectIdToSubmodelInfoLites[project?.uuid || ''],
  );
  const [projectItems, fileIdToWatch] = React.useMemo(() => {
    let modelData: ProjectsDetailTableData[] = [];
    let submodelData: ProjectsDetailTableData[] = [];
    let projectFileData: ProjectsDetailTableData[] = [];
    let busTypeData: ProjectsDetailTableData[] = [];

    modelData =
      project?.models.map((m) => ({
        type: ProjectItemType.MODEL,
        projectUuid: project?.uuid || '',
        uuid: m.uuid,
        name: m.name.split('/').pop() || m.name,
        path: m.name,
        createdAt: m.created_at,
        updatedAt: m.updated_at,
        error: '',
        isProcessing: false,
      })) || [];

    projectFileData =
      project?.files.map((f) => ({
        type: ProjectItemType.PROJECTFILE,
        projectUuid: project.uuid,
        uuid: f.uuid,
        name: f.name.split('/').pop() || f.name,
        path: f.name,
        createdAt: f.created_at,
        updatedAt: f.updated_at,
        error: f.error,
        isProcessing: f.status === 'processing_in_progress',
      })) || [];

    submodelData = (submodels || []).map((submodel) => ({
      type: ProjectItemType.SUBMODEL,
      projectUuid: project?.uuid || '',
      uuid: submodel.id,
      name: submodel.name.split('/').pop() || submodel.name,
      path: submodel.name,
      createdAt: submodel.createdAt,
      updatedAt: submodel.updatedAt,
      error: '',
      isProcessing: false,
    }));

    busTypeData = (project?.busTypes || []).map((busType) => ({
      type: ProjectItemType.BUS_TYPE,
      projectUuid: project?.uuid || '',
      uuid: busType.id,
      name: busType.name.split('/').pop() || busType.name,
      path: busType.name,
      createdAt: busType.created_at,
      updatedAt: busType.updated_at,
      error: '',
      isProcessing: false,
    }));

    const projectItems = modelData.concat(
      submodelData,
      projectFileData,
      busTypeData,
    );

    // FIXME: multiple files can be processing at once, but this only detects one?
    // Elsewhere on the `ProjectDetail` page the `project` is refreshed when a file is done processing,
    // so if there are other files in progress, they will be picked up in the next.
    // Noting the fragmented logic that ensures all files are accounted for in that specific case,
    // but it would not be correct on its own.
    // See: useFileProcessingStatus for the logic that uses this var
    // Even in the case of `ProjectDetail` there is a bug:
    // a file that finishes faster will not show as completed if it's lower on the list.
    const fileIdToWatch =
      projectFileData.find((f) => f.isProcessing)?.uuid || '';

    return [projectItems, fileIdToWatch];
  }, [
    project?.models,
    project?.files,
    project?.busTypes,
    project?.uuid,
    submodels,
  ]);

  return {
    projectItems,
    fileIdToWatch,
  };
}
