/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const AcausalElectricalResistor: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M7.25 10.25C7.56871 10.25 7.85261 10.4514 7.95789 10.7522L10.75 18.7297L13.5421 10.7522C13.6474 10.4514 13.9313 10.25 14.25 10.25C14.5687 10.25 14.8526 10.4514 14.9579 10.7522L17.75 18.7297L20.5421 10.7522C20.6474 10.4514 20.9313 10.25 21.25 10.25C21.5687 10.25 21.8526 10.4514 21.9579 10.7522L24.75 18.7297L25.7921 15.7522C25.8974 15.4514 26.1813 15.25 26.5 15.25H30C30.4142 15.25 30.75 15.5858 30.75 16C30.75 16.4142 30.4142 16.75 30 16.75H27.0321L25.4579 21.2478C25.3526 21.5486 25.0687 21.75 24.75 21.75C24.4313 21.75 24.1474 21.5486 24.0421 21.2478L21.25 13.2703L18.4579 21.2478C18.3526 21.5486 18.0687 21.75 17.75 21.75C17.4313 21.75 17.1474 21.5486 17.0421 21.2478L14.25 13.2703L11.4579 21.2478C11.3526 21.5486 11.0687 21.75 10.75 21.75C10.4313 21.75 10.1474 21.5486 10.0421 21.2478L7.25 13.2703L6.20789 16.2478C6.10261 16.5486 5.81871 16.75 5.5 16.75H2C1.58579 16.75 1.25 16.4142 1.25 16C1.25 15.5858 1.58579 15.25 2 15.25H4.96789L6.54211 10.7522C6.64739 10.4514 6.93129 10.25 7.25 10.25Z"
      fill={props.fill}
    />
  </svg>
);

AcausalElectricalResistor.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default AcausalElectricalResistor;
