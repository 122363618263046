/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const DropdownDefault: React.FC<Props> = (props) => (
  <svg viewBox="0 0 24 24" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M7.64645 10.6464C7.84171 10.4512 8.15829 10.4512 8.35355 10.6464L12 14.2929L15.6464 10.6464C15.8417 10.4512 16.1583 10.4512 16.3536 10.6464C16.5488 10.8417 16.5488 11.1583 16.3536 11.3536L12 15.7071L7.64645 11.3536C7.45118 11.1583 7.45118 10.8417 7.64645 10.6464Z"
      fill={props.fill}
    />
  </svg>
);

DropdownDefault.defaultProps = {
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default DropdownDefault;
