import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { Coordinate } from 'app/common_types/Coordinate';

export const MINIMUM_ZOOM = 0.2;
export const MAXIMUM_ZOOM = 5;

interface CameraState {
  zoom: number;
  coord: Coordinate;
  rerenderTransformData: boolean;
}

const initialState: CameraState = {
  zoom: 1,
  coord: { x: 0, y: 0 },
  rerenderTransformData: false,
};

const cameraSlice = createSlice({
  name: 'cameraSlice',
  initialState,
  reducers: {
    setEntireTransform(
      state,
      action: PayloadAction<{
        zoom: number;
        coord: Coordinate;
        rerender: boolean;
      }>,
    ) {
      const { coord, zoom, rerender } = action.payload;

      state.coord = coord;
      state.zoom = Math.min(MAXIMUM_ZOOM, Math.max(MINIMUM_ZOOM, zoom));

      state.rerenderTransformData = rerender;
    },

    unsetRerender(state) {
      state.rerenderTransformData = false;
    },
  },
});

export const cameraActions = cameraSlice.actions;

export default cameraSlice;
