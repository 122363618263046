/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Instrumentation: React.FC<Props> = (props) => (
  <svg viewBox="0 0 24 24" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 5.75C12.1381 5.75 12.25 5.86193 12.25 6V7C12.25 7.13807 12.1381 7.25 12 7.25C11.8619 7.25 11.75 7.13807 11.75 7V6C11.75 5.86193 11.8619 5.75 12 5.75Z"
      fill={props.fill}
    />
    <path
      d="M6.6967 8.09315C6.59907 7.99552 6.44078 7.99552 6.34315 8.09315C6.24552 8.19078 6.24552 8.34907 6.34315 8.4467L7.05026 9.15381C7.14789 9.25144 7.30618 9.25144 7.40381 9.15381C7.50144 9.05618 7.50144 8.89789 7.40381 8.80025L6.6967 8.09315Z"
      fill={props.fill}
    />
    <path
      d="M17.6568 8.09315C17.7545 8.19078 17.7545 8.34907 17.6568 8.4467L16.9497 9.15381C16.8521 9.25144 16.6938 9.25144 16.5962 9.15381C16.4986 9.05618 16.4986 8.89789 16.5962 8.80025L17.3033 8.09315C17.4009 7.99552 17.5592 7.99552 17.6568 8.09315Z"
      fill={props.fill}
    />
    <path
      d="M16 6.8218C16.1196 6.89083 16.1605 7.04373 16.0915 7.1633L15.5915 8.02933C15.5225 8.1489 15.3696 8.18987 15.25 8.12084C15.1304 8.0518 15.0895 7.8989 15.1585 7.77933L15.6585 6.9133C15.7275 6.79373 15.8804 6.75276 16 6.8218Z"
      fill={props.fill}
    />
    <path
      d="M10.2356 6.19937C10.1999 6.066 10.0628 5.98686 9.92944 6.02259C9.79607 6.05833 9.71692 6.19541 9.75266 6.32878L10.0115 7.29471C10.0472 7.42807 10.1843 7.50722 10.3177 7.47148C10.451 7.43575 10.5302 7.29866 10.4944 7.1653L10.2356 6.19937Z"
      fill={props.fill}
    />
    <path
      d="M14.0706 6.02259C14.2039 6.05833 14.2831 6.19541 14.2473 6.32878L13.9885 7.29471C13.9528 7.42807 13.8157 7.50722 13.6823 7.47148C13.549 7.43575 13.4698 7.29866 13.5056 7.1653L13.7644 6.19937C13.8001 6.066 13.9372 5.98686 14.0706 6.02259Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M6 3.75C4.48122 3.75 3.25 4.98122 3.25 6.5V11.5C3.25 13.0188 4.48122 14.25 6 14.25H18C19.5188 14.25 20.75 13.0188 20.75 11.5V6.5C20.75 4.98122 19.5188 3.75 18 3.75H6ZM4.75 6.5C4.75 5.80964 5.30964 5.25 6 5.25H18C18.6904 5.25 19.25 5.80964 19.25 6.5V11.5C19.25 12.1904 18.6904 12.75 18 12.75H15.675C15.3275 11.0383 13.8142 9.75 12 9.75C11.7124 9.75 11.4324 9.78237 11.1634 9.84367L9.41603 7.22265C9.27708 7.01423 9.00534 6.94079 8.78032 7.05084C8.67789 7.10094 8.59953 7.18203 8.55202 7.27792L8.34151 6.9133C8.27247 6.79373 8.11957 6.75276 8 6.8218C7.88043 6.89083 7.83946 7.04373 7.90849 7.1633L8.40849 8.02933C8.46609 8.12909 8.58207 8.17414 8.68836 8.14623L9.53185 10.6767C8.92543 11.2073 8.49145 11.9301 8.32501 12.75H6C5.30964 12.75 4.75 12.1904 4.75 11.5V6.5ZM9.87803 12.75H14.122C13.8131 11.8761 12.9797 11.25 12 11.25C11.0203 11.25 10.1869 11.8761 9.87803 12.75Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M8 14.75C6.48122 14.75 5.25 15.9812 5.25 17.5C5.25 19.0188 6.48122 20.25 8 20.25C9.51878 20.25 10.75 19.0188 10.75 17.5C10.75 15.9812 9.51878 14.75 8 14.75ZM6.75 17.5C6.75 16.8096 7.30964 16.25 8 16.25C8.69036 16.25 9.25 16.8096 9.25 17.5C9.25 18.1904 8.69036 18.75 8 18.75C7.30964 18.75 6.75 18.1904 6.75 17.5Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M13.25 17.5C13.25 15.9812 14.4812 14.75 16 14.75C17.5188 14.75 18.75 15.9812 18.75 17.5C18.75 19.0188 17.5188 20.25 16 20.25C14.4812 20.25 13.25 19.0188 13.25 17.5ZM16 16.25C15.3096 16.25 14.75 16.8096 14.75 17.5C14.75 18.1904 15.3096 18.75 16 18.75C16.6904 18.75 17.25 18.1904 17.25 17.5C17.25 16.8096 16.6904 16.25 16 16.25Z"
      fill={props.fill}
    />
  </svg>
);

Instrumentation.defaultProps = {
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Instrumentation;
