// !!!! -- DO NOT MANUALLY EDIT -- !!!!
// This file was generated by something in scripts/generateDefaultBlockData.js
// If you want to change the contents of this file, it should be automated
//
// (You can edit this file locally, but it may not be checked into the codebase -
// PRs with a manual edit of this file are forbidden from being merged.)

import { computationBlockDefaults } from 'app/generated_blocks/computationBlockDefaults';
import type { ComputationBlockClass } from 'app/generated_types/ComputationBlockClass';
import deepmerge from 'deepmerge';

export const ExtendedKalmanFilter: ComputationBlockClass = deepmerge(
  computationBlockDefaults,
  {
    "base": {
      "name": "ExtendedKalmanFilter",
      "namespace": "core",
      "description": "Extended Kalman Filter for a continuous-time plant",
      "help_url": "https://docs.collimator.ai/using-model-editors/block-library/discrete-blocks#extended-kalman-filter",
      "supports_iterator": false,
      "keywords": [
        "kf"
      ]
    },
    "modes": {
      "time": "discrete"
    },
    "ports": {
      "inputs": {
        "static": [
          {
            "name": "u"
          },
          {
            "name": "y"
          }
        ]
      },
      "outputs": {
        "static": [
          {
            "name": "x_hat"
          }
        ]
      }
    },
    "parameter_definitions": [
      {
        "name": "plant_submodel_uuid",
        "data_type": "reference_submodel",
        "description": "Continuous-time plant to linearize and discretize for Extended Kalman Filter creation",
        "default_value": "",
        "display_name": "Plant Submodel",
        "optional": true
      },
      {
        "name": "discretization_method",
        "description": "Discretization method for the continuous-time plant",
        "data_type": "string",
        "default_value": "euler",
        "optional": true,
        "allowed_values": [
          "euler",
          "zoh"
        ]
      },
      {
        "name": "dt",
        "data_type": "float",
        "description": "Sampling rate for discretization of the continuous-time plant",
        "optional": true,
        "default_value": ""
      },
      {
        "name": "discretized_noise",
        "display_name": "Provided noise statistics are discretized?",
        "description": "Check this box if the G, Q, and R matrices are already discretized",
        "data_type": "bool",
        "default_value": "false"
      },
      {
        "name": "G",
        "data_type": "any",
        "default_value": "",
        "optional": true,
        "description": "Process noise matrix G for the plant"
      },
      {
        "name": "Q",
        "data_type": "any",
        "default_value": "",
        "optional": true,
        "description": "Process noise covariance matrix Q for the plant"
      },
      {
        "name": "R",
        "data_type": "any",
        "default_value": "",
        "optional": true,
        "description": "Measurement noise covariance matrix R for the plant"
      },
      {
        "name": "x_hat_0",
        "data_type": "float",
        "default_value": "",
        "optional": true,
        "description": "Initial value for the state vector estimate"
      },
      {
        "name": "P_hat_0",
        "data_type": "float",
        "default_value": "",
        "optional": true,
        "description": "Initial value for covariance of the state vector estimate"
      }
    ]
  }
);
