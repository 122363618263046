/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Conditional: React.FC<Props> = (props) => (
  <svg viewBox="0 0 16 16" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.99999 7.16665C4.99999 6.98256 5.14923 6.83332 5.33333 6.83332H7.33333C7.51742 6.83332 7.66666 6.98256 7.66666 7.16665V8.83332C7.66666 9.01742 7.51742 9.16665 7.33333 9.16665H5.33333C5.14923 9.16665 4.99999 9.01742 4.99999 8.83332V7.16665Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M8.33333 7.16665C8.33333 6.98256 8.48256 6.83332 8.66666 6.83332H10.6667C10.8508 6.83332 11 6.98256 11 7.16665V8.83332C11 9.01742 10.8508 9.16665 10.6667 9.16665H8.66666C8.48256 9.16665 8.33333 9.01742 8.33333 8.83332V7.16665ZM8.99999 8.49999V7.49999H10.3333V8.49999H8.99999Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M2.66666 3.33332C2.66666 2.96513 2.96513 2.66666 3.33332 2.66666H12.6667C13.0348 2.66666 13.3333 2.96513 13.3333 3.33332V12.6667C13.3333 13.0348 13.0348 13.3333 12.6667 13.3333H3.33332C2.96513 13.3333 2.66666 13.0348 2.66666 12.6667V3.33332ZM3.66666 12.3333V3.66666H12.3333V12.3333H3.66666Z"
      fill={props.fill}
    />
  </svg>
);

Conditional.defaultProps = {
  width: '16',
  height: '16',
  viewBox: '0 0 16 16',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Conditional;
