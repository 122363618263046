/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Replicator: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M12 19H14.25V22C14.25 22.6904 14.8096 23.25 15.5 23.25H22.75C23.4404 23.25 24 22.6904 24 22V16C24 15.3096 23.4404 14.75 22.75 14.75H19.25V12.75C19.25 12.0596 18.6904 11.5 18 11.5H15V9.75C15 9.05964 14.4404 8.5 13.75 8.5H9.25C8.55964 8.5 8 9.05964 8 9.75V13.5C8 14.1904 8.55964 14.75 9.25 14.75H10.75V17.75C10.75 18.4404 11.3096 19 12 19ZM15.5 14.75C14.8096 14.75 14.25 15.3096 14.25 16V17.5H12.25V13H17.75V14.75H15.5ZM10.75 13.25V12.75C10.75 12.0596 11.3096 11.5 12 11.5H13.5V10H9.5V13.25H10.75ZM15.75 16.25H22.5V21.75H15.75V16.25Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M5.5 4C4.67157 4 4 4.67157 4 5.5V26.5C4 27.3284 4.67157 28 5.5 28H26.5C27.3284 28 28 27.3284 28 26.5V5.5C28 4.67157 27.3284 4 26.5 4H5.5ZM26.5 5.5H5.5V26.5H26.5V5.5Z"
      fill={props.fill}
    />
  </svg>
);

Replicator.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Replicator;
