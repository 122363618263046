/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Continuous: React.FC<Props> = (props) => (
  <svg viewBox="0 0 24 24" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M5.41517 11.4233C5.35038 11.6493 5.28555 11.8755 5.21966 12.1C5.10301 12.4974 4.68626 12.7251 4.28881 12.6084C3.89136 12.4918 3.66372 12.075 3.78036 11.6776C3.83934 11.4766 3.89931 11.2668 3.96097 11.0511C4.27697 9.94543 4.63761 8.68363 5.13848 7.65717C5.73003 6.44489 6.66961 5.25 8.25001 5.25C9.83214 5.25 10.7714 6.44706 11.3631 7.67423C11.8782 8.74258 12.2435 10.0664 12.5666 11.2372C12.6194 11.4284 12.671 11.6156 12.722 11.7968C12.7771 11.9926 12.8315 12.1891 12.8858 12.3854C13.213 13.5676 13.5372 14.7389 13.9881 15.6742C14.5214 16.7804 15.0822 17.25 15.75 17.25C16.4161 17.25 16.9772 16.7826 17.5104 15.6634C17.9751 14.6882 18.3064 13.4485 18.6434 12.1877C18.6874 12.0231 18.7314 11.8581 18.7759 11.6933C18.8839 11.2934 19.2956 11.0567 19.6955 11.1647C20.0954 11.2727 20.3321 11.6844 20.2241 12.0843C20.1811 12.2435 20.1377 12.4064 20.0936 12.5719C19.7634 13.8108 19.3936 15.1984 18.8646 16.3086C18.2728 17.5507 17.3339 18.75 15.75 18.75C14.1679 18.75 13.2286 17.5528 12.6369 16.3256C12.1219 15.2573 11.7565 13.9335 11.4335 12.7627C11.3807 12.5715 11.329 12.3843 11.278 12.203C11.223 12.0072 11.1686 11.8108 11.1143 11.6146C10.7871 10.4323 10.4629 9.26097 10.0119 8.32569C9.47862 7.21957 8.91788 6.75 8.25001 6.75C7.58041 6.75 7.02 7.22175 6.48655 8.31498C6.04937 9.2109 5.73269 10.3156 5.41517 11.4233Z"
      fill={props.fill}
    />
  </svg>
);

Continuous.defaultProps = {
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Continuous;
