/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  className?: string;
}

const ComparatorLtgt: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.08391 20.5C9.00391 20.5 8.95191 20.46 8.92791 20.38L8.53191 19.096C8.51591 19.064 8.4959 19.048 8.4719 19.048H5.3039C5.2799 19.048 5.2599 19.064 5.2439 19.096L4.8479 20.38C4.8239 20.46 4.7719 20.5 4.6919 20.5H3.4919C3.4439 20.5 3.4079 20.488 3.3839 20.464C3.3599 20.432 3.3559 20.388 3.3719 20.332L5.9759 12.22C5.9999 12.14 6.0519 12.1 6.1319 12.1H7.6319C7.7119 12.1 7.7639 12.14 7.7879 12.22L10.4039 20.332C10.4119 20.348 10.4159 20.368 10.4159 20.392C10.4159 20.464 10.3719 20.5 10.2839 20.5H9.08391ZM5.6159 17.884C5.6079 17.932 5.6239 17.956 5.6639 17.956H8.09991C8.14791 17.956 8.16391 17.932 8.1479 17.884L6.9119 13.864C6.9039 13.832 6.8919 13.816 6.8759 13.816C6.8599 13.816 6.8479 13.832 6.8399 13.864L5.6159 17.884Z"
      fill={props.fill}
    />
    <path
      d="M18.6795 15.964C18.6795 16.004 18.6635 16.04 18.6315 16.072C18.6075 16.096 18.5755 16.108 18.5355 16.108H16.8555C16.8235 16.108 16.7995 16.124 16.7835 16.156L16.3035 17.224C16.2955 17.24 16.2955 17.256 16.3035 17.272C16.3115 17.288 16.3235 17.296 16.3395 17.296H18.5355C18.5755 17.296 18.6075 17.312 18.6315 17.344C18.6635 17.368 18.6795 17.4 18.6795 17.44V18.34C18.6795 18.38 18.6635 18.416 18.6315 18.448C18.6075 18.472 18.5755 18.484 18.5355 18.484H15.7755C15.7435 18.484 15.7195 18.5 15.7035 18.532L15.2595 19.504C15.2275 19.576 15.1715 19.612 15.0915 19.612H14.5275C14.4715 19.612 14.4315 19.6 14.4075 19.576C14.3915 19.544 14.3955 19.5 14.4195 19.444L14.8275 18.556C14.8355 18.54 14.8355 18.524 14.8275 18.508C14.8195 18.492 14.8075 18.484 14.7915 18.484H13.7115C13.6715 18.484 13.6355 18.472 13.6035 18.448C13.5795 18.416 13.5675 18.38 13.5675 18.34V17.44C13.5675 17.4 13.5795 17.368 13.6035 17.344C13.6355 17.312 13.6715 17.296 13.7115 17.296H15.3555C15.3875 17.296 15.4115 17.28 15.4275 17.248L15.9075 16.18C15.9155 16.164 15.9155 16.148 15.9075 16.132C15.8995 16.116 15.8875 16.108 15.8715 16.108H13.7115C13.6715 16.108 13.6355 16.096 13.6035 16.072C13.5795 16.04 13.5675 16.004 13.5675 15.964V15.064C13.5675 15.024 13.5795 14.992 13.6035 14.968C13.6355 14.936 13.6715 14.92 13.7115 14.92H16.4355C16.4675 14.92 16.4915 14.904 16.5075 14.872L16.9635 13.852C16.9955 13.78 17.0515 13.744 17.1315 13.744H17.6955C17.7515 13.744 17.7875 13.76 17.8035 13.792C17.8275 13.816 17.8275 13.856 17.8035 13.912L17.3835 14.848C17.3755 14.864 17.3755 14.88 17.3835 14.896C17.3915 14.912 17.4035 14.92 17.4195 14.92H18.5355C18.5755 14.92 18.6075 14.936 18.6315 14.968C18.6635 14.992 18.6795 15.024 18.6795 15.064V15.964Z"
      fill={props.fill}
    />
    <path
      d="M27.0484 16.12C27.0004 16.144 27.0004 16.168 27.0484 16.192C27.8644 16.576 28.2724 17.236 28.2724 18.172C28.2724 18.932 28.0164 19.512 27.5044 19.912C27.0004 20.304 26.3404 20.5 25.5244 20.5H22.5004C22.4604 20.5 22.4244 20.488 22.3924 20.464C22.3684 20.432 22.3564 20.396 22.3564 20.356V12.244C22.3564 12.204 22.3684 12.172 22.3924 12.148C22.4244 12.116 22.4604 12.1 22.5004 12.1H25.4284C26.3004 12.1 26.9724 12.288 27.4444 12.664C27.9164 13.04 28.1524 13.608 28.1524 14.368C28.1524 15.208 27.7844 15.792 27.0484 16.12ZM23.8084 13.3C23.7684 13.3 23.7484 13.32 23.7484 13.36V15.58C23.7484 15.62 23.7684 15.64 23.8084 15.64H25.4284C25.8524 15.64 26.1844 15.54 26.4244 15.34C26.6724 15.132 26.7964 14.848 26.7964 14.488C26.7964 14.12 26.6724 13.832 26.4244 13.624C26.1844 13.408 25.8524 13.3 25.4284 13.3H23.8084ZM25.5244 19.288C25.9404 19.288 26.2684 19.176 26.5084 18.952C26.7564 18.728 26.8804 18.424 26.8804 18.04C26.8804 17.648 26.7564 17.344 26.5084 17.128C26.2604 16.904 25.9244 16.792 25.5004 16.792H23.8084C23.7684 16.792 23.7484 16.812 23.7484 16.852V19.228C23.7484 19.268 23.7684 19.288 23.8084 19.288H25.5244Z"
      fill={props.fill}
    />
  </svg>
);

ComparatorLtgt.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  className: '',
};

export default ComparatorLtgt;
