/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  className?: string;
}

const EdgedetectorFalling: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4 9.25C3.58579 9.25 3.25 9.58579 3.25 10C3.25 10.4142 3.58579 10.75 4 10.75H15.25V16.1893L13.5303 14.4697C13.2374 14.1768 12.7626 14.1768 12.4697 14.4697C12.1768 14.7626 12.1768 15.2374 12.4697 15.5303L15.1161 18.1768C15.1586 18.2192 15.2033 18.258 15.25 18.2931V21.5C15.25 22.1904 15.8096 22.75 16.5 22.75H28C28.4142 22.75 28.75 22.4142 28.75 22C28.75 21.5858 28.4142 21.25 28 21.25H16.75V18.2931C16.7967 18.258 16.8414 18.2192 16.8839 18.1768L19.5303 15.5303C19.8232 15.2374 19.8232 14.7626 19.5303 14.4697C19.2374 14.1768 18.7626 14.1768 18.4697 14.4697L16.75 16.1893V10.5C16.75 9.80964 16.1904 9.25 15.5 9.25H4Z"
      fill={props.fill}
    />
  </svg>
);

EdgedetectorFalling.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  className: '',
};

export default EdgedetectorFalling;
