/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const DiscretepidPd: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1 4.75C1.13807 4.75 1.25 4.86193 1.25 5V25.7927C1.32819 25.765 1.41234 25.75 1.5 25.75H2.75V24.75C2.75 24.1977 3.19772 23.75 3.75 23.75H4.75V11.75C4.75 11.1977 5.19771 10.75 5.75 10.75H6.75V6.25C6.75 5.69772 7.19771 5.25 7.75 5.25H9.25C9.80229 5.25 10.25 5.69772 10.25 6.25H11.25C11.8023 6.25 12.25 6.69772 12.25 7.25V8.75H13.25C13.8023 8.75 14.25 9.19772 14.25 9.75H14.75C14.75 9.19772 15.1977 8.75 15.75 8.75H16.8838C17.0567 8.4511 17.3799 8.25 17.75 8.25H30C30.4142 8.25 30.75 8.58579 30.75 9C30.75 9.41421 30.4142 9.75 30 9.75H18.1162C17.9433 10.0489 17.6201 10.25 17.25 10.25H16.25C16.25 10.8023 15.8023 11.25 15.25 11.25H13.75C13.1977 11.25 12.75 10.8023 12.75 10.25H11.75C11.1977 10.25 10.75 9.80228 10.75 9.25V7.75H9.75C9.19772 7.75 8.75 7.30228 8.75 6.75H8.25V11.25C8.25 11.8023 7.80228 12.25 7.25 12.25H6.25V24.25C6.25 24.8023 5.80228 25.25 5.25 25.25H4.25V26.25C4.25 26.4321 4.2013 26.6029 4.11622 26.75H31C31.1381 26.75 31.25 26.8619 31.25 27C31.25 27.1381 31.1381 27.25 31 27.25H0.75V5C0.75 4.86193 0.861929 4.75 1 4.75Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M16.4707 15.588C16.9747 15.588 17.4187 15.692 17.8027 15.9C18.1867 16.108 18.4827 16.404 18.6907 16.788C18.9067 17.164 19.0147 17.6 19.0147 18.096C19.0147 18.584 18.9067 19.016 18.6907 19.392C18.4747 19.768 18.1667 20.06 17.7667 20.268C17.3747 20.468 16.9187 20.568 16.3987 20.568H14.4787C14.4387 20.568 14.4187 20.588 14.4187 20.628V23.856C14.4187 23.896 14.4027 23.932 14.3707 23.964C14.3467 23.988 14.3147 24 14.2747 24H13.1707C13.1307 24 13.0947 23.988 13.0627 23.964C13.0387 23.932 13.0267 23.896 13.0267 23.856V15.732C13.0267 15.692 13.0387 15.66 13.0627 15.636C13.0947 15.604 13.1307 15.588 13.1707 15.588H16.4707ZM16.2667 19.44C16.6747 19.44 17.0027 19.32 17.2507 19.08C17.4987 18.84 17.6227 18.524 17.6227 18.132C17.6227 17.732 17.4987 17.408 17.2507 17.16C17.0027 16.912 16.6747 16.788 16.2667 16.788H14.4787C14.4387 16.788 14.4187 16.808 14.4187 16.848V19.38C14.4187 19.42 14.4387 19.44 14.4787 19.44H16.2667Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M20.3794 24C20.3394 24 20.3034 23.988 20.2714 23.964C20.2474 23.932 20.2354 23.896 20.2354 23.856V15.744C20.2354 15.704 20.2474 15.672 20.2714 15.648C20.3034 15.616 20.3394 15.6 20.3794 15.6H23.3074C23.8754 15.6 24.3754 15.7 24.8074 15.9C25.2394 16.1 25.5714 16.388 25.8034 16.764C26.0434 17.132 26.1634 17.564 26.1634 18.06V21.54C26.1634 22.036 26.0434 22.472 25.8034 22.848C25.5714 23.216 25.2394 23.5 24.8074 23.7C24.3754 23.9 23.8754 24 23.3074 24H20.3794ZM21.6274 22.74C21.6274 22.78 21.6474 22.8 21.6874 22.8H23.3674C23.7914 22.8 24.1274 22.668 24.3754 22.404C24.6314 22.132 24.7634 21.772 24.7714 21.324V18.288C24.7714 17.832 24.6434 17.472 24.3874 17.208C24.1314 16.936 23.7874 16.8 23.3554 16.8H21.6874C21.6474 16.8 21.6274 16.82 21.6274 16.86V22.74Z"
      fill={props.fill}
    />
  </svg>
);

DiscretepidPd.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default DiscretepidPd;
