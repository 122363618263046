import styled from '@emotion/styled';
import { useAppDispatch } from 'app/hooks';
import { dataExplorerActions } from 'app/slices/dataExplorerSlice';
import { TraceDragSource } from 'app/slices/traceDragSlice';
import {
  RowDropZoneGrid,
  TraceDropZoneBase,
} from 'ui/dataExplorer/drag/DropZoneCommon';

const TraceDragDropZoneTop = styled(TraceDropZoneBase)({
  gridArea: 'top',
});
const TraceDragDropZoneBottom = styled(TraceDropZoneBase)({
  gridArea: 'bottom',
});

type Props = {
  traceDragSource: TraceDragSource;
  targetRowId: string;
};

/**
 * Trace drop zones that represent new rows.
 */
const TraceDragRowDropZones = ({ targetRowId, traceDragSource }: Props) => {
  const dispatch = useAppDispatch();

  return (
    <RowDropZoneGrid>
      <TraceDragDropZoneTop
        onMouseUp={() => {
          dispatch(
            dataExplorerActions.moveTraceToNewRowBefore({
              targetRowId,
              traceId: traceDragSource.traceId,
            }),
          );
        }}
      />
      <TraceDragDropZoneBottom
        onMouseUp={() => {
          dispatch(
            dataExplorerActions.moveTraceToNewRowAfter({
              targetRowId,
              traceId: traceDragSource.traceId,
            }),
          );
        }}
      />
    </RowDropZoneGrid>
  );
};

export default TraceDragRowDropZones;
