/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const DiscretepidPi: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1 4.75C1.13807 4.75 1.25 4.86193 1.25 5V25.7927C1.32819 25.765 1.41234 25.75 1.5 25.75H2.75V24.75C2.75 24.1977 3.19772 23.75 3.75 23.75H4.75V11.75C4.75 11.1977 5.19771 10.75 5.75 10.75H6.75V6.25C6.75 5.69772 7.19771 5.25 7.75 5.25H9.25C9.80229 5.25 10.25 5.69772 10.25 6.25H11.25C11.8023 6.25 12.25 6.69772 12.25 7.25V8.75H13.25C13.8023 8.75 14.25 9.19772 14.25 9.75H14.75C14.75 9.19772 15.1977 8.75 15.75 8.75H16.8838C17.0567 8.4511 17.3799 8.25 17.75 8.25H30C30.4142 8.25 30.75 8.58579 30.75 9C30.75 9.41421 30.4142 9.75 30 9.75H18.1162C17.9433 10.0489 17.6201 10.25 17.25 10.25H16.25C16.25 10.8023 15.8023 11.25 15.25 11.25H13.75C13.1977 11.25 12.75 10.8023 12.75 10.25H11.75C11.1977 10.25 10.75 9.80228 10.75 9.25V7.75H9.75C9.19772 7.75 8.75 7.30228 8.75 6.75H8.25V11.25C8.25 11.8023 7.80228 12.25 7.25 12.25H6.25V24.25C6.25 24.8023 5.80228 25.25 5.25 25.25H4.25V26.25C4.25 26.4321 4.2013 26.6029 4.11622 26.75H31C31.1381 26.75 31.25 26.8619 31.25 27C31.25 27.1381 31.1381 27.25 31 27.25H0.75V5C0.75 4.86193 0.861929 4.75 1 4.75Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M18.6093 15.588C19.1133 15.588 19.5573 15.692 19.9413 15.9C20.3253 16.108 20.6213 16.404 20.8293 16.788C21.0453 17.164 21.1533 17.6 21.1533 18.096C21.1533 18.584 21.0453 19.016 20.8293 19.392C20.6133 19.768 20.3053 20.06 19.9053 20.268C19.5133 20.468 19.0573 20.568 18.5373 20.568H16.6173C16.5773 20.568 16.5573 20.588 16.5573 20.628V23.856C16.5573 23.896 16.5413 23.932 16.5093 23.964C16.4853 23.988 16.4533 24 16.4133 24H15.3093C15.2693 24 15.2333 23.988 15.2013 23.964C15.1773 23.932 15.1653 23.896 15.1653 23.856V15.732C15.1653 15.692 15.1773 15.66 15.2013 15.636C15.2333 15.604 15.2693 15.588 15.3093 15.588H18.6093ZM18.4053 19.44C18.8133 19.44 19.1413 19.32 19.3893 19.08C19.6373 18.84 19.7613 18.524 19.7613 18.132C19.7613 17.732 19.6373 17.408 19.3893 17.16C19.1413 16.912 18.8133 16.788 18.4053 16.788H16.6173C16.5773 16.788 16.5573 16.808 16.5573 16.848V19.38C16.5573 19.42 16.5773 19.44 16.6173 19.44H18.4053Z"
      fill={props.fill}
    />
    <path
      d="M22.518 24C22.478 24 22.442 23.988 22.41 23.964C22.386 23.932 22.374 23.896 22.374 23.856V15.744C22.374 15.704 22.386 15.672 22.41 15.648C22.442 15.616 22.478 15.6 22.518 15.6H23.622C23.662 15.6 23.694 15.616 23.718 15.648C23.75 15.672 23.766 15.704 23.766 15.744V23.856C23.766 23.896 23.75 23.932 23.718 23.964C23.694 23.988 23.662 24 23.622 24H22.518Z"
      fill={props.fill}
    />
  </svg>
);

DiscretepidPi.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default DiscretepidPi;
