import { Trans } from '@lingui/macro';
import { Project } from 'app/apiTransformers/convertAPIProjectToProject';
import { useAppSelector } from 'app/hooks';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Model as ModelIcon,
  Submodel as SubmodelIcon,
} from 'ui/common/Icons/Standard';
import ListItem from './ListItem';
import ListTitle from './ListTitle';

interface Props {
  project: Project;
  triggerClose: () => void;
}

const Models: React.FC<Props> = ({ project, triggerClose }) => {
  const navigate = useNavigate();
  const submodels = useAppSelector(
    (state) =>
      state.submodels?.projectIdToSubmodelInfoLites[project.uuid] || [],
  );

  return (
    <>
      <ListTitle>
        <Trans id="browser.modelsTitle">Models</Trans>
      </ListTitle>
      {project.models.map((m) => (
        <ListItem
          key={m.uuid}
          Icon={ModelIcon}
          isActive={
            location.pathname === `/projects/${project.uuid}/models/${m.uuid}`
          }
          onClick={() => {
            navigate(`/projects/${project.uuid}/models/${m.uuid}`);
            triggerClose();
          }}>
          {m.name}
        </ListItem>
      ))}
      {submodels.map((sm) => (
        <ListItem
          key={sm.id}
          Icon={SubmodelIcon}
          isActive={
            location.pathname === `/projects/${project.uuid}/models/${sm.id}`
          }
          onClick={() => {
            navigate(`/projects/${project.uuid}/models/${sm.id}`);
            triggerClose();
          }}>
          {sm.name}
        </ListItem>
      ))}
    </>
  );
};

export default Models;
