import { t } from '@lingui/macro';
import {
  useGetSimulationProgressByUuidQuery,
  useGetSimulationReadByUuidQuery,
} from 'app/apiGenerated/generatedApi';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { notificationsActions } from 'app/slices/notificationsSlice';
import { ModelSimulationState, projectActions } from 'app/slices/projectSlice';
import React from 'react';
import { useNotifications } from 'ui/common/notifications/useNotifications';

const SIMULATION_POLL_INTERVAL = 500;

export function useModelSimulationStatus(
  modelId: string,
  correlationId: string,
  simulationId: string,
) {
  const dispatch = useAppDispatch();

  const forceRefetchSimulationSummary = useAppSelector(
    (state) => state.project.forceRefetchSimulationSummary,
  );
  const storedSimulationSummary = useAppSelector(
    (state) => state.project.simulationSummary,
  );
  const modelSimulationState = useAppSelector(
    (state) => state.project.modelSimulationState,
  );

  const { showError } = useNotifications();

  const { data: simulationProgress } = useGetSimulationProgressByUuidQuery(
    {
      modelUuid: modelId,
      simulationUuid: simulationId,
      'X-Correlation-ID': correlationId,
    },
    {
      pollingInterval: SIMULATION_POLL_INTERVAL,
      skip:
        !storedSimulationSummary ||
        storedSimulationSummary.status !== 'simulation_in_progress',
    },
  );

  const timerIdRef = React.useRef<any>(null);

  const {
    data: simulationSummary,
    error,
    refetch,
    isFetching,
  } = useGetSimulationReadByUuidQuery({
    modelUuid: modelId,
    simulationUuid: simulationId,
    'X-Correlation-ID': correlationId,
  });

  // Update the redux state when we get a new simulation summary result.
  // If the simulation hasn't yet completed, trigger another summary update for later.
  React.useEffect(() => {
    if (
      !isFetching &&
      simulationSummary &&
      storedSimulationSummary?.status !== 'completed'
    ) {
      if (storedSimulationSummary)
        dispatch(projectActions.simulationSummaryUpdated(simulationSummary));

      switch (simulationSummary.status) {
        case 'completed':
        case 'cancelled':
        case 'failed':
          // Don't need to poll for further updates.
          break;
        case 'pending':
        case 'waiting_for_resources':
        case 'compilation_in_progress':
        case 'simulation_in_progress':
          if (timerIdRef.current) {
            clearTimeout(timerIdRef.current);
          }

          timerIdRef.current = setTimeout(() => {
            dispatch(projectActions.requestRefetchSimulationSummary());
          }, SIMULATION_POLL_INTERVAL);
          break;
        case 'created':
          // TODO figure out what to do in this case.
          // This is meant to be used from the notebook and
          // is not used yet.
          break;
      }
    }
  }, [
    dispatch,
    simulationSummary,
    correlationId,
    modelId,
    simulationId,
    isFetching,
    storedSimulationSummary,
  ]);

  // Update the redux state with error status if we are unable to get a simulation summary result.
  React.useEffect(() => {
    if (!isFetching && error) {
      showError(
        t({
          id: 'modelsApi.failedToRetrieveLatestSimulationStatus',
          message: 'Failed to get simulation status.',
        }),
        error,
      );
      dispatch(projectActions.checkOrRunCancelled(correlationId));
    }
  }, [dispatch, error, isFetching, showError, correlationId]);

  // This effect's lifetime matches the lifetime of the component
  // to ensure the timer is cleaned up properly.
  React.useEffect(
    () => () => {
      if (timerIdRef.current) {
        clearTimeout(timerIdRef.current);
      }
      dispatch(notificationsActions.clearProgressBar());
    },
    [dispatch],
  );

  React.useEffect(() => {
    if (!storedSimulationSummary) {
      dispatch(notificationsActions.clearProgressBar());
      return;
    }

    if (
      storedSimulationSummary.status === 'simulation_in_progress' &&
      simulationProgress?.current_time !== undefined &&
      simulationProgress?.target_time &&
      modelSimulationState === ModelSimulationState.MonitoringRun
    ) {
      dispatch(
        notificationsActions.setProgressBar({
          elapsed: simulationProgress?.current_time,
          total: simulationProgress?.target_time,
          startText: t({
            id: 'modelSimulationStatus.progressBar.startText',
            message: 'Elapsed',
          }),
          endText: t({
            id: 'modelSimulationStatus.progressBar.endText',
            message: 'Simulation end time',
          }),
        }),
      );
    }
  }, [
    dispatch,
    storedSimulationSummary,
    modelSimulationState,
    simulationProgress?.current_time,
    simulationProgress?.target_time,
  ]);

  // Watch for requests to fetch summary updates and then call the update API.
  React.useEffect(() => {
    if (forceRefetchSimulationSummary) {
      refetch();
      dispatch(projectActions.clearRequestSimulationSummary());
    }
  }, [dispatch, forceRefetchSimulationSummary, refetch]);
}
