import { DiagramVersion } from 'app/apiTransformers/convertGetSnapshotReadAll';
import React from 'react';
import { getUserDisplayName, getUserProfileImageUrl } from 'ui/auth/utils';
import Button from 'ui/common/Button/Button';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import MenuIcon from 'ui/common/Icons/Standard/Menu';
import { validNameRules } from 'ui/common/Input/inputValidation';
import Menu from 'ui/common/Menu/Menu';
import DiagramVersionIcon from 'ui/versionHistory/DiagramVersionIcon';
import {
  AuthorAvatar,
  AuthorAvatarContainer,
  VersionAuthor,
  VersionDate,
  VersionInfoSection,
  VersionMenuContainer,
  VersionMetadata,
  VersionName,
  VersionNameContainer,
  VersionNameInputTall,
  VersionSegment,
} from 'ui/versionHistory/DiagramVersionItemParts';
import { formatVersionTimestamp } from 'util/dateUtils';
import { useVersionItemMenu } from './useVersionItemMenu';

interface Props {
  version: DiagramVersion;
  bookmarkVersion?: (
    modelUuid: string,
    editId: string | number,
    newVersionName: string,
  ) => void;
  renameVersion?: (
    modelUuid: string,
    versionUuid: string,
    newVersionName: string,
  ) => void;
  restoreVersion?: (modelUuid: string, versionUuid: string) => void;
  isWorkingVersion?: boolean;
  canEditModelMetadata: boolean;
  projectId: string;
  urlModelId: string;
  urlVersionId?: string;
}

const DiagramVersionItem: React.FC<Props> = ({
  version,
  bookmarkVersion,
  renameVersion,
  restoreVersion,
  isWorkingVersion,
  canEditModelMetadata,
  projectId,
  urlModelId,
  urlVersionId,
}) => {
  const userDisplayName = getUserDisplayName();
  const userProfileImageUrl = getUserProfileImageUrl();

  const {
    menuItems,
    isVersionSelected,
    navigateToItem,
    endRename,
    setLatestVersionName,
    startRename,
    latestVersionName,
    isRenaming,
  } = useVersionItemMenu({
    thisModelId: version.model_uuid,
    thisVersionId: version.uuid,
    urlProjectId: projectId,
    urlModelId,
    urlVersionId,
    isWorkingVersion: !!isWorkingVersion,
    initialVersionName: version.name,
    canEditModelMetadata,
    restoreVersion,
  });

  const commitRename = (newVersionName: string) => {
    if (
      canEditModelMetadata &&
      newVersionName &&
      newVersionName !== version.name
    ) {
      setLatestVersionName(newVersionName);
      if (renameVersion) {
        renameVersion(version.model_uuid, version.uuid, newVersionName);
      } else if (bookmarkVersion) {
        bookmarkVersion(version.model_uuid, version.editId, newVersionName);
      }
    }
    endRename();
  };

  const [isHovered, setIsHovered] = React.useState(false);
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);

  return (
    <VersionSegment
      isSelected={isVersionSelected}
      onClick={navigateToItem}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}>
      {/* Version metadata section */}
      <VersionInfoSection>
        <DiagramVersionIcon source={version.source} />
        <AuthorAvatarContainer>
          <AuthorAvatar
            src={version.authorProfileImageUrl || userProfileImageUrl}
          />
        </AuthorAvatarContainer>

        <VersionMetadata>
          <VersionNameContainer onDoubleClick={startRename}>
            {isRenaming && (
              <VersionNameInputTall
                hasBorder
                autoFocus
                onSubmitValue={commitRename}
                onCancel={endRename}
                value={latestVersionName}
                validationRules={validNameRules}
              />
            )}
            <VersionName
              isWorkingVersion={isWorkingVersion}
              isRenaming={isRenaming}>
              {latestVersionName}
            </VersionName>
          </VersionNameContainer>
          <VersionAuthor>
            {version.authorDisplayName || userDisplayName}
          </VersionAuthor>
          <VersionDate>{formatVersionTimestamp(version.createdAt)}</VersionDate>
        </VersionMetadata>
        <VersionMenuContainer>
          {canEditModelMetadata &&
            (isHovered || isVersionSelected || isMenuOpen) && (
              <Menu
                items={menuItems}
                onClose={() => setIsMenuOpen(false)}
                onActive={setIsMenuOpen}>
                <Button
                  Icon={MenuIcon}
                  variant={ButtonVariants.LargeTertiary}
                />
              </Menu>
            )}
        </VersionMenuContainer>
      </VersionInfoSection>
    </VersionSegment>
  );
};

export default DiagramVersionItem;
