import React from 'react';
import styled from '@emotion/styled/macro';
import { t } from '@lingui/macro';
import { ActionButtonContainer } from 'ui/common/Modal/Modal';
import Button from 'ui/common/Button/Button';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import { useModal } from 'ui/common/Modal/useModal';
import { Remove } from 'ui/common/Icons/Standard';
import Label from 'ui/common/Label';
import { Checkbox } from 'ui/common/Checkbox';
import { useAppParams } from 'util/useAppParams';

const DeleteBranchContainer = styled.div`
  width: 350px;
`;

const RemoteDeleteWrapper = styled.div`
  display: flex;
  justify-content: space-bewteen;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing.xlarge};

  label {
    color: ${({ theme }) => theme.colors.text.secondary};
  }
`;

const DeleteWarning = styled.div`
  line-height: 1.5em;
  margin-bottom: ${({ theme }) => theme.spacing.large};
`;

export const GitDeleteBranchModal = () => {
  const { closeModal } = useModal();

  const [deleting, setDeleting] = React.useState(false);
  const [remoteDelete, setRemoteDelete] = React.useState<boolean>(false);

  const { projectId } = useAppParams();

  const onDelete = () => {
    setDeleting(true);

    const payload = {
      projectId,
      remoteDelete,
    };

    // NOTE: should be replaced with API call to delete the current branch
    setTimeout(() => {
      setDeleting(false);
      closeModal();
    }, 1000);
  };

  return (
    <DeleteBranchContainer>
      <DeleteWarning>
        {t({
          id: 'gitDeleteBranchModal.warning',
          message:
            'Are you sure you want to delete this branch? All files in this project will be deleted, and any changes will be lost.',
        })}
      </DeleteWarning>
      <RemoteDeleteWrapper>
        <Label>
          {t({
            id: 'gitDeleteBranchModal.remoteDelete.label',
            message: 'Also delete branch on remote',
          })}
        </Label>
        <Checkbox value={remoteDelete} onChange={setRemoteDelete} />
      </RemoteDeleteWrapper>
      <ActionButtonContainer>
        <Button
          type="button"
          onClick={closeModal}
          variant={ButtonVariants.LargeSecondary}>
          {t({
            id: 'gitDeleteBranchModal.cancelButton.label',
            message: 'Cancel',
          })}
        </Button>
        <Button
          type="submit"
          Icon={Remove}
          variant={ButtonVariants.Danger}
          disabled={deleting}
          onClick={() => onDelete()}>
          {t({
            id: 'gitDeleteBranchModal.checkoutButton.label',
            message: 'Delete branch',
          })}
        </Button>
      </ActionButtonContainer>
    </DeleteBranchContainer>
  );
};
