import React from 'react';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { useGetDataIntegrationsObjectsReadAllQuery } from 'app/apiGenerated/generatedApi';
import { projectActions } from 'app/slices/projectSlice';

export function useDataIntegrationObjects(
  projectUuid?: string,
  dataIntegrationUuid?: string,
) {
  const dispatch = useAppDispatch();
  const forceReloadDataIntegrationObjects = useAppSelector(
    (state) => state.project.forceReloadDataIntegrationObjects,
  );

  const {
    data: integrationObjects,
    refetch,
    isLoading: integrationObjectsIsLoading,
  } = useGetDataIntegrationsObjectsReadAllQuery(
    {
      projectUuid: projectUuid || '',
      dataIntegrationUuid: dataIntegrationUuid || '',
    },
    {
      skip: !projectUuid || !dataIntegrationUuid,
    },
  );

  React.useEffect(() => {
    if (
      projectUuid ||
      dataIntegrationUuid ||
      forceReloadDataIntegrationObjects
    ) {
      refetch();
      dispatch(projectActions.clearRequestRefetchDataIntegrationObjects());
    }
  }, [
    projectUuid,
    dataIntegrationUuid,
    refetch,
    dispatch,
    forceReloadDataIntegrationObjects,
  ]);

  return {
    integrationObjects,
    integrationObjectsIsLoading,
  };
}
