import { useGetProjectReadByUuidQuery } from 'app/enhancedApi';
import { useAppParams } from 'util/useAppParams';

interface Props {
  refetchOnMountOrArgChange?: boolean;
  projectId?: string;
}

// Hook to get a single project, with detailed information.
// See also: useProjects() and useProjectOrRedirect().
// Why introduce this one more hook? Because there is too much coupling with
// useProjects() and useProjectOrRedirect() has side effects.
export function useProject({
  refetchOnMountOrArgChange,
  projectId,
}: Props = {}) {
  const { projectId: currentProject } = useAppParams();

  const {
    data: project,
    isLoading: isLoadingProject,
    isFetching: isFetchingProject,
  } = useGetProjectReadByUuidQuery(
    { projectUuid: projectId || currentProject || '' },
    {
      skip: !currentProject && !projectId,
      refetchOnMountOrArgChange,
    },
  );

  return {
    project,
    isLoadingProject,
    isFetchingProject,
  };
}
