import { PayloadAction } from '@reduxjs/toolkit/dist/createAction';

// This wraps a function that takes only the action payload as a reducer.
// This is similar to just passing through a PayloadAction<P>, but it removes
// the awkward { payload: {...} } to only need {...} in the call sites.
// -- @jp, 2023-03-22
export const wrapPayloadOnlyFnAsReducer =
  <S, P>(fn: (state: S, payload: P) => void) =>
  (state: S, action: PayloadAction<P>) =>
    fn(state, action.payload);
