import styled from '@emotion/styled/macro';
import React from 'react';

const DisplayDetailsGraphicContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  flex-shrink: 0;
  width: 50%;
  padding-right: ${({ theme }) => theme.spacing.normal};
  margin-top: ${({ theme }) => theme.spacing.normal};
  margin-bottom: ${({ theme }) => theme.spacing.normal};
`;

const Label = styled.div<{ filled: boolean }>`
  width: 48px;
  height: 8px;
  border-radius: 2px;
  cursor: pointer;

  ${({ filled }) =>
    filled ? 'background: #7E9A9A;' : 'border: 1.5px solid #E4E7E7;'}
`;
const DirectionalityContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 88px;
  height: 48px;
  margin: ${({ theme }) => theme.spacing.small} 0;
  padding: 0 12px;
  border-radius: 2px;
  border: 1.5px solid #e4e7e7;
  cursor: pointer;
  position: relative;
`;
const DirectionalityArrow = styled.div<{ filled: boolean }>`
  ${({ filled }) =>
    filled
      ? `
    > svg {
      stroke: black;
      stroke-width: 2;
    }
  `
      : ''}
`;

const PortAlignmentDisplayWrapper = styled.div`
  position: absolute;
  top: -1.5px;
  right: -1.5px;
  bottom: -1.5px;
  left: -1.5px;
  pointer-events: none;
  display: flex;
  justify-content: space-between;
`;

const PortDisplayWrapper = styled.div<{ alignment?: string }>`
  display: flex;
  flex-direction: column;
  ${({ alignment }) => {
    switch (alignment) {
      case 'top':
        return 'justify-content: flex-start;';
      case 'center':
        return 'justify-content: center;';
      case 'bottom':
        return 'justify-content: flex-end;';
      default:
        return 'justify-content: space-between;';
    }
  }}
`;

const PortAlignDisplay = styled.div`
  display: flex;
  width: 4px;
  height: 8px;
  margin: 4px 0;

  > img {
    height: 100%;
  }
`;

export const BlockDisplayDetailsGraphic = ({
  directionality,
  labelPosition,
  portAlignment,
  changeDirectionality,
  changeLabelPos,
}: {
  directionality?: 'left' | 'right';
  labelPosition?: 'top' | 'bottom';
  portAlignment?: 'spaced' | 'top' | 'center' | 'bottom';
  changeDirectionality: (d: 'left' | 'right') => void;
  changeLabelPos: (p: 'top' | 'bottom') => void;
}): React.ReactElement => {
  const leftFilled = directionality === 'left';

  return (
    <DisplayDetailsGraphicContainer>
      <Label
        filled={labelPosition === 'top'}
        onClick={() => changeLabelPos('top')}
      />
      <DirectionalityContainer>
        <PortAlignmentDisplayWrapper>
          <PortDisplayWrapper alignment={portAlignment}>
            {directionality === 'left'
              ? [
                  <PortAlignDisplay key="0">
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/aligndisp_output_leftdir.svg`}
                    />
                  </PortAlignDisplay>,
                  <PortAlignDisplay key="1">
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/aligndisp_output_leftdir.svg`}
                    />
                  </PortAlignDisplay>,
                ]
              : [
                  <PortAlignDisplay key="2">
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/aligndisp_input_rightdir.svg`}
                    />
                  </PortAlignDisplay>,
                  <PortAlignDisplay key="3">
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/aligndisp_input_rightdir.svg`}
                    />
                  </PortAlignDisplay>,
                ]}
          </PortDisplayWrapper>
          <PortDisplayWrapper alignment={portAlignment}>
            {directionality === 'left'
              ? [
                  <PortAlignDisplay key="0">
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/aligndisp_input_leftdir.svg`}
                    />
                  </PortAlignDisplay>,
                  <PortAlignDisplay key="1">
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/aligndisp_input_leftdir.svg`}
                    />
                  </PortAlignDisplay>,
                ]
              : [
                  <PortAlignDisplay key="2">
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/aligndisp_output_rightdir.svg`}
                    />
                  </PortAlignDisplay>,
                  <PortAlignDisplay key="3">
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/aligndisp_output_rightdir.svg`}
                    />
                  </PortAlignDisplay>,
                ]}
          </PortDisplayWrapper>
        </PortAlignmentDisplayWrapper>
        <DirectionalityArrow
          filled={leftFilled}
          onClick={() => changeDirectionality('left')}>
          <img
            src={`${
              process.env.PUBLIC_URL
            }/assets/block_directionality_arrow_left${
              leftFilled ? '_filled' : ''
            }.svg`}
          />
        </DirectionalityArrow>
        <DirectionalityArrow
          filled={!leftFilled}
          onClick={() => changeDirectionality('right')}>
          <img
            src={`${
              process.env.PUBLIC_URL
            }/assets/block_directionality_arrow_right${
              !leftFilled ? '_filled' : ''
            }.svg`}
          />
        </DirectionalityArrow>
      </DirectionalityContainer>
      <Label
        filled={labelPosition !== 'top'}
        onClick={() => changeLabelPos('bottom')}
      />
    </DisplayDetailsGraphicContainer>
  );
};
