import * as NVG from 'nanovg-js';
import { hexToNvgColor } from 'util/nvgColorHexConvert';

// Block borders and links color scheme.
// https://www.figma.com/design/ljX6SlNNvPs3pufav7ywJ9/Model-Editor?node-id=5261-159943&node-type=FRAME&t=mkEepXdd1iq3RhJI-0
export const LINE_COLORS = {
  // default boring grey
  normal: NVG.RGB(131, 149, 149),

  // signal dimensions
  scalar: NVG.RGB(224, 176, 237),
  vector: NVG.RGB(196, 107, 220),
  matrix: NVG.RGB(152, 56, 176),
  tensor: NVG.RGB(109, 19, 120),

  // signal data types (not used yet but in figma, TODO)
  boolean: hexToNvgColor('#6778d6'),
  integer: hexToNvgColor('#bdaf17'),
  double: hexToNvgColor('#008f8d'),
  complex: hexToNvgColor('#fc3277'),

  // time modes and rates
  constant: hexToNvgColor('#445757'),
  continuous: hexToNvgColor('#008f8d'),
  discrete_1: hexToNvgColor('#e9d92c'),
  discrete_2: hexToNvgColor('#dacb1d'), // not used atm
  discrete_3: hexToNvgColor('#eb653a'), // not used atm
  discrete_4: hexToNvgColor('#d43655'), // not used atm
  discrete_5: hexToNvgColor('#a8196a'), // not used atm
  multirate: hexToNvgColor('#adb8b8'), // not used atm

  // special
  iterator: NVG.RGB(155, 141, 17),

  // acausal domains
  electrical: hexToNvgColor('#8c2a99'),
  magnetic: hexToNvgColor('#c85ed6'),
  rotational: hexToNvgColor('#ad054b'),
  translational: hexToNvgColor('#fc3277'),
  thermal: hexToNvgColor('#bdaf17'),
  hydraulic: hexToNvgColor('#47e0dc'),
  pneumatic: hexToNvgColor('#95a6f1'),
  multi_domain: hexToNvgColor('#adb8b8'),

  // mixed time mode (not mixed rates), advertised as 'Hybrid' by wildcat.
  // See also https://github.com/collimator-ai/collimator/pull/6845
  hybrid: hexToNvgColor('#adb8b8'),

  // error and ux highlights
  error: hexToNvgColor('#f50b09'),
  error_in_child: NVG.RGB(180, 120, 120),
  warning: hexToNvgColor('#f0f065'),
  link_disconnected: NVG.RGB(230, 67, 77),
  selected: hexToNvgColor('#c9d3f8'),
  highlight: hexToNvgColor('#c9d3f890'),
  selection_rectangle: NVG.RGB(50, 188, 184),
  annotation_rectangle: NVG.RGB(100, 100, 100),
  unconnected_port: NVG.RGBA(131, 149, 149, 127),
  unconnected_port_hovering: NVG.RGBA(64, 91, 91, 255),
  input_port: NVG.RGB(27, 28, 29),

  text: NVG.RGB(79, 79, 79),
  text_secondary: NVG.RGB(92, 111, 112),
};

export const BLOCK_FILL_COLORS = {
  normal: NVG.RGBA(249, 249, 251, 255),
  iterator: NVG.RGBA(248, 245, 227, 255),
  inside_iterator: NVG.RGBA(239, 232, 180, 255),
  linearizer: NVG.RGBA(0, 232, 180, 255), // TODO: get proper color
};

export function withAlphaf(color: NVG.Color, alpha: number): NVG.Color {
  return NVG.RGBAf(color.r, color.g, color.b, alpha);
}

export type LineColorType = keyof typeof LINE_COLORS;
