/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  stroke?: any;
  strokeWidth?: any;
  strokeLinecap?: any;
  strokeLinejoin?: any;
  className?: string;
}

const Kalmanfilter: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.25 3C3.25 2.86193 3.13807 2.75 3 2.75C2.86193 2.75 2.75 2.86193 2.75 3V29.25H29C29.1381 29.25 29.25 29.1381 29.25 29C29.25 28.8619 29.1381 28.75 29 28.75H3.25V3Z"
      fill={props.fill}
    />
    <path
      d="M4.5 19C5.5 19 5.52222 16 8.07778 16C10.6333 16 9.94444 18.5 12.5 18.5C15.0556 18.5 14.7284 17.5 17 17.5C19.2716 17.5 18.8111 18.7 20.8556 18.7C22.6753 18.7 22.4556 17.5 24.5 17.5C26.5444 17.5 25.9667 18.1 27.5 18.1"
      stroke={props.stroke}
      strokeWidth="1.5"
      strokeLinecap={props.strokeLinecap}
    />
    <path
      d="M4 22.734L4.51064 14.8936L5.02128 23.5L5.53191 9.53191L6.04255 22.3511L6.55319 9.91489L7.06383 19.8723L7.57447 12.4149L8.08511 17.0106L8.59574 10.5L9.10638 19.1064L9.61702 11.8298L10.1277 21.0213L10.6383 10.6809L11.1489 22.4681L11.6596 16.7234L12.1702 22.0851L12.6809 15.9574L13.1915 21.3191L13.7021 16.7234L14.2128 20.5532L14.7234 17.1915L15.234 21.7872L15.7447 11.8298L16.2553 17.1915L16.766 13.3617L17.2766 18.7234L17.7872 14.1277L18.2979 19.4894L18.8085 15.6596L19.3191 21.4043L19.8298 16.4255L20.3404 21.0213L20.8511 17.9574L21.3617 21.0213L21.8723 15.6596L22.383 20.2553L22.8936 16.4255L23.4043 18.7234L23.9149 14.5106L24.4255 17.1915L24.9362 15.6596L25.4468 19.4894L25.9574 15.6596L26.4681 20.2553L26.9787 16.8085L27.4894 21.0213L28 18.7234"
      stroke={props.stroke}
      strokeWidth={props.strokeWidth}
      strokeLinecap={props.strokeLinecap}
      strokeLinejoin={props.strokeLinejoin}
    />
  </svg>
);

Kalmanfilter.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  stroke: '#1D3131',
  strokeWidth: '0.5',
  strokeLinecap: 'round',
  strokeLinejoin: 'round',
  className: '',
};

export default Kalmanfilter;
