/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  className?: string;
}

const Code: React.FC<Props> = (props) => (
  <svg viewBox="0 0 16 16" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.82567 4.28714C9.92469 3.97387 9.76415 3.63527 9.46712 3.53084C9.17008 3.42642 8.84901 3.59572 8.75 3.90899L6.125 12.7129C6.02599 13.0261 6.18652 13.3647 6.48356 13.4692C6.7806 13.5736 7.10166 13.4043 7.20067 13.091L9.82567 4.28714Z"
      fill={props.fill}
    />
    <path
      d="M4.94154 4.83398C5.19202 5.03211 5.24278 5.40688 5.05492 5.67105L3.41732 8.50114L5.05492 11.3312C5.24278 11.5954 5.19202 11.9702 4.94154 12.1683C4.69105 12.3664 4.3357 12.3129 4.14783 12.0487L2.31356 9.01905C2.12081 8.70068 2.12081 8.3016 2.31356 7.98323L4.14783 4.95357C4.3357 4.68939 4.69105 4.63586 4.94154 4.83398Z"
      fill={props.fill}
    />
    <path
      d="M11.0585 4.83398C10.808 5.03211 10.7572 5.40688 10.9451 5.67105L12.5827 8.50114L10.9451 11.3312C10.7572 11.5954 10.808 11.9702 11.0585 12.1683C11.3089 12.3664 11.6643 12.3129 11.8522 12.0487L13.6864 9.01905C13.8792 8.70068 13.8792 8.3016 13.6864 7.98323L11.8522 4.95357C11.6643 4.68939 11.3089 4.63586 11.0585 4.83398Z"
      fill={props.fill}
    />
  </svg>
);

Code.defaultProps = {
  width: '16',
  height: '16',
  viewBox: '0 0 16 16',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  className: '',
};

export default Code;
