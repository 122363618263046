import { t } from '@lingui/macro';
import { useProject } from 'app/api/useProject';
import { useAppSelector } from 'app/hooks';
import React from 'react';
import { ActionsSpacer } from 'ui/common/Button/actionButtonStyles';
import Button from 'ui/common/Button/Button';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import { Remove } from 'ui/common/Icons/Standard';
import { ProjectDetailNewItemButtons } from 'ui/dashboard/projectDetail/buttons/ProjectDetailNewItemButtons';
import { useProjectDetailActions } from 'ui/dashboard/projectDetail/useProjectDetailActions';
import { useProjectPermission } from 'ui/permission/useProjectPermission';
import { ButtonProps } from './buttons/ButtonProps';
import { ProjectDetailCreateBusTypeButton } from './buttons/ProjectDetailBusTypeButton';
import { ProjectDetailExportButton } from './buttons/ProjectDetailExportButton';
import { ProjectDetailImportButton } from './buttons/ProjectDetailImportButton';
import { ProjectElementDownloadFileButton } from './buttons/ProjectElementDownloadFileButton';
import { ProjectElementDuplicateButton } from './buttons/ProjectElementDuplicateButton';
import { ProjectElementRenameButton } from './buttons/ProjectElementRenameButton';

export const ProjectDetailActionButtons: React.FC<ButtonProps> = ({
  selectedItems,
  numSelected,
}) => {
  const { project } = useProject();
  const { developerModeEnabled } = useAppSelector(
    (state) => state.userOptions.options,
  );
  const { deleteElement } = useProjectDetailActions(selectedItems);

  const { canEditProject } = useProjectPermission(project?.uuid);

  const hasSelected = numSelected && numSelected > 0;

  return (
    <>
      {canEditProject && hasSelected ? (
        <Button
          variant={ButtonVariants.SmallSecondary}
          testId="project-element-rename-button"
          Icon={Remove}
          onClick={deleteElement}
          hasRedText>
          {t({
            id: 'dashboard.projectsDetailActionButtons.deleteButton.Label',
            message: 'Delete',
          })}
        </Button>
      ) : null}
      <ActionsSpacer />
      {project && canEditProject && !hasSelected ? (
        <>
          <ProjectDetailNewItemButtons project={project} />
          {developerModeEnabled && (
            <ProjectDetailCreateBusTypeButton project={project} />
          )}
          <ProjectDetailImportButton project={project} />
        </>
      ) : null}
      {developerModeEnabled && (
        <ProjectDetailExportButton disabled={!!hasSelected} />
      )}
      {hasSelected ? (
        <>
          <ProjectElementRenameButton
            selectedItems={selectedItems}
            numSelected={numSelected}
            disabled={!canEditProject || numSelected > 1}
          />
          <ProjectElementDuplicateButton
            selectedItems={selectedItems}
            numSelected={numSelected}
          />
          <ProjectElementDownloadFileButton selectedItems={selectedItems} />
        </>
      ) : null}
    </>
  );
};
