import type { Coordinate } from 'app/common_types/Coordinate';
import { MouseActions } from 'app/common_types/MouseTypes';
import { rcMenuActions } from 'app/slices/rcMenuSlice';
import { RendererState } from '../modelRenderer';
import { transitionMouseState } from '../transitionMouseState';

export const mouseInputMiddleClickHold = (
  rs: RendererState,
  zoomedClickCoord: Coordinate,
): void => {
  rs.dispatch(rcMenuActions.close());

  transitionMouseState(rs, {
    state: MouseActions.Panning,
    cameraStartX: rs.camera.x,
    cameraStartY: rs.camera.y,
    cursorStartX: zoomedClickCoord.x,
    cursorStartY: zoomedClickCoord.y,
  });
};
