import styled from '@emotion/styled';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { dataExplorerActions } from 'app/slices/dataExplorerSlice';
import React, { useRef, useState } from 'react';
import { useDrop } from 'react-dnd';
import Draggable, {
  ControlPosition,
  DraggableData,
  DraggableEvent,
} from 'react-draggable';
import {
  CellRow,
  DataExplorerDragType,
} from 'ui/dataExplorer/dataExplorerTypes';
import HideableSignalDragRowDropZones from 'ui/dataExplorer/drag/signalDrag/HideableRowDropZones';
import TraceDragRowDropZones from 'ui/dataExplorer/drag/traceDrag/RowDropZones';
import { DataExplorerCell } from 'ui/dataExplorer/grid/DataExplorerCell';
import { DEFAULT_ROW_HEIGHT } from 'util/plotCellUtils';

const CellRowWrapper = styled.div<{ rowHeight: number }>`
  position: relative;
  display: flex;
  width: 100%;
  gap: ${({ theme }) => theme.spacing.small};
  height: ${({ rowHeight }) => rowHeight}px;
`;

// The resizer lives here instead of at the chart level because
// the parent component doesn't have one return element we can attach this to.
const CellRowResizer = styled.div<{ isActive?: boolean }>`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 6px;
  cursor: s-resize;
  z-index: 1;
  transition: background-color 0.1s ease-out;
  ${({ theme, isActive }) => `
  border-radius: 0 0 ${theme.spacing.xsmall} ${theme.spacing.xsmall};
  background-color: ${isActive ? theme.colors.ui.tint1 : 'transparent'};
  :hover {
    transition: none;
    background-color: ${
      isActive ? theme.colors.ui.tint1 : theme.colors.ui.tint2
    };
  }
  `}
`;

/**
 * display: flex and align-items: stretch ensure the cell height matches the row height.
 */
const DataExplorerCellWrapper = styled.div<{ height: number }>`
  position: relative;
  flex: 1;
  min-width: 0;
  height: ${({ height }) => `${height}px`};
  background-color: ${({ theme }) => theme.colors.grey[2]};
  border-radius: ${({ theme }) => theme.spacing.xsmall};
  border: 1px solid ${({ theme }) => theme.colors.grey[10]};
`;

interface Props {
  cellRowId: string;
  simulationId?: string;
  canEditVisualization: boolean;
}

export const DataExplorerRow: React.FC<Props> = ({
  cellRowId,
  simulationId,
  canEditVisualization,
}) => {
  const dispatch = useAppDispatch();

  const [{ isDraggingSignal }] = useDrop(() => ({
    accept: DataExplorerDragType.SignalTree,
    collect: (monitor) => ({
      isDraggingSignal: !!monitor.canDrop(),
    }),
  }));

  const { traceDragSourceCandidate, isDraggingTrace } = useAppSelector(
    (state) => state.traceDrag,
  );

  const cellRow: CellRow = useAppSelector(
    (state) => state.dataExplorer.idToCellRow[cellRowId],
  );

  const [resizeTop, setResizeTop] = useState<number>();
  // TODO: do not allow for resizing when a trace is dragging. react-dnd
  const [isResizing, setIsResizing] = useState(false);

  // library hack to suppress warning: https://github.com/react-grid-layout/react-draggable/blob/v4.4.2/lib/DraggableCore.js#L159-L171
  const resizerRef = useRef<null | HTMLDivElement>(null);

  // Row resize
  const startResize = (event: DraggableEvent, data: DraggableData) => {
    setIsResizing(true);
    const topLimit = DEFAULT_ROW_HEIGHT - cellRow.rowHeight;
    setResizeTop(topLimit);
  };

  const stopResize = (event: DraggableEvent, data: DraggableData) => {
    setIsResizing(false);
    dispatch(
      dataExplorerActions.setRowHeight({
        cellRowId,
        rowHeight: data.y + cellRow.rowHeight,
      }),
    );
  };

  return (
    <CellRowWrapper rowHeight={cellRow.rowHeight || DEFAULT_ROW_HEIGHT}>
      {!(isDraggingTrace || isDraggingSignal) && (
        <Draggable
          nodeRef={resizerRef}
          bounds={{
            left: 0,
            right: 0,
            top: resizeTop,
          }}
          position={
            isResizing ? undefined : ({ x: 0, y: 0 } as ControlPosition)
          }
          onStart={startResize}
          onStop={stopResize}>
          <CellRowResizer ref={resizerRef} isActive={isResizing} />
        </Draggable>
      )}
      {isDraggingTrace && traceDragSourceCandidate && (
        <TraceDragRowDropZones
          traceDragSource={traceDragSourceCandidate}
          targetRowId={cellRowId}
        />
      )}
      <HideableSignalDragRowDropZones targetRowId={cellRowId} />
      {cellRow.cellIds.map((cellId) => (
        <DataExplorerCellWrapper
          key={cellId}
          height={cellRow.rowHeight || DEFAULT_ROW_HEIGHT}>
          <DataExplorerCell
            cellId={cellId}
            simulationId={simulationId}
            canEditVisualization={canEditVisualization}
          />
        </DataExplorerCellWrapper>
      ))}
    </CellRowWrapper>
  );
};
