/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  cx?: any;
  cy?: any;
  r?: any;
  stroke?: any;
  strokeWidth?: any;
  fillRule?: any;
  clipRule?: any;
  strokeLinecap?: any;
  className?: string;
}

const AcausalThermalTemperaturesource: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <circle
      cx={props.cx}
      cy={props.cy}
      r={props.r}
      stroke={props.stroke}
      strokeWidth={props.strokeWidth}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M12.3948 12.6902C12.0476 12.4643 11.9493 11.9997 12.1751 11.6525C12.401 11.3053 12.8656 11.207 13.2128 11.4329C13.6819 11.7381 14.03 12.1058 14.2559 12.5357C14.4813 12.9646 14.5627 13.4126 14.5627 13.8461C14.5627 14.6492 14.2758 15.4886 14.0386 16.1823L14.018 16.2424C13.7544 17.0144 13.5539 17.6264 13.5539 18.1538C13.5539 18.3973 13.5985 18.5953 13.6885 18.7665C13.7778 18.9365 13.9342 19.1227 14.2218 19.3098C14.569 19.5357 14.6673 20.0003 14.4414 20.3475C14.2155 20.6947 13.7509 20.793 13.4037 20.5671C12.9347 20.2619 12.5866 19.8942 12.3606 19.4642C12.1353 19.0354 12.0539 18.5873 12.0539 18.1538C12.0539 17.3508 12.3408 16.5114 12.578 15.8178L12.5986 15.7576C12.8622 14.9856 13.0627 14.3736 13.0627 13.8462C13.0627 13.6028 13.018 13.4047 12.9281 13.2335C12.8387 13.0635 12.6824 12.8773 12.3948 12.6902ZM17.4035 12.6902C17.0563 12.4643 16.958 11.9997 17.1838 11.6525C17.4097 11.3053 17.8743 11.207 18.2215 11.4329C18.6906 11.7381 19.0387 12.1058 19.2646 12.5357C19.49 12.9646 19.5714 13.4126 19.5714 13.8461C19.5714 14.6492 19.2845 15.4886 19.0473 16.1823L19.0473 16.1823L19.0267 16.2424C18.7631 17.0144 18.5626 17.6264 18.5626 18.1538C18.5626 18.3973 18.6073 18.5953 18.6972 18.7665C18.7865 18.9365 18.9429 19.1227 19.2305 19.3098C19.5777 19.5357 19.676 20.0003 19.4501 20.3475C19.2242 20.6947 18.7596 20.793 18.4124 20.5671C17.9434 20.2619 17.5953 19.8942 17.3693 19.4642C17.144 19.0354 17.0626 18.5873 17.0626 18.1538C17.0626 17.3508 17.3495 16.5114 17.5867 15.8178L17.6073 15.7576C17.8709 14.9856 18.0714 14.3736 18.0714 13.8462C18.0714 13.6028 18.0267 13.4047 17.9368 13.2335C17.8475 13.0635 17.6911 12.8773 17.4035 12.6902Z"
      fill={props.fill}
    />
    <path
      d="M2 16L4 16"
      stroke={props.stroke}
      strokeWidth={props.strokeWidth}
      strokeLinecap={props.strokeLinecap}
    />
    <path
      d="M28 16L30 16"
      stroke={props.stroke}
      strokeWidth={props.strokeWidth}
      strokeLinecap={props.strokeLinecap}
    />
  </svg>
);

AcausalThermalTemperaturesource.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  cx: '16',
  cy: '16',
  r: '11.25',
  stroke: '#1D3131',
  strokeWidth: '1.5',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  strokeLinecap: 'round',
  className: '',
};

export default AcausalThermalTemperaturesource;
