import { Theme, useTheme } from '@emotion/react';
import styled from '@emotion/styled/macro';
import { t } from '@lingui/macro';
import { Close } from 'ui/common/Icons/Small';

const RemoveLabel = styled.div`
  color: white;
  position: absolute;
  ${({ theme }) => `font-size: ${theme.typography.font.extraSmall.size};`}
  top: 30%;
`;

const TraceRemoveDropZoneBackground = styled.div`
  position: relative;
  height: 120px;
  width: 120px;
  isolation: isolate;

  display: flex;
  align-items: center;
  justify-content: center;

  opacity: 0.4;
  transition: opacity 0.2s ease-out;

  &::before {
    ${({ theme }) => `background-color: ${theme.colors.base.red};`}
    transition: scale 0.2s ease-out;
    border-radius: 50%;
    height: 60%;
    width: 60%;
    content: '';
    z-index: -1;
    position: absolute;
    top: 20%;
    left: 20%;
  }

  &:hover::before {
    scale: 1.5;
  }

  ${RemoveLabel} {
    transition: opacity 0.1s ease-out;
    opacity: 0;
  }

  &:hover {
    opacity: 0.6;
    ${RemoveLabel} {
      opacity: 1;
    }
  }
`;

const CloseIconWrapper = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: white;
`;

type Props = {
  removeTrace: () => void;
};

const TraceRemoveDropZone = ({ removeTrace }: Props) => {
  const theme = useTheme() as Theme;

  return (
    <TraceRemoveDropZoneBackground onMouseUp={removeTrace}>
      <RemoveLabel>
        {t({
          id: 'visualizer.signalRemoveDropZone.removeLabel',
          message: 'REMOVE',
        })}
      </RemoveLabel>
      <CloseIconWrapper>
        <Close fill={theme.colors.base.red} />
      </CloseIconWrapper>
    </TraceRemoveDropZoneBackground>
  );
};
export default TraceRemoveDropZone;
