import { Theme, useTheme } from '@emotion/react';
import styled from '@emotion/styled/macro';
import { t } from '@lingui/macro';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Cell, Column, Row, SortByFn, TableState } from 'react-table';
import {
  CheckboxForTableCell,
  CheckboxForTableHeader,
} from 'ui/common/Checkbox';
import {
  ArrowRight,
  Bus,
  Data,
  File,
  Model as ModelIcon,
  Notebook,
  PythonFile,
  Submodel,
} from 'ui/common/Icons/Standard';
import ValidationError from 'ui/common/Input/ValidationError';
import { useModal } from 'ui/common/Modal/useModal';
import SpinnerWithTooltip from 'ui/common/SpinnerWithTooltip';
import Table from 'ui/common/Table/Table';
import { TooltipPlacement } from 'ui/common/Tooltip/tooltipTypes';
import {
  ProjectItemType,
  ProjectsDetailTableData,
  ProjectsDetailTableData as TableData,
} from 'ui/dashboard/dashboardTypes';
import { formatDate } from 'util/dateUtils';
import DownloadFileModal from './DownloadFileModal';

const TYPE_SORT_PRIORITIES = {
  [ProjectItemType.MODEL]: 4,
  [ProjectItemType.SUBMODEL]: 3,
  [ProjectItemType.BUS_TYPE]: 2,
  [ProjectItemType.PROJECTFILE]: 1,
  [ProjectItemType.FOLDER]: 0,
};

const ErrorIconWrapper = styled.div`
  position: relative;
  height: 20px;
  width: 24px;
  margin-right: -4px;
`;

const DateBlock = styled.span`
  text-overflow: ellipsis;
  overflow: hidden;
`;

const CellIcon = (cell: Cell<TableData>, theme: Theme) => {
  if (cell.row.original.isProcessing) {
    return (
      <SpinnerWithTooltip
        tooltipPlacement={TooltipPlacement.BOTTOM_RIGHT}
        tooltipMessage={t({
          id: 'dashboard.projectDetails.fileItemIcon.isProcessing.tooltip',
          message: 'Processing in progress',
        })}
      />
    );
  }

  if (cell.row.original.error) {
    return (
      <ErrorIconWrapper>
        <ValidationError
          validationError={cell.row.original.error}
          tooltipPlacement={TooltipPlacement.BOTTOM_RIGHT}
          isInFocus={false}
        />
      </ErrorIconWrapper>
    );
  }

  switch (cell.row.original.type) {
    case ProjectItemType.MODEL:
      return <ModelIcon fill={theme.colors.text.secondary} />;
    case ProjectItemType.SUBMODEL:
      return <Submodel fill={theme.colors.text.secondary} />;
    case ProjectItemType.BUS_TYPE:
      return <Bus fill={theme.colors.text.secondary} />;
    case ProjectItemType.FOLDER:
      return <ArrowRight fill={theme.colors.text.secondary} />;
    default:
      const fileExt = cell.row.original.name.split('.').pop()?.toLowerCase();
      switch (fileExt) {
        case 'py':
          return <PythonFile fill={theme.colors.text.secondary} />;
        case 'ipynb':
          return <Notebook fill={theme.colors.text.secondary} />;
        case 'csv':
          return <Data fill={theme.colors.text.secondary} />;
        default:
          return <File fill={theme.colors.text.secondary} />;
      }
  }
};

const DateCell = ({ cell }: { cell: Cell<TableData> }) => (
  <DateBlock title={cell.value}>{formatDate(cell.value)}</DateBlock>
);

const cmpDate = (a: string, b: string) =>
  Math.sign(new Date(b).getTime() - new Date(a).getTime());

interface Props {
  projectId: string;
  projectItems: ProjectsDetailTableData[];
  getRowsSelected: (selectionState: Record<string, boolean>) => void;
  numSelected: number;
  showFullPath: boolean;
}

export const ProjectDetailTable: React.FC<Props> = ({
  projectId,
  projectItems,
  getRowsSelected,
  numSelected,
  showFullPath,
}) => {
  const navigate = useNavigate();
  const { triggerModal } = useModal();
  const theme = useTheme();

  const sortByName: SortByFn<ProjectsDetailTableData> = React.useCallback(
    (
      rowA: Row<TableData>,
      rowB: Row<TableData>,
      _columnId: string,
      desc?: boolean,
    ) => {
      const a = rowA.original.name;
      const b = rowB.original.name;
      return a.localeCompare(b);
    },
    [],
  );

  const sortByFileType: SortByFn<ProjectsDetailTableData> = React.useCallback(
    (
      rowA: Row<TableData>,
      rowB: Row<TableData>,
      _columnId: string,
      desc?: boolean,
    ) => {
      const a = TYPE_SORT_PRIORITIES[rowA.original.type];
      const b = TYPE_SORT_PRIORITIES[rowB.original.type];
      if (a !== b) return Math.sign(b - a);
      // fallback to updatedAt sort, but always sort in descending order
      return (
        (desc ? -1 : 1) *
        cmpDate(rowA.original.updatedAt, rowB.original.updatedAt)
      );
    },
    [],
  );

  const columns: Column<TableData>[] = [
    { Header: 'UUID', accessor: 'uuid', minWidth: 0, maxWidth: 0 },
    {
      Header: CheckboxForTableHeader,
      id: 'multiselect',
      minWidth: 24,
      maxWidth: 0,
      Cell: CheckboxForTableCell,
    },
    {
      Header: '',
      id: 'icon',
      minWidth: 24,
      maxWidth: 0,
      Cell: ({ cell }: { cell: Cell<TableData> }) => CellIcon(cell, theme),
    },
    {
      Header: t`Name`,
      accessor: (row) => (showFullPath ? row.path : row.name),
      minWidth: 100,
      maxWidth: 1,
      sortType: sortByName,
    },
    {
      Header: t`Type`,
      accessor: 'type',
      minWidth: 100,
      maxWidth: 0,
      sortType: sortByFileType,
    },
    {
      Header: t`Last Edited`,
      accessor: 'updatedAt',
      minWidth: 140,
      maxWidth: 0,
      Cell: DateCell,
    },
    {
      Header: t`Created`,
      accessor: 'createdAt',
      minWidth: 140,
      maxWidth: 0,
      Cell: DateCell,
    },
  ];

  const initialState: Partial<TableState<TableData>> = {
    hiddenColumns: ['uuid'],
    sortBy: [{ id: 'type', desc: false }],
  };

  const rowNavigation = React.useCallback(
    (data: TableData) => {
      if (data.isProcessing) return;

      const fileExt = data.name.split('.').pop()?.toLowerCase() || '';
      const isCodeFile = ['py', 'txt', 'csv'].includes(fileExt);

      switch (data.type) {
        case ProjectItemType.FOLDER:
          navigate(`/projects/${projectId}?path=${data.path}`);
          break;
        case ProjectItemType.MODEL:
        case ProjectItemType.SUBMODEL:
          navigate(`/projects/${projectId}/models/${data.uuid}`);
          break;
        case ProjectItemType.BUS_TYPE:
          navigate(`/projects/${projectId}/bus_types/${data.uuid}`);
          break;
        case ProjectItemType.PROJECTFILE:
          if (isCodeFile) {
            navigate(`/projects/${projectId}/code_editor/${data.uuid}`);
            break;
          } else if (fileExt === 'ipynb') {
            navigate(`/projects/${projectId}/notebook/${data.uuid}`);
            break;
          } else if (fileExt === 'md') {
            navigate(`/projects/${projectId}/markdown/${data.uuid}`);
            break;
          }

          triggerModal(
            <DownloadFileModal
              projectId={data.projectUuid}
              fileName={data.name}
              fileUuid={data.uuid}
            />,
            t({ id: 'downloadFileModal.title', message: 'Download file' }),
          );
          break;
      }
    },
    [navigate, triggerModal, projectId],
  );

  return (
    <Table
      columns={columns}
      data={projectItems}
      initialState={initialState}
      testId="project-detail-table"
      onRowClick={(data) => {
        if (data.type === ProjectItemType.FOLDER || numSelected === 0) {
          rowNavigation(data);
        }
      }}
      onRowDoubleClick={rowNavigation}
      getRowTestId={(row: Row<TableData>) => row.original.uuid}
      getRowsSelected={getRowsSelected}
      enableMultiSelect
    />
  );
};
