import { t } from '@lingui/macro';
import { DiagramVersionFull } from 'app/apiTransformers/convertGetSnapshotReadByUuid';
import { VersionTagValues } from 'app/apiTransformers/convertPostSubmodelsFetch';
import {
  LinkInstance,
  NodeInstance,
  SubmodelInstance,
  SubmodelsSection,
} from 'app/generated_types/SimulationModel';
import {
  nodeTypeIsLocalSubdiagram,
  nodeTypeIsReferencedSubmodel,
} from 'app/helpers';
import {
  ModelVersionRequestData,
  getModelData,
} from 'app/slices/modelVersionsSlice';
import { getActualVersionId } from 'app/utils/submodelUtils';
import {
  getSubmodelRef,
  isSubmodelRefAvailable,
} from '../app/sliceRefAccess/SubmodelRef';

export interface ModelLevelResult {
  // If the set of nodes changes for the next level (for all submodels),
  // this is set, otherwise undefined.
  nodes?: NodeInstance[];

  // If the set of nodes changes for the next level (for all submodels),
  // this is set, otherwise undefined.
  links?: LinkInstance[];

  // If the set of submodels changes for the next level (for reference submodels only),
  // this is set, otherwise undefined.
  submodels?: SubmodelsSection;

  // If we need to request a reference submodel,
  // it will be set here, otherwise this will be undefined.
  modelVersionToRequest?: ModelVersionRequestData;

  // If we can't find the submodel information we need to walk the next level,
  // the error message is set, otherwise undefined.
  errorMessage?: string;

  isExpectedError?: boolean;
}

export function getNextModelLevel(
  node: NodeInstance,
  submodels: SubmodelsSection,
  modelIdToVersionIdToModelData: Record<
    string,
    Record<string, DiagramVersionFull>
  >,
): ModelLevelResult {
  if (nodeTypeIsLocalSubdiagram(node.type)) {
    const reference = submodels.references[node.uuid];
    if (!reference) {
      return {
        errorMessage: t({
          id: 'dataExplorer.addSignalError.unableToFindLocalSubmodelReference',
          message:
            'Unable to visualize signal: local submodel reference not found.',
        }),
      };
    }

    const diagram = submodels.diagrams[reference.diagram_uuid];
    if (!diagram) {
      return {
        errorMessage: t({
          id: 'dataExplorer.addSignalError.unableToFindLocalSubmodelReferenceDiagram',
          message:
            'Unable to visualize signal: local submodel reference diagram not found.',
        }),
      };
    }

    // Set the nodes and links to the local submodel diagram nodes in preparation
    // for processing the next layer.
    // For local submodels, the submodels section does not change.
    return {
      nodes: diagram.nodes,
      links: diagram.links,
    };
  }

  if (nodeTypeIsReferencedSubmodel(node.type)) {
    const submodelReferenceId = (node as SubmodelInstance)
      .submodel_reference_uuid;
    const submodelReferenceVersionId = VersionTagValues.LATEST_VERSION;
    if (!submodelReferenceId) {
      return {
        errorMessage: t({
          id: 'dataExplorer.addSignalError.unableToFindReferenceSubmodelId',
          message:
            'Unable to visualize signal: reference submodel reference id not found.',
        }),
      };
    }

    const referenceSubmodelData = getModelData(
      submodelReferenceId,
      submodelReferenceVersionId,
      modelIdToVersionIdToModelData,
    );
    if (!referenceSubmodelData) {
      const actualVersionId =
        getActualVersionId(
          submodelReferenceId,
          submodelReferenceVersionId,
          isSubmodelRefAvailable()
            ? getSubmodelRef().idToLatestTaggedVersionId
            : {},
        ) || VersionTagValues.LATEST_VERSION;

      return {
        modelVersionToRequest: {
          modelId: submodelReferenceId,
          versionId: actualVersionId,
          kind: 'Submodel',
        },
      };
    }

    // Nodes, links, and submodels sections will change to walk the next level
    // for a reference submodel.
    return {
      nodes: referenceSubmodelData.diagram.nodes,
      links: referenceSubmodelData.diagram.links,
      submodels: referenceSubmodelData.submodelsSection,
    };
  }

  // Not a submodel node, so there are no changes to the nodes or submodels.
  return {};
}
