import {
  transformBackendModelDiagram,
  transformBackendSubmodelConfiguration,
  transformBackendSubmodels,
} from '@collimator/model-schemas-ts';
import {
  GetSubmodelApiResponse,
  SubmodelFull,
} from 'app/apiGenerated/generatedApiTypes';
import { SubmodelInfoUI } from 'app/apiTransformers/convertGetSubmodelsListForModelParent';
import {
  ModelDiagram,
  StateMachineDiagram,
  SubmodelsSection,
} from 'app/generated_types/SimulationModel';

export interface SubmodelFullUI extends SubmodelInfoUI {
  diagram: ModelDiagram;
  submodels: SubmodelsSection;
  stateMachines: { [k: string]: StateMachineDiagram | undefined };
}

export function convertAPISubmodelFullToSubmodelFullUI(
  apiSubmodel: SubmodelFull,
): SubmodelFullUI {
  const diagram = transformBackendModelDiagram(apiSubmodel.diagram);
  const submodels = transformBackendSubmodels(apiSubmodel.submodels);
  const submodelConfiguration = transformBackendSubmodelConfiguration(
    apiSubmodel.configuration,
  );
  return {
    id: apiSubmodel.uuid,
    isGlobal: apiSubmodel.is_global,
    createdAt: apiSubmodel.created_at || '',
    updatedAt: apiSubmodel.updated_at || '',
    name: apiSubmodel.name,
    description: apiSubmodel.description,
    editId: apiSubmodel.edit_id,
    projectId: apiSubmodel.project_uuid,
    ownerId: apiSubmodel.owner_uuid,
    inputCount: apiSubmodel.port_count.inputs,
    outputCount: apiSubmodel.port_count.outputs,
    parameterDefinitions: apiSubmodel.parameter_definitions,
    portDefinitionsInputs: apiSubmodel.port_definitions.inputs || [],
    portDefinitionsOutputs: apiSubmodel.port_definitions.outputs || [],
    submodelConfiguration,
    diagram,
    submodels,
    stateMachines: apiSubmodel.state_machines as {
      [k: string]: StateMachineDiagram | undefined;
    },
  };
}

export function convertGetSubmodel(
  apiResponse: GetSubmodelApiResponse,
): SubmodelFullUI {
  return convertAPISubmodelFullToSubmodelFullUI(apiResponse);
}
