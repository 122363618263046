/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Environment: React.FC<Props> = (props) => (
  <svg viewBox="0 0 24 24" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M10.5118 4.25199C10.7387 4.26857 10.9458 4.38723 11.0748 4.57457L11.7902 5.61316L13.0629 5.21719C13.3166 5.13828 13.593 5.19942 13.7897 5.37791L17.5147 8.75797L18.884 8.03486C19.1069 7.91717 19.374 7.91929 19.5949 8.0405L21.3607 9.0091C21.5567 9.11663 21.6946 9.30602 21.7366 9.52562C21.7787 9.74523 21.7206 9.97214 21.5781 10.1445L19.0297 13.2278L16.7356 16.6201C16.6655 16.7238 16.5706 16.8082 16.4595 16.8658L11.0595 19.6658C10.738 19.8325 10.3433 19.7457 10.1214 19.4594L2.40716 9.50383C2.25594 9.30868 2.21045 9.05161 2.28551 8.81642C2.36057 8.58123 2.54657 8.39804 2.78287 8.32656L7.73167 6.8297L9.90791 4.48928C10.0628 4.3227 10.2849 4.23541 10.5118 4.25199ZM18.1276 11.9646L19.844 9.888L19.4665 9.6809L17.9131 11.9276L18.1276 11.9646ZM16.7857 13.2549L15.4205 14.1195L16.0659 14.9327L17.1571 13.3191L16.7857 13.2549ZM14.9609 15.9532L14.0935 14.8605L12.8572 15.4317L13.6625 16.6265L14.9609 15.9532ZM12.3221 17.3215L11.4803 16.0727L9.95181 16.7915L10.9242 18.0464L12.3221 17.3215ZM9.01043 15.5766L10.5214 14.8661L9.48476 13.6856L8.03823 14.322L9.01043 15.5766ZM7.09124 13.0999L8.47553 12.4908L7.41825 11.2117L6.09922 11.8196L7.09124 13.0999ZM5.15619 10.6026L6.32829 10.0624L5.36285 9.11331L4.26054 9.44673L5.15619 10.6026ZM6.9991 8.6184L7.43978 9.0516L7.80121 8.37579L6.9991 8.6184ZM10.1272 7.20734L8.52723 10.1989L9.85141 11.801L10.9056 11.1632L10.1272 7.20734ZM11.6459 12.4686L12.9867 13.7195L11.9368 14.2046L10.8403 12.9559L11.6459 12.4686ZM14.4024 12.9887L12.9798 11.6616L13.7491 11.1962L15.3214 12.4068L14.4024 12.9887ZM12.8217 10.0042L12.2718 10.3368L11.8916 8.40474L12.3805 9.11459L12.8217 10.0042ZM14.0604 9.12589L14.2234 9.45459L15.7579 9.18936L15.1872 8.67148L14.0604 9.12589ZM13.9532 7.55175L13.0992 6.77682L12.6812 6.90685L13.3054 7.81301L13.9532 7.55175ZM15.6113 10.7369L17.0893 10.4815L16.4606 11.3908L15.6113 10.7369Z"
      fill={props.fill}
    />
  </svg>
);

Environment.defaultProps = {
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Environment;
