import styled from '@emotion/styled/macro';
import { Project } from 'app/apiTransformers/convertAPIProjectToProject';
import { useAppSelector } from 'app/hooks';
import React from 'react';
import { H4 } from 'ui/common/typography/Typography';
import MembersSection from '../../members/MembersSection';
import ToolsSection from './ToolsSection';

const Wrapper = styled.aside`
  height: 100%;
  width: 256px;
  flex-shrink: 0;
  flex-grow: 0;
  background-color: ${(props) => props.theme.colors.grey[2]};
  padding: ${(props) => props.theme.spacing.normal};
  padding-top: ${(props) => props.theme.spacing.xxlarge};
  overflow-y: auto;
  pointer-events: auto;
`;

export const Section = styled.div`
  margin-bottom: 48px;
`;
export const SectionItems = styled.div``;
export const SectionItemWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0 ${({ theme }) => theme.spacing.normal};
  cursor: pointer;
  > svg:first-of-type {
    margin-right: ${({ theme }) => theme.spacing.small};
  }
`;

export const ProjectRightSidebarHeading = styled.div`
  display: flex;
  width: 100%;
  color: ${({ theme }) => theme.colors.text.secondary};
  margin-bottom: ${({ theme }) => theme.spacing.large};
  padding-right: ${({ theme }) => theme.spacing.normal};
  padding-left: ${({ theme }) => theme.spacing.normal};
  margin-top: ${({ theme }) => theme.spacing.small};
`;

export const SectionH4 = styled(H4)`
  flex-grow: 1;
  text-transform: uppercase;
  letter-spacing: 0.07em;
`;

interface Props {
  project: Project;
}

const ProjectRightSidebar: React.FC<Props> = ({ project }) => {
  const { requirementsEnabled } = useAppSelector(
    (state) => state.userOptions.options,
  );

  return (
    <Wrapper>
      {requirementsEnabled && <ToolsSection project={project} />}
      <MembersSection project={project} />
    </Wrapper>
  );
};

export default ProjectRightSidebar;
