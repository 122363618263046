/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Component: React.FC<Props> = (props) => (
  <svg viewBox="0 0 16 16" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M8.66668 4.16665C8.66668 3.98255 8.81592 3.83331 9.00001 3.83331H12.3333C12.5174 3.83331 12.6667 3.98255 12.6667 4.16665V7.49998C12.6667 7.68407 12.5174 7.83331 12.3333 7.83331H9.00001C8.81592 7.83331 8.66668 7.68407 8.66668 7.49998V4.16665ZM9.66668 6.83331V4.83331H11.6667V6.83331H9.66668Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M3.33334 9.49998C3.33334 9.31588 3.48258 9.16665 3.66668 9.16665H7.00001C7.18411 9.16665 7.33334 9.31588 7.33334 9.49998V12.8333C7.33334 13.0174 7.18411 13.1666 7.00001 13.1666H3.66668C3.48258 13.1666 3.33334 13.0174 3.33334 12.8333V9.49998ZM4.33334 12.1666V10.1666H6.33334V12.1666H4.33334Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M10.6667 13.1666C11.7712 13.1666 12.6667 12.2712 12.6667 11.1666C12.6667 10.0621 11.7712 9.16665 10.6667 9.16665C9.56211 9.16665 8.66668 10.0621 8.66668 11.1666C8.66668 12.2712 9.56211 13.1666 10.6667 13.1666ZM10.6667 12.1666C11.219 12.1666 11.6667 11.7189 11.6667 11.1666C11.6667 10.6144 11.219 10.1666 10.6667 10.1666C10.1144 10.1666 9.66668 10.6144 9.66668 11.1666C9.66668 11.7189 10.1144 12.1666 10.6667 12.1666Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M5.04542 4.3269C5.17404 4.1064 5.49264 4.1064 5.62127 4.3269L7.37426 7.33202C7.50389 7.55424 7.3436 7.83331 7.08633 7.83331H3.58036C3.32309 7.83331 3.1628 7.55424 3.29243 7.33202L5.04542 4.3269ZM4.55557 6.94442L5.33334 5.61109L6.11112 6.94442H4.55557Z"
      fill={props.fill}
    />
  </svg>
);

Component.defaultProps = {
  width: '16',
  height: '16',
  viewBox: '0 0 16 16',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Component;
