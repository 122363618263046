import { t } from '@lingui/macro';
import { ParameterDefinition } from 'app/apiGenerated/generatedApiTypes';
import { SubmodelInstance } from 'app/generated_types/SimulationModel';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { modelActions } from 'app/slices/modelSlice';
import React from 'react';
import TooltipButton from 'ui/common/Button/TooltipButton';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import { Promote, Reset } from 'ui/common/Icons/Standard';
import { requiredRules } from 'ui/common/Input/inputValidation';
import {
  DetailsInput,
  DetailsLabel,
  DetailsSection,
} from 'ui/modelEditor/DetailsComponents';

interface Props {
  parentPath: string[];
  submodelNode: SubmodelInstance;
  disabled: boolean;
  paramDef: ParameterDefinition;
}

const SubmodelParameterDetail: React.FC<Props> = ({
  parentPath,
  submodelNode,
  disabled,
  paramDef,
}: Props) => {
  const dispatch = useAppDispatch();

  const topLevelModelType = useAppSelector(
    (state) => state.submodels.topLevelModelType,
  );
  const parameters = useAppSelector((state) => state.model.present?.parameters);
  const parameterDefinitions = useAppSelector(
    (state) => state.model.present?.parameterDefinitions,
  );

  let matchingTopLevelParameterExists = false;
  if (topLevelModelType === 'Submodel') {
    matchingTopLevelParameterExists = parameterDefinitions.some(
      (param) => param.name === paramDef.name,
    );
  } else if (topLevelModelType === 'Model') {
    matchingTopLevelParameterExists = parameters.some(
      (param) => param.name === paramDef.name,
    );
  }

  const currentValue =
    submodelNode.parameters[paramDef.name]?.value || paramDef.default_value;

  const updateParameterCurrentValue = (
    paramDefName: string,
    newValue: string,
    inputHeight?: string,
  ) => {
    if (disabled) return;

    dispatch(
      modelActions.updateNodeExtraParameter({
        parentPath,
        nodeUuid: submodelNode.uuid,
        paramName: paramDefName,
        value: newValue,
        inputHeight,
      }),
    );
  };

  const setToDefault = () => {
    if (disabled) return;

    dispatch(
      modelActions.updateNodeExtraParameter({
        parentPath,
        nodeUuid: submodelNode.uuid,
        paramName: paramDef.name,
        value: paramDef.default_value,
      }),
    );
  };

  const promoteParam = () => {
    if (disabled) return;

    // Add the parameter if needed.
    if (topLevelModelType === 'Submodel') {
      if (!matchingTopLevelParameterExists) {
        dispatch(
          modelActions.addParameterDefinition({
            name: paramDef.name,
            defaultValue: currentValue,
            description: paramDef.description,
          }),
        );
      }
    } else if (topLevelModelType === 'Model') {
      if (!matchingTopLevelParameterExists) {
        dispatch(
          modelActions.addModelParameter({
            name: paramDef.name,
            value: currentValue,
          }),
        );
      }
    } else {
      return;
    }

    // Update the value of the submodel instance parameter to match
    // the new promoted parameter.
    dispatch(
      modelActions.updateNodeExtraParameter({
        parentPath,
        nodeUuid: submodelNode.uuid,
        paramName: paramDef.name,
        value: paramDef.name,
      }),
    );
  };

  const isAlreadyPromoted =
    matchingTopLevelParameterExists &&
    submodelNode.parameters[paramDef.name]?.value === paramDef.name;

  // If the value is not set, the simulation will use the default,
  // so we don't need to show the set to default button.
  const isValueSetToDefault =
    !submodelNode.parameters[paramDef.name] ||
    submodelNode.parameters[paramDef.name]?.value === paramDef.default_value;

  return (
    <DetailsSection vertical>
      <DetailsSection>
        <DetailsLabel stretch data-test-id={`param-label-${paramDef.name}`}>
          {paramDef.name}
        </DetailsLabel>
        {!isAlreadyPromoted && (
          <TooltipButton
            tooltip={
              matchingTopLevelParameterExists
                ? t({
                    id: 'modelEditor.blockPropertiesPanel.parameter.setToTopLevelParameter.tooltip',
                    message: 'Set to top level parameter',
                  })
                : t({
                    id: 'modelEditor.blockPropertiesPanel.parameter.promoteParameterToTopLevel.tooltip',
                    message: 'Promote parameter to top level',
                  })
            }
            testId={`promote-parameter-button-${paramDef.name}`}
            variant={ButtonVariants.LargeTertiary}
            Icon={Promote}
            onClick={promoteParam}
          />
        )}
        {!isValueSetToDefault && (
          <TooltipButton
            tooltip={t({
              id: 'modelEditor.blockPropertiesPanel.parameter.resetToDefaultButton.tooltip',
              message: 'Reset to default value',
            })}
            testId={`set-to-default-button-${paramDef.name}`}
            variant={ButtonVariants.LargeTertiary}
            Icon={Reset}
            onClick={setToDefault}
          />
        )}
      </DetailsSection>

      <DetailsInput
        grow
        testId={`param-value-${paramDef.name}`}
        value={currentValue}
        onSubmitValue={(defaultValue, { inputHeight }) =>
          updateParameterCurrentValue(paramDef.name, defaultValue, inputHeight)
        }
        multilineHeight={
          submodelNode.uiprops?.parameter_heights
            ? submodelNode.uiprops?.parameter_heights[paramDef.name]
            : paramDef.uiprops?.default_value_height
        }
        disabled={disabled}
        hasBorder
        allowMultiline
        isMonospaced
        validationRules={requiredRules}
        isEmphasized={!isValueSetToDefault}
      />
    </DetailsSection>
  );
};

export default SubmodelParameterDetail;
