import {
  PostSubmodelsFetchApiResponse,
  SubmodelFetchResponseItem,
  SubmodelFetchItemNotFound,
} from 'app/apiGenerated/generatedApiTypes';
import {
  convertAPISubmodelInfoToSubmodelInfoUI,
  SubmodelInfoUI,
} from 'app/apiTransformers/convertGetSubmodelsListForModelParent';

export enum VersionTagValues {
  LATEST_VERSION = 'latest',
  LATEST_TAGGED_VERSION = 'latest_tagged',
}

export interface VersionedSubmodelsResponse {
  submodelItems: SubmodelInfoItemUI[];
  notFoundItems: SubmodelFetchItemNotFound[] | undefined;
}

export interface SubmodelInfoItemUI {
  submodelId: string;
  versionId: string;
  submodel: SubmodelInfoUI;
  isLatestTaggedVersion: boolean;
}

function convertAPISubmodelFetchItemsToSubmodelFetchItemsUI(
  apiSubmodelItem: SubmodelFetchResponseItem,
): SubmodelInfoItemUI {
  return {
    submodelId: apiSubmodelItem.requested_item.submodel_uuid,
    versionId:
      apiSubmodelItem.snapshot?.uuid || apiSubmodelItem.requested_item.version,
    isLatestTaggedVersion:
      apiSubmodelItem.requested_item.version ===
      VersionTagValues.LATEST_TAGGED_VERSION,
    submodel: convertAPISubmodelInfoToSubmodelInfoUI(apiSubmodelItem.submodel),
  };
}

export function convertPostSubmodelsFetch(
  apiResponse: PostSubmodelsFetchApiResponse,
): VersionedSubmodelsResponse {
  const submodelItems = apiResponse.submodels.map((apiSubmodelItem) =>
    convertAPISubmodelFetchItemsToSubmodelFetchItemsUI(apiSubmodelItem),
  );

  return {
    submodelItems,
    notFoundItems: apiResponse.not_found,
  };
}
