import { useAppSelector } from 'app/hooks';
import { initSubmodelRef, SubmodelRef } from 'app/sliceRefAccess/SubmodelRef';
import React from 'react';

export const SubmodelRefAccess: React.FC = () => {
  const idToVersionIdToSubmodelFull = useAppSelector(
    (state) => state.submodels.idToVersionIdToSubmodelFull,
  );
  const idToVersionIdToSubmodelInfo = useAppSelector(
    (state) => state.submodels.idToVersionIdToSubmodelInfo,
  );
  const idToLatestTaggedVersionId = useAppSelector(
    (state) => state.submodels.idToLatestTaggedVersionId,
  );

  const refs = React.useRef<SubmodelRef>({
    idToVersionIdToSubmodelFull,
    idToVersionIdToSubmodelInfo,
    idToLatestTaggedVersionId,
  });

  React.useEffect(() => {
    initSubmodelRef(refs);
  }, []);

  React.useEffect(() => {
    refs.current.idToVersionIdToSubmodelFull = idToVersionIdToSubmodelFull;
  }, [idToVersionIdToSubmodelFull]);

  React.useEffect(() => {
    refs.current.idToVersionIdToSubmodelInfo = idToVersionIdToSubmodelInfo;
  }, [idToVersionIdToSubmodelInfo]);

  React.useEffect(() => {
    refs.current.idToLatestTaggedVersionId = idToLatestTaggedVersionId;
  }, [idToLatestTaggedVersionId]);

  return null;
};
