/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const DiscreteinitializerInitialstatefalse: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M26.67 18C26.7034 18 26.73 18.0133 26.75 18.04C26.7767 18.06 26.79 18.0867 26.79 18.12V18.89C26.79 18.9233 26.7767 18.9533 26.75 18.98C26.73 19 26.7034 19.01 26.67 19.01H24.83C24.7967 19.01 24.78 19.0267 24.78 19.06V24.88C24.78 24.9133 24.7667 24.9433 24.74 24.97C24.72 24.99 24.6934 25 24.66 25H23.74C23.7067 25 23.6767 24.99 23.65 24.97C23.63 24.9433 23.62 24.9133 23.62 24.88V19.06C23.62 19.0267 23.6034 19.01 23.57 19.01H21.8C21.7667 19.01 21.7367 19 21.71 18.98C21.69 18.9533 21.68 18.9233 21.68 18.89V18.12C21.68 18.0867 21.69 18.06 21.71 18.04C21.7367 18.0133 21.7667 18 21.8 18H26.67Z"
      fill={props.fill}
    />
    <path
      d="M14.7304 8.97C14.697 8.97 14.667 8.96 14.6404 8.94C14.6204 8.91333 14.6104 8.88333 14.6104 8.85V8.09C14.6104 8.05667 14.6204 8.03 14.6404 8.01C14.667 7.98333 14.697 7.97 14.7304 7.97H18.7504C18.7837 7.97 18.8104 7.98333 18.8304 8.01C18.857 8.03 18.8704 8.05667 18.8704 8.09V8.85C18.8704 8.88333 18.857 8.91333 18.8304 8.94C18.8104 8.96 18.7837 8.97 18.7504 8.97H14.7304Z"
      fill={props.fill}
    />
    <path
      d="M14.7304 10.95C14.697 10.95 14.667 10.94 14.6404 10.92C14.6204 10.8933 14.6104 10.8633 14.6104 10.83V10.07C14.6104 10.0367 14.6204 10.01 14.6404 9.99C14.667 9.96333 14.697 9.95 14.7304 9.95H18.7504C18.7837 9.95 18.8104 9.96333 18.8304 9.99C18.857 10.01 18.8704 10.0367 18.8704 10.07V10.83C18.8704 10.8633 18.857 10.8933 18.8304 10.92C18.8104 10.94 18.7837 10.95 18.7504 10.95H14.7304Z"
      fill={props.fill}
    />
    <path
      d="M8.20996 8.3C8.23663 8.27334 8.24996 8.24334 8.24996 8.21V7.51C8.24996 7.47667 8.23663 7.45 8.20996 7.43C8.18996 7.40334 8.16329 7.39 8.12996 7.39H7.10996C7.07663 7.39 7.05996 7.37334 7.05996 7.34V6.17C7.05996 6.13667 7.04663 6.11 7.01996 6.09C6.99996 6.06334 6.97329 6.05 6.93996 6.05H6.06996C6.03663 6.05 6.00663 6.06334 5.97996 6.09C5.95996 6.11 5.94996 6.13667 5.94996 6.17V7.34C5.94996 7.37334 5.93329 7.39 5.89996 7.39H5.32996C5.29663 7.39 5.26663 7.40334 5.23996 7.43C5.21996 7.45 5.20996 7.47667 5.20996 7.51V8.21C5.20996 8.24334 5.21996 8.27334 5.23996 8.3C5.26663 8.32 5.29663 8.33 5.32996 8.33H5.89996C5.93329 8.33 5.94996 8.34667 5.94996 8.38V11.23C5.95663 11.73 6.08996 12.07 6.34996 12.25C6.60996 12.43 7.00329 12.52 7.52996 12.52L8.04996 12.51C8.12996 12.4967 8.16996 12.4533 8.16996 12.38V11.63C8.16996 11.5967 8.15663 11.57 8.12996 11.55C8.10996 11.5233 8.08329 11.51 8.04996 11.51H7.74996C7.50996 11.51 7.33329 11.4533 7.21996 11.34C7.11329 11.2267 7.05996 11.0433 7.05996 10.79V8.38C7.05996 8.34667 7.07663 8.33 7.10996 8.33H8.12996C8.16329 8.33 8.18996 8.32 8.20996 8.3Z"
      fill={props.fill}
    />
    <path
      d="M8.20996 20.8C8.23663 20.7733 8.24996 20.7433 8.24996 20.71V20.01C8.24996 19.9767 8.23663 19.95 8.20996 19.93C8.18996 19.9033 8.16329 19.89 8.12996 19.89H7.10996C7.07663 19.89 7.05996 19.8733 7.05996 19.84V18.67C7.05996 18.6367 7.04663 18.61 7.01996 18.59C6.99996 18.5633 6.97329 18.55 6.93996 18.55H6.06996C6.03663 18.55 6.00663 18.5633 5.97996 18.59C5.95996 18.61 5.94996 18.6367 5.94996 18.67V19.84C5.94996 19.8733 5.93329 19.89 5.89996 19.89H5.32996C5.29663 19.89 5.26663 19.9033 5.23996 19.93C5.21996 19.95 5.20996 19.9767 5.20996 20.01V20.71C5.20996 20.7433 5.21996 20.7733 5.23996 20.8C5.26663 20.82 5.29663 20.83 5.32996 20.83H5.89996C5.93329 20.83 5.94996 20.8467 5.94996 20.88V23.73C5.95663 24.23 6.08996 24.57 6.34996 24.75C6.60996 24.93 7.00329 25.02 7.52996 25.02L8.04996 25.01C8.12996 24.9967 8.16996 24.9533 8.16996 24.88V24.13C8.16996 24.0967 8.15663 24.07 8.12996 24.05C8.10996 24.0233 8.08329 24.01 8.04996 24.01H7.74996C7.50996 24.01 7.33329 23.9533 7.21996 23.84C7.11329 23.7267 7.05996 23.5433 7.05996 23.29V20.88C7.05996 20.8467 7.07663 20.83 7.10996 20.83H8.12996C8.16329 20.83 8.18996 20.82 8.20996 20.8Z"
      fill={props.fill}
    />
    <path
      d="M26.6244 6.47C26.6511 6.44333 26.6644 6.41333 26.6644 6.38V5.62C26.6644 5.58667 26.6511 5.56 26.6244 5.54C26.6044 5.51333 26.5777 5.5 26.5444 5.5H22.0544C22.0211 5.5 21.9911 5.51333 21.9644 5.54C21.9444 5.56 21.9344 5.58667 21.9344 5.62V12.38C21.9344 12.4133 21.9444 12.4433 21.9644 12.47C21.9911 12.49 22.0211 12.5 22.0544 12.5H22.9744C23.0077 12.5 23.0344 12.49 23.0544 12.47C23.0811 12.4433 23.0944 12.4133 23.0944 12.38V9.52C23.0944 9.48667 23.1111 9.47 23.1444 9.47H25.4344C25.4677 9.47 25.4944 9.46 25.5144 9.44C25.5411 9.41333 25.5544 9.38333 25.5544 9.35V8.59C25.5544 8.55667 25.5411 8.53 25.5144 8.51C25.4944 8.48333 25.4677 8.47 25.4344 8.47H23.1444C23.1111 8.47 23.0944 8.45333 23.0944 8.42V6.55C23.0944 6.51667 23.1111 6.5 23.1444 6.5H26.5444C26.5777 6.5 26.6044 6.49 26.6244 6.47Z"
      fill={props.fill}
    />
    <path
      d="M14.6404 21.44C14.667 21.46 14.697 21.47 14.7304 21.47H18.7504C18.7837 21.47 18.8104 21.46 18.8304 21.44C18.857 21.4133 18.8704 21.3833 18.8704 21.35V20.59C18.8704 20.5567 18.857 20.53 18.8304 20.51C18.8104 20.4833 18.7837 20.47 18.7504 20.47H14.7304C14.697 20.47 14.667 20.4833 14.6404 20.51C14.6204 20.53 14.6104 20.5567 14.6104 20.59V21.35C14.6104 21.3833 14.6204 21.4133 14.6404 21.44Z"
      fill={props.fill}
    />
    <path
      d="M14.6404 23.42C14.667 23.44 14.697 23.45 14.7304 23.45H18.7504C18.7837 23.45 18.8104 23.44 18.8304 23.42C18.857 23.3933 18.8704 23.3633 18.8704 23.33V22.57C18.8704 22.5367 18.857 22.51 18.8304 22.49C18.8104 22.4633 18.7837 22.45 18.7504 22.45H14.7304C14.697 22.45 14.667 22.4633 14.6404 22.49C14.6204 22.51 14.6104 22.5367 14.6104 22.57V23.33C14.6104 23.3633 14.6204 23.3933 14.6404 23.42Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M10.283 14.066C9.86304 14.066 9.52904 13.948 9.28104 13.712C9.03704 13.472 8.91504 13.15 8.91504 12.746V11.054C8.91504 10.654 9.03704 10.338 9.28104 10.106C9.52904 9.87 9.86304 9.752 10.283 9.752C10.703 9.752 11.037 9.87 11.285 10.106C11.537 10.338 11.663 10.654 11.663 11.054V12.746C11.663 13.15 11.537 13.472 11.285 13.712C11.037 13.948 10.703 14.066 10.283 14.066ZM10.283 13.46C10.491 13.46 10.657 13.4 10.781 13.28C10.905 13.156 10.967 12.99 10.967 12.782V11.03C10.967 10.822 10.905 10.658 10.781 10.538C10.657 10.414 10.491 10.352 10.283 10.352C10.079 10.352 9.91504 10.414 9.79104 10.538C9.67104 10.658 9.61104 10.822 9.61104 11.03V12.782C9.61104 12.99 9.67104 13.156 9.79104 13.28C9.91504 13.4 10.079 13.46 10.283 13.46Z"
      fill={props.fill}
    />
    <path
      d="M11.6817 22.324C11.6697 22.336 11.6637 22.352 11.6637 22.372L11.6697 25.168C11.6697 25.184 11.6657 25.194 11.6577 25.198C11.6497 25.198 11.6417 25.192 11.6337 25.18L9.91168 22.348C9.89568 22.316 9.86768 22.3 9.82768 22.3H9.29368C9.27368 22.3 9.25568 22.308 9.23968 22.324C9.22768 22.336 9.22168 22.352 9.22168 22.372V26.428C9.22168 26.448 9.22768 26.466 9.23968 26.482C9.25568 26.494 9.27368 26.5 9.29368 26.5H9.84568C9.86568 26.5 9.88168 26.494 9.89368 26.482C9.90968 26.466 9.91768 26.448 9.91768 26.428L9.90568 23.632C9.90568 23.616 9.90968 23.608 9.91768 23.608C9.92568 23.604 9.93368 23.608 9.94168 23.62L11.6697 26.452C11.6857 26.484 11.7137 26.5 11.7537 26.5H12.2877C12.3077 26.5 12.3237 26.494 12.3357 26.482C12.3517 26.466 12.3597 26.448 12.3597 26.428V22.372C12.3597 22.352 12.3517 22.336 12.3357 22.324C12.3237 22.308 12.3077 22.3 12.2877 22.3H11.7357C11.7157 22.3 11.6977 22.308 11.6817 22.324Z"
      fill={props.fill}
    />
  </svg>
);

DiscreteinitializerInitialstatefalse.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default DiscreteinitializerInitialstatefalse;
