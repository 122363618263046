/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Key: React.FC<Props> = (props) => (
  <svg viewBox="0 0 24 24" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M21 12C21 14.2091 19.2091 16 17 16C15.0472 16 13.4213 14.6006 13.0702 12.75H9.25V16C9.25 16.4142 8.91421 16.75 8.5 16.75C8.08579 16.75 7.75 16.4142 7.75 16V12.75H6.25V15C6.25 15.4142 5.91421 15.75 5.5 15.75C5.08579 15.75 4.75 15.4142 4.75 15V12.75H4C3.58579 12.75 3.25 12.4142 3.25 12C3.25 11.5858 3.58579 11.25 4 11.25H13.0702C13.4213 9.39935 15.0472 8 17 8C19.2091 8 21 9.79086 21 12ZM19.5 12C19.5 13.3807 18.3807 14.5 17 14.5C15.6193 14.5 14.5 13.3807 14.5 12C14.5 10.6193 15.6193 9.5 17 9.5C18.3807 9.5 19.5 10.6193 19.5 12Z"
      fill={props.fill}
    />
  </svg>
);

Key.defaultProps = {
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Key;
