import { NodeInstance } from 'app/generated_types/SimulationModel';
import { renderConstants } from 'app/utils/renderConstants';
import { BLOCK_GLYPH_MAP } from './drawPortLabel';

export const GLYPH_MARGIN = 2 * renderConstants.GRID_UNIT_PXSIZE;

export const PORT_BLOCK_WIDTH = 6 * renderConstants.GRID_UNIT_PXSIZE;

export const CONSTANT_BLOCK_MINWIDTH = PORT_BLOCK_WIDTH;

export const getMinimumVisualNodeWidth = (node?: NodeInstance): number => {
  if (!node) return renderConstants.BLOCK_MIN_WIDTH;

  if (node.type === 'core.Constant') {
    return CONSTANT_BLOCK_MINWIDTH;
  }

  if (node.uiprops.show_port_name_labels) {
    const glyphMap = BLOCK_GLYPH_MAP[node.type];
    const nodeHasGlyphs = Boolean(glyphMap);

    const oneSideLabelMargin = nodeHasGlyphs ? GLYPH_MARGIN : 0;

    return renderConstants.BLOCK_MIN_WIDTH + oneSideLabelMargin * 2;
  }

  return renderConstants.BLOCK_MIN_WIDTH;
};

export const getVisualNodeWidth = (node?: NodeInstance): number => {
  if (!node) return renderConstants.BLOCK_MIN_WIDTH;

  if (node.type === 'core.Inport' || node.type === 'core.Outport') {
    return PORT_BLOCK_WIDTH;
  }

  const gridWidth = node.uiprops.grid_width || 0;
  return Math.max(
    getMinimumVisualNodeWidth(node),
    gridWidth * renderConstants.GRID_UNIT_PXSIZE,
  );
};
