import { t } from '@lingui/macro';
import TooltipButton from 'ui/common/Button/TooltipButton';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import * as Icons from 'ui/common/Icons';
import { useModal } from 'ui/common/Modal/useModal';
import NewProjectModal from 'ui/dashboard/projectsList/NewProjectModal';

export const NewProjectButton = () => {
  const { triggerModal } = useModal();

  const createProjectModal = () =>
    triggerModal(
      <NewProjectModal />,
      t({
        id: 'dashboard.projects.newModal.title',
        message: 'Create a new project',
      }),
    );

  const tooltip = t({
    id: 'dashboard.projects.newProjectButton.Label',
    message: 'New Project',
  });

  return (
    <TooltipButton
      tooltip={tooltip}
      Icon={Icons.Standard.Plus}
      largeIcon
      variant={ButtonVariants.LargePrimary}
      onClick={createProjectModal}
      className="tour-onboarding-1"
      testId="create-project-button"
    />
  );
};
