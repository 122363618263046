/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const EyeCrossed: React.FC<Props> = (props) => (
  <svg viewBox="0 0 24 24" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M10.0116 16.1861C10.6387 16.39 11.3068 16.5 12 16.5C14.6798 16.5 16.984 14.8557 18 12.5C17.4542 11.2346 16.5368 10.1745 15.3891 9.46423L14.4429 10.6469C15.2121 11.0844 15.8611 11.7234 16.3204 12.5C15.4271 14.0103 13.8165 15 12 15C11.671 15 11.3487 14.9675 11.0362 14.9054L10.0116 16.1861ZM12.9638 10.0946C12.6513 10.0325 12.329 10 12 10C10.1835 10 8.57291 10.9897 7.67956 12.5C8.13893 13.2766 8.78793 13.9156 9.55706 14.3531L8.61092 15.5358C7.46322 14.8255 6.54576 13.7654 6 12.5C7.01601 10.1443 9.32019 8.5 12 8.5C12.6932 8.5 13.3613 8.61002 13.9884 8.81392L12.9638 10.0946Z"
      fill={props.fill}
    />
    <path
      d="M11.7709 13.987C11.8461 13.9956 11.9225 14 12 14C13.1046 14 14 13.1046 14 12C14 11.7687 13.9607 11.5466 13.8885 11.3399L11.7709 13.987Z"
      fill={props.fill}
    />
    <path
      d="M12.8777 10.2023C12.6127 10.0727 12.3148 10 12 10C10.8954 10 10 10.8954 10 12C10 12.4732 10.1644 12.9081 10.4391 13.2505L12.8777 10.2023Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M15.9685 6.41435C16.292 6.6731 16.3444 7.14507 16.0857 7.46852L8.08565 17.4685C7.82689 17.792 7.35493 17.8444 7.03148 17.5856C6.70803 17.3269 6.65559 16.8549 6.91435 16.5315L14.9143 6.53148C15.1731 6.20803 15.6451 6.15559 15.9685 6.41435Z"
      fill={props.fill}
    />
  </svg>
);

EyeCrossed.defaultProps = {
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default EyeCrossed;
