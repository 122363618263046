import { ModelKind } from 'app/apiGenerated/generatedApiTypes';
import { blockClassExists, blockClassLookup } from 'app/generated_blocks';
import { BlockClassName } from 'app/generated_types/SimulationModel';
import {
  nodeTypeIsIterator,
  nodeTypeIsReferencedSubmodel,
  nodeTypeIsSubdiagram,
} from 'app/helpers';
import { iteratorOnlyBlocks } from './sections/IteratorBlocks';

export function getIsSearchMatch(
  blockClassName: string,
  lowercaseSearchString: string,
): boolean {
  const shortSearchString = lowercaseSearchString
    .trim()
    .replaceAll(' ', '')
    .replaceAll('_', '');
  const searchMatch =
    blockClassName
      .replaceAll(' ', '')
      .replaceAll('_', '')
      .toLowerCase()
      .indexOf(shortSearchString) > -1;

  if (searchMatch) {
    return true;
  }

  const blockClassIsReal = blockClassExists(blockClassName);

  let keywords: string[] = [];

  if (blockClassIsReal) {
    const blockClass = blockClassLookup(blockClassName as BlockClassName);
    if (blockClass.modes.time === 'discrete') {
      keywords.push('discrete');
    }

    if (blockClass.modes.time === 'agnostic') {
      keywords.push('agnostic');
    }

    if (blockClass.base.keywords) {
      keywords = [...keywords, ...blockClass.base.keywords];
    }
  }

  for (let i = 0; i < keywords.length; i++) {
    const keyword = keywords[i];
    if (keyword.indexOf(lowercaseSearchString) > -1) {
      return true;
    }
  }

  return false;
}

export function getIsSubmodelSearchMatch(
  referenceSubmodelName: string,
  lowercaseSearchString: string,
): boolean {
  return (
    referenceSubmodelName.toLowerCase().indexOf(lowercaseSearchString) > -1
  );
}

const submodelOnlyBlocks: BlockClassName[] = ['core.Inport', 'core.Outport'];

/**
 * Defaults to true, unless the block has flags that explicitly disable it.
 *
 * The two caveats are when inside a submodel or an iterator.
 * Those diagram types have blocks that are specifically for them.
 */
export function shouldShowBlock(
  className: BlockClassName,
  developerModeEnabled: boolean,
  topLevelModelKind: ModelKind,
  currentSubdiagramType?: BlockClassName,
  acausalModelingEnabled?: boolean,
): boolean {
  const isInsideSubmodel = nodeTypeIsSubdiagram(currentSubdiagramType);
  const isInsideIterator = nodeTypeIsIterator(currentSubdiagramType);

  if (nodeTypeIsReferencedSubmodel(className)) return false;

  const blockClass = blockClassLookup(className);
  if (blockClass.base.hidden) return false;

  if (
    blockClass.base.namespace.split('.')[0] === 'acausal' &&
    !acausalModelingEnabled
  ) {
    return false;
  }

  if (isInsideSubmodel && submodelOnlyBlocks.includes(className)) {
    return true;
  }

  // handled by its own category
  if (iteratorOnlyBlocks.includes(className)) return false;

  if (isInsideIterator) {
    if (!blockClass.base.supports_iterator) return false;
  }

  if (topLevelModelKind === 'Model' && !blockClass.base.supports_models) {
    return false;
  }

  if (topLevelModelKind === 'Submodel' && !blockClass.base.supports_submodels) {
    return false;
  }

  switch (blockClass.base.feature_level) {
    case 'disabled':
      return false;
    case 'dev':
      return developerModeEnabled;
    default:
      return true;
  }
}
