import { css, Global } from '@emotion/react';
import { DISABLE_ONBOARDING } from 'app/config/globalApplicationConfig';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import React from 'react';
import Joyride, { ACTIONS, CallBackProps, EVENTS, STATUS } from 'react-joyride';

import { uiFlagsActions } from 'app/slices/uiFlagsSlice';
import { stepMap } from './OnboardingSteps';
import OnboardingTooltip from './OnboardingTooltip';
import { waitUntilVisible } from './onboardingUtils';

const FloaterStyles = css`
  .__floater {
    transition: none !important;
  }
`;

enum TourStatus {
  Waiting,
  Touring,
  Finished,
}

type OnboardingState = {
  area: keyof typeof stepMap;
  status: TourStatus;
  stepIndex: number;
};

const Onboarding: React.FC = () => {
  const onboardingEnabled = !DISABLE_ONBOARDING;

  const onboardingStartedManually = useAppSelector(
    (state) => state.uiFlags.onboardingStartedManually,
  );

  const dispatch = useAppDispatch();

  const [tourState, setTourState_raw] = React.useState<OnboardingState>({
    area: 'top',
    status: TourStatus.Waiting,
    stepIndex: 0,
  });

  const updateTourState = (s: Partial<OnboardingState>) =>
    setTourState_raw((cs: OnboardingState) => ({ ...cs, ...s }));

  const moveToArea = React.useCallback((areaName: keyof typeof stepMap) => {
    updateTourState({
      stepIndex: 0,
      area: areaName,
      status: TourStatus.Waiting,
    });

    const nextFirstStep = stepMap[areaName][0];
    if (!nextFirstStep) return;

    // this settimeout is to avoid the case where the target is already visible,
    // which could immediately override our previous update call
    // (react does not sequentially batch them for a single "useState", only one takes precedent per-tick)
    setTimeout(() => {
      waitUntilVisible(nextFirstStep.target as string).then(() => {
        updateTourState({ status: TourStatus.Touring });
      });
    }, 50);
  }, []);

  React.useEffect(() => {
    if (!onboardingEnabled) return;

    if (onboardingStartedManually) {
      moveToArea('top');
      dispatch(uiFlagsActions.setUIFlag({ onboardingStartedManually: false }));
    }
  }, [dispatch, onboardingEnabled, moveToArea, onboardingStartedManually]);

  const currentSteps = stepMap[tourState.area];

  const handleCallback = (data: CallBackProps) => {
    const { action, index, status, type, size } = data;

    if (action === 'skip' || status == STATUS.SKIPPED) {
      updateTourState({ status: TourStatus.Finished });
    } else if (action === 'close' && index >= size - 1) {
      if (tourState.area === 'top') {
        moveToArea('project');
      } else if (tourState.area == 'project') {
        moveToArea('model');
      } else {
        updateTourState({ status: TourStatus.Finished });
      }
    } else if (type === EVENTS.STEP_AFTER) {
      const nextStepIndex = index + (action === ACTIONS.PREV ? -1 : 1);
      updateTourState({
        stepIndex: nextStepIndex,
      });
    }
  };

  React.useEffect(() => {
    // ideally i would like to do this with React Router, but
    // react-router-dom v6.3 literally does not have this functionality
    // despite previous versions having it.
    // to do it w/ react-router-dom v6.3, we'd have to import things
    // that start with "UNSAFE_", which seems not great.
    const stopTour = () => updateTourState({ status: TourStatus.Finished });
    window.addEventListener('popstate', stopTour);
    return () => window.removeEventListener('popstate', stopTour);
  }, []);

  if (!onboardingEnabled) return null;

  return (
    <>
      <Global styles={FloaterStyles} />
      <Joyride
        key={`tour-onboarding-${tourState.stepIndex}`}
        steps={currentSteps}
        run={tourState.status == TourStatus.Touring}
        showProgress
        callback={handleCallback}
        tooltipComponent={OnboardingTooltip}
        spotlightClicks
        disableOverlayClose
        floaterProps={{
          styles: {
            floater: {
              filter:
                'drop-shadow(0px 19px 30px rgba(0, 0, 0, 0.09)) drop-shadow(0px 7.09px 14px rgba(0, 0, 0, 0.0425)) drop-shadow(0px 3.33px 3.9px rgba(0, 0, 0, 0.0225))',
            },
            arrow: {
              length: 8,
              spread: 16,
            },
          },
        }}
        stepIndex={tourState.stepIndex}
        styles={{
          overlay: {
            backgroundColor: 'rgba(65, 58, 112, 0.7)',
            mixBlendMode: 'multiply',
          },
          spotlight: {
            backgroundColor: 'white',
          },
          options: {
            zIndex: 10000,
          },
        }}
      />
    </>
  );
};

export default Onboarding;
