/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Download: React.FC<Props> = (props) => (
  <svg viewBox="0 0 24 24" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M13.2282 5.75C11.7851 5.75 10.5645 6.71073 10.1747 8.0291L9.97495 8.70484L9.28808 8.54753C9.12015 8.50908 8.94468 8.4886 8.76349 8.4886C7.47273 8.4886 6.42636 9.53497 6.42636 10.8257C6.42636 11.0125 6.4481 11.1931 6.48888 11.3657L6.7027 12.2709L5.77273 12.288C5.20626 12.2984 4.75 12.7612 4.75 13.3304C4.75 13.9061 5.21676 14.3729 5.79253 14.3729C5.905 14.3729 6.01505 14.3722 6.12434 14.3715L6.13545 14.3714C6.24016 14.3707 6.34476 14.37 6.44615 14.37H9C9.27614 14.37 9.5 14.5938 9.5 14.87V15.37C9.5 15.6461 9.27614 15.87 9 15.87H6.44615C6.34984 15.87 6.24965 15.8707 6.14321 15.8714L6.13435 15.8714C6.02548 15.8722 5.9107 15.8729 5.79253 15.8729C4.38833 15.8729 3.25 14.7346 3.25 13.3304C3.25 12.2297 3.94931 11.2925 4.92799 10.9386C4.92691 10.9011 4.92636 10.8635 4.92636 10.8257C4.92636 8.70654 6.6443 6.9886 8.76349 6.9886C8.83077 6.9886 8.89768 6.99034 8.96419 6.99378C9.70152 5.37563 11.3326 4.25 13.2282 4.25C15.6261 4.25 17.6031 6.05194 17.8789 8.37556C19.5291 8.79207 20.75 10.2857 20.75 12.065C20.75 14.1664 19.0464 15.87 16.945 15.87H15C14.7239 15.87 14.5 15.6461 14.5 15.37V14.87C14.5 14.5938 14.7239 14.37 15 14.37H16.945C18.218 14.37 19.25 13.338 19.25 12.065C19.25 10.8471 18.3049 9.84922 17.1087 9.76568L16.3962 9.71592L16.4111 9.00181C16.4116 8.97916 16.4118 8.95642 16.4118 8.93361C16.4118 7.17535 14.9865 5.75 13.2282 5.75Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M12 20.75L14.5303 18.2197C14.8232 17.9268 14.8232 17.4519 14.5303 17.159C14.2374 16.8661 13.7626 16.8661 13.4697 17.159L12.75 17.8787V10.6893C12.75 10.2751 12.4142 9.93934 12 9.93934C11.5858 9.93934 11.25 10.2751 11.25 10.6893V17.8787L10.5303 17.159C10.2374 16.8661 9.76256 16.8661 9.46967 17.159C9.17678 17.4519 9.17678 17.9268 9.46967 18.2197L12 20.75Z"
      fill={props.fill}
    />
  </svg>
);

Download.defaultProps = {
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Download;
