import { NodeInstance } from '@collimator/model-schemas-ts';
import { blockClassLookup } from 'app/generated_blocks/';
import { useAppSelector } from 'app/hooks';
import React from 'react';
import { DetailInputRowsSection } from 'ui/modelEditor/DetailsComponents';
import BlockParameter from './BlockParameter';
import { isParameterHidden } from './blockParameterDisplay';

interface Props {
  parentPath: string[];
  selectedNode: NodeInstance;
  canEdit: boolean;
}

export const CommonBlockParametersDetails: React.FC<Props> = ({
  parentPath,
  selectedNode,
  canEdit,
}) => {
  const userOptions = useAppSelector((state) => state.userOptions.options);

  if (!selectedNode) return null;

  const blockClass = blockClassLookup(selectedNode.type);
  if (!blockClass || !blockClass.parameter_definitions) return null;

  return (
    <DetailInputRowsSection>
      {blockClass.parameter_definitions.map(
        (paramDef) =>
          !isParameterHidden(selectedNode, paramDef, userOptions) && (
            <BlockParameter
              parentPath={parentPath}
              selectedNode={selectedNode}
              paramDef={paramDef}
              key={`row-${paramDef.name}-${selectedNode.uuid}`}
              isOptional={paramDef.optional}
              disabled={!canEdit}
            />
          ),
      )}
    </DetailInputRowsSection>
  );
};
