/* eslint-disable import/order */
import styled from '@emotion/styled/macro';
import { t } from '@lingui/macro';
import { UpdateAPIDispatcher } from 'UpdateAPIDispatcher';
import { UpdateParametersAPIDispatcher } from 'UpdateParametersAPIDispatcher';
import { clearModelState } from 'app/api/useModelData';
import { wildcatClassTable } from 'app/generated_wildcat_cache/wildcatClassStrings';
import { getCodeBasedBlockParamKey, nodeTypeCode } from 'app/helpers';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { CurrentModelRefAccess } from 'app/sliceRefAccess/CurrentModelRefAccess';
import { cameraActions } from 'app/slices/cameraSlice';
import {
  entityPreferencesActions,
  selectEntityPrefs,
} from 'app/slices/entityPreferencesSlice';
import { modelActions } from 'app/slices/modelSlice';
import { simulationSignalsActions } from 'app/slices/simulationSignalsSlice';
import { NavbarContext, uiFlagsActions } from 'app/slices/uiFlagsSlice';
import { userPreferencesActions } from 'app/slices/userPreferencesSlice';
import { versionHistoryActions } from 'app/slices/versionHistorySlice';
import { getIsCurrentDiagramReadonly } from 'app/utils/modelDiagramUtils';
import React, { useEffect } from 'react';
import { Outlet, useNavigate, useSearchParams } from 'react-router-dom';
import {
  STATE_MACHINE_EDITOR_BLOCK_QUERY_PARAM,
  StateMachineEditor,
} from 'state_machine_tempdir/StateMachineEditor';
import FooterContainer from 'ui/appBottomBar/FooterContainer';
import {
  CODE_EDITOR_BLOCK_QUERY_PARAM,
  CodeEditor,
  MODEL_SOURCE_CODE_VIEWING_QUERY_PARAM,
} from 'ui/codeEditor/CodeEditor';
import { Spinner } from 'ui/common/Spinner';
import {
  AppContentWithFooterWrapper,
  AppContentWrapper,
} from 'ui/common/layout/appLayout';
import { SmallEmphasis } from 'ui/common/typography/Typography';
import { DragOverlay } from 'ui/dragdrop/DragOverlay';
import { DragProvider } from 'ui/dragdrop/DragProvider';
import { ConvertGroupToSubmodelTracker } from 'ui/modelEditor/ConvertGroupToSubmodelTracker';
import { CurrentDiagramTracker } from 'ui/modelEditor/CurrentDiagramTracker';
import { ModelRestoreTracker } from 'ui/modelEditor/ModelRestoreTracker';
import { NestedSubmodelLoader } from 'ui/modelEditor/NestedSubmodelLoader';
import { NodeNavigator } from 'ui/modelEditor/NodeNavigator';
import { ProjectSubmodelLoader } from 'ui/modelEditor/ProjectSubmodelLoader';
import { VisualizationTraceInitializer } from 'ui/modelEditor/VisualizationTraceInitializer';
import { VisualizationTraceRecordTracker } from 'ui/modelEditor/VisualizationTraceRecordTracker';
import { VisualizationTraceTracker } from 'ui/modelEditor/VisualizationTraceTracker';
import { rendererState } from 'ui/modelRendererInternals/modelRenderer';
import { LayersMenu } from 'ui/navbar/LayersMenu';
import ZoomMenu from 'ui/navbar/ZoomMenu';
import { useModelPermission } from 'ui/permission/useModelPermission';
import { useIsBlockNavState } from 'ui/requirements/blockNav';
import { GLOBAL_ENTITY } from 'ui/userPreferences/globalEntity';
import {
  MODEL_EDITOR_LAYOUT_PREFS_V1_KEY,
  ModelEditorLayoutPrefsV1,
} from 'ui/userPreferences/modelEditorLayoutPrefs';
import {
  PYTHON_SCRIPTBLOCK_PREFS_V1_KEY,
  PythonScriptblockPrefsV1,
} from 'ui/userPreferences/pythonScriptEditorPrefs';
import useEntityPreferences from 'ui/userPreferences/useEntityPreferences';
import { useAppParams } from 'util/useAppParams';
import DiagramRightSidebar from './DiagramRightSidebar';
import ModelEditorBreadcrumb from './ModelEditorBreadcrumb';
import { ModelEditorURLParameterTracker } from './ModelEditorURLParameterTracker';
import ModelLeftSidebar from './ModelLeftSidebar';
import { ModelRendererWrapper } from './ModelRendererWrapper';
import {
  overlayCameraExternalChange,
  RendererOverlay,
} from './RendererOverlay';
import { UpdateSubmodelDispatcher } from './UpdateSubmodelDispatcher';

// used for type inference
const dummyTimeout = setTimeout(() => {}, 0);

const ModelRendererOverlay = styled.div<{ isReadonly: boolean }>`
  flex: 1 0 50px;
  position: relative;
  ${({ isReadonly, theme }) =>
    isReadonly ? '' : `border: 1px solid ${theme.colors.grey[10]}`};
`;

const SideMenusWrapper = styled.div`
  position: absolute;
  top: ${({ theme }) => theme.spacing.normal};
  right: ${({ theme }) => theme.spacing.normal};
  display: flex;
  flex-direction: row;
  pointer-events: auto;
`;

const ModelLoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 0 50px;
  background: rgba(240, 242, 242, 1);
`;

const ReadonlyOverlay = styled.div`
  position: absolute;
  border-style: inset;
  border: solid 2px ${({ theme }) => theme.colors.brand.tertiary.darker};
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: start;
  z-index: 2;
`;

const ReadonlyBanner = styled(SmallEmphasis)`
  background-color: ${({ theme }) => theme.colors.brand.tertiary.darker};
  border-radius: 0 0 2px 2px;
  color: white;
  padding: ${({ theme }) => theme.spacing.small};
`;

type TabDescription = { paramName: string; label: string };

const pythonScriptTabs: Record<string, TabDescription[]> = {
  'core.PythonScript': [
    { paramName: 'user_statements', label: 'Step' },
    { paramName: 'init_script', label: 'Init' },
    // { paramName: 'finalize_script', label: 'Finalize' },
  ],
  'core.CustomLeafSystem': [{ paramName: 'source_code', label: 'Code' }],
};

let hfCameraRaf = -1;

const ModelEditor: React.FC = () => {
  const loadedModelId = useAppSelector(
    (state) => state.modelMetadata.loadedModelId,
  );
  const { modelId, projectId, versionId } = useAppParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  // Left side bar opens to the tree navigator if a block is specified
  const { isBlockNavState, blockInstanceUuid } = useIsBlockNavState();
  const isLeftSidebarOpen = useAppSelector(
    (state) => state.uiFlags.isLeftSidebarOpen,
  );
  useEffect(() => {
    if (isBlockNavState && !!blockInstanceUuid && !isLeftSidebarOpen) {
      dispatch(uiFlagsActions.setUIFlag({ isLeftSidebarOpen: true }));
    }
  }, [dispatch, isBlockNavState, blockInstanceUuid, isLeftSidebarOpen]);

  const areUserOptionsLoaded = useAppSelector(
    (state) => state.userOptions.areUserOptionsLoaded,
  );

  // Parent logic for bottom panel sizing
  const AppContentWithFooterWrapperRef = React.useRef<HTMLDivElement | null>(
    null,
  );

  useEntityPreferences(MODEL_EDITOR_LAYOUT_PREFS_V1_KEY, GLOBAL_ENTITY);
  const layoutPrefs = useAppSelector((state) =>
    selectEntityPrefs(state, MODEL_EDITOR_LAYOUT_PREFS_V1_KEY, GLOBAL_ENTITY),
  ) as ModelEditorLayoutPrefsV1 | null;

  // If the model id changes, clear out the model version history state.
  // If the version id changes, but the version id remains the same,
  // don't clear the version history because the history
  // is still relevant for the current model id.
  React.useEffect(() => {
    if (loadedModelId && modelId !== loadedModelId) {
      dispatch(versionHistoryActions.resetVersionHistoryState());
    }
  }, [dispatch, modelId, loadedModelId]);

  // When we leave the model editor, clear out the model version history state
  // as well as the model state.
  React.useEffect(
    () => () => {
      clearModelState(dispatch);
      dispatch(versionHistoryActions.resetVersionHistoryState());
      // FIXME: as of 2024-08-28 the below comments are probably not making much
      // sense anymore, since I removed the use of some Loader components for the
      // DataExplorer.
      //
      // Clear this state to avoid a vis pref copy bug.
      // Many layers which I attempt to document:
      // 1. Run a model, and `simulationSignalsSlice` will save a mapping `simulationIdToModelVersionId`
      // 2. Duplicate the model along with vis prefs.
      //    The vis prefs will contain stale sim ID since the concept of traces
      //    were introduced for the data explorer where signals from multiple sims could be viewed simulatenously.
      //    (Backend has no idea what prefs contain, so stripping the sim ID upon copy was more work than this fix.)
      // 3. Run the duplicate model, and since the stale model ID is used in the 'redux-state-as-action'
      //    pattern of `modelVersionsSlice`, the 'request' never resolves.
      // 4. The loader component (another anti-pattern) `DataExplorerPlotLoader` is stuck now since it waits on all requested model versions to be loaded first.
      dispatch(simulationSignalsActions.reset());
    },
    [dispatch],
  );

  React.useEffect(() => {
    dispatch(uiFlagsActions.setNavbarContext(NavbarContext.ModelEditor));
    return () => {
      dispatch(uiFlagsActions.setNavbarContext(NavbarContext.None));
      dispatch(userPreferencesActions.unsetLoadModelEditor());
    };
  }, [dispatch]);

  const cameraSettingTimeout = React.useRef(dummyTimeout);

  const [searchParams, setSearchParams] = useSearchParams();

  const sourceCodeViewQuery = searchParams.get(
    MODEL_SOURCE_CODE_VIEWING_QUERY_PARAM,
  );
  const modelLevelSourceCode = useAppSelector(
    (state) => state.uiFlags.modelSourceCodeCache,
  );
  const sourceCodeViewString = React.useMemo(() => {
    if (sourceCodeViewQuery) {
      if (sourceCodeViewQuery === 'model_level') return modelLevelSourceCode;

      return wildcatClassTable[sourceCodeViewQuery];
    }
  }, [modelLevelSourceCode, sourceCodeViewQuery]);

  const codeEditorQuery = searchParams.get(CODE_EDITOR_BLOCK_QUERY_PARAM);
  const codeEditorOpen = !!codeEditorQuery || Boolean(sourceCodeViewString);
  const [codeEditorBlockId, codeEditorParamNameFromQuery] =
    codeEditorQuery?.split('.') ?? [];

  const stateMachineEditorOpen = !!searchParams.get(
    STATE_MACHINE_EDITOR_BLOCK_QUERY_PARAM,
  );

  const selectionParentPath = useAppSelector(
    (state) => state.model.present.selectionParentPath,
  );

  React.useEffect(() => {
    if (codeEditorOpen && codeEditorBlockId) {
      dispatch(
        modelActions.setSelections({
          selectionParentPath,
          selectedBlockIds: [codeEditorBlockId],
        }),
      );
    }
  }, [codeEditorOpen, codeEditorBlockId, selectionParentPath, dispatch]);

  const { canEditCurrentModelVersion, arePermissionsLoaded } =
    useModelPermission(projectId, modelId, versionId);

  const currentSubmodelPath = useAppSelector(
    (state) => state.model.present.currentSubmodelPath,
  );

  const referenceSubmodelId = useAppSelector(
    (state) => state.modelMetadata.currentDiagramSubmodelReferenceId,
  );

  const showLayersMenu = useAppSelector(
    (state) => state.submodels.topLevelModelType === 'Model',
  );

  const setTransform = React.useCallback(
    (transform: { x: number; y: number; zoom: number } | undefined) => {
      if (transform) {
        if (rendererState && rendererState.onTransformUpdate) {
          rendererState.onTransformUpdate(transform);
        }

        // for high-frequency 60fps camera changes (RendererOverlay only)
        window.cancelAnimationFrame(hfCameraRaf);
        hfCameraRaf = window.requestAnimationFrame(() => {
          overlayCameraExternalChange({
            zoom: transform.zoom,
            coord: {
              x: transform.x,
              y: transform.y,
            },
          });
        });

        // for low-frequency camera changes (everywhere else in React)
        clearTimeout(cameraSettingTimeout.current);
        cameraSettingTimeout.current = setTimeout(() => {
          dispatch(
            cameraActions.setEntireTransform({
              coord: {
                x: transform.x,
                y: transform.y,
              },
              zoom: transform.zoom,
              rerender: false,
            }),
          );
        }, 50);
      }
    },
    [dispatch],
  );

  const modelRendererOpen =
    !!loadedModelId && !codeEditorOpen && !stateMachineEditorOpen;
  const isDiagramReadonly = getIsCurrentDiagramReadonly({
    modelId,
    loadedModelId,
    referenceSubmodelId,
    arePermissionsLoaded,
    canEditCurrentModelVersion,
  });
  React.useEffect(() => {
    dispatch(uiFlagsActions.setUIFlag({ canEditModel: !isDiagramReadonly }));
  }, [dispatch, isDiagramReadonly]);

  useEntityPreferences(PYTHON_SCRIPTBLOCK_PREFS_V1_KEY, codeEditorBlockId);
  const pythonScriptBlockPanelPrefs = useAppSelector((state) =>
    selectEntityPrefs(
      state,
      PYTHON_SCRIPTBLOCK_PREFS_V1_KEY,
      codeEditorBlockId,
    ),
  ) as PythonScriptblockPrefsV1 | null;

  const codeBlock = useAppSelector((state) =>
    codeEditorBlockId
      ? state.modelMetadata.currentDiagram?.nodes.find(
          (n) => n.uuid === codeEditorBlockId,
        )
      : undefined,
  );

  const codeLanguage = React.useMemo(
    () =>
      nodeTypeCode(codeBlock?.type) ||
      (sourceCodeViewString ? 'python' : 'plaintext'),
    [codeBlock, sourceCodeViewString],
  );

  const codeBlockParamName =
    codeEditorParamNameFromQuery || getCodeBasedBlockParamKey(codeBlock);
  const editorDisplayPath = codeEditorParamNameFromQuery
    ? `${codeBlock?.name}: ${codeEditorParamNameFromQuery}`
    : codeBlock?.name;

  const codeDefaultValue: string = React.useMemo(() => {
    if (sourceCodeViewString) return sourceCodeViewString;
    if (!codeBlock || !codeBlockParamName) {
      return '';
    }

    return codeBlock?.parameters?.[codeBlockParamName]?.value || '';
  }, [codeBlock, codeBlockParamName, sourceCodeViewString]);

  const codeChangeTimerId = React.useRef<number | null>(null);
  const onChangeBlockCode = React.useCallback(
    (value: string | undefined, paramKey?: string) => {
      if (codeBlock && paramKey) {
        if (codeChangeTimerId.current) {
          window.clearTimeout(codeChangeTimerId.current);
        }

        codeChangeTimerId.current = window.setTimeout(async () => {
          dispatch(
            modelActions.changeBlockParameter({
              parentPath: currentSubmodelPath,
              nodeUuid: codeBlock.uuid,
              paramName: paramKey,
              value: value === undefined ? '' : value,
            }),
          );
        }, 100);
      }
    },
    [codeBlock, currentSubmodelPath, dispatch],
  );

  const externalOverlayRef = React.useRef(null);

  const codeMultiPaneData = React.useMemo(
    () =>
      pythonScriptTabs[codeBlock?.type || '']?.map((tab) => ({
        paramKey: tab.paramName,
        paneTitle: tab.label,
        defaultValue: codeBlock?.parameters?.[tab.paramName]?.value,
        defaultClosed:
          (pythonScriptBlockPanelPrefs?.panelClosedStatesByParamName || {})[
            tab.paramName
          ],
      })),
    [codeBlock, pythonScriptBlockPanelPrefs?.panelClosedStatesByParamName],
  );

  const onCodeBlockPaneStateChange = React.useCallback(
    (paramName: string, closed: boolean) => {
      dispatch(
        entityPreferencesActions.onChangePythonScriptPanelState({
          scriptBlockUuid: codeEditorBlockId,
          panelParamKey: paramName,
          closed,
        }),
      );
    },
    [dispatch, codeEditorBlockId],
  );

  const wildcatCodeClickNav = React.useCallback(
    (word: string) => {
      const navigableBlockTypes = Object.keys(wildcatClassTable);

      if (navigableBlockTypes.includes(word)) {
        searchParams.set(MODEL_SOURCE_CODE_VIEWING_QUERY_PARAM, word);
        setSearchParams(searchParams);
      }
    },
    [setSearchParams, searchParams],
  );

  if (!projectId || !modelId) {
    navigate('/');
    return null;
  }

  return (
    <>
      {/* FIXME: These non-UI components are probably 'bad practice' -- @jp */}
      <ModelEditorURLParameterTracker />
      <ProjectSubmodelLoader />
      <CurrentDiagramTracker />
      <UpdateAPIDispatcher />
      <UpdateParametersAPIDispatcher />
      <UpdateSubmodelDispatcher />
      <ConvertGroupToSubmodelTracker />
      <NodeNavigator />
      <ModelRestoreTracker />
      <CurrentModelRefAccess />

      {/* FIXME: remove these DASH-1751 */}
      <VisualizationTraceInitializer modelId={modelId} />
      <VisualizationTraceTracker modelId={modelId} />
      <VisualizationTraceRecordTracker />

      <DragProvider>
        {/* Model renderer takes up the full content area with other content
            overlays to prevent canvas resizes. Make sure not to pass any
            other props that would cause a re-init of the entire GL context. */}
        {modelRendererOpen && (
          <ModelRendererWrapper
            setTransform={setTransform}
            externalOverlayRef={externalOverlayRef}
          />
        )}

        <AppContentWithFooterWrapper ref={AppContentWithFooterWrapperRef}>
          <AppContentWrapper>
            <Outlet />

            {/* Left sidebar */}
            <ModelLeftSidebar />

            {/* Center content */}
            {!loadedModelId || !areUserOptionsLoaded ? (
              <ModelLoaderWrapper>
                <Spinner />
              </ModelLoaderWrapper>
            ) : codeEditorOpen && (codeEditorQuery || sourceCodeViewString) ? (
              <CodeEditor
                editingDisabled={
                  isDiagramReadonly || Boolean(sourceCodeViewString)
                }
                defaultValue={codeDefaultValue}
                valueOverride={sourceCodeViewString}
                onChangeCode={onChangeBlockCode}
                editorDisplayPath={editorDisplayPath || ''}
                language={codeLanguage}
                multiPaneData={
                  sourceCodeViewString ? undefined : codeMultiPaneData
                }
                onPaneStateChange={onCodeBlockPaneStateChange}
                codeBlockForCompletions={codeBlock}
                onDoubleClickWord={
                  sourceCodeViewString ? wildcatCodeClickNav : undefined
                }
              />
            ) : stateMachineEditorOpen ? (
              <StateMachineEditor readOnly={isDiagramReadonly} />
            ) : modelRendererOpen ? (
              <ModelRendererOverlay
                ref={externalOverlayRef}
                isReadonly={isDiagramReadonly}>
                {isDiagramReadonly && (
                  <ReadonlyOverlay>
                    <ReadonlyBanner>
                      {t({
                        id: 'manageMembers.roleOptions.read_only',
                        message: 'Read only',
                      })}
                    </ReadonlyBanner>
                  </ReadonlyOverlay>
                )}

                <NestedSubmodelLoader />
                <ModelEditorBreadcrumb />
                <SideMenusWrapper>
                  {showLayersMenu && <LayersMenu />}
                  <ZoomMenu />
                </SideMenusWrapper>
              </ModelRendererOverlay>
            ) : null}

            {/* Right sidebar */}
            <DiagramRightSidebar />
          </AppContentWrapper>

          {/* Footer content */}
          <FooterContainer
            enabled={!!loadedModelId}
            savedHeight={layoutPrefs?.bottomPanelHeight}
            parentRef={AppContentWithFooterWrapperRef.current || undefined}
          />
        </AppContentWithFooterWrapper>

        {modelRendererOpen && (
          <RendererOverlay isDiagramReadonly={isDiagramReadonly} />
        )}

        <DragOverlay />
      </DragProvider>
    </>
  );
};

export default ModelEditor;
