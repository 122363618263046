/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Library: React.FC<Props> = (props) => (
  <svg viewBox="0 0 16 16" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M12.0233 4.00062C12.5721 3.98161 13 4.40598 13 4.90384V11.1431C13 11.6138 12.6242 12.0049 12.1344 12.0507C11.3113 12.1277 10.3335 12.2421 9.56951 12.4495C9.18439 12.5541 8.89495 12.6717 8.71256 12.7939C8.53293 12.9142 8.53192 12.984 8.53192 13H7.46809V12.9985C7.46798 12.9941 7.4664 12.9818 7.44827 12.9562C7.42407 12.922 7.37421 12.8708 7.2805 12.8086C7.08905 12.6815 6.78957 12.5574 6.40136 12.4466C5.63218 12.227 4.65814 12.0988 3.89347 12.0416C3.39868 12.0047 3 11.6147 3 11.1273V4.95306C3 4.41214 3.49723 3.98016 4.07495 4.0527C5.00925 4.17001 6.07916 4.35675 6.92631 4.7389C7.31123 4.91254 7.67494 5.13957 7.95855 5.43368C8.21838 5.1125 8.56582 4.85409 8.96232 4.65402C9.80377 4.22944 10.9055 4.03932 12.0233 4.00062ZM7.46809 6.83679C7.46809 6.33482 7.14889 5.95934 6.4705 5.65332C5.81595 5.35805 4.94218 5.1879 4.06383 5.07247V11.0395C4.85231 11.1024 5.86628 11.2372 6.70713 11.4773C6.9708 11.5525 7.23111 11.6419 7.46809 11.7492V6.83679ZM8.53192 11.7331V6.83679C8.53192 6.27541 8.84277 5.85991 9.46081 5.54806C10.0749 5.23818 10.9464 5.0626 11.9362 5.01704V11.0524C11.1266 11.1299 10.1105 11.2503 9.2776 11.4764C9.02001 11.5463 8.76502 11.6301 8.53192 11.7331Z"
      fill={props.fill}
    />
  </svg>
);

Library.defaultProps = {
  width: '16',
  height: '16',
  viewBox: '0 0 16 16',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Library;
