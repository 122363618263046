import { NodeInstance } from '@collimator/model-schemas-ts';
import { blockClassLookup } from 'app/generated_blocks';
import { renderConstants } from 'app/utils/renderConstants';
import * as NVG from 'nanovg-js';
import { drawImage } from './drawImage';
import { RendererState } from './modelRenderer';
import {
  getOrInitLoadImageFromStore,
  RasterLoadState,
} from './rasterTextureStore';

export enum SignalPlotterToggleState {
  Inactive = 'inactive',
  Hidden = 'hidden',
  Partial = 'partial',
  Active = 'active',
}

export type SignalPlotterStatesLUT = { [k: string]: SignalPlotterToggleState };

export function drawSignalPlotter(
  nvg: NVG.Context,
  rs: RendererState,
  posX: number,
  posY: number,
  nodeWidth: number,
  signalPlotterToggleState?: SignalPlotterToggleState,
  leftSide?: boolean,
): void {
  if (!signalPlotterToggleState) {
    signalPlotterToggleState = SignalPlotterToggleState.Inactive;
  } else if (signalPlotterToggleState === SignalPlotterToggleState.Hidden) {
    return;
  }

  const rawScale = Math.round(window.devicePixelRatio * rs.zoom);
  const scale = rawScale > 2 ? 4 : rawScale < 1 ? 1 : rawScale;
  const baseRasterID = `plotter_toggle_${signalPlotterToggleState.toString()}`;
  const scaledRasterID = `${baseRasterID}_${scale}x`;

  const rasterMeta = getOrInitLoadImageFromStore(
    nvg,
    `${process.env.PUBLIC_URL}/assets/${scaledRasterID}.png`,
    scaledRasterID,
    scale,
  );

  if (rasterMeta?.loadState === RasterLoadState.Loaded) {
    const raw_w = rasterMeta.width / scale;
    const raw_h = rasterMeta.height / scale;
    const raw_x = leftSide ? posX - raw_w : posX + nodeWidth;
    const raw_y = posY - renderConstants.SIGNAL_PLOTTER_GRAPHIC_OFFSET_Y;

    const rx = leftSide ? (raw_x + raw_w) * rs.zoom : raw_x * rs.zoom;
    const ry = raw_y * rs.zoom;
    const rw = leftSide ? -raw_w * rs.zoom : raw_w * rs.zoom;
    const rh = raw_h * rs.zoom;

    drawImage(rs, nvg, rasterMeta.id, rx, ry, rw, rh, 0, 1);
  }
}

// Note: passing in visualizerPrefs to trigger re-computes (react re-render)
// when visualizerPrefs change.
export function buildSignalPlotterStatesLUT(
  rs: RendererState,
  visualizerPrefs: typeof rs.refs.current.visualizerPrefs,
  nodes: NodeInstance[],
  parentNamePath: string[],
): SignalPlotterStatesLUT {
  const { getPortsInChartFast } = visualizerPrefs;
  const lut: SignalPlotterStatesLUT = {};

  for (let i = 0; i < nodes.length; i++) {
    const node = nodes[i];
    const blockClass = blockClassLookup(node.type);
    if (!blockClass) continue;

    // fully acausal blocks can not have a signal plotter
    if (blockClass.base.namespace !== 'core') {
      let noSupportedPorts = true;
      for (let i = 0; i < node.outputs.length; i++) {
        const output = node.outputs[i];
        if (output.variant?.variant_kind !== 'acausal') {
          noSupportedPorts = false;
          break;
        }
      }

      if (noSupportedPorts) {
        lut[node.uuid] = SignalPlotterToggleState.Hidden;
        continue;
      }
    }

    const portsInChart = getPortsInChartFast({ node, parentNamePath });
    const allPortsInChart = portsInChart.length === node.outputs.length;
    const anyPortsInChart = portsInChart.length > 0;
    lut[node.uuid] = allPortsInChart
      ? SignalPlotterToggleState.Active
      : anyPortsInChart
      ? SignalPlotterToggleState.Partial
      : SignalPlotterToggleState.Inactive;
  }

  return lut;
}
