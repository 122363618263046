import { useUserOptions } from 'app/api/useUserOptions';
import React from 'react';
import useUserPreferences from 'ui/userPreferences/useUserPreferences';

const UserPreferencesLoader: React.FC = () => {
  useUserOptions();

  const { loadUserPreferences } = useUserPreferences();

  React.useEffect(() => {
    loadUserPreferences();
  }, [loadUserPreferences]);

  return null;
};

export default UserPreferencesLoader;
