// !!!! -- DO NOT MANUALLY EDIT -- !!!!
// This file was generated by something in scripts/generateDefaultBlockData.js
// If you want to change the contents of this file, it should be automated
//
// (You can edit this file locally, but it may not be checked into the codebase -
// PRs with a manual edit of this file are forbidden from being merged.)

import { computationBlockDefaults } from 'app/generated_blocks/computationBlockDefaults';
import type { ComputationBlockClass } from 'app/generated_types/ComputationBlockClass';
import deepmerge from 'deepmerge';

export const RigidBody: ComputationBlockClass = deepmerge(
  computationBlockDefaults,
  {
    "base": {
      "name": "RigidBody",
      "namespace": "core",
      "description": "Models rigid body motion with 3 translational degress of freedom, and optionally 3 rotational degrees of freedom.",
      "keywords": [
        "dof",
        "6dof",
        "six degrees of freedom"
      ],
      "help_url": "https://docs.collimator.ai/using-model-editors/block-library/continuous-blocks#rigid-body",
      "supports_iterator": false
    },
    "modes": {
      "time": "agnostic"
    },
    "ports": {
      "inputs": {
        "static": [
          {
            "name": "force_vector"
          },
          {
            "name": "torque_vector"
          }
        ],
        "conditional": [
          {
            "name": "mass",
            "condition_bool_parameter": "enable_external_mass",
            "order": 2
          },
          {
            "name": "inertia_matrix",
            "condition_bool_parameter": "enable_external_inertia_matrix",
            "order": 3
          }
        ]
      },
      "outputs": {
        "static": [
          {
            "name": "position"
          },
          {
            "name": "orientation"
          },
          {
            "name": "velocity"
          },
          {
            "name": "angular_velocity"
          }
        ],
        "conditional": [
          {
            "name": "position_derivative",
            "condition_bool_parameter": "enable_output_state_derivatives",
            "order": 4
          },
          {
            "name": "orientation_derivative",
            "condition_bool_parameter": "enable_output_state_derivatives",
            "order": 5
          },
          {
            "name": "velocity_derivative",
            "condition_bool_parameter": "enable_output_state_derivatives",
            "order": 6
          },
          {
            "name": "angular_velocity_derivative",
            "condition_bool_parameter": "enable_output_state_derivatives",
            "order": 7
          }
        ]
      }
    },
    "parameter_definitions": [
      {
        "name": "initial_position",
        "data_type": "any",
        "default_value": "[0.0, 0.0, 0.0]",
        "description": "Initial position in inertial frame."
      },
      {
        "name": "initial_orientation",
        "data_type": "any",
        "default_value": "[0.0, 0.0, 0.0]",
        "description": "Initial roll-pitch-yaw orientation."
      },
      {
        "name": "initial_velocity",
        "data_type": "any",
        "default_value": "[0.0, 0.0, 0.0]",
        "description": "Initial velocity in the body frame."
      },
      {
        "name": "initial_angular_velocity",
        "data_type": "any",
        "default_value": "[0.0, 0.0, 0.0]",
        "description": "Initial angular velocity in the body frame."
      },
      {
        "name": "enable_external_mass",
        "data_type": "bool",
        "default_value": "false",
        "description": "Source for the value used as the mass of the body. True=from inport, False=from the mass parameter."
      },
      {
        "name": "mass",
        "data_type": "float",
        "default_value": "1.0",
        "description": "Mass of the body."
      },
      {
        "name": "enable_external_inertia_matrix",
        "data_type": "bool",
        "default_value": "false",
        "description": "Source for the value used as the inertia matrix of the body. True=from inport, False=from the inertia matrix parameter."
      },
      {
        "name": "inertia_matrix",
        "data_type": "any",
        "default_value": "[[1.0,0.0,0.0],[0.0,1.0,0.0],[0.0,0.0,1.0]]",
        "description": "The rotational inertia matrix."
      },
      {
        "name": "enable_output_state_derivatives",
        "data_type": "bool",
        "default_value": "false",
        "description": "User selection for enabling the output port for the blocks state derivatives as a vector."
      },
      {
        "name": "gravity_vector",
        "data_type": "any",
        "default_value": "[0.0, 0.0, 0.0]",
        "description": "Gravity vector in the inertial frame."
      }
    ]
  }
);
