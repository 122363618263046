/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  stroke?: any;
  strokeWidth?: any;
  strokeLinecap?: any;
  className?: string;
}

const AcausalHydraulicHydraulicactuatorlinear: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7 16V11C7 10.4477 7.44772 10 8 10H23C23.5523 10 24 10.4477 24 11V13.5M7 16V21C7 21.5523 7.44772 22 8 22H23C23.5523 22 24 21.5523 24 21V18.5M7 16H4"
      stroke={props.stroke}
      strokeWidth={props.strokeWidth}
      strokeLinecap={props.strokeLinecap}
    />
    <path
      d="M13 16V18.75C13 18.8881 12.8881 19 12.75 19H10.25C10.1119 19 10 18.8881 10 18.75V13.25C10 13.1119 10.1119 13 10.25 13H12.75C12.8881 13 13 13.1119 13 13.25V16ZM13 16H27.5"
      stroke={props.stroke}
      strokeWidth={props.strokeWidth}
      strokeLinecap={props.strokeLinecap}
    />
    <path
      d="M15.5 19.5V18M22 12V14M15.5 14V12.2596C15.5 12.2046 15.576 12.1899 15.5964 12.2411L18.4036 19.2589C18.424 19.3101 18.5 19.2954 18.5 19.2404V18M18.5 14V12.2254C18.5 12.1719 18.5727 12.1558 18.5953 12.2042L21.9047 19.2958C21.9273 19.3442 22 19.3281 22 19.2746V18"
      stroke={props.stroke}
      strokeWidth={props.strokeWidth}
      strokeLinecap={props.strokeLinecap}
    />
  </svg>
);

AcausalHydraulicHydraulicactuatorlinear.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: 'none',
  xmlns: 'http://www.w3.org/2000/svg',
  stroke: '#1D3131',
  strokeWidth: '1.5',
  strokeLinecap: 'round',
  className: '',
};

export default AcausalHydraulicHydraulicactuatorlinear;
