import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  CellMetadata,
  CellRow,
  DataBounds,
  ImageCellMetadata,
  MarkedPoint,
  PartialDataBounds,
  PlotCellMetadata,
  PlotType,
  TextCellMetadata,
  TraceMetadata,
} from 'ui/dataExplorer/dataExplorerTypes';
import { containsMarkedPoint, isSameMarkedPoint } from 'util/markedPointsUtils';
import {
  addTracesInNewPlotCells,
  addTracesInNewRow,
  addTracesToNewCellAtOffset,
  addTracesToNewRowAtOffset,
  addTracesToPlotCell,
  cancelAddSignalRequest,
  moveTracesToNewPlotCellAtOffset,
  moveTracesToNewRowAtOffset,
  moveTraceToPlotCell,
  newSimulationRunCompleted,
  removeTraces,
} from 'util/plotCellUtils';
import { ExplorationAddSignalRequest } from 'util/simulationSignalsTraces';

export interface DataExplorerState {
  // Source of truth data that we save to the backend:
  explorationId: string;
  cellRowIds: string[];
  idToCellRow: Record<string, CellRow>;
  idToCell: Record<string, CellMetadata>;
  idToPlotCell: Record<string, PlotCellMetadata>;
  idToTextCell: Record<string, TextCellMetadata>;
  idToImageCell: Record<string, ImageCellMetadata>;
  idToTrace: Record<string, TraceMetadata>;
  serieIdToMarkedPoints: Record<string, MarkedPoint[]>;

  // Exploration update requests from the user that might require us to load
  // a model version that isn't available yet, so we need to wait before
  // we can fulfill the request.
  // FIXME DASH-1751 remove this
  idToAddSignalRequest: Record<string, ExplorationAddSignalRequest>;

  // If a signal path is unsupported, track it here so the visualizer toggle button
  // can be removed so the user doesn't repeat the attempt to visualize it
  // FIXME DASH-1751 too complicated for what it does (let backend deal with this)
  simulationIdToSignalPathToIsUnsupported: Record<
    string,
    Record<string, boolean>
  >;
}

const initialState: DataExplorerState = {
  explorationId: '',
  cellRowIds: [],
  idToCellRow: {},
  idToCell: {},
  idToPlotCell: {},
  idToTextCell: {},
  idToImageCell: {},
  idToTrace: {},
  serieIdToMarkedPoints: {},
  idToAddSignalRequest: {},
  simulationIdToSignalPathToIsUnsupported: {},
};

const dataExplorerSlice = createSlice({
  name: 'DataExplorerSlice',
  initialState,
  reducers: {
    resetDataExplorerState: () => initialState,

    onDataExplorationLoaded(
      state,
      action: PayloadAction<{
        explorationId: string;
        cellRowIds: string[];
        idToCellRow: Record<string, CellRow>;
        idToCell: Record<string, CellMetadata>;
        idToPlotCell: Record<string, PlotCellMetadata>;
        idToTextCell: Record<string, TextCellMetadata>;
        idToImageCell: Record<string, ImageCellMetadata>;
        idToTrace: Record<string, TraceMetadata>;
        traceIdToMarkedPoints?: Record<string, MarkedPoint[]>;
      }>,
    ) {
      const {
        explorationId,
        cellRowIds,
        idToCellRow,
        idToCell,
        idToPlotCell,
        idToTextCell,
        idToImageCell,
        idToTrace,
        traceIdToMarkedPoints,
      } = action.payload;

      state.cellRowIds = cellRowIds;
      state.idToCellRow = idToCellRow;
      state.idToCell = idToCell;
      state.idToPlotCell = idToPlotCell;
      state.idToTextCell = idToTextCell;
      state.idToImageCell = idToImageCell;
      state.idToTrace = idToTrace;
      if (traceIdToMarkedPoints) {
        state.serieIdToMarkedPoints = traceIdToMarkedPoints;
      } else if (state.explorationId !== explorationId) {
        state.serieIdToMarkedPoints = {};
      }
      state.explorationId = explorationId;
    },

    requestAddSignal(
      state,
      action: PayloadAction<ExplorationAddSignalRequest>,
    ) {
      const addSignalRequest = action.payload;

      state.idToAddSignalRequest[addSignalRequest.id] = addSignalRequest;
    },

    cancelAddSignalRequest(
      state,
      action: PayloadAction<{
        addSignalRequestId: string;
        unsupportedSignalPath?: string;
      }>,
    ) {
      const { addSignalRequestId, unsupportedSignalPath } = action.payload;

      cancelAddSignalRequest(state, addSignalRequestId, unsupportedSignalPath);
    },

    cancelAddSignalRequestForSignalPath(
      state,
      action: PayloadAction<{
        simulationId: string;
        signalPath: string;
      }>,
    ) {
      const { signalPath, simulationId } = action.payload;
      const addSignalRequestsToCancel: ExplorationAddSignalRequest[] =
        Object.values(state.idToAddSignalRequest).filter(
          (addSignalRequest) =>
            addSignalRequest.signalPath === signalPath &&
            addSignalRequest.simulationId === simulationId,
        );
      addSignalRequestsToCancel.forEach((addSignalRequest) => {
        delete state.idToAddSignalRequest[addSignalRequest.id];
      });
    },
    newSimulationRunCompleted,

    removeTraces,

    addTracesInNewPlotCells,

    addTracesInNewRow,
    addTracesToPlotCell,
    addTracesToNewCellAtOffset,
    addTracesToNewRowAtOffset,

    moveTraceToPlotCell,

    // TODO: clean this up after visualizer clean up
    moveTraceToNewCellBefore(
      state: DataExplorerState,
      action: PayloadAction<{
        targetCellId: string;
        traceId: string;
      }>,
    ) {
      const {
        payload: { targetCellId, traceId },
        type,
      } = action;

      moveTracesToNewPlotCellAtOffset(state, {
        payload: { targetCellId, offset: 0, traceIds: [traceId] },
        type,
      });
    },

    moveTraceToNewCellAfter(
      state: DataExplorerState,
      action: PayloadAction<{
        targetCellId: string;
        traceId: string;
      }>,
    ) {
      const {
        payload: { targetCellId, traceId },
        type,
      } = action;

      moveTracesToNewPlotCellAtOffset(state, {
        payload: { targetCellId, offset: 1, traceIds: [traceId] },
        type,
      });
    },

    moveTraceToNewRowBefore(
      state: DataExplorerState,
      action: PayloadAction<{
        targetRowId: string;
        traceId: string;
      }>,
    ) {
      const {
        payload: { targetRowId, traceId },
        type,
      } = action;

      moveTracesToNewRowAtOffset(state, {
        payload: { targetRowId, offset: 0, traceIds: [traceId] },
        type,
      });
    },

    moveTraceToNewRowAfter(
      state: DataExplorerState,
      action: PayloadAction<{
        targetRowId: string;
        traceId: string;
      }>,
    ) {
      const {
        payload: { targetRowId, traceId },
        type,
      } = action;

      moveTracesToNewRowAtOffset(state, {
        payload: { targetRowId, offset: 1, traceIds: [traceId] },
        type,
      });
    },

    setRowHeight(
      state,
      action: PayloadAction<{
        cellRowId: string;
        rowHeight: number;
      }>,
    ) {
      const { cellRowId, rowHeight } = action.payload;
      const cellRow = state.idToCellRow[cellRowId];
      if (cellRow) {
        cellRow.rowHeight = rowHeight;
      }
    },

    setPlotType(
      state,
      action: PayloadAction<{
        traceIds: string[];
        plotType: PlotType;
      }>,
    ) {
      const { traceIds, plotType } = action.payload;
      traceIds.forEach((traceId) => {
        const trace = state.idToTrace[traceId];
        if (trace) {
          trace.plotType = plotType;
        }
      });
    },

    setTraceColor(
      state,
      action: PayloadAction<{
        traceId: string;
        color?: string;
      }>,
    ) {
      const { traceId, color } = action.payload;
      const trace = state.idToTrace[traceId];
      if (trace) {
        trace.color = color;
      }
    },

    setPlotCellZoomBounds(
      state,
      action: PayloadAction<{
        cellId: string;
        bounds: PartialDataBounds;
      }>,
    ) {
      const { cellId, bounds } = action.payload;
      const plotCell = state.idToPlotCell[cellId];
      if (plotCell) {
        plotCell.zoomBounds = bounds;
      } else {
        console.error('Plot cell not found for ID: %s', cellId);
      }
    },
    setPlotCellInitialBounds(
      state,
      action: PayloadAction<{
        cellId: string;
        bounds: DataBounds;
      }>,
    ) {
      const { cellId, bounds } = action.payload;
      const plotCell = state.idToPlotCell[cellId];
      if (plotCell) {
        plotCell.initialBounds = bounds;
      } else {
        console.error('Plot cell not found for ID: %s', cellId);
      }
    },

    addMarkedPoint(
      state,
      action: PayloadAction<{
        seriesId: string;
        point: MarkedPoint;
      }>,
    ) {
      const { seriesId, point } = action.payload;
      const markedPoints = state.serieIdToMarkedPoints[seriesId];
      if (markedPoints) {
        if (!containsMarkedPoint(markedPoints, point)) {
          markedPoints.push(point);
        }
      } else {
        state.serieIdToMarkedPoints[seriesId] = [point];
      }
    },

    removeMarkedPoint(
      state,
      action: PayloadAction<{
        seriesId: string;
        point: MarkedPoint;
      }>,
    ) {
      const { seriesId, point } = action.payload;
      const markedPoints = state.serieIdToMarkedPoints[seriesId];
      if (markedPoints) {
        if (containsMarkedPoint(markedPoints, point)) {
          state.serieIdToMarkedPoints[seriesId] = markedPoints.filter(
            (existingPoint) => !isSameMarkedPoint(existingPoint, point),
          );
        }
      }
    },
  },
});

export const dataExplorerActions = dataExplorerSlice.actions;

export default dataExplorerSlice;
