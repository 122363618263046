// !!!! -- DO NOT MANUALLY EDIT -- !!!!
// This file was generated by something in scripts/generateDefaultBlockData.js
// If you want to change the contents of this file, it should be automated
//
// (You can edit this file locally, but it may not be checked into the codebase -
// PRs with a manual edit of this file are forbidden from being merged.)

import { computationBlockDefaults } from 'app/generated_blocks/computationBlockDefaults';
import type { ComputationBlockClass } from 'app/generated_types/ComputationBlockClass';
import deepmerge from 'deepmerge';

export const AcausalElectricalIntegratedMotor: ComputationBlockClass = deepmerge(
  computationBlockDefaults,
  {
    "base": {
      "name": "IntegratedMotor",
      "namespace": "acausal.electrical",
      "description": "Models Brushless DC Motor+Inverter System."
    },
    "modes": {
      "time": "agnostic"
    },
    "ports": {
      "inputs": {
        "static": [
          {
            "name": "trq_req_norm"
          },
          {
            "name": "pos",
            "variant": {
              "variant_kind": "acausal",
              "acausal_domain": "electrical"
            }
          },
          {
            "name": "neg",
            "variant": {
              "variant_kind": "acausal",
              "acausal_domain": "electrical"
            }
          }
        ]
      },
      "outputs": {
        "static": [
          {
            "name": "shaft",
            "variant": {
              "variant_kind": "acausal",
              "acausal_domain": "rotational"
            }
          }
        ],
        "conditional": [
          {
            "name": "heat",
            "condition_bool_parameter": "enable_heat_port",
            "order": 1,
            "variant": {
              "variant_kind": "acausal",
              "acausal_domain": "thermal"
            }
          }
        ]
      }
    },
    "parameter_definitions": [
      {
        "name": "J",
        "data_type": "float",
        "default_value": "0.1",
        "description": "Rotor inertia."
      },
      {
        "name": "peaktrq_spd",
        "data_type": "any",
        "description": "Speed vector for the peak torque curve, in rads/s."
      },
      {
        "name": "peaktrq_trq",
        "data_type": "any",
        "description": "Torqe vector for the peak torque curve, in Nm."
      },
      {
        "name": "peak_trq",
        "data_type": "float",
        "description": "Peak torque of the motor, in Nm."
      },
      {
        "name": "peak_pwr",
        "data_type": "float",
        "description": "Peak power of the motor, in Watts."
      },
      {
        "name": "peak_spd",
        "data_type": "float",
        "description": "Peak speed of the motor, in rads/s."
      },
      {
        "name": "eff_spd",
        "data_type": "any",
        "description": "Speed vector for the efficiency map, in rads/s."
      },
      {
        "name": "eff_trq",
        "data_type": "any",
        "description": "Torqe vector for the efficiency map, in Nm."
      },
      {
        "name": "eff_eff",
        "data_type": "any",
        "description": "The efficiency map, 0<eff<=1. Dimension len(eff_spd)*len(eff_trq)."
      },
      {
        "name": "eff_k",
        "data_type": "float",
        "description": "Scalar efficiency of the motor, 0<eff<=1."
      },
      {
        "name": "enable_heat_port",
        "data_type": "bool",
        "default_value": "false",
        "description": "User selection for enabling thermal heat port."
      }
    ]
  }
);
