/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Simulation: React.FC<Props> = (props) => (
  <svg viewBox="0 0 16 16" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M7.68377 3.57837C7.88904 3.50995 8.11096 3.50995 8.31623 3.57837L11.8162 4.74504C12.2246 4.88115 12.5 5.26329 12.5 5.69372V10.3063C12.5 10.7367 12.2246 11.1189 11.8162 11.255L8.31623 12.4216C8.11096 12.4901 7.88904 12.4901 7.68377 12.4216L4.18377 11.255C3.77543 11.1189 3.5 10.7367 3.5 10.3063V5.69372C3.5 5.26329 3.77543 4.88115 4.18377 4.74504L7.68377 3.57837ZM4.5 6.02705L7.5 7.02705V11.3063L4.5 10.3063V6.02705ZM8.5 11.3063L11.5 10.3063V6.02705L8.5 7.02705V11.3063ZM8 6.13963L10.4189 5.33334L8 4.52705L5.58114 5.33334L8 6.13963Z"
      fill={props.fill}
    />
  </svg>
);

Simulation.defaultProps = {
  width: '16',
  height: '16',
  viewBox: '0 0 16 16',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Simulation;
