import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';
import Button from 'ui/common/Button/Button';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import { Menu } from 'ui/common/Icons/Standard';
import MenuContent from 'ui/common/Menu/MenuContent';
import PortalMenu, { PortalMenuBackground } from 'ui/common/Menu/PortalMenu';
import { MenuItemConfig } from 'ui/common/Menu/menuItemTypes';
import { usePortalPopup } from 'ui/common/Tooltip/usePortalPopup';
import { useModelPermission } from 'ui/permission/useModelPermission';
import { useAppParams } from 'util/useAppParams';

const ListItemWrapper = styled.li<{ active: boolean }>`
  ${({ active, theme: { colors } }) => `
  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover {
    ${
      active
        ? `background: ${colors.brand.primary.darker};`
        : `background: ${colors.brand.primary.darkest};`
    }
  }
  ${active ? `background: ${colors.brand.primary.darker};` : ''}`}
  padding-left: ${(props) => props.theme.spacing.large};
  padding-right: ${(props) => props.theme.spacing.normal};
`;

const ListItemContent = styled.div`
  flex: 1;
  overflow-wrap: break-word;
  word-break: break-all;
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme.colors.grey[2]};
  padding-top: ${(props) => props.theme.spacing.normal};
  padding-bottom: ${(props) => props.theme.spacing.normal};
`;

const ListItem: React.FC<{
  children: React.ReactNode;
  menu?: MenuItemConfig[];
  Icon?: React.FC<any>;
  IconButton?: React.FC;
  isActive?: boolean;
  onClick?: () => void;
}> = ({ children, menu, Icon, onClick, isActive = false, IconButton }) => {
  const [hovering, setHovering] = React.useState(false);

  const { triggerElRef, isPopupOpen, setIsPopupOpen } =
    usePortalPopup<HTMLButtonElement>();

  const {
    spacing: { large, small },
    colors,
  } = useTheme();

  const { projectId, modelId, versionId } = useAppParams();
  const { canEditCurrentModelVersion } = useModelPermission(
    projectId,
    modelId,
    versionId,
  );

  return (
    <ListItemWrapper
      active={isActive}
      onClick={onClick}
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}>
      {Icon ? (
        <Icon
          width={large}
          height={large}
          fill={colors.grey[10]}
          style={{ marginRight: small }}
        />
      ) : null}
      <ListItemContent>{children}</ListItemContent>
      {menu && canEditCurrentModelVersion && (hovering || isPopupOpen) && (
        <Button
          ref={triggerElRef}
          variant={ButtonVariants.LargeTertiary}
          Icon={Menu}
          onClick={() => setIsPopupOpen(!isPopupOpen)}
        />
      )}
      {menu && isPopupOpen && triggerElRef.current && (
        <PortalMenu
          triggerEl={triggerElRef.current}
          triggerClose={() => setIsPopupOpen(false)}>
          <PortalMenuBackground>
            <MenuContent
              items={menu || []}
              isMenuOpen
              triggerCloseMenu={() => setIsPopupOpen(false)}
            />
          </PortalMenuBackground>
        </PortalMenu>
      )}
      {IconButton && hovering && <IconButton />}
    </ListItemWrapper>
  );
};

export default ListItem;
