import { createAsyncThunk } from '@reduxjs/toolkit';
import type { Coordinate } from 'app/common_types/Coordinate';
import {
  HoverEntity,
  HoverEntityType,
  MouseActions,
} from 'app/common_types/MouseTypes';
import { LinkInstance } from 'app/generated_types/SimulationModel';
import { getCurrentlyEditingModelFromState } from 'app/modelState/ModelState';
import { modelActions } from 'app/slices/modelSlice';
import { store } from 'app/store';
import { snapNumberToGrid } from 'app/utils/modelDataUtils';
import { v4 as uuid } from 'uuid';
import { RendererState } from '../modelRenderer';
import { transitionMouseState } from '../transitionMouseState';
import { reifyAndGetSegmentData } from './reifyLinkAndGetNewSegmentData';

export const tapNormalSegment = (
  rs: RendererState,
  hoveringLinkUuid: string,
  segmentId: number,
  worldCursor: Coordinate,
  overrideLinkData?: LinkInstance[],
) => {
  if (!rs.refs.current.uiFlags.canEditModel) {
    return;
  }

  if (
    rs.mouseState.state !== MouseActions.Idle &&
    rs.mouseState.state !== MouseActions.DrawingLinkFromStart
  ) {
    return;
  }

  const links = overrideLinkData || rs.refs.current.links;
  const hoveringLink = links[rs.refs.current.linksIndexLUT[hoveringLinkUuid]];

  if (!hoveringLink || !hoveringLink.uiprops.segments[segmentId]) return;

  const segmentDirection =
    hoveringLink.uiprops.segments[segmentId].segment_direction;
  let tapCoordinate = 0;

  if (segmentDirection === 'vert') {
    tapCoordinate = worldCursor.y;
  } else {
    tapCoordinate = worldCursor.x;
  }

  if (rs.mouseState.state === MouseActions.Idle) {
    const newLinkUuid = uuid();
    rs.dispatch(
      modelActions.addNewLinkToModel({
        uuid: newLinkUuid,
        linkPayload: {
          source: hoveringLink.src
            ? {
                node: hoveringLink.src.node,
                port: hoveringLink.src.port,
                port_side: hoveringLink.src.port_side,
              }
            : undefined,
        },
        linkType: {
          connection_method: 'link_tap',
          tapped_link_uuid: hoveringLinkUuid,
          tapped_segment: {
            segment_type: 'real',
            tapped_segment_index: segmentId,
            tapped_segment_direction: segmentDirection,
          },
          tap_coordinate: snapNumberToGrid(tapCoordinate),
        },
      }),
    );

    transitionMouseState(rs, {
      state: MouseActions.DrawingLinkFromEnd,
      linkUuid: newLinkUuid,
    });
  }

  if (
    rs.mouseState.state === MouseActions.DrawingLinkFromStart &&
    rs.mouseState.linkUuid !== hoveringLinkUuid
  ) {
    rs.dispatch(
      modelActions.setLinkTap({
        linkUuid: rs.mouseState.linkUuid,
        tapped_link_uuid: hoveringLinkUuid,
        tapped_segment: {
          segment_type: 'real',
          tapped_segment_index: segmentId,
          tapped_segment_direction: segmentDirection,
        },
        tap_coordinate: snapNumberToGrid(tapCoordinate),
      }),
    );

    // we specifically don't use transitionMouseState here
    // because we don't want our link to get deleted
    rs.mouseState = { state: MouseActions.Idle };
  }
};

export const tapFakeSegmentThunk = createAsyncThunk<
  void,
  {
    rs: RendererState;
    hoveringEntity: HoverEntity;
    worldCursor: Coordinate;
  }
>(
  'jackson_unsure_FIXME/tapFakeSegment',
  async ({ rs, hoveringEntity, worldCursor }, { dispatch, getState }) => {
    if (!rs.refs.current.uiFlags.canEditModel) {
      return;
    }

    if (hoveringEntity.entityType !== HoverEntityType.FakeLinkSegment) return;

    const typedGetState: typeof store.getState =
      getState as typeof store.getState;

    let modelState = typedGetState().model.present;
    let model = getCurrentlyEditingModelFromState(modelState);
    if (!model) return;
    const link = model.links.find((l) => l.uuid === hoveringEntity.linkUuid);
    if (!link) return;

    const originalSegmentLength = link.uiprops.segments.length;

    const renderDataIndex =
      rs.linksRenderFrameDataIndexLUT[hoveringEntity.linkUuid];
    const renderData = rs.linksRenderFrameData[renderDataIndex];
    const reifiedLinkData = reifyAndGetSegmentData(hoveringEntity, renderData);

    if (reifiedLinkData) {
      dispatch(
        modelActions.addSegmentsToLink({
          prepend: reifiedLinkData.shouldPrepend,
          linkUuid: hoveringEntity.linkUuid,
          segmentsData: reifiedLinkData.newSegments,
        }),
      );

      if (reifiedLinkData.shouldPrepend) {
        dispatch(
          modelActions.adjustTapLinkSegments({
            tappedLinkUuid: hoveringEntity.linkUuid,
            adjustAmount: reifiedLinkData.newSegments.length,
          }),
        );
      }

      // refresh model data after updating via the above actions
      modelState = typedGetState().model.present;
      model = getCurrentlyEditingModelFromState(modelState);
      let overrideLinks: LinkInstance[] | undefined;

      if (model) {
        overrideLinks = model.links;
      }

      tapNormalSegment(
        rs,
        hoveringEntity.linkUuid,
        reifiedLinkData.newInteractedIndex,
        worldCursor,
        overrideLinks,
      );

      if (originalSegmentLength > 0) {
        dispatch(
          modelActions.simplifyLinkSegments({
            linkUuid: hoveringEntity.linkUuid,
          }),
        );
      }
    }
  },
);
