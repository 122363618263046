/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  className?: string;
}

const Reload: React.FC<Props> = (props) => (
  <svg viewBox="0 0 24 24" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.045 6.58356C12.1888 6.38187 13.3671 6.54748 14.4111 7.05664C15.1865 7.43483 15.8582 7.98815 16.3759 8.66805L15.2372 8.28849C14.8442 8.1575 14.4195 8.36987 14.2885 8.76283C14.1575 9.15579 14.3699 9.58053 14.7628 9.71151L18.4744 10.9487L19.7115 7.23717C19.8425 6.84421 19.6301 6.41947 19.2372 6.28849C18.8442 6.1575 18.4195 6.36987 18.2885 6.76283L17.8314 8.13409C17.8202 8.1129 17.8076 8.09204 17.7938 8.07158C17.1099 7.06293 16.1717 6.24646 15.0686 5.70845C13.74 5.06042 12.2403 4.84966 10.7845 5.10635C9.32869 5.36305 7.99153 6.07403 6.96465 7.1374C6.1121 8.02024 5.50976 9.10834 5.21208 10.2901C5.1109 10.6917 5.40067 11.0725 5.81085 11.1302C6.22103 11.1878 6.59529 10.8998 6.70817 10.5012C6.95414 9.63276 7.4113 8.83421 8.04366 8.17939C8.85049 7.34388 9.90112 6.78525 11.045 6.58356Z"
      fill={props.fill}
    />
    <path
      d="M17.2919 13.4988C17.0459 14.3673 16.5887 15.1658 15.9564 15.8206C15.1496 16.6561 14.0989 17.2148 12.9551 17.4164C11.8112 17.6181 10.6329 17.4525 9.58898 16.9434C8.81356 16.5652 8.14186 16.0119 7.62415 15.3319L8.76285 15.7115C9.15581 15.8425 9.58055 15.6301 9.71153 15.2372C9.84252 14.8442 9.63015 14.4195 9.23719 14.2885L5.52568 13.0513L4.28851 16.7628C4.15752 17.1558 4.36989 17.5805 4.76285 17.7115C5.15581 17.8425 5.58055 17.6301 5.71153 17.2372L6.16862 15.8659C6.17986 15.8871 6.1924 15.908 6.20627 15.9284C6.89017 16.9371 7.82834 17.7536 8.93142 18.2916C10.2601 18.9396 11.7598 19.1504 13.2156 18.8937C14.6714 18.637 16.0085 17.926 17.0354 16.8626C17.8879 15.9798 18.4903 14.8917 18.788 13.7099C18.8891 13.3083 18.5994 12.9275 18.1892 12.8698C17.779 12.8122 17.4048 13.1002 17.2919 13.4988Z"
      fill={props.fill}
    />
  </svg>
);

Reload.defaultProps = {
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  className: '',
};

export default Reload;
