/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Close: React.FC<Props> = (props) => (
  <svg viewBox="0 0 16 16" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M4.97976 4.97977C5.17502 4.78451 5.4916 4.78451 5.68687 4.97977L7.99998 7.29289L10.3131 4.97977C10.5084 4.78451 10.8249 4.78451 11.0202 4.97977C11.2155 5.17504 11.2155 5.49162 11.0202 5.68688L8.70709 7.99999L11.0202 10.3131C11.2155 10.5084 11.2155 10.825 11.0202 11.0202C10.8249 11.2155 10.5084 11.2155 10.3131 11.0202L7.99998 8.7071L5.68687 11.0202C5.4916 11.2155 5.17502 11.2155 4.97976 11.0202C4.7845 10.825 4.7845 10.5084 4.97976 10.3131L7.29287 7.99999L4.97976 5.68688C4.7845 5.49162 4.7845 5.17504 4.97976 4.97977Z"
      fill={props.fill}
    />
  </svg>
);

Close.defaultProps = {
  width: '16',
  height: '16',
  viewBox: '0 0 16 16',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Close;
