import { Project } from 'app/apiTransformers/convertAPIProjectToProject';
import { useModal } from 'ui/common/Modal/useModal';
import CopyPublicProjectModal from 'ui/navbar/actionButtons/CopyPublicProjectModal';

import { t } from '@lingui/macro';
import Button from 'ui/common/Button/Button';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import Copy from 'ui/common/Icons/Standard/Copy';

const CopyPublicProjectButton = ({
  project,
  tint,
}: {
  project: Project;
  tint?: string;
}) => {
  const { triggerModal } = useModal();
  const triggerCopyModal = () => {
    triggerModal(
      <CopyPublicProjectModal project={project} />,
      t({ id: 'copyPublicProjectModal.title', message: 'Copy to My Projects' }),
    );
  };
  return (
    <Button
      onClick={triggerCopyModal}
      Icon={Copy}
      testId="copy-public-project-button"
      tint={tint}
      variant={ButtonVariants.LargePrimary}>
      {t({
        id: 'copyPublicProjectButton.Label',
        message: 'Copy to My Projects',
      })}
    </Button>
  );
};

export default CopyPublicProjectButton;
