/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  className?: string;
}

const Promote: React.FC<Props> = (props) => (
  <svg viewBox="0 0 24 24" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.4697 12.5303C15.7626 12.8232 16.2374 12.8232 16.5303 12.5303C16.8232 12.2374 16.8232 11.7626 16.5303 11.4697L12.7071 7.64644C12.3166 7.25591 11.6834 7.25592 11.2929 7.64644L7.46967 11.4697C7.17678 11.7626 7.17678 12.2374 7.46967 12.5303C7.76256 12.8232 8.23744 12.8232 8.53033 12.5303L12 9.06065L15.4697 12.5303Z"
      fill={props.fill}
    />
    <path
      d="M12.7071 13C12.3166 12.6095 11.6834 12.6095 11.2929 13L8.64645 15.6464C8.45118 15.8417 8.45118 16.1583 8.64645 16.3536C8.84171 16.5488 9.15829 16.5488 9.35355 16.3536L12 13.7071L14.6464 16.3536C14.8417 16.5488 15.1583 16.5488 15.3536 16.3536C15.5488 16.1583 15.5488 15.8417 15.3536 15.6464L12.7071 13Z"
      fill={props.fill}
    />
  </svg>
);

Promote.defaultProps = {
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  className: '',
};

export default Promote;
