import { t } from '@lingui/macro';
import { useProject } from 'app/api/useProject';
import { useProjectActions } from 'app/api/useProjectActions';
import React from 'react';
import TooltipButton from 'ui/common/Button/TooltipButton';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import { Download } from 'ui/common/Icons/Standard';
import { ButtonProps } from './ButtonProps';

export const ProjectDetailExportButton: React.FC<ButtonProps> = ({
  disabled,
}) => {
  const { project } = useProject();
  const { exportProject } = useProjectActions();

  const onExportProject = () => {
    const projectId = project?.uuid;
    const projectTitle = project?.title;
    if (!projectId || !projectTitle) {
      return null;
    }

    const fileName = `${projectTitle}.zip`;
    exportProject(fileName, {
      projectUuid: projectId,
    });
  };

  const tooltip = t({
    id: 'dashboard.projectsDetailActionButtons.ExportButton.Label',
    message: 'Export Project (dev only)',
  });

  return (
    <TooltipButton
      tooltip={tooltip}
      variant={ButtonVariants.SmallSecondary}
      testId="project-detail-export-button"
      Icon={Download}
      largeIcon
      onClick={onExportProject}
      disabled={disabled}
    />
  );
};
