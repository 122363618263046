/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Merge: React.FC<Props> = (props) => (
  <svg viewBox="0 0 24 24" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M9.18934 6.5L12.2197 3.46967C12.5126 3.17678 12.9874 3.17678 13.2803 3.46967C13.5732 3.76256 13.5732 4.23744 13.2803 4.53033L12.0607 5.75L15.5 5.75C17.0188 5.75 18.25 6.98122 18.25 8.5V15.1145C19.2643 15.433 20 16.3806 20 17.5C20 18.8807 18.8807 20 17.5 20C16.1193 20 15 18.8807 15 17.5C15 16.3806 15.7357 15.433 16.75 15.1145V8.5C16.75 7.80964 16.1904 7.25 15.5 7.25L12.0607 7.25L13.2803 8.46967C13.5732 8.76256 13.5732 9.23744 13.2803 9.53033C12.9874 9.82322 12.5126 9.82322 12.2197 9.53033L9.18934 6.5ZM17.5 18.5C18.0523 18.5 18.5 18.0523 18.5 17.5C18.5 16.9477 18.0523 16.5 17.5 16.5C16.9477 16.5 16.5 16.9477 16.5 17.5C16.5 18.0523 16.9477 18.5 17.5 18.5Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M7.25 8.88555C8.26428 8.56698 9 7.61941 9 6.5C9 5.11929 7.88071 4 6.5 4C5.11929 4 4 5.11929 4 6.5C4 7.61941 4.73572 8.56698 5.75 8.88555V15.1145C4.73572 15.433 4 16.3806 4 17.5C4 18.8807 5.11929 20 6.5 20C7.88071 20 9 18.8807 9 17.5C9 16.3806 8.26428 15.433 7.25 15.1145V8.88555ZM7.5 6.5C7.5 7.05228 7.05228 7.5 6.5 7.5C5.94772 7.5 5.5 7.05228 5.5 6.5C5.5 5.94772 5.94772 5.5 6.5 5.5C7.05228 5.5 7.5 5.94772 7.5 6.5ZM6.5 18.5C7.05228 18.5 7.5 18.0523 7.5 17.5C7.5 16.9477 7.05228 16.5 6.5 16.5C5.94772 16.5 5.5 16.9477 5.5 17.5C5.5 18.0523 5.94772 18.5 6.5 18.5Z"
      fill={props.fill}
    />
  </svg>
);

Merge.defaultProps = {
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Merge;
