import { useTheme } from '@emotion/react';
import {
  ChatMessage,
  ChatMessageContent,
  ChatMessageRole,
  ToolCall,
} from 'app/third_party_types/chat-types';
import { ReactElement, ReactNode, memo } from 'react';
import ReactMarkdown from 'react-markdown';
import RehypeKatex from 'rehype-katex';
import RemarkMathPlugin from 'remark-math';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import { Remove } from 'ui/common/Icons/Standard';

import styled from '@emotion/styled';
import { useSimworkerJobRunner } from 'app/SimworkerJobRunner';
import { GptFunction } from 'app/chat/responseStreamParser';
import PythonCode from './PythonCode';
import {
  AiAvatar,
  AssistantRow,
  ChatContentDiv,
  MessageDiv,
  RemoveButton,
  UserAvatar,
  UserRow,
} from './Styles';
import {
  CallCountExceededMessage,
  OpenAiModelNotFoundMessage,
  WelcomeMessage,
} from './SystemMessages';
import { useAuthorizationCheck } from './useAuthorizationCheck';

// eslint-disable-next-line import/no-unassigned-import
import 'katex/dist/katex.min.css';
import { useChatContext } from './ChatContextProvider';
import { useFunctions } from './functions/useFunctions';

const Li = styled.li`
  line-height: 22px;
`;

const Ol = styled.ol`
  list-style-type: decimal;
  line-height: 0px;
`;

const Ul = styled.ul`
  list-style-type: circle;
  line-height: 0px;
`;

const Thumbnail = styled.img`
  max-width: 256px;
  max-height: 256px;
`;

// grayed out text
const AgentDiv = styled.div`
  color: #888;
`;

export interface MessageEdit {
  content?: ChatMessageContent[];
  functionArgs?: object;
  functionResult?: string;
}

interface CodeContentProps {
  id: string;
  code: string;

  useSimResult?: boolean;
  stdout?: string;
  stderr?: string;
  readOnly?: boolean;
  executeFn?: GptFunction;
  onOutputChange?: (code: string, stdout?: string, stderr?: string) => void;
  showStdOut?: boolean;
}

const ToolCodeContent = memo(
  ({
    id,
    code,
    stdout,
    stderr,
    readOnly,
    executeFn,
    onOutputChange,
    showStdOut,
  }: CodeContentProps): ReactElement => {
    const { lastSimResults, lastOptimResults } = useSimworkerJobRunner();
    const signalNames = lastSimResults?.current?.map((s) => s.name);
    const metricNames = lastOptimResults?.current?.metrics?.map((m) => m.name);
    const columns = signalNames?.concat(metricNames ?? []);

    return (
      <div>
        <PythonCode
          id={id}
          readOnly={readOnly}
          value={code}
          onOutputChange={onOutputChange}
          executeFn={executeFn}
          stdout={stdout}
          error={stderr}
          showStdOut={showStdOut}
        />
      </div>
    );
  },
);

interface PlotContentProps {
  plots: string[];
  plotTag: string;
}

const ToolPlotContent = memo(({ plots, plotTag }: PlotContentProps) => {
  const plotRegex = /\[\[plot_id:(\d+)\]\]/g;
  const plotRegexMatch = plotTag.match(plotRegex);
  const match = plotRegexMatch?.[0]; // assume only one plot per message
  if (!match) {
    return <div>{plotTag}</div>;
  }
  const plotId = match.replace('[[plot_id:', '').replace(']]', '');
  const plotIdInt = parseInt(plotId);
  const plotContent =
    plotIdInt < 0 || plotIdInt >= plots.length
      ? `Could not find plot with id ${plotIdInt}.`
      : plots[plotIdInt];
  return (
    <img
      src={`data:image/png;base64, ${plotContent}`}
      data-test-id={`plot-${plotId}`}
      style={{ width: '700px' }}
    />
  );
});

type MessageBlock = {
  content: string;
  isCode?: boolean;
};

const extractCodeBlocks = (text: string): MessageBlock[] => {
  const blocks: MessageBlock[] = [];

  let currentBlock = '';
  let isCode = false;

  const lines = text.split('\n');
  lines.forEach((line) => {
    if (line.startsWith('```') || line.endsWith('```')) {
      if (currentBlock) {
        const pfx =
          line.endsWith('```') && line !== '```'
            ? `\n${line.slice(0, -3)}`
            : '';
        blocks.push({
          content: currentBlock + pfx,
          isCode,
        });
        currentBlock = '';
      }
      isCode = !isCode;
    } else if (line) {
      currentBlock += `${line}\n`;
    }
  });

  if (currentBlock) {
    blocks.push({
      content: currentBlock,
      isCode,
    });
  }

  return blocks;
};

const TextContent = ({ id, content }: { id: number; content: string }) => (
  <>
    {extractCodeBlocks(content).map((block, i) =>
      block.isCode ? (
        <ToolCodeContent
          id={`code-assistant-${id}-${i}`}
          key={i}
          code={block.content}
          readOnly
        />
      ) : (
        <ReactMarkdown
          remarkPlugins={[RemarkMathPlugin]}
          rehypePlugins={[RehypeKatex]}
          components={{
            ol: Ol,
            ul: Ul,
            li: Li,
          }}
          key={i}>
          {block.content}
        </ReactMarkdown>
      ),
    )}
  </>
);

const getProgressStatus = (toolName: string) => {
  switch (toolName) {
    case 'add_custom_block':
    case 'execute_commands':
      return 'Building model';
    case 'execute_python':
      return 'Analyzing results';
    case 'run_simulation':
      return 'Running simulation';
    case 'search_blocks':
      return 'Searching for blocks';
    case 'get_user_model':
      return 'Retrieving model';
    case 'run_optimization':
      return 'Running optimization';
  }
  return '';
};

interface MessageProps {
  msg: ChatMessage;
  index: number;
  disableRemoveButton: boolean;
  onRemoveButtonClick?: (index: number) => void;
  children?: ReactNode;
}

interface AssistantMessageProps extends MessageProps {
  agentId?: string;
  spin?: boolean;
}

const AssistantMessage = memo(
  ({
    spin,
    index,
    disableRemoveButton,
    onRemoveButtonClick,
    children,
    agentId,
  }: AssistantMessageProps) => {
    const theme = useTheme();
    return (
      <AssistantRow key={index}>
        <AiAvatar spin={spin} />
        <MessageDiv>
          {agentId && (
            <AgentDiv>
              <b>Agent:</b> {agentId}
            </AgentDiv>
          )}
          {children}
        </MessageDiv>
        <RemoveButton
          Icon={Remove}
          variant={ButtonVariants.SmallTertiary}
          tint={theme.colors.grey[10]}
          onClick={() => onRemoveButtonClick?.(index)}
          disabled={disableRemoveButton}
        />
      </AssistantRow>
    );
  },
);

const UserMessage = memo(
  ({ msg, index, disableRemoveButton, onRemoveButtonClick }: MessageProps) => {
    const theme = useTheme();
    return (
      <UserRow key={index}>
        <UserAvatar />
        <MessageDiv>
          {msg.content.map((content, i) => (
            <>
              {content.image_url && (
                <Thumbnail
                  src={content.image_url.url}
                  data-test-id={`img-${index}-${i}`}
                  key={`img-${index}-${i}`}
                />
              )}
              {content.text && (
                <TextContent
                  id={index}
                  key={`text-${index}-${i}`}
                  content={content.text}
                />
              )}
            </>
          ))}
        </MessageDiv>
        <RemoveButton
          Icon={Remove}
          variant={ButtonVariants.SmallTertiary}
          disabled={disableRemoveButton}
          tint={theme.colors.grey[10]}
          onClick={() => onRemoveButtonClick?.(index)}
        />
      </UserRow>
    );
  },
);
interface ChatContentProps {
  showAdvancedOptions: boolean;
  onRemoveButtonClick?: (index: number) => void;
  plots: string[];
  addPlot: (plot: string) => number;
  openAiModelNotFound?: boolean;
}

const ChatContent = ({
  showAdvancedOptions,
  onRemoveButtonClick,
  plots,
  openAiModelNotFound,
}: ChatContentProps): ReactElement => {
  const { isAuthorized } = useAuthorizationCheck();
  const { messages, editMessage, isCurrentlyCompleting } = useChatContext();

  // map tool calls to their results
  const toolCallsResults: { [key: string]: ChatMessageContent[] } = {};
  messages.forEach((msg) => {
    if (msg.role === ChatMessageRole.Tool && msg.toolCallId && msg.content) {
      toolCallsResults[msg.toolCallId] = msg.content;
    }
  });

  const functions = useFunctions();

  const editToolCall = (
    toolId: string | undefined,
    code: string,
    stdout?: string,
    stderr?: string,
  ) => {
    if (!toolId) {
      return;
    }
    messages.forEach((msg, i) => {
      if (
        msg.role === ChatMessageRole.Tool &&
        msg.toolCallId &&
        msg.toolCallId === toolId
      ) {
        editMessage(
          {
            content: [{ type: 'text', text: stdout, error: stderr }],
          },
          i,
        );
      }
      if (msg.role === ChatMessageRole.Assistant && msg.toolCalls) {
        msg.toolCalls.forEach((toolCall) => {
          if (toolCall.tool_id === toolId) {
            editMessage(
              {
                toolCalls: [
                  {
                    ...toolCall,
                    arguments: JSON.stringify({ code }),
                  },
                ],
              },
              i,
            );
          }
        });
      }
    });
  };

  const renderToolCallDebug = (toolCall: ToolCall, renderResults: boolean) => (
    <div>
      <div>
        {toolCall.name}({toolCall.arguments})
      </div>
      {toolCall.tool_id &&
        renderResults &&
        toolCallsResults[toolCall.tool_id] &&
        toolCallsResults[toolCall.tool_id].map((res) => (
          <div key={res.text}>
            {res.text}
            {res.error}
          </div>
        ))}
    </div>
  );

  const renderToolCallResult = (toolCall: ToolCall) => {
    let result: string | undefined;
    let error: string | undefined;
    if (toolCall.tool_id && toolCallsResults[toolCall.tool_id]) {
      result = toolCallsResults[toolCall.tool_id]?.[0].text;
      error = toolCallsResults[toolCall.tool_id]?.[0].error;
    }

    let args: any = {};
    let code = '';
    if (toolCall.arguments) {
      try {
        args = JSON.parse(toolCall.arguments);
        code = args.code;
      } catch (e) {
        args = `Couldn't parse arguments: ${e}`;
      }
    }

    switch (toolCall.name) {
      case 'execute_python':
        if (args.has_plot) {
          return (
            <>
              <ToolCodeContent
                id={`code-${toolCall.tool_id}`}
                code={code}
                stdout={error ? '' : result}
                stderr={error}
                readOnly={isCurrentlyCompleting}
                executeFn={functions.plot}
                onOutputChange={(code, stdout, stderr) => {
                  editToolCall(toolCall.tool_id, code, stdout, stderr);
                }}
                useSimResult
              />
              {result && result && (
                <ToolPlotContent plots={plots} plotTag={result} />
              )}
            </>
          );
        }
        return (
          <ToolCodeContent
            id={`code-${toolCall.tool_id}`}
            code={code}
            stdout={error ? '' : result}
            stderr={error}
            readOnly={isCurrentlyCompleting}
            executeFn={functions.execute_python}
            onOutputChange={(code, stdout, stderr) => {
              editToolCall(toolCall.tool_id, code, stdout, stderr);
            }}
            useSimResult
            showStdOut
          />
        );
      case 'execute_commands':
        return (
          <ToolCodeContent
            id={`code-${toolCall.tool_id}`}
            code={code}
            stdout={error ? '' : result}
            stderr={error}
            readOnly={isCurrentlyCompleting}
            executeFn={functions.build_model}
            onOutputChange={(code, stdout, stderr) => {
              editToolCall(toolCall.tool_id, code, stdout, stderr);
            }}
          />
        );
      case 'get_user_model':
        if (error) {
          break;
        }
        return (
          <ToolCodeContent
            id={`code-${toolCall.tool_id}`}
            code={result || ''}
            readOnly
          />
        );
      case 'add_custom_block':
        return (
          <ToolCodeContent
            id={`code-${toolCall.tool_id}`}
            code={code}
            stderr={error}
            readOnly
          />
        );
    }

    return <div>{`${result}\n${error !== undefined ? error : ''}`}</div>;
  };

  const toolOutputVisible = (toolCall: ToolCall) =>
    ['execute_python', 'build_model', 'plot'].includes(toolCall.name);

  const renderToolCall = (
    toolCall: ToolCall,
    msg: ChatMessage,
    isLastMsg: boolean,
    index: number,
    key: string,
  ) => {
    let result: string | undefined;

    if (toolCall.tool_id && toolCallsResults[toolCall.tool_id]) {
      result =
        toolCallsResults[toolCall.tool_id]?.[0].text ||
        toolCallsResults[toolCall.tool_id]?.[0].error;
    }

    const shouldRenderToolCallResult =
      result && (toolOutputVisible(toolCall) || showAdvancedOptions);

    const shouldRender =
      showAdvancedOptions || shouldRenderToolCallResult || isLastMsg;

    if (!shouldRender) {
      return null;
    }

    return (
      <AssistantMessage
        msg={msg}
        index={index}
        key={key}
        agentId={showAdvancedOptions ? msg.agentId : undefined}
        spin={isCurrentlyCompleting && isLastMsg}
        onRemoveButtonClick={onRemoveButtonClick}
        disableRemoveButton={isCurrentlyCompleting}>
        {showAdvancedOptions &&
          renderToolCallDebug(toolCall, !shouldRenderToolCallResult)}
        {shouldRenderToolCallResult && renderToolCallResult(toolCall)}
        {isLastMsg && isCurrentlyCompleting && (
          <>{getProgressStatus(toolCall.name)}...</>
        )}
      </AssistantMessage>
    );
  };

  const renderMessages = () => {
    const visibleMessagesIdx = messages
      .map((msg, i) => (msg.role !== ChatMessageRole.Tool ? i : undefined))
      .filter((i) => i !== undefined) as number[];

    const lastVisibleIdx = visibleMessagesIdx[visibleMessagesIdx.length - 1];

    return messages.map((msg, index) => {
      if (msg.hidden && !showAdvancedOptions) {
        return null;
      }
      switch (msg.role) {
        case ChatMessageRole.User:
          return (
            <UserMessage
              msg={msg}
              index={index}
              key={index}
              disableRemoveButton={isCurrentlyCompleting}
              onRemoveButtonClick={onRemoveButtonClick}
            />
          );
        case ChatMessageRole.Assistant:
          const isLastMsg = index === lastVisibleIdx;
          if (msg.toolCalls && msg.toolCalls.length > 0) {
            return msg.toolCalls.map((toolCall, i) =>
              renderToolCall(toolCall, msg, isLastMsg, index, `${index}-${i}`),
            );
          }
          return (
            <AssistantMessage
              msg={msg}
              index={index}
              key={index}
              spin={isCurrentlyCompleting && isLastMsg}
              agentId={showAdvancedOptions ? msg.agentId : undefined}
              onRemoveButtonClick={onRemoveButtonClick}
              disableRemoveButton={isCurrentlyCompleting}>
              {msg.content[0].text && (
                <TextContent id={index} content={msg.content[0].text} />
              )}
            </AssistantMessage>
          );
      }
      return null;
    });
  };

  return (
    <ChatContentDiv data-test-id="chat-content-div">
      <WelcomeMessage />
      {renderMessages()}
      {!isAuthorized && <CallCountExceededMessage />}
      {openAiModelNotFound && <OpenAiModelNotFoundMessage />}
    </ChatContentDiv>
  );
};

export default ChatContent;
