/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Sawtooth: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M16.2211 4.28332C16.5356 4.38031 16.7501 4.67094 16.7501 5V24.5686L30.3804 4.57749C30.5658 4.30562 30.9067 4.18632 31.2211 4.28332C31.5356 4.38031 31.7501 4.67094 31.7501 5V27C31.7501 27.4142 31.4143 27.75 31.0001 27.75C30.5859 27.75 30.2501 27.4142 30.2501 27V7.43135L16.6197 27.4225C16.4344 27.6944 16.0934 27.8137 15.779 27.7167C15.4646 27.6197 15.2501 27.3291 15.2501 27V7.43135L1.61974 27.4225C1.3864 27.7647 0.919805 27.853 0.57757 27.6197C0.235335 27.3863 0.14706 26.9197 0.380402 26.5775L15.3804 4.57749C15.5658 4.30562 15.9067 4.18632 16.2211 4.28332Z"
      fill={props.fill}
    />
  </svg>
);

Sawtooth.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Sawtooth;
