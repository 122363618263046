/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  stroke?: any;
  strokeWidth?: any;
  strokeLinecap?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const AcausalRotationalSpring: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.5 16.0002C14 16.0002 14 18.0002 15.4998 18.0002C18.5 18.0001 18.5 13.5 15.5 13.5002C10.3547 13.5004 11 20.5002 15.5 20.5002C22 20.5001 21.7325 11.0002 15.5 11.0002C7.50167 11.0002 7 23 15.4998 23.0002C25.3935 23.0003 25.5 8.50015 15.5 8.50015C9.25381 8.50015 7.57968 13.0722 7.14584 15.0768C7.03448 15.5914 6.59091 16.0002 6.06445 16.0002H3"
      stroke={props.stroke}
      strokeWidth={props.strokeWidth}
      strokeLinecap={props.strokeLinecap}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M24.9707 16.75C24.9891 16.5024 24.9984 16.2523 24.9984 16C24.9984 15.7477 24.9891 15.4976 24.9707 15.25H28.4999C28.9141 15.25 29.2499 15.5858 29.2499 16C29.2499 16.4142 28.9141 16.75 28.4999 16.75H24.9707Z"
      fill={props.fill}
    />
  </svg>
);

AcausalRotationalSpring.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  stroke: '#1D3131',
  strokeWidth: '1.5',
  strokeLinecap: 'round',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default AcausalRotationalSpring;
