/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Predictor: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M24.5021 11.9965C24.2262 12.0311 24.0002 11.8022 24.0005 11.5242L24.001 11.0209C24.0012 10.7429 24.229 10.5229 24.5014 10.4675C24.76 10.4149 25.0153 10.32 25.2584 10.1796C25.5016 10.0392 25.7113 9.86565 25.8862 9.668C26.0704 9.45979 26.3748 9.37248 26.6157 9.51125L27.0518 9.76253C27.2927 9.90131 27.3779 10.2115 27.2101 10.4331C26.8951 10.849 26.4936 11.2102 26.0135 11.4874C25.5333 11.7646 25.0197 11.9317 24.5021 11.9965Z"
      fill={props.fill}
    />
    <path
      d="M27.9965 7.49791C28.0311 7.77376 27.8022 7.99977 27.5242 7.99952L27.0209 7.99905C26.7429 7.99879 26.5229 7.77098 26.4675 7.49857C26.4149 7.23995 26.32 6.98474 26.1796 6.74159C26.0392 6.49844 25.8657 6.28869 25.668 6.11381C25.4598 5.9296 25.3725 5.62522 25.5113 5.38434L25.7625 4.94818C25.9013 4.70729 26.2115 4.62211 26.4331 4.78994C26.849 5.10489 27.2102 5.50637 27.4874 5.98654C27.7646 6.46672 27.9317 6.98026 27.9965 7.49791Z"
      fill={props.fill}
    />
    <path
      d="M23.9995 4.47578C23.9998 4.19778 23.7738 3.96893 23.4979 4.00346C22.9803 4.06826 22.4667 4.23536 21.9865 4.51259C21.5064 4.78982 21.1049 5.15101 20.7899 5.56691C20.6221 5.78853 20.7073 6.09869 20.9482 6.23747L21.3843 6.48875C21.6252 6.62752 21.9296 6.54021 22.1138 6.332C22.2887 6.13434 22.4984 5.96075 22.7416 5.82037C22.9847 5.67999 23.24 5.58514 23.4986 5.53251C23.771 5.47708 23.9988 5.25714 23.999 4.97914L23.9995 4.47578Z"
      fill={props.fill}
    />
    <path
      d="M20.0035 8.50209C19.9689 8.22624 20.1978 8.00023 20.4758 8.00048L20.9791 8.00095C21.2571 8.00121 21.4771 8.22902 21.5325 8.50143C21.5851 8.76005 21.68 9.01526 21.8204 9.25841C21.9608 9.50156 22.1343 9.71131 22.332 9.88619C22.5402 10.0704 22.6275 10.3748 22.4887 10.6157L22.2375 11.0518C22.0987 11.2927 21.7885 11.3779 21.5669 11.2101C21.3462 11.0429 21.1408 10.8513 20.9553 10.6366L20.9113 10.7907C20.7975 11.1889 20.3823 11.4195 19.9841 11.3058C19.5858 11.192 19.3552 10.7768 19.469 10.3786L19.5728 10.0151L19.2093 9.91125C18.8111 9.79746 18.5805 9.38235 18.6942 8.98407C18.808 8.58579 19.2232 8.35518 19.6214 8.46897L20.0142 8.58118C20.0103 8.55483 20.0068 8.52846 20.0035 8.50209Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M11.1715 18.4378C10.4402 19.3878 9.2916 20 8 20C5.79086 20 4 18.2091 4 16C4 13.7909 5.79086 12 8 12C10.2091 12 12 13.7909 12 16C12 16.3809 11.9468 16.7494 11.8473 17.0984L19.2969 21.0193L18.7789 19.206C18.6651 18.8078 18.8957 18.3927 19.294 18.2789C19.6922 18.1651 20.1073 18.3957 20.2211 18.794L20.9643 21.3951C21.6979 20.541 22.7858 20 24 20C26.2091 20 28 21.7909 28 24C28 26.2091 26.2091 28 24 28C21.7909 28 20 26.2091 20 24C20 23.7997 20.0147 23.6029 20.0431 23.4105L17.206 24.2211C16.8078 24.3349 16.3927 24.1043 16.2789 23.706C16.1651 23.3078 16.3957 22.8927 16.794 22.7789L18.4957 22.2926L11.1715 18.4378ZM10.5 16C10.5 17.3807 9.38071 18.5 8 18.5C6.61929 18.5 5.5 17.3807 5.5 16C5.5 14.6193 6.61929 13.5 8 13.5C9.38071 13.5 10.5 14.6193 10.5 16ZM26.5 24C26.5 25.3807 25.3807 26.5 24 26.5C22.6193 26.5 21.5 25.3807 21.5 24C21.5 22.6193 22.6193 21.5 24 21.5C25.3807 21.5 26.5 22.6193 26.5 24Z"
      fill={props.fill}
    />
    <path
      d="M19.294 13.7211C19.6922 13.8349 20.1073 13.6043 20.2211 13.206L20.3937 12.6022C20.5075 12.2039 20.2768 11.7888 19.8786 11.675C19.4803 11.5612 19.0652 11.7918 18.9514 12.1901L18.7789 12.794C18.6651 13.1922 18.8957 13.6073 19.294 13.7211Z"
      fill={props.fill}
    />
    <path
      d="M18.325 8.87857C18.4388 8.48029 18.2082 8.06518 17.8099 7.95139L17.206 7.77886C16.8078 7.66506 16.3927 7.89568 16.2789 8.29396C16.1651 8.69224 16.3957 9.10735 16.794 9.22114L17.3978 9.39367C17.7961 9.50746 18.2112 9.27684 18.325 8.87857Z"
      fill={props.fill}
    />
    <path
      d="M18.3358 11.6708C18.7063 11.4856 18.8564 11.0351 18.6712 10.6646C18.4859 10.2941 18.0354 10.1439 17.6649 10.3292L16.865 10.7292C16.4945 10.9144 16.3443 11.3649 16.5295 11.7354C16.7148 12.1059 17.1653 12.2561 17.5358 12.0708L18.3358 11.6708Z"
      fill={props.fill}
    />
    <path
      d="M15.1358 13.2708C15.5063 13.0856 15.6564 12.6351 15.4712 12.2646C15.2859 11.8941 14.8354 11.7439 14.4649 11.9292L12.8649 12.7292C12.4945 12.9144 12.3443 13.3649 12.5295 13.7354C12.7148 14.1059 13.1653 14.2561 13.5358 14.0708L15.1358 13.2708Z"
      fill={props.fill}
    />
  </svg>
);

Predictor.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Predictor;
