/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  stroke?: any;
  strokeWidth?: any;
  strokeLinecap?: any;
  strokeLinejoin?: any;
  className?: string;
}

const Ramp: React.FC<Props> = (props) => (
  <svg viewBox="0 0 34 34" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M32.75 4L9.77564 28H0.75"
      stroke={props.stroke}
      strokeWidth={props.strokeWidth}
      strokeLinecap={props.strokeLinecap}
      strokeLinejoin={props.strokeLinejoin}
    />
  </svg>
);

Ramp.defaultProps = {
  width: '34',
  height: '32',
  viewBox: '0 0 34 32',
  fill: 'none',
  xmlns: 'http://www.w3.org/2000/svg',
  stroke: '#1D3131',
  strokeWidth: '1.5',
  strokeLinecap: 'round',
  strokeLinejoin: 'round',
  className: '',
};

export default Ramp;
