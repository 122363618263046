// !!!! -- DO NOT MANUALLY EDIT -- !!!!
// This file was generated by something in scripts/generateDefaultBlockData.js
// If you want to change the contents of this file, it should be automated
//
// (You can edit this file locally, but it may not be checked into the codebase -
// PRs with a manual edit of this file are forbidden from being merged.)

import { computationBlockDefaults } from 'app/generated_blocks/computationBlockDefaults';
import type { ComputationBlockClass } from 'app/generated_types/ComputationBlockClass';
import deepmerge from 'deepmerge';

export const ReferenceSubmodel: ComputationBlockClass = deepmerge(
  computationBlockDefaults,
  {
    "base": {
      "name": "ReferenceSubmodel",
      "namespace": "core",
      "description": "A submodel that can be referenced multiple times by many models.",
      "extra_parameters": true,
      "feature_level": "dev"
    },
    "ui_defaults": {
      "grid_width": 24,
      "grid_height": 12
    },
    "modes": {
      "time": "any"
    },
    "ports": {
      "inputs": {
        "dynamic": {
          "min_count": 0,
          "max_count": -1,
          "default_count": 1
        }
      },
      "outputs": {
        "dynamic": {
          "min_count": 0,
          "max_count": -1,
          "default_count": 1
        }
      },
      "has_automatic_ports": true
    }
  }
);
