/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Datasink: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M11.25 7.5C11.25 6.80964 11.8096 6.25 12.5 6.25H21C21.1989 6.25 21.3897 6.32902 21.5303 6.46967L26.5303 11.4697C26.671 11.6103 26.75 11.8011 26.75 12V24.5C26.75 25.1904 26.1904 25.75 25.5 25.75H12.5C11.8096 25.75 11.25 25.1904 11.25 24.5V22L12.75 20.5V24.25H25.25V12.75H21C20.5858 12.75 20.25 12.4142 20.25 12V7.75H12.75V14.5L11.25 13V7.5ZM21.75 8.81066L24.1893 11.25H21.75V8.81066Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M23 15H15C14.4477 15 14 15.4477 14 16V22C14 22.5523 14.4477 23 15 23H23C23.5523 23 24 22.5523 24 22V16C24 15.4477 23.5523 15 23 15ZM21 17H23V18H21V17ZM21 19H23V20H21V19ZM21 21H23V22H21V21ZM20 21V22H18V21H20ZM17 21V22H15V21H17ZM17 20H15V19H17V20ZM17 18H15V17H17V18ZM20 17H18V18H20V17ZM20 19H18V20H20V19Z"
      fill={props.fill}
    />
    <path
      d="M9.03033 12.4697C8.81583 12.2552 8.49324 12.191 8.21299 12.3071C7.93273 12.4232 7.75 12.6967 7.75 13V15.25H5V16.75H8.5C8.91421 16.75 9.25 16.4142 9.25 16V14.8107L11.9393 17.5L9.25 20.1893V19C9.25 18.5858 8.91421 18.25 8.5 18.25H5V19.75H7.75V22C7.75 22.3033 7.93273 22.5768 8.21299 22.6929C8.49324 22.809 8.81583 22.7448 9.03033 22.5303L13.5303 18.0303C13.8232 17.7374 13.8232 17.2626 13.5303 16.9697L9.03033 12.4697Z"
      fill={props.fill}
    />
  </svg>
);

Datasink.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Datasink;
