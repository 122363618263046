export enum OpSys {
  macOS,
  iOS,
  Windows,
  Android,
  Linux,
}

// adapted from https://stackoverflow.com/a/38241481
const getOS = (): OpSys | null => {
  const userAgent = window.navigator.userAgent;
  const platform = window.navigator.platform;
  const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
  const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
  const iosPlatforms = ['iPhone', 'iPad', 'iPod'];
  let os = null;

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = OpSys.macOS;
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = OpSys.iOS;
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = OpSys.Windows;
  } else if (/Android/.test(userAgent)) {
    os = OpSys.Android;
  } else if (!os && /Linux/.test(platform)) {
    os = OpSys.Linux;
  }

  return os;
};

export const detectedOS = getOS();
