/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const StateMachine: React.FC<Props> = (props) => (
  <svg viewBox="0 0 16 16" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M2.5 4C2.5 3.17157 3.17157 2.5 4 2.5H7C7.82843 2.5 8.5 3.17157 8.5 4V4.75C9.24014 4.75 9.94911 4.80265 10.4648 5.14648C11.0414 5.53081 11.25 6.17518 11.25 7H11.8242C12.0563 7 12.1631 7.28876 11.9869 7.43981L10.75 8.5L9.51312 7.43981C9.33689 7.28876 9.44371 7 9.67581 7H10.25C10.25 6.32482 10.0836 6.09419 9.91015 5.97853C9.67589 5.82235 9.25986 5.75 8.5 5.75V6C8.5 6.82843 7.82843 7.5 7 7.5H5.25H4C3.17157 7.5 2.5 6.82843 2.5 6V4ZM4 3.5H7C7.27614 3.5 7.5 3.72386 7.5 4V6C7.5 6.27614 7.27614 6.5 7 6.5H4C3.72386 6.5 3.5 6.27614 3.5 6V4C3.5 3.72386 3.72386 3.5 4 3.5Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M7.5 10.25C6.74014 10.25 6.32411 10.1776 6.08985 10.0215C5.91635 9.90581 5.75 9.67518 5.75 9H6.32419C6.55629 9 6.66311 8.71124 6.48688 8.56019L5.25 7.5L4.01312 8.56019C3.83689 8.71124 3.94371 9 4.17581 9H4.75C4.75 9.82482 4.95865 10.4692 5.53515 10.8535C6.05089 11.1974 6.75986 11.25 7.5 11.25V12C7.5 12.8284 8.17157 13.5 9 13.5H12C12.8284 13.5 13.5 12.8284 13.5 12V10C13.5 9.17157 12.8284 8.5 12 8.5H10.75H9C8.17157 8.5 7.5 9.17157 7.5 10V10.25ZM9 9.5H12C12.2761 9.5 12.5 9.72386 12.5 10V12C12.5 12.2761 12.2761 12.5 12 12.5H9C8.72386 12.5 8.5 12.2761 8.5 12V10C8.5 9.72386 8.72386 9.5 9 9.5Z"
      fill={props.fill}
    />
  </svg>
);

StateMachine.defaultProps = {
  width: '16',
  height: '16',
  viewBox: '0 0 16 16',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default StateMachine;
