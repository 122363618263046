// !!!! -- DO NOT MANUALLY EDIT -- !!!!
// This file was generated by something in scripts/generateDefaultBlockData.js
// If you want to change the contents of this file, it should be automated
//
// (You can edit this file locally, but it may not be checked into the codebase -
// PRs with a manual edit of this file are forbidden from being merged.)

import { computationBlockDefaults } from 'app/generated_blocks/computationBlockDefaults';
import type { ComputationBlockClass } from 'app/generated_types/ComputationBlockClass';
import deepmerge from 'deepmerge';

export const Trigonometric: ComputationBlockClass = deepmerge(
  computationBlockDefaults,
  {
    "base": {
      "name": "Trigonometric",
      "namespace": "core",
      "description": "This block outputs the result of a trigonometric function based on the input, e.g. sin(x).",
      "keywords": [
        "sin",
        "cos",
        "tan",
        "sine",
        "cosine",
        "tangent",
        "sinh",
        "cosh",
        "tanh",
        "hyperbolic",
        "arcsin",
        "arccos",
        "arctan",
        "arcsinh",
        "arccosh",
        "arctanh",
        "asin",
        "acos",
        "atan",
        "asinh",
        "acosh",
        "atanh",
        "trigonometric",
        "trigonometry"
      ],
      "help_url": "https://docs.collimator.ai/using-model-editors/block-library/agnostic-blocks#trigonometric"
    },
    "modes": {
      "time": "agnostic"
    },
    "ports": {
      "inputs": {
        "static": [
          {
            "name": "in_0"
          }
        ],
        "conditional": [
          {
            "name": "y",
            "order": 1
          }
        ]
      },
      "outputs": {
        "static": [
          {
            "name": "out_0"
          }
        ]
      }
    },
    "parameter_definitions": [
      {
        "name": "function",
        "data_type": "string",
        "default_value": "sin",
        "description": "Trigonometric function",
        "allowed_values": [
          "sin",
          "cos",
          "tan",
          "asin",
          "acos",
          "atan",
          "sinh",
          "cosh",
          "tanh",
          "asinh",
          "acosh",
          "atanh"
        ]
      }
    ]
  }
);
