/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Statespace: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.5 5.25C7.91421 5.25 8.25 5.58579 8.25 6C8.25 6.41421 7.91421 6.75 7.5 6.75H5.25V25.25H7.5C7.91421 25.25 8.25 25.5858 8.25 26C8.25 26.4142 7.91421 26.75 7.5 26.75H5C4.30964 26.75 3.75 26.1904 3.75 25.5V6.5C3.75 5.80964 4.30964 5.25 5 5.25H7.5Z"
      fill={props.fill}
    />
    <path
      d="M24.5 5.25C24.0858 5.25 23.75 5.58579 23.75 6C23.75 6.41421 24.0858 6.75 24.5 6.75H26.75V25.25H24.5C24.0858 25.25 23.75 25.5858 23.75 26C23.75 26.4142 24.0858 26.75 24.5 26.75H27C27.6904 26.75 28.25 26.1904 28.25 25.5V6.5C28.25 5.80964 27.6904 5.25 27 5.25H24.5Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M13.288 15C13.2213 15 13.178 14.9667 13.158 14.9L12.858 13.92C12.8447 13.8933 12.828 13.88 12.808 13.88H10.348C10.328 13.88 10.3113 13.8933 10.298 13.92L10.008 14.9C9.988 14.9667 9.94467 15 9.878 15H8.608C8.568 15 8.538 14.99 8.518 14.97C8.498 14.9433 8.49467 14.9067 8.508 14.86L10.668 8.1C10.688 8.03333 10.7313 8 10.798 8H12.368C12.4347 8 12.478 8.03333 12.498 8.1L14.658 14.86C14.6647 14.8733 14.668 14.89 14.668 14.91C14.668 14.97 14.6313 15 14.558 15H13.288ZM10.658 12.73C10.6513 12.77 10.6647 12.79 10.698 12.79H12.458C12.498 12.79 12.5113 12.77 12.498 12.73L11.598 9.76C11.5913 9.73333 11.5813 9.72333 11.568 9.73C11.5547 9.73 11.5447 9.74 11.538 9.76L10.658 12.73Z"
      fill={props.fill}
    />
    <path
      d="M10.23 24.28C10.6233 24.48 11.08 24.58 11.6 24.58C12.1133 24.58 12.5633 24.4833 12.95 24.29C13.3433 24.0966 13.6467 23.8233 13.86 23.47C14.08 23.1166 14.19 22.71 14.19 22.25C14.19 22.2233 14.1767 22.2 14.15 22.18C14.13 22.16 14.1033 22.15 14.07 22.15L12.9 22.1C12.82 22.1 12.78 22.1367 12.78 22.21C12.78 22.5567 12.67 22.8366 12.45 23.05C12.2367 23.2633 11.9533 23.37 11.6 23.37C11.24 23.37 10.95 23.2633 10.73 23.05C10.5167 22.8366 10.41 22.5567 10.41 22.21V19.8C10.41 19.4466 10.5167 19.1633 10.73 18.95C10.95 18.7366 11.24 18.63 11.6 18.63C11.9533 18.63 12.2367 18.7366 12.45 18.95C12.67 19.1633 12.78 19.4466 12.78 19.8C12.78 19.8733 12.8133 19.91 12.88 19.91H12.9L14.07 19.84C14.15 19.8266 14.19 19.79 14.19 19.73C14.19 19.27 14.08 18.8666 13.86 18.52C13.6467 18.1666 13.3433 17.8966 12.95 17.71C12.5633 17.5166 12.1133 17.42 11.6 17.42C11.08 17.42 10.6233 17.52 10.23 17.72C9.83667 17.92 9.53333 18.2033 9.32 18.57C9.10667 18.9366 9 19.36 9 19.84V22.15C9 22.63 9.10667 23.0533 9.32 23.42C9.53333 23.7866 9.83667 24.0733 10.23 24.28Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M21.5592 11.35C21.5192 11.37 21.5192 11.39 21.5592 11.41C22.1925 11.7367 22.5092 12.28 22.5092 13.04C22.5092 13.6867 22.2892 14.1767 21.8492 14.51C21.4092 14.8367 20.8325 15 20.1192 15H17.5292C17.4958 15 17.4658 14.99 17.4392 14.97C17.4192 14.9433 17.4092 14.9133 17.4092 14.88V8.12C17.4092 8.08667 17.4192 8.06 17.4392 8.04C17.4658 8.01333 17.4958 8 17.5292 8H20.0392C21.6192 8 22.4092 8.64 22.4092 9.92C22.4092 10.5867 22.1258 11.0633 21.5592 11.35ZM18.8692 9.21C18.8358 9.21 18.8192 9.22667 18.8192 9.26V10.82C18.8192 10.8533 18.8358 10.87 18.8692 10.87H20.0392C20.3525 10.87 20.5958 10.7967 20.7692 10.65C20.9425 10.5033 21.0292 10.3033 21.0292 10.05C21.0292 9.79 20.9425 9.58667 20.7692 9.44C20.5958 9.28667 20.3525 9.21 20.0392 9.21H18.8692ZM20.1192 13.78C20.4258 13.78 20.6658 13.7033 20.8392 13.55C21.0125 13.39 21.0992 13.1733 21.0992 12.9C21.0992 12.6267 21.0125 12.4133 20.8392 12.26C20.6658 12.1 20.4225 12.02 20.1092 12.02H18.8692C18.8358 12.02 18.8192 12.0367 18.8192 12.07V13.73C18.8192 13.7633 18.8358 13.78 18.8692 13.78H20.1192Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M17.4587 24.47C17.4854 24.49 17.5154 24.5 17.5487 24.5H20.0487C20.5487 24.5 20.9854 24.4133 21.3587 24.24C21.7387 24.06 22.032 23.81 22.2387 23.49C22.4454 23.17 22.5487 22.8 22.5487 22.38V19.62C22.5487 19.2 22.4454 18.83 22.2387 18.51C22.032 18.19 21.7387 17.9433 21.3587 17.77C20.9854 17.59 20.5487 17.5 20.0487 17.5H17.5487C17.5154 17.5 17.4854 17.5133 17.4587 17.54C17.4387 17.56 17.4287 17.5867 17.4287 17.62V24.38C17.4287 24.4133 17.4387 24.4433 17.4587 24.47ZM18.8887 23.29C18.8554 23.29 18.8387 23.2733 18.8387 23.24V18.76C18.8387 18.7267 18.8554 18.71 18.8887 18.71H20.0887C20.4087 18.71 20.662 18.8133 20.8487 19.02C21.042 19.2267 21.1387 19.5033 21.1387 19.85V22.15C21.132 22.4967 21.032 22.7733 20.8387 22.98C20.652 23.1867 20.4054 23.29 20.0987 23.29H18.8887Z"
      fill={props.fill}
    />
  </svg>
);

Statespace.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Statespace;
