/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const DropdownExpanded: React.FC<Props> = (props) => (
  <svg viewBox="0 0 16 16" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M3.64645 9.14645C3.84171 8.95118 4.15829 8.95118 4.35355 9.14645L8 12.7929L11.6464 9.14645C11.8417 8.95118 12.1583 8.95118 12.3536 9.14645C12.5488 9.34171 12.5488 9.65829 12.3536 9.85355L8 14.2071L3.64645 9.85355C3.45118 9.65829 3.45118 9.34171 3.64645 9.14645Z"
      fill={props.fill}
    />
  </svg>
);

DropdownExpanded.defaultProps = {
  width: '16',
  height: '16',
  viewBox: '0 0 16 16',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default DropdownExpanded;
