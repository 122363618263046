/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const PidP: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1 4.75C1.13807 4.75 1.25 4.86193 1.25 5V25.7927C1.32819 25.765 1.41234 25.75 1.5 25.75H2.57788C2.71708 25.4511 2.86031 24.996 2.99948 24.3765C3.16499 23.6398 3.31087 22.7377 3.44763 21.7211C3.61134 20.5042 3.7572 19.1634 3.90802 17.777C4.01102 16.8302 4.11634 15.8621 4.23123 14.8978C4.51033 12.5551 4.8441 10.2545 5.33462 8.53013C5.57851 7.67276 5.87656 6.90008 6.26195 6.3266C6.64488 5.75677 7.2112 5.25 8 5.25C8.87957 5.25 9.55887 5.65532 10.088 6.16078C10.5934 6.64349 11.0143 7.26934 11.3792 7.81182L11.3924 7.83142C11.7828 8.41183 12.1148 8.90216 12.4822 9.25849C12.8323 9.59808 13.1485 9.75 13.5 9.75C14.4354 9.75 15.3107 9.43063 16.302 9.04987L16.414 9.00681C17.3314 8.65371 18.3803 8.25 19.5 8.25C20.0499 8.25 20.8374 8.24849 21.7608 8.24671C24.1858 8.24205 27.5481 8.23559 30.0044 8.25001C30.4186 8.25245 30.7524 8.5902 30.75 9.0044C30.7476 9.41861 30.4098 9.75242 29.9956 9.74999C27.5457 9.7356 24.197 9.74204 21.7728 9.7467C20.8463 9.74848 20.0546 9.75 19.5 9.75C18.6655 9.75 17.8489 10.0626 16.8398 10.4501C15.8785 10.8194 14.7537 11.25 13.5 11.25C12.6282 11.25 11.9577 10.8394 11.4379 10.3353C10.9503 9.86239 10.5392 9.25092 10.1807 8.71757L10.1477 8.66858C9.75892 8.09054 9.42387 7.60069 9.05198 7.24547C8.69781 6.90718 8.37043 6.75 8 6.75C7.93359 6.75 7.76411 6.78057 7.50694 7.16325C7.25223 7.54228 7.00589 8.13726 6.77738 8.94055C6.32311 10.5375 6.00007 12.7302 5.7207 15.0752C5.61014 16.0033 5.50605 16.959 5.40321 17.9035C5.24992 19.3111 5.09937 20.6936 4.93423 21.9211C4.79527 22.9542 4.64259 23.906 4.463 24.7053C4.28758 25.4861 4.07165 26.1933 3.77686 26.6898C3.76456 26.7105 3.7518 26.7306 3.73862 26.75H31C31.1381 26.75 31.25 26.8619 31.25 27C31.25 27.1381 31.1381 27.25 31 27.25H0.75V5C0.75 4.86193 0.861929 4.75 1 4.75Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M19.6796 15.588C20.1836 15.588 20.6276 15.692 21.0116 15.9C21.3956 16.108 21.6916 16.404 21.8996 16.788C22.1156 17.164 22.2236 17.6 22.2236 18.096C22.2236 18.584 22.1156 19.016 21.8996 19.392C21.6836 19.768 21.3756 20.06 20.9756 20.268C20.5836 20.468 20.1276 20.568 19.6076 20.568H17.6876C17.6476 20.568 17.6276 20.588 17.6276 20.628V23.856C17.6276 23.896 17.6116 23.932 17.5796 23.964C17.5556 23.988 17.5236 24 17.4836 24H16.3796C16.3396 24 16.3036 23.988 16.2716 23.964C16.2476 23.932 16.2356 23.896 16.2356 23.856V15.732C16.2356 15.692 16.2476 15.66 16.2716 15.636C16.3036 15.604 16.3396 15.588 16.3796 15.588H19.6796ZM19.4756 19.44C19.8836 19.44 20.2116 19.32 20.4596 19.08C20.7076 18.84 20.8316 18.524 20.8316 18.132C20.8316 17.732 20.7076 17.408 20.4596 17.16C20.2116 16.912 19.8836 16.788 19.4756 16.788H17.6876C17.6476 16.788 17.6276 16.808 17.6276 16.848V19.38C17.6276 19.42 17.6476 19.44 17.6876 19.44H19.4756Z"
      fill={props.fill}
    />
  </svg>
);

PidP.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default PidP;
