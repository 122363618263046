/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  className?: string;
}

const Mujoco: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.67027 3.12824L4 27.2688H7.89189L10.0649 14.9581L15.9676 29L22.1946 14.9581L24.1081 27.2688H28L23.7838 3L16.0649 20.3761L8.67027 3.12824Z"
      fill={props.fill}
    />
  </svg>
);

Mujoco.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  className: '',
};

export default Mujoco;
