// !!!! -- DO NOT MANUALLY EDIT -- !!!!
// This file was generated by something in scripts/generateDefaultBlockData.js
// If you want to change the contents of this file, it should be automated
//
// (You can edit this file locally, but it may not be checked into the codebase -
// PRs with a manual edit of this file are forbidden from being merged.)

import { computationBlockDefaults } from 'app/generated_blocks/computationBlockDefaults';
import type { ComputationBlockClass } from 'app/generated_types/ComputationBlockClass';
import deepmerge from 'deepmerge';

export const Ros2Subscriber: ComputationBlockClass = deepmerge(
  computationBlockDefaults,
  {
    "base": {
      "name": "Ros2Subscriber",
      "namespace": "core",
      "description": "Listen to messages on a ROS2 topic.",
      "feature_level": "dev"
    },
    "modes": {
      "time": "discrete"
    },
    "ports": {
      "outputs": {
        "dynamic": {
          "default_count": 1,
          "max_count": -1,
          "min_count": 0,
          "parameter_definitions": [
            {
              "name": "field_name",
              "data_type": "string",
              "default_value": "x",
              "display_full_width": true
            },
            {
              "name": "dtype",
              "data_type": "string",
              "value_is_a_type": true,
              "default_value": "float64",
              "display_full_width": true
            }
          ]
        }
      }
    },
    "parameter_definitions": [
      {
        "name": "topic",
        "data_type": "string",
        "default_value": "/turtle1/pose"
      },
      {
        "name": "msg_type",
        "display_name": "Message Type",
        "$comment": "FIXME UI should allow for user-defined strings here",
        "description": "Type name of the message to listen to. Select from the list.",
        "data_type": "string",
        "default_value": "turtlesim/msg/Pose",
        "allowed_values": [
          "turtlesim/msg/Pose",
          "geometry_msgs/msg/Twist"
        ]
      },
      {
        "name": "read_before_start",
        "description": "Read the latest message on the topic before starting the simulation.",
        "data_type": "bool",
        "default_value": "true"
      }
    ]
  }
);
