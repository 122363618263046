import { useAppDispatch, useAppSelector } from 'app/hooks';
import { dataExplorerActions } from 'app/slices/dataExplorerSlice';
import { selectEntityPrefs } from 'app/slices/entityPreferencesSlice';
import React from 'react';
import useEntityPreferences from 'ui/userPreferences/useEntityPreferences';
import {
  MODEL_EDITOR_VISUALIZER_EXPLORATION_ID,
  VISUALIZER_PREFS_V1_KEY,
  VisualizerPrefsV1,
} from 'ui/userPreferences/visualizerPrefs';

interface Props {
  modelId: string;
}

export const VisualizationTraceInitializer: React.FC<Props> = ({ modelId }) => {
  const dispatch = useAppDispatch();

  const loadedModelId = useAppSelector(
    (state) => state.modelMetadata.loadedModelId,
  );

  const [hasLoadedPrefsForCurrentModel, setHasLoadedPrefsForCurrentModel] =
    React.useState<boolean>(false);

  useEntityPreferences(VISUALIZER_PREFS_V1_KEY, modelId);
  const visualizerPrefs = useAppSelector((state) =>
    selectEntityPrefs(state, VISUALIZER_PREFS_V1_KEY, modelId),
  ) as VisualizerPrefsV1 | null;

  React.useEffect(() => {
    setHasLoadedPrefsForCurrentModel(false);
  }, [dispatch, modelId]);

  // When visualizer prefs load, initialize the data explorer data.
  React.useEffect(() => {
    // Don't load the prefs until the model finishes loading
    // to ensure the prefs don't get unset by the model-clearing-before-loading
    // actions (see clearModelState function).
    if (loadedModelId !== modelId) {
      return;
    }

    if (visualizerPrefs && modelId && hasLoadedPrefsForCurrentModel === false) {
      // If the visualizer prefs are up to date, then update the data exploration
      dispatch(
        dataExplorerActions.onDataExplorationLoaded({
          explorationId: MODEL_EDITOR_VISUALIZER_EXPLORATION_ID,
          cellRowIds: visualizerPrefs.cellRowIds || [],
          idToCellRow: visualizerPrefs.idToCellRow || {},
          idToCell: visualizerPrefs.idToCell || {},
          idToPlotCell: visualizerPrefs.idToPlotCell || {},
          idToTextCell: visualizerPrefs.idToTextCell || {},
          idToImageCell: visualizerPrefs.idToImageCell || {},
          idToTrace: visualizerPrefs.idToTrace || {},
        }),
      );

      setHasLoadedPrefsForCurrentModel(true);
    }
  }, [
    dispatch,
    visualizerPrefs,
    modelId,
    loadedModelId,
    hasLoadedPrefsForCurrentModel,
  ]);

  return null;
};
