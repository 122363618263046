/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  className?: string;
}

const Pytorch: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M22.0214 12.2682L24.085 10.1889C28.5944 14.7582 28.5944 22.0486 24.1105 26.5923C19.6776 31.1359 12.3913 31.1359 7.882 26.5923C3.37267 22.0486 3.37267 14.7069 7.882 10.1632L15.9835 2V6.0816L9.84369 12.2682C6.45532 15.631 6.45532 21.1245 9.84369 24.5386C13.1811 27.9528 18.6331 27.9528 22.0214 24.5386C25.4098 21.1758 25.4098 15.6823 22.0214 12.2682Z"
      fill={props.fill}
    />
    <path
      d="M21.5371 8.18624C21.5371 9.02252 20.8643 9.70043 20.0343 9.70043C19.2043 9.70043 18.5315 9.02252 18.5315 8.18624C18.5315 7.34997 19.2043 6.67203 20.0343 6.67203C20.8643 6.67203 21.5371 7.34997 21.5371 8.18624Z"
      fill={props.fill}
    />
  </svg>
);

Pytorch.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  className: '',
};

export default Pytorch;
