/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  className?: string;
}

const Rename: React.FC<Props> = (props) => (
  <svg viewBox="0 0 24 24" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 5.5C12.2576 5.5 12.6897 5.50277 13.0501 5.60577C13.2285 5.65671 13.3465 5.72095 13.4139 5.78486C13.4666 5.83472 13.5 5.89253 13.5 6V18C13.5 18.1075 13.4666 18.1653 13.4139 18.2152C13.3465 18.2791 13.2285 18.3433 13.0501 18.3942C12.6897 18.4972 12.2576 18.5 12 18.5C11.7239 18.5 11.5 18.7239 11.5 19C11.5 19.2761 11.7239 19.5 12 19.5L12.0132 19.5C12.2625 19.5001 12.8195 19.5002 13.3249 19.3558C13.547 19.2923 13.7905 19.1927 14 19.0286C14.2095 19.1927 14.453 19.2923 14.6751 19.3558C15.1805 19.5002 15.7375 19.5001 15.9868 19.5L16 19.5C16.2761 19.5 16.5 19.2761 16.5 19C16.5 18.7239 16.2761 18.5 16 18.5C15.7424 18.5 15.3103 18.4972 14.9499 18.3942C14.7715 18.3433 14.6535 18.2791 14.5861 18.2152C14.5334 18.1653 14.5 18.1075 14.5 18V6C14.5 5.89724 14.5323 5.84023 14.588 5.78863C14.659 5.72288 14.782 5.65766 14.9643 5.6062C15.3314 5.50256 15.7653 5.5 16 5.5C16.2761 5.5 16.5 5.27615 16.5 5C16.5 4.72386 16.2761 4.5 16 4.5L15.9887 4.5C15.7587 4.49996 15.2026 4.49986 14.6926 4.64381C14.4666 4.7076 14.2175 4.80807 14.0037 4.97426C13.7933 4.8085 13.5483 4.70808 13.3249 4.64424C12.8195 4.49984 12.2625 4.49995 12.0132 4.5L12 4.5C11.7239 4.5 11.5 4.72386 11.5 5C11.5 5.27615 11.7239 5.5 12 5.5Z"
      fill={props.fill}
    />
    <path
      d="M5 9H12.5V10.5H5.5V15.5H12.5V17H5C4.44772 17 4 16.5523 4 16V10C4 9.44772 4.44772 9 5 9Z"
      fill={props.fill}
    />
    <path
      d="M18.5 15.5H15.5V17H19C19.5523 17 20 16.5523 20 16V10C20 9.44772 19.5523 9 19 9H15.5V10.5H18.5V15.5Z"
      fill={props.fill}
    />
  </svg>
);

Rename.defaultProps = {
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  className: '',
};

export default Rename;
