/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Integrations: React.FC<Props> = (props) => (
  <svg viewBox="0 0 24 24" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.7167 3.95936C13.8596 3.42589 14.4079 3.10931 14.9414 3.25225L19.1558 4.3815C19.6893 4.52444 20.0059 5.07278 19.8629 5.60625L18.7337 9.82068C18.5908 10.3541 18.0424 10.6707 17.5089 10.5278L13.2945 9.39854C12.761 9.25559 12.4445 8.70726 12.5874 8.17379L13.7167 3.95936Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M4 5.00001C4 4.44772 4.44772 4.00001 5 4.00001H10C10.5523 4.00001 11 4.44772 11 5.00001V10C11 10.5523 10.5523 11 10 11H5C4.44772 11 4 10.5523 4 10V5.00001ZM5.5 9.50001V5.50001H9.5V9.50001H5.5Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M4 13C4 12.4477 4.44772 12 5 12H10C10.5523 12 11 12.4477 11 13V18C11 18.5523 10.5523 19 10 19H5C4.44772 19 4 18.5523 4 18V13ZM5.5 17.5V13.5H9.5V17.5H5.5Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M12 13C12 12.4477 12.4477 12 13 12H18C18.5523 12 19 12.4477 19 13V18C19 18.5523 18.5523 19 18 19H13C12.4477 19 12 18.5523 12 18V13ZM13.5 17.5V13.5H17.5V17.5H13.5Z"
      fill={props.fill}
    />
  </svg>
);

Integrations.defaultProps = {
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Integrations;
