import styled from '@emotion/styled';
import { t } from '@lingui/macro';
import { useProject } from 'app/api/useProject';
import { useProjectActions } from 'app/api/useProjectActions';
import {
  useGetFileDirectDownloadQuery,
  useGetFileReadByUuidQuery,
} from 'app/apiGenerated/generatedApi';
import { useAppDispatch } from 'app/hooks';
import { NavbarContext, uiFlagsActions } from 'app/slices/uiFlagsSlice';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Breadcrumb from 'ui/common/Breadcrumb/Breadcrumb';
import Button from 'ui/common/Button/Button';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import { Download, Notebook, Project } from 'ui/common/Icons/Standard';
import { Spinner } from 'ui/common/Spinner';
import { AppContentWithFooterWrapper } from 'ui/common/layout/appLayout';
import { NotebookViewerFrame } from './NotebookViewerFrame';

const Loader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: absolute;
  background: ${({ theme }) => theme.colors.grey[10]};
  top: 0;
  left: 0;
`;

const PageContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.grey[5]};
`;

const ButtonWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: ${({ theme }) => theme.spacing.normal};
  padding-bottom: 0;
  pointer-events: all;
`;

const ContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: ${({ theme }) => theme.spacing.normal};
  align-items: center;
`;

const NotebookWrapper = styled.div`
  width: 900px;
  height: 100%;
`;

// NOTE: Keep this in sync with the backend (JupyterNotebookFileProperties)
// If the type changes, then existing notebooks would need re-processing.
interface JupyterNotebookFileProperties {
  html_file_name: string;
  notebook_name: string;
  s3_key: string;
}

export const NotebookViewerPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { downloadFile } = useProjectActions();

  React.useEffect(() => {
    dispatch(uiFlagsActions.setNavbarContext(NavbarContext.NotebookViewer));
    return () => {
      dispatch(uiFlagsActions.setNavbarContext(NavbarContext.None));
    };
  }, [dispatch]);

  const { projectId: projectUuid, fileUuid } = useParams();
  const { project } = useProject({ projectId: projectUuid });

  const { data: fileData } = useGetFileReadByUuidQuery(
    {
      projectUuid: projectUuid || '',
      fileUuid: fileUuid || '',
    },
    {
      skip: !(!!projectUuid && !!fileUuid),
    },
  );

  const [fileName, s3key] = React.useMemo(() => {
    const props = fileData?.properties as JupyterNotebookFileProperties;
    return [props?.notebook_name, props?.s3_key];
  }, [fileData]);

  const { data: downloadData } = useGetFileDirectDownloadQuery(
    {
      projectUuid: projectUuid || '',
      fileName: s3key || '',
      redirect: false,
    },
    { skip: !fileName },
  );

  return (
    <AppContentWithFooterWrapper>
      {!downloadData?.download_link ? (
        <Loader>
          <Spinner width="36" height="36" />
        </Loader>
      ) : (
        <PageContentWrap>
          <Breadcrumb stretch>
            <Button
              variant={ButtonVariants.SmallTertiary}
              onClick={() => navigate(`/projects/${projectUuid}`)}>
              <Project />
              {project?.title}
            </Button>
            <Button variant={ButtonVariants.SmallTertiary}>
              <Notebook />
              {fileName ?? 'Notebook'}
            </Button>
          </Breadcrumb>
          <ButtonWrap>
            <Button
              Icon={Download}
              onClick={() => {
                if (projectUuid && fileUuid) {
                  downloadFile(fileName, { projectUuid, fileUuid });
                }
              }}>
              {t({
                id: 'project.readonlyNotebook.download',
                message: 'Download Notebook',
              })}
            </Button>
          </ButtonWrap>
          <ContentWrap>
            <NotebookWrapper>
              <NotebookViewerFrame notebookUrl={downloadData.download_link} />
            </NotebookWrapper>
          </ContentWrap>
        </PageContentWrap>
      )}
    </AppContentWithFooterWrapper>
  );
};
