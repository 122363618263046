/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const SimulationsRunning: React.FC<Props> = (props) => (
  <svg viewBox="0 0 24 24" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M12.8331 3.54094C10.8761 3.34819 8.91276 3.83998 7.27765 4.93252C5.64255 6.02506 4.43686 7.65075 3.86601 9.53259C3.29516 11.4144 3.39447 13.436 4.14703 15.2528C4.89958 17.0696 6.25881 18.5693 7.99313 19.4963C9.72744 20.4233 11.7295 20.7203 13.6583 20.3367C15.587 19.953 17.323 18.9125 18.5706 17.3923C19.659 16.0661 20.3168 14.4465 20.4669 12.7491C20.5034 12.3364 20.8358 12 21.25 12C21.6642 12 22.0029 12.3363 21.9719 12.7494C21.8183 14.7943 21.0387 16.7494 19.7301 18.3439C18.2624 20.1323 16.22 21.3565 13.9509 21.8078C11.6818 22.2592 9.32641 21.9098 7.28604 20.8192C5.24566 19.7286 3.64657 17.9643 2.76121 15.8268C1.87585 13.6894 1.75901 11.3111 2.4306 9.09716C3.10219 6.88323 4.52065 4.97066 6.4443 3.68532C8.36795 2.39998 10.6778 1.8214 12.9802 2.04817C15.033 2.25035 16.9667 3.0816 18.5213 4.41894C18.8353 4.68907 18.8336 5.16638 18.5407 5.45927C18.2478 5.75217 17.7749 5.74927 17.4574 5.48332C16.1509 4.3892 14.5405 3.7091 12.8331 3.54094Z"
      fill={props.fill}
    />
  </svg>
);

SimulationsRunning.defaultProps = {
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default SimulationsRunning;
