import styled from '@emotion/styled';
import React from 'react';

const ScrollableContainer = styled.object`
  width: 100%;
  height: 100%;
  overflow: auto;
  border: 1px solid ${({ theme }) => theme.colors.grey[10]};
  pointer-events: all;
`;

export interface Props {
  notebookUrl: string;
}

export const NotebookViewerFrame: React.FC<Props> = ({
  notebookUrl,
}): React.ReactElement | null => (
  <ScrollableContainer
    data={notebookUrl}
    type="text/html"
    width="100%"
    height="100%"
  />
);
