import { t } from '@lingui/macro';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { modelActions } from 'app/slices/modelSlice';
import React from 'react';
import { shallowEqual } from 'react-redux';
import { TextInputAlign } from 'ui/common/Input/inputTypes';
import {
  ValidationRule,
  validIdentifierRules,
} from 'ui/common/Input/inputValidation';
import {
  DetailsInput,
  DetailsLabel,
  DetailsSection,
} from 'ui/modelEditor/DetailsComponents';
import { useVisualizerPrefs } from './useVisualizerPrefs';

type Props = {
  parentPath: string[];
  blockUuid: string;
  title?: string;
  canEdit: boolean;
};

function nameValidationRules(otherNames: string[]): ValidationRule[] {
  const isUniqueBlockNameRule = {
    predicate: (value: string) => !otherNames || !otherNames.includes(value),
    message: t({
      id: 'input.validationMessage.isUniqueBlockName',
      message: `Block name must be unique`,
    }),
  };

  return [...validIdentifierRules, isUniqueBlockNameRule];
}

export const BlockNameField: React.FC<Props> = ({
  parentPath,
  blockUuid,
  title,
  canEdit,
}: Props) => {
  const dispatch = useAppDispatch();

  const otherNames = useAppSelector(
    (state) =>
      (state.modelMetadata.currentDiagram?.nodes || [])
        .filter((node) => node.uuid !== blockUuid)
        .map((node) => node.name),
    shallowEqual,
  );

  const visualizerPrefs = useVisualizerPrefs();

  const onChangeBlockName = (newName: string) => {
    if (!canEdit) return;

    visualizerPrefs.removeBlockPortsFromChart(
      [{ nodeId: blockUuid, parentPath }],
      true,
    );

    // TODO update block name in table

    dispatch(
      modelActions.changeBlockName({
        parentPath,
        blockUuid,
        newName: newName.trim(),
      }),
    );
  };

  return (
    <DetailsSection>
      <DetailsLabel shrink data-test-id="block-properties-block-name-label">
        Name
      </DetailsLabel>
      <DetailsInput
        testId="block-properties-block-name-textbox"
        grow
        value={title}
        onSubmitValue={onChangeBlockName}
        align={TextInputAlign.Right}
        validationRules={nameValidationRules(otherNames)}
        disabled={!canEdit}
      />
    </DetailsSection>
  );
};
