import styled from '@emotion/styled/macro';
import { ParameterSweep } from 'app/apiGenerated/generatedApiTypes';
import {
  ParamOptions,
  ParamTypes,
  overridenParamsMap,
} from 'app/slices/ensembleSimsSlice';
import { ModelParameters } from 'app/utils/modelDataUtils';
import Input from 'ui/common/Input/Input';
import { ValidationRule } from 'ui/common/Input/inputValidation';

export const InfoLabel = styled.label`
  font-size: ${({ theme }) => theme.typography.font.large.size};
  line-height: ${({ theme }) => theme.typography.font.large.lineHeight};
  color: ${({ theme }) => theme.colors.text.primary};
`;

export const ActionButtonContainer = styled.div`
  margin-top: ${({ theme }) => theme.spacing.normal};
  margin-left: 2px;
`;

export const BottomBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-top: ${({ theme }) => theme.spacing.xlarge};
`;

export const TotalSimsInput = styled(Input)`
  max-width: 120px;
  margin-left: ${({ theme }) => theme.spacing.normal};
`;

export const Spacer = styled.div`
  flex: 1;
`;

export const getValidationRules = (
  hasTooManySimulations: boolean,
  hasTooFewSimulations: boolean,
) => {
  const validationsRules: ValidationRule[] = [
    {
      predicate: () => !hasTooManySimulations,
      message: 'Number of simulations exceeds queue limit',
    },
    {
      predicate: () => !hasTooFewSimulations,
      message: 'Must have at least 1 simulation',
    },
  ];
  return validationsRules;
};

export const getSweepValues = (type: ParamTypes, params: string[]) =>
  ParamOptions[type].toSweepValues?.apply(null, params);

export const getParamSweeps = (
  parameters: ModelParameters,
  overrides: overridenParamsMap,
) =>
  parameters
    .map((param, index) => {
      const override = overrides?.[param.name];
      if (!override) return;

      const sweepValues = getSweepValues(
        override.type as ParamTypes,
        override.params,
      );

      if (!sweepValues) return;

      return {
        parameter_name: param.name,
        sweep_values: sweepValues,
        default_value: parameters[index].value,
      };
    })
    .filter((item) => item !== undefined) as ParameterSweep[];
