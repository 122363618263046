import { useAppDispatch } from 'app/hooks';
import { dataExplorerActions } from 'app/slices/dataExplorerSlice';
import { useDrop } from 'react-dnd';
import {
  DataExplorerDragType,
  SignalDragItem,
  SignalDropArea,
} from 'ui/dataExplorer/dataExplorerTypes';
import {
  HideableRowDropZoneGrid,
  SignalDropZoneBase,
} from 'ui/dataExplorer/drag/DropZoneCommon';

type Props = {
  targetRowId: string;
};

const SignalDragDropZoneTop = ({ targetRowId }: Props) => {
  const dispatch = useAppDispatch();
  const [{ isOver }, drop] = useDrop(() => ({
    accept: DataExplorerDragType.SignalTree,
    drop: (item: SignalDragItem) => {
      dispatch(
        dataExplorerActions.addTracesToNewRowAtOffset({
          targetRowId,
          offset: 0,
          traceSpecs: item.traceSpecs,
        }),
      );
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  }));

  return (
    <SignalDropZoneBase ref={drop} area={SignalDropArea.Top} isOver={isOver} />
  );
};

const SignalDragDropZoneBottom = ({ targetRowId }: Props) => {
  const dispatch = useAppDispatch();
  const [{ isOver }, drop] = useDrop(() => ({
    accept: DataExplorerDragType.SignalTree,
    drop: (item: SignalDragItem) => {
      dispatch(
        dataExplorerActions.addTracesToNewRowAtOffset({
          targetRowId,
          offset: 1,
          traceSpecs: item.traceSpecs,
        }),
      );
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  }));

  return (
    <SignalDropZoneBase
      ref={drop}
      area={SignalDropArea.Bottom}
      isOver={isOver}
    />
  );
};

/**
 * Signal drop zones that represent new rows.
 */
const HideableSignalDragRowDropZones = ({ targetRowId }: Props) => {
  const [{ isDraggingSignal }] = useDrop(() => ({
    accept: DataExplorerDragType.SignalTree,
    collect: (monitor) => ({
      isDraggingSignal: !!monitor.canDrop(),
    }),
  }));
  return (
    <HideableRowDropZoneGrid show={isDraggingSignal}>
      <SignalDragDropZoneTop targetRowId={targetRowId} />
      <SignalDragDropZoneBottom targetRowId={targetRowId} />
    </HideableRowDropZoneGrid>
  );
};

export default HideableSignalDragRowDropZones;
