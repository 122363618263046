/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  cx?: any;
  cy?: any;
  r?: any;
  stroke?: any;
  className?: string;
}

const AcausalThermalInsulator: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M11.9042 18.8686C11.5336 18.3404 11.2625 17.7372 11.1183 17.0863L10.75 17.823L8.14443 12.6118C7.7759 11.8748 6.7241 11.8748 6.35557 12.6118L5.03647 15.25H3C2.58579 15.25 2.25 15.5858 2.25 16C2.25 16.4142 2.58579 16.75 3 16.75H5.34549C5.72426 16.75 6.07053 16.536 6.23992 16.1972L7.25 14.1771L9.85557 19.3882C10.2241 20.1252 11.2759 20.1253 11.6444 19.3882L11.9042 18.8686ZM20.8817 14.9137L21.25 14.1771L23.8556 19.3882C24.2241 20.1252 25.2759 20.1253 25.6444 19.3882L26.9635 16.75H29C29.4142 16.75 29.75 16.4142 29.75 16C29.75 15.5858 29.4142 15.25 29 15.25H26.6545C26.2757 15.25 25.9295 15.464 25.7601 15.8028L24.75 17.823L22.1444 12.6118C21.7759 11.8748 20.7241 11.8748 20.3556 12.6118L20.0958 13.1314C20.4664 13.6596 20.7375 14.2628 20.8817 14.9137Z"
      fill={props.fill}
    />
    <circle cx={props.cx} cy={props.cy} r={props.r} stroke={props.stroke} />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M14.3232 14.7836C14.0628 14.6142 13.989 14.2657 14.1585 14.0053C14.3279 13.7449 14.6763 13.6712 14.9367 13.8406C15.1669 13.9904 15.3469 14.1771 15.4657 14.4032C15.584 14.6284 15.6248 14.8606 15.6248 15.0769C15.6248 15.4623 15.49 15.8561 15.3912 16.1446L15.3785 16.1818C15.2618 16.5234 15.1925 16.7451 15.1925 16.9231C15.1925 16.9968 15.2057 17.0416 15.2225 17.0735C15.2388 17.1046 15.275 17.1552 15.3691 17.2164C15.6295 17.3858 15.7033 17.7343 15.5338 17.9947C15.3644 18.2551 15.016 18.3288 14.7556 18.1594C14.5254 18.0096 14.3454 17.8229 14.2266 17.5968C14.1083 17.3716 14.0675 17.1394 14.0675 16.9231C14.0675 16.5377 14.2023 16.144 14.3011 15.8554L14.3139 15.8182C14.4305 15.4766 14.4998 15.2549 14.4998 15.0769C14.4998 15.0032 14.4866 14.9584 14.4698 14.9265C14.4535 14.8954 14.4173 14.8448 14.3232 14.7836ZM16.6306 14.7836C16.3702 14.6142 16.2964 14.2657 16.4659 14.0053C16.6353 13.7449 16.9837 13.6712 17.2441 13.8406C17.4743 13.9904 17.6543 14.1771 17.7731 14.4032C17.8914 14.6284 17.9322 14.8606 17.9322 15.0769C17.9322 15.4623 17.7974 15.8561 17.6986 16.1446L17.6859 16.1818C17.5692 16.5234 17.4999 16.7451 17.4999 16.9231C17.4999 16.9968 17.5131 17.0416 17.5299 17.0735C17.5462 17.1046 17.5824 17.1552 17.6765 17.2164C17.9369 17.3858 18.0107 17.7343 17.8413 17.9947C17.6718 18.2551 17.3234 18.3288 17.063 18.1594C16.8328 18.0096 16.6528 17.8229 16.534 17.5968C16.4157 17.3716 16.3749 17.1394 16.3749 16.9231C16.3749 16.5377 16.5097 16.144 16.6085 15.8554L16.6213 15.8182C16.7379 15.4766 16.8072 15.2549 16.8072 15.0769C16.8072 15.0032 16.794 14.9584 16.7772 14.9265C16.7609 14.8954 16.7247 14.8448 16.6306 14.7836Z"
      fill={props.fill}
    />
  </svg>
);

AcausalThermalInsulator.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  cx: '16',
  cy: '16',
  r: '4.5',
  stroke: '#1D3131',
  className: '',
};

export default AcausalThermalInsulator;
