import { LinkVertex } from 'app/utils/linkToRenderData';

export type BoundingBox = {
  x1: number;
  y1: number;
  x2: number;
  y2: number;
};

export const getVertexHitboxForIndex = (
  vertexData: LinkVertex[],
  index: number,
): BoundingBox => {
  if (index === vertexData.length - 1) {
    return {
      x1: 0,
      y1: 0,
      x2: 0,
      y2: 0,
    };
  }

  const [v1x, v1y] = vertexData[index].coordinate;
  const [v2x, v2y] = vertexData[index + 1].coordinate;

  const box = {
    x1: v1x,
    y1: v1y,
    x2: v2x,
    y2: v2y,
  };

  if (v1x < v2x) {
    box.x1 = v1x - 4;
    box.x2 = v2x - 4;
  } else if (v1x > v2x) {
    box.x1 = v2x + 4;
    box.x2 = v1x + 4;
  }

  if (v1y < v2y) {
    box.y1 = v1y - 4;
    box.y2 = v2y - 4;
  } else if (v1y > v2y) {
    box.y1 = v2y + 4;
    box.y2 = v1y + 4;
  }

  if (v1x === v2x) {
    box.x1 = v1x - 4;
    box.x2 = v2x + 4;
  }

  if (v1y === v2y) {
    box.y1 = v1y - 4;
    box.y2 = v2y + 4;
  }

  return box;
};

export function boundingBoxIntersects(
  one: BoundingBox,
  two: BoundingBox,
): boolean {
  return !(
    one.x1 >= two.x2 ||
    one.x2 <= two.x1 ||
    one.y1 >= two.y2 ||
    one.y2 <= two.y1
  );
}
