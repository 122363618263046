import styled from '@emotion/styled/macro';
import { useOutsideClick } from 'app/hooks';
import React from 'react';
import ReactDOM from 'react-dom';

const MENU_OVERLAY_OFFSET_IN_PX = 4;

// TODO be more sophisticated and measure the content instead of using a fixed
// dropup threshold.
const MINIMUM_HEIGHT_FOR_DROPDOWN = 140;
const MINIMUM_WIDTH_FOR_ALIGN_LEFT = 256;

const PortalMenuWrapper = styled.div<{
  top: number;
  left?: number;
  right?: number;
  bottomMargin?: string;
}>`
  z-index: 3;
  position: absolute;
  top: ${({ top }) => top}px;
  left: ${({ left }) =>
    left !== undefined ? `${left - MENU_OVERLAY_OFFSET_IN_PX}px` : '0'};
  right: ${({ right }) =>
    right !== undefined ? `${right - MENU_OVERLAY_OFFSET_IN_PX}px` : '0'};
  bottom: ${({ bottomMargin }) => bottomMargin || '0'};
  overflow: hidden;
  pointer-events: none;
  display: flex;
  flex-direction: row;
`;

const PortalMenuColumn = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex-shrink: 0;
`;

const PortalMenuSpacer = styled.div`
  flex-shrink: 1;
  height: 100%;
  width: 100%;
`;

export const PortalMenuBackground = styled.div`
  pointer-events: auto;
  background-color: ${(props) => props.theme.colors.grey[2]};
  overflow: auto;
  flex-shrink: 0;
  max-height: 100%;
  max-width: 100%;
`;

interface Props {
  children: React.ReactElement;
  triggerEl: HTMLElement;
  triggerClose: () => void;
  leftMargin?: number;
  bottomMargin?: string;
}

const PortalMenu: React.FC<Props> = ({
  children,
  triggerEl,
  triggerClose,
  leftMargin,
  bottomMargin,
}) => {
  const triggerRect = triggerEl.getBoundingClientRect();
  const boundingRect = document.body.getBoundingClientRect();
  const contentElRef = React.useRef<HTMLDivElement>(null);

  let isDropUp = false;
  let isAlignRight = false;
  let top: number = triggerRect.bottom;
  let left: number | undefined = triggerRect.left + (leftMargin ?? 0);
  let right: number | undefined;
  let actualBottom: string | undefined = bottomMargin;

  // Check if there is enough space for a dropdown, otherwise use a dropup menu.
  const spaceBelow = boundingRect.bottom - top;
  const spaceAbove = triggerRect.top;
  if (spaceBelow < MINIMUM_HEIGHT_FOR_DROPDOWN && spaceBelow < spaceAbove) {
    isDropUp = true;
    top = 0;
    actualBottom = `${boundingRect.bottom - triggerRect.top}px`;
  }

  // Check if there is enough space for the menu to go to the right, and if not,
  // position it to the left.
  const spaceToRight = boundingRect.right - left;
  const spaceToLeft = triggerRect.left;
  if (
    spaceToRight < MINIMUM_WIDTH_FOR_ALIGN_LEFT &&
    spaceToRight < spaceToLeft
  ) {
    isAlignRight = true;
    left = undefined;
    right = boundingRect.right - triggerRect.right;
  }

  // If the user clicks outside the menu, close the menu.
  useOutsideClick(
    contentElRef,
    () => {
      triggerClose();
    },
    true,
    triggerEl,
  );

  // If the trigger element moves for any reason (browser resize, scroll event, etc.)
  // make sure to close the menu because it will no longer be visually attached to the trigger element.
  React.useEffect(() => {
    const handleIntersect = (entries: IntersectionObserverEntry[]) => {
      if (!entries[0]?.isIntersecting) {
        triggerClose();
      }
    };

    // Set negative margins from the root element right up to the original rect of the trigger
    // element so we can detect when the trigger element moves outside of its original position.
    const options = {
      rootMargin: `-${triggerRect.top}px -${
        boundingRect.right - triggerRect.right
      }px -${boundingRect.bottom - triggerRect.bottom}px -${
        triggerRect.left
      }px`,
      threshold: 0,
    };

    const observer = new IntersectionObserver(handleIntersect, options);
    observer.observe(triggerEl);

    return () => {
      if (observer) observer.disconnect();
    };
  }, [triggerEl, triggerClose, triggerRect, boundingRect]);

  const menuContainer: HTMLElement | null = document.getElementById('menus');

  React.useEffect(() => {
    if (!menuContainer) {
      triggerClose();
    }
  }, [menuContainer, triggerClose]);

  if (!menuContainer) {
    return null;
  }

  return ReactDOM.createPortal(
    <PortalMenuWrapper
      top={top}
      left={left}
      right={right}
      bottomMargin={actualBottom}>
      {isAlignRight && <PortalMenuSpacer />}
      <PortalMenuColumn ref={contentElRef}>
        {isDropUp && <PortalMenuSpacer />}
        {children}
        {!isDropUp && <PortalMenuSpacer />}
      </PortalMenuColumn>
    </PortalMenuWrapper>,
    menuContainer,
  );
};

export default PortalMenu;
