/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Datasource: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M6.25 7.75V24.25H18.75V21H20.25V24.5C20.25 25.1904 19.6904 25.75 19 25.75H6C5.30964 25.75 4.75 25.1904 4.75 24.5V7.5C4.75 6.80964 5.30964 6.25 6 6.25H14.5C14.6989 6.25 14.8897 6.32902 15.0303 6.46967L20.0303 11.4697C20.171 11.6103 20.25 11.8011 20.25 12V14H18.75V12.75H14.5C14.0858 12.75 13.75 12.4142 13.75 12V7.75H6.25ZM15.25 8.81066L17.6893 11.25H15.25V8.81066Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M16.5 15H8.5C7.94772 15 7.5 15.4477 7.5 16V22C7.5 22.5523 7.94772 23 8.5 23H16.5C17.0523 23 17.5 22.5523 17.5 22V16C17.5 15.4477 17.0523 15 16.5 15ZM14.5 17H16.5V18H14.5V17ZM14.5 19H16.5V20H14.5V19ZM14.5 21H16.5V22H14.5V21ZM13.5 21V22H11.5V21H13.5ZM10.5 21V22H8.5V21H10.5ZM10.5 20H8.5V19H10.5V20ZM10.5 18H8.5V17H10.5V18ZM11.5 17V18H13.5V17H11.5ZM13.5 19H11.5V20H13.5V19Z"
      fill={props.fill}
    />
    <path
      d="M22.5303 12.4697C22.3158 12.2552 21.9932 12.191 21.713 12.3071C21.4327 12.4232 21.25 12.6967 21.25 13V15.25H18.5V16.75H22C22.4142 16.75 22.75 16.4142 22.75 16V14.8107L25.4393 17.5L22.75 20.1893V19C22.75 18.5858 22.4142 18.25 22 18.25H18.5V19.75H21.25V22C21.25 22.3033 21.4327 22.5768 21.713 22.6929C21.9932 22.809 22.3158 22.7448 22.5303 22.5303L27.0303 18.0303C27.3232 17.7374 27.3232 17.2626 27.0303 16.9697L22.5303 12.4697Z"
      fill={props.fill}
    />
  </svg>
);

Datasource.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Datasource;
