import { t } from '@lingui/macro';
import TooltipButton from 'ui/common/Button/TooltipButton';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import * as Icons from 'ui/common/Icons';
import { useModal } from 'ui/common/Modal/useModal';
import { useProjects } from 'app/api/useProjects';
import { useProjectActions } from 'app/api/useProjectActions';
import { ProjectFilter } from 'util/projectFilterUtils';
import React from 'react';
import { Project } from 'app/apiTransformers/convertAPIProjectToProject';
import RenameModal from '../../projectDetail/RenameModal';

type Props = {
  projectFilter: ProjectFilter;
  selectionState: Record<string, boolean>;
  numSelected: number;
};

export const RenameProjectButton: React.FC<Props> = ({
  projectFilter,
  selectionState,
  numSelected,
}: Props) => {
  const { projects } = useProjects(projectFilter);
  const { updateProject } = useProjectActions();
  const { triggerModal, closeModal } = useModal();

  const selectedProject = React.useMemo(() => {
    if (!projects) return;
    return projects.find((project: Project) => selectionState[project.uuid]);
  }, [projects, selectionState]);

  const onRename = (newTitle: string) => {
    const project = selectedProject;
    if (project) {
      updateProject({
        projectUuid: project.uuid,
        projectUpdateRequest: {
          title: newTitle,
          description: project.description,
        },
      });
      closeModal();
    }
  };

  const renameProjectModal = () =>
    triggerModal(
      <RenameModal
        onRename={onRename}
        initialValue={selectedProject?.title ?? ''}
      />,
      t({
        id: 'dashboard.projects.renameModal.title',
        message: 'Rename project',
      }),
    );

  const tooltip = t({
    id: 'dashboard.projects.renameProjectButton.Label',
    message: 'Rename Project',
  });

  return (
    <TooltipButton
      tooltip={tooltip}
      Icon={Icons.Standard.Rename}
      largeIcon
      variant={ButtonVariants.LargePrimary}
      disabled={numSelected > 1}
      onClick={renameProjectModal}
      testId="rename-project-button"
    />
  );
};
