import { WebSocketMessage } from 'app/third_party_types/websocket/websocket-message';
import { WebSocketMessageType } from 'app/third_party_types/websocket/websocket-message-type';
import React from 'react';
import { useAuth } from 'ui/auth/useAuthWrapper';
import PartySocket from 'partysocket';

type Subscriber = (message: WebSocketMessage) => void;

export interface PartySocketContext {
  isOpen: boolean;
  publish: (message: WebSocketMessage) => void;
  subscribe: (
    type: WebSocketMessageType,
    id: string,
    callback: Subscriber,
  ) => void;
  unsubscribe: (type: WebSocketMessageType, id: string) => void;
  connect: (roomId: string) => void;
  disconnect: () => void;
}

const partySocketContext = React.createContext<PartySocketContext>({
  isOpen: false,
  publish: () => {},
  subscribe: () => {},
  unsubscribe: () => {},
  connect: (_) => {},
  disconnect: () => {},
});

interface WebSocketProviderProps {
  children: React.ReactNode;
  mockAuth?: boolean;
}

export const PartySocketProvider: React.FC<WebSocketProviderProps> = ({
  children,
  mockAuth,
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const subscribers = React.useRef<
    Map<WebSocketMessageType, Map<string, Subscriber>>
  >(new Map());
  const partySocket = React.useRef<PartySocket | null>(null);

  const publish = React.useCallback(
    async (message: WebSocketMessage) => {
      if (!partySocket.current || !isOpen) {
        console.warn(
          `PartySocket is not connected ${JSON.stringify(message)} ${isOpen}`,
        );

        return;
      }

      partySocket.current.send(
        JSON.stringify({
          ...message,
          payload: message.payload,
        }),
      );
    },
    [isOpen],
  );

  const subscribe = React.useCallback(
    (
      type: WebSocketMessageType,
      subscriberId: string,
      callback: Subscriber,
      force?: boolean,
    ) => {
      const idToSubscriber =
        subscribers.current.get(type) || new Map<string, Subscriber>();
      if (!force && idToSubscriber.has(subscriberId)) {
        console.error(
          `${subscriberId} is already subscribed to ${type}, you must unsubscribe first.`,
        );
      } else {
        idToSubscriber.set(subscriberId, callback);
        subscribers.current.set(type, idToSubscriber);
      }
    },
    [],
  );

  const unsubscribe = React.useCallback(
    (type: WebSocketMessageType, subscriberId: string) => {
      const idToSubscriber = subscribers.current.get(type);
      if (!idToSubscriber) return;
      idToSubscriber.delete(subscriberId);
    },
    [],
  );

  const disconnect = React.useCallback(() => {
    if (
      partySocket.current &&
      partySocket.current.readyState === WebSocket.OPEN
    ) {
      partySocket.current.close();
    }
  }, []);

  const connect = React.useCallback((roomId: string) => {
    const partykitURL = process.env.REACT_APP_PARTYKIT_URL;

    if (!partykitURL) return;

    if (partySocket.current) {
      partySocket.current.close();
    }

    const socket = new PartySocket({
      host: partykitURL,
      room: roomId,
    });

    socket.onopen = async () => {
      setIsOpen(true);
    };

    socket.onmessage = (event) => {
      const data: WebSocketMessage = JSON.parse(event.data);
      const callbacksIt = subscribers.current.get(data.type)?.values();
      if (!callbacksIt) return;
      const callbacks = [...callbacksIt];
      for (let callback of callbacks) {
        callback(data);
      }
    };

    socket.onerror = (error) => {
      console.error('PartySocket Error: ', error);
    };

    socket.onclose = () => {
      console.warn('PartySocket websocket connection was closed.');
      setIsOpen(false);
    };

    partySocket.current = socket;
  }, []);

  React.useEffect(() => {
    if (!isOpen || !partySocket.current) return;
    const helloMessage: WebSocketMessage = {
      id: '1',
      type: WebSocketMessageType.HELLO,
      payload: 'Client connected',
    };
    publish(helloMessage);
  }, [isOpen, publish]);

  const initLock = React.useRef<boolean>(false);

  React.useEffect(() => {
    if (!initLock.current) {
      initLock.current = true;
      return;
    }

    return () => {
      if (partySocket.current?.readyState === WebSocket.OPEN) {
        partySocket.current.close();
        partySocket.current = null;
      }
      unsubscribe(WebSocketMessageType.INTERNAL_ERROR, 'internal_error');
      unsubscribe(WebSocketMessageType.HELLO, 'hello');
    };
  }, [unsubscribe]);

  const contextValue = React.useMemo(
    () => ({ isOpen, publish, subscribe, unsubscribe, connect, disconnect }),
    [isOpen, publish, subscribe, unsubscribe, connect, disconnect],
  );

  return (
    <partySocketContext.Provider value={contextValue}>
      {children}
    </partySocketContext.Provider>
  );
};

export const useCustomPartySocket = () => {
  const context = React.useContext(partySocketContext);
  if (!context) {
    throw new Error('useWebSocket must be used within a WebSocketProvider');
  }
  return context;
};
