import * as NVG from 'nanovg-js';
import { nvgColorToCSS } from 'util/nvgColorHexConvert';
import { RendererState } from './modelRenderer';
import { NAME_FONTSIZE } from './textRenderUtils';

export interface DrawContext {
  beginPath: () => void;
  closePath: () => void;
  save: () => void;
  restore: () => void;
  globalAlpha: (alpha: number) => void;

  scissor: (x: number, y: number, w: number, h: number) => void;

  fill: () => void;
  fillColor: (color: NVG.Color) => void;
  stroke: () => void;
  strokeColor: (color: NVG.Color) => void;
  strokeWidth: (width: number) => void;

  circle: (cx: number, cy: number, r: number) => void;
  rect: (x: number, y: number, w: number, h: number) => void;
  roundedRect: (x: number, y: number, w: number, h: number, r: number) => void;

  moveTo: (x: number, y: number) => void;
  lineTo: (x: number, y: number) => void;
  arc: (
    cx: number,
    cy: number,
    radius: number,
    startAngle: number,
    endAngle: number,
    direction: NVG.NVGwinding,
  ) => void;
  arcTo: (
    x1: number,
    y1: number,
    x2: number,
    y2: number,
    radius: number,
  ) => void;
  bezierTo: (
    c1x: number,
    c1y: number,
    c2x: number,
    c2y: number,
    x: number,
    y: number,
  ) => void;

  fontFace: (font: string) => void;
  fontSize: (size: number) => void;
  textAlign: (align: NVG.NVGalign) => void;
  text: (x: number, y: number, text: string, maxWidth?: number | null) => void;

  RGB: (r: number, g: number, b: number) => NVG.Color;
  RGBA: (r: number, g: number, b: number, a: number) => NVG.Color;
}

const _cachedDrawContext: {
  ctx?: DrawContext;
  rawCtx?: CanvasRenderingContext2D;
  pixelRatio?: number;
  hasRoundRectFn?: boolean;

  // stateful data
  fontFace: string;
  fontSize: number;
} = {
  fontFace: 'Archivo',
  fontSize: NAME_FONTSIZE,
};

function getFontFace(font: string, size: number) {
  // HACK: NanoVG and Canvas2D render at different sizes for some reason
  // (Note that canvas2d kerning is much better)
  const factor = 0.93;
  return `${size * factor}px ${font}`;
}

export function getDrawContextForCanvas2dCtx(
  ctx: CanvasRenderingContext2D,
  devicePixelRatio: number,
): DrawContext {
  const pr = devicePixelRatio;

  const drawContext: DrawContext = {
    beginPath: () => ctx.beginPath(),
    closePath: () => ctx.closePath(),
    save: () => ctx.save(),
    restore: () => ctx.restore(),
    globalAlpha: (alpha: number) => (ctx.globalAlpha = alpha),

    scissor: (x: number, y: number, w: number, h: number) => {
      ctx.beginPath();
      ctx.rect(x * pr, y * pr, w * pr, h * pr);
      ctx.clip();
    },

    fill: () => ctx.fill(),
    fillColor: (color: NVG.Color) => (ctx.fillStyle = nvgColorToCSS(color)),
    stroke: () => ctx.stroke(),
    strokeColor: (color: NVG.Color) => (ctx.strokeStyle = nvgColorToCSS(color)),
    strokeWidth: (width: number) => (ctx.lineWidth = width * pr),

    circle: (cx: number, cy: number, r: number) =>
      ctx.arc(cx * pr, cy * pr, r * pr, 0, Math.PI * 2),
    rect: (x: number, y: number, w: number, h: number) =>
      ctx.rect(x * pr, y * pr, w * pr, h * pr),

    // This is a new API, but we could implement it with multiRadiusRect,
    // if we cared about older browsers.
    roundedRect: (x: number, y: number, w: number, h: number, r: number) =>
      _cachedDrawContext.hasRoundRectFn
        ? (ctx as any).roundRect(x * pr, y * pr, w * pr, h * pr, r * pr)
        : ctx.rect(x * pr, y * pr, w * pr, h * pr),

    moveTo: (x: number, y: number) => ctx.moveTo(x * pr, y * pr),
    lineTo: (x: number, y: number) => ctx.lineTo(x * pr, y * pr),
    arc: (
      cx: number,
      cy: number,
      radius: number,
      startAngle: number,
      endAngle: number,
      direction: NVG.NVGwinding,
    ) => {
      // FIXME CW is HOLE and CCW is SOLID so it's not just about angle
      if (direction === NVG.NVGwinding.CCW) {
        ctx.arc(cx * pr, cy * pr, radius * pr, endAngle, startAngle);
      } else {
        ctx.arc(cx * pr, cy * pr, radius * pr, startAngle, endAngle);
      }
    },
    arcTo: (x1: number, y1: number, x2: number, y2: number, radius: number) =>
      ctx.arcTo(x1 * pr, y1 * pr, x2 * pr, y2 * pr, radius * pr),
    bezierTo: (
      c1x: number,
      c1y: number,
      c2x: number,
      c2y: number,
      x: number,
      y: number,
    ) =>
      ctx.bezierCurveTo(c1x * pr, c1y * pr, c2x * pr, c2y * pr, x * pr, y * pr),

    fontFace: (font: string) => {
      _cachedDrawContext.fontFace = font;
      ctx.font = getFontFace(font, _cachedDrawContext.fontSize * pr);
    },
    fontSize: (size: number) => {
      _cachedDrawContext.fontSize = size;
      ctx.font = getFontFace(_cachedDrawContext.fontFace, size * pr);
    },
    textAlign: (align: NVG.NVGalign) => {
      if (align & NVG.NVGalign.CENTER) {
        ctx.textAlign = 'center';
      }
      if (align & NVG.NVGalign.LEFT) {
        ctx.textAlign = 'left';
      }
      if (align & NVG.NVGalign.RIGHT) {
        ctx.textAlign = 'right';
      }
      if (align & NVG.NVGalign.TOP) {
        ctx.textBaseline = 'top';
      }
      if (align & NVG.NVGalign.MIDDLE) {
        ctx.textBaseline = 'middle';
      }
      if (align & NVG.NVGalign.BOTTOM) {
        ctx.textBaseline = 'bottom';
      }
      if (align & NVG.NVGalign.BASELINE) {
        ctx.textBaseline = 'alphabetic';
      }
    },
    text: (
      x: number,
      y: number,
      text: string,
      maxWidth: number | null = null,
    ) => {
      ctx.font = getFontFace(
        _cachedDrawContext.fontFace,
        _cachedDrawContext.fontSize * pr,
      );
      ctx.fillText(text, x * pr, y * pr, maxWidth ? maxWidth * pr : undefined);
    },

    RGB: (r: number, g: number, b: number) => NVG.RGB(r, g, b),
    RGBA: (r: number, g: number, b: number, a: number) => NVG.RGBA(r, g, b, a),
  };

  return drawContext;
}

export function getDrawContext(
  rs: RendererState,
  nvg: NVG.Context,
): DrawContext {
  if (!rs.refs.current.uiFlags.renderCanvas2d) return nvg;

  const ctx = rs.refs.current.overlayCanvas2dCtx;
  if (!ctx) return nvg; // oops!

  if (
    _cachedDrawContext.ctx &&
    _cachedDrawContext.rawCtx === ctx &&
    _cachedDrawContext.pixelRatio === window.devicePixelRatio
  ) {
    return _cachedDrawContext.ctx;
  }

  const drawContext = getDrawContextForCanvas2dCtx(
    ctx,
    window.devicePixelRatio,
  );

  _cachedDrawContext.ctx = drawContext;
  _cachedDrawContext.rawCtx = ctx;
  _cachedDrawContext.pixelRatio = window.devicePixelRatio;
  _cachedDrawContext.hasRoundRectFn = (ctx as any).roundRect !== undefined;

  return drawContext;
}

export function fillCircle(
  rs: RendererState,
  nvg: NVG.Context,
  cx: number,
  cy: number,
  radius: number,
  color: NVG.Color,
) {
  const ctx = getDrawContext(rs, nvg);

  ctx.beginPath();
  ctx.circle(cx * rs.zoom, cy * rs.zoom, radius * rs.zoom);
  ctx.fillColor(color);
  ctx.fill();
}

export function fillHalfCircle(
  rs: RendererState,
  nvg: NVG.Context,
  cx: number,
  cy: number,
  radius: number,
  startAngle: number,
  endAngle: number,
  color: NVG.Color,
) {
  const ctx = getDrawContext(rs, nvg);

  ctx.beginPath();
  ctx.moveTo(cx * rs.zoom, cy * rs.zoom);
  ctx.arc(
    cx * rs.zoom,
    cy * rs.zoom,
    radius * rs.zoom,
    startAngle,
    endAngle,
    NVG.NVGwinding.CCW,
  );
  ctx.fillColor(color);
  ctx.fill();
}
