import React from 'react';
import styled from '@emotion/styled/macro';
import { useTheme } from '@emotion/react';
import { t } from '@lingui/macro';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { PseudoOnboardingTooltip } from 'tours/onboarding/OnboardingTooltip';
import {
  entityPreferencesActions,
  selectEntityPrefs,
} from 'app/slices/entityPreferencesSlice';
import {
  TutorialPrefsV1,
  TUTORIAL_PREFS_V1_KEY,
} from 'ui/userPreferences/tutorialPrefs';
import { useAppParams } from 'util/useAppParams';
import useEntityPreferences from 'ui/userPreferences/useEntityPreferences';
import { GLOBAL_ENTITY } from 'ui/userPreferences/globalEntity';

const SimTutorialAnchor = styled.div`
  display: flex;
  position: absolute;
  justify-content: center;

  > * {
    position: absolute;
  }
`;

const SimTutorialTooltipArrow = styled.div`
  position: absolute;
  top: -7px;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;

  border-bottom: 8px solid white;
  z-index: 2;
`;

const SimTutorialAnim = styled.img`
  width: 100%;
  margin-bottom: ${({ theme }) => theme.spacing.normal};
`;

export const SimTutorialPopup = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const { links } = useAppSelector((state) => state.model.present.rootModel);

  // NOTE: only calculated once when the model document loads, very much on purpose.
  // this determines whether the model has at least somewhat meaningful data in it or not.
  // if the model essentially has nothing, we'll display the more complex ("initial") tutorial
  // otherwise we'll just show the "basic" tutorial.
  const shouldShowBasic = React.useMemo(
    () => links.filter((link) => link.src && link.dst).length > 0,
    [], // eslint-disable-line
  );

  useEntityPreferences(TUTORIAL_PREFS_V1_KEY, GLOBAL_ENTITY);
  const tutorialEntityPrefs = useAppSelector((state) =>
    selectEntityPrefs(state, TUTORIAL_PREFS_V1_KEY, GLOBAL_ENTITY),
  ) as TutorialPrefsV1 | null;
  const seenBasicSimTutorial = Boolean(
    tutorialEntityPrefs?.seenBasicSimTutorial,
  );
  const seenInitialSimTutorial = Boolean(
    tutorialEntityPrefs?.seenInitialSimTutorial,
  );
  const seenCurrentSimTutorial = shouldShowBasic
    ? seenBasicSimTutorial
    : seenInitialSimTutorial;

  // refs because direct render state is never based on this, only used to break from hook logic
  // and also to break from setTimeout logic.
  const alreadyShownTutorialRef = React.useRef(false);
  const seenCurrentSimTutorialRef = React.useRef(false);
  seenCurrentSimTutorialRef.current = seenCurrentSimTutorial;

  const [showSimTutorial, setShowSimTutorial] = React.useState(false);

  const tutorialPosition = React.useMemo(() => {
    const runButton = document.querySelector(
      '#navbar-run-simulation-button',
    ) as HTMLElement;
    if (runButton === null) return;

    const left = runButton.offsetLeft + runButton.offsetWidth / 2;
    const top = parseInt(theme.sizes.navbarHeight) + 20;

    return { left, top };
  }, [theme.sizes.navbarHeight]);

  const basicSimTutorialPopupTimerRef = React.useRef<number>();
  React.useEffect(() => {
    clearTimeout(basicSimTutorialPopupTimerRef.current);

    if (alreadyShownTutorialRef.current || seenCurrentSimTutorialRef.current)
      return;

    if (shouldShowBasic) {
      basicSimTutorialPopupTimerRef.current = window.setTimeout(() => {
        if (
          alreadyShownTutorialRef.current ||
          seenCurrentSimTutorialRef.current === true
        ) {
          return;
        }

        dispatch(entityPreferencesActions.onUserSawBasicTutorial());
        setShowSimTutorial(true);
        alreadyShownTutorialRef.current = true;
      }, 20 * 1000);
    }

    return () => clearTimeout(basicSimTutorialPopupTimerRef.current);
  }, [dispatch, shouldShowBasic]);

  React.useEffect(() => {
    if (alreadyShownTutorialRef.current || seenCurrentSimTutorial === true) {
      return;
    }

    if (shouldShowBasic) return;

    const hasEnoughConnections =
      links.filter((link) => link.src && link.dst).length > 0;

    if (hasEnoughConnections) {
      dispatch(entityPreferencesActions.onUserSawInitialTutorial());
      alreadyShownTutorialRef.current = true;
      setShowSimTutorial(true);
    }
  }, [dispatch, shouldShowBasic, seenCurrentSimTutorial, links]);

  const initialTutorialBody = (
    <PseudoOnboardingTooltip
      title={t({
        id: 'simTutorialPopup.initial.title',
        message: 'Ready to visualize your signals?',
      })}
      onClose={() => {
        setShowSimTutorial(false);
      }}>
      <SimTutorialAnim src="/assets/simtutorial_anim.gif" />
      {t({
        id: 'simTutorialPopup.inital.body',
        message:
          'To plot signals in the visualizer, click on the visualizer toggle for blocks of interest, and then run a simulation. Your plots will pop up automatically.',
      })}
    </PseudoOnboardingTooltip>
  );

  const basicTutorialBody = (
    <PseudoOnboardingTooltip
      title={t({
        id: 'simTutorialPopup.basic.title',
        message: 'Click play to simulate',
      })}
      onClose={() => {
        setShowSimTutorial(false);
      }}>
      {t({
        id: 'simTutorialPopup.basic.body',
        message:
          'Click play to run a simulation and see plots of your signals.',
      })}
    </PseudoOnboardingTooltip>
  );

  return showSimTutorial ? (
    <SimTutorialAnchor style={{ ...tutorialPosition }}>
      <SimTutorialTooltipArrow />
      {shouldShowBasic ? basicTutorialBody : initialTutorialBody}
    </SimTutorialAnchor>
  ) : null;
};
