import React from 'react';
import {
  useGetSimulationCsvResultsInfoReadByUuidQuery,
  useGetSimulationReadByUuidQuery,
} from 'app/apiGenerated/generatedApi';
import { SimulationSignalInfo } from 'app/apiGenerated/generatedApiTypes';
import { useAppDispatch } from 'app/hooks';
import { simulationSignalsActions } from 'app/slices/simulationSignalsSlice';
import { extractErrorMessage } from 'ui/common/notifications/errorFormatUtils';
import { buildSignalsTree, SignalsList } from 'util/simulationSignalsTree';

export function useSimulationSignals(modelId: string, simulationId: string) {
  const dispatch = useAppDispatch();

  const { data, error } = useGetSimulationCsvResultsInfoReadByUuidQuery({
    modelUuid: modelId,
    simulationUuid: simulationId,
  });

  const signals: SimulationSignalInfo[] | undefined = data?.signals;

  const { data: simulationSummary, error: simulationSummaryError } =
    useGetSimulationReadByUuidQuery({
      modelUuid: modelId,
      simulationUuid: simulationId,
      'X-Correlation-ID': undefined,
    });

  const modelVersionId = simulationSummary?.snapshot_uuid;

  // When the data is loaded, transform the data from a flat list into a tree
  // and save it in the redux store.
  React.useEffect(() => {
    if (simulationId && signals && modelVersionId) {
      const rootSignalsList: SignalsList = buildSignalsTree(signals);
      dispatch(
        simulationSignalsActions.onSimulationSignalsLoaded({
          simulationId,
          modelVersionId,
          signalsTreeData: {
            rootSignalsList,
          },
        }),
      );
    }
  }, [dispatch, signals, simulationId, modelVersionId]);

  // If we fail to load the signal data, save the error message to the redux store
  // to prevent an endless load spinner.
  React.useEffect(() => {
    if (simulationId && (error || simulationSummaryError)) {
      dispatch(
        simulationSignalsActions.onSimulationSignalsLoaded({
          simulationId,
          modelVersionId: modelVersionId || '',
          signalsTreeData: {
            errorMessage: error
              ? extractErrorMessage(error)
              : extractErrorMessage(simulationSummaryError),
          },
        }),
      );
    }
  }, [dispatch, error, simulationId, modelVersionId, simulationSummaryError]);
}
