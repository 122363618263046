import { Model } from 'app/apiData';
import {
  BusType,
  FileSummary,
  GitRemote,
  ProjectPermissionRole,
  ProjectSummary,
} from 'app/apiGenerated/generatedApiTypes';

export interface Project extends Record<string, unknown> {
  createdAt: string;
  description?: string;
  files: FileSummary[];
  labels?: string[];
  models: Model[];
  role: ProjectPermissionRole;
  title: string;
  updatedAt: string;
  uuid: string;
  ownerUuid: string;
  isNew: boolean;
  isPublic: boolean;
  isFavorite?: boolean;
  isMember?: boolean;
  gitConnected?: boolean;
  gitRemote?: GitRemote;
  busTypes?: BusType[];
}

export function convertAPIProjectToProject(
  apiProject: ProjectSummary,
): Project {
  return {
    createdAt: apiProject.created_at || '',
    description: apiProject.description,
    files: apiProject.files || [],
    models:
      apiProject.models?.map((model) => ({
        kind: model.kind || 'Model',
        uuid: model.uuid,
        version: model.version,
        name: model.name,
        description: model.description || '',
        created_at: model.created_at,
        updated_at: model.updated_at,
      })) || [],
    role: apiProject.role || 'read_only',
    title: apiProject.title,
    updatedAt: apiProject.updated_at || '',
    uuid: apiProject.uuid,
    ownerUuid: apiProject.owner_uuid,
    isNew: apiProject.is_new,
    isPublic: apiProject.is_default || false,
    isFavorite: apiProject.is_favourite,
    isMember: apiProject.is_member,
    gitConnected: apiProject.git_connected,
    gitremote: apiProject.git_remote,
    busTypes: apiProject.bus_types,
  };
}
