/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const StateMachine: React.FC<Props> = (props) => (
  <svg viewBox="0 0 24 24" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M3.74998 6C3.74998 4.75736 4.75734 3.75 5.99998 3.75H10.5C11.7426 3.75 12.75 4.75736 12.75 6V7.125C13.8602 7.125 14.9237 7.20397 15.6973 7.71971C16.562 8.29621 16.875 9.26277 16.875 10.5H17.7363C18.0844 10.5 18.2446 10.9331 17.9803 11.1597L16.125 12.75L14.2697 11.1597C14.0053 10.9331 14.1656 10.5 14.5137 10.5H15.375C15.375 9.48723 15.1255 9.14129 14.8652 8.96779C14.5138 8.73353 13.8898 8.625 12.75 8.625V9C12.75 10.2426 11.7426 11.25 10.5 11.25H7.87498H5.99998C4.75734 11.25 3.74998 10.2426 3.74998 9V6ZM5.99998 5.25H10.5C10.9142 5.25 11.25 5.58579 11.25 6V9C11.25 9.41421 10.9142 9.75 10.5 9.75H5.99998C5.58577 9.75 5.24998 9.41421 5.24998 9V6C5.24998 5.58579 5.58577 5.25 5.99998 5.25Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M11.25 15.375C10.1102 15.375 9.48615 15.2665 9.13476 15.0322C8.87451 14.8587 8.62498 14.5128 8.62498 13.5H9.48626C9.83442 13.5 9.99465 13.0669 9.73031 12.8403L7.87498 11.25L6.01966 12.8403C5.75532 13.0669 5.91555 13.5 6.26371 13.5H7.12498C7.12498 14.7372 7.43796 15.7038 8.30271 16.2803C9.07632 16.796 10.1398 16.875 11.25 16.875V18C11.25 19.2426 12.2573 20.25 13.5 20.25H18C19.2426 20.25 20.25 19.2426 20.25 18V15C20.25 13.7574 19.2426 12.75 18 12.75H16.125H13.5C12.2573 12.75 11.25 13.7574 11.25 15V15.375ZM13.5 14.25H18C18.4142 14.25 18.75 14.5858 18.75 15V18C18.75 18.4142 18.4142 18.75 18 18.75H13.5C13.0858 18.75 12.75 18.4142 12.75 18V15C12.75 14.5858 13.0858 14.25 13.5 14.25Z"
      fill={props.fill}
    />
  </svg>
);

StateMachine.defaultProps = {
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default StateMachine;
