/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const LineLabels: React.FC<Props> = (props) => (
  <svg viewBox="0 0 24 24" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M4.5 7.75C4.36193 7.75 4.25 7.86193 4.25 8V14.5C4.25 14.6381 4.36193 14.75 4.5 14.75H9.03513C9.55097 14.75 10.0405 14.9776 10.373 15.372L12.0226 17.3284L13.621 15.3875C13.9535 14.9839 14.449 14.75 14.9719 14.75H19.5C19.6381 14.75 19.75 14.6381 19.75 14.5V8C19.75 7.86193 19.6381 7.75 19.5 7.75H4.5ZM2.75 8C2.75 7.0335 3.5335 6.25 4.5 6.25H19.5C20.4665 6.25 21.25 7.0335 21.25 8V14.5C21.25 15.4665 20.4665 16.25 19.5 16.25H14.9719C14.8972 16.25 14.8264 16.2834 14.7789 16.3411L12.0362 19.6716L9.22626 16.3389C9.17876 16.2825 9.10882 16.25 9.03513 16.25H4.5C3.5335 16.25 2.75 15.4665 2.75 14.5V8Z"
      fill={props.fill}
    />
  </svg>
);

LineLabels.defaultProps = {
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default LineLabels;
