import styled from '@emotion/styled';
import {
  BusTypeSignal,
  ProjectScopeId,
} from 'app/apiGenerated/generatedApiTypes';
import Breadcrumb from 'ui/common/Breadcrumb/Breadcrumb';
import Bus from 'ui/common/Icons/Standard/Bus';
import BusTypeSpreadsheet from './BusTypeSpreadsheet';

/**
 * The glide data grid overlays use a portal div
 */
export const GlideDataGridPortal = () => <div id="portal" />;

export const BusTypeEditorBackground = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.colors.grey[5]};
  overflow-y: auto;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

const BusTypeEditorContainer = styled.div`
  width: 900px;
  height: 100%;
  padding: 16px 12px;
  background-color: ${(props) => props.theme.colors.grey[2]};
  border: 1px solid ${(props) => props.theme.colors.grey[10]};
`;

type Props = {
  projectUuid: string;
  busTypeId: ProjectScopeId;
  name: string;
  signals: BusTypeSignal[];
};

/**
 * BusTypeEditor is a mix of the CodeEditor and RequirementsManager.
 * Same layout as CodeEditor since both are GUIS for a file in a project.
 * Same styling as the RequirementsManager spreadsheet.
 */
function BusTypeEditor({ projectUuid, busTypeId, name, signals }: Props) {
  // TODO gate editing behind permissions. It's already gated in backend.
  return (
    <BusTypeEditorBackground>
      <Breadcrumb>
        <>
          <Bus />
          {name}
        </>
      </Breadcrumb>
      <BusTypeEditorContainer>
        <BusTypeSpreadsheet
          projectUuid={projectUuid}
          busTypeId={busTypeId}
          signals={signals}
        />
      </BusTypeEditorContainer>
      <GlideDataGridPortal />
    </BusTypeEditorBackground>
  );
}

export default BusTypeEditor;
