import { BlockClassName, Parameters } from '@collimator/model-schemas-ts';
import { blockClassLookup } from 'app/generated_blocks';

export const flippableIconIDs = ['gain', 'logicaloperator', 'mux', 'demux'];

// Subset compatible with NodeInstance - do not export type
interface NodeInstanceLite {
  type: BlockClassName;
  parameters: Parameters;
}

const correctOp = (op?: string) => {
  switch (op) {
    case '==':
      return 'ee';
    case '>':
      return 'gt';
    case '<':
      return 'lt';
    case '>=':
      return 'gte';
    case '<=':
      return 'lte';
    case '!=':
      return 'ltgt'; // didn't expect this
    default:
      return 'ee';
  }
};

function getConcatenationIconSuffix(node?: NodeInstanceLite): string {
  return node?.parameters?.axis?.value === '0'
    ? 'concatenationvertical'
    : 'concatenationhorizontal';
}

function getMatrixOperatorIconSuffix(node?: NodeInstanceLite) {
  switch (node?.parameters?.function?.value) {
    case 'transpose':
      return 'transpose';
    case 'inverse':
      return 'inverse';
    case 'multiply':
      if (node?.parameters?.multiplication_type?.value === 'vector_vector') {
        return 'dotproduct';
      }
      return 'multiply';
    case 'concatenation':
      return getConcatenationIconSuffix(node);
    case 'extraction':
      return 'extraction';
    case 'fft':
      return 'multiply'; // TODO add fft icon
    default:
      return 'multiply';
  }
}

const correctors: {
  [k: string]: ((n?: NodeInstanceLite) => string) | undefined;
} = {
  modelicafmu: () => 'fmi',
  logicaloperator: (node?: NodeInstanceLite) =>
    `logic.${node?.parameters?.function?.value || 'and'}`,
  matrixoperator: (node?: NodeInstanceLite) =>
    `matrixoperator.${getMatrixOperatorIconSuffix(node)}`,
  matrixtransposition: (node?: NodeInstanceLite) => `matrixoperator.transpose`,
  matrixinversion: (node?: NodeInstanceLite) => `matrixoperator.inverse`,
  matrixmultiplication: (node?: NodeInstanceLite) => `matrixoperator.multiply`,
  // FIXME when we have a proper icon for Slice block
  slice: (node?: NodeInstanceLite) => `matrixoperator.extraction`,
  matrixconcatenation: (node?: NodeInstanceLite) =>
    `matrixoperator.${getConcatenationIconSuffix(node)}`,
  trigonometric: (node?: NodeInstanceLite) =>
    `trig.${node?.parameters?.function?.value || 'sin'}`,
  comparator: (node?: NodeInstanceLite) =>
    `comparator.${correctOp(node?.parameters?.operator?.value)}`,
  discreteinitializer: (node?: NodeInstanceLite) =>
    `discreteinitializer${
      node?.parameters?.initial_state?.value === 'true'
        ? 'initialstatetrue'
        : '.initialstatefalse'
    }`,
  derivativediscrete: () => 'discretederivative',
  integratordiscrete: () => 'discreteintegrator',
  cosinewave: () => 'cosine',
  referencesubmodel: () => 'submodel',
  pythonscript: () => 'pythonscript',
  pid: () => 'pid.pid',
  pid_discrete: () => 'discretepid.pid',
  randomnumber: () => 'randomnormal',
  edgedetection: (node?: NodeInstanceLite) =>
    `edgedetector.${node?.parameters?.edge_detection?.value || 'rising'}`,
  coordinaterotation: (node?: NodeInstanceLite) => {
    switch (node?.parameters?.rotation_type?.value) {
      case 'quaternion':
        return 'coordinaterotation.quaternion';
      case 'roll_pitch_yaw':
        return 'coordinaterotation.rotationangles';
      case 'DCM':
        return 'coordinaterotation.dcm';
      default:
        return 'coordinaterotation.quaternion';
    }
  },
  coordinaterotationconversion: (node?: NodeInstanceLite) => {
    switch (node?.parameters?.conversion_type?.value) {
      case 'quaternion_to_DCM':
        return 'coordinaterotationconversion.quaterniontodcm';
      case 'quaternion_to_RPY':
        return 'coordinaterotationconversion.quaterniontorotationangles';
      case 'DCM_to_quaternion':
        return 'coordinaterotationconversion.dcmtoquaternion';
      case 'DCM_to_RPY':
        return 'coordinaterotationconversion.dcmtorotationangles';
      case 'RPY_to_quaternion':
        return 'coordinaterotationconversion.rotationanglestoquaternion';
      case 'RPY_to_DCM':
        return 'coordinaterotationconversion.rotationanglestodcm';
      default:
        return 'coordinaterotationconversion.quaterniontodcm';
    }
  },
  logicalreduce: (node?: NodeInstanceLite) =>
    `logicalreduce.${node?.parameters?.function?.value || 'any'}`,
  pytwin: () => 'pyansys',
  'acausal.rotational.SpeedSource': () => 'acausal.translational.speedsource',
  'acausal.rotational.TorqueSource': () => 'acausal.rotational.speedsource',
  'acausal.rotational.TorqueSensor': () => 'torquesensor',
};

export const getBlockIconID = (
  noNamespaceClassName: string,
  node: NodeInstanceLite,
): string => {
  const blockClass = blockClassLookup(node.type);
  if (blockClass.base.namespace !== 'core') {
    const corrector = correctors[node.type];
    if (corrector) {
      return corrector(node);
    }
  }

  const lowerCasedClass = noNamespaceClassName.toLowerCase();
  const corrector = correctors[lowerCasedClass];
  if (corrector) {
    return corrector(node);
  }

  // Get the icon for the corresponding block without the _dev suffix
  // (if one was appended)
  const lowerBaseClassNoDev = lowerCasedClass.replace(/_dev$/, '');
  if (lowerCasedClass !== lowerBaseClassNoDev) {
    const corrector_dev = correctors[lowerBaseClassNoDev];
    if (corrector_dev) {
      return corrector_dev(node);
    }
  }

  return lowerBaseClassNoDev;
};
