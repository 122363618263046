/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Loopstate: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.25 6.5C9.25 5.80964 9.80964 5.25 10.5 5.25H12.5V6.75H10.75V8.83333H9.25V6.5Z"
      fill={props.fill}
    />
    <path
      d="M13.5 5.25H15.5C16.1904 5.25 16.75 5.80964 16.75 6.5V8.375H15.25V6.75H13.5V5.25Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M16.75 9.68934L17.4697 8.96967C17.7626 8.67678 18.2374 8.67678 18.5303 8.96967C18.8232 9.26256 18.8232 9.73744 18.5303 10.0303L16.5263 12.0343C18.4867 12.292 20 13.9693 20 16C20 17.9528 18.6006 19.5787 16.75 19.9298V21.625H15.25V19.9298C13.3994 19.5787 12 17.9528 12 16C12 13.9693 13.5133 12.292 15.4737 12.0343L13.4697 10.0303C13.1768 9.73744 13.1768 9.26256 13.4697 8.96967C13.7626 8.67678 14.2374 8.67678 14.5303 8.96967L15.25 9.68934V9.125H16.75V9.68934ZM16 18.5C17.3807 18.5 18.5 17.3807 18.5 16C18.5 14.6193 17.3807 13.5 16 13.5C14.6193 13.5 13.5 14.6193 13.5 16C13.5 17.3807 14.6193 18.5 16 18.5Z"
      fill={props.fill}
    />
    <path d="M10.75 10.5V15.1667H9.25V10.5H10.75Z" fill={props.fill} />
    <path d="M10.75 16.8333V21.5H9.25V16.8333H10.75Z" fill={props.fill} />
    <path
      d="M10.75 23.1667V25.25H12.5V26.75H10.5C9.80964 26.75 9.25 26.1904 9.25 25.5V23.1667H10.75Z"
      fill={props.fill}
    />
    <path
      d="M16.75 23.2083V25.5C16.75 26.1904 16.1904 26.75 15.5 26.75H13.5V25.25H15.25V23.2083H16.75Z"
      fill={props.fill}
    />
  </svg>
);

Loopstate.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Loopstate;
