/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const EditedExternally: React.FC<Props> = (props) => (
  <svg viewBox="0 0 24 24" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M9.01007 15H15C16.1046 15 17 14.1046 17 13C17 12.2233 16.5573 11.55 15.9104 11.2188L15.9132 11.2194C15.9699 10.989 16 10.748 16 10.5C16 8.84315 14.6569 7.5 13 7.5C11.6912 7.5 10.5781 8.33814 10.1683 9.50698C10.1128 9.50236 10.0567 9.5 10 9.5C8.97687 9.5 8.13317 10.2683 8.01433 11.2594C7.40863 11.6031 7 12.2538 7 13C7 14.1046 7.89543 15 9 15L9.01007 15ZM14.4718 10.7924C14.4902 10.6988 14.5 10.6012 14.5 10.5C14.5 9.67157 13.8284 9 13 9C12.3674 9 11.8263 9.39158 11.6058 9.94557C11.4192 10.5616 10.847 11.01 10.17 11.01C10.1111 11.01 10.0531 11.0066 9.99594 11C9.73959 11.0021 9.52919 11.197 9.50279 11.4468L9.49848 11.447C9.43921 11.9237 9.15625 12.331 8.75794 12.5606L8.7586 12.5617C8.60112 12.6492 8.5 12.8138 8.5 13C8.5 13.2762 8.72386 13.5 9 13.5H15C15.2762 13.5 15.5 13.2761 15.5 13C15.5 12.8168 15.4014 12.6565 15.2544 12.5695C15.2361 12.5606 15.2172 12.5508 15.1993 12.5412C14.7293 12.2879 14.41 11.7912 14.41 11.2199C14.41 11.0714 14.4316 10.9279 14.4718 10.7924Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12ZM19 12C19 15.866 15.866 19 12 19C8.13401 19 5 15.866 5 12C5 8.13401 8.13401 5 12 5C15.866 5 19 8.13401 19 12Z"
      fill={props.fill}
    />
  </svg>
);

EditedExternally.defaultProps = {
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default EditedExternally;
