import styled from '@emotion/styled/macro';

const TosPageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  background: ${(props) => props.theme.colors.grey[10]};
`;

const TosContent = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  width: 800px;
  height: 100%;
  padding: ${(props) => props.theme.spacing.large};
`;

const TosCopy = styled.div`
  flex: 1;
  margin-bottom: ${(props) => props.theme.spacing.large};
  overflow-y: scroll;

  > p {
    margin-bottom: ${(props) => props.theme.spacing.large};
  }

  > h4 {
    margin-bottom: ${(props) => props.theme.spacing.normal};
  }
`;

const TosCopyTitle = styled.div`
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: ${(props) => props.theme.spacing.xlarge};
`;
const Bolder = styled.span`
  font-weight: bold;
`;

export const TosPage = () => (
  <TosPageWrapper>
    <TosContent>
      <TosCopy>
        <TosCopyTitle data-test-id="terms-title">BETA USER TERMS</TosCopyTitle>

        <p>
          These Beta User Terms (these “Beta Terms”) is entered into between
          Collimator, Inc., a Delaware corporation (“Collimator”), and you
          (“You”). This Beta Tester Agreement governs your access to and use of
          certain software and related services (the “Beta Services”) offered by
          Collimator solely on a preliminary or “beta” basis, and/or for testing
          and evaluation purposes. By accessing or using the Beta Services, you
          agree to be bound by these Beta Terms and accept all of its terms. If
          you do not accept all the terms of these Beta Terms, then you may not
          use the Beta Services.
        </p>

        <h4>1. Acknowledgment of Beta Services Limitations.</h4>
        <p>
          You acknowledge that: (a) the Beta Services have not been made
          commercially available by Collimator; (b) the Beta Services may not
          operate properly, be in final form or fully functional; (c) the Beta
          Services may contain errors, design flaws or other problems; (d) it
          may not be possible to make the Beta Services fully functional; (e)
          use of the Beta Services may result in unexpected results, corruption
          or loss of data, or other unpredictable damage or loss; and (f)
          Collimator has no obligation to release a commercial version of the
          Beta Services or otherwise introduce the Beta Services. You assume all
          risk arising from use of the Beta Services as further stated in these
          Beta Terms.
        </p>

        <h4>2. License.</h4>
        <p>
          2.1. License Grant. Subject to your compliance with the terms and
          conditions of these Beta Terms, Collimator hereby grants to you a
          limited, nonexclusive, nontransferable, revocable right, during the
          term for which the Beta Services are made available to you, to access
          and use the Beta Services solely for internal purposes and/or for
          testing and evaluation.
        </p>

        <h4>2.2. Restrictions.</h4>
        <p>
          You acknowledge that the Beta Services may contain trade secrets or
          other proprietary rights of Collimator and its licensors, and, in
          order to protect such trade secrets and other rights or interests that
          Collimator and its licensors may have in the Beta Services, you agree
          not to: (i) reverse engineer, decompile or disassemble the Beta
          Services or authorize a third party to do any of the foregoing, (ii)
          modify the Beta Services, (iii) distribute sell, sublicense or
          otherwise transfer the Beta Services, or (iv) copy the Beta Services,
          except as strictly necessary to use the Beta Services in accordance
          with the terms of Section 2.1.
        </p>

        <h4>3. Feedback.</h4>
        <p>
          You agree to (a) cooperate with Collimator in evaluating the Beta
          Services, and (b) provide Collimator with feedback as reasonably
          requested from time to time. All feedback, comments, and suggestions
          for improvements that you provide to Collimator hereunder are referred
          to collectively as “Feedback”. You hereby grant Collimator a
          non-exclusive, worldwide, perpetual, irrevocable, fully-paid,
          royalty-free, sublicensable and transferable license under any and all
          intellectual property rights that you own or control to use, copy,
          modify, create derivative works based upon and otherwise exploit the
          Feedback for any purpose.
        </p>

        <h4>4. Ownership.</h4>
        <p>
          As between the parties, Collimator is the sole owner of all rights,
          title and interest in and to the Beta Services, including all
          intellectual property rights therein. You will not acquire any rights
          or licenses in the Beta Services or Collimator’s Confidential
          Information, except as expressly provided in Section 2.1 these Beta
          Terms.
        </p>

        <h4>5. User Content.</h4>
        <p>
          In connection with your use of the Beta Services, you may submit or
          input certain information, data or other content into the Beta
          Services (“User Content”). You are the sole owner of all rights, title
          and interest in and to the User Content, including all intellectual
          property rights therein. You hereby grant Collimator the right and
          license to use and access your User Content for the purposes of
          providing the Beta Services to you, including for troubleshooting.
        </p>

        <h4>6. Confidentiality.</h4>
        <p>
          <Bolder>6.1.</Bolder> For purposes of these Beta Terms, “Confidential
          Information” means the Beta Services and any information or materials
          disclosed by or on behalf of Collimator to you that (a) is marked
          “confidential” or “proprietary” or with a similar designation at the
          time of such disclosure, or (b) due to its nature or the circumstances
          of its disclosure, a person exercising reasonable business judgment
          would understand to be confidential or proprietary. You agree: (i) to
          maintain all Confidential Information in strict confidence; (ii) not
          to disclose Confidential Information to any third parties; and (iii)
          not to use any Confidential Information for any purpose except for the
          purpose of testing and evaluating the Beta Services in accordance with
          these Beta Terms.
        </p>

        <p>
          <Bolder>6.2.</Bolder> The obligations and restrictions in Section 6.1
          will not apply to any information or materials that: (a) were, at the
          date of disclosure, or have subsequently become, generally known or
          available to the public through no act or failure to act by you; (b)
          were rightfully known by you prior to the disclosure of such
          information or materials from Collimator; (c) are rightfully acquired
          by you from a third party who has the right to disclose such
          information or materials without breach of any obligation of
          confidentiality or restricted use to Collimator; or (d) are
          independently developed by you without access to any Confidential
          Information.
        </p>

        <p>
          <Bolder>6.3.</Bolder> Upon termination of these Beta Terms, or earlier
          upon Collimator’s request, you will promptly return to Collimator, or,
          at Collimator’s option, destroy, all tangible items and embodiments
          containing or consisting of Confidential Information and all copies
          thereof. All Confidential Information remains the sole and exclusive
          property of Collimator.
        </p>

        <p>
          <Bolder>6.4.</Bolder> You agree that any breach of these Beta Terms by
          you may result in irreparable harm to Collimator, for which damages
          would be an inadequate remedy and therefore, in addition to its rights
          and remedies otherwise available at law, Collimator shall be entitled
          to seek equitable relief, including injunction, in the event of such
          breach.
        </p>

        <h4>7. Termination.</h4>
        <p>
          Collimator reserves the right to modify or terminate the Beta
          Services, these Beta Terms, or your use of the Beta Services, or to
          limit or deny access to the Beta Services, at any time, in its sole
          discretion, for any reason, with or without notice and without
          liability to you. You may discontinue your use of the Beta Services at
          any time. Upon any termination, discontinuation or cancellation of the
          Beta Services, these Beta Terms or your access, the following sections
          of these Beta Terms will survive: 1, 2.2, 3, 6, 7, 8, 9 and 10.
        </p>

        <h4>8. Warranty Disclaimers.</h4>
        <p>
          YOU ACKNOWLEDGE THAT THE BETA SERVICES ARE BEING PROVIDED “AS IS.”
          COLLIMATOR DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING THE
          IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
          PURPOSE, AND NON-INFRINGEMENT, AND ANY WARRANTIES ARISING OUT OF
          COURSE OF DEALING OR USAGE OF TRADE. COLLIMATOR MAKES NO WARRANTY THAT
          ANY OF THE BETA SERVICES WILL MEET YOUR REQUIREMENTS AND/OR THAT THE
          BETA SERVICES WILL BE UNINTERRUPTED, TIMELY OR ERROR-FREE, NOR DOES
          COLLIMATOR MAKE ANY WARRANTY AS TO THE RESULTS THAT MAY BE OBTAINED
          FROM THE USE OF THE BETA SERVICES OR THE ACCURACY OF ANY OTHER
          INFORMATION OBTAINED THROUGH THE BETA SERVICES. YOU UNDERSTAND AND
          AGREE THAT ANY MATERIAL AND/OR DATA DOWNLOADED OR OTHERWISE OBTAINED
          THROUGH THE USE OF ANY OF THE BETA SERVICES IS DONE AT YOUR SOLE RISK
          AND THAT YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR
          COMPUTER SYSTEM OR LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD OF SUCH
          MATERIAL OR DATA.
        </p>

        <h4>9. Limitation on Liability.</h4>
        <p>
          IN NO EVENT WILL COLLIMATOR BE LIABLE TO YOU OR TO ANY THIRD PARTY FOR
          DAMAGES OF ANY KIND, INCLUDING, WITHOUT LIMITATION, DIRECT, SPECIAL,
          INCIDENTAL, PUNITIVE OR CONSEQUENTIAL DAMAGES (INCLUDING LOSS OF USE,
          DATA, BUSINESS OR PROFITS) ARISING OUT OF OR IN CONNECTION WITH THESE
          BETA TERMS OR THE ACCESS OR USE OF OR INABILITY TO USE THE BETA
          SERVICES OR FOR ANY ERROR OR DEFECT IN THE BETA SERVICES, WHETHER SUCH
          LIABILITY ARISES FROM ANY CLAIM BASED UPON CONTRACT, WARRANTY, TORT
          (INCLUDING NEGLIGENCE), STRICT LIABILITY OR OTHERWISE, AND WHETHER OR
          NOT COLLIMATOR HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH LOSS OR
          DAMAGE. THE PARTIES HAVE AGREED THAT THESE LIMITATIONS WILL SURVIVE
          AND APPLY EVEN IF ANY LIMITED REMEDY SPECIFIED IN THESE BETA TERMS IS
          FOUND TO HAVE FAILED OF ITS ESSENTIAL PURPOSE. TO THE EXTENT PERMITTED
          BY APPLICABLE LAW, COLLIMATOR’S LIABILITY HEREUNDER IS LIMITED TO
          $50.00.
        </p>

        <h4>10. Miscellaneous.</h4>
        <p>
          These Beta Terms will be governed and construed in accordance with the
          laws of the State of Delaware, without regard to its conflict of laws
          provisions. The exclusive jurisdiction and venue of any action related
          to these Beta Terms will be the state and federal courts located in
          the Denver, Colorado, and each of the parties hereto waives any
          objection to jurisdiction and venue in such courts. These Beta Terms
          constitute the entire and exclusive understanding and agreement
          between Collimator and you with respect to its subject matter, and
          supersedes and replaces any and all prior oral or written
          understandings or agreements between Collimator and you with respect
          to its subject matter. If for any reason a court of competent
          jurisdiction finds any provision of these Beta Terms invalid or
          unenforceable, that provision will be enforced to the maximum extent
          permissible and the other provisions of these Beta Terms will remain
          in full force and effect. You may not assign or transfer these Beta
          Terms, by operation of law or otherwise, without Collimator&apos;s
          prior written consent. Any attempt by you to assign or transfer these
          Beta Terms, without such consent, will be null and of no effect.
          Collimator may freely assign or transfer these Beta Terms without
          restriction. Subject to the foregoing, these Beta Terms will bind and
          inure to the benefit of the parties, their successors and permitted
          assigns.
        </p>

        <p>
          Collimator’s failure to enforce any right or provision of these Beta
          Terms will not be considered a waiver of those rights. The waiver of
          any such right or provision will be effective only if in writing and
          signed by a duly authorized representative of Collimator. Except as
          expressly set forth in these Beta Terms, the exercise by either party
          of any of its remedies under these Beta Terms will be without
          prejudice to its other remedies under these Beta Terms or otherwise.
        </p>
      </TosCopy>
    </TosContent>
  </TosPageWrapper>
);
