import styled from '@emotion/styled';
import { t } from '@lingui/macro';
import { Step } from 'react-joyride';
import { Standard } from 'ui/common/typography/Typography';

const commonStepConfig = {
  disableBeacon: true,
  spotlightPadding: 0,
};

const ProjectIntroBulletList = styled.ul`
  margin: ${({ theme }) => theme.spacing.normal} 0;
  padding-left: 22px;

  > li {
    line-height: 22px;
    position: relative;

    &:before {
      content: ' ';
      background-color: ${({ theme }) => theme.colors.text.secondary};
      border-radius: 10px;
      width: 4px;
      height: 4px;
      position: absolute;
      top: 50%;
      transform: translateY(-2px);
      left: -13px;
    }
  }
`;

export const stepMap: { top: Step[]; project: Step[]; model: Step[] } = {
  top: [
    {
      ...commonStepConfig,
      styles: {
        spotlight: {
          borderRadius: 0,
        },
      },
      content: (
        <Standard>
          {t({
            id: 'tour.onboarding.0.description.0',
            message:
              'In Collimator, everything starts with a project. In the sidebar you can find: ',
          })}
          <br />
          <ProjectIntroBulletList>
            {[
              t`Projects you’ve created`,
              t`Projects you’ve been invited to`,
              t`Free examples and tutorials`,
            ].map((item, i) => (
              <li key={i}>{item}</li>
            ))}
          </ProjectIntroBulletList>
          {t({
            id: 'tour.onboarding.0.description.2',
            message:
              'Any projects that you star will also appear in the sidebar for quick access.',
          })}
        </Standard>
      ),
      target: '.tour-onboarding-0',
      placement: 'right-start',
      offset: -10,
      title: t({
        id: 'tour.onboarding.0.title',
        message: 'Introducing projects',
      }),
    },
    {
      ...commonStepConfig,
      spotlightPadding: 5,
      content: (
        <Standard>
          {t({
            id: 'tour.onboarding.1.description.0',
            message:
              'To create a new project, click the “New Project” button on the My Projects screen.',
          })}
        </Standard>
      ),
      target: '.tour-onboarding-1',
      placement: 'right-start',
      title: t({
        id: 'tour.onboarding.1.title',
        message: 'Creating projects',
      }),
    },
  ],
  project: [
    {
      ...commonStepConfig,
      spotlightPadding: 5,
      content: (
        <Standard>
          {t({
            id: 'tour.onboarding.2.description.0',
            message:
              'To run a simulation, you need a model. Click the “Add...” button and select “New Model” to create one.',
          })}
        </Standard>
      ),
      target: '.tour-onboarding-2',
      placement: 'right-start',
      title: t({
        id: 'tour.onboarding.2.title',
        message: 'Create a model',
      }),
    },
  ],
  model: [
    {
      ...commonStepConfig,
      styles: {
        spotlight: {
          borderRadius: 0,
        },
      },
      content: (
        <Standard>
          {t({
            id: 'tour.onboarding.3.description.0',
            message:
              'Models in Collimator are made from diagrams of blocks. You can find all the available blocks in the Library, here to the left. To add a block to your model, drag it from the Library to your canvas.',
          })}
          <br />
          <b>{t`Quick tip`}</b>:{' '}
          {t({
            id: 'tour.onboarding.3.description.1',
            message:
              'You can also double click on the canvas to search for blocks to add.',
          })}
        </Standard>
      ),
      target: '.tour-onboarding-3',
      placement: 'right-start',
      offset: -10,
      title: t({
        id: 'tour.onboarding.3.title',
        message: 'The block library',
      }),
    },
    {
      ...commonStepConfig,
      styles: {
        spotlight: {
          borderRadius: 0,
        },
      },
      content: (
        <Standard>
          {t({
            id: 'tour.onboarding.4.description.0',
            message:
              'Model parameters let you define constant values that are available throughout your model. Parameters can be any type or dimension. You set them by adding them one by one here, or by running a Python script that sets a lot of values all at once.',
          })}
        </Standard>
      ),
      target: '.tour-onboarding-4',
      placement: 'left-start',
      offset: -10,
      title: t({
        id: 'tour.onboarding.4.title',
        message: 'Model parameters',
      }),
    },
    {
      ...commonStepConfig,
      styles: {
        spotlight: {
          borderRadius: 0,
        },
      },
      content: (
        <Standard>
          {t({
            id: 'tour.onboarding.5.description.0',
            message:
              'You can find simulation settings and output format options, as well as version history on the other tabs here.',
          })}
        </Standard>
      ),
      target: '.tour-onboarding-5',
      placement: 'left-start',
      offset: -10,
      title: t({
        id: 'tour.onboarding.5.title',
        message: 'More settings and history',
      }),
    },
  ],
};
