/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Neuralnet: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.5 8.5C12.5 9.32843 11.8284 10 11 10C10.1716 10 9.5 9.32843 9.5 8.5C9.5 7.67157 10.1716 7 11 7C11.8284 7 12.5 7.67157 12.5 8.5Z"
      fill={props.fill}
    />
    <path
      d="M18.5 7.5C18.5 8.32843 17.8284 9 17 9C16.1716 9 15.5 8.32843 15.5 7.5C15.5 6.67157 16.1716 6 17 6C17.8284 6 18.5 6.67157 18.5 7.5Z"
      fill={props.fill}
    />
    <path
      d="M23.5 8.5C23.5 9.32843 22.8284 10 22 10C21.1716 10 20.5 9.32843 20.5 8.5C20.5 7.67157 21.1716 7 22 7C22.8284 7 23.5 7.67157 23.5 8.5Z"
      fill={props.fill}
    />
    <path
      d="M27.5 12.5C27.5 13.3284 26.8284 14 26 14C25.1716 14 24.5 13.3284 24.5 12.5C24.5 11.6716 25.1716 11 26 11C26.8284 11 27.5 11.6716 27.5 12.5Z"
      fill={props.fill}
    />
    <path
      d="M27.5 18.5C27.5 19.3284 26.8284 20 26 20C25.1716 20 24.5 19.3284 24.5 18.5C24.5 17.6716 25.1716 17 26 17C26.8284 17 27.5 17.6716 27.5 18.5Z"
      fill={props.fill}
    />
    <path
      d="M24.5 14.5C24.5 15.3284 23.8284 16 23 16C22.1716 16 21.5 15.3284 21.5 14.5C21.5 13.6716 22.1716 13 23 13C23.8284 13 24.5 13.6716 24.5 14.5Z"
      fill={props.fill}
    />
    <path
      d="M19.5 17.5C19.5 18.3284 18.8284 19 18 19C17.1716 19 16.5 18.3284 16.5 17.5C16.5 16.6716 17.1716 16 18 16C18.8284 16 19.5 16.6716 19.5 17.5Z"
      fill={props.fill}
    />
    <path
      d="M19.5 12.5C19.5 13.3284 18.8284 14 18 14C17.1716 14 16.5 13.3284 16.5 12.5C16.5 11.6716 17.1716 11 18 11C18.8284 11 19.5 11.6716 19.5 12.5Z"
      fill={props.fill}
    />
    <path
      d="M13.5 14.5C13.5 15.3284 12.8284 16 12 16C11.1716 16 10.5 15.3284 10.5 14.5C10.5 13.6716 11.1716 13 12 13C12.8284 13 13.5 13.6716 13.5 14.5Z"
      fill={props.fill}
    />
    <path
      d="M11.5 19.5C11.5 20.3284 10.8284 21 10 21C9.17157 21 8.5 20.3284 8.5 19.5C8.5 18.6716 9.17157 18 10 18C10.8284 18 11.5 18.6716 11.5 19.5Z"
      fill={props.fill}
    />
    <path
      d="M10.5 24.5C10.5 25.3284 9.82843 26 9 26C8.17157 26 7.5 25.3284 7.5 24.5C7.5 23.6716 8.17157 23 9 23C9.82843 23 10.5 23.6716 10.5 24.5Z"
      fill={props.fill}
    />
    <path
      d="M15.5 19.5C15.5 20.3284 14.8284 21 14 21C13.1716 21 12.5 20.3284 12.5 19.5C12.5 18.6716 13.1716 18 14 18C14.8284 18 15.5 18.6716 15.5 19.5Z"
      fill={props.fill}
    />
    <path
      d="M8.5 12.5C8.5 13.3284 7.82843 14 7 14C6.17157 14 5.5 13.3284 5.5 12.5C5.5 11.6716 6.17157 11 7 11C7.82843 11 8.5 11.6716 8.5 12.5Z"
      fill={props.fill}
    />
    <path
      d="M7.5 17.5C7.5 18.3284 6.82843 19 6 19C5.17157 19 4.5 18.3284 4.5 17.5C4.5 16.6716 5.17157 16 6 16C6.82843 16 7.5 16.6716 7.5 17.5Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M16.8767 6.7602C16.9663 6.74526 17.058 6.74674 17.1471 6.76456L22.1471 7.76456C22.2923 7.7936 22.4256 7.86497 22.5303 7.96967L26.5303 11.9697C26.671 12.1103 26.75 12.3011 26.75 12.5V18.5C26.75 18.7151 26.6576 18.9199 26.4964 19.0623C26.3351 19.2046 26.1204 19.2709 25.907 19.2442L18.1323 18.2724L14.4443 20.1164L9.53033 25.0303C9.29663 25.264 8.9375 25.3172 8.64611 25.1613C8.35471 25.0053 8.19975 24.677 8.26456 24.3529L9.15222 19.9146L5.66459 18.1708C5.36208 18.0196 5.19824 17.6846 5.26456 17.3529L6.26456 12.3529C6.2936 12.2077 6.36497 12.0744 6.46967 11.9697L10.4697 7.96967C10.5802 7.85914 10.7225 7.7859 10.8767 7.7602L16.8767 6.7602ZM10.6149 20.25L10.2212 22.2181L12.1893 20.25H10.6149ZM13.8229 18.75L16.3229 17.5L12.3936 15.5353L11.1078 18.75H13.8229ZM9.60636 18.4647L10.571 16.053L7.67705 17.5L9.60636 18.4647ZM7.03618 16.1434L7.55861 13.5312L10.1708 14.5761L7.03618 16.1434ZM13.9549 14.6389L17.25 13.5406V16.2865L13.9549 14.6389ZM20.2489 17.0253L24.34 17.5367L22.8058 15.4911L20.2489 17.0253ZM24.0757 14.6843L25.25 16.25V13.9014L24.0757 14.6843ZM24.8228 12.3834L23.5491 13.2325L23.1245 10.6852L24.8228 12.3834ZM20.4787 8.9606L18.4606 10.9787L17.9561 8.45607L20.4787 8.9606ZM16.407 8.35918L16.9414 11.0313L13.2005 8.8936L16.407 8.35918ZM10.4998 10.0609L8.3346 12.2261L11.0411 13.3087L10.4998 10.0609ZM11.9995 9.93496L12.5955 13.5109L16.1715 12.3189L11.9995 9.93496ZM18.75 13.6078L21.3176 14.6348L18.75 16.1754V13.6078ZM22.0411 13.3087L21.4998 10.0609L19.3346 12.2261L22.0411 13.3087Z"
      fill={props.fill}
    />
  </svg>
);

Neuralnet.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Neuralnet;
