import styled from '@emotion/styled/macro';
import React from 'react';
import { Spinner } from 'ui/common/Spinner';
import PortalTextTooltip from 'ui/common/Tooltip/PortalTextTooltip';
import { TooltipPlacement } from 'ui/common/Tooltip/tooltipTypes';
import { usePortalTooltip } from 'ui/common/Tooltip/usePortalTooltip';

const SpinnerWrapper = styled.div`
  height: 20px;
  width: 20px;
`;

interface Props {
  tooltipMessage: string;
  tooltipPlacement: TooltipPlacement;
}

const SpinnerWithTooltip: React.FC<Props> = ({
  tooltipMessage,
  tooltipPlacement,
}: Props) => {
  const { triggerElRef, isTooltipOpen } = usePortalTooltip<HTMLDivElement>();

  return (
    <>
      <SpinnerWrapper ref={triggerElRef}>
        <Spinner />
      </SpinnerWrapper>
      {triggerElRef.current && isTooltipOpen && (
        <PortalTextTooltip
          triggerEl={triggerElRef.current}
          contentText={tooltipMessage}
          placement={tooltipPlacement}
        />
      )}
    </>
  );
};

export default SpinnerWithTooltip;
