/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Derivative: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.548 5.74401C17.548 5.70401 17.56 5.67201 17.584 5.64801C17.616 5.61601 17.652 5.60001 17.692 5.60001H18.796C18.836 5.60001 18.868 5.61601 18.892 5.64801C18.924 5.67201 18.94 5.70401 18.94 5.74401V13.856C18.94 13.896 18.924 13.932 18.892 13.964C18.868 13.988 18.836 14 18.796 14H17.692C17.652 14 17.616 13.988 17.584 13.964C17.56 13.932 17.548 13.896 17.548 13.856V13.496C17.548 13.472 17.54 13.456 17.524 13.448C17.508 13.44 17.492 13.448 17.476 13.472C17.124 13.888 16.664 14.096 16.096 14.096C15.528 14.096 15.048 13.948 14.656 13.652C14.264 13.356 13.98 12.944 13.804 12.416C13.66 11.968 13.588 11.468 13.588 10.916C13.588 10.348 13.656 9.86001 13.792 9.45201C13.968 8.92401 14.252 8.51201 14.644 8.21601C15.036 7.92001 15.528 7.77201 16.12 7.77201C16.672 7.77201 17.124 7.97201 17.476 8.37201C17.492 8.38801 17.508 8.39601 17.524 8.39601C17.54 8.38801 17.548 8.37201 17.548 8.34801V5.74401ZM17.26 12.32C17.444 11.992 17.536 11.528 17.536 10.928C17.536 10.304 17.436 9.82801 17.236 9.50001C17.02 9.14801 16.716 8.97201 16.324 8.97201C15.916 8.97201 15.596 9.14001 15.364 9.47601C15.124 9.82801 15.004 10.316 15.004 10.94C15.004 11.5 15.1 11.96 15.292 12.32C15.516 12.704 15.856 12.896 16.312 12.896C16.72 12.896 17.036 12.704 17.26 12.32Z"
      fill={props.fill}
    />
    <path
      d="M14.548 18.244C14.548 18.204 14.56 18.172 14.584 18.148C14.616 18.116 14.652 18.1 14.692 18.1H15.796C15.836 18.1 15.868 18.116 15.892 18.148C15.924 18.172 15.94 18.204 15.94 18.244V26.356C15.94 26.396 15.924 26.432 15.892 26.464C15.868 26.488 15.836 26.5 15.796 26.5H14.692C14.652 26.5 14.616 26.488 14.584 26.464C14.56 26.432 14.548 26.396 14.548 26.356V25.996C14.548 25.972 14.54 25.956 14.524 25.948C14.508 25.94 14.492 25.948 14.476 25.972C14.124 26.388 13.664 26.596 13.096 26.596C12.528 26.596 12.048 26.448 11.656 26.152C11.264 25.856 10.98 25.444 10.804 24.916C10.66 24.468 10.588 23.968 10.588 23.416C10.588 22.848 10.656 22.36 10.792 21.952C10.968 21.424 11.252 21.012 11.644 20.716C12.036 20.42 12.528 20.272 13.12 20.272C13.672 20.272 14.124 20.472 14.476 20.872C14.492 20.888 14.508 20.896 14.524 20.896C14.54 20.888 14.548 20.872 14.548 20.848V18.244ZM14.26 24.82C14.444 24.492 14.536 24.028 14.536 23.428C14.536 22.804 14.436 22.328 14.236 22C14.02 21.648 13.716 21.472 13.324 21.472C12.916 21.472 12.596 21.64 12.364 21.976C12.124 22.328 12.004 22.816 12.004 23.44C12.004 24 12.1 24.46 12.292 24.82C12.516 25.204 12.856 25.396 13.312 25.396C13.72 25.396 14.036 25.204 14.26 24.82Z"
      fill={props.fill}
    />
    <path
      d="M17.1231 26.5C17.0671 26.5 17.0271 26.484 17.0031 26.452C16.9871 26.412 16.9951 26.368 17.0271 26.32L18.9711 23.464C18.9871 23.44 18.9871 23.416 18.9711 23.392L17.0271 20.548C17.0111 20.516 17.0031 20.488 17.0031 20.464C17.0031 20.4 17.0431 20.368 17.1231 20.368H18.2991C18.3711 20.368 18.4271 20.4 18.4671 20.464L19.7151 22.312C19.7311 22.328 19.7471 22.336 19.7631 22.336C19.7791 22.336 19.7911 22.328 19.7991 22.312L21.0471 20.464C21.0871 20.4 21.1431 20.368 21.2151 20.368H22.4751C22.5311 20.368 22.5671 20.388 22.5831 20.428C22.6071 20.46 22.6031 20.5 22.5711 20.548L20.6271 23.392C20.6191 23.416 20.6191 23.44 20.6271 23.464L22.5711 26.32C22.5871 26.352 22.5951 26.38 22.5951 26.404C22.5951 26.468 22.5551 26.5 22.4751 26.5H21.2991C21.2271 26.5 21.1711 26.468 21.1311 26.404L19.8831 24.556C19.8751 24.54 19.8631 24.532 19.8471 24.532C19.8311 24.532 19.8151 24.54 19.7991 24.556L18.5511 26.404C18.5111 26.468 18.4551 26.5 18.3831 26.5H17.1231Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M7.25 16C7.25 15.5858 7.58579 15.25 8 15.25H24C24.4142 15.25 24.75 15.5858 24.75 16C24.75 16.4142 24.4142 16.75 24 16.75H8C7.58579 16.75 7.25 16.4142 7.25 16Z"
      fill={props.fill}
    />
  </svg>
);

Derivative.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Derivative;
