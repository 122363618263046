/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Bus: React.FC<Props> = (props) => (
  <svg viewBox="0 0 24 24" {...props} xmlns="http://www.w3.org/2000/svg">
    <path d="M11.0667 12.75H8.26667V11.25H11.0667V12.75Z" fill={props.fill} />
    <path d="M15.7333 12.75H12.9333V11.25H15.7333V12.75Z" fill={props.fill} />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M4.75 6C5.16421 6 5.5 6.33579 5.5 6.75V10H18.5V6.75C18.5 6.33579 18.8358 6 19.25 6C19.6642 6 20 6.33579 20 6.75V17.25C20 17.6642 19.6642 18 19.25 18C18.8358 18 18.5 17.6642 18.5 17.25V14H5.5V17.25C5.5 17.6642 5.16421 18 4.75 18C4.33579 18 4 17.6642 4 17.25V6.75C4 6.33579 4.33579 6 4.75 6ZM18.5 13V12.75H17.6V11.25H18.5V11H5.5V11.25H6.4V12.75H5.5V13H18.5Z"
      fill={props.fill}
    />
  </svg>
);

Bus.defaultProps = {
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Bus;
