const BlockDocMap: { [key: string]: unknown } = {
  'acausal.electrical.Battery': {
    $schema: '../../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/acausal/electrical/battery.json',
    base: {
      name: 'Battery',
      namespace: 'acausal.electrical',
      description: 'Equivalent circuit battery cell model.',
    },
    modes: {
      time: 'agnostic',
    },
    ports: {
      inputs: {
        static: [
          {
            name: 'p',
            variant: {
              variant_kind: 'acausal',
              acausal_domain: 'electrical',
            },
          },
        ],
      },
      outputs: {
        static: [
          {
            name: 'n',
            variant: {
              variant_kind: 'acausal',
              acausal_domain: 'electrical',
            },
          },
        ],
        conditional: [
          {
            name: 'soc',
            condition_bool_parameter: 'enable_soc_port',
            order: 1,
          },
          {
            name: 'Up',
            condition_bool_parameter: 'enable_Up_port',
            order: 2,
          },
          {
            name: 'ocv',
            condition_bool_parameter: 'enable_ocv_port',
            order: 3,
          },
        ],
      },
    },
    parameter_definitions: [
      {
        name: 'AH',
        data_type: 'float',
        default_value: '1.0',
        description: 'Capacity in Amp-Hours.',
      },
      {
        name: 'OCV_soc',
        data_type: 'any',
        default_value: '[0.0, 1.0]',
        description: 'SOC breakpoints for the SOC-OCV curve.',
      },
      {
        name: 'OCV_volts',
        data_type: 'any',
        default_value: '[10.0, 15.0]',
        description: 'Open Circuit Voltage values for SOC-OCV curve.',
      },
      {
        name: 'R',
        data_type: 'any',
        default_value: '0.01',
        description: 'Internal resistance in Ohms. Can be scalar or array.',
      },
      {
        name: 'Rp',
        data_type: 'any',
        default_value: '1e-6',
        description: 'Polarization resistance in Ohms. Can be scalar or array.',
      },
      {
        name: 'Cp',
        data_type: 'any',
        default_value: '1e-6',
        description:
          'Polarization capacitance in Farads. Can be scalar or array.',
      },
      {
        name: 'SOC_v',
        data_type: 'any',
        default_value: 'None',
        description:
          'SOC breakpoints for when R, Rp, Cp are lookup tables of SOC.',
      },
      {
        name: 'initial_soc',
        data_type: 'float',
        default_value: '0.5',
        description: 'Initial SOC.',
      },
      {
        name: 'initial_soc_fixed',
        data_type: 'bool',
        default_value: 'False',
        description: 'Whether initial SOC condition is fixed or not.',
      },
      {
        name: 'enable_soc_port',
        data_type: 'bool',
        default_value: 'false',
        description: 'User selection for enabling the SOC output',
      },
      {
        name: 'enable_Up_port',
        data_type: 'bool',
        default_value: 'false',
        description:
          'User selection for enabling the polarization voltage output',
      },
      {
        name: 'enable_ocv_port',
        data_type: 'bool',
        default_value: 'false',
        description: 'User selection for enabling the OCV output',
      },
    ],
  },
  'acausal.electrical.Capacitor': {
    $schema: '../../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/acausal/electrical/capacitor.json',
    base: {
      name: 'Capacitor',
      namespace: 'acausal.electrical',
      description: 'Ideal linear electrical capacitor.',
    },
    modes: {
      time: 'agnostic',
    },
    ports: {
      inputs: {
        static: [
          {
            name: 'p',
            variant: {
              variant_kind: 'acausal',
              acausal_domain: 'electrical',
            },
          },
        ],
      },
      outputs: {
        static: [
          {
            name: 'n',
            variant: {
              variant_kind: 'acausal',
              acausal_domain: 'electrical',
            },
          },
        ],
      },
    },
    parameter_definitions: [
      {
        name: 'C',
        data_type: 'float',
        default_value: '1.0',
        description: 'Capacitance in Farads.',
      },
      {
        name: 'initial_voltage',
        data_type: 'float',
        default_value: '0.0',
        description: 'Initial voltage.',
      },
      {
        name: 'initial_voltage_fixed',
        data_type: 'bool',
        default_value: 'False',
        description: 'Whether initial voltage condition is fixed or not.',
      },
    ],
  },
  'acausal.electrical.CurrentSensor': {
    $schema: '../../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/acausal/electrical/current_sensor.json',
    base: {
      name: 'CurrentSensor',
      namespace: 'acausal.electrical',
      description: 'Current sensor.',
    },
    modes: {
      time: 'agnostic',
    },
    ports: {
      inputs: {
        static: [
          {
            name: 'p',
            variant: {
              variant_kind: 'acausal',
              acausal_domain: 'electrical',
            },
          },
        ],
      },
      outputs: {
        static: [
          {
            name: 'n',
            variant: {
              variant_kind: 'acausal',
              acausal_domain: 'electrical',
            },
          },
          {
            name: 'i',
          },
        ],
      },
    },
  },
  'acausal.electrical.CurrentSource': {
    $schema: '../../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/acausal/electrical/current_source.json',
    base: {
      name: 'CurrentSource',
      namespace: 'acausal.electrical',
      description: 'Current source.',
      feature_level: 'dev',
    },
    modes: {
      time: 'agnostic',
    },
    ports: {
      inputs: {
        static: [
          {
            name: 'p',
            variant: {
              variant_kind: 'acausal',
              acausal_domain: 'electrical',
            },
          },
        ],
        conditional: [
          {
            name: 'i',
            condition_bool_parameter: 'enable_current_port',
            order: 1,
          },
        ],
      },
      outputs: {
        static: [
          {
            name: 'n',
            variant: {
              variant_kind: 'acausal',
              acausal_domain: 'electrical',
            },
          },
        ],
      },
    },
    parameter_definitions: [
      {
        name: 'i',
        data_type: 'float',
        default_value: '1.0',
        description: 'Constant current.',
      },
      {
        name: 'enable_current_port',
        data_type: 'bool',
        default_value: 'false',
        description: 'User selection for enabling inport for current value.',
      },
    ],
  },
  'acausal.electrical.Ground': {
    $schema: '../../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/acausal/electrical/ground.json',
    base: {
      name: 'Ground',
      namespace: 'acausal.electrical',
      description: 'Electrical reference.',
    },
    modes: {
      time: 'agnostic',
    },
    ports: {
      inputs: {
        static: [
          {
            name: 'p',
            variant: {
              variant_kind: 'acausal',
              acausal_domain: 'electrical',
            },
          },
        ],
      },
    },
  },
  'acausal.electrical.IdealDiode': {
    $schema: '../../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/acausal/electrical/ideal_diode.json',
    base: {
      name: 'IdealDiode',
      namespace: 'acausal.electrical',
      description: 'Ideal Diode in electrical domain.',
    },
    modes: {
      time: 'agnostic',
    },
    ports: {
      inputs: {
        static: [
          {
            name: 'p',
            variant: {
              variant_kind: 'acausal',
              acausal_domain: 'electrical',
            },
          },
        ],
      },
      outputs: {
        static: [
          {
            name: 'n',
            variant: {
              variant_kind: 'acausal',
              acausal_domain: 'electrical',
            },
          },
        ],
      },
    },
    parameter_definitions: [
      {
        name: 'Vknee',
        data_type: 'float',
        default_value: '0.7',
        description: 'Voltage drop when in forward bias.',
      },
      {
        name: 'Ron',
        data_type: 'float',
        default_value: '1e-6',
        description: 'Resistance in forward bias.',
      },
      {
        name: 'Roff',
        data_type: 'float',
        default_value: '1e9',
        description: 'Resistance in reverse bias.',
      },
    ],
  },
  'acausal.electrical.IdealMotor': {
    $schema: '../../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/acausal/electrical/ideal_motor.json',
    base: {
      name: 'IdealMotor',
      namespace: 'acausal.electrical',
      description: 'Electrical Motor with basic relationships.',
    },
    modes: {
      time: 'agnostic',
    },
    ports: {
      inputs: {
        static: [
          {
            name: 'pos',
            variant: {
              variant_kind: 'acausal',
              acausal_domain: 'electrical',
            },
          },
          {
            name: 'neg',
            variant: {
              variant_kind: 'acausal',
              acausal_domain: 'electrical',
            },
          },
        ],
      },
      outputs: {
        static: [
          {
            name: 'shaft',
            variant: {
              variant_kind: 'acausal',
              acausal_domain: 'rotational',
            },
          },
        ],
        conditional: [
          {
            name: 'heat',
            condition_bool_parameter: 'enable_heat_port',
            order: 1,
            variant: {
              variant_kind: 'acausal',
              acausal_domain: 'thermal',
            },
          },
        ],
      },
    },
    parameter_definitions: [
      {
        name: 'R',
        data_type: 'float',
        default_value: '1.0',
        description: 'Series resistance of the rotor winding.',
      },
      {
        name: 'K',
        data_type: 'float',
        default_value: '1.0',
        description:
          'Torque constant K in torque = K*rotor_current. Also Back EMF constant E in BackEMF = E*rotor_rotational_speed.',
      },
      {
        name: 'L',
        data_type: 'float',
        default_value: '1e-6',
        description: 'Series inductance of the rotor winding.',
      },
      {
        name: 'J',
        data_type: 'float',
        default_value: '0.1',
        description: 'Rotor inertia.',
      },
      {
        name: 'initial_angle',
        data_type: 'float',
        default_value: '0.0',
        description: 'Initial angular position.',
      },
      {
        name: 'initial_angle_fixed',
        data_type: 'bool',
        default_value: 'False',
        description: 'Whether initial angle condition is fixed or not.',
      },
      {
        name: 'initial_velocity',
        data_type: 'float',
        default_value: '0.0',
        description: 'Initial angular velocity.',
      },
      {
        name: 'initial_velocity_fixed',
        data_type: 'bool',
        default_value: 'False',
        description: 'Whether initial velocity condition is fixed or not.',
      },
      {
        name: 'initial_current',
        data_type: 'float',
        default_value: '0.0',
        description: 'Initial electrical current.',
      },
      {
        name: 'initial_current_fixed',
        data_type: 'bool',
        default_value: 'False',
        description: 'Whether initial current condition is fixed or not.',
      },
      {
        name: 'enable_heat_port',
        data_type: 'bool',
        default_value: 'false',
        description: 'User selection for enabling thermal heat port.',
      },
    ],
  },
  'acausal.electrical.Inductor': {
    $schema: '../../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/acausal/electrical/inductor.json',
    base: {
      name: 'Inductor',
      namespace: 'acausal.electrical',
      description: 'Ideal linear electrical inductor.',
    },
    modes: {
      time: 'agnostic',
    },
    ports: {
      inputs: {
        static: [
          {
            name: 'p',
            variant: {
              variant_kind: 'acausal',
              acausal_domain: 'electrical',
            },
          },
        ],
      },
      outputs: {
        static: [
          {
            name: 'n',
            variant: {
              variant_kind: 'acausal',
              acausal_domain: 'electrical',
            },
          },
        ],
      },
    },
    parameter_definitions: [
      {
        name: 'L',
        data_type: 'float',
        default_value: '1.0',
        description: "Inductance in Henry's.",
      },
      {
        name: 'initial_current',
        data_type: 'float',
        default_value: '0.0',
        description: 'Initial current.',
      },
      {
        name: 'initial_current_fixed',
        data_type: 'bool',
        default_value: 'False',
        description: 'Whether initial current condition is fixed or not.',
      },
    ],
  },
  'acausal.electrical.IntegratedMotor': {
    $schema: '../../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/acausal/electrical/integrated_motor.json',
    base: {
      name: 'IntegratedMotor',
      namespace: 'acausal.electrical',
      description: 'Models Brushless DC Motor+Inverter System.',
    },
    modes: {
      time: 'agnostic',
    },
    ports: {
      inputs: {
        static: [
          {
            name: 'trq_req_norm',
          },
          {
            name: 'pos',
            variant: {
              variant_kind: 'acausal',
              acausal_domain: 'electrical',
            },
          },
          {
            name: 'neg',
            variant: {
              variant_kind: 'acausal',
              acausal_domain: 'electrical',
            },
          },
        ],
      },
      outputs: {
        static: [
          {
            name: 'shaft',
            variant: {
              variant_kind: 'acausal',
              acausal_domain: 'rotational',
            },
          },
        ],
        conditional: [
          {
            name: 'heat',
            condition_bool_parameter: 'enable_heat_port',
            order: 1,
            variant: {
              variant_kind: 'acausal',
              acausal_domain: 'thermal',
            },
          },
        ],
      },
    },
    parameter_definitions: [
      {
        name: 'J',
        data_type: 'float',
        default_value: '0.1',
        description: 'Rotor inertia.',
      },
      {
        name: 'peaktrq_spd',
        data_type: 'any',
        default_value: 'None',
        description: 'Speed vector for the peak torque curve, in rads/s.',
      },
      {
        name: 'peaktrq_trq',
        data_type: 'any',
        default_value: 'None',
        description: 'Torqe vector for the peak torque curve, in Nm.',
      },
      {
        name: 'peak_trq',
        data_type: 'float',
        default_value: 'None',
        description: 'Peak torque of the motor, in Nm.',
      },
      {
        name: 'peak_pwr',
        data_type: 'float',
        default_value: 'None',
        description: 'Peak power of the motor, in Watts.',
      },
      {
        name: 'peak_spd',
        data_type: 'float',
        default_value: 'None',
        description: 'Peak speed of the motor, in rads/s.',
      },
      {
        name: 'eff_spd',
        data_type: 'any',
        default_value: 'None',
        description: 'Speed vector for the efficiency map, in rads/s.',
      },
      {
        name: 'eff_trq',
        data_type: 'any',
        default_value: 'None',
        description: 'Torqe vector for the efficiency map, in Nm.',
      },
      {
        name: 'eff_eff',
        data_type: 'any',
        default_value: 'None',
        description:
          'The efficiency map, 0<eff<=1. Dimension len(eff_spd)*len(eff_trq).',
      },
      {
        name: 'eff_k',
        data_type: 'float',
        default_value: 'None',
        description: 'Scalar efficiency of the motor, 0<eff<=1.',
      },
      {
        name: 'enable_heat_port',
        data_type: 'bool',
        default_value: 'false',
        description: 'User selection for enabling thermal heat port.',
      },
    ],
  },
  'acausal.electrical.Resistor': {
    $schema: '../../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/acausal/electrical/resistor.json',
    base: {
      name: 'Resistor',
      namespace: 'acausal.electrical',
      description: 'Ideal linear electrical resistor.',
    },
    modes: {
      time: 'agnostic',
    },
    ports: {
      inputs: {
        static: [
          {
            name: 'p',
            variant: {
              variant_kind: 'acausal',
              acausal_domain: 'electrical',
            },
          },
        ],
      },
      outputs: {
        static: [
          {
            name: 'n',
            variant: {
              variant_kind: 'acausal',
              acausal_domain: 'electrical',
            },
          },
        ],
        conditional: [
          {
            name: 'heat',
            condition_bool_parameter: 'enable_heat_port',
            order: 1,
            variant: {
              variant_kind: 'acausal',
              acausal_domain: 'thermal',
            },
          },
        ],
      },
    },
    parameter_definitions: [
      {
        name: 'R',
        data_type: 'float',
        default_value: '1.0',
        description: 'Resistance in Ohms.',
      },
      {
        name: 'enable_heat_port',
        data_type: 'bool',
        default_value: 'false',
        description: 'User selection for enabling thermal heat port.',
      },
    ],
  },
  'acausal.electrical.VoltageSensor': {
    $schema: '../../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/acausal/electrical/voltage_sensor.json',
    base: {
      name: 'VoltageSensor',
      namespace: 'acausal.electrical',
      description: 'Voltage sensor.',
    },
    modes: {
      time: 'agnostic',
    },
    ports: {
      inputs: {
        static: [
          {
            name: 'p',
            variant: {
              variant_kind: 'acausal',
              acausal_domain: 'electrical',
            },
          },
        ],
      },
      outputs: {
        static: [
          {
            name: 'n',
            variant: {
              variant_kind: 'acausal',
              acausal_domain: 'electrical',
            },
          },
          {
            name: 'v',
          },
        ],
      },
    },
  },
  'acausal.electrical.VoltageSource': {
    $schema: '../../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/acausal/electrical/voltage_source.json',
    base: {
      name: 'VoltageSource',
      namespace: 'acausal.electrical',
      description: 'Voltage source.',
    },
    modes: {
      time: 'agnostic',
    },
    ports: {
      inputs: {
        static: [
          {
            name: 'p',
            variant: {
              variant_kind: 'acausal',
              acausal_domain: 'electrical',
            },
          },
        ],
        conditional: [
          {
            name: 'v',
            condition_bool_parameter: 'enable_voltage_port',
            order: 1,
          },
        ],
      },
      outputs: {
        static: [
          {
            name: 'n',
            variant: {
              variant_kind: 'acausal',
              acausal_domain: 'electrical',
            },
          },
        ],
      },
    },
    parameter_definitions: [
      {
        name: 'v',
        data_type: 'float',
        default_value: '1.0',
        description: 'Constant voltage.',
      },
      {
        name: 'enable_voltage_port',
        data_type: 'bool',
        default_value: 'false',
        description: 'User selection for enabling inport for voltage value.',
      },
    ],
  },
  'acausal.hydraulic.Accumulator': {
    $schema: '../../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/acausal/hydraulic/accumulator.json',
    base: {
      name: 'Accumulator',
      namespace: 'acausal.hydraulic',
      description: 'Fluid mass and pressure accumulator.',
    },
    modes: {
      time: 'agnostic',
    },
    ports: {
      inputs: {
        static: [
          {
            name: 'port',
            variant: {
              variant_kind: 'acausal',
              acausal_domain: 'hydraulic',
            },
          },
        ],
      },
    },
    parameter_definitions: [
      {
        name: 'area',
        data_type: 'float',
        default_value: '1.0',
        description: 'Surface area of the accumulator piston in m*m.',
      },
      {
        name: 'k',
        data_type: 'float',
        default_value: '1.0',
        description:
          'Spring constant of the spring acting pon the piston in N*m.',
      },
      {
        name: 'initial_pressure',
        data_type: 'float',
        default_value: '0.0',
        description: 'Initial pressure.',
      },
      {
        name: 'initial_pressure_fixed',
        data_type: 'bool',
        default_value: 'False',
        description: 'Whether initial pressure condition is fixed or not.',
      },
    ],
  },
  'acausal.hydraulic.HydraulicActuatorLinear': {
    $schema: '../../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/acausal/elec/hydraulic_actuator_linear.json',
    base: {
      name: 'HydraulicActuatorLinear',
      namespace: 'acausal.hydraulic',
      description:
        'Linear actuator between hydraulic and traslational domains.',
    },
    modes: {
      time: 'agnostic',
    },
    ports: {
      inputs: {
        static: [
          {
            name: 'port_a',
            variant: {
              variant_kind: 'acausal',
              acausal_domain: 'hydraulic',
            },
          },
          {
            name: 'port_b',
            variant: {
              variant_kind: 'acausal',
              acausal_domain: 'hydraulic',
            },
          },
        ],
      },
      outputs: {
        static: [
          {
            name: 'flange_a',
            variant: {
              variant_kind: 'acausal',
              acausal_domain: 'translational',
            },
          },
          {
            name: 'flange_b',
            variant: {
              variant_kind: 'acausal',
              acausal_domain: 'translational',
            },
          },
        ],
      },
    },
    parameter_definitions: [
      {
        name: 'area',
        data_type: 'float',
        default_value: '1.0',
        description: 'Surface area of the actuator piston in m*m.',
      },
      {
        name: 'initial_position_B',
        data_type: 'float',
        default_value: '0.0',
        description: 'Initial position of end B.',
      },
      {
        name: 'initial_position_B_fixed',
        data_type: 'bool',
        default_value: 'False',
        description: 'Whether initial position B condition is fixed or not.',
      },
    ],
  },
  'acausal.hydraulic.HydraulicProperties': {
    $schema: '../../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/acausal/hydraulic/hydraulic_properties.json',
    base: {
      name: 'HydraulicProperties',
      namespace: 'acausal.hydraulic',
      description: 'Fluid properties block for hydraulic domain.',
    },
    modes: {
      time: 'agnostic',
    },
    ports: {
      outputs: {
        static: [
          {
            name: 'port',
            variant: {
              variant_kind: 'acausal',
              acausal_domain: 'hydraulic',
            },
          },
        ],
      },
    },
    parameter_definitions: [
      {
        name: 'fluid_name',
        description: 'Select the fluid type.',
        data_type: 'string',
        default_value: 'water',
        allowed_values: ['water', 'hydraulicfluid'],
      },
    ],
  },
  'acausal.hydraulic.MassflowSensor': {
    $schema: '../../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/acausal/hydraulic/massflow_sensor.json',
    base: {
      name: 'MassflowSensor',
      namespace: 'acausal.hydraulic',
      description: 'Massflow sensor.',
    },
    modes: {
      time: 'agnostic',
    },
    ports: {
      inputs: {
        static: [
          {
            name: 'port_a',
            variant: {
              variant_kind: 'acausal',
              acausal_domain: 'hydraulic',
            },
          },
        ],
      },
      outputs: {
        static: [
          {
            name: 'port_b',
            variant: {
              variant_kind: 'acausal',
              acausal_domain: 'hydraulic',
            },
          },
          {
            name: 'm_flow',
          },
        ],
      },
    },
  },
  'acausal.hydraulic.Pipe': {
    $schema: '../../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/acausal/hydraulic/pipe.json',
    base: {
      name: 'Pipe',
      namespace: 'acausal.hydraulic',
      description: 'Simple pipe component in hydraulic domain.',
    },
    modes: {
      time: 'agnostic',
    },
    ports: {
      inputs: {
        static: [
          {
            name: 'port_a',
            variant: {
              variant_kind: 'acausal',
              acausal_domain: 'hydraulic',
            },
          },
        ],
        conditional: [
          {
            name: 'R',
            condition_bool_parameter: 'enable_resistance_port',
            order: 1,
          },
        ],
      },
      outputs: {
        static: [
          {
            name: 'port_b',
            variant: {
              variant_kind: 'acausal',
              acausal_domain: 'hydraulic',
            },
          },
        ],
      },
    },
    parameter_definitions: [
      {
        name: 'R',
        data_type: 'float',
        default_value: '1.0',
        description: 'Flow resistance coefficient in Pa/(kg/s).',
      },
      {
        name: 'enable_resistance_port',
        data_type: 'bool',
        default_value: 'false',
        description: 'User selection for enabling inport for R value.',
      },
    ],
  },
  'acausal.hydraulic.PressureSensor': {
    $schema: '../../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/acausal/hydraulic/pressure_sensor.json',
    base: {
      name: 'PressureSensor',
      namespace: 'acausal.hydraulic',
      description: 'Pressure sensor.',
    },
    modes: {
      time: 'agnostic',
    },
    ports: {
      inputs: {
        static: [
          {
            name: 'port_a',
            variant: {
              variant_kind: 'acausal',
              acausal_domain: 'hydraulic',
            },
          },
        ],
      },
      outputs: {
        static: [
          {
            name: 'P_rel',
          },
        ],
        conditional: [
          {
            name: 'port_b',
            condition_bool_parameter: 'enable_port_b',
            order: 0,
            variant: {
              variant_kind: 'acausal',
              acausal_domain: 'hydraulic',
            },
          },
        ],
      },
    },
    parameter_definitions: [
      {
        name: 'enable_port_b',
        data_type: 'bool',
        default_value: 'true',
        description: 'User selection for enabling the port_b port',
      },
    ],
  },
  'acausal.hydraulic.PressureSource': {
    $schema: '../../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/acausal/hydraulic/pressure_source.json',
    base: {
      name: 'PressureSource',
      namespace: 'acausal.hydraulic',
      description: 'Pressure source.',
    },
    modes: {
      time: 'agnostic',
    },
    ports: {
      inputs: {
        static: [
          {
            name: 'port',
            variant: {
              variant_kind: 'acausal',
              acausal_domain: 'hydraulic',
            },
          },
        ],
        conditional: [
          {
            name: 'pressure',
            condition_bool_parameter: 'enable_pressure_port',
            order: 1,
          },
        ],
      },
    },
    parameter_definitions: [
      {
        name: 'pressure',
        data_type: 'float',
        default_value: '0.1',
        description: 'Constant pressure in Pa.',
      },
      {
        name: 'enable_pressure_port',
        data_type: 'bool',
        default_value: 'false',
        description: 'User selection for enabling inport for pressure value.',
      },
    ],
  },
  'acausal.hydraulic.Pump': {
    $schema: '../../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/acausal/hydraulic/pump.json',
    base: {
      name: 'Pump',
      namespace: 'acausal.hydraulic',
      description: 'Simple pump in hydraulic domain.',
    },
    modes: {
      time: 'agnostic',
    },
    ports: {
      inputs: {
        static: [
          {
            name: 'port_a',
            variant: {
              variant_kind: 'acausal',
              acausal_domain: 'hydraulic',
            },
          },
          {
            name: 'power',
          },
        ],
      },
      outputs: {
        static: [
          {
            name: 'port_b',
            variant: {
              variant_kind: 'acausal',
              acausal_domain: 'hydraulic',
            },
          },
        ],
      },
    },
    parameter_definitions: [
      {
        name: 'dPmax',
        data_type: 'float',
        default_value: '1.0',
        description:
          'Maximum pressure differential in Pa. The max outlet pressure is dP_max - P_in.',
      },
      {
        name: 'CoP',
        data_type: 'float',
        default_value: '1.0',
        description:
          'Pump performance coefficient that converts dP*power to massflow. CoP has units of kg*s*MPa/Watt.',
      },
    ],
  },
  'acausal.rotational.Damper': {
    $schema: '../../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/acausal/rotational/damper.json',
    base: {
      name: 'Damper',
      namespace: 'acausal.rotational',
      description: 'Ideal linear 1D rotational damper.',
    },
    modes: {
      time: 'agnostic',
    },
    ports: {
      inputs: {
        static: [
          {
            name: 'flange_a',
            variant: {
              variant_kind: 'acausal',
              acausal_domain: 'rotational',
            },
          },
        ],
      },
      outputs: {
        static: [
          {
            name: 'flange_b',
            variant: {
              variant_kind: 'acausal',
              acausal_domain: 'rotational',
            },
          },
        ],
      },
    },
    parameter_definitions: [
      {
        name: 'D',
        data_type: 'float',
        default_value: '1.0',
        description: 'Damping coefficient in N.m.s/rad',
      },
      {
        name: 'initial_angle_A',
        data_type: 'float',
        default_value: '0.0',
        description: 'Initial angle of end A.',
      },
      {
        name: 'initial_angle_A_fixed',
        data_type: 'bool',
        default_value: 'False',
        description: 'Whether initial angle A condition is fixed or not.',
      },
      {
        name: 'initial_velocity_A',
        data_type: 'float',
        default_value: '0.0',
        description: 'Initial velocity of end A.',
      },
      {
        name: 'initial_velocity_A_fixed',
        data_type: 'bool',
        default_value: 'False',
        description: 'Whether initial velocity A condition is fixed or not.',
      },
      {
        name: 'initial_angle_B',
        data_type: 'float',
        default_value: '0.0',
        description: 'Initial angle of end B.',
      },
      {
        name: 'initial_angle_B_fixed',
        data_type: 'bool',
        default_value: 'False',
        description: 'Whether initial angle B condition is fixed or not.',
      },
      {
        name: 'initial_velocity_B',
        data_type: 'float',
        default_value: '0.0',
        description: 'Initial velocity of end B.',
      },
      {
        name: 'initial_velocity_B_fixed',
        data_type: 'bool',
        default_value: 'False',
        description: 'Whether initial velocity B condition is fixed or not.',
      },
    ],
  },
  'acausal.rotational.Engine': {
    $schema: '../../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/acausal/rotational/engine.json',
    base: {
      name: 'Engine',
      namespace: 'acausal.rotational',
      description: 'Basic engine modeled as modulated torque source.',
    },
    modes: {
      time: 'agnostic',
    },
    ports: {
      inputs: {
        static: [
          {
            name: 'thr',
          },
        ],
      },
      outputs: {
        static: [
          {
            name: 'flange',
            variant: {
              variant_kind: 'acausal',
              acausal_domain: 'rotational',
            },
          },
        ],
      },
    },
    parameter_definitions: [
      {
        name: 'peak_trq_w',
        data_type: 'any',
        default_value: '[0.0, 99, 100, 500, 600]',
        description:
          'Speed break points for peak torque curve, in radians/second.',
      },
      {
        name: 'peak_trq_t',
        data_type: 'any',
        default_value: '[-1e6, 1e6]',
        description: 'Torque points for peak torque curve, in Nm.',
      },
    ],
  },
  'acausal.rotational.FixedAngle': {
    $schema: '../../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/acausal/rotational/fixed_angle.json',
    base: {
      name: 'FixedAngle',
      namespace: 'acausal.rotational',
      description: 'Fixed mechanical rotational reference.',
    },
    modes: {
      time: 'agnostic',
    },
    ports: {
      outputs: {
        static: [
          {
            name: 'flange',
            variant: {
              variant_kind: 'acausal',
              acausal_domain: 'rotational',
            },
          },
        ],
      },
    },
    parameter_definitions: [
      {
        name: 'initial_angle',
        data_type: 'float',
        default_value: '0.0',
        description: 'Angular position of fixed reference.',
      },
    ],
  },
  'acausal.rotational.Friction': {
    $schema: '../../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/acausal/rotational/friction.json',
    base: {
      name: 'Friction',
      namespace: 'acausal.rotational',
      description: 'Friction in rotational domain.',
    },
    modes: {
      time: 'agnostic',
    },
    ports: {
      inputs: {
        static: [
          {
            name: 'flange_a',
            variant: {
              variant_kind: 'acausal',
              acausal_domain: 'rotational',
            },
          },
        ],
      },
      outputs: {
        static: [
          {
            name: 'flange_b',
            variant: {
              variant_kind: 'acausal',
              acausal_domain: 'rotational',
            },
          },
        ],
      },
    },
    parameter_definitions: [
      {
        name: 'Tc',
        data_type: 'float',
        default_value: '1.0',
        description: 'Coulomb friction coefficient in Nm.',
      },
      {
        name: 'Wbrk',
        data_type: 'float',
        default_value: '0.1',
        description: 'Break away friction velocity threshold in rad/s.',
      },
      {
        name: 'C',
        data_type: 'float',
        default_value: 'None',
        description: 'Viscous friction coefficient in N.m.s/rad.',
        optional: true,
      },
    ],
  },
  'acausal.rotational.Gear': {
    $schema: '../../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/acausal/rotational/gear.json',
    base: {
      name: 'Gear',
      namespace: 'acausal.rotational',
      description: 'Gear ratio with efficiency map based on speed and torque.',
    },
    modes: {
      time: 'agnostic',
    },
    ports: {
      inputs: {
        static: [
          {
            name: 'flange_a',
            variant: {
              variant_kind: 'acausal',
              acausal_domain: 'rotational',
            },
          },
        ],
      },
      outputs: {
        static: [
          {
            name: 'flange_b',
            variant: {
              variant_kind: 'acausal',
              acausal_domain: 'rotational',
            },
          },
        ],
      },
    },
    parameter_definitions: [
      {
        name: 'r',
        data_type: 'float',
        default_value: '1.0',
        description: 'Gear ratio.',
      },
      {
        name: 'spd_pts',
        data_type: 'any',
        default_value: 'None',
        description: 'Speed break points for efficiency map.',
      },
      {
        name: 'trq_pts',
        data_type: 'any',
        default_value: 'None',
        description: 'Torque break points for efficiency map.',
      },
      {
        name: 'eff',
        data_type: 'any',
        default_value: 'None',
        description: 'Efficiency map.',
      },
    ],
  },
  'acausal.rotational.IdealPlanetary': {
    $schema: '../../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/acausal/rotational/ideal_planetary.json',
    base: {
      name: 'IdealPlanetary',
      namespace: 'acausal.rotational',
      description: 'Ideal planetary gear set in rotational domain.',
    },
    modes: {
      time: 'agnostic',
    },
    ports: {
      inputs: {
        static: [
          {
            name: 'carrier',
            variant: {
              variant_kind: 'acausal',
              acausal_domain: 'rotational',
            },
          },
          {
            name: 'sun',
            variant: {
              variant_kind: 'acausal',
              acausal_domain: 'rotational',
            },
          },
        ],
      },
      outputs: {
        static: [
          {
            name: 'ring',
            variant: {
              variant_kind: 'acausal',
              acausal_domain: 'rotational',
            },
          },
        ],
      },
    },
    parameter_definitions: [
      {
        name: 'r',
        data_type: 'float',
        default_value: '2.0',
        description: 'Gear ratio.',
      },
    ],
  },
  'acausal.rotational.IdealWheel': {
    $schema: '../../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/acausal/rotational/ideal_wheel.json',
    base: {
      name: 'IdealWheel',
      namespace: 'acausal.rotational',
      description: 'Ideal wheel between rotational and translation domains.',
    },
    modes: {
      time: 'agnostic',
    },
    ports: {
      inputs: {
        static: [
          {
            name: 'shaft',
            variant: {
              variant_kind: 'acausal',
              acausal_domain: 'rotational',
            },
          },
        ],
      },
      outputs: {
        static: [
          {
            name: 'flange',
            variant: {
              variant_kind: 'acausal',
              acausal_domain: 'translational',
            },
          },
        ],
      },
    },
    parameter_definitions: [
      {
        name: 'r',
        data_type: 'float',
        default_value: '1.0',
        description: 'Radius of the wheel in meters.',
      },
    ],
  },
  'acausal.rotational.Inertia': {
    $schema: '../../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/acausal/rotational/inertia.json',
    base: {
      name: 'Inertia',
      namespace: 'acausal.rotational',
      description: 'One dimensional rotational inertia.',
    },
    modes: {
      time: 'agnostic',
    },
    ports: {
      inputs: {
        static: [
          {
            name: 'flange',
            variant: {
              variant_kind: 'acausal',
              acausal_domain: 'rotational',
            },
          },
        ],
      },
    },
    parameter_definitions: [
      {
        name: 'I',
        data_type: 'float',
        default_value: '1.0',
        description: 'Interia in kg*m2',
      },
      {
        name: 'initial_angle',
        data_type: 'float',
        default_value: '0.0',
        description: 'Initial angular position.',
      },
      {
        name: 'initial_angle_fixed',
        data_type: 'bool',
        default_value: 'False',
        description: 'Whether initial angle condition is fixed or not.',
      },
      {
        name: 'initial_velocity',
        data_type: 'float',
        default_value: '0.0',
        description: 'Initial angular velocity.',
      },
      {
        name: 'initial_velocity_fixed',
        data_type: 'bool',
        default_value: 'False',
        description: 'Whether initial velocity condition is fixed or not.',
      },
    ],
  },
  'acausal.rotational.MotionSensor': {
    $schema: '../../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/acausal/rotational/motion_sensor.json',
    base: {
      name: 'MotionSensor',
      namespace: 'acausal.rotational',
      description: 'Rotational motion sensor',
    },
    modes: {
      time: 'agnostic',
    },
    ports: {
      inputs: {
        static: [
          {
            name: 'flange_a',
            variant: {
              variant_kind: 'acausal',
              acausal_domain: 'rotational',
            },
          },
        ],
      },
      outputs: {
        conditional: [
          {
            name: 'flange_b',
            condition_bool_parameter: 'enable_flange_b',
            order: 0,
            variant: {
              variant_kind: 'acausal',
              acausal_domain: 'rotational',
            },
          },
          {
            name: 'ang_rel',
            condition_bool_parameter: 'enable_angle_port',
            order: 1,
          },
          {
            name: 'w_rel',
            condition_bool_parameter: 'enable_velocity_port',
            order: 2,
          },
          {
            name: 'alpha_rel',
            condition_bool_parameter: 'enable_acceleration_port',
            order: 3,
          },
        ],
      },
    },
    parameter_definitions: [
      {
        name: 'enable_flange_b',
        data_type: 'bool',
        default_value: 'true',
        description: 'User selection for enabling the flange_b port',
      },
      {
        name: 'enable_angle_port',
        data_type: 'bool',
        default_value: 'false',
        description: 'User selection for enabling the angle output',
      },
      {
        name: 'enable_velocity_port',
        data_type: 'bool',
        default_value: 'true',
        description: 'User selection for enabling the velocity output',
      },
      {
        name: 'enable_acceleration_port',
        data_type: 'bool',
        default_value: 'false',
        description: 'User selection for enabling the acceleration output',
      },
    ],
  },
  'acausal.rotational.SpeedSource': {
    $schema: '../../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/acausal/rotational/speed_source.json',
    base: {
      name: 'SpeedSource',
      namespace: 'acausal.rotational',
      description: 'Rotational speed source.',
      feature_level: 'dev',
    },
    modes: {
      time: 'agnostic',
    },
    ports: {
      inputs: {
        static: [
          {
            name: 'flange_a',
            variant: {
              variant_kind: 'acausal',
              acausal_domain: 'rotational',
            },
          },
        ],
        conditional: [
          {
            name: 'w_ref',
            condition_bool_parameter: 'enable_speed_port',
            order: 1,
          },
        ],
      },
      outputs: {
        conditional: [
          {
            name: 'flange_b',
            condition_bool_parameter: 'enable_flange_b',
            order: 0,
            variant: {
              variant_kind: 'acausal',
              acausal_domain: 'rotational',
            },
          },
        ],
      },
    },
    parameter_definitions: [
      {
        name: 'w_ref',
        data_type: 'float',
        default_value: '1.0',
        description: 'Constant speed in radians/second.',
      },
      {
        name: 'enable_speed_port',
        data_type: 'bool',
        default_value: 'false',
        description: 'User selection for enabling inport for speed value.',
      },
      {
        name: 'enable_flange_b',
        data_type: 'bool',
        default_value: 'true',
        description: 'User selection for enabling the flange_b port',
      },
    ],
  },
  'acausal.rotational.Spring': {
    $schema: '../../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/acausal/rotational/spring.json',
    base: {
      name: 'Spring',
      namespace: 'acausal.rotational',
      description: 'Ideal linear 1D rotational spring.',
    },
    modes: {
      time: 'agnostic',
    },
    ports: {
      inputs: {
        static: [
          {
            name: 'flange_a',
            variant: {
              variant_kind: 'acausal',
              acausal_domain: 'rotational',
            },
          },
        ],
      },
      outputs: {
        static: [
          {
            name: 'flange_b',
            variant: {
              variant_kind: 'acausal',
              acausal_domain: 'rotational',
            },
          },
        ],
      },
    },
    parameter_definitions: [
      {
        name: 'K',
        data_type: 'float',
        default_value: '1.0',
        description: 'Spring constant in N.m/rad',
      },
      {
        name: 'initial_angle_A',
        data_type: 'float',
        default_value: '0.0',
        description: 'Initial angle of end A.',
      },
      {
        name: 'initial_angle_A_fixed',
        data_type: 'bool',
        default_value: 'False',
        description: 'Whether initial angle A condition is fixed or not.',
      },
      {
        name: 'initial_velocity_A',
        data_type: 'float',
        default_value: '0.0',
        description: 'Initial velocity of end A.',
      },
      {
        name: 'initial_velocity_A_fixed',
        data_type: 'bool',
        default_value: 'False',
        description: 'Whether initial velocity A condition is fixed or not.',
      },
      {
        name: 'initial_angle_B',
        data_type: 'float',
        default_value: '0.0',
        description: 'Initial angle of end B.',
      },
      {
        name: 'initial_angle_B_fixed',
        data_type: 'bool',
        default_value: 'False',
        description: 'Whether initial angle B condition is fixed or not.',
      },
      {
        name: 'initial_velocity_B',
        data_type: 'float',
        default_value: '0.0',
        description: 'Initial velocity of end B.',
      },
      {
        name: 'initial_velocity_B_fixed',
        data_type: 'bool',
        default_value: 'False',
        description: 'Whether initial velocity B condition is fixed or not.',
      },
    ],
  },
  'acausal.rotational.TorqueSensor': {
    $schema: '../../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/acausal/rotational/torque_sensor.json',
    base: {
      name: 'TorqueSensor',
      namespace: 'acausal.rotational',
      description: 'Torque sensor',
    },
    modes: {
      time: 'agnostic',
    },
    ports: {
      inputs: {
        static: [
          {
            name: 'flange_a',
            variant: {
              variant_kind: 'acausal',
              acausal_domain: 'rotational',
            },
          },
        ],
      },
      outputs: {
        static: [
          {
            name: 'flange_b',
            variant: {
              variant_kind: 'acausal',
              acausal_domain: 'rotational',
            },
          },
          {
            name: 'tau',
          },
        ],
      },
    },
    parameter_definitions: [],
  },
  'acausal.rotational.TorqueSource': {
    $schema: '../../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/acausal/rotational/torque_source.json',
    base: {
      name: 'TorqueSource',
      namespace: 'acausal.rotational',
      description: 'Torque source.',
    },
    modes: {
      time: 'agnostic',
    },
    ports: {
      inputs: {
        static: [
          {
            name: 'flange_a',
            variant: {
              variant_kind: 'acausal',
              acausal_domain: 'rotational',
            },
          },
        ],
        conditional: [
          {
            name: 'tau',
            condition_bool_parameter: 'enable_torque_port',
            order: 1,
          },
        ],
      },
      outputs: {
        conditional: [
          {
            name: 'flange_b',
            order: 0,
            condition_bool_parameter: 'enable_flange_b',
            variant: {
              variant_kind: 'acausal',
              acausal_domain: 'rotational',
            },
          },
        ],
      },
    },
    parameter_definitions: [
      {
        name: 'tau',
        data_type: 'float',
        default_value: '1.0',
        description: 'Constant torque in Nm.',
      },
      {
        name: 'enable_torque_port',
        data_type: 'bool',
        default_value: 'false',
        description: 'User selection for enabling inport for torque value.',
      },
      {
        name: 'enable_flange_b',
        data_type: 'bool',
        default_value: 'true',
        description: 'User selection for enabling the flange_b port',
      },
    ],
  },
  'acausal.thermal.HeatCapacitor': {
    $schema: '../../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/acausal/thermal/heat_capacitor.json',
    base: {
      name: 'HeatCapacitor',
      namespace: 'acausal.thermal',
      description: 'Ideal linear heat capacitor.',
    },
    modes: {
      time: 'agnostic',
    },
    ports: {
      inputs: {
        static: [
          {
            name: 'port',
            variant: {
              variant_kind: 'acausal',
              acausal_domain: 'thermal',
            },
          },
        ],
      },
    },
    parameter_definitions: [
      {
        name: 'C',
        data_type: 'float',
        default_value: '1.0',
        description: 'Capacitance in Farads.',
      },
      {
        name: 'initial_temperature',
        data_type: 'float',
        default_value: '300.0',
        description: 'Initial temperature.',
      },
      {
        name: 'initial_temperature_fixed',
        data_type: 'bool',
        default_value: 'False',
        description: 'Whether initial temperature condition is fixed or not.',
      },
    ],
  },
  'acausal.thermal.HeatflowSensor': {
    $schema: '../../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/acausal/thermal/heatflow_sensor.json',
    base: {
      name: 'HeatflowSensor',
      namespace: 'acausal.thermal',
      description: 'Heatflow sensor.',
    },
    modes: {
      time: 'agnostic',
    },
    ports: {
      inputs: {
        static: [
          {
            name: 'port_a',
            variant: {
              variant_kind: 'acausal',
              acausal_domain: 'thermal',
            },
          },
        ],
      },
      outputs: {
        static: [
          {
            name: 'port_b',
            variant: {
              variant_kind: 'acausal',
              acausal_domain: 'thermal',
            },
          },
          {
            name: 'Q_flow',
          },
        ],
      },
    },
  },
  'acausal.thermal.HeatflowSource': {
    $schema: '../../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/acausal/elec/heatflow_source.json',
    base: {
      name: 'HeatflowSource',
      namespace: 'acausal.thermal',
      description: 'Heatflow source.',
    },
    modes: {
      time: 'agnostic',
    },
    ports: {
      inputs: {
        static: [
          {
            name: 'port_a',
            variant: {
              variant_kind: 'acausal',
              acausal_domain: 'thermal',
            },
          },
        ],
        conditional: [
          {
            name: 'Q_flow',
            condition_bool_parameter: 'enable_heat_port',
            order: 1,
          },
        ],
      },
      outputs: {
        conditional: [
          {
            name: 'port_b',
            order: 0,
            condition_bool_parameter: 'enable_port_b',
            variant: {
              variant_kind: 'acausal',
              acausal_domain: 'thermal',
            },
          },
        ],
      },
    },
    parameter_definitions: [
      {
        name: 'Q_flow',
        data_type: 'float',
        default_value: '1.0',
        description: 'Constant heatflow.',
      },
      {
        name: 'enable_heat_port',
        data_type: 'bool',
        default_value: 'false',
        description: 'User selection for enabling inport for heatflow value.',
      },
      {
        name: 'enable_port_b',
        data_type: 'bool',
        default_value: 'true',
        description: 'User selection for enabling the port_b port',
      },
    ],
  },
  'acausal.thermal.Insulator': {
    $schema: '../../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/acausal/thermal/insulator.json',
    base: {
      name: 'Insulator',
      namespace: 'acausal.thermal',
      description: 'thermal insulator.',
    },
    modes: {
      time: 'agnostic',
    },
    ports: {
      inputs: {
        static: [
          {
            name: 'port_a',
            variant: {
              variant_kind: 'acausal',
              acausal_domain: 'thermal',
            },
          },
        ],
        conditional: [
          {
            name: 'R',
            condition_bool_parameter: 'enable_resistance_port',
            order: 1,
          },
        ],
      },
      outputs: {
        static: [
          {
            name: 'port_b',
            variant: {
              variant_kind: 'acausal',
              acausal_domain: 'thermal',
            },
          },
        ],
      },
    },
    parameter_definitions: [
      {
        name: 'R',
        data_type: 'float',
        default_value: '1.0',
        description:
          'Insulation coefficient in degrees Kelvin per Watt of heat flow.',
      },
      {
        name: 'enable_resistance_port',
        data_type: 'bool',
        default_value: 'false',
        description: 'User selection for enabling inport for R value.',
      },
    ],
  },
  'acausal.thermal.Radiation': {
    $schema: '../../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/acausal/thermal/radiation.json',
    base: {
      name: 'Radiation',
      namespace: 'acausal.thermal',
      description: 'thermal radiation.',
    },
    modes: {
      time: 'agnostic',
    },
    ports: {
      inputs: {
        static: [
          {
            name: 'port_a',
            variant: {
              variant_kind: 'acausal',
              acausal_domain: 'thermal',
            },
          },
        ],
        conditional: [
          {
            name: 'Gr',
            condition_bool_parameter: 'enable_Gr_port',
            order: 1,
          },
        ],
      },
      outputs: {
        static: [
          {
            name: 'port_b',
            variant: {
              variant_kind: 'acausal',
              acausal_domain: 'thermal',
            },
          },
        ],
      },
    },
    parameter_definitions: [
      {
        name: 'Gr',
        data_type: 'float',
        default_value: '1.0',
        description: 'Radiation coefficient in m**2.',
      },
      {
        name: 'enable_Gr_port',
        data_type: 'bool',
        default_value: 'false',
        description: 'User selection for enabling inport for Gr value.',
      },
    ],
  },
  'acausal.thermal.TemperatureSensor': {
    $schema: '../../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/acausal/thermal/temperature_sensor.json',
    base: {
      name: 'TemperatureSensor',
      namespace: 'acausal.thermal',
      description: 'Temperature sensor.',
    },
    modes: {
      time: 'agnostic',
    },
    ports: {
      inputs: {
        static: [
          {
            name: 'port_a',
            variant: {
              variant_kind: 'acausal',
              acausal_domain: 'thermal',
            },
          },
        ],
      },
      outputs: {
        static: [
          {
            name: 'T_rel',
          },
        ],
        conditional: [
          {
            name: 'port_b',
            condition_bool_parameter: 'enable_port_b',
            order: 0,
            variant: {
              variant_kind: 'acausal',
              acausal_domain: 'thermal',
            },
          },
        ],
      },
    },
    parameter_definitions: [
      {
        name: 'enable_port_b',
        data_type: 'bool',
        default_value: 'true',
        description: 'User selection for enabling the port_b port',
      },
    ],
  },
  'acausal.thermal.TemperatureSource': {
    $schema: '../../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/acausal/thermal/temperature_source.json',
    base: {
      name: 'TemperatureSource',
      namespace: 'acausal.thermal',
      description: 'Temperature source.',
    },
    modes: {
      time: 'agnostic',
    },
    ports: {
      inputs: {
        static: [
          {
            name: 'port',
            variant: {
              variant_kind: 'acausal',
              acausal_domain: 'thermal',
            },
          },
        ],
        conditional: [
          {
            name: 'temperature',
            condition_bool_parameter: 'enable_temperature_port',
            order: 1,
          },
        ],
      },
    },
    parameter_definitions: [
      {
        name: 'temperature',
        data_type: 'float',
        default_value: '300.0',
        description: 'Constant temperature in Kelvin.',
      },
      {
        name: 'enable_temperature_port',
        data_type: 'bool',
        default_value: 'false',
        description:
          'User selection for enabling inport for temperatrue value.',
      },
    ],
  },
  'acausal.translational.Damper': {
    $schema: '../../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/acausal/translational/damper.json',
    base: {
      name: 'Damper',
      namespace: 'acausal.translational',
      description: 'Ideal linear 1D translational damper.',
    },
    modes: {
      time: 'agnostic',
    },
    ports: {
      inputs: {
        static: [
          {
            name: 'flange_a',
            variant: {
              variant_kind: 'acausal',
              acausal_domain: 'translational',
            },
          },
        ],
      },
      outputs: {
        static: [
          {
            name: 'flange_b',
            variant: {
              variant_kind: 'acausal',
              acausal_domain: 'translational',
            },
          },
        ],
      },
    },
    parameter_definitions: [
      {
        name: 'D',
        data_type: 'float',
        default_value: '1.0',
        description: 'Damping coefficient in N.s/m',
      },
      {
        name: 'initial_position_A',
        data_type: 'float',
        default_value: '0.0',
        description: 'Initial position of end A.',
      },
      {
        name: 'initial_position_A_fixed',
        data_type: 'bool',
        default_value: 'False',
        description: 'Whether initial position A condition is fixed or not.',
      },
      {
        name: 'initial_velocity_A',
        data_type: 'float',
        default_value: '0.0',
        description: 'Initial velocity of end A.',
      },
      {
        name: 'initial_velocity_A_fixed',
        data_type: 'bool',
        default_value: 'False',
        description: 'Whether initial velocity A condition is fixed or not.',
      },
      {
        name: 'initial_position_B',
        data_type: 'float',
        default_value: '0.0',
        description: 'Initial position of end B.',
      },
      {
        name: 'initial_position_B_fixed',
        data_type: 'bool',
        default_value: 'False',
        description: 'Whether initial position B condition is fixed or not.',
      },
      {
        name: 'initial_velocity_B',
        data_type: 'float',
        default_value: '0.0',
        description: 'Initial velocity of end B.',
      },
      {
        name: 'initial_velocity_B_fixed',
        data_type: 'bool',
        default_value: 'False',
        description: 'Whether initial velocity B condition is fixed or not.',
      },
    ],
  },
  'acausal.translational.FixedPosition': {
    $schema: '../../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/acausal/translational/fixed_position.json',
    base: {
      name: 'FixedPosition',
      namespace: 'acausal.translational',
      description: 'Fixed mechanical translational reference.',
    },
    modes: {
      time: 'agnostic',
    },
    ports: {
      outputs: {
        static: [
          {
            name: 'flange',
            variant: {
              variant_kind: 'acausal',
              acausal_domain: 'translational',
            },
          },
        ],
      },
    },
    parameter_definitions: [
      {
        name: 'initial_position',
        data_type: 'float',
        default_value: '0.0',
        description: 'Position of fixed reference.',
      },
    ],
  },
  'acausal.translational.ForceSensor': {
    $schema: '../../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/acausal/translational/force_sensor.json',
    base: {
      name: 'ForceSensor',
      namespace: 'acausal.translational',
      description: 'Force sensor',
    },
    modes: {
      time: 'agnostic',
    },
    ports: {
      inputs: {
        static: [
          {
            name: 'flange_a',
            variant: {
              variant_kind: 'acausal',
              acausal_domain: 'translational',
            },
          },
        ],
      },
      outputs: {
        static: [
          {
            name: 'flange_b',
            variant: {
              variant_kind: 'acausal',
              acausal_domain: 'translational',
            },
          },
          {
            name: 'f',
          },
        ],
      },
    },
    parameter_definitions: [],
  },
  'acausal.translational.ForceSource': {
    $schema: '../../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/acausal/translational/force_source.json',
    base: {
      name: 'ForceSource',
      namespace: 'acausal.translational',
      description: 'Force source.',
    },
    modes: {
      time: 'agnostic',
    },
    ports: {
      inputs: {
        static: [
          {
            name: 'flange_a',
            variant: {
              variant_kind: 'acausal',
              acausal_domain: 'translational',
            },
          },
        ],
        conditional: [
          {
            name: 'f',
            condition_bool_parameter: 'enable_force_port',
            order: 1,
          },
        ],
      },
      outputs: {
        conditional: [
          {
            name: 'flange_b',
            condition_bool_parameter: 'enable_flange_b',
            order: 0,
            variant: {
              variant_kind: 'acausal',
              acausal_domain: 'translational',
            },
          },
        ],
      },
    },
    parameter_definitions: [
      {
        name: 'f',
        data_type: 'float',
        default_value: '1.0',
        description: 'Constant force in N.',
      },
      {
        name: 'enable_force_port',
        data_type: 'bool',
        default_value: 'false',
        description: 'User selection for enabling inport for force value.',
      },
      {
        name: 'enable_flange_b',
        data_type: 'bool',
        default_value: 'true',
        description: 'User selection for enabling the flange_b port',
      },
    ],
  },
  'acausal.translational.Friction': {
    $schema: '../../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/acausal/translational/friction.json',
    base: {
      name: 'Friction',
      namespace: 'acausal.translational',
      description: 'Friction in translational domain.',
    },
    modes: {
      time: 'agnostic',
    },
    ports: {
      inputs: {
        static: [
          {
            name: 'flange_a',
            variant: {
              variant_kind: 'acausal',
              acausal_domain: 'translational',
            },
          },
        ],
      },
      outputs: {
        static: [
          {
            name: 'flange_b',
            variant: {
              variant_kind: 'acausal',
              acausal_domain: 'translational',
            },
          },
        ],
      },
    },
    parameter_definitions: [
      {
        name: 'Fc',
        data_type: 'float',
        default_value: '1.0',
        description: 'Coulomb friction coefficient in Newtons.',
      },
      {
        name: 'Vbrk',
        data_type: 'float',
        default_value: '0.1',
        description: 'Break away friction velocity threshold in m/s.',
      },
      {
        name: 'C',
        data_type: 'float',
        default_value: 'None',
        description: 'Viscous friction coefficient in N.s/m',
        optional: true,
      },
    ],
  },
  'acausal.translational.Mass': {
    $schema: '../../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/acausal/translational/mass.json',
    base: {
      name: 'Mass',
      namespace: 'acausal.translational',
      description: 'Translational mass.',
    },
    modes: {
      time: 'agnostic',
    },
    ports: {
      inputs: {
        static: [
          {
            name: 'flange',
            variant: {
              variant_kind: 'acausal',
              acausal_domain: 'translational',
            },
          },
        ],
      },
    },
    parameter_definitions: [
      {
        name: 'M',
        data_type: 'float',
        default_value: '1.0',
        description: 'Mass in kg',
      },
      {
        name: 'initial_position',
        data_type: 'float',
        default_value: '0.0',
        description: 'Initial position.',
      },
      {
        name: 'initial_position_fixed',
        data_type: 'bool',
        default_value: 'False',
        description: 'Whether initial position condition is fixed or not.',
      },
      {
        name: 'initial_velocity',
        data_type: 'float',
        default_value: '0.0',
        description: 'Initial angular velocity.',
      },
      {
        name: 'initial_velocity_fixed',
        data_type: 'bool',
        default_value: 'False',
        description: 'Whether initial velocity condition is fixed or not.',
      },
    ],
  },
  'acausal.translational.MotionSensor': {
    $schema: '../../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/acausal/translational/motion_sensor.json',
    base: {
      name: 'MotionSensor',
      namespace: 'acausal.translational',
      description: 'Translational motion sensor',
    },
    modes: {
      time: 'agnostic',
    },
    ports: {
      inputs: {
        static: [
          {
            name: 'flange_a',
            variant: {
              variant_kind: 'acausal',
              acausal_domain: 'translational',
            },
          },
        ],
      },
      outputs: {
        conditional: [
          {
            name: 'flange_b',
            condition_bool_parameter: 'enable_flange_b',
            order: 0,
            variant: {
              variant_kind: 'acausal',
              acausal_domain: 'translational',
            },
          },
          {
            name: 'x_rel',
            condition_bool_parameter: 'enable_position_port',
            order: 1,
          },
          {
            name: 'v_rel',
            condition_bool_parameter: 'enable_velocity_port',
            order: 2,
          },
          {
            name: 'a_rel',
            condition_bool_parameter: 'enable_acceleration_port',
            order: 3,
          },
        ],
      },
    },
    parameter_definitions: [
      {
        name: 'enable_flange_b',
        data_type: 'bool',
        default_value: 'true',
        description: 'User selection for enabling the flange_b port',
      },
      {
        name: 'enable_position_port',
        data_type: 'bool',
        default_value: 'false',
        description: 'User selection for enabling the position output',
      },
      {
        name: 'enable_velocity_port',
        data_type: 'bool',
        default_value: 'true',
        description: 'User selection for enabling the velocity output',
      },
      {
        name: 'enable_acceleration_port',
        data_type: 'bool',
        default_value: 'false',
        description: 'User selection for enabling the acceleration output',
      },
    ],
  },
  'acausal.translational.SpeedSource': {
    $schema: '../../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/acausal/translational/speed_source.json',
    base: {
      name: 'SpeedSource',
      namespace: 'acausal.translational',
      description: 'Speed source.',
      feature_level: 'dev',
    },
    modes: {
      time: 'agnostic',
    },
    ports: {
      inputs: {
        static: [
          {
            name: 'flange_a',
            variant: {
              variant_kind: 'acausal',
              acausal_domain: 'translational',
            },
          },
        ],
        conditional: [
          {
            name: 'v_ref',
            condition_bool_parameter: 'enable_speed_port',
            order: 1,
          },
        ],
      },
      outputs: {
        conditional: [
          {
            name: 'flange_b',
            condition_bool_parameter: 'enable_flange_b',
            order: 0,
            variant: {
              variant_kind: 'acausal',
              acausal_domain: 'translational',
            },
          },
        ],
      },
    },
    parameter_definitions: [
      {
        name: 'v_ref',
        data_type: 'float',
        default_value: '1.0',
        description: 'Constant speed in meters/second.',
      },
      {
        name: 'enable_speed_port',
        data_type: 'bool',
        default_value: 'false',
        description: 'User selection for enabling inport for speed value.',
      },
      {
        name: 'enable_flange_b',
        data_type: 'bool',
        default_value: 'true',
        description: 'User selection for enabling the flange_b port',
      },
    ],
  },
  'acausal.translational.Spring': {
    $schema: '../../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/acausal/translational/spring.json',
    base: {
      name: 'Spring',
      namespace: 'acausal.translational',
      description: 'Ideal linear 1D translational spring.',
    },
    modes: {
      time: 'agnostic',
    },
    ports: {
      inputs: {
        static: [
          {
            name: 'flange_a',
            variant: {
              variant_kind: 'acausal',
              acausal_domain: 'translational',
            },
          },
        ],
      },
      outputs: {
        static: [
          {
            name: 'flange_b',
            variant: {
              variant_kind: 'acausal',
              acausal_domain: 'translational',
            },
          },
        ],
      },
    },
    parameter_definitions: [
      {
        name: 'K',
        data_type: 'float',
        default_value: '1.0',
        description: 'Spring constant in N/m',
      },
      {
        name: 'initial_position_A',
        data_type: 'float',
        default_value: '0.0',
        description: 'Initial position of end A.',
      },
      {
        name: 'initial_position_A_fixed',
        data_type: 'bool',
        default_value: 'False',
        description: 'Whether initial position A condition is fixed or not.',
      },
      {
        name: 'initial_velocity_A',
        data_type: 'float',
        default_value: '0.0',
        description: 'Initial velocity of end A.',
      },
      {
        name: 'initial_velocity_A_fixed',
        data_type: 'bool',
        default_value: 'False',
        description: 'Whether initial velocity A condition is fixed or not.',
      },
      {
        name: 'initial_position_B',
        data_type: 'float',
        default_value: '0.0',
        description: 'Initial position of end B.',
      },
      {
        name: 'initial_position_B_fixed',
        data_type: 'bool',
        default_value: 'False',
        description: 'Whether initial position B condition is fixed or not.',
      },
      {
        name: 'initial_velocity_B',
        data_type: 'float',
        default_value: '0.0',
        description: 'Initial velocity of end B.',
      },
      {
        name: 'initial_velocity_B_fixed',
        data_type: 'bool',
        default_value: 'False',
        description: 'Whether initial velocity B condition is fixed or not.',
      },
    ],
  },
  'core.Conditional': {
    $schema: '../../block-class.schema.json',
    base: {
      name: 'Conditional',
      namespace: 'core',
      description: 'conditionally execute the model content inside.',
      extra_parameters: true,
      hidden: true,
      feature_level: 'dev',
    },
    modes: {
      time: 'any',
    },
    ports: {
      inputs: {
        static: [
          {
            name: 'enable',
            appearance: 'hollow',
          },
        ],
      },
      has_automatic_ports: true,
    },
    parameter_definitions: [
      {
        name: 'output_on_disable',
        data_type: 'string',
        default_value: 'hold',
        description:
          'how the outputs of the conditional block are computed when it is disabled',
        allowed_values: ['hold', 'reset to 0'],
      },
      {
        name: 'state_on_disable',
        data_type: 'string',
        default_value: 'hold',
        description:
          'how the states of the blocks inside the conditional block are computed when it is disabled',
        allowed_values: ['hold', 'reset'],
      },
    ],
  },
  'core.Abs': {
    $schema: '../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/base/maths/abs.json',
    base: {
      name: 'Abs',
      namespace: 'core',
      description: 'Outputs the absolute value of the inputs.',
      help_url:
        'https://docs.collimator.ai/using-model-editors/block-library/agnostic-blocks#absolute-value',
    },
    modes: {
      time: 'agnostic',
    },
    events: {
      count: 1,
    },
    ports: {
      inputs: {
        static: [
          {
            name: 'in_0',
          },
        ],
      },
      outputs: {
        static: [
          {
            name: 'out_0',
          },
        ],
      },
    },
  },
  'core.Adder': {
    $schema: '../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/base/maths/adder.json',
    base: {
      name: 'Adder',
      namespace: 'core',
      description: 'Adds and/or subtracts 2 or more inputs together.',
      development_notes:
        'the parameter operators must be used to configure the number of block inputs. this algorithm can be found in maths.py/Adder/compute_configuration',
      keywords: ['sum', 'subtract'],
      help_url:
        'https://docs.collimator.ai/using-model-editors/block-library/agnostic-blocks#adder',
    },
    modes: {
      time: 'agnostic',
    },
    ports: {
      inputs: {
        dynamic: {
          min_count: 2,
          max_count: -1,
          default_count: 2,
        },
      },
      outputs: {
        static: [
          {
            name: 'out_0',
          },
        ],
      },
    },
    parameter_definitions: [
      {
        name: 'operators',
        description:
          "A string containing the signs to apply to each input port, like '++' or '+-'. Must contain exactly as many symbols as input ports. For each operator, an input port named `in_<n>` will be automatically generated (where n is a number starting with 0).",
        description_gpt:
          "A list of operations to apply between inputs. Each operation must be either 'add' or 'sub'.",
        data_type: 'string',
        default_value: '++',
        hidden: true,
      },
    ],
  },
  'core.Arithmetic': {
    $schema: '../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/base/maths/adder.json',
    base: {
      name: 'Arithmetic',
      namespace: 'core',
      description:
        'Performs addition, subtraction, multiplication, and division on the input.',
      feature_level: 'dev',
      development_notes: '',
      keywords: ['sum', 'subtract', 'multiply', 'divide'],
      help_url: '',
    },
    modes: {
      time: 'agnostic',
    },
    ports: {
      inputs: {
        dynamic: {
          min_count: 2,
          max_count: -1,
          default_count: 2,
        },
      },
      outputs: {
        static: [
          {
            name: 'out_0',
          },
        ],
      },
    },
    parameter_definitions: [
      {
        name: 'operators',
        description:
          "A string containing the signs to apply to each input port, like '++' or '+-'. Must contain exactly as many symbols as input ports. For each operator, an input port named `in_<n>` will be automatically generated (where n is a number starting with 0).",
        data_type: 'string',
        default_value: '++',
      },
    ],
  },
  'core.BatteryCell': {
    $schema: '../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/base/discrete/battery_cell.json',
    base: {
      name: 'BatteryCell',
      namespace: 'core',
      description:
        'Single cell of a chemical battery like a Lithium-Ion, Lithium-Polymer, NiCad, NiMH, or Lead-Acid.',
      help_url:
        'https://docs.collimator.ai/using-model-editors/block-library/continuous-blocks#battery-cell',
      supports_iterator: false,
    },
    modes: {
      time: 'agnostic',
    },
    ports: {
      inputs: {
        static: [
          {
            name: 'current',
          },
        ],
      },
      outputs: {
        static: [
          {
            name: 'voltage',
          },
          {
            name: 'SOC',
          },
        ],
      },
    },
    parameter_definitions: [
      {
        name: 'E0',
        data_type: 'float',
        default_value: '3.366',
        description: 'Constant Voltage (V).',
      },
      {
        name: 'K',
        data_type: 'float',
        default_value: '0.0076',
        description: 'Polarization Constant (V/Ah).',
      },
      {
        name: 'Q',
        data_type: 'float',
        default_value: '2.3',
        description: 'cell capacity (Ah).',
      },
      {
        name: 'R',
        data_type: 'float',
        default_value: '0.01',
        description:
          'Internal Resistance (Ohms). Must be negative even though the parameter is positive, compiler expects the negative of the parameter value.',
      },
      {
        name: 'A',
        data_type: 'float',
        default_value: '0.2642',
        description: 'Exponential zone amplitude (V).',
      },
      {
        name: 'B',
        data_type: 'float',
        default_value: '26.5487',
        description:
          'Exponential zone time constant inverse (1/Ah). Must be negative even though the parameter is positive, compiler expects the negative of the parameter value. ',
      },
      {
        name: 'intial_SOC',
        data_type: 'float',
        default_value: '1.0',
        description: 'Initial value of the Depth of Discharge.',
      },
    ],
  },
  'core.BusCreator': {
    $schema: '../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/base/signal_routing/bus_creator.json',
    base: {
      name: 'BusCreator',
      namespace: 'core',
      description: 'Create a bus signal from all of the inputs.',
      feature_level: 'dev',
      supports_models: true,
      supports_submodels: true,
    },
    modes: {
      time: 'agnostic',
    },
    ports: {
      has_automatic_ports: true,
      outputs: {
        static: [
          {
            name: 'out_0',
          },
        ],
      },
    },
    parameter_definitions: [
      {
        name: 'bus_type',
        description: 'The bus type to create a bus for.',
        $comment:
          'Displays the name, but stores the project scope ID. Used in conjunction with `project_uuid` to uniquely identify the bus type.',
        data_type: 'string',
        default_value: '',
      },
      {
        name: 'project_uuid',
        description: 'The UUID of the project that the bus type belongs to.',
        $comment: 'Implicitly set by `bus_type` selection.',
        data_type: 'string',
        default_value: '',
        hidden: true,
        read_only: true,
      },
    ],
  },
  'core.BusSelector': {
    $schema: '../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/base/signal_routing/bus_selector.json',
    base: {
      name: 'BusSelector',
      namespace: 'core',
      description:
        'Extract the selected signals from the bus signal as outputs of this block.',
      feature_level: 'dev',
    },
    modes: {
      time: 'agnostic',
    },
    ports: {
      inputs: {
        static: [
          {
            name: 'in_0',
          },
        ],
      },
      has_automatic_ports: true,
    },
    parameter_definitions: [
      {
        name: 'bus_type',
        description: 'The bus type to extract signals from.',
        $comment:
          'Displays the name, but stores the project scope ID. Used in conjunction with `project_uuid` to uniquely identify the bus type.',
        data_type: 'string',
        default_value: '',
      },
      {
        name: 'project_uuid',
        description: 'The UUID of the project that the bus type belongs to.',
        $comment: 'Implicitly set by `bus_type` selection.',
        data_type: 'string',
        default_value: '',
        hidden: true,
        read_only: true,
      },
    ],
  },
  'core.Chirp': {
    $schema: '../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/base/sources/chirp.json',
    base: {
      name: 'Chirp',
      namespace: 'core',
      description:
        'The Chirp block outputs a swept-frequency cosine (chirp) signal with unity amplitude and continuous phase. The function returns cos(f(t) * t), where f(t) is defined as: f(t) = f0 + (f1 - f0) * t / t1.',
      help_url:
        'https://docs.collimator.ai/using-model-editors/block-library/agnostic-blocks#chirp',
    },
    modes: {
      time: 'any',
    },
    ports: {
      outputs: {
        static: [
          {
            name: 'out_0',
          },
        ],
      },
    },
    parameter_definitions: [
      {
        name: 'f0',
        description: 'Starting frequency.',
        data_type: 'float',
        default_value: '0.0',
      },
      {
        name: 'f1',
        description: 'Stop frequency.',
        data_type: 'float',
        default_value: '10.0',
      },
      {
        name: 'stop_time',
        description: 'Time it reaches f1.',
        data_type: 'float',
        default_value: '10.0',
      },
      {
        name: 'phi',
        description: 'Phase offset.',
        data_type: 'float',
        default_value: '0.0',
      },
    ],
  },
  'core.Clock': {
    $schema: '../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/base/sources/clock.json',
    base: {
      name: 'Clock',
      namespace: 'core',
      description: 'Generates a signal that is equal to simulation time.',
      help_url:
        'https://docs.collimator.ai/using-model-editors/block-library/agnostic-blocks#clock',
    },
    modes: {
      time: 'any',
    },
    ports: {
      outputs: {
        static: [
          {
            name: 'out_0',
          },
        ],
      },
    },
  },
  'core.Comparator': {
    $schema: '../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/base/logic_and_bit_operations/comparator.json',
    base: {
      name: 'Comparator',
      namespace: 'core',
      description:
        'Compares two inputs using a given comparison operator and returns true or false.',
      keywords: ['greater', 'less', 'equal'],
      help_url:
        'https://docs.collimator.ai/using-model-editors/block-library/agnostic-blocks#comparator',
    },
    modes: {
      time: 'agnostic',
    },
    events: {
      count: 1,
    },
    ports: {
      inputs: {
        static: [
          {
            name: 'in_0',
          },
          {
            name: 'in_1',
          },
        ],
      },
      outputs: {
        static: [
          {
            name: 'out_0',
          },
        ],
      },
    },
    parameter_definitions: [
      {
        name: 'operator',
        description:
          'Indicates the comparison operator to use: input[0] <op> input[1].',
        data_type: 'string',
        default_value: '>',
        allowed_values: ['==', '!=', '>=', '<=', '>', '<'],
      },
      {
        name: 'atol',
        description:
          "Absolute tolerance for math.isclose ('==' and '!=' operators only)",
        data_type: 'float',
        default_value: '1e-6',
      },
      {
        name: 'rtol',
        description:
          "Relative tolerance for math.isclose ('==' and '!=' operators only)",
        data_type: 'float',
        default_value: '1e-9',
      },
    ],
  },
  'core.Constant': {
    $schema: '../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/base/sources/constant.json',
    base: {
      name: 'Constant',
      namespace: 'core',
      description: 'Returns a given constant value.',
      help_url:
        'https://docs.collimator.ai/using-model-editors/block-library/agnostic-blocks#constant',
    },
    modes: {
      time: 'discrete',
    },
    ports: {
      outputs: {
        static: [
          {
            name: 'out_0',
          },
        ],
      },
    },
    parameter_definitions: [
      {
        name: 'value',
        description: 'The value to return',
        data_type: 'float',
        default_value: '0.0',
      },
    ],
  },
  'core.CoordinateRotation': {
    $schema: '../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/base/discrete/coordinate_rotation.json',
    base: {
      name: 'CoordinateRotation',
      namespace: 'core',
      description:
        'computes rotation between coordinate systems, e.g. quaternion, DCM, etc.',
      keywords: [
        'quaternion',
        'dcm',
        'rotation',
        'angles',
        'euler',
        'angle',
        'conversion',
        'coordinate',
      ],
      development_notes:
        'when quaternion selected: expose either w,x,y,z inports or params. when roll_pitch_yaw selected, expose either roll,pitch,yaw inports or params. when DCM selected, expose DCM inport or param. ',
    },
    modes: {
      time: 'agnostic',
    },
    ports: {
      inputs: {
        static: [
          {
            name: 'vector_in',
          },
        ],
        conditional: [
          {
            name: 'rotation',
            condition_bool_parameter: 'enable_external_rotation_definition',
            order: 1,
          },
        ],
      },
      outputs: {
        static: [
          {
            name: 'vector_out',
          },
        ],
      },
    },
    parameter_definitions: [
      {
        name: 'rotation_type',
        data_type: 'string',
        default_value: 'quaternion',
        description: 'Select the rotation type desired.',
        allowed_values: ['quaternion', 'roll_pitch_yaw', 'DCM'],
        display_full_width: true,
      },
      {
        name: 'enable_external_rotation_definition',
        data_type: 'bool',
        default_value: 'false',
        description:
          'Source for the rotation parameters. True=from inport(s), False=from the block parameters.',
      },
      {
        name: 'inverse',
        data_type: 'bool',
        default_value: 'false',
        description: 'Apply the inverse rotation transformation.',
      },
      {
        name: 'quaternion',
        data_type: 'any',
        default_value: '[1.0, 0.0, 0.0, 0.0]',
        description: 'Quaternion representation of rotation.',
      },
      {
        name: 'roll_pitch_yaw',
        data_type: 'any',
        default_value: '[0.0, 0.0, 0.0]',
        description: 'Roll, pitch, and yaw angles (theta, phi, psi).',
      },
      {
        name: 'direction_cosine_matrix',
        data_type: 'any',
        default_value: '[[1.0,0.0,0.0],\n[0.0,1.0,0.0],\n[0.0,0.0,1.0]]\n',
        description: 'DCM 3x3 matrix',
      },
    ],
  },
  'core.CoordinateRotationConversion': {
    $schema: '../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/base/discrete/coordinate_rotation_conversion.json',
    base: {
      name: 'CoordinateRotationConversion',
      namespace: 'core',
      description:
        'computes conversion between coordinate rotations, e.g. quaternion to DCM, etc.',
      keywords: [
        'quaternion',
        'dcm',
        'rotation',
        'angles',
        'euler',
        'angle',
        'conversion',
        'coordinate',
      ],
      development_notes:
        'when quaternion_to_X selected: expose w,x,y,z inports. when rotation_angle_to_X selected, alpha,beta,gamma, ports. when DCM_to slected, DCM inport. and vice versa for outports.',
    },
    modes: {
      time: 'agnostic',
    },
    ports: {
      inputs: {
        static: [
          {
            name: 'rotation_in',
          },
        ],
      },
      outputs: {
        static: [
          {
            name: 'rotation_out',
          },
        ],
      },
    },
    parameter_definitions: [
      {
        name: 'conversion_type',
        data_type: 'string',
        default_value: 'quaternion_to_DCM',
        description: 'Select the rotation conversion type desired.',
        allowed_values: [
          'quaternion_to_DCM',
          'quaternion_to_RPY',
          'DCM_to_quaternion',
          'DCM_to_RPY',
          'RPY_to_quaternion',
          'RPY_to_DCM',
        ],
        display_full_width: true,
      },
    ],
  },
  'core.CosineWave': {
    $schema: '../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/base/sources/cosine_wave.json',
    base: {
      name: 'CosineWave',
      namespace: 'core',
      description:
        'Generates a cosine wave based on the simulation time.\n\ny(t) = amplitude * cos(t*frequency + phase) + bias.',
      help_url:
        'https://docs.collimator.ai/using-model-editors/block-library/agnostic-blocks#cosine-wave',
    },
    modes: {
      time: 'any',
    },
    ports: {
      outputs: {
        static: [
          {
            name: 'out_0',
          },
        ],
      },
    },
    parameter_definitions: [
      {
        name: 'frequency',
        description: 'Frequency in rad/s.',
        data_type: 'float',
        default_value: '1.0',
      },
      {
        name: 'amplitude',
        description: 'Amplitude of the signal.',
        data_type: 'float',
        default_value: '1.0',
      },
      {
        name: 'phase',
        description: 'Phase shift in rad.',
        data_type: 'float',
        default_value: '0.0',
      },
      {
        name: 'bias',
        description: 'Constant bias added to the signal.',
        data_type: 'float',
        default_value: '0.0',
      },
    ],
  },
  'core.CrossProduct': {
    $schema: '../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/base/maths/cross_product.json',
    base: {
      name: 'CrossProduct',
      namespace: 'core',
      description: 'This block outputs the cross product of the input vectors.',
      keywords: ['multiply', 'product'],
      help_url:
        'https://docs.collimator.ai/using-model-editors/block-library/agnostic-blocks#cross-product',
    },
    modes: {
      time: 'agnostic',
    },
    ports: {
      inputs: {
        static: [
          {
            name: 'a',
          },
          {
            name: 'b',
          },
        ],
      },
      outputs: {
        static: [
          {
            name: 's',
          },
        ],
      },
    },
  },
  'core.CustomLeafSystem': {
    $schema: '../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/base/user_defined/custom_leaf_system.json',
    base: {
      name: 'CustomLeafSystem',
      namespace: 'core',
      description: 'Custom pycollimator LeafSystem.',
      keywords: ['leaf', 'system', 'pycollimator', 'custom'],
      extra_parameters: true,
      help_url: '',
      feature_level: 'dev',
    },
    modes: {
      time: 'any',
    },
    ports: {
      inputs: {
        dynamic: {
          min_count: 0,
          max_count: -1,
          default_count: 0,
        },
      },
      outputs: {
        dynamic: {
          min_count: 0,
          max_count: -1,
          default_count: 0,
        },
      },
    },
    parameter_definitions: [
      {
        name: 'file_path',
        description:
          'Path to the custom LeafSystem class. Must be a path relative to the project root.',
        data_type: 'file',
        file_formats: ['*.py'],
        default_value: '',
      },
      {
        name: 'source_code',
        description: 'Implementation of the custom LeafSystem.',
        data_type: 'string',
        default_value:
          'from collimator import LeafSystem, parameters, ports\nfrom collimator.logging import logger\n\n\n@ports(inputs=["in_0"], outputs=1)\n@parameters(dynamic=["dyn_param"], static=["static_param"])\nclass MySystem(LeafSystem):\n    \n    def __init__(self, dyn_param, static_param, *args, **kwargs):\n        super().__init__(*args, **kwargs)\n\n    def initialize(self, dyn_param, static_param):\n        \n        def _callback(time, state, u, dyn_param):\n            logger.info("hello world")\n            return u\n\n        self.configure_output_port(\n            0,\n            _callback,\n            requires_inputs=True,\n        )',
        hidden: true,
      },
      {
        name: 'class_name',
        description:
          'Name of the custom LeafSystem class, will be determined automatically during compilation if implementation is inlined.',
        data_type: 'string',
        default_value: '',
      },
      {
        name: 'inline',
        description:
          'Whether the implementation of the custom LeafSystem is inlined in the parameter source_code.',
        data_type: 'bool',
        default_value: 'false',
      },
    ],
  },
  'core.DataSource': {
    $schema: '../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/base/sources/data_source.json',
    base: {
      name: 'DataSource',
      namespace: 'core',
      description: 'Specifies a data file that can be used as a source.',
      help_url:
        'https://docs.collimator.ai/using-model-editors/block-library/agnostic-blocks#data-source',
    },
    modes: {
      time: 'any',
    },
    ports: {
      outputs: {
        static: [
          {
            name: 'out_0',
          },
        ],
      },
    },
    parameter_definitions: [
      {
        name: 'data_integration_id',
        description: 'ID of the integration to be used.',
        data_type: 'string',
        default_value: '',
        feature_level: 'disabled',
      },
      {
        name: 'file_name',
        description: 'Name of the file to use as a data source.',
        data_type: 'string',
        default_value: '',
      },
      {
        name: 'header_as_first_row',
        description: 'Check this box if the file has a header.',
        data_type: 'bool',
        default_value: 'false',
      },
      {
        name: 'time_samples_as_column',
        description:
          'Check this box to use a column in the data file as the source of time stamps for data samples(rows).',
        data_type: 'bool',
        default_value: 'true',
      },
      {
        name: 'time_column',
        description:
          'Index of the time stamps column. Default is 0, which is the first column.',
        data_type: 'string',
        default_value: '0',
      },
      {
        name: 'sampling_interval',
        description:
          'Time interval between two data samples(rows) of the data file whe there is no time column. Must be > 0.',
        data_type: 'float',
        default_value: '0.1',
      },
      {
        name: 'data_columns',
        description:
          'Index or String to select the data column for the output. Default is 1, which is the first column after time column.',
        data_type: 'string',
        default_value: '1',
      },
      {
        name: 'extrapolation',
        data_type: 'string',
        default_value: 'hold',
        description:
          'Method used to compute output after end-of-file has been reached.',
        allowed_values: ['hold', 'zero'],
      },
      {
        name: 'interpolation',
        data_type: 'string',
        default_value: 'linear',
        description:
          'Method used to compute output at times between time stamps.',
        allowed_values: ['zero_order_hold', 'linear'],
      },
    ],
  },
  'core.DeadZone': {
    $schema: '../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/base/discontinuities/dead_zone.json',
    base: {
      name: 'DeadZone',
      namespace: 'core',
      description:
        'Sets outputs to zero within a given range centered on a given value.',
      help_url:
        'https://docs.collimator.ai/using-model-editors/block-library/agnostic-blocks#dead-zone',
    },
    modes: {
      time: 'agnostic',
    },
    events: {
      count: 2,
    },
    ports: {
      inputs: {
        static: [
          {
            name: 'in_0',
          },
        ],
      },
      outputs: {
        static: [
          {
            name: 'out_0',
          },
        ],
      },
    },
    parameter_definitions: [
      {
        name: 'half_range',
        description:
          'the full range of the dead zone is 2*half_range. the dead zone will extend half_range in each direction from zero',
        data_type: 'float',
        default_value: '0.5',
      },
    ],
  },
  'core.Delay': {
    $schema: '../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/base/discrete/delay.json',
    base: {
      name: 'Delay',
      namespace: 'core',
      description: 'Delays the input to the output by a fixed amount of time.',
      help_url:
        'https://docs.collimator.ai/using-model-editors/block-library/continuous-blocks#delay',
      supports_iterator: false,
      development_notes:
        'not supported in wildcat at the moment of writing this',
      feature_level: 'dev',
    },
    modes: {
      time: 'agnostic',
    },
    ports: {
      inputs: {
        static: [
          {
            name: 'in_0',
          },
        ],
      },
      outputs: {
        static: [
          {
            name: 'out_0',
          },
        ],
      },
    },
    parameter_definitions: [
      {
        name: 'delay',
        data_type: 'float',
        default_value: '1.0',
        description: 'Amount of time to delay.',
      },
      {
        name: 'initial_output',
        data_type: 'float',
        default_value: '0.0',
        description: 'Value of block output until delay time has passed.',
      },
    ],
  },
  'core.Demux': {
    $schema: '../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/base/signal_routing/demux.json',
    base: {
      name: 'Demux',
      namespace: 'core',
      description:
        'Split a vector signal into its component, and pass each component as an output from this block.',
      development_notes:
        'FIXME ports should be automatic based on the connected mux',
      help_url:
        'https://docs.collimator.ai/using-model-editors/block-library/agnostic-blocks#demux',
    },
    modes: {
      time: 'agnostic',
    },
    ports: {
      inputs: {
        static: [
          {
            name: 'in_0',
          },
        ],
      },
      outputs: {
        dynamic: {
          min_count: 1,
          max_count: -1,
          default_count: 2,
        },
      },
      has_automatic_ports: true,
    },
  },
  'core.Derivative': {
    $schema: '../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/base/discrete/derivative.json',
    base: {
      name: 'Derivative',
      namespace: 'core',
      description: 'Computes the derivative of the input signal.',
      help_url:
        'https://docs.collimator.ai/using-model-editors/block-library/agnostic-blocks#derivative',
      supports_iterator: false,
    },
    modes: {
      time: 'agnostic',
    },
    ports: {
      inputs: {
        static: [
          {
            name: 'in_0',
          },
        ],
      },
      outputs: {
        static: [
          {
            name: 'out_0',
          },
        ],
      },
    },
    parameter_definitions: [
      {
        name: 'filter_coefficient',
        data_type: 'int',
        default_value: '100',
        description:
          'The filter coefficient to use for the derivative calculation.',
      },
    ],
  },
  'core.DerivativeDiscrete': {
    $schema: '../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/base/discrete/derivative_discrete.json',
    base: {
      name: 'DerivativeDiscrete',
      namespace: 'core',
      description:
        'Computes the first-order approximation of the first derivative of the input signal.',
      help_url:
        'https://docs.collimator.ai/using-model-editors/block-library/discrete-blocks#derivative-discrete',
      supports_iterator: false,
    },
    modes: {},
    ports: {
      inputs: {
        static: [
          {
            name: 'in_0',
          },
        ],
      },
      outputs: {
        static: [
          {
            name: 'out_0',
          },
        ],
      },
    },
    parameter_definitions: [
      {
        name: 'filter_coefficient',
        data_type: 'float',
        default_value: '1.0',
        description:
          'The filter coefficient to use for the derivative calculation.',
      },
      {
        name: 'filter_type',
        data_type: 'string',
        default_value: 'none',
        description:
          'Type of filter used to approximate the derivative. By default a simple backward difference approximation.',
        allowed_values: ['none', 'forward', 'backward', 'bilinear'],
      },
    ],
  },
  'core.DiscreteClock': {
    $schema: '../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/base/sources/discrete_clock.json',
    base: {
      name: 'DiscreteClock',
      namespace: 'core',
      description: 'Generates a discrete-time signal sampling simulation time.',
      help_url:
        'https://docs.collimator.ai/using-model-editors/block-library/discrete-blocks#clock-discrete',
    },
    modes: {},
    ports: {
      outputs: {
        static: [
          {
            name: 'out_0',
          },
        ],
      },
    },
  },
  'core.DiscreteInitializer': {
    $schema: '../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/base/discrete/discrete_initializer.json',
    base: {
      name: 'DiscreteInitializer',
      namespace: 'core',
      description:
        'First output is true, all following are false, or vice versa, first output is false, and all following are true.',
      help_url:
        'https://docs.collimator.ai/using-model-editors/block-library/discrete-blocks#discrete-initializer',
      supports_iterator: false,
    },
    modes: {
      time: 'discrete',
    },
    ports: {
      outputs: {
        static: [
          {
            name: 'out_0',
          },
        ],
      },
    },
    parameter_definitions: [
      {
        name: 'initial_state',
        data_type: 'bool',
        default_value: 'true',
        description: 'Initial output value.',
        display_variant: 'list',
        allowed_values: ['true', 'false'],
      },
    ],
  },
  'core.DotProduct': {
    $schema: '../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/base/matrix_operations/dot_product.json',
    base: {
      name: 'DotProduct',
      namespace: 'core',
      description: 'This block outputs the dot product of 2 vectors.',
      help_url:
        'https://docs.collimator.ai/using-model-editors/block-library/agnostic-blocks#dot-product',
    },
    modes: {
      time: 'agnostic',
    },
    ports: {
      inputs: {
        static: [
          {
            name: 'in_0',
          },
          {
            name: 'in_1',
          },
        ],
      },
      outputs: {
        static: [
          {
            name: 'out_0',
          },
        ],
      },
    },
  },
  'core.DriveCycle': {
    $schema: '../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/base/sources/drive_cycle.json',
    base: {
      name: 'DriveCycle',
      namespace: 'core',
      description:
        'Outputs the speed signal from standard automotive drive cycles.',
      feature_level: 'dev',
    },
    modes: {
      time: 'any',
    },
    ports: {
      outputs: {
        static: [
          {
            name: 'speed_kph',
          },
        ],
        conditional: [
          {
            name: 'upper_limit_kph',
            order: 1,
          },
          {
            name: 'lower_limit_kph',
            order: 2,
          },
        ],
      },
    },
    parameter_definitions: [
      {
        name: 'cycle',
        description: 'Name of the drive cycle.',
        data_type: 'string',
        default_value: 'ECE',
        allowed_values: [
          'CSC',
          'CWTVC',
          'ECE',
          'EUDC',
          'FTP',
          'HDUDDS',
          'HWYFET',
          'IM240',
          'JPN10',
          'JPN15',
          'LA92',
          'NYCC',
          'SC03',
          'US06',
          'WHVC',
        ],
      },
      {
        name: 'output_limits',
        description:
          'Check this box to also output the upper and lower limits.',
        data_type: 'bool',
        default_value: 'false',
      },
    ],
  },
  'core.EdgeDetection': {
    $schema: '../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/base/logic_and_bit_operations/edge_detection.json',
    base: {
      name: 'EdgeDetection',
      namespace: 'core',
      description:
        'Outputs True when it detects the signal transition conditions specified by user (e.g. rising edge).',
      help_url:
        'https://docs.collimator.ai/using-model-editors/block-library/agnostic-blocks#edge-detection',
      supports_iterator: false,
    },
    modes: {
      time: 'agnostic',
    },
    events: {
      count: 1,
    },
    ports: {
      inputs: {
        static: [
          {
            name: 'in_0',
          },
        ],
      },
      outputs: {
        static: [
          {
            name: 'out_0',
          },
        ],
      },
    },
    parameter_definitions: [
      {
        name: 'edge_detection',
        description: 'Defines the type of edge that is detected.',
        data_type: 'string',
        default_value: 'rising',
        allowed_values: ['rising', 'falling', 'either'],
      },
      {
        name: 'initial_state',
        description:
          "An edge detection detects a change in a signal, so it needs an 'old value' to compare against the 'new value', this is the initial value of 'old value'.",
        data_type: 'bool',
        default_value: 'false',
        display_variant: 'list',
        allowed_values: ['true', 'false'],
      },
    ],
  },
  'core.Exponent': {
    $schema: '../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/base/maths/exponent.json',
    base: {
      name: 'Exponent',
      namespace: 'core',
      description: 'This block outputs exp(inputs) or 2^inputs.',
      help_url:
        'https://docs.collimator.ai/using-model-editors/block-library/agnostic-blocks#exponent',
    },
    modes: {
      time: 'agnostic',
    },
    ports: {
      inputs: {
        static: [
          {
            name: 'in_0',
          },
        ],
      },
      outputs: {
        static: [
          {
            name: 'out_0',
          },
        ],
      },
    },
    parameter_definitions: [
      {
        name: 'base',
        data_type: 'string',
        default_value: 'exp',
        description: 'Base of the exponential.',
        allowed_values: ['exp', '2'],
      },
    ],
  },
  'core.ExtendedKalmanFilter': {
    $schema: '../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/base/discrete/extended_kalman_filter.json',
    base: {
      name: 'ExtendedKalmanFilter',
      namespace: 'core',
      description: 'Extended Kalman Filter for a continuous-time plant',
      help_url:
        'https://docs.collimator.ai/using-model-editors/block-library/discrete-blocks#extended-kalman-filter',
      supports_iterator: false,
      keywords: ['kf'],
    },
    modes: {
      time: 'discrete',
    },
    ports: {
      inputs: {
        static: [
          {
            name: 'u',
          },
          {
            name: 'y',
          },
        ],
      },
      outputs: {
        static: [
          {
            name: 'x_hat',
          },
        ],
      },
    },
    parameter_definitions: [
      {
        name: 'plant_submodel_uuid',
        data_type: 'reference_submodel',
        description:
          'Continuous-time plant to linearize and discretize for Extended Kalman Filter creation',
        default_value: '',
        display_name: 'Plant Submodel',
        optional: true,
      },
      {
        name: 'discretization_method',
        description: 'Discretization method for the continuous-time plant',
        data_type: 'string',
        default_value: 'euler',
        optional: true,
        allowed_values: ['euler', 'zoh'],
      },
      {
        name: 'dt',
        data_type: 'float',
        description:
          'Sampling rate for discretization of the continuous-time plant',
        optional: true,
        default_value: '',
      },
      {
        name: 'discretized_noise',
        display_name: 'Provided noise statistics are discretized?',
        description:
          'Check this box if the G, Q, and R matrices are already discretized',
        data_type: 'bool',
        default_value: 'false',
      },
      {
        name: 'G',
        data_type: 'any',
        default_value: '',
        optional: true,
        description: 'Process noise matrix G for the plant',
      },
      {
        name: 'Q',
        data_type: 'any',
        default_value: '',
        optional: true,
        description: 'Process noise covariance matrix Q for the plant',
      },
      {
        name: 'R',
        data_type: 'any',
        default_value: '',
        optional: true,
        description: 'Measurement noise covariance matrix R for the plant',
      },
      {
        name: 'x_hat_0',
        data_type: 'float',
        default_value: '',
        optional: true,
        description: 'Initial value for the state vector estimate',
      },
      {
        name: 'P_hat_0',
        data_type: 'float',
        default_value: '',
        optional: true,
        description:
          'Initial value for covariance of the state vector estimate',
      },
    ],
  },
  'core.FilterDiscrete': {
    $schema: '../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/base/discrete/filter_discrete.json',
    base: {
      name: 'FilterDiscrete',
      namespace: 'core',
      description: 'A discrete implementation of either an FIR or IIR filter.',
      help_url:
        'https://docs.collimator.ai/using-model-editors/block-library/discrete-blocks#fir-iir-filter',
      keywords: ['filter', 'fir', 'iir'],
      supports_iterator: false,
    },
    modes: {},
    ports: {
      inputs: {
        static: [
          {
            name: 'in_0',
          },
        ],
      },
      outputs: {
        static: [
          {
            name: 'out_0',
          },
        ],
      },
    },
    parameter_definitions: [
      {
        name: 'filter_type',
        data_type: 'string',
        default_value: 'FIR',
        description: 'the type of filter to implement. FIR or IIR.',
        allowed_values: ['FIR', 'IIR'],
      },
      {
        name: 'b_coefficients',
        data_type: 'any',
        default_value: '[0.5, 0.5]',
        description: 'Used for FIR filter and feed forward leg of IIR filter.',
      },
      {
        name: 'a_coefficients',
        data_type: 'any',
        default_value: '[0.5, 0.5]',
        description:
          'Used for feedback leg of IIR filter. Not used for FIR filter.',
      },
    ],
  },
  'core.From': {
    $schema: '../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/base/sources/from.json',
    base: {
      name: 'From',
      namespace: 'core',
      description: "Connects to a signal from corresponding 'Goto' block.",
      feature_level: 'dev',
    },
    modes: {
      time: 'any',
    },
    ports: {
      outputs: {
        static: [
          {
            name: 'in_0',
          },
        ],
      },
    },
    parameter_definitions: [
      {
        name: 'signal',
        data_type: 'string',
        default_value: 'A',
        description: 'Name of the Goto signal to connect to.',
      },
    ],
  },
  'core.Gain': {
    $schema: '../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/base/maths/gain.json',
    base: {
      name: 'Gain',
      namespace: 'core',
      description:
        'This block outputs its input value multiplied by a constant scalar.',
      keywords: ['multiply', 'product'],
      help_url:
        'https://docs.collimator.ai/using-model-editors/block-library/agnostic-blocks#gain',
    },
    modes: {
      time: 'agnostic',
    },
    ports: {
      inputs: {
        static: [
          {
            name: 'in_0',
          },
        ],
      },
      outputs: {
        static: [
          {
            name: 'out_0',
          },
        ],
      },
    },
    parameter_definitions: [
      {
        name: 'gain',
        data_type: 'float',
        default_value: '1.0',
        description:
          'Scalar multiplying factor. It must be a correct python expression.',
      },
    ],
  },
  'core.Generic': {
    $schema: '../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/base/user_defined/generic.json',
    base: {
      name: 'Generic',
      namespace: 'core',
      description: 'Generic block.',
      feature_level: 'dev',
    },
    modes: {
      time: 'any',
    },
    ports: {
      inputs: {
        dynamic: {
          min_count: 0,
          max_count: -1,
          default_count: 1,
        },
      },
      outputs: {
        dynamic: {
          min_count: 0,
          max_count: -1,
          default_count: 1,
        },
      },
    },
    parameter_definitions: [
      {
        name: 'a',
        data_type: 'string',
        default_value: '',
        description: 'Block specific parameter.',
      },
      {
        name: 'b',
        data_type: 'string',
        default_value: '',
        description: 'Block specific parameter.',
      },
      {
        name: 'c',
        data_type: 'string',
        default_value: '',
        description: 'Block specific parameter.',
      },
      {
        name: 'd',
        data_type: 'string',
        default_value: '',
        description: 'Block specific parameter.',
      },
      {
        name: 'e',
        data_type: 'string',
        default_value: '',
        description: 'Block specific parameter.',
      },
      {
        name: 'f',
        data_type: 'string',
        default_value: '',
        description: 'Block specific parameter.',
      },
      {
        name: 'g',
        data_type: 'string',
        default_value: '',
        description: 'Block specific parameter.',
      },
      {
        name: 'h',
        data_type: 'string',
        default_value: '',
        description: 'Block specific parameter.',
      },
    ],
  },
  'core.Goto': {
    $schema: '../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/base/sources/goto.json',
    base: {
      name: 'Goto',
      namespace: 'core',
      description:
        "Creates a 'signal' which can be connected to by one or more 'From' blocks.",
      feature_level: 'dev',
    },
    modes: {
      time: 'any',
    },
    ports: {
      inputs: {
        static: [
          {
            name: 'out_0',
          },
        ],
      },
    },
    parameter_definitions: [
      {
        name: 'signal',
        data_type: 'string',
        default_value: 'A',
        description: "Name of the signal that 'From' will connect to.",
      },
    ],
  },
  'core.Group': {
    $schema: '../../block-class.schema.json',
    base: {
      name: 'Group',
      namespace: 'core',
      description: 'This is a stub to allow the group to render',
      extra_parameters: true,
      hidden: true,
    },
    modes: {
      time: 'any',
    },
    ports: {
      has_automatic_ports: true,
    },
  },
  'core.IfThenElse': {
    $schema: '../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/base/signal_routing/if_then_else.json',
    base: {
      name: 'IfThenElse',
      namespace: 'core',
      description:
        'When If input is true, passes The input to output, otherwise passes Else input to output.',
      keywords: ['switch'],
      help_url:
        'https://docs.collimator.ai/using-model-editors/block-library/agnostic-blocks#if-then-else',
    },
    modes: {
      time: 'agnostic',
    },
    ports: {
      inputs: {
        static: [
          {
            name: 'test',
          },
          {
            name: 'if_true',
          },
          {
            name: 'if_false',
          },
        ],
      },
      outputs: {
        static: [
          {
            name: 'out_0',
          },
        ],
      },
    },
  },
  'core.ImageSegmentation': {
    $schema: '../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/core/image_segmentation.json',
    base: {
      name: 'ImageSegmentation',
      namespace: 'core',
      description:
        'Panoptic segmentation. Model was trained on COCO 2017 panoptic. Output is a python dictionary of "segmentations" numpy array with each position set to the ID of the object detected and "annotations" list of python dictionary containing the "label", "score" and "id" for each object detected.',
      feature_level: 'dev',
    },
    modes: {
      time: 'discrete',
    },
    ports: {
      inputs: {
        static: [
          {
            name: 'image',
          },
        ],
      },
      outputs: {
        static: [
          {
            name: 'segmentations',
          },
        ],
      },
    },
    parameter_definitions: [
      {
        name: 'model',
        data_type: 'string',
        default_value: 'detr-resnet-50-panoptic',
        allowed_values: ['detr-resnet-50-panoptic'],
        description: 'Image segmentation model.',
      },
    ],
  },
  'core.ImageSource': {
    $schema: '../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/base/sources/image_source.json',
    base: {
      name: 'ImageSource',
      namespace: 'core',
      description: 'Loads an image (png, jpeg) as a set of matrices.',
      development_notes:
        'We could decide to return a MxNx3 tensor, but this does not seem to be supported yet by cmlc.',
      help_url:
        'https://docs.collimator.ai/using-model-editors/block-library/agnostic-blocks#imagesource',
      feature_level: 'dev',
    },
    modes: {
      time: 'discrete',
    },
    ports: {
      outputs: {
        static: [
          {
            name: 'image',
          },
        ],
      },
    },
    parameter_definitions: [
      {
        name: 'file_name',
        description: 'Name of the image file to load.',
        data_type: 'string',
        default_value: '',
      },
      {
        name: 'normalize',
        description:
          'Controls the range of pixel values. If true: [0,1], else: [0,255].',
        data_type: 'bool',
        default_value: 'false',
      },
    ],
  },
  'core.Inport': {
    $schema: '../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/base/ports_and_submodels/inport.json',
    base: {
      name: 'Inport',
      namespace: 'core',
      description: 'This block represents the inport of a submodel.',
      supports_models: false,
      supports_submodels: true,
    },
    modes: {
      time: 'agnostic',
    },
    ports: {
      outputs: {
        static: [
          {
            name: 'out_0',
          },
        ],
      },
    },
    parameter_definitions: [
      {
        name: 'port_id',
        data_type: 'int',
        read_only: true,
        hidden: true,
        description:
          'The index / id matching with the actual inport of the submodel block that houses this inport block',
        default_value: '0',
      },
      {
        name: 'description',
        data_type: 'string',
        description: 'Description of the port',
        default_value: '',
      },
      {
        name: 'default_value',
        data_type: 'string',
        description: 'Default value of the data going into the port',
        default_value: '',
      },
    ],
  },
  'core.Integrator': {
    $schema: '../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/base/continuous/integrator.json',
    base: {
      name: 'Integrator',
      namespace: 'core',
      description: 'Integrates a continuous function.',
      help_url:
        'https://docs.collimator.ai/using-model-editors/block-library/continuous-blocks#integrator',
      supports_iterator: false,
    },
    modes: {
      time: 'agnostic',
    },
    ports: {
      inputs: {
        static: [
          {
            name: 'in_0',
          },
        ],
        conditional: [
          {
            name: 'reset',
            order: 1,
            appearance: 'hollow',
          },
          {
            name: 'reset_value',
            order: 2,
          },
          {
            name: 'hold',
            order: 3,
          },
        ],
      },
      outputs: {
        static: [
          {
            name: 'out_0',
          },
        ],
      },
    },
    parameter_definitions: [
      {
        name: 'initial_states',
        data_type: 'float',
        default_value: '0.0',
        description: 'initial value of continuous integrator state',
      },
      {
        name: 'enable_reset',
        data_type: 'bool',
        default_value: 'false',
        description: 'enable the reset function',
      },
      {
        name: 'enable_external_reset',
        data_type: 'bool',
        default_value: 'true',
        description:
          'source for the value used to reset the integrator state: True=from inport, False=from the initial_state parameter',
      },
      {
        name: 'enable_hold',
        data_type: 'bool',
        default_value: 'false',
        description: 'enable the hold function',
      },
      {
        name: 'enable_limits',
        data_type: 'bool',
        default_value: 'false',
        description: 'enable the limit function',
      },
      {
        name: 'upper_limit',
        description: 'upper limit to impose on the output',
        data_type: 'float',
        default_value: '1.0',
      },
      {
        name: 'lower_limit',
        description: 'lower limit to impose on the output',
        data_type: 'float',
        default_value: '-1.0',
      },
    ],
  },
  'core.IntegratorDiscrete': {
    $schema: '../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/base/discrete/integrator_discrete.json',
    base: {
      name: 'IntegratorDiscrete',
      namespace: 'core',
      description: 'Integrates a function.',
      help_url:
        'https://docs.collimator.ai/using-model-editors/block-library/discrete-blocks#integrator-discrete',
      supports_iterator: false,
    },
    modes: {
      time: 'discrete',
    },
    ports: {
      inputs: {
        static: [
          {
            name: 'in_0',
          },
        ],
        conditional: [
          {
            name: 'reset',
            order: 1,
            appearance: 'hollow',
          },
          {
            name: 'reset_value',
            order: 2,
          },
          {
            name: 'hold',
            order: 3,
          },
        ],
      },
      outputs: {
        static: [
          {
            name: 'out_0',
          },
        ],
      },
    },
    parameter_definitions: [
      {
        name: 'initial_states',
        data_type: 'float',
        default_value: '0.0',
        description: 'initial value of discrete integrator state',
      },
      {
        name: 'enable_reset',
        data_type: 'bool',
        default_value: 'false',
        description: 'enable the reset function',
      },
      {
        name: 'enable_external_reset',
        data_type: 'bool',
        default_value: 'true',
        description:
          'source for the value used to reset the integrator state: True=from inport, False=from the initial_state parameter',
      },
      {
        name: 'enable_hold',
        data_type: 'bool',
        default_value: 'false',
        description: 'enable the hold function',
      },
      {
        name: 'enable_limits',
        data_type: 'bool',
        default_value: 'false',
        description: 'enable the limit function',
      },
      {
        name: 'upper_limit',
        description: 'upper limit to impose on the output',
        data_type: 'float',
        default_value: '1.0',
      },
      {
        name: 'lower_limit',
        description: 'lower limit to impose on the output',
        data_type: 'float',
        default_value: '-1.0',
      },
    ],
  },
  'core.Iterator': {
    $schema: '../../block-class.schema.json',
    base: {
      name: 'Iterator',
      namespace: 'core',
      description:
        'Specify an iterative computation of blocks.  This is a stub to allow the iterator to render',
      extra_parameters: true,
      hidden: true,
      feature_level: 'dev',
    },
    modes: {
      time: 'any',
    },
    ports: {
      has_automatic_ports: true,
    },
  },
  'core.KalmanFilter': {
    $schema: '../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/base/discrete/kalman_filter.json',
    base: {
      name: 'KalmanFilter',
      namespace: 'core',
      description:
        'Discrete-time Kalman Filter for a plant or discrete state-space matrices',
      help_url:
        'https://docs.collimator.ai/using-model-editors/block-library/discrete-blocks#kalman-filter',
      supports_iterator: false,
      keywords: ['kf'],
    },
    modes: {
      time: 'discrete',
    },
    ports: {
      inputs: {
        static: [
          {
            name: 'u',
          },
          {
            name: 'y',
          },
        ],
      },
      outputs: {
        static: [
          {
            name: 'x_hat',
          },
        ],
      },
    },
    parameter_definitions: [
      {
        name: 'matrices_provided',
        display_name: 'Input discrete-time LTI matrices',
        description:
          'Check this box to input matrices for the discrete-time LTI system directly',
        data_type: 'bool',
        default_value: 'false',
      },
      {
        name: 'use_ihkf',
        display_name: 'Use Infinite-Horizon Kalman Filter',
        description: 'Check this box to use the steady state Kalman filter',
        data_type: 'bool',
        default_value: 'false',
      },
      {
        name: 'plant_submodel_uuid',
        data_type: 'reference_submodel',
        description:
          'Continuous-time plant to linearize and discretize for Kalman Filter creation',
        default_value: '',
        display_name: 'Plant Submodel',
        optional: true,
      },
      {
        name: 'dt',
        data_type: 'float',
        description:
          'Sampling rate of the discrete-time system [OR] Sampling rate for continuous-time plant discreteization',
        optional: true,
        default_value: '',
      },
      {
        name: 'discretization_method',
        description:
          'Discretization method for linearized continuous-time plant',
        data_type: 'string',
        default_value: 'zoh',
        allowed_values: ['zoh', 'euler'],
      },
      {
        name: 'x_eq',
        data_type: 'any',
        default_value: '[0, 0]',
        description:
          'State vector at the equilibrium (fixed) point for continuous-time plant linearization',
      },
      {
        name: 'u_eq',
        data_type: 'any',
        default_value: '[0]',
        description:
          'Control vector at the equilibrium (fixed) point for continuous-time plant linearization',
      },
      {
        name: 'A',
        data_type: 'any',
        default_value: '[[1,0],[0,1]]',
        description:
          'State transition matrix A for the discrete-time LTI system',
      },
      {
        name: 'B',
        data_type: 'any',
        default_value: '[[0],[0]]',
        description: 'Input matrix B for the discrete-time LTI system',
      },
      {
        name: 'C',
        data_type: 'any',
        default_value: '',
        optional: true,
        description: 'Output matrix C for the discrete-time LTI system',
      },
      {
        name: 'D',
        data_type: 'any',
        default_value: '',
        optional: true,
        description: 'Feedthrough matrix D for the discrete-time LTI system',
      },
      {
        name: 'G',
        data_type: 'any',
        default_value: '',
        optional: true,
        description:
          'Process noise matrix G for the discrete-time LTI system [OR] Process noise matrix G for the linearized continuous-time plant',
      },
      {
        name: 'Q',
        data_type: 'any',
        default_value: '',
        optional: true,
        description:
          'Process noise covariance matrix Q for the discrete-time LTI system [OR] Process noise covariance matrix Q linearized continuous-time plant',
      },
      {
        name: 'R',
        data_type: 'any',
        default_value: '',
        optional: true,
        description:
          'Measurement noise covariance matrix R for the discrete-time LTI system [OR] Measurement noise covariance matrix R linearized continuous-time plant',
      },
      {
        name: 'x_hat_0',
        data_type: 'float',
        default_value: '',
        optional: true,
        description: 'Initial value for the state vector estimate',
      },
      {
        name: 'P_hat_0',
        data_type: 'float',
        default_value: '',
        optional: true,
        description:
          'Initial value for covariance of the state vector estimate',
      },
    ],
  },
  'core.LinearizedSystem': {
    $schema: '../../block-class.schema.json',
    base: {
      name: 'LinearizedSystem',
      namespace: 'core',
      description: 'Description needed',
      extra_parameters: false,
      hidden: true,
      feature_level: 'dev',
    },
    modes: {
      time: 'any',
    },
    ports: {
      has_automatic_ports: true,
    },
    parameter_definitions: [
      {
        name: 'python_file',
        display_name: 'Operating points',
        description:
          'Name of the python file to refer to for operating point defintiion.',
        data_type: 'string',
        default_value: '',
      },
    ],
  },
  'core.Log': {
    $schema: '../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/base/maths/log.json',
    base: {
      name: 'Log',
      namespace: 'core',
      description:
        'Outputs the logarithm of base N of the inputs, where N is specified by the user.',
      help_url:
        'https://docs.collimator.ai/using-model-editors/block-library/agnostic-blocks#log',
    },
    modes: {
      time: 'agnostic',
    },
    ports: {
      inputs: {
        static: [
          {
            name: 'in_0',
          },
        ],
      },
      outputs: {
        static: [
          {
            name: 'out_0',
          },
        ],
      },
    },
    parameter_definitions: [
      {
        name: 'base',
        data_type: 'string',
        default_value: '2',
        description: 'Base for the logarithm operation.',
        allowed_values: ['natural', '2', '10'],
      },
    ],
  },
  'core.LogicalOperator': {
    $schema: '../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/base/logic_and_bit_operations/logical_operator.json',
    base: {
      name: 'LogicalOperator',
      namespace: 'core',
      description:
        'This block outputs the result of a logic operation based on the input, e.g. in_0 AND in_1.',
      development_notes:
        'See also LogicalReduce block for 2+ inputs with np.any & np.all functions.',
      keywords: ['or', 'and', 'not', 'nand', 'nor', 'xor'],
      help_url:
        'https://docs.collimator.ai/using-model-editors/block-library/agnostic-blocks#logical-operator',
    },
    modes: {
      time: 'agnostic',
    },
    ports: {
      inputs: {
        static: [
          {
            name: 'in_0',
          },
        ],
        conditional: [
          {
            order: 1,
            default_enabled: true,
            name: 'in_1',
          },
        ],
      },
      outputs: {
        static: [
          {
            name: 'out_0',
          },
        ],
      },
    },
    parameter_definitions: [
      {
        name: 'function',
        data_type: 'string',
        default_value: 'or',
        description: 'Logical function',
        allowed_values: ['or', 'and', 'not', 'nand', 'nor', 'xor'],
      },
    ],
  },
  'core.LogicalReduce': {
    $schema: '../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/base/logic_and_bit_operations/logical_reduce.json',
    base: {
      name: 'LogicalReduce',
      namespace: 'core',
      description:
        'This block outputs the result of a logic reduce operation based on the input, e.g. np.any(), np.all().',
      development_notes:
        'See also LogicalOperator block for other operations with just 2 inputs.',
      keywords: ['any', 'all'],
      help_url:
        'https://docs.collimator.ai/using-model-editors/block-library/agnostic-blocks#logical-reduce',
    },
    modes: {
      time: 'agnostic',
    },
    ports: {
      inputs: {
        static: [
          {
            name: 'in_0',
          },
        ],
      },
      outputs: {
        static: [
          {
            name: 'out_0',
          },
        ],
      },
    },
    parameter_definitions: [
      {
        name: 'function',
        data_type: 'string',
        default_value: 'any',
        description: 'Logical function',
        allowed_values: ['any', 'all'],
      },
      {
        name: 'axis',
        data_type: 'int',
        default_value: '',
        description: 'Axis along which operation is performed.',
        optional: true,
      },
    ],
  },
  'core.LookupTable1d': {
    $schema: '../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/base/lookup_tables/lookup_table_1d.json',
    base: {
      name: 'LookupTable1d',
      namespace: 'core',
      description:
        'The block models non-linearity by mapping inputs to an output value by looking up or interpolating a table of values, in this case, the table is 2 1d arrays.',
      keywords: ['interpolate'],
      help_url:
        'https://docs.collimator.ai/using-model-editors/block-library/agnostic-blocks#lookup-table-1d',
    },
    modes: {
      time: 'agnostic',
    },
    ports: {
      inputs: {
        static: [
          {
            name: 'in_0',
          },
        ],
      },
      outputs: {
        static: [
          {
            name: 'out_0',
          },
        ],
      },
    },
    parameter_definitions: [
      {
        name: 'input_array',
        data_type: 'any',
        default_value: '[0.0, 1.0]',
        description: 'Array on which the input is mapped',
      },
      {
        name: 'output_array',
        data_type: 'any',
        default_value: '[0.0, 1.0]',
        description: 'Array on which the output is mapped',
      },
      {
        name: 'interpolation',
        data_type: 'string',
        default_value: 'linear',
        description: 'Interpolation method',
        allowed_values: ['flat', 'nearest', 'linear'],
      },
    ],
  },
  'core.LookupTable2d': {
    $schema: '../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/base/lookup_tables/lookup_table_2d.json',
    base: {
      name: 'LookupTable2d',
      namespace: 'core',
      description:
        'The block models non-linearity by mapping inputs to an output value by looking up or interpolating a table of values, in this case, the table is a 2d array, and has 2 breakpoint vectors.',
      keywords: ['interpolate'],
      help_url:
        'https://docs.collimator.ai/using-model-editors/block-library/agnostic-blocks#lookup-table-2d',
    },
    modes: {
      time: 'agnostic',
    },
    ports: {
      inputs: {
        static: [
          {
            name: 'in_0',
          },
          {
            name: 'in_1',
          },
        ],
      },
      outputs: {
        static: [
          {
            name: 'out_0',
          },
        ],
      },
    },
    parameter_definitions: [
      {
        name: 'input_x_array',
        data_type: 'any',
        default_value: '[0.0, 1.0]',
        description: 'Array on which the input is mapped',
      },
      {
        name: 'input_y_array',
        data_type: 'any',
        default_value: '[0.0, 1.0]',
        description: 'Array on which the input is mapped',
      },
      {
        name: 'output_table_array',
        data_type: 'any',
        default_value: '[ [0.0, 1.0], [2.0, 3.0] ]',
        description: 'Array on which the output is mapped',
      },
      {
        name: 'interpolation',
        data_type: 'string',
        default_value: 'linear',
        description:
          'Interpolation method. Options: [linear, cubic, quintic]. Only linear is supported presently',
        allowed_values: ['linear'],
      },
    ],
  },
  'core.LoopBreak': {
    $schema: '../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/base/sources/loop_break.json',
    base: {
      name: 'LoopBreak',
      namespace: 'core',
      description: 'When true stop Iterator and exit.',
      supports_iterator: true,
      supports_models: false,
      supports_submodels: false,
      feature_level: 'dev',
    },
    modes: {
      time: 'any',
    },
    ports: {
      inputs: {
        static: [
          {
            name: 'in_0',
          },
        ],
      },
    },
  },
  'core.LoopCounter': {
    $schema: '../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/base/sources/loop_counter.json',
    base: {
      name: 'LoopCounter',
      namespace: 'core',
      description:
        'Acts as the (i=0;i<N;i+=increment) feature of a typical for loop.',
      supports_iterator: true,
      supports_models: false,
      supports_submodels: false,
      feature_level: 'dev',
    },
    modes: {
      time: 'any',
    },
    ports: {
      outputs: {
        static: [
          {
            name: 'out_0',
          },
        ],
      },
    },
    parameter_definitions: [
      {
        name: 'start_value',
        description: 'The value the counter starts at, e.g. for(i=value; ...',
        data_type: 'int',
        default_value: '0',
      },
      {
        name: 'end_value',
        description: 'The value the counter stops at, e.g. for(i=0;i<value ...',
        data_type: 'int',
        default_value: '0',
      },
      {
        name: 'increment',
        description:
          'The value the counter steps by, e.g. for(i=0;i<N,i+=value) ...',
        data_type: 'int',
        default_value: '1',
      },
    ],
  },
  'core.LoopMemory': {
    $schema: '../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/base/discrete/loop_memory.json',
    base: {
      name: 'LoopMemory',
      namespace: 'core',
      description: 'Memory in an Iterator loop.',
      supports_iterator: true,
      supports_models: false,
      supports_submodels: false,
      feature_level: 'dev',
    },
    modes: {
      time: 'any',
    },
    ports: {
      inputs: {
        static: [
          {
            name: 'in_0',
          },
        ],
      },
      outputs: {
        static: [
          {
            name: 'out_0',
          },
        ],
      },
    },
    parameter_definitions: [
      {
        name: 'initial_value',
        data_type: 'float',
        default_value: '0.0',
        description: 'initial value of memory.',
      },
    ],
  },
  'core.MatrixConcatenation': {
    $schema: '../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/base/matrix_operations/matrix_concatenation.json',
    base: {
      name: 'MatrixConcatenation',
      namespace: 'core',
      description: 'This block outputs the concatenation of 2 matrices.',
      help_url:
        'https://docs.collimator.ai/using-model-editors/block-library/agnostic-blocks#matrix-concatenation',
    },
    modes: {
      time: 'agnostic',
    },
    ports: {
      inputs: {
        static: [
          {
            name: 'in_0',
          },
          {
            name: 'in_1',
          },
        ],
      },
      outputs: {
        static: [
          {
            name: 'out_0',
          },
        ],
      },
    },
    parameter_definitions: [
      {
        name: 'axis',
        data_type: 'int',
        default_value: '0',
        description:
          'Axis along which the matrices will be concatenated. 0 for vertical and 1 for horizontal. Default is 0.',
        $comment:
          "For now, None is not supported. If we want support for it, add 'optional' flag.",
      },
    ],
  },
  'core.MatrixInversion': {
    $schema: '../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/base/matrix_operations/matrix_inversion.json',
    base: {
      name: 'MatrixInversion',
      namespace: 'core',
      description: 'This block outputs the inverse of a matrix.',
      help_url:
        'https://docs.collimator.ai/using-model-editors/block-library/agnostic-blocks#matrix-inversion',
    },
    modes: {
      time: 'agnostic',
    },
    ports: {
      inputs: {
        static: [
          {
            name: 'in_0',
          },
        ],
      },
      outputs: {
        static: [
          {
            name: 'out_0',
          },
        ],
      },
    },
  },
  'core.MatrixMultiplication': {
    $schema: '../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/base/matrix_operations/matrix_multiplication.json',
    base: {
      name: 'MatrixMultiplication',
      namespace: 'core',
      description:
        'This block outputs the result of multiplying a matrix/vector by a matrix/vector.',
      help_url:
        'https://docs.collimator.ai/using-model-editors/block-library/agnostic-blocks#matrix-multiplication',
    },
    modes: {
      time: 'agnostic',
    },
    ports: {
      inputs: {
        static: [
          {
            name: 'in_0',
          },
          {
            name: 'in_1',
          },
        ],
      },
      outputs: {
        static: [
          {
            name: 'out_0',
          },
        ],
      },
    },
  },
  'core.MatrixOperator': {
    $schema: '../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/base/matrix_operations/matrix_operator.json',
    base: {
      name: 'MatrixOperator',
      namespace: 'core',
      description:
        'This block outputs the result of a matrix operation based on the inputs.',
      feature_level: 'dev',
      help_url:
        'https://docs.collimator.ai/using-model-editors/block-library/agnostic-blocks#matrix-operator',
    },
    modes: {
      time: 'agnostic',
    },
    ports: {
      inputs: {
        static: [
          {
            name: 'in_0',
          },
        ],
        conditional: [
          {
            order: 1,
            default_enabled: false,
            name: 'in_1',
          },
        ],
      },
      outputs: {
        static: [
          {
            name: 'out_0',
          },
        ],
      },
    },
    parameter_definitions: [
      {
        name: 'function',
        data_type: 'string',
        default_value: 'transpose',
        description: 'Matrix function',
        allowed_values: ['transpose', 'inverse', 'multiply', 'extraction'],
      },
      {
        name: 'concatenation_orientation',
        data_type: 'string',
        default_value: 'horizontal',
        description: 'Orientation for the concatenation function',
        allowed_values: ['horizontal', 'vertical'],
      },
      {
        name: 'multiplication_type',
        data_type: 'string',
        default_value: 'matrix_matrix',
        description: 'Matrix multiplication type',
        allowed_values: ['matrix_matrix', 'matrix_vector', 'vector_vector'],
      },
      {
        name: 'extraction_index',
        data_type: 'int',
        default_value: '0',
        description:
          'The index for element in the to be extracted. The element will be extracted from the last dimension.',
      },
    ],
  },
  'core.MatrixTransposition': {
    $schema: '../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/base/matrix_operations/matrix_transposition.json',
    base: {
      name: 'MatrixTransposition',
      namespace: 'core',
      description: 'This block outputs the transpose of a matrix.',
      help_url:
        'https://docs.collimator.ai/using-model-editors/block-library/agnostic-blocks#matrix-transposition',
    },
    modes: {
      time: 'agnostic',
    },
    ports: {
      inputs: {
        static: [
          {
            name: 'in_0',
          },
        ],
      },
      outputs: {
        static: [
          {
            name: 'out_0',
          },
        ],
      },
    },
  },
  'core.MinMax': {
    $schema: '../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/base/maths/minmax.json',
    base: {
      name: 'MinMax',
      namespace: 'core',
      description: 'Outputs the minimum or maximum of the inputs.',
      keywords: ['min', 'max'],
      help_url:
        'https://docs.collimator.ai/using-model-editors/block-library/agnostic-blocks#minmax',
    },
    modes: {
      time: 'agnostic',
    },
    events: {
      count: -1,
    },
    ports: {
      inputs: {
        dynamic: {
          min_count: 2,
          max_count: -1,
          default_count: 2,
        },
      },
      outputs: {
        static: [
          {
            name: 'out_0',
          },
        ],
      },
    },
    parameter_definitions: [
      {
        name: 'operator',
        description: 'Selection to apply min() or max() operator.',
        data_type: 'string',
        default_value: 'max',
        allowed_values: ['max', 'min'],
      },
    ],
  },
  'core.MLP': {
    $schema: '../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/core/mlp.json',
    base: {
      name: 'MLP',
      namespace: 'core',
      description: 'Equinox MLP block (auto differentiable)',
      help_url:
        'https://docs.collimator.ai/using-model-editors/block-library/using-the-mlp-block',
    },
    modes: {
      time: 'agnostic',
    },
    ports: {
      inputs: {
        static: [
          {
            name: 'in_0',
          },
        ],
      },
      outputs: {
        static: [
          {
            name: 'out_0',
          },
        ],
      },
    },
    parameter_definitions: [
      {
        name: 'in_size',
        data_type: 'int',
        description: 'Input size: model input will be of shape (in_size,)',
        default_value: '2',
      },
      {
        name: 'out_size',
        data_type: 'int',
        description: 'Output size: model output will be of shape (out_size,)',
        default_value: '2',
      },
      {
        name: 'width_size',
        data_type: 'int',
        description: 'Size of each hidden layer',
        default_value: '2',
      },
      {
        name: 'depth',
        data_type: 'int',
        description: 'Number of hidden layers, including the output layer',
        default_value: '2',
      },
      {
        name: 'seed',
        data_type: 'int',
        description:
          'An integer seed for the random number generator. If empty, a random 32-bit seed will be generated.',
        optional: true,
        default_value: '',
      },
      {
        name: 'activation_str',
        description: 'Activation function after each hidden layer',
        data_type: 'string',
        default_value: 'relu',
        optional: true,
        allowed_values: [
          'relu',
          'sigmoid',
          'tanh',
          'elu',
          'swish',
          'rbf',
          'identity',
        ],
      },
      {
        name: 'final_activation_str',
        description: 'Activation function after the output layer',
        data_type: 'string',
        default_value: 'relu',
        optional: true,
        allowed_values: [
          'relu',
          'sigmoid',
          'tanh',
          'elu',
          'swish',
          'rbf',
          'identity',
        ],
      },
      {
        name: 'use_bias',
        data_type: 'bool',
        default_value: 'true',
        optional: true,
        description: 'Whether to add on a bias to internal layers',
      },
      {
        name: 'use_final_bias',
        data_type: 'bool',
        default_value: 'true',
        optional: true,
        description: 'Whether to add on a bias to the final layer',
      },
      {
        name: 'file_name',
        display_name: 'Parameter file name',
        data_type: 'string',
        default_value: '',
        optional: true,
        description: 'Serialised file containing MLP parameters',
      },
    ],
  },
  'core.ModelicaFMU': {
    $schema: '../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/base/user_defined/modelica_fmu.json',
    base: {
      name: 'ModelicaFMU',
      namespace: 'core',
      description:
        "Instantiates a cosimulation exported according to the Modelica Association's FMI specification",
      extra_parameters: true,
      keywords: ['code'],
      help_url:
        'https://docs.collimator.ai/using-model-editors/block-library/discrete-blocks#modelica_fmu',
    },
    modes: {
      time: 'discrete',
    },
    ports: {
      has_automatic_ports: true,
    },
    parameter_definitions: [
      {
        name: 'file_name',
        description: 'Name of the .fmu file to use',
        data_type: 'string',
        default_value: '',
      },
      {
        name: 'fmu_guid',
        data_type: 'string',
        default_value: '',
        description: "guid from the .fmu's modelDescription.xml",
        hidden: true,
      },
    ],
  },
  'core.MuJoCo': {
    $schema: '../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/core/mujoco.json',
    base: {
      name: 'MuJoCo',
      namespace: 'core',
      description: 'Embed a MuJoCo robot model as a block.',
      feature_level: 'dev',
      keywords: ['mujoco', 'robot', 'mjcf', 'mjx'],
    },
    modes: {
      time: 'agnostic',
    },
    ports: {
      inputs: {
        static: [
          {
            name: 'control',
          },
        ],
        conditional: [
          {
            name: 'mocap_pos',
            order: 1,
            condition_bool_parameter: 'enable_mocap_pos',
            default_enabled: false,
          },
        ],
      },
      outputs: {
        static: [
          {
            name: 'qpos',
          },
          {
            name: 'qvel',
          },
          {
            name: 'act',
          },
        ],
        conditional: [
          {
            name: 'sensor_data',
            order: 3,
            condition_bool_parameter: 'enable_sensor_data',
            default_enabled: false,
          },
          {
            name: 'video',
            order: 4,
            condition_bool_parameter: 'enable_video_output',
            default_enabled: false,
          },
        ],
        dynamic: {
          default_count: 0,
          min_count: 0,
          max_count: -1,
          parameter_definitions: [
            {
              name: 'script',
              description: 'Inline script to execute to compute this output',
              data_type: 'code',
              default_value: 'output_fn = lambda: 0',
              display_full_width: true,
            },
          ],
        },
      },
    },
    parameter_definitions: [
      {
        name: 'use_mjx',
        data_type: 'bool',
        default_value: 'true',
        description: 'Whether to use the JAX (MJX) or C++ implementation',
      },
      {
        name: 'file_name',
        display_name: 'XML file',
        description:
          'Select the root XML robot model to use, must be MJCF or URDF format',
        data_type: 'file',
        file_formats: ['*.xml', '*.mjcf', '*.urdf'],
        default_value: '',
      },
      {
        name: 'dt',
        display_name: 'Δt',
        description:
          'The time interval between each simulation step, only respected by the non-mjx variant. If not set, defaults to the global discrete interval',
        data_type: 'float',
        default_value: '',
        optional: true,
      },
      {
        name: 'key_frame_0',
        description: 'The keyframe to initialize the model from',
        data_type: 'int',
        optional: true,
        default_value: '',
      },
      {
        name: 'qpos_0',
        description: 'The initial generalized position coordinates',
        data_type: 'any',
        optional: true,
        default_value: '',
      },
      {
        name: 'qvel_0',
        description: 'The initial generalized velocity coordinates',
        data_type: 'any',
        optional: true,
        default_value: '',
      },
      {
        name: 'act_0',
        description: 'TThe initial actuator coordinates',
        data_type: 'any',
        optional: true,
        default_value: '',
      },
      {
        name: 'enable_sensor_data',
        description: 'Whether to output the sensor data',
        data_type: 'bool',
        default_value: 'false',
      },
      {
        name: 'enable_mocap_pos',
        description: 'Enable the mocap_pos input port',
        data_type: 'bool',
        default_value: 'false',
      },
      {
        name: 'enable_video_output',
        description: 'Whether to output the video data',
        data_type: 'bool',
        default_value: 'false',
      },
      {
        name: 'video_size',
        description: 'The size of the video output, as a Python shape: (H,W)',
        default_value: '(480, 640)',
        data_type: 'any',
        optional: true,
      },
    ],
  },
  'core.Mux': {
    $schema: '../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/base/signal_routing/mux.json',
    base: {
      name: 'Mux',
      namespace: 'core',
      description: 'Create a vector signal from all of the inputs.',
      help_url:
        'https://docs.collimator.ai/using-model-editors/block-library/agnostic-blocks#mux',
    },
    modes: {
      time: 'agnostic',
    },
    ports: {
      inputs: {
        dynamic: {
          min_count: 2,
          max_count: -1,
          default_count: 2,
        },
      },
      outputs: {
        static: [
          {
            name: 'out_0',
          },
        ],
      },
    },
  },
  'core.ObjectDetection': {
    $schema: '../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/core/object_detection.json',
    base: {
      name: 'ObjectDetection',
      namespace: 'core',
      description:
        'Detect objects in images. Model was trained on COCO 2017 object detection. Output is a list of python dictionary containing the "label", "score" and "box" (xmin, ymin, xmax, ymax) for each object detected.',
      feature_level: 'dev',
    },
    modes: {
      time: 'discrete',
    },
    ports: {
      inputs: {
        static: [
          {
            name: 'image',
          },
        ],
      },
      outputs: {
        static: [
          {
            name: 'results',
          },
        ],
      },
    },
    parameter_definitions: [
      {
        name: 'model',
        data_type: 'string',
        default_value: 'detr-resnet-50',
        allowed_values: ['detr-resnet-50'],
        description:
          'Object detection model. Only "detr-resnet-50" supported for now.',
      },
    ],
  },
  'core.Offset': {
    $schema: '../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/base/maths/offset.json',
    base: {
      name: 'Offset',
      namespace: 'core',
      description:
        'This block outputs its input value offset (added) by a constant scalar.',
      help_url:
        'https://docs.collimator.ai/using-model-editors/block-library/agnostic-blocks#offset',
    },
    modes: {
      time: 'agnostic',
    },
    ports: {
      inputs: {
        static: [
          {
            name: 'in_0',
          },
        ],
      },
      outputs: {
        static: [
          {
            name: 'out_0',
          },
        ],
      },
    },
    parameter_definitions: [
      {
        name: 'offset',
        data_type: 'float',
        default_value: '1.0',
        description: 'Scalar offset',
      },
    ],
  },
  'core.Outport': {
    $schema: '../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/base/ports_and_submodels/outport.json',
    base: {
      name: 'Outport',
      namespace: 'core',
      description: 'This block represents the outport of a submodel.',
      supports_models: false,
      supports_submodels: true,
    },
    modes: {
      time: 'agnostic',
    },
    ports: {
      inputs: {
        static: [
          {
            name: 'in_0',
          },
        ],
      },
    },
    parameter_definitions: [
      {
        name: 'port_id',
        data_type: 'int',
        description:
          'The index / id matching with the actual outport of the submodel block that houses this outport block',
        default_value: '0',
        hidden: true,
        read_only: true,
      },
      {
        name: 'description',
        data_type: 'string',
        description: 'Description of the port',
        default_value: '',
      },
    ],
  },
  'core.PID': {
    $schema: '../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/base/discrete/pid.json',
    base: {
      name: 'PID',
      namespace: 'core',
      description: 'Continuous time PID controller.',
      help_url:
        'https://docs.collimator.ai/using-model-editors/block-library/continuous-blocks#pid',
      supports_iterator: false,
    },
    modes: {
      time: 'agnostic',
    },
    ports: {
      inputs: {
        static: [
          {
            name: 'in_0',
          },
        ],
        conditional: [
          {
            name: 'initial_state',
            condition_bool_parameter: 'enable_external_initial_state',
            order: 1,
          },
        ],
      },
      outputs: {
        static: [
          {
            name: 'out_0',
          },
        ],
      },
    },
    parameter_definitions: [
      {
        name: 'Kp',
        data_type: 'float',
        default_value: '1.0',
        description: 'Proportional gain.',
      },
      {
        name: 'Ki',
        data_type: 'float',
        default_value: '1.0',
        description: 'Integral gain.',
      },
      {
        name: 'Kd',
        data_type: 'float',
        default_value: '1.0',
        description: 'Derivative gain.',
      },
      {
        name: 'N',
        data_type: 'int',
        default_value: '100',
        description: 'Derivative filter coefficient.',
      },
      {
        name: 'enable_external_initial_state',
        data_type: 'bool',
        default_value: 'false',
        description:
          'Source for the value used for the integrator initial state. True=from inport, False=from the initial_state parameter.',
      },
      {
        name: 'initial_state',
        data_type: 'float',
        default_value: '0.0',
        description:
          'Initial value of the integrator state which sets the initial value of the I term.',
      },
      {
        name: 'tuning_time',
        data_type: 'float',
        default_value: '0.0',
        description: 'Amount of time the auto-tune should run for.',
        hidden: true,
      },
    ],
  },
  'core.PID_Discrete': {
    $schema: '../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/base/discrete/pid_discrete.json',
    base: {
      name: 'PID_Discrete',
      namespace: 'core',
      description: 'Discrete time PID controller',
      help_url:
        'https://docs.collimator.ai/using-model-editors/block-library/discrete-blocks#pid-discrete',
      supports_iterator: false,
    },
    modes: {},
    ports: {
      inputs: {
        static: [
          {
            name: 'in_0',
          },
        ],
        conditional: [
          {
            name: 'initial_state',
            condition_bool_parameter: 'enable_external_initial_state',
            order: 1,
          },
        ],
      },
      outputs: {
        static: [
          {
            name: 'out_0',
          },
        ],
      },
    },
    parameter_definitions: [
      {
        name: 'Kp',
        data_type: 'float',
        default_value: '1.0',
        description: 'Proportional gain.',
      },
      {
        name: 'Ki',
        data_type: 'float',
        default_value: '1.0',
        description: 'Integral gain.',
      },
      {
        name: 'Kd',
        data_type: 'float',
        default_value: '1.0',
        description: 'Derivative gain.',
      },
      {
        name: 'enable_external_initial_state',
        data_type: 'bool',
        default_value: 'false',
        description:
          'Source for the value used for the integrator initial state. True=from inport, False=from the initial_state parameter.',
      },
      {
        name: 'initial_state',
        data_type: 'float',
        default_value: '0.0',
        description:
          'Initial value of the integrator state which sets the initial value of the I term.',
      },
      {
        name: 'filter_type',
        data_type: 'string',
        allowed_values: ['none', 'forward', 'backward', 'bilinear'],
        default_value: 'none',
        description:
          'Type of filter used to estimate the derivative of the error signal.',
      },
      {
        name: 'filter_coefficient',
        data_type: 'float',
        default_value: '1.0',
        description: 'Filter coefficient for the derivative filter.',
      },
      {
        name: 'tuning_time',
        data_type: 'float',
        default_value: '0.0',
        description: 'Amount of time the auto-tune should run for.',
        $comment:
          'Only for auto-tuning optimizations - not for normal simulations',
        hidden: true,
      },
    ],
  },
  'core.Power': {
    $schema: '../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/base/maths/power.json',
    base: {
      name: 'Power',
      namespace: 'core',
      description:
        'This block outputs its input value raised to the power of a user specified value.',
      keywords: ['square', 'exponent'],
      help_url:
        'https://docs.collimator.ai/using-model-editors/block-library/agnostic-blocks#power',
    },
    modes: {
      time: 'agnostic',
    },
    ports: {
      inputs: {
        static: [
          {
            name: 'in_0',
          },
        ],
      },
      outputs: {
        static: [
          {
            name: 'out_0',
          },
        ],
      },
    },
    parameter_definitions: [
      {
        name: 'exponent',
        data_type: 'float',
        default_value: '1.0',
        description: 'Scalar exponent',
      },
    ],
  },
  'core.Product': {
    $schema: '../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/base/maths/product.json',
    base: {
      name: 'Product',
      namespace: 'core',
      description: 'Multiplies and/or divides 2 or more inputs together',
      development_notes:
        'the parameter operators must be used to configure the number of block inputs. this algorithm can be found in maths.py/Product/compute_configuration',
      keywords: ['multiply', 'divide'],
      help_url:
        'https://docs.collimator.ai/using-model-editors/block-library/agnostic-blocks#product',
    },
    modes: {
      time: 'agnostic',
    },
    ports: {
      inputs: {
        dynamic: {
          min_count: 2,
          max_count: -1,
          default_count: 2,
        },
      },
      outputs: {
        static: [
          {
            name: 'out_0',
          },
        ],
      },
    },
    parameter_definitions: [
      {
        name: 'operators',
        description:
          "A string of '*' or '/' operators to apply multiplication or division on the respective inputs. For each operator, an input port named `in_<n>` will be automatically generated (where n is a number starting with 0).",
        description_gpt:
          "A list of operations to apply between inputs. Each operation must be either 'mul' or 'div'.",
        data_type: 'string',
        default_value: '**',
        hidden: true,
      },
      {
        name: 'divide_by_zero_behavior',
        description:
          'User input to define the behavior. Two options. 1] return inf and raise RuntimeWarning, or 2] prevent it by preventing denominator being zero.',
        description_gpt:
          "A string, either '1' or '2' which defines the behavior when dividing by zero. Set 1 to return inf and raise RuntimeWarning, or 2 to prevent the denominator from being too close to zero by using limit_denominator.",
        data_type: 'string',
        default_value: 'inf',
        allowed_values: ['inf', 'limit_denominator'],
      },
      {
        name: 'denominator_limit',
        description:
          'When the abs(denominator) is between zero and denominator_limit, the denominator will be set to denominator_limit, with its sign preserved. divide_by_zero_behavior must be set to limit_denominator for this to have an effect. Negative values for denominator_limit are converted to positive.',
        description_gpt:
          "Only enabled when divide_by_zero_behavior = '2'. Use denominator_limit to prevent dividing by very small numbers, or 0. When the abs(denominator) is between zero and denominator_limit, the denominator will be set to denominator_limit, with its sign preserved. Negative values for denominator_limit are converted to positive.",
        data_type: 'float',
        default_value: '1e-12',
      },
    ],
  },
  'core.ProductOfElements': {
    $schema: '../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/base/matrix_operations/product_of_elements.json',
    base: {
      name: 'ProductOfElements',
      namespace: 'core',
      description:
        'This block outputs the product of the elements of a matrix.',
      help_url:
        'https://docs.collimator.ai/using-model-editors/block-library/agnostic-blocks#product-of-elements',
    },
    modes: {
      time: 'agnostic',
    },
    ports: {
      inputs: {
        static: [
          {
            name: 'in_0',
          },
        ],
      },
      outputs: {
        static: [
          {
            name: 'out_0',
          },
        ],
      },
    },
  },
  'core.Pulse': {
    $schema: '../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/base/sources/pulse.json',
    base: {
      name: 'Pulse',
      namespace: 'core',
      description: 'Generates square wave pulses at regular intervals',
      help_url:
        'https://docs.collimator.ai/using-model-editors/block-library/discrete-blocks#pulse',
    },
    modes: {
      time: 'discrete',
    },
    ports: {
      outputs: {
        static: [
          {
            name: 'out_0',
          },
        ],
      },
    },
    parameter_definitions: [
      {
        name: 'amplitude',
        description: 'the amplitude of the pulses.',
        data_type: 'float',
        default_value: '1.0',
      },
      {
        name: 'pulse_width',
        description:
          'the fraction of the period that the signal has the value of amplitude.',
        data_type: 'float',
        default_value: '0.5',
      },
      {
        name: 'period',
        description:
          'the time take for both the on and off portions of the signal. e.g. one cycle.',
        data_type: 'float',
        default_value: '1.0',
      },
    ],
  },
  'core.PythonScript': {
    $schema: '../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/base/user_defined/python_script.json',
    base: {
      name: 'PythonScript',
      namespace: 'core',
      description:
        'Executes Python statements. Values on named input ports are bound to Python variables before the statements in the block are executed. Values for named output ports are retrieved from the Python environment after executing the statements in the block. Use this block to implement any arbitrary function.',
      keywords: ['code'],
      extra_parameters: true,
      help_url:
        'https://docs.collimator.ai/using-model-editors/block-library/using-the-python-script-block',
    },
    modes: {
      time: 'any',
    },
    ports: {
      inputs: {
        dynamic: {
          min_count: 0,
          default_count: 1,
          max_count: -1,
        },
      },
      outputs: {
        dynamic: {
          min_count: 0,
          default_count: 1,
          max_count: -1,
        },
      },
    },
    parameter_definitions: [
      {
        name: 'user_statements',
        data_type: 'string',
        default_value: 'out_0 = in_0',
        description: 'String representation of the user statements.',
        hidden: true,
      },
      {
        name: 'init_script',
        data_type: 'string',
        default_value:
          '# import numpy as np\n# import jax.numpy as jnp\nout_0 = 0.0  # out_0 will be of type float64. Use out_0 = 0 for int',
        description: 'String representation of the initialization script.',
        hidden: true,
      },
      {
        name: 'finalize_script',
        data_type: 'string',
        default_value: '',
        description: 'String representation of the finalization script.',
        hidden: true,
      },
      {
        name: 'accelerate_with_jax',
        display_name: 'Accelerate with JAX',
        data_type: 'bool',
        default_value: 'false',
        description: 'Use JAX to accelerate simulation.',
      },
    ],
  },
  'core.PyTorch': {
    $schema: '../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/core/pytorch.json',
    base: {
      name: 'PyTorch',
      namespace: 'core',
      description: 'Prediction (Inference) with PyTorch (TorchScript) model.',
      help_url:
        'https://docs.collimator.ai/using-model-editors/block-library/using-the-predictor-block',
    },
    modes: {
      time: 'discrete',
    },
    ports: {
      inputs: {
        dynamic: {
          min_count: 1,
          default_count: 1,
          max_count: -1,
        },
      },
      outputs: {
        dynamic: {
          min_count: 1,
          default_count: 1,
          max_count: -1,
        },
      },
    },
    parameter_definitions: [
      {
        name: 'file_name',
        data_type: 'file',
        default_value: '',
        file_formats: ['*'],
        description: 'TorchScript file to load.',
      },
      {
        name: 'add_batch_dim_to_inputs',
        display_name: 'Auto add batch dimension',
        data_type: 'bool',
        default_value: 'false',
        description:
          'Automatically add new first dimension to the input tensors for batch processing.',
      },
    ],
  },
  'core.PyTwin': {
    $schema: '../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/base/user_defined/ansys_pytwin.json',
    base: {
      name: 'PyTwin',
      namespace: 'core',
      description: 'Block to incorporate Ansys PyTwin models',
    },
    modes: {
      time: 'discrete',
    },
    ports: {
      has_automatic_ports: true,
    },
    parameter_definitions: [
      {
        name: 'dt',
        display_name: 'Δt',
        data_type: 'float',
        description:
          'Discrete-time update period for the twin. If empty, matches the global clock tick.',
        optional: true,
        default_value: '',
      },
      {
        name: 'pytwin_file',
        description: 'Name of the PyTwin .twin file to use',
        data_type: 'file',
        default_value: '',
        file_formats: ['*.twin'],
      },
      {
        name: 'pytwin_config',
        description: 'Name of the PyTwin .json config file for initialization',
        data_type: 'file',
        optional: true,
        default_value: '',
        file_formats: ['*.json'],
      },
      {
        name: 'parameters',
        description: 'Dictionary of model parameters',
        data_type: 'any',
        optional: true,
        default_value: '',
      },
      {
        name: 'inputs',
        description: 'Dictionary of model inputs',
        data_type: 'any',
        optional: true,
        default_value: '',
      },
    ],
  },
  'core.Quantizer': {
    $schema: '../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/base/maths/quantizer.json',
    base: {
      name: 'Quantizer',
      namespace: 'core',
      description:
        'This block bins the inputs values into discrete ranges defined by the quantizing interval.',
      help_url:
        'https://docs.collimator.ai/using-model-editors/block-library/agnostic-blocks#quantizer',
    },
    modes: {
      time: 'agnostic',
    },
    ports: {
      inputs: {
        static: [
          {
            name: 'in_0',
          },
        ],
      },
      outputs: {
        static: [
          {
            name: 'out_0',
          },
        ],
      },
    },
    parameter_definitions: [
      {
        name: 'interval',
        data_type: 'float',
        default_value: '1.0',
        description: 'Quantization interval',
      },
    ],
  },
  'core.Ramp': {
    $schema: '../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/base/sources/ramp.json',
    base: {
      name: 'Ramp',
      namespace: 'core',
      description:
        'Generates a signal that starts at a specified time and value and changes by a specified rate. y(t) = A*(t-t0) if t >= t0.',
      help_url:
        'https://docs.collimator.ai/using-model-editors/block-library/agnostic-blocks#ramp',
    },
    modes: {
      time: 'any',
    },
    ports: {
      outputs: {
        static: [
          {
            name: 'out_0',
          },
        ],
      },
    },
    parameter_definitions: [
      {
        name: 'slope',
        description: 'specified rate at which the signal changes.',
        data_type: 'float',
        default_value: '1.0',
      },
      {
        name: 'start_value',
        description: 'Initial value of the signal before the start time.',
        data_type: 'float',
        default_value: '0.0',
      },
      {
        name: 'start_time',
        description:
          'Time in seconds when the output start to change according to the slope value.',
        data_type: 'float',
        default_value: '1.0',
      },
    ],
  },
  'core.RandomNumber': {
    $schema: '../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/base/sources/random_number.json',
    base: {
      name: 'RandomNumber',
      namespace: 'core',
      description: 'Generates normally distributed random numbers',
      help_url:
        'https://docs.collimator.ai/using-model-editors/block-library/discrete-blocks#random-number',
    },
    modes: {
      time: 'discrete',
    },
    ports: {
      outputs: {
        static: [
          {
            name: 'out_0',
          },
        ],
      },
    },
    parameter_definitions: [
      {
        name: 'distribution',
        data_type: 'string',
        allowed_values: ['normal'],
        default_value: 'normal',
        description: 'Distribution to sample from',
      },
      {
        name: 'seed',
        data_type: 'int',
        description:
          'An integer seed for the random number generator. If empty, a random 32-bit seed will be generated.',
        optional: true,
        default_value: '',
      },
      {
        name: 'dtype',
        data_type: 'string',
        default_value: 'float64',
        allowed_values: ['float16', 'float32', 'float64', 'float128'],
        description: 'Data type of the signal',
      },
      {
        name: 'shape',
        data_type: 'any',
        default_value: '()',
        description: 'Array shape of the signal',
      },
    ],
  },
  'core.RateLimiter': {
    $schema: '../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/base/discontinuities/rate_limiter.json',
    base: {
      name: 'RateLimiter',
      namespace: 'core',
      description:
        'Passes through the input but constrains the derivative of the output to be between the upper and lower derivative limits.',
      help_url:
        'https://docs.collimator.ai/using-model-editors/block-library/agnostic-blocks#rate-limiter',
      supports_iterator: false,
      feature_level: 'dev',
    },
    modes: {
      time: 'agnostic',
    },
    events: {
      count: 2,
    },
    ports: {
      inputs: {
        static: [
          {
            name: 'in_0',
          },
        ],
        conditional: [
          {
            name: 'upper_limit',
            condition_bool_parameter: 'enable_dynamic_upper_limit',
            order: 0,
          },
          {
            name: 'lower_limit',
            condition_bool_parameter: 'enable_dynamic_lower_limit',
            order: 2,
          },
        ],
      },
      outputs: {
        static: [
          {
            name: 'out_0',
          },
        ],
      },
    },
    parameter_definitions: [
      {
        name: 'enable_dynamic_upper_limit',
        data_type: 'bool',
        default_value: 'false',
        description:
          'User selection for enabling inport for upper limit derivative value.',
      },
      {
        name: 'upper_limit',
        description: 'upper limit impose on the output derivative',
        data_type: 'float',
        default_value: '1.0',
      },
      {
        name: 'enable_dynamic_lower_limit',
        data_type: 'bool',
        default_value: 'false',
        description:
          'User selection for enabling inport for lower limit derivative value.',
      },
      {
        name: 'lower_limit',
        description: 'lower limit impose on the output derivative',
        data_type: 'float',
        default_value: '-1.0',
      },
    ],
  },
  'core.Reciprocal': {
    $schema: '../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/base/maths/reciprocal.json',
    base: {
      name: 'Reciprocal',
      namespace: 'core',
      description: 'Outputs the reciprocal of the inputs.',
      keywords: ['inverse'],
      help_url:
        'https://docs.collimator.ai/using-model-editors/block-library/agnostic-blocks#reciprocal',
    },
    modes: {
      time: 'agnostic',
    },
    ports: {
      inputs: {
        static: [
          {
            name: 'in_0',
          },
        ],
      },
      outputs: {
        static: [
          {
            name: 'out_0',
          },
        ],
      },
    },
  },
  'core.ReferenceSubmodel': {
    $schema: '../../block-class.schema.json',
    base: {
      name: 'ReferenceSubmodel',
      namespace: 'core',
      description:
        'A submodel that can be referenced multiple times by many models.',
      extra_parameters: true,
      feature_level: 'dev',
    },
    ui_defaults: {
      grid_width: 24,
      grid_height: 12,
    },
    modes: {
      time: 'any',
    },
    ports: {
      inputs: {
        dynamic: {
          min_count: 0,
          max_count: -1,
          default_count: 1,
        },
      },
      outputs: {
        dynamic: {
          min_count: 0,
          max_count: -1,
          default_count: 1,
        },
      },
      has_automatic_ports: true,
    },
  },
  'core.Relay': {
    $schema: '../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/base/discontinuities/relay.json',
    base: {
      name: 'Relay',
      namespace: 'core',
      description:
        'The Relay block allows its output to switch between two specified values. ',
      development_notes:
        'this block initial_state should not be exposed to the user, it defaults to off_value. ',
      help_url:
        'https://docs.collimator.ai/using-model-editors/block-library/agnostic-blocks#relay',
    },
    modes: {
      time: 'agnostic',
    },
    events: {
      count: 2,
    },
    ports: {
      inputs: {
        static: [
          {
            name: 'in_0',
          },
        ],
      },
      outputs: {
        static: [
          {
            name: 'out_0',
          },
        ],
      },
    },
    parameter_definitions: [
      {
        name: 'on_threshold',
        description:
          'threshold above which the output will switch to the on_value',
        data_type: 'float',
        default_value: '1.0',
      },
      {
        name: 'off_threshold',
        description:
          'threshold below which the output will switch to the off_value',
        data_type: 'float',
        default_value: '0.0',
      },
      {
        name: 'on_value',
        description: 'output value when in on state',
        data_type: 'float',
        default_value: '1.0',
      },
      {
        name: 'off_value',
        description: 'output value when in off state',
        data_type: 'float',
        default_value: '0.0',
      },
      {
        name: 'initial_state',
        data_type: 'float',
        default_value: '0.0',
        description: 'initial value of relay state.',
      },
    ],
  },
  'core.Replicator': {
    $schema: '../../block-class.schema.json',
    base: {
      name: 'Replicator',
      namespace: 'core',
      description: 'replcaite a submodel N times.',
      extra_parameters: true,
      hidden: true,
      feature_level: 'dev',
    },
    modes: {
      time: 'any',
    },
    ports: {
      has_automatic_ports: true,
    },
    parameter_definitions: [
      {
        name: 'instances',
        data_type: 'int',
        default_value: '2',
        description: 'the number of instances/replicas',
      },
    ],
  },
  'core.RigidBody': {
    $schema: '../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/base/discrete/rigid_body.json',
    base: {
      name: 'RigidBody',
      namespace: 'core',
      description:
        'Models rigid body motion with 3 translational degress of freedom, and optionally 3 rotational degrees of freedom.',
      keywords: ['dof', '6dof', 'six degrees of freedom'],
      help_url:
        'https://docs.collimator.ai/using-model-editors/block-library/continuous-blocks#rigid-body',
      supports_iterator: false,
    },
    modes: {
      time: 'agnostic',
    },
    ports: {
      inputs: {
        static: [
          {
            name: 'force_vector',
          },
          {
            name: 'torque_vector',
          },
        ],
        conditional: [
          {
            name: 'mass',
            condition_bool_parameter: 'enable_external_mass',
            order: 2,
          },
          {
            name: 'inertia_matrix',
            condition_bool_parameter: 'enable_external_inertia_matrix',
            order: 3,
          },
        ],
      },
      outputs: {
        static: [
          {
            name: 'position',
          },
          {
            name: 'orientation',
          },
          {
            name: 'velocity',
          },
          {
            name: 'angular_velocity',
          },
        ],
        conditional: [
          {
            name: 'position_derivative',
            condition_bool_parameter: 'enable_output_state_derivatives',
            order: 4,
          },
          {
            name: 'orientation_derivative',
            condition_bool_parameter: 'enable_output_state_derivatives',
            order: 5,
          },
          {
            name: 'velocity_derivative',
            condition_bool_parameter: 'enable_output_state_derivatives',
            order: 6,
          },
          {
            name: 'angular_velocity_derivative',
            condition_bool_parameter: 'enable_output_state_derivatives',
            order: 7,
          },
        ],
      },
    },
    parameter_definitions: [
      {
        name: 'initial_position',
        data_type: 'any',
        default_value: '[0.0, 0.0, 0.0]',
        description: 'Initial position in inertial frame.',
      },
      {
        name: 'initial_orientation',
        data_type: 'any',
        default_value: '[0.0, 0.0, 0.0]',
        description: 'Initial roll-pitch-yaw orientation.',
      },
      {
        name: 'initial_velocity',
        data_type: 'any',
        default_value: '[0.0, 0.0, 0.0]',
        description: 'Initial velocity in the body frame.',
      },
      {
        name: 'initial_angular_velocity',
        data_type: 'any',
        default_value: '[0.0, 0.0, 0.0]',
        description: 'Initial angular velocity in the body frame.',
      },
      {
        name: 'enable_external_mass',
        data_type: 'bool',
        default_value: 'false',
        description:
          'Source for the value used as the mass of the body. True=from inport, False=from the mass parameter.',
      },
      {
        name: 'mass',
        data_type: 'float',
        default_value: '1.0',
        description: 'Mass of the body.',
      },
      {
        name: 'enable_external_inertia_matrix',
        data_type: 'bool',
        default_value: 'false',
        description:
          'Source for the value used as the inertia matrix of the body. True=from inport, False=from the inertia matrix parameter.',
      },
      {
        name: 'inertia_matrix',
        data_type: 'any',
        default_value: '[[1.0,0.0,0.0],[0.0,1.0,0.0],[0.0,0.0,1.0]]',
        description: 'The rotational inertia matrix.',
      },
      {
        name: 'enable_output_state_derivatives',
        data_type: 'bool',
        default_value: 'false',
        description:
          'User selection for enabling the output port for the blocks state derivatives as a vector.',
      },
      {
        name: 'gravity_vector',
        data_type: 'any',
        default_value: '[0.0, 0.0, 0.0]',
        description: 'Gravity vector in the inertial frame.',
      },
    ],
  },
  'core.Ros2Publisher': {
    $schema: '../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/core/ros2_publisher.json',
    base: {
      name: 'Ros2Publisher',
      namespace: 'core',
      description: 'Publish messages to a ROS2 network to control a robot.',
      feature_level: 'dev',
    },
    modes: {
      time: 'discrete',
    },
    ports: {
      inputs: {
        dynamic: {
          default_count: 1,
          max_count: -1,
          min_count: 0,
          parameter_definitions: [
            {
              name: 'field_name',
              data_type: 'string',
              default_value: 'linear.x',
              display_full_width: true,
            },
            {
              name: 'dtype',
              data_type: 'string',
              value_is_a_type: true,
              default_value: 'float64',
              display_full_width: true,
            },
          ],
        },
      },
    },
    parameter_definitions: [
      {
        name: 'topic',
        data_type: 'string',
        default_value: '/turtle1/cmd_vel',
      },
      {
        name: 'msg_type',
        display_name: 'Message Type',
        $comment: 'FIXME UI should allow for user-defined strings here',
        description: 'Type name of the message to send. Select from the list.',
        data_type: 'string',
        default_value: 'geometry_msgs/msg/Twist',
        allowed_values: ['geometry_msgs/msg/Twist'],
      },
    ],
  },
  'core.Ros2Subscriber': {
    $schema: '../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/core/ros2_subscriber.json',
    base: {
      name: 'Ros2Subscriber',
      namespace: 'core',
      description: 'Listen to messages on a ROS2 topic.',
      feature_level: 'dev',
    },
    modes: {
      time: 'discrete',
    },
    ports: {
      outputs: {
        dynamic: {
          default_count: 1,
          max_count: -1,
          min_count: 0,
          parameter_definitions: [
            {
              name: 'field_name',
              data_type: 'string',
              default_value: 'x',
              display_full_width: true,
            },
            {
              name: 'dtype',
              data_type: 'string',
              value_is_a_type: true,
              default_value: 'float64',
              display_full_width: true,
            },
          ],
        },
      },
    },
    parameter_definitions: [
      {
        name: 'topic',
        data_type: 'string',
        default_value: '/turtle1/pose',
      },
      {
        name: 'msg_type',
        display_name: 'Message Type',
        $comment: 'FIXME UI should allow for user-defined strings here',
        description:
          'Type name of the message to listen to. Select from the list.',
        data_type: 'string',
        default_value: 'turtlesim/msg/Pose',
        allowed_values: ['turtlesim/msg/Pose', 'geometry_msgs/msg/Twist'],
      },
      {
        name: 'read_before_start',
        description:
          'Read the latest message on the topic before starting the simulation.',
        data_type: 'bool',
        default_value: 'true',
      },
    ],
  },
  'core.Saturate': {
    $schema: '../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/base/discontinuities/saturate.json',
    base: {
      name: 'Saturate',
      namespace: 'core',
      description:
        'Passes through the input but constrains the output to be between the upper and lower limits.',
      help_url:
        'https://docs.collimator.ai/using-model-editors/block-library/agnostic-blocks#saturate',
    },
    modes: {
      time: 'agnostic',
    },
    events: {
      count: 2,
    },
    ports: {
      inputs: {
        static: [
          {
            name: 'in_0',
          },
        ],
        conditional: [
          {
            name: 'upper_limit',
            condition_bool_parameter: 'enable_dynamic_upper_limit',
            order: 0,
          },
          {
            name: 'lower_limit',
            condition_bool_parameter: 'enable_dynamic_lower_limit',
            order: 2,
          },
        ],
      },
      outputs: {
        static: [
          {
            name: 'out_0',
          },
        ],
      },
    },
    parameter_definitions: [
      {
        name: 'enable_dynamic_upper_limit',
        data_type: 'bool',
        default_value: 'false',
        description:
          'User selection for enabling inport for upper limit value.',
      },
      {
        name: 'upper_limit',
        description: 'upper limit impose on the output',
        data_type: 'float',
        default_value: '1.0',
      },
      {
        name: 'enable_dynamic_lower_limit',
        data_type: 'bool',
        default_value: 'false',
        description:
          'User selection for enabling inport for lower limit value.',
      },
      {
        name: 'lower_limit',
        description: 'lower limit impose on the output',
        data_type: 'float',
        default_value: '-1.0',
      },
    ],
  },
  'core.Sawtooth': {
    $schema: '../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/base/sources/sawtooth.json',
    base: {
      name: 'Sawtooth',
      namespace: 'core',
      description: 'Generates sawtooth wave',
      keywords: ['triangle'],
      help_url:
        'https://docs.collimator.ai/using-model-editors/block-library/agnostic-blocks#sawtooth',
    },
    modes: {
      time: 'any',
    },
    ports: {
      outputs: {
        static: [
          {
            name: 'out_0',
          },
        ],
      },
    },
    parameter_definitions: [
      {
        name: 'amplitude',
        description: 'the amplitude of the wave.',
        data_type: 'float',
        default_value: '1.0',
      },
      {
        name: 'frequency',
        description: 'the frequency of the wave.',
        data_type: 'float',
        default_value: '0.5',
      },
      {
        name: 'phase_delay',
        description:
          'the time dimension offset when the first cycle should start.',
        data_type: 'float',
        default_value: '1.0',
      },
    ],
  },
  'core.ScalarBroadcast': {
    $schema: '../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/base/scalar_broadcast.json',
    base: {
      name: 'ScalarBroadcast',
      namespace: 'core',
      description:
        'take the scalar input and braodcasts it to tensor. the Tensor shape is provided as a pair of parameters.',
      help_url:
        'https://docs.collimator.ai/using-model-editors/block-library/agnostic-blocks#scalar-broadcast',
    },
    modes: {
      time: 'agnostic',
    },
    ports: {
      inputs: {
        static: [
          {
            name: 'in_0',
          },
        ],
      },
      outputs: {
        static: [
          {
            name: 'out_0',
          },
        ],
      },
    },
    parameter_definitions: [
      {
        name: 'm',
        data_type: 'int',
        default_value: '2',
        description: 'number of rows',
        optional: true,
      },
      {
        name: 'n',
        data_type: 'int',
        default_value: '2',
        description: 'number of columns',
        optional: true,
      },
    ],
  },
  'core.SignalDatatypeConversion': {
    $schema: '../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/base/signal_attributes/signal_datatype_conversion.json',
    base: {
      name: 'SignalDatatypeConversion',
      namespace: 'core',
      description:
        'This block converts the the input signal to the required data type provided by the user.',
    },
    modes: {
      time: 'agnostic',
    },
    ports: {
      inputs: {
        static: [
          {
            name: 'in_0',
          },
        ],
      },
      outputs: {
        static: [
          {
            name: 'out_0',
          },
        ],
      },
    },
    parameter_definitions: [
      {
        name: 'convert_to_type',
        data_type: 'string',
        default_value: 'float64',
        description: 'data type of the outputs',
        value_is_a_type: true,
      },
    ],
  },
  'core.SINDy': {
    $schema: '../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/base/sources/sindy.json',
    base: {
      name: 'SINDy',
      namespace: 'core',
      description: 'Block learns system dynamics from CSV data using PySINDy.',
      feature_level: 'dev',
    },
    modes: {
      time: 'agnostic',
    },
    ports: {
      outputs: {
        static: [
          {
            name: 'out_0',
          },
        ],
      },
    },
    parameter_definitions: [
      {
        name: 'file_name',
        description: 'Name of the file to use as a data source.',
        data_type: 'string',
        default_value: '',
      },
      {
        name: 'header_as_first_row',
        description: 'Check this box if the file has a header.',
        data_type: 'bool',
        default_value: 'false',
      },
      {
        name: 'discrete_time',
        description: 'Check this box to train a discrete-time SINDy model',
        data_type: 'bool',
        default_value: 'false',
      },
      {
        name: 'state_columns',
        description:
          'Enter a list of column names/indices/slice where the state value(s) are',
        data_type: 'string',
        default_value: '["some_state"]',
      },
      {
        name: 'control_input_columns',
        description:
          'Enter a list of column names/indices/slice where the input value(s) are',
        data_type: 'string',
        default_value: '',
      },
      {
        name: 'dt',
        data_type: 'float',
        default_value: '1e-5',
        description: 'Fixed time-interval between rows of CSV files',
      },
      {
        name: 'time_column',
        description: 'Enter the name/index of the time column',
        data_type: 'string',
        default_value: 'time',
      },
      {
        name: 'state_derivatives_columns',
        description:
          'Enter a list of column names/indices/slice where the state derivative value(s) are. Must be same order as state names.',
        data_type: 'string',
        default_value: '',
      },
      {
        name: 'differentiation_method',
        description: 'Method to approximate state derivatives',
        data_type: 'string',
        hidden: true,
        default_value: 'centered difference',
        allowed_values: ['centered difference'],
      },
      {
        name: 'threshold',
        data_type: 'float',
        default_value: '0.1',
        description:
          'Threshold for Sequentially Thresholded Least Squares (STLSQ) algorithm',
      },
      {
        name: 'alpha',
        data_type: 'float',
        default_value: '0.05',
        description: 'Regularization strength for STLSQ',
      },
      {
        name: 'max_iter',
        data_type: 'int',
        default_value: '20',
        description: 'Maximization iterations for STLSQ',
      },
      {
        name: 'normalize_columns',
        data_type: 'bool',
        default_value: 'false',
        description:
          'Normalize the columns of the data matrix before regression',
      },
      {
        name: 'poly_order',
        data_type: 'int',
        default_value: '2',
        description: 'Highest polynomial order in regression library',
      },
      {
        name: 'fourier_n_frequencies',
        data_type: 'int',
        default_value: '',
        description:
          'Highest order of Fourier frequencies in regression library',
      },
      {
        name: 'initial_state',
        data_type: 'any',
        default_value: '[0]',
        description:
          'Initial_values of the states; must be same order as state names.',
      },
      {
        name: 'discrete_time_update_interval',
        data_type: 'float',
        default_value: '1.0',
        description:
          'Time interval for discrete updates if a discrete-time model is trained',
      },
      {
        name: 'equations',
        description: 'String representation of the equations.',
        data_type: 'any',
        default_value: '',
        read_only: true,
      },
      {
        name: 'base_feature_names',
        description: 'Base feature names of the equations.',
        data_type: 'any',
        default_value: '',
        hidden: true,
        read_only: true,
      },
      {
        name: 'feature_names',
        description: 'Feature names of the equations.',
        data_type: 'any',
        default_value: '',
        hidden: true,
        read_only: true,
      },
      {
        name: 'coefficients',
        description: 'Coefficients of the equations.',
        data_type: 'any',
        default_value: '',
        hidden: true,
        read_only: true,
      },
      {
        name: 'has_control_input',
        description: 'Whether the model has control inputs.',
        data_type: 'bool',
        default_value: 'true',
        hidden: true,
        read_only: true,
      },
    ],
  },
  'core.SineWave': {
    $schema: '../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/base/sources/sine_wave.json',
    base: {
      name: 'SineWave',
      namespace: 'core',
      description:
        'Generates a sine wave based on the simulation time.\n\ny(t) = amplitude * sin(t*frequency + phase) + bias.',
      help_url:
        'https://docs.collimator.ai/using-model-editors/block-library/agnostic-blocks#sine-wave',
    },
    modes: {
      time: 'any',
    },
    ports: {
      outputs: {
        static: [
          {
            name: 'out_0',
          },
        ],
      },
    },
    parameter_definitions: [
      {
        name: 'frequency',
        description: 'Frequency in rad/s.',
        data_type: 'float',
        default_value: '1.0',
      },
      {
        name: 'amplitude',
        description: 'Amplitude of the signal.',
        data_type: 'float',
        default_value: '1.0',
      },
      {
        name: 'phase',
        description: 'Phase shift in rad.',
        data_type: 'float',
        default_value: '0.0',
      },
      {
        name: 'bias',
        description: 'Constant bias added to the signal.',
        data_type: 'float',
        default_value: '0.0',
      },
    ],
  },
  'core.Slice': {
    $schema: '../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/base/maths/slice.json',
    base: {
      name: 'Slice',
      namespace: 'core',
      description: 'Slice arrays like np.array[1:4,:,:] etc.',
    },
    modes: {
      time: 'agnostic',
    },
    ports: {
      inputs: {
        static: [
          {
            name: 'in_0',
          },
        ],
      },
      outputs: {
        static: [
          {
            name: 'out_0',
          },
        ],
      },
    },
    parameter_definitions: [
      {
        name: 'slice_',
        data_type: 'string',
        default_value: '',
        description: 'The slice operation, e.g. [1:4,:]',
      },
    ],
  },
  'core.SquareRoot': {
    $schema: '../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/base/maths/square_root.json',
    base: {
      name: 'SquareRoot',
      namespace: 'core',
      description: 'Outputs the square root of the inputs.',
      help_url:
        'https://docs.collimator.ai/using-model-editors/block-library/agnostic-blocks#square-root',
    },
    modes: {
      time: 'agnostic',
    },
    ports: {
      inputs: {
        static: [
          {
            name: 'in_0',
          },
        ],
      },
      outputs: {
        static: [
          {
            name: 'out_0',
          },
        ],
      },
    },
  },
  'core.Stack': {
    $schema: '../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/base/maths/stack.json',
    base: {
      name: 'Stack',
      namespace: 'core',
      description: 'Stacks arrays like np.stack.',
      keywords: [
        'vector',
        'matrix',
        'stack',
        'hstack',
        'vstack',
        'row_stack',
        'column_stack',
        'dstack',
        'join',
        'mux',
      ],
    },
    modes: {
      time: 'agnostic',
    },
    ports: {
      inputs: {
        dynamic: {
          min_count: 2,
          max_count: -1,
          default_count: 2,
        },
      },
      outputs: {
        static: [
          {
            name: 'out_0',
          },
        ],
      },
    },
    parameter_definitions: [
      {
        name: 'axis',
        description: 'Same as np.stack axis argument',
        data_type: 'int',
        default_value: '0',
      },
    ],
  },
  'core.StateMachine': {
    $schema: '../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/base/user_defined/state_machine.json',
    base: {
      name: 'StateMachine',
      namespace: 'core',
      help_url:
        'https://docs.collimator.ai/using-model-editors/block-library/state-machine',
      description:
        'Executes a user defined state machine designed using pytransitions to compute the outputs from the inputs.',
    },
    modes: {
      time: 'any',
    },
    ports: {
      inputs: {
        dynamic: {
          min_count: 1,
          max_count: -1,
          default_count: 1,
        },
      },
      outputs: {
        dynamic: {
          min_count: 1,
          max_count: -1,
          default_count: 1,
        },
      },
    },
    parameter_definitions: [
      {
        name: 'accelerate_with_jax',
        display_name: 'Accelerate with JAX',
        data_type: 'bool',
        default_value: 'false',
        description:
          'Use JAX to accelerate simulation. Requires using bitwise operators for logical operations.',
      },
    ],
  },
  'core.StateSpace': {
    $schema: '../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/base/continuous/state_space.json',
    base: {
      name: 'StateSpace',
      namespace: 'core',
      description: 'Models a linear system by State Space representation.',
      help_url:
        'https://docs.collimator.ai/using-model-editors/block-library/continuous-blocks#state-space',
      supports_iterator: false,
    },
    modes: {
      time: 'agnostic',
    },
    ports: {
      inputs: {
        static: [
          {
            name: 'in_0',
          },
        ],
      },
      outputs: {
        static: [
          {
            name: 'out_0',
          },
        ],
      },
    },
    parameter_definitions: [
      {
        name: 'A',
        data_type: 'any',
        default_value: '[[1,0],[0,1]]',
        description:
          'Coefficients of the state space A matrix, which is sort of like a jacobian like this state*A=derivative.',
      },
      {
        name: 'B',
        data_type: 'any',
        default_value: '[0,0]',
        description:
          'Coefficients of the state space B matrix, which is the input matrix like this input*B=derivative.',
      },
      {
        name: 'C',
        data_type: 'any',
        default_value: '[1,1]',
        description:
          'Coefficients of the state space C matrix, which is the output matrix like state*C=output.',
      },
      {
        name: 'D',
        data_type: 'any',
        default_value: '[0]',
        description:
          'Coefficients of the state space D matrix, which is feedthrough matrix like this output=D*input.',
      },
      {
        name: 'initial_states',
        data_type: 'float',
        default_value: '0.0',
        description: 'initial value of state space system state vector',
      },
    ],
  },
  'core.Step': {
    $schema: '../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/base/sources/step.json',
    base: {
      name: 'Step',
      namespace: 'core',
      description: 'Generates a single step output, aka. Heaviside function.',
      help_url:
        'https://docs.collimator.ai/using-model-editors/block-library/agnostic-blocks#step',
    },
    modes: {
      time: 'any',
    },
    events: {
      count: 1,
    },
    ports: {
      outputs: {
        static: [
          {
            name: 'out_0',
          },
        ],
      },
    },
    parameter_definitions: [
      {
        name: 'start_value',
        description: 'Output value before jump.',
        data_type: 'float',
        default_value: '0.0',
      },
      {
        name: 'end_value',
        description: 'Output value at and after jump.',
        data_type: 'float',
        default_value: '1.0',
      },
      {
        name: 'step_time',
        description:
          'Time in seconds when the output jumps from `start_value` to `end_value`.',
        data_type: 'float',
        default_value: '1.0',
      },
    ],
  },
  'core.Stop': {
    $schema: '../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/base/sources/stop.json',
    base: {
      name: 'Stop',
      namespace: 'core',
      description:
        'When true stop simulation at end of major or minor step, which ever comes first..',
    },
    modes: {
      time: 'any',
    },
    ports: {
      inputs: {
        static: [
          {
            name: 'in_0',
          },
        ],
      },
    },
  },
  'core.Submodel': {
    $schema: '../../block-class.schema.json',
    base: {
      name: 'Submodel',
      namespace: 'core',
      description: 'This is a stub to allow the submodel to render',
      extra_parameters: true,
      help_url:
        'https://docs.collimator.ai/using-model-editors/using-submodels',
      hidden: true,
    },
    modes: {
      time: 'any',
    },
    ports: {
      has_automatic_ports: true,
    },
  },
  'core.SumOfElements': {
    $schema: '../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/base/matrix_operations/sum_of_elements.json',
    base: {
      name: 'SumOfElements',
      namespace: 'core',
      description:
        'This block outputs the summation of the elements of a matrix.',
      help_url:
        'https://docs.collimator.ai/using-model-editors/block-library/agnostic-blocks#matrix-operator',
    },
    modes: {
      time: 'agnostic',
    },
    ports: {
      inputs: {
        static: [
          {
            name: 'in_0',
          },
        ],
      },
      outputs: {
        static: [
          {
            name: 'out_0',
          },
        ],
      },
    },
  },
  'core.TensorFlow': {
    $schema: '../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/core/tensorflow.json',
    base: {
      name: 'TensorFlow',
      namespace: 'core',
      description: 'Prediction (Inference) with TensorFlow SavedModel.',
      help_url:
        'https://docs.collimator.ai/using-model-editors/block-library/using-the-predictor-block',
    },
    modes: {
      time: 'discrete',
    },
    ports: {
      inputs: {
        dynamic: {
          min_count: 1,
          default_count: 1,
          max_count: -1,
        },
      },
      outputs: {
        dynamic: {
          min_count: 1,
          default_count: 1,
          max_count: -1,
        },
      },
    },
    parameter_definitions: [
      {
        name: 'file_name',
        data_type: 'string',
        default_value: '',
        description: 'TensorFlow SavedModel zip file to load.',
      },
      {
        name: 'add_batch_dim_to_inputs',
        display_name: 'Auto add batch dimension',
        data_type: 'bool',
        default_value: 'false',
        description:
          'Automatically add new first dimension to the input tensors for batch processing.',
      },
    ],
  },
  'core.TransferFunction': {
    $schema: '../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/base/continuous/transfer_function.json',
    base: {
      name: 'TransferFunction',
      namespace: 'core',
      description:
        'Models a linear system by a transfer function of the Laplace-domain variable s.',
      development_notes:
        'the order of the denominator must be greater than the numerator. leading zeros in either num or den should be pruned.',
      help_url:
        'https://docs.collimator.ai/using-model-editors/block-library/continuous-blocks#transfer-function',
      supports_iterator: false,
    },
    modes: {
      time: 'agnostic',
    },
    ports: {
      inputs: {
        static: [
          {
            name: 'in_0',
          },
        ],
      },
      outputs: {
        static: [
          {
            name: 'out_0',
          },
        ],
      },
    },
    parameter_definitions: [
      {
        name: 'numerator_coefficients',
        data_type: 'any',
        default_value: '[1]',
        description:
          'Coefficients of the polynomial of Laplace variable s for the numerator of the transfer function.',
      },
      {
        name: 'denominator_coefficients',
        data_type: 'any',
        default_value: '[1, 1]',
        description:
          'Coefficients of the polynomial of Laplace variable s for the denominator of the transfer function.',
      },
    ],
  },
  'core.TransferFunctionDiscrete': {
    $schema: '../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/base/discrete/transfer_function_discrete.json',
    base: {
      name: 'TransferFunctionDiscrete',
      namespace: 'core',
      description:
        'Models a linear system by a transfer function of the discrete time system variable z.',
      development_notes:
        'the order of the denominator must be greater than the numerator. leading zeros in either num or den should be pruned.',
      help_url:
        'https://docs.collimator.ai/using-model-editors/block-library/discrete-blocks#transfer-function-discrete',
      supports_iterator: false,
    },
    modes: {
      time: 'discrete',
    },
    ports: {
      inputs: {
        static: [
          {
            name: 'in_0',
          },
        ],
      },
      outputs: {
        static: [
          {
            name: 'out_0',
          },
        ],
      },
    },
    parameter_definitions: [
      {
        name: 'numerator_coefficients',
        data_type: 'any',
        default_value: '[1]',
        description:
          'Coefficients of the polynomial of discrete time variable z for the numerator of the transfer function.',
      },
      {
        name: 'denominator_coefficients',
        data_type: 'any',
        default_value: '[1, 1]',
        description:
          'Coefficients of the polynomial of discrete time variable z for the denominator of the transfer function.',
      },
    ],
  },
  'core.Trigonometric': {
    $schema: '../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/base/maths/trigonometric.json',
    base: {
      name: 'Trigonometric',
      namespace: 'core',
      description:
        'This block outputs the result of a trigonometric function based on the input, e.g. sin(x).',
      keywords: [
        'sin',
        'cos',
        'tan',
        'sine',
        'cosine',
        'tangent',
        'sinh',
        'cosh',
        'tanh',
        'hyperbolic',
        'arcsin',
        'arccos',
        'arctan',
        'arcsinh',
        'arccosh',
        'arctanh',
        'asin',
        'acos',
        'atan',
        'asinh',
        'acosh',
        'atanh',
        'trigonometric',
        'trigonometry',
      ],
      help_url:
        'https://docs.collimator.ai/using-model-editors/block-library/agnostic-blocks#trigonometric',
    },
    modes: {
      time: 'agnostic',
    },
    ports: {
      inputs: {
        static: [
          {
            name: 'in_0',
          },
        ],
        conditional: [
          {
            name: 'y',
            order: 1,
          },
        ],
      },
      outputs: {
        static: [
          {
            name: 'out_0',
          },
        ],
      },
    },
    parameter_definitions: [
      {
        name: 'function',
        data_type: 'string',
        default_value: 'sin',
        description: 'Trigonometric function',
        allowed_values: [
          'sin',
          'cos',
          'tan',
          'asin',
          'acos',
          'atan',
          'sinh',
          'cosh',
          'tanh',
          'asinh',
          'acosh',
          'atanh',
        ],
      },
    ],
  },
  'core.UnitDelay': {
    $schema: '../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/base/discrete/unit_delay.json',
    base: {
      name: 'UnitDelay',
      namespace: 'core',
      description:
        'Adds a delay of one time step between the input and the output.',
      help_url:
        'https://docs.collimator.ai/using-model-editors/block-library/discrete-blocks#unit-delay-discrete',
      supports_iterator: false,
    },
    modes: {
      time: 'discrete',
    },
    ports: {
      inputs: {
        static: [
          {
            name: 'in_0',
          },
        ],
      },
      outputs: {
        static: [
          {
            name: 'out_0',
          },
        ],
      },
    },
    parameter_definitions: [
      {
        name: 'initial_state',
        data_type: 'float',
        default_value: '0.0',
        description: 'initial value of unit delay state.',
      },
    ],
  },
  'core.UnscentedKalmanFilter': {
    $schema: '../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/base/discrete/unscented_kalman_filter.json',
    base: {
      name: 'UnscentedKalmanFilter',
      namespace: 'core',
      description: 'Unscented Kalman Filter for a continuous-time plant',
      help_url:
        'https://docs.collimator.ai/using-model-editors/block-library/discrete-blocks#unscented-kalman-filter',
      supports_iterator: false,
      keywords: ['kf'],
    },
    modes: {
      time: 'discrete',
    },
    ports: {
      inputs: {
        static: [
          {
            name: 'u',
          },
          {
            name: 'y',
          },
        ],
      },
      outputs: {
        static: [
          {
            name: 'x_hat',
          },
        ],
      },
    },
    parameter_definitions: [
      {
        name: 'plant_submodel_uuid',
        data_type: 'reference_submodel',
        description:
          'Continuous-time plant to linearize and discretize for Unscented Kalman Filter creation',
        default_value: '',
        display_name: 'Plant Submodel',
        optional: true,
      },
      {
        name: 'discretization_method',
        description: 'Discretization method for the continuous-time plant',
        data_type: 'string',
        default_value: 'euler',
        optional: true,
        allowed_values: ['euler', 'zoh'],
      },
      {
        name: 'dt',
        data_type: 'float',
        description:
          'Sampling rate for discretization of the continuous-time plant',
        optional: true,
        default_value: '',
      },
      {
        name: 'discretized_noise',
        display_name: 'Provided noise statistics are discretized?',
        description:
          'Check this box if the G, Q, and R matrices are already discretized',
        data_type: 'bool',
        default_value: 'false',
      },
      {
        name: 'G',
        data_type: 'any',
        default_value: '',
        optional: true,
        description: 'Process noise matrix G for the plant',
      },
      {
        name: 'Q',
        data_type: 'any',
        default_value: '',
        optional: true,
        description: 'Process noise covariance matrix Q for the plant',
      },
      {
        name: 'R',
        data_type: 'any',
        default_value: '',
        optional: true,
        description: 'Measurement noise covariance matrix R for the plant',
      },
      {
        name: 'x_hat_0',
        data_type: 'float',
        default_value: '',
        optional: true,
        description: 'Initial value for the state vector estimate',
      },
      {
        name: 'P_hat_0',
        data_type: 'float',
        default_value: '',
        optional: true,
        description:
          'Initial value for covariance of the state vector estimate',
      },
    ],
  },
  'core.VideoSink': {
    $schema: '../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/base/sources/video_sink.json',
    base: {
      name: 'VideoSink',
      namespace: 'core',
      description: 'Output to a video.',
      help_url:
        'https://docs.collimator.ai/using-model-editors/block-library/discrete-blocks#videosink',
      feature_level: 'dev',
    },
    modes: {
      time: 'discrete',
    },
    ports: {
      inputs: {
        static: [
          {
            name: 'frame',
          },
        ],
      },
      outputs: {
        static: [
          {
            name: 'frame_id',
          },
        ],
      },
    },
    parameter_definitions: [
      {
        name: 'dt',
        display_name: 'Frame Interval',
        description: 'Interval between frames',
        data_type: 'float',
        default_value: '0.1',
      },
    ],
    file_outputs: [
      {
        filename: '{{block_name}}.mp4',
        type: 'video/mp4',
      },
    ],
  },
  'core.VideoSource': {
    $schema: '../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/base/sources/video_source.json',
    base: {
      name: 'VideoSource',
      namespace: 'core',
      description:
        'Loads a video file and outputs it frame by frame. Output is a tensor of shape (H,W,3) in RGB color space.',
      help_url:
        'https://docs.collimator.ai/using-model-editors/block-library/discrete-blocks#videosource',
      feature_level: 'dev',
    },
    modes: {
      time: 'discrete',
    },
    ports: {
      outputs: {
        static: [
          {
            name: 'frame',
          },
          {
            name: 'frame_id',
          },
        ],
        conditional: [
          {
            name: 'stopped',
            condition_bool_parameter: 'no_repeat',
            order: 2,
          },
        ],
      },
    },
    parameter_definitions: [
      {
        name: 'file_name',
        description: 'Name of the video file to play',
        data_type: 'file',
        file_formats: ['*.avi', '*.mp4', '*.mpeg4', '*.webp'],
        default_value: '',
      },
      {
        name: 'no_repeat',
        description:
          'If true, the video will stop after playing once. If false, the video will loop indefinitely.',
        data_type: 'bool',
        default_value: 'false',
      },
    ],
  },
  'core.WhiteNoise': {
    $schema: '../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/base/sources/white_noise.json',
    base: {
      name: 'WhiteNoise',
      namespace: 'core',
      description: 'Generates a band-limited white noise signal.',
      help_url:
        'https://docs.collimator.ai/using-model-editors/block-library/continuous-blocks#white-noise',
    },
    modes: {},
    ports: {
      outputs: {
        static: [
          {
            name: 'out_0',
          },
        ],
      },
    },
    parameter_definitions: [
      {
        name: 'correlation_time',
        data_type: 'float',
        description:
          'Sample rate of the underlying random number generator. If empty, matches the global clock tick.',
        optional: true,
        default_value: '',
      },
      {
        name: 'noise_power',
        data_type: 'float',
        default_value: '1.0',
        description: 'Variance of the noise signal',
      },
      {
        name: 'num_samples',
        data_type: 'int',
        default_value: '10',
        description:
          'The number of samples to use for sinc interpolation.  More samples will result in a more accurate approximation of the ideal power spectrum, but will also increase the computational cost.  The default of 10 is sufficient for most applications.',
      },
      {
        name: 'seed',
        data_type: 'float',
        description:
          'An integer seed for the random number generator. If empty, a random 32-bit seed will be generated.',
        optional: true,
        default_value: '',
      },
      {
        name: 'dtype',
        data_type: 'string',
        default_value: 'float64',
        allowed_values: ['float16', 'float32', 'float64', 'float128'],
        description: 'Data type of the signal',
      },
      {
        name: 'shape',
        data_type: 'any',
        default_value: '()',
        description: 'Array shape of the signal',
      },
    ],
  },
  'core.ZeroOrderHold': {
    $schema: '../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/base/discrete/zero_order_hold.json',
    base: {
      name: 'ZeroOrderHold',
      namespace: 'core',
      description:
        'sample input at update, then holds output constant over one sample time period.',
      help_url:
        'https://docs.collimator.ai/using-model-editors/block-library/discrete-blocks#zero-order-hold-discrete',
    },
    modes: {
      time: 'discrete',
    },
    ports: {
      inputs: {
        static: [
          {
            name: 'in_0',
          },
        ],
      },
      outputs: {
        static: [
          {
            name: 'out_0',
          },
        ],
      },
    },
  },
  'quanser.QuanserHAL': {
    $schema: '../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/quanser/quanser_hal.json',
    base: {
      name: 'QuanserHAL',
      namespace: 'quanser',
      description:
        'Hardware Abstraction Layer to virtual or physical Quanser systems.  Can only be run locally with connection to hardware.',
      help_url: 'https://docs.collimator.ai/using-model-editors/block-library/',
      feature_level: 'dev',
    },
    modes: {
      time: 'agnostic',
    },
    ports: {
      inputs: {
        static: [
          {
            name: 'voltage',
          },
        ],
      },
      outputs: {
        static: [
          {
            name: 'state_measurements',
          },
        ],
      },
    },
    parameter_definitions: [
      {
        name: 'hardware',
        data_type: 'bool',
        default_value: 'true',
        description:
          'Use physical hardware (true) or virtual hardware (false).',
      },
    ],
  },
  'quanser.QubeServoModel': {
    $schema: '../../block-class.schema.json',
    $id: 'https://collimator.ai/models/blocks/quanser/qube_servo_model.json',
    base: {
      name: 'QubeServoModel',
      namespace: 'quanser',
      description: 'Idealized model of the Qube Servo rotary pendulum.',
      help_url: 'https://docs.collimator.ai/using-model-editors/block-library/',
      feature_level: 'dev',
    },
    modes: {
      time: 'agnostic',
    },
    ports: {
      inputs: {
        static: [
          {
            name: 'u',
          },
        ],
      },
      outputs: {
        static: [
          {
            name: 'y',
          },
        ],
      },
    },
    parameter_definitions: [
      {
        name: 'x0',
        data_type: 'float',
        default_value: '[0.0, 0.0, 0.0, 0.0]',
        description:
          'Initial state vector [theta, alpha, theta_dot, alpha_dot]',
      },
      {
        name: 'Rm',
        data_type: 'float',
        default_value: '8.4',
        description: 'Motor resistance (Ohms)',
      },
      {
        name: 'km',
        data_type: 'float',
        default_value: '0.042',
        description: 'Back-EMF constant (V-s/rad)',
      },
      {
        name: 'mr',
        data_type: 'float',
        default_value: '0.095',
        description: 'Rotor arm mass (kg)',
      },
      {
        name: 'Lr',
        data_type: 'float',
        default_value: '0.085',
        description: 'Rotor arm length (m)',
      },
      {
        name: 'br',
        data_type: 'float',
        default_value: '0.0005',
        description: 'Damping constant for the rotor arm (N-m-s/rad)',
      },
      {
        name: 'mp',
        data_type: 'float',
        default_value: '0.024',
        description: 'Mass of the pendulum arm (kg)',
      },
      {
        name: 'Lp',
        data_type: 'float',
        default_value: '0.129',
        description: 'Length of the pendulum arm (m)',
      },
      {
        name: 'bp',
        data_type: 'float',
        default_value: '0.000025',
        description: 'Damping constant for the pendulum arm (N-m-s/rad)',
      },
      {
        name: 'g',
        data_type: 'float',
        default_value: '9.81',
        description: 'Gravity constant (m/s^2)',
      },
      {
        name: 'kr',
        data_type: 'float',
        default_value: '0.0',
        description:
          'Proportional gain modeling tendency to return to zero position',
      },
      {
        name: 'full_state_output',
        data_type: 'bool',
        default_value: 'false',
        description:
          'Output the full state instead of only the angular positions',
      },
    ],
  },
};

export default BlockDocMap;
