import React, { useState } from 'react';

export const usePlotCache = (storageKey?: string) => {
  const [plots, setPlots] = useState<string[]>([]);

  const _addPlot = React.useCallback((plot: string) => {
    let newIndex = 0;
    setPlots((prevPlots) => {
      newIndex = prevPlots.length;
      return [...prevPlots, plot];
    });
    return newIndex;
  }, []);

  const removePlot = React.useCallback((index: number) => {
    setPlots((prevPlots) => {
      const newPlots = [...prevPlots];
      newPlots.splice(index, 1);
      return newPlots;
    });
  }, []);

  React.useEffect(() => {
    try {
      if (!storageKey) return;
      const savedOutput = localStorage.getItem(storageKey) || '';
      if (!savedOutput) return;
      const savedOutputParsed = JSON.parse(savedOutput);
      if (savedOutputParsed) {
        setPlots(savedOutputParsed);
      }
    } catch (e) {
      console.error('Error loading saved chat output', e);
    }
  }, [storageKey, setPlots]);

  const addPlot = React.useCallback(
    (plot: string): number => {
      const index = _addPlot(plot);
      if (!storageKey) return index;
      localStorage.setItem(storageKey, JSON.stringify([...plots, plot]));
      return index;
    },
    [_addPlot, storageKey, plots],
  );

  return { plots, setPlots, addPlot, removePlot };
};
