import { ClerkLoaded, ClerkProvider } from '@clerk/clerk-react';
import React from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import SignInPage from './SignInPage';
import SignUpPage from './SignUpPage';
import SignedIn from './SignedIn';
import SignedUp from './SignedUp';

const clerkPubKey = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY || '';

// these are routes that Clerk would navigate to on log-out
const refreshRoutes = ['/login/sign-in', '/login/sign-up'];

// This is a separate that gets mounted under /login route to simplify
// the Auth flow. Mounting SignInPage/SignUpPage within the AuthProvider
// was confusing Clerk and it couldn't find the mounted internal component
// after a successful sign in/sign up.
// All the customization of the flow would reside in this component.
const LoginProvider: React.FC = ({}) => {
  const navigate = useNavigate();

  return (
    <ClerkProvider
      publishableKey={clerkPubKey}
      navigate={(to) => {
        // sometimes we want to hard-refresh to fully clear the app state
        // (for example, when a user logs out)
        if (refreshRoutes.includes(to)) {
          window.location.href = to;
        } else {
          navigate(to);
        }
      }}>
      <ClerkLoaded>
        <Routes>
          <Route path="/sign-in/*" element={<SignInPage />} />
          <Route path="/sign-up/*" element={<SignUpPage />} />
          <Route path="/sign-in-success" element={<SignedIn />} />
          <Route path="/sign-up-success" element={<SignedUp />} />
        </Routes>
      </ClerkLoaded>
    </ClerkProvider>
  );
};

export default LoginProvider;
