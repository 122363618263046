/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const LogicOr: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M5.78391 8.13473C5.33735 7.31119 5.91517 6.24432 6.9054 6.25462C11.3792 6.30114 15.7858 6.71201 19.0943 8.15704C20.7609 8.88493 22.1841 9.88997 23.1892 11.275C24.0785 12.5003 24.6114 13.9831 24.7264 15.75H28C28.4142 15.75 28.75 16.0858 28.75 16.5C28.75 16.9142 28.4142 17.25 28 17.25H24.7247C24.6065 18.9886 24.0772 20.4188 23.187 21.5738C22.1763 22.8851 20.749 23.7791 19.0912 24.391C15.8072 25.6031 11.4273 25.7731 6.99894 25.7478C5.95393 25.7418 5.41795 24.5701 5.93345 23.7607C6.36922 23.0764 6.97114 22.0188 7.46344 20.75H4C3.58579 20.75 3.25 20.4142 3.25 20C3.25 19.5858 3.58579 19.25 4 19.25H7.95437C8.18633 18.3749 8.33572 17.4412 8.33572 16.4883C8.33572 15.5849 8.2021 14.6551 7.99107 13.75H4C3.58579 13.75 3.25 13.4142 3.25 13C3.25 12.5858 3.58579 12.25 4 12.25H7.56971C7.00715 10.5241 6.26085 9.01432 5.78391 8.13473ZM7.28364 7.75963C8.19859 9.50685 9.83572 13.0939 9.83572 16.4883C9.83572 19.7412 8.33795 22.7024 7.39601 24.2495C11.6981 24.2618 15.6647 24.0568 18.5718 22.9838C20.0533 22.437 21.2101 21.6816 21.9989 20.6581C22.7814 19.6428 23.25 18.3013 23.25 16.4883C23.25 14.6686 22.7776 13.2616 21.9752 12.156C21.1676 11.0431 19.9878 10.1841 18.4939 9.53165C15.5706 8.25485 11.5811 7.82613 7.28364 7.75963Z"
      fill={props.fill}
    />
  </svg>
);

LogicOr.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default LogicOr;
