/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  className?: string;
}

const Switch: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.5 4C5.22386 4 5 4.22386 5 4.5C5 4.77614 5.22386 5 5.5 5H15.5V6.5H11.5C10.9477 6.5 10.5 6.94772 10.5 7.5V8.5H11.5V7.5H20.5V14H21.5V7.5C21.5 6.94772 21.0523 6.5 20.5 6.5H16.5V5C16.5 4.44772 16.0523 4 15.5 4H5.5Z"
      fill={props.fill}
    />
    <path
      d="M5.5 9.75C5.08579 9.75 4.75 10.0858 4.75 10.5C4.75 10.9142 5.08579 11.25 5.5 11.25H13.1682L18.1682 16.75H24.6893L22.9697 18.4697C22.6768 18.7626 22.6768 19.2374 22.9697 19.5303C23.2626 19.8232 23.7374 19.8232 24.0303 19.5303L27.5607 16L24.0303 12.4697C23.7374 12.1768 23.2626 12.1768 22.9697 12.4697C22.6768 12.7626 22.6768 13.2374 22.9697 13.5303L24.6893 15.25H18.8318L14.0569 9.99766L14.0472 9.98709C13.9128 9.84375 13.7227 9.75325 13.5114 9.75009L4.75 10.5L13.4969 9.75H5.5Z"
      fill={props.fill}
    />
    <path
      d="M5 16C5 15.7239 5.22386 15.5 5.5 15.5H13.5C13.7761 15.5 14 15.7239 14 16C14 16.2761 13.7761 16.5 13.5 16.5H5.5C5.22386 16.5 5 16.2761 5 16Z"
      fill={props.fill}
    />
    <path
      d="M5.5 21C5.22386 21 5 21.2239 5 21.5C5 21.7761 5.22386 22 5.5 22H13.5C13.7761 22 14 21.7761 14 21.5C14 21.2239 13.7761 21 13.5 21H5.5Z"
      fill={props.fill}
    />
    <path
      d="M11.5 23V24H20.5V18H21.5V24C21.5 24.5523 21.0523 25 20.5 25H11.5C10.9477 25 10.5 24.5523 10.5 24V23H11.5Z"
      fill={props.fill}
    />
    <path d="M10.5 17.5V20H11.5V17.5H10.5Z" fill={props.fill} />
    <path d="M11.5 14.5V12.5H10.5V14.5H11.5Z" fill={props.fill} />
  </svg>
);

Switch.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  className: '',
};

export default Switch;
