import { NodeInstance } from '@collimator/model-schemas-ts';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { getCurrentModelRef } from 'app/sliceRefAccess/CurrentModelRef';
import { dataExplorerPlotDataActions } from 'app/slices/dataExplorerPlotDataSlice';
import { dataExplorerActions } from 'app/slices/dataExplorerSlice';
import { uiFlagsActions } from 'app/slices/uiFlagsSlice';
import { getNestedNode } from 'app/utils/modelDiagramUtils';
import React from 'react';
import { TraceMetadata } from 'ui/dataExplorer/dataExplorerTypes';
import {
  NodeLocation,
  PortLocation,
  getPortPathName,
} from 'ui/modelEditor/portPathNameUtils';
import { NewPlotRequest } from 'util/plotCellUtils';
import {
  getAreAllNodePortsInVisualizer,
  getAreAnyNodePortsInVisualizer,
  getIsNodePortInVisualizer,
  getIsPortInVisualizer,
} from 'util/signalVisualization';
import { v4 as uuid } from 'uuid';

export function useVisualizerPrefs() {
  const dispatch = useAppDispatch();

  const loadedModelId = useAppSelector(
    (state) => state.modelMetadata.loadedModelId,
  );

  const idToTrace = useAppSelector((state) => state.dataExplorer.idToTrace);

  const getAreAnyNodePortsInChart = React.useCallback(
    ({ nodeId, parentPath }: NodeLocation) =>
      getAreAnyNodePortsInVisualizer({
        nodeId,
        parentPath,
        idToTrace,
      }),
    [idToTrace],
  );

  const getAreAllNodePortsInChart = React.useCallback(
    ({ nodeId, parentPath }: NodeLocation) =>
      getAreAllNodePortsInVisualizer({
        nodeId,
        parentPath,
        idToTrace,
      }),
    [idToTrace],
  );

  const getIsPortInChart = React.useCallback(
    ({ nodeId, parentPath, portIndex }: PortLocation) =>
      getIsPortInVisualizer({
        nodeId,
        parentPath,
        portIndex,
        idToTrace,
      }),
    [idToTrace],
  );

  const getPortsInChartFast = React.useCallback(
    ({
      node,
      parentNamePath,
    }: {
      node: NodeInstance;
      parentNamePath: string[];
    }) =>
      node.outputs.reduce((acc, _port, portIndex) => {
        if (
          getIsNodePortInVisualizer({
            node,
            portIndex,
            parentNamePath,
            idToTrace,
          })
        ) {
          acc.push(portIndex);
        }
        return acc;
      }, [] as number[]),
    [idToTrace],
  );

  const addPortsToChart = React.useCallback(
    (portLocations: PortLocation[], autoShowNormalVis = true) => {
      const newPlots: NewPlotRequest[] = [];

      portLocations.forEach((portLocation) => {
        const signalPath = getPortPathName(
          getCurrentModelRef().topLevelNodes,
          getCurrentModelRef().submodels,
          portLocation,
          { includePortNameForNonSubmodels: true },
        );

        if (!signalPath) return;

        const traceMetadata: TraceMetadata = {
          id: uuid(),
          signalPath,
          tracePath: signalPath,
          displayName: signalPath,
          modelId: loadedModelId,
        };

        // Add all traces for a single signal into
        // their own plot cells.
        newPlots.push({ traces: [traceMetadata] });
      });

      if (newPlots.length > 0) {
        dispatch(
          dataExplorerActions.addTracesInNewPlotCells({
            newPlots,
          }),
        );
      }

      if (autoShowNormalVis) {
        dispatch(uiFlagsActions.showVisualizerFooterTab());
      }
    },
    [dispatch, loadedModelId],
  );

  const removePortsFromChart = React.useCallback(
    (portLocations: PortLocation[]) => {
      const traceIdsToRemove: string[] = [];
      portLocations.forEach((portLocation) => {
        const signalPath = getPortPathName(
          getCurrentModelRef().topLevelNodes,
          getCurrentModelRef().submodels,
          portLocation,
          {
            includePortNameForNonSubmodels: true,
          },
        );

        if (!signalPath) return;

        Object.values(idToTrace).forEach((trace) => {
          if (trace.signalPath === signalPath) {
            traceIdsToRemove.push(trace.id);
          }
        });
      });

      dispatch(
        dataExplorerActions.removeTraces({
          traceIds: traceIdsToRemove,
        }),
      );
      dispatch(
        dataExplorerPlotDataActions.removeTraceLoadStates({
          traceIds: traceIdsToRemove,
        }),
      );
    },
    [dispatch, idToTrace],
  );

  const removeBlockPortsFromChart = React.useCallback(
    (nodeLocations: NodeLocation[], flagAsInvalid?: boolean) => {
      const pathsToHide: string[] = [];
      const portsToRemove: PortLocation[] = [];
      nodeLocations.forEach(({ parentPath, nodeId }) => {
        const node = getNestedNode(
          getCurrentModelRef().topLevelNodes,
          getCurrentModelRef().submodels,
          parentPath,
          nodeId,
        );
        if (node) {
          for (
            let portIndex = 0;
            portIndex < node.outputs.length;
            portIndex++
          ) {
            const portLocation = {
              nodeId,
              parentPath,
              portIndex,
            };
            const signalPath = getPortPathName(
              getCurrentModelRef().topLevelNodes,
              getCurrentModelRef().submodels,
              portLocation,
              {
                includePortNameForNonSubmodels: true,
              },
            );

            portsToRemove.push(portLocation);
            if (signalPath) pathsToHide.push(signalPath);
          }
        }
      });

      if (flagAsInvalid) {
        dispatch(
          dataExplorerPlotDataActions.flagTracePathsForHiding({
            tracePaths: pathsToHide,
          }),
        );
      }
      removePortsFromChart(portsToRemove);
    },
    [removePortsFromChart, dispatch],
  );

  const toggleAllBlockPortsInChart = React.useCallback(
    ({ nodeId, parentPath }: NodeLocation) => {
      const areAnyNodePortsInChart = getAreAnyNodePortsInChart({
        nodeId,
        parentPath,
      });
      if (areAnyNodePortsInChart) {
        removeBlockPortsFromChart([{ nodeId, parentPath }]);
      } else {
        const node = getNestedNode(
          getCurrentModelRef().topLevelNodes,
          getCurrentModelRef().submodels,
          parentPath,
          nodeId,
        );
        if (node) {
          const nodeDetails: PortLocation[] = [];
          for (
            let portIndex = 0;
            portIndex < node.outputs.length;
            portIndex++
          ) {
            if (node.outputs[portIndex].variant?.variant_kind === 'acausal')
              continue;

            nodeDetails.push({
              nodeId,
              parentPath,
              portIndex,
            });
          }

          addPortsToChart(nodeDetails);
        }
      }
    },
    [getAreAnyNodePortsInChart, removeBlockPortsFromChart, addPortsToChart],
  );

  return {
    getAreAnyNodePortsInChart,
    getAreAllNodePortsInChart,
    getIsPortInChart,
    getPortsInChartFast,
    addPortsToChart,
    removePortsFromChart,
    removeBlockPortsFromChart,
    toggleAllBlockPortsInChart,
  };
}
