/** @jsxImportSource @emotion/react */
import { Interpolation, Theme, useTheme } from '@emotion/react';
import React from 'react';
import { Typography } from 'theme/emotion';

export interface Props {
  children?:
    | React.ReactNode
    | true
    | React.ReactChild
    | React.ReactFragment
    | React.ReactPortal;
  className?: string;
  testId?: string;
}

export const getTypographyStyle = (
  theme: Theme,
  type: keyof Typography['font'],
): Interpolation<Theme> => ({
  fontFamily: theme.typography.font[type].fontFamily,
  fontSize: theme.typography.font[type].size,
  fontWeight: theme.typography.font[type].weight,
  lineHeight: theme.typography.font[type].lineHeight,
  letterSpacing: theme.typography.font[type].letterSpacing,
  textTransform: theme.typography.font[type].textTransform,
});

export const ExtraSmall: React.FC<Props> = ({
  children,
  className,
  testId,
}) => (
  <span
    css={getTypographyStyle(useTheme(), 'extraSmall')}
    className={className}
    data-test-id={testId}>
    {children}
  </span>
);

export const Small: React.FC<Props> = ({ children, className, testId }) => (
  <span
    css={getTypographyStyle(useTheme(), 'small')}
    className={className}
    data-test-id={testId}>
    {children}
  </span>
);

export const Standard: React.FC<Props> = ({ children, className, testId }) => (
  <span
    css={getTypographyStyle(useTheme(), 'large')}
    className={className}
    data-test-id={testId}>
    {children}
  </span>
);

export const Large: React.FC<Props> = ({ children, className, testId }) => (
  <span
    css={getTypographyStyle(useTheme(), 'large')}
    className={className}
    data-test-id={testId}>
    {children}
  </span>
);

export const Code: React.FC<Props> = ({ children, className, testId }) => (
  <span
    css={getTypographyStyle(useTheme(), 'code')}
    className={className}
    data-test-id={testId}>
    {children}
  </span>
);

export const ExtraSmallCaps: React.FC<Props> = ({
  children,
  className,
  testId,
}) => (
  <span
    css={getTypographyStyle(useTheme(), 'extraSmallCaps')}
    className={className}
    data-test-id={testId}>
    {children}
  </span>
);

export const ExtraSmallCapsHighlight: React.FC<Props> = ({
  children,
  className,
  testId,
}) => (
  <span
    css={getTypographyStyle(useTheme(), 'extraSmallCapsHighlight')}
    className={className}
    data-test-id={testId}>
    {children}
  </span>
);
interface SettableProps {
  content: string;
  className?: string;
  testId?: string;
}
export const SettableSmallEmphasis: React.FC<SettableProps> = ({
  content,
  className,
  testId,
}) => (
  <span
    // eslint-disable-next-line react/no-danger
    dangerouslySetInnerHTML={{ __html: content }}
    css={getTypographyStyle(useTheme(), 'smallEmphasis')}
    className={className}
    data-test-id={testId}
  />
);

export const SmallEmphasis: React.FC<Props> = ({
  children,
  className,
  testId,
}) => (
  <span
    css={getTypographyStyle(useTheme(), 'smallEmphasis')}
    className={className}
    data-test-id={testId}>
    {children}
  </span>
);

export const StandardHighlight: React.FC<Props> = ({
  children,
  className,
  testId,
}) => (
  <span
    css={getTypographyStyle(useTheme(), 'standardHighlight')}
    className={className}
    data-test-id={testId}>
    {children}
  </span>
);

export const SmallHighlight: React.FC<Props> = ({
  children,
  className,
  testId,
}) => (
  <span
    css={getTypographyStyle(useTheme(), 'smallHighlight')}
    className={className}
    data-test-id={testId}>
    {children}
  </span>
);

export const Title: React.FC<Props> = ({ children, className, testId }) => (
  <div
    css={getTypographyStyle(useTheme(), 'title')}
    className={className}
    data-test-id={testId}>
    {children}
  </div>
);

export const H1: React.FC<Props> = ({ children, className, testId }) => (
  <h1
    css={getTypographyStyle(useTheme(), 'heading1')}
    className={className}
    data-test-id={testId}>
    {children}
  </h1>
);

export const H2: React.FC<Props> = ({ children, className, testId }) => (
  <h2
    css={getTypographyStyle(useTheme(), 'heading2')}
    className={className}
    data-test-id={testId}>
    {children}
  </h2>
);

export const H3: React.FC<Props> = ({ children, className, testId }) => (
  <h3
    css={getTypographyStyle(useTheme(), 'heading3')}
    className={className}
    data-test-id={testId}>
    {children}
  </h3>
);

export const H4: React.FC<Props> = ({ children, className, testId }) => (
  <h4
    css={getTypographyStyle(useTheme(), 'heading4')}
    className={className}
    data-test-id={testId}>
    {children}
  </h4>
);

export const H5: React.FC<Props> = ({ children, className, testId }) => (
  <h5
    css={getTypographyStyle(useTheme(), 'heading5')}
    className={className}
    data-test-id={testId}>
    {children}
  </h5>
);
