/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const SuperSimulation: React.FC<Props> = (props) => (
  <svg viewBox="0 0 24 24" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M11.6583 4.57622C11.879 4.49594 12.121 4.49594 12.3417 4.57622L16.2167 5.98531C16.6119 6.12902 16.875 6.5046 16.875 6.9251V10.7247L20.3417 11.9853C20.7369 12.129 21 12.5046 21 12.9251V17.0749C21 17.4954 20.7369 17.871 20.3417 18.0147L16.4667 19.4238C16.246 19.5041 16.004 19.5041 15.7833 19.4238L12 18.048L8.21674 19.4238C7.99599 19.5041 7.75401 19.5041 7.53326 19.4238L3.65826 18.0147C3.26307 17.871 3 17.4954 3 17.0749V12.9251C3 12.5046 3.26307 12.129 3.65826 11.9853L7.125 10.7247V6.9251C7.125 6.5046 7.38807 6.12902 7.78326 5.98531L11.6583 4.57622ZM7.875 12.048L5.94463 12.75L7.875 13.452L9.80537 12.75L7.875 12.048ZM11.25 13.8208L8.625 14.7753V17.6792L11.25 16.7247V13.8208ZM12.75 16.7247L15.375 17.6792V14.7753L12.75 13.8208V16.7247ZM14.1946 12.75L16.125 13.452L18.0554 12.75L16.125 12.048L14.1946 12.75ZM15.375 10.7247V7.82077L12.75 8.77532V11.6792L15.375 10.7247ZM11.25 11.6792V8.77532L8.625 7.82077V10.7247L11.25 11.6792ZM10.0696 6.75L12 7.45195L13.9304 6.75L12 6.04805L10.0696 6.75ZM19.5 13.8208L16.875 14.7753V17.6792L19.5 16.7247V13.8208ZM7.125 17.6792V14.7753L4.5 13.8208V16.7247L7.125 17.6792Z"
      fill={props.fill}
    />
  </svg>
);

SuperSimulation.defaultProps = {
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default SuperSimulation;
