import React from 'react';
import { StartingModalsLoader } from 'StartingModalsLoader';
import {
  RedirectToSignIn,
  RefreshIfSignedOut,
  SignedIn,
  SignedOut,
} from './AuthComponents';

const ProtectedRoute: React.FC<{
  children: React.ReactElement;
}> = ({ children }) => (
  <>
    <SignedIn>
      <StartingModalsLoader>{children}</StartingModalsLoader>
    </SignedIn>
    <SignedOut>
      <RedirectToSignIn />
    </SignedOut>
    <RefreshIfSignedOut />
  </>
);

export default ProtectedRoute;
