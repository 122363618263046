/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Group: React.FC<Props> = (props) => (
  <svg viewBox="0 0 16 16" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M5.5 5.80303C5.5 5.63567 5.63168 5.5 5.79412 5.5H8.14706C8.3095 5.5 8.44118 5.63567 8.44118 5.80303V6.40909H9.02941V5.80303C9.02941 5.63567 9.16109 5.5 9.32353 5.5H10.2059C10.3683 5.5 10.5 5.63567 10.5 5.80303V7.62121C10.5 7.78857 10.3683 7.92424 10.2059 7.92424H9.32353C9.16109 7.92424 9.02941 7.78857 9.02941 7.62121V7.01515H8.44118V7.62121C8.44118 7.78857 8.3095 7.92424 8.14706 7.92424L7.26471 7.92424V9.43939H8.14706V9.28788C8.14706 9.12052 8.27874 8.98485 8.44118 8.98485H10.2059C10.3683 8.98485 10.5 9.12052 10.5 9.28788V10.197C10.5 10.3643 10.3683 10.5 10.2059 10.5H8.44118C8.27874 10.5 8.14706 10.3643 8.14706 10.197V10.0455H7.26471C6.93983 10.0455 6.67647 9.77411 6.67647 9.43939V7.92424L5.79412 7.92424C5.63168 7.92424 5.5 7.78857 5.5 7.62121V5.80303ZM6.08824 7.31818V6.10606H7.85294V7.31818H6.08824ZM9.61765 6.10606V7.31818H9.91176V6.10606H9.61765ZM8.73529 9.89394V9.59091H9.91176V9.89394H8.73529Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M3 4C3 3.44772 3.44772 3 4 3H12C12.5523 3 13 3.44772 13 4V12C13 12.5523 12.5523 13 12 13H4C3.44772 13 3 12.5523 3 12V4ZM4 4H12V12H4V4Z"
      fill={props.fill}
    />
  </svg>
);

Group.defaultProps = {
  width: '16',
  height: '16',
  viewBox: '0 0 16 16',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Group;
