/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  className?: string;
}

const CoordinaterotationDcm: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.3774 20C5.3374 20 5.3014 19.988 5.2694 19.964C5.2454 19.932 5.2334 19.896 5.2334 19.856V11.744C5.2334 11.704 5.2454 11.672 5.2694 11.648C5.3014 11.616 5.3374 11.6 5.3774 11.6H8.3054C8.8734 11.6 9.3734 11.7 9.8054 11.9C10.2374 12.1 10.5694 12.388 10.8014 12.764C11.0414 13.132 11.1614 13.564 11.1614 14.06V17.54C11.1614 18.036 11.0414 18.472 10.8014 18.848C10.5694 19.216 10.2374 19.5 9.8054 19.7C9.3734 19.9 8.8734 20 8.3054 20H5.3774ZM6.6254 18.74C6.6254 18.78 6.6454 18.8 6.6854 18.8H8.3654C8.7894 18.8 9.1254 18.668 9.3734 18.404C9.6294 18.132 9.7614 17.772 9.7694 17.324V14.288C9.7694 13.832 9.6414 13.472 9.3854 13.208C9.1294 12.936 8.7854 12.8 8.3534 12.8H6.6854C6.6454 12.8 6.6254 12.82 6.6254 12.86V18.74Z"
      fill={props.fill}
    />
    <path
      d="M15.4476 20.096C14.8476 20.096 14.3196 19.98 13.8636 19.748C13.4076 19.508 13.0556 19.176 12.8076 18.752C12.5596 18.328 12.4356 17.836 12.4356 17.276V14.312C12.4356 13.752 12.5596 13.26 12.8076 12.836C13.0556 12.412 13.4076 12.084 13.8636 11.852C14.3196 11.62 14.8476 11.504 15.4476 11.504C16.0396 11.504 16.5636 11.616 17.0196 11.84C17.4756 12.064 17.8276 12.38 18.0756 12.788C18.3236 13.196 18.4476 13.668 18.4476 14.204C18.4476 14.244 18.4316 14.28 18.3996 14.312C18.3756 14.336 18.3436 14.348 18.3036 14.348L17.1996 14.408C17.1036 14.408 17.0556 14.364 17.0556 14.276C17.0556 13.804 16.9076 13.424 16.6116 13.136C16.3236 12.848 15.9356 12.704 15.4476 12.704C14.9596 12.704 14.5676 12.848 14.2716 13.136C13.9756 13.424 13.8276 13.804 13.8276 14.276V17.336C13.8276 17.8 13.9756 18.176 14.2716 18.464C14.5676 18.752 14.9596 18.896 15.4476 18.896C15.9356 18.896 16.3236 18.756 16.6116 18.476C16.9076 18.188 17.0556 17.808 17.0556 17.336C17.0556 17.248 17.1036 17.204 17.1996 17.204L18.3036 17.252C18.3436 17.252 18.3756 17.264 18.3996 17.288C18.4316 17.312 18.4476 17.34 18.4476 17.372C18.4476 17.916 18.3236 18.396 18.0756 18.812C17.8276 19.22 17.4756 19.536 17.0196 19.76C16.5636 19.984 16.0396 20.096 15.4476 20.096Z"
      fill={props.fill}
    />
    <path
      d="M25.3524 11.696C25.3924 11.632 25.4484 11.6 25.5204 11.6H26.6244C26.6644 11.6 26.6964 11.616 26.7204 11.648C26.7524 11.672 26.7684 11.704 26.7684 11.744V19.856C26.7684 19.896 26.7524 19.932 26.7204 19.964C26.6964 19.988 26.6644 20 26.6244 20H25.5204C25.4804 20 25.4444 19.988 25.4124 19.964C25.3884 19.932 25.3764 19.896 25.3764 19.856V14.132C25.3764 14.108 25.3644 14.092 25.3404 14.084C25.3244 14.076 25.3124 14.084 25.3044 14.108L23.8044 16.412C23.7644 16.476 23.7084 16.508 23.6364 16.508H23.0844C23.0124 16.508 22.9564 16.476 22.9164 16.412L21.4044 14.12C21.3964 14.096 21.3804 14.088 21.3564 14.096C21.3404 14.104 21.3324 14.12 21.3324 14.144V19.856C21.3324 19.896 21.3164 19.932 21.2844 19.964C21.2604 19.988 21.2284 20 21.1884 20H20.0844C20.0444 20 20.0084 19.988 19.9764 19.964C19.9524 19.932 19.9404 19.896 19.9404 19.856V11.744C19.9404 11.704 19.9524 11.672 19.9764 11.648C20.0084 11.616 20.0444 11.6 20.0844 11.6H21.1884C21.2604 11.6 21.3164 11.632 21.3564 11.696L23.3124 14.66C23.3284 14.676 23.3444 14.684 23.3604 14.684C23.3764 14.684 23.3884 14.676 23.3964 14.66L25.3524 11.696Z"
      fill={props.fill}
    />
  </svg>
);

CoordinaterotationDcm.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  className: '',
};

export default CoordinaterotationDcm;
