import styled from '@emotion/styled';
import AppRouter from 'AppRouter';
import { DISABLE_GA4 } from 'app/config/globalApplicationConfig';
import { SimulationRefAccess } from 'app/sliceRefAccess/SimulationRefAccess';
import { SubmodelRefAccess } from 'app/sliceRefAccess/SubmodelRefAccess';
import { googleAnalyticsTracking } from 'googleAnalyticsTracking';
import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import { Theme, ThemeStore } from 'theme';
import Onboarding from 'tours/onboarding/Onboarding';
import { ChatContextProvider } from 'ui/appBottomBar/assistant/ChatContextProvider';
import { SignedIn } from 'ui/auth/AuthComponents';
import AuthProvider from 'ui/auth/AuthProvider';
import LoginProvider from 'ui/auth/LoginProvider';
import UserSessionTracker from 'ui/auth/UserSessionTracker';
import { CustomStripeProvider } from 'ui/common/CustomStripeProvider';
import { ModalProvider } from 'ui/common/Modal/ModalProvider';
import { PartySocketProvider } from 'ui/common/PartySocketProvider';
import { PythonProvider } from 'ui/common/PythonProvider';
import { SubscriptionModals } from 'ui/common/SubscriptionModals';
import { WebSocketProvider } from 'ui/common/WebSocketProvider';
import { Notification } from 'ui/common/notifications/Notification';
import { Navbar } from 'ui/navbar/Navbar';
import UserPreferencesLoader from 'ui/userPreferences/UserPreferencesLoader';

const AppLayoutWrapper = styled.div`
  isolation: isolate;
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

const AppContentWrapper = styled.div`
  width: 100%;
  flex-grow: 1;
  overflow: hidden;
`;

// necessary because this needs to be a part of the top-level AppLayout,
// but it also needs to be inside of the BrowserRouter component
const GATracker = () => {
  const { pathname, search } = useLocation();

  React.useEffect(() => {
    if (DISABLE_GA4) return;
    if (process.env.NODE_ENV !== 'production') return;

    googleAnalyticsTracking({
      path: pathname,
      search,
      title: pathname.split('/')[1],
    });
  }, [pathname, search]);

  return null;
};

const AppLayout: React.FC = () => (
  <ThemeStore themeValue="base">
    <Theme>
      <BrowserRouter>
        <GATracker />
        <Routes>
          <Route path="/login/*" element={<LoginProvider />} />
          <Route
            path="*"
            element={
              <AuthProvider>
                <WebSocketProvider>
                  <PartySocketProvider>
                    <PythonProvider>
                      <CustomStripeProvider>
                        <ModalProvider>
                          <AppLayoutWrapper>
                            <SubscriptionModals />
                            <SignedIn>
                              <Onboarding />
                              {/* Non-visual components that should be created exactly once when the user is signed in and whose lifetime should match the user session. */}
                              <UserPreferencesLoader />
                              <UserSessionTracker />
                              <SubmodelRefAccess />
                              <SimulationRefAccess />

                              {/* App navbar */}
                              <Navbar />
                            </SignedIn>

                            {/* App content */}
                            <AppContentWrapper>
                              <DndProvider backend={HTML5Backend}>
                                <ChatContextProvider>
                                  <AppRouter />
                                </ChatContextProvider>
                              </DndProvider>
                            </AppContentWrapper>

                            {/* Notifications live below the navbar and above the content. */}
                            <SignedIn>
                              <Notification />
                            </SignedIn>
                          </AppLayoutWrapper>
                        </ModalProvider>
                      </CustomStripeProvider>
                    </PythonProvider>
                  </PartySocketProvider>
                </WebSocketProvider>
              </AuthProvider>
            }
          />
        </Routes>
      </BrowserRouter>
    </Theme>
  </ThemeStore>
);

export default AppLayout;
