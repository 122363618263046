import { PayloadAction } from '@reduxjs/toolkit';
import { DiagramVersionFull } from 'app/apiTransformers/convertGetSnapshotReadByUuid';
import {
  Node as NodeSignalInfo,
  SignalTypes,
} from 'app/generated_types/collimator/dashboard/serialization/ui_types.gen';
import {
  SignalIndividualTypeAndTimeInfo,
  nonDiscreteTimeModeValMap,
} from 'app/slices/compilationAnalysisDataSlice';
import { DataExplorerState } from 'app/slices/dataExplorerSlice';
import { WritableDraft } from 'immer/dist/internal';
import {
  CellMetadata,
  CellRow,
  CellType,
  MarkedPoint,
  PlotCellMetadata,
  SignalDragItem,
  TraceMetadata,
} from 'ui/dataExplorer/dataExplorerTypes';
import { generateTracesThatSupportVectorPorts } from 'util/visualizerTraces';
import { v4 as uuid } from 'uuid';

export const DEFAULT_ROW_HEIGHT = 260;

/**
 * Initialize new cell of given type and return the ID.
 */
function initializeCell(state: DataExplorerState, cellType: CellType) {
  const cell: CellMetadata = {
    id: uuid(),
    cellType,
  };
  state.idToCell[cell.id] = cell;

  switch (cellType) {
    case 'plot':
      const plotCell: PlotCellMetadata = {
        id: cell.id,
        traceIds: [],
      };
      state.idToPlotCell[plotCell.id] = plotCell;
      break;
    case 'image':
      // TODO for image cell
      break;
    case 'text':
      // TODO for text cell
      break;
  }
  return cell.id;
}

/**
 * Adds a new cell at given row index, and returns the ID of the new cell that occupies it.
 */
export function addNewCellAtRowIndex(
  state: DataExplorerState,
  rowIndex: number,
  cellType: CellType,
) {
  const cellId = initializeCell(state, cellType);

  const cellRow: CellRow = {
    id: uuid(),
    rowHeight: DEFAULT_ROW_HEIGHT,
    cellIds: [cellId],
  };
  state.idToCellRow[cellRow.id] = cellRow;

  const cellRowIds = state.cellRowIds;
  cellRowIds.splice(rowIndex, 0, cellRow.id);

  state.cellRowIds = cellRowIds;

  return cellId;
}

function fixupTraces(
  state: WritableDraft<DataExplorerState>,
  modelIdToVersionIdToModelData: Record<
    string,
    Record<string, DiagramVersionFull>
  >,
) {
  const { modelVersionToRequest, hasChanges, idToPlotCell, idToTrace } =
    generateTracesThatSupportVectorPorts(
      state.idToTrace,
      state.idToPlotCell,
      modelIdToVersionIdToModelData,
    );

  if (modelVersionToRequest) {
    return;
  }

  if (hasChanges && idToTrace && idToPlotCell) {
    state.idToTrace = idToTrace;
    state.idToPlotCell = idToPlotCell;
  }
}

const updateAllTracePathIndicesForNewTypeData = (
  state: DataExplorerState,
  pathToTypeData: {
    [path: string]: SignalIndividualTypeAndTimeInfo | undefined;
  },
) => {
  let reMetaInfoByTraceId: Record<
    string,
    { op: 'delete' } | { op: 'addMore'; addIds: string[] }
  > = {};

  const traceIds = Object.keys(state.idToTrace);

  const sizeDiffVectorMap: {
    [signalPath: string]:
      | {
          skip: false;
          traceData: TraceMetadata;
          maxCurrentIdx: number;
          nextMaxIdx: number;
        }
      | { skip: true }
      | undefined;
  } = {};

  for (let i = 0; i < traceIds.length; i++) {
    const id = traceIds[i];
    const trace = state.idToTrace[id];
    const traceTypeData = pathToTypeData[trace.signalPath];

    // this needs more thorough error state consideration. leaving just so that it doesn't crash.
    if (!traceTypeData) continue;
    const typeIsVector = traceTypeData.dimension.length == 1;

    // if the existing trace data we have stored is for a vector,
    // but the new type info says this signal is not a vector anymore
    if (trace.vectorIndex !== undefined && !typeIsVector) {
      if (trace.vectorIndex == 0) {
        trace.displayName = `${trace.signalPath}`;
        trace.tracePath = `${trace.signalPath}`;
      } else {
        reMetaInfoByTraceId[trace.id] = { op: 'delete' };
        delete state.idToTrace[id];
      }
    }
    // exact opposite (if the stored trace data is not for a vector,
    // but the new type info says the signal IS a vector now)
    if (trace.vectorIndex === undefined && typeIsVector) {
      const vectorLength = traceTypeData.dimension[0];
      const newName = `${trace.tracePath}[0]`;
      const additionalIds: string[] = [];

      for (let j = 1; j < vectorLength; j++) {
        const additionalTracePath = `${trace.tracePath}[${j}]`;
        const additionalTraceId = uuid();
        additionalIds.push(additionalTraceId);
        state.idToTrace[additionalTraceId] = {
          ...trace,
          id: additionalTraceId,
          displayName: additionalTracePath,
          tracePath: additionalTracePath,
          signalPath: `${trace.signalPath}`,
          vectorIndex: j,
        };
      }

      trace.displayName = `${newName}`;
      trace.tracePath = `${newName}`;
      trace.vectorIndex = 0;

      reMetaInfoByTraceId[trace.id] = {
        op: 'addMore',
        addIds: additionalIds,
      };
    }
    // here we just store sizing data for easy processing
    // which we use to correct for vector size increases differences later.
    // we also just go ahead and add delete ops here (in the case of vector size decreases)
    // in place because it's simpler and also takes less iterations
    if (typeIsVector && trace.vectorIndex !== undefined) {
      const nextMaxIdx = traceTypeData.dimension[0] - 1;

      if (nextMaxIdx < trace.vectorIndex) {
        reMetaInfoByTraceId[trace.id] = { op: 'delete' };
        sizeDiffVectorMap[trace.signalPath] = { skip: true };
      }

      const existingSizeDiffData = sizeDiffVectorMap[trace.signalPath];
      if (!existingSizeDiffData || !existingSizeDiffData.skip) {
        const newMaxCurrentIdx = Math.max(
          existingSizeDiffData?.maxCurrentIdx ?? trace.vectorIndex,
          trace.vectorIndex,
        );

        sizeDiffVectorMap[trace.signalPath] = {
          skip: false,
          traceData: trace,
          maxCurrentIdx: newMaxCurrentIdx,
          nextMaxIdx,
        };
      }
    }
  }

  // correct the display for vector size increases
  const diffSignalPaths = Object.keys(sizeDiffVectorMap);
  for (let i = 0; i < diffSignalPaths.length; i++) {
    const signalPath = diffSignalPaths[i];
    const diffData = sizeDiffVectorMap[signalPath];
    if (!diffData || diffData.skip) continue;

    if (diffData.maxCurrentIdx < diffData.nextMaxIdx) {
      const additionalIds: string[] = [];
      for (let j = diffData.maxCurrentIdx + 1; j <= diffData.nextMaxIdx; j++) {
        const additionalTracePath = `${signalPath}[${j}]`;
        const additionalTraceId = uuid();
        additionalIds.push(additionalTraceId);
        state.idToTrace[additionalTraceId] = {
          ...diffData.traceData,
          id: additionalTraceId,
          displayName: additionalTracePath,
          tracePath: additionalTracePath,
          signalPath,
          vectorIndex: j,
        };
      }

      reMetaInfoByTraceId[diffData.traceData.id] = {
        op: 'addMore',
        addIds: additionalIds,
      };
    }
  }

  const plotCellIds = Object.keys(state.idToPlotCell);
  for (let i = 0; i < plotCellIds.length; i++) {
    const id = plotCellIds[i];
    const copiedTraceIds: string[] = JSON.parse(
      JSON.stringify(state.idToPlotCell[id].traceIds),
    );
    state.idToPlotCell[id].traceIds = copiedTraceIds.reduce((acc, id) => {
      const reMetaInfo = reMetaInfoByTraceId[id];
      if (reMetaInfo && reMetaInfo.op === 'delete') return acc;
      if (reMetaInfo && reMetaInfo.op === 'addMore') {
        return [...acc, id, ...reMetaInfo.addIds];
      }

      return [...acc, id];
    }, [] as string[]);
  }
};

/**
 * We are displaying a new data exploration on every run, so upon a new simulation run, the existing traces must be updated.
 *
 * Assign new UUIDs to traces.
 * Update the simulation ID.
 * Blow away zoom.
 *
 * Essentially rebuilding a new data explorer based on the old data explorer, despite looking like modifying the old data explorer state.
 */
export function newSimulationRunCompleted(
  state: DataExplorerState,
  action: PayloadAction<{
    simulationId: string;
    modelIdToVersionIdToModelData: Record<
      string,
      Record<string, DiagramVersionFull>
    >;
    signalTypes: SignalTypes;
  }>,
) {
  const { simulationId, modelIdToVersionIdToModelData, signalTypes } =
    action.payload;

  fixupTraces(state, modelIdToVersionIdToModelData);

  const typeMap = signalTypes.nodes.reduce<{
    [path: string]: SignalIndividualTypeAndTimeInfo | undefined;
  }>((acc, nodeInfo: NodeSignalInfo) => {
    let mappedPorts: {
      [path: string]: SignalIndividualTypeAndTimeInfo | undefined;
    } = {};
    const nodePath = nodeInfo.namepath.join('.');

    for (let i = 0; i < nodeInfo.outports.length; i++) {
      const portInfo = nodeInfo.outports[i];
      mappedPorts[`${nodePath}.${portInfo.name}`] = {
        dtype: portInfo.dtype,
        dimension: portInfo.dimension,
        mode:
          portInfo.time_mode === 'Discrete'
            ? {
                mode: 'Discrete',
                stepLevel: 0,
                discreteInterval: portInfo.discrete_interval || 0,
              }
            : {
                mode:
                  nonDiscreteTimeModeValMap[portInfo.time_mode] || 'Unknown',
              },
      };
    }

    return { ...acc, ...mappedPorts };
  }, {});

  updateAllTracePathIndicesForNewTypeData(state, typeMap);

  // TODO: update time mode on every sim run. See UI-1147

  const oldIdToNewId: Record<string, string> = {};
  const nextIdsToTrace: Record<string, TraceMetadata> = {};
  Object.values(state.idToTrace).forEach((trace) => {
    const newTraceId = uuid();
    oldIdToNewId[trace.id] = newTraceId;
    nextIdsToTrace[newTraceId] = {
      ...trace,
      id: newTraceId,
      // FIXME: this should not be here. DASH-1751
      explorationSimId: simulationId,
    };
  });

  const nextIdToPlotCell: Record<string, PlotCellMetadata> = {};
  Object.values(state.idToPlotCell).forEach((plotCell) => {
    const { initialBounds, zoomBounds, traceIds, ...propsToPreserve } =
      plotCell;
    nextIdToPlotCell[plotCell.id] = {
      ...propsToPreserve,
      traceIds: plotCell.traceIds.map((oldTraceId) => oldIdToNewId[oldTraceId]),
    };
  });

  /// FIXME: what is this doing? DASH-1751
  const nextTraceIdToMarkedPoints: Record<string, MarkedPoint[]> = {};
  Object.keys(state.serieIdToMarkedPoints).forEach((serieId) => {
    const newTraceId = oldIdToNewId[serieId];
    nextTraceIdToMarkedPoints[newTraceId] =
      state.serieIdToMarkedPoints[serieId];
  });

  state.idToTrace = nextIdsToTrace;
  state.idToPlotCell = nextIdToPlotCell;
  state.serieIdToMarkedPoints = nextTraceIdToMarkedPoints;
}

/**
 * Used when we need to add new traces to a new plot cell
 * in a new row at the end of the cell rows.  Useful when adding all traces for a
 * specific signal.
 */
function addTracesInNewPlotCell(
  state: DataExplorerState,
  traces: TraceMetadata[],
  addSignalRequestId?: string,
) {
  // First, add the cell in a brand new row that contains the traces.
  const cellId = addNewCellAtRowIndex(state, state.cellRowIds.length, 'plot');
  state.idToPlotCell[cellId].traceIds = traces.map((trace) => trace.id);

  // Second, add the traces themselves.
  traces.forEach((trace) => {
    // Add the data for the signal id.
    state.idToTrace[trace.id] = trace;
  });

  // If these traces are a result of an add signal request, clear the request now that we've added the associated traces.
  if (addSignalRequestId) {
    delete state.idToAddSignalRequest[addSignalRequestId];
  }
}

export function cancelAddSignalRequest(
  state: DataExplorerState,
  addSignalRequestId: string,
  unsupportedSignalPath?: string,
) {
  const addSignalRequest = state.idToAddSignalRequest[addSignalRequestId];

  delete state.idToAddSignalRequest[addSignalRequestId];

  if (addSignalRequest && unsupportedSignalPath) {
    if (
      !state.simulationIdToSignalPathToIsUnsupported[
        addSignalRequest.simulationId
      ]
    ) {
      state.simulationIdToSignalPathToIsUnsupported[
        addSignalRequest.simulationId
      ] = {};
    }
    state.simulationIdToSignalPathToIsUnsupported[
      addSignalRequest.simulationId
    ][unsupportedSignalPath] = true;
  }
}

export interface NewPlotRequest {
  traces: TraceMetadata[];
  addSignalRequestId?: string;
}

export interface AddSignalCancelRequest {
  addSignalRequestId: string;
  unsupportedSignalPath?: string;
}

/**
 * Used when we need to add new traces to a new plot cell
 * in a new row at the end of the cell rows.  Useful when adding all traces for a
 * specific signal.
 */
export function addTracesInNewPlotCells(
  state: DataExplorerState,
  action: PayloadAction<{
    newPlots: NewPlotRequest[];
    signalRequestsToCancel?: AddSignalCancelRequest[];
  }>,
) {
  const { newPlots, signalRequestsToCancel } = action.payload;

  newPlots.forEach((newPlot) => {
    if (newPlot.traces.length > 0) {
      addTracesInNewPlotCell(state, newPlot.traces, newPlot.addSignalRequestId);
    } else {
      console.error('Cannot add a new plot cell with no traces.');
    }
  });

  if (signalRequestsToCancel) {
    signalRequestsToCancel.forEach((signalRequest) => {
      cancelAddSignalRequest(
        state,
        signalRequest.addSignalRequestId,
        signalRequest.unsupportedSignalPath,
      );
    });
  }
}

/**
 * Remove traces from plot cells. Does not remove look ups.
 * @return List of emptied cell ids.
 */
function removeTracesFromPlotCells(
  state: DataExplorerState,
  traceIdsToRemove: string[],
) {
  const emptiedPlotCellIds: string[] = [];

  Object.keys(state.idToPlotCell).forEach((plotCellId: string) => {
    const plotCell = state.idToPlotCell[plotCellId];
    const remainingTraceIds = plotCell.traceIds.filter(
      (traceId) => !traceIdsToRemove.includes(traceId),
    );
    plotCell.traceIds = remainingTraceIds;
    if (remainingTraceIds.length === 0) {
      emptiedPlotCellIds.push(plotCellId);
    }
  });

  return emptiedPlotCellIds;
}

/**
 * Cascading removal of empty cells. Remove look ups as well as empty ancestors.
 */
function cascadeRemoveEmptiedCells(
  state: DataExplorerState,
  emptiedPlotCellIds: string[],
) {
  if (emptiedPlotCellIds.length === 0) {
    return;
  }

  // First, remove the plot cells from rows,
  // tracking all emptied cell rows so we can remove them too.
  const emptiedRowIds: string[] = [];

  const cellRows: CellRow[] = state.cellRowIds.map(
    (rowId: string) => state.idToCellRow[rowId],
  );

  cellRows.forEach((cellRow) => {
    const updatedCellIds = cellRow.cellIds.filter(
      (cellId) => !emptiedPlotCellIds.includes(cellId),
    );
    if (updatedCellIds.length === 0) {
      emptiedRowIds.push(cellRow.id);
      delete state.idToCellRow[cellRow.id];
    } else if (updatedCellIds.length !== cellRow.cellIds.length) {
      cellRow.cellIds = updatedCellIds;
    }
  });

  // Then, remove all emptied rows.
  if (emptiedRowIds.length) {
    state.cellRowIds = state.cellRowIds.filter(
      (cellRowId) => !emptiedRowIds.includes(cellRowId),
    );
  }

  // Now that there are no references to the cell id, remove the cell id look ups.
  emptiedPlotCellIds.forEach((plotCellId) => {
    delete state.idToCell[plotCellId];
    delete state.idToPlotCell[plotCellId];
  });
}

/**
 * Internal helper to remove traces from the Data Explorer display structure.
 * Propagates clean up of empty entries up the hierarchy.
 */
export function removeTracesFromDisplay(
  state: DataExplorerState,
  traceIdsToRemove: string[],
) {
  // First, remove the traces from their parent cells,
  // tracking all emptied plot cells so we can cascade the removal.
  const emptiedPlotCellIds: string[] = removeTracesFromPlotCells(
    state,
    traceIdsToRemove,
  );

  // Cascade the removal of empty cells
  cascadeRemoveEmptiedCells(state, emptiedPlotCellIds);
}

function removeTracesFromLookups(
  state: DataExplorerState,
  traceIdsToRemove: string[],
) {
  traceIdsToRemove.forEach((traceIdToRemove) => {
    delete state.idToTrace[traceIdToRemove];
    delete state.serieIdToMarkedPoints[traceIdToRemove];
  });
}

/**
 * Remove traces entirely, both from the display structure and simulation lookups.
 */
export function removeTraces(
  state: DataExplorerState,
  action: PayloadAction<{
    traceIds: string[];
  }>,
) {
  const { traceIds: traceIdsToRemove } = action.payload;

  removeTracesFromDisplay(state, traceIdsToRemove);

  // Remove the data for the trace id now that we don't need it.
  removeTracesFromLookups(state, traceIdsToRemove);
}

export function moveTracesToPlotCell(
  state: DataExplorerState,
  action: PayloadAction<{
    targetCellId: string;
    traceIds: string[];
  }>,
) {
  const { targetCellId, traceIds } = action.payload;

  removeTracesFromPlotCells(state, traceIds);

  if (state.idToCell[targetCellId].cellType !== 'plot') {
    console.error('Cannot move traces to a non-plot cell.');
    return;
  }

  // Add the traces to the target plot cell.
  const targetCell = state.idToPlotCell[targetCellId];
  if (!targetCell) {
    console.error('PlotCell not found. plotCellId: %s', targetCellId);
    return;
  }

  // Dedup traceIds
  const newTraceIds = traceIds.filter(
    (traceId: string) => !targetCell.traceIds.includes(traceId),
  );

  if (newTraceIds.length !== traceIds.length) {
    console.error(
      'Duplicate traceIds found. Existing: %O. New: %O.',
      targetCell.traceIds,
      newTraceIds,
    );
  }

  targetCell.traceIds.push(...newTraceIds);

  const emptiedPlotCellIds = Object.keys(state.idToPlotCell).filter(
    (plotCellId) => state.idToPlotCell[plotCellId].traceIds.length === 0,
  );
  cascadeRemoveEmptiedCells(state, emptiedPlotCellIds);
}

export function moveTraceToPlotCell(
  state: DataExplorerState,
  action: PayloadAction<{
    targetCellId: string;
    traceId: string;
  }>,
) {
  const {
    payload: { targetCellId, traceId },
    type,
  } = action;
  moveTracesToPlotCell(state, {
    payload: { targetCellId, traceIds: [traceId] },
    type,
  });
}

function addNewCellAtIndex(
  state: DataExplorerState,
  rowId: string,
  cellIndex: number,
  cellType: CellType,
) {
  const cellId = initializeCell(state, cellType);

  const cellIds = state.idToCellRow[rowId].cellIds;
  cellIds.splice(cellIndex, 0, cellId);

  state.idToCellRow[rowId].cellIds = cellIds;
  return cellId;
}

function findCellLocation(state: DataExplorerState, cellId: string) {
  const cellRows: CellRow[] = state.cellRowIds.map(
    (rowId: string) => state.idToCellRow[rowId],
  );

  const row = cellRows.find((cellRow: CellRow) =>
    cellRow.cellIds.includes(cellId),
  );

  return {
    rowId: row?.id,
    cellIndex: row?.cellIds.indexOf(cellId),
  };
}

export function moveTracesToNewPlotCellAtOffset(
  state: DataExplorerState,
  action: PayloadAction<{
    traceIds: string[];
    targetCellId: string;
    offset: number;
  }>,
) {
  const {
    payload: { traceIds, targetCellId, offset },
    type,
  } = action;

  const { rowId, cellIndex } = findCellLocation(state, targetCellId);
  if (rowId === undefined || cellIndex === undefined) {
    console.error('Could not find location of cell with ID: %s', targetCellId);
    return;
  }

  const newPlotCellId = addNewCellAtIndex(
    state,
    rowId,
    cellIndex + offset,
    'plot',
  );

  moveTracesToPlotCell(state, {
    payload: { targetCellId: newPlotCellId, traceIds },
    type,
  });
}

export function moveTracesToNewRowAtOffset(
  state: DataExplorerState,
  action: PayloadAction<{
    traceIds: string[];
    targetRowId: string;
    offset: number;
  }>,
) {
  const {
    payload: { traceIds, targetRowId, offset },
    type,
  } = action;

  const rowIndex = state.cellRowIds.indexOf(targetRowId);
  if (rowIndex === -1) {
    console.error('Could not find location of row with ID: %s', targetRowId);
    return;
  }

  const newPlotCellId = addNewCellAtRowIndex(state, rowIndex + offset, 'plot');

  moveTracesToPlotCell(state, {
    payload: { targetCellId: newPlotCellId, traceIds },
    type,
  });
}

/**
 * Add new traces to a target plot cell of the exploration.
 */
export function addTracesToPlotCell(
  state: DataExplorerState,
  action: PayloadAction<{
    targetCellId: string;
    traceSpecs: SignalDragItem['traceSpecs'];
  }>,
) {
  const { targetCellId, traceSpecs } = action.payload;

  if (state.idToCell[targetCellId].cellType !== 'plot') {
    console.error('Cannot move traces to a non-plot cell.');
    return;
  }
  const targetPlotCell = state.idToPlotCell[targetCellId];
  const existingTraces = targetPlotCell.traceIds.map(
    (traceId) => state.idToTrace[traceId],
  );

  // First, create TraceMetadata out of the TraceSpecs,
  // filtering out the the existing traces.
  const traces: TraceMetadata[] = traceSpecs
    .map((traceSpec) => ({
      id: uuid(),
      ...traceSpec,
    }))
    .filter(
      (trace) =>
        !existingTraces.some(
          (existingTrace) =>
            existingTrace.explorationSimId === trace.explorationSimId &&
            existingTrace.tracePath === trace.tracePath,
        ),
    );

  // Second, add the new traces to the plot cell.
  const plotCell = state.idToPlotCell[targetCellId];
  const traceIds = traces.map((trace) => trace.id);
  plotCell.traceIds.push(...traceIds);

  // Last, add the traces lookups.
  traces.forEach((trace) => {
    // Add the data for the signal id.
    state.idToTrace[trace.id] = trace;
  });
}

export function addTracesToNewCellAtOffset(
  state: DataExplorerState,
  action: PayloadAction<{
    traceSpecs: SignalDragItem['traceSpecs'];
    targetCellId: string;
    offset: number;
  }>,
) {
  const {
    payload: { traceSpecs, targetCellId, offset },
    type,
  } = action;

  const { rowId, cellIndex } = findCellLocation(state, targetCellId);
  if (rowId === undefined || cellIndex === undefined) {
    console.error('Could not find location of cell with ID: %s', targetCellId);
    return;
  }

  const newPlotCellId = addNewCellAtIndex(
    state,
    rowId,
    cellIndex + offset,
    'plot',
  );

  addTracesToPlotCell(state, {
    payload: { targetCellId: newPlotCellId, traceSpecs },
    type,
  });
}

export function addTracesToNewRowAtOffset(
  state: DataExplorerState,
  action: PayloadAction<{
    traceSpecs: SignalDragItem['traceSpecs'];
    targetRowId: string;
    offset: number;
  }>,
) {
  const {
    payload: { traceSpecs, targetRowId, offset },
    type,
  } = action;

  const rowIndex = state.cellRowIds.indexOf(targetRowId);
  if (rowIndex === -1) {
    console.error('Could not find location of row with ID: %s', targetRowId);
    return;
  }

  const newPlotCellId = addNewCellAtRowIndex(state, rowIndex + offset, 'plot');

  addTracesToPlotCell(state, {
    payload: { targetCellId: newPlotCellId, traceSpecs },
    type,
  });
}
/**
 * Used when we need to add new traces in a new plot cell a new row at the end.
 */
export function addTracesInNewRow(
  state: DataExplorerState,
  action: PayloadAction<{
    traceSpecs: SignalDragItem['traceSpecs'];
  }>,
) {
  const {
    payload: { traceSpecs },
    type,
  } = action;

  const newPlotCellId = addNewCellAtRowIndex(
    state,
    state.cellRowIds.length,
    'plot',
  );

  addTracesToPlotCell(state, {
    payload: { targetCellId: newPlotCellId, traceSpecs },
    type,
  });
}
