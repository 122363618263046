import styled from '@emotion/styled';
import { t } from '@lingui/macro';
import { OpenAiModels } from 'app/chat/models';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
  entityPreferencesActions,
  selectEntityPrefs,
} from 'app/slices/entityPreferencesSlice';
import { Agent } from 'app/third_party_types/chat-types';
import React, { ReactElement } from 'react';
import Button from 'ui/common/Button/Button';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import { Checkbox } from 'ui/common/Checkbox';
import { BackArrow } from 'ui/common/Icons/Standard';
import { useModal } from 'ui/common/Modal/useModal';
import Tooltip from 'ui/common/Tooltip/Tooltip';
import {
  CHAT_PREFS_V1_KEY,
  ChatPrefsV1,
  defaultChatPrefsV1,
} from 'ui/userPreferences/chatPrefs';
import { useAppParams } from 'util/useAppParams';
import { useChatContext } from './ChatContextProvider';
import { CHAT_WIDTH, INPUT_BAR_HEIGHT } from './Sizings';
import SystemPromptModal from './SystemPromptModal';

// This element is used to hide the "show advanced options" checkbox.
// The checkbox is useful for devs testing but should be hidden for demos
// and external users.
const ShowOnHover = styled.div<{ shouldShow?: boolean }>`
  opacity: ${({ shouldShow }) => (shouldShow ? '1' : '0')};
  transition: opacity 0.2s ease-in-out;

  :hover {
    opacity: 1;
  }
`;

const Selector = styled.select`
  flex: 0;
  appearance: menulist;
  color: ${({ theme }) => theme.colors.grey[50]};
  background-color: ${({ theme }) => theme.colors.grey[5]};
`;

const NumberSelector = styled.input`
  flex: 0;
  width: 30px;
  margin-right: ${({ theme }) => theme.spacing.normal};
  align-self: center;
  color: ${({ theme }) => theme.colors.grey[70]};
  text-align: right;
  appearance: textfield;
  background-color: ${({ theme }) => theme.colors.grey[5]};
`;

const AdvancedOptionsDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: ${INPUT_BAR_HEIGHT}px;
  width: ${CHAT_WIDTH}px;
  padding-top: ${({ theme }) => theme.spacing.normal};
  padding-left: ${({ theme }) => theme.spacing.xlarge};
  padding-right: ${({ theme }) => theme.spacing.xlarge};
  background-color: ${({ theme }) => theme.colors.grey[5]};
`;

const Spacer = styled.div`
  flex: 100;
`;

interface AdvancedOptionsProps {
  showAdvancedOptions: boolean;
  setShowAdvancedOptions: (showAdvancedOptions: boolean) => void;
  restartConversationOnClick: () => void;
  setSystemPrompt: (systemPrompt: string) => void;
  systemPrompt?: string;
  setTools: (tools: string) => void;
  tools?: string;
}

const AdvancedOptions = ({
  showAdvancedOptions,
  setShowAdvancedOptions,
  restartConversationOnClick,
  systemPrompt,
  setSystemPrompt,
  tools,
  setTools,
}: AdvancedOptionsProps): ReactElement => {
  const { agentRef, setAgent } = useChatContext();
  const [agentValue, setAgentValue] = React.useState<Agent>(agentRef.current);
  const { developerModeEnabled } = useAppSelector(
    (state) => state.userOptions.options,
  );
  const { modelId } = useAppParams();
  const dispatch = useAppDispatch();
  const chatPrefs =
    (useAppSelector((state) =>
      selectEntityPrefs(state, CHAT_PREFS_V1_KEY, modelId),
    ) as ChatPrefsV1) ?? defaultChatPrefsV1;

  const { triggerModal } = useModal();

  const { defaultChatAiModel } = useAppSelector(
    (state) => state.userOptions.options,
  );

  const openSystemPromptModal = () => {
    triggerModal(
      <SystemPromptModal
        systemPrompt={systemPrompt}
        setSystemPrompt={setSystemPrompt}
        tools={tools}
        setTools={setTools}
      />,
      t`System prompt`,
    );
  };

  const updateOptions = React.useCallback(
    ({
      aiModelId,
      temperature,
      seed,
      useModelBuilderWithAcausal,
    }: {
      aiModelId?: string;
      temperature?: number;
      seed?: number;
      useModelBuilderWithAcausal?: boolean;
    }) => {
      if (!modelId) {
        return;
      }
      dispatch(
        entityPreferencesActions.onUserChangedChatOptions({
          modelId,
          aiModelId: aiModelId ?? chatPrefs.aiModelId ?? defaultChatAiModel,
          temperature: temperature ?? chatPrefs.temperature,
          seed: seed ?? chatPrefs.seed,
          useModelBuilderWithAcausal:
            useModelBuilderWithAcausal ?? chatPrefs.useModelBuilderWithAcausal,
        }),
      );
    },
    [
      chatPrefs.aiModelId,
      chatPrefs.seed,
      chatPrefs.temperature,
      chatPrefs.useModelBuilderWithAcausal,
      defaultChatAiModel,
      dispatch,
      modelId,
    ],
  );

  React.useEffect(() => {
    if (agentRef.current) {
      setAgentValue(agentRef.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agentRef.current]);

  return (
    <AdvancedOptionsDiv>
      {(showAdvancedOptions && (
        <>
          <Spacer />
          <Button onClick={openSystemPromptModal}>{t`System prompt`}</Button>
          <Spacer />
          <Selector
            onChange={(e) => updateOptions({ aiModelId: e.target.value })}
            value={chatPrefs.aiModelId ?? defaultChatAiModel}>
            {OpenAiModels.map((model) => (
              <option key={model.id} value={model.id}>
                {model.name}
              </option>
            ))}
          </Selector>
          Agent:
          <Selector
            onChange={(e) => {
              setAgentValue(e.target.value as Agent);
              setAgent(e.target.value as Agent);
            }}
            value={agentValue}>
            {Object.values(Agent).map((agent) => (
              <option key={agent} value={agent}>
                {agent}
              </option>
            ))}
          </Selector>
          Use acausal:
          <Checkbox
            value={chatPrefs.useModelBuilderWithAcausal}
            onChange={(value) =>
              updateOptions({
                useModelBuilderWithAcausal: value,
              })
            }
          />
          Temperature:
          <NumberSelector
            type="number"
            min="0.1"
            max="2"
            step="0.1"
            value={chatPrefs.temperature ?? 0.1}
            onChange={(e) =>
              updateOptions({ temperature: parseFloat(e.target.value) })
            }
          />
          Seed:
          <NumberSelector
            type="number"
            step="1"
            value={chatPrefs.seed}
            onChange={(e) => updateOptions({ seed: parseInt(e.target.value) })}
          />
        </>
      )) || <Spacer />}
      {developerModeEnabled ? (
        <Tooltip
          contentText={
            showAdvancedOptions
              ? t`Hide advanced options`
              : t`Show advanced options`
          }>
          <Checkbox
            value={showAdvancedOptions}
            onChange={() => setShowAdvancedOptions(!showAdvancedOptions)}
            testId="show-advanced-options-checkbox"
          />
        </Tooltip>
      ) : null}
      <Button
        variant={ButtonVariants.SmallTertiary}
        Icon={BackArrow}
        onClick={restartConversationOnClick}>
        {t`Restart the conversation`}
      </Button>
    </AdvancedOptionsDiv>
  );
};

export default AdvancedOptions;
