/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  className?: string;
}

const Tensorflow: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.4565 2L3.62686 8.83139L3.61035 15.0273L10.7305 10.8695V27.2282L15.4565 30V2Z"
      fill={props.fill}
    />
    <path
      d="M16.5303 2L28.3599 8.83139L28.3764 15.0273L21.2563 10.8695V13.9989L24.8053 16.0482L24.8255 21.4381L21.2563 19.3499V27.2282L16.5303 30V2Z"
      fill={props.fill}
    />
  </svg>
);

Tensorflow.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  className: '',
};

export default Tensorflow;
