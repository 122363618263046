/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const PidPd: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1 4.75C1.13807 4.75 1.25 4.86193 1.25 5V25.7927C1.32819 25.765 1.41234 25.75 1.5 25.75H2.57788C2.71708 25.4511 2.86031 24.996 2.99948 24.3765C3.16499 23.6398 3.31087 22.7377 3.44763 21.7211C3.61134 20.5042 3.7572 19.1634 3.90802 17.777C4.01102 16.8302 4.11634 15.8621 4.23123 14.8978C4.51033 12.5551 4.8441 10.2545 5.33462 8.53013C5.57851 7.67276 5.87656 6.90008 6.26195 6.3266C6.64488 5.75677 7.2112 5.25 8 5.25C8.87957 5.25 9.55887 5.65532 10.088 6.16078C10.5934 6.64349 11.0143 7.26934 11.3792 7.81182L11.3924 7.83142C11.7828 8.41183 12.1148 8.90216 12.4822 9.25849C12.8323 9.59808 13.1485 9.75 13.5 9.75C14.4354 9.75 15.3107 9.43063 16.302 9.04987L16.414 9.00681C17.3314 8.65371 18.3803 8.25 19.5 8.25C20.0499 8.25 20.8374 8.24849 21.7608 8.24671C24.1858 8.24205 27.5481 8.23559 30.0044 8.25001C30.4186 8.25245 30.7524 8.5902 30.75 9.0044C30.7476 9.41861 30.4098 9.75242 29.9956 9.74999C27.5457 9.7356 24.197 9.74204 21.7728 9.7467C20.8463 9.74848 20.0546 9.75 19.5 9.75C18.6655 9.75 17.8489 10.0626 16.8398 10.4501C15.8785 10.8194 14.7537 11.25 13.5 11.25C12.6282 11.25 11.9577 10.8394 11.4379 10.3353C10.9503 9.86239 10.5392 9.25092 10.1807 8.71757L10.1477 8.66858C9.75892 8.09054 9.42387 7.60069 9.05198 7.24547C8.69781 6.90718 8.37043 6.75 8 6.75C7.93359 6.75 7.76411 6.78057 7.50694 7.16325C7.25223 7.54228 7.00589 8.13726 6.77738 8.94055C6.32311 10.5375 6.00007 12.7302 5.7207 15.0752C5.61014 16.0033 5.50605 16.959 5.40321 17.9035C5.24992 19.3111 5.09937 20.6936 4.93423 21.9211C4.79527 22.9542 4.64259 23.906 4.463 24.7053C4.28758 25.4861 4.07165 26.1933 3.77686 26.6898C3.76456 26.7105 3.7518 26.7306 3.73862 26.75H31C31.1381 26.75 31.25 26.8619 31.25 27C31.25 27.1381 31.1381 27.25 31 27.25H0.75V5C0.75 4.86193 0.861929 4.75 1 4.75Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M16.4707 15.588C16.9747 15.588 17.4187 15.692 17.8027 15.9C18.1867 16.108 18.4827 16.404 18.6907 16.788C18.9067 17.164 19.0147 17.6 19.0147 18.096C19.0147 18.584 18.9067 19.016 18.6907 19.392C18.4747 19.768 18.1667 20.06 17.7667 20.268C17.3747 20.468 16.9187 20.568 16.3987 20.568H14.4787C14.4387 20.568 14.4187 20.588 14.4187 20.628V23.856C14.4187 23.896 14.4027 23.932 14.3707 23.964C14.3467 23.988 14.3147 24 14.2747 24H13.1707C13.1307 24 13.0947 23.988 13.0627 23.964C13.0387 23.932 13.0267 23.896 13.0267 23.856V15.732C13.0267 15.692 13.0387 15.66 13.0627 15.636C13.0947 15.604 13.1307 15.588 13.1707 15.588H16.4707ZM16.2667 19.44C16.6747 19.44 17.0027 19.32 17.2507 19.08C17.4987 18.84 17.6227 18.524 17.6227 18.132C17.6227 17.732 17.4987 17.408 17.2507 17.16C17.0027 16.912 16.6747 16.788 16.2667 16.788H14.4787C14.4387 16.788 14.4187 16.808 14.4187 16.848V19.38C14.4187 19.42 14.4387 19.44 14.4787 19.44H16.2667Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M20.3794 24C20.3394 24 20.3034 23.988 20.2714 23.964C20.2474 23.932 20.2354 23.896 20.2354 23.856V15.744C20.2354 15.704 20.2474 15.672 20.2714 15.648C20.3034 15.616 20.3394 15.6 20.3794 15.6H23.3074C23.8754 15.6 24.3754 15.7 24.8074 15.9C25.2394 16.1 25.5714 16.388 25.8034 16.764C26.0434 17.132 26.1634 17.564 26.1634 18.06V21.54C26.1634 22.036 26.0434 22.472 25.8034 22.848C25.5714 23.216 25.2394 23.5 24.8074 23.7C24.3754 23.9 23.8754 24 23.3074 24H20.3794ZM21.6274 22.74C21.6274 22.78 21.6474 22.8 21.6874 22.8H23.3674C23.7914 22.8 24.1274 22.668 24.3754 22.404C24.6314 22.132 24.7634 21.772 24.7714 21.324V18.288C24.7714 17.832 24.6434 17.472 24.3874 17.208C24.1314 16.936 23.7874 16.8 23.3554 16.8H21.6874C21.6474 16.8 21.6274 16.82 21.6274 16.86V22.74Z"
      fill={props.fill}
    />
  </svg>
);

PidPd.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default PidPd;
