/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const AddLabel: React.FC<Props> = (props) => (
  <svg viewBox="0 0 24 24" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.25 9.5C2.25 8.5335 3.0335 7.75 4 7.75H18C18.9665 7.75 19.75 8.5335 19.75 9.5V11C19.75 11.4142 19.4142 11.75 19 11.75C18.5858 11.75 18.25 11.4142 18.25 11V9.5C18.25 9.36193 18.1381 9.25 18 9.25H4C3.86193 9.25 3.75 9.36193 3.75 9.5V15.5C3.75 15.6381 3.86193 15.75 4 15.75H13.8235C14.2377 15.75 14.5735 16.0858 14.5735 16.5C14.5735 16.9142 14.2377 17.25 13.8235 17.25H4C3.0335 17.25 2.25 16.4665 2.25 15.5V9.5Z"
      fill={props.fill}
    />
    <path
      d="M19 13.25C19.4142 13.25 19.75 13.5858 19.75 14V15.75H21.5C21.9142 15.75 22.25 16.0858 22.25 16.5C22.25 16.9142 21.9142 17.25 21.5 17.25H19.75V19C19.75 19.4142 19.4142 19.75 19 19.75C18.5858 19.75 18.25 19.4142 18.25 19V17.25H16.5C16.0858 17.25 15.75 16.9142 15.75 16.5C15.75 16.0858 16.0858 15.75 16.5 15.75H18.25V14C18.25 13.5858 18.5858 13.25 19 13.25Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M7 10.25C5.75736 10.25 4.75 11.2574 4.75 12.5C4.75 13.7426 5.75736 14.75 7 14.75C8.24264 14.75 9.25 13.7426 9.25 12.5C9.25 11.2574 8.24264 10.25 7 10.25ZM6.25 12.5C6.25 12.0858 6.58579 11.75 7 11.75C7.41421 11.75 7.75 12.0858 7.75 12.5C7.75 12.9142 7.41421 13.25 7 13.25C6.58579 13.25 6.25 12.9142 6.25 12.5Z"
      fill={props.fill}
    />
  </svg>
);

AddLabel.defaultProps = {
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default AddLabel;
