import { t } from '@lingui/macro';
import { useAppSelector } from 'app/hooks';
import React from 'react';
import { isAdminUser } from 'ui/auth/utils';
import Button from 'ui/common/Button/Button';
import TooltipButton from 'ui/common/Button/TooltipButton';
import {
  ActionButtonGroup,
  ActionsSpacer,
} from 'ui/common/Button/actionButtonStyles';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import { Download } from 'ui/common/Icons/Standard';
import { ProjectFilter } from 'util/projectFilterUtils';
import { DeleteProjectButton } from '../common/DeleteProjectButton';
import { NewProjectButton } from '../common/NewProjectButton';
import { DuplicateProjectButton } from './buttons/DuplicateProjectButton';
import { ManageMembersProjectButton } from './buttons/ManageMembersProjectButton';
import { PublishProjectButton } from './buttons/PublishProjectButton';
import { RenameProjectButton } from './buttons/RenameProjectButton';

type Props = {
  projectFilter: ProjectFilter;
  selectionState: Record<string, boolean>;
  numSelected: number;
};

export const ProjectListButtons: React.FC<Props> = ({
  projectFilter,
  selectionState,
  numSelected,
}: Props) => {
  const { developerModeEnabled } = useAppSelector(
    (state) => state.userOptions.options,
  );
  const hasSelected = numSelected > 0;

  const downloadTooltip = t({
    id: 'dashboard.projects.downloadProjectButton.Label',
    message: 'Download Project',
  });

  return (
    <ActionButtonGroup>
      {projectFilter === 'mine' && hasSelected && (
        <DeleteProjectButton
          selectionState={selectionState}
          numSelected={numSelected}
        />
      )}

      <ActionsSpacer />

      {projectFilter === 'mine' && !hasSelected && <NewProjectButton />}
      {projectFilter === 'mine' && hasSelected && (
        <RenameProjectButton
          projectFilter={projectFilter}
          selectionState={selectionState}
          numSelected={numSelected}
        />
      )}
      {hasSelected && (
        <DuplicateProjectButton
          projectFilter={projectFilter}
          selectionState={selectionState}
          numSelected={numSelected}
        />
      )}
      <TooltipButton
        tooltip={downloadTooltip}
        Icon={Download}
        largeIcon
        variant={ButtonVariants.LargeSecondary}
        disabled
      />
      {developerModeEnabled && hasSelected && (
        <PublishProjectButton
          projectFilter={projectFilter}
          selectionState={selectionState}
          numSelected={numSelected}
        />
      )}
      {projectFilter === 'mine' && hasSelected && (
        <ManageMembersProjectButton
          projectFilter={projectFilter}
          selectionState={selectionState}
          numSelected={numSelected}
        />
      )}

      {isAdminUser() && (
        <Button
          variant={ButtonVariants.LargePrimary}
          onClick={() => {
            localStorage.setItem('justAcceptedToS', 'true');
            window.location.href = '/';
          }}>
          Start tour
        </Button>
      )}
    </ActionButtonGroup>
  );
};
