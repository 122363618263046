/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const HighVoltagebattery: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.5 16C6.5 15.7239 6.72386 15.5 7 15.5H11C11.2761 15.5 11.5 15.7239 11.5 16C11.5 16.2761 11.2761 16.5 11 16.5H7C6.72386 16.5 6.5 16.2761 6.5 16Z"
      fill={props.fill}
    />
    <path
      d="M23.5 14C23.5 13.7239 23.2761 13.5 23 13.5C22.7239 13.5 22.5 13.7239 22.5 14V15.5H21C20.7239 15.5 20.5 15.7239 20.5 16C20.5 16.2761 20.7239 16.5 21 16.5H22.5V18C22.5 18.2761 22.7239 18.5 23 18.5C23.2761 18.5 23.5 18.2761 23.5 18V16.5H25C25.2761 16.5 25.5 16.2761 25.5 16C25.5 15.7239 25.2761 15.5 25 15.5H23.5V14Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M6.5 7V9H5C4.44772 9 4 9.44772 4 10V24C4 24.5523 4.44772 25 5 25H27C27.5523 25 28 24.5523 28 24V10C28 9.44772 27.5523 9 27 9H25.5V7C25.5 6.72386 25.2761 6.5 25 6.5H21C20.7239 6.5 20.5 6.72386 20.5 7V9H11.5V7C11.5 6.72386 11.2761 6.5 11 6.5H7C6.72386 6.5 6.5 6.72386 6.5 7ZM8 8V9H10V8H8ZM22 9H24V8H22V9ZM5.5 10.5V23.5H26.5V10.5H5.5Z"
      fill={props.fill}
    />
  </svg>
);

HighVoltagebattery.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default HighVoltagebattery;
