/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  stroke?: any;
  strokeWidth?: any;
  strokeLinecap?: any;
  strokeLinejoin?: any;
  className?: string;
}

const Extendedkalmanfilter: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.25 3C3.25 2.86193 3.13807 2.75 3 2.75C2.86193 2.75 2.75 2.86193 2.75 3V29.25H29C29.1381 29.25 29.25 29.1381 29.25 29C29.25 28.8619 29.1381 28.75 29 28.75H3.25V3Z"
      fill={props.fill}
    />
    <path
      d="M28 11.5C26.4674 11.5 26.4674 19 23.913 19C21.3587 19 22.3804 12.5 19.8261 12.5C17.2717 12.5 17.4988 18.2 15.2283 18.2C12.9577 18.2 13.1848 13.7 11.1413 13.7C9.32239 13.7 9.6087 16.7 7.56522 16.7C5.52174 16.7 6.03261 16 4.5 16"
      stroke={props.stroke}
      strokeWidth="1.5"
      strokeLinecap={props.strokeLinecap}
    />
    <path
      d="M28 8.33721L27.4894 19.6395L26.9787 7.5L26.4681 25.5L25.9574 8.75581L25.4468 21.5814L24.9362 14.1977L24.4255 25.0814L23.9149 16.5581L23.4043 25.0814L22.8936 15.0349L22.383 22.9884L21.8723 12.9419L21.3617 24.2442L20.8511 9.17442L20.3404 15.4535L19.8298 9.59302L19.3191 20.8953L18.8085 10.4302L18.2979 15.4535L17.7872 11.2674L17.2766 22.5698L16.766 12.1047L16.2553 22.9884L15.7447 17.1279L15.234 21.314L14.7234 15.4535L14.2128 20.4767L13.7021 14.6163L13.1915 18.8023L12.6809 12.5233L12.1702 17.9651L11.6596 12.9419L11.1489 16.2907L10.6383 10.4302L10.1277 18.8023L9.61702 12.9419L9.10638 17.9651L8.59575 15.4535L8.08511 20.0581L7.57447 17.1279L7.06383 18.8023L6.55319 15.0349L6.04255 19.2209L5.53192 14.1977L5.02128 17.9651L4.51064 13.3605L4 15.8721"
      stroke={props.stroke}
      strokeWidth={props.strokeWidth}
      strokeLinecap={props.strokeLinecap}
      strokeLinejoin={props.strokeLinejoin}
    />
  </svg>
);

Extendedkalmanfilter.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  stroke: '#1D3131',
  strokeWidth: '0.5',
  strokeLinecap: 'round',
  strokeLinejoin: 'round',
  className: '',
};

export default Extendedkalmanfilter;
