/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Model: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M15.6583 4.32428C15.879 4.24401 16.121 4.24401 16.3417 4.32428L27.0917 8.23337C27.4869 8.37708 27.75 8.75266 27.75 9.17317V22.823C27.75 23.2435 27.4869 23.619 27.0917 23.7627L16.3417 27.6718C16.121 27.7521 15.879 27.7521 15.6583 27.6718L4.90826 23.7627C4.51307 23.619 4.25 23.2435 4.25 22.823V9.17317C4.25 8.75266 4.51307 8.37708 4.90826 8.23337L15.6583 4.32428ZM5.75 10.2615L9.75 12.4433V18.981L15.25 21.481V25.9273L5.75 22.4727V10.2615ZM16.75 25.9273L26.25 22.4727V10.2615L22.25 12.4433V18.981L16.75 21.481V25.9273ZM16 20.1742L19.6875 18.4981L16 16.8219L12.3125 18.4981L16 20.1742ZM11.25 17.3333V12.5234L15.25 11.0688V15.5151L11.25 17.3333ZM16.75 15.5151L20.75 17.3333V12.5234L16.75 11.0688V15.5151ZM21.4381 11.1775L25.1824 9.13516L16 5.79611L6.8176 9.13516L10.5619 11.1775L16 9.20001L21.4381 11.1775Z"
      fill={props.fill}
    />
  </svg>
);

Model.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: 'black',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Model;
