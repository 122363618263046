/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Squareroot: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M15.7356 27.9999L15.75 3.99988L16.25 4.00018L16.2356 28.0002L15.7356 27.9999Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M32 16.25L0 16.25L4.37112e08 15.75L32 15.75V16.25Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M32 9.13869C32 9.48759 31.7593 9.79038 31.4194 9.8691L29.9415 10.2114L27.8775 10.7293L26.0496 11.2281L24.4423 11.7069L23.0406 12.165L21.8135 12.6074L20.7614 13.0285L19.8693 13.4277L19.1068 13.8128L18.4746 14.1777L17.957 14.5241L17.5679 14.8308L17.2896 15.0922L17.0893 15.32L16.9502 15.5166L16.8537 15.6939L16.7974 15.8388L16.7598 16.0052L16.7482 16.1716C16.7193 16.5848 16.3609 16.8963 15.9477 16.8675C15.5345 16.8386 15.223 16.4802 15.2518 16.067L15.2674 15.8434C15.2701 15.8053 15.2757 15.7675 15.2841 15.7303L15.3466 15.4539C15.3547 15.4178 15.3655 15.3824 15.3789 15.348L15.4727 15.1064C15.4843 15.0765 15.4978 15.0473 15.5131 15.0191L15.6537 14.7608C15.6678 14.735 15.6833 14.71 15.7003 14.686L15.8878 14.4211C15.903 14.3996 15.9194 14.3789 15.9368 14.3591L16.1868 14.0748C16.2025 14.0569 16.2191 14.0398 16.2365 14.0234L16.5646 13.7152C16.5804 13.7004 16.5968 13.6863 16.6138 13.6729L17.0513 13.328C17.0666 13.316 17.0823 13.3046 17.0985 13.2937L17.661 12.9173C17.6748 12.908 17.6889 12.8992 17.7033 12.8909L18.3751 12.5032C18.3872 12.4963 18.3994 12.4896 18.4119 12.4834L19.2087 12.0809C19.2192 12.0756 19.2298 12.0705 19.2405 12.0657L20.1624 11.6532C20.1715 11.6491 20.1807 11.6452 20.19 11.6415L21.2682 11.2099C21.2762 11.2067 21.2844 11.2036 21.2925 11.2007L22.5425 10.75C22.5496 10.7475 22.5567 10.745 22.5639 10.7427L23.9858 10.278L24.0046 10.2722L25.6296 9.78803L25.6463 9.78326L27.4901 9.28019L27.505 9.27629L29.5964 8.75149L31.0814 8.40821C31.5513 8.29959 32 8.65642 32 9.13869Z"
      fill={props.fill}
    />
  </svg>
);

Squareroot.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Squareroot;
