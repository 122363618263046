import { t } from '@lingui/macro';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { DiagramFooterTab, uiFlagsActions } from 'app/slices/uiFlagsSlice';
import React from 'react';
import { AiAgent, Console, Output, Visualizer } from 'ui/common/Icons/Standard';
import { usePython } from 'ui/common/PythonProvider';
import { Spinner } from 'ui/common/Spinner';
import { usePortalPopup } from 'ui/common/Tooltip/usePortalPopup';
import { ChatOnboarding } from './ChatOnboarding';
import FooterButton from './FooterButton';

const DiagramFooterButtons: React.FC = () => {
  const dispatch = useAppDispatch();
  const diagramFooterTab = useAppSelector(
    (state) => state.uiFlags.diagramFooterTab,
  );

  const { triggerElRef, isPopupOpen, setIsPopupOpen } =
    usePortalPopup<HTMLDivElement>();

  const { pythonConsoleEnabled, mediaVisualizerEnabled, aiAssistantEnabled } =
    useAppSelector((state) => state.userOptions.options);
  const toggleTab = (tabClicked: DiagramFooterTab) => {
    if (diagramFooterTab === tabClicked) {
      // The user is using the tab to close the footer.
      dispatch(uiFlagsActions.setDiagramFooterTab(DiagramFooterTab.None));
    } else {
      dispatch(uiFlagsActions.setDiagramFooterTab(tabClicked));
    }
  };

  const { isReady: pythonIsReady } = usePython();

  const logCounts = useAppSelector(
    (state) => state.simResults.simulationLogCounts,
  );

  // TODO localize and figure out how to display nicely if there are a combination of
  // errors, warnings, and messages.
  // TODO add a number badge to display these more elegantly.
  const resultsSummary = `${
    logCounts.error > 0 ? `${logCounts.error} errors ` : ''
  }${logCounts.warn > 0 ? `${logCounts.warn} warnings ` : ''}${
    logCounts.info > 0 ? `${logCounts.info} messages` : ''
  }`;

  return (
    <>
      <FooterButton
        testId="visualizer-footer-button"
        tooltipText={t({
          id: 'footer.modelRenderer.visualizerTab.tooltip.text',
          message: 'Graphs of selected signals after running a simulation',
        })}
        name={t({
          id: 'footer.modelRenderer.visualizerTab.button.label',
          message: 'Visualizer',
        })}
        Icon={Visualizer}
        isSelected={diagramFooterTab === DiagramFooterTab.Visualizer}
        onClickHandler={() => toggleTab(DiagramFooterTab.Visualizer)}
      />
      {mediaVisualizerEnabled && (
        <FooterButton
          testId="media-visualizer-footer-button"
          tooltipText={t({
            id: 'footer.modelRenderer.mediaVisualizerTab.tooltip.text',
            message: 'Media Visualizer',
          })}
          name={t({
            id: 'footer.modelRenderer.mediaVisualizerTab.button.label',
            message: 'Media Visualizer',
          })}
          Icon={Visualizer}
          isSelected={diagramFooterTab === DiagramFooterTab.MediaVisualizer}
          onClickHandler={() => toggleTab(DiagramFooterTab.MediaVisualizer)}
        />
      )}
      <FooterButton
        testId="output-footer-button"
        tooltipText={t({
          id: 'footer.modelRenderer.outputTab.tooltip.text',
          message: 'Logs and results from compiling and running simulations',
        })}
        name={t({
          id: 'footer.modelRenderer.outputTab.button.label',
          message: 'Output',
        })}
        detailText={resultsSummary}
        Icon={Output}
        isSelected={diagramFooterTab === DiagramFooterTab.Output}
        onClickHandler={() => toggleTab(DiagramFooterTab.Output)}
      />
      {pythonConsoleEnabled && (
        <FooterButton
          testId="console-footer-button"
          tooltipText={t({
            id: 'footer.modelRenderer.consoleTab.tooltip.text',
            message: 'Console to execute Python code',
          })}
          name={t({
            id: 'footer.modelRenderer.consoleTab.button.label',
            message: 'Console',
          })}
          Icon={pythonIsReady ? Console : Spinner}
          isSelected={diagramFooterTab === DiagramFooterTab.Console}
          onClickHandler={() => toggleTab(DiagramFooterTab.Console)}
        />
      )}
      {aiAssistantEnabled && (
        <div ref={triggerElRef} style={{ height: '100%' }}>
          <ChatOnboarding anchor={triggerElRef.current} />
          <FooterButton
            testId="ai-assistant-footer-button"
            tooltipText={t({
              id: 'footer.modelRenderer.aiAssistantTab.tooltip.text',
              message: 'Console to interact with the AI Assistant',
            })}
            name={t({
              id: 'footer.modelRenderer.aiAssistantTab.button.label',
              message: 'Chat',
            })}
            Icon={pythonIsReady ? AiAgent : Spinner}
            isSelected={diagramFooterTab === DiagramFooterTab.AiAssistant}
            onClickHandler={() => toggleTab(DiagramFooterTab.AiAssistant)}
          />
        </div>
      )}
    </>
  );
};

export default DiagramFooterButtons;
