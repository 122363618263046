import { t } from '@lingui/macro';
import { useVisualizerAllData } from 'app/api/useVisualizerData';
import { DownsamplingAlgorithm } from 'app/apiGenerated/generatedApiTypes';
import { FetchError } from 'app/errors';
import React from 'react';
import { Download } from 'ui/common/Icons/Standard';
import { useNotifications } from 'ui/common/notifications/useNotifications';
import { Spinner } from 'ui/common/Spinner';
import { formatFileNameDate } from 'util/dateUtils';
import { downloadBlobFile } from 'util/fileUtils';

export function getDownloadDataButtonIcon(
  enableButton: boolean,
  isRunning: boolean,
): React.FC {
  if (isRunning) {
    return Spinner;
  }

  if (enableButton) {
    return Download;
  }

  return Download;
}

export function useDownloadResults() {
  const { showError } = useNotifications();

  const [dataDownloadLoading, setDataDownloadLoading] = React.useState(false);

  const { fetchAllData } = useVisualizerAllData();

  const downloadData = React.useCallback(
    async ({
      simulationId,
      modelId,
      modelName,
      signalNames,
      downsamplingAlgorithm,
    }: {
      simulationId: string;
      modelId: string;
      modelName: string;
      signalNames: string[];
      downsamplingAlgorithm?: DownsamplingAlgorithm;
    }) => {
      if (simulationId && modelId) {
        setDataDownloadLoading(true);
        try {
          const now = new Date();
          const rawFiles = await fetchAllData({
            simulationId,
            modelId,
            signalNames,
            downsamplingAlgorithm,
          });

          if (
            signalNames.length === 1 &&
            signalNames[0] === 'continuous_results.csv'
          ) {
            const fileName = `${modelName}_${formatFileNameDate(now)}.csv`;
            const text = rawFiles[0].text ?? '';
            // This splits the first occurrence of \n
            const [headers, data] = text.split(/\n(.*)/s);
            let finalHeaders;
            finalHeaders = headers;
            downloadBlobFile(
              new Blob([finalHeaders, '\n', data], { type: 'text/plain' }),
              fileName,
            );
          } else {
            rawFiles.forEach((file) => {
              if (!file.blob) {
                return;
              }
              downloadBlobFile(file.blob, file.filename);
            });
          }
        } catch (e: any) {
          let msg;
          if (e.response) {
            switch ((e as FetchError)?.response?.status) {
              case 413:
                msg = 'File too large';
                break;
              default:
                msg = (e as FetchError)?.response?.statusText;
                break;
            }
          } else {
            msg = e.message;
          }
          showError(
            t({
              id: 'navBar.downloadData.error',
              message: 'Failed to download data.',
            }),
            msg,
          );
        }
        setDataDownloadLoading(false);
      }
    },
    [showError, fetchAllData],
  );

  return {
    downloadData,
    dataDownloadLoading,
  };
}
