import { t } from '@lingui/macro';
import React from 'react';
import TooltipButton from 'ui/common/Button/TooltipButton';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import { Copy } from 'ui/common/Icons/Standard';
import { ProjectItemType } from 'ui/dashboard/dashboardTypes';
import { useProjectDetailActions } from 'ui/dashboard/projectDetail/useProjectDetailActions';
import { ButtonProps } from './ButtonProps';

export const ProjectElementDuplicateButton: React.FC<ButtonProps> = ({
  selectedItems,
  numSelected,
}) => {
  const { duplicateElement, type } = useProjectDetailActions(selectedItems);

  const hasValidType =
    type === ProjectItemType.MODEL ||
    type === ProjectItemType.SUBMODEL ||
    type === ProjectItemType.PROJECTFILE;

  const tooltip = t({
    id: 'dashboard.projectsDetailActionButtons.duplicateButton.Label',
    message: 'Duplicate',
  });

  if (numSelected === 1 && hasValidType) {
    return (
      <TooltipButton
        tooltip={tooltip}
        variant={ButtonVariants.SmallSecondary}
        testId="project-element-duplicate-button"
        Icon={Copy}
        largeIcon
        onClick={duplicateElement}
      />
    );
  }
  return (
    <TooltipButton
      tooltip={tooltip}
      variant={ButtonVariants.SmallSecondary}
      testId="project-element-duplicate-button"
      Icon={Copy}
      largeIcon
      disabled
    />
  );
};
