import React from 'react';

export type InputChangeEvent =
  | React.ChangeEvent<HTMLInputElement>
  | React.ChangeEvent<HTMLSelectElement>
  | React.ChangeEvent<HTMLTextAreaElement>;

const inputStringCallback =
  (cb: (s: string, e: InputChangeEvent) => void) =>
  (event: InputChangeEvent): void => {
    const {
      target: { value },
    } = event;
    cb(value, event);
  };

export default inputStringCallback;
