import styled from '@emotion/styled/macro';
import { useGetRequirementsQuery } from 'app/apiGenerated/generatedApi';
import { Project } from 'app/apiTransformers/convertAPIProjectToProject';
import { useAppDispatch } from 'app/hooks';
import { NavbarContext, uiFlagsActions } from 'app/slices/uiFlagsSlice';
import { useEffect } from 'react';
import { GlideDataGridPortal } from 'ui/busTypeEditor/BusTypeEditor';
import { EmptyStateBorderBox } from 'ui/common/EmptyState';
import { Spinner, SpinnerWrapper } from 'ui/common/Spinner';
import { H3, Title } from 'ui/common/typography/Typography';
import RequirementsTable from './RequirementsTable';

const RequirementsBackground = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.colors.grey[5]};
  overflow-y: auto;
`;

const RequirementsContainerBase = styled.div`
  margin: 0 65px;
  height: 100%;
  padding: 16px 12px;
  padding-top: 32px;
`;

const RequirementsContainer = styled(RequirementsContainerBase)`
  background-color: ${(props) => props.theme.colors.grey[2]};
  border: 1px solid ${(props) => props.theme.colors.grey[10]};
`;
const RequirementsContainerEmpty = RequirementsContainerBase;

const RequirementsManagerEmptyState = () => (
  <RequirementsContainerEmpty>
    <EmptyStateBorderBox>
      <H3>Project has no requirements</H3>
      <Title>Import the project&apos;s requirements to get started.</Title>
    </EmptyStateBorderBox>
  </RequirementsContainerEmpty>
);

type RequirementsEditorProps = {
  project: Project;
};

const RequirementsManager = ({ project }: RequirementsEditorProps) => {
  const dispatch = useAppDispatch();

  // TODO deal with re-import and collisions. isFetching.

  // Refetch on mount to ensure links are up to date.
  // Not necessary for Block Details since the link is part of the block, not requirements.
  // Block Details and Requirements Manager use the same query, but even if they use separate queries it's good to ignore the cache timeout here.
  const { data, isLoading } = useGetRequirementsQuery(
    {
      projectUuid: project.uuid,
      expand: true,
    },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  // TODO: remove in UI-1226
  useEffect(() => {
    dispatch(uiFlagsActions.setNavbarContext(NavbarContext.Requirements));
    return () => {
      dispatch(uiFlagsActions.setNavbarContext(NavbarContext.None));
    };
  }, [dispatch]);

  if (isLoading || !data) {
    return (
      <RequirementsBackground>
        <RequirementsContainer>
          <SpinnerWrapper>
            <Spinner />
          </SpinnerWrapper>
        </RequirementsContainer>
      </RequirementsBackground>
    );
  }

  return (
    <RequirementsBackground>
      {data.items.length > 0 ? (
        <RequirementsContainer>
          <RequirementsTable requirements={data.items} />
        </RequirementsContainer>
      ) : (
        <RequirementsManagerEmptyState />
      )}
      <GlideDataGridPortal />
    </RequirementsBackground>
  );
};

export default RequirementsManager;
