import styled from '@emotion/styled/macro';
import React from 'react';
import { H5, Small, SmallHighlight } from 'ui/common/typography/Typography';

export interface BlockItemDoc {
  id: string;
  name: string;
  description: string;
}

interface Props {
  sectionTitle: string;
  items: BlockItemDoc[];
}

const BlockItemsTitle = styled(H5)`
  margin-bottom: ${({ theme }) => theme.spacing.large};
  color: ${({ theme }) => theme.colors.text.primary};
`;

const BlockItem = styled.p`
  margin-bottom: ${({ theme }) => theme.spacing.large};
  color: ${({ theme }) => theme.colors.text.primary};
`;

export const BlockItemsDocumentation: React.FC<Props> = ({
  sectionTitle,
  items,
}) => {
  if (items.length === 0) {
    return null;
  }

  return (
    <>
      <BlockItemsTitle>{sectionTitle}</BlockItemsTitle>
      {items.map((item) => (
        <BlockItem key={item.id}>
          <SmallHighlight>{item.name}</SmallHighlight>
          {item.description && (
            <>
              {' — '}
              <Small>{item.description}</Small>
            </>
          )}
        </BlockItem>
      ))}
    </>
  );
};
