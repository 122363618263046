/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  stroke?: any;
  className?: string;
}

const Bus: React.FC<Props> = (props) => (
  <svg viewBox="0 0 16 16" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2 7.5C2 7.77614 2.22386 8 2.5 8H3.5C3.77614 8 4 7.77614 4 7.5V6.5C4 6.22386 3.77614 6 3.5 6H2.5C2.22386 6 2 6.22386 2 6.5V7.5Z"
      fill={props.fill}
    />
    <path
      d="M6 7.5C6 7.77614 6.22386 8 6.5 8H9.5C9.77614 8 10 7.77614 10 7.5V6.5C10 6.22386 9.77614 6 9.5 6H6.5C6.22386 6 6 6.22386 6 6.5V7.5Z"
      fill={props.fill}
    />
    <path
      d="M12 7.5C12 7.77614 12.2239 8 12.5 8H13.5C13.7761 8 14 7.77614 14 7.5V6.5C14 6.22386 13.7761 6 13.5 6H12.5C12.2239 6 12 6.22386 12 6.5V7.5Z"
      fill={props.fill}
    />
    <path
      d="M2 11.5C2 11.7761 2.22386 12 2.5 12H13.5C13.7761 12 14 11.7761 14 11.5V10.5C14 10.2239 13.7761 10 13.5 10H2.5C2.22386 10 2 10.2239 2 10.5V11.5Z"
      fill={props.fill}
    />
    <path
      d="M2 7.5C2 7.77614 2.22386 8 2.5 8H3.5C3.77614 8 4 7.77614 4 7.5V6.5C4 6.22386 3.77614 6 3.5 6H2.5C2.22386 6 2 6.22386 2 6.5V7.5Z"
      stroke={props.stroke}
    />
    <path
      d="M6 7.5C6 7.77614 6.22386 8 6.5 8H9.5C9.77614 8 10 7.77614 10 7.5V6.5C10 6.22386 9.77614 6 9.5 6H6.5C6.22386 6 6 6.22386 6 6.5V7.5Z"
      stroke={props.stroke}
    />
    <path
      d="M12 7.5C12 7.77614 12.2239 8 12.5 8H13.5C13.7761 8 14 7.77614 14 7.5V6.5C14 6.22386 13.7761 6 13.5 6H12.5C12.2239 6 12 6.22386 12 6.5V7.5Z"
      stroke={props.stroke}
    />
    <path
      d="M2 11.5C2 11.7761 2.22386 12 2.5 12H13.5C13.7761 12 14 11.7761 14 11.5V10.5C14 10.2239 13.7761 10 13.5 10H2.5C2.22386 10 2 10.2239 2 10.5V11.5Z"
      stroke={props.stroke}
    />
  </svg>
);

Bus.defaultProps = {
  width: '16',
  height: '16',
  viewBox: '0 0 16 16',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  stroke: '#020606',
  className: '',
};

export default Bus;
