/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  className?: string;
}

const Requirements: React.FC<Props> = (props) => (
  <svg viewBox="0 0 16 16" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.62397 4.42645C5.84867 4.58696 5.90072 4.89923 5.74021 5.12394L4.26114 7.19464C3.99971 7.56064 3.49502 7.65314 3.12078 7.40365L2.38933 6.91601C2.15956 6.76284 2.09748 6.4524 2.25065 6.22264C2.40383 5.99287 2.71426 5.93079 2.94403 6.08396L3.54121 6.48208L4.92648 4.5427C5.08698 4.318 5.39926 4.26595 5.62397 4.42645Z"
      fill={props.fill}
    />
    <path
      d="M13.1667 6.33332C13.1667 6.60946 12.9428 6.83332 12.6667 6.83332L7.16668 6.83332C6.89054 6.83332 6.66668 6.60946 6.66668 6.33332C6.66668 6.05718 6.89054 5.83332 7.16668 5.83332L12.6667 5.83332C12.9428 5.83332 13.1667 6.05718 13.1667 6.33332Z"
      fill={props.fill}
    />
    <path
      d="M13.1667 11C13.1667 11.2761 12.9428 11.5 12.6667 11.5L7.16668 11.5C6.89054 11.5 6.66668 11.2761 6.66668 11C6.66668 10.7238 6.89054 10.5 7.16668 10.5H12.6667C12.9428 10.5 13.1667 10.7238 13.1667 11Z"
      fill={props.fill}
    />
    <path
      d="M5.74021 9.79061C5.90072 9.5659 5.84867 9.25363 5.62397 9.09312C5.39926 8.93262 5.08698 8.98466 4.92648 9.20937L3.54121 11.1487L2.94403 10.7506C2.71426 10.5975 2.40383 10.6595 2.25065 10.8893C2.09748 11.1191 2.15956 11.4295 2.38933 11.5827L3.12078 12.0703C3.49502 12.3198 3.99971 12.2273 4.26114 11.8613L5.74021 9.79061Z"
      fill={props.fill}
    />
  </svg>
);

Requirements.defaultProps = {
  width: '16',
  height: '16',
  viewBox: '0 0 16 16',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  className: '',
};

export default Requirements;
