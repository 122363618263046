import { Coordinate } from 'app/common_types/Coordinate';
import { MouseActions } from 'app/common_types/MouseTypes';
import { getCurrentModelRef } from 'app/sliceRefAccess/CurrentModelRef';
import { modelActions } from 'app/slices/modelSlice';
import { snapNumberToGrid } from 'app/utils/modelDataUtils';
import { RendererState } from '../modelRenderer';

export const setDrawingLinkHangPoint = (
  rs: RendererState,
  worldTargetCoord: Coordinate,
) => {
  if (rs.mouseState.state === MouseActions.DrawingLinkFromStart) {
    rs.dispatch(
      modelActions.setLinkHangCoordStart({
        linkUuid: rs.mouseState.linkUuid,
        coord: {
          x: snapNumberToGrid(worldTargetCoord.x),
          y: snapNumberToGrid(worldTargetCoord.y),
        },
      }),
    );
  } else if (rs.mouseState.state === MouseActions.DrawingLinkFromEnd) {
    rs.dispatch(
      modelActions.setLinkHangCoordEnd({
        linkUuid: rs.mouseState.linkUuid,
        coord: {
          x: snapNumberToGrid(worldTargetCoord.x),
          y: snapNumberToGrid(worldTargetCoord.y),
        },
      }),
    );
  }
};

export const leaveDrawingLinkHangingHandler = (
  rs: RendererState,
  worldTargetCoord: Coordinate,
) => {
  setDrawingLinkHangPoint(rs, worldTargetCoord);

  // we specifically don't use transitionMouseState here
  // because we don't want our link to get deleted
  rs.mouseState = { state: MouseActions.Idle };
  rs.dispatch(
    modelActions.setSelections({
      selectedBlockIds: [],
      selectionParentPath: getCurrentModelRef().submodelPath,
    }),
  );
};
