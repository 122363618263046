import React from 'react';
import { Route, Routes } from 'react-router-dom';
import AdminPage from 'ui/admin/AdminPage';
import ClerkWhitelist from 'ui/admin/ClerkWhitelist';
import ChatErrors from 'ui/admin/chat/ChatErrors';
import ChatSession from 'ui/admin/chat/ChatSession';
import GlobalProjects from 'ui/admin/globalProjects/GlobalProjects';
import GlobalUserOptions from 'ui/admin/globalUserOptions/GlobalUserOptions';
import UserOptionPage from 'ui/admin/globalUserOptions/UserOptionPage';
import { PreRegMetaList } from 'ui/admin/preRegMeta/PreRegMetaList';
import SimulationsQueue from 'ui/admin/simulationsQueue/SimulationsQueue';
import UserDetail from 'ui/admin/userDetail/UserDetail';
import { isAdminUser } from 'ui/auth/utils';
import ValidateRouteBusType from 'ui/busTypeEditor/ValidateRouteBusType';
import Home from 'ui/dashboard/Home';
import ProjectDetailLoader from 'ui/dashboard/projectDetail/ProjectDetailLoader';
import { ProjectIntegrations } from 'ui/dashboard/projectDetail/ProjectIntegrations';
import ProjectsList from 'ui/dashboard/projectsList/ProjectsList';
import { ValidateRouteDataExplorer } from 'ui/dataExplorer/ValidateRouteDataExplorer';
import { ClaimInvite } from 'ui/invites';
import ModelVersionLoader from 'ui/modelEditor/ModelVersionLoader';
import ProjectsPage from 'ui/modelEditor/ProjectsPage';
import ValidateRouteProjectModel from 'ui/modelEditor/ValidateRouteProjectModel';
import { CodeEditorPage } from 'ui/projectFiles/codeEditor/CodeEditorPage';
import { MarkdownViewerPage } from 'ui/projectFiles/markdownViewer/MarkdownViewerPage';
import { NotebookViewerPage } from 'ui/projectFiles/notebookViewer/NotebookViewerPage';
import ValidateRouteRequirements from 'ui/requirements/ValidateRouteRequirements';
import GitHubCallback from 'ui/thirdparty/GitHubCallback';
import { TosPage } from 'ui/tos/TosPage';
import { getProjectFilterSubpath } from 'util/projectFilterUtils';

const AppRouter: React.FC = () => (
  <Routes>
    <Route path="/invites/*" element={<ClaimInvite />} />
    <Route path="/tos" element={<TosPage />} />
    {/* Admin routes */}
    {isAdminUser() && (
      <Route path="/admin" element={<AdminPage />}>
        <Route index element={<ClerkWhitelist />} />
        <Route path="users/:userId" element={<UserDetail />} />
        <Route path="user_options" element={<GlobalUserOptions />} />
        <Route path="user_options/:optionId" element={<UserOptionPage />} />
        <Route path="global_projects" element={<GlobalProjects />} />
        <Route path="simulations_queue" element={<SimulationsQueue />} />
        <Route path="chat_errors" element={<ChatErrors />} />
        <Route path="chat_session/:chatSessionId" element={<ChatSession />} />
        <Route path="pre_reg_meta" element={<PreRegMetaList />} />
      </Route>
    )}

    {/* Project routes */}
    <Route path="/projects" element={<ProjectsPage />}>
      {/* Dashboard sub routes */}

      {/* Make these project filters query params instead of path params bc that's what they are.
          We are not rendering new components for these paths.. just filtering the projects list.
          Specifying them as subroutes makes grouping logic impossible. For example, none of these list pages need to validate a project while the rest do.
          Also has implications for the highlight and nav behavior. */}
      <Route index element={<ProjectsList projectFilter="mine" />} />
      <Route
        path={getProjectFilterSubpath('sharedWithMe')}
        element={<ProjectsList projectFilter="sharedWithMe" />}
      />
      <Route
        path={getProjectFilterSubpath('public')}
        element={<ProjectsList projectFilter="public" />}
      />

      <Route path=":projectId">
        <Route index element={<ProjectDetailLoader />} />
        {/* Documents */}
        <Route path="models/:modelId" element={<ValidateRouteProjectModel />}>
          <Route path="versions/:versionId" element={<ModelVersionLoader />} />
        </Route>
        <Route path="code_editor/:fileUuid" element={<CodeEditorPage />} />
        <Route path="notebook/:fileUuid" element={<NotebookViewerPage />} />
        <Route path="markdown/:fileUuid" element={<MarkdownViewerPage />} />
        <Route path="bus_types/:busTypeId" element={<ValidateRouteBusType />} />
        {/* Project wide settings */}
        <Route path="requirements" element={<ValidateRouteRequirements />} />
        <Route path="integrations" element={<ProjectIntegrations />} />
      </Route>

      {/* Data explorer sub route */}
      <Route
        path=":projectId/explorations/:explorationId"
        element={<ValidateRouteDataExplorer />}
      />

      {/* Python file editor sub route */}
    </Route>

    {/* GitHub and other third-party callbacks */}
    <Route path="/github/callback" element={<GitHubCallback />} />

    {/* If path is not found, redirect to dashboard (projects page) */}
    <Route path="*" element={<Home />} />
  </Routes>
);

export default AppRouter;
