/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const MatrixoperatorMultiply: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M8.09805 22C8.00472 22 7.94405 21.9533 7.91605 21.86L7.45405 20.362C7.43539 20.3247 7.41205 20.306 7.38405 20.306H3.68805C3.66005 20.306 3.63672 20.3247 3.61805 20.362L3.15605 21.86C3.12805 21.9533 3.06738 22 2.97405 22H1.57405C1.51805 22 1.47605 21.986 1.44805 21.958C1.42005 21.9207 1.41538 21.8693 1.43405 21.804L4.47205 12.34C4.50005 12.2467 4.56072 12.2 4.65405 12.2H6.40405C6.49739 12.2 6.55805 12.2467 6.58605 12.34L9.63805 21.804C9.64739 21.8227 9.65205 21.846 9.65205 21.874C9.65205 21.958 9.60072 22 9.49805 22H8.09805ZM4.05205 18.948C4.04272 19.004 4.06138 19.032 4.10805 19.032H6.95005C7.00605 19.032 7.02472 19.004 7.00605 18.948L5.56405 14.258C5.55472 14.2207 5.54072 14.202 5.52205 14.202C5.50339 14.202 5.48939 14.2207 5.48005 14.258L4.05205 18.948Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M28.4961 16.89C28.4401 16.918 28.4401 16.946 28.4961 16.974C29.4481 17.422 29.9241 18.192 29.9241 19.284C29.9241 20.1707 29.6254 20.8473 29.0281 21.314C28.4401 21.7713 27.6701 22 26.7181 22H23.1901C23.1434 22 23.1014 21.986 23.0641 21.958C23.0361 21.9207 23.0221 21.8787 23.0221 21.832V12.368C23.0221 12.3213 23.0361 12.284 23.0641 12.256C23.1014 12.2187 23.1434 12.2 23.1901 12.2H26.6061C27.6234 12.2 28.4074 12.4193 28.9581 12.858C29.5087 13.2967 29.7841 13.9593 29.7841 14.846C29.7841 15.826 29.3547 16.5073 28.4961 16.89ZM24.7161 13.6C24.6694 13.6 24.6461 13.6233 24.6461 13.67V16.26C24.6461 16.3067 24.6694 16.33 24.7161 16.33H26.6061C27.1007 16.33 27.4881 16.2133 27.7681 15.98C28.0574 15.7373 28.2021 15.406 28.2021 14.986C28.2021 14.5567 28.0574 14.2207 27.7681 13.978C27.4881 13.726 27.1007 13.6 26.6061 13.6H24.7161ZM26.7181 20.586C27.2034 20.586 27.5861 20.4553 27.8661 20.194C28.1554 19.9327 28.3001 19.578 28.3001 19.13C28.3001 18.6727 28.1554 18.318 27.8661 18.066C27.5767 17.8047 27.1847 17.674 26.6901 17.674H24.7161C24.6694 17.674 24.6461 17.6973 24.6461 17.744V20.516C24.6461 20.5627 24.6694 20.586 24.7161 20.586H26.7181Z"
      fill={props.fill}
    />
    <path
      d="M14.0304 13.9697C13.7375 13.6768 13.2626 13.6768 12.9697 13.9697C12.6768 14.2626 12.6768 14.7375 12.9697 15.0303L14.9394 17L12.9697 18.9697C12.6768 19.2626 12.6768 19.7375 12.9697 20.0303C13.2626 20.3232 13.7375 20.3232 14.0304 20.0303L16.0001 18.0607L17.9697 20.0303C18.2626 20.3232 18.7375 20.3232 19.0304 20.0303C19.3233 19.7375 19.3233 19.2626 19.0304 18.9697L17.0607 17L19.0304 15.0303C19.3233 14.7375 19.3233 14.2626 19.0304 13.9697C18.7375 13.6768 18.2626 13.6768 17.9697 13.9697L16.0001 15.9394L14.0304 13.9697Z"
      fill={props.fill}
    />
  </svg>
);

MatrixoperatorMultiply.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default MatrixoperatorMultiply;
