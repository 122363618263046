// !!!! -- DO NOT MANUALLY EDIT -- !!!!
// This file was generated by something in scripts/generateDefaultBlockData.js
// If you want to change the contents of this file, it should be automated
//
// (You can edit this file locally, but it may not be checked into the codebase -
// PRs with a manual edit of this file are forbidden from being merged.)

import { computationBlockDefaults } from 'app/generated_blocks/computationBlockDefaults';
import type { ComputationBlockClass } from 'app/generated_types/ComputationBlockClass';
import deepmerge from 'deepmerge';

export const LoopCounter: ComputationBlockClass = deepmerge(
  computationBlockDefaults,
  {
    "base": {
      "name": "LoopCounter",
      "namespace": "core",
      "description": "Acts as the (i=0;i<N;i+=increment) feature of a typical for loop.",
      "supports_iterator": true,
      "supports_models": false,
      "supports_submodels": false,
      "feature_level": "dev"
    },
    "modes": {
      "time": "any"
    },
    "ports": {
      "outputs": {
        "static": [
          {
            "name": "out_0"
          }
        ]
      }
    },
    "parameter_definitions": [
      {
        "name": "start_value",
        "description": "The value the counter starts at, e.g. for(i=value; ...",
        "data_type": "int",
        "default_value": "0"
      },
      {
        "name": "end_value",
        "description": "The value the counter stops at, e.g. for(i=0;i<value ...",
        "data_type": "int",
        "default_value": "0"
      },
      {
        "name": "increment",
        "description": "The value the counter steps by, e.g. for(i=0;i<N,i+=value) ...",
        "data_type": "int",
        "default_value": "1"
      }
    ]
  }
);
