import styled from '@emotion/styled/macro';
import { t } from '@lingui/macro';
import {
  MemberUpdatedRole,
  NewMember,
  useProjectPermissions,
} from 'app/api/useProjectPermissions';
import { ProjectPermissionRole } from 'app/apiGenerated/generatedApiTypes';
import { ProjectPermission } from 'app/apiTransformers/convertGetProjectPermissionReadAll';
import { useAppSelector } from 'app/hooks';
import React from 'react';
import Button from 'ui/common/Button/Button';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import { Plus } from 'ui/common/Icons/Standard';
import Separator from 'ui/common/Menu/items/Separator';
import {
  ActionButtonContainer,
  SmallFormContainer,
} from 'ui/common/Modal/Modal';
import { useModal } from 'ui/common/Modal/useModal';
import { Spinner, SpinnerWrapper } from 'ui/common/Spinner';
import { DetailsSection } from 'ui/modelEditor/DetailsComponents';
import { useProjectPermission } from 'ui/permission/useProjectPermission';
import { v4 as uuid } from 'uuid';
import MemberItem from './MemberItem';

const FormContentWrapper = styled.div`
  padding-bottom: ${({ theme }) => `${theme.spacing.large}`};
  padding-top: ${({ theme }) => `${theme.spacing.large}`};
`;

interface Props {
  projectId: string;
}

const ManageMembersModal: React.FC<Props> = ({ projectId }: Props) => {
  const { projectPermissions, doBulkUpdate } = useProjectPermissions(projectId);

  const { closeModal } = useModal();

  const { canAdministrateProject } = useProjectPermission(projectId);
  const userId = useAppSelector((state) => state.user.userId);

  const [inProgressPermissions, setInProgressPermissions] = React.useState<
    ProjectPermission[] | null
  >(null);
  const [newMembers, setNewMembers] = React.useState<NewMember[]>([]);
  const [memberRoleChanges, setMemberRoleChanges] = React.useState<
    MemberUpdatedRole[]
  >([]);
  const [removedMembers, setRemovedMembers] = React.useState<string[]>([]);

  const [canSave, setCanSave] = React.useState<boolean>(false);
  const [isSaving, setIsSaving] = React.useState<boolean>(false);
  const [isUpdatingOwnPermission, setIsUpdatingOwnPermission] =
    React.useState<boolean>(false);

  // Copy the server based permissions into a local copy for the user
  // to edit while in the dialog.
  React.useEffect(() => {
    // If the permissions aren't ready yet, there is nothing to load.
    if (!projectPermissions) return;

    // If we've already loaded the permissions, there is nothing to do.
    if (inProgressPermissions) return;

    // Make a local copy of the permissions so that if the user cancels the dialog
    // the permissions are unaffected.
    setInProgressPermissions(
      projectPermissions.map((permission) => ({ ...permission })),
    );
  }, [projectPermissions, inProgressPermissions]);

  const onSave = async () => {
    if (!canSave) return;

    setIsSaving(true);

    await doBulkUpdate(
      {
        projectId: projectId || '',
        membersToAdd: newMembers,
        membersToUpdate: memberRoleChanges,
        membersToRemove: removedMembers,
      },
      isUpdatingOwnPermission,
    );
    closeModal();
  };

  // Run a validation check when the user makes a change.
  React.useEffect(() => {
    const hasChanges =
      newMembers.length > 0 ||
      memberRoleChanges.length > 0 ||
      removedMembers.length > 0;
    setCanSave(
      !isSaving && newMembers.every((member) => member.isValid) && hasChanges,
    );
  }, [isSaving, newMembers, memberRoleChanges, removedMembers]);

  const currentNumberOfAdmins =
    inProgressPermissions?.filter(
      (permission: ProjectPermission) =>
        permission.role === 'admin' && permission.memberUuid,
    ).length || 0;

  const canRemoveAdmin = currentNumberOfAdmins > 1;

  const onAddNewMember = () => {
    const newMember: NewMember = {
      id: uuid(),
      role: 'read_only',
      email: '',
      isValid: false,
    };
    setNewMembers([...newMembers, newMember]);
  };

  const onChangeRole = (id: string, role: ProjectPermissionRole) => {
    const permission = inProgressPermissions?.find(
      (permission) => permission.id === id,
    );
    if (permission) {
      permission.role = role;
      const existingRoleChange = memberRoleChanges.find(
        (roleChange) => roleChange.id === id,
      );
      if (existingRoleChange) {
        existingRoleChange.role = role;
        setMemberRoleChanges([...memberRoleChanges]);
      } else {
        setMemberRoleChanges([...memberRoleChanges, { id, role }]);
      }
      if (permission.memberUuid === userId) {
        setIsUpdatingOwnPermission(true);
      }
      return;
    }

    const newMember = newMembers.find((newMember) => newMember.id === id);
    if (newMember) {
      newMember.role = role;
      setNewMembers([...newMembers]);
    }
  };

  const onChangeEmail = (id: string, email: string, isValid: boolean) => {
    const newMember = newMembers.find((newMember) => newMember.id === id);
    if (newMember) {
      newMember.email = email;
      newMember.isValid = isValid;
      setNewMembers([...newMembers]);
    }
  };

  const onRemove = (id: string) => {
    if (inProgressPermissions) {
      const permissionIndex = inProgressPermissions.findIndex(
        (permission) => permission.id === id,
      );
      if (permissionIndex > -1) {
        if (inProgressPermissions[permissionIndex].memberUuid === userId) {
          setIsUpdatingOwnPermission(true);
        }
        inProgressPermissions.splice(permissionIndex, 1);
        setInProgressPermissions([...inProgressPermissions]);
        setRemovedMembers([...removedMembers, id]);
        return;
      }
    }

    const newMemberIndex = newMembers.findIndex(
      (newMember) => newMember.id === id,
    );
    if (newMemberIndex > -1) {
      newMembers.splice(newMemberIndex, 1);
      setNewMembers([...newMembers]);
    }
  };

  return (
    <SmallFormContainer
      onSubmit={(e) => {
        e?.preventDefault();
        onSave();
      }}>
      <Separator />

      <FormContentWrapper>
        {/* Existing permissions that the server is aware of */}
        {inProgressPermissions &&
          inProgressPermissions.map((permission: ProjectPermission) => (
            <MemberItem
              key={permission.id}
              memberRole={permission.role}
              memberName={permission.memberName}
              email={permission.email}
              memberProfileImageUrl={permission.memberProfileImageUrl}
              canChangeRole={
                canAdministrateProject &&
                (permission.role !== 'admin' ||
                  !permission.memberUuid ||
                  canRemoveAdmin)
              }
              onChangeRole={(role) => onChangeRole(permission.id, role)}
              canRemove={
                (canAdministrateProject || permission.memberUuid === userId) &&
                (permission.role !== 'admin' || canRemoveAdmin)
              }
              onRemove={() => onRemove(permission.id)}
            />
          ))}

        {/* New permissions that are client side only */}
        {newMembers.map((newMember) => (
          <MemberItem
            key={newMember.id}
            memberRole={newMember.role}
            email={newMember.email}
            canChangeEmail
            onChangeEmail={(email, isValid) =>
              onChangeEmail(newMember.id, email, isValid)
            }
            canChangeRole
            onChangeRole={(role) => onChangeRole(newMember.id, role)}
            canRemove
            onRemove={() => onRemove(newMember.id)}
          />
        ))}

        {!isSaving && canAdministrateProject && (
          <DetailsSection>
            {/* Add new member button */}
            <Button
              type="button"
              variant={ButtonVariants.SmallTertiary}
              Icon={Plus}
              onClick={onAddNewMember}>
              {t({
                id: 'manageMembersModal.addAMemberButton.label',
                message: 'Add a member',
              })}
            </Button>
          </DetailsSection>
        )}

        {/* Saving spinner */}
        {isSaving && (
          <SpinnerWrapper>
            <Spinner />
          </SpinnerWrapper>
        )}
      </FormContentWrapper>

      <ActionButtonContainer>
        {/* Cancel button */}
        <Button
          type="button"
          onClick={closeModal}
          variant={ButtonVariants.LargeSecondary}
          testId="simulation-settings-cancel-button">
          {t({
            id: 'manageMembersModal.cancelButton.label',
            message: 'Cancel',
          })}
        </Button>

        {/* Save button */}
        <Button
          type="submit"
          disabled={!canSave}
          variant={ButtonVariants.LargePrimary}
          testId="simulation-settings-apply-button">
          {t({
            id: 'manageMembersModal.saveButton.label',
            message: 'Save',
          })}
        </Button>
      </ActionButtonContainer>
    </SmallFormContainer>
  );
};

export default ManageMembersModal;
