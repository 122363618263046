/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Library: React.FC<Props> = (props) => (
  <svg viewBox="0 0 24 24" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M4.25 6C4.25 5.58579 4.58579 5.25 5 5.25C6.88165 5.25 8.74158 5.78435 10.135 6.44785C10.8318 6.77968 11.4396 7.15686 11.8865 7.53993C11.925 7.57294 11.9629 7.60646 12 7.64049C12.0371 7.60646 12.075 7.57294 12.1135 7.53993C12.5604 7.15686 13.1682 6.77968 13.865 6.44785C15.2584 5.78435 17.1183 5.25 19 5.25C19.4142 5.25 19.75 5.58579 19.75 6V17.5C19.75 17.9142 19.4142 18.25 19 18.25C17.5608 18.25 15.8873 18.4676 14.5965 18.8666C13.947 19.0673 13.4421 19.2996 13.1154 19.5392C12.7803 19.7849 12.75 19.9453 12.75 20H11.25C11.25 19.9453 11.2197 19.7849 10.8846 19.5392C10.5579 19.2996 10.053 19.0673 9.40352 18.8666C8.1127 18.4676 6.43916 18.25 5 18.25C4.58579 18.25 4.25 17.9142 4.25 17.5V6ZM11.25 18.0031C10.8298 17.7765 10.3484 17.5886 9.84648 17.4334C8.61569 17.053 7.12359 16.8281 5.75 16.767V6.78207C7.11533 6.89676 8.44868 7.30626 9.49005 7.80215C10.1057 8.09532 10.5916 8.40564 10.9103 8.67882C11.0704 8.81597 11.1695 8.92785 11.2231 9.00649C11.2359 9.02529 11.2445 9.03969 11.25 9.04986V18.0031ZM11.259 9.06847L11.2585 9.06724L11.259 9.06847ZM12.75 18.0031C13.1702 17.7765 13.6516 17.5886 14.1535 17.4334C15.3843 17.053 16.8764 16.8281 18.25 16.767V6.78207C16.8847 6.89676 15.5513 7.30626 14.5099 7.80215C13.8943 8.09532 13.4084 8.40564 13.0897 8.67882C12.9296 8.81597 12.8305 8.92785 12.7769 9.00649C12.7641 9.02529 12.7555 9.03969 12.75 9.04986V18.0031ZM12.7415 9.06725L12.741 9.06847L12.7415 9.06725Z"
      fill={props.fill}
    />
  </svg>
);

Library.defaultProps = {
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Library;
