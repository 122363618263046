// !!!! -- DO NOT MANUALLY EDIT -- !!!!
// This file was generated by something in scripts/generateDefaultBlockData.js
// If you want to change the contents of this file, it should be automated
//
// (You can edit this file locally, but it may not be checked into the codebase -
// PRs with a manual edit of this file are forbidden from being merged.)

import { computationBlockDefaults } from 'app/generated_blocks/computationBlockDefaults';
import type { ComputationBlockClass } from 'app/generated_types/ComputationBlockClass';
import deepmerge from 'deepmerge';

export const MatrixMultiplication: ComputationBlockClass = deepmerge(
  computationBlockDefaults,
  {
    "base": {
      "name": "MatrixMultiplication",
      "namespace": "core",
      "description": "This block outputs the result of multiplying a matrix/vector by a matrix/vector.",
      "help_url": "https://docs.collimator.ai/using-model-editors/block-library/agnostic-blocks#matrix-multiplication"
    },
    "modes": {
      "time": "agnostic"
    },
    "ports": {
      "inputs": {
        "static": [
          {
            "name": "in_0"
          },
          {
            "name": "in_1"
          }
        ]
      },
      "outputs": {
        "static": [
          {
            "name": "out_0"
          }
        ]
      }
    }
  }
);
