/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  className?: string;
}

const LogOutput: React.FC<Props> = (props) => (
  <svg viewBox="0 0 24 24" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.5 6.75C14.8097 6.75 15.9609 6.96371 16.757 7.28214C17.1571 7.44219 17.4306 7.61401 17.5917 7.76643C17.7502 7.91628 17.75 7.9954 17.75 7.99991C17.75 8.00668 17.7477 8.0861 17.5917 8.23357C17.4306 8.38599 17.1571 8.55781 16.757 8.71786C15.9609 9.03629 14.8097 9.25 13.5 9.25C12.722 9.25 12 9.17459 11.375 9.04662L13.0716 10.7432C13.2132 10.7477 13.3561 10.75 13.5 10.75C14.9517 10.75 16.3005 10.516 17.3141 10.1106C17.464 10.0506 17.6099 9.98555 17.75 9.9152V12C17.75 12.0068 17.7477 12.0861 17.5917 12.2336C17.4306 12.386 17.1571 12.5578 16.757 12.7179C15.9609 13.0363 14.8097 13.25 13.5 13.25C13.0285 13.25 12.5776 13.2223 12.1564 13.172L10.8589 14.4696C11.6528 14.6508 12.5546 14.75 13.5 14.75C14.9517 14.75 16.3005 14.516 17.3141 14.1106C17.464 14.0506 17.6099 13.9855 17.75 13.9152V16C17.75 16.0045 17.7502 16.0837 17.5917 16.2336C17.4306 16.386 17.1571 16.5578 16.757 16.7179C15.9609 17.0363 14.8097 17.25 13.5 17.25C12.1903 17.25 11.0391 17.0363 10.243 16.7179C9.84289 16.5578 9.56943 16.386 9.40825 16.2336C9.32739 16.1571 9.28784 16.099 9.2685 16.0599L8.91421 16.4142C8.66247 16.666 8.36031 16.8366 8.04024 16.926C8.13768 17.0733 8.25345 17.206 8.37759 17.3234C8.72245 17.6495 9.18122 17.9087 9.68592 18.1106C10.6995 18.516 12.0483 18.75 13.5 18.75C14.9517 18.75 16.3005 18.516 17.3141 18.1106C17.8188 17.9087 18.2775 17.6495 18.6224 17.3234C18.9688 16.9958 19.25 16.5488 19.25 16V8C19.25 7.45118 18.9688 7.00422 18.6224 6.67659C18.2775 6.35046 17.8188 6.09131 17.3141 5.88943C16.3005 5.484 14.9517 5.25 13.5 5.25C12.0483 5.25 10.6995 5.484 9.68592 5.88943C9.32936 6.03205 8.99572 6.20326 8.70898 6.40648C8.7804 6.4608 8.849 6.52057 8.91421 6.58579L9.81211 7.48369C9.93493 7.41596 10.0782 7.34805 10.243 7.28214C11.0391 6.96371 12.1903 6.75 13.5 6.75Z"
      fill={props.fill}
    />
    <path
      d="M8.03033 7.46967C7.73744 7.17678 7.26256 7.17678 6.96967 7.46967C6.67678 7.76256 6.67678 8.23744 6.96967 8.53033L9.18934 10.75H3.5C3.08579 10.75 2.75 11.0858 2.75 11.5C2.75 11.9142 3.08579 12.25 3.5 12.25H9.18934L6.96967 14.4697C6.67678 14.7626 6.67678 15.2374 6.96967 15.5303C7.26256 15.8232 7.73744 15.8232 8.03033 15.5303L12.0607 11.5L8.03033 7.46967Z"
      fill={props.fill}
    />
  </svg>
);

LogOutput.defaultProps = {
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  className: '',
};

export default LogOutput;
