/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  className?: string;
}

const CommentOut: React.FC<Props> = (props) => (
  <svg viewBox="0 0 24 24" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.6465 3.88026C18.8565 3.52324 18.7373 3.06356 18.3803 2.85355C18.0232 2.64353 17.5636 2.76271 17.3535 3.11973L7.35355 20.1197C7.14353 20.4768 7.26271 20.9364 7.61974 21.1464C7.97676 21.3565 8.43644 21.2373 8.64645 20.8803L9.60865 19.2445C9.63312 19.2481 9.65835 19.25 9.68426 19.25H13.75C14.0261 19.25 14.25 19.0261 14.25 18.75V18.25C14.25 17.9739 14.0261 17.75 13.75 17.75H10.4878L17.2525 6.25H18.5C18.6381 6.25 18.75 6.36193 18.75 6.5V7.96428C18.75 8.24043 18.9739 8.46428 19.25 8.46428H19.75C20.0261 8.46428 20.25 8.24043 20.25 7.96428V6.5C20.25 5.5335 19.4665 4.75 18.5 4.75H18.1348L18.6465 3.88026Z"
      fill={props.fill}
    />
    <path
      d="M3.75 6.5C3.75 5.5335 4.5335 4.75 5.5 4.75H14.9L14 6.25H5.5C5.36193 6.25 5.25 6.36193 5.25 6.5V17.5C5.25 17.6381 5.36193 17.75 5.5 17.75H7.2L6.3 19.25H5.5C4.5335 19.25 3.75 18.4665 3.75 17.5V6.5Z"
      fill={props.fill}
    />
    <path
      d="M19.75 10.0357C20.0261 10.0357 20.25 10.2596 20.25 10.5357V13.4643C20.25 13.7404 20.0261 13.9643 19.75 13.9643H19.25C18.9739 13.9643 18.75 13.7404 18.75 13.4643V10.5357C18.75 10.2596 18.9739 10.0357 19.25 10.0357H19.75Z"
      fill={props.fill}
    />
    <path
      d="M19.75 15.5357C20.0261 15.5357 20.25 15.7596 20.25 16.0357V17.5C20.25 18.4665 19.4665 19.25 18.5 19.25H16.75C16.4739 19.25 16.25 19.0261 16.25 18.75V18.25C16.25 17.9739 16.4739 17.75 16.75 17.75H18.5C18.6381 17.75 18.75 17.6381 18.75 17.5V16.0357C18.75 15.7596 18.9739 15.5357 19.25 15.5357H19.75Z"
      fill={props.fill}
    />
  </svg>
);

CommentOut.defaultProps = {
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  className: '',
};

export default CommentOut;
