import * as culori from 'culori';

export function lighten(color: string, percent: number): string {
  const f = parseInt(color.slice(1), 16);
  const t = percent < 0 ? 0 : 255;
  const p = percent < 0 ? percent * -1 : percent;
  const R = f >> 16;
  const G = (f >> 8) & 0x00ff;
  const B = f & 0x0000ff;
  return `#${(
    0x1000000 +
    (Math.round((t - R) * p) + R) * 0x10000 +
    (Math.round((t - G) * p) + G) * 0x100 +
    (Math.round((t - B) * p) + B)
  )
    .toString(16)
    .slice(1)}`;
}

export type ColorIntensity =
  | 5
  | 10
  | 15
  | 20
  | 30
  | 40
  | 50
  | 60
  | 70
  | 80
  | 85
  | 90
  | 95
  | 97
  | 99;

export function generateColor(color: string, intensity: ColorIntensity) {
  culori.rgb(color);
  const okhsl = culori.converter('okhsl');
  let base = okhsl(color);
  if (!base) return;

  // Return a Culori color object
  const c = {
    mode: 'okhsl',
    h: base.h,
    s: base.s,
    l: intensity / 100,
  };

  return culori.formatHex(c);
}
