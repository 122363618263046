import { t } from '@lingui/macro';
import { usePostSubmodelCopyMutation } from 'app/apiGenerated/generatedApi';
import { PostSubmodelCopyApiArg } from 'app/apiGenerated/generatedApiTypes';
import { useAppDispatch } from 'app/hooks';
import { submodelsActions } from 'app/slices/submodelsSlice';
import React from 'react';
import { useNotifications } from 'ui/common/notifications/useNotifications';

export function useDuplicateSubmodel() {
  const dispatch = useAppDispatch();

  const { createShowError, showCompletion } = useNotifications();

  const [callDuplicateSubmodelApi] = usePostSubmodelCopyMutation();

  const duplicateSubmodel = React.useCallback(
    (request: PostSubmodelCopyApiArg) => {
      const submodel = callDuplicateSubmodelApi(request)
        .unwrap()
        .then((payload) => {
          showCompletion(
            t({
              id: 'submodelsApi.duplicateSubmodelSuccess',
              message: 'Submodel duplicated',
            }),
          );
          return payload;
        })
        .catch((e) => {
          createShowError(
            t({
              id: 'submodelsApi.duplicateSubmodelError',
              message: 'Unable to duplicate submodel.',
            }),
          )(e);
          return undefined;
        })
        .finally(() => {
          // If there is a failure, make sure we clear out any optimistic updates
          // in the client state.
          dispatch(
            submodelsActions.requestSubmodels(
              request.submodelDuplicateRequest.destination_project_uuid,
            ),
          );
        });
      return submodel;
    },
    [callDuplicateSubmodelApi, dispatch, createShowError, showCompletion],
  );

  return {
    duplicateSubmodel,
  };
}
