import { useSignalTypeData } from 'app/api/useSignalTypeData';
import React from 'react';

interface Props {
  modelId: string;
  simulationId: string;
  correlationId?: string;
}

export const SignalTypePoller: React.FC<Props> = ({
  modelId,
  simulationId,
  correlationId,
}) => {
  useSignalTypeData(modelId, simulationId, correlationId);

  return null;
};
