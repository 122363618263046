import { t } from '@lingui/macro';
import {
  DiagramAutoSaveGroup,
  DiagramAutoSaveVersion,
} from 'app/apiTransformers/convertGetSnapshotReadAll';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
  VERSION_HISTORY_ITEM_BATCH_SIZE,
  versionHistoryActions,
} from 'app/slices/versionHistorySlice';
import React from 'react';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import {
  TreeArrowCollapsed,
  TreeArrowExpanded,
} from 'ui/common/Icons/Standard';
import ArrowDown from 'ui/common/Icons/Standard/ArrowDown';
import {
  AuthorAvatar,
  AuthorAvatarContainer,
  ShowMoreButton,
  VersionAuthor,
  VersionAutoSaveNameContainer,
  VersionExpanderButton,
  VersionInfoSection,
  VersionMetadata,
  VersionName,
  VersionSegment,
} from 'ui/versionHistory/DiagramVersionItemParts';
import DiagramVersionAutoSaveItem from './DiagramVersionAutoSaveItem';

interface Props {
  versionGroup: DiagramAutoSaveGroup;
  renameVersion: (
    modelUuid: string,
    versionUuid: string,
    newVersionName: string,
  ) => void;
  restoreVersion: (modelUuid: string, versionUuid: string) => void;
  canEditModelMetadata: boolean;
  projectId: string;
  urlModelId: string;
  urlVersionId?: string;
}

const DiagramVersionAutoSaveGroup: React.FC<Props> = ({
  versionGroup,
  renameVersion,
  restoreVersion,
  canEditModelMetadata,
  projectId,
  urlModelId,
  urlVersionId,
}) => {
  const dispatch = useAppDispatch();

  const versionId = versionGroup.autoSaveVersions[0].uuid;

  const lastOpenItemCount: number | undefined = useAppSelector(
    (state) =>
      state.versionHistory.expandedAutoSaveVersionsToItemCount[versionId],
  );

  const [isExpanded, setIsExpanded] = React.useState(!!lastOpenItemCount);
  const [numberOfItemsToDisplay, setNumberOfItemsToDisplay] =
    React.useState<number>(
      lastOpenItemCount || VERSION_HISTORY_ITEM_BATCH_SIZE,
    );
  const [canShowMore, setCanShowMore] = React.useState<boolean>(
    versionGroup.autoSaveVersions.length > numberOfItemsToDisplay,
  );
  const [itemsToDisplay, setItemsToDisplay] = React.useState<
    DiagramAutoSaveVersion[]
  >([]);

  React.useEffect(() => {
    if (lastOpenItemCount) {
      setNumberOfItemsToDisplay(lastOpenItemCount);
      setIsExpanded(true);
    } else {
      setNumberOfItemsToDisplay(VERSION_HISTORY_ITEM_BATCH_SIZE);
      setIsExpanded(false);
    }
  }, [lastOpenItemCount]);

  React.useEffect(() => {
    setCanShowMore(
      versionGroup.autoSaveVersions.length > numberOfItemsToDisplay,
    );
  }, [numberOfItemsToDisplay, versionGroup.autoSaveVersions.length]);

  React.useEffect(() => {
    setItemsToDisplay(
      versionGroup.autoSaveVersions.slice(0, numberOfItemsToDisplay),
    );
  }, [numberOfItemsToDisplay, versionGroup.autoSaveVersions]);

  const toggleAutoVersionsExpanded = () => {
    const nextIsExpanded = !isExpanded;

    if (nextIsExpanded) {
      dispatch(
        versionHistoryActions.setAutoSaveVersionExpanded({
          versionId,
        }),
      );
    } else {
      dispatch(
        versionHistoryActions.setAutoSaveVersionCollapsed({ versionId }),
      );
    }
  };

  const isVersionSelected =
    !isExpanded &&
    !!urlVersionId &&
    versionGroup.autoSaveVersions.some(
      (version) =>
        version.model_uuid === urlModelId && urlVersionId === version.uuid,
    );

  return (
    <>
      <VersionSegment
        isSelected={isVersionSelected}
        onClick={toggleAutoVersionsExpanded}>
        {/* Version metadata section */}
        <VersionInfoSection>
          <VersionExpanderButton
            variant={ButtonVariants.LargeTertiary}
            onClick={toggleAutoVersionsExpanded}
            Icon={isExpanded ? TreeArrowExpanded : TreeArrowCollapsed}
          />
          <AuthorAvatarContainer>
            <AuthorAvatar src={versionGroup.authorProfileImageUrl} />
          </AuthorAvatarContainer>

          <VersionMetadata>
            <VersionAutoSaveNameContainer>
              <VersionName isAutoSaveVersionGroup>
                {versionGroup.name}
              </VersionName>
            </VersionAutoSaveNameContainer>
            <VersionAuthor>{versionGroup.authorDisplayName}</VersionAuthor>
          </VersionMetadata>
        </VersionInfoSection>
      </VersionSegment>
      {isExpanded &&
        itemsToDisplay.map((version, index) => (
          <DiagramVersionAutoSaveItem
            key={version.uuid}
            version={version}
            restoreVersion={restoreVersion}
            renameVersion={renameVersion}
            canEditModelMetadata={canEditModelMetadata}
            projectId={projectId}
            urlModelId={urlModelId}
            urlVersionId={urlVersionId}
            nextItemId={itemsToDisplay[index + 1]?.uuid}
          />
        ))}
      {isExpanded && canShowMore && (
        <ShowMoreButton
          variant={ButtonVariants.SmallTertiary}
          RightIcon={ArrowDown}
          onClick={() =>
            dispatch(
              versionHistoryActions.showMoreAutoSaveVersions({
                versionId,
              }),
            )
          }>
          {t({
            id: 'diagramVersionHistory.showMore.button.label',
            message: 'Show more',
          })}
        </ShowMoreButton>
      )}
    </>
  );
};

export default DiagramVersionAutoSaveGroup;
