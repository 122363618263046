// !!!! -- DO NOT MANUALLY EDIT -- !!!!
// This file was generated by something in scripts/generateDefaultBlockData.js
// If you want to change the contents of this file, it should be automated
//
// (You can edit this file locally, but it may not be checked into the codebase -
// PRs with a manual edit of this file are forbidden from being merged.)

import { computationBlockDefaults } from 'app/generated_blocks/computationBlockDefaults';
import type { ComputationBlockClass } from 'app/generated_types/ComputationBlockClass';
import deepmerge from 'deepmerge';

export const PID_Discrete: ComputationBlockClass = deepmerge(
  computationBlockDefaults,
  {
    "base": {
      "name": "PID_Discrete",
      "namespace": "core",
      "description": "Discrete time PID controller",
      "help_url": "https://docs.collimator.ai/using-model-editors/block-library/discrete-blocks#pid-discrete",
      "supports_iterator": false
    },
    "modes": {},
    "ports": {
      "inputs": {
        "static": [
          {
            "name": "in_0"
          }
        ],
        "conditional": [
          {
            "name": "initial_state",
            "condition_bool_parameter": "enable_external_initial_state",
            "order": 1
          }
        ]
      },
      "outputs": {
        "static": [
          {
            "name": "out_0"
          }
        ]
      }
    },
    "parameter_definitions": [
      {
        "name": "Kp",
        "data_type": "float",
        "default_value": "1.0",
        "description": "Proportional gain."
      },
      {
        "name": "Ki",
        "data_type": "float",
        "default_value": "1.0",
        "description": "Integral gain."
      },
      {
        "name": "Kd",
        "data_type": "float",
        "default_value": "1.0",
        "description": "Derivative gain."
      },
      {
        "name": "enable_external_initial_state",
        "data_type": "bool",
        "default_value": "false",
        "description": "Source for the value used for the integrator initial state. True=from inport, False=from the initial_state parameter."
      },
      {
        "name": "initial_state",
        "data_type": "float",
        "default_value": "0.0",
        "description": "Initial value of the integrator state which sets the initial value of the I term."
      },
      {
        "name": "filter_type",
        "data_type": "string",
        "allowed_values": [
          "none",
          "forward",
          "backward",
          "bilinear"
        ],
        "default_value": "none",
        "description": "Type of filter used to estimate the derivative of the error signal."
      },
      {
        "name": "filter_coefficient",
        "data_type": "float",
        "default_value": "1.0",
        "description": "Filter coefficient for the derivative filter."
      },
      {
        "name": "tuning_time",
        "data_type": "float",
        "default_value": "0.0",
        "description": "Amount of time the auto-tune should run for.",
        "$comment": "Only for auto-tuning optimizations - not for normal simulations",
        "hidden": true
      }
    ]
  }
);
