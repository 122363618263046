import styled from '@emotion/styled';
import { t } from '@lingui/macro';
import { useAppSelector } from 'app/hooks';
import { Link } from 'react-router-dom';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import { ArrowRight } from 'ui/common/Icons/Standard';
import { useModal } from 'ui/common/Modal/useModal';
import ApiKeysModal from 'ui/navbar/ApiKeysModal';
import {
  AiAvatar,
  AssistantRow,
  MessageBlockDiv,
  SuggestionButton,
  SuggestionButtonRow,
} from './Styles';

const MessageDiv = styled.div`
  flex-direction: column;
  align-items: flex-start;
`;

export const WelcomeMessage = () => {
  const { developerModeEnabled } = useAppSelector(
    (state) => state.userOptions.options,
  );

  return (
    <AssistantRow key={-1}>
      <AiAvatar />
      <MessageDiv>
        <MessageBlockDiv>
          Hi, welcome to Collimator Chat! I&apos;m still a work in progress, but
          I can help you create, explain how something works, and assist you in
          optimizing your models.
        </MessageBlockDiv>
        {!developerModeEnabled && (
          <>
            <MessageBlockDiv>
              Collimator Chat is currently in beta, and may give inaccurate or
              misleading information, generate incorrect diagrams and code, or
              produce offensive language. Before using the output of Chat in a
              production system, you should verify it independently. Chat is
              subject to our <Link to="/tos">Terms of Service</Link> and{' '}
              <a
                href="https://www.collimator.ai/company/privacy-policy"
                target="_blank"
                rel="noreferrer">
                Privacy Policy
              </a>
              .
            </MessageBlockDiv>
            <MessageBlockDiv>
              Collimator Chat uses OpenAI&apos;s ChatGPT to power it. Please
              review OpenAI&apos;s{' '}
              <a
                href="https://openai.com/policies"
                target="_blank"
                rel="noreferrer">
                Terms of Service
              </a>{' '}
              and{' '}
              <a
                href="https://openai.com/policies"
                target="_blank"
                rel="noreferrer">
                Privacy Policy
              </a>{' '}
              before using Chat.
            </MessageBlockDiv>
          </>
        )}
      </MessageDiv>
    </AssistantRow>
  );
};

export const CallCountExceededMessage = () => {
  const { triggerModal } = useModal();

  const apiKeysModal = () => {
    triggerModal(<ApiKeysModal tab="openAiKey" />, undefined, {
      preventOverflow: true,
    });
  };

  return (
    <AssistantRow>
      <AiAvatar />
      <MessageDiv>
        <MessageBlockDiv>
          Thank you for trying Collimator Chat. You have now used up your
          complimentary prompts. To continue using this feature, go to{' '}
          <a
            href="https://platform.openai.com/account/billing/overview"
            target="_blank"
            rel="noreferrer">
            https://platform.openai.com/account/billing/overview
          </a>{' '}
          and add funds to your OpenAI account if needed. Then{' '}
          <a
            href="https://platform.openai.com/account/api-keys"
            target="_blank"
            rel="noreferrer">
            https://platform.openai.com/account/api-keys
          </a>{' '}
          and follow the instructions to generate an API Key for your account.
          Copy the secret to your clipboard, and return to this window to enter
          it here.
        </MessageBlockDiv>
        <SuggestionButtonRow>
          <SuggestionButton
            variant={ButtonVariants.SmallSecondary}
            Icon={ArrowRight}
            onClick={apiKeysModal}>
            {t`I want to enter my OpenAI API key`}
          </SuggestionButton>
        </SuggestionButtonRow>
      </MessageDiv>
    </AssistantRow>
  );
};

export const ProcessingMessage = () => (
  <AssistantRow>
    <AiAvatar spin />
    <MessageDiv>
      <MessageBlockDiv>
        {t`Please hold on while I process your request...`}
      </MessageBlockDiv>
    </MessageDiv>
  </AssistantRow>
);

export const OpenAiModelNotFoundMessage = () => (
  <AssistantRow>
    <AiAvatar />
    <MessageDiv>
      <MessageBlockDiv>
        Sorry, I&apos;m having trouble connecting to the chat service. Please
        check that you have funds in your account at{' '}
        <a
          href="https://platform.openai.com/account/billing/overview"
          target="_blank"
          rel="noreferrer">
          https://platform.openai.com/account/billing/overview
        </a>{' '}
        If your account has a balance, then you may need to generate a new
        secret API key at:{' '}
        <a
          href="https://platform.openai.com/api-keys"
          target="_blank"
          rel="noreferrer">
          https://platform.openai.com/api-keys
        </a>{' '}
      </MessageBlockDiv>
    </MessageDiv>
  </AssistantRow>
);
