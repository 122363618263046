/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Kernel: React.FC<Props> = (props) => (
  <svg viewBox="0 0 16 16" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M8.00001 7.99999H9.00001H11.6667C12.219 7.99999 12.6766 8.46054 12.5583 8.99999C12.4342 9.56568 12.2052 10.1062 11.8802 10.5927C11.3674 11.3601 10.6386 11.9582 9.78587 12.3114C8.93315 12.6646 7.99484 12.7571 7.08959 12.577C6.18435 12.3969 5.35283 11.9525 4.70018 11.2998C4.04754 10.6472 3.60308 9.81566 3.42301 8.91042C3.24295 8.00517 3.33537 7.06686 3.68857 6.21414C4.04178 5.36142 4.63992 4.63258 5.40735 4.1198C5.89376 3.7948 6.43433 3.56583 7.00001 3.44173C7.53947 3.32338 8.00001 3.78104 8.00001 4.33333V6.99999V7.99999ZM8.00001 8.99999H11.5277C11.4237 9.36662 11.2627 9.7168 11.0487 10.0371C10.6458 10.6401 10.0732 11.11 9.40318 11.3876C8.73319 11.6651 7.99594 11.7377 7.28468 11.5962C6.57342 11.4547 5.92008 11.1055 5.40729 10.5927C4.89449 10.0799 4.54528 9.42659 4.4038 8.71533C4.26232 8.00406 4.33493 7.26682 4.61245 6.59682C4.88998 5.92683 5.35994 5.35417 5.96292 4.95127C6.28321 4.73726 6.63338 4.57626 7.00001 4.47233V7.99999V8.99999H8.00001ZM11.7463 6.05772C11.9138 6.46213 12 6.89559 12 7.33333H8.66668V3.99999C9.10442 3.99999 9.53787 4.08621 9.94229 4.25373C10.3467 4.42125 10.7142 4.66678 11.0237 4.97631C11.3332 5.28583 11.5788 5.6533 11.7463 6.05772Z"
      fill={props.fill}
    />
  </svg>
);

Kernel.defaultProps = {
  width: '16',
  height: '16',
  viewBox: '0 0 16 16',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Kernel;
