import { SubmodelInfoUI } from 'app/apiTransformers/convertGetSubmodelsListForModelParent';
import {
  ModelDiagram,
  SubmodelInstance,
  Port,
  SubmodelsSection,
  NodeInstance,
} from 'app/generated_types/SimulationModel';
import { nodeTypeIsReferencedSubmodel } from 'app/helpers';
import { SubmodelPortDefinition } from 'app/apiGenerated/generatedApiTypes';
import { getSpecificReferenceSubmodelByNode } from 'app/utils/submodelUtils';

function buildUpdatedPorts(
  ports: Port[],
  portDefinitions: SubmodelPortDefinition[],
): Port[] {
  // Use existing ports wherever we can.
  const existingPortsByName: Record<string, Port> = {};
  for (let i = 0; i < ports.length; i++) {
    const port = ports[i];
    existingPortsByName[port.name] = port;
  }

  return portDefinitions.map((portDef) => {
    const existingPort = existingPortsByName[portDef.name];
    if (existingPort) {
      return existingPort;
    }

    return {
      name: portDef.name,
      kind: 'dynamic',
    };
  });
}

function doPortsMatchPortDefinitions(
  ports: Port[],
  portDefinitions: SubmodelPortDefinition[],
) {
  if (ports.length !== portDefinitions.length) {
    return false;
  }

  for (let i = 0; i < ports.length; i++) {
    const port = ports[i];
    const portDefinition = portDefinitions[i];
    if (port.name !== portDefinition.name) {
      return false;
    }
  }

  return true;
}

export function updateSubmodelInstanceForReferenceChanges(
  submodelInstance?: SubmodelInstance,
  submodelReference?: SubmodelInfoUI,
) {
  if (!submodelInstance || !submodelReference) {
    return;
  }

  // Fixup inputs if needed.
  const inputsMatch = doPortsMatchPortDefinitions(
    submodelInstance.inputs,
    submodelReference.portDefinitionsInputs,
  );
  if (!inputsMatch) {
    submodelInstance.inputs = buildUpdatedPorts(
      submodelInstance.inputs,
      submodelReference.portDefinitionsInputs,
    );
  }

  // Fixup outputs if needed.
  const outputsMatch = doPortsMatchPortDefinitions(
    submodelInstance.outputs,
    submodelReference.portDefinitionsOutputs,
  );
  if (!outputsMatch) {
    submodelInstance.outputs = buildUpdatedPorts(
      submodelInstance.outputs,
      submodelReference.portDefinitionsOutputs,
    );
  }
}

function removeDuplicateDiagramEntries(
  nodes: NodeInstance[],
  submodelsSection: SubmodelsSection,
) {
  for (let i = 0; i < nodes.length; i++) {
    const node = nodes[i];
    if (nodeTypeIsReferencedSubmodel(node.type)) {
      const submodelInstance: SubmodelInstance = node as SubmodelInstance;
      if (submodelInstance?.submodel_reference_uuid) {
        const submodelReference = submodelsSection.references[node.uuid];
        if (submodelReference) {
          delete submodelsSection.diagrams[submodelReference.diagram_uuid];
          delete submodelsSection.references[node.uuid];
        }
      }
    }
  }
}

function updateNodesForSubmodelReferenceChanges(
  nodes: NodeInstance[],
  idToVersionIdToSubmodelInfo: Record<string, Record<string, SubmodelInfoUI>>,
) {
  for (let i = 0; i < nodes.length; i++) {
    const node = nodes[i];
    if (nodeTypeIsReferencedSubmodel(node.type)) {
      const submodelInstance: SubmodelInstance = node as SubmodelInstance;
      if (submodelInstance?.submodel_reference_uuid) {
        const submodelReference: SubmodelInfoUI | undefined =
          getSpecificReferenceSubmodelByNode(
            submodelInstance,
            idToVersionIdToSubmodelInfo,
          );
        updateSubmodelInstanceForReferenceChanges(
          submodelInstance,
          submodelReference,
        );
      }
    }
  }
}

export function updateModelForSubmodelReferenceChanges(
  diagram: ModelDiagram,
  submodelsSection: SubmodelsSection,
  idToVersionIdToSubmodelInfo: Record<string, Record<string, SubmodelInfoUI>>,
) {
  updateNodesForSubmodelReferenceChanges(
    diagram.nodes,
    idToVersionIdToSubmodelInfo,
  );
  removeDuplicateDiagramEntries(diagram.nodes, submodelsSection);

  if (submodelsSection && submodelsSection.diagrams) {
    const submodelDiagrams = Object.values(submodelsSection.diagrams);

    for (let i = 0; i < submodelDiagrams.length; i++) {
      const submodelDiagram = submodelDiagrams[i];
      if (submodelDiagram?.nodes) {
        updateNodesForSubmodelReferenceChanges(
          submodelDiagram.nodes,
          idToVersionIdToSubmodelInfo,
        );
        removeDuplicateDiagramEntries(submodelDiagram.nodes, submodelsSection);
      }
    }
  }
}
