import { SubmodelInfoLiteUI } from 'app/apiTransformers/convertGetSubmodelsList';
import {
  ModelDiagram,
  NodeInstance,
  SubmodelInstance,
} from 'app/generated_types/SimulationModel';
import { nodeClassWithoutNamespace_displayOnly } from 'app/helpers';

// See https://realpython.com/lessons/reserved-keywords/
// and https://docs.python.org/3/library/functions.html
export const FORBIDDEN_NAMES = [
  'abs',
  'aiter',
  'all',
  'and',
  'anext',
  'any',
  'as',
  'ascii',
  'assert',
  'bin',
  'bool',
  'break',
  'breakpoint',
  'bytearray',
  'bytes',
  'callable',
  'chr',
  'class',
  'classmethod',
  'compile',
  'complex',
  'continue',
  'def',
  'del',
  'delattr',
  'dict',
  'dir',
  'divmod',
  'elif',
  'else',
  'enumerate',
  'eval',
  'except',
  'exec',
  'false',
  'false',
  'filter',
  'finally',
  'float',
  'for',
  'format',
  'from',
  'frozenset',
  'getattr',
  'global',
  'globals',
  'hasattr',
  'hash',
  'help',
  'hex',
  'id',
  'if',
  'import',
  'in',
  'input',
  'int',
  'is',
  'isinstance',
  'issubclass',
  'iter',
  'lambda',
  'len',
  'list',
  'locals',
  'map',
  'max',
  'math',
  'memoryview',
  'min',
  'next',
  'none',
  'nonlocal',
  'not',
  'np',
  'null',
  'numpy',
  'object',
  'oct',
  'open',
  'or',
  'ord',
  'pass',
  'pow',
  'print',
  'property',
  'raise',
  'range',
  'repr',
  'return',
  'reversed',
  'round',
  'set',
  'setattr',
  'slice',
  'sorted',
  'staticmethod',
  'str',
  'sum',
  'super',
  'true',
  'try',
  'tuple',
  'type',
  'undefined',
  'vars',
  'while',
  'with',
  'yield',
  'zip',
  'i', // prevent bug in c generation - see https://collimator.atlassian.net/browse/SIM-776```
];

// Ensure that node names are valid C or Python identifiers
export const stringToValidIdentifier = (name: string): string => {
  const newName = name.trim().replace(/[^a-zA-Z0-9_]/g, '_');
  if (newName.substring(0, 1).match(/[0-9]/)) {
    return `_${newName}`;
  }

  // prevent names from starting with __ (double underscore),
  // because that will not work well with python's "private" scopes.
  if (newName.substring(0, 2) === '__' && newName.substring(0, 3) != '___') {
    return `_${newName}`;
  }

  // Prevent names that match restricted names,
  // using a case insensitive search.
  const lowerCaseNewName = newName.toLowerCase();
  if (FORBIDDEN_NAMES.includes(lowerCaseNewName)) {
    return `_${newName}`;
  }

  // TODO implement character limit (64 characters) when we can show a validation error
  // (it is not obvious how to "fix up" a too-long name and we will stop
  // doing these fixups soon anyway).
  return newName;
};

/**
 * Returns a unique identifier based on `tentative`. Increments from `minIndex` even if `tentative` is available.
 * @param minIndex Defaults to 0. To start after the `tentative` suffix, pass it here.
 */
export const getNextValidIdentifier = (
  tentative: string,
  existing: string[],
  minIndex = 0,
  separator = '_',
) => {
  const suffixIndex = tentative.lastIndexOf(separator);
  const suffix = suffixIndex > 0 ? tentative.substring(suffixIndex + 1) : null;
  const suffixIsInt = suffix?.match(/^[0-9]+$/);
  const suffixLength = suffix ? suffix.length + 1 : 0;
  const validIdBase = suffixIsInt
    ? tentative.substring(0, tentative.length - suffixLength)
    : tentative;
  const base = validIdBase.length > 0 ? validIdBase : separator;

  for (let i = minIndex; ; i++) {
    if (!existing.includes(`${base}${separator}${i}`)) {
      return `${base}${separator}${i}`;
    }
  }
};

/**
 * Returns a unique identifier based on `tentative`. Increments from `minIndex` if `tentative` is not available.
 * @param minIndex Defaults to 0. To start after tentative suffix, pass it here.
 */
export const getUniqueIdentifier = (
  tentative: string,
  existing: string[],
  minIndex = 0,
): string => {
  const validId = stringToValidIdentifier(tentative);
  if (!existing.includes(validId)) {
    return validId;
  }

  return getNextValidIdentifier(validId, existing, minIndex);
};

export const getUniqueParameterName = (
  node: NodeInstance,
  tentativeName = 'param_0',
  otherNamesInUse?: string[],
): string => {
  const paramNames = Object.keys(node.parameters || {});
  const existingNames = paramNames.concat(otherNamesInUse || []);
  return getUniqueIdentifier(tentativeName, existingNames);
};

// This is NOT recursive by design, we don't enforce uniqueness across planes
export const getUniqueNodeName = (
  diagram: ModelDiagram,
  nodeTypeName: string,
  nodeUuid: string,
  tentativeNodeName?: string,
  minIndex?: number,
): string => {
  const existingNames = diagram.nodes
    .filter((n) => n.uuid != nodeUuid)
    .map((n) => n.name || nodeClassWithoutNamespace_displayOnly(n.type));
  const nodeNameSuffixId = parseInt(
    tentativeNodeName?.split('_')?.pop() || '0',
    10,
  );
  const implicitMinIndex =
    !isNaN(nodeNameSuffixId) && nodeNameSuffixId > 0 ? nodeNameSuffixId : 0;
  const validName =
    tentativeNodeName || `${nodeTypeName}_${minIndex ?? implicitMinIndex}`;

  return getUniqueIdentifier(
    validName,
    existingNames,
    minIndex ?? implicitMinIndex,
  );
};

export const getValidNodeName = (
  diagram: ModelDiagram,
  node: NodeInstance,
  referenceSubmodelIdToSubmodel: Record<string, SubmodelInfoLiteUI>,
  minIndex?: number,
): string => {
  const referenceSubmodelId = (node as SubmodelInstance)
    ?.submodel_reference_uuid;
  const nodeTypeName = referenceSubmodelId
    ? referenceSubmodelIdToSubmodel[referenceSubmodelId]?.name
    : nodeClassWithoutNamespace_displayOnly(node.type);
  return getUniqueNodeName(
    diagram,
    nodeTypeName,
    node.uuid,
    node.name,
    minIndex,
  );
};
