import { t } from '@lingui/macro';
import { format, formatDistanceToNow } from 'date-fns';

const JUST_NOW_THRESHOLD = 10 * 1000; // 10 seconds in milliseconds

export function formatDate(dateString: string): string {
  if (isNaN(Date.parse(dateString))) {
    return t({
      id: 'formattedDateTime.UnknownDate',
      message: 'Unknown date',
    });
  }

  const date = new Date(dateString);
  const now = new Date();
  let timeDiff = now.getTime() - date.getTime();
  const isFuture = timeDiff < 0;

  if (!isFuture && timeDiff <= JUST_NOW_THRESHOLD) {
    return t({
      id: 'formattedDateInThePast.JustNow',
      message: 'Just now',
    });
  }

  const dateDistance = formatDistanceToNow(date);

  if (isFuture) {
    return t({
      id: 'formattedDateInTheFuture.Default',
      message: 'In {formattedDateDistance}',
      values: { formattedDateDistance: dateDistance },
    });
  }

  return t({
    id: 'formattedDateInThePast.Default',
    message: '{formattedDateDistance} ago',
    values: { formattedDateDistance: dateDistance },
  });
}

export function areDatesOnTheSameDay(
  dateStringA: string,
  dateStringB: string,
): boolean {
  if (isNaN(Date.parse(dateStringA)) || isNaN(Date.parse(dateStringB))) {
    return false;
  }

  const dateA = new Date(dateStringA);
  const dateB = new Date(dateStringB);

  // Check for day of the month.
  if (dateA.getDate() !== dateB.getDate()) {
    return false;
  }

  // Check for the month.
  if (dateA.getMonth() !== dateB.getMonth()) {
    return false;
  }

  // Check for the year.
  if (dateA.getFullYear() !== dateB.getFullYear()) {
    return false;
  }

  return true;
}

export function toDateStringOrNull(dateString: string): string | null {
  if (isNaN(Date.parse(dateString))) {
    return null;
  }

  const date = new Date(dateString);
  return format(date, 'yyyy-MM-dd');
}

export function formatFileNameDate(date: Date) {
  return format(date, 'yyyy-MM-dd-HH-mm-ss');
}

export function toDateTimeStringOrNull(dateString: string): string | null {
  if (isNaN(Date.parse(dateString))) {
    return null;
  }

  const date = new Date(dateString);
  return formatFileNameDate(date);
}

export function formatVersionTimestamp(dateString?: string) {
  if (!dateString || isNaN(Date.parse(dateString))) {
    return '';
  }

  const date = new Date(dateString);
  return format(date, 'pp');
}
