/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  stroke?: any;
  strokeWidth?: any;
  strokeLinecap?: any;
  className?: string;
}

const Ros2publisher: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M6.73161 9.39056C8.19875 9.39056 9.38829 8.16143 9.38829 6.64528C9.38829 5.12941 8.19875 3.9 6.73161 3.9C5.26473 3.9 4.0752 5.12941 4.0752 6.64528C4.0752 8.16143 5.2646 9.39056 6.73161 9.39056ZM6.73161 18.746C8.19875 18.746 9.38829 17.5169 9.38829 16.0008C9.38829 14.4846 8.19875 13.2555 6.73161 13.2555C5.26473 13.2555 4.0752 14.4846 4.0752 16.0008C4.0752 17.5169 5.2646 18.746 6.73161 18.746ZM9.38829 25.3564C9.38829 26.8723 8.19875 28.1017 6.73161 28.1017C5.2646 28.1017 4.0752 26.8723 4.0752 25.3564C4.0752 23.8405 5.26473 22.6111 6.73161 22.6111C8.19875 22.6111 9.38829 23.8404 9.38829 25.3564ZM16.0021 18.7461C17.4693 18.7461 18.6588 17.5169 18.6588 16.0008C18.6588 14.4846 17.4693 13.2555 16.0021 13.2555C14.5352 13.2555 13.3457 14.4846 13.3457 16.0008C13.3457 17.5169 14.5351 18.7461 16.0021 18.7461ZM18.6588 6.64528C18.6588 8.16143 17.4693 9.39056 16.0021 9.39056C14.5351 9.39056 13.3457 8.16143 13.3457 6.64528C13.3457 5.12941 14.5352 3.9 16.0021 3.9C17.4693 3.9 18.6588 5.12941 18.6588 6.64528ZM25.2716 18.746C26.7388 18.746 27.9283 17.5169 27.9283 16.0008C27.9283 14.4846 26.7389 13.2555 25.2716 13.2555C23.8048 13.2555 22.6152 14.4846 22.6152 16.0008C22.6152 17.5169 23.8046 18.746 25.2716 18.746ZM27.9283 6.64528C27.9283 8.16143 26.7388 9.39056 25.2716 9.39056C23.8046 9.39056 22.6152 8.16143 22.6152 6.64528C22.6152 5.12941 23.8048 3.9 25.2716 3.9C26.7389 3.9 27.9283 5.12941 27.9283 6.64528ZM25.2716 28.1017C26.7388 28.1017 27.9283 26.8723 27.9283 25.3564C27.9283 23.9895 26.9613 22.8557 25.6956 22.6459L25.9976 22.9595C26.9151 23.9123 26.2399 25.5 24.9171 25.5H23.3935C23.1154 25.5 22.8907 25.7269 22.8935 26.0051L22.8994 26.593C23.3372 27.4879 24.2351 28.1017 25.2716 28.1017ZM18.0161 27.1466C17.5289 27.7314 16.8073 28.1017 16.0021 28.1017C14.5351 28.1017 13.3457 26.8723 13.3457 25.3564C13.3457 24.1256 14.1299 23.0837 15.21 22.7353L14.9945 22.9588C14.0759 23.9113 14.751 25.5 16.0742 25.5H17.5045C17.7787 25.5 18.0017 25.7208 18.0045 25.9949L18.0161 27.1466Z"
      fill={props.fill}
    />
    <path
      d="M19.5 24.25V29.25C19.5 29.3881 19.6119 29.5 19.75 29.5H21.25C21.3881 29.5 21.5 29.3881 21.5 29.25V24.25C21.5 24.1119 21.6119 24 21.75 24H24.3964C24.6192 24 24.7307 23.7307 24.5732 23.5732L20.6768 19.6768C20.5791 19.5791 20.4209 19.5791 20.3232 19.6768L16.4268 23.5732C16.2693 23.7307 16.3808 24 16.6036 24H19.25C19.3881 24 19.5 24.1119 19.5 24.25Z"
      stroke={props.stroke}
      strokeWidth={props.strokeWidth}
      strokeLinecap={props.strokeLinecap}
    />
  </svg>
);

Ros2publisher.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  stroke: '#1D3131',
  strokeWidth: '1.5',
  strokeLinecap: 'round',
  className: '',
};

export default Ros2publisher;
