import { t } from '@lingui/macro';
import { useAppSelector } from 'app/hooks';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Library, Tree } from 'ui/common/Icons/Small';
import { SidebarTabGroupWrapper } from 'ui/common/layout/appLayout';
import TabGroup from 'ui/common/tabs/TabGroup';
import LibrarySection from 'ui/objectBrowser/sections/LibrarySection';
import { ProjectSection } from 'ui/objectBrowser/sections/ProjectSection';
import { useModelPermission } from 'ui/permission/useModelPermission';
import { isBlockNavState } from 'ui/requirements/blockNav';
import { useAppParams } from 'util/useAppParams';

enum LeftSidebarSection {
  ModelTree,
  Library,
}

export const ModelLeftSidebarContent: React.FC = () => {
  const { projectId, modelId, versionId } = useAppParams();
  const { canEditCurrentModelVersion } = useModelPermission(
    projectId,
    modelId,
    versionId,
  );

  const referenceSubmodelId = useAppSelector(
    (state) => state.modelMetadata.currentDiagramSubmodelReferenceId,
  );
  const isViewingReferenceSubmodel =
    referenceSubmodelId && referenceSubmodelId !== modelId;

  // Default to the library tab unless we are in readonly mode and the library tab not available,
  // in which case default to the model tree.
  const [section, setSection] = React.useState<LeftSidebarSection>(
    canEditCurrentModelVersion
      ? LeftSidebarSection.Library
      : LeftSidebarSection.ModelTree,
  );

  const { state } = useLocation();
  // Left side bar opens to the tree navigator if a block is specified
  useEffect(() => {
    if (isBlockNavState(state) && !!state.block_instance_uuid) {
      setSection(LeftSidebarSection.ModelTree);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (
      (!canEditCurrentModelVersion || isViewingReferenceSubmodel) &&
      section === LeftSidebarSection.Library
    ) {
      setSection(LeftSidebarSection.ModelTree);
    }
  }, [canEditCurrentModelVersion, isViewingReferenceSubmodel, section]);

  const renderSection = () => {
    switch (section) {
      case LeftSidebarSection.ModelTree:
        return projectId && <ProjectSection projectId={projectId} />;
      case LeftSidebarSection.Library:
        if (canEditCurrentModelVersion) {
          return <LibrarySection />;
        }
        return null;
    }
  };

  const tabs = React.useMemo(() => {
    const tabBuilder = [
      {
        id: 'modelTree',
        Icon: Tree,
        displayName: t({
          id: 'modelEditor.leftSidebarTab.modelTree',
          message: 'Model tree',
        }),
        onClick: () => {
          setSection(LeftSidebarSection.ModelTree);
        },
        isSelected: () => section === LeftSidebarSection.ModelTree,
      },
    ];

    if (canEditCurrentModelVersion) {
      tabBuilder.push({
        id: 'library',
        Icon: Library,
        displayName: t({
          id: 'modelEditor.leftSidebarTab.library',
          message: 'Library',
        }),
        onClick: () => {
          setSection(LeftSidebarSection.Library);
        },
        isSelected: () => section === LeftSidebarSection.Library,
      });
    }
    return tabBuilder;
  }, [section, canEditCurrentModelVersion]);

  return (
    <>
      <SidebarTabGroupWrapper>
        <TabGroup tabs={tabs} testId="model-left-sidebar-tabs" />
      </SidebarTabGroupWrapper>
      {renderSection()}
    </>
  );
};
