/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  className?: string;
}

const CoordinaterotationRotationangles: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.70801 19.1436C4.10645 19.1436 3.58919 18.9954 3.15625 18.6992C2.72786 18.3984 2.39974 17.9814 2.17188 17.4482C1.94401 16.915 1.83008 16.293 1.83008 15.582V15.4385C1.83008 14.6455 1.94401 13.9505 2.17188 13.3535C2.39974 12.7565 2.73014 12.2939 3.16309 11.9658C3.59603 11.6331 4.11556 11.4668 4.72168 11.4668C5.17741 11.4668 5.57845 11.5579 5.9248 11.7402C6.27572 11.918 6.57194 12.1755 6.81348 12.5127L6.92969 11.6035H8.07129V16.9766C8.07129 17.3594 8.11686 17.6237 8.20801 17.7695C8.29915 17.9154 8.42676 17.9883 8.59082 17.9883C8.62728 17.9883 8.66374 17.986 8.7002 17.9814C8.74121 17.9723 8.77539 17.9609 8.80273 17.9473L8.9668 18.9111C8.83008 19.0023 8.69336 19.0638 8.55664 19.0957C8.41992 19.1276 8.26725 19.1436 8.09863 19.1436C7.77506 19.1436 7.50846 19.0592 7.29883 18.8906C7.08919 18.7174 6.93652 18.444 6.84082 18.0703C6.59928 18.4258 6.30078 18.6947 5.94531 18.877C5.5944 19.0547 5.18197 19.1436 4.70801 19.1436ZM5.0498 18.0908C5.44629 18.0908 5.77897 17.986 6.04785 17.7764C6.32129 17.5667 6.54688 17.2705 6.72461 16.8877V13.7432C6.60156 13.4925 6.46029 13.276 6.30078 13.0938C6.14128 12.9115 5.95898 12.7725 5.75391 12.6768C5.54883 12.5765 5.31868 12.5264 5.06348 12.5264C4.63509 12.5264 4.2819 12.6517 4.00391 12.9023C3.72591 13.1484 3.51855 13.4902 3.38184 13.9277C3.24512 14.3652 3.17676 14.8688 3.17676 15.4385V15.582C3.17676 16.334 3.32943 16.9401 3.63477 17.4004C3.9401 17.8607 4.41178 18.0908 5.0498 18.0908Z"
      fill={props.fill}
    />
    <path
      d="M10.293 21.126L9.53418 20.6201C9.74837 20.292 9.91016 19.957 10.0195 19.6152C10.1289 19.2734 10.1836 18.9111 10.1836 18.5283V17.4893H11.5303V18.5078C11.5303 18.9544 11.4186 19.4215 11.1953 19.9092C10.972 20.3968 10.6712 20.8024 10.293 21.126Z"
      fill={props.fill}
    />
    <path
      d="M13.1162 21.6182V11.665C13.1162 11.1592 13.2552 10.6989 13.5332 10.2842C13.8112 9.86491 14.1826 9.52995 14.6475 9.2793C15.1123 9.02865 15.625 8.90332 16.1855 8.90332C16.778 8.90332 17.2952 9.01725 17.7373 9.24512C18.1794 9.47298 18.5212 9.78971 18.7627 10.1953C19.0088 10.6009 19.1318 11.068 19.1318 11.5967C19.1318 12.0114 19.0179 12.3919 18.79 12.7383C18.5667 13.0846 18.2568 13.3626 17.8604 13.5723C18.4027 13.7728 18.8242 14.0964 19.125 14.543C19.4258 14.9896 19.5762 15.5068 19.5762 16.0947C19.5762 16.7282 19.444 17.2728 19.1797 17.7285C18.9199 18.1842 18.5553 18.5352 18.0859 18.7812C17.6211 19.0228 17.0811 19.1436 16.4658 19.1436C16.0921 19.1436 15.7298 19.0934 15.3789 18.9932C15.0326 18.8929 14.7272 18.738 14.4629 18.5283V21.6182H13.1162ZM16.3291 18.0908C16.917 18.0908 17.3796 17.9085 17.7168 17.5439C18.0586 17.1794 18.2295 16.7054 18.2295 16.1221C18.2295 15.7803 18.1611 15.4635 18.0244 15.1719C17.8923 14.8757 17.7008 14.6364 17.4502 14.4541C17.2041 14.2718 16.9124 14.1807 16.5752 14.1807H15.5977V13.1211H16.1582C16.7142 13.1211 17.1243 12.9844 17.3887 12.7109C17.653 12.4329 17.7852 12.0524 17.7852 11.5693C17.7852 11.2822 17.7214 11.0179 17.5938 10.7764C17.4661 10.5303 17.2816 10.3343 17.04 10.1885C16.8031 10.0381 16.5182 9.96289 16.1855 9.96289C15.862 9.96289 15.5703 10.0381 15.3105 10.1885C15.0508 10.3389 14.8434 10.5417 14.6885 10.7969C14.5381 11.0521 14.4629 11.3415 14.4629 11.665V17.3457C14.6634 17.5781 14.9232 17.7604 15.2422 17.8926C15.5658 18.0247 15.9281 18.0908 16.3291 18.0908Z"
      fill={props.fill}
    />
    <path
      d="M21.0664 21.126L20.3076 20.6201C20.5218 20.292 20.6836 19.957 20.793 19.6152C20.9023 19.2734 20.957 18.9111 20.957 18.5283V17.4893H22.3037V18.5078C22.3037 18.9544 22.1921 19.4215 21.9688 19.9092C21.7454 20.3968 21.4447 20.8024 21.0664 21.126Z"
      fill={props.fill}
    />
    <path
      d="M26.0293 21.8438V18.6514L24.0674 13.0938C24.0036 12.916 23.9329 12.8021 23.8555 12.752C23.7826 12.6973 23.6709 12.6699 23.5205 12.6699C23.4795 12.6699 23.4453 12.6722 23.418 12.6768C23.3906 12.6813 23.3086 12.695 23.1719 12.7178L23.0488 11.6992C23.1628 11.6491 23.3086 11.6081 23.4863 11.5762C23.6686 11.5397 23.8304 11.5215 23.9717 11.5215C24.1904 11.5215 24.3704 11.5488 24.5117 11.6035C24.653 11.6536 24.7715 11.7493 24.8672 11.8906C24.9674 12.0273 25.0609 12.2256 25.1475 12.4854L26.5693 16.8193L26.6855 17.3936H26.7266L26.8701 16.8193L28.2441 12.6152L27.3213 12.4717V11.6035H30.1787V12.4717L29.5908 12.5674L27.376 18.5898V21.8438H26.0293Z"
      fill={props.fill}
    />
  </svg>
);

CoordinaterotationRotationangles.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  className: '',
};

export default CoordinaterotationRotationangles;
