export function fileToBase64(file: File) {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });
}

export function splitFullName(fullName: string) {
  const extensionIndex = fullName.lastIndexOf('.');
  if (extensionIndex !== -1) {
    const name = fullName.slice(0, extensionIndex);
    const extension = fullName.slice(extensionIndex + 1);
    return [name, extension];
  }
  return [fullName, ''];
}

export function downloadUrlFile(href: string, fileName: string) {
  const a = document.createElement('a');
  a.href = href;
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  a.remove();
}

export function downloadBlobFile(blob: Blob, fileName: string) {
  const href = window.URL.createObjectURL(blob);
  downloadUrlFile(href, fileName);
}

export function downloadResource(url: string, filename: string): Promise<void> {
  return fetch(url, {
    headers: new Headers({
      Origin: location.origin,
    }),
    mode: 'cors',
  })
    .then((response) => response.blob())
    .then((blob) => {
      const blobUrl = window.URL.createObjectURL(blob);
      downloadUrlFile(blobUrl, filename);
    });
}

export class NotFoundError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'NotFoundError';
  }
}

// maxSizeBytes is a safety guard to prevent loading files too large for the inline editor
export async function fetchFileContents(
  url: string,
  maxSizeBytes = 5 * 1024 * 1024,
): Promise<string> {
  const controller = new AbortController();
  const signal = controller.signal;

  return new Promise<string>((resolve, reject) => {
    fetch(url, { signal })
      .then((response) => {
        if (!response.ok) {
          if (response.status === 404) {
            throw new NotFoundError('File not found');
          }
          throw new Error('Network response was not ok');
        }

        const contentLength = response.headers.get('Content-Length');
        const fileLength = parseInt(contentLength || '', 10);
        if (fileLength > maxSizeBytes || !response.body) {
          throw new Error('File too large');
        }

        const reader = response.body.getReader();
        const decoder = new TextDecoder('utf-8');
        let receivedLength = 0;
        let chunks: string[] = [];

        // Read function recursively reads chunks until complete
        function readChunk() {
          reader.read().then(({ done, value }) => {
            if (done) {
              return resolve(chunks.join(''));
            }

            receivedLength += value.length;
            if (receivedLength > maxSizeBytes) {
              throw new Error('File too large');
            }

            const str = decoder.decode(value);
            chunks.push(str);

            readChunk();
          });
        }

        readChunk();
      })
      .catch((error) => {
        controller.abort();
        reject(error);
      });
  });
}
