import { t } from '@lingui/macro';
import { useProject } from 'app/api/useProject';
import { BusType } from 'app/apiGenerated/generatedApiTypes';
import { PortSide } from 'app/common_types/PortTypes';
import { BlockInstance } from 'app/generated_types/SimulationModel';
import { useAppDispatch } from 'app/hooks';
import { modelActions } from 'app/slices/modelSlice';
import React from 'react';
import { batch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import SelectInput, { SelectInputOption } from 'ui/common/SelectInput';
import { useNotifications } from 'ui/common/notifications/useNotifications';
import { CreateBusTypeModal } from 'ui/dashboard/projectDetail/CreateBusTypeModal';
import { DynamicVariableCommon } from 'util/dynamicBlockUtils';
import { useAppParams } from 'util/useAppParams';
import { useModal } from '../../../common/Modal/useModal';

type Props = {
  parentPath: string[];
  node: BlockInstance;
  nodeType: 'core.BusCreator' | 'core.BusSelector'; // kind of a hack, but forces user to check when declaring.
  currentBusTypeId: string;
  onSelectBusType: (newBusTypeId: string) => void;
};
const CREATE_BUS_TYPE = '__create_bus_type';

const PROJECT_UUID_PARAM = 'project_uuid';

/**
 * Since a bus type is only unique within a project,
 * uses hidden block parameter `project_uuid` to determine which bus type is selected
 */
const BusTypeSelect: React.FC<Props> = ({
  parentPath,
  node,
  nodeType,
  currentBusTypeId,
  onSelectBusType,
}) => {
  // TODO: useEffect for load. On load, validate and update the block.

  const portSide =
    nodeType === 'core.BusCreator' ? PortSide.Input : PortSide.Output;

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { triggerModal } = useModal();
  const { showError } = useNotifications();
  const { projectId } = useAppParams();

  const { project, isFetchingProject } = useProject({
    // FIXME: Excessive, but works well enough. Should just refetch the selected bus type instead of relying on project summary.
    refetchOnMountOrArgChange: true,
  });

  const busTypes = project?.busTypes;
  const busTypeProjectUuid = node.parameters[PROJECT_UUID_PARAM]?.value;

  // TODO: Similar branch support to come
  /**
   * Set all block params related to the bus type, including hidden ones.
   */
  const setBusTypeParams = (busType: BusType) => {
    if (!projectId) return;

    onSelectBusType(busType.id);

    // Set the project_uuid if the BusType belongs to a different project
    if (busType.project_uuid !== projectId) {
      dispatch(
        modelActions.changeBlockParameter({
          parentPath,
          nodeUuid: node.uuid,
          paramName: PROJECT_UUID_PARAM,
          value: busType.project_uuid,
        }),
      );
    }
  };

  const clearBusTypeParams = () => {
    onSelectBusType('');
    dispatch(
      modelActions.changeBlockParameter({
        parentPath,
        nodeUuid: node.uuid,
        paramName: PROJECT_UUID_PARAM,
        value: '',
      }),
    );
  };

  // Dynamic ports according to the selected Bus Type
  const configurePorts = (newBusTypeId: string) => {
    if (!projectId) return;

    const busType = busTypes?.find(
      (busType) =>
        busType.id === newBusTypeId &&
        // No project implies the current project
        (!busTypeProjectUuid
          ? busType.project_uuid === projectId
          : busType.project_uuid === busTypeProjectUuid),
    );
    if (!busType) {
      console.error(`Bus type '${newBusTypeId}' not found`);
      return;
    }

    const signals = busType.definition.signals;

    if (
      signals.length === 0 ||
      !signals.every((signal) => signal.name.trim() !== '')
    ) {
      showError(
        `Bus type '${busType.name}' is incomplete and cannot be applied`,
      );
      return;
    }

    // Complete bus type
    setBusTypeParams(busType);
    const busPorts: DynamicVariableCommon[] = signals.map((signal) => ({
      id: signal.name,
      causality: nodeType === 'core.BusCreator' ? 'input' : 'output',
    }));
    batch(() => {
      dispatch(modelActions.resetDynamicBlock({ parentPath, node, portSide }));
      dispatch(
        modelActions.configureDynamicBlock({
          parentPath,
          node,
          dynamicVariables: busPorts,
        }),
      );
    });
  };

  const internalOnSelect = (newBusTypeId: string) => {
    if (newBusTypeId === currentBusTypeId) return;
    // TODO: Definition may have changed, re-apply.

    if (!newBusTypeId) {
      clearBusTypeParams();
      dispatch(modelActions.resetDynamicBlock({ parentPath, node, portSide }));
    } else if (newBusTypeId === CREATE_BUS_TYPE) {
      if (!projectId) return;
      triggerModal(
        <CreateBusTypeModal
          projectId={projectId}
          onCreated={(uuid: string) => {
            navigate(`/projects/${projectId}/bus_types/${uuid}`);
          }}
        />,
        t({
          id: 'createBusTypeModal.title',
          message: 'Create a new Bus Type',
        }),
      );
    } else {
      configurePorts(newBusTypeId);
    }
  };

  const [isCurrentBusTypeValidOrEmpty, setIsCurrentBusTypeValidOrEmpty] =
    React.useState<boolean>(true);

  const options = React.useMemo(() => {
    let options: SelectInputOption[] = [];

    const projectBusTypes = busTypes || [];
    options = projectBusTypes.map((busType) => ({
      value: busType.id,
      label: busType.name,
    }));

    if (currentBusTypeId) {
      const currentBusTypeIsValid = options.some(
        (option) => option.value === currentBusTypeId,
      );
      if (currentBusTypeIsValid) {
        setIsCurrentBusTypeValidOrEmpty(true);
      } else if (!isFetchingProject) {
        options.push({
          value: currentBusTypeId,
          label: t({
            id: 'modelRenderer.parameters.busTypeNotFound.label',
            message: '{filename} (Not found)',
            values: {
              filename: currentBusTypeId,
            },
          }),
        });
        setIsCurrentBusTypeValidOrEmpty(false);
      }
    } else {
      // The current value is empty so we should not show an error.
      setIsCurrentBusTypeValidOrEmpty(true);
    }

    return [
      {
        value: '',
        label: t({
          id: 'modelRenderer.parameters.selectOption.label',
          message: 'Select option',
        }),
      },
      ...options,
      {
        value: CREATE_BUS_TYPE,
        label: t({
          id: 'modelRenderer.parameters.newBusType.label',
          message: 'Create a new bus type...',
        }),
      },
    ];
  }, [busTypes, currentBusTypeId, isFetchingProject]);

  return (
    <SelectInput
      currentValue={currentBusTypeId}
      options={options}
      onSelectValue={internalOnSelect}
      isInvalid={!isCurrentBusTypeValidOrEmpty}
    />
  );
};

export default BusTypeSelect;
