/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Buscreator: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M14.7848 8.54381C14.9635 7.87698 15.6489 7.48125 16.3157 7.65993L17.7661 8.04855C18.4329 8.22723 18.8287 8.91265 18.65 9.57948L17.3315 14.5H25.5C25.7761 14.5 26 14.7239 26 15V17C26 17.2761 25.7761 17.5 25.5 17.5H16.5277L14.7677 24.0684C14.589 24.7352 13.9036 25.1309 13.2368 24.9523L11.7864 24.5636C11.1196 24.385 10.7239 23.6995 10.9025 23.0327L11.2462 21.75H7.5C7.08579 21.75 6.75 21.4142 6.75 21C6.75 20.5858 7.08579 20.25 7.5 20.25H11.6482L12.586 16.75H7.5C7.08579 16.75 6.75 16.4142 6.75 16C6.75 15.5858 7.08579 15.25 7.5 15.25H12.9879L13.9257 11.75H7.5C7.08579 11.75 6.75 11.4142 6.75 11C6.75 10.5858 7.08579 10.25 7.5 10.25H14.3276L14.7848 8.54381ZM24 16.75V15.25H22V16.75H24ZM18 15.25H20V16.75H18V15.25ZM16.169 9.17352L12.4161 23.1794L13.3835 23.4387L17.1364 9.43274L16.169 9.17352Z"
      fill={props.fill}
    />
  </svg>
);

Buscreator.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Buscreator;
