import styled from '@emotion/styled';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Cell, Column } from 'react-table';

import { useTheme } from '@emotion/react';
import { useAdminApis } from 'app/api/useAdminApis';
import Button from 'ui/common/Button/Button';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import { BackArrow, Reload, Remove } from 'ui/common/Icons/Standard';
import Table from 'ui/common/Table/Table';
import * as Headings from 'ui/common/typography/Typography';
import { formatDate } from 'util/dateUtils';

const Head = styled.header(({ theme }) => ({
  display: 'flex',
  width: ' 100%',
  marginBottom: theme.spacing.xlarge,
  padding: 10,
  columnGap: 8,
}));

const Title = styled(Headings.H2)(({ theme }) => ({
  width: '100%',
}));

const SubTitle = styled(Headings.H3)(({ theme }) => ({
  paddingBottom: 10,
  paddingLeft: 10,
  width: '100%',
}));

const TableWrapper = styled.div(({ theme }) => ({
  paddingBottom: theme.spacing.xlarge,
}));

const SimulationsJobsWrapper = styled.div(({ theme }) => ({
  width: `calc(100% - ${theme.spacing.xxlarge} - ${theme.spacing.xxlarge})`,
  height: `calc(100% - ${theme.spacing.xxlarge} - ${theme.spacing.xxlarge})`,
  margin: theme.spacing.xlarge,
  padding: theme.spacing.large,
  background: theme.colors.grey[5],
  borderRadius: theme.spacing.small,
  overflowX: 'auto',
  overflowY: 'auto',
}));

const SimulationJobActions = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '24px',
});

const ActionSection = styled.div(({ theme }) => ({
  marginBottom: theme.spacing.large,
  alignItems: 'center',
}));

interface SimulationWorkerTableData extends Record<string, string> {
  instance_id: string;
  instance_type: string;
  autoscaling_source: string;
  current_simulation_job_uuid: string;
  launched_at: string;
  worker_ip_address: string;
}
interface SimulationJobsTableData extends Record<string, string> {
  created_at: string;
  updated_at: string;
  status: string;
  user_display_name: string;
  uuid: string;
  worker_type: string;
  worker_ip_address: string;
}

interface RayJobTableData extends Record<string, string | number> {
  start_time: number;
  end_time: number;
  error_type: string;
  message: string;
  status: string;
  job_id: string;
}

const ActionCell = ({ cell }: { cell: Cell<SimulationJobsTableData> }) => {
  const theme = useTheme();
  const { deleteSimulationJob } = useAdminApis();
  const { uuid, status, user_display_name } = cell.row.original;
  return (
    <SimulationJobActions>
      <Button
        variant={ButtonVariants.SmallTertiary}
        title="Delete simulation job"
        Icon={Remove}
        tint={theme.colors.ui.error}
        onClick={() => {
          if (confirm(`Cancel ${status} simulation by ${user_display_name}?`)) {
            deleteSimulationJob(uuid);
          }
        }}
      />
    </SimulationJobActions>
  );
};

const DateCell = ({ value }: { value: string }) => (
  <span title={value}>{formatDate(value)}</span>
);

const TimestampCell = ({ value }: { value: number | string }) => (
  <span title={`${value}`}>
    {value ? formatDate(new Date(value).toString()) : ''}
  </span>
);

const SimulationsQueue: React.FC = () => {
  const {
    simulationJobs,
    platformInfo,
    refetchSimulationJobs,
    refetchPlatformInfo,
  } = useAdminApis();
  const navigate = useNavigate();

  if (!simulationJobs && !platformInfo) return <span>Loading</span>;

  const simulationWorkerTableColumns: Column<SimulationWorkerTableData>[] = [
    {
      id: 'instance_id',
      Header: 'Instance ID',
      accessor: 'instance_id',
      maxWidth: 150,
    },
    {
      id: 'instance_type',
      Header: 'Instance Type',
      accessor: 'instance_type',
      maxWidth: 150,
    },
    {
      id: 'autoscaling_source',
      Header: 'Autoscaling Group',
      accessor: 'autoscaling_source',
      maxWidth: 150,
    },
    {
      id: 'current_simulation_job_uuid',
      Header: 'Current Simulation Job UUID',
      accessor: 'current_simulation_job_uuid',
      maxWidth: 150,
    },
    {
      id: 'worker_ip_address',
      Header: 'Worker IP Address',
      accessor: 'worker_ip_address',
      maxWidth: 150,
    },
    {
      id: 'launched_at',
      Header: 'Launched At',
      accessor: 'launched_at',
      Cell: DateCell,
      minWidth: 150,
      maxWidth: 0,
    },
  ];

  const tableData: SimulationJobsTableData[] | undefined =
    simulationJobs?.simulation_jobs.map((job) => ({
      created_at: job?.created_at,
      updated_at: job?.updated_at,
      status: job?.status,
      user_display_name: job?.user?.display_name,
      uuid: job?.uuid,
      worker_type: job?.worker_type,
      worker_ip_address: job?.worker_ip_address,
    }));

  const tableColumns: Column<SimulationJobsTableData>[] = [
    {
      Header: 'Created At',
      accessor: 'created_at',
      Cell: DateCell,
      minWidth: 150,
      maxWidth: 0,
    },
    {
      Header: 'Updated At',
      accessor: 'updated_at',
      Cell: DateCell,
      minWidth: 150,
      maxWidth: 0,
    },
    {
      id: 'status',
      Header: 'Status',
      accessor: 'status',
      maxWidth: 100,
    },
    {
      id: 'worker_type',
      Header: 'Worker Type',
      accessor: 'worker_type',
      maxWidth: 100,
    },
    {
      id: 'worker_ip_address',
      Header: 'IP Address',
      accessor: 'worker_ip_address',
      maxWidth: 100,
    },
    {
      id: 'display_name',
      Header: 'User',
      accessor: 'user_display_name',
    },
    {
      id: 'uuid',
      Header: 'UUID',
      accessor: 'uuid',
      minWidth: 200,
    },
    {
      id: 'action',
      Header: 'Actions',
      accessor: 'status',
      maxWidth: 50,
      Cell: ActionCell,
    },
  ];

  const inProgress = platformInfo?.simulations_queue_info?.in_progress || 0;
  const queued = platformInfo?.simulations_queue_info?.queued || 0;

  const rayJobListTableData: RayJobTableData[] | undefined =
    platformInfo?.ray_job_list?.map((job) => ({
      start_time: job?.start_time || 0,
      end_time: job?.end_time || 0,
      error_type: job?.error_type || '',
      message: job?.message || '',
      status: job.status,
      job_id: job?.job_id || '',
    }));

  const rayJobListTableColumns: Column<RayJobTableData>[] = [
    {
      Header: 'Start Time',
      accessor: 'start_time',
      Cell: TimestampCell,
      minWidth: 150,
      maxWidth: 0,
    },
    {
      Header: 'End Time',
      accessor: 'end_time',
      Cell: TimestampCell,
      minWidth: 150,
      maxWidth: 0,
    },
    {
      id: 'status',
      Header: 'Status',
      accessor: 'status',
      maxWidth: 100,
    },
    {
      id: 'error_type',
      Header: 'Error Type',
      accessor: 'error_type',
      maxWidth: 100,
    },
    {
      id: 'message',
      Header: 'Message',
      accessor: 'message',
      maxWidth: 100,
    },
    {
      id: 'job_id',
      Header: 'Job ID',
      accessor: 'job_id',
      minWidth: 200,
    },
  ];

  return (
    <SimulationsJobsWrapper>
      <ActionSection>
        <Head>
          <Title>
            Simulations | In Progress - {inProgress}; Queued - {queued}
          </Title>
          <Button
            variant={ButtonVariants.LargeSecondary}
            Icon={BackArrow}
            onClick={() => navigate('/admin')}>
            Admin portal
          </Button>
          <Button
            variant={ButtonVariants.LargeSecondary}
            Icon={Reload}
            onClick={() => {
              refetchPlatformInfo();
              refetchSimulationJobs();
            }}>
            Refresh
          </Button>
        </Head>
      </ActionSection>
      <TableWrapper>
        <SubTitle>Simulation Queue</SubTitle>
        {tableData && <Table data={tableData} columns={tableColumns} />}
      </TableWrapper>
      <TableWrapper>
        <SubTitle>Ray Jobs</SubTitle>
        <Table
          data={rayJobListTableData || []}
          columns={rayJobListTableColumns}
        />
      </TableWrapper>
    </SimulationsJobsWrapper>
  );
};

export default SimulationsQueue;
