/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  className?: string;
}

const Loopcounter: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.5 5.75C5.5 5.61193 5.61193 5.5 5.75 5.5H25.5C25.6381 5.5 25.75 5.61193 25.75 5.75V16.4091L23.5189 14.271C23.2199 13.9844 22.7451 13.9945 22.4585 14.2936C22.1719 14.5926 22.182 15.0674 22.4811 15.354L26.5 19.2055L30.5189 15.354C30.818 15.0674 30.8281 14.5926 30.5415 14.2936C30.2549 13.9945 29.7801 13.9844 29.4811 14.271L27.25 16.4091V5.75C27.25 4.7835 26.4665 4 25.5 4H5.75C4.7835 4 4 4.7835 4 5.75V26.25C4 27.2165 4.7835 28 5.75 28H13.75C14.1642 28 14.5 27.6642 14.5 27.25C14.5 26.8358 14.1642 26.5 13.75 26.5H5.75C5.61193 26.5 5.5 26.3881 5.5 26.25V5.75Z"
      fill={props.fill}
    />
    <path
      d="M17.25 26.5C16.8358 26.5 16.5 26.8358 16.5 27.25C16.5 27.6642 16.8358 28 17.25 28H19.75C20.1642 28 20.5 27.6642 20.5 27.25C20.5 26.8358 20.1642 26.5 19.75 26.5H17.25Z"
      fill={props.fill}
    />
    <path
      d="M22.5 27.25C22.5 26.8358 22.8358 26.5 23.25 26.5H25.75C26.1642 26.5 26.5 26.8358 26.5 27.25C26.5 27.6642 26.1642 28 25.75 28H23.25C22.8358 28 22.5 27.6642 22.5 27.25Z"
      fill={props.fill}
    />
    <path
      d="M13.3438 19.576V21H18.6558V19.576H16.9917V13.56H13.4397V14.984H15.1678V19.576H13.3438Z"
      fill={props.fill}
    />
    <path
      d="M15.1997 12.008C15.4237 12.2213 15.6904 12.328 15.9998 12.328C16.2984 12.328 16.5598 12.2213 16.7838 12.008C17.0184 11.7947 17.1357 11.5387 17.1357 11.24C17.1357 10.9413 17.0184 10.6853 16.7838 10.472C16.5598 10.2587 16.2984 10.152 15.9998 10.152C15.6691 10.152 15.3971 10.2533 15.1838 10.456C14.9704 10.6587 14.8638 10.92 14.8638 11.24C14.8638 11.5387 14.9757 11.7947 15.1997 12.008Z"
      fill={props.fill}
    />
  </svg>
);

Loopcounter.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  className: '',
};

export default Loopcounter;
