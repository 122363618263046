/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  className?: string;
}

const EdgedetectorEither: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.75 10.75V13.7069C11.7967 13.742 11.8414 13.7808 11.8839 13.8232L14.5303 16.4697C14.8232 16.7626 14.8232 17.2374 14.5303 17.5303C14.2374 17.8232 13.7626 17.8232 13.4697 17.5303L11.75 15.8107V21.5C11.75 22.1904 11.1904 22.75 10.5 22.75H4C3.58579 22.75 3.25 22.4142 3.25 22C3.25 21.5858 3.58579 21.25 4 21.25H10.25V15.8107L8.53033 17.5303C8.23744 17.8232 7.76256 17.8232 7.46967 17.5303C7.17678 17.2374 7.17678 16.7626 7.46967 16.4697L10.1161 13.8232C10.1586 13.7808 10.2033 13.742 10.25 13.7069V10.5C10.25 9.80964 10.8096 9.25 11.5 9.25H20.5C21.1904 9.25 21.75 9.80964 21.75 10.5V16.1893L23.4697 14.4697C23.7626 14.1768 24.2374 14.1768 24.5303 14.4697C24.8232 14.7626 24.8232 15.2374 24.5303 15.5303L21.8839 18.1768C21.8414 18.2192 21.7967 18.258 21.75 18.2931V21.25H28C28.4142 21.25 28.75 21.5858 28.75 22C28.75 22.4142 28.4142 22.75 28 22.75H21.5C20.8096 22.75 20.25 22.1904 20.25 21.5V18.2931C20.2033 18.258 20.1586 18.2192 20.1161 18.1768L17.4697 15.5303C17.1768 15.2374 17.1768 14.7626 17.4697 14.4697C17.7626 14.1768 18.2374 14.1768 18.5303 14.4697L20.25 16.1893V10.75H11.75Z"
      fill={props.fill}
    />
  </svg>
);

EdgedetectorEither.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  className: '',
};

export default EdgedetectorEither;
