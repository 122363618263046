import { t } from '@lingui/macro';
import {
  useDeleteModelByUuidMutation,
  usePostModelCopyByUuidMutation,
  usePutModelConfigurationUpdateByUuidMutation,
  usePutModelParametersUpdateByUuidMutation,
  usePutModelSummaryUpdateByUuidMutation,
  usePutModelUpdateByUuidMutation,
} from 'app/apiGenerated/generatedApi';
import {
  ModelConfiguration,
  ModelSummary,
  ModelUpdateRequest,
  Parameters,
} from 'app/apiGenerated/generatedApiTypes';
import { useGetProjectReadByUuidQuery } from 'app/enhancedApi';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { modelMetadataActions } from 'app/slices/modelMetadataSlice';
import { modelActions } from 'app/slices/modelSlice';
import { projectActions } from 'app/slices/projectSlice';
import { submodelsActions } from 'app/slices/submodelsSlice';
import { uiFlagsActions } from 'app/slices/uiFlagsSlice';
import { WebSocketMessageType } from 'app/third_party_types/websocket/websocket-message-type';
import React from 'react';
import { useChatContext } from 'ui/appBottomBar/assistant/ChatContextProvider';
import { useCustomPartySocket } from 'ui/common/PartySocketProvider';
import { useNotifications } from 'ui/common/notifications/useNotifications';
import { useAppParams } from 'util/useAppParams';

const invalidModelVersionErrorMessagePrefix = 'invalid model version, got';

export function isInvalidModelVersionError(error: any): boolean {
  if (error.data?.code === 409 && typeof error.data?.message === 'string') {
    const message = error.data.message as string;
    return message.startsWith(invalidModelVersionErrorMessagePrefix);
  }
  return false;
}

export function useModels() {
  const dispatch = useAppDispatch();
  const { projectId } = useAppParams();

  const customPartySocket = useCustomPartySocket();
  const { preventSendingUpdateData } = useAppSelector((state) => ({
    preventSendingUpdateData: state.model.present.preventSendingUpdateData,
  }));

  const [callUpdateModelSummaryApi] = usePutModelSummaryUpdateByUuidMutation();
  const [callUpdateModelConfigurationApi] =
    usePutModelConfigurationUpdateByUuidMutation();
  const [callUpdateModelParametersApi] =
    usePutModelParametersUpdateByUuidMutation();
  const [callUpdateModelContentApi] = usePutModelUpdateByUuidMutation();
  const [callDuplicateModelApi, { isLoading: isDuplicatingModel }] =
    usePostModelCopyByUuidMutation();

  const [callDeleteModelApi] = useDeleteModelByUuidMutation();

  const { refetch } = useGetProjectReadByUuidQuery(
    { projectUuid: projectId || '' },
    { skip: !projectId },
  );

  const { showCompletion, showError, createShowError } = useNotifications();

  const updateModelSummary = React.useCallback(
    (modelUuid: string, name: string, description?: string) => {
      if (preventSendingUpdateData) return;

      return callUpdateModelSummaryApi({
        modelUuid,
        modelSummaryUpdateRequest: { name, description },
      })
        .unwrap()
        .then(() => {
          customPartySocket.publish({
            id: 'irrelevant right now',
            type: WebSocketMessageType.BCAST_DOC_UPDATE,
            payload: { documentId: modelUuid },
          });
        })
        .catch(
          createShowError(
            t({
              id: 'modelsApi.updateModelError',
              message: 'Unable to update model information.',
            }),
          ),
        );
    },
    [
      callUpdateModelSummaryApi,
      createShowError,
      preventSendingUpdateData,
      customPartySocket,
    ],
  );

  const updateModelConfiguration = React.useCallback(
    (modelUuid: string, configuration: ModelConfiguration) => {
      if (preventSendingUpdateData) return;

      dispatch(uiFlagsActions.setUIFlag({ simConfigDataUpdating: true }));
      return callUpdateModelConfigurationApi({
        modelUuid,
        modelConfigurationUpdateRequest: { configuration },
      })
        .unwrap()
        .then(() => {
          dispatch(uiFlagsActions.setUIFlag({ simConfigDataUpdating: false }));

          customPartySocket.publish({
            id: 'irrelevant right now',
            type: WebSocketMessageType.BCAST_DOC_UPDATE,
            payload: { documentId: modelUuid },
          });
        })
        .then(() => {
          // TODO show some kind of "working indicator to know that the model configuration
          // is being saved
        })
        .catch((e) => {
          dispatch(uiFlagsActions.setUIFlag({ simConfigDataUpdating: false }));
          createShowError(
            t({
              id: 'modelsApi.updateModelConfigurationError',
              message: 'Unable to update model configuration.',
            }),
          )(e);
        });
    },
    [
      callUpdateModelConfigurationApi,
      createShowError,
      preventSendingUpdateData,
      customPartySocket,
      dispatch,
    ],
  );

  const updateModelParameters = React.useCallback(
    (modelUuid: string, parameters: Parameters) => {
      if (preventSendingUpdateData) return;

      dispatch(uiFlagsActions.setUIFlag({ simConfigDataUpdating: true }));
      return callUpdateModelParametersApi({
        modelUuid,
        modelParametersUpdateRequest: { parameters },
      })
        .unwrap()
        .then((response: ModelSummary) => {
          dispatch(uiFlagsActions.setUIFlag({ simConfigDataUpdating: false }));
          dispatch(
            modelMetadataActions.updateCurrentModelVersion({
              modelId: response.uuid,
              editId: response.version,
              updatedAt: response.updated_at,
            }),
          );
          dispatch(modelActions.updateOpenModelName(response.name));

          // TODO show some kind of "working indicator to know that the model parameters
          // are being saved
        })
        .then(() => {
          customPartySocket.publish({
            id: 'irrelevant right now',
            type: WebSocketMessageType.BCAST_DOC_UPDATE,
            payload: { documentId: modelUuid },
          });
        })
        .catch((e) => {
          dispatch(uiFlagsActions.setUIFlag({ simConfigDataUpdating: false }));
          createShowError(
            t({
              id: 'modelsApi.updateModelParametersError',
              message: 'Unable to update model parameters.',
            }),
          )(e);
        });
    },
    [
      callUpdateModelParametersApi,
      dispatch,
      createShowError,
      preventSendingUpdateData,
      customPartySocket,
    ],
  );

  // TODO ask JP if we can possibly add types to this API call
  // Intentionally not using types seems like an anti-pattern
  // From openApi.yaml:
  // |     JSONRawData:
  // |     type: object
  // |     # DO NOT bind to a go type in order to avoid serialize/deserialize
  // |     # This could drop fields that are unknown by go but used by the frontend
  const { editIds } = useChatContext();
  const updateModelContent = React.useCallback(
    (
      modelUuid: string,
      modelUpdateRequest: ModelUpdateRequest,
      refetchProjectsAfterUpdate: boolean,
      reloadOnFailure = true,
      forceSendUpdate = false, // used by chat
    ) => {
      // FIXME: it's weird to have preventSendingUpdateData AND forceSendUpdate
      // but it's hard to trace when preventSendingUpdateData is set
      if (!forceSendUpdate && preventSendingUpdateData) {
        if (process.env.NODE_ENV === 'development') {
          console.error(
            'Preventing update data from being sent because preventSendingUpdateData is true',
          );
        }
        return;
      }

      dispatch(uiFlagsActions.setUIFlag({ modelDataUpdating: true }));
      dispatch(projectActions.startModelUpdate(modelUuid));
      return callUpdateModelContentApi({
        modelUuid,
        modelUpdateRequest,
      })
        .unwrap()
        .then((response: ModelSummary | null) => {
          dispatch(uiFlagsActions.setUIFlag({ modelDataUpdating: false }));
          if (response) {
            editIds.current.set(modelUuid, response.version);
            dispatch(
              modelMetadataActions.updateCurrentModelVersion({
                modelId: response.uuid,
                editId: response.version,
                updatedAt: response.updated_at,
              }),
            );
          }
          if (refetchProjectsAfterUpdate) {
            refetch();
          }
          customPartySocket.publish({
            id: 'irrelevant right now',
            type: WebSocketMessageType.BCAST_DOC_UPDATE,
            payload: { documentId: modelUuid },
          });
        })
        .catch((e) => {
          dispatch(uiFlagsActions.setUIFlag({ modelDataUpdating: false }));
          if (e) {
            if (isInvalidModelVersionError(e)) {
              showError(
                t({
                  id: 'modelsApi.modelOutOfDateError',
                  message:
                    'Unable to apply change because model was out of date. Reloading model to get latest changes.',
                }),
              );

              dispatch(
                modelMetadataActions.reportVersionError({
                  message:
                    'Unable to update model because the model was out of date.',
                  originalError: e,
                }),
              );
              if (reloadOnFailure) {
                dispatch(projectActions.requestReloadModel());
              }
            } else {
              showError(
                t({
                  id: 'modelsApi.updateModelContentError',
                  message: 'Unable to update model content.',
                }),
                e,
              );
            }
          }
        })
        .finally(() => {
          dispatch(projectActions.completeModelUpdate(modelUuid));
        });
    },
    [
      preventSendingUpdateData,
      dispatch,
      callUpdateModelContentApi,
      customPartySocket,
      editIds,
      refetch,
      showError,
    ],
  );

  const duplicateModel = React.useCallback(
    (modelUuid: string, name: string, destination_project_uuid: string) =>
      callDuplicateModelApi({
        modelUuid,
        modelCopyRequest: { name, destination_project_uuid },
      })
        .unwrap()
        .then((createdModel) => {
          showCompletion(
            t({
              id: 'modelsApi.duplicateModelSuccess',
              message: 'Model and its dependencies have been duplicated',
            }),
          );
          dispatch(submodelsActions.requestSubmodels(destination_project_uuid));
          return createdModel;
        })
        .catch(
          createShowError(
            t({
              id: 'modelsApi.duplicateModelError',
              message: 'Unable to duplicate model.',
            }),
          ),
        ),
    [callDuplicateModelApi, dispatch, showCompletion, createShowError],
  );

  const deleteModel = React.useCallback(
    (modelUuid: string) => {
      callDeleteModelApi({
        modelUuid,
      })
        .unwrap()
        .then(() => {
          showCompletion(
            t({ id: 'modelsApi.deleteModelSuccess', message: 'Model deleted' }),
          );
        })
        .catch(
          createShowError(
            t({
              id: 'modelsApi.deleteModelError',
              message: 'Unable to delete model.',
            }),
          ),
        );
    },
    [callDeleteModelApi, showCompletion, createShowError],
  );

  return {
    updateModelSummary,
    updateModelContent,
    updateModelParameters,
    updateModelConfiguration,
    duplicateModel,
    isDuplicatingModel,
    deleteModel,
  };
}
