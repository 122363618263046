/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Save: React.FC<Props> = (props) => (
  <svg viewBox="0 0 24 24" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M4 4.75C4 4.33579 4.33579 4 4.75 4H16.75C16.9489 4 17.1397 4.07902 17.2803 4.21967L19.2803 6.21967C19.421 6.36032 19.5 6.55109 19.5 6.75V18.75C19.5 19.1642 19.1642 19.5 18.75 19.5H4.75C4.33579 19.5 4 19.1642 4 18.75V4.75ZM5.5 5.5V18H18V7.06066L16.4393 5.5H5.5Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M11.75 13.25C12.3023 13.25 12.75 12.8023 12.75 12.25C12.75 11.6977 12.3023 11.25 11.75 11.25C11.1977 11.25 10.75 11.6977 10.75 12.25C10.75 12.8023 11.1977 13.25 11.75 13.25ZM11.75 14.75C13.1307 14.75 14.25 13.6307 14.25 12.25C14.25 10.8693 13.1307 9.75 11.75 9.75C10.3693 9.75 9.25 10.8693 9.25 12.25C9.25 13.6307 10.3693 14.75 11.75 14.75Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M8.25 4C8.66421 4 9 4.33579 9 4.75V7.5H14.5V4.75C14.5 4.33579 14.8358 4 15.25 4C15.6642 4 16 4.33579 16 4.75V8.25C16 8.66421 15.6642 9 15.25 9H8.25C7.83579 9 7.5 8.66421 7.5 8.25V4.75C7.5 4.33579 7.83579 4 8.25 4Z"
      fill={props.fill}
    />
  </svg>
);

Save.defaultProps = {
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Save;
