/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Copy: React.FC<Props> = (props) => (
  <svg viewBox="0 0 24 24" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7 4.75C6.0335 4.75 5.25 5.5335 5.25 6.5V17C5.25 17.4142 5.58579 17.75 6 17.75C6.41421 17.75 6.75 17.4142 6.75 17V6.5C6.75 6.36193 6.86193 6.25 7 6.25H15.5C15.9142 6.25 16.25 5.91421 16.25 5.5C16.25 5.08579 15.9142 4.75 15.5 4.75H7Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M8 8.5C8 7.94771 8.44772 7.5 9 7.5H17C17.5523 7.5 18 7.94772 18 8.5V18.5C18 19.0523 17.5523 19.5 17 19.5H9C8.44772 19.5 8 19.0523 8 18.5V8.5ZM9.5 18V9H16.5V18H9.5Z"
      fill={props.fill}
    />
  </svg>
);

Copy.defaultProps = {
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Copy;
