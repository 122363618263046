import { t } from '@lingui/macro';
import { useProject } from 'app/api/useProject';
import { useCreateReferenceSubmodel } from 'app/behaviorHooks/useCreateReferenceSubmodel';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { modelActions } from 'app/slices/modelSlice';
import { submodelsActions } from 'app/slices/submodelsSlice';
import React from 'react';
import Button from 'ui/common/Button/Button';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import { Checkbox } from 'ui/common/Checkbox';
import Input from 'ui/common/Input/Input';
import {
  checkIsValid,
  isPositiveNumberRule,
} from 'ui/common/Input/inputValidation';
import { isValidModelOrSubmodelName } from 'ui/common/Input/inputValidationForModels';
import Label from 'ui/common/Label';
import {
  ActionButtonContainer,
  ModalInputGroup,
  SmallFormContainer,
} from 'ui/common/Modal/Modal';
import { useModal } from 'ui/common/Modal/useModal';
import { useAppParams } from 'util/useAppParams';
import { v4 as uuid } from 'uuid';

interface Props {
  projectId: string;
  groupBlockId?: string;
  groupBlockName?: string;
}

const AddReferenceSubmodelModal: React.FC<Props> = ({
  projectId,
  groupBlockId,
  groupBlockName,
}) => {
  const dispatch = useAppDispatch();

  const { modelId } = useAppParams();

  const submodels = useAppSelector(
    (state) => state.submodels.projectIdToSubmodelInfoLites[projectId],
  );
  const { project } = useProject();

  const { createReferenceSubmodel } = useCreateReferenceSubmodel();

  const validationRules = React.useMemo(
    () => (project ? isValidModelOrSubmodelName('', project, submodels) : []),
    [project, submodels],
  );

  const [nameIsValid, setNameIsValid] = React.useState(!!groupBlockName);
  React.useEffect(() => {
    if (groupBlockName !== undefined) {
      // checkIsValid returns an empty string if the value is valid
      // (not sure i agree with that personally but not gonna change it here - jackson)
      const defaultGroupNameIsValid =
        checkIsValid(groupBlockName, validationRules) === '';
      setNameIsValid(defaultGroupNameIsValid);
    }
  }, [validationRules, setNameIsValid, groupBlockName]);

  const [stepIsValid, setStepIsValid] = React.useState(false);
  const [usingDiscreteStep, setUsingDiscreteStep] = React.useState(false);
  const [name, setName] = React.useState(groupBlockName || '');
  const [discreteStep, setDiscreteStep] = React.useState('');
  const { closeModal } = useModal();

  const currentSubmodelPath = useAppSelector(
    (state) => state.model.present?.currentSubmodelPath,
  );

  const onCreateSubmodel = () => {
    if (!nameIsValid || (usingDiscreteStep && !stepIsValid)) return;

    const config = usingDiscreteStep
      ? { discrete_step: { value: discreteStep } }
      : undefined;

    if (modelId) {
      const submodelInstanceId = groupBlockId || uuid();

      dispatch(
        submodelsActions.setSubmodelToPublish({
          projectId,
          name,
          submodelInstanceParentPath: currentSubmodelPath,
          submodelInstanceId,
          submodelConfiguration: config,
        }),
      );

      // Split the submodel into its own diagram if it hasn't been
      // split off into its own diagram already.
      if (!groupBlockId) {
        dispatch(
          modelActions.createSubdiagramFromSelection({
            subdiagramType: 'core.ReferenceSubmodel',
            submodelInstanceId,
            referenceSubmodelName: name,
          }),
        );
      }
    } else {
      createReferenceSubmodel(projectId, name, undefined, undefined, config);
    }
    closeModal();
  };

  return (
    <SmallFormContainer
      onSubmit={(e) => {
        e?.preventDefault();
        onCreateSubmodel();
      }}>
      <ModalInputGroup>
        <Label testId="duplicate-name-label">
          {t({
            id: 'addReferenceSubmodelModal.nameInput.label',
            message: 'Name',
          })}
        </Label>
        <Input
          hasBorder
          autoFocus
          onChangeText={(v, isValid) => {
            setName(v);
            setNameIsValid(isValid);
          }}
          value={name}
          autoValidate={groupBlockName !== undefined}
          validationRules={validationRules}
          testId="submodel-name-textbox"
        />
      </ModalInputGroup>
      <ModalInputGroup horiz>
        <Label testId="duplicate-name-label">
          {t({
            id: 'addReferenceSubmodelModal.useDiscreteStep.label',
            message: 'Use a discrete step',
          })}
        </Label>
        <Checkbox value={usingDiscreteStep} onChange={setUsingDiscreteStep} />
      </ModalInputGroup>
      {usingDiscreteStep && (
        <ModalInputGroup horiz>
          <Label testId="duplicate-name-label">
            {t({
              id: 'addReferenceSubmodelModal.discreteStepInput.label',
              message: 'Discrete step',
            })}
          </Label>
          <Input
            hasBorder
            autoFocus
            onChangeText={(v, isValid) => {
              setDiscreteStep(v);
              setStepIsValid(isValid);
            }}
            value={discreteStep}
            validationRules={[isPositiveNumberRule]}
            testId="submodel-name-textbox"
          />
        </ModalInputGroup>
      )}
      <ActionButtonContainer>
        {/* Cancel button */}
        <Button
          type="button"
          onClick={closeModal}
          variant={ButtonVariants.LargeSecondary}
          testId="cancel-create-submodel-button">
          {t({
            id: 'addReferenceSubmodelModal.cancelButton.label',
            message: 'Cancel',
          })}
        </Button>

        {/* Create button */}
        <Button
          type="submit"
          variant={ButtonVariants.LargePrimary}
          testId="create-submodel-button"
          disabled={!nameIsValid || (usingDiscreteStep && !stepIsValid)}>
          {t({
            id: 'addReferenceSubmodelModal.createButton.label',
            message: 'Create',
          })}
        </Button>
      </ActionButtonContainer>
    </SmallFormContainer>
  );
};

export default AddReferenceSubmodelModal;
