/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  cx?: any;
  cy?: any;
  r?: any;
  stroke?: any;
  strokeWidth?: any;
  strokeLinecap?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const AcausalElectricalVoltagesensor: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <circle
      cx={props.cx}
      cy={props.cy}
      r="11.25"
      stroke={props.stroke}
      strokeWidth={props.strokeWidth}
    />
    <path
      d="M16 5.5V7.5"
      stroke={props.stroke}
      strokeWidth={props.strokeWidth}
      strokeLinecap={props.strokeLinecap}
    />
    <path
      d="M25.0938 10.75L23.3617 11.75"
      stroke={props.stroke}
      strokeWidth={props.strokeWidth}
      strokeLinecap={props.strokeLinecap}
    />
    <path
      d="M6.90625 10.75L8.6383 11.75"
      stroke={props.stroke}
      strokeWidth={props.strokeWidth}
      strokeLinecap={props.strokeLinecap}
    />
    <path
      d="M10.75 6.90673L11.75 8.63878"
      stroke={props.stroke}
      strokeWidth={props.strokeWidth}
      strokeLinecap={props.strokeLinecap}
    />
    <path
      d="M21.25 6.90673L20.25 8.63878"
      stroke={props.stroke}
      strokeWidth={props.strokeWidth}
      strokeLinecap={props.strokeLinecap}
    />
    <path
      d="M15.3848 25C15.3315 25 15.2968 24.9733 15.2808 24.92L13.6648 19.512L13.6568 19.48C13.6568 19.4267 13.6862 19.4 13.7448 19.4H14.9688C15.0275 19.4 15.0648 19.4267 15.0808 19.48L16.0008 23.12C16.0062 23.136 16.0142 23.144 16.0248 23.144C16.0355 23.144 16.0435 23.136 16.0488 23.12L16.9528 19.48C16.9688 19.4267 17.0062 19.4 17.0648 19.4H18.2648C18.2968 19.4 18.3208 19.4107 18.3368 19.432C18.3528 19.4533 18.3555 19.48 18.3448 19.512L16.7048 24.92C16.6888 24.9733 16.6542 25 16.6008 25H15.3848Z"
      fill={props.fill}
    />
    <circle cx={props.cx} cy={props.cy} r={props.r} stroke={props.stroke} />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M14.7278 7.8181C14.6273 7.41625 14.2201 7.17193 13.8183 7.2724C13.4164 7.37286 13.1721 7.78006 13.2726 8.1819L14.8229 14.3831C15.1532 14.1422 15.5601 14 16.0002 14C16.0945 14 16.1872 14.0065 16.278 14.0191L14.7278 7.8181Z"
      fill={props.fill}
    />
    <path
      d="M2 16L4 16"
      stroke={props.stroke}
      strokeWidth={props.strokeWidth}
      strokeLinecap={props.strokeLinecap}
    />
    <path
      d="M28 16L30 16"
      stroke={props.stroke}
      strokeWidth={props.strokeWidth}
      strokeLinecap={props.strokeLinecap}
    />
  </svg>
);

AcausalElectricalVoltagesensor.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  cx: '16',
  cy: '16',
  r: '1.5',
  stroke: '#1D3131',
  strokeWidth: '1.5',
  strokeLinecap: 'round',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default AcausalElectricalVoltagesensor;
