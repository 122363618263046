// !!!! -- DO NOT MANUALLY EDIT -- !!!!
// This file was generated by something in scripts/generateDefaultBlockData.js
// If you want to change the contents of this file, it should be automated
//
// (You can edit this file locally, but it may not be checked into the codebase -
// PRs with a manual edit of this file are forbidden from being merged.)

import { computationBlockDefaults } from 'app/generated_blocks/computationBlockDefaults';
import type { ComputationBlockClass } from 'app/generated_types/ComputationBlockClass';
import deepmerge from 'deepmerge';

export const AcausalTranslationalSpeedSource: ComputationBlockClass = deepmerge(
  computationBlockDefaults,
  {
    "base": {
      "name": "SpeedSource",
      "namespace": "acausal.translational",
      "description": "Speed source.",
      "feature_level": "dev"
    },
    "modes": {
      "time": "agnostic"
    },
    "ports": {
      "inputs": {
        "static": [
          {
            "name": "flange_a",
            "variant": {
              "variant_kind": "acausal",
              "acausal_domain": "translational"
            }
          }
        ],
        "conditional": [
          {
            "name": "v_ref",
            "condition_bool_parameter": "enable_speed_port",
            "order": 1
          }
        ]
      },
      "outputs": {
        "conditional": [
          {
            "name": "flange_b",
            "condition_bool_parameter": "enable_flange_b",
            "order": 0,
            "variant": {
              "variant_kind": "acausal",
              "acausal_domain": "translational"
            }
          }
        ]
      }
    },
    "parameter_definitions": [
      {
        "name": "v_ref",
        "data_type": "float",
        "default_value": "1.0",
        "description": "Constant speed in meters/second."
      },
      {
        "name": "enable_speed_port",
        "data_type": "bool",
        "default_value": "false",
        "description": "User selection for enabling inport for speed value."
      },
      {
        "name": "enable_flange_b",
        "data_type": "bool",
        "default_value": "true",
        "description": "User selection for enabling the flange_b port"
      }
    ]
  }
);
