import { useProject } from 'app/api/useProject';
import { useGetFileReadByUuidQuery } from 'app/apiGenerated/generatedApi';
import { PortSide } from 'app/common_types/PortTypes';
import { PyTwin } from 'app/generated_blocks/core/PyTwin';
import { BlockInstance } from 'app/generated_types/SimulationModel';
import { useAppDispatch } from 'app/hooks';
import { modelActions } from 'app/slices/modelSlice';
import React, { useEffect } from 'react';
import { useAppParams } from 'util/useAppParams';
import { useNotifications } from '../notifications/useNotifications';
import DataFileParameter from './DataFileParameter';

type Props = {
  parentPath: string[];
  selectedBlock: BlockInstance;
  currentValue: string;
  onSelectValue: (newValue: string) => void;
};

interface PytwinProps {
  inputs: string[];
  outputs: string[];
}

const PyTwinFileParameter: React.FC<Props> = ({
  parentPath,
  selectedBlock,
  currentValue,
  onSelectValue,
}) => {
  const dispatch = useAppDispatch();
  const { showError } = useNotifications();

  const { projectId } = useAppParams();
  const { project } = useProject();

  const pytwinFileName = selectedBlock.parameters.pytwin_file?.value || ''; // unselected file has value empty string, but types don't know that.

  const paramDef = PyTwin.parameter_definitions?.find(
    (param) => param.name === 'pytwin_file',
  );

  // Find file UUID here for now. Existing param dropdown doesn't save file UUID.
  const projectFiles =
    project?.files.filter(
      (file) => !file.error && !(file.status === 'processing_in_progress'),
    ) || [];
  const fileUuid = projectFiles.find(
    (projectFile) => projectFile.name === pytwinFileName,
  )?.uuid;

  const { data: fileData, isFetching } = useGetFileReadByUuidQuery(
    {
      projectUuid: projectId || '',
      fileUuid: fileUuid || '',
    },
    {
      skip: !projectId || !fileUuid,
    },
  );

  const [blockNeedsUpdate, setBlockNeedsUpdate] = React.useState(false);

  useEffect(() => {
    if (blockNeedsUpdate) {
      if (fileData && !isFetching) {
        const pytwinProps = fileData.properties as PytwinProps;

        if (!pytwinProps || !pytwinProps.inputs || !pytwinProps.outputs) {
          // Prevents application error, but this should have been caught by the backend.
          showError('PyTwin file is invalid');
          return;
        }

        dispatch(
          modelActions.removeAllPorts({
            parentPath,
            nodeUuid: selectedBlock.uuid,
          }),
        );

        pytwinProps.inputs.forEach((name) => {
          dispatch(
            modelActions.addPort({
              parentPath,
              nodeUuid: selectedBlock.uuid,
              portSide: PortSide.Input,
              name,
              // parameters: {},
            }),
          );
        });

        pytwinProps.outputs.forEach((name) => {
          dispatch(
            modelActions.addPort({
              parentPath,
              nodeUuid: selectedBlock.uuid,
              portSide: PortSide.Output,
              name,
              // parameters: {},
            }),
          );
        });

        setBlockNeedsUpdate(false);
      }
    }
  }, [
    blockNeedsUpdate,
    dispatch,
    fileData,
    pytwinFileName,
    isFetching,
    parentPath,
    selectedBlock,
    showError,
  ]);

  return (
    <DataFileParameter
      datafileType="core.PyTwin"
      onSelectValue={(v) => {
        onSelectValue(v);
        setBlockNeedsUpdate(true);
      }}
      currentValue={currentValue}
      paramDef={paramDef}
    />
  );
};

export default PyTwinFileParameter;
