/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  className?: string;
}

const RandomdistributionExponential: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path d="M2.25 6V25.75H30V26.25H1.75V6H2.25Z" fill={props.fill} />
    <path
      d="M4.74741 7.93772C4.71301 7.52493 4.3505 7.21819 3.93772 7.25259C3.52493 7.28699 3.21819 7.6495 3.25259 8.06228C3.78204 14.4156 8.02751 18.6006 13.0328 21.1674C18.0308 23.7305 23.9019 24.75 28 24.75C28.4142 24.75 28.75 24.4142 28.75 24C28.75 23.5858 28.4142 23.25 28 23.25C24.0981 23.25 18.4692 22.2695 13.7172 19.8326C8.97249 17.3994 5.21796 13.5844 4.74741 7.93772Z"
      fill={props.fill}
    />
  </svg>
);

RandomdistributionExponential.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  className: '',
};

export default RandomdistributionExponential;
