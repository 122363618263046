import { PayloadAction } from '@reduxjs/toolkit';
import {
  ParameterDefinition,
  SubmodelPortDefinition,
} from 'app/apiGenerated/generatedApiTypes';
import { ModelState } from 'app/modelState/ModelState';
import { getUniqueIdentifier } from 'app/transformers/uniqueNameGenerators';
import { v4 as uuid } from 'uuid';

export function addParameterDefinition(
  state: ModelState,
  action: PayloadAction<{
    name?: string;
    defaultValue?: string;
    description?: string;
  }>,
) {
  const { name, defaultValue, description } = action.payload;

  let paramName: string;
  if (name) {
    paramName = name;
  } else {
    const parameterDefinitions: ParameterDefinition[] =
      state.parameterDefinitions;
    const portDefinitionsInputs: SubmodelPortDefinition[] =
      state.portDefinitionsInputs;
    const portDefinitionsOutputs: SubmodelPortDefinition[] =
      state.portDefinitionsOutputs;

    const existingParameterNames = parameterDefinitions.map(
      (paramDef) => paramDef.name,
    );
    const existingInPortNames = portDefinitionsInputs.map((port) => port.name);
    const existingOutPortNames = portDefinitionsOutputs.map(
      (port) => port.name,
    );
    const existingNames = [
      ...existingParameterNames,
      ...existingInPortNames,
      ...existingOutPortNames,
    ];
    const minIndex = existingParameterNames.length;
    const tentativeName = `param_${minIndex}`;
    paramName = getUniqueIdentifier(tentativeName, existingNames, minIndex);
  }

  const newParameter = {
    uuid: uuid(),
    name: paramName,
    default_value: defaultValue || '',
    description,
  };

  state.parameterDefinitions.push(newParameter);
}

export function updateParameterDefinitionName(
  state: ModelState,
  action: PayloadAction<{
    paramDefId: string;
    newName: string;
  }>,
) {
  const { paramDefId, newName } = action.payload;

  const parameterDefinitions: ParameterDefinition[] =
    state.parameterDefinitions;

  const parameterToUpdateIndex = parameterDefinitions.findIndex(
    (paramDef) => paramDef.uuid === paramDefId,
  );
  if (parameterToUpdateIndex === -1) {
    return;
  }

  const parameterToUpdate = parameterDefinitions[parameterToUpdateIndex];
  parameterToUpdate.name = newName;
}

export function updateParameterDefinitionDefaultValue(
  state: ModelState,
  action: PayloadAction<{
    paramDefId: string;
    newDefaultValue: string;
    inputHeight?: string;
  }>,
) {
  const { paramDefId, newDefaultValue, inputHeight } = action.payload;

  const parameterDefinitions: ParameterDefinition[] =
    state.parameterDefinitions;

  const parameterToUpdateIndex = parameterDefinitions.findIndex(
    (paramDef) => paramDef.uuid === paramDefId,
  );
  if (parameterToUpdateIndex === -1) {
    return;
  }

  const parameterToUpdate = parameterDefinitions[parameterToUpdateIndex];
  parameterToUpdate.default_value = newDefaultValue;
  if (inputHeight) {
    if (!parameterToUpdate.uiprops) {
      parameterToUpdate.uiprops = {};
    }
    parameterToUpdate.uiprops.default_value_height = inputHeight;
  }
}

export function updateParameterDefinitionDescription(
  state: ModelState,
  action: PayloadAction<{
    paramDefId: string;
    newDescription: string;
    inputHeight?: string;
  }>,
) {
  const { paramDefId, newDescription, inputHeight } = action.payload;

  const parameterDefinitions: ParameterDefinition[] =
    state.parameterDefinitions;

  const parameterToUpdateIndex = parameterDefinitions.findIndex(
    (paramDef) => paramDef.uuid === paramDefId,
  );
  if (parameterToUpdateIndex === -1) {
    return;
  }

  const parameterToUpdate = parameterDefinitions[parameterToUpdateIndex];
  parameterToUpdate.description = newDescription;
  if (inputHeight) {
    if (!parameterToUpdate.uiprops) {
      parameterToUpdate.uiprops = {};
    }
    parameterToUpdate.uiprops.description_height = inputHeight;
  }
}

export function removeParameterDefinition(
  state: ModelState,
  action: PayloadAction<{
    paramDefId: string;
  }>,
) {
  const { paramDefId } = action.payload;

  const parameterDefinitions: ParameterDefinition[] =
    state.parameterDefinitions;
  const parameterToRemoveIndex = parameterDefinitions.findIndex(
    (paramDef) => paramDef.uuid === paramDefId,
  );
  if (parameterToRemoveIndex !== -1) {
    state.parameterDefinitions.splice(parameterToRemoveIndex, 1);
  }
}
