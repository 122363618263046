import { useAppDispatch } from 'app/hooks';
import { projectActions } from 'app/slices/projectSlice';
import { userActions } from 'app/slices/userSlice';
import React from 'react';

const UserSessionTracker: React.FC = () => {
  const dispatch = useAppDispatch();

  // Track that a user signed in so if we end up back
  // at the sign in page without refreshing,
  // we will force a refresh to ensure each user has a clean session.
  React.useEffect(() => {
    dispatch(userActions.setUserAssociationToSession());
    dispatch(projectActions.requestRefetchProjects());
  }, [dispatch]);

  return null;
};

export default UserSessionTracker;
