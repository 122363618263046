/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  className?: string;
}

const Power: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.044 14.36C14.044 14.3 14.062 14.252 14.098 14.216C14.146 14.168 14.2 14.144 14.26 14.144H15.898C15.958 14.144 16.006 14.168 16.042 14.216C16.09 14.252 16.114 14.3 16.114 14.36V23.126C16.114 23.186 16.09 23.24 16.042 23.288C16.006 23.324 15.958 23.342 15.898 23.342H14.26C14.2 23.342 14.146 23.324 14.098 23.288C14.062 23.24 14.044 23.186 14.044 23.126V22.55C14.044 22.514 14.032 22.49 14.008 22.478C13.984 22.466 13.96 22.478 13.936 22.514C13.444 23.15 12.706 23.468 11.722 23.468C10.81 23.468 10.042 23.204 9.418 22.676C8.806 22.148 8.5 21.398 8.5 20.426V14.36C8.5 14.3 8.518 14.252 8.554 14.216C8.602 14.168 8.656 14.144 8.716 14.144H10.372C10.432 14.144 10.48 14.168 10.516 14.216C10.564 14.252 10.588 14.3 10.588 14.36V19.832C10.588 20.396 10.732 20.846 11.02 21.182C11.32 21.518 11.734 21.686 12.262 21.686C12.802 21.686 13.234 21.518 13.558 21.182C13.882 20.846 14.044 20.396 14.044 19.832V14.36Z"
      fill={props.fill}
    />
    <path
      d="M20.3765 14.144C20.2965 14.144 20.2445 14.104 20.2205 14.024L18.2885 8.16801C18.2805 8.15201 18.2765 8.13201 18.2765 8.10801C18.2765 8.03601 18.3205 8.00001 18.4085 8.00001H19.6325C19.7205 8.00001 19.7765 8.04001 19.8005 8.12001L20.9645 12.128C20.9725 12.152 20.9845 12.164 21.0005 12.164C21.0165 12.164 21.0285 12.152 21.0365 12.128L22.2005 8.12001C22.2245 8.04001 22.2805 8.00001 22.3685 8.00001L23.5925 8.02401C23.6405 8.02401 23.6765 8.04001 23.7005 8.07201C23.7245 8.09601 23.7285 8.13601 23.7125 8.19201L21.7925 14.024C21.7685 14.104 21.7165 14.144 21.6365 14.144H20.3765Z"
      fill={props.fill}
    />
  </svg>
);

Power.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  className: '',
};

export default Power;
