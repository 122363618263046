import { t } from '@lingui/macro';
import { BlockInstance } from 'app/generated_types/SimulationModel';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import React from 'react';
import { requiredRules } from 'ui/common/Input/inputValidation';
import SectionHeading from 'ui/common/Inputs/SectionHeading';
import {
  DetailInputRowsSection,
  DetailsInput,
  DetailsLabel,
  DetailsRow,
  DetailsSection,
} from 'ui/modelEditor/DetailsComponents';
import {
  orderedExtraParameters,
  updateExtraParameter,
} from 'util/dynamicBlockUtils';
import { CommonBlockParametersDetails } from '../CommonBlockParameterDetails';

interface Props {
  parentPath: string[];
  node: BlockInstance;
  disabled: boolean;
}

/**
 * Quick implementation of a dynamic display for the extra FMU params.
 * Derived from the FMU the user uploads, does not perform extra validation.
 */
const FmuBlockParameterDetails: React.FC<Props> = ({
  parentPath,
  node,
  disabled,
}: Props) => {
  const dispatch = useAppDispatch();

  const { fmuBlocksEnabled } = useAppSelector(
    (state) => state.userOptions.options,
  );

  const extraParameterNames = orderedExtraParameters(node);

  if (!fmuBlocksEnabled) {
    return (
      <>
        <SectionHeading>Parameters</SectionHeading>
        <DetailInputRowsSection>
          <DetailsLabel stretch>
            Upgrade subscription to use FMU blocks
          </DetailsLabel>
        </DetailInputRowsSection>
      </>
    );
  }

  return (
    <>
      <SectionHeading testId="fmu-parameter-definition-details">
        {t({
          id: 'blockDetails.fmuBlockParametersTitle',
          message: 'Parameters',
        })}
      </SectionHeading>

      <CommonBlockParametersDetails
        parentPath={parentPath}
        selectedNode={node}
        canEdit={!disabled}
      />
      <DetailInputRowsSection>
        {extraParameterNames.map((paramName) => {
          const param = node.parameters[paramName];
          const valueKey = `extra-param-value-${paramName}`;
          const paramValue = param?.value || '';
          return (
            <DetailsSection key={paramName} vertical>
              <DetailsLabel stretch title={paramName}>
                {paramName}
              </DetailsLabel>
              <DetailsRow>
                <DetailsInput
                  grow
                  testId={valueKey}
                  value={paramValue}
                  onSubmitValue={updateExtraParameter(
                    dispatch,
                    parentPath,
                    node,
                    paramName,
                  )}
                  disabled={disabled}
                  hasBorder
                  allowMultiline
                  validationRules={requiredRules}
                />
              </DetailsRow>
            </DetailsSection>
          );
        })}
      </DetailInputRowsSection>
    </>
  );
};

export default FmuBlockParameterDetails;
