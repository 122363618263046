/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const ChevronRight: React.FC<Props> = (props) => (
  <svg viewBox="0 0 24 24" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M10.4697 16.5303C10.1768 16.2374 10.1768 15.7626 10.4697 15.4697L13.9393 12L10.4697 8.53033C10.1768 8.23744 10.1768 7.76256 10.4697 7.46967C10.7626 7.17678 11.2374 7.17678 11.5303 7.46967L15.3536 11.2929C15.7441 11.6834 15.7441 12.3166 15.3536 12.7071L11.5303 16.5303C11.2374 16.8232 10.7626 16.8232 10.4697 16.5303Z"
      fill={props.fill}
    />
  </svg>
);

ChevronRight.defaultProps = {
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default ChevronRight;
