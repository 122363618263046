import { useGetSimulationProcessResultsReadByUuidQuery } from 'app/apiGenerated/generatedApi';
import { GetSimulationProcessResultsReadByUuidApiArg } from 'app/apiGenerated/generatedApiTypes';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { dataExplorerPlotDataActions } from 'app/slices/dataExplorerPlotDataSlice';
import React from 'react';
import { extractErrorMessage } from 'ui/common/notifications/errorFormatUtils';

export function useDataExplorerData({
  modelId,
  simulationId,
  traceId,
  tracePath,
  fromTime,
  toTime,
}: {
  modelId: string;
  simulationId: string;
  traceId: string;
  tracePath: string;
  fromTime?: number;
  toTime?: number;
}) {
  const dispatch = useAppDispatch();

  const downsamplingAlgorithm = useAppSelector(
    (state) => state.visualizer.downsamplingAlgorithm,
  );

  const requestBody: GetSimulationProcessResultsReadByUuidApiArg = {
    threshold: 5000,
    downsamplingAlgorithm:
      downsamplingAlgorithm === 'none' ? undefined : downsamplingAlgorithm,
    fromTime,
    toTime,
    modelUuid: modelId,
    simulationUuid: simulationId,
    signalNames: tracePath,
  };

  const tracePathHidden = useAppSelector(
    (state) => state.dataExplorerPlotData.hiddenTracePaths[tracePath],
  );

  React.useEffect(() => {
    if (tracePathHidden) {
      dispatch(
        dataExplorerPlotDataActions.updateTraceLoadState({
          traceId,
          tracePath,
          errorMessage:
            'Block of the same name was deleted. Please re-run the simulation to see accurate results for this block.',
        }),
      );
    }
  }, [tracePathHidden, dispatch, traceId, tracePath]);

  const { data, error, isSuccess } =
    useGetSimulationProcessResultsReadByUuidQuery(requestBody, {
      skip: tracePathHidden,
    });

  // Update the loader state so we know this trace is ready to be fetched.
  React.useEffect(() => {
    if (data && isSuccess) {
      dispatch(
        dataExplorerPlotDataActions.updateTraceLoadState({
          traceId,
          tracePath,
          s3_url: data.s3_urls[0],
        }),
      );
    }
  }, [dispatch, traceId, tracePath, data, isSuccess]);

  // Update the loader state so we know this trace has an error and we shouldn't wait for it.
  React.useEffect(() => {
    if (error) {
      dispatch(
        dataExplorerPlotDataActions.updateTraceLoadState({
          traceId,
          tracePath,
          errorMessage: extractErrorMessage(error),
        }),
      );
    }
  }, [dispatch, traceId, tracePath, error]);
}
