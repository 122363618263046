// !!!! -- DO NOT MANUALLY EDIT -- !!!!
// This file was generated by something in scripts/generateDefaultBlockData.js
// If you want to change the contents of this file, it should be automated
//
// (You can edit this file locally, but it may not be checked into the codebase -
// PRs with a manual edit of this file are forbidden from being merged.)

import { computationBlockDefaults } from 'app/generated_blocks/computationBlockDefaults';
import type { ComputationBlockClass } from 'app/generated_types/ComputationBlockClass';
import deepmerge from 'deepmerge';

export const WhiteNoise: ComputationBlockClass = deepmerge(
  computationBlockDefaults,
  {
    "base": {
      "name": "WhiteNoise",
      "namespace": "core",
      "description": "Generates a band-limited white noise signal.",
      "help_url": "https://docs.collimator.ai/using-model-editors/block-library/continuous-blocks#white-noise"
    },
    "modes": {},
    "ports": {
      "outputs": {
        "static": [
          {
            "name": "out_0"
          }
        ]
      }
    },
    "parameter_definitions": [
      {
        "name": "correlation_time",
        "data_type": "float",
        "description": "Sample rate of the underlying random number generator. If empty, matches the global clock tick.",
        "optional": true,
        "default_value": ""
      },
      {
        "name": "noise_power",
        "data_type": "float",
        "default_value": "1.0",
        "description": "Variance of the noise signal"
      },
      {
        "name": "num_samples",
        "data_type": "int",
        "default_value": "10",
        "description": "The number of samples to use for sinc interpolation.  More samples will result in a more accurate approximation of the ideal power spectrum, but will also increase the computational cost.  The default of 10 is sufficient for most applications."
      },
      {
        "name": "seed",
        "data_type": "float",
        "description": "An integer seed for the random number generator. If empty, a random 32-bit seed will be generated.",
        "optional": true,
        "default_value": ""
      },
      {
        "name": "dtype",
        "data_type": "string",
        "default_value": "float64",
        "allowed_values": [
          "float16",
          "float32",
          "float64",
          "float128"
        ],
        "description": "Data type of the signal"
      },
      {
        "name": "shape",
        "data_type": "any",
        "default_value": "()",
        "description": "Array shape of the signal"
      }
    ]
  }
);
