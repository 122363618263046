/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  className?: string;
}

const Productofelements: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.25098 23V21.8926L10.6836 21.6465V11.5654L9.25098 11.3193V10.2031H21.8018V11.3193L20.3691 11.5654V21.6465L21.8018 21.8926V23H17.2051V21.8926L18.6377 21.6465V11.5654H12.415V21.6465L13.8477 21.8926V23H9.25098Z"
      fill={props.fill}
    />
  </svg>
);

Productofelements.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  className: '',
};

export default Productofelements;
