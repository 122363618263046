/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const AcausalElectricalGround: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M5.25 16C5.25 15.5858 5.58579 15.25 6 15.25L17.25 15.25L17.25 6C17.25 5.58579 17.5858 5.25 18 5.25C18.4142 5.25 18.75 5.58579 18.75 6L18.75 26C18.75 26.4142 18.4142 26.75 18 26.75C17.5858 26.75 17.25 26.4142 17.25 26L17.25 16.75L6 16.75C5.58579 16.75 5.25 16.4142 5.25 16ZM22 22.1346C21.5858 22.1346 21.25 21.7988 21.25 21.3846L21.25 10.6154C21.25 10.2012 21.5858 9.86538 22 9.86538C22.4142 9.86538 22.75 10.2012 22.75 10.6154L22.75 21.3846C22.75 21.7988 22.4142 22.1346 22 22.1346ZM26 19.0577C25.5858 19.0577 25.25 18.7219 25.25 18.3077L25.25 13.6923C25.25 13.2781 25.5858 12.9423 26 12.9423C26.4142 12.9423 26.75 13.2781 26.75 13.6923L26.75 18.3077C26.75 18.7219 26.4142 19.0577 26 19.0577Z"
      fill={props.fill}
    />
  </svg>
);

AcausalElectricalGround.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default AcausalElectricalGround;
