/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const DiscretepidPid: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1 4.75C1.13807 4.75 1.25 4.86193 1.25 5V25.7927C1.32819 25.765 1.41234 25.75 1.5 25.75H2.75V24.75C2.75 24.1977 3.19772 23.75 3.75 23.75H4.75V11.75C4.75 11.1977 5.19771 10.75 5.75 10.75H6.75V6.25C6.75 5.69772 7.19771 5.25 7.75 5.25H9.25C9.80229 5.25 10.25 5.69772 10.25 6.25H11.25C11.8023 6.25 12.25 6.69772 12.25 7.25V8.75H13.25C13.8023 8.75 14.25 9.19772 14.25 9.75H14.75C14.75 9.19772 15.1977 8.75 15.75 8.75H16.8838C17.0567 8.4511 17.3799 8.25 17.75 8.25H30C30.4142 8.25 30.75 8.58579 30.75 9C30.75 9.41421 30.4142 9.75 30 9.75H18.1162C17.9433 10.0489 17.6201 10.25 17.25 10.25H16.25C16.25 10.8023 15.8023 11.25 15.25 11.25H13.75C13.1977 11.25 12.75 10.8023 12.75 10.25H11.75C11.1977 10.25 10.75 9.80228 10.75 9.25V7.75H9.75C9.19772 7.75 8.75 7.30228 8.75 6.75H8.25V11.25C8.25 11.8023 7.80228 12.25 7.25 12.25H6.25V24.25C6.25 24.8023 5.80228 25.25 5.25 25.25H4.25V26.25C4.25 26.4321 4.2013 26.6029 4.11622 26.75H31C31.1381 26.75 31.25 26.8619 31.25 27C31.25 27.1381 31.1381 27.25 31 27.25H0.75V5C0.75 4.86193 0.861929 4.75 1 4.75Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M14.4003 15.588C14.9043 15.588 15.3483 15.692 15.7323 15.9C16.1163 16.108 16.4123 16.404 16.6203 16.788C16.8363 17.164 16.9443 17.6 16.9443 18.096C16.9443 18.584 16.8363 19.016 16.6203 19.392C16.4043 19.768 16.0963 20.06 15.6963 20.268C15.3043 20.468 14.8483 20.568 14.3283 20.568H12.4083C12.3683 20.568 12.3483 20.588 12.3483 20.628V23.856C12.3483 23.896 12.3323 23.932 12.3003 23.964C12.2763 23.988 12.2443 24 12.2043 24H11.1003C11.0603 24 11.0243 23.988 10.9923 23.964C10.9683 23.932 10.9563 23.896 10.9563 23.856V15.732C10.9563 15.692 10.9683 15.66 10.9923 15.636C11.0243 15.604 11.0603 15.588 11.1003 15.588H14.4003ZM14.1963 19.44C14.6043 19.44 14.9323 19.32 15.1803 19.08C15.4283 18.84 15.5523 18.524 15.5523 18.132C15.5523 17.732 15.4283 17.408 15.1803 17.16C14.9323 16.912 14.6043 16.788 14.1963 16.788H12.4083C12.3683 16.788 12.3483 16.808 12.3483 16.848V19.38C12.3483 19.42 12.3683 19.44 12.4083 19.44H14.1963Z"
      fill={props.fill}
    />
    <path
      d="M18.3091 24C18.2691 24 18.2331 23.988 18.2011 23.964C18.1771 23.932 18.1651 23.896 18.1651 23.856V15.744C18.1651 15.704 18.1771 15.672 18.2011 15.648C18.2331 15.616 18.2691 15.6 18.3091 15.6H19.4131C19.4531 15.6 19.4851 15.616 19.5091 15.648C19.5411 15.672 19.5571 15.704 19.5571 15.744V23.856C19.5571 23.896 19.5411 23.932 19.5091 23.964C19.4851 23.988 19.4531 24 19.4131 24H18.3091Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M21.4497 24C21.4097 24 21.3737 23.988 21.3417 23.964C21.3177 23.932 21.3057 23.896 21.3057 23.856V15.744C21.3057 15.704 21.3177 15.672 21.3417 15.648C21.3737 15.616 21.4097 15.6 21.4497 15.6H24.3777C24.9457 15.6 25.4457 15.7 25.8777 15.9C26.3097 16.1 26.6417 16.388 26.8737 16.764C27.1137 17.132 27.2337 17.564 27.2337 18.06V21.54C27.2337 22.036 27.1137 22.472 26.8737 22.848C26.6417 23.216 26.3097 23.5 25.8777 23.7C25.4457 23.9 24.9457 24 24.3777 24H21.4497ZM22.6977 22.74C22.6977 22.78 22.7177 22.8 22.7577 22.8H24.4377C24.8617 22.8 25.1977 22.668 25.4457 22.404C25.7017 22.132 25.8337 21.772 25.8417 21.324V18.288C25.8417 17.832 25.7137 17.472 25.4577 17.208C25.2017 16.936 24.8577 16.8 24.4257 16.8H22.7577C22.7177 16.8 22.6977 16.82 22.6977 16.86V22.74Z"
      fill={props.fill}
    />
  </svg>
);

DiscretepidPid.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default DiscretepidPid;
