import { SimulationResultsS3Url } from 'app/apiGenerated/generatedApiTypes';
import { API_BASE_URL } from 'app/config/globalApplicationConfig';
import { downloadBlobFile } from 'util/fileUtils';

export function downloadSimulationDump(simulationId: string) {
  fetch(`${API_BASE_URL}/simulations/${simulationId}/dump`)
    .then((response) => response.json())
    .then((data: SimulationResultsS3Url) => {
      if (!data.url) {
        throw Error(`Failed to retrieve simulation's dump: ${data.error}`);
      }

      fetch(data.url)
        .then((dumpResp) => dumpResp.blob())
        .then((blob) => {
          const fileName = `sim-${simulationId}.zip`;
          downloadBlobFile(blob, fileName);
        });
    });
}
