// !!!! -- DO NOT MANUALLY EDIT -- !!!!
// This file was generated by something in scripts/generateDefaultBlockData.js
// If you want to change the contents of this file, it should be automated
//
// (You can edit this file locally, but it may not be checked into the codebase -
// PRs with a manual edit of this file are forbidden from being merged.)

import { computationBlockDefaults } from 'app/generated_blocks/computationBlockDefaults';
import type { ComputationBlockClass } from 'app/generated_types/ComputationBlockClass';
import deepmerge from 'deepmerge';

export const Stop: ComputationBlockClass = deepmerge(
  computationBlockDefaults,
  {
    "base": {
      "name": "Stop",
      "namespace": "core",
      "description": "When true stop simulation at end of major or minor step, which ever comes first.."
    },
    "modes": {
      "time": "any"
    },
    "ports": {
      "inputs": {
        "static": [
          {
            "name": "in_0"
          }
        ]
      }
    }
  }
);
