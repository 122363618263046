import styled from '@emotion/styled/macro';

export const MenuItemWrapper = styled.div`
  &:first-of-type {
    margin-top: ${(props) => props.theme.spacing.small};
  }
  height: ${(props) => props.theme.spacing.xlarge};
  padding: ${(props) => props.theme.spacing.small}
    ${(props) => props.theme.spacing.normal};
  margin-bottom: ${(props) => props.theme.spacing.small};
  display: flex;
  align-items: center;
`;
