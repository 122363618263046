/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  className?: string;
}

const Transferfunction: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.002 9.296C9.002 9.176 9.062 9.116 9.182 9.116H10.55C10.67 9.116 10.73 9.176 10.73 9.296V21.536C10.73 21.656 10.67 21.716 10.55 21.716H9.182C9.062 21.716 9.002 21.656 9.002 21.536V16.19C9.002 16.142 8.978 16.118 8.93 16.118H3.8C3.752 16.118 3.728 16.142 3.728 16.19V21.536C3.728 21.656 3.668 21.716 3.548 21.716H2.18C2.06 21.716 2 21.656 2 21.536V9.296C2 9.176 2.06 9.116 2.18 9.116H3.548C3.668 9.116 3.728 9.176 3.728 9.296V14.552C3.728 14.6 3.752 14.624 3.8 14.624H8.93C8.978 14.624 9.002 14.6 9.002 14.552V9.296Z"
      fill={props.fill}
    />
    <path
      d="M15.3756 23.516C15.2916 23.516 15.2196 23.48 15.1596 23.408C14.5356 22.496 14.0316 21.38 13.6476 20.06C13.2636 18.74 13.0716 17.324 13.0716 15.812C13.0716 14.264 13.2636 12.824 13.6476 11.492C14.0436 10.148 14.5596 9.02 15.1956 8.108C15.2436 8.036 15.3156 8 15.4116 8H16.7076C16.7796 8 16.8276 8.024 16.8516 8.072C16.8756 8.108 16.8696 8.156 16.8336 8.216C16.3056 9.164 15.8676 10.298 15.5196 11.618C15.1836 12.926 15.0156 14.318 15.0156 15.794C15.0156 17.27 15.1836 18.656 15.5196 19.952C15.8676 21.248 16.3056 22.364 16.8336 23.3C16.8576 23.348 16.8696 23.384 16.8696 23.408C16.8696 23.48 16.8156 23.516 16.7076 23.516H15.3756Z"
      fill={props.fill}
    />
    <path
      d="M21.8458 21.824C21.1258 21.824 20.4898 21.716 19.9378 21.5C19.3978 21.284 18.9778 20.99 18.6778 20.618C18.3778 20.234 18.2278 19.814 18.2278 19.358V19.16C18.2278 19.04 18.2878 18.98 18.4078 18.98H19.7038C19.8238 18.98 19.8838 19.04 19.8838 19.16V19.25C19.8838 19.586 20.0638 19.88 20.4238 20.132C20.7958 20.384 21.2638 20.51 21.8278 20.51C22.3678 20.51 22.8058 20.396 23.1418 20.168C23.4778 19.928 23.6458 19.628 23.6458 19.268C23.6458 18.92 23.4898 18.662 23.1778 18.494C22.8658 18.314 22.3618 18.128 21.6658 17.936C20.9818 17.744 20.4058 17.552 19.9378 17.36C19.4818 17.156 19.0918 16.874 18.7678 16.514C18.4558 16.142 18.2998 15.668 18.2998 15.092C18.2998 14.288 18.6118 13.652 19.2358 13.184C19.8718 12.704 20.7118 12.464 21.7558 12.464C22.4518 12.464 23.0638 12.578 23.5918 12.806C24.1318 13.022 24.5458 13.328 24.8338 13.724C25.1218 14.12 25.2658 14.564 25.2658 15.056V15.11C25.2658 15.23 25.2058 15.29 25.0858 15.29H23.8258C23.7058 15.29 23.6458 15.23 23.6458 15.11V15.056C23.6458 14.708 23.4718 14.42 23.1238 14.192C22.7878 13.952 22.3258 13.832 21.7378 13.832C21.2098 13.832 20.7778 13.934 20.4418 14.138C20.1178 14.342 19.9558 14.618 19.9558 14.966C19.9558 15.314 20.1118 15.578 20.4238 15.758C20.7478 15.938 21.2638 16.13 21.9718 16.334C22.6678 16.526 23.2438 16.718 23.6998 16.91C24.1678 17.09 24.5638 17.366 24.8878 17.738C25.2118 18.098 25.3738 18.572 25.3738 19.16C25.3738 19.976 25.0498 20.624 24.4018 21.104C23.7658 21.584 22.9138 21.824 21.8458 21.824Z"
      fill={props.fill}
    />
    <path
      d="M28.1233 8C28.2073 8 28.2793 8.036 28.3393 8.108C28.9753 9.02 29.4853 10.136 29.8693 11.456C30.2533 12.776 30.4453 14.192 30.4453 15.704C30.4453 17.24 30.2473 18.68 29.8513 20.024C29.4673 21.356 28.9573 22.484 28.3213 23.408C28.2733 23.48 28.2013 23.516 28.1053 23.516H26.7913C26.7193 23.516 26.6713 23.492 26.6473 23.444C26.6233 23.408 26.6293 23.36 26.6653 23.3C27.2053 22.352 27.6433 21.224 27.9793 19.916C28.3273 18.596 28.5013 17.198 28.5013 15.722C28.5013 14.258 28.3273 12.878 27.9793 11.582C27.6433 10.274 27.2053 9.152 26.6653 8.216C26.6413 8.168 26.6293 8.132 26.6293 8.108C26.6293 8.036 26.6833 8 26.7913 8H28.1233Z"
      fill={props.fill}
    />
  </svg>
);

Transferfunction.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  className: '',
};

export default Transferfunction;
