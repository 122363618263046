/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Stack: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19 5.25C19.4142 5.25 19.75 5.58579 19.75 6C19.75 6.41421 19.4142 6.75 19 6.75H16.75V25.25H19C19.4142 25.25 19.75 25.5858 19.75 26C19.75 26.4142 19.4142 26.75 19 26.75H16.5C15.8096 26.75 15.25 26.1904 15.25 25.5V6.5C15.25 5.80964 15.8096 5.25 16.5 5.25H19Z"
      fill={props.fill}
    />
    <path
      d="M25.9999 5.25C25.5857 5.25 25.2499 5.58579 25.2499 6C25.2499 6.41421 25.5857 6.75 25.9999 6.75H28.2499V25.25H25.9999C25.5857 25.25 25.2499 25.5858 25.2499 26C25.2499 26.4142 25.5857 26.75 25.9999 26.75H28.4999C29.1902 26.75 29.7499 26.1904 29.7499 25.5V6.5C29.7499 5.80964 29.1902 5.25 28.4999 5.25H25.9999Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M23.9899 14.9C24.0099 14.9667 24.0532 15 24.1199 15H25.3899C25.4632 15 25.4999 14.97 25.4999 14.91C25.4999 14.89 25.4966 14.8733 25.4899 14.86L23.3299 8.1C23.3099 8.03333 23.2666 8 23.1999 8H21.6299C21.5632 8 21.5199 8.03333 21.4999 8.1L19.3399 14.86C19.3266 14.9067 19.3299 14.9433 19.3499 14.97C19.3699 14.99 19.3999 15 19.4399 15H20.7099C20.7766 15 20.8199 14.9667 20.8399 14.9L21.1299 13.92C21.1432 13.8933 21.1599 13.88 21.1799 13.88H23.6399C23.6599 13.88 23.6766 13.8933 23.6899 13.92L23.9899 14.9ZM21.5299 12.79C21.4966 12.79 21.4832 12.77 21.4899 12.73L22.3699 9.76C22.3766 9.74 22.3866 9.73 22.3999 9.73C22.4132 9.72333 22.4232 9.73333 22.4299 9.76L23.3299 12.73C23.3432 12.77 23.3299 12.79 23.2899 12.79H21.5299Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M6.78019 18.84C6.71352 18.84 6.67019 18.8067 6.65019 18.74L6.35019 17.76C6.33685 17.7333 6.32019 17.72 6.30019 17.72H3.84019C3.82019 17.72 3.80352 17.7333 3.79019 17.76L3.50019 18.74C3.48019 18.8067 3.43685 18.84 3.37019 18.84H2.10019C2.06019 18.84 2.03019 18.83 2.01019 18.81C1.99019 18.7833 1.98685 18.7467 2.00019 18.7L4.16019 11.94C4.18019 11.8733 4.22352 11.84 4.29019 11.84H5.86019C5.92685 11.84 5.97019 11.8733 5.99019 11.94L8.15019 18.7C8.15685 18.7133 8.16019 18.73 8.16019 18.75C8.16019 18.81 8.12352 18.84 8.05019 18.84H6.78019ZM4.15019 16.57C4.14352 16.61 4.15685 16.63 4.19019 16.63H5.95019C5.99019 16.63 6.00352 16.61 5.99019 16.57L5.09019 13.6C5.08352 13.5733 5.07352 13.5633 5.06019 13.57C5.04685 13.57 5.03685 13.58 5.03019 13.6L4.15019 16.57Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M23.9899 23.9C24.0099 23.9667 24.0532 24 24.1199 24H25.3899C25.4632 24 25.4999 23.97 25.4999 23.91C25.4999 23.89 25.4966 23.8733 25.4899 23.86L23.3299 17.1C23.3099 17.0333 23.2666 17 23.1999 17H21.6299C21.5632 17 21.5199 17.0333 21.4999 17.1L19.3399 23.86C19.3266 23.9067 19.3299 23.9433 19.3499 23.97C19.3699 23.99 19.3999 24 19.4399 24H20.7099C20.7766 24 20.8199 23.9667 20.8399 23.9L21.1299 22.92C21.1432 22.8933 21.1599 22.88 21.1799 22.88H23.6399C23.6599 22.88 23.6766 22.8933 23.6899 22.92L23.9899 23.9ZM21.5299 21.79C21.4966 21.79 21.4832 21.77 21.4899 21.73L22.3699 18.76C22.3766 18.74 22.3866 18.73 22.3999 18.73C22.4132 18.7233 22.4232 18.7333 22.4299 18.76L23.3299 21.73C23.3432 21.77 23.3299 21.79 23.2899 21.79H21.5299Z"
      fill={props.fill}
    />
    <path
      d="M10.7003 11.9697C10.4074 11.6768 9.93248 11.6768 9.63959 11.9697C9.3467 12.2626 9.3467 12.7374 9.63959 13.0303L12.1093 15.5L9.63959 17.9697C9.3467 18.2626 9.3467 18.7374 9.63959 19.0303C9.93248 19.3232 10.4074 19.3232 10.7003 19.0303L13.3467 16.3839C13.8349 15.8957 13.8349 15.1043 13.3467 14.6161L10.7003 11.9697Z"
      fill={props.fill}
    />
  </svg>
);

Stack.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Stack;
