import { t } from '@lingui/macro';
import { BlockClassName } from 'app/generated_types/SimulationModel';
import React from 'react';
import { BlockLibraryDragItem } from 'ui/objectBrowser/sections/BlockLibraryDragItem';
import LibrarySubsection from 'ui/objectBrowser/sections/LibrarySubsection';

export const iteratorOnlyBlocks: BlockClassName[] = [
  'core.LoopBreak',
  'core.LoopCounter',
  'core.LoopMemory',
];

interface Props {
  lowercaseSearchString: string;
}

export const IteratorBlocks: React.FC<Props> = ({ lowercaseSearchString }) => {
  const filteredIterBlocks = iteratorOnlyBlocks.filter((block) =>
    block.toLowerCase().includes(lowercaseSearchString),
  );

  if (filteredIterBlocks.length === 0) {
    return null;
  }

  return (
    <LibrarySubsection
      title={t({
        id: 'modelEditor.library.iteratorBlocks',
        message: 'Iterator Blocks',
      })}
      count={filteredIterBlocks.length}>
      {filteredIterBlocks.map((iteratorBlockClassName) => (
        <BlockLibraryDragItem
          blockClassName={iteratorBlockClassName}
          key={iteratorBlockClassName}
        />
      ))}
    </LibrarySubsection>
  );
};
