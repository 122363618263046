import styled from '@emotion/styled/macro';
import {
  useDeletePreRegMetaByEmailMutation,
  useGetPreRegMetasAllQuery,
  usePostCreatePreRegMetaMutation,
} from 'app/apiGenerated/generatedApi';
import React from 'react';
import * as Headings from 'ui/common/typography/Typography';
import { useNavigate } from 'react-router-dom';
import Button from 'ui/common/Button/Button';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import * as Icons from 'ui/common/Icons';

const Wrapper = styled.section`
  height: 100%;
  display: flex;
  flex-direction: column;
  background: ${(props) => props.theme.colors.ui.background};
`;

const Content = styled.section`
  background-color: ${(props) => props.theme.colors.grey[5]};
  border-radius: ${(props) => props.theme.spacing.small};
  margin: ${(props) => props.theme.spacing.xlarge};
  padding: ${(props) => props.theme.spacing.xlarge};
  overflow-y: auto;
  overflow-x: hidden;
  font-size: ${(props) => props.theme.typography.font.small.size};
`;

const Head = styled.header`
  display: flex;
  margin-bottom: ${(props) => props.theme.spacing.xlarge};
  padding: 10px;
`;

const Title = styled(Headings.H2)`
  width: 100%;
`;

const MetaList = styled.div`
  margin-top: 10px;
  border-bottom: 1px solid gray;
`;

const MetaRow = styled.div`
  display: flex;
  align-items: center;
  padding: 0 10px;
  height: 30px;
  border-top: 1px solid gray;
`;

const MetaEmail = styled.div`
  margin-left: 10px;
`;

export const PreRegMetaList: React.FC = () => {
  const navigate = useNavigate();

  const [newEmailInput, setNewEmail] = React.useState<string>('');

  const { data: preRegMetas, isSuccess } = useGetPreRegMetasAllQuery();
  const [callCreatePreRegMeta] = usePostCreatePreRegMetaMutation();

  const [callDeletePreRegMeta] = useDeletePreRegMetaByEmailMutation();

  const onAddNew = () => {
    callCreatePreRegMeta({
      preRegMetaCreateRequest: {
        user_email: newEmailInput,
      },
    }).then(() => {
      setNewEmail('');
    });
  };

  const onDelete = (userEmail: string) => {
    callDeletePreRegMeta({
      email: userEmail,
    });
  };

  return (
    <Wrapper>
      <Content>
        <Head>
          <Title>Payment-exempt emails (pre-registration)</Title>
        </Head>
        <div>
          <button onClick={() => navigate('/admin')}>
            Back to regular admin area
          </button>
        </div>
        <br />
        <br />
        <div>
          <label>Add new exempt email:</label>
          <input
            type="text"
            value={newEmailInput}
            onChange={(e) => setNewEmail(e.target.value)}
          />
          <button onClick={onAddNew} disabled={!newEmailInput}>
            Add
          </button>
        </div>
        <br />
        <br />
        <div>
          <h3>All exempt emails:</h3>
        </div>
        <MetaList>
          {isSuccess
            ? preRegMetas.prereg_metas.map((meta) => (
                <MetaRow>
                  <Button
                    Icon={Icons.Standard.Remove}
                    variant={ButtonVariants.LargeTertiary}
                    onClick={(
                      e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
                    ) => {
                      e.preventDefault();
                      onDelete(meta.user_email);
                    }}
                  />
                  <MetaEmail>{meta.user_email}</MetaEmail>
                </MetaRow>
              ))
            : null}
        </MetaList>
      </Content>
    </Wrapper>
  );
};
