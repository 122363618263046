import { t } from '@lingui/macro';
import { useProjectActions } from 'app/api/useProjectActions';
import { useProjects } from 'app/api/useProjects';
import { Project } from 'app/apiTransformers/convertAPIProjectToProject';
import React from 'react';
import TooltipButton from 'ui/common/Button/TooltipButton';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import Copy from 'ui/common/Icons/Standard/Copy';
import { ProjectFilter } from 'util/projectFilterUtils';
import { useNavigate } from 'react-router-dom';

type Props = {
  projectFilter: ProjectFilter;
  selectionState: Record<string, boolean>;
  numSelected: number;
  navigateToNewProject?: boolean;
};

export const DuplicateProjectButton: React.FC<Props> = ({
  projectFilter,
  selectionState,
  numSelected,
  navigateToNewProject,
}: Props) => {
  const navigate = useNavigate();
  const { projects } = useProjects(projectFilter);
  const { duplicateProject } = useProjectActions();

  const selectedProject = React.useMemo(() => {
    if (!projects) return;
    return projects.find((project: Project) => selectionState[project.uuid]);
  }, [projects, selectionState]);

  const onDuplicateProject = async () => {
    const p = selectedProject;
    if (!p) return;
    const newTitle = `${p.title} - Copy - ${new Date().toISOString()}`;
    const projectCopy = await duplicateProject({
      projectUuid: p.uuid,
      projectCreateRequest: {
        title: newTitle,
        description: p.description,
      },
    });
    if (projectCopy) {
      if (navigateToNewProject) {
        // Take them to the new project
        navigate(`/projects/${projectCopy.uuid}`);
      } else {
        // If in list view, take them to 'My Projects' so that they see the new copy.
        navigate(`/projects`);
      }
    }
  };

  const tooltip = t({
    id: 'dashboard.projects.duplicateProjectButton.Label',
    message: 'Duplicate Project',
  });

  return (
    <TooltipButton
      tooltip={tooltip}
      Icon={Copy}
      largeIcon
      variant={ButtonVariants.LargeSecondary}
      onClick={onDuplicateProject}
      disabled={numSelected !== 1}
      testId="duplicate-project-button"
    />
  );
};
