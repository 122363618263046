import styled from '@emotion/styled';
import { OptimizationAlgoName } from 'app/api/custom_types/optimizations';
import React from 'react';
import { Checkbox } from 'ui/common/Checkbox';
import Input from 'ui/common/Input/Input';
import SectionHeading from 'ui/common/Inputs/SectionHeading';
import SelectInput, { SelectInputOption } from 'ui/common/SelectInput';
import { ExpandableDetailsContainer } from './ExpandableDetailsContainer';
import {
  AlgoFieldData,
  FieldDataKind,
  OptimizationAlgorithmsFields,
} from './OptimizerAlgoList';
import {
  OptimizerInputGroup,
  OptimizerInputLabel,
} from './optimizerModalUtils';

const AdvancedFieldsContainer = styled.div`
  ${({ theme }) => `padding-left: ${theme.spacing.xlarge};`}
`;

const AlgorithmField = ({
  fieldData,
  fieldValue,
  setField,
}: {
  fieldData: AlgoFieldData;
  fieldValue: string | boolean | undefined;
  setField: (newVal: string | boolean | undefined) => void;
}) => (
  <OptimizerInputGroup key={fieldData.name}>
    <OptimizerInputLabel>{fieldData.label}</OptimizerInputLabel>
    {!fieldData.kind || fieldData.kind === FieldDataKind.Input ? (
      <Input
        placeholder={fieldData.placeholder || ''}
        value={fieldValue !== undefined ? `${fieldValue}` : fieldData.default}
        defaultValue={fieldData.default || ''}
        onChangeText={setField}
        onClickRightIcon={() => setField(undefined)}
        rightIconIsResetButton
        hasBorder
      />
    ) : (
      <Checkbox value={Boolean(fieldValue)} onChange={setField} />
    )}
  </OptimizerInputGroup>
);

const AlgorithmFields = ({
  algorithm,
  fieldValues,
  setFields,
  setAlgo,
  hasConstraints,
}: {
  algorithm: OptimizationAlgoName;
  fieldValues: { [key: string]: string | boolean | undefined };
  setFields: (fields: { [key: string]: string | boolean | undefined }) => void;
  setAlgo: (newAlgo: OptimizationAlgoName) => void;
  hasConstraints: boolean;
}) => {
  const algoOptions: Array<SelectInputOption> = React.useMemo(
    () =>
      Object.keys(OptimizationAlgorithmsFields)
        .filter((value) => !OptimizationAlgorithmsFields[value].hidden)
        .map((value) => ({
          value,
          label: OptimizationAlgorithmsFields[value]?.label,
        })),
    [],
  );

  const selectedAlgoData = OptimizationAlgorithmsFields[algorithm];
  const mainFields = selectedAlgoData.mainFields;

  const onSelectedAlgo = (newVal: string) => {
    const algo = newVal as OptimizationAlgoName;
    setAlgo(algo);
  };

  const setField = (
    fieldName: string,
    newVal: string | boolean | undefined,
  ) => {
    setFields({ ...fieldValues, [fieldName]: newVal });
  };

  const isOptionDisabled = (option: SelectInputOption) =>
    hasConstraints &&
    !OptimizationAlgorithmsFields[option.value].supportsConstraints;

  const selectedOptionIsDisabled = isOptionDisabled({
    value: algorithm,
    label: '',
  });

  return (
    <>
      <SectionHeading noBorder>Algorithm</SectionHeading>
      <OptimizerInputGroup>
        <SelectInput
          currentValue={algorithm}
          onSelectValue={onSelectedAlgo}
          options={algoOptions}
          isOptionDisabled={(option) =>
            hasConstraints &&
            !OptimizationAlgorithmsFields[option.value].supportsConstraints
          }
          isInvalid={selectedOptionIsDisabled}
        />
      </OptimizerInputGroup>
      {mainFields.map((fieldData) => (
        <AlgorithmField
          fieldData={fieldData}
          fieldValue={fieldValues[fieldData.name]}
          setField={(newVal) => setField(fieldData.name, newVal)}
          key={fieldData.name}
        />
      ))}

      <ExpandableDetailsContainer label="Advanced settings">
        <AdvancedFieldsContainer>
          {OptimizationAlgorithmsFields[algorithm].settingsFields.map(
            (fieldData) => (
              <AlgorithmField
                fieldData={fieldData}
                fieldValue={fieldValues[fieldData.name]}
                setField={(newVal) => setField(fieldData.name, newVal)}
                key={fieldData.name}
              />
            ),
          )}
        </AdvancedFieldsContainer>
      </ExpandableDetailsContainer>
    </>
  );
};

export default AlgorithmFields;
