/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Submodel: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M15.6838 3.31484C15.889 3.24641 16.111 3.24641 16.3162 3.31484L28.0662 7.2315C28.4746 7.36762 28.75 7.74976 28.75 8.18019V23.8198C28.75 24.2502 28.4746 24.6324 28.0662 24.7685L16.3162 28.6852C16.111 28.7536 15.889 28.7536 15.6838 28.6852L3.93377 24.7685C3.52543 24.6324 3.25 24.2502 3.25 23.8198V8.18019C3.25 7.74976 3.52543 7.36762 3.93377 7.2315L15.6838 3.31484ZM4.75 9.04057L15.25 12.5406V26.9594L4.75 23.4594V9.04057ZM16.75 26.9594L27.25 23.4594V9.04057L16.75 12.5406V26.9594ZM16 11.2094L25.6283 8L16 4.79057L6.37171 8L16 11.2094Z"
      fill={props.fill}
    />
  </svg>
);

Submodel.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Submodel;
