/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  className?: string;
}

const Offset: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5 9.25C4.58579 9.25 4.25 9.58579 4.25 10C4.25 10.4142 4.58579 10.75 5 10.75H27C27.4142 10.75 27.75 10.4142 27.75 10C27.75 9.58579 27.4142 9.25 27 9.25H5Z"
      fill={props.fill}
    />
    <path
      d="M16 11.4393L18.5303 13.9697C18.8232 14.2626 18.8232 14.7374 18.5303 15.0303C18.2374 15.3232 17.7626 15.3232 17.4697 15.0303L16.75 14.3107V20.5C16.75 20.9142 16.4142 21.25 16 21.25C15.5858 21.25 15.25 20.9142 15.25 20.5V14.3107L14.5303 15.0303C14.2374 15.3232 13.7626 15.3232 13.4697 15.0303C13.1768 14.7374 13.1768 14.2626 13.4697 13.9697L16 11.4393Z"
      fill={props.fill}
    />
    <path
      d="M4.75 22.5C4.75 22.3619 4.86193 22.25 5 22.25H27C27.1381 22.25 27.25 22.3619 27.25 22.5C27.25 22.6381 27.1381 22.75 27 22.75H5C4.86193 22.75 4.75 22.6381 4.75 22.5Z"
      fill={props.fill}
    />
  </svg>
);

Offset.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  className: '',
};

export default Offset;
