/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  className?: string;
}

const Rotational: React.FC<Props> = (props) => (
  <svg viewBox="0 0 8 8" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.57438 0.322866C5.86948 0.44921 5.91273 0.829882 5.68575 1.05686L5.26725 1.47536C5.1205 1.62212 4.89951 1.65822 4.70028 1.60008C4.47476 1.53427 4.23915 1.5 4 1.5C3.33696 1.5 2.70107 1.76339 2.23223 2.23223C1.86734 2.59712 1.6269 3.06319 1.53821 3.5645C1.513 3.70704 1.62863 3.83002 1.77339 3.83002H2.56643C2.76849 3.83002 2.87904 4.05165 2.78022 4.21037C2.77003 4.22675 2.75849 4.24226 2.74694 4.25777C2.73484 4.27403 2.72273 4.29029 2.71219 4.30755L1.17214 6.8275L1.17057 6.82801L1.16982 6.82716L1.16933 6.82618C0.420587 6.07625 0 5.05981 0 4C0 2.93913 0.421427 1.92172 1.17157 1.17157C1.92172 0.421428 2.93913 0 4 0C4.54653 0 5.08152 0.111847 5.57438 0.322866Z"
      fill={props.fill}
    />
    <path
      d="M2.42562 7.67713C2.13052 7.55079 2.08727 7.17012 2.31425 6.94313L2.73275 6.52463C2.8795 6.37788 3.10049 6.34178 3.29972 6.39992C3.52524 6.46573 3.76085 6.5 4 6.5C4.66304 6.5 5.29893 6.23661 5.76777 5.76777C6.13266 5.40288 6.3731 4.93681 6.46179 4.4355C6.487 4.29296 6.37137 4.16998 6.22661 4.16998H5.43357C5.23151 4.16998 5.12096 3.94835 5.21978 3.78963C5.22997 3.77325 5.24152 3.75774 5.25306 3.74223C5.26516 3.72597 5.27727 3.70971 5.28781 3.69245L6.82786 1.1725L6.82943 1.17199L6.83018 1.17284L6.83067 1.17382C7.57941 1.92375 8 2.94019 8 4C8 5.06087 7.57857 6.07828 6.82843 6.82843C6.07828 7.57857 5.06087 8 4 8C3.45347 8 2.91848 7.88815 2.42562 7.67713Z"
      fill={props.fill}
    />
  </svg>
);

Rotational.defaultProps = {
  width: '8',
  height: '8',
  viewBox: '0 0 8 8',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  className: '',
};

export default Rotational;
