import { GetProjectReadAllApiResponse } from 'app/apiGenerated/generatedApiTypes';
import {
  convertAPIProjectToProject,
  Project,
} from './convertAPIProjectToProject';

export function convertGetProjectReadAll(
  apiResponse: GetProjectReadAllApiResponse,
): Project[] {
  const projects = apiResponse.projects?.map(convertAPIProjectToProject);
  if (projects) {
    return [...projects].sort(
      (a, b) =>
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
    );
  }
  return [];
}
