/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Deadzone: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M30.0303 6.96967C30.3232 7.26256 30.3232 7.73744 30.0303 8.03033L21.6768 16.3839C21.4424 16.6183 21.1244 16.75 20.7929 16.75H11.3107L3.03033 25.0303C2.73744 25.3232 2.26256 25.3232 1.96967 25.0303C1.67678 24.7374 1.67678 24.2626 1.96967 23.9697L10.3232 15.6161C10.5576 15.3817 10.8756 15.25 11.2071 15.25H20.6893L28.9697 6.96967C29.2626 6.67678 29.7374 6.67678 30.0303 6.96967Z"
      fill={props.fill}
    />
  </svg>
);

Deadzone.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Deadzone;
