import { t } from '@lingui/macro';
import { useDeleteSubmodelMutation } from 'app/apiGenerated/generatedApi';
import { DeleteSubmodelApiArg } from 'app/apiGenerated/generatedApiTypes';
import { useAppDispatch } from 'app/hooks';
import { submodelsActions } from 'app/slices/submodelsSlice';
import React from 'react';
import { useModal } from 'ui/common/Modal/useModal';
import { useNotifications } from 'ui/common/notifications/useNotifications';
import SubmodelReferenceModal, {
  SubmodelReferenceItem,
} from 'ui/dashboard/projectDetail/SubmodelReferenceModal';

const REFERENCE_BY_OTHER_SUBMODELS_ERROR_MESSAGE =
  'submodel is referenced by other models';

function getIsReferencedByOtherSubmodelsError(error: any): boolean {
  if (error.data.message !== REFERENCE_BY_OTHER_SUBMODELS_ERROR_MESSAGE) {
    return false;
  }

  if (!error.data.extra_data?.references?.parents) {
    return false;
  }

  return true;
}

export function useDeleteSubmodel() {
  const dispatch = useAppDispatch();
  const { triggerModal } = useModal();

  const { showError, showCompletion } = useNotifications();

  const [callDeleteSubmodelApi] = useDeleteSubmodelMutation();

  const deleteSubmodel = React.useCallback(
    (request: DeleteSubmodelApiArg) =>
      callDeleteSubmodelApi(request)
        .unwrap()
        .then(() => {
          showCompletion(
            t({
              id: 'submodelsApi.deleteSubmodelSuccess',
              message: 'Submodel deleted',
            }),
          );
        })
        .catch((error) => {
          const isReferencedByOtherSubmodelsError =
            getIsReferencedByOtherSubmodelsError(error);

          showError(
            t({
              id: 'submodelsApi.deleteSubmodelError',
              message: 'Unable to delete submodel.',
            }),
            error,
            {
              isExpectedError: isReferencedByOtherSubmodelsError,
            },
          );

          if (isReferencedByOtherSubmodelsError) {
            // FIXME: This data used to be shoved into redux without type checking. Now it's at least a variable (but same problem).
            const references: SubmodelReferenceItem[] =
              error.data.extra_data?.references?.parents;

            triggerModal(
              <SubmodelReferenceModal references={references} />,
              t({
                id: 'submodelReferenceDialog.title',
                message: 'Where this submodel is used',
              }),
              { preventOverflow: true },
            );
          }
        })
        .finally(() => {
          // If there is a failure, make sure we clear out our optimistic updates
          // in the client state.
          dispatch(submodelsActions.requestSubmodels(request.projectUuid));
        }),
    [callDeleteSubmodelApi, showCompletion, showError, triggerModal, dispatch],
  );

  return {
    deleteSubmodel,
  };
}
