// !!!! -- DO NOT MANUALLY EDIT -- !!!!
// This file was generated by something in scripts/generateDefaultBlockData.js
// If you want to change the contents of this file, it should be automated
//
// (You can edit this file locally, but it may not be checked into the codebase -
// PRs with a manual edit of this file are forbidden from being merged.)

import { computationBlockDefaults } from 'app/generated_blocks/computationBlockDefaults';
import type { ComputationBlockClass } from 'app/generated_types/ComputationBlockClass';
import deepmerge from 'deepmerge';

export const Conditional: ComputationBlockClass = deepmerge(
  computationBlockDefaults,
  {
    "base": {
      "name": "Conditional",
      "namespace": "core",
      "description": "conditionally execute the model content inside.",
      "extra_parameters": true,
      "hidden": true,
      "feature_level": "dev"
    },
    "modes": {
      "time": "any"
    },
    "ports": {
      "inputs": {
        "static": [
          {
            "name": "enable",
            "appearance": "hollow"
          }
        ]
      },
      "has_automatic_ports": true
    },
    "parameter_definitions": [
      {
        "name": "output_on_disable",
        "data_type": "string",
        "default_value": "hold",
        "description": "how the outputs of the conditional block are computed when it is disabled",
        "allowed_values": [
          "hold",
          "reset to 0"
        ]
      },
      {
        "name": "state_on_disable",
        "data_type": "string",
        "default_value": "hold",
        "description": "how the states of the blocks inside the conditional block are computed when it is disabled",
        "allowed_values": [
          "hold",
          "reset"
        ]
      }
    ]
  }
);
