import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const VERSION_HISTORY_ITEM_BATCH_SIZE = 40;

interface VersionHistoryState {
  forceRefetchSnapshots: boolean;
  expandedAutoSaveVersionsToItemCount: Record<string, number>;
}

const initialState: VersionHistoryState = {
  forceRefetchSnapshots: false,
  expandedAutoSaveVersionsToItemCount: {},
};

const versionHistorySlice = createSlice({
  name: 'VersionHistorySlice',
  initialState,
  reducers: {
    resetVersionHistoryState: () => initialState,

    requestDiagramVersions(state) {
      state.forceRefetchSnapshots = true;
    },

    clearRequestDiagramVersions(state) {
      state.forceRefetchSnapshots = false;
    },

    setAutoSaveVersionExpanded(
      state,
      action: PayloadAction<{
        versionId: string;
      }>,
    ) {
      const { versionId } = action.payload;
      state.expandedAutoSaveVersionsToItemCount[versionId] =
        VERSION_HISTORY_ITEM_BATCH_SIZE;
    },

    setAutoSaveVersionCollapsed(
      state,
      action: PayloadAction<{
        versionId: string;
      }>,
    ) {
      const { versionId } = action.payload;
      delete state.expandedAutoSaveVersionsToItemCount[versionId];
    },

    showMoreAutoSaveVersions(
      state,
      action: PayloadAction<{
        versionId: string;
      }>,
    ) {
      const { versionId } = action.payload;
      state.expandedAutoSaveVersionsToItemCount[versionId] =
        (state.expandedAutoSaveVersionsToItemCount[versionId] || 0) +
        VERSION_HISTORY_ITEM_BATCH_SIZE;
    },
  },
});

export const versionHistoryActions = versionHistorySlice.actions;

export default versionHistorySlice;
