import { keyframes } from '@emotion/react';
import styled from '@emotion/styled/macro';
import { Loader } from './Icons/Standard';

const spin = keyframes`
from {
  transform: rotate(0deg);
}
to {
  transform: rotate(360deg);
}
`;

export const SpinnerWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Spinner = styled(Loader)`
  animation: ${spin} 1s infinite ease-in-out;
`;
