/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  className?: string;
}

const Inport: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.852 13.528C12.604 13.528 12.4 13.448 12.24 13.288C12.08 13.128 12 12.924 12 12.676C12 12.428 12.08 12.224 12.24 12.064C12.4 11.904 12.604 11.824 12.852 11.824C13.1 11.824 13.304 11.904 13.464 12.064C13.624 12.224 13.704 12.428 13.704 12.676C13.704 12.924 13.624 13.128 13.464 13.288C13.304 13.448 13.1 13.528 12.852 13.528ZM12.276 20.5C12.236 20.5 12.2 20.488 12.168 20.464C12.144 20.432 12.132 20.396 12.132 20.356V14.5C12.132 14.46 12.144 14.428 12.168 14.404C12.2 14.372 12.236 14.356 12.276 14.356H13.38C13.42 14.356 13.452 14.372 13.476 14.404C13.508 14.428 13.524 14.46 13.524 14.5V20.356C13.524 20.396 13.508 20.432 13.476 20.464C13.452 20.488 13.42 20.5 13.38 20.5H12.276Z"
      fill={props.fill}
    />
    <path
      d="M18.0586 14.272C18.7066 14.272 19.2226 14.464 19.6066 14.848C19.9986 15.232 20.1946 15.756 20.1946 16.42V20.356C20.1946 20.396 20.1786 20.432 20.1466 20.464C20.1226 20.488 20.0906 20.5 20.0506 20.5H18.9466C18.9066 20.5 18.8706 20.488 18.8386 20.464C18.8146 20.432 18.8026 20.396 18.8026 20.356V16.708C18.8026 16.34 18.6986 16.044 18.4906 15.82C18.2826 15.588 18.0066 15.472 17.6626 15.472C17.3186 15.472 17.0386 15.584 16.8226 15.808C16.6146 16.032 16.5106 16.328 16.5106 16.696V20.356C16.5106 20.396 16.4946 20.432 16.4626 20.464C16.4386 20.488 16.4066 20.5 16.3666 20.5H15.2626C15.2226 20.5 15.1866 20.488 15.1546 20.464C15.1306 20.432 15.1186 20.396 15.1186 20.356V14.512C15.1186 14.472 15.1306 14.44 15.1546 14.416C15.1866 14.384 15.2226 14.368 15.2626 14.368H16.3666C16.4066 14.368 16.4386 14.384 16.4626 14.416C16.4946 14.44 16.5106 14.472 16.5106 14.512V14.896C16.5106 14.92 16.5186 14.936 16.5346 14.944C16.5506 14.952 16.5626 14.944 16.5706 14.92C16.8986 14.488 17.3946 14.272 18.0586 14.272Z"
      fill={props.fill}
    />
  </svg>
);

Inport.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  className: '',
};

export default Inport;
