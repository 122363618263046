// !!!! -- DO NOT MANUALLY EDIT -- !!!!
// This file was generated by something in scripts/generateDefaultBlockData.js
// If you want to change the contents of this file, it should be automated
//
// (You can edit this file locally, but it may not be checked into the codebase -
// PRs with a manual edit of this file are forbidden from being merged.)

import { computationBlockDefaults } from 'app/generated_blocks/computationBlockDefaults';
import type { ComputationBlockClass } from 'app/generated_types/ComputationBlockClass';
import deepmerge from 'deepmerge';

export const AcausalThermalTemperatureSensor: ComputationBlockClass = deepmerge(
  computationBlockDefaults,
  {
    "base": {
      "name": "TemperatureSensor",
      "namespace": "acausal.thermal",
      "description": "Temperature sensor."
    },
    "modes": {
      "time": "agnostic"
    },
    "ports": {
      "inputs": {
        "static": [
          {
            "name": "port_a",
            "variant": {
              "variant_kind": "acausal",
              "acausal_domain": "thermal"
            }
          }
        ]
      },
      "outputs": {
        "static": [
          {
            "name": "T_rel"
          }
        ],
        "conditional": [
          {
            "name": "port_b",
            "condition_bool_parameter": "enable_port_b",
            "order": 0,
            "variant": {
              "variant_kind": "acausal",
              "acausal_domain": "thermal"
            }
          }
        ]
      }
    },
    "parameter_definitions": [
      {
        "name": "enable_port_b",
        "data_type": "bool",
        "default_value": "true",
        "description": "User selection for enabling the port_b port"
      }
    ]
  }
);
