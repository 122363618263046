import { t } from '@lingui/macro';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import BackArrow from 'ui/common/Icons/Standard/BackArrow';
import { useAppParams } from 'util/useAppParams';
import { NavbarButton } from './NavbarButtons';

const BackToCurrentButton: React.FC = () => {
  const navigate = useNavigate();

  const { projectId, modelId, versionId } = useAppParams();
  if (!versionId) return null;

  return (
    <NavbarButton
      tooltipText={t({
        id: 'navBar.returnToCurrentVersion.tooltip',
        message: 'Return to current version',
      })}
      Icon={BackArrow}
      isEnabled
      onClickHandler={() =>
        navigate(`/projects/${projectId}/models/${modelId}`)
      }
      testId="navbar-back-to-current-version-button"
    />
  );
};

export default BackToCurrentButton;
