import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { nodeTypeIsContainer } from 'app/helpers';
import React from 'react';
import Information from 'ui/common/Icons/Standard/Information';
import { blockClassLookup } from '../generated_blocks';
import { modelActions } from './modelSlice';

export interface ProgressBar {
  elapsed: number;
  total: number;
  startText: string;
  endText: string;
}

interface NotificationData {
  message: string;
  icon: React.FC<any> | undefined;
  canClose: boolean;
  sticky?: boolean;
}

interface NotificationsState {
  currentMessage: string | undefined;
  currentIcon: React.FC<any> | undefined;
  canClose: boolean;
  progressBar: ProgressBar | undefined;
  sticky?: boolean;
}

const initialState: NotificationsState = {
  currentMessage: undefined,
  currentIcon: undefined,
  canClose: false,
  progressBar: undefined,
  sticky: false,
};

const NotificationsSlice = createSlice({
  name: 'Notifications',
  initialState,
  reducers: {
    setCurrentMessage(
      state,
      action: PayloadAction<NotificationData | undefined>,
    ) {
      state.progressBar = undefined;
      if (action.payload) {
        const { message, icon, canClose, sticky } = action.payload;
        state.currentMessage = message;
        state.currentIcon = icon;
        state.canClose = canClose;
        state.sticky = sticky;
      } else {
        state.currentMessage = undefined;
        state.sticky = false;
      }
    },

    setProgressBar(state, action: PayloadAction<ProgressBar | undefined>) {
      state.progressBar = action.payload;
      state.currentMessage = undefined;
      state.currentIcon = undefined;
      state.canClose = false;
      state.sticky = !!state.progressBar;
    },

    clearProgressBar(state, action: PayloadAction<ProgressBar | undefined>) {
      state.progressBar = undefined;
      state.sticky = false;
    },
  },
  extraReducers(builder) {
    builder.addMatcher(
      modelActions.createSubdiagramFromSelection.match,
      (state, action) => {
        const { subdiagramType } = action.payload;

        if (!nodeTypeIsContainer(subdiagramType)) {
          return;
        }

        state.currentMessage = `${
          blockClassLookup(action.payload.subdiagramType).base.name
        } created`;
        state.currentIcon = Information;
        state.canClose = true;
      },
    );
  },
});

export const notificationsActions = NotificationsSlice.actions;

export default NotificationsSlice;
