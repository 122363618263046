import styled from '@emotion/styled/macro';
import { t } from '@lingui/macro';
import { useProjectActions } from 'app/api/useProjectActions';
import { Project } from 'app/apiTransformers/convertAPIProjectToProject';
import { getAdminAssumedUser } from 'app/config/adminAssumedUser';
import { useAppSelector } from 'app/hooks';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from 'ui/common/Button/Button';
import {
  ActionButtonGroup,
  ActionsSpacer,
} from 'ui/common/Button/actionButtonStyles';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import { Integrations, LogOut } from 'ui/common/Icons/Standard';
import Input from 'ui/common/Input/Input';
import {
  validNameRules,
  validNameRulesForOptionalField,
} from 'ui/common/Input/inputValidation';
import { useModal } from 'ui/common/Modal/useModal';
import { BadgeType, getBadgeColor, getBadgeText } from 'ui/common/badge/Badge';
import { H1, StandardHighlight } from 'ui/common/typography/Typography';
import LeaveProjectModal from 'ui/dashboard/projectsList/LeaveProjectModal';
import { useProjectPermission } from 'ui/permission/useProjectPermission';
import {
  getProjectFilter,
  getProjectFilterLabel,
  getProjectFilterPath,
} from 'util/projectFilterUtils';
import ArrowLeft from '../../common/Icons/Standard/ArrowLeft';
import CopyPublicProjectButton from '../../navbar/actionButtons/CopyPublicProjectButton';
import { FavoriteIcon } from '../projectsList/FavoriteIcon';
import { ProjectDetailHeaderMenuButton } from '../projectsList/ProjectMenuButton';
import { ProjectGitBranchCommands } from './gitProjects/ProjectGitBranchCommands';

export const ProjHeaderWrapper = styled.div`
  width: 100%;
  flex-shrink: 0;
  flex-grow: 0;
  margin-bottom: ${(props) => props.theme.spacing.xxlarge};
`;

export const ProjTitle = styled(Input)`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  & > input {
    font-size: ${({ theme }) => theme.typography.font.heading1.size};
    line-height: ${({ theme }) => theme.typography.font.heading1.lineHeight};
    letter-spacing: ${({ theme }) =>
      theme.typography.font.heading1.letterSpacing};
    font-weight: ${({ theme }) => theme.typography.font.heading1.weight};
    height: ${({ theme }) => theme.typography.font.heading1.lineHeight};
    padding-left: 0;
    color: ${({ theme }) => theme.colors.text.primary};
  }
  height: ${({ theme }) => theme.typography.font.heading1.lineHeight};
`;

const Description = styled(Input)`
  height: 34px;
  & > input {
    font-size: ${(props) => props.theme.typography.font.small.size};
    height: 34px;
  }
  margin-top: ${(props) => props.theme.spacing.normal};
`;

export const BackToProjectsButtonContainer = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.normal};
  & > button {
    padding-left: 0;
  }
`;

const ReadonlyLabelWrapper = styled.div<{ badgeType: BadgeType }>`
  background-color: ${({ theme, badgeType }) =>
    getBadgeColor(theme, badgeType)};
  padding-top: ${({ theme }) => theme.spacing.small};
  padding-left: ${({ theme }) => theme.spacing.normal};
  padding-right: ${({ theme }) => theme.spacing.normal};
  border-radius: 2px;
  margin-left: ${({ theme }) => theme.spacing.normal};
  height: 30px;
  margin-top: 3px;
`;

const ReadonlyLabel = styled(StandardHighlight)`
  color: white;
`;

export const ProjTitleSpacer = styled(H1)`
  visibility: hidden;
  white-space: nowrap;
`;

const ProjectMenuButtonContainer = styled.div`
  margin-right: ${({ theme }) => theme.spacing.normal};
`;

export const IconAndBadgeContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ProjTitleContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  max-width: 100%;
  flex-grow: 1;
`;

const ReadOnlyBadge = () => (
  <ReadonlyLabelWrapper badgeType={BadgeType.ReadOnly}>
    <ReadonlyLabel>{getBadgeText(BadgeType.ReadOnly)}</ReadonlyLabel>
  </ReadonlyLabelWrapper>
);

const PublicBadge = () => (
  <ReadonlyLabelWrapper badgeType={BadgeType.Public}>
    <ReadonlyLabel>{getBadgeText(BadgeType.Public)}</ReadonlyLabel>
  </ReadonlyLabelWrapper>
);

interface Props {
  project: Project;
  numSelected?: number;
}

export const ProjectDetailHeader: React.FC<Props> = ({ project }) => {
  const navigate = useNavigate();

  const { updateProject } = useProjectActions();
  const { triggerModal } = useModal();

  const { gitEnabled } = useAppSelector((state) => state.userOptions.options);

  const { canAdministrateProject, canEditProject } = useProjectPermission(
    project.uuid,
  );

  const userId = useAppSelector((state) => state.user.userId);
  const currentUserId = getAdminAssumedUser() ?? userId;
  const projectFilter =
    !!currentUserId && getProjectFilter(project, currentUserId);

  const projectHasGitIntegration = project.gitConnected ?? false;

  const showLeaveProject =
    !canAdministrateProject &&
    projectFilter !== 'public' &&
    project.ownerUuid !== userId;

  const renameTitle = (newTitle: string) => {
    updateProject({
      projectUuid: project.uuid,
      projectUpdateRequest: {
        title: newTitle,
        description: project.description,
      },
    });
  };
  const renameDescription = (newDescription: string) => {
    updateProject({
      projectUuid: project.uuid,
      projectUpdateRequest: {
        title: project.title,
        description: newDescription,
      },
    });
  };
  const triggerLeaveModal = () => {
    triggerModal(
      <LeaveProjectModal projectId={project.uuid} />,
      t({ id: 'leaveProjectModal.title', message: 'Leave Project?' }),
    );
  };

  return (
    <ProjHeaderWrapper>
      {projectFilter && (
        <BackToProjectsButtonContainer>
          <Button
            variant={ButtonVariants.SmallTertiary}
            Icon={ArrowLeft}
            onClick={() => navigate(getProjectFilterPath(projectFilter))}>
            {getProjectFilterLabel(projectFilter)}
          </Button>
        </BackToProjectsButtonContainer>
      )}
      <ActionButtonGroup>
        {projectFilter !== 'public' && (
          <IconAndBadgeContainer>
            <FavoriteIcon
              projectId={project.uuid}
              isFavorite={project.isFavorite}
            />
          </IconAndBadgeContainer>
        )}

        <ProjTitleContainer>
          <ProjTitleSpacer>{project.title}</ProjTitleSpacer>
          {project.isPublic ? (
            <PublicBadge />
          ) : !canEditProject ? (
            <ReadOnlyBadge />
          ) : null}
          <ProjTitle
            value={project.title}
            onSubmitValue={renameTitle}
            validationRules={validNameRules}
            disabled={!canEditProject}
          />
        </ProjTitleContainer>
        <ActionsSpacer />

        {gitEnabled && (
          <Button
            onClick={() => navigate(`/projects/${project.uuid}/integrations`)}
            Icon={Integrations}
            variant={ButtonVariants.LargeSecondary}>
            {t({
              id: 'dashboard.projectsDetailHeader.IntegrationsButton.Label',
              message: 'Integrations',
            })}
          </Button>
        )}

        {projectFilter === 'public' && (
          <CopyPublicProjectButton project={project} />
        )}

        {showLeaveProject && (
          <Button
            onClick={triggerLeaveModal}
            Icon={LogOut}
            testId="project-detail-leave-project-button"
            variant={ButtonVariants.LargeSecondary}>
            {t({
              id: 'dashboard.projectsDetailHeader.leaveProjectButton.Label',
              message: 'Leave Project',
            })}
          </Button>
        )}
        <ProjectMenuButtonContainer>
          <ProjectDetailHeaderMenuButton project={project} />
        </ProjectMenuButtonContainer>
      </ActionButtonGroup>
      <Description
        placeholder={
          canEditProject
            ? t({
                id: 'dashboard.projects.rightSidebar.descriptionPlaceholder',
                message: 'Insert project description',
              })
            : t({
                id: 'dashboard.projects.rightSidebar.noDescriptionPlaceholder',
                message: 'Project has no description',
              })
        }
        value={project.description}
        onSubmitValue={renameDescription}
        validationRules={validNameRulesForOptionalField}
        readonly={!canEditProject}
        hasBorder
        spellCheck
      />
      {projectHasGitIntegration && <ProjectGitBranchCommands />}
    </ProjHeaderWrapper>
  );
};
