/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Iterator: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.5 5.75C5.5 5.61193 5.61193 5.5 5.75 5.5H25.5C25.6381 5.5 25.75 5.61193 25.75 5.75V16.4091L23.5189 14.271C23.2199 13.9844 22.7451 13.9945 22.4585 14.2936C22.1719 14.5926 22.182 15.0674 22.4811 15.354L26.5 19.2055L30.5189 15.354C30.818 15.0674 30.8281 14.5926 30.5415 14.2936C30.2549 13.9945 29.7801 13.9844 29.4811 14.271L27.25 16.4091V5.75C27.25 4.7835 26.4665 4 25.5 4H5.75C4.7835 4 4 4.7835 4 5.75V26.25C4 27.2165 4.7835 28 5.75 28H13.75C14.1642 28 14.5 27.6642 14.5 27.25C14.5 26.8358 14.1642 26.5 13.75 26.5H5.75C5.61193 26.5 5.5 26.3881 5.5 26.25V5.75Z"
      fill={props.fill}
    />
    <path
      d="M17.25 26.5C16.8358 26.5 16.5 26.8358 16.5 27.25C16.5 27.6642 16.8358 28 17.25 28H19.75C20.1642 28 20.5 27.6642 20.5 27.25C20.5 26.8358 20.1642 26.5 19.75 26.5H17.25Z"
      fill={props.fill}
    />
    <path
      d="M23.25 26.5C22.8358 26.5 22.5 26.8358 22.5 27.25C22.5 27.6642 22.8358 28 23.25 28H25.75C26.1642 28 26.5 27.6642 26.5 27.25C26.5 26.8358 26.1642 26.5 25.75 26.5H23.25Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M9 8C8.44772 8 8 8.44772 8 9V15C8 15.5523 8.44772 16 9 16H11.75V21.5C11.75 21.9142 12.0858 22.25 12.5 22.25H16V23C16 23.5523 16.4477 24 17 24H23C23.5523 24 24 23.5523 24 23V20C24 19.4477 23.5523 19 23 19H17C16.4477 19 16 19.4477 16 20V20.75H13.25V16H16C16.5523 16 17 15.5523 17 15V9C17 8.44772 16.5523 8 16 8H9ZM9.5 9.5V14.5H15.5V9.5H9.5ZM22.5 20.5H17.5V22.5H22.5V20.5Z"
      fill={props.fill}
    />
  </svg>
);

Iterator.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Iterator;
