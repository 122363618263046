import styled from '@emotion/styled/macro';
import React from 'react';
import { TooltipPlacement } from 'ui/common/Tooltip/tooltipTypes';
import PortalTooltip from './PortalTooltip';

interface Props {
  triggerEl: HTMLElement;
  contentText: string;
  placement: TooltipPlacement;
  invertedColor?: boolean;
}

export const SimpleTextTooltip = styled.div<{
  invertedColor: boolean;
}>`
  padding: ${(props) => props.theme.spacing.normal};
  border-radius: 2px;
  font-weight: 600;
  min-height: 32px;
  ${({ theme, invertedColor }) => {
    if (invertedColor) {
      return `
        background-color: ${theme.colors.grey[85]};
        color: ${theme.colors.grey[5]};
        `;
    }
    return `
      background-color: ${theme.colors.grey[2]};
      `;
  }}
`;

const PortalTextTooltip: React.FC<Props> = ({
  contentText,
  triggerEl,
  placement,
  invertedColor = true,
}) => (
  <PortalTooltip
    triggerEl={triggerEl}
    placement={placement}
    invertedColor={invertedColor}>
    <SimpleTextTooltip invertedColor={invertedColor}>
      {contentText}
    </SimpleTextTooltip>
  </PortalTooltip>
);

export default PortalTextTooltip;
