import { t } from '@lingui/macro';
import { PortSide } from 'app/common_types/PortTypes';
import { blockClassLookup } from 'app/generated_blocks/';
import { NodeInstance } from 'app/generated_types/SimulationModel';
import { useAppDispatch } from 'app/hooks';
import { modelActions } from 'app/slices/modelSlice';
import { getPortDisplayName } from 'app/utils/portConditionUtils';
import { canRenamePort } from 'app/utils/portOperationUtils';
import React from 'react';
import SectionHeading from 'ui/common/Inputs/SectionHeading';
import { DetailsInput, DetailsSection } from 'ui/modelEditor/DetailsComponents';
import { Port } from '@collimator/model-schemas-ts';
import { isValidBlockPortNameRuleSet } from 'ui/common/Input/inputValidationForModels';
import BlockPortParameters from './BlockPortParameter';
import { PortList, PortRow } from './BlockOutportDetails';

type Props = {
  parentPath: string[];
  selectedNode: NodeInstance;
  canEdit: boolean;
};

export const BlockAcausalPortDetails: React.FC<Props> = ({
  parentPath,
  selectedNode,
  canEdit,
}) => {
  const dispatch = useAppDispatch();

  const blockClass = blockClassLookup(selectedNode.type);

  const renamePort = (
    inputId: number,
    newPortName: string,
    portSide: PortSide,
  ) => {
    dispatch(
      modelActions.renamePort({
        parentPath,
        nodeUuid: selectedNode.uuid,
        portSide,
        portId: inputId,
        newPortName,
      }),
    );
  };

  const allAcausalPorts: Array<{
    port: Port;
    portIndex: number;
    portSide: PortSide;
  }> = React.useMemo(() => {
    const ports = [];

    for (let i = 0; i < selectedNode.inputs.length; i++) {
      let portIndex = i;

      if (selectedNode.inputs[portIndex]?.variant?.variant_kind === 'acausal') {
        ports.push({
          port: selectedNode.inputs[portIndex],
          portIndex,
          portSide: PortSide.Input,
        });
      }
    }

    for (let i = 0; i < selectedNode.outputs.length; i++) {
      let portIndex = i;

      if (
        selectedNode.outputs[portIndex]?.variant?.variant_kind === 'acausal'
      ) {
        ports.push({
          port: selectedNode.outputs[portIndex],
          portIndex,
          portSide: PortSide.Output,
        });
      }
    }

    return ports;
  }, [selectedNode.inputs, selectedNode.outputs]);

  if (allAcausalPorts.length === 0) return null;

  return (
    <>
      <SectionHeading>
        {t({
          id: 'blockDetails.AcausalPortsTitle',
          message: 'Acausal Ports',
        })}
      </SectionHeading>
      <PortList>
        {allAcausalPorts.map(({ portIndex, port, portSide }) => (
          <PortRow key={port.name || `row-input[${portIndex}]`}>
            <DetailsSection>
              <DetailsInput
                grow
                testId={`block-input-name-${portIndex}`}
                value={getPortDisplayName(selectedNode.type, port.name)}
                placeholder={`Unnamed input ${portIndex}`}
                onSubmitValue={(newName) => {
                  renamePort(portIndex, newName, portSide);
                }}
                disabled={!canEdit || !canRenamePort(selectedNode)}
                validationRules={isValidBlockPortNameRuleSet(selectedNode, {
                  id: portIndex,
                  side: portSide,
                })}
              />
            </DetailsSection>
            <BlockPortParameters
              parentPath={parentPath}
              selectedNode={selectedNode}
              canEdit={canEdit}
              port={port}
              index={portIndex}
              paramDefinitions={
                blockClass.ports.inputs?.dynamic?.parameter_definitions
              }
              portSide={PortSide.Input}
            />
          </PortRow>
        ))}
      </PortList>
    </>
  );
};
