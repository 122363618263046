import { useAppDispatch, useAppSelector } from 'app/hooks';
import { navigationActions } from 'app/slices/navigationSlice';
import { setNavigationURLParams } from 'app/utils/URLParamsUtils';
import { isSamePath } from 'app/utils/parentPathUtils';
import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { rendererState } from 'ui/modelRendererInternals/modelRenderer';
import { centerCameraAroundNode } from 'ui/modelRendererInternals/shortcutKeyConfig';
import { STATE_MACHINE_EDITOR_BLOCK_QUERY_PARAM } from 'state_machine_tempdir/StateMachineEditor';

// TODO: turn this into a hook.
// User action is the trigger,
//  but since there's a potential document load it makes sense to be a side effect.
export const NodeNavigator: React.FC = () => {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const stateMachineEditorOpen = !!searchParams.get(
    STATE_MACHINE_EDITOR_BLOCK_QUERY_PARAM,
  );

  const nodeToFocusOn = useAppSelector(
    (state) => state.navigation.nodeToFocusOn,
  );
  const currentParentPath = useAppSelector(
    (state) => state.model.present.currentSubmodelPath,
  );
  const currentDiagram = useAppSelector(
    (state) => state.modelMetadata.currentDiagram,
  );

  React.useEffect(() => {
    if (!currentDiagram || !nodeToFocusOn) {
      return;
    }

    const { parentPath, nodeId } = nodeToFocusOn;

    if (isSamePath(currentParentPath, parentPath) && !stateMachineEditorOpen) {
      // TODO -- this doesn't work if we are not in the current diagram
      // Current workaround is to use a timeout below or
      // manually press pan twice to actually pan to the model.

      // If we are in the current diagram, pan to the block to focus on it.
      let tryCount = 0;
      const tryCenteringCamera = () => {
        // we take a "retry if there's no canvas or renderer approach"
        // because sometimes we want to target-navigate to a node,
        // but the model editor is not mounted yet.
        // this makes sure we have everything we need to center on the node
        // before actually trying to run the camera-centering code.
        if (rendererState && rendererState.refs.current.canvas?.clientWidth) {
          const node = currentDiagram.nodes.find(
            (node) => node.uuid === nodeId,
          );
          if (node) {
            centerCameraAroundNode(rendererState, node);
            dispatch(navigationActions.clearRequestToFocusOnNode());
            dispatch(navigationActions.setRecentlyFocused(true));
          }
        } else if (tryCount < 20) {
          setTimeout(tryCenteringCamera, 50);
        } else if (tryCount >= 20) {
          // bailout case
          dispatch(navigationActions.clearRequestToFocusOnNode());
        }

        tryCount++;
      };
      setTimeout(tryCenteringCamera, 50);
    } else {
      // If we aren't in the diagram containing this node, switch to the
      // matching diagram before updating the camera.
      const newSearchParams = new URLSearchParams();
      setNavigationURLParams(newSearchParams, { parentPath });
      setSearchParams(newSearchParams);
    }
  }, [
    dispatch,
    nodeToFocusOn,
    currentParentPath,
    currentDiagram,
    searchParams,
    setSearchParams,
    stateMachineEditorOpen,
  ]);

  return null;
};
