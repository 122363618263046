import { t } from '@lingui/macro';
import { NodeInstance } from 'app/generated_types/SimulationModel';
import React from 'react';
import SectionHeading from 'ui/common/Inputs/SectionHeading';
import { CommonBlockParametersDetails } from './CommonBlockParameterDetails';

interface Props {
  parentPath: string[];
  selectedNode: NodeInstance;
  canEdit: boolean;
}

export const BlockParametersDetails: React.FC<Props> = ({
  parentPath,
  selectedNode,
  canEdit,
}) => {
  if (!selectedNode) return null;

  return (
    <>
      <SectionHeading testId="parameter-definition-details">
        {t({
          id: 'blockDetails.parametersTitle',
          message: 'Parameters',
        })}
      </SectionHeading>
      <CommonBlockParametersDetails
        parentPath={parentPath}
        selectedNode={selectedNode}
        canEdit={canEdit}
      />
    </>
  );
};
