import { DiagramVersionFull } from 'app/apiTransformers/convertGetSnapshotReadByUuid';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { modelActions } from 'app/slices/modelSlice';
import { AppDispatch } from 'app/store';
import React from 'react';
import { TraceMetadata } from 'ui/dataExplorer/dataExplorerTypes';
import { MODEL_EDITOR_VISUALIZER_EXPLORATION_ID } from 'ui/userPreferences/visualizerPrefs';
import { buildSignalInfoForCurrentModel } from 'util/modelVersionSignal';

// TODO move this logic into extraReducers. Make the toggles events that both slices can listen to as opposed to functions specific to the slices.
function updateModelRecordForTrace(
  dispatch: AppDispatch,
  signalPath: string,
  modelIdToVersionIdToModelData: Record<
    string,
    Record<string, DiagramVersionFull>
  >,
  shouldRecord?: boolean,
) {
  const { parentPath, node, portIndex } = buildSignalInfoForCurrentModel(
    signalPath,
    modelIdToVersionIdToModelData,
  );

  if (
    parentPath &&
    node &&
    portIndex !== undefined &&
    shouldRecord !== undefined
  ) {
    dispatch(
      modelActions.setOutputPortRecord({
        parentPath,
        nodeUuid: node.uuid,
        portId: portIndex,
        record: shouldRecord,
      }),
    );
  }
}

/**
 * Record mode is implicitly turned on with the visualizer toggles.
 */
export const VisualizationTraceRecordTracker = () => {
  const dispatch = useAppDispatch();

  const lastIdToStateRef = React.useRef<Record<string, TraceMetadata> | null>(
    null,
  );

  const recordAllOutputs = useAppSelector(
    (state) => state.model.present.configuration.record_mode === 'all',
  );

  const modelIdToVersionIdToModelData = useAppSelector(
    (state) => state.modelVersions.modelIdToVersionIdToModelData,
  );

  const idToTrace = useAppSelector((state) => state.dataExplorer.idToTrace);
  const explorationId = useAppSelector(
    (state) => state.dataExplorer.explorationId,
  );

  // FIXME: should dispatch actions at the toggles instead of this useEffect.
  // Would greatly simplify the logic by removing the need for diffing two states.
  // When a trace is toggled on or off, update its record state in the model.
  React.useEffect(() => {
    if (
      !idToTrace ||
      explorationId !== MODEL_EDITOR_VISUALIZER_EXPLORATION_ID
    ) {
      lastIdToStateRef.current = null;
      return;
    }

    if (!lastIdToStateRef.current) {
      lastIdToStateRef.current = { ...idToTrace };
      return;
    }

    const signalPathsToStartRecording: Record<string, boolean> = {};
    Object.values(idToTrace).forEach((trace) => {
      if (!lastIdToStateRef.current) {
        return;
      }

      if (!lastIdToStateRef.current[trace.id]) {
        signalPathsToStartRecording[trace.signalPath] = true;
      }
    });

    const signalPathsToStopRecording: Record<string, boolean> = {};
    Object.values(lastIdToStateRef.current).forEach((trace) => {
      if (!idToTrace[trace.id]) {
        signalPathsToStopRecording[trace.signalPath] = true;
      }
    });

    // Treat visualization and record mode toggle as one when explicitly recording all.
    // FIXME: clicking on the visualizer squiggly causes model edits.
    Object.keys(signalPathsToStopRecording).forEach((signalPath) => {
      if (!signalPathsToStartRecording[signalPath] && recordAllOutputs) {
        updateModelRecordForTrace(
          dispatch,
          signalPath,
          modelIdToVersionIdToModelData,
          false,
        );
      }
    });

    // Always add traces that are newly visualized even if some of its instances were removed.
    // FIXME: clicking on the visualizer squiggly causes model edits.
    Object.keys(signalPathsToStartRecording).forEach((signalPath) => {
      updateModelRecordForTrace(
        dispatch,
        signalPath,
        modelIdToVersionIdToModelData,
        true,
      );
    });

    lastIdToStateRef.current = { ...idToTrace };
  }, [
    dispatch,
    idToTrace,
    explorationId,
    modelIdToVersionIdToModelData,
    recordAllOutputs,
  ]);

  return null;
};
