/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  className?: string;
}

const ComparatorLte: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.08391 20.4C9.00391 20.4 8.95191 20.36 8.92791 20.28L8.53191 18.996C8.51591 18.964 8.4959 18.948 8.4719 18.948H5.3039C5.2799 18.948 5.2599 18.964 5.2439 18.996L4.8479 20.28C4.8239 20.36 4.7719 20.4 4.6919 20.4H3.4919C3.4439 20.4 3.4079 20.388 3.3839 20.364C3.3599 20.332 3.3559 20.288 3.3719 20.232L5.9759 12.12C5.9999 12.04 6.0519 12 6.1319 12H7.6319C7.7119 12 7.7639 12.04 7.7879 12.12L10.4039 20.232C10.4119 20.248 10.4159 20.268 10.4159 20.292C10.4159 20.364 10.3719 20.4 10.2839 20.4H9.08391ZM5.6159 17.784C5.6079 17.832 5.6239 17.856 5.6639 17.856H8.09991C8.14791 17.856 8.16391 17.832 8.1479 17.784L6.9119 13.764C6.9039 13.732 6.8919 13.716 6.8759 13.716C6.8599 13.716 6.8479 13.732 6.8399 13.764L5.6159 17.784Z"
      fill={props.fill}
    />
    <path
      d="M18.6795 18.552C18.6795 18.608 18.6635 18.648 18.6315 18.672C18.5995 18.688 18.5595 18.684 18.5115 18.66L13.6755 16.476C13.6035 16.436 13.5675 16.38 13.5675 16.308V15.336C13.5675 15.256 13.6035 15.2 13.6755 15.168L18.5115 12.984C18.5435 12.968 18.5675 12.96 18.5835 12.96C18.6475 12.96 18.6795 13.004 18.6795 13.092V14.124C18.6795 14.196 18.6435 14.252 18.5715 14.292L15.0795 15.78C15.0315 15.804 15.0315 15.828 15.0795 15.852L18.5715 17.34C18.6435 17.372 18.6795 17.428 18.6795 17.508V18.552ZM18.6795 20.256C18.6795 20.296 18.6635 20.332 18.6315 20.364C18.6075 20.388 18.5755 20.4 18.5355 20.4H13.7115C13.6715 20.4 13.6355 20.388 13.6035 20.364C13.5795 20.332 13.5675 20.296 13.5675 20.256V19.356C13.5675 19.316 13.5795 19.284 13.6035 19.26C13.6355 19.228 13.6715 19.212 13.7115 19.212H18.5355C18.5755 19.212 18.6075 19.228 18.6315 19.26C18.6635 19.284 18.6795 19.316 18.6795 19.356V20.256Z"
      fill={props.fill}
    />
    <path
      d="M27.0484 16.02C27.0004 16.044 27.0004 16.068 27.0484 16.092C27.8644 16.476 28.2724 17.136 28.2724 18.072C28.2724 18.832 28.0164 19.412 27.5044 19.812C27.0004 20.204 26.3404 20.4 25.5244 20.4H22.5004C22.4604 20.4 22.4244 20.388 22.3924 20.364C22.3684 20.332 22.3564 20.296 22.3564 20.256V12.144C22.3564 12.104 22.3684 12.072 22.3924 12.048C22.4244 12.016 22.4604 12 22.5004 12H25.4284C26.3004 12 26.9724 12.188 27.4444 12.564C27.9164 12.94 28.1524 13.508 28.1524 14.268C28.1524 15.108 27.7844 15.692 27.0484 16.02ZM23.8084 13.2C23.7684 13.2 23.7484 13.22 23.7484 13.26V15.48C23.7484 15.52 23.7684 15.54 23.8084 15.54H25.4284C25.8524 15.54 26.1844 15.44 26.4244 15.24C26.6724 15.032 26.7964 14.748 26.7964 14.388C26.7964 14.02 26.6724 13.732 26.4244 13.524C26.1844 13.308 25.8524 13.2 25.4284 13.2H23.8084ZM25.5244 19.188C25.9404 19.188 26.2684 19.076 26.5084 18.852C26.7564 18.628 26.8804 18.324 26.8804 17.94C26.8804 17.548 26.7564 17.244 26.5084 17.028C26.2604 16.804 25.9244 16.692 25.5004 16.692H23.8084C23.7684 16.692 23.7484 16.712 23.7484 16.752V19.128C23.7484 19.168 23.7684 19.188 23.8084 19.188H25.5244Z"
      fill={props.fill}
    />
  </svg>
);

ComparatorLte.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  className: '',
};

export default ComparatorLte;
