import React from 'react';

export function usePortalPopup<TElement extends HTMLElement>() {
  const triggerElRef = React.useRef<TElement>(null);

  const [isPopupOpen, setIsPopupOpen] = React.useState<boolean>(false);

  return {
    triggerElRef,
    isPopupOpen,
    setIsPopupOpen,
  };
}
