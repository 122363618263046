import { JobSummary } from 'app/apiGenerated/generatedApiTypes';
import { useGetSimulationLogsReadByUuidQuery } from 'app/enhancedApi';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
  errorsActions,
  parseAllWildcatErrorsFromLogs,
} from 'app/slices/errorsSlice';
import { SimulationResultType, projectActions } from 'app/slices/projectSlice';
import { ModelLogLine, simResultsActions } from 'app/slices/simResultsSlice';
import { DiagramFooterTab, uiFlagsActions } from 'app/slices/uiFlagsSlice';
import React from 'react';
import { parseSimulationLogs } from 'ui/modelEditor/utils';
import { useAppParams } from 'util/useAppParams';

export interface SimulationResult {
  modelId?: string;
  correlationId?: string;
  summary: JobSummary;
  logs?: ModelLogLine[];
}

export function useModelSimulationResults() {
  const dispatch = useAppDispatch();

  const { modelId } = useAppParams() || '';

  const correlationId = useAppSelector((state) => state.project.correlationId);

  const { simulationSummary, simulationSummaryType } = useAppSelector(
    (state) => state.project,
  );

  const hasVisualizedSignals = useAppSelector(
    (state) => state.dataExplorer.cellRowIds.length > 0,
  );
  const autoSwitchBottomTabOnSimDone = useAppSelector(
    (state) => state.project.autoSwitchBottomTabOnSimDone,
  );

  const forceOpenOutputTabOnSimComplete = useAppSelector(
    (state) => state.uiFlags.forceOpenOutputTabOnSimComplete,
  );

  const isSimulationDone =
    simulationSummary?.status === 'completed' ||
    simulationSummary?.status === 'cancelled' ||
    simulationSummary?.status === 'failed' ||
    false;

  const { data: simulationLogs, isFetching: isFetchingLogs } =
    useGetSimulationLogsReadByUuidQuery(
      {
        modelUuid: modelId || '',
        simulationUuid: simulationSummary?.uuid || '',
      },
      {
        skip: !simulationSummary || !modelId || !isSimulationDone,
      },
    );

  React.useEffect(() => {
    if (simulationSummary && isSimulationDone && !isFetchingLogs) {
      const result: SimulationResult = {
        modelId,
        correlationId,
        logs: parseSimulationLogs(simulationLogs ?? ''),
        summary: simulationSummary,
      };
      dispatch(simResultsActions.setSimulationResult(result));
      dispatch(projectActions.checkOrRunFinished(result.summary));

      const logs = result.logs || result.summary.compilation_logs;
      // TODO: implement a cleaner way to add the 'Jump To' button without
      // abusing error logs.
      const wildcatErrors = parseAllWildcatErrorsFromLogs(logs || []).filter(
        (e) => e.kind !== 'NotAnError',
      );
      dispatch(errorsActions.setWildcatErrors(wildcatErrors));

      if (!autoSwitchBottomTabOnSimDone) {
        return;
      }

      if (wildcatErrors.length > 0) {
        // If there are errors, switch to the console if we aren't already there
        // in order to see the error details.
        dispatch(uiFlagsActions.setDiagramFooterTab(DiagramFooterTab.Output));
      } else if (
        simulationSummaryType === SimulationResultType.CompileAndRun &&
        (hasVisualizedSignals || forceOpenOutputTabOnSimComplete)
      ) {
        // If the simulation is successful, switch to the visualizer if we
        // aren't already there in order to see the results.
        if (forceOpenOutputTabOnSimComplete) {
          // sometimes we want to force the output tab open
          // (currently only used for ensemle sims completion)
          dispatch(uiFlagsActions.setDiagramFooterTab(DiagramFooterTab.Output));
        } else {
          dispatch(
            uiFlagsActions.setDiagramFooterTab(DiagramFooterTab.Visualizer),
          );
        }
      }
    }
  }, [
    autoSwitchBottomTabOnSimDone,
    correlationId,
    dispatch,
    hasVisualizedSignals,
    isSimulationDone,
    modelId,
    simulationLogs,
    simulationSummary,
    simulationSummaryType,
    forceOpenOutputTabOnSimComplete,
    isFetchingLogs,
  ]);
}
