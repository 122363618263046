import { useModelVersionData } from 'app/api/useModelVersionData';
import { ModelKind } from 'app/apiGenerated/generatedApiTypes';
import React from 'react';

interface Props {
  modelId: string;
  versionId: string;
  kind: ModelKind;
}

export const ModelItemLoader: React.FC<Props> = ({
  modelId,
  versionId,
  kind,
}) => {
  useModelVersionData(modelId, versionId, kind, {});

  return null;
};
