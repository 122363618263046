import { useAppSelector } from 'app/hooks';
import React from 'react';
import { NestedSubmodelItemLoader } from 'ui/modelEditor/NestedSubmodelItemLoader';
import { NodeLocation } from 'ui/modelEditor/portPathNameUtils';
import { useAppParams } from 'util/useAppParams';

export const NestedSubmodelLoader: React.FC = () => {
  const { projectId } = useAppParams();

  const currentSubmodelPath = useAppSelector(
    (state) => state.model.present?.currentSubmodelPath,
  );

  const [nodeLocations, setNodeLocations] = React.useState<NodeLocation[]>([]);

  React.useEffect(() => {
    let updatedNodeLocations: NodeLocation[] = [];
    let currentParentPath: string[] = [];
    currentSubmodelPath.forEach((nodeId) => {
      updatedNodeLocations.push({
        parentPath: currentParentPath,
        nodeId,
      });
      currentParentPath = [...currentParentPath, nodeId];
    });

    setNodeLocations(updatedNodeLocations);
  }, [currentSubmodelPath]);

  if (!projectId) {
    return null;
  }

  return (
    <>
      {nodeLocations.map((nodeLocation) => (
        <NestedSubmodelItemLoader
          key={nodeLocation.nodeId}
          projectId={projectId}
          nodeId={nodeLocation.nodeId}
          parentPath={nodeLocation.parentPath}
        />
      ))}
    </>
  );
};
