import React from 'react';
import { useNotifications } from 'ui/common/notifications/useNotifications';
import {
  PythonModel,
  usePythonExecutor,
  usePythonModelCallback,
} from './PythonHooks';
import { EditorMode, useModelEditorInfo } from './useModelEditorInfo';

export const withModelBuilder = (code: string, modelCode: string) => `
from model_builder import GLOBAL_MODEL_BUILDER
from model_builder.library import core, acausal
from model_builder.library.core import *
from model_builder.model import ModelBuilder, OPort, IPort

GLOBAL_MODEL_BUILDER.clear()
model_builder = GLOBAL_MODEL_BUILDER

def add_block(*args, **kwargs):
    return model_builder.add_block(*args, **kwargs)

def remove_block(*args, **kwargs):
    return model_builder.remove_block(*args, **kwargs)

def add_link(*args, **kwargs):
    return model_builder.add_link(*args, **kwargs)

def remove_link(*args, **kwargs):
    return model_builder.remove_link(*args, **kwargs)

def add_parameter(*args, **kwargs):
    return model_builder.add_parameter(*args, **kwargs)

def update_parameter(*args, **kwargs):
    return model_builder.update_parameter(*args, **kwargs)

def group(*args, **kwargs):
    return model_builder.group(*args, **kwargs)

def add_group(*args, **kwargs):
    return model_builder.add_group(*args, **kwargs)

core.add_link = add_link

# HACK to make model params available as global variables
for param_name in model_builder.parameters:
    globals()[param_name] = param_name

# HACK to enable chatgpt to use lower case true and false
true = True
false = False

${modelCode}
${code}
`;

const convertToJsonCode = `
import json
from model_builder import to_json

json_model = None

model_builder.set_names_from_locals(locals())
model_builder.print_warnings()

ids_to_uuids = None
ids_to_uiprops = None
if inputs["ids_to_uuids"]:
    ids_to_uuids = inputs["ids_to_uuids"]
if inputs["ids_to_uiprops"]:
    ids_to_uiprops = inputs["ids_to_uiprops"]
json_obj = to_json.render_model(
    model_builder,
    ids_to_uuids=ids_to_uuids,
    # uiprops=ids_to_uiprops,
    is_submodel=inputs["is_submodel"])
json_model = json.dumps(json_obj)
`;

export const usePythonToJsonConverter = () => {
  const { getCurrentModelInPython } = usePythonModelCallback();

  const { showError } = useNotifications();
  const executePython = usePythonExecutor();

  const { isModelReadOnly } = useModelEditorInfo();

  const convertPythonToJson = React.useCallback(
    async (modelCode: string, isNewModel: boolean, editorMode: EditorMode) => {
      if (isModelReadOnly) {
        throw new Error(
          'The editor is in read-only mode. Switch to edit mode to update the model.',
        );
      }
      if (!modelCode) {
        throw new Error('code is empty');
      }

      let pyModel: PythonModel | undefined;
      let existingModel = '';

      if (!isNewModel) {
        try {
          pyModel = await getCurrentModelInPython({
            output_groups: true,
            output_submodels: true,
          });
        } catch (e) {
          console.error(e);
          showError(`${e}`);
          throw new Error('Internal error: could not retrieve current model.');
        }

        if (!pyModel?.pythonStr) {
          showError(`Current model is empty.`);
          throw new Error('Internal error: could not retrieve current model.');
        }
        existingModel = pyModel.pythonStr;
      }

      const {
        results,
        stdout,
        stderr,
        error: pyerror,
      } = await executePython({
        code: withModelBuilder(
          convertToJsonCode,
          `${existingModel}\n${modelCode}`,
        ),
        inputs: {
          ids_to_uuids: pyModel?.ids_to_uuids,
          ids_to_uiprops: pyModel?.ids_to_uiprops,
          is_submodel: editorMode === EditorMode.Submodel,
        },
        returnVariableNames: ['json_model'],
      });
      if (stdout && process.env.NODE_ENV === 'development') {
        // eslint-disable-next-line no-console
        console.debug(stdout);
      }

      if (pyerror) {
        throw new Error(`${pyerror}`);
      }
      if (results) {
        const resultsMap = results as Map<string, unknown>;
        return {
          jsonModel: resultsMap.get('json_model') as string,
          stdout,
          stderr,
        };
      }
      throw new Error('Unexpected error: result could not be found.');
    },
    [executePython, getCurrentModelInPython, isModelReadOnly, showError],
  );

  return convertPythonToJson;
};
