/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const DropdownDefault: React.FC<Props> = (props) => (
  <svg viewBox="0 0 16 16" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M3.64645 6.64645C3.84171 6.45118 4.15829 6.45118 4.35355 6.64645L8 10.2929L11.6464 6.64645C11.8417 6.45118 12.1583 6.45118 12.3536 6.64645C12.5488 6.84171 12.5488 7.15829 12.3536 7.35355L8 11.7071L3.64645 7.35355C3.45118 7.15829 3.45118 6.84171 3.64645 6.64645Z"
      fill={props.fill}
    />
  </svg>
);

DropdownDefault.defaultProps = {
  width: '16',
  height: '16',
  viewBox: '0 0 16 16',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default DropdownDefault;
