import React from 'react';
import { Checkbox } from './Checkbox';

type Props = {
  isDisabled?: boolean;
  value?: string;
  onChange?: (b: string) => void;
};

export const CheckboxStringValue: React.FC<Props> = ({
  isDisabled,
  value,
  onChange,
}: Props): React.ReactElement => {
  const changeHandler = React.useCallback(
    (v: boolean) => {
      if (onChange) onChange(v ? 'true' : 'false');
    },
    [onChange],
  );

  return (
    <Checkbox
      isDisabled={isDisabled}
      value={value === 'true'}
      onChange={changeHandler}
    />
  );
};
