import styled from '@emotion/styled';
import { t } from '@lingui/macro';
import { useUserCredentials } from 'app/api/useUserCredentials';
import { UserCredentialsRequest } from 'app/apiGenerated/generatedApiTypes';
import {
  useDeleteUserCredentialsMutation,
  usePostUserCredentialsMutation,
} from 'app/enhancedApi';
import React, { useState } from 'react';
import Button from 'ui/common/Button/Button';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import { Remove } from 'ui/common/Icons/Standard';
import Input from 'ui/common/Input/Input';
import { isRequiredRule } from 'ui/common/Input/inputValidation';
import { useNotifications } from 'ui/common/notifications/useNotifications';
import { Standard } from 'ui/common/typography/Typography';

const CENSORED_TOKEN = 'sk-*****************************';

const Wrapper = styled.div`
  word-break: break-word;
  width: 100%;
  min-width: 610px;
  max-width: 610px;
  min-height: 200px;

  display: flex;
  flex-direction: column;
`;

const LinkToOpenAi = styled.div`
  margin-top: ${({ theme }) => theme.spacing.large};
  margin-bottom: ${({ theme }) => theme.spacing.normal};
  font-size: ${({ theme }) => theme.typography.font.small.size};
  font-family: ${({ theme }) => theme.typography.font.small.fontFamily};
  color: ${({ theme }) => theme.colors.text.secondary};

  > a {
    color: inherit;
    text-decoration: underline;
  }
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const SecretInput = styled(Input)<{ grow?: boolean }>`
  margin-right: 8px;
  width: 100%;

  ${(props) => props.grow && 'flex-grow: 1;'}
`;

export const ApiKeysOpenAiModalContents: React.FC = () => {
  const { userCredentials, isLoadingCredentials } = useUserCredentials();
  const [deleteUserCredentials] = useDeleteUserCredentialsMutation();
  const [callAddCredsApi] = usePostUserCredentialsMutation();
  const [editable, setEditable] = useState(true);
  const { showInfo, showError } = useNotifications();
  const [secret, setSecret] = useState('');

  const existingSecretItem = React.useMemo(
    () =>
      userCredentials?.items?.find((cred) => cred.kind === 'openai_api_key'),
    [userCredentials],
  );

  React.useEffect(() => {
    if (existingSecretItem) {
      setSecret(CENSORED_TOKEN);
    } else {
      setSecret('');
    }
  }, [existingSecretItem]);

  const onDelete = () => {
    if (!existingSecretItem) return;

    setEditable(false);
    deleteUserCredentials({ name: existingSecretItem.name })
      .unwrap()
      .then(() => showInfo(t`OpenAI API key removed`))
      .catch((e) => showError(t`Error removing OpenAI API key`, e))
      .finally(() => setEditable(true));
  };

  const onSaveSecret = React.useCallback(() => {
    if (!secret.length) return;

    const userCredentialsRequest: UserCredentialsRequest = {
      name: 'openai_api_key',
      kind: 'openai_api_key',
      secret,
      expires_at: undefined,
    };

    setEditable(false);
    callAddCredsApi({ userCredentialsRequest })
      .unwrap()
      .then(() => showInfo(t`OpenAI API key saved`))
      .catch((e) => showError(t`Error saving OpenAI API key`, e))
      .finally(() => setEditable(true));
  }, [secret, callAddCredsApi, showInfo, showError]);

  return (
    <Wrapper>
      <Standard>
        Entering an OpenAI secret API key will allow you to use our chat
        integration. In order to use the OpenAI service, you need to have{' '}
        <a
          href="https://platform.openai.com/account/billing/overview"
          target="_blank"
          rel="noreferrer">
          funds in your account
        </a>{' '}
        (a ChatGPT subscription is separate), and you&apos;ll need to{' '}
        <a
          href="https://platform.openai.com/account/api-keys"
          target="_blank"
          rel="noreferrer">
          generate a secret API key.
        </a>
      </Standard>
      <LinkToOpenAi>Paste your secret API key here</LinkToOpenAi>
      <InputWrapper>
        <SecretInput
          grow
          hasBorder
          disabled={isLoadingCredentials || !editable || !!existingSecretItem}
          onChangeText={setSecret}
          value={existingSecretItem ? CENSORED_TOKEN : secret}
          validationRules={[isRequiredRule]}
          onSubmitValue={onSaveSecret}
        />
        {existingSecretItem ? (
          <Button
            type="button"
            disabled={isLoadingCredentials || !editable}
            variant={ButtonVariants.SmallTertiary}
            Icon={Remove}
            onClick={() => {
              if (
                confirm(
                  t`Do you really want to remove this API key? This may prevent you from using the Collimator Chat.`,
                )
              ) {
                onDelete();
              }
            }}
          />
        ) : (
          <Button
            type="button"
            disabled={isLoadingCredentials || !editable || secret.length === 0}
            variant={ButtonVariants.SmallTertiary}
            onClick={onSaveSecret}>
            {t`Save`}
          </Button>
        )}
      </InputWrapper>
    </Wrapper>
  );
};
