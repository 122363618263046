/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const LogicNand: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M5.25 12.25H2C1.58579 12.25 1.25 12.5858 1.25 13C1.25 13.4142 1.58579 13.75 2 13.75H5.25V19.25H2C1.58579 19.25 1.25 19.5858 1.25 20C1.25 20.4142 1.58579 20.75 2 20.75H5.25V24.4947C5.25 25.1803 5.80288 25.7377 6.488 25.744C10.8702 25.7841 15.1353 25.6555 18.3123 24.4573C19.9201 23.8509 21.2953 22.955 22.264 21.6301C22.9656 20.6707 23.4286 19.5212 23.6319 18.1614C24.0898 18.6759 24.757 19 25.5 19C26.6194 19 27.567 18.2643 27.8855 17.25H30C30.4142 17.25 30.75 16.9142 30.75 16.5C30.75 16.0858 30.4142 15.75 30 15.75H27.8855C27.567 14.7357 26.6194 14 25.5 14C24.7587 14 24.0927 14.3227 23.6349 14.8352C23.4338 13.451 22.9728 12.2586 22.2786 11.2421C21.3238 9.8442 19.9646 8.83251 18.3596 8.10403C15.1791 6.66042 10.915 6.2779 6.50528 6.25149C5.81176 6.24733 5.25 6.80931 5.25 7.5V12.25ZM6.75 7.75339C11.0381 7.79313 14.9255 8.19258 17.7397 9.46991C19.1597 10.1145 20.2757 10.9693 21.0399 12.0881C21.8013 13.2029 22.25 14.6333 22.25 16.4941C22.25 18.3563 21.8009 19.7223 21.0532 20.7447C20.3024 21.7716 19.2014 22.5189 17.783 23.0538C14.9615 24.1179 11.0693 24.2787 6.75 24.2461V7.75339ZM25.5 17.5C26.0523 17.5 26.5 17.0523 26.5 16.5C26.5 15.9477 26.0523 15.5 25.5 15.5C24.9477 15.5 24.5 15.9477 24.5 16.5C24.5 17.0523 24.9477 17.5 25.5 17.5Z"
      fill={props.fill}
    />
  </svg>
);

LogicNand.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default LogicNand;
