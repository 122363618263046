/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Ifthenelse: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M21.4932 16.8029C21.6315 16.9297 21.8124 17 22 17H30C30.4142 17 30.75 16.6642 30.75 16.25C30.75 15.8358 30.4142 15.5 30 15.5H22.2917L10.5068 4.69713C10.3685 4.57034 10.1876 4.5 10 4.5H2C1.58579 4.5 1.25 4.83579 1.25 5.25C1.25 5.66421 1.58579 6 2 6H9.70826L21.4932 16.8029Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M1.5 16.25C1.5 15.9739 1.72386 15.75 2 15.75H10C10.2761 15.75 10.5 15.9739 10.5 16.25C10.5 16.5261 10.2761 16.75 10 16.75H2C1.72386 16.75 1.5 16.5261 1.5 16.25Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M1.5 26.75C1.5 26.4739 1.72386 26.25 2 26.25H10C10.2761 26.25 10.5 26.4739 10.5 26.75C10.5 27.0261 10.2761 27.25 10 27.25H2C1.72386 27.25 1.5 27.0261 1.5 26.75Z"
      fill={props.fill}
    />
  </svg>
);

Ifthenelse.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Ifthenelse;
