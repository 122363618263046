/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Eye: React.FC<Props> = (props) => (
  <svg viewBox="0 0 24 24" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M7.67956 12C8.57292 13.5103 10.1835 14.5 12 14.5C13.8165 14.5 15.4271 13.5103 16.3204 12C15.4271 10.4897 13.8165 9.5 12 9.5C10.1835 9.5 8.57291 10.4897 7.67956 12ZM18 12C16.984 9.64433 14.6798 8 12 8C9.32019 8 7.01601 9.64434 6 12C7.01601 14.3557 9.32019 16 12 16C14.6798 16 16.984 14.3557 18 12Z"
      fill={props.fill}
    />
    <path
      d="M14 11.5C14 12.6046 13.1046 13.5 12 13.5C10.8954 13.5 10 12.6046 10 11.5C10 10.3954 10.8954 9.5 12 9.5C13.1046 9.5 14 10.3954 14 11.5Z"
      fill={props.fill}
    />
  </svg>
);

Eye.defaultProps = {
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Eye;
