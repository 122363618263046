import { LinkInstance, ModelDiagram } from '@collimator/model-schemas-ts';
import { PayloadAction } from '@reduxjs/toolkit';
import { AutolayoutPayload } from 'app/autolayout';
import type { Coordinate } from 'app/common_types/Coordinate';
import { PortSide } from 'app/common_types/PortTypes';
import {
  ModelState,
  getCurrentlyEditingModelFromState,
} from 'app/modelState/ModelState';
import { getVisualNodeHeight } from 'ui/modelRendererInternals/getVisualNodeHeight';
import { getVisualNodeWidth } from 'ui/modelRendererInternals/getVisualNodeWidth';
import {
  RendererState,
  regenLinkRenderFrameData,
  rendererState,
} from 'ui/modelRendererInternals/modelRenderer';
import { pointToLineDistance } from 'util/pointToLineDistance';
import { getPortWorldCoordinate } from './getPortOffsetCoordinate';
import {
  reifyLink_mut,
  setLinkSourceAndDependentTapSources,
} from './linkMutationUtils';
import { snapNumberToGrid } from './modelDataUtils';
import { renderConstants } from './renderConstants';
import { getLinkTapCoordinate } from './tapLinkToRenderData';

export const eraseModelLayout = (state: ModelState) => {
  const model = getCurrentlyEditingModelFromState(state);
  if (!model) return;

  for (let i = 0; i < model.nodes.length; i++) {
    const node = model.nodes[i];

    node.uiprops.x = 0;
    node.uiprops.y = 0;
  }

  for (let i = 0; i < model.links.length; i++) {
    const link = model.links[i];

    link.uiprops.segments = [];
  }
};

const connectTapToLinkNearestToCoord = (
  tappingLink: LinkInstance,
  hostLink: LinkInstance,
  targetCoordinate: Coordinate,
  model: ModelDiagram,
) => {
  let tappedSegmentIndex = 0;
  let tappedSegmentDir: 'horiz' | 'vert' = 'horiz';
  let tappedSegmentCoord = targetCoordinate.x;
  let shortestDist = Number.MAX_SAFE_INTEGER;

  for (let i = 1; i < hostLink.uiprops.segments.length - 1; i++) {
    const curSeg = hostLink.uiprops.segments[i];
    const prevSeg = hostLink.uiprops.segments[i - 1];
    const nextSeg = hostLink.uiprops.segments[i + 1];
    const isEven = i % 2 === 0;

    const vertexOne = isEven
      ? { x: prevSeg.coordinate, y: curSeg.coordinate }
      : { x: curSeg.coordinate, y: prevSeg.coordinate };
    const vertexTwo = isEven
      ? { x: nextSeg.coordinate, y: curSeg.coordinate }
      : { x: curSeg.coordinate, y: nextSeg.coordinate };

    const dist = pointToLineDistance(targetCoordinate, vertexOne, vertexTwo);

    if (dist < shortestDist) {
      shortestDist = dist;
      tappedSegmentIndex = i;
      tappedSegmentDir = isEven ? 'vert' : 'horiz';
      tappedSegmentCoord = isEven ? targetCoordinate.y : targetCoordinate.x;
    }
  }

  tappingLink.uiprops.link_type = {
    connection_method: 'link_tap',
    tapped_link_uuid: hostLink.uuid,
    tapped_segment: {
      segment_type: 'real',
      tapped_segment_index: tappedSegmentIndex,
      tapped_segment_direction: tappedSegmentDir,
    }, // TODO: unhardcode
    tap_coordinate: snapNumberToGrid(tappedSegmentCoord),
  };

  setLinkSourceAndDependentTapSources(
    tappingLink.uuid,
    hostLink.src,
    model.links,
    rendererState?.refs?.current?.linksIndexLUT,
  );
};

export const consumeAutoLayoutRaw = (
  model: ModelDiagram,
  layoutPayload: AutolayoutPayload,
) => {
  const { nodeCoordinates, linkLayoutLut, tapData, tappedHostLinkIds } =
    layoutPayload;
  for (let i = 0; i < model.nodes.length; i++) {
    const node = model.nodes[i];

    const currentNodeCoord = nodeCoordinates[node.uuid];

    if (currentNodeCoord) {
      node.uiprops.x = currentNodeCoord.x;
      node.uiprops.y = currentNodeCoord.y;
    }
  }

  const linkLut: Record<string, LinkInstance> = {};

  for (let i = 0; i < model.links.length; i++) {
    const link = model.links[i];
    link.uiprops.segments = [];

    // if the link has taps, the generated autolayout segment position data
    // is not valid for this link.
    // this is because we're fudging the existence of tap points, which
    // is a collimator-only idea (and hopefully soon to be replaced with actual node entities)
    if (tappedHostLinkIds.has(link.uuid)) {
      // we override model.nodes because the rendererState inside reifyLink_mut
      // has stale data for where the nodes are
      reifyLink_mut(link, false, true, model.nodes);
    } else if (linkLayoutLut[link.uuid]) {
      link.uiprops.segments = linkLayoutLut[link.uuid];
    }

    linkLut[link.uuid] = link;
  }

  // here, we're processing tap link metadata and placing each tap point on its host link
  // at the closest point possible correlated to the generated autolayout data.
  for (let i = 0; i < tapData.length; i++) {
    const currentTapData = tapData[i];
    const tappingLink = linkLut[currentTapData.tappingLinkUuid];
    const hostLink = linkLut[currentTapData.hostLinkUuid];

    if (!tappingLink || !hostLink) continue;

    connectTapToLinkNearestToCoord(
      tappingLink,
      hostLink,
      currentTapData.coordinate,
      model,
    );
  }
};

export const consumeAutoLayout = (
  state: ModelState,
  action: PayloadAction<AutolayoutPayload>,
) => {
  const model = getCurrentlyEditingModelFromState(state);
  if (!model) return;

  consumeAutoLayoutRaw(model, action.payload);
};

const rebuildNecessaryLinksData = (model: ModelDiagram, rs: RendererState) => {
  rs.refs.current.linksRenderingDependencyTree = {};

  for (let i = 0; i < model.links.length; i++) {
    const l = model.links[i];

    if (l.uiprops.link_type.connection_method == 'link_tap') {
      if (
        !rs.refs.current.linksRenderingDependencyTree[
          l.uiprops.link_type.tapped_link_uuid
        ]
      ) {
        rs.refs.current.linksRenderingDependencyTree[
          l.uiprops.link_type.tapped_link_uuid
        ] = [l.uuid];
      } else {
        rs.refs.current.linksRenderingDependencyTree[
          l.uiprops.link_type.tapped_link_uuid
        ].push(l.uuid);
      }
    } else {
      if (!rs.refs.current.linksRenderingDependencyTree.__no_dependency) {
        rs.refs.current.linksRenderingDependencyTree.__no_dependency = [];
      }
      rs.refs.current.linksRenderingDependencyTree.__no_dependency.push(l.uuid);
    }
  }

  regenLinkRenderFrameData(rs, model.nodes, model.links);
};

type SegmentHashValueList = Array<{
  segmentIndex: number;
  linkIndex: number;
  spanMin: number;
  spanMax: number;
}>;
type SegmentSpatialHash = Map<number, SegmentHashValueList>;

type NodeHashValueList = Array<{ spanMin: number; spanMax: number }>;
type NodeSpatialHash = Map<number, NodeHashValueList>;

const separateSegmentsAlongAxis = (
  model: ModelDiagram,
  segmentSpatialHashForAxis: SegmentSpatialHash,
  nodeSpatialHashForAxis: NodeSpatialHash,
) => {
  for (let [i, dataList] of segmentSpatialHashForAxis) {
    const spaceGridCoord = i;
    // copy so our iterations don't get messed up (we actively modify the contents of the spatial hash).
    // generally these won't be large arrays.
    const spaceSegmentsDataList = [...dataList];

    for (let j = 0; j < spaceSegmentsDataList.length; j++) {
      const currentSegmentData = spaceSegmentsDataList[j];

      let slotFound = false;
      let newGridCoord = 0;
      let distance = 0;

      while (!slotFound) {
        let noBeforeCollision = true;
        const beforeSpaceCoord = spaceGridCoord - distance;
        const segmentsInBeforeSpace =
          segmentSpatialHashForAxis.get(beforeSpaceCoord);

        if (segmentsInBeforeSpace && segmentsInBeforeSpace.length > 1) {
          for (let k = 0; k < segmentsInBeforeSpace.length; k++) {
            const checkCollSegment = segmentsInBeforeSpace[k];

            // tapping links should move out of the way of regular links,
            // not the other way around.
            if (
              model.links[checkCollSegment.linkIndex].uiprops.link_type
                .connection_method !== 'link_tap' ||
              (model.links[checkCollSegment.linkIndex].uiprops.link_type
                .connection_method === 'link_tap' &&
                model.links[currentSegmentData.linkIndex].uiprops.link_type
                  .connection_method !== 'direct_to_block')
            ) {
              const colliding =
                currentSegmentData.linkIndex !== checkCollSegment.linkIndex &&
                currentSegmentData.spanMin < checkCollSegment.spanMax &&
                currentSegmentData.spanMax > checkCollSegment.spanMin;

              if (colliding) {
                noBeforeCollision = false;
                break;
              }
            }
          }
        }

        const nodesInBeforeSpace = nodeSpatialHashForAxis.get(beforeSpaceCoord);
        if (noBeforeCollision && nodesInBeforeSpace) {
          for (let k = 0; k < nodesInBeforeSpace.length; k++) {
            const checkCollNode = nodesInBeforeSpace[k];
            const colliding =
              currentSegmentData.spanMin < checkCollNode.spanMax &&
              currentSegmentData.spanMax > checkCollNode.spanMin;

            if (colliding) {
              noBeforeCollision = false;
              break;
            }
          }
        }

        if (noBeforeCollision) {
          slotFound = true;
          newGridCoord = beforeSpaceCoord;
          break;
        }

        let noAfterCollision = true;
        const afterSpaceCoord = spaceGridCoord + distance;
        const segmentsInAfterSpace =
          segmentSpatialHashForAxis.get(afterSpaceCoord);

        if (segmentsInAfterSpace && segmentsInAfterSpace.length > 1) {
          for (let k = 0; k < segmentsInAfterSpace.length; k++) {
            const checkCollSegment = segmentsInAfterSpace[k];

            // tapping links should move out of the way of regular links,
            // not the other way around.
            if (
              model.links[checkCollSegment.linkIndex].uiprops.link_type
                .connection_method !== 'link_tap' ||
              (model.links[checkCollSegment.linkIndex].uiprops.link_type
                .connection_method === 'link_tap' &&
                model.links[currentSegmentData.linkIndex].uiprops.link_type
                  .connection_method !== 'direct_to_block')
            ) {
              const colliding =
                currentSegmentData.linkIndex !== checkCollSegment.linkIndex &&
                currentSegmentData.spanMin < checkCollSegment.spanMax &&
                currentSegmentData.spanMax > checkCollSegment.spanMin;

              if (colliding) {
                noAfterCollision = false;
                break;
              }
            }
          }
        }

        const nodesInAfterSpace = nodeSpatialHashForAxis.get(afterSpaceCoord);
        if (noAfterCollision && nodesInAfterSpace) {
          for (let k = 0; k < nodesInAfterSpace.length; k++) {
            const checkCollNode = nodesInAfterSpace[k];
            const colliding =
              currentSegmentData.spanMin < checkCollNode.spanMax &&
              currentSegmentData.spanMax > checkCollNode.spanMin;

            if (colliding) {
              noAfterCollision = false;
              break;
            }
          }
        }

        if (noAfterCollision) {
          slotFound = true;
          newGridCoord = afterSpaceCoord;
          break;
        }

        distance++;
      }

      const currentSpaceData =
        segmentSpatialHashForAxis.get(spaceGridCoord) || [];
      segmentSpatialHashForAxis.set(
        spaceGridCoord,
        currentSpaceData.filter(
          (iterSegData) =>
            !(
              iterSegData.linkIndex === currentSegmentData.linkIndex &&
              iterSegData.segmentIndex === currentSegmentData.segmentIndex
            ),
        ),
      );
      const newSpace = segmentSpatialHashForAxis.get(newGridCoord);
      segmentSpatialHashForAxis.set(
        newGridCoord,
        newSpace ? [...newSpace, currentSegmentData] : [currentSegmentData],
      );

      model.links[currentSegmentData.linkIndex].uiprops.segments[
        currentSegmentData.segmentIndex
      ].coordinate = newGridCoord * renderConstants.GRID_UNIT_PXSIZE;
    }
  }
};

let hody = false;

export const postAutoLayoutRaw = (model: ModelDiagram) => {
  if (!rendererState) return;

  const verticalSegmentSpatialHash: SegmentSpatialHash = new Map<
    number,
    SegmentHashValueList
  >();

  // vertical segments are defined by their X coordinate
  const xAxisNodeSpatialHash: NodeSpatialHash = new Map<
    number,
    NodeHashValueList
  >();

  const horizontalSegmentSpatialHash: SegmentSpatialHash = new Map<
    number,
    SegmentHashValueList
  >();
  // horizontal segments are defined by their Y coordinate
  const yAxisNodeSpatialHash: NodeSpatialHash = new Map<
    number,
    NodeHashValueList
  >();

  rebuildNecessaryLinksData(model, rendererState);

  // collect tap coordinates and make a local link LUT
  const tapCoordinates: { [uuid: string]: Coordinate } = {};
  const linkIndexLut: Record<string, number> = {};
  for (let i = 0; i < model.links.length; i++) {
    const link = model.links[i];
    linkIndexLut[link.uuid] = i;
    if (link.uiprops.link_type.connection_method !== 'link_tap') continue;
    tapCoordinates[link.uuid] = getLinkTapCoordinate(rendererState, link);
  }

  // iterate through links in order of rendering-dependency (so we can reify them in order)
  // and then build our segments' spatial hashes

  const baseLevelLinks =
    rendererState.refs.current.linksRenderingDependencyTree.__no_dependency ||
    [];
  const iteratingLinkUUIDs: string[] = [];
  for (let i = 0; i < baseLevelLinks.length; i++) {
    iteratingLinkUUIDs.push(baseLevelLinks[i]);
  }
  let linkRenderIndex = 0;

  while (iteratingLinkUUIDs.length) {
    const linkUUID = iteratingLinkUUIDs.pop() || '';
    const realLinkIndex = rendererState.refs.current.linksIndexLUT[linkUUID];
    const link = model.links[realLinkIndex];

    if (!link) continue;

    const dependentLinkUUIDs =
      rendererState.refs.current.linksRenderingDependencyTree[linkUUID];

    if (dependentLinkUUIDs && dependentLinkUUIDs.length > 0) {
      for (let i = 0; i < dependentLinkUUIDs.length; i++) {
        iteratingLinkUUIDs.push(dependentLinkUUIDs[i]);
      }
    }

    if (link.uiprops.link_type.connection_method === 'link_tap' && !hody) {
      const hostLink =
        model.links[linkIndexLut[link.uiprops.link_type.tapped_link_uuid]];
      connectTapToLinkNearestToCoord(
        link,
        hostLink,
        tapCoordinates[link.uuid],
        model,
      );
    }

    // reify all links. we can't set the coordinates of fake link segments
    reifyLink_mut(link);

    for (let j = 0; j < link.uiprops.segments.length; j++) {
      const segment = link.uiprops.segments[j];
      const segmentGridCoord = Math.round(
        segment.coordinate / renderConstants.GRID_UNIT_PXSIZE,
      );

      const isVert = segment.segment_direction === 'vert';

      let startCoord = 0;
      let endCoord = 0;
      if (j === 0) {
        const srcBlock = rendererState
          ? model.nodes[
              rendererState.refs.current.nodesIndexLUT[link.src?.node || '']
            ]
          : undefined;
        if (srcBlock) {
          const linkStartCoordFromConnection =
            link.uiprops.link_type.connection_method === 'link_tap'
              ? getLinkTapCoordinate(rendererState, link)
              : getPortWorldCoordinate(srcBlock, PortSide.Output, link.src);

          const linkStartCoord =
            linkStartCoordFromConnection || link.uiprops.hang_coord_start;

          startCoord = isVert ? linkStartCoord?.y || 0 : linkStartCoord?.x || 0;
        }
      } else {
        startCoord = link.uiprops.segments[j - 1].coordinate;
      }

      if (j === link.uiprops.segments.length - 1) {
        const dstBlock = rendererState
          ? model.nodes[
              rendererState.refs.current.nodesIndexLUT[link.dst?.node || '']
            ]
          : undefined;
        if (dstBlock) {
          const linkEndCoord =
            getPortWorldCoordinate(dstBlock, PortSide.Input, link.dst) ||
            link.uiprops.hang_coord_end;
          endCoord = isVert ? linkEndCoord?.y || 0 : linkEndCoord?.x || 0;
        }
      } else {
        endCoord = link.uiprops.segments[j + 1].coordinate;
      }

      let spanMin = Math.round(
        Math.min(startCoord, endCoord) / renderConstants.GRID_UNIT_PXSIZE,
      );
      let spanMax = Math.round(
        Math.max(startCoord, endCoord) / renderConstants.GRID_UNIT_PXSIZE,
      );

      // we don't bother worrying about zero-length segments (visually irrelevant),
      // because we will clean those up after with link-simplification
      if (spanMax - spanMin > 1) {
        if (isVert) {
          const vertList = verticalSegmentSpatialHash.get(segmentGridCoord);
          verticalSegmentSpatialHash.set(
            segmentGridCoord,
            vertList
              ? [
                  ...vertList,
                  {
                    segmentIndex: j,
                    linkIndex: realLinkIndex,
                    spanMin,
                    spanMax,
                  },
                ]
              : [
                  {
                    segmentIndex: j,
                    linkIndex: realLinkIndex,
                    spanMin,
                    spanMax,
                  },
                ],
          );
        } else {
          const horizList = horizontalSegmentSpatialHash.get(segmentGridCoord);
          horizontalSegmentSpatialHash.set(
            segmentGridCoord,
            horizList
              ? [
                  ...horizList,
                  {
                    segmentIndex: j,
                    linkIndex: realLinkIndex,
                    spanMin,
                    spanMax,
                  },
                ]
              : [
                  {
                    segmentIndex: j,
                    linkIndex: realLinkIndex,
                    spanMin,
                    spanMax,
                  },
                ],
          );
        }
      }
    }

    linkRenderIndex++;
  }

  // build our nodes' spatial hash
  for (let i = 0; i < model.nodes.length; i++) {
    const node = model.nodes[i];

    const gridWidth =
      Math.floor(getVisualNodeWidth(node) / renderConstants.GRID_UNIT_PXSIZE) +
      1;
    const gridHeight =
      Math.floor(getVisualNodeHeight(node) / renderConstants.GRID_UNIT_PXSIZE) +
      2;
    const gridX =
      Math.floor(node.uiprops.x / renderConstants.GRID_UNIT_PXSIZE) - 1;
    const gridY =
      Math.floor(node.uiprops.y / renderConstants.GRID_UNIT_PXSIZE) - 1;

    for (let j = gridX; j <= gridX + gridWidth; j++) {
      const xAxisList = xAxisNodeSpatialHash.get(j);
      xAxisNodeSpatialHash.set(
        j,
        xAxisList
          ? [...xAxisList, { spanMin: gridY, spanMax: gridY + gridHeight }]
          : [{ spanMin: gridY, spanMax: gridY + gridHeight }],
      );
    }

    for (let j = gridY; j <= gridY + gridHeight; j++) {
      const yAxisList = yAxisNodeSpatialHash.get(j);
      yAxisNodeSpatialHash.set(
        j,
        yAxisList
          ? [...yAxisList, { spanMin: gridX, spanMax: gridX + gridWidth }]
          : [{ spanMin: gridX, spanMax: gridX + gridWidth }],
      );
    }
  }

  // perform the separation
  separateSegmentsAlongAxis(
    model,
    verticalSegmentSpatialHash,
    xAxisNodeSpatialHash,
  );
  separateSegmentsAlongAxis(
    model,
    horizontalSegmentSpatialHash,
    yAxisNodeSpatialHash,
  );
};

export const postAutoLayout = (state: ModelState) => {
  const model = getCurrentlyEditingModelFromState(state);
  if (!model || !rendererState) return;

  postAutoLayoutRaw(model);
};
