/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Conditional: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8 14C8 13.4477 8.44772 13 9 13H14C14.5523 13 15 13.4477 15 14V18C15 18.5523 14.5523 19 14 19H9C8.44772 19 8 18.5523 8 18V14Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M17 14C17 13.4477 17.4477 13 18 13H23C23.5523 13 24 13.4477 24 14V18C24 18.5523 23.5523 19 23 19H18C17.4477 19 17 18.5523 17 18V14ZM18.5 17.5V14.5H22.5V17.5H18.5Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M5.5 4C4.67157 4 4 4.67157 4 5.5V26.5C4 27.3284 4.67157 28 5.5 28H26.5C27.3284 28 28 27.3284 28 26.5V5.5C28 4.67157 27.3284 4 26.5 4H5.5ZM26.5 5.5H5.5V26.5H26.5V5.5Z"
      fill={props.fill}
    />
  </svg>
);

Conditional.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Conditional;
