import { t } from '@lingui/macro';
import {
  useDeleteProjectPermissionByUuidMutation,
  useGetProjectPermissionReadAllQuery,
  usePostProjectPermissionCreateMutation,
  usePutProjectPermissionUpdateByUuidMutation,
} from 'app/apiGenerated/generatedApi';
import {
  DeleteProjectPermissionByUuidApiArg,
  PostProjectPermissionCreateApiArg,
  ProjectPermissionRole,
  PutProjectPermissionUpdateByUuidApiArg,
} from 'app/apiGenerated/generatedApiTypes';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { projectActions } from 'app/slices/projectSlice';
import React from 'react';
import { useNotifications } from 'ui/common/notifications/useNotifications';

export interface NewMember {
  id: string;
  role: ProjectPermissionRole;
  email: string;
  isValid: boolean;
}

export interface MemberUpdatedRole {
  id: string;
  role: ProjectPermissionRole;
}

export interface PermissionBulkUpdateRequest {
  projectId: string;
  membersToAdd: NewMember[];
  membersToUpdate: MemberUpdatedRole[];
  membersToRemove: string[];
}

export function useProjectPermissions(projectId: string) {
  const dispatch = useAppDispatch();

  const { showCompletion, createShowError } = useNotifications();

  const forceRefetchProjectPermissions = useAppSelector(
    (state) => state.project.forceRefetchProjectPermissions,
  );

  const {
    data: projectPermissions,
    refetch,
    isLoading: isLoadingProjectPermissions,
  } = useGetProjectPermissionReadAllQuery({ projectUuid: projectId });

  const [callCreateProjectPermissionApi] =
    usePostProjectPermissionCreateMutation();

  const [callDeleteProjectPermissionApi] =
    useDeleteProjectPermissionByUuidMutation();

  const [callUpdateProjectPermissionApi] =
    usePutProjectPermissionUpdateByUuidMutation();

  // Make sure we refetch the project permission list after another part of the system requests it.
  React.useEffect(() => {
    if (forceRefetchProjectPermissions) {
      refetch();
      dispatch(projectActions.clearRequestRefetchProjectPermissions());
    }
  }, [forceRefetchProjectPermissions, dispatch, refetch]);

  const createProjectPermission = React.useCallback(
    (request: PostProjectPermissionCreateApiArg) =>
      callCreateProjectPermissionApi(request)
        .unwrap()
        .then(() => {
          dispatch(projectActions.requestRefetchProjectPermissions());
        })
        .catch(
          createShowError(
            t({
              id: 'projectApi.createProjectPermissionError',
              message: 'Unable to add member to project.',
            }),
          ),
        ),
    [dispatch, callCreateProjectPermissionApi, createShowError],
  );

  const updateProjectPermission = React.useCallback(
    (request: PutProjectPermissionUpdateByUuidApiArg) =>
      callUpdateProjectPermissionApi(request)
        .unwrap()
        .then(() => {
          dispatch(projectActions.requestRefetchProjectPermissions());
        })
        .catch(
          createShowError(
            t({
              id: 'projectApi.updateProjectPermissionError',
              message: 'Unable to update member role.',
            }),
          ),
        ),
    [dispatch, callUpdateProjectPermissionApi, createShowError],
  );

  const deleteProjectPermission = React.useCallback(
    (request: DeleteProjectPermissionByUuidApiArg) =>
      callDeleteProjectPermissionApi(request)
        .unwrap()
        .catch(
          createShowError(
            t({
              id: 'projectApi.deleteProjectPermissionError',
              message: 'Unable to remove member.',
            }),
          ),
        ),
    [callDeleteProjectPermissionApi, createShowError],
  );

  const doBulkUpdate = React.useCallback(
    (
      request: PermissionBulkUpdateRequest,
      refreshProjectsAtCompletion: boolean,
    ) => {
      const memberAddPromises = request.membersToAdd.map(
        (memberToAdd: NewMember) =>
          callCreateProjectPermissionApi({
            projectUuid: request.projectId,
            projectPermissionCreateRequest: {
              role: memberToAdd.role,
              email: memberToAdd.email,
            },
          }).unwrap(),
      );

      const memberUpdatePromises = request.membersToUpdate.map(
        (memberToUpdate: MemberUpdatedRole) =>
          callUpdateProjectPermissionApi({
            projectUuid: request.projectId,
            permissionUuid: memberToUpdate.id,
            projectPermissionUpdateRequest: {
              role: memberToUpdate.role,
            },
          }).unwrap(),
      );

      const memberRemovePromises = request.membersToRemove.map((permissionId) =>
        callDeleteProjectPermissionApi({
          projectUuid: request.projectId,
          permissionUuid: permissionId,
        }).unwrap(),
      );

      return Promise.all([
        Promise.all(memberAddPromises),
        Promise.all(memberUpdatePromises),
        Promise.all(memberRemovePromises),
      ])
        .then(() => {
          showCompletion(
            t({
              id: 'projectApi.bulkMemberUpdateSuccess',
              message: 'Member permission successfully updated.',
            }),
          );
        })
        .catch(
          createShowError(
            t({
              id: 'projectApi.bulkMemberUpdateError',
              message: 'Unable to update member permissions.',
            }),
          ),
        )
        .finally(() => {
          dispatch(projectActions.requestRefetchProjectPermissions());
          if (refreshProjectsAtCompletion) {
            dispatch(projectActions.requestRefetchProjects());
          }
        });
    },
    [
      dispatch,
      callCreateProjectPermissionApi,
      callUpdateProjectPermissionApi,
      callDeleteProjectPermissionApi,
      createShowError,
      showCompletion,
    ],
  );

  return {
    projectPermissions,
    isLoadingProjectPermissions,
    createProjectPermission,
    updateProjectPermission,
    deleteProjectPermission,
    doBulkUpdate,
  };
}
