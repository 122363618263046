import { t } from '@lingui/macro';
import {
  useDeleteBusTypeMutation,
  usePutBusTypeUpdateMutation,
} from 'app/apiGenerated/generatedApi';
import { useCallback } from 'react';
import { useNotifications } from 'ui/common/notifications/useNotifications';

export function useBusTypeActions() {
  const { showError, showCompletion } = useNotifications();

  const [deleteBusTypeMutation] = useDeleteBusTypeMutation();

  const [updateBusTypeMutation] = usePutBusTypeUpdateMutation();

  const deleteBusType = useCallback(
    async (projectUuid: string, busTypeId: string) => {
      try {
        await deleteBusTypeMutation({
          projectUuid,
          busTypeId,
        }).unwrap();
        showCompletion(
          t({
            id: 'busTypeActions.deleteBusTypeSuccess',
            message: 'Bus type deleted',
          }),
        );
      } catch (e) {
        showError(
          t({
            id: 'busTypeActions.deleteBusTypeError',
            message: 'Failed to delete bus type',
          }),
          e,
        );
      }
    },
    [deleteBusTypeMutation, showCompletion, showError],
  );

  const renameBusType = useCallback(
    async (projectUuid: string, busTypeId: string, newName: string) => {
      try {
        await updateBusTypeMutation({
          projectUuid,
          busTypeId,
          busTypeUpdateRequest: {
            name: newName,
          },
        }).unwrap();
      } catch (e) {
        showError(
          t({
            id: 'busTypeActions.renameBusTypeSuccess',
            message: 'Failed to rename bus type',
          }),
          e,
        );
      }
    },
    [showError, updateBusTypeMutation],
  );

  return {
    renameBusType,
    deleteBusType,
  };
}
