import {
  DesignOptimizationRequest,
  OptimizationAlgoName,
  OptimizationDesignParam,
  OptimizationOptions,
  OptimizationStochasticParam,
} from 'app/api/custom_types/optimizations';
import { useAppSelector } from 'app/hooks';
import React from 'react';
import SectionHeading from 'ui/common/Inputs/SectionHeading';
import SelectInput from 'ui/common/SelectInput';
import AlgorithmFields from './AlgorithmFields';
import ConstrainsSelector from './ConstraintsSelector';
import DesignParamFields from './DesignParamFields';
import {
  BATCH_SIZE,
  NUM_BATCHES,
  OptimizationAlgorithmsFields,
} from './OptimizerAlgoList';
import StochasticParamFields from './StochasticParamFields';
import {
  OptimizationContentProps,
  OptimizerInputGroup,
  StochasticParamsConfig,
  filterConstraints,
  filterDesignParameters,
  filterStochasticParameters,
} from './optimizerModalUtils';

const DesignOptimizationContent = ({
  request,
  signalOptions,
  onUpdate,
  onUpdateValidRequest,
}: OptimizationContentProps) => {
  const options = React.useMemo(() => request.options ?? {}, [request]);
  const algorithm = request.algorithm;
  const objective = request.objective;
  const modelParameters = useAppSelector(
    (state) => state.model.present.parameters,
  );
  const constraints = React.useMemo(
    () => request.constraints ?? [undefined],
    [request],
  );
  const filteredConstraints = React.useMemo(
    () => filterConstraints(constraints),
    [constraints],
  );
  const designParams = React.useMemo(
    () => request.design_parameters ?? [{}],
    [request],
  );
  const stochasticParams: OptimizationStochasticParam[] = React.useMemo(
    () => request.stochastic_parameters ?? [{ distribution: 'normal' }],
    [request],
  );
  const stochasticParamsConfig = React.useMemo<StochasticParamsConfig>(
    () => ({
      params: stochasticParams,
      numBatches: options.num_batches
        ? String(options.num_batches)
        : NUM_BATCHES.default,
      batchSize: options.batch_size
        ? String(options.batch_size)
        : BATCH_SIZE.default,
    }),
    [stochasticParams, options],
  );

  const setOptions = (options: OptimizationOptions) =>
    onUpdate({ ...request, options });

  const setAlgorithm = (algorithm: OptimizationAlgoName) =>
    onUpdate({ ...request, algorithm });

  const setObjective = (objective: string) =>
    onUpdate({ ...request, objective });

  const setConstraints = (constraints: Array<string | undefined>) =>
    onUpdate({ ...request, constraints });

  const setDesignParameters = (params: OptimizationDesignParam[]) =>
    onUpdate({ ...request, design_parameters: params });

  const setStochasticParamsConfig = (config: StochasticParamsConfig) => {
    onUpdate({
      ...request,
      stochastic_parameters: config.params,
      options: {
        ...options,
        num_batches: config.numBatches,
        batch_size: config.batchSize,
      },
    });
  };

  const validDesignParams = React.useMemo(
    () => filterDesignParameters(designParams),
    [designParams],
  );
  const supportsBounds =
    !!OptimizationAlgorithmsFields[algorithm].supportsBounds;
  const hasBoundsButIsNotSupported = React.useMemo(
    () =>
      validDesignParams.some(
        (param) =>
          !supportsBounds &&
          ((param.min !== undefined && param.min !== '') ||
            (param.max !== undefined && param.max !== '')),
      ),
    [validDesignParams, supportsBounds],
  );

  const hasConstraintsButIsNotSupported = React.useMemo(
    () =>
      filteredConstraints.length > 0 &&
      !OptimizationAlgorithmsFields[algorithm].supportsConstraints,
    [filteredConstraints.length, algorithm],
  );

  const isValid =
    !!objective &&
    validDesignParams.length > 0 &&
    !hasBoundsButIsNotSupported &&
    !hasConstraintsButIsNotSupported;
  const invalidReason = React.useMemo(() => {
    if (!objective) return 'The objective signal must be required';
    if (validDesignParams.length === 0)
      return 'At least one design parameter must be selected';
    if (hasBoundsButIsNotSupported)
      return 'Bounds (min/max) are not supported for this algorithm';
    if (hasConstraintsButIsNotSupported)
      return 'Constraints are not supported for this algorithm';
    return undefined;
  }, [
    hasBoundsButIsNotSupported,
    hasConstraintsButIsNotSupported,
    objective,
    validDesignParams.length,
  ]);

  const validRequest = React.useMemo<DesignOptimizationRequest | null>(() => {
    if (!isValid) return null;
    return {
      type: 'design',
      algorithm,
      objective,
      options,
      constraints: filteredConstraints,
      design_parameters: validDesignParams,
      stochastic_parameters: filterStochasticParameters(stochasticParams),
    };
  }, [
    isValid,
    algorithm,
    objective,
    options,
    filteredConstraints,
    validDesignParams,
    stochasticParams,
  ]);

  React.useEffect(
    () => onUpdateValidRequest(validRequest, invalidReason),
    [onUpdateValidRequest, validRequest, invalidReason],
  );

  return (
    <>
      <SectionHeading noBorder>Objective</SectionHeading>
      <OptimizerInputGroup>
        <SelectInput
          placeholder="Choose a signal to optimize"
          options={signalOptions}
          currentValue={objective}
          onSelectValue={setObjective}
        />
      </OptimizerInputGroup>
      <ConstrainsSelector
        signalOptions={signalOptions}
        constraints={constraints}
        onUpdate={setConstraints}
      />
      <DesignParamFields
        designParams={designParams}
        onUpdate={setDesignParameters}
        supportsBounds={supportsBounds}
        modelParameters={modelParameters}
      />
      <StochasticParamFields
        config={stochasticParamsConfig}
        onUpdate={setStochasticParamsConfig}
      />
      <AlgorithmFields
        algorithm={algorithm}
        fieldValues={options}
        setFields={setOptions}
        setAlgo={setAlgorithm}
        hasConstraints={filteredConstraints.length > 0}
      />
    </>
  );
};

export default DesignOptimizationContent;
