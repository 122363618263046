/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Search: React.FC<Props> = (props) => (
  <svg viewBox="0 0 24 24" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M10.9091 6.75C8.61209 6.75 6.75 8.61209 6.75 10.9091C6.75 13.2061 8.61209 15.0682 10.9091 15.0682C13.2061 15.0682 15.0682 13.2061 15.0682 10.9091C15.0682 8.61209 13.2061 6.75 10.9091 6.75ZM5.25 10.9091C5.25 7.78366 7.78366 5.25 10.9091 5.25C14.0345 5.25 16.5682 7.78366 16.5682 10.9091C16.5682 12.2014 16.135 13.3926 15.4059 14.3453L18.5302 17.4697C18.8231 17.7626 18.8231 18.2374 18.5302 18.5303C18.2373 18.8232 17.7624 18.8232 17.4695 18.5303L14.3452 15.406C13.3925 16.135 12.2014 16.5682 10.9091 16.5682C7.78366 16.5682 5.25 14.0345 5.25 10.9091Z"
      fill={props.fill}
    />
  </svg>
);

Search.defaultProps = {
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Search;
