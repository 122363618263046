// !!!! -- DO NOT MANUALLY EDIT -- !!!!
// This file was generated by something in scripts/generateDefaultBlockData.js
// If you want to change the contents of this file, it should be automated
//
// (You can edit this file locally, but it may not be checked into the codebase -
// PRs with a manual edit of this file are forbidden from being merged.)

import { computationBlockDefaults } from 'app/generated_blocks/computationBlockDefaults';
import type { ComputationBlockClass } from 'app/generated_types/ComputationBlockClass';
import deepmerge from 'deepmerge';

export const SINDy: ComputationBlockClass = deepmerge(
  computationBlockDefaults,
  {
    "base": {
      "name": "SINDy",
      "namespace": "core",
      "description": "Block learns system dynamics from CSV data using PySINDy.",
      "feature_level": "dev"
    },
    "modes": {
      "time": "agnostic"
    },
    "ports": {
      "outputs": {
        "static": [
          {
            "name": "out_0"
          }
        ]
      }
    },
    "parameter_definitions": [
      {
        "name": "file_name",
        "description": "Name of the file to use as a data source.",
        "data_type": "string",
        "default_value": ""
      },
      {
        "name": "header_as_first_row",
        "description": "Check this box if the file has a header.",
        "data_type": "bool",
        "default_value": "false"
      },
      {
        "name": "discrete_time",
        "description": "Check this box to train a discrete-time SINDy model",
        "data_type": "bool",
        "default_value": "false"
      },
      {
        "name": "state_columns",
        "description": "Enter a list of column names/indices/slice where the state value(s) are",
        "data_type": "string",
        "default_value": "[\"some_state\"]"
      },
      {
        "name": "control_input_columns",
        "description": "Enter a list of column names/indices/slice where the input value(s) are",
        "data_type": "string",
        "default_value": ""
      },
      {
        "name": "dt",
        "data_type": "float",
        "default_value": "1e-5",
        "description": "Fixed time-interval between rows of CSV files"
      },
      {
        "name": "time_column",
        "description": "Enter the name/index of the time column",
        "data_type": "string",
        "default_value": "time"
      },
      {
        "name": "state_derivatives_columns",
        "description": "Enter a list of column names/indices/slice where the state derivative value(s) are. Must be same order as state names.",
        "data_type": "string",
        "default_value": ""
      },
      {
        "name": "differentiation_method",
        "description": "Method to approximate state derivatives",
        "data_type": "string",
        "hidden": true,
        "default_value": "centered difference",
        "allowed_values": [
          "centered difference"
        ]
      },
      {
        "name": "threshold",
        "data_type": "float",
        "default_value": "0.1",
        "description": "Threshold for Sequentially Thresholded Least Squares (STLSQ) algorithm"
      },
      {
        "name": "alpha",
        "data_type": "float",
        "default_value": "0.05",
        "description": "Regularization strength for STLSQ"
      },
      {
        "name": "max_iter",
        "data_type": "int",
        "default_value": "20",
        "description": "Maximization iterations for STLSQ"
      },
      {
        "name": "normalize_columns",
        "data_type": "bool",
        "default_value": "false",
        "description": "Normalize the columns of the data matrix before regression"
      },
      {
        "name": "poly_order",
        "data_type": "int",
        "default_value": "2",
        "description": "Highest polynomial order in regression library"
      },
      {
        "name": "fourier_n_frequencies",
        "data_type": "int",
        "default_value": "",
        "description": "Highest order of Fourier frequencies in regression library"
      },
      {
        "name": "initial_state",
        "data_type": "any",
        "default_value": "[0]",
        "description": "Initial_values of the states; must be same order as state names."
      },
      {
        "name": "discrete_time_update_interval",
        "data_type": "float",
        "default_value": "1.0",
        "description": "Time interval for discrete updates if a discrete-time model is trained"
      },
      {
        "name": "equations",
        "description": "String representation of the equations.",
        "data_type": "any",
        "default_value": "",
        "read_only": true
      },
      {
        "name": "base_feature_names",
        "description": "Base feature names of the equations.",
        "data_type": "any",
        "default_value": "",
        "hidden": true,
        "read_only": true
      },
      {
        "name": "feature_names",
        "description": "Feature names of the equations.",
        "data_type": "any",
        "default_value": "",
        "hidden": true,
        "read_only": true
      },
      {
        "name": "coefficients",
        "description": "Coefficients of the equations.",
        "data_type": "any",
        "default_value": "",
        "hidden": true,
        "read_only": true
      },
      {
        "name": "has_control_input",
        "description": "Whether the model has control inputs.",
        "data_type": "bool",
        "default_value": "true",
        "hidden": true,
        "read_only": true
      }
    ]
  }
);
