/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Thermal: React.FC<Props> = (props) => (
  <svg viewBox="0 0 8 8" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M1.76441 2.3781C1.41722 2.15221 1.31888 1.68763 1.54477 1.34043C1.77067 0.993235 2.23525 0.894899 2.58244 1.12079C2.88937 1.32049 3.12936 1.56948 3.28775 1.8709C3.44553 2.17116 3.49995 2.48076 3.49995 2.76921C3.49996 3.28307 3.32018 3.80806 3.18842 4.19282L3.17146 4.2424C3.0159 4.69787 2.92347 4.99341 2.92347 5.23075C2.92347 5.32911 2.94112 5.38874 2.9635 5.43134C2.98528 5.47278 3.03354 5.54026 3.15899 5.62188C3.50619 5.84778 3.60452 6.31236 3.37863 6.65955C3.15273 7.00675 2.68815 7.10508 2.34095 6.87918C2.03403 6.67949 1.79404 6.43049 1.63566 6.12907C1.47788 5.82881 1.42347 5.51921 1.42347 5.23076C1.42347 4.71691 1.60324 4.19193 1.735 3.80717L1.75197 3.75758C1.90753 3.30211 1.99995 3.00657 1.99995 2.76924C1.99995 2.67088 1.9823 2.61125 1.95991 2.56865C1.93814 2.52721 1.88987 2.45973 1.76441 2.3781ZM4.84095 2.3781C4.49375 2.15221 4.39542 1.68763 4.62131 1.34043C4.8472 0.993235 5.31178 0.894899 5.65898 1.12079C5.96591 1.32049 6.2059 1.56948 6.36429 1.8709C6.52207 2.17116 6.57648 2.48076 6.57649 2.76921C6.5765 3.28307 6.39672 3.80806 6.26496 4.19282L6.248 4.2424C6.09243 4.69787 6.00001 4.99341 6.00001 5.23075C6.00001 5.32911 6.01766 5.38874 6.04004 5.43134C6.06182 5.47278 6.11008 5.54026 6.23553 5.62188C6.58273 5.84778 6.68106 6.31236 6.45516 6.65955C6.22927 7.00675 5.76469 7.10508 5.41749 6.87918C5.11057 6.67949 4.87058 6.43049 4.7122 6.12907C4.55442 5.82881 4.50001 5.51921 4.50001 5.23076C4.50001 4.71691 4.67978 4.19193 4.81154 3.80717L4.81154 3.80717L4.82851 3.75758C4.98407 3.30211 5.07649 3.00657 5.07649 2.76924C5.07649 2.67088 5.05884 2.61125 5.03645 2.56865C5.01468 2.52721 4.96641 2.45973 4.84095 2.3781Z"
      fill={props.fill}
    />
  </svg>
);

Thermal.defaultProps = {
  width: '8',
  height: '8',
  viewBox: '0 0 8 8',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Thermal;
