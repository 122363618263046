import { useTheme } from '@emotion/react';
import styled from '@emotion/styled/macro';
import { Trans } from '@lingui/macro';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { cameraActions } from 'app/slices/cameraSlice';
import { ReactElement } from 'react';
import Button from 'ui/common/Button/Button';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import { ChevronDown } from 'ui/common/Icons/Standard';
import Menu from 'ui/common/Menu/Menu';
import {
  ChangedItem,
  ItemType,
  ShortcutModifier,
} from 'ui/common/Menu/menuItemTypes';
import { rendererState } from 'ui/modelRendererInternals/modelRenderer';
import { scaleCameraToFitModel } from 'ui/modelRendererInternals/shortcutKeyConfig';
import {
  BASE_ZOOM_DELTA,
  resetZoomAroundScreenCenter,
  setZoomAroundScreenCenter,
  zoomAroundScreenCenter,
} from 'ui/modelRendererInternals/zoom';
import { OpSys, detectedOS } from 'util/detectOS';

const ctrlOrCmd = detectedOS === OpSys.macOS ? '⌘' : 'Ctrl';
const altOrOption = detectedOS === OpSys.macOS ? '⌥' : 'Alt';

export const numberToFixedPrecisionNumberString = (
  num: number,
  prec: number,
) => {
  if (!isNaN(num)) {
    return num.toFixed(prec);
  }

  return undefined;
};

const ZoomDisplayValue = styled.span`
  width: 32px;
  text-align: left;
  margin-left: 4px;
  margin-right: 4px;
`;

const ZoomMenuButton = styled(Button)`
  color: ${({ theme }) => theme.colors.text.secondary};
  font-weight: normal;
  pointer-events: auto;
  padding: 0;
  height: 32px;
`;

const ZoomMenu = (): ReactElement => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const appZoom = useAppSelector((state) => state.camera.zoom);

  const zoomText = numberToFixedPrecisionNumberString(appZoom * 100, 0);

  const updateZoom = (value: number) => {
    const newZoom = value / 100;

    const transform = setZoomAroundScreenCenter(newZoom);
    dispatch(
      cameraActions.setEntireTransform({
        ...transform,
        rerender: true,
      }),
    );
  };

  const onMenuChanged = (changed: ChangedItem) => {
    if (changed?.zoom?.[0]) {
      updateZoom(changed.zoom[0] as number);
    }
  };

  const zoomOut = () => {
    const transform = zoomAroundScreenCenter(-BASE_ZOOM_DELTA);
    dispatch(
      cameraActions.setEntireTransform({
        ...transform,
        rerender: false,
      }),
    );
  };
  const zoomIn = () => {
    const transform = zoomAroundScreenCenter(BASE_ZOOM_DELTA);
    dispatch(
      cameraActions.setEntireTransform({
        ...transform,
        rerender: false,
      }),
    );
  };
  const zoomReset = () => {
    const transform = resetZoomAroundScreenCenter();
    dispatch(
      cameraActions.setEntireTransform({
        ...transform,
        rerender: false,
      }),
    );
  };

  const zoomToFit = () => {
    if (!rendererState) return;

    scaleCameraToFitModel(rendererState);
  };

  // TODO autofocus on the zoom input when the lifecycle
  // of the menu items matches the visibility of the menu.
  // Currently we render this menu even if the zoom menu isn't open
  // so autofocusing on the zoom input will just serve to steal
  // focus from somewhere else in the app after loading the model renderer
  // and the zoom input will have been blurred by the time the zoom menu is opened.
  // This is related to general performance and lifecycle issues
  // we are seeing with Tooltip.tsx.
  return (
    <Menu
      onSubmitValue={onMenuChanged}
      items={[
        {
          type: ItemType.Input,
          id: 'zoom',
          value: zoomText,
          pattern: '[0-9]*',
        },
        {
          type: ItemType.Separator,
        },
        {
          type: ItemType.Button,
          content: <Trans id="zoomMenu.zoomOut">Zoom out</Trans>,
          onClick: zoomOut,
          shortcut: {
            keyStrings: [ctrlOrCmd, '-'],
            modifier: ShortcutModifier.META,
            code: '-',
          },
        },
        {
          type: ItemType.Button,
          content: <Trans id="zoomMenu.zoomIn">Zoom in</Trans>,
          onClick: zoomIn,
          shortcut: {
            keyStrings: [ctrlOrCmd, '='],
            modifier: ShortcutModifier.META,
            code: '=',
          },
        },
        {
          type: ItemType.Button,
          content: <Trans id="zoomMenu.reset">Zoom to 100%</Trans>,
          onClick: zoomReset,
          shortcut: {
            keyStrings: [ctrlOrCmd, '0'],
            modifier: ShortcutModifier.META,
            code: '0',
          },
        },
        {
          type: ItemType.Button,
          content: <Trans id="zoomMenu.zoomToFit">Zoom to fit</Trans>,
          onClick: zoomToFit,
          shortcut: {
            keyStrings: ['Space'],
            modifier: [],
            code: '0',
          },
        },
      ]}>
      <ZoomMenuButton variant={ButtonVariants.SmallSecondary}>
        <ZoomDisplayValue>{zoomText}%</ZoomDisplayValue>
        <ChevronDown fill={theme.colors.text.secondary} />
      </ZoomMenuButton>
    </Menu>
  );
};

export default ZoomMenu;
