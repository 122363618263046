import {
  JobKind,
  SimulationListResponse,
  SimulationStatus,
} from 'app/apiGenerated/generatedApiTypes';
import { convertAPISimulationSummaryToSimulationSummaryUI } from 'app/apiTransformers/convertGetSimulationsForUserReadAll';
import { toDateTimeStringOrNull } from 'util/dateUtils';

export interface SimulationSummaryUI {
  id: string;
  projectId: string;
  modelId: string;
  modelVersionId: string;
  startDate: string;
  status: SimulationStatus;
  areResultsAvailable: boolean;
  kind?: JobKind;
}

export function getSimulationNameAsFormattedCreationDate(
  simulation: SimulationSummaryUI,
) {
  return toDateTimeStringOrNull(simulation.startDate);
}

export function convertGetSimulationReadAll(
  apiResponse: SimulationListResponse,
): SimulationSummaryUI[] {
  const simulations = apiResponse.simulations
    .filter((apiSimulationSummary) => !apiSimulationSummary.compile_only)
    .map((apiSimulationSummary) =>
      convertAPISimulationSummaryToSimulationSummaryUI(apiSimulationSummary),
    );

  if (simulations) {
    return [...simulations].sort(
      (a, b) =>
        new Date(b.startDate).getTime() - new Date(a.startDate).getTime(),
    );
  }

  return [];
}
