import { GetProjectReadAllApiArg } from 'app/apiGenerated/generatedApiTypes';
import { getAdminAssumedUser } from 'app/config/adminAssumedUser';
import { useGetProjectReadAllQuery } from 'app/enhancedApi';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { projectActions } from 'app/slices/projectSlice';
import React from 'react';

import { Project } from 'app/apiTransformers/convertAPIProjectToProject';
import { ProjectFilter } from 'util/projectFilterUtils';

const getProjectFilterFunction =
  (
    projectFilter: ProjectFilter,
    showFavoritesOnly: boolean,
    currentUserId: string,
  ) =>
  (project: Project) => {
    if (showFavoritesOnly && !project.isFavorite) {
      return false;
    }

    if (projectFilter === 'public') {
      return project.isPublic;
    }
    if (projectFilter === 'mine') {
      return project.ownerUuid === currentUserId;
    }
    if (projectFilter === 'sharedWithMe') {
      return project.isMember && project.ownerUuid !== currentUserId;
    }
    return false;
  };

export function useProjects(
  projectFilter?: ProjectFilter,
  showFavoritesOnly?: boolean,
) {
  const dispatch = useAppDispatch();

  const forceRefetchProjects = useAppSelector(
    (state) => state.project.forceRefetchProjects,
  );

  const assumedAdminUser = getAdminAssumedUser();
  const getProjectReadAllQueryRequest: GetProjectReadAllApiArg = React.useMemo(
    () => ({ userUuid: assumedAdminUser }),
    [assumedAdminUser],
  );

  const userId = useAppSelector((state) => state.user.userId);
  const currentUserId = getAdminAssumedUser() ?? userId;

  const {
    data: allProjects,
    refetch,
    isLoading: isLoadingProjects,
    isFetching: isFetchingProjects,
  } = useGetProjectReadAllQuery(getProjectReadAllQueryRequest);

  // Make sure we refetch the project list after another part of the system requests it.
  // FIXME: remove this - use RTK Query cache
  React.useEffect(() => {
    if (forceRefetchProjects) {
      refetch();
      dispatch(projectActions.clearRequestRefetchProjects());
    }
  }, [forceRefetchProjects, dispatch, refetch]);

  const projects = React.useMemo(() => {
    if (projectFilter) {
      if (!currentUserId) return [];
      return (
        allProjects?.filter(
          getProjectFilterFunction(
            projectFilter,
            !!showFavoritesOnly,
            currentUserId,
          ),
        ) || []
      );
    }
    return allProjects;
  }, [allProjects, projectFilter, showFavoritesOnly, currentUserId]);

  return {
    refetchProjects: refetch,
    isLoadingProjects: isLoadingProjects || !currentUserId,
    isFetchingProjects: isFetchingProjects || !currentUserId,
    projects,
  };
}
