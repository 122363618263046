import { ToolCompleteCallback } from 'app/chat/responseStreamParser';
import React from 'react';
import { indent } from '../PythonHooks';
import { useModelEditorInfo } from '../useModelEditorInfo';
import { usePythonToJsonConverter } from '../usePythonToJsonConverter';
import { useUiModelUpdater } from '../useUiModelUpdater';

const wrapInTryCatch = (code: string) => `
try:
${indent(code, 4)}
except AttributeError as e:
    print(e)
    if "model_builder.library" in str(e):
        raise AttributeError(f"{e}\\nSearch blocks library with search_blocks.")
    else:
        raise e
`;

export const useUpdateModel = () => {
  const { editorMode, isModelReadOnly } = useModelEditorInfo();
  const updateUiModel = useUiModelUpdater();

  const convertPythonToJson = usePythonToJsonConverter();

  const updateModelCallback = React.useCallback(
    async (args: any, onComplete: ToolCompleteCallback) => {
      if (isModelReadOnly) {
        onComplete({
          error: "read-only models can't be modified.",
        });
        return;
      }
      const { code, is_new } = args;
      try {
        const { jsonModel, stdout, stderr } = await convertPythonToJson(
          wrapInTryCatch(code),
          is_new,
          editorMode,
        );
        await updateUiModel(jsonModel, editorMode, true);

        onComplete({
          result: `Model updated.\n\n${stdout}\n\n${stderr}`,
        });
      } catch (e1: any) {
        try {
          onComplete({
            error: `${e1.message}\n**Model was not updated.**`,
          });
        } catch (e2: any) {
          if (process.env.NODE_ENV === 'development') {
            console.error(e2);
          }
          onComplete({ error: `${e2.message}\n**Model was not updated.**` });
        }
      }
    },
    [convertPythonToJson, editorMode, isModelReadOnly, updateUiModel],
  );

  return updateModelCallback;
};
