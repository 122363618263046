import { useAppSelector } from 'app/hooks';
import React from 'react';
import { DataExplorerPlotCellLoader } from 'ui/dataExplorer/loaders/DataExplorerPlotCellLoader';

interface Props {
  explorationId: string; // Exploration id as set in the URL
}

/**
 * Loader that notices when the current set of plot cells changes and makes sure
 * we load plot data for all current plots being shown in the data explorer.
 * This should be used as a singleton to ensure that plot data is only loaded and
 * processed once.
 */
export const DataExplorerPlotLoader: React.FC<Props> = ({ explorationId }) => {
  const loadedExplorationId = useAppSelector(
    (state) => state.dataExplorer.explorationId,
  );
  const idToPlotCell = useAppSelector(
    (state) => state.dataExplorer.idToPlotCell,
  );
  const idToTrace = useAppSelector((state) => state.dataExplorer.idToTrace);

  const modelIdToVersionIdRequestData = useAppSelector(
    (state) => state.modelVersions.modelIdToVersionIdRequestData,
  );
  const submodelsToFetch = useAppSelector(
    (state) => state.submodels.submodelsToFetch,
  );

  // Don't load any plot data until we have loaded the exploration that defines
  // the plot metadata for visualization.
  if (explorationId !== loadedExplorationId) {
    return null;
  }

  // Wait for model and submodel data to be fetched before fetching traces.
  if (Object.values(modelIdToVersionIdRequestData).length > 0) {
    return null;
  }
  if (submodelsToFetch.length > 0) {
    return null;
  }

  const plotCellsReadyToLoad = Object.values(idToPlotCell).filter((plotCell) =>
    plotCell.traceIds.every(
      (traceId) => !!idToTrace[traceId]?.explorationSimId,
    ),
  );

  // Once we have a loaded data exploration metadata, load the associated plot data.
  // Also load any plot data if the data exploration metadata changes to include
  // plot data we haven't loaded yet.
  return (
    <>
      {plotCellsReadyToLoad.map((plotCell) => (
        <DataExplorerPlotCellLoader key={plotCell.id} cellId={plotCell.id} />
      ))}
    </>
  );
};
