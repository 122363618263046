import styled from '@emotion/styled/macro';
import React from 'react';
import { Search } from 'ui/common/Icons/Small';
import Input, { InputSubmitMetadata } from 'ui/common/Input/Input';

const SectionSearchWrapper = styled.div`
  padding: ${({ theme }) => theme.spacing.normal};

  input,
  input:focus {
    background: #f1f3f3;
  }

  input::placeholder {
    color: #b6bbbb;
    opacity: 1;
  }

  svg {
    fill: #b6bbbb !important;
  }

  &:hover {
    svg {
      fill: #5c6f70 !important;
    }

    input::placeholder {
      color: #5c6f70 !important;
    }
  }

  svg.focused {
    fill: #082426 !important;
  }
  input.focused::placeholder {
    color: #082426 !important;
  }
`;

interface Props {
  onChangeText: (newText: string) => void;
  placeholder: string;
  autoFocus?: boolean;
  onCancel?: () => void;
  onSubmit?: (newValue: string, submitMetadata: InputSubmitMetadata) => void;
  inputRef?: React.MutableRefObject<
    HTMLInputElement | HTMLTextAreaElement | null
  >;
}

const SectionSearch: React.FC<Props> = ({
  onChangeText,
  placeholder,
  autoFocus,
  onCancel,
  onSubmit,
  inputRef,
}) => (
  <SectionSearchWrapper>
    <Input
      noLeftIconColor
      LeftIcon={Search}
      placeholder={placeholder}
      onChangeText={onChangeText}
      onCancel={onCancel}
      onSubmitValue={onSubmit}
      autoFocus={autoFocus}
      externalInputRef={inputRef}
      clearable
    />
  </SectionSearchWrapper>
);

export default SectionSearch;
