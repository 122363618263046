/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const CppFile: React.FC<Props> = (props) => (
  <svg viewBox="0 0 24 24" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.7072 5.6H6.56097L6.56097 18.4H16.7072V18.09L18.2682 17.1944V18.4C18.2682 19.2837 17.5693 20 16.7072 20H6.56097C5.69887 20 5 19.2837 5 18.4V5.6C5 4.71634 5.69887 4 6.56097 4H16.7072C17.5693 4 18.2682 4.71634 18.2682 5.6V7.63227L16.7072 6.82606V5.6Z"
      fill={props.fill}
    />
    <path d="M11 8.5H8V7.5H12.7L11 8.5Z" fill={props.fill} />
    <path d="M8 11.5H9.5V10.5H8V11.5Z" fill={props.fill} />
    <path d="M9.5 14.5H8V13.5H9.5V14.5Z" fill={props.fill} />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M19.6103 9.71258C19.7174 9.77368 19.8149 9.87919 19.8855 9.99999C19.9562 10.1208 20 10.2569 20 10.3791V14.6209C20 14.7431 19.9561 14.8792 19.8855 15C19.8149 15.1208 19.7175 15.2263 19.6103 15.2874L15.8897 17.4083C15.6754 17.5306 15.3246 17.5306 15.1103 17.4083L11.3897 15.2874C11.2825 15.2263 11.1851 15.1208 11.1145 15C11.0439 14.8792 11 14.7431 11 14.6209V10.3791C11 10.1347 11.1754 9.83475 11.3897 9.71258L15.1103 7.59165C15.3246 7.46945 15.6754 7.46945 15.8897 7.59165L19.6103 9.71258ZM15.5 9.5379C16.5674 9.5379 17.5629 10.1051 18.098 11.018L16.7993 11.7593C16.5315 11.3026 16.0336 11.019 15.5 11.019C14.6729 11.019 14 11.6833 14 12.5C14 12.7695 14.0733 13.0225 14.2012 13.2404C14.4609 13.6828 14.9459 13.9811 15.5 13.9811C16.0336 13.9811 16.5315 13.6974 16.7994 13.2407L18.0981 13.9819C17.563 14.8949 16.5675 15.4621 15.5 15.4621C14.3917 15.4621 13.4219 14.8657 12.9024 13.9808C12.6466 13.5449 12.5 13.039 12.5 12.5C12.5 10.8667 13.8458 9.5379 15.5 9.5379ZM18.1666 12.3354H18.5V12.6646H18.1666V12.9937H17.8334V12.6646H17.5V12.3354H17.8334V12.0063H18.1666V12.3354ZM19.75 12.3354H19.4166V12.0063H19.0834V12.3354H18.75V12.6646H19.0834V12.9937H19.4166V12.6646H19.75V12.3354Z"
      fill={props.fill}
    />
  </svg>
);

CppFile.defaultProps = {
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default CppFile;
