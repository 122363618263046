/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Simulation: React.FC<Props> = (props) => (
  <svg viewBox="0 0 24 24" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M11.6838 5.31484C11.889 5.24642 12.111 5.24642 12.3162 5.31484L18.0662 7.23151C18.4746 7.36762 18.75 7.74976 18.75 8.18019V15.8198C18.75 16.2502 18.4746 16.6324 18.0662 16.7685L12.3162 18.6852C12.111 18.7536 11.889 18.7536 11.6838 18.6852L5.93377 16.7685C5.52543 16.6324 5.25 16.2502 5.25 15.8198V8.18019C5.25 7.74976 5.52543 7.36762 5.93377 7.23151L11.6838 5.31484ZM6.75 9.04057L11.25 10.5406V16.9594L6.75 15.4594V9.04057ZM12.75 16.9594L17.25 15.4594V9.04057L12.75 10.5406V16.9594ZM12 9.20943L15.6283 8L12 6.79057L8.37171 8L12 9.20943Z"
      fill={props.fill}
    />
  </svg>
);

Simulation.defaultProps = {
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Simulation;
