import { useTheme } from '@emotion/react';
import styled from '@emotion/styled/macro';
import { useAppSelector } from 'app/hooks';
import React, { ReactElement, useEffect } from 'react';
import { Information } from 'ui/common/Icons/Standard';
import {
  SettableSmallEmphasis,
  SmallEmphasis,
} from 'ui/common/typography/Typography';

const NotificationContainer = styled.div<{ isOpen: boolean }>`
  position: absolute;
  display: flex;
  flex-direction: column;
  padding: 0 ${(props) => props.theme.spacing.large};
  pointer-events: none;
  z-index: 2;
  width: 100%;
  transition: top 0.15s;
  transition-timing-function: ease-in;
  top: ${({ isOpen, theme }) =>
    isOpen ? theme.spacing.xsmall : `-${theme.sizes.navbarHeight}`};
`;

const NotificationItem = styled.div`
  padding-left: ${({ theme }) => theme.spacing.large};
  padding-top: ${({ theme }) => theme.spacing.small};
  padding-bottom: ${({ theme }) => theme.spacing.small};
  border-radius: 2px;
  color: white;
  background-color: ${({ theme }) => theme.colors.grey[85]};
  pointer-events: all;
  cursor: pointer;
  margin: auto;
  overflow: hidden;
  max-width: 80%;
  display: flex;
  height: 32px;
`;

const NotificationMessage = styled(SmallEmphasis)`
  padding-left: ${({ theme }) => theme.spacing.small};
  padding-right: ${({ theme }) => theme.spacing.small};
  padding-top: ${({ theme }) => theme.spacing.small};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: ${({ theme }) => theme.colors.text.tertiary};
`;

const SettableNotificationMessage = styled(SettableSmallEmphasis)`
  padding-left: ${({ theme }) => theme.spacing.small};
  padding-right: ${({ theme }) => theme.spacing.small};
  padding-top: ${({ theme }) => theme.spacing.small};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-right: ${({ theme }) => theme.spacing.large};
  > a {
    color: inherit;
    text-decoration: underline;
  }
`;

const NotificationCloseButtonSpacer = styled.div`
  margin-right: ${({ theme }) => theme.spacing.small};
  height: 24px;
  border-left: 1px solid ${({ theme }) => theme.colors.grey[70]};
`;

const DAYS_TO_DISPLAY = 3;
const DATE_KEY = 'update_banner_viewed_at';
const CONTENT_KEY = 'update_banner_content';
const MILLIS_IN_DAY = 1000 * 60 * 60 * 24;

// Hack implementation of an update banner based on Notification.tsx
export const UpdateBanner: React.FC = (): ReactElement | null => {
  const { colors } = useTheme();

  const { updateBannerEnabled, updateBannerContent } = useAppSelector(
    (state) => state.userOptions.options,
  );
  const updateBannerActive = updateBannerEnabled && updateBannerContent;

  const [open, setOpen] = React.useState<boolean>(false);

  const bannerViewedOn = localStorage.getItem(DATE_KEY);
  const lastViewedContent = localStorage.getItem(CONTENT_KEY);

  useEffect(() => {
    if (updateBannerActive) {
      if (lastViewedContent !== updateBannerContent) {
        // If the banner content has changed, show the banner
        setOpen(true);

        // Start the clock on the new banner, and save the new content
        localStorage.setItem(DATE_KEY, new Date().toISOString());
        localStorage.setItem(CONTENT_KEY, updateBannerContent);
      } else if (bannerViewedOn) {
        // Date set alongside content. If it isn't, will be set on the next content update.
        // If the banner content hasn't changed, check if it should be displayed
        const viewedOn = new Date(bannerViewedOn);
        const now = new Date();
        const daysSinceViewed =
          (now.getTime() - viewedOn.getTime()) / MILLIS_IN_DAY;
        if (daysSinceViewed < DAYS_TO_DISPLAY) {
          setOpen(true);
        } else {
          setOpen(false);
        }
      }
    }
  }, [
    bannerViewedOn,
    lastViewedContent,
    updateBannerActive,
    updateBannerContent,
  ]);

  return (
    <NotificationContainer isOpen={open}>
      <NotificationItem>
        <Information fill={colors.grey[5]} />
        <NotificationMessage>Collimator update:</NotificationMessage>
        <SettableNotificationMessage content={updateBannerContent} />
      </NotificationItem>
    </NotificationContainer>
  );
};
