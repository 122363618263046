/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  className?: string;
}

const PidI: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1 4.75C1.13807 4.75 1.25 4.86193 1.25 5V25.7927C1.32819 25.765 1.41234 25.75 1.5 25.75H2.57788C2.71708 25.4511 2.86031 24.996 2.99948 24.3765C3.16499 23.6398 3.31087 22.7377 3.44763 21.7211C3.61134 20.5042 3.7572 19.1634 3.90802 17.777C4.01102 16.8302 4.11634 15.8621 4.23123 14.8978C4.51033 12.5551 4.8441 10.2545 5.33462 8.53013C5.57851 7.67276 5.87656 6.90008 6.26195 6.3266C6.64488 5.75677 7.2112 5.25 8 5.25C8.87957 5.25 9.55887 5.65532 10.088 6.16078C10.5934 6.64349 11.0143 7.26934 11.3792 7.81182L11.3924 7.83142C11.7828 8.41183 12.1148 8.90216 12.4822 9.25849C12.8323 9.59808 13.1485 9.75 13.5 9.75C14.4354 9.75 15.3107 9.43063 16.302 9.04987L16.414 9.00681C17.3314 8.65371 18.3803 8.25 19.5 8.25C20.0499 8.25 20.8374 8.24849 21.7608 8.24671C24.1858 8.24205 27.5481 8.23559 30.0044 8.25001C30.4186 8.25245 30.7524 8.5902 30.75 9.0044C30.7476 9.41861 30.4098 9.75242 29.9956 9.74999C27.5457 9.7356 24.197 9.74204 21.7728 9.7467C20.8463 9.74848 20.0546 9.75 19.5 9.75C18.6655 9.75 17.8489 10.0626 16.8398 10.4501C15.8785 10.8194 14.7537 11.25 13.5 11.25C12.6282 11.25 11.9577 10.8394 11.4379 10.3353C10.9503 9.86239 10.5392 9.25092 10.1807 8.71757L10.1477 8.66858C9.75892 8.09054 9.42387 7.60069 9.05198 7.24547C8.69781 6.90718 8.37043 6.75 8 6.75C7.93359 6.75 7.76411 6.78057 7.50694 7.16325C7.25223 7.54228 7.00589 8.13726 6.77738 8.94055C6.32311 10.5375 6.00007 12.7302 5.7207 15.0752C5.61014 16.0033 5.50605 16.959 5.40321 17.9035C5.24992 19.3111 5.09937 20.6936 4.93423 21.9211C4.79527 22.9542 4.64259 23.906 4.463 24.7053C4.28758 25.4861 4.07165 26.1933 3.77686 26.6898C3.76456 26.7105 3.7518 26.7306 3.73862 26.75H31C31.1381 26.75 31.25 26.8619 31.25 27C31.25 27.1381 31.1381 27.25 31 27.25H0.75V5C0.75 4.86193 0.861929 4.75 1 4.75Z"
      fill={props.fill}
    />
    <path
      d="M18.4497 24C18.4097 24 18.3737 23.988 18.3417 23.964C18.3177 23.932 18.3057 23.896 18.3057 23.856V15.744C18.3057 15.704 18.3177 15.672 18.3417 15.648C18.3737 15.616 18.4097 15.6 18.4497 15.6H19.5537C19.5937 15.6 19.6257 15.616 19.6497 15.648C19.6817 15.672 19.6977 15.704 19.6977 15.744V23.856C19.6977 23.896 19.6817 23.932 19.6497 23.964C19.6257 23.988 19.5937 24 19.5537 24H18.4497Z"
      fill={props.fill}
    />
  </svg>
);

PidI.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  className: '',
};

export default PidI;
