/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Edited: React.FC<Props> = (props) => (
  <svg viewBox="0 0 24 24" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M12.4697 8.46967L13 9C12.4697 8.46967 12.4704 8.46891 12.4704 8.46891L12.4713 8.46801L12.4736 8.46579L12.4797 8.45972L12.4987 8.44141C12.5142 8.42659 12.5354 8.40663 12.5619 8.38254C12.6147 8.33451 12.6893 8.26936 12.7815 8.1956C12.9629 8.05046 13.2269 7.86051 13.5396 7.70418C14.132 7.40799 15.1781 7.11739 16.0303 7.96967C16.8826 8.82194 16.592 9.86803 16.2958 10.4604C16.1395 10.7731 15.9495 11.0371 15.8044 11.2185C15.7306 11.3107 15.6655 11.3853 15.6175 11.4381C15.5934 11.4646 15.5734 11.4858 15.5586 11.5013L15.5403 11.5203L15.5342 11.5264L15.532 11.5287L15.5311 11.5296L11.3698 15.6909L8.27938 16.309C7.92952 16.3789 7.62106 16.0705 7.69103 15.7206L8.30911 12.6302L12.4697 8.46967ZM9.69091 13.3698L13 10.0607L13.9394 11L10.6303 14.3091L9.45608 14.5439L9.69091 13.3698ZM14.1422 9.0815L14.9185 9.85784C14.9308 9.83533 14.9427 9.81256 14.9542 9.78959C15.158 9.38197 15.1174 9.17805 14.9697 9.03033C14.822 8.8826 14.618 8.84201 14.2104 9.04582C14.1874 9.05731 14.1647 9.06923 14.1422 9.0815Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12ZM19 12C19 15.866 15.866 19 12 19C8.13401 19 5 15.866 5 12C5 8.13401 8.13401 5 12 5C15.866 5 19 8.13401 19 12Z"
      fill={props.fill}
    />
  </svg>
);

Edited.defaultProps = {
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Edited;
