import { useAppSelector } from 'app/hooks';
import React from 'react';
import HideableSignalDragCellDropZones from 'ui/dataExplorer/drag/signalDrag/HideableCellDropZones';
import TraceDragCellDropZones from 'ui/dataExplorer/drag/traceDrag/CellDropZones';
import { DataExplorerImageCell } from 'ui/dataExplorer/grid/DataExplorerImageCell';
import { DataExplorerPlotCell } from 'ui/dataExplorer/grid/DataExplorerPlotCell';
import { DataExplorerTextCell } from 'ui/dataExplorer/grid/DataExplorerTextCell';

interface Props {
  cellId: string;
  simulationId?: string;
  canEditVisualization: boolean;
}

export const DataExplorerCell: React.FC<Props> = ({
  cellId,
  simulationId,
  canEditVisualization,
}) => {
  const cellMetadata = useAppSelector(
    (state) => state.dataExplorer.idToCell[cellId],
  );

  const { traceDragSourceCandidate, isDraggingTrace } = useAppSelector(
    (state) => state.traceDrag,
  );

  if (!cellMetadata) {
    return null;
  }

  return (
    <>
      {isDraggingTrace && traceDragSourceCandidate && (
        <TraceDragCellDropZones
          traceDragSource={traceDragSourceCandidate}
          targetCellId={cellId}
          cellType={cellMetadata.cellType}
        />
      )}
      <HideableSignalDragCellDropZones
        targetCellId={cellId}
        cellType={cellMetadata.cellType}
      />
      {cellMetadata.cellType === 'plot' ? (
        <DataExplorerPlotCell
          cellId={cellId}
          simulationId={simulationId}
          canEditVisualization={canEditVisualization}
        />
      ) : cellMetadata.cellType === 'text' ? (
        <DataExplorerTextCell cellId={cellId} isDraggingRow={false} />
      ) : cellMetadata.cellType === 'image' ? (
        <DataExplorerImageCell cellId={cellId} isDraggingRow={false} />
      ) : null}
    </>
  );
};
