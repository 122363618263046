import React, { useContext } from 'react';
import { PaymentModal } from 'ui/subscriptions/PaymentModal';
import { t } from '@lingui/macro';
import { customStripeContext } from './CustomStripeProvider';
import { useModal } from './Modal/useModal';

export const SubscriptionModals = () => {
  const { subscriptionStatus } = useContext(customStripeContext);
  const { triggerModal, closeModal } = useModal();

  const summonPaymentModal = React.useCallback(() => {
    if (subscriptionStatus?.in_trial || subscriptionStatus?.paid_and_active)
      return;

    triggerModal(
      <PaymentModal closeModal={closeModal} />,
      t`Your subscription is no longer active`,
    );
  }, [triggerModal, closeModal, subscriptionStatus]);

  const [autoFlashedPaymentModal, setAutoFlashedPaymentModal] =
    React.useState<boolean>(false);

  React.useEffect(() => {
    if (autoFlashedPaymentModal) return;

    if (
      subscriptionStatus &&
      !subscriptionStatus.in_trial &&
      !subscriptionStatus.paid_and_active &&
      subscriptionStatus.has_subscription &&
      !subscriptionStatus.exempt
    ) {
      setAutoFlashedPaymentModal(true);
      summonPaymentModal();
    }
  }, [
    autoFlashedPaymentModal,
    setAutoFlashedPaymentModal,
    subscriptionStatus,
    summonPaymentModal,
  ]);

  return null;
};
