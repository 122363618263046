import {
  CellRow,
  PlotCellMetadata,
  TraceMetadata,
  CellMetadata,
  TextCellMetadata,
  ImageCellMetadata,
} from 'ui/dataExplorer/dataExplorerTypes';

// Associated Entity Id: modelId
export const VISUALIZER_PREFS_V1_KEY = 'VISUALIZER_PREFS_V1';

export const MODEL_EDITOR_VISUALIZER_EXPLORATION_ID =
  'MODEL_EDITOR_VISUALIZER_EXPLORATION_ID';

export interface VisualizerPrefsV1 {
  cellRowIds: string[];
  idToCellRow: Record<string, CellRow>;
  idToCell: Record<string, CellMetadata>;
  idToPlotCell: Record<string, PlotCellMetadata>;
  idToTextCell: Record<string, TextCellMetadata>;
  idToImageCell: Record<string, ImageCellMetadata>;
  idToTrace: Record<string, TraceMetadata>;
}
