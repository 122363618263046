/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Publish: React.FC<Props> = (props) => (
  <svg viewBox="0 0 24 24" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.2278 3.50852C12.1526 3.50287 12.0766 3.5 12 3.5C10.3431 3.5 9 4.84315 9 6.5C9 8.15685 10.3431 9.5 12 9.5C12.0766 9.5 12.1526 9.49712 12.2278 9.49148C11.9795 9.01348 11.789 8.50075 11.6652 7.9625C10.9979 7.81038 10.5 7.21334 10.5 6.5C10.5 5.78666 10.9979 5.18963 11.6652 5.0375C11.789 4.49925 11.9795 3.98652 12.2278 3.50852Z"
      fill={props.fill}
    />
    <path
      d="M13.4182 18.8154C12.9657 18.9358 12.4904 19 12 19C11.5096 19 11.0343 18.9358 10.5818 18.8154C10.3209 18.746 10.0427 18.871 9.91596 19.1094C9.86118 19.2125 9.8025 19.3131 9.74014 19.4112C9.55631 19.7003 9.65081 20.105 9.97886 20.2038C10.6188 20.3965 11.2973 20.5 12 20.5C12.7027 20.5 13.3812 20.3965 14.0211 20.2038C14.3492 20.105 14.4437 19.7003 14.2599 19.4112C14.1975 19.3131 14.1388 19.2125 14.084 19.1094C13.9573 18.871 13.6791 18.746 13.4182 18.8154Z"
      fill={props.fill}
    />
    <path
      d="M6.10001 12.5028C6.36991 12.5122 6.61716 12.3333 6.68708 12.0725C6.94006 11.1286 7.43787 10.2849 8.10828 9.61354C8.29926 9.42231 8.32986 9.11843 8.18615 8.88954C8.12461 8.79154 8.06675 8.69099 8.01276 8.5881C7.85354 8.2847 7.45553 8.16461 7.2059 8.39932C6.21944 9.32685 5.50207 10.5373 5.18343 11.9011C5.1056 12.2342 5.40825 12.5181 5.75004 12.5039C5.81303 12.5013 5.87636 12.5 5.94 12.5C5.99356 12.5 6.0469 12.5009 6.10001 12.5028Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M15.4641 15.5C14.6357 16.9349 15.1273 18.7696 16.5622 19.5981C17.9971 20.4265 19.8318 19.9349 20.6603 18.5C21.4887 17.0651 20.9971 15.2303 19.5622 14.4019C18.1273 13.5735 16.2925 14.0651 15.4641 15.5ZM16.7631 16.25C16.3489 16.9674 16.5947 17.8848 17.3122 18.299C18.0296 18.7132 18.947 18.4674 19.3612 17.75C19.7754 17.0326 19.5296 16.1152 18.8122 15.701C18.0947 15.2867 17.1774 15.5326 16.7631 16.25Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M3.33974 18.5C4.16817 19.9349 6.00294 20.4265 7.43782 19.5981C8.8727 18.7696 9.36432 16.9349 8.53589 15.5C7.70747 14.0651 5.8727 13.5735 4.43782 14.4019C3.00294 15.2303 2.51131 17.0651 3.33974 18.5ZM4.63878 17.75C5.05299 18.4674 5.97038 18.7132 6.68782 18.299C7.40526 17.8848 7.65107 16.9674 7.23686 16.25C6.82264 15.5326 5.90526 15.2867 5.18782 15.701C4.47038 16.1152 4.22457 17.0326 4.63878 17.75Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M18 11.5C20.7614 11.5 23 9.26142 23 6.5C23 3.73858 20.7614 1.5 18 1.5C15.2386 1.5 13 3.73858 13 6.5C13 9.26142 15.2386 11.5 18 11.5ZM18.75 4C18.75 3.58579 18.4142 3.25 18 3.25C17.5858 3.25 17.25 3.58579 17.25 4V5.75H15.5C15.0858 5.75 14.75 6.08579 14.75 6.5C14.75 6.91421 15.0858 7.25 15.5 7.25H17.25V9C17.25 9.41421 17.5858 9.75 18 9.75C18.4142 9.75 18.75 9.41421 18.75 9V7.25H20.5C20.9142 7.25 21.25 6.91421 21.25 6.5C21.25 6.08579 20.9142 5.75 20.5 5.75H18.75V4Z"
      fill={props.fill}
    />
  </svg>
);

Publish.defaultProps = {
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Publish;
