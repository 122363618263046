/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const ArrowLeft: React.FC<Props> = (props) => (
  <svg viewBox="0 0 24 24" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M12.5303 6.46967C12.2374 6.17678 11.7626 6.17678 11.4697 6.46967L5.93934 12L11.4697 17.5303C11.7626 17.8232 12.2374 17.8232 12.5303 17.5303C12.8232 17.2374 12.8232 16.7626 12.5303 16.4697L8.81066 12.75H17C17.4142 12.75 17.75 12.4142 17.75 12C17.75 11.5858 17.4142 11.25 17 11.25H8.81066L12.5303 7.53033C12.8232 7.23744 12.8232 6.76256 12.5303 6.46967Z"
      fill={props.fill}
    />
  </svg>
);

ArrowLeft.defaultProps = {
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default ArrowLeft;
