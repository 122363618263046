/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  cx?: any;
  cy?: any;
  r?: any;
  stroke?: any;
  strokeWidth?: any;
  strokeLinecap?: any;
  className?: string;
}

const AcausalElecCurrentsource: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <circle
      cx={props.cx}
      cy={props.cy}
      r={props.r}
      stroke={props.stroke}
      strokeWidth={props.strokeWidth}
    />
    <path
      d="M16 30L16 28"
      stroke={props.stroke}
      strokeWidth={props.strokeWidth}
      strokeLinecap={props.strokeLinecap}
    />
    <path
      d="M16 4L16 2"
      stroke={props.stroke}
      strokeWidth={props.strokeWidth}
      strokeLinecap={props.strokeLinecap}
    />
    <path
      d="M18.9245 21C18.8178 21 18.7485 20.9467 18.7165 20.84L18.1885 19.128C18.1672 19.0853 18.1405 19.064 18.1085 19.064H13.8845C13.8525 19.064 13.8258 19.0853 13.8045 19.128L13.2765 20.84C13.2445 20.9467 13.1752 21 13.0685 21H11.4685C11.4045 21 11.3565 20.984 11.3245 20.952C11.2925 20.9093 11.2872 20.8507 11.3085 20.776L14.7805 9.96C14.8125 9.85333 14.8818 9.8 14.9885 9.8H16.9885C17.0952 9.8 17.1645 9.85333 17.1965 9.96L20.6845 20.776C20.6952 20.7973 20.7005 20.824 20.7005 20.856C20.7005 20.952 20.6418 21 20.5245 21H18.9245ZM14.3005 17.512C14.2898 17.576 14.3112 17.608 14.3645 17.608H17.6125C17.6765 17.608 17.6978 17.576 17.6765 17.512L16.0285 12.152C16.0178 12.1093 16.0018 12.088 15.9805 12.088C15.9592 12.088 15.9432 12.1093 15.9325 12.152L14.3005 17.512Z"
      fill={props.fill}
    />
  </svg>
);

AcausalElecCurrentsource.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  cx: '16',
  cy: '16',
  r: '11.25',
  stroke: '#1D3131',
  strokeWidth: '1.5',
  strokeLinecap: 'round',
  className: '',
};

export default AcausalElecCurrentsource;
