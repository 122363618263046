import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface NodeToFocusOn {
  parentPath: string[];
  nodeId: string;
}

interface NavigationState {
  nodeToFocusOn?: NodeToFocusOn;
  recentlyFocused?: boolean;
}

const initialState: NavigationState = {};

// ? Could do away with this slice if the camera knew to wait for subdiagram load before focusing.
// Ofc, we don't have a way to wait for subdiagram load yet, but if we did...
const navigationSlice = createSlice({
  name: 'NavigationSlice',
  initialState,
  reducers: {
    resetNavigationState: () => initialState,

    requestFocusOnNode(state, action: PayloadAction<NodeToFocusOn>) {
      state.nodeToFocusOn = action.payload;
    },

    clearRequestToFocusOnNode(state) {
      state.nodeToFocusOn = undefined;
    },

    setRecentlyFocused(state, action: PayloadAction<boolean>) {
      state.recentlyFocused = action.payload;
    },
  },
});

export const navigationActions = navigationSlice.actions;

export default navigationSlice;
