// !!!! -- DO NOT MANUALLY EDIT -- !!!!
// This file was generated by something in scripts/generateDefaultBlockData.js
// If you want to change the contents of this file, it should be automated
//
// (You can edit this file locally, but it may not be checked into the codebase -
// PRs with a manual edit of this file are forbidden from being merged.)

import { computationBlockDefaults } from 'app/generated_blocks/computationBlockDefaults';
import type { ComputationBlockClass } from 'app/generated_types/ComputationBlockClass';
import deepmerge from 'deepmerge';

export const FilterDiscrete: ComputationBlockClass = deepmerge(
  computationBlockDefaults,
  {
    "base": {
      "name": "FilterDiscrete",
      "namespace": "core",
      "description": "A discrete implementation of either an FIR or IIR filter.",
      "help_url": "https://docs.collimator.ai/using-model-editors/block-library/discrete-blocks#fir-iir-filter",
      "keywords": [
        "filter",
        "fir",
        "iir"
      ],
      "supports_iterator": false
    },
    "modes": {},
    "ports": {
      "inputs": {
        "static": [
          {
            "name": "in_0"
          }
        ]
      },
      "outputs": {
        "static": [
          {
            "name": "out_0"
          }
        ]
      }
    },
    "parameter_definitions": [
      {
        "name": "filter_type",
        "data_type": "string",
        "default_value": "FIR",
        "description": "the type of filter to implement. FIR or IIR.",
        "allowed_values": [
          "FIR",
          "IIR"
        ]
      },
      {
        "name": "b_coefficients",
        "data_type": "any",
        "default_value": "[0.5, 0.5]",
        "description": "Used for FIR filter and feed forward leg of IIR filter."
      },
      {
        "name": "a_coefficients",
        "data_type": "any",
        "default_value": "[0.5, 0.5]",
        "description": "Used for feedback leg of IIR filter. Not used for FIR filter."
      }
    ]
  }
);
