/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  stroke?: any;
  strokeWidth?: any;
  className?: string;
}

const Hydraulic: React.FC<Props> = (props) => (
  <svg viewBox="0 0 8 8" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.25 5.14286C6.25 5.81823 6.01793 6.32816 5.65776 6.67118C5.29429 7.01735 4.74022 7.25 4 7.25C3.25978 7.25 2.70571 7.01735 2.34224 6.67118C1.98207 6.32816 1.75 5.81823 1.75 5.14286C1.75 4.46363 2.11829 3.62205 2.66851 2.77883C3.11241 2.09857 3.6243 1.49243 4 1.08045C4.3757 1.49243 4.8876 2.09857 5.33149 2.77883C5.88171 3.62205 6.25 4.46363 6.25 5.14286Z"
      stroke={props.stroke}
      strokeWidth={props.strokeWidth}
    />
  </svg>
);

Hydraulic.defaultProps = {
  width: '8',
  height: '8',
  viewBox: '0 0 8 8',
  fill: 'none',
  xmlns: 'http://www.w3.org/2000/svg',
  stroke: '#1D3131',
  strokeWidth: '1.5',
  className: '',
};

export default Hydraulic;
