import styled from '@emotion/styled/macro';
import { t } from '@lingui/macro';
import { useProject } from 'app/api/useProject';
import { useProjectActions } from 'app/api/useProjectActions';
import { useProjectItems } from 'app/api/useProjectItems';
import { useUserOption } from 'app/api/useUserOptions';
import React from 'react';
import { shallowEqual } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Breadcrumb from 'ui/common/Breadcrumb/Breadcrumb';
import Button from 'ui/common/Button/Button';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import { DropZone } from 'ui/common/DropZone';
import {
  ArrowRight,
  Project as ProjectIcon,
  Search,
} from 'ui/common/Icons/Standard';
import Input from 'ui/common/Input/Input';
import { ActionButtonContainer } from 'ui/common/Modal/Modal';
import {
  AppContentWithFooterWrapper,
  AppContentWrapper,
} from 'ui/common/layout/appLayout';
import {
  ProjectItemType,
  ProjectsDetailTableData,
} from 'ui/dashboard/dashboardTypes';
import InProgressFileWatcher from 'ui/dashboard/projectDetail/InProgressFileWatcher';
import { ProjectDetailHeader } from 'ui/dashboard/projectDetail/ProjectDetailHeader';
import { ProjectDetailTable } from 'ui/dashboard/projectDetail/ProjectDetailTable';
import DashboardLeftSidebar from 'ui/dashboard/sidebar/Sidebar';
import { ProjectDetailActionButtons } from './ProjectDetailActionButtons';
import { ProjectReadme } from './ProjectReadme';
import ProjectRightSidebar from './rightSidebar/ProjectRightSidebar';

export const ProjectDetailContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${(props) => props.theme.spacing.xlarge};
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  flex-grow: 1;
  background-color: ${(props) => props.theme.colors.grey[5]};
  pointer-events: auto;
`;

// TODO pull out as an action row container, and clean up the multiple Header/Head components.
// This type of search + buttons component is used both in the projects list and project detail pages since they're a table view.
export const ProjectDetailHeadButtonsContainer = styled(ActionButtonContainer)`
  display: flex;
  flex-direction: row;
  margin-bottom: ${({ theme }) => theme.spacing.large};
  width: 100%;
  height: 32px;

  button {
    height: 32px;
  }
`;

const SearchBoxWrapper = styled.div`
  width: 256px;
  max-width: 100%;

  input {
    height: 32px;
    font-size: ${({ theme }) => theme.typography.font.standard.size};
    padding: 0 32px;
    background: #f9fafa;
  }
  input::placeholder {
    color: #b6bbbb;
    opacity: 1;
  }

  svg {
    fill: #b6bbbb !important;
    position: relative;
    left: 2px;
  }

  &:hover {
    svg {
      fill: #5c6f70 !important;
    }

    input::placeholder {
      color: #5c6f70 !important;
      opacity: 0.8;
    }
  }

  svg.focused {
    fill: #082426 !important;
  }
  input.focused::placeholder {
    color: #082426 !important;
    opacity: 0.8 !important;
  }
`;

const shouldSeeProjectItem = (
  projectItem: ProjectsDetailTableData,
  searchString?: string,
) =>
  projectItem.path.toLowerCase().indexOf((searchString || '').toLowerCase()) >
  -1;

const getTopLevelFolders = (
  projectItems: ProjectsDetailTableData[],
  path: string,
): ProjectsDetailTableData[] => {
  const normalizedPath = !path || path.endsWith('/') ? path : `${path}/`;
  const visited = new Set<string>();
  return projectItems
    .filter((item) => item.path.startsWith(normalizedPath))
    .map((item) => {
      const relativePath = item.path.slice(normalizedPath.length);
      if (!relativePath.includes('/')) {
        return null;
      }
      const firstFolder = relativePath.split('/')[0];
      if (visited.has(firstFolder)) {
        return null;
      }
      visited.add(firstFolder);
      return {
        ...item,
        name: firstFolder,
        path: `${normalizedPath}${firstFolder}`,
        createdAt: '',
        updatedAt: '',
        type: ProjectItemType.FOLDER,
        isFolder: true,
      };
    })
    .filter(Boolean) as ProjectsDetailTableData[];
};

const getTopLevelFiles = (
  projectItems: ProjectsDetailTableData[],
  path: string,
): ProjectsDetailTableData[] => {
  const normalizedPath = !path || path.endsWith('/') ? path : `${path}/`;
  return projectItems.filter((item) => {
    const relativePath = item.path.slice(normalizedPath.length);
    return (
      item.path.startsWith(normalizedPath) &&
      !relativePath.includes('/') &&
      relativePath.length > 0
    );
  });
};

const ProjectDetail: React.FC = () => {
  const { project } = useProject();

  const [searchString, setSearchString] = React.useState('');

  const { projectItems, fileIdToWatch } = useProjectItems(project);
  const { createFile } = useProjectActions();
  const projectReadmeEnabled = useUserOption('projectReadmeEnabled');
  const allowUploadDirectories = useUserOption('allowUploadDirectories');
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(window.location.search);
  const path = queryParams.get('path') ?? '';

  const filteredProjectItems = React.useMemo(() => {
    if (!projectItems) {
      return [];
    }
    if (searchString) {
      return projectItems.filter((projectItem) => {
        if (path && !projectItem.path.startsWith(path)) {
          return false;
        }
        return shouldSeeProjectItem(projectItem, searchString);
      });
    }
    const topLevelFolders = getTopLevelFolders(projectItems, path);
    const topLevelFiles = getTopLevelFiles(projectItems, path);
    return topLevelFolders.concat(topLevelFiles);
  }, [path, projectItems, searchString]);

  const validFolders = React.useMemo(() => {
    const folders = projectItems
      .filter((item) => item.path.includes('/'))
      .map((item) => item.path.split('/').slice(0, -1).join('/'));
    return new Set(folders);
  }, [projectItems]);

  if (path && !validFolders.has(path)) {
    // FIXME: maybe allow empty folders?
    navigate(`/projects/${project?.uuid}`);
  }

  const [selectedItems, setSelectedItems] = React.useState<
    ProjectsDetailTableData[]
  >([]);
  const [selectionState, setSelectionState] = React.useState({});
  const updateRowsSelected = React.useCallback(
    (newSelectionState: Record<string, boolean>) => {
      if (shallowEqual(newSelectionState, selectionState)) {
        return;
      }
      setSelectionState(newSelectionState);
      setSelectedItems(
        Object.entries(newSelectionState)
          .filter(([uuid, isSelected]) => isSelected)
          .map(([uuid, isSelected]) =>
            filteredProjectItems.find((item) => item.uuid === uuid),
          )
          .filter(Boolean) as ProjectsDetailTableData[],
      );
    },
    [filteredProjectItems, selectionState],
  );

  const numSelected = React.useMemo(
    () =>
      Object.values(selectionState).reduce(
        (acc: number, val) => acc + Number(val),
        0,
      ),
    [selectionState],
  );

  const onFileDrop = React.useCallback(
    (files: File[]) => {
      if (!project?.uuid || !files) return;

      const needsOverwrite = files
        .filter((file) =>
          projectItems.some(
            (item) =>
              item.path === file.webkitRelativePath || item.path === file.name,
          ),
        )
        .map((file) => file.webkitRelativePath || file.name);

      const confirmMsg = t({
        id: 'projectDetail.projectItemList.overwriteFiles',
        message: 'Overwrite the following files?',
      });
      const overwrite =
        needsOverwrite.length > 0 &&
        window.confirm(`${confirmMsg}\n\n${needsOverwrite.join('\n')}`);

      if (needsOverwrite.length > 0 && !overwrite) return;

      for (const file of files) {
        createFile(
          {
            projectUuid: project.uuid,
            fileCreateRequest: {
              name: file.webkitRelativePath || file.name,
              content_type: file.type,
              size: file.size,
              processor: undefined,
              overwrite: needsOverwrite.length > 0 && overwrite,
            },
          },
          file,
        );
      }
    },
    [createFile, project, projectItems],
  );

  if (!project) {
    return null;
  }

  const hasReadme = projectItems.some(
    (item) =>
      item.path === 'README.md' && item.type === ProjectItemType.PROJECTFILE,
  );

  return (
    <AppContentWithFooterWrapper>
      <AppContentWrapper>
        <DashboardLeftSidebar />
        <ProjectDetailContent>
          <ProjectDetailHeader project={project} numSelected={numSelected} />
          <ProjectDetailHeadButtonsContainer>
            {numSelected === 0 && (
              <SearchBoxWrapper>
                <Input
                  hasBorder
                  noLeftIconColor
                  LeftIcon={Search}
                  placeholder={t({
                    id: 'projectDetail.projectItemList.projectItemPlaceholder',
                    message: 'Search',
                  })}
                  onChangeText={setSearchString}
                  value={searchString}
                  clearable
                />
              </SearchBoxWrapper>
            )}
            <ProjectDetailActionButtons
              selectedItems={selectedItems}
              numSelected={numSelected}
            />
          </ProjectDetailHeadButtonsContainer>
          {allowUploadDirectories && (
            <Breadcrumb stretch>
              <Button
                key={project.title}
                variant={ButtonVariants.SmallTertiary}
                onClick={() => navigate(`/projects/${project.uuid}`)}>
                <ProjectIcon />
                {project.title}
              </Button>
              {path &&
                path.split('/').map((folder, index, folders) => {
                  const subpath = folders.slice(0, index + 1).join('/');
                  return (
                    <Button
                      key={folder}
                      variant={ButtonVariants.SmallTertiary}
                      onClick={() =>
                        navigate(`/projects/${project.uuid}?path=${subpath}`)
                      }>
                      <ArrowRight />
                      {folder}
                    </Button>
                  );
                })}
            </Breadcrumb>
          )}
          <DropZone onDrop={onFileDrop} recurse={allowUploadDirectories}>
            <ProjectDetailTable
              projectId={project.uuid}
              projectItems={filteredProjectItems}
              getRowsSelected={updateRowsSelected}
              numSelected={numSelected}
              showFullPath={!!searchString}
            />
          </DropZone>
          {projectReadmeEnabled && hasReadme && (
            <ProjectReadme projectId={project.uuid} />
          )}
        </ProjectDetailContent>
        <ProjectRightSidebar project={project} />
      </AppContentWrapper>
      {fileIdToWatch && (
        <InProgressFileWatcher
          projectId={project.uuid}
          fileIdToWatch={fileIdToWatch}
        />
      )}
    </AppContentWithFooterWrapper>
  );
};

export default ProjectDetail;
