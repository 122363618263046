import styled from '@emotion/styled/macro';
import { t } from '@lingui/macro';
import React from 'react';
import Button from 'ui/common/Button/Button';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import {
  CloseButtonContainer,
  ModalInputGroup,
  ModalScrollContainer,
} from 'ui/common/Modal/Modal';
import { useModal } from 'ui/common/Modal/useModal';

const SystemPromptModalContainer = styled(ModalScrollContainer)`
  width: 400px;
`;

const DisplayGroup = styled(ModalInputGroup)`
  display: flex;
  flex-direction: column;
`;

interface Props {
  systemPrompt?: string;
  setSystemPrompt: (value: string) => void;
  tools?: string;
  setTools: (value: string) => void;
}

const SystemPromptModal: React.FC<Props> = ({
  systemPrompt,
  setSystemPrompt,
  tools,
  setTools,
}) => {
  const { closeModal } = useModal();

  return (
    <>
      <SystemPromptModalContainer>
        <DisplayGroup>
          {t`System prompt`}
          <textarea
            defaultValue={
              systemPrompt ?? localStorage.getItem('systemPrompt') ?? ''
            }
            onChange={(e) => setSystemPrompt(e.target.value)}
            rows={20}
            cols={400}
            placeholder="Default"
          />
        </DisplayGroup>
        <DisplayGroup>
          {t`GPT functions`}
          <textarea
            defaultValue={tools ?? localStorage.getItem('tools') ?? ''}
            onChange={(e) => setTools(e.target.value)}
            rows={20}
            cols={400}
            placeholder='Default. Type "[]" to disable.'
          />
        </DisplayGroup>
      </SystemPromptModalContainer>

      <CloseButtonContainer>
        <Button
          type="button"
          variant={ButtonVariants.LargePrimary}
          onClick={() => {
            localStorage.setItem('systemPrompt', systemPrompt ?? '');
            localStorage.setItem('tools', tools ?? '');
            closeModal();
          }}>
          {t({
            id: 'systemPromptModal.dialog.closeButton.label',
            message: `Apply`,
          })}
        </Button>
      </CloseButtonContainer>
    </>
  );
};

export default SystemPromptModal;
