import {
  GetSimulationProcessResultsReadByUuidApiArg,
  GetSimulationProcessResultsReadByUuidApiResponse,
} from 'app/apiGenerated/generatedApiTypes';
import { API_BASE_URL } from 'app/config/globalApplicationConfig';
import { parseToNdArray } from 'app/utils/npy';
import { LRUCache } from 'lru-cache';
import ndarray from 'ndarray';

// Store results data in a cache but outside of redux

export const DOWNSAMPLING_THRESHOLD = 5000;
export const DOWNSAMPLING_THRESHOLD_OUTSIDE_BOUNDS = 1000;

export interface SignalArrayData {
  signal: string;
  cacheKey: string;
  done?: boolean;
  array?: ndarray.NdArray;
  error?: any; // FIXME TODO
}

function createCache() {
  const options = {
    max: 100, // FIXME not good (too arbitrary)
    // TODO: maxSize with a buffer size calculation
  };

  return new LRUCache<string, any>(options);
}

const _GlobalCache = createCache();

function resultsCacheKey({
  simulationId,
  signal,
  from,
  to,
}: {
  simulationId: string;
  signal: string;
  from?: number;
  to?: number;
}): string {
  if (from !== undefined) {
    return `${simulationId}:${signal}:${from}:${to}`;
  }
  return `${simulationId}:${signal}`;
}

async function callProcessResultsApi({
  modelUuid,
  simulationUuid,
  signalNames,
  combinedVectors,
  downsamplingAlgorithm,
  threshold,
  thresholdOutsideBounds,
  finalValues,
  fromTime,
  toTime,
  genCharts,
}: GetSimulationProcessResultsReadByUuidApiArg): Promise<GetSimulationProcessResultsReadByUuidApiResponse> {
  if (!signalNames) {
    throw new Error('No signals to process');
  }

  const api = `models/${modelUuid}/simulations/${simulationUuid}/process_results`;
  let url = `${API_BASE_URL}/${api}?signal_names=${signalNames}`;
  if (combinedVectors) url += `&combined_vectors=${combinedVectors}`;
  if (downsamplingAlgorithm)
    url += `&downsampling_algorithm=${downsamplingAlgorithm}`;
  if (threshold) url += `&threshold=${threshold}`;
  if (finalValues) url += `&final_values=${finalValues}`;
  if (fromTime) url += `&from_time=${fromTime}`; // undefined same as 0
  if (toTime) url += `&to_time=${toTime}`; // undefined same as -1
  if (genCharts) url += `&gen_charts=${genCharts}`;
  if (thresholdOutsideBounds)
    url += `&threshold_outside_bounds=${thresholdOutsideBounds}`;

  const response = await fetch(url, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  });

  if (!response.ok) {
    throw new Error('Failed to trigger signal data processing');
  }

  // FIXME: proper error handling (including with contents of API result)
  const resp: GetSimulationProcessResultsReadByUuidApiResponse =
    await response.json();

  return resp;
}

// FIXME: the code is not pretty, but somewhat functional
// TODO: implement zoom
// TODO: when zooming, no need to fetch new data if we already have it all
export async function loadSimulationResults({
  modelId,
  signal,
  simulationId,
  from,
  to,
  threshold = DOWNSAMPLING_THRESHOLD,
  thresholdOutsideBounds,
}: {
  modelId: string;
  signal: string;
  simulationId: string;
  from?: number;
  to?: number;
  threshold: number;
  thresholdOutsideBounds?: number;
}): Promise<SignalArrayData> {
  const cache = _GlobalCache;
  const cacheKey = resultsCacheKey({ simulationId, signal, from, to });
  let cachedResults = cache.get(cacheKey);

  if (cachedResults) {
    return { cacheKey, signal, ...cachedResults };
  }

  let processResultsPromise:
    | Promise<GetSimulationProcessResultsReadByUuidApiResponse>
    | undefined = cache.get(`processing:${cacheKey}`);
  if (!processResultsPromise) {
    processResultsPromise = callProcessResultsApi({
      modelUuid: modelId,
      simulationUuid: simulationId,
      signalNames: signal,
      combinedVectors: true,
      fromTime: from,
      toTime: to,
      threshold,
      thresholdOutsideBounds,
      // downsamplingAlgorithm
      // finalValues
    });
    cache.set(`processing:${cacheKey}`, processResultsPromise);
  }

  let processedResultsURL: string;
  try {
    // NOTE: we only expect one URL since we only ever ask for a single signal (here)
    const resp = await processResultsPromise;
    if (!resp.s3_urls || !resp.s3_urls[0]?.url || resp.s3_urls[0]?.error) {
      throw new Error(
        resp.s3_urls[0]?.error ?? 'Failed to download downsampled results',
      );
    }
    processedResultsURL = resp.s3_urls[0].url;
  } catch (error) {
    cachedResults = { error };
    cache.set(cacheKey, cachedResults);
    return { cacheKey, signal, ...cachedResults };
  } finally {
    cache.delete(`processing:${cacheKey}`);
  }

  let signalsDataPromise: Promise<ndarray.NdArray> | undefined = cache.get(
    `fetching:${cacheKey}`,
  );
  if (!signalsDataPromise) {
    signalsDataPromise = fetch(processedResultsURL)
      .then((response) => response.arrayBuffer())
      .then(parseToNdArray);
    cache.set(`fetching:${cacheKey}`, signalsDataPromise);
  }

  try {
    const array = await signalsDataPromise;
    cachedResults = { array };
    cache.set(cacheKey, cachedResults);
    return { cacheKey, signal, ...cachedResults };
  } catch (error) {
    cachedResults = { error };
    cache.set(cacheKey, cachedResults);
    return { cacheKey, signal, ...cachedResults };
  } finally {
    cache.delete(`fetching:${cacheKey}`);
  }
}
