import { GetSubmodelsListForSubmodelParentApiResponse } from 'app/apiGenerated/generatedApiTypes';
import {
  convertAPISubmodelInfoToSubmodelInfoUI,
  SubmodelInfoUI,
} from 'app/apiTransformers/convertGetSubmodelsListForModelParent';
import { sortSubmodels } from 'app/apiTransformers/convertGetSubmodelsList';

export function convertGetSubmodelsListForSubmodelParent(
  apiResponse: GetSubmodelsListForSubmodelParentApiResponse,
): SubmodelInfoUI[] {
  const submodels = apiResponse.submodels?.map(
    convertAPISubmodelInfoToSubmodelInfoUI,
  );
  return sortSubmodels(submodels);
}
