/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  className?: string;
}

const Constant: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.1042 27.5C10.8908 27.5 10.7842 27.3933 10.7842 27.18V5.42001C10.7842 5.20667 10.8908 5.10001 11.1042 5.10001H12.1602C12.3735 5.10001 12.4802 5.20667 12.4802 5.42001V19.084C12.4802 19.1267 12.4908 19.1587 12.5122 19.18C12.5548 19.2013 12.5975 19.1907 12.6402 19.148L20.0322 11.532C20.1388 11.4253 20.2668 11.372 20.4162 11.372H21.6962C21.8242 11.372 21.8988 11.4147 21.9202 11.5C21.9628 11.564 21.9415 11.6493 21.8562 11.756L17.7922 16.108C17.7495 16.1507 17.7388 16.204 17.7602 16.268L22.6882 27.116L22.7202 27.244C22.7202 27.4147 22.6242 27.5 22.4322 27.5H21.3122C21.1415 27.5 21.0242 27.4147 20.9602 27.244L16.6082 17.42C16.5655 17.3347 16.5122 17.324 16.4482 17.388L12.5442 21.42C12.5015 21.4627 12.4802 21.516 12.4802 21.58V27.18C12.4802 27.3933 12.3735 27.5 12.1602 27.5H11.1042Z"
      fill={props.fill}
    />
  </svg>
);

Constant.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  className: '',
};

export default Constant;
