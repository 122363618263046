import { PortSide } from 'app/common_types/PortTypes';
import {
  LinkInstance,
  NodeInstance,
  SubmodelsSection,
} from 'app/generated_types/SimulationModel';
import { nodeTypeIsSubdiagram } from 'app/helpers';
import {
  findLocalSubmodelParentBlock,
  getNestedNode,
} from 'app/utils/modelDiagramUtils';
import { canAddPort } from 'app/utils/portOperationUtils';

export interface NodeLocation {
  parentPath: string[];
  nodeId: string;
}

export interface PortLocation extends NodeLocation {
  portIndex: number;
}

/**
 * It goes through the submodel tree, extracting the names of the different layers and combining them as a string
 */
export function getSubmodelPathName(
  topLevelNodes: NodeInstance[],
  submodels: SubmodelsSection,
  parentPath: string[],
) {
  const result = [];
  if (parentPath) {
    const parentPathOfParent: string[] = [];
    for (let i = 0; i < parentPath.length; i++) {
      const parentNodeInstanceId = parentPath[i];
      const node = getNestedNode(
        topLevelNodes,
        submodels,
        parentPathOfParent,
        parentNodeInstanceId,
      );
      if (node) {
        result.push(node?.name || '');
      }
      parentPathOfParent.push(parentNodeInstanceId);
    }
  }
  return result.join('.');
}

export function getPortRecordMode(
  topLevelNodes: NodeInstance[],
  submodels: SubmodelsSection,
  nodeLocation: PortLocation,
) {
  if (!topLevelNodes || !submodels || !nodeLocation) {
    // "" is a valid path for signal data. It is the diagram itself.
    return null;
  }

  const { nodeId, portIndex, parentPath } = nodeLocation;
  const currentNode = getNestedNode(
    topLevelNodes,
    submodels,
    parentPath,
    nodeId,
  );

  if (
    currentNode &&
    currentNode.outputs.length > 0 &&
    currentNode.outputs[portIndex]
  ) {
    const port = currentNode.outputs[portIndex];
    return !!port.record;
  }
  return null;
}

export function getNodePathName(
  topLevelNodes: NodeInstance[],
  submodels: SubmodelsSection,
  nodeLocation: NodeLocation,
) {
  if (!topLevelNodes || !submodels || !nodeLocation) return null;

  const { nodeId, parentPath } = nodeLocation;
  const pathName = getSubmodelPathName(topLevelNodes, submodels, parentPath);
  const currentNode = getNestedNode(
    topLevelNodes,
    submodels,
    parentPath,
    nodeId,
  );
  const blockName = currentNode?.name;
  return [pathName, blockName].filter((n) => n).join('.');
}

/**
 * It builds the block ID, used to request the S3 URL containing the CSV data of the signal.
 * @returns a string in the form of `<submodel_path>.<block>.<outport>`
 *
 * includePortNameForNonSubmodels: FIXME unclear whether we need this
 */
export function getPortPathName(
  topLevelNodes: NodeInstance[],
  submodels: SubmodelsSection,
  portLocation: PortLocation,
  options: {
    includePortNameForNonSubmodels?: boolean;
  },
) {
  if (!topLevelNodes || !submodels || !portLocation) {
    // "" is a valid path for signal data. It is the diagram itself.
    return null;
  }

  const { nodeId, portIndex, parentPath } = portLocation;
  const pathName = getSubmodelPathName(topLevelNodes, submodels, parentPath);
  let blockName = '';
  let outportName = '';
  const currentNode = getNestedNode(
    topLevelNodes,
    submodels,
    parentPath,
    nodeId,
  );
  if (currentNode) {
    if (currentNode?.type === 'core.Outport') {
      let parentNode = findLocalSubmodelParentBlock(
        topLevelNodes,
        submodels,
        nodeId,
      );
      if (parentNode) {
        blockName = `${parentNode.name}.`;
      }
    }
    blockName += currentNode.name;
    if (currentNode.outputs.length > 0 && currentNode.outputs[portIndex]) {
      outportName = currentNode.outputs[portIndex].name;
    }
  }

  if (
    options.includePortNameForNonSubmodels ||
    (currentNode && nodeTypeIsSubdiagram(currentNode.type))
  ) {
    return [pathName, blockName, outportName].filter((n) => n).join('.');
  }

  return [pathName, blockName].filter((n) => n).join('.');
}

/**
 * User-friendly name for the chart
 */
export function getLegendName(
  nodeDetail: PortLocation,
  topLevelNodes: NodeInstance[],
  submodels: SubmodelsSection,
  topLevelLinks: LinkInstance[],
) {
  const { nodeId, portIndex, parentPath } = nodeDetail;
  const pathName = getSubmodelPathName(topLevelNodes, submodels, parentPath);
  let fullBlockName = '';
  let outportName = '';
  const currentNode = getNestedNode(
    topLevelNodes,
    submodels,
    parentPath,
    nodeId,
  );
  fullBlockName = currentNode?.name || '';
  if (currentNode && currentNode.outputs.length > 0) {
    const link = topLevelLinks.find(
      (l) => l?.src?.node === currentNode.uuid && l.src?.port === portIndex,
    );
    if (link?.name) {
      outportName = link.name;
      fullBlockName = '';
    } else if (
      canAddPort(currentNode, PortSide.Output) ||
      currentNode.outputs.length > 1
    ) {
      outportName = currentNode.outputs[portIndex]?.name;
    }
  }
  return [pathName, fullBlockName, outportName].filter((n) => n).join('.');
}
