import React from 'react';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import type { AppDispatch, RootState } from './store';

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = (): AppDispatch => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export function useOnScreen(
  ref: React.RefObject<Element>,
  rootMargin = '0px',
): boolean {
  // State and setter for storing whether element is visible
  const [isIntersecting, setIntersecting] = React.useState(false);
  React.useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        // Update our state when observer callback fires
        setIntersecting(entry.isIntersecting);
      },
      {
        rootMargin,
      },
    );
    const currentRef = ref.current;
    if (currentRef) {
      observer.observe(currentRef);
    }
    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, [ref, rootMargin]); // Empty array ensures that effect is only run on mount and unmount
  return isIntersecting;
}

export function useOutsideClick<T extends HTMLElement>(
  ref: React.RefObject<T>,
  fn: () => void,
  active = true,
  triggerEl?: HTMLElement,
): void {
  React.useEffect(() => {
    if (!active) return;
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: MouseEvent) {
      const isClickOutsideElement =
        ref.current && !ref.current.contains(event.target as HTMLElement);
      const isClickOutsideTriggerElement = !triggerEl?.contains(
        event.target as HTMLElement,
      );

      if (
        isClickOutsideElement &&
        (!triggerEl || isClickOutsideTriggerElement)
      ) {
        fn();
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, fn, active, triggerEl]);
}

function getSize(el?: HTMLElement | null) {
  if (!el) {
    return {
      width: 0,
      height: 0,
    };
  }

  return {
    width: el.offsetWidth,
    height: el.offsetHeight,
  };
}

export function useComponentSize(ref?: React.RefObject<HTMLElement>) {
  const [ComponentSize, setComponentSize] = React.useState(
    getSize(ref?.current),
  );

  const handleResize = React.useCallback(() => {
    if (ref?.current) {
      setComponentSize(getSize(ref.current));
    }
  }, [ref]);

  React.useLayoutEffect(() => {
    if (!ref?.current) {
      return;
    }

    handleResize();

    if (typeof ResizeObserver === 'function') {
      const resizeObserver = new ResizeObserver(handleResize);
      resizeObserver.observe(ref.current);

      return function () {
        resizeObserver.disconnect();
      };
    }
    window.addEventListener('resize', handleResize);

    return function () {
      window.removeEventListener('resize', handleResize);
    };
  }, [ref, handleResize]);

  return ComponentSize;
}

export function useInterval(callback: () => any, ms = 100) {
  const [value, setValue] = React.useState<any>(null);
  React.useEffect(() => {
    const interval = () => {
      const v = callback();
      setValue(v);
    };
    const clear = setInterval(interval, ms);
    if (value) {
      clearInterval(clear);
    }
    return () => clearInterval(clear);
  }, [callback, ms, value]);
  return value;
}

function useDebounce<T>(value: T, delay?: number): T {
  const [debouncedValue, setDebouncedValue] = React.useState<T>(value);

  React.useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(value), delay ?? 500);

    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);

  return debouncedValue;
}

export default useDebounce;
