/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  className?: string;
}

const Discretederivative: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21.0765 5.60001C21.0205 5.60001 20.9605 5.61201 20.8965 5.63601L19.6005 6.28401C19.5365 6.31601 19.5045 6.37201 19.5045 6.45201L19.5405 7.24401C19.5405 7.28401 19.5485 7.31601 19.5645 7.34001C19.5885 7.36401 19.6165 7.37601 19.6485 7.37601C19.6725 7.37601 19.6925 7.37201 19.7085 7.36401L20.9085 6.99201C20.9245 6.98401 20.9405 6.98801 20.9565 7.00401C20.9725 7.01201 20.9805 7.02401 20.9805 7.04001V13.856C20.9805 13.896 20.9925 13.932 21.0165 13.964C21.0485 13.988 21.0845 14 21.1245 14H22.2285C22.2685 14 22.3005 13.988 22.3245 13.964C22.3565 13.932 22.3725 13.896 22.3725 13.856V5.74401C22.3725 5.70401 22.3565 5.67201 22.3245 5.64801C22.3005 5.61601 22.2685 5.60001 22.2285 5.60001H21.0765Z"
      fill={props.fill}
    />
    <path
      d="M9.144 14C9.104 14 9.068 13.988 9.036 13.964C9.012 13.932 9 13.896 9 13.856V12.836C9 12.764 9.02 12.708 9.06 12.668L12.012 9.20001C12.052 9.15201 12.04 9.12801 11.976 9.12801H9.204C9.164 9.12801 9.128 9.11601 9.096 9.09201C9.072 9.06001 9.06 9.02401 9.06 8.98401V8.01201C9.06 7.97201 9.072 7.94001 9.096 7.91601C9.128 7.88401 9.164 7.86801 9.204 7.86801H13.572C13.612 7.86801 13.644 7.88401 13.668 7.91601C13.7 7.94001 13.716 7.97201 13.716 8.01201V9.03201C13.716 9.09601 13.692 9.15201 13.644 9.20001L10.632 12.668C10.6 12.716 10.612 12.74 10.668 12.74H13.608C13.648 12.74 13.68 12.756 13.704 12.788C13.736 12.812 13.752 12.844 13.752 12.884V13.856C13.752 13.896 13.736 13.932 13.704 13.964C13.68 13.988 13.648 14 13.608 14H9.144Z"
      fill={props.fill}
    />
    <path
      d="M14.6529 10.988C14.6129 10.988 14.5769 10.976 14.5449 10.952C14.5209 10.92 14.5089 10.884 14.5089 10.844V9.93201C14.5089 9.89201 14.5209 9.86001 14.5449 9.83601C14.5769 9.80401 14.6129 9.78801 14.6529 9.78801H18.4329C18.4729 9.78801 18.5049 9.80401 18.5289 9.83601C18.5609 9.86001 18.5769 9.89201 18.5769 9.93201V10.844C18.5769 10.884 18.5609 10.92 18.5289 10.952C18.5049 10.976 18.4729 10.988 18.4329 10.988H14.6529Z"
      fill={props.fill}
    />
    <path
      d="M7.25 16C7.25 15.5858 7.58579 15.25 8 15.25H24C24.4142 15.25 24.75 15.5858 24.75 16C24.75 16.4142 24.4142 16.75 24 16.75H8C7.58579 16.75 7.25 16.4142 7.25 16Z"
      fill={props.fill}
    />
    <path
      d="M15.988 18C16.028 18 16.06 18.016 16.084 18.048C16.116 18.072 16.132 18.104 16.132 18.144V19.068C16.132 19.108 16.116 19.144 16.084 19.176C16.06 19.2 16.028 19.212 15.988 19.212H13.78C13.74 19.212 13.72 19.232 13.72 19.272V26.256C13.72 26.296 13.704 26.332 13.672 26.364C13.648 26.388 13.616 26.4 13.576 26.4H12.472C12.432 26.4 12.396 26.388 12.364 26.364C12.34 26.332 12.328 26.296 12.328 26.256V19.272C12.328 19.232 12.308 19.212 12.268 19.212H10.144C10.104 19.212 10.068 19.2 10.036 19.176C10.012 19.144 10 19.108 10 19.068V18.144C10 18.104 10.012 18.072 10.036 18.048C10.068 18.016 10.104 18 10.144 18H15.988Z"
      fill={props.fill}
    />
    <path
      d="M17.0212 26.4C16.9812 26.4 16.9452 26.388 16.9132 26.364C16.8892 26.332 16.8772 26.296 16.8772 26.256V25.236C16.8772 25.164 16.8972 25.108 16.9372 25.068L19.8892 21.6C19.9292 21.552 19.9172 21.528 19.8532 21.528H17.0812C17.0412 21.528 17.0052 21.516 16.9732 21.492C16.9492 21.46 16.9372 21.424 16.9372 21.384V20.412C16.9372 20.372 16.9492 20.34 16.9732 20.316C17.0052 20.284 17.0412 20.268 17.0812 20.268H21.4492C21.4892 20.268 21.5212 20.284 21.5452 20.316C21.5772 20.34 21.5932 20.372 21.5932 20.412V21.432C21.5932 21.496 21.5692 21.552 21.5212 21.6L18.5092 25.068C18.4772 25.116 18.4892 25.14 18.5452 25.14H21.4852C21.5252 25.14 21.5572 25.156 21.5812 25.188C21.6132 25.212 21.6292 25.244 21.6292 25.284V26.256C21.6292 26.296 21.6132 26.332 21.5812 26.364C21.5572 26.388 21.5252 26.4 21.4852 26.4H17.0212Z"
      fill={props.fill}
    />
  </svg>
);

Discretederivative.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  className: '',
};

export default Discretederivative;
