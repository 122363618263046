/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Checked: React.FC<Props> = (props) => (
  <svg viewBox="0 0 24 24" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.5857 9.46852C16.8444 9.14507 16.792 8.67311 16.4685 8.41435C16.1451 8.15559 15.6731 8.20803 15.4143 8.53148L11.0775 13.9525L8.46173 11.909C8.13532 11.654 7.66399 11.7119 7.40898 12.0383C7.15397 12.3647 7.21186 12.836 7.53827 13.091L10.3488 15.2867C10.8884 15.7083 11.6667 15.6173 12.0944 15.0826L16.5857 9.46852Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12ZM19 12C19 15.866 15.866 19 12 19C8.13401 19 5 15.866 5 12C5 8.13401 8.13401 5 12 5C15.866 5 19 8.13401 19 12Z"
      fill={props.fill}
    />
  </svg>
);

Checked.defaultProps = {
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Checked;
