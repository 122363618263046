import { useTheme } from '@emotion/react';
import { t } from '@lingui/macro';
import React from 'react';
import Button from 'ui/common/Button/Button';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import {
  ActionButtonContainer,
  ModalMessageContainer,
  ModalMessageText,
  SmallFormContainer,
} from 'ui/common/Modal/Modal';
import { useModal } from 'ui/common/Modal/useModal';

interface Props {
  message: string;
  onDelete: () => void;
}

const DeleteConfirmationModal: React.FC<Props> = ({ onDelete, message }) => {
  const { closeModal } = useModal();
  const theme = useTheme();
  return (
    <SmallFormContainer
      onSubmit={(e) => {
        e?.preventDefault();
        onDelete();
        closeModal();
      }}>
      <ModalMessageContainer data-test-id="delete-project-modal-message">
        <ModalMessageText>{message}</ModalMessageText>
      </ModalMessageContainer>

      <ActionButtonContainer>
        {/* Cancel button */}
        <Button
          type="button"
          onClick={closeModal}
          variant={ButtonVariants.LargeSecondary}
          testId="delete-project-modal-cancel-button">
          {t({
            id: 'deleteProjectModal.cancelButton.label',
            message: 'Cancel',
          })}
        </Button>

        {/* Delete button */}
        <Button
          type="submit"
          variant={ButtonVariants.LargePrimary}
          tint={theme.colors.ui.error}
          testId="delete-project-modal-delete-button">
          {t({
            id: 'deleteProjectModal.deleteButton.label',
            message: 'Delete',
          })}
        </Button>
      </ActionButtonContainer>
    </SmallFormContainer>
  );
};

export default DeleteConfirmationModal;
