import Menu from 'ui/common/Menu/Menu';
import { ItemType } from 'ui/common/Menu/menuItemTypes';

import styled from '@emotion/styled/macro';
import { useAppSelector } from 'app/hooks';
import { forwardRef } from 'react';

const TraceColorDragGhost = styled.div<{ color: string | undefined }>`
  display: block;
  width: 24px;
  height: 14px;
  background-color: ${({ color }) => color};
`;

const TraceDragger = styled.div<{ show: boolean }>`
  ${({ show }) => `
  display: ${show ? 'block' : 'none'};
  position: absolute;
  z-index: 2;
  `}
`;

type Props = {
  // Need this so that the position can be set before it is shown.
  show: boolean;
};

const TraceDragPreview = forwardRef<HTMLDivElement, Props>(
  ({ show }: Props, ref) => {
    const traceDragSourceCandidate = useAppSelector(
      (state) => state.traceDrag.traceDragSourceCandidate,
    );

    return (
      <TraceDragger ref={ref} show={show}>
        <Menu
          items={[
            {
              type: ItemType.Button,
              content: (
                <>
                  {traceDragSourceCandidate?.displayName}
                  <TraceColorDragGhost
                    color={traceDragSourceCandidate?.color}
                  />
                </>
              ),
            },
          ]}
          startOpen
        />
      </TraceDragger>
    );
  },
);

export default TraceDragPreview;
