/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  className?: string;
}

const Loopbreak: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.5303 4.46967C18.2374 4.17678 17.7626 4.17678 17.4697 4.46967C17.1768 4.76256 17.1768 5.23744 17.4697 5.53033L21.4393 9.5L17.4697 13.4697C17.1768 13.7626 17.1768 14.2374 17.4697 14.5303C17.7626 14.8232 18.2374 14.8232 18.5303 14.5303L22.5 10.5607L26.4697 14.5303C26.7626 14.8232 27.2374 14.8232 27.5303 14.5303C27.8232 14.2374 27.8232 13.7626 27.5303 13.4697L23.5607 9.5L27.5303 5.53033C27.8232 5.23744 27.8232 4.76256 27.5303 4.46967C27.2374 4.17678 26.7626 4.17678 26.4697 4.46967L22.5 8.43934L18.5303 4.46967Z"
      fill={props.fill}
    />
    <path
      d="M18.568 8.5C18.6343 8.5 18.6979 8.52634 18.7448 8.57322L19.4948 9.32322C19.5924 9.42085 19.5924 9.57915 19.4948 9.67678L19.2448 9.92678C19.1979 9.97366 19.1343 10 19.068 10H6.25C6.11193 10 6 10.1119 6 10.25V25.75C6 25.8881 6.11193 26 6.25 26H9.75C10.1642 26 10.5 26.3358 10.5 26.75C10.5 27.1642 10.1642 27.5 9.75 27.5H6.25C5.2835 27.5 4.5 26.7165 4.5 25.75V10.25C4.5 9.2835 5.2835 8.5 6.25 8.5H18.568Z"
      fill={props.fill}
    />
    <path
      d="M21.75 13.182C21.75 13.1157 21.7763 13.0521 21.8232 13.0052L22.3232 12.5052C22.4209 12.4076 22.5791 12.4076 22.6768 12.5052L23.1768 13.0052C23.2237 13.0521 23.25 13.1157 23.25 13.182V19.4091L25.4811 17.271C25.7801 16.9844 26.2549 16.9945 26.5415 17.2936C26.8281 17.5926 26.818 18.0674 26.5189 18.354L22.5 22.2055L18.4811 18.354C18.182 18.0674 18.1719 17.5926 18.4585 17.2936C18.7451 16.9945 19.2199 16.9844 19.5189 17.271L21.75 19.4091V13.182Z"
      fill={props.fill}
    />
    <path
      d="M13.25 26C12.8358 26 12.5 26.3358 12.5 26.75C12.5 27.1642 12.8358 27.5 13.25 27.5H15.75C16.1642 27.5 16.5 27.1642 16.5 26.75C16.5 26.3358 16.1642 26 15.75 26H13.25Z"
      fill={props.fill}
    />
    <path
      d="M19.25 26C18.8358 26 18.5 26.3358 18.5 26.75C18.5 27.1642 18.8358 27.5 19.25 27.5H21.75C22.1642 27.5 22.5 27.1642 22.5 26.75C22.5 26.3358 22.1642 26 21.75 26H19.25Z"
      fill={props.fill}
    />
  </svg>
);

Loopbreak.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  className: '',
};

export default Loopbreak;
