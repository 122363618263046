import { BlockClassName } from '@collimator/model-schemas-ts';
import styled from '@emotion/styled/macro';
import { t } from '@lingui/macro';
import Ajv, { Schema } from 'ajv';
import draft6MetaSchema from 'ajv/dist/refs/json-schema-draft-06.json';
import {
  OnRunOptimizationCompleteArgs,
  SimworkerJobRunnerProvider,
  useSimworkerJobRunner,
} from 'app/SimworkerJobRunner';
import {
  OptimizationPartialRequest,
  OptimizationRequest,
} from 'app/api/custom_types/optimizations';
import { useLiveLogs } from 'app/api/useLiveLogs';
import { useModelSimulationRequests } from 'app/api/useModelSimulationRequests';
import { JobSummary } from 'app/apiGenerated/generatedApiTypes';
import {
  OptimalParameterJson as OptimalParameter,
  OptimizationResultsJson as OptimizationResults,
} from 'app/generated_types/collimator/dashboard/serialization/ui_types.gen';
import optimizationRequestSchema from 'app/generated_types/schemas/optimization_request.schema.json';
import optimizationResultsSchema from 'app/generated_types/schemas/optimization_results.schema.json';
import { nodeTypeIsReferencedSubmodel } from 'app/helpers';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { ModelState } from 'app/modelState/ModelState';
import {
  entityPreferencesActions,
  selectEntityPrefs,
} from 'app/slices/entityPreferencesSlice';
import { modelActions } from 'app/slices/modelSlice';
import { ModelLogLine } from 'app/slices/simResultsSlice';
import { ModelParameters } from 'app/utils/modelDataUtils';
import { getNestedNode } from 'app/utils/modelDiagramUtils';
import React from 'react';
import Button from 'ui/common/Button/Button';
import TooltipButton from 'ui/common/Button/TooltipButton';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import { BackArrow, Reset, Save, Tune } from 'ui/common/Icons/Standard';
import { useModal } from 'ui/common/Modal/useModal';
import { Spinner } from 'ui/common/Spinner';
import { TooltipPlacement } from 'ui/common/Tooltip/tooltipTypes';
import { useNotifications } from 'ui/common/notifications/useNotifications';
import {
  defaultOptimizerModalPrefs,
  OPTIMIZER_MODAL_PREFS_V1_KEY,
  OptimizerModalSavedPrefs,
  TabName,
} from 'ui/userPreferences/optimizerModalPrefs';
import { parseSimulationLogs } from '../utils';
import DesignOptimizationContent from './DesignOptimizationContent';
import OptimizationLiveContent from './OptimizationLiveContent';
import OptimizationResultsContent from './OptimizationResultsContent';
import PIDTuningContent from './PIDTuningContent';
import ParamEstimationContent from './ParamEstimationContent';
import { TabSelector } from './TabSelector';
import {
  OptimizationContentProps,
  readCsvAsMetricsJson,
  scanNodesAndSignals,
} from './optimizerModalUtils';

// Note: max height is set to show just the top of the advanced settings but
// truncated, as a cue for the user to scroll down.
const ParentContainer = styled.div`
  display: flex;
  height: max(min(726px, calc(100vh - 150px)), 200px);
`;

const ModelOptimizerContainer = styled.div`
  width: 640px;
  padding: 0 1px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;

  ${({ theme }) => `
    p {
      padding: 0 ${theme.spacing.small};
      font-size: ${theme.typography.font.standard.size};
      line-height: ${theme.typography.font.standard.lineHeight};
    }
  `}
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  // padding: 0 ${({ theme }) => theme.spacing.normal};
  flex: 1;

  overflow-x: hidden;
  overflow-y: auto;
`;

const OptimizeButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: ${({ theme }) => theme.spacing.normal};

  & > button {
    margin-left: ${({ theme }) => theme.spacing.normal};
  }
`;

const ModelOptimizerNavContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing.normal};

  > * {
    flex: 1;
  }

  & > *:nth-child(2) {
    flex: 0;
    flex-basis: 480px;
  }
`;

const ModelOptimizerNavSpacer = styled.div`
  height: 1px;
  background: ${({ theme }) => theme.colors.grey[10]};
`;

const TabDescription = styled.p`
  margin-left: ${({ theme }) => theme.spacing.normal};
  margin-right: ${({ theme }) => theme.spacing.normal};
  margin-top: ${({ theme }) => theme.spacing.small};
  margin-bottom: ${({ theme }) => theme.spacing.small};
  font-size: ${({ theme }) => theme.typography.font.standard.size};
  line-height: ${({ theme }) => theme.typography.font.standard.lineHeight};
`;

const Spacer = styled.div`
  flex: 1;
`;

export const ajvInstance: Ajv = new Ajv({ meta: draft6MetaSchema });
const ajvValidateResults = ajvInstance.compile(
  optimizationResultsSchema as Schema,
);
const ajvValidateRequest = ajvInstance.compile(
  optimizationRequestSchema as Schema,
);

const tabOptions = [
  { value: 'design', label: 'Design optimization' },
  { value: 'estimation', label: 'Parameter estimation' },
  { value: 'pid', label: 'PID tuning' },
];
const tabHeaderText: Record<TabName, string> = {
  design: `Optimize model performance by varying design parameter values using
    either a global or local search method. Optionally vary other parameters
    stochastically to improve solution robustness.`,
  estimation: `Optimize model parameter values to best match recorded data. Select a
    time series data file to use as a reference signal for the optimization.`,
  pid: `Optimize the parameters of a PID controller block. Select a PID block
      and reference signal, and the PID coefficients will be generated to best
      track the reference.`,
};

const OptimizerModalContent: React.FC<{
  initPrefs: OptimizerModalSavedPrefs;
}> = ({ initPrefs }) => {
  const dispatch = useAppDispatch();
  const { showError, showInfo } = useNotifications();
  const { closeModal } = useModal();
  const { setDisableCloseControls } = useModal();

  const modelState: ModelState = useAppSelector((state) => state.model.present);
  const modelUuid = useAppSelector(
    (state) => state.modelMetadata.loadedModelId,
  );

  const updatedPrefs: OptimizerModalSavedPrefs | undefined = useAppSelector(
    (state) =>
      selectEntityPrefs(state, OPTIMIZER_MODAL_PREFS_V1_KEY, modelUuid),
  );

  const savedEntityPrefs =
    updatedPrefs && Object.keys(updatedPrefs).length > 0
      ? updatedPrefs
      : initPrefs;
  const { isRunning, runOptimization } = useSimworkerJobRunner();
  const abortController = React.useRef(new AbortController());
  const abortSignal = abortController.current.signal;

  const [validRequest, setValidRequest] =
    React.useState<OptimizationRequest | null>(null);
  const [invalidReason, setInvalidReason] = React.useState<
    string | undefined
  >();

  const [results, setResults] = React.useState<
    OptimizationResults | undefined
  >();
  const [resultError, setResultError] = React.useState<string | undefined>();
  const [logs, setLogs] = React.useState<ModelLogLine[] | undefined>();
  const [jobSummary, setJobSummary] = React.useState<JobSummary | undefined>();

  const selectedTab = React.useMemo(
    () =>
      savedEntityPrefs.selectedTab ? savedEntityPrefs.selectedTab : 'design',
    [savedEntityPrefs],
  );

  const setSelectedTab = React.useCallback(
    (tab: TabName) => {
      if (tab === selectedTab) return;
      dispatch(
        entityPreferencesActions.onUserUpdatedOptimizerModalPrefs({
          modelId: modelUuid,
          selectedTab: tab,
          prefs: savedEntityPrefs[tab],
        }),
      );
    },
    [selectedTab, dispatch, modelUuid, savedEntityPrefs],
  );

  const nodeTypes: Array<BlockClassName> = React.useMemo(
    () =>
      selectedTab === 'pid'
        ? ['core.PID', 'core.PID_Discrete']
        : selectedTab === 'estimation'
        ? ['core.ReferenceSubmodel']
        : [],
    [selectedTab],
  );

  const { nodeOptions, signalOptions } = React.useMemo(() => {
    const { nodeOptions, signalOptions } = scanNodesAndSignals(
      modelState,
      nodeTypes,
    );
    return {
      nodeOptions: nodeOptions.map((op) => ({ value: op, label: op })),
      signalOptions: signalOptions.map((op) => ({
        value: op.signalPathName,
        label: op.signalPathName,
      })),
    };
  }, [modelState, nodeTypes]);

  const Content: React.FC<OptimizationContentProps> = {
    design: DesignOptimizationContent,
    estimation: ParamEstimationContent,
    pid: PIDTuningContent,
  }[selectedTab];

  const onUpdate = React.useCallback(
    (updatedRequest: OptimizationPartialRequest) => {
      if (
        JSON.stringify(updatedRequest) ===
        JSON.stringify(savedEntityPrefs[selectedTab])
      )
        return;
      dispatch(
        entityPreferencesActions.onUserUpdatedOptimizerModalPrefs({
          modelId: modelUuid,
          selectedTab,
          prefs: updatedRequest,
        }),
      );
    },
    [dispatch, modelUuid, savedEntityPrefs, selectedTab],
  );

  const onComplete = React.useCallback(
    ({
      optimalParameters: optimal_parameters,
      metrics,
      error,
      logs,
    }: OnRunOptimizationCompleteArgs) => {
      if (error) showError(t`Failed to optimize model`);

      let results: OptimizationResults | undefined;
      if (ajvValidateResults({ optimal_parameters, metrics })) {
        results = { optimal_parameters, metrics } as OptimizationResults;
      } else {
        console.error(
          'Got invalid response from optimization backend',
          ajvInstance.errorsText(ajvValidateResults.errors),
        );
      }

      setResults(results);
      setLogs(logs);
      setResultError(error);
    },
    [showError, setResults, setLogs, setResultError],
  );

  const onRun = React.useCallback(
    (request: OptimizationRequest) => {
      if (!ajvValidateRequest(request)) {
        // eslint-disable-next-line
        console.warn(
          'Trying to run a potentially invalid optimization request:',
          ajvInstance.errorsText(ajvValidateRequest.errors),
        );
        // return; // Keep going... we'll see what's up in backend logs.
      }

      runOptimization(request, onComplete, abortSignal, setJobSummary);
    },
    [onComplete, runOptimization, abortSignal],
  );

  const onBack = React.useCallback(() => {
    setResultError(undefined);
    setResults(undefined);
    setLogs(undefined);
    setJobSummary(undefined);
  }, [setResults, setResultError, setLogs, setJobSummary]);

  // Reset prefs
  const onReset = React.useCallback(() => {
    onBack();
    dispatch(
      entityPreferencesActions.onUserUpdatedOptimizerModalPrefs({
        modelId: modelUuid,
        selectedTab,
        prefs: defaultOptimizerModalPrefs[selectedTab],
      }),
    );
  }, [onBack, dispatch, modelUuid, selectedTab]);

  // Cancel running job
  const { stopSimulation } = useModelSimulationRequests();
  const cancelJob = React.useCallback(
    (job: JobSummary) => {
      stopSimulation(job.uuid, job.uuid);
    },
    [stopSimulation],
  );

  const onAbort = React.useCallback(() => {
    abortController.current.abort();
    abortController.current = new AbortController();

    if (jobSummary) cancelJob(jobSummary);
    setResultError('Optimization was canceled');
  }, [jobSummary, cancelJob]);

  // Poll logs_short and show them asap
  const { data: logsShortRaw } = useLiveLogs({
    logFile: 'logs_short.txt',
    jobId: jobSummary?.uuid,
    skip: !isRunning,
  });
  const logsShort = React.useMemo(
    () => parseSimulationLogs(logsShortRaw),
    [logsShortRaw],
  );

  // Poll metrics file (CSV because it's an append-only file in simworker)
  const { data: metricsFile } = useLiveLogs({
    logFile: 'metrics.csv',
    jobId: jobSummary?.uuid,
    skip: !isRunning,
  });
  const metricsData = React.useMemo(
    () => readCsvAsMetricsJson(metricsFile),
    [metricsFile],
  );

  // Apply parameters
  const modelParams = useAppSelector((state) => state.model.present.parameters);
  const modelNodes = useAppSelector(
    (state) => state.model.present.rootModel.nodes,
  );
  const subdiagrams = useAppSelector((state) => state.model.present.submodels);

  const paramIndex = (params: ModelParameters, paramName: string) =>
    params.findIndex((param) => param.name === paramName);

  const applySingleParameter = React.useCallback(
    (param: OptimalParameter): boolean => {
      const value = param.optimal_value;

      if (param.block_uuidpath) {
        const nodeUuid = param.block_uuidpath[param.block_uuidpath.length - 1];
        const parentPath = param.block_uuidpath.slice(0, -1);
        const paramName = param.param_name;

        // Check node exists
        const node = getNestedNode(
          modelNodes,
          subdiagrams,
          parentPath,
          nodeUuid,
        );
        if (!node) return false;

        if (nodeTypeIsReferencedSubmodel(node?.type)) {
          dispatch(
            modelActions.updateNodeExtraParameter({
              parentPath,
              nodeUuid: node.uuid,
              paramName,
              value,
            }),
          );
        } else {
          dispatch(
            modelActions.changeBlockParameter({
              parentPath,
              nodeUuid,
              paramName,
              value,
            }),
          );
        }
        return true;
      }

      const index = paramIndex(modelParams, param.param_name);
      if (index !== -1) {
        dispatch(modelActions.setModelParameterValue({ index, value }));
        return true;
      }

      return false;
    },
    [dispatch, modelParams, modelNodes, subdiagrams],
  );

  const applyParameters = React.useCallback(
    (params: OptimalParameter[]) => {
      const notFoundParams: string[] = [];

      for (const param of params) {
        if (!applySingleParameter(param)) {
          notFoundParams.push(param.param_name);
        }
      }

      const extraMsg =
        notFoundParams.length > 0
          ? `, but the following were not found: ${notFoundParams.join(', ')}`
          : '';
      showInfo(`Model updated with the optimal parameters${extraMsg}`);
      closeModal();
    },
    [applySingleParameter, showInfo, closeModal],
  );

  // Prevent modal from closing while running
  React.useEffect(
    () => setDisableCloseControls(isRunning),
    [setDisableCloseControls, isRunning],
  );

  const onUpdateValidRequest = React.useCallback(
    (validRequest: OptimizationRequest | null, invalidReason?: string) => {
      setValidRequest(validRequest);
      setInvalidReason(invalidReason);
    },
    [setValidRequest, setInvalidReason],
  );

  const isReady = !isRunning && validRequest;
  const isCompleted = !!results || !!resultError;

  return (
    <ModelOptimizerContainer>
      <ModelOptimizerNavContainer>
        <ModelOptimizerNavSpacer />
        <TabSelector
          value={selectedTab}
          options={tabOptions}
          onSelect={(tab) => setSelectedTab(tab as TabName)}
          disabled={isRunning || isCompleted}
        />
        <ModelOptimizerNavSpacer />
      </ModelOptimizerNavContainer>
      <TabDescription>{tabHeaderText[selectedTab]}</TabDescription>

      <ContentContainer>
        {isCompleted ? (
          <OptimizationResultsContent
            results={results}
            metrics={metricsData}
            logs={logs?.length ? logs : logsShort || []}
            onApplySingleParameter={(param) => {
              if (applySingleParameter(param)) showInfo('Parameter updated');
              else showError('Failed to update parameter value in the model');
            }}
          />
        ) : isRunning ? (
          <OptimizationLiveContent
            logs={logsShort || []}
            metrics={metricsData}
          />
        ) : (
          <Content
            request={savedEntityPrefs[selectedTab]}
            nodeOptions={nodeOptions}
            signalOptions={signalOptions}
            onUpdate={onUpdate}
            onUpdateValidRequest={onUpdateValidRequest}
          />
        )}
      </ContentContainer>

      <OptimizeButtonContainer>
        {isCompleted ? (
          <Button
            variant={ButtonVariants.LargeSecondary}
            Icon={BackArrow}
            onClick={onBack}>
            Back
          </Button>
        ) : !isRunning ? (
          <Button
            variant={ButtonVariants.LargeSecondary}
            Icon={Reset}
            disabled={isRunning}
            onClick={onReset}>
            Reset
          </Button>
        ) : null}
        <Spacer />
        {isCompleted ? (
          <Button
            variant={ButtonVariants.LargePrimary}
            Icon={Save}
            disabled={!results?.optimal_parameters}
            onClick={() => {
              if (results?.optimal_parameters)
                applyParameters(results.optimal_parameters);
            }}>
            Apply all and Close
          </Button>
        ) : isRunning ? (
          <Button
            variant={ButtonVariants.Danger}
            Icon={Spinner}
            onClick={onAbort}>
            Cancel
          </Button>
        ) : invalidReason ? (
          <TooltipButton
            variant={ButtonVariants.LargePrimary}
            Icon={Tune}
            disabled={!isReady && !isRunning}
            onClick={() => {
              if (isReady) onRun(validRequest);
            }}
            tooltip={invalidReason}
            placement={TooltipPlacement.TOP_LEFT}>
            Optimize
          </TooltipButton>
        ) : (
          <Button
            variant={ButtonVariants.LargePrimary}
            Icon={Tune}
            disabled={!isReady && !isRunning}
            onClick={() => {
              if (isReady) onRun(validRequest);
            }}>
            Optimize
          </Button>
        )}
      </OptimizeButtonContainer>
    </ModelOptimizerContainer>
  );
};

export const OptimizerModal: React.FC<{
  initPrefs: OptimizerModalSavedPrefs;
}> = ({ initPrefs }) => (
  <SimworkerJobRunnerProvider>
    <ParentContainer>
      <OptimizerModalContent initPrefs={initPrefs} />
    </ParentContainer>
  </SimworkerJobRunnerProvider>
);
