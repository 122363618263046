export const isValidBranchName = (
  branchName: string,
  existingBranchNames?: string[],
) => {
  if (!branchName) return false;

  // Check valid names. This is probably not exhaustive.
  const regex = /^[a-zA-Z0-9-_][a-zA-Z0-9-_.\/]*$/;
  if (!regex.test(branchName)) return false;
  if (branchName.indexOf('..') !== -1) return false;
  if (branchName.indexOf('//') !== -1) return false;
  if (branchName.startsWith('-')) return false;
  if (branchName.endsWith('.lock')) return false;
  if (branchName.endsWith('/')) return false;
  if (branchName.startsWith('refs/')) return false;

  // Prevent branch name conflicts
  if (
    existingBranchNames &&
    existingBranchNames.find((branch) => branchName.startsWith(`${branch}/`))
  )
    return false;

  return true;
};
