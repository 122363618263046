import { useProjectActions } from 'app/api/useProjectActions';
import React from 'react';
import { t } from '@lingui/macro';
import Button from 'ui/common/Button/Button';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import Archive from 'ui/common/Icons/Standard/Archive';
import { useNavigate } from 'react-router-dom';
import { useModal } from 'ui/common/Modal/useModal';
import DeleteConfirmationModal from '../projectsList/DeleteConfirmationModal';

type Props = {
  selectionState: Record<string, boolean>;
  numSelected: number;
  navigateToProjects?: boolean;
};

export const DeleteProjectButton: React.FC<Props> = ({
  selectionState,
  numSelected,
  navigateToProjects,
}: Props) => {
  const navigate = useNavigate();
  const { triggerModal, closeModal } = useModal();
  const { deleteProject } = useProjectActions();

  const onDeleteProject = async () => {
    const deleteProjectPromises = await Promise.all(
      Object.keys(selectionState).map((projectUuid) =>
        selectionState[projectUuid]
          ? deleteProject(projectUuid)
          : new Promise((resolve): void => {
              resolve(true);
            }),
      ),
    );
    if (
      deleteProjectPromises.every((promise) => promise) &&
      navigateToProjects
    ) {
      navigate('/projects');
    }
    closeModal();
  };

  const deleteModal = () => {
    triggerModal(
      <DeleteConfirmationModal
        onDelete={onDeleteProject}
        message={t({
          id: 'deleteProjectModal.messageContent.text',
          message: `Are you sure you want to delete ${
            numSelected > 1 ? `${numSelected} projects` : 'this project'
          }? All files and settings will be removed.`,
        })}
      />,
      t({
        id: 'deleteProjectModal.messageTitle.text',
        message: 'Delete Project?',
      }),
    );
  };

  return (
    <Button
      Icon={Archive}
      variant={ButtonVariants.LargeSecondary}
      onClick={deleteModal}
      testId="project-detail-delete-project-button"
      hasRedText>
      {t({
        id: 'dashboard.projects.deleteProjectButton.Label',
        message: 'Delete Project',
      })}
    </Button>
  );
};
