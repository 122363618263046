// !!!! -- DO NOT MANUALLY EDIT -- !!!!
// This file was generated by something in scripts/generateDefaultBlockData.js
// If you want to change the contents of this file, it should be automated
//
// (You can edit this file locally, but it may not be checked into the codebase -
// PRs with a manual edit of this file are forbidden from being merged.)

import { computationBlockDefaults } from 'app/generated_blocks/computationBlockDefaults';
import type { ComputationBlockClass } from 'app/generated_types/ComputationBlockClass';
import deepmerge from 'deepmerge';

export const BatteryCell: ComputationBlockClass = deepmerge(
  computationBlockDefaults,
  {
    "base": {
      "name": "BatteryCell",
      "namespace": "core",
      "description": "Single cell of a chemical battery like a Lithium-Ion, Lithium-Polymer, NiCad, NiMH, or Lead-Acid.",
      "help_url": "https://docs.collimator.ai/using-model-editors/block-library/continuous-blocks#battery-cell",
      "supports_iterator": false
    },
    "modes": {
      "time": "agnostic"
    },
    "ports": {
      "inputs": {
        "static": [
          {
            "name": "current"
          }
        ]
      },
      "outputs": {
        "static": [
          {
            "name": "voltage"
          },
          {
            "name": "SOC"
          }
        ]
      }
    },
    "parameter_definitions": [
      {
        "name": "E0",
        "data_type": "float",
        "default_value": "3.366",
        "description": "Constant Voltage (V)."
      },
      {
        "name": "K",
        "data_type": "float",
        "default_value": "0.0076",
        "description": "Polarization Constant (V/Ah)."
      },
      {
        "name": "Q",
        "data_type": "float",
        "default_value": "2.3",
        "description": "cell capacity (Ah)."
      },
      {
        "name": "R",
        "data_type": "float",
        "default_value": "0.01",
        "description": "Internal Resistance (Ohms). Must be negative even though the parameter is positive, compiler expects the negative of the parameter value."
      },
      {
        "name": "A",
        "data_type": "float",
        "default_value": "0.2642",
        "description": "Exponential zone amplitude (V)."
      },
      {
        "name": "B",
        "data_type": "float",
        "default_value": "26.5487",
        "description": "Exponential zone time constant inverse (1/Ah). Must be negative even though the parameter is positive, compiler expects the negative of the parameter value. "
      },
      {
        "name": "intial_SOC",
        "data_type": "float",
        "default_value": "1.0",
        "description": "Initial value of the Depth of Discharge."
      }
    ]
  }
);
