import { useAppDispatch, useAppSelector } from 'app/hooks';
import { modelVersionsActions } from 'app/slices/modelVersionsSlice';
import React from 'react';
import { NodeResult } from 'util/modelVersionNode';
import { buildNodeInfoForModelVersion } from 'util/modelVersionSignal';
import { TraceMetadata } from '../dataExplorerTypes';

/**
 * Loader that notices when a signal path exists in the data explorer where model version
 * data isn't loaded and makes sure that data gets requested.
 */

// @jp: FIXME: remove this.
// Not useful, overly complex and likely broken in lots of cases (submodels don't
// even have simulation-specific snapshots anyway).
// Why do we even need this? We really could just have static metadata
// about signals/traces and not need to load models & submodels. If we need it.
// This makes no sense.
export const DataExplorerSignalTraceLoader: React.FC = () => {
  const dispatch = useAppDispatch();

  const idToTrace = useAppSelector((state) => state.dataExplorer.idToTrace);

  const modelIdToVersionIdToModelData = useAppSelector(
    (state) => state.modelVersions.modelIdToVersionIdToModelData,
  );

  const simulationIdToModelVersionId = useAppSelector(
    (state) => state.simulationSignals.simulationIdToModelVersionId,
  );

  const idToVersionIdToSubmodelFull = useAppSelector(
    (state) => state.submodels.idToVersionIdToSubmodelFull,
  );

  // If there are signal paths that don't have their full data loaded, make sure it gets loaded.
  React.useEffect(() => {
    Object.values(idToTrace).forEach((trace: TraceMetadata) => {
      if (!trace.explorationSimId) {
        return;
      }

      const modelVersionId =
        simulationIdToModelVersionId[trace.explorationSimId];
      if (!modelVersionId) {
        return;
      }

      const { modelVersionToRequest }: NodeResult =
        buildNodeInfoForModelVersion(
          trace.signalPath,
          trace.modelId,
          modelVersionId,
          modelIdToVersionIdToModelData,
        );

      if (modelVersionToRequest) {
        dispatch(
          modelVersionsActions.requestModelVersion(modelVersionToRequest),
        );
      }
    });
  }, [
    dispatch,
    idToTrace,
    modelIdToVersionIdToModelData,
    simulationIdToModelVersionId,
    idToVersionIdToSubmodelFull,
  ]);

  return null;
};
