/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const DiscreteinitializerInitialstatetrue: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M26.594 5.5C26.6273 5.5 26.654 5.51333 26.674 5.54C26.7007 5.56 26.714 5.58667 26.714 5.62V6.39C26.714 6.42333 26.7007 6.45333 26.674 6.48C26.654 6.5 26.6273 6.51 26.594 6.51H24.754C24.7207 6.51 24.704 6.52667 24.704 6.56V12.38C24.704 12.4133 24.6907 12.4433 24.664 12.47C24.644 12.49 24.6173 12.5 24.584 12.5H23.664C23.6307 12.5 23.6007 12.49 23.574 12.47C23.554 12.4433 23.544 12.4133 23.544 12.38V6.56C23.544 6.52667 23.5273 6.51 23.494 6.51H21.724C21.6907 6.51 21.6607 6.5 21.634 6.48C21.614 6.45333 21.604 6.42333 21.604 6.39V5.62C21.604 5.58667 21.614 5.56 21.634 5.54C21.6607 5.51333 21.6907 5.5 21.724 5.5H26.594Z"
      fill={props.fill}
    />
    <path
      d="M14.7899 8.97C14.7566 8.97 14.7266 8.96 14.6999 8.94C14.6799 8.91333 14.6699 8.88333 14.6699 8.85V8.09C14.6699 8.05667 14.6799 8.03 14.6999 8.01C14.7266 7.98333 14.7566 7.97 14.7899 7.97H18.8099C18.8433 7.97 18.8699 7.98333 18.8899 8.01C18.9166 8.03 18.9299 8.05667 18.9299 8.09V8.85C18.9299 8.88333 18.9166 8.91333 18.8899 8.94C18.8699 8.96 18.8433 8.97 18.8099 8.97H14.7899Z"
      fill={props.fill}
    />
    <path
      d="M14.7899 10.95C14.7566 10.95 14.7266 10.94 14.6999 10.92C14.6799 10.8933 14.6699 10.8633 14.6699 10.83V10.07C14.6699 10.0367 14.6799 10.01 14.6999 9.99C14.7266 9.96333 14.7566 9.95 14.7899 9.95H18.8099C18.8433 9.95 18.8699 9.96333 18.8899 9.99C18.9166 10.01 18.9299 10.0367 18.9299 10.07V10.83C18.9299 10.8633 18.9166 10.8933 18.8899 10.92C18.8699 10.94 18.8433 10.95 18.8099 10.95H14.7899Z"
      fill={props.fill}
    />
    <path
      d="M8.26953 8.3C8.2962 8.27334 8.30953 8.24334 8.30953 8.21V7.51C8.30953 7.47667 8.2962 7.45 8.26953 7.43C8.24953 7.40334 8.22286 7.39 8.18953 7.39H7.16953C7.1362 7.39 7.11953 7.37334 7.11953 7.34V6.17C7.11953 6.13667 7.1062 6.11 7.07953 6.09C7.05953 6.06334 7.03286 6.05 6.99953 6.05H6.12953C6.0962 6.05 6.0662 6.06334 6.03953 6.09C6.01953 6.11 6.00953 6.13667 6.00953 6.17V7.34C6.00953 7.37334 5.99286 7.39 5.95953 7.39H5.38953C5.3562 7.39 5.3262 7.40334 5.29953 7.43C5.27953 7.45 5.26953 7.47667 5.26953 7.51V8.21C5.26953 8.24334 5.27953 8.27334 5.29953 8.3C5.3262 8.32 5.3562 8.33 5.38953 8.33H5.95953C5.99286 8.33 6.00953 8.34667 6.00953 8.38V11.23C6.0162 11.73 6.14953 12.07 6.40953 12.25C6.66953 12.43 7.06286 12.52 7.58953 12.52L8.10953 12.51C8.18953 12.4967 8.22953 12.4533 8.22953 12.38V11.63C8.22953 11.5967 8.2162 11.57 8.18953 11.55C8.16953 11.5233 8.14286 11.51 8.10953 11.51H7.80953C7.56953 11.51 7.39286 11.4533 7.27953 11.34C7.17286 11.2267 7.11953 11.0433 7.11953 10.79V8.38C7.11953 8.34667 7.1362 8.33 7.16953 8.33H8.18953C8.22286 8.33 8.24953 8.32 8.26953 8.3Z"
      fill={props.fill}
    />
    <path
      d="M8.26953 20.8C8.2962 20.7733 8.30953 20.7433 8.30953 20.71V20.01C8.30953 19.9767 8.2962 19.95 8.26953 19.93C8.24953 19.9033 8.22286 19.89 8.18953 19.89H7.16953C7.1362 19.89 7.11953 19.8733 7.11953 19.84V18.67C7.11953 18.6367 7.1062 18.61 7.07953 18.59C7.05953 18.5633 7.03286 18.55 6.99953 18.55H6.12953C6.0962 18.55 6.0662 18.5633 6.03953 18.59C6.01953 18.61 6.00953 18.6367 6.00953 18.67V19.84C6.00953 19.8733 5.99286 19.89 5.95953 19.89H5.38953C5.3562 19.89 5.3262 19.9033 5.29953 19.93C5.27953 19.95 5.26953 19.9767 5.26953 20.01V20.71C5.26953 20.7433 5.27953 20.7733 5.29953 20.8C5.3262 20.82 5.3562 20.83 5.38953 20.83H5.95953C5.99286 20.83 6.00953 20.8467 6.00953 20.88V23.73C6.0162 24.23 6.14953 24.57 6.40953 24.75C6.66953 24.93 7.06286 25.02 7.58953 25.02L8.10953 25.01C8.18953 24.9967 8.22953 24.9533 8.22953 24.88V24.13C8.22953 24.0967 8.2162 24.07 8.18953 24.05C8.16953 24.0233 8.14286 24.01 8.10953 24.01H7.80953C7.56953 24.01 7.39286 23.9533 7.27953 23.84C7.17286 23.7267 7.11953 23.5433 7.11953 23.29V20.88C7.11953 20.8467 7.1362 20.83 7.16953 20.83H8.18953C8.22286 20.83 8.24953 20.82 8.26953 20.8Z"
      fill={props.fill}
    />
    <path
      d="M26.684 18.97C26.7107 18.9433 26.724 18.9133 26.724 18.88V18.12C26.724 18.0867 26.7107 18.06 26.684 18.04C26.664 18.0133 26.6373 18 26.604 18H22.114C22.0807 18 22.0507 18.0133 22.024 18.04C22.004 18.06 21.994 18.0867 21.994 18.12V24.88C21.994 24.9133 22.004 24.9433 22.024 24.97C22.0507 24.99 22.0807 25 22.114 25H23.034C23.0673 25 23.094 24.99 23.114 24.97C23.1407 24.9433 23.154 24.9133 23.154 24.88V22.02C23.154 21.9867 23.1707 21.97 23.204 21.97H25.494C25.5273 21.97 25.554 21.96 25.574 21.94C25.6007 21.9133 25.614 21.8833 25.614 21.85V21.09C25.614 21.0567 25.6007 21.03 25.574 21.01C25.554 20.9833 25.5273 20.97 25.494 20.97H23.204C23.1707 20.97 23.154 20.9533 23.154 20.92V19.05C23.154 19.0167 23.1707 19 23.204 19H26.604C26.6373 19 26.664 18.99 26.684 18.97Z"
      fill={props.fill}
    />
    <path
      d="M14.6999 21.44C14.7266 21.46 14.7566 21.47 14.7899 21.47H18.8099C18.8433 21.47 18.8699 21.46 18.8899 21.44C18.9166 21.4133 18.9299 21.3833 18.9299 21.35V20.59C18.9299 20.5567 18.9166 20.53 18.8899 20.51C18.8699 20.4833 18.8433 20.47 18.8099 20.47H14.7899C14.7566 20.47 14.7266 20.4833 14.6999 20.51C14.6799 20.53 14.6699 20.5567 14.6699 20.59V21.35C14.6699 21.3833 14.6799 21.4133 14.6999 21.44Z"
      fill={props.fill}
    />
    <path
      d="M14.6999 23.42C14.7266 23.44 14.7566 23.45 14.7899 23.45H18.8099C18.8433 23.45 18.8699 23.44 18.8899 23.42C18.9166 23.3933 18.9299 23.3633 18.9299 23.33V22.57C18.9299 22.5367 18.9166 22.51 18.8899 22.49C18.8699 22.4633 18.8433 22.45 18.8099 22.45H14.7899C14.7566 22.45 14.7266 22.4633 14.6999 22.49C14.6799 22.51 14.6699 22.5367 14.6699 22.57V23.33C14.6699 23.3633 14.6799 23.3933 14.6999 23.42Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M10.3426 14.066C9.92261 14.066 9.58861 13.948 9.34061 13.712C9.09661 13.472 8.97461 13.15 8.97461 12.746V11.054C8.97461 10.654 9.09661 10.338 9.34061 10.106C9.58861 9.87 9.92261 9.752 10.3426 9.752C10.7626 9.752 11.0966 9.87 11.3446 10.106C11.5966 10.338 11.7226 10.654 11.7226 11.054V12.746C11.7226 13.15 11.5966 13.472 11.3446 13.712C11.0966 13.948 10.7626 14.066 10.3426 14.066ZM10.3426 13.46C10.5506 13.46 10.7166 13.4 10.8406 13.28C10.9646 13.156 11.0266 12.99 11.0266 12.782V11.03C11.0266 10.822 10.9646 10.658 10.8406 10.538C10.7166 10.414 10.5506 10.352 10.3426 10.352C10.1386 10.352 9.97461 10.414 9.85061 10.538C9.73061 10.658 9.67061 10.822 9.67061 11.03V12.782C9.67061 12.99 9.73061 13.156 9.85061 13.28C9.97461 13.4 10.1386 13.46 10.3426 13.46Z"
      fill={props.fill}
    />
    <path
      d="M11.7413 22.324C11.7292 22.336 11.7233 22.352 11.7233 22.372L11.7292 25.168C11.7292 25.184 11.7253 25.194 11.7172 25.198C11.7092 25.198 11.7013 25.192 11.6933 25.18L9.97125 22.348C9.95525 22.316 9.92725 22.3 9.88725 22.3H9.35325C9.33325 22.3 9.31525 22.308 9.29925 22.324C9.28725 22.336 9.28125 22.352 9.28125 22.372V26.428C9.28125 26.448 9.28725 26.466 9.29925 26.482C9.31525 26.494 9.33325 26.5 9.35325 26.5H9.90525C9.92525 26.5 9.94125 26.494 9.95325 26.482C9.96925 26.466 9.97725 26.448 9.97725 26.428L9.96525 23.632C9.96525 23.616 9.96925 23.608 9.97725 23.608C9.98525 23.604 9.99325 23.608 10.0012 23.62L11.7292 26.452C11.7453 26.484 11.7733 26.5 11.8133 26.5H12.3472C12.3673 26.5 12.3833 26.494 12.3953 26.482C12.4113 26.466 12.4193 26.448 12.4193 26.428V22.372C12.4193 22.352 12.4113 22.336 12.3953 22.324C12.3833 22.308 12.3673 22.3 12.3472 22.3H11.7952C11.7753 22.3 11.7572 22.308 11.7413 22.324Z"
      fill={props.fill}
    />
  </svg>
);

DiscreteinitializerInitialstatetrue.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default DiscreteinitializerInitialstatetrue;
