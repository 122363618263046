import { t } from '@lingui/macro';
import { useProjects } from 'app/api/useProjects';
import { usePostProjectCopyByUuidMutation } from 'app/apiGenerated/generatedApi';
import { PostProjectCopyByUuidApiArg } from 'app/apiGenerated/generatedApiTypes';
import { Project } from 'app/apiTransformers/convertAPIProjectToProject';
import { useAppSelector } from 'app/hooks';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from 'ui/common/Button/Button';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import Input from 'ui/common/Input/Input';
import { isRequiredRule } from 'ui/common/Input/inputValidation';
import Label from 'ui/common/Label';
import {
  ActionButtonContainer,
  ModalInputGroup,
  SmallFormContainer,
} from 'ui/common/Modal/Modal';
import { useModal } from 'ui/common/Modal/useModal';
import { useNotifications } from 'ui/common/notifications/useNotifications';

interface Props {
  project: Project;
}

const findNewTitle = (title: string, myProjectTitles: string[]) => {
  let newTitle = title;
  let i = 1;
  while (myProjectTitles.includes(newTitle)) {
    newTitle = `${title} (${i})`;
    i++;
  }
  return newTitle;
};

const CopyPublicProjectModal: React.FC<Props> = ({ project }) => {
  const navigate = useNavigate();
  const { createShowError } = useNotifications();
  const { projects } = useProjects();
  const { userId } = useAppSelector((state) => state.user);

  const { closeModal } = useModal();

  const [isValid, setIsValid] = React.useState(false);
  const [name, setName] = React.useState('');

  const myProjectTitles = React.useMemo(
    () =>
      projects
        ?.filter((p: Project) => p.ownerUuid === userId)
        ?.map((p) => p.title) || [],
    [projects, userId],
  );

  React.useEffect(() => {
    setName(findNewTitle(project.title, myProjectTitles));
  }, [project, myProjectTitles]);

  React.useEffect(() => {
    if (name.length === 0) {
      setIsValid(false);
      return;
    }
    setIsValid(!myProjectTitles.includes(name));
  }, [name, myProjectTitles]);

  // I expose this so that I can use the built in loading state.
  // The biggest reason we have `useProjectActions` wrapped is the custom cache invalidation.
  const [copyProject, { isLoading: isCopyingProject }] =
    usePostProjectCopyByUuidMutation();

  const wrappedCopyProject = (request: PostProjectCopyByUuidApiArg) =>
    copyProject(request)
      .unwrap()
      .then((payload) => payload.uuid)
      .catch(
        createShowError(
          t({
            id: 'projectApi.duplicateProjectError',
            message: 'Unable to duplicate project.',
          }),
        ),
      );

  const onCreateModel = async () => {
    if (!isValid) return;
    const newProjectId = await wrappedCopyProject({
      projectUuid: project.uuid,
      projectCreateRequest: {
        title: name,
        description: project.description,
      },
    });
    if (newProjectId) {
      navigate(`/projects/${newProjectId}`);
    }
    closeModal();
  };

  return (
    <SmallFormContainer
      onSubmit={(e) => {
        e?.preventDefault();
        onCreateModel();
      }}>
      <ModalInputGroup>
        <Label testId="duplicate-name-label">
          {t({
            id: 'copyPublicProjectModal.nameInput.label',
            message: 'Give your project a unique name',
          })}
        </Label>
        <Input
          value={name}
          onChangeText={(v, isValid) => {
            setName(v);
            setIsValid(isValid);
          }}
          hasBorder
          testId="project-name-textbox"
          autoFocus
          validationRules={[isRequiredRule]}
        />
      </ModalInputGroup>
      <ActionButtonContainer>
        <Button
          type="button"
          onClick={closeModal}
          variant={ButtonVariants.LargeSecondary}
          testId="cancel-copy-public-project-button">
          {t({
            id: 'copyPublicProjectModal.cancelButton.label',
            message: 'Cancel',
          })}
        </Button>

        <Button
          type="submit"
          variant={ButtonVariants.LargePrimary}
          testId="copy-public-project-button"
          loading={isCopyingProject}
          disabled={!isValid}>
          {t({
            id: 'copyPublicProjectModal.copyButton.label',
            message: 'Copy',
          })}
        </Button>
      </ActionButtonContainer>
    </SmallFormContainer>
  );
};

export default CopyPublicProjectModal;
