import React from 'react';
import Breadcrumb from 'ui/common/Breadcrumb/Breadcrumb';
import BreadcrumbLink from 'ui/common/Breadcrumb/BreadcrumbLink';
import { SignalExplorer } from 'ui/common/Icons/Small';

interface Props {
  projectId: string;
  explorationId: string;
}

export const DataExplorerBreadcrumb: React.FC<Props> = ({
  projectId,
  explorationId,
}) => {
  // TODO set exploration title here based on data
  const dataExplorationTitle = 'Data Exploration 1';

  return (
    <Breadcrumb>
      <BreadcrumbLink
        Icon={SignalExplorer}
        displayName={dataExplorationTitle}
      />
    </Breadcrumb>
  );
};
