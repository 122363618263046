import styled from '@emotion/styled';
import { useGetFileDirectDownloadQuery } from 'app/apiGenerated/generatedApi';
import React, { useEffect } from 'react';
import { MarkdownViewer } from 'ui/common/MarkdownViewer';
import { NotFoundError, fetchFileContents } from 'util/fileUtils';

const Wrapper = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.grey['10']};
  margin-top: ${({ theme }) => theme.spacing.normal};
`;

export interface Props {
  projectId: string;
}

export const ProjectReadme: React.FC<Props> = ({ projectId }: Props) => {
  const [contents, setContents] = React.useState<string | null>(null);

  // FIXME: add cache tags in enhancedApi.ts
  const { data } = useGetFileDirectDownloadQuery({
    projectUuid: projectId,
    fileName: 'README.md',
  });

  useEffect(() => {
    if (!data?.download_link) return;
    fetchFileContents(data.download_link, 1 * 1024 * 1024)
      .then(setContents)
      .catch((e) => {
        setContents(null);
        if (!(e instanceof NotFoundError)) {
          console.error(e);
        }
      });
  }, [data, setContents]);

  if (!contents) return null;

  // NOTE: We could also use remark-gfm to support github-style markdown.
  return (
    <Wrapper>
      <MarkdownViewer contents={contents} />
    </Wrapper>
  );
};
