import { t } from '@lingui/macro';
import { Project } from 'app/apiTransformers/convertAPIProjectToProject';
import { PortSide } from 'app/common_types/PortTypes';
import { CustomLeafSystem } from 'app/generated_blocks/core/CustomLeafSystem';
import { BlockInstance } from 'app/generated_types/SimulationModel';
import { useAppDispatch } from 'app/hooks';
import { modelActions } from 'app/slices/modelSlice';
import React from 'react';
import { batch } from 'react-redux';
import { usePythonExecutor } from 'ui/appBottomBar/assistant/PythonHooks';
import SectionHeading from 'ui/common/Inputs/SectionHeading';
import { useNotifications } from 'ui/common/notifications/useNotifications';
import {
  orderedExtraParameters,
  updateExtraParameter,
} from 'util/dynamicBlockUtils';
import Button from '../../common/Button/Button';
import { ButtonVariants } from '../../common/Button/buttonTypes';
import { Remove, Reset } from '../../common/Icons/Standard';
import { usePython } from '../../common/PythonProvider';
import BlockParameter from '../BlockParameter';
import {
  DetailInputRowsSection,
  DetailsInput,
  DetailsRow,
  DetailsSection,
} from '../DetailsComponents';

type Props = {
  parentPath: string[];
  selectedNode: BlockInstance;
  canEdit: boolean;
};

const getBlockInfoPyCode = (blockDefPyCode: string) =>
  `

${blockDefPyCode}

from collimator import LeafSystem
leaf_systems = [
    v for v in locals().values()
    if isinstance(v, type) and issubclass(v, LeafSystem) and "collimator" not in v.__module__
]

if len(leaf_systems) > 1:
    raise ValueError(f'Multiple leaf system classes found: {leaf_systems}')

if len(leaf_systems) == 0:
    raise ValueError('No leaf system class found.')

system = leaf_systems[0]
block_info = {
    "name": system.__qualname__,
    "input_ports": system.__input_ports__,
    "output_ports": system.__output_ports__,
    "parameters": system.__parameters__,
}

`.trim();

export const fileFromPath = (filePath: string, project: Project) => {
  for (const file of project.files) {
    if (file.content_type !== 'text/x-python') {
      continue;
    }
    if (file.name === filePath) {
      return file;
    }
  }

  return null;
};

const LeafSystemBlockParameterDetails: React.FC<Props> = ({
  parentPath,
  selectedNode,
  canEdit,
}) => {
  const dispatch = useAppDispatch();

  const executePython = usePythonExecutor();
  const python = usePython();
  const { showError } = useNotifications();

  const addPorts = React.useCallback(
    (ports: number | string[], portSide: PortSide) => {
      if (typeof ports === 'number') {
        for (let i = 0; i < ports; i++) {
          dispatch(
            modelActions.addPort({
              parentPath,
              nodeUuid: selectedNode.uuid,
              portSide,
              kind: 'static',
            }),
          );
        }
      } else if (Array.isArray(ports)) {
        ports.forEach((p) => {
          dispatch(
            modelActions.addPort({
              parentPath,
              nodeUuid: selectedNode.uuid,
              portSide,
              name: p,
              kind: 'static',
            }),
          );
        });
      } else {
        showError('Port must be integer or array of string.');
      }
    },
    [dispatch, parentPath, selectedNode.uuid, showError],
  );

  // Reconfigure the block based on the Python code
  // If the block is defined in an external file, the block can't be compiled
  // to infer the parameters and ports.
  const compile = React.useCallback(async () => {
    if (!python.isReady) return;

    const code = getBlockInfoPyCode(
      selectedNode.parameters.source_code?.value || '',
    );

    const { results, error } = await executePython({
      code,
      returnVariableNames: ['block_info'],
    }).catch((error: any) => ({ error, results: null }));

    if (error) {
      showError(error);
      return;
    }

    const blockInfo = results?.get('block_info') as Map<string, unknown>;
    batch(() => {
      dispatch(
        modelActions.removeAllPorts({
          parentPath,
          nodeUuid: selectedNode.uuid,
        }),
      );
      const parameters = blockInfo.get('parameters') as string[];

      dispatch(
        modelActions.updateNodeExtraParameter({
          parentPath,
          nodeUuid: selectedNode.uuid,
          paramName: 'class_name',
          value: (blockInfo.get('name') as string) || '',
        }),
      );

      parameters.forEach((param) => {
        dispatch(
          modelActions.addNodeExtraParameter({
            parentPath,
            nodeUuid: selectedNode.uuid,
            name: param,
          }),
        );
      });

      const inputPorts = blockInfo.get('input_ports') as number | string[];
      addPorts(inputPorts, PortSide.Input);

      const outputPorts = blockInfo.get('output_ports') as number | string[];
      addPorts(outputPorts, PortSide.Output);
    });
  }, [
    python.isReady,
    selectedNode.parameters.source_code?.value,
    selectedNode.uuid,
    executePython,
    showError,
    dispatch,
    parentPath,
    addPorts,
  ]);

  const addExtraParameter = () => {
    dispatch(
      modelActions.addNodeExtraParameter({
        parentPath,
        nodeUuid: selectedNode.uuid,
      }),
    );
  };
  const renameExtraParameter = (oldName: string, newName: string) => {
    dispatch(
      modelActions.renameNodeExtraParameter({
        parentPath,
        nodeUuid: selectedNode.uuid,
        oldName,
        newName,
      }),
    );
  };

  const removeExtraParameter = (paramName: string) => {
    dispatch(
      modelActions.removeNodeExtraParameter({
        parentPath,
        nodeUuid: selectedNode.uuid,
        paramName,
      }),
    );
  };

  const parameters = orderedExtraParameters(selectedNode);
  const inline = selectedNode.parameters.inline?.value === 'true';
  const paramDefs = CustomLeafSystem.parameter_definitions?.reduce(
    (acc, paramDef) => {
      acc[paramDef.name] = paramDef;
      return acc;
    },
    {} as Record<string, any>,
  );

  const extraParams = parameters.map((paramName) => {
    const param = selectedNode.parameters[paramName];
    const valueKey = `extra-param-value-${paramName}`;
    const paramValue = param?.value || '';
    return (
      <DetailsSection key={paramName} vertical>
        <DetailsRow>
          <DetailsInput
            grow
            testId={paramName}
            value={paramName}
            onSubmitValue={(newName) =>
              renameExtraParameter(paramName, newName)
            }
            disabled={inline || !canEdit}
          />
          {!inline && (
            <Button
              variant={ButtonVariants.LargeTertiary}
              Icon={Remove}
              onClick={() => removeExtraParameter(paramName)}
              disabled={!canEdit}
            />
          )}
        </DetailsRow>
        <DetailsRow>
          <DetailsInput
            grow
            testId={valueKey}
            value={paramValue}
            onSubmitValue={updateExtraParameter(
              dispatch,
              parentPath,
              selectedNode,
              paramName,
            )}
            hasBorder
            allowMultiline
            disabled={!canEdit}
          />
        </DetailsRow>
      </DetailsSection>
    );
  });

  return (
    <>
      {inline && (
        <Button
          Icon={Reset}
          variant={ButtonVariants.SmallTertiary}
          disabled={!python.isReady}
          onClick={compile}>
          {python.isReady ? t`Compile` : t`Python is not ready...`}
        </Button>
      )}
      <SectionHeading
        testId="python-block-parameters"
        onButtonClick={!inline ? addExtraParameter : undefined}>
        {t({
          id: 'blockDetails.PythonBlockParameterDefinitionsTitle',
          message: 'Parameters',
        })}
      </SectionHeading>
      <DetailInputRowsSection>
        {paramDefs?.inline ? (
          <BlockParameter
            parentPath={parentPath}
            selectedNode={selectedNode}
            paramDef={paramDefs.inline}
            key={`row-inline-${selectedNode.uuid}`}
            disabled={!canEdit}
            isOptional={paramDefs.inline.optional}
          />
        ) : null}
        {!inline && (
          <>
            {paramDefs?.file_path ? (
              <BlockParameter
                parentPath={parentPath}
                selectedNode={selectedNode}
                paramDef={paramDefs.file_path}
                key={`row-file_path-${selectedNode.uuid}`}
                disabled={!canEdit}
                isOptional={paramDefs.file_path.optional}
              />
            ) : null}
            {paramDefs?.class_name ? (
              <BlockParameter
                parentPath={parentPath}
                selectedNode={selectedNode}
                paramDef={paramDefs.class_name}
                key={`row-class_name-${selectedNode.uuid}`}
                disabled={!canEdit}
                isOptional={paramDefs.class_name.optional}
              />
            ) : null}
          </>
        )}
        {extraParams}
      </DetailInputRowsSection>
    </>
  );
};

export default LeafSystemBlockParameterDetails;
