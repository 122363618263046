import { PayloadAction } from '@reduxjs/toolkit';
import {
  TraceMetadata,
  CellRow,
  PlotCellMetadata,
  CellMetadata,
  TextCellMetadata,
  ImageCellMetadata,
} from 'ui/dataExplorer/dataExplorerTypes';
import { VisualizerPrefsV1 } from 'ui/userPreferences/visualizerPrefs';

export function updateVisualizerPrefs(
  visualizerPrefs: VisualizerPrefsV1,
  action: PayloadAction<{
    cellRowIds: string[];
    idToCellRow: Record<string, CellRow>;
    idToCell: Record<string, CellMetadata>;
    idToPlotCell: Record<string, PlotCellMetadata>;
    idToTextCell: Record<string, TextCellMetadata>;
    idToImageCell: Record<string, ImageCellMetadata>;
    idToTrace: Record<string, TraceMetadata>;
  }>,
) {
  const {
    payload: {
      cellRowIds,
      idToCellRow,
      idToCell,
      idToPlotCell,
      idToTextCell,
      idToImageCell,
      idToTrace,
    },
  } = action;

  visualizerPrefs.cellRowIds = cellRowIds;
  visualizerPrefs.idToCellRow = idToCellRow;
  visualizerPrefs.idToCell = idToCell;
  visualizerPrefs.idToPlotCell = idToPlotCell;
  visualizerPrefs.idToTextCell = idToTextCell;
  visualizerPrefs.idToImageCell = idToImageCell;
  visualizerPrefs.idToTrace = idToTrace;
}
