/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const ArrowUp: React.FC<Props> = (props) => (
  <svg viewBox="0 0 24 24" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M12 17.75C12.4142 17.75 12.75 17.4142 12.75 17V8.81066L16.4697 12.5303C16.7626 12.8232 17.2374 12.8232 17.5303 12.5303C17.8232 12.2374 17.8232 11.7626 17.5303 11.4697L12 5.93934L6.46967 11.4697C6.17678 11.7626 6.17678 12.2374 6.46967 12.5303C6.76256 12.8232 7.23744 12.8232 7.53033 12.5303L11.25 8.81066V17C11.25 17.4142 11.5858 17.75 12 17.75Z"
      fill={props.fill}
    />
  </svg>
);

ArrowUp.defaultProps = {
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default ArrowUp;
