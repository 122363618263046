// !!!! -- DO NOT MANUALLY EDIT -- !!!!
// This file was generated by something in scripts/generateDefaultBlockData.js
// If you want to change the contents of this file, it should be automated
//
// (You can edit this file locally, but it may not be checked into the codebase -
// PRs with a manual edit of this file are forbidden from being merged.)

import { computationBlockDefaults } from 'app/generated_blocks/computationBlockDefaults';
import type { ComputationBlockClass } from 'app/generated_types/ComputationBlockClass';
import deepmerge from 'deepmerge';

export const Sawtooth: ComputationBlockClass = deepmerge(
  computationBlockDefaults,
  {
    "base": {
      "name": "Sawtooth",
      "namespace": "core",
      "description": "Generates sawtooth wave",
      "keywords": [
        "triangle"
      ],
      "help_url": "https://docs.collimator.ai/using-model-editors/block-library/agnostic-blocks#sawtooth"
    },
    "modes": {
      "time": "any"
    },
    "ports": {
      "outputs": {
        "static": [
          {
            "name": "out_0"
          }
        ]
      }
    },
    "parameter_definitions": [
      {
        "name": "amplitude",
        "description": "the amplitude of the wave.",
        "data_type": "float",
        "default_value": "1.0"
      },
      {
        "name": "frequency",
        "description": "the frequency of the wave.",
        "data_type": "float",
        "default_value": "0.5"
      },
      {
        "name": "phase_delay",
        "description": "the time dimension offset when the first cycle should start.",
        "data_type": "float",
        "default_value": "1.0"
      }
    ]
  }
);
