/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  className?: string;
}

const Discreteintegrator: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.988 5.60001C19.028 5.60001 19.06 5.61601 19.084 5.64801C19.116 5.67201 19.132 5.70401 19.132 5.74401V6.66801C19.132 6.70801 19.116 6.74401 19.084 6.77601C19.06 6.80001 19.028 6.81201 18.988 6.81201H16.78C16.74 6.81201 16.72 6.83201 16.72 6.87201V13.856C16.72 13.896 16.704 13.932 16.672 13.964C16.648 13.988 16.616 14 16.576 14H15.472C15.432 14 15.396 13.988 15.364 13.964C15.34 13.932 15.328 13.896 15.328 13.856V6.87201C15.328 6.83201 15.308 6.81201 15.268 6.81201H13.144C13.104 6.81201 13.068 6.80001 13.036 6.77601C13.012 6.74401 13 6.70801 13 6.66801V5.74401C13 5.70401 13.012 5.67201 13.036 5.64801C13.068 5.61601 13.104 5.60001 13.144 5.60001H18.988Z"
      fill={props.fill}
    />
    <path
      d="M20.8965 18.136C20.9605 18.112 21.0205 18.1 21.0765 18.1H22.2285C22.2685 18.1 22.3005 18.116 22.3245 18.148C22.3565 18.172 22.3725 18.204 22.3725 18.244V26.356C22.3725 26.396 22.3565 26.432 22.3245 26.464C22.3005 26.488 22.2685 26.5 22.2285 26.5H21.1245C21.0845 26.5 21.0485 26.488 21.0165 26.464C20.9925 26.432 20.9805 26.396 20.9805 26.356V19.54C20.9805 19.524 20.9725 19.512 20.9565 19.504C20.9405 19.488 20.9245 19.484 20.9085 19.492L19.7085 19.864C19.6925 19.872 19.6725 19.876 19.6485 19.876C19.6165 19.876 19.5885 19.864 19.5645 19.84C19.5485 19.816 19.5405 19.784 19.5405 19.744L19.5045 18.952C19.5045 18.872 19.5365 18.816 19.6005 18.784L20.8965 18.136Z"
      fill={props.fill}
    />
    <path
      d="M9.036 26.464C9.068 26.488 9.104 26.5 9.144 26.5H13.608C13.648 26.5 13.68 26.488 13.704 26.464C13.736 26.432 13.752 26.396 13.752 26.356V25.384C13.752 25.344 13.736 25.312 13.704 25.288C13.68 25.256 13.648 25.24 13.608 25.24H10.668C10.612 25.24 10.6 25.216 10.632 25.168L13.644 21.7C13.692 21.652 13.716 21.596 13.716 21.532V20.512C13.716 20.472 13.7 20.44 13.668 20.416C13.644 20.384 13.612 20.368 13.572 20.368H9.204C9.164 20.368 9.128 20.384 9.096 20.416C9.072 20.44 9.06 20.472 9.06 20.512V21.484C9.06 21.524 9.072 21.56 9.096 21.592C9.128 21.616 9.164 21.628 9.204 21.628H11.976C12.04 21.628 12.052 21.652 12.012 21.7L9.06 25.168C9.02 25.208 9 25.264 9 25.336V26.356C9 26.396 9.012 26.432 9.036 26.464Z"
      fill={props.fill}
    />
    <path
      d="M14.5449 23.452C14.5769 23.476 14.6129 23.488 14.6529 23.488H18.4329C18.4729 23.488 18.5049 23.476 18.5289 23.452C18.5609 23.42 18.5769 23.384 18.5769 23.344V22.432C18.5769 22.392 18.5609 22.36 18.5289 22.336C18.5049 22.304 18.4729 22.288 18.4329 22.288H14.6529C14.6129 22.288 14.5769 22.304 14.5449 22.336C14.5209 22.36 14.5089 22.392 14.5089 22.432V23.344C14.5089 23.384 14.5209 23.42 14.5449 23.452Z"
      fill={props.fill}
    />
    <path
      d="M8 15.25C7.58579 15.25 7.25 15.5858 7.25 16C7.25 16.4142 7.58579 16.75 8 16.75H24C24.4142 16.75 24.75 16.4142 24.75 16C24.75 15.5858 24.4142 15.25 24 15.25H16H8Z"
      fill={props.fill}
    />
  </svg>
);

Discreteintegrator.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  className: '',
};

export default Discreteintegrator;
