// !!!! -- DO NOT MANUALLY EDIT -- !!!!
// This file was generated by something in scripts/generateApiWithTransforms.js
// If you want to change the contents of this file, it should be automated
//
// (You can edit this file locally, but it may not be checked into the codebase -
// PRs with a manual edit of this file are forbidden from being merged.)

import {
  convertGetSubmodelsList,
  SubmodelInfoLiteUI,
} from 'app/apiTransformers/convertGetSubmodelsList';
import {
  convertGetSimulationReadAll,
  SimulationSummaryUI,
} from 'app/apiTransformers/convertGetSimulationReadAll';
import {
  convertGetSnapshotReadAll,
  DiagramVersionItemData,
} from 'app/apiTransformers/convertGetSnapshotReadAll';
import {
  convertGetSnapshotReadByUuid,
  DiagramVersionFull,
} from 'app/apiTransformers/convertGetSnapshotReadByUuid';
import {
  convertGetSubmodelsListForModelParent,
  SubmodelInfoUI,
} from 'app/apiTransformers/convertGetSubmodelsListForModelParent';
import {
  convertGetSubmodel,
  SubmodelFullUI,
} from 'app/apiTransformers/convertGetSubmodel';
import { convertGetSubmodelsListForSubmodelParent } from 'app/apiTransformers/convertGetSubmodelsListForSubmodelParent';
import { convertGetSubmodelInfo } from 'app/apiTransformers/convertGetSubmodelInfo';
import { convertGetProjectReadAll } from 'app/apiTransformers/convertGetProjectReadAll';
import {
  convertAPIProjectToProject,
  Project,
} from 'app/apiTransformers/convertAPIProjectToProject';
import {
  convertGetProjectPermissionReadAll,
  ProjectPermission,
} from 'app/apiTransformers/convertGetProjectPermissionReadAll';
import {
  convertGetSimulationsForUserReadAll,
  SimulationSummariesUI,
} from 'app/apiTransformers/convertGetSimulationsForUserReadAll';
import {
  convertPostSubmodelsFetch,
  VersionedSubmodelsResponse,
} from 'app/apiTransformers/convertPostSubmodelsFetch';
import {
  GetAdminAuditLogApiArg,
  GetAdminAuditLogApiResponse,
  GetAdminGlobalProjectsApiArg,
  GetAdminGlobalProjectsApiResponse,
  PostAdminGlobalProjectCreateApiArg,
  PostAdminGlobalProjectCreateApiResponse,
  DeleteAdminGlobalProjectByUuidApiArg,
  DeleteAdminGlobalProjectByUuidApiResponse,
  GetAdminPlatformInfoApiArg,
  GetAdminPlatformInfoApiResponse,
  GetAdminSimulationJobsListApiArg,
  GetAdminSimulationJobsListApiResponse,
  DeleteAdminSimulationJobsByUuidApiArg,
  DeleteAdminSimulationJobsByUuidApiResponse,
  GetUserOptionOverridesReadAllApiArg,
  GetUserOptionOverridesReadAllApiResponse,
  PostUserOptionOverridesCreateApiArg,
  PostUserOptionOverridesCreateApiResponse,
  DeleteUserOptionOverridesByUuidApiArg,
  DeleteUserOptionOverridesByUuidApiResponse,
  PutUserOptionOverridesUpdateByUuidApiArg,
  PutUserOptionOverridesUpdateByUuidApiResponse,
  GetUserOptionsReadAllApiArg,
  GetUserOptionsReadAllApiResponse,
  PostUserOptionsCreateApiArg,
  PostUserOptionsCreateApiResponse,
  DeleteUserOptionsByUuidApiArg,
  DeleteUserOptionsByUuidApiResponse,
  PutUserOptionsUpdateByUuidApiArg,
  PutUserOptionsUpdateByUuidApiResponse,
  GetUserOptionOverridingUsersListApiArg,
  GetUserOptionOverridingUsersListApiResponse,
  GetAdminUsersReadAllApiArg,
  GetAdminUsersReadAllApiResponse,
  PatchAdminUserByUuidApiArg,
  PatchAdminUserByUuidApiResponse,
  DeleteUserEntirelyAdminUseOnlyApiArg,
  DeleteUserEntirelyAdminUseOnlyApiResponse,
  PostCreatePreRegMetaApiArg,
  PostCreatePreRegMetaApiResponse,
  GetPreRegMetasAllApiArg,
  GetPreRegMetasAllApiResponse,
  DeletePreRegMetaByEmailApiArg,
  DeletePreRegMetaByEmailApiResponse,
  GetAuthTokensReadAllApiArg,
  GetAuthTokensReadAllApiResponse,
  PostAuthTokensApiArg,
  PostAuthTokensApiResponse,
  DeleteAuthTokenByUuidApiArg,
  DeleteAuthTokenByUuidApiResponse,
  PostCodeAutocompleteApiArg,
  PostCodeAutocompleteApiResponse,
  DeleteEntityPreferencesApiArg,
  DeleteEntityPreferencesApiResponse,
  GetEntityPreferencesApiArg,
  GetEntityPreferencesApiResponse,
  PutEntityPreferencesApiArg,
  PutEntityPreferencesApiResponse,
  PostGitHubCallbackApiArg,
  PostGitHubCallbackApiResponse,
  GetGitHubReposApiArg,
  GetGitHubReposApiResponse,
  GetGlobalSubmodelsReadApiArg,
  PutHashedFilesUploadByIdApiArg,
  PutHashedFilesUploadByIdApiResponse,
  GetOpenApiApiArg,
  GetOpenApiApiResponse,
  PostModelCreateApiArg,
  PostModelCreateApiResponse,
  DeleteModelByUuidApiArg,
  DeleteModelByUuidApiResponse,
  GetModelReadByUuidApiArg,
  GetModelReadByUuidApiResponse,
  PutModelUpdateByUuidApiArg,
  PutModelUpdateByUuidApiResponse,
  GetCommentsReadAllApiArg,
  GetCommentsReadAllApiResponse,
  PostCommentCreateApiArg,
  PostCommentCreateApiResponse,
  DeleteCommentByUuidApiArg,
  DeleteCommentByUuidApiResponse,
  PutModelConfigurationUpdateByUuidApiArg,
  PutModelConfigurationUpdateByUuidApiResponse,
  PostModelCopyByUuidApiArg,
  PostModelCopyByUuidApiResponse,
  GetModelParametersByUuidApiArg,
  GetModelParametersByUuidApiResponse,
  PutModelParametersUpdateByUuidApiArg,
  PutModelParametersUpdateByUuidApiResponse,
  PostJobCreateApiArg,
  PostJobCreateApiResponse,
  GetJobSummaryApiArg,
  GetJobSummaryApiResponse,
  PostJobStopApiArg,
  PostJobStopApiResponse,
  GetJobLogsFileApiArg,
  GetJobLogsFileApiResponse,
  GetSimulationReadAllApiArg,
  PostSimulationCreateApiArg,
  PostSimulationCreateApiResponse,
  GetSimulationCachedReadApiArg,
  GetSimulationCachedReadApiResponse,
  GetSimulationReadByUuidApiArg,
  GetSimulationReadByUuidApiResponse,
  PutSimulationUpdateApiArg,
  PutSimulationUpdateApiResponse,
  GetSimulationArtifactsReadByUuidApiArg,
  GetSimulationArtifactsReadByUuidApiResponse,
  GetSimulationSignalsReadByUuidApiArg,
  GetSimulationSignalsReadByUuidApiResponse,
  GetSimulationCsvResultsInfoReadByUuidApiArg,
  GetSimulationCsvResultsInfoReadByUuidApiResponse,
  PostSimulationEventCreateApiArg,
  PostSimulationEventCreateApiResponse,
  PostSimulationHashedFileCreateApiArg,
  PostSimulationHashedFileCreateApiResponse,
  GetSimulationProgressByUuidApiArg,
  GetSimulationProgressByUuidApiResponse,
  GetSimulationLogsReadByUuidApiArg,
  GetSimulationLogsReadByUuidApiResponse,
  GetSimulationLogFileReadByUuidApiArg,
  GetSimulationLogFileReadByUuidApiResponse,
  GetSimulationLogsShortReadByUuidApiArg,
  GetSimulationLogsShortReadByUuidApiResponse,
  PutSimulationParametersUpdateApiArg,
  PutSimulationParametersUpdateApiResponse,
  GetSimulationProcessResultsReadByUuidApiArg,
  GetSimulationProcessResultsReadByUuidApiResponse,
  GetSimulationSignalTypesByPathReadByUuidApiArg,
  GetSimulationSignalTypesByPathReadByUuidApiResponse,
  PostSimulationStartByUuidApiArg,
  PostSimulationStartByUuidApiResponse,
  GetSimulationTimeModeAssignmentByPathReadByUuidApiArg,
  GetSimulationTimeModeAssignmentByPathReadByUuidApiResponse,
  GetSnapshotReadAllApiArg,
  PostSnapshotCreateApiArg,
  PostSnapshotCreateApiResponse,
  DeleteSnapshotByUuidApiArg,
  DeleteSnapshotByUuidApiResponse,
  GetSnapshotReadByUuidApiArg,
  PostSnapshotRestoreByUuidApiArg,
  PostSnapshotRestoreByUuidApiResponse,
  PutSnapshotSummaryUpdateByUuidApiArg,
  PutSnapshotSummaryUpdateByUuidApiResponse,
  PutModelSummaryUpdateByUuidApiArg,
  PutModelSummaryUpdateByUuidApiResponse,
  GetModelWithSubmodelsReadByUuidApiArg,
  GetModelWithSubmodelsReadByUuidApiResponse,
  GetNodeApiStatusApiArg,
  GetNodeApiStatusApiResponse,
  GetMigrationServiceStatusApiArg,
  GetMigrationServiceStatusApiResponse,
  PostMigrationServiceMigrateModelApiArg,
  PostMigrationServiceMigrateModelApiResponse,
  GetSubmodelsListForModelParentApiArg,
  GetSubmodelsListApiArg,
  PostSubmodelCreateApiArg,
  PostSubmodelCreateApiResponse,
  DeleteSubmodelApiArg,
  DeleteSubmodelApiResponse,
  GetSubmodelApiArg,
  PutSubmodelUpdateApiArg,
  PutSubmodelUpdateApiResponse,
  GetSubmodelsListForSubmodelParentApiArg,
  PostSubmodelCopyApiArg,
  PostSubmodelCopyApiResponse,
  GetSubmodelInfoApiArg,
  GetParentReferencesToSubmodelApiArg,
  GetParentReferencesToSubmodelApiResponse,
  GetSubmodelWithSubmodelsApiArg,
  GetSubmodelWithSubmodelsApiResponse,
  GetProjectReadAllApiArg,
  PostProjectCreateApiArg,
  PostProjectCreateApiResponse,
  DeleteProjectByUuidApiArg,
  DeleteProjectByUuidApiResponse,
  GetProjectReadByUuidApiArg,
  PutProjectUpdateByUuidApiArg,
  PutProjectUpdateByUuidApiResponse,
  PostProjectCopyByUuidApiArg,
  PostProjectCopyByUuidApiResponse,
  GetDataIntegrationsReadAllApiArg,
  GetDataIntegrationsReadAllApiResponse,
  PostDataIntegrationsCreateApiArg,
  PostDataIntegrationsCreateApiResponse,
  GetDataIntegrationsObjectsReadAllApiArg,
  GetDataIntegrationsObjectsReadAllApiResponse,
  GetProjectExportByUuidApiArg,
  GetProjectExportByUuidApiResponse,
  PostFileCreateApiArg,
  PostFileCreateApiResponse,
  DeleteFileByUuidApiArg,
  DeleteFileByUuidApiResponse,
  GetFileReadByUuidApiArg,
  GetFileReadByUuidApiResponse,
  PutFileUpdateByUuidApiArg,
  PutFileUpdateByUuidApiResponse,
  PutFileContentUpdateByUuidApiArg,
  PutFileContentUpdateByUuidApiResponse,
  GetFileDownloadByUuidApiArg,
  GetFileDownloadByUuidApiResponse,
  GetFileDirectDownloadApiArg,
  GetFileDirectDownloadApiResponse,
  PostFileProcessByUuidApiArg,
  PostFileProcessByUuidApiResponse,
  PostFileCopyByUuidApiArg,
  PostFileCopyByUuidApiResponse,
  PostProjectImportByUuidApiArg,
  PostProjectImportByUuidApiResponse,
  PostProjectImportProcessByUuidApiArg,
  PostProjectImportProcessByUuidApiResponse,
  DeleteDataIntegrationsByUuidApiArg,
  DeleteDataIntegrationsByUuidApiResponse,
  PutDataIntegrationsUpdateByUuidApiArg,
  PutDataIntegrationsUpdateByUuidApiResponse,
  GetProjectPermissionReadAllApiArg,
  PostProjectPermissionCreateApiArg,
  PostProjectPermissionCreateApiResponse,
  PutProjectPermissionAllowAnonymousUpdateByUuidApiArg,
  PutProjectPermissionAllowAnonymousUpdateByUuidApiResponse,
  DeleteProjectPermissionByUuidApiArg,
  DeleteProjectPermissionByUuidApiResponse,
  PutProjectPermissionUpdateByUuidApiArg,
  PutProjectPermissionUpdateByUuidApiResponse,
  PostProjectPermissionClaimApiArg,
  PostProjectPermissionClaimApiResponse,
  GetRequirementsApiArg,
  GetRequirementsApiResponse,
  PostRequirementsImportApiArg,
  PostRequirementsImportApiResponse,
  PostRequirementsImportProcessApiArg,
  PostRequirementsImportProcessApiResponse,
  PostRequirementLinkCreateApiArg,
  PostRequirementLinkCreateApiResponse,
  DeleteRequirementLinkApiArg,
  DeleteRequirementLinkApiResponse,
  GetRequirementBlockByLocationApiArg,
  GetRequirementBlockByLocationApiResponse,
  PostProjectGitConnectRemoteApiArg,
  PostProjectGitConnectRemoteApiResponse,
  GetProjectGitRemoteApiArg,
  GetProjectGitRemoteApiResponse,
  DeleteProjectGitRemoteApiArg,
  DeleteProjectGitRemoteApiResponse,
  GetProjectGitRemoteFilesApiArg,
  GetProjectGitRemoteFilesApiResponse,
  PostProjectGitResetFromUpstreamApiArg,
  PostProjectGitResetFromUpstreamApiResponse,
  PostProjectGitPushToUpstreamApiArg,
  PostProjectGitPushToUpstreamApiResponse,
  PostBusTypeCreateApiArg,
  PostBusTypeCreateApiResponse,
  GetBusTypeApiArg,
  GetBusTypeApiResponse,
  PutBusTypeUpdateApiArg,
  PutBusTypeUpdateApiResponse,
  DeleteBusTypeApiArg,
  DeleteBusTypeApiResponse,
  PostSentryApiArg,
  PostSentryApiResponse,
  GetSimulationsForUserReadAllApiArg,
  GetSimulationDumpByUuidApiArg,
  GetSimulationDumpByUuidApiResponse,
  GetStatusApiArg,
  GetStatusApiResponse,
  GetSubmodelsFetchApiArg,
  GetSubmodelsFetchApiResponse,
  PostSubmodelsFetchApiArg,
  GetSubmodelSnapshotReadAllApiArg,
  GetSubmodelSnapshotReadAllApiResponse,
  PostSubmodelSnapshotCreateApiArg,
  PostSubmodelSnapshotCreateApiResponse,
  DeleteSubmodelSnapshotByUuidApiArg,
  DeleteSubmodelSnapshotByUuidApiResponse,
  GetSubmodelSnapshotReadByUuidApiArg,
  GetSubmodelSnapshotReadByUuidApiResponse,
  PostSubmodelSnapshotRestoreByUuidApiArg,
  PostSubmodelSnapshotRestoreByUuidApiResponse,
  PutSubmodelSnapshotSummaryUpdateByUuidApiArg,
  PutSubmodelSnapshotSummaryUpdateByUuidApiResponse,
  GetSubmodelCommentsReadAllApiArg,
  GetSubmodelCommentsReadAllApiResponse,
  PostSubmodelCommentCreateApiArg,
  PostSubmodelCommentCreateApiResponse,
  DeleteSubmodelCommentByUuidApiArg,
  DeleteSubmodelCommentByUuidApiResponse,
  GetTemplateProjectsReadAllApiArg,
  GetTemplateProjectsReadAllApiResponse,
  PostTemplateProjectsCopyByUuidApiArg,
  PostTemplateProjectsCopyByUuidApiResponse,
  PostClerkWhitelistIdentifierCreateApiArg,
  PostClerkWhitelistIdentifierCreateApiResponse,
  DeleteClerkWhitelistIdentifierByIdApiArg,
  DeleteClerkWhitelistIdentifierByIdApiResponse,
  GetUserOptionsApiArg,
  GetUserOptionsApiResponse,
  GetUserPreferencesApiArg,
  GetUserPreferencesApiResponse,
  PutUserPreferencesApiArg,
  PutUserPreferencesApiResponse,
  GetUserProfileApiArg,
  GetUserProfileApiResponse,
  PostUserRegisterApiArg,
  PostUserRegisterApiResponse,
  PostUserCredentialsApiArg,
  PostUserCredentialsApiResponse,
  GetListUserCredentialsApiArg,
  GetListUserCredentialsApiResponse,
  DeleteUserCredentialsApiArg,
  DeleteUserCredentialsApiResponse,
  PostUserSignBetaTosApiArg,
  PostUserSignBetaTosApiResponse,
  GetSubscriptionStatusApiArg,
  GetSubscriptionStatusApiResponse,
  PostSubscriptionCheckoutUrlsApiArg,
  PostSubscriptionCheckoutUrlsApiResponse,
  PostStripeManagementInfoApiArg,
  PostStripeManagementInfoApiResponse,
  GetUserStatisticsApiArg,
  GetUserStatisticsApiResponse,
  PostChatSessionApiArg,
  PostChatSessionApiResponse,
  PutChatSessionApiArg,
  PutChatSessionApiResponse,
  GetChatSessionByUuidApiArg,
  GetChatSessionByUuidApiResponse,
  GetChatSessionLastApiArg,
  GetChatSessionLastApiResponse,
  GetChatSessionsErrorsApiArg,
  GetChatSessionsErrorsApiResponse,
  PostChatAbortApiArg,
  PostChatAbortApiResponse,
  PostDocumentationBlockSearchApiArg,
  PostDocumentationBlockSearchApiResponse,
  PostChatFileCreateApiArg,
  PostChatFileCreateApiResponse,
  StripeWebhooksHandlerApiArg,
  StripeWebhooksHandlerApiResponse,
} from 'app/apiGenerated/generatedApiTypes';

import { v4 as uuid } from 'uuid';
import { emptySplitApi as api } from './emptyApi';

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAdminAuditLog: build.query<
      GetAdminAuditLogApiResponse,
      GetAdminAuditLogApiArg
    >({
      query: (queryArg) => ({
        url: `/admin/audit_log`,
        params: {
          action: queryArg.action,
          after: queryArg.after,
          before: queryArg.before,
          actor_uuid: queryArg.actorUuid,
          limit: queryArg.limit,
        },
      }),
    }),
    getAdminGlobalProjects: build.query<
      GetAdminGlobalProjectsApiResponse,
      GetAdminGlobalProjectsApiArg
    >({
      query: (queryArg) => ({
        url: `/admin/global_projects`,
        params: { kind: queryArg.kind },
      }),
    }),
    postAdminGlobalProjectCreate: build.mutation<
      PostAdminGlobalProjectCreateApiResponse,
      PostAdminGlobalProjectCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/admin/global_projects`,
        method: 'POST',
        body: queryArg.globalProjectCreateRequest,
      }),
    }),
    deleteAdminGlobalProjectByUuid: build.mutation<
      DeleteAdminGlobalProjectByUuidApiResponse,
      DeleteAdminGlobalProjectByUuidApiArg
    >({
      query: (queryArg) => ({
        url: `/admin/global_projects/${queryArg.projectKind}/${queryArg.projectUuid}`,
        method: 'DELETE',
      }),
    }),
    getAdminPlatformInfo: build.query<
      GetAdminPlatformInfoApiResponse,
      GetAdminPlatformInfoApiArg
    >({
      query: () => ({ url: `/admin/platform` }),
    }),
    getAdminSimulationJobsList: build.query<
      GetAdminSimulationJobsListApiResponse,
      GetAdminSimulationJobsListApiArg
    >({
      query: () => ({ url: `/admin/simulation_jobs` }),
    }),
    deleteAdminSimulationJobsByUuid: build.mutation<
      DeleteAdminSimulationJobsByUuidApiResponse,
      DeleteAdminSimulationJobsByUuidApiArg
    >({
      query: (queryArg) => ({
        url: `/admin/simulation_jobs/${queryArg.simulationJobUuid}`,
        method: 'DELETE',
      }),
    }),
    getUserOptionOverridesReadAll: build.query<
      GetUserOptionOverridesReadAllApiResponse,
      GetUserOptionOverridesReadAllApiArg
    >({
      query: () => ({ url: `/admin/user_option_overrides` }),
    }),
    postUserOptionOverridesCreate: build.mutation<
      PostUserOptionOverridesCreateApiResponse,
      PostUserOptionOverridesCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/admin/user_option_overrides`,
        method: 'POST',
        body: queryArg.userOptionOverrideCreateRequest,
      }),
    }),
    deleteUserOptionOverridesByUuid: build.mutation<
      DeleteUserOptionOverridesByUuidApiResponse,
      DeleteUserOptionOverridesByUuidApiArg
    >({
      query: (queryArg) => ({
        url: `/admin/user_option_overrides/${queryArg.userOptionOverrideUuid}`,
        method: 'DELETE',
      }),
    }),
    putUserOptionOverridesUpdateByUuid: build.mutation<
      PutUserOptionOverridesUpdateByUuidApiResponse,
      PutUserOptionOverridesUpdateByUuidApiArg
    >({
      query: (queryArg) => ({
        url: `/admin/user_option_overrides/${queryArg.userOptionOverrideUuid}`,
        method: 'PUT',
        body: queryArg.userOptionOverrideUpdateRequest,
      }),
    }),
    getUserOptionsReadAll: build.query<
      GetUserOptionsReadAllApiResponse,
      GetUserOptionsReadAllApiArg
    >({
      query: () => ({ url: `/admin/user_options` }),
    }),
    postUserOptionsCreate: build.mutation<
      PostUserOptionsCreateApiResponse,
      PostUserOptionsCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/admin/user_options`,
        method: 'POST',
        body: queryArg.userOptionCreateRequest,
      }),
    }),
    deleteUserOptionsByUuid: build.mutation<
      DeleteUserOptionsByUuidApiResponse,
      DeleteUserOptionsByUuidApiArg
    >({
      query: (queryArg) => ({
        url: `/admin/user_options/${queryArg.userOptionUuid}`,
        method: 'DELETE',
      }),
    }),
    putUserOptionsUpdateByUuid: build.mutation<
      PutUserOptionsUpdateByUuidApiResponse,
      PutUserOptionsUpdateByUuidApiArg
    >({
      query: (queryArg) => ({
        url: `/admin/user_options/${queryArg.userOptionUuid}`,
        method: 'PUT',
        body: queryArg.userOptionUpdateRequest,
      }),
    }),
    getUserOptionOverridingUsersList: build.query<
      GetUserOptionOverridingUsersListApiResponse,
      GetUserOptionOverridingUsersListApiArg
    >({
      query: (queryArg) => ({
        url: `/admin/user_options/${queryArg.userOptionUuid}/users`,
      }),
    }),
    getAdminUsersReadAll: build.query<
      GetAdminUsersReadAllApiResponse,
      GetAdminUsersReadAllApiArg
    >({
      query: () => ({ url: `/admin/users` }),
    }),
    patchAdminUserByUuid: build.mutation<
      PatchAdminUserByUuidApiResponse,
      PatchAdminUserByUuidApiArg
    >({
      query: (queryArg) => ({
        url: `/admin/users/${queryArg.userUuid}`,
        method: 'PATCH',
        body: queryArg.adminModifyUserRequest,
      }),
    }),
    deleteUserEntirelyAdminUseOnly: build.mutation<
      DeleteUserEntirelyAdminUseOnlyApiResponse,
      DeleteUserEntirelyAdminUseOnlyApiArg
    >({
      query: (queryArg) => ({
        url: `/admin/users/${queryArg.userUuid}`,
        method: 'DELETE',
      }),
    }),
    postCreatePreRegMeta: build.mutation<
      PostCreatePreRegMetaApiResponse,
      PostCreatePreRegMetaApiArg
    >({
      query: (queryArg) => ({
        url: `/admin/create_prereg_meta`,
        method: 'POST',
        body: queryArg.preRegMetaCreateRequest,
      }),
    }),
    getPreRegMetasAll: build.query<
      GetPreRegMetasAllApiResponse,
      GetPreRegMetasAllApiArg
    >({
      query: () => ({ url: `/admin/prereg_metas` }),
    }),
    deletePreRegMetaByEmail: build.mutation<
      DeletePreRegMetaByEmailApiResponse,
      DeletePreRegMetaByEmailApiArg
    >({
      query: (queryArg) => ({
        url: `/admin/prereg_metas`,
        method: 'DELETE',
        params: { email: queryArg.email },
      }),
    }),
    getAuthTokensReadAll: build.query<
      GetAuthTokensReadAllApiResponse,
      GetAuthTokensReadAllApiArg
    >({
      query: () => ({ url: `/auth/tokens` }),
    }),
    postAuthTokens: build.mutation<
      PostAuthTokensApiResponse,
      PostAuthTokensApiArg
    >({
      query: (queryArg) => ({
        url: `/auth/tokens`,
        method: 'POST',
        body: queryArg.createAuthTokenRequest,
      }),
    }),
    deleteAuthTokenByUuid: build.mutation<
      DeleteAuthTokenByUuidApiResponse,
      DeleteAuthTokenByUuidApiArg
    >({
      query: (queryArg) => ({
        url: `/auth/tokens/${queryArg.authTokenUuid}`,
        method: 'DELETE',
      }),
    }),
    postCodeAutocomplete: build.mutation<
      PostCodeAutocompleteApiResponse,
      PostCodeAutocompleteApiArg
    >({
      query: (queryArg) => ({
        url: `/code/autocomplete`,
        method: 'POST',
        body: queryArg.autocompleteRequest,
      }),
    }),
    deleteEntityPreferences: build.mutation<
      DeleteEntityPreferencesApiResponse,
      DeleteEntityPreferencesApiArg
    >({
      query: (queryArg) => ({
        url: `/entity_preferences/${queryArg.entityUuid}/${queryArg.preferencesKey}`,
        method: 'DELETE',
      }),
    }),
    getEntityPreferences: build.query<
      GetEntityPreferencesApiResponse,
      GetEntityPreferencesApiArg
    >({
      query: (queryArg) => ({
        url: `/entity_preferences/${queryArg.entityUuid}/${queryArg.preferencesKey}`,
      }),
    }),
    putEntityPreferences: build.mutation<
      PutEntityPreferencesApiResponse,
      PutEntityPreferencesApiArg
    >({
      query: (queryArg) => ({
        url: `/entity_preferences/${queryArg.entityUuid}/${queryArg.preferencesKey}`,
        method: 'PUT',
        body: queryArg.entityPreferencesContainer,
      }),
    }),
    postGitHubCallback: build.mutation<
      PostGitHubCallbackApiResponse,
      PostGitHubCallbackApiArg
    >({
      query: (queryArg) => ({
        url: `/github/callback`,
        method: 'POST',
        body: queryArg.gitHubCallbackRequest,
      }),
    }),
    getGitHubRepos: build.query<
      GetGitHubReposApiResponse,
      GetGitHubReposApiArg
    >({
      query: () => ({ url: `/github/repos` }),
    }),
    getGlobalSubmodelsRead: build.query<
      SubmodelInfoLiteUI[],
      GetGlobalSubmodelsReadApiArg
    >({
      query: () => ({ url: `/global_submodels` }),
      transformResponse: convertGetSubmodelsList,
    }),
    putHashedFilesUploadById: build.mutation<
      PutHashedFilesUploadByIdApiResponse,
      PutHashedFilesUploadByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/hashed_files/upload/${queryArg.uploadUuid}`,
        method: 'PUT',
        body: queryArg.body,
        params: { token: queryArg.token },
      }),
    }),
    getOpenApi: build.query<GetOpenApiApiResponse, GetOpenApiApiArg>({
      query: () => ({ url: `/internal/openapi` }),
    }),
    postModelCreate: build.mutation<
      PostModelCreateApiResponse,
      PostModelCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/models`,
        method: 'POST',
        body: queryArg.modelCreateRequest,
      }),
    }),
    deleteModelByUuid: build.mutation<
      DeleteModelByUuidApiResponse,
      DeleteModelByUuidApiArg
    >({
      query: (queryArg) => ({
        url: `/models/${queryArg.modelUuid}`,
        method: 'DELETE',
      }),
    }),
    getModelReadByUuid: build.query<
      GetModelReadByUuidApiResponse,
      GetModelReadByUuidApiArg
    >({
      query: (queryArg) => ({ url: `/models/${queryArg.modelUuid}` }),
    }),
    putModelUpdateByUuid: build.mutation<
      PutModelUpdateByUuidApiResponse,
      PutModelUpdateByUuidApiArg
    >({
      query: (queryArg) => ({
        url: `/models/${queryArg.modelUuid}`,
        method: 'PUT',
        body: queryArg.modelUpdateRequest,
      }),
    }),
    getCommentsReadAll: build.query<
      GetCommentsReadAllApiResponse,
      GetCommentsReadAllApiArg
    >({
      query: (queryArg) => ({ url: `/models/${queryArg.modelUuid}/comments` }),
    }),
    postCommentCreate: build.mutation<
      PostCommentCreateApiResponse,
      PostCommentCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/models/${queryArg.modelUuid}/comments`,
        method: 'POST',
        body: queryArg.commentCreateRequest,
      }),
    }),
    deleteCommentByUuid: build.mutation<
      DeleteCommentByUuidApiResponse,
      DeleteCommentByUuidApiArg
    >({
      query: (queryArg) => ({
        url: `/models/${queryArg.modelUuid}/comments/${queryArg.commentUuid}`,
        method: 'DELETE',
      }),
    }),
    putModelConfigurationUpdateByUuid: build.mutation<
      PutModelConfigurationUpdateByUuidApiResponse,
      PutModelConfigurationUpdateByUuidApiArg
    >({
      query: (queryArg) => ({
        url: `/models/${queryArg.modelUuid}/configuration`,
        method: 'PUT',
        body: queryArg.modelConfigurationUpdateRequest,
      }),
    }),
    postModelCopyByUuid: build.mutation<
      PostModelCopyByUuidApiResponse,
      PostModelCopyByUuidApiArg
    >({
      query: (queryArg) => ({
        url: `/models/${queryArg.modelUuid}/copy`,
        method: 'POST',
        body: queryArg.modelCopyRequest,
      }),
    }),
    getModelParametersByUuid: build.query<
      GetModelParametersByUuidApiResponse,
      GetModelParametersByUuidApiArg
    >({
      query: (queryArg) => ({
        url: `/models/${queryArg.modelUuid}/parameters`,
      }),
    }),
    putModelParametersUpdateByUuid: build.mutation<
      PutModelParametersUpdateByUuidApiResponse,
      PutModelParametersUpdateByUuidApiArg
    >({
      query: (queryArg) => ({
        url: `/models/${queryArg.modelUuid}/parameters`,
        method: 'PUT',
        body: queryArg.modelParametersUpdateRequest,
      }),
    }),
    postJobCreate: build.mutation<
      PostJobCreateApiResponse,
      PostJobCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/jobs`,
        method: 'POST',
        body: queryArg.jobCreateRequest,
        headers: { 'X-Correlation-ID': queryArg['X-Correlation-ID'] },
      }),
    }),
    getJobSummary: build.query<GetJobSummaryApiResponse, GetJobSummaryApiArg>({
      query: (queryArg) => ({
        url: `/jobs/${queryArg.jobUuid}`,
        headers: { 'X-Correlation-ID': queryArg['X-Correlation-ID'] },
      }),
    }),
    postJobStop: build.mutation<PostJobStopApiResponse, PostJobStopApiArg>({
      query: (queryArg) => ({
        url: `/jobs/${queryArg.jobUuid}/stop`,
        method: 'POST',
        headers: { 'X-Correlation-ID': queryArg['X-Correlation-ID'] },
      }),
    }),
    getJobLogsFile: build.query<
      GetJobLogsFileApiResponse,
      GetJobLogsFileApiArg
    >({
      query: (queryArg) => ({
        url: `/jobs/${queryArg.jobUuid}/logs/${queryArg.logFile}`,
        headers: { 'X-Correlation-ID': queryArg['X-Correlation-ID'] },
      }),
    }),
    getSimulationReadAll: build.query<
      SimulationSummaryUI[],
      GetSimulationReadAllApiArg
    >({
      query: (queryArg) => ({
        url: `/models/${queryArg.modelUuid}/simulations`,
      }),
      transformResponse: convertGetSimulationReadAll,
    }),
    postSimulationCreate: build.mutation<
      PostSimulationCreateApiResponse,
      PostSimulationCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/models/${queryArg.modelUuid}/simulations`,
        method: 'POST',
        body: queryArg.simulationCreateRequest,
        headers: { 'X-Correlation-ID': queryArg['X-Correlation-ID'] },
      }),
    }),
    getSimulationCachedRead: build.query<
      GetSimulationCachedReadApiResponse,
      GetSimulationCachedReadApiArg
    >({
      query: (queryArg) => ({
        url: `/models/${queryArg.modelUuid}/simulations/cached`,
      }),
    }),
    getSimulationReadByUuid: build.query<
      GetSimulationReadByUuidApiResponse,
      GetSimulationReadByUuidApiArg
    >({
      query: (queryArg) => ({
        url: `/models/${queryArg.modelUuid}/simulations/${queryArg.simulationUuid}`,
        headers: { 'X-Correlation-ID': queryArg['X-Correlation-ID'] },
      }),
    }),
    putSimulationUpdate: build.mutation<
      PutSimulationUpdateApiResponse,
      PutSimulationUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/models/${queryArg.modelUuid}/simulations/${queryArg.simulationUuid}`,
        method: 'PUT',
        body: queryArg.simulationUpdateRequest,
      }),
    }),
    getSimulationArtifactsReadByUuid: build.query<
      GetSimulationArtifactsReadByUuidApiResponse,
      GetSimulationArtifactsReadByUuidApiArg
    >({
      query: (queryArg) => ({
        url: `/models/${queryArg.modelUuid}/simulations/${queryArg.simulationUuid}/artifacts`,
      }),
    }),
    getSimulationSignalsReadByUuid: build.query<
      GetSimulationSignalsReadByUuidApiResponse,
      GetSimulationSignalsReadByUuidApiArg
    >({
      query: (queryArg) => ({
        url: `/models/${queryArg.modelUuid}/simulations/${queryArg.simulationUuid}/signals`,
      }),
    }),
    getSimulationCsvResultsInfoReadByUuid: build.query<
      GetSimulationCsvResultsInfoReadByUuidApiResponse,
      GetSimulationCsvResultsInfoReadByUuidApiArg
    >({
      query: (queryArg) => ({
        url: `/models/${queryArg.modelUuid}/simulations/${queryArg.simulationUuid}/csv_results_info`,
      }),
    }),
    postSimulationEventCreate: build.mutation<
      PostSimulationEventCreateApiResponse,
      PostSimulationEventCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/models/${queryArg.modelUuid}/simulations/${queryArg.simulationUuid}/events`,
        method: 'POST',
        body: queryArg.simulationEventCreateRequest,
        headers: { 'X-Correlation-ID': queryArg['X-Correlation-ID'] },
      }),
    }),
    postSimulationHashedFileCreate: build.mutation<
      PostSimulationHashedFileCreateApiResponse,
      PostSimulationHashedFileCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/models/${queryArg.modelUuid}/simulations/${queryArg.simulationUuid}/hashed_files`,
        method: 'POST',
        body: queryArg.simulationHashedFile,
      }),
    }),
    getSimulationProgressByUuid: build.query<
      GetSimulationProgressByUuidApiResponse,
      GetSimulationProgressByUuidApiArg
    >({
      query: (queryArg) => ({
        url: `/models/${queryArg.modelUuid}/simulations/${queryArg.simulationUuid}/progress`,
        headers: { 'X-Correlation-ID': queryArg['X-Correlation-ID'] },
      }),
    }),
    getSimulationLogsReadByUuid: build.query<
      GetSimulationLogsReadByUuidApiResponse,
      GetSimulationLogsReadByUuidApiArg
    >({
      query: (queryArg) => ({
        url: `/models/${queryArg.modelUuid}/simulations/${queryArg.simulationUuid}/logs`,
        headers: { 'X-Correlation-ID': queryArg['X-Correlation-ID'] },
      }),
    }),
    getSimulationLogFileReadByUuid: build.query<
      GetSimulationLogFileReadByUuidApiResponse,
      GetSimulationLogFileReadByUuidApiArg
    >({
      query: (queryArg) => ({
        url: `/models/${queryArg.modelUuid}/simulations/${queryArg.simulationUuid}/logs/${queryArg.simulationJsonLogFile}`,
        headers: { 'X-Correlation-ID': queryArg['X-Correlation-ID'] },
      }),
    }),
    getSimulationLogsShortReadByUuid: build.query<
      GetSimulationLogsShortReadByUuidApiResponse,
      GetSimulationLogsShortReadByUuidApiArg
    >({
      query: (queryArg) => ({
        url: `/models/${queryArg.modelUuid}/simulations/${queryArg.simulationUuid}/logs/short`,
        headers: { 'X-Correlation-ID': queryArg['X-Correlation-ID'] },
      }),
    }),
    putSimulationParametersUpdate: build.mutation<
      PutSimulationParametersUpdateApiResponse,
      PutSimulationParametersUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/models/${queryArg.modelUuid}/simulations/${queryArg.simulationUuid}/parameters`,
        method: 'PUT',
        body: queryArg.simulationParametersRequest,
      }),
    }),
    getSimulationProcessResultsReadByUuid: build.query<
      GetSimulationProcessResultsReadByUuidApiResponse,
      GetSimulationProcessResultsReadByUuidApiArg
    >({
      query: (queryArg) => ({
        url: `/models/${queryArg.modelUuid}/simulations/${queryArg.simulationUuid}/process_results`,
        params: {
          signal_names: queryArg.signalNames,
          from_time: queryArg.fromTime,
          to_time: queryArg.toTime,
          downsampling_algorithm: queryArg.downsamplingAlgorithm,
          threshold: queryArg.threshold,
          threshold_outside_bounds: queryArg.thresholdOutsideBounds,
          gen_charts: queryArg.genCharts,
          final_values: queryArg.finalValues,
          combined_vectors: queryArg.combinedVectors,
          files: queryArg.files,
          offset: queryArg.offset,
        },
      }),
    }),
    getSimulationSignalTypesByPathReadByUuid: build.query<
      GetSimulationSignalTypesByPathReadByUuidApiResponse,
      GetSimulationSignalTypesByPathReadByUuidApiArg
    >({
      query: (queryArg) => ({
        url: `/models/${queryArg.modelUuid}/simulations/${queryArg.simulationUuid}/signal_types_path`,
        headers: { 'X-Correlation-ID': queryArg['X-Correlation-ID'] },
      }),
    }),
    postSimulationStartByUuid: build.mutation<
      PostSimulationStartByUuidApiResponse,
      PostSimulationStartByUuidApiArg
    >({
      query: (queryArg) => ({
        url: `/models/${queryArg.modelUuid}/simulations/${queryArg.simulationUuid}/start`,
        method: 'POST',
        body: queryArg.simulationStartRequest,
        headers: { 'X-Correlation-ID': queryArg['X-Correlation-ID'] },
      }),
    }),
    getSimulationTimeModeAssignmentByPathReadByUuid: build.query<
      GetSimulationTimeModeAssignmentByPathReadByUuidApiResponse,
      GetSimulationTimeModeAssignmentByPathReadByUuidApiArg
    >({
      query: (queryArg) => ({
        url: `/models/${queryArg.modelUuid}/simulations/${queryArg.simulationUuid}/time_mode_assignment_path`,
        headers: { 'X-Correlation-ID': queryArg['X-Correlation-ID'] },
      }),
    }),
    getSnapshotReadAll: build.query<
      DiagramVersionItemData[],
      GetSnapshotReadAllApiArg
    >({
      query: (queryArg) => ({
        url: `/models/${queryArg.modelUuid}/snapshots`,
        params: {
          kind: queryArg.kind,
          max_autosave_count: queryArg.maxAutosaveCount,
          max_count: queryArg.maxCount,
        },
      }),
      transformResponse: convertGetSnapshotReadAll,
    }),
    postSnapshotCreate: build.mutation<
      PostSnapshotCreateApiResponse,
      PostSnapshotCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/models/${queryArg.modelUuid}/snapshots`,
        method: 'POST',
        body: queryArg.snapshotCreateRequest,
        params: { kind: queryArg.kind },
      }),
    }),
    deleteSnapshotByUuid: build.mutation<
      DeleteSnapshotByUuidApiResponse,
      DeleteSnapshotByUuidApiArg
    >({
      query: (queryArg) => ({
        url: `/models/${queryArg.modelUuid}/snapshots/${queryArg.snapshotUuid}`,
        method: 'DELETE',
        params: { kind: queryArg.kind },
      }),
    }),
    getSnapshotReadByUuid: build.query<
      DiagramVersionFull,
      GetSnapshotReadByUuidApiArg
    >({
      query: (queryArg) => ({
        url: `/models/${queryArg.modelUuid}/snapshots/${queryArg.snapshotUuid}`,
        params: { kind: queryArg.kind },
      }),
      transformResponse: convertGetSnapshotReadByUuid,
    }),
    postSnapshotRestoreByUuid: build.mutation<
      PostSnapshotRestoreByUuidApiResponse,
      PostSnapshotRestoreByUuidApiArg
    >({
      query: (queryArg) => ({
        url: `/models/${queryArg.modelUuid}/snapshots/${queryArg.snapshotUuid}/restore`,
        method: 'POST',
        body: queryArg.snapshotRestoreRequest,
        params: { kind: queryArg.kind },
      }),
    }),
    putSnapshotSummaryUpdateByUuid: build.mutation<
      PutSnapshotSummaryUpdateByUuidApiResponse,
      PutSnapshotSummaryUpdateByUuidApiArg
    >({
      query: (queryArg) => ({
        url: `/models/${queryArg.modelUuid}/snapshots/${queryArg.snapshotUuid}/summary`,
        method: 'PUT',
        body: queryArg.snapshotSummaryUpdateRequest,
        params: { kind: queryArg.kind },
      }),
    }),
    putModelSummaryUpdateByUuid: build.mutation<
      PutModelSummaryUpdateByUuidApiResponse,
      PutModelSummaryUpdateByUuidApiArg
    >({
      query: (queryArg) => ({
        url: `/models/${queryArg.modelUuid}/summary`,
        method: 'PUT',
        body: queryArg.modelSummaryUpdateRequest,
      }),
    }),
    getModelWithSubmodelsReadByUuid: build.query<
      GetModelWithSubmodelsReadByUuidApiResponse,
      GetModelWithSubmodelsReadByUuidApiArg
    >({
      query: (queryArg) => ({
        url: `/models_with_submodels/${queryArg.modelUuid}`,
      }),
    }),
    getNodeApiStatus: build.query<
      GetNodeApiStatusApiResponse,
      GetNodeApiStatusApiArg
    >({
      query: () => ({ url: `/nodeapi/status` }),
    }),
    getMigrationServiceStatus: build.query<
      GetMigrationServiceStatusApiResponse,
      GetMigrationServiceStatusApiArg
    >({
      query: () => ({ url: `/migration-service/status` }),
    }),
    postMigrationServiceMigrateModel: build.mutation<
      PostMigrationServiceMigrateModelApiResponse,
      PostMigrationServiceMigrateModelApiArg
    >({
      query: (queryArg) => ({
        url: `/migration-service/migrate-model`,
        method: 'POST',
        body: queryArg.migrationRequest,
      }),
    }),
    getSubmodelsListForModelParent: build.query<
      SubmodelInfoUI[],
      GetSubmodelsListForModelParentApiArg
    >({
      query: (queryArg) => ({
        url: `/project/${queryArg.projectUuid}/models/${queryArg.modelUuid}/children_submodels`,
        params: { parent_kind: queryArg.parentKind },
      }),
      transformResponse: convertGetSubmodelsListForModelParent,
    }),
    getSubmodelsList: build.query<SubmodelInfoLiteUI[], GetSubmodelsListApiArg>(
      {
        query: (queryArg) => ({
          url: `/project/${queryArg.projectUuid}/submodels`,
          params: { name: queryArg.name },
        }),
        transformResponse: convertGetSubmodelsList,
      },
    ),
    postSubmodelCreate: build.mutation<
      PostSubmodelCreateApiResponse,
      PostSubmodelCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/project/${queryArg.projectUuid}/submodels`,
        method: 'POST',
        body: queryArg.submodelCreateRequest,
      }),
    }),
    deleteSubmodel: build.mutation<
      DeleteSubmodelApiResponse,
      DeleteSubmodelApiArg
    >({
      query: (queryArg) => ({
        url: `/project/${queryArg.projectUuid}/submodels/${queryArg.submodelUuid}`,
        method: 'DELETE',
        params: { force: queryArg.force },
      }),
    }),
    getSubmodel: build.query<SubmodelFullUI, GetSubmodelApiArg>({
      query: (queryArg) => ({
        url: `/project/${queryArg.projectUuid}/submodels/${queryArg.submodelUuid}`,
      }),
      transformResponse: convertGetSubmodel,
    }),
    putSubmodelUpdate: build.mutation<
      PutSubmodelUpdateApiResponse,
      PutSubmodelUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/project/${queryArg.projectUuid}/submodels/${queryArg.submodelUuid}`,
        method: 'PUT',
        body: queryArg.submodelUpdateRequest,
      }),
    }),
    getSubmodelsListForSubmodelParent: build.query<
      SubmodelInfoUI[],
      GetSubmodelsListForSubmodelParentApiArg
    >({
      query: (queryArg) => ({
        url: `/project/${queryArg.projectUuid}/submodels/${queryArg.submodelUuid}/children_submodels`,
      }),
      transformResponse: convertGetSubmodelsListForSubmodelParent,
    }),
    postSubmodelCopy: build.mutation<
      PostSubmodelCopyApiResponse,
      PostSubmodelCopyApiArg
    >({
      query: (queryArg) => ({
        url: `/project/${queryArg.projectUuid}/submodels/${queryArg.submodelUuid}/copy`,
        method: 'POST',
        body: queryArg.submodelDuplicateRequest,
      }),
    }),
    getSubmodelInfo: build.query<SubmodelInfoUI, GetSubmodelInfoApiArg>({
      query: (queryArg) => ({
        url: `/project/${queryArg.projectUuid}/submodels/${queryArg.submodelUuid}/info`,
      }),
      transformResponse: convertGetSubmodelInfo,
    }),
    getParentReferencesToSubmodel: build.query<
      GetParentReferencesToSubmodelApiResponse,
      GetParentReferencesToSubmodelApiArg
    >({
      query: (queryArg) => ({
        url: `/project/${queryArg.projectUuid}/submodels/${queryArg.submodelUuid}/references`,
      }),
    }),
    getSubmodelWithSubmodels: build.query<
      GetSubmodelWithSubmodelsApiResponse,
      GetSubmodelWithSubmodelsApiArg
    >({
      query: (queryArg) => ({
        url: `/project/${queryArg.projectUuid}/submodels_with_submodels/${queryArg.submodelUuid}`,
      }),
    }),
    getProjectReadAll: build.query<Project[], GetProjectReadAllApiArg>({
      query: (queryArg) => ({
        url: `/projects`,
        params: { user_uuid: queryArg.userUuid },
      }),
      transformResponse: convertGetProjectReadAll,
    }),
    postProjectCreate: build.mutation<
      PostProjectCreateApiResponse,
      PostProjectCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/projects`,
        method: 'POST',
        body: queryArg.projectCreateRequest,
      }),
    }),
    deleteProjectByUuid: build.mutation<
      DeleteProjectByUuidApiResponse,
      DeleteProjectByUuidApiArg
    >({
      query: (queryArg) => ({
        url: `/projects/${queryArg.projectUuid}`,
        method: 'DELETE',
      }),
    }),
    getProjectReadByUuid: build.query<Project, GetProjectReadByUuidApiArg>({
      query: (queryArg) => ({ url: `/projects/${queryArg.projectUuid}` }),
      transformResponse: convertAPIProjectToProject,
    }),
    putProjectUpdateByUuid: build.mutation<
      PutProjectUpdateByUuidApiResponse,
      PutProjectUpdateByUuidApiArg
    >({
      query: (queryArg) => ({
        url: `/projects/${queryArg.projectUuid}`,
        method: 'PUT',
        body: queryArg.projectUpdateRequest,
      }),
    }),
    postProjectCopyByUuid: build.mutation<
      PostProjectCopyByUuidApiResponse,
      PostProjectCopyByUuidApiArg
    >({
      query: (queryArg) => ({
        url: `/projects/${queryArg.projectUuid}/copy`,
        method: 'POST',
        body: queryArg.projectCreateRequest,
      }),
    }),
    getDataIntegrationsReadAll: build.query<
      GetDataIntegrationsReadAllApiResponse,
      GetDataIntegrationsReadAllApiArg
    >({
      query: (queryArg) => ({
        url: `/projects/${queryArg.projectUuid}/data_integrations`,
      }),
    }),
    postDataIntegrationsCreate: build.mutation<
      PostDataIntegrationsCreateApiResponse,
      PostDataIntegrationsCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/projects/${queryArg.projectUuid}/data_integrations`,
        method: 'POST',
        body: queryArg.dataIntegrationCreateRequest,
      }),
    }),
    getDataIntegrationsObjectsReadAll: build.query<
      GetDataIntegrationsObjectsReadAllApiResponse,
      GetDataIntegrationsObjectsReadAllApiArg
    >({
      query: (queryArg) => ({
        url: `/projects/${queryArg.projectUuid}/data_integrations/${queryArg.dataIntegrationUuid}/objects`,
      }),
    }),
    getProjectExportByUuid: build.query<
      GetProjectExportByUuidApiResponse,
      GetProjectExportByUuidApiArg
    >({
      query: (queryArg) => ({
        url: `/projects/${queryArg.projectUuid}/export`,
      }),
    }),
    postFileCreate: build.mutation<
      PostFileCreateApiResponse,
      PostFileCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/projects/${queryArg.projectUuid}/files`,
        method: 'POST',
        body: queryArg.fileCreateRequest,
      }),
    }),
    deleteFileByUuid: build.mutation<
      DeleteFileByUuidApiResponse,
      DeleteFileByUuidApiArg
    >({
      query: (queryArg) => ({
        url: `/projects/${queryArg.projectUuid}/files/${queryArg.fileUuid}`,
        method: 'DELETE',
      }),
    }),
    getFileReadByUuid: build.query<
      GetFileReadByUuidApiResponse,
      GetFileReadByUuidApiArg
    >({
      query: (queryArg) => ({
        url: `/projects/${queryArg.projectUuid}/files/${queryArg.fileUuid}`,
      }),
    }),
    putFileUpdateByUuid: build.mutation<
      PutFileUpdateByUuidApiResponse,
      PutFileUpdateByUuidApiArg
    >({
      query: (queryArg) => ({
        url: `/projects/${queryArg.projectUuid}/files/${queryArg.fileUuid}`,
        method: 'PUT',
        body: queryArg.fileUpdateRequest,
      }),
    }),
    putFileContentUpdateByUuid: build.mutation<
      PutFileContentUpdateByUuidApiResponse,
      PutFileContentUpdateByUuidApiArg
    >({
      query: (queryArg) => ({
        url: `/projects/${queryArg.projectUuid}/files/${queryArg.fileUuid}/content`,
        method: 'PUT',
        body: queryArg.fileContentUpdateRequest,
      }),
    }),
    getFileDownloadByUuid: build.query<
      GetFileDownloadByUuidApiResponse,
      GetFileDownloadByUuidApiArg
    >({
      query: (queryArg) => ({
        url: `/projects/${queryArg.projectUuid}/files/${queryArg.fileUuid}/download`,
      }),
    }),
    getFileDirectDownload: build.query<
      GetFileDirectDownloadApiResponse,
      GetFileDirectDownloadApiArg
    >({
      query: (queryArg) => ({
        url: `/projects/${queryArg.projectUuid}/files/direct_download`,
        params: { file_name: queryArg.fileName, redirect: queryArg.redirect },
      }),
    }),
    postFileProcessByUuid: build.mutation<
      PostFileProcessByUuidApiResponse,
      PostFileProcessByUuidApiArg
    >({
      query: (queryArg) => ({
        url: `/projects/${queryArg.projectUuid}/files/${queryArg.fileUuid}/process`,
        method: 'POST',
      }),
    }),
    postFileCopyByUuid: build.mutation<
      PostFileCopyByUuidApiResponse,
      PostFileCopyByUuidApiArg
    >({
      query: (queryArg) => ({
        url: `/projects/${queryArg.projectUuid}/files/${queryArg.fileUuid}/copy`,
        method: 'POST',
        body: queryArg.fileCopyRequest,
      }),
    }),
    postProjectImportByUuid: build.mutation<
      PostProjectImportByUuidApiResponse,
      PostProjectImportByUuidApiArg
    >({
      query: (queryArg) => ({
        url: `/projects/${queryArg.projectUuid}/import`,
        method: 'POST',
      }),
    }),
    postProjectImportProcessByUuid: build.mutation<
      PostProjectImportProcessByUuidApiResponse,
      PostProjectImportProcessByUuidApiArg
    >({
      query: (queryArg) => ({
        url: `/projects/${queryArg.projectUuid}/import/${queryArg.importUuid}/process`,
        method: 'POST',
      }),
    }),
    deleteDataIntegrationsByUuid: build.mutation<
      DeleteDataIntegrationsByUuidApiResponse,
      DeleteDataIntegrationsByUuidApiArg
    >({
      query: (queryArg) => ({
        url: `/projects/${queryArg.projectUuid}/integrations/${queryArg.dataIntegrationUuid}`,
        method: 'DELETE',
      }),
    }),
    putDataIntegrationsUpdateByUuid: build.mutation<
      PutDataIntegrationsUpdateByUuidApiResponse,
      PutDataIntegrationsUpdateByUuidApiArg
    >({
      query: (queryArg) => ({
        url: `/projects/${queryArg.projectUuid}/integrations/${queryArg.dataIntegrationUuid}`,
        method: 'PUT',
        body: queryArg.dataIntegrationUpdateRequest,
      }),
    }),
    getProjectPermissionReadAll: build.query<
      ProjectPermission[],
      GetProjectPermissionReadAllApiArg
    >({
      query: (queryArg) => ({
        url: `/projects/${queryArg.projectUuid}/permissions`,
      }),
      transformResponse: convertGetProjectPermissionReadAll,
    }),
    postProjectPermissionCreate: build.mutation<
      PostProjectPermissionCreateApiResponse,
      PostProjectPermissionCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/projects/${queryArg.projectUuid}/permissions`,
        method: 'POST',
        body: queryArg.projectPermissionCreateRequest,
      }),
    }),
    putProjectPermissionAllowAnonymousUpdateByUuid: build.mutation<
      PutProjectPermissionAllowAnonymousUpdateByUuidApiResponse,
      PutProjectPermissionAllowAnonymousUpdateByUuidApiArg
    >({
      query: (queryArg) => ({
        url: `/projects/${queryArg.projectUuid}/permissions/allow_anonymous_access`,
        method: 'PUT',
        body: queryArg.projectPermissionAllowAnonymousUpdateRequest,
      }),
    }),
    deleteProjectPermissionByUuid: build.mutation<
      DeleteProjectPermissionByUuidApiResponse,
      DeleteProjectPermissionByUuidApiArg
    >({
      query: (queryArg) => ({
        url: `/projects/${queryArg.projectUuid}/permissions/${queryArg.permissionUuid}`,
        method: 'DELETE',
      }),
    }),
    putProjectPermissionUpdateByUuid: build.mutation<
      PutProjectPermissionUpdateByUuidApiResponse,
      PutProjectPermissionUpdateByUuidApiArg
    >({
      query: (queryArg) => ({
        url: `/projects/${queryArg.projectUuid}/permissions/${queryArg.permissionUuid}`,
        method: 'PUT',
        body: queryArg.projectPermissionUpdateRequest,
      }),
    }),
    postProjectPermissionClaim: build.mutation<
      PostProjectPermissionClaimApiResponse,
      PostProjectPermissionClaimApiArg
    >({
      query: (queryArg) => ({
        url: `/projects/${queryArg.projectUuid}/permissions/${queryArg.permissionUuid}/claim`,
        method: 'POST',
        body: queryArg.projectPermissionClaimRequest,
      }),
    }),
    getRequirements: build.query<
      GetRequirementsApiResponse,
      GetRequirementsApiArg
    >({
      query: (queryArg) => ({
        url: `/projects/${queryArg.projectUuid}/requirements`,
        params: { expand: queryArg.expand },
      }),
    }),
    postRequirementsImport: build.mutation<
      PostRequirementsImportApiResponse,
      PostRequirementsImportApiArg
    >({
      query: (queryArg) => ({
        url: `/projects/${queryArg.projectUuid}/requirements/import`,
        method: 'POST',
      }),
    }),
    postRequirementsImportProcess: build.mutation<
      PostRequirementsImportProcessApiResponse,
      PostRequirementsImportProcessApiArg
    >({
      query: (queryArg) => ({
        url: `/projects/${queryArg.projectUuid}/requirements/import/${queryArg.importUuid}/process`,
        method: 'POST',
      }),
    }),
    postRequirementLinkCreate: build.mutation<
      PostRequirementLinkCreateApiResponse,
      PostRequirementLinkCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/projects/${queryArg.projectUuid}/requirement_links`,
        method: 'POST',
        params: {
          model_id: queryArg.modelId,
          block_instance_uuid: queryArg.blockInstanceUuid,
          requirement_id: queryArg.requirementId,
        },
      }),
    }),
    deleteRequirementLink: build.mutation<
      DeleteRequirementLinkApiResponse,
      DeleteRequirementLinkApiArg
    >({
      query: (queryArg) => ({
        url: `/projects/${queryArg.projectUuid}/requirement_links/${queryArg.requirementLinkId}`,
        method: 'DELETE',
      }),
    }),
    getRequirementBlockByLocation: build.query<
      GetRequirementBlockByLocationApiResponse,
      GetRequirementBlockByLocationApiArg
    >({
      query: (queryArg) => ({
        url: `/projects/${queryArg.projectUuid}/requirement_blocks/find_by_location`,
        params: {
          model_id: queryArg.modelId,
          block_instance_uuid: queryArg.blockInstanceUuid,
        },
      }),
    }),
    postProjectGitConnectRemote: build.mutation<
      PostProjectGitConnectRemoteApiResponse,
      PostProjectGitConnectRemoteApiArg
    >({
      query: (queryArg) => ({
        url: `/projects/${queryArg.projectUuid}/git/remotes`,
        method: 'POST',
        body: queryArg.projectGitConnectRequest,
      }),
    }),
    getProjectGitRemote: build.query<
      GetProjectGitRemoteApiResponse,
      GetProjectGitRemoteApiArg
    >({
      query: (queryArg) => ({
        url: `/projects/${queryArg.projectUuid}/git/remote`,
        params: { expand: queryArg.expand },
      }),
    }),
    deleteProjectGitRemote: build.mutation<
      DeleteProjectGitRemoteApiResponse,
      DeleteProjectGitRemoteApiArg
    >({
      query: (queryArg) => ({
        url: `/projects/${queryArg.projectUuid}/git/remote`,
        method: 'DELETE',
      }),
    }),
    getProjectGitRemoteFiles: build.query<
      GetProjectGitRemoteFilesApiResponse,
      GetProjectGitRemoteFilesApiArg
    >({
      query: (queryArg) => ({
        url: `/projects/${queryArg.projectUuid}/git/remote/files`,
        params: { branch: queryArg.branch },
      }),
    }),
    postProjectGitResetFromUpstream: build.mutation<
      PostProjectGitResetFromUpstreamApiResponse,
      PostProjectGitResetFromUpstreamApiArg
    >({
      query: (queryArg) => ({
        url: `/projects/${queryArg.projectUuid}/git/reset_from_upstream`,
        method: 'POST',
        body: queryArg.projectGitResetFromUpstreamRequest,
      }),
    }),
    postProjectGitPushToUpstream: build.mutation<
      PostProjectGitPushToUpstreamApiResponse,
      PostProjectGitPushToUpstreamApiArg
    >({
      query: (queryArg) => ({
        url: `/projects/${queryArg.projectUuid}/git/push_to_upstream`,
        method: 'POST',
        body: queryArg.projectGitPushToUpstreamRequest,
      }),
    }),
    postBusTypeCreate: build.mutation<
      PostBusTypeCreateApiResponse,
      PostBusTypeCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/projects/${queryArg.projectUuid}/bus_types`,
        method: 'POST',
        body: queryArg.busTypeCreateRequest,
      }),
    }),
    getBusType: build.query<GetBusTypeApiResponse, GetBusTypeApiArg>({
      query: (queryArg) => ({
        url: `/projects/${queryArg.projectUuid}/bus_types/${queryArg.busTypeId}`,
      }),
    }),
    putBusTypeUpdate: build.mutation<
      PutBusTypeUpdateApiResponse,
      PutBusTypeUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/projects/${queryArg.projectUuid}/bus_types/${queryArg.busTypeId}`,
        method: 'PUT',
        body: queryArg.busTypeUpdateRequest,
      }),
    }),
    deleteBusType: build.mutation<
      DeleteBusTypeApiResponse,
      DeleteBusTypeApiArg
    >({
      query: (queryArg) => ({
        url: `/projects/${queryArg.projectUuid}/bus_types/${queryArg.busTypeId}`,
        method: 'DELETE',
      }),
    }),
    postSentry: build.mutation<PostSentryApiResponse, PostSentryApiArg>({
      query: (queryArg) => ({
        url: `/sentry`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    getSimulationsForUserReadAll: build.query<
      SimulationSummariesUI,
      GetSimulationsForUserReadAllApiArg
    >({
      query: (queryArg) => ({
        url: `/simulations`,
        params: {
          max_past_sims: queryArg.maxPastSims,
          from_time: queryArg.fromTime,
        },
      }),
      transformResponse: convertGetSimulationsForUserReadAll,
    }),
    getSimulationDumpByUuid: build.query<
      GetSimulationDumpByUuidApiResponse,
      GetSimulationDumpByUuidApiArg
    >({
      query: (queryArg) => ({
        url: `/simulations/${queryArg.simulationUuid}/dump`,
        params: { redirect: queryArg.redirect },
      }),
    }),
    getStatus: build.query<GetStatusApiResponse, GetStatusApiArg>({
      query: () => ({ url: `/status` }),
    }),
    getSubmodelsFetch: build.query<
      GetSubmodelsFetchApiResponse,
      GetSubmodelsFetchApiArg
    >({
      query: (queryArg) => ({
        url: `/submodels/fetch`,
        params: { submodels: queryArg.submodels },
      }),
    }),
    postSubmodelsFetch: build.mutation<
      VersionedSubmodelsResponse,
      PostSubmodelsFetchApiArg
    >({
      query: (queryArg) => ({
        url: `/submodels/fetch`,
        method: 'POST',
        body: queryArg.submodelFetchRequest,
      }),
      transformResponse: convertPostSubmodelsFetch,
    }),
    getSubmodelSnapshotReadAll: build.query<
      GetSubmodelSnapshotReadAllApiResponse,
      GetSubmodelSnapshotReadAllApiArg
    >({
      query: (queryArg) => ({
        url: `/submodels/${queryArg.submodelUuid}/snapshots`,
        params: {
          max_autosave_count: queryArg.maxAutosaveCount,
          max_count: queryArg.maxCount,
        },
      }),
    }),
    postSubmodelSnapshotCreate: build.mutation<
      PostSubmodelSnapshotCreateApiResponse,
      PostSubmodelSnapshotCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/submodels/${queryArg.submodelUuid}/snapshots`,
        method: 'POST',
        body: queryArg.snapshotCreateRequest,
      }),
    }),
    deleteSubmodelSnapshotByUuid: build.mutation<
      DeleteSubmodelSnapshotByUuidApiResponse,
      DeleteSubmodelSnapshotByUuidApiArg
    >({
      query: (queryArg) => ({
        url: `/submodels/${queryArg.submodelUuid}/snapshots/${queryArg.snapshotUuid}`,
        method: 'DELETE',
      }),
    }),
    getSubmodelSnapshotReadByUuid: build.query<
      GetSubmodelSnapshotReadByUuidApiResponse,
      GetSubmodelSnapshotReadByUuidApiArg
    >({
      query: (queryArg) => ({
        url: `/submodels/${queryArg.submodelUuid}/snapshots/${queryArg.snapshotUuid}`,
      }),
    }),
    postSubmodelSnapshotRestoreByUuid: build.mutation<
      PostSubmodelSnapshotRestoreByUuidApiResponse,
      PostSubmodelSnapshotRestoreByUuidApiArg
    >({
      query: (queryArg) => ({
        url: `/submodels/${queryArg.submodelUuid}/snapshots/${queryArg.snapshotUuid}/restore`,
        method: 'POST',
        body: queryArg.snapshotRestoreRequest,
      }),
    }),
    putSubmodelSnapshotSummaryUpdateByUuid: build.mutation<
      PutSubmodelSnapshotSummaryUpdateByUuidApiResponse,
      PutSubmodelSnapshotSummaryUpdateByUuidApiArg
    >({
      query: (queryArg) => ({
        url: `/submodels/${queryArg.submodelUuid}/snapshots/${queryArg.snapshotUuid}/summary`,
        method: 'PUT',
        body: queryArg.snapshotSummaryUpdateRequest,
      }),
    }),
    getSubmodelCommentsReadAll: build.query<
      GetSubmodelCommentsReadAllApiResponse,
      GetSubmodelCommentsReadAllApiArg
    >({
      query: (queryArg) => ({
        url: `/submodels/${queryArg.submodelUuid}/comments`,
      }),
    }),
    postSubmodelCommentCreate: build.mutation<
      PostSubmodelCommentCreateApiResponse,
      PostSubmodelCommentCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/submodels/${queryArg.submodelUuid}/comments`,
        method: 'POST',
        body: queryArg.commentCreateRequest,
      }),
    }),
    deleteSubmodelCommentByUuid: build.mutation<
      DeleteSubmodelCommentByUuidApiResponse,
      DeleteSubmodelCommentByUuidApiArg
    >({
      query: (queryArg) => ({
        url: `/submodels/${queryArg.submodelUuid}/comments/${queryArg.commentUuid}`,
        method: 'DELETE',
      }),
    }),
    getTemplateProjectsReadAll: build.query<
      GetTemplateProjectsReadAllApiResponse,
      GetTemplateProjectsReadAllApiArg
    >({
      query: () => ({ url: `/template_projects` }),
    }),
    postTemplateProjectsCopyByUuid: build.mutation<
      PostTemplateProjectsCopyByUuidApiResponse,
      PostTemplateProjectsCopyByUuidApiArg
    >({
      query: (queryArg) => ({
        url: `/template_projects/${queryArg.templateProjectUuid}/copy`,
        method: 'POST',
        body: queryArg.projectCreateRequest,
      }),
    }),
    postClerkWhitelistIdentifierCreate: build.mutation<
      PostClerkWhitelistIdentifierCreateApiResponse,
      PostClerkWhitelistIdentifierCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/user/clerk_whitelist`,
        method: 'POST',
        body: queryArg.addUserToWhitelistRequest,
      }),
    }),
    deleteClerkWhitelistIdentifierById: build.mutation<
      DeleteClerkWhitelistIdentifierByIdApiResponse,
      DeleteClerkWhitelistIdentifierByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/user/clerk_whitelist/${queryArg.identifierId}`,
        method: 'DELETE',
      }),
    }),
    getUserOptions: build.query<
      GetUserOptionsApiResponse,
      GetUserOptionsApiArg
    >({
      query: () => ({ url: `/user/options` }),
    }),
    getUserPreferences: build.query<
      GetUserPreferencesApiResponse,
      GetUserPreferencesApiArg
    >({
      query: () => ({ url: `/user/preferences` }),
    }),
    putUserPreferences: build.mutation<
      PutUserPreferencesApiResponse,
      PutUserPreferencesApiArg
    >({
      query: (queryArg) => ({
        url: `/user/preferences`,
        method: 'PUT',
        body: queryArg.jsonRawData,
      }),
    }),
    getUserProfile: build.query<
      GetUserProfileApiResponse,
      GetUserProfileApiArg
    >({
      query: () => ({ url: `/user/profile` }),
    }),
    postUserRegister: build.mutation<
      PostUserRegisterApiResponse,
      PostUserRegisterApiArg
    >({
      query: (queryArg) => ({
        url: `/user/register`,
        method: 'POST',
        body: queryArg.userRegisterRequest,
      }),
    }),
    postUserCredentials: build.mutation<
      PostUserCredentialsApiResponse,
      PostUserCredentialsApiArg
    >({
      query: (queryArg) => ({
        url: `/user/credentials`,
        method: 'POST',
        body: queryArg.userCredentialsRequest,
      }),
    }),
    getListUserCredentials: build.query<
      GetListUserCredentialsApiResponse,
      GetListUserCredentialsApiArg
    >({
      query: () => ({ url: `/user/credentials` }),
    }),
    deleteUserCredentials: build.mutation<
      DeleteUserCredentialsApiResponse,
      DeleteUserCredentialsApiArg
    >({
      query: (queryArg) => ({
        url: `/user/credentials/${queryArg.name}`,
        method: 'DELETE',
      }),
    }),
    postUserSignBetaTos: build.mutation<
      PostUserSignBetaTosApiResponse,
      PostUserSignBetaTosApiArg
    >({
      query: (queryArg) => ({
        url: `/user/sign_beta_tos`,
        method: 'POST',
        body: queryArg.userSignBetaTosRequest,
      }),
    }),
    getSubscriptionStatus: build.query<
      GetSubscriptionStatusApiResponse,
      GetSubscriptionStatusApiArg
    >({
      query: () => ({ url: `/user/subscription_status` }),
    }),
    postSubscriptionCheckoutUrls: build.mutation<
      PostSubscriptionCheckoutUrlsApiResponse,
      PostSubscriptionCheckoutUrlsApiArg
    >({
      query: (queryArg) => ({
        url: `/user/subscription_checkout_urls`,
        method: 'POST',
        body: queryArg.userSubscriptionCheckoutUrlsRequest,
      }),
    }),
    postStripeManagementInfo: build.mutation<
      PostStripeManagementInfoApiResponse,
      PostStripeManagementInfoApiArg
    >({
      query: (queryArg) => ({
        url: `/user/stripe_management_info`,
        method: 'POST',
        body: queryArg.userStripeManagementInfoRequest,
      }),
    }),
    getUserStatistics: build.query<
      GetUserStatisticsApiResponse,
      GetUserStatisticsApiArg
    >({
      query: () => ({ url: `/user/statistics` }),
    }),
    postChatSession: build.mutation<
      PostChatSessionApiResponse,
      PostChatSessionApiArg
    >({
      query: (queryArg) => ({
        url: `/chat/session`,
        method: 'POST',
        body: queryArg.chatSessionCreateRequest,
      }),
    }),
    putChatSession: build.mutation<
      PutChatSessionApiResponse,
      PutChatSessionApiArg
    >({
      query: (queryArg) => ({
        url: `/chat/session`,
        method: 'PUT',
        body: queryArg.chatSession,
      }),
    }),
    getChatSessionByUuid: build.query<
      GetChatSessionByUuidApiResponse,
      GetChatSessionByUuidApiArg
    >({
      query: (queryArg) => ({
        url: `/chat/session/${queryArg.chatSessionUuid}`,
      }),
    }),
    getChatSessionLast: build.query<
      GetChatSessionLastApiResponse,
      GetChatSessionLastApiArg
    >({
      query: (queryArg) => ({
        url: `/chat/session/${queryArg.projectUuid}/${queryArg.modelUuid}`,
        params: { subdiagram_path: queryArg.subdiagramPath },
      }),
    }),
    getChatSessionsErrors: build.query<
      GetChatSessionsErrorsApiResponse,
      GetChatSessionsErrorsApiArg
    >({
      query: (queryArg) => ({
        url: `/chat/sessions/errors`,
        params: { start_date: queryArg.startDate, end_date: queryArg.endDate },
      }),
    }),
    postChatAbort: build.mutation<
      PostChatAbortApiResponse,
      PostChatAbortApiArg
    >({
      query: (queryArg) => ({
        url: `/chat/stream/${queryArg.streamUuid}/abort`,
        method: 'POST',
      }),
    }),
    postDocumentationBlockSearch: build.mutation<
      PostDocumentationBlockSearchApiResponse,
      PostDocumentationBlockSearchApiArg
    >({
      query: (queryArg) => ({
        url: `/chat/documentation/block/search`,
        method: 'POST',
        body: queryArg.documentationBlockSearchRequest,
      }),
    }),
    postChatFileCreate: build.mutation<
      PostChatFileCreateApiResponse,
      PostChatFileCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/chat/file`,
        method: 'POST',
        body: queryArg.fileCreateRequest,
      }),
    }),
    stripeWebhooksHandler: build.mutation<
      StripeWebhooksHandlerApiResponse,
      StripeWebhooksHandlerApiArg
    >({
      query: (queryArg) => ({
        url: `/stripe-webhooks`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as generatedApi };
export const {
  useGetAdminAuditLogQuery,
  useGetAdminGlobalProjectsQuery,
  usePostAdminGlobalProjectCreateMutation,
  useDeleteAdminGlobalProjectByUuidMutation,
  useGetAdminPlatformInfoQuery,
  useGetAdminSimulationJobsListQuery,
  useDeleteAdminSimulationJobsByUuidMutation,
  useGetUserOptionOverridesReadAllQuery,
  usePostUserOptionOverridesCreateMutation,
  useDeleteUserOptionOverridesByUuidMutation,
  usePutUserOptionOverridesUpdateByUuidMutation,
  useGetUserOptionsReadAllQuery,
  usePostUserOptionsCreateMutation,
  useDeleteUserOptionsByUuidMutation,
  usePutUserOptionsUpdateByUuidMutation,
  useGetUserOptionOverridingUsersListQuery,
  useGetAdminUsersReadAllQuery,
  usePatchAdminUserByUuidMutation,
  useDeleteUserEntirelyAdminUseOnlyMutation,
  usePostCreatePreRegMetaMutation,
  useGetPreRegMetasAllQuery,
  useDeletePreRegMetaByEmailMutation,
  useGetAuthTokensReadAllQuery,
  usePostAuthTokensMutation,
  useDeleteAuthTokenByUuidMutation,
  usePostCodeAutocompleteMutation,
  useDeleteEntityPreferencesMutation,
  useGetEntityPreferencesQuery,
  usePutEntityPreferencesMutation,
  usePostGitHubCallbackMutation,
  useGetGitHubReposQuery,
  useGetGlobalSubmodelsReadQuery,
  usePutHashedFilesUploadByIdMutation,
  useGetOpenApiQuery,
  usePostModelCreateMutation,
  useDeleteModelByUuidMutation,
  useGetModelReadByUuidQuery,
  usePutModelUpdateByUuidMutation,
  useGetCommentsReadAllQuery,
  usePostCommentCreateMutation,
  useDeleteCommentByUuidMutation,
  usePutModelConfigurationUpdateByUuidMutation,
  usePostModelCopyByUuidMutation,
  useGetModelParametersByUuidQuery,
  usePutModelParametersUpdateByUuidMutation,
  usePostJobCreateMutation,
  useGetJobSummaryQuery,
  usePostJobStopMutation,
  useGetJobLogsFileQuery,
  useGetSimulationReadAllQuery,
  usePostSimulationCreateMutation,
  useGetSimulationCachedReadQuery,
  useGetSimulationReadByUuidQuery,
  usePutSimulationUpdateMutation,
  useGetSimulationArtifactsReadByUuidQuery,
  useGetSimulationSignalsReadByUuidQuery,
  useGetSimulationCsvResultsInfoReadByUuidQuery,
  usePostSimulationEventCreateMutation,
  usePostSimulationHashedFileCreateMutation,
  useGetSimulationProgressByUuidQuery,
  useGetSimulationLogsReadByUuidQuery,
  useGetSimulationLogFileReadByUuidQuery,
  useGetSimulationLogsShortReadByUuidQuery,
  usePutSimulationParametersUpdateMutation,
  useGetSimulationProcessResultsReadByUuidQuery,
  useGetSimulationSignalTypesByPathReadByUuidQuery,
  usePostSimulationStartByUuidMutation,
  useGetSimulationTimeModeAssignmentByPathReadByUuidQuery,
  useGetSnapshotReadAllQuery,
  usePostSnapshotCreateMutation,
  useDeleteSnapshotByUuidMutation,
  useGetSnapshotReadByUuidQuery,
  usePostSnapshotRestoreByUuidMutation,
  usePutSnapshotSummaryUpdateByUuidMutation,
  usePutModelSummaryUpdateByUuidMutation,
  useGetModelWithSubmodelsReadByUuidQuery,
  useGetNodeApiStatusQuery,
  useGetMigrationServiceStatusQuery,
  usePostMigrationServiceMigrateModelMutation,
  useGetSubmodelsListForModelParentQuery,
  useGetSubmodelsListQuery,
  usePostSubmodelCreateMutation,
  useDeleteSubmodelMutation,
  useGetSubmodelQuery,
  usePutSubmodelUpdateMutation,
  useGetSubmodelsListForSubmodelParentQuery,
  usePostSubmodelCopyMutation,
  useGetSubmodelInfoQuery,
  useGetParentReferencesToSubmodelQuery,
  useGetSubmodelWithSubmodelsQuery,
  useGetProjectReadAllQuery,
  usePostProjectCreateMutation,
  useDeleteProjectByUuidMutation,
  useGetProjectReadByUuidQuery,
  usePutProjectUpdateByUuidMutation,
  usePostProjectCopyByUuidMutation,
  useGetDataIntegrationsReadAllQuery,
  usePostDataIntegrationsCreateMutation,
  useGetDataIntegrationsObjectsReadAllQuery,
  useGetProjectExportByUuidQuery,
  usePostFileCreateMutation,
  useDeleteFileByUuidMutation,
  useGetFileReadByUuidQuery,
  usePutFileUpdateByUuidMutation,
  usePutFileContentUpdateByUuidMutation,
  useGetFileDownloadByUuidQuery,
  useGetFileDirectDownloadQuery,
  usePostFileProcessByUuidMutation,
  usePostFileCopyByUuidMutation,
  usePostProjectImportByUuidMutation,
  usePostProjectImportProcessByUuidMutation,
  useDeleteDataIntegrationsByUuidMutation,
  usePutDataIntegrationsUpdateByUuidMutation,
  useGetProjectPermissionReadAllQuery,
  usePostProjectPermissionCreateMutation,
  usePutProjectPermissionAllowAnonymousUpdateByUuidMutation,
  useDeleteProjectPermissionByUuidMutation,
  usePutProjectPermissionUpdateByUuidMutation,
  usePostProjectPermissionClaimMutation,
  useGetRequirementsQuery,
  usePostRequirementsImportMutation,
  usePostRequirementsImportProcessMutation,
  usePostRequirementLinkCreateMutation,
  useDeleteRequirementLinkMutation,
  useGetRequirementBlockByLocationQuery,
  usePostProjectGitConnectRemoteMutation,
  useGetProjectGitRemoteQuery,
  useDeleteProjectGitRemoteMutation,
  useGetProjectGitRemoteFilesQuery,
  usePostProjectGitResetFromUpstreamMutation,
  usePostProjectGitPushToUpstreamMutation,
  usePostBusTypeCreateMutation,
  useGetBusTypeQuery,
  usePutBusTypeUpdateMutation,
  useDeleteBusTypeMutation,
  usePostSentryMutation,
  useGetSimulationsForUserReadAllQuery,
  useGetSimulationDumpByUuidQuery,
  useGetStatusQuery,
  useGetSubmodelsFetchQuery,
  usePostSubmodelsFetchMutation,
  useGetSubmodelSnapshotReadAllQuery,
  usePostSubmodelSnapshotCreateMutation,
  useDeleteSubmodelSnapshotByUuidMutation,
  useGetSubmodelSnapshotReadByUuidQuery,
  usePostSubmodelSnapshotRestoreByUuidMutation,
  usePutSubmodelSnapshotSummaryUpdateByUuidMutation,
  useGetSubmodelCommentsReadAllQuery,
  usePostSubmodelCommentCreateMutation,
  useDeleteSubmodelCommentByUuidMutation,
  useGetTemplateProjectsReadAllQuery,
  usePostTemplateProjectsCopyByUuidMutation,
  usePostClerkWhitelistIdentifierCreateMutation,
  useDeleteClerkWhitelistIdentifierByIdMutation,
  useGetUserOptionsQuery,
  useGetUserPreferencesQuery,
  usePutUserPreferencesMutation,
  useGetUserProfileQuery,
  usePostUserRegisterMutation,
  usePostUserCredentialsMutation,
  useGetListUserCredentialsQuery,
  useDeleteUserCredentialsMutation,
  usePostUserSignBetaTosMutation,
  useGetSubscriptionStatusQuery,
  usePostSubscriptionCheckoutUrlsMutation,
  usePostStripeManagementInfoMutation,
  useGetUserStatisticsQuery,
  usePostChatSessionMutation,
  usePutChatSessionMutation,
  useGetChatSessionByUuidQuery,
  useGetChatSessionLastQuery,
  useGetChatSessionsErrorsQuery,
  usePostChatAbortMutation,
  usePostDocumentationBlockSearchMutation,
  usePostChatFileCreateMutation,
  useStripeWebhooksHandlerMutation,
} = injectedRtkApi;
