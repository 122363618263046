/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Adder: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.7496 9.45454C16.7496 9.04033 16.4138 8.70454 15.9996 8.70454C15.5853 8.70454 15.2496 9.04033 15.2496 9.45454V15.25H9.4541C9.03989 15.25 8.7041 15.5858 8.7041 16C8.7041 16.4142 9.03989 16.75 9.4541 16.75H15.2496V22.5455C15.2496 22.9597 15.5853 23.2955 15.9996 23.2955C16.4138 23.2955 16.7496 22.9597 16.7496 22.5455V16.75H22.545C22.9592 16.75 23.295 16.4142 23.295 16C23.295 15.5858 22.9592 15.25 22.545 15.25H16.7496V9.45454Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M28 16C28 22.6274 22.6274 28 16 28C9.37258 28 4 22.6274 4 16C4 9.37258 9.37258 4 16 4C22.6274 4 28 9.37258 28 16ZM26.5 16C26.5 21.799 21.799 26.5 16 26.5C10.201 26.5 5.5 21.799 5.5 16C5.5 10.201 10.201 5.5 16 5.5C21.799 5.5 26.5 10.201 26.5 16Z"
      fill={props.fill}
    />
  </svg>
);

Adder.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Adder;
