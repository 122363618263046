import { getCurrentModelRef } from 'app/sliceRefAccess/CurrentModelRef';
import * as NVG from 'nanovg-js';
import { RendererState } from 'ui/modelRendererInternals/modelRenderer';
import { LINE_COLORS } from './coloring';
import { convertZoomedScreenToWorldCoordinates } from './convertScreenToWorldCoordinates';
import { getDrawContext } from './drawPrimitives';
import { getHoveringEntities } from './getHoveringEntities';
import { keysPressed } from './keyEvents';

let frameCount = 0;
let frameCountStartTime = 0;
let lastRenderFPS = 0;
let lastNodesTime = 0;
let lastLinksTime = 0;

export function drawDebuggingInfo(
  rs: RendererState,
  nvg: NVG.Context,
  nodesTime: number,
  linksTime: number,
): void {
  if (!rs.refs.current.uiFlags.renderDebug) return;
  const ctx = getDrawContext(rs, nvg);

  frameCount++;
  if (frameCountStartTime === 0) {
    frameCountStartTime = Date.now();
  }
  const time = Date.now() - frameCountStartTime;
  const fps = frameCount / (time / 1000);
  if (time > 1000) {
    lastRenderFPS = Math.round(fps);
    frameCountStartTime = Date.now();
    frameCount = 0;
    lastNodesTime = Math.round(nodesTime);
    lastLinksTime = Math.round(linksTime);
  }

  const debugLegendX = rs.refs.current.uiFlags.isLeftSidebarOpen ? 248 : 16;
  const worldCursor = convertZoomedScreenToWorldCoordinates(
    rs.camera,
    rs.screenCursorZoomed,
  );

  const parts = [
    `Renderer: ${
      rs.refs.current.uiFlags.renderCanvas2d ? 'Canvas 2D' : 'WebGL'
    }`,
    `FPS: ${lastRenderFPS}`,
    `Size: ${rs.refs.current.canvas?.offsetWidth}x${rs.refs.current.canvas?.offsetHeight}`,
    `Mouse: ${worldCursor.x.toFixed(0)}, ${worldCursor.y.toFixed(0)}`,
    `Nodes: ${lastNodesTime}ms`,
    `Links: ${lastLinksTime}ms`,
  ];

  const keysPressedLines = JSON.stringify(keysPressed)
    .replaceAll('{', '')
    .replaceAll('}', '')
    .replaceAll('"', '')
    .replaceAll(',', '\n')
    .replaceAll(':', ': ')
    .split('\n');
  parts.push(...keysPressedLines);

  ctx.beginPath();
  ctx.rect(debugLegendX, 90, 150, parts.length * 15 + 8);
  ctx.fillColor(ctx.RGBA(255, 255, 255, 128));
  ctx.fill();

  for (let i = 0; i < parts.length; i++) {
    ctx.fontFace('archivo');
    ctx.fontSize(12);
    ctx.textAlign(NVG.Align.LEFT | NVG.Align.BASELINE);
    ctx.fillColor(LINE_COLORS.text);
    ctx.text(debugLegendX + 8, 104 + i * 15, parts[i], null);
  }

  const hoveringEntities = getHoveringEntities(
    rs.mouseState,
    worldCursor,
    rs.camera,
    rs.zoom,
    rs.refs.current.nodes,
    rs.refs.current.links,
    rs.refs.current.annotations,
    rs.refs.current.linksIndexLUT,
    rs.linksRenderFrameData,
    getCurrentModelRef().submodelPath,
  );
  // NOTE: rs.hoveringEntity is not used

  for (let entity of hoveringEntities) {
    const geom = entity.geom;
    if (!geom) continue;

    ctx.beginPath();
    const rx = (geom.x + rs.camera.x) * rs.zoom;
    const ry = (geom.y + rs.camera.y) * rs.zoom;
    const rw = geom.width * rs.zoom;
    const rh = geom.height * rs.zoom;
    ctx.rect(rx, ry, rw, rh);
    if (entity === hoveringEntities[0]) {
      ctx.strokeColor(ctx.RGBA(0, 255, 0, 128));
      ctx.stroke();
    }
    ctx.fillColor(ctx.RGBA(0, 255, 0, 32));
    ctx.fill();
  }
}
