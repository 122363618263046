import { ModelKind } from 'app/apiGenerated/generatedApiTypes';
import {
  BlockClassName,
  BlockClassNamesArray,
} from 'app/generated_types/SimulationModel';
import {
  nodeClassWithoutNamespace_displayOnly,
  printNameToSpacedDisplayName,
} from 'app/helpers';
import { useAppSelector } from 'app/hooks';
import { PseudoBlockType } from 'app/pseudo_blocks/StateMachine';
import { allPseudoBlocksData } from 'app/pseudo_blocks/allPseudoBlocksData';
import React from 'react';
import {
  getIsSearchMatch,
  shouldShowBlock,
} from 'ui/objectBrowser/librarySearch';
import { BlockLibraryDragItem } from 'ui/objectBrowser/sections/BlockLibraryDragItem';
import LibrarySubsection, {
  BlocksGrid,
} from 'ui/objectBrowser/sections/LibrarySubsection';
import { getStringSorter } from 'util/getStringSorter';

export enum LibItemDisplayType {
  Base,
  Pseudo,
}
export type LibItemDisplayUnion =
  | {
      type: LibItemDisplayType.Base;
      sortName: string;
      blockClassName: BlockClassName;
    }
  | {
      type: LibItemDisplayType.Pseudo;
      sortName: string;
      pseudoData: PseudoBlockType;
    };

// typescript compiler gets angry here if we are less verbose than this
const baseDisplayItems: LibItemDisplayUnion[] = BlockClassNamesArray.map(
  (name) => ({
    type: LibItemDisplayType.Base,
    sortName: name,
    blockClassName: name,
  }),
);
const pseudoDisplayItems: LibItemDisplayUnion[] = allPseudoBlocksData.map(
  (pseudoData) => ({
    type: LibItemDisplayType.Pseudo,
    sortName: `core.${pseudoData.displayName.trim()}`,
    pseudoData,
  }),
);

const libraryDisplayItems: LibItemDisplayUnion[] = [
  ...baseDisplayItems,
  ...pseudoDisplayItems,
].sort(getStringSorter('sortName'));

interface Props {
  lowercaseSearchString: string;
  currentSubdiagramType?: BlockClassName;
}

export const searchFoundationalBlocks = (
  lowercaseSearchString: string,
  developerModeEnabled: boolean,
  topLevelModelKind: ModelKind,
  currentSubdiagramType?: BlockClassName,
  acausalModelingEnabled?: boolean,
) =>
  libraryDisplayItems.filter((block) => {
    const blockClassName =
      block.type === LibItemDisplayType.Base
        ? block.blockClassName
        : block.pseudoData.baseBlockClassName;
    if (
      !shouldShowBlock(
        blockClassName,
        developerModeEnabled,
        topLevelModelKind,
        currentSubdiagramType,
        acausalModelingEnabled,
      )
    ) {
      return false;
    }

    if (lowercaseSearchString) {
      const searchMatch =
        getIsSearchMatch(
          printNameToSpacedDisplayName(
            nodeClassWithoutNamespace_displayOnly(blockClassName),
          ),
          lowercaseSearchString,
        ) || getIsSearchMatch(blockClassName, lowercaseSearchString);

      if (block.type === LibItemDisplayType.Pseudo) {
        return (
          searchMatch ||
          getIsSearchMatch(block.pseudoData.displayName, lowercaseSearchString)
        );
      }

      if (!searchMatch) {
        return false;
      }
    }

    return true;
  });

enum FilterLeafType {
  Tree,
  ValueCheck,
  Default,
}

type FilterTree =
  | {
      kind: FilterLeafType.Tree;
      contents: {
        [k: string]: FilterTree | undefined;
      };
    }
  | {
      kind: FilterLeafType.ValueCheck;
      label?: string;
      checker: (bcn: BlockClassName) => boolean;
    }
  | {
      kind: FilterLeafType.Default;
    };

const mlBlockClassList: BlockClassName[] = [
  'core.TensorFlow',
  'core.MLP',
  'core.SINDy',
  'core.PyTorch',
];

const integrationsBlockClassList: BlockClassName[] = [
  'core.ModelicaFMU',
  'core.PyTwin',
  'core.Ros2Publisher',
  'core.Ros2Subscriber',
  'quanser.QuanserHAL',
  'quanser.QubeServoModel',
];

const acausalDomains = [
  'electrical',
  'magnetic',
  'thermal',
  'rotational',
  'translational',
  'hydraulic',
  'pneumatic',
];

const sectionFilters: FilterTree = {
  kind: FilterLeafType.Tree,
  contents: {
    Foundational: {
      kind: FilterLeafType.Tree,
      contents: {
        Integrations: {
          kind: FilterLeafType.ValueCheck,
          checker: (n: BlockClassName) =>
            integrationsBlockClassList.includes(n),
        },
        'Machine Learning': {
          kind: FilterLeafType.ValueCheck,
          checker: (n: BlockClassName) => mlBlockClassList.includes(n),
        },
        'State Machines': {
          kind: FilterLeafType.ValueCheck,
          checker: (n: BlockClassName) => n === 'core.StateMachine',
        },
        Core: {
          kind: FilterLeafType.Default,
        },
      },
    },
    Acausal: {
      kind: FilterLeafType.Tree,
      contents: acausalDomains.reduce((acc, domain) => {
        const Domain = domain.charAt(0).toUpperCase() + domain.slice(1);
        acc[Domain] = {
          kind: FilterLeafType.ValueCheck,
          checker: (n: BlockClassName) => n.startsWith(`acausal.${domain}.`),
        };
        return acc;
      }, {} as { [k: string]: FilterTree | undefined }),
    },
  },
};

type LibDisplayTree = {
  title: string;
  count: number;
  blocks: LibItemDisplayUnion[];
  childSections: LibDisplayBranches;
};
type LibDisplayBranches = {
  [k: string]: LibDisplayTree | undefined;
};

const insertBlockDataInDisplayTree = (
  checkingFilterTree: FilterTree,
  displayItemData: LibItemDisplayUnion,
  resultTree: LibDisplayTree | undefined,
  rootLevel = true,
): boolean => {
  if (!resultTree || checkingFilterTree.kind !== FilterLeafType.Tree) {
    return false;
  }

  const filterLeafNames = Object.keys(
    checkingFilterTree.contents,
  ) as (keyof typeof checkingFilterTree.contents)[];

  for (const filterLeafKey of filterLeafNames) {
    const leaf = checkingFilterTree.contents[filterLeafKey];
    if (!leaf) continue;

    if (leaf.kind === FilterLeafType.Tree) {
      if (!resultTree.childSections[filterLeafKey]) {
        resultTree.childSections[filterLeafKey] = {
          title: filterLeafKey as string,
          count: 0,
          blocks: [],
          childSections: {},
        };
      }

      const added = insertBlockDataInDisplayTree(
        leaf,
        displayItemData,
        resultTree.childSections[filterLeafKey],
        false,
      );
      if (added) {
        resultTree.count++;
        return true;
      }
    }
    if (leaf.kind === FilterLeafType.ValueCheck) {
      const className =
        displayItemData.type === LibItemDisplayType.Base
          ? displayItemData.blockClassName
          : displayItemData.pseudoData.baseBlockClassName;

      const shouldAdd = leaf.checker(className);

      if (shouldAdd) {
        if (!resultTree.childSections[filterLeafKey]) {
          resultTree.childSections[filterLeafKey] = {
            title: filterLeafKey as string,
            count: 0,
            blocks: [],
            childSections: {},
          };
        }

        // eslint-disable-next-line
        resultTree.childSections[filterLeafKey]!.blocks.push(displayItemData);
        // eslint-disable-next-line
        resultTree.childSections[filterLeafKey]!.count++;
        resultTree.count++;

        return true;
      }
    }
  }

  if (rootLevel) {
    if (!resultTree.childSections.Foundational) {
      resultTree.childSections.Foundational = {
        title: 'Foundational',
        count: 1,
        blocks: [],
        childSections: {
          Core: {
            title: 'Core',
            count: 1,
            blocks: [displayItemData],
            childSections: {},
          },
        },
      };
    } else if (!resultTree.childSections.Foundational.childSections.Core) {
      resultTree.childSections.Foundational.childSections.Core = {
        title: 'Core',
        count: 1,
        blocks: [displayItemData],
        childSections: {},
      };
      resultTree.childSections.Foundational.count++;
      resultTree.count++;
    } else {
      resultTree.childSections.Foundational.childSections.Core.blocks.push(
        displayItemData,
      );
      resultTree.childSections.Foundational.childSections.Core.count++;
      resultTree.childSections.Foundational.count++;
      resultTree.count++;
    }
  }

  return false;
};

export const FoundationalBlocks: React.FC<Props> = ({
  lowercaseSearchString,
  currentSubdiagramType,
}) => {
  const { developerModeEnabled } = useAppSelector(
    (state) => state.userOptions.options,
  );
  const modelKind = useAppSelector(
    (state) => state.submodels.topLevelModelType,
  );
  const acausalModelingEnabled = useAppSelector(
    (state) => state.userOptions.options.acausalModelingEnabled,
  );

  const filteredBlocks: LibItemDisplayUnion[] = React.useMemo(
    () =>
      modelKind
        ? searchFoundationalBlocks(
            lowercaseSearchString,
            developerModeEnabled,
            modelKind,
            currentSubdiagramType,
            acausalModelingEnabled,
          )
        : [],
    [
      modelKind,
      lowercaseSearchString,
      developerModeEnabled,
      currentSubdiagramType,
      acausalModelingEnabled,
    ],
  );

  const blockLibDisplayTree = React.useMemo(() => {
    const blockTree: LibDisplayTree = {
      title: '',
      count: 0,
      blocks: [],
      childSections: {},
    };

    for (let i = 0; i < filteredBlocks.length; i++) {
      const blockData = filteredBlocks[i];

      insertBlockDataInDisplayTree(sectionFilters, blockData, blockTree);
    }

    return blockTree;
  }, [filteredBlocks]);

  if (filteredBlocks.length === 0) {
    return null;
  }

  const recursivelyRenderLibDisplayTree = (tree: LibDisplayTree) => (
    <LibrarySubsection
      title={tree.title}
      key={tree.title}
      count={tree.count}
      forceOpen={blockLibDisplayTree.count < 10}>
      {tree.blocks.length === 0 &&
        Object.keys(tree.childSections).map((sectionKey) =>
          tree.childSections[sectionKey]
            ? recursivelyRenderLibDisplayTree(tree.childSections[sectionKey]!) // eslint-disable-line
            : null,
        )}
      {tree.blocks.length > 0 && (
        <BlocksGrid>
          {tree.blocks.map((displayItem) =>
            displayItem.type === LibItemDisplayType.Base ? (
              <BlockLibraryDragItem
                blockClassName={displayItem.blockClassName}
                key={displayItem.blockClassName}
              />
            ) : (
              <BlockLibraryDragItem
                blockClassName={displayItem.pseudoData.baseBlockClassName}
                overrideDisplayName={displayItem.pseudoData.displayName}
                overridePropDefaults={displayItem.pseudoData.overrideDefaults}
                key={`pseudo_${displayItem.pseudoData.baseBlockClassName}-${displayItem.pseudoData.overrideDefaults.name}`}
              />
            ),
          )}
        </BlocksGrid>
      )}
    </LibrarySubsection>
  );

  if (!blockLibDisplayTree.childSections) return null;

  return (
    <>
      {Object.keys(blockLibDisplayTree.childSections).map(
        (sectionKey: keyof typeof blockLibDisplayTree.childSections) =>
          blockLibDisplayTree.childSections[sectionKey]
            ? recursivelyRenderLibDisplayTree(
                blockLibDisplayTree.childSections[sectionKey]!, // eslint-disable-line
              )
            : [],
      )}
    </>
  );
};
