import { MigratedModelV0 } from 'modelMigrations_v0';
import {
  Parameter,
  ParameterDefinition,
  ParameterDefinitions,
  Parameters,
} from 'schemas/SimulationModel';
import { v4 as uuidv4 } from 'uuid';

export interface MigratedModelV1 extends MigratedModelV0 {}

function escapeString(str: string): string {
  return str.replace(/\\/g, '\\\\').replace(/"/g, '\\"');
}

// NOTE: is_string is extremely unlikely to be true here
const parameterToParameterDefinition = (
  name: string,
  p: Parameter,
): ParameterDefinition => ({
  default_value: !!p.is_string ? `str("${escapeString(p.value)}")` : p.value,
  description: undefined,
  name,
  uuid: uuidv4(),
  uiprops: {},
});

const migrateSubmodelParametersToV1 = (
  parameters: Parameters,
): ParameterDefinitions =>
  Object.keys(parameters).map((k) =>
    parameterToParameterDefinition(k, parameters[k] as Parameter),
  );

export function migrateModelDataV0toV1(
  modelData: MigratedModelV0,
): MigratedModelV1 {
  if (modelData.kind === 'Submodel') {
    const data = { ...modelData, schema_version: '1' } as MigratedModelV1;
    if (!data.parameter_definitions && data.parameters) {
      data.parameter_definitions = migrateSubmodelParametersToV1(
        data.parameters,
      );
      delete data.parameters;
    }
    return data;
  }

  return { ...modelData, schema_version: '1' };
}
