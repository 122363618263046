/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Revert: React.FC<Props> = (props) => (
  <svg viewBox="0 0 24 24" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17 2.93934L20.0303 5.96967C20.3232 6.26256 20.3232 6.73744 20.0303 7.03033C19.7374 7.32322 19.2626 7.32322 18.9697 7.03033L17.75 5.81066V8.92157C17.75 9.05418 17.6973 9.18136 17.6036 9.27513L17.3536 9.52513C17.1583 9.72039 16.8417 9.72039 16.6464 9.52513L16.3964 9.27513C16.3027 9.18136 16.25 9.05418 16.25 8.92157V5.81066L15.0303 7.03033C14.7374 7.32322 14.2626 7.32322 13.9697 7.03033C13.6768 6.73744 13.6768 6.26256 13.9697 5.96967L17 2.93934Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M17.6036 14.7249C17.6973 14.8186 17.75 14.9458 17.75 15.0784V15.5C17.75 17.0188 16.5188 18.25 15 18.25H8.88555C8.56698 19.2643 7.61941 20 6.5 20C5.11929 20 4 18.8807 4 17.5C4 16.3806 4.73572 15.433 5.75 15.1145V14.75C5.75 14.3358 6.08579 14 6.5 14C6.91421 14 7.25 14.3358 7.25 14.75V15.1145C8.02752 15.3587 8.64135 15.9725 8.88555 16.75H15C15.6904 16.75 16.25 16.1904 16.25 15.5V15.0784C16.25 14.9458 16.3027 14.8186 16.3964 14.7249L16.6464 14.4749C16.8417 14.2796 17.1583 14.2796 17.3536 14.4749L17.6036 14.7249ZM7.5 17.5C7.5 18.0523 7.05228 18.5 6.5 18.5C5.94772 18.5 5.5 18.0523 5.5 17.5C5.5 16.9477 5.94772 16.5 6.5 16.5C7.05228 16.5 7.5 16.9477 7.5 17.5Z"
      fill={props.fill}
    />
    <path
      d="M7.25 4.75C7.25 4.33579 6.91421 4 6.5 4C6.08579 4 5.75 4.33579 5.75 4.75V6.75C5.75 7.16421 6.08579 7.5 6.5 7.5C6.91421 7.5 7.25 7.16421 7.25 6.75V4.75Z"
      fill={props.fill}
    />
    <path
      d="M5.75 11.75C5.75 12.1642 6.08579 12.5 6.5 12.5C6.91421 12.5 7.25 12.1642 7.25 11.75V9.75C7.25 9.33579 6.91421 9 6.5 9C6.08579 9 5.75 9.33579 5.75 9.75V11.75Z"
      fill={props.fill}
    />
    <path
      d="M13.9697 8.96967C14.2626 8.67678 14.7374 8.67678 15.0303 8.96967L17 10.9393L18.9697 8.96967C19.2626 8.67678 19.7374 8.67678 20.0303 8.96967C20.3232 9.26256 20.3232 9.73744 20.0303 10.0303L18.0607 12L20.0303 13.9697C20.3232 14.2626 20.3232 14.7374 20.0303 15.0303C19.7374 15.3232 19.2626 15.3232 18.9697 15.0303L17 13.0607L15.0303 15.0303C14.7374 15.3232 14.2626 15.3232 13.9697 15.0303C13.6768 14.7374 13.6768 14.2626 13.9697 13.9697L15.9393 12L13.9697 10.0303C13.6768 9.73744 13.6768 9.26256 13.9697 8.96967Z"
      fill={props.fill}
    />
  </svg>
);

Revert.defaultProps = {
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Revert;
