/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Cut: React.FC<Props> = (props) => (
  <svg viewBox="0 0 24 24" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M9.56182 10.5619C9.10689 10.8398 8.57215 11 8 11C6.34315 11 5 9.65685 5 8C5 6.34315 6.34315 5 8 5C9.65685 5 11 6.34315 11 8C11 8.54269 10.8559 9.05172 10.6039 9.49092L17.9999 16.9999C18.2499 17.2499 18.3749 17.3749 18.3749 17.4999C18.3749 17.6249 18.2499 17.7499 17.9999 17.9999C17.7071 18.2928 17.2322 18.2928 16.9393 17.9999L12.0303 13.0303L10.5846 14.476C10.8485 14.9226 11 15.4436 11 16C11 17.6569 9.65685 19 8 19C6.34315 19 5 17.6569 5 16C5 14.3431 6.34315 13 8 13C8.55635 13 9.07732 13.1514 9.52395 13.4153L10.9696 11.9697L9.56182 10.5619ZM8 9.5C7.17157 9.5 6.5 8.82843 6.5 8C6.5 7.17157 7.17157 6.5 8 6.5C8.82843 6.5 9.5 7.17157 9.5 8C9.5 8.82843 8.82843 9.5 8 9.5ZM8 17.5C8.82843 17.5 9.5 16.8284 9.5 16C9.5 15.1716 8.82843 14.5 8 14.5C7.17157 14.5 6.5 15.1716 6.5 16C6.5 16.8284 7.17157 17.5 8 17.5Z"
      fill={props.fill}
    />
    <path
      d="M18.3221 6.49235C18.3248 6.36986 18.215 6.2449 17.9999 6.00002C17.707 5.70713 17.2321 5.70713 16.9392 6.00002L12.9393 10L14 11.0607L17.9392 7.00008C18.1944 6.74493 18.3193 6.61999 18.3221 6.49235Z"
      fill={props.fill}
    />
  </svg>
);

Cut.defaultProps = {
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Cut;
