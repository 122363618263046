/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  cx?: any;
  cy?: any;
  r?: any;
  stroke?: any;
  strokeWidth?: any;
  strokeLinecap?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const AcausalRotationalMotionsensor: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <circle
      cx={props.cx}
      cy={props.cy}
      r="11.25"
      stroke={props.stroke}
      strokeWidth={props.strokeWidth}
    />
    <path
      d="M16 5.5V7.5"
      stroke={props.stroke}
      strokeWidth={props.strokeWidth}
      strokeLinecap={props.strokeLinecap}
    />
    <path
      d="M25.0938 10.75L23.3617 11.75"
      stroke={props.stroke}
      strokeWidth={props.strokeWidth}
      strokeLinecap={props.strokeLinecap}
    />
    <path
      d="M6.90625 10.75L8.6383 11.75"
      stroke={props.stroke}
      strokeWidth={props.strokeWidth}
      strokeLinecap={props.strokeLinecap}
    />
    <path
      d="M10.75 6.90673L11.75 8.63879"
      stroke={props.stroke}
      strokeWidth={props.strokeWidth}
      strokeLinecap={props.strokeLinecap}
    />
    <path
      d="M21.25 6.90673L20.25 8.63879"
      stroke={props.stroke}
      strokeWidth={props.strokeWidth}
      strokeLinecap={props.strokeLinecap}
    />
    <circle cx={props.cx} cy={props.cy} r={props.r} stroke={props.stroke} />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M14.7278 7.8181C14.6273 7.41625 14.2201 7.17193 13.8183 7.2724C13.4164 7.37286 13.1721 7.78006 13.2726 8.1819L14.8229 14.3831C15.1532 14.1422 15.5601 14 16.0002 14C16.0945 14 16.1872 14.0065 16.278 14.0191L14.7278 7.8181Z"
      fill={props.fill}
    />
    <path
      d="M17.1808 19.2421C17.4021 19.3369 17.4345 19.6224 17.2643 19.7926L16.9504 20.1065C16.8404 20.2166 16.6746 20.2437 16.5252 20.2001C16.3561 20.1507 16.1794 20.125 16 20.125C15.5027 20.125 15.0258 20.3225 14.6742 20.6742C14.4005 20.9478 14.2202 21.2974 14.1537 21.6734C14.1347 21.7803 14.2215 21.8725 14.33 21.8725H14.9248C15.0764 21.8725 15.1593 22.0387 15.0852 22.1578C15.0775 22.1701 15.0689 22.1817 15.0602 22.1933C15.0511 22.2055 15.0421 22.2177 15.0341 22.2307L13.8791 24.1206L13.8779 24.121L13.8774 24.1204L13.877 24.1196C13.3154 23.5572 13 22.7949 13 22C13 21.2044 13.3161 20.4413 13.8787 19.8787C14.4413 19.3161 15.2044 19 16 19C16.4099 19 16.8111 19.0839 17.1808 19.2421Z"
      fill={props.fill}
    />
    <path
      d="M14.8192 24.7579C14.5979 24.6631 14.5655 24.3776 14.7357 24.2074L15.0496 23.8935C15.1596 23.7834 15.3254 23.7563 15.4748 23.7999C15.6439 23.8493 15.8206 23.875 16 23.875C16.4973 23.875 16.9742 23.6775 17.3258 23.3258C17.5995 23.0522 17.7798 22.7026 17.8463 22.3266C17.8653 22.2197 17.7785 22.1275 17.67 22.1275H17.0752C16.9236 22.1275 16.8407 21.9613 16.9148 21.8422C16.9225 21.8299 16.9311 21.8183 16.9398 21.8067C16.9489 21.7945 16.9579 21.7823 16.9659 21.7693L18.1209 19.8794L18.1221 19.879L18.1226 19.8796L18.123 19.8804C18.6846 20.4428 19 21.2051 19 22C19 22.7956 18.6839 23.5587 18.1213 24.1213C17.5587 24.6839 16.7956 25 16 25C15.5901 25 15.1889 24.9161 14.8192 24.7579Z"
      fill={props.fill}
    />
    <path
      d="M2 16L4 16"
      stroke={props.stroke}
      strokeWidth={props.strokeWidth}
      strokeLinecap={props.strokeLinecap}
    />
    <path
      d="M28 16L30 16"
      stroke={props.stroke}
      strokeWidth={props.strokeWidth}
      strokeLinecap={props.strokeLinecap}
    />
  </svg>
);

AcausalRotationalMotionsensor.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  cx: '16',
  cy: '16',
  r: '1.5',
  stroke: '#1D3131',
  strokeWidth: '1.5',
  strokeLinecap: 'round',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default AcausalRotationalMotionsensor;
