/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Demux: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M18.941 5.19098C19.7721 4.77542 20.75 5.37979 20.75 6.30902V9.75H24C24.4142 9.75 24.75 10.0858 24.75 10.5C24.75 10.9142 24.4142 11.25 24 11.25H20.75V15.25H24C24.4142 15.25 24.75 15.5858 24.75 16C24.75 16.4142 24.4142 16.75 24 16.75H20.75V20.75H24C24.4142 20.75 24.75 21.0858 24.75 21.5C24.75 21.9142 24.4142 22.25 24 22.25H20.75V25.691C20.75 26.6202 19.7721 27.2246 18.941 26.809L11.941 23.309C11.5175 23.0973 11.25 22.6644 11.25 22.191V17.25H7C6.72386 17.25 6.5 17.0261 6.5 16.75C6.5 16.4739 6.72386 16.25 7 16.25H11.25V15.75H7C6.72386 15.75 6.5 15.5261 6.5 15.25C6.5 14.9739 6.72386 14.75 7 14.75H11.25V9.80902C11.25 9.33555 11.5175 8.90272 11.941 8.69098L18.941 5.19098ZM19.25 25.2865V6.71352L12.75 9.96352V22.0365L19.25 25.2865Z"
      fill={props.fill}
    />
  </svg>
);

Demux.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Demux;
