/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  className?: string;
}

const Tree: React.FC<Props> = (props) => (
  <svg viewBox="0 0 16 16" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3 3.5C3 3.22386 3.22386 3 3.5 3H6.5C6.77614 3 7 3.22386 7 3.5V4.5C7 4.77614 6.77614 5 6.5 5H3.5C3.22386 5 3 4.77614 3 4.5V3.5Z"
      fill={props.fill}
    />
    <path
      d="M4.5 12V6.5C4.5 6.22386 4.72386 6 5 6C5.27614 6 5.5 6.22386 5.5 6.5V8C5.5 8.27614 5.72386 8.5 6 8.5H7.5C7.77614 8.5 8 8.72386 8 9C8 9.27614 7.77614 9.5 7.5 9.5H6C5.82468 9.5 5.65639 9.46992 5.5 9.41465V12C5.5 12.2761 5.72386 12.5 6 12.5H7.5C7.77614 12.5 8 12.7239 8 13C8 13.2761 7.77614 13.5 7.5 13.5H6C5.17157 13.5 4.5 12.8284 4.5 12Z"
      fill={props.fill}
    />
    <path
      d="M9.5 8C9.22386 8 9 8.22386 9 8.5V9.5C9 9.77614 9.22386 10 9.5 10H12.5C12.7761 10 13 9.77614 13 9.5V8.5C13 8.22386 12.7761 8 12.5 8H9.5Z"
      fill={props.fill}
    />
    <path
      d="M9 12.5C9 12.2239 9.22386 12 9.5 12H12.5C12.7761 12 13 12.2239 13 12.5V13.5C13 13.7761 12.7761 14 12.5 14H9.5C9.22386 14 9 13.7761 9 13.5V12.5Z"
      fill={props.fill}
    />
  </svg>
);

Tree.defaultProps = {
  width: '16',
  height: '16',
  viewBox: '0 0 16 16',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  className: '',
};

export default Tree;
