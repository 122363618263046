import { PayloadAction } from '@reduxjs/toolkit';
import {
  generateInName,
  generateOutName,
} from 'app/blockClassNameToInstanceDefaults';
import { PortSide } from 'app/common_types/PortTypes';
import { BlockParameterDefinitions } from 'app/generated_types/ComputationBlockClass';
import { Parameters } from 'app/generated_types/SimulationModel';
import {
  ModelState,
  getCurrentlyEditingModelFromState,
} from 'app/modelState/ModelState';
import { getUniqueParameterName } from 'app/transformers/uniqueNameGenerators';
import { getPortWorldCoordinate } from 'app/utils/getPortOffsetCoordinate';
import { setLinkSourceAndDependentTapSources } from 'app/utils/linkMutationUtils';
import { getPortNamesInScope } from 'app/utils/portNameUtils';
import { rendererState } from 'ui/modelRendererInternals/modelRenderer';
import { v4 as makeUuid } from 'uuid';

export function disconnectLinkFromSourceOrDest(
  state: ModelState,
  action: PayloadAction<{ linkUuid: string; disconnectSource: boolean }>,
) {
  const { linkUuid, disconnectSource } = action.payload;

  const model = getCurrentlyEditingModelFromState(state);
  if (!model) return;

  let link = model.links.find((l) => l.uuid === linkUuid);
  if (!link) return;

  if (!disconnectSource) {
    link.dst = undefined;
  } else {
    setLinkSourceAndDependentTapSources(
      link.uuid,
      undefined,
      model.links,
      rendererState?.refs?.current?.linksIndexLUT,
    );
    // necessary for valid quick-disconnect of tapped links
    link.uiprops.link_type = { connection_method: 'direct_to_block' };
  }
}

export function connectNodesPorts(
  state: ModelState,
  action: PayloadAction<{
    parentPath: string[];
    sourceNodeUuid: string;
    destNodeUuid: string;
    sourceNodeOutputPortIDs: number[];
    destNodeInputPortIDs: number[];
  }>,
) {
  const {
    sourceNodeUuid,
    destNodeUuid,
    sourceNodeOutputPortIDs,
    destNodeInputPortIDs,
  } = action.payload;

  const model = getCurrentlyEditingModelFromState(state);
  if (!model) return;

  const portCount = Math.min(
    sourceNodeOutputPortIDs.length,
    destNodeInputPortIDs.length,
  );

  for (let i = 0; i < portCount; i++) {
    model.links.push({
      uuid: makeUuid(),
      src: {
        node: sourceNodeUuid,
        port: sourceNodeOutputPortIDs[i],
      },
      dst: { node: destNodeUuid, port: destNodeInputPortIDs[i] },
      uiprops: {
        link_type: { connection_method: 'direct_to_block' },
        segments: [],
      },
    });
  }
}

export function addPort(
  state: ModelState,
  payload: {
    parentPath: string[];
    nodeUuid: string;
    portSide: PortSide;
    name?: string;
    parameters?: Parameters;
    kind?: 'dynamic' | 'static' | 'conditional';
  },
) {
  const { parentPath, nodeUuid, portSide, name, parameters } = payload;

  const model = getCurrentlyEditingModelFromState(state, parentPath);
  if (!model) return;

  const node = model.nodes.find((n) => n.uuid === nodeUuid);

  if (!node) return;

  const existingNames = getPortNamesInScope(node);

  if (portSide === PortSide.Input) {
    const newPortIndex = node.inputs.length;
    const defaultPortName = generateInName(newPortIndex);
    const uniquePortName = getUniqueParameterName(
      node,
      defaultPortName,
      existingNames,
    );
    if (node.uiprops.inport_order) {
      node.uiprops.inport_order.push(node.inputs.length);
    }
    if (node.uiprops.inport_order_index_map) {
      node.uiprops.inport_order_index_map.push(node.inputs.length);
    }
    node.inputs.push({
      name: name || uniquePortName,
      kind: payload.kind || 'dynamic',
      parameters,
    });
  }

  if (portSide === PortSide.Output) {
    const newPortIndex = node.outputs.length;
    const defaultPortName = generateOutName(newPortIndex);
    const uniquePortName = getUniqueParameterName(
      node,
      defaultPortName,
      existingNames,
    );
    if (node.uiprops.outport_order) {
      node.uiprops.outport_order.push(node.outputs.length);
    }
    if (node.uiprops.outport_order_index_map) {
      node.uiprops.outport_order_index_map.push(node.outputs.length);
    }
    node.outputs.push({
      name: name || uniquePortName,
      kind: payload.kind || 'dynamic',
      parameters,
    });
  }
}

export function removeAllInputPorts(
  state: ModelState,
  payload: {
    parentPath: string[];
    nodeUuid: string;
  },
) {
  const { parentPath, nodeUuid } = payload;
  const model = getCurrentlyEditingModelFromState(state, parentPath);
  if (!model) return;

  const node = model.nodes.find((n) => n.uuid === nodeUuid);

  if (!node) return;

  node.inputs = [];
}

export function removeAllPorts(
  state: ModelState,
  payload: {
    parentPath: string[];
    nodeUuid: string;
  },
) {
  const { parentPath, nodeUuid } = payload;
  const model = getCurrentlyEditingModelFromState(state, parentPath);
  if (!model) return;

  const node = model.nodes.find((n) => n.uuid === nodeUuid);

  if (!node) return;

  node.inputs = [];
  node.outputs = [];
}

export function removePort(
  state: ModelState,
  payload: {
    parentPath: string[];
    nodeUuid: string;
    portSide: PortSide;
    portId: number;
  },
) {
  const { parentPath, nodeUuid, portSide, portId } = payload;

  const model = getCurrentlyEditingModelFromState(state, parentPath);
  if (!model) return;

  const node = model.nodes.find((n) => n.uuid === nodeUuid);

  if (!node) return;

  if (portSide === PortSide.Input) {
    if (node.inputs[portId] == undefined) return;
    node.inputs.splice(portId, 1);

    if (node.uiprops.inport_order) {
      node.uiprops.inport_order = node.uiprops.inport_order.filter(
        (v) => v !== portId,
      );
      for (let i = 0; i < node.uiprops.inport_order.length; i++) {
        if (node.uiprops.inport_order[i] >= portId) {
          node.uiprops.inport_order[i] -= 1;
        }
      }
    }

    if (node.uiprops.inport_order_index_map && node.uiprops.inport_order) {
      node.uiprops.inport_order_index_map = [];
      for (let i = 0; i < node.uiprops.inport_order.length; i++) {
        const portIndex = node.uiprops.inport_order[i];
        node.uiprops.inport_order_index_map[portIndex] = i;
      }
    }
  }

  if (portSide === PortSide.Output) {
    if (node.outputs[portId] == undefined) return;
    node.outputs.splice(portId, 1);

    if (node.uiprops.outport_order) {
      node.uiprops.outport_order = node.uiprops.outport_order.filter(
        (v) => v !== portId,
      );
      for (let i = 0; i < node.uiprops.outport_order.length; i++) {
        if (node.uiprops.outport_order[i] >= portId) {
          node.uiprops.outport_order[i] -= 1;
        }
      }
    }

    if (node.uiprops.outport_order_index_map && node.uiprops.outport_order) {
      node.uiprops.outport_order_index_map = [];
      for (let i = 0; i < node.uiprops.outport_order.length; i++) {
        const portIndex = node.uiprops.outport_order[i];
        node.uiprops.outport_order_index_map[portIndex] = i;
      }
    }
  }

  // make sure links are disconnected properly if their port is removed
  state.rootModel.links.forEach((link) => {
    const srcOrDst = portSide === PortSide.Input ? link.dst : link.src;
    if (srcOrDst && srcOrDst.node === nodeUuid) {
      if (typeof srcOrDst.port == 'number' && srcOrDst.port > portId) {
        srcOrDst.port -= 1;
      } else if (srcOrDst.port === portId) {
        const portCoord = getPortWorldCoordinate(
          node,
          portSide,
          portSide === PortSide.Input ? link.dst : link.src,
        );

        if (portSide === PortSide.Input) {
          link.uiprops.hang_coord_end = portCoord;
          delete link.dst;
        }

        if (portSide === PortSide.Output) {
          link.uiprops.hang_coord_start = portCoord;
          setLinkSourceAndDependentTapSources(
            link.uuid,
            undefined,
            model.links,
            rendererState?.refs?.current?.linksIndexLUT,
          );
        }
      }
    }
  });
}

export function renamePort(
  state: ModelState,
  action: PayloadAction<{
    parentPath: string[];
    nodeUuid: string;
    portSide: PortSide;
    portId: number;
    newPortName: string;
  }>,
) {
  const { parentPath, nodeUuid, portSide, portId, newPortName } =
    action.payload;

  const model = getCurrentlyEditingModelFromState(state, parentPath);
  if (!model) return;

  const node = model.nodes.find((n) => n.uuid === nodeUuid);
  if (!node) return;

  if (portSide === PortSide.Input) {
    const port = node.inputs[portId];
    if (port) {
      port.name = newPortName;
    }
  } else if (portSide === PortSide.Output) {
    const port = node.outputs[portId];
    if (port) {
      port.name = newPortName;
    }
  }
}

export function setPortParameters(
  state: ModelState,
  action: PayloadAction<{
    parentPath: string[];
    nodeUuid: string;
    portSide: PortSide;
    portId: number;
    parameters: Parameters;
  }>,
) {
  const { parentPath, nodeUuid, portSide, portId, parameters } = action.payload;

  const model = getCurrentlyEditingModelFromState(state, parentPath);
  if (!model) return;

  const node = model.nodes.find((n) => n.uuid === nodeUuid);
  if (!node) return;

  const port =
    portSide === PortSide.Input ? node.inputs[portId] : node.outputs[portId];
  if (!port) return;

  if (!port.parameters) port.parameters = {};
  // FIXME: casting because of AWS-661 and autogen
  port.parameters = { ...(port.parameters as Parameters), ...parameters };
}

export function setOutputPortRecord(
  state: ModelState,
  action: PayloadAction<{
    parentPath: string[];
    nodeUuid: string;
    portId?: number;
    record?: boolean;
  }>,
) {
  const { parentPath, nodeUuid, portId, record } = action.payload;

  const model = getCurrentlyEditingModelFromState(state, parentPath);
  if (!model) return;

  const node = model.nodes.find((n) => n.uuid === nodeUuid);
  if (!node) return;

  let portIds: number[];
  if (portId !== undefined && portId >= 0) {
    portIds = [portId];
  } else {
    portIds = [...Array(node.outputs.length).keys()];
  }

  for (const id of portIds) {
    const port = node.outputs[id];
    if (!port) continue;

    if (record) {
      port.record = true;
    } else {
      delete port.record;
    }
  }
}

export function getDefaultPortParameters(
  paramDefs: BlockParameterDefinitions | undefined,
): Parameters | undefined {
  if (!paramDefs) return undefined;
  const defaultPortParams: Parameters = {};
  paramDefs?.forEach((paramDef) => {
    if (
      paramDef.default_value !== undefined &&
      paramDef.default_value !== null
    ) {
      defaultPortParams[paramDef.name] = {
        value: paramDef.default_value,
        is_string:
          paramDef.data_type === 'string' ||
          paramDef.data_type === 'reference_submodel' ||
          paramDef.data_type === 'file' ||
          paramDef.data_type === 'code',
      };
    }
  });
  return defaultPortParams;
}
