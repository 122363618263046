import styled from '@emotion/styled';
import { useNavigateToProjectHome } from 'app/api/useNavigateToProjectHome';
import React from 'react';
import { ExtraSmallCaps } from 'ui/common/typography/Typography';

const Brand = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.grey[5]};
  cursor: pointer;
`;

const Logo = styled.img`
  height: 16px;
  margin: 0 12px;
  fill: ${({ theme }) => theme.colors.grey[2]};
`;

const BrandText = styled(ExtraSmallCaps)`
  color: ${({ theme }) => theme.colors.grey[5]};
`;

const BrandLogo: React.FC = () => {
  const { navigateToProjectHome } = useNavigateToProjectHome();

  return (
    <Brand onClick={navigateToProjectHome}>
      <Logo
        alt="brand"
        src={`${process.env.PUBLIC_URL}/assets/topbar_logo.svg`}
      />
      <BrandText>Collimator</BrandText>
    </Brand>
  );
};

export default BrandLogo;
