/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  stroke?: any;
  strokeWidth?: any;
  strokeLinecap?: any;
  id?: any;
  x?: any;
  y?: any;
  rx?: any;
  mask?: any;
  strokeLinejoin?: any;
  className?: string;
}

const AcausalTranslationalFriction: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4 19H8M28 19H26M20 19L16.5 22.5M20 19H14M20 19H26M14 19L10.5 22.5M14 19H8M8 19L4.5 22.5M26 19L22.5 22.5"
      stroke={props.stroke}
      strokeWidth={props.strokeWidth}
      strokeLinecap={props.strokeLinecap}
    />
    <mask id={props.id} fill={props.fill}>
      <rect
        x={props.x}
        y={props.y}
        width={props.width}
        height={props.height}
        rx={props.rx}
      />
    </mask>
    <rect
      x={props.x}
      y={props.y}
      width={props.width}
      height={props.height}
      rx={props.rx}
      stroke={props.stroke}
      strokeWidth="3"
      mask={props.mask}
    />
    <path
      d="M26.5 17H21.5241C21.5152 17 21.5108 16.9892 21.5171 16.9829L23.5 15"
      stroke={props.stroke}
      strokeWidth={props.strokeWidth}
      strokeLinecap={props.strokeLinecap}
    />
    <path
      d="M11 14H4M11 14L8 11M11 14L8 17"
      stroke={props.stroke}
      strokeWidth={props.strokeWidth}
      strokeLinecap={props.strokeLinecap}
      strokeLinejoin={props.strokeLinejoin}
    />
  </svg>
);

AcausalTranslationalFriction.defaultProps = {
  width: '8',
  height: '8',
  viewBox: '0 0 32 32',
  fill: 'white',
  xmlns: 'http://www.w3.org/2000/svg',
  stroke: '#1D3131',
  strokeWidth: '1.5',
  strokeLinecap: 'round',
  id: 'path2Inside1_11020_19897',
  x: '12',
  y: '10',
  rx: '1',
  mask: 'url(#path2Inside1_11020_19897)',
  strokeLinejoin: 'round',
  className: '',
};

export default AcausalTranslationalFriction;
