/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  className?: string;
}

const Outputs: React.FC<Props> = (props) => (
  <svg viewBox="0 0 16 16" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8 4C7.47471 4 6.95457 4.10346 6.46927 4.30448C5.98396 4.5055 5.54301 4.80014 5.17157 5.17157C4.80014 5.54301 4.5055 5.98396 4.30448 6.46927C4.10346 6.95457 4 7.47471 4 8C4 8.52529 4.10346 9.04543 4.30448 9.53073C4.5055 10.016 4.80014 10.457 5.17157 10.8284C5.54301 11.1999 5.98396 11.4945 6.46927 11.6955C6.95457 11.8965 7.47471 12 8 12H9C9.27614 12 9.5 12.2239 9.5 12.5C9.5 12.7761 9.27614 13 9 13H8C7.34339 13 6.69321 12.8707 6.08658 12.6194C5.47996 12.3681 4.92876 11.9998 4.46447 11.5355C4.00017 11.0712 3.63188 10.52 3.3806 9.91342C3.12933 9.30679 3 8.65661 3 8C3 7.34339 3.12933 6.69321 3.3806 6.08658C3.63188 5.47995 4.00017 4.92876 4.46447 4.46447C4.92876 4.00017 5.47995 3.63188 6.08658 3.3806C6.69321 3.12933 7.34339 3 8 3H9C9.27614 3 9.5 3.22386 9.5 3.5C9.5 3.77614 9.27614 4 9 4H8Z"
      fill={props.fill}
    />
    <path
      d="M10.8536 5.64645C10.6583 5.45118 10.3417 5.45118 10.1464 5.64645C9.95118 5.84171 9.95118 6.15829 10.1464 6.35355L11.2929 7.5H6.5C6.22386 7.5 6 7.72386 6 8C6 8.27614 6.22386 8.5 6.5 8.5H11.2929L10.1464 9.64645C9.95118 9.84171 9.95118 10.1583 10.1464 10.3536C10.3417 10.5488 10.6583 10.5488 10.8536 10.3536L13.2071 8L10.8536 5.64645Z"
      fill={props.fill}
    />
  </svg>
);

Outputs.defaultProps = {
  width: '16',
  height: '16',
  viewBox: '0 0 16 16',
  fill: 'black',
  xmlns: 'http://www.w3.org/2000/svg',
  className: '',
};

export default Outputs;
