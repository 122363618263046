/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const CoordinaterotationconversionRotationanglestodcm: React.FC<Props> = (
  props,
) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.5298 11.9697C12.2369 11.6768 11.7621 11.6768 11.4692 11.9697C11.1763 12.2626 11.1763 12.7374 11.4692 13.0303L13.9389 15.5L11.4692 17.9697C11.1763 18.2626 11.1763 18.7374 11.4692 19.0303C11.7621 19.3232 12.2369 19.3232 12.5298 19.0303L15.1763 16.3839C15.6644 15.8957 15.6644 15.1043 15.1763 14.6161L12.5298 11.9697Z"
      fill={props.fill}
    />
    <path
      d="M23.8933 18.564C23.4773 18.564 23.112 18.484 22.7973 18.324C22.4826 18.1587 22.24 17.9293 22.0693 17.636C21.8986 17.3427 21.8133 17.004 21.8133 16.62V14.772C21.8133 14.388 21.8986 14.0493 22.0693 13.756C22.24 13.4627 22.4826 13.236 22.7973 13.076C23.112 12.916 23.4773 12.836 23.8933 12.836C24.304 12.836 24.664 12.9133 24.9733 13.068C25.288 13.2173 25.5306 13.4333 25.7013 13.716C25.8773 13.9933 25.9653 14.316 25.9653 14.684C25.9653 14.732 25.9333 14.7613 25.8693 14.772L24.9333 14.828H24.9173C24.864 14.828 24.8373 14.7987 24.8373 14.74C24.8373 14.4573 24.7493 14.2307 24.5733 14.06C24.4026 13.8893 24.176 13.804 23.8933 13.804C23.6053 13.804 23.3733 13.8893 23.1973 14.06C23.0266 14.2307 22.9413 14.4573 22.9413 14.74V16.668C22.9413 16.9453 23.0266 17.1693 23.1973 17.34C23.3733 17.5107 23.6053 17.596 23.8933 17.596C24.176 17.596 24.4026 17.5107 24.5733 17.34C24.7493 17.1693 24.8373 16.9453 24.8373 16.668C24.8373 16.6093 24.8693 16.58 24.9333 16.58L25.8693 16.62C25.896 16.62 25.9173 16.628 25.9333 16.644C25.9546 16.66 25.9653 16.6787 25.9653 16.7C25.9653 17.068 25.8773 17.3933 25.7013 17.676C25.5306 17.9587 25.288 18.1773 24.9733 18.332C24.664 18.4867 24.304 18.564 23.8933 18.564Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M17.0838 18.5C17.0571 18.5 17.0331 18.492 17.0118 18.476C16.9958 18.4547 16.9878 18.4307 16.9878 18.404V12.996C16.9878 12.9693 16.9958 12.948 17.0118 12.932C17.0331 12.9107 17.0571 12.9 17.0838 12.9H19.0838C19.4838 12.9 19.8331 12.972 20.1318 13.116C20.4358 13.2547 20.6705 13.452 20.8358 13.708C21.0011 13.964 21.0838 14.26 21.0838 14.596V16.804C21.0838 17.14 21.0011 17.436 20.8358 17.692C20.6705 17.948 20.4358 18.148 20.1318 18.292C19.8331 18.4307 19.4838 18.5 19.0838 18.5H17.0838ZM18.1158 17.492C18.1158 17.5187 18.1291 17.532 18.1558 17.532H19.1238C19.3691 17.532 19.5665 17.4493 19.7158 17.284C19.8705 17.1187 19.9505 16.8973 19.9558 16.62V14.78C19.9558 14.5027 19.8785 14.2813 19.7238 14.116C19.5745 13.9507 19.3718 13.868 19.1158 13.868H18.1558C18.1291 13.868 18.1158 13.8813 18.1158 13.908V17.492Z"
      fill={props.fill}
    />
    <path
      d="M30.5045 12.9C30.4565 12.9 30.4191 12.9213 30.3925 12.964L29.1845 14.828C29.1685 14.86 29.1525 14.86 29.1365 14.828L27.9365 12.964C27.9098 12.9213 27.8725 12.9 27.8245 12.9H26.8885C26.8618 12.9 26.8378 12.9107 26.8165 12.932C26.8005 12.948 26.7925 12.9693 26.7925 12.996V18.404C26.7925 18.4307 26.8005 18.4547 26.8165 18.476C26.8378 18.492 26.8618 18.5 26.8885 18.5H27.8245C27.8511 18.5 27.8725 18.492 27.8885 18.476C27.9098 18.4547 27.9205 18.4307 27.9205 18.404V14.884C27.9205 14.8627 27.9258 14.852 27.9365 14.852C27.9471 14.8467 27.9578 14.852 27.9685 14.868L28.8165 16.196C28.8431 16.2387 28.8805 16.26 28.9285 16.26H29.4005C29.4485 16.26 29.4858 16.2387 29.5125 16.196L30.3605 14.868C30.3711 14.852 30.3818 14.844 30.3925 14.844C30.4031 14.844 30.4085 14.8547 30.4085 14.876V18.404C30.4085 18.4307 30.4165 18.4547 30.4325 18.476C30.4538 18.492 30.4778 18.5 30.5045 18.5H31.4405C31.4671 18.5 31.4885 18.492 31.5045 18.476C31.5258 18.4547 31.5365 18.4307 31.5365 18.404V12.996C31.5365 12.9693 31.5258 12.948 31.5045 12.932C31.4885 12.9107 31.4671 12.9 31.4405 12.9H30.5045Z"
      fill={props.fill}
    />
    <path
      d="M3.38672 5.5C3.38672 5.22386 3.16286 5 2.88672 5H1.38672C0.834435 5 0.386719 5.44772 0.386719 6V26C0.386719 26.5523 0.834433 27 1.38672 27H2.88672C3.16286 27 3.38672 26.7761 3.38672 26.5C3.38672 26.2239 3.16286 26 2.88672 26H1.38672V6H2.88672C3.16286 6 3.38672 5.77614 3.38672 5.5Z"
      fill={props.fill}
    />
    <path
      d="M7.23047 5C6.95433 5 6.73047 5.22386 6.73047 5.5C6.73047 5.77614 6.95433 6 7.23047 6H8.73047V26H7.23047C6.95433 26 6.73047 26.2239 6.73047 26.5C6.73047 26.7761 6.95433 27 7.23047 27H8.73047C9.28275 27 9.73047 26.5523 9.73047 26V6C9.73047 5.44772 9.28275 5 8.73047 5H7.23047Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M4.64014 10.8384C4.34619 10.8384 4.09212 10.7632 3.87793 10.6128C3.66602 10.4624 3.50309 10.2528 3.38916 9.98389C3.27523 9.71273 3.21826 9.39714 3.21826 9.03711V8.96533C3.21826 8.57113 3.27523 8.22705 3.38916 7.93311C3.50309 7.63916 3.66602 7.41016 3.87793 7.24609C4.09212 7.08203 4.34733 7 4.64355 7C4.8623 7 5.05143 7.04443 5.21094 7.1333C5.37272 7.22217 5.50944 7.34863 5.62109 7.5127L5.68945 7.07178H6.43115V9.65918C6.43115 9.83691 6.4528 9.9611 6.49609 10.0317C6.54167 10.1024 6.60319 10.1377 6.68066 10.1377C6.69662 10.1377 6.71256 10.1366 6.72852 10.1343C6.74447 10.132 6.75814 10.1274 6.76953 10.1206L6.85156 10.7393C6.77865 10.778 6.70687 10.8042 6.63623 10.8179C6.56787 10.8315 6.49496 10.8384 6.41748 10.8384C6.23063 10.8384 6.07227 10.7939 5.94238 10.7051C5.81478 10.6139 5.72021 10.4749 5.65869 10.2881C5.54476 10.4681 5.40462 10.6048 5.23828 10.6982C5.07194 10.7917 4.87256 10.8384 4.64014 10.8384ZM4.88281 10.1753C5.04232 10.1753 5.1779 10.1366 5.28955 10.0591C5.40348 9.97933 5.49691 9.86654 5.56982 9.7207V8.13818C5.51969 8.0402 5.46159 7.95589 5.39551 7.88525C5.32943 7.81462 5.25423 7.76107 5.16992 7.72461C5.08561 7.68587 4.99219 7.6665 4.88965 7.6665C4.70508 7.6665 4.55241 7.72233 4.43164 7.83398C4.31315 7.94336 4.22542 8.09489 4.16846 8.28857C4.11149 8.48226 4.08301 8.70784 4.08301 8.96533V9.03711C4.08301 9.38346 4.14681 9.66032 4.27441 9.86768C4.40202 10.0728 4.60482 10.1753 4.88281 10.1753Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M3.37207 19.1133V14.147C3.37207 13.8758 3.44271 13.6331 3.58398 13.4189C3.72754 13.2025 3.92008 13.0316 4.16162 12.9062C4.40316 12.7809 4.67432 12.7183 4.9751 12.7183C5.27588 12.7183 5.5402 12.7752 5.76807 12.8892C5.99821 13.0008 6.17708 13.158 6.30469 13.3608C6.43457 13.5614 6.49951 13.7949 6.49951 14.0615C6.49951 14.2803 6.44369 14.4797 6.33203 14.6597C6.22038 14.8374 6.06771 14.981 5.87402 15.0903C6.1543 15.1906 6.36963 15.3467 6.52002 15.5586C6.67041 15.7705 6.74561 16.0326 6.74561 16.3447C6.74561 16.6546 6.68294 16.9212 6.55762 17.1445C6.43229 17.3678 6.25456 17.5399 6.02441 17.6606C5.79655 17.7791 5.52881 17.8384 5.22119 17.8384C5.04118 17.8384 4.86572 17.8156 4.69482 17.77C4.52393 17.7244 4.37012 17.6549 4.2334 17.5615V19.1133H3.37207ZM5.02979 17.1753C5.29639 17.1753 5.50602 17.0967 5.65869 16.9395C5.81136 16.7822 5.8877 16.5726 5.8877 16.3105C5.8877 16.1533 5.85693 16.0109 5.79541 15.8833C5.73389 15.7534 5.64502 15.6509 5.52881 15.5757C5.41488 15.4982 5.27702 15.4595 5.11523 15.4595H4.71875V14.8306H4.9375C5.17904 14.8306 5.35563 14.7679 5.46729 14.6426C5.58122 14.515 5.63818 14.3418 5.63818 14.123C5.63818 13.984 5.61084 13.8599 5.55615 13.7505C5.50374 13.6388 5.42627 13.55 5.32373 13.4839C5.22347 13.4178 5.10156 13.3848 4.95801 13.3848C4.81901 13.3848 4.69482 13.4178 4.58545 13.4839C4.47607 13.55 4.38949 13.64 4.32568 13.7539C4.26416 13.8678 4.2334 13.9989 4.2334 14.147V16.895C4.33138 16.9839 4.44873 17.0534 4.58545 17.1035C4.72445 17.1514 4.87256 17.1753 5.02979 17.1753Z"
      fill={props.fill}
    />
    <path
      d="M4.54443 24.082V25.6919H5.40576V24.1538L6.4585 21.146L6.7627 21.0981V20.5684H5.19043V21.0981L5.60059 21.1699L5.06055 23.0088L5.00928 23.2515H4.98877L4.94092 23.0088L4.32227 21.0469C4.27669 20.9079 4.2277 20.8008 4.17529 20.7256C4.12288 20.6504 4.05453 20.5991 3.97021 20.5718C3.88818 20.5444 3.77767 20.5308 3.63867 20.5308C3.5612 20.5308 3.47689 20.5376 3.38574 20.5513C3.2946 20.5649 3.21484 20.5832 3.14648 20.606L3.21484 21.2588C3.2627 21.2497 3.2946 21.244 3.31055 21.2417C3.3265 21.2394 3.34587 21.2383 3.36865 21.2383C3.45068 21.2383 3.51335 21.2542 3.55664 21.2861C3.60221 21.3158 3.63867 21.3761 3.66602 21.4673L4.54443 24.082Z"
      fill={props.fill}
    />
  </svg>
);

CoordinaterotationconversionRotationanglestodcm.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default CoordinaterotationconversionRotationanglestodcm;
