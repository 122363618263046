import styled from '@emotion/styled/macro';
import { BlockParameterDefinition } from 'app/generated_types/ComputationBlockClass';
import { NodeInstance } from 'app/generated_types/SimulationModel';
import { useAppSelector } from 'app/hooks';
import { getNamePath } from 'app/utils/modelDiagramUtils';
import React from 'react';
import { shallowEqual } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Button from 'ui/common/Button/Button';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import { Copy, Edit, Reset } from 'ui/common/Icons/Standard';
import { DetailsLabel, DetailsSection } from 'ui/modelEditor/DetailsComponents';
import { useAppParams } from 'util/useAppParams';
import BlockParameterValue from './BlockParameterValue';
import {
  BooleanDisplayType,
  getBooleanDisplayType,
  getParameterDisplayName,
  isDataFileDropdown,
  isParameterHidden,
  isParameterReadOnly,
  isSubmodelDropdown,
} from './blockParameterDisplay';

// Floats above the right end of the label text
const CopyButton = styled(Button)`
  opacity: 0;
  width: 24px;
  :hover {
    opacity: 1;
  }

  position: absolute;
  top: 0;
  right: -28px;
  z-index: 1;
`;

const RightSideButton = styled(Button)`
  flex-position: right;
  justify-self: end;
  height: 24px;
  margin-left: 8px;
  margin-right: -4px; // small hack to v-align with checkboxes
`;

const LabelInner = styled.div`
  position: relative;
  display: flex;
`;

const Spacer = styled.div`
  max-width: 100%;
  flex: 1;
`;

interface Props {
  parentPath: string[];
  selectedNode: NodeInstance;
  paramDef: BlockParameterDefinition;
  disabled: boolean;
  isOptional?: boolean;
  paramEditSideEffect?: () => void;
  onReset?: () => void;
}

const BlockParameter: React.FC<Props> = ({
  parentPath,
  selectedNode,
  paramDef,
  disabled,
  isOptional,
  paramEditSideEffect,
  onReset,
}: Props) => {
  const navigate = useNavigate();
  const { projectId } = useAppParams();

  const userOptions = useAppSelector((state) => state.userOptions.options);
  const developerModeEnabled = userOptions.developerModeEnabled;

  // Get the block path that can identify the parameter in the scope of the document.
  const parentNamePath = useAppSelector(
    (state) =>
      getNamePath(
        state.model.present.rootModel.nodes,
        state.model.present.submodels,
        state.model.present.currentSubmodelPath,
        state.submodels.idToVersionIdToSubmodelFull,
        state.submodels.idToLatestTaggedVersionId,
      ),
    // since this is a string[] we can't use Object.is()
    shallowEqual,
  );

  const parameterDisplayName = getParameterDisplayName(
    selectedNode.type,
    paramDef,
  );
  const isReadOnly = disabled || isParameterReadOnly(selectedNode, paramDef);

  const booleanDisplayType = getBooleanDisplayType(paramDef);
  const isMultiRowSection =
    isDataFileDropdown(selectedNode, paramDef) ||
    isSubmodelDropdown(selectedNode, paramDef) ||
    paramDef.display_full_width ||
    booleanDisplayType === BooleanDisplayType.None ||
    booleanDisplayType === BooleanDisplayType.List;

  const stretchLabel =
    isMultiRowSection ||
    getBooleanDisplayType(paramDef) === BooleanDisplayType.Checkbox;

  const submodelId = isSubmodelDropdown(selectedNode, paramDef)
    ? selectedNode.parameters[paramDef.name]?.value
    : undefined;
  const openSubmodel = React.useCallback(() => {
    if (!submodelId) return;
    navigate(`/projects/${projectId}/models/${submodelId}`);
  }, [submodelId, navigate, projectId]);

  if (isParameterHidden(selectedNode, paramDef, userOptions)) return null;

  return (
    <DetailsSection title={paramDef.description} vertical={isMultiRowSection}>
      <DetailsLabel stretch={stretchLabel} disabled={isReadOnly}>
        <>
          <LabelInner>
            {parameterDisplayName}
            {developerModeEnabled && (
              // Should we expose this to all users?
              <CopyButton
                variant={ButtonVariants.SmallTertiary}
                Icon={Copy}
                onClick={() => {
                  navigator.clipboard.writeText(
                    [...parentNamePath, selectedNode.name, paramDef.name].join(
                      '.',
                    ),
                  );
                }}
              />
            )}
          </LabelInner>
          <Spacer />
          {onReset && (
            <RightSideButton
              variant={ButtonVariants.SmallTertiary}
              Icon={Reset}
              onClick={onReset}
            />
          )}
          {submodelId && (
            <RightSideButton
              variant={ButtonVariants.SmallTertiary}
              Icon={Edit}
              onClick={openSubmodel}
              disabled={!submodelId}
            />
          )}
        </>
      </DetailsLabel>
      <BlockParameterValue
        parentPath={parentPath}
        selectedNode={selectedNode}
        paramDef={paramDef}
        isReadOnly={isReadOnly}
        isOptional={isOptional}
        paramEditSideEffect={paramEditSideEffect}
      />
    </DetailsSection>
  );
};

export default BlockParameter;
