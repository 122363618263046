/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Discretefilter: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.9158 14C14.8385 14 14.7844 13.9755 14.7535 13.9264C14.3286 13.4007 13.9848 12.7629 13.7222 12.0129C13.4672 11.2558 13.3397 10.4252 13.3397 9.52103C13.3397 8.60981 13.4672 7.7757 13.7222 7.01869C13.9848 6.25467 14.3325 5.60631 14.7651 5.0736C14.796 5.02453 14.8501 5 14.9274 5H15.9471C16.0012 5 16.036 5.01402 16.0514 5.04206C16.0746 5.07009 16.0746 5.10514 16.0514 5.1472C15.6961 5.70794 15.4063 6.36332 15.1823 7.11332C14.9583 7.85631 14.8462 8.65888 14.8462 9.52103C14.8462 10.3762 14.9583 11.1717 15.1823 11.9077C15.4063 12.6437 15.6961 13.2921 16.0514 13.8528C16.0669 13.8808 16.0746 13.9019 16.0746 13.9159C16.0746 13.972 16.0321 14 15.9471 14H14.9158Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M11.5311 9.15304C11.4848 9.17407 11.4848 9.19509 11.5311 9.21612C12.3191 9.55257 12.7131 10.1308 12.7131 10.9509C12.7131 11.6168 12.4659 12.125 11.9715 12.4755C11.4848 12.8189 10.8474 12.9907 10.0594 12.9907H7.13906C7.10043 12.9907 7.06567 12.9801 7.03477 12.9591C7.01159 12.9311 7 12.8995 7 12.8645V5.75701C7 5.72196 7.01159 5.69393 7.03477 5.6729C7.06567 5.64486 7.10043 5.63084 7.13906 5.63084H9.96666C10.8088 5.63084 11.4577 5.79556 11.9135 6.125C12.3693 6.45444 12.5973 6.9521 12.5973 7.61799C12.5973 8.35397 12.2419 8.86565 11.5311 9.15304ZM8.40221 6.68224C8.36358 6.68224 8.34427 6.69977 8.34427 6.73481V8.67991C8.34427 8.71495 8.36358 8.73248 8.40221 8.73248H9.96666C10.3761 8.73248 10.6967 8.64486 10.9285 8.46963C11.168 8.28738 11.2877 8.03855 11.2877 7.72313C11.2877 7.4007 11.168 7.14836 10.9285 6.96612C10.6967 6.77687 10.3761 6.68224 9.96666 6.68224H8.40221ZM10.0594 11.9287C10.4611 11.9287 10.7779 11.8306 11.0096 11.6343C11.2491 11.4381 11.3689 11.1717 11.3689 10.8353C11.3689 10.4918 11.2491 10.2255 11.0096 10.0362C10.7701 9.83995 10.4457 9.74182 10.0362 9.74182H8.40221C8.36358 9.74182 8.34427 9.75935 8.34427 9.79439V11.8762C8.34427 11.9112 8.36358 11.9287 8.40221 11.9287H10.0594Z"
      fill={props.fill}
    />
    <path
      d="M17.3888 12.9591C17.4197 12.9801 17.4545 12.9907 17.4931 12.9907H21.8041C21.8427 12.9907 21.8736 12.9801 21.8968 12.9591C21.9277 12.9311 21.9431 12.8995 21.9431 12.8645V12.0129C21.9431 11.9778 21.9277 11.9498 21.8968 11.9287C21.8736 11.9007 21.8427 11.8867 21.8041 11.8867H18.9649C18.9108 11.8867 18.8992 11.8657 18.9301 11.8236L21.8388 8.78505C21.8852 8.74299 21.9083 8.69393 21.9083 8.63785V7.74416C21.9083 7.70911 21.8929 7.68107 21.862 7.66005C21.8388 7.63201 21.8079 7.61799 21.7693 7.61799H17.5511C17.5124 7.61799 17.4777 7.63201 17.4468 7.66005C17.4236 7.68107 17.412 7.70911 17.412 7.74416V8.59579C17.412 8.63084 17.4236 8.66238 17.4468 8.69042C17.4777 8.71145 17.5124 8.72196 17.5511 8.72196H20.228C20.2898 8.72196 20.3014 8.74299 20.2628 8.78505L17.412 11.8236C17.3734 11.8586 17.3541 11.9077 17.3541 11.9708V12.8645C17.3541 12.8995 17.3657 12.9311 17.3888 12.9591Z"
      fill={props.fill}
    />
    <path
      d="M24.424 5C24.5012 5 24.5553 5.02453 24.5862 5.0736C25.0111 5.5993 25.351 6.24065 25.606 6.99766C25.8687 7.74766 26 8.57126 26 9.46846C26 10.3727 25.8687 11.2103 25.606 11.9813C25.3433 12.7453 24.9957 13.3937 24.563 13.9264C24.5321 13.9755 24.478 14 24.4008 14H23.381C23.3269 14 23.2883 13.986 23.2651 13.9579C23.2497 13.9299 23.2535 13.8949 23.2767 13.8528C23.6321 13.2921 23.9218 12.6402 24.1458 11.8972C24.3699 11.1472 24.4819 10.3411 24.4819 9.47897C24.4819 8.61682 24.3699 7.81776 24.1458 7.08178C23.9218 6.34579 23.6321 5.70093 23.2767 5.1472C23.2612 5.11916 23.2535 5.09813 23.2535 5.08411C23.2535 5.02804 23.296 5 23.381 5H24.424Z"
      fill={props.fill}
    />
    <path
      d="M4.75 16C4.75 15.5858 5.08579 15.25 5.5 15.25H26.5C26.9142 15.25 27.25 15.5858 27.25 16C27.25 16.4142 26.9142 16.75 26.5 16.75H5.5C5.08579 16.75 4.75 16.4142 4.75 16Z"
      fill={props.fill}
    />
    <path
      d="M14.6408 26.9264C14.672 26.9755 14.7266 27 14.8047 27H15.8464C15.9322 27 15.9751 26.972 15.9751 26.9159C15.9751 26.9019 15.9673 26.8808 15.9517 26.8528C15.5928 26.2921 15.3002 25.6437 15.0739 24.9077C14.8476 24.1717 14.7345 23.3762 14.7345 22.521C14.7345 21.6589 14.8476 20.8563 15.0739 20.1133C15.3002 19.3633 15.5928 18.7079 15.9517 18.1472C15.9751 18.1051 15.9751 18.0701 15.9517 18.0421C15.9361 18.014 15.901 18 15.8464 18H14.8164C14.7384 18 14.6837 18.0245 14.6525 18.0736C14.2155 18.6063 13.8644 19.2547 13.5991 20.0187C13.3416 20.7757 13.2128 21.6098 13.2128 22.521C13.2128 23.4252 13.3416 24.2558 13.5991 25.0128C13.8644 25.7628 14.2116 26.4007 14.6408 26.9264Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M11.4286 25.8855C11.452 25.9556 11.5027 25.9907 11.5808 25.9907H12.7512C12.8371 25.9907 12.88 25.9591 12.88 25.896C12.88 25.875 12.8761 25.8575 12.8683 25.8435L10.3167 18.736C10.2933 18.6659 10.2425 18.6308 10.1645 18.6308H8.70143C8.6234 18.6308 8.57268 18.6659 8.54927 18.736L6.00936 25.8435C5.99376 25.8925 5.99766 25.9311 6.02107 25.9591C6.04448 25.9801 6.07959 25.9907 6.12641 25.9907H7.29688C7.37491 25.9907 7.42563 25.9556 7.44904 25.8855L7.83529 24.7605C7.8509 24.7325 7.8704 24.7185 7.89381 24.7185H10.9838C11.0073 24.7185 11.0268 24.7325 11.0424 24.7605L11.4286 25.8855ZM8.24495 23.7617C8.20594 23.7617 8.19033 23.7407 8.19813 23.6986L9.39201 20.1764C9.39981 20.1484 9.41152 20.1343 9.42712 20.1343C9.44273 20.1343 9.45443 20.1484 9.46224 20.1764L10.6678 23.6986C10.6834 23.7407 10.6678 23.7617 10.621 23.7617H8.24495Z"
      fill={props.fill}
    />
    <path
      d="M17.4079 25.9907C17.3689 25.9907 17.3337 25.9801 17.3025 25.9591C17.2791 25.9311 17.2674 25.8995 17.2674 25.8645V24.9708C17.2674 24.9077 17.2869 24.8586 17.3259 24.8236L20.2053 21.785C20.2443 21.743 20.2326 21.722 20.1702 21.722H17.4664C17.4274 21.722 17.3923 21.7114 17.3611 21.6904C17.3376 21.6624 17.3259 21.6308 17.3259 21.5958V20.7442C17.3259 20.7091 17.3376 20.6811 17.3611 20.66C17.3923 20.632 17.4274 20.618 17.4664 20.618H21.7269C21.7659 20.618 21.7971 20.632 21.8205 20.66C21.8517 20.6811 21.8673 20.7091 21.8673 20.7442V21.6378C21.8673 21.6939 21.8439 21.743 21.7971 21.785L18.8592 24.8236C18.828 24.8657 18.8397 24.8867 18.8944 24.8867H21.762C21.801 24.8867 21.8322 24.9007 21.8556 24.9287C21.8869 24.9498 21.9025 24.9778 21.9025 25.0128V25.8645C21.9025 25.8995 21.8869 25.9311 21.8556 25.9591C21.8322 25.9801 21.801 25.9907 21.762 25.9907H17.4079Z"
      fill={props.fill}
    />
    <path
      d="M24.572 18.0736C24.5408 18.0245 24.4862 18 24.4082 18H23.3547C23.2689 18 23.226 18.028 23.226 18.0841C23.226 18.0981 23.2338 18.1192 23.2494 18.1472C23.6083 18.7009 23.901 19.3458 24.1273 20.0818C24.3535 20.8178 24.4667 21.6168 24.4667 22.479C24.4667 23.3411 24.3535 24.1472 24.1273 24.8972C23.901 25.6402 23.6083 26.2921 23.2494 26.8528C23.226 26.8949 23.2221 26.9299 23.2377 26.9579C23.2611 26.986 23.3001 27 23.3547 27H24.3848C24.4628 27 24.5174 26.9755 24.5486 26.9264C24.9856 26.3937 25.3367 25.7453 25.602 24.9813C25.8673 24.2103 26 23.3727 26 22.4685C26 21.5713 25.8673 20.7477 25.602 19.9977C25.3445 19.2407 25.0012 18.5993 24.572 18.0736Z"
      fill={props.fill}
    />
  </svg>
);

Discretefilter.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Discretefilter;
