// !!!! -- DO NOT MANUALLY EDIT -- !!!!
// This file was generated by something in scripts/generateDefaultBlockData.js
// If you want to change the contents of this file, it should be automated
//
// (You can edit this file locally, but it may not be checked into the codebase -
// PRs with a manual edit of this file are forbidden from being merged.)

import { computationBlockDefaults } from 'app/generated_blocks/computationBlockDefaults';
import type { ComputationBlockClass } from 'app/generated_types/ComputationBlockClass';
import deepmerge from 'deepmerge';

export const DerivativeDiscrete: ComputationBlockClass = deepmerge(
  computationBlockDefaults,
  {
    "base": {
      "name": "DerivativeDiscrete",
      "namespace": "core",
      "description": "Computes the first-order approximation of the first derivative of the input signal.",
      "help_url": "https://docs.collimator.ai/using-model-editors/block-library/discrete-blocks#derivative-discrete",
      "supports_iterator": false
    },
    "modes": {},
    "ports": {
      "inputs": {
        "static": [
          {
            "name": "in_0"
          }
        ]
      },
      "outputs": {
        "static": [
          {
            "name": "out_0"
          }
        ]
      }
    },
    "parameter_definitions": [
      {
        "name": "filter_coefficient",
        "data_type": "float",
        "default_value": "1.0",
        "description": "The filter coefficient to use for the derivative calculation."
      },
      {
        "name": "filter_type",
        "data_type": "string",
        "default_value": "none",
        "description": "Type of filter used to approximate the derivative. By default a simple backward difference approximation.",
        "allowed_values": [
          "none",
          "forward",
          "backward",
          "bilinear"
        ]
      }
    ]
  }
);
