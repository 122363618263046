/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Pyansys: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.6558 4H17.9257C17.961 4 17.9852 4.03576 17.9721 4.06861L8.38978 27.9686C8.38218 27.9876 8.3638 28 8.34337 28H2.07349C2.0381 28 2.01391 27.9642 2.02708 27.9314L11.6094 4.03139C11.617 4.01243 11.6353 4 11.6558 4Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M20.6727 4.91592C20.479 4.46306 19.8335 4.47346 19.6546 4.93235L16.5411 12.9174C16.4897 13.0489 16.491 13.1952 16.5446 13.3259L22.6287 28.1587C22.7133 28.3652 22.9144 28.5 23.1375 28.5H29.9239C30.3185 28.5 30.5847 28.0966 30.4296 27.7338L20.6727 4.91592ZM17.5381 13.1129L20.1862 6.32162L29.242 27.5H23.4393L17.5381 13.1129Z"
      fill={props.fill}
    />
  </svg>
);

Pyansys.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Pyansys;
