import {
  useGetEntityPreferencesQuery,
  usePutEntityPreferencesMutation,
} from 'app/enhancedApi';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
  EntityPrefsKey,
  EntityPrefsMapType,
  _selectEntityPrefNeedsSaving,
  entityPreferencesActions,
  selectEntityPrefs,
} from 'app/slices/entityPreferencesSlice';
import React from 'react';
import { useDebounce } from 'react-use';

const TIME_BETWEEN_USER_PREF_UPDATE_REQUESTS_IN_MS = 1000;

const useEntityPreferences = <T extends EntityPrefsKey>(
  preferencesKey: T,
  entityId: string | undefined,
) => {
  const [callPutEntityPreferences] = usePutEntityPreferencesMutation();
  const { data: preferencesContainer, isFetching } =
    useGetEntityPreferencesQuery(
      {
        entityUuid: entityId || '',
        preferencesKey,
      },
      {
        skip: !entityId,
      },
    );
  const dispatch = useAppDispatch();
  // Necessary to ensure frontend value is set from server only on mount or an ID/key change,
  // not on every entityPrefs value change.
  const isInitializedRef = React.useRef<boolean>(false);
  const entityPrefs = useAppSelector((state) =>
    selectEntityPrefs(state, preferencesKey, entityId),
  );

  // Listen for a request to update the preferences.
  const entityPrefsNeedSaving = useAppSelector((state) =>
    _selectEntityPrefNeedsSaving(state, preferencesKey, entityId),
  );

  // On ID/key change, reset so that the entity pref value can be hydrated if needed.
  React.useEffect(() => {
    isInitializedRef.current = false;
  }, [preferencesKey, entityId]);

  // If the value in the store is null, which indicates it has never been fetched from server,
  // hydrate the value in frontend store with value from backend.
  React.useEffect(() => {
    if (
      isInitializedRef.current === false &&
      preferencesContainer &&
      !isFetching
    ) {
      if (!entityPrefs) {
        dispatch(
          entityPreferencesActions._setPrefsFromServer({
            preferencesKey,
            entityId,
            // NOTE: might be good to eventually make sure this data is fully trustworthy
            prefs: (preferencesContainer.data || {}) as EntityPrefsMapType[T],
          }),
        );
      }
      isInitializedRef.current = true;
    }
  }, [
    dispatch,
    preferencesKey,
    entityId,
    preferencesContainer,
    entityPrefs,
    isFetching,
  ]);

  // Update prefs when they change.
  useDebounce(
    () => {
      if (
        entityId &&
        isInitializedRef.current &&
        entityPrefsNeedSaving &&
        entityPrefs
      ) {
        callPutEntityPreferences({
          entityUuid: entityId || '',
          preferencesKey,
          entityPreferencesContainer: {
            data: entityPrefs,
          },
        });

        // Clear the request to update the prefs.
        dispatch(
          entityPreferencesActions._onPrefsUpdateSentToServer({
            preferencesKey,
            entityId,
          }),
        );
      }
    },
    TIME_BETWEEN_USER_PREF_UPDATE_REQUESTS_IN_MS,
    [entityPrefsNeedSaving, entityPrefs, callPutEntityPreferences],
  );
};

export default useEntityPreferences;
