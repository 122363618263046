/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Public: React.FC<Props> = (props) => (
  <svg viewBox="0 0 24 24" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M15 6.5C15 8.15685 13.6569 9.5 12 9.5C10.3431 9.5 9 8.15685 9 6.5C9 4.84315 10.3431 3.5 12 3.5C13.6569 3.5 15 4.84315 15 6.5ZM13.5 6.5C13.5 7.32843 12.8284 8 12 8C11.1716 8 10.5 7.32843 10.5 6.5C10.5 5.67157 11.1716 5 12 5C12.8284 5 13.5 5.67157 13.5 6.5Z"
      fill={props.fill}
    />
    <path
      d="M15.9872 8.5881C16.1465 8.2847 16.5445 8.16461 16.7941 8.39932C17.7806 9.32685 18.4979 10.5373 18.8166 11.9011C18.8944 12.2342 18.5917 12.5181 18.25 12.5039C18.187 12.5013 18.1236 12.5 18.06 12.5C18.0064 12.5 17.9531 12.5009 17.9 12.5028C17.6301 12.5122 17.3828 12.3333 17.3129 12.0725C17.0599 11.1286 16.5621 10.2849 15.8917 9.61354C15.7007 9.42231 15.6701 9.11843 15.8138 8.88954C15.8754 8.79154 15.9332 8.69099 15.9872 8.5881Z"
      fill={props.fill}
    />
    <path
      d="M13.4182 18.8154C12.9657 18.9358 12.4904 19 12 19C11.5096 19 11.0343 18.9358 10.5818 18.8154C10.3209 18.746 10.0427 18.871 9.91596 19.1094C9.86118 19.2125 9.8025 19.3131 9.74014 19.4112C9.55631 19.7003 9.65081 20.105 9.97886 20.2038C10.6188 20.3965 11.2973 20.5 12 20.5C12.7027 20.5 13.3812 20.3965 14.0211 20.2038C14.3492 20.105 14.4437 19.7003 14.2599 19.4112C14.1975 19.3131 14.1388 19.2125 14.084 19.1094C13.9573 18.871 13.6791 18.746 13.4182 18.8154Z"
      fill={props.fill}
    />
    <path
      d="M6.68708 12.0725C6.61716 12.3333 6.36991 12.5122 6.10001 12.5028C6.0469 12.5009 5.99356 12.5 5.94 12.5C5.87636 12.5 5.81303 12.5013 5.75004 12.5039C5.40825 12.5181 5.1056 12.2342 5.18343 11.9011C5.50207 10.5373 6.21944 9.32685 7.2059 8.39932C7.45553 8.16461 7.85354 8.2847 8.01276 8.5881C8.06675 8.69099 8.12461 8.79154 8.18615 8.88954C8.32986 9.11843 8.29926 9.42231 8.10828 9.61354C7.43787 10.2849 6.94006 11.1286 6.68708 12.0725Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M15.4641 15.5C14.6357 16.9349 15.1273 18.7696 16.5622 19.5981C17.9971 20.4265 19.8318 19.9349 20.6603 18.5C21.4887 17.0651 20.9971 15.2303 19.5622 14.4019C18.1273 13.5735 16.2925 14.0651 15.4641 15.5ZM16.7631 16.25C16.3489 16.9674 16.5947 17.8848 17.3122 18.299C18.0296 18.7132 18.947 18.4674 19.3612 17.75C19.7754 17.0326 19.5296 16.1152 18.8122 15.701C18.0947 15.2867 17.1774 15.5326 16.7631 16.25Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M7.43782 19.5981C6.00294 20.4265 4.16817 19.9349 3.33974 18.5C2.51131 17.0651 3.00294 15.2303 4.43782 14.4019C5.8727 13.5735 7.70747 14.0651 8.53589 15.5C9.36432 16.9349 8.8727 18.7696 7.43782 19.5981ZM6.68782 18.299C5.97038 18.7132 5.05299 18.4674 4.63878 17.75C4.22457 17.0326 4.47038 16.1152 5.18782 15.701C5.90526 15.2867 6.82264 15.5326 7.23686 16.25C7.65107 16.9674 7.40526 17.8848 6.68782 18.299Z"
      fill={props.fill}
    />
  </svg>
);

Public.defaultProps = {
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Public;
