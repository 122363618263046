/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Translational: React.FC<Props> = (props) => (
  <svg viewBox="0 0 8 8" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M1.75031 1.25C2.30259 1.25 2.75031 1.69772 2.75031 2.25V3.25001H5.34717L4.68556 2.49389C4.4128 2.18216 4.44439 1.70834 4.75612 1.43558C5.06784 1.16282 5.54167 1.1944 5.81443 1.50613L7.56443 3.50613C7.81185 3.7889 7.81185 4.21112 7.56443 4.49389L5.81443 6.49389C5.54167 6.80562 5.06784 6.8372 4.75612 6.56444C4.44439 6.29168 4.4128 5.81786 4.68556 5.50613L5.34717 4.75001H2.75031V5.75C2.75031 6.30228 2.30259 6.75 1.75031 6.75C1.19802 6.75 0.750305 6.30228 0.750305 5.75V2.25C0.750305 1.69772 1.19802 1.25 1.75031 1.25Z"
      fill={props.fill}
    />
  </svg>
);

Translational.defaultProps = {
  width: '8',
  height: '8',
  viewBox: '0 0 8 8',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Translational;
