import { useTheme } from '@emotion/react';
import styled from '@emotion/styled/macro';
import { t } from '@lingui/macro';
import { useAppDispatch } from 'app/hooks';
import { uiFlagsActions } from 'app/slices/uiFlagsSlice';
import React, { ReactElement } from 'react';
import Button from 'ui/common/Button/Button';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import { AiAgent, ArrowUp, Image } from 'ui/common/Icons/Standard';
import DialogButtons, { DialogButtonsProps } from './DialogButtons';
import { INPUT_BAR_HEIGHT } from './Sizings';
import { useImageUpload } from './useImageUpload';

const UploadFileInput = styled.input`
  visibility: hidden;
  position: absolute;
  z-index: -100;
  width: 10px;
`;

const UploadFileFileDiv = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px;
  align-items: center;
  position: relative;
  top: -1px;
`;

const UserInputBoxDiv = styled.div`
  display: flex;
  position: flex-end;
  align-items: center;
  pointer-events: auto;
  z-index: 1;
  width: 100%;
  border-radius: 3px;

  background-color: ${({ theme }) => theme.colors.grey[2]};
  box-shadow: ${({ theme }) => theme.shadows.standard};
  border: none;

  &:focus-within {
    outline: 2px solid ${({ theme }) => theme.colors.brand.primary.lighter};
  }

  > button {
    padding: ${({ theme }) => theme.spacing.normal};
    color: ${({ theme }) => theme.colors.tint.base};
  }

  &:hover {
      background-color: ${({ theme }) => theme.colors.grey[2]};
      outline: 2px solid ${({ theme }) => theme.colors.brand.primary.lighter};
    }
  }
`;

const UserInput = styled.textarea`
  pointer-events: auto;

  width: 100%;
  height: ${INPUT_BAR_HEIGHT}px;
  padding: ${({ theme }) => theme.spacing.normal}
    ${({ theme }) => theme.spacing.xlarge};

  font-size: ${({ theme }) => theme.typography.font.standard.size};
  font-weight: ${({ theme }) => theme.typography.font.standard.weight};
  line-height: ${({ theme }) => theme.typography.font.standard.lineHeight};
  border-radius: 3px;

  background-color: ${({ theme }) => theme.colors.grey[2]};
  outline: none;
  border: none;

  // Resizing could be useful but I couldn't get it right
  // the resize handle is always on the bottom-right, but it would be better on the top-right
  // resize: vertical;
  resize: none;
`;

const HowCanIHelpDiv = styled.div`
  padding: ${({ theme }) => theme.spacing.normal};
  color: ${({ theme }) => theme.colors.text.secondary};
  font-size: ${({ theme }) => theme.typography.font.standard.size};
  font-weight: ${({ theme }) => theme.typography.font.standard.weight};
  line-height: ${({ theme }) => theme.typography.font.standard.lineHeight};
  white-space: nowrap;

  display: flex;
  flex-direction: row;
  align-items: flex-end;
`;

const ButtonsAndInputBoxDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const UserInputDiv = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${({ theme }) => theme.colors.grey[5]};
`;

export interface UserInputBoxProps {
  input: string;
  setInput: (input: string) => void;
  isReady: boolean;
  onSubmit: (
    input: string,
    imageUrl?: string,
    imageBase64?: string,
  ) => Promise<void>;
}

const UserInputBox = ({
  input,
  setInput,
  isReady,
  onSubmit,
  output,
  onClickAbort,
  onRepeatQuestion,
}: UserInputBoxProps & DialogButtonsProps): ReactElement => {
  const theme = useTheme();
  const uploadImageFileRef = React.useRef<HTMLInputElement>(null);
  const [imageBase64, setImageBase64] = React.useState<string | undefined>();
  const [imageFile, setImageFile] = React.useState<File | undefined>();
  const [isLoadingImage, setIsLoadingImage] = React.useState(false);

  const dispatch = useAppDispatch();

  const { uploadImage } = useImageUpload();

  const sendMessage = React.useCallback(() => {
    if (!isReady || isLoadingImage) return;
    uploadImage(imageFile).then((imageUrl) => onSubmit(input, imageUrl));
    setImageBase64(undefined);
    setImageFile(undefined);
    setInput('');
  }, [
    imageFile,
    input,
    isLoadingImage,
    isReady,
    onSubmit,
    setInput,
    uploadImage,
  ]);

  const handleEnter = React.useCallback(
    async (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (
        e.key === 'Enter' &&
        input.length > 0 &&
        input[input.length - 1] !== '\\' &&
        !e.shiftKey
      ) {
        e.preventDefault();
        sendMessage();
      }
    },
    [input, sendMessage],
  );

  const loadBase64EncodedImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target?.files?.[0];
    if (!file) {
      return null;
    }

    const reader = new FileReader();
    reader.onload = () => {
      const base64EncodedImage = reader.result as string;
      setImageBase64(base64EncodedImage);
      setImageFile(file);
      setIsLoadingImage(false);
    };
    reader.readAsDataURL(file);
  };

  return (
    <UserInputDiv>
      <HowCanIHelpDiv>
        <AiAgent fill={theme.colors.brand.primary.base} />
        {t`How can I help?`}
      </HowCanIHelpDiv>
      <ButtonsAndInputBoxDiv>
        <DialogButtons
          output={output}
          onRepeatQuestion={onRepeatQuestion}
          onClickAbort={onClickAbort}
          isReady={isReady}
          onSubmit={onSubmit}
        />
        <UserInputBoxDiv>
          <UploadFileFileDiv>
            {imageBase64 && (
              <img
                src={imageBase64}
                alt="Uploaded"
                style={{
                  maxWidth: '128px',
                  maxHeight: '128px',
                  margin: '5px',
                }}
              />
            )}
            <Button
              Icon={Image}
              variant={ButtonVariants.SmallTertiary}
              onClick={() => uploadImageFileRef?.current?.click?.()}
              testId="chat-upload-image-button"
            />
            <UploadFileInput
              type="file"
              accept="image/png, image/jpeg, image/jpg"
              ref={uploadImageFileRef}
              onChange={(e) => {
                setIsLoadingImage(true);
                loadBase64EncodedImage(e);
                e.target.value = '';
              }}
            />
          </UploadFileFileDiv>
          <UserInput
            value={input}
            onFocus={() =>
              dispatch(uiFlagsActions.setUIFlag({ textInputFocused: true }))
            }
            onBlur={() =>
              dispatch(uiFlagsActions.setUIFlag({ textInputFocused: false }))
            }
            onChange={(e) => {
              setInput(e.target.value);
            }}
            onKeyDown={handleEnter}
            placeholder={t`Ask a question or give me a task`}
            data-cy="chat-gpt-input"
          />
          <Button
            disabled={!isReady || isLoadingImage || input.length === 0}
            Icon={ArrowUp}
            variant={ButtonVariants.SmallTertiary}
            onClick={sendMessage}
            testId="chat-submit-button"
          />
        </UserInputBoxDiv>
      </ButtonsAndInputBoxDiv>
    </UserInputDiv>
  );
};

export default UserInputBox;
