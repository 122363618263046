import { Coordinate } from 'app/common_types/Coordinate';
import { MAXIMUM_ZOOM, MINIMUM_ZOOM } from 'app/slices/cameraSlice';
import { overlayCameraExternalChange } from 'ui/modelEditor/RendererOverlay';
import { rendererState } from './modelRenderer';

export const BASE_ZOOM_DELTA = 20;

// Make the steps finer
const ZOOM_STEP_FACTOR = 0.5;

export const findNextZoomLevel = (zoom: number, zoomDelta: number): number =>
  Math.min(
    MAXIMUM_ZOOM,
    Math.max(
      MINIMUM_ZOOM,
      zoomDelta < 0
        ? zoom * (1 + Math.max(zoomDelta * ZOOM_STEP_FACTOR, -66) / 100)
        : zoom / (1 - Math.min(zoomDelta * ZOOM_STEP_FACTOR, 66) / 100),
    ),
  );

const zoomAroundScreenAnchor = (
  nextZoom: number,
  screenAnchor: Coordinate,
): { zoom: number; coord: Coordinate } => {
  if (!rendererState) {
    return { zoom: 1, coord: { x: 0, y: 0 } };
  }

  const { x: screenAnchorX, y: screenAnchorY } = screenAnchor;

  const previousWorldAnchorX = screenAnchorX / rendererState.zoom;
  const previousWorldAnchorY = screenAnchorY / rendererState.zoom;

  const newWorldAnchorX = screenAnchorX / nextZoom;
  const newWorldAnchorY = screenAnchorY / nextZoom;

  const cameraAdjustX = newWorldAnchorX - previousWorldAnchorX;
  const cameraAdjustY = newWorldAnchorY - previousWorldAnchorY;

  rendererState.camera.x += cameraAdjustX;
  rendererState.camera.y += cameraAdjustY;

  rendererState.zoom = nextZoom;

  overlayCameraExternalChange({
    zoom: rendererState.zoom,
    coord: rendererState.camera,
  });

  return {
    zoom: rendererState.zoom,
    coord: {
      x: rendererState.camera.x,
      y: rendererState.camera.y,
    },
  };
};

export const zoomAroundScreenCenter = (
  zoomDelta: number,
): { zoom: number; coord: Coordinate } => {
  if (!rendererState || !rendererState.refs.current.parent) {
    return { zoom: 1, coord: { x: 0, y: 0 } };
  }

  const screenCenterX = rendererState.refs.current.parent.clientWidth / 2;
  const screenCenterY = rendererState.refs.current.parent.clientHeight / 2;
  const nextZoom = findNextZoomLevel(rendererState.zoom, zoomDelta);

  return zoomAroundScreenAnchor(nextZoom, {
    x: screenCenterX,
    y: screenCenterY,
  });
};

export const setZoomAroundScreenCenter = (
  zoomLevel: number,
): { zoom: number; coord: Coordinate } => {
  if (!rendererState || !rendererState.refs.current.parent) {
    return { zoom: 1, coord: { x: 0, y: 0 } };
  }

  const screenCenterX = rendererState.refs.current.parent.clientWidth / 2;
  const screenCenterY = rendererState.refs.current.parent.clientHeight / 2;
  return zoomAroundScreenAnchor(zoomLevel, {
    x: screenCenterX,
    y: screenCenterY,
  });
};

export const resetZoomAroundScreenCenter = (): {
  zoom: number;
  coord: Coordinate;
} => {
  if (!rendererState) {
    return { zoom: 1, coord: { x: 0, y: 0 } };
  }

  return setZoomAroundScreenCenter(1);
};
