/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const CommandMenu: React.FC<Props> = (props) => (
  <svg viewBox="0 0 24 24" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M18 7C18 7.41421 17.6642 7.75 17.25 7.75H6.75C6.33579 7.75 6 7.41421 6 7C6 6.58579 6.33579 6.25 6.75 6.25H17.25C17.6642 6.25 18 6.58579 18 7ZM9 12C9 11.5858 9.33579 11.25 9.75 11.25H17.25C17.6642 11.25 18 11.5858 18 12C18 12.4142 17.6642 12.75 17.25 12.75H9.75C9.33579 12.75 9 12.4142 9 12ZM18 17C18 17.4142 17.6642 17.75 17.25 17.75H9.75C9.33579 17.75 9 17.4142 9 17C9 16.5858 9.33579 16.25 9.75 16.25H17.25C17.6642 16.25 18 16.5858 18 17Z"
      fill={props.fill}
    />
  </svg>
);

CommandMenu.defaultProps = {
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default CommandMenu;
