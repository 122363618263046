import {
  convertAPISubmodelInfoToSubmodelInfoUI,
  SubmodelInfoUI,
} from 'app/apiTransformers/convertGetSubmodelsListForModelParent';
import { GetSubmodelInfoApiResponse } from 'app/apiGenerated/generatedApiTypes';

export function convertGetSubmodelInfo(
  apiResponse: GetSubmodelInfoApiResponse,
): SubmodelInfoUI {
  return convertAPISubmodelInfoToSubmodelInfoUI(apiResponse);
}
