/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  className?: string;
}

const Flax: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.9762 1L20.9641 3.91891L15.9726 6.95253L10.8649 3.9189L15.9762 1Z"
      fill={props.fill}
    />
    <path
      d="M23.8903 5.59464L29.0005 8.59027V14.4672L26.0243 12.7057V10.1794L23.8903 8.944V5.59464Z"
      fill={props.fill}
    />
    <path
      d="M3 8.53385L8.05403 5.59546V8.944L5.92011 10.1794V12.7062L3 14.4096V8.53385Z"
      fill={props.fill}
    />
    <path
      d="M20.7929 7.4724L18.1045 5.88378L15.9585 7.23146L13.7852 5.94264L10.9151 7.46929L15.9614 10.5078L20.7929 7.4724Z"
      fill={props.fill}
    />
    <path
      d="M11.0562 10.9096L13.5559 9.34618L15.9582 10.8254L18.2155 9.40225L20.856 10.9067L15.9601 13.8313L11.0562 10.9096Z"
      fill={props.fill}
    />
    <path
      d="M22.8235 14.3037V17.5926L20.3526 18.9965V21.3538L17.6571 22.9466V17.1805L22.8235 14.3037Z"
      fill={props.fill}
    />
    <path
      d="M21.0824 13.2885V15.0103L17.4322 17.0319V21.1805L16.1406 22.0642V16.1712L21.0824 13.2885Z"
      fill={props.fill}
    />
    <path
      d="M10.7497 14.8962V13.2327L15.8037 16.1711V22.0529L14.3998 21.1838V17.0301L10.7497 14.8962Z"
      fill={props.fill}
    />
    <path
      d="M25.8 19.3331V16.2702L20.5775 19.1456V24.7472L23.5538 22.925V20.6246L25.8 19.3331Z"
      fill={props.fill}
    />
    <path
      d="M6.14463 16.0413V19.161L8.33471 20.5088V22.8702L11.1425 24.5183V18.9224L6.14463 16.0413Z"
      fill={props.fill}
    />
    <path
      d="M28.9441 23.713V17.7235L23.7778 20.7764V26.7071L28.9441 23.713Z"
      fill={props.fill}
    />
    <path
      d="M3 17.5487V23.3753L8.05403 26.3724V20.666L3 17.5487Z"
      fill={props.fill}
    />
    <path
      d="M25.8 10.33L21.022 7.62434L20.5775 7.92064V10.4621L23.0484 11.8099V14.7852L25.4601 16.1381L25.8 15.9681V10.33Z"
      fill={props.fill}
    />
    <path
      d="M10.7003 7.6787L6.1455 10.3309V15.6831L6.65072 15.9718L8.84099 14.7287V11.8632L11.1995 10.4593V8.03527L10.7003 7.6787Z"
      fill={props.fill}
    />
    <path
      d="M20.3433 24.0096L18.3968 22.7863L15.9716 24.2475L13.5024 22.7884L11.3579 24.0065L11.3579 24.6672L15.9721 27.3724L20.3457 24.8135L20.3433 24.0096Z"
      fill={props.fill}
    />
    <path
      d="M13.7839 26.4377L15.9725 27.7284L18.1596 26.4386L20.9719 28.0254L15.9723 30.9663L10.9181 28.0278L13.7839 26.4377Z"
      fill={props.fill}
    />
    <path
      d="M15.9718 14.0838L12.4909 12.0627L10.8063 13.0346L15.9729 16.0288L21.025 13.09L19.2848 12.0623L15.9718 14.0838Z"
      fill={props.fill}
    />
    <path
      d="M21.0793 10.9964L22.8233 11.9006V13.9978L21.2509 14.915V12.9888L19.4535 11.9719L21.0793 10.9964Z"
      fill={props.fill}
    />
    <path
      d="M9.06491 12.0465L9.06549 13.9589L10.573 14.8266L10.5731 12.9511L12.2941 11.9597L10.7665 11.0548L9.06491 12.0465Z"
      fill={props.fill}
    />
    <path
      d="M17.436 23.0409L15.9739 23.948L14.4075 23.0211L14.3991 21.463L15.9701 22.4472L17.4337 21.455L17.436 23.0409Z"
      fill={props.fill}
    />
    <path
      d="M14.1757 17.1253L9.06551 14.1884V17.4232L11.3679 18.7709V21.2978L14.1757 22.9459V17.1253Z"
      fill={props.fill}
    />
  </svg>
);

Flax.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  className: '',
};

export default Flax;
