/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Lookuptable1d: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M0 4H1V27H32V28H0V4Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M14.25 4.25H18.75V8.25H21.75V21.25H23.25V15.25H26.25V12.75H30.75V26H29.25V14.25H27.75V26H26.25V16.75H24.75V26H23.25V22.75H21.75V26H20.25V9.75H18.75V26H17.25V5.75H15.75V26H14.25V8.75H12.75V26H11.25V11.75H9.75V26H8.25V13.25H6.75V26H5.25V14.75H3.75V26H2.25V13.25H5.25V11.75H8.25V10.25H11.25V7.25H14.25V4.25Z"
      fill={props.fill}
    />
  </svg>
);

Lookuptable1d.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Lookuptable1d;
