/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  className?: string;
}

const TreeArrowExpanded: React.FC<Props> = (props) => (
  <svg viewBox="0 0 24 24" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.7048 16.7074L15.7246 12.3934C15.8406 12.2277 15.7221 12 15.5198 12L9.48016 12C9.27791 12 9.15937 12.2277 9.27536 12.3934L12.2952 16.7074C12.3947 16.8496 12.6053 16.8496 12.7048 16.7074Z"
      fill={props.fill}
    />
  </svg>
);

TreeArrowExpanded.defaultProps = {
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  className: '',
};

export default TreeArrowExpanded;
