import styled from '@emotion/styled/macro';
import React from 'react';
import TabButton from './TabButton';
import { TabInfo } from './tabTypes';

const TabGroupWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding-left: ${({ theme }) => theme.spacing.small};
  gap: ${({ theme }) => theme.spacing.large};
  justify-content: space-evenly;
`;

const TabButtonWrapper = styled.div<{
  compact: boolean;
  isTabSelected: boolean;
}>`
  min-width: ${({ compact, isTabSelected }) =>
    compact && isTabSelected ? '90px' : 'auto'};
  display: flex;
  justify-content: center;
`;

interface Props {
  tabs: TabInfo[];
  compact?: boolean;
  selectedIsHighlighted?: boolean;
  testId?: string;
  className?: string;
}

const TabGroup: React.FC<Props> = ({
  tabs,
  compact,
  selectedIsHighlighted,
  testId,
  className,
}) => (
  <TabGroupWrapper className={className}>
    {tabs.map((tab) => (
      <TabButtonWrapper
        key={tab.id}
        compact={Boolean(compact)}
        isTabSelected={tab.isSelected()}>
        <TabButton
          Icon={tab.Icon}
          onClick={tab.onClick}
          displayName={compact && !tab.isSelected() ? '' : tab.displayName}
          isTabSelected={tab.isSelected()}
          selectedIsHighlighted={selectedIsHighlighted}
          testId={`${testId}-${tab.id}`}
        />
      </TabButtonWrapper>
    ))}
  </TabGroupWrapper>
);

export default TabGroup;
