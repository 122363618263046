/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const PinFilled: React.FC<Props> = (props) => (
  <svg viewBox="0 0 24 24" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M10.7745 16.1226L14.8882 18.4976C15.446 18.8197 16.3319 18.6875 16.6122 17.8943C17.2293 16.1479 16.4206 14.1328 14.9468 12.5963L16.3516 9.52757L16.9731 9.88638C17.8101 10.3696 18.8804 10.0828 19.3636 9.24583L19.6136 8.81282C20.0969 7.97581 19.8101 6.90553 18.9731 6.42228L13.7769 3.42228C12.9399 2.93903 11.8696 3.22581 11.3864 4.06282L11.1364 4.49583C10.6531 5.33285 10.9399 6.40313 11.7769 6.88638L12.3984 7.24518L10.4432 9.99614C8.37569 9.48811 6.22615 9.79525 5.02232 11.2029C4.47551 11.8422 4.80402 12.6755 5.36188 12.9976L9.4755 15.3726L7.3505 19.0532C7.14339 19.4119 7.2663 19.8706 7.62502 20.0777C7.98374 20.2848 8.44243 20.1619 8.64954 19.8032L10.7745 16.1226Z"
      fill={props.fill}
    />
  </svg>
);

PinFilled.defaultProps = {
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default PinFilled;
