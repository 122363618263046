import styled from '@emotion/styled';
import Button from 'ui/common/Button/Button';
import Input from 'ui/common/Input/Input';
import {
  ExtraSmall,
  Small,
  SmallHighlight,
} from 'ui/common/typography/Typography';

export const VersionSegment = styled.div<{ isSelected: boolean }>`
  display: flex;
  flex-direction: row;
  width: 100%;
  ${({ isSelected, theme }) =>
    isSelected ? `background-color: ${theme.colors.ui.tint1};` : ''}
  padding-left: ${({ theme }) => theme.spacing.small};
  &:hover {
    ${({ isSelected, theme }) =>
      isSelected ? '' : `background-color: ${theme.colors.grey[5]};`}
  }
`;

export const VersionItemInfoSection = styled.div`
  display: flex;
  flex-direction: row;
  width: 10px;
  flex-grow: 1;
`;

export const VersionInfoSection = styled(VersionItemInfoSection)`
  padding-top: ${({ theme }) => theme.spacing.normal};
  padding-bottom: ${({ theme }) => theme.spacing.normal};
`;

export const AuthorAvatarContainer = styled.div`
  padding-right: ${({ theme }) => theme.spacing.normal};
`;

export const AuthorAvatar = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 50%;
`;

export const AuthorAvatarSpace = styled.div`
  width: 24px;
`;

export const VersionExpanderButton = styled(Button)`
  margin: 0 ${({ theme }) => theme.spacing.small};
`;

export const VersionSectionButton = styled.div`
  margin: 0 ${({ theme }) => theme.spacing.small};
  width: 24px;
  height: 24px;
  border-radius: 2px;
`;

export const VersionMetadata = styled.div`
  flex-grow: 1;
  flex-shrink: 0;
  width: 10px;
`;

export const VersionNameContainer = styled.div`
  position: relative;
  width: 100%;
`;

export const VersionAutoSaveNameContainer = styled(VersionNameContainer)`
  margin-top: ${({ theme }) => theme.spacing.small};
`;

export const VersionPickerVersionName = styled(SmallHighlight)`
  color: ${({ theme }) => theme.colors.text.primary};
  overflow-wrap: break-word;
  word-break: break-all;
  display: block;
  cursor: default;
`;

export const VersionName = styled(SmallHighlight)<{
  isWorkingVersion?: boolean;
  isAutoSaveVersionGroup?: boolean;
  isRenaming?: boolean;
}>`
  color: ${({ theme, isWorkingVersion, isAutoSaveVersionGroup }) =>
    isWorkingVersion || isAutoSaveVersionGroup
      ? theme.colors.text.secondary
      : theme.colors.text.primary};
  overflow-wrap: break-word;
  word-break: break-all;
  cursor: default;
  ${({ isRenaming }) => (isRenaming ? 'visibility: hidden;' : '')}
`;

// Position for input is not using atomic grid units
// because the purpose of the spacing is to align
// the text in the textbox with the text it replaces
// so the text doesn't appear to "shift"
// when entering and exiting the in-place renaming.
export const VersionNameInput = styled(Input)`
  position: absolute;
  right: 0;
  background-color: ${({ theme }) => theme.colors.grey[2]};
  z-index: 2;
  left: -3px;
`;

export const VersionNameInputTall = styled(VersionNameInput)`
  top: -${({ theme }) => theme.spacing.small};
`;

export const LatestTaggedVersionName = styled(ExtraSmall)`
  color: ${({ theme }) => theme.colors.text.tertiary};
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
  cursor: default;
`;

export const VersionAuthor = styled(ExtraSmall)`
  color: ${({ theme }) => theme.colors.text.tertiary};
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
  cursor: default;
`;

export const VersionDate = styled(ExtraSmall)`
  color: ${({ theme }) => theme.colors.text.tertiary};
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
  cursor: default;
`;

export const VersionAutoSaveDate = styled(Small)<{ isRenaming: boolean }>`
  padding-top: ${({ theme }) => theme.spacing.small};
  color: ${({ theme }) => theme.colors.text.primary};
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
  cursor: default;
  ${({ isRenaming }) => (isRenaming ? 'visibility: hidden;' : '')}
`;

export const VersionMenuContainer = styled.div`
  position: relative;
  width: ${({ theme }) => theme.spacing.xxlarge};
  padding-right: ${({ theme }) => theme.spacing.normal};
`;

export const ShowMoreButton = styled(Button)`
  margin: 0 60px;
`;
