/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const PythonFile: React.FC<Props> = (props) => (
  <svg viewBox="0 0 24 24" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.7072 5.6H6.56097L6.56097 18.4H16.7072V18C16.7072 18 18 17.75 18.2682 17.3683V18.4C18.2682 19.2837 17.5693 20 16.7072 20H6.56097C5.69887 20 5 19.2837 5 18.4V5.6C5 4.71634 5.69887 4 6.56097 4H16.7072C17.5693 4 18.2682 4.71634 18.2682 5.6V7.38875C18 7 16.7072 6.82606 16.7072 6.82606V5.6Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M15.9035 7.50008C15.4942 7.50197 15.1034 7.53656 14.7595 7.59687C13.7464 7.77426 13.5625 8.14556 13.5625 8.83028V9.73459H15.9565V10.036H12.6641C11.9683 10.036 11.3591 10.4505 11.1685 11.239C10.9487 12.1428 10.939 12.7068 11.1685 13.6505C11.3387 14.353 11.7451 14.8535 12.4409 14.8535H13.264V13.7694C13.264 12.9863 13.9476 12.2954 14.7595 12.2954H17.1507C17.8163 12.2954 18.3477 11.7522 18.3477 11.0897V8.83028C18.3477 8.18724 17.8003 7.70419 17.1507 7.59687C16.7394 7.52902 16.3127 7.4982 15.9035 7.50008ZM14.6088 8.2274C14.8561 8.2274 15.058 8.43082 15.058 8.68094C15.058 8.93018 14.8561 9.13172 14.6088 9.13172C14.3607 9.13172 14.1596 8.93018 14.1596 8.68094C14.1596 8.43082 14.3607 8.2274 14.6088 8.2274Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M18.6462 10.036V11.0897C18.6462 11.9066 17.9475 12.5941 17.1507 12.5941H14.7595C14.1045 12.5941 13.5625 13.1497 13.5625 13.7999V16.0593C13.5625 16.7023 14.1267 17.0805 14.7595 17.265C15.5173 17.4859 16.244 17.5258 17.1507 17.265C17.7534 17.0921 18.3477 16.744 18.3477 16.0593V15.1549H15.9565V14.8535H19.5446C20.2404 14.8535 20.4997 14.3725 20.7416 13.6505C20.9916 12.9073 20.9809 12.1925 20.7416 11.239C20.5697 10.5525 20.2413 10.036 19.5446 10.036H18.6462ZM17.3013 15.7578C17.5495 15.7578 17.7506 15.9594 17.7506 16.2086C17.7506 16.4587 17.5495 16.6621 17.3013 16.6621C17.0541 16.6621 16.8521 16.4587 16.8521 16.2086C16.8521 15.9594 17.0541 15.7578 17.3013 15.7578Z"
      fill={props.fill}
    />
    <path
      d="M8 8.5H12.45C12.45 8 12.75 7.5 12.75 7.5H8V8.5Z"
      fill={props.fill}
    />
    <path d="M8 11.5H10C10 11 10.3 10.5 10.3 10.5H8V11.5Z" fill={props.fill} />
    <path
      d="M8 14.5C8 14.5 10.3 14.4482 10.3 14.5C10 14 10 13.5 10 13.5H8V14.5Z"
      fill={props.fill}
    />
  </svg>
);

PythonFile.defaultProps = {
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default PythonFile;
