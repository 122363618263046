/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Stop: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.72161 18.666C8.99894 18.794 9.32161 18.858 9.68961 18.858C10.2336 18.858 10.6683 18.7167 10.9936 18.434C11.3243 18.146 11.4896 17.7593 11.4896 17.274C11.4896 17.002 11.4309 16.77 11.3136 16.578C11.1963 16.386 11.0496 16.2287 10.8736 16.106C10.7029 15.978 10.4629 15.8287 10.1536 15.658L9.98561 15.562C9.71361 15.4287 9.51094 15.322 9.37761 15.242C9.24428 15.1567 9.14561 15.0687 9.08161 14.978C9.01761 14.8873 8.98561 14.7807 8.98561 14.658C8.98561 14.482 9.03894 14.346 9.14561 14.25C9.25761 14.1487 9.40961 14.098 9.60161 14.098C9.83094 14.098 10.0123 14.162 10.1456 14.29C10.2789 14.418 10.3456 14.578 10.3456 14.77V14.914C10.3456 14.9407 10.3536 14.9647 10.3696 14.986C10.3909 15.002 10.4149 15.01 10.4416 15.01H11.3616C11.3883 15.01 11.4096 15.002 11.4256 14.986C11.4469 14.9647 11.4576 14.9407 11.4576 14.914V14.754C11.4576 14.434 11.3803 14.1513 11.2256 13.906C11.0709 13.6607 10.8549 13.4713 10.5776 13.338C10.3003 13.1993 9.98294 13.13 9.62561 13.13C9.28428 13.13 8.98028 13.194 8.71361 13.322C8.44694 13.4447 8.23894 13.6233 8.08961 13.858C7.94561 14.0873 7.87361 14.3567 7.87361 14.666C7.87361 14.9753 7.94561 15.2393 8.08961 15.458C8.23361 15.6767 8.40694 15.8553 8.60961 15.994C8.81228 16.1327 9.07361 16.282 9.39361 16.442C9.65494 16.5753 9.84694 16.682 9.96961 16.762C10.0976 16.8367 10.1963 16.9193 10.2656 17.01C10.3349 17.0953 10.3696 17.1993 10.3696 17.322C10.3696 17.4873 10.3136 17.6233 10.2016 17.73C10.0949 17.8367 9.93761 17.89 9.72961 17.89C9.49494 17.89 9.30828 17.8287 9.16961 17.706C9.03094 17.578 8.96161 17.4207 8.96161 17.234V17.082C8.96161 17.0553 8.95094 17.034 8.92961 17.018C8.91361 16.9967 8.89228 16.986 8.86561 16.986H7.94561C7.91894 16.986 7.89494 16.9967 7.87361 17.018C7.85761 17.034 7.84961 17.0553 7.84961 17.082V17.29C7.84961 17.5993 7.92428 17.874 8.07361 18.114C8.22828 18.3487 8.44428 18.5327 8.72161 18.666Z"
      fill={props.fill}
    />
    <path
      d="M15.6552 13.226C15.6392 13.2047 15.6178 13.194 15.5912 13.194H11.9912C11.9645 13.194 11.9405 13.2047 11.9192 13.226C11.9032 13.242 11.8952 13.2633 11.8952 13.29V14.074C11.8952 14.1007 11.9032 14.1247 11.9192 14.146C11.9405 14.162 11.9645 14.17 11.9912 14.17H13.1672C13.1938 14.17 13.2072 14.1833 13.2072 14.21V18.698C13.2072 18.7247 13.2152 18.7487 13.2312 18.77C13.2525 18.786 13.2765 18.794 13.3032 18.794H14.2392C14.2658 18.794 14.2872 18.786 14.3032 18.77C14.3245 18.7487 14.3352 18.7247 14.3352 18.698V14.21C14.3352 14.1833 14.3485 14.17 14.3752 14.17H15.5912C15.6178 14.17 15.6392 14.162 15.6552 14.146C15.6765 14.1247 15.6872 14.1007 15.6872 14.074V13.29C15.6872 13.2633 15.6765 13.242 15.6552 13.226Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M17.8946 18.866C17.5266 18.866 17.2013 18.7913 16.9186 18.642C16.6413 18.4927 16.4253 18.282 16.2706 18.01C16.1159 17.738 16.0386 17.4207 16.0386 17.058V14.93C16.0386 14.5727 16.1159 14.258 16.2706 13.986C16.4253 13.714 16.6413 13.5033 16.9186 13.354C17.2013 13.2047 17.5266 13.13 17.8946 13.13C18.2626 13.13 18.5879 13.2047 18.8706 13.354C19.1533 13.5033 19.3719 13.714 19.5266 13.986C19.6813 14.258 19.7586 14.5727 19.7586 14.93V17.058C19.7586 17.4207 19.6813 17.738 19.5266 18.01C19.3719 18.282 19.1533 18.4927 18.8706 18.642C18.5879 18.7913 18.2626 18.866 17.8946 18.866ZM17.8946 17.898C18.1186 17.898 18.2973 17.826 18.4306 17.682C18.5639 17.5327 18.6306 17.3407 18.6306 17.106V14.898C18.6306 14.658 18.5639 14.466 18.4306 14.322C18.2973 14.1727 18.1186 14.098 17.8946 14.098C17.6759 14.098 17.4999 14.1727 17.3666 14.322C17.2333 14.466 17.1666 14.658 17.1666 14.898V17.106C17.1666 17.3407 17.2333 17.5327 17.3666 17.682C17.4999 17.826 17.6759 17.898 17.8946 17.898Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M23.3656 13.402C23.1203 13.258 22.8376 13.186 22.5176 13.186H20.5816C20.5549 13.186 20.5309 13.1967 20.5096 13.218C20.4936 13.234 20.4856 13.2553 20.4856 13.282V18.698C20.4856 18.7247 20.4936 18.7487 20.5096 18.77C20.5309 18.786 20.5549 18.794 20.5816 18.794H21.5176C21.5443 18.794 21.5656 18.786 21.5816 18.77C21.6029 18.7487 21.6136 18.7247 21.6136 18.698V16.618C21.6136 16.5913 21.6269 16.578 21.6536 16.578H22.5176C22.8376 16.578 23.1203 16.5087 23.3656 16.37C23.6163 16.2313 23.8083 16.0367 23.9416 15.786C24.0803 15.53 24.1496 15.2367 24.1496 14.906C24.1496 14.57 24.0803 14.2713 23.9416 14.01C23.8083 13.7487 23.6163 13.546 23.3656 13.402ZM22.8296 15.482C22.7069 15.6153 22.5416 15.682 22.3336 15.682H21.6536C21.6269 15.682 21.6136 15.6687 21.6136 15.642V14.202C21.6136 14.1753 21.6269 14.162 21.6536 14.162H22.3336C22.5416 14.162 22.7069 14.2313 22.8296 14.37C22.9576 14.5087 23.0216 14.6953 23.0216 14.93C23.0216 15.1593 22.9576 15.3433 22.8296 15.482Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M20.3492 4C20.7471 4 21.1286 4.15804 21.4099 4.43934L27.5607 10.5901C27.842 10.8714 28 11.2529 28 11.6508V20.3492C28 20.7471 27.842 21.1286 27.5607 21.4099L21.4099 27.5607C21.1286 27.842 20.7471 28 20.3492 28H11.6508C11.2529 28 10.8714 27.842 10.5901 27.5607L4.43934 21.4099C4.15804 21.1286 4 20.7471 4 20.3492V11.6508C4 11.2529 4.15804 10.8714 4.43934 10.5901L10.5901 4.43934C10.8714 4.15804 11.2529 4 11.6508 4H20.3492ZM20.3492 5.5L26.5 11.6508V20.3492L20.3492 26.5H11.6508L5.5 20.3492L5.5 11.6508L11.6508 5.5H20.3492Z"
      fill={props.fill}
    />
  </svg>
);

Stop.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Stop;
