import { useUser } from '@clerk/clerk-react';
import { Coordinate } from 'app/common_types/Coordinate';
import { useAppSelector } from 'app/hooks';
import { WebSocketMessageType } from 'app/third_party_types/websocket/websocket-message-type';
import React from 'react';
import { useCustomPartySocket } from 'ui/common/PartySocketProvider';

let mto = -100;

export type MultiplayerMouseTy = {
  [userName: string]: Coordinate & { currentPath: string };
};

export const useMultiplayerMouses = () => {
  const { user } = useUser();

  const currentSubmodelPath = useAppSelector(
    (state) => state.model.present?.currentSubmodelPath,
  );

  const joinedSubmodelPath = React.useMemo(
    () => currentSubmodelPath.join('.'),
    [currentSubmodelPath],
  );

  const camera = useAppSelector((state) => state.camera);
  const cameraRef = React.useRef(camera);
  React.useEffect(() => {
    cameraRef.current = camera;
  }, [camera]);

  const [mousePoses, setMousePoses] = React.useState<MultiplayerMouseTy>({});

  const updateMousePosFor = React.useCallback(
    (name?: string, coord?: Coordinate, currentPath?: string) => {
      if (
        name &&
        coord &&
        coord.x !== undefined &&
        coord.y !== undefined &&
        currentPath !== undefined
      ) {
        setMousePoses((state) => ({
          ...state,
          [name]: {
            x: coord.x,
            y: coord.y,
            currentPath,
          },
        }));
      }
    },
    [],
  );

  const customPartySocket = useCustomPartySocket();
  React.useEffect(() => {
    customPartySocket.subscribe(
      WebSocketMessageType.BCAST_DOC_ACTION,
      'modeleditormouse',
      ({ payload }) => {
        const anyPayload = payload as any;
        updateMousePosFor(
          anyPayload?.name,
          anyPayload?.coord,
          anyPayload?.currentPath,
        );
      },
    );

    const handleMouseBroadcast = (e: MouseEvent) => {
      if (mto != -100) return;

      mto = window.setTimeout(() => {
        mto = -100;
      }, 32);

      if (customPartySocket.isOpen) {
        // NOTE: temporarily piggybacking on bcast_doc_action since it already exists and is currently unused
        const coord = {
          x: e.clientX / cameraRef.current.zoom - cameraRef.current.coord.x,
          y: e.clientY / cameraRef.current.zoom - cameraRef.current.coord.y,
        };
        customPartySocket.publish({
          id: 'modeleditormouse',
          type: WebSocketMessageType.BCAST_DOC_ACTION,
          payload: {
            action: 'mouseupdate',
            name: `${user?.firstName} ${user?.lastName}`,
            coord,
            currentPath: joinedSubmodelPath,
          },
        });
      }
    };
    document.addEventListener('mousemove', handleMouseBroadcast);

    return () => {
      document.removeEventListener('mousemove', handleMouseBroadcast);
      customPartySocket.unsubscribe(
        WebSocketMessageType.BCAST_DOC_ACTION,
        'modeleditormouse',
      );
    };
  }, [
    customPartySocket,
    updateMousePosFor,
    joinedSubmodelPath,
    user?.firstName,
    user?.lastName,
  ]);

  return mousePoses;
};
