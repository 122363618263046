import styled from '@emotion/styled';
import React from 'react';
import { Standard } from 'ui/common/typography/Typography';
import Link from './Link';

const NavSubSectionWrapper = styled(Link)`
  padding-left: 40px;
  color: ${({ theme }) => theme.colors.text.secondary};
  &.active {
    color: ${({ theme }) => theme.colors.text.primary};
    background-color: ${({ theme }) => theme.colors.ui.tint2};
  }
  &.root {
    border-left: 0;
  }
`;

const Name = styled(Standard)`
  font-weight: inherit;
  flex-grow: 1;
  overflow-wrap: break-word;
  word-break: break-all;
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

type Props = {
  children: React.ReactChild;
  to: string;
};

const NavSubSection: React.FC<Props> = ({ to, children }) => (
  <NavSubSectionWrapper to={to} data-test-id="sidebar-project-list-item">
    <Name>{children}</Name>
  </NavSubSectionWrapper>
);

export default NavSubSection;
