import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OptimizationPartialRequest } from 'app/api/custom_types/optimizations';
import { RootState } from 'app/store';
import {
  CellMetadata,
  CellRow,
  ImageCellMetadata,
  PlotCellMetadata,
  TextCellMetadata,
  TraceMetadata,
} from 'ui/dataExplorer/dataExplorerTypes';
import { CHAT_PREFS_V1_KEY, ChatPrefsV1 } from 'ui/userPreferences/chatPrefs';
import {
  defaultEnsembleSimPrefsV1,
  ENSEMBLE_SIM_PREFS_V1_KEY,
  EnsemblePrefUpdate,
  EnsembleSimPrefsV1,
  EnsembleTab,
} from 'ui/userPreferences/ensembleSimModelPrefs';
import { GLOBAL_ENTITY } from 'ui/userPreferences/globalEntity';
import {
  MODEL_EDITOR_LAYOUT_PREFS_V1_KEY,
  ModelEditorLayoutPrefsV1,
} from 'ui/userPreferences/modelEditorLayoutPrefs';
import {
  MODEL_EDITOR_MODEL_PREFS_V1_KEY,
  ModelEditorModelPrefsV1,
} from 'ui/userPreferences/modelEditorModelPrefs';
import {
  defaultOptimizerModalPrefs,
  OPTIMIZER_MODAL_PREFS_V1_KEY,
  TabName,
} from 'ui/userPreferences/optimizerModalPrefs';
import {
  PYTHON_SCRIPTBLOCK_PREFS_V1_KEY,
  PythonScriptblockPrefsV1,
} from 'ui/userPreferences/pythonScriptEditorPrefs';
import {
  TUTORIAL_PREFS_V1_KEY,
  TutorialPrefsV1,
} from 'ui/userPreferences/tutorialPrefs';
import {
  VISUALIZER_PREFS_V1_KEY,
  VisualizerPrefsV1,
} from 'ui/userPreferences/visualizerPrefs';
import { updateVisualizerPrefs } from 'util/updateVisualizerPrefs';

export type EntityPrefsMapType = {
  [MODEL_EDITOR_LAYOUT_PREFS_V1_KEY]: ModelEditorLayoutPrefsV1;
  [MODEL_EDITOR_MODEL_PREFS_V1_KEY]: ModelEditorModelPrefsV1;
  [TUTORIAL_PREFS_V1_KEY]: TutorialPrefsV1;
  [VISUALIZER_PREFS_V1_KEY]: VisualizerPrefsV1;
  [PYTHON_SCRIPTBLOCK_PREFS_V1_KEY]: PythonScriptblockPrefsV1;
  [CHAT_PREFS_V1_KEY]: ChatPrefsV1;
  [OPTIMIZER_MODAL_PREFS_V1_KEY]: Record<string, any>;
  [ENSEMBLE_SIM_PREFS_V1_KEY]: EnsembleSimPrefsV1;
};

export type EntityPrefsKey = keyof EntityPrefsMapType;

export interface EntityPreferencesState {
  prefKeyToEntityIdToPrefs: {
    [Key in EntityPrefsKey]: { [entKey: string]: EntityPrefsMapType[Key] };
  };
  prefKeyToEntityIdToPrefsToSave: {
    [Key in EntityPrefsKey]: { [entKey: string]: boolean };
  };
}

export const initialState: EntityPreferencesState = {
  prefKeyToEntityIdToPrefs: {
    [MODEL_EDITOR_LAYOUT_PREFS_V1_KEY]: {},
    [MODEL_EDITOR_MODEL_PREFS_V1_KEY]: {},
    [TUTORIAL_PREFS_V1_KEY]: {},
    [VISUALIZER_PREFS_V1_KEY]: {},
    [PYTHON_SCRIPTBLOCK_PREFS_V1_KEY]: {},
    [CHAT_PREFS_V1_KEY]: {},
    [OPTIMIZER_MODAL_PREFS_V1_KEY]: {},
    [ENSEMBLE_SIM_PREFS_V1_KEY]: {},
  },
  prefKeyToEntityIdToPrefsToSave: {
    [MODEL_EDITOR_LAYOUT_PREFS_V1_KEY]: {},
    [MODEL_EDITOR_MODEL_PREFS_V1_KEY]: {},
    [TUTORIAL_PREFS_V1_KEY]: {},
    [VISUALIZER_PREFS_V1_KEY]: {},
    [PYTHON_SCRIPTBLOCK_PREFS_V1_KEY]: {},
    [CHAT_PREFS_V1_KEY]: {},
    [OPTIMIZER_MODAL_PREFS_V1_KEY]: {},
    [ENSEMBLE_SIM_PREFS_V1_KEY]: {},
  },
};

function clearPreferenceToSave(
  state: EntityPreferencesState,
  preferencesKey: EntityPrefsKey,
  entityId: string,
) {
  if (state.prefKeyToEntityIdToPrefsToSave[preferencesKey]) {
    // NOTE: disabling the next line because the non-null assertion is just compensating for TSC's dissatisfaction with the check above
    // eslint-disable-next-line
    delete state.prefKeyToEntityIdToPrefsToSave[preferencesKey]![entityId];
  }
}

function markPrefsSetByUser(
  state: EntityPreferencesState,
  details: {
    preferencesKey: EntityPrefsKey;
    entityId: string;
  },
) {
  const { preferencesKey, entityId } = details;
  if (!preferencesKey || !entityId) {
    return;
  }
  state.prefKeyToEntityIdToPrefsToSave[preferencesKey][entityId] = true;
}

const entityPreferencesSlice = createSlice({
  name: 'entityPreferencesSlice',
  initialState,
  reducers: {
    // Do not use the internal redux actions yourself. Those are for the hook.
    // useEntityPreferences already handles data flow between react, redux, and backend for you.
    // Add your entity preference specific fns to the reducer here instead.
    _setPrefsFromServer<T extends EntityPrefsKey>(
      state: EntityPreferencesState,
      action: PayloadAction<{
        preferencesKey: T;
        entityId: string | undefined;
        prefs: EntityPrefsMapType[T];
      }>,
    ) {
      const { preferencesKey, entityId, prefs } = action.payload;
      if (!preferencesKey || !entityId) {
        return;
      }
      if (!prefs) {
        console.error(
          'Preferences from server should never be undefined or null. If unset in backend, set as empty object to differentiate from not loaded.',
        );
        return;
      }

      (
        state.prefKeyToEntityIdToPrefs[preferencesKey] as {
          [entIdKey: string]: EntityPrefsMapType[T];
        }
      )[entityId] = prefs;

      // Since we are updating to the server value, make sure we don't overwrite the
      // server values with outdated local values.
      clearPreferenceToSave(state, preferencesKey, entityId);
    },
    _onPrefsUpdateSentToServer(
      state,
      action: PayloadAction<{
        preferencesKey: EntityPrefsKey;
        entityId: string;
      }>,
    ) {
      const { preferencesKey, entityId } = action.payload;
      clearPreferenceToSave(state, preferencesKey, entityId);
    },
    onUserChangedBottomPanelHeight(
      state,
      action: PayloadAction<{ height: number }>,
    ) {
      const { height } = action.payload;
      if (!height) {
        return;
      }
      (
        state.prefKeyToEntityIdToPrefs[MODEL_EDITOR_LAYOUT_PREFS_V1_KEY][
          GLOBAL_ENTITY
        ] as ModelEditorLayoutPrefsV1
      ).bottomPanelHeight = height;

      markPrefsSetByUser(state, {
        preferencesKey: MODEL_EDITOR_LAYOUT_PREFS_V1_KEY,
        entityId: GLOBAL_ENTITY,
      });
    },
    onUserChangedVisualizer(
      state,
      action: PayloadAction<{
        modelId: string;
        cellRowIds: string[];
        idToCellRow: Record<string, CellRow>;
        idToCell: Record<string, CellMetadata>;
        idToPlotCell: Record<string, PlotCellMetadata>;
        idToTextCell: Record<string, TextCellMetadata>;
        idToImageCell: Record<string, ImageCellMetadata>;
        idToTrace: Record<string, TraceMetadata>;
      }>,
    ) {
      const { modelId } = action.payload;
      if (!modelId) {
        return;
      }

      const visualizerPrefs = <VisualizerPrefsV1>(
        state.prefKeyToEntityIdToPrefs[VISUALIZER_PREFS_V1_KEY][modelId]
      );

      // Update the prefs based on all the options.
      // FIXME: added if: https://collimator.atlassian.net/browse/UI-1172
      if (visualizerPrefs) {
        updateVisualizerPrefs(visualizerPrefs, action);
      }

      markPrefsSetByUser(state, {
        preferencesKey: VISUALIZER_PREFS_V1_KEY,
        entityId: modelId,
      });
    },
    onUserSawBasicTutorial(state) {
      const baseTutorialPrefs =
        state.prefKeyToEntityIdToPrefs[TUTORIAL_PREFS_V1_KEY];

      if (!baseTutorialPrefs[GLOBAL_ENTITY]) {
        (baseTutorialPrefs[GLOBAL_ENTITY] as TutorialPrefsV1) = {
          seenBasicSimTutorial: true,
        };
      } else {
        (
          baseTutorialPrefs[GLOBAL_ENTITY] as TutorialPrefsV1
        ).seenBasicSimTutorial = true;
      }

      markPrefsSetByUser(state, {
        preferencesKey: TUTORIAL_PREFS_V1_KEY,
        entityId: GLOBAL_ENTITY,
      });
    },
    onUserSawInitialTutorial(state) {
      const baseTutorialPrefs =
        state.prefKeyToEntityIdToPrefs[TUTORIAL_PREFS_V1_KEY];

      if (!baseTutorialPrefs[GLOBAL_ENTITY]) {
        (baseTutorialPrefs[GLOBAL_ENTITY] as TutorialPrefsV1) = {
          seenInitialSimTutorial: true,
        };
      } else {
        (
          baseTutorialPrefs[GLOBAL_ENTITY] as TutorialPrefsV1
        ).seenInitialSimTutorial = true;
      }

      markPrefsSetByUser(state, {
        preferencesKey: TUTORIAL_PREFS_V1_KEY,
        entityId: GLOBAL_ENTITY,
      });
    },
    onChangePythonScriptPanelState(
      state,
      action: PayloadAction<{
        scriptBlockUuid: string;
        panelParamKey: string;
        closed: boolean;
      }>,
    ) {
      const { scriptBlockUuid, panelParamKey, closed } = action.payload;

      const basePythonScriptPrefs =
        state.prefKeyToEntityIdToPrefs[PYTHON_SCRIPTBLOCK_PREFS_V1_KEY];

      if (
        !basePythonScriptPrefs[scriptBlockUuid] ||
        (!basePythonScriptPrefs[scriptBlockUuid].panelClosedStatesByParamName &&
          closed === true)
      ) {
        basePythonScriptPrefs[scriptBlockUuid] = {
          panelClosedStatesByParamName: {
            [panelParamKey]: closed,
          },
        };
      } else if (basePythonScriptPrefs[scriptBlockUuid]) {
        basePythonScriptPrefs[scriptBlockUuid].panelClosedStatesByParamName[
          panelParamKey
        ] = closed;
      }

      markPrefsSetByUser(state, {
        preferencesKey: PYTHON_SCRIPTBLOCK_PREFS_V1_KEY,
        entityId: scriptBlockUuid,
      });
    },
    onUserChangedChatOptions(
      state,
      action: PayloadAction<{
        modelId: string;
        seed?: number;
        temperature: number;
        aiModelId: string;
        useModelBuilderWithAcausal?: boolean;
      }>,
    ) {
      const { modelId } = action.payload;
      if (!modelId) {
        return;
      }

      const chatPrefs = <ChatPrefsV1>(
        state.prefKeyToEntityIdToPrefs[CHAT_PREFS_V1_KEY][modelId]
      );

      if (chatPrefs) {
        chatPrefs.seed = action.payload.seed;
        chatPrefs.temperature = action.payload.temperature;
        chatPrefs.aiModelId = action.payload.aiModelId;
        chatPrefs.useModelBuilderWithAcausal =
          action.payload.useModelBuilderWithAcausal;
      }

      markPrefsSetByUser(state, {
        preferencesKey: CHAT_PREFS_V1_KEY,
        entityId: modelId,
      });
    },

    onUserUpdatedOptimizerModalPrefs(
      state,
      action: PayloadAction<{
        modelId: string;
        selectedTab: TabName;
        prefs: OptimizationPartialRequest;
      }>,
    ) {
      const { modelId, selectedTab, prefs } = action.payload;

      if (!modelId) {
        return;
      }

      state.prefKeyToEntityIdToPrefs[OPTIMIZER_MODAL_PREFS_V1_KEY][modelId] = {
        ...defaultOptimizerModalPrefs,
        ...state.prefKeyToEntityIdToPrefs[OPTIMIZER_MODAL_PREFS_V1_KEY][
          modelId
        ],
        selectedTab,
        [selectedTab]: prefs,
      };

      markPrefsSetByUser(state, {
        preferencesKey: OPTIMIZER_MODAL_PREFS_V1_KEY,
        entityId: modelId,
      });
    },

    // Update prefs for the given tab
    onUserUpdatedEnsembleSimModalPrefs(
      state,
      action: PayloadAction<{
        modelId: string;
        tab: EnsembleTab;
        prefs: EnsemblePrefUpdate;
      }>,
    ) {
      const { modelId, tab, prefs } = action.payload;

      if (!modelId) {
        return;
      }

      let currentConfig =
        state.prefKeyToEntityIdToPrefs[ENSEMBLE_SIM_PREFS_V1_KEY][modelId];

      state.prefKeyToEntityIdToPrefs[ENSEMBLE_SIM_PREFS_V1_KEY][modelId] = {
        ...defaultEnsembleSimPrefsV1,
        ...currentConfig,
        [tab]: {
          ...currentConfig[tab],
          ...prefs,
        },
      };

      markPrefsSetByUser(state, {
        preferencesKey: ENSEMBLE_SIM_PREFS_V1_KEY,
        entityId: modelId,
      });
    },

    // Generic API to update entity preferences
    onUserUpdatedPrefs(
      state,
      action: PayloadAction<{
        preferencesKey: EntityPrefsKey;
        entityId: string;
        prefs: EntityPrefsMapType[EntityPrefsKey];
      }>,
    ) {
      const { preferencesKey, entityId, prefs } = action.payload;
      if (!preferencesKey || !entityId) {
        return;
      }

      if (!state.prefKeyToEntityIdToPrefs[preferencesKey]) {
        state.prefKeyToEntityIdToPrefs[preferencesKey] = {};
      }

      state.prefKeyToEntityIdToPrefs[preferencesKey][entityId] = prefs;
      markPrefsSetByUser(state, { preferencesKey, entityId });
    },
  },
});

export const entityPreferencesActions = entityPreferencesSlice.actions;

export const selectEntityPrefs: ReturnType<typeof createSelector> =
  createSelector(
    [
      (state: RootState) => state.entityPreferences.prefKeyToEntityIdToPrefs,
      (state, preferencesKey: EntityPrefsKey, entityId) => preferencesKey,
      (state, preferencesKey, entityId: string | undefined) => entityId,
    ],
    (prefKeyToEntityIdToPrefs, preferencesKey, entityId) => {
      if (!preferencesKey) {
        console.error('You must provide a non empty preferencesKey.');
        return null;
      }
      if (!entityId) {
        // not initialized
        // component does not have necessary the state to fetch the pref values
        return null;
      }

      const prefs = prefKeyToEntityIdToPrefs[preferencesKey];
      if (prefs) {
        return prefs[entityId];
      }
      // not intialized
      // server fetch has not finished yet, hence the missing entry in the store
      return null;
    },
  );

export const _selectEntityPrefNeedsSaving: ReturnType<typeof createSelector> =
  createSelector(
    [
      (state: RootState) =>
        state.entityPreferences.prefKeyToEntityIdToPrefsToSave,
      (state, preferencesKey: EntityPrefsKey, entityId) => preferencesKey,
      (state, preferencesKey, entityId: string | undefined) => entityId,
    ],
    (prefKeyToEntityIdToPrefsToSave, preferencesKey, entityId) => {
      if (!preferencesKey || !entityId) {
        return false;
      }

      const prefs = prefKeyToEntityIdToPrefsToSave[preferencesKey];
      if (prefs) {
        return prefs[entityId];
      }
      return false;
    },
  );

export default entityPreferencesSlice;
