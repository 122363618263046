import styled from '@emotion/styled/macro';
import React from 'react';

interface Props {
  children: React.ReactNode;
  testId?: string;
}

const Wrapper = styled.label`
  display: block;
  font-size: ${(props) => props.theme.typography.font.base.size};
  line-height: ${(props) => props.theme.typography.font.base.lineHeight};
  margin-bottom: ${(props) => props.theme.spacing.normal};
  color: ${(props) => props.theme.colors.text.tertiary};
`;

const Label: React.FC<Props> = ({ children, testId }) => (
  <Wrapper data-test-id={testId}>{children}</Wrapper>
);

export default Label;
