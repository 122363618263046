/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const PidPi: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1 4.75C1.13807 4.75 1.25 4.86193 1.25 5V25.7927C1.32819 25.765 1.41234 25.75 1.5 25.75H2.57788C2.71708 25.4511 2.86031 24.996 2.99948 24.3765C3.16499 23.6398 3.31087 22.7377 3.44763 21.7211C3.61134 20.5042 3.7572 19.1634 3.90802 17.777C4.01102 16.8302 4.11634 15.8621 4.23123 14.8978C4.51033 12.5551 4.8441 10.2545 5.33462 8.53013C5.57851 7.67276 5.87656 6.90008 6.26195 6.3266C6.64488 5.75677 7.2112 5.25 8 5.25C8.87957 5.25 9.55887 5.65532 10.088 6.16078C10.5934 6.64349 11.0143 7.26934 11.3792 7.81182L11.3924 7.83142C11.7828 8.41183 12.1148 8.90216 12.4822 9.25849C12.8323 9.59808 13.1485 9.75 13.5 9.75C14.4354 9.75 15.3107 9.43063 16.302 9.04987L16.414 9.00681C17.3314 8.65371 18.3803 8.25 19.5 8.25C20.0499 8.25 20.8374 8.24849 21.7608 8.24671C24.1858 8.24205 27.5481 8.23559 30.0044 8.25001C30.4186 8.25245 30.7524 8.5902 30.75 9.0044C30.7476 9.41861 30.4098 9.75242 29.9956 9.74999C27.5457 9.7356 24.197 9.74204 21.7728 9.7467C20.8463 9.74848 20.0546 9.75 19.5 9.75C18.6655 9.75 17.8489 10.0626 16.8398 10.4501C15.8785 10.8194 14.7537 11.25 13.5 11.25C12.6282 11.25 11.9577 10.8394 11.4379 10.3353C10.9503 9.86239 10.5392 9.25092 10.1807 8.71757L10.1477 8.66858C9.75892 8.09054 9.42387 7.60069 9.05198 7.24547C8.69781 6.90718 8.37043 6.75 8 6.75C7.93359 6.75 7.76411 6.78057 7.50694 7.16325C7.25223 7.54228 7.00589 8.13726 6.77738 8.94055C6.32311 10.5375 6.00007 12.7302 5.7207 15.0752C5.61014 16.0033 5.50605 16.959 5.40321 17.9035C5.24992 19.3111 5.09937 20.6936 4.93423 21.9211C4.79527 22.9542 4.64259 23.906 4.463 24.7053C4.28758 25.4861 4.07165 26.1933 3.77686 26.6898C3.76456 26.7105 3.7518 26.7306 3.73862 26.75H31C31.1381 26.75 31.25 26.8619 31.25 27C31.25 27.1381 31.1381 27.25 31 27.25H0.75V5C0.75 4.86193 0.861929 4.75 1 4.75Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M18.6093 15.588C19.1133 15.588 19.5573 15.692 19.9413 15.9C20.3253 16.108 20.6213 16.404 20.8293 16.788C21.0453 17.164 21.1533 17.6 21.1533 18.096C21.1533 18.584 21.0453 19.016 20.8293 19.392C20.6133 19.768 20.3053 20.06 19.9053 20.268C19.5133 20.468 19.0573 20.568 18.5373 20.568H16.6173C16.5773 20.568 16.5573 20.588 16.5573 20.628V23.856C16.5573 23.896 16.5413 23.932 16.5093 23.964C16.4853 23.988 16.4533 24 16.4133 24H15.3093C15.2693 24 15.2333 23.988 15.2013 23.964C15.1773 23.932 15.1653 23.896 15.1653 23.856V15.732C15.1653 15.692 15.1773 15.66 15.2013 15.636C15.2333 15.604 15.2693 15.588 15.3093 15.588H18.6093ZM18.4053 19.44C18.8133 19.44 19.1413 19.32 19.3893 19.08C19.6373 18.84 19.7613 18.524 19.7613 18.132C19.7613 17.732 19.6373 17.408 19.3893 17.16C19.1413 16.912 18.8133 16.788 18.4053 16.788H16.6173C16.5773 16.788 16.5573 16.808 16.5573 16.848V19.38C16.5573 19.42 16.5773 19.44 16.6173 19.44H18.4053Z"
      fill={props.fill}
    />
    <path
      d="M22.518 24C22.478 24 22.442 23.988 22.41 23.964C22.386 23.932 22.374 23.896 22.374 23.856V15.744C22.374 15.704 22.386 15.672 22.41 15.648C22.442 15.616 22.478 15.6 22.518 15.6H23.622C23.662 15.6 23.694 15.616 23.718 15.648C23.75 15.672 23.766 15.704 23.766 15.744V23.856C23.766 23.896 23.75 23.932 23.718 23.964C23.694 23.988 23.662 24 23.622 24H22.518Z"
      fill={props.fill}
    />
  </svg>
);

PidPi.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default PidPi;
