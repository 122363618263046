/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Interface: React.FC<Props> = (props) => (
  <svg viewBox="0 0 24 24" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M6 4C4.89543 4 4 4.89543 4 6V18C4 19.1046 4.89543 20 6 20H18C19.1046 20 20 19.1046 20 18V6C20 4.89543 19.1046 4 18 4H6ZM18 5.5H6C5.72386 5.5 5.5 5.72386 5.5 6V8.75H14.1893L13.4697 8.03033C13.1768 7.73744 13.1768 7.26256 13.4697 6.96967C13.7626 6.67678 14.2374 6.67678 14.5303 6.96967L17.0607 9.5L14.5303 12.0303C14.2374 12.3232 13.7626 12.3232 13.4697 12.0303C13.1768 11.7374 13.1768 11.2626 13.4697 10.9697L14.1893 10.25H5.5V18C5.5 18.2761 5.72386 18.5 6 18.5H18C18.2761 18.5 18.5 18.2761 18.5 18V15.25H9.81066L10.5303 15.9697C10.8232 16.2626 10.8232 16.7374 10.5303 17.0303C10.2374 17.3232 9.76256 17.3232 9.46967 17.0303L6.93934 14.5L9.46967 11.9697C9.76256 11.6768 10.2374 11.6768 10.5303 11.9697C10.8232 12.2626 10.8232 12.7374 10.5303 13.0303L9.81066 13.75H18.5V6C18.5 5.72386 18.2761 5.5 18 5.5Z"
      fill={props.fill}
    />
  </svg>
);

Interface.defaultProps = {
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Interface;
