/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const History: React.FC<Props> = (props) => (
  <svg viewBox="0 0 24 24" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M14.5927 7.14945C13.4705 6.54962 12.175 6.35745 10.927 6.60569C9.679 6.85393 8.55568 7.52723 7.74844 8.51085C6.9412 9.49447 6.49999 10.7276 6.49999 12H4.99999C4.99999 10.3805 5.56153 8.81114 6.58892 7.55926C7.61632 6.30737 9.046 5.45046 10.6344 5.13451C12.2227 4.81857 13.8715 5.06314 15.2998 5.82657C16.728 6.58999 17.8474 7.82503 18.4672 9.32124C19.0869 10.8175 19.1687 12.4823 18.6986 14.032C18.2285 15.5818 17.2355 16.9206 15.889 17.8203C14.5424 18.72 12.9255 19.125 11.3139 18.9663C9.95202 18.8322 8.66508 18.3025 7.60793 17.4507C7.2854 17.1908 7.28767 16.7123 7.58056 16.4194C7.87346 16.1265 8.34571 16.1313 8.6757 16.3817C9.48151 16.9931 10.4447 17.3734 11.4609 17.4735C12.7272 17.5983 13.9976 17.28 15.0556 16.5731C16.1136 15.8662 16.8938 14.8143 17.2632 13.5966C17.6325 12.3789 17.5683 11.0709 17.0813 9.89526C16.5944 8.71967 15.7149 7.74928 14.5927 7.14945Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M3.46965 10.2374C3.76255 9.94455 4.23742 9.94455 4.53031 10.2374L5.76775 11.4749L7.00519 10.2374C7.29808 9.94455 7.77296 9.94455 8.06585 10.2374C8.35874 10.5303 8.35874 11.0052 8.06585 11.2981L5.76775 13.5962L3.46965 11.2981C3.17676 11.0052 3.17676 10.5303 3.46965 10.2374Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M12 7.75002C12.4142 7.75002 12.75 8.0858 12.75 8.50002V11.25H14.5C14.9142 11.25 15.25 11.5858 15.25 12C15.25 12.4142 14.9142 12.75 14.5 12.75H12.5C11.8096 12.75 11.25 12.1904 11.25 11.5V8.50002C11.25 8.0858 11.5858 7.75002 12 7.75002Z"
      fill={props.fill}
    />
  </svg>
);

History.defaultProps = {
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default History;
