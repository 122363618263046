/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const CreateReplicator: React.FC<Props> = (props) => (
  <svg viewBox="0 0 24 24" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6 4.25C5.0335 4.25 4.25 5.0335 4.25 6V17C4.25 17.9665 5.0335 18.75 6 18.75H13C13.4142 18.75 13.75 18.4142 13.75 18C13.75 17.5858 13.4142 17.25 13 17.25H6C5.86193 17.25 5.75 17.1381 5.75 17V6C5.75 5.86193 5.86193 5.75 6 5.75H17C17.1381 5.75 17.25 5.86193 17.25 6V13C17.25 13.4142 17.5858 13.75 18 13.75C18.4142 13.75 18.75 13.4142 18.75 13V6C18.75 5.0335 17.9665 4.25 17 4.25H6Z"
      fill={props.fill}
    />
    <path
      d="M18.75 16C18.75 15.5858 18.4142 15.25 18 15.25C17.5858 15.25 17.25 15.5858 17.25 16V17.25H16C15.5858 17.25 15.25 17.5858 15.25 18C15.25 18.4142 15.5858 18.75 16 18.75H17.25V20C17.25 20.4142 17.5858 20.75 18 20.75C18.4142 20.75 18.75 20.4142 18.75 20V18.75H20C20.4142 18.75 20.75 18.4142 20.75 18C20.75 17.5858 20.4142 17.25 20 17.25H18.75V16Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M9.24999 13H10.5V14.75C10.5 15.0261 10.7238 15.25 11 15.25H15.25C15.5261 15.25 15.75 15.0261 15.75 14.75V11.25C15.75 10.9739 15.5261 10.75 15.25 10.75H13.25V9.5C13.25 9.22386 13.0261 9 12.75 9H11V8C11 7.72386 10.7761 7.5 10.5 7.5H7.74999C7.47385 7.5 7.24999 7.72386 7.24999 8V10.25C7.24999 10.5261 7.47385 10.75 7.74999 10.75H8.74999V12.5C8.74999 12.7761 8.97385 13 9.24999 13ZM11.5 11.75H14.75V14.25H11.5V11.75ZM10.5 11.25C10.5 10.9739 10.7238 10.75 11 10.75H12.25V10H9.74999V12H10.5V11.25ZM8.74999 9.75V9.5C8.74999 9.22386 8.97385 9 9.24999 9H9.99999V8.5H8.24999V9.75H8.74999Z"
      fill={props.fill}
    />
  </svg>
);

CreateReplicator.defaultProps = {
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default CreateReplicator;
