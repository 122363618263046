/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Settings: React.FC<Props> = (props) => (
  <svg viewBox="0 0 24 24" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M8.75 5.5C8.75 5.08579 8.41421 4.75 8 4.75C7.58579 4.75 7.25 5.08579 7.25 5.5V6.5C7.25 6.53151 7.25194 6.56256 7.25572 6.59305C5.9589 6.92409 5 8.10006 5 9.5C5 10.8999 5.9589 12.0759 7.25572 12.407C7.25194 12.4374 7.25 12.4685 7.25 12.5V18.5C7.25 18.9142 7.58579 19.25 8 19.25C8.41421 19.25 8.75 18.9142 8.75 18.5V12.5C8.75 12.4685 8.74806 12.4374 8.74428 12.407C10.0411 12.0759 11 10.8999 11 9.5C11 8.10006 10.0411 6.92409 8.74428 6.59305C8.74806 6.56256 8.75 6.53151 8.75 6.5V5.5ZM9.5 9.5C9.5 10.3284 8.82843 11 8 11C7.17157 11 6.5 10.3284 6.5 9.5C6.5 8.67157 7.17157 8 8 8C8.82843 8 9.5 8.67157 9.5 9.5Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M19 14.5C19 15.8999 18.0411 17.0759 16.7443 17.407C16.7481 17.4374 16.75 17.4685 16.75 17.5V18.5C16.75 18.9142 16.4142 19.25 16 19.25C15.5858 19.25 15.25 18.9142 15.25 18.5V17.5C15.25 17.4685 15.2519 17.4374 15.2557 17.407C13.9589 17.0759 13 15.8999 13 14.5C13 13.1001 13.9589 11.9241 15.2557 11.593C15.2519 11.5626 15.25 11.5315 15.25 11.5V5.5C15.25 5.08579 15.5858 4.75 16 4.75C16.4142 4.75 16.75 5.08579 16.75 5.5V11.5C16.75 11.5315 16.7481 11.5626 16.7443 11.593C18.0411 11.9241 19 13.1001 19 14.5ZM17.5 14.5C17.5 15.3284 16.8284 16 16 16C15.1716 16 14.5 15.3284 14.5 14.5C14.5 13.6716 15.1716 13 16 13C16.8284 13 17.5 13.6716 17.5 14.5Z"
      fill={props.fill}
    />
  </svg>
);

Settings.defaultProps = {
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Settings;
