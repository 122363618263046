import { t } from '@lingui/macro';
import { useProjectActions } from 'app/api/useProjectActions';
import { FileSummary } from 'app/apiGenerated/generatedApiTypes';
import React from 'react';
import Button from 'ui/common/Button/Button';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import Input from 'ui/common/Input/Input';
import Label from 'ui/common/Label';
import {
  ActionButtonContainer,
  ModalInputGroup,
  SmallFormContainer,
} from 'ui/common/Modal/Modal';
import { useModal } from 'ui/common/Modal/useModal';

interface Props {
  projectId: string;
  onCreated: (fileUuid: string) => void;
}

const pythonizeFilename = (filename: string): string => {
  const splitFn = filename.split('.');

  if (splitFn.length > 1 && splitFn[splitFn.length - 1] === 'py') {
    return filename;
  }

  if (splitFn.length > 1) {
    splitFn.pop();
  }

  splitFn.push('py');
  return splitFn.join('.');
};

export const CreateScriptModal: React.FC<Props> = ({
  projectId,
  onCreated,
}) => {
  const [filename, setFilename] = React.useState('');
  const trimmedFilename = filename.trim();

  const [submitting, setSubmitting] = React.useState(false);
  const { closeModal } = useModal();

  const { createFile } = useProjectActions();
  const queryParams = new URLSearchParams(window.location.search);
  const path = queryParams.get('path') ?? '';

  const onCreateScript = async () => {
    if (trimmedFilename.length === 0) {
      return;
    }

    const finalFilename = pythonizeFilename(trimmedFilename);

    const file = new File([''], finalFilename, { type: 'text/x-python' });
    setSubmitting(true);

    await createFile(
      {
        projectUuid: projectId,
        fileCreateRequest: {
          name: path ? `${path}/${file.name}` : file.name,
          content_type: file.type,
          size: file.size,
        },
      },
      file,
    ).then((fileSummary) => {
      if (fileSummary) {
        const newFileUuid = (fileSummary as FileSummary).uuid;
        onCreated(newFileUuid);
      }
    });

    closeModal();
  };

  return (
    <SmallFormContainer
      onSubmit={(e) => {
        e?.preventDefault();
        onCreateScript();
      }}>
      <ModalInputGroup>
        <Label>
          {t({
            id: 'createScriptModal.filenameInput.label',
            message: 'Filename',
          })}
        </Label>
        <Input
          hasBorder
          autoFocus
          onChangeText={(v) => {
            setFilename(v);
          }}
          value={filename}
        />
      </ModalInputGroup>
      <ActionButtonContainer>
        {/* Cancel button */}
        <Button
          type="button"
          onClick={closeModal}
          variant={ButtonVariants.LargeSecondary}>
          {t({
            id: 'createScriptModal.cancelButton.label',
            message: 'Cancel',
          })}
        </Button>

        {/* Create button */}
        <Button
          type="submit"
          variant={ButtonVariants.LargePrimary}
          loading={submitting}
          disabled={trimmedFilename.length === 0}>
          {t({
            id: 'createScriptModal.createButton.label',
            message: 'Create',
          })}
        </Button>
      </ActionButtonContainer>
    </SmallFormContainer>
  );
};
