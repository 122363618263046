import {
  ParameterDefinition,
  ParameterDefinitions,
} from './schemas/ParameterDefinitions';
import {
  BlockClassName,
  BlockInstance,
  LinkInstance,
  LinkSegmentType,
  LinkTypeType,
  ModelConfiguration,
  ModelDiagram,
  NodeInstance,
  Parameter,
  Parameters,
  Port,
  SimulationModel,
  StateMachineDiagram,
  StateMachines,
  SubmodelInstance,
  SubmodelsSection,
  WorkspaceConfig,
} from './schemas/SimulationModel';

interface ParameterChange {
  parameterId: string;
  value: any;
}

export {
  BlockClassName,
  BlockInstance,
  LinkInstance,
  LinkSegmentType,
  LinkTypeType,
  ModelConfiguration,
  ModelDiagram,
  NodeInstance,
  Parameter,
  ParameterChange,
  ParameterDefinition,
  ParameterDefinitions,
  Parameters,
  Port,
  SimulationModel,
  StateMachineDiagram,
  StateMachines,
  SubmodelInstance,
  SubmodelsSection,
  WorkspaceConfig,
};

export type SupportedVersion = '0' | '1' | '2' | '3' | '4';
export const SupportedVersionsList: SupportedVersion[] = [
  '0',
  '1',
  '2',
  '3',
  '4',
];

// This one indicates the actual latest version (for wildcat)
export const LatestVersion: SupportedVersion = '4';

export interface VersionedModel {
  schema_version: SupportedVersion;
}
