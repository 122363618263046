import { useCreateReferenceSubmodel } from 'app/behaviorHooks/useCreateReferenceSubmodel';
import { SubmodelsSection } from 'app/generated_types/SimulationModel';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { modelActions } from 'app/slices/modelSlice';
import { submodelsActions } from 'app/slices/submodelsSlice';
import { findSubmodelsForNewReferenceSubmodel } from 'app/utils/insertNodeUtils';
import React from 'react';

export const UpdateSubmodelDispatcher: React.FC = () => {
  const dispatch = useAppDispatch();

  const { createReferenceSubmodel } = useCreateReferenceSubmodel();

  const submodelToPublish = useAppSelector(
    (state) => state.submodels.submodelToPublish,
  );
  const hostingDiagramSubmodelSubmodels = useAppSelector(
    (state) => state.model.present.submodels,
  );

  React.useEffect(() => {
    if (
      !submodelToPublish ||
      !hostingDiagramSubmodelSubmodels ||
      !hostingDiagramSubmodelSubmodels.references ||
      !hostingDiagramSubmodelSubmodels.diagrams
    ) {
      return;
    }

    // If the diagram has been successfully created to represent the submodel,
    // send a create request to the backend to publish the submodel.
    if (!submodelToPublish.requestSent) {
      const reference =
        hostingDiagramSubmodelSubmodels.references[
          submodelToPublish.submodelInstanceId
        ];
      if (!reference || !reference.diagram_uuid) {
        return;
      }

      const submodelDiagram =
        hostingDiagramSubmodelSubmodels.diagrams[reference.diagram_uuid];
      if (!submodelDiagram) {
        return;
      }

      const submodelSubmodels: SubmodelsSection =
        findSubmodelsForNewReferenceSubmodel(
          submodelDiagram,
          hostingDiagramSubmodelSubmodels,
        );

      createReferenceSubmodel(
        submodelToPublish.projectId,
        submodelToPublish.name,
        submodelDiagram,
        submodelSubmodels,
        submodelToPublish.submodelConfiguration,
      );
    } else if (submodelToPublish.submodelFull) {
      dispatch(
        modelActions.confirmReferenceSubmodelCreatedFromSelection({
          submodelInstanceId: submodelToPublish.submodelInstanceId,
          referenceSubmodelId: submodelToPublish.submodelFull.id,
          submodel: submodelToPublish.submodelFull,
        }),
      );

      dispatch(submodelsActions.clearSubmodelToPublish());

      dispatch(
        modelActions.setSelections({
          selectionParentPath: submodelToPublish.submodelInstanceParentPath,
          selectedBlockIds: [submodelToPublish.submodelInstanceId],
          selectedLinkIds: [],
          selectedAnnotationIds: [],
        }),
      );
    }
  }, [
    dispatch,
    createReferenceSubmodel,
    submodelToPublish,
    hostingDiagramSubmodelSubmodels,
  ]);

  return null;
};
