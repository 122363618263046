import {
  ModelConfiguration,
  defaultModelConfiguration,
} from '@collimator/model-schemas-ts';
import { t } from '@lingui/macro';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { modelActions } from 'app/slices/modelSlice';
import {
  ModelConfigurationSolverType,
  ModelConfigurationType,
  buildNumericalChange,
} from 'app/utils/modelDataUtils';
import { Information } from 'ui/common/Icons/Standard';
import { TextInputAlign } from 'ui/common/Input/inputTypes';
import {
  isPositiveNumberRule,
  nonNegativeNumberRules,
  positiveNumberRules,
} from 'ui/common/Input/inputValidation';
import SectionHeading from 'ui/common/Inputs/SectionHeading';
import SelectInput from 'ui/common/SelectInput';
import {
  DetailInputRowsSection,
  DetailsDoubleRow,
  DetailsInput,
  DetailsLabel,
  DetailsSection,
  DetailsSingleRow,
} from 'ui/modelEditor/DetailsComponents';
import { useModelPermission } from 'ui/permission/useModelPermission';
import { useAppParams } from 'util/useAppParams';

const workerTypes = ['any', 'cpu', 'gpu'];
const workerTypesOptions = workerTypes.map((o) => ({
  value: o,
  label: o,
}));

const numericalBackendOptions: {
  value: NonNullable<ModelConfiguration['numerical_backend']>;
  label: string;
}[] = [
  {
    value: 'auto',
    label: 'Auto',
  },
  {
    value: 'numpy',
    label: 'NumPy',
  },
  {
    value: 'jax',
    label: 'JAX',
  },
];

const SimulationSettingsDetails = () => {
  const dispatch = useAppDispatch();

  const { projectId, modelId, versionId } = useAppParams();
  const { canEditCurrentModelVersion } = useModelPermission(
    projectId,
    modelId,
    versionId,
  );
  const { workerTypeSelectionEnabled } = useAppSelector(
    (state) => state.userOptions.options,
  );

  const model = useAppSelector((state) => state.model.present);
  const config = model.configuration;

  const onChangeNumericalConfigValue = (
    name: ModelConfigurationType,
    value: string | undefined,
  ) =>
    dispatch(
      modelActions.changeModelConfigurationValue({
        name,
        value: buildNumericalChange(value, true),
      }),
    );

  const onChangeNumericalConfigSolverValue = (
    name: ModelConfigurationSolverType,
    value: string | undefined,
  ) =>
    dispatch(
      modelActions.changeModelConfigurationSolverValue({
        name,
        value: buildNumericalChange(value),
      }),
    );

  const changeWorkerType = (value: string) => {
    dispatch(
      modelActions.changeModelConfigurationValue({
        name: 'worker_type',
        value,
      }),
    );
  };

  const configuration = useAppSelector(
    (state) => state.model.present.configuration,
  );

  // max_minor_steps_per_major_step: requirment of diffrax, but the default should be good enough. Will go away after WC-88
  const showMaxMinorSteps = false;
  const showMaxMajorSteps =
    configuration.numerical_backend === 'jax' ||
    configuration.numerical_backend === 'auto';

  return (
    <>
      <SectionHeading
        ButtonIcon={Information}
        linkUrl="https://docs.collimator.ai/running-simulations/simulation-and-output-settings#simulation"
        testId="simulation-settings">
        {t({
          id: 'modelRenderer.modelConfigurationDetails.heading',
          message: 'Simulation settings',
        })}
      </SectionHeading>
      <DetailInputRowsSection key="sim_params">
        <DetailsDoubleRow noMargin>
          <DetailsLabel>
            {t({
              id: 'modelRenderer.modelConfigurationDetails.startTime.label',
              message: 'Start time',
            })}
          </DetailsLabel>
          <DetailsInput
            grow
            onSubmitValue={(value) =>
              onChangeNumericalConfigValue('start_time', value)
            }
            value={config.start_time}
            align={TextInputAlign.Right}
            disabled={!canEditCurrentModelVersion}
            validationRules={nonNegativeNumberRules}
          />
        </DetailsDoubleRow>
        <DetailsDoubleRow noMargin>
          <DetailsLabel>
            {t({
              id: 'modelRenderer.modelConfigurationDetails.endTime.label',
              message: 'End time',
            })}
          </DetailsLabel>
          <DetailsInput
            grow
            onSubmitValue={(value) =>
              onChangeNumericalConfigValue('stop_time', value)
            }
            value={config.stop_time}
            align={TextInputAlign.Right}
            disabled={!canEditCurrentModelVersion}
            validationRules={positiveNumberRules}
          />
        </DetailsDoubleRow>
        <DetailsSingleRow noMargin>
          <DetailsLabel stretch>Numerical backend</DetailsLabel>
          <SelectInput
            onSelectValue={(value) =>
              dispatch(
                modelActions.changeModelConfigurationValue({
                  name: 'numerical_backend',
                  value,
                }),
              )
            }
            currentValue={configuration.numerical_backend ?? 'auto'}
            options={numericalBackendOptions}
            isDisabled={!canEditCurrentModelVersion}
          />
        </DetailsSingleRow>
        {showMaxMajorSteps && (
          <DetailsSingleRow noMargin>
            <DetailsLabel stretch>
              {t({
                id: 'modelRenderer.simulationSolverDetails.continuous.maxMajorSteps.label',
                message: 'Maximum Major Steps',
              })}
            </DetailsLabel>
            <DetailsInput
              rightIconIsResetButton
              onSubmitValue={(value) =>
                onChangeNumericalConfigValue('max_major_steps', value)
              }
              onClickRightIcon={() =>
                dispatch(
                  modelActions.changeModelConfigurationValue({
                    name: 'max_major_steps',
                    value: defaultModelConfiguration.max_major_steps,
                  }),
                )
              }
              value={configuration.max_major_steps ?? ''}
              defaultValue=""
              align={TextInputAlign.Right}
              hasBorder
              grow
              validationRules={[isPositiveNumberRule]}
              placeholder="Auto"
              disabled={!canEditCurrentModelVersion}
            />
          </DetailsSingleRow>
        )}
        {showMaxMinorSteps && (
          <DetailsSingleRow noMargin>
            <DetailsLabel stretch>
              {t({
                id: 'modelRenderer.simulationSolverDetails.continuous.maxMinorStepsPerMajorStep.label',
                message: 'Maximum minor steps per major step',
              })}
            </DetailsLabel>
            <DetailsInput
              rightIconIsResetButton
              onSubmitValue={(value) =>
                onChangeNumericalConfigSolverValue(
                  'max_minor_steps_per_major_step',
                  value,
                )
              }
              onClickRightIcon={() =>
                onChangeNumericalConfigSolverValue(
                  'max_minor_steps_per_major_step',
                  String(
                    defaultModelConfiguration.solver
                      ?.max_minor_steps_per_major_step ?? '',
                  ),
                )
              }
              value={configuration.solver?.max_minor_steps_per_major_step ?? ''}
              defaultValue={
                defaultModelConfiguration.solver?.max_minor_steps_per_major_step
              }
              align={TextInputAlign.Right}
              hasBorder
              grow
              validationRules={positiveNumberRules}
              disabled={!canEditCurrentModelVersion}
            />
          </DetailsSingleRow>
        )}
        {workerTypeSelectionEnabled && (
          <DetailsSection>
            <DetailsLabel>
              {t({
                id: 'modelRenderer.modelConfigurationDetails.workerType.label',
                message: 'Worker type',
              })}
            </DetailsLabel>
            <SelectInput
              options={workerTypesOptions}
              currentValue={config.worker_type || 'any'}
              onSelectValue={changeWorkerType}
              isDisabled={!canEditCurrentModelVersion}
            />
          </DetailsSection>
        )}
      </DetailInputRowsSection>
    </>
  );
};

export default SimulationSettingsDetails;
