import { t } from '@lingui/macro';
import { ModelLogLine, OutputLogLevel } from 'app/slices/simResultsSlice';

/** Returns the display name and CSS class for a given level */
export const getLogLevelDisplayName = (rawLevel?: string): [string, string] => {
  const level: OutputLogLevel =
    Object.values(OutputLogLevel).find((l) => l === rawLevel) ||
    OutputLogLevel.UNKNOWN;

  switch (level) {
    case OutputLogLevel.DBG:
    case OutputLogLevel.DEBUG:
      return [
        t({
          id: 'modelEditor.consoleLogLine.logLevel.debug',
          message: 'DEBUG',
        }),
        'dbg',
      ];
    case OutputLogLevel.INF:
    case OutputLogLevel.INFO:
      return [
        t({
          id: 'modelEditor.consoleLogLine.logLevel.info',
          message: 'INFO',
        }),
        'inf',
      ];
    case OutputLogLevel.WRN:
    case OutputLogLevel.WARNING:
      return [
        t({
          id: 'modelEditor.consoleLogLine.logLevel.warn',
          message: 'WARNING',
        }),
        'wrn',
      ];
    case OutputLogLevel.ERR:
    case OutputLogLevel.ERROR:
      return [
        t({
          id: 'modelEditor.consoleLogLine.logLevel.error',
          message: 'ERROR',
        }),
        'err',
      ];
    case OutputLogLevel.FATAL:
      return [
        t({
          id: 'modelEditor.consoleLogLine.logLevel.fatal',
          message: 'FATAL ERROR',
        }),
        'fatal',
      ];
    case OutputLogLevel.UNKNOWN:
    default:
      return [
        t({
          id: 'modelEditor.consoleLogLine.logLevel.unknown',
          message: '???',
        }),
        'unknown',
      ];
  }
};

export function parseServerOutputLog(rawLog: ModelLogLine): [string, string][] {
  const SPECIAL_KEYS = [
    'level',
    'message',
    'timestamp',
    '__error__',
    '__detail__',
  ];
  return Object.keys(rawLog)
    .filter((key) => SPECIAL_KEYS.indexOf(key) === -1)
    .map((key) => [key, JSON.stringify(rawLog[key])]);
}
