import { WebSocketMessage } from 'app/third_party_types/websocket/websocket-message';
import { WebSocketMessageType } from 'app/third_party_types/websocket/websocket-message-type';
import React from 'react';
import { useAuth } from 'ui/auth/useAuthWrapper';

type Subscriber = (message: WebSocketMessage) => void;

export interface WebSocketContext {
  isOpen: boolean;
  isInit: boolean;
  publish: (message: WebSocketMessage) => void;
  subscribe: (
    type: WebSocketMessageType,
    id: string,
    callback: Subscriber,
    force?: boolean,
  ) => void;
  unsubscribe: (type: WebSocketMessageType, id: string) => void;
}

const webSocketContext = React.createContext<WebSocketContext>({
  isOpen: false,
  isInit: false,
  publish: () => {},
  subscribe: () => {},
  unsubscribe: () => {},
});

interface WebSocketProviderProps {
  children: React.ReactNode;
  mockAuth?: boolean;
}

export const WebSocketProvider: React.FC<WebSocketProviderProps> = ({
  children,
  mockAuth,
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [isInit, setIsInit] = React.useState(false);
  const subscribers = React.useRef<
    Map<WebSocketMessageType, Map<string, Subscriber>>
  >(new Map());
  const webSocket = React.useRef<WebSocket | null>(null);
  const { getToken, isSignedIn } = useAuth(mockAuth)();

  const publish = React.useCallback(
    async (message: WebSocketMessage) => {
      if (!webSocket.current || !isOpen || !isSignedIn) {
        throw new Error(
          `WebSocket is not connected.\nmessage: ${message.type} ${message.payload}`,
        );
      }
      const jwt = await getToken();
      if (!jwt) {
        throw new Error('JWT is not set');
      }
      webSocket.current.send(
        JSON.stringify({
          ...message,
          payload: message.payload,
          jwt,
        }),
      );
    },
    [getToken, isOpen, isSignedIn],
  );

  const subscribe = React.useCallback(
    (
      type: WebSocketMessageType,
      subscriberId: string,
      callback: Subscriber,
      force?: boolean,
    ) => {
      const idToSubscriber =
        subscribers.current.get(type) || new Map<string, Subscriber>();
      if (!force && idToSubscriber.has(subscriberId)) {
        console.error(
          `${subscriberId} is already subscribed to ${type}, you must unsubscribe first.`,
        );
      } else {
        idToSubscriber.set(subscriberId, callback);
        subscribers.current.set(type, idToSubscriber);
      }
    },
    [],
  );

  const unsubscribe = React.useCallback(
    (type: WebSocketMessageType, subscriberId: string) => {
      const idToSubscriber = subscribers.current.get(type);
      if (!idToSubscriber) return;
      idToSubscriber.delete(subscriberId);
    },
    [],
  );

  const connect = React.useCallback(() => {
    const socket = new WebSocket(`${process.env.REACT_APP_WS_ENDPOINT}/ws`);

    socket.onopen = async () => {
      setIsOpen(true);
    };

    socket.onmessage = (event) => {
      const data: WebSocketMessage = JSON.parse(event.data);
      const callbacksIt = subscribers.current.get(data.type)?.values();
      if (!callbacksIt) return;
      const callbacks = [...callbacksIt];
      for (let callback of callbacks) {
        callback(data);
      }
    };

    socket.onerror = (event) => {
      console.error('WebSocket Error: ', event);
      const callbacksIt = subscribers.current
        .get(WebSocketMessageType.INTERNAL_ERROR)
        ?.values();
      if (!callbacksIt) return;
      const callbacks = [...callbacksIt];
      for (let callback of callbacks) {
        callback({
          id: '',
          type: WebSocketMessageType.INTERNAL_ERROR,
          payload: event,
        });
      }
    };

    socket.onclose = () => {
      console.warn('WebSocket connection was closed. Reconnecting...');
      setIsOpen(false);
      setIsInit(false);
      setTimeout(connect, 1000);
    };
    webSocket.current = socket;
  }, []);

  React.useEffect(() => {
    if (!webSocket.current || !isOpen || !isSignedIn) return;
    const helloMessage: WebSocketMessage = {
      id: '1',
      type: WebSocketMessageType.HELLO,
      payload: 'Client connected',
    };
    try {
      publish(helloMessage);
    } catch (e) {
      console.error(e);
    }
  }, [isOpen, isSignedIn, publish]);

  React.useEffect(() => {
    if (isInit) return;
    if (webSocket.current) return;
    if (!isSignedIn) return;
    if (isOpen) return;

    if (!process.env.REACT_APP_WS_ENDPOINT) {
      console.error('REACT_APP_WS_ENDPOINT is not set');
      return;
    }

    subscribe(
      WebSocketMessageType.INTERNAL_ERROR,
      'internal_error',
      (message: WebSocketMessage) => {
        console.error('WebSocket internal error: ', message);
      },
    );
    if (process.env.NODE_ENV === 'development') {
      subscribe(
        WebSocketMessageType.HELLO,
        'hello',
        (message: WebSocketMessage) => {
          // eslint-disable-next-line no-console
          console.debug('WebSocket hello: ', message);
        },
      );
    }

    connect();

    setIsInit(true);

    return () => {
      if (webSocket.current?.readyState === WebSocket.OPEN) {
        webSocket.current.close();
        webSocket.current = null;
      }
      unsubscribe(WebSocketMessageType.INTERNAL_ERROR, 'internal_error');
      unsubscribe(WebSocketMessageType.HELLO, 'hello');
    };
  }, [connect, isSignedIn, isOpen, subscribe, unsubscribe, isInit]);

  const contextValue = React.useMemo(
    () => ({ isOpen, isInit, publish, subscribe, unsubscribe }),
    [isOpen, isInit, publish, subscribe, unsubscribe],
  );

  return (
    <webSocketContext.Provider value={contextValue}>
      {children}
    </webSocketContext.Provider>
  );
};

export const useWebSocket = () => {
  const context = React.useContext(webSocketContext);
  if (!context) {
    throw new Error('useWebSocket must be used within a WebSocketProvider');
  }
  return context;
};
