/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  stroke?: any;
  strokeLinecap?: any;
  className?: string;
}

const Discrete: React.FC<Props> = (props) => (
  <svg viewBox="0 0 16 16" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3 12H4.5C4.77614 12 5 11.7761 5 11.5V4.5C5 4.22386 5.22386 4 5.5 4H6.5C6.77614 4 7 4.22386 7 4.5V11.5C7 11.7761 7.22386 12 7.5 12H8.5C8.77614 12 9 11.7761 9 11.5V4.5C9 4.22386 9.22386 4 9.5 4H10.5C10.7761 4 11 4.22386 11 4.5V11.5C11 11.7761 11.2239 12 11.5 12H13"
      stroke={props.stroke}
      strokeLinecap={props.strokeLinecap}
    />
  </svg>
);

Discrete.defaultProps = {
  width: '16',
  height: '16',
  viewBox: '0 0 16 16',
  fill: 'none',
  xmlns: 'http://www.w3.org/2000/svg',
  stroke: '#020606',
  strokeLinecap: 'round',
  className: '',
};

export default Discrete;
