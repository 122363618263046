import styled from '@emotion/styled';
import * as Headings from 'ui/common/typography/Typography';
import { formatDate } from 'util/dateUtils';

export const PageWrapper = styled.div(({ theme }) => ({
  width: `calc(100% - ${theme.spacing.xxlarge} - ${theme.spacing.xxlarge})`,
  height: `calc(100% - ${theme.spacing.xxlarge} - ${theme.spacing.xxlarge})`,
  margin: theme.spacing.xlarge,
  padding: theme.spacing.large,
  background: theme.colors.grey[5],
  borderRadius: theme.spacing.small,
  overflowX: 'auto',
  overflowY: 'auto',
}));

export const Head = styled.header(({ theme }) => ({
  display: 'flex',
  width: ' 100%',
  marginBottom: theme.spacing.xlarge,
  padding: 10,
  columnGap: 8,
}));

export const Title = styled(Headings.H2)(({ theme }) => ({
  width: '100%',
}));

export const ActionSection = styled.div(({ theme }) => ({
  marginBottom: theme.spacing.large,
  alignItems: 'center',
}));

export const DateCell: React.FC<{ value: string }> = ({ value }) => (
  <span title={value}>{formatDate(value)}</span>
);
