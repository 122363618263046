import styled from '@emotion/styled/macro';
import { t } from '@lingui/macro';
import {
  DOWNSAMPLING_THRESHOLD,
  DOWNSAMPLING_THRESHOLD_OUTSIDE_BOUNDS,
  loadSimulationResults,
} from 'app/api/useSimulationResultsNew';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { dataExplorerActions } from 'app/slices/dataExplorerSlice';
import { filterValid } from 'app/utils/compare';
import ndarray from 'ndarray';
import React from 'react';
import Button from 'ui/common/Button/Button';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import { Remove } from 'ui/common/Icons/Standard';
import { Spinner, SpinnerWrapper } from 'ui/common/Spinner';
import { SmallEmphasis } from 'ui/common/typography/Typography';
import Chart from 'ui/dataExplorer/charts/Chart';
import {
  DataBounds,
  PartialDataBounds,
  PlotCellArrayData,
  PlotCellMetadata,
  TraceMetadata,
} from '../dataExplorerTypes';

const RemoveButton = styled(Button)`
  margin-top: ${({ theme }) => theme.spacing.normal};
`;

const computeBounds = (array: ndarray.NdArray): DataBounds => {
  const startX = array.get(0, 0);
  const endX = array.get(array.shape[0] - 1, 0);

  let startY = Infinity;
  let endY = -Infinity;

  for (let i = 1; i < array.shape[1]; i++) {
    for (let j = 0; j < array.shape[0]; j++) {
      const value = array.get(j, i);
      if (value !== undefined && isFinite(value)) {
        if (value < startY) startY = value;
        if (value > endY) endY = value;
      }
    }
  }

  if (startY === Infinity || endY === -Infinity) {
    startY = 0;
    endY = 1;
  } else if (startY === endY) {
    startY -= 1;
    endY += 1;
  }

  return { startX, endX, startY, endY };
};

// Returns a uniformly resampled time series for the zoom slider minimap
const buildMinimapData = (
  source: ndarray.NdArray,
  numPoints = 200,
  sumAll = false,
): ndarray.NdArray => {
  if (source.shape[0] < numPoints) return source;

  const target = ndarray(new Float64Array(numPoints * 2), [numPoints, 2]);
  const ndims = source.shape[1];

  const sourceIndex = (ii: number) =>
    Math.min(
      Math.round((ii * source.shape[0]) / numPoints),
      source.shape[0] - 1,
    );

  for (let i = 0; i < numPoints; i++) {
    const time = source.get(sourceIndex(i), 0);
    const value = source.get(sourceIndex(i), 1);
    target.set(i, 0, time);
    if (!sumAll) {
      target.set(i, 1, value);
    } else {
      let sum = 0;
      for (let j = 1; j < ndims; j++) {
        sum += source.get(sourceIndex(i), j);
      }
      target.set(i, 1, sum / (ndims / 2));
    }
  }
  return target;
};

// This computes zoomed in bounds that are somewhat rounded to be more likely to
// hit the cache. Eg with initial [0,1000], zoom [120,280], return [100,300].
const computeZoomedBounds = (
  initialBounds: PartialDataBounds,
  zoomBounds: PartialDataBounds,
): PartialDataBounds | undefined => {
  const start = initialBounds.startX;
  const end = initialBounds.endX;
  if (start === Infinity || end === -Infinity) return undefined;

  const zoomStart = zoomBounds.startX;
  const zoomEnd = zoomBounds.endX;
  if (zoomStart === undefined || zoomEnd === undefined) return undefined;
  if (zoomStart === start && zoomEnd === end) return undefined;

  const ratio = (zoomEnd - zoomStart) / (end - start);
  const stepSize = 10 ** Math.floor(Math.log10(ratio * (end - start)));
  const roundedStart =
    start + Math.floor((zoomStart - start) / stepSize) * stepSize;
  const roundedEnd = start + Math.ceil((zoomEnd - start) / stepSize) * stepSize;
  return { startX: roundedStart, endX: roundedEnd };
};

// For DataExplorer: simulationId is undefined, stored in each trace metadata.
// For Visualizer: simulationId must be passed in.
interface Props {
  cellId: string;
  simulationId?: string;
  canEditVisualization: boolean;
}

export const DataExplorerPlotCell: React.FC<Props> = ({
  cellId,
  simulationId,
  canEditVisualization,
}) => {
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = React.useState(true);
  const [tracesData, setTracesData] = React.useState<PlotCellArrayData[]>([]);

  const [initialBounds, setInitialBounds] = React.useState<
    PartialDataBounds & {
      totalPoints?: number;
    }
  >({ startX: Infinity, endX: -Infinity });
  const [zoomBounds, setZoomBounds] = React.useState<
    PartialDataBounds | undefined
  >();
  const [uniqueErrors, setUniqueErrors] = React.useState<string[]>([]);

  const plotCell: PlotCellMetadata | undefined = useAppSelector(
    (state) => state.dataExplorer.idToPlotCell[cellId],
  );

  const idToTrace = useAppSelector((state) => state.dataExplorer.idToTrace);
  const traceMetadatas = React.useMemo(
    () =>
      (plotCell?.traceIds || [])
        .map((traceId) => idToTrace[traceId])
        .filter(Boolean)
        .filter((trace) => simulationId || trace.explorationSimId),
    [idToTrace, plotCell, simulationId],
  );

  const fetchSignalData = React.useCallback(
    (
      trace: TraceMetadata,
      from?: number,
      to?: number,
    ): Promise<PlotCellArrayData | undefined> => {
      const simId = simulationId ?? trace.explorationSimId;
      if (!simId) return Promise.resolve(undefined);
      return loadSimulationResults({
        modelId: trace.modelId,
        signal: trace.tracePath,
        simulationId: simId,
        from,
        to,
        threshold: DOWNSAMPLING_THRESHOLD,
        thresholdOutsideBounds: DOWNSAMPLING_THRESHOLD_OUTSIDE_BOUNDS,
      }).then((data): PlotCellArrayData | undefined => {
        if (!data || !data.array) {
          const error = data.error ?? `No data for ${trace.signalPath}`;
          if (!uniqueErrors.includes(String(error))) {
            setUniqueErrors([...uniqueErrors, String(error)]);
          }
          return undefined;
        }

        return { traceId: trace.id, array: data.array, kind: 'array' };
      });
    },
    [simulationId, uniqueErrors],
  );

  // Fetch initial signal data (not zoomed in)
  React.useEffect(() => {
    Promise.all(
      traceMetadatas.map((trace) => {
        // Do not re-fetch if we have <5000 points, that would indicate we have all
        // the points already.
        const bounds =
          zoomBounds &&
          (initialBounds.totalPoints === undefined ||
            initialBounds.totalPoints >= DOWNSAMPLING_THRESHOLD)
            ? computeZoomedBounds(initialBounds, zoomBounds)
            : undefined;
        return fetchSignalData(trace, bounds?.startX, bounds?.endX);
      }),
    )
      .then(filterValid)
      .then((allTraceData) => setTracesData(allTraceData))
      .finally(() => setIsLoading(false));
  }, [
    traceMetadatas,
    initialBounds,
    zoomBounds,
    fetchSignalData,
    setTracesData,
    setIsLoading,
  ]);

  React.useEffect(() => {
    if (initialBounds.startX !== Infinity) return;

    const bounds: DataBounds = tracesData.reduce(
      (acc, plotCellData) => {
        if (plotCellData.kind !== 'array') return acc;
        const bounds = computeBounds(plotCellData.array);
        return {
          startX: Math.min(acc.startX, bounds.startX),
          endX: Math.max(acc.endX, bounds.endX),
          startY: Math.min(acc.startY, bounds.startY),
          endY: Math.max(acc.endY, bounds.endY),
        };
      },
      { startX: Infinity, endX: -Infinity, startY: Infinity, endY: -Infinity },
    );

    const totalPoints = tracesData[0]?.array.shape[0];

    if (
      bounds.startX !== initialBounds.startX ||
      bounds.endX !== initialBounds.endX ||
      bounds.startY !== initialBounds.startY ||
      bounds.endY !== initialBounds.endY ||
      totalPoints !== initialBounds.totalPoints
    ) {
      setInitialBounds({ ...bounds, totalPoints });
    }
  }, [setInitialBounds, tracesData, initialBounds]);

  const [minimapData, setMinimapData] = React.useState<
    PlotCellArrayData | undefined
  >();
  const tracesWithSliderMinimapData: PlotCellArrayData[] = React.useMemo(() => {
    // Construct a preview time series to simplify bounds handling and display
    // in the slider minimap.
    // Ideally we may also want to include all values from the signals, so that
    // the zoom slider minimap shows useful data. Fow now we just pick the first one.
    if (!tracesData.length) return [];
    if (tracesData[0].kind !== 'array') return []; // FIXME remove this

    // Do not rebuild if zoomed in.
    if (minimapData) {
      return [minimapData, ...tracesData];
    }

    const sliderData = buildMinimapData(tracesData[0].array);
    const data: PlotCellArrayData = {
      traceId: 'sliderMinimapData',
      array: sliderData,
      kind: 'array',
    };
    setMinimapData(data);

    return [data, ...tracesData];
  }, [tracesData, minimapData]);

  const fetchZoomedData = React.useCallback(
    (from: number, to: number) => {
      if (from === initialBounds.startX && to === initialBounds.endX) {
        setZoomBounds(undefined);
      } else {
        setZoomBounds({ startX: from, endX: to });
      }
    },
    [setZoomBounds, initialBounds],
  );

  if (!plotCell) return null;

  if (
    isLoading ||
    !tracesWithSliderMinimapData.some((data) => data.array?.shape[0] > 0)
  )
    return (
      <SpinnerWrapper>
        {isLoading ? (
          <>
            <Spinner />
            <SmallEmphasis>
              {t({
                id: 'dataExplorer.plotCell.loadingDataText',
                message: 'Loading data',
              })}
            </SmallEmphasis>
          </>
        ) : (
          <SmallEmphasis>
            {t({
              id: 'dataExplorer.plotCell.noData',
              message: 'Failed to load signal data for plots: ',
            })}
            {traceMetadatas.map((trace) => trace?.tracePath).join(', ')}
            {uniqueErrors.map((error) => (
              <div key={error}>{error}</div>
            ))}
          </SmallEmphasis>
        )}

        <RemoveButton
          Icon={Remove}
          variant={ButtonVariants.SmallSecondary}
          onClick={() =>
            dispatch(
              dataExplorerActions.removeTraces({
                traceIds: plotCell.traceIds,
              }),
            )
          }>
          {t({
            id: 'dataExplorer.plotCell.removePlotButtonText',
            message: 'Remove plot',
          })}
        </RemoveButton>
      </SpinnerWrapper>
    );

  return (
    <Chart
      canEdit={canEditVisualization}
      data={tracesWithSliderMinimapData}
      minimapDataTraceId="sliderMinimapData"
      traceIds={plotCell.traceIds}
      initialBounds={initialBounds}
      zoomBounds={zoomBounds}
      fetchZoomedData={fetchZoomedData}
    />
  );
};
