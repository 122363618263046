import { t } from '@lingui/macro';

export type TimeModeValueType = 'agnostic' | 'discrete';
export const userDefinedBlockTimeModes: Array<{
  label: string;
  value: TimeModeValueType;
}> = [
  {
    label: t({
      id: 'blockDetails.TimeModeOption.Agnostic',
      message: 'Agnostic',
    }),
    value: 'agnostic',
  },
  {
    label: t({
      id: 'blockDetails.TimeModeOption.Discrete',
      message: 'Discrete',
    }),
    value: 'discrete',
  },
];
