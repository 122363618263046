// !!!! -- DO NOT MANUALLY EDIT -- !!!!
// This file was generated by something in scripts/generateDefaultBlockData.js
// If you want to change the contents of this file, it should be automated
//
// (You can edit this file locally, but it may not be checked into the codebase -
// PRs with a manual edit of this file are forbidden from being merged.)

import { computationBlockDefaults } from 'app/generated_blocks/computationBlockDefaults';
import type { ComputationBlockClass } from 'app/generated_types/ComputationBlockClass';
import deepmerge from 'deepmerge';

export const AcausalElectricalVoltageSensor: ComputationBlockClass = deepmerge(
  computationBlockDefaults,
  {
    "base": {
      "name": "VoltageSensor",
      "namespace": "acausal.electrical",
      "description": "Voltage sensor."
    },
    "modes": {
      "time": "agnostic"
    },
    "ports": {
      "inputs": {
        "static": [
          {
            "name": "p",
            "variant": {
              "variant_kind": "acausal",
              "acausal_domain": "electrical"
            }
          }
        ]
      },
      "outputs": {
        "static": [
          {
            "name": "n",
            "variant": {
              "variant_kind": "acausal",
              "acausal_domain": "electrical"
            }
          },
          {
            "name": "v"
          }
        ]
      }
    }
  }
);
