import { useLocation } from 'react-router-dom';

export type BlockNavState = {
  block_instance_uuid: string;
};

// Location is finicky to use since it makes no guarantees at compile time
// https://github.com/remix-run/react-router/issues/8396#issuecomment-980829670
// Reasonably assert it's the right type with custom type guard
export const isBlockNavState = (state: unknown): state is BlockNavState =>
  typeof state === 'object' &&
  state !== null &&
  'block_instance_uuid' in state &&
  typeof (state as BlockNavState)?.block_instance_uuid === 'string';

export const useIsBlockNavState = () => {
  const state = useLocation()?.state as BlockNavState | undefined;
  return {
    isBlockNavState: state && isBlockNavState(state),
    blockInstanceUuid: state?.block_instance_uuid,
  };
};
