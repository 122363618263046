import { t } from '@lingui/macro';
import React from 'react';
import { Data, History } from 'ui/common/Icons/Small';
import { SidebarTabGroupWrapper } from 'ui/common/layout/appLayout';
import TabGroup from 'ui/common/tabs/TabGroup';
import { SimulationDataSection } from './simulationDataSidebar/SimulationDataSection';

enum LeftSidebarSection {
  SimulationData,
  DataSources,
}

interface Props {
  projectId: string;
  explorationId: string;
  canEditProject: boolean;
}

export const DataExplorerLeftSidebarContent: React.FC<Props> = ({
  projectId,
  explorationId,
  canEditProject,
}) => {
  const [section, setSection] = React.useState<LeftSidebarSection>(
    LeftSidebarSection.SimulationData,
  );

  const renderSection = () => {
    switch (section) {
      case LeftSidebarSection.SimulationData:
        return (
          projectId && (
            <SimulationDataSection
              projectId={projectId}
              explorationId={explorationId}
              canEditProject={canEditProject}
            />
          )
        );
      case LeftSidebarSection.DataSources:
        if (canEditProject) {
          // TODO add data sources section content
          return null;
        }
        return null;
    }
  };

  const tabs = React.useMemo(() => {
    const tabBuilder = [
      {
        id: 'simulationData',
        Icon: History,
        displayName: t({
          id: 'dataExplorer.leftSidebarTab.simulationData',
          message: 'Simulation Data',
        }),
        onClick: () => {
          setSection(LeftSidebarSection.SimulationData);
        },
        isSelected: () => section === LeftSidebarSection.SimulationData,
      },
    ];

    if (canEditProject) {
      tabBuilder.push({
        id: 'dataSources',
        Icon: Data,
        displayName: t({
          id: 'dataExplorer.leftSidebarTab.dataSources',
          message: 'Data Sources',
        }),
        onClick: () => {
          setSection(LeftSidebarSection.DataSources);
        },
        isSelected: () => section === LeftSidebarSection.DataSources,
      });
    }
    return tabBuilder;
  }, [section, canEditProject]);

  return (
    <>
      <SidebarTabGroupWrapper>
        <TabGroup tabs={tabs} testId="data-explorer-left-sidebar-tabs" />
      </SidebarTabGroupWrapper>
      {renderSection()}
    </>
  );
};
