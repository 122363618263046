/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Information: React.FC<Props> = (props) => (
  <svg viewBox="0 0 24 24" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.25 7C11.1119 7 11 7.11193 11 7.25V8.75C11 8.88807 11.1119 9 11.25 9H12.75C12.8881 9 13 8.88807 13 8.75V7.25C13 7.11193 12.8881 7 12.75 7H11.25Z"
      fill={props.fill}
    />
    <path
      d="M11 11.25C11 11.1119 11.1119 11 11.25 11H12.75C12.8881 11 13 11.1119 13 11.25V16.75C13 16.8881 12.8881 17 12.75 17H11.25C11.1119 17 11 16.8881 11 16.75V11.25Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M12 3.25C7.16751 3.25 3.25 7.16751 3.25 12C3.25 16.8325 7.16751 20.75 12 20.75C16.8325 20.75 20.75 16.8325 20.75 12C20.75 7.16751 16.8325 3.25 12 3.25ZM4.75 12C4.75 7.99594 7.99594 4.75 12 4.75C16.0041 4.75 19.25 7.99594 19.25 12C19.25 16.0041 16.0041 19.25 12 19.25C7.99594 19.25 4.75 16.0041 4.75 12Z"
      fill={props.fill}
    />
  </svg>
);

Information.defaultProps = {
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Information;
