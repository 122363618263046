import { v4 as uuid } from 'uuid';
import { nodeClassWithoutNamespace_displayOnly } from 'app/helpers';
import blockTypeNameToInstanceDefaults from 'app/blockClassNameToInstanceDefaults';
import { BlockClassName } from 'app/generated_types/SimulationModel';
import { getBlockIconID } from 'util/getBlockIconID';
import { SubmodelInfoLiteUI } from 'app/apiTransformers/convertGetSubmodelsList';
import { blockClassLookup } from 'app/generated_blocks';
import { Port } from '@collimator/model-schemas-ts';

export interface BlockDisplayData {
  nodeId: string;
  inputs?: Port[];
  outputs?: Port[];
  incount: number;
  outcount: number;
  nodeTypePrintName: string;
  blockClassName: BlockClassName;
  overrideDisplayName?: string;
  overridePropDefaults?: any;
  blockIconId: string;
  submodel?: SubmodelInfoLiteUI;
  connectedIDs?: Set<string>;
}

export function getBuiltInBlockDisplayData(
  blockClassName: BlockClassName,
): BlockDisplayData {
  const shortName = nodeClassWithoutNamespace_displayOnly(blockClassName);
  const blockClass = blockClassLookup(blockClassName);
  const shouldUseShortName =
    blockClass.base.namespace === 'core' ||
    blockClass.base.namespace === 'quanser';

  // TODO this is copied from elsewhere --
  // do we really need to build a whole instance just to get these couple bits of data?
  const node = blockTypeNameToInstanceDefaults(blockClassName);

  const nodeTypePrintName = nodeClassWithoutNamespace_displayOnly(node.type);

  return {
    nodeId: node.uuid,
    inputs: node.inputs,
    outputs: node.outputs,
    incount: node.inputs.length,
    outcount: node.outputs.length,
    nodeTypePrintName,
    blockClassName,
    blockIconId: getBlockIconID(
      shouldUseShortName ? shortName : node.type,
      node,
    ),
  };
}

export function getReferenceSubmodelBlockDisplayData(
  submodel: SubmodelInfoLiteUI,
): BlockDisplayData {
  return {
    nodeId: uuid(),
    incount: submodel.inputCount,
    outcount: submodel.outputCount,
    nodeTypePrintName: submodel.name,
    blockIconId: 'submodel',
    blockClassName: 'core.ReferenceSubmodel',
    submodel,
  };
}
