import { ModelDiagram } from 'app/generated_types/SimulationModel';
import React from 'react';
import ModelBlockItem from 'ui/objectBrowser/sections/ModelBlockItem';

interface Props {
  projectId: string;
  testIdPath: string;
  diagram?: ModelDiagram | null;
  parentPath: string[];
  searchString?: string;
}

const DiagramTreeContent: React.FC<Props> = ({
  projectId,
  testIdPath,
  diagram,
  parentPath,
  searchString,
}) => {
  if (!diagram || !diagram.nodes) {
    return null;
  }

  return (
    <>
      {diagram.nodes.map((node, index) => (
        <ModelBlockItem
          testIdPath={`${testIdPath}-${index}`}
          key={node.uuid}
          node={node}
          parentPath={parentPath}
          searchString={searchString}
          projectId={projectId}
        />
      ))}
    </>
  );
};

export default DiagramTreeContent;
