import styled from '@emotion/styled';
import { t } from '@lingui/macro';
import { useProject } from 'app/api/useProject';
import { useProjectItems } from 'app/api/useProjectItems';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { modelActions } from 'app/slices/modelSlice';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from 'ui/common/Button/Button';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import { Edit } from 'ui/common/Icons/Standard';
import SectionHeading from 'ui/common/Inputs/SectionHeading';
import DataFileParameter from 'ui/common/parameters/DataFileParameter';
import { ProjectItemType } from 'ui/dashboard/dashboardTypes';
import {
  DetailInputRowsSection,
  DetailsSection,
} from 'ui/modelEditor/DetailsComponents';

const RightSideButton = styled(Button)`
  flex-position: right;
  justify-self: end;
  height: 24px;
  margin-left: 8px;
  margin-right: -4px; // small hack to v-align with checkboxes
`;
const Spacer = styled.div`
  max-width: 100%;
  flex: 1;
`;

const InitializationScriptDetails = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { project } = useProject();
  const { projectItems } = useProjectItems(project);

  const isSubmodel = useAppSelector(
    (state) => state.submodels.topLevelModelType === 'Submodel',
  );

  const initScriptName = useAppSelector((state) =>
    isSubmodel
      ? state.model.present.submodelConfiguration?.init_script?.value
      : state.model.present.configuration.workspace?.init_scripts?.[0]
          ?.file_name,
  );

  const scriptFile = React.useMemo(
    () =>
      projectItems.find(
        (item) =>
          item.type === ProjectItemType.PROJECTFILE &&
          item.name === initScriptName,
      ),
    [initScriptName, projectItems],
  );

  const changeInitScriptName = React.useCallback(
    (value: string) => {
      dispatch(
        modelActions.setInitScriptName({ file_name: value, isSubmodel }),
      );
    },
    [dispatch, isSubmodel],
  );

  const onClickEdit = React.useCallback(() => {
    if (!scriptFile || !project) return;
    navigate(`/projects/${project?.uuid}/code_editor/${scriptFile.uuid}`);
  }, [navigate, scriptFile, project]);

  return (
    <>
      <SectionHeading testId="model">
        {t({
          id: 'modelRenderer.propertiesSidebar.initializationScript.heading',
          message: 'Initialization script',
        })}
        <Spacer />
        <RightSideButton
          variant={ButtonVariants.SmallTertiary}
          Icon={Edit}
          onClick={onClickEdit}
          disabled={!scriptFile}
        />
      </SectionHeading>
      <DetailInputRowsSection>
        <DetailsSection vertical>
          <DataFileParameter
            datafileType="PythonScript"
            onSelectValue={changeInitScriptName}
            currentValue={initScriptName || ''}
            allowEmpty
          />
        </DetailsSection>
      </DetailInputRowsSection>
    </>
  );
};

export default InitializationScriptDetails;
