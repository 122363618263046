import styled from '@emotion/styled/macro';
import { useAppDispatch } from 'app/hooks';
import { dataExplorerActions } from 'app/slices/dataExplorerSlice';
import { useDrop } from 'react-dnd';
import {
  DataExplorerDragType,
  SignalDragItem,
} from 'ui/dataExplorer/dataExplorerTypes';
import { DEFAULT_ROW_HEIGHT } from 'util/plotCellUtils';

const RowCreateDropZoneWrapper = styled.div<{
  isOver: boolean;
  show: boolean;
}>`
  flex-shrink: 0;
  height: ${DEFAULT_ROW_HEIGHT}px;
  ${({ theme, isOver, show }) => `
  border-radius: ${theme.spacing.xsmall};
  visibility: ${show ? 'visible' : 'hidden'};
  background-color: ${isOver ? theme.colors.ui.tint2 : theme.colors.ui.tint3};
  `}
`;

const RowCreateDropZone = () => {
  const dispatch = useAppDispatch();

  const [{ isOver, isDraggingSignal }, drop] = useDrop(() => ({
    accept: DataExplorerDragType.SignalTree,
    drop: (item: SignalDragItem) => {
      dispatch(
        dataExplorerActions.addTracesInNewRow({
          traceSpecs: item.traceSpecs,
        }),
      );
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
      isDraggingSignal: !!monitor.canDrop(),
    }),
  }));

  return (
    <RowCreateDropZoneWrapper
      ref={drop}
      isOver={isOver}
      show={isDraggingSignal}
    />
  );
};

export default RowCreateDropZone;
