/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Resize: React.FC<Props> = (props) => (
  <svg viewBox="0 0 24 24" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M21.8047 10.1953C22.0651 10.4556 22.0651 10.8777 21.8047 11.1381L11.1381 21.8047C10.8777 22.0651 10.4556 22.0651 10.1953 21.8047C9.93491 21.5444 9.93491 21.1223 10.1953 20.8619L20.8619 10.1953C21.1223 9.93491 21.5444 9.93491 21.8047 10.1953ZM21.8047 15.5286C22.0651 15.7889 22.0651 16.2111 21.8047 16.4714L16.4714 21.8047C16.2111 22.0651 15.7889 22.0651 15.5286 21.8047C15.2682 21.5444 15.2682 21.1223 15.5286 20.8619L20.8619 15.5286C21.1223 15.2682 21.5444 15.2682 21.8047 15.5286Z"
      fill={props.fill}
    />
  </svg>
);

Resize.defaultProps = {
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Resize;
