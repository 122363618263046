import React from 'react';
import {
  usePostUserSignBetaTosMutation,
  useGetUserProfileQuery,
} from 'app/apiGenerated/generatedApi';
import { useAppDispatch } from 'app/hooks';
import { userActions } from 'app/slices/userSlice';

export const useTos = () => {
  const dispatch = useAppDispatch();

  const [callSignBetaTos] = usePostUserSignBetaTosMutation();
  const { data: userProfile, refetch, isFetching } = useGetUserProfileQuery();

  const setAgreedTos = React.useCallback(
    (organizationName: string) =>
      callSignBetaTos({
        userSignBetaTosRequest: {
          accepted: true,
          organization: organizationName,
        },
      })
        .unwrap()
        .then(() => {
          localStorage.setItem('justAcceptedToS', 'true');
          refetch();
        }),
    [callSignBetaTos, refetch],
  );
  React.useEffect(() => {
    if (userProfile) {
      dispatch(userActions.setUserProfile(userProfile));
    }
  }, [dispatch, userProfile]);

  return {
    hasAgreedToTermsOfService: !!userProfile?.signed_beta_tos,
    isFetching,
    setAgreedTos,
  };
};
