import React, { useCallback } from 'react';
import { ThemeMapType } from './themes';

const ThemeContext = React.createContext<{
  theme: keyof ThemeMapType;
  switchTheme: (_t: keyof ThemeMapType) => void;
}>({
  theme: 'base',
  switchTheme: (_t: keyof ThemeMapType) => {},
});

const ThemeStore = ({
  children,
  themeValue,
}: {
  children: React.ReactNode;
  themeValue: keyof ThemeMapType;
}): React.ReactElement => {
  const [theme, setTheme] = React.useState<keyof ThemeMapType>(themeValue);

  const switchTheme = useCallback(
    (t: keyof ThemeMapType) => setTheme(t),
    [setTheme],
  );

  const contextValue = React.useMemo(
    () => ({ theme, switchTheme }),
    [theme, switchTheme],
  );

  return (
    <ThemeContext.Provider value={contextValue}>
      {children}
    </ThemeContext.Provider>
  );
};

export { ThemeContext };

export default ThemeStore;
