/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  className?: string;
}

const Code: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.7115 7.23718C19.8425 6.84422 19.6301 6.41948 19.2372 6.28849C18.8442 6.15751 18.4195 6.36988 18.2885 6.76283L12.2885 24.7628C12.1575 25.1558 12.3699 25.5805 12.7628 25.7115C13.1558 25.8425 13.5805 25.6301 13.7115 25.2372L19.7115 7.23718Z"
      fill={props.fill}
    />
    <path
      d="M9.48014 9.42384C9.79835 9.68901 9.84134 10.1619 9.57617 10.4801L4.97628 16L9.57617 21.5199C9.84134 21.8381 9.79835 22.311 9.48014 22.5762C9.16193 22.8413 8.68901 22.7984 8.42383 22.4801L3.42383 16.4801C3.19206 16.202 3.19206 15.798 3.42383 15.5199L8.42383 9.51987C8.68901 9.20166 9.16193 9.15867 9.48014 9.42384Z"
      fill={props.fill}
    />
    <path
      d="M22.5199 9.42384C22.2017 9.68901 22.1587 10.1619 22.4238 10.4801L27.0237 16L22.4238 21.5199C22.1587 21.8381 22.2017 22.311 22.5199 22.5762C22.8381 22.8413 23.311 22.7984 23.5762 22.4801L28.5762 16.4801C28.8079 16.202 28.8079 15.798 28.5762 15.5199L23.5762 9.51987C23.311 9.20166 22.8381 9.15867 22.5199 9.42384Z"
      fill={props.fill}
    />
  </svg>
);

Code.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  className: '',
};

export default Code;
