import { BaseQueryApi } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import {
  createApi,
  FetchArgs,
  fetchBaseQuery,
  retry,
} from '@reduxjs/toolkit/query/react';
import * as Sentry from '@sentry/react';
import { Scope } from '@sentry/types';
import { API_BASE_URL } from 'app/config/globalApplicationConfig';
import { v4 as uuid } from 'uuid';
import { ApiError } from './generatedApiTypes';

// A global session ID that is used to group logs and errors together
// Do NOT use this for any logic!
export const globalSessionId = uuid();

const baseQueryWithRetries = retry(
  async (args: string | FetchArgs, api: BaseQueryApi, extraOptions) => {
    const baseQueryFn = fetchBaseQuery({
      baseUrl: API_BASE_URL,
    });

    // Always set X-Request-ID header
    if (typeof args === 'string' || args instanceof String) {
      console.error('Called fetch with string url instead of args object');
      args = { url: `${args}` };
    }

    const requestId = uuid();
    if (Array.isArray(args.headers)) {
      args.headers.push(
        ['X-Request-ID', requestId],
        ['X-Session-ID', globalSessionId],
      );
    } else {
      args.headers = {
        ...(args.headers || {}),
        'X-Request-ID': requestId,
        'X-Session-ID': globalSessionId,
      };
    }

    const result = await baseQueryFn(args, api, extraOptions);

    // All good
    if (!result.error) return result;

    const statusCode = result.meta?.response?.status || 0;

    // 404 means not found, that's OK, don't log, don't retry.
    if (statusCode === 404) {
      return retry.fail(result.error);
    }

    // We got an error, send it to Sentry with the request ID
    // If this becomes noisy, we can control it with a Sentry filter
    const sentryScope = (scope: Scope): Scope => {
      scope.setTag('request_id', requestId);
      return scope;
    };
    const apiErrorMessage =
      (result.error.data as any as ApiError)?.message ||
      result.error.data ||
      '(no message)';
    Sentry.captureMessage(`API error: ${apiErrorMessage}`, sentryScope);

    // Don't retry
    if (statusCode === 401) {
      retry.fail(result.error); // Unauthorized
    }

    // Always retry on GET with 5xx status
    const isGet =
      !result.meta?.request?.method ||
      result.meta.request.method.toUpperCase() === 'GET';

    if (isGet && statusCode >= 500) {
      return result;
    }

    // Always retry on these network errors
    // Note that if the request actually went through and only the response
    // failed, there could be some side effects. We'll have to see how this
    // works out in real life.
    if (statusCode === 502) return result; // Bad Gateway
    if (statusCode === 503) return result; // Service Unavailable
    if (statusCode === 504) return result; // Gateway Timeout

    console.warn('not retrying error API call to ', args.url);
    return retry.fail(result.error);
  },
  {
    maxRetries: 5,
  },
);

export const emptySplitApi = createApi({
  baseQuery: baseQueryWithRetries,
  endpoints: () => ({}),
});
