import { ModelDiagram } from '@collimator/model-schemas-ts';
import { useAppSelector } from 'app/hooks';
import { getCurrentlyEditingModelFromState } from 'app/modelState/ModelState';
import React from 'react';

// this walks only the specified diagram, not its subdiagrams or submodels
const listAcausalDomains = (diagram: ModelDiagram | null): Set<string> =>
  (diagram?.nodes || []).reduce((acc, node) => {
    if (node.type.startsWith('acausal.')) {
      const suffix = node.type.substring('acausal.'.length).indexOf('.');
      acc.add(
        node.type.substring('acausal.'.length, 'acausal.'.length + suffix),
      );
    }
    return acc;
  }, new Set<string>());

export function useCurrentDiagramAcausalInfo() {
  const currentDiagram = useAppSelector((state) =>
    getCurrentlyEditingModelFromState(state.model.present),
  );

  const visibleAcausalDomains = React.useMemo(
    () => listAcausalDomains(currentDiagram),
    [currentDiagram],
  );

  return {
    visibleAcausalDomains,
  };
}
