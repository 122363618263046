export enum FieldDataKind {
  Input = 1,
  Checkbox = 2,
}

// NOTE: This is informational only
export enum UnderlyingOptimizerPackage {
  SCIPY = 'scipy',
  OPTAX = 'optax',
  EVOSAX = 'evosax',
}

export type AlgoFieldData = {
  name: string;
  label: string;
  kind?: FieldDataKind;
  default?: string;
  placeholder?: string;
};

export type AlgoSchema = {
  [key: string]: {
    label: string;
    pkg: UnderlyingOptimizerPackage;
    mainFields: Array<AlgoFieldData>;
    settingsFields: Array<AlgoFieldData>;
    // true if bounds are supported for design parameters
    supportsBounds?: boolean;
    // see wildcat/collimator/optimization/ui_jobs.py
    supportsConstraints?: boolean;
    // not supported from UI at the moment
    hidden?: boolean;
  };
};

// Ideally, keep the defaults in sync with wildcat/simcluster defaults.

const LEARNING_RATE = {
  name: 'learning_rate',
  label: 'Learning rate',
  default: '0.003',
};

const NUM_EPOCHS = {
  name: 'num_epochs',
  label: 'Number of epochs',
  kind: FieldDataKind.Input,
  default: '1000',
};

const CLIP_RANGE = {
  name: 'gradients_clip_range',
  label: 'Clip gradients to range (min, max)',
  kind: FieldDataKind.Input,
  placeholder: 'None',
};

const POP_SIZE = {
  name: 'pop_size',
  label: 'Population size',
  kind: FieldDataKind.Input,
  default: '50',
};

const NUM_GENERATIONS = {
  name: 'num_generations',
  label: 'Number of generations',
  kind: FieldDataKind.Input,
  default: '50',
};

const MAX_ITERATIONS = {
  name: 'maxiter',
  label: 'Max iterations',
  kind: FieldDataKind.Input,
  default: '20',
};

const TOLERANCE = {
  name: 'tol',
  label: 'Tolerance',
  kind: FieldDataKind.Input,
  placeholder: 'None',
};

const NESTEROV_MOMENTUM = {
  name: 'nesterov_momentum',
  label: 'Nesterov momentum',
  kind: FieldDataKind.Checkbox,
};

export const BATCH_SIZE = {
  name: 'batch_size',
  label: 'Batch size',
  kind: FieldDataKind.Input,
  default: '10',
};

export const NUM_BATCHES = {
  name: 'num_batches',
  label: 'Number of batches',
  kind: FieldDataKind.Input,
  default: '10',
};

export const OptimizationAlgorithmsFields: AlgoSchema = Object.freeze({
  // Optax
  adam: {
    label: 'ADAM (Local)',
    pkg: UnderlyingOptimizerPackage.OPTAX,
    mainFields: [LEARNING_RATE, NUM_EPOCHS],
    settingsFields: [
      {
        name: 'beta1',
        label: 'Beta 1',
        kind: FieldDataKind.Input,
        default: '0.9',
      },
      {
        name: 'beta2',
        label: 'Beta 2',
        kind: FieldDataKind.Input,
        default: '0.999',
      },
      {
        name: 'epsilon',
        label: 'Epsilon',
        kind: FieldDataKind.Input,
        default: '1e-08',
      },
      {
        name: 'epsilon_root',
        label: 'Epsilon root',
        kind: FieldDataKind.Input,
        default: '0.0',
      },
      NESTEROV_MOMENTUM,
      CLIP_RANGE, // not in figma
    ],
  },
  rmsprop: {
    label: 'RMSProp (Local)',
    pkg: UnderlyingOptimizerPackage.OPTAX,
    mainFields: [LEARNING_RATE, NUM_EPOCHS],
    settingsFields: [
      {
        name: 'decay',
        label: 'Decay',
        kind: FieldDataKind.Input,
        default: '0.9',
      },
      {
        name: 'epsilon',
        label: 'Epsilon',
        kind: FieldDataKind.Input,
        default: '1e-08',
      },
      {
        name: 'initial_scale',
        label: 'Initial scale',
        kind: FieldDataKind.Input,
        default: '0.0',
      },
      { name: 'centered', label: 'Centered', kind: FieldDataKind.Checkbox },
      {
        name: 'momentum',
        label: 'Momentum',
        kind: FieldDataKind.Input,
        default: '0.0',
      },
      NESTEROV_MOMENTUM,
      CLIP_RANGE, // not in figma
    ],
  },
  stochastic_gradient_descent: {
    label: 'Stochastic gradient descent (Local)',
    pkg: UnderlyingOptimizerPackage.OPTAX,
    mainFields: [LEARNING_RATE, NUM_EPOCHS],
    settingsFields: [
      {
        name: 'momentum',
        label: 'Momentum',
        kind: FieldDataKind.Input,
        default: '0.0',
      },
      NESTEROV_MOMENTUM,
      CLIP_RANGE, // not in figma
    ],
  },

  // Scipy
  bfgs: {
    label: 'BFGS (Local 2nd order)',
    pkg: UnderlyingOptimizerPackage.SCIPY,
    mainFields: [MAX_ITERATIONS, TOLERANCE],
    settingsFields: [],
    hidden: true,
  },
  l_bfgs_b: {
    label: 'L-BFGS-B (Local 2nd order)',
    pkg: UnderlyingOptimizerPackage.SCIPY,
    mainFields: [MAX_ITERATIONS, TOLERANCE],
    settingsFields: [],
    supportsBounds: true,
  },
  sequential_least_squares: {
    label: 'Sequential Least Squares (Local 2nd order)',
    pkg: UnderlyingOptimizerPackage.SCIPY,
    mainFields: [MAX_ITERATIONS, TOLERANCE],
    settingsFields: [],
    supportsBounds: true,
    supportsConstraints: true,
  },

  // Evosax
  particle_swarm_optimization: {
    label: 'Particle swarm optimization (Global)',
    pkg: UnderlyingOptimizerPackage.EVOSAX,
    mainFields: [POP_SIZE, NUM_GENERATIONS],
    settingsFields: [],
    supportsBounds: true,
  },
  simulated_annealing: {
    label: 'Simulated annealing (Global)',
    pkg: UnderlyingOptimizerPackage.EVOSAX,
    mainFields: [POP_SIZE, NUM_GENERATIONS],
    settingsFields: [
      {
        name: 'sigma_init',
        label: 'Initial sigma',
        kind: FieldDataKind.Input,
        default: '0.03',
      },
      {
        name: 'sigma_decay',
        label: 'Sigma decay',
        kind: FieldDataKind.Input,
        default: '1.0',
      },
      {
        name: 'sigma_limit',
        label: 'Sigma limit',
        kind: FieldDataKind.Input,
        default: '0.01',
      },
    ],
    supportsBounds: true,
  },
  covariance_matrix_adaptation_evolution_strategy: {
    label: 'CMA-ES (Global)',
    pkg: UnderlyingOptimizerPackage.EVOSAX,
    mainFields: [POP_SIZE, NUM_GENERATIONS],
    settingsFields: [],
    supportsBounds: true,
  },
  genetic_algorithm: {
    label: 'Genetic algorithm (Global)',
    pkg: UnderlyingOptimizerPackage.EVOSAX,
    mainFields: [POP_SIZE, NUM_GENERATIONS],
    settingsFields: [],
    hidden: true,
    supportsBounds: true,
  },
});
