/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  stroke?: any;
  strokeLinecap?: any;
  x?: any;
  y?: any;
  rx?: any;
  cx?: any;
  cy?: any;
  r?: any;
  className?: string;
}

const Unlocked: React.FC<Props> = (props) => (
  <svg viewBox="0 0 16 16" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.5 5.5C10.5 1.5 5.5 1.5 5.5 5.5V7.5"
      stroke={props.stroke}
      strokeLinecap={props.strokeLinecap}
    />
    <rect
      x={props.x}
      y={props.y}
      width={props.width}
      height={props.height}
      rx={props.rx}
      stroke={props.stroke}
    />
    <circle cx={props.cx} cy={props.cy} r={props.r} fill={props.fill} />
    <path d="M8 10V12" stroke={props.stroke} />
  </svg>
);

Unlocked.defaultProps = {
  width: '7',
  height: '6',
  viewBox: '0 0 16 16',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  stroke: '#020606',
  strokeLinecap: 'round',
  x: '4.5',
  y: '7.5',
  rx: '0.5',
  cx: '8',
  cy: '10',
  r: '1',
  className: '',
};

export default Unlocked;
