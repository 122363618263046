import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AuthToken } from 'app/apiGenerated/generatedApiTypes';

interface AuthState {
  xsrfToken: string;
  scopedToken: { [scope: string]: AuthToken };
}

const initialState: AuthState = {
  xsrfToken: '',
  scopedToken: {},
};

const authSlice = createSlice({
  name: 'authSlice',
  initialState,
  reducers: {
    setXsrfToken(state, action: PayloadAction<string>) {
      state.xsrfToken = action.payload;
    },
    setScopedToken(state, action: PayloadAction<AuthToken>) {
      const token = action.payload;
      state.scopedToken[token.scope] = token;
    },
  },
});

export const authActions = authSlice.actions;

export default authSlice;
