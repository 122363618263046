/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const RunAll: React.FC<Props> = (props) => (
  <svg viewBox="0 0 24 24" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M7 6.81435V17.6705L12.2239 12.0553L7 6.81435ZM5.5 6.2147C5.5 5.12476 6.86762 4.58849 7.64874 5.37215L13.4647 11.207C13.9215 11.6653 13.9343 12.3884 13.494 12.8616L7.67809 19.1132C6.91118 19.9376 5.5 19.4115 5.5 18.3012V6.2147Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M11 6.2147C11 5.12476 12.3676 4.58849 13.1487 5.37215L18.9647 11.207C19.4215 11.6653 19.4343 12.3884 18.994 12.8616L13.1781 19.1132C12.4112 19.9376 11 19.4115 11 18.3012V16.7101L12.5 15.1984V17.6705L17.7239 12.0553L12.5 6.81435V8.89462L11 7.43991V6.2147Z"
      fill={props.fill}
    />
  </svg>
);

RunAll.defaultProps = {
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default RunAll;
