import { t } from '@lingui/macro';
import { useSimworkerJobRunner } from 'app/SimworkerJobRunner';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { uiFlagsActions } from 'app/slices/uiFlagsSlice';
import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { MODEL_SOURCE_CODE_VIEWING_QUERY_PARAM } from 'ui/codeEditor/CodeEditor';
import Button from 'ui/common/Button/Button';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import { Code } from 'ui/common/Icons/Standard';
import { useNotifications } from 'ui/common/notifications/useNotifications';
import ModelDiagramDetails from 'ui/modelEditor/ModelDiagramDetails';
import ReferenceSubmodelDiagramDetails from 'ui/modelEditor/ReferenceSubmodelDiagramDetails';
import SubmodelPortDetails from 'ui/modelEditor/SubmodelPortDetails';
import InitializationScriptDetails from './InitializationScriptDetails';
import ModelParameterDetails from './ModelParameterDetails';
import ParameterDefinitionDetails from './ParameterDefinitionDetails';

interface Props {
  projectId: string;
  modelId: string;
  canEditCurrentModelVersion: boolean;
}

const ModelDetails: React.FC<Props> = ({
  modelId,
  projectId,
  canEditCurrentModelVersion,
}) => {
  const { developerModeEnabled } = useAppSelector(
    (state) => state.userOptions.options,
  );
  const { showError } = useNotifications();

  const [searchParams, setSearchParams] = useSearchParams();
  const modelCodeOpen = !!searchParams.get(
    MODEL_SOURCE_CODE_VIEWING_QUERY_PARAM,
  );

  const dispatch = useAppDispatch();
  const { runGenerateModelPythonCode } = useSimworkerJobRunner();

  const topLevelModelType = useAppSelector(
    (state) => state.submodels.topLevelModelType,
  );

  const navigateToModelCode = async (consecutive?: boolean) => {
    if (modelCodeOpen) {
      searchParams.delete(MODEL_SOURCE_CODE_VIEWING_QUERY_PARAM);
      setSearchParams(searchParams);
      return;
    }

    searchParams.set(MODEL_SOURCE_CODE_VIEWING_QUERY_PARAM, 'model_level');
    setSearchParams(searchParams);

    try {
      dispatch(
        uiFlagsActions.setUIFlag({
          modelSourceCodeCache: 'Loading model source code...',
        }),
      );

      await runGenerateModelPythonCode(({ code, error }) => {
        if (error) {
          dispatch(uiFlagsActions.setUIFlag({ modelSourceCodeCache: error }));
        } else {
          dispatch(uiFlagsActions.setUIFlag({ modelSourceCodeCache: code }));
        }
      });
    } catch (e) {
      searchParams.delete(MODEL_SOURCE_CODE_VIEWING_QUERY_PARAM);
      setSearchParams(searchParams);

      dispatch(uiFlagsActions.setUIFlag({ modelSourceCodeCache: undefined }));

      console.warn(e);
      if (!consecutive) {
        setTimeout(() => navigateToModelCode(true), 500);
      } else {
        showError(
          'Python is not ready yet (or model contained unsupported blocks). Please wait just a moment and try again.',
        );
      }
    }
  };

  const codeViewButton = developerModeEnabled && (
    <Button
      variant={ButtonVariants.SmallTertiary}
      onClick={() => navigateToModelCode()}
      Icon={Code}>
      {modelCodeOpen ? t`Hide model code` : t`Show model code`}
    </Button>
  );

  return (
    <>
      {topLevelModelType === 'Model' && (
        <>
          <ModelDiagramDetails
            projectId={projectId}
            modelId={modelId}
            codeViewButton={codeViewButton}
          />
          <InitializationScriptDetails />
          <ModelParameterDetails />
        </>
      )}
      {topLevelModelType === 'Submodel' && (
        <>
          <ReferenceSubmodelDiagramDetails
            projectId={projectId}
            submodelId={modelId}
            canEdit={canEditCurrentModelVersion}
            codeViewButton={codeViewButton}
          />
          <ParameterDefinitionDetails canEdit={canEditCurrentModelVersion} />
          <SubmodelPortDetails
            canEditCurrentModelVersion={canEditCurrentModelVersion}
          />
          <InitializationScriptDetails />
        </>
      )}
    </>
  );
};

export default ModelDetails;
