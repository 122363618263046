import { RENDERER_EXPERIMENTAL_ENABLE_CANVAS2D } from 'app/config/globalApplicationConfig';
import * as NVG from 'nanovg-js';
import { DrawContext, getDrawContextForCanvas2dCtx } from './drawPrimitives';

export enum RasterLoadState {
  Loading,
  Loaded,
  Failed,
}

export type RasterMetaType =
  | {
      loadState: RasterLoadState.Loading | RasterLoadState.Failed;
    }
  | {
      id: string;
      imageId: number;
      width: number;
      height: number;
      preZoom: number;
      loadState: RasterLoadState.Loaded;
      canvas2dImage?: HTMLImageElement;
      canvas2dImageByColor?: Record<string, HTMLImageElement>;
      canvas2dPattern?: CanvasPattern | null;
    };

export type RasterMetaStoreType = {
  [key: string]: RasterMetaType | undefined;
};

export const rasterMetaStore: RasterMetaStoreType = {};

export const loadArrayBufferPromise = async (
  url: string,
): Promise<ArrayBuffer | undefined> =>
  fetch(url).then((response) => {
    const contentType = response.headers.get('Content-Type') || '';
    const isImage = contentType.indexOf('image') > -1;

    if (isImage && response.body) {
      const reader = response.body.getReader();

      const streamResponse = new Response(
        new ReadableStream({
          async start(controller) {
            let reading = true;
            while (reading) {
              // eslint-disable-next-line
              const { done, value } = await reader.read();

              if (done) {
                reading = false;
              } else {
                controller.enqueue(value);
              }
            }

            controller.close();
            reader.releaseLock();
          },
        }),
      );

      return streamResponse.arrayBuffer();
    }

    return undefined;
  });

export const allocMemImageAndIntoStoreFromImageBuffer = (
  nvg: NVG.Context,
  imageBuffer: ArrayBuffer,
  id: string,
  preZoom = 1,
) => {
  const imageData = new Uint8Array(imageBuffer);
  const imageId = nvg.createImageMem(
    NVG.NVGimageFlags.PREMULTIPLIED,
    imageData,
  );

  const width: [number] = [0];
  const height: [number] = [0];
  nvg.imageSize(imageId, width, height);

  const rasterMeta: RasterMetaType = {
    id,
    imageId,
    width: width[0],
    height: height[0],
    preZoom,
    loadState: RasterLoadState.Loaded,
  };

  if (RENDERER_EXPERIMENTAL_ENABLE_CANVAS2D) {
    rasterMeta.canvas2dImage = new Image();
    rasterMeta.canvas2dImage.src = URL.createObjectURL(
      new Blob([imageData], { type: 'image/png' }),
    );
  }

  rasterMetaStore[id] = rasterMeta;
  return rasterMeta;
};

export const loadImageIntoMemAndStore = async (
  nvg: NVG.Context,
  url: string,
  id: string,
  preZoom = 1,
) => {
  rasterMetaStore[id] = {
    loadState: RasterLoadState.Loading,
  };

  const imageBuffer = await loadArrayBufferPromise(url);

  if (!imageBuffer) {
    rasterMetaStore[id] = {
      loadState: RasterLoadState.Failed,
    };

    console.warn(`Failed to load image ID ${id} :`, url);
    return;
  }

  allocMemImageAndIntoStoreFromImageBuffer(nvg, imageBuffer, id, preZoom);
};

export const getOrInitLoadImageFromStore = (
  nvg: NVG.Context,
  url: string,
  id: string,
  preZoom = 1,
) => {
  if (!rasterMetaStore[id]) {
    loadImageIntoMemAndStore(nvg, url, id, preZoom);
  }

  return rasterMetaStore[id];
};

export const deleteImageFromMemAndStore = (nvg: NVG.Context, id: string) => {
  if (rasterMetaStore[id]) {
    const rasterMeta = rasterMetaStore[id];
    if (rasterMeta?.loadState === RasterLoadState.Loaded) {
      nvg.deleteImage(rasterMeta.imageId);
    }

    delete rasterMetaStore[id];
  }
};

export const deleteAllImagesFromMemAndStore = (nvg: NVG.Context) => {
  Object.keys(rasterMetaStore).forEach((id) => {
    deleteImageFromMemAndStore(nvg, id);
  });
};

export const getCanvas2dColoredImage = (
  id: string,
  color: string,
): HTMLImageElement | undefined => {
  if (!RENDERER_EXPERIMENTAL_ENABLE_CANVAS2D) return;

  const rasterMeta = rasterMetaStore[id];
  if (!rasterMeta || rasterMeta.loadState !== RasterLoadState.Loaded) return;

  if (!rasterMeta.canvas2dImageByColor) {
    rasterMeta.canvas2dImageByColor = {};
  }

  if (!rasterMeta.canvas2dImageByColor[color]) {
    const canvas = document.createElement('canvas');
    canvas.width = rasterMeta.width;
    canvas.height = rasterMeta.height;
    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    ctx.drawImage(rasterMeta.canvas2dImage as HTMLImageElement, 0, 0);
    ctx.globalCompositeOperation = 'source-in';
    ctx.fillStyle = color;
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    const newImage = new Image();
    newImage.src = canvas.toDataURL('image/png');
    rasterMeta.canvas2dImageByColor[color] = newImage;
  }

  return rasterMeta.canvas2dImageByColor[color];
};

// HACK: abusing the texture cache here to store custom images
// only used by the canvas2d renderer.
export const getOrRenderCanvasImage = (
  id: string,
  width: number,
  height: number,
  renderFn: (ctx: DrawContext) => void,
  pixelRatio = 1,
): RasterMetaType | undefined => {
  id = `__canvas2d_${id}_${width}x${height}`;
  let rasterMeta = rasterMetaStore[id];

  if (
    !rasterMeta ||
    rasterMeta.loadState !== RasterLoadState.Loaded ||
    !rasterMeta.canvas2dImage
  ) {
    const canvas = document.createElement('canvas');
    canvas.width = width * pixelRatio;
    canvas.height = height * pixelRatio;
    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    const drawCtx = getDrawContextForCanvas2dCtx(ctx, pixelRatio);

    ctx.clearRect(0, 0, width * pixelRatio, height * pixelRatio);
    renderFn(drawCtx);

    const newImage = new Image();
    newImage.src = canvas.toDataURL('image/png');
    newImage.width = width;
    newImage.height = height;

    rasterMeta = {
      id,
      height,
      width,
      imageId: -1,
      preZoom: -1,
      canvas2dImage: newImage,
      loadState: RasterLoadState.Loaded,
    };
  }

  rasterMetaStore[id] = rasterMeta;
  return rasterMeta;
};
