// !!!! -- DO NOT MANUALLY EDIT -- !!!!
// This file was generated by something in scripts/generateDefaultBlockData.js
// If you want to change the contents of this file, it should be automated
//
// (You can edit this file locally, but it may not be checked into the codebase -
// PRs with a manual edit of this file are forbidden from being merged.)

import { computationBlockDefaults } from 'app/generated_blocks/computationBlockDefaults';
import type { ComputationBlockClass } from 'app/generated_types/ComputationBlockClass';
import deepmerge from 'deepmerge';

export const TensorFlow: ComputationBlockClass = deepmerge(
  computationBlockDefaults,
  {
    "base": {
      "name": "TensorFlow",
      "namespace": "core",
      "description": "Prediction (Inference) with TensorFlow SavedModel.",
      "help_url": "https://docs.collimator.ai/using-model-editors/block-library/using-the-predictor-block"
    },
    "modes": {
      "time": "discrete"
    },
    "ports": {
      "inputs": {
        "dynamic": {
          "min_count": 1,
          "default_count": 1,
          "max_count": -1
        }
      },
      "outputs": {
        "dynamic": {
          "min_count": 1,
          "default_count": 1,
          "max_count": -1
        }
      }
    },
    "parameter_definitions": [
      {
        "name": "file_name",
        "data_type": "string",
        "default_value": "",
        "description": "TensorFlow SavedModel zip file to load."
      },
      {
        "name": "add_batch_dim_to_inputs",
        "display_name": "Auto add batch dimension",
        "data_type": "bool",
        "default_value": "false",
        "description": "Automatically add new first dimension to the input tensors for batch processing."
      }
    ]
  }
);
