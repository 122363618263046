/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  className?: string;
}

const EdgedetectorRising: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4 22.75C3.58579 22.75 3.25 22.4142 3.25 22C3.25 21.5858 3.58579 21.25 4 21.25H15.25V15.8107L13.5303 17.5303C13.2374 17.8232 12.7626 17.8232 12.4697 17.5303C12.1768 17.2374 12.1768 16.7626 12.4697 16.4697L15.1161 13.8232C15.1586 13.7808 15.2033 13.742 15.25 13.7069V10.5C15.25 9.80964 15.8096 9.25 16.5 9.25H28C28.4142 9.25 28.75 9.58579 28.75 10C28.75 10.4142 28.4142 10.75 28 10.75H16.75V13.7069C16.7967 13.742 16.8414 13.7808 16.8839 13.8232L19.5303 16.4697C19.8232 16.7626 19.8232 17.2374 19.5303 17.5303C19.2374 17.8232 18.7626 17.8232 18.4697 17.5303L16.75 15.8107V21.5C16.75 22.1904 16.1904 22.75 15.5 22.75H4Z"
      fill={props.fill}
    />
  </svg>
);

EdgedetectorRising.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  className: '',
};

export default EdgedetectorRising;
