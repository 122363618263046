// !!!! -- DO NOT MANUALLY EDIT -- !!!!
// This file was generated by something in scripts/generateDefaultBlockData.js
// If you want to change the contents of this file, it should be automated
//
// (You can edit this file locally, but it may not be checked into the codebase -
// PRs with a manual edit of this file are forbidden from being merged.)

import type { ComputationBlockClass } from 'app/generated_types/ComputationBlockClass';

export const computationBlockDefaults: ComputationBlockClass = {
  "base": {
    "name": "NamelessBlockClass",
    "namespace": "core",
    "keywords": [],
    "feature_level": "all",
    "hidden": false,
    "supports_models": true,
    "supports_submodels": true,
    "supports_iterator": true
  },
  "modes": {
    "time": "agnostic"
  },
  "ports": {
    "inputs": {
      "conditional": [],
      "static": []
    },
    "outputs": {
      "conditional": [],
      "static": []
    },
    "has_automatic_ports": false
  },
  "parameter_definitions": [],
  "events": {
    "count": 0
  },
  "ui_defaults": {
    "grid_height": 0,
    "grid_width": 0
  }
};
