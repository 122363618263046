// ! This is a HACK
// Uses the editor overlay to display custom cell content with custom behavior.
// Based off the DrilldownCell: https://github.com/glideapps/glide-data-grid/blob/c9e1f3df5d545171ccca2226c0c55e253fb6cc83/packages/core/src/data-grid-overlay-editor/private/drilldown-overlay-editor.tsx
// Proper implementation of custom cell renderers require html canvas. Also would lose the SPA nav functionality.
// See UserProfileCell for public api example: https://github.com/glideapps/glide-data-grid/blob/main/packages/cells/src/cells/user-profile-cell.tsx
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import {
  DrilldownCell,
  DrilldownCellData,
  GridCell,
  GridCellKind,
} from '@glideapps/glide-data-grid';
import { RequirementBlock } from 'app/apiGenerated/generatedApiTypes';
import { Link } from 'react-router-dom';
import { ArrowRight, Submodel } from 'ui/common/Icons/Standard';
import { BlockNavState } from '../blockNav';

type BlockData = DrilldownCellData & RequirementBlock;

const RequirementBlockLink = styled(Link)`
  text-decoration: none;

  &:not(:last-child) {
    margin-bottom: 4px;
  }
  width: max-content;
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 0 2px;
  height: 24px;

  background-color: ${({ theme }) => theme.colors.grey[2]};
  color: ${({ theme }) => theme.colors.text.primary};
  margin: 2px;

  border-radius: 2px;
  border: 1px solid ${({ theme }) => theme.colors.grey[10]};
`;

const RequirementBlockViewer = ({ cell }: { cell: DrilldownCell }) => {
  const theme = useTheme();

  return (
    // Vertically display the blocks when there's multiple. The varying length makes horizontal seeking difficult.
    <div>
      {cell.data.map((value) => {
        // TODO: move the custom block data into a custom renderer for type safety
        const block = value as BlockData;
        const blockNavState: BlockNavState = {
          block_instance_uuid: block.block_instance_uuid,
        };
        return (
          <RequirementBlockLink
            title="Navigate to document containing this block. TODO: nav directly to block."
            key={block.id}
            state={blockNavState}
            to={`/projects/${block.project_uuid}/models/${block.model_id}`}>
            <Submodel fill={theme.colors.text.secondary} />
            {block.block_name}
            <ArrowRight fill={theme.colors.grey[30]} />
          </RequirementBlockLink>
        );
      })}
    </div>
  );
};
/**
 * Unused props since we're not actually editing.
 * Still necessary to match the interface.
 */
interface UnusedEditorProps {
  readonly onChange: (newValue: GridCell) => void;
  readonly onFinishedEditing: (newValue?: GridCell) => void;
  readonly isHighlighted: boolean;
  readonly value: GridCell;
}

/**
 * Hijacks the editor to display clickable blocks.
 * Only returns display for the `Satisfied by` column.
 */
export const provideRequirementBlockViewer =
  (cell: GridCell) => (_: UnusedEditorProps) => {
    if (cell.kind === GridCellKind.Drilldown) {
      return <RequirementBlockViewer cell={cell} />;
    }
    return null;
  };
