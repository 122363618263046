/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  className?: string;
}

const DiscretepidI: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1 4.75C1.13807 4.75 1.25 4.86193 1.25 5V25.7927C1.32819 25.765 1.41234 25.75 1.5 25.75H2.75V24.75C2.75 24.1977 3.19772 23.75 3.75 23.75H4.75V11.75C4.75 11.1977 5.19771 10.75 5.75 10.75H6.75V6.25C6.75 5.69772 7.19771 5.25 7.75 5.25H9.25C9.80229 5.25 10.25 5.69772 10.25 6.25H11.25C11.8023 6.25 12.25 6.69772 12.25 7.25V8.75H13.25C13.8023 8.75 14.25 9.19772 14.25 9.75H14.75C14.75 9.19772 15.1977 8.75 15.75 8.75H16.8838C17.0567 8.4511 17.3799 8.25 17.75 8.25H30C30.4142 8.25 30.75 8.58579 30.75 9C30.75 9.41421 30.4142 9.75 30 9.75H18.1162C17.9433 10.0489 17.6201 10.25 17.25 10.25H16.25C16.25 10.8023 15.8023 11.25 15.25 11.25H13.75C13.1977 11.25 12.75 10.8023 12.75 10.25H11.75C11.1977 10.25 10.75 9.80228 10.75 9.25V7.75H9.75C9.19772 7.75 8.75 7.30228 8.75 6.75H8.25V11.25C8.25 11.8023 7.80228 12.25 7.25 12.25H6.25V24.25C6.25 24.8023 5.80228 25.25 5.25 25.25H4.25V26.25C4.25 26.4321 4.2013 26.6029 4.11622 26.75H31C31.1381 26.75 31.25 26.8619 31.25 27C31.25 27.1381 31.1381 27.25 31 27.25H0.75V5C0.75 4.86193 0.861929 4.75 1 4.75Z"
      fill={props.fill}
    />
    <path
      d="M18.4497 24C18.4097 24 18.3737 23.988 18.3417 23.964C18.3177 23.932 18.3057 23.896 18.3057 23.856V15.744C18.3057 15.704 18.3177 15.672 18.3417 15.648C18.3737 15.616 18.4097 15.6 18.4497 15.6H19.5537C19.5937 15.6 19.6257 15.616 19.6497 15.648C19.6817 15.672 19.6977 15.704 19.6977 15.744V23.856C19.6977 23.896 19.6817 23.932 19.6497 23.964C19.6257 23.988 19.5937 24 19.5537 24H18.4497Z"
      fill={props.fill}
    />
  </svg>
);

DiscretepidI.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  className: '',
};

export default DiscretepidI;
