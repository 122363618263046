import styled from '@emotion/styled/macro';
import { t } from '@lingui/macro';
import { useProjectPermissions } from 'app/api/useProjectPermissions';
import { Project } from 'app/apiTransformers/convertAPIProjectToProject';
import { ProjectPermission } from 'app/apiTransformers/convertGetProjectPermissionReadAll';
import React from 'react';
import Button from 'ui/common/Button/Button';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import { Properties } from 'ui/common/Icons/Standard';
import { useModal } from 'ui/common/Modal/useModal';
import { Spinner, SpinnerWrapper } from 'ui/common/Spinner';
import {
  ProjectRightSidebarHeading,
  SectionH4,
} from '../projectDetail/rightSidebar/ProjectRightSidebar';
import ManageMembersModal from './ManageMembersModal';
import MemberItem from './MemberItem';

const MembersListWrapper = styled.div`
  padding-right: ${({ theme }) => theme.spacing.normal};
`;

interface Props {
  project: Project;
}

const MembersSection: React.FC<Props> = ({ project }) => {
  const { projectPermissions, isLoadingProjectPermissions } =
    useProjectPermissions(project.uuid);

  const { triggerModal } = useModal();

  const openMembersDialog = () => {
    triggerModal(
      <ManageMembersModal projectId={project.uuid} />,
      t({
        id: 'projects.manageMembersModalDialog.title',
        message: 'Manage members',
      }),
    );
  };

  return (
    <div>
      <ProjectRightSidebarHeading>
        <SectionH4>
          {t({
            id: 'dashboard.rightSidebar.members.title',
            message: 'Members',
          })}
        </SectionH4>

        <Button
          variant={ButtonVariants.LargeTertiary}
          Icon={Properties}
          onClick={openMembersDialog}
          test-id="open-members-dialog-button"
        />
      </ProjectRightSidebarHeading>

      {isLoadingProjectPermissions && (
        <SpinnerWrapper>
          <Spinner />
        </SpinnerWrapper>
      )}
      <MembersListWrapper>
        {projectPermissions &&
          projectPermissions.map((permission: ProjectPermission) => (
            <MemberItem
              key={permission.id}
              memberRole={permission.role}
              memberName={permission.memberName}
              email={permission.email}
              memberProfileImageUrl={permission.memberProfileImageUrl}
            />
          ))}
      </MembersListWrapper>
    </div>
  );
};

export default MembersSection;
