// !!!! -- DO NOT MANUALLY EDIT -- !!!!
// This file was generated by something in scripts/generateDefaultBlockData.js
// If you want to change the contents of this file, it should be automated
//
// (You can edit this file locally, but it may not be checked into the codebase -
// PRs with a manual edit of this file are forbidden from being merged.)

import { computationBlockDefaults } from 'app/generated_blocks/computationBlockDefaults';
import type { ComputationBlockClass } from 'app/generated_types/ComputationBlockClass';
import deepmerge from 'deepmerge';

export const VideoSource: ComputationBlockClass = deepmerge(
  computationBlockDefaults,
  {
    "base": {
      "name": "VideoSource",
      "namespace": "core",
      "description": "Loads a video file and outputs it frame by frame. Output is a tensor of shape (H,W,3) in RGB color space.",
      "help_url": "https://docs.collimator.ai/using-model-editors/block-library/discrete-blocks#videosource",
      "feature_level": "dev"
    },
    "modes": {
      "time": "discrete"
    },
    "ports": {
      "outputs": {
        "static": [
          {
            "name": "frame"
          },
          {
            "name": "frame_id"
          }
        ],
        "conditional": [
          {
            "name": "stopped",
            "condition_bool_parameter": "no_repeat",
            "order": 2
          }
        ]
      }
    },
    "parameter_definitions": [
      {
        "name": "file_name",
        "description": "Name of the video file to play",
        "data_type": "file",
        "file_formats": [
          "*.avi",
          "*.mp4",
          "*.mpeg4",
          "*.webp"
        ],
        "default_value": ""
      },
      {
        "name": "no_repeat",
        "description": "If true, the video will stop after playing once. If false, the video will loop indefinitely.",
        "data_type": "bool",
        "default_value": "false"
      }
    ]
  }
);
