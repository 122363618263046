/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Properties: React.FC<Props> = (props) => (
  <svg viewBox="0 0 24 24" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M6.75 9C5.50736 9 4.5 7.99264 4.5 6.75C4.5 5.50736 5.50736 4.5 6.75 4.5C7.99264 4.5 9 5.50736 9 6.75C9 7.99264 7.99264 9 6.75 9ZM6.75 7.5C6.33579 7.5 6 7.16421 6 6.75C6 6.33579 6.33579 6 6.75 6C7.16421 6 7.5 6.33579 7.5 6.75C7.5 7.16421 7.16421 7.5 6.75 7.5Z"
      fill={props.fill}
    />
    <path
      d="M19.5 7.5C19.9142 7.5 20.25 7.16421 20.25 6.75C20.25 6.33579 19.9142 6 19.5 6L11.25 6C10.8358 6 10.5 6.33579 10.5 6.75C10.5 7.16421 10.8358 7.5 11.25 7.5L19.5 7.5Z"
      fill={props.fill}
    />
    <path
      d="M19.5 12.75C19.9142 12.75 20.25 12.4142 20.25 12C20.25 11.5858 19.9142 11.25 19.5 11.25L11.25 11.25C10.8358 11.25 10.5 11.5858 10.5 12C10.5 12.4142 10.8358 12.75 11.25 12.75L19.5 12.75Z"
      fill={props.fill}
    />
    <path
      d="M20.25 17.25C20.25 17.6642 19.9142 18 19.5 18H11.25C10.8358 18 10.5 17.6642 10.5 17.25C10.5 16.8358 10.8358 16.5 11.25 16.5H19.5C19.9142 16.5 20.25 16.8358 20.25 17.25Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M4.5 12C4.5 13.2426 5.50736 14.25 6.75 14.25C7.99264 14.25 9 13.2426 9 12C9 10.7574 7.99264 9.75 6.75 9.75C5.50736 9.75 4.5 10.7574 4.5 12ZM6 12C6 12.4142 6.33579 12.75 6.75 12.75C7.16421 12.75 7.5 12.4142 7.5 12C7.5 11.5858 7.16421 11.25 6.75 11.25C6.33579 11.25 6 11.5858 6 12Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M6.75 19.5C5.50736 19.5 4.5 18.4926 4.5 17.25C4.5 16.0074 5.50736 15 6.75 15C7.99264 15 9 16.0074 9 17.25C9 18.4926 7.99264 19.5 6.75 19.5ZM6.75 18C6.33579 18 6 17.6642 6 17.25C6 16.8358 6.33579 16.5 6.75 16.5C7.16421 16.5 7.5 16.8358 7.5 17.25C7.5 17.6642 7.16421 18 6.75 18Z"
      fill={props.fill}
    />
  </svg>
);

Properties.defaultProps = {
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Properties;
