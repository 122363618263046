import { useAppSelector } from 'app/hooks';
import { SimulationSignalsRequest } from 'app/slices/simulationSignalsSlice';
import React from 'react';
import { DataExplorerSimulationSignalsLoader } from 'ui/dataExplorer/loaders/DataExplorerSimulationSignalsLoader';

/**
 * Loader that notices which signals have been requested by the user
 * (i.e. when the user expands the simulation item in the simulation data tree),
 * and makes sure the simulation signal data is loaded and processed exactly once,
 * and then stored in redux for whenever it is needed.
 */
export const DataExplorerSimulationSignalsListLoader: React.FC = () => {
  const simulationIdToSignalsRequest = useAppSelector(
    (state) => state.simulationSignals.simulationIdToSignalsRequest,
  );

  const requestDataItems: SimulationSignalsRequest[] = [];
  Object.values(simulationIdToSignalsRequest).forEach(
    (requestData: SimulationSignalsRequest) => {
      requestDataItems.push(requestData);
    },
  );

  return (
    <>
      {requestDataItems.map((requestData: SimulationSignalsRequest) => (
        <DataExplorerSimulationSignalsLoader
          key={requestData.simulationId}
          modelId={requestData.modelId}
          simulationId={requestData.simulationId}
        />
      ))}
    </>
  );
};
