import { ModelConfiguration } from '@collimator/model-schemas-ts';
import styled from '@emotion/styled/macro';
import { t } from '@lingui/macro';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { modelActions } from 'app/slices/modelSlice';
import { buildNumericalChange } from 'app/utils/modelDataUtils';
import React from 'react';
import { Information } from 'ui/common/Icons/Standard';
import { TextInputAlign } from 'ui/common/Input/inputTypes';
import { isPositiveNumberRule } from 'ui/common/Input/inputValidation';
import SectionHeading from 'ui/common/Inputs/SectionHeading';
import SelectInput from 'ui/common/SelectInput';
import { useModelPermission } from 'ui/permission/useModelPermission';
import { useAppParams } from 'util/useAppParams';
import {
  DetailInputRowsSection,
  DetailsInput,
  DetailsLabel,
  DetailsSingleRow,
} from './DetailsComponents';

const ParameterWrapper = styled.div`
  margin: ${({ theme }) => `${theme.spacing.small}`} 0;
`;

const outputModeOptions: {
  value: NonNullable<ModelConfiguration['sim_output_mode']>;
  label: string;
}[] = [
  {
    value: 'auto',
    label: 'Automatic',
  },
];

const devOnlyOutputModeOptions: {
  value: NonNullable<ModelConfiguration['sim_output_mode']>;
  label: string;
}[] = [
  {
    value: 'auto',
    label: 'Automatic',
  },
  {
    value: 'discrete_steps_only',
    label: 'Discrete steps only (dev only)',
  },
  {
    value: 'fixed_interval',
    label: 'Fixed interval (dev only)',
  },
];

const SimulationOutputDetails: React.FC = () => {
  const developerModeEnabled = useAppSelector(
    (state) => state.userOptions.options.developerModeEnabled,
  );
  const { projectId, modelId, versionId } = useAppParams();
  const { canEditCurrentModelVersion } = useModelPermission(
    projectId,
    modelId,
    versionId,
  );

  const dispatch = useAppDispatch();
  const configuration = useAppSelector(
    (state) => state.model.present.configuration,
  );

  const changeOutputMode = (value: string) => {
    dispatch(
      modelActions.changeModelConfigurationValue({
        name: 'sim_output_mode',
        value:
          value === 'auto'
            ? 'auto'
            : value === 'discrete_steps_only'
            ? 'discrete_steps_only'
            : 'fixed_interval',
      }),
    );
  };

  const disableIntervalInput =
    !canEditCurrentModelVersion ||
    configuration.sim_output_mode === 'discrete_steps_only';
  const showFixedResultsInterval =
    configuration.sim_output_mode === 'fixed_interval';
  const showMaxResultsInterval =
    configuration.sim_output_mode === 'auto' ||
    configuration.sim_output_mode === 'discrete_steps_only';

  return (
    <>
      <SectionHeading
        ButtonIcon={Information}
        linkUrl="https://docs.collimator.ai/running-simulations/simulation-and-output-settings#output-settingss"
        testId="simulation-settings">
        {t({
          id: 'modelRenderer.simulationOutputDetails.heading',
          message: 'Simulation Output',
        })}
      </SectionHeading>
      <DetailInputRowsSection>
        <ParameterWrapper>
          <SelectInput
            options={
              developerModeEnabled
                ? devOnlyOutputModeOptions
                : outputModeOptions
            }
            currentValue={configuration.sim_output_mode || 'auto'}
            onSelectValue={changeOutputMode}
            isDisabled={!(developerModeEnabled && canEditCurrentModelVersion)}
          />
        </ParameterWrapper>

        {showMaxResultsInterval && (
          <ParameterWrapper>
            <DetailsSingleRow noMargin>
              <DetailsLabel stretch disabled={disableIntervalInput}>
                {t({
                  id: 'modelRenderer.simulationOutputDetails.maxResultsInterval.label',
                  message: 'Max. results interval',
                })}
              </DetailsLabel>
              <DetailsInput
                onSubmitValue={(value: string) =>
                  dispatch(
                    modelActions.updateModelConfiguration({
                      ...configuration,
                      max_results_interval: buildNumericalChange(value),
                    }),
                  )
                }
                onClickRightIcon={() =>
                  dispatch(
                    modelActions.updateModelConfiguration({
                      ...configuration,
                      max_results_interval: undefined,
                    }),
                  )
                }
                align={TextInputAlign.Right}
                hasBorder
                grow
                validationRules={[isPositiveNumberRule]}
                value={
                  configuration.max_results_interval
                    ? configuration.max_results_interval
                    : ''
                }
                placeholder="Auto"
                disabled={disableIntervalInput}
                rightIconIsResetButton
                defaultValue=""
              />
            </DetailsSingleRow>
          </ParameterWrapper>
        )}

        {showFixedResultsInterval && (
          <ParameterWrapper>
            <DetailsSingleRow noMargin>
              <DetailsLabel stretch disabled={disableIntervalInput}>
                {t({
                  id: 'modelRenderer.simulationOutputDetails.fixedResultsInterval.label',
                  message: 'Fixed results interval',
                })}
              </DetailsLabel>
              <DetailsInput
                onSubmitValue={(value: string) =>
                  dispatch(
                    modelActions.updateModelConfiguration({
                      ...configuration,
                      fixed_results_interval: buildNumericalChange(value),
                    }),
                  )
                }
                onClickRightIcon={() =>
                  dispatch(
                    modelActions.updateModelConfiguration({
                      ...configuration,
                      fixed_results_interval: undefined,
                    }),
                  )
                }
                align={TextInputAlign.Right}
                hasBorder
                grow
                validationRules={[isPositiveNumberRule]}
                value={
                  configuration.fixed_results_interval
                    ? configuration.fixed_results_interval
                    : ''
                }
                placeholder="Auto"
                disabled={disableIntervalInput}
                rightIconIsResetButton
                defaultValue=""
              />
            </DetailsSingleRow>
          </ParameterWrapper>
        )}
      </DetailInputRowsSection>
    </>
  );
};

export default SimulationOutputDetails;
