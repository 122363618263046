/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Videosink: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M4 9C4 8.44771 4.44772 8 5 8H27C27.5523 8 28 8.44771 28 9V23C28 23.5523 27.5523 24 27 24H5C4.44772 24 4 23.5523 4 23V20.5H6L5.5 20.5V22H6.34987C6.92608 22.7564 8.09245 22.9803 8.91264 22.2513L9.5 21.7292V22C9.5 22.2761 9.72386 22.5 10 22.5H22C22.2761 22.5 22.5 22.2761 22.5 22V10C22.5 9.72386 22.2761 9.5 22 9.5H10C9.72386 9.5 9.5 9.72386 9.5 10V12.8787L8.98744 12.3661C7.90969 11.2884 6.08403 12.0104 6.00281 13.5H5.5V14.5H4V9ZM5.5 11.5H8V10H5.5V11.5ZM26.5 10H24V11.5H26.5V10ZM26.5 13.5H24V15H26.5V13.5ZM26.5 17H24V18.5H26.5V17ZM26.5 20.5H24V22H26.5V20.5Z"
      fill={props.fill}
    />
    <path
      d="M8.25 15.75V14.8107L10.9072 17.4679L8.25 19.8299V19.25C8.25 18.6977 7.80228 18.25 7.25 18.25H2V19.75H6.75V20.9433C6.75 21.8061 7.76946 22.2639 8.41436 21.6907L12.3001 18.2367C12.7301 17.8544 12.7497 17.189 12.3428 16.7822L8.45711 12.8964C7.82714 12.2665 6.75 12.7126 6.75 13.6036V15.25H2V16.75H7.25C7.80228 16.75 8.25 16.3023 8.25 15.75Z"
      fill={props.fill}
    />
  </svg>
);

Videosink.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Videosink;
