import styled from '@emotion/styled';
import { t } from '@lingui/macro';
import { useProject } from 'app/api/useProject';
import {
  useGetFileDownloadByUuidQuery,
  useGetFileReadByUuidQuery,
} from 'app/apiGenerated/generatedApi';
import { useAppDispatch } from 'app/hooks';
import { NavbarContext, uiFlagsActions } from 'app/slices/uiFlagsSlice';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Breadcrumb from 'ui/common/Breadcrumb/Breadcrumb';
import Button from 'ui/common/Button/Button';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import { Edit, File, Project } from 'ui/common/Icons/Standard';
import { MarkdownViewer } from 'ui/common/MarkdownViewer';
import { Spinner } from 'ui/common/Spinner';
import { AppContentWithFooterWrapper } from 'ui/common/layout/appLayout';
import { useNotifications } from 'ui/common/notifications/useNotifications';
import { fetchFileContents } from 'util/fileUtils';

const Loader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: absolute;
  background: ${({ theme }) => theme.colors.grey[10]};
  top: 0;
  left: 0;
`;

const ContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.grey['5']};
`;

const TopBar = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  padding: ${({ theme }) => theme.spacing.normal};
  margin-bottom: ${({ theme }) => theme.spacing.normal};
`;

const ButtonWrapper = styled.div`
  pointer-events: all;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
`;

const ViewerWrapper = styled.div`
  width: 900px;
  overflow: auto;
  pointer-events: all;
`;

const Spacer = styled.div`
  flex: 1;
`;

// Supports various text/code files like md, txt, csv and python.
export const MarkdownViewerPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { createShowError } = useNotifications();

  React.useEffect(() => {
    dispatch(uiFlagsActions.setNavbarContext(NavbarContext.ScriptEditor));
    return () => {
      dispatch(uiFlagsActions.setNavbarContext(NavbarContext.None));
    };
  }, [dispatch]);

  const { projectId, fileUuid } = useParams();
  const { project } = useProject();
  const [contents, setContents] = React.useState<string | undefined>();

  const { data: fileProps } = useGetFileReadByUuidQuery(
    {
      projectUuid: projectId || '',
      fileUuid: fileUuid || '',
    },
    { skip: !projectId || !fileUuid },
  );

  const fileName = fileProps?.summary.name;

  const downloadData = useGetFileDownloadByUuidQuery(
    {
      projectUuid: projectId || '',
      fileUuid: fileUuid || '',
    },
    { skip: !projectId || !fileUuid },
  );

  React.useEffect(() => {
    if (!downloadData.data) return;

    fetchFileContents(downloadData.data.download_link, 1 * 1024 * 1024)
      .then(setContents)
      .catch((e) => {
        createShowError(t`Could not load file in viewer:`)(e);
        navigate(`/projects/${projectId}`);
      });
  }, [downloadData.data, createShowError, navigate, projectId]);

  return (
    <AppContentWithFooterWrapper>
      <ContentWrap>
        <TopBar>
          <Breadcrumb stretch>
            <Button
              variant={ButtonVariants.SmallTertiary}
              onClick={() => navigate(`/projects/${projectId}`)}>
              <Project />
              {project?.title}
            </Button>
            <Button variant={ButtonVariants.SmallTertiary}>
              <File />
              {fileName}
            </Button>
          </Breadcrumb>
          <Spacer />
          <ButtonWrapper>
            <Button
              variant={ButtonVariants.LargeSecondary}
              onClick={() =>
                navigate(`/projects/${projectId}/code_editor/${fileUuid}`)
              }>
              <Edit />
              {t`Edit`}
            </Button>
          </ButtonWrapper>
        </TopBar>
        <ContentWrapper>
          <ViewerWrapper>
            {contents ? (
              <MarkdownViewer contents={contents} />
            ) : (
              <Loader>
                <Spinner width="36" height="36" />
              </Loader>
            )}
          </ViewerWrapper>
        </ContentWrapper>
      </ContentWrap>
    </AppContentWithFooterWrapper>
  );
};
