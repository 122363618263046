/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const QuestionMark: React.FC<Props> = (props) => (
  <svg viewBox="0 0 24 24" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.07 14.528C11.1127 14.56 11.1607 14.576 11.214 14.576H12.686C12.7393 14.576 12.782 14.56 12.814 14.528C12.8567 14.4853 12.878 14.4373 12.878 14.384V13.84C12.878 13.552 12.9687 13.312 13.15 13.12C13.3313 12.9173 13.6087 12.6827 13.982 12.416C14.3553 12.1493 14.6487 11.9093 14.862 11.696C15.086 11.4827 15.278 11.2053 15.438 10.864C15.6087 10.5227 15.694 10.1173 15.694 9.64802C15.694 8.74135 15.3847 8.01601 14.766 7.47201C14.1473 6.91735 13.3207 6.64001 12.286 6.64001C11.5927 6.64001 10.9793 6.77335 10.446 7.04001C9.91267 7.29601 9.49667 7.66401 9.198 8.14401C8.89933 8.61335 8.75 9.15201 8.75 9.76001V10C8.75 10.0533 8.766 10.1013 8.798 10.144C8.84067 10.1867 8.88867 10.208 8.942 10.208L10.414 10.272C10.542 10.272 10.606 10.2133 10.606 10.096V9.76001C10.606 9.34401 10.7553 9.00268 11.054 8.73601C11.3527 8.46935 11.7473 8.33601 12.238 8.33601C12.7073 8.33601 13.086 8.46401 13.374 8.72001C13.662 8.96535 13.806 9.29601 13.806 9.71202C13.806 10.0853 13.6993 10.4 13.486 10.656C13.2727 10.9013 12.9527 11.1787 12.526 11.488C12.0353 11.84 11.662 12.176 11.406 12.496C11.15 12.8053 11.022 13.216 11.022 13.728V14.384C11.022 14.4373 11.038 14.4853 11.07 14.528Z"
      fill={props.fill}
    />
    <path
      d="M11.086 17.696C11.2993 17.9093 11.5713 18.016 11.902 18.016C12.222 18.016 12.4887 17.9093 12.702 17.696C12.9153 17.4827 13.022 17.2107 13.022 16.88C13.022 16.5493 12.9153 16.2827 12.702 16.08C12.4993 15.8667 12.2327 15.76 11.902 15.76C11.5713 15.76 11.2993 15.8667 11.086 16.08C10.8727 16.2827 10.766 16.5493 10.766 16.88C10.766 17.2107 10.8727 17.4827 11.086 17.696Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM20.5 12C20.5 16.6944 16.6944 20.5 12 20.5C7.30558 20.5 3.5 16.6944 3.5 12C3.5 7.30558 7.30558 3.5 12 3.5C16.6944 3.5 20.5 7.30558 20.5 12Z"
      fill={props.fill}
    />
  </svg>
);

QuestionMark.defaultProps = {
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default QuestionMark;
