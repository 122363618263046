import { SIGNAL_COLORS } from '../ui/dataExplorer/charts/constants';

export enum ChartPosition {
  UP,
  RIGHT,
  DOWN,
  LEFT,
  SELF,
}

export function assignFreeColor(usedColors: string[]) {
  const available = SIGNAL_COLORS.filter((c) => !usedColors.includes(c));
  return available[0];
}
