/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const ChevronLeft: React.FC<Props> = (props) => (
  <svg viewBox="0 0 16 16" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M9.79647 3.70811C10.0678 3.98559 10.0678 4.43547 9.79647 4.71294L6.5817 8L9.79647 11.2871C10.0678 11.5645 10.0678 12.0144 9.79647 12.2919C9.52509 12.5694 9.0851 12.5694 8.81373 12.2919L5.27138 8.66989C4.90954 8.29992 4.90954 7.70008 5.27138 7.33011L8.81373 3.70811C9.0851 3.43063 9.52509 3.43063 9.79647 3.70811Z"
      fill={props.fill}
    />
  </svg>
);

ChevronLeft.defaultProps = {
  width: '16',
  height: '16',
  viewBox: '0 0 16 16',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default ChevronLeft;
