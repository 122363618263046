/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const CreateSubmodel: React.FC<Props> = (props) => (
  <svg viewBox="0 0 24 24" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M11.6838 5.31485C11.889 5.24643 12.111 5.24643 12.3162 5.31485L18.0662 7.23152C18.4746 7.36763 18.75 7.74977 18.75 8.1802V13.5245C18.75 13.6971 18.5785 13.8182 18.4088 13.787C18.2762 13.7627 18.1396 13.75 18 13.75C17.8604 13.75 17.7238 13.7627 17.5912 13.787C17.4215 13.8182 17.25 13.6971 17.25 13.5245V9.04058L12.75 10.5406V16.9594L13.4027 16.7419C13.6533 16.6583 13.9416 17.0523 13.8599 17.3035C13.7886 17.5228 13.75 17.7569 13.75 18L13.75 18.0067C13.7503 18.1226 13.6815 18.2301 13.5715 18.2668L12.3162 18.6852C12.111 18.7536 11.889 18.7536 11.6838 18.6852L5.93377 16.7685C5.52543 16.6324 5.25 16.2503 5.25 15.8198V8.1802C5.25 7.74977 5.52543 7.36763 5.93377 7.23152L11.6838 5.31485ZM6.75 9.04058L11.25 10.5406V16.9594L6.75 15.4594V9.04058ZM12 9.20944L15.6283 8.00001L12 6.79058L8.37171 8.00001L12 9.20944Z"
      fill={props.fill}
    />
    <path
      d="M18.75 16C18.75 15.5858 18.4142 15.25 18 15.25C17.5858 15.25 17.25 15.5858 17.25 16V17.25H16C15.5858 17.25 15.25 17.5858 15.25 18C15.25 18.4142 15.5858 18.75 16 18.75H17.25V20C17.25 20.4142 17.5858 20.75 18 20.75C18.4142 20.75 18.75 20.4142 18.75 20V18.75H20C20.4142 18.75 20.75 18.4142 20.75 18C20.75 17.5858 20.4142 17.25 20 17.25H18.75V16Z"
      fill={props.fill}
    />
  </svg>
);

CreateSubmodel.defaultProps = {
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default CreateSubmodel;
