import { SubmodelFetchItem } from 'app/apiGenerated/generatedApiTypes';
import { SubmodelInfoLiteUI } from 'app/apiTransformers/convertGetSubmodelsList';
import { SubmodelInfoUI } from 'app/apiTransformers/convertGetSubmodelsListForModelParent';
import { VersionTagValues } from 'app/apiTransformers/convertPostSubmodelsFetch';
import {
  LinkInstance,
  ModelDiagram,
  NodeInstance,
  SubmodelInstance,
  SubmodelsSection,
} from 'app/generated_types/SimulationModel';
import { nodeTypeIsSubdiagram } from 'app/helpers';
import { getSubmodelRef } from 'app/sliceRefAccess/SubmodelRef';
import { v4 as makeUuid } from 'uuid';
import { SubmodelFullUI } from '../apiTransformers/convertGetSubmodel';

export function copySubmodelReferencesRecursive_mut(
  currentModelMutableSubmodels: SubmodelsSection,
  overrideModelSubmodelsCopyData: SubmodelsSection | undefined,
  oldSubmodelUuid: string,
  newSubmodelUuid: string,
  referenceSubmodelId?: string,
) {
  // this is necessary because sometimes we need to pull the data to be copied
  // from an arbitrary (not in the model state) set of submodels,
  // e.g. when copying from one model to another.
  // we still need the "currentModelMutableSubmodels" so that we can actually mutate
  // the document state to update with the newly copied data.
  const copyingFromSubmodelSection =
    overrideModelSubmodelsCopyData || currentModelMutableSubmodels;

  const ref = copyingFromSubmodelSection.references[oldSubmodelUuid];
  const diagram = ref
    ? copyingFromSubmodelSection.diagrams[ref.diagram_uuid]
    : undefined;

  if (!diagram) {
    if (!referenceSubmodelId) {
      const diagram_uuid = makeUuid();
      currentModelMutableSubmodels.diagrams[diagram_uuid] = {
        uuid: diagram_uuid,
        nodes: [],
        links: [],
      };
      currentModelMutableSubmodels.references[newSubmodelUuid] = {
        diagram_uuid,
      };
    }
    return;
  }

  const newNodes: NodeInstance[] = [];
  const nodesOldUuidToNewUuid: { [uuid: string]: string } = {};
  for (let i = 0; i < diagram.nodes.length; i++) {
    const oldNode = diagram.nodes[i];
    const newNode = { ...oldNode, uuid: makeUuid() };
    newNodes.push(newNode);
    nodesOldUuidToNewUuid[oldNode.uuid] = newNode.uuid;

    if (nodeTypeIsSubdiagram(oldNode.type)) {
      const submodelInstance = newNode as SubmodelInstance;
      copySubmodelReferencesRecursive_mut(
        currentModelMutableSubmodels,
        overrideModelSubmodelsCopyData,
        oldNode.uuid,
        newNode.uuid,
        submodelInstance?.submodel_reference_uuid,
      );
    }
  }

  const oldToNewLinkUuids: { [k: string]: string } = {};
  for (let j = 0; j < diagram.links.length; j++) {
    oldToNewLinkUuids[diagram.links[j].uuid] = makeUuid();
  }

  const newLinks: LinkInstance[] = [];
  for (let k = 0; k < diagram.links.length; k++) {
    const oldLink = diagram.links[k];

    const oldSrc = oldLink.src?.node;
    const oldDst = oldLink.dst?.node;
    const newSrcUuid = oldSrc && nodesOldUuidToNewUuid[oldSrc];
    const newDstUuid = oldDst && nodesOldUuidToNewUuid[oldDst];

    // Simply spreading the uiprops resulted in modification of oldLink's uiprops
    // Immer gives us deep-references, so we have to be sure to copy data
    // instead of reusing the references when we want a new object.
    const newLink: LinkInstance = JSON.parse(JSON.stringify(oldLink));
    newLink.uuid = oldToNewLinkUuids[oldLink.uuid];

    if (oldLink.src && newSrcUuid) {
      newLink.src = {
        node: newSrcUuid,
        port: oldLink.src.port,
      };
    }

    if (oldLink.dst && newDstUuid) {
      newLink.dst = {
        node: newDstUuid,
        port: oldLink.dst.port,
      };
    }

    if (newLink.uiprops.link_type.connection_method === 'link_tap') {
      newLink.uiprops.link_type.tapped_link_uuid =
        oldToNewLinkUuids[newLink.uiprops.link_type.tapped_link_uuid];
    }

    newLinks.push(newLink);
  }

  if (!referenceSubmodelId) {
    const diagram_uuid = makeUuid();
    currentModelMutableSubmodels.diagrams[diagram_uuid] = {
      uuid: diagram_uuid,
      nodes: newNodes,
      links: newLinks,
    };
    currentModelMutableSubmodels.references[newSubmodelUuid] = { diagram_uuid };
  }
}

export function removeSubmodelReferencesRecursive(
  submodels: SubmodelsSection,
  submodelUuid: string,
) {
  const ref = submodels.references[submodelUuid];
  if (!ref) return;

  const diagram = submodels.diagrams[ref.diagram_uuid];
  if (diagram) {
    for (let k = 0; k < diagram.nodes.length; k++) {
      const node = diagram.nodes[k];
      if (nodeTypeIsSubdiagram(node.type)) {
        removeSubmodelReferencesRecursive(submodels, node.uuid);
      }
    }
  }

  delete submodels.diagrams[ref.diagram_uuid];
  delete submodels.references[submodelUuid];
}

export function getActualVersionId(
  referenceSubmodelId: string,
  versionId: string | undefined,
  updatedIdToLatestTaggedVersionId?: Record<string, string>,
): string | undefined {
  // If we've loaded the latest tagged version for this submodel, look up the actual version id.
  // If it is not available, then we need to wait for the version to load.
  if (versionId === VersionTagValues.LATEST_TAGGED_VERSION) {
    const idToLatestTaggedVersionId =
      updatedIdToLatestTaggedVersionId ||
      getSubmodelRef().idToLatestTaggedVersionId;
    return idToLatestTaggedVersionId[referenceSubmodelId] || undefined;
  }
  return versionId || undefined;
}

export function getSpecificReferenceSubmodelById<
  TSubmodel extends SubmodelInfoLiteUI,
>(
  referenceSubmodelId: string,
  versionId: string,
  idToVersionIdToSubmodel: Record<string, Record<string, TSubmodel>>,
  updatedIdToLatestTaggedVersionId?: Record<string, string>,
): TSubmodel | undefined {
  if (!referenceSubmodelId) {
    return;
  }

  const versionIdToSubmodel = idToVersionIdToSubmodel[referenceSubmodelId];
  if (versionIdToSubmodel) {
    const actualVersionId = getActualVersionId(
      referenceSubmodelId,
      versionId,
      updatedIdToLatestTaggedVersionId,
    );
    if (actualVersionId) {
      return versionIdToSubmodel[actualVersionId];
    }
  }
}

export function getReferenceSubmodelById(
  referenceSubmodelId: string,
  versionId: string,
): SubmodelFullUI | undefined {
  if (!referenceSubmodelId) {
    return;
  }

  const versionIdToSubmodel =
    getSubmodelRef().idToVersionIdToSubmodelFull[referenceSubmodelId];
  if (versionIdToSubmodel) {
    const actualVersionId = getActualVersionId(referenceSubmodelId, versionId);
    if (actualVersionId) {
      return versionIdToSubmodel[actualVersionId];
    }
  }
}

export function getSpecificReferenceSubmodelByNode<
  TSubmodel extends SubmodelInfoLiteUI,
>(
  submodelInstance: SubmodelInstance,
  idToVersionIdToSubmodel: Record<string, Record<string, TSubmodel>>,
  idToLatestTaggedVersionId?: Record<string, string>,
): TSubmodel | undefined {
  if (!idToVersionIdToSubmodel) {
    return;
  }

  if (!submodelInstance || !submodelInstance.submodel_reference_uuid) {
    return;
  }

  const versionId = VersionTagValues.LATEST_VERSION;

  return getSpecificReferenceSubmodelById(
    submodelInstance.submodel_reference_uuid,
    versionId,
    idToVersionIdToSubmodel,
    idToLatestTaggedVersionId,
  );
}

export function getReferenceSubmodelByNode(
  submodelInstance: SubmodelInstance,
  idToVersionIdToSubmodel?: Record<string, Record<string, SubmodelFullUI>>,
  idToLatestTaggedVersionId?: Record<string, string>,
): SubmodelFullUI | undefined {
  return getSpecificReferenceSubmodelByNode(
    submodelInstance,
    idToVersionIdToSubmodel || getSubmodelRef().idToVersionIdToSubmodelFull,
    idToLatestTaggedVersionId,
  );
}

function populateSubmodelsToFetch(
  diagram: ModelDiagram,
  submodelsToFetch: SubmodelFetchItem[],
): void {
  diagram.nodes.forEach((node) => {
    const submodelInstance = node as SubmodelInstance;
    if (submodelInstance?.submodel_reference_uuid) {
      submodelsToFetch.push({
        submodel_uuid: submodelInstance.submodel_reference_uuid,
        version: VersionTagValues.LATEST_VERSION,
      });
    }
  });
}

export function getSubmodelsToFetchFromDiagrams(
  diagram: ModelDiagram,
  submodelsSection: SubmodelsSection,
): SubmodelFetchItem[] {
  const submodelsToFetch: SubmodelFetchItem[] = [];

  // Find submodels to fetch from main diagram.
  populateSubmodelsToFetch(diagram, submodelsToFetch);

  // Find submodels to fetch from submodel diagrams.
  for (const submodelDiagram of Object.values(submodelsSection.diagrams)) {
    populateSubmodelsToFetch(submodelDiagram as ModelDiagram, submodelsToFetch);
  }

  return submodelsToFetch;
}

export function areAllReferencedSubmodelsLoaded(
  idToVersionIdToSubmodelInfo: Record<string, Record<string, SubmodelInfoUI>>,
  referencedSubmodels: SubmodelFetchItem[],
  idToVersionIdToNotFoundReason: Record<string, Record<string, string>>,
): boolean {
  // If there are no referenced submodels, then there is nothing to load,
  // so we consider loading to be complete.
  if (!referencedSubmodels || referencedSubmodels.length === 0) {
    return true;
  }

  // If there is no cache, then we have more loading to do.
  if (!idToVersionIdToSubmodelInfo) {
    return false;
  }

  // If any references haven't loaded yet, we are still loading.
  for (let i = 0; i < referencedSubmodels.length; i++) {
    const referencedSubmodel = referencedSubmodels[i];
    if (
      !getSpecificReferenceSubmodelById(
        referencedSubmodel.submodel_uuid,
        referencedSubmodel.version,
        idToVersionIdToSubmodelInfo,
      )
    ) {
      // If the version is not found, don't wait to load it.
      const versionIdToNotFoundReason =
        idToVersionIdToNotFoundReason[referencedSubmodel.submodel_uuid];
      if (versionIdToNotFoundReason) {
        if (versionIdToNotFoundReason[referencedSubmodel.version]) {
          continue;
        }
      }

      return false;
    }
  }

  // All references have loaded and we are ready to proceed combining the model diagram
  // with its associated submodels.
  return true;
}
