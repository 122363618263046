import { useGetSubscriptionStatusQuery } from 'app/apiGenerated/generatedApi';
import React from 'react';
import { customStripeContext } from 'ui/common/CustomStripeProvider';
import { useModal } from 'ui/common/Modal/useModal';
import { TermsOfServiceModal } from 'ui/tos/TermsOfServiceModal';
import { useTos } from 'ui/tos/useTos';

export const StartingModalsLoader: React.FC<{
  children: React.ReactElement;
}> = ({ children }) => {
  const { isFetching: tosIsFetching, hasAgreedToTermsOfService } = useTos();
  const { triggerModal, closeModal } = useModal();
  const { subscriptionStatus } = React.useContext(customStripeContext);

  const openLock = React.useRef(false);

  React.useEffect(() => {
    if (tosIsFetching || !subscriptionStatus) {
      return;
    }

    if (openLock.current) return;

    const markedSelfAsStudent =
      localStorage.getItem('signupTier') === 'student';

    const isExempt = subscriptionStatus.exempt || false;

    if (!hasAgreedToTermsOfService) {
      openLock.current = true;
      triggerModal(
        <TermsOfServiceModal
          isExempt={isExempt}
          markedSelfAsStudent={markedSelfAsStudent}
          closeModal={closeModal}
        />,
        'Thank you for creating an account',
        { disableNormalCloseControls: true },
      );
    }
  }, [
    tosIsFetching,
    hasAgreedToTermsOfService,
    subscriptionStatus,
    triggerModal,
    closeModal,
  ]);

  if (
    tosIsFetching ||
    !hasAgreedToTermsOfService ||
    !subscriptionStatus ||
    !subscriptionStatus.has_subscription
  ) {
    return null;
  }

  return children;
};
