import { useSimulationSignals } from 'app/api/useSimulationSignals';
import React from 'react';

interface Props {
  modelId: string;
  simulationId: string;
}

export const DataExplorerSimulationSignalsLoader: React.FC<Props> = ({
  modelId,
  simulationId,
}) => {
  useSimulationSignals(modelId, simulationId);

  return null;
};
