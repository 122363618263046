import * as NVG from 'nanovg-js';

import { calculateTextSize } from 'util/calculateTextSize';
import { drawImage } from './drawImage';
import { getDrawContext } from './drawPrimitives';
import { RendererState } from './modelRenderer';
import {
  RasterLoadState,
  getOrInitLoadImageFromStore,
} from './rasterTextureStore';

export function drawMultiplayerMice(rs: RendererState, nvg: NVG.Context) {
  const { multiplayerMouses } = rs.refs.current;
  const mouseUsers = Object.keys(multiplayerMouses);
  const ctx = getDrawContext(rs, nvg);

  for (let i = 0; i < mouseUsers.length; i++) {
    const userName = mouseUsers[i];
    const userMouse = multiplayerMouses[userName];

    const rx = (userMouse.x - 3 + rs.camera.x) * rs.zoom;
    const ry = (userMouse.y - 3 + rs.camera.y) * rs.zoom;

    const scale = 2; // it's a 2x dpi png which never needs to scale up or down
    const rasterMeta = getOrInitLoadImageFromStore(
      nvg,
      `${process.env.PUBLIC_URL}/assets/multiplayer_mouse.png`,
      'multiplayer_mouse',
      scale,
    );
    if (rasterMeta?.loadState === RasterLoadState.Loaded) {
      const rw = rasterMeta.width / scale;
      const rh = rasterMeta.height / scale;
      drawImage(rs, nvg, rasterMeta.id, rx, ry, rw, rh);
    }

    const tx = rx + 10;
    const ty = ry + 15;

    const fontSize = 14;
    const { width: nameWidth, height: nameHeight } = calculateTextSize(
      userName,
      {
        font: 'Archivo',
        fontSize: `${fontSize}px`,
      },
    );

    ctx.beginPath();
    ctx.roundedRect(tx, ty, nameWidth, nameHeight, 3);
    ctx.fillColor(NVG.RGB(0, 0, 0));
    ctx.fill();

    ctx.fontSize(fontSize);
    ctx.fontFace('archivo');
    ctx.textAlign(NVG.Align.LEFT | NVG.Align.TOP);
    ctx.fillColor(NVG.RGB(255, 255, 255));
    ctx.text(tx + 4, ty + 1, userName, null);
  }
}
