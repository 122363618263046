import { Coordinate } from 'app/common_types/Coordinate';
import {
  StateLinkInstance,
  StateNodeInstance,
} from 'app/generated_types/SimulationModel';
import { AppDispatch } from 'app/store';
import { RefObject } from 'react';

export type StateNodeSide = 'top' | 'left' | 'right' | 'down';

export type SMECamera = { x: number; y: number; zoom: number };

export enum SMEInteractionTag {
  None,
  Panning,
  DraggingNodes,
  DrawingLink,
  ReDraggingLink,
  DraggingEntryPoint,
  ReadyToDragSelect,
  SelectDragRect,
}

export type SMEInteractionTy =
  | { tag: SMEInteractionTag.None }
  | { tag: SMEInteractionTag.DraggingEntryPoint }
  | {
      tag: SMEInteractionTag.Panning;
    }
  | {
      tag: SMEInteractionTag.DraggingNodes;
    }
  | {
      tag: SMEInteractionTag.DrawingLink;
      from: {
        nodeId: string;
        side: StateNodeSide;
        coord: number;
      };
    }
  | {
      tag: SMEInteractionTag.ReDraggingLink;
      linkId: string;
      fromStart: boolean;
      connectedNodeId?: string;
    }
  | {
      tag: SMEInteractionTag.ReadyToDragSelect;
      startCoord: Coordinate;
    }
  | {
      tag: SMEInteractionTag.SelectDragRect;
      startCoord: Coordinate;
    };

export type SMERefsObjTy = {
  readOnly: boolean;
  dispatch?: AppDispatch;
  stateMachineId: string;
  setInteraction: (state: SMEInteractionTy) => void;
  camera: SMECamera;
  setCamera: (state: SMECamera) => void;
  mouseCoords: Coordinate;
  setMouseCoords: (state: Coordinate) => void;
  stateNodes: Array<StateNodeInstance>;
  nodeLUT: { [nodeId: string]: StateNodeInstance };
  stateLinks: Array<StateLinkInstance>;
  selectedNodeIds: Array<string>;
  selectedLinkIds: Array<string>;
  setSelectedNodeIds: (nodeIds: Array<string>) => void;
  setSelectedLinkIds: (linkIds: Array<string>) => void;
  interactionState: SMEInteractionTy;
  stateMachineBgRef: RefObject<HTMLDivElement> | null;
};
