import styled from '@emotion/styled/macro';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { dataExplorerPlotDataActions } from 'app/slices/dataExplorerPlotDataSlice';
import { dataExplorerActions } from 'app/slices/dataExplorerSlice';
import { traceDragActions } from 'app/slices/traceDragSlice';
import React, { useEffect } from 'react';
import { batch } from 'react-redux';
import RowCreateDropZone from 'ui/dataExplorer/drag/signalDrag/RowCreateDropZone';
import TraceDragPreview from 'ui/dataExplorer/drag/traceDrag/TraceDragPreview';
import TraceRemoveDropZone from 'ui/dataExplorer/drag/traceDrag/TraceRemoveDropZone';
import { DataExplorerRow } from 'ui/dataExplorer/grid/DataExplorerRow';

const DataExplorerGridWrapper = styled.div`
  isolation: isolate;
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: hidden;
`;

// Note that the 84px bottom padding is just enough space so that the user can scroll
// the grid above the feedback button.
const DataExplorerGridContent = styled.div`
  isolation: isolate;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.small};
  padding-top: ${({ theme }) => theme.spacing.normal};
  padding-left: ${({ theme }) => theme.spacing.normal};
  padding-right: ${({ theme }) => theme.spacing.normal};
  padding-bottom: 84px;
  height: 100%;
  overflow: auto;
`;

const TraceRemoveDropZoneWrapper = styled.div`
  position: fixed;
  transform: translate(-30%, 30%);
  bottom: 0;
  z-index: 1;
`;

interface Props {
  simulationId?: string;
  canEditVisualization: boolean;
}

export const DataExplorerGrid: React.FC<Props> = ({
  simulationId,
  canEditVisualization,
}) => {
  const dispatch = useAppDispatch();
  const wrapperRef = React.useRef<HTMLDivElement>(null);

  const cellRowIds: string[] = useAppSelector(
    (state) => state.dataExplorer.cellRowIds,
  );

  // ! HACK to allow prevent default on scroll events in the charts.
  // Needed because we have custom scroll overrides.
  // See https://github.com/facebook/react/issues/14856#issuecomment-586781399
  useEffect(() => {
    const cancelModifierKeyWheel = (e: WheelEvent) => {
      if (e.shiftKey || e.ctrlKey) {
        e.preventDefault();
      }
    };

    document.body.addEventListener('wheel', cancelModifierKeyWheel, {
      passive: false,
    });

    return () => {
      document.body.removeEventListener('wheel', cancelModifierKeyWheel);
    };
  }, []);

  /**
   * Trace drag logic here.
   * Trace drag is hard to reason about (and fragile) because the logic is fragmented across the components.
   * This is largely due to using 'legend drag' as an interaction, which is not supported by ECharts.
   */

  const traceDragPreviewRef = React.useRef<HTMLDivElement>(null);

  // In the `Chart`: on `mousedown` of a legend item, we `startTraceDrag` on mousedown to store `traceDragSourceCandidate` in redux.
  const { traceDragSourceCandidate, isDraggingTrace } = useAppSelector(
    (state) => state.traceDrag,
  );

  // In the `DataExplorerGridWrapper`: on `move` set `isDragging` for traceDrag to true,
  // if there is source origin set by `mousedown` mechanism above.
  const onMove = (e: React.MouseEvent<HTMLDivElement>) => {
    if (traceDragSourceCandidate && traceDragPreviewRef.current) {
      const distance = Math.hypot(
        e.clientX - traceDragSourceCandidate.startMouseX,
        e.clientY - traceDragSourceCandidate.startMouseY,
      );
      if (!isDraggingTrace && distance >= 16) {
        dispatch(traceDragActions.startDrag());
      }
    }
  };
  // In the `TraceDragChartDropZones` overlay: on `mouseUp` calls actions to move the trace.

  // This means that if you drag and release outside of the visualizer, it'll be stuck in a drag state. Hence,
  // In the wrapper: on `click` or `mouseUp` we clear out the `draggingTrace`
  // TODO: refactor to not need this catch-all clean up. We shouldn't get in this state in the first place. (Not dragging, but state thinks we are.)
  const clearTraceDrag = () => {
    if (traceDragSourceCandidate) {
      dispatch(traceDragActions.endDrag());
    }
  };

  // Trace drag: The end. Please fix with proper drag support before adding more features to it.

  const removeTrace = () => {
    if (canEditVisualization && isDraggingTrace && traceDragSourceCandidate) {
      const traceId = traceDragSourceCandidate.traceId;
      batch(() => {
        dispatch(
          dataExplorerActions.removeTraces({
            traceIds: [traceId],
          }),
        );
        dispatch(
          dataExplorerPlotDataActions.removeTraceLoadStates({
            traceIds: [traceId],
          }),
        );
      });
    }
  };

  return (
    <DataExplorerGridWrapper
      onMouseMove={canEditVisualization ? onMove : undefined}
      onMouseUp={canEditVisualization ? clearTraceDrag : undefined}>
      {canEditVisualization && (
        <>
          {isDraggingTrace && traceDragSourceCandidate && (
            <TraceRemoveDropZoneWrapper>
              <TraceRemoveDropZone removeTrace={removeTrace} />
            </TraceRemoveDropZoneWrapper>
          )}
          <TraceDragPreview ref={traceDragPreviewRef} show={isDraggingTrace} />
        </>
      )}
      <DataExplorerGridContent ref={wrapperRef}>
        {cellRowIds.map((cellRowId) => (
          <DataExplorerRow
            key={cellRowId}
            cellRowId={cellRowId}
            simulationId={simulationId}
            canEditVisualization={canEditVisualization}
          />
        ))}
        <RowCreateDropZone />
      </DataExplorerGridContent>
    </DataExplorerGridWrapper>
  );
};
