import { useTheme } from '@emotion/react';
import styled from '@emotion/styled/macro';
import {
  DataEditor,
  GridCell,
  GridCellKind,
  GridColumn,
  Item,
  getDefaultTheme,
} from '@glideapps/glide-data-grid';
// eslint-disable-next-line import/no-unassigned-import
import '@glideapps/glide-data-grid/dist/index.css';
import { Requirement } from 'app/apiGenerated/generatedApiTypes';
import { useCallback } from 'react';
import { provideRequirementBlockViewer } from './overlays/RequirementBlockViewer';

// Seems the child needs to be 100% width to make the editor expand.
const RequirementsTableWrapper = styled.div`
  width: 100%;
  > div:first-of-type {
    width: 100%;
  }
  border: 1px solid ${(props) => props.theme.colors.grey[10]};
`;

// Grid columns may also provide icon, overlayIcon, menu, style, and theme overrides
// Hard coded for now
const columns: GridColumn[] = [
  { title: 'ID', id: 'object_identifier', grow: 1 },
  { title: 'Number', id: 'object_number', grow: 1 },
  { title: 'Heading', id: 'object_heading', grow: 3 },
  { title: 'Text', id: 'object_text', grow: 6 },
  { title: 'Satisfied by', id: 'satisfied_by', grow: 2 },
];

type RequirementsTableProps = {
  requirements: Requirement[];
};

const RequirementsTable = ({ requirements }: RequirementsTableProps) => {
  const editorTheme = getDefaultTheme();
  const appTheme = useTheme();

  editorTheme.fontFamily = 'Barlow, sans-serif';
  editorTheme.baseFontStyle = appTheme.typography.font.standard.size;
  editorTheme.editorFontSize = appTheme.typography.font.standard.size;
  editorTheme.headerFontStyle = `700 ${appTheme.typography.font.standard.size}`;

  // Only the `Satisfied by` column can show an overlay on double click.
  const getCellContent = useCallback(
    ([col, row]: Item): GridCell => {
      const requirement = requirements?.[row];
      const mainReq = requirement.object_number.split('.').length === 1;

      if (col === 0) {
        return {
          kind: GridCellKind.Text,
          data: requirement.object_identifier,
          allowOverlay: false,
          displayData: requirement.object_identifier,
        };
      }
      if (col === 1) {
        return {
          kind: GridCellKind.Text,
          allowOverlay: false,
          displayData: requirement.object_number,
          data: requirement.object_number,
        };
      }
      if (col === 2) {
        return {
          kind: GridCellKind.Text,
          data: requirement.object_heading || '',
          allowOverlay: false,
          displayData: requirement.object_heading || '',
          themeOverride: mainReq
            ? {
                baseFontStyle: '600 14.4px',
              }
            : undefined,
        };
      }
      if (col === 3) {
        return {
          kind: GridCellKind.Text,
          data: requirement.object_text || '',
          allowOverlay: false,
          displayData: requirement.object_text || '',
        };
      }
      if (col === 4) {
        // HACK: Pass extra data to the drilldowns so that they can be used in the overlay
        // See `RequirementBlockLink`
        return {
          kind: GridCellKind.Drilldown,
          data: requirement.requirement_blocks.map((block) => ({
            text: block.block_name,
            ...block,
          })),
          allowOverlay: true,
        };
      }
      throw new Error();
    },
    [requirements],
  );

  return (
    <RequirementsTableWrapper>
      <DataEditor
        getCellContent={getCellContent}
        columns={columns}
        rows={requirements.length}
        provideEditor={provideRequirementBlockViewer}
        // Use the default implementation since data is small, we're not paginating.
        // https://grid.glideapps.com/docs/interfaces/DataEditorProps.html#getCellsForSelection
        getCellsForSelection
        theme={editorTheme}
        keybindings={{ search: true }}
      />
    </RequirementsTableWrapper>
  );
};

export default RequirementsTable;
