import { useAppSelector } from 'app/hooks';
import { ModelVersionRequestData } from 'app/slices/modelVersionsSlice';
import React from 'react';
import { DataExplorerModelVersionLoader } from 'ui/dataExplorer/loaders/DataExplorerModelVersionLoader';

/**
 * Loader that notices model version load requests and makes sure that
 * each model version is loaded once and saved to the redux store.
 */
export const DataExplorerModelVersionsLoader: React.FC = () => {
  const modelIdToVersionIdRequestData = useAppSelector(
    (state) => state.modelVersions.modelIdToVersionIdRequestData,
  );

  const requestDataItems: ModelVersionRequestData[] = [];
  Object.values(modelIdToVersionIdRequestData).forEach(
    (versionIdRequestData) => {
      Object.values(versionIdRequestData).forEach((requestData) => {
        requestDataItems.push(requestData);
      });
    },
  );

  return (
    <>
      {requestDataItems.map((requestData) => (
        <DataExplorerModelVersionLoader
          key={`${requestData.modelId}.${requestData.versionId}`}
          modelId={requestData.modelId}
          versionId={requestData.versionId}
          kind={requestData.kind}
        />
      ))}
    </>
  );
};
