import type { Coordinate } from 'app/common_types/Coordinate';
import { AppDispatch } from 'app/store';
import hotkeys from 'hotkeys-js';
import * as NVG from 'nanovg-js';
import { MutableRefObject } from 'react';
import {
  RendererRefsType,
  TransformFunc,
} from 'ui/modelEditor/ModelRendererWrapper';
import { drawScene } from 'ui/modelRendererInternals/drawScene';
import { mouseInput } from 'ui/modelRendererInternals/mouseInput';

import { userPreferencesActions } from 'app/slices/userPreferencesSlice';

import { LinkInstance, NodeInstance } from '@collimator/model-schemas-ts';
import {
  ClickStates,
  HoverEntity,
  MouseActions,
  MouseClickState,
  MouseState,
} from 'app/common_types/MouseTypes';
import { PortSide } from 'app/common_types/PortTypes';
import { RENDERER_EXPERIMENTAL_ENABLE_CANVAS2D } from 'app/config/globalApplicationConfig';
import { uiFlagsActions } from 'app/slices/uiFlagsSlice';
import { LinkRenderData, linkToRenderData } from 'app/utils/linkToRenderData';
import { lineIntersect90Deg } from 'util/lineIntersectPoint';
import { blockIconIDsList } from './blockIconIDsList';
import { drawBgDots, prepareBgDotsFramebuffer } from './drawBackgroundDots';
import {
  drawFloatingDndBlocks,
  prepareDndBlocksFramebuffer,
} from './drawFloatingDndBlocks';
import { drawMultiplayerMice } from './drawMultiplayerMice';
import { getCursorVisualState } from './getCursorVisualState';
import { boundingBoxIntersects } from './getVertexHitboxForIndex';
import {
  keyDown,
  keyUp,
  registerHotkeyEvents,
  specialKeyMainCallback,
} from './keyEvents';
import {
  contextMenuEvent,
  mouseDoubleClick,
  mouseDown,
  mouseMove,
  mouseUp,
  wheel,
} from './pointerEvents';
import {
  RasterLoadState,
  allocMemImageAndIntoStoreFromImageBuffer,
  deleteAllImagesFromMemAndStore,
  loadArrayBufferPromise,
  rasterMetaStore,
} from './rasterTextureStore';

// Fade in for 200ms when the canvas initializes.
const FIRST_FRAME_FADE_IN_MS = 200;

// Keep the renderer active for a few frames after the last event.
// This does not seem necessary at the moment (no animations).
const DELAY_BEFORE_SLEEP_MS = 0;

const preloadedRastersPromises = [
  ...blockIconIDsList.map((id) => `renderer_icon_rasters/${id}`),
  'text_fader',
  'plotter_toggle_active',
  'plotter_toggle_inactive',
  'plotter_toggle_partial', // ? does this work correctly? The rasters have suffixes and have 4 versions.
  'input_port',
  'input_port_trigger',
  'link_end_input_blank',
  'link_occlusion_v',
  'link_occlusion_h',
  'continuous_signal_label_icon',
  'discrete_signal_label_icon',
  'matrix_signal_type_icon',
  'scalar_signal_type_icon',
  'vector_signal_type_icon',
  'tuned_indicator',
].reduce(
  (
    acc: { iconID: string; bufferPromise: Promise<ArrayBuffer | undefined> }[],
    iconID: string,
  ) => {
    const scales = [1, 2, 4];

    return [
      ...acc,
      ...scales.map((scale) => {
        const scaledIconID = `${iconID}_${scale}x`;
        rasterMetaStore[scaledIconID] = {
          loadState: RasterLoadState.Loading,
        };

        return {
          iconID: scaledIconID,
          bufferPromise: loadArrayBufferPromise(
            `${process.env.PUBLIC_URL}/assets/${scaledIconID}.png`,
          ),
        };
      }),
    ];
  },
  [],
);

export type PortConnListType = Array<{
  fullyConnected: boolean;
  side: PortSide;
  portId: number;
  linkUuid: string;
}>;

export interface PortConnLUTType {
  [k: string]: PortConnListType;
}

export interface RendererState {
  camera: Coordinate;
  zoom: number;
  cursorOverCanvas: boolean;
  screenCursorRaw: Coordinate;
  screenCursorZoomed: Coordinate;
  clickState: MouseClickState;
  mouseState: MouseState;
  linksRenderFrameData: LinkRenderData[];
  linksRenderFrameDataIndexLUT: { [uuid: string]: number };
  linksOcclusionPointLUT: { [uuid: string]: Array<LinkIntersectionCoordinate> };
  refs: MutableRefObject<RendererRefsType>;
  setTransform: TransformFunc;
  onTransformUpdate: TransformFunc | null;
  dispatch: AppDispatch;
  hoveringEntity: HoverEntity | undefined;
  hoveringAnnotationUuid: string | undefined;
}

// Mutation is required for performance of graphics relative to redux:
// eslint-disable-next-line import/no-mutable-exports
export let rendererState: RendererState | null = null;

let tickRafId: number | null = null;
let startMillis = 0;
let lastRenderRequestTime = 0;
let earlyCheckFailedCount = 0;
let lateCheckFailedCount = 0;
let gl: WebGLRenderingContext | null = null;
let nvg: NVG.Context | null = null;
let blockDndFramebuffer: NVG.NVGLUframebuffer | null = null;
let bgDotsFramebuffer: NVG.NVGLUframebuffer | null = null;
let windowResizer: ((event: UIEvent) => void) | null = null;

export function requestFrameRender() {
  lastRenderRequestTime = performance.now();
  if (tickRafId !== null) return;

  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  tickRafId = window.requestAnimationFrame(renderTick);
}

export const externallySetRendererTransform = (
  x: number,
  y: number,
  zoom: number,
): void => {
  if (!rendererState) return;

  rendererState.camera.x = x;
  rendererState.camera.y = y;
  rendererState.zoom = zoom;
};

async function loadFont(nvgContext: NVG.Context) {
  const loadArrayBuffer = async (url: string): Promise<ArrayBuffer> => {
    const response: Response = await fetch(url);
    return response.arrayBuffer();
  };

  const fontUrl = `${process.env.PUBLIC_URL}/assets/Archivo-Regular.ttf`;
  const buffer = await loadArrayBuffer(fontUrl);
  const fontArchivo = NVG.nvgCreateFontMem(
    nvgContext.ctx,
    'archivo',
    new Uint8Array(buffer),
  );

  if (fontArchivo === -1) {
    console.error('Could not load font from', fontUrl);
    return -1;
  }
  return 0;
}

export const startNanovg = async (canvas: HTMLCanvasElement): Promise<any> => {
  await NVG.default();

  gl = canvas.getContext('webgl', {
    stencil: true,
    preserveDrawingBuffer: true,
  });

  if (!gl) {
    console.error('Could not init webGL.');
    return -1;
  }

  nvg = NVG.createWebGL(
    gl,
    NVG.CreateFlags.ANTIALIAS | NVG.CreateFlags.STENCIL_STROKES,
  );
  if (!nvg) {
    console.error('Could not init nanovg.');
    return -1;
  }

  gl.pixelStorei(gl.UNPACK_PREMULTIPLY_ALPHA_WEBGL, true);

  blockDndFramebuffer = NVG.nvgluCreateFramebuffer(
    nvg.ctx,
    5000,
    5000,
    NVG.NVGimageFlags.PREMULTIPLIED,
  );
  bgDotsFramebuffer = NVG.nvgluCreateFramebuffer(
    nvg.ctx,
    64,
    64,
    NVG.NVGimageFlags.PREMULTIPLIED |
      NVG.ImageFlags.REPEATX |
      NVG.ImageFlags.REPEATY,
  );

  preloadedRastersPromises.forEach(({ bufferPromise, iconID }) =>
    bufferPromise.then((buffer) => {
      if (buffer && nvg) {
        allocMemImageAndIntoStoreFromImageBuffer(nvg, buffer, iconID, 4);
      }
    }),
  );

  const ok = await loadFont(nvg);
  if (ok === -1) {
    console.error('Could not load font for nanovg.');
    return -1;
  }
};

export const endNanovg = async (): Promise<any> => {
  if (nvg) {
    if (blockDndFramebuffer) {
      NVG.nvgluDeleteFramebuffer(blockDndFramebuffer);
      blockDndFramebuffer = null;
    }
    if (bgDotsFramebuffer) {
      NVG.nvgluDeleteFramebuffer(bgDotsFramebuffer);
      bgDotsFramebuffer = null;
    }
    deleteAllImagesFromMemAndStore(nvg);
    NVG.deleteWebGL(nvg);

    nvg = null;
  }

  if (gl) {
    const loseContextExtension = gl.getExtension('WEBGL_lose_context');
    if (loseContextExtension) {
      loseContextExtension.loseContext();
    }
    gl = null;
  }
};

type LinkIntersectionCoordinate = Coordinate & {
  orientation: 'vertical' | 'horizontal';
};

const getTwoLinksIntersectionPoints = (
  linkRenderDataOne: LinkRenderData,
  linkRenderDataTwo: LinkRenderData,
): LinkIntersectionCoordinate[] => {
  const intersections: LinkIntersectionCoordinate[] = [];

  for (let i = 0; i < linkRenderDataOne.vertexData.length - 1; i++) {
    const segmentOneStart = linkRenderDataOne.vertexData[i];
    const segmentOneEnd = linkRenderDataOne.vertexData[i + 1];

    const orientation =
      segmentOneStart.coordinate[0] === segmentOneEnd.coordinate[0]
        ? 'vertical'
        : 'horizontal';

    for (let j = 0; j < linkRenderDataTwo.vertexData.length - 1; j++) {
      const segmentTwoStart = linkRenderDataTwo.vertexData[j];
      const segmentTwoEnd = linkRenderDataTwo.vertexData[j + 1];

      const intersectionPoint = lineIntersect90Deg(
        segmentOneStart.coordinate[0],
        segmentOneStart.coordinate[1],
        segmentOneEnd.coordinate[0],
        segmentOneEnd.coordinate[1],
        segmentTwoStart.coordinate[0],
        segmentTwoStart.coordinate[1],
        segmentTwoEnd.coordinate[0],
        segmentTwoEnd.coordinate[1],
      );

      if (intersectionPoint !== null)
        intersections.push({ ...intersectionPoint, orientation });
    }
  }

  return intersections;
};

// FIXME: cache results
const setLinksOcclusionPoints = (rs: RendererState) => {
  rs.linksOcclusionPointLUT = {};
  if (!rs.refs.current.canvas) return;

  const renderFrameData: LinkRenderData[] = rs.linksRenderFrameData;

  // FIXME this is probably wrong - should use the sceneBoundingBox
  const bbCanvas = {
    x1: -rs.camera.x,
    y1: -rs.camera.y,
    x2: -rs.camera.x + rs.refs.current.canvas.width / rs.zoom,
    y2: -rs.camera.y + rs.refs.current.canvas.height / rs.zoom,
  };

  for (let i = 0; i < renderFrameData.length - 1; i++) {
    const renderDataOne = renderFrameData[i];
    const bbOne = renderDataOne.boundingBox;

    if (!boundingBoxIntersects(bbOne, bbCanvas)) continue;

    for (let j = i + 1; j < renderFrameData.length; j++) {
      const renderDataTwo = renderFrameData[j];
      const bbTwo = renderDataTwo.boundingBox;

      if (
        !boundingBoxIntersects(bbTwo, bbCanvas) ||
        !boundingBoxIntersects(bbTwo, bbOne)
      ) {
        continue;
      }

      const occlusionPoints = getTwoLinksIntersectionPoints(
        renderDataOne,
        renderDataTwo,
      );
      if (occlusionPoints.length > 0) {
        if (!rs.linksOcclusionPointLUT[renderDataOne.linkUuid]) {
          rs.linksOcclusionPointLUT[renderDataOne.linkUuid] = occlusionPoints;
        } else {
          for (let k = 0; k < occlusionPoints.length; k++) {
            rs.linksOcclusionPointLUT[renderDataOne.linkUuid].push(
              occlusionPoints[k],
            );
          }
        }
      }
    }
  }
};

// FIXME: cache results
export const regenLinkRenderFrameData = (
  rendererState: RendererState,
  overrideNodes?: NodeInstance[],
  overrideLinks?: LinkInstance[],
) => {
  // TODO: This is temporary while we wait to prioritize developing a
  // more robust method for pre-calculating the link render data
  // that doesn't happen every frame.
  rendererState.linksRenderFrameData = [];
  rendererState.linksRenderFrameDataIndexLUT = {};

  const usingLinksList = overrideLinks || rendererState.refs.current.links;
  const usingNodesList = overrideNodes || rendererState.refs.current.nodes;

  const baseLevelLinks =
    rendererState.refs.current.linksRenderingDependencyTree.__no_dependency ||
    [];

  // this is performance-wise the fastest way to get a copy of this
  const iteratingLinkUUIDs: string[] = [];
  for (let i = 0; i < baseLevelLinks.length; i++) {
    iteratingLinkUUIDs.push(baseLevelLinks[i]);
  }

  let linkRenderIndex = 0;
  while (iteratingLinkUUIDs.length) {
    const linkUUID = iteratingLinkUUIDs.pop() || '';
    const realLinkIndex = rendererState.refs.current.linksIndexLUT[linkUUID];
    const link = usingLinksList[realLinkIndex];

    if (!link) continue;

    const dependentLinkUUIDs =
      rendererState.refs.current.linksRenderingDependencyTree[linkUUID];

    if (dependentLinkUUIDs && dependentLinkUUIDs.length > 0) {
      for (let i = 0; i < dependentLinkUUIDs.length; i++) {
        iteratingLinkUUIDs.push(dependentLinkUUIDs[i]);
      }
    }

    rendererState.linksRenderFrameData.push(
      linkToRenderData(
        rendererState,
        link,
        usingNodesList,
        rendererState.refs.current.nodesIndexLUT,
        rendererState.camera.x,
        rendererState.camera.y,
      ),
    );

    rendererState.linksRenderFrameDataIndexLUT[link.uuid] = linkRenderIndex;
    linkRenderIndex++;
  }
};

function renderTick(time: number) {
  if (tickRafId !== null) window.cancelAnimationFrame(tickRafId);
  tickRafId = null;

  if (!rendererState || !nvg) {
    earlyCheckFailedCount++;
    if (earlyCheckFailedCount < 120) {
      tickRafId = window.requestAnimationFrame(renderTick);
    }
    return;
  }

  earlyCheckFailedCount = 0;

  let renderWidth = 0;
  let renderHeight = 0;
  let framebufWidth = 0;
  let framebufHeight = 0;

  const parent = rendererState.refs.current.parent;
  const canvas = rendererState.refs.current.canvas;

  if (parent) {
    renderWidth = parent.clientWidth;
    renderHeight = parent.clientHeight;

    if (canvas) {
      // NOTE: there is probably something rogue setting canvas width/height and causing it to "disappear",
      // so putting this here for now (and will consider removal
      // if we can diagnose the "disappearing" problem's source).
      // this won't affect performance as the value isn't actually changing every frame.
      canvas.width = parent.clientWidth * window.devicePixelRatio;
      canvas.height = parent.clientHeight * window.devicePixelRatio;
    }
  }

  if (gl) {
    framebufWidth = gl.drawingBufferWidth;
    framebufHeight = gl.drawingBufferHeight;

    gl.viewport(0, 0, framebufWidth, framebufHeight);

    gl.enable(gl.BLEND);
    gl.blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA);
    gl.enable(gl.CULL_FACE);
    gl.disable(gl.DEPTH_TEST);
  }

  mouseInput(rendererState, rendererState.dispatch, rendererState.setTransform);

  if (canvas) {
    if (time - startMillis < FIRST_FRAME_FADE_IN_MS) {
      canvas.style.opacity = `${(time - startMillis) / FIRST_FRAME_FADE_IN_MS}`;
    } else if (canvas.style.opacity !== '1') {
      canvas.style.opacity = '1';
    }
  }

  const cursorVisualStyle = getCursorVisualState(rendererState);
  if (canvas && canvas.style.cursor !== cursorVisualStyle) {
    canvas.style.cursor = cursorVisualStyle;
  }

  regenLinkRenderFrameData(rendererState);
  setLinksOcclusionPoints(rendererState);

  // FIXME properly switch between renderers - don't use an overlay
  const ctx = rendererState.refs.current.uiFlags.renderCanvas2d
    ? rendererState.refs.current.overlayCanvas2dCtx
    : null;
  if (ctx) ctx.clearRect(0, 0, framebufWidth, framebufHeight);

  if (gl) {
    prepareBgDotsFramebuffer(
      rendererState,
      nvg,
      gl,
      ctx,
      bgDotsFramebuffer,
      framebufWidth,
      framebufHeight,
    );

    prepareDndBlocksFramebuffer(
      rendererState,
      nvg,
      gl,
      ctx,
      blockDndFramebuffer,
      framebufWidth,
      framebufHeight,
    );

    gl.viewport(0, 0, framebufWidth, framebufHeight);
    gl.enable(gl.BLEND);

    // FIXME: use coloring.ts
    const subdiagramType = rendererState.refs.current.currentSubdiagramType;
    if (subdiagramType === 'core.Iterator') {
      gl.clearColor(248 / 255, 245 / 255, 227 / 255, 1);
    } else if (subdiagramType === 'core.LinearizedSystem') {
      gl.clearColor(0, 245 / 255, 227 / 255, 1);
    } else {
      gl.clearColor(0xf1 / 255, 0xf3 / 255, 0xf3 / 255, 1.0);
    }

    gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT | gl.STENCIL_BUFFER_BIT);

    // on local, unmounting gets tripped up sometimes and very frequently
    // chokes right here (non-catastrophically, though annoyingly).
    // this seems to fix it
    if (!nvg) {
      lateCheckFailedCount++;
      if (lateCheckFailedCount < 120) {
        tickRafId = window.requestAnimationFrame(renderTick);
      }
      return;
    }

    lateCheckFailedCount = 0;

    nvg.beginFrame(renderWidth, renderHeight, window.devicePixelRatio);

    drawBgDots(
      rendererState,
      nvg,
      ctx,
      bgDotsFramebuffer,
      framebufWidth,
      framebufHeight,
      renderWidth,
      renderHeight,
    );

    drawScene(
      nvg,
      rendererState,
      rendererState.refs.current.connectedPortLUT,
      rendererState.refs.current.selectedNodeIds,
      renderWidth / rendererState.zoom,
      renderHeight / rendererState.zoom,
    );

    drawFloatingDndBlocks(
      rendererState,
      nvg,
      ctx,
      blockDndFramebuffer,
      framebufWidth,
      framebufHeight,
      renderWidth,
      renderHeight,
    );

    drawMultiplayerMice(rendererState, nvg);

    nvg.endFrame();
    gl.enable(gl.DEPTH_TEST);
  }

  // Clicks should only persist for 1 frame
  if (
    rendererState.clickState.state === ClickStates.Click ||
    rendererState.clickState.state === ClickStates.DoubleClick
  ) {
    rendererState.clickState = { state: ClickStates.Idle };
  }

  // Same as requestFrameRender()
  if (
    typeof window !== 'undefined' &&
    (time - lastRenderRequestTime <= DELAY_BEFORE_SLEEP_MS ||
      time - startMillis <= FIRST_FRAME_FADE_IN_MS)
  ) {
    tickRafId = window.requestAnimationFrame(renderTick);
  }
}

function onTextSelectionChange() {
  if (rendererState === null) return;
  requestFrameRender();

  const dispatch = rendererState.dispatch;
  const { anchorOffset, focusOffset } = document.getSelection() || {
    anchorOffset: 0,
    focusOffset: 0,
  };
  const minPos = Math.min(anchorOffset, focusOffset);
  const maxPos = Math.max(anchorOffset, focusOffset);
  if (maxPos - minPos > 0) {
    dispatch(uiFlagsActions.setUIFlag({ htmlTextSelected: true }));
  } else {
    dispatch(uiFlagsActions.setUIFlag({ htmlTextSelected: false }));
  }
}

// necessary for re-mounting
export function unregisterRendererEvents(): void {
  if (!rendererState) return;

  const { canvas } = rendererState.refs.current;
  if (typeof window !== 'undefined') {
    window.removeEventListener('keydown', keyDown);
    window.removeEventListener('keyup', keyUp);
    window.removeEventListener('pointermove', mouseMove);
    window.removeEventListener('mousedown', mouseDown);
    window.removeEventListener('contextmenu', contextMenuEvent);
    window.removeEventListener('mouseup', mouseUp);

    if (canvas) {
      canvas.removeEventListener('wheel', wheel);
      canvas.removeEventListener('dblclick', mouseDoubleClick);
    }

    hotkeys.unbind();
    document.removeEventListener('keydown', specialKeyMainCallback);
    document.removeEventListener('selectionchange', onTextSelectionChange);
  }
}

function initCanvas2d(refs: MutableRefObject<RendererRefsType>) {
  if (!RENDERER_EXPERIMENTAL_ENABLE_CANVAS2D) return;

  const canvas = refs.current.overlayCanvas2d;
  const parent = refs.current.parent;
  if (!canvas || !parent) {
    console.warn('Could not create experimental overlay canvas');
    return;
  }

  canvas.style.left = '0';
  canvas.style.right = '0';
  canvas.style.top = '0';
  canvas.style.bottom = '0';
  canvas.style.width = '100%';
  canvas.style.height = '100%';
  canvas.style.position = 'absolute';
  canvas.width = parent.clientWidth * window.devicePixelRatio;
  canvas.height = parent.clientHeight * window.devicePixelRatio;

  // alpha: true because this is an overlay over the WebGL canvas
  // We can't fully commit to canvas 2d because the bg dot background rendering
  // is too slow (scales and repeats on CPU).
  const ctx = canvas.getContext('2d', { alpha: true });
  if (!ctx) throw new Error('Canvas 2d context could not be created');

  ctx.imageSmoothingEnabled = true;
  ctx.imageSmoothingQuality = 'high';

  refs.current.overlayCanvas2dCtx = ctx;
}

export async function initModelRenderer(
  refs: MutableRefObject<RendererRefsType>,
  setTransform: TransformFunc,
  dispatch: AppDispatch,
): Promise<void> {
  await startNanovg(refs.current.canvas as HTMLCanvasElement);
  initCanvas2d(refs);

  rendererState = {
    refs,
    setTransform,
    onTransformUpdate: null,
    dispatch,
    linksRenderFrameData: rendererState?.linksRenderFrameData || [],
    linksRenderFrameDataIndexLUT: {},
    linksOcclusionPointLUT: {},
    camera: rendererState?.camera || { x: 0, y: 0 },
    cursorOverCanvas: false,
    screenCursorRaw: rendererState?.screenCursorRaw || { x: 0, y: 0 },
    screenCursorZoomed: rendererState?.screenCursorZoomed || { x: 0, y: 0 },
    clickState: rendererState?.clickState || { state: ClickStates.Idle },
    mouseState: rendererState?.mouseState || { state: MouseActions.Idle },
    zoom: rendererState?.zoom || 1,
    hoveringEntity: undefined,
    hoveringAnnotationUuid: undefined,
  };

  registerHotkeyEvents();

  document.addEventListener('selectionchange', onTextSelectionChange);

  const canvas = refs.current.canvas;
  const parent = refs.current.parent;
  if (typeof window !== 'undefined' && canvas && parent) {
    window.addEventListener('keydown', keyDown);
    window.addEventListener('keyup', keyUp);
    window.addEventListener('pointermove', mouseMove);
    window.addEventListener('mousedown', mouseDown);
    window.addEventListener('contextmenu', contextMenuEvent);
    window.addEventListener('mouseup', mouseUp);
    canvas.addEventListener('dblclick', mouseDoubleClick);
    canvas.addEventListener('wheel', wheel, { passive: false });
    canvas.tabIndex = 1;
    canvas.style.left = '0';
    canvas.style.right = '0';
    canvas.style.top = '0';
    canvas.style.bottom = '0';
    canvas.style.width = '100%';
    canvas.style.height = '100%';
    canvas.style.position = 'absolute';
    canvas.width = parent.clientWidth * window.devicePixelRatio;
    canvas.height = parent.clientHeight * window.devicePixelRatio;

    if (windowResizer) {
      window.removeEventListener('resize', windowResizer);
    }

    windowResizer = (_event: UIEvent): void => {
      requestFrameRender();
      canvas.width = parent.clientWidth * window.devicePixelRatio;
      canvas.height = parent.clientHeight * window.devicePixelRatio;

      const canvas2d = refs.current.overlayCanvas2d;
      if (canvas2d) {
        canvas2d.width = parent.clientWidth * window.devicePixelRatio;
        canvas2d.height = parent.clientHeight * window.devicePixelRatio;
      }
    };

    window.addEventListener('resize', windowResizer);

    // see useModelEditorPreferences.ts for an explanation on why we're using
    // setTimeout() here.
    // not the perfect solution, but it works. -jackson
    window.setTimeout(() => {
      dispatch(userPreferencesActions.setLoadModelEditor());
      dispatch(userPreferencesActions.setLoadVisualizer());
    }, 50);

    dispatch(uiFlagsActions.setUIFlag({ rendererStateInitialized: true }));
  }

  if (typeof window !== 'undefined') {
    if (tickRafId !== null) window.cancelAnimationFrame(tickRafId);
    tickRafId = window.requestAnimationFrame((t: number) => {
      startMillis = t;
      lastRenderRequestTime = t;
      renderTick(t);
    });
  }
}
