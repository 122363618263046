// !!!! -- DO NOT MANUALLY EDIT -- !!!!
// This file was generated by something in scripts/generateDefaultBlockData.js
// If you want to change the contents of this file, it should be automated
//
// (You can edit this file locally, but it may not be checked into the codebase -
// PRs with a manual edit of this file are forbidden from being merged.)

import { computationBlockDefaults } from 'app/generated_blocks/computationBlockDefaults';
import type { ComputationBlockClass } from 'app/generated_types/ComputationBlockClass';
import deepmerge from 'deepmerge';

export const TransferFunction: ComputationBlockClass = deepmerge(
  computationBlockDefaults,
  {
    "base": {
      "name": "TransferFunction",
      "namespace": "core",
      "description": "Models a linear system by a transfer function of the Laplace-domain variable s.",
      "development_notes": "the order of the denominator must be greater than the numerator. leading zeros in either num or den should be pruned.",
      "help_url": "https://docs.collimator.ai/using-model-editors/block-library/continuous-blocks#transfer-function",
      "supports_iterator": false
    },
    "modes": {
      "time": "agnostic"
    },
    "ports": {
      "inputs": {
        "static": [
          {
            "name": "in_0"
          }
        ]
      },
      "outputs": {
        "static": [
          {
            "name": "out_0"
          }
        ]
      }
    },
    "parameter_definitions": [
      {
        "name": "numerator_coefficients",
        "data_type": "any",
        "default_value": "[1]",
        "description": "Coefficients of the polynomial of Laplace variable s for the numerator of the transfer function."
      },
      {
        "name": "denominator_coefficients",
        "data_type": "any",
        "default_value": "[1, 1]",
        "description": "Coefficients of the polynomial of Laplace variable s for the denominator of the transfer function."
      }
    ]
  }
);
