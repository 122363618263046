import { useAppSelector } from 'app/hooks';
import { getDiagramForPath } from 'app/utils/modelDiagramUtils';
import React from 'react';
import DiagramTreeContent from 'ui/objectBrowser/sections/DiagramTreeContent';

interface Props {
  projectId: string;
  testIdPath: string;
  nodeId: string;
  parentPath: string[];
  searchString?: string;
}

const LocalSubmodelTreeContent: React.FC<Props> = ({
  projectId,
  testIdPath,
  nodeId,
  parentPath,
  searchString,
}) => {
  // TODO: match to state to automatically navigate to the correct diagram
  const nextParentPath = [...parentPath, nodeId];
  const diagram = useAppSelector(
    (state) =>
      getDiagramForPath(
        nextParentPath,
        state.model.present.rootModel,
        state.model.present.submodels,
        state.submodels.idToVersionIdToSubmodelFull,
        state.submodels.idToLatestTaggedVersionId,
      ).diagram,
  );

  return (
    <DiagramTreeContent
      projectId={projectId}
      testIdPath={testIdPath}
      parentPath={nextParentPath}
      searchString={searchString}
      diagram={diagram}
    />
  );
};

export default LocalSubmodelTreeContent;
