/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Notebook: React.FC<Props> = (props) => (
  <svg viewBox="0 0 24 24" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M16.5 6.55556L8.29999 6.55556V17.4444L16.5 17.4444V6.55556ZM8.29999 5C7.47156 5 6.79999 5.69645 6.79999 6.55556V17.4444C6.79999 18.3036 7.47156 19 8.29999 19H16.5C17.3284 19 18 18.3036 18 17.4444V6.55556C18 5.69645 17.3284 5 16.5 5H8.29999Z"
      fill={props.fill}
    />
    <path d="M10.5 8.11111H15.3802V9.66667H10.5V8.11111Z" fill={props.fill} />
    <path d="M10.5 11.2222H15.3802V12.7778H10.5V11.2222Z" fill={props.fill} />
    <path
      d="M6 8.88889C6 8.45934 6.33579 8.11111 6.75 8.11111H8.25C8.66422 8.11111 9 8.45934 9 8.88889C9 9.31845 8.66422 9.66667 8.25 9.66667H6.75C6.33579 9.66667 6 9.31845 6 8.88889Z"
      fill={props.fill}
    />
    <path
      d="M6 12C6 11.5705 6.33579 11.2222 6.75 11.2222H8.25C8.66422 11.2222 9 11.5705 9 12C9 12.4296 8.66422 12.7778 8.25 12.7778H6.75C6.33579 12.7778 6 12.4296 6 12Z"
      fill={props.fill}
    />
    <path
      d="M6 15.1111C6 14.6816 6.33579 14.3333 6.75 14.3333H8.25C8.66422 14.3333 9 14.6816 9 15.1111C9 15.5407 8.66422 15.8889 8.25 15.8889H6.75C6.33579 15.8889 6 15.5407 6 15.1111Z"
      fill={props.fill}
    />
  </svg>
);

Notebook.defaultProps = {
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Notebook;
