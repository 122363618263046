import {
  BlockClassName,
  NodeInstance,
} from 'app/generated_types/SimulationModel';
import * as NVG from 'nanovg-js';
import { LINE_COLORS } from './coloring';
import { getDrawContext } from './drawPrimitives';
import { RendererState } from './modelRenderer';

const PORT_LABEL_MARGIN_X = 10;

// Use a dash instead of a minus sign because the dash is much closer to the same width
// as the '+' symbol and it makes the layout better.
// Use unicode because the dash glyph is not included in our 'archivo' font.
const DASH_UNICODE_GLYPH = '\u2013';

export const BLOCK_GLYPH_MAP: {
  [block in BlockClassName]?: {
    defaultGlyphLabel: string;
    portLabelToGlyph: { [label: string]: string };
  };
} = {
  'core.Adder': {
    defaultGlyphLabel: '+',
    portLabelToGlyph: {
      '+': '+',
      '-': DASH_UNICODE_GLYPH,
    },
  },
  'core.Product': {
    defaultGlyphLabel: '*',
    portLabelToGlyph: {
      '*': '×',
      '/': '÷',
    },
  },
  'core.Arithmetic': {
    defaultGlyphLabel: '+',
    portLabelToGlyph: {
      '+': '+',
      '-': DASH_UNICODE_GLYPH,
      '*': '×',
      '/': '÷',
    },
  },
};

export function drawPortGlyph(
  rs: RendererState,
  nvg: NVG.Context,
  x: number,
  y: number,
  zoom: number,
  portIndex: number,
  node: NodeInstance,
) {
  const ctx = getDrawContext(rs, nvg);

  const glyphMap = BLOCK_GLYPH_MAP[node.type];
  if (!glyphMap) return;

  const portLabel =
    node.parameters.operators?.value?.[portIndex] || glyphMap.defaultGlyphLabel;
  const portGlyph = glyphMap.portLabelToGlyph[portLabel];
  if (!portGlyph) return;

  const nodeFlipped = node.uiprops.directionality === 'left';
  const portHorizMargin = nodeFlipped
    ? -PORT_LABEL_MARGIN_X
    : PORT_LABEL_MARGIN_X;
  const symbolXOffset =
    portHorizMargin + (portGlyph === DASH_UNICODE_GLYPH ? 1 : 0);

  ctx.fontSize(12 * zoom);
  ctx.fontFace('archivo');
  ctx.textAlign(
    nodeFlipped
      ? NVG.Align.RIGHT | NVG.Align.MIDDLE
      : NVG.Align.LEFT | NVG.Align.MIDDLE,
  );
  ctx.fillColor(LINE_COLORS.text);
  ctx.text((x + symbolXOffset) * zoom, y * zoom, portGlyph, null);
}
