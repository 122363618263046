import styled from '@emotion/styled';
import { t } from '@lingui/macro';
import { useProjects } from 'app/api/useProjects';
import React from 'react';
import Button from 'ui/common/Button/Button';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import Label from 'ui/common/Label';
import {
  ActionButtonContainer,
  ModalInputGroup,
  SmallFormContainer,
} from 'ui/common/Modal/Modal';
import { useModal } from 'ui/common/Modal/useModal';
import SelectInput from 'ui/common/SelectInput';
import { Spinner, SpinnerWrapper } from 'ui/common/Spinner';
import ProjectItemNameField from 'ui/dashboard/projectDetail/ProjectItemNameField';
import { ProjectItemType } from '../dashboardTypes';

interface Props {
  itemType: ProjectItemType;
  initialDestinationProjectUuid: string;
  defaultDuplicateName: string;
  extension: string;
  onDuplicate: (name: string, destinationProjectUuid: string) => void;
  description?: string;
}

const Description = styled.div`
  margin-top: ${({ theme }) => theme.spacing.large};
  margin-bottom: 20px;
  font-size: ${({ theme }) => theme.typography.font.standard.size};
  line-height: ${({ theme }) => theme.typography.font.standard.lineHeight};
`;

const DuplicateModal: React.FC<Props> = ({
  itemType,
  initialDestinationProjectUuid,
  defaultDuplicateName,
  extension,
  onDuplicate,
  description,
}) => {
  const { closeModal } = useModal();

  const [newName, setName] = React.useState(defaultDuplicateName);
  const [destinationProjectUuid, setDestinationProjectUuid] = React.useState(
    initialDestinationProjectUuid,
  );

  const [isValid, setIsValid] = React.useState(true);

  const { projects } = useProjects();
  const project = projects?.find((p) => p.uuid === destinationProjectUuid);

  const projectOptions = React.useMemo(() => {
    const options: { value: string; label: string }[] = [];
    if (projects) {
      projects.forEach((project) => {
        const projectLabel =
          project.uuid === initialDestinationProjectUuid
            ? `${project.title} (This project)`
            : `${project.title}`;
        options.push({
          value: project.uuid,
          label: projectLabel,
        });
      });
    }
    return options;
  }, [projects, initialDestinationProjectUuid]);

  const onChangeName = React.useCallback(
    (newName: string, isValid: boolean) => {
      setName(newName);
      setIsValid(isValid);
    },
    [],
  );

  return (
    <SmallFormContainer
      onSubmit={(e) => {
        e?.preventDefault();
        onDuplicate(newName, destinationProjectUuid);
      }}>
      {description && <Description>{description}</Description>}
      <fieldset>
        <ModalInputGroup>
          <Label testId="destination-project-label">
            {t({
              id: 'duplicateModal.destinationProjectInput.label',
              message: 'Choose a destination project',
            })}
          </Label>
          <SelectInput
            options={projectOptions}
            onSelectValue={(value: string) => setDestinationProjectUuid(value)}
            currentValue={destinationProjectUuid}
          />
        </ModalInputGroup>
        {project ? (
          <ProjectItemNameField
            project={project}
            itemType={itemType}
            onChangeName={onChangeName}
            oldNameWithoutExtension={newName}
            extension={extension}
          />
        ) : (
          <SpinnerWrapper>
            <Spinner />
          </SpinnerWrapper>
        )}
      </fieldset>
      <ActionButtonContainer>
        {/* Cancel button */}
        <Button
          type="button"
          onClick={closeModal}
          variant={ButtonVariants.LargeSecondary}
          testId="duplicate-model-cancel-button">
          {t({
            id: 'duplicateModal.cancelButton.label',
            message: 'Cancel',
          })}
        </Button>

        {/* Duplicate button */}
        <Button
          type="submit"
          variant={ButtonVariants.LargePrimary}
          disabled={!isValid}
          testId="duplicate-model-button">
          {t({
            id: 'duplicateModal.duplicateButton.label',
            message: 'Duplicate',
          })}
        </Button>
      </ActionButtonContainer>
    </SmallFormContainer>
  );
};

export default DuplicateModal;
