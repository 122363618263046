import { useAppDispatch, useAppSelector } from 'app/hooks';
import { entityPreferencesActions } from 'app/slices/entityPreferencesSlice';
import React from 'react';
import { MODEL_EDITOR_VISUALIZER_EXPLORATION_ID } from 'ui/userPreferences/visualizerPrefs';

interface Props {
  modelId: string;
}

export const VisualizationTraceTracker: React.FC<Props> = ({ modelId }) => {
  const dispatch = useAppDispatch();

  const loadedModelId = useAppSelector(
    (state) => state.modelMetadata.loadedModelId,
  );

  const {
    explorationId,
    cellRowIds,
    idToCellRow,
    idToCell,
    idToPlotCell,
    idToTextCell,
    idToImageCell,
    idToTrace,
  } = useAppSelector((state) => state.dataExplorer);

  // Update the visualizer prefs when the data explorer changes.
  React.useEffect(() => {
    if (
      modelId !== loadedModelId ||
      explorationId !== MODEL_EDITOR_VISUALIZER_EXPLORATION_ID
    ) {
      return;
    }

    dispatch(
      entityPreferencesActions.onUserChangedVisualizer({
        modelId,
        cellRowIds,
        idToCellRow,
        idToCell,
        idToPlotCell,
        idToTextCell,
        idToImageCell,
        idToTrace,
      }),
    );
  }, [
    dispatch,
    loadedModelId,
    modelId,
    explorationId,
    cellRowIds,
    idToCellRow,
    idToCell,
    idToPlotCell,
    idToTextCell,
    idToImageCell,
    idToTrace,
  ]);

  return null;
};
