/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const LogicalreduceAll: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10 6C10 8.20914 8.20914 10 6 10C3.79086 10 2 8.20914 2 6C2 3.79086 3.79086 2 6 2C8.20914 2 10 3.79086 10 6Z"
      fill={props.fill}
    />
    <path
      d="M10 16C10 18.2091 8.20914 20 6 20C3.79086 20 2 18.2091 2 16C2 13.7909 3.79086 12 6 12C8.20914 12 10 13.7909 10 16Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M10 26C10 28.2091 8.20914 30 6 30C3.79086 30 2 28.2091 2 26C2 23.7909 3.79086 22 6 22C8.20914 22 10 23.7909 10 26ZM8.5 26C8.5 27.3807 7.38071 28.5 6 28.5C4.61929 28.5 3.5 27.3807 3.5 26C3.5 24.6193 4.61929 23.5 6 23.5C7.38071 23.5 8.5 24.6193 8.5 26Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M26 20C28.2091 20 30 18.2091 30 16C30 13.7909 28.2091 12 26 12C23.7909 12 22 13.7909 22 16C22 18.2091 23.7909 20 26 20ZM26 18.5C27.3807 18.5 28.5 17.3807 28.5 16C28.5 14.6193 27.3807 13.5 26 13.5C24.6193 13.5 23.5 14.6193 23.5 16C23.5 17.3807 24.6193 18.5 26 18.5Z"
      fill={props.fill}
    />
    <path
      d="M14.25 4C14.25 3.58579 13.9142 3.25 13.5 3.25C13.0858 3.25 12.75 3.58579 12.75 4V28C12.75 28.4142 13.0858 28.75 13.5 28.75C13.9142 28.75 14.25 28.4142 14.25 28V16.75H16.8987L15.4238 18.5199C15.1587 18.8381 15.2017 19.311 15.5199 19.5762C15.8381 19.8413 16.311 19.7983 16.5762 19.4801L19.5146 15.954L16.5303 12.9697C16.2374 12.6768 15.7626 12.6768 15.4697 12.9697C15.1768 13.2626 15.1768 13.7374 15.4697 14.0303L16.6893 15.25H14.25V4Z"
      fill={props.fill}
    />
  </svg>
);

LogicalreduceAll.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default LogicalreduceAll;
