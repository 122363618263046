const ADMIN_ASSUMED_USER_KEY = 'AdminAssumedUserUuid';

// FIXME: Should we migrate this to a user option too?
export const getAdminAssumedUser = () => {
  const assumedUser = localStorage.getItem(ADMIN_ASSUMED_USER_KEY);
  return assumedUser || undefined;
};

export const setAdminAssumedUser = (userUuid: string | undefined) => {
  if (!userUuid) {
    localStorage.removeItem(ADMIN_ASSUMED_USER_KEY);
    return;
  }
  localStorage.setItem(ADMIN_ASSUMED_USER_KEY, userUuid);
};
