import styled from '@emotion/styled/macro';
import { Trans } from '@lingui/macro';
import { useProjects } from 'app/api/useProjects';
import { Project } from 'app/apiTransformers/convertAPIProjectToProject';
import { useAppDispatch } from 'app/hooks';
import { NavbarContext, uiFlagsActions } from 'app/slices/uiFlagsSlice';
import React from 'react';
import { shallowEqual } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Cell, Column, Row, TableState } from 'react-table';
import {
  CheckboxForTableCell,
  CheckboxForTableHeader,
} from 'ui/common/Checkbox';
import Table from 'ui/common/Table/Table';
import {
  AppContentWithFooterWrapper,
  AppContentWrapper,
} from 'ui/common/layout/appLayout';
import { UpdateBanner } from 'ui/common/notifications/UpdateBanner';
import { H1 } from 'ui/common/typography/Typography';
import { ProjectsListTableData as TableData } from 'ui/dashboard/dashboardTypes';
import { ProjectEmptyState } from 'ui/dashboard/projectDetail/ProjectEmptyState';
import { FavoriteIconForTable } from 'ui/dashboard/projectsList/FavoriteIcon';
import { ProjectBadges } from 'ui/dashboard/projectsList/ProjectBadges';
import { ProjectListButtons } from 'ui/dashboard/projectsList/ProjectListButtons';
import DashboardLeftSidebar from 'ui/dashboard/sidebar/Sidebar';
import { formatDate } from 'util/dateUtils';
import { ProjectFilter, getProjectFilterLabel } from 'util/projectFilterUtils';

const Content = styled.div`
  padding: ${(props) => props.theme.spacing.xlarge};
  overflow-y: auto;
  width: 100%;
  background-color: ${(props) => props.theme.colors.grey[5]};
  pointer-events: auto;
`;

const Title = styled(H1)`
  margin-bottom: ${({ theme }) => theme.spacing.xxlarge};
`;

const Head = styled.header`
  margin-bottom: ${({ theme }) => theme.spacing.xxlarge};
`;

const DateBlock = styled.span`
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const TitleBlockWrapper = styled.div`
  display: flex;
`;

export const TitleBlock = styled.span`
  flex-shrink: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-right: ${({ theme }) => theme.spacing.small};
`;

const Date = ({ cell }: { cell: Cell<TableData> }) => (
  <DateBlock title={cell.value}>{formatDate(cell.value)}</DateBlock>
);

type Props = {
  projectFilter: ProjectFilter;
};

const ProjectNameSection = (
  cell: Cell<TableData>,
  { projectFilter }: Props,
) => (
  <TitleBlockWrapper>
    <TitleBlock>{cell.value}</TitleBlock>
    <ProjectBadges
      project={cell.row.values as Project}
      showPublicBadge={projectFilter !== 'public'}
    />
  </TitleBlockWrapper>
);

const ProjectsList: React.FC<Props> = ({ projectFilter }: Props) => {
  const navigate = useNavigate();
  const { projects, isLoadingProjects } = useProjects(projectFilter);
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    dispatch(uiFlagsActions.setNavbarContext(NavbarContext.Dashboard));
    return () => {
      dispatch(uiFlagsActions.setNavbarContext(NavbarContext.None));
    };
  }, [dispatch]);

  const [selectionState, setSelectionState] = React.useState<
    Record<string, boolean>
  >({});
  const updateRowsSelected = React.useCallback(
    (newSelectionState: Record<string, boolean>) => {
      if (!shallowEqual(newSelectionState, selectionState)) {
        setSelectionState(newSelectionState);
      }
    },
    [setSelectionState, selectionState],
  );

  const columns: Column<TableData>[] = [
    {
      Header: 'UUID',
      accessor: 'uuid',
      minWidth: 0,
      maxWidth: 0,
    },
    {
      Header: 'ISNEW',
      accessor: 'isNew',
      minWidth: 0,
      maxWidth: 0,
    },
    {
      Header: 'ISPUBLIC',
      accessor: 'isPublic',
      minWidth: 0,
      maxWidth: 0,
    },
    {
      Header: 'ISFAVORITE',
      accessor: 'isFavorite',
      minWidth: 0,
      maxWidth: 0,
    },
    {
      Header: 'ROLE',
      accessor: 'role',
      minWidth: 0,
      maxWidth: 0,
    },
    {
      Header: CheckboxForTableHeader,
      id: 'multiselect',
      minWidth: 24,
      maxWidth: 0,
      Cell: CheckboxForTableCell,
    },
    {
      Header: '',
      id: 'favorite_icon',
      minWidth: 24,
      maxWidth: 0,
      Cell: FavoriteIconForTable,
    },
    {
      Header: <Trans id="dashboard.projects.table.name">Name</Trans>,
      accessor: 'title',
      minWidth: 200,
      maxWidth: 1,
      Cell: ({ cell }: { cell: Cell<TableData> }) =>
        ProjectNameSection(cell, { projectFilter }),
    },
    {
      Header: (
        <Trans id="dashboard.projects.table.lastEdited">Last Edited</Trans>
      ),
      accessor: 'updatedAt',
      minWidth: 140,
      maxWidth: 0,
      Cell: Date,
    },
    {
      Header: <Trans id="dashboard.projects.table.created">Created</Trans>,
      accessor: 'createdAt',
      minWidth: 140,
      maxWidth: 0,
      Cell: Date,
    },
  ];

  const hiddenColumns = ['uuid', 'isNew', 'isPublic', 'isFavorite', 'role'];
  if (projectFilter === 'public') {
    hiddenColumns.push('favorite_icon');
  }

  const initialState: Partial<TableState<TableData>> = {
    hiddenColumns,
    sortBy: [{ id: 'updatedAt', desc: true }],
  };

  const numSelected = React.useMemo(
    () =>
      Object.values(selectionState).reduce(
        (acc: number, val) => acc + Number(val),
        0,
      ),
    [selectionState],
  );

  return (
    <AppContentWithFooterWrapper>
      <AppContentWrapper>
        <UpdateBanner />
        <DashboardLeftSidebar />
        <Content>
          <Head>
            <Title>{getProjectFilterLabel(projectFilter)}</Title>
            <ProjectListButtons
              projectFilter={projectFilter}
              selectionState={selectionState}
              numSelected={numSelected}
            />
          </Head>

          {!isLoadingProjects &&
            projects &&
            (projects.length === 0 ? (
              <ProjectEmptyState projectFilter={projectFilter} />
            ) : (
              <Table
                columns={columns}
                data={projects}
                initialState={initialState}
                testId="project-list-table"
                onRowClick={(data) => {
                  if (numSelected === 0) navigate(`/projects/${data.uuid}`);
                }}
                onRowDoubleClick={(data) => navigate(`/projects/${data.uuid}`)}
                getRowTestId={(row: Row<TableData>) => row.original.uuid}
                getRowsSelected={updateRowsSelected}
                enableMultiSelect
              />
            ))}
        </Content>
      </AppContentWrapper>
    </AppContentWithFooterWrapper>
  );
};

export default ProjectsList;
