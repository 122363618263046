/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Lookuptable2d: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M8.50001 4.5V13H7.50001V4.5H8.50001ZM29.5 19.5H31C31.2022 19.5 31.3846 19.6218 31.462 19.8087C31.5393 19.9955 31.4966 20.2106 31.3536 20.3536L24.3536 27.3536C24.2598 27.4473 24.1326 27.5 24 27.5H1.00001C0.797783 27.5 0.615465 27.3782 0.538075 27.1913C0.460684 27.0045 0.503462 26.7894 0.646461 26.6464L2.00001 25.2929L2.70712 26L2.20712 26.5H23.7929L29.7929 20.5H29.5V19.5Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M21.25 5.25H25.75V6.25H28.75V21H27.25V7.75H25.75V12.5H24.25V6.75H22.75V12.5H21.25V7.71H19.75V12.5H18.25V8.75H16.75V12.5H15.25V9.75H13.75L13.75 13.5H12.25L12.25 8.03H10.75V12.5H9.25001V6.53H13.75V8.25H15.25V7.25H18.25V6.21H21.25V5.25Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M15.25 13.25H19.75V14.79H21.25V13.29H25.75V23.5H24.25V14.79H22.75V18.5H21.25V16.29H19.75V18.5H18.25V14.75H16.75V20H15.25V15.79H13.75V19.5H12.25V16.79H10.75V18.5H9.25002V15.07H7.75001V18.5H6.25001V13.57H10.75V15.29H12.25V14.29H15.25V13.25Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M6.25002 19.25H10.75V20.29H13.75L13.75 21.29H15.25L15.25 20.79H18.25L18.25 19.29H22.75L22.75 25.5H21.25L21.25 20.79H19.75L19.75 25.5H18.25L18.25 22.29H16.75L16.75 25.5H15.25L15.25 22.79H13.75L13.75 25.5H12.25L12.25 21.79H10.75L10.75 25.5H9.25002L9.25002 20.75H7.75002L7.75002 25.5H6.25002L6.25002 21.75H4.75002L4.75002 25.5H3.25002L3.25002 20.25H6.25002L6.25002 19.25Z"
      fill={props.fill}
    />
  </svg>
);

Lookuptable2d.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Lookuptable2d;
