import React from 'react';
import { useGetSimulationReadAllQuery } from 'app/apiGenerated/generatedApi';

export function useModelSimulations(modelId: string) {
  const { data, isLoading } = useGetSimulationReadAllQuery({
    modelUuid: modelId,
  });

  return {
    isLoadingModelSimulations: isLoading,
    modelSimulations: data,
  };
}
