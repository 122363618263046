import styled from '@emotion/styled';
import { useAdminApis } from 'app/api/useAdminApis';
import {
  useDeleteUserOptionOverridesByUuidMutation,
  useGetUserOptionOverridingUsersListQuery,
} from 'app/enhancedApi';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Cell, Column, TableState } from 'react-table';
import Button from 'ui/common/Button/Button';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import * as Icons from 'ui/common/Icons';
import { BackArrow } from 'ui/common/Icons/Standard';
import Table from 'ui/common/Table/Table';
import TabGroup from 'ui/common/tabs/TabGroup';
import { TabInfo } from 'ui/common/tabs/tabTypes';
import * as Headings from 'ui/common/typography/Typography';
import { formatDate } from 'util/dateUtils';
import { getStringSorter } from 'util/getStringSorter';
import { useAppParams } from 'util/useAppParams';

type Tab = 'usersWithOptionOverride';

const Head = styled.header(({ theme }) => ({
  display: 'flex',
  width: ' 100%',
  columnGap: 8,
}));

const Title = styled(Headings.H2)(({ theme }) => ({
  width: '100%',
}));

const DetailWrapper = styled.div(({ theme }) => ({
  width: `calc(100% - ${theme.spacing.xxlarge} - ${theme.spacing.xxlarge})`,
  height: `calc(100% - ${theme.spacing.xxlarge} - ${theme.spacing.xxlarge})`,
  margin: theme.spacing.xxlarge,
  padding: theme.spacing.xlarge,
  background: theme.colors.grey[5],
  borderRadius: theme.spacing.small,
  overflowX: 'auto',
  overflowY: 'auto',
}));

const ActionSection = styled.div(({ theme }) => ({
  marginBottom: theme.spacing.large,
  display: 'flex',
  width: '100%',
  columnGap: '8px',
}));

const DateCell: React.FC<any> = ({ value }) => (
  <span title={value}>{formatDate(value)}</span>
);

interface TableItem extends Record<string, unknown> {
  user_uuid: string;
  display_name?: string;
  identifier: string;
  organization: string;
  override_updated_at: string;
  override_uuid: string;
  override_value: string;
}

const UsersWithOptionOverride: React.FC<{ optionId: string }> = ({
  optionId,
}) => {
  const navigate = useNavigate();

  const { data: apiData } = useGetUserOptionOverridingUsersListQuery(
    { userOptionUuid: optionId || '' },
    { skip: !optionId, refetchOnMountOrArgChange: true },
  );
  const [callDeleteUserOptionOverrideApi] =
    useDeleteUserOptionOverridesByUuidMutation();

  const RemoveCell = React.useCallback(
    ({ cell }: { cell: Cell<TableItem> }) => {
      const onDelete = (userOptionOverrideUuid: string) =>
        callDeleteUserOptionOverrideApi({ userOptionOverrideUuid });
      return (
        <Button
          Icon={Icons.Standard.Remove}
          variant={ButtonVariants.LargeTertiary}
          onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            e.preventDefault();
            onDelete(cell.row.original.override_uuid);
          }}
        />
      );
    },
    [callDeleteUserOptionOverrideApi],
  );

  if (!apiData) {
    return null;
  }

  const sortedUsers = apiData.overrides
    .filter((o) => !!o)
    .sort(getStringSorter('name'));

  const tableData: TableItem[] = sortedUsers.map((o) => ({
    user_uuid: o.user.uuid,
    display_name: o.user.display_name,
    identifier: o.user.identifier,
    organization: o.user.organization,
    override_updated_at: o.user_option_override.updated_at,
    override_uuid: o.user_option_override.uuid,
    override_value: o.user_option_override.value,
  }));

  const tableColumns: Column<TableItem>[] = [
    {
      Header: 'Name',
      accessor: 'display_name',
      minWidth: 50,
      maxWidth: 100,
    },
    {
      Header: 'Identifier',
      accessor: 'identifier',
      minWidth: 200,
      maxWidth: 500,
    },
    // {
    //   Header: 'Organization',
    //   accessor: 'organization',
    //   minWidth: 100,
    //   maxWidth: 300,
    // },
    {
      Header: 'Option value',
      accessor: 'override_value',
      minWidth: 100,
      maxWidth: 300,
    },
    {
      Header: 'Option set at',
      accessor: 'override_updated_at',
      minWidth: 100,
      maxWidth: 300,
      Cell: DateCell,
    },
    {
      Header: '',
      id: 'remove',
      disableSortBy: true,
      minWidth: 20,
      maxWidth: 0,
      disableResizing: true,
      Cell: RemoveCell,
    },
    {
      Header: 'ID',
      accessor: 'override_uuid',
      minWidth: 200,
      maxWidth: 500,
    },
  ];

  const onRowDoubleClick = (row: TableItem) => {
    navigate(`/admin/users/${row.user_uuid}`);
  };

  const initialState: Partial<TableState<TableItem>> = {
    hiddenColumns: ['override_uuid'],
  };

  return (
    <>
      <ActionSection>
        <Button
          variant={ButtonVariants.LargeSecondary}
          Icon={BackArrow}
          onClick={() => navigate('/admin')}>
          Admin portal
        </Button>
        <Button onClick={() => navigate('/admin/user_options')}>
          Global user options
        </Button>
      </ActionSection>
      <Table
        data={tableData}
        columns={tableColumns}
        initialState={initialState}
        onRowDoubleClick={onRowDoubleClick}
      />
    </>
  );
};

const UserDetail: React.FC = ({}) => {
  const { optionId } = useAppParams();
  const { userOptions } = useAdminApis();

  const option = userOptions?.user_options.find(
    (option) => option.uuid === optionId,
  );

  const [activeTab, setActiveTab] = React.useState<Tab>(
    'usersWithOptionOverride',
  );
  if (!optionId || !option) return <>User option {optionId} not found.</>;

  const tabs: TabInfo[] = [
    {
      id: 'usersWithOptionOverride',
      isSelected: () => activeTab === 'usersWithOptionOverride',
      displayName: 'Users with option',
      onClick: () => setActiveTab('usersWithOptionOverride'),
    },
  ];

  return (
    <DetailWrapper>
      <Head>
        <Title>
          User option: {option.name} (default: {option.value})
        </Title>
      </Head>
      <TabGroup tabs={tabs} />
      {activeTab === 'usersWithOptionOverride' && (
        <UsersWithOptionOverride optionId={optionId} />
      )}
    </DetailWrapper>
  );
};

export default UserDetail;
