import {
  NodeInstance,
  LinkInstance,
} from 'app/generated_types/SimulationModel';

const DEFAULT_OF_FIRST_OUTPORT_NAME_OF_BLOCK = 'out_0';
const DEFAULT_OF_FIRST_OUTPORT_NAME_OF_SUBMODEL = 'Outport_0';

function getHasDefaultName(portName: string) {
  return (
    portName === DEFAULT_OF_FIRST_OUTPORT_NAME_OF_BLOCK ||
    DEFAULT_OF_FIRST_OUTPORT_NAME_OF_SUBMODEL
  );
}

function getIsTheOnlyPort(
  portName: string,
  portIndex: number,
  nodeWithAssociatedPort: NodeInstance,
) {
  // The first,
  if (portIndex !== 0) {
    return false;
  }

  // the only,
  if (nodeWithAssociatedPort.outputs.length !== 1) {
    return false;
  }

  // and the same one.
  if (nodeWithAssociatedPort.outputs[0].name !== portName) {
    return false;
  }

  return true;
}

function getAssociatedLink(
  portIndex: number,
  sourceNodeId: string,
  links: LinkInstance[] | undefined,
) {
  if (!links) {
    return;
  }
  return links.find(
    (link) => link.src?.node === sourceNodeId && link.src?.port === portIndex,
  );
}

export function getSignalDisplayName(
  blockName: string,
  portName: string,
  portIndex: number,
  sourceNodeId: string,
  nodeWithAssociatedPort: NodeInstance,
  links: LinkInstance[] | undefined,
) {
  // If the associated link has a user defined signal name, use that name instead.
  const link = getAssociatedLink(portIndex, sourceNodeId, links);
  if (link && link.name) {
    return link.name;
  }

  // Omit the port name for blocks with only a single default port.
  if (
    getHasDefaultName(portName) &&
    getIsTheOnlyPort(portName, portIndex, nodeWithAssociatedPort)
  ) {
    return blockName;
  }

  // Show the block name combined with port name by default.
  return `${blockName}.${portName}`;
}
