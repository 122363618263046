// !!!! -- DO NOT MANUALLY EDIT -- !!!!
// This file was generated by something in scripts/generateDefaultBlockData.js
// If you want to change the contents of this file, it should be automated
//
// (You can edit this file locally, but it may not be checked into the codebase -
// PRs with a manual edit of this file are forbidden from being merged.)

import { computationBlockDefaults } from 'app/generated_blocks/computationBlockDefaults';
import type { ComputationBlockClass } from 'app/generated_types/ComputationBlockClass';
import deepmerge from 'deepmerge';

export const CoordinateRotationConversion: ComputationBlockClass = deepmerge(
  computationBlockDefaults,
  {
    "base": {
      "name": "CoordinateRotationConversion",
      "namespace": "core",
      "description": "computes conversion between coordinate rotations, e.g. quaternion to DCM, etc.",
      "keywords": [
        "quaternion",
        "dcm",
        "rotation",
        "angles",
        "euler",
        "angle",
        "conversion",
        "coordinate"
      ],
      "development_notes": "when quaternion_to_X selected: expose w,x,y,z inports. when rotation_angle_to_X selected, alpha,beta,gamma, ports. when DCM_to slected, DCM inport. and vice versa for outports."
    },
    "modes": {
      "time": "agnostic"
    },
    "ports": {
      "inputs": {
        "static": [
          {
            "name": "rotation_in"
          }
        ]
      },
      "outputs": {
        "static": [
          {
            "name": "rotation_out"
          }
        ]
      }
    },
    "parameter_definitions": [
      {
        "name": "conversion_type",
        "data_type": "string",
        "default_value": "quaternion_to_DCM",
        "description": "Select the rotation conversion type desired.",
        "allowed_values": [
          "quaternion_to_DCM",
          "quaternion_to_RPY",
          "DCM_to_quaternion",
          "DCM_to_RPY",
          "RPY_to_quaternion",
          "RPY_to_DCM"
        ],
        "display_full_width": true
      }
    ]
  }
);
