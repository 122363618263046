/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  className?: string;
}

const LogOutput: React.FC<Props> = (props) => (
  <svg viewBox="0 0 16 16" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.00001 5C9.87316 5 10.6406 5.14248 11.1713 5.35476C11.4381 5.46146 11.6204 5.576 11.7278 5.67762C11.8335 5.77752 11.8334 5.83027 11.8333 5.83328C11.8333 5.83779 11.8318 5.89073 11.7278 5.98905C11.6204 6.09066 11.4381 6.20521 11.1713 6.3119C10.6406 6.52419 9.87316 6.66667 9.00001 6.66667C8.48135 6.66667 7.99998 6.61639 7.58337 6.53108L8.7144 7.66211C8.80883 7.66513 8.90408 7.66667 9.00001 7.66667C9.96781 7.66667 10.867 7.51067 11.5427 7.24038C11.6427 7.20039 11.74 7.15703 11.8333 7.11013V8.5C11.8333 8.50451 11.8318 8.5574 11.7278 8.65572C11.6204 8.75733 11.4381 8.87187 11.1713 8.97857C10.6406 9.19086 9.87316 9.33333 9.00001 9.33333C8.6857 9.33333 8.38509 9.31487 8.10429 9.28134L7.23926 10.1464C7.76856 10.2672 8.36974 10.3333 9.00001 10.3333C9.96781 10.3333 10.867 10.1773 11.5427 9.90705C11.6427 9.86706 11.74 9.8237 11.8333 9.7768V11.1667C11.8334 11.1697 11.8335 11.2225 11.7278 11.3224C11.6204 11.424 11.4381 11.5385 11.1713 11.6452C10.6406 11.8575 9.87316 12 9.00001 12C8.12686 12 7.3594 11.8575 6.82868 11.6452C6.56194 11.5385 6.37963 11.424 6.27218 11.3224C6.21827 11.2714 6.1919 11.2327 6.17901 11.2066L5.94282 11.4428C5.77499 11.6106 5.57355 11.7244 5.36017 11.784C5.42513 11.8822 5.50231 11.9707 5.58507 12.0489C5.81498 12.2664 6.12082 12.4391 6.45729 12.5737C7.13301 12.844 8.03221 13 9.00001 13C9.96781 13 10.867 12.844 11.5427 12.5737C11.8792 12.4391 12.185 12.2664 12.4149 12.0489C12.6459 11.8305 12.8333 11.5325 12.8333 11.1667V5.83333C12.8333 5.46746 12.6459 5.16948 12.4149 4.95106C12.185 4.73364 11.8792 4.56087 11.5427 4.42629C10.867 4.156 9.96781 4 9.00001 4C8.03221 4 7.13301 4.156 6.45729 4.42629C6.21958 4.52137 5.99716 4.63551 5.806 4.77099C5.85361 4.8072 5.89934 4.84705 5.94282 4.89052L6.54142 5.48913C6.6233 5.44397 6.71883 5.3987 6.82868 5.35476C7.3594 5.14248 8.12686 5 9.00001 5Z"
      fill={props.fill}
    />
    <path
      d="M5.35356 5.47978C5.1583 5.28452 4.84172 5.28452 4.64646 5.47978C4.45119 5.67504 4.45119 5.99162 4.64646 6.18689L6.12624 7.66667H2.33334C2.0572 7.66667 1.83334 7.89052 1.83334 8.16667C1.83334 8.44281 2.0572 8.66667 2.33334 8.66667H6.12624L4.64646 10.1464C4.45119 10.3417 4.45119 10.6583 4.64646 10.8536C4.84172 11.0488 5.1583 11.0488 5.35356 10.8536L8.04045 8.16667L5.35356 5.47978Z"
      fill={props.fill}
    />
  </svg>
);

LogOutput.defaultProps = {
  width: '16',
  height: '16',
  viewBox: '0 0 16 16',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  className: '',
};

export default LogOutput;
