import styled from '@emotion/styled/macro';
import { t } from '@lingui/macro';
import { useGetProjectGitRemoteFilesQuery } from 'app/apiGenerated/generatedApi';
import { usePostProjectGitResetFromUpstreamMutation } from 'app/enhancedApi';
import { useAppSelector } from 'app/hooks';
import React from 'react';
import Button from 'ui/common/Button/Button';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import { Reset } from 'ui/common/Icons/Standard';
import { ActionButtonContainer } from 'ui/common/Modal/Modal';
import { useModal } from 'ui/common/Modal/useModal';
import { Spinner } from 'ui/common/Spinner';
import { useNotifications } from 'ui/common/notifications/useNotifications';
import { useAppParams } from 'util/useAppParams';

const ResetBranchContainer = styled.div`
  width: 350px;
`;

const ResetWarning = styled.div`
  line-height: 1.5em;
  margin-bottom: ${({ theme }) => theme.spacing.large};
`;

interface Props {
  branch?: string;
}

export const GitResetBranchModal = ({ branch }: Props) => {
  const { closeModal } = useModal();
  const { showInfo, createShowError } = useNotifications();
  const { projectId } = useAppParams();
  const { developerModeEnabled } = useAppSelector(
    (state) => state.userOptions.options,
  );

  const [resetting, setResetting] = React.useState(false);

  const { data: remoteFilesData, isFetching } =
    useGetProjectGitRemoteFilesQuery(
      {
        projectUuid: projectId || '',
        branch,
      },
      {
        skip: !projectId || !branch || !developerModeEnabled,
        refetchOnMountOrArgChange: true,
      },
    );

  const [callGitResetBranchApi] = usePostProjectGitResetFromUpstreamMutation();

  const showFileListDebug = developerModeEnabled;

  const onReset = () => {
    if (!projectId || !branch) return;

    setResetting(true);

    callGitResetBranchApi({
      projectUuid: projectId,
      projectGitResetFromUpstreamRequest: { branch },
    })
      .unwrap()
      .then(() => showInfo(t`Project succesfully reset from git!`))
      .catch(createShowError(t`Error resetting project from git!`))
      .finally(() => {
        setResetting(false);
        closeModal();
      });
  };

  return (
    <ResetBranchContainer>
      <ResetWarning>
        {t({
          id: 'gitResetBranchModal.warning',
          message:
            'Are you sure you want to reset? All changes made since ' +
            'checking out or pushing this branch will be discarded.',
        })}
      </ResetWarning>
      {showFileListDebug && !isFetching && (
        <ul>
          {remoteFilesData?.files?.map((file, index) => (
            <li key={index}>
              <a href={file.html_url} target="_blank" rel="noreferrer">
                {file.path}
              </a>
            </li>
          ))}
        </ul>
      )}
      <ActionButtonContainer>
        <Button
          type="button"
          onClick={closeModal}
          variant={ButtonVariants.LargeSecondary}>
          {t({
            id: 'gitResetBranchModal.cancelButton.label',
            message: 'Cancel',
          })}
        </Button>
        <Button
          type="submit"
          Icon={resetting || isFetching ? Spinner : Reset}
          variant={ButtonVariants.Danger}
          disabled={resetting || isFetching}
          onClick={() => onReset()}>
          {t({
            id: 'gitResetBranchModal.checkoutButton.label',
            message: 'Reset branch',
          })}
        </Button>
      </ActionButtonContainer>
    </ResetBranchContainer>
  );
};
