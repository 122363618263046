/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  className?: string;
}

const Magnetometer: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.2121 6.97599C12.2228 6.99199 12.2441 6.99999 12.2761 6.99999H12.9641C13.0068 6.99999 13.0388 6.98399 13.0601 6.95199L13.9961 5.58399C14.0014 5.57333 14.0094 5.56799 14.0201 5.56799C14.0308 5.56799 14.0388 5.57333 14.0441 5.58399L14.9721 6.95199C14.9934 6.98399 15.0254 6.99999 15.0681 6.99999H15.7241C15.7721 6.99999 15.7961 6.98133 15.7961 6.94399L15.7801 6.90399L14.4521 4.99199C14.4468 4.97599 14.4468 4.95999 14.4521 4.94399L15.7801 3.02399C15.7961 2.99733 15.7988 2.97599 15.7881 2.95999C15.7774 2.93866 15.7561 2.92799 15.7241 2.92799H15.0281C14.9854 2.92799 14.9534 2.94399 14.9321 2.97599L14.0041 4.34399C13.9988 4.34933 13.9908 4.35199 13.9801 4.35199C13.9694 4.35199 13.9614 4.34933 13.9561 4.34399L13.0201 2.97599C12.9988 2.94399 12.9668 2.92799 12.9241 2.92799H12.2761C12.2281 2.92799 12.2041 2.94666 12.2041 2.98399L12.2201 3.02399L13.5481 4.94399C13.5588 4.95999 13.5588 4.97599 13.5481 4.99199L12.2201 6.90399C12.2041 6.93066 12.2014 6.95466 12.2121 6.97599Z"
      fill={props.fill}
    />
    <path
      d="M19.143 5.1501C19.3362 4.95284 19.6528 4.94958 19.8501 5.14281L21.9573 7.20706L19.8573 9.34996C19.664 9.54718 19.3474 9.55038 19.1502 9.3571C18.953 9.16382 18.9498 8.84725 19.1431 8.65003L19.9966 7.77914C14.2496 8.38384 10.8237 13.1202 10.7514 17.9164C12.0372 19.7122 14.4034 20.7734 16.9379 21.1345C19.0702 21.4382 21.2238 21.2316 22.8058 20.625L21.2977 19.9572C21.0452 19.8454 20.9312 19.55 21.043 19.2975C21.1548 19.045 21.4502 18.931 21.7027 19.0428L24.6503 20.3482L23.4615 23.1928C23.355 23.4476 23.0622 23.5678 22.8074 23.4613C22.5526 23.3549 22.4324 23.062 22.5389 22.8072L23.0414 21.6047C21.2705 22.253 18.9886 22.4366 16.7969 22.1245C14.1541 21.748 11.5074 20.6305 9.99117 18.5714H3.23903L4.34726 19.6401C4.54604 19.8318 4.55179 20.1483 4.36011 20.3471C4.16843 20.5458 3.8519 20.5516 3.65312 20.3599L1.29297 18.0841L3.64049 15.6527C3.83229 15.454 4.14883 15.4485 4.34749 15.6403C4.54614 15.8321 4.5517 16.1486 4.35989 16.3473L3.17797 17.5714H9.76134C9.99236 12.3877 13.8 7.32885 20.0781 6.76608L19.1503 5.85717C18.953 5.66393 18.9498 5.34737 19.143 5.1501Z"
      fill={props.fill}
    />
    <path
      d="M13.7502 18.5C13.7502 14.7721 16.7723 11.75 20.5002 11.75C20.766 11.75 21.0282 11.7654 21.286 11.7952L21.0151 12.8787C20.9481 13.1466 21.111 13.4181 21.3789 13.4851C21.6468 13.552 21.9183 13.3892 21.9853 13.1213L22.2695 11.9843C25.1392 12.7617 27.2502 15.3843 27.2502 18.5C27.2502 22.2279 24.2281 25.25 20.5002 25.25C18.2926 25.25 16.3325 24.1902 15.101 22.5517C14.3605 22.3617 13.629 22.1101 12.9326 21.7907C13.7983 23.7787 15.4243 25.3593 17.4435 26.1652L17.0151 27.8787C16.9481 28.1466 17.111 28.4181 17.3789 28.4851C17.6468 28.552 17.9183 28.3892 17.9853 28.1213L18.3958 26.4792C19.0676 26.6559 19.7729 26.75 20.5002 26.75C25.0565 26.75 28.7502 23.0563 28.7502 18.5C28.7502 14.6814 26.1558 11.4687 22.6335 10.5285L22.9853 9.12126C23.0522 8.85336 22.8894 8.5819 22.6215 8.51492C22.3536 8.44795 22.0821 8.61083 22.0151 8.87873L21.6523 10.3298C21.2758 10.2772 20.8912 10.25 20.5002 10.25C15.9645 10.25 12.2837 13.9102 12.2504 18.438C12.7038 18.8248 13.2317 19.1609 13.8156 19.4436C13.7725 19.1353 13.7502 18.8202 13.7502 18.5Z"
      fill={props.fill}
    />
    <path
      d="M18.0378 20.592C18.0005 20.592 17.9818 20.5653 17.9818 20.512V20.04C17.9818 19.9867 18.0085 19.96 18.0618 19.96H18.0778C18.2912 19.9547 18.4592 19.928 18.5818 19.88C18.7045 19.832 18.8058 19.7413 18.8858 19.608C18.9658 19.48 19.0432 19.2853 19.1178 19.024C19.1232 19.0187 19.1232 19.0107 19.1178 19V18.976L17.7818 15.016L17.7738 14.984C17.7738 14.9467 17.7978 14.928 17.8458 14.928H18.4938C18.5418 14.928 18.5738 14.9493 18.5898 14.992L19.4938 18.008C19.4992 18.0187 19.5072 18.024 19.5178 18.024C19.5285 18.024 19.5365 18.0187 19.5418 18.008L20.4378 14.992C20.4538 14.9493 20.4858 14.928 20.5338 14.928H21.1658C21.2245 14.928 21.2458 14.9573 21.2298 15.016L19.7738 19.28C19.6512 19.648 19.5285 19.92 19.4058 20.096C19.2832 20.2773 19.1232 20.4053 18.9258 20.48C18.7285 20.5547 18.4512 20.592 18.0938 20.592H18.0378Z"
      fill={props.fill}
    />
    <path
      d="M5.9355 16.42C5.9355 16.4733 5.96217 16.5 6.0155 16.5H8.9515C9.00483 16.5 9.0315 16.4733 9.0315 16.42V15.884C9.0315 15.8307 9.00483 15.804 8.9515 15.804H6.8555C6.84483 15.804 6.83683 15.8013 6.8315 15.796C6.82617 15.7853 6.82883 15.7747 6.8395 15.764L8.9915 13.172C9.01283 13.1453 9.0235 13.1133 9.0235 13.076V12.508C9.0235 12.4547 8.99683 12.428 8.9435 12.428H6.0555C6.00217 12.428 5.9755 12.4547 5.9755 12.508V13.052C5.9755 13.1053 6.00217 13.132 6.0555 13.132H8.0715C8.08217 13.132 8.09017 13.1373 8.0955 13.148C8.10083 13.1533 8.09817 13.1613 8.0875 13.172L5.9675 15.764C5.94617 15.7907 5.9355 15.8227 5.9355 15.86V16.42Z"
      fill={props.fill}
    />
  </svg>
);

Magnetometer.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  className: '',
};

export default Magnetometer;
