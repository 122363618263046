/* eslint-disable import/order */
import styled from '@emotion/styled/macro';
import { useSubmodels } from 'app/api/useSubmodels';
import { useAppDispatch } from 'app/hooks';
import { NavbarContext, uiFlagsActions } from 'app/slices/uiFlagsSlice';
import React from 'react';
import {
  AppContentWithFooterWrapper,
  AppContentWrapper,
} from 'ui/common/layout/appLayout';
import { DataExplorerBreadcrumb } from 'ui/dataExplorer/DataExplorerBreadcrumb';
import { DataExplorerLeftSidebar } from 'ui/dataExplorer/DataExplorerLeftSidebar';
import { DataExplorerGrid } from 'ui/dataExplorer/grid/DataExplorerGrid';
import { DataExplorerExplorationLoader } from 'ui/dataExplorer/loaders/DataExplorerExplorationLoader';
import { DataExplorerModelVersionsLoader } from 'ui/dataExplorer/loaders/DataExplorerModelVersionsLoader';
import { DataExplorerPlotLoader } from 'ui/dataExplorer/loaders/DataExplorerPlotLoader';
import { DataExplorerSignalLoader } from 'ui/dataExplorer/loaders/DataExplorerSignalLoader';
import { DataExplorerSignalTraceLoader } from 'ui/dataExplorer/loaders/DataExplorerSignalTraceLoader';
import { DataExplorerSimulationSignalsListLoader } from 'ui/dataExplorer/loaders/DataExplorerSimulationSignalsListLoader';
import { useProjectPermission } from 'ui/permission/useProjectPermission';

const DataExplorerContentWrapper = styled.div`
  flex: 1 0 50px;
  position: relative;
  border: 1px solid ${({ theme }) => theme.colors.grey[10]};
  background: ${({ theme }) => theme.colors.grey[5]};
  overflow-y: hidden;
  pointer-events: auto;
`;

const DataExplorerBreadcrumbSpacer = styled.div`
  width: 100%;
  height: 40px;
`;

interface Props {
  projectId: string;
  explorationId: string;
}

export const DataExplorer: React.FC<Props> = ({ projectId, explorationId }) => {
  const dispatch = useAppDispatch();

  useSubmodels(projectId);

  const { canEditProject } = useProjectPermission(projectId);

  // Set the navbar content for Data Explorer.
  React.useEffect(() => {
    dispatch(uiFlagsActions.setNavbarContext(NavbarContext.DataExplorer));
    return () => {
      dispatch(uiFlagsActions.setNavbarContext(NavbarContext.None));
    };
  }, [dispatch]);

  return (
    <AppContentWithFooterWrapper>
      <AppContentWrapper>
        {/* Data loaders to populate redux store (these components are not visible to user) */}
        <DataExplorerExplorationLoader explorationId={explorationId} />
        <DataExplorerSignalLoader />
        <DataExplorerSignalTraceLoader />
        <DataExplorerSimulationSignalsListLoader />
        <DataExplorerModelVersionsLoader />
        <DataExplorerPlotLoader explorationId={explorationId} />

        {/* Left sidebar */}
        <DataExplorerLeftSidebar
          projectId={projectId}
          explorationId={explorationId}
          canEditProject={canEditProject}
        />

        {/* Center content for the exploration grid */}
        <DataExplorerContentWrapper>
          <DataExplorerBreadcrumb
            projectId={projectId}
            explorationId={explorationId}
          />
          <DataExplorerBreadcrumbSpacer />
          <DataExplorerGrid canEditVisualization={canEditProject} />
        </DataExplorerContentWrapper>
      </AppContentWrapper>
    </AppContentWithFooterWrapper>
  );
};
