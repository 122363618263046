import { t } from '@lingui/macro';
import { useBusTypeActions } from 'app/api/useBusTypeActions';
import { useDeleteSubmodel } from 'app/api/useDeleteSubmodel';
import { useDuplicateSubmodel } from 'app/api/useDuplicateSubmodel';
import { useModels } from 'app/api/useModels';
import { useProject } from 'app/api/useProject';
import { useProjectActions } from 'app/api/useProjectActions';
import { useProjectItems } from 'app/api/useProjectItems';
import { useUpdateSubmodel } from 'app/api/useUpdateSubmodel';
import { SubmodelInfoLiteUI } from 'app/apiTransformers/convertGetSubmodelsList';
import { useAppSelector } from 'app/hooks';
import { getNextValidIdentifier } from 'app/transformers/uniqueNameGenerators';
import React from 'react';
import { useModal } from 'ui/common/Modal/useModal';
import { useNotifications } from 'ui/common/notifications/useNotifications';
import {
  ProjectItemType,
  ProjectsDetailTableData,
  ProjectsDetailTableData as TableData,
} from 'ui/dashboard/dashboardTypes';
import DuplicateModal from 'ui/dashboard/projectDetail/DuplicateModal';
import RenameModal from 'ui/dashboard/projectDetail/RenameModal';
import DeleteConfirmationModal from 'ui/dashboard/projectsList/DeleteConfirmationModal';
import { splitFullName } from 'util/fileUtils';

const MULTIPLE = 'multiple';

const sameTypeItems = (
  name: string,
  itemType: ProjectItemType,
  projectItems: TableData[],
) => {
  if (itemType === ProjectItemType.PROJECTFILE) {
    const [_, extension] = splitFullName(name);
    return projectItems
      .map((d) => splitFullName(d.name))
      .filter(([_, ext]) => ext === extension)
      .map(([basename, _]) => basename);
  }
  return projectItems
    .filter((d) => d.type !== ProjectItemType.PROJECTFILE)
    .map((value) => value.name);
};

export function useProjectDetailActions(
  selectedItems?: ProjectsDetailTableData[],
) {
  const { project } = useProject();
  const { projectItems } = useProjectItems(project);

  const { deleteFile, downloadFile, updateFile, duplicateFile } =
    useProjectActions();
  const { updateModelSummary, deleteModel, duplicateModel } = useModels();
  const { deleteSubmodel } = useDeleteSubmodel();
  const { updateSubmodelName } = useUpdateSubmodel();
  const { duplicateSubmodel } = useDuplicateSubmodel();
  const { showCompletion } = useNotifications();
  const { renameBusType, deleteBusType } = useBusTypeActions();

  const { triggerModal, closeModal } = useModal();
  const queryParams = new URLSearchParams(window.location.search);
  const path = queryParams.get('path') ?? '';

  const type =
    selectedItems && selectedItems.length > 1
      ? MULTIPLE
      : selectedItems?.[0]?.type;

  const submodels = useAppSelector(
    (state) => state.submodels.projectIdToSubmodelInfoLites,
  );

  // FIXME: each delete function should return a promise
  const deleteElement = React.useCallback(
    ({ uuid, projectUuid, type, path }: TableData) => {
      switch (type) {
        case ProjectItemType.PROJECTFILE:
          deleteFile({ projectUuid, fileUuid: uuid });
          break;
        case ProjectItemType.MODEL:
          deleteModel(uuid);
          break;
        case ProjectItemType.SUBMODEL:
          deleteSubmodel({
            projectUuid,
            submodelUuid: uuid,
          });
          break;
        case ProjectItemType.BUS_TYPE:
          deleteBusType(projectUuid, uuid);
          break;
        case ProjectItemType.FOLDER:
          projectItems
            .filter((item) => item.path.startsWith(path))
            .forEach((item) => deleteElement(item));

          break;
      }
    },
    [deleteBusType, deleteFile, deleteModel, deleteSubmodel, projectItems],
  );

  const deleteSelectedElements = React.useCallback(
    async (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e?.stopPropagation();

      const onDelete = () => {
        // FIXME: each delete function should return a promise - then we can await them all
        selectedItems?.forEach((data: TableData) => deleteElement(data));
        showCompletion(
          t({
            id: 'dashboard.projects.deleteItems.notification',
            message: 'Item(s) deleted',
          }),
        );
      };

      if (!type) {
        return;
      }

      let confirmationTitleText = '';
      let confirmationMessageText = '';

      switch (type) {
        case MULTIPLE:
          confirmationTitleText = t({
            id: 'deleteMultipleModal.messageTitle.text',
            message: 'Delete?',
          });
          confirmationMessageText = t({
            id: 'deleteMultipleModal.messageContent.text',
            message: 'Are you sure you want to delete these items?',
          });
          break;
        case ProjectItemType.MODEL:
          confirmationTitleText = t({
            id: 'deleteModelModal.messageTitle.text',
            message: 'Delete Model?',
          });
          confirmationMessageText = t({
            id: 'deleteModelModal.messageContent.text',
            message: 'Are you sure you want to delete this model?',
          });
          break;
        case ProjectItemType.SUBMODEL:
          confirmationTitleText = t({
            id: 'deleteSubmodelModal.messageTitle.text',
            message: 'Delete Submodel?',
          });
          confirmationMessageText = t({
            id: 'deleteProjectSubmodelModal.messageContent.text',
            message: 'Are you sure you want to delete this submodel?',
          });
          break;
        case ProjectItemType.BUS_TYPE:
          confirmationTitleText = t({
            id: 'deleteBusType.messageTitle.text',
            message: 'Delete Bus Type?',
          });
          confirmationMessageText = t({
            id: 'deleteProjectBusTypeModal.messageContent.text',
            message: 'Are you sure you want to delete this bus type?',
          });
          break;
        case ProjectItemType.FOLDER:
          confirmationTitleText = t({
            id: 'deleteFolderModal.messageTitle.text',
            message: 'Delete Folder?',
          });
          confirmationMessageText = t({
            id: 'deleteFolderModal.messageContent.text',
            message: 'Are you sure you want to delete this folder?',
          });
          break;
        case ProjectItemType.PROJECTFILE:
        default:
          confirmationTitleText = t({
            id: 'deleteFileModal.messageTitle.text',
            message: 'Delete File?',
          });
          confirmationMessageText = t({
            id: 'deleteFileModal.messageContent.text',
            message: 'Are you sure you want to delete this file?',
          });
          break;
      }

      triggerModal(
        <DeleteConfirmationModal
          onDelete={onDelete}
          message={confirmationMessageText}
        />,
        confirmationTitleText,
      );
    },
    [type, triggerModal, selectedItems, showCompletion, deleteElement],
  );

  const renameElement = React.useCallback(
    (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e?.stopPropagation();

      if (!selectedItems || selectedItems.length !== 1) return;
      const {
        uuid: itemId,
        projectUuid: projectId,
        name,
        type,
      } = selectedItems[0];

      let oldName = name;
      let extension = '';
      if (type === ProjectItemType.PROJECTFILE) {
        [oldName, extension] = splitFullName(name);
      }

      const onRename = async (newName: string) => {
        switch (type) {
          case ProjectItemType.BUS_TYPE:
            renameBusType(projectId, itemId, newName);
            closeModal();
            break;
          case ProjectItemType.PROJECTFILE:
            await updateFile(projectId, itemId, {
              name: path ? `${path}/${newName}` : newName,
            });
            closeModal();
            break;
          case ProjectItemType.MODEL:
            await updateModelSummary(itemId, newName);
            closeModal();
            break;
          case ProjectItemType.SUBMODEL:
            const submodel = submodels[projectId].find(
              (s: SubmodelInfoLiteUI) => s.id === itemId,
            );
            if (!submodel) return;

            if (submodel.editId) {
              await updateSubmodelName({
                projectId,
                submodelId: itemId,
                newName,
                editId: submodel.editId,
              });
            }
            closeModal();
            break;
        }
      };

      triggerModal(
        <RenameModal
          initialValue={oldName}
          onRename={(v) => onRename(`${v}${extension && `.${extension}`}`)}
          values={sameTypeItems(name, type, projectItems)}
          comparer={(cellName) => cellName}
        />,
        'Rename',
      );
    },
    [
      selectedItems,
      triggerModal,
      projectItems,
      renameBusType,
      closeModal,
      updateFile,
      path,
      updateModelSummary,
      submodels,
      updateSubmodelName,
    ],
  );

  const duplicateElement = React.useCallback(
    (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e?.stopPropagation();

      if (!selectedItems || selectedItems.length !== 1) return;
      const {
        uuid: itemId,
        projectUuid: projectId,
        name,
        type,
      } = selectedItems[0];

      let sourceItemName = name;
      let extension = '';
      if (type === ProjectItemType.PROJECTFILE) {
        [sourceItemName, extension] = splitFullName(name);
      }

      const defaultDuplicateNameStarter = t({
        id: 'duplicateProjectItemModal.defaultDuplicateItemName.format',
        message: '{originalFileName} copy',
        values: {
          originalFileName: sourceItemName,
        },
      });
      const defaultDuplicateName = getNextValidIdentifier(
        defaultDuplicateNameStarter,
        sameTypeItems(name, type, projectItems),
      );

      const onDuplicate = async (
        newName: string,
        destinationProjectUuid: string,
      ) => {
        switch (type) {
          case ProjectItemType.MODEL:
            await duplicateModel(itemId, newName, destinationProjectUuid);
            closeModal();
            break;
          case ProjectItemType.SUBMODEL:
            await duplicateSubmodel({
              projectUuid: projectId,
              submodelUuid: itemId,
              submodelDuplicateRequest: {
                name: newName,
                destination_project_uuid: destinationProjectUuid,
              },
            });
            closeModal();
            break;
          case ProjectItemType.PROJECTFILE:
            await duplicateFile(
              projectId,
              itemId,
              destinationProjectUuid,
              newName,
            );
            closeModal();
            break;
        }
      };

      triggerModal(
        <DuplicateModal
          itemType={type}
          initialDestinationProjectUuid={projectId}
          defaultDuplicateName={defaultDuplicateName}
          extension={extension}
          onDuplicate={(name, project) =>
            onDuplicate(`${name}${extension && `.${extension}`}`, project)
          }
        />,
        'Duplicate Item',
      );
    },
    [
      triggerModal,
      closeModal,
      duplicateModel,
      duplicateSubmodel,
      duplicateFile,
      selectedItems,
      projectItems,
    ],
  );

  const downloadDataFile = React.useCallback(
    async (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e?.stopPropagation();

      selectedItems?.forEach(
        ({ uuid: itemId, projectUuid: projectId, name }) => {
          downloadFile(name, {
            fileUuid: itemId,
            projectUuid: projectId,
          });
        },
      );
    },
    [selectedItems, downloadFile],
  );

  return {
    deleteElement: deleteSelectedElements,
    renameElement,
    duplicateElement,
    downloadDataFile,
    type,
  };
}
