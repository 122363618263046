import styled from '@emotion/styled/macro';
import React from 'react';
import Input from 'ui/common/Input/Input';
import { ValidationRule } from 'ui/common/Input/inputValidation';

const MenuItemInputWrapper = styled.section`
  display: flex;
  padding: ${({ theme: { spacing } }) => `${spacing.small} ${spacing.normal}`};
`;

const Text = styled.span`
  width: 50%;
  line-height: ${(props) => props.theme.typography.font.title.lineHeight};
`;

const StyledInput = styled(Input)<{ hasText: boolean }>`
  width: ${({ hasText }) => (hasText ? '50%' : '100%')};
`;

interface Props {
  onSubmitValue: (v: string) => void;
  text?: string;
  hasBorder?: boolean;
  pattern?: string;
  value?: string | number;
  validationRules?: ValidationRule[];
}

const MenuItemInput: React.FC<Props> = ({
  text,
  onSubmitValue,
  hasBorder = true,
  value,
  pattern,
  validationRules,
}) => (
  <MenuItemInputWrapper>
    {text ? <Text>{text}</Text> : null}
    <StyledInput
      value={value}
      pattern={pattern}
      hasText={!!text}
      hasBorder={hasBorder}
      onSubmitValue={onSubmitValue}
      validationRules={validationRules}
    />
  </MenuItemInputWrapper>
);

export default MenuItemInput;
