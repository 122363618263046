import { t } from '@lingui/macro';
import React from 'react';
import Button from 'ui/common/Button/Button';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import Input from 'ui/common/Input/Input';
import { isDuplicatedRuleSet } from 'ui/common/Input/inputValidation';
import Label from 'ui/common/Label';
import {
  ActionButtonContainer,
  ModalInputGroup,
  SmallFormContainer,
} from 'ui/common/Modal/Modal';
import { useModal } from 'ui/common/Modal/useModal';

interface Props {
  onRename: (value: string) => void;
  initialValue: string;
  values?: any[];
  comparer?: (item: any) => string;
}

const RenameModal: React.FC<Props> = ({
  initialValue,
  onRename,
  values,
  comparer,
}) => {
  const { closeModal } = useModal();

  const [newValue, setValue] = React.useState(initialValue);
  const [isValid, setIsValid] = React.useState(false);

  return (
    <SmallFormContainer
      onSubmit={(e) => {
        e?.preventDefault();
        onRename(newValue);
      }}>
      <ModalInputGroup>
        <Label testId="duplicate-name-label">
          {t({
            id: 'addModelModal.nameInput.label',
            message: 'New name',
          })}
        </Label>
        <Input
          value={newValue}
          hasBorder
          autoFocus
          onChangeText={(v, isValid) => {
            setValue(v);
            setIsValid(isValid);
          }}
          validationRules={
            values && comparer && isDuplicatedRuleSet(values, comparer)
          }
        />
      </ModalInputGroup>
      <ActionButtonContainer>
        {/* Cancel button */}
        <Button
          type="button"
          onClick={closeModal}
          variant={ButtonVariants.LargeSecondary}
          testId="cancel-rename-button">
          {t({
            id: 'renameModal.cancelButton.label',
            message: 'Cancel',
          })}
        </Button>

        {/* Rename button */}
        <Button
          type="submit"
          variant={ButtonVariants.LargePrimary}
          disabled={!isValid}
          testId="rename-button">
          {t({
            id: 'renameModal.renameButton.label',
            message: 'Rename',
          })}
        </Button>
      </ActionButtonContainer>
    </SmallFormContainer>
  );
};

export default RenameModal;
