import type { Coordinate } from 'app/common_types/Coordinate';

export const convertZoomedScreenToWorldCoordinates = (
  camera: Coordinate,
  coord: Coordinate,
): Coordinate => {
  const x = -camera.x + coord.x;
  const y = -camera.y + coord.y;

  return { x, y };
};
