import { MINIMUM_ZOOM } from 'app/slices/cameraSlice';

export const NAME_FONTSIZE = 12;
export const NAME_SCALEDOWN_THRESHOLD = 0.7;
export const NAME_SCALEUP_THRESHOLD = 1.75;
export const MIN_NAME_SIZE = 9;

export function getFontSize(zoom: number, baseSize = NAME_FONTSIZE): number {
  const scaledDownFontSize = Math.max(
    NAME_FONTSIZE * (zoom / NAME_SCALEDOWN_THRESHOLD),
    MIN_NAME_SIZE,
  );
  const fontSize =
    zoom < NAME_SCALEDOWN_THRESHOLD
      ? scaledDownFontSize
      : zoom > NAME_SCALEUP_THRESHOLD
      ? NAME_FONTSIZE * (zoom / NAME_SCALEUP_THRESHOLD)
      : NAME_FONTSIZE;

  return fontSize;
}

export function getFontOpacity(zoom: number): number {
  const rawNameOpacity =
    (zoom - MINIMUM_ZOOM * 2) / (NAME_SCALEDOWN_THRESHOLD - MINIMUM_ZOOM * 2);
  const nameOpacity = Math.max(0, Math.min(1, rawNameOpacity));

  return nameOpacity;
}
