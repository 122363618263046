import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
  getModelData,
  modelVersionsActions,
} from 'app/slices/modelVersionsSlice';
import { simulationSignalsActions } from 'app/slices/simulationSignalsSlice';
import React from 'react';
import { Spinner } from 'ui/common/Spinner';
import { SimulationDataModelSimulationSignalList } from 'ui/dataExplorer/simulationDataSidebar/SimulationDataModelSimulationSignalList';
import { SingleLineSpinnerWrapper } from 'ui/objectBrowser/sections/ReferenceSubmodelTreeContent';
import { SimulationSignalsTreeData } from 'util/simulationSignalsTree';

interface Props {
  modelId: string;
  modelVersionId: string;
  simulationId: string;
  canEditProject: boolean;
}

export const SimulationDataModelSimulationContent: React.FC<Props> = ({
  modelId,
  modelVersionId,
  simulationId,
  canEditProject,
}) => {
  const dispatch = useAppDispatch();

  const signalsTreeData: SimulationSignalsTreeData = useAppSelector(
    (state) =>
      state.simulationSignals.simulationIdToSignalsTreeData[simulationId],
  );

  const modelIdToVersionIdToModelData = useAppSelector(
    (state) => state.modelVersions.modelIdToVersionIdToModelData,
  );

  React.useEffect(() => {
    if (!signalsTreeData) {
      dispatch(
        simulationSignalsActions.requestSimulationSignals({
          modelId,
          simulationId,
        }),
      );

      dispatch(
        modelVersionsActions.requestModelVersion({
          modelId,
          versionId: modelVersionId,
          kind: 'Model',
        }),
      );
    }
  }, [dispatch, modelId, modelVersionId, simulationId, signalsTreeData]);

  const modelData = getModelData(
    modelId,
    modelVersionId,
    modelIdToVersionIdToModelData,
  );

  if (!signalsTreeData || !modelData) {
    return (
      <SingleLineSpinnerWrapper>
        <Spinner />
      </SingleLineSpinnerWrapper>
    );
  }

  // TODO show error message on failure to load signals.
  if (signalsTreeData.errorMessage || !signalsTreeData.rootSignalsList) {
    return null;
  }

  return (
    <SimulationDataModelSimulationSignalList
      modelId={modelId}
      modelVersionId={modelVersionId}
      simulationId={simulationId}
      canEditProject={canEditProject}
      signalsList={signalsTreeData.rootSignalsList}
    />
  );
};
