/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const AcausalElectricalBattery: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6 12.5C5.72386 12.5 5.5 12.7239 5.5 13V19C5.5 19.2761 5.72386 19.5 6 19.5H8C8.27614 19.5 8.5 19.2761 8.5 19V13C8.5 12.7239 8.27614 12.5 8 12.5H6Z"
      fill={props.fill}
    />
    <path
      d="M9.5 13C9.5 12.7239 9.72386 12.5 10 12.5H12C12.2761 12.5 12.5 12.7239 12.5 13V19C12.5 19.2761 12.2761 19.5 12 19.5H10C9.72386 19.5 9.5 19.2761 9.5 19V13Z"
      fill={props.fill}
    />
    <path
      d="M14 12.5C13.7239 12.5 13.5 12.7239 13.5 13V19C13.5 19.2761 13.7239 19.5 14 19.5H16C16.2761 19.5 16.5 19.2761 16.5 19V13C16.5 12.7239 16.2761 12.5 16 12.5H14Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M27 21V19H28.5C29.0523 19 29.5 18.5523 29.5 18V14C29.5 13.4477 29.0523 13 28.5 13H27V11C27 10.4477 26.5523 10 26 10H4C3.44772 10 3 10.4477 3 11V21C3 21.5523 3.44772 22 4 22H26C26.5523 22 27 21.5523 27 21ZM4.5 11.5V20.5H25.5V11.5H4.5ZM28 17.5H27V14.5H28V17.5Z"
      fill={props.fill}
    />
  </svg>
);

AcausalElectricalBattery.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default AcausalElectricalBattery;
