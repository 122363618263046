export const hexToRgb = (hex: string): [number, number, number] | undefined => {
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    let s = hex.substring(1).split('');
    if (s.length === 3) {
      s = [s[0], s[0], s[1], s[1], s[2], s[2]];
    }
    const c = parseInt(`0x${s.join('')}`);
    return [(c >> 16) & 255, (c >> 8) & 255, c & 255];
  }
};

export const hexToRgbWithDefault = (
  hex: string,
  defaultRgb: [number, number, number] = [0, 0, 0],
): [number, number, number] => hexToRgb(hex) || defaultRgb;
