type SortForward = 1;
type SortReverse = -1;
type SortOrder = SortForward | SortReverse;

export const getStringSorter =
  (property: string, sortOrder: SortOrder = 1) =>
  (a: any, b: any) => {
    if (!a[property]) return sortOrder * -1;
    if (!b[property]) return sortOrder;

    const result =
      a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
    return result * sortOrder;
  };
