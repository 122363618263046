import { useTheme } from '@emotion/react';
import {
  SimulationSummaryUI,
  getSimulationNameAsFormattedCreationDate,
} from 'app/apiTransformers/convertGetSimulationReadAll';
import React from 'react';
import { Simulation } from 'ui/common/Icons/Small';
import {
  TreeArrowCollapsed,
  TreeArrowExpanded,
} from 'ui/common/Icons/Standard';
import { SimulationDataModelSimulationContent } from 'ui/dataExplorer/simulationDataSidebar/SimulationDataModelSimulationContent';
import {
  ItemSection,
  ModelBlockItemText,
  ModelTreeIcon,
} from 'ui/objectBrowser/ModelTreeParts';

interface Props {
  modelId: string;
  simulation: SimulationSummaryUI;
  simulationIndex: number;
  canEditProject: boolean;
}

export const SimulationDataModelSimulationItem: React.FC<Props> = ({
  modelId,
  simulation,
  simulationIndex,
  canEditProject,
}) => {
  const theme = useTheme();

  const [isExpanded, setIsExpanded] = React.useState(false);

  const toggleExpandedState = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    event.preventDefault();
    event.stopPropagation();

    setIsExpanded(!isExpanded);
  };

  return (
    <>
      <ItemSection
        data-test-id={`data-explorer-model-simulation-tree-model-item-${simulationIndex}`}
        selected={false}
        nestedLayer={1}>
        <ModelTreeIcon onClick={toggleExpandedState}>
          {isExpanded ? <TreeArrowExpanded /> : <TreeArrowCollapsed />}
        </ModelTreeIcon>

        <ModelTreeIcon>
          <Simulation fill={theme.colors.text.secondary} />
        </ModelTreeIcon>
        <div
          data-test-id={`data-explorer-model-simulation-tree-model-item-text-${simulationIndex}`}>
          <ModelBlockItemText>
            {getSimulationNameAsFormattedCreationDate(simulation)}
          </ModelBlockItemText>
        </div>
      </ItemSection>

      {isExpanded && (
        <SimulationDataModelSimulationContent
          modelId={modelId}
          modelVersionId={simulation.modelVersionId}
          simulationId={simulation.id}
          canEditProject={canEditProject}
        />
      )}
    </>
  );
};
