/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  className?: string;
}

const Randomnormal: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.5 4C16.5 3.72386 16.2761 3.5 16 3.5C15.7239 3.5 15.5 3.72386 15.5 4V5.1C15.5 5.37614 15.7239 5.6 16 5.6C16.2761 5.6 16.5 5.37614 16.5 5.1V4Z"
      fill={props.fill}
    />
    <path
      d="M16.5 8.4C16.5 8.12386 16.2761 7.9 16 7.9C15.7239 7.9 15.5 8.12386 15.5 8.4V10.6C15.5 10.8761 15.7239 11.1 16 11.1C16.2761 11.1 16.5 10.8761 16.5 10.6V8.4Z"
      fill={props.fill}
    />
    <path
      d="M16.5 13.9C16.5 13.6239 16.2761 13.4 16 13.4C15.7239 13.4 15.5 13.6239 15.5 13.9V16.1C15.5 16.3761 15.7239 16.6 16 16.6C16.2761 16.6 16.5 16.3761 16.5 16.1V13.9Z"
      fill={props.fill}
    />
    <path
      d="M16.5 19.4C16.5 19.1239 16.2761 18.9 16 18.9C15.7239 18.9 15.5 19.1239 15.5 19.4V21.6C15.5 21.8761 15.7239 22.1 16 22.1C16.2761 22.1 16.5 21.8761 16.5 21.6V19.4Z"
      fill={props.fill}
    />
    <path
      d="M16.5 24.9C16.5 24.6239 16.2761 24.4 16 24.4C15.7239 24.4 15.5 24.6239 15.5 24.9V25.5H2C1.72386 25.5 1.5 25.7239 1.5 26C1.5 26.2761 1.72386 26.5 2 26.5H15.9986C15.9991 26.5 15.9995 26.5 16 26.5C16.0005 26.5 16.0009 26.5 16.0014 26.5H30C30.2761 26.5 30.5 26.2761 30.5 26C30.5 25.7239 30.2761 25.5 30 25.5H16.5V24.9Z"
      fill={props.fill}
    />
    <path
      d="M3.5 24.75C5.7603 24.75 7.34478 23.4781 8.50758 21.7649C9.65427 20.0755 10.4633 17.8569 11.1814 15.741C11.2871 15.4294 11.3908 15.1204 11.4933 14.8149C12.1027 12.9988 12.6685 11.3125 13.358 10.0064C14.1729 8.46258 15.0033 7.75 16 7.75C16.9967 7.75 17.8271 8.46258 18.642 10.0064C19.3315 11.3125 19.8973 12.9986 20.5066 14.8147C20.6091 15.1201 20.7129 15.4295 20.8186 15.741C21.5367 17.8569 22.3457 20.0755 23.4924 21.7649C24.6552 23.4781 26.2397 24.75 28.5 24.75C28.9142 24.75 29.25 24.4142 29.25 24C29.25 23.5858 28.9142 23.25 28.5 23.25C26.9141 23.25 25.7342 22.3969 24.7335 20.9226C23.7168 19.4245 22.9633 17.3931 22.2391 15.259C22.1381 14.9616 22.0377 14.6618 21.9371 14.3615L21.9359 14.358C21.3311 12.5532 20.7202 10.73 19.9685 9.30612C19.1008 7.66242 17.8879 6.25 16 6.25C14.1121 6.25 12.8992 7.66242 12.0315 9.30612C11.2798 10.73 10.6689 12.5532 10.0641 14.358C9.96318 14.6592 9.86216 14.9607 9.76094 15.259C9.03668 17.3931 8.28323 19.4245 7.26646 20.9226C6.2658 22.3969 5.08585 23.25 3.5 23.25C3.08579 23.25 2.75 23.5858 2.75 24C2.75 24.4142 3.08579 24.75 3.5 24.75Z"
      fill={props.fill}
    />
  </svg>
);

Randomnormal.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  className: '',
};

export default Randomnormal;
