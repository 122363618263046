import styled from '@emotion/styled/macro';
import { t } from '@lingui/macro';
import { useProjectActions } from 'app/api/useProjectActions';
import { FileProcessorType } from 'app/apiGenerated/generatedApiTypes';
import { Project } from 'app/apiTransformers/convertAPIProjectToProject';
import { useAppSelector } from 'app/hooks';
import React from 'react';
import TooltipButton from 'ui/common/Button/TooltipButton';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import { ChevronDown, Upload } from 'ui/common/Icons/Standard';
import Menu from 'ui/common/Menu/Menu';
import { ItemType, MenuItemConfig } from 'ui/common/Menu/menuItemTypes';
import { useModal } from 'ui/common/Modal/useModal';
import ImportFromUrlModal from '../ImportFromUrlModal';
import ManageIntegrations from '../ManageIntegrationsModal';

export const UploadFileInput = styled.input`
  visibility: hidden;
  position: absolute;
  z-index: -100;
  width: 10px;
`;

interface Props {
  project: Project;
}

export const ProjectDetailImportButton: React.FC<Props> = ({ project }) => {
  const {
    developerModeEnabled,
    dataIntegrationsEnabled,
    fmuBlocksEnabled,
    uploadMlModelsEnabled,
    twinBlocksEnabled,
  } = useAppSelector((state) => state.userOptions.options);

  const { triggerModal } = useModal();

  const uploadDataFileRef = React.useRef<HTMLInputElement>(null);
  const importProjectRef = React.useRef<HTMLInputElement>(null);
  const uploadZipFmuFileRef = React.useRef<HTMLInputElement>(null);
  const uploadMlModelFileRef = React.useRef<HTMLInputElement>(null);
  const uploadTwinFileRef = React.useRef<HTMLInputElement>(null);

  const { createFile, importIntoProject } = useProjectActions();

  const uploadDataFile = (
    e: React.ChangeEvent<HTMLInputElement>,
    processor?: FileProcessorType,
  ) => {
    const projectId = project?.uuid;
    const file = e.target?.files?.[0];
    if (!projectId || !file) {
      return null;
    }
    createFile(
      {
        projectUuid: projectId,
        fileCreateRequest: {
          name: file.name,
          content_type: file.type,
          size: file.size,
          processor,
        },
      },
      file,
    );
  };

  const importProjectFiles = (e: React.ChangeEvent<HTMLInputElement>) => {
    const projectId = project?.uuid;
    const file = e.target?.files?.[0];
    if (!projectId || !file) {
      return null;
    }

    importIntoProject(
      {
        projectUuid: projectId,
      },
      file,
    );
  };

  const uploadItems: MenuItemConfig[] = [
    {
      type: ItemType.Button,
      content: t({ id: 'uploadMenu.uploadFile', message: 'Upload file...' }),
      onClick: () => uploadDataFileRef?.current?.click?.(),
    },
  ];

  if (fmuBlocksEnabled) {
    uploadItems.push({
      type: ItemType.Button,
      content: t({
        id: 'uploadMenu.ZipFmu',
        message: 'Upload FMU ZIP file...',
      }),
      onClick: () => uploadZipFmuFileRef?.current?.click?.(),
    });
  }

  if (twinBlocksEnabled) {
    uploadItems.push({
      type: ItemType.Button,
      content: t({
        id: 'uploadMenu.Twin',
        message: 'Upload TWIN file...',
      }),
      onClick: () => uploadTwinFileRef?.current?.click?.(),
    });
  }

  if (uploadMlModelsEnabled) {
    uploadItems.push({
      type: ItemType.Button,
      content: t({
        id: 'uploadMenu.ZipMlModel',
        message: 'Upload ML model...',
      }),
      onClick: () => uploadMlModelFileRef?.current?.click?.(),
    });
  }

  if (developerModeEnabled) {
    uploadItems.push({
      type: ItemType.Button,
      content: t({
        id: 'uploadMenu.import',
        message: 'Import external project... (dev only)',
      }),
      onClick: () => importProjectRef?.current?.click?.(),
    });
  }

  uploadItems.push({
    type: ItemType.Separator,
  });
  uploadItems.push({
    type: ItemType.Button,
    content: t({ id: 'uploadMenu.url', message: 'Import from URL...' }),
    onClick: () =>
      triggerModal(
        <ImportFromUrlModal project={project} />,
        t({ id: 'downloadFromUrlModal.title', message: 'Import from URL' }),
      ),
  });
  if (dataIntegrationsEnabled) {
    uploadItems.push({
      type: ItemType.Button,
      content: t({
        id: 'uploadMenu.manageIntegrations',
        message: 'Manage Integrations',
      }),
      onClick: () =>
        triggerModal(
          <ManageIntegrations projectId={project.uuid} />,
          t({ id: 'manageIntegrations.title', message: 'Manage Integrations' }),
        ),
    });
  }

  const tooltip = t({
    id: 'dashboard.projectsDetailActionButtons.ImportButton.Label',
    message: 'Import',
  });

  return (
    <>
      <Menu items={uploadItems}>
        <TooltipButton
          tooltip={tooltip}
          variant={ButtonVariants.SmallSecondary}
          Icon={Upload}
          largeIcon
          testId="project-detail-upload-button">
          <ChevronDown />
        </TooltipButton>
      </Menu>
      <UploadFileInput
        type="file"
        ref={uploadDataFileRef}
        onChange={(e) => {
          uploadDataFile(e);
          e.target.value = '';
        }}
      />
      <UploadFileInput
        type="file"
        ref={uploadZipFmuFileRef}
        onChange={(e) => {
          uploadDataFile(e, 'zip_fmu');
          e.target.value = '';
        }}
      />
      <UploadFileInput
        type="file"
        ref={uploadMlModelFileRef}
        accept=".*"
        onChange={(e) => {
          uploadDataFile(e, 'ml_model');
          e.target.value = '';
        }}
      />
      <UploadFileInput
        type="file"
        ref={uploadTwinFileRef}
        accept=".twin"
        onChange={(e) => {
          uploadDataFile(e, 'twin');
          e.target.value = '';
        }}
      />
      {developerModeEnabled && (
        <UploadFileInput
          type="file"
          ref={importProjectRef}
          onChange={(e) => {
            importProjectFiles(e);
            e.target.value = '';
          }}
        />
      )}
    </>
  );
};
