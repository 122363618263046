import { t } from '@lingui/macro';
import { blockClassLookup } from 'app/generated_blocks';
import { BlockInstance } from 'app/generated_types/SimulationModel';
import { useAppDispatch } from 'app/hooks';
import { modelActions } from 'app/slices/modelSlice';
import React from 'react';
import Button from 'ui/common/Button/Button';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import { Remove } from 'ui/common/Icons/Standard';
import { requiredRules } from 'ui/common/Input/inputValidation';
import { isValidBlockParameterNameRuleSet } from 'ui/common/Input/inputValidationForModels';
import SectionHeading from 'ui/common/Inputs/SectionHeading';
import {
  DetailInputRowsSection,
  DetailsInput,
  DetailsRow,
  DetailsSection,
} from 'ui/modelEditor/DetailsComponents';
import {
  orderedExtraParameters,
  updateExtraParameter,
} from 'util/dynamicBlockUtils';
import { CommonBlockParametersDetails } from '../CommonBlockParameterDetails';

interface Props {
  parentPath: string[];
  node: BlockInstance;
  disabled: boolean;
}

const CodeBlockParameterDetails: React.FC<Props> = ({
  parentPath,
  node,
  disabled,
}: Props) => {
  const dispatch = useAppDispatch();

  const blockClass = blockClassLookup(node.type);

  const addExtraParameter = () => {
    dispatch(
      modelActions.addNodeExtraParameter({
        parentPath,
        nodeUuid: node.uuid,
      }),
    );
  };
  const renameExtraParameter = (oldName: string, newName: string) => {
    dispatch(
      modelActions.renameNodeExtraParameter({
        parentPath,
        nodeUuid: node.uuid,
        oldName,
        newName,
      }),
    );
  };

  const removeExtraParameter = (paramName: string) => {
    dispatch(
      modelActions.removeNodeExtraParameter({
        parentPath,
        nodeUuid: node.uuid,
        paramName,
      }),
    );
  };

  // Class-level parameters aren't allowed names
  // These are hidden from the user for the python script block.
  // FIXME: if we separate class and extra params, this may not be necessary
  const classParamNames =
    blockClass.parameter_definitions?.map((def) => def.name) || [];

  // Extra parameters
  const extraNames = orderedExtraParameters(node);

  // The ordering matters here because the validation rule assumes the list of params is exactly what is displayed.
  // The python block is a special case due to hidden user-params in `parameter_definitions`.
  const allParamNames = extraNames.concat(classParamNames);

  return (
    <>
      <SectionHeading
        testId="python-block-parameters"
        onButtonClick={
          !disabled && blockClass.base.extra_parameters
            ? addExtraParameter
            : undefined
        }
        buttonTooltip={t({
          id: 'blockDetails.addPythonBlockParametersButtonTooltip',
          message: 'Add parameter',
        })}>
        {t({
          id: 'blockDetails.PythonBlockParameterDefinitionsTitle',
          message: 'Parameters',
        })}
      </SectionHeading>
      <CommonBlockParametersDetails
        parentPath={parentPath}
        selectedNode={node}
        canEdit={!disabled}
      />
      <DetailInputRowsSection>
        {extraNames.map((paramName, index) => {
          const param = node.parameters[paramName];
          const nameKey = `extra-param-name-${paramName}`;
          const valueKey = `extra-param-value-${paramName}`;
          const paramValue = param?.value || '';
          return (
            <DetailsSection key={paramName} vertical>
              <DetailsRow>
                <DetailsInput
                  grow
                  testId={nameKey}
                  value={paramName}
                  onSubmitValue={(newName) =>
                    renameExtraParameter(paramName, newName)
                  }
                  disabled={disabled}
                  validationRules={isValidBlockParameterNameRuleSet(
                    allParamNames,
                    index,
                    node,
                  )}
                />
                {!disabled && (
                  <Button
                    variant={ButtonVariants.LargeTertiary}
                    Icon={Remove}
                    onClick={() => removeExtraParameter(paramName)}
                    disabled={disabled}
                  />
                )}
              </DetailsRow>
              <DetailsRow>
                <DetailsInput
                  grow
                  testId={valueKey}
                  value={paramValue}
                  onSubmitValue={updateExtraParameter(
                    dispatch,
                    parentPath,
                    node,
                    paramName,
                  )}
                  disabled={disabled}
                  hasBorder
                  allowMultiline
                  validationRules={requiredRules}
                />
              </DetailsRow>
            </DetailsSection>
          );
        })}
      </DetailInputRowsSection>
    </>
  );
};

export default CodeBlockParameterDetails;
