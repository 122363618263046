/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  className?: string;
}

const StripeLogo: React.FC<Props> = (props) => (
  <svg viewBox="0 0 24 24" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.3874 7.29231C11.3858 7.29231 10.7811 7.58901 10.7811 8.36044C10.7811 10.5758 17.9811 9.52747 18 15.422C18 18.9626 15.2976 21 11.3669 21C9.74173 21 7.96535 20.6637 6.20787 19.8725V15.1648C7.79528 16.0747 9.79843 16.7473 11.3669 16.7473C12.4252 16.7473 13.1811 16.4505 13.1811 15.5407C13.1811 13.1868 6 14.0571 6 8.5978C6 5.0967 8.55118 3 12.3874 3C13.9559 3 15.5055 3.25714 17.074 3.90989V8.55824C15.6378 7.74725 13.8236 7.29231 12.3874 7.29231Z"
      fill={props.fill}
    />
  </svg>
);

StripeLogo.defaultProps = {
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  className: '',
};

export default StripeLogo;
