import { SimulationSignalInfo } from 'app/apiGenerated/generatedApiTypes';
import { getVectorIndex } from 'util/modelVersionSignal';

const TIME_TRACE_NAME = 'time';

export interface SignalData {
  signalPath: string;
  vectorIndices?: number[];
  displayName: string;
  hasCsvSupport: boolean;
}

export interface SignalsList {
  parentCount: number;
  parentNamePath: string;
  displayName: string;
  signals: SignalData[];
  submodelNameToSignalsList: Record<string, SignalsList>;
}

export interface SimulationSignalsTreeData {
  // If the data loaded and was parsed success fully, it will be set here.
  // If there was an error, this will be undefined.
  rootSignalsList?: SignalsList;

  // If there was an error loading the simulation signals,
  // it will be set here.  Otherwise this is undefined.
  errorMessage?: string;
}

export function buildSignalsTree(signals: SimulationSignalInfo[]): SignalsList {
  const rootSignalsList: SignalsList = {
    parentCount: 0,
    parentNamePath: '',
    displayName: '',
    signals: [],
    submodelNameToSignalsList: {},
  };

  signals.forEach((signal: SimulationSignalInfo) => {
    // The time signal need not be included in the tree.
    if (signal.name === TIME_TRACE_NAME) {
      return;
    }

    // All non-time signals should have at least 2 sections:
    // block name and port name, plus any parent path names
    const nameSections: string[] = signal.name.split('.');
    if (nameSections.length < 2) {
      console.error('Found unexpected signal path format: ', signal.name);
      return;
    }

    const portNameWithVectorIndex: string = nameSections.pop() as string;
    const blockName: string = nameSections.pop() as string;
    const parentNames: string[] = nameSections;

    let currentSignalsList: SignalsList = rootSignalsList;
    let currentParentNamePath = '';
    parentNames.forEach((parentName: string, i: number) => {
      if (!currentParentNamePath) {
        currentParentNamePath = parentName;
      } else {
        currentParentNamePath = `${currentParentNamePath}.${parentName}`;
      }

      if (!currentSignalsList.submodelNameToSignalsList[parentName]) {
        currentSignalsList.submodelNameToSignalsList[parentName] = {
          parentCount: i + 1,
          parentNamePath: currentParentNamePath,
          displayName: parentName,
          signals: [],
          submodelNameToSignalsList: {},
        };
      }
      currentSignalsList =
        currentSignalsList.submodelNameToSignalsList[parentName];
    });

    const { portName, vectorIndex } = getVectorIndex(portNameWithVectorIndex);
    const signalPath = signal.name.split('[')[0];
    if (vectorIndex === undefined) {
      // The signal is not a vector, so the there is only one trace associated with the signal.
      currentSignalsList.signals.push({
        signalPath,
        displayName: `${blockName}.${portName}`,
        hasCsvSupport: signal.csv_support,
      });
    } else {
      // The signal is a vector, so create a new entry for it or append to a previous entry
      // and just add the new vector index.
      const existingSignalData: SignalData | undefined =
        currentSignalsList.signals.find(
          (signalList) => signalList.signalPath === signalPath,
        );
      if (!existingSignalData || !existingSignalData.vectorIndices) {
        currentSignalsList.signals.push({
          signalPath,
          vectorIndices: [vectorIndex],
          displayName: `${blockName}.${portName}`,
          hasCsvSupport: signal.csv_support,
        });
      } else {
        existingSignalData.vectorIndices.push(vectorIndex);
      }
    }
  });

  return rootSignalsList;
}
