/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Generic: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M1.5 1.5V5.5H5.5V1.5H1.5ZM2.5 27.5V29.5H4.5V27.5H2.5ZM1.5 26.5V30.5H5.5V26.5H1.5ZM8.3 3.3H8.7V3.7H8.3V3.3ZM6.5 1.5H10.5V5.5H6.5V1.5ZM3.3 8.3V8.7H3.7V8.3H3.3ZM1.5 6.5V10.5H5.5V6.5H1.5ZM7.5 22.5H9.5V24.5H7.5V22.5ZM6.5 21.5H10.5V25.5H6.5V21.5ZM9.25 27.75H7.75V29.25H9.25V27.75ZM6.75 26.75V30.25H10.25V26.75H6.75ZM12.75 22.75H14.25V24.25H12.75V22.75ZM11.75 21.75H15.25V25.25H11.75V21.75ZM19.25 17.75H17.75V19.25H19.25V17.75ZM16.75 16.75V20.25H20.25V16.75H16.75ZM22.75 12.75H24.25V14.25H22.75V12.75ZM21.75 11.75H25.25V15.25H21.75V11.75ZM29.25 7.75H27.75V9.25H29.25V7.75ZM26.75 6.75V10.25H30.25V6.75H26.75ZM13.1 3.1H13.9V3.9H13.1V3.1ZM11.5 1.5H15.5V5.5H11.5V1.5ZM8.9 8.1H8.1V8.9H8.9V8.1ZM6.5 6.5V10.5H10.5V6.5H6.5ZM3.1 13.9V13.1H3.9V13.9H3.1ZM1.5 11.5H5.5V15.5H1.5V11.5ZM14.5 17.5H12.5V19.5H14.5V17.5ZM11.5 16.5V20.5H15.5V16.5H11.5ZM13 28H14V29H13V28ZM12 27H15V30H12V27ZM18 23H19V24H18V23ZM17 22H20V25H17V22ZM24 18H23V19H24V18ZM22 17V20H25V17H22ZM28 13H29V14H28V13ZM27 12H30V15H27V12ZM19.1 2.9H17.9V4.1H19.1V2.9ZM16.5 1.5V5.5H20.5V1.5H16.5ZM12.9 7.9H14.1V9.1H12.9V7.9ZM11.5 6.5H15.5V10.5H11.5V6.5ZM9.1 12.9H7.9V14.1H9.1V12.9ZM6.5 11.5V15.5H10.5V11.5H6.5ZM2.9 19.1V17.9H4.1V19.1H2.9ZM1.5 16.5H5.5V20.5H1.5V16.5ZM19.5 12.5H17.5V14.5H19.5V12.5ZM16.5 11.5V15.5H20.5V11.5H16.5ZM18.25 28.25H18.75V28.75H18.25V28.25ZM17.25 27.25H19.75V29.75H17.25V27.25ZM23.75 23.25H23.25V23.75H23.75V23.25ZM22.25 22.25V24.75H24.75V22.25H22.25ZM28.25 18.25H28.75V18.75H28.25V18.25ZM27.25 17.25H29.75V19.75H27.25V17.25ZM24.3 2.7H22.7V4.3H24.3V2.7ZM21.5 1.5V5.5H25.5V1.5H21.5ZM17.7 7.7H19.3V9.3H17.7V7.7ZM16.5 6.5H20.5V10.5H16.5V6.5ZM14.3 12.7H12.7V14.3H14.3V12.7ZM11.5 11.5V15.5H15.5V11.5H11.5ZM7.7 17.7H9.3V19.3H7.7V17.7ZM6.5 16.5H10.5V20.5H6.5V16.5ZM2.7 22.7V24.3H4.3V22.7H2.7ZM1.5 21.5V25.5H5.5V21.5H1.5ZM22.5 7.5H24.5V9.5H22.5V7.5ZM21.5 6.5H25.5V10.5H21.5V6.5ZM29.5 2.5H27.5V4.5H29.5V2.5ZM26.5 1.5V5.5H30.5V1.5H26.5ZM27.75 27.75H29.25V29.25H27.75V27.75ZM27.5 22.5H29.5V24.5H27.5V22.5ZM22.5 27.5V29.5H24.5V27.5H22.5Z"
      fill={props.fill}
    />
  </svg>
);

Generic.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Generic;
