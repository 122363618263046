// !!!! -- DO NOT MANUALLY EDIT -- !!!!
// This file was generated by something in scripts/generateDefaultBlockData.js
// If you want to change the contents of this file, it should be automated
//
// (You can edit this file locally, but it may not be checked into the codebase -
// PRs with a manual edit of this file are forbidden from being merged.)

import { computationBlockDefaults } from 'app/generated_blocks/computationBlockDefaults';
import type { ComputationBlockClass } from 'app/generated_types/ComputationBlockClass';
import deepmerge from 'deepmerge';

export const BusSelector: ComputationBlockClass = deepmerge(
  computationBlockDefaults,
  {
    "base": {
      "name": "BusSelector",
      "namespace": "core",
      "description": "Extract the selected signals from the bus signal as outputs of this block.",
      "feature_level": "dev"
    },
    "modes": {
      "time": "agnostic"
    },
    "ports": {
      "inputs": {
        "static": [
          {
            "name": "in_0"
          }
        ]
      },
      "has_automatic_ports": true
    },
    "parameter_definitions": [
      {
        "name": "bus_type",
        "description": "The bus type to extract signals from.",
        "$comment": "Displays the name, but stores the project scope ID. Used in conjunction with `project_uuid` to uniquely identify the bus type.",
        "data_type": "string",
        "default_value": ""
      },
      {
        "name": "project_uuid",
        "description": "The UUID of the project that the bus type belongs to.",
        "$comment": "Implicitly set by `bus_type` selection.",
        "data_type": "string",
        "default_value": "",
        "hidden": true,
        "read_only": true
      }
    ]
  }
);
