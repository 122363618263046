/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Iterator: React.FC<Props> = (props) => (
  <svg viewBox="0 0 16 16" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.0202 7.68688C14.2155 7.49161 14.2155 7.17503 14.0202 6.97977C13.825 6.78451 13.5084 6.78451 13.3131 6.97977L12.5 7.79288V4.33332C12.5 3.68899 11.9777 3.16666 11.3333 3.16666H4.00001C3.35568 3.16666 2.83334 3.68899 2.83334 4.33332V11.6667C2.83334 12.311 3.35568 12.8333 4.00001 12.8333H6.16668C6.44282 12.8333 6.66668 12.6095 6.66668 12.3333C6.66668 12.0572 6.44282 11.8333 6.16668 11.8333H4.00001C3.90796 11.8333 3.83334 11.7587 3.83334 11.6667L3.83334 4.33332C3.83334 4.24128 3.90796 4.16666 4.00001 4.16666H11.3333C11.4254 4.16666 11.5 4.24128 11.5 4.33332L11.5 7.79289L10.6869 6.97977C10.4916 6.78451 10.175 6.78451 9.97978 6.97977C9.78451 7.17503 9.78451 7.49162 9.97978 7.68688L12 9.7071L14.0202 7.68688Z"
      fill={props.fill}
    />
    <path
      d="M8.83334 12.8333C9.10948 12.8333 9.33334 12.6095 9.33334 12.3333C9.33334 12.0572 9.10948 11.8333 8.83334 11.8333H7.83334C7.5572 11.8333 7.33334 12.0572 7.33334 12.3333C7.33334 12.6095 7.5572 12.8333 7.83334 12.8333H8.83334Z"
      fill={props.fill}
    />
    <path
      d="M11.5198 12.8333C11.7959 12.8333 12.0198 12.6095 12.0198 12.3333C12.0198 12.0572 11.7959 11.8333 11.5198 11.8333L10.4798 11.8333C10.2036 11.8333 9.97978 12.0572 9.97978 12.3333C9.97978 12.6095 10.2036 12.8333 10.4798 12.8333H11.5198Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M5.66668 5.33332C5.29849 5.33332 5.00001 5.6318 5.00001 5.99999V7.33332C5.00001 7.70151 5.29849 7.99999 5.66668 7.99999H6.29631V9.33275C6.29631 9.88503 6.74402 10.3327 7.29631 10.3327H7.8049C7.92008 10.5323 8.13572 10.6667 8.38273 10.6667H9.65685C10.025 10.6667 10.3235 10.3682 10.3235 10V9.64908C10.3235 9.28089 10.025 8.98241 9.65685 8.98241H8.38273C8.01454 8.98241 7.71606 9.28089 7.71606 9.64908V9.66608H7.29631C7.11221 9.66608 6.96297 9.51684 6.96297 9.33275V7.99999H7.66668C8.03487 7.99999 8.33334 7.70151 8.33334 7.33332V5.99999C8.33334 5.6318 8.03487 5.33332 7.66668 5.33332H5.66668ZM7.66668 5.99999H5.66668L5.66668 7.33332H7.66668V5.99999ZM8.38273 9.64908H9.65685L9.65685 10H8.38273V9.64908Z"
      fill={props.fill}
    />
  </svg>
);

Iterator.defaultProps = {
  width: '16',
  height: '16',
  viewBox: '0 0 16 16',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Iterator;
