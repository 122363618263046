/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Abs: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M27.2498 4C27.9175 4 28.2553 4.80387 27.7832 5.27606C25.2783 7.78139 19.6871 13.3736 16.5303 16.5303C16.2374 16.8232 15.7626 16.8232 15.4697 16.5303C12.313 13.3736 6.72167 7.78139 4.21678 5.27606C3.74468 4.80387 4.08249 4 4.7502 4C4.94898 4 5.13963 4.07896 5.28019 4.21953L16 14.9393L26.7198 4.21953C26.8604 4.07896 27.051 4 27.2498 4Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M15.7356 27.9999L15.75 3.99988L16.25 4.00018L16.2356 28.0002L15.7356 27.9999Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M32 16.25L0 16.25L4.37112e08 15.75L32 15.75V16.25Z"
      fill={props.fill}
    />
  </svg>
);

Abs.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Abs;
