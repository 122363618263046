import { useTheme } from '@emotion/react';
import styled from '@emotion/styled/macro';
import React from 'react';
import {
  ExtraSmallCaps,
  ExtraSmallCapsHighlight,
} from 'ui/common/typography/Typography';

const TabButtonWrapper = styled.div<{
  isTabSelected: boolean;
  selectedIsHighlighted: boolean;
}>`
  display: flex;
  flex-direction: row;

  background: ${({ theme, isTabSelected, selectedIsHighlighted }) =>
    isTabSelected && selectedIsHighlighted
      ? theme.colors.brand.tertiary.lighter
      : 'inherit'};
  filter: brightness(115%);

  &:hover {
    ${({ theme, isTabSelected, selectedIsHighlighted }) =>
      isTabSelected && selectedIsHighlighted
        ? ''
        : `background-color: ${theme.colors.grey[10]};`}
  }
  margin-top: ${({ theme }) => theme.spacing.small};
  margin-bottom: ${({ theme }) => theme.spacing.small};
  margin-right: ${({ theme }) => theme.spacing.small};
  border-radius: 2px;
  cursor: pointer;
`;

const TabTextWrapper = styled.div`
  padding-top: ${({ theme }) => theme.spacing.small};
  padding-right: ${({ theme }) => theme.spacing.small};
`;

const UnselectedTabText = styled(ExtraSmallCaps)`
  color: ${({ theme }) => theme.colors.text.secondary};
`;

const SelectedTabText = styled(ExtraSmallCapsHighlight)`
  color: ${({ theme }) => theme.colors.brand.tertiary.darkest};
`;

const IconWrapper = styled.div`
  width: 24px;
  height: 24px;
  padding: ${({ theme }) => theme.spacing.small};
`;

interface Props {
  Icon?: React.FC<any>;
  displayName: string;
  onClick: React.MouseEventHandler<HTMLElement>;
  isTabSelected: boolean;
  selectedIsHighlighted?: boolean;
  testId: string;
}

const TabButton: React.FC<Props> = ({
  Icon,
  displayName,
  onClick,
  isTabSelected,
  selectedIsHighlighted,
  testId,
}) => {
  const theme = useTheme();

  return (
    <TabButtonWrapper
      isTabSelected={isTabSelected}
      selectedIsHighlighted={Boolean(selectedIsHighlighted)}
      onClick={onClick}
      data-test-id={testId}>
      {Icon && (
        <IconWrapper>
          <Icon
            fill={
              isTabSelected
                ? theme.colors.brand.tertiary.darkest
                : theme.colors.text.secondary
            }
          />
        </IconWrapper>
      )}
      <TabTextWrapper>
        {isTabSelected ? (
          <SelectedTabText testId={`${testId}-text`}>
            {displayName}
          </SelectedTabText>
        ) : (
          <UnselectedTabText testId={`${testId}-text`}>
            {displayName}
          </UnselectedTabText>
        )}
      </TabTextWrapper>
    </TabButtonWrapper>
  );
};

export default TabButton;
