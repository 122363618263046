/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  cx?: any;
  cy?: any;
  r?: any;
  stroke?: any;
  strokeWidth?: any;
  fillRule?: any;
  clipRule?: any;
  strokeLinecap?: any;
  className?: string;
}

const AcausalTranslationalForcesource: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <circle
      cx={props.cx}
      cy={props.cy}
      r={props.r}
      stroke={props.stroke}
      strokeWidth={props.strokeWidth}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M18.5962 12.2561C18.3234 11.9444 17.8496 11.9128 17.5378 12.1856C17.2261 12.4583 17.1945 12.9322 17.4673 13.2439L19.2226 15.25H13.75V13.25C13.75 12.6977 13.3023 12.25 12.75 12.25C12.1977 12.25 11.75 12.6977 11.75 13.25V18.75C11.75 19.3023 12.1977 19.75 12.75 19.75C13.3023 19.75 13.75 19.3023 13.75 18.75V16.75H19.2226L17.4673 18.7561C17.1945 19.0679 17.2261 19.5417 17.5378 19.8144C17.8496 20.0872 18.3234 20.0556 18.5962 19.7439L21.4399 16.4939C21.6873 16.2111 21.6873 15.7889 21.4399 15.5061L18.5962 12.2561Z"
      fill={props.fill}
    />
    <path
      d="M16 30L16 28"
      stroke={props.stroke}
      strokeWidth={props.strokeWidth}
      strokeLinecap={props.strokeLinecap}
    />
    <path
      d="M16 4L16 2"
      stroke={props.stroke}
      strokeWidth={props.strokeWidth}
      strokeLinecap={props.strokeLinecap}
    />
  </svg>
);

AcausalTranslationalForcesource.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  cx: '16',
  cy: '16',
  r: '11.25',
  stroke: '#1D3131',
  strokeWidth: '1.5',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  strokeLinecap: 'round',
  className: '',
};

export default AcausalTranslationalForcesource;
