import { useGetSimulationsForUserReadAllQuery } from 'app/enhancedApi';

export function useSimulations(maxPastSims?: number) {
  const { data, isLoading } = useGetSimulationsForUserReadAllQuery(
    {
      maxPastSims,
    },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  return {
    isLoadingSimulations: isLoading,
    simulations: data,
  };
}
