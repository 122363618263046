import {
  ProjectPermissionRole,
  ProjectPermissionSummary,
  GetProjectPermissionReadAllApiResponse,
} from 'app/apiGenerated/generatedApiTypes';

function getRoleWeight(role: ProjectPermissionRole): number {
  switch (role) {
    case 'admin':
      return 1;
    case 'editor':
      return 2;
    case 'read_only':
      return 3;
  }
  return 4;
}

export interface ProjectPermission {
  id: string;
  role: ProjectPermissionRole;
  email: string;
  memberUuid: string;
  memberName: string;
  memberProfileImageUrl: string;
}

function convertAPIPermissionToPermission(
  apiPermission: ProjectPermissionSummary,
): ProjectPermission {
  return {
    id: apiPermission.uuid,
    role: apiPermission.role || 'viewer',
    email: apiPermission.email,
    memberUuid: apiPermission.user?.uuid || '',
    memberName: apiPermission.user?.display_name || '',
    memberProfileImageUrl: apiPermission.user?.profile_image_url || '',
  };
}

function isMember(permission: ProjectPermission): boolean {
  // If the permission has a name associated to it
  // (not just an email), that means that it is associated as a full
  // member and not just an email invitation.
  // Members should be sorted above invitations in the list.
  return !!permission.memberName;
}

export function convertGetProjectPermissionReadAll(
  apiResponse: GetProjectPermissionReadAllApiResponse,
): ProjectPermission[] {
  const permissions = apiResponse.permissions?.map(
    convertAPIPermissionToPermission,
  );
  if (permissions) {
    // To sort, group by who has accepted invitations first
    // then sort by role (with the most privileged roles at the top),
    // and then sort alphabetically by member display name within each role group.
    return [...permissions].sort((a, b) => {
      // Check for member vs invitation first.
      if (isMember(a) !== isMember(b)) {
        if (isMember(a)) return -1;
        return 1;
      }

      // Next, check for different roles.
      if (a.role !== b.role) {
        return getRoleWeight(a.role) - getRoleWeight(b.role);
      }

      // Finally, for each group of member/invite + role, sort the permissions by name.
      if (isMember(a)) {
        return a.memberName.localeCompare(b.memberName);
      }
      return a.email.localeCompare(b.email);
    });
  }
  return [];
}
