/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  stroke?: any;
  strokeLinecap?: any;
  className?: string;
}

const BackArrow: React.FC<Props> = (props) => (
  <svg viewBox="0 0 16 16" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4 6.66667L6.68447 4M4 6.66667L6.68447 9.33333M4 6.66667C12.2644 5.66667 12.9356 10.3333 11.2578 12"
      stroke={props.stroke}
      strokeLinecap={props.strokeLinecap}
    />
  </svg>
);

BackArrow.defaultProps = {
  width: '16',
  height: '16',
  viewBox: '0 0 16 16',
  fill: 'none',
  xmlns: 'http://www.w3.org/2000/svg',
  stroke: '#020606',
  strokeLinecap: 'round',
  className: '',
};

export default BackArrow;
