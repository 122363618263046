import { t } from '@lingui/macro';
import { ParameterDefinition } from 'app/apiGenerated/generatedApiTypes';
import { SubmodelInfoUI } from 'app/apiTransformers/convertGetSubmodelsListForModelParent';
import { SubmodelInstance } from 'app/generated_types/SimulationModel';
import { useAppSelector } from 'app/hooks';
import { getSpecificReferenceSubmodelByNode } from 'app/utils/submodelUtils';
import React from 'react';
import SectionHeading from 'ui/common/Inputs/SectionHeading';
import { Spinner, SpinnerWrapper } from 'ui/common/Spinner';
import { DetailInputRowsSection } from 'ui/modelEditor/DetailsComponents';
import SubmodelParameterDetail from 'ui/modelEditor/SubmodelParameterDetail';

interface Props {
  parentPath: string[];
  submodelNode: SubmodelInstance;
  disabled: boolean;
}

const SubmodelParameterDetails: React.FC<Props> = ({
  parentPath,
  submodelNode,
  disabled,
}: Props) => {
  const referenceSubmodel: SubmodelInfoUI | undefined = useAppSelector(
    (state) =>
      getSpecificReferenceSubmodelByNode(
        submodelNode,
        state.submodels.idToVersionIdToSubmodelInfo,
      ),
  );

  if (!referenceSubmodel || !referenceSubmodel.parameterDefinitions) {
    return (
      <SpinnerWrapper>
        <Spinner />
      </SpinnerWrapper>
    );
  }

  return (
    <>
      <SectionHeading testId="parameter-definition-details">
        {t({
          id: 'referenceSubmodelDetails.parametersTitle',
          message: 'Parameters',
        })}
      </SectionHeading>
      <DetailInputRowsSection>
        {referenceSubmodel.parameterDefinitions.map(
          (paramDef: ParameterDefinition) => (
            <SubmodelParameterDetail
              parentPath={parentPath}
              submodelNode={submodelNode}
              disabled={disabled}
              paramDef={paramDef}
              key={paramDef.uuid}
            />
          ),
        )}
      </DetailInputRowsSection>
    </>
  );
};

export default SubmodelParameterDetails;
