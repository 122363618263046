import styled from '@emotion/styled/macro';
import React from 'react';

type Props = React.TextareaHTMLAttributes<HTMLTextAreaElement> & {
  className?: string;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  value?: string;
  cols?: number;
  rows?: number;
  resizable?: boolean;
  testId?: string;
  isHidden?: boolean;
};

const Wrapper = styled.textarea<{ resizable: boolean; isHidden?: boolean }>`
  ${({
    theme: {
      colors: { grey, brand },
      spacing: { small, normal, xsmall },
    },
    resizable,
    isHidden,
  }) => `
  width: 100%;
  font-family: 'Barlow', sans-serif;
  font-size: 12px;
  line-height: 18px;
  border: 1px solid ${grey[10]};
  outline: none;
  border-radius: ${xsmall};
  padding: ${small} ${normal};
  resize: ${resizable ? 'auto' : 'none'};
  background-color: transparent;
  &:focus {
    background-color: white;
    box-shadow: 0px 0px 0px 1px ${brand.primary.lighter};
  }
  ${isHidden && 'visibility: hidden;'}
`}
`;
const Textarea: React.FC<Props> = ({
  className,
  onChange,
  cols,
  rows,
  resizable = true,
  testId,
  isHidden,
  ...restProps
}) => (
  <Wrapper
    isHidden={isHidden}
    className={className}
    onChange={onChange}
    cols={cols}
    rows={rows}
    resizable={resizable}
    data-test-id={testId}
    {...restProps}
  />
);

export default Textarea;
