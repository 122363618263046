import { useGetSnapshotReadByUuidQuery } from 'app/apiGenerated/generatedApi';
import { ModelKind } from 'app/apiGenerated/generatedApiTypes';
import React from 'react';
import { submodelsActions } from 'app/slices/submodelsSlice';
import { useAppDispatch } from 'app/hooks';
import { SubmodelLoadOptions } from 'app/api/useSubmodel';
import { getActualVersionId } from 'app/utils/submodelUtils';
import { VersionTagValues } from 'app/apiTransformers/convertPostSubmodelsFetch';
import { modelVersionsActions } from 'app/slices/modelVersionsSlice';

export function useModelVersionData(
  modelId: string,
  versionId: string | undefined,
  kind: ModelKind | undefined,
  options: SubmodelLoadOptions,
) {
  const dispatch = useAppDispatch();

  const actualVersionId = getActualVersionId(modelId, versionId);

  const { data, isFetching } = useGetSnapshotReadByUuidQuery(
    {
      modelUuid: modelId,
      snapshotUuid: actualVersionId || '',
      kind,
    },
    {
      skip:
        !modelId ||
        !kind ||
        !actualVersionId ||
        actualVersionId === VersionTagValues.LATEST_VERSION ||
        !!options.skip,
    },
  );

  React.useEffect(() => {
    if (!data) return;

    if (data.submodel && actualVersionId) {
      dispatch(
        submodelsActions.setSubmodel({
          submodel: data.submodel,
          versionId: actualVersionId,
          loadReferencedSubmodelInfos: !!options.loadReferencedSubmodelInfos,
        }),
      );
    }

    dispatch(modelVersionsActions.onModelVersionLoaded(data));
  }, [dispatch, data, actualVersionId, options, modelId]);

  return {
    versionFull: data,
    isFetching,
  };
}
