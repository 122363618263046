import { t } from '@lingui/macro';
import { useGetSimulationLogFileReadByUuidQuery } from 'app/apiGenerated/generatedApi';
import { SimulationLogJsonFile } from 'app/apiGenerated/generatedApiTypes';
import { SignalTypes } from 'app/generated_types/collimator/dashboard/serialization/ui_types.gen';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
  compilationAnalysisDataActions,
  getValidatedSignalTypeAndTimeInfoData,
} from 'app/slices/compilationAnalysisDataSlice';
import React from 'react';
import {
  matchesExpectedError,
  useNotifications,
} from 'ui/common/notifications/useNotifications';

const genericErrorMsg = t({
  id: 'entityPreferencesApi.getSimSignalDataError',
  message: 'Unable to get signal data from simulation.',
});

export const SIGNAL_TYPE_DATA_FILE_NAME: SimulationLogJsonFile = 'signal_types';

export function useSignalTypeData(
  modelId: string,
  simulationId: string,
  correlationId?: string,
  skipReduxUpdate?: boolean, // avoids side effects
): {
  signalTypes?: SignalTypes;
  error?: string;
} {
  const dispatch = useAppDispatch();
  const { showError } = useNotifications();

  const simulationSummaryStatus = useAppSelector(
    (state) => state.project.simulationSummary?.status,
  );

  const { data: rawSignalTypeInfo, error: simSignalDataError } =
    useGetSimulationLogFileReadByUuidQuery({
      modelUuid: modelId,
      simulationUuid: simulationId,
      simulationJsonLogFile: SIGNAL_TYPE_DATA_FILE_NAME,
      'X-Correlation-ID': correlationId || simulationId,
    });

  const signalTypeData = React.useMemo(() => {
    if (!rawSignalTypeInfo) return null;
    return getValidatedSignalTypeAndTimeInfoData(rawSignalTypeInfo);
  }, [rawSignalTypeInfo]);

  React.useEffect(() => {
    if (skipReduxUpdate) return;
    if (signalTypeData) {
      dispatch(
        compilationAnalysisDataActions.setAllDatatypeData({
          simulationId,
          nodeSignalInfos: signalTypeData,
        }),
      );
    }
  }, [dispatch, skipReduxUpdate, simulationId, signalTypeData]);

  React.useEffect(() => {
    if (skipReduxUpdate) return;
    if (!['completed', 'failed'].includes(simulationSummaryStatus || ''))
      return;

    if (simulationId && simSignalDataError) {
      if (
        matchesExpectedError(simSignalDataError, SIGNAL_TYPE_DATA_FILE_NAME)
      ) {
        /* NOTE:
         * This ticket: https://collimator.atlassian.net/browse/DASH-1402
         * requests that this error be disabled.
         * Not sure removing it is the right thing to do,
         * so just commenting out for now.
        showError(
          t({
            id: 'entityPreferencesApi.noSimSignalDataAvailableError',
            message:
              'No signal data available from simulation. Signal types not generated.',
          }),
          null,
          { isExpectedError: true },
        );
        */
      } else {
        showError(genericErrorMsg, simSignalDataError);
      }

      dispatch(
        compilationAnalysisDataActions.clearSimulationCache({
          simulationId,
        }),
      );
      dispatch(compilationAnalysisDataActions.clearAllCompilationState());
    }
  }, [
    dispatch,
    showError,
    skipReduxUpdate,
    simulationId,
    simSignalDataError,
    simulationSummaryStatus,
  ]);

  const error = React.useMemo(() => {
    if (!simSignalDataError) return undefined;
    if (matchesExpectedError(simSignalDataError, SIGNAL_TYPE_DATA_FILE_NAME)) {
      return genericErrorMsg;
    }
    return t({
      id: 'entityPreferencesApi.getSimSignalDataError',
      message: 'Unable to get signal data from simulation.',
    });
  }, [simSignalDataError]);

  const signalTypes = React.useMemo(
    () => (signalTypeData ? { nodes: signalTypeData } : undefined),
    [signalTypeData],
  );
  return { signalTypes, error };
}
