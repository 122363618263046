import { NodeInstance } from '@collimator/model-schemas-ts';
import { t } from '@lingui/macro';
import { blockClassLookup } from 'app/generated_blocks/';
import { BlockParameterDefinition } from 'app/generated_types/ComputationBlockClass';
import { useAppSelector } from 'app/hooks';
import SectionHeading from 'ui/common/Inputs/SectionHeading';
import BlockParameter from '../BlockParameter';
import { DetailInputRowsSection } from '../DetailsComponents';

const descriptionCorrectionMap: {
  [key: string]:
    | { matricesProvided: string; notMatricesProvided: string }
    | undefined;
} = {
  G: {
    matricesProvided: 'Process noise matrix G for the discrete-time LTI system',
    notMatricesProvided:
      'Process noise matrix G for the linearized continuous-time plant',
  },
  Q: {
    matricesProvided:
      'Process noise covariance matrix Q for the discrete-time LTI system',
    notMatricesProvided:
      'Process noise covariance matrix Q linearized continuous-time plant',
  },
  R: {
    matricesProvided:
      'Measurement noise covariance matrix R for the discrete-time LTI system',
    notMatricesProvided:
      'Measurement noise covariance matrix R linearized continuous-time plant',
  },
  dt: {
    matricesProvided: 'Sampling rate of the discrete-time system',
    notMatricesProvided:
      'Sampling rate for continuous-time plant discreteization',
  },
};

const correctParamDescription = (
  paramDef: BlockParameterDefinition,
  matricesProvided: boolean,
) => {
  const descCorrections = descriptionCorrectionMap[paramDef.name];
  if (descCorrections) {
    return {
      ...paramDef,
      description: matricesProvided
        ? descCorrections.matricesProvided
        : descCorrections.notMatricesProvided,
    };
  }
  return paramDef;
};

export const KalmanBlockParameterDetails = ({
  parentPath,
  selectedNode,
  canEdit,
}: {
  parentPath: string[];
  selectedNode: NodeInstance;
  canEdit: boolean;
}) => {
  const { developerModeEnabled } = useAppSelector(
    (state) => state.userOptions.options,
  );

  if (!selectedNode) return null;

  const blockClass = blockClassLookup(selectedNode.type);
  if (!blockClass || !blockClass.parameter_definitions) {
    return null;
  }

  const isParamVisible = (paramDef?: BlockParameterDefinition): boolean => {
    if (!paramDef || paramDef.hidden) return false;

    if (
      selectedNode.parameters.use_ihkf?.value === 'true' &&
      paramDef.name === 'P_hat_0'
    ) {
      return false;
    }

    if (
      selectedNode.parameters.matrices_provided?.value === 'true' &&
      ['x_eq', 'u_eq', 'discretization_method', 'plant_submodel_uuid'].includes(
        paramDef.name,
      )
    ) {
      return false;
    }

    if (
      selectedNode.parameters.matrices_provided?.value !== 'true' &&
      ['A', 'B', 'C', 'D'].includes(paramDef.name)
    ) {
      return false;
    }

    switch (paramDef.feature_level) {
      case 'disabled':
        return false;
      case 'dev':
        return developerModeEnabled;
      default:
        return true;
    }
  };

  return (
    <>
      <SectionHeading testId="parameter-definition-details">
        {t({
          id: 'kalmanBlockDetails.parametersTitle',
          message: 'Parameters',
        })}
      </SectionHeading>
      <DetailInputRowsSection>
        {blockClass.parameter_definitions.map(
          (param) =>
            isParamVisible(param) && (
              <BlockParameter
                parentPath={parentPath}
                selectedNode={selectedNode}
                paramDef={correctParamDescription(
                  param,
                  Boolean(
                    selectedNode.parameters.matrices_provided?.value === 'true',
                  ),
                )}
                key={`row-${param.name}-${selectedNode.uuid}`}
                isOptional={param.optional}
                disabled={!canEdit}
              />
            ),
        )}
      </DetailInputRowsSection>
    </>
  );
};
