import { TraceMetadata } from 'ui/dataExplorer/dataExplorerTypes';

// DASH-1751. Double check if these are still used after cleanup. Unlikely.

export function getTracesForSimulationSignal(
  simulationId: string,
  signalPath: string,
  idToTrace: Record<string, TraceMetadata>,
): TraceMetadata[] {
  return Object.values(idToTrace).filter(
    (trace) =>
      trace.explorationSimId === simulationId &&
      trace.signalPath === signalPath,
  );
}

export function getTraceIdsForSimulationTrace(
  simulationId: string,
  tracePath: string,
  idToTrace: Record<string, TraceMetadata>,
): string[] {
  return Object.values(idToTrace)
    .filter(
      (trace) =>
        trace.explorationSimId === simulationId &&
        trace.tracePath === tracePath,
    )
    .map((trace) => trace.id);
}
