import { registerClerkUser } from 'app/apiData';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { redirectAfterSignin } from './utils';

const SignedIn: React.FC = () => {
  const navigate = useNavigate();
  React.useEffect(() => {
    registerClerkUser(
      false,
      () => redirectAfterSignin(navigate),
      console.error,
    );
  }, [navigate]);
  return <div />;
};

export default SignedIn;
