import styled from '@emotion/styled';
import React from 'react';
import {
  To,
  useHref,
  useLinkClickHandler,
  useMatch,
  useResolvedPath,
} from 'react-router-dom';

const LinkWrapper = styled.a`
  ${({ theme: { colors, spacing, typography } }) => `
    display: flex;
    align-items: center;
    text-decoration: none;
    width: 100%;
    height: ${spacing.xxlarge};
    color: ${colors.grey[10]};
    font-size: ${typography.font.heading4.size};
    &.active {
      font-weight: 600;
      background-color: ${colors.tint.tint1};
    }
`}
`;

const Link = React.forwardRef<
  HTMLAnchorElement,
  {
    onClick?: React.MouseEventHandler<HTMLAnchorElement>;
    replace?: boolean;
    state?: any;
    target?: React.HTMLAttributeAnchorTarget;
    to: To;
    children: React.ReactNode;
  }
>(({ onClick, replace = false, state, target, to, children, ...rest }, ref) => {
  let resolved = useResolvedPath(to);
  let isRoot = !!useMatch({ path: resolved.pathname, end: true });
  let isActive = !!useMatch({ path: resolved.pathname, end: false });
  let href = useHref(to);
  let handleClick = useLinkClickHandler(to, {
    replace,
    state,
    target,
  });

  return (
    <LinkWrapper
      {...rest}
      className={`${(rest as any).className || ''} ${
        isActive ? 'active' : ''
      } ${isRoot ? 'root' : ''}`}
      href={href}
      onClick={handleClick}
      ref={ref}
      target={target}>
      {children}
    </LinkWrapper>
  );
});

export default Link;
