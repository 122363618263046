import { useGetGitHubReposQuery } from 'app/enhancedApi';
import { useUserCredentials } from './useUserCredentials';

export function useGitHubReposList() {
  const { isGitHubConnected } = useUserCredentials();

  const { data: gitHubReposList, isLoading: isLoadingGitHubRepos } =
    useGetGitHubReposQuery(undefined, {
      refetchOnMountOrArgChange: true,
      skip: !isGitHubConnected,
    });

  return { gitHubReposList, isLoadingGitHubRepos };
}

// Checking whether we are in fact connected to GitHub is a bit tricky because
// we may have valid credentials in the db but they could expire sooner
// than adverstised (6 months), eg. if SSO is enabled (24 hours). So we must
// do an actual API call to check whether we are connected. It'll probably
// be worth adding a better caching and checking mechanism in the backend.
export function useGitHubCredentials() {
  const { isGitHubConnected, isLoadingCredentials } = useUserCredentials();

  const { data: gitHubReposList, isLoading: isLoadingGitHubRepos } =
    useGetGitHubReposQuery(undefined, {
      skip: !isGitHubConnected,
    });

  return {
    isGithubCredentialsLoading: isLoadingGitHubRepos || isLoadingGitHubRepos,
    isGithubCredentialsValid:
      isGitHubConnected &&
      !isLoadingGitHubRepos &&
      gitHubReposList?.repos !== undefined,
  };
}
