/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  cx?: any;
  cy?: any;
  r?: any;
  stroke?: any;
  strokeWidth?: any;
  strokeLinecap?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const AcausalElectricalCurrentsensor: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <circle
      cx={props.cx}
      cy={props.cy}
      r="11.25"
      stroke={props.stroke}
      strokeWidth={props.strokeWidth}
    />
    <path
      d="M16 5.5V7.5"
      stroke={props.stroke}
      strokeWidth={props.strokeWidth}
      strokeLinecap={props.strokeLinecap}
    />
    <path
      d="M25.0938 10.75L23.3617 11.75"
      stroke={props.stroke}
      strokeWidth={props.strokeWidth}
      strokeLinecap={props.strokeLinecap}
    />
    <path
      d="M6.90625 10.75L8.6383 11.75"
      stroke={props.stroke}
      strokeWidth={props.strokeWidth}
      strokeLinecap={props.strokeLinecap}
    />
    <path
      d="M10.75 6.90673L11.75 8.63878"
      stroke={props.stroke}
      strokeWidth={props.strokeWidth}
      strokeLinecap={props.strokeLinecap}
    />
    <path
      d="M21.25 6.90673L20.25 8.63878"
      stroke={props.stroke}
      strokeWidth={props.strokeWidth}
      strokeLinecap={props.strokeLinecap}
    />
    <path
      d="M17.2707 25C17.212 25 17.1747 24.9733 17.1587 24.92L16.9507 24.208C16.94 24.1867 16.9267 24.176 16.9107 24.176H15.0867C15.0707 24.176 15.0573 24.1867 15.0467 24.208L14.8387 24.92C14.8227 24.9733 14.7853 25 14.7267 25H13.5027C13.4707 25 13.4467 24.992 13.4307 24.976C13.4147 24.9547 13.412 24.9253 13.4227 24.888L15.1347 19.48C15.1507 19.4267 15.1853 19.4 15.2387 19.4H16.7507C16.804 19.4 16.8387 19.4267 16.8547 19.48L18.5747 24.888C18.58 24.8987 18.5827 24.912 18.5827 24.928C18.5827 24.976 18.5533 25 18.4947 25H17.2707ZM15.3667 23.104C15.3613 23.136 15.372 23.152 15.3987 23.152H16.5907C16.6227 23.152 16.6333 23.136 16.6227 23.104L16.0147 21.048C16.0093 21.0267 16.0013 21.0187 15.9907 21.024C15.98 21.024 15.972 21.032 15.9667 21.048L15.3667 23.104Z"
      fill={props.fill}
    />
    <circle cx={props.cx} cy={props.cy} r={props.r} stroke={props.stroke} />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M14.7278 7.8181C14.6273 7.41625 14.2201 7.17193 13.8183 7.2724C13.4164 7.37286 13.1721 7.78006 13.2726 8.1819L14.8229 14.3831C15.1532 14.1422 15.5601 14 16.0002 14C16.0945 14 16.1872 14.0065 16.278 14.0191L14.7278 7.8181Z"
      fill={props.fill}
    />
    <path
      d="M16 30L16 28"
      stroke={props.stroke}
      strokeWidth={props.strokeWidth}
      strokeLinecap={props.strokeLinecap}
    />
    <path
      d="M16 4L16 2"
      stroke={props.stroke}
      strokeWidth={props.strokeWidth}
      strokeLinecap={props.strokeLinecap}
    />
  </svg>
);

AcausalElectricalCurrentsensor.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  cx: '16',
  cy: '16',
  r: '1.5',
  stroke: '#1D3131',
  strokeWidth: '1.5',
  strokeLinecap: 'round',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default AcausalElectricalCurrentsensor;
