/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  className?: string;
}

const AddAnnotation: React.FC<Props> = (props) => (
  <svg viewBox="0 0 24 24" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.89192 5.77432C5.92373 5.75897 5.95976 5.75 6 5.75H7.57143C7.98564 5.75 8.32143 5.41421 8.32143 5C8.32143 4.58579 7.98564 4.25 7.57143 4.25H6C5.72924 4.25 5.47085 4.31202 5.24014 4.42332C4.99898 4.53966 4.53966 4.99898 4.42332 5.24014C4.31202 5.47085 4.25 5.72924 4.25 6V7.57143C4.25 7.98564 4.58579 8.32143 5 8.32143C5.41421 8.32143 5.75 7.98564 5.75 7.57143V6C5.75 5.95976 5.75897 5.92373 5.77432 5.89192C5.78754 5.8645 5.79895 5.83668 5.80859 5.80859C5.83668 5.79895 5.8645 5.78754 5.89192 5.77432Z"
      fill={props.fill}
    />
    <path
      d="M9.92857 4.25C9.51436 4.25 9.17857 4.58579 9.17857 5C9.17857 5.41421 9.51436 5.75 9.92857 5.75H13.0714C13.4856 5.75 13.8214 5.41421 13.8214 5C13.8214 4.58579 13.4856 4.25 13.0714 4.25H9.92857Z"
      fill={props.fill}
    />
    <path
      d="M15.4286 4.25C15.0144 4.25 14.6786 4.58579 14.6786 5C14.6786 5.41421 15.0144 5.75 15.4286 5.75H17C17.0402 5.75 17.0763 5.75897 17.1081 5.77432C17.1355 5.78754 17.1633 5.79895 17.1914 5.80859C17.2011 5.83668 17.2125 5.8645 17.2257 5.89192C17.241 5.92373 17.25 5.95976 17.25 6V7.57143C17.25 7.98564 17.5858 8.32143 18 8.32143C18.4142 8.32143 18.75 7.98564 18.75 7.57143V6C18.75 5.72924 18.688 5.47085 18.5767 5.24014C18.4603 4.99898 18.001 4.53966 17.7599 4.42332C17.5291 4.31202 17.2708 4.25 17 4.25H15.4286Z"
      fill={props.fill}
    />
    <path
      d="M5.75 9.92857C5.75 9.51436 5.41421 9.17857 5 9.17857C4.58579 9.17857 4.25 9.51436 4.25 9.92857V13.0714C4.25 13.4856 4.58579 13.8214 5 13.8214C5.41421 13.8214 5.75 13.4856 5.75 13.0714V9.92857Z"
      fill={props.fill}
    />
    <path
      d="M18.75 9.92857C18.75 9.51436 18.4142 9.17857 18 9.17857C17.5858 9.17857 17.25 9.51436 17.25 9.92857V13.0714C17.25 13.4856 17.5858 13.8214 18 13.8214C18.4142 13.8214 18.75 13.4856 18.75 13.0714V9.92857Z"
      fill={props.fill}
    />
    <path
      d="M5.75 15.4286C5.75 15.0144 5.41421 14.6786 5 14.6786C4.58579 14.6786 4.25 15.0144 4.25 15.4286V17C4.25 17.2708 4.31202 17.5291 4.42332 17.7599C4.53966 18.001 4.99898 18.4603 5.24014 18.5767C5.47085 18.688 5.72924 18.75 6 18.75H7.57143C7.98564 18.75 8.32143 18.4142 8.32143 18C8.32143 17.5858 7.98564 17.25 7.57143 17.25H6C5.95976 17.25 5.92373 17.241 5.89192 17.2257C5.8645 17.2125 5.83668 17.2011 5.80859 17.1914C5.79895 17.1633 5.78754 17.1355 5.77432 17.1081C5.75897 17.0763 5.75 17.0402 5.75 17V15.4286Z"
      fill={props.fill}
    />
    <path
      d="M9.92857 17.25C9.51436 17.25 9.17857 17.5858 9.17857 18C9.17857 18.4142 9.51436 18.75 9.92857 18.75H13.0714C13.4856 18.75 13.8214 18.4142 13.8214 18C13.8214 17.5858 13.4856 17.25 13.0714 17.25H9.92857Z"
      fill={props.fill}
    />
    <path
      d="M18.75 16C18.75 15.5858 18.4142 15.25 18 15.25C17.5858 15.25 17.25 15.5858 17.25 16V17.25H16C15.5858 17.25 15.25 17.5858 15.25 18C15.25 18.4142 15.5858 18.75 16 18.75H17.25V20C17.25 20.4142 17.5858 20.75 18 20.75C18.4142 20.75 18.75 20.4142 18.75 20V18.75H20C20.4142 18.75 20.75 18.4142 20.75 18C20.75 17.5858 20.4142 17.25 20 17.25H18.75V16Z"
      fill={props.fill}
    />
    <path
      d="M14.12 8.04C14.1 8.01333 14.0733 8 14.04 8H9C8.96667 8 8.93667 8.01333 8.91001 8.04C8.89001 8.06 8.88 8.08667 8.88 8.12V9.1C8.88 9.13333 8.89001 9.16333 8.91001 9.19C8.93667 9.21 8.96667 9.22 9 9.22H10.74C10.7733 9.22 10.79 9.23667 10.79 9.27V14.88C10.79 14.9133 10.8 14.9433 10.82 14.97C10.8467 14.99 10.8767 15 10.91 15H12.08C12.1133 15 12.14 14.99 12.16 14.97C12.1867 14.9433 12.2 14.9133 12.2 14.88V9.27C12.2 9.23667 12.2167 9.22 12.25 9.22H14.04C14.0733 9.22 14.1 9.21 14.12 9.19C14.1467 9.16333 14.16 9.13333 14.16 9.1V8.12C14.16 8.08667 14.1467 8.06 14.12 8.04Z"
      fill={props.fill}
    />
  </svg>
);

AddAnnotation.defaultProps = {
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  className: '',
};

export default AddAnnotation;
