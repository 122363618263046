import styled from '@emotion/styled/macro';
import { t } from '@lingui/macro';
import Button from 'ui/common/Button/Button';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import { Check } from 'ui/common/Icons/Standard';

const SelectionPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding-bottom: 95px;
  color: ${({ theme }) => theme.colors.grey[2]};

  a {
    color: ${({ theme }) => theme.colors.grey[2]};
  }
`;

const PageTitle = styled.div`
  ${({ theme }) => `
    font-size: ${theme.typography.font.heading2.size};
    font-weight: ${theme.typography.font.heading2.weight};
    line-height: ${theme.typography.font.heading2.lineHeight};
    margin-bottom: ${theme.spacing.xxlarge};
  `};
`;

const PageDesc = styled.div`
  ${({ theme }) => `
    font-size: ${theme.typography.font.title.size};
    line-height: ${theme.typography.font.title.lineHeight};
    margin-bottom: ${theme.spacing.xxlarge};
  `};
`;

const AllTiersContainer = styled.div`
  display: flex;
  align-items: flex-start;
  width: 1112px;
`;

const TierContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: rgba(0, 0, 0, 0.12);
  border: 1px solid ${({ theme }) => theme.colors.grey[50]};
  border-radius: 8px;
  margin-right: ${({ theme }) => theme.spacing.large};
  padding: ${({ theme }) => `${theme.spacing.xxlarge} ${theme.spacing.normal}`};
  min-height: 0;

  button {
    height: 40px;
  }
`;

const TierTitle = styled.div`
  ${({ theme }) => `
    font-size: ${theme.typography.font.heading2.size};
    font-weight: ${theme.typography.font.heading2.weight};
    line-height: ${theme.typography.font.heading2.lineHeight};
  `};
`;

const TierPricing = styled.div<{ request?: boolean }>`
  ${({ theme, request }) => `
    font-size: ${theme.typography.font.heading3.size};
    font-weight: ${theme.typography.font.heading3.weight};
    line-height: ${theme.typography.font.heading3.lineHeight};
    margin-bottom: ${theme.spacing.xxlarge};
    ${
      request
        ? `
      font-style: italic;
      opacity: 0.7;
    `
        : ''
    }
  `}
`;

const TierDescription = styled.div`
  text-align: center;
  ${({ theme }) => `
    font-size: ${theme.typography.font.standard.size};
    line-height: ${theme.typography.font.standard.lineHeight};
    margin-bottom: ${theme.spacing.xxlarge};
  `};
`;

const TierFeaturesTitle = styled.div`
  ${({ theme }) => `
    font-size: ${theme.typography.font.standard.size};
    line-height: ${theme.typography.font.standard.lineHeight};
    margin-bottom: ${theme.spacing.normal};
  `};
`;

const TierFeaturesList = styled.div`
  ${({ theme }) => `
    margin-bottom: ${theme.spacing.xxlarge};
  `};
`;

const TierFeature = styled.div`
  display: flex;
  align-items: center;
  font-weight: 600;
  ${({ theme }) => `
    font-size: ${theme.typography.font.standard.size};
    margin-bottom: ${theme.spacing.normal};
  `};

  &:last-child {
    margin-bottom: 0;
  }
`;

const TierFeatureIcon = styled.div`
  margin-right: ${({ theme }) => theme.spacing.normal};
`;

const Tier = ({
  title,
  pricing,
  requestPricing,
  description,
  featuresTitle,
  featuresList,
  ctaText,
  onSelect,
}: {
  title: string;
  pricing: string;
  requestPricing?: boolean;
  description: string;
  featuresTitle: string;
  featuresList: string[];
  ctaText: string;
  onSelect: () => void;
}) => (
  <TierContainer>
    <TierTitle>{title}</TierTitle>
    <TierPricing request={requestPricing}>{pricing}</TierPricing>
    <TierDescription>{description}</TierDescription>
    <TierFeaturesTitle>{featuresTitle}</TierFeaturesTitle>
    <TierFeaturesList>
      {featuresList.map((feature) => (
        <TierFeature>
          <TierFeatureIcon>
            <Check fill="rgba(241, 243, 243, 1)" />
          </TierFeatureIcon>
          {feature}
        </TierFeature>
      ))}
    </TierFeaturesList>
    <Button
      variant={ButtonVariants.LargePrimary}
      tint="#d4c355"
      textTint="#082426"
      onClick={onSelect}>
      {ctaText}
    </Button>
  </TierContainer>
);

export const TierSelection = ({
  onSelectTier,
}: {
  onSelectTier: (tier: string) => void;
}) => {
  const contact = () => {
    window.open('mailto:help@collimator.ai');
  };

  return (
    <SelectionPageContainer>
      <PageTitle>{t`Choose the plan that is right for you`}</PageTitle>
      <PageDesc>
        {t`For full information about the features included in these tiers, please visit our`}
        &nbsp;
        <a
          href="https://collimator.ai/pricing"
          target="_blank"
          rel="noreferrer">
          {t`pricing page`}
        </a>
        .
      </PageDesc>
      <AllTiersContainer>
        <Tier
          title={t`Starter`}
          pricing={t`$100 per user / month`}
          description={t`Perfect for emerging innovators. Includes essential tools for smaller teams and projects.`}
          featuresTitle={t`Plan includes:`}
          featuresList={[
            t`Intuitive graphical interface`,
            t`Single click to simulate`,
            t`Python native`,
            t`Version control`,
            t`Shared projects`,
            t`Store everything in the cloud`,
          ]}
          ctaText={t`Start free trial`}
          onSelect={() => {
            onSelectTier('starter');
          }}
        />
        <Tier
          title={t`Professional`}
          pricing={t`$400 per user / month`}
          description={t`Ideal for larger teams looking for faster collaboration and greater integration with existing tools.`}
          featuresTitle={t`Everything in Starter, plus:`}
          featuresList={[
            t`Hosted AI chat`,
            t`Prioritized CPU simulations`,
            t`AI and ML integrations`,
            t`Data and tool integrations`,
            t`Real-time collaboration`,
            t`Priority support`,
            t`FMU support`,
            t`Requirements management`,
          ]}
          ctaText={t`Contact us`}
          onSelect={contact}
        />
        <Tier
          title={t`Enterprise`}
          pricing={t`Request pricing`}
          requestPricing
          description={t`Custom solutions for lare enterprises with extensive needs and requirements`}
          featuresTitle={t`Everything in Professional, plus:`}
          featuresList={[
            t`Unlimited simulations`,
            t`GPU acceleration`,
            t`Unlimited data storage`,
            t`Code generation`,
            t`ITAR compliance`,
            t`Security compliance audits`,
          ]}
          ctaText={t`Contact us`}
          onSelect={contact}
        />
      </AllTiersContainer>
    </SelectionPageContainer>
  );
};
