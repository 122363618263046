/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  clipPath?: any;
  fillRule?: any;
  clipRule?: any;
  id?: any;
  className?: string;
}

const TrigAcos: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <g clipPath={props.clipPath}>
      <path
        fillRule={props.fillRule}
        clipRule={props.clipRule}
        d="M15.7354 27.9999L15.7424 16.25L0 16.25V15.75L15.7427 15.75L15.7453 11.3795L13.2507 8.74961C13.2454 8.74402 13.2402 8.73834 13.235 8.73258L12.586 8.00444C12.5791 7.99675 12.5724 7.98892 12.5659 7.98096L12.061 7.36536C12.0532 7.35585 12.0457 7.34614 12.0383 7.33625L11.6345 6.79112C11.6262 6.78002 11.6183 6.76869 11.6107 6.75715L11.2934 6.2753C11.2851 6.26264 11.2771 6.24973 11.2695 6.23658L11.0099 5.78494C11.0018 5.77078 10.9941 5.75635 10.9869 5.74169L10.785 5.33037C10.7774 5.31496 10.7704 5.29929 10.7639 5.28339L10.668 5.04858C10.5114 4.66511 10.6953 4.22729 11.0788 4.07069C11.4622 3.91409 11.9 4.09801 12.0566 4.48148L12.1428 4.69244L12.3226 5.05862L12.5586 5.46929L12.8521 5.91483L13.2326 6.42847L13.7159 7.01784L14.347 7.72582L15.7466 9.20145L15.7498 3.99988L16.2498 4.00018L16.2463 9.72814L16.9497 10.4696C16.9572 10.4774 16.9645 10.4854 16.9716 10.4936L17.6207 11.2401C17.6285 11.2491 17.6361 11.2583 17.6435 11.2676L18.1339 11.8892C18.1423 11.8999 18.1505 11.9108 18.1583 11.9219L18.5334 12.4545C18.5421 12.4669 18.5504 12.4795 18.5583 12.4924L18.8612 12.983C18.8698 12.9969 18.8779 13.0111 18.8856 13.0255L19.1164 13.4605C19.1245 13.4759 19.1322 13.4916 19.1392 13.5075L19.3267 13.9296C19.3344 13.9469 19.3414 13.9644 19.3478 13.9822L19.492 14.3868C19.4986 14.4052 19.5044 14.4239 19.5095 14.4427L19.6105 14.8158C19.6159 14.8357 19.6205 14.8558 19.6242 14.8761L19.6963 15.2684C19.6997 15.2867 19.7024 15.3052 19.7044 15.3237L19.7332 15.5915C19.7351 15.6087 19.7363 15.6259 19.737 15.6431L19.7411 15.75L33 15.75V16.25L19.7074 16.25C19.6079 16.5312 19.345 16.7373 19.0288 16.7494C18.6924 16.7623 18.3993 16.5516 18.2928 16.25H16.2424L16.2354 28.0002L15.7354 27.9999ZM18.24 15.75L18.2391 15.7264L18.216 15.5121L18.1545 15.1778L18.0693 14.8629L17.9444 14.5126L17.779 14.1404L17.572 13.7501L17.2939 13.2996L16.9437 12.8023L16.477 12.2108L16.245 11.944L16.2427 15.75H18.24Z"
        fill="#1D3131"
      />
    </g>
    <defs>
      <clipPath id={props.id}>
        <rect width={props.width} height={props.height} fill={props.fill} />
      </clipPath>
    </defs>
  </svg>
);

TrigAcos.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: 'white',
  xmlns: 'http://www.w3.org/2000/svg',
  clipPath: 'url(#clip0_2107_53949)',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  id: 'clip0_2107_53949',
  className: '',
};

export default TrigAcos;
