// !!!! -- DO NOT MANUALLY EDIT -- !!!!
// This file was generated by something in scripts/generateDefaultBlockData.js
// If you want to change the contents of this file, it should be automated
//
// (You can edit this file locally, but it may not be checked into the codebase -
// PRs with a manual edit of this file are forbidden from being merged.)

import { computationBlockDefaults } from 'app/generated_blocks/computationBlockDefaults';
import type { ComputationBlockClass } from 'app/generated_types/ComputationBlockClass';
import deepmerge from 'deepmerge';

export const Adder: ComputationBlockClass = deepmerge(
  computationBlockDefaults,
  {
    "base": {
      "name": "Adder",
      "namespace": "core",
      "description": "Adds and/or subtracts 2 or more inputs together.",
      "development_notes": "the parameter operators must be used to configure the number of block inputs. this algorithm can be found in maths.py/Adder/compute_configuration",
      "keywords": [
        "sum",
        "subtract"
      ],
      "help_url": "https://docs.collimator.ai/using-model-editors/block-library/agnostic-blocks#adder"
    },
    "modes": {
      "time": "agnostic"
    },
    "ports": {
      "inputs": {
        "dynamic": {
          "min_count": 2,
          "max_count": -1,
          "default_count": 2
        }
      },
      "outputs": {
        "static": [
          {
            "name": "out_0"
          }
        ]
      }
    },
    "parameter_definitions": [
      {
        "name": "operators",
        "description": "A string containing the signs to apply to each input port, like '++' or '+-'. Must contain exactly as many symbols as input ports. For each operator, an input port named `in_<n>` will be automatically generated (where n is a number starting with 0).",
        "description_gpt": "A list of operations to apply between inputs. Each operation must be either 'add' or 'sub'.",
        "data_type": "string",
        "default_value": "++",
        "hidden": true
      }
    ]
  }
);
