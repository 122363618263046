/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Tag: React.FC<Props> = (props) => (
  <svg viewBox="0 0 24 24" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M5.5 9.5V14.5H15.8787L18.3787 12L15.8787 9.5H5.5ZM5 8C4.44772 8 4 8.44772 4 9V15C4 15.5523 4.44772 16 5 16H16.0858C16.351 16 16.6054 15.8946 16.7929 15.7071L19.7929 12.7071C20.1834 12.3166 20.1834 11.6834 19.7929 11.2929L16.7929 8.29289C16.6054 8.10536 16.351 8 16.0858 8H5Z"
      fill={props.fill}
    />
  </svg>
);

Tag.defaultProps = {
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Tag;
