import React from 'react';
import { Cell } from 'react-table';
import { StarEmpty, StarFilled } from 'ui/common/Icons/Standard';
import { ProjectsListTableData as TableData } from 'ui/dashboard/dashboardTypes';
import { useEditProjectFavorites } from 'ui/userPreferences/useEditProjectFavorites';

interface Props {
  projectId: string;
  isFavorite?: boolean;
}

export const FavoriteIcon: React.FC<Props> = ({ projectId, isFavorite }) => {
  const { setAsFavorite, removeFavorite, isUpdating } =
    useEditProjectFavorites();

  const toggleFavoriteOn = React.useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      if (isUpdating) return;
      setAsFavorite(projectId);
    },
    [setAsFavorite, projectId, isUpdating],
  );
  const toggleFavoriteOff = React.useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      if (isUpdating) return;
      removeFavorite(projectId);
    },
    [removeFavorite, projectId, isUpdating],
  );

  return isFavorite ? (
    <StarFilled onClick={toggleFavoriteOff} />
  ) : (
    <StarEmpty onClick={toggleFavoriteOn} />
  );
};

export const FavoriteIconForTable = ({ cell }: { cell: Cell<TableData> }) => {
  const projectId = cell.row.original.uuid;
  return (
    <FavoriteIcon
      projectId={projectId}
      isFavorite={cell.row.original.isFavorite}
    />
  );
};
