import { MarkedPoint } from 'ui/dataExplorer/dataExplorerTypes';

export function isSameMarkedPoint(
  point1: MarkedPoint,
  point2: MarkedPoint,
): boolean {
  return point1.x === point2.x && point1.y === point2.y;
}

export function containsMarkedPoint(
  markedPoints: MarkedPoint[],
  point: MarkedPoint,
): boolean {
  return markedPoints.some((existingPoint) =>
    isSameMarkedPoint(existingPoint, point),
  );
}
