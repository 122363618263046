import { useUserCredentials } from 'app/api/useUserCredentials';
import { useGetUserStatisticsQuery } from 'app/apiGenerated/generatedApi';
import { useAppSelector } from 'app/hooks';
import React from 'react';

export const useAuthorizationCheck = () => {
  const { userCredentials } = useUserCredentials();
  const { data: userStats } = useGetUserStatisticsQuery();
  const { chatCommonKeyMaxCallCount } = useAppSelector(
    (state) => state.userOptions.options,
  );

  const { hasOpenAiKey } = React.useMemo(
    () => ({
      hasOpenAiKey: userCredentials?.items.some(
        (item) => item.kind === 'openai_api_key',
      ),
    }),
    [userCredentials?.items],
  );

  const { canUseCollimatorKey } = React.useMemo(
    () => ({
      canUseCollimatorKey:
        userStats?.chat_call_count === undefined ||
        userStats?.chat_call_count < chatCommonKeyMaxCallCount ||
        chatCommonKeyMaxCallCount === -1,
    }),
    [chatCommonKeyMaxCallCount, userStats?.chat_call_count],
  );
  return {
    isAuthorized: canUseCollimatorKey || hasOpenAiKey,
  };
};
