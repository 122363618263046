// !!!! -- DO NOT MANUALLY EDIT -- !!!!
// This file was generated by something in scripts/generateDefaultBlockData.js
// If you want to change the contents of this file, it should be automated
//
// (You can edit this file locally, but it may not be checked into the codebase -
// PRs with a manual edit of this file are forbidden from being merged.)

import { computationBlockDefaults } from 'app/generated_blocks/computationBlockDefaults';
import type { ComputationBlockClass } from 'app/generated_types/ComputationBlockClass';
import deepmerge from 'deepmerge';

export const AcausalHydraulicPump: ComputationBlockClass = deepmerge(
  computationBlockDefaults,
  {
    "base": {
      "name": "Pump",
      "namespace": "acausal.hydraulic",
      "description": "Simple pump in hydraulic domain."
    },
    "modes": {
      "time": "agnostic"
    },
    "ports": {
      "inputs": {
        "static": [
          {
            "name": "port_a",
            "variant": {
              "variant_kind": "acausal",
              "acausal_domain": "hydraulic"
            }
          },
          {
            "name": "power"
          }
        ]
      },
      "outputs": {
        "static": [
          {
            "name": "port_b",
            "variant": {
              "variant_kind": "acausal",
              "acausal_domain": "hydraulic"
            }
          }
        ]
      }
    },
    "parameter_definitions": [
      {
        "name": "dPmax",
        "data_type": "float",
        "default_value": "1.0",
        "description": "Maximum pressure differential in Pa. The max outlet pressure is dP_max - P_in."
      },
      {
        "name": "CoP",
        "data_type": "float",
        "default_value": "1.0",
        "description": "Pump performance coefficient that converts dP*power to massflow. CoP has units of kg*s*MPa/Watt."
      }
    ]
  }
);
