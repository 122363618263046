import React from 'react';
import { t } from '@lingui/macro';
import { ButtonConfig, ItemType } from 'ui/common/Menu/menuItemTypes';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'app/hooks';

interface VersionMenuOptions {
  thisModelId: string;
  thisVersionId: string;
  urlProjectId: string;
  urlModelId: string;
  urlVersionId: string | undefined;
  isWorkingVersion: boolean;
  initialVersionName: string;
  canEditModelMetadata: boolean;
  restoreVersion?: (modelUuid: string, versionUuid: string) => void;
}

export function useVersionItemMenu({
  thisModelId,
  thisVersionId,
  urlProjectId,
  urlModelId,
  urlVersionId,
  isWorkingVersion,
  initialVersionName,
  canEditModelMetadata,
  restoreVersion,
}: VersionMenuOptions) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [isRenaming, setIsRenaming] = React.useState(false);
  const [latestVersionName, setLatestVersionName] =
    React.useState(initialVersionName);
  const startRename = React.useCallback(
    (e?: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (!canEditModelMetadata) return;

      if (!isRenaming) {
        setIsRenaming(true);
        e?.preventDefault();
        e?.stopPropagation();
      }
    },
    [isRenaming, canEditModelMetadata],
  );

  const endRename = React.useCallback(() => {
    setIsRenaming(false);
  }, []);

  // Working version is selected if we are not currently viewing a version.
  const isSelectedAsWorkingVersion = isWorkingVersion && !urlVersionId;

  // Readonly version is selected if we are currently viewing the same version in the URL.
  const isSelectedAsReadonlyVersion =
    !!urlVersionId &&
    thisModelId === urlModelId &&
    thisVersionId === urlVersionId;
  const isVersionSelected =
    isSelectedAsWorkingVersion || isSelectedAsReadonlyVersion;

  const navigateToItem = React.useCallback(
    (e?: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (isVersionSelected) return;

      if (isWorkingVersion) {
        navigate(`/projects/${urlProjectId}/models/${thisModelId}`);
      } else {
        navigate(`versions/${thisVersionId}`);
      }
      e?.preventDefault();
      e?.stopPropagation();
    },
    [
      isVersionSelected,
      isWorkingVersion,
      navigate,
      urlProjectId,
      thisModelId,
      thisVersionId,
    ],
  );

  const renameMenuItem = React.useMemo(
    (): ButtonConfig => ({
      type: ItemType.Button,
      content: t({
        id: 'diagramVersionHistory.moreMenu.renameVersionMenuItemLabel',
        message: 'Rename',
      }),
      onClick: startRename,
    }),
    [startRename],
  );

  const bookmarkMenuItem = React.useMemo(
    (): ButtonConfig => ({
      type: ItemType.Button,
      content: t({
        id: 'diagramVersionHistory.moreMenu.saveWorkingVersionMenuItemLabel',
        message: 'Save',
      }),
      onClick: startRename,
    }),
    [startRename],
  );

  const viewMenuItem = React.useMemo(
    (): ButtonConfig => ({
      type: ItemType.Button,
      content: t({
        id: 'diagramVersionHistory.moreMenu.viewVersionMenuItemLabel',
        message: 'View',
      }),
      onClick: navigateToItem,
    }),
    [navigateToItem],
  );

  const editMenuItem = React.useMemo(
    (): ButtonConfig => ({
      type: ItemType.Button,
      content: t({
        id: 'diagramVersionHistory.moreMenu.editVersionMenuItemLabel',
        message: 'Edit',
      }),
      onClick: navigateToItem,
    }),
    [navigateToItem],
  );

  const restoreMenuItem = React.useMemo(
    (): ButtonConfig => ({
      type: ItemType.Button,
      content: t({
        id: 'diagramVersionHistory.moreMenu.restoreVersionMenuItemLabel',
        message: 'Restore',
      }),
      onClick: restoreVersion
        ? () => restoreVersion(thisModelId, thisVersionId)
        : () => {},
    }),
    [restoreVersion, thisModelId, thisVersionId],
  );

  let menuItems;
  if (isWorkingVersion) {
    if (isVersionSelected) {
      menuItems = [bookmarkMenuItem];
    } else if (canEditModelMetadata) {
      menuItems = [bookmarkMenuItem, editMenuItem];
    } else {
      menuItems = [bookmarkMenuItem, viewMenuItem];
    }
  } else if (isVersionSelected) {
    menuItems = [renameMenuItem, viewMenuItem, restoreMenuItem];
  } else {
    menuItems = [renameMenuItem, restoreMenuItem];
  }

  return {
    isVersionSelected,
    navigateToItem,
    menuItems,
    endRename,
    setLatestVersionName,
    startRename,
    latestVersionName,
    isRenaming,
  };
}
