import {
  JobSummary,
  UserSimulationListResponse,
} from 'app/apiGenerated/generatedApiTypes';
import { SimulationSummaryUI } from 'app/apiTransformers/convertGetSimulationReadAll';

export interface SimulationSummariesUI {
  runningSimulations: SimulationSummaryUI[];
  queuedSimulations: SimulationSummaryUI[];
  pastSimulations: SimulationSummaryUI[];
}

export function convertAPISimulationSummaryToSimulationSummaryUI(
  apiSimulationSummary: JobSummary,
): SimulationSummaryUI {
  return {
    id: apiSimulationSummary.uuid,
    projectId: apiSimulationSummary.project_uuid,
    modelId: apiSimulationSummary.model_uuid,
    modelVersionId: apiSimulationSummary.snapshot_uuid,
    startDate: apiSimulationSummary.created_at,
    status: apiSimulationSummary.status,
    areResultsAvailable: apiSimulationSummary.results_available || false,
    kind: apiSimulationSummary.kind,
  };
}

function convertSummaryList(summaries: JobSummary[]) {
  return summaries
    .filter((apiSimulationSummary) =>
      ['Model', 'EnsembleSimulation', 'Optimization'].includes(
        apiSimulationSummary.kind ?? '',
      ),
    )
    .map((apiSimulationSummary) =>
      convertAPISimulationSummaryToSimulationSummaryUI(apiSimulationSummary),
    )
    .sort(
      (a, b) =>
        new Date(b.startDate).getTime() - new Date(a.startDate).getTime(),
    );
}

export function convertGetSimulationsForUserReadAll(
  apiResponse: UserSimulationListResponse,
): SimulationSummariesUI {
  const runningSimulations = convertSummaryList(
    apiResponse.running_simulations,
  );
  const queuedSimulations = convertSummaryList(apiResponse.queued_simulations);
  const pastSimulations = convertSummaryList(apiResponse.past_simulations);

  return {
    runningSimulations,
    queuedSimulations,
    pastSimulations,
  };
}
