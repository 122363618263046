import { ModelConfiguration } from 'types';
import { MigratedModelV2 } from './modelMigrations_v2';

export interface MigratedModelV3 extends MigratedModelV2 {}

function migrateModelConfiguration(cfg: any): ModelConfiguration {
  // Deletes fields that shouldn't exist
  const solver = { ...(cfg.solver || {}) };
  delete solver.type;
  delete solver.fixed_step;
  delete solver.first_step;
  delete solver.max_interval_between_samples;

  const max_results_interval: number | undefined =
    cfg.max_results_interval ?? cfg.continuous_time_result_interval;

  const config = { ...cfg, solver, max_results_interval };
  delete config.data_points_min;
  delete config.events_handling;

  // A few other fields that used to exist
  delete config.oversample_output_data;
  delete config.continuous_time_result_interval;

  // Remove __developer_options too - don't need for wildcat
  delete config.__developer_options;

  return config;
}

export function migrateModelDataV2toV3(
  modelData: MigratedModelV2,
): MigratedModelV3 {
  const data = { ...modelData, schema_version: '3' } as MigratedModelV3;
  if (!data.configuration) return data;

  if (modelData.kind === 'Model' || !modelData.kind) {
    data.configuration = migrateModelConfiguration(data.configuration);
  } else if (modelData.kind === 'Submodel') {
    // Some JSONs in git have 'configuration' field with contents matching
    // model-configuration.schema.json but that's not valid with Submodels.
    // I suspect a previous migration was not done correctly.
    delete data.configuration;
  }
  return data;
}
