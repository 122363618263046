import { BlockClassName, ModelDiagram } from '@collimator/model-schemas-ts';
import styled from '@emotion/styled';
import {
  OptimizationAlgoName,
  OptimizationDesignParam,
  OptimizationOptions,
  OptimizationPartialRequest,
  OptimizationRequest,
  OptimizationStochasticParam,
} from 'app/api/custom_types/optimizations';
import { OptimizationMetricJson } from 'app/generated_types/collimator/dashboard/serialization/ui_types.gen';
import { nodeTypeIsLocalSubdiagram } from 'app/helpers';
import { ModelState } from 'app/modelState/ModelState';
import { SelectInputOption } from 'ui/common/SelectInput';
import { PortLocation } from '../portPathNameUtils';
import {
  AlgoFieldData,
  BATCH_SIZE,
  NUM_BATCHES,
  OptimizationAlgorithmsFields,
} from './OptimizerAlgoList';

export const OptimizerInputGroup = styled.div`
  display: flex;
  margin-bottom: 8px;

  & > * {
    flex: 1;
    flex-shrink: 0;
  }
`;
export const OptimizerInputLabel = styled.div`
  padding-left: 8px;
  display: flex;
  align-items: center;
`;

export const ParamContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 4px;
  border-radius: 2px;
  background: ${({ theme }) => theme.colors.grey[5]};
  margin-bottom: 8px;

  .selectinput__control {
    background: white !important;
  }

  input {
    background: white;
  }
`;

export const ParamSelectRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 4px;

  & > svg {
    opacity: 0.3;

    &:hover {
      opacity: 0.6;
    }
  }
`;

export const ParamInputGroup = styled.div`
  display: flex;
  padding-right: 8px;
  min-width: 0;

  &:last-child {
    padding-right: 0;
  }

  & > * {
    flex: 1;
  }
`;

export interface OptimizationContentProps {
  request: OptimizationPartialRequest;
  nodeOptions: Array<SelectInputOption>;
  signalOptions: Array<SelectInputOption>;
  onUpdate: (request: OptimizationPartialRequest) => void;
  onUpdateValidRequest: (
    request: OptimizationRequest | null,
    invalidReason?: string,
  ) => void;
}

export type StochasticParamsConfig = {
  params: OptimizationStochasticParam[];
  numBatches?: string;
  batchSize?: string;
};

export function filterDesignParameters(
  designParameters: OptimizationDesignParam[],
  availableParams?: string[],
): OptimizationDesignParam[] {
  return designParameters.filter(
    (param) =>
      param.param_name !== undefined &&
      (!availableParams || availableParams.includes(param.param_name)),
  );
}

export function filterStochasticParameters(
  stochasticParameters: OptimizationStochasticParam[],
): OptimizationStochasticParam[] {
  return stochasticParameters.filter((param) => param.param_name !== undefined);
}

export function filterConstraints(
  constraints: Array<string | undefined>,
): Array<string> {
  return constraints.filter(
    (c) => c !== undefined && c !== null,
  ) as Array<string>;
}

export function filterOptions(
  algorithm: OptimizationAlgoName,
  options: OptimizationOptions,
) {
  const allFields: AlgoFieldData[] = [
    ...OptimizationAlgorithmsFields[algorithm].mainFields,
    ...OptimizationAlgorithmsFields[algorithm].settingsFields,
    BATCH_SIZE,
    NUM_BATCHES,
  ];
  const fieldNames = allFields.map((f) => f.name);

  const algDefaultValue = (f: string): string | boolean | undefined =>
    allFields.find((field) => field.name === f)?.default;

  return fieldNames.reduce((acc, key) => {
    const value = options[key] ?? algDefaultValue(key);
    if (value !== undefined && value !== null) acc[key] = value;
    return acc;
  }, {} as OptimizationOptions);
}

// FIXME does not belong here
export interface CSVFileProps {
  header: string[];
  columns: number;
  rows: number;
}

export const scanNodesAndSignals = (
  modelState: ModelState,
  nodeTypes?: Array<BlockClassName>,
) => {
  const submodels = modelState.submodels;

  const nodeOptions: string[] = [];
  const signalOptions: Array<{
    signalPathName: string;
    locationData: PortLocation;
  }> = [];
  const searchingDiagrams: Array<{
    path: Array<string>;
    diagram: ModelDiagram;
  }> = [{ path: [], diagram: modelState.rootModel }];

  while (searchingDiagrams.length) {
    const diagramData = searchingDiagrams.pop();
    if (!diagramData) continue;
    const { diagram: currentDiagram, path } = diagramData;

    for (let i = 0; i < currentDiagram.nodes.length; i++) {
      const currentNode = currentDiagram.nodes[i];

      if (nodeTypeIsLocalSubdiagram(currentNode.type)) {
        const ref = submodels.references[currentNode.uuid];
        if (!ref) continue;

        const diagram = submodels.diagrams[ref.diagram_uuid];
        if (diagram) {
          searchingDiagrams.push({
            path: [...path, currentNode.name],
            diagram,
          });
        }
      }

      if (nodeTypes?.includes(currentNode.type)) {
        nodeOptions.push([...path, currentNode.name].join('.'));
      }

      for (let j = 0; j < currentNode.outputs.length; j++) {
        const output = currentNode.outputs[j];
        signalOptions.push({
          signalPathName: [...path, currentNode.name, output.name].join('.'),
          locationData: {
            parentPath: path,
            nodeId: currentNode.uuid,
            portIndex: j,
          },
        });
      }
    }
  }

  return { nodeOptions, signalOptions };
};

export const readCsvAsMetricsJson = (
  text?: string,
): OptimizationMetricJson[] | undefined => {
  if (!text) return undefined;

  const header: string[] = [];
  const values: Record<string, number[]> = {};

  try {
    const lines = text.split('\n').filter((line) => line.length > 0);
    lines[0]
      .split(',')
      .map((el) => el.trim())
      .forEach((el) => {
        header.push(el);
        values[el] = [];
      });

    for (let i = 1; i < lines.length; i++) {
      const line = lines[i].split(',');
      for (let j = 0; j < line.length; j++) {
        const value = parseFloat(line[j].trim());
        values[header[j]].push(value);
      }
    }
  } catch (e) {
    console.warn('Error reading CSV:', e);
    return undefined;
  }

  return header.map((name) => ({ name, value: values[name] }));
};
