import { RENDERER_EXPERIMENTAL_ENABLE_CANVAS2D } from 'app/config/globalApplicationConfig';
import { renderConstants } from 'app/utils/renderConstants';
import * as NVG from 'nanovg-js';
import { DrawContext } from './drawPrimitives';
import { RendererState } from './modelRenderer';
import { RasterLoadState, getOrRenderCanvasImage } from './rasterTextureStore';

export function prepareBgDotsFramebuffer(
  rendererState: RendererState,
  nvg: NVG.Context,
  gl: WebGLRenderingContext,
  ctx: CanvasRenderingContext2D | null,
  bgDotsFramebuffer: NVG.NVGLUframebuffer | null,
  framebufWidth: number,
  framebufHeight: number,
) {
  // Rendering a pattern with the canvas API is awfully slow. Better
  // disable it for now. It is rendered via NanoVG instead (WebGL).
  let RENDER_BG_DOT_WITH_CANVAS_2D =
    false && RENDERER_EXPERIMENTAL_ENABLE_CANVAS2D;

  const bgOpacity = Math.max(0, Math.min(1, rendererState.zoom - 0.5)) / 2;
  if (
    bgDotsFramebuffer !== null &&
    bgOpacity > 0 &&
    !RENDER_BG_DOT_WITH_CANVAS_2D
  ) {
    const fboWidth: [number] = [0];
    const fboHeight: [number] = [0];
    nvg.imageSize(bgDotsFramebuffer.image, fboWidth, fboHeight);

    NVG.nvgluBindFramebuffer(bgDotsFramebuffer);
    gl.viewport(0, 0, fboWidth[0], fboHeight[0]);
    gl.clearColor(0, 0, 0, 0);
    gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT | gl.STENCIL_BUFFER_BIT);

    nvg.beginFrame(fboWidth[0], fboHeight[0], window.devicePixelRatio);
    nvg.beginPath();
    nvg.circle(
      fboWidth[0] / 2,
      fboHeight[0] / 2,
      fboWidth[0] / renderConstants.GRID_UNIT_PXSIZE,
    );
    nvg.fillColor(NVG.RGBA(200, 200, 200, 255));
    nvg.fill();
    nvg.fill();
    nvg.endFrame();
    NVG.nvgluBindFramebuffer(null);
  } else if (RENDER_BG_DOT_WITH_CANVAS_2D && ctx) {
    const grid = renderConstants.GRID_UNIT_PXSIZE;

    const drawBgDot = (ctx: DrawContext) => {
      ctx.beginPath();
      ctx.circle(64 / 2, 64 / 2, 64 / grid);
      ctx.fillColor(ctx.RGBA(200, 200, 200, 255 * bgOpacity));
      ctx.fill();
    };

    const bgRasterMeta = getOrRenderCanvasImage('bgdot', 64, 64, drawBgDot);
    const bgPixelRatio = 64 / grid;

    let pattern: CanvasPattern | null | undefined;
    if (bgRasterMeta && bgRasterMeta.loadState === RasterLoadState.Loaded) {
      if (bgRasterMeta.canvas2dPattern) {
        pattern = bgRasterMeta.canvas2dPattern;
      } else if (bgRasterMeta.canvas2dImage) {
        pattern = ctx.createPattern(bgRasterMeta.canvas2dImage, 'repeat');
        bgRasterMeta.canvas2dPattern = pattern;
      }
    }

    if (pattern) {
      const zoom = rendererState.zoom;
      const fillStyle = ctx.fillStyle;

      ctx.save();

      const bgTransform = new DOMMatrix();
      bgTransform.translateSelf(
        (rendererState.camera.x + grid / 2) * window.devicePixelRatio * zoom,
        (rendererState.camera.y + grid / 2) * window.devicePixelRatio * zoom,
      );
      bgTransform.scaleSelf(
        (zoom * window.devicePixelRatio) / bgPixelRatio,
        (zoom * window.devicePixelRatio) / bgPixelRatio,
      );
      pattern.setTransform(bgTransform);
      ctx.fillStyle = pattern;

      ctx.fillRect(0, 0, framebufWidth, framebufHeight);
      ctx.fillStyle = fillStyle;

      ctx.restore();
    }
  }
}

export function drawBgDots(
  rendererState: RendererState,
  nvg: NVG.Context,
  ctx: CanvasRenderingContext2D | null,
  bgDotsFramebuffer: NVG.NVGLUframebuffer | null,
  framebufWidth: number,
  framebufHeight: number,
  renderWidth: number,
  renderHeight: number,
) {
  // Rendering a pattern with the canvas API is awfully slow. Better
  // disable it for now. It is rendered via NanoVG instead (WebGL).
  let renderWithCanvas2d = false && RENDERER_EXPERIMENTAL_ENABLE_CANVAS2D;

  const bgOpacity = Math.max(0, Math.min(1, rendererState.zoom - 0.5)) / 2;
  if (bgDotsFramebuffer !== null && bgOpacity > 0 && !renderWithCanvas2d) {
    nvg.fillPaint(
      nvg.imagePattern(
        (renderConstants.GRID_UNIT_PXSIZE / 2 + rendererState.camera.x) *
          rendererState.zoom,
        (renderConstants.GRID_UNIT_PXSIZE / 2 + rendererState.camera.y) *
          rendererState.zoom,
        renderConstants.GRID_UNIT_PXSIZE * rendererState.zoom,
        renderConstants.GRID_UNIT_PXSIZE * rendererState.zoom,
        0,
        bgDotsFramebuffer.image,
        bgOpacity,
      ),
    );
    nvg.fillRect(0, 0, renderWidth, renderHeight);
  } else if (renderWithCanvas2d && ctx) {
    const grid = renderConstants.GRID_UNIT_PXSIZE;

    const drawBgDot = (ctx: DrawContext) => {
      ctx.beginPath();
      ctx.circle(64 / 2, 64 / 2, 64 / grid);
      ctx.fillColor(ctx.RGBA(200, 200, 200, 255 * bgOpacity));
      ctx.fill();
    };

    const bgRasterMeta = getOrRenderCanvasImage('bgdot', 64, 64, drawBgDot);
    const bgPixelRatio = 64 / grid;

    let pattern: CanvasPattern | null | undefined;
    if (bgRasterMeta && bgRasterMeta.loadState === RasterLoadState.Loaded) {
      if (bgRasterMeta.canvas2dPattern) {
        pattern = bgRasterMeta.canvas2dPattern;
      } else if (bgRasterMeta.canvas2dImage) {
        pattern = ctx.createPattern(bgRasterMeta.canvas2dImage, 'repeat');
        bgRasterMeta.canvas2dPattern = pattern;
      }
    }

    if (pattern) {
      const zoom = rendererState.zoom;
      const oldFillStyle = ctx.fillStyle;
      ctx.save();

      const bgTransform = new DOMMatrix();
      bgTransform.translateSelf(
        (rendererState.camera.x + grid / 2) * window.devicePixelRatio * zoom,
        (rendererState.camera.y + grid / 2) * window.devicePixelRatio * zoom,
      );
      bgTransform.scaleSelf(
        (zoom * window.devicePixelRatio) / bgPixelRatio,
        (zoom * window.devicePixelRatio) / bgPixelRatio,
      );
      pattern.setTransform(bgTransform);
      ctx.fillStyle = pattern;
      ctx.fillRect(0, 0, framebufWidth, framebufHeight);

      ctx.restore();
      ctx.fillStyle = oldFillStyle;
    }
  }
}
