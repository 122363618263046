// !!!! -- DO NOT MANUALLY EDIT -- !!!!
// This file was generated by something in scripts/generateDefaultBlockData.js
// If you want to change the contents of this file, it should be automated
//
// (You can edit this file locally, but it may not be checked into the codebase -
// PRs with a manual edit of this file are forbidden from being merged.)

import { computationBlockDefaults } from 'app/generated_blocks/computationBlockDefaults';
import type { ComputationBlockClass } from 'app/generated_types/ComputationBlockClass';
import deepmerge from 'deepmerge';

export const MatrixOperator: ComputationBlockClass = deepmerge(
  computationBlockDefaults,
  {
    "base": {
      "name": "MatrixOperator",
      "namespace": "core",
      "description": "This block outputs the result of a matrix operation based on the inputs.",
      "feature_level": "dev",
      "help_url": "https://docs.collimator.ai/using-model-editors/block-library/agnostic-blocks#matrix-operator"
    },
    "modes": {
      "time": "agnostic"
    },
    "ports": {
      "inputs": {
        "static": [
          {
            "name": "in_0"
          }
        ],
        "conditional": [
          {
            "order": 1,
            "default_enabled": false,
            "name": "in_1"
          }
        ]
      },
      "outputs": {
        "static": [
          {
            "name": "out_0"
          }
        ]
      }
    },
    "parameter_definitions": [
      {
        "name": "function",
        "data_type": "string",
        "default_value": "transpose",
        "description": "Matrix function",
        "allowed_values": [
          "transpose",
          "inverse",
          "multiply",
          "extraction"
        ]
      },
      {
        "name": "concatenation_orientation",
        "data_type": "string",
        "default_value": "horizontal",
        "description": "Orientation for the concatenation function",
        "allowed_values": [
          "horizontal",
          "vertical"
        ]
      },
      {
        "name": "multiplication_type",
        "data_type": "string",
        "default_value": "matrix_matrix",
        "description": "Matrix multiplication type",
        "allowed_values": [
          "matrix_matrix",
          "matrix_vector",
          "vector_vector"
        ]
      },
      {
        "name": "extraction_index",
        "data_type": "int",
        "default_value": "0",
        "description": "The index for element in the to be extracted. The element will be extracted from the last dimension."
      }
    ]
  }
);
