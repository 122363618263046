import { ModelKind } from 'app/apiGenerated/generatedApiTypes';
import React from 'react';
import { ModelItemLoader } from 'ui/modelEditor/ModelItemLoader';
import { SubmodelItemLoader } from 'ui/modelEditor/SubmodelItemLoader';
import { useAppParams } from 'util/useAppParams';

interface Props {
  modelId: string;
  versionId: string;
  kind: ModelKind;
}

export const DataExplorerModelVersionLoader: React.FC<Props> = ({
  modelId,
  versionId,
  kind,
}) => {
  const { projectId } = useAppParams();

  switch (kind) {
    case 'Model':
      return (
        <ModelItemLoader modelId={modelId} versionId={versionId} kind={kind} />
      );
    case 'Submodel':
      if (projectId) {
        return (
          <SubmodelItemLoader
            projectId={projectId}
            submodelReferenceId={modelId}
            submodelVersionId={versionId}
          />
        );
      }
      return null;
    default:
      return null;
  }
};
