/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  className?: string;
}

const RandomdistributionGaussian: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path d="M2.25 6V25.75H30V26.25H1.75V6H2.25Z" fill={props.fill} />
    <path
      d="M3.5 24.75C5.7603 24.75 7.34478 23.4781 8.50758 21.7649C9.65427 20.0755 10.4633 17.8569 11.1814 15.741C11.2871 15.4294 11.3908 15.1203 11.4933 14.8148C12.1027 12.9987 12.6685 11.3125 13.358 10.0064C14.1729 8.46258 15.0033 7.75 16 7.75C16.9967 7.75 17.8271 8.46258 18.642 10.0064C19.3315 11.3125 19.8973 12.9986 20.5066 14.8147C20.6091 15.1201 20.7129 15.4295 20.8186 15.741C21.5367 17.8569 22.3457 20.0755 23.4924 21.7649C24.6552 23.4781 26.2397 24.75 28.5 24.75C28.9142 24.75 29.25 24.4142 29.25 24C29.25 23.5858 28.9142 23.25 28.5 23.25C26.9141 23.25 25.7342 22.3969 24.7335 20.9226C23.7168 19.4245 22.9633 17.3931 22.2391 15.259C22.1379 14.9609 22.0372 14.6603 21.9363 14.3592L21.9359 14.358C21.3311 12.5532 20.7202 10.73 19.9685 9.30612C19.1008 7.66242 17.8879 6.25 16 6.25C14.1121 6.25 12.8992 7.66242 12.0315 9.30612C11.2798 10.73 10.6689 12.5532 10.0641 14.358C9.96318 14.6592 9.86216 14.9607 9.76094 15.259C9.03668 17.3931 8.28323 19.4245 7.26646 20.9226C6.2658 22.3969 5.08585 23.25 3.5 23.25C3.08579 23.25 2.75 23.5858 2.75 24C2.75 24.4142 3.08579 24.75 3.5 24.75Z"
      fill={props.fill}
    />
  </svg>
);

RandomdistributionGaussian.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  className: '',
};

export default RandomdistributionGaussian;
