import { useAppDispatch } from 'app/hooks';
import { dataExplorerActions } from 'app/slices/dataExplorerSlice';
import React from 'react';

interface Props {
  explorationId: string;
}

/**
 * Loader that automatically loads the exploration for the exploration id in the URL
 * when we are in on a data explorer route (not used for the visualizer).
 * When this loader completes, it updates the data in the data explorer redux store.
 * At this point, other loaders will notice that the exploration has been loaded and
 * will begin to load the relevant model versions and cell data (including plot data).
 */
export const DataExplorerExplorationLoader: React.FC<Props> = ({
  explorationId,
}) => {
  const dispatch = useAppDispatch();

  // TODO load exploration from the backend.
  // For now, just create an empty local one for testing.
  React.useEffect(() => {
    dispatch(
      dataExplorerActions.onDataExplorationLoaded({
        explorationId,
        cellRowIds: [],
        idToCellRow: {},
        idToCell: {},
        idToPlotCell: {},
        idToTextCell: {},
        idToImageCell: {},
        idToTrace: {},
        traceIdToMarkedPoints: {},
      }),
    );
  }, [dispatch, explorationId]);

  return null;
};
