import { migrateModelDataNoneToV0 } from './modelMigrations_v0';
import { migrateModelDataV0toV1 } from './modelMigrations_v1';
import { migrateModelDataV1toV2 } from './modelMigrations_v2';
import { migrateModelDataV2toV3 } from './modelMigrations_v3';
import { migrateModelDataV3toV4 } from './modelMigrations_v4';
import {
  LatestVersion,
  SupportedVersion,
  SupportedVersionsList,
  VersionedModel,
} from './types';

type MigrationFunction = (data: any) => VersionedModel;

const migrations: Record<string, MigrationFunction> = {
  null: migrateModelDataNoneToV0,
  undefined: migrateModelDataNoneToV0,
  0: migrateModelDataV0toV1,
  1: migrateModelDataV1toV2,
  2: migrateModelDataV2toV3,
  3: migrateModelDataV3toV4,
};

function isValidVersion(version: string): boolean {
  // TODO: when we adopt a proper versioning scheme, implement this properly
  // This function is here so that we can ignore versions like 1.0-alpha0
  // and whatnot that may be found in some jsons.
  return SupportedVersionsList.indexOf(version as SupportedVersion) !== -1;
}

export function runAllModelMigrations(
  obj: any,
  requestedVersion: SupportedVersion = LatestVersion,
): VersionedModel {
  let version = `${obj.schema_version}`;

  if (version === requestedVersion) {
    return obj;
  }

  if (!isValidVersion(version)) {
    console.warn(`Model migration: unknown version ${version}`);
    version = 'undefined';
  }

  if (parseInt(version) > parseInt(requestedVersion)) {
    console.warn(
      `Model migration: requested version ${requestedVersion} is older ` +
        `than current version ${version}`,
    );
  }

  try {
    let model = obj;
    while (version in migrations && version !== requestedVersion) {
      console.info(`Model migration: migrating from version ${version}`);
      model = migrations[version](model);
      version = `${model.schema_version}`;
    }
    console.info(`Model migration: reached version ${version} (${model.kind})`);
    return model;
  } catch (e) {
    console.error(
      `Model migration: failed, reached version ${version}`,
      e,
      obj,
    );
    throw e;
  }
}
