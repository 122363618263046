import hotkeys from 'hotkeys-js';

import { onBrowserFocusChange } from 'util/onBrowserFocusChange';
import { rendererState, requestFrameRender } from './modelRenderer';
import { SingleShortcutConfig, shortcutsConfig } from './shortcutKeyConfig';

const triggerShortcut = (configItem: SingleShortcutConfig) => {
  requestFrameRender();

  if (rendererState !== null) {
    const uiFlags = rendererState.refs.current.uiFlags;
    const shouldIgnore =
      configItem.ignoreIfTextFocused &&
      (uiFlags.textInputFocused || uiFlags.htmlTextSelected);
    if (!shouldIgnore) configItem.handler(rendererState);
  }
};

const specialKeyCallbacks: ((e: KeyboardEvent) => void)[] = [];
export const specialKeyMainCallback = (e: KeyboardEvent) => {
  requestFrameRender();

  for (let j = 0; j < specialKeyCallbacks.length; j++) {
    specialKeyCallbacks[j](e);
  }
};

// necessary because for some of our alt keys we get the wrong "key"
// which is the recommended layout-independent (!) value to use
const macosAltKeyMap: { [k: string]: string } = {
  '∂': 'd',
  '¬': 'l',
  '®': 'r',
};

export const registerHotkeyEvents = () => {
  for (let i = 0; i < shortcutsConfig.length; i++) {
    const configItem = shortcutsConfig[i];

    if (configItem.variant === 'special') {
      /* eslint-disable */
      specialKeyCallbacks.push((e: KeyboardEvent) => {
        if (rendererState == null) return;

        const uiFlags = rendererState.refs.current.uiFlags;
        const shouldIgnore =
          configItem.ignoreIfTextFocused &&
          (uiFlags.textInputFocused || uiFlags.htmlTextSelected);

        if (shouldIgnore) return;

        const modKeys = configItem.specialConfig.modKeys;
        let modKeysPressed = true;
        for (let j = 0; j < modKeys.length; j++) {
          switch (modKeys[j]) {
            case 'CtrlCmd':
              modKeysPressed = e.ctrlKey || e.metaKey;
              break;
            case 'AltOption':
              modKeysPressed = e.altKey;
              break;
            case 'Shift':
              modKeysPressed = e.shiftKey;
              break;
          }
        }

        const configKey = configItem.specialConfig.key;
        const pressingMainKey =
          configKey == e.key || configKey == macosAltKeyMap[e.key];

        if (modKeysPressed && pressingMainKey) {
          if (configItem.preventDefault) {
            e.preventDefault();
          }
          triggerShortcut(configItem);
        }
      });
      /* eslint-enable */
    } else {
      /* eslint-disable */
      hotkeys(configItem.hotkeyString, (e) => {
        if (rendererState == null) return;

        const uiFlags = rendererState.refs.current.uiFlags;
        const shouldIgnore =
          configItem.ignoreIfTextFocused &&
          (uiFlags.textInputFocused || uiFlags.htmlTextSelected);

        if (shouldIgnore) return;

        if (configItem.preventDefault) {
          e.preventDefault();
        }
        triggerShortcut(configItem);
      });
      /* eslint-enable */
    }
  }

  document.addEventListener('keydown', specialKeyMainCallback);
};

// FIXME: This may not be properly kept up-to-date. Browser key, mouse/pointer
// events contain the proper info about which modifier keys are pressed.
// Non-modifier keys will trigger their own keydown/keyup events, and if we
// really need to keep track of them, we should merge this into rendererState.
export const keysPressed: { [k: string]: boolean } = {};

const onBlurOrFocus = () => {
  requestFrameRender();

  const keyKeys = Object.keys(keysPressed);
  for (let i = 0; i < keyKeys.length; i++) {
    keysPressed[keyKeys[i]] = false;
  }
};

// FIXME: this call happens outside a function, should be moved under a global
// renderer init.
onBrowserFocusChange(onBlurOrFocus, onBlurOrFocus);

export function keyDown(event: KeyboardEvent): void {
  requestFrameRender();

  let keyName = event.code;
  if (keyName.indexOf('Arrow') === -1) {
    keyName = keyName.replace('Left', '').replace('Right', '');
  }
  keysPressed[keyName] = true;
}

export function keyUp(event: KeyboardEvent): void {
  requestFrameRender();

  // the alt key seems to be problematic in getting stuck,
  // so we'll take an aggressive approach on it for now.
  if (!event.altKey) {
    keysPressed.Alt = false;
  }

  let keyName = event.code;
  if (keyName.indexOf('Arrow') === -1) {
    keyName = keyName.replace('Left', '').replace('Right', '');
  }
  keysPressed[keyName] = false;

  // on macOS, it is impossible to get keyUp events for keys while "cmd" is pressed.
  // this is well-documented, and there is no way to get around it.
  // so, we broadly emulate keyups that happened during the "cmd" key being held
  // by just clearing every pressed key. this should have minimal side effects
  // because there is no real UX case for keeping other keys held
  // after cmd is released.
  if (keyName === 'Meta') {
    const pressedKeyNames = Object.keys(keysPressed);
    for (let i = 0; i < pressedKeyNames.length; i++) {
      keysPressed[pressedKeyNames[i]] = false;
    }
  }
}
