import { generatedApi } from 'app/apiGenerated/generatedApi';
import { ModelWithSubmodels } from 'app/apiGenerated/generatedApiTypes';
import React from 'react';
import { PythonContext, usePython } from 'ui/common/PythonProvider';
import { useAppParams } from 'util/useAppParams';
import { EditorMode, useModelEditorInfo } from './useModelEditorInfo';
import { withModelBuilder } from './usePythonToJsonConverter';

export interface PythonModel {
  pythonStr: string;
  ids_to_uuids: unknown;
  ids_to_uiprops: unknown;
  stdout?: string;
  stderr?: string;
}

export interface PythonExecuteResult {
  results?: Map<string, unknown>;
  stdout?: string;
  stderr?: string;
  error?: string;
}

export type PythonRenderConfig = {
  output_groups: boolean;
  output_submodels: boolean;

  // if this is set, the function will only render the group
  group_block_name?: string;
};

export const indent = (code: string, spaces: number) => {
  const lines = code.split('\n');
  const indentedLines = lines.map((line) => `${' '.repeat(spaces)}${line}`);
  return indentedLines.join('\n');
};

const getCodeLine = (code: string, lineno: number) => {
  const lines = code.split('\n');
  return lines[lineno - 1].trim();
};

const debugLog = (code: string, stdout: string, stderr: string) => {
  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.debug(
      `runPythonCheckError code:\n${code}\nstdout:\n${stdout}\nstderr:\n${stderr}`,
    );
  }
};
/**
 * Run python code.
 *
 * Returned `results` contains the variable specified in `returnVariables`.
 *
 */
const runPythonCheckError = async (
  python: PythonContext,
  code: string,
  inputVariables: Map<string, unknown> | undefined,
  returnVariableNames?: string[],
): Promise<{
  results?: Map<string, unknown>;
  stdout?: string;
  stderr?: string;
  error?: string;
}> => {
  if (!python.isReady) return { error: 'python is not ready' };

  let results: Map<string, unknown>;
  let stdout = '';
  let stderr = '';

  await python.readStdout();
  await python.readStderr();
  try {
    results = (await python.pyodide?.runPythonAsync(
      code,
      inputVariables,
      returnVariableNames,
    )) as Map<string, unknown>;
  } catch (error: any) {
    stdout = await python.readStdout();
    stderr = await python.readStderr();
    debugLog(code, stdout, stderr);

    if (error instanceof Map) {
      let codeLine;
      if (error.get('lineno')) {
        codeLine = getCodeLine(code, error.get('lineno'));
        codeLine = `At line: ${codeLine}`;
      }
      const tb = error.get('tb');
      const errorMsg = error.get('msg');
      return {
        stdout,
        stderr,
        error: `Traceback:\n${tb}\n${errorMsg}\n${codeLine || ''}`,
      };
    }
    return { stdout, stderr, error: `${error}` };
  }

  stdout = await python.readStdout();
  stderr = await python.readStderr();

  debugLog(code, stdout, stderr);

  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.debug('Output from Python:', results, 'stdout:\n', stdout);
  }

  if (returnVariableNames) {
    const filteredResults = new Map<string, unknown>();
    let error = '';
    for (const returnVariableName of returnVariableNames) {
      if (!results.has(returnVariableName)) {
        error = `${error}Error: You must set "${returnVariableName}"\n`;
        continue;
      }
      const result = results.get(returnVariableName);
      filteredResults.set(returnVariableName, result);
    }
    return { results: filteredResults, stdout, stderr };
  }

  return { stdout, stderr };
};

export interface ExecutePythonArgs {
  code: string;
  inputs?: { [key: string]: unknown };
  returnVariableNames?: string[];
}

export const usePythonExecutor = () => {
  const python = usePython();
  const executePythonCallback = React.useCallback(
    async ({
      code,
      inputs,
      returnVariableNames,
    }: ExecutePythonArgs): Promise<PythonExecuteResult> => {
      if (!python.isReady) return { error: 'Python is not ready' };

      python.executionId.current += 1;

      const inputVariables = new Map(Object.entries(inputs || {}));
      const { results, stdout, stderr, error } = await runPythonCheckError(
        python,
        code,
        inputVariables,
        returnVariableNames,
      );
      if (error) {
        return { stdout, stderr, error };
      }
      if (returnVariableNames && results) {
        let error = '';
        const runResults = results as Map<string, unknown>;
        const filteredResults = new Map<string, unknown>();

        for (const returnVariableName of returnVariableNames) {
          if (!runResults.has(returnVariableName)) {
            error = `${error}Error: You must set "${returnVariableName}"\n`;
            continue;
          }
          const result = runResults.get(returnVariableName);
          filteredResults.set(returnVariableName, result);
        }
        return { results: filteredResults, stdout, stderr, error };
      }
      return { stdout, stderr };
    },
    [python],
  );

  return executePythonCallback;
};

export const usePythonModelCallback = () => {
  const { projectId } = useAppParams();
  const { editorMode, modelInEditorUuid, groupBlockUuid } =
    useModelEditorInfo();
  const [getModelWithSubmodelsReadByUuidTrigger] =
    generatedApi.endpoints.getModelWithSubmodelsReadByUuid.useLazyQuery();
  const [getSubmodelWithSubmodelsTrigger] =
    generatedApi.endpoints.getSubmodelWithSubmodels.useLazyQuery();
  const executePython = usePythonExecutor();

  const convertToPyCode = `
import json
from model_builder import from_json
from model_builder.to_python import to_python_str

json_data = json.loads(inputs["json_model"])
root_model_builder, ids_to_uuids, ids_to_uiprops = from_json.parse_json(json_data)
model_builder = root_model_builder
if inputs["group_uuid"]:
    for group_block, group_model_builder in root_model_builder.groups.items():
        if ids_to_uuids[group_block.id] == inputs["group_uuid"]:
            model_builder = group_model_builder
            break

if 'group_block_name' in inputs and inputs["group_block_name"]:
    group_block = model_builder.nodes[inputs["group_block_name"]]
    model_builder = model_builder.groups[group_block]

python_str = to_python_str(
    model_builder,
    builder_name='model_builder',
    output_submodels=inputs["output_submodels"],
    output_groups=inputs["output_groups"],
    omit_model_builder=True,
)
print("\\n----------\\n")
model_builder.print_warnings()
print(f"Signal outputs: {model_builder.outport_names()}")
`;

  const convertModelToPython = React.useCallback(
    async (
      model: ModelWithSubmodels,
      pyRenderConfig: PythonRenderConfig,
    ): Promise<PythonModel> => {
      const { results, stdout, stderr, error } = await executePython({
        code: `${convertToPyCode}`,
        inputs: {
          json_model: JSON.stringify(model),
          group_uuid: groupBlockUuid,
          ...pyRenderConfig,
        },
        returnVariableNames: ['ids_to_uuids', 'ids_to_uiprops', 'python_str'],
      });

      if (error) {
        throw Error(error);
      }

      const resultsMap = results as Map<string, unknown>;
      return {
        stdout,
        stderr,
        pythonStr: resultsMap.get('python_str') as string,
        ids_to_uuids: resultsMap.get('ids_to_uuids'),
        ids_to_uiprops: resultsMap.get('ids_to_uiprops'),
      };
    },
    [executePython, convertToPyCode, groupBlockUuid],
  );

  const getCurrentModelInPython = React.useCallback(
    async (pyRenderConfig: PythonRenderConfig): Promise<PythonModel> => {
      let simulationModel;
      try {
        if (editorMode === EditorMode.Submodel) {
          if (!projectId) throw Error('projectId is not defined.');
          simulationModel = await getSubmodelWithSubmodelsTrigger({
            submodelUuid: modelInEditorUuid,
            projectUuid: projectId,
          }).unwrap();
        } else {
          simulationModel = await getModelWithSubmodelsReadByUuidTrigger({
            modelUuid: modelInEditorUuid,
          }).unwrap();
        }
      } catch (e) {
        throw Error('Failed to load the model.');
      }

      const results = await convertModelToPython(
        simulationModel,
        pyRenderConfig,
      );

      return results;
    },
    [
      convertModelToPython,
      editorMode,
      projectId,
      getSubmodelWithSubmodelsTrigger,
      modelInEditorUuid,
      getModelWithSubmodelsReadByUuidTrigger,
    ],
  );

  const validateCurrentPythonModel =
    React.useCallback(async (): Promise<string> => {
      let pythonModel;
      try {
        pythonModel = await getCurrentModelInPython({
          output_groups: true,
          output_submodels: true,
        });
      } catch (e: unknown) {
        return (e as Error).message;
      }
      const { error, stderr } = await executePython({
        code: withModelBuilder(
          'model_builder.print_warnings()',
          pythonModel.pythonStr,
        ),
      });
      if (error || stderr) {
        return `${stderr}\n${error || ''}`;
      }
      return '';
    }, [executePython, getCurrentModelInPython]);

  return {
    getCurrentModelInPython,
    validateCurrentPythonModel,
  };
};
