/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Accelerometer: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.5 2.29289L16.8536 4.64645C17.0488 4.84171 17.0488 5.15829 16.8536 5.35355C16.6583 5.54881 16.3417 5.54881 16.1464 5.35355L15 4.20711V16C15 16.2761 14.7761 16.5 14.5 16.5C14.2239 16.5 14 16.2761 14 16V4.20711L12.8536 5.35355C12.6583 5.54881 12.3417 5.54881 12.1464 5.35355C11.9512 5.15829 11.9512 4.84171 12.1464 4.64645L14.5 2.29289Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M24.132 6.41421V9.74264C24.132 10.0188 23.9081 10.2426 23.632 10.2426C23.3558 10.2426 23.132 10.0188 23.132 9.74264V8.11281L19.6279 11.582L23.4423 15.3964C23.6299 15.584 23.7352 15.8383 23.7352 16.1035V18.9112C23.7352 19.1764 23.6299 19.4307 23.4423 19.6183L21.3408 21.7198L20.2801 20.6592L22.2352 18.704V17.8106L15.2499 24.7959V25.6893L18.1588 22.7805L19.2194 23.8411L15.2072 27.8534C14.8164 28.2442 14.1828 28.2439 13.7924 27.8528L5.55693 19.6031C5.36977 19.4156 5.26465 19.1615 5.26465 18.8966V16.1035C5.26465 15.8383 5.37001 15.584 5.55754 15.3964L12.9999 7.95411V10.0754L7.07531 16L14.4999 23.4246L21.9246 16L15.9999 10.0753V7.95396L18.9208 10.8749L22.4163 7.41421H20.8036C20.5274 7.41421 20.3036 7.19036 20.3036 6.91421C20.3036 6.63807 20.5274 6.41421 20.8036 6.41421H24.132ZM13.7499 24.7959L6.76465 17.8106V18.6897L13.7499 25.6871V24.7959Z"
      fill={props.fill}
    />
    <path
      d="M24 26.5012L24.0009 23.0003C24.001 22.7241 23.7772 22.5002 23.501 22.5001C23.2249 22.5001 23.001 22.7239 23.0009 23L23.0005 24.7941L19.8536 21.6465C19.6584 21.4512 19.3418 21.4512 19.1465 21.6464C18.9512 21.8416 18.9512 22.1582 19.1464 22.3535L22.2928 25.5006L20.5002 25.5C20.224 25.4999 20.0001 25.7237 20 25.9998C19.9999 26.276 20.2237 26.4999 20.4998 26.5L24 26.5012Z"
      fill={props.fill}
    />
    <path
      d="M25.7119 26.476C25.7226 26.492 25.7439 26.5 25.7759 26.5H26.4639C26.5066 26.5 26.5386 26.484 26.5599 26.452L27.4959 25.084C27.5012 25.0733 27.5092 25.068 27.5199 25.068C27.5306 25.068 27.5386 25.0733 27.5439 25.084L28.4719 26.452C28.4932 26.484 28.5252 26.5 28.5679 26.5H29.2239C29.2719 26.5 29.2959 26.4813 29.2959 26.444L29.2799 26.404L27.9519 24.492C27.9466 24.476 27.9466 24.46 27.9519 24.444L29.2799 22.524C29.2959 22.4973 29.2986 22.476 29.2879 22.46C29.2772 22.4387 29.2559 22.428 29.2239 22.428H28.5279C28.4852 22.428 28.4532 22.444 28.4319 22.476L27.5039 23.844C27.4986 23.8493 27.4906 23.852 27.4799 23.852C27.4692 23.852 27.4612 23.8493 27.4559 23.844L26.5199 22.476C26.4986 22.444 26.4666 22.428 26.4239 22.428H25.7759C25.7279 22.428 25.7039 22.4467 25.7039 22.484L25.7199 22.524L27.0479 24.444C27.0586 24.46 27.0586 24.476 27.0479 24.492L25.7199 26.404C25.7039 26.4307 25.7012 26.4547 25.7119 26.476Z"
      fill={props.fill}
    />
    <path
      d="M26.0377 11.592C26.0003 11.592 25.9817 11.5653 25.9817 11.512V11.04C25.9817 10.9867 26.0083 10.96 26.0617 10.96H26.0777C26.291 10.9547 26.459 10.928 26.5817 10.88C26.7043 10.832 26.8057 10.7413 26.8857 10.608C26.9657 10.48 27.043 10.2853 27.1177 10.024C27.123 10.0187 27.123 10.0107 27.1177 10V9.976L25.7817 6.016L25.7737 5.984C25.7737 5.94667 25.7977 5.928 25.8457 5.928H26.4937C26.5417 5.928 26.5737 5.94933 26.5897 5.992L27.4937 9.008C27.499 9.01867 27.507 9.024 27.5177 9.024C27.5283 9.024 27.5363 9.01867 27.5417 9.008L28.4377 5.992C28.4537 5.94933 28.4857 5.928 28.5337 5.928H29.1657C29.2243 5.928 29.2457 5.95733 29.2297 6.016L27.7737 10.28C27.651 10.648 27.5283 10.92 27.4057 11.096C27.283 11.2773 27.123 11.4053 26.9257 11.48C26.7283 11.5547 26.451 11.592 26.0937 11.592H26.0377Z"
      fill={props.fill}
    />
    <path
      d="M6.93531 9.92C6.93531 9.97333 6.96198 10 7.01531 10H9.95131C10.0046 10 10.0313 9.97333 10.0313 9.92V9.384C10.0313 9.33067 10.0046 9.304 9.95131 9.304H7.85531C7.84465 9.304 7.83665 9.30133 7.83131 9.296C7.82598 9.28533 7.82865 9.27467 7.83931 9.264L9.99131 6.672C10.0126 6.64533 10.0233 6.61333 10.0233 6.576V6.008C10.0233 5.95467 9.99665 5.928 9.94331 5.928H7.05531C7.00198 5.928 6.97531 5.95467 6.97531 6.008V6.552C6.97531 6.60533 7.00198 6.632 7.05531 6.632H9.07131C9.08198 6.632 9.08998 6.63733 9.09531 6.648C9.10065 6.65333 9.09798 6.66133 9.08731 6.672L6.96731 9.264C6.94598 9.29067 6.93531 9.32267 6.93531 9.36V9.92Z"
      fill={props.fill}
    />
  </svg>
);

Accelerometer.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Accelerometer;
