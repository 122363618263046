import styled from '@emotion/styled';
import React from 'react';
import {
  TreeArrowCollapsed,
  TreeArrowExpanded,
} from 'ui/common/Icons/Standard';

const DetailsLabel = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  & > svg {
    opacity: 0.3;
  }

  &:hover > svg {
    opacity: 0.6;
  }
`;

export const ExpandableDetailsContainer = ({
  label,
  defaultExpanded,
  children,
}: {
  label: string;
  defaultExpanded?: boolean;
  children: React.ReactNode | (() => React.ReactNode);
}) => {
  const [isOpen, setIsOpen] = React.useState(!!defaultExpanded);
  const toggleOpen = () => setIsOpen((open) => !open);

  return (
    <>
      <DetailsLabel onClick={toggleOpen}>
        {isOpen ? <TreeArrowExpanded /> : <TreeArrowCollapsed />}
        {label}
      </DetailsLabel>
      {isOpen && (typeof children === 'function' ? children() : children)}
    </>
  );
};
