/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  className?: string;
}

const Rates: React.FC<Props> = (props) => (
  <svg viewBox="0 0 24 24" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.09202 7.96045C6.80332 5.76021 7.91813 5.45315 8.658 5.60891C9.07211 5.69609 9.50276 5.94721 9.97623 6.37121C10.4495 6.79501 10.9163 7.34597 11.4144 7.96852C11.9163 8.59597 12.4495 9.23251 13.0231 9.74621C13.5965 10.2597 14.2596 10.6961 15.033 10.8589C16.6681 11.2031 18.3033 10.2602 20.092 7.96045C20.3463 7.63349 20.2874 7.16229 19.9605 6.90798C19.6335 6.65368 19.1623 6.71258 18.908 7.03954C17.1967 9.23979 16.0819 9.54685 15.342 9.39109C14.9279 9.3039 14.4972 9.05278 14.0238 8.62879C13.5505 8.20499 13.0837 7.65403 12.5857 7.03148C12.0837 6.40403 11.5505 5.76749 10.9769 5.25379C10.4035 4.74028 9.7404 4.3039 8.96701 4.14109C7.33188 3.79685 5.69669 4.73979 3.90799 7.03954C3.65369 7.3665 3.71259 7.83771 4.03955 8.09201C4.36651 8.34631 4.83772 8.28741 5.09202 7.96045Z"
      fill={props.fill}
    />
    <path
      d="M8.25 18V14.25H9.75V18C9.75 18.6904 10.3096 19.25 11 19.25H13C13.6904 19.25 14.25 18.6904 14.25 18V14.25H15.75V18C15.75 18.6904 16.3096 19.25 17 19.25H19.5C19.9142 19.25 20.25 18.9142 20.25 18.5C20.25 18.0858 19.9142 17.75 19.5 17.75H17.25V14C17.25 13.3096 16.6904 12.75 16 12.75H14C13.3096 12.75 12.75 13.3096 12.75 14V17.75H11.25V14C11.25 13.3096 10.6904 12.75 10 12.75H8C7.30964 12.75 6.75 13.3096 6.75 14V17.75H4.5C4.08579 17.75 3.75 18.0858 3.75 18.5C3.75 18.9142 4.08579 19.25 4.5 19.25H7C7.69036 19.25 8.25 18.6904 8.25 18Z"
      fill={props.fill}
    />
  </svg>
);

Rates.defaultProps = {
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  className: '',
};

export default Rates;
