export enum PyodideWorkerMessageType {
  // Request message types
  INIT = 'INIT',
  RUN_PYTHON = 'RUN_PYTHON',
  RUN_PYTHON_RAW = 'RUN_PYTHON_RAW',
  GLOBALS_SET = 'GLOBALS_SET',
  READ_STDOUT = 'READ_STDOUT',
  READ_STDERR = 'READ_STDERR',

  // Callback message types
  NEW_STDOUT = 'NEW_STDOUT',
  NEW_STDERR = 'NEW_STDERR',
}

export type PyodideWorkerMessage =
  | {
      type: PyodideWorkerMessageType.INIT;
      wheels: Array<string>;
    }
  | {
      type: PyodideWorkerMessageType.RUN_PYTHON;
      code: string;
      inputVariables?: { [key: string]: any };
      returnVariables?: Array<string>;
    }
  | {
      type: PyodideWorkerMessageType.RUN_PYTHON_RAW;
      code: string;
    }
  | {
      type: PyodideWorkerMessageType.GLOBALS_SET;
      key: string;
      value: any;
    }
  | {
      type: PyodideWorkerMessageType.READ_STDOUT;
    }
  | {
      type: PyodideWorkerMessageType.READ_STDERR;
    };

export type PyodideWorkerResponseRunRaw = {
  type: PyodideWorkerMessageType.RUN_PYTHON_RAW;
  resultStr: string | null;
  id: number;
};

export type PyodideWorkerResponseReadStdout = {
  type: PyodideWorkerMessageType.READ_STDOUT;
  stdout: string;
  id: number;
};

export type PyodideWorkerResponseReadStderr = {
  type: PyodideWorkerMessageType.READ_STDERR;
  stderr: string;
  id: number;
};

export type PyodideWorkerResponseNewOutput = {
  type:
    | PyodideWorkerMessageType.NEW_STDOUT
    | PyodideWorkerMessageType.NEW_STDERR;
  msg: string;
};
