/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  className?: string;
}

const FmuFile: React.FC<Props> = (props) => (
  <svg viewBox="0 0 24 24" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.7072 5.6H6.56097L6.56097 18.4H18.2682C18.2682 19.2837 17.5693 20 16.7072 20H6.56097C5.69887 20 5 19.2837 5 18.4V5.6C5 4.71634 5.69887 4 6.56097 4H16.7072C17.5693 4 18.2682 4.71634 18.2682 5.6V8.4C17.4877 8 16.7072 8 16.7072 8V5.6Z"
      fill={props.fill}
    />
    <path
      d="M9.22877 15.2468V10.7311H7.72369L7.75529 9.15478H9.22877V9.04503C9.22877 8.35224 9.49859 7.75252 10.0095 7.28226L10.0251 7.26793L10.042 7.25539C10.2303 7.11632 10.4406 7.00257 10.6606 6.92243L10.681 6.91498C10.9351 6.83839 11.2201 6.80182 11.5203 6.8L11.9128 6.79761V8.37325L11.5261 8.37683C11.0974 8.38081 10.9496 8.52289 10.8876 8.67948C10.8291 8.82954 10.8068 8.9514 10.8068 9.04503V9.15478H13.0019L12.7947 9.34149C13.097 9.21531 13.441 9.15474 13.8128 9.15474C14.3554 9.15474 14.8437 9.33556 15.259 9.68105C15.678 9.32616 16.1797 9.14324 16.7419 9.14324C17.2524 9.14324 17.6626 9.26337 17.9864 9.46518C18.3094 9.66647 18.5246 9.93547 18.6662 10.2C18.943 10.717 18.9493 11.2355 18.9493 11.2951V14.4152H17.3711V11.3793C17.3711 11.2211 17.3314 11.1061 17.2661 11.0157C17.2019 10.9267 17.0953 10.8373 16.9218 10.7613C16.8083 10.7368 16.7455 10.7308 16.7096 10.7308C16.4498 10.7308 16.2499 10.8424 16.0913 11.1309C16.0633 11.2096 16.0441 11.3186 16.0441 11.471L16.0507 14.4152H14.4726L14.4656 11.3277C14.4656 11.1562 14.4179 11.0079 14.3352 10.9098C14.2614 10.8223 14.1242 10.7308 13.8473 10.7308C13.5281 10.7308 13.3712 10.8564 13.2832 10.9761C13.1796 11.117 13.1512 11.2809 13.1512 11.3352V14.4152H11.5725V10.7311H10.8068V15.2543L9.99329 15.8905L9.22877 15.2468Z"
      fill={props.fill}
    />
    <path
      d="M19.3704 10.2955V8.49193H20.9589V10.2955H19.3704Z"
      fill={props.fill}
    />
    <path
      d="M7.74289 15.7633L9.56422 15.7961L10.0038 16.1281L10.452 15.7941L21 15.7698V17.2H7.74289V15.7633Z"
      fill={props.fill}
    />
    <path
      d="M20.9565 14.4155H19.368V10.9947H20.9565V14.4155Z"
      fill={props.fill}
    />
  </svg>
);

FmuFile.defaultProps = {
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  className: '',
};

export default FmuFile;
