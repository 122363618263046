/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Random: React.FC<Props> = (props) => (
  <svg viewBox="0 0 34 34" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M10.6667 4C10.6667 3.58579 11.0025 3.25 11.4167 3.25H13.55C13.9642 3.25 14.3 3.58579 14.3 4V9.75H14.9333V9.23881C14.9333 8.8246 15.2691 8.48881 15.6833 8.48881H17.8167C18.2309 8.48881 18.5667 8.8246 18.5667 9.23881V20.9677H19.2V13.5C19.2 13.0858 19.5358 12.75 19.95 12.75H22.0833C22.4975 12.75 22.8333 13.0858 22.8333 13.5V27.25H23.4667V16.5659C23.4667 16.1517 23.8025 15.8159 24.2167 15.8159H25.6V15.0692C25.6 14.655 25.9358 14.3192 26.35 14.3192H28.4833C28.8975 14.3192 29.2333 14.655 29.2333 15.0692V18.6834H30.6167C31.0309 18.6834 31.3667 19.0192 31.3667 19.4334V21.5934H32.75C33.1642 21.5934 33.5 21.9292 33.5 22.3434C33.5 22.7576 33.1642 23.0934 32.75 23.0934H30.6167C30.2025 23.0934 29.8667 22.7576 29.8667 22.3434V20.1834H28.4833C28.0691 20.1834 27.7333 19.8476 27.7333 19.4334V15.8192H27.1V16.5659C27.1 16.9801 26.7642 17.3159 26.35 17.3159H24.9667V28C24.9667 28.4142 24.6309 28.75 24.2167 28.75H22.0833C21.6691 28.75 21.3333 28.4142 21.3333 28V14.25H20.7V21.7177C20.7 22.1319 20.3642 22.4677 19.95 22.4677H17.8167C17.4025 22.4677 17.0667 22.1319 17.0667 21.7177V9.98881H16.4333V10.5C16.4333 10.9142 16.0975 11.25 15.6833 11.25H13.55C13.1358 11.25 12.8 10.9142 12.8 10.5V4.75H12.1667V15.4225C12.1667 15.8367 11.8309 16.1725 11.4167 16.1725H9.28333C8.86912 16.1725 8.53333 15.8367 8.53333 15.4225V14.843H7.9V20.3246C7.9 20.7388 7.56421 21.0746 7.15 21.0746H5.76667V22.8627C5.76667 23.2769 5.43088 23.6127 5.01667 23.6127H2.88333C2.46912 23.6127 2.13333 23.2769 2.13333 22.8627V19.6797H1.5V21.7177C1.5 22.1319 1.16421 22.4677 0.75 22.4677C0.335786 22.4677 0 22.1319 0 21.7177V18.9297C0 18.5155 0.335786 18.1797 0.75 18.1797H2.88333C3.29755 18.1797 3.63333 18.5155 3.63333 18.9297V22.1127H4.26667V20.3246C4.26667 19.9104 4.60245 19.5746 5.01667 19.5746H6.4V14.093C6.4 13.6788 6.73579 13.343 7.15 13.343H9.28333C9.69755 13.343 10.0333 13.6788 10.0333 14.093V14.6725H10.6667V4Z"
      fill={props.fill}
    />
  </svg>
);

Random.defaultProps = {
  width: '34',
  height: '32',
  viewBox: '0 0 34 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Random;
