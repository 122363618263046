import { clearModelState } from 'app/api/useModelData';
import { useModelVersionData } from 'app/api/useModelVersionData';
import { ModelKind } from 'app/apiGenerated/generatedApiTypes';
import { useProjectOrRedirect } from 'app/behaviorHooks/useProjectOrRedirect';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
  modelMetadataActions,
  ModelRestoreState,
} from 'app/slices/modelMetadataSlice';
import { modelActions } from 'app/slices/modelSlice';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ActionCreators as UndoRedoActionCreators } from 'redux-undo';
import { useModelPermission } from 'ui/permission/useModelPermission';

interface Props {
  projectId: string;
  modelId: string;
  versionId: string;
}

const ModelVersion: React.FC<Props> = ({ projectId, modelId, versionId }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const submodels = useAppSelector(
    (state) => state.submodels.projectIdToSubmodelInfoLites[projectId],
  );

  const { project } = useProjectOrRedirect();

  const { arePermissionsLoaded } = useModelPermission(
    projectId,
    modelId,
    versionId,
  );

  const { modelRestoreState } = useAppSelector((state) => state.modelMetadata);

  // Determine whether the current model is a submodel or model.
  const topLevelSubmodel = (submodels || []).find(
    (submodel) => submodel.id === modelId,
  );
  const topLevelModel = project?.models.find((model) => model.uuid === modelId);

  const kind: ModelKind | undefined = topLevelModel
    ? 'Model'
    : topLevelSubmodel
    ? 'Submodel'
    : undefined;

  const { versionFull, isFetching } = useModelVersionData(
    modelId,
    versionId,
    kind,
    {},
  );

  // Make sure we clear the model state before the initial load and between loads.
  React.useEffect(() => {
    if (isFetching) {
      clearModelState(dispatch);
    }
  }, [dispatch, isFetching]);

  React.useEffect(() => {
    if (!versionFull || !modelId || !versionId || !arePermissionsLoaded) return;

    // Clear old model state.
    // Model version data might be cached, in which case there is no API call needed
    // to load the model, and there will be no intermediate "loading" state.
    clearModelState(dispatch);

    // Load new model state.
    if (versionFull.kind === 'Model') {
      dispatch(
        modelActions.loadModelContent({
          diagram: versionFull.diagram,
          submodels: versionFull.submodelsSection,
          state_machines: versionFull.stateMachines,
          parameters: versionFull.modelParameters,
          configuration: versionFull.configuration,
          name: versionFull.name,
        }),
      );
    } else if (versionFull.kind === 'Submodel') {
      dispatch(
        modelActions.loadSubmodelContent({
          diagram: versionFull.diagram,
          submodels: versionFull.submodelsSection,
          state_machines: versionFull.stateMachines,
          parameterDefinitions: versionFull.parameterDefinitions || [],
          portDefinitionsInputs: versionFull.portDefinitionsInputs || [],
          portDefinitionsOutputs: versionFull.portDefinitionsOutputs || [],
          submodelConfiguration: versionFull.submodelConfiguration || {},
          name: versionFull.name,
        }),
      );
    } else {
      console.error('Unknown model kind in ModelVersion.tsx', versionFull);
    }

    dispatch(
      modelMetadataActions.updateOpenModel({
        modelId,
        versionId,
        editId: versionFull.editId,
        updatedAt: versionFull.updatedAt,
      }),
    );

    // clear out redux undo/redo history when a model is loaded
    // (shouldn't be possible to return to a state before the model is loaded)
    dispatch(UndoRedoActionCreators.clearHistory());
  }, [dispatch, versionFull, arePermissionsLoaded, versionId, modelId]);

  React.useEffect(() => {
    if (modelRestoreState === ModelRestoreState.TriggeringOpenRestoredModel) {
      dispatch(modelMetadataActions.finishTriggeringOpenRestoredModel());
      navigate(`/projects/${projectId}/models/${modelId}`);
    }
  }, [dispatch, navigate, modelRestoreState, modelId, projectId]);

  return null;
};

export default ModelVersion;
