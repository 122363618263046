/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  className?: string;
}

const ComparatorLt: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.08391 20.5C9.00391 20.5 8.95191 20.46 8.92791 20.38L8.53191 19.096C8.51591 19.064 8.4959 19.048 8.4719 19.048H5.3039C5.2799 19.048 5.2599 19.064 5.2439 19.096L4.8479 20.38C4.8239 20.46 4.7719 20.5 4.6919 20.5H3.4919C3.4439 20.5 3.4079 20.488 3.3839 20.464C3.3599 20.432 3.3559 20.388 3.3719 20.332L5.9759 12.22C5.9999 12.14 6.0519 12.1 6.1319 12.1H7.6319C7.7119 12.1 7.7639 12.14 7.7879 12.22L10.4039 20.332C10.4119 20.348 10.4159 20.368 10.4159 20.392C10.4159 20.464 10.3719 20.5 10.2839 20.5H9.08391ZM5.6159 17.884C5.6079 17.932 5.6239 17.956 5.6639 17.956H8.09991C8.14791 17.956 8.16391 17.932 8.1479 17.884L6.9119 13.864C6.9039 13.832 6.8919 13.816 6.8759 13.816C6.8599 13.816 6.8479 13.832 6.8399 13.864L5.6159 17.884Z"
      fill={props.fill}
    />
    <path
      d="M18.6795 19.96C18.6795 20.016 18.6635 20.056 18.6315 20.08C18.5995 20.096 18.5595 20.092 18.5115 20.068L13.6755 17.884C13.6035 17.844 13.5675 17.788 13.5675 17.716V16.744C13.5675 16.664 13.6035 16.608 13.6755 16.576L18.5115 14.392C18.5435 14.376 18.5675 14.368 18.5835 14.368C18.6475 14.368 18.6795 14.412 18.6795 14.5V15.544C18.6795 15.616 18.6435 15.672 18.5715 15.712L15.0795 17.188C15.0315 17.212 15.0315 17.236 15.0795 17.26L18.5715 18.748C18.6435 18.78 18.6795 18.836 18.6795 18.916V19.96Z"
      fill={props.fill}
    />
    <path
      d="M27.0484 16.12C27.0004 16.144 27.0004 16.168 27.0484 16.192C27.8644 16.576 28.2724 17.236 28.2724 18.172C28.2724 18.932 28.0164 19.512 27.5044 19.912C27.0004 20.304 26.3404 20.5 25.5244 20.5H22.5004C22.4604 20.5 22.4244 20.488 22.3924 20.464C22.3684 20.432 22.3564 20.396 22.3564 20.356V12.244C22.3564 12.204 22.3684 12.172 22.3924 12.148C22.4244 12.116 22.4604 12.1 22.5004 12.1H25.4284C26.3004 12.1 26.9724 12.288 27.4444 12.664C27.9164 13.04 28.1524 13.608 28.1524 14.368C28.1524 15.208 27.7844 15.792 27.0484 16.12ZM23.8084 13.3C23.7684 13.3 23.7484 13.32 23.7484 13.36V15.58C23.7484 15.62 23.7684 15.64 23.8084 15.64H25.4284C25.8524 15.64 26.1844 15.54 26.4244 15.34C26.6724 15.132 26.7964 14.848 26.7964 14.488C26.7964 14.12 26.6724 13.832 26.4244 13.624C26.1844 13.408 25.8524 13.3 25.4284 13.3H23.8084ZM25.5244 19.288C25.9404 19.288 26.2684 19.176 26.5084 18.952C26.7564 18.728 26.8804 18.424 26.8804 18.04C26.8804 17.648 26.7564 17.344 26.5084 17.128C26.2604 16.904 25.9244 16.792 25.5004 16.792H23.8084C23.7684 16.792 23.7484 16.812 23.7484 16.852V19.228C23.7484 19.268 23.7684 19.288 23.8084 19.288H25.5244Z"
      fill={props.fill}
    />
  </svg>
);

ComparatorLt.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  className: '',
};

export default ComparatorLt;
