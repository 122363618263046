import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Schema } from 'ajv';
import {
  Node as NodeSignalInfo,
  SignalTypes,
} from 'app/generated_types/collimator/dashboard/serialization/ui_types.gen';
import sigTypesSchema from 'app/generated_types/schemas/ui_types.gen.schema.json';
import { toCapitalize } from 'util/toCapitalize';
import { ajvInstance } from './errorsSlice';

export const signalTypeInfoIsValid = ajvInstance.compile<SignalTypes>(
  sigTypesSchema as Schema,
);

export const getValidatedSignalTypeAndTimeInfoData = (
  data: unknown,
): NodeSignalInfo[] => {
  const validationRes = signalTypeInfoIsValid(data);
  if (validationRes) {
    return data.nodes;
  }

  console.error('Invalid signal type info data', signalTypeInfoIsValid.errors);
  return [];
};

export const nonDiscreteTimeModeValMap: {
  [k: string]: 'Continuous' | 'Constant' | 'Acausal' | 'Hybrid';
} = {
  Continuous: 'Continuous',
  Constant: 'Constant',
  Hybrid: 'Hybrid',
  Acausal: 'Acausal',
};

export type TimeModeType =
  | { mode: 'Continuous' | 'Constant' | 'Acausal' | 'Hybrid' | 'Unknown' }
  | { mode: 'Discrete'; stepLevel: number; discreteInterval: number };

export type SignalIndividualTypeAndTimeInfo = {
  dtype: string;
  dimension: number[];
  mode: TimeModeType;
};
type SignalAnalysisDataType = {
  datatypeAndDimensions: Array<SignalIndividualTypeAndTimeInfo>;
  timeMode: TimeModeType | undefined;
};

export interface SignalsData {
  [blockPathName: string]: SignalAnalysisDataType | undefined;
}

export interface CompilationAnalysisDataState {
  signalsDataSimulationId: string;
  signalsData: SignalsData;
  discreteStepsLevelMap: { [stepValue: number]: number | undefined };
  discreteStepsOrderedByLevel: Array<number>;
  hasHybridTimeMode: boolean;
}

const initialState: CompilationAnalysisDataState = {
  signalsDataSimulationId: '',
  signalsData: {},
  discreteStepsLevelMap: {},
  discreteStepsOrderedByLevel: [],
  hasHybridTimeMode: false,
};

function setCacheSimulationId(
  state: CompilationAnalysisDataState,
  simulationId: string,
) {
  if (state.signalsDataSimulationId !== simulationId) {
    state.signalsData = {};
    state.signalsDataSimulationId = simulationId;
  }
}

const compilationDataSlice = createSlice({
  name: 'compilationDataSlice',
  initialState,
  reducers: {
    clearAllCompilationState() {
      return initialState;
    },
    clearSimulationCache(
      state,
      action: PayloadAction<{
        simulationId: string;
      }>,
    ) {
      const { simulationId } = action.payload;
      setCacheSimulationId(state, simulationId);
    },

    // FIXME: this is not implemented with wildcat
    setDiscreteSteps(
      state,
      action: PayloadAction<{
        discreteStepsLevelMap: { [stepValue: number]: number | undefined };
        discreteStepsOrderedByLevel: Array<number>;
      }>,
    ) {
      state.discreteStepsLevelMap = action.payload.discreteStepsLevelMap;
      state.discreteStepsOrderedByLevel =
        action.payload.discreteStepsOrderedByLevel;
    },

    setAllDatatypeData(
      state,
      action: PayloadAction<{
        simulationId: string;
        nodeSignalInfos: NodeSignalInfo[];
      }>,
    ) {
      const { simulationId, nodeSignalInfos } = action.payload;

      // Clear out any earlier cache before repopulating the cache.
      setCacheSimulationId(state, simulationId);
      let hasHybridTimeMode = false;

      for (let i = 0; i < nodeSignalInfos.length; i++) {
        const nodeInfo = nodeSignalInfos[i];
        const blockPathName = nodeInfo.namepath.join('.');

        let signalData = state.signalsData[blockPathName];
        if (!signalData) {
          signalData = {
            timeMode: undefined,
            datatypeAndDimensions: [],
          };
          state.signalsData[blockPathName] = signalData;
        }

        signalData.timeMode =
          nodeInfo.time_mode === 'Discrete'
            ? {
                mode: 'Discrete',
                stepLevel: 0,
                discreteInterval: nodeInfo.discrete_interval || 0,
              }
            : {
                mode:
                  nonDiscreteTimeModeValMap[nodeInfo.time_mode] || 'Unknown',
              };

        if (signalData.timeMode.mode === 'Hybrid') {
          hasHybridTimeMode = true;
        }

        for (let j = 0; j < nodeInfo.outports.length; j++) {
          const portInfo = nodeInfo.outports[j];

          const portIndex = portInfo.index;
          const typeData: SignalIndividualTypeAndTimeInfo = {
            dtype: toCapitalize(portInfo.dtype),
            dimension: portInfo.dimension,
            mode:
              portInfo.time_mode === 'Discrete'
                ? {
                    mode: 'Discrete',
                    stepLevel: 0,
                    discreteInterval: portInfo.discrete_interval || 0,
                  }
                : {
                    mode:
                      nonDiscreteTimeModeValMap[portInfo.time_mode] ||
                      'Unknown',
                  },
          };

          signalData.datatypeAndDimensions[portIndex] = typeData;
        }
      }

      state.hasHybridTimeMode = hasHybridTimeMode;
    },
  },
});

export const compilationAnalysisDataActions = compilationDataSlice.actions;

export default compilationDataSlice;
