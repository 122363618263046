/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const AcausalRotationalFixedangle: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M17.75 6.60357C17.75 5.71266 18.8271 5.2665 19.4571 5.89646L24.0303 10.4697C24.3232 10.7626 24.3232 11.2375 24.0303 11.5303C23.7374 11.8232 23.2626 11.8232 22.9697 11.5303L19.25 7.81067V10.6894L24.0303 15.4697C24.3232 15.7626 24.3232 16.2375 24.0303 16.5303C23.7374 16.8232 23.2626 16.8232 22.9697 16.5303L19.25 12.8107V15.6894L24.0303 20.4697C24.3232 20.7626 24.3232 21.2375 24.0303 21.5303C23.7374 21.8232 23.2626 21.8232 22.9697 21.5303L19.25 17.8107V20.6894L24.0303 25.4697C24.3232 25.7626 24.3232 26.2374 24.0303 26.5303C23.7374 26.8232 23.2626 26.8232 22.9697 26.5303L19.25 22.8107V26C19.25 26.4142 18.9142 26.75 18.5 26.75C18.0858 26.75 17.75 26.4142 17.75 26V16.75H6C5.58579 16.75 5.25 16.4142 5.25 16C5.25 15.5858 5.58579 15.25 6 15.25H17.75V6.60357Z"
      fill={props.fill}
    />
  </svg>
);

AcausalRotationalFixedangle.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default AcausalRotationalFixedangle;
