// !!!! -- DO NOT MANUALLY EDIT -- !!!!
// This file was generated by something in scripts/generateDefaultBlockData.js
// If you want to change the contents of this file, it should be automated
//
// (You can edit this file locally, but it may not be checked into the codebase -
// PRs with a manual edit of this file are forbidden from being merged.)

import { computationBlockDefaults } from 'app/generated_blocks/computationBlockDefaults';
import type { ComputationBlockClass } from 'app/generated_types/ComputationBlockClass';
import deepmerge from 'deepmerge';

export const DriveCycle: ComputationBlockClass = deepmerge(
  computationBlockDefaults,
  {
    "base": {
      "name": "DriveCycle",
      "namespace": "core",
      "description": "Outputs the speed signal from standard automotive drive cycles.",
      "feature_level": "dev"
    },
    "modes": {
      "time": "any"
    },
    "ports": {
      "outputs": {
        "static": [
          {
            "name": "speed_kph"
          }
        ],
        "conditional": [
          {
            "name": "upper_limit_kph",
            "order": 1
          },
          {
            "name": "lower_limit_kph",
            "order": 2
          }
        ]
      }
    },
    "parameter_definitions": [
      {
        "name": "cycle",
        "description": "Name of the drive cycle.",
        "data_type": "string",
        "default_value": "ECE",
        "allowed_values": [
          "CSC",
          "CWTVC",
          "ECE",
          "EUDC",
          "FTP",
          "HDUDDS",
          "HWYFET",
          "IM240",
          "JPN10",
          "JPN15",
          "LA92",
          "NYCC",
          "SC03",
          "US06",
          "WHVC"
        ]
      },
      {
        "name": "output_limits",
        "description": "Check this box to also output the upper and lower limits.",
        "data_type": "bool",
        "default_value": "false"
      }
    ]
  }
);
