import styled from '@emotion/styled';
import { LogLine } from 'app/apiGenerated/generatedApiTypes';
import { ErrorLoopItem as WildcatErrorLoopItem } from 'app/generated_types/collimator/dashboard/serialization/ui_types.gen';
import { useAppDispatch } from 'app/hooks';
import { parseAllWildcatErrorsFromLogs } from 'app/slices/errorsSlice';
import { modelActions } from 'app/slices/modelSlice';
import { navigationActions } from 'app/slices/navigationSlice';
import { ModelLogLine } from 'app/slices/simResultsSlice';
import { format } from 'date-fns';
import React from 'react';
import {
  getLogLevelDisplayName,
  parseServerOutputLog,
} from 'ui/appBottomBar/OutputLogLineUtils';
import Button from 'ui/common/Button/Button';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import Tooltip from 'ui/common/Tooltip/Tooltip';
import { ExpandableDetailsContainer } from 'ui/modelEditor/optimizations/ExpandableDetailsContainer';

const ButtonWrapper = styled.div`
  margin-right: ${({ theme }) => theme.spacing.normal};
`;

const CompilationErrorButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 100%;
  flex-wrap: wrap;
  margin-top: ${({ theme }) => theme.spacing.normal};

  > button {
    margin-bottom: ${({ theme }) => theme.spacing.normal};
    margin-right: ${({ theme }) => theme.spacing.normal};
  }
`;

const GoToBlockButton = ({
  namePath,
  uuidPath,
}: {
  namePath: string;
  uuidPath?: string[];
}) => {
  const dispatch = useAppDispatch();

  const navigateToBlock = React.useCallback(() => {
    if (!uuidPath || !uuidPath.length) return;

    const parentPath = uuidPath.slice(0, -1);
    const nodeUuid = uuidPath[uuidPath.length - 1];
    dispatch(
      modelActions.setSelections({
        selectionParentPath: parentPath,
        selectedBlockIds: [nodeUuid],
      }),
    );
    dispatch(
      navigationActions.requestFocusOnNode({
        nodeId: nodeUuid,
        parentPath,
      }),
    );
  }, [dispatch, uuidPath]);

  const name = namePath ? namePath.split('.').pop() : null;
  if (!name) return null;

  return (
    <ButtonWrapper>
      <Tooltip contentText={name}>
        <Button
          variant={ButtonVariants.SmallSecondary}
          onClick={navigateToBlock}>
          Jump to {name}
        </Button>
      </Tooltip>
    </ButtonWrapper>
  );
};

const CompilationErrorButtons = ({ logLine }: { logLine: LogLine }) => {
  const errors = parseAllWildcatErrorsFromLogs([logLine]);
  if (!errors.length) return null;

  const blockUuidPaths: Record<string, string[]> = {};

  for (let i = 0; i < errors.length; i++) {
    const errorVal = errors[i];

    if (errorVal.name_path) {
      const blockUuidPath = errorVal.uuid_path;
      if (blockUuidPath && !blockUuidPaths[errorVal.name_path]) {
        blockUuidPaths[errorVal.name_path] = blockUuidPath;
      }
    }

    if (errorVal.loop) {
      for (let j = 0; j < errorVal.loop.length; j++) {
        const loopItem: WildcatErrorLoopItem = errorVal.loop[j];
        if (Array.isArray(loopItem.uuid_path) && loopItem.name_path) {
          const blockUuidPath = loopItem.uuid_path;
          if (blockUuidPath && !blockUuidPaths[loopItem.name_path]) {
            blockUuidPaths[loopItem.name_path] = blockUuidPath;
          }
        }
      }
    }
  }

  if (Object.entries(blockUuidPaths).length === 0) return null;

  return (
    <CompilationErrorButtonsContainer>
      {Object.entries(blockUuidPaths).map(([namePath, uuidPath], i) => (
        <GoToBlockButton key={i} namePath={namePath} uuidPath={uuidPath} />
      ))}
    </CompilationErrorButtonsContainer>
  );
};

export const OutputRow = styled.tr`
  display: table-row;
  white-space: pre-line;

  :hover {
    background-color: ${({ theme }) => theme.colors.ui.tint3};
  }
`;

const OutputRowElement = styled.td`
  padding: 0.5em;
  vertical-align: top;
  width: 1%;

  &.unknown {
    padding-top: 0;
    padding-bottom: 0;
  }
`;

export const OutputRowTimestamp = styled(OutputRowElement)`
  color: ${({ theme }) => theme.colors.text.secondary};
  white-space: nowrap;
`;

export const OutputRowLevel = styled(OutputRowElement)`
  color: ${({ theme }) => theme.colors.text.tertiary};
  font-weight: bold;
  white-space: nowrap;

  &.fatal {
    color: ${({ theme }) => theme.colors.ui.error};
  }

  &.err {
    color: ${({ theme }) => theme.colors.ui.error};
  }

  &.wrn {
    color: ${({ theme }) => theme.colors.ui.highlight};
  }

  &.inf {
    color: ${({ theme }) => theme.colors.brand.tertiary.base};
  }

  &.dbg {
    color: ${({ theme }) => theme.colors.text.tertiary};
  }

  &.unknown {
    color: ${({ theme }) => theme.colors.text.tertiary};
  }
`;

export const OutputRowText = styled(OutputRowElement)`
  color: ${({ theme }) => theme.colors.text.primary};
  width: 100%;
  overflow-wrap: break-word;
`;

export const OutputRowMessage = styled.span`
  white-space: pre-wrap;
`;

const MetadataEntry = styled.span`
  color: ${({ theme }) => theme.colors.text.secondary};
  margin-right: 1em;
  font-weight: normal;
  display: inline-block;
  word-break: break-all;

  :hover {
    color: ${({ theme }) => theme.colors.text.primary};
  }
`;

const MetadataName = styled.span`
  font-weight: bold;
  display: inline-block;
`;

interface Props {
  rawLog: ModelLogLine;
}

const OutputLogLine: React.FC<Props> = ({ rawLog }) => {
  const [hovering, setHovering] = React.useState(false);

  const message: string = rawLog.message || '';

  const timestamp: string | null =
    typeof rawLog.timestamp === 'number'
      ? format(new Date(rawLog.timestamp * 1000), 'HH:mm:ss.SSS')
      : null;

  const metadata: [string, string][] = parseServerOutputLog(rawLog);

  const [levelName, className] = getLogLevelDisplayName(rawLog.level);
  const isError = levelName === 'ERROR' || levelName === 'FATAL ERROR';

  return (
    <OutputRow
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}>
      <OutputRowTimestamp>{timestamp}</OutputRowTimestamp>
      <OutputRowLevel className={className}>{levelName}</OutputRowLevel>
      <OutputRowText className={className}>
        <OutputRowMessage>{message}</OutputRowMessage>
        {metadata ? <br /> : null}
        {metadata?.map((m, i) => (
          <MetadataEntry key={i}>
            <MetadataName>{m[0]}</MetadataName>={m[1]}
          </MetadataEntry>
        ))}
        {rawLog.__detail__ && (
          <ExpandableDetailsContainer label="See more">
            {rawLog.__detail__}
          </ExpandableDetailsContainer>
        )}
        {rawLog.__error__ && (hovering || isError) && (
          <CompilationErrorButtons logLine={rawLog} />
        )}
      </OutputRowText>
    </OutputRow>
  );
};

export default OutputLogLine;
