/** @jsxImportSource @emotion/react */
// Figma design: https://www.figma.com/file/2ZwzFm5zJ82g4uCKXIxxAW/Library?node-id=225%3A115

import { useTheme } from '@emotion/react';
import styled from '@emotion/styled/macro';
import React from 'react';

import { Spinner } from 'ui/common/Spinner';
import { getButtonStyle } from './ButtonVariants';
import { ButtonVariants } from './buttonTypes';

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  /** Icon to be placed before the text. */
  Icon?: React.FC<any>;
  RightIcon?: React.FC<any>;
  children?: React.ReactNode | string;
  variant?: ButtonVariants;
  hasRedText?: boolean;
  disabled?: boolean;
  testId?: string;
  className?: string;
  loading?: boolean;
  tint?: string;
  textTint?: string;
  largeIcon?: boolean;
}

const Content = styled.span<{ isSmall: boolean; hasRedText: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  padding: ${({ theme, isSmall }) =>
    isSmall ? theme.spacing.small : `5px ${theme.spacing.normal}`};
  ${({ theme, hasRedText }) =>
    hasRedText ? `color: ${theme.colors.ui.error};` : ''}
`;

const Children = styled.span<{ visible: boolean }>`
  opacity: ${({ visible }) => (visible ? '1' : '0')};
  display: flex;
  align-items: center;
`;

const Loader = styled(Spinner)`
  position: absolute;
`;

const isSmall = (variant: ButtonVariants) =>
  [
    ButtonVariants.SmallPrimary,
    ButtonVariants.SmallSecondary,
    ButtonVariants.SmallTertiary,
  ].includes(variant);

const isPrimary = (variant: ButtonVariants) =>
  [ButtonVariants.SmallPrimary, ButtonVariants.LargePrimary].includes(variant);

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      onClick,
      Icon,
      RightIcon,
      variant = ButtonVariants.LargePrimary,
      hasRedText = false,
      disabled = false,
      children,
      testId,
      className,
      loading = false,
      tint,
      textTint,
      largeIcon,
      ...restProps
    },
    ref,
  ) => {
    const theme = useTheme();

    const getVariantLoader = () => {
      if (!loading) return;
      if (isPrimary(variant)) {
        return <Loader fill="white" />;
      }
      return <Loader />;
    };

    const Wrapper = getButtonStyle(
      theme,
      variant,
      largeIcon === undefined ? !children : !largeIcon,
      tint,
      textTint,
      hasRedText,
    );
    return (
      <button
        css={Wrapper}
        ref={ref}
        {...restProps}
        onClick={onClick}
        disabled={disabled || loading}
        data-test-id={testId}
        className={className}>
        {Icon && <Icon fill={hasRedText ? theme.colors.ui.error : undefined} />}
        {children ? (
          <Content isSmall={isSmall(variant)} hasRedText={hasRedText}>
            <Children visible={!loading}>{children}</Children>
            {getVariantLoader()}
          </Content>
        ) : null}
        {RightIcon && <RightIcon />}
      </button>
    );
  },
);

export default Button;
