import styled from '@emotion/styled/macro';
import { t } from '@lingui/macro';
import {
  globalRunAutolayout_avoidUsing,
  globalRunEraseModelLayout_avoidUsing,
} from 'app/autolayout';
import type { Coordinate } from 'app/common_types/Coordinate';
import { HoverEntity, HoverEntityType } from 'app/common_types/MouseTypes';
import { PortSide } from 'app/common_types/PortTypes';
import {
  AnnotationInstance,
  LinkInstance,
  ModelDiagram,
  NodeInstance,
} from 'app/generated_types/SimulationModel';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { getCurrentModelRef } from 'app/sliceRefAccess/CurrentModelRef';
import { modelActions } from 'app/slices/modelSlice';
import { rcMenuActions } from 'app/slices/rcMenuSlice';
import { submodelsActions } from 'app/slices/submodelsSlice';
import { InAppClipboard } from 'app/slices/uiFlagsSlice';
import { AppDispatch } from 'app/store';
import { getNamePath } from 'app/utils/modelDiagramUtils';
import React from 'react';
import { useVisualizerPrefs } from 'ui/modelEditor/useVisualizerPrefs';
import {
  copyEntities,
  pasteEntities,
} from 'ui/modelRendererInternals/copyPaste';
import { rendererState } from 'ui/modelRendererInternals/modelRenderer';
import { OpSys, detectedOS } from 'util/detectOS';

const ctrlOrCmd = detectedOS === OpSys.macOS ? '⌘' : 'Ctrl';

export enum RCMenuSelection {
  NoEntity,
  MultiEntity,
  SingleEntity,
}
export type RCMenuContent =
  | { type: RCMenuSelection.NoEntity }
  | { type: RCMenuSelection.MultiEntity }
  | { type: RCMenuSelection.SingleEntity; entity: HoverEntity };

const RCContextMenuContainer = styled.div<{ x: number; y: number }>`
  width: 128px;
  position: absolute;
  left: ${({ x }) => x}px;
  top: ${({ y }) => y}px;

  display: flex;
  flex-direction: column;
  background: #f9fafa;
  box-shadow: 0px 19px 30px rgba(0, 0, 0, 0.09),
    0px 7.09px 14px rgba(0, 0, 0, 0.0425),
    0px 3.33px 3.9px rgba(0, 0, 0, 0.0225);
  border-radius: 0px 0px 2px 2px;
  padding-top: ${({ theme }) => theme.spacing.small};
`;

const MenuItem = styled.div<{ canClick: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 24px;
  padding: 0 ${({ theme }) => theme.spacing.normal};
  margin-bottom: ${({ theme }) => theme.spacing.small};
  ${({ theme, canClick }) =>
    // TODO: get rid of prettier because it forces exactly this type of
    // ugly formatting. eslint-airbnb has a sufficient ruleset by itself.
    canClick
      ? `
    cursor: pointer;

    &:hover {
      background: ${theme.colors.tint.tint2};
    }
  `
      : `
    cursor: not-allowed;
    opacity: 0.6;
  `};
`;

const RCMenuSeparator = styled.div`
  height: 1px;
  background-color: #e4e7e7;
  margin-bottom: ${({ theme }) => theme.spacing.small};
`;

const MenuLabel = styled.div``;
const MenuSubLabel = styled.div`
  color: #869697;
`;

enum RCMenuSeparatorPosition {
  Top,
  Bottom,
}

type MenuItemData = {
  label: string;
  subLabel?: string;
  onClick?: () => void;
  separator?: RCMenuSeparatorPosition;
  hideIfReadOnly?: boolean;
};

const cutItemMessage = t({
  message: 'Cut',
  id: 'rightClickMenu.cutModelItemMessage',
});
const copyItemMessage = t({
  message: 'Copy',
  id: 'rightClickMenu.copyModelItemMessage',
});
const deleteItemMessage = t({
  message: 'Delete',
  id: 'rightClickMenu.deleteModelItemMessage',
});
const pasteItemMessage = t({
  message: 'Paste',
  id: 'rightClickMenu.pasteModelItemMessage',
});
const createGroupMessage = t({
  message: 'Create Group',
  id: 'rightClickMenu.createGroup',
});

const blockMenuItems = (
  dispatch: AppDispatch,
  blockUuid: string,
  blockName: string, // TODO make this name path
  parentPath: string[],
  namePath: string[],
  block: NodeInstance,
  visualizerPrefs: ReturnType<typeof useVisualizerPrefs>,
  developerModeEnabled: boolean,
): MenuItemData[] => {
  const changeBlockDirectionality = () => {
    dispatch(
      modelActions.flipNodeDirectionality({
        blockUuid,
      }),
    );
  };

  const changeBlockLabelPos = () => {
    dispatch(
      modelActions.swapNodeLabelPosition({
        blockUuid,
      }),
    );
  };

  const togglePortLabels = () => {
    dispatch(
      modelActions.togglePortLabelDisplay({
        blockUuid,
      }),
    );
  };

  const deleteBlock = () => {
    visualizerPrefs.removeBlockPortsFromChart(
      [
        {
          nodeId: blockUuid,
          parentPath,
        },
      ],
      true,
    );

    // TODO: call delete block endpoint so that link deletes cascade.
    // dispatch(enhancedApi.endpoints.deleteBlock.initiate());

    dispatch(
      modelActions.removeEntitiesFromModel({
        blockUuids: [blockUuid],
        autoRemoveBlocksLinks: false,
      }),
    );
  };

  const cutFunc = () => {
    if (!rendererState) return;
    copyEntities(
      getCurrentModelRef().projectId,
      [block],
      [],
      [],
      [blockUuid],
      [],
      [],
      dispatch,
      rendererState.refs.current.visualizerPrefs,
      true,
    );
  };

  const copyFunc = () => {
    if (!rendererState) return;
    copyEntities(
      getCurrentModelRef().projectId,
      [block],
      [],
      [],
      [blockUuid],
      [],
      [],
      dispatch,
      rendererState.refs.current.visualizerPrefs,
      false,
    );
  };

  const createSubmodelFromGroup = (
    groupBlockId: string,
    groupBlockName: string,
  ) => {
    dispatch(
      submodelsActions.setGroupToConvertToSubmodel({
        id: groupBlockId,
        name: groupBlockName,
      }),
    );
  };

  const menuItems: MenuItemData[] = [
    {
      label: cutItemMessage,
      subLabel: `${ctrlOrCmd} + X`,
      onClick: cutFunc,
      hideIfReadOnly: true,
    },
    {
      label: copyItemMessage,
      subLabel: `${ctrlOrCmd} + C`,
      onClick: copyFunc,
    },
    {
      label: deleteItemMessage,
      subLabel: 'Del',
      onClick: deleteBlock,
      separator: RCMenuSeparatorPosition.Bottom,
      hideIfReadOnly: true,
    },
    {
      label: t({
        message: 'Flip Horizontally',
        id: 'rightClickMenu.flipBlockDirection',
      }),
      onClick: changeBlockDirectionality,
      hideIfReadOnly: true,
    },
    {
      label: t({
        message: 'Swap Label Position',
        id: 'rightClickMenu.swapBlockLabelPosition',
      }),
      onClick: changeBlockLabelPos,
      hideIfReadOnly: true,
    },
    {
      label: t({
        message: 'Toggle Port Labels',
        id: 'rightClickMenu.togglePortLabels',
      }),
      onClick: togglePortLabels,
      separator: RCMenuSeparatorPosition.Bottom,
      hideIfReadOnly: true,
    },
  ];

  if (block.type === 'core.Group' || block.type === 'core.Submodel') {
    menuItems.push({
      label: t({
        message: 'Convert to Submodel',
        id: 'rightClickMenu.convertToSubmodel',
      }),
      onClick: () => createSubmodelFromGroup(blockUuid, block.name),
      separator: RCMenuSeparatorPosition.Bottom,
      hideIfReadOnly: true,
    });
  }

  if (developerModeEnabled) {
    menuItems.push({
      label: 'Copy block UUID',
      onClick: () => {
        navigator.clipboard.writeText(blockUuid);
      },
    });

    if (developerModeEnabled) {
      menuItems.push({
        label: 'Copy block path',
        onClick: () => {
          navigator.clipboard.writeText([...namePath, blockName].join('.'));
        },
      });
    }
  }

  return menuItems;
};

function getOutputName(blockUuid: string, portId: number): string | null {
  if (rendererState === null) return null;

  const nodeIndex = rendererState.refs.current.nodesIndexLUT[blockUuid];
  const node = rendererState.refs.current.nodes[nodeIndex];
  const port = node.outputs[portId];
  if (!port) return null;
  return `${node.name}.${port.name}`;
}

function outputPortMenuItems(
  blockUuid: string,
  portId: number,
  namePath: string[],
): MenuItemData[] {
  const outputName = getOutputName(blockUuid, portId);
  if (outputName === null) return [];

  return [
    {
      label: 'Copy output path',
      onClick: () => {
        navigator.clipboard.writeText([...namePath, outputName].join('.'));
      },
    },
  ];
}

const linkMenuItems = (
  dispatch: AppDispatch,
  linkUuid: string,
  link: LinkInstance,
  namePath: string[],
  developerModeEnabled = false,
): MenuItemData[] => {
  const deleteLink = () => {
    dispatch(
      modelActions.removeEntitiesFromModel({
        linkUuids: [linkUuid],
      }),
    );
  };

  const cutFunc = () => {
    if (!rendererState) return;
    copyEntities(
      getCurrentModelRef().projectId,
      [],
      [link],
      [],
      [],
      [linkUuid],
      [],
      dispatch,
      rendererState.refs.current.visualizerPrefs,
      true,
    );
  };

  const copyFunc = () => {
    if (!rendererState) return;
    copyEntities(
      getCurrentModelRef().projectId,
      [],
      [link],
      [],
      [],
      [linkUuid],
      [],
      dispatch,
      rendererState.refs.current.visualizerPrefs,
      false,
    );
  };

  const menuItems: MenuItemData[] = [
    {
      label: cutItemMessage,
      subLabel: `${ctrlOrCmd} + X`,
      onClick: cutFunc,
      hideIfReadOnly: true,
    },
    {
      label: copyItemMessage,
      subLabel: `${ctrlOrCmd} + C`,
      onClick: copyFunc,
    },
    {
      label: deleteItemMessage,
      subLabel: 'Del',
      onClick: deleteLink,
      hideIfReadOnly: true,
    },
  ];

  if (developerModeEnabled) {
    menuItems.push(
      {
        label: 'Developer Options',
        separator: RCMenuSeparatorPosition.Top,
      },
      {
        label: 'Copy link UUID',
        onClick: () => {
          navigator.clipboard.writeText(linkUuid);
        },
      },
    );
    const outputName = link.src && getOutputName(link.src.node, link.src.port);
    if (outputName) {
      menuItems.push({
        label: 'Copy output path',
        onClick: () => {
          navigator.clipboard.writeText([...namePath, outputName].join('.'));
        },
      });
    }
  }

  return menuItems;
};

const multiMenuItems = (
  dispatch: AppDispatch,
  selectedNodeIds: string[],
  selectedLinkIds: string[],
  selectedAnnotationIds: string[],
  selectedNodes: NodeInstance[],
  selectedLinks: LinkInstance[],
  selectedAnnotations: AnnotationInstance[],
  selectionParentPath: string[],
  visualizerPrefs: ReturnType<typeof useVisualizerPrefs>,
): MenuItemData[] => {
  const deleteAll = () => {
    // TODO: call delete block endpoint so that link deletes cascade.
    // dispatch(enhancedApi.endpoints.deleteBlock.initiate());

    selectedNodeIds.forEach((nodeId) => {
      visualizerPrefs.removeBlockPortsFromChart(
        [
          {
            nodeId,
            parentPath: selectionParentPath,
          },
        ],
        true,
      );
    });

    dispatch(
      modelActions.removeEntitiesFromModel({
        blockUuids: selectedNodeIds,
        linkUuids: selectedLinkIds,
        autoRemoveBlocksLinks: false,
      }),
    );
  };

  const cutFunc = () => {
    if (!rendererState) return;
    copyEntities(
      getCurrentModelRef().projectId,
      selectedNodes,
      selectedLinks,
      selectedAnnotations,
      selectedNodeIds,
      selectedLinkIds,
      selectedAnnotationIds,
      dispatch,
      rendererState.refs.current.visualizerPrefs,
      true,
    );
  };

  const copyFunc = () => {
    if (!rendererState) return;
    copyEntities(
      getCurrentModelRef().projectId,
      selectedNodes,
      selectedLinks,
      selectedAnnotations,
      selectedNodeIds,
      selectedLinkIds,
      selectedAnnotationIds,
      dispatch,
      rendererState.refs.current.visualizerPrefs,
      false,
    );
  };

  const createSubmodel = () => {
    dispatch(
      modelActions.createSubdiagramFromSelection({
        subdiagramType: 'core.Group',
      }),
    );
  };

  const selectedBlockCount =
    rendererState?.refs.current.selectedNodeIds.length || 0;

  const menuItems: (MenuItemData | undefined)[] = [
    {
      label: cutItemMessage,
      subLabel: `${ctrlOrCmd} + X`,
      onClick: cutFunc,
      hideIfReadOnly: true,
    },
    {
      label: copyItemMessage,
      subLabel: `${ctrlOrCmd} + C`,
      onClick: copyFunc,
    },
    {
      label: deleteItemMessage,
      subLabel: 'Del',
      onClick: deleteAll,
      separator:
        selectedBlockCount > 1 ? RCMenuSeparatorPosition.Bottom : undefined,
      hideIfReadOnly: true,
    },
    selectedBlockCount > 1
      ? {
          label: createGroupMessage,
          onClick: createSubmodel,
          hideIfReadOnly: true,
        }
      : undefined,
  ];

  return menuItems.filter((item) => item !== undefined) as MenuItemData[];
};

const modelBgMenuItems = (
  dispatch: AppDispatch,
  screenCoord: Coordinate,
  camera: Coordinate,
  zoom: number,
  {
    nodes,
    links,
    annotations,
    copiedSubmodelsSection,
    copiedStateMachines,
  }: InAppClipboard,
  allNodes: NodeInstance[],
  developerModeEnabled: boolean,
): MenuItemData[] => {
  const clickX = screenCoord.x / zoom - camera.x;
  const clickY = screenCoord.y / zoom - camera.y;

  const pasteFunc = () => {
    const pastedUuids = pasteEntities(
      clickX,
      clickY,
      false,
      nodes,
      links,
      annotations,
      copiedSubmodelsSection,
      copiedStateMachines,
      allNodes,
      dispatch,
    );

    if (rendererState) {
      dispatch(
        modelActions.setSelections({
          selectionParentPath: getCurrentModelRef().submodelPath,
          selectedBlockIds: pastedUuids.nodeUuids,
          selectedLinkIds: pastedUuids.linkUuids,
          selectedAnnotationIds: [],
        }),
      );
    }
  };

  const menuItems: MenuItemData[] = [
    {
      label: pasteItemMessage,
      subLabel: `${ctrlOrCmd} + V`,
      onClick: pasteFunc,
      hideIfReadOnly: true,
    },
  ];

  if (developerModeEnabled) {
    menuItems.push({
      label: 'Autolayout Model',
      onClick: () => {
        globalRunAutolayout_avoidUsing();
      },
    });

    menuItems.push({
      label: 'Erase model layout (for testing)',
      onClick: () => {
        globalRunEraseModelLayout_avoidUsing();
      },
    });
  }

  return menuItems;
};

const getMenuItems = (
  menuScreenCoord: Coordinate,
  camera: Coordinate,
  zoom: number,
  content: RCMenuContent | undefined,
  dispatch: AppDispatch,
  selectedNodeIds: string[],
  selectedLinkIds: string[],
  selectedAnnotationIds: string[],
  selectedNodes: NodeInstance[],
  selectedLinks: LinkInstance[],
  selectedAnnotations: AnnotationInstance[],
  selectionParentPath: string[],
  currentSubmodelPath: string[],
  namePath: string[],
  allNodes: NodeInstance[],
  inAppClipboard: InAppClipboard,
  visualizerPrefs: ReturnType<typeof useVisualizerPrefs>,
  developerModeEnabled: boolean,
): MenuItemData[] => {
  if (!content) return [];

  switch (content.type) {
    case RCMenuSelection.SingleEntity:
      if (content.entity.entityType === HoverEntityType.Node) {
        return blockMenuItems(
          dispatch,
          content.entity.block.uuid,
          content.entity.block.name,
          currentSubmodelPath,
          namePath,
          content.entity.block,
          visualizerPrefs,
          developerModeEnabled,
        );
      }
      if (
        content.entity.entityType === HoverEntityType.Link ||
        content.entity.entityType === HoverEntityType.FakeLinkSegment
      ) {
        return linkMenuItems(
          dispatch,
          content.entity.linkUuid,
          content.entity.link,
          namePath,
          developerModeEnabled,
        );
      }
      if (content.entity.entityType === HoverEntityType.Port) {
        const port = content.entity.port;
        if (port.side === PortSide.Output) {
          return outputPortMenuItems(port.blockUuid, port.portId, namePath);
        }
        return [];
      }
      break;
    case RCMenuSelection.MultiEntity:
      return multiMenuItems(
        dispatch,
        selectedNodeIds,
        selectedLinkIds,
        selectedAnnotationIds,
        selectedNodes,
        selectedLinks,
        selectedAnnotations,
        selectionParentPath,
        visualizerPrefs,
      );
    case RCMenuSelection.NoEntity:
      return modelBgMenuItems(
        dispatch,
        menuScreenCoord,
        camera,
        zoom,
        inAppClipboard,
        allNodes,
        developerModeEnabled,
      );
  }

  return [];
};

function getSelectedNodes(
  currentDiagram: ModelDiagram | null,
  selectedBlockIds: string[],
) {
  if (!currentDiagram) return [];

  return selectedBlockIds.reduce<NodeInstance[]>((acc, id) => {
    const foundNode = currentDiagram.nodes.find((node) => node.uuid === id);
    return foundNode ? [...acc, foundNode] : acc;
  }, []);
}

function getSelectedLinks(
  currentDiagram: ModelDiagram | null,
  selectedLinkIds: string[],
) {
  if (!currentDiagram) return [];

  return selectedLinkIds.reduce<LinkInstance[]>((acc, id) => {
    const foundLink = currentDiagram.links.find((link) => link.uuid === id);
    return foundLink ? [...acc, foundLink] : acc;
  }, []);
}

function getSelectedAnnotations(
  currentDiagram: ModelDiagram | null,
  selectedAnnotationIds: string[],
) {
  const annotations = currentDiagram?.annotations;
  if (!currentDiagram || !annotations) return [];

  return selectedAnnotationIds.reduce<AnnotationInstance[]>((acc, id) => {
    const foundAnnotation = annotations.find(
      (annotation) => annotation.uuid === id,
    );
    return foundAnnotation ? [...acc, foundAnnotation] : acc;
  }, []);
}

export const RCContextMenu = (): React.ReactElement | null => {
  const dispatch = useAppDispatch();
  const visualizerPrefs = useVisualizerPrefs();
  const { developerModeEnabled } = useAppSelector(
    (state) => state.userOptions.options,
  );

  const rs = rendererState;

  const { open, coord, content } = useAppSelector((state) => state.rcMenu);

  const { coord: camera, zoom } = useAppSelector((state) => state.camera);

  const inAppClipboard = useAppSelector(
    (state) => state.uiFlags.inAppClipboard,
  );

  const selectedBlockIds = useAppSelector(
    (state) => state.model.present.selectedBlockIds,
  );
  const selectedLinkIds = useAppSelector(
    (state) => state.model.present.selectedLinkIds,
  );
  const selectedAnnotationIds = useAppSelector(
    (state) => state.model.present.selectedAnnotationIds,
  );
  const currentDiagram = useAppSelector(
    (state) => state.modelMetadata.currentDiagram,
  );

  const allNodes = currentDiagram?.nodes || [];
  const selectedNodes = getSelectedNodes(currentDiagram, selectedBlockIds);
  const selectedLinks = getSelectedLinks(currentDiagram, selectedLinkIds);
  const selectedAnnotations = getSelectedAnnotations(
    currentDiagram,
    selectedAnnotationIds,
  );
  const selectionParentPath = useAppSelector(
    (state) => state.model.present.selectionParentPath,
  );
  const currentSubmodelPath = useAppSelector(
    (state) => state.model.present.currentSubmodelPath,
  );

  const nodes = useAppSelector((state) => state.model.present.rootModel.nodes);
  const submodels = useAppSelector((state) => state.model.present.submodels);

  const idToVersionIdToSubmodelFull = useAppSelector(
    (state) => state.submodels.idToVersionIdToSubmodelFull,
  );
  const idToLatestTaggedVersionId = useAppSelector(
    (state) => state.submodels.idToLatestTaggedVersionId,
  );

  const namePath = getNamePath(
    nodes,
    submodels,
    currentSubmodelPath,
    idToVersionIdToSubmodelFull,
    idToLatestTaggedVersionId,
  );

  const menuItems = getMenuItems(
    coord,
    camera,
    zoom,
    content,
    dispatch,
    selectedBlockIds,
    selectedLinkIds,
    selectedAnnotationIds,
    selectedNodes,
    selectedLinks,
    selectedAnnotations,
    selectionParentPath,
    currentSubmodelPath,
    namePath,
    allNodes,
    inAppClipboard,
    visualizerPrefs,
    developerModeEnabled,
  );

  if (!menuItems || menuItems.length === 0) {
    return null;
  }

  return open && rs?.refs?.current ? (
    <RCContextMenuContainer
      x={coord.x}
      y={coord.y}
      onContextMenu={(e: React.MouseEvent) => e.preventDefault()}
      onMouseDown={(e) => e.stopPropagation()}>
      {menuItems.map(
        (item) =>
          (rs.refs.current.uiFlags.canEditModel ||
            (!rs.refs.current.uiFlags.canEditModel &&
              !item.hideIfReadOnly)) && (
            <React.Fragment key={item.label}>
              {item.separator === RCMenuSeparatorPosition.Top ? (
                <RCMenuSeparator />
              ) : null}
              <MenuItem
                onClick={() => {
                  if (item.onClick) {
                    item.onClick();
                    dispatch(rcMenuActions.close());
                  }
                }}
                canClick={Boolean(item.onClick)}>
                <MenuLabel>{item.label}</MenuLabel>
                {item.subLabel ? (
                  <MenuSubLabel>{item.subLabel}</MenuSubLabel>
                ) : null}
              </MenuItem>
              {item.separator === RCMenuSeparatorPosition.Bottom ? (
                <RCMenuSeparator />
              ) : null}
            </React.Fragment>
          ),
      )}
    </RCContextMenuContainer>
  ) : null;
};
