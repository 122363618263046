import { useGetChatSessionsErrorsQuery } from 'app/apiGenerated/generatedApi';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Column } from 'react-table';
import Button from 'ui/common/Button/Button';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import { BackArrow, Reload } from 'ui/common/Icons/Standard';
import Input from 'ui/common/Input/Input';
import Table from 'ui/common/Table/Table';
import { TableWrapper } from 'ui/navbar/ApiKeysModal';
import { ActionSection, DateCell, Head, PageWrapper, Title } from '../styles';

interface ChatErrorData extends Record<string, string> {
  chat_session_uuid: string;
  email: string;
  error: string;
  function_call_name: string;
  function_call_args: string;
  date: string;
}

const ChatSessionCell: React.FC<{ value: string }> = ({ value }) => (
  <a href={`/admin/chat_session/${value}`} target="_blank" rel="noreferrer">
    {value}
  </a>
);

const columns: Column<ChatErrorData>[] = [
  {
    id: 'chat_session_uuid',
    Header: 'Chat Session UUID',
    accessor: 'chat_session_uuid',
    Cell: ChatSessionCell,
    maxWidth: 150,
  },
  {
    id: 'email',
    Header: 'Email',
    accessor: 'email',
    maxWidth: 150,
  },
  {
    id: 'function_name',
    Header: 'Function Name',
    accessor: 'function_call_name',
    maxWidth: 150,
  },
  {
    id: 'function_args',
    Header: 'Function Args',
    accessor: 'function_call_args',
    maxWidth: 150,
  },
  {
    id: 'error',
    Header: 'Error',
    accessor: 'error',
    maxWidth: 150,
  },
  {
    id: 'date',
    Header: 'Date',
    accessor: 'date',
    Cell: DateCell,
    minWidth: 150,
    maxWidth: 0,
  },
];

const ChatErrors: React.FC = () => {
  const [shouldFetch, setShouldFetch] = React.useState<boolean>(false);

  const startDate = React.useRef<string>();
  const endDate = React.useRef<string>();

  const navigate = useNavigate();
  const {
    data: chatErrors,
    isFetching,
    refetch,
  } = useGetChatSessionsErrorsQuery(
    {
      startDate: startDate.current || '',
      endDate: endDate.current || '',
    },
    {
      skip: !shouldFetch,
    },
  );

  const chatErrorsData: ChatErrorData[] = React.useMemo(
    () =>
      chatErrors?.map((chatError) => ({
        chat_session_uuid: chatError.chat_session_uuid,
        email: chatError.user_email,
        error: chatError.error,
        function_call_name: chatError.function_call_name || '',
        function_call_args: chatError.function_call_args || '',
        date: chatError.date,
      })) || [],
    [chatErrors],
  );

  // First initialization of the dates
  React.useEffect(() => {
    if (startDate.current && endDate.current) {
      setShouldFetch(true);
      return;
    }
    endDate.current = new Date().toISOString();
    const d = new Date();
    d.setDate(d.getDate() - 1);
    startDate.current = d.toISOString();
    setShouldFetch(true);
  }, []);

  React.useEffect(() => {
    if (isFetching) {
      setShouldFetch(false);
    }
  }, [isFetching]);

  return (
    <PageWrapper>
      <ActionSection>
        <Head>
          <Title>Chat errors</Title>
          <Button
            variant={ButtonVariants.LargeSecondary}
            Icon={BackArrow}
            onClick={() => navigate('/admin')}>
            Admin portal
          </Button>
        </Head>
        Start date:
        <Input
          value={startDate.current}
          onSubmitValue={(d) => {
            startDate.current = d;
          }}
          disabled={isFetching}
        />
        End date:
        <Input
          value={endDate.current}
          onSubmitValue={(d) => {
            endDate.current = d;
          }}
          disabled={isFetching}
        />
        <Button
          variant={ButtonVariants.LargeSecondary}
          Icon={Reload}
          onClick={() => {
            setShouldFetch(true);
            refetch();
          }}>
          Apply
        </Button>
      </ActionSection>
      <TableWrapper>
        {chatErrorsData ? (
          <Table data={chatErrorsData} columns={columns} />
        ) : (
          <span>Loading</span>
        )}
      </TableWrapper>
    </PageWrapper>
  );
};

export default ChatErrors;
