/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Model: React.FC<Props> = (props) => (
  <svg viewBox="0 0 16 16" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M7.65826 2.59223C7.87901 2.51195 8.12099 2.51195 8.34174 2.59223L13.3417 4.41041C13.7369 4.55411 14 4.9297 14 5.3502V11.6498C14 12.0703 13.7369 12.4459 13.3417 12.5896L8.34174 14.4078C8.12099 14.488 7.87901 14.488 7.65826 14.4078L2.65826 12.5896C2.26307 12.4459 2 12.0703 2 11.6498V5.3502C2 4.9297 2.26307 4.55411 2.65826 4.41041L7.65826 2.59223ZM3 5.84226L4.75 6.7968V10.1155L7.5 11.3262V13.2861L3 11.6498V5.84226ZM8.5 13.2861L13 11.6498V5.84226L11.25 6.7968V10.1155L8.5 11.3262V13.2861ZM8 10.4537L9.54161 9.77497L8 9.05221L6.45839 9.77497L8 10.4537ZM5.75 9.00264V6.8502L7.5 6.21384V8.18218L5.75 9.00264ZM8.5 8.18218L10.25 9.00264V6.8502L8.5 6.21384V8.18218ZM10.7087 5.95295L12.2883 5.09139L8 3.53202L3.71173 5.09139L5.29126 5.95295L8 4.96796L10.7087 5.95295Z"
      fill={props.fill}
    />
  </svg>
);

Model.defaultProps = {
  width: '16',
  height: '16',
  viewBox: '0 0 16 16',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Model;
