/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const CoordinaterotationconversionDcmtorpy: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M25.2402 5.5C25.2402 5.22386 25.0164 5 24.7402 5H23.2402C22.688 5 22.2402 5.44772 22.2402 6V26C22.2402 26.5523 22.6879 27 23.2402 27H24.7402C25.0164 27 25.2402 26.7761 25.2402 26.5C25.2402 26.2239 25.0164 26 24.7402 26H23.2402V6H24.7402C25.0164 6 25.2402 5.77614 25.2402 5.5Z"
      fill={props.fill}
    />
    <path
      d="M29.084 5C28.8078 5 28.584 5.22386 28.584 5.5C28.584 5.77614 28.8078 6 29.084 6H30.584V26H29.084C28.8078 26 28.584 26.2239 28.584 26.5C28.584 26.7761 28.8078 27 29.084 27H30.584C31.1363 27 31.584 26.5523 31.584 26V6C31.584 5.44772 31.1363 5 30.584 5H29.084Z"
      fill={props.fill}
    />
    <path
      d="M16.7197 11.9697C17.0126 11.6768 17.4874 11.6768 17.7803 11.9697L20.4268 14.6161C20.9149 15.1043 20.9149 15.8957 20.4268 16.3839L17.7803 19.0303C17.4874 19.3232 17.0126 19.3232 16.7197 19.0303C16.4268 18.7374 16.4268 18.2626 16.7197 17.9697L19.1893 15.5L16.7197 13.0303C16.4268 12.7374 16.4268 12.2626 16.7197 11.9697Z"
      fill={props.fill}
    />
    <path
      d="M6.3095 18.324C6.62417 18.484 6.9895 18.564 7.4055 18.564C7.81617 18.564 8.17617 18.4867 8.4855 18.332C8.80017 18.1773 9.04283 17.9587 9.2135 17.676C9.3895 17.3933 9.4775 17.068 9.4775 16.7C9.4775 16.6787 9.46683 16.66 9.4455 16.644C9.4295 16.628 9.40817 16.62 9.3815 16.62L8.4455 16.58C8.3815 16.58 8.3495 16.6093 8.3495 16.668C8.3495 16.9453 8.2615 17.1693 8.0855 17.34C7.91483 17.5107 7.68817 17.596 7.4055 17.596C7.1175 17.596 6.8855 17.5107 6.7095 17.34C6.53883 17.1693 6.4535 16.9453 6.4535 16.668V14.74C6.4535 14.4573 6.53883 14.2307 6.7095 14.06C6.8855 13.8893 7.1175 13.804 7.4055 13.804C7.68817 13.804 7.91483 13.8893 8.0855 14.06C8.2615 14.2307 8.3495 14.4573 8.3495 14.74C8.3495 14.7987 8.37617 14.828 8.4295 14.828H8.4455L9.3815 14.772C9.4455 14.7613 9.4775 14.732 9.4775 14.684C9.4775 14.316 9.3895 13.9933 9.2135 13.716C9.04283 13.4333 8.80017 13.2173 8.4855 13.068C8.17617 12.9133 7.81617 12.836 7.4055 12.836C6.9895 12.836 6.62417 12.916 6.3095 13.076C5.99483 13.236 5.75217 13.4627 5.5815 13.756C5.41083 14.0493 5.3255 14.388 5.3255 14.772V16.62C5.3255 17.004 5.41083 17.3427 5.5815 17.636C5.75217 17.9293 5.99483 18.1587 6.3095 18.324Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M0.596 18.5C0.569333 18.5 0.545333 18.492 0.524 18.476C0.508 18.4547 0.5 18.4307 0.5 18.404V12.996C0.5 12.9693 0.508 12.948 0.524 12.932C0.545333 12.9107 0.569333 12.9 0.596 12.9H2.596C2.996 12.9 3.34533 12.972 3.644 13.116C3.948 13.2547 4.18267 13.452 4.348 13.708C4.51333 13.964 4.596 14.26 4.596 14.596V16.804C4.596 17.14 4.51333 17.436 4.348 17.692C4.18267 17.948 3.948 18.148 3.644 18.292C3.34533 18.4307 2.996 18.5 2.596 18.5H0.596ZM1.628 17.492C1.628 17.5187 1.64133 17.532 1.668 17.532H2.636C2.88133 17.532 3.07867 17.4493 3.228 17.284C3.38267 17.1187 3.46267 16.8973 3.468 16.62V14.78C3.468 14.5027 3.39067 14.2813 3.236 14.116C3.08667 13.9507 2.884 13.868 2.628 13.868H1.668C1.64133 13.868 1.628 13.8813 1.628 13.908V17.492Z"
      fill={props.fill}
    />
    <path
      d="M13.9047 12.964C13.9314 12.9213 13.9687 12.9 14.0167 12.9H14.9527C14.9794 12.9 15.0007 12.9107 15.0167 12.932C15.038 12.948 15.0487 12.9693 15.0487 12.996V18.404C15.0487 18.4307 15.038 18.4547 15.0167 18.476C15.0007 18.492 14.9794 18.5 14.9527 18.5H14.0167C13.99 18.5 13.966 18.492 13.9447 18.476C13.9287 18.4547 13.9207 18.4307 13.9207 18.404V14.876C13.9207 14.8547 13.9154 14.844 13.9047 14.844C13.894 14.844 13.8834 14.852 13.8727 14.868L13.0247 16.196C12.998 16.2387 12.9607 16.26 12.9127 16.26H12.4407C12.3927 16.26 12.3554 16.2387 12.3287 16.196L11.4807 14.868C11.47 14.852 11.4594 14.8467 11.4487 14.852C11.438 14.852 11.4327 14.8627 11.4327 14.884V18.404C11.4327 18.4307 11.422 18.4547 11.4007 18.476C11.3847 18.492 11.3634 18.5 11.3367 18.5H10.4007C10.374 18.5 10.35 18.492 10.3287 18.476C10.3127 18.4547 10.3047 18.4307 10.3047 18.404V12.996C10.3047 12.9693 10.3127 12.948 10.3287 12.932C10.35 12.9107 10.374 12.9 10.4007 12.9H11.3367C11.3847 12.9 11.422 12.9213 11.4487 12.964L12.6487 14.828C12.6647 14.86 12.6807 14.86 12.6967 14.828L13.9047 12.964Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M26.4937 10.8384C26.1997 10.8384 25.9456 10.7632 25.7314 10.6128C25.5195 10.4624 25.3566 10.2528 25.2427 9.98389C25.1287 9.71273 25.0718 9.39714 25.0718 9.03711V8.96533C25.0718 8.57113 25.1287 8.22705 25.2427 7.93311C25.3566 7.63916 25.5195 7.41016 25.7314 7.24609C25.9456 7.08203 26.2008 7 26.4971 7C26.7158 7 26.9049 7.04443 27.0645 7.1333C27.2262 7.22217 27.363 7.34863 27.4746 7.5127L27.543 7.07178H28.2847V9.65918C28.2847 9.83691 28.3063 9.9611 28.3496 10.0317C28.3952 10.1024 28.4567 10.1377 28.5342 10.1377C28.5501 10.1377 28.5661 10.1366 28.582 10.1343C28.598 10.132 28.6117 10.1274 28.623 10.1206L28.7051 10.7393C28.6322 10.778 28.5604 10.8042 28.4897 10.8179C28.4214 10.8315 28.3485 10.8384 28.271 10.8384C28.0841 10.8384 27.9258 10.7939 27.7959 10.7051C27.6683 10.6139 27.5737 10.4749 27.5122 10.2881C27.3983 10.4681 27.2581 10.6048 27.0918 10.6982C26.9255 10.7917 26.7261 10.8384 26.4937 10.8384ZM26.7363 10.1753C26.8958 10.1753 27.0314 10.1366 27.1431 10.0591C27.257 9.97933 27.3504 9.86654 27.4233 9.7207V8.13818C27.3732 8.0402 27.3151 7.95589 27.249 7.88525C27.1829 7.81462 27.1077 7.76107 27.0234 7.72461C26.9391 7.68587 26.8457 7.6665 26.7432 7.6665C26.5586 7.6665 26.4059 7.72233 26.2852 7.83398C26.1667 7.94336 26.0789 8.09489 26.022 8.28857C25.965 8.48226 25.9365 8.70784 25.9365 8.96533V9.03711C25.9365 9.38346 26.0003 9.66032 26.1279 9.86768C26.2555 10.0728 26.4583 10.1753 26.7363 10.1753Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M25.2256 19.1133V14.147C25.2256 13.8758 25.2962 13.6331 25.4375 13.4189C25.5811 13.2025 25.7736 13.0316 26.0151 12.9062C26.2567 12.7809 26.5278 12.7183 26.8286 12.7183C27.1294 12.7183 27.3937 12.7752 27.6216 12.8892C27.8517 13.0008 28.0306 13.158 28.1582 13.3608C28.2881 13.5614 28.353 13.7949 28.353 14.0615C28.353 14.2803 28.2972 14.4797 28.1855 14.6597C28.0739 14.8374 27.9212 14.981 27.7275 15.0903C28.0078 15.1906 28.2231 15.3467 28.3735 15.5586C28.5239 15.7705 28.5991 16.0326 28.5991 16.3447C28.5991 16.6546 28.5365 16.9212 28.4111 17.1445C28.2858 17.3678 28.1081 17.5399 27.8779 17.6606C27.6501 17.7791 27.3823 17.8384 27.0747 17.8384C26.8947 17.8384 26.7192 17.8156 26.5483 17.77C26.3774 17.7244 26.2236 17.6549 26.0869 17.5615V19.1133H25.2256ZM26.8833 17.1753C27.1499 17.1753 27.3595 17.0967 27.5122 16.9395C27.6649 16.7822 27.7412 16.5726 27.7412 16.3105C27.7412 16.1533 27.7104 16.0109 27.6489 15.8833C27.5874 15.7534 27.4985 15.6509 27.3823 15.5757C27.2684 15.4982 27.1305 15.4595 26.9688 15.4595H26.5723V14.8306H26.791C27.0326 14.8306 27.2091 14.7679 27.3208 14.6426C27.4347 14.515 27.4917 14.3418 27.4917 14.123C27.4917 13.984 27.4644 13.8599 27.4097 13.7505C27.3573 13.6388 27.2798 13.55 27.1772 13.4839C27.077 13.4178 26.9551 13.3848 26.8115 13.3848C26.6725 13.3848 26.5483 13.4178 26.439 13.4839C26.3296 13.55 26.243 13.64 26.1792 13.7539C26.1177 13.8678 26.0869 13.9989 26.0869 14.147V16.895C26.1849 16.9839 26.3022 17.0534 26.439 17.1035C26.578 17.1514 26.7261 17.1753 26.8833 17.1753Z"
      fill={props.fill}
    />
    <path
      d="M26.3979 24.082V25.6919H27.2593V24.1538L28.312 21.146L28.6162 21.0981V20.5684H27.0439V21.0981L27.4541 21.1699L26.9141 23.0088L26.8628 23.2515H26.8423L26.7944 23.0088L26.1758 21.0469C26.1302 20.9079 26.0812 20.8008 26.0288 20.7256C25.9764 20.6504 25.908 20.5991 25.8237 20.5718C25.7417 20.5444 25.6312 20.5308 25.4922 20.5308C25.4147 20.5308 25.3304 20.5376 25.2393 20.5513C25.1481 20.5649 25.0684 20.5832 25 20.606L25.0684 21.2588C25.1162 21.2497 25.1481 21.244 25.1641 21.2417C25.18 21.2394 25.1994 21.2383 25.2222 21.2383C25.3042 21.2383 25.3669 21.2542 25.4102 21.2861C25.4557 21.3158 25.4922 21.3761 25.5195 21.4673L26.3979 24.082Z"
      fill={props.fill}
    />
  </svg>
);

CoordinaterotationconversionDcmtorpy.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default CoordinaterotationconversionDcmtorpy;
