import { Port } from '@collimator/model-schemas-ts';
import {
  HoverEntity,
  HoverEntityType,
  MouseActions,
} from 'app/common_types/MouseTypes';
import { PortSide } from 'app/common_types/PortTypes';
import { isLinkDependent } from '../isLinkDependent';
import { RendererState } from '../modelRenderer';

export const isEntityInteractable = (
  rs: RendererState,
  entity: HoverEntity,
): boolean => {
  switch (entity.entityType) {
    case HoverEntityType.Port:
      // allow interaction with any port in idle state
      // so that we can drag connected links off of a port
      if (rs.mouseState.state === MouseActions.Idle) {
        return true;
      }

      const nodeID = entity.port.blockUuid;
      const connectedPorts = rs.refs.current.connectedPortLUT[nodeID] || [];
      const portIsConnected = Boolean(
        connectedPorts.find(
          (connPort) =>
            connPort.side === entity.port.side &&
            connPort.portId === entity.port.portId,
        ),
      );

      const nodeIndex = rs.refs.current.nodesIndexLUT[nodeID];
      const node = rs.refs.current.nodes[nodeIndex];
      const portsList =
        entity.port.side === PortSide.Input ? node.inputs : node.outputs;
      const entityRealPort = portsList[entity.port.portId];

      // temporary hack - this should of course be refined once acausal ports UX is solidified
      if (
        entityRealPort &&
        entityRealPort.variant?.variant_kind === 'acausal'
      ) {
        if (
          rs.mouseState.state === MouseActions.DrawingLinkFromEnd ||
          rs.mouseState.state === MouseActions.DrawingLinkFromStart
        ) {
          const linkIndex =
            rs.refs.current.linksIndexLUT[rs.mouseState.linkUuid];
          const link = rs.refs.current.links[linkIndex];
          if (!link) return false;

          const oppositeEndIsSrc =
            rs.mouseState.state === MouseActions.DrawingLinkFromEnd;
          const oppositeEnd = oppositeEndIsSrc ? link.src : link.dst;
          if (!oppositeEnd) return true;

          const oppositeNodeIndex =
            rs.refs.current.nodesIndexLUT[oppositeEnd.node];
          const oppositeNode = rs.refs.current.nodes[oppositeNodeIndex];
          let oppositePortsList: Port[] = [];
          if (oppositeEnd.port_side !== undefined) {
            oppositePortsList =
              oppositeEnd.port_side === 'outputs'
                ? oppositeNode.outputs
                : oppositeNode.inputs;
          } else {
            oppositePortsList = oppositeEndIsSrc
              ? oppositeNode.outputs
              : oppositeNode.inputs;
          }
          const oppositeRealPort = oppositePortsList[oppositeEnd.port];

          if (
            oppositeRealPort?.variant?.variant_kind !== 'acausal' ||
            oppositeRealPort?.variant.acausal_domain !==
              entityRealPort?.variant.acausal_domain
          ) {
            return false;
          }
        }

        return true;
      }

      // an occupied (connected) port cannot be clicked to make a new link
      // or finish a connection
      if (portIsConnected) return false;

      if (
        rs.mouseState.state === MouseActions.DrawingLinkFromEnd ||
        rs.mouseState.state === MouseActions.DrawingLinkFromStart
      ) {
        const linkIndex = rs.refs.current.linksIndexLUT[rs.mouseState.linkUuid];
        const link = rs.refs.current.links[linkIndex];

        if (!link) return false;

        if (
          rs.mouseState.state === MouseActions.DrawingLinkFromEnd &&
          entity.port.side !== PortSide.Input
        )
          return false;
        if (
          rs.mouseState.state === MouseActions.DrawingLinkFromStart &&
          entity.port.side !== PortSide.Output
        )
          return false;
      }
      break;
    case HoverEntityType.Link:
    case HoverEntityType.FakeLinkSegment:
    case HoverEntityType.TapPoint:
      if (rs.mouseState.state === MouseActions.DrawingLinkFromStart) {
        const hoverLinkIndex = rs.refs.current.linksIndexLUT[entity.linkUuid];
        const hoverLink = rs.refs.current.links[hoverLinkIndex];
        const tappingLinkIndex =
          rs.refs.current.linksIndexLUT[rs.mouseState.linkUuid];
        const tappingLink = rs.refs.current.links[tappingLinkIndex];

        if (!hoverLink || !tappingLink) return false;

        // a link cannot tap a link that would result in
        // an algebraic loop (connecting an input to an output of the same node)
        if (hoverLink.src && hoverLink.src.node === tappingLink.dst?.node) {
          return false;
        }

        // a link cannot (for slightly more reasons) tap a link
        // that would cause a circular tapping dependency
        // (impossible to render, algebraic loop etc.)
        if (isLinkDependent(tappingLink.uuid, hoverLink.uuid, rs)) {
          return false;
        }
      }

      // a link cannot end with a tap
      if (rs.mouseState.state === MouseActions.DrawingLinkFromEnd) {
        return false;
      }

      break;
  }

  return true;
};
