/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Project: React.FC<Props> = (props) => (
  <svg viewBox="0 0 24 24" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M8.88387 5.32321C8.39571 4.83505 7.60426 4.83505 7.1161 5.32321L4.82321 7.6161C4.33505 8.10426 4.33505 8.89571 4.82321 9.38387L14.6161 19.1768C15.1043 19.6649 15.8957 19.6649 16.3839 19.1768L18.6768 16.8839C19.1649 16.3957 19.1649 15.6043 18.6768 15.1161L8.88387 5.32321ZM6.06065 8.49999L7.99999 6.56065L8.68933 7.24999L8.46966 7.46966C8.17676 7.76255 8.17676 8.23742 8.46966 8.53032C8.76255 8.82321 9.23742 8.82321 9.53032 8.53032L9.74999 8.31065L10.4393 8.99999L9.96966 9.46966C9.67676 9.76255 9.67676 10.2374 9.96966 10.5303C10.2625 10.8232 10.7374 10.8232 11.0303 10.5303L11.5 10.0606L12.2169 10.7776L11.9697 11.0248C11.6768 11.3177 11.6768 11.7926 11.9697 12.0855C12.2625 12.3784 12.7374 12.3784 13.0303 12.0855L13.2776 11.8382L13.9393 12.5L13.4697 12.9697C13.1768 13.2625 13.1768 13.7374 13.4697 14.0303C13.7625 14.3232 14.2374 14.3232 14.5303 14.0303L15 13.5606L15.6893 14.25L15.4697 14.4697C15.1768 14.7625 15.1768 15.2374 15.4697 15.5303C15.7625 15.8232 16.2374 15.8232 16.5303 15.5303L16.75 15.3106L17.4393 16L15.5 17.9393L6.06065 8.49999Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M8.59966 14.0303L7.68107 14.9489L9.05107 16.3189L9.96965 15.4003L11.0303 16.461L9.47254 18.0187L6.36175 19.2631C5.34136 19.6712 4.32876 18.6586 4.73691 17.6382L5.98123 14.5274L7.539 12.9697L8.59966 14.0303ZM7.71647 17.1056L6.34627 17.6537L6.89435 16.2835L7.71647 17.1056Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M14.9003 10.4697L16.0926 9.27738L14.7226 7.90739L13.5303 9.09967L12.4696 8.03901L14.1923 6.3164C14.676 5.83263 15.3094 5.43786 15.9933 5.30108C16.7034 5.15906 17.4778 5.30047 18.0886 5.91127C18.6995 6.5221 18.8409 7.29648 18.6989 8.00659C18.5621 8.6905 18.1673 9.32394 17.6836 9.80771L15.961 11.5303L14.9003 10.4697ZM17.0741 8.13754L15.8624 6.9259C16.0079 6.85116 16.152 6.79906 16.2875 6.77195C16.5902 6.71142 16.8286 6.77255 17.028 6.97198C17.2274 7.17135 17.2886 7.40974 17.228 7.7124C17.2009 7.84795 17.1488 7.99208 17.0741 8.13754Z"
      fill={props.fill}
    />
  </svg>
);

Project.defaultProps = {
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Project;
