import { t } from '@lingui/macro';

export function extractErrorMessage(serverError: any): string {
  let serverErrorMessage = '';
  if (serverError) {
    if (typeof serverError === 'string') {
      serverErrorMessage = serverError;
    } else if (serverError.message) {
      serverErrorMessage = serverError.message;
    } else if (serverError.data?.message) {
      serverErrorMessage = serverError.data.message;
    } else if (serverError.error) {
      if (serverError.originalStatus) {
        const typeOfError = typeof serverError.error;
        const serverErrorString =
          typeOfError === 'string'
            ? serverError.error
            : JSON.stringify(serverError.error, null, 2);

        serverErrorMessage = t({
          id: 'notifications.errorWithStatusAndMessage',
          message:
            'An error occurred with status code {statusCode}: {errorMessage}',
          values: {
            statusCode: serverError.originalStatus,
            errorMessage: serverErrorString,
          },
        });
      } else {
        serverErrorMessage = serverError.error;
      }
    } else {
      serverErrorMessage = t({
        id: 'notifications.unexpectedError',
        message: 'An unexpected error occurred.',
      });
    }
  }
  return serverErrorMessage;
}
