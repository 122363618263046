/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  className?: string;
}

const Magnetic: React.FC<Props> = (props) => (
  <svg viewBox="0 0 8 8" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.97765 4.84898C2.98477 5.09895 2.99226 5.14298 2.99652 5.24997C3.002 5.38794 2.8881 5.49999 2.75003 5.5L1.25003 5.5C1.11195 5.50001 1.00471 5.3878 0.995678 5.25003C0.991884 5.19216 0.986077 5.17036 0.980181 4.9735C0.97258 4.71974 0.964829 4.46099 0.961048 4.17739C0.953088 3.5804 0.966287 2.94466 1.08194 2.36638C1.19836 1.78431 1.43826 1.1581 1.96493 0.685445C2.50437 0.201332 3.20733 0 4.00003 0C4.79272 0 5.49568 0.201332 6.03512 0.685446C6.56179 1.1581 6.80169 1.78431 6.9181 2.36638C7.03376 2.94466 7.04696 3.5804 7.039 4.17739C7.03522 4.46097 7.02747 4.71971 7.01987 4.97346C7.01397 5.17034 7.00817 5.19214 7.00437 5.25002C6.99534 5.3878 6.8881 5.5 6.75003 5.5H5.25003C5.11196 5.5 4.99805 5.38794 5.00353 5.24998C5.00779 5.14297 5.01527 5.09895 5.0224 4.84897C5.0296 4.59619 5.03643 4.35641 5.03918 4.15073C5.04684 3.57585 5.02879 3.11784 4.95694 2.75862C4.88586 2.40319 4.782 2.24815 4.6993 2.17393C4.62936 2.11117 4.45733 2 4.00003 2C3.54272 2 3.37068 2.11117 3.30075 2.17393C3.21804 2.24815 3.11419 2.40319 3.0431 2.75862C2.97126 3.11784 2.95321 3.57585 2.96087 4.15073C2.96361 4.35635 2.97045 4.59627 2.97765 4.84898Z"
      fill={props.fill}
    />
    <path
      d="M1.25004 8C1.11196 8 1.00004 7.88807 1.00004 7.75L1.00004 6.75C1.00004 6.61193 1.11196 6.5 1.25004 6.5H2.75004C2.88811 6.5 3.00004 6.61193 3.00004 6.75V7.75C3.00004 7.88807 2.88811 8 2.75004 8H1.25004Z"
      fill={props.fill}
    />
    <path
      d="M5.25004 6.5C5.11197 6.5 5.00004 6.61193 5.00004 6.75V7.75C5.00004 7.88807 5.11197 8 5.25004 8H6.75004C6.88811 8 7.00004 7.88807 7.00004 7.75V6.75C7.00004 6.61193 6.88811 6.5 6.75004 6.5H5.25004Z"
      fill={props.fill}
    />
  </svg>
);

Magnetic.defaultProps = {
  width: '8',
  height: '8',
  viewBox: '0 0 8 8',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  className: '',
};

export default Magnetic;
