import styled from '@emotion/styled/macro';
import { t } from '@lingui/macro';
import { useModelSimulationResults } from 'app/api/useModelSimulationResults';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
  entityPreferencesActions,
  selectEntityPrefs,
} from 'app/slices/entityPreferencesSlice';
import { projectActions } from 'app/slices/projectSlice';
import { uiFlagsActions } from 'app/slices/uiFlagsSlice';
import { getIsCurrentDiagramReadonly } from 'app/utils/modelDiagramUtils';
import React, { useCallback, useEffect, useState } from 'react';
import { customStripeContext } from 'ui/common/CustomStripeProvider';
import { Check, Play, RunAll, Stop, Tune } from 'ui/common/Icons/Standard';
import { useModal } from 'ui/common/Modal/useModal';
import { Spinner } from 'ui/common/Spinner';
import { useNotifications } from 'ui/common/notifications/useNotifications';
import { sindyFitTimeoutssStore } from 'ui/modelEditor/SindyFitButton';
import { OptimizerModal } from 'ui/modelEditor/optimizations/OptimizerModal';
import { useModelSimulationControls } from 'ui/modelEditor/useModelSimulationControls';
import { useModelPermission } from 'ui/permission/useModelPermission';
import { PaymentModal } from 'ui/subscriptions/PaymentModal';
import {
  ENSEMBLE_SIM_PREFS_V1_KEY,
  EnsembleSimPrefsV1,
  defaultEnsembleSimPrefsV1,
} from 'ui/userPreferences/ensembleSimModelPrefs';
import {
  OPTIMIZER_MODAL_PREFS_V1_KEY,
  OptimizerModalSavedPrefs,
  defaultOptimizerModalPrefs,
} from 'ui/userPreferences/optimizerModalPrefs';
import useEntityPreferences from 'ui/userPreferences/useEntityPreferences';
import { useAppParams } from 'util/useAppParams';
import { NavbarButton } from './NavbarButtons';
import { SignalTypePoller } from './SignalTypePoller';
import SimulationStatusPoller from './SimulationStatusPoller';
import EnsembleSimModal from './ensemble/EnsembleSimModal';
import {
  getDownloadDataButtonIcon,
  useDownloadResults,
} from './useDownloadResults';

enum SimulationBeingRunType {
  Normal,
  Ensemble,
  Unknown,
}

const DefaultCheck = styled(Check)`
  & > * {
    fill: ${({ theme }) => theme.colors.grey[10]};
  }
`;

const DisabledCheck = styled(Check)`
  & > * {
    fill: ${({ theme }) => theme.colors.grey[30]};
  }
`;

const DefaultRun = styled(Play)`
  & > * {
    fill: ${({ theme }) => theme.colors.grey[10]};
  }
`;

const DisabledRun = styled(Play)`
  & > * {
    fill: ${({ theme }) => theme.colors.grey[30]};
  }
`;

const DefaultRunAll = styled(RunAll)`
  & > * {
    fill: ${({ theme }) => theme.colors.grey[10]};
  }
`;

const DisabledRunAll = styled(RunAll)`
  & > * {
    fill: ${({ theme }) => theme.colors.grey[30]};
  }
`;

function getCheckButtonIcon(
  enableCheckButton: boolean,
  isChecking: boolean,
): React.FC {
  if (isChecking) {
    return Spinner;
  }

  if (enableCheckButton) {
    return DefaultCheck;
  }

  return DisabledCheck;
}

function getRunButtonIcon(
  enableRunButton: boolean,
  isRunning: boolean,
): React.FC {
  if (isRunning) {
    return Spinner;
  }

  if (enableRunButton) {
    return DefaultRun;
  }

  return DisabledRun;
}

function getRunAllButtonIcon(
  enableRunButton: boolean,
  isRunning: boolean,
): React.FC {
  if (isRunning) {
    return Spinner;
  }

  if (enableRunButton) {
    return DefaultRunAll;
  }

  return DisabledRunAll;
}

export const SimulationControls: React.FC = () => {
  const dispatch = useAppDispatch();
  const { showInfo } = useNotifications();
  const { triggerModal, closeModal } = useModal();
  const { subscriptionStatus } = React.useContext(customStripeContext);

  const [runningSimulationType, setRunningSimulationType] =
    React.useState<SimulationBeingRunType>(SimulationBeingRunType.Unknown);

  const modelDataUpdating = useAppSelector(
    (state) => state.uiFlags.modelDataUpdating,
  );
  const simConfigDataUpdating = useAppSelector(
    (state) => state.uiFlags.simConfigDataUpdating,
  );
  const isUpdating = modelDataUpdating || simConfigDataUpdating;
  // Hack to run sims when user clicks on Run button while editing.
  // Updating model onChange of input would make this unnecessary,
  // but seems there's been historical bugs that forced a switch to onSubmit
  const [simQueued, setSimQueued] = useState(false);

  const ensembleSimRecordedSignals = useAppSelector(
    (state) =>
      state.project.simulationEnsembleRequest?.modelOverrides.recorded_signals
        ?.signal_ids,
  );

  const runSim = useCallback(() => {
    setRunningSimulationType(SimulationBeingRunType.Normal);
    dispatch(
      uiFlagsActions.setUIFlag({
        forceOpenOutputTabOnSimComplete: false,
      }),
    );
    dispatch(entityPreferencesActions.onUserSawBasicTutorial());
    dispatch(entityPreferencesActions.onUserSawInitialTutorial());
    dispatch(projectActions.requestRun({}));
  }, [dispatch]);

  useEffect(() => {
    if (!isUpdating && simQueued) {
      runSim();
      setSimQueued(false);
    }
  }, [isUpdating, runSim, simQueued]);

  const summonPaymentModal = React.useCallback(() => {
    if (subscriptionStatus?.in_trial || subscriptionStatus?.paid_and_active)
      return;

    triggerModal(
      <PaymentModal closeModal={closeModal} />,
      t`Your subscription is no longer active`,
    );
  }, [triggerModal, closeModal, subscriptionStatus]);

  const { dataDownloadLoading, downloadData } = useDownloadResults();

  const { projectId, modelId, versionId } = useAppParams();
  const { arePermissionsLoaded, canEditCurrentModelVersion } =
    useModelPermission(projectId, modelId, versionId);
  const loadedModelId = useAppSelector(
    (state) => state.modelMetadata.loadedModelId,
  );
  const referenceSubmodelId = useAppSelector(
    (state) => state.modelMetadata.currentDiagramSubmodelReferenceId,
  );
  const isDiagramReadonly = getIsCurrentDiagramReadonly({
    modelId,
    loadedModelId,
    referenceSubmodelId,
    arePermissionsLoaded,
    canEditCurrentModelVersion,
  });

  const correlationId = useAppSelector((state) => state.project.correlationId);
  const simulationId = useAppSelector(
    (state) => state.project.simulationSummary?.uuid,
  );
  const simulationSummary = useAppSelector(
    (state) => state.project.simulationSummary,
  );
  const modelName = useAppSelector((state) => state.model.present.name);

  const { optimizationEnabled } = useAppSelector(
    (state) => state.userOptions.options,
  );

  const modelUuid = useAppSelector(
    (state) => state.modelMetadata.loadedModelId,
  );

  useEntityPreferences(OPTIMIZER_MODAL_PREFS_V1_KEY, modelUuid);
  const optimizerModalPrefs: OptimizerModalSavedPrefs | undefined =
    useAppSelector((state) => {
      const prefs = selectEntityPrefs(
        state,
        OPTIMIZER_MODAL_PREFS_V1_KEY,
        modelUuid,
      );
      if (!prefs) return undefined;
      if (Object.keys(prefs).length === 0) return defaultOptimizerModalPrefs;
      return prefs;
    });

  useEntityPreferences(ENSEMBLE_SIM_PREFS_V1_KEY, modelUuid);
  const ensembleModalPrefs: EnsembleSimPrefsV1 | undefined = useAppSelector(
    (state) => {
      const prefs = selectEntityPrefs(
        state,
        ENSEMBLE_SIM_PREFS_V1_KEY,
        modelUuid,
      );
      if (!prefs) return undefined;
      if (Object.keys(prefs).length === 0) {
        dispatch(
          entityPreferencesActions.onUserUpdatedPrefs({
            preferencesKey: ENSEMBLE_SIM_PREFS_V1_KEY,
            entityId: modelUuid,
            prefs: defaultEnsembleSimPrefsV1,
          }),
        );
        return defaultEnsembleSimPrefsV1;
      }
      return prefs;
    },
  );

  const openEnsembleSimModal = () => {
    if (!ensembleModalPrefs) return;
    triggerModal(
      <EnsembleSimModal initPrefs={ensembleModalPrefs} />,
      t`Run Ensemble Simulation`,
      {
        preventOverflow: true,
      },
    );
  };

  const {
    shouldPollSimulationStatus,
    isRunning,
    isChecking,
    isStopping,
    enableCheckButton,
    enableRunButton,
    enableStopButton,
    enableDownloadDataButton,
    canLoadSignalResults,
  } = useModelSimulationControls();

  useModelSimulationResults();

  const autofitFlags: { [blockUuid: string]: boolean } = useAppSelector(
    (state) => state.uiFlags.autoFittingOrTuningBlockUUIDs,
  );
  const anySindyBlockAutoFitting = Object.keys(autofitFlags).find(
    (key) => autofitFlags[key],
  );

  if (versionId) return null;

  const invalidSub =
    subscriptionStatus &&
    !subscriptionStatus.in_trial &&
    !subscriptionStatus.paid_and_active;

  const isEnsembleRunning =
    isRunning && runningSimulationType === SimulationBeingRunType.Ensemble;

  const isNormalRunning =
    isRunning &&
    (runningSimulationType === SimulationBeingRunType.Normal ||
      Boolean(anySindyBlockAutoFitting));

  const runButtonEnabled = enableRunButton && !isRunning;

  let signalNames = ['continuous_results.csv'];

  if (runningSimulationType === SimulationBeingRunType.Ensemble) {
    signalNames =
      ensembleSimRecordedSignals?.map((signal) => `${signal}.npz`) ?? [];
    signalNames.push('time.npz');
  }

  const showOptimizerButton =
    canEditCurrentModelVersion && !isDiagramReadonly && optimizationEnabled;

  const openOptimizer = () => {
    if (!optimizerModalPrefs) return;
    triggerModal(
      <OptimizerModal initPrefs={optimizerModalPrefs} />,
      t({
        id: 'optimizerModal.title',
        message: 'Model Optimizer',
      }),
    );
  };

  return (
    <>
      {/* Compile button */}
      <NavbarButton
        tooltipText={t({
          id: 'navBar.compileModel',
          message: 'Compile',
        })}
        Icon={getCheckButtonIcon(enableCheckButton, isChecking)}
        isEnabled={enableCheckButton}
        onClickHandler={() => {
          if (invalidSub) {
            summonPaymentModal();
          } else {
            dispatch(projectActions.requestCheck({}));
          }
        }}
        testId="navbar-check-model-button"
      />

      {/* Run/Running button */}
      <NavbarButton
        tooltipText={t({
          id: 'simulationStatus.runSimulation',
          message: 'Run',
        })}
        Icon={getRunButtonIcon(runButtonEnabled, isNormalRunning)}
        isEnabled={runButtonEnabled}
        onClickHandler={() => {
          if (invalidSub) {
            summonPaymentModal();
          } else if (isUpdating) {
            setSimQueued(true);
          } else {
            runSim();
          }
        }}
        testId="navbar-run-simulation-button"
      />

      {/* Run/Running button */}
      <NavbarButton
        tooltipText={t({
          id: 'simulationStatus.runEnsembleSimulation',
          message: 'Run Ensemble Simulation',
        })}
        Icon={getRunAllButtonIcon(runButtonEnabled, isEnsembleRunning)}
        isEnabled={runButtonEnabled && ensembleModalPrefs !== undefined}
        onClickHandler={() => {
          if (invalidSub) {
            summonPaymentModal();
          } else {
            dispatch(
              uiFlagsActions.setUIFlag({
                forceOpenOutputTabOnSimComplete: true,
              }),
            );
            setRunningSimulationType(SimulationBeingRunType.Ensemble);
            openEnsembleSimModal();
          }
        }}
        testId="navbar-run-ensemble-simulation-button"
      />

      {showOptimizerButton && (
        <NavbarButton
          tooltipText={t({
            id: 'navBar.optimizer.label',
            message: 'Optimize',
          })}
          Icon={Tune}
          isEnabled={runButtonEnabled && optimizerModalPrefs !== undefined}
          onClickHandler={openOptimizer}
          testId="navbar-optimizer-button"
        />
      )}

      {/* Stop button */}
      {enableStopButton || anySindyBlockAutoFitting ? (
        <NavbarButton
          tooltipText={t({
            id: 'navBar.stopSimulation',
            message: 'Stop',
          })}
          Icon={isStopping ? Spinner : Stop}
          isEnabled={enableStopButton || Boolean(anySindyBlockAutoFitting)}
          onClickHandler={() => {
            if (anySindyBlockAutoFitting) {
              const fittingSindyBlockUuids = Object.keys(autofitFlags);
              fittingSindyBlockUuids.forEach((blockUuid) => {
                dispatch(
                  uiFlagsActions.unmarkBlockAutoFittingOrTuning(blockUuid),
                );
                showInfo(`Cancelled SINDy training`);

                const sindyFitPollTimeout =
                  sindyFitTimeoutssStore[blockUuid]?.pollTimeoutID;
                if (sindyFitPollTimeout !== undefined) {
                  window.clearTimeout(sindyFitPollTimeout);
                  sindyFitTimeoutssStore[blockUuid] = undefined;
                }
              });
            } else {
              dispatch(
                uiFlagsActions.setUIFlag({
                  forceOpenOutputTabOnSimComplete: false,
                }),
              );
            }
            dispatch(projectActions.requestStop());
          }}
          testId="navbar-stop-simulation-button"
        />
      ) : (
        <NavbarButton
          tooltipText={t({
            id: 'navBar.downloadData',
            message: 'Download simulation results',
          })}
          Icon={getDownloadDataButtonIcon(
            enableDownloadDataButton,
            dataDownloadLoading,
          )}
          isEnabled={
            !!simulationSummary &&
            enableDownloadDataButton &&
            simulationSummary?.results_available &&
            runningSimulationType !== SimulationBeingRunType.Unknown
          }
          onClickHandler={() =>
            simulationSummary &&
            downloadData({
              simulationId: simulationSummary.uuid,
              modelId: simulationSummary.model_uuid,
              modelName,
              signalNames,
              // FIXME: downsample ensemble results (npz)
              downsamplingAlgorithm:
                runningSimulationType === SimulationBeingRunType.Ensemble
                  ? 'none'
                  : undefined,
            })
          }
          testId="navbar-download-simulation-results-button"
        />
      )}

      {shouldPollSimulationStatus &&
        modelId &&
        correlationId &&
        simulationId && (
          <SimulationStatusPoller
            modelId={modelId}
            correlationId={correlationId}
            simulationId={simulationId}
          />
        )}
      {modelId && correlationId && simulationId && canLoadSignalResults && (
        <SignalTypePoller
          modelId={modelId}
          correlationId={correlationId}
          simulationId={simulationId}
        />
      )}
    </>
  );
};
