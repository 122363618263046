import { PortSide } from 'app/common_types/PortTypes';
import { NodeInstance } from 'app/generated_types/SimulationModel';

const getPortNames = (
  ports: { name?: string }[],
  portSide: PortSide,
  excludedPort?: { id: number; side: PortSide },
): string[] =>
  ports
    .filter(
      (port, index) =>
        !excludedPort ||
        !(index === excludedPort.id && excludedPort.side === portSide),
    )
    .map((port) => port.name)
    .filter((n) => n) as string[];

export const getPortNamesInScope = (
  node: NodeInstance,
  portToExclude?: { id: number; side: PortSide },
) => {
  const existingInPortNames = getPortNames(
    node.inputs,
    PortSide.Input,
    portToExclude,
  );
  const existingOutPortNames = getPortNames(
    node.outputs,
    PortSide.Output,
    portToExclude,
  );
  const existingNames = [...existingInPortNames, ...existingOutPortNames];
  return existingNames;
};
