import { Coordinate } from 'app/common_types/Coordinate';

export const lineIntersect90Deg = (
  x1: number,
  y1: number,
  x2: number,
  y2: number,
  x3: number,
  y3: number,
  x4: number,
  y4: number,
): Coordinate | null => {
  const firstLineVertical = x1 == x2;
  const firstLineHorizontal = y1 == y2;
  if (!firstLineVertical && !firstLineHorizontal) return null; // not 90 degrees
  if (firstLineVertical && firstLineHorizontal) return null; // no length;

  const secondLineVertical = x3 == x4;
  const secondLineHorizontal = y3 == y4;
  if (!secondLineVertical && !secondLineHorizontal) return null; // not 90 degrees
  if (secondLineVertical && secondLineHorizontal) return null; // no length;

  // perpendicular case
  if (firstLineVertical && secondLineHorizontal) {
    const firstLineYMin = Math.min(y1, y2);
    const firstLineYMax = Math.max(y1, y2);
    const secondLineXMin = Math.min(x3, x4);
    const secondLineXMax = Math.max(x3, x4);

    if (
      firstLineYMin < y3 &&
      firstLineYMax > y3 &&
      secondLineXMin < x1 &&
      secondLineXMax > x1
    ) {
      return {
        x: x1,
        y: y3,
      };
    }
  }

  // perpendicular case
  if (firstLineHorizontal && secondLineVertical) {
    const firstLineXMin = Math.min(x1, x2);
    const firstLineXMax = Math.max(x1, x2);
    const secondLineYMin = Math.min(y3, y4);
    const secondLineYMax = Math.max(y3, y4);

    if (
      secondLineYMin < y1 &&
      secondLineYMax > y1 &&
      firstLineXMin < x3 &&
      firstLineXMax > x3
    ) {
      return {
        x: x3,
        y: y1,
      };
    }
  }

  // TODO: finish this
  // parallel overlapping case
  /*
  if (firstLineVertical && secondLineVertical) {
  }
  if (firstLineHorizontal && secondLineHorizontal) {
    const firstLineXMin = Math.min(x1, x2);
    const firstLineXMax = Math.max(x1, x2);
    const secondLineXMin = Math.min(x3, x4);
    const secondLineXMax = Math.max(x3, x4);

    if (
      firstLineXMin < secondLineXMin &&
      firstLineXMin > secondLineXMin
    ) {
    }
  }
  */

  return null;
};
