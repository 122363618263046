/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  cx?: any;
  cy?: any;
  r?: any;
  stroke?: any;
  strokeWidth?: any;
  strokeLinecap?: any;
  className?: string;
}

const AcausalRotationalSpeedsource: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <circle
      cx={props.cx}
      cy={props.cy}
      r={props.r}
      stroke={props.stroke}
      strokeWidth={props.strokeWidth}
    />
    <path
      d="M12.5535 12.5535C13.468 11.639 14.7083 11.1253 16.0016 11.1253C16.9234 11.1253 17.8183 11.3863 18.5875 11.8674L19.4 11.0549C18.4069 10.3726 17.2234 10 16.0016 10C14.4098 10 12.8833 10.6323 11.7578 11.7578C10.6323 12.8833 10 14.4098 10 16.0016C10 17.5933 10.6323 19.1198 11.7578 20.2453L13.5 16.5L11.5004 17.502C11.2357 17.2872 11.1253 16.7698 11.1253 16.0016C11.1253 14.7083 11.639 13.468 12.5535 12.5535Z"
      fill={props.fill}
    />
    <path
      d="M19.4464 19.4467C18.5319 20.3611 17.2916 20.8749 15.9983 20.8749C15.0877 20.8749 14.2034 20.6202 13.4405 20.1502L12.627 20.9638C13.6145 21.6343 14.7875 22.0002 15.9983 22.0002C17.59 22.0002 19.1166 21.3679 20.2421 20.2424C21.3676 19.1169 21.9999 17.5903 21.9999 15.9986C21.9999 14.4069 21.3676 12.8804 20.2421 11.7549L18.5 15.5L20.4995 14.4982C20.7642 14.713 20.8746 15.2304 20.8746 15.9986C20.8746 17.2919 20.3608 18.5322 19.4464 19.4467Z"
      fill={props.fill}
    />
    <path
      d="M11.5459 18.2718C11.0357 17.2714 10.8771 16.1284 11.0956 15.0269C11.3142 13.9254 11.8971 12.9296 12.7507 12.1998C13.6042 11.47 14.6785 11.0488 15.8006 11.004C16.9227 10.9592 18.0272 11.2934 18.9361 11.9529"
      stroke={props.stroke}
      strokeWidth="2"
    />
    <path
      d="M20.5874 14.0112C21.0207 15.0105 21.1158 16.124 20.8582 17.1823C20.6007 18.2406 20.0046 19.1859 19.1606 19.8744C18.3166 20.5629 17.2708 20.957 16.1823 20.9967C15.0938 21.0364 14.0222 20.7196 13.1302 20.0944"
      stroke={props.stroke}
      strokeWidth="2"
    />
    <path
      d="M2 16L4 16"
      stroke={props.stroke}
      strokeWidth={props.strokeWidth}
      strokeLinecap={props.strokeLinecap}
    />
    <path
      d="M28 16L30 16"
      stroke={props.stroke}
      strokeWidth={props.strokeWidth}
      strokeLinecap={props.strokeLinecap}
    />
  </svg>
);

AcausalRotationalSpeedsource.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  cx: '16',
  cy: '16',
  r: '11.25',
  stroke: '#1D3131',
  strokeWidth: '1.5',
  strokeLinecap: 'round',
  className: '',
};

export default AcausalRotationalSpeedsource;
