import { Coordinate } from 'app/common_types/Coordinate';
import {
  StateLinkInstance,
  StateNodeInstance,
} from 'app/generated_types/SimulationModel';
import { v4 as makeUuid } from 'uuid';
import { StateNodeSide } from './SMETypes';
import { STATENODE_HEIGHT, STATENODE_WIDTH } from './StateMachineNodeUI';

export const oppositeSide = (inputSide: StateNodeSide): StateNodeSide => {
  switch (inputSide) {
    case 'top':
      return 'down';
    case 'right':
      return 'left';
    case 'down':
      return 'top';
    case 'left':
      return 'right';
  }
};

export const getNodeSideWorldCoord = (
  node?: StateNodeInstance,
  side?: StateNodeSide,
  coord?: number,
): Coordinate | undefined => {
  if (node === undefined || side === undefined || coord === undefined) {
    return undefined;
  }

  switch (side) {
    case 'top':
      return { x: node.uiprops.x + coord, y: node.uiprops.y };
    case 'right':
      return { x: node.uiprops.x + STATENODE_WIDTH, y: node.uiprops.y + coord };
    case 'down':
      return {
        x: node.uiprops.x + coord,
        y: node.uiprops.y + STATENODE_HEIGHT,
      };
    case 'left':
      return { x: node.uiprops.x, y: node.uiprops.y + coord };
  }
};

export const getCoordsForLink = (
  link: StateLinkInstance,
  nodeLUT: { [id: string]: StateNodeInstance },
): { start: Coordinate; end: Coordinate } => {
  const start = getNodeSideWorldCoord(
    nodeLUT[link.sourceNodeId || ''],
    link.uiprops.sourceSide,
    link.uiprops.sourceCoord,
  ) || { x: 0, y: 0 };
  const end = getNodeSideWorldCoord(
    nodeLUT[link.destNodeId || ''],
    link.uiprops.destSide,
    link.uiprops.destCoord,
  ) || { x: 0, y: 0 };
  return { start, end };
};

export const createNewStateNode = (
  name: string,
  x: number,
  y: number,
): StateNodeInstance => ({
  uuid: makeUuid(),
  name,
  exit_priority_list: [],
  uiprops: {
    x,
    y,
  },
});

export const createNewStateLink = (
  start: {
    x: number;
    y: number;
  },
  end: {
    x: number;
    y: number;
  },
): StateLinkInstance => ({
  uuid: makeUuid(),
  uiprops: {
    startPoint: start,
    endPoint: end,
    curveDeviation: { x: 0, y: 0 },
  },
});

export type SMECoordBox = { x1: number; y1: number; x2: number; y2: number };
export type SMESizeBox = {
  x: number;
  y: number;
  width: number;
  height: number;
};

export const coordsToCoordBox = (
  c1: Coordinate,
  c2: Coordinate,
): SMECoordBox => {
  const minX = Math.min(c1.x, c2.x);
  const minY = Math.min(c1.y, c2.y);
  const maxX = Math.max(c1.x, c2.x);
  const maxY = Math.max(c1.y, c2.y);

  return {
    x1: minX,
    y1: minY,
    x2: maxX,
    y2: maxY,
  };
};
export const coordsToSizeBox = (c1: Coordinate, c2: Coordinate): SMESizeBox => {
  const coordBox = coordsToCoordBox(c1, c2);

  return {
    x: coordBox.x1,
    y: coordBox.y1,
    width: coordBox.x2 - coordBox.x1,
    height: coordBox.y2 - coordBox.y1,
  };
};

export const coordBoxAABB = (cb1: SMECoordBox, cb2: SMECoordBox): boolean => {
  if (
    cb1.x1 < cb2.x2 &&
    cb1.x2 > cb2.x1 &&
    cb1.y1 < cb2.y2 &&
    cb1.y2 > cb2.y1
  ) {
    return true;
  }

  return false;
};

export const pointInCoordBox = (point: Coordinate, box: SMECoordBox) =>
  point.x > box.x1 && point.x < box.x2 && point.y > box.y1 && point.y < box.y2;

export const stateNodeToCoordBox = (node: StateNodeInstance): SMECoordBox => ({
  x1: node.uiprops.x,
  y1: node.uiprops.y,
  x2: node.uiprops.x + STATENODE_WIDTH,
  y2: node.uiprops.y + STATENODE_HEIGHT,
});

export const getEntitiesInBox = (
  selectionBox: SMECoordBox,
  nodes: Array<StateNodeInstance>,
  links: Array<StateLinkInstance>,
  nodeLUT: { [id: string]: StateNodeInstance },
): { linkIds: Array<string>; nodeIds: Array<string> } => {
  let nodeIds: Array<string> = [];

  for (let i = 0; i < nodes.length; i++) {
    const node = nodes[i];
    if (coordBoxAABB(selectionBox, stateNodeToCoordBox(node))) {
      nodeIds.push(node.uuid);
    }
  }

  let linkIds: Array<string> = [];

  for (let i = 0; i < links.length; i++) {
    const link = links[i];
    const { start, end } = getCoordsForLink(link, nodeLUT);
    if (pointInCoordBox(start, selectionBox)) {
      linkIds.push(link.uuid);
      continue;
    }
    if (pointInCoordBox(end, selectionBox)) {
      linkIds.push(link.uuid);
      continue;
    }
  }

  return {
    linkIds,
    nodeIds,
  };
};
