/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  className?: string;
}

const SharedWithMe: React.FC<Props> = (props) => (
  <svg viewBox="0 0 24 24" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.46967 12.2197C8.17678 11.9268 8.17678 11.4519 8.46967 11.159C8.76256 10.8661 9.23744 10.8661 9.53033 11.159L11.25 12.8787L11.25 5.68933C11.25 5.27512 11.5858 4.93933 12 4.93933C12.4142 4.93933 12.75 5.27512 12.75 5.68933L12.75 12.8787L14.4697 11.159C14.7626 10.8661 15.2374 10.8661 15.5303 11.159C15.8232 11.4519 15.8232 11.9268 15.5303 12.2197L12 15.75L8.46967 12.2197Z"
      fill={props.fill}
    />
    <path
      d="M6.75 14.5C6.75 14.0858 6.41421 13.75 6 13.75C5.58579 13.75 5.25 14.0858 5.25 14.5V18C5.25 18.6904 5.80964 19.25 6.5 19.25H17.5C18.1904 19.25 18.75 18.6904 18.75 18V14.5C18.75 14.0858 18.4142 13.75 18 13.75C17.5858 13.75 17.25 14.0858 17.25 14.5V17.75H6.75V14.5Z"
      fill={props.fill}
    />
  </svg>
);

SharedWithMe.defaultProps = {
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  className: '',
};

export default SharedWithMe;
