/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const History: React.FC<Props> = (props) => (
  <svg viewBox="0 0 16 16" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M9.92188 4.53532C9.13812 4.10687 8.23334 3.9696 7.36172 4.14692C6.4901 4.32424 5.70556 4.80516 5.14177 5.50774C4.57799 6.21033 4.26985 7.0911 4.26985 8H3.22223C3.22223 6.84322 3.61441 5.72224 4.33196 4.82804C5.0495 3.93384 6.04801 3.32175 7.15734 3.09608C8.26668 2.87041 9.41821 3.0451 10.4157 3.5904C11.4132 4.13571 12.195 5.01787 12.6279 6.08659C13.0607 7.15532 13.1178 8.34447 12.7895 9.45143C12.4611 10.5584 11.7677 11.5147 10.8272 12.1573C9.88677 12.8 8.75753 13.0893 7.6319 12.9759C6.68078 12.8801 5.78197 12.5018 5.04364 11.8933C4.81838 11.7077 4.81997 11.3659 5.02453 11.1567C5.22909 10.9475 5.55892 10.9509 5.78938 11.1298C6.35217 11.5665 7.02485 11.8382 7.73459 11.9096C8.61901 11.9987 9.50627 11.7714 10.2452 11.2665C10.9841 10.7615 11.529 10.0102 11.787 9.14041C12.045 8.27065 12.0001 7.33632 11.66 6.49661C11.3199 5.6569 10.7056 4.96377 9.92188 4.53532Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M2.15342 6.74103C2.35798 6.53182 2.68964 6.53182 2.8942 6.74103L3.75844 7.62491L4.62268 6.74103C4.82724 6.53182 5.1589 6.53182 5.36346 6.74103C5.56802 6.95024 5.56802 7.28943 5.36346 7.49864L3.75844 9.14014L2.15342 7.49864C1.94886 7.28943 1.94886 6.95024 2.15342 6.74103Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M8.11111 4.96429C8.4004 4.96429 8.63492 5.20414 8.63492 5.50001V7.46429H9.85714C10.1464 7.46429 10.381 7.70414 10.381 8C10.381 8.29587 10.1464 8.53572 9.85714 8.53572H8.46032C7.97817 8.53572 7.5873 8.13597 7.5873 7.64286V5.50001C7.5873 5.20414 7.82182 4.96429 8.11111 4.96429Z"
      fill={props.fill}
    />
  </svg>
);

History.defaultProps = {
  width: '16',
  height: '16',
  viewBox: '0 0 16 16',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default History;
