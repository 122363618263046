import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { generatedApi } from 'app/apiGenerated/generatedApi';
import { store } from 'app/store';
import { ChatMessageEdit } from 'ui/appBottomBar/assistant/ChatContextProvider';
import {
  ChatCompleteErrorPayload,
  ChatCompleteErrorTypes,
  ChatCompleteResponsePayload,
  ChatMessage,
  ChatMessageContent,
  ChatMessageRole,
  FinishReason,
  ToolCall,
} from '../third_party_types/chat-types';
import { WebSocketMessage } from '../third_party_types/websocket/websocket-message';

export interface CallbackResult {
  result?: string;
  imageUrl?: string;
  error?: string;
  toolChoice?: string;
}

export type ToolCompleteCallback = (result: CallbackResult) => void;

export type GptFunction = (
  args: object,
  onComplete: ToolCompleteCallback,
  abortSignal?: AbortSignal,
) => void;

interface ResponseStreamParserParams {
  onNewMessage(message: ChatMessage): void;
  onChunkReceived: (message: ChatMessageEdit, index?: number) => void;
  functions?: { [k: string]: GptFunction };
  abortSignal?: AbortSignal;
}

export const makeTextContent = (
  text: string,
  error?: string,
): ChatMessageContent => ({
  type: 'text',
  text,
  error,
});

const makeImageUrlContent = (imageUrl: string): ChatMessageContent => ({
  type: 'image_url',
  image_url: { url: imageUrl },
});

type ToolCallWithId = ToolCall & {
  tool_id: string;
};

type ToolCallFn = () => void;

export class ResponseStreamParser {
  params: ResponseStreamParserParams;

  // internal states
  streamUuid = '';

  fullContent = '';

  toolCalls: ToolCallWithId[] = [];

  toolCallIsDone: { [toolId: string]: boolean } = {};

  // outputs

  finishReason?: FinishReason;

  error?: string;

  toolCallQueue: ToolCallFn[] = [];

  constructor({
    onNewMessage,
    onChunkReceived,
    functions,
    abortSignal,
  }: ResponseStreamParserParams) {
    this.params = {
      onNewMessage,
      onChunkReceived,
      functions,
      abortSignal,
    };
  }

  start = (streamUuid: string) => {
    this.streamUuid = streamUuid;
  };

  onChatResponseReceived = async (data: WebSocketMessage) => {
    const { onNewMessage, onChunkReceived, abortSignal } = this.params;
    if (abortSignal && abortSignal.aborted) {
      this.abort();
      return;
    }

    const { content, finishReason, functionCall, streamUuid } =
      data.payload as ChatCompleteResponsePayload;

    if (!this.streamUuid || this.streamUuid !== streamUuid) {
      return;
    }

    if (finishReason) {
      if (finishReason === FinishReason.ToolCalls) {
        this.toolCalls.forEach((tool) => {
          try {
            this.handleToolCall(tool);
          } catch (e) {
            this.abort();
            this.toolDoneCallback(tool, {
              error: `Error while executing ${tool.name}:\n${e}`,
            });
          }
        });
      }
      this.finishReason = finishReason;
    } else if (functionCall) {
      const { name, arguments: args, index, tool_id: toolId } = functionCall;

      if (this.toolCalls.length === 0) {
        onNewMessage({
          role: ChatMessageRole.Assistant,
          content: [makeTextContent('')],
          toolCalls: [],
        });
      }

      if (name) {
        if (!toolId) {
          throw new Error('tool_id is required');
        }
        const toolCall: ToolCallWithId = {
          tool_id: toolId,
          type: 'function',
          name,
          arguments: '',
        };
        this.toolCallIsDone[toolId] = false;
        this.toolCalls.push(toolCall);
        onChunkReceived({
          toolCalls: [...this.toolCalls],
        });
      }
      if (args) {
        if (index === undefined) {
          throw new Error('index is required');
        }
        const oldToolCall = this.toolCalls[index];
        const newToolCall = {
          ...oldToolCall,
          arguments: `${oldToolCall.arguments}${args}`,
        };
        this.toolCalls[index] = newToolCall;
        onChunkReceived({
          toolCalls: [...this.toolCalls],
        });
      }
    } else if (content) {
      if (this.fullContent === '') {
        onNewMessage({
          role: ChatMessageRole.Assistant,
          content: [makeTextContent('')],
        });
      }
      this.fullContent = `${this.fullContent}${content}`;
      const textContent = [makeTextContent(this.fullContent)];
      onChunkReceived({
        content: textContent,
      });
    }
  };

  onInternalError = (data: WebSocketMessage) => {
    this.error = 'Internal Error';
    this.finishReason = FinishReason.Error;
    if (process.env.NODE_ENV === 'development') {
      // eslint-disable-next-line no-console
      console.error('Chat internal error:', data.payload);
    }
  };

  onError = (data: WebSocketMessage) => {
    try {
      const payload = JSON.parse(
        data.payload as string,
      ) as ChatCompleteErrorPayload;
      if (payload.type === ChatCompleteErrorTypes.OpenAiKeyNotFound) {
        this.error = 'Please configure your OpenAI API key.';
      } else if (
        payload.type === ChatCompleteErrorTypes.ChatCallCountExceeded
      ) {
        this.error = 'Call count exceeded. Please register an API key.';
      } else if (payload.error.includes('status code: 404')) {
        // the user doesn't have access to the gpt model
        this.error = payload.error;
      } else if (payload.error.includes('status code: 401')) {
        this.error = 'Invalid OpenAI API key.';
      } else if (
        payload.error.includes('status code: 400') &&
        payload.error.includes(
          'Please reduce the length of the messages or functions.',
        )
      ) {
        this.error = 'Maximum token count reached';
      } else if (payload.type === ChatCompleteErrorTypes.ChatImageInvalid) {
        this.error = 'Image is invalid. Try re-uploading.';
      } else {
        this.error = 'Internal Error';
      }
    } catch (e) {
      if (process.env.NODE_ENV === 'development') {
        // eslint-disable-next-line no-console
        console.debug('Error parsing error message:', e);
      }
      this.error = 'Internal Error';
    }
  };

  abort = async () => {
    this.error = 'abort';
    const result = await store.dispatch(
      generatedApi.endpoints.postChatAbort.initiate({
        streamUuid: this.streamUuid,
      }),
    );
    if ((result as FetchBaseQueryError).data) {
      this.error = 'Failed to abort the chat session.';
    }
  };

  isDone = () => {
    if (this.error) {
      return true;
    }
    if (this.finishReason) {
      if (this.finishReason === FinishReason.ToolCalls) {
        return this.toolCalls.every(
          (tool) => this.toolCallIsDone[tool.tool_id],
        );
      }
      return true;
    }
    return false;
  };

  toolDoneCallback = (tool: ToolCallWithId, callbackResult: CallbackResult) => {
    const content = [
      makeTextContent(callbackResult.result || '', callbackResult.error),
    ];

    this.params.onNewMessage({
      role: ChatMessageRole.Tool,
      toolCallId: tool.tool_id,
      content,
      toolChoice: callbackResult.toolChoice,
    });
    // if the tool returns an image, add it as a hidden message with role "User"
    // so that chatgpt can see it
    if (callbackResult.imageUrl) {
      this.params.onNewMessage({
        role: ChatMessageRole.User,
        content: [makeImageUrlContent(callbackResult.imageUrl)],
        hidden: true,
      });
    }
    this.toolCallIsDone[tool.tool_id] = true;
  };

  handleToolCall = (tool: ToolCallWithId) => {
    const { functions, abortSignal } = this.params;

    // HACK: sometimes chatgpt returns functionName = python and functionArgs
    // will contain the python code.
    if (tool.name === 'python') {
      console.warn('Force execute_python');
      tool.name = 'execute_python';
      tool.arguments = tool.arguments.replace(/\r?\n/g, '\\n');
      tool.arguments = tool.arguments.replace(/"/g, '\\"');
      tool.arguments = `{ "code": "${tool.arguments}" }`;
    }

    if (!functions) {
      throw new Error(
        `No functions definition provided, cannot execute tool ${tool.name}`,
      );
    }
    const func = functions[tool.name];
    if (!func) {
      throw new Error(`Unknown function: ${tool.name}`);
    }

    let argsJson = {};
    try {
      argsJson = JSON.parse(tool.arguments);
    } catch (e) {
      this.toolDoneCallback(tool, {
        error: `Error while parsing ${tool.name} args:\n${e}\nMake sure the args are valid JSON: ${tool.arguments}`,
      });
    }

    this.toolCallQueue.push(async () => {
      await func(
        argsJson,
        (callbackResult: CallbackResult) =>
          this.toolDoneCallback(tool, callbackResult),
        abortSignal,
      );
    });
  };
}
