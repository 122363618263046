/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Project: React.FC<Props> = (props) => (
  <svg viewBox="0 0 16 16" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M6.04042 3.66666C5.6499 3.27613 5.01674 3.27613 4.62621 3.66666L3.33332 4.95955C2.94279 5.35007 2.94279 5.98324 3.33332 6.37376L5.316 8.35645L3.9875 9.68495L3.08574 11.9394C2.84085 12.5516 3.44841 13.1591 4.06064 12.9143L6.31504 12.0125L7.64354 10.684L9.62621 12.6667C10.0167 13.0572 10.6499 13.0572 11.0404 12.6667L12.3333 11.3738C12.7238 10.9832 12.7238 10.3501 12.3333 9.95955L10.3506 7.97688L11.7891 6.53847C12.1116 6.21596 12.3747 5.79366 12.4659 5.33773C12.5606 4.86432 12.4664 4.34806 12.0591 3.94085C11.6519 3.53365 11.1356 3.43937 10.6622 3.53405C10.2063 3.62524 9.78402 3.88842 9.46151 4.21093L8.02311 5.64934L6.04042 3.66666ZM8.73021 6.35645L9.64354 7.26977L10.7284 6.18492L9.81507 5.27159L8.73021 6.35645ZM6.93644 9.97688L6.02311 9.06356L5.12073 9.96593L6.03406 10.8793L6.93644 9.97688ZM4.04042 5.66666L5.33332 4.37376L5.79288 4.83332L5.64643 4.97977C5.45117 5.17503 5.45117 5.49162 5.64643 5.68688C5.84169 5.88214 6.15828 5.88214 6.35354 5.68688L6.49998 5.54043L6.95954 5.99999L6.64643 6.3131C6.45117 6.50837 6.45117 6.82495 6.64643 7.02021C6.84169 7.21547 7.15828 7.21547 7.35354 7.02021L7.66665 6.7071L8.14461 7.18506L7.97976 7.3499C7.7845 7.54516 7.7845 7.86174 7.97976 8.05701C8.17503 8.25227 8.49161 8.25227 8.68687 8.05701L8.85172 7.89216L9.29288 8.33332L8.97976 8.64644C8.7845 8.8417 8.7845 9.15828 8.97976 9.35354C9.17503 9.54881 9.49161 9.54881 9.68687 9.35354L9.99998 9.04043L10.4595 9.49999L10.3131 9.64644C10.1178 9.8417 10.1178 10.1583 10.3131 10.3535C10.5084 10.5488 10.8249 10.5488 11.0202 10.3535L11.1667 10.2071L11.6262 10.6667L10.3333 11.9596L4.04042 5.66666ZM5.14433 11.4037L4.23087 11.7691L4.59625 10.8557L5.14433 11.4037ZM11.3827 5.42503L10.575 4.61727C10.6719 4.56744 10.768 4.53271 10.8584 4.51463C11.0601 4.47428 11.2191 4.51504 11.352 4.64798C11.485 4.7809 11.5257 4.93982 11.4854 5.1416C11.4673 5.23197 11.4325 5.32805 11.3827 5.42503Z"
      fill={props.fill}
    />
  </svg>
);

Project.defaultProps = {
  width: '16',
  height: '16',
  viewBox: '0 0 16 16',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Project;
