import { messages as en } from './en/messages';

// types copied from @linguijs/core
export type CompiledMessage =
  | string
  | Array<
      string | Array<string | (string | undefined) | Record<string, unknown>>
    >;
export type Messages = Record<string, CompiledMessage>;

const allMessages: Record<string, Messages> = {
  en,
};

export default allMessages;
