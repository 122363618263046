/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  className?: string;
}

const ExecutionOrder: React.FC<Props> = (props) => (
  <svg viewBox="0 0 24 24" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.097 8.85701C17.085 8.86901 17.082 8.88101 17.088 8.89301C17.094 8.90501 17.106 8.91101 17.124 8.91101H19.563C19.593 8.91101 19.617 8.92301 19.635 8.94701C19.659 8.96501 19.671 8.98901 19.671 9.01901V9.89201C19.671 9.92201 19.659 9.94901 19.635 9.97301C19.617 9.99101 19.593 10 19.563 10H15.513C15.483 10 15.456 9.99101 15.432 9.97301C15.414 9.94901 15.405 9.92201 15.405 9.89201V9.06401C15.405 9.01001 15.423 8.96501 15.459 8.92901C15.753 8.64101 16.053 8.32901 16.359 7.99301C16.665 7.65101 16.857 7.43801 16.935 7.35401C17.103 7.15601 17.274 6.96701 17.448 6.78701C17.994 6.18101 18.267 5.73101 18.267 5.43701C18.267 5.22701 18.192 5.05601 18.042 4.92401C17.892 4.78601 17.697 4.71701 17.457 4.71701C17.217 4.71701 17.022 4.78601 16.872 4.92401C16.722 5.05601 16.647 5.23301 16.647 5.45501V5.68001C16.647 5.71001 16.635 5.73701 16.611 5.76101C16.593 5.77901 16.569 5.78801 16.539 5.78801H15.477C15.447 5.78801 15.42 5.77901 15.396 5.76101C15.378 5.73701 15.369 5.71001 15.369 5.68001V5.25701C15.387 4.93301 15.489 4.64801 15.675 4.40201C15.861 4.15001 16.107 3.95801 16.413 3.82601C16.725 3.69401 17.073 3.62801 17.457 3.62801C17.883 3.62801 18.252 3.70901 18.564 3.87101C18.882 4.02701 19.125 4.24001 19.293 4.51001C19.467 4.78001 19.554 5.08001 19.554 5.41001C19.554 5.66201 19.491 5.92001 19.365 6.18401C19.239 6.44801 19.05 6.73301 18.798 7.03901C18.612 7.27301 18.411 7.50401 18.195 7.73201C17.979 7.96001 17.658 8.29001 17.232 8.72201L17.097 8.85701Z"
      fill={props.fill}
    />
    <path
      d="M5.526 3.727C5.556 3.709 5.601 3.7 5.661 3.7H6.75C6.78 3.7 6.804 3.712 6.822 3.736C6.846 3.754 6.858 3.778 6.858 3.808V9.892C6.858 9.922 6.846 9.949 6.822 9.973C6.804 9.991 6.78 10 6.75 10H5.697C5.667 10 5.64 9.991 5.616 9.973C5.598 9.949 5.589 9.922 5.589 9.892V4.96C5.589 4.948 5.583 4.936 5.571 4.924C5.559 4.912 5.547 4.909 5.535 4.915L4.644 5.158L4.608 5.167C4.554 5.167 4.527 5.134 4.527 5.068L4.5 4.294C4.5 4.234 4.527 4.192 4.581 4.168L5.526 3.727Z"
      fill={props.fill}
    />
    <path
      d="M7.996 17.494C8.116 17.77 8.176 18.088 8.176 18.448C8.176 18.772 8.122 19.072 8.014 19.348C7.87 19.732 7.627 20.032 7.285 20.248C6.949 20.464 6.55 20.572 6.088 20.572C5.632 20.572 5.23 20.458 4.882 20.23C4.54 20.002 4.294 19.693 4.144 19.303C4.06 19.069 4.012 18.817 4 18.547C4 18.475 4.036 18.439 4.108 18.439H5.17C5.242 18.439 5.278 18.475 5.278 18.547C5.302 18.745 5.335 18.892 5.377 18.988C5.431 19.144 5.518 19.267 5.638 19.357C5.764 19.441 5.911 19.483 6.079 19.483C6.415 19.483 6.646 19.336 6.772 19.042C6.856 18.862 6.898 18.652 6.898 18.412C6.898 18.13 6.853 17.899 6.763 17.719C6.625 17.437 6.394 17.296 6.07 17.296C6.004 17.296 5.935 17.317 5.863 17.359C5.791 17.395 5.704 17.449 5.602 17.521C5.578 17.539 5.554 17.548 5.53 17.548C5.494 17.548 5.467 17.53 5.449 17.494L4.918 16.747C4.906 16.729 4.9 16.708 4.9 16.684C4.9 16.648 4.912 16.618 4.936 16.594L6.367 15.343C6.379 15.331 6.382 15.319 6.376 15.307C6.376 15.295 6.367 15.289 6.349 15.289H4.234C4.204 15.289 4.177 15.28 4.153 15.262C4.135 15.238 4.126 15.211 4.126 15.181V14.308C4.126 14.278 4.135 14.254 4.153 14.236C4.177 14.212 4.204 14.2 4.234 14.2H7.987C8.017 14.2 8.041 14.212 8.059 14.236C8.083 14.254 8.095 14.278 8.095 14.308V15.298C8.095 15.346 8.074 15.391 8.032 15.433L6.853 16.513C6.841 16.525 6.835 16.537 6.835 16.549C6.841 16.561 6.856 16.567 6.88 16.567C7.402 16.669 7.774 16.978 7.996 17.494Z"
      fill={props.fill}
    />
    <path
      d="M19.914 17.755C19.944 17.755 19.968 17.767 19.986 17.791C20.01 17.809 20.022 17.833 20.022 17.863V18.772C20.022 18.802 20.01 18.829 19.986 18.853C19.968 18.871 19.944 18.88 19.914 18.88H19.527C19.497 18.88 19.482 18.895 19.482 18.925V20.392C19.482 20.422 19.47 20.449 19.446 20.473C19.428 20.491 19.404 20.5 19.374 20.5H18.321C18.291 20.5 18.264 20.491 18.24 20.473C18.222 20.449 18.213 20.422 18.213 20.392V18.925C18.213 18.895 18.198 18.88 18.168 18.88H15.405C15.375 18.88 15.348 18.871 15.324 18.853C15.306 18.829 15.297 18.802 15.297 18.772V18.052C15.297 18.016 15.306 17.971 15.324 17.917L16.944 14.281C16.968 14.227 17.01 14.2 17.07 14.2H18.195C18.237 14.2 18.264 14.212 18.276 14.236C18.294 14.254 18.294 14.284 18.276 14.326L16.773 17.701C16.767 17.713 16.767 17.725 16.773 17.737C16.779 17.749 16.788 17.755 16.8 17.755H18.168C18.198 17.755 18.213 17.74 18.213 17.71V16.594C18.213 16.564 18.222 16.54 18.24 16.522C18.264 16.498 18.291 16.486 18.321 16.486H19.374C19.404 16.486 19.428 16.498 19.446 16.522C19.47 16.54 19.482 16.564 19.482 16.594V17.71C19.482 17.74 19.497 17.755 19.527 17.755H19.914Z"
      fill={props.fill}
    />
    <path
      d="M9.5 6.5C9.22386 6.5 9 6.72386 9 7C9 7.27614 9.22386 7.5 9.5 7.5H13.5C13.7761 7.5 14 7.27614 14 7C14 6.72386 13.7761 6.5 13.5 6.5H9.5Z"
      fill={props.fill}
    />
    <path
      d="M9 17.5C9 17.2239 9.22386 17 9.5 17H13.5C13.7761 17 14 17.2239 14 17.5C14 17.7761 13.7761 18 13.5 18H9.5C9.22386 18 9 17.7761 9 17.5Z"
      fill={props.fill}
    />
    <path
      d="M9.24275 13.0713C9.00596 13.2133 8.92918 13.5205 9.07125 13.7572C9.21333 13.994 9.52046 14.0708 9.75725 13.9287L13.8998 11.4432C14.1365 11.3012 14.2133 10.994 14.0713 10.7572C13.9292 10.5205 13.622 10.4437 13.3853 10.5857L9.24275 13.0713Z"
      fill={props.fill}
    />
  </svg>
);

ExecutionOrder.defaultProps = {
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  className: '',
};

export default ExecutionOrder;
