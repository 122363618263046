import { t } from '@lingui/macro';
import {
  ModelOverrides,
  ParameterSweep,
} from 'app/apiGenerated/generatedApiTypes';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { entityPreferencesActions } from 'app/slices/entityPreferencesSlice';
import React from 'react';
import Button from 'ui/common/Button/Button';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import TooltipButton from 'ui/common/Button/TooltipButton';
import { Plus, RunAll } from 'ui/common/Icons/Standard';
import { MonteCarloPrefs } from 'ui/userPreferences/ensembleSimModelPrefs';
import { useAppParams } from 'util/useAppParams';
import {
  ActionButtonContainer,
  BottomBar,
  InfoLabel,
  Spacer,
  TotalSimsInput,
  getParamSweeps,
  getValidationRules,
} from './EnsembleConfigs';
import OverridesList from './OverridesList';
import { SignalsAndPlotsConfig } from './SignalsAndPlotsConfig';

export interface MonteCarloConfigProps {
  prefs: MonteCarloPrefs;
  onRunAll: (modelOverrides: ModelOverrides) => void;
}

export const MonteCarloConfig: React.FC<MonteCarloConfigProps> = ({
  onRunAll,
  prefs,
}) => {
  const dispatch = useAppDispatch();

  const { maxQueuedSims } = useAppSelector(
    (state) => state.userOptions.options,
  );

  const modelId = useAppParams().modelId || '';

  const overridesKeys = Object.keys(prefs.overridenParams ?? []);

  const parameters = useAppSelector((state) => state.model.present.parameters);

  const defaultSetting = {
    type: 'Uniform',
    params: ['0', '1'],
  };

  const parameterSweeps: ParameterSweep[] = React.useMemo(
    () => getParamSweeps(parameters, prefs.overridenParams),
    [parameters, prefs.overridenParams],
  );

  const modelOverrides: ModelOverrides = React.useMemo(
    () => ({
      ensemble_config: {
        sweep_strategy: 'monte_carlo',
        model_parameter_sweeps: Object.values(parameterSweeps),
        num_sims: prefs.numSims,
      },
      // recorded_signals repurposed to carry viz on/off
      recorded_signals: {
        signal_ids: prefs.signals,
      },
    }),
    [parameterSweeps, prefs.numSims, prefs.signals],
  );

  const onAddParam = () => {
    const overridenParamId = parameters.find(
      (parameter) => !prefs.overridenParams[parameter.name],
    )?.name;
    if (overridenParamId)
      dispatch(
        entityPreferencesActions.onUserUpdatedEnsembleSimModalPrefs({
          modelId,
          tab: 'monteCarlo',
          prefs: {
            overridenParams: {
              ...prefs.overridenParams,
              [overridenParamId]: defaultSetting,
            },
          },
        }),
      );
  };

  const setNumSims = (value: number) => {
    dispatch(
      entityPreferencesActions.onUserUpdatedEnsembleSimModalPrefs({
        modelId,
        tab: 'monteCarlo',
        prefs: {
          numSims: value,
        },
      }),
    );
  };

  const hasTooManySimulations =
    prefs.numSims > maxQueuedSims && maxQueuedSims !== -1;
  const hasTooFewSimulations = prefs.numSims < 1;
  const validationsRules = getValidationRules(
    hasTooManySimulations,
    hasTooFewSimulations,
  );

  const allowedToRun =
    prefs.overridenParams &&
    !(hasTooManySimulations || hasTooFewSimulations) &&
    prefs.signals.length > 0;

  const plotOptions = useAppSelector(
    (state) => state.uiFlags.ensemblePlotOptions,
  );

  const runButtonDisabled =
    !allowedToRun ||
    prefs.signals.length === 0 ||
    !(
      plotOptions.iqr_error ||
      plotOptions.overlay ||
      plotOptions.standard_deviations
    );

  const invalidReason = React.useMemo(() => {
    if (!prefs.overridenParams || Object.keys(prefs.overridenParams).length < 1)
      return t`Please add a parameter.`;
    if (hasTooManySimulations) return t`Too many simulations.`;
    if (hasTooFewSimulations)
      return t`Please enter a valid number of simulations.`;
    if (!prefs.signals || prefs.signals.length === 0)
      return t`Please select signals to plot.`;
    if (
      !(
        plotOptions.iqr_error ||
        plotOptions.overlay ||
        plotOptions.standard_deviations
      )
    )
      return t`Must have at least one plot option selected`;
    return '';
  }, [
    prefs.overridenParams,
    prefs.signals,
    hasTooManySimulations,
    hasTooFewSimulations,
    plotOptions.iqr_error,
    plotOptions.overlay,
    plotOptions.standard_deviations,
  ]);

  return (
    <>
      <InfoLabel>{t`Enter distributions and ranges for each model parameter, and then the number of total simulations to run.`}</InfoLabel>
      {prefs.overridenParams && (
        <OverridesList
          sweepTypes={['Constant', 'Normal', 'Uniform']}
          ensembleType="monteCarlo"
          defaultSetting={defaultSetting}
          overrides={prefs.overridenParams}
        />
      )}
      <ActionButtonContainer>
        <Button
          variant={ButtonVariants.SmallSecondary}
          Icon={Plus}
          onClick={onAddParam}
          disabled={overridesKeys.length >= parameters.length}>
          {t`Add parameter`}
        </Button>
      </ActionButtonContainer>
      <SignalsAndPlotsConfig prefs={prefs} ensembleType="monteCarlo" />
      <BottomBar>
        <InfoLabel>{t`Total simulations to run:`}</InfoLabel>
        <TotalSimsInput
          hasBorder
          value={prefs.numSims >= 0 ? prefs.numSims : ''}
          validationRules={validationsRules}
          onChangeText={(value) => setNumSims(parseInt(value, 10))}
        />
        <Spacer />
        <TooltipButton
          variant={ButtonVariants.LargePrimary}
          Icon={RunAll}
          onClick={() => onRunAll(modelOverrides)}
          tooltip={invalidReason}
          disabled={runButtonDisabled}>
          {t`Run Simulations`}
        </TooltipButton>
      </BottomBar>
    </>
  );
};
