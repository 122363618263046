/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Experiment: React.FC<Props> = (props) => (
  <svg viewBox="0 0 16 16" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M6.99997 9.41666C6.30961 9.41666 5.74997 9.9763 5.74997 10.6667C5.74997 11.357 6.30961 11.9167 6.99997 11.9167C7.69033 11.9167 8.24997 11.357 8.24997 10.6667C8.24997 9.9763 7.69033 9.41666 6.99997 9.41666ZM6.24997 10.6667C6.24997 10.2524 6.58576 9.91666 6.99997 9.91666C7.41418 9.91666 7.74997 10.2524 7.74997 10.6667C7.74997 11.0809 7.41418 11.4167 6.99997 11.4167C6.58576 11.4167 6.24997 11.0809 6.24997 10.6667Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M7.41664 7.83332C7.41664 7.41911 7.75242 7.08332 8.16664 7.08332C8.58085 7.08332 8.91664 7.41911 8.91664 7.83332C8.91664 8.24754 8.58085 8.58332 8.16664 8.58332C7.75242 8.58332 7.41664 8.24754 7.41664 7.83332ZM8.16664 7.58332C8.02857 7.58332 7.91664 7.69525 7.91664 7.83332C7.91664 7.97139 8.02857 8.08332 8.16664 8.08332C8.30471 8.08332 8.41664 7.97139 8.41664 7.83332C8.41664 7.69525 8.30471 7.58332 8.16664 7.58332Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M9.66664 10.75C9.16038 10.75 8.74997 11.1604 8.74997 11.6667C8.74997 12.1729 9.16038 12.5833 9.66664 12.5833C10.1729 12.5833 10.5833 12.1729 10.5833 11.6667C10.5833 11.1604 10.1729 10.75 9.66664 10.75ZM9.24997 11.6667C9.24997 11.4365 9.43652 11.25 9.66664 11.25C9.89676 11.25 10.0833 11.4365 10.0833 11.6667C10.0833 11.8968 9.89676 12.0833 9.66664 12.0833C9.43652 12.0833 9.24997 11.8968 9.24997 11.6667Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M5.66664 2.16666C5.3905 2.16666 5.16664 2.39051 5.16664 2.66666C5.16664 2.9428 5.3905 3.16666 5.66664 3.16666H6.16664V6.43059C6.16664 6.50821 6.14856 6.58477 6.11385 6.6542L3.6097 11.6625C3.11102 12.6599 3.83627 13.8333 4.95134 13.8333H11.0486C12.1637 13.8333 12.8889 12.6599 12.3902 11.6625L9.88609 6.65419C9.85138 6.58477 9.8333 6.50821 9.8333 6.43059V3.16666H10.3333C10.6094 3.16666 10.8333 2.9428 10.8333 2.66666C10.8333 2.39051 10.6094 2.16666 10.3333 2.16666H5.66664ZM7.16664 6.43059V3.16666H8.8333V6.43059C8.8333 6.66346 8.88752 6.89313 8.99166 7.10141L11.4958 12.1097C11.662 12.4422 11.4203 12.8333 11.0486 12.8333H4.95134C4.57965 12.8333 4.3379 12.4422 4.50412 12.1097L7.00828 7.10141C7.11242 6.89313 7.16664 6.66346 7.16664 6.43059Z"
      fill={props.fill}
    />
  </svg>
);

Experiment.defaultProps = {
  width: '16',
  height: '16',
  viewBox: '0 0 16 16',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Experiment;
