import { getCommonTheme, getThemeValue } from 'theme/themes';
import { TooltipPlacement } from 'ui/common/Tooltip/tooltipTypes';

const POINTER_OFFSET = getThemeValue(getCommonTheme().spacing.normal);

const MARGIN = getThemeValue(getCommonTheme().spacing.normal);
const SMALL_MARGIN = getThemeValue(getCommonTheme().spacing.small);

export function getTooltipPosition(
  triggerRect: DOMRect | undefined,
  contentRect: DOMRect | undefined,
  placement: TooltipPlacement,
  showPointer: boolean,
  isButtonTooltip: boolean,
  noMargin?: boolean,
): { top: number; left: number } {
  if (triggerRect && contentRect) {
    const margin = noMargin ? 0 : isButtonTooltip ? SMALL_MARGIN : MARGIN;
    const pointerOffset = showPointer ? POINTER_OFFSET : 0;
    const offset = showPointer ? triggerRect.width / 2 - contentRect.width : 0;
    switch (placement) {
      case TooltipPlacement.TOP_LEFT:
        return {
          top: -contentRect.height - pointerOffset - margin,
          left: 0 + offset + pointerOffset * 2,
        };
      case TooltipPlacement.TOP_CENTER:
        return {
          top: -contentRect.height - pointerOffset - margin,
          left: Math.floor(triggerRect.width / 2 - contentRect.width / 2),
        };
      case TooltipPlacement.TOP_RIGHT:
        return {
          top: -contentRect.height - pointerOffset - margin,
          left:
            triggerRect.width - contentRect.width - offset - pointerOffset * 2,
        };
      case TooltipPlacement.BOTTOM_LEFT:
        return {
          top: triggerRect.height + pointerOffset + margin,
          left: 0 + offset + pointerOffset * 2,
        };
      case TooltipPlacement.BOTTOM_CENTER:
        return {
          top: triggerRect.height + pointerOffset + margin,
          left: Math.floor(triggerRect.width / 2 - contentRect.width / 2),
        };
      case TooltipPlacement.BOTTOM_RIGHT:
        return {
          top: triggerRect.height + pointerOffset + margin,
          left:
            triggerRect.width - contentRect.width - offset - pointerOffset * 2,
        };
      default:
        return { top: 0, left: 0 };
    }
  } else {
    return { top: 0, left: 0 };
  }
}

export function getPointerPosition(
  triggerRect: DOMRect | undefined,
  contentRect: DOMRect | undefined,
  placement: TooltipPlacement,
): { top: number; left: number } {
  if (contentRect && triggerRect) {
    switch (placement) {
      case TooltipPlacement.TOP_LEFT:
        return {
          top: contentRect.height,
          left: contentRect.width - POINTER_OFFSET * 3,
        };
      case TooltipPlacement.TOP_CENTER:
        return {
          top: contentRect.height,
          left: Math.floor(contentRect.width / 2) - POINTER_OFFSET,
        };
      case TooltipPlacement.TOP_RIGHT:
        return {
          top: contentRect.height,
          left: POINTER_OFFSET,
        };
      case TooltipPlacement.BOTTOM_LEFT:
        return {
          top: -POINTER_OFFSET,
          left: contentRect.width - POINTER_OFFSET * 3,
        };
      case TooltipPlacement.BOTTOM_CENTER:
        return {
          top: -POINTER_OFFSET,
          left: Math.floor(contentRect.width / 2) - POINTER_OFFSET,
        };
      case TooltipPlacement.BOTTOM_RIGHT:
        return {
          top: -POINTER_OFFSET,
          left: POINTER_OFFSET,
        };
      default:
        return { top: 0, left: 0 };
    }
  } else {
    return { top: 0, left: 0 };
  }
}
