/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  className?: string;
}

const Quanserhal: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16 25.0178C18.5356 25.0178 20.7228 24.0046 22.33 22.23C22.8506 22.7388 23.5 23.5 23.9 24.5C21.8258 26.6036 19.1335 27.8033 16 27.8033C9.64873 27.8033 4.5 22.4747 4.5 15.9016C4.5 9.32854 9.64873 4 16 4C22.3513 4 27.5 9.32854 27.5 15.9016C27.5 17.9563 26.9969 19.8894 26.1113 21.5761C25.577 20.7775 24.8943 19.9488 24.1711 19.3129C24.5822 18.2592 24.8085 17.1078 24.8085 15.9016C24.8085 10.8669 20.8648 6.78549 16 6.78549C11.1352 6.78549 7.19149 10.8669 7.19149 15.9016C7.19149 20.9363 11.1352 25.0178 16 25.0178Z"
      fill={props.fill}
    />
    <path
      d="M20.6489 22.7388C20.4042 21.9791 20.1595 21.0168 20.5266 20.713C21.1383 20.2065 22.5508 21.244 23.634 22.7388C25.1021 24.7646 25.0532 26.0307 24.8085 26.2839C24.3192 26.7903 23.5851 26.2839 23.0957 25.7775C22.6064 26.2839 21.6277 26.7903 21.1377 27.0436C23.0957 29.0694 25.5426 29.5758 26.5212 28.3097C28.3678 25.9208 26.0566 22.5727 25.3197 21.5051L25.2973 21.4726C24.0744 19.7 21.1383 17.1678 19.1803 18.4339C17.6846 19.4012 18.202 22.992 18.9356 23.7517C19.6702 23.4984 20.4043 22.992 20.6489 22.7388Z"
      fill={props.fill}
    />
  </svg>
);

Quanserhal.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  className: '',
};

export default Quanserhal;
