/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Scalarbroadcast: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.5 2.75C17.5 2.05964 18.0596 1.5 18.75 1.5H21.25C21.6642 1.5 22 1.83579 22 2.25C22 2.66421 21.6642 3 21.25 3H19V29H21.25C21.6642 29 22 29.3358 22 29.75C22 30.1642 21.6642 30.5 21.25 30.5H18.75C18.0596 30.5 17.5 29.9404 17.5 29.25V2.75Z"
      fill={props.fill}
    />
    <path
      d="M28.75 1.5C29.4404 1.5 30 2.05964 30 2.75V29.25C30 29.9404 29.4404 30.5 28.75 30.5H26.25C25.8358 30.5 25.5 30.1642 25.5 29.75C25.5 29.3358 25.8358 29 26.25 29H28.5V3H26.25C25.8358 3 25.5 2.66421 25.5 2.25C25.5 1.83579 25.8358 1.5 26.25 1.5H28.75Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M6.18031 13.22C5.86031 13.0733 5.49031 13 5.07031 13C4.49031 13 4.00698 13.13 3.62031 13.39C3.24031 13.65 3.02365 13.9833 2.97031 14.39C2.96365 14.4167 2.97031 14.44 2.99031 14.46C3.01698 14.4733 3.04698 14.4833 3.08031 14.49L4.05031 14.61C4.12365 14.6233 4.17031 14.59 4.19031 14.51C4.23031 14.35 4.32698 14.2267 4.48031 14.14C4.64031 14.0467 4.84698 14 5.10031 14C5.40698 14 5.63698 14.0733 5.79031 14.22C5.94365 14.36 6.02031 14.56 6.02031 14.82V15.06C6.02031 15.0933 6.00365 15.11 5.97031 15.11H4.90031C4.24031 15.11 3.72698 15.2567 3.36031 15.55C3.00031 15.8367 2.82031 16.24 2.82031 16.76C2.82031 17.26 2.98031 17.6367 3.30031 17.89C3.62698 18.1433 4.04031 18.27 4.54031 18.27C5.16031 18.27 5.63365 18.09 5.96031 17.73C5.97365 17.7167 5.98698 17.7133 6.00031 17.72C6.01365 17.72 6.02031 17.73 6.02031 17.75V18.07C6.02031 18.1033 6.03031 18.1333 6.05031 18.16C6.07698 18.18 6.10698 18.19 6.14031 18.19H7.06031C7.09365 18.19 7.12031 18.18 7.14031 18.16C7.16698 18.1333 7.18031 18.1033 7.18031 18.07V14.7C7.18031 14.3733 7.09365 14.0833 6.92031 13.83C6.74698 13.57 6.50031 13.3667 6.18031 13.22ZM5.67031 17.09C5.43698 17.2633 5.15698 17.35 4.83031 17.35C4.58365 17.35 4.38031 17.2933 4.22031 17.18C4.06698 17.06 3.99031 16.8933 3.99031 16.68C3.99031 16.4333 4.09031 16.2433 4.29031 16.11C4.49698 15.9767 4.77698 15.91 5.13031 15.91H5.97031C6.00365 15.91 6.02031 15.9267 6.02031 15.96V16.43C6.02031 16.6967 5.90365 16.9167 5.67031 17.09Z"
      fill={props.fill}
    />
    <path
      d="M10.6396 11.9697C10.9325 11.6768 11.4074 11.6768 11.7003 11.9697L14.3467 14.6161C14.8349 15.1043 14.8349 15.8957 14.3467 16.3839L11.7003 19.0303C11.4074 19.3232 10.9325 19.3232 10.6396 19.0303C10.3467 18.7374 10.3467 18.2626 10.6396 17.9697L13.1093 15.5L10.6396 13.0303C10.3467 12.7374 10.3467 12.2626 10.6396 11.9697Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M23.75 4.31C24.17 4.31 24.54 4.38333 24.86 4.53C25.18 4.67666 25.4267 4.88 25.6 5.14C25.7733 5.39333 25.86 5.68333 25.86 6.01V9.38C25.86 9.41333 25.8467 9.44333 25.82 9.47C25.8 9.49 25.7733 9.5 25.74 9.5H24.82C24.7867 9.5 24.7567 9.49 24.73 9.47C24.71 9.44333 24.7 9.41333 24.7 9.38V9.06C24.7 9.04 24.6933 9.03 24.68 9.03C24.6667 9.02333 24.6533 9.02666 24.64 9.04C24.3133 9.4 23.84 9.58 23.22 9.58C22.72 9.58 22.3067 9.45333 21.98 9.2C21.66 8.94666 21.5 8.57 21.5 8.07C21.5 7.55 21.68 7.14666 22.04 6.86C22.4067 6.56666 22.92 6.42 23.58 6.42H24.65C24.6833 6.42 24.7 6.40333 24.7 6.37V6.13C24.7 5.87 24.6233 5.67 24.47 5.53C24.3167 5.38333 24.0867 5.31 23.78 5.31C23.5267 5.31 23.32 5.35666 23.16 5.45C23.0067 5.53666 22.91 5.66 22.87 5.82C22.85 5.9 22.8033 5.93333 22.73 5.92L21.76 5.8C21.7267 5.79333 21.6967 5.78333 21.67 5.77C21.65 5.75 21.6433 5.72666 21.65 5.7C21.7033 5.29333 21.92 4.96 22.3 4.7C22.6867 4.44 23.17 4.31 23.75 4.31ZM23.51 8.66C23.8367 8.66 24.1167 8.57333 24.35 8.4C24.5833 8.22666 24.7 8.00666 24.7 7.74V7.27C24.7 7.23666 24.6833 7.22 24.65 7.22H23.81C23.4567 7.22 23.1767 7.28666 22.97 7.42C22.77 7.55333 22.67 7.74333 22.67 7.99C22.67 8.20333 22.7467 8.37 22.9 8.49C23.06 8.60333 23.2633 8.66 23.51 8.66Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M23.75 13C24.17 13 24.54 13.0733 24.86 13.22C25.18 13.3667 25.4267 13.57 25.6 13.83C25.7733 14.0833 25.86 14.3733 25.86 14.7V18.07C25.86 18.1033 25.8467 18.1333 25.82 18.16C25.8 18.18 25.7733 18.19 25.74 18.19H24.82C24.7867 18.19 24.7567 18.18 24.73 18.16C24.71 18.1333 24.7 18.1033 24.7 18.07V17.75C24.7 17.73 24.6933 17.72 24.68 17.72C24.6667 17.7133 24.6533 17.7167 24.64 17.73C24.3133 18.09 23.84 18.27 23.22 18.27C22.72 18.27 22.3067 18.1433 21.98 17.89C21.66 17.6367 21.5 17.26 21.5 16.76C21.5 16.24 21.68 15.8367 22.04 15.55C22.4067 15.2567 22.92 15.11 23.58 15.11H24.65C24.6833 15.11 24.7 15.0933 24.7 15.06V14.82C24.7 14.56 24.6233 14.36 24.47 14.22C24.3167 14.0733 24.0867 14 23.78 14C23.5267 14 23.32 14.0467 23.16 14.14C23.0067 14.2267 22.91 14.35 22.87 14.51C22.85 14.59 22.8033 14.6233 22.73 14.61L21.76 14.49C21.7267 14.4833 21.6967 14.4733 21.67 14.46C21.65 14.44 21.6433 14.4167 21.65 14.39C21.7033 13.9833 21.92 13.65 22.3 13.39C22.6867 13.13 23.17 13 23.75 13ZM23.51 17.35C23.8367 17.35 24.1167 17.2633 24.35 17.09C24.5833 16.9167 24.7 16.6967 24.7 16.43V15.96C24.7 15.9267 24.6833 15.91 24.65 15.91H23.81C23.4567 15.91 23.1767 15.9767 22.97 16.11C22.77 16.2433 22.67 16.4333 22.67 16.68C22.67 16.8933 22.7467 17.06 22.9 17.18C23.06 17.2933 23.2633 17.35 23.51 17.35Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M23.75 21.78C24.17 21.78 24.54 21.8533 24.86 22C25.18 22.1467 25.4267 22.35 25.6 22.61C25.7733 22.8633 25.86 23.1533 25.86 23.48V26.85C25.86 26.8833 25.8467 26.9133 25.82 26.94C25.8 26.96 25.7733 26.97 25.74 26.97H24.82C24.7867 26.97 24.7567 26.96 24.73 26.94C24.71 26.9133 24.7 26.8833 24.7 26.85V26.53C24.7 26.51 24.6933 26.5 24.68 26.5C24.6667 26.4933 24.6533 26.4967 24.64 26.51C24.3133 26.87 23.84 27.05 23.22 27.05C22.72 27.05 22.3067 26.9233 21.98 26.67C21.66 26.4167 21.5 26.04 21.5 25.54C21.5 25.02 21.68 24.6167 22.04 24.33C22.4067 24.0367 22.92 23.89 23.58 23.89H24.65C24.6833 23.89 24.7 23.8733 24.7 23.84V23.6C24.7 23.34 24.6233 23.14 24.47 23C24.3167 22.8533 24.0867 22.78 23.78 22.78C23.5267 22.78 23.32 22.8267 23.16 22.92C23.0067 23.0067 22.91 23.13 22.87 23.29C22.85 23.37 22.8033 23.4033 22.73 23.39L21.76 23.27C21.7267 23.2633 21.6967 23.2533 21.67 23.24C21.65 23.22 21.6433 23.1967 21.65 23.17C21.7033 22.7633 21.92 22.43 22.3 22.17C22.6867 21.91 23.17 21.78 23.75 21.78ZM23.51 26.13C23.8367 26.13 24.1167 26.0433 24.35 25.87C24.5833 25.6967 24.7 25.4767 24.7 25.21V24.74C24.7 24.7067 24.6833 24.69 24.65 24.69H23.81C23.4567 24.69 23.1767 24.7567 22.97 24.89C22.77 25.0233 22.67 25.2133 22.67 25.46C22.67 25.6733 22.7467 25.84 22.9 25.96C23.06 26.0733 23.2633 26.13 23.51 26.13Z"
      fill={props.fill}
    />
  </svg>
);

Scalarbroadcast.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Scalarbroadcast;
