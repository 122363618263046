/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  className?: string;
}

const Tree: React.FC<Props> = (props) => (
  <svg viewBox="0 0 24 24" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.5 4.75C4.5 4.33579 4.83579 4 5.25 4H9.75C10.1642 4 10.5 4.33579 10.5 4.75V6.25C10.5 6.66421 10.1642 7 9.75 7H5.25C4.83579 7 4.5 6.66421 4.5 6.25V4.75Z"
      fill={props.fill}
    />
    <path
      d="M6.75 17.5V9.25C6.75 8.83579 7.08579 8.5 7.5 8.5C7.91421 8.5 8.25 8.83579 8.25 9.25V11.5C8.25 11.9142 8.58579 12.25 9 12.25H11.25C11.6642 12.25 12 12.5858 12 13C12 13.4142 11.6642 13.75 11.25 13.75H9C8.73702 13.75 8.48458 13.7049 8.25 13.622V17.5C8.25 17.9142 8.58579 18.25 9 18.25H11.25C11.6642 18.25 12 18.5858 12 19C12 19.4142 11.6642 19.75 11.25 19.75H9C7.75736 19.75 6.75 18.7426 6.75 17.5Z"
      fill={props.fill}
    />
    <path
      d="M14.25 11.5C13.8358 11.5 13.5 11.8358 13.5 12.25V13.75C13.5 14.1642 13.8358 14.5 14.25 14.5H18.75C19.1642 14.5 19.5 14.1642 19.5 13.75V12.25C19.5 11.8358 19.1642 11.5 18.75 11.5H14.25Z"
      fill={props.fill}
    />
    <path
      d="M13.5 18.25C13.5 17.8358 13.8358 17.5 14.25 17.5H18.75C19.1642 17.5 19.5 17.8358 19.5 18.25V19.75C19.5 20.1642 19.1642 20.5 18.75 20.5H14.25C13.8358 20.5 13.5 20.1642 13.5 19.75V18.25Z"
      fill={props.fill}
    />
  </svg>
);

Tree.defaultProps = {
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  className: '',
};

export default Tree;
