import React from 'react';
import { SelectOptionConfig } from 'ui/common/Menu/menuItemTypes';
import SelectOption from './SelectOption';

interface Props {
  options: SelectOptionConfig[];
  onChange: (id: string) => void;
  selectionId: string | number;
}

const Select: React.FC<Props> = ({ options, onChange, selectionId }) => (
  <>
    {options.map((option, idx) => (
      <SelectOption
        key={option.id || `${option}_${idx}`}
        onClick={onChange}
        content={option.content}
        id={option.id}
        selected={selectionId === option.id}
      />
    ))}
  </>
);

export default Select;
