/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Dotproduct: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M26.9499 14.968C27.1206 15.512 27.2059 16.1573 27.2059 16.904C27.2059 17.704 27.1046 18.3867 26.9019 18.952C26.6672 19.6453 26.2939 20.184 25.7819 20.568C25.2806 20.9413 24.6459 21.128 23.8779 21.128C23.5046 21.128 23.1579 21.0533 22.8379 20.904C22.5179 20.7547 22.2406 20.5467 22.0059 20.28C21.9846 20.2587 21.9632 20.2533 21.9419 20.264C21.9312 20.2747 21.9259 20.2907 21.9259 20.312V20.84C21.9259 20.9467 21.8726 21 21.7659 21H20.5499C20.4432 21 20.3899 20.9467 20.3899 20.84V9.96C20.3899 9.85334 20.4432 9.8 20.5499 9.8H21.7659C21.8726 9.8 21.9259 9.85334 21.9259 9.96V13.528C21.9259 13.5493 21.9312 13.5653 21.9419 13.576C21.9632 13.5867 21.9846 13.5813 22.0059 13.56C22.2406 13.2933 22.5179 13.0907 22.8379 12.952C23.1686 12.8027 23.5152 12.728 23.8779 12.728C24.6779 12.728 25.3339 12.9253 25.8459 13.32C26.3579 13.7147 26.7259 14.264 26.9499 14.968ZM25.2539 18.92C25.5206 18.4187 25.6539 17.7573 25.6539 16.936C25.6539 16.0613 25.4832 15.3573 25.1419 14.824C24.8326 14.312 24.3579 14.056 23.7179 14.056C23.1206 14.056 22.6672 14.328 22.3579 14.872C22.0806 15.3627 21.9419 16.0453 21.9419 16.92C21.9419 17.7947 22.0699 18.4667 22.3259 18.936C22.6459 19.512 23.1152 19.8 23.7339 19.8C24.4379 19.8 24.9446 19.5067 25.2539 18.92Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M8.27804 12.728C8.92871 12.728 9.49404 12.8453 9.97404 13.08C10.4647 13.304 10.838 13.624 11.094 14.04C11.3607 14.4453 11.494 14.9093 11.494 15.432V20.84C11.494 20.9467 11.4407 21 11.334 21H10.118C10.0114 21 9.95804 20.9467 9.95804 20.84V20.264C9.95804 20.2107 9.93137 20.2 9.87804 20.232C9.60071 20.52 9.25937 20.744 8.85404 20.904C8.45937 21.0533 8.01671 21.128 7.52604 21.128C6.73671 21.128 6.08071 20.9307 5.55804 20.536C5.04604 20.1307 4.79004 19.5227 4.79004 18.712C4.79004 17.88 5.07804 17.2293 5.65404 16.76C6.24071 16.28 7.05671 16.04 8.10204 16.04H9.89404C9.93671 16.04 9.95804 16.0187 9.95804 15.976V15.544C9.95804 15.0747 9.81937 14.712 9.54204 14.456C9.27537 14.1893 8.86471 14.056 8.31004 14.056C7.86204 14.056 7.49404 14.1467 7.20604 14.328C6.92871 14.4987 6.75804 14.7333 6.69404 15.032C6.66204 15.1387 6.59804 15.1867 6.50204 15.176L5.20604 15C5.09937 14.9787 5.05137 14.936 5.06204 14.872C5.14737 14.2427 5.48337 13.7307 6.07004 13.336C6.65671 12.9307 7.39271 12.728 8.27804 12.728ZM7.86204 19.832C8.42737 19.832 8.91804 19.6827 9.33404 19.384C9.75004 19.0853 9.95804 18.7067 9.95804 18.248V17.304C9.95804 17.2613 9.93671 17.24 9.89404 17.24H8.39004C7.76071 17.24 7.25937 17.3627 6.88604 17.608C6.51271 17.8533 6.32604 18.1947 6.32604 18.632C6.32604 19.0267 6.46471 19.3253 6.74204 19.528C7.03004 19.7307 7.40337 19.832 7.86204 19.832Z"
      fill={props.fill}
    />
    <path
      d="M16 18.5C16.8284 18.5 17.5 17.8284 17.5 17C17.5 16.1716 16.8284 15.5 16 15.5C15.1716 15.5 14.5 16.1716 14.5 17C14.5 17.8284 15.1716 18.5 16 18.5Z"
      fill={props.fill}
    />
  </svg>
);

Dotproduct.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Dotproduct;
