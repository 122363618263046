import { t } from '@lingui/macro';
import { PortSide } from 'app/common_types/PortTypes';
import { blockClassLookup } from 'app/generated_blocks';
import {
  BlockClassName,
  BlockInstance,
} from 'app/generated_types/SimulationModel';

export interface PortCondition {
  shouldHavePort: (block: BlockInstance) => boolean;
  side: PortSide;

  /**
   * Name of conditional port
   */
  portName?: string;

  /**
   * Whether the condition applies to dynamic ports in addition to any
   * named conditional ports.
   */
  appliesToDynamicPorts?: boolean;
}

const logicalOperatorPortConditions: PortCondition[] = [
  {
    shouldHavePort: (block: BlockInstance) =>
      block.parameters.function?.value !== 'not',
    side: PortSide.Input,
    portName: 'in_1',
    appliesToDynamicPorts: true,
  },
];

const matrixOperatorPortConditions: PortCondition[] = [
  {
    shouldHavePort: (block: BlockInstance) =>
      block.parameters.function?.value === 'multiply' ||
      block.parameters.function?.value === 'concatenation',
    side: PortSide.Input,
    portName: 'in_1',
  },
];

const integratorPortConditions: PortCondition[] = [
  {
    shouldHavePort: (block: BlockInstance) =>
      block.parameters.enable_reset?.value === 'true',
    side: PortSide.Input,
    portName: 'reset',
  },
  {
    shouldHavePort: (block: BlockInstance) =>
      block.parameters.enable_reset?.value === 'true' &&
      block.parameters.enable_external_reset?.value === 'true',
    side: PortSide.Input,
    portName: 'reset_value',
  },
  {
    shouldHavePort: (block: BlockInstance) =>
      block.parameters.enable_hold?.value === 'true',
    side: PortSide.Input,
    portName: 'hold',
  },
];

const _genericConditions: Record<string, PortCondition[] | null> = {};

// Generically handle json-defined port conditions like condition_bool_parameter
const genericConditionalPortConditions = (
  blockType: BlockClassName,
): PortCondition[] | null => {
  if (_genericConditions[blockType]) return _genericConditions[blockType];

  const blockClass = blockClassLookup(blockType);
  if (!blockClass?.ports) return null;

  const conds: PortCondition[] = [];

  if (blockClass.ports.inputs?.conditional) {
    blockClass.ports.inputs.conditional.forEach((port) => {
      if (port.condition_bool_parameter) {
        const condition_bool_parameter = port.condition_bool_parameter;
        conds.push({
          shouldHavePort: (block: BlockInstance) =>
            block.parameters[condition_bool_parameter]?.value === 'true',
          side: PortSide.Input,
          portName: port.name,
        });
      }
    });
  }

  if (blockClass.ports.outputs?.conditional) {
    blockClass.ports.outputs.conditional.forEach((port) => {
      if (port.condition_bool_parameter) {
        const condition_bool_parameter = port.condition_bool_parameter;
        conds.push({
          shouldHavePort: (block: BlockInstance) =>
            block.parameters[condition_bool_parameter]?.value === 'true',
          side: PortSide.Output,
          portName: port.name,
        });
      }
    });
  }

  _genericConditions[blockType] = conds;
  return conds;
};

/**
 * Note: Provides port conditions in an order such that for each port side, the
 * conditions are ordered so that the ports with smallest 'order' property come first.
 * Ensure that all port conditions are specified in this specific order
 * so that the conditional ports are added/removed correctly.
 */
export function getPortConditions(
  blockType: BlockClassName,
): PortCondition[] | null {
  switch (blockType) {
    case 'core.LogicalOperator':
      return logicalOperatorPortConditions;

    case 'core.MatrixOperator':
      return matrixOperatorPortConditions;

    case 'core.Integrator':
    case 'core.IntegratorDiscrete':
      return integratorPortConditions;

    default:
      return genericConditionalPortConditions(blockType);
  }
}

export function getPortDisplayName(
  nodeType: BlockClassName,
  portName: string,
): string {
  // Expose localized names where the display name is different from the name in the schema.
  switch (nodeType) {
    case 'core.CoordinateRotation':
    case 'core.CoordinateRotationConversion':
      if (portName === 'quaternion_w') {
        return t({
          id: 'BlockPortLabel.CoordinateRotation.quaternion_w',
          message: 'q_w',
        });
      }
      if (portName === 'quaternion_x') {
        return t({
          id: 'BlockPortLabel.CoordinateRotation.quaternion_x',
          message: 'q_x',
        });
      }
      if (portName === 'quaternion_y') {
        return t({
          id: 'BlockPortLabel.CoordinateRotation.quaternion_y',
          message: 'q_y',
        });
      }
      if (portName === 'quaternion_z') {
        return t({
          id: 'BlockPortLabel.CoordinateRotation.quaternion_z',
          message: 'q_z',
        });
      }
      if (portName === 'roll') {
        return t({
          id: 'BlockPortLabel.CoordinateRotation.roll',
          message: 'roll',
        });
      }
      if (portName === 'pitch') {
        return t({
          id: 'BlockPortLabel.CoordinateRotation.pitch',
          message: 'pitch',
        });
      }
      if (portName === 'yaw') {
        return t({
          id: 'BlockPortLabel.CoordinateRotation.yaw',
          message: 'yaw',
        });
      }
      break;
  }

  return portName;
}
