/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Edit: React.FC<Props> = (props) => (
  <svg viewBox="0 0 24 24" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M14.2616 6.54252C14.8996 6.33064 15.9639 6.1637 16.7762 6.97604C17.5885 7.78838 17.4216 8.85268 17.2097 9.49068C17.0967 9.83115 16.9487 10.126 16.8323 10.3324C16.7733 10.4368 16.7206 10.5219 16.6815 10.5823C16.6619 10.6126 16.6456 10.637 16.6335 10.6547L16.6186 10.6764L16.6137 10.6834L16.6119 10.686L16.6111 10.687C16.611 10.6872 16.6105 10.6879 16 10.2522L16.6105 10.6879L16.5744 10.7385L11.1441 16.1688L7.70179 17.2226L7.68377 17.2271C6.97032 17.4074 6.35443 16.7554 6.5241 16.0694L6.52904 16.0494L7.58349 12.6081L13.0137 7.17788L13.0644 7.14172L13.5 7.75222C13.0644 7.14172 13.0642 7.14187 13.0644 7.14172L13.0663 7.14038L13.0688 7.13858L13.0758 7.13365L13.0975 7.11871C13.1153 7.10661 13.1396 7.09031 13.1699 7.07073C13.2304 7.03165 13.3154 6.97894 13.4199 6.91999C13.6262 6.80355 13.9211 6.65559 14.2616 6.54252ZM14.7647 7.95619C15.2322 7.80694 15.5318 7.85298 15.7155 8.0367C15.8993 8.22042 15.9453 8.52007 15.7961 8.98759L14.7647 7.95619ZM13.5 8.81288L8.90826 13.4046L8.27262 15.4791L10.3477 14.8439L14.9394 10.2522L13.5 8.81288Z"
      fill={props.fill}
    />
  </svg>
);

Edit.defaultProps = {
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Edit;
