// !!!! -- DO NOT MANUALLY EDIT -- !!!!
// This file was generated by something in scripts/generateDefaultBlockData.js
// If you want to change the contents of this file, it should be automated
//
// (You can edit this file locally, but it may not be checked into the codebase -
// PRs with a manual edit of this file are forbidden from being merged.)

import { computationBlockDefaults } from 'app/generated_blocks/computationBlockDefaults';
import type { ComputationBlockClass } from 'app/generated_types/ComputationBlockClass';
import deepmerge from 'deepmerge';

export const StateSpace: ComputationBlockClass = deepmerge(
  computationBlockDefaults,
  {
    "base": {
      "name": "StateSpace",
      "namespace": "core",
      "description": "Models a linear system by State Space representation.",
      "help_url": "https://docs.collimator.ai/using-model-editors/block-library/continuous-blocks#state-space",
      "supports_iterator": false
    },
    "modes": {
      "time": "agnostic"
    },
    "ports": {
      "inputs": {
        "static": [
          {
            "name": "in_0"
          }
        ]
      },
      "outputs": {
        "static": [
          {
            "name": "out_0"
          }
        ]
      }
    },
    "parameter_definitions": [
      {
        "name": "A",
        "data_type": "any",
        "default_value": "[[1,0],[0,1]]",
        "description": "Coefficients of the state space A matrix, which is sort of like a jacobian like this state*A=derivative."
      },
      {
        "name": "B",
        "data_type": "any",
        "default_value": "[0,0]",
        "description": "Coefficients of the state space B matrix, which is the input matrix like this input*B=derivative."
      },
      {
        "name": "C",
        "data_type": "any",
        "default_value": "[1,1]",
        "description": "Coefficients of the state space C matrix, which is the output matrix like state*C=output."
      },
      {
        "name": "D",
        "data_type": "any",
        "default_value": "[0]",
        "description": "Coefficients of the state space D matrix, which is feedthrough matrix like this output=D*input."
      },
      {
        "name": "initial_states",
        "data_type": "float",
        "default_value": "0.0",
        "description": "initial value of state space system state vector"
      }
    ]
  }
);
