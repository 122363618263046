import { t } from '@lingui/macro';
import { apiS3UploadDataFile } from 'app/apiData';
import {
  generatedApi,
  useDeleteFileByUuidMutation,
  useDeleteModelByUuidMutation,
  useDeleteProjectByUuidMutation,
  usePostFileCreateMutation,
  usePostFileProcessByUuidMutation,
  usePostModelCreateMutation,
  usePostProjectCopyByUuidMutation,
  usePostProjectCreateMutation,
  usePostProjectImportByUuidMutation,
  usePostProjectImportProcessByUuidMutation,
  usePutFileUpdateByUuidMutation,
  usePutProjectUpdateByUuidMutation,
} from 'app/apiGenerated/generatedApi';
import {
  DeleteFileByUuidApiArg,
  DeleteModelByUuidApiArg,
  FileUpdateRequest,
  GetFileDownloadByUuidApiArg,
  GetProjectExportByUuidApiArg,
  PostFileCreateApiArg,
  PostModelCreateApiArg,
  PostProjectCopyByUuidApiArg,
  PostProjectCreateApiArg,
  PostProjectImportByUuidApiArg,
  PostProjectImportByUuidApiResponse,
  PutProjectUpdateByUuidApiArg,
} from 'app/apiGenerated/generatedApiTypes';
import { usePostFileCopyByUuidMutation } from 'app/enhancedApi';
import { useAppDispatch } from 'app/hooks';
import { submodelsActions } from 'app/slices/submodelsSlice';
import React from 'react';
import { useNotifications } from 'ui/common/notifications/useNotifications';
import { downloadResource } from 'util/fileUtils';

export function useProjectActions() {
  const dispatch = useAppDispatch();

  const { showError, createShowError, showInfo, showCompletion } =
    useNotifications();

  const [callCreateProjectApi] = usePostProjectCreateMutation();

  const [callUpdateProjectApi] = usePutProjectUpdateByUuidMutation();

  const [callDeleteProjectApi] = useDeleteProjectByUuidMutation();

  const [callCopyProjectApi] = usePostProjectCopyByUuidMutation();

  const [callCreateModelApi, { isLoading: isCreatingModel }] =
    usePostModelCreateMutation();

  const [callDeleteModelApi] = useDeleteModelByUuidMutation();

  const [callCreateFileApi] = usePostFileCreateMutation();

  const [callDeleteFileApi] = useDeleteFileByUuidMutation();

  const [callUpdateFileApi] = usePutFileUpdateByUuidMutation();

  const [callDuplicateFileApi] = usePostFileCopyByUuidMutation();

  const [callProcessFileApi] = usePostFileProcessByUuidMutation();

  const [callImportProjectApi] = usePostProjectImportByUuidMutation();

  const [callProcessImportProjectApi] =
    usePostProjectImportProcessByUuidMutation();

  const createProject = (request: PostProjectCreateApiArg) =>
    callCreateProjectApi(request)
      .unwrap()
      .catch(
        createShowError(
          t({
            id: 'projectApi.createProjectError',
            message: 'Unable to create project.',
          }),
        ),
      );

  const updateProject = (request: PutProjectUpdateByUuidApiArg) => {
    callUpdateProjectApi(request)
      .unwrap()
      .catch(
        createShowError(
          t({
            id: 'projectApi.updateProjectError',
            message: 'Unable to update project.',
          }),
        ),
      );
  };

  const deleteProject = (projectUuid: string) =>
    callDeleteProjectApi({
      projectUuid,
    })
      .unwrap()
      .then(() => true)
      .catch(
        createShowError(
          t({
            id: 'projectApi.deleteProjectError',
            message: 'Unable to delete project.',
          }),
        ),
      );

  const duplicateProject = (request: PostProjectCopyByUuidApiArg) => {
    try {
      const resp = callCopyProjectApi(request).unwrap();
      showCompletion(
        t({
          id: 'projectActions.duplicateProjectSuccess',
          message: 'Project has been duplicated',
        }),
      );
      return resp;
    } catch (e) {
      createShowError(
        t({
          id: 'projectApi.duplicateProjectError',
          message: 'Unable to duplicate project.',
        }),
      );
    }
  };

  const createModel = (request: PostModelCreateApiArg) =>
    callCreateModelApi(request)
      .unwrap()
      .catch(
        createShowError(
          t({
            id: 'projectApi.createModelError',
            message: 'Unable to create model.',
          }),
        ),
      );

  const deleteModel = (request: DeleteModelByUuidApiArg) =>
    callDeleteModelApi(request)
      .unwrap()
      .catch(
        createShowError(
          t({
            id: 'projectApi.deleteModelError',
            message: 'Unable to delete model.',
          }),
        ),
      );

  const uploadFileFromUrl = async (request: PostFileCreateApiArg) => {
    try {
      const data = await callCreateFileApi(request).unwrap();

      // Trigger the processing of the file as we do in normal file uploads.
      // This portion will be removed when we implement automatic processing triggers through
      // S3 upload notification workflow.
      callProcessFileApi({
        projectUuid: data.summary.project_uuid,
        fileUuid: data.summary.uuid,
      });

      showInfo(
        t({
          id: 'projectApi.uploadFromUrl.successMessage',
          message: 'File uploaded successfully',
        }),
      );
    } catch (e) {
      showError(
        t({
          id: 'projectApi.uploadFromUrl.errorMessage',
          message: 'Unable to upload file.',
        }),
        e,
      );
    }
  };

  const createFile = async (request: PostFileCreateApiArg, file: File) => {
    try {
      const data = await callCreateFileApi(request).unwrap();

      if (!data.put_presigned_url) {
        throw new Error(
          t({
            id: 'projectApi.createFile.uploadDestinationError',
            message: 'Unable to find upload destination.',
          }),
        );
      }

      await apiS3UploadDataFile(
        data.put_presigned_url,
        request.fileCreateRequest.content_type,
        file,
      );

      const resp = await callProcessFileApi({
        projectUuid: data.summary.project_uuid,
        fileUuid: data.summary.uuid,
      }).unwrap();

      return resp.summary;
    } catch (error) {
      showError(
        t({
          id: 'projectApi.createFileError',
          message: 'Unable to create file.',
        }),
        error,
      );
    }
  };

  const deleteFile = (request: DeleteFileByUuidApiArg) =>
    callDeleteFileApi(request)
      .unwrap()
      .catch(
        createShowError(
          t({
            id: 'projectApi.deleteFileError',
            message: 'Unable to delete file.',
          }),
        ),
      );

  const downloadFile = async (
    fileName: string,
    request: GetFileDownloadByUuidApiArg,
  ) => {
    const fileDownloadData = await dispatch(
      generatedApi.endpoints.getFileDownloadByUuid.initiate(request),
    );
    if (fileDownloadData.data) {
      downloadResource(fileDownloadData.data.download_link, fileName).catch(
        (e: any) => {
          showError(
            t({
              id: 'projectApi.downloadFile.unableToDownloadFile',
              message: 'Unable to download file.',
            }),
            e,
          );
          console.error(e);
        },
      );
    } else {
      showError(
        t({
          id: 'projectApi.downloadFile.noFileData',
          message: 'There was a problem retrieving the file data information',
        }),
      );
    }
  };

  const updateFile = (
    projectUuid: string,
    fileUuid: string,
    fileUpdateRequest: FileUpdateRequest,
  ) =>
    callUpdateFileApi({ fileUuid, projectUuid, fileUpdateRequest })
      .unwrap()
      .catch((e) => {
        showError(
          t({
            id: 'projectApi.updateFile.errorMessage',
            message: 'Unable to update file.',
          }),
          e,
        );
      });

  const duplicateFile = (
    projectUuid: string,
    fileUuid: string,
    destinationProjectUuid: string,
    name?: string,
  ) =>
    callDuplicateFileApi({
      fileUuid,
      projectUuid,
      fileCopyRequest: {
        destination_project_uuid: destinationProjectUuid,
        name,
      },
    })
      .unwrap()
      .catch(
        createShowError(
          t({
            id: 'projectApi.duplicateFile.errorMessage',
            message: 'Unable to duplicate file.',
          }),
        ),
      );

  const exportProject = React.useCallback(
    async (fileName: string, request: GetProjectExportByUuidApiArg) => {
      const projectDownloadData = await dispatch(
        generatedApi.endpoints.getProjectExportByUuid.initiate(request, {
          forceRefetch: true,
        }),
      );
      if (projectDownloadData.data) {
        downloadResource(projectDownloadData.data.url, fileName).catch(
          (e: any) => {
            showError(
              t({
                id: 'projectApi.exportProject.unableToDownloadProject',
                message: 'Unable to download project.',
              }),
              e,
            );
            console.error(e);
          },
        );
      } else {
        showError(
          t({
            id: 'projectApi.exportProject.noProjectData',
            message:
              'There was a problem retrieving the project data information',
          }),
        );
      }
    },
    [dispatch, showError],
  );

  const importIntoProject = React.useCallback(
    (request: PostProjectImportByUuidApiArg, file: File) => {
      callImportProjectApi(request)
        .unwrap()
        .then((data: PostProjectImportByUuidApiResponse) => {
          if (!data.url) {
            throw new Error(
              t({
                id: 'projectApi.importIntoProject.importProjectDestinationError',
                message: 'Unable to find upload destination.',
              }),
            );
          }

          return apiS3UploadDataFile(data.url, 'application/zip', file).then(
            () =>
              callProcessImportProjectApi({
                projectUuid: request.projectUuid,
                importUuid: data.uuid,
              })
                .unwrap()
                .catch(
                  createShowError(
                    t({
                      id: 'projectApi.importIntoProject.processImportError',
                      message: 'Unable to process import.',
                    }),
                  ),
                ),
          );
        })
        .then(() => {
          dispatch(submodelsActions.requestSubmodels(request.projectUuid));
        })
        .catch(
          createShowError(
            t({
              id: 'projectApi.importIntoProject.importProjectError',
              message: 'Unable to import into project.',
            }),
          ),
        );
    },
    [
      callImportProjectApi,
      callProcessImportProjectApi,
      createShowError,
      dispatch,
    ],
  );

  return {
    isCreatingModel,
    createProject,
    updateProject,
    deleteProject,
    duplicateProject,
    createModel,
    deleteModel,
    createFile,
    deleteFile,
    downloadFile,
    updateFile,
    duplicateFile,
    exportProject,
    importIntoProject,
    uploadFileFromUrl,
  };
}
