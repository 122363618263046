const URL_STRING_ARRAY_SEPARATOR = '_';

export const PARENT_PATH_KEY = 'parent_path';
export const BLOCK_KEY = 'block';

// TODO: review these and remove if possible
export const SELECTION_PARENT_PATH_KEY = 'selection_parent_path';
export const SELECTED_BLOCK_IDS_KEY = 'selected_block_ids';
export const SELECTED_LINK_IDS_KEY = 'selected_link_ids';
export const SELECTED_ANNOTATION_IDS_KEY = 'selected_annotation_ids';

export function getUrlData(
  searchParams: URLSearchParams,
  urlQueryParam: string,
): string[] {
  if (searchParams) {
    const urlDataString = searchParams.get(urlQueryParam);
    if (urlDataString) {
      return urlDataString.split(URL_STRING_ARRAY_SEPARATOR);
    }
  }
  return [];
}

export const arrayToStringParam = (array?: string[]) =>
  array?.join(URL_STRING_ARRAY_SEPARATOR) || '';

export const setBlockURLParams = (
  params: URLSearchParams,
  { block }: { block: string },
) => {
  if (block) {
    params.set(BLOCK_KEY, block);
  } else {
    params.delete(BLOCK_KEY);
  }
};

type NavigationParams = {
  parentPath: string[];
};

export const setNavigationURLParams = (
  params: URLSearchParams,
  { parentPath }: NavigationParams,
) => {
  if (parentPath.length > 0) {
    params.set(PARENT_PATH_KEY, arrayToStringParam(parentPath));
  } else {
    params.delete(PARENT_PATH_KEY);
  }
};

type SelectionParams = {
  selectionParentPath: string[]; // TODO: get rid of this divergence
  selectedBlockIds?: string[];
  selectedLinkIds?: string[];
  selectedAnnotationIds?: string[];
};

export const setSelectionURLParams = (
  params: URLSearchParams,
  {
    selectionParentPath,
    selectedBlockIds,
    selectedLinkIds,
    selectedAnnotationIds,
  }: SelectionParams,
) => {
  if (selectionParentPath.length > 0) {
    params.set(
      SELECTION_PARENT_PATH_KEY,
      arrayToStringParam(selectionParentPath),
    );
  } else {
    params.delete(SELECTION_PARENT_PATH_KEY);
  }

  if (selectedBlockIds && selectedBlockIds.length > 0) {
    params.set(SELECTED_BLOCK_IDS_KEY, arrayToStringParam(selectedBlockIds));
  } else {
    params.delete(SELECTED_BLOCK_IDS_KEY);
  }

  if (selectedLinkIds && selectedLinkIds.length > 0) {
    params.set(SELECTED_LINK_IDS_KEY, arrayToStringParam(selectedLinkIds));
  } else {
    params.delete(SELECTED_LINK_IDS_KEY);
  }

  if (selectedAnnotationIds && selectedAnnotationIds.length > 0) {
    params.set(
      SELECTED_ANNOTATION_IDS_KEY,
      arrayToStringParam(selectedAnnotationIds),
    );
  } else {
    params.delete(SELECTED_ANNOTATION_IDS_KEY);
  }
};
