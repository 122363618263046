import React from 'react';

interface Props {
  fill: string;
}

const EmptyBlockIcon: React.FC<Props> = ({ fill }) => (
  <svg
    width="10"
    height="8"
    viewBox="0 0 10 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.5 1.41648C0.5 1.3706 0.537193 1.3334 0.583073 1.3334H9.41693C9.46281 1.3334 9.5 1.3706 9.5 1.41648V7.25033C9.5 7.29621 9.46281 7.33341 9.41693 7.33341H0.583074C0.537194 7.33341 0.5 7.29621 0.5 7.25033V1.41648Z"
      stroke={fill}
    />
  </svg>
);

export default EmptyBlockIcon;
