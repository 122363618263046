import { useProject } from 'app/api/useProject';
import { Model } from 'app/apiData';
import React from 'react';
import { SimulationDataModelItem } from 'ui/dataExplorer/simulationDataSidebar/SimulationDataModelItem';

interface Props {
  projectId: string;
  explorationId: string;
  canEditProject: boolean;
  searchString: string;
}

export const SimulationDataContent: React.FC<Props> = ({
  projectId,
  explorationId,
  canEditProject,
  searchString,
}: Props) => {
  const { project } = useProject();

  if (!project) {
    return null;
  }

  return (
    <>
      {project.models.map((model: Model) => (
        <SimulationDataModelItem
          key={model.uuid}
          searchString={searchString}
          projectId={projectId}
          modelId={model.uuid}
          modelName={model.name}
          canEditProject={canEditProject}
        />
      ))}
    </>
  );
};
