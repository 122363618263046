import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { SignalDropArea } from 'ui/dataExplorer/dataExplorerTypes';

/**
 * Common grid styling used between CellRow and PlotCell to place the Data explorer drag drop zones without overlap.
 *
 * Trace drag and Signal drag use the same drop zone styling, but the drag and drop mechanisms are separate.
 * Hence the two different sets of components.
 *
 * FIXME: Would be great to use react-dnd for trace drags, too, and merge these drop zones.
 * Cannot do that in the current state since the legend items used for trace drag are not react components, but internal echart ones.
 */

const CELL_DROP_ZONE_WIDTH = 88;
const ROW_DROP_ZONE_HEIGHT = 48;

/**
 * Grid for drop zones of a row
 */
export const RowDropZoneGrid = styled.div({
  position: 'absolute',
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'transparent',
  display: 'grid',
  gridTemplateAreas: "'top top top' 'left self right' 'bottom bottom bottom'",
  gridTemplateRows: `${ROW_DROP_ZONE_HEIGHT}px 1fr ${ROW_DROP_ZONE_HEIGHT}px`,
  gridTemplateColumns: `${CELL_DROP_ZONE_WIDTH}px 1fr ${CELL_DROP_ZONE_WIDTH}px`,
  zIndex: 1,
});

/**
 * Grid for drop zones of a cell
 */
export const CellDropZoneGrid = styled.div({
  position: 'absolute',
  left: 0,
  top: `${ROW_DROP_ZONE_HEIGHT}px`,
  width: '100%',
  height: `calc(100% - ${ROW_DROP_ZONE_HEIGHT * 2}px)`,
  backgroundColor: 'transparent',
  display: 'grid',
  gridTemplateAreas: "'left self right'",
  gridTemplateColumns: `${CELL_DROP_ZONE_WIDTH}px 1fr ${CELL_DROP_ZONE_WIDTH}px`,
  zIndex: 1,
});

type HideableProps = {
  show: boolean;
};

/**
 * Hide, don't unmount the drop zones when drag state changes.
 * Necessary to prevent memory leaks react-dnd components.
 * See: https://github.com/react-dnd/react-dnd/issues/1516
 */
const hideStyles = ({ show }: HideableProps) =>
  !show &&
  css`
    visibility: hidden;
  `;

/**
 * Hideable grid for drop zones of a cell.
 */
export const HideableCellDropZoneGrid = styled(CellDropZoneGrid)<HideableProps>`
  ${hideStyles};
`;

/**
 * Hideable grid for drop zones of a row.
 */
export const HideableRowDropZoneGrid = styled(RowDropZoneGrid)<HideableProps>`
  ${hideStyles};
`;

/**
 * Used to create a trace drop zone.
 * Highlighted using :hover since it uses a homebrewed drag and drop state.
 */
export const TraceDropZoneBase = styled.div(({ theme }) => ({
  '&:hover': {
    backgroundColor: theme.colors.tint.tint2,
    opacity: 0.7,
  },
}));

/**
 * Used to create a signal drop zone w/ react-dnd
 */
export const SignalDropZoneBase = styled.div<{
  isOver: boolean;
  area: SignalDropArea;
}>`
  grid-area: ${({ area }) => area};
  ${({ isOver, theme }) =>
    isOver
      ? `
  background-color: ${theme.colors.tint.tint2};
  opacity: 0.7;
  `
      : ''}
`;
