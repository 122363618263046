import { Coordinate } from 'app/common_types/Coordinate';

// Associated Entity Id: modelId
export const MODEL_EDITOR_MODEL_PREFS_V1_KEY = 'MODEL_EDITOR_MODEL_PREFS_V1';

export interface DiagramPrefs {
  cameraPosition: Coordinate;
  cameraZoom: number;
}

export interface ModelEditorModelPrefsV1 {
  parentPathStringToPrefs: Record<string, DiagramPrefs>;
}
