import { t } from '@lingui/macro';
import { Project } from 'app/apiTransformers/convertAPIProjectToProject';
import { useNavigate } from 'react-router-dom';
import TooltipButton from 'ui/common/Button/TooltipButton';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import { Bus } from 'ui/common/Icons/Standard';
import { useModal } from 'ui/common/Modal/useModal';
import { CreateBusTypeModal } from '../CreateBusTypeModal';

interface Props {
  project: Project;
}

export const ProjectDetailCreateBusTypeButton: React.FC<Props> = ({
  project,
}) => {
  const navigate = useNavigate();
  const { triggerModal } = useModal();
  const onBusTypeCreated = (name: string) => {
    navigate(`/projects/${project.uuid}/bus_types/${name}`);
  };

  const openCreateBusTypeModal = () => {
    triggerModal(
      <CreateBusTypeModal
        projectId={project.uuid}
        onCreated={onBusTypeCreated}
      />,
      t({
        id: 'createBusTypeModal.title',
        message: 'Create new Bus Type',
      }),
    );
  };

  const tooltip = t({
    id: 'dashboard.projectsDetailActionButtons.NewBusTypeButton.Label',
    message: 'Create New Bus Type',
  });

  return (
    <TooltipButton
      tooltip={tooltip}
      variant={ButtonVariants.SmallSecondary}
      Icon={Bus}
      largeIcon
      testId="project-detail-new-bus-type-dropdown-button"
      onClick={openCreateBusTypeModal}
    />
  );
};
