/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Multiply: React.FC<Props> = (props) => (
  <svg viewBox="0 0 8 8" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M0.71967 0.71967C1.01256 0.426777 1.48744 0.426777 1.78033 0.71967L4 2.93934L6.21967 0.71967C6.51256 0.426777 6.98744 0.426777 7.28033 0.71967C7.57322 1.01256 7.57322 1.48744 7.28033 1.78033L5.06066 4L7.28033 6.21967C7.57322 6.51256 7.57322 6.98744 7.28033 7.28033C6.98744 7.57322 6.51256 7.57322 6.21967 7.28033L4 5.06066L1.78033 7.28033C1.48744 7.57322 1.01256 7.57322 0.71967 7.28033C0.426777 6.98744 0.426777 6.51256 0.71967 6.21967L2.93934 4L0.71967 1.78033C0.426777 1.48744 0.426777 1.01256 0.71967 0.71967Z"
      fill={props.fill}
    />
  </svg>
);

Multiply.defaultProps = {
  width: '8',
  height: '8',
  viewBox: '0 0 8 8',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Multiply;
