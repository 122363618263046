/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Play: React.FC<Props> = (props) => (
  <svg viewBox="0 0 24 24" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M5.75 5.87054C5.75 4.9058 6.79652 4.30471 7.62984 4.79081L18.1375 10.9203C18.9644 11.4026 18.9644 12.5974 18.1375 13.0797L7.62984 19.2092C6.79652 19.6953 5.75 19.0942 5.75 18.1295V5.87054ZM7.25 6.30579V17.6942L17.0115 12L7.25 6.30579Z"
      fill={props.fill}
    />
  </svg>
);

Play.defaultProps = {
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Play;
