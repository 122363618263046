import { t } from '@lingui/macro';
import {
  useDeleteEntityPreferencesMutation,
  usePutEntityPreferencesMutation,
} from 'app/apiGenerated/generatedApi';
import { enhancedApi } from 'app/enhancedApi';
import { useAppDispatch } from 'app/hooks';
import React from 'react';
import { useNotifications } from 'ui/common/notifications/useNotifications';
import { IS_FAVOURITE_V1 } from 'ui/userPreferences/projectFavoritesPrefs';

export function useEditProjectFavorites() {
  const dispatch = useAppDispatch();
  const { showError } = useNotifications();

  const [callPutEntityPreferences, { isLoading: isLoadingPut }] =
    usePutEntityPreferencesMutation();
  const [callDeleteEntityPreferences, { isLoading: isLoadingDelete }] =
    useDeleteEntityPreferencesMutation();

  const setAsFavorite = React.useCallback(
    (projectId: string) => {
      dispatch(
        enhancedApi.util.updateQueryData('getProjectReadAll', {}, (projects) =>
          projects.map((p) =>
            p.uuid === projectId ? { ...p, isFavorite: true } : p,
          ),
        ),
      );

      callPutEntityPreferences({
        entityUuid: projectId,
        preferencesKey: IS_FAVOURITE_V1,
        entityPreferencesContainer: {
          data: {},
        },
      })
        .unwrap()
        .catch((error) => {
          showError(
            error,
            t({
              id: 'projectFavoriteApi.setAsFavoriteError',
              message: 'Unable to favorite project.',
            }),
          );
        });
    },
    [dispatch, callPutEntityPreferences, showError],
  );

  const removeFavorite = React.useCallback(
    (projectId: string) => {
      dispatch(
        enhancedApi.util.updateQueryData('getProjectReadAll', {}, (projects) =>
          projects.map((p) =>
            p.uuid === projectId ? { ...p, isFavorite: false } : p,
          ),
        ),
      );

      callDeleteEntityPreferences({
        entityUuid: projectId,
        preferencesKey: IS_FAVOURITE_V1,
      })
        .unwrap()
        .catch((error) => {
          showError(
            error,
            t({
              id: 'projectFavoriteApi.removeFavoriteError',
              message: 'Unable to unfavorite project.',
            }),
          );
        });
    },
    [dispatch, callDeleteEntityPreferences, showError],
  );

  return {
    setAsFavorite,
    removeFavorite,
    isUpdating: isLoadingPut || isLoadingDelete,
  };
}
