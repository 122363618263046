import { NodeInstance } from 'app/generated_types/SimulationModel';
import { nodeTypeIsAdder, nodeTypeIsProduct } from 'app/helpers';
import { useAppDispatch } from 'app/hooks';
import { modelActions } from 'app/slices/modelSlice';
import React from 'react';
import { Minus, Plus } from 'ui/common/Icons/Standard';
import { Divide, Multiply } from 'ui/common/Icons/StandardSymbols';
import Switch from 'ui/common/switch/Switch';

function getIsSwitchedLeft(
  selectedNode: NodeInstance,
  portIndex: number,
  leftOperator: string,
): boolean {
  return selectedNode.parameters.operators?.value?.[portIndex] === leftOperator;
}

type Props = {
  parentPath: string[];
  selectedNode: NodeInstance;
  portIndex: number;
  disabled: boolean;
};

const BlockInportSwitch: React.FC<Props> = ({
  parentPath,
  selectedNode,
  portIndex,
  disabled,
}) => {
  const dispatch = useAppDispatch();

  const isAdderBlock = nodeTypeIsAdder(selectedNode.type);
  const isProductBlock = nodeTypeIsProduct(selectedNode.type);
  const leftOperator = isAdderBlock ? '+' : '*';
  const rightOperator = isAdderBlock ? '-' : '/';

  const [isSwitchedLeft, setIsSwitchedLeft] = React.useState<boolean>(() =>
    getIsSwitchedLeft(selectedNode, portIndex, leftOperator),
  );

  const switchOperator = (nextIsSwitchedLeft: boolean) => {
    const newOperator = nextIsSwitchedLeft ? leftOperator : rightOperator;

    const operatorsParameter = selectedNode.parameters.operators;
    if (!operatorsParameter) {
      console.error('no operators parameter found for block', selectedNode);
      return;
    }
    const newOperators =
      operatorsParameter.value.slice(0, portIndex) +
      newOperator +
      operatorsParameter.value.slice(portIndex + 1);

    dispatch(
      modelActions.changeBlockParameter({
        parentPath,
        nodeUuid: selectedNode.uuid,
        paramName: 'operators',
        value: newOperators,
      }),
    );

    setIsSwitchedLeft(nextIsSwitchedLeft);
  };

  React.useEffect(() => {
    const nextIsSwitchedLeft = getIsSwitchedLeft(
      selectedNode,
      portIndex,
      leftOperator,
    );
    if (nextIsSwitchedLeft !== isSwitchedLeft) {
      setIsSwitchedLeft(nextIsSwitchedLeft);
    }
  }, [
    selectedNode.uuid,
    selectedNode.parameters.operators?.value?.[portIndex],
    isSwitchedLeft,
    portIndex,
    leftOperator,
    selectedNode,
  ]);

  if (isAdderBlock) {
    return (
      <Switch
        onSwitched={switchOperator}
        switchedLeft={isSwitchedLeft}
        disabled={disabled}
        LeftIcon={Plus}
        RightIcon={Minus}
        testId={`adder-operator-switch-button-${portIndex}`}
      />
    );
  }

  if (isProductBlock) {
    return (
      <Switch
        onSwitched={switchOperator}
        switchedLeft={isSwitchedLeft}
        LeftIcon={Multiply}
        RightIcon={Divide}
        testId={`product-operator-switch-button-${portIndex}`}
      />
    );
  }

  return null;
};

export default BlockInportSwitch;
