import { t } from '@lingui/macro';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { submodelsActions } from 'app/slices/submodelsSlice';
import React from 'react';
import { useModal } from 'ui/common/Modal/useModal';
import AddReferenceSubmodelModal from 'ui/modelEditor/AddReferenceSubmodelModal';
import { useAppParams } from 'util/useAppParams';

export const ConvertGroupToSubmodelTracker: React.FC = () => {
  const dispatch = useAppDispatch();
  const { triggerModal } = useModal();

  const { projectId } = useAppParams();

  const submodelToPublish = useAppSelector(
    (state) => state.submodels.submodelToPublish,
  );
  const groupBlockToConvertToSubmodel = useAppSelector(
    (state) => state.submodels.groupBlockToConvertToSubmodel,
  );

  React.useEffect(() => {
    if (!projectId || !groupBlockToConvertToSubmodel || !!submodelToPublish) {
      return;
    }

    triggerModal(
      <AddReferenceSubmodelModal
        projectId={projectId}
        groupBlockId={groupBlockToConvertToSubmodel.id}
        groupBlockName={groupBlockToConvertToSubmodel.name}
      />,
      t({
        id: 'ConvertToSubmodelModal.title',
        message: 'Convert to Submodel',
      }),
    );
    dispatch(submodelsActions.clearGroupToConvertToSubmodel());
  }, [
    dispatch,
    triggerModal,
    projectId,
    groupBlockToConvertToSubmodel,
    submodelToPublish,
  ]);

  return null;
};
