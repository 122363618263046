import { DiagramVersionFull } from 'app/apiTransformers/convertGetSnapshotReadByUuid';
import { ModelVersionRequestData } from 'app/slices/modelVersionsSlice';
import { TraceMetadata } from 'ui/dataExplorer/dataExplorerTypes';
import { buildSignalInfoForModelVersion } from 'util/modelVersionSignal';
import { getDefaultChartType } from 'util/timeMode';
import { v4 as uuid } from 'uuid';

export interface ExplorationAddSignalRequest {
  id: string;
  modelId: string;
  modelVersionId: string;
  simulationId: string;
  signalPath: string;
  vectorIndices?: number[];
}

export interface SignalTracesResult {
  // If there is a model (or submodel) version that we need to request,
  // it will be set here, otherwise this will be undefined.
  modelVersionToRequest?: ModelVersionRequestData;

  // If all the model and submodel versions that we needed to access the
  // requested signal path are available, the traces associated to that signal
  // will be available here, otherwise this will be undefined.
  traces?: TraceMetadata[];

  // If there is a problem loading the model versions for this request
  // and the request needs to be cancelled, the error message will be set,
  // otherwise this will be undefined.
  errorMessage?: string;
  isExpectedError?: boolean;
}

// FIXME: this does not seem useful anymore DASH-1751
export function getSignalTraces(
  request: ExplorationAddSignalRequest,
  modelIdToVersionIdToModelData: Record<
    string,
    Record<string, DiagramVersionFull>
  >,
): SignalTracesResult {
  const {
    timeMode,
    errorMessage,
    modelVersionToRequest,
    signalDisplayName: foundSignalDisplayName,
  } = buildSignalInfoForModelVersion(
    request.signalPath,
    request.modelId,
    request.modelVersionId,
    modelIdToVersionIdToModelData,
  );

  if (errorMessage) {
    return {
      errorMessage,
    };
  }
  if (modelVersionToRequest) {
    return {
      modelVersionToRequest,
    };
  }

  const signalDisplayName = foundSignalDisplayName || request.signalPath;

  // We were able to resolve the whole signal path, so create the trace metadata
  // so we can plot the signal traces.
  let traces: TraceMetadata[];
  if (request.vectorIndices) {
    traces = request.vectorIndices.map((vectorIndex) => ({
      id: uuid(),
      signalPath: request.signalPath,
      tracePath: `${request.signalPath}[${vectorIndex}]`,
      vectorIndex,
      displayName: `${signalDisplayName}[${vectorIndex}]`,
      plotType: getDefaultChartType(timeMode),
      modelId: request.modelId,
      explorationSimId: request.simulationId,
    }));
  } else {
    traces = [
      {
        id: uuid(),
        signalPath: request.signalPath,
        tracePath: request.signalPath,
        displayName: signalDisplayName,
        plotType: getDefaultChartType(timeMode),
        modelId: request.modelId,
        explorationSimId: request.simulationId,
      },
    ];
  }

  return {
    traces,
  };
}
