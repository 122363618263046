/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const DropdownExpanded: React.FC<Props> = (props) => (
  <svg viewBox="0 0 24 24" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M7.64645 14.6464C7.84171 14.4512 8.15829 14.4512 8.35355 14.6464L12 18.2929L15.6464 14.6464C15.8417 14.4512 16.1583 14.4512 16.3536 14.6464C16.5488 14.8417 16.5488 15.1583 16.3536 15.3536L12 19.7071L7.64645 15.3536C7.45118 15.1583 7.45118 14.8417 7.64645 14.6464Z"
      fill={props.fill}
    />
  </svg>
);

DropdownExpanded.defaultProps = {
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default DropdownExpanded;
