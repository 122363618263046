import styled from '@emotion/styled/macro';
import React from 'react';
import { useDebounce } from 'react-use';
import Button from 'ui/common/Button/Button';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import {
  TreeArrowCollapsed,
  TreeArrowExpanded,
} from 'ui/common/Icons/Standard';
import { Small } from 'ui/common/typography/Typography';

const SectionTitle = styled.div(({ theme }) => ({
  display: 'flex',
  width: '100%',
  alignItems: 'center',
  padding: theme.spacing.normal,
  flexShrink: 0,
}));

const TitleText = styled(Small)(({ theme }) => ({
  padding: theme.spacing.small,
}));

const SectionContent = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyItems: 'center',
  justifyContent: 'space-around',
  overflow: 'hidden',
  flexShrink: 0,
  paddingLeft: theme.spacing.normal,
}));

export const BlocksGrid = styled.div(({ theme: { spacing } }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 72px)',
  columnGap: 32,
  paddingBottom: spacing.xlarge,
  paddingTop: spacing.normal,
  paddingLeft: 8,
}));

const TitleCount = styled.div(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: 16,
  width: 24,
  marginLeft: 4,
  borderRadius: 4,
  background: 'none',
  border: `1px solid ${theme.colors.grey[30]}`,
  fontWeight: 'bold',
  fontSize: theme.typography.font.extraSmall.size,
  color: theme.colors.grey[50],
}));

interface Props {
  title?: string;
  children?: React.ReactNode;
  count?: number;
  forceOpen?: boolean;
  hide?: boolean;
}

const BlockSubsection: React.FC<Props> = ({
  children,
  title,
  count,
  forceOpen,
}) => {
  // If there's no title there's no expander, so default to expanded
  const [isExpanded_raw, setIsExpanded] = React.useState(!title);
  const manuallyExpanded = React.useRef(false);

  const isExpanded = isExpanded_raw && Boolean(count);

  // make sure to show the content while the closing animation runs (150ms)
  const [showContent, setShowContent] = React.useState(isExpanded);
  useDebounce(() => {
    setShowContent(isExpanded);
  }, 200);

  React.useEffect(() => {
    if (forceOpen === true) {
      setIsExpanded(true);
    } else if (!manuallyExpanded.current) {
      setIsExpanded(false);
    }
  }, [forceOpen]);

  return (
    <>
      {title && (
        <SectionTitle>
          <Button
            variant={ButtonVariants.LargeTertiary}
            Icon={isExpanded ? TreeArrowExpanded : TreeArrowCollapsed}
            onClick={() => {
              setIsExpanded(!isExpanded);
              manuallyExpanded.current = !isExpanded;
            }}
          />
          <TitleText>{title}</TitleText>
          {count !== undefined && <TitleCount>{count}</TitleCount>}
        </SectionTitle>
      )}
      {(isExpanded || showContent) && (
        <SectionContent>{children}</SectionContent>
      )}
    </>
  );
};

export default BlockSubsection;
