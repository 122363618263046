import React from 'react';
import styled from '@emotion/styled/macro';
import { customStripeContext } from 'ui/common/CustomStripeProvider';
import { usePostStripeManagementInfoMutation } from 'app/apiGenerated/generatedApi';
import { getFullBrowserHostUrl } from 'util/getFullBrowserHostUrl';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import Button from 'ui/common/Button/Button';
import { t } from '@lingui/macro';

const SubscriptionInfoInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const SubscriptionInfoTitle = styled.div`
  ${({ theme }) => `
    font-size: ${theme.typography.font.standard.size};
    font-weight: 400;
    color ${theme.colors.text.primary};
    line-height: 22px;
    margin-bottom: ${theme.spacing.large};
  `};
`;

const SubscriptionInfoLineItemList = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ theme }) => theme.spacing.xxlarge};
`;

const SubscriptionInfoLineItem = styled.div`
  display: flex;
  ${({ theme }) => `
    line-height: ${theme.spacing.xlarge};
    margin-bottom: ${theme.spacing.normal};
  `}

  &:last-child {
    margin-bottom: 0;
  }

  > * {
    width: 50%;
    flex-shrink: 0;
  }
`;
const SubscriptionInfoLineItemLabel = styled.div`
  ${({ theme }) => `
    font-size: ${theme.typography.font.base.size};
    font-weight: 500;
    color ${theme.colors.text.secondary};
  `};
`;
const SubscriptionInfoLineItemValue = styled.div`
  ${({ theme }) => `
    font-size: ${theme.typography.font.base.size};
    font-weight: 500;
    color ${theme.colors.text.primary};
  `};
`;

const SubscriptionButtonContainer = styled.div`
  display: flex;
`;

export const SubscriptionManagementModalContents = () => {
  const { subscriptionStatus } = React.useContext(customStripeContext);

  const billingDateString = React.useMemo(() => {
    if (!subscriptionStatus?.billing_time) return '';

    return new Date(subscriptionStatus.billing_time).toDateString();
  }, [subscriptionStatus]);

  const [callGetStripeManagementInfo] = usePostStripeManagementInfoMutation();
  const [managementUrl, setManagementUrl] = React.useState<string | null>();

  React.useEffect(() => {
    callGetStripeManagementInfo({
      userStripeManagementInfoRequest: {
        domain: getFullBrowserHostUrl(),
      },
    })
      .unwrap()
      .then((res) => {
        setManagementUrl(res.management_url);
      });
  }, [callGetStripeManagementInfo]);

  const statusTitleString = React.useMemo(() => {
    if (subscriptionStatus?.in_trial) {
      return t`Your subscription is in a trial.`;
    }

    if (subscriptionStatus?.paid_and_active) {
      return t`Your subscription is active.`;
    }

    return t`Your subscription is not active.`;
  }, [subscriptionStatus]);

  const validSub =
    subscriptionStatus?.in_trial || subscriptionStatus?.paid_and_active;

  return (
    <SubscriptionInfoInnerContainer>
      <SubscriptionInfoTitle>{statusTitleString}</SubscriptionInfoTitle>
      <SubscriptionInfoLineItemList>
        <SubscriptionInfoLineItem>
          <SubscriptionInfoLineItemLabel>
            {t`Current plan`}
          </SubscriptionInfoLineItemLabel>
          <SubscriptionInfoLineItemValue>
            {t`Basic`}
          </SubscriptionInfoLineItemValue>
        </SubscriptionInfoLineItem>
        {billingDateString && (
          <SubscriptionInfoLineItem>
            <SubscriptionInfoLineItemLabel>
              {validSub ? t`Next billing date` : t`Last billing date`}
            </SubscriptionInfoLineItemLabel>
            <SubscriptionInfoLineItemValue>
              {billingDateString}
            </SubscriptionInfoLineItemValue>
          </SubscriptionInfoLineItem>
        )}
      </SubscriptionInfoLineItemList>
      <SubscriptionButtonContainer>
        <Button
          disabled={!managementUrl}
          variant={ButtonVariants.LargePrimary}
          onClick={() => {
            if (!managementUrl) return;
            window.location.href = managementUrl;
          }}>
          {t`Manage Subscription`}
        </Button>
      </SubscriptionButtonContainer>
    </SubscriptionInfoInnerContainer>
  );
};
