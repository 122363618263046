/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Busselector: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M20.1234 9.2732C20.302 8.60636 19.9063 7.92094 19.2395 7.74226L17.7906 7.35404C17.1237 7.17536 16.4383 7.57109 16.2597 8.23792L14.5817 14.5H5.5C5.22386 14.5 5 14.7239 5 15V17C5 17.2761 5.22386 17.5 5.5 17.5H13.7779L12.3774 22.7268C12.1987 23.3936 12.5944 24.0791 13.2612 24.2577L14.7101 24.646C15.377 24.8246 16.0624 24.4289 16.2411 23.7621L16.7802 21.75H23.5C23.9142 21.75 24.25 21.4142 24.25 21C24.25 20.5858 23.9142 20.25 23.5 20.25H17.1821L18.12 16.75H23.5C23.9142 16.75 24.25 16.4142 24.25 16C24.25 15.5858 23.9142 15.25 23.5 15.25H18.5219L19.4597 11.75H23.5C23.9142 11.75 24.25 11.4142 24.25 11C24.25 10.5858 23.9142 10.25 23.5 10.25H19.8616L20.1234 9.2732ZM7 16.75V15.25H9V16.75H7ZM13 15.25H11V16.75H13V15.25ZM18.6098 9.12645L14.8569 23.1324L13.891 22.8736L17.6438 8.86763L18.6098 9.12645Z"
      fill={props.fill}
    />
  </svg>
);

Busselector.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Busselector;
