import { t } from '@lingui/macro';
import { useProject } from 'app/api/useProject';
import { useProjectActions } from 'app/api/useProjectActions';
import {
  ModelConfiguration,
  ModelCreateRequest,
} from 'app/apiGenerated/generatedApiTypes';
import {
  ModelDiagram,
  defaultModelConfiguration,
} from 'app/generated_types/SimulationModel';
import { useAppSelector } from 'app/hooks';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from 'ui/common/Button/Button';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import Input from 'ui/common/Input/Input';
import { isValidModelOrSubmodelName } from 'ui/common/Input/inputValidationForModels';
import Label from 'ui/common/Label';
import {
  ActionButtonContainer,
  ModalInputGroup,
  SmallFormContainer,
} from 'ui/common/Modal/Modal';
import { useModal } from 'ui/common/Modal/useModal';

interface Props {
  projectId: string;
  kind?: 'Model';
}

const AddModelModal: React.FC<Props> = ({ projectId, kind }) => {
  const [isValid, setIsValid] = React.useState(false);
  const [name, setName] = React.useState('');
  const { closeModal } = useModal();
  const navigate = useNavigate();

  const { createModel, isCreatingModel } = useProjectActions();

  const onCreateModel = async () => {
    if (!isValid) return;

    const diagram: ModelDiagram = { nodes: [], links: [] };
    const configuration: ModelConfiguration = defaultModelConfiguration;
    const modelCreateRequest: ModelCreateRequest = {
      name,
      project_uuid: projectId,
      diagram,
      configuration,
      kind,
    };
    const model = await createModel({ modelCreateRequest });
    if (model) {
      navigate(`/projects/${projectId}/models/${model.uuid}`);
    }
    closeModal();
  };

  const { project } = useProject();
  const submodels = useAppSelector(
    (state) => state.submodels.projectIdToSubmodelInfoLites[projectId],
  );
  const validationRules = React.useMemo(
    () => (project ? isValidModelOrSubmodelName('', project, submodels) : []),
    [project, submodels],
  );

  return (
    <SmallFormContainer
      onSubmit={(e) => {
        e?.preventDefault();
        onCreateModel();
      }}>
      <ModalInputGroup>
        <Label testId="duplicate-name-label">
          {t({
            id: 'addModelModal.nameInput.label',
            message: 'Name',
          })}
        </Label>
        <Input
          hasBorder
          autoFocus
          onChangeText={(v, isValid) => {
            setName(v);
            setIsValid(isValid);
          }}
          value={name}
          validationRules={validationRules}
          testId="model-name-textbox"
        />
      </ModalInputGroup>
      <ActionButtonContainer>
        {/* Cancel button */}
        <Button
          type="button"
          onClick={closeModal}
          variant={ButtonVariants.LargeSecondary}
          testId="cancel-new-model-button">
          {t({
            id: 'addModelModal.cancelButton.label',
            message: 'Cancel',
          })}
        </Button>

        {/* Create button */}
        <Button
          type="submit"
          variant={ButtonVariants.LargePrimary}
          loading={isCreatingModel}
          testId="add-model-button"
          disabled={!isValid}>
          {t({
            id: 'addModelModal.createButton.label',
            message: 'Create',
          })}
        </Button>
      </ActionButtonContainer>
    </SmallFormContainer>
  );
};

export default AddModelModal;
