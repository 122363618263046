import React from 'react';

export function getMouseCoords(
  ref: React.RefObject<HTMLDivElement>,
  e: MouseEvent | React.MouseEvent,
) {
  const rect = ref.current?.getBoundingClientRect();
  const left = e.clientX - (rect?.left || 0); // x position within the element.
  const top = e.clientY - (rect?.top || 0); // y position within the element.

  return { left, top };
}
