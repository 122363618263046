/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  className?: string;
}

const Electrical: React.FC<Props> = (props) => (
  <svg viewBox="0 0 8 8" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.06462 3.68878L5.82714 0.669973C5.88441 0.443213 5.62675 0.268989 5.43764 0.406611L1.12128 3.54786C0.925544 3.69031 1.02631 4 1.26839 4H2.69327C2.85607 4 2.97544 4.15314 2.93571 4.31102L2.17577 7.33044C2.1187 7.55719 2.37637 7.7312 2.56537 7.59355L6.87919 4.45209C7.07485 4.30961 6.97406 4 6.73202 4H5.30701C5.14412 4 5.02473 3.84671 5.06462 3.68878Z"
      fill={props.fill}
    />
  </svg>
);

Electrical.defaultProps = {
  width: '8',
  height: '8',
  viewBox: '0 0 8 8',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  className: '',
};

export default Electrical;
