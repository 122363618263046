import { callCompletion } from 'app/chat/genAiClient';
import { useAppSelector } from 'app/hooks';
import { selectEntityPrefs } from 'app/slices/entityPreferencesSlice';
import { Agent, ChatMessage } from 'app/third_party_types/chat-types';
import React, { useState } from 'react';
import { useWebSocket } from 'ui/common/WebSocketProvider';
import {
  CHAT_PREFS_V1_KEY,
  ChatPrefsV1,
  defaultChatPrefsV1,
} from 'ui/userPreferences/chatPrefs';
import { ChatMessageEdit, useChatContext } from './ChatContextProvider';
import { usePythonModelCallback } from './PythonHooks';
import { useFunctions } from './functions/useFunctions';

interface useCallChatCompletionProps {
  onNewMessage: (message: ChatMessage) => void;
  onChunkReceived: (edit: ChatMessageEdit, index?: number) => void;

  onError?: (message: string, error?: any) => void;

  modelId?: string;

  // used to override the system prompt and functions from advanced options in UI
  systemPrompt?: string;

  // definition of the tools, ie. functions.json
  tools?: string;
}

const useCallChatCompletion = ({
  modelId,
  tools,
  onNewMessage,
  onChunkReceived,
  onError,
  systemPrompt,
}: useCallChatCompletionProps) => {
  const { setIsCurrentlyCompleting } = useChatContext();
  const [openAiModelNotFound, setOpenAiModelNotFound] = useState(false);

  const { getCurrentModelInPython } = usePythonModelCallback();
  const websocket = useWebSocket();
  const chatPrefs =
    (useAppSelector((state) =>
      selectEntityPrefs(state, CHAT_PREFS_V1_KEY, modelId),
    ) as ChatPrefsV1) ?? defaultChatPrefsV1;

  const { defaultChatAiModel } = useAppSelector(
    (state) => state.userOptions.options,
  );

  const functions = useFunctions();

  const callChatCompletion = React.useCallback(
    async (
      agentRef: React.MutableRefObject<Agent>,
      messages: ChatMessage[],
      abortSignal: AbortSignal,
    ) => {
      setOpenAiModelNotFound(false);
      setIsCurrentlyCompleting(true);
      try {
        const { error } = await callCompletion({
          messages,
          websocket,
          abortSignal,
          temperature: chatPrefs.temperature,
          modelId: chatPrefs.aiModelId ?? defaultChatAiModel,
          systemPrompt,
          functions,
          tools,
          seed: chatPrefs.seed,
          onNewMessage,
          onChunkReceived,
          agentRef,
          getCurrentModelInPython,
        });

        if (error) {
          if (error.includes('status code: 404')) {
            // requested model not found, most likely the user didn't add funds to
            // his openai account.
            setOpenAiModelNotFound(true);
          } else {
            onError?.(
              `AI chat assistant encountered an issue (reason: ${error}).`,
            );
          }
        }
      } catch (e) {
        console.error(e);
        onError?.('Request to AI failed:', e);
      }

      setIsCurrentlyCompleting(false);
    },
    [
      chatPrefs.aiModelId,
      chatPrefs.seed,
      chatPrefs.temperature,
      defaultChatAiModel,
      functions,
      getCurrentModelInPython,
      onChunkReceived,
      onError,
      onNewMessage,
      setIsCurrentlyCompleting,
      systemPrompt,
      tools,
      websocket,
    ],
  );

  return {
    openAiModelNotFound,
    callChatCompletion,
  };
};

export default useCallChatCompletion;
