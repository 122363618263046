import { useAdminApis } from 'app/api/useAdminApis';
import { AdminUserInfo } from 'app/apiGenerated/generatedApiTypes';
import React, { FormEventHandler } from 'react';
import Button from 'ui/common/Button/Button';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import Input from 'ui/common/Input/Input';
import Label from 'ui/common/Label';
import {
  ActionButtonContainer,
  ModalInputGroup,
  SmallFormContainer,
} from 'ui/common/Modal/Modal';
import { useModal } from 'ui/common/Modal/useModal';
import SelectInput, { SelectInputOption } from 'ui/common/SelectInput';
import { useAppParams } from 'util/useAppParams';

const NewUserOptionOverrideModal: React.FC<{ user: AdminUserInfo }> = ({
  user,
}) => {
  const { userId } = useAppParams();
  const { closeModal } = useModal();
  const { userOptions, userOptionsOverrides, createUserOptionOverride } =
    useAdminApis();
  const [optionUuid, setOptionUuid] = React.useState('');
  const [value, setValue] = React.useState('');

  if (!userId || !userOptions || !userOptionsOverrides) return null;

  const selectedOptionDescription = userOptions?.user_options.find(
    (option) => option.uuid === optionUuid,
  )?.description;

  const options: SelectInputOption[] = userOptions?.user_options
    .filter(
      (option) =>
        !userOptionsOverrides.user_option_overrides.some(
          (override) =>
            override.user_option_uuid === option.uuid &&
            override.user_uuid === user.uuid,
        ),
    )
    .map((option) => ({
      label: option.name,
      value: option.uuid,
    }));

  const submit: FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    await createUserOptionOverride(userId, optionUuid, value);
    closeModal();
  };
  return (
    <SmallFormContainer onSubmit={submit}>
      <ModalInputGroup>
        <Label>User option name</Label>
        <SelectInput
          options={options}
          onSelectValue={setOptionUuid}
          currentValue={optionUuid}
        />
      </ModalInputGroup>
      {selectedOptionDescription && (
        <ModalInputGroup>
          <Label>User option description</Label>
          <p>{selectedOptionDescription}</p>
        </ModalInputGroup>
      )}
      <ModalInputGroup>
        <Label>value</Label>
        <Input value={value} hasBorder onChangeText={(v) => setValue(v)} />
      </ModalInputGroup>
      <ActionButtonContainer>
        <Button type="submit" variant={ButtonVariants.LargePrimary}>
          Create
        </Button>
      </ActionButtonContainer>
    </SmallFormContainer>
  );
};

export default NewUserOptionOverrideModal;
