import {
  AnnotationInstance,
  LinkInstance,
  NodeInstance,
} from 'app/generated_types/SimulationModel';
import { RendererState } from './modelRenderer';

export const getSelectedEntitiesFromRendererState = (
  rs: RendererState,
  overrideSelectedNodeIds?: string[],
  overrideSelectedLinkIds?: string[],
  overrideSelectedAnnotationIds?: string[],
): {
  selectedNodes: NodeInstance[];
  selectedLinks: LinkInstance[];
  selectedAnnotations: AnnotationInstance[];
} => {
  const {
    links,
    nodes,
    annotations,
    linksIndexLUT,
    nodesIndexLUT,
    annotationsIndexLUT,
    selectedNodeIds: stateNodeIds,
    selectedLinkIds: stateLinkIds,
    selectedAnnotationIds: stateAnnotationIds,
  } = rs.refs.current;

  const selectedNodeIds = overrideSelectedNodeIds || stateNodeIds;
  const selectedLinkIds = overrideSelectedLinkIds || stateLinkIds;
  const selectedAnnotationIds =
    overrideSelectedAnnotationIds || stateAnnotationIds;

  const selectedNodes = selectedNodeIds.reduce<NodeInstance[]>(
    (acc, uuid: string) => {
      const mappedNodeIndex = nodesIndexLUT[uuid];
      const mappedNode = nodes[isNaN(mappedNodeIndex) ? -1 : mappedNodeIndex];
      if (mappedNode) return [...acc, mappedNode];
      return acc;
    },
    [],
  );

  const selectedLinks = selectedLinkIds.reduce<LinkInstance[]>(
    (acc, uuid: string) => {
      const mappedLinkIndex = linksIndexLUT[uuid];
      const mappedLink = links[isNaN(mappedLinkIndex) ? -1 : mappedLinkIndex];
      if (mappedLink) return [...acc, mappedLink];
      return acc;
    },
    [],
  );

  const selectedAnnotations = selectedAnnotationIds.reduce<
    AnnotationInstance[]
  >((acc, uuid: string) => {
    const mappedAnnotationsIndex = annotationsIndexLUT[uuid];
    const mappedAnnotations =
      annotations[isNaN(mappedAnnotationsIndex) ? -1 : mappedAnnotationsIndex];
    if (mappedAnnotations) return [...acc, mappedAnnotations];
    return acc;
  }, []);

  return { selectedNodes, selectedLinks, selectedAnnotations };
};
