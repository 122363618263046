/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Matrix: React.FC<Props> = (props) => (
  <svg viewBox="0 0 16 16" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M3.5 3C3.22386 3 3 3.22386 3 3.5V12.5C3 12.7761 3.22386 13 3.5 13H12.5C12.7761 13 13 12.7761 13 12.5V3.5C13 3.22386 12.7761 3 12.5 3H3.5ZM11.5 9C11.7761 9 12 8.77614 12 8.5V7.5C12 7.22386 11.7761 7 11.5 7H10.5C10.2239 7 10 7.22386 10 7.5V8.5C10 8.77614 10.2239 9 10.5 9H11.5ZM8.5 9C8.77614 9 9 8.77614 9 8.5V7.5C9 7.22386 8.77614 7 8.5 7H7.5C7.22386 7 7 7.22386 7 7.5V8.5C7 8.77614 7.22386 9 7.5 9H8.5ZM12 10.5C12 10.2239 11.7761 10 11.5 10H10.5C10.2239 10 10 10.2239 10 10.5V11.5C10 11.7761 10.2239 12 10.5 12H11.5C11.7761 12 12 11.7761 12 11.5V10.5ZM8.5 12C8.77614 12 9 11.7761 9 11.5V10.5C9 10.2239 8.77614 10 8.5 10H7.5C7.22386 10 7 10.2239 7 10.5V11.5C7 11.7761 7.22386 12 7.5 12H8.5ZM5.5 10C5.77614 10 6 10.2239 6 10.5V11.5C6 11.7761 5.77614 12 5.5 12H4.5C4.22386 12 4 11.7761 4 11.5V10.5C4 10.2239 4.22386 10 4.5 10H5.5ZM4 8.5C4 8.77614 4.22386 9 4.5 9H5.5C5.77614 9 6 8.77614 6 8.5V7.5C6 7.22386 5.77614 7 5.5 7H4.5C4.22386 7 4 7.22386 4 7.5V8.5ZM12 5.5C12 5.77614 11.7761 6 11.5 6H10.5C10.2239 6 10 5.77614 10 5.5V4.5C10 4.22386 10.2239 4 10.5 4H11.5C11.7761 4 12 4.22386 12 4.5V5.5ZM7 5.5C7 5.77614 7.22386 6 7.5 6H8.5C8.77614 6 9 5.77614 9 5.5V4.5C9 4.22386 8.77614 4 8.5 4H7.5C7.22386 4 7 4.22386 7 4.5V5.5ZM6 5.5C6 5.77614 5.77614 6 5.5 6H4.5C4.22386 6 4 5.77614 4 5.5V4.5C4 4.22386 4.22386 4 4.5 4H5.5C5.77614 4 6 4.22386 6 4.5V5.5Z"
      fill={props.fill}
    />
  </svg>
);

Matrix.defaultProps = {
  width: '16',
  height: '16',
  viewBox: '0 0 16 16',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Matrix;
