import { PayloadAction } from '@reduxjs/toolkit';
import { ModelState } from 'app/modelState/ModelState';

export function setInitScriptName(
  state: ModelState,
  action: PayloadAction<{ file_name: string; isSubmodel: boolean }>,
) {
  const { file_name, isSubmodel } = action.payload;

  if (isSubmodel) {
    if (file_name) {
      state.submodelConfiguration.init_script = {
        value: file_name,
        is_string: true,
      };
    } else {
      delete state.submodelConfiguration.init_script;
    }
    return;
  }

  if (!file_name) {
    if (state.configuration.workspace)
      delete state.configuration.workspace.init_scripts;
    return;
  }

  const initScript = { file_name };
  if (!state.configuration.workspace) state.configuration.workspace = {};
  state.configuration.workspace.init_scripts = [initScript];
}

export function setIsAtomic(
  state: ModelState,
  action: PayloadAction<{ isAtomic: boolean }>,
) {
  const { isAtomic } = action.payload;

  state.submodelConfiguration.atomic = {
    value: isAtomic ? 'true' : 'false',
  };
}

export function setDiscreteStep(
  state: ModelState,
  action: PayloadAction<string>,
) {
  const value = action.payload;

  state.submodelConfiguration.discrete_step = {
    value,
  };
}

export function unsetDiscreteStep(state: ModelState) {
  state.submodelConfiguration.discrete_step = undefined;
}
