import { overridenParamsMap } from 'app/slices/ensembleSimsSlice';

// Associated Entity Id: modelId
export const ENSEMBLE_SIM_PREFS_V1_KEY = 'ENSEMBLE_SIM_PREFS_V1';

export interface CommonEnsembleSimPrefs {
  overridenParams: overridenParamsMap;
  signals: Array<string>;
}

export interface MonteCarloPrefs extends CommonEnsembleSimPrefs {
  numSims: number;
}

export interface EnsemblePrefUpdate {
  overridenParams?: overridenParamsMap;
  signals?: Array<string | undefined>;
  numSims?: number;
}

export type EnsembleTab = 'paramSweep' | 'monteCarlo';

export interface EnsembleSimPrefsV1 {
  selectedTab: EnsembleTab;
  monteCarlo: MonteCarloPrefs;
  paramSweep: CommonEnsembleSimPrefs;
}

export const defaultEnsembleSimPrefsV1: EnsembleSimPrefsV1 = {
  selectedTab: 'paramSweep',
  monteCarlo: {
    overridenParams: {},
    signals: [],
    numSims: 1,
  },
  paramSweep: {
    overridenParams: {},
    signals: [],
  },
};
