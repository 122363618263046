// Functions implementation for the model builder agent, see src/services/goapi/internal/goapi/chat/agents/causal/functions.json

import { CallbackResult, GptFunction } from 'app/chat/responseStreamParser';
import { useAppSelector } from 'app/hooks';
import { ModelLogLine, OutputLogLevel } from 'app/slices/simResultsSlice';
import { Agent } from 'app/third_party_types/chat-types';
import React from 'react';
import { parseSimulationLogs } from 'ui/modelEditor/utils';
import { useSimworkerJobRunner } from '../../../../app/SimworkerJobRunner';
import { useChatContext } from '../ChatContextProvider';
import { usePythonModelCallback } from '../PythonHooks';
import { useModelEditorInfo } from '../useModelEditorInfo';
import { useSearchBlocks } from './SearchBlocks';
import { useUpdateModel } from './UpdateModel';

export const useModelBuilderFunctions = (): {
  [key: string]: GptFunction;
} => {
  const { loadedModelId } = useAppSelector((state) => state.modelMetadata);

  const acausalModelingEnabled = useAppSelector(
    (state) => state.userOptions.options.acausalModelingEnabled,
  );

  const { search_blocks } = useSearchBlocks(acausalModelingEnabled);

  const { isModelReadOnly } = useModelEditorInfo();

  const { validateCurrentPythonModel } = usePythonModelCallback();

  const { runModelCheck } = useSimworkerJobRunner();

  const updateModelCallback = useUpdateModel();

  const compileCallback = React.useCallback(
    async (
      args: any,
      onComplete: (r: CallbackResult) => void,
      abortSignal?: AbortSignal,
    ) => {
      if (isModelReadOnly) {
        onComplete({
          error: "read-only models can't be validated.",
        });
        return;
      }
      const pyError = await validateCurrentPythonModel();
      const pyCheck = `python check:\n${pyError || 'No issues found.'}`;
      try {
        runModelCheck(({ logs, error }) => {
          if (error) {
            onComplete({
              error: `internal error: ${error}`,
            });
            return;
          }
          const simulationLogs = parseSimulationLogs(logs || '');

          const errorLogs = simulationLogs.filter(
            (log: ModelLogLine) =>
              log.level !== undefined &&
              [OutputLogLevel.WARNING, OutputLogLevel.ERROR].includes(
                log.level,
              ),
          );
          const compilationCheck =
            errorLogs.length > 0
              ? errorLogs.map((log: ModelLogLine) => log.message).join('\n')
              : 'No issues found.';
          onComplete({
            result: `${pyCheck}\ncompilation check:\n${compilationCheck}`,
          });
        }, abortSignal);
      } catch (e: any) {
        onComplete({
          error: `${pyCheck}\ncompilation check:\n${
            e.message || 'unknown error'
          }`,
        });
      }
    },
    [isModelReadOnly, runModelCheck, validateCurrentPythonModel],
  );

  const { setAgent } = useChatContext();
  const returnToMainAgentCallback = React.useCallback(
    (args: any, onComplete: (r: CallbackResult) => void) => {
      const { request } = args;
      setAgent(Agent.Main);
      onComplete({
        result: request || 'Please process the last user request.',
      });
    },
    [setAgent],
  );

  const askCustomBlockBuilder = React.useCallback(
    (args: any, onComplete: (r: CallbackResult) => void) => {
      const { description } = args;
      setAgent(Agent.CustomBlockBuilder);
      onComplete({
        result: description,
        toolChoice: 'required',
      });
    },
    [setAgent],
  );

  const groupCallback = React.useCallback(
    async (args: any, onComplete: (r: CallbackResult) => void) => {
      const { name, block_ids, output_ports } = args;
      let code;
      if (output_ports === undefined) {
        code = `group("${name}", [${block_ids}])`;
      } else {
        code = `group("${name}", [${block_ids}], [${output_ports}])`;
      }
      code = `${code}\nprint(f"input ports: {model_builder.inport_names('${name}')}")`;
      code = `${code}\nprint(f"output ports: {model_builder.outport_names('${name}')}")`;
      await updateModelCallback(
        {
          code,
          is_new: false,
        },
        onComplete,
      );
    },
    [updateModelCallback],
  );

  const addParameterCallback = React.useCallback(
    async (args: any, onComplete: (r: CallbackResult) => void) => {
      const { name, value } = args;
      let code;
      code = `add_parameter("${name}", ${value})`;
      await updateModelCallback(
        {
          code,
          is_new: false,
        },
        onComplete,
      );
    },
    [updateModelCallback],
  );

  const addLinkCallback = React.useCallback(
    async (args: any, onComplete: (r: CallbackResult) => void) => {
      const { from, src, to, dst } = args;
      let code;
      code = `add_link(${from || src}, ${to || dst})`;
      await updateModelCallback(
        {
          code,
          is_new: false,
        },
        onComplete,
      );
    },
    [updateModelCallback],
  );

  return {
    check_model: compileCallback,
    execute_commands: updateModelCallback,
    search_blocks,
    return_to_main_agent: returnToMainAgentCallback,
    ask_custom_block_builder: askCustomBlockBuilder,
    group: groupCallback,
    // below functions are not in the tools description but are sometimes called
    // by the model builder agent instead of calling execute_commands.
    add_parameter: addParameterCallback,
    add_link: addLinkCallback,
  };
};
