import styled from '@emotion/styled/macro';
import { t } from '@lingui/macro';
import { useGetGlobalSubmodelsReadQuery } from 'app/apiGenerated/generatedApi';
import { ModelKind } from 'app/apiGenerated/generatedApiTypes';
import { SubmodelInfoLiteUI } from 'app/apiTransformers/convertGetSubmodelsList';
import { SubmodelInfoUI } from 'app/apiTransformers/convertGetSubmodelsListForModelParent';
import { VersionTagValues } from 'app/apiTransformers/convertPostSubmodelsFetch';
import blockTypeNameToInstanceDefaults from 'app/blockClassNameToInstanceDefaults';
import { Coordinate } from 'app/common_types/Coordinate';
import { blockClassLookup } from 'app/generated_blocks';
import {
  BlockClassName,
  NodeInstance,
} from 'app/generated_types/SimulationModel';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
  modelActions,
  selectCurrentSubdiagramType,
} from 'app/slices/modelSlice';
import { submodelsActions } from 'app/slices/submodelsSlice';
import { uiFlagsActions } from 'app/slices/uiFlagsSlice';
import { snapCoordinateToGrid } from 'app/utils/modelDataUtils';
import deepmerge from 'deepmerge';
import React from 'react';
import { getBuiltInBlockDisplayData } from 'ui/objectBrowser/BlockDisplayData';
import {
  LibItemDisplayType,
  searchFoundationalBlocks,
} from 'ui/objectBrowser/sections/FoundationalBlocks';
import SectionSearch from 'ui/objectBrowser/sections/SectionSearch';
import { useAppParams } from 'util/useAppParams';

const CommandPaletteContainer = styled.div<{ position: Coordinate }>`
  display: flex;
  flex-direction: column;
  position: absolute;
  left: ${({ position }) => position.x}px;
  top: ${({ position }) => position.y}px;
  width: 200px;
`;

const CommandPaletteSearchResultsContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  width: 100%;
  max-height: 120px;
  overflow-y: auto;
  overflow-x: hidden;
  ${({ theme }) => `
    padding: ${theme.spacing.normal};
    scroll-padding: ${theme.spacing.normal};
    border-radius: ${theme.spacing.normal};
  `}
`;

const CommandPaletteSearchResultItem = styled.div<{ selected: boolean }>`
  display: flex;
  align-items: end;
  flex-shrink: 0;
  ${({ theme, selected }) => `
    background: ${
      selected ? theme.colors.brand.primary.lighter : 'transparent'
    };
    padding: 0 ${theme.spacing.normal};
    border-radius: ${theme.spacing.small};
  `}

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 12px;
  line-height: 20px;
  cursor: pointer;
`;

const ResultNamespace = styled.div`
  font-size: 9px;
`;

type LibrarySearchResult = {
  blockClassName: BlockClassName;
  displayName: string;
  overrideDefaults?: Partial<NodeInstance>;
  submodelData?: SubmodelInfoLiteUI;
};

const searchAllLibraryBlocks = (
  searchString: string,
  submodels: SubmodelInfoLiteUI[],
  highLevelBlocks: SubmodelInfoLiteUI[],
  developerModeEnabled: boolean,
  topLevelModelKind: ModelKind,
  currentSubdiagramType?: BlockClassName,
  acausalModelingEnabled?: boolean,
): Array<LibrarySearchResult> => {
  const filteredBlocks = searchFoundationalBlocks(
    searchString,
    developerModeEnabled,
    topLevelModelKind,
    currentSubdiagramType,
    acausalModelingEnabled,
  );

  const foundationalBlocks = filteredBlocks.map((data) => {
    if (data.type === LibItemDisplayType.Base) {
      const displayData = getBuiltInBlockDisplayData(data.blockClassName);
      return {
        blockClassName: displayData.blockClassName,
        displayName: displayData.nodeTypePrintName,
      };
    }

    return {
      blockClassName: data.pseudoData.baseBlockClassName,
      displayName: data.pseudoData.displayName,
      overrideDefaults: data.pseudoData.overrideDefaults,
    };
  });

  // const massageSubmodelData = (submodelData: SubmodelInfoLiteUI) => {
  //   const displayData = getReferenceSubmodelBlockDisplayData(submodelData);
  //   return {
  //     blockClassName: displayData.blockClassName,
  //     displayName: displayData.nodeTypePrintName,
  //     submodelData,
  //   };
  // };

  // const submodelBlocks = searchSubmodelBlocks(
  //   submodels,
  //   searchString,
  //   developerModeEnabled,
  // ).map(massageSubmodelData);

  // const globalSubmodelBlocks = searchSubmodelBlocks(
  //   highLevelBlocks,
  //   searchString,
  //   developerModeEnabled,
  // ).map(massageSubmodelData);

  // TODO: re-enable submodels in results after figuring out
  // why i'm not getting full SubmodelInfoUI data
  // return [...submodelBlocks, ...globalSubmodelBlocks, ...foundationalBlocks];
  return foundationalBlocks;
};

export const CommandPalette = () => {
  const dispatch = useAppDispatch();

  const [rawSelectedItemIndex, setSelectedItemIndex] =
    React.useState<number>(0);

  const { projectId, modelId } = useAppParams();
  const { developerModeEnabled } = useAppSelector(
    (state) => state.userOptions.options,
  );
  const [blockSearchString, setSearchString_raw] = React.useState('');
  const setSearchString = (newVal: string) => {
    setSearchString_raw(newVal);
    setSelectedItemIndex(0);
  };

  const currentSubmodelPath = useAppSelector(
    (state) => state.model.present.currentSubmodelPath,
  );

  const currentSubdiagramType = useAppSelector((state) =>
    selectCurrentSubdiagramType(state.model.present),
  );

  const topLevelModelKind =
    useAppSelector((state) => state.submodels.topLevelModelType) || 'Model';
  const { commandPaletteCoordScreenSpace, commandPaletteCoordWorldSpace } =
    useAppSelector((state) => ({
      commandPaletteCoordScreenSpace:
        state.uiFlags.commandPaletteCoordScreenSpace,
      commandPaletteCoordWorldSpace:
        state.uiFlags.commandPaletteCoordWorldSpace,
    }));
  const closeCommandPalette = () => {
    dispatch(
      uiFlagsActions.setUIFlag({
        showingCommandPalette: false,
      }),
    );
  };

  const submodels = useAppSelector(
    (state) => state.submodels.projectIdToSubmodelInfoLites[projectId || ''],
  );
  const submodelsToShow =
    submodels?.filter((submodel) => submodel.id !== modelId) || [];
  const { data: highLevelBlocks } = useGetGlobalSubmodelsReadQuery();

  const acausalModelingEnabled = useAppSelector(
    (state) => state.userOptions.options.acausalModelingEnabled,
  );

  const cleanedSearchString = blockSearchString.trim().toLowerCase();
  const foundBlocks =
    cleanedSearchString.length > 0
      ? searchAllLibraryBlocks(
          cleanedSearchString,
          submodelsToShow,
          highLevelBlocks || [],
          developerModeEnabled,
          topLevelModelKind,
          currentSubdiagramType,
          acausalModelingEnabled,
        ).map((data) => {
          const blockClass = blockClassLookup(data.blockClassName);
          return { ...data, namespace: blockClass.base.namespace };
        })
      : [];

  const addBlockToModel = (blockData: LibrarySearchResult) => {
    const blockClassName = blockData.blockClassName;

    const baseNodeData: NodeInstance = blockTypeNameToInstanceDefaults(
      blockClassName as BlockClassName,
      undefined,
      blockData.submodelData?.id,
    );
    const overrideProps = blockData.overrideDefaults;
    const nodeData: NodeInstance = {
      ...baseNodeData,
      ...overrideProps,
      parameters: overrideProps?.parameters
        ? deepmerge(baseNodeData.parameters, overrideProps.parameters)
        : baseNodeData.parameters,
    };
    nodeData.uiprops = {
      ...nodeData.uiprops,
      ...snapCoordinateToGrid(commandPaletteCoordWorldSpace),
    };

    if (
      blockData.submodelData &&
      !(blockData.submodelData as SubmodelInfoUI).portDefinitionsInputs
    ) {
      dispatch(
        submodelsActions.requestLoadSubmodelInfos([
          {
            submodel_uuid: blockData.submodelData.id,
            version: VersionTagValues.LATEST_VERSION,
          },
        ]),
      );
    }

    const referenceSubmodelIdToSubmodel: Record<string, SubmodelInfoLiteUI> =
      blockData.submodelData
        ? {
            [blockData.submodelData.id]: blockData.submodelData,
          }
        : {};

    dispatch(
      modelActions.addPremadeEntitiesToModel({
        nodes: [nodeData],
        links: [],
        referenceSubmodelIdToSubmodel,
      }),
    );

    dispatch(
      modelActions.setSelections({
        selectionParentPath: currentSubmodelPath,
        selectedBlockIds: [nodeData.uuid],
        selectedLinkIds: [],
        selectedAnnotationIds: [],
      }),
    );
  };

  const selectedItemIndex = Math.max(
    0,
    Math.min(foundBlocks.length - 1, rawSelectedItemIndex),
  );

  const onSubmit = (itemIndex?: number) => {
    if (foundBlocks.length > 0) {
      const usingIndex = itemIndex != undefined ? itemIndex : selectedItemIndex;
      const usingResult = foundBlocks[usingIndex];
      addBlockToModel(usingResult);
    }

    closeCommandPalette();
  };

  const searchInputRef = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    const searchInputEl = searchInputRef.current;

    const arrowsListener = (e: KeyboardEvent) => {
      const isUp = e.code === 'ArrowUp';
      const isDown = e.code === 'ArrowDown';

      if (isUp || isDown) {
        e.preventDefault();
      }
      if (isUp) {
        setSelectedItemIndex((currentIndex) => Math.max(0, currentIndex - 1));
      }
      if (isDown) {
        setSelectedItemIndex((currentIndex) =>
          Math.min(foundBlocks.length - 1, currentIndex + 1),
        );
      }
    };

    if (searchInputEl != null) {
      searchInputEl.addEventListener('keydown', arrowsListener);
    }

    return () => {
      if (searchInputEl != null) {
        searchInputEl.removeEventListener('keydown', arrowsListener);
      }
    };
  }, [foundBlocks.length]);

  const resultsContainerRef = React.useRef<HTMLDivElement | null>(null);

  // keep our selection visible when using the keyboard to nav
  React.useEffect(() => {
    if (foundBlocks.length > 0 && resultsContainerRef.current) {
      const resultEl =
        resultsContainerRef.current.children.item(selectedItemIndex);

      if (resultEl) {
        resultEl.scrollIntoView({ block: 'nearest' });
      }
    }
  }, [resultsContainerRef, foundBlocks.length, selectedItemIndex]);

  return (
    <CommandPaletteContainer position={commandPaletteCoordScreenSpace}>
      <SectionSearch
        placeholder={t({
          id: 'modelEditor.commandPalette.search.placeholder',
          message: 'Add a block',
        })}
        onChangeText={setSearchString}
        onCancel={closeCommandPalette}
        onSubmit={(_, __) => onSubmit()}
        inputRef={searchInputRef}
        autoFocus
      />
      {foundBlocks.length > 0 && (
        <CommandPaletteSearchResultsContainer ref={resultsContainerRef}>
          {foundBlocks.map((foundBlock, i) => (
            <CommandPaletteSearchResultItem
              onMouseEnter={(_) => setSelectedItemIndex(i)}
              onClick={(_) => onSubmit(i)}
              selected={selectedItemIndex === i}>
              <ResultNamespace>
                {foundBlock.namespace !== 'core'
                  ? `${foundBlock.namespace}.`
                  : ''}
              </ResultNamespace>
              {foundBlock.displayName}
            </CommandPaletteSearchResultItem>
          ))}
        </CommandPaletteSearchResultsContainer>
      )}
    </CommandPaletteContainer>
  );
};
