/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const SortAlphaDescending: React.FC<Props> = (props) => (
  <svg viewBox="0 0 16 16" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.5 2.5C5.5 2.22386 5.27614 2 5 2C4.72386 2 4.5 2.22386 4.5 2.5V12.2929L3.35355 11.1464C3.15829 10.9512 2.84171 10.9512 2.64645 11.1464C2.45118 11.3417 2.45118 11.6583 2.64645 11.8536L5 14.2071L7.35355 11.8536C7.54882 11.6583 7.54882 11.3417 7.35355 11.1464C7.15829 10.9512 6.84171 10.9512 6.64645 11.1464L5.5 12.2929V2.5Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M11.6051 7C11.5618 7 11.5337 6.97619 11.5208 6.92857L11.3262 6.22857C11.3176 6.20952 11.3067 6.2 11.2938 6.2H9.69844C9.68547 6.2 9.67466 6.20952 9.66602 6.22857L9.47795 6.92857C9.46498 6.97619 9.43688 7 9.39364 7H8.57004C8.5441 7 8.52464 6.99286 8.51167 6.97857C8.4987 6.95952 8.49654 6.93333 8.50519 6.9L9.90597 2.07143C9.91894 2.02381 9.94704 2 9.99027 2H11.0084C11.0517 2 11.0798 2.02381 11.0927 2.07143L12.4935 6.9C12.4978 6.90952 12.5 6.92143 12.5 6.93571C12.5 6.97857 12.4762 7 12.4287 7H11.6051ZM9.89948 5.37857C9.89516 5.40714 9.9038 5.42143 9.92542 5.42143H11.0668C11.0927 5.42143 11.1014 5.40714 11.0927 5.37857L10.5091 3.25714C10.5048 3.2381 10.4983 3.23095 10.4896 3.23571C10.481 3.23571 10.4745 3.24286 10.4702 3.25714L9.89948 5.37857Z"
      fill={props.fill}
    />
    <path
      d="M8.59856 13.5C8.57118 13.5 8.54654 13.4929 8.52464 13.4786C8.50821 13.4595 8.5 13.4381 8.5 13.4143V12.6429C8.5 12.6048 8.51369 12.569 8.54107 12.5357L11.194 9.40714C11.205 9.39762 11.205 9.3881 11.194 9.37857C11.1886 9.36905 11.1776 9.36429 11.1612 9.36429H8.59856C8.57118 9.36429 8.54654 9.35714 8.52464 9.34286C8.50821 9.32381 8.5 9.30238 8.5 9.27857V8.58571C8.5 8.5619 8.50821 8.54286 8.52464 8.52857C8.54654 8.50952 8.57118 8.5 8.59856 8.5H12.4014C12.4288 8.5 12.4507 8.50952 12.4671 8.52857C12.489 8.54286 12.5 8.5619 12.5 8.58571V9.35714C12.5 9.39524 12.4863 9.43095 12.4589 9.46429L9.80595 12.5929C9.80048 12.6024 9.80048 12.6119 9.80595 12.6214C9.81143 12.631 9.82238 12.6357 9.83881 12.6357H12.4014C12.4288 12.6357 12.4507 12.6452 12.4671 12.6643C12.489 12.6786 12.5 12.6976 12.5 12.7214V13.4143C12.5 13.4381 12.489 13.4595 12.4671 13.4786C12.4507 13.4929 12.4288 13.5 12.4014 13.5H8.59856Z"
      fill={props.fill}
    />
  </svg>
);

SortAlphaDescending.defaultProps = {
  width: '16',
  height: '16',
  viewBox: '0 0 16 16',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default SortAlphaDescending;
