import { useSubmodels } from 'app/api/useSubmodels';
import React from 'react';
import { Outlet } from 'react-router-dom';
import ProtectedRoute from 'ui/auth/ProtectedRoute';
import { useAppParams } from 'util/useAppParams';

const ProjectsPage: React.FC = () => {
  const { projectId } = useAppParams();

  // Why does this live here? Do any of the list pages care, or is it just the model editor page?
  useSubmodels(projectId);

  return (
    <ProtectedRoute>
      <Outlet />
    </ProtectedRoute>
  );
};

export default ProjectsPage;
