/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Library: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M7.75 8.26578V22.2579C10.5187 22.3168 12.6615 22.7027 14.1495 23.2876C14.5573 23.4478 14.9263 23.6271 15.25 23.8254V12C15.25 11.2123 14.6281 10.3178 13.1871 9.55904C11.8372 8.84824 9.93257 8.35805 7.75 8.26578ZM16 9.91994C15.4821 9.24628 14.7353 8.67894 13.886 8.23178C12.2324 7.36115 9.98891 6.82341 7.52034 6.75699C6.8095 6.73787 6.25 7.31471 6.25 8.00001V22.5C6.25 23.1946 6.81244 23.7438 7.49002 23.7535C10.2718 23.7933 12.2982 24.1716 13.6008 24.6836C14.2533 24.94 14.6876 25.2158 14.9471 25.4671C15.2031 25.715 15.25 25.8968 15.25 26H16.75C16.75 25.8968 16.7969 25.715 17.0529 25.4671C17.3124 25.2158 17.7467 24.94 18.3992 24.6836C19.7018 24.1716 21.7282 23.7933 24.51 23.7535C25.1876 23.7438 25.75 23.1946 25.75 22.5V8.00001C25.75 7.31471 25.1905 6.73787 24.4797 6.75699C22.0111 6.82341 19.7676 7.36115 18.114 8.23178C17.2647 8.67894 16.5179 9.24628 16 9.91994ZM16.75 23.8254C17.0737 23.6271 17.4427 23.4478 17.8505 23.2876C19.3385 22.7027 21.4813 22.3168 24.25 22.2579V8.26578C22.0674 8.35805 20.1628 8.84824 18.8129 9.55904C17.3719 10.3178 16.75 11.2123 16.75 12V23.8254Z"
      fill={props.fill}
    />
  </svg>
);

Library.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Library;
