// !!!! -- DO NOT MANUALLY EDIT -- !!!!
// This file was generated by something in scripts/generateDefaultBlockData.js
// If you want to change the contents of this file, it should be automated
//
// (You can edit this file locally, but it may not be checked into the codebase -
// PRs with a manual edit of this file are forbidden from being merged.)

import { computationBlockDefaults } from 'app/generated_blocks/computationBlockDefaults';
import type { ComputationBlockClass } from 'app/generated_types/ComputationBlockClass';
import deepmerge from 'deepmerge';

export const LinearizedSystem: ComputationBlockClass = deepmerge(
  computationBlockDefaults,
  {
    "base": {
      "name": "LinearizedSystem",
      "namespace": "core",
      "description": "Description needed",
      "extra_parameters": false,
      "hidden": true,
      "feature_level": "dev"
    },
    "modes": {
      "time": "any"
    },
    "ports": {
      "has_automatic_ports": true
    },
    "parameter_definitions": [
      {
        "name": "python_file",
        "display_name": "Operating points",
        "description": "Name of the python file to refer to for operating point defintiion.",
        "data_type": "string",
        "default_value": ""
      }
    ]
  }
);
