import {
  HoverEdgeSide,
  HoverEntityType,
  MouseActions,
} from 'app/common_types/MouseTypes';
import { RENDERER_EXPERIMENTAL_SMOOTH_ENTITY_MOVE } from 'app/config/globalApplicationConfig';
import { getCurrentModelRef } from 'app/sliceRefAccess/CurrentModelRef';
import { modelActions } from 'app/slices/modelSlice';
import { AppDispatch } from 'app/store';
import {
  moveAnnotationsMut,
  moveLinksMut,
  moveNodesMut,
} from 'app/utils/positionUtils';
import { renderConstants } from 'app/utils/renderConstants';
import { TransformFunc } from 'ui/modelEditor/ModelRendererWrapper';
import { RendererState } from 'ui/modelRendererInternals/modelRenderer';
import { convertZoomedScreenToWorldCoordinates } from './convertScreenToWorldCoordinates';
import { getHoveringEntities } from './getHoveringEntities';
import { getMinimumVisualNodeHeight } from './getVisualNodeHeight';
import { getMinimumVisualNodeWidth } from './getVisualNodeWidth';
import { ensureEntitiesAreMutable } from './transitionMouseState';

// FIXME: it seems different versions of configs of tsc lead to different
// compilation errors when inferring the type of hoveringAnnotation below.
interface WithUuid {
  uuid: string;
}

export const mouseInput = (
  rs: RendererState,
  dispatch: AppDispatch,
  setTransform: TransformFunc,
): void => {
  if (!rs.cursorOverCanvas && rs.mouseState.state === MouseActions.Idle) {
    rs.hoveringEntity = undefined;
    return;
  }

  const worldCursor = convertZoomedScreenToWorldCoordinates(
    rs.camera,
    rs.screenCursorZoomed,
  );

  if (
    rs.mouseState.state !== MouseActions.MakingSelection &&
    rs.mouseState.state !== MouseActions.DefiningAnnotationBox
  ) {
    const hoveringEntities = getHoveringEntities(
      rs.mouseState,
      worldCursor,
      rs.camera,
      rs.zoom,
      rs.refs.current.nodes,
      rs.refs.current.links,
      rs.refs.current.annotations,
      rs.refs.current.linksIndexLUT,
      rs.linksRenderFrameData,
      getCurrentModelRef().submodelPath,
    );
    rs.hoveringEntity = hoveringEntities[0];

    const hoveringAnnotation = hoveringEntities.find(
      (e) =>
        e.entityType === HoverEntityType.Annotation ||
        e.entityType === HoverEntityType.AnnotationText ||
        e.entityType === HoverEntityType.AnnotationResizeEdge,
    );

    rs.hoveringAnnotationUuid = hoveringAnnotation
      ? (hoveringAnnotation as WithUuid).uuid
      : undefined;
  }

  // mouse actions
  // this takes the current tick's mouse input/state and
  // applies the effects of that state/input to the scene or model data
  switch (rs.mouseState.state) {
    case MouseActions.Panning:
      rs.camera.x =
        rs.mouseState.cameraStartX -
        (rs.mouseState.cursorStartX - rs.screenCursorZoomed.x);
      rs.camera.y =
        rs.mouseState.cameraStartY -
        (rs.mouseState.cursorStartY - rs.screenCursorZoomed.y);
      setTransform({ x: rs.camera.x, y: rs.camera.y, zoom: rs.zoom });
      break;
    case MouseActions.DraggingSelected:
      const deltaX = rs.screenCursorZoomed.x - rs.mouseState.previousCursorX;
      const deltaY = rs.screenCursorZoomed.y - rs.mouseState.previousCursorY;

      rs.mouseState.previousCursorX = rs.screenCursorZoomed.x;
      rs.mouseState.previousCursorY = rs.screenCursorZoomed.y;

      const blockUuids = rs.mouseState.selectionOverride
        ? rs.mouseState.selectionOverride.nodeUuids || []
        : rs.refs.current.selectedNodeIds;
      const linkUuids = rs.mouseState.selectionOverride
        ? rs.mouseState.selectionOverride.linkUuids || []
        : rs.refs.current.selectedLinkIds;
      const annotationUuids = rs.mouseState.selectionOverride
        ? rs.mouseState.selectionOverride.annotationUuids || []
        : rs.refs.current.selectedAnnotationIds;

      if (!RENDERER_EXPERIMENTAL_SMOOTH_ENTITY_MOVE) {
        dispatch(
          modelActions.moveEntitiesByDelta({
            blockUuids,
            linkUuids,
            annotationUuids,
            deltaX,
            deltaY,
          }),
        );
      } else {
        // immediately update information for the renderer
        ensureEntitiesAreMutable(rs.refs);
        moveNodesMut(rs.refs.current.nodes, deltaX, deltaY, blockUuids);
        moveLinksMut(rs.refs.current.links, deltaX, deltaY, linkUuids);
        moveAnnotationsMut(
          rs.refs.current.annotations,
          deltaX,
          deltaY,
          annotationUuids,
        );
      }

      break;
    case MouseActions.DraggingLinkSegment: {
      const worldCursorX = -rs.camera.x + rs.screenCursorZoomed.x;
      const worldCursorY = -rs.camera.y + rs.screenCursorZoomed.y;

      const linkIndex = rs.refs.current.linksIndexLUT[rs.mouseState.linkUuid];
      const link = rs.refs.current.links[linkIndex];
      if (!link) break;

      const segment = link.uiprops.segments[rs.mouseState.segmentId];
      if (!segment) break;

      dispatch(
        modelActions.changeSegmentCoordinate({
          linkUuid: rs.mouseState.linkUuid,
          segmentIndex: rs.mouseState.segmentId,
          newCoordinate:
            segment.segment_direction === 'horiz' ? worldCursorY : worldCursorX,
        }),
      );
      break;
    }
    case MouseActions.ResizeNodeManually: {
      const worldCursorX = -rs.camera.x + rs.screenCursorZoomed.x;
      const worldCursorY = -rs.camera.y + rs.screenCursorZoomed.y;

      const nodeIndex = rs.refs.current.nodesIndexLUT[rs.mouseState.nodeUuid];
      const node = rs.refs.current.nodes[nodeIndex];

      let newX;
      let newY;

      let newGridWidth = rs.mouseState.startingGridWidth;
      let newGridHeight = rs.mouseState.startingGridHeight;

      if (rs.mouseState.handleSides.includes(HoverEdgeSide.Top)) {
        const minGridHeight =
          getMinimumVisualNodeHeight(node) / renderConstants.GRID_UNIT_PXSIZE;
        const mouseGridY = Math.floor(
          worldCursorY / renderConstants.GRID_UNIT_PXSIZE,
        );
        const gridYDif = rs.mouseState.startingGridY - mouseGridY;
        const proposedNewGridHeight =
          rs.mouseState.startingGridHeight + gridYDif;
        if (proposedNewGridHeight >= minGridHeight) {
          newGridHeight = proposedNewGridHeight;
          newY = mouseGridY * renderConstants.GRID_UNIT_PXSIZE;
        } else {
          newGridHeight = minGridHeight;
          const newGridY =
            rs.mouseState.startingGridY +
            (rs.mouseState.startingGridHeight - minGridHeight);
          newY = newGridY * renderConstants.GRID_UNIT_PXSIZE;
        }
      } else if (rs.mouseState.handleSides.includes(HoverEdgeSide.Bottom)) {
        const mouseGridY = Math.floor(
          worldCursorY / renderConstants.GRID_UNIT_PXSIZE,
        );
        const gridYDif = mouseGridY - rs.mouseState.startingGridY;
        newGridHeight = gridYDif;
      }

      if (rs.mouseState.handleSides.includes(HoverEdgeSide.Left)) {
        const minGridWidth =
          getMinimumVisualNodeWidth(node) / renderConstants.GRID_UNIT_PXSIZE;
        const mouseGridX = Math.floor(
          worldCursorX / renderConstants.GRID_UNIT_PXSIZE,
        );
        const gridXDif = rs.mouseState.startingGridX - mouseGridX;
        const proposedNewGridWidth = rs.mouseState.startingGridWidth + gridXDif;
        if (proposedNewGridWidth >= minGridWidth) {
          newGridWidth = proposedNewGridWidth;
          newX = mouseGridX * renderConstants.GRID_UNIT_PXSIZE;
        } else {
          newGridWidth = minGridWidth;
          const newGridX =
            rs.mouseState.startingGridX +
            (rs.mouseState.startingGridWidth - minGridWidth);
          newX = newGridX * renderConstants.GRID_UNIT_PXSIZE;
        }
      } else if (rs.mouseState.handleSides.includes(HoverEdgeSide.Right)) {
        const mouseGridX = Math.floor(
          worldCursorX / renderConstants.GRID_UNIT_PXSIZE,
        );
        const gridXDif = mouseGridX - rs.mouseState.startingGridX;
        newGridWidth = gridXDif;
      }

      dispatch(
        modelActions.resizeNode({
          nodeUuid: rs.mouseState.nodeUuid,
          gridWidth: newGridWidth,
          gridHeight: newGridHeight,
          x: newX,
          y: newY,
        }),
      );
      break;
    }

    case MouseActions.ResizeAnnotationManually: {
      const worldCursorX = -rs.camera.x + rs.screenCursorZoomed.x;
      const worldCursorY = -rs.camera.y + rs.screenCursorZoomed.y;

      const annoIndex = rs.refs.current.annotationsIndexLUT[rs.mouseState.uuid];
      const annotation = rs.refs.current.annotations[annoIndex];

      let newX;
      let newY;

      let newGridWidth = rs.mouseState.startingGridWidth;
      let newGridHeight = rs.mouseState.startingGridHeight;

      const hovTop = rs.mouseState.handleSides.includes(HoverEdgeSide.Top);
      const hovBtm = rs.mouseState.handleSides.includes(HoverEdgeSide.Bottom);
      const hovLeft = rs.mouseState.handleSides.includes(HoverEdgeSide.Left);
      const hovRight = rs.mouseState.handleSides.includes(HoverEdgeSide.Right);

      if (hovTop || hovBtm) {
        const minGridHeight = 2;
        const mouseGridY = Math.floor(
          worldCursorY / renderConstants.GRID_UNIT_PXSIZE,
        );
        const gridYDif = hovTop
          ? rs.mouseState.startingGridY - mouseGridY
          : mouseGridY -
            (rs.mouseState.startingGridY + rs.mouseState.startingGridHeight);
        const proposedNewGridHeight =
          rs.mouseState.startingGridHeight + gridYDif;

        if (proposedNewGridHeight >= minGridHeight) {
          newGridHeight = proposedNewGridHeight;

          if (hovTop) {
            newY = mouseGridY * renderConstants.GRID_UNIT_PXSIZE;
          }
        } else {
          newGridHeight = minGridHeight;

          if (hovTop) {
            const newGridY =
              rs.mouseState.startingGridY +
              (rs.mouseState.startingGridHeight - minGridHeight);
            newY = newGridY * renderConstants.GRID_UNIT_PXSIZE;
          }
        }
      }

      if (hovLeft || hovRight) {
        const minGridWidth = 2;
        const mouseGridX = Math.floor(
          worldCursorX / renderConstants.GRID_UNIT_PXSIZE,
        );
        const gridXDif = hovLeft
          ? rs.mouseState.startingGridX - mouseGridX
          : mouseGridX -
            (rs.mouseState.startingGridX + rs.mouseState.startingGridWidth);
        const proposedNewGridWidth = rs.mouseState.startingGridWidth + gridXDif;

        if (proposedNewGridWidth >= minGridWidth) {
          newGridWidth = proposedNewGridWidth;

          if (hovLeft) {
            newX = mouseGridX * renderConstants.GRID_UNIT_PXSIZE;
          }
        } else {
          newGridWidth = minGridWidth;

          if (hovLeft) {
            const newGridX =
              rs.mouseState.startingGridX +
              (rs.mouseState.startingGridWidth - minGridWidth);
            newX = newGridX * renderConstants.GRID_UNIT_PXSIZE;
          }
        }
      }

      dispatch(
        modelActions.resizeAnnotation({
          uuid: rs.mouseState.uuid,
          gridWidth: newGridWidth,
          gridHeight: newGridHeight,
          x: newX,
          y: newY,
        }),
      );
      break;
    }
  }
};
