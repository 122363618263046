import styled from '@emotion/styled/macro';
import {
  OptimizationAlgoName,
  OptimizationOptions,
  OptimizationStochasticParam,
  PIDTuningRequest,
} from 'app/api/custom_types/optimizations';
import React from 'react';
import { Remove } from 'ui/common/Icons/Standard';
import SectionHeading from 'ui/common/Inputs/SectionHeading';
import SelectInput from 'ui/common/SelectInput';
import AlgorithmFields from './AlgorithmFields';
import ConstrainsSelector from './ConstraintsSelector';
import {
  BATCH_SIZE,
  NUM_BATCHES,
  OptimizationAlgorithmsFields,
} from './OptimizerAlgoList';
import StochasticParamFields from './StochasticParamFields';
import {
  OptimizationContentProps,
  OptimizerInputGroup,
  StochasticParamsConfig,
  filterConstraints,
  filterStochasticParameters,
} from './optimizerModalUtils';

const PidSelectInputGroup = styled.div`
  display: flex;
  margin-bottom: 8px;

  & > svg {
    opacity: 0.3;
    cursor: pointer;

    &:hover {
      opacity: 0.6;
    }
  }
`;

const PIDTuningContent = ({
  request,
  nodeOptions,
  signalOptions,
  onUpdate,
  onUpdateValidRequest,
}: OptimizationContentProps) => {
  const algorithm = request.algorithm;
  const pidBlocks = React.useMemo(
    () => request.pid_blocks ?? [undefined],
    [request],
  );
  const errorSignal = request.objective;
  const options = React.useMemo(() => request.options ?? {}, [request]);
  const constraints = React.useMemo(
    () => request.constraints ?? [undefined],
    [request],
  );
  const filteredConstraints = React.useMemo(
    () => filterConstraints(constraints),
    [constraints],
  );
  const stochasticParams: OptimizationStochasticParam[] = React.useMemo(
    () => request.stochastic_parameters ?? [{ distribution: 'normal' }],
    [request],
  );
  const stochasticParamsConfig = React.useMemo<StochasticParamsConfig>(
    () => ({
      params: stochasticParams,
      numBatches: options.num_batches
        ? String(options.num_batches)
        : NUM_BATCHES.default,
      batchSize: options.batch_size
        ? String(options.batch_size)
        : BATCH_SIZE.default,
    }),
    [stochasticParams, options],
  );

  const addPidBlock = () =>
    onUpdate({ ...request, pid_blocks: [...pidBlocks, undefined] });

  const removePidBlock = (removeIndex: number) =>
    onUpdate({
      ...request,
      pid_blocks: pidBlocks.filter((_, i) => i !== removeIndex),
    });

  const onSelectPid = (index: number, newValue: string) =>
    onUpdate({
      ...request,
      pid_blocks: pidBlocks.map((pb, i) => (i === index ? newValue : pb)),
    });

  const setErrorSignal = (objective: string) =>
    onUpdate({ ...request, objective });

  const setOptions = (options: OptimizationOptions) =>
    onUpdate({ ...request, options });

  const setAlgorithm = (algorithm: OptimizationAlgoName) =>
    onUpdate({ ...request, algorithm });

  const setStochasticParamsConfig = (config: StochasticParamsConfig) => {
    onUpdate({
      ...request,
      stochastic_parameters: config.params,
      options: {
        ...options,
        num_batches: config.numBatches,
        batch_size: config.batchSize,
      },
    });
  };

  const setConstraints = (constraints: Array<string | undefined>) =>
    onUpdate({ ...request, constraints });

  const validPidBlocks = React.useMemo(
    () => pidBlocks.filter((pb) => pb !== undefined) as string[],
    [pidBlocks],
  );

  const hasConstraintsButIsNotSupported = React.useMemo(
    () =>
      filteredConstraints.length > 0 &&
      !OptimizationAlgorithmsFields[algorithm].supportsConstraints,
    [filteredConstraints.length, algorithm],
  );

  const isValid =
    !!errorSignal &&
    validPidBlocks.length > 0 &&
    !hasConstraintsButIsNotSupported;
  const invalidReason = React.useMemo(() => {
    if (!errorSignal) return 'Error signal must be selected';
    if (validPidBlocks.length === 0)
      return 'At least one PID block must be selected';
    if (hasConstraintsButIsNotSupported)
      return 'Constraints are not supported for this algorithm';
    return undefined;
  }, [errorSignal, hasConstraintsButIsNotSupported, validPidBlocks.length]);
  const validRequest = React.useMemo<PIDTuningRequest | null>(() => {
    if (!isValid) return null;
    return {
      type: 'pid',
      algorithm,
      objective: errorSignal,
      options,
      pid_blocks: validPidBlocks,
      constraints: filteredConstraints,
      stochastic_parameters: filterStochasticParameters(stochasticParams),
    };
  }, [
    isValid,
    algorithm,
    errorSignal,
    options,
    validPidBlocks,
    filteredConstraints,
    stochasticParams,
  ]);

  React.useEffect(
    () => onUpdateValidRequest(validRequest, invalidReason),
    [onUpdateValidRequest, validRequest, invalidReason],
  );

  return (
    <>
      <SectionHeading noBorder onButtonClick={addPidBlock}>
        PID blocks
      </SectionHeading>
      {pidBlocks.map((pb, i) => (
        <PidSelectInputGroup key={i}>
          <SelectInput
            placeholder="Choose a PID"
            options={nodeOptions}
            currentValue={pb}
            onSelectValue={(newValue) => onSelectPid(i, newValue)}
          />
          {pidBlocks.length > 1 && <Remove onClick={() => removePidBlock(i)} />}
        </PidSelectInputGroup>
      ))}
      <SectionHeading noBorder>Cost signal</SectionHeading>
      <OptimizerInputGroup>
        <SelectInput
          placeholder="Choose a cost signal to minimize"
          options={signalOptions}
          currentValue={errorSignal}
          onSelectValue={setErrorSignal}
        />
      </OptimizerInputGroup>
      <ConstrainsSelector
        signalOptions={signalOptions}
        constraints={constraints}
        onUpdate={setConstraints}
      />
      <StochasticParamFields
        config={stochasticParamsConfig}
        onUpdate={setStochasticParamsConfig}
      />
      <AlgorithmFields
        algorithm={algorithm}
        fieldValues={options}
        setFields={setOptions}
        setAlgo={setAlgorithm}
        hasConstraints={filteredConstraints.length > 0}
      />
    </>
  );
};

export default PIDTuningContent;
