import styled from '@emotion/styled/macro';
import { t } from '@lingui/macro';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { EmptyStateBorderBox } from 'ui/common/EmptyState';
import { H3, Title } from 'ui/common/typography/Typography';
import { NewProjectButton } from 'ui/dashboard/common/NewProjectButton';
import { ProjectFilter } from 'util/projectFilterUtils';

const ProjectEmptyStateWrapper = styled.div`
  display: flex;
  padding: ${({ theme }) => theme.spacing.xlarge};
  width: 100%;
`;

const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ theme }) => theme.spacing.large};
  gap: ${({ theme }) => theme.spacing.large};
`;

const ContentContainer = styled.div`
  margin-left: ${({ theme }) => theme.spacing.small};
  display: flex;
  flex-direction: row;
`;

const TitleContainer = styled.div`
  margin-top: ${({ theme }) => theme.spacing.small};
  text-align: center;
`;

const ContentMessageContainer = styled.div`
  margin-right: ${({ theme }) => theme.spacing.normal};
  padding-top: ${({ theme }) => theme.spacing.small};
  display: flex;
  flex-direction: row;
  text-align: center;
  flex-grow: 1;
  justify-content: center;
`;

interface Props {
  projectFilter: ProjectFilter;
}

function getEmptyStateTitleText(projectFilter: ProjectFilter): string {
  switch (projectFilter) {
    case 'public':
      return t({
        id: 'dashboard.projects.emptyState.public.title',
        message:
          'We’re having trouble connecting to the public project repository.',
      });
    case 'sharedWithMe':
      return t({
        id: 'dashboard.projects.emptyState.sharedWithMe.title',
        message: 'You don’t have any shared projects.',
      });
    case 'mine':
    default:
      return t({
        id: 'dashboard.projects.emptyState.mine.title',
        message: 'You don’t have any projects.',
      });
  }
}

function getEmptyStateMessageText(projectFilter: ProjectFilter): string {
  switch (projectFilter) {
    case 'public':
      return t({
        id: 'dashboard.projects.emptyState.public.message',
        message: 'You can try refreshing the browser or contacting us here.',
      });
    case 'sharedWithMe':
      return t({
        id: 'dashboard.projects.emptyState.sharedWithMe.message',
        message:
          'When your team shares a project with you, it will appear here.',
      });
    case 'mine':
    default:
      return t({
        id: 'dashboard.projects.emptyState.mine.message',
        message: 'Why not create a new one?',
      });
  }
}

const PublicProjectsLink = styled.span`
  color: ${({ theme }) => theme.colors.text.primary};
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.colors.text.secondary};
  }
`;

const CheckPublicProjects = () => {
  const navigate = useNavigate();

  return (
    <ContentMessageContainer>
      {t({
        id: 'dashboard.projects.emptyState.checkPublicProjects.message',
        message: 'Looking for inspiration? Check out our',
      })}
      &nbsp;
      <PublicProjectsLink onClick={() => navigate('/projects/public')}>
        {t({
          id: 'dashboard.projects.emptyState.publicProjectsLink.message',
          message: 'Public Projects',
        })}
      </PublicProjectsLink>
      .
    </ContentMessageContainer>
  );
};

export const ProjectEmptyState: React.FC<Props> = ({ projectFilter }) => (
  <ProjectEmptyStateWrapper>
    <EmptyStateBorderBox>
      <TopContainer>
        <TitleContainer>
          <H3>{getEmptyStateTitleText(projectFilter)}</H3>
        </TitleContainer>
        <ContentContainer>
          <ContentMessageContainer>
            <Title>{getEmptyStateMessageText(projectFilter)}</Title>
          </ContentMessageContainer>
          {projectFilter === 'mine' && <NewProjectButton />}
        </ContentContainer>
        {projectFilter !== 'public' && <CheckPublicProjects />}
      </TopContainer>
    </EmptyStateBorderBox>
  </ProjectEmptyStateWrapper>
);
