// !!!! -- DO NOT MANUALLY EDIT -- !!!!
// This file was generated by something in scripts/generateDefaultBlockData.js
// If you want to change the contents of this file, it should be automated
//
// (You can edit this file locally, but it may not be checked into the codebase -
// PRs with a manual edit of this file are forbidden from being merged.)

import { computationBlockDefaults } from 'app/generated_blocks/computationBlockDefaults';
import type { ComputationBlockClass } from 'app/generated_types/ComputationBlockClass';
import deepmerge from 'deepmerge';

export const MLP: ComputationBlockClass = deepmerge(
  computationBlockDefaults,
  {
    "base": {
      "name": "MLP",
      "namespace": "core",
      "description": "Equinox MLP block (auto differentiable)",
      "help_url": "https://docs.collimator.ai/using-model-editors/block-library/using-the-mlp-block"
    },
    "modes": {
      "time": "agnostic"
    },
    "ports": {
      "inputs": {
        "static": [
          {
            "name": "in_0"
          }
        ]
      },
      "outputs": {
        "static": [
          {
            "name": "out_0"
          }
        ]
      }
    },
    "parameter_definitions": [
      {
        "name": "in_size",
        "data_type": "int",
        "description": "Input size: model input will be of shape (in_size,)",
        "default_value": "2"
      },
      {
        "name": "out_size",
        "data_type": "int",
        "description": "Output size: model output will be of shape (out_size,)",
        "default_value": "2"
      },
      {
        "name": "width_size",
        "data_type": "int",
        "description": "Size of each hidden layer",
        "default_value": "2"
      },
      {
        "name": "depth",
        "data_type": "int",
        "description": "Number of hidden layers, including the output layer",
        "default_value": "2"
      },
      {
        "name": "seed",
        "data_type": "int",
        "description": "An integer seed for the random number generator. If empty, a random 32-bit seed will be generated.",
        "optional": true,
        "default_value": ""
      },
      {
        "name": "activation_str",
        "description": "Activation function after each hidden layer",
        "data_type": "string",
        "default_value": "relu",
        "optional": true,
        "allowed_values": [
          "relu",
          "sigmoid",
          "tanh",
          "elu",
          "swish",
          "rbf",
          "identity"
        ]
      },
      {
        "name": "final_activation_str",
        "description": "Activation function after the output layer",
        "data_type": "string",
        "default_value": "relu",
        "optional": true,
        "allowed_values": [
          "relu",
          "sigmoid",
          "tanh",
          "elu",
          "swish",
          "rbf",
          "identity"
        ]
      },
      {
        "name": "use_bias",
        "data_type": "bool",
        "default_value": "true",
        "optional": true,
        "description": "Whether to add on a bias to internal layers"
      },
      {
        "name": "use_final_bias",
        "data_type": "bool",
        "default_value": "true",
        "optional": true,
        "description": "Whether to add on a bias to the final layer"
      },
      {
        "name": "file_name",
        "display_name": "Parameter file name",
        "data_type": "string",
        "default_value": "",
        "optional": true,
        "description": "Serialised file containing MLP parameters"
      }
    ]
  }
);
