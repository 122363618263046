import { useProject } from 'app/api/useProject';
import React from 'react';
import { useNavigateToProjectHome } from '../api/useNavigateToProjectHome';

export function useProjectOrRedirect() {
  const { isLoadingProject, isFetchingProject, project } = useProject();
  const { navigateToProjectHome } = useNavigateToProjectHome();

  React.useEffect(() => {
    if (!isLoadingProject && !isFetchingProject && !project) {
      navigateToProjectHome();
    }
  }, [project, isLoadingProject, isFetchingProject, navigateToProjectHome]);

  return {
    isLoadingProject,
    isFetchingProject,
    project,
  };
}
