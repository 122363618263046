import { useGetSubmodelQuery } from 'app/apiGenerated/generatedApi';
import { VersionTagValues } from 'app/apiTransformers/convertPostSubmodelsFetch';
import { useAppDispatch } from 'app/hooks';
import { isSubmodelRefAvailable } from 'app/sliceRefAccess/SubmodelRef';
import { submodelsActions } from 'app/slices/submodelsSlice';
import { getReferenceSubmodelById } from 'app/utils/submodelUtils';
import React from 'react';
import { modelVersionsActions } from '../slices/modelVersionsSlice';

export interface SubmodelLoadOptions {
  loadReferencedSubmodelInfos?: boolean;
  skip?: boolean;
}

export function useSubmodel(
  projectId: string | undefined,
  submodelId: string | undefined,
  options: SubmodelLoadOptions,
) {
  const dispatch = useAppDispatch();

  const cachedSubmodel =
    isSubmodelRefAvailable() &&
    getReferenceSubmodelById(submodelId || '', VersionTagValues.LATEST_VERSION);

  const { data, refetch, isLoading, isFetching } = useGetSubmodelQuery(
    {
      projectUuid: projectId || '',
      submodelUuid: submodelId || '',
    },
    {
      skip: !projectId || !submodelId || !!options.skip,
      refetchOnMountOrArgChange: !cachedSubmodel, // If we've cleared the submodel out of our manual cache, make sure we also clear it out of the RTKQuery cache as well, otherwise, editing a submodel and then viewing another model/submodel that uses the edited submodel will show an old version of that submodel.
    },
  );

  // Store the results in redux so we only need one instantiation of this hook
  // per set of parameter values.
  // Update the submodels slice with the submodel full so that the
  // breadcrumb can access all the submodel diagrams for its parent path.
  React.useEffect(() => {
    if (data && !options.skip) {
      dispatch(
        submodelsActions.setSubmodel({
          submodel: data,
          versionId: VersionTagValues.LATEST_VERSION,
          loadReferencedSubmodelInfos: !!options.loadReferencedSubmodelInfos,
        }),
      );

      // Clear the model version request in the next event loop so the submodel
      // will appear in the submodels slice in a way that is visible to the model versions slice.
      // TODO maybe put these in the same slice so this waiting isn't needed?
      setTimeout(() => {
        if (submodelId) {
          dispatch(
            modelVersionsActions.clearModelVersionRequest({
              modelId: submodelId,
              versionId: VersionTagValues.LATEST_VERSION,
              kind: 'Submodel',
            }),
          );
        }
      }, 0);
    }
  }, [dispatch, data, options, submodelId]);

  // Make sure we load a brand new copy of the submodel if it goes missing
  // from the cache (we clear the cache for a submodel when we exit
  // an edit session for that top level submodel).
  React.useEffect(() => {
    if (!cachedSubmodel && !data && !isFetching && !options.skip) {
      refetch();
    }
  }, [cachedSubmodel, refetch, isFetching, data, options]);

  return {
    isLoading,
    isFetching,
    submodel: data || undefined,
    refetch,
  };
}
