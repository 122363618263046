import { useAppSelector } from 'app/hooks';
import {
  CurrentModelRef,
  initCurrentModelRef,
} from 'app/sliceRefAccess/CurrentModelRef';
import React from 'react';
import { useAppParams } from 'util/useAppParams';

export const CurrentModelRefAccess: React.FC = () => {
  const { projectId, modelId } = useAppParams();

  const topLevelModelType = useAppSelector(
    (state) => state.submodels.topLevelModelType,
  );

  const topLevelNodes = useAppSelector(
    (state) => state.model.present.rootModel?.nodes,
  );

  const topLevelLinks = useAppSelector(
    (state) => state.model.present.rootModel?.links,
  );

  const submodels = useAppSelector((state) => state.model.present.submodels);

  const submodelPath = useAppSelector(
    (state) => state.model.present.currentSubmodelPath,
  );

  const refs = React.useRef<CurrentModelRef>({
    projectId: projectId || '',
    modelId: modelId || '',
    topLevelModelType,
    topLevelNodes,
    topLevelLinks,
    submodels,
    submodelPath,
  });

  React.useEffect(() => {
    initCurrentModelRef(refs);
  }, []);

  React.useEffect(() => {
    refs.current.projectId = projectId || '';
  }, [projectId]);

  React.useEffect(() => {
    refs.current.modelId = modelId || '';
  }, [modelId]);

  React.useEffect(() => {
    refs.current.topLevelModelType = topLevelModelType;
  }, [topLevelModelType]);

  React.useEffect(() => {
    refs.current.topLevelNodes = topLevelNodes;
  }, [topLevelNodes]);

  React.useEffect(() => {
    refs.current.topLevelLinks = topLevelLinks;
  }, [topLevelLinks]);

  React.useEffect(() => {
    refs.current.submodels = submodels;
  }, [submodels]);

  React.useEffect(() => {
    refs.current.submodelPath = submodelPath;
  }, [submodelPath]);

  return null;
};
