import styled from '@emotion/styled/macro';
import { t } from '@lingui/macro';
import {
  ParameterDefinition,
  SubmodelPortDefinition,
} from 'app/apiGenerated/generatedApiTypes';
import { SubmodelInfoUI } from 'app/apiTransformers/convertGetSubmodelsListForModelParent';
import {
  BlockParameterDefinition,
  ComputationBlockClass,
  PortsDefinition,
} from 'app/generated_types/ComputationBlockClass';
import React from 'react';
import Button from 'ui/common/Button/Button';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import { ArrowRight } from 'ui/common/Icons/Standard';
import {
  BlockItemDoc,
  BlockItemsDocumentation,
} from 'ui/common/Inputs/BlockItemsDocumentation';
import Separator from 'ui/common/Menu/items/Separator';
import { Small } from 'ui/common/typography/Typography';

function getDocsFromBlockClassPorts(idSuffix: string, ports?: PortsDefinition) {
  if (!ports) return [];

  const portsDocs: BlockItemDoc[] = [];

  ports.static?.forEach((port) => {
    portsDocs.push({
      id: `${idSuffix}-${port.name}`,
      name: port.name,
      description: '', // TODO add description if it becomes available.
    });
  });

  ports.conditional?.forEach((port) => {
    portsDocs.push({
      id: `${idSuffix}-${port.name}`,
      name: port.name,
      description: '', // TODO add description if it becomes available.
    });
  });

  return portsDocs;
}

function getDocsFromSubmodelPorts(ports?: SubmodelPortDefinition[]) {
  if (!ports) return [];

  const portsDocs: BlockItemDoc[] = [];

  ports.forEach((port) => {
    portsDocs.push({
      id: port.uuid,
      name: port.name,
      description: port.description || '',
    });
  });

  return portsDocs;
}

function getDocsFromParameters(
  parameters?: (BlockParameterDefinition | ParameterDefinition)[],
) {
  if (!parameters) return [];

  const parametersDocs: BlockItemDoc[] = [];

  parameters.forEach((parameter) => {
    parametersDocs.push({
      id: (parameter as ParameterDefinition)?.uuid || parameter.name, // TODO update when block parameters properly support ids
      name: parameter.name,
      description: parameter.description || '',
    });
  });

  return parametersDocs;
}

interface Props {
  helpUrl?: string;
  blockDescription?: string;
  blockClass?: ComputationBlockClass;
  submodelReference?: SubmodelInfoUI;
}

const DocContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const TooltipText = styled.p`
  margin-bottom: ${({ theme }) => theme.spacing.xlarge};
  color: ${({ theme }) => theme.colors.text.secondary};
`;

const ButtonText = styled.div`
  color: ${({ theme }) => theme.colors.text.secondary};
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  color: ${({ theme }) => theme.colors.text.secondary};
  fill: ${({ theme }) => theme.colors.text.tertiary};
  padding: ${({ theme }) => theme.spacing.normal} 0;
`;

export const BlockDocumentation: React.FC<Props> = ({
  helpUrl,
  blockDescription,
  blockClass,
  submodelReference,
}) => {
  const inputDocs = submodelReference
    ? getDocsFromSubmodelPorts(submodelReference.portDefinitionsInputs)
    : blockClass
    ? getDocsFromBlockClassPorts('in/', blockClass.ports.inputs)
    : [];
  const outputDocs = submodelReference
    ? getDocsFromSubmodelPorts(submodelReference.portDefinitionsOutputs)
    : blockClass
    ? getDocsFromBlockClassPorts('out/', blockClass.ports.outputs)
    : [];
  const paramDocs = submodelReference
    ? getDocsFromParameters(submodelReference.parameterDefinitions)
    : blockClass
    ? getDocsFromParameters(blockClass.parameter_definitions)
    : [];

  return (
    <DocContainer>
      <TooltipText>
        <Small>{blockDescription}</Small>
      </TooltipText>

      <BlockItemsDocumentation
        key="inputs"
        sectionTitle={t({
          id: 'modelEditor.blockDocumentation.inputsSection.title',
          message: 'Inputs',
        })}
        items={inputDocs}
      />
      <BlockItemsDocumentation
        key="outputs"
        sectionTitle={t({
          id: 'modelEditor.blockDocumentation.outputsSection.title',
          message: 'Outputs',
        })}
        items={outputDocs}
      />
      <BlockItemsDocumentation
        key="parameters"
        sectionTitle={t({
          id: 'modelEditor.blockDocumentation.parametersSection.title',
          message: 'Parameters',
        })}
        items={paramDocs}
      />

      {helpUrl && (
        <>
          <Separator />
          <ButtonContainer>
            <Button
              variant={ButtonVariants.SmallTertiary}
              RightIcon={ArrowRight}
              onClick={() => window.open(helpUrl, '_blank')}>
              <ButtonText>
                {t({
                  id: 'modelEditor.blockTypeName.infoTooltip.fullDocumentationLink.label',
                  message: 'Full documentation',
                })}
              </ButtonText>
            </Button>
          </ButtonContainer>
        </>
      )}
    </DocContainer>
  );
};
