export enum ButtonVariants {
  Danger = 'Danger',
  LargePrimary = 'LargePrimary',
  LargeSecondary = 'LargeSecondary',
  LargeTertiary = 'LargeTertiary',
  SmallPrimary = 'SmallPrimary',
  SmallSecondary = 'SmallSecondary',
  SmallTertiary = 'SmallTertiary',
}

export enum ButtonSize {
  Small,
  Large,
}
