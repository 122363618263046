import styled from '@emotion/styled';
import { t } from '@lingui/macro';
import {
  useGetSimulationArtifactsReadByUuidQuery,
  useGetSimulationSignalsReadByUuidQuery,
} from 'app/apiGenerated/generatedApi';
import {
  GetSimulationArtifactsReadByUuidApiArg,
  SimulationResultsS3Url,
  SimulationResultsS3UrlsResponse,
} from 'app/apiGenerated/generatedApiTypes';
import { useGetSimulationLogsShortReadByUuidQuery } from 'app/enhancedApi';
import {
  EnsembleResults,
  SignalImage,
} from 'app/generated_types/collimator/dashboard/serialization/ui_types.gen';
import { useAppSelector } from 'app/hooks';
import { ModelLogLine } from 'app/slices/simResultsSlice';
import { parseToNdArray } from 'app/utils/npy';
import { format } from 'date-fns';
import React, { ReactElement, useEffect, useRef } from 'react';
import ConsoleLogLine, {
  OutputRow,
  OutputRowLevel,
  OutputRowMessage,
  OutputRowText,
  OutputRowTimestamp,
} from 'ui/appBottomBar/OutputLogLine';
import { parseSimulationLogs } from 'ui/modelEditor/utils';

const OutputTableWrapper = styled.div<{ border?: boolean }>`
  overflow: auto;
  height: 100%;
  background-color: #ffffff;
  border: ${({ border, theme }) =>
    border ? `1px solid ${theme.colors.grey[10]}` : 'none'};
`;

// Note: The bottom padding is to give space for the feedback button
// so it does not cover up content.
const OutputTable = styled.table<{ noBottomSpace?: boolean }>`
  font-family: monospace;
  font-size: 12px;
  line-height: 1.5;
  color: #000000;
  background-color: #ffffff; // TODO see if we can change this to a theme color
  overflow: scroll;
  padding: 1em;
  width: 100%;
  margin-bottom: ${(props) => (props.noBottomSpace ? '0' : '64px')};
`;

// FIXME: fetch bokehjs manually due to issues with transpiling
export const fetchBokehLib = (): Promise<any> => {
  if (!(window as any).isBokehReady) {
    (window as any).isBokehReady = new Promise<void>((resolve) => {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = 'https://cdn.bokeh.org/bokeh/release/bokeh-2.4.3.min.js';
      script.onload = () => resolve();
      document.head.appendChild(script);
    })
      .then(
        () =>
          new Promise<void>((resolve) => {
            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.src =
              'https://cdn.bokeh.org/bokeh/release/bokeh-api-2.4.3.min.js';
            script.onload = () => resolve();
            document.head.appendChild(script);
          }),
      )
      .then(() => (window as any).Bokeh);
  }
  return (window as any).isBokehReady;
};

const plotAll = (
  Bokeh: any,
  title: string,
  x_range: number[],
  y_range: number[],
) => {
  const figure = Bokeh.Plotting.figure({
    title,
    x_range,
    y_range,
    height: 250,
    width: Math.floor((window.innerWidth - 250) / 3),
    tools: 'pan,crosshair,wheel_zoom,box_zoom,reset,save',
    toolbar_location: 'below',
  });

  return figure;
};

const plotStdDevBands = (
  Bokeh: any,
  title: string,
  x_range: number[],
  y_range: number[],
  stats: SignalStats,
) => {
  if (!stats.mean || !stats.std_dev) {
    return;
  }

  const figure = Bokeh.Plotting.figure({
    title,
    x_range,
    y_range,
    height: 250,
    width: Math.floor((window.innerWidth - 250) / 3),
    tools: 'pan,crosshair,wheel_zoom,box_zoom,reset,save',
    toolbar_location: 'below',
  });

  const source = new Bokeh.ColumnDataSource({
    data: {
      mean: stats.mean,
      time: stats.time,
      lower: stats.mean.map((y, i) => y - stats.std_dev[i]),
      upper: stats.mean.map((y, i) => y + stats.std_dev[i]),
      lower2: stats.mean.map((y, i) => y - 2 * stats.std_dev[i]),
      upper2: stats.mean.map((y, i) => y + 2 * stats.std_dev[i]),
    },
  });

  figure.varea({
    x: { field: 'time' },
    y1: { field: 'lower2' },
    y2: { field: 'upper2' },
    source,
    fill_alpha: 0.33,
    fill_color: '#C4CBF2',
    line_color: 'black',
    legend_label: '2 std dev',
  });

  figure.varea({
    x: { field: 'time' },
    y1: { field: 'lower' },
    y2: { field: 'upper' },
    source,
    fill_alpha: 0.33,
    fill_color: '#6779D6',
    line_color: 'black',
    legend_label: '1 std dev',
  });

  figure.line({
    x: { field: 'time' },
    y: { field: 'mean' },
    line_width: 2,
    source,
    line_color: '#3A49A7',
    legend_label: 'mean',
  });

  return figure;
};

const downsampleArray = (array: number[], n: number): number[] => {
  if (array.length <= n) {
    return array;
  }
  const step = Math.ceil(array.length / n);
  const downsampledArray = [];
  for (let i = 0; i < array.length; i += step) {
    downsampledArray.push(array[i]);
  }
  return downsampledArray;
};

const plotMedianIQR = (
  Bokeh: any,
  title: string,
  x_range: number[],
  y_range: number[],
  stats: SignalStats,
) => {
  if (!stats.median || !stats.median_q1 || !stats.median_q3) {
    return;
  }

  const figure = Bokeh.Plotting.figure({
    title,
    x_range,
    y_range,
    height: 250,
    width: Math.floor((window.innerWidth - 250) / 3),
    tools: 'pan,crosshair,wheel_zoom,box_zoom,reset,save',
    toolbar_location: 'below',
  });

  const source = new Bokeh.ColumnDataSource({
    data: {
      median: stats.median,
      time: stats.time,
      min: stats.min,
      max: stats.max,
    },
  });

  const quartileData = {
    iqrTime: stats.time,
    q1: stats.median_q1,
    q3: stats.median_q3,
  };

  if (quartileData.iqrTime.length > 100) {
    quartileData.iqrTime = downsampleArray(quartileData.iqrTime, 100);
    quartileData.q1 = downsampleArray(quartileData.q1, 100);
    quartileData.q3 = downsampleArray(quartileData.q3, 100);
  }

  const quartileSource = new Bokeh.ColumnDataSource({
    data: quartileData,
  });

  const whisker = new Bokeh.Whisker({
    base: { field: 'iqrTime' },
    upper: { field: 'q3' },
    lower: { field: 'q1' },
    source: quartileSource,
    level: 'annotation',
    line_width: 2,
  });
  figure.add_layout(whisker);

  figure.line({
    x: { field: 'time' },
    y: { field: 'median' },
    line_width: 2,
    line_color: '#008F8D',
    source,
    legend_label: 'median',
  });

  figure.line({
    x: { field: 'time' },
    y: { field: 'min' },
    line_color: '#6779D6',
    line_width: 2,
    line_dash: [10, 7],
    source,
    legend_label: 'min',
  });

  figure.line({
    x: { field: 'time' },
    y: { field: 'max' },
    line_color: '#CA758A',
    line_dash: [10, 7],
    line_width: 2,
    source,
    legend_label: 'max',
  });

  return figure;
};

interface SignalStats {
  mean: number[];
  median: number[];
  median_q1: number[];
  median_q3: number[];
  std_dev: number[];
  min: number[];
  max: number[];
  time: number[];
}

const getStats = (
  signal_name: string,
  s3_urls: SimulationResultsS3Url[],
): Promise<SignalStats> => {
  const stats: SignalStats = {
    mean: [],
    median: [],
    median_q1: [],
    median_q3: [],
    std_dev: [],
    min: [],
    max: [],
    time: [],
  };
  const statsKeys = Object.keys(stats);
  const promises: Promise<void>[] = [];
  // FIXME: ideally we should have an api that returns the stats for a given signal
  s3_urls.forEach((s3Url) => {
    if (s3Url.key?.includes(signal_name)) {
      // if any of the stat_keys are in the key, then it's a stats npy
      for (const key of statsKeys) {
        if (s3Url.key?.endsWith(`.${key}.npy`) && s3Url.url) {
          const promise = fetch(s3Url.url)
            .then((response) => response.arrayBuffer())
            .then(parseToNdArray)
            .then((array) => {
              stats[key as keyof SignalStats] = array.data as number[];
            });
          promises.push(promise);
        }
      }
    }
  });

  return Promise.all(promises).then(() => stats);
};

const BokehOut = styled.div`
  display: flex;
  gap: 0 16px;
  margin: 16px 0;

  .bk-root {
    border: 1px solid #e4e7e7;
  }
`;

const S3LinkLog = ({
  url,
  index,
  timestamp,
  metadata,
  signals,
}: {
  url: SimulationResultsS3Url;
  index: number;
  timestamp: string;
  metadata?: SignalImage;
  signals?: SimulationResultsS3UrlsResponse;
}) => {
  const [stats, setStats] = React.useState<SignalStats | undefined>();
  const isPng = url.name.endsWith('.png');
  // FIXME: find more reliable way to distinguish bounds-encoded datashader plots vs. others

  React.useEffect(() => {
    if (!metadata?.signal_name || !signals?.s3_urls) {
      return;
    }
    getStats(metadata?.signal_name, signals?.s3_urls).then(setStats);
  }, [metadata?.signal_name, signals?.s3_urls]);

  const plotOptions = useAppSelector(
    (state) => state.uiFlags.ensemblePlotOptions,
  );

  // assume .png files are from datashader
  const bokehOutEl = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    async function initBokehPlot() {
      const Bokeh = await fetchBokehLib();
      if (!url.url || !isPng || !bokehOutEl.current || !stats) {
        return;
      }

      bokehOutEl.current
        .querySelectorAll('.bk-root')
        .forEach((el) => el.remove());

      let [x0, x1] = metadata?.x_range || [0, 1];
      let [y0, y1] = metadata?.y_range || [0, 1];

      if (x1 - x0 === 0) {
        x0 -= 0.1;
        x1 += 0.1;
      }
      if (y1 - y0 === 0) {
        y0 -= 0.1;
        y1 += 0.1;
      }
      const xMargin = 0;
      const yMargin = (y1 - y0) * 0.05;
      const x_range = [x0 - xMargin, x1 + xMargin];
      const y_range = [y0 - yMargin, y1 + yMargin];

      const figureAll = plotAll(
        Bokeh,
        metadata?.signal_name || url.name,
        x_range,
        y_range,
      );
      figureAll.image_url(url.url, x0, y1, x1 - x0, y1 - y0);

      const figureStdDev = plotStdDevBands(
        Bokeh,
        `Std dev ranges for ${metadata?.signal_name}`,
        x_range,
        y_range,
        stats,
      );

      const figureMedianIQR = plotMedianIQR(
        Bokeh,
        `Median and IQR for ${metadata?.signal_name}`,
        x_range,
        y_range,
        stats,
      );

      if (plotOptions.overlay) {
        Bokeh.Plotting.show(figureAll, bokehOutEl.current);
      }

      if (plotOptions.standard_deviations) {
        Bokeh.Plotting.show(figureStdDev, bokehOutEl.current);
      }

      if (plotOptions.iqr_error) {
        Bokeh.Plotting.show(figureMedianIQR, bokehOutEl.current);
      }
    }
    initBokehPlot();
  }, [isPng, metadata, stats, url.name, url.url, plotOptions]);

  return (
    <OutputRow key={index}>
      <OutputRowTimestamp>{timestamp}</OutputRowTimestamp>
      <OutputRowLevel className="inf">INFO</OutputRowLevel>
      <OutputRowText className="inf">
        <OutputRowMessage>
          {url.name.endsWith('.svg') || (isPng && !metadata) ? (
            // FIXME this is a massive hack to inline images directly
            // Used for preview for EnsembleSims - after redesign, we should
            // remove this special case.
            <>
              {t`Output file:`}{' '}
              <a href={url.url} target="_blank" rel="noreferrer">
                {url.name}
              </a>
              <br />
              <img src={url.url} alt={url.name} width="100%" />
            </>
          ) : (
            <>
              {t`Output file:`}{' '}
              <a href={url.url} target="_blank" rel="noreferrer">
                {url.name}
              </a>
            </>
          )}
          <BokehOut ref={bokehOutEl} />
        </OutputRowMessage>
      </OutputRowText>
    </OutputRow>
  );
};

export const OutputContainer: React.FC<{
  noBottomSpace?: boolean;
  border?: boolean;
  children: React.ReactNode;
}> = ({ noBottomSpace, border, children }) => (
  <OutputTableWrapper border={border}>
    <OutputTable noBottomSpace={noBottomSpace}>
      <tbody>{children}</tbody>
    </OutputTable>
  </OutputTableWrapper>
);

export const Output = (): ReactElement => {
  const [logsShort, setLogsShort] = React.useState<ModelLogLine[]>([]);

  const simulationLogs =
    useAppSelector((state) => state.simResults.simulationLogs) || [];

  const { simulationSummary } = useAppSelector((state) => state.project);

  const requestBody: GetSimulationArtifactsReadByUuidApiArg = {
    modelUuid: simulationSummary?.model_uuid || '',
    simulationUuid: simulationSummary?.uuid || '',
  };
  const { data: artifacts } = useGetSimulationArtifactsReadByUuidQuery(
    requestBody,
    {
      skip:
        !simulationSummary ||
        !['completed', 'failed'].includes(simulationSummary?.status || ''),
    },
  );

  const {
    data: logsShortRaw,
    isError: isErrorLogsShort,
    isFetching: isFetchingLogsShort,
  } = useGetSimulationLogsShortReadByUuidQuery(
    {
      simulationUuid: simulationSummary?.uuid || '',
      modelUuid: simulationSummary?.model_uuid || '',
    },
    {
      pollingInterval: 1000,
      skip:
        !simulationSummary ||
        simulationSummary?.status !== 'simulation_in_progress',
    },
  );

  const logLines =
    simulationLogs.length > 0 ||
    simulationSummary?.status !== 'simulation_in_progress'
      ? simulationLogs
      : logsShort;

  let urls = artifacts?.s3_urls.map((s3Url): SimulationResultsS3Url => {
    const basename = s3Url.key?.split('/').reverse()[0] ?? '';
    return { name: basename, url: s3Url.url };
  });

  const { data: signals } = useGetSimulationSignalsReadByUuidQuery(
    {
      simulationUuid: simulationSummary?.uuid || '',
      modelUuid: simulationSummary?.model_uuid || '',
    },
    {
      skip: !simulationSummary || simulationSummary?.status !== 'completed',
    },
  );

  const timestamp = React.useMemo(() => format(new Date(), 'HH:mm:ss.SSS'), []);

  const findMetadata = React.useCallback(
    (key: string) =>
      (simulationSummary?.results as EnsembleResults)?.signal_images?.filter(
        (signalImage: SignalImage) => key.startsWith(signalImage.signal_name),
      )?.[0],
    [simulationSummary?.results],
  );

  React.useEffect(() => {
    if (simulationSummary?.status !== 'simulation_in_progress') {
      setLogsShort([]);
      return;
    }
    if (logsShortRaw && !isFetchingLogsShort && !isErrorLogsShort) {
      setLogsShort(parseSimulationLogs(logsShortRaw));
    }
  }, [
    isErrorLogsShort,
    isFetchingLogsShort,
    logsShortRaw,
    simulationSummary?.status,
  ]);

  const renderS3Links = (urls: SimulationResultsS3Url[]) => (
    <>
      {urls
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((url, i) => (
          <S3LinkLog
            key={i}
            url={url}
            index={i}
            timestamp={timestamp}
            metadata={findMetadata(url.name)}
            signals={signals}
          />
        ))}
    </>
  );

  return (
    <OutputContainer>
      {logLines.map((rawLog, i) => (
        <ConsoleLogLine rawLog={rawLog} key={i} />
      ))}
      {simulationSummary?.status === 'completed' && renderS3Links(urls || [])}
    </OutputContainer>
  );
};
