import styled from '@emotion/styled/macro';

export const ActionButtonGroup = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacing.normal};
`;

export const ActionsSpacer = styled.div`
  flex-grow: 1;
`;
