/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  className?: string;
}

const FmiFile: React.FC<Props> = (props) => (
  <svg viewBox="0 0 16 16" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.74702 10.8101V6.90323H2.5L2.52619 5.53939H3.74702V5.44444C3.74702 4.84505 3.97059 4.32618 4.39389 3.91931L4.40679 3.90691L4.42086 3.89606C4.57681 3.77574 4.75106 3.67733 4.93332 3.60799L4.95025 3.60155C5.16079 3.53528 5.39696 3.50365 5.64564 3.50207L5.97089 3.5V4.86322L5.65049 4.86633C5.29526 4.86977 5.1728 4.99269 5.12143 5.12817C5.07295 5.258 5.05452 5.36344 5.05452 5.44444V5.53939H6.87321L6.70152 5.70094C6.95201 5.59176 7.23702 5.53935 7.54509 5.53935C7.99468 5.53935 8.39924 5.6958 8.74332 5.99472C9.09045 5.68767 9.50616 5.52941 9.97198 5.52941C10.3949 5.52941 10.7349 5.63334 11.0031 5.80794C11.2707 5.9821 11.449 6.21484 11.5663 6.44369C11.7957 6.89097 11.8009 7.33964 11.8009 7.39119V10.0907H10.4933V7.46403C10.4933 7.32715 10.4604 7.22766 10.4063 7.14943C10.3531 7.07246 10.2648 6.99511 10.1211 6.92931C10.027 6.90814 9.97496 6.90295 9.94521 6.90295C9.72998 6.90295 9.56434 6.99951 9.43294 7.24909C9.4097 7.31716 9.39383 7.41148 9.39379 7.54339L9.39931 10.0907H8.09175L8.08599 7.4194C8.08599 7.27096 8.04646 7.14269 7.97794 7.0578C7.9168 6.98207 7.80313 6.90295 7.57369 6.90295C7.30918 6.90295 7.17922 7.01165 7.1063 7.1152C7.02045 7.2371 6.99697 7.3789 6.99697 7.42587V10.0907H5.6889V6.90323H5.05452V10.8166L4.38046 11.367L3.74702 10.8101Z"
      fill={props.fill}
    />
    <path
      d="M12.1498 6.52636V4.9659H13.466V6.52636H12.1498Z"
      fill={props.fill}
    />
    <path
      d="M2.51591 11.257L4.02496 11.2854L4.38915 11.5727L4.76049 11.2836L13.5 11.2626V12.5H2.51591V11.257Z"
      fill={props.fill}
    />
    <path
      d="M13.464 10.0909H12.1479V7.13129H13.464V10.0909Z"
      fill={props.fill}
    />
  </svg>
);

FmiFile.defaultProps = {
  width: '16',
  height: '16',
  viewBox: '0 0 16 16',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  className: '',
};

export default FmiFile;
