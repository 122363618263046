import React from 'react';
import { useAppSelector, useAppDispatch } from 'app/hooks';
import { useGetSnapshotReadAllQuery } from 'app/apiGenerated/generatedApi';
import { versionHistoryActions } from 'app/slices/versionHistorySlice';

export function useDiagramVersions(modelId: string) {
  const dispatch = useAppDispatch();
  const forceRefetchSnapshots = useAppSelector(
    (state) => state.versionHistory.forceRefetchSnapshots,
  );

  const kind = useAppSelector((state) => state.submodels.topLevelModelType);
  const loadedModelId = useAppSelector(
    (state) => state.modelMetadata.loadedModelId,
  );

  const { data, refetch, isLoading } = useGetSnapshotReadAllQuery(
    {
      modelUuid: modelId,
      kind: kind || 'Model',
    },
    {
      skip: !modelId || !kind || modelId !== loadedModelId,
    },
  );

  // Make sure we refetch snapshots after another part of the system requests it.
  React.useEffect(() => {
    if (forceRefetchSnapshots) {
      refetch();
      dispatch(versionHistoryActions.clearRequestDiagramVersions());
    }
  }, [forceRefetchSnapshots, dispatch, refetch]);

  return {
    isLoading,
    diagramVersions: data || [],
  };
}
