/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  className?: string;
}

const AddTag: React.FC<Props> = (props) => (
  <svg viewBox="0 0 24 24" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.492 9.5V13H4V9C4 8.44772 4.44533 8 4.99467 8H16.0213C16.2851 8 16.5381 8.10536 16.7247 8.29289L19.7087 11.2929C20.0971 11.6834 20.0971 12.3166 19.7087 12.7071L16.7247 15.7071C16.5381 15.8946 16.2851 16 16.0213 16H13.75V14.5H15.8153L18.302 12L15.8153 9.5H5.492Z"
      fill={props.fill}
    />
    <path
      d="M7.25 11.75V14.5H4.5V16H7.25V18.75H8.75V16H11.5V14.5H8.75V11.75H7.25Z"
      fill={props.fill}
    />
  </svg>
);

AddTag.defaultProps = {
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  className: '',
};

export default AddTag;
