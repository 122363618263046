/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  className?: string;
}

const LoRes: React.FC<Props> = (props) => (
  <svg viewBox="0 0 24 24" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6 5.25C6 5.11193 6.11193 5 6.25 5H9.75C9.88807 5 10 5.11193 10 5.25V6.75C10 6.88807 10.1119 7 10.25 7H10.75C10.8881 7 11 7.11193 11 7.25V8.75C11 8.88807 11.1119 9 11.25 9H11.75C11.8881 9 12 9.11193 12 9.25V10.75C12 10.8881 12.1119 11 12.25 11H12.75C12.8881 11 13 11.1119 13 11.25V12.75C13 12.8881 13.1119 13 13.25 13H13.75C13.8881 13 14 13.1119 14 13.25V14.75C14 14.8881 14.1119 15 14.25 15H14.75C14.8881 15 15 15.1119 15 15.25V16.75C15 16.8881 15.1119 17 15.25 17H16.75C16.8881 17 17 16.8881 17 16.75V15.25C17 15.1119 17.1119 15 17.25 15H17.75C17.8881 15 18 14.8881 18 14.75V13.25C18 13.1119 18.1119 13 18.25 13H18.75C18.8881 13 19 12.8881 19 12.75V11.25C19 11.1119 19.1119 11 19.25 11H20.75C20.8881 11 21 11.1119 21 11.25V12.75C21 12.8881 20.8881 13 20.75 13H20.25C20.1119 13 20 13.1119 20 13.25V14.75C20 14.8881 19.8881 15 19.75 15H19.25C19.1119 15 19 15.1119 19 15.25V16.75C19 16.8881 18.8881 17 18.75 17H18.25C18.1119 17 18 17.1119 18 17.25V18.75C18 18.8881 17.8881 19 17.75 19H14.25C14.1119 19 14 18.8881 14 18.75V17.25C14 17.1119 13.8881 17 13.75 17H13.25C13.1119 17 13 16.8881 13 16.75V15.25C13 15.1119 12.8881 15 12.75 15H12.25C12.1119 15 12 14.8881 12 14.75V13.25C12 13.1119 11.8881 13 11.75 13H11.25C11.1119 13 11 12.8881 11 12.75V11.25C11 11.1119 10.8881 11 10.75 11H10.25C10.1119 11 10 10.8881 10 10.75V9.25C10 9.11193 9.88807 9 9.75 9H9.25C9.11193 9 9 8.88807 9 8.75V7.25C9 7.11193 8.88807 7 8.75 7H7.25C7.11193 7 7 7.11193 7 7.25V8.75C7 8.88807 6.88807 9 6.75 9H6.25C6.11193 9 6 9.11193 6 9.25V10.75C6 10.8881 5.88807 11 5.75 11H5.25C5.11193 11 5 11.1119 5 11.25V12.75C5 12.8881 4.88807 13 4.75 13H3.25C3.11193 13 3 12.8881 3 12.75V11.25C3 11.1119 3.11193 11 3.25 11H3.75C3.88807 11 4 10.8881 4 10.75V9.25C4 9.11193 4.11193 9 4.25 9H4.75C4.88807 9 5 8.88807 5 8.75V7.25C5 7.11193 5.11193 7 5.25 7H5.75C5.88807 7 6 6.88807 6 6.75V5.25Z"
      fill={props.fill}
    />
  </svg>
);

LoRes.defaultProps = {
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  className: '',
};

export default LoRes;
