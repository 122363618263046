/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Loopmemory: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.75 6C5.61193 6 5.5 6.11193 5.5 6.25V21.5C5.5 21.6381 5.61193 21.75 5.75 21.75H7.75C8.16421 21.75 8.5 22.0858 8.5 22.5C8.5 22.9142 8.16421 23.25 7.75 23.25H5.75C4.7835 23.25 4 22.4665 4 21.5V6.25C4 5.2835 4.7835 4.5 5.75 4.5H21.0415C22.008 4.5 22.7915 5.2835 22.7915 6.25V13.9091L25.0226 11.771C25.3216 11.4844 25.7964 11.4945 26.083 11.7936C26.3696 12.0926 26.3595 12.5674 26.0604 12.854L22.0415 16.7055L18.0226 12.854C17.7235 12.5674 17.7134 12.0926 18 11.7936C18.2866 11.4945 18.7614 11.4844 19.0604 11.771L21.2915 13.9091V6.25C21.2915 6.11193 21.1796 6 21.0415 6H5.75Z"
      fill={props.fill}
    />
    <path
      d="M10.5 22.5C10.5 22.0858 10.8358 21.75 11.25 21.75H13.75C14.1642 21.75 14.5 22.0858 14.5 22.5C14.5 22.9142 14.1642 23.25 13.75 23.25H11.25C10.8358 23.25 10.5 22.9142 10.5 22.5Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M16 18.5C16 17.9477 16.4477 17.5 17 17.5H27C27.5523 17.5 28 17.9477 28 18.5V26.5C28 27.0523 27.5523 27.5 27 27.5H17C16.4477 27.5 16 27.0523 16 26.5V18.5ZM26.5 26H17.5V19H26.5V26Z"
      fill={props.fill}
    />
  </svg>
);

Loopmemory.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Loopmemory;
