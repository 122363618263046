/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const ChevronDown: React.FC<Props> = (props) => (
  <svg viewBox="0 0 16 16" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M3.70811 6.20353C3.98559 5.93216 4.43547 5.93216 4.71294 6.20353L8 9.4183L11.2871 6.20353C11.5645 5.93216 12.0144 5.93216 12.2919 6.20353C12.5694 6.47491 12.5694 6.9149 12.2919 7.18627L8.66989 10.7286C8.29992 11.0905 7.70008 11.0905 7.33011 10.7286L3.70811 7.18627C3.43063 6.9149 3.43063 6.47491 3.70811 6.20353Z"
      fill={props.fill}
    />
  </svg>
);

ChevronDown.defaultProps = {
  width: '16',
  height: '16',
  viewBox: '0 0 16 16',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default ChevronDown;
