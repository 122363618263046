/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  className?: string;
}

const Fmi: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.02133 25.4492V24.2064L6.70951 24.207L7.68974 25.056L8.71533 24.207H28V25.4492H4.02133Z"
      fill={props.fill}
    />
    <path
      d="M22.5257 19.9524V14.7495C22.5257 13.8284 22.0583 13.1633 21.1368 12.7729C20.8566 12.7075 20.6589 12.6798 20.5043 12.6798C19.688 12.6798 19.0595 13.0985 18.6353 13.9236C18.5267 14.2034 18.4753 14.5372 18.4753 14.9316L18.4863 19.9524H16.9533L16.9417 14.6458C16.9417 13.6955 16.4327 12.6798 15.0033 12.6798C13.2988 12.6798 12.9156 14.1485 12.9156 14.6624V19.9524H11.3826L11.3904 14.7876C11.3904 12.4744 12.6829 11.148 14.937 11.148C15.9599 11.148 16.8576 11.555 17.6058 12.358L17.722 12.4832L17.8373 12.3565C18.5776 11.5401 19.4959 11.1253 20.5664 11.1253C24.0188 11.1253 24.0587 14.4425 24.0587 14.5833V19.9524H22.5257Z"
      fill={props.fill}
    />
    <path
      d="M27.9164 19.953H26.3636V14.7799H27.9164V19.953Z"
      fill={props.fill}
    />
    <path
      d="M27.921 13.3998H26.3682V11.4188H27.921V13.3998Z"
      fill={props.fill}
    />
    <path
      d="M6.87702 23.5893V12.6805H4L4.02991 11.1481H6.87702V10.1419C6.87702 9.0077 7.29852 8.03456 8.1286 7.24984C8.4337 7.01833 8.77495 6.82892 9.12995 6.69607C9.53798 6.56975 10.0138 6.50326 10.5355 6.5V8.03343C9.53854 8.04293 8.89404 8.41012 8.61928 9.12566C8.48034 9.49115 8.40988 9.83282 8.40988 10.1419V11.1481H11.3894C10.8113 11.6831 10.4911 12.408 10.3854 12.6805H8.40988V23.5866L7.61354 24.2262L6.87702 23.5893Z"
      fill={props.fill}
    />
  </svg>
);

Fmi.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  className: '',
};

export default Fmi;
