import { NodeInstance } from '@collimator/model-schemas-ts';
import { Global, useTheme } from '@emotion/react';
import styled from '@emotion/styled/macro';
import { t } from '@lingui/macro';
import Editor, { useMonaco } from '@monaco-editor/react';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { notificationsActions } from 'app/slices/notificationsSlice';
import { editor as monacoEditor } from 'monaco-editor/esm/vs/editor/editor.api';
import React, { ReactElement } from 'react';
import { getThemeValue } from 'theme/themes';
import {
  setupAutocomplete,
  setupAutocompleteShortcut,
} from 'ui/codeEditor/AutocompleteHandler';
import { Code } from 'ui/common/Icons/Small';
import {
  TreeArrowCollapsed,
  TreeArrowExpanded,
} from 'ui/common/Icons/Standard';
import ModelEditorBreadcrumb from 'ui/modelEditor/ModelEditorBreadcrumb';

export const CODE_EDITOR_BLOCK_QUERY_PARAM = 'code_editor_path';
export const MODEL_SOURCE_CODE_VIEWING_QUERY_PARAM = 'view_model_sourcecode';

// See https://microsoft.github.io/monaco-editor/
export type CodeLanguage = 'python' | 'markdown' | 'plaintext' | 'c';

const EditorScreen = styled.div<{ noWidthSet?: boolean }>`
  position: relative;
  height: 100%;
  pointer-events: auto;
  background: ${({ theme }) => theme.colors.grey[5]};
  ${({ noWidthSet }) => (noWidthSet ? '' : 'width: 40px;')}
  flex-grow: 1;
  border: 1px solid ${({ theme }) => theme.colors.grey[10]};
`;

const EditorSection = styled.div<{ noTopMargin?: boolean }>`
  flex: 1;
  min-height: 0;
  height: 100%;
  max-height: 100%;
  padding-top: ${({ noTopMargin }) => (noTopMargin ? '0' : '48px')};
  position: relative;
`;

const CodePanesContainer = styled.div`
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-height: 100%;
  display: flex;
`;
const CodePaneTitleContainer = styled.div`
  display: flex;
  align-items: center;
  z-index: 99;
  width: 100%;
  height: 30px;
  background: white;
  border: 1px solid #e3e7e7;
  border-left: none;
  border-right: none;
  cursor: pointer;
  user-selection: none;
`;
const CodePane = styled.div<{ open: boolean }>`
  overflow: hidden;

  ${({ open }) =>
    open
      ? `
    flex: 1;
  `
      : `
    flex: 0;
    min-height: 30px;
  `}
`;

const CodePaneTitle = ({
  onToggle,
  open,
  title,
}: {
  onToggle?: () => void;
  open: boolean;
  title: string;
}) => (
  <CodePaneTitleContainer onClick={onToggle}>
    {open ? (
      <TreeArrowExpanded fill="#ABB0B0" />
    ) : (
      <TreeArrowCollapsed fill="#ABB0B0" />
    )}
    <Code fill="#5C6F70" />
    {title}
  </CodePaneTitleContainer>
);

interface CodeEditorWrapperProps {
  language: CodeLanguage;
  editorOptions: monacoEditor.IStandaloneEditorConstructionOptions;
  path?: string;
  title?: string;
  defaultValue?: string;
  defaultClosed?: boolean;
  inPane?: boolean;
  onChangeCode: (code?: string) => void;
  onFocused: (editor: monacoEditor.IStandaloneCodeEditor | null) => void;
  onPaneStateChange?: (closedState: boolean) => void;
  valueOverride?: string;
  onDoubleClickWord?: (word: string) => void;
}

const CodeEditorWrapper = ({
  path,
  title,
  defaultValue,
  valueOverride,
  defaultClosed,
  language,
  editorOptions,
  inPane,
  onChangeCode,
  onFocused,
  onPaneStateChange,
  onDoubleClickWord,
}: CodeEditorWrapperProps): ReactElement => {
  const editorRef = React.useRef<monacoEditor.IStandaloneCodeEditor | null>(
    null,
  );
  const [open, setOpen_raw] = React.useState<boolean>(!defaultClosed);
  // if the entity prefs take a bit to initially load from the API,
  // this makes sure we still get that default state
  React.useEffect(() => {
    setOpen_raw(!defaultClosed);
  }, [defaultClosed]);

  const setOpen = (setterFunc: (openState: boolean) => boolean) => {
    // important to remember that we store closed states in the entitry prefs
    // (because the open state is the default)
    // so we pass "true" to mean "closed" into the onPaneStateChange
    setOpen_raw((currentOpenState) => {
      const newOpenState = setterFunc(currentOpenState);
      if (onPaneStateChange) {
        onPaneStateChange(!newOpenState);
      }
      return newOpenState;
    });
  };

  const handleEditorDidMount = React.useCallback(
    (editor: monacoEditor.IStandaloneCodeEditor) => {
      setupAutocompleteShortcut(editor);
      editorRef.current = editor;
    },
    [],
  );

  const onDoubleClick = React.useCallback(
    (e: React.MouseEvent) => {
      if (!editorRef.current || !onDoubleClickWord) return;

      const target = editorRef.current.getTargetAtClientPoint(
        e.clientX,
        e.clientY,
      );
      if (!target || !target.position) return;
      const model = editorRef.current.getModel();
      if (!model) return;
      const wordData = model.getWordAtPosition(target.position);
      if (!wordData) return;

      onDoubleClickWord(wordData.word);
    },
    [onDoubleClickWord],
  );

  React.useEffect(() => {
    if (valueOverride && editorRef.current) {
      editorRef.current.setScrollTop(0);
    }
  }, [valueOverride]);

  if (inPane) {
    return (
      <CodePane open={open} onFocus={() => onFocused(editorRef.current)}>
        <CodePaneTitle
          title={title || path || 'Untitled'}
          onToggle={() => setOpen((open) => !open)}
          open={open}
        />
        <Editor
          language={language || 'python'}
          theme="collimator"
          path={path}
          defaultValue={defaultValue}
          onChange={(val: string | undefined) => onChangeCode(val)}
          onMount={handleEditorDidMount}
          options={editorOptions}
          wrapperProps={{
            onDoubleClick,
          }}
        />
      </CodePane>
    );
  }
  return (
    <Editor
      language={language || 'python'}
      theme="collimator"
      path={path}
      defaultValue={defaultValue}
      value={valueOverride}
      onChange={(val: string | undefined) => onChangeCode(val)}
      onMount={handleEditorDidMount}
      options={editorOptions}
      wrapperProps={{
        onDoubleClick,
      }}
    />
  );
};

interface CodeEditorProps {
  language: CodeLanguage;
  editorDisplayPath: string;
  onChangeCode: (v: string | undefined, paramKey?: string) => void;

  defaultValue?: string;
  noTopMargin?: boolean;
  noBreadcrumb?: boolean;
  editingDisabled?: boolean;
  multiPaneData?: Array<{
    paneTitle: string;
    paramKey: string;
    defaultValue?: string;
    defaultClosed?: boolean;
  }>;
  onPaneStateChange?: (paneParamName: string, paneIsClosed: boolean) => void;
  codeBlockForCompletions?: NodeInstance;
  valueOverride?: string;
  onDoubleClickWord?: (word: string) => void;
}

export const CodeEditor = ({
  defaultValue,
  onChangeCode,
  editorDisplayPath,
  noTopMargin,
  noBreadcrumb,
  editingDisabled,
  language,
  multiPaneData,
  onPaneStateChange,
  codeBlockForCompletions,
  valueOverride,
  onDoubleClickWord,
}: CodeEditorProps): ReactElement => {
  const [editorInFocus, setEditorInFocus] =
    React.useState<monacoEditor.IStandaloneCodeEditor | null>(null);

  const theme = useTheme();
  const dispatch = useAppDispatch();

  // FIXME: on first load in dev mode (local) this hook triggers:
  // "operation is manually canceled" error.
  // https://github.com/suren-atoyan/monaco-react/issues/575
  const monaco = useMonaco();
  const timerId = React.useRef<NodeJS.Timeout | null>(null);

  const { codexAutocompleteEnabled, codexAutocompleteModel } = useAppSelector(
    (state) => state.userOptions.options,
  );

  React.useEffect(() => {
    const showAutosaveMessage = () => {
      dispatch(
        notificationsActions.setCurrentMessage({
          message: t({
            message: 'Your file is saved automatically.',
            id: 'pythonEditor.autoSavePseudoNag',
          }),
          icon: undefined,
          canClose: true,
        }),
      );
    };

    const checkKeydownForSave = (e: KeyboardEvent) => {
      if ((e.ctrlKey || e.metaKey) && e.key.toLowerCase() === 's') {
        e.preventDefault();
        showAutosaveMessage();
      }
    };

    document.addEventListener('keydown', checkKeydownForSave);

    return () => {
      document.removeEventListener('keydown', checkKeydownForSave);
    };
  }, [dispatch]);

  const editorOptions: monacoEditor.IStandaloneEditorConstructionOptions =
    React.useMemo(
      () => ({
        readOnly: editingDisabled,
        fontSize: getThemeValue(theme.typography.font.code.size),
        lineHeight: 26,
        // FIXME: This font seems to mess up the cursor alignment in chrome
        // when zoom is at 100%
        // fontFamily: 'Inconsolata',
        renderWhitespace: 'none',
        lineNumbersMinChars: 3,
        folding: false,
        lineDecorationsWidth: getThemeValue(theme.spacing.normal),
        minimap: {
          enabled: false,
        },
        hideCursorInOverviewRuler: true,
        overviewRulerLanes: 0,
        inlineSuggest: {
          enabled: true,
          mode: 'prefix',
        },
      }),
      [theme, editingDisabled],
    );

  // setup monaco - note that there can be multiple editors but only one monaco instance
  // the `monaco.editor` is a namespace and does not point to a specific editor.
  React.useEffect(() => {
    if (!monaco) return;
    monaco.editor.defineTheme('collimator', {
      base: 'vs',
      inherit: true,
      rules: [
        { token: 'namespace', foreground: '#212121' },
        { token: 'comment', foreground: '#408080', fontStyle: 'italic' },
        { token: 'string', foreground: '#ba2121' },
        { token: 'keyword', foreground: '#008000', fontStyle: 'bold' },
        { token: '', foreground: '#0000ff' },
      ],
      colors: {
        'editorGutter.background': theme.colors.grey[10],
        'editor.background': theme.colors.grey[2],
        'editor.lineHighlightBorder': theme.colors.grey[2],
      },
    });
    monaco.editor.setTheme('collimator');
    if (codexAutocompleteEnabled) {
      setupAutocomplete(
        monaco,
        dispatch,
        timerId,
        codexAutocompleteModel,
        language,
      );
    }
  }, [
    codexAutocompleteEnabled,
    codexAutocompleteModel,
    dispatch,
    language,
    monaco,
    theme.colors.grey,
  ]);

  const internalOnPaneStateChange =
    (paramName: string) => (newClosedState: boolean) => {
      if (onPaneStateChange) {
        onPaneStateChange(paramName, newClosedState);
      }
    };

  return (
    <EditorScreen noWidthSet={noTopMargin}>
      {!noBreadcrumb && <ModelEditorBreadcrumb />}
      <EditorSection data-codeeditor noTopMargin={noTopMargin}>
        <Global
          styles={{
            '.monaco-editor .lines-content': {
              paddingLeft: getThemeValue(theme.spacing.large),
            },
            '.monaco-editor .margin-view-overlays .line-numbers': {
              color: theme.colors.text.secondary,
            },
          }}
        />
        {multiPaneData ? (
          <CodePanesContainer>
            {multiPaneData.map(
              (
                {
                  paneTitle,
                  paramKey,
                  defaultValue: paneDefault,
                  defaultClosed,
                },
                index,
              ) => (
                <CodeEditorWrapper
                  key={`code-editor-${index}`}
                  path={`${editorDisplayPath}_${paramKey}`}
                  title={paneTitle}
                  language={language || 'python'}
                  defaultValue={paneDefault}
                  defaultClosed={defaultClosed}
                  onChangeCode={(v) => onChangeCode(v, paramKey)}
                  editorOptions={editorOptions}
                  onFocused={(editor) => setEditorInFocus(editor)}
                  onPaneStateChange={internalOnPaneStateChange(paramKey)}
                  onDoubleClickWord={onDoubleClickWord}
                  inPane
                />
              ),
            )}
          </CodePanesContainer>
        ) : (
          <CodeEditorWrapper
            language={language || 'python'}
            defaultValue={defaultValue}
            valueOverride={valueOverride}
            onChangeCode={(v) => onChangeCode(v)}
            editorOptions={editorOptions}
            onFocused={(editor) => setEditorInFocus(editor)}
            onDoubleClickWord={onDoubleClickWord}
          />
        )}
      </EditorSection>
    </EditorScreen>
  );
};
