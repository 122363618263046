import { useGetSubmodelsListQuery } from 'app/enhancedApi';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { submodelsActions } from 'app/slices/submodelsSlice';
import React from 'react';

export function useSubmodels(projectId?: string) {
  const dispatch = useAppDispatch();
  const needToRefetchSubmodels = useAppSelector((state) =>
    projectId ? state.submodels.projectIdsToRefetchSubmodels[projectId] : false,
  );

  const { data, refetch, isFetching } = useGetSubmodelsListQuery(
    {
      projectUuid: projectId || '',
    },
    {
      skip: !projectId,
    },
  );

  // Make sure we refetch project submodels after another part of the system requests it.
  React.useEffect(() => {
    if (needToRefetchSubmodels && projectId) {
      refetch();
      dispatch(submodelsActions.clearRequestSubmodels(projectId));
    }
  }, [needToRefetchSubmodels, dispatch, refetch, projectId]);

  // Store the results in redux so we only need one instantiation of this hook.
  React.useEffect(() => {
    if (projectId && data && !isFetching) {
      dispatch(
        submodelsActions.setSubmodelInfoLites({ projectId, submodels: data }),
      );
    }
  }, [dispatch, data, projectId, isFetching]);
}
