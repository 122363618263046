import { useAppSelector } from 'app/hooks';
import {
  initSimulationRef,
  SimulationRef,
} from 'app/sliceRefAccess/SimulationRef';
import React from 'react';

export const SimulationRefAccess: React.FC = () => {
  const compilationData = useAppSelector((state) => state.compilationData);

  const refs = React.useRef<SimulationRef>({
    compilationData,
  });

  React.useEffect(() => {
    initSimulationRef(refs);
  }, []);

  React.useEffect(() => {
    refs.current.compilationData = compilationData;
  }, [compilationData]);

  return null;
};
