import { useModelData } from 'app/api/useModelData';
import { useModels } from 'app/api/useModels';
import { useSubmodelData } from 'app/api/useSubmodelData';
import { useUpdateSubmodel } from 'app/api/useUpdateSubmodel';
import { TagType, enhancedApi } from 'app/enhancedApi';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { modelMetadataActions } from 'app/slices/modelMetadataSlice';
import { projectActions } from 'app/slices/projectSlice';
import { submodelsActions } from 'app/slices/submodelsSlice';
import { RightSidebarSection, uiFlagsActions } from 'app/slices/uiFlagsSlice';
import { versionHistoryActions } from 'app/slices/versionHistorySlice';
import { WebSocketMessageType } from 'app/third_party_types/websocket/websocket-message-type';
import React from 'react';
import { useChatContext } from 'ui/appBottomBar/assistant/ChatContextProvider';
import { useCustomPartySocket } from 'ui/common/PartySocketProvider';
import { useModelPermission } from 'ui/permission/useModelPermission';
import { useAppParams } from 'util/useAppParams';

const UPDATE_DELAY_IN_MS = 200;

/*
 * This hook can be used to load any model data
 * regardless of whether it is a "submodel" or not.
 * We should keep this in mind for when we are "unifying" submodels and models
 */
export const useLoadModelDataHooks = () => {
  const dispatch = useAppDispatch();
  const { modelId, projectId, versionId } = useAppParams();

  const { topLevelModelId } = useAppSelector((state) => state.submodels);
  const topLevelModelType = useAppSelector(
    (state) => state.submodels.topLevelModelType,
  );

  const shouldntLoad =
    !modelId ||
    !projectId ||
    !topLevelModelId ||
    topLevelModelId !== modelId ||
    topLevelModelType === null;

  const isSubmodel = topLevelModelType === 'Submodel';
  const nonSubmodel = !isSubmodel;

  const { forceSilentModelReload: modelReload } = useModelData(
    projectId || '',
    modelId || '',
    versionId,
    topLevelModelType,
    shouldntLoad,
  );
  const { forceSilentModelReload: submodelReload } = useSubmodelData(
    projectId || '',
    modelId || '',
    versionId,
    topLevelModelType,
    shouldntLoad,
  );

  React.useEffect(() => {
    // Clear this model out of the cache when we leave this submodel edit session
    // because the submodel was likely edited and will have likely changed.
    if (topLevelModelType === 'Submodel' && modelId) {
      return () => {
        dispatch(submodelsActions.clearCacheForSubmodel(modelId));
      };
    }
  }, [dispatch, modelId, topLevelModelType]);

  const reloader = nonSubmodel
    ? modelReload
    : isSubmodel
    ? submodelReload
    : () => {};

  return { forceSilentModelReload: reloader };
};

export const UpdateAPIDispatcher = () => {
  const dispatch = useAppDispatch();

  const { projectId, modelId, versionId } = useAppParams();
  const { canEditCurrentModelVersion } = useModelPermission(
    projectId,
    modelId,
    versionId,
  );

  const { multiplayer: multiplayerEnabled } = useAppSelector(
    (state) => state.userOptions.options,
  );

  const { editId, loadedModelId, loadedVersionId } = useAppSelector(
    (state) => state.modelMetadata,
  );

  const topLevelModelType = useAppSelector(
    (state) => state.submodels.topLevelModelType,
  );

  const { forceUpdateModel, modelUpdatingId } = useAppSelector(
    (state) => state.project,
  );

  const sidebarSection: RightSidebarSection = useAppSelector(
    (state) => state.uiFlags.currentRightSidebarSection,
  );

  const customPartySocket = useCustomPartySocket();

  const { forceSilentModelReload } = useLoadModelDataHooks();
  // this callback (used in partkit socket events) needs to be injected without having to
  // juggle a bunch of unsub-resub funny business.
  const forceModelReloadFuncRef = React.useRef(forceSilentModelReload);
  forceModelReloadFuncRef.current = forceSilentModelReload;

  const {
    diagram,
    submodels,
    stateMachines,
    modelName,
    configuration,
    parameterDefinitions,
    submodelConfiguration,
    preventSendingUpdateData,
  } = useAppSelector((state) => ({
    preventSendingUpdateData: state.model.present.preventSendingUpdateData,
    diagram: state.model.present.rootModel,
    submodels: state.model.present.submodels,
    stateMachines: state.model.present.stateMachines,
    modelName: state.model.present.name,
    configuration: state.model.present.configuration,
    parameterDefinitions: state.model.present.parameterDefinitions,
    submodelConfiguration: state.model.present.submodelConfiguration,
  }));

  const { isCurrentlyCompletingRef } = useChatContext();

  const canUpdateModel =
    !preventSendingUpdateData &&
    modelId &&
    loadedModelId &&
    modelId === loadedModelId &&
    canEditCurrentModelVersion &&
    editId !== null &&
    !versionId &&
    !loadedVersionId &&
    !isCurrentlyCompletingRef.current;

  const { connect: multiplayerConnect, disconnect: multiplayerDisconnect } =
    customPartySocket;

  React.useEffect(() => {
    if (!multiplayerEnabled) return;

    if (modelId === loadedModelId) {
      multiplayerConnect(`${projectId}|${modelId}`);
    }

    return () => {
      multiplayerDisconnect();
    };
  }, [
    multiplayerConnect,
    multiplayerDisconnect,
    multiplayerEnabled,
    modelId,
    loadedModelId,
    projectId,
  ]);

  React.useEffect(() => {
    if (customPartySocket.isOpen && modelId === loadedModelId) {
      customPartySocket.subscribe(
        WebSocketMessageType.BCAST_DOC_UPDATE,
        'UpdateAPIDispatcher',
        () => {
          dispatch(
            enhancedApi.util.invalidateTags([
              { type: TagType.SubmodelContents, id: loadedModelId },
              { type: TagType.ModelContents, id: loadedModelId },
              { type: TagType.Project, id: projectId }, // NOTE: does not seem to help
            ]),
          );
          forceModelReloadFuncRef.current();
        },
      );
    }

    return () => {
      customPartySocket.unsubscribe(
        WebSocketMessageType.BCAST_DOC_UPDATE,
        'UpdateAPIDispatcher',
      );
    };
  }, [modelId, loadedModelId, projectId, customPartySocket, dispatch]);

  const { updateModelConfiguration, updateModelContent } = useModels();
  const { updateSubmodelContent } = useUpdateSubmodel();

  const timerIdRef = React.useRef<NodeJS.Timeout | null>(null);
  const lastModelNameRef = React.useRef<string | null>(null);
  const reQueryProjectsAfterNextUpdate = React.useRef<boolean>(false);

  /**
   * When user is editing the model, set the model editor overlays accordingly.
   */
  const enterEditMode = React.useCallback(() => {
    // TODO: make refs for the relevant UI flags so that gating doesn't trigger the larger useEffect
    dispatch(uiFlagsActions.setUIFlag({ showSignalNamesInModel: false }));
  }, [dispatch]);

  React.useEffect(() => {
    if (timerIdRef.current) {
      clearTimeout(timerIdRef.current);
    }

    // This is gated on model loating state AND "prevent update loops" state
    // (the "preventSendingUpdateData" flag in the modelSlice redux slice)
    if (!canUpdateModel) {
      lastModelNameRef.current = modelName;
      return;
    }

    timerIdRef.current = setTimeout(() => {
      dispatch(projectActions.requestUpdateModel());
    }, UPDATE_DELAY_IN_MS);

    // Prevent model version number mismatch errors when
    // running/checking a model right after the user makes a model change.
    dispatch(projectActions.startWaitingForModelUpdate());
  }, [
    dispatch,
    diagram,
    submodels,
    stateMachines,
    modelName,
    canUpdateModel,
    parameterDefinitions,
    submodelConfiguration,
  ]);

  // Watch for requests to call the actual API (after the appropriate delay has elapsed).
  React.useEffect(() => {
    if (!projectId) return;

    if (forceUpdateModel && !modelUpdatingId) {
      if (canUpdateModel) {
        dispatch(
          modelMetadataActions.logVersionEvent(
            `before send updateModelContent editId=${editId}`,
          ),
        );
        enterEditMode();
        if (topLevelModelType === 'Model') {
          updateModelContent(
            loadedModelId,
            {
              version: Number(editId),
              name: modelName,
              project_uuid: projectId,
              diagram: diagram as any,
              submodels: submodels as any,
              state_machines: stateMachines,
            },
            reQueryProjectsAfterNextUpdate.current,
          );
        } else {
          updateSubmodelContent({
            projectId,
            submodelId: loadedModelId,
            editId: String(editId),
            name: modelName,
            diagram,
            submodels,
            parameterDefinitions,
            submodelConfiguration,
            stateMachines,
          });
        }

        // Invalidate model contents cache
        dispatch(
          enhancedApi.util.invalidateTags([
            { type: TagType.ModelContents, id: loadedModelId },
          ]),
        );

        reQueryProjectsAfterNextUpdate.current = false;

        // Close version history and invalidate the cache if edits are made to the model.
        if (sidebarSection === RightSidebarSection.VersionHistory) {
          dispatch(
            uiFlagsActions.setRightSidebarTab(RightSidebarSection.Properties),
          );
          dispatch(versionHistoryActions.requestDiagramVersions());
        }
      }
      dispatch(projectActions.clearRequestToUpdateModel());
    }
  }, [
    customPartySocket,
    dispatch,
    forceUpdateModel,
    loadedModelId,
    updateModelContent,
    editId,
    projectId,
    modelName,
    diagram,
    submodels,
    stateMachines,
    parameterDefinitions,
    submodelConfiguration,
    canUpdateModel,
    modelUpdatingId,
    sidebarSection,
    topLevelModelType,
    updateSubmodelContent,
    enterEditMode,
    preventSendingUpdateData,
  ]);

  // Make sure we refetch projects if a model name changes.
  React.useEffect(() => {
    if (lastModelNameRef.current && lastModelNameRef.current !== modelName) {
      reQueryProjectsAfterNextUpdate.current = true;
      lastModelNameRef.current = modelName;
    }
  }, [dispatch, modelName]);

  React.useEffect(() => {
    // Submodels currently don't support configuration
    if (topLevelModelType === 'Submodel') {
      return;
    }

    // If we aren't allowed to update the model, just return.
    // (the preventSendingUpdateData flag (used in the canUpdateModel flag) will prevent sending data out on initial load)
    if (!canUpdateModel) {
      return;
    }

    updateModelConfiguration(loadedModelId, configuration);
  }, [
    updateModelConfiguration,
    configuration,
    canUpdateModel,
    loadedModelId,
    topLevelModelType,
  ]);

  // This effect's lifetime matches the lifetime of the component
  // to ensure the timer is cleaned up properly.
  React.useEffect(
    () => () => {
      if (timerIdRef.current) {
        clearTimeout(timerIdRef.current);
      }
    },
    [],
  );
  return null;
};
