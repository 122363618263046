import { t } from '@lingui/macro';
import { usePostBusTypeCreateMutation } from 'app/apiGenerated/generatedApi';
import React from 'react';
import Button from 'ui/common/Button/Button';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import Input from 'ui/common/Input/Input';
import Label from 'ui/common/Label';
import {
  ActionButtonContainer,
  ModalInputGroup,
  SmallFormContainer,
} from 'ui/common/Modal/Modal';
import { useModal } from 'ui/common/Modal/useModal';
import { useNotifications } from 'ui/common/notifications/useNotifications';

interface Props {
  projectId: string;
  onCreated: (id: string) => void;
}

export const CreateBusTypeModal: React.FC<Props> = ({
  projectId,
  onCreated,
}) => {
  const { showError } = useNotifications();
  const [name, setName] = React.useState('');
  const trimmedName = name.trim();

  const [submitting, setSubmitting] = React.useState(false);
  const { closeModal } = useModal();

  const [createBusType] = usePostBusTypeCreateMutation();

  const onCreateBusType = async () => {
    if (trimmedName.length === 0) {
      return;
    }

    setSubmitting(true);

    try {
      const { id } = await createBusType({
        projectUuid: projectId,
        busTypeCreateRequest: {
          name: trimmedName,
        },
      }).unwrap();

      onCreated(id);
    } catch (e) {
      showError(
        t({
          id: 'createBusTypeModal.createCallback.error',
          message: 'Failed to create bus type.',
        }),
        e,
      );
    }
    setSubmitting(false);
    closeModal();
  };

  return (
    <SmallFormContainer
      onSubmit={(e) => {
        e?.preventDefault();
        onCreateBusType();
      }}>
      <ModalInputGroup>
        <Label>
          {t({
            id: 'createBusTypeModal.nameInput.label',
            message: 'Name',
          })}
        </Label>
        <Input
          hasBorder
          autoFocus
          onChangeText={(v) => {
            setName(v);
          }}
          value={name}
        />
      </ModalInputGroup>
      <ActionButtonContainer>
        {/* Cancel button */}
        <Button
          type="button"
          onClick={closeModal}
          variant={ButtonVariants.LargeSecondary}>
          {t({
            id: 'createBusTypeModal.cancelButton.label',
            message: 'Cancel',
          })}
        </Button>

        {/* Create button */}
        <Button
          type="submit"
          variant={ButtonVariants.LargePrimary}
          loading={submitting}
          disabled={trimmedName.length === 0}>
          {t({
            id: 'createBusTypeModal.createButton.label',
            message: 'Create',
          })}
        </Button>
      </ActionButtonContainer>
    </SmallFormContainer>
  );
};
