import { DiagramVersionFull } from 'app/apiTransformers/convertGetSnapshotReadByUuid';
import {
  LinkInstance,
  NodeInstance,
  SubmodelsSection,
} from 'app/generated_types/SimulationModel';
import { ModelVersionRequestData } from 'app/slices/modelVersionsSlice';
import { getNextModelLevel } from 'util/modelVersion';

export interface NodeResult {
  // If there is a model (or submodel) version that we need to request,
  // it will be set here, otherwise this will be undefined.
  modelVersionToRequest?: ModelVersionRequestData;

  // If all the model and submodel versions that we needed to access the
  // requested parent node are available, the parent node will be set here,
  // otherwise this will be undefined.
  node?: NodeInstance;

  parentNode?: NodeInstance;

  // Provides the set of links for the parent node (if one was found),
  // otherwise undefined.
  links?: LinkInstance[];

  parentLinks?: LinkInstance[];

  // Provides an array of parent ids associated with the parent node (if one was found),
  // otherwise undefined.
  parentPath?: string[];

  // If there is a problem loading the model versions for this
  // parent name path, the error message will be set,
  // otherwise this will be undefined.
  errorMessage?: string;
  isExpectedError?: boolean;
}

export function getNodeForTopLevelModelPath(
  topLevelSubmodels: SubmodelsSection,
  topLevelNodes: NodeInstance[],
  topLevelLinks: LinkInstance[],
  signalPath: string,
  isModelEditor: boolean,
  modelIdToVersionIdToModelData: Record<
    string,
    Record<string, DiagramVersionFull>
  >,
): NodeResult {
  let submodels: SubmodelsSection = topLevelSubmodels;
  // Do not include the port name at the end.
  // Subdiagram Outport and Inport blocks are confused with the housing node's ports if this is included.
  const nameSections: string[] = signalPath.split('.').slice(0, -1);
  let nodes: NodeInstance[] = topLevelNodes;
  let links: LinkInstance[] = topLevelLinks;
  let parentLinks: LinkInstance[] | undefined;
  let previousParentNode: NodeInstance | undefined;
  let previousNode: NodeInstance | undefined;
  let parentPath: string[] = [];

  // Traverse nodes and submodels to find node and port associated with the name path.
  for (let i = 0; i < nameSections.length; i++) {
    const nameSection = nameSections[i];

    // First, check for a node with a name matching the current name section.
    let node = nodes.find((node) => node.name === nameSection);

    // If we find a node, see if it is a submodel node and
    // update the nodes and submodels we are searching
    // through to match the next layer we need to traverse.
    if (node) {
      previousParentNode = previousNode;
      previousNode = node;

      // Do not go down a level if it's the last item. The housing node is the target, not something the inside subdiagram.
      if (i === nameSections.length - 1) {
        break;
      }
      const levelResult = getNextModelLevel(
        node,
        submodels,
        modelIdToVersionIdToModelData,
      );
      if (levelResult.errorMessage) {
        return {
          errorMessage: levelResult.errorMessage,
          isExpectedError: levelResult.isExpectedError,
        };
      }
      if (levelResult.modelVersionToRequest) {
        // If this is the model editor, we don't need to load the last
        // submodel full because we have all the data we need in the
        // submodel info already which is automatically loaded by the model editor.
        if (nameSections.length > i + 2 || !isModelEditor) {
          return {
            modelVersionToRequest: levelResult.modelVersionToRequest,
          };
        }
      }
      if (levelResult.nodes) {
        nodes = levelResult.nodes;
        parentPath.push(node.uuid);
      }
      if (levelResult.links) {
        parentLinks = links;
        links = levelResult.links;
      }
      if (levelResult.submodels) {
        submodels = levelResult.submodels;
      }
    }
  }

  return {
    node: previousNode,
    parentNode: previousParentNode,
    links,
    parentLinks,
    parentPath,
  };
}
