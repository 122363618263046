/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Output: React.FC<Props> = (props) => (
  <svg viewBox="0 0 16 16" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9 6.5C9 6.22386 9.22386 6 9.5 6H11.5C11.7761 6 12 6.22386 12 6.5C12 6.77614 11.7761 7 11.5 7H9.5C9.22386 7 9 6.77614 9 6.5Z"
      fill={props.fill}
    />
    <path
      d="M9 8.5C9 8.22386 9.22386 8 9.5 8H11C11.2761 8 11.5 8.22386 11.5 8.5C11.5 8.77614 11.2761 9 11 9H9.5C9.22386 9 9 8.77614 9 8.5Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M6 9.5C7.10457 9.5 8 8.60457 8 7.5C8 6.39543 7.10457 5.5 6 5.5C4.89543 5.5 4 6.39543 4 7.5C4 8.60457 4.89543 9.5 6 9.5ZM7 7.5C7 8.05228 6.55228 8.5 6 8.5C5.44772 8.5 5 8.05228 5 7.5C5 6.94772 5.44772 6.5 6 6.5C6.55228 6.5 7 6.94772 7 7.5Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M4 3.5C2.89543 3.5 2 4.39543 2 5.5V9.5C2 10.6046 2.89543 11.5 4 11.5H7.5V13H4.5C4.22386 13 4 13.2239 4 13.5C4 13.7761 4.22386 14 4.5 14H11.5C11.7761 14 12 13.7761 12 13.5C12 13.2239 11.7761 13 11.5 13H8.5V11.5H12C13.1046 11.5 14 10.6046 14 9.5V5.5C14 4.39543 13.1046 3.5 12 3.5H4ZM12 4.5H4C3.44772 4.5 3 4.94772 3 5.5V9.5C3 10.0523 3.44772 10.5 4 10.5H12C12.5523 10.5 13 10.0523 13 9.5V5.5C13 4.94772 12.5523 4.5 12 4.5Z"
      fill={props.fill}
    />
  </svg>
);

Output.defaultProps = {
  width: '16',
  height: '16',
  viewBox: '0 0 16 16',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Output;
