import { usePostChatFileCreateMutation } from 'app/apiGenerated/generatedApi';
import React from 'react';

export const useImageUpload = () => {
  const [callPostChatFileCreateApi] = usePostChatFileCreateMutation();
  const uploadImage = React.useCallback(
    async (imageFile?: File) => {
      if (!imageFile) {
        return undefined;
      }
      const response = await callPostChatFileCreateApi({
        fileCreateRequest: {
          name: imageFile.name,
          content_type: imageFile.type,
          size: imageFile.size,
        },
      }).unwrap();

      if (!response.put_url) {
        throw new Error('No presigned URL returned');
      }
      await fetch(response.put_url, {
        method: 'PUT',
        headers: {
          'Content-Type': imageFile.type,
        },
        body: imageFile,
      });
      return response.get_url;
    },
    [callPostChatFileCreateApi],
  );

  const uploadImageB64 = React.useCallback(
    async (name: string, imageBase64: string) => {
      const byteCharacters = atob(imageBase64);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const response = await callPostChatFileCreateApi({
        fileCreateRequest: {
          name,
          content_type: 'image/png',
          size: byteArray.length,
        },
      }).unwrap();

      if (!response.put_url) {
        throw new Error('No presigned URL returned');
      }
      const putResponse = await fetch(response.put_url, {
        method: 'PUT',
        headers: {
          'Content-Type': 'image/png',
        },
        body: byteArray,
      });

      if (!putResponse.ok) {
        throw new Error('Failed to upload image');
      }

      return response.get_url;
    },
    [callPostChatFileCreateApi],
  );

  return { uploadImage, uploadImageB64 };
};
