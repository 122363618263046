import { SignIn } from '@clerk/clerk-react';
import React from 'react';
import { NotSignedInWrapper } from './NotSignedInWrapper';

const SignInPage: React.FC = () => (
  <NotSignedInWrapper>
    <SignIn
      redirectUrl="/login/sign-in-success"
      path="/login/sign-in"
      routing="virtual"
    />
  </NotSignedInWrapper>
);
export default SignInPage;
