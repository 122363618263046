/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Statemachine: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M4 6.99998C4 5.34313 5.34315 3.99998 7 3.99998H14C15.6569 3.99998 17 5.34313 17 6.99998V9.25352C18.0397 9.26883 18.9069 9.33558 19.6217 9.4845C20.4535 9.65779 21.1403 9.95465 21.6553 10.4697C22.1734 10.9877 22.4389 11.6477 22.5805 12.3909C22.6714 12.868 22.7158 13.4057 22.7357 14H24.5C24.9805 14 25.1844 14.6117 24.8 14.9L22.3 16.775C22.1222 16.9083 21.8778 16.9083 21.7 16.775L19.2 14.9C18.8156 14.6117 19.0195 14 19.5 14H21.2347C21.2158 13.4759 21.1771 13.0396 21.107 12.6716C20.9986 12.1022 20.8266 11.7623 20.5947 11.5303C20.3597 11.2953 19.984 11.0922 19.3158 10.953C18.7366 10.8323 17.9846 10.7691 17 10.7537V12C17 13.6568 15.6569 15 14 15H7C5.34315 15 4 13.6568 4 12V6.99998ZM7 5.49998H14C14.8284 5.49998 15.5 6.17156 15.5 6.99998V12C15.5 12.8284 14.8284 13.5 14 13.5H7C6.17157 13.5 5.5 12.8284 5.5 12V6.99998C5.5 6.17156 6.17157 5.49998 7 5.49998Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M28.001 25C28.001 26.6568 26.6578 28 25.001 28H18.001C16.3441 28 15.001 26.6568 15.001 25V22.7465C13.9613 22.7311 13.0941 22.6644 12.3793 22.5155C11.5475 22.3422 10.8606 22.0453 10.3456 21.5303C9.82761 21.0123 9.56203 20.3522 9.42047 19.6091C9.3296 19.132 9.28518 18.5943 9.26526 18H7.50097C7.02047 18 6.81657 17.3883 7.20098 17.1L9.70098 15.225C9.87875 15.0917 10.1232 15.0917 10.301 15.225L12.801 17.1C13.1854 17.3883 12.9815 18 12.501 18L10.7663 18C10.7852 18.5241 10.8239 18.9604 10.894 19.3284C11.0024 19.8977 11.1743 20.2377 11.4063 20.4697C11.6413 20.7047 12.017 20.9078 12.6852 21.047C13.2643 21.1677 14.0164 21.2309 15.001 21.2463V20C15.001 18.3431 16.3441 17 18.001 17L25.001 17C26.6578 17 28.001 18.3431 28.001 20V25ZM25.001 26.5H18.001C17.1725 26.5 16.501 25.8284 16.501 25L16.501 20C16.501 19.1716 17.1725 18.5 18.001 18.5L25.001 18.5C25.8294 18.5 26.501 19.1716 26.501 20L26.501 25C26.501 25.8284 25.8294 26.5 25.001 26.5Z"
      fill={props.fill}
    />
  </svg>
);

Statemachine.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Statemachine;
