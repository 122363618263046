/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Account: React.FC<Props> = (props) => (
  <svg viewBox="0 0 24 24" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M16 8C16 10.2091 14.2091 12 12 12C9.79086 12 8 10.2091 8 8C8 5.79086 9.79086 4 12 4C14.2091 4 16 5.79086 16 8ZM14.5 8C14.5 9.38071 13.3807 10.5 12 10.5C10.6193 10.5 9.5 9.38071 9.5 8C9.5 6.61929 10.6193 5.5 12 5.5C13.3807 5.5 14.5 6.61929 14.5 8Z"
      fill={props.fill}
    />
    <path
      d="M10 12.75C7.37665 12.75 5.25 14.8766 5.25 17.5V19.5C5.25 19.9142 5.58579 20.25 6 20.25C6.41421 20.25 6.75 19.9142 6.75 19.5V17.5C6.75 15.7051 8.20507 14.25 10 14.25H14C15.7949 14.25 17.25 15.7051 17.25 17.5V19.5C17.25 19.9142 17.5858 20.25 18 20.25C18.4142 20.25 18.75 19.9142 18.75 19.5V17.5C18.75 14.8766 16.6234 12.75 14 12.75H10Z"
      fill={props.fill}
    />
  </svg>
);

Account.defaultProps = {
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Account;
