import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface CodeGenState {
  requestCodeGenerationDownload: boolean;
  codeGenerationName?: string;
  codeGenerationMode?: CodegenMode;
}

const initialState: CodeGenState = {
  requestCodeGenerationDownload: false,
};

export type CodegenMode = 'code_export' | 'fmu_export';

const codeGenSlice = createSlice({
  name: 'codeGenSlice',
  initialState,
  reducers: {
    resetCodeGenState: () => initialState,
    requestCodeGenerationDownload(state) {
      state.requestCodeGenerationDownload = true;
    },
    setCodeGenerationProperties(
      state,
      action: PayloadAction<{
        name: string;
        mode: CodegenMode;
      }>,
    ) {
      const { name, mode } = action.payload;
      state.codeGenerationName = name;
      state.codeGenerationMode = mode;
    },
    clearCodeGenerationDownload(state) {
      state.requestCodeGenerationDownload = false;
      state.codeGenerationName = undefined;
    },
  },
});

export const codeGenActions = codeGenSlice.actions;

export default codeGenSlice;
