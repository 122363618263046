import { t } from '@lingui/macro';
import React from 'react';
import SectionSearch from 'ui/objectBrowser/sections/SectionSearch';
import { useAppParams } from 'util/useAppParams';
import DiagramVersionList from './DiagramVersionList';

export const DiagramVersionsPane: React.FC = () => {
  const [versionSearchString, setVersionSearchString] = React.useState('');

  const { projectId, modelId, versionId } = useAppParams();

  return (
    <>
      <SectionSearch
        placeholder={t({
          id: 'modelRenderer.versionHistory.versionSearchPlaceholder',
          message: 'Search for versions',
        })}
        onChangeText={setVersionSearchString}
      />
      {modelId && projectId && (
        <DiagramVersionList
          versionSearchString={versionSearchString}
          modelId={modelId}
          projectId={projectId}
          versionId={versionId}
        />
      )}
    </>
  );
};

export default DiagramVersionsPane;
