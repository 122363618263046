/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const MatrixoperatorInverse: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M24.4514 8.60001C24.3954 8.60001 24.3354 8.61201 24.2714 8.63601L22.9754 9.28401C22.9114 9.31601 22.8794 9.37201 22.8794 9.45201L22.9154 10.244C22.9154 10.284 22.9234 10.316 22.9394 10.34C22.9634 10.364 22.9914 10.376 23.0234 10.376C23.0474 10.376 23.0674 10.372 23.0834 10.364L24.2834 9.99201C24.2994 9.98401 24.3154 9.98801 24.3314 10.004C24.3474 10.012 24.3554 10.024 24.3554 10.04V16.856C24.3554 16.896 24.3674 16.932 24.3914 16.964C24.4234 16.988 24.4594 17 24.4994 17H25.6034C25.6434 17 25.6754 16.988 25.6994 16.964C25.7314 16.932 25.7474 16.896 25.7474 16.856V8.74401C25.7474 8.70401 25.7314 8.67201 25.6994 8.64801C25.6754 8.61601 25.6434 8.60001 25.6034 8.60001H24.4514Z"
      fill={props.fill}
    />
    <path
      d="M17.9198 13.952C17.9518 13.976 17.9878 13.988 18.0278 13.988H21.8078C21.8478 13.988 21.8798 13.976 21.9038 13.952C21.9358 13.92 21.9518 13.884 21.9518 13.844V12.932C21.9518 12.892 21.9358 12.86 21.9038 12.836C21.8798 12.804 21.8478 12.788 21.8078 12.788H18.0278C17.9878 12.788 17.9518 12.804 17.9198 12.836C17.8958 12.86 17.8838 12.892 17.8838 12.932V13.844C17.8838 13.884 17.8958 13.92 17.9198 13.952Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M14.9161 21.86C14.9441 21.9533 15.0047 22 15.0981 22H16.4981C16.6007 22 16.6521 21.958 16.6521 21.874C16.6521 21.846 16.6474 21.8227 16.6381 21.804L13.5861 12.34C13.5581 12.2467 13.4974 12.2 13.4041 12.2H11.6541C11.5607 12.2 11.5001 12.2467 11.4721 12.34L8.43405 21.804C8.41538 21.8693 8.42005 21.9207 8.44805 21.958C8.47605 21.986 8.51805 22 8.57405 22H9.97405C10.0674 22 10.1281 21.9533 10.1561 21.86L10.6181 20.362C10.6367 20.3247 10.6601 20.306 10.6881 20.306H14.3841C14.4121 20.306 14.4354 20.3247 14.4541 20.362L14.9161 21.86ZM11.1081 19.032C11.0614 19.032 11.0427 19.004 11.0521 18.948L12.4801 14.258C12.4894 14.2207 12.5034 14.202 12.5221 14.202C12.5407 14.202 12.5547 14.2207 12.5641 14.258L14.0061 18.948C14.0247 19.004 14.0061 19.032 13.9501 19.032H11.1081Z"
      fill={props.fill}
    />
  </svg>
);

MatrixoperatorInverse.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default MatrixoperatorInverse;
