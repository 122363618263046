/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  stroke?: any;
  strokeWidth?: any;
  className?: string;
}

const AcausalRotationalEngine: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.5843 10.1095H13.4317C13.2992 10.1095 13.172 10.1621 13.0783 10.2558L10.3034 13.0292H8.52809C7.9758 13.0292 7.52809 13.4769 7.52809 14.0292V16.3869M16.5843 10.1095V7.33577H14.309C14.0328 7.33577 13.809 7.11191 13.809 6.83577V5.5C13.809 5.22386 14.0328 5 14.309 5H20.9045C21.1806 5 21.4045 5.22386 21.4045 5.5V6.83577C21.4045 7.11191 21.1806 7.33577 20.9045 7.33577H18.7753V10.1095M16.5843 10.1095H18.7753M18.7753 10.1095H23.9101C24.4624 10.1095 24.9101 10.5572 24.9101 11.1095V15.365H26.809V13.2372C26.809 12.9611 27.0328 12.7372 27.309 12.7372H28.5C28.7761 12.7372 29 12.9611 29 13.2372V20.7044C29 20.9805 28.7761 21.2044 28.5 21.2044H27.309C27.0328 21.2044 26.809 20.9805 26.809 20.7044V18.5766H24.9101V20.4745L20.5285 24.8537C20.4347 24.9474 20.3076 25 20.175 25H13.3005C13.1592 25 13.0246 24.9403 12.9298 24.8356L10.4522 22.0987C10.3574 21.994 10.2228 21.9343 10.0816 21.9343H8.52809C7.9758 21.9343 7.52809 21.4866 7.52809 20.9343V18.5766M7.52809 16.3869H5.33708V13.5292C5.33708 13.2531 5.11322 13.0292 4.83708 13.0292H3.5C3.22386 13.0292 3 13.2531 3 13.5292V21.4343C3 21.7104 3.22386 21.9343 3.5 21.9343H4.83708C5.11322 21.9343 5.33708 21.7104 5.33708 21.4343V18.5766H7.52809M7.52809 16.3869V18.5766"
      stroke={props.stroke}
      strokeWidth={props.strokeWidth}
    />
    <path
      d="M18.1808 14.2421C18.4021 14.3369 18.4345 14.6224 18.2643 14.7926L17.9504 15.1065C17.8404 15.2166 17.6746 15.2437 17.5252 15.2001C17.3561 15.1507 17.1794 15.125 17 15.125C16.5027 15.125 16.0258 15.3225 15.6742 15.6742C15.4005 15.9478 15.2202 16.2974 15.1537 16.6734C15.1347 16.7803 15.2215 16.8725 15.33 16.8725H15.9248C16.0764 16.8725 16.1593 17.0387 16.0852 17.1578C16.0775 17.1701 16.0689 17.1817 16.0602 17.1933C16.0511 17.2055 16.0421 17.2177 16.0341 17.2307L14.8791 19.1206L14.8779 19.121L14.8774 19.1204L14.877 19.1196C14.3154 18.5572 14 17.7949 14 17C14 16.2044 14.3161 15.4413 14.8787 14.8787C15.4413 14.3161 16.2044 14 17 14C17.4099 14 17.8111 14.0839 18.1808 14.2421Z"
      fill={props.fill}
    />
    <path
      d="M15.8192 19.7579C15.5979 19.6631 15.5655 19.3776 15.7357 19.2074L16.0496 18.8935C16.1596 18.7834 16.3254 18.7563 16.4748 18.7999C16.6439 18.8493 16.8206 18.875 17 18.875C17.4973 18.875 17.9742 18.6775 18.3258 18.3258C18.5995 18.0522 18.7798 17.7026 18.8463 17.3266C18.8653 17.2197 18.7785 17.1275 18.67 17.1275H18.0752C17.9236 17.1275 17.8407 16.9613 17.9148 16.8422C17.9225 16.8299 17.9311 16.8183 17.9398 16.8067C17.9489 16.7945 17.9579 16.7823 17.9659 16.7693L19.1209 14.8794L19.1221 14.879L19.1226 14.8796L19.123 14.8804C19.6846 15.4428 20 16.2051 20 17C20 17.7956 19.6839 18.5587 19.1213 19.1213C18.5587 19.6839 17.7956 20 17 20C16.5901 20 16.1889 19.9161 15.8192 19.7579Z"
      fill={props.fill}
    />
  </svg>
);

AcausalRotationalEngine.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  stroke: '#1D3131',
  strokeWidth: '1.5',
  className: '',
};

export default AcausalRotationalEngine;
