import { t } from '@lingui/macro';
import { Project } from 'app/apiTransformers/convertAPIProjectToProject';

// FIXME: these filters are not mutually exclusive. The three subcategories are:
// 1. published state
// 2. favorited state
// 3. owneship
// We should tease these apart and properly handle the combinations. Esp for libraries.
export type ProjectFilter = 'public' | 'mine' | 'sharedWithMe';

export function getProjectFilterLabel(projectFilter: ProjectFilter) {
  switch (projectFilter) {
    case 'sharedWithMe':
      return t({
        id: 'project.filters.label.sharedWithMe',
        message: 'Shared with me',
      });
    case 'public':
      return t({
        id: 'project.filters.label.public',
        message: 'Public projects',
      });
    case 'mine':
      return t({
        id: 'project.filters.label.mine',
        message: 'My Projects',
      });
  }
}

/**
 * Fixed project paths that are not tied to a specific entity.
 * Should be used as query params.
 */
export const PROJECT_FILTER_SUBPATHS = ['public', 'shared-with-me'] as const;
export type FixedProjectPath = typeof PROJECT_FILTER_SUBPATHS[number];

export function getProjectFilterSubpath(
  projectFilter: ProjectFilter & ('public' | 'sharedWithMe'),
): FixedProjectPath | undefined {
  switch (projectFilter) {
    case 'sharedWithMe':
      return 'shared-with-me';
    case 'public':
      return 'public';
  }
}

// Won't need this if we use query params. But at least it's in one place and explicit.
export function getProjectFilterPath(projectFilter: ProjectFilter) {
  switch (projectFilter) {
    case 'sharedWithMe':
    case 'public':
      return `/projects/${getProjectFilterSubpath(projectFilter)}`;
    case 'mine':
      return '/projects';
  }
}

/** Ownership takes precedent over published status. */
export function getProjectFilter(
  project: Project,
  userUuid: string,
): ProjectFilter {
  if (project.ownerUuid === userUuid) {
    return 'mine';
  }
  if (project.isMember) {
    return 'sharedWithMe';
  }
  if (project.isPublic) {
    return 'public';
  }
  return 'sharedWithMe';
}
