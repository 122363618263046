import { Project } from 'app/apiTransformers/convertAPIProjectToProject';
import React from 'react';
import { Badge, BadgeType } from 'ui/common/badge/Badge';

interface Props {
  project: Project;
  showPublicBadge: boolean;
}

export const ProjectBadges: React.FC<Props> = ({
  project,
  showPublicBadge,
}) => (
  <>
    {showPublicBadge && project.isPublic && (
      <Badge badgeType={BadgeType.Public} />
    )}
    {project.isNew && <Badge badgeType={BadgeType.New} />}
  </>
);
