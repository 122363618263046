import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useProject } from './useProject';

export function useNavigateToProjectHome() {
  const { project } = useProject();
  const navigate = useNavigate();

  const navigateToProjectHome = React.useCallback(() => {
    if (project) {
      return navigate(`/projects/${project.uuid}`);
    }

    return navigate(`/projects`);
  }, [navigate, project]);

  return {
    navigateToProjectHome,
  };
}
