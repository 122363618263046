/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  className?: string;
}

const Delay: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16 4.75C16 5.16421 15.6638 5.49721 15.2506 5.52677C13.7837 5.63173 12.3523 6.04394 11.0503 6.73983C9.52749 7.5538 8.22889 8.73079 7.26957 10.1665C6.31025 11.6022 5.71981 13.2524 5.55056 14.9708C5.38131 16.6892 5.63847 18.4229 6.29927 20.0182C6.96006 21.6135 8.00409 23.0212 9.33887 24.1166C10.6737 25.212 12.258 25.9614 13.9516 26.2982C15.6451 26.6351 17.3956 26.5491 19.048 26.0479C20.4607 25.6194 21.7643 24.8986 22.8758 23.9356C23.1888 23.6643 23.6621 23.6621 23.955 23.955C24.2478 24.2478 24.2493 24.7247 23.9387 24.9987C22.6511 26.1347 21.1322 26.9831 19.4834 27.4833C17.595 28.0561 15.5944 28.1544 13.6589 27.7694C11.7234 27.3844 9.91275 26.528 8.38728 25.2761C6.86182 24.0242 5.66864 22.4154 4.91345 20.5922C4.15826 18.769 3.86436 16.7877 4.05779 14.8238C4.25121 12.8599 4.926 10.974 6.02237 9.33316C7.11874 7.69233 8.60285 6.34721 10.3432 5.41695C11.8628 4.60475 13.5367 4.13068 15.2505 4.02343C15.6639 3.99756 16 4.33579 16 4.75Z"
      fill={props.fill}
    />
    <path
      d="M18.5013 4.26364C18.1039 4.17893 17.7288 4.45811 17.6686 4.8599C17.6084 5.26168 17.8861 5.63361 18.2827 5.72171C18.3693 5.74096 18.4558 5.76133 18.5422 5.78281C18.6285 5.8043 18.7145 5.82685 18.8 5.85045C19.1917 5.9585 19.6113 5.76009 19.7464 5.37697C19.8815 4.99382 19.681 4.57136 19.2903 4.45996C19.1601 4.42286 19.0292 4.3879 18.8974 4.35512C18.7657 4.32234 18.6336 4.29185 18.5013 4.26364Z"
      fill={props.fill}
    />
    <path
      d="M21.9387 7.3061C21.6032 7.07693 21.4836 6.62845 21.6888 6.27781C21.894 5.92717 22.346 5.80739 22.6834 6.03367C22.9086 6.18466 23.1285 6.34324 23.3429 6.50912C23.6643 6.75772 23.6935 7.22444 23.4257 7.52993C23.1579 7.83544 22.6945 7.86371 22.3711 7.61786C22.2296 7.51031 22.0854 7.40636 21.9387 7.3061Z"
      fill={props.fill}
    />
    <path
      d="M24.8942 9.08811C24.5734 9.33739 24.5178 9.7982 24.7441 10.1356C24.7935 10.2093 24.8421 10.2838 24.8897 10.3589C24.9374 10.434 24.9841 10.5096 25.0297 10.5858C25.2386 10.9342 25.6792 11.0801 26.0414 10.896C26.4036 10.7119 26.5499 10.2678 26.344 9.91754C26.2754 9.80089 26.2047 9.68523 26.132 9.57061C26.0592 9.456 25.9847 9.34281 25.9084 9.23108C25.6792 8.89561 25.215 8.83883 24.8942 9.08811Z"
      fill={props.fill}
    />
    <path
      d="M27.7142 13.3979C27.6261 13.0013 27.2162 12.7761 26.8258 12.8883C26.4353 13.0005 26.2124 13.4077 26.2971 13.805C26.3342 13.9788 26.3668 14.1536 26.395 14.329C26.4595 14.7302 26.8143 15.0294 27.219 14.993C27.6236 14.9567 27.9245 14.5987 27.8635 14.1971C27.8228 13.9291 27.773 13.6625 27.7142 13.3979Z"
      fill={props.fill}
    />
    <path
      d="M27.8423 17.9374C27.9079 17.5364 27.6111 17.175 27.2069 17.1341C26.8027 17.0932 26.4444 17.3884 26.3754 17.7887C26.3603 17.8762 26.3441 17.9636 26.3268 18.0509C26.3095 18.1381 26.2911 18.2251 26.2716 18.3117C26.1824 18.708 26.4006 19.1176 26.7898 19.2343C27.1789 19.3509 27.5913 19.1304 27.6839 18.7348C27.7147 18.6031 27.7434 18.4706 27.7698 18.3374C27.7963 18.2043 27.8204 18.0709 27.8423 17.9374Z"
      fill={props.fill}
    />
    <path
      d="M26.2743 22.1994C26.4842 21.8515 26.3429 21.4057 25.9829 21.2176C25.6228 21.0294 25.1806 21.1703 24.9677 21.5163C24.8746 21.6677 24.7777 21.8167 24.677 21.9632C24.4469 22.298 24.4973 22.7594 24.8152 23.0123C25.1332 23.2652 25.598 23.2137 25.8309 22.8809C25.9864 22.6588 26.1343 22.4315 26.2743 22.1994Z"
      fill={props.fill}
    />
    <path
      d="M16.5303 15.4697C16.2374 15.1768 15.7626 15.1768 15.4697 15.4697C15.1768 15.7626 15.1768 16.2374 15.4697 16.5303L21.4697 22.5303C21.7626 22.8232 22.2374 22.8232 22.5303 22.5303C22.8232 22.2374 22.8232 21.7626 22.5303 21.4697L16.5303 15.4697Z"
      fill={props.fill}
    />
  </svg>
);

Delay.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  className: '',
};

export default Delay;
