/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Append: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M13.5 7.5C13.5 7.22386 13.7239 7 14 7H18C18.2761 7 18.5 7.22386 18.5 7.5V9H24.5C25.8807 9 27 10.1193 27 11.5V14.2929L28.1464 13.1464C28.3417 12.9512 28.6583 12.9512 28.8536 13.1464C29.0488 13.3417 29.0488 13.6583 28.8536 13.8536L26.5 16.2071L24.1464 13.8536C23.9512 13.6583 23.9512 13.3417 24.1464 13.1464C24.3417 12.9512 24.6583 12.9512 24.8536 13.1464L26 14.2929V11.5C26 10.6716 25.3284 10 24.5 10H18.5V11.5C18.5 11.7761 18.2761 12 18 12H14C13.7239 12 13.5 11.7761 13.5 11.5V7.5ZM15 10.5V8.5H17V10.5H15Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M3 16.5C3 16.2239 3.22386 16 3.5 16H7.5C7.77614 16 8 16.2239 8 16.5V18H10V16.5C10 16.2239 10.2239 16 10.5 16H14.5C14.7761 16 15 16.2239 15 16.5V18H17V16.5C17 16.2239 17.2239 16 17.5 16H21.5C21.7761 16 22 16.2239 22 16.5V18H23C23.2761 18 23.5 18.2239 23.5 18.5C23.5 18.7761 23.2761 19 23 19H22V20.5C22 20.7761 21.7761 21 21.5 21H17.5C17.2239 21 17 20.7761 17 20.5V19H15V20.5C15 20.7761 14.7761 21 14.5 21H10.5C10.2239 21 10 20.7761 10 20.5V19H8V20.5C8 20.7761 7.77614 21 7.5 21H3.5C3.22386 21 3 20.7761 3 20.5V16.5ZM4.5 19.5V17.5H6.5V19.5H4.5ZM11.5 19.5V17.5H13.5V19.5H11.5ZM18.5 17.5V19.5H20.5V17.5H18.5Z"
      fill={props.fill}
    />
    <path
      d="M24 20.5V19.5C24 19.2239 24.2239 19 24.5 19C24.7761 19 25 19.2239 25 19.5V20H25.5C25.7761 20 26 20.2239 26 20.5C26 20.7761 25.7761 21 25.5 21H24.5C24.2239 21 24 20.7761 24 20.5Z"
      fill={props.fill}
    />
    <path
      d="M25 17V17.5C25 17.7761 24.7761 18 24.5 18C24.2239 18 24 17.7761 24 17.5V16.5C24 16.2239 24.2239 16 24.5 16H25.5C25.7761 16 26 16.2239 26 16.5C26 16.7761 25.7761 17 25.5 17H25Z"
      fill={props.fill}
    />
    <path
      d="M27 16.5C27 16.2239 27.2239 16 27.5 16H28.5C28.7761 16 29 16.2239 29 16.5V17.5C29 17.7761 28.7761 18 28.5 18C28.2239 18 28 17.7761 28 17.5V17H27.5C27.2239 17 27 16.7761 27 16.5Z"
      fill={props.fill}
    />
    <path
      d="M28 20V19.5C28 19.2239 28.2239 19 28.5 19C28.7761 19 29 19.2239 29 19.5V20.5C29 20.7761 28.7761 21 28.5 21H27.5C27.2239 21 27 20.7761 27 20.5C27 20.2239 27.2239 20 27.5 20H28Z"
      fill={props.fill}
    />
  </svg>
);

Append.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Append;
