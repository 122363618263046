// Tree shaking imports available: https://echarts.apache.org/handbook/en/basics/import/#

import styled from '@emotion/styled';
import useDebounce, {
  useAppDispatch,
  useAppSelector,
  useComponentSize,
} from 'app/hooks';
import { dataExplorerActions } from 'app/slices/dataExplorerSlice';
import { traceDragActions } from 'app/slices/traceDragSlice';
import * as echarts from 'echarts';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTimeoutFn } from 'react-use';
import ReactEChartsChart from 'ui/dataExplorer/charts/ReactEChartsChart';
import TraceContextMenu from 'ui/dataExplorer/charts/TraceContextMenu';
import {
  BASE_PLOT_CONF,
  MARKPOINT_PLOT_CONF,
  PLOT_CONF_WITH_SLIDER_ZOOM,
  SERIES_BLOCK_CONF,
  X_AXIS,
  Y_AXIS,
} from 'ui/dataExplorer/charts/constants';
import {
  MarkedPoint,
  PartialDataBounds,
  PlotCellArrayData,
  TraceMetadata,
} from 'ui/dataExplorer/dataExplorerTypes';
import { getMouseCoords } from 'util/getMouseCoords';
import { assignFreeColor } from 'util/visualizerUtils';
import ChartMenu from './ChartMenu';

// Keep this short to minimize the jitteriness of scroll.
const BACKEND_DATA_FETCH_DEBOUNCE_DELAY = 100;
const ENABLE_LEGEND_HOVER_HIGHLIGHT = true;

// Zoom types for ECharts inside zoom: https://echarts.apache.org/en/option.html#dataZoom-inside
enum ZoomID {
  X = 'InsideX',
  Y = 'InsideY',
  SliderX = 'SliderX',
}

// isolation: isolate temporarily removed until we figure out the overflowing chart menus.
const ReactEChartsChartWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

interface Props {
  canEdit: boolean;
  traceIds: string[];
  data: PlotCellArrayData[];
  minimapDataTraceId?: string;
  initialBounds: PartialDataBounds;
  zoomBounds?: PartialDataBounds;
  fetchZoomedData: (from: number, to: number) => void;
}

/**
 * A Chart for Data Explorer and Visualizer.
 * Adds custom Collimator logic to ECharts.
 */
const Chart: React.FC<Props> = ({
  canEdit,
  traceIds,
  data,
  minimapDataTraceId,
  initialBounds,
  zoomBounds,
  fetchZoomedData,
}) => {
  const dispatch = useAppDispatch();
  const wrapperRef = useRef<HTMLDivElement>(null);

  const idToTrace = useAppSelector((state) => state.dataExplorer.idToTrace);

  const serieIdToMarkedPoints = useAppSelector(
    (state) => state.dataExplorer.serieIdToMarkedPoints,
  );

  const [contextMenuTraceId, setContextMenuTraceId] = useState('');

  const [echartsInstance, setEchartsInstance] = useState<echarts.ECharts>();

  // The chart needs explicit sizing to render properly.
  const { height, width } = useComponentSize(wrapperRef);

  // Must we use coords ..
  const [lineMenuCoords, setLineMenuCoords] = useState({
    top: -9999,
    left: -9999,
  });

  // Data source (CSV file) zoom and its debounced state
  // TODO: clarify difference between CSV zoom and echarts only zoom.
  // Drag area zoom is already buggy before refactor.
  const [partialZoom, setPartialZoom] = useState<{
    from: number;
    to: number;
  }>();
  const debouncedZoom = useDebounce(
    partialZoom,
    BACKEND_DATA_FETCH_DEBOUNCE_DELAY,
  );
  useEffect(() => {
    if (debouncedZoom?.from !== undefined && debouncedZoom?.to !== undefined) {
      fetchZoomedData(debouncedZoom.from, debouncedZoom.to);
    } else {
      // Reset to initial bounds if zoom is undefined.
      fetchZoomedData(initialBounds.startX, initialBounds.endX);
    }
  }, [debouncedZoom, initialBounds, fetchZoomedData]);

  // The following hack allows wheel scroll over echart plots.
  // Hack to control zoom behaviour. Turn off if no event emitted for set time.
  // ECharts swallows all wheel events when their own dataZoom is on.
  // Even specifying modifier keys does not work, and there's no API to allow default.
  const [echartsScrollZoomOn, setEChartsScrollZoomOn] = useState(false);
  const [_isReady, _cancel, resetScrollZoomTimeout] = useTimeoutFn(() => {
    setEChartsScrollZoomOn(false);
  }, 300); // 300ms is the sweet spot. Doesn't trigger while scrolling, and turns off shortly after pause.

  const turnOnDataZoom = useCallback(
    (e: React.WheelEvent<HTMLElement>) => {
      if (e.shiftKey || e.ctrlKey) {
        if (!echartsScrollZoomOn) {
          setEChartsScrollZoomOn(true);
        } else {
          resetScrollZoomTimeout();
        }
      }
    },
    [echartsScrollZoomOn, resetScrollZoomTimeout],
  );

  const [minY, setMinY] = useState<number | undefined>(initialBounds.startY);
  const [maxY, setMaxY] = useState<number | undefined>(initialBounds.endY);
  const setYBounds = useCallback(
    (min: number, max: number) => {
      setMinY(min);
      setMaxY(max);
    },
    [setMinY, setMaxY],
  );

  // Highlight series on legend hover, NOT on series hover. The latter leads to
  // a ton of flickering. Ref: https://github.com/apache/echarts/issues/17357
  // This is a bit hacky and sometimes does not work when hovering over a legend
  // item the first time. Just live with it for now...
  const [highlightingLegend, setHighlightingLegend] = useState(false);
  const highlightRAF = useRef<boolean>(false);
  const onHighlightEvent = useCallback(
    (e: any) => {
      if (!ENABLE_LEGEND_HOVER_HIGHLIGHT) return;
      if (!echartsInstance) return;

      const shouldEnableHighlight = !!e.seriesName;
      if (shouldEnableHighlight !== highlightingLegend) {
        setHighlightingLegend(shouldEnableHighlight);
        if (shouldEnableHighlight && !highlightRAF.current) {
          highlightRAF.current = true;
          window.requestAnimationFrame(() => {
            highlightRAF.current = false;
          });

          echartsInstance.dispatchAction({
            type: 'highlight',
            seriesName: e.seriesName,
          });
        }
      }
    },
    [echartsInstance, highlightingLegend, highlightRAF],
  );

  // ECharts options for the wrapped instance
  const chartOptions: echarts.EChartsCoreOption = useMemo(() => {
    const usedColors: string[] = [];

    const dataZoom: echarts.DataZoomComponentOption[] = [
      {
        id: ZoomID.X,
        type: 'inside',
        xAxisIndex: [0],
        zoomOnMouseWheel: 'shift',
        disabled: !echartsScrollZoomOn,
        filterMode: 'none',
        startValue: zoomBounds?.startX,
        endValue: zoomBounds?.endX,
        rangeMode: ['value', 'value'],
      },
      {
        id: ZoomID.Y,
        type: 'inside',
        yAxisIndex: [0],
        zoomOnMouseWheel: 'ctrl',
        disabled: !echartsScrollZoomOn,
        filterMode: 'none',
      },
    ];

    if (minimapDataTraceId) {
      dataZoom.push({
        id: ZoomID.SliderX,
        type: 'slider',
        xAxisIndex: [0],
        filterMode: 'none',
        startValue: zoomBounds?.startX,
        endValue: zoomBounds?.endX,
        rangeMode: ['value', 'value'],
        orient: 'horizontal',
      });
    }

    const dataset = data.map((d, i) => ({
      source: d.array.data,
      // Part 1 of the magic of decoding multiple time series from a single ndarray:
      // we need to specify the dimensions of the dataset.
      // For part 2, see encode in the series definition.
      dimensions: Array.from({ length: d.array.shape[1] }, (_, i) => i),
    }));

    const series: any[] = [];
    for (let dataPlotId = 0; dataPlotId < data.length; dataPlotId++) {
      const dataPlot = data[dataPlotId];
      const trace: TraceMetadata | undefined = idToTrace[dataPlot.traceId];

      // NOTE: We keep the minimap as a proper series so that it actually
      // shows correct data in the slider but does not appear, with '' as name
      // for the legend and transparent color for the graph. Ideally it should
      // not be visible at all, but echarts does not seem to support that.
      // if (dataPlot.traceId === minimapDataTraceId) continue;

      const isVector = dataPlot.array.shape[1] > 2;
      for (
        let vectorIndex = 0;
        vectorIndex < dataPlot.array.shape[1] / 2;
        vectorIndex++
      ) {
        let color: string;
        if (dataPlot.traceId !== minimapDataTraceId) {
          color = trace?.color || assignFreeColor(usedColors);
          usedColors.push(color);
        } else {
          color = 'rgba(0,0,0,0)';
        }

        // NOTE: use of 'line' (an echarts property) for 'step' (discrete) is correct.
        const plotType =
          trace?.plotType === 'step' ? 'line' : trace?.plotType ?? 'line';
        const stepType = trace?.plotType === 'step' ? 'end' : false;

        const baseName = trace?.displayName ?? dataPlot.traceId;
        const name = isVector ? `${baseName}[${vectorIndex}]` : baseName;

        const serieId =
          vectorIndex === 0
            ? dataPlot.traceId
            : `${dataPlot.traceId}:${vectorIndex}`;

        const markedPoints = serieIdToMarkedPoints[serieId] || [];

        const serie = {
          ...SERIES_BLOCK_CONF,
          type: plotType,
          id: serieId,
          large: true,
          // Legend display name, '' hides it from the legend
          name: dataPlot.traceId === minimapDataTraceId ? '' : name,
          color,
          step: stepType,
          // This is the magic where we can decode a numpy array formatted as:
          // time0, value0, time1, value1, ...
          encode: { x: 2 * vectorIndex, y: 2 * vectorIndex + 1 },
          datasetIndex: dataPlotId,
          markPoint: markedPoints
            ? {
                data: markedPoints.map((point) => ({
                  coord: [point.x, point.y],
                  value: point.y,
                  name: serieId,
                })),
                ...MARKPOINT_PLOT_CONF,
              }
            : undefined,
          emphasis: highlightingLegend
            ? { disabled: false, focus: 'series' }
            : { disabled: true },
        };

        series.push(serie);
      }
    }

    const options: echarts.EChartsCoreOption = {
      ...BASE_PLOT_CONF,
      ...(minimapDataTraceId ? PLOT_CONF_WITH_SLIDER_ZOOM : {}),
      xAxis: {
        ...X_AXIS,
        min: initialBounds.startX,
        max: initialBounds.endX,
      },
      yAxis: {
        ...Y_AXIS,
        min: minY !== undefined && isFinite(minY) ? minY : initialBounds.startY,
        max: maxY !== undefined && isFinite(maxY) ? maxY : initialBounds.endY,
      },
      dataZoom,
      dataset,
      series,
    };

    return options;
  }, [
    data,
    initialBounds,
    echartsScrollZoomOn,
    zoomBounds,
    idToTrace,
    serieIdToMarkedPoints,
    minimapDataTraceId,
    highlightingLegend,
    minY,
    maxY,
  ]);

  /**
   * Custom chart handler definitions go here, at the same level as custom chart components.
   * Pass to the ECharts wrapper as props, and attach to the ref there.
   */

  // Edit handlers. Use `canEdit` to gate passing as props.

  const prepTraceDrag = useCallback(
    (
      seriesId: string,
      displayName: string,
      color: string,
      startMouseX: number,
      startMouseY: number,
    ) => {
      // Remove the ':vectorIndex' suffix from the given seriesId so that it matches
      // an existing traceId. That traceId corresponds to the entire vector, meaning
      // the entire vector will be moved together.
      const traceId = seriesId.split(':')[0];
      dispatch(
        traceDragActions.setSourceCandidate({
          traceId,
          displayName,
          color,
          startMouseX,
          startMouseY,
        }),
      );
    },
    [dispatch],
  );

  const toggleSeriesMarkPoint = useCallback(
    // ECharts needs to be updated to a version that exports the ECElementEvent type.
    (e: any) => {
      // The OptionDataItem type is a catch-all union type used to represent all the
      // different shapes of data. The joys of using dynamically typed code in Typescript.
      if (
        e.componentType === 'series' &&
        e.dimensionNames &&
        e.seriesId &&
        e.encode &&
        e.data
      ) {
        const seriesId = e.seriesId;
        const point: MarkedPoint = {
          x: e.data[e.encode.x[0]],
          y: e.data[e.encode.y[0]],
        };
        dispatch(dataExplorerActions.addMarkedPoint({ seriesId, point }));
      }

      if (e.componentType === 'markPoint' && e.name && e.data) {
        const seriesId = e.name;
        const coord = e.data.coord;
        const point: MarkedPoint = { x: coord[0], y: coord[1] };
        dispatch(dataExplorerActions.removeMarkedPoint({ seriesId, point }));
      }
    },
    [dispatch],
  );

  // Display handlers

  const showContextMenu = useCallback((e: any) => {
    setLineMenuCoords(getMouseCoords(wrapperRef, e.event.event));
    setContextMenuTraceId(e.seriesId);
    e.event.event.preventDefault();
  }, []);

  const restoreChartRange = useCallback(
    (e: any) => fetchZoomedData(initialBounds.startX, initialBounds.endX),
    [fetchZoomedData, initialBounds],
  );

  const setZoom = useCallback(
    (e: any) => {
      // For InsideX zoom event (rectangle select): contains X,Y zoom data as batches
      // For SliderX we only have a single zoom data
      const xZoom: any = Object.keys(e).includes('batch') ? e.batch[0] : e;

      // Y zoom does not change the data granularity, only X does.
      if (
        xZoom.dataZoomId === ZoomID.X ||
        xZoom.dataZoomId === ZoomID.SliderX ||
        xZoom.dataZoomId.includes('xAxis')
      ) {
        // Either startValue (absolute value) or start (percentage) will be provided.
        if (xZoom.startValue !== undefined) {
          setPartialZoom({
            from: xZoom.startValue,
            to: xZoom.endValue,
          });
        } else {
          const initialRange = initialBounds.endX - initialBounds.startX;
          setPartialZoom({
            from: (initialRange * xZoom.start) / 100,
            to: (initialRange * xZoom.end) / 100,
          });
        }
      }
    },
    [initialBounds],
  );

  return (
    <ReactEChartsChartWrapper
      onWheelCapture={turnOnDataZoom}
      ref={wrapperRef}
      onClick={() => setContextMenuTraceId('')}>
      <TraceContextMenu
        top={lineMenuCoords.top}
        left={lineMenuCoords.left}
        traceId={contextMenuTraceId}
      />

      {echartsInstance && (
        <ChartMenu
          chart={echartsInstance}
          zoomData={fetchZoomedData}
          setYBounds={setYBounds}
          traceIds={traceIds}
          timeRanges={{
            from: zoomBounds?.startX,
            to: zoomBounds?.endX,
          }}
          initialTimeRanges={{
            from: initialBounds.startX,
            to: initialBounds.endX,
          }}
          minY={initialBounds.startY}
          maxY={initialBounds.endY}
        />
      )}

      {width > 0 && height > 0 && (
        <ReactEChartsChart
          options={chartOptions}
          width={width}
          height={height}
          toggleSeriesMarkPoint={canEdit ? toggleSeriesMarkPoint : undefined}
          prepTraceDrag={canEdit ? prepTraceDrag : undefined}
          resetScrollZoomTimeout={resetScrollZoomTimeout}
          showContextMenu={showContextMenu}
          restoreChartRange={restoreChartRange}
          setZoom={setZoom}
          setParentEChartsInstance={setEchartsInstance}
          onHighlightEvent={onHighlightEvent}
        />
      )}
    </ReactEChartsChartWrapper>
  );
};

export default Chart;
