import { MutableRefObject } from 'react';
import { SubmodelFullUI } from 'app/apiTransformers/convertGetSubmodel';
import { SubmodelInfoUI } from 'app/apiTransformers/convertGetSubmodelsListForModelParent';

export type SubmodelRef = {
  idToVersionIdToSubmodelFull: Record<string, Record<string, SubmodelFullUI>>;
  idToVersionIdToSubmodelInfo: Record<string, Record<string, SubmodelInfoUI>>;
  idToLatestTaggedVersionId: Record<string, string>;
};

interface SubmodelRefWrapper {
  refs: MutableRefObject<SubmodelRef>;
}

let submodelRefWrapper: SubmodelRefWrapper | null = null;

export function initSubmodelRef(refs: MutableRefObject<SubmodelRef>) {
  submodelRefWrapper = {
    refs,
  };
}

export function isSubmodelRefAvailable(): boolean {
  return !!submodelRefWrapper;
}

export function getSubmodelRef(): SubmodelRef {
  if (submodelRefWrapper) {
    return submodelRefWrapper.refs.current;
  }

  throw new Error('Tried to access SubmodelRef before it was initialized.');
}
