import type { Coordinate } from 'app/common_types/Coordinate';
import { PortSide } from 'app/common_types/PortTypes';
import { NodeInstance } from 'app/generated_types/SimulationModel';
import { renderConstants } from 'app/utils/renderConstants';
import { getVisualNodeHeight } from 'ui/modelRendererInternals/getVisualNodeHeight';
import { getVisualNodeWidth } from 'ui/modelRendererInternals/getVisualNodeWidth';

export const getCenteredPortYPos = (
  totalPortSlots: number,
  totalPorts: number,
  portOrderIndex: number,
) => {
  const centerSlot = Math.floor(totalPortSlots / 2);
  const startSlot = centerSlot - (totalPorts - 1);
  const portSlot = startSlot + 2 * portOrderIndex + 1;
  const portY = portSlot * renderConstants.GRID_UNIT_PXSIZE;

  return portY;
};

export const getTopAlignedPortYPos = (portOrderIndex: number) => {
  const portSlot = 2 * portOrderIndex + 2;
  const portY = portSlot * renderConstants.GRID_UNIT_PXSIZE;

  return portY;
};

export const getBottomAlignedPortYPos = (
  totalPortSlots: number,
  totalPorts: number,
  portOrderIndex: number,
) => {
  const startSlot = Math.max(0, totalPortSlots - totalPorts * 2);
  const portSlot = startSlot + 2 * portOrderIndex + 1;
  const portY = portSlot * renderConstants.GRID_UNIT_PXSIZE;

  return portY;
};

export const getSpacedPortYPos = (
  totalPortSlots: number,
  totalPorts: number,
  portOrderIndex: number,
) => {
  if (totalPorts === 1) {
    return getCenteredPortYPos(totalPortSlots, totalPorts, portOrderIndex);
  }

  const interval = Math.floor(totalPortSlots / totalPorts);

  let portY = 0;

  if (interval >= 1) {
    const remainingVisualSlots =
      totalPortSlots - 1 - interval * (totalPorts - 1);
    const portSlot =
      interval * portOrderIndex + 1 + Math.floor(remainingVisualSlots / 2);
    portY = portSlot * renderConstants.GRID_UNIT_PXSIZE;
  } else {
    const centerSlot = Math.floor(totalPortSlots / 2);
    const startSlot = centerSlot - (totalPorts - 1);
    const portSlot = startSlot + 2 * portOrderIndex;
    portY = portSlot * renderConstants.GRID_UNIT_PXSIZE;
  }

  return portY;
};

export function getPortNodeLocalCoordinate(
  nodeData: NodeInstance,
  portSide: PortSide,
  portData: { node: string; port: number },
): Coordinate {
  const { port } = portData;
  let portX;
  let portY;

  const portOrderIndex =
    portSide === PortSide.Input
      ? nodeData.uiprops.inport_order_index_map
        ? nodeData.uiprops.inport_order_index_map[port]
        : port
      : nodeData.uiprops.outport_order_index_map
      ? nodeData.uiprops.outport_order_index_map[port]
      : port;

  const nodeIsIOPort =
    nodeData.type === 'core.Inport' || nodeData.type === 'core.Outport';

  const currentNodeHeight = nodeIsIOPort
    ? renderConstants.BLOCK_MIN_HEIGHT
    : getVisualNodeHeight(nodeData);
  const currentNodeWidth = getVisualNodeWidth(nodeData);

  const leftX = 0;
  const rightX = currentNodeWidth;

  const portSlotsCount =
    Math.floor(currentNodeHeight / renderConstants.GRID_UNIT_PXSIZE) - 1;

  if (portSide === PortSide.Input) {
    portX = nodeData.uiprops.directionality === 'left' ? rightX : leftX;
  } else {
    portX = nodeData.uiprops.directionality === 'left' ? leftX : rightX;
  }

  const portsCount =
    portSide === PortSide.Input
      ? nodeData.inputs.length
      : nodeData.outputs.length;

  const portAlignMode = nodeData.uiprops.port_alignment || 'center';

  switch (portAlignMode) {
    case 'top':
      portY = getTopAlignedPortYPos(portOrderIndex);
      break;
    case 'bottom':
      portY = getBottomAlignedPortYPos(
        portSlotsCount,
        portsCount,
        portOrderIndex,
      );
      break;
    case 'center':
      portY = getCenteredPortYPos(portSlotsCount, portsCount, portOrderIndex);
      break;
    case 'spaced':
      portY = getSpacedPortYPos(portSlotsCount, portsCount, portOrderIndex);
      break;
  }

  return {
    x: portX,
    y: portY,
  };
}

function getPortWorldCoordinateWithMaybeNode(
  nodeData: NodeInstance | undefined,
  portSide: PortSide,
  portData?: { node: string; port: number },
): Coordinate | undefined {
  if (!portData) return undefined;
  if (!nodeData) return undefined;

  const { x: portX, y: portY } = getPortNodeLocalCoordinate(
    nodeData,
    portSide,
    portData,
  );

  return {
    x: portX + nodeData.uiprops.x,
    y: portY + nodeData.uiprops.y,
  };
}

export function getPortWorldCoordinate(
  nodeData: NodeInstance,
  defaultPortSide: PortSide,
  portData?: { node: string; port: number; port_side?: 'inputs' | 'outputs' },
): Coordinate | undefined {
  if (!portData) return undefined;

  let realPortSide = defaultPortSide;
  if (portData.port_side !== undefined) {
    realPortSide =
      portData.port_side === 'inputs' ? PortSide.Input : PortSide.Output;
  }

  if (realPortSide === PortSide.Input) {
    if (portData.port >= nodeData.inputs.length) {
      return undefined;
    }
  }

  if (realPortSide === PortSide.Output) {
    if (portData.port >= nodeData.outputs.length) {
      return undefined;
    }
  }

  return getPortWorldCoordinateWithMaybeNode(nodeData, realPortSide, portData);
}
