import styled from '@emotion/styled/macro';
import Input from 'ui/common/Input/Input';
import { SidebarScrollContainer } from 'ui/common/layout/appLayout';

export const DetailsContainer = styled(SidebarScrollContainer)`
  padding-top: ${({ theme }) => theme.spacing.normal};
  padding-left: ${({ theme }) => theme.spacing.normal};
  padding-right: ${({ theme }) => theme.spacing.normal};
  padding-bottom: 30px;
  overflow: hidden;
`;

export const DetailInputRowsSection = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => `0 ${theme.spacing.small}`};
  padding-bottom: ${({ theme }) => `${theme.spacing.large}`};
`;

export const DetailsSection = styled.div<{ vertical?: boolean }>`
  display: flex;
  flex-direction: ${({ vertical }) => (vertical ? 'column' : 'row')};
  ${({ vertical }) => (vertical ? '' : 'min-height: 24px;')}
  margin-bottom: ${(props) => props.theme.spacing.small};
  font-size: ${(props) => props.theme.typography.font.base.size};
`;

export const DetailsRow = styled.div<{ vertical?: boolean }>`
  display: flex;
  margin-bottom: ${(props) => props.theme.spacing.small};
  flex-grow: 1;
`;

export const DetailsDoubleRow = styled.div<{ noMargin?: boolean }>`
  display: flex;
  margin-right: ${({ noMargin }) => (noMargin ? '0' : '24')}px;

  > * {
    width: 50%;
    flex-shrink: 0;

    input {
      margin-right: 0;
    }
  }
`;

export const DetailsSingleRow = styled.div<{ noMargin?: boolean }>`
  display: flex;
  flex-direction: column;
  margin-right: ${({ noMargin }) => (noMargin ? '0' : '24')}px;
  > div {
    flex-shrink: 0;
  }
`;

export const DetailsLabelBase = styled.label<{
  disabled?: boolean;
}>`
  line-height: 1.5em;
  padding: 0 ${({ theme }) => theme.spacing.normal};
  color: ${({ disabled, theme }) =>
    disabled ? theme.colors.text.tertiary : theme.colors.text.primary};
  font-weight: 500;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const DetailsLabel = styled(DetailsLabelBase)<{
  shrink?: boolean;
  stretch?: boolean;
  disabled?: boolean;
}>`
  display: flex;
  align-items: center;
  min-height: 24px;

  ${({ shrink, stretch }) =>
    shrink
      ? `
    flex: 0 1 auto;
    max-width: 50%;
  `
      : `
    ${stretch ? 'flex-grow: 1; max-width: 100%;' : 'max-width: 50%;'}
  `};
`;

export const DetailsInput = styled(Input)<{
  grow?: boolean;
}>`
  ${({ grow }) =>
    grow
      ? `
    flex-grow: 1;
    min-width: 10px;
  `
      : `
    flex-shrink: 0;
    width: 50%;
  `};
`;

export const DetailsDarkGroup = styled.div`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.grey[5]};
  padding: ${({ theme }) => theme.spacing.normal};
  border-radius: 4px;

  > :last-child {
    margin-bottom: 0;
  }
`;

export const DetailsDarkGroupLabel = styled.label`
  ${({ theme }) => `
  margin: 0 0 ${theme.spacing.small} ${theme.spacing.small};
  font-size: ${theme.typography.font.extraSmall.size};
  line-height: ${theme.typography.font.extraSmall.lineHeight};
  font-weight: ${theme.typography.font.extraSmall.weight};
  color: ${theme.colors.text.secondary};
`}
`;
