import { useAdminApis } from 'app/api/useAdminApis';
import { useGetUserOptionsQuery } from 'app/apiGenerated/generatedApi';
import React, { FormEventHandler } from 'react';
import Button from 'ui/common/Button/Button';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import Input from 'ui/common/Input/Input';
import Label from 'ui/common/Label';
import {
  ActionButtonContainer,
  ModalInputGroup,
  SmallFormContainer,
} from 'ui/common/Modal/Modal';
import { useModal } from 'ui/common/Modal/useModal';

const NewGlobalUserOptionModal: React.FC = ({}) => {
  const { closeModal } = useModal();
  const { userOptions, createUserOption } = useAdminApis();
  const [value, setValue] = React.useState('');
  const [name, setName] = React.useState('');
  const [description, setDescription] = React.useState('');
  const { refetch: refetchUserOptions } = useGetUserOptionsQuery();

  if (!userOptions) return null;

  const submit: FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    await createUserOption(name, value, description);
    refetchUserOptions();
    closeModal();
  };
  return (
    <SmallFormContainer onSubmit={submit}>
      <ModalInputGroup>
        <Label>name</Label>
        <Input value={name} hasBorder onChangeText={(v) => setName(v)} />
      </ModalInputGroup>
      <ModalInputGroup>
        <Label>description</Label>
        <Input
          value={description}
          hasBorder
          onChangeText={(v) => setDescription(v)}
        />
      </ModalInputGroup>
      <ModalInputGroup>
        <Label>value</Label>
        <Input value={value} hasBorder onChangeText={(v) => setValue(v)} />
      </ModalInputGroup>
      <ActionButtonContainer>
        <Button type="submit" variant={ButtonVariants.LargePrimary}>
          Create
        </Button>
      </ActionButtonContainer>
    </SmallFormContainer>
  );
};

export default NewGlobalUserOptionModal;
