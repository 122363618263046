import { useCustomBlockBuilderFunctions } from './CustomBlockBuilderFunctions';
import { useMainFunctions } from './MainFunctions';
import { useModelBuilderFunctions } from './ModelBuilderAgentFunctions';

export const useFunctions = () => {
  const mainFunctions = useMainFunctions();
  const modelBuilderFunctions = useModelBuilderFunctions();
  const customBlockBuilderFunctions = useCustomBlockBuilderFunctions();

  return {
    ...mainFunctions,
    ...modelBuilderFunctions,
    ...customBlockBuilderFunctions,
  };
};
