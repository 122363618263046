import { useDataExplorerData } from 'app/api/useDataExplorerData';
import React from 'react';

interface Props {
  modelId: string;
  simulationId: string;
  traceId: string;
  tracePath: string;
  fromTime?: number;
  toTime?: number;
}

export const DataExplorerTraceLoader: React.FC<Props> = ({
  modelId,
  simulationId,
  traceId,
  tracePath,
  fromTime,
  toTime,
}) => {
  useDataExplorerData({
    modelId,
    simulationId,
    traceId,
    tracePath,
    fromTime,
    toTime,
  });

  return null;
};
