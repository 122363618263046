/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Ratelimiter: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M8.88515 5.25L8.78515 5.75H5.25V23.4257L4.88515 25.25H1.5C1.08579 25.25 0.75 25.5858 0.75 26C0.75 26.4142 1.08579 26.75 1.5 26.75H6.11485L10.1149 6.75H11.8851L12.25 8.57426V26.25H15.7851L15.8851 26.75H20.1149L24.1149 6.75H25.8851L26.25 8.57426V26.25H29.7928C29.9148 26.5966 30.2764 26.8096 30.6471 26.7354C31.0533 26.6542 31.3167 26.2591 31.2354 25.8529L27.1149 5.25H22.8851L22.7851 5.75H19.25V23.4257L18.8851 25.25H17.1149L13.1149 5.25H8.88515ZM19.75 20.9257L22.6851 6.25H19.75V20.9257ZM15.6851 25.75L12.75 11.0743V25.75H15.6851ZM26.75 11.0743V25.75H29.6851L26.75 11.0743ZM8.68515 6.25H5.75V20.9257L8.68515 6.25Z"
      fill={props.fill}
    />
  </svg>
);

Ratelimiter.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Ratelimiter;
