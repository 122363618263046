// !!!! -- DO NOT MANUALLY EDIT -- !!!!
// This file was generated by something in scripts/generateDefaultBlockData.js
// If you want to change the contents of this file, it should be automated
//
// (You can edit this file locally, but it may not be checked into the codebase -
// PRs with a manual edit of this file are forbidden from being merged.)

import { computationBlockDefaults } from 'app/generated_blocks/computationBlockDefaults';
import type { ComputationBlockClass } from 'app/generated_types/ComputationBlockClass';
import deepmerge from 'deepmerge';

export const Arithmetic: ComputationBlockClass = deepmerge(
  computationBlockDefaults,
  {
    "base": {
      "name": "Arithmetic",
      "namespace": "core",
      "description": "Performs addition, subtraction, multiplication, and division on the input.",
      "feature_level": "dev",
      "development_notes": "",
      "keywords": [
        "sum",
        "subtract",
        "multiply",
        "divide"
      ],
      "help_url": ""
    },
    "modes": {
      "time": "agnostic"
    },
    "ports": {
      "inputs": {
        "dynamic": {
          "min_count": 2,
          "max_count": -1,
          "default_count": 2
        }
      },
      "outputs": {
        "static": [
          {
            "name": "out_0"
          }
        ]
      }
    },
    "parameter_definitions": [
      {
        "name": "operators",
        "description": "A string containing the signs to apply to each input port, like '++' or '+-'. Must contain exactly as many symbols as input ports. For each operator, an input port named `in_<n>` will be automatically generated (where n is a number starting with 0).",
        "data_type": "string",
        "default_value": "++"
      }
    ]
  }
);
