import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { useAdminApis } from 'app/api/useAdminApis';
import { UserOption } from 'app/apiGenerated/generatedApiTypes';
import { useAppSelector } from 'app/hooks';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Cell, Column } from 'react-table';
import Button from 'ui/common/Button/Button';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import { BackArrow, Plus, Remove } from 'ui/common/Icons/Standard';
import Input from 'ui/common/Input/Input';
import { useModal } from 'ui/common/Modal/useModal';
import Table from 'ui/common/Table/Table';
import * as Headings from 'ui/common/typography/Typography';
import { getStringSorter } from 'util/getStringSorter';
import NewGlobalUserOptionModal from './NewGlobalUserOptionModal';

const Head = styled.header(({ theme }) => ({
  display: 'flex',
  width: ' 100%',
  marginBottom: theme.spacing.xlarge,
  padding: 10,
  columnGap: 8,
}));

const Title = styled(Headings.H2)(({ theme }) => ({
  width: '100%',
}));

const UserOptionsWrapper = styled.div(({ theme }) => ({
  width: `calc(100% - ${theme.spacing.xxlarge} - ${theme.spacing.xxlarge})`,
  height: `calc(100% - ${theme.spacing.xxlarge} - ${theme.spacing.xxlarge})`,
  margin: theme.spacing.xlarge,
  padding: theme.spacing.large,
  background: theme.colors.grey[5],
  borderRadius: theme.spacing.small,
  overflowX: 'auto',
  overflowY: 'auto',
}));

const UserOptionActions = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '24px',
});

const ActionSection = styled.div(({ theme }) => ({
  marginBottom: theme.spacing.large,
  alignItems: 'center',
}));

const TableInputGroup = styled.div`
  width: 450px;
  height: 100%;
`;

interface UserOptionsTableData extends Record<string, string | boolean> {
  optionUuid: string;
  defaultValue: string;
  name: string;
  description: string;
  createdAt: string;
  updatedAt: string;
}

const ActionCell = ({ cell }: { cell: Cell<UserOptionsTableData> }) => {
  const theme = useTheme();
  const { deleteUserOption } = useAdminApis();
  const { optionUuid } = cell.row.original;
  return (
    <UserOptionActions>
      <Button
        variant={ButtonVariants.SmallTertiary}
        title="Delete user option"
        Icon={Remove}
        tint={theme.colors.ui.error}
        onClick={() => deleteUserOption(optionUuid)}
      />
    </UserOptionActions>
  );
};

const ValueCell = ({
  cell,
}: {
  cell: Cell<UserOptionsTableData, string | boolean>;
}) => {
  const { updateUserOption } = useAdminApis();
  const { optionUuid, name, description } = cell.row.original;
  return (
    <Input
      value={cell.value as string}
      onSubmitValue={(v) => {
        updateUserOption(optionUuid, name, v, description);
      }}
    />
  );
};

const DescriptionCell = ({
  cell,
}: {
  cell: Cell<UserOptionsTableData, string | boolean>;
}) => {
  const { updateUserOption } = useAdminApis();
  const { optionUuid, name, defaultValue } = cell.row.original;
  return (
    <TableInputGroup>
      <Input
        value={cell.value as string}
        onSubmitValue={(v) => {
          updateUserOption(optionUuid, name, defaultValue, v);
        }}
      />
    </TableInputGroup>
  );
};

const GlobalUserOptions: React.FC = () => {
  const { userOptions } = useAdminApis();
  const { triggerModal } = useModal();
  const navigate = useNavigate();
  const userId = useAppSelector((state) => state.user.userId);

  if (!userOptions || !userOptions.user_options) return <span>Loading</span>;
  const sortedOptions = userOptions.user_options
    .filter((userOption) => !!userOption)
    .sort(getStringSorter('name'));
  const tableData: UserOptionsTableData[] = sortedOptions.map(
    (userOption: UserOption) => ({
      key: userOption.uuid || '',
      name: userOption.name || '',
      description: userOption.description || '',
      defaultValue: userOption.value || '',
      createdAt: userOption.created_at,
      updatedAt: userOption.updated_at,
      optionUuid: userOption.uuid || '',
    }),
  );

  const tableColumns: Column<UserOptionsTableData>[] = [
    {
      id: 'name',
      Header: 'Name',
      accessor: 'name',
    },
    {
      id: 'description',
      Header: 'Description',
      accessor: 'description',
      minWidth: 250,
      Cell: DescriptionCell,
    },
    {
      id: 'value',
      Header: 'Default value',
      accessor: 'defaultValue',
      Cell: ValueCell,
    },
    {
      id: 'action',
      Header: 'Actions',
      accessor: 'status',
      maxWidth: 12,
      Cell: ActionCell,
    },
  ];

  const onRowDoubleClick = (row: UserOptionsTableData) => {
    navigate(`/admin/user_options/${row.optionUuid}`);
  };

  return (
    <UserOptionsWrapper>
      <ActionSection>
        <Head>
          <Title>Global User Options</Title>
          <Button
            variant={ButtonVariants.LargeSecondary}
            Icon={BackArrow}
            onClick={() => navigate('/admin')}>
            Admin portal
          </Button>
          <Button
            variant={ButtonVariants.LargePrimary}
            onClick={() => navigate(`/admin/users/${userId}`)}>
            My option overrides
          </Button>
          <Button
            variant={ButtonVariants.LargePrimary}
            Icon={Plus}
            onClick={() =>
              triggerModal(
                <NewGlobalUserOptionModal />,
                'Create new user option',
              )
            }>
            Create user option
          </Button>
        </Head>
      </ActionSection>
      <Table
        data={tableData}
        columns={tableColumns}
        onRowDoubleClick={onRowDoubleClick}
      />
    </UserOptionsWrapper>
  );
};

export default GlobalUserOptions;
