/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  className?: string;
}

const Sumofelements: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.5 23V21.708L15.71 16.6016L11.5 11.4951V10.2031H20.0781V13.0332H18.5928L18.4609 11.5654H13.6533L13.627 11.6094L17.5469 16.5049V16.7158L13.627 21.5938L13.6533 21.6377H18.9004L19.0322 20.1699H20.5088V23H11.5Z"
      fill={props.fill}
    />
  </svg>
);

Sumofelements.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  className: '',
};

export default Sumofelements;
