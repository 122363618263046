/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const AcausalRotationalIdealwheel: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M15.25 18.9055V25.4708C13.1904 25.31 11.3152 24.4923 9.83374 23.2271L14.4761 18.5847C14.7148 18.7257 14.9748 18.8346 15.25 18.9055ZM13.4154 17.5241L8.77306 22.1664C7.50777 20.6849 6.69003 18.8097 6.52917 16.75H13.0945C13.1654 17.0253 13.2743 17.2853 13.4154 17.5241ZM13.0945 15.25C13.1654 14.9748 13.2743 14.7148 13.4153 14.4761L8.77292 9.83374C7.50771 11.3152 6.69003 13.1904 6.52917 15.25H13.0945ZM14.4759 13.4154C14.7147 13.2743 14.9747 13.1654 15.25 13.0945V6.52917C13.1903 6.69003 11.3151 7.50777 9.83357 8.77306L14.4759 13.4154ZM16.75 13.0945C17.0253 13.1654 17.2853 13.2743 17.5241 13.4154L22.1664 8.77306C20.6849 7.50777 18.8097 6.69003 16.75 6.52917V13.0945ZM18.5847 14.4761C18.7257 14.7148 18.8346 14.9748 18.9055 15.25H25.4708C25.31 13.1904 24.4923 11.3152 23.2271 9.83374L18.5847 14.4761ZM18.9055 16.75C18.8346 17.0253 18.7257 17.2853 18.5846 17.5241L23.2269 22.1664C24.4922 20.6849 25.31 18.8097 25.4708 16.75H18.9055ZM17.5239 18.5847L22.1663 23.2271C20.6848 24.4923 18.8096 25.31 16.75 25.4708V18.9055C17.0252 18.8346 17.2852 18.7257 17.5239 18.5847ZM28 16C28 22.6274 22.6274 28 16 28C9.37258 28 4 22.6274 4 16C4 9.37258 9.37258 4 16 4C22.6274 4 28 9.37258 28 16ZM17.5 16C17.5 16.8284 16.8284 17.5 16 17.5C15.1716 17.5 14.5 16.8284 14.5 16C14.5 15.1716 15.1716 14.5 16 14.5C16.8284 14.5 17.5 15.1716 17.5 16Z"
      fill={props.fill}
    />
  </svg>
);

AcausalRotationalIdealwheel.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default AcausalRotationalIdealwheel;
