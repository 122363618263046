import { t } from '@lingui/macro';
import { useAdminApis } from 'app/api/useAdminApis';
import { useProjects } from 'app/api/useProjects';
import { Project } from 'app/apiTransformers/convertAPIProjectToProject';
import React from 'react';
import { isAdminUser } from 'ui/auth/utils';
import TooltipButton from 'ui/common/Button/TooltipButton';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import Publish from 'ui/common/Icons/Standard/Publish';
import Unpublish from 'ui/common/Icons/Standard/Unpublish';
import { ProjectFilter } from 'util/projectFilterUtils';

type Props = {
  projectFilter: ProjectFilter;
  selectionState: Record<string, boolean>;
  numSelected: number;
};

enum PublishedState {
  None = 'none',
  Published = 'published',
  Unpublished = 'unpublished',
  Mixed = 'mixed',
}

export const PublishProjectButton: React.FC<Props> = ({
  projectFilter,
  selectionState,
  numSelected,
}: Props) => {
  const { createGlobalProject, deleteGlobalProject } = useAdminApis();
  const { projects } = useProjects(projectFilter);

  const selectedProjects = React.useMemo(() => {
    if (!projects) return;
    return projects.filter((project: Project) => selectionState[project.uuid]);
  }, [projects, selectionState]);

  const publishedState = React.useMemo(() => {
    if (!numSelected || !selectedProjects?.length) {
      return PublishedState.None;
    }

    let firstProjectPublished = selectedProjects[0].isPublic;
    for (let i = 1; i < numSelected; i++) {
      if (firstProjectPublished !== selectedProjects[i].isPublic) {
        return PublishedState.Mixed;
      }
    }

    return firstProjectPublished
      ? PublishedState.Published
      : PublishedState.Unpublished;
  }, [selectedProjects, numSelected]);

  const onTogglePublishProject = async () => {
    selectedProjects?.forEach((p) => {
      if (p.isPublic) {
        if (confirm('Are you sure you want to unpublish this project?')) {
          deleteGlobalProject(p.uuid, 'Default');
        }
      } else if (confirm('Are you sure you want to publish this project?')) {
        createGlobalProject(p.uuid, 'Default');
      }
    });
  };

  const tooltipPublish = t({
    id: 'dashboard.projects.publishProjectButton.Label',
    message: 'Publish Project',
  });
  const tooltipUnpublish = t({
    id: 'dashboard.projects.unpublishProjectButton.Label',
    message: 'Unpublish Project',
  });

  return (
    <TooltipButton
      tooltip={
        publishedState === PublishedState.Unpublished
          ? tooltipPublish
          : tooltipUnpublish
      }
      Icon={publishedState === PublishedState.Unpublished ? Publish : Unpublish}
      largeIcon
      variant={ButtonVariants.LargeSecondary}
      onClick={onTogglePublishProject}
      disabled={
        !isAdminUser() ||
        numSelected !== 1 ||
        publishedState === PublishedState.Mixed
      }
      testId="publish-project-button"
    />
  );
};
