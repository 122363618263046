/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  className?: string;
}

const Equinox: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M23.2 13.6V11.2H25.6V13.6H23.2ZM23.2 16V13.6H20.8V16H23.2ZM23.2 11.2V8.8H20.8V11.2H23.2ZM20.8 5.008V6.4H23.2C22.444 5.848 21.652 5.38 20.8 5.008ZM23.2 6.4V8.8H25.6C24.904 7.9 24.1 7.096 23.2 6.4ZM25.6 8.8V11.2H26.992C26.62 10.348 26.152 9.556 25.6 8.8ZM28 16C28 15.184 27.916 14.38 27.76 13.6H25.6V16H28ZM20.8 8.8V6.4H18.4V8.8H20.8ZM20.8 20.8H23.2V18.4H20.8V20.8ZM23.2 23.2H25.6V20.8H23.2V23.2ZM20.8 25.6H23.2V23.2H20.8V25.6ZM18.4 27.76C19.24 27.592 20.032 27.328 20.8 26.992V25.6H18.4V27.76ZM23.2 18.4H25.6V16H23.2V18.4ZM20.8 11.2H18.4V13.6H20.8V11.2ZM25.6 20.8H26.992C27.328 20.032 27.592 19.24 27.76 18.4H25.6V20.8ZM20.8 16H18.4V18.4H20.8V16ZM16 23.2V20.8H18.4V18.4H16V16H18.4V13.6H16V11.2H18.4V8.8H16V6.4H18.4V4.24C17.62 4.084 16.828 4 16 4C9.4 4 4 9.4 4 16C4 22.6 9.4 28 16 28V25.6H18.4V23.2H16ZM18.4 23.2H20.8V20.8H18.4V23.2Z"
      fill={props.fill}
    />
  </svg>
);

Equinox.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  className: '',
};

export default Equinox;
