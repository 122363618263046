import type { Coordinate } from 'app/common_types/Coordinate';

import { FPS60_MILLIS } from 'app/utils/GeneralConstants';
import { pythagoreanDistance } from 'util/pythagoreanDistance';

import { ClickStates, MouseActions } from 'app/common_types/MouseTypes';
import { rcMenuActions } from 'app/slices/rcMenuSlice';
import { renderConstants } from 'app/utils/renderConstants';
import { mouseInputClick } from './clickHandlers/mouseInputClick';
import { mouseInputClickHold } from './clickHandlers/mouseInputClickHold';
import { mouseInputDoubleClick } from './clickHandlers/mouseInputDoubleClick';
import { mouseInputMiddleClick } from './clickHandlers/mouseInputMiddleClick';
import { mouseInputMiddleClickHold } from './clickHandlers/mouseInputMiddleClickHold';
import { mouseInputRightClick } from './clickHandlers/mouseInputRightClick';
import { keysPressed } from './keyEvents';
import { rendererState, requestFrameRender } from './modelRenderer';
import { multiSelection } from './multiSelection';
import { transitionMouseState } from './transitionMouseState';
import { findNextZoomLevel } from './zoom';

// Distance below which down+up are only clicks.
const MIN_DISTANCE_FOR_DRAG = renderConstants.GRID_UNIT_PXSIZE / 2;

let canvasBounds: { x: number; y: number };
const getCanvasBounds = (): { x: number; y: number } => {
  if (canvasBounds) return canvasBounds;

  if (!rendererState || !rendererState.refs.current.canvas) {
    return { x: 0, y: 0 };
  }

  if (rendererState.refs.current.canvas) {
    canvasBounds = rendererState.refs.current.canvas.getBoundingClientRect();
  }

  return canvasBounds;
};

let mousemoveRollingTimeout: number | null = null;
let mousemoveFinalTimeout: number | null = null;
export const mouseMove = (event: MouseEvent): void => {
  if (!rendererState) return;
  requestFrameRender();

  const canvas = rendererState.refs.current.canvas;
  if (!canvas) return;

  const { x: canvasOffsetX, y: canvasOffsetY } = getCanvasBounds();
  const canvasScreenX = event.x - canvasOffsetX;
  const canvasScreenY = event.y - canvasOffsetY;

  // We care whether we're inside the canvas only if the mouse is held down.
  // If inside, all good. If outside, we could be hovering over another UI
  // element, or even outside the window. We'll only get mouse events when
  // the mouse is outside the window if the mouse is held down. In this case,
  // we care about them. If we're hovering over another UI element, we
  // should ignore move events unless the mouse is down.
  // const isStillInsideCanvas =
  //   canvasScreenX >= 0 &&
  //   canvasScreenX < canvas.offsetWidth &&
  //   canvasScreenY >= 0 &&
  //   canvasScreenY < canvas.offsetHeight;

  rendererState.cursorOverCanvas = event.target === canvas;
  rendererState.screenCursorZoomed.x = canvasScreenX / rendererState.zoom;
  rendererState.screenCursorZoomed.y = canvasScreenY / rendererState.zoom;
  rendererState.screenCursorRaw.x = canvasScreenX;
  rendererState.screenCursorRaw.y = canvasScreenY;

  if (
    rendererState.clickState.state === ClickStates.ClickHeld &&
    (rendererState.mouseState.state === MouseActions.Idle ||
      rendererState.mouseState.state === MouseActions.ReadyToDefineAnnotation)
  ) {
    const movedDistance = pythagoreanDistance(
      rendererState.clickState.rawCoord,
      rendererState.screenCursorRaw,
    );
    if (movedDistance >= MIN_DISTANCE_FOR_DRAG) {
      // FIXME: keysPressed was not updated in this event handler
      mouseInputClickHold(
        rendererState,
        rendererState.clickState.zoomedCoord,
        rendererState.clickState.rawCoord,
        keysPressed,
      );
    }
  }

  // FIXME: The problem is that selection (single and multiple) causes too many
  // React app re-renders.
  //
  // This "interlocking" timeout pattern is to allow us to throttle certain actions
  // to a lower framerate if they are performance-intensive.
  // This is because the mouse events fire faster than our render loop.
  // We can avoid smashing the CPU with a bunch of stuff
  // that would otherwise cause frame drops for everything
  // due to slowing the app down.
  if (!mousemoveRollingTimeout) {
    mousemoveRollingTimeout = window.setTimeout(
      () => {
        mousemoveRollingTimeout = null;
        multiSelection();
      },
      // throttling the FPS to 30 for selection since it's a bit expensive right now
      // TODO: make multiSelection() more efficient (but don't remove this throttle)
      FPS60_MILLIS * 2,
    );
  } else {
    if (mousemoveFinalTimeout) clearTimeout(mousemoveFinalTimeout);
    mousemoveFinalTimeout = window.setTimeout(
      () => {
        multiSelection();
      },
      // totally ensure that this never calls until the end
      // by making it over 2x as slow
      FPS60_MILLIS * 4.5,
    );
  }
};

export const mouseDown = (event: MouseEvent): void => {
  requestFrameRender();

  // FIXME: see note about keysPressed - not reliable (funnily enough, the
  // below code is actually correct)
  // the alt key seems to be problematic in getting stuck,
  // so we'll take an aggressive approach on it for now.
  if (!event.altKey) {
    keysPressed.Alt = false;
  }

  if (!rendererState) return;

  rendererState.dispatch(rcMenuActions.close());

  const canvas = rendererState.refs.current.canvas;
  if (!canvas) return;

  const { x: canvasOffsetX, y: canvasOffsetY } = getCanvasBounds();
  const canvasScreenX = event.x - canvasOffsetX;
  const canvasScreenY = event.y - canvasOffsetY;

  if (event.target === canvas) {
    document.getSelection()?.removeAllRanges();

    const zoomedClickCoord = {
      x: canvasScreenX / rendererState.zoom,
      y: canvasScreenY / rendererState.zoom,
    };
    const rawClickCoord = {
      x: canvasScreenX,
      y: canvasScreenY,
    };

    // Start moving camera on middle button down
    if (event.button === 1) {
      mouseInputMiddleClickHold(rendererState, zoomedClickCoord);
      return;
    }

    // Context menu shows up immediately on mouseDown (not mouseUp)
    if (event.button === 2) {
      mouseInputRightClick(rendererState, zoomedClickCoord, rawClickCoord);
      event.preventDefault();
      return;
    }

    rendererState.clickState = {
      state: ClickStates.ClickHeld,
      zoomedCoord: zoomedClickCoord,
      rawCoord: rawClickCoord,
    };
  }
};

export const mouseUp = (event: MouseEvent): void => {
  if (!rendererState) return;
  requestFrameRender();

  const canvas = rendererState.refs.current.canvas;
  if (!canvas) return;

  // If not on canvas, release all mouse actions
  if (event.target !== canvas) {
    rendererState.clickState = { state: ClickStates.Idle };
    transitionMouseState(rendererState, { state: MouseActions.Idle });
    return;
  }

  // Right click is handled only in mouseDown
  if (event.button === 2) return;

  // Middle button: click simply releases the camera move
  if (event.button === 1) {
    mouseInputMiddleClick(rendererState);
    return;
  }

  if (event.button === 0 && event.target === canvas) {
    const zoomedClickCoord: Coordinate = {
      ...rendererState.screenCursorZoomed,
    };

    rendererState.clickState = {
      state: ClickStates.Idle,
    };

    rendererState.clickState = {
      state: ClickStates.Click,
      ...zoomedClickCoord,
    };

    mouseInputClick(rendererState, zoomedClickCoord, keysPressed);
  }
};

export const contextMenuEvent = (event: MouseEvent): void => {
  if (!rendererState) return;
  requestFrameRender();

  const canvas = rendererState.refs.current.canvas;
  if (!canvas) return;

  if (event.target === canvas) {
    event.preventDefault();
  }
};

export const mouseDoubleClick = (event: MouseEvent): void => {
  if (!rendererState) return;
  requestFrameRender();

  const canvas = rendererState.refs.current.canvas;
  if (!canvas) return;
  if (event.target !== canvas) return;

  const { x: canvasOffsetX, y: canvasOffsetY } = getCanvasBounds();
  const canvasScreenX = event.x - canvasOffsetX;
  const canvasScreenY = event.y - canvasOffsetY;

  document.getSelection()?.removeAllRanges();

  const zoomedClickCoord = {
    x: canvasScreenX / rendererState.zoom,
    y: canvasScreenY / rendererState.zoom,
  };

  mouseInputDoubleClick(rendererState, zoomedClickCoord);
};

export const wheel = (event: WheelEvent) => {
  requestFrameRender();
  event.preventDefault();

  if (!rendererState) return;
  if (rendererState.refs.current.rcMenuState.open) return;

  let rawDeltaX = 0;
  let rawDeltaY = 0;
  let rawZoomDelta = 0;

  // macOS will modify Shift+VScroll into deltaX (instead of deltaY),
  // but MOS breaks this behavior, so we still explicitly check for
  // modifiers even on mac.
  if (event.ctrlKey || event.metaKey) {
    rawZoomDelta = -event.deltaY;
  } else if (event.shiftKey && event.deltaY !== 0) {
    rawDeltaX = event.deltaY;
  } else {
    rawDeltaX = event.deltaX;
    rawDeltaY = event.deltaY;
  }

  if (rawZoomDelta !== 0) {
    const nextZoom = findNextZoomLevel(rendererState.zoom, rawZoomDelta);

    const previousCursorX = event.offsetX / rendererState.zoom;
    const previousCursorY = event.offsetY / rendererState.zoom;

    const newCursorX = event.offsetX / nextZoom;
    const newCursorY = event.offsetY / nextZoom;

    const cameraAdjustX = newCursorX - previousCursorX;
    const cameraAdjustY = newCursorY - previousCursorY;

    rendererState.camera.x += cameraAdjustX;
    rendererState.camera.y += cameraAdjustY;

    rendererState.screenCursorZoomed.x = newCursorX;
    rendererState.screenCursorZoomed.y = newCursorY;

    rendererState.zoom = nextZoom;
  } else {
    rendererState.camera.x -= rawDeltaX / rendererState.zoom;
    rendererState.camera.y -= rawDeltaY / rendererState.zoom;
  }

  rendererState.setTransform({
    x: rendererState.camera.x,
    y: rendererState.camera.y,
    zoom: rendererState.zoom,
  });
};
