/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Replicator: React.FC<Props> = (props) => (
  <svg viewBox="0 0 16 16" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M6.49999 8.99999H7.33333V10.1667C7.33333 10.3507 7.48256 10.5 7.66666 10.5H10.5C10.6841 10.5 10.8333 10.3507 10.8333 10.1667V7.83332C10.8333 7.64922 10.6841 7.49999 10.5 7.49999H9.16666V6.66665C9.16666 6.48256 9.01742 6.33332 8.83333 6.33332H7.66666V5.66665C7.66666 5.48256 7.51742 5.33332 7.33333 5.33332H5.49999C5.3159 5.33332 5.16666 5.48256 5.16666 5.66665V7.16665C5.16666 7.35075 5.3159 7.49999 5.49999 7.49999H6.16666V8.66665C6.16666 8.85075 6.3159 8.99999 6.49999 8.99999ZM7.99999 8.16665H10.1667V9.83332H7.99999V8.16665ZM7.33333 7.83332C7.33333 7.64922 7.48256 7.49999 7.66666 7.49999H8.49999V6.99999H6.83333V8.33332H7.33333V7.83332ZM6.16666 6.83332V6.66665C6.16666 6.48256 6.3159 6.33332 6.49999 6.33332H6.99999V5.99999H5.83333V6.83332H6.16666Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M3.33332 2.66666C2.96513 2.66666 2.66666 2.96513 2.66666 3.33332V12.6667C2.66666 13.0348 2.96513 13.3333 3.33332 13.3333H12.6667C13.0348 13.3333 13.3333 13.0348 13.3333 12.6667V3.33332C13.3333 2.96513 13.0348 2.66666 12.6667 2.66666H3.33332ZM3.66666 3.66666V12.3333H12.3333V3.66666H3.66666Z"
      fill={props.fill}
    />
  </svg>
);

Replicator.defaultProps = {
  width: '16',
  height: '16',
  viewBox: '0 0 16 16',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Replicator;
