import { useTheme } from '@emotion/react';
import { t } from '@lingui/macro';
import { VersionSource } from 'app/apiTransformers/convertGetSnapshotReadAll';
import React from 'react';
import { BookmarkFilled, Edit, History, Play } from 'ui/common/Icons/Standard';
import PortalTextTooltip from 'ui/common/Tooltip/PortalTextTooltip';
import { TooltipPlacement } from 'ui/common/Tooltip/tooltipTypes';
import { usePortalTooltip } from 'ui/common/Tooltip/usePortalTooltip';
import { VersionSectionButton } from './DiagramVersionItemParts';

function getSourceTooltip(source: VersionSource): string {
  switch (source) {
    case 'edit':
      return t({
        id: 'diagramVersionHistory.versionSource.edit.tooltip',
        message: 'Version that represents a burst of edits from a single user',
      });
    case 'restore':
      return t({
        id: 'diagramVersionHistory.versionSource.restore.tooltip',
        message:
          'Version that represents the last edit before a restore operation',
      });
    case 'run':
      return t({
        id: 'diagramVersionHistory.versionSource.run.tooltip',
        message:
          'Version that represents the last edit before a simulation run',
      });
    case 'bookmark':
      return t({
        id: 'diagramVersionHistory.versionSource.bookmark.tooltip',
        message: 'Version that represents a manual save point',
      });
  }
}

interface Props {
  source: VersionSource;
}

const DiagramVersionIcon: React.FC<Props> = ({ source }) => {
  const theme = useTheme();
  const { triggerElRef, isTooltipOpen } = usePortalTooltip<HTMLDivElement>();

  const tooltip = getSourceTooltip(source);

  return (
    <>
      <VersionSectionButton ref={triggerElRef}>
        {source === 'edit' && <Edit fill={theme.colors.grey[30]} />}
        {source === 'restore' && <History fill={theme.colors.grey[30]} />}
        {source === 'run' && <Play fill={theme.colors.grey[30]} />}
        {source === 'bookmark' && (
          <BookmarkFilled fill={theme.colors.grey[30]} />
        )}
      </VersionSectionButton>
      {isTooltipOpen && triggerElRef.current && (
        <PortalTextTooltip
          triggerEl={triggerElRef.current}
          contentText={tooltip}
          placement={TooltipPlacement.BOTTOM_CENTER}
        />
      )}
    </>
  );
};

export default DiagramVersionIcon;
