import { SubmodelInfoUI } from 'app/apiTransformers/convertGetSubmodelsListForModelParent';
import blockTypeNameToInstanceDefaults from 'app/blockClassNameToInstanceDefaults';
import { MouseActions } from 'app/common_types/MouseTypes';
import { SubmodelInstance } from 'app/generated_types/SimulationModel';
import { updateSubmodelInstanceForReferenceChanges } from 'app/utils/modelSubmodelFixupUtils';
import * as NVG from 'nanovg-js';
import { drawNode } from './drawNode';
import { RendererState } from './modelRenderer';

function renderDndBlocks(rendererState: RendererState, nvg: NVG.Context) {
  if (rendererState.mouseState.state !== MouseActions.DragDropLibraryBlock)
    return;

  const blockData = blockTypeNameToInstanceDefaults(
    rendererState.mouseState.blockClassName,
    undefined,
    rendererState.mouseState.referenceSubmodel?.id,
  );

  const referenceSubmodel = rendererState.mouseState
    .referenceSubmodel as SubmodelInfoUI;
  if (referenceSubmodel && referenceSubmodel.portDefinitionsInputs) {
    updateSubmodelInstanceForReferenceChanges(
      blockData as SubmodelInstance,
      referenceSubmodel,
    );
  }

  drawNode(
    nvg,
    rendererState,
    blockData,
    [],
    false,
    undefined,
    rendererState.screenCursorZoomed.x -
      rendererState.mouseState.cursorOffset.x,
    rendererState.screenCursorZoomed.y -
      rendererState.mouseState.cursorOffset.y,
  );
}

export function prepareDndBlocksFramebuffer(
  rendererState: RendererState,
  nvg: NVG.Context,
  gl: WebGLRenderingContext,
  ctx: CanvasRenderingContext2D | null,
  backFramebuffer: NVG.NVGLUframebuffer | null,
  framebufWidth: number,
  framebufHeight: number,
) {
  if (ctx) return; // with canvas2d enable, we don't need to draw in gl here
  if (!backFramebuffer) return;

  const fboWidth: [number] = [0];
  const fboHeight: [number] = [0];
  nvg.imageSize(backFramebuffer.image, fboWidth, fboHeight);

  NVG.nvgluBindFramebuffer(backFramebuffer);
  gl.viewport(0, 0, fboWidth[0], fboHeight[0]);
  gl.clearColor(0, 0, 0, 0);
  gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT | gl.STENCIL_BUFFER_BIT);

  nvg.beginFrame(framebufWidth, framebufHeight, window.devicePixelRatio);
  renderDndBlocks(rendererState, nvg);
  nvg.endFrame();
  NVG.nvgluBindFramebuffer(null);
}

export function drawFloatingDndBlocks(
  rendererState: RendererState,
  nvg: NVG.Context,
  ctx: CanvasRenderingContext2D | null,
  backFramebuffer: NVG.NVGLUframebuffer | null,
  framebufWidth: number,
  framebufHeight: number,
  renderWidth: number,
  renderHeight: number,
) {
  // Render block d'n'd framebuffer as a semi-transparent overlay
  if (!ctx && backFramebuffer !== null) {
    nvg.fillPaint(
      nvg.imagePattern(
        0,
        0,
        framebufWidth,
        framebufHeight,
        0,
        backFramebuffer.image,
        0.6,
      ),
    );
    nvg.fillRect(0, 0, renderWidth, renderHeight);
  } else if (ctx) {
    // FIXME: Using globalAlpha is not 100% correct because alpha blends
    // the node elements together, so you can see the node's edges under the
    // supposedly opaque ports, for instance.
    // This can be easily fixed by rendering into a separate floating
    // canvas with alpha. It may also be possible to use another simple
    // <img> DOM element, but I'm not sure it could be out of sync (and
    // lag behind).
    ctx.globalAlpha = 0.6;
    renderDndBlocks(rendererState, nvg);
    ctx.globalAlpha = 1;
  }
}
