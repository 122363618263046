import { useTheme } from '@emotion/react';
import React from 'react';
import { Model } from 'ui/common/Icons/Small';
import {
  TreeArrowCollapsed,
  TreeArrowExpanded,
} from 'ui/common/Icons/Standard';
import { SimulationDataModelSimulationList } from 'ui/dataExplorer/simulationDataSidebar/SimulationDataModelSimulationList';
import {
  ItemSection,
  ModelBlockItemText,
  ModelTreeIcon,
} from 'ui/objectBrowser/ModelTreeParts';

const shouldSeeNode = (modelName: string, searchString?: string) =>
  modelName.toLowerCase().indexOf((searchString || '').toLowerCase()) > -1;

interface Props {
  searchString?: string;
  projectId: string;
  modelId: string;
  modelName: string;
  canEditProject: boolean;
}

export const SimulationDataModelItem: React.FC<Props> = ({
  searchString,
  projectId,
  modelId,
  modelName,
  canEditProject,
}) => {
  const theme = useTheme();

  const [isExpanded, setIsExpanded] = React.useState(false);

  const toggleExpandedState = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    event.preventDefault();
    event.stopPropagation();

    setIsExpanded(!isExpanded);
  };

  if (!shouldSeeNode(modelName, searchString)) {
    return null;
  }

  return (
    <>
      <ItemSection
        data-test-id={`data-explorer-model-simulation-tree-model-item-${modelName}`}
        key={modelId}
        selected={false}
        nestedLayer={0}>
        <ModelTreeIcon onClick={toggleExpandedState}>
          {isExpanded ? <TreeArrowExpanded /> : <TreeArrowCollapsed />}
        </ModelTreeIcon>

        <ModelTreeIcon>
          <Model fill={theme.colors.text.secondary} />
        </ModelTreeIcon>
        <div
          data-test-id={`data-explorer-model-simulation-tree-model-item-text-${modelName}`}>
          <ModelBlockItemText>{modelName}</ModelBlockItemText>
        </div>
      </ItemSection>

      {isExpanded && (
        <SimulationDataModelSimulationList
          modelId={modelId}
          canEditProject={canEditProject}
        />
      )}
    </>
  );
};
