import styled from '@emotion/styled/macro';
import ReactDOM from 'react-dom';

import Button from 'ui/common/Button/Button';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import { Close } from 'ui/common/Icons/Standard';
import { Large } from 'ui/common/typography/Typography';

import { useModal } from './useModal';

const Background = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #343a70;
  opacity: 0.7;
`;

const ModalOuterContainer = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
`;

const ModalTopSpacer = styled.div`
  width: 10px;
  height: 140px;
  min-height: 100px;
  flex-shrink: 1000;
  flex-grow: 0;
`;

const ModalInnerContainer = styled.div<{
  allowOverflow?: boolean;
  noPadding?: boolean;
}>`
  pointer-events: auto;
  display: flex;
  padding: ${(props) => (props.noPadding ? 0 : props.theme.spacing.large)};
  box-shadow: ${(props) => props.theme.shadows.highest};
  border-radius: ${(props) => props.theme.spacing.small};
  background-color: ${(props) => props.theme.colors.grey[2]};
  z-index: 100;
  ${({ allowOverflow }) => (allowOverflow ? '' : 'overflow: hidden;')}
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
`;

const ModalBottomSpacer = styled.div`
  width: 10px;
  height: 40px;
  min-height: 40px;
  flex-shrink: 0;
  flex-grow: 1;
`;

export const Header = styled.header`
  display: flex;
  margin-bottom: ${(props) => props.theme.spacing.large};
  align-items: center;
  flex-shrink: 0;
  flex-grow: 0;
`;

const ModalContent = styled.div<{ allowOverflow?: boolean }>`
  flex-shrink: 1;
  flex-grow: 1;
  ${({ allowOverflow }) => (allowOverflow ? '' : 'overflow: hidden;')}
  display: flex;
  flex-direction: column;
`;

export const Title = styled.span`
  flex: 1;
  font-size: ${(props) => props.theme.typography.font.title.size};
  line-height: ${(props) => props.theme.typography.font.title.lineHeight};
  margin-right: ${(props) => props.theme.spacing.large};
`;

/**
 * Modal dialog form container for wide content (like a table).
 */
export const LargeFormContainer = styled.form`
  width: 740px;
`;

/**
 * Modal dialog form container for narrow content (like a form).
 */
export const SmallFormContainer = styled.form`
  width: 340px;
`;

/**
 * Container for message text within the body of a modal dialog.
 */
export const ModalMessageContainer = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.large};
  overflow: hidden;
  flex-shrink: 1;
  flex-grow: 1;
  max-height: 100%;
`;

/**
 * Container for message text within the body of a modal dialog.
 */
export const ModalMessageText = styled(Large)`
  color: ${({ theme }) => theme.colors.text.primary};
`;

/**
 * Container for an input within the body of a modal dialog.
 */
export const ModalInputGroup = styled.div<{ horiz?: boolean }>`
  margin-bottom: ${({ theme }) => theme.spacing.large};
  ${({ horiz }) =>
    horiz
      ? `
    display: flex;
    > * {
      flex: 1;
      flex-shrink: 0;
    }
  `
      : ''}
`;

/**
 * Horizontal display group for use within the body of a modal dialog.
 */
export const ModalDisplayGroup = styled(ModalInputGroup)`
  display: flex;
  flex-direction: row;
`;

/**
 * Scroll container for scrollable content within the body of a modal dialog.
 */
export const ModalScrollContainer = styled(ModalInputGroup)`
  overflow-y: auto;
  flex-shrink: 1;
`;

/**
 * Scroll container for scrollable content within the body of a modal dialog.
 */
export const ModalBorderedContainer = styled(ModalInputGroup)`
  border: 1px solid ${({ theme }) => theme.colors.grey[10]};
  display: flex;
  flex-direction: column;
`;

/**
 * Modal dialog button container for 2 buttons
 * (cancel on the left, action button on the right).
 */
export const ActionButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-shrink: 0;
  flex-grow: 0;
  gap: ${({ theme }) => theme.spacing.normal};
`;

/**
 * Modal dialog button container when only a single close button
 * (on the right) is needed.
 */
export const CloseButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Modal: React.FC = () => {
  const el: HTMLElement | null = document.getElementById('modals');
  const {
    content,
    closeModal,
    showModal,
    allowOverflow,
    disableCloseControls,
    title,
  } = useModal();
  if (!el || !showModal) {
    return null;
  }

  return ReactDOM.createPortal(
    <>
      <Background
        data-test-id="modal-backdrop"
        onClick={!disableCloseControls ? closeModal : undefined}
      />
      <ModalOuterContainer>
        <ModalTopSpacer />
        <ModalInnerContainer
          noPadding={!title}
          allowOverflow={allowOverflow}
          data-test-id="modal-dialog"
          className="tour-modal">
          {title && (
            <Header>
              <Title data-test-id="modal-title">{title}</Title>
              {!disableCloseControls && (
                <Button
                  Icon={Close}
                  onClick={closeModal}
                  testId="modal-close-button"
                  variant={ButtonVariants.LargeTertiary}
                />
              )}
            </Header>
          )}
          <ModalContent
            allowOverflow={allowOverflow}
            data-test-id="modal-content">
            {content}
          </ModalContent>
        </ModalInnerContainer>
        <ModalBottomSpacer />
      </ModalOuterContainer>
    </>,
    el,
  );
};

export default Modal;
