import { NodeInstance, Parameters } from 'types';
import { mapAllNodes } from './migrationHelper';
import { MigratedModelV1 } from './modelMigrations_v1';

export interface MigratedModelV2 extends MigratedModelV1 {}

function fixIntegratorParams(node: NodeInstance): NodeInstance {
  const parameters: Parameters = {};

  switch (node.type) {
    case 'core.IntegratorDiscrete':
    case 'core.Integrator':
      // https://www.notion.so/Wildcat-legacy-support-workarounds-ad3f8141906c4665992e2a2e93cb6270?pvs=4#5075d9f8655e4305b3738d72409eaed5
      const UNSUPPORTED_PARAMS = [
        'hold_trigger_method',
        'reset_trigger_method',
      ];
      for (const [key, value] of Object.entries(node.parameters)) {
        if (!UNSUPPORTED_PARAMS.includes(key) && value) {
          parameters[key] = value;
        }
      }
      parameters.enable_reset = parameters.enable_reset ?? { value: 'false' };
      parameters.enable_external_reset = parameters.enable_external_reset ?? {
        value: parameters.enable_reset.value,
      };
      return { ...node, parameters };
  }

  return node;
}

export function migrateModelDataV1toV2(
  modelData: MigratedModelV1,
): MigratedModelV2 {
  const data = { ...modelData, schema_version: '2' } as MigratedModelV2;

  mapAllNodes(fixIntegratorParams, data.diagram, data.subdiagrams);

  return data;
}
