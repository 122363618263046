import { t } from '@lingui/macro';
import { useGetSimulationArtifactsReadByUuidQuery } from 'app/apiGenerated/generatedApi';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { CodegenMode, codeGenActions } from 'app/slices/codeGenSlice';
import { projectActions } from 'app/slices/projectSlice';
import React from 'react';
import { useNotifications } from 'ui/common/notifications/useNotifications';
import { formatFileNameDate } from 'util/dateUtils';
import { downloadBlobFile } from 'util/fileUtils';

export function useCodeGeneration() {
  const dispatch = useAppDispatch();
  const { showError } = useNotifications();

  const model = useAppSelector((state) => state.model.present);
  const { simulationSummary } = useAppSelector((state) => state.project);
  const {
    codeGenerationName,
    codeGenerationMode,
    requestCodeGenerationDownload,
  } = useAppSelector((state) => state.codeGen);

  const { data: files } = useGetSimulationArtifactsReadByUuidQuery(
    {
      modelUuid: simulationSummary?.model_uuid || '',
      simulationUuid: simulationSummary?.uuid || '',
    },
    {
      skip: !simulationSummary || simulationSummary?.status !== 'completed',
    },
  );

  /**
   * Triggers the simulation process for generating code
   * @param name name of the submodel we want to generate code for
   */
  const startCodeGeneration = (mode: CodegenMode, name: string) => {
    dispatch(codeGenActions.setCodeGenerationProperties({ mode, name }));
    if (mode === 'fmu_export') {
      dispatch(projectActions.requestFMUExport());
    } else {
      dispatch(projectActions.requestGenerateCode());
    }
  };

  // TODO: add filename to the API response & request
  // FMU: filename defined in fmu_utils.go
  // Codegen: filename defined in codegen_utils.go
  const expectedArtifactName = React.useMemo(
    () =>
      codeGenerationMode === 'fmu_export' ? 'submodel.fmu' : 'codegen.zip',
    [codeGenerationMode],
  );

  const codeGenerationArtifact = React.useMemo(
    () => files?.s3_urls?.find((file) => file.name === expectedArtifactName),
    [files, expectedArtifactName],
  );

  React.useEffect(() => {
    async function downloadFile() {
      if (!requestCodeGenerationDownload) return;
      if (!codeGenerationArtifact || !codeGenerationArtifact.url) return;

      dispatch(codeGenActions.clearCodeGenerationDownload());

      const errorMsg = t({
        id: 'codegen.download.error',
        message: 'There was a problem generating the code.',
      });

      const ext = codeGenerationMode === 'fmu_export' ? 'fmu' : 'zip';
      let fileName = `${model.name}.${codeGenerationName}`;
      fileName += `-${formatFileNameDate(new Date())}.${ext}`;

      try {
        // Note: downloadUrlFile can't reliably set the filename for a real
        // file so we use fetch + blob instead.
        const response = await fetch(codeGenerationArtifact.url);
        if (!response.ok) {
          const errResponse = await response.json();
          showError(errorMsg, errResponse.error);
        } else {
          const blob = await response.blob();
          downloadBlobFile(blob, fileName);
        }
      } catch (e) {
        showError(errorMsg, e);
      }
    }

    downloadFile();
  }, [
    requestCodeGenerationDownload,
    codeGenerationArtifact,
    codeGenerationMode,
    codeGenerationName,
    dispatch,
    model.name,
    showError,
  ]);

  return {
    startCodeGeneration,
  };
}
