import { useAuth as useClerkAuth } from '@clerk/clerk-react';

export const useAuth = (mock = false) => {
  if (mock) {
    return () => ({
      isLoaded: true,
      isSignedIn: true,
      userId: 'mock-user-id',
      sessionId: 'mock-session-id',
      actor: null,
      orgId: null,
      orgRole: null,
      orgSlug: null,
      getToken: () => 'mock-token',
    });
  }
  return useClerkAuth;
};
