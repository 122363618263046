import { OptimizationPartialRequest } from 'app/api/custom_types/optimizations';

export const OPTIMIZER_MODAL_PREFS_V1_KEY = 'OPTIMIZER_MODAL_PREFS_V1';

export type TabName = 'design' | 'estimation' | 'pid';

// Store a single prefs per model since entity prefs require an entity UUID,
// so we can't use a combined key like 'modelId+tabName'.
export type OptimizerModalSavedPrefs = {
  selectedTab: TabName;
  design: OptimizationPartialRequest;
  estimation: OptimizationPartialRequest;
  pid: OptimizationPartialRequest;
};

export const defaultOptimizerModalPrefs: OptimizerModalSavedPrefs = {
  selectedTab: 'design',
  design: {
    type: 'design',
    algorithm: 'adam',
    objective: undefined,
    constraints: [undefined],
    design_parameters: [{}],
    stochastic_parameters: [{ distribution: 'normal' }],
  },
  estimation: {
    type: 'estimation',
    algorithm: 'adam',
    design_parameters: [{}],
  },
  pid: {
    type: 'pid',
    algorithm: 'adam',
    pid_blocks: [undefined],
    objective: undefined,
  },
};
