import { t } from '@lingui/macro';
import React from 'react';
import { QuestionMark } from 'ui/common/Icons/Standard';
import { NavbarButton } from './NavbarButtons';

const globalHelpUrl = 'https://docs.collimator.ai/';

export const HelpButton: React.FC = () => (
  <NavbarButton
    tooltipText={t({
      id: 'navBar.helpDocumentationButton.tooltip',
      message: 'Documentation',
    })}
    Icon={QuestionMark}
    isEnabled
    onClickHandler={() => window.open(globalHelpUrl, '_blank')}
    testId="navbar-help-button"
  />
);
