import styled from '@emotion/styled';
import { useAppDispatch } from 'app/hooks';
import { dataExplorerActions } from 'app/slices/dataExplorerSlice';
import { TraceDragSource } from 'app/slices/traceDragSlice';
import { CellType } from 'ui/dataExplorer/dataExplorerTypes';
import {
  CellDropZoneGrid,
  TraceDropZoneBase,
} from 'ui/dataExplorer/drag/DropZoneCommon';

const TraceDragDropZoneLeft = styled(TraceDropZoneBase)({
  gridArea: 'left',
});
const TraceDragDropZoneRight = styled(TraceDropZoneBase)({
  gridArea: 'right',
});

const TraceDragDropZoneSelf = styled(TraceDropZoneBase)({
  gridArea: 'self',
});

type Props = {
  traceDragSource: TraceDragSource;
  targetCellId: string;
  cellType: CellType;
};

/**
 * Trace drop zones that represent cells within the target cell's row.
 */
const TraceDragCellDropZones = ({
  targetCellId,
  traceDragSource,
  cellType,
}: Props) => {
  const dispatch = useAppDispatch();

  return (
    <CellDropZoneGrid>
      <TraceDragDropZoneLeft
        onMouseUp={() => {
          dispatch(
            dataExplorerActions.moveTraceToNewCellBefore({
              targetCellId,
              traceId: traceDragSource.traceId,
            }),
          );
        }}
      />
      <TraceDragDropZoneRight
        onMouseUp={() => {
          dispatch(
            dataExplorerActions.moveTraceToNewCellAfter({
              targetCellId,
              traceId: traceDragSource.traceId,
            }),
          );
        }}
      />
      {cellType === 'plot' && (
        <TraceDragDropZoneSelf
          onMouseUp={() => {
            dispatch(
              dataExplorerActions.moveTraceToPlotCell({
                targetCellId,
                traceId: traceDragSource.traceId,
              }),
            );
          }}
        />
      )}
    </CellDropZoneGrid>
  );
};

export default TraceDragCellDropZones;
