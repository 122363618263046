// Use deepEqual in an useAppSelector when objects references may change
// despite their contents staying the same (eg. block is being moved and x,y
// change), but you must avoid expensive re-renders.
export function deepEqual(a: any, b: any): boolean {
  // return JSON.stringify(a) === JSON.stringify(b);
  if (a === b) return true;
  if (typeof a !== 'object' || typeof b !== 'object') return false;
  if (Object.keys(a).length !== Object.keys(b).length) return false;
  for (const key in a) {
    if (!deepEqual(a[key], b[key])) return false;
  }
  return true;
}

export function filterValid<T>(arr: (T | undefined | null)[]): T[] {
  return arr.filter((x) => x !== undefined && x !== null) as T[];
}
