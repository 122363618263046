import { DiagramVersionFull } from 'app/apiTransformers/convertGetSnapshotReadByUuid';
import { getCurrentModelRef } from 'app/sliceRefAccess/CurrentModelRef';
import {
  getSimulationRef,
  isSimulationRefAvailable,
} from 'app/sliceRefAccess/SimulationRef';
import { ModelVersionRequestData } from 'app/slices/modelVersionsSlice';
import { getNestedNode } from 'app/utils/modelDiagramUtils';
import {
  PortLocation,
  getPortPathName,
} from 'ui/modelEditor/portPathNameUtils';
import {
  SignalPathInfo,
  buildSignalInfoForCurrentModel,
} from 'util/modelVersionSignal';
import {
  SignalIndividualTypeAndTimeInfo,
  TimeModeType,
} from '../app/slices/compilationAnalysisDataSlice';

export function getPortTypeIsVector(
  portType: SignalIndividualTypeAndTimeInfo | null,
) {
  if (portType) {
    return portType.dimension.length === 1;
  }

  return false;
}

function isTypeSupportedForVisualization(
  dataType: SignalIndividualTypeAndTimeInfo | null,
) {
  if (!dataType) return true;

  // vectors and scalars have a dimension length of less than 2
  if (dataType.dimension.length < 2) {
    return true;
  }

  return false;
}

export function getPortTypeFromPortPathName(
  portPathName: string,
  portIndex: number,
): SignalIndividualTypeAndTimeInfo | null {
  if (!isSimulationRefAvailable()) {
    return null;
  }
  // doesn't this make it so we can't even specify which simulation we're getting this data for? -jackson
  const typeData = getSimulationRef().compilationData.signalsData;
  if (!typeData) {
    return null;
  }

  let nodeTypeData = typeData[portPathName];
  if (!nodeTypeData) {
    // TODO find a cleaner way to do this.
    // Try the lookup without the port name appended.
    const blockPathNameWithoutPortName = portPathName
      .split('.')
      .slice(0, -1)
      .join('.');
    nodeTypeData = typeData[blockPathNameWithoutPortName];
  }

  if (nodeTypeData && nodeTypeData.datatypeAndDimensions) {
    const portData = nodeTypeData.datatypeAndDimensions[portIndex];
    if (portData) {
      return portData;
    }
  }

  return null;
}

export function getTimeModeFromPortPathName(
  portPathName: string,
): TimeModeType | null {
  if (!isSimulationRefAvailable()) {
    return null;
  }
  const typeData = getSimulationRef().compilationData.signalsData;
  if (!typeData) {
    return null;
  }

  let nodeTypeData = typeData[portPathName];
  if (!nodeTypeData) {
    // TODO find a cleaner way to do this.
    // Try the lookup without the port name appended.
    const blockPathNameWithoutPortName = portPathName
      .split('.')
      .slice(0, -1)
      .join('.');
    nodeTypeData = typeData[blockPathNameWithoutPortName];
  }

  if (nodeTypeData && nodeTypeData.timeMode) {
    return nodeTypeData.timeMode;
  }

  return null;
}

export function getIsSignalSupported(signalPath: string, portIndex: number) {
  const signalType = getPortTypeFromPortPathName(signalPath, portIndex);
  return isTypeSupportedForVisualization(signalType);
}

interface TracePathsForPortResult {
  isVector?: boolean;
  tracePaths?: string[];
  modelVersionToRequest?: ModelVersionRequestData;
}

/**
 * Demux a port when possible into a list of the signal names for s3, otherwise return port name.
 * cmlc only supports vectors up to a small number of entries.
 */
export function demuxPortForTracePaths(
  portPathName: string,
  portIndex: number,
): TracePathsForPortResult {
  const portType = getPortTypeFromPortPathName(portPathName, portIndex);

  const tracePaths: string[] = [];
  let isVector = false;

  if (portType && getPortTypeIsVector(portType)) {
    const vectorLength = portType.dimension[0] as number;
    for (let i = 0; i < vectorLength; i++) {
      tracePaths.push(`${portPathName}[${i}]`);
    }
    isVector = true;
  } else {
    tracePaths.push(portPathName);
  }
  return {
    tracePaths,
    isVector,
  };
}

export function getPortType(portLocation: PortLocation) {
  const typeData = getSimulationRef().compilationData.signalsData;
  if (!typeData) {
    return null;
  }

  const portPathName = getPortPathName(
    getCurrentModelRef().topLevelNodes,
    getCurrentModelRef().submodels,
    portLocation,
    {
      includePortNameForNonSubmodels: false,
    },
  );
  if (!portPathName) {
    return null;
  }

  return getPortTypeFromPortPathName(portPathName, portLocation.portIndex);
}

export function getTracePathsForPort(
  signalPath: string,
  modelIdToVersionIdToModelData: Record<
    string,
    Record<string, DiagramVersionFull>
  >,
): TracePathsForPortResult {
  const { portIndex, errorMessage, modelVersionToRequest }: SignalPathInfo =
    buildSignalInfoForCurrentModel(signalPath, modelIdToVersionIdToModelData);

  if (modelVersionToRequest) {
    return { modelVersionToRequest };
  }

  if (portIndex === undefined || errorMessage) {
    return {
      tracePaths: [signalPath],
    };
  }

  return demuxPortForTracePaths(signalPath, portIndex);
}

function isPortTypeSupported(portLocation: PortLocation) {
  const typeData = getSimulationRef().compilationData.signalsData;

  // If we don't have typeData, assume the port type is supported.
  // Once we have typeData, we can mark a port type as unsupported later.
  if (Object.keys(typeData).length === 0) {
    return true;
  }

  const dataType = getPortType(portLocation);
  if (dataType && dataType.dtype === 'Complex') {
    return false;
  }
  return true;
}

export function getIsPortSupported({
  parentPath,
  nodeId,
  portIndex,
}: PortLocation) {
  // Top level submodels cannot be simulated directly.
  const topLevelModelType = getCurrentModelRef().topLevelModelType;
  if (topLevelModelType === 'Submodel') {
    return false;
  }

  const node = getNestedNode(
    getCurrentModelRef().topLevelNodes,
    getCurrentModelRef().submodels,
    parentPath,
    nodeId,
  );
  if (!node) {
    return false;
  }

  if (node.type === 'core.Outport') {
    return true;
  }
  if (!node.outputs || node.outputs.length === 0 || !node.outputs[portIndex]) {
    return false;
  }

  if (node.outputs[portIndex].variant?.variant_kind === 'acausal') return false;

  return isPortTypeSupported({
    nodeId,
    parentPath,
    portIndex,
  });
}

export function getIsNodeSupported(nodeId: string, parentPath: string[]) {
  // Top level submodels cannot be simulated directly.
  const topLevelModelType = getCurrentModelRef().topLevelModelType;
  if (topLevelModelType === 'Submodel') {
    return false;
  }

  const node = getNestedNode(
    getCurrentModelRef().topLevelNodes,
    getCurrentModelRef().submodels,
    parentPath,
    nodeId,
  );
  if (!node) {
    return false;
  }

  if (node.type === 'core.Outport' || node.type === 'core.Inport') {
    return false;
  }

  if (!node.outputs || node.outputs.length === 0) {
    return false;
  }

  for (let portIndex = 0; portIndex < node.outputs.length; portIndex++) {
    if (
      isPortTypeSupported({
        nodeId,
        parentPath,
        portIndex,
      })
    ) {
      return true;
    }
  }

  return false;
}
