import { MouseActions } from 'app/common_types/MouseTypes';
import { PortSide } from 'app/common_types/PortTypes';
import { FakeSegmentType } from 'app/common_types/SegmentTypes';
import {
  LinkInstance,
  LinkSegmentType,
  NodeInstance,
} from 'app/generated_types/SimulationModel';
import { getPortWorldCoordinate } from 'app/utils/getPortOffsetCoordinate';
import { snapNumberToGrid } from 'app/utils/modelDataUtils';
import { renderConstants } from 'app/utils/renderConstants';
import { tapLinkToRenderData } from 'app/utils/tapLinkToRenderData';
import { getVisualNodeHeight } from 'ui/modelRendererInternals/getVisualNodeHeight';
import { getVisualNodeWidth } from 'ui/modelRendererInternals/getVisualNodeWidth';
import { RendererState } from 'ui/modelRendererInternals/modelRenderer';

const GRID_SIZE = renderConstants.GRID_UNIT_PXSIZE;

export const BEND_SPACING = GRID_SIZE * 3;
const TOP_OFFSET = -BEND_SPACING;
const ERROR_LINK_DISTANCE = GRID_SIZE * 3;

export enum VertexSegmentType {
  Real,
  Fake,
  Irrelevant,
}

export enum VertexSegmentInteraction {
  ReifyEntireLine,
  ReifyIndices,
  None,
}

export type LinkVertex =
  | {
      segmentType: VertexSegmentType.Real;
      segmentIndex: number;
      vertexBent?: {
        indexWithinBend: number;
        reificationData: LinkSegmentType[];
        reifiedSegmentInteractedIndex: number;
      };
      coordinate: [x: number, y: number];
    }
  | {
      segmentType: VertexSegmentType.Fake;
      reificationData: LinkSegmentType[];
      reifiedSegmentInteractedIndex: number;
      fakeSegmentType: FakeSegmentType;
      coordinate: [x: number, y: number];
    }
  | {
      segmentType: VertexSegmentType.Irrelevant;
      coordinate: [x: number, y: number];
    };

export type LinkRenderData = {
  linkUuid: string;
  vertexData: LinkVertex[];
  boundingBox: {
    x1: number;
    y1: number;
    x2: number;
    y2: number;
  };
};

// FIXME: This function needs some refactoring, it is >1000 LoC long.
export const linkToRenderData = (
  rs: RendererState,
  link: LinkInstance,
  nodes: NodeInstance[],
  blocksIndexLUT: { [k: string]: number },
  offsetX: number,
  offsetY: number,
): LinkRenderData => {
  const userDrawingThisLink =
    (rs.mouseState.state === MouseActions.DrawingLinkFromStart ||
      rs.mouseState.state === MouseActions.DrawingLinkFromEnd) &&
    rs.mouseState.linkUuid === link.uuid;

  let segments = JSON.parse(JSON.stringify(link.uiprops.segments));
  let vertices: LinkVertex[] = [];

  if (link.uiprops.link_type.connection_method === 'link_tap') {
    return tapLinkToRenderData(
      rs,
      link,
      nodes,
      blocksIndexLUT,
      offsetX,
      offsetY,
      userDrawingThisLink,
    );
  }

  const srcBlock = nodes[blocksIndexLUT[link.src?.node || '']];
  const dstBlock = nodes[blocksIndexLUT[link.dst?.node || '']];

  const srcBlockHeight = getVisualNodeHeight(srcBlock);
  const dstBlockHeight = getVisualNodeHeight(dstBlock);
  const srcBlockWidth = getVisualNodeWidth(srcBlock);
  const dstBlockWidth = getVisualNodeWidth(dstBlock);

  const srcFlipped = srcBlock && srcBlock.uiprops.directionality === 'left';
  const dstFlipped = dstBlock && dstBlock.uiprops.directionality === 'left';

  const srcSide =
    link.src?.port_side === undefined || link.src?.port_side === 'outputs'
      ? PortSide.Output
      : PortSide.Input;
  const dstSide =
    link.dst?.port_side === undefined || link.dst?.port_side === 'inputs'
      ? PortSide.Input
      : PortSide.Output;

  const srcOnRight =
    (!srcFlipped && (srcSide === PortSide.Output || srcSide === undefined)) ||
    (srcFlipped && srcSide === PortSide.Input);
  const dstOnLeft =
    (!dstFlipped && (dstSide === PortSide.Input || dstSide === undefined)) ||
    (dstFlipped && dstSide === PortSide.Output);

  let linkStartCoord =
    getPortWorldCoordinate(srcBlock, PortSide.Output, link.src) ||
    link.uiprops.hang_coord_start;
  let linkEndCoord =
    getPortWorldCoordinate(dstBlock, PortSide.Input, link.dst) ||
    link.uiprops.hang_coord_end;

  // these next 3 if-blocks ensure that we always have
  // something renderable so that a user can click to
  // delete or remedy a link that somehow got into a weird state
  // (e.g. randomly browser-refreshing,
  //  PC crashed in the middle of editing, etc.)

  if (linkStartCoord && !linkEndCoord) {
    let endCoordX = 0;

    if (srcBlock) {
      if (!srcOnRight) {
        endCoordX = linkStartCoord.x - ERROR_LINK_DISTANCE;
      } else {
        endCoordX = linkStartCoord.x + ERROR_LINK_DISTANCE;
      }
    } else {
      endCoordX = linkStartCoord.x + ERROR_LINK_DISTANCE;
    }

    linkEndCoord = {
      x: endCoordX,
      y: linkStartCoord.y,
    };
  }

  if (linkEndCoord && !linkStartCoord) {
    let startCoordX = 0;

    if (dstBlock) {
      if (dstBlock.uiprops.directionality === 'left') {
        startCoordX = linkEndCoord.x + ERROR_LINK_DISTANCE;
      } else {
        startCoordX = linkEndCoord.x - ERROR_LINK_DISTANCE;
      }
    } else {
      startCoordX = linkEndCoord.x - ERROR_LINK_DISTANCE;
    }

    linkStartCoord = {
      x: startCoordX,
      y: linkEndCoord.y,
    };
  }

  if (!linkEndCoord && !linkStartCoord) {
    linkStartCoord = {
      x: -GRID_SIZE * 2,
      y: 0,
    };

    linkEndCoord = {
      x: GRID_SIZE * 2,
      y: 0,
    };
  }

  const cursorX = rs.screenCursorZoomed.x - offsetX;
  const cursorY = rs.screenCursorZoomed.y - offsetY;

  // Construct the vertices of our link

  if (segments.length === 0) {
    if (
      userDrawingThisLink &&
      rs.mouseState.state === MouseActions.DrawingLinkFromEnd &&
      linkStartCoord
    ) {
      const midX = snapNumberToGrid(
        (cursorX - linkStartCoord.x) / 2 + linkStartCoord.x,
      );

      vertices = [
        {
          segmentType: VertexSegmentType.Fake,
          fakeSegmentType: FakeSegmentType.SStart,
          reifiedSegmentInteractedIndex: 1,
          reificationData: [
            {
              coordinate:
                linkStartCoord.x + (!srcOnRight ? -GRID_SIZE : GRID_SIZE),
              segment_direction: 'vert',
            },
            {
              coordinate: linkStartCoord.y,
              segment_direction: 'horiz',
            },
          ],
          coordinate: [linkStartCoord.x, linkStartCoord.y],
        },
        {
          segmentType: VertexSegmentType.Fake,
          fakeSegmentType: FakeSegmentType.SMiddle,
          coordinate: [midX, linkStartCoord.y],
          reifiedSegmentInteractedIndex: 0,
          reificationData: [
            {
              coordinate: midX,
              segment_direction: 'vert',
            },
          ],
        },
        {
          segmentType: VertexSegmentType.Fake,
          fakeSegmentType: FakeSegmentType.SEnd,
          coordinate: [midX, cursorY],
          reifiedSegmentInteractedIndex: 0,
          reificationData: [
            {
              coordinate: cursorY,
              segment_direction: 'horiz',
            },
            {
              coordinate: cursorX + (!dstOnLeft ? GRID_SIZE : -GRID_SIZE),
              segment_direction: 'vert',
            },
          ],
        },
        {
          segmentType: VertexSegmentType.Fake,
          fakeSegmentType: FakeSegmentType.SEnd,
          coordinate: [cursorX, cursorY],
          reifiedSegmentInteractedIndex: 0,
          reificationData: [],
        },
      ];
    } else if (
      userDrawingThisLink &&
      rs.mouseState.state === MouseActions.DrawingLinkFromStart &&
      linkEndCoord
    ) {
      const midX = snapNumberToGrid((linkEndCoord.x - cursorX) / 2 + cursorX);

      vertices = [
        {
          segmentType: VertexSegmentType.Fake,
          fakeSegmentType: FakeSegmentType.SStart,
          reifiedSegmentInteractedIndex: 1,
          coordinate: [cursorX, cursorY],
          reificationData: [
            {
              coordinate: cursorX + (!srcOnRight ? -GRID_SIZE : GRID_SIZE),
              segment_direction: 'vert',
            },
            {
              coordinate: cursorY,
              segment_direction: 'horiz',
            },
          ],
        },
        {
          segmentType: VertexSegmentType.Fake,
          fakeSegmentType: FakeSegmentType.SMiddle,
          coordinate: [midX, cursorY],
          reifiedSegmentInteractedIndex: 0,
          reificationData: [
            {
              coordinate: midX,
              segment_direction: 'vert',
            },
          ],
        },
        {
          segmentType: VertexSegmentType.Fake,
          fakeSegmentType: FakeSegmentType.SEnd,
          coordinate: [midX, linkEndCoord.y],
          reifiedSegmentInteractedIndex: 0,
          reificationData: [
            {
              coordinate: linkEndCoord.y,
              segment_direction: 'horiz',
            },
            {
              coordinate:
                linkEndCoord.x + (!dstOnLeft ? GRID_SIZE : -GRID_SIZE),
              segment_direction: 'vert',
            },
          ],
        },
        {
          segmentType: VertexSegmentType.Fake,
          fakeSegmentType: FakeSegmentType.SEnd,
          coordinate: [linkEndCoord.x, linkEndCoord.y],
          reifiedSegmentInteractedIndex: 0,
          reificationData: [],
        },
      ];
    } else if (linkStartCoord && linkEndCoord) {
      const linkIsForwards =
        (srcOnRight && dstOnLeft && linkEndCoord.x > linkStartCoord.x) ||
        (!srcOnRight && !dstOnLeft && linkEndCoord.x < linkStartCoord.x);
      const linkBackwardsNormal =
        srcOnRight && dstOnLeft && linkEndCoord.x <= linkStartCoord.x;
      const linkBackwardsFlipped =
        !srcOnRight && !dstOnLeft && linkEndCoord.x >= linkStartCoord.x;
      const linkIsBackwards = linkBackwardsNormal || linkBackwardsFlipped;
      const bothPortsLeft = !srcOnRight && dstOnLeft;
      const bothPortsRight = srcOnRight && !dstOnLeft;
      const linkIsOnSameSide = bothPortsLeft || bothPortsRight;

      vertices.push({
        segmentType: VertexSegmentType.Fake,
        fakeSegmentType: FakeSegmentType.SStart,
        coordinate: [linkStartCoord.x, linkStartCoord.y],
        reifiedSegmentInteractedIndex: 1,
        reificationData: [
          {
            coordinate:
              linkStartCoord.x + (!srcOnRight ? -GRID_SIZE : GRID_SIZE),
            segment_direction: 'vert',
          },
          {
            coordinate: linkStartCoord.y,
            segment_direction: 'horiz',
          },
        ],
      });

      if (linkIsForwards) {
        const minX = Math.min(linkStartCoord.x, linkEndCoord.x);
        const maxX = Math.max(linkStartCoord.x, linkEndCoord.x);
        const midX = minX + (maxX - minX) / 2;

        vertices.push({
          segmentType: VertexSegmentType.Fake,
          fakeSegmentType: FakeSegmentType.SMiddle,
          coordinate: [midX, linkStartCoord.y],
          reifiedSegmentInteractedIndex: 0,
          reificationData: [
            {
              coordinate: midX,
              segment_direction: 'vert',
            },
          ],
        });
        vertices.push({
          segmentType: VertexSegmentType.Fake,
          fakeSegmentType: FakeSegmentType.SEnd,
          coordinate: [midX, linkEndCoord.y],
          reifiedSegmentInteractedIndex: 0,
          reificationData: [
            {
              coordinate: linkEndCoord.y,
              segment_direction: 'horiz',
            },
            {
              coordinate:
                linkEndCoord.x + (!dstOnLeft ? GRID_SIZE : -GRID_SIZE),
              segment_direction: 'vert',
            },
          ],
        });
        vertices.push({
          segmentType: VertexSegmentType.Fake,
          fakeSegmentType: FakeSegmentType.SEnd,
          coordinate: [linkEndCoord.x, linkEndCoord.y],
          reifiedSegmentInteractedIndex: 0,
          reificationData: [],
        });
      } else if (linkIsBackwards) {
        const dstBlockWidth = getVisualNodeWidth(dstBlock);

        const startXLimit = linkBackwardsNormal
          ? Math.max(
              linkStartCoord.x + GRID_SIZE,
              linkEndCoord.x + dstBlockWidth + GRID_SIZE,
            )
          : Math.min(
              linkStartCoord.x - GRID_SIZE,
              linkEndCoord.x - dstBlockWidth - GRID_SIZE,
            );

        let middleYLimit = 0;
        if (linkStartCoord.y <= linkEndCoord.y) {
          const srcYLimit = srcBlock
            ? srcBlock.uiprops.y + srcBlockHeight + BEND_SPACING
            : linkStartCoord.y;
          const dstYLimit = dstBlock
            ? dstBlock.uiprops.y + dstBlockHeight + BEND_SPACING
            : linkEndCoord.y;
          middleYLimit = Math.max(srcYLimit, dstYLimit);
        } else {
          const srcYLimit = srcBlock
            ? srcBlock.uiprops.y + TOP_OFFSET
            : linkStartCoord.y;
          const dstYLimit = dstBlock
            ? dstBlock.uiprops.y + TOP_OFFSET
            : linkEndCoord.y;
          middleYLimit = Math.min(srcYLimit, dstYLimit);
        }

        const endXLimit = dstBlock
          ? !dstOnLeft
            ? linkEndCoord.x + GRID_SIZE
            : linkEndCoord.x - GRID_SIZE
          : linkEndCoord.x;

        vertices = [
          {
            segmentType: VertexSegmentType.Fake,
            fakeSegmentType: FakeSegmentType.SEnd,
            coordinate: [linkStartCoord.x, linkStartCoord.y],
            reifiedSegmentInteractedIndex: 0,
            reificationData: [],
          },
          {
            segmentType: VertexSegmentType.Fake,
            fakeSegmentType: FakeSegmentType.SMiddle,
            coordinate: [startXLimit, linkStartCoord.y],
            reifiedSegmentInteractedIndex: 0,
            reificationData: [
              {
                coordinate: startXLimit,
                segment_direction: 'vert',
              },
            ],
          },
          {
            segmentType: VertexSegmentType.Fake,
            fakeSegmentType: FakeSegmentType.SEnd,
            coordinate: [startXLimit, middleYLimit],
            reifiedSegmentInteractedIndex: 0,
            reificationData: [
              {
                coordinate: middleYLimit,
                segment_direction: 'horiz',
              },
            ],
          },
          {
            segmentType: VertexSegmentType.Fake,
            fakeSegmentType: FakeSegmentType.SEnd,
            coordinate: [endXLimit, middleYLimit],
            reifiedSegmentInteractedIndex: 0,
            reificationData: [
              {
                coordinate: endXLimit,
                segment_direction: 'vert',
              },
            ],
          },
          {
            segmentType: VertexSegmentType.Fake,
            fakeSegmentType: FakeSegmentType.SEnd,
            coordinate: [endXLimit, linkEndCoord.y],
            reifiedSegmentInteractedIndex: 0,
            reificationData: [],
          },
          {
            segmentType: VertexSegmentType.Fake,
            fakeSegmentType: FakeSegmentType.SEnd,
            coordinate: [linkEndCoord.x, linkEndCoord.y],
            reifiedSegmentInteractedIndex: 0,
            reificationData: [],
          },
        ];
      } else if (linkIsOnSameSide) {
        const startXLimit = srcBlock
          ? !srcOnRight
            ? linkStartCoord.x - GRID_SIZE
            : linkStartCoord.x + GRID_SIZE
          : linkStartCoord.x;
        const finalXLimit = dstBlock
          ? !dstOnLeft
            ? linkEndCoord.x + GRID_SIZE
            : linkEndCoord.x - GRID_SIZE
          : linkEndCoord.x;

        const midX = !srcOnRight
          ? Math.min(startXLimit, finalXLimit)
          : Math.max(startXLimit, finalXLimit);

        const srcTopY = srcBlock
          ? srcBlock.uiprops.y + TOP_OFFSET
          : linkStartCoord.y;
        const srcBottomY = srcBlock
          ? srcBlock.uiprops.y + srcBlockHeight + BEND_SPACING
          : linkStartCoord.y;
        // const dstTopY = dstBlock ? dstBlock.uiprops.y + TOP_OFFSET : linkStartCoord.y;
        const dstBottomY = dstBlock
          ? dstBlock.uiprops.y + dstBlockHeight + BEND_SPACING
          : linkStartCoord.y;
        if (linkEndCoord.y >= srcTopY && linkEndCoord.y <= srcBottomY) {
          let yLimit = 0;

          if (!srcOnRight) {
            if (linkEndCoord.x > linkStartCoord.x) {
              yLimit = Math.max(linkEndCoord.y, srcBottomY);
            } else {
              yLimit = Math.max(linkEndCoord.y, dstBottomY);
            }
          } else if (linkStartCoord.x > linkEndCoord.x) {
            yLimit = Math.max(linkEndCoord.y, srcBottomY);
          } else {
            yLimit = Math.max(linkEndCoord.y, dstBottomY);
          }

          vertices.push({
            segmentType: VertexSegmentType.Fake,
            fakeSegmentType: FakeSegmentType.SMiddle,
            coordinate: [startXLimit, linkStartCoord.y],
            reifiedSegmentInteractedIndex: 0,
            reificationData: [
              {
                coordinate: startXLimit,
                segment_direction: 'vert',
              },
            ],
          });
          vertices.push({
            segmentType: VertexSegmentType.Fake,
            fakeSegmentType: FakeSegmentType.SMiddle,
            coordinate: [startXLimit, yLimit],
            reifiedSegmentInteractedIndex: 0,
            reificationData: [
              {
                coordinate: yLimit,
                segment_direction: 'horiz',
              },
            ],
          });
          vertices.push({
            segmentType: VertexSegmentType.Fake,
            fakeSegmentType: FakeSegmentType.SEnd,
            coordinate: [finalXLimit, yLimit],
            reifiedSegmentInteractedIndex: 0,
            reificationData: [
              {
                coordinate: finalXLimit,
                segment_direction: 'vert',
              },
            ],
          });
          vertices.push({
            segmentType: VertexSegmentType.Fake,
            fakeSegmentType: FakeSegmentType.SEnd,
            coordinate: [finalXLimit, linkEndCoord.y],
            reifiedSegmentInteractedIndex: 0,
            reificationData: [],
          });
          vertices.push({
            segmentType: VertexSegmentType.Fake,
            fakeSegmentType: FakeSegmentType.SEnd,
            coordinate: [linkEndCoord.x, linkEndCoord.y],
            reifiedSegmentInteractedIndex: 0,
            reificationData: [],
          });
        } else {
          vertices.push({
            segmentType: VertexSegmentType.Fake,
            fakeSegmentType: FakeSegmentType.SMiddle,
            coordinate: [midX, linkStartCoord.y],
            reifiedSegmentInteractedIndex: 0,
            reificationData: [
              {
                coordinate: midX,
                segment_direction: 'vert',
              },
            ],
          });
          vertices.push({
            segmentType: VertexSegmentType.Fake,
            fakeSegmentType: FakeSegmentType.SEnd,
            coordinate: [midX, linkEndCoord.y],
            reifiedSegmentInteractedIndex: 0,
            reificationData: [
              {
                coordinate: linkEndCoord.y,
                segment_direction: 'horiz',
              },
              {
                coordinate:
                  linkEndCoord.x + (!dstOnLeft ? GRID_SIZE : -GRID_SIZE),
                segment_direction: 'vert',
              },
            ],
          });
          vertices.push({
            segmentType: VertexSegmentType.Fake,
            fakeSegmentType: FakeSegmentType.SEnd,
            coordinate: [linkEndCoord.x, linkEndCoord.y],
            reifiedSegmentInteractedIndex: 0,
            reificationData: [],
          });
        }
      }
    }
  }

  if (segments.length === 1) {
    const onlySegmentCoord = segments[0].coordinate;
    if (
      userDrawingThisLink &&
      rs.mouseState.state === MouseActions.DrawingLinkFromEnd &&
      linkStartCoord
    ) {
      vertices = [
        {
          segmentType: VertexSegmentType.Fake,
          fakeSegmentType: FakeSegmentType.Start,
          reifiedSegmentInteractedIndex: 1,
          reificationData: [
            {
              coordinate:
                linkStartCoord.x + (!srcOnRight ? -GRID_SIZE : GRID_SIZE),
              segment_direction: 'vert',
            },
            {
              coordinate: linkStartCoord.y,
              segment_direction: 'horiz',
            },
          ],
          coordinate: [linkStartCoord.x, linkStartCoord.y],
        },
        {
          segmentType: VertexSegmentType.Real,
          segmentIndex: 0,
          coordinate: [onlySegmentCoord, linkStartCoord.y],
        },
        {
          segmentType: VertexSegmentType.Fake,
          fakeSegmentType: FakeSegmentType.End,
          coordinate: [onlySegmentCoord, cursorY],
          reifiedSegmentInteractedIndex: 0,
          reificationData: [
            {
              coordinate: cursorY,
              segment_direction: 'horiz',
            },
            {
              coordinate:
                onlySegmentCoord + (!dstOnLeft ? GRID_SIZE : -GRID_SIZE),
              segment_direction: 'vert',
            },
          ],
        },
        {
          segmentType: VertexSegmentType.Fake,
          fakeSegmentType: FakeSegmentType.End,
          coordinate: [cursorX, cursorY],
          reifiedSegmentInteractedIndex: 0,
          reificationData: [],
        },
      ];
    } else if (
      userDrawingThisLink &&
      rs.mouseState.state === MouseActions.DrawingLinkFromStart &&
      linkEndCoord
    ) {
      vertices = [
        {
          segmentType: VertexSegmentType.Fake,
          fakeSegmentType: FakeSegmentType.Start,
          reifiedSegmentInteractedIndex: 1,
          coordinate: [cursorX, cursorY],
          reificationData: [
            {
              coordinate: cursorX + (!srcOnRight ? -GRID_SIZE : GRID_SIZE),
              segment_direction: 'vert',
            },
            {
              coordinate: cursorY,
              segment_direction: 'horiz',
            },
          ],
        },
        {
          segmentType: VertexSegmentType.Real,
          segmentIndex: 0,
          coordinate: [onlySegmentCoord, cursorY],
        },
        {
          segmentType: VertexSegmentType.Fake,
          fakeSegmentType: FakeSegmentType.End,
          coordinate: [onlySegmentCoord, linkEndCoord.y],
          reifiedSegmentInteractedIndex: 0,
          reificationData: [
            {
              coordinate: linkEndCoord.y,
              segment_direction: 'horiz',
            },
            {
              coordinate:
                onlySegmentCoord + (!dstOnLeft ? GRID_SIZE : -GRID_SIZE),
              segment_direction: 'vert',
            },
          ],
        },
        {
          segmentType: VertexSegmentType.Fake,
          fakeSegmentType: FakeSegmentType.End,
          coordinate: [linkEndCoord.x, linkEndCoord.y],
          reifiedSegmentInteractedIndex: 0,
          reificationData: [],
        },
      ];
    } else if (linkStartCoord && linkEndCoord) {
      const sourceBlock = link.src
        ? nodes[blocksIndexLUT[link.src.node]]
        : undefined;
      const dstBlock = link.dst
        ? nodes[blocksIndexLUT[link.dst.node]]
        : undefined;

      vertices.push({
        segmentType: VertexSegmentType.Fake,
        fakeSegmentType: FakeSegmentType.Start,
        coordinate: [linkStartCoord.x, linkStartCoord.y],
        reifiedSegmentInteractedIndex: 1,
        reificationData: [
          {
            coordinate: linkStartCoord.x,
            segment_direction: 'vert',
          },
          {
            coordinate: linkStartCoord.y,
            segment_direction: 'horiz',
          },
        ],
      });

      if (sourceBlock) {
        const sourcePortOnLeft = !srcOnRight;
        const startX = sourcePortOnLeft
          ? sourceBlock.uiprops.x - GRID_SIZE
          : sourceBlock.uiprops.x + srcBlockWidth + GRID_SIZE;

        if (
          (sourcePortOnLeft && startX < onlySegmentCoord) ||
          (!sourcePortOnLeft && startX > onlySegmentCoord)
        ) {
          const bendY =
            linkStartCoord.y > linkEndCoord.y
              ? sourceBlock.uiprops.y - GRID_SIZE * 3
              : sourceBlock.uiprops.y + srcBlockHeight + BEND_SPACING;
          vertices.push({
            segmentType: VertexSegmentType.Real,
            segmentIndex: 0,
            coordinate: [startX, linkStartCoord.y],
          });
          vertices.push({
            segmentType: VertexSegmentType.Real,
            segmentIndex: 0,
            coordinate: [startX, bendY],
          });
          vertices.push({
            segmentType: VertexSegmentType.Real,
            segmentIndex: 0,
            coordinate: [onlySegmentCoord, bendY],
          });
        } else {
          vertices.push({
            segmentType: VertexSegmentType.Real,
            segmentIndex: 0,
            coordinate: [onlySegmentCoord, linkStartCoord.y],
          });
        }
      } else {
        vertices.push({
          segmentType: VertexSegmentType.Real,
          segmentIndex: 0,
          coordinate: [onlySegmentCoord, linkStartCoord.y],
        });
      }

      if (dstBlock) {
        const dstPortOnRight = !dstOnLeft;
        const endX = dstPortOnRight
          ? dstBlock.uiprops.x + dstBlockWidth + GRID_SIZE
          : dstBlock.uiprops.x - GRID_SIZE;

        if (
          (dstPortOnRight && endX > onlySegmentCoord) ||
          (!dstPortOnRight && endX < onlySegmentCoord)
        ) {
          const bendY =
            linkStartCoord.y > linkEndCoord.y
              ? dstBlock.uiprops.y + dstBlockHeight + BEND_SPACING
              : dstBlock.uiprops.y + TOP_OFFSET;
          vertices.push({
            segmentType: VertexSegmentType.Real,
            segmentIndex: 0,
            coordinate: [onlySegmentCoord, bendY],
          });
          vertices.push({
            segmentType: VertexSegmentType.Real,
            segmentIndex: 0,
            coordinate: [endX, bendY],
          });
          vertices.push({
            segmentType: VertexSegmentType.Fake,
            fakeSegmentType: FakeSegmentType.End,
            coordinate: [endX, linkEndCoord.y],
            reifiedSegmentInteractedIndex: 0,
            reificationData: [
              {
                coordinate: linkEndCoord.y,
                segment_direction: 'horiz',
              },
              {
                coordinate: linkEndCoord.x,
                segment_direction: 'vert',
              },
            ],
          });
        } else {
          vertices.push({
            segmentType: VertexSegmentType.Fake,
            fakeSegmentType: FakeSegmentType.End,
            coordinate: [onlySegmentCoord, linkEndCoord.y],
            reifiedSegmentInteractedIndex: 0,
            reificationData: [
              {
                coordinate: linkEndCoord.y,
                segment_direction: 'horiz',
              },
              {
                coordinate: linkEndCoord.x,
                segment_direction: 'vert',
              },
            ],
          });
        }
      } else {
        vertices.push({
          segmentType: VertexSegmentType.Fake,
          fakeSegmentType: FakeSegmentType.End,
          coordinate: [onlySegmentCoord, linkEndCoord.y],
          reifiedSegmentInteractedIndex: 0,
          reificationData: [
            {
              coordinate: linkEndCoord.y,
              segment_direction: 'horiz',
            },
            {
              coordinate: linkEndCoord.x,
              segment_direction: 'vert',
            },
          ],
        });
      }

      vertices.push({
        segmentType: VertexSegmentType.Fake,
        fakeSegmentType: FakeSegmentType.End,
        coordinate: [linkEndCoord.x, linkEndCoord.y],
        reifiedSegmentInteractedIndex: 0,
        reificationData: [],
      });
    }
  }

  if (segments.length > 1) {
    const firstSegment = segments[0];

    if (
      userDrawingThisLink &&
      rs.mouseState.state === MouseActions.DrawingLinkFromStart
    ) {
      vertices.push({
        segmentType: VertexSegmentType.Fake,
        fakeSegmentType: FakeSegmentType.Start,
        coordinate: [cursorX, cursorY],
        reifiedSegmentInteractedIndex: 1,
        reificationData: [
          {
            coordinate: cursorX,
            segment_direction: 'vert',
          },
          {
            coordinate: cursorY,
            segment_direction: 'horiz',
          },
        ],
      });
      if (firstSegment.segment_direction === 'vert') {
        vertices.push({
          segmentType: VertexSegmentType.Irrelevant,
          coordinate: [firstSegment.coordinate, cursorY],
        });
      } else {
        vertices.push({
          segmentType: VertexSegmentType.Irrelevant,
          coordinate: [cursorX, firstSegment.coordinate],
        });
      }
    } else if (linkStartCoord) {
      if (firstSegment.segment_direction === 'vert') {
        // if we're connected to a source, check to see if we should
        // "bend" the first segment around the source block
        // and then add more vertices if that is the case
        const sourceBlockIndex = blocksIndexLUT[link.src?.node || ''];
        const sourceBlock =
          nodes[isNaN(sourceBlockIndex) ? -1 : sourceBlockIndex];
        const { x: bX, y: bY } = sourceBlock?.uiprops || { x: 0, y: 0 };
        const sourcePortOnLeft = !srcOnRight;
        const minX = sourceBlock
          ? sourcePortOnLeft
            ? bX - GRID_SIZE
            : bX + srcBlockWidth + GRID_SIZE
          : 0;
        const bent =
          (link.src && sourcePortOnLeft && firstSegment.coordinate > minX) ||
          (!sourcePortOnLeft && firstSegment.coordinate < minX);

        if (bent) {
          vertices.push({
            segmentType: VertexSegmentType.Real,
            segmentIndex: 0,
            coordinate: [linkStartCoord.x, linkStartCoord.y],
            vertexBent: {
              indexWithinBend: 0,
              reificationData: [],
              reifiedSegmentInteractedIndex: 0,
            },
          });

          const topY = bY - GRID_SIZE * 3;
          const bottomY = bY + srcBlockHeight + BEND_SPACING;

          vertices.push({
            segmentType: VertexSegmentType.Real,
            segmentIndex: 0,
            coordinate: [minX, linkStartCoord.y],
            vertexBent: {
              indexWithinBend: 1,
              reificationData: [
                {
                  coordinate: minX,
                  segment_direction: 'vert',
                },
              ],
              reifiedSegmentInteractedIndex: 0,
            },
          });

          const nextSegment = segments[1];
          if (nextSegment && nextSegment.coordinate < topY) {
            vertices.push({
              segmentType: VertexSegmentType.Real,
              segmentIndex: 0,
              coordinate: [minX, topY],
              vertexBent: {
                indexWithinBend: 1,
                reificationData: [
                  {
                    coordinate: topY,
                    segment_direction: 'horiz',
                  },
                ],
                reifiedSegmentInteractedIndex: 0,
              },
            });
            vertices.push({
              segmentType: VertexSegmentType.Real,
              segmentIndex: 0,
              coordinate: [firstSegment.coordinate, topY],
            });
          } else if (nextSegment && nextSegment.coordinate > bottomY) {
            vertices.push({
              segmentType: VertexSegmentType.Real,
              segmentIndex: 0,
              coordinate: [minX, bottomY],
              vertexBent: {
                indexWithinBend: 1,
                reificationData: [
                  {
                    coordinate: bottomY,
                    segment_direction: 'horiz',
                  },
                ],
                reifiedSegmentInteractedIndex: 0,
              },
            });
            vertices.push({
              segmentType: VertexSegmentType.Real,
              segmentIndex: 0,
              coordinate: [firstSegment.coordinate, bottomY],
            });
          }
        }

        if (!bent) {
          vertices.push({
            segmentType: VertexSegmentType.Fake,
            fakeSegmentType: FakeSegmentType.Start,
            coordinate: [linkStartCoord.x, linkStartCoord.y],
            reifiedSegmentInteractedIndex: 1,
            reificationData: [
              {
                coordinate: linkStartCoord.x,
                segment_direction: 'vert',
              },
              {
                coordinate: linkStartCoord.y,
                segment_direction: 'horiz',
              },
            ],
          });
          vertices.push({
            segmentType: VertexSegmentType.Real,
            segmentIndex: 0,
            coordinate: [firstSegment.coordinate, linkStartCoord.y],
          });
        }
      } else {
        vertices.push({
          segmentType: VertexSegmentType.Real,
          segmentIndex: 0,
          coordinate: [linkStartCoord.x, firstSegment.coordinate],
        });
      }
    }

    for (let i = 1; i < segments.length; i++) {
      const segment = segments[i];
      const previousVertex = vertices[vertices.length - 1]?.coordinate;
      const [pVX, pVY] = previousVertex || [0, 0];
      let coordinate: [x: number, y: number] = [0, 0];

      if (segment.segment_direction === 'vert') {
        coordinate = [segment.coordinate, pVY];
      } else {
        coordinate = [pVX, segment.coordinate];
      }

      vertices.push({
        segmentType: VertexSegmentType.Real,
        segmentIndex: i,
        coordinate,
      });
    }

    const lastSegmentIndex = segments.length - 1;
    const lastSegment = segments[lastSegmentIndex];

    if (
      userDrawingThisLink &&
      rs.mouseState.state === MouseActions.DrawingLinkFromEnd
    ) {
      if (lastSegment.segment_direction === 'vert') {
        vertices.push({
          segmentType: VertexSegmentType.Irrelevant,
          coordinate: [lastSegment.coordinate, cursorY],
        });
      } else {
        vertices.push({
          segmentType: VertexSegmentType.Irrelevant,
          coordinate: [cursorX, lastSegment.coordinate],
        });
      }
      vertices.push({
        segmentType: VertexSegmentType.Irrelevant,
        coordinate: [cursorX, cursorY],
      });
    } else if (linkEndCoord) {
      let lastVertices: LinkVertex[] = [];

      if (lastSegment.segment_direction === 'vert') {
        // vertices.push([lastSegment.coordinate, linkEndCoord.y]);

        // if we're connected to a destination, check to see if we should
        // "bend" the first segment around the destination block
        // and then add more vertices if that is the case
        let bent = false;

        if (link.dst) {
          const dstBlock = nodes[blocksIndexLUT[link.dst.node]];
          const { x: bX, y: bY } = dstBlock.uiprops;
          const dstPortOnRight = !dstOnLeft;
          const endX = dstPortOnRight
            ? bX + dstBlockWidth + GRID_SIZE
            : bX - GRID_SIZE;
          const topY = bY - GRID_SIZE * 3;
          const bottomY = bY + dstBlockHeight + BEND_SPACING;

          if (
            (dstPortOnRight && lastSegment.coordinate < endX) ||
            (!dstPortOnRight && lastSegment.coordinate > endX)
          ) {
            bent = true;
            lastVertices.unshift({
              segmentType: VertexSegmentType.Real,
              segmentIndex: lastSegmentIndex,
              coordinate: [endX, linkEndCoord.y],
            });

            const prevSegment = segments[segments.length - 2];
            if (prevSegment && prevSegment.coordinate < topY) {
              lastVertices.unshift({
                segmentType: VertexSegmentType.Real,
                segmentIndex: lastSegmentIndex,
                coordinate: [endX, topY],
              });
              lastVertices.unshift({
                segmentType: VertexSegmentType.Real,
                segmentIndex: lastSegmentIndex,
                coordinate: [lastSegment.coordinate, topY],
              });
            } else if (prevSegment && prevSegment.coordinate > bottomY) {
              lastVertices.unshift({
                segmentType: VertexSegmentType.Real,
                segmentIndex: lastSegmentIndex,
                coordinate: [endX, bottomY],
              });
              lastVertices.unshift({
                segmentType: VertexSegmentType.Real,
                segmentIndex: lastSegmentIndex,
                coordinate: [lastSegment.coordinate, bottomY],
              });
            } else {
              // the final vertex's X has already been computed as past the maximum X,
              // so we need to correct it here so that it isn't rendered under
              vertices[vertices.length - 1].coordinate[0] = endX;
            }
          }
        }

        if (!bent) {
          lastVertices = [
            {
              segmentType: VertexSegmentType.Fake,
              fakeSegmentType: FakeSegmentType.End,
              coordinate: [lastSegment.coordinate, linkEndCoord.y],
              reifiedSegmentInteractedIndex: 0,
              reificationData: [
                {
                  coordinate: linkEndCoord.y,
                  segment_direction: 'horiz',
                },
                {
                  coordinate: linkEndCoord.x,
                  segment_direction: 'vert',
                },
              ],
            },
          ];
        }
      } else {
        lastVertices = [
          {
            segmentType: VertexSegmentType.Fake,
            fakeSegmentType: FakeSegmentType.End,
            coordinate: [linkEndCoord.x, lastSegment.coordinate],
            reifiedSegmentInteractedIndex: 0,
            reificationData: [
              {
                coordinate: linkEndCoord.y,
                segment_direction: 'horiz',
              },
              {
                coordinate: linkEndCoord.x,
                segment_direction: 'vert',
              },
            ],
          },
        ];
      }
      vertices = vertices.concat(lastVertices);
      vertices.push({
        segmentType: VertexSegmentType.Fake,
        fakeSegmentType: FakeSegmentType.End,
        coordinate: [linkEndCoord.x, linkEndCoord.y],
        reifiedSegmentInteractedIndex: 0,
        reificationData: [],
      });
    }
  }

  let linkBoxMinX = Infinity;
  let linkBoxMinY = Infinity;
  let linkBoxMaxX = -Infinity;
  let linkBoxMaxY = -Infinity;
  for (let k = 0; k < vertices.length; k++) {
    const vertex = vertices[k];
    const [x, y] = vertex.coordinate;
    linkBoxMinX = Math.min(linkBoxMinX, x);
    linkBoxMinY = Math.min(linkBoxMinY, y);
    linkBoxMaxX = Math.max(linkBoxMaxX, x);
    linkBoxMaxY = Math.max(linkBoxMaxY, y);
  }

  return {
    linkUuid: link.uuid,
    vertexData: vertices,
    boundingBox: {
      x1: linkBoxMinX,
      y1: linkBoxMinY,
      x2: linkBoxMaxX,
      y2: linkBoxMaxY,
    },
  };
};
