import React from 'react';
import { useAppSelector, useAppDispatch } from 'app/hooks';
import { dataExplorerActions } from 'app/slices/dataExplorerSlice';
import { v4 as uuid } from 'uuid';
import { buildSignalInfoForModelVersion } from 'util/modelVersionSignal';
import { NodeInstance } from 'app/generated_types/SimulationModel';
import { ExplorationAddSignalRequest } from 'util/simulationSignalsTraces';
import { getTracesForSimulationSignal } from 'util/traceLookup';

interface Props {
  modelId: string;
  modelVersionId: string;
  simulationId: string;
  signalPath: string;
  vectorIndices?: number[];
  parentNode: NodeInstance | undefined;
  defaultDisplayName: string;
  hasCsvSupport: boolean;
  canEditProject: boolean;
}

export function useSignal({
  modelId,
  modelVersionId,
  simulationId,
  signalPath,
  vectorIndices,
  hasCsvSupport,
  canEditProject,
}: Props) {
  const dispatch = useAppDispatch();

  const [addSignalRequestId, setAddSignalRequestId] =
    React.useState<string>('');

  const idToTrace = useAppSelector((state) => state.dataExplorer.idToTrace);
  const traces = getTracesForSimulationSignal(
    simulationId,
    signalPath,
    idToTrace,
  );
  const isSignalPathMarkedUnsupported = useAppSelector((state) => {
    if (
      state.dataExplorer.simulationIdToSignalPathToIsUnsupported[simulationId]
    ) {
      return state.dataExplorer.simulationIdToSignalPathToIsUnsupported[
        simulationId
      ][signalPath];
    }
    return false;
  });

  // Used for optimistic updates for toggling this signal on
  // when we need to wait for the model version to load.
  const addSignalRequestIsPending = useAppSelector((state) =>
    addSignalRequestId
      ? !!state.dataExplorer.idToAddSignalRequest[addSignalRequestId]
      : false,
  );

  // Clear the pending request id if the request is completed.
  React.useEffect(() => {
    if (addSignalRequestId && !addSignalRequestIsPending) {
      setAddSignalRequestId('');
    }
  }, [addSignalRequestId, addSignalRequestIsPending]);

  const isSignalInExploration = React.useMemo(
    () => traces && traces.length > 0,
    [traces],
  );

  const isSignalToggledOn = isSignalInExploration || addSignalRequestId;

  const toggleVisualization = React.useCallback(() => {
    if (isSignalToggledOn) {
      dispatch(
        dataExplorerActions.cancelAddSignalRequestForSignalPath({
          simulationId,
          signalPath,
        }),
      );
      dispatch(
        dataExplorerActions.removeTraces({
          traceIds: traces.map((trace) => trace.id),
        }),
      );
    } else {
      const addSignalRequest: ExplorationAddSignalRequest = {
        id: uuid(),
        modelId,
        modelVersionId,
        simulationId,
        signalPath,
        vectorIndices,
      };

      dispatch(dataExplorerActions.requestAddSignal(addSignalRequest));
    }
  }, [
    dispatch,
    isSignalToggledOn,
    modelId,
    modelVersionId,
    simulationId,
    signalPath,
    traces,
    vectorIndices,
  ]);

  const modelIdToVersionIdToModelData = useAppSelector(
    (state) => state.modelVersions.modelIdToVersionIdToModelData,
  );

  // Find the associated parent node in the model version so we
  // can find signal names (which we use as display names for signals when
  // they are available and so we can know what type each submodel is and
  // display them with the correct icon.)
  const {
    timeMode,
    parentPath,
    node,
    portIndex,
    signalDisplayName,
    signalLeafDisplayName,
  } = buildSignalInfoForModelVersion(
    signalPath,
    modelId,
    modelVersionId,
    modelIdToVersionIdToModelData,
  );

  const nodeId = node?.uuid;
  const isSupported = hasCsvSupport && isSignalPathMarkedUnsupported !== true;

  return {
    isSupported,
    signalDisplayName,
    signalLeafDisplayName,
    isSignalInExploration,
    toggleVisualization,
    traces,
    parentPath,
    nodeId,
    portIndex,
    timeMode,
  };
}
