import styled from '@emotion/styled/macro';
import { t } from '@lingui/macro';
import { useGitHubReposList } from 'app/api/useGitHubApis';
import { PostProjectGitConnectRemoteApiArg } from 'app/apiGenerated/generatedApiTypes';
import { usePostProjectGitConnectRemoteMutation } from 'app/enhancedApi';
import React from 'react';
import Button from 'ui/common/Button/Button';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import { Plus } from 'ui/common/Icons/Standard';
import Label from 'ui/common/Label';
import {
  ActionButtonContainer,
  ModalInputGroup,
  SmallFormContainer,
} from 'ui/common/Modal/Modal';
import { useModal } from 'ui/common/Modal/useModal';
import SelectInput from 'ui/common/SelectInput';
import { Spinner } from 'ui/common/Spinner';
import { useNotifications } from 'ui/common/notifications/useNotifications';
import { useAppParams } from 'util/useAppParams';

const StyledForm = styled(SmallFormContainer)`
  label {
    color: ${({ theme }) => theme.colors.text.secondary};
  }
`;

export const GitRemoteModal = () => {
  const { closeModal } = useModal();
  const { projectId } = useAppParams();
  const { showError, showInfo } = useNotifications();

  const { gitHubReposList, isLoadingGitHubRepos } = useGitHubReposList();
  const [callGitConnectRemoteApi] = usePostProjectGitConnectRemoteMutation();

  const [repoFullName, setRepoFullName] = React.useState<string | undefined>();
  const [submitting, setSubmitting] = React.useState(false);

  const repoOptions = React.useMemo(
    () =>
      (gitHubReposList?.repos || []).map((repo) => {
        const value = `${repo.owner}/${repo.name}`;
        const label = `${value}${!repo.private ? ' (public)' : ''}`;
        return { label, value };
      }),
    [gitHubReposList?.repos],
  );

  const dataValid = repoFullName !== undefined;
  const appInstallURL = process.env.REACT_APP_GITHUB_APP_INSTALL_URL;

  const onSubmit = React.useCallback(() => {
    if (!projectId || !repoFullName) return;

    setSubmitting(true);

    const repo_owner = repoFullName.split('/')[0];
    const repo_name = repoFullName.split('/')[1];

    const payload: PostProjectGitConnectRemoteApiArg = {
      projectUuid: projectId,
      projectGitConnectRequest: { repo_owner, repo_name },
    };

    callGitConnectRemoteApi(payload)
      .unwrap()
      .then(() => {
        showInfo(t`Project is now connected to git remote!`);
      })
      .catch((e) => {
        showError(t`Could not connect project to git remote!`, e);
      })
      .finally(() => {
        setSubmitting(false);
        closeModal();
      });
  }, [
    callGitConnectRemoteApi,
    showError,
    showInfo,
    closeModal,
    projectId,
    repoFullName,
  ]);

  return (
    <StyledForm
      onSubmit={(e) => {
        e?.preventDefault();
        onSubmit();
      }}>
      <ModalInputGroup>
        <Label>
          {t({
            id: 'gitRemoteModal.service.label',
            message: 'Choose a supported service',
          })}
        </Label>
        <SelectInput
          isDisabled
          options={[{ label: 'GitHub', value: 'github' }]}
          currentValue="github"
        />
      </ModalInputGroup>
      <ModalInputGroup>
        <Label>
          {t({
            id: 'gitRemoteModal.repoName.label',
            message: 'Select the repository to link',
          })}
        </Label>
        <SelectInput
          options={repoOptions}
          currentValue={repoFullName}
          onSelectValue={setRepoFullName}
          isDisabled={isLoadingGitHubRepos || submitting}
        />
      </ModalInputGroup>
      <ModalInputGroup>
        <Label>
          {t({
            id: 'gitRemoteModal.installApp.label',
            message: "Can't find the repository you're looking for?",
          })}
        </Label>
        <Button
          type="button"
          disabled={!appInstallURL}
          variant={ButtonVariants.SmallSecondary}
          onClick={() =>
            window.open(appInstallURL, '_blank')
          }>{t`Install the Collimator App on GitHub`}</Button>
      </ModalInputGroup>
      <ActionButtonContainer>
        <Button
          type="button"
          onClick={closeModal}
          disabled={submitting}
          variant={ButtonVariants.LargeSecondary}>
          {t({
            id: 'gitRemoteModal.cancelButton.label',
            message: 'Cancel',
          })}
        </Button>
        <Button
          type="submit"
          Icon={isLoadingGitHubRepos || submitting ? Spinner : Plus}
          variant={ButtonVariants.LargePrimary}
          disabled={submitting || !dataValid}>
          {t({
            id: 'gitRemoteModal.addButton.label',
            message: 'Add',
          })}
        </Button>
      </ActionButtonContainer>
    </StyledForm>
  );
};
