import styled from '@emotion/styled/macro';
import { Coordinate } from 'app/common_types/Coordinate';
import { useAppSelector } from 'app/hooks';
import React from 'react';
import { DragContext } from './DragProvider';

const DragOverlayWrapper = styled.div`
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  height: 0;
  width: 0;
`;

const DragPreviewWrapper = styled.div<{
  pos: Coordinate;
  offset: Coordinate;
  hide: boolean;
}>`
  position: relative;
  ${({ pos, offset }) => `
    transform: translate(${pos.x - offset.x}px, ${pos.y - offset.y}px);
  `}
  ${({ hide }) => `opacity: ${hide ? 0 : 1};`}
  z-index: 100;
`;

export const DragOverlay = () => {
  const dragContextData = React.useContext(DragContext);
  const [dragPos, setDragPos] = React.useState<Coordinate>({ x: 0, y: 0 });
  const hideLibraryDrag = useAppSelector(
    (state) => state.uiFlags.hideLibraryDrag,
  );

  const handleMouseMove = React.useCallback(
    (e: MouseEvent) => {
      if (
        hideLibraryDrag &&
        (!dragContextData.state.dragging ||
          dragContextData.state.category === 'LibraryBlock')
      ) {
        return;
      }

      setDragPos({ x: e.x, y: e.y });
    },
    [setDragPos, dragContextData.state, hideLibraryDrag],
  );

  React.useEffect(() => {
    let moverRAF = -1;
    const mover = (e: MouseEvent) => {
      window.cancelAnimationFrame(moverRAF);
      window.requestAnimationFrame(() => handleMouseMove(e));
    };

    window.addEventListener('mousemove', mover);

    return () => window.removeEventListener('mousemove', mover);
  }, [handleMouseMove]);

  if (dragContextData.state.dragging) {
    const { dragPreviewComp: DragPreview, dragPreviewCompProps: dragProps } =
      dragContextData.state;

    const hide =
      hideLibraryDrag && dragContextData.state.category === 'LibraryBlock';

    return (
      <DragOverlayWrapper>
        <DragPreviewWrapper
          hide={hide}
          pos={dragPos}
          offset={dragContextData.state.cursorElementOffset}>
          <DragPreview {...dragProps} />
        </DragPreviewWrapper>
      </DragOverlayWrapper>
    );
  }

  return null;
};
