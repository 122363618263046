import { t } from '@lingui/macro';
import { useProjectActions } from 'app/api/useProjectActions';
import React from 'react';
import Button from 'ui/common/Button/Button';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import {
  ActionButtonContainer,
  ModalMessageContainer,
  ModalMessageText,
  SmallFormContainer,
} from 'ui/common/Modal/Modal';
import { useModal } from 'ui/common/Modal/useModal';

interface Props {
  projectId: string;
  fileName: string;
  fileUuid: string;
}

const DownloadFileModal: React.FC<Props> = ({
  fileName,
  fileUuid,
  projectId,
}) => {
  const { closeModal } = useModal();
  const { downloadFile } = useProjectActions();

  const onCancel = () => {
    closeModal();
  };

  const onConfirm = () => {
    downloadFile(fileName, {
      fileUuid,
      projectUuid: projectId,
    });
    closeModal();
  };

  return (
    <SmallFormContainer
      onSubmit={(e) => {
        e?.preventDefault();
        onConfirm();
      }}>
      <ModalMessageContainer data-test-id="download-file-modal-message">
        <ModalMessageText>
          {t({
            id: 'downloadFileModal.messageContent.text',
            message:
              'We don’t have a viewer for that file at the moment. However, you can download the file and edit it locally.',
          })}
        </ModalMessageText>
      </ModalMessageContainer>
      <ActionButtonContainer>
        {/* Cancel button */}
        <Button
          type="button"
          onClick={onCancel}
          variant={ButtonVariants.LargeSecondary}>
          {t({
            id: 'downloadFileModal.cancelButton.label',
            message: 'Cancel',
          })}
        </Button>

        {/* Download button */}
        <Button type="submit" variant={ButtonVariants.LargePrimary}>
          {t({
            id: 'downloadFileModal.downloadButton.label',
            message: 'Download',
          })}
        </Button>
      </ActionButtonContainer>
    </SmallFormContainer>
  );
};

export default DownloadFileModal;
