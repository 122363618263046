/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Label: React.FC<Props> = (props) => (
  <svg viewBox="0 0 16 16" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M3 4.5C3 3.67157 3.67157 3 4.5 3H7.58579C7.98361 3 8.36514 3.15804 8.64645 3.43934L12.6464 7.43934C13.2322 8.02513 13.2322 8.97487 12.6464 9.56066L9.56066 12.6464C8.97487 13.2322 8.02513 13.2322 7.43934 12.6464L3.43934 8.64645C3.15804 8.36514 3 7.98361 3 7.58579V4.5ZM4.5 4C4.22386 4 4 4.22386 4 4.5V7.58579C4 7.7184 4.05268 7.84557 4.14645 7.93934L8.14645 11.9393C8.34171 12.1346 8.65829 12.1346 8.85355 11.9393L11.9393 8.85355C12.1346 8.65829 12.1346 8.34171 11.9393 8.14645L7.93934 4.14645C7.84557 4.05268 7.71839 4 7.58579 4H4.5ZM6 5.5C5.72386 5.5 5.5 5.72386 5.5 6C5.5 6.27614 5.72386 6.5 6 6.5C6.27614 6.5 6.5 6.27614 6.5 6C6.5 5.72386 6.27614 5.5 6 5.5ZM4.5 6C4.5 5.17157 5.17157 4.5 6 4.5C6.82843 4.5 7.5 5.17157 7.5 6C7.5 6.82843 6.82843 7.5 6 7.5C5.17157 7.5 4.5 6.82843 4.5 6Z"
      fill={props.fill}
    />
  </svg>
);

Label.defaultProps = {
  width: '16',
  height: '16',
  viewBox: '0 0 16 16',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Label;
