import { useModal } from 'ui/common/Modal/useModal';

import { t } from '@lingui/macro';
import { useDuplicateSubmodel } from 'app/api/useDuplicateSubmodel';
import { useProjectItems } from 'app/api/useProjectItems';
import { Project } from 'app/apiTransformers/convertAPIProjectToProject';
import { SubmodelFullUI } from 'app/apiTransformers/convertGetSubmodel';
import { getNextValidIdentifier } from 'app/transformers/uniqueNameGenerators';
import { useNavigate } from 'react-router-dom';
import Button from 'ui/common/Button/Button';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import Copy from 'ui/common/Icons/Standard/Copy';
import { ProjectItemType } from 'ui/dashboard/dashboardTypes';
import DuplicateModal from 'ui/dashboard/projectDetail/DuplicateModal';

const DESC = t({
  message:
    'This High-Level Block will be duplicated to your project as a submodel, and then that submodel will be opened for you to edit.',
  id: 'DuplicateHighLevelBlockButton.Description',
});

const DuplicateHighLevelBlockButton = ({
  project,
  hlb,
  tint,
}: {
  project: Project;
  hlb: SubmodelFullUI;
  tint?: string;
}) => {
  const { triggerModal, closeModal } = useModal();
  const navigate = useNavigate();

  const hlbName = hlb.name;
  const { projectItems } = useProjectItems(project);
  const sameTypeFiles = projectItems
    .filter((item) => item.type === ProjectItemType.SUBMODEL)
    .map((item) => item.name);
  const defaultDuplicateNameStarter = t({
    id: 'duplicateHLBModal.defaultDuplicateHLBName.format',
    message: '{hlbName} copy',
    values: {
      hlbName,
    },
  });
  const defaultDuplicateName = getNextValidIdentifier(
    defaultDuplicateNameStarter,
    sameTypeFiles,
  );

  const { duplicateSubmodel } = useDuplicateSubmodel();
  const onDuplicate = async (
    newName: string,
    destinationProjectUuid: string,
  ) => {
    const submodel = await duplicateSubmodel({
      projectUuid: project.uuid,
      submodelUuid: hlb.id,
      submodelDuplicateRequest: {
        name: newName,
        destination_project_uuid: destinationProjectUuid,
      },
    });
    closeModal();
    if (submodel) {
      navigate(`/projects/${submodel.project_uuid}/models/${submodel.uuid}`);
    }
  };

  const triggerDuplicateModal = () => {
    triggerModal(
      <DuplicateModal
        itemType={ProjectItemType.SUBMODEL}
        initialDestinationProjectUuid={project.uuid}
        defaultDuplicateName={defaultDuplicateName}
        extension=""
        onDuplicate={onDuplicate}
        description={DESC}
      />,
      t({
        id: 'DuplicateHighLevelBlockModal.title',
        message: 'Duplicate and edit',
      }),
    );
  };
  return (
    <Button
      onClick={triggerDuplicateModal}
      Icon={Copy}
      testId="Duplicate-hlb-button"
      tint={tint}
      variant={ButtonVariants.LargePrimary}>
      {t({
        id: 'DuplicateHighLevelBlockButton.Label',
        message: 'Duplicate and edit',
      })}
    </Button>
  );
};

export default DuplicateHighLevelBlockButton;
