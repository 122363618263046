import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
  ModelRestoreState,
  modelMetadataActions,
} from 'app/slices/modelMetadataSlice';
import { projectActions } from 'app/slices/projectSlice';
import React from 'react';

export const ModelRestoreTracker: React.FC = () => {
  const dispatch = useAppDispatch();

  // If we are restoring the state of the current model, make sure the
  // reload is triggered once we have validated the metadata for the submodel or model.
  const modelRestoreState = useAppSelector(
    (state) => state.modelMetadata.modelRestoreState,
  );

  React.useEffect(() => {
    if (modelRestoreState === ModelRestoreState.TriggeringOpenRestoredModel) {
      dispatch(projectActions.requestReloadModel());
      dispatch(modelMetadataActions.finishTriggeringOpenRestoredModel());
    }
  }, [dispatch, modelRestoreState]);

  return null;
};
