import React from 'react';

function stopRefTimer(timerIdRef: React.MutableRefObject<any>) {
  if (timerIdRef.current) {
    clearTimeout(timerIdRef.current);
    timerIdRef.current = null;
  }
}

/**
 * Exposes the same functionality as setTimeout with some additional cleanup:
 *  - The timer is cancelled when the component is removed.
 *  - When startTimer is called, if a timer is running, it is cancelled,
 *    ensuring that only the most recent timer is running at any time.
 */
export function useTimer() {
  // Use "any" type because typescript dts files for
  // setTimeout return type are not correct.
  const timerIdRef = React.useRef<any>(null);

  // Make sure the timer is stopped when the component
  // is removed.
  React.useEffect(
    () => () => {
      stopRefTimer(timerIdRef);
    },
    [],
  );

  const startTimer = React.useCallback(
    (timeoutHandler: () => void, timeoutInMs: number) => {
      stopRefTimer(timerIdRef);
      timerIdRef.current = setTimeout(timeoutHandler, timeoutInMs);
    },
    [],
  );

  const stopTimer = React.useCallback(() => {
    stopRefTimer(timerIdRef);
  }, []);

  return {
    startTimer,
    stopTimer,
  };
}
