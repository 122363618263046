/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const ArrowDown: React.FC<Props> = (props) => (
  <svg viewBox="0 0 24 24" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M12 6.25C12.4142 6.25 12.75 6.58579 12.75 7V15.1893L16.4697 11.4697C16.7626 11.1768 17.2374 11.1768 17.5303 11.4697C17.8232 11.7626 17.8232 12.2374 17.5303 12.5303L12 18.0607L6.46967 12.5303C6.17678 12.2374 6.17678 11.7626 6.46967 11.4697C6.76256 11.1768 7.23744 11.1768 7.53033 11.4697L11.25 15.1893V7C11.25 6.58579 11.5858 6.25 12 6.25Z"
      fill={props.fill}
    />
  </svg>
);

ArrowDown.defaultProps = {
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default ArrowDown;
