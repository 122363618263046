/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  className?: string;
}

const RandomdistributionPoisson: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path d="M2.25 25.75V6H1.75V26.25H30V25.75H2.25Z" fill={props.fill} />
    <path d="M6 25H3.5V20H6V25Z" fill={props.fill} />
    <path d="M8 25H10.5V12H8V25Z" fill={props.fill} />
    <path d="M19.5 25H17V14H19.5V25Z" fill={props.fill} />
    <path d="M12.5 25H15V7H12.5V25Z" fill={props.fill} />
    <path d="M24 25H21.5V17H24V25Z" fill={props.fill} />
    <path d="M26 25H28.5V20H26V25Z" fill={props.fill} />
  </svg>
);

RandomdistributionPoisson.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  className: '',
};

export default RandomdistributionPoisson;
