import { useAppDispatch, useAppSelector } from 'app/hooks';
import { modelVersionsActions } from 'app/slices/modelVersionsSlice';
import React from 'react';
import { Spinner } from 'ui/common/Spinner';
import { ModelSimulationSignalGroupListItem } from 'ui/dataExplorer/simulationDataSidebar/ModelSimulationSignalGroupListItem';
import { ModelSimulationSignalListItem } from 'ui/dataExplorer/simulationDataSidebar/ModelSimulationSignalListItem';
import { SimulationDataModelSimulationSubmodelItem } from 'ui/dataExplorer/simulationDataSidebar/SimulationDataModelSimulationSubmodelItem';
import { SingleLineSpinnerWrapper } from 'ui/objectBrowser/sections/ReferenceSubmodelTreeContent';
import { buildNodeInfoForModelVersion } from 'util/modelVersionSignal';
import { SignalData, SignalsList } from 'util/simulationSignalsTree';

interface Props {
  modelId: string;
  modelVersionId: string;
  simulationId: string;
  canEditProject: boolean;
  signalsList: SignalsList;
}

export const SimulationDataModelSimulationSignalList: React.FC<Props> = ({
  modelId,
  modelVersionId,
  simulationId,
  canEditProject,
  signalsList,
}) => {
  const dispatch = useAppDispatch();

  const modelIdToVersionIdToModelData = useAppSelector(
    (state) => state.modelVersions.modelIdToVersionIdToModelData,
  );
  const modelIdToVersionIdRequestsCompleted = useAppSelector(
    (state) =>
      Object.values(state.modelVersions.modelIdToVersionIdRequestData)
        .length === 0,
  );

  // Find the associated parent node in the model version so we
  // can find signal names (which we use as display names for signals when
  // they are available and so we can know what type each submodel is and
  // display them with the correct icon.)
  const { modelVersionToRequest, node } = React.useMemo(
    () =>
      buildNodeInfoForModelVersion(
        signalsList.parentNamePath,
        modelId,
        modelVersionId,
        modelIdToVersionIdToModelData,

        // modelIdToVersionIdRequestsCompleted appears to be an extra dependency
        // but it is needed in order to know when the modelIdToVersionIdToModelData
        // is actually updated which this data depends on.
        // TODO find a cleaner way to do this.
        /* eslint-disable react-hooks/exhaustive-deps */
      ),
    [
      modelId,
      modelVersionId,
      modelIdToVersionIdToModelData,
      signalsList.parentNamePath,
      modelIdToVersionIdRequestsCompleted,
    ],
  );

  React.useEffect(() => {
    if (modelVersionToRequest) {
      dispatch(modelVersionsActions.requestModelVersion(modelVersionToRequest));
    }
  }, [dispatch, modelVersionToRequest]);

  if (modelVersionToRequest) {
    return (
      <SingleLineSpinnerWrapper>
        <Spinner />
      </SingleLineSpinnerWrapper>
    );
  }

  return (
    <>
      {/* Direct child signals */}
      {signalsList.signals.map((signal: SignalData) =>
        signal.vectorIndices ? (
          <ModelSimulationSignalGroupListItem
            key={signal.signalPath}
            modelId={modelId}
            modelVersionId={modelVersionId}
            simulationId={simulationId}
            signalPath={signal.signalPath}
            parentCount={signalsList.parentCount}
            defaultDisplayName={signal.displayName}
            hasCsvSupport={signal.hasCsvSupport}
            canEditProject={canEditProject}
            parentNode={node}
            vectorIndices={signal.vectorIndices}
          />
        ) : (
          <ModelSimulationSignalListItem
            key={signal.signalPath}
            modelId={modelId}
            modelVersionId={modelVersionId}
            simulationId={simulationId}
            signalPath={signal.signalPath}
            parentCount={signalsList.parentCount}
            defaultDisplayName={signal.displayName}
            hasCsvSupport={signal.hasCsvSupport}
            canEditProject={canEditProject}
            parentNode={node}
          />
        ),
      )}

      {/* Submodels that contain more signals */}
      {Object.values(signalsList.submodelNameToSignalsList).map(
        (signalsList: SignalsList) => (
          <SimulationDataModelSimulationSubmodelItem
            key={signalsList.displayName}
            modelId={modelId}
            modelVersionId={modelVersionId}
            simulationId={simulationId}
            signalsList={signalsList}
            canEditProject={canEditProject}
          />
        ),
      )}
    </>
  );
};
