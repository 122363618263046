import { useModelSimulations } from 'app/api/useModelSimulations';
import { SimulationSummaryUI } from 'app/apiTransformers/convertGetSimulationReadAll';
import React from 'react';
import { Spinner } from 'ui/common/Spinner';
import { SimulationDataModelSimulationItem } from 'ui/dataExplorer/simulationDataSidebar/SimulationDataModelSimulationItem';
import { SingleLineSpinnerWrapper } from 'ui/objectBrowser/sections/ReferenceSubmodelTreeContent';

interface Props {
  modelId: string;
  canEditProject: boolean;
}

export const SimulationDataModelSimulationList: React.FC<Props> = ({
  modelId,
  canEditProject,
}) => {
  const { modelSimulations, isLoadingModelSimulations } =
    useModelSimulations(modelId);

  if (isLoadingModelSimulations) {
    return (
      <SingleLineSpinnerWrapper>
        <Spinner />
      </SingleLineSpinnerWrapper>
    );
  }

  if (!modelSimulations) {
    return null;
  }

  return (
    <>
      {modelSimulations.map((simulation: SimulationSummaryUI, i) => (
        <SimulationDataModelSimulationItem
          key={simulation.id}
          modelId={modelId}
          simulationIndex={i}
          simulation={simulation}
          canEditProject={canEditProject}
        />
      ))}
    </>
  );
};
