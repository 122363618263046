/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const MatrixoperatorExtraction: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2 8.25C1.30964 8.25 0.75 8.80964 0.75 9.5V22.5C0.75 23.1904 1.30964 23.75 2 23.75H4.5C4.91421 23.75 5.25 23.4142 5.25 23C5.25 22.5858 4.91421 22.25 4.5 22.25H2.25V9.75H4.5C4.91421 9.75 5.25 9.41421 5.25 9C5.25 8.58579 4.91421 8.25 4.5 8.25H2Z"
      fill={props.fill}
    />
    <path
      d="M31.25 9.5C31.25 8.80964 30.6904 8.25 30 8.25H27.5C27.0858 8.25 26.75 8.58579 26.75 9C26.75 9.41421 27.0858 9.75 27.5 9.75H29.75V22.25H27.5C27.0858 22.25 26.75 22.5858 26.75 23C26.75 23.4142 27.0858 23.75 27.5 23.75H30C30.6904 23.75 31.25 23.1904 31.25 22.5V9.5Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M9.584 20C9.504 20 9.452 19.96 9.428 19.88L9.032 18.596C9.016 18.564 8.996 18.548 8.972 18.548H5.804C5.78 18.548 5.76 18.564 5.744 18.596L5.348 19.88C5.324 19.96 5.272 20 5.192 20H3.992C3.944 20 3.908 19.988 3.884 19.964C3.86 19.932 3.856 19.888 3.872 19.832L6.476 11.72C6.5 11.64 6.552 11.6 6.632 11.6H8.132C8.212 11.6 8.264 11.64 8.288 11.72L10.904 19.832C10.912 19.848 10.916 19.868 10.916 19.892C10.916 19.964 10.872 20 10.784 20H9.584ZM6.116 17.384C6.108 17.432 6.124 17.456 6.164 17.456H8.6C8.648 17.456 8.664 17.432 8.648 17.384L7.412 13.364C7.404 13.332 7.392 13.316 7.376 13.316C7.36 13.316 7.348 13.332 7.34 13.364L6.116 17.384Z"
      fill={props.fill}
    />
    <path
      d="M12.2029 17.8168C12.0541 17.8168 11.9317 17.7688 11.8357 17.6728C11.7396 17.5768 11.6917 17.4544 11.6917 17.3056C11.6917 17.1568 11.7396 17.0344 11.8357 16.9384C11.9317 16.8424 12.0541 16.7944 12.2029 16.7944C12.3517 16.7944 12.4741 16.8424 12.5701 16.9384C12.666 17.0344 12.7141 17.1568 12.7141 17.3056C12.7141 17.4544 12.666 17.5768 12.5701 17.6728C12.4741 17.7688 12.3517 17.8168 12.2029 17.8168Z"
      fill={props.fill}
    />
    <path
      d="M11.8573 22C11.8333 22 11.8117 21.9928 11.7924 21.9784C11.7781 21.9592 11.7709 21.9376 11.7709 21.9136V18.4C11.7709 18.376 11.7781 18.3568 11.7924 18.3424C11.8117 18.3232 11.8333 18.3136 11.8573 18.3136H12.5197C12.5436 18.3136 12.5628 18.3232 12.5773 18.3424C12.5964 18.3568 12.6061 18.376 12.6061 18.4V21.9136C12.6061 21.9376 12.5964 21.9592 12.5773 21.9784C12.5628 21.9928 12.5436 22 12.5197 22H11.8573Z"
      fill={props.fill}
    />
    <path
      d="M15.2404 16.1C14.9604 16.1 14.7284 16.012 14.5444 15.836C14.3604 15.652 14.2684 15.428 14.2684 15.164C14.2684 14.916 14.3644 14.704 14.5564 14.528C14.7564 14.344 14.9844 14.252 15.2404 14.252C15.4964 14.252 15.7204 14.344 15.9124 14.528C16.1124 14.704 16.2124 14.916 16.2124 15.164C16.2124 15.42 16.1164 15.64 15.9244 15.824C15.7404 16.008 15.5124 16.1 15.2404 16.1Z"
      fill={props.fill}
    />
    <path
      d="M14.4844 21.068C14.4364 21.068 14.3964 21.052 14.3644 21.02C14.3404 20.996 14.3324 20.96 14.3404 20.912L14.6404 18.284C14.6484 18.196 14.6964 18.152 14.7844 18.152H15.7564C15.8684 18.152 15.9124 18.204 15.8884 18.308L15.2884 20.936C15.2644 21.024 15.2124 21.068 15.1324 21.068H14.4844Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M23.1898 20C23.1098 20 23.0578 19.96 23.0338 19.88L22.6378 18.596C22.6218 18.564 22.6018 18.548 22.5778 18.548H19.4098C19.3858 18.548 19.3658 18.564 19.3498 18.596L18.9538 19.88C18.9298 19.96 18.8778 20 18.7978 20H17.5978C17.5498 20 17.5138 19.988 17.4898 19.964C17.4658 19.932 17.4618 19.888 17.4778 19.832L20.0818 11.72C20.1058 11.64 20.1578 11.6 20.2378 11.6H21.7378C21.8178 11.6 21.8698 11.64 21.8938 11.72L24.5098 19.832C24.5178 19.848 24.5218 19.868 24.5218 19.892C24.5218 19.964 24.4778 20 24.3898 20H23.1898ZM19.7218 17.384C19.7138 17.432 19.7298 17.456 19.7698 17.456H22.2058C22.2538 17.456 22.2698 17.432 22.2538 17.384L21.0178 13.364C21.0098 13.332 20.9978 13.316 20.9818 13.316C20.9658 13.316 20.9538 13.332 20.9458 13.364L19.7218 17.384Z"
      fill={props.fill}
    />
    <path
      d="M25.7799 17.8168C25.6311 17.8168 25.5087 17.7688 25.4127 17.6728C25.3167 17.5768 25.2687 17.4544 25.2687 17.3056C25.2687 17.1568 25.3167 17.0344 25.4127 16.9384C25.5087 16.8424 25.6311 16.7944 25.7799 16.7944C25.9287 16.7944 26.0511 16.8424 26.1471 16.9384C26.2431 17.0344 26.2911 17.1568 26.2911 17.3056C26.2911 17.4544 26.2431 17.5768 26.1471 17.6728C26.0511 17.7688 25.9287 17.8168 25.7799 17.8168Z"
      fill={props.fill}
    />
    <path
      d="M24.6063 23.476C24.5487 23.476 24.5199 23.4472 24.5199 23.3896V22.8568C24.5199 22.7992 24.5511 22.7728 24.6135 22.7776C24.8871 22.7824 25.0815 22.732 25.1967 22.6264C25.3119 22.5256 25.3695 22.3576 25.3695 22.1224L25.3623 18.4072C25.3623 18.3832 25.3695 18.364 25.3839 18.3496C25.4031 18.3304 25.4247 18.3208 25.4487 18.3208H26.1111C26.1351 18.3208 26.1543 18.3304 26.1687 18.3496C26.1879 18.364 26.1975 18.3832 26.1975 18.4072V22.1152C26.1975 22.4656 26.1471 22.7392 26.0463 22.936C25.9503 23.1328 25.7871 23.2744 25.5567 23.3608C25.3311 23.4472 25.0143 23.4856 24.6063 23.476Z"
      fill={props.fill}
    />
  </svg>
);

MatrixoperatorExtraction.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default MatrixoperatorExtraction;
