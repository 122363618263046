import * as NVG from 'nanovg-js';
import { nvgColorToCSS } from 'util/nvgColorHexConvert';
import { RendererState } from './modelRenderer';
import {
  getCanvas2dColoredImage,
  RasterLoadState,
  rasterMetaStore,
} from './rasterTextureStore';

// TODO: Add common function that loads, computes scale, etc...

export function drawImage(
  rs: RendererState,
  nvg: NVG.Context,
  rasterId: string,
  rx: number,
  ry: number,
  rw: number,
  rh: number,
  angle = 0,
  alpha = 1,
  color?: NVG.Color,
) {
  const rasterMeta = rasterMetaStore[rasterId];
  if (!rasterMeta || rasterMeta.loadState !== RasterLoadState.Loaded) return;

  if (!rs.refs.current.uiFlags.renderCanvas2d) {
    const imgPaint = nvg.imagePattern(rx, ry, rw, rh, 0, rasterMeta.imageId, 1);

    // Hack: NanoVG supports color multiplication as below, but the TS API
    // does not explicitly expose it. This works well though. There are various
    // ways to apply alpha (eg. use alphaColor here and apply alpha to
    // the outerColor) or use globalAlpha. I did not have good test images &
    // colors to see which is the "most correct" way.
    if (color) {
      (imgPaint.innerColor as NVG.NVGcolor) = color;
      (imgPaint.outerColor as NVG.NVGcolor) = NVG.RGBAf(1, 1, 1, 1);
    }

    nvg.save();
    nvg.beginPath();
    nvg.globalAlpha(alpha);
    nvg.rect(rx, ry, rw, rh);
    nvg.fillPaint(imgPaint);
    nvg.fill();
    nvg.restore();
  } else {
    const ctx = rs.refs.current.overlayCanvas2dCtx;
    const pr = window.devicePixelRatio;
    const image = color
      ? getCanvas2dColoredImage(rasterId, nvgColorToCSS(color))
      : rasterMeta.canvas2dImage;
    if (ctx && image) {
      const flipped = rw < 0;

      ctx.save();
      ctx.globalAlpha = alpha;
      ctx.translate((rx + (flipped ? rw : 0)) * pr, ry * pr);
      if (flipped) ctx.scale(-1, 1);
      ctx.rotate(angle);
      ctx.drawImage(image, 0, 0, rw * pr, rh * pr);
      ctx.restore();
    }
  }
}
