import styled from '@emotion/styled/macro';
import { Coordinate } from 'app/common_types/Coordinate';
import { StateNodeInstance } from 'app/generated_types/SimulationModel';
import { useAppDispatch } from 'app/hooks';
import { modelActions } from 'app/slices/modelSlice';
import React, { MutableRefObject } from 'react';
import Input from 'ui/common/Input/Input';
import { SMERefsObjTy, StateNodeSide } from './SMETypes';
import { SMEAllMouseEvent } from './StateMachineEdInputManager';

export const STATENODE_WIDTH = 128;
export const STATENODE_HEIGHT = 96;

const StateMachineNodeContainer = styled.div<
  Coordinate & { selected?: boolean }
>(({ x, y, selected }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  position: 'absolute',
  left: Math.floor(x),
  top: Math.floor(y),
  border: `1px solid ${selected ? 'rgb(105, 225, 219)' : 'black'}`,
  width: STATENODE_WIDTH,
  height: STATENODE_HEIGHT,
  padding: 4,
  borderRadius: 8,
  background: 'white',
}));
const StateNodeTitle = styled(Input)`
  width: 100%;

  > input {
    color: black;
    text-align: center;
    padding: 0 8px;
  }
`;
const StateNodeTitleBorder = styled.div({
  width: '100%',
  height: 1,
  flexGrow: 0,
  background: 'black',
});

const closestSide = (x: number, y: number): StateNodeSide => {
  const btmDist = STATENODE_HEIGHT - y;
  const rgtDist = STATENODE_WIDTH - x;

  if (x < STATENODE_WIDTH / 2) {
    if (y < STATENODE_HEIGHT / 2) {
      if (x < y) return 'left';
      return 'top';
    }
    if (x < btmDist) return 'left';
    return 'down';
  }
  if (y < STATENODE_HEIGHT / 2) {
    if (rgtDist < y) return 'right';
    return 'top';
  }
  if (rgtDist < btmDist) return 'right';
  return 'down';
};

type Props = {
  stateMachineId: string;
  node: StateNodeInstance;
  onLink: (nodeId: string, side: StateNodeSide, coord: number) => void;
  onSelect: (nodeId: string) => void;
  selected?: boolean;
  onStartDragNode: (e: SMEAllMouseEvent) => void;
  canSingleClickLink?: boolean;
  readOnly: boolean;
  refsObj: MutableRefObject<SMERefsObjTy>;
};

let singleClickTimer: number | undefined;

export const StateMachineNodeUI = ({
  node,
  onLink,
  stateMachineId,
  onSelect,
  selected,
  onStartDragNode,
  canSingleClickLink,
  readOnly,
  refsObj,
}: Props) => {
  const dispatch = useAppDispatch();

  const selectLock = React.useRef<boolean>(false);
  const mouseDrag = React.useCallback(
    (e: SMEAllMouseEvent) => {
      onStartDragNode(e);
      document.removeEventListener('mousemove', mouseDrag);
      selectLock.current = true;
    },
    [onStartDragNode],
  );

  const mouseUp = React.useCallback(
    (e: MouseEvent) => {
      e.stopPropagation();
      if (readOnly) return;
      document.removeEventListener('mouseup', mouseUp);
      document.removeEventListener('mousemove', mouseDrag);

      const doOnLink = () => {
        const { left: offX, top: offY } = (
          e.target as HTMLDivElement
        ).getBoundingClientRect();
        const x = (e.clientX - offX) / refsObj.current.camera.zoom;
        const y = (e.clientY - offY) / refsObj.current.camera.zoom;

        const fromSide = closestSide(x, y);
        const coordOnXAxis = fromSide === 'top' || fromSide === 'down';

        onLink(node.uuid, fromSide, coordOnXAxis ? x : y);
      };

      if (e.detail === 1) {
        singleClickTimer = window.setTimeout(() => {
          // handle state-node single-click
          if (canSingleClickLink) {
            doOnLink();
          }
          if (selectLock.current === false) {
            onSelect(node.uuid);
          } else {
            selectLock.current = false;
          }
        }, 200);
      }

      if (e.detail === 2) {
        clearTimeout(singleClickTimer);
        doOnLink();
      }

      dispatch(
        modelActions.snapStateNodesToGrid({
          stateMachineUuid: stateMachineId,
        }),
      );
    },
    [
      dispatch,
      mouseDrag,
      onLink,
      canSingleClickLink,
      node.uuid,
      onSelect,
      readOnly,
      refsObj,
      stateMachineId,
    ],
  );
  const mouseDown = React.useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      if (!selected) {
        onSelect(node.uuid);
      }
      if (readOnly) return;
      document.addEventListener('mouseup', mouseUp);
      document.addEventListener('mousemove', mouseDrag);
    },
    [mouseDrag, mouseUp, readOnly, selected, onSelect, node.uuid],
  );

  const changeNodeName = React.useCallback(
    (newName: string) =>
      dispatch(
        modelActions.changeStateNodeName({
          stateMachineUuid: stateMachineId,
          nodeId: node.uuid,
          newName,
        }),
      ),
    [dispatch, node.uuid, stateMachineId],
  );

  return (
    <StateMachineNodeContainer
      selected={selected}
      onMouseDown={mouseDown}
      onClick={(e) => e.stopPropagation()}
      {...node.uiprops}>
      <StateNodeTitle
        value={node.name}
        onKeyDown={(e) => e.stopPropagation()}
        onMouseDown={(e) => e.stopPropagation()}
        onChangeText={changeNodeName}
        disabled={readOnly}
      />
      <StateNodeTitleBorder />
    </StateMachineNodeContainer>
  );
};
