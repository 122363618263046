import styled from '@emotion/styled/macro';
import { useAppSelector } from 'app/hooks';
import { DiagramFooterTab } from 'app/slices/uiFlagsSlice';
import React, { useState } from 'react';
import { Output } from 'ui/appBottomBar/Output';
import { Spinner, SpinnerWrapper } from 'ui/common/Spinner';
import { SimworkerJobRunnerProvider } from '../../app/SimworkerJobRunner';
import ChatGpt from './Assistant';
import Console from './Console';
import MediaVisualizer from './MediaVisualizer';

const VisualizerLoader = styled(SpinnerWrapper)(({ theme }) => ({
  backgroundColor: theme.colors.grey[5],
}));

interface Props {
  isFooterOpen: boolean;
}

const LazyVisualizer = React.lazy(
  () =>
    import(
      /* webpackChunkName: "visualizer" */
      '../modelEditor/Visualizer/Visualizer'
    ),
);

const PYTHON_BANNER = 'Welcome to the Python terminal emulator 🐍\n';

const DiagramFooterContent: React.FC<Props> = ({ isFooterOpen }) => {
  const diagramFooterTab = useAppSelector(
    (state) => state.uiFlags.diagramFooterTab,
  );

  // Console state persists throughout a model editing session.
  const [input, setInput] = useState('');
  const [output, setOutput] = useState(PYTHON_BANNER);

  if (!isFooterOpen) {
    // TODO: persist the components when invisible. closing the tab mid-api call will miss any callbacks.

    // TODO: existing bug. transition is useless on close.
    // add fallback panel so that it doesn't flash the background editor on close
    return null;
  }

  return (
    <React.Suspense
      fallback={
        <VisualizerLoader>
          <Spinner />
        </VisualizerLoader>
      }>
      {diagramFooterTab === DiagramFooterTab.Visualizer && <LazyVisualizer />}

      {diagramFooterTab === DiagramFooterTab.Output && <Output />}

      {diagramFooterTab === DiagramFooterTab.Console && (
        <Console
          input={input}
          setInput={setInput}
          output={output}
          setOutput={setOutput}
        />
      )}

      <SimworkerJobRunnerProvider>
        <ChatGpt hidden={diagramFooterTab !== DiagramFooterTab.AiAssistant} />
        {diagramFooterTab === DiagramFooterTab.MediaVisualizer && (
          <MediaVisualizer />
        )}
      </SimworkerJobRunnerProvider>
    </React.Suspense>
  );
};

export default DiagramFooterContent;
