import styled from '@emotion/styled';
import React, { DragEvent, useState } from 'react';

const Zone = styled.div`
  height: 100%;
  &.dragover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors.grey[10]};
  }
`;

export interface Props {
  onDrop: (files: File[]) => void;
  recurse?: boolean;
  children: React.ReactNode;
}

export const DropZone: React.FC<Props> = ({ onDrop, recurse, children }) => {
  const [isDragging, setIsDragging] = useState(false);

  function processFile(
    fileSystemFileEntry: FileSystemFileEntry,
  ): Promise<File[]> {
    return new Promise((resolve) => {
      fileSystemFileEntry.file((file) => {
        // https://stackoverflow.com/questions/73872588/file-object-resulting-from-filesystemfileentry-file-call-is-missing-webkitre
        Object.defineProperty(file, 'webkitRelativePath', {
          value: fileSystemFileEntry.fullPath.slice(1),
        });
        resolve([file]);
      });
    });
  }

  const processDirectory = React.useCallback(
    (directory: FileSystemDirectoryEntry, depth: number) => {
      let dirReader = directory.createReader();

      if (depth > 3) {
        return Promise.reject(new Error('Directory depth too great'));
      }

      function readEntries(): Promise<File[]> {
        return new Promise((resolve) => {
          dirReader.readEntries((entries) => {
            if (entries.length > 0) {
              const entryPromises = Array.from(entries).map((entry) => {
                if (entry.isDirectory) {
                  return processDirectory(
                    entry as FileSystemDirectoryEntry,
                    depth + 1,
                  );
                }

                if (entry.isFile) {
                  return processFile(entry as FileSystemFileEntry);
                }

                return Promise.reject(new Error('Not a file or directory'));
              });
              Promise.all(entryPromises).then((files) => resolve(files.flat()));
            } else {
              resolve([]);
            }
          });
        });
      }

      return readEntries();
    },
    [],
  );

  const processEntry = React.useCallback(
    (entry: FileSystemEntry, recurse: boolean, depth = 0): Promise<File[]> => {
      if (entry.isDirectory) {
        if (!recurse) {
          return Promise.reject(
            new Error(
              'Directory dropped but recursive traversal is not allowed',
            ),
          );
        }
        return processDirectory(entry as FileSystemDirectoryEntry, depth + 1);
      }

      if (entry.isFile) {
        return processFile(entry as FileSystemFileEntry);
      }

      return Promise.reject(new Error('Not a file or directory'));
    },
    [processDirectory],
  );

  const handleItems = React.useCallback(
    (items: DataTransferItem[], recurse: boolean): Promise<File[]> => {
      const promises: Promise<File[]>[] = items
        .map((item) => item.webkitGetAsEntry())
        .filter((item) => !!item)
        .map((entry) => processEntry(entry as FileSystemEntry, recurse));

      return Promise.all(promises).then((files) => files.flat());
    },
    [processEntry],
  );

  const handleDragEnter = (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };

  const handleDragOver = (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDrop = React.useCallback(
    (e: DragEvent) => {
      e.preventDefault();
      e.stopPropagation();
      setIsDragging(false);

      const items = Array.from(e.dataTransfer.items);
      handleItems(items, !!recurse).then(onDrop).catch(console.error);
    },
    [onDrop, recurse, handleItems],
  );

  return (
    <Zone
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      className={isDragging ? 'dragover' : ''}>
      {children}
    </Zone>
  );
};
