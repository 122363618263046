import { t } from '@lingui/macro';
import {
  useDeleteRequirementLinkMutation,
  useGetRequirementBlockByLocationQuery,
  useGetRequirementsQuery,
  usePostRequirementLinkCreateMutation,
} from 'app/apiGenerated/generatedApi';
import { useEffect, useState } from 'react';
import SectionHeading from 'ui/common/Inputs/SectionHeading';
import SelectInput, { SelectInputOption } from 'ui/common/SelectInput';
import { useNotifications } from 'ui/common/notifications/useNotifications';
import {
  DetailInputRowsSection,
  DetailsLabel,
  DetailsSection,
} from '../DetailsComponents';

type RequirementsSelectorProps = {
  projectUuid: string;
  modelId: string;
  blockInstanceUuid: string;
  canEdit: boolean;
};

const RequirementsSelector = ({
  projectUuid,
  modelId,
  blockInstanceUuid,
  canEdit,
}: RequirementsSelectorProps) => {
  const { showError } = useNotifications();
  const { data, isLoading: isLoadingReqs } = useGetRequirementsQuery({
    projectUuid,
  });
  const reqs = data?.items ?? [];

  const {
    data: block,
    isLoading: isLoadingBlock,
    isFetching: isFetchingBlock,
  } = useGetRequirementBlockByLocationQuery({
    projectUuid,
    modelId,
    blockInstanceUuid,
  });

  const [createLink] = usePostRequirementLinkCreateMutation();
  const [deleteLink] = useDeleteRequirementLinkMutation();

  // TODO: Move into a loop when we have more than one requirement
  // Deletes will be through to the X button instead of deselect.. probably. Makes consistent ordering a lot easier.
  const link = block?.requirement_links?.[0];
  const reqId = link ? link.requirement_id : '';

  // For optimistic updates
  const [selectedReqId, setSelectedReqId] = useState(reqId);
  const [isUpdatingTraceability, setIsUpdatingTraceability] = useState(false);
  useEffect(() => {
    if (isUpdatingTraceability || isFetchingBlock) return;
    // Network calls have finished, update the selected req to the server source of truth
    setSelectedReqId(reqId);
  }, [reqId, isUpdatingTraceability, isFetchingBlock]);

  if (isLoadingBlock || isLoadingReqs) {
    <div>
      <SectionHeading testId="block-title">Requirements</SectionHeading>
      <DetailInputRowsSection>
        <DetailsSection
          title={t({
            id: 'modelRenderer.requirementsSelector.title',
            message: 'The requirement this block satisfies',
          })}
          vertical>
          <DetailsLabel disabled={!canEdit}>
            {t({
              id: 'modelRenderer.requirementsSelector.label',
              message: 'Satisfies',
            })}
          </DetailsLabel>
          <SelectInput options={[]} />
        </DetailsSection>
      </DetailInputRowsSection>
    </div>;
  }

  const requirementOptions: SelectInputOption[] = reqs.map((req) => ({
    value: req.id,
    label: `${req.object_number}-${req.object_heading}`,
  }));

  // The 'none' option and current val validation should be handled by the SelectInput component.
  // But this is what we have.
  requirementOptions.push({
    value: '',
    label: t({
      id: 'modelRenderer.requirementsSelector.noLink.label',
      message: '(None)',
    }),
  });
  const invalidLink = !!reqId && !reqs.some((req) => req.id === reqId);

  const onSelectRequirement = async (reqId: string, prevReqId?: string) => {
    if (reqId === prevReqId) return;
    // Optimistic update
    setSelectedReqId(reqId);
    setIsUpdatingTraceability(true);
    try {
      if (prevReqId) {
        if (!link) {
          console.error(
            `No requirement link that matches the previous id: ${prevReqId}`,
          );
        } else {
          await deleteLink({
            projectUuid,
            requirementLinkId: link.id,
          }).unwrap();
        }
      }
      if (reqId) {
        await createLink({
          projectUuid,
          modelId,
          blockInstanceUuid,
          requirementId: reqId,
        }).unwrap();
      }
    } catch (e) {
      showError('Error linking requirement: ', e);
    } finally {
      setIsUpdatingTraceability(false);
    }
  };

  return (
    <>
      <SectionHeading testId="block-title">Requirements</SectionHeading>
      <DetailInputRowsSection>
        <DetailsSection
          title={t({
            id: 'modelRenderer.requirementsSelector.title',
            message: 'The requirement this block satisfies',
          })}
          vertical>
          <DetailsLabel>
            {t({
              id: 'modelRenderer.requirementsSelector.label',
              message: 'Satisfies',
            })}
          </DetailsLabel>
          <SelectInput
            currentValue={selectedReqId}
            options={requirementOptions}
            onSelectValue={onSelectRequirement}
            isInvalid={invalidLink}
            isDisabled={!canEdit}
          />
        </DetailsSection>
      </DetailInputRowsSection>
    </>
  );
};

export default RequirementsSelector;
