/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Variant: React.FC<Props> = (props) => (
  <svg viewBox="0 0 24 24" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M7.65811 3.78243C7.55548 3.74821 7.44452 3.74821 7.34189 3.78243L4.09189 4.86576C3.88771 4.93382 3.75 5.12489 3.75 5.3401V9.65991C3.75 9.87513 3.88772 10.0662 4.09189 10.1343L7.34189 11.2176C7.44452 11.2518 7.55548 11.2518 7.65811 11.2176L10.9081 10.1343C11.1123 10.0662 11.25 9.87513 11.25 9.65991V5.3401C11.25 5.12489 11.1123 4.93382 10.9081 4.86576L7.65811 3.78243ZM4.5 9.47972V5.77029L7.125 6.64529V10.3547L4.5 9.47972ZM7.875 10.3547V6.64529L10.5 5.77029V9.47972L7.875 10.3547ZM7.5 5.97972L9.68915 5.25001L7.5 4.52029L5.31085 5.25001L7.5 5.97972Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M16.6581 3.78243C16.5555 3.74821 16.4445 3.74821 16.3419 3.78243L13.0919 4.86576C12.8877 4.93382 12.75 5.12489 12.75 5.3401V9.65991C12.75 9.87513 12.8877 10.0662 13.0919 10.1343L16.3419 11.2176C16.4445 11.2518 16.5555 11.2518 16.6581 11.2176L19.9081 10.1343C20.1123 10.0662 20.25 9.87513 20.25 9.65991V5.3401C20.25 5.12489 20.1123 4.93382 19.9081 4.86576L16.6581 3.78243ZM13.5 6.10472V5.77029L16.125 6.64529V6.97972L13.5 6.10472ZM16.875 6.97972V6.64529L19.5 5.77029V6.10472L16.875 6.97972ZM16.5 5.97972L18.6891 5.25001L18.1875 5.08279L15.9984 5.81251L16.5 5.97972ZM16.5 4.52029L17.0016 4.68751L14.8125 5.41722L14.3109 5.25001L16.5 4.52029ZM16.875 7.77029L19.5 6.89529V7.26613L16.875 8.11047V7.77029ZM16.875 8.89831L19.5 8.05397V8.37293L16.875 9.2326V8.89831ZM16.875 10.0218L19.5 9.16212V9.47972L16.875 10.3547V10.0218ZM16.125 10.0203V10.3547L13.5 9.47972V9.14529L16.125 10.0203ZM16.125 9.22972L13.5 8.35472V8.02029L16.125 8.89529V9.22972ZM16.125 8.10472L13.5 7.22972V6.89529L16.125 7.77029V8.10472Z"
      fill={props.fill}
    />
    <path
      d="M7.125 16.5C7.125 16.9142 6.78921 17.25 6.375 17.25C5.96079 17.25 5.625 16.9142 5.625 16.5C5.625 16.0858 5.96079 15.75 6.375 15.75C6.78921 15.75 7.125 16.0858 7.125 16.5Z"
      fill={props.fill}
    />
    <path
      d="M4.5 17.625C4.5 17.2108 4.83579 16.875 5.25 16.875C5.66421 16.875 6 17.2108 6 17.625C6 18.0392 5.66421 18.375 5.25 18.375C4.83579 18.375 4.5 18.0392 4.5 17.625Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M7.65811 12.7824L10.9081 13.8658C11.1123 13.9338 11.25 14.1249 11.25 14.3401V18.6599C11.25 18.8751 11.1123 19.0662 10.9081 19.1343L7.65811 20.2176C7.55548 20.2518 7.44452 20.2518 7.34189 20.2176L4.09189 19.1343C3.88772 19.0662 3.75 18.8751 3.75 18.6599V14.3401C3.75 14.1249 3.88771 13.9338 4.09189 13.8658L7.34189 12.7824C7.44452 12.7482 7.55548 12.7482 7.65811 12.7824ZM4.5 14.7703V15.1003C4.61032 15.0365 4.73839 15 4.875 15C5.28921 15 5.625 15.3358 5.625 15.75C5.625 16.1642 5.28921 16.5 4.875 16.5C4.73839 16.5 4.61032 16.4635 4.5 16.3997L4.5 17.625L4.5 18.4797L7.125 19.3547V19.0247C7.01469 19.0885 6.88661 19.125 6.75 19.125C6.33579 19.125 6 18.7892 6 18.375C6 17.9608 6.33579 17.625 6.75 17.625C6.88661 17.625 7.01469 17.6615 7.125 17.7253V16.5V15.6453L4.5 14.7703ZM7.875 17.1497V18.3743V19.3547L8.57009 19.123C8.18175 19.0949 7.87538 18.7697 7.875 18.3743C7.8754 17.9604 8.21103 17.625 8.625 17.625C9.03921 17.625 9.375 17.9608 9.375 18.375C9.375 18.6006 9.27536 18.803 9.11769 18.9405L10.5 18.4797V18.2747C10.3897 18.3385 10.2616 18.375 10.125 18.375C9.71079 18.375 9.375 18.0392 9.375 17.625C9.375 17.2108 9.71079 16.875 10.125 16.875C10.2616 16.875 10.3897 16.9115 10.5 16.9753V15.75V14.7703L9.80491 15.002C10.1935 15.0301 10.5 15.3543 10.5 15.75C10.5 16.1642 10.1642 16.5 9.75 16.5C9.33579 16.5 9 16.1642 9 15.75C9 15.5244 9.09964 15.322 9.25731 15.1845L7.875 15.6453V15.8503C7.98532 15.7865 8.11339 15.75 8.25 15.75C8.66421 15.75 9 16.0858 9 16.5C9 16.9142 8.66421 17.25 8.25 17.25C8.11339 17.25 7.98532 17.2135 7.875 17.1497ZM6.78733 14.7422C6.7631 14.7053 6.75 14.6659 6.75 14.625C6.75 14.4179 7.08579 14.25 7.5 14.25C7.91421 14.25 8.25 14.4179 8.25 14.625C8.25 14.6659 8.2369 14.7053 8.21267 14.7422L9.68915 14.25L7.5 13.5203L7.45002 13.537C7.70147 13.5975 7.875 13.7261 7.875 13.875C7.875 14.0821 7.53921 14.25 7.125 14.25C6.72416 14.25 6.39677 14.0928 6.37604 13.8949L5.31085 14.25L6.78733 14.7422Z"
      fill={props.fill}
    />
    <path
      d="M16.5 15.375L13.5506 14.3611C13.4143 14.3143 13.4161 14.1208 13.5533 14.0765L16.4223 13.1501C16.4728 13.1338 16.5272 13.134 16.5775 13.1506L19.381 14.0769C19.5169 14.1217 19.5187 14.3133 19.3837 14.3608L16.5 15.375Z"
      fill={props.fill}
    />
    <path
      d="M16.125 15.855L13.4586 14.9084C13.2959 14.8506 13.125 14.9713 13.125 15.144V18.4734C13.125 18.5768 13.1887 18.6695 13.2852 18.7067L15.7852 19.6692C15.9489 19.7322 16.125 19.6113 16.125 19.4359V15.855Z"
      fill={props.fill}
    />
    <path
      d="M16.875 19.4324C16.875 19.6088 17.0528 19.7298 17.2169 19.6649L19.6405 18.7075C19.7359 18.6698 19.7986 18.5776 19.7986 18.475V15.1471C19.7986 14.9735 19.6261 14.8528 19.4631 14.9122L16.875 15.855V19.4324Z"
      fill={props.fill}
    />
  </svg>
);

Variant.defaultProps = {
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Variant;
