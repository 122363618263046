import React from 'react';

const BUTTON_TOOLTIP_OPEN_DELAY_IN_MS = 1000;

/**
 * Note: Do not use a portal tooltip directly on a button or any other
 * element that can be disabled because disabled elements don't emit
 * mouse events, resulting in tooltips that can get stuck open.  If an element will
 * be disabled, wrap it in another element that will not be disabled,
 * and this wrapper element can provide reliable mouse events.
 * See TooltipButton component as an example.
 */
export function usePortalTooltip<TElement extends HTMLElement>() {
  const triggerElRef = React.useRef<TElement | null>(null);
  const timer = React.useRef<ReturnType<typeof setTimeout> | null>(null);
  const [isPopupOpen, setIsPopupOpen] = React.useState(false);

  const cleanup = React.useCallback(() => {
    setIsPopupOpen(false);
    if (timer.current) {
      clearTimeout(timer.current);
      timer.current = null;
    }
  }, [timer, setIsPopupOpen]);

  const onMouseEnter = React.useCallback(() => {
    if (!triggerElRef || !triggerElRef.current) {
      cleanup();
      return;
    }

    if (timer.current) clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      setIsPopupOpen(true);
      timer.current = null;
    }, BUTTON_TOOLTIP_OPEN_DELAY_IN_MS);
  }, [triggerElRef, cleanup]);

  const onMouseLeave = React.useCallback(() => {
    if (timer.current) {
      clearTimeout(timer.current);
      timer.current = null;
    }

    setIsPopupOpen(false);
  }, [timer, setIsPopupOpen]);

  React.useEffect(() => {
    if (!triggerElRef || !triggerElRef.current) {
      console.warn('Unable to find trigger element for tooltip.');
      return;
    }

    const triggerEl: TElement = triggerElRef.current;
    triggerEl.addEventListener('mouseleave', onMouseLeave);
    triggerEl.addEventListener('mouseenter', onMouseEnter);
    return () => {
      triggerEl.removeEventListener('mouseleave', onMouseLeave);
      triggerEl.removeEventListener('mouseenter', onMouseEnter);
      if (!triggerEl.matches(':hover')) cleanup();
    };
  }, [triggerElRef, onMouseEnter, onMouseLeave, cleanup]);

  return {
    triggerElRef,
    isTooltipOpen: isPopupOpen,
    setIsTooltipOpen: setIsPopupOpen,
  };
}
