// !!!! -- DO NOT MANUALLY EDIT -- !!!!
// This file was generated by something in scripts/generateDefaultBlockData.js
// If you want to change the contents of this file, it should be automated
//
// (You can edit this file locally, but it may not be checked into the codebase -
// PRs with a manual edit of this file are forbidden from being merged.)

import { computationBlockDefaults } from 'app/generated_blocks/computationBlockDefaults';
import type { ComputationBlockClass } from 'app/generated_types/ComputationBlockClass';
import deepmerge from 'deepmerge';

export const Stack: ComputationBlockClass = deepmerge(
  computationBlockDefaults,
  {
    "base": {
      "name": "Stack",
      "namespace": "core",
      "description": "Stacks arrays like np.stack.",
      "keywords": [
        "vector",
        "matrix",
        "stack",
        "hstack",
        "vstack",
        "row_stack",
        "column_stack",
        "dstack",
        "join",
        "mux"
      ]
    },
    "modes": {
      "time": "agnostic"
    },
    "ports": {
      "inputs": {
        "dynamic": {
          "min_count": 2,
          "max_count": -1,
          "default_count": 2
        }
      },
      "outputs": {
        "static": [
          {
            "name": "out_0"
          }
        ]
      }
    },
    "parameter_definitions": [
      {
        "name": "axis",
        "description": "Same as np.stack axis argument",
        "data_type": "int",
        "default_value": "0"
      }
    ]
  }
);
