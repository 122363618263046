import styled from '@emotion/styled/macro';
import React from 'react';
import { DragContext, GenericComponentType } from './DragProvider';

const DraggableWrapper = styled.div<{ dragging: boolean }>`
  user-select: none;
  opacity: ${({ dragging }) => (dragging ? 0 : 1)};
`;

type DraggableProps<C extends GenericComponentType> = {
  children: React.ReactNode;
  category: string;
  dragPreviewComp: C;
  dragPreviewCompProps: React.ComponentProps<C>;
  onDragStart?: () => void;
  onDragEnd?: () => void;
  extraData?: any;
  testId?: string;
};
export const Draggable = <C extends GenericComponentType>({
  children,
  category,
  dragPreviewComp,
  dragPreviewCompProps,
  extraData,
  onDragStart,
  onDragEnd,
  className,
  testId,
  ...rest
}: DraggableProps<C> & { className?: string }) => {
  const [draggingLocal, setDraggingLocal] = React.useState<boolean>(false);
  const dragContextData = React.useContext(DragContext);

  const dragEnderRef = React.useRef((_e: MouseEvent) => {});

  const handleDragEnd = (_e: MouseEvent) => {
    setDraggingLocal(false);
    dragContextData.endDrag();
    if (onDragEnd) onDragEnd();
  };

  const handleDragStart = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();

    setDraggingLocal(true);
    dragContextData.startDrag(
      category,
      dragPreviewComp,
      dragPreviewCompProps,
      { x: e.nativeEvent.offsetX, y: e.nativeEvent.offsetY },
      extraData,
    );
    if (onDragStart) onDragStart();

    const dragEnder = (e: MouseEvent) => {
      handleDragEnd(e);
      window.removeEventListener('mouseup', dragEnder);
    };

    window.addEventListener('mouseup', dragEnder);

    dragEnderRef.current = dragEnder;
  };

  React.useEffect(
    () => () => window.removeEventListener('mouseup', dragEnderRef.current),
    [],
  );

  return (
    <DraggableWrapper
      data-test-id={testId}
      draggable
      onDragStart={handleDragStart}
      dragging={draggingLocal}
      className={className}
      {...rest}>
      {children}
    </DraggableWrapper>
  );
};
