/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const ChevronUp: React.FC<Props> = (props) => (
  <svg viewBox="0 0 16 16" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M3.70811 9.79647C3.98559 10.0678 4.43547 10.0678 4.71294 9.79647L8 6.5817L11.2871 9.79647C11.5645 10.0678 12.0144 10.0678 12.2919 9.79647C12.5694 9.52509 12.5694 9.0851 12.2919 8.81373L8.66989 5.27138C8.29992 4.90954 7.70008 4.90954 7.33011 5.27138L3.70811 8.81373C3.43063 9.0851 3.43063 9.52509 3.70811 9.79647Z"
      fill={props.fill}
    />
  </svg>
);

ChevronUp.defaultProps = {
  width: '16',
  height: '16',
  viewBox: '0 0 16 16',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default ChevronUp;
