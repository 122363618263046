import styled from '@emotion/styled/macro';
import { usePostStripeManagementInfoMutation } from 'app/apiGenerated/generatedApi';
import React from 'react';
import Button from 'ui/common/Button/Button';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import { ActionButtonContainer } from 'ui/common/Modal/Modal';
import { getFullBrowserHostUrl } from 'util/getFullBrowserHostUrl';
import { t } from '@lingui/macro';

const PaymentModalContents = styled.div`
  display: flex;
  flex-direction: column;
  width: 310px;
  border-top: 1px solid ${({ theme }) => theme.colors.grey[10]};
  padding-top: ${({ theme }) => theme.spacing.normal};

  > p {
    line-height: 1.6em;
    margin-bottom: ${({ theme }) => theme.spacing.xlarge};

    &:first-child {
      margin-bottom: ${({ theme }) => theme.spacing.large};
    }
  }
`;

export const PaymentModal = ({ closeModal }: { closeModal: () => void }) => {
  const [callGetStripeManagementInfo] = usePostStripeManagementInfoMutation();
  const [managementUrl, setManagementUrl] = React.useState<string | null>();

  React.useEffect(() => {
    callGetStripeManagementInfo({
      userStripeManagementInfoRequest: {
        domain: getFullBrowserHostUrl(),
      },
    })
      .unwrap()
      .then((res) => {
        setManagementUrl(res.management_url);
      });
  }, [callGetStripeManagementInfo]);

  return (
    <PaymentModalContents>
      <p>
        {t`Don't worry, you still have access to your Projects, and you can still view or download your files. However, simulations and API access have been disabled. You can reactivate your subscripton at any time from your Collimator settings.`}
      </p>
      <p>
        {t`If you believe this is in error, or you need further assistance, please contact us at`}
        &nbsp;
        <a href="mailto:help@collimator.ai">help@collimator.ai</a>.
      </p>
      <ActionButtonContainer>
        <Button variant={ButtonVariants.LargeSecondary} onClick={closeModal}>
          {t`Got it`}
        </Button>
        <Button
          disabled={!managementUrl}
          variant={ButtonVariants.LargePrimary}
          onClick={() => {
            if (!managementUrl) return;
            window.location.href = managementUrl;
          }}>
          {t`Reactivate Subscription`}
        </Button>
      </ActionButtonContainer>
    </PaymentModalContents>
  );
};
