import { Theme, useTheme } from '@emotion/react';
import { NodeInstance } from 'app/generated_types/SimulationModel';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { modelVersionsActions } from 'app/slices/modelVersionsSlice';
import React from 'react';
import { Group, Iterator, Submodel } from 'ui/common/Icons/Small';
import {
  TreeArrowCollapsed,
  TreeArrowExpanded,
} from 'ui/common/Icons/Standard';
import { SimulationDataModelSimulationSignalList } from 'ui/dataExplorer/simulationDataSidebar/SimulationDataModelSimulationSignalList';
import {
  ItemSection,
  ModelBlockItemText,
  ModelTreeIcon,
} from 'ui/objectBrowser/ModelTreeParts';
import { buildNodeInfoForModelVersion } from 'util/modelVersionSignal';
import { SignalsList } from 'util/simulationSignalsTree';

function getIcon(theme: Theme, node?: NodeInstance) {
  if (node?.type === 'core.Group')
    return <Group fill={theme.colors.text.secondary} />;
  if (node?.type === 'core.LinearizedSystem')
    // TODO: needs proper icon
    return <Group fill={theme.colors.text.secondary} />;
  if (node?.type === 'core.Iterator' || node?.type === 'core.Replicator')
    return <Iterator fill={theme.colors.text.secondary} />;
  return <Submodel fill={theme.colors.text.secondary} />;
}

interface Props {
  modelId: string;
  modelVersionId: string;
  simulationId: string;
  canEditProject: boolean;
  signalsList: SignalsList;
}

export const SimulationDataModelSimulationSubmodelItem: React.FC<Props> = ({
  modelId,
  modelVersionId,
  simulationId,
  canEditProject,
  signalsList,
}) => {
  const theme = useTheme();

  const [isExpanded, setIsExpanded] = React.useState(false);

  const toggleExpandedState = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    event.preventDefault();
    event.stopPropagation();

    setIsExpanded(!isExpanded);
  };

  const dispatch = useAppDispatch();

  const modelIdToVersionIdToModelData = useAppSelector(
    (state) => state.modelVersions.modelIdToVersionIdToModelData,
  );

  // Find the associated parent node in the model version so we
  // can find signal names (which we use as display names for signals when
  // they are available and so we can know what type each submodel is and
  // display them with the correct icon.)
  const { modelVersionToRequest, node } = buildNodeInfoForModelVersion(
    signalsList.parentNamePath,
    modelId,
    modelVersionId,
    modelIdToVersionIdToModelData,
  );

  React.useEffect(() => {
    if (modelVersionToRequest) {
      dispatch(modelVersionsActions.requestModelVersion(modelVersionToRequest));
    }
  }, [dispatch, modelVersionToRequest]);

  return (
    <>
      <ItemSection
        data-test-id={`data-explorer-model-simulation-submodel-tree-model-item-${signalsList.displayName}`}
        selected={false}
        nestedLayer={signalsList.parentCount + 1}>
        <ModelTreeIcon onClick={toggleExpandedState}>
          {isExpanded ? <TreeArrowExpanded /> : <TreeArrowCollapsed />}
        </ModelTreeIcon>

        <ModelTreeIcon>{getIcon(theme, node)}</ModelTreeIcon>
        <div
          data-test-id={`data-explorer-model-simulation-submodel-tree-model-item-text-${signalsList.displayName}`}>
          <ModelBlockItemText>{signalsList.displayName}</ModelBlockItemText>
        </div>
      </ItemSection>

      {isExpanded && (
        <SimulationDataModelSimulationSignalList
          modelId={modelId}
          modelVersionId={modelVersionId}
          simulationId={simulationId}
          canEditProject={canEditProject}
          signalsList={signalsList}
        />
      )}
    </>
  );
};
