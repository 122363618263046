import { generatedApi } from 'app/apiGenerated/generatedApi';
import { IS_FAVOURITE_V1 } from 'ui/userPreferences/projectFavoritesPrefs';
import {
  GetModelReadByUuidApiArg,
  GetSubmodelApiArg,
} from './apiGenerated/generatedApiTypes';

export enum TagType {
  AuthToken = 'AuthToken',
  Project = 'Project',
  Submodels = 'Submodels',
  ModelContents = 'ModelContents',
  SubmodelContents = 'SubmodelContents',
  UserOptionOverrides = 'UserOptionOverrides',
  Requirements = 'Requirements',
  RequirementBlocks = 'RequirementBlocks',
  Simulations = 'Simulations',
  UserCredentials = 'UserCredentials',
  GitHubRepos = 'GitHubRepos',
  ChatSessions = 'ChatSessions',
  BusTypes = 'BusTypes',
  UserStatistics = 'UserStatistics',
  PreRegMetas = 'PreregMetas',
  Comments = 'Comments',
  EntityPreferences = 'EntityPreferences',
}

export const LIST = 'LIST';

/**
 *  Add tags to RTKQ for custom cache invalidation
 */
export const enhancedApi = generatedApi.enhanceEndpoints({
  addTagTypes: Object.keys(TagType),
  endpoints: {
    getAuthTokensReadAll: { providesTags: [TagType.AuthToken] },
    postAuthTokens: { invalidatesTags: [TagType.AuthToken] },
    deleteAuthTokenByUuid: { invalidatesTags: [TagType.AuthToken] },

    // Requirements manager invalidation
    // There is no single requirement view, only a table view of all requirements, so we invalidate all requirements
    getRequirements: { providesTags: [TagType.Requirements] },
    postRequirementsImportProcess: {
      invalidatesTags: [TagType.Requirements],
    },
    // Block detail requirement selector invalidation
    // Only one block viewed at a time
    getRequirementBlockByLocation: {
      providesTags: [TagType.RequirementBlocks],
    },
    // Not necessary to invalidate requirements yet on link create/delete, since there's no special handling of requirements in the select dropdown
    postRequirementLinkCreate: {
      invalidatesTags: [
        TagType.RequirementBlocks,
        // TagType.Requirements
      ],
    },
    deleteRequirementLink: {
      invalidatesTags: [
        TagType.RequirementBlocks,
        // TagType.Requirements
      ],
    },

    // Projects
    getProjectReadAll: {
      providesTags: (result, error, arg) => [
        { type: TagType.Project, id: LIST },
      ],
    },

    getProjectReadByUuid: {
      providesTags: (result, error, arg) => [
        { type: TagType.Project, id: arg.projectUuid },
      ],
    },

    postProjectCreate: {
      invalidatesTags: (result, error, arg) => [
        { type: TagType.Project, id: LIST },
      ],
    },

    deleteProjectByUuid: {
      invalidatesTags: (result, error, arg) => [
        { type: TagType.Project, id: LIST },
        { type: TagType.Project, id: arg.projectUuid },
      ],
    },

    postProjectGitConnectRemote: {
      invalidatesTags: (result, error, arg) => [
        { type: TagType.Project, id: LIST },
        { type: TagType.Project, id: arg.projectUuid },
        { type: TagType.Submodels, id: LIST },
      ],
    },

    deleteProjectGitRemote: {
      invalidatesTags: (result, error, arg) => [
        { type: TagType.Project, id: LIST },
        { type: TagType.Project, id: arg.projectUuid },
        { type: TagType.Submodels, id: LIST },
      ],
    },

    postProjectCopyByUuid: {
      invalidatesTags: (result, error, arg) => [
        { type: TagType.Project, id: LIST },
      ],
    },

    putProjectUpdateByUuid: {
      invalidatesTags: (result, error, arg) => [
        { type: TagType.Project, id: LIST },
        { type: TagType.Project, id: arg.projectUuid },
      ],
    },

    postProjectImportProcessByUuid: {
      invalidatesTags: (result, error, arg) => [
        { type: TagType.Project, id: arg.projectUuid },
      ],
    },

    postProjectGitResetFromUpstream: {
      invalidatesTags: (result, error, arg) => [
        { type: TagType.Project, id: LIST },
        { type: TagType.Project, id: arg.projectUuid },
        { type: TagType.Submodels, id: LIST },
      ],
    },

    // Models
    postModelCreate: {
      invalidatesTags: (result, error, arg) => [
        { type: TagType.Project, id: arg.modelCreateRequest.project_uuid },
      ],
    },

    // FIXME: these didn't have a projectUuid when porting
    postModelCopyByUuid: {
      invalidatesTags: (result, error, arg) => [{ type: TagType.Project }],
    },

    deleteModelByUuid: {
      invalidatesTags: (result, error, arg) => [{ type: TagType.Project }],
    },

    putModelSummaryUpdateByUuid: {
      invalidatesTags: (result, error, arg) => [{ type: TagType.Project }],
    },

    // Submodels
    getSubmodelsList: {
      providesTags: (result, error, arg) => [
        { type: TagType.Submodels, id: LIST },
      ],
    },

    // Note: This API is used for GET but for PUT we go via apiData.ts
    // As a result, we need to invalidate the cache manually
    // FIXME: PUT calls should use a mutation
    getModelReadByUuid: {
      providesTags: (result, error, args: GetModelReadByUuidApiArg) => [
        { type: TagType.ModelContents, id: args.modelUuid },
      ],
    },

    getSubmodel: {
      providesTags: (result, error, args: GetSubmodelApiArg) => [
        { type: TagType.SubmodelContents, id: args.submodelUuid },
      ],
    },

    getUserOptionOverridingUsersList: {
      providesTags: (result, error, arg) => [
        { type: TagType.UserOptionOverrides, id: LIST },
      ],
    },

    deleteUserOptionOverridesByUuid: {
      invalidatesTags: (result, error, arg) => [
        { type: TagType.UserOptionOverrides, id: LIST },
      ],
    },

    postSimulationCreate: {
      invalidatesTags: (result, error, arg) => [
        { type: TagType.Simulations, id: LIST },
      ],
    },

    getSimulationsForUserReadAll: {
      providesTags: (result, error, arg) => [
        { type: TagType.Simulations, id: LIST },
      ],
    },

    // Files

    deleteFileByUuid: {
      invalidatesTags: (result, error, arg) => [
        { type: TagType.Project, id: arg.projectUuid },
      ],
    },

    // rename file, not for content update.
    putFileUpdateByUuid: {
      invalidatesTags: (result, error, arg) => [
        { type: TagType.Project, id: arg.projectUuid },
      ],
    },

    postFileProcessByUuid: {
      invalidatesTags: (result, error, arg) => [
        { type: TagType.Project, id: arg.projectUuid },
      ],
    },

    postFileCopyByUuid: {
      invalidatesTags: (result, error, arg) => [
        { type: TagType.Project, id: arg.projectUuid },
      ],
    },

    // APIs for git projects

    postGitHubCallback: {
      invalidatesTags: (result, error, arg) => [
        TagType.UserCredentials,
        TagType.GitHubRepos,
      ],
    },

    getGitHubRepos: {
      providesTags: (result, error, arg) => [
        { type: TagType.GitHubRepos, id: LIST },
      ],
    },

    postUserCredentials: {
      invalidatesTags: (result, error, arg) => [
        TagType.UserCredentials,
        TagType.GitHubRepos,
      ],
    },

    deleteUserCredentials: {
      invalidatesTags: (result, error, arg) => [
        TagType.UserCredentials,
        TagType.GitHubRepos,
      ],
    },

    getListUserCredentials: {
      providesTags: (result, error, arg) => [
        { type: TagType.UserCredentials, id: LIST },
      ],
    },

    // Bus Types
    getBusType: {
      providesTags: (result, error, arg) => [
        { type: TagType.BusTypes, id: `${arg.projectUuid}/${arg.busTypeId}` },
      ],
    },
    putBusTypeUpdate: {
      invalidatesTags: (result, error, arg) => {
        // If the name changed, the project list needs to refetch
        const projectTags = arg.busTypeUpdateRequest.name
          ? [{ type: TagType.Project, id: arg.projectUuid }]
          : [];

        return [
          ...projectTags,
          { type: TagType.BusTypes, id: `${arg.projectUuid}/${arg.busTypeId}` },
        ];
      },
    },
    postBusTypeCreate: {
      invalidatesTags: (result, error, arg) => [
        { type: TagType.Project, id: arg.projectUuid },
      ],
    },
    deleteBusType: {
      invalidatesTags: (result, error, arg) => [
        { type: TagType.Project, id: arg.projectUuid },
      ],
    },

    getUserStatistics: {
      providesTags: (result, error, arg) => [{ type: TagType.UserStatistics }],
    },

    postProjectPermissionClaim: {
      invalidatesTags: (result, error, arg) => [{ type: TagType.Project }],
    },

    // Entity Preferences
    getEntityPreferences: {
      providesTags: (result, error, arg) => [
        {
          type: TagType.EntityPreferences,
          id: arg.entityUuid,
        },
      ],
    },
    deleteEntityPreferences: {
      invalidatesTags: (result, error, arg) => {
        if (arg.preferencesKey === IS_FAVOURITE_V1) {
          return [
            { type: TagType.Project, id: LIST },
            { type: TagType.Project, id: arg.entityUuid },
          ];
        }
        return [{ type: TagType.EntityPreferences, id: arg.entityUuid }];
      },
    },
    putEntityPreferences: {
      invalidatesTags: (result, error, arg) => {
        if (arg.preferencesKey === IS_FAVOURITE_V1) {
          return [
            { type: TagType.Project, id: LIST },
            { type: TagType.Project, id: arg.entityUuid },
          ];
        }
        return [{ type: TagType.EntityPreferences, id: arg.entityUuid }];
      },
    },

    postUserSignBetaTos: {
      invalidatesTags: (result, error, arg) => [{ type: TagType.Project }],
    },

    postAdminGlobalProjectCreate: {
      invalidatesTags: (result, error, arg) => [
        { type: TagType.Project, id: LIST },
        {
          type: TagType.Project,
          id: arg.globalProjectCreateRequest.project_uuid,
        },
      ],
    },

    deleteAdminGlobalProjectByUuid: {
      invalidatesTags: (result, error, arg) => [
        { type: TagType.Project, id: LIST },
        { type: TagType.Project, id: arg.projectUuid },
      ],
    },

    deleteProjectPermissionByUuid: {
      invalidatesTags: (result, error, arg) => [
        { type: TagType.Project, id: LIST },
        { type: TagType.Project, id: arg.projectUuid },
      ],
    },

    postTemplateProjectsCopyByUuid: {
      invalidatesTags: (result, error, arg) => [
        { type: TagType.Project, id: LIST },
      ],
    },

    getSimulationLogsReadByUuid: {
      query: (queryArg) => ({
        url: `/models/${queryArg.modelUuid}/simulations/${queryArg.simulationUuid}/logs`,
        headers: { 'X-Correlation-ID': queryArg['X-Correlation-ID'] },
        // force text response otherwise RTKQ will try to parse as JSON
        responseHandler: (response) => response.text(),
      }),
    },

    getPreRegMetasAll: {
      providesTags: () => [{ type: TagType.PreRegMetas, id: LIST }],
    },

    postCreatePreRegMeta: {
      invalidatesTags: () => [{ type: TagType.PreRegMetas, id: LIST }],
    },

    deletePreRegMetaByEmail: {
      invalidatesTags: () => [{ type: TagType.PreRegMetas, id: LIST }],
    },
    getSimulationLogsShortReadByUuid: {
      query: (queryArg) => ({
        url: `/models/${queryArg.modelUuid}/simulations/${queryArg.simulationUuid}/logs/short`,
        headers: { 'X-Correlation-ID': queryArg['X-Correlation-ID'] },
        keepUnusedDataFor: 0,
        responseHandler: (response) => response.text(),
      }),
    },
    getCommentsReadAll: {
      providesTags: () => [{ type: TagType.Comments, id: LIST }],
    },
    postCommentCreate: {
      invalidatesTags: () => [{ type: TagType.Comments, id: LIST }],
    },
    getSubmodelCommentsReadAll: {
      providesTags: () => [{ type: TagType.Comments, id: LIST }],
    },
    postSubmodelCommentCreate: {
      invalidatesTags: () => [{ type: TagType.Comments, id: LIST }],
    },
    deleteCommentByUuid: {
      invalidatesTags: () => [{ type: TagType.Comments, id: LIST }],
    },

    getJobLogsFile: {
      query: (queryArg) => ({
        url: `/jobs/${queryArg.jobUuid}/logs/${queryArg.logFile}`,
        headers: { 'X-Correlation-ID': queryArg['X-Correlation-ID'] },
        // force text response otherwise RTKQ will try to parse as JSON
        responseHandler: (response) => response.text(),
      }),
    },
  },
});

export const {
  useGetAuthTokensReadAllQuery,
  usePostAuthTokensMutation,
  useDeleteAuthTokenByUuidMutation,

  useGetProjectReadAllQuery,
  useGetProjectReadByUuidQuery,
  usePostProjectGitConnectRemoteMutation,
  usePostProjectGitResetFromUpstreamMutation,
  useDeleteProjectGitRemoteMutation,
  usePostFileCopyByUuidMutation,

  useGetSubmodelsListQuery,

  useGetEntityPreferencesQuery,
  usePutEntityPreferencesMutation,

  useGetModelReadByUuidQuery,
  useGetUserOptionOverridingUsersListQuery,
  useDeleteUserOptionOverridesByUuidMutation,
  usePostSimulationCreateMutation,
  useGetSimulationsForUserReadAllQuery,
  usePostGitHubCallbackMutation,
  useGetGitHubReposQuery,
  usePostUserCredentialsMutation,
  useDeleteUserCredentialsMutation,
  useGetListUserCredentialsQuery,

  useGetBusTypeQuery,
  usePutBusTypeUpdateMutation,
  useGetSimulationLogsReadByUuidQuery,
  useGetSimulationLogsShortReadByUuidQuery,

  useGetJobLogsFileQuery,
} = enhancedApi;
