/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Plus: React.FC<Props> = (props) => (
  <svg viewBox="0 0 16 16" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M7.99999 4.16667C8.27613 4.16667 8.49999 4.39053 8.49999 4.66667V7.5H11.3333C11.6095 7.5 11.8333 7.72386 11.8333 8C11.8333 8.27615 11.6095 8.5 11.3333 8.5H8.49999V11.3333C8.49999 11.6095 8.27613 11.8333 7.99999 11.8333C7.72385 11.8333 7.49999 11.6095 7.49999 11.3333V8.5H4.66666C4.39051 8.5 4.16666 8.27615 4.16666 8C4.16666 7.72386 4.39051 7.5 4.66666 7.5H7.49999V4.66667C7.49999 4.39053 7.72385 4.16667 7.99999 4.16667Z"
      fill={props.fill}
    />
  </svg>
);

Plus.defaultProps = {
  width: '16',
  height: '16',
  viewBox: '0 0 16 16',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Plus;
