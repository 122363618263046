import { ModelState } from 'app/modelState/ModelState';
import { PayloadAction } from '@reduxjs/toolkit';
import { isSamePath } from 'app/utils/parentPathUtils';

export interface NavigationAndSelectionRequest extends SelectionRequest {
  parentPath: string[];
  navigateToModelId?: string;
}

export interface SelectionRequest {
  selectionParentPath: string[];
  selectedBlockIds?: string[];
  selectedLinkIds?: string[];
  selectedAnnotationIds?: string[];
}

// FUNCTIONS TO REPLACE SELECTIONS

export function unselectAll(state: ModelState) {
  state.selectionParentPath = [];
  state.selectedBlockIds = [];
  state.selectedLinkIds = [];
  state.selectedAnnotationIds = [];
}

export function setSelections(
  state: ModelState,
  selectionRequest: SelectionRequest,
) {
  const {
    selectionParentPath,
    selectedBlockIds,
    selectedLinkIds,
    selectedAnnotationIds,
  } = selectionRequest;

  state.selectionParentPath = selectionParentPath;
  state.selectedBlockIds = selectedBlockIds || [];
  state.selectedLinkIds = selectedLinkIds || [];
  state.selectedAnnotationIds = selectedAnnotationIds || [];
}

// FUNCTION TO DETERMINE IF SELECTION MODIFICATION IS ALLOWED

function isAppendingToSelectionAllowed(
  state: ModelState,
  parentPath: string[],
) {
  const isSelectionEmpty =
    state.selectedBlockIds.length === 0 && state.selectedLinkIds.length === 0;
  if (isSelectionEmpty) {
    // There are no selections, so we are free to select anything from any parent path.
    return true;
  }

  // Don't allow multiple selections across different diagram levels.
  if (!isSamePath(state.selectionParentPath, parentPath)) {
    // We already have a selection that is at different diagram level, so disallow the new selection.
    return false;
  }

  return true;
}

// FUNCTIONS TO MODIFY SELECTIONS

export function selectAdditionalNodes(
  state: ModelState,
  action: PayloadAction<{
    parentPath: string[];
    blockIds: string[];
  }>,
) {
  const { parentPath, blockIds } = action.payload;
  if (!isAppendingToSelectionAllowed(state, parentPath)) {
    return;
  }

  state.selectionParentPath = parentPath;
  for (let i = 0; i < blockIds.length; i++) {
    const blockUuid = blockIds[i];

    if (!state.selectedBlockIds.includes(blockUuid)) {
      state.selectedBlockIds.push(blockUuid);
    }
  }
}

export function selectAdditionalLinks(
  state: ModelState,
  action: PayloadAction<{
    parentPath: string[];
    linkIds: string[];
  }>,
) {
  const { parentPath, linkIds } = action.payload;
  if (!isAppendingToSelectionAllowed(state, parentPath)) {
    return;
  }

  state.selectionParentPath = parentPath;
  for (let i = 0; i < linkIds.length; i++) {
    const linkUuid = linkIds[i];

    if (!state.selectedLinkIds.includes(linkUuid)) {
      state.selectedLinkIds.push(linkUuid);
    }
  }
}

export function selectAdditionalAnnotations(
  state: ModelState,
  action: PayloadAction<{
    parentPath: string[];
    annotationIds: string[];
  }>,
) {
  const { parentPath, annotationIds } = action.payload;
  if (!isAppendingToSelectionAllowed(state, parentPath)) {
    return;
  }

  for (let i = 0; i < annotationIds.length; i++) {
    const annotationUuid = annotationIds[i];

    if (!state.selectedAnnotationIds.includes(annotationUuid)) {
      state.selectedAnnotationIds.push(annotationUuid);
    }
  }
}

// FUNCTIONS TO REMOVE SELECTIONS

export function unselectNode(
  state: ModelState,
  action: PayloadAction<{ nodeUuid: string }>,
) {
  const { nodeUuid } = action.payload;
  state.selectedBlockIds = state.selectedBlockIds.filter(
    (uuid) => uuid !== nodeUuid,
  );
}

export function unselectNodes(
  state: ModelState,
  data: { removingBlockUuids: string[] },
) {
  const { removingBlockUuids } = data;
  state.selectedBlockIds = state.selectedBlockIds.filter(
    (uuid) => !removingBlockUuids.includes(uuid),
  );
}

export function unselectLink(
  state: ModelState,
  action: PayloadAction<{ linkUuid: string }>,
) {
  const { linkUuid } = action.payload;
  state.selectedLinkIds = state.selectedLinkIds.filter(
    (uuid) => uuid !== linkUuid,
  );
}

export function unselectAnnotation(
  state: ModelState,
  action: PayloadAction<{ uuid: string }>,
) {
  const { uuid: annotationUuid } = action.payload;
  state.selectedAnnotationIds = state.selectedAnnotationIds.filter(
    (uuid) => uuid !== annotationUuid,
  );
}
