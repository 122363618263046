/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Paste: React.FC<Props> = (props) => (
  <svg viewBox="0 0 24 24" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M12 3.25C10.8714 3.25 9.93683 4.08099 9.77484 5.1645L9.68939 5.24995H7C6.0335 5.24995 5.25 6.03345 5.25 6.99995V18C5.25 18.9665 6.0335 19.75 7 19.75H17C17.9665 19.75 18.75 18.9665 18.75 18V6.99995C18.75 6.03346 17.9665 5.24995 17 5.24995H14.3106L14.2252 5.1645C14.0632 4.08099 13.1286 3.25 12 3.25ZM11.25 5.5C11.25 5.08579 11.5858 4.75 12 4.75C12.4142 4.75 12.75 5.08579 12.75 5.5C12.75 5.91421 12.4142 6.25 12 6.25C11.5858 6.25 11.25 5.91421 11.25 5.5ZM15.6451 6.74995C15.7137 6.92095 15.75 7.10505 15.75 7.29289C15.75 8.09763 15.0976 8.75 14.2929 8.75H9.70711C8.90237 8.75 8.25 8.09763 8.25 7.29289C8.25 7.10505 8.28627 6.92095 8.35493 6.74995H7C6.86193 6.74995 6.75 6.86188 6.75 6.99995V18C6.75 18.1381 6.86193 18.25 7 18.25H17C17.1381 18.25 17.25 18.1381 17.25 18V6.99995C17.25 6.86188 17.1381 6.74995 17 6.74995H15.6451Z"
      fill={props.fill}
    />
  </svg>
);

Paste.defaultProps = {
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Paste;
