import { nodeTypeIsLocalSubdiagram } from 'app/helpers';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { modelMetadataActions } from 'app/slices/modelMetadataSlice';
import { selectCurrentSubdiagramType } from 'app/slices/modelSlice';
import { getDiagramForPath } from 'app/utils/modelDiagramUtils';
import React from 'react';
import { useNotifications } from 'ui/common/notifications/useNotifications';

export const CurrentDiagramTracker: React.FC = () => {
  const dispatch = useAppDispatch();
  const { showError } = useNotifications();

  const topLevelDiagram = useAppSelector(
    (state) => state.model.present.rootModel,
  );
  const topLevelSubmodels = useAppSelector(
    (state) => state.model.present.submodels,
  );
  const currentSubmodelPath = useAppSelector(
    (state) => state.model.present?.currentSubmodelPath,
  );
  const idToVersionIdToSubmodelFull = useAppSelector(
    (state) => state.submodels.idToVersionIdToSubmodelFull,
  );
  const idToLatestTaggedVersionId = useAppSelector(
    (state) => state.submodels.idToLatestTaggedVersionId,
  );

  const currentSubdiagramType = useAppSelector((state) =>
    selectCurrentSubdiagramType(state.model.present),
  );

  const {
    diagram,
    submodelInstanceId,
    submodelReferenceProjectId,
    submodelReferenceId,
  } = getDiagramForPath(
    currentSubmodelPath,
    topLevelDiagram,
    topLevelSubmodels,
    idToVersionIdToSubmodelFull,
    idToLatestTaggedVersionId,
  );

  React.useEffect(() => {
    if (!diagram && nodeTypeIsLocalSubdiagram(currentSubdiagramType)) {
      // We know for sure model.json is malformed if the local subdiagram is missing.
      showError(
        `Could not find diagram for current path: ${currentSubmodelPath}`,
      );
    }
    // Unfortunately, if it's a reference subdiagram, there's no way to know if missing or loading.
    dispatch(
      modelMetadataActions.updateCurrentDiagram({
        currentDiagram: diagram,
        submodelInstanceId,
        submodelReferenceProjectId,
        submodelReferenceId,
      }),
    );
  }, [
    dispatch,
    diagram,
    submodelReferenceProjectId,
    submodelReferenceId,
    submodelInstanceId,
    showError,
    currentSubmodelPath,
    currentSubdiagramType,
  ]);

  return null;
};
