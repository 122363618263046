// https://stackoverflow.com/a/65996386
export const writeToClipboard = (textToCopy: string): Promise<void> => {
  // navigator clipboard api needs a secure context (https)
  if (navigator.clipboard && window.isSecureContext) {
    return navigator.clipboard.writeText(textToCopy);
  }
  // hidden textarea method
  const textArea = document.createElement('textarea');
  textArea.value = textToCopy;
  textArea.style.position = 'fixed';
  textArea.style.left = '-999999px';
  textArea.style.top = '-999999px';
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();
  return new Promise((res, rej) => {
    const copied = document.execCommand('copy');
    if (copied) res();
    else rej();
    textArea.remove();
  });
};
