import styled from '@emotion/styled/macro';
import { OptimizationMetricJson } from 'app/generated_types/collimator/dashboard/serialization/ui_types.gen';
import { ModelLogLine, OutputLogLevel } from 'app/slices/simResultsSlice';
import React from 'react';
import { OutputContainer } from 'ui/appBottomBar/Output';
import OutputLogLine from 'ui/appBottomBar/OutputLogLine';
import SectionHeading from 'ui/common/Inputs/SectionHeading';
import { ExpandableDetailsContainer } from './ExpandableDetailsContainer';
import { OptimizationMetrics } from './OptimizationResultsContent';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: ${({ theme }) => theme.spacing.large};
`;

const FixedHeight = styled.div`
  height: 300px;
  overflow-y: auto;
`;

const MinHeight = styled.div`
  min-height: 300px;
`;

const OptimizationLiveContent: React.FC<{
  logs: ModelLogLine[];
  metrics?: OptimizationMetricJson[];
}> = ({ logs, metrics }) => {
  const logsToShow =
    logs.length > 0
      ? logs
      : [
          {
            message: 'Optimization will start soon...',
            level: OutputLogLevel.INF,
          },
        ];

  return (
    <Wrapper>
      <MinHeight>
        <SectionHeading noBorder>Optimization Performance</SectionHeading>
        {metrics && metrics.length > 0 ? (
          <OptimizationMetrics metrics={metrics} />
        ) : (
          <p>No data yet</p>
        )}
      </MinHeight>
      <ExpandableDetailsContainer label="Show logs" defaultExpanded>
        <FixedHeight>
          <OutputContainer noBottomSpace border>
            {logsToShow.map((log, i) => (
              <OutputLogLine key={i} rawLog={log} />
            ))}
          </OutputContainer>
        </FixedHeight>
      </ExpandableDetailsContainer>
    </Wrapper>
  );
};

export default OptimizationLiveContent;
