import { t } from '@lingui/macro';
import { getSimulationLogsStream } from 'app/apiData';
import { JobSummary } from 'app/apiGenerated/generatedApiTypes';
import { ModelLogLine, OutputLogLevel } from 'app/slices/simResultsSlice';

const MAX_LOGS_LENGTH = 1000;
const TAIL_LOGS_LENGTH = 10;

const rawLogToModelLogLine = (logLine: string): ModelLogLine => {
  try {
    const parsed = JSON.parse(logLine);
    if (parsed.message !== undefined && parsed.message !== null) {
      return parsed;
    }
    return { message: logLine };
  } catch (e) {
    return { message: logLine };
  }
};

export function parseSimulationLogs(logs?: string): ModelLogLine[] {
  let simulationLogs: ModelLogLine[] = [];
  if (!logs) return simulationLogs;

  // \r will show python progress bars line by line rather than producing
  // an unreadable mess
  const lines = logs.split(/[\n\r]/);

  const newLogs = lines.filter((l) => l.length > 0).map(rawLogToModelLogLine);
  simulationLogs = simulationLogs.concat(newLogs);

  if (simulationLogs.length > MAX_LOGS_LENGTH) {
    const skippedMsg = t({
      id: 'output.logsSkipped',
      message: `(${simulationLogs.length - MAX_LOGS_LENGTH} lines skipped)`,
    });

    simulationLogs = simulationLogs
      .slice(0, MAX_LOGS_LENGTH - TAIL_LOGS_LENGTH)
      .concat({
        timestamp: simulationLogs[MAX_LOGS_LENGTH - TAIL_LOGS_LENGTH].timestamp,
        level: OutputLogLevel.WRN,
        message: skippedMsg,
      })
      .concat(simulationLogs.slice(-TAIL_LOGS_LENGTH));
  }
  return simulationLogs;
}

export const fetchSimulationLogs = (
  summary: JobSummary,
): Promise<ModelLogLine[]> => {
  let partialLogsChunk = '';
  let simulationLogs: ModelLogLine[] = [];

  function addSimulationLogChunk(nextChunk: string): void {
    if (!nextChunk) return;

    const chunk = partialLogsChunk + nextChunk;

    // \r will show python progress bars line by line rather than producing
    // an unreadable mess
    const lines = chunk.split(/[\n\r]/);

    if (chunk.length > 0 && chunk[chunk.length - 1] !== '\n') {
      const lastLine = lines.pop() || '';
      partialLogsChunk = lastLine;
    }

    const newLogs = lines.filter((l) => l.length > 0).map(rawLogToModelLogLine);
    simulationLogs = simulationLogs.concat(newLogs);

    if (simulationLogs.length > MAX_LOGS_LENGTH) {
      const skippedMsg = t({
        id: 'output.logsSkipped',
        message: `(${simulationLogs.length - MAX_LOGS_LENGTH} lines skipped)`,
      });

      simulationLogs = simulationLogs
        .slice(0, MAX_LOGS_LENGTH - TAIL_LOGS_LENGTH)
        .concat({
          timestamp:
            simulationLogs[MAX_LOGS_LENGTH - TAIL_LOGS_LENGTH].timestamp,
          level: OutputLogLevel.WRN,
          message: skippedMsg,
        })
        .concat(simulationLogs.slice(-TAIL_LOGS_LENGTH));
    }
  }

  return (
    getSimulationLogsStream(summary.model_uuid, summary.uuid)
      /* eslint-disable @typescript-eslint/no-non-null-assertion */
      .then((response) => response.body!.getReader())
      .then(async (reader) => {
        const push = async (): Promise<ModelLogLine[]> => {
          const item = await reader.read();
          if (item?.value) {
            const decoder = new TextDecoder();
            const logsChunk = decoder.decode(item.value, {
              stream: !item?.done,
            });
            addSimulationLogChunk(logsChunk);
          }
          if (item?.done) return simulationLogs;
          return push();
        };
        return push();
      })
  );
};
