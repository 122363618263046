import React from 'react';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { cameraActions } from 'app/slices/cameraSlice';
import {
  userPreferencesActions,
  userPreferencesSelectors,
} from 'app/slices/userPreferencesSlice';
import { rendererState } from 'ui/modelRendererInternals/modelRenderer';
import { scaleCameraToFitModelIfLargerAndCenter } from 'ui/modelRendererInternals/shortcutKeyConfig';
import { useAppParams } from 'util/useAppParams';
import { navigationActions } from 'app/slices/navigationSlice';
import { overlayCameraExternalChange } from 'ui/modelEditor/RendererOverlay';

const useModelEditorPreferences = () => {
  const dispatch = useAppDispatch();
  const loadModelEditorPrefs = useAppSelector(
    (state) => state.userPreferences.shouldLoadModelEditorPrefs,
  );
  const submodelPath = useAppSelector(
    (state) => state.model.present.currentSubmodelPath,
  );

  const currentDiagram = useAppSelector(
    (state) => state.modelMetadata.currentDiagram,
  );
  const currentModelHasContent =
    currentDiagram &&
    (currentDiagram.nodes.length > 0 || currentDiagram.links.length > 0);

  const currentModelId = useAppSelector(
    (state) => state.modelMetadata.loadedModelId,
  );
  const modelEditorPreferences = useAppSelector(
    userPreferencesSelectors.selectModelEditorPreferences,
  );
  const rendererStateInitialized = useAppSelector(
    (state) => state.uiFlags.rendererStateInitialized,
  );

  const loadedModelId = useAppSelector(
    (state) => state.modelMetadata.loadedModelId,
  );

  const { nodeToFocusOn, recentlyFocused } = useAppSelector(
    (state) => state.navigation,
  );

  const canActuallyMoveCamera =
    !nodeToFocusOn && !recentlyFocused && loadModelEditorPrefs;

  const { projectId, modelId } = useAppParams();
  const modelIsOpenAndLoaded =
    projectId && modelId && loadedModelId && loadedModelId === modelId;

  React.useEffect(() => {
    dispatch(userPreferencesActions.setSubmodelPath(submodelPath));
  }, [dispatch, submodelPath]);

  React.useEffect(() => {
    dispatch(userPreferencesActions.setModelId(currentModelId));
  }, [dispatch, currentModelId]);

  React.useEffect(() => {
    if (modelIsOpenAndLoaded && rendererStateInitialized) {
      if (loadModelEditorPrefs && modelEditorPreferences) {
        const { zoom, coord } = modelEditorPreferences.camera;

        if (canActuallyMoveCamera) {
          dispatch(
            cameraActions.setEntireTransform({ zoom, coord, rerender: true }),
          );
          overlayCameraExternalChange({
            zoom,
            coord,
          });
        }
        dispatch(userPreferencesActions.unsetLoadModelEditor());
        dispatch(navigationActions.setRecentlyFocused(false));
      }
      if (loadModelEditorPrefs && !modelEditorPreferences && rendererState) {
        // setTimeout is, unfortunately, required here
        // because the model renderer needs to wait a cycle (or multiple) for its data to be
        // up-to-date with the newly selected submodel.
        // tried to avoid this, but the logic for calling this inside the model editor
        // became too complex.
        // we have to call model-editor-side code because we're relying on the canvas'
        // width and height, so unfortunately this can't be a redux action.
        // for reference, a frame at 60fps is 16ms.
        // so we wait ~50ms to account for perfectly timed stutter/lag.
        // -jackson

        if (canActuallyMoveCamera) {
          setTimeout(() => {
            if (rendererState) {
              if (currentModelHasContent) {
                scaleCameraToFitModelIfLargerAndCenter(rendererState);
              } else {
                dispatch(
                  cameraActions.setEntireTransform({
                    zoom: 1,
                    coord: { x: 0, y: 0 },
                    rerender: true,
                  }),
                );
              }
            }
          }, 50);
        }
        dispatch(userPreferencesActions.unsetLoadModelEditor());
        dispatch(navigationActions.setRecentlyFocused(false));
      }
    }
  }, [
    dispatch,
    loadModelEditorPrefs,
    modelEditorPreferences,
    modelIsOpenAndLoaded,
    rendererStateInitialized,
    currentModelHasContent,
    canActuallyMoveCamera,
  ]);
};

export default useModelEditorPreferences;
