// !!!! -- DO NOT MANUALLY EDIT -- !!!!
// This file was generated by something in scripts/generateDefaultBlockData.js
// If you want to change the contents of this file, it should be automated
//
// (You can edit this file locally, but it may not be checked into the codebase -
// PRs with a manual edit of this file are forbidden from being merged.)

import { computationBlockDefaults } from 'app/generated_blocks/computationBlockDefaults';
import type { ComputationBlockClass } from 'app/generated_types/ComputationBlockClass';
import deepmerge from 'deepmerge';

export const AcausalTranslationalFixedPosition: ComputationBlockClass = deepmerge(
  computationBlockDefaults,
  {
    "base": {
      "name": "FixedPosition",
      "namespace": "acausal.translational",
      "description": "Fixed mechanical translational reference."
    },
    "modes": {
      "time": "agnostic"
    },
    "ports": {
      "outputs": {
        "static": [
          {
            "name": "flange",
            "variant": {
              "variant_kind": "acausal",
              "acausal_domain": "translational"
            }
          }
        ]
      }
    },
    "parameter_definitions": [
      {
        "name": "initial_position",
        "data_type": "float",
        "default_value": "0.0",
        "description": "Position of fixed reference."
      }
    ]
  }
);
