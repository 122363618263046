import {
  GetSubmodelsListForModelParentApiResponse,
  ParameterDefinition,
  SubmodelConfiguration,
  SubmodelInfo,
  SubmodelPortDefinition,
} from 'app/apiGenerated/generatedApiTypes';
import {
  SubmodelInfoLiteUI,
  sortSubmodels,
} from 'app/apiTransformers/convertGetSubmodelsList';

export interface SubmodelInfoUI extends SubmodelInfoLiteUI {
  projectId: string;
  ownerId: string;
  inputCount: number;
  outputCount: number;
  parameterDefinitions: ParameterDefinition[];
  portDefinitionsInputs: SubmodelPortDefinition[];
  portDefinitionsOutputs: SubmodelPortDefinition[];
  submodelConfiguration: SubmodelConfiguration;
}

export function convertAPISubmodelInfoToSubmodelInfoUI(
  apiSubmodel: SubmodelInfo,
): SubmodelInfoUI {
  return {
    id: apiSubmodel.uuid,
    isGlobal: apiSubmodel.is_global,
    createdAt: apiSubmodel.created_at || '',
    updatedAt: apiSubmodel.updated_at || '',
    name: apiSubmodel.name,
    description: apiSubmodel.description,
    editId: apiSubmodel.edit_id,
    projectId: apiSubmodel.project_uuid,
    ownerId: apiSubmodel.owner_uuid,
    inputCount: apiSubmodel.port_count.inputs,
    outputCount: apiSubmodel.port_count.outputs,
    parameterDefinitions: apiSubmodel.parameter_definitions,
    portDefinitionsInputs: apiSubmodel.port_definitions.inputs || [],
    portDefinitionsOutputs: apiSubmodel.port_definitions.outputs || [],
    submodelConfiguration: apiSubmodel.configuration || {},
  };
}

export function convertGetSubmodelsListForModelParent(
  apiResponse: GetSubmodelsListForModelParentApiResponse,
): SubmodelInfoUI[] {
  const submodels = apiResponse.submodels?.map(
    convertAPISubmodelInfoToSubmodelInfoUI,
  );
  return sortSubmodels(submodels);
}
