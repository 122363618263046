/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Output: React.FC<Props> = (props) => (
  <svg viewBox="0 0 24 24" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M12 11.5C12 12.8807 10.8807 14 9.5 14C8.11929 14 7 12.8807 7 11.5C7 10.1193 8.11929 9 9.5 9C10.8807 9 12 10.1193 12 11.5ZM10.5 11.5C10.5 12.0523 10.0523 12.5 9.5 12.5C8.94772 12.5 8.5 12.0523 8.5 11.5C8.5 10.9477 8.94772 10.5 9.5 10.5C10.0523 10.5 10.5 10.9477 10.5 11.5Z"
      fill={props.fill}
    />
    <path
      d="M12.75 10.5C12.75 10.0858 13.0858 9.75 13.5 9.75H16.5C16.9142 9.75 17.25 10.0858 17.25 10.5C17.25 10.9142 16.9142 11.25 16.5 11.25H13.5C13.0858 11.25 12.75 10.9142 12.75 10.5Z"
      fill={props.fill}
    />
    <path
      d="M13.5 12.25C13.0858 12.25 12.75 12.5858 12.75 13C12.75 13.4142 13.0858 13.75 13.5 13.75H15.5C15.9142 13.75 16.25 13.4142 16.25 13C16.25 12.5858 15.9142 12.25 15.5 12.25H13.5Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M4.5 8.5C4.5 7.39543 5.39543 6.5 6.5 6.5H17.5C18.6046 6.5 19.5 7.39543 19.5 8.5V14.5C19.5 15.6046 18.6046 16.5 17.5 16.5H12.75V17.75H16C16.4142 17.75 16.75 18.0858 16.75 18.5C16.75 18.9142 16.4142 19.25 16 19.25H8C7.58579 19.25 7.25 18.9142 7.25 18.5C7.25 18.0858 7.58579 17.75 8 17.75H11.25V16.5H6.5C5.39543 16.5 4.5 15.6046 4.5 14.5V8.5ZM6.5 8H17.5C17.7761 8 18 8.22386 18 8.5V14.5C18 14.7761 17.7761 15 17.5 15H6.5C6.22386 15 6 14.7761 6 14.5V8.5C6 8.22386 6.22386 8 6.5 8Z"
      fill={props.fill}
    />
  </svg>
);

Output.defaultProps = {
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Output;
