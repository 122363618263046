/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const BackArrow: React.FC<Props> = (props) => (
  <svg viewBox="0 0 24 24" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M10.9244 5.46967C11.2173 5.76256 11.2173 6.23744 10.9244 6.53033L8.38095 9.07375C13.3867 8.82069 16.4272 10.1078 17.9459 12.0359C19.6638 14.217 19.2507 17.0185 17.7388 18.5303C17.4459 18.8232 16.9711 18.8232 16.6782 18.5303C16.3853 18.2374 16.3853 17.7626 16.6782 17.4697C17.6663 16.4815 18.0032 14.533 16.7675 12.9641C15.6437 11.5372 13.067 10.2588 8.05002 10.5953L10.9244 13.4697C11.2173 13.7626 11.2173 14.2374 10.9244 14.5303C10.6315 14.8232 10.1566 14.8232 9.8637 14.5303L5.33337 10L9.8637 5.46967C10.1566 5.17678 10.6315 5.17678 10.9244 5.46967Z"
      fill={props.fill}
    />
  </svg>
);

BackArrow.defaultProps = {
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default BackArrow;
