import styled from '@emotion/styled/macro';
import { Project } from 'app/apiTransformers/convertAPIProjectToProject';
import React from 'react';
import { Row } from 'react-table';
import Button from 'ui/common/Button/Button';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import { Menu } from 'ui/common/Icons/Standard';
import PortalMenu from 'ui/common/Menu/PortalMenu';
import { usePortalPopup } from 'ui/common/Tooltip/usePortalPopup';
import ProjectMenu from './ProjectMenu';

const ProjectMenuButtonWrapper = styled.div`
  text-align: right;
`;

interface Props {
  project: Project;
  isViewingProject: boolean;
  /**
   * This is what happens when you don't have composability.
   * The god component has to contain all the context in its props to determine which items to show.
   */
  excludeDeleteMenuOption?: boolean;
}

export const ProjectMenuButton: React.FC<Props> = ({
  project,
  isViewingProject,
  excludeDeleteMenuOption,
}) => {
  const { triggerElRef, isPopupOpen, setIsPopupOpen } =
    usePortalPopup<HTMLButtonElement>();

  return (
    <ProjectMenuButtonWrapper>
      <Button
        ref={triggerElRef}
        Icon={Menu}
        variant={ButtonVariants.LargeTertiary}
        onClick={() => setIsPopupOpen(!isPopupOpen)}
        testId="project-list-item-menu-button"
      />
      {isPopupOpen && triggerElRef.current && (
        <PortalMenu
          triggerEl={triggerElRef.current}
          triggerClose={() => setIsPopupOpen(false)}>
          <ProjectMenu
            project={project}
            isViewingProject={isViewingProject}
            triggerClose={() => setIsPopupOpen(false)}
            excludeDeleteMenuOption={excludeDeleteMenuOption || false}
          />
        </PortalMenu>
      )}
    </ProjectMenuButtonWrapper>
  );
};

export const ProjectDetailHeaderMenuButton = ({
  project,
}: {
  project: Project;
}) => (
  <ProjectMenuButton
    project={project}
    isViewingProject
    excludeDeleteMenuOption
  />
);

interface TableProps {
  row: Row<Project>;
  excludeDeleteMenuOption?: boolean;
}

export const ProjectMenuButtonForTable: React.FC<TableProps> = ({
  row,
  excludeDeleteMenuOption,
}) => (
  <ProjectMenuButton
    project={row.original}
    isViewingProject={false}
    excludeDeleteMenuOption={excludeDeleteMenuOption}
  />
);
