/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const SuperSimulation: React.FC<Props> = (props) => (
  <svg viewBox="0 0 16 16" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M7.82913 3.03009C7.93951 2.98995 8.06049 2.98995 8.17087 3.03009L10.9209 4.03009C11.1185 4.10194 11.25 4.28974 11.25 4.49999V7.14977L13.6709 8.03009C13.8685 8.10194 14 8.28974 14 8.49999V11.5C14 11.7102 13.8685 11.898 13.6709 11.9699L10.9209 12.9699C10.8105 13.01 10.6895 13.01 10.5791 12.9699L8 12.032L5.42087 12.9699C5.31049 13.01 5.18951 13.01 5.07913 12.9699L2.32913 11.9699C2.13153 11.898 2 11.7102 2 11.5V8.49999C2 8.28974 2.13153 8.10194 2.32913 8.03009L4.75 7.14977V4.49999C4.75 4.28974 4.88153 4.10194 5.07913 4.03009L7.82913 3.03009ZM5.25 8.03202L3.96309 8.49999L5.25 8.96796L6.53691 8.49999L5.25 8.03202ZM7.5 9.21384L5.75 9.8502V11.7861L7.5 11.1498V9.21384ZM8.5 11.1498L10.25 11.7861V9.8502L8.5 9.21384V11.1498ZM9.46309 8.49999L10.75 8.96796L12.0369 8.49999L10.75 8.03202L9.46309 8.49999ZM10.25 7.14977V5.21384L8.5 5.8502V7.78614L10.25 7.14977ZM7.5 7.78614V5.8502L5.75 5.21384V7.14977L7.5 7.78614ZM6.71309 4.49999L8 4.96796L9.28691 4.49999L8 4.03202L6.71309 4.49999ZM13 9.21384L11.25 9.8502V11.7861L13 11.1498V9.21384ZM4.75 11.7861V9.8502L3 9.21384V11.1498L4.75 11.7861Z"
      fill={props.fill}
    />
  </svg>
);

SuperSimulation.defaultProps = {
  width: '16',
  height: '16',
  viewBox: '0 0 16 16',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default SuperSimulation;
