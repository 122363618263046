// !!!! -- DO NOT MANUALLY EDIT -- !!!!
// This file was generated by something in scripts/generateDefaultBlockData.js
// If you want to change the contents of this file, it should be automated
//
// (You can edit this file locally, but it may not be checked into the codebase -
// PRs with a manual edit of this file are forbidden from being merged.)

import { computationBlockDefaults } from 'app/generated_blocks/computationBlockDefaults';
import type { ComputationBlockClass } from 'app/generated_types/ComputationBlockClass';
import deepmerge from 'deepmerge';

export const AcausalElectricalInductor: ComputationBlockClass = deepmerge(
  computationBlockDefaults,
  {
    "base": {
      "name": "Inductor",
      "namespace": "acausal.electrical",
      "description": "Ideal linear electrical inductor."
    },
    "modes": {
      "time": "agnostic"
    },
    "ports": {
      "inputs": {
        "static": [
          {
            "name": "p",
            "variant": {
              "variant_kind": "acausal",
              "acausal_domain": "electrical"
            }
          }
        ]
      },
      "outputs": {
        "static": [
          {
            "name": "n",
            "variant": {
              "variant_kind": "acausal",
              "acausal_domain": "electrical"
            }
          }
        ]
      }
    },
    "parameter_definitions": [
      {
        "name": "L",
        "data_type": "float",
        "default_value": "1.0",
        "description": "Inductance in Henry's."
      },
      {
        "name": "initial_current",
        "data_type": "float",
        "default_value": "0.0",
        "description": "Initial current."
      },
      {
        "name": "initial_current_fixed",
        "data_type": "bool",
        "default_value": "False",
        "description": "Whether initial current condition is fixed or not."
      }
    ]
  }
);
