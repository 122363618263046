import styled from '@emotion/styled';
import { t } from '@lingui/macro';
import { useUserCredentials } from 'app/api/useUserCredentials';
import { UserCredentialsRequest } from 'app/apiGenerated/generatedApiTypes';
import {
  useDeleteUserCredentialsMutation,
  usePostUserCredentialsMutation,
} from 'app/enhancedApi';
import React, { useState } from 'react';
import Button from 'ui/common/Button/Button';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import { Remove } from 'ui/common/Icons/Standard';
import Input from 'ui/common/Input/Input';
import {
  ValidationRule,
  isRequiredRule,
} from 'ui/common/Input/inputValidation';
import { useNotifications } from 'ui/common/notifications/useNotifications';
import { Standard } from 'ui/common/typography/Typography';

const CENSORED_TOKEN = 'github_pat_*****************************';

const Wrapper = styled.div`
  word-break: break-word;
  width: 100%;
  min-width: 610px;
  max-width: 610px;
  min-height: 200px;

  display: flex;
  flex-direction: column;
`;

const LinkToGitHub = styled.div`
  margin-top: ${({ theme }) => theme.spacing.large};
  margin-bottom: ${({ theme }) => theme.spacing.normal};
  font-size: ${({ theme }) => theme.typography.font.small.size};
  font-family: ${({ theme }) => theme.typography.font.small.fontFamily};
  color: ${({ theme }) => theme.colors.text.secondary};

  > a {
    color: inherit;
    text-decoration: underline;
  }
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const SecretInput = styled(Input)<{ grow?: boolean }>`
  margin-right: 8px;
  width: 100%;

  ${(props) => props.grow && 'flex-grow: 1;'}
`;

const isValidPatRule: ValidationRule = {
  // Note: this rule should not prevent saving, just warn the user
  predicate: (value: string) => {
    if (!value) {
      return false;
    }

    // old PATs
    if (value.startsWith('ghp_')) {
      return value.length === 40;
    }

    // new fine-grained PATs
    if (value.startsWith('github_pat_')) {
      return value.length === 93;
    }

    // really old PATs
    return value.length === 40 || value === CENSORED_TOKEN;
  },
  message: t({
    id: 'input.validationMessage.invalidGitHubPAT',
    message: 'This does not look like a valid GitHub PAT.',
  }),
};

export const ApiKeysGitHubPATModalContents: React.FC = () => {
  const { userCredentials, isLoadingCredentials } = useUserCredentials();
  const [deleteUserCredentials] = useDeleteUserCredentialsMutation();
  const [callAddCredsApi] = usePostUserCredentialsMutation();
  const [editable, setEditable] = useState(true);
  const { showInfo, showError } = useNotifications();
  const [secret, setSecret] = useState('');

  const existingSecretItem = React.useMemo(
    () => userCredentials?.items?.find((cred) => cred.kind === 'github_pat'),
    [userCredentials],
  );

  React.useEffect(() => {
    if (existingSecretItem) {
      setSecret(CENSORED_TOKEN);
    } else {
      setSecret('');
    }
  }, [existingSecretItem]);

  const onDelete = () => {
    if (!existingSecretItem) return;

    setEditable(false);
    deleteUserCredentials({ name: existingSecretItem.name })
      .unwrap()
      .then(() => showInfo(t`GitHub PAT deleted`))
      .catch((e) => showError(t`Error deleting GitHub PAT`, e))
      .finally(() => setEditable(true));
  };

  const onSaveSecret = React.useCallback(() => {
    if (!secret.length) return;

    const userCredentialsRequest: UserCredentialsRequest = {
      name: 'github_pat',
      kind: 'github_pat',
      secret,
      expires_at: undefined,
    };

    setEditable(false);
    callAddCredsApi({ userCredentialsRequest })
      .unwrap()
      .then(() => showInfo(t`GitHub PAT saved`))
      .catch((e) => showError(t`Error saving GitHub PAT`, e))
      .finally(() => setEditable(true));
  }, [secret, callAddCredsApi, showInfo, showError]);

  return (
    <Wrapper>
      <Standard>
        {t({
          id: 'dashboard.githubPat.description',
          message:
            'A GitHub Personal Access Token (PAT) may be required by your ' +
            'project admin in order to use the Git integration in your project.',
        })}
      </Standard>
      <LinkToGitHub>
        {t`You can`}{' '}
        <a
          href="https://github.com/settings/tokens?type=beta"
          target="_blank"
          rel="noreferrer">
          {t`generate a new token on GitHub`}
        </a>
      </LinkToGitHub>
      <InputWrapper>
        <SecretInput
          grow
          hasBorder
          disabled={isLoadingCredentials || !editable || !!existingSecretItem}
          onChangeText={setSecret}
          value={existingSecretItem ? CENSORED_TOKEN : secret}
          validationRules={
            existingSecretItem ? [] : [isRequiredRule, isValidPatRule]
          }
          placeholder={t`Paste your GitHub PAT here`}
          onSubmitValue={onSaveSecret}
        />
        {existingSecretItem ? (
          <Button
            type="button"
            disabled={isLoadingCredentials || !editable}
            variant={ButtonVariants.SmallTertiary}
            Icon={Remove}
            onClick={() => {
              if (
                confirm(
                  t`Do you really want to remove this GitHub PAT? This may prevent you from using git integrations for some projects.`,
                )
              ) {
                onDelete();
              }
            }}
          />
        ) : (
          <Button
            type="button"
            disabled={isLoadingCredentials || !editable || secret.length === 0}
            variant={ButtonVariants.SmallTertiary}
            onClick={onSaveSecret}>
            {t`Save`}
          </Button>
        )}
      </InputWrapper>
    </Wrapper>
  );
};
