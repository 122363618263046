import styled from '@emotion/styled/macro';
import React from 'react';

export type SeparatorProps = {
  greyLevel?: 2 | 5 | 10 | 30 | 50 | 70 | 85 | 98;
  hMargin?: number;
};

const SeparatorWrapper = styled.div<SeparatorProps>`
  width: 100%;
  height: 1px;
  flex-shrink: 0;
  background-color: ${(props) =>
    props.theme.colors.grey[
      props.greyLevel !== undefined ? props.greyLevel : 10
    ]};
  margin: 0 ${(props) => props.hMargin || 0}px
    ${(props) => props.theme.spacing.normal};
`;

const Separator: React.FC<SeparatorProps> = (props: SeparatorProps) => (
  <SeparatorWrapper {...props} />
);

export default Separator;
