import React from 'react';
import { GetSubscriptionStatusApiResponse } from 'app/apiGenerated/generatedApiTypes';
import { generatedApi } from 'app/apiGenerated/generatedApi';

type CustomStripeContext = {
  subscriptionStatus?: GetSubscriptionStatusApiResponse;
  refetchStatus: () => void;
};

export const customStripeContext = React.createContext<CustomStripeContext>({
  subscriptionStatus: undefined,
  refetchStatus: () => {},
});

export const CustomStripeProvider = ({
  children,
}: {
  children: React.ReactChild;
}): React.ReactElement => {
  const [refetchStatus, { data: subscriptionStatus }] =
    generatedApi.endpoints.getSubscriptionStatus.useLazyQuery();

  React.useEffect(() => {
    refetchStatus();
  }, [refetchStatus]);

  const contextValue = React.useMemo(
    () => ({
      subscriptionStatus,
      refetchStatus,
    }),
    [subscriptionStatus, refetchStatus],
  );

  return (
    <customStripeContext.Provider value={contextValue}>
      {children}
    </customStripeContext.Provider>
  );
};
