import React from 'react';
import styled from '@emotion/styled';
import { CommentComplete } from 'app/apiGenerated/generatedApiTypes';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import Input from 'ui/common/Input/Input';
import {
  useDeleteCommentByUuidMutation,
  useGetCommentsReadAllQuery,
  useGetSubmodelCommentsReadAllQuery,
  usePostCommentCreateMutation,
  usePostSubmodelCommentCreateMutation,
} from 'app/apiGenerated/generatedApi';
import {
  ArrowLeft,
  ArrowRight,
  ArrowUp,
  Checked,
  Close,
} from 'ui/common/Icons/Standard';
import { uiFlagsActions } from 'app/slices/uiFlagsSlice';
import { getUserName } from 'ui/auth/utils';
import { formatDate } from 'util/dateUtils';
import { css } from '@emotion/react';
import { modelActions } from 'app/slices/modelSlice';

export type ModelCommentAnchorType =
  | { kind: 'block'; nodeUuid: string }
  | { kind: 'canvas'; x: number; y: number };

export type ModelCommentUiProps = {
  parentPath: string;
  anchor: ModelCommentAnchorType;
};

export type ModelComment = Omit<CommentComplete, 'uiprops'> & {
  uiprops: ModelCommentUiProps;
};

const wheelDisabler = (e: MouseEvent) => {
  e.preventDefault();
  e.stopPropagation();
};

const ModelCommentAnchor = styled.div<{ x: number; y: number }>`
  position: absolute;
  left: ${({ x }) => x}px;
  top: ${({ y }) => y}px;
  height: 0px;
  width: 0px;
`;

const ModelCommentWrapper = styled.div`
  position: relative;
`;

const CommentIconOuter = styled.div<{ unread: boolean; open: boolean }>`
  ${({ unread, open, theme }) => css`
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    border-radius: 16px;
    border-bottom-left-radius: 2px;
    border: 1.5px solid
      ${unread ? 'rgba(103, 121, 214, 1)' : theme.colors.grey[10]};
    background: white;
    cursor: pointer;

    box-shadow: 0px 1.7761286497116089px 1.4566044807434082px 0px
        rgba(0, 0, 0, 0.04),
      0px 2.6805734634399414px 4.118751049041748px 0px rgba(0, 0, 0, 0.05),
      0px 5px 11px 0px rgba(0, 0, 0, 0.1);

    ${open
      ? css`
          opacity: 1;
          background: ${unread ? 'white' : theme.colors.grey[10]};
        `
      : css`
          opacity: 0.7;
          &:hover {
            opacity: 1;
          }
        `}
  `}
`;
const CommentIconInner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 99px;
  background: #008f8d;
  font-size: 12px;
  font-weight: 700;
  font-family: 'Barlow';
  color: white;
  flex-shrink: 0;
  user-select: none;
`;
const CommentIcon = ({
  onClick,
  initials,
  unread,
  open,
}: {
  onClick: React.MouseEventHandler | undefined;
  initials: string;
  unread: boolean;
  open: boolean;
}) => (
  <CommentIconOuter unread={unread} open={open} onClick={onClick}>
    <CommentIconInner>{initials}</CommentIconInner>
  </CommentIconOuter>
);

const CommentInputBoxOuter = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  width: 300px;
  height: 40px;
  padding: 0 8px;
  left: 40px;
  bottom: -4px;
  border: 1px solid ${({ theme }) => theme.colors.grey[10]};
  border-radius: 4px;
  background: white;

  box-shadow: 0px 3.3299999237060547px 3.9000000953674316px 0px
      rgba(0, 0, 0, 0.02),
    0px 7.090000152587891px 14px 0px rgba(0, 0, 0, 0.04),
    0px 19px 30px 0px rgba(0, 0, 0, 0.09);

  > * {
    margin-right: 8px;
  }

  > input {
    border-radius: 2px;
  }

  > *:last-child {
    margin-right: 0;
    flex: 1;
  }
`;
const CommentInputBox = ({
  initials,
  onSubmit,
}: {
  initials: string;
  onSubmit: (content: string) => void;
}) => {
  const [submitting, setSubmitting] = React.useState(false);
  const [text, setText] = React.useState<string>('');

  const onSubmitInner = () => {
    if (text.trim().length > 0) {
      onSubmit(text);
      setSubmitting(true);
    }
  };

  return (
    <CommentInputBoxOuter>
      <CommentIconInner>{initials}</CommentIconInner>
      <Input
        autoFocus
        hasBorder
        value={text}
        onChangeText={setText}
        onKeyDown={(e) => {
          if (e.key === 'Enter') onSubmitInner();
        }}
        RightIcon={ArrowUp}
        onClickRightIcon={onSubmitInner}
        disabled={submitting}
      />
    </CommentInputBoxOuter>
  );
};

const nameToInitials = (userName?: string | null) => {
  const splitName = (userName || 'No name').split(' ');
  const first = splitName[0];

  if (splitName.length < 2) {
    if (first.length < 1) {
      return 'NN';
    }

    return first[0].toUpperCase();
  }

  const last = splitName.pop() || '';

  return `${first[0].toUpperCase()} ${last[0].toUpperCase()}`;
};

const CommentContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 300px;
  padding: 0 16px 16px;
  top: -36px;
  left: 40px;
  border: 1px solid ${({ theme }) => theme.colors.grey[10]};
  border-radius: 4px;
  background: white;

  box-shadow: 0px 3.3299999237060547px 3.9000000953674316px 0px
      rgba(0, 0, 0, 0.02),
    0px 7.090000152587891px 14px 0px rgba(0, 0, 0, 0.04),
    0px 19px 30px 0px rgba(0, 0, 0, 0.09);

  .clickable {
    cursor: pointer;
  }
`;
const CommentHead = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  margin-bottom: 16px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey[10]};
`;
const CommentPager = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  user-select: none;

  svg {
    opacity: 0.2;

    &.enabled {
      opacity: 0.4;
    }

    &.enabled:hover {
      cursor: pointer;
      opacity: 0.8;
    }
  }
`;
const CommentBody = styled.div`
  display: flex;
`;
const CommentInfo = styled.div`
  margin-left: 8px;
`;
const CommentName = styled.div`
  font-weight: 500;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 4px;
`;
const CommentDate = styled.div`
  font-weight: 400;
  opacity: 0.73;
  margin-left: 8px;
`;
const CommentTextContent = styled.div`
  font-weight: 400;
  margin-top: 4px;
  font-size: 12px;
  line-height: 1.3em;
`;
const ClearButtonContainer = styled.div`
  flex: 1;
  display: flex;

  svg {
    opacity: 0.4;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }
`;

const CommentContent = ({
  uuid,
  name,
  initials,
  text,
  postedAt,
  onClose,
  onNext,
  onPrev,
  onDelete,
  index,
  fullCount,
}: {
  uuid: string;
  name: string;
  initials: string;
  text: string;
  postedAt: string;
  onClose?: () => void;
  onNext?: () => void;
  onPrev?: () => void;
  onDelete?: (commentUuid: string) => void;
  index: number;
  fullCount: number;
}) => {
  const formattedPostTime = React.useMemo(
    () => formatDate(postedAt),
    [postedAt],
  );

  return (
    <CommentContentWrapper>
      <CommentHead>
        <CommentPager>
          <ArrowLeft
            onClick={onPrev}
            className={index > 0 ? 'enabled' : undefined}
          />
          {index + 1} / {fullCount}
          <ArrowRight
            onClick={onNext}
            className={index < fullCount - 1 ? 'enabled' : undefined}
          />
        </CommentPager>
        <ClearButtonContainer>
          <Checked onClick={onDelete ? () => onDelete(uuid) : undefined} />
        </ClearButtonContainer>
        <Close
          className="clickable"
          onClick={onClose}
          fill="rgba(173, 184, 184, 1)"
        />
      </CommentHead>
      <CommentBody>
        <CommentIconInner>{initials}</CommentIconInner>
        <CommentInfo>
          <CommentName>
            {name}
            <CommentDate>{formattedPostTime}</CommentDate>
          </CommentName>
          <CommentTextContent>{text}</CommentTextContent>
        </CommentInfo>
      </CommentBody>
    </CommentContentWrapper>
  );
};

const SingleModelComment = ({
  forInput,
  anchorOverride,
  index,
  fullCount,
  comment,
  onSubmit,
  parentPath,
  initialsOverride,
  isOpen,
  onOpen,
  onNext,
  onPrev,
  onClose,
  onDelete,
  readCommentUuidList,
}: {
  forInput?: boolean;
  anchorOverride?: ModelCommentAnchorType;
  index?: number;
  fullCount?: number;
  comment?: ModelComment;
  onSubmit?: (comtent: string) => void;
  parentPath: string;
  initialsOverride?: string;
  isOpen?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
  onNext?: () => void;
  onPrev?: () => void;
  onDelete?: (commentUuid: string) => void;
  readCommentUuidList: string[];
}) => {
  const dispatch = useAppDispatch();

  const usingAnchor = anchorOverride || comment?.uiprops.anchor;

  const anchorBlockCoord = useAppSelector((state) => {
    if (usingAnchor?.kind !== 'block') return;
    return state.model.present.blockPositionCache[usingAnchor.nodeUuid];
  });

  const unread = React.useMemo(() => {
    if (!comment) return true;
    return !readCommentUuidList.includes(comment.uuid);
  }, [readCommentUuidList, comment]);

  const anchorRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (!anchorRef.current) return;
    const anchorEl = anchorRef.current;

    anchorEl.addEventListener('wheel', wheelDisabler, {
      passive: false,
    });

    return () => {
      if (!anchorEl) return;
      anchorEl.removeEventListener('wheel', wheelDisabler);
    };
  }, [anchorRef]);

  const commenterInitials = React.useMemo(() => {
    if (!comment) return 'NN';
    return nameToInitials(comment.owner_name);
  }, [comment]);
  const initials = initialsOverride || commenterInitials || 'NN';

  if (comment && comment.uiprops.parentPath !== parentPath) {
    return null;
  }

  const commentX =
    usingAnchor?.kind === 'canvas'
      ? usingAnchor.x
      : usingAnchor?.kind === 'block'
      ? anchorBlockCoord?.x || 0
      : 0;
  const commentY =
    usingAnchor?.kind === 'canvas'
      ? usingAnchor.y
      : usingAnchor?.kind === 'block'
      ? anchorBlockCoord?.y || 0
      : 0;

  const closeMe = () => {
    if (forInput) {
      dispatch(
        uiFlagsActions.setUIFlag({
          commenting: { addingNew: false },
        }),
      );
    } else if (onClose) {
      onClose();
    }
  };

  return (
    <ModelCommentAnchor
      ref={anchorRef}
      className="inverted-scale"
      x={commentX}
      y={commentY}>
      <ModelCommentWrapper>
        <CommentIcon
          open={isOpen || forInput || false}
          unread={forInput ? false : unread}
          initials={initials}
          onClick={isOpen ? closeMe : onOpen}
        />
        {forInput && onSubmit && (
          <CommentInputBox initials={initials} onSubmit={onSubmit} />
        )}
        {!forInput && comment && isOpen && (
          <CommentContent
            uuid={comment.uuid}
            index={index || 0}
            onNext={onNext}
            onPrev={onPrev}
            fullCount={fullCount || 0}
            onClose={onClose}
            onDelete={onDelete}
            name={comment.owner_name}
            initials={initials}
            text={comment.content}
            postedAt={comment.created_at}
          />
        )}
      </ModelCommentWrapper>
    </ModelCommentAnchor>
  );
};

export const ModelCommentsDisplay = ({
  parentPath,
}: {
  parentPath: string;
}) => {
  const dispatch = useAppDispatch();

  const modelUuid = useAppSelector(
    (state) => state.modelMetadata.loadedModelId,
  );
  const topLevelModelType = useAppSelector(
    (state) => state.submodels.topLevelModelType,
  );
  const commentingUiFlags = useAppSelector((state) => state.uiFlags.commenting);

  const isSubmodel = topLevelModelType === 'Submodel';

  const myUserInitials = React.useMemo(() => nameToInitials(getUserName()), []);

  const { data: regularComments, isSuccess: commentsLoaded } =
    useGetCommentsReadAllQuery({ modelUuid }, { skip: isSubmodel });
  const { data: submodelComments, isSuccess: submodelCommentsLoaded } =
    useGetSubmodelCommentsReadAllQuery(
      { submodelUuid: modelUuid },
      { skip: !isSubmodel },
    );

  const processedComments: ModelComment[] = React.useMemo(() => {
    if (isSubmodel) {
      if (!submodelCommentsLoaded || !submodelComments.comments) return [];

      return submodelComments.comments.map((comment) => ({
        ...comment,
        uiprops: comment.uiprops as ModelCommentUiProps,
      }));
    }

    if (!commentsLoaded || !regularComments.comments) return [];

    return regularComments.comments.map((comment) => ({
      ...comment,
      uiprops: comment.uiprops as ModelCommentUiProps,
    }));
  }, [
    regularComments,
    submodelComments,
    commentsLoaded,
    submodelCommentsLoaded,
    isSubmodel,
  ]);

  // need to activate positional cache for block-anchored comments
  React.useEffect(() => {
    if (processedComments.length > 0) {
      for (const comment of processedComments) {
        if (comment.uiprops.anchor.kind === 'block') {
          dispatch(
            modelActions.addBlockToPositionCache({
              nodeUuid: comment.uiprops.anchor.nodeUuid,
            }),
          );
        }
      }
    }
  }, [processedComments, dispatch]);

  // localizing this for now for ease of integration.
  // easy enough to send it up once proper DB relations exist in the backend
  const defaultReadCommentIds: string[] = React.useMemo(() => {
    let readDefaults = [];

    try {
      readDefaults = JSON.parse(
        window.localStorage.getItem('readCommentIds') || '[]',
      );
    } catch {
      window.localStorage.setItem('readCommentIds', '[]');
    }

    return readDefaults;
  }, []);
  const [readCommentUuids, setReadComments_raw] = React.useState<string[]>(
    defaultReadCommentIds,
  );
  const addReadCommentUuid = React.useCallback(
    (uuid: string) => {
      setReadComments_raw((allReadUuids) => {
        if (allReadUuids.includes(uuid)) return allReadUuids;

        const newReadUuids = [...allReadUuids, uuid];
        window.localStorage.setItem(
          'readCommentIds',
          JSON.stringify(newReadUuids),
        );
        return newReadUuids;
      });
    },
    [setReadComments_raw],
  );

  const [openCommentIdx, setOpenComment_raw] = React.useState<
    number | undefined
  >();
  const setOpenComment = React.useCallback(
    (newVal: number | undefined) => {
      if (newVal === undefined) {
        setOpenComment_raw(undefined);
        return;
      }

      const safeVal = Math.max(
        0,
        Math.min(processedComments.length - 1, newVal),
      );

      addReadCommentUuid(processedComments[safeVal].uuid);
      setOpenComment_raw(safeVal);
    },
    [processedComments, setOpenComment_raw, addReadCommentUuid],
  );

  const [callDeleteComment] = useDeleteCommentByUuidMutation();
  const clearComment = React.useCallback(
    (commentUuid: string) => {
      callDeleteComment({ modelUuid, commentUuid });
    },
    [modelUuid, callDeleteComment],
  );

  // necessary because the parent updates at 60fps
  const cachedCommentDisplays = React.useMemo(
    () =>
      processedComments.map((comment, i) => (
        <SingleModelComment
          key={comment.uuid}
          index={i}
          fullCount={processedComments.length}
          isOpen={openCommentIdx === i}
          onNext={() => setOpenComment(i + 1)}
          onPrev={() => setOpenComment(i - 1)}
          comment={comment}
          parentPath={parentPath}
          readCommentUuidList={readCommentUuids}
          onOpen={() => {
            setOpenComment(i);
          }}
          onClose={() => setOpenComment(undefined)}
          onDelete={clearComment}
        />
      )),
    [
      processedComments,
      parentPath,
      readCommentUuids,
      setOpenComment,
      openCommentIdx,
      clearComment,
    ],
  );

  const [callPostCommentApi] = usePostCommentCreateMutation();
  const [callPostSubmodelCommentApi] = usePostSubmodelCommentCreateMutation();

  const onSubmit = (content: string) => {
    if (!commentingUiFlags.addingNew) return;

    const newCommentUiProps = {
      parentPath,
      anchor: commentingUiFlags.commentAnchorData,
    };

    if (isSubmodel) {
      callPostSubmodelCommentApi({
        submodelUuid: modelUuid,
        commentCreateRequest: {
          content,
          uiprops: newCommentUiProps,
        },
      }).then(() => {
        dispatch(
          uiFlagsActions.setUIFlag({
            commenting: { addingNew: false },
          }),
        );
      });
    } else {
      callPostCommentApi({
        modelUuid,
        commentCreateRequest: {
          content,
          uiprops: newCommentUiProps,
        },
      }).then(() => {
        dispatch(
          uiFlagsActions.setUIFlag({
            commenting: { addingNew: false },
          }),
        );
      });
    }
  };

  React.useEffect(() => {
    if (commentingUiFlags.addingNew) {
      const closeCommentInput = () => {
        dispatch(
          uiFlagsActions.setUIFlag({
            commenting: { addingNew: false },
          }),
        );
      };

      const canvas = document.querySelector('canvas#model-renderer-canvas');

      if (canvas) {
        // This is not a hack, we actually have to do it this way.
        // the "adding comment" state change propagates so quickly that when we enable it, this click event still catches on the same "tick".
        // The reason it previously worked for core.Group blocks is
        // it literally does the same thing - it waits for a double-click using a setTimeout.
        // - jackson
        setTimeout(
          () => canvas.addEventListener('click', closeCommentInput),
          500,
        );
      }

      return () => {
        const canvas = document.querySelector('canvas#model-renderer-canvas');

        if (canvas) {
          canvas.removeEventListener('click', closeCommentInput);
        }
      };
    }
  }, [commentingUiFlags.addingNew, dispatch]);

  return (
    <>
      {commentingUiFlags.addingNew && (
        <SingleModelComment
          key="inputter"
          forInput
          initialsOverride={myUserInitials}
          anchorOverride={commentingUiFlags.commentAnchorData}
          parentPath={parentPath}
          onSubmit={onSubmit}
          readCommentUuidList={readCommentUuids}
        />
      )}
      {cachedCommentDisplays}
    </>
  );
};
