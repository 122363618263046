import { Middleware, MiddlewareAPI } from 'redux';

/**
 * Error handler for calls made using RTK Query.
 * For other calls that can produce a 401 error,
 * see apiData.ts
 */
export const rtkQueryErrorLogger: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    // If a 401 error occurs because the user is logged out on the backend,
    // make sure to redirect them to the sign in page.
    if (action.type?.startsWith('api/') && action.payload?.status === 401) {
      // @jp disabled this redirect because it causes lots of flashing
      // window.location.assign('/login/sign-in');
      console.error('Unauthorized request (401)');
    }

    return next(action);
  };
