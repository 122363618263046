import { registerClerkUser } from 'app/apiData';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const SignedUp: React.FC = () => {
  const navigate = useNavigate();
  React.useEffect(() => {
    registerClerkUser(true, () => navigate('/'), console.error);
  }, [navigate]);
  return <div />;
};

export default SignedUp;
