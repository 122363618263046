/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const StarEmpty: React.FC<Props> = (props) => (
  <svg viewBox="0 0 24 24" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M9.57804 8.99715C9.5052 9.15505 9.35556 9.26377 9.18288 9.28425L4.51699 9.83746C4.0933 9.8877 3.92314 10.4114 4.23639 10.7011L7.686 13.8912C7.81367 14.0092 7.87082 14.1852 7.83693 14.3557L6.92124 18.9642C6.83808 19.3827 7.28356 19.7063 7.65587 19.4979L11.7558 17.203C11.9076 17.118 12.0925 17.118 12.2443 17.203L16.3442 19.4979C16.7165 19.7063 17.162 19.3827 17.0789 18.9642L16.1632 14.3557C16.1293 14.1852 16.1864 14.0092 16.3141 13.8912L19.7637 10.7011C20.077 10.4114 19.9068 9.8877 19.4831 9.83746L14.8172 9.28425C14.6445 9.26377 14.4949 9.15505 14.422 8.99715L12.4541 4.73058C12.2754 4.34314 11.7247 4.34314 11.546 4.73058L9.57804 8.99715ZM12 7.32751L10.9401 9.62542C10.6488 10.257 10.0502 10.6919 9.35949 10.7738L6.84652 11.0718L8.70442 12.7899C9.2151 13.2622 9.44373 13.9658 9.30817 14.648L8.81499 17.1301L11.0232 15.8941C11.6301 15.5543 12.37 15.5543 12.9769 15.8941L15.1851 17.1301L14.6919 14.648C14.5564 13.9658 14.785 13.2622 15.2957 12.7899L17.1536 11.0718L14.6406 10.7738C13.9499 10.6919 13.3513 10.257 13.06 9.62542L12 7.32751Z"
      fill={props.fill}
    />
  </svg>
);

StarEmpty.defaultProps = {
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default StarEmpty;
