import React from 'react';
import { t } from '@lingui/macro';
import { useNotifications } from 'ui/common/notifications/useNotifications';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { usePutSubmodelUpdateMutation } from 'app/apiGenerated/generatedApi';
import {
  PutSubmodelUpdateApiArg,
  SubmodelInfoLite,
  ParameterDefinition,
  SubmodelConfiguration,
} from 'app/apiGenerated/generatedApiTypes';
import { submodelsActions } from 'app/slices/submodelsSlice';
import { modelMetadataActions } from 'app/slices/modelMetadataSlice';
import {
  ModelDiagram,
  StateMachineDiagram,
  SubmodelsSection,
} from 'app/generated_types/SimulationModel';
import { projectActions } from 'app/slices/projectSlice';
import { isInvalidModelVersionError } from 'app/api/useModels';
import { WebSocketMessageType } from 'app/third_party_types/websocket/websocket-message-type';
import { useCustomPartySocket } from 'ui/common/PartySocketProvider';

export function useUpdateSubmodel() {
  const dispatch = useAppDispatch();

  const customPartySocket = useCustomPartySocket();

  const { preventSendingUpdateData } = useAppSelector((state) => ({
    preventSendingUpdateData: state.model.present.preventSendingUpdateData,
  }));

  const { showError } = useNotifications();

  const [callUpdateSubmodelApi] = usePutSubmodelUpdateMutation();

  const updateSubmodel = React.useCallback(
    (request: PutSubmodelUpdateApiArg) => {
      if (preventSendingUpdateData) return;

      dispatch(projectActions.startModelUpdate(request.submodelUuid));

      callUpdateSubmodelApi(request)
        .unwrap()
        .then((response: SubmodelInfoLite) => {
          dispatch(
            modelMetadataActions.updateCurrentModelVersion({
              modelId: response.uuid,
              editId: response.edit_id,
              updatedAt: response.updated_at || '',
            }),
          );

          customPartySocket.publish({
            id: 'irrelevant right now',
            type: WebSocketMessageType.BCAST_DOC_UPDATE,
            payload: { documentId: request.submodelUuid },
          });

          // TODO show some kind of "working indicator to know that the model parameters
          // are being saved
        })
        .catch((e) => {
          if (e && isInvalidModelVersionError(e)) {
            showError(
              t({
                id: 'submodelApi.submodelOutOfDateError',
                message:
                  'Unable to apply change because submodel was out of date. Reloading model to get latest changes.',
              }),
            );

            dispatch(modelMetadataActions.reportVersionError(e));
            dispatch(projectActions.requestReloadModel());
          } else {
            showError(
              t({
                id: 'submodelApi.updateSubmodelContentError',
                message: 'Unable to update submodel content.',
              }),
              e,
            );

            dispatch(projectActions.requestReloadModel());
          }
        })
        .finally(() => {
          dispatch(projectActions.completeModelUpdate(request.submodelUuid));
        });
    },
    [
      callUpdateSubmodelApi,
      dispatch,
      showError,
      preventSendingUpdateData,
      customPartySocket,
    ],
  );

  const updateSubmodelName = React.useCallback(
    ({
      projectId,
      submodelId,
      editId,
      newName,
    }: {
      projectId: string;
      submodelId: string;
      editId: string;
      newName: string;
    }) => {
      updateSubmodel({
        projectUuid: projectId,
        submodelUuid: submodelId,
        submodelUpdateRequest: {
          edit_id: editId,
          name: newName,
        },
      });
    },
    [updateSubmodel],
  );

  const updateSubmodelDescription = React.useCallback(
    ({
      projectId,
      submodelId,
      editId,
      newDescription,
    }: {
      projectId: string;
      submodelId: string;
      editId: string;
      newDescription: string;
    }) => {
      updateSubmodel({
        projectUuid: projectId,
        submodelUuid: submodelId,
        submodelUpdateRequest: {
          edit_id: editId,
          description: newDescription,
        },
      });
    },
    [updateSubmodel],
  );

  const updateSubmodelContent = React.useCallback(
    ({
      projectId,
      submodelId,
      editId,
      diagram,
      submodels,
      stateMachines,
      name,
      parameterDefinitions,
      submodelConfiguration,
    }: {
      projectId: string;
      submodelId: string;
      editId: string;
      diagram: ModelDiagram;
      submodels: SubmodelsSection;
      stateMachines?: { [k: string]: StateMachineDiagram | undefined };
      name: string;
      parameterDefinitions: ParameterDefinition[];
      submodelConfiguration?: SubmodelConfiguration;
    }) => {
      updateSubmodel({
        projectUuid: projectId,
        submodelUuid: submodelId,
        submodelUpdateRequest: {
          edit_id: editId,
          diagram,
          submodels,
          state_machines: stateMachines,
          name,
          parameter_definitions: parameterDefinitions,
          configuration: submodelConfiguration,
        },
      });
    },
    [updateSubmodel],
  );

  return {
    updateSubmodel,
    updateSubmodelName,
    updateSubmodelDescription,
    updateSubmodelContent,
  };
}
