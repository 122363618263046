import { t } from '@lingui/macro';
import {
  useGetFileReadByUuidQuery,
  useGetProjectReadByUuidQuery,
} from 'app/apiGenerated/generatedApi';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { projectActions } from 'app/slices/projectSlice';
import React from 'react';
import { useNotifications } from 'ui/common/notifications/useNotifications';

const PROCESSING_POLL_INTERVAL = 2000;

/**
 * Only called for files that are 'in progress' when the project list is first loaded.
 * This hook will poll the file status until it is no longer 'in progress'.
 * See `useProjectItems` for where this file comes from.
 */
export function useFileProcessingStatus(
  projectId: string,
  fileIdToWatch: string,
) {
  const dispatch = useAppDispatch();

  const forceUpdateFileStatus = useAppSelector(
    (state) => state.project.forceUpdateFileStatus,
  );

  const { showInfo, showError } = useNotifications();

  const timerIdRef = React.useRef<any>(null);

  const {
    data: fileData,
    error,
    refetch,
    isFetching,
  } = useGetFileReadByUuidQuery({
    projectUuid: projectId,
    fileUuid: fileIdToWatch,
  });

  const { refetch: refetchProject } = useGetProjectReadByUuidQuery(
    { projectUuid: projectId || '' },
    { skip: !projectId },
  );

  // If the file processing hasn't yet completed, trigger another update for later.
  React.useEffect(() => {
    if (!isFetching && fileData) {
      if (fileData.summary.status === 'processing_in_progress') {
        if (timerIdRef.current) {
          clearTimeout(timerIdRef.current);
        }

        timerIdRef.current = setTimeout(() => {
          dispatch(projectActions.requestFileStatusRefetch());
        }, PROCESSING_POLL_INTERVAL);
      } else {
        // Probably should check for 'processing_completed'.
        // The reason it can work with out here is that this hook is only called
        // if the project list starts out with a file 'processing_in_progress',
        // so the only possible `else` from there is 'processing_completed'.
        // Noting all that here since that logic is fragmented across `useProjectItems` and `ProjectDetails`..
        // and I don't want to dig through it again.
        refetchProject();
      }
    }
  }, [dispatch, showInfo, fileData, isFetching, refetchProject]);

  // Show error status if we are unable to get a file status result.
  React.useEffect(() => {
    if (!isFetching && error) {
      showError(
        t({
          id: 'fileApi.failedToRetrieveFileProcessingStatus',
          message: 'Failed to get file processing status.',
        }),
        error,
      );
    }
  }, [error, isFetching, showError]);

  // This effect's lifetime matches the lifetime of the component
  // to ensure the timer is cleaned up properly.
  React.useEffect(
    () => () => {
      if (timerIdRef.current) {
        clearTimeout(timerIdRef.current);
      }
    },
    [],
  );

  // Watch for requests to fetch file updates and then call the update API.
  React.useEffect(() => {
    if (forceUpdateFileStatus) {
      refetch();
      dispatch(projectActions.clearFileStatusRefetch());
    }
  }, [dispatch, forceUpdateFileStatus, refetch]);
}
