import { useProjectOrRedirect } from 'app/behaviorHooks/useProjectOrRedirect';
import { useGetBusTypeQuery } from 'app/enhancedApi';
import { NavbarContext, uiFlagsActions } from 'app/slices/uiFlagsSlice';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Spinner, SpinnerWrapper } from 'ui/common/Spinner';
import { useAppParams } from 'util/useAppParams';
import BusTypeEditor, { BusTypeEditorBackground } from './BusTypeEditor';

const ValidateRouteBusType: React.FC = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(uiFlagsActions.setNavbarContext(NavbarContext.BusTypeEditor));
    return () => {
      dispatch(uiFlagsActions.setNavbarContext(NavbarContext.None));
    };
  }, [dispatch]);

  const { isLoadingProject, project } = useProjectOrRedirect();
  const projectUuid = project?.uuid;

  const { busTypeId } = useAppParams();

  const { data, isLoading: isBusTypeLoading } = useGetBusTypeQuery(
    {
      projectUuid: projectUuid || '',
      busTypeId: busTypeId || '',
    },
    { skip: !projectUuid || !busTypeId },
  );

  if (!busTypeId) {
    console.error('Invalid bus type UUID for Bus Type Editor.');
    return null;
  }

  if (isLoadingProject || isBusTypeLoading) {
    return (
      <BusTypeEditorBackground>
        <SpinnerWrapper>
          <Spinner />
        </SpinnerWrapper>
      </BusTypeEditorBackground>
    );
  }

  if (!project || !projectUuid) {
    console.error('Unable to load project for Bus Type Editor.');
    return null;
  }

  if (!data) {
    console.error('Unable to load bus type for Bus Type Editor.');
    return null;
  }

  return (
    <BusTypeEditor
      key={busTypeId}
      projectUuid={projectUuid}
      busTypeId={busTypeId}
      name={data.name}
      signals={data.definition.signals}
    />
  );
};

export default ValidateRouteBusType;
