import { PortConnection } from '@collimator/model-schemas-ts/src/schemas/SimulationModel';
import { Coordinate } from 'app/common_types/Coordinate';
import { FakeSegmentType } from 'app/common_types/SegmentTypes';
import { TimeModeValueType } from 'app/common_types/TimeModeTypesData';
import {
  FakeTappedSegmentType,
  ModelConfiguration,
  ModelDiagram,
  SubmodelsSection,
} from 'app/generated_types/SimulationModel';
import { getLocalSubmodelDiagram } from 'app/utils/modelDiagramUtils';
import { renderConstants } from 'app/utils/renderConstants';

export interface LinkPayload {
  source?: PortConnection;
  destination?: PortConnection;
}

export type ModelConfigurationType = keyof ModelConfiguration;
export type SolverType = NonNullable<ModelConfiguration['solver']>;
export type ModelConfigurationSolverType = keyof SolverType;

export interface ModelParameter {
  name: string;
  value: string;
}

export type ModelParameters = ModelParameter[];

interface TimeModePayload {
  paramName: 'time_mode';
  value: TimeModeValueType;
}

export type CommonParamPayload = TimeModePayload;

export const fakeSegmentStringMap: {
  [k in FakeSegmentType]: FakeTappedSegmentType;
} = {
  [FakeSegmentType.Start]: 'start',
  [FakeSegmentType.SStart]: 'sstart',
  [FakeSegmentType.End]: 'end',
  [FakeSegmentType.SEnd]: 'send',
  [FakeSegmentType.SMiddle]: 'smiddle',
};

// FIXME: change nomenclature for nonNegative/postive acrosss the codebase
export function buildNumericalChange(
  value: string | undefined,
  isNonNegative?: boolean,
): number | undefined {
  const numberValue = parseFloat(value || '');
  if (
    !isNaN(numberValue) &&
    (isNonNegative
      ? numberValue >= 0
      : numberValue > 0) /* && numberValue !== serverValue */
  ) {
    return numberValue;
  }
}

export const getCurrentParentModelDiagram = (
  rootModel: ModelDiagram,
  submodels: SubmodelsSection,
  currentSubmodelPath: string[],
): ModelDiagram | null => {
  if (currentSubmodelPath.length === 0) {
    return null;
  }
  if (currentSubmodelPath.length === 1) {
    return rootModel;
  }
  const parentUuid = currentSubmodelPath[currentSubmodelPath.length - 2];
  return getLocalSubmodelDiagram(submodels, parentUuid);
};

export const snapNumberToGrid = (number: number) =>
  Math.round(number / renderConstants.GRID_UNIT_PXSIZE) *
  renderConstants.GRID_UNIT_PXSIZE;

export const snapCoordinateToGrid = (coord: Coordinate): Coordinate => ({
  x: snapNumberToGrid(coord.x),
  y: snapNumberToGrid(coord.y),
});
