import styled from '@emotion/styled';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import RehypeKatex from 'rehype-katex';
import RemarkMathPlugin from 'remark-math';

const li = styled.li`
  list-style-type: disc;
  line-height: ${({ theme }) => theme.typography.font.heading4.lineHeight};

  font-size: ${({ theme }) => theme.typography.font.standard.size};
  font-weight: ${({ theme }) => theme.typography.font.standard.weight};
  font-family: ${({ theme }) => theme.typography.font.standard.fontFamily};
  line-height: ${({ theme }) => theme.typography.font.standard.lineHeight};
  letter-spacing: ${({ theme }) =>
    theme.typography.font.standard.letterSpacing};

  > ul > li {
    list-style-type: circle;
  }
`;

const ul = styled.ul`
  list-style-type: circle;
  line-height: 0px;

  font-size: ${({ theme }) => theme.typography.font.standard.size};
  font-weight: ${({ theme }) => theme.typography.font.standard.weight};
  font-family: ${({ theme }) => theme.typography.font.standard.fontFamily};
  line-height: ${({ theme }) => theme.typography.font.standard.lineHeight};
  letter-spacing: ${({ theme }) =>
    theme.typography.font.standard.letterSpacing};
`;

const ol = styled.ol`
  list-style-type: decimal;
  line-height: 0px;
`;

const h1 = styled.h1`
  font-size: ${({ theme }) => theme.typography.font.heading1.size};
  font-weight: ${({ theme }) => theme.typography.font.heading1.weight};
  font-family: ${({ theme }) => theme.typography.font.heading1.fontFamily};
  line-height: ${({ theme }) => theme.typography.font.heading1.lineHeight};
  letter-spacing: ${({ theme }) =>
    theme.typography.font.heading1.letterSpacing};
  padding-top: ${({ theme }) => theme.spacing.normal};
  padding-bottom: ${({ theme }) => theme.spacing.normal};
`;

const h2 = styled.h2`
  font-size: ${({ theme }) => theme.typography.font.heading2.size};
  font-weight: ${({ theme }) => theme.typography.font.heading2.weight};
  font-family: ${({ theme }) => theme.typography.font.heading2.fontFamily};
  line-height: ${({ theme }) => theme.typography.font.heading2.lineHeight};
  letter-spacing: ${({ theme }) =>
    theme.typography.font.heading2.letterSpacing};
  padding-top: ${({ theme }) => theme.spacing.normal};
  padding-bottom: ${({ theme }) => theme.spacing.normal};
`;

const h3 = styled.h3`
  font-size: ${({ theme }) => theme.typography.font.heading3.size};
  font-weight: ${({ theme }) => theme.typography.font.heading3.weight};
  font-family: ${({ theme }) => theme.typography.font.heading3.fontFamily};
  line-height: ${({ theme }) => theme.typography.font.heading3.lineHeight};
  letter-spacing: ${({ theme }) =>
    theme.typography.font.heading3.letterSpacing};
  padding-top: ${({ theme }) => theme.spacing.normal};
  padding-bottom: ${({ theme }) => theme.spacing.normal};
`;

const h4 = styled.h4`
  font-size: ${({ theme }) => theme.typography.font.heading4.size};
  font-weight: ${({ theme }) => theme.typography.font.heading4.weight};
  font-family: ${({ theme }) => theme.typography.font.heading4.fontFamily};
  line-height: ${({ theme }) => theme.typography.font.heading4.lineHeight};
  letter-spacing: ${({ theme }) =>
    theme.typography.font.heading4.letterSpacing};
`;

const h5 = styled.h5`
  font-size: ${({ theme }) => theme.typography.font.heading5.size};
  font-weight: ${({ theme }) => theme.typography.font.heading5.weight};
  font-family: ${({ theme }) => theme.typography.font.heading5.fontFamily};
  line-height: ${({ theme }) => theme.typography.font.heading5.lineHeight};
  letter-spacing: ${({ theme }) =>
    theme.typography.font.heading5.letterSpacing};
`;

const p = styled.p`
  font-size: ${({ theme }) => theme.typography.font.standard.size};
  font-weight: ${({ theme }) => theme.typography.font.standard.weight};
  font-family: ${({ theme }) => theme.typography.font.standard.fontFamily};
  line-height: ${({ theme }) => theme.typography.font.standard.lineHeight};
  letter-spacing: ${({ theme }) =>
    theme.typography.font.standard.letterSpacing};
`;

const pre = styled.pre`
  font-size: ${({ theme }) => theme.typography.font.code.size};
  font-weight: ${({ theme }) => theme.typography.font.code.weight};
  font-family: ${({ theme }) => theme.typography.font.code.fontFamily};
  line-height: ${({ theme }) => theme.typography.font.code.lineHeight};
  letter-spacing: ${({ theme }) => theme.typography.font.code.letterSpacing};

  color: #00f; // Code editors are in blue
  background-color: ${({ theme }) => theme.colors.grey[10]};
  padding: ${({ theme }) => theme.spacing.small};
`;

const img = styled.img`
  max-width: 100%;
  height: auto;
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

const MarkdownWrapper = styled.div`
  padding-top: ${({ theme }) => theme.spacing.normal};
`;

export interface Props {
  contents: string;
}

// NOTE: We could also use remark-gfm to support github-style markdown.
export const MarkdownViewer: React.FC<Props> = ({ contents }: Props) => (
  <MarkdownWrapper>
    <ReactMarkdown
      remarkPlugins={[RemarkMathPlugin]}
      rehypePlugins={[RehypeKatex]}
      components={{ ol, ul, li, h1, h2, h3, h4, h5, pre, p, img }}>
      {contents}
    </ReactMarkdown>
  </MarkdownWrapper>
);
