/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  className?: string;
}

const TreeArrowCollapsed: React.FC<Props> = (props) => (
  <svg viewBox="0 0 24 24" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.2074 13.2952L10.8934 10.2754C10.7277 10.1594 10.5 10.2779 10.5 10.4802V16.5198C10.5 16.7221 10.7277 16.8406 10.8934 16.7246L15.2074 13.7048C15.3496 13.6053 15.3496 13.3947 15.2074 13.2952Z"
      fill={props.fill}
    />
  </svg>
);

TreeArrowCollapsed.defaultProps = {
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  className: '',
};

export default TreeArrowCollapsed;
