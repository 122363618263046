/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  className?: string;
}

const CoordinaterotationQuaternion: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.62 12.28C17.62 12.2333 17.634 12.196 17.662 12.168C17.6993 12.1307 17.7413 12.112 17.788 12.112H19.076C19.1227 12.112 19.16 12.1307 19.188 12.168C19.2253 12.196 19.244 12.2333 19.244 12.28V21.744C19.244 21.7907 19.2253 21.828 19.188 21.856C19.16 21.8933 19.1227 21.912 19.076 21.912H17.788C17.7413 21.912 17.6993 21.8933 17.662 21.856C17.634 21.828 17.62 21.7907 17.62 21.744V18.706C17.62 18.678 17.6107 18.664 17.592 18.664C17.5733 18.6547 17.5547 18.6593 17.536 18.678C17.1253 19.1447 16.598 19.378 15.954 19.378C15.2633 19.378 14.6893 19.2053 14.232 18.86C13.7747 18.5147 13.4433 18.034 13.238 17.418C13.0793 16.942 13 16.3727 13 15.71C13 15.0567 13.084 14.4733 13.252 13.96C13.4573 13.344 13.7887 12.8633 14.246 12.518C14.7033 12.1727 15.2633 12 15.926 12C16.5887 12 17.1253 12.2427 17.536 12.728C17.5547 12.756 17.5733 12.7653 17.592 12.756C17.6107 12.7467 17.62 12.728 17.62 12.7V12.28ZM17.256 17.362C17.4893 16.9793 17.606 16.4193 17.606 15.682C17.606 14.982 17.4987 14.4407 17.284 14.058C17.0227 13.61 16.654 13.386 16.178 13.386C15.646 13.386 15.2493 13.6147 14.988 14.072C14.764 14.492 14.652 15.0287 14.652 15.682C14.652 16.3913 14.792 16.9607 15.072 17.39C15.3427 17.782 15.716 17.978 16.192 17.978C16.6493 17.978 17.004 17.7727 17.256 17.362Z"
      fill={props.fill}
    />
  </svg>
);

CoordinaterotationQuaternion.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  className: '',
};

export default CoordinaterotationQuaternion;
