import styled from '@emotion/styled/macro';
import { t } from '@lingui/macro';
import { useGetProjectGitRemoteQuery } from 'app/apiGenerated/generatedApi';
import React from 'react';
import Button from 'ui/common/Button/Button';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import { Download } from 'ui/common/Icons/Standard';
import Input from 'ui/common/Input/Input';
import Label from 'ui/common/Label';
import {
  ActionButtonContainer,
  ModalInputGroup,
  SmallFormContainer,
} from 'ui/common/Modal/Modal';
import { useModal } from 'ui/common/Modal/useModal';
import SelectInput, { SelectInputOption } from 'ui/common/SelectInput';
import { Spinner } from 'ui/common/Spinner';
import { useNotifications } from 'ui/common/notifications/useNotifications';
import { isValidBranchName } from 'util/gitUtils';
import { useAppParams } from 'util/useAppParams';

const StyledForm = styled(SmallFormContainer)`
  label {
    color: ${({ theme }) => theme.colors.text.secondary};
  }
`;

export const GitCheckoutBranchModal = () => {
  const { closeModal } = useModal();
  const { projectId } = useAppParams();
  const { showError } = useNotifications();

  const { data: remoteInfo, isLoading } = useGetProjectGitRemoteQuery(
    { projectUuid: projectId || '', expand: ['branches', 'remote_branches'] },
    { skip: !projectId, refetchOnMountOrArgChange: true },
  );

  const [branchName, setBranchName] = React.useState('');
  const [checkingOut, setCheckingOut] = React.useState(false);

  const remoteBrancheNames = React.useMemo(
    () => remoteInfo?.remote_branches?.map((branch) => branch.name) || [],
    [remoteInfo],
  );
  const existingBranchOptions: SelectInputOption[] = React.useMemo(
    () =>
      remoteBrancheNames.map((branch) => ({
        label: branch,
        value: branch,
      })),
    [remoteBrancheNames],
  );

  const isExistingBranch = React.useMemo(
    () => remoteBrancheNames.find((branch) => branch === branchName),
    [branchName, remoteBrancheNames],
  );

  const onChangeNewBranchName = (value: string) => {
    setBranchName(value);
  };

  const onPushChanges = () => {
    closeModal();
    setCheckingOut(true);
    showError('Sorry, this is not implemented yet!');
    // TODO
  };

  return (
    <StyledForm
      onSubmit={(e) => {
        e?.preventDefault();
        onPushChanges();
      }}>
      <ModalInputGroup>
        <Label>
          {t({
            id: 'gitCheckoutBranchModal.selectExistingBranch.label',
            message: 'Select an existing branch to check out',
          })}
        </Label>
        <SelectInput
          options={existingBranchOptions}
          onSelectValue={setBranchName}
          currentValue={branchName}
          isDisabled={isLoading}
        />
      </ModalInputGroup>
      <ModalInputGroup>
        <Label>
          {t({
            id: 'gitCheckoutBranchModal.createNewBranchInput.label',
            message: 'Or create a new branch with the following name',
          })}
        </Label>
        <Input
          value={branchName}
          hasBorder
          autoFocus
          onChangeText={onChangeNewBranchName}
        />
      </ModalInputGroup>
      <ActionButtonContainer>
        <Button
          type="button"
          onClick={closeModal}
          variant={ButtonVariants.LargeSecondary}>
          {t({
            id: 'gitCheckoutBranchModal.cancelButton.label',
            message: 'Cancel',
          })}
        </Button>
        <Button
          type="submit"
          Icon={isLoading ? Spinner : Download}
          variant={ButtonVariants.LargePrimary}
          disabled={
            isLoading ||
            checkingOut ||
            !isValidBranchName(branchName, remoteBrancheNames)
          }>
          {isExistingBranch
            ? t({
                id: 'gitCheckoutBranchModal.checkoutButton.label',
                message: 'Checkout branch',
              })
            : t({
                id: 'gitCheckoutBranchModal.createNewBranch.label',
                message: 'Create new branch',
              })}
        </Button>
      </ActionButtonContainer>
    </StyledForm>
  );
};
