/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Sequence: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M22.25 4.75H30C30.4142 4.75 30.75 5.08579 30.75 5.5C30.75 5.91421 30.4142 6.25 30 6.25H23.75V13.25H16.75V20.25H9.75V27.25H2C1.58579 27.25 1.25 26.9142 1.25 26.5C1.25 26.0858 1.58579 25.75 2 25.75H8.25V18.75H15.25V11.75H22.25V4.75Z"
      fill={props.fill}
    />
  </svg>
);

Sequence.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Sequence;
