/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  className?: string;
}

const PythonCode: React.FC<Props> = (props) => (
  <svg viewBox="0 0 16 16" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.88416 2.0001C7.39305 2.00236 6.92406 2.04387 6.51139 2.11625C5.29572 2.32912 5.075 2.77467 5.075 3.59634V4.68151H7.94777V5.04324H3.99688C3.16197 5.04324 2.4309 5.54062 2.20224 6.48682C1.93847 7.57139 1.92677 8.24817 2.20224 9.38063C2.40644 10.2236 2.89412 10.8242 3.72902 10.8242H4.71674V9.52333C4.71674 8.58351 5.53715 7.75452 6.51139 7.75452H9.38081C10.1796 7.75452 10.8172 7.10268 10.8172 6.30762V3.59634C10.8172 2.82469 10.1604 2.24503 9.38081 2.11625C8.88731 2.03483 8.37526 1.99784 7.88416 2.0001ZM6.33058 2.87289C6.62732 2.87289 6.86965 3.11699 6.86965 3.41713C6.86965 3.71621 6.62732 3.95806 6.33058 3.95806C6.03278 3.95806 5.79152 3.71621 5.79152 3.41713C5.79152 3.11699 6.03278 2.87289 6.33058 2.87289Z"
      fill={props.fill}
    />
    <path
      d="M11.1755 5.04324V6.30762C11.1755 7.28788 10.337 8.11293 9.38081 8.11293H6.51139C5.72541 8.11293 5.075 8.77967 5.075 9.55983V12.2711C5.075 13.0428 5.752 13.4966 6.51139 13.718C7.42074 13.983 8.29277 14.0309 9.38081 13.718C10.104 13.5105 10.8172 13.0928 10.8172 12.2711V11.1859H7.94777V10.8242H12.2536C13.0885 10.8242 13.3996 10.247 13.69 9.38063C13.9899 8.48871 13.9771 7.63099 13.69 6.48682C13.4836 5.66302 13.0895 5.04324 12.2536 5.04324H11.1755ZM9.56161 11.9094C9.85941 11.9094 10.1007 12.1512 10.1007 12.4503C10.1007 12.7505 9.85941 12.9946 9.56161 12.9946C9.26487 12.9946 9.02255 12.7505 9.02255 12.4503C9.02255 12.1512 9.26487 11.9094 9.56161 11.9094Z"
      fill={props.fill}
    />
  </svg>
);

PythonCode.defaultProps = {
  width: '16',
  height: '16',
  viewBox: '0 0 16 16',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  className: '',
};

export default PythonCode;
