/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Search: React.FC<Props> = (props) => (
  <svg viewBox="0 0 16 16" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M7.27273 4.5C5.74139 4.5 4.5 5.74139 4.5 7.27273C4.5 8.80406 5.74139 10.0455 7.27273 10.0455C8.80406 10.0455 10.0455 8.80406 10.0455 7.27273C10.0455 5.74139 8.80406 4.5 7.27273 4.5ZM3.5 7.27273C3.5 5.18911 5.18911 3.5 7.27273 3.5C9.35635 3.5 11.0455 5.18911 11.0455 7.27273C11.0455 8.1343 10.7567 8.92842 10.2706 9.56355L12.3535 11.6465C12.5487 11.8417 12.5487 12.1583 12.3535 12.3536C12.1582 12.5488 11.8416 12.5488 11.6464 12.3536L9.56345 10.2706C8.92833 10.7567 8.13425 11.0455 7.27273 11.0455C5.18911 11.0455 3.5 9.35635 3.5 7.27273Z"
      fill={props.fill}
    />
  </svg>
);

Search.defaultProps = {
  width: '16',
  height: '16',
  viewBox: '0 0 16 16',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Search;
