import { t } from '@lingui/macro';
import TooltipButton from 'ui/common/Button/TooltipButton';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import * as Icons from 'ui/common/Icons';
import { useModal } from 'ui/common/Modal/useModal';
import { ProjectFilter } from 'util/projectFilterUtils';
import React from 'react';
import ManageMembersModal from '../../members/ManageMembersModal';

type Props = {
  projectFilter: ProjectFilter;
  selectionState: Record<string, boolean>;
  numSelected: number;
};

export const ManageMembersProjectButton: React.FC<Props> = ({
  selectionState,
  numSelected,
}: Props) => {
  const { triggerModal } = useModal();

  const selectedUuid = React.useMemo(() => {
    for (const uuid in selectionState) {
      if (selectionState[uuid]) {
        return uuid;
      }
    }
  }, [selectionState]);

  const createProjectModal = () =>
    triggerModal(
      <ManageMembersModal projectId={selectedUuid as string} />,
      t({
        id: 'projects.manageMembersModalDialog.title',
        message: 'Manage members',
      }),
    );

  const tooltip = t({
    id: 'dashboard.projects.manageMembersProjectButton.Label',
    message: 'Manage Members',
  });

  return (
    <TooltipButton
      tooltip={tooltip}
      Icon={Icons.Standard.Account}
      largeIcon
      variant={ButtonVariants.LargeSecondary}
      disabled={numSelected > 1}
      onClick={createProjectModal}
      testId="manage-members-project-button"
    />
  );
};
