import { t } from '@lingui/macro';
import React from 'react';
import TooltipButton from 'ui/common/Button/TooltipButton';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import { Rename } from 'ui/common/Icons/Standard';
import { ProjectItemType } from 'ui/dashboard/dashboardTypes';
import { useProjectDetailActions } from 'ui/dashboard/projectDetail/useProjectDetailActions';
import { ButtonProps } from './ButtonProps';

export const ProjectElementRenameButton: React.FC<ButtonProps> = ({
  selectedItems,
  numSelected,
  disabled,
}) => {
  const { renameElement, type } = useProjectDetailActions(selectedItems);

  const hasValidType =
    type === ProjectItemType.MODEL ||
    type === ProjectItemType.SUBMODEL ||
    type === ProjectItemType.PROJECTFILE ||
    type === ProjectItemType.BUS_TYPE;

  const tooltip = t({
    id: 'dashboard.projectsDetailActionButtons.renameButton.Label',
    message: 'Rename',
  });

  if (numSelected === 1 && hasValidType) {
    return (
      <TooltipButton
        tooltip={tooltip}
        variant={ButtonVariants.SmallPrimary}
        testId="project-element-rename-button"
        Icon={Rename}
        largeIcon
        onClick={renameElement}
        disabled={disabled}
      />
    );
  }
  return (
    <TooltipButton
      tooltip={tooltip}
      variant={ButtonVariants.SmallPrimary}
      testId="project-element-rename-button"
      Icon={Rename}
      largeIcon
      disabled
    />
  );
};
