import styled from '@emotion/styled/macro';
import { t } from '@lingui/macro';
import { useAppDispatch } from 'app/hooks';
import { dataExplorerActions } from 'app/slices/dataExplorerSlice';
import React from 'react';
import Menu from 'ui/common/Menu/Menu';
import {
  ChangedItem,
  ItemType,
  MenuItemConfig,
} from 'ui/common/Menu/menuItemTypes';
import { SIGNAL_COLORS } from './constants';

const TraceContextMenuWrapper = styled.div<{
  top: number;
  left: number;
  show: boolean;
}>`
  ${({ top, left, show }) => `
    opacity: ${show ? '100%' : '0%'};
    width: 1px;
    height: 1px;
    position: absolute;
    top: ${top}px;
    left: ${left}px;
  `}
`;

const Color = styled.div<{ color: string }>`
  width: 120px;
  height: 100%;
  background-color: ${({ color }) => color};
`;

interface Props {
  top: number;
  left: number;
  traceId: string;
}

const TraceContextMenu: React.FC<Props> = ({ top, left, traceId }) => {
  const dispatch = useAppDispatch();

  if (!traceId) {
    return null;
  }

  const onSubmitValue = (selections: ChangedItem) => {
    dispatch(
      dataExplorerActions.setTraceColor({
        traceId,
        color: selections.color[0] as string,
      }),
    );
  };

  const items: MenuItemConfig[] = [
    {
      type: ItemType.Button,
      content: t`Remove this trace`,
      shortcut: {
        keyStrings: ['Del'],
        code: 'Delete',
      },
      onClick: () => {
        dispatch(
          dataExplorerActions.removeTraces({
            traceIds: [traceId],
          }),
        );
      },
    },
    { type: ItemType.Separator },
    {
      type: ItemType.Title,
      text: t`Change color:`,
    },
    {
      type: ItemType.Select,
      id: 'color',
      options: SIGNAL_COLORS.map((color) => ({
        id: color,
        content: <Color color={color} />,
      })),
    },
  ];
  return (
    <TraceContextMenuWrapper top={top} left={left} show>
      <Menu items={items} startOpen onSubmitValue={onSubmitValue} />
    </TraceContextMenuWrapper>
  );
};

export default TraceContextMenu;
