import { DiagramAutoSaveVersion } from 'app/apiTransformers/convertGetSnapshotReadAll';
import { useAppDispatch } from 'app/hooks';
import { versionHistoryActions } from 'app/slices/versionHistorySlice';
import React from 'react';
import Button from 'ui/common/Button/Button';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import MenuIcon from 'ui/common/Icons/Standard/Menu';
import { validNameRules } from 'ui/common/Input/inputValidation';
import Menu from 'ui/common/Menu/Menu';
import DiagramVersionIcon from 'ui/versionHistory/DiagramVersionIcon';
import {
  AuthorAvatarContainer,
  AuthorAvatarSpace,
  VersionAutoSaveDate,
  VersionItemInfoSection,
  VersionMenuContainer,
  VersionMetadata,
  VersionNameContainer,
  VersionNameInput,
  VersionSegment,
} from 'ui/versionHistory/DiagramVersionItemParts';
import { useVersionItemMenu } from 'ui/versionHistory/useVersionItemMenu';
import { formatVersionTimestamp } from 'util/dateUtils';

interface Props {
  version: DiagramAutoSaveVersion;
  renameVersion: (
    modelUuid: string,
    versionUuid: string,
    newVersionName: string,
  ) => void;
  restoreVersion: (modelUuid: string, versionUuid: string) => void;
  canEditModelMetadata: boolean;
  projectId: string;
  urlModelId: string;
  urlVersionId?: string;
  nextItemId?: string;
}

const DiagramVersionAutoSaveItem: React.FC<Props> = ({
  version,
  renameVersion,
  restoreVersion,
  canEditModelMetadata,
  projectId,
  urlModelId,
  urlVersionId,
  nextItemId,
}) => {
  const dispatch = useAppDispatch();

  const initialVersionName = formatVersionTimestamp(version.createdAt);
  const {
    menuItems,
    isVersionSelected,
    navigateToItem,
    endRename,
    setLatestVersionName,
    startRename,
    latestVersionName,
    isRenaming,
  } = useVersionItemMenu({
    thisModelId: version.model_uuid,
    thisVersionId: version.uuid,
    urlProjectId: projectId,
    urlModelId,
    urlVersionId,
    isWorkingVersion: false,
    initialVersionName,
    canEditModelMetadata,
    restoreVersion,
  });

  const commitRename = React.useCallback(
    (newVersionName: string) => {
      if (canEditModelMetadata && newVersionName) {
        setLatestVersionName(newVersionName);
        renameVersion(version.model_uuid, version.uuid, newVersionName);
      }
      endRename();

      // Set the next item as expanded
      if (nextItemId) {
        dispatch(
          versionHistoryActions.setAutoSaveVersionExpanded({
            versionId: nextItemId,
          }),
        );
      }
    },
    [
      endRename,
      canEditModelMetadata,
      renameVersion,
      version,
      setLatestVersionName,
      nextItemId,
      dispatch,
    ],
  );

  const [isHovered, setIsHovered] = React.useState(false);
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);

  return (
    <VersionSegment
      isSelected={isVersionSelected}
      onClick={navigateToItem}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}>
      {/* Version metadata section */}
      <VersionItemInfoSection>
        <AuthorAvatarContainer>
          <AuthorAvatarSpace />
        </AuthorAvatarContainer>
        <DiagramVersionIcon source={version.source} />

        <VersionMetadata>
          <VersionNameContainer onDoubleClick={startRename}>
            {isRenaming && (
              <VersionNameInput
                hasBorder
                autoFocus
                onSubmitValue={commitRename}
                onCancel={endRename}
                value={latestVersionName}
                validationRules={validNameRules}
              />
            )}
            <VersionAutoSaveDate isRenaming={isRenaming}>
              {latestVersionName}
            </VersionAutoSaveDate>
          </VersionNameContainer>
        </VersionMetadata>
        <VersionMenuContainer>
          {canEditModelMetadata &&
            (isHovered || isVersionSelected || isMenuOpen) && (
              <Menu
                items={menuItems}
                onClose={() => setIsMenuOpen(false)}
                onActive={setIsMenuOpen}>
                <Button
                  Icon={MenuIcon}
                  variant={ButtonVariants.LargeTertiary}
                />
              </Menu>
            )}
        </VersionMenuContainer>
      </VersionItemInfoSection>
    </VersionSegment>
  );
};

export default DiagramVersionAutoSaveItem;
