/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Cppfunction: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.5 3.25C7.25736 3.25 6.25 4.25736 6.25 5.5V26.5C6.25 27.7426 7.25736 28.75 8.5 28.75H23.5C24.7426 28.75 25.75 27.7426 25.75 26.5V25.6654L24.25 26.5C24.25 26.9142 23.9142 27.25 23.5 27.25H8.5C8.08579 27.25 7.75 26.9142 7.75 26.5V5.5C7.75 5.08579 8.08579 4.75 8.5 4.75H23.5C23.9142 4.75 24.25 5.08579 24.25 5.5V13.5L25.75 14.4125V5.5C25.75 4.25736 24.7426 3.25 23.5 3.25H8.5Z"
      fill={props.fill}
    />
    <path
      d="M9.5 8C9.5 7.72386 9.72386 7.5 10 7.5H22C22.2761 7.5 22.5 7.72386 22.5 8C22.5 8.27614 22.2761 8.5 22 8.5H10C9.72386 8.5 9.5 8.27614 9.5 8Z"
      fill={props.fill}
    />
    <path
      d="M10 11C9.72386 11 9.5 11.2239 9.5 11.5C9.5 11.7761 9.72386 12 10 12H22C22.2761 12 22.5 11.7761 22.5 11.5C22.5 11.2239 22.2761 11 22 11H10Z"
      fill={props.fill}
    />
    <path
      d="M9.5 15C9.5 14.7239 9.72386 14.5 10 14.5H14C14.2761 14.5 14.5 14.7239 14.5 15C14.5 15.2761 14.2761 15.5 14 15.5H10C9.72386 15.5 9.5 15.2761 9.5 15Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M27.8601 16.875C27.7737 16.724 27.6547 16.5921 27.5237 16.5157L22.9763 13.8646C22.7144 13.7118 22.2856 13.7118 22.0237 13.8646L17.4763 16.5157C17.2143 16.6684 17 17.0434 17 17.3488V22.6512C17 22.8039 17.0536 22.974 17.1399 23.125C17.2263 23.276 17.3453 23.4079 17.4763 23.4843L22.0237 26.1354C22.2856 26.2882 22.7143 26.2882 22.9763 26.1354L27.5237 23.4843C27.6547 23.4079 27.7737 23.276 27.86 23.125C27.9464 22.974 28 22.8039 28 22.6512V17.3488C28 17.1961 27.9464 17.026 27.8601 16.875ZM25.6753 18.1475C25.0213 17.0063 23.8046 16.2974 22.5 16.2974C20.4782 16.2974 18.8333 17.9584 18.8333 20C18.8333 20.6738 19.0125 21.3061 19.3252 21.851C19.9601 22.9571 21.1454 23.7027 22.5 23.7027C23.8047 23.7027 25.0214 22.9937 25.6754 21.8524L24.0881 20.9259C23.7607 21.4968 23.1522 21.8513 22.5 21.8513C21.8227 21.8513 21.23 21.4785 20.9126 20.9255C20.7562 20.6531 20.6667 20.3369 20.6667 20C20.6667 18.9792 21.4891 18.1487 22.5 18.1487C23.1522 18.1487 23.7607 18.5033 24.0881 19.0741L25.6753 18.1475ZM26.1667 19.7943H25.7592V19.3829H25.3519V19.7943H24.9444V20.2057H25.3519V20.6171H25.7592V20.2057H26.1667V19.7943ZM27.287 19.7943H27.6944V20.2057H27.287V20.6171H26.8797V20.2057H26.4722V19.7943H26.8797V19.3829H27.287V19.7943Z"
      fill={props.fill}
    />
  </svg>
);

Cppfunction.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Cppfunction;
