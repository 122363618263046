import styled from '@emotion/styled/macro';
import { t } from '@lingui/macro';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { modelActions } from 'app/slices/modelSlice';
import { renderConstants } from 'app/utils/renderConstants';
import React from 'react';
import { TextInputAlign } from 'ui/common/Input/inputTypes';
import { numberRules } from 'ui/common/Input/inputValidation';
import SectionHeading from 'ui/common/Inputs/SectionHeading';
import SelectInput from 'ui/common/SelectInput';
import DescriptionField from 'ui/modelEditor/DescriptionField';
import {
  DetailInputRowsSection,
  DetailsDoubleRow,
  DetailsInput,
  DetailsLabel,
  DetailsSection,
} from 'ui/modelEditor/DetailsComponents';
import {
  annotationColorMap,
  annotationColorOrder,
} from 'ui/modelRendererInternals/drawAnnotation';

interface Props {
  selectedAnnotationId: string;
  canEditCurrentModelVersion: boolean;
}

const ShortDescriptionField = styled(DescriptionField)`
  height: 50px;
`;

const textPosOptions = [
  {
    label: t({
      id: 'modelRenderer.propertiesSidebar.annotationTextPosTop',
      message: 'Top',
    }),
    value: 'top',
  },
  {
    label: t({
      id: 'modelRenderer.propertiesSidebar.annotationTextPosBtm',
      message: 'Bottom',
    }),
    value: 'bottom',
  },
  {
    label: t({
      id: 'modelRenderer.propertiesSidebar.annotationTextPosInside',
      message: 'Inside',
    }),
    value: 'inside',
  },
];

export const AnnotationDetails: React.FC<Props> = ({
  selectedAnnotationId,
  canEditCurrentModelVersion,
}) => {
  const dispatch = useAppDispatch();

  const formatLabel = React.useCallback(
    ({ label: hex }: { label: string }) => (
      <div
        style={{
          width: 72,
          height: 16,
          borderRadius: 2,
          background: `#${hex}`,
        }}
      />
    ),
    [],
  );

  const currentDiagram = useAppSelector(
    (state) => state.modelMetadata.currentDiagram,
  );

  const selectedAnnotation = currentDiagram?.annotations?.find(
    (anno) => anno.uuid === selectedAnnotationId,
  );

  const annotationWidth = React.useMemo(
    () =>
      (selectedAnnotation?.grid_width || 0) * renderConstants.GRID_UNIT_PXSIZE,
    [selectedAnnotation?.grid_width],
  );

  const annotationHeight = React.useMemo(
    () =>
      (selectedAnnotation?.grid_height || 0) * renderConstants.GRID_UNIT_PXSIZE,
    [selectedAnnotation?.grid_height],
  );

  if (!selectedAnnotation) return null;

  const changeAnnotationCoordinates = (rawX: string, rawY: string) => {
    if (!canEditCurrentModelVersion) return;

    const x = parseFloat(rawX);
    const y = parseFloat(rawY);

    if (isNaN(x) || isNaN(y)) {
      console.warn(
        'Something went wrong with the coordinate input, x:',
        x,
        'y: ',
        y,
      );
    } else {
      dispatch(
        modelActions.moveAnnotationsTo({
          uuids: [selectedAnnotation.uuid],
          x:
            Math.round(x / renderConstants.GRID_UNIT_PXSIZE) *
            renderConstants.GRID_UNIT_PXSIZE,
          y:
            Math.round(y / renderConstants.GRID_UNIT_PXSIZE) *
            renderConstants.GRID_UNIT_PXSIZE,
        }),
      );
    }
  };

  const changeAnnotationSize = (rawW: string, rawH: string) => {
    if (!canEditCurrentModelVersion) return;

    const w = parseFloat(rawW);
    const h = parseFloat(rawH);

    if (isNaN(w) || isNaN(h)) {
      console.warn(
        'Something went wrong with the coordinate input, w:',
        w,
        'h: ',
        h,
      );
    } else {
      dispatch(
        modelActions.resizeAnnotation({
          uuid: selectedAnnotation.uuid,
          gridWidth: Math.round(w / renderConstants.GRID_UNIT_PXSIZE),
          gridHeight: Math.round(h / renderConstants.GRID_UNIT_PXSIZE),
        }),
      );
    }
  };

  const changeAnnotationText = (newText: string) => {
    dispatch(
      modelActions.setAnnotationText({
        uuid: selectedAnnotation.uuid,
        text: newText,
      }),
    );
  };

  const changeAnnotationColor = (newColorId: string) => {
    dispatch(
      modelActions.setAnnotationColor({
        uuid: selectedAnnotation.uuid,
        colorId: newColorId,
      }),
    );
  };

  const changeAnnotationLabelPos = (newLabelPos: string) => {
    dispatch(
      modelActions.setAnnotationLabelPos({
        uuid: selectedAnnotation.uuid,
        pos: newLabelPos,
      }),
    );
  };

  return (
    <>
      <SectionHeading testId="block-title">
        {t({
          message: 'Annotation',
          id: 'modelRenderer.propertiesSidebar.annotationTitle',
        })}
      </SectionHeading>
      <DetailInputRowsSection>
        <ShortDescriptionField
          testId="annotation-text-textarea"
          placeholder={t({
            id: 'modelRenderer.propertiesSidebar.annotationText.placeholder',
            message: 'Enter annotation details',
          })}
          savedDescription={selectedAnnotation.text || ''}
          saveDescription={changeAnnotationText}
          canEdit={canEditCurrentModelVersion}
        />
      </DetailInputRowsSection>
      <SectionHeading testId="annotation-panel-display-props">
        {t({
          id: 'modelRenderer.propertiesSidebar.annotationDisplayHeading.label',
          message: 'Display',
        })}
      </SectionHeading>
      <DetailInputRowsSection>
        <DetailsDoubleRow>
          <DetailsSection>
            <DetailsLabel
              shrink
              data-test-id="annotation-display-x-coord-label">
              X
            </DetailsLabel>
            <DetailsInput
              testId="annotation-display-x-coord-textbox"
              grow
              align={TextInputAlign.Right}
              value={selectedAnnotation.x}
              onSubmitValue={(newX: string) =>
                changeAnnotationCoordinates(newX, `${selectedAnnotation.y}`)
              }
              validationRules={numberRules}
              disabled={!canEditCurrentModelVersion}
            />
          </DetailsSection>
          <DetailsSection>
            <DetailsLabel
              shrink
              data-test-id="annotation-display-y-coord-label">
              Y
            </DetailsLabel>
            <DetailsInput
              testId="annotation-display-y-coord-textbox"
              grow
              align={TextInputAlign.Right}
              value={selectedAnnotation.y}
              onSubmitValue={(newY: string) =>
                changeAnnotationCoordinates(`${selectedAnnotation.x}`, newY)
              }
              validationRules={numberRules}
              disabled={!canEditCurrentModelVersion}
            />
          </DetailsSection>
        </DetailsDoubleRow>
        <DetailsDoubleRow>
          <DetailsSection>
            <DetailsLabel shrink data-test-id="annotation-display-width-label">
              W
            </DetailsLabel>
            <DetailsInput
              testId="annotation-display-width-textbox"
              grow
              align={TextInputAlign.Right}
              value={annotationWidth}
              onSubmitValue={(newW: string) =>
                changeAnnotationSize(newW, `${annotationHeight}`)
              }
              validationRules={numberRules}
              disabled={!canEditCurrentModelVersion}
            />
          </DetailsSection>
          <DetailsSection>
            <DetailsLabel shrink data-test-id="annotation-display-height-label">
              H
            </DetailsLabel>
            <DetailsInput
              testId="annotation-display-height-textbox"
              grow
              align={TextInputAlign.Right}
              value={annotationHeight}
              onSubmitValue={(newH: string) =>
                changeAnnotationSize(`${annotationWidth}`, newH)
              }
              validationRules={numberRules}
              disabled={!canEditCurrentModelVersion}
            />
          </DetailsSection>
        </DetailsDoubleRow>
        <DetailsSection>
          <DetailsLabel>
            {t({
              id: 'modelRenderer.propertiesSidebar.annotationColor',
              message: 'Color',
            })}
          </DetailsLabel>
          <SelectInput
            currentValue={selectedAnnotation.color_id || 'green'}
            onSelectValue={changeAnnotationColor}
            formatOptionLabel={formatLabel}
            options={annotationColorOrder.map((key) => ({
              value: key,
              label: annotationColorMap[key].hex,
            }))}
          />
        </DetailsSection>
        <DetailsSection>
          <DetailsLabel>
            {t({
              id: 'modelRenderer.propertiesSidebar.annotationLabelPosInputLabel',
              message: 'Label position',
            })}
          </DetailsLabel>
          <SelectInput
            currentValue={selectedAnnotation.label_position || 'bottom'}
            onSelectValue={changeAnnotationLabelPos}
            options={textPosOptions}
          />
        </DetailsSection>
      </DetailInputRowsSection>
    </>
  );
};
