/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Notebook: React.FC<Props> = (props) => (
  <svg viewBox="0 0 16 16" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M11 5L5.53333 5V12L11 12V5ZM5.53333 4C4.98104 4 4.53333 4.44771 4.53333 5V12C4.53333 12.5523 4.98104 13 5.53333 13H11C11.5523 13 12 12.5523 12 12V5C12 4.44772 11.5523 4 11 4H5.53333Z"
      fill={props.fill}
    />
    <path d="M7 6H10.2534V7H7V6Z" fill={props.fill} />
    <path d="M7 8H10.2534V9H7V8Z" fill={props.fill} />
    <path
      d="M4 6.5C4 6.22386 4.22386 6 4.5 6H5.5C5.77614 6 6 6.22386 6 6.5C6 6.77614 5.77614 7 5.5 7H4.5C4.22386 7 4 6.77614 4 6.5Z"
      fill={props.fill}
    />
    <path
      d="M4 8.5C4 8.22386 4.22386 8 4.5 8H5.5C5.77614 8 6 8.22386 6 8.5C6 8.77614 5.77614 9 5.5 9H4.5C4.22386 9 4 8.77614 4 8.5Z"
      fill={props.fill}
    />
    <path
      d="M4 10.5C4 10.2239 4.22386 10 4.5 10H5.5C5.77614 10 6 10.2239 6 10.5C6 10.7761 5.77614 11 5.5 11H4.5C4.22386 11 4 10.7761 4 10.5Z"
      fill={props.fill}
    />
  </svg>
);

Notebook.defaultProps = {
  width: '16',
  height: '16',
  viewBox: '0 0 16 16',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Notebook;
