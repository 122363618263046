import { VersionTagValues } from 'app/apiTransformers/convertPostSubmodelsFetch';
import { SubmodelInstance } from 'app/generated_types/SimulationModel';
import { useAppSelector } from 'app/hooks';
import { getCurrentModelRef } from 'app/sliceRefAccess/CurrentModelRef';
import { getNestedNode } from 'app/utils/modelDiagramUtils';
import React from 'react';
import { SubmodelItemLoader } from './SubmodelItemLoader';

interface Props {
  projectId: string;
  parentPath: string[];
  nodeId: string;
}

export const NestedSubmodelItemLoader: React.FC<Props> = ({
  projectId,
  parentPath,
  nodeId,
}) => {
  const idToVersionIdToSubmodelFull = useAppSelector(
    (state) => state.submodels.idToVersionIdToSubmodelFull,
  );
  const idToLatestTaggedVersionId = useAppSelector(
    (state) => state.submodels.idToLatestTaggedVersionId,
  );

  const node = getNestedNode(
    getCurrentModelRef().topLevelNodes,
    getCurrentModelRef().submodels,
    parentPath,
    nodeId,
    idToVersionIdToSubmodelFull,
    idToLatestTaggedVersionId,
  );

  // If this node is a reference submodel, make sure we load its diagram
  // and local submodels.
  const submodelInstance = node as SubmodelInstance;
  const submodelReferenceId = submodelInstance?.submodel_reference_uuid || '';
  const submodelVersionId = VersionTagValues.LATEST_VERSION;

  return (
    <SubmodelItemLoader
      projectId={projectId}
      submodelReferenceId={submodelReferenceId}
      submodelVersionId={submodelVersionId}
      loadReferencedSubmodelInfos
    />
  );
};
