/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const LogicXor: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M9.84152 6.26167C8.88091 6.23492 8.29832 7.23194 8.66869 8.04807C9.36718 9.5872 10.8214 13.2029 10.8214 16.4882C10.8214 19.6108 9.50306 22.514 8.7858 23.8559C8.35383 24.6641 8.90147 25.7503 9.9085 25.7411C13.6478 25.7069 17.3068 25.1342 20.059 23.7195C22.6424 22.3916 24.4594 20.2941 24.7182 17.25H28C28.4142 17.25 28.75 16.9142 28.75 16.5C28.75 16.0858 28.4142 15.75 28 15.75H24.7205C24.4706 12.6706 22.6575 10.3777 20.0715 8.83625C17.3071 7.18844 13.6241 6.36698 9.84152 6.26167ZM12.3214 16.4882C12.3214 13.1033 10.9494 9.51147 10.189 7.7746C13.6663 7.91941 16.9184 8.70299 19.3035 10.1247C21.7531 11.5849 23.25 13.6843 23.25 16.4882C23.25 19.2637 21.7853 21.1456 19.3732 22.3854C17.0065 23.602 13.7683 24.1673 10.2796 24.2356C11.0642 22.6973 12.3214 19.7336 12.3214 16.4882Z"
      fill={props.fill}
    />
    <path
      d="M7.16902 7.161C6.9818 6.79152 6.5305 6.64376 6.16101 6.83097C5.79158 7.01816 5.64381 7.46934 5.83089 7.8388L5.83192 7.84084L5.83639 7.84976L5.85547 7.88821C5.87255 7.92282 5.89813 7.97516 5.931 8.04378C5.99674 8.18105 6.09155 8.38331 6.20574 8.63917C6.43434 9.15138 6.73949 9.87584 7.04443 10.7219C7.21767 11.2026 7.38882 11.7171 7.54535 12.25H4C3.58579 12.25 3.25 12.5858 3.25 13C3.25 13.4142 3.58579 13.75 4 13.75H7.92932C8.12472 14.6576 8.25 15.5766 8.25 16.4392C8.25 17.3847 8.09911 18.3428 7.87089 19.25H4C3.58579 19.25 3.25 19.5858 3.25 20C3.25 20.4142 3.58579 20.75 4 20.75H7.4153C7.29973 21.0767 7.17869 21.3899 7.05671 21.6855C6.75505 22.4165 6.45332 23.0267 6.22776 23.4529C6.1151 23.6657 6.02179 23.8319 5.95747 23.9437C5.92532 23.9995 5.90044 24.0416 5.88405 24.0691L5.86598 24.0992L5.862 24.1057L5.86142 24.1067C5.64439 24.4592 5.75397 24.9212 6.10644 25.1384C6.45904 25.3558 6.92109 25.2462 7.13844 24.8936L6.5 24.5C7.13844 24.8936 7.13834 24.8937 7.13844 24.8936L7.13958 24.8917L7.14162 24.8884L7.14837 24.8773L7.17211 24.8378C7.19233 24.8039 7.22116 24.755 7.25737 24.6922C7.32977 24.5664 7.43177 24.3846 7.55349 24.1546C7.79668 23.6952 8.11995 23.0412 8.44329 22.2577C9.08491 20.7028 9.75 18.5828 9.75 16.4392C9.75 14.3052 9.08956 11.9724 8.45557 10.2133C8.13551 9.32525 7.81566 8.56591 7.57551 8.02783C7.45533 7.75855 7.35482 7.54406 7.28385 7.39586C7.24835 7.32175 7.22022 7.26417 7.20068 7.22456L7.17795 7.17878L7.17171 7.16633L7.16991 7.16277L7.16902 7.161C7.16895 7.16086 7.16902 7.161 6.5 7.49999L7.16902 7.161Z"
      fill={props.fill}
    />
  </svg>
);

LogicXor.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default LogicXor;
