import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { generatedApi } from 'app/apiGenerated/generatedApi';
import {
  DownsamplingAlgorithm,
  SimulationResultsS3Url,
} from 'app/apiGenerated/generatedApiTypes';
import React from 'react';

type FetchArgs = {
  chartIds: string[];
  files?: SimulationResultsS3Url[];
};

type FetchResponse = {
  raw: {
    filename: string;
    text?: string;
    blob?: Blob;
  }[];
};

const injectedApi = generatedApi.injectEndpoints({
  endpoints: (build) => ({
    getSignalFiles: build.query<FetchResponse, FetchArgs>({
      async queryFn(args, _queryApi, _extraOptions, _fetchWithBQ) {
        const { chartIds, files } = args;
        if (!files) return { data: { raw: [], processed: [] } };
        try {
          let promises = chartIds.map((chartId) => {
            const file = files.find((f) => chartId === f.name);
            if (file?.url) {
              return fetch(file.url).then((data) => ({
                filename: file.key?.split('/').pop() ?? '',
                data,
              }));
            }
            return Promise.reject(
              new Error(`File URL not found for chart ID ${chartId}`),
            );
          });
          const responses = await Promise.all(promises);
          const rawFiles = await Promise.all(
            responses.map(async (resp) => {
              const contentType = resp.data.headers.get('Content-Type');
              if (
                contentType &&
                (contentType.includes('text') || contentType.includes('json'))
              ) {
                return {
                  filename: resp.filename,
                  text: await resp.data.text(),
                };
              }
              return { filename: resp.filename, blob: await resp.data.blob() };
            }),
          );
          return {
            data: {
              raw: rawFiles,
            },
          };
        } catch (error: any) {
          return { error: error as FetchBaseQueryError };
        }
      },
    }),
  }),
});

export const { useLazyGetSignalFilesQuery } = injectedApi;

export function useVisualizerAllData() {
  const [isLoading, setIsLoading] = React.useState(false);
  const [triggerGetSignalFiles] = useLazyGetSignalFilesQuery();
  const [triggerProcessResult] =
    generatedApi.endpoints.getSimulationProcessResultsReadByUuid.useLazyQuery();

  const fetchAllData = async ({
    simulationId,
    modelId,
    signalNames,
    downsamplingAlgorithm,
  }: {
    simulationId: string;
    modelId: string;
    signalNames: string[];
    downsamplingAlgorithm?: DownsamplingAlgorithm;
  }) => {
    setIsLoading(true);
    downsamplingAlgorithm = downsamplingAlgorithm ?? 'LTTB';
    const files = await triggerProcessResult({
      modelUuid: modelId,
      simulationUuid: simulationId,
      signalNames: signalNames.join(','),
      downsamplingAlgorithm,
    }).unwrap();

    // FIXME: signalNames could have extension ".npz" in the case of ensemble sims
    // because we haven't implemented downsampling for ensemble sims results yet
    const chartIds = signalNames.map((signalName) =>
      signalName.replace('.npz', ''),
    );

    const response = await triggerGetSignalFiles({
      chartIds,
      files: files?.s3_urls,
    }).unwrap();
    setIsLoading(false);
    return response.raw;
  };
  return {
    isLoading,
    fetchAllData,
  };
}
