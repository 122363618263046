import {
  HoverEdgeSide,
  HoverEntityType,
  MouseActions,
} from 'app/common_types/MouseTypes';
import { isEntityInteractable } from './clickHandlers/isEntityInteractable';
import { RendererState } from './modelRenderer';

export type CursorVisualType =
  | 'default'
  | 'pointer'
  | 'grab'
  | 'grabbing'
  | 'crosshair'
  | 'copy'
  | 'not-allowed'
  | 'nwse-resize'
  | 'nesw-resize'
  | 'ew-resize'
  | 'ns-resize';

export const getCursorVisualState = (rs: RendererState): CursorVisualType => {
  // mouse's explicit current state
  // takes precedence over hovering entity
  if (
    rs.refs.current.uiFlags.addCommentToolActive ||
    rs.mouseState.state === MouseActions.ReadyToAddComment
  ) {
    return 'copy';
  }

  if (
    rs.refs.current.uiFlags.addingAnnotation ||
    rs.mouseState.state === MouseActions.MakingSelection
  ) {
    return 'crosshair';
  }

  let grabbing = false;
  if (
    rs.mouseState.state === MouseActions.Panning ||
    rs.mouseState.state === MouseActions.DraggingSelected ||
    rs.mouseState.state === MouseActions.DraggingLinkSegment ||
    rs.mouseState.draggingMode
  ) {
    grabbing = true;
  }

  if (
    rs.mouseState.state === MouseActions.ResizeNodeManually ||
    rs.mouseState.state === MouseActions.ResizeAnnotationManually ||
    (rs.hoveringEntity &&
      (rs.hoveringEntity.entityType === HoverEntityType.NodeResizeEdge ||
        rs.hoveringEntity.entityType === HoverEntityType.AnnotationResizeEdge))
  ) {
    const handleSides: HoverEdgeSide[] =
      rs.mouseState.state === MouseActions.ResizeNodeManually ||
      rs.mouseState.state === MouseActions.ResizeAnnotationManually
        ? rs.mouseState.handleSides
        : rs.hoveringEntity?.entityType === HoverEntityType.NodeResizeEdge ||
          rs.hoveringEntity?.entityType === HoverEntityType.AnnotationResizeEdge
        ? rs.hoveringEntity.handleSides
        : [];

    if (
      (handleSides.includes(HoverEdgeSide.Top) &&
        handleSides.includes(HoverEdgeSide.Left)) ||
      (handleSides.includes(HoverEdgeSide.Bottom) &&
        handleSides.includes(HoverEdgeSide.Right))
    ) {
      return 'nwse-resize';
    }

    if (
      (handleSides.includes(HoverEdgeSide.Top) &&
        handleSides.includes(HoverEdgeSide.Right)) ||
      (handleSides.includes(HoverEdgeSide.Bottom) &&
        handleSides.includes(HoverEdgeSide.Left))
    ) {
      return 'nesw-resize';
    }

    if (
      handleSides.includes(HoverEdgeSide.Right) ||
      handleSides.includes(HoverEdgeSide.Left)
    ) {
      return 'ew-resize';
    }

    if (
      handleSides.includes(HoverEdgeSide.Top) ||
      handleSides.includes(HoverEdgeSide.Bottom)
    ) {
      return 'ns-resize';
    }
  }

  if (rs.hoveringEntity) {
    const interactable = isEntityInteractable(rs, rs.hoveringEntity);
    if (!interactable) return 'not-allowed';
    return grabbing ? 'grabbing' : 'pointer';
  }

  if (grabbing) {
    return 'grabbing';
  }

  return 'default';
};
