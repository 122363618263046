import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserProfile } from 'app/apiGenerated/generatedApiTypes';
import { DISABLE_INTERCOM } from 'app/config/globalApplicationConfig';
import { getUserEmail } from 'ui/auth/utils';

interface WindowWithIntercomSettings extends Window {
  intercomSettings: {
    user_id: string;
    email: string | undefined;
  };
}

interface UserState {
  userIsAssociatedToBrowserSession: boolean;
  userId?: string;
}

const initialState: UserState = {
  userIsAssociatedToBrowserSession: false,
  userId: undefined,
};

/* Start of collimator intercom chat script */
/* eslint-disable */
function registerIntercom() {
  var w = window;
  var ic = (w as any).Intercom;
  if (typeof ic === 'function') {
    ic('reattach_activator');
    ic('update', (w as any).intercomSettings);
  } else {
    var d = document;
    var i = function () {
      (i as any).c(arguments);
    };
    (i as any).q = [];
    (i as any).c = function (args: any) {
      (i as any).q.push(args);
    };
    (w as any).Intercom = i;
    var l = function () {
      var s = d.createElement('script');
      s.type = 'text/javascript';
      s.async = true;
      s.src = 'https://widget.intercom.io/widget/qgghnhin';
      var x = d.getElementsByTagName('script')[0];
      (x as any).parentNode.insertBefore(s, x);
    };
    if (document.readyState === 'complete') {
      l();
    } else if ((w as any).attachEvent) {
      (w as any).attachEvent('onload', l);
    } else {
      w.addEventListener('load', l, false);
    }
  }
}
/* eslint-enable */
/* End of collimator intercom chat script */

const userSlice = createSlice({
  name: 'userSlice',
  initialState,
  reducers: {
    setUserAssociationToSession(state) {
      state.userIsAssociatedToBrowserSession = true;
    },
    setUserProfile(state, action: PayloadAction<UserProfile>) {
      const user = action.payload;
      const userId = user?.uuid;
      state.userId = userId;

      // Setup intercom for this user.
      if (DISABLE_INTERCOM) return;
      const windowWithIntercom =
        window as unknown as WindowWithIntercomSettings;
      if (windowWithIntercom && windowWithIntercom.intercomSettings) {
        windowWithIntercom.intercomSettings.user_id = userId;
        windowWithIntercom.intercomSettings.email = getUserEmail();
      }

      // Re-register so the email and id come through.
      registerIntercom();
    },
  },
});

export const userActions = userSlice.actions;

export default userSlice;
