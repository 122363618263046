import { useTheme } from '@emotion/react';
import { NodeInstance } from 'app/generated_types/SimulationModel';
import React from 'react';
import { Continuous } from 'ui/common/Icons/Small';
import {
  TreeArrowCollapsed,
  TreeArrowExpanded,
} from 'ui/common/Icons/Standard';
import { SignalDragItem } from 'ui/dataExplorer/dataExplorerTypes';
import DraggableSignalTreeItem from 'ui/dataExplorer/drag/signalDrag/DraggableSignalTreeItem';
import { RightPinnedVisualizerButton } from 'ui/dataExplorer/simulationDataSidebar/RightPinnedButton';
import { useSignal } from 'ui/dataExplorer/simulationDataSidebar/useSignal';
import {
  ItemSection,
  ModelBlockItemText,
  ModelTreeIcon,
} from 'ui/objectBrowser/ModelTreeParts';
import { getDefaultChartType } from 'util/timeMode';
import { ModelSimulationSignalTraceListItem } from './ModelSimulationSignalTraceListItem';

interface Props {
  modelId: string;
  modelVersionId: string;
  simulationId: string;
  signalPath: string;
  vectorIndices: number[];
  parentCount: number;
  parentNode: NodeInstance | undefined;
  defaultDisplayName: string;
  hasCsvSupport: boolean;
  canEditProject: boolean;
}

export const ModelSimulationSignalGroupListItem: React.FC<Props> = ({
  modelId,
  modelVersionId,
  simulationId,
  signalPath,
  vectorIndices,
  parentCount,
  parentNode,
  defaultDisplayName,
  hasCsvSupport,
  canEditProject,
}) => {
  const theme = useTheme();

  const [isExpanded, setIsExpanded] = React.useState(false);

  const toggleExpandedState = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    event.preventDefault();
    event.stopPropagation();

    setIsExpanded(!isExpanded);
  };

  const {
    isSupported,
    signalDisplayName,
    signalLeafDisplayName,
    isSignalInExploration,
    toggleVisualization,
    parentPath,
    nodeId,
    portIndex,
    timeMode,
  } = useSignal({
    modelId,
    modelVersionId,
    simulationId,
    signalPath,
    vectorIndices,
    parentNode,
    defaultDisplayName,
    hasCsvSupport,
    canEditProject,
  });

  const traceSpecs: SignalDragItem['traceSpecs'] = vectorIndices.map(
    (vectorIndex) => ({
      signalPath,
      tracePath: `${signalPath}[${vectorIndex}]`,
      vectorIndex,
      plotType: getDefaultChartType(timeMode),
      displayName: `${signalDisplayName}[${vectorIndex}]`,
      simulationId,
      modelId,
    }),
  );

  return (
    <>
      <ItemSection
        data-test-id={`data-explorer-model-simulation-signal-tree-model-item-${signalPath}`}
        selected={false}
        nestedLayer={parentCount + 2}>
        <ModelTreeIcon onClick={toggleExpandedState}>
          {isExpanded ? <TreeArrowExpanded /> : <TreeArrowCollapsed />}
        </ModelTreeIcon>
        <DraggableSignalTreeItem traceSpecs={traceSpecs}>
          <ModelTreeIcon>
            <Continuous fill={theme.colors.text.secondary} />
          </ModelTreeIcon>
          <div
            data-test-id={`data-explorer-model-simulation-signal-tree-model-item-text-${signalPath}`}>
            <ModelBlockItemText>{signalLeafDisplayName}</ModelBlockItemText>
          </div>
        </DraggableSignalTreeItem>
        {isSupported && (
          <RightPinnedVisualizerButton
            path={signalPath}
            isToggledOn={isSignalInExploration}
            canEditProject={canEditProject}
            toggleVisualization={toggleVisualization}
          />
        )}
      </ItemSection>

      {isExpanded &&
        vectorIndices.map((vectorIndex) => (
          <ModelSimulationSignalTraceListItem
            key={vectorIndex}
            modelId={modelId}
            canEditProject={canEditProject}
            simulationId={simulationId}
            signalPath={signalPath}
            vectorIndex={vectorIndex}
            parentCount={parentCount}
            signalDisplayName={signalDisplayName || signalPath}
            parentPath={parentPath}
            portIndex={portIndex}
            nodeId={nodeId}
            isSupported={isSupported}
          />
        ))}
    </>
  );
};
