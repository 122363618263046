// !!!! -- DO NOT MANUALLY EDIT -- !!!!
// This file was generated by something in scripts/generateDefaultBlockData.js
// If you want to change the contents of this file, it should be automated
//
// (You can edit this file locally, but it may not be checked into the codebase -
// PRs with a manual edit of this file are forbidden from being merged.)

import type { ComputationBlockClass } from 'app/generated_types/ComputationBlockClass';
import type { BlockClassName } from 'app/generated_types/SimulationModel';
import { computationBlockDefaults } from './computationBlockDefaults';

import { AcausalElectricalBattery } from 'app/generated_blocks/acausal/electrical/Battery';
import { AcausalElectricalCapacitor } from 'app/generated_blocks/acausal/electrical/Capacitor';
import { AcausalElectricalCurrentSensor } from 'app/generated_blocks/acausal/electrical/CurrentSensor';
import { AcausalElectricalCurrentSource } from 'app/generated_blocks/acausal/electrical/CurrentSource';
import { AcausalElectricalGround } from 'app/generated_blocks/acausal/electrical/Ground';
import { AcausalElectricalIdealDiode } from 'app/generated_blocks/acausal/electrical/IdealDiode';
import { AcausalElectricalIdealMotor } from 'app/generated_blocks/acausal/electrical/IdealMotor';
import { AcausalElectricalInductor } from 'app/generated_blocks/acausal/electrical/Inductor';
import { AcausalElectricalIntegratedMotor } from 'app/generated_blocks/acausal/electrical/IntegratedMotor';
import { AcausalElectricalResistor } from 'app/generated_blocks/acausal/electrical/Resistor';
import { AcausalElectricalVoltageSensor } from 'app/generated_blocks/acausal/electrical/VoltageSensor';
import { AcausalElectricalVoltageSource } from 'app/generated_blocks/acausal/electrical/VoltageSource';
import { AcausalHydraulicAccumulator } from 'app/generated_blocks/acausal/hydraulic/Accumulator';
import { AcausalHydraulicHydraulicActuatorLinear } from 'app/generated_blocks/acausal/hydraulic/HydraulicActuatorLinear';
import { AcausalHydraulicHydraulicProperties } from 'app/generated_blocks/acausal/hydraulic/HydraulicProperties';
import { AcausalHydraulicMassflowSensor } from 'app/generated_blocks/acausal/hydraulic/MassflowSensor';
import { AcausalHydraulicPipe } from 'app/generated_blocks/acausal/hydraulic/Pipe';
import { AcausalHydraulicPressureSensor } from 'app/generated_blocks/acausal/hydraulic/PressureSensor';
import { AcausalHydraulicPressureSource } from 'app/generated_blocks/acausal/hydraulic/PressureSource';
import { AcausalHydraulicPump } from 'app/generated_blocks/acausal/hydraulic/Pump';
import { AcausalRotationalDamper } from 'app/generated_blocks/acausal/rotational/Damper';
import { AcausalRotationalEngine } from 'app/generated_blocks/acausal/rotational/Engine';
import { AcausalRotationalFixedAngle } from 'app/generated_blocks/acausal/rotational/FixedAngle';
import { AcausalRotationalFriction } from 'app/generated_blocks/acausal/rotational/Friction';
import { AcausalRotationalGear } from 'app/generated_blocks/acausal/rotational/Gear';
import { AcausalRotationalIdealPlanetary } from 'app/generated_blocks/acausal/rotational/IdealPlanetary';
import { AcausalRotationalIdealWheel } from 'app/generated_blocks/acausal/rotational/IdealWheel';
import { AcausalRotationalInertia } from 'app/generated_blocks/acausal/rotational/Inertia';
import { AcausalRotationalMotionSensor } from 'app/generated_blocks/acausal/rotational/MotionSensor';
import { AcausalRotationalSpeedSource } from 'app/generated_blocks/acausal/rotational/SpeedSource';
import { AcausalRotationalSpring } from 'app/generated_blocks/acausal/rotational/Spring';
import { AcausalRotationalTorqueSensor } from 'app/generated_blocks/acausal/rotational/TorqueSensor';
import { AcausalRotationalTorqueSource } from 'app/generated_blocks/acausal/rotational/TorqueSource';
import { AcausalThermalHeatCapacitor } from 'app/generated_blocks/acausal/thermal/HeatCapacitor';
import { AcausalThermalHeatflowSensor } from 'app/generated_blocks/acausal/thermal/HeatflowSensor';
import { AcausalThermalHeatflowSource } from 'app/generated_blocks/acausal/thermal/HeatflowSource';
import { AcausalThermalInsulator } from 'app/generated_blocks/acausal/thermal/Insulator';
import { AcausalThermalRadiation } from 'app/generated_blocks/acausal/thermal/Radiation';
import { AcausalThermalTemperatureSensor } from 'app/generated_blocks/acausal/thermal/TemperatureSensor';
import { AcausalThermalTemperatureSource } from 'app/generated_blocks/acausal/thermal/TemperatureSource';
import { AcausalTranslationalDamper } from 'app/generated_blocks/acausal/translational/Damper';
import { AcausalTranslationalFixedPosition } from 'app/generated_blocks/acausal/translational/FixedPosition';
import { AcausalTranslationalForceSensor } from 'app/generated_blocks/acausal/translational/ForceSensor';
import { AcausalTranslationalForceSource } from 'app/generated_blocks/acausal/translational/ForceSource';
import { AcausalTranslationalFriction } from 'app/generated_blocks/acausal/translational/Friction';
import { AcausalTranslationalMass } from 'app/generated_blocks/acausal/translational/Mass';
import { AcausalTranslationalMotionSensor } from 'app/generated_blocks/acausal/translational/MotionSensor';
import { AcausalTranslationalSpeedSource } from 'app/generated_blocks/acausal/translational/SpeedSource';
import { AcausalTranslationalSpring } from 'app/generated_blocks/acausal/translational/Spring';
import { Conditional } from 'app/generated_blocks/core/Conditional';
import { Abs } from 'app/generated_blocks/core/Abs';
import { Adder } from 'app/generated_blocks/core/Adder';
import { Arithmetic } from 'app/generated_blocks/core/Arithmetic';
import { BatteryCell } from 'app/generated_blocks/core/BatteryCell';
import { BusCreator } from 'app/generated_blocks/core/BusCreator';
import { BusSelector } from 'app/generated_blocks/core/BusSelector';
import { Chirp } from 'app/generated_blocks/core/Chirp';
import { Clock } from 'app/generated_blocks/core/Clock';
import { Comparator } from 'app/generated_blocks/core/Comparator';
import { Constant } from 'app/generated_blocks/core/Constant';
import { CoordinateRotation } from 'app/generated_blocks/core/CoordinateRotation';
import { CoordinateRotationConversion } from 'app/generated_blocks/core/CoordinateRotationConversion';
import { CosineWave } from 'app/generated_blocks/core/CosineWave';
import { CrossProduct } from 'app/generated_blocks/core/CrossProduct';
import { CustomLeafSystem } from 'app/generated_blocks/core/CustomLeafSystem';
import { DataSource } from 'app/generated_blocks/core/DataSource';
import { DeadZone } from 'app/generated_blocks/core/DeadZone';
import { Delay } from 'app/generated_blocks/core/Delay';
import { Demux } from 'app/generated_blocks/core/Demux';
import { Derivative } from 'app/generated_blocks/core/Derivative';
import { DerivativeDiscrete } from 'app/generated_blocks/core/DerivativeDiscrete';
import { DiscreteClock } from 'app/generated_blocks/core/DiscreteClock';
import { DiscreteInitializer } from 'app/generated_blocks/core/DiscreteInitializer';
import { DotProduct } from 'app/generated_blocks/core/DotProduct';
import { DriveCycle } from 'app/generated_blocks/core/DriveCycle';
import { EdgeDetection } from 'app/generated_blocks/core/EdgeDetection';
import { Exponent } from 'app/generated_blocks/core/Exponent';
import { ExtendedKalmanFilter } from 'app/generated_blocks/core/ExtendedKalmanFilter';
import { FilterDiscrete } from 'app/generated_blocks/core/FilterDiscrete';
import { From } from 'app/generated_blocks/core/From';
import { Gain } from 'app/generated_blocks/core/Gain';
import { Generic } from 'app/generated_blocks/core/Generic';
import { Goto } from 'app/generated_blocks/core/Goto';
import { Group } from 'app/generated_blocks/core/Group';
import { IfThenElse } from 'app/generated_blocks/core/IfThenElse';
import { ImageSegmentation } from 'app/generated_blocks/core/ImageSegmentation';
import { ImageSource } from 'app/generated_blocks/core/ImageSource';
import { Inport } from 'app/generated_blocks/core/Inport';
import { Integrator } from 'app/generated_blocks/core/Integrator';
import { IntegratorDiscrete } from 'app/generated_blocks/core/IntegratorDiscrete';
import { Iterator } from 'app/generated_blocks/core/Iterator';
import { KalmanFilter } from 'app/generated_blocks/core/KalmanFilter';
import { LinearizedSystem } from 'app/generated_blocks/core/LinearizedSystem';
import { Log } from 'app/generated_blocks/core/Log';
import { LogicalOperator } from 'app/generated_blocks/core/LogicalOperator';
import { LogicalReduce } from 'app/generated_blocks/core/LogicalReduce';
import { LookupTable1d } from 'app/generated_blocks/core/LookupTable1d';
import { LookupTable2d } from 'app/generated_blocks/core/LookupTable2d';
import { LoopBreak } from 'app/generated_blocks/core/LoopBreak';
import { LoopCounter } from 'app/generated_blocks/core/LoopCounter';
import { LoopMemory } from 'app/generated_blocks/core/LoopMemory';
import { MatrixConcatenation } from 'app/generated_blocks/core/MatrixConcatenation';
import { MatrixInversion } from 'app/generated_blocks/core/MatrixInversion';
import { MatrixMultiplication } from 'app/generated_blocks/core/MatrixMultiplication';
import { MatrixOperator } from 'app/generated_blocks/core/MatrixOperator';
import { MatrixTransposition } from 'app/generated_blocks/core/MatrixTransposition';
import { MinMax } from 'app/generated_blocks/core/MinMax';
import { MLP } from 'app/generated_blocks/core/MLP';
import { ModelicaFMU } from 'app/generated_blocks/core/ModelicaFMU';
import { MuJoCo } from 'app/generated_blocks/core/MuJoCo';
import { Mux } from 'app/generated_blocks/core/Mux';
import { ObjectDetection } from 'app/generated_blocks/core/ObjectDetection';
import { Offset } from 'app/generated_blocks/core/Offset';
import { Outport } from 'app/generated_blocks/core/Outport';
import { PID } from 'app/generated_blocks/core/PID';
import { PID_Discrete } from 'app/generated_blocks/core/PID_Discrete';
import { Power } from 'app/generated_blocks/core/Power';
import { Product } from 'app/generated_blocks/core/Product';
import { ProductOfElements } from 'app/generated_blocks/core/ProductOfElements';
import { Pulse } from 'app/generated_blocks/core/Pulse';
import { PythonScript } from 'app/generated_blocks/core/PythonScript';
import { PyTorch } from 'app/generated_blocks/core/PyTorch';
import { PyTwin } from 'app/generated_blocks/core/PyTwin';
import { Quantizer } from 'app/generated_blocks/core/Quantizer';
import { Ramp } from 'app/generated_blocks/core/Ramp';
import { RandomNumber } from 'app/generated_blocks/core/RandomNumber';
import { RateLimiter } from 'app/generated_blocks/core/RateLimiter';
import { Reciprocal } from 'app/generated_blocks/core/Reciprocal';
import { ReferenceSubmodel } from 'app/generated_blocks/core/ReferenceSubmodel';
import { Relay } from 'app/generated_blocks/core/Relay';
import { Replicator } from 'app/generated_blocks/core/Replicator';
import { RigidBody } from 'app/generated_blocks/core/RigidBody';
import { Ros2Publisher } from 'app/generated_blocks/core/Ros2Publisher';
import { Ros2Subscriber } from 'app/generated_blocks/core/Ros2Subscriber';
import { Saturate } from 'app/generated_blocks/core/Saturate';
import { Sawtooth } from 'app/generated_blocks/core/Sawtooth';
import { ScalarBroadcast } from 'app/generated_blocks/core/ScalarBroadcast';
import { SignalDatatypeConversion } from 'app/generated_blocks/core/SignalDatatypeConversion';
import { SINDy } from 'app/generated_blocks/core/SINDy';
import { SineWave } from 'app/generated_blocks/core/SineWave';
import { Slice } from 'app/generated_blocks/core/Slice';
import { SquareRoot } from 'app/generated_blocks/core/SquareRoot';
import { Stack } from 'app/generated_blocks/core/Stack';
import { StateMachine } from 'app/generated_blocks/core/StateMachine';
import { StateSpace } from 'app/generated_blocks/core/StateSpace';
import { Step } from 'app/generated_blocks/core/Step';
import { Stop } from 'app/generated_blocks/core/Stop';
import { Submodel } from 'app/generated_blocks/core/Submodel';
import { SumOfElements } from 'app/generated_blocks/core/SumOfElements';
import { TensorFlow } from 'app/generated_blocks/core/TensorFlow';
import { TransferFunction } from 'app/generated_blocks/core/TransferFunction';
import { TransferFunctionDiscrete } from 'app/generated_blocks/core/TransferFunctionDiscrete';
import { Trigonometric } from 'app/generated_blocks/core/Trigonometric';
import { UnitDelay } from 'app/generated_blocks/core/UnitDelay';
import { UnscentedKalmanFilter } from 'app/generated_blocks/core/UnscentedKalmanFilter';
import { VideoSink } from 'app/generated_blocks/core/VideoSink';
import { VideoSource } from 'app/generated_blocks/core/VideoSource';
import { WhiteNoise } from 'app/generated_blocks/core/WhiteNoise';
import { ZeroOrderHold } from 'app/generated_blocks/core/ZeroOrderHold';
import { QuanserQuanserHAL } from 'app/generated_blocks/quanser/QuanserHAL';
import { QuanserQubeServoModel } from 'app/generated_blocks/quanser/QubeServoModel';

// Do not export this type, only use blockClassLookup().
type blockClassShortNameType =
 | 'AcausalElectricalBattery'
 | 'AcausalElectricalCapacitor'
 | 'AcausalElectricalCurrentSensor'
 | 'AcausalElectricalCurrentSource'
 | 'AcausalElectricalGround'
 | 'AcausalElectricalIdealDiode'
 | 'AcausalElectricalIdealMotor'
 | 'AcausalElectricalInductor'
 | 'AcausalElectricalIntegratedMotor'
 | 'AcausalElectricalResistor'
 | 'AcausalElectricalVoltageSensor'
 | 'AcausalElectricalVoltageSource'
 | 'AcausalHydraulicAccumulator'
 | 'AcausalHydraulicHydraulicActuatorLinear'
 | 'AcausalHydraulicHydraulicProperties'
 | 'AcausalHydraulicMassflowSensor'
 | 'AcausalHydraulicPipe'
 | 'AcausalHydraulicPressureSensor'
 | 'AcausalHydraulicPressureSource'
 | 'AcausalHydraulicPump'
 | 'AcausalRotationalDamper'
 | 'AcausalRotationalEngine'
 | 'AcausalRotationalFixedAngle'
 | 'AcausalRotationalFriction'
 | 'AcausalRotationalGear'
 | 'AcausalRotationalIdealPlanetary'
 | 'AcausalRotationalIdealWheel'
 | 'AcausalRotationalInertia'
 | 'AcausalRotationalMotionSensor'
 | 'AcausalRotationalSpeedSource'
 | 'AcausalRotationalSpring'
 | 'AcausalRotationalTorqueSensor'
 | 'AcausalRotationalTorqueSource'
 | 'AcausalThermalHeatCapacitor'
 | 'AcausalThermalHeatflowSensor'
 | 'AcausalThermalHeatflowSource'
 | 'AcausalThermalInsulator'
 | 'AcausalThermalRadiation'
 | 'AcausalThermalTemperatureSensor'
 | 'AcausalThermalTemperatureSource'
 | 'AcausalTranslationalDamper'
 | 'AcausalTranslationalFixedPosition'
 | 'AcausalTranslationalForceSensor'
 | 'AcausalTranslationalForceSource'
 | 'AcausalTranslationalFriction'
 | 'AcausalTranslationalMass'
 | 'AcausalTranslationalMotionSensor'
 | 'AcausalTranslationalSpeedSource'
 | 'AcausalTranslationalSpring'
 | 'Conditional'
 | 'Abs'
 | 'Adder'
 | 'Arithmetic'
 | 'BatteryCell'
 | 'BusCreator'
 | 'BusSelector'
 | 'Chirp'
 | 'Clock'
 | 'Comparator'
 | 'Constant'
 | 'CoordinateRotation'
 | 'CoordinateRotationConversion'
 | 'CosineWave'
 | 'CrossProduct'
 | 'CustomLeafSystem'
 | 'DataSource'
 | 'DeadZone'
 | 'Delay'
 | 'Demux'
 | 'Derivative'
 | 'DerivativeDiscrete'
 | 'DiscreteClock'
 | 'DiscreteInitializer'
 | 'DotProduct'
 | 'DriveCycle'
 | 'EdgeDetection'
 | 'Exponent'
 | 'ExtendedKalmanFilter'
 | 'FilterDiscrete'
 | 'From'
 | 'Gain'
 | 'Generic'
 | 'Goto'
 | 'Group'
 | 'IfThenElse'
 | 'ImageSegmentation'
 | 'ImageSource'
 | 'Inport'
 | 'Integrator'
 | 'IntegratorDiscrete'
 | 'Iterator'
 | 'KalmanFilter'
 | 'LinearizedSystem'
 | 'Log'
 | 'LogicalOperator'
 | 'LogicalReduce'
 | 'LookupTable1d'
 | 'LookupTable2d'
 | 'LoopBreak'
 | 'LoopCounter'
 | 'LoopMemory'
 | 'MatrixConcatenation'
 | 'MatrixInversion'
 | 'MatrixMultiplication'
 | 'MatrixOperator'
 | 'MatrixTransposition'
 | 'MinMax'
 | 'MLP'
 | 'ModelicaFMU'
 | 'MuJoCo'
 | 'Mux'
 | 'ObjectDetection'
 | 'Offset'
 | 'Outport'
 | 'PID'
 | 'PID_Discrete'
 | 'Power'
 | 'Product'
 | 'ProductOfElements'
 | 'Pulse'
 | 'PythonScript'
 | 'PyTorch'
 | 'PyTwin'
 | 'Quantizer'
 | 'Ramp'
 | 'RandomNumber'
 | 'RateLimiter'
 | 'Reciprocal'
 | 'ReferenceSubmodel'
 | 'Relay'
 | 'Replicator'
 | 'RigidBody'
 | 'Ros2Publisher'
 | 'Ros2Subscriber'
 | 'Saturate'
 | 'Sawtooth'
 | 'ScalarBroadcast'
 | 'SignalDatatypeConversion'
 | 'SINDy'
 | 'SineWave'
 | 'Slice'
 | 'SquareRoot'
 | 'Stack'
 | 'StateMachine'
 | 'StateSpace'
 | 'Step'
 | 'Stop'
 | 'Submodel'
 | 'SumOfElements'
 | 'TensorFlow'
 | 'TransferFunction'
 | 'TransferFunctionDiscrete'
 | 'Trigonometric'
 | 'UnitDelay'
 | 'UnscentedKalmanFilter'
 | 'VideoSink'
 | 'VideoSource'
 | 'WhiteNoise'
 | 'ZeroOrderHold'
 | 'QuanserQuanserHAL'
 | 'QuanserQubeServoModel';

// Do not export this LUT, only use blockClassLookup().
const blockClassLUT: { [key in blockClassShortNameType]: ComputationBlockClass } = {
  AcausalElectricalBattery,
  AcausalElectricalCapacitor,
  AcausalElectricalCurrentSensor,
  AcausalElectricalCurrentSource,
  AcausalElectricalGround,
  AcausalElectricalIdealDiode,
  AcausalElectricalIdealMotor,
  AcausalElectricalInductor,
  AcausalElectricalIntegratedMotor,
  AcausalElectricalResistor,
  AcausalElectricalVoltageSensor,
  AcausalElectricalVoltageSource,
  AcausalHydraulicAccumulator,
  AcausalHydraulicHydraulicActuatorLinear,
  AcausalHydraulicHydraulicProperties,
  AcausalHydraulicMassflowSensor,
  AcausalHydraulicPipe,
  AcausalHydraulicPressureSensor,
  AcausalHydraulicPressureSource,
  AcausalHydraulicPump,
  AcausalRotationalDamper,
  AcausalRotationalEngine,
  AcausalRotationalFixedAngle,
  AcausalRotationalFriction,
  AcausalRotationalGear,
  AcausalRotationalIdealPlanetary,
  AcausalRotationalIdealWheel,
  AcausalRotationalInertia,
  AcausalRotationalMotionSensor,
  AcausalRotationalSpeedSource,
  AcausalRotationalSpring,
  AcausalRotationalTorqueSensor,
  AcausalRotationalTorqueSource,
  AcausalThermalHeatCapacitor,
  AcausalThermalHeatflowSensor,
  AcausalThermalHeatflowSource,
  AcausalThermalInsulator,
  AcausalThermalRadiation,
  AcausalThermalTemperatureSensor,
  AcausalThermalTemperatureSource,
  AcausalTranslationalDamper,
  AcausalTranslationalFixedPosition,
  AcausalTranslationalForceSensor,
  AcausalTranslationalForceSource,
  AcausalTranslationalFriction,
  AcausalTranslationalMass,
  AcausalTranslationalMotionSensor,
  AcausalTranslationalSpeedSource,
  AcausalTranslationalSpring,
  Conditional,
  Abs,
  Adder,
  Arithmetic,
  BatteryCell,
  BusCreator,
  BusSelector,
  Chirp,
  Clock,
  Comparator,
  Constant,
  CoordinateRotation,
  CoordinateRotationConversion,
  CosineWave,
  CrossProduct,
  CustomLeafSystem,
  DataSource,
  DeadZone,
  Delay,
  Demux,
  Derivative,
  DerivativeDiscrete,
  DiscreteClock,
  DiscreteInitializer,
  DotProduct,
  DriveCycle,
  EdgeDetection,
  Exponent,
  ExtendedKalmanFilter,
  FilterDiscrete,
  From,
  Gain,
  Generic,
  Goto,
  Group,
  IfThenElse,
  ImageSegmentation,
  ImageSource,
  Inport,
  Integrator,
  IntegratorDiscrete,
  Iterator,
  KalmanFilter,
  LinearizedSystem,
  Log,
  LogicalOperator,
  LogicalReduce,
  LookupTable1d,
  LookupTable2d,
  LoopBreak,
  LoopCounter,
  LoopMemory,
  MatrixConcatenation,
  MatrixInversion,
  MatrixMultiplication,
  MatrixOperator,
  MatrixTransposition,
  MinMax,
  MLP,
  ModelicaFMU,
  MuJoCo,
  Mux,
  ObjectDetection,
  Offset,
  Outport,
  PID,
  PID_Discrete,
  Power,
  Product,
  ProductOfElements,
  Pulse,
  PythonScript,
  PyTorch,
  PyTwin,
  Quantizer,
  Ramp,
  RandomNumber,
  RateLimiter,
  Reciprocal,
  ReferenceSubmodel,
  Relay,
  Replicator,
  RigidBody,
  Ros2Publisher,
  Ros2Subscriber,
  Saturate,
  Sawtooth,
  ScalarBroadcast,
  SignalDatatypeConversion,
  SINDy,
  SineWave,
  Slice,
  SquareRoot,
  Stack,
  StateMachine,
  StateSpace,
  Step,
  Stop,
  Submodel,
  SumOfElements,
  TensorFlow,
  TransferFunction,
  TransferFunctionDiscrete,
  Trigonometric,
  UnitDelay,
  UnscentedKalmanFilter,
  VideoSink,
  VideoSource,
  WhiteNoise,
  ZeroOrderHold,
  QuanserQuanserHAL,
  QuanserQubeServoModel
};

function _tsBlockClassName(strlist: string[]): blockClassShortNameType {
  if (strlist[0] === 'core') {
    return _tsBlockClassName(strlist.slice(1));
  }
  return strlist
    .map((str) => str.charAt(0).toUpperCase() + str.slice(1))
    .join('') as blockClassShortNameType;
}

function jsonBlockClassToTsTypeName(fullName: string): blockClassShortNameType {
  return _tsBlockClassName(fullName.split('.'));
}

export const blockClassLookup = (name: BlockClassName) => {
  const shortName = jsonBlockClassToTsTypeName(name);
  const klass = blockClassLUT[shortName];
  if (klass) return klass;

  // Block does not exist, we need to report the error without crashing the app.
  console.error('Unknown block class:', name);
  return computationBlockDefaults;
}

export const blockClassExists = (name: string): boolean => {
  const shortName = jsonBlockClassToTsTypeName(name);
  const klass = blockClassLUT[shortName];
  return !!klass;
}
