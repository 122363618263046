/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Iterator: React.FC<Props> = (props) => (
  <svg viewBox="0 0 24 24" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21.0303 11.5303C21.3232 11.2374 21.3232 10.7626 21.0303 10.4697C20.7374 10.1768 20.2625 10.1768 19.9697 10.4697L18.75 11.6893V6.5C18.75 5.5335 17.9665 4.75 17 4.75H6C5.0335 4.75 4.25 5.5335 4.25 6.5V17.5C4.25 18.4665 5.0335 19.25 6 19.25H9.25C9.66421 19.25 10 18.9142 10 18.5C10 18.0858 9.66421 17.75 9.25 17.75H6C5.86193 17.75 5.75 17.6381 5.75 17.5L5.75 6.5C5.75 6.36193 5.86193 6.25 6 6.25H17C17.1381 6.25 17.25 6.36193 17.25 6.5L17.25 11.6893L16.0303 10.4697C15.7374 10.1768 15.2625 10.1768 14.9697 10.4697C14.6768 10.7626 14.6768 11.2374 14.9697 11.5303L18 14.5607L21.0303 11.5303Z"
      fill={props.fill}
    />
    <path
      d="M13.25 19.25C13.6642 19.25 14 18.9142 14 18.5C14 18.0858 13.6642 17.75 13.25 17.75H11.75C11.3358 17.75 11 18.0858 11 18.5C11 18.9142 11.3358 19.25 11.75 19.25H13.25Z"
      fill={props.fill}
    />
    <path
      d="M17.2796 19.25C17.6939 19.25 18.0296 18.9142 18.0296 18.5C18.0296 18.0858 17.6939 17.75 17.2796 17.75L15.7197 17.75C15.3054 17.75 14.9697 18.0858 14.9697 18.5C14.9697 18.9142 15.3054 19.25 15.7197 19.25H17.2796Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M8.5 8C7.94771 8 7.5 8.44772 7.5 9V11C7.5 11.5523 7.94771 12 8.5 12H9.44444V13.9991C9.44444 14.8276 10.116 15.4991 10.9444 15.4991H11.7073C11.8801 15.7985 12.2036 16 12.5741 16H14.4853C15.0376 16 15.4853 15.5523 15.4853 15V14.4736C15.4853 13.9213 15.0376 13.4736 14.4853 13.4736H12.5741C12.0218 13.4736 11.5741 13.9213 11.5741 14.4736V14.4991H10.9444C10.6683 14.4991 10.4444 14.2753 10.4444 13.9991V12H11.5C12.0523 12 12.5 11.5523 12.5 11V9C12.5 8.44772 12.0523 8 11.5 8H8.5ZM11.5 9H8.5L8.5 11H11.5V9ZM12.5741 14.4736H14.4853L14.4853 15H12.5741V14.4736Z"
      fill={props.fill}
    />
  </svg>
);

Iterator.defaultProps = {
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Iterator;
