// Figma design: https://www.figma.com/file/2ZwzFm5zJ82g4uCKXIxxAW/Library?node-id=1163%3A3342

import { Theme, useTheme } from '@emotion/react';
import styled from '@emotion/styled/macro';
import React from 'react';

import { SmallEmphasis } from 'ui/common/typography/Typography';

const switchShadow =
  '0px 5px 11px rgba(0, 0, 0, 0.1), 0px 2.68057px 4.11875px rgba(0, 0, 0, 0.0544), 0px 1.77613px 1.4566px rgba(0, 0, 0, 0.0382)';

const getOptionColor = (
  theme: Theme,
  isSideActive: boolean,
  isHovered: boolean,
  disabled?: boolean,
) => {
  if (disabled) {
    return theme.colors.text.tertiary;
  }
  if (isSideActive) {
    return theme.colors.text.primary;
  }

  if (isHovered) {
    return theme.colors.text.secondary;
  }

  return theme.colors.text.tertiary;
};

type Props = {
  onSwitched?: (switchedLeft: boolean) => void;
  switchedLeft: boolean;
  LeftIcon?: React.FC<any>;
  leftText?: string;
  RightIcon?: React.FC<any>;
  rightText?: string;
  testId?: string;
  disabled?: boolean;
};

const SwitchWrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 26px;
`;

const SwitchFocusContainer = styled.div<{ disabled?: boolean }>`
  flex: 0 1 auto;
  display: flex;
  align-items: center;
  height: 28px;
  margin: 0 2px;
  border-radius: 2px;
  &:focus {
    margin: 0;
    border: 2px solid
      ${({ theme, disabled }) =>
        disabled ? 'transparent' : theme.colors.brand.primary.lighter};
  }
`;

const OptionContent = styled.div<{
  hasIcon: boolean;
  hasText: boolean;
  leftSide: boolean;
  isSideActive: boolean;
  isHovering: boolean;
  disabled?: boolean;
}>`
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-left: ${({ theme, leftSide }) =>
    leftSide ? 0 : theme.spacing.normal};
  margin-right: ${({ theme, leftSide }) =>
    leftSide ? theme.spacing.normal : 0};
  padding-left: ${({ theme, hasIcon }) => (hasIcon ? 0 : theme.spacing.small)};
  padding-right: ${({ theme, hasText }) => (hasText ? theme.spacing.small : 0)};
  color: ${({ theme, isSideActive, isHovering, disabled }) =>
    getOptionColor(theme, isSideActive, isHovering, disabled)};
  border-radius: ${({ theme }) => theme.spacing.xsmall};
  height: 24px;

  ${({ theme, isSideActive, disabled }) =>
    isSideActive || disabled
      ? 'cursor: default;'
      : `&:active { background-color: ${theme.colors.grey[10]}; }
      cursor: pointer;
      `}
`;

const SwitchRailContainer = styled.div<{ disabled?: boolean }>`
  position: relative;
  width: 24px;
  height: 16px;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
`;

const SwitchRail = styled.div<{ disabled?: boolean }>`
  margin: 6px 2px;
  height: 4px;
  border-radius: 1px;
  background-color: ${({ theme, disabled }) =>
    disabled ? theme.colors.grey[30] : theme.colors.grey[50]};
`;

const SwitchButton = styled.div<{
  isSwitchedLeft: boolean;
  isWholeSwitchInFocus: boolean;
  disabled?: boolean;
}>`
  position: absolute;
  top: 0;
  left: ${({ isSwitchedLeft }) => (isSwitchedLeft ? 0 : '12px')};
  width: 12px;
  height: 16px;
  border-radius: 2px;
  background-color: ${({ theme }) => theme.colors.grey[2]};
  border: 1px solid ${({ theme }) => theme.colors.grey[30]};

  ${({ isWholeSwitchInFocus, disabled }) =>
    isWholeSwitchInFocus && !disabled ? `box-shadow: ${switchShadow};` : ''}

  &:hover, &:active {
    box-shadow: ${({ disabled }) => (disabled ? 'none' : switchShadow)};
  }

  transition: left 0.1s ease-in;
`;

const Switch: React.FC<Props> = ({
  onSwitched,
  switchedLeft,
  LeftIcon,
  leftText,
  RightIcon,
  rightText,
  disabled,
  testId,
}) => {
  const theme = useTheme() as Theme;
  const [hoveringLeftOption, setHoveringLeftOption] = React.useState(false);
  const [hoveringRightOption, setHoveringRightOption] = React.useState(false);
  const [focused, setFocused] = React.useState(false);
  const [isSwitchedLeft, setIsSwitchedLeft] = React.useState(switchedLeft);

  const changeIsSwitchedLeft = (switchLeft: boolean) => {
    if (isSwitchedLeft === switchLeft) return;

    setIsSwitchedLeft(switchLeft);
    if (onSwitched) {
      onSwitched(switchLeft);
    }
  };

  React.useEffect(() => {
    if (isSwitchedLeft !== switchedLeft) {
      setIsSwitchedLeft(switchedLeft);
    }
  }, [isSwitchedLeft, switchedLeft]);

  return (
    <SwitchWrapper
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}>
      <SwitchFocusContainer tabIndex={0} disabled={disabled}>
        {/* Left Label */}
        <OptionContent
          leftSide
          disabled={disabled}
          isSideActive={isSwitchedLeft}
          isHovering={hoveringLeftOption}
          hasIcon={!!LeftIcon}
          hasText={!!leftText}
          onClick={() => !disabled && changeIsSwitchedLeft(true)}
          data-test-id={`${testId}-left-label`}
          onMouseEnter={() => setHoveringLeftOption(true)}
          onMouseLeave={() => setHoveringLeftOption(false)}>
          {LeftIcon && (
            <LeftIcon
              fill={getOptionColor(
                theme,
                isSwitchedLeft,
                hoveringLeftOption,
                disabled,
              )}
            />
          )}
          {leftText && <SmallEmphasis>{leftText}</SmallEmphasis>}
        </OptionContent>

        {/* Switch */}
        <SwitchRailContainer
          onClick={() => changeIsSwitchedLeft(!isSwitchedLeft)}
          data-test-id={testId}
          disabled={disabled}>
          <SwitchRail disabled={disabled} />
          <SwitchButton
            isSwitchedLeft={isSwitchedLeft}
            isWholeSwitchInFocus={focused}
            disabled={disabled}
          />
        </SwitchRailContainer>

        {/* Right Label */}
        <OptionContent
          leftSide={false}
          disabled={disabled}
          isSideActive={!isSwitchedLeft}
          isHovering={hoveringRightOption}
          hasIcon={!!RightIcon}
          hasText={!!rightText}
          onClick={() => !disabled && changeIsSwitchedLeft(false)}
          data-test-id={`${testId}-right-label`}
          onMouseEnter={() => setHoveringRightOption(true)}
          onMouseLeave={() => setHoveringRightOption(false)}>
          {RightIcon && (
            <RightIcon
              fill={getOptionColor(
                theme,
                !isSwitchedLeft,
                hoveringRightOption,
                disabled,
              )}
            />
          )}
          {rightText && <SmallEmphasis>{rightText}</SmallEmphasis>}
        </OptionContent>
      </SwitchFocusContainer>
    </SwitchWrapper>
  );
};

export default Switch;
