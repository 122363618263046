/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Restored: React.FC<Props> = (props) => (
  <svg viewBox="0 0 24 24" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.8169 7.91798C11.6999 7.66207 12.6421 7.69938 13.5021 8.0243C14.3621 8.34923 15.0936 8.94426 15.5867 9.72012C16.0799 10.496 16.3082 11.4108 16.2374 12.3274C16.1665 13.244 15.8004 14.113 15.1939 14.8038C14.5874 15.4947 13.7732 15.9703 12.8735 16.1593C12.2199 16.2965 11.5468 16.2771 10.908 16.1073C10.5077 16.0009 10.3458 15.5475 10.5208 15.1721C10.6959 14.7967 11.1434 14.6458 11.5521 14.7133C11.8861 14.7684 12.2296 14.7618 12.5652 14.6913C13.1474 14.569 13.6742 14.2613 14.0666 13.8143C14.4591 13.3672 14.696 12.805 14.7418 12.2119C14.7876 11.6188 14.6399 11.0268 14.3208 10.5248C14.0017 10.0228 13.5284 9.63774 12.9719 9.42749C12.4155 9.21724 11.8058 9.1931 11.2345 9.35869C10.6631 9.52428 10.1609 9.87067 9.80307 10.3459C9.59684 10.6198 9.44501 10.928 9.35314 11.2539C9.32931 11.3384 9.29585 11.4204 9.25396 11.497L10.05 10.9C10.3814 10.6515 10.8515 10.7186 11.1 11.05C11.3485 11.3814 11.2814 11.8515 10.95 12.1L8.35 14.05L6.4 11.45C6.15147 11.1186 6.21863 10.6485 6.55 10.4C6.88137 10.1515 7.35147 10.2186 7.6 10.55L7.8864 10.9319C8.02533 10.3969 8.26816 9.89071 8.60476 9.44366C9.15773 8.70922 9.93394 8.17389 10.8169 7.91798Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19Z"
      fill={props.fill}
    />
  </svg>
);

Restored.defaultProps = {
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Restored;
