import { DISABLE_GA4 } from 'app/config/globalApplicationConfig';
import { GA4React } from 'ga-4-react';
import { GA4ReactResolveInterface } from 'ga-4-react/dist/models/gtagModels';

let ga4: Promise<GA4ReactResolveInterface> | undefined;
let ga4Initializing = false;

const productionStreamId = 'G-7S391H6BCL';
const isProduction = process.env.NODE_ENV === 'production';
const streamId = isProduction
  ? productionStreamId
  : process.env.REACT_APP_GA4_STREAM_ID;

if (!DISABLE_GA4 && streamId && !ga4 && !ga4Initializing) {
  try {
    ga4Initializing = true;
    ga4 = new GA4React(streamId).initialize();
  } catch (e) {
    ga4 = undefined;
    console.warn('Got an error while initializing GA4React:', e);
  } finally {
    ga4Initializing = false;
  }
}

export interface PathData {
  path: string;
  search: string;
  title: string;
}

export const googleAnalyticsTracking = (data: PathData) => {
  const { path, search, title } = data;

  if (!ga4) return;

  ga4
    .then((ga) => {
      ga.pageview(path, search, title);
    })
    .catch((err) => console.error(err));
};

export const googleAnalyticsTrackSearchKeyword = (
  keyword: string,
  category = 'block_library',
) => {
  if (!ga4) return;

  ga4
    .then((ga) => {
      ga.event('search', category, keyword);
    })
    .catch((err) => console.error(err));
};
