import styled from '@emotion/styled/macro';
import { H3 } from 'ui/common/typography/Typography';

export const Wrapper = styled.section(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  width: '100%',
  height: '100%',
  backgroundColor: theme.colors.grey[2],
}));

export const Title = styled(H3)(({ theme }) => ({
  marginBottom: theme.spacing.large,
}));

export const Message = styled.div(({ theme }) => ({
  width: 336,
  textAlign: 'center',
  marginBottom: theme.spacing.large,
}));

export const HintImage = styled.img(({ theme }) => ({
  marginBottom: theme.spacing.xlarge,
}));
