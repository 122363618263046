/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const PopOut: React.FC<Props> = (props) => (
  <svg viewBox="0 0 24 24" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M14 4.75C13.5858 4.75 13.25 4.41421 13.25 4C13.25 3.58579 13.5858 3.25 14 3.25H20.75V10C20.75 10.4142 20.4142 10.75 20 10.75C19.5858 10.75 19.25 10.4142 19.25 10V5.81066L12.5303 12.5303C12.2374 12.8232 11.7626 12.8232 11.4697 12.5303C11.1768 12.2374 11.1768 11.7626 11.4697 11.4697L18.1893 4.75H14ZM6.75 6.75H10.875C11.2892 6.75 11.625 6.41421 11.625 6C11.625 5.58579 11.2892 5.25 10.875 5.25H6.5C5.80964 5.25 5.25 5.80964 5.25 6.5V17.5C5.25 18.1904 5.80964 18.75 6.5 18.75H17.5C18.1904 18.75 18.75 18.1904 18.75 17.5V13.125C18.75 12.7108 18.4142 12.375 18 12.375C17.5858 12.375 17.25 12.7108 17.25 13.125V17.25H6.75V6.75Z"
      fill={props.fill}
    />
  </svg>
);

PopOut.defaultProps = {
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default PopOut;
