/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const CFunction: React.FC<Props> = (props) => (
  <svg viewBox="0 0 16 16" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.0262 8.00004C10.0262 6.89604 9.11812 6.00004 7.99989 6.00004C6.88136 6.00004 5.97358 6.89604 5.97358 8.00004C5.97358 9.10376 6.88165 10 7.99989 10C9.11812 10 10.0262 9.10376 10.0262 8.00004Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M8.31725 2.08199C8.12099 1.97314 7.87899 1.97314 7.68244 2.08199L2.81755 4.77742C2.621 4.88628 2.5 5.08742 2.5 5.30513V10.6957C2.5 10.9134 2.621 11.1143 2.81726 11.2231C3.41713 11.5553 4.76089 12.2999 5.9298 12.9475C6.65392 13.3487 7.31094 13.7128 7.68244 13.9186C7.8787 14.0274 8.1207 14.0274 8.31725 13.9186C8.91848 13.5856 10.267 12.8384 11.4378 12.1897C12.1587 11.7903 12.8123 11.4282 13.1824 11.2231C13.379 11.1143 13.5 10.9131 13.5 10.6954V5.30485C13.5 5.08713 13.379 4.88599 13.1824 4.77742C12.582 4.4449 11.2362 3.69925 10.0665 3.05116C9.34373 2.65069 8.68817 2.28747 8.31725 2.08199ZM12.0525 8.00004C12.0525 5.79233 10.2366 4.00005 7.99989 4.00005C5.76313 4.00005 3.94727 5.79233 3.94727 8.00004C3.94727 10.2078 5.76313 12 7.99989 12C10.2366 12 12.0525 10.2078 12.0525 8.00004Z"
      fill={props.fill}
    />
    <path
      d="M2.5 10.6443C2.5 10.7743 2.511 10.8709 2.588 10.9726L4.44758 9.92569C4.12872 9.35432 3.94727 8.69794 3.94727 8.00004C3.94727 5.79233 5.76313 4.00005 7.99989 4.00005C9.48291 4.00005 10.7809 4.78794 11.4873 5.96241L13.3515 4.91291C13.2982 4.84405 13.2386 4.79176 13.1604 4.74833C12.6766 4.48091 11.709 3.94815 10.7414 3.41538C9.77372 2.88258 8.80604 2.34977 8.32217 2.08234C8.1262 1.97405 7.86075 1.97176 7.66478 2.08005C6.69707 2.61519 3.78439 4.22519 2.81668 4.76005C2.62071 4.86805 2.5 5.06805 2.5 5.28462V10.6443Z"
      fill={props.fill}
    />
    <path
      d="M9.72669 6.95362C9.36991 6.38164 8.72965 6.00004 7.99989 6.00004C6.88136 6.00004 5.97358 6.89604 5.97358 8.00004C5.97358 8.33746 6.05845 8.6555 6.20824 8.93446L9.72669 6.95362Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M13.3509 4.91576C13.5003 5.05376 13.4974 5.26176 13.4974 5.42548C13.4974 6.50976 13.4881 9.59376 13.5 10.678C13.5011 10.7912 13.4632 10.9103 13.4065 11L7.88217 8.00005L13.3509 4.91576Z"
      fill={props.fill}
    />
  </svg>
);

CFunction.defaultProps = {
  width: '16',
  height: '16',
  viewBox: '0 0 16 16',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default CFunction;
