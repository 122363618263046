import { t } from '@lingui/macro';
import {
  ModelOverrides,
  ParameterSweep,
} from 'app/apiGenerated/generatedApiTypes';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { entityPreferencesActions } from 'app/slices/entityPreferencesSlice';
import React, { useState } from 'react';
import Button from 'ui/common/Button/Button';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import TooltipButton from 'ui/common/Button/TooltipButton';
import { Plus, RunAll } from 'ui/common/Icons/Standard';
import SectionHeading from 'ui/common/Inputs/SectionHeading';
import { usePython } from 'ui/common/PythonProvider';
import { CommonEnsembleSimPrefs } from 'ui/userPreferences/ensembleSimModelPrefs';
import { useAppParams } from 'util/useAppParams';
import {
  ActionButtonContainer,
  BottomBar,
  InfoLabel,
  Spacer,
  TotalSimsInput,
  getParamSweeps,
  getValidationRules,
} from './EnsembleConfigs';
import OverridesList from './OverridesList';
import { SignalsAndPlotsConfig } from './SignalsAndPlotsConfig';

export interface ParameterSweepConfigProps {
  prefs: CommonEnsembleSimPrefs;
  onRunAll: (modelOverrides: ModelOverrides) => void;
}

export const ParameterSweepConfig: React.FC<ParameterSweepConfigProps> = ({
  prefs,
  onRunAll,
}) => {
  const dispatch = useAppDispatch();

  const { maxQueuedSims } = useAppSelector(
    (state) => state.userOptions.options,
  );

  const modelId = useAppParams().modelId || '';

  const overridesKeys = Object.keys(prefs.overridenParams ?? []);

  const parameters = useAppSelector((state) => state.model.present.parameters);

  const [numSims, setNumSims] = useState<number>(0);
  const { isReady: pythonIsReady } = usePython();

  const defaultSetting = {
    type: 'Range',
    params: ['0', '1', '1'],
  };

  const parameterSweeps: ParameterSweep[] = React.useMemo(
    () => getParamSweeps(parameters, prefs.overridenParams),
    [parameters, prefs.overridenParams],
  );

  const modelOverrides: ModelOverrides = React.useMemo(
    () => ({
      ensemble_config: {
        sweep_strategy: 'all_combinations',
        model_parameter_sweeps: Object.values(parameterSweeps),
      },
      // recorded_signals repurposed to carry viz on/off
      recorded_signals: {
        signal_ids: prefs.signals,
      },
    }),
    [parameterSweeps, prefs.signals],
  );

  // ---------------------------------------------------------------------------- //
  // Event Handlers                                                               //
  // ---------------------------------------------------------------------------- //

  const onAddParam = () => {
    const overridenParamId = parameters.find(
      (parameter) => !prefs.overridenParams[parameter.name],
    )?.name;
    if (overridenParamId)
      dispatch(
        entityPreferencesActions.onUserUpdatedEnsembleSimModalPrefs({
          modelId,
          tab: 'paramSweep',
          prefs: {
            overridenParams: {
              ...prefs.overridenParams,
              [overridenParamId]: defaultSetting,
            },
          },
        }),
      );
  };

  const hasTooManySimulations = numSims > maxQueuedSims && maxQueuedSims !== -1;
  const hasTooFewSimulations = numSims < 1;
  const validationsRules = getValidationRules(
    hasTooManySimulations,
    hasTooFewSimulations,
  );

  const allowedToRun =
    prefs.overridenParams && !(hasTooManySimulations || hasTooFewSimulations);

  const plotOptions = useAppSelector(
    (state) => state.uiFlags.ensemblePlotOptions,
  );

  const runButtonDisabled =
    !allowedToRun ||
    prefs.signals.length === 0 ||
    !(
      plotOptions.iqr_error ||
      plotOptions.overlay ||
      plotOptions.standard_deviations
    );

  const invalidReason = React.useMemo(() => {
    if (!prefs.overridenParams || Object.keys(prefs.overridenParams).length < 1)
      return t`Please add a parameter.`;
    if (hasTooManySimulations) return t`Too many simulations.`;
    if (hasTooFewSimulations)
      return t`Please enter a valid number of simulations.`;
    if (!prefs.signals || prefs.signals.length === 0)
      return t`Please select signals to plot.`;
    if (
      !(
        plotOptions.iqr_error ||
        plotOptions.overlay ||
        plotOptions.standard_deviations
      )
    )
      return t`Must have at least one plot option selected`;
    return '';
  }, [
    prefs.overridenParams,
    prefs.signals,
    hasTooManySimulations,
    hasTooFewSimulations,
    plotOptions.iqr_error,
    plotOptions.overlay,
    plotOptions.standard_deviations,
  ]);

  return (
    <>
      <InfoLabel>{t`Enter value lists or ranges for any model parameters to run another simulation for each value.`}</InfoLabel>
      <SectionHeading noBorder>{t`Parameter Sweeps`}</SectionHeading>
      {prefs.overridenParams && (
        <OverridesList
          noTopMargin
          sweepTypes={['Constant', 'Range', 'Array']}
          ensembleType="paramSweep"
          defaultSetting={defaultSetting}
          onNumSimsChange={setNumSims}
          overrides={prefs.overridenParams}
          doPythonEval
        />
      )}
      <ActionButtonContainer>
        <Button
          variant={ButtonVariants.SmallSecondary}
          Icon={Plus}
          onClick={onAddParam}
          disabled={overridesKeys.length >= parameters.length}>
          {t`Add sweep`}
        </Button>
      </ActionButtonContainer>
      <SignalsAndPlotsConfig prefs={prefs} ensembleType="paramSweep" />
      <BottomBar>
        <InfoLabel>{t`Total simulations to run:`}</InfoLabel>

        <TotalSimsInput
          disabled
          hasBorder
          value={numSims >= 0 ? numSims : ''}
          validationRules={
            numSims >= 0 && prefs.overridenParams && pythonIsReady
              ? validationsRules
              : undefined
          }
        />
        <Spacer />
        <TooltipButton
          variant={ButtonVariants.LargePrimary}
          Icon={RunAll}
          tooltip={invalidReason}
          onClick={() => onRunAll(modelOverrides)}
          disabled={runButtonDisabled}>
          {t`Run Simulations`}
        </TooltipButton>
      </BottomBar>
    </>
  );
};
