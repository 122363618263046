/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const LogicNor: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M1.25 13C1.25 12.5858 1.58579 12.25 2 12.25H6.5C6.91421 12.25 7.25 12.5858 7.25 13C7.25 13.4142 6.91421 13.75 6.5 13.75H2C1.58579 13.75 1.25 13.4142 1.25 13Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M1.25 20C1.25 19.5858 1.58579 19.25 2 19.25H6.5C6.91421 19.25 7.25 19.5858 7.25 20C7.25 20.4142 6.91421 20.75 6.5 20.75H2C1.58579 20.75 1.25 20.4142 1.25 20Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M26.75 15.75H30C30.4142 15.75 30.75 16.0858 30.75 16.5C30.75 16.9142 30.4142 17.25 30 17.25H26.75V15.75Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M5.36277 7.75128C6.28955 9.38017 7.74993 12.5678 7.74993 16.5C7.74993 20.1832 6.47 22.8115 5.52651 24.2484C9.66569 24.2356 13.7611 24.1217 16.8931 23.1486C18.5616 22.6303 19.8912 21.8863 20.8029 20.8406C21.7021 19.8095 22.2499 18.4224 22.2499 16.5C22.2499 14.3043 21.6857 12.7172 20.766 11.5453C19.8401 10.3654 18.4997 9.53752 16.8307 8.96375C13.6729 7.87817 9.53987 7.76293 5.36277 7.75128ZM3.65148 7.8196C3.23506 7.17532 3.66636 6.24976 4.49962 6.25006C8.89936 6.25164 13.66 6.28759 17.3184 7.54523C19.1662 8.18048 20.7885 9.14423 21.9461 10.6192C23.1098 12.1022 23.7499 14.0334 23.7499 16.5C23.7499 18.7329 23.1029 20.4854 21.9335 21.8265C20.7767 23.1531 19.1641 24.0138 17.3381 24.5811C13.714 25.707 8.99247 25.7479 4.60335 25.7499C4.14526 25.7501 3.82069 25.4567 3.68019 25.1493C3.54095 24.8447 3.5325 24.4228 3.8031 24.0796C4.5495 23.1328 6.24993 20.5335 6.24993 16.5C6.24993 12.2495 4.36392 8.92189 3.65148 7.8196Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M25.5 17.5C26.0523 17.5 26.5 17.0523 26.5 16.5C26.5 15.9477 26.0523 15.5 25.5 15.5C24.9477 15.5 24.5 15.9477 24.5 16.5C24.5 17.0523 24.9477 17.5 25.5 17.5ZM25.5 19C26.8807 19 28 17.8807 28 16.5C28 15.1193 26.8807 14 25.5 14C24.1193 14 23 15.1193 23 16.5C23 17.8807 24.1193 19 25.5 19Z"
      fill={props.fill}
    />
  </svg>
);

LogicNor.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default LogicNor;
