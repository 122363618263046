/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Break: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M12.25 5C12.25 2.92893 13.9289 1.25 16 1.25C18.0711 1.25 19.75 2.92893 19.75 5C19.75 6.90154 18.3347 8.47251 16.5 8.71696V10.2929L17.1464 9.64645C17.3417 9.45118 17.6583 9.45118 17.8536 9.64645C18.0488 9.84171 18.0488 10.1583 17.8536 10.3536L16 12.2071L14.1464 10.3536C13.9512 10.1583 13.9512 9.84171 14.1464 9.64645C14.3417 9.45118 14.6583 9.45118 14.8536 9.64645L15.5 10.2929V8.71696C13.6653 8.47251 12.25 6.90154 12.25 5ZM16 2.75C14.7574 2.75 13.75 3.75736 13.75 5C13.75 6.24264 14.7574 7.25 16 7.25C17.2426 7.25 18.25 6.24264 18.25 5C18.25 3.75736 17.2426 2.75 16 2.75Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M12.25 16C12.25 13.9289 13.9289 12.25 16 12.25C18.0711 12.25 19.75 13.9289 19.75 16C19.75 17.9015 18.3347 19.4725 16.5 19.717V20.25C16.5 20.5261 16.7239 20.75 17 20.75H22.2929L21.6464 20.1036C21.4512 19.9083 21.4512 19.5917 21.6464 19.3964C21.8417 19.2012 22.1583 19.2012 22.3536 19.3964L24.2071 21.25L22.3536 23.1036C22.1583 23.2988 21.8417 23.2988 21.6464 23.1036C21.4512 22.9083 21.4512 22.5917 21.6464 22.3964L22.2929 21.75H17C16.1716 21.75 15.5 21.0784 15.5 20.25V19.717C13.6653 19.4725 12.25 17.9015 12.25 16ZM16 13.75C14.7574 13.75 13.75 14.7574 13.75 16C13.75 17.2426 14.7574 18.25 16 18.25C17.2426 18.25 18.25 17.2426 18.25 16C18.25 14.7574 17.2426 13.75 16 13.75Z"
      fill={props.fill}
    />
    <path
      d="M16.4976 23.2824C16.2239 23.2458 15.9993 23.4734 15.9996 23.7495L16 24.2495C16.0003 24.5256 16.2271 24.7436 16.4964 24.8047C16.7114 24.8534 16.923 24.9349 17.1248 25.0514C17.3266 25.1679 17.503 25.3105 17.6527 25.4723C17.8402 25.675 18.1424 25.7624 18.3817 25.6245L18.8149 25.3749C19.0542 25.2371 19.1389 24.9288 18.9704 24.7101C18.6796 24.3326 18.3118 24.0047 17.8748 23.7524C17.4378 23.5001 16.97 23.3455 16.4976 23.2824Z"
      fill={props.fill}
    />
    <path
      d="M19.7174 27.4979C19.754 27.2241 19.5264 26.9995 19.2503 26.9998L18.7503 27.0002C18.4741 27.0005 18.2562 27.2273 18.1951 27.4966C18.1464 27.7116 18.0649 27.9232 17.9483 28.125C17.8318 28.3268 17.6893 28.5032 17.5275 28.6529C17.3248 28.8404 17.2374 29.1426 17.3753 29.3819L17.6248 29.8151C17.7627 30.0544 18.071 30.1391 18.2897 29.9706C18.6672 29.6798 18.9951 29.312 19.2474 28.875C19.4997 28.438 19.6543 27.9702 19.7174 27.4979Z"
      fill={props.fill}
    />
    <path
      d="M13.7098 24.0294C13.3323 24.3202 13.0045 24.688 12.7522 25.125C12.4999 25.562 12.3453 26.0298 12.2822 26.5021C12.2456 26.7759 12.4731 27.0005 12.7493 27.0002L13.2493 26.9998C13.5254 26.9995 13.7434 26.7727 13.8045 26.5034C13.8532 26.2884 13.9347 26.0768 14.0512 25.875C14.1677 25.6732 14.3102 25.4968 14.472 25.3471C14.6748 25.1596 14.7622 24.8574 14.6243 24.6181L14.3747 24.1849C14.2369 23.9456 13.9286 23.8609 13.7098 24.0294Z"
      fill={props.fill}
    />
    <path
      d="M13.0292 29.29C13.32 29.6674 13.6878 29.9953 14.1248 30.2476C14.5618 30.4999 15.0296 30.6545 15.5019 30.7176C15.7756 30.7542 16.0003 30.5266 16 30.2505L15.9995 29.7505C15.9993 29.4744 15.7725 29.2564 15.5032 29.1953C15.2882 29.1466 15.0766 29.0651 14.8748 28.9486C14.673 28.8321 14.4966 28.6895 14.3469 28.5277C14.1594 28.325 13.8572 28.2376 13.6179 28.3755L13.1847 28.6251C12.9454 28.7629 12.8607 29.0712 13.0292 29.29Z"
      fill={props.fill}
    />
  </svg>
);

Break.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Break;
