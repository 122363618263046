import { MouseActions } from 'app/common_types/MouseTypes';
import { getCurrentModelRef } from 'app/sliceRefAccess/CurrentModelRef';
import { modelActions } from 'app/slices/modelSlice';
import { renderConstants } from 'app/utils/renderConstants';
import { convertZoomedScreenToWorldCoordinates } from './convertScreenToWorldCoordinates';
import { getVertexHitboxForIndex } from './getVertexHitboxForIndex';
import { getVisualNodeHeight, PORT_BLOCK_YOFFSET } from './getVisualNodeHeight';
import { getVisualNodeWidth } from './getVisualNodeWidth';
import { keysPressed } from './keyEvents';
import { rendererState } from './modelRenderer';
import { clickModifierConfig } from './shortcutKeyConfig';

export function multiSelection() {
  if (!rendererState) return;

  if (rendererState.mouseState.state === MouseActions.MakingSelection) {
    const worldCursor = convertZoomedScreenToWorldCoordinates(
      rendererState.camera,
      rendererState.screenCursorZoomed,
    );

    const { rawScreenCursorStartX, rawScreenCursorStartY } =
      rendererState.mouseState;

    const worldStart = convertZoomedScreenToWorldCoordinates(
      rendererState.camera,
      {
        x: rawScreenCursorStartX / rendererState.zoom,
        y: rawScreenCursorStartY / rendererState.zoom,
      },
    );

    // faster than Math.min/max 4 times
    let startX;
    let startY;
    let endX;
    let endY = 0;
    if (worldCursor.x < worldStart.x) {
      startX = worldCursor.x;
      endX = worldStart.x;
    } else {
      startX = worldStart.x;
      endX = worldCursor.x;
    }
    if (worldCursor.y < worldStart.y) {
      startY = worldCursor.y;
      endY = worldStart.y;
    } else {
      startY = worldStart.y;
      endY = worldCursor.y;
    }

    const nodes = rendererState.refs.current.nodes;
    const annotations = rendererState.refs.current.annotations;
    const selectedBlockUuids = [];
    const selectedLinkUuids = [];
    const selectedAnnotationUuids = [];

    // Just doing a brute-force "AABB" collision to keep it simple for now.
    // This is applied for both blocks AND links during multi-select.
    // We don't have any collision optimization data structures right now,
    // so this is the simplest way to do this currently.
    // TODO: update this block when we have grid-based collision
    // for links + selection area.

    for (let i = 0; i < nodes.length; i++) {
      const node = nodes[i];
      const nodeHeight = getVisualNodeHeight(node);
      const nodeWidth = getVisualNodeWidth(node);
      const nodeIsIOPort =
        node.type === 'core.Inport' || node.type === 'core.Outport';
      const fixedNodeY = nodeIsIOPort
        ? node.uiprops.y + PORT_BLOCK_YOFFSET
        : node.uiprops.y;
      if (
        node.uiprops.x < endX &&
        node.uiprops.x + nodeWidth > startX &&
        fixedNodeY < endY &&
        fixedNodeY + nodeHeight > startY
      ) {
        selectedBlockUuids.push(node.uuid);
      }
    }

    for (let i = 0; i < annotations.length; i++) {
      const anno = annotations[i];
      const annoHeight = anno.grid_height * renderConstants.GRID_UNIT_PXSIZE;
      const annoWidth = anno.grid_width * renderConstants.GRID_UNIT_PXSIZE;
      if (
        anno.x < endX &&
        anno.x + annoWidth > startX &&
        anno.y < endY &&
        anno.y + annoHeight > startY
      ) {
        selectedAnnotationUuids.push(anno.uuid);
      }
    }

    // see above comment about brute-force AABB
    const linksRenderFrameData = rendererState.linksRenderFrameData;
    const hitLinksMap: { [k: string]: boolean } = {};

    for (let i = 0; i < linksRenderFrameData.length; i++) {
      const linkRenderData = linksRenderFrameData[i];

      if (hitLinksMap[linkRenderData.linkUuid]) continue;

      const { vertexData } = linkRenderData;

      // final vertex's hitbox does not exist
      // so we skip it (subtract 1 from length)
      for (let j = 0; j < vertexData.length - 1; j++) {
        if (hitLinksMap[linkRenderData.linkUuid]) break;

        const hitbox = getVertexHitboxForIndex(vertexData, j);

        if (
          hitbox.x1 < endX &&
          hitbox.x2 > startX &&
          hitbox.y1 < endY &&
          hitbox.y2 > startY
        ) {
          hitLinksMap[linkRenderData.linkUuid] = true;

          selectedLinkUuids.push(linkRenderData.linkUuid);
        }
      }
    }

    const st = rendererState.refs.current;
    const selectionChanged =
      selectedBlockUuids.length !== st.selectedNodeIds.length ||
      selectedLinkUuids.length !== st.selectedLinkIds.length ||
      selectedAnnotationUuids.length !== st.selectedAnnotationIds.length ||
      selectedBlockUuids.some((uuid) => !st.selectedNodeIds.includes(uuid)) ||
      selectedLinkUuids.some((uuid) => !st.selectedLinkIds.includes(uuid)) ||
      selectedAnnotationUuids.some(
        (uuid) => !st.selectedAnnotationIds.includes(uuid),
      );

    if (selectionChanged) {
      // FIXME the modifier key should not be looked up from unreliable keysPressed
      if (
        keysPressed[clickModifierConfig.selectMultiple] ||
        keysPressed[clickModifierConfig.selectMultipleMacOS]
      ) {
        rendererState.dispatch(
          modelActions.setSelections({
            selectionParentPath: getCurrentModelRef().submodelPath,
            selectedBlockIds: selectedBlockUuids,
            selectedLinkIds: selectedLinkUuids,
            selectedAnnotationIds: selectedAnnotationUuids,
          }),
        );
        // these two cases are the same
      } else {
        rendererState.dispatch(
          modelActions.setSelections({
            selectionParentPath: getCurrentModelRef().submodelPath,
            selectedBlockIds: selectedBlockUuids,
            selectedLinkIds: selectedLinkUuids,
            selectedAnnotationIds: selectedAnnotationUuids,
          }),
        );
      }
    }
  }
}
