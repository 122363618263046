// !!!! -- DO NOT MANUALLY EDIT -- !!!!
// This file was generated by something in scripts/generateDefaultBlockData.js
// If you want to change the contents of this file, it should be automated
//
// (You can edit this file locally, but it may not be checked into the codebase -
// PRs with a manual edit of this file are forbidden from being merged.)

import { computationBlockDefaults } from 'app/generated_blocks/computationBlockDefaults';
import type { ComputationBlockClass } from 'app/generated_types/ComputationBlockClass';
import deepmerge from 'deepmerge';

export const PyTwin: ComputationBlockClass = deepmerge(
  computationBlockDefaults,
  {
    "base": {
      "name": "PyTwin",
      "namespace": "core",
      "description": "Block to incorporate Ansys PyTwin models"
    },
    "modes": {
      "time": "discrete"
    },
    "ports": {
      "has_automatic_ports": true
    },
    "parameter_definitions": [
      {
        "name": "dt",
        "display_name": "Δt",
        "data_type": "float",
        "description": "Discrete-time update period for the twin. If empty, matches the global clock tick.",
        "optional": true,
        "default_value": ""
      },
      {
        "name": "pytwin_file",
        "description": "Name of the PyTwin .twin file to use",
        "data_type": "file",
        "default_value": "",
        "file_formats": [
          "*.twin"
        ]
      },
      {
        "name": "pytwin_config",
        "description": "Name of the PyTwin .json config file for initialization",
        "data_type": "file",
        "optional": true,
        "default_value": "",
        "file_formats": [
          "*.json"
        ]
      },
      {
        "name": "parameters",
        "description": "Dictionary of model parameters",
        "data_type": "any",
        "optional": true,
        "default_value": ""
      },
      {
        "name": "inputs",
        "description": "Dictionary of model inputs",
        "data_type": "any",
        "optional": true,
        "default_value": ""
      }
    ]
  }
);
