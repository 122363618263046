/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Expand: React.FC<Props> = (props) => (
  <svg viewBox="0 0 24 24" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M4.25 4.25H7.94737C8.36158 4.25 8.69737 4.58579 8.69737 5C8.69737 5.41421 8.36158 5.75 7.94737 5.75H6.81066L12 10.9393L17.1893 5.75H16.0526C15.6384 5.75 15.3026 5.41421 15.3026 5C15.3026 4.58579 15.6384 4.25 16.0526 4.25H19.75V7.94737C19.75 8.36158 19.4142 8.69737 19 8.69737C18.5858 8.69737 18.25 8.36158 18.25 7.94737V6.81066L13.0607 12L18.25 17.1893L18.25 16.0526C18.25 15.6384 18.5858 15.3026 19 15.3026C19.4142 15.3026 19.75 15.6384 19.75 16.0526L19.75 19.75H16.0526C15.6384 19.75 15.3026 19.4142 15.3026 19C15.3026 18.5858 15.6384 18.25 16.0526 18.25H17.1893L12 13.0607L6.81066 18.25H7.94737C8.36158 18.25 8.69737 18.5858 8.69737 19C8.69737 19.4142 8.36158 19.75 7.94737 19.75H4.25V16.0526C4.25 15.6384 4.58579 15.3026 5 15.3026C5.41421 15.3026 5.75 15.6384 5.75 16.0526V17.1893L10.9393 12L5.75 6.81066V7.94737C5.75 8.36158 5.41421 8.69737 5 8.69737C4.58579 8.69737 4.25 8.36158 4.25 7.94737V4.25Z"
      fill={props.fill}
    />
  </svg>
);

Expand.defaultProps = {
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Expand;
