import styled from '@emotion/styled';
import React from 'react';
import { H5 } from 'ui/common/typography/Typography';

interface Props {
  children: React.ReactNode;
}

const ListTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  align-self: stretch;
  margin-top: ${(props) => props.theme.spacing.xxlarge};
  margin-left: ${(props) => props.theme.spacing.large};
  margin-right: ${(props) => props.theme.spacing.normal};
  margin-bottom: ${(props) => props.theme.spacing.small};
  color: ${(props) => props.theme.colors.text.tertiary};
`;

const Content = styled(H5)`
  flex-grow: 1;
`;

const ListTitle: React.FC<Props> = ({ children }) => (
  <ListTitleWrapper>
    <Content>{children}</Content>
  </ListTitleWrapper>
);

export default ListTitle;
