/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const SortAlphaAscending: React.FC<Props> = (props) => (
  <svg viewBox="0 0 24 24" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.75 20.0607C6.75 20.4749 7.08579 20.8107 7.5 20.8107C7.91421 20.8107 8.25 20.4749 8.25 20.0607V5.37132L9.96967 7.09099C10.2626 7.38388 10.7374 7.38388 11.0303 7.09099C11.3232 6.7981 11.3232 6.32322 11.0303 6.03033L7.5 2.5L3.96967 6.03033C3.67678 6.32322 3.67678 6.7981 3.96967 7.09099C4.26256 7.38388 4.73744 7.38388 5.03033 7.09099L6.75 5.37132V20.0607Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M17.4076 10.5C17.3427 10.5 17.3006 10.4643 17.2811 10.3929L16.9893 9.34286C16.9763 9.31429 16.9601 9.3 16.9407 9.3H14.5477C14.5282 9.3 14.512 9.31429 14.499 9.34286L14.2169 10.3929C14.1975 10.4643 14.1553 10.5 14.0905 10.5H12.8551C12.8161 10.5 12.787 10.4893 12.7675 10.4679C12.7481 10.4393 12.7448 10.4 12.7578 10.35L14.8589 3.10714C14.8784 3.03571 14.9206 3 14.9854 3H16.5126C16.5775 3 16.6196 3.03571 16.6391 3.10714L18.7403 10.35C18.7468 10.3643 18.75 10.3821 18.75 10.4036C18.75 10.4679 18.7143 10.5 18.643 10.5H17.4076ZM14.8492 8.06786C14.8427 8.11071 14.8557 8.13214 14.8881 8.13214H16.6002C16.6391 8.13214 16.6521 8.11071 16.6391 8.06786L15.7636 4.88571C15.7571 4.85714 15.7474 4.84643 15.7344 4.85357C15.7215 4.85357 15.7117 4.86429 15.7053 4.88571L14.8492 8.06786Z"
      fill={props.fill}
    />
    <path
      d="M12.8978 20.25C12.8568 20.25 12.8198 20.2393 12.787 20.2179C12.7623 20.1893 12.75 20.1571 12.75 20.1214V18.9643C12.75 18.9071 12.7705 18.8536 12.8116 18.8036L16.7911 14.1107C16.8075 14.0964 16.8075 14.0821 16.7911 14.0679C16.7829 14.0536 16.7664 14.0464 16.7418 14.0464H12.8978C12.8568 14.0464 12.8198 14.0357 12.787 14.0143C12.7623 13.9857 12.75 13.9536 12.75 13.9179V12.8786C12.75 12.8429 12.7623 12.8143 12.787 12.7929C12.8198 12.7643 12.8568 12.75 12.8978 12.75H18.6022C18.6432 12.75 18.6761 12.7643 18.7007 12.7929C18.7336 12.8143 18.75 12.8429 18.75 12.8786V14.0357C18.75 14.0929 18.7295 14.1464 18.6884 14.1964L14.7089 18.8893C14.7007 18.9036 14.7007 18.9179 14.7089 18.9321C14.7171 18.9464 14.7336 18.9536 14.7582 18.9536H18.6022C18.6432 18.9536 18.6761 18.9679 18.7007 18.9964C18.7336 19.0179 18.75 19.0464 18.75 19.0821V20.1214C18.75 20.1571 18.7336 20.1893 18.7007 20.2179C18.6761 20.2393 18.6432 20.25 18.6022 20.25H12.8978Z"
      fill={props.fill}
    />
  </svg>
);

SortAlphaAscending.defaultProps = {
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default SortAlphaAscending;
