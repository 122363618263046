import React from 'react';
import { useDebounce } from 'react-use';

import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
  userPreferencesActions,
  userPreferencesSelectors,
  UserPreferencesState,
  USER_PREFERENCE_VERSION,
} from 'app/slices/userPreferencesSlice';
import {
  useGetUserPreferencesQuery,
  usePutUserPreferencesMutation,
} from 'app/apiGenerated/generatedApi';

import useModelEditorPreferences from './useModelEditorPreferences';

const useUserPreferences = () => {
  const [callPutUserPreferences] = usePutUserPreferencesMutation();
  const { data: backendUserPreferences } = useGetUserPreferencesQuery();
  const dispatch = useAppDispatch();
  const [isInitialized, setIsInitialized] = React.useState(false);
  const userPreferences = useAppSelector(
    userPreferencesSelectors.selectUserPreferences,
  );
  useModelEditorPreferences();

  useDebounce(
    () => {
      if (isInitialized) {
        callPutUserPreferences({ jsonRawData: userPreferences });
      }
    },
    1000,
    [isInitialized, userPreferences, callPutUserPreferences],
  );

  const loadUserPreferences = React.useCallback(() => {
    const backendPrefs = backendUserPreferences as UserPreferencesState;
    if (!isInitialized && backendPrefs) {
      if (backendPrefs.version === USER_PREFERENCE_VERSION) {
        dispatch(
          userPreferencesActions.initializeUserPreferences(backendPrefs),
        );
      } else {
        dispatch(userPreferencesActions.initializeUserPreferences());
      }
      setIsInitialized(true);
    }
  }, [dispatch, backendUserPreferences, isInitialized]);

  return { loadUserPreferences };
};

export default useUserPreferences;
