import { LinkInstance } from 'app/generated_types/SimulationModel';
import { RendererState } from './modelRenderer';

export const isLinkDependent = (
  maybeDependentLinkUuid: string,
  firstCheckLinkUuid: string,
  rendererState: RendererState,
): boolean => {
  let linkUuidToCheck: string | null = firstCheckLinkUuid;

  while (linkUuidToCheck) {
    if (linkUuidToCheck === maybeDependentLinkUuid) return true;

    const checkingLink: LinkInstance =
      rendererState.refs.current.links[
        rendererState.refs.current.linksIndexLUT[linkUuidToCheck]
      ];

    linkUuidToCheck = null;

    if (
      checkingLink &&
      checkingLink.uiprops.link_type.connection_method === 'link_tap'
    ) {
      linkUuidToCheck = checkingLink.uiprops.link_type.tapped_link_uuid;
    }
  }

  return false;
};
