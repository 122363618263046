import { useGetListUserCredentialsQuery } from 'app/enhancedApi';
import React from 'react';

export function useUserCredentials() {
  const { data: userCredentials, isLoading: isLoadingCredentials } =
    useGetListUserCredentialsQuery(undefined, {
      refetchOnMountOrArgChange: true, // FIXME: remove this once well tested
    });

  const isGitHubConnected = React.useMemo(
    () =>
      userCredentials?.items?.some(
        (credential) => credential.kind === 'github_refresh_token',
      ),
    [userCredentials],
  );

  return {
    isLoadingCredentials,
    userCredentials,
    isGitHubConnected,
  };
}
