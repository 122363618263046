import { useAppSelector } from 'app/hooks';
import React from 'react';
import SimulationSettingsDetails from 'ui/modelEditor/SimulationSettingsDetails';
import SimulationSolverDetails from 'ui/modelEditor/SimulationSolverDetails';
import SubmodelPortDetails from 'ui/modelEditor/SubmodelPortDetails';
import { SubmodelSimulationDetails } from 'ui/modelEditor/SubmodelSimulationDetails';
import { useModelPermission } from 'ui/permission/useModelPermission';
import { useAppParams } from 'util/useAppParams';
import { DetailsContainer } from './DetailsComponents';
import DeveloperOptionsDetails from './DeveloperOptionsDetails';

const ModelSimulationSidebar: React.FC = () => {
  const topLevelModelType = useAppSelector(
    (state) => state.submodels.topLevelModelType,
  );

  const { projectId, modelId, versionId } = useAppParams();
  const { canEditCurrentModelVersion } = useModelPermission(
    projectId,
    modelId,
    versionId,
  );

  return (
    <DetailsContainer>
      {topLevelModelType === 'Model' && (
        <>
          <SimulationSettingsDetails />
          <SimulationSolverDetails />
        </>
      )}
      {topLevelModelType === 'Submodel' && (
        <>
          <SubmodelSimulationDetails canEdit={canEditCurrentModelVersion} />
          <SubmodelPortDetails
            canEditCurrentModelVersion={canEditCurrentModelVersion}
          />
        </>
      )}
      <DeveloperOptionsDetails />
    </DetailsContainer>
  );
};

export default ModelSimulationSidebar;
