/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const CFile: React.FC<Props> = (props) => (
  <svg viewBox="0 0 24 24" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.56097 5.6H16.7072V6.82606L18.2682 7.63227V5.6C18.2682 4.71634 17.5693 4 16.7072 4H6.56097C5.69887 4 5 4.71634 5 5.6V18.4C5 19.2837 5.69887 20 6.56097 20H16.7072C17.5693 20 18.2682 19.2837 18.2682 18.4V17.1944L16.7072 18.09V18.4H6.56097L6.56097 5.6Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M19.885 9.93558L19.8898 9.9415C19.936 9.98959 19.9624 10.0467 19.9776 10.1066C19.9922 10.1538 20 10.2034 20 10.2541V14.7463C20 14.9277 19.901 15.0953 19.7402 15.186L15.7596 17.4322C15.5988 17.5229 15.4008 17.5229 15.2402 17.4322L11.2596 15.186C11.099 15.0953 11 14.9279 11 14.7465V10.2372C11 10.0567 11.0988 9.89007 11.2591 9.80007C12.0509 9.35435 14.434 8.01269 15.2257 7.56673C15.3156 7.51618 15.4232 7.49454 15.5287 7.50116C15.5603 7.50287 15.5916 7.50738 15.6225 7.5147C15.6722 7.5261 15.72 7.54411 15.7636 7.56864C16.1593 7.7914 16.9505 8.23511 17.7419 8.67891L17.7429 8.67947C18.5346 9.12346 19.3263 9.56745 19.7222 9.7903C19.7861 9.82649 19.8347 9.8702 19.8782 9.92758L19.885 9.93558ZM12.5935 14.1048C12.3326 13.6286 12.1841 13.0816 12.1841 12.5001C12.1841 10.6603 13.6698 9.16673 15.4999 9.16673C16.7133 9.16673 17.7753 9.82331 18.3532 10.802L18.3549 10.8048L16.9151 11.6318L16.9127 11.628C16.6208 11.1514 16.097 10.8334 15.4999 10.8334C14.5848 10.8334 13.842 11.5801 13.842 12.5001C13.842 12.7587 13.9008 13.0038 14.0056 13.2223C14.2735 13.7809 14.8423 14.1667 15.4999 14.1667C16.1086 14.1667 16.6411 13.8362 16.9295 13.344L18.3824 14.1477C17.8115 15.1542 16.734 15.8334 15.4999 15.8334C14.2484 15.8334 13.1579 15.1349 12.5935 14.1048Z"
      fill={props.fill}
    />
    <path d="M8 8.5H11L12.7 7.5H8V8.5Z" fill={props.fill} />
    <path d="M8 11.5H9.5V10.5H8V11.5Z" fill={props.fill} />
    <path d="M8 14.5H9.5V13.5H8V14.5Z" fill={props.fill} />
  </svg>
);

CFile.defaultProps = {
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default CFile;
