import { SubmodelFullUI } from 'app/apiTransformers/convertGetSubmodel';
import {
  ModelDiagram,
  NodeInstance,
  SubmodelInstance,
  SubmodelsSection,
} from 'app/generated_types/SimulationModel';
import { ModelState } from 'app/modelState/ModelState';
import {
  getReferenceSubmodelByNode,
  getSpecificReferenceSubmodelByNode,
} from 'app/utils/submodelUtils';

/**
 * Only use for editing the current diagram.
 * This method does not walk through
 * reference submodels boundaries.
 */
export const getLocalSubmodelDiagram = (
  submodels: SubmodelsSection,
  submodelNodeUuid: string,
): ModelDiagram | null => {
  const reference = submodels?.references[submodelNodeUuid];
  if (!reference?.diagram_uuid) {
    return null;
  }

  const diagram = submodels.diagrams[reference.diagram_uuid];
  if (!diagram) {
    console.error('could not find diagram for submodel', submodelNodeUuid);
    return null;
  }

  return diagram;
};

/**
 * Given a node ID (that represents a block inside a local submodel), finds and returns the parent local submodel node
 */
export function findLocalSubmodelParentBlock(
  rootNodes: NodeInstance[],
  submodels: SubmodelsSection,
  nodeId: string,
) {
  let nodeDiagramId;
  // First, we find the submodel this block belongs to
  for (const [diagramId, diagram] of Object.entries(submodels.diagrams)) {
    for (const node of diagram?.nodes || []) {
      if (node.uuid === nodeId) {
        nodeDiagramId = diagramId;
        break;
      }
    }
    if (nodeDiagramId) break;
  }
  if (!nodeDiagramId) return;
  // Now we look for that submodel ID on the list of submodel references
  let referenceId;
  for (const [refId, diagramReference] of Object.entries(
    submodels.references,
  )) {
    if (diagramReference?.diagram_uuid === nodeDiagramId) {
      referenceId = refId;
    }
  }
  if (!referenceId) return;
  // Once we have the reference ID, we need to find the node with that ID. It can be in the root model or in the submodel list
  for (const node of rootNodes) {
    if (node.uuid === referenceId) {
      return node;
    }
  }
  for (const diagram of Object.values(submodels.diagrams)) {
    for (const node of diagram?.nodes || []) {
      if (node.uuid === referenceId) {
        return node;
      }
    }
  }
}

export function findNodeInDiagram(
  nodes: NodeInstance[],
  submodels: SubmodelsSection,
  nodeId: string,
) {
  let node = nodes.find((node) => node.uuid === nodeId);
  if (node) {
    return node;
  }

  for (const diagram of Object.values(submodels.diagrams)) {
    if (!diagram) return;
    node = diagram.nodes.find((node) => node.uuid === nodeId);
    if (node) {
      return node;
    }
  }
}

// Looks for a node by its name path in a model.
// Returns the node object, if found.
// NOTE: Does not traverse submodels.
// Used by OptimizerModal.tsx
export function findNodeInDiagramByNamePath(
  nodes: NodeInstance[],
  subdiagrams: SubmodelsSection,
  namePath: string[],
): NodeInstance | null {
  const node = nodes.find((node) => node.name === namePath[0]);
  if (!node) return null;

  namePath = namePath.slice(1);
  if (namePath.length === 0) {
    return node;
  }

  if (node.type === 'core.Group') {
    const ref = subdiagrams.references[node.uuid];
    if (!ref) return null;

    const subdiagram = subdiagrams.diagrams[ref.diagram_uuid];
    if (!subdiagram) return null;

    return findNodeInDiagramByNamePath(subdiagram.nodes, subdiagrams, namePath);
  }

  return null;
}

// Looks for the node+port pair that is connected into the given target node+port.
// Returns the node object and the port's index and name, if found.
// NOTE: Does not traverse submodels.
// Used by OptimizerModal.tsx
export function findIncomingOutputLink(
  target: NodeInstance,
  inputPortIndex: number,
  model: ModelDiagram,
  subdiagrams: SubmodelsSection,
): [NodeInstance, number, string] | null {
  let owningDiagram: ModelDiagram | null = null;
  for (const diagram of [model, ...Object.values(subdiagrams.diagrams)]) {
    if (diagram?.nodes.find((node) => node.uuid === target.uuid)) {
      owningDiagram = diagram;
      break;
    }
  }
  if (!owningDiagram) return null;

  const incomingLink = owningDiagram.links.find(
    (link) =>
      link?.dst?.node === target.uuid && link?.dst?.port === inputPortIndex,
  );
  if (!incomingLink?.src) return null;

  const node = owningDiagram.nodes.find(
    (node) => node?.uuid === incomingLink?.src?.node,
  );
  if (!node) return null;

  const outport = node.outputs[incomingLink.src.port];
  if (!outport) return null;

  return [node, incomingLink.src.port, outport.name];
}

/**
 * Only use for editing the current diagram.
 * This method does not walk through
 * reference submodels boundaries.
 */
export function getNode(model: ModelState, nodeId: string) {
  const {
    submodels,
    rootModel: { nodes },
  } = model;

  return findNodeInDiagram(nodes, submodels, nodeId);
}

// Ripped off getNestedNode
export function getNamePath(
  topLevelNodes: NodeInstance[],
  topLevelSubmodels: SubmodelsSection,
  uuidPath?: string[],
  idToVersionIdToSubmodel?: Record<string, Record<string, SubmodelFullUI>>,
  idToLatestTaggedVersionId?: Record<string, string>,
) {
  if (!topLevelNodes || !topLevelSubmodels || !uuidPath) {
    return [];
  }

  let submodels: SubmodelsSection = topLevelSubmodels;
  let nodes: NodeInstance[] = topLevelNodes;
  let namePath = [];

  for (let i = 0; i < uuidPath.length; i += 1) {
    const parentId = uuidPath[i];

    // Find the next parent in the current diagram.
    const parentNode = findNodeInDiagram(nodes, submodels, parentId);
    if (!parentNode) {
      return [];
    }
    namePath.push(parentNode.name);

    // If the next parent node is a reference submodel, update the diagram
    // we are using to search for the next node.
    const submodelFull = getReferenceSubmodelByNode(
      parentNode as SubmodelInstance,
      idToVersionIdToSubmodel,
      idToLatestTaggedVersionId,
    );
    if (submodelFull) {
      submodels = submodelFull.submodels;
      nodes = submodelFull.diagram.nodes;
    }
  }

  return namePath;
}

/**
 * Finds the node information from the model or nested submodel,
 * including loaded reference submodels.
 */
export function getNestedNode(
  topLevelNodes: NodeInstance[],
  topLevelSubmodels: SubmodelsSection,
  parentPath?: string[],
  nodeId?: string,
  idToVersionIdToSubmodel?: Record<string, Record<string, SubmodelFullUI>>,
  idToLatestTaggedVersionId?: Record<string, string>,
) {
  if (!topLevelNodes || !topLevelSubmodels || !nodeId) {
    return;
  }

  let submodels: SubmodelsSection = topLevelSubmodels;
  let nodes: NodeInstance[] = topLevelNodes;

  for (let i = 0; i < (parentPath || []).length; i += 1) {
    const parentId = (parentPath || [])[i];

    // Find the next parent in the current diagram.
    const parentNode = findNodeInDiagram(nodes, submodels, parentId);
    if (!parentNode) {
      return;
    }

    // If the next parent node is a reference submodel, update the diagram
    // we are using to search for the next node.
    const submodelFull = getReferenceSubmodelByNode(
      parentNode as SubmodelInstance,
      idToVersionIdToSubmodel,
      idToLatestTaggedVersionId,
    );
    if (submodelFull) {
      submodels = submodelFull.submodels;
      nodes = submodelFull.diagram.nodes;
    }
  }

  return findNodeInDiagram(nodes, submodels, nodeId);
}

/**
 * Finds the diagram associated with any parent path,
 * including walking any reference submodels whose diagrams are
 * loaded and available.
 */
export function getDiagramForPath(
  parentPath: string[],
  topLevelDiagram: ModelDiagram,
  topLevelSubmodels: SubmodelsSection,
  idToVersionIdToSubmodelFull: Record<string, Record<string, SubmodelFullUI>>,
  idToLatestTaggedVersionId: Record<string, string>,
): {
  diagram: ModelDiagram | null;
  submodelInstanceId?: string;
  submodelReferenceProjectId?: string;
  submodelReferenceId?: string;
} {
  let submodels: SubmodelsSection = topLevelSubmodels;
  let diagram: ModelDiagram = topLevelDiagram;
  let submodelInstanceId: string | undefined;
  let submodelReferenceProjectId: string | undefined;
  let submodelReferenceId: string | undefined;

  for (let i = 0; i < parentPath.length; i += 1) {
    const parentId = parentPath[i];

    // Find the next parent in the current diagram.
    let parentNode = diagram.nodes.find((node) => node.uuid === parentId);

    // The node was not found.
    // It might be missing because a reference submodel is loading.
    if (!parentNode) {
      return {
        diagram: null,
      };
    }

    // After finding the parent node in the current diagram,
    // find the diagram that contains this parent's children.
    const submodelFull = getSpecificReferenceSubmodelByNode(
      parentNode as SubmodelInstance,
      idToVersionIdToSubmodelFull,
      idToLatestTaggedVersionId,
    );
    if (submodelFull) {
      // The parent node is a reference submodel.
      submodelInstanceId = parentNode.uuid;
      submodelReferenceProjectId = submodelFull.projectId;
      submodelReferenceId = submodelFull.id;
      submodels = submodelFull.submodels;
      diagram = submodelFull.diagram;
    } else {
      // The parent node is a reference submodel being created, a local submodel or a code block.
      const nextDiagram = getLocalSubmodelDiagram(submodels, parentId);
      if (nextDiagram) {
        diagram = nextDiagram;
      } else {
        // The node was not found.
        // It might be missing because a reference submodel is loading.
        return {
          diagram: null,
        };
      }
    }
  }

  return {
    diagram: diagram || null,
    submodelInstanceId,
    submodelReferenceProjectId,
    submodelReferenceId,
  };
}

export function getIsViewingReferenceSubmodel({
  modelId,
  referenceSubmodelId,
}: {
  modelId: string | undefined;
  referenceSubmodelId: string | undefined;
}) {
  return referenceSubmodelId && referenceSubmodelId !== modelId;
}

export function getIsCurrentDiagramReadonly({
  modelId,
  loadedModelId,
  referenceSubmodelId,
  arePermissionsLoaded,
  canEditCurrentModelVersion,
}: {
  modelId: string | undefined;
  loadedModelId: string;
  referenceSubmodelId: string | undefined;
  arePermissionsLoaded: boolean;
  canEditCurrentModelVersion: boolean;
}) {
  const isViewingReferenceSubmodel =
    referenceSubmodelId && referenceSubmodelId !== modelId;
  const isDiagramReadonly = !!(
    loadedModelId &&
    modelId === loadedModelId &&
    arePermissionsLoaded &&
    (isViewingReferenceSubmodel || !canEditCurrentModelVersion)
  );
  return isDiagramReadonly;
}

// NOTE: This does not support version pinning on submodels.
export const findNodeUuidPathByNamePath = (
  blockPath: string[],
  diagram: ModelDiagram,
  subdiagrams: SubmodelsSection,
  idToVersionIdToSubmodel?: Record<string, Record<string, SubmodelFullUI>>,
  currentUuidPath?: string[],
): string[] | undefined => {
  if (!blockPath.length) return currentUuidPath;

  const firstName = blockPath[0];
  if (!firstName) return undefined;

  const firstNode = diagram.nodes.find((n) => n.name === firstName);
  if (!firstNode) return undefined;

  if (blockPath.length === 1) {
    return [...(currentUuidPath || []), firstNode.uuid];
  }

  if (firstNode.type === 'core.ReferenceSubmodel') {
    const submodelNode = firstNode as SubmodelInstance;
    const submodelId = submodelNode.submodel_reference_uuid;
    if (!submodelId) return undefined;

    const submodelModel = idToVersionIdToSubmodel?.[submodelId]?.latest;
    if (!submodelModel) return undefined;

    const submodelBlockPath = blockPath.slice(1);
    return findNodeUuidPathByNamePath(
      submodelBlockPath,
      submodelModel.diagram,
      submodelModel.submodels,
      idToVersionIdToSubmodel,
      [...(currentUuidPath || []), firstNode.uuid],
    );
  }

  if (firstNode.type === 'core.Group') {
    const ref = subdiagrams.references[firstNode.uuid];
    if (!ref) return undefined;

    const subdiagram = subdiagrams.diagrams[ref.diagram_uuid];
    if (!subdiagram) return undefined;

    return findNodeUuidPathByNamePath(
      blockPath.slice(1),
      subdiagram,
      subdiagrams,
      idToVersionIdToSubmodel,
      [...(currentUuidPath || []), firstNode.uuid],
    );
  }

  return undefined;
};
