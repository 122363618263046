/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const ChevronRight: React.FC<Props> = (props) => (
  <svg viewBox="0 0 16 16" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M6.20353 12.2919C5.93216 12.0144 5.93216 11.5645 6.20353 11.2871L9.4183 8L6.20353 4.71294C5.93216 4.43547 5.93216 3.98559 6.20353 3.70811C6.47491 3.43063 6.9149 3.43063 7.18627 3.70811L10.7286 7.33011C11.0905 7.70008 11.0905 8.29992 10.7286 8.66989L7.18627 12.2919C6.9149 12.5694 6.47491 12.5694 6.20353 12.2919Z"
      fill={props.fill}
    />
  </svg>
);

ChevronRight.defaultProps = {
  width: '16',
  height: '16',
  viewBox: '0 0 16 16',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default ChevronRight;
