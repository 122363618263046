/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Kernel: React.FC<Props> = (props) => (
  <svg viewBox="0 0 24 24" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M12 12H13.5H17.5H18C18.5523 12 19.0073 12.4499 18.9287 12.9966C18.9044 13.1656 18.8739 13.3335 18.8374 13.5C18.6512 14.3485 18.3078 15.1594 17.8203 15.889C17.0511 17.0401 15.9579 17.9373 14.6788 18.4672C13.3997 18.997 11.9922 19.1356 10.6344 18.8655C9.2765 18.5954 8.02922 17.9287 7.05026 16.9497C6.07129 15.9708 5.4046 14.7235 5.13451 13.3656C4.86441 12.0078 5.00303 10.6003 5.53285 9.32122C6.06266 8.04213 6.95987 6.94888 8.11101 6.17971C8.84063 5.6922 9.65148 5.34876 10.5 5.1626C10.6665 5.12608 10.8344 5.09562 11.0034 5.0713C11.5501 4.99267 12 5.44772 12 6V6.5V10.5V12ZM12 13.5H17.2915C17.1356 14.0499 16.8941 14.5752 16.5731 15.0556C15.9687 15.9601 15.1098 16.6651 14.1048 17.0813C13.0998 17.4976 11.9939 17.6065 10.927 17.3943C9.86011 17.1821 8.8801 16.6583 8.11092 15.8891C7.34173 15.1199 6.8179 14.1399 6.60568 13.073C6.39347 12.0061 6.50238 10.9002 6.91867 9.89524C7.33495 8.89025 8.0399 8.03126 8.94437 7.42692C9.4248 7.1059 9.95006 6.86439 10.5 6.7085V12V12.5C10.5 13.0523 10.9477 13.5 11.5 13.5H12ZM17.6194 9.08658C17.8707 9.69321 18 10.3434 18 11H13V6C13.6566 6 14.3068 6.12933 14.9134 6.3806C15.52 6.63188 16.0712 7.00017 16.5355 7.46447C16.9998 7.92876 17.3681 8.47995 17.6194 9.08658Z"
      fill={props.fill}
    />
  </svg>
);

Kernel.defaultProps = {
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Kernel;
