import { useNavigateToProjectHome } from 'app/api/useNavigateToProjectHome';
import React from 'react';

const Home: React.FC = () => {
  const { navigateToProjectHome } = useNavigateToProjectHome();

  // Redirect to appropriate project route.
  // Each project route has its own loading logic.
  React.useEffect(() => {
    navigateToProjectHome();
  }, [navigateToProjectHome]);

  return null;
};

export default Home;
