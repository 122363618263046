/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const RequestEdit: React.FC<Props> = (props) => (
  <svg viewBox="0 0 16 16" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M9.87379 4.33332L7.85357 2.3131C7.65831 2.11784 7.34173 2.11784 7.14647 2.3131C6.9512 2.50837 6.9512 2.82495 7.14647 3.02021L7.95958 3.83332H5.92372C5.71134 3.15714 5.07963 2.66666 4.33335 2.66666C3.41288 2.66666 2.66669 3.41285 2.66669 4.33332C2.66669 5.0796 3.15717 5.71131 3.83335 5.92369V10.0763C3.15717 10.2887 2.66669 10.9204 2.66669 11.6667C2.66669 12.5871 3.41288 13.3333 4.33335 13.3333C5.25383 13.3333 6.00002 12.5871 6.00002 11.6667C6.00002 10.9204 5.50954 10.2887 4.83335 10.0763V5.92369C5.3517 5.76089 5.76092 5.35167 5.92372 4.83332H7.95958L7.14647 5.64644C6.95121 5.8417 6.9512 6.15828 7.14647 6.35354C7.34173 6.54881 7.65831 6.54881 7.85357 6.35354L9.87379 4.33332ZM4.33335 12.3333C4.70154 12.3333 5.00002 12.0348 5.00002 11.6667C5.00002 11.2985 4.70154 11 4.33335 11C3.96516 11 3.66669 11.2985 3.66669 11.6667C3.66669 12.0348 3.96516 12.3333 4.33335 12.3333ZM5.00002 4.33332C5.00002 4.70151 4.70154 4.99999 4.33335 4.99999C3.96516 4.99999 3.66669 4.70151 3.66669 4.33332C3.66669 3.96513 3.96516 3.66666 4.33335 3.66666C4.70154 3.66666 5.00002 3.96513 5.00002 4.33332Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M11.6667 5.99999C12.5872 5.99999 13.3334 5.2538 13.3334 4.33332C13.3334 3.41285 12.5872 2.66666 11.6667 2.66666C10.7462 2.66666 10 3.41285 10 4.33332C10 5.2538 10.7462 5.99999 11.6667 5.99999ZM11.6667 4.99999C12.0349 4.99999 12.3334 4.70151 12.3334 4.33332C12.3334 3.96513 12.0349 3.66666 11.6667 3.66666C11.2985 3.66666 11 3.96513 11 4.33332C11 4.70151 11.2985 4.99999 11.6667 4.99999Z"
      fill={props.fill}
    />
  </svg>
);

RequestEdit.defaultProps = {
  width: '16',
  height: '16',
  viewBox: '0 0 16 16',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default RequestEdit;
