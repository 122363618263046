/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Exclamation: React.FC<Props> = (props) => (
  <svg viewBox="0 0 24 24" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.9701 8.82924C12.9701 8.30307 12.5358 7.87652 12 7.87652C11.4642 7.87652 11.0299 8.30307 11.0299 8.82924V13.5692C11.0299 14.0953 11.4642 14.5219 12 14.5219C12.5358 14.5219 12.9701 14.0953 12.9701 13.5692V8.82924Z"
      fill={props.fill}
    />
    <path
      d="M12.9701 16.4273C12.9701 16.9535 12.5358 17.38 12 17.38C11.4642 17.38 11.0299 16.9535 11.0299 16.4273C11.0299 15.9011 11.4642 15.4746 12 15.4746C12.5358 15.4746 12.9701 15.9011 12.9701 16.4273Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M13.9291 5.14064C13.1092 3.61979 10.8908 3.61979 10.0709 5.14064L3.75666 16.8534C2.98703 18.2811 4.04089 20 5.68578 20H18.3142C19.9591 20 21.013 18.2811 20.2433 16.8534L13.9291 5.14064ZM11.357 5.80929C11.6303 5.30234 12.3697 5.30234 12.643 5.80929L18.9573 17.5221C19.2138 17.9979 18.8625 18.5709 18.3142 18.5709H5.68578C5.13748 18.5709 4.7862 17.9979 5.04274 17.5221L11.357 5.80929Z"
      fill={props.fill}
    />
  </svg>
);

Exclamation.defaultProps = {
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Exclamation;
