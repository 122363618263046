import styled from '@emotion/styled';
import { t } from '@lingui/macro';
import { NodeInstance, Parameter } from 'app/generated_types/SimulationModel';
import { nodeTypeIsLocalSubdiagram } from 'app/helpers';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { getInheritedParametersFromState } from 'app/modelState/ModelState';
import { modelActions } from 'app/slices/modelSlice';
import React from 'react';
import Button from 'ui/common/Button/Button';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import { Remove } from 'ui/common/Icons/Standard';
import { TextInputAlign } from 'ui/common/Input/inputTypes';
import { requiredRules } from 'ui/common/Input/inputValidation';
import { isValidBlockParameterNameRuleSet } from 'ui/common/Input/inputValidationForModels';
import SectionHeading from 'ui/common/Inputs/SectionHeading';
import {
  DetailInputRowsSection,
  DetailsInput,
  DetailsLabel,
  DetailsRow,
  DetailsSection,
} from 'ui/modelEditor/DetailsComponents';

const InheritedParameterValueInput = styled(DetailsInput)`
  input {
    padding-right: ${({ theme }) => theme.spacing.normal};
  }
`;

interface Props {
  parentPath: string[];
  submodelNode: NodeInstance;
  canEdit: boolean;
}

const LegacySubmodelParametersDetails: React.FC<Props> = ({
  parentPath,
  submodelNode,
  canEdit,
}: Props) => {
  const dispatch = useAppDispatch();

  const nodeInheritedParameters = useAppSelector((state) => {
    if (submodelNode && nodeTypeIsLocalSubdiagram(submodelNode.type)) {
      return getInheritedParametersFromState(state.model.present, parentPath);
    }
    return null;
  });

  const addExtraParameter = () => {
    dispatch(
      modelActions.addNodeExtraParameter({
        parentPath,
        nodeUuid: submodelNode.uuid,
      }),
    );
  };
  const renameExtraParameter = (oldName: string, newName: string) => {
    dispatch(
      modelActions.renameNodeExtraParameter({
        parentPath,
        nodeUuid: submodelNode.uuid,
        oldName,
        newName,
      }),
    );
  };
  const updateExtraParameter = (
    paramName: string,
    value: string,
    inputHeight?: string,
  ) => {
    dispatch(
      modelActions.updateNodeExtraParameter({
        parentPath,
        nodeUuid: submodelNode.uuid,
        paramName,
        value,
        inputHeight,
      }),
    );
  };
  const removeExtraParameter = (paramName: string) => {
    dispatch(
      modelActions.removeNodeExtraParameter({
        parentPath,
        nodeUuid: submodelNode.uuid,
        paramName,
      }),
    );
  };

  const paramKeys = Object.keys(submodelNode.parameters);
  const paramNames = paramKeys.sort((a: string, b: string): number => {
    const p1 = submodelNode.parameters[a] as Parameter;
    const p2 = submodelNode.parameters[b] as Parameter;
    if (p1.order === undefined || p2.order === undefined) {
      console.warn('Some parameters are missing their order key');
      return a.localeCompare(b);
    }
    return p1.order - p2.order;
  });

  return (
    <>
      <SectionHeading
        testId="submodel-parameters"
        onButtonClick={canEdit ? addExtraParameter : undefined}
        buttonTooltip={t({
          id: 'blockDetails.addSubmodelParametersButtonTooltip',
          message: 'Add submodel parameter',
        })}>
        {t({
          id: 'blockDetails.SubmodelParameterDefinitionsTitle',
          message: 'Submodel Parameters',
        })}
      </SectionHeading>
      <DetailInputRowsSection>
        {paramNames.map((paramName, index) => {
          const param = submodelNode.parameters[paramName];
          if (!param) {
            console.error(
              `param '${paramName}' is undefined in block`,
              submodelNode,
            );
            return null;
          }
          const nameKey = `extra-param-name-${paramName}`;
          const valueKey = `extra-param-value-${paramName}`;
          return param.source !== 'extra' ? null : (
            <DetailsSection key={paramName} vertical>
              <DetailsRow>
                <DetailsInput
                  grow
                  testId={nameKey}
                  value={paramName}
                  onSubmitValue={(newName) =>
                    renameExtraParameter(paramName, newName)
                  }
                  disabled={!canEdit}
                  validationRules={isValidBlockParameterNameRuleSet(
                    paramNames,
                    index,
                    submodelNode,
                  )}
                />
                {canEdit && (
                  <Button
                    variant={ButtonVariants.LargeTertiary}
                    Icon={Remove}
                    onClick={() => removeExtraParameter(paramName)}
                  />
                )}
              </DetailsRow>
              <DetailsRow>
                <DetailsInput
                  grow
                  testId={valueKey}
                  value={param.value}
                  onSubmitValue={(s, { inputHeight }) =>
                    updateExtraParameter(paramName, s, inputHeight)
                  }
                  multilineHeight={
                    submodelNode.uiprops?.parameter_heights
                      ? submodelNode.uiprops?.parameter_heights[paramName]
                      : undefined
                  }
                  disabled={!canEdit}
                  hasBorder
                  allowMultiline
                  isMonospaced
                  validationRules={requiredRules}
                />
              </DetailsRow>
            </DetailsSection>
          );
        })}
      </DetailInputRowsSection>
      {nodeInheritedParameters && (
        <>
          <SectionHeading>
            {t({
              id: 'blockDetails.SubmodelInheritedParametersTitle',
              message: 'Inherited parameters',
            })}
          </SectionHeading>
          <DetailInputRowsSection>
            {nodeInheritedParameters.map((param) => (
              <DetailsSection key={param.name}>
                <DetailsLabel>{param.name}</DetailsLabel>
                <InheritedParameterValueInput
                  value={param.value}
                  align={TextInputAlign.Right}
                  hasBorder
                  disabled
                  validationRules={requiredRules}
                />
              </DetailsSection>
            ))}
          </DetailInputRowsSection>
        </>
      )}
    </>
  );
};

export default LegacySubmodelParametersDetails;
