import { BlockInstance } from '@collimator/model-schemas-ts';
import { t } from '@lingui/macro';
import { PortSide } from 'app/common_types/PortTypes';
import { blockClassLookup } from 'app/generated_blocks';
import {
  BlockParameterDefinition,
  ComputationBlockClass,
} from 'app/generated_types/ComputationBlockClass';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { modelActions } from 'app/slices/modelSlice';
import React, { useEffect } from 'react';
import { CheckboxStringValue } from 'ui/common/CheckboxStringValue';
import SectionHeading from 'ui/common/Inputs/SectionHeading';
import BlockParameter from '../BlockParameter';
import {
  DetailInputRowsSection,
  DetailsLabel,
  DetailsSection,
} from '../DetailsComponents';
import { SindyFitButton } from '../SindyFitButton';

interface Props {
  parentPath: string[];
  selectedNode: BlockInstance;
  canEdit: boolean;
}

// all params used for training
export const TrainingParamNames = [
  'file_name',
  'header_as_first_row',
  'time_column',
  'state_columns',
  'control_input_columns',
  'state_derivatives_columns',
  'poly_order',
  'threshold',
  'dt',
  'discrete_time',
  'alpha',
  'max_iter',
  'fourier_n_frequencies',
  'normalize_columns',
];

const csvParamNames = [
  'file_name',
  'header_as_first_row',
  'state_columns',
  'control_input_columns',
  'state_derivatives_columns',
  'discrete_time',
];

const optimParamNames = ['threshold', 'alpha', 'max_iter', 'normalize_columns'];

const sindyParamNames = ['poly_order', 'fourier_n_frequencies'];

const simParamNames = ['discrete_time_update_interval', 'initial_state'];

export const SindyBlockParametersDetails: React.FC<Props> = ({
  parentPath,
  selectedNode,
  canEdit,
}: Props) => {
  const [fixedDt, setFixedDt] = React.useState('false');
  const dispatch = useAppDispatch();
  const selectionParentPath = useAppSelector(
    (state) => state.model.present?.selectionParentPath,
  );

  const controlInputColumns = selectedNode.parameters?.control_input_columns;

  useEffect(() => {
    if (!controlInputColumns) return;

    dispatch(
      modelActions.removeAllInputPorts({
        parentPath,
        nodeUuid: selectedNode.uuid,
      }),
    );

    if (controlInputColumns.value) {
      dispatch(
        modelActions.addPort({
          parentPath,
          nodeUuid: selectedNode.uuid,
          portSide: PortSide.Input,
          kind: 'conditional',
        }),
      );
    }
  }, [controlInputColumns, dispatch, parentPath, selectedNode.uuid]);

  const blockClass = blockClassLookup(
    selectedNode.type,
  ) as ComputationBlockClass;
  const parameterDefinitions =
    blockClass.parameter_definitions as BlockParameterDefinition[];

  const requiredTrainingParamNames = ['file_name', 'state_column'];

  const trainingResultColumnNames = [
    'coefficients',
    'base_feature_names',
    'feature_names',
    'equations',
  ];

  const trainingResultsParamDefs = parameterDefinitions.filter((param) =>
    trainingResultColumnNames.includes(param.name),
  );
  const csvParamDefs = parameterDefinitions.filter((param) =>
    csvParamNames.includes(param.name),
  );
  const optimParamDefs = parameterDefinitions.filter((param) =>
    optimParamNames.includes(param.name),
  );
  const sindyParamDefs = parameterDefinitions.filter((param) =>
    sindyParamNames.includes(param.name),
  );
  const simParamDefs = parameterDefinitions.filter((param) =>
    simParamNames.includes(param.name),
  );

  const dtParam = parameterDefinitions.find(
    (param) => param.name === 'dt',
  ) as BlockParameterDefinition;

  const timeColumnParam = parameterDefinitions.find(
    (param) => param.name === 'time_column',
  ) as BlockParameterDefinition;

  const onReset = () => {
    const resetParamNames = [
      'coefficients',
      'base_feature_names',
      'feature_names',
      'equations',
    ];

    resetParamNames.forEach((paramName) => {
      dispatch(
        modelActions.deleteBlockParameter({
          parentPath: selectionParentPath,
          blockUuid: selectedNode.uuid,
          paramName,
        }),
      );
    });

    dispatch(
      modelActions.setNodeAutotuned({
        uuid: selectedNode.uuid,
        tuned: false,
      }),
    );
  };

  const onFixedDtChange = (value: string) => {
    setFixedDt(value);
    let paramToEmpty = value === 'true' ? timeColumnParam : dtParam;
    let paramToReset = value === 'true' ? dtParam : timeColumnParam;
    dispatch(
      modelActions.changeBlockParameter({
        parentPath,
        nodeUuid: selectedNode.uuid,
        paramName: paramToEmpty.name,
        value: '',
      }),
    );
    dispatch(
      modelActions.changeBlockParameter({
        parentPath,
        nodeUuid: selectedNode.uuid,
        paramName: paramToReset.name,
        value: paramToReset.default_value || '',
      }),
    );
  };

  // set fixed dt to false on mount, will empty the dt parameter
  React.useEffect(() => {
    onFixedDtChange(fixedDt);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <SectionHeading testId="sindy-sim-parameter-definition-details">
        {t({
          id: 'blockDetails.sindyBlockSimParametersTitle',
          message: 'Simulation',
        })}
      </SectionHeading>
      <DetailInputRowsSection>
        {simParamDefs.map((param) => (
          <BlockParameter
            parentPath={parentPath}
            selectedNode={selectedNode}
            paramDef={param}
            key={`row-${param.name}-${selectedNode.uuid}`}
            disabled={!canEdit}
            isOptional
          />
        ))}
        {trainingResultsParamDefs.map((param) => (
          <BlockParameter
            parentPath={parentPath}
            selectedNode={selectedNode}
            paramDef={param}
            key={`row-${param.name}-${selectedNode.uuid}`}
            onReset={selectedNode.uiprops.is_autotuned ? onReset : undefined}
            isOptional
            disabled
          />
        ))}
      </DetailInputRowsSection>
      <DetailInputRowsSection>
        <SectionHeading testId="sindy-csv-parameter-definition-details">
          {t({
            id: 'blockDetails.sindyBlockCsvParametersTitle',
            message: 'CSV Data',
          })}
        </SectionHeading>
        {csvParamDefs.map((param) => (
          <BlockParameter
            parentPath={parentPath}
            selectedNode={selectedNode}
            paramDef={param}
            key={`row-${param.name}-${selectedNode.uuid}`}
            disabled={!canEdit}
            isOptional={!requiredTrainingParamNames.includes(param.name)}
          />
        ))}
        <DetailsSection title="Fixed dt">
          <DetailsLabel stretch>Fixed dt</DetailsLabel>
          <CheckboxStringValue value={fixedDt} onChange={onFixedDtChange} />
        </DetailsSection>
        {fixedDt === 'true' ? (
          <BlockParameter
            parentPath={parentPath}
            selectedNode={selectedNode}
            paramDef={dtParam}
            key={`row-dt-${selectedNode.uuid}`}
            disabled={!canEdit}
            isOptional={!requiredTrainingParamNames.includes(dtParam.name)}
          />
        ) : (
          <BlockParameter
            parentPath={parentPath}
            selectedNode={selectedNode}
            paramDef={timeColumnParam}
            key={`row-${timeColumnParam.name}-${selectedNode.uuid}`}
            disabled={!canEdit}
            isOptional={
              !requiredTrainingParamNames.includes(timeColumnParam.name)
            }
          />
        )}
        <SectionHeading testId="sindy-optim-parameter-definition-details">
          {t({
            id: 'blockDetails.sindyBlockOptimParametersTitle',
            message: 'Optimizer',
          })}
        </SectionHeading>
        {optimParamDefs.map((param) => (
          <BlockParameter
            parentPath={parentPath}
            selectedNode={selectedNode}
            paramDef={param}
            key={`row-${param.name}-${selectedNode.uuid}`}
            disabled={!canEdit}
            isOptional={!requiredTrainingParamNames.includes(param.name)}
          />
        ))}
        <SectionHeading testId="sindy-sindy-parameter-definition-details">
          {t({
            id: 'blockDetails.sindyBlockSindyParametersTitle',
            message: 'Sindy',
          })}
        </SectionHeading>
        {sindyParamDefs.map((param) => (
          <BlockParameter
            parentPath={parentPath}
            selectedNode={selectedNode}
            paramDef={param}
            key={`row-${param.name}-${selectedNode.uuid}`}
            disabled={!canEdit}
            isOptional={!requiredTrainingParamNames.includes(param.name)}
          />
        ))}
        <SindyFitButton selectedNode={selectedNode} />
      </DetailInputRowsSection>
    </>
  );
};
