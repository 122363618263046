// TODO: Move these types somewhere else as this is not a slice

import {
  SweepArray,
  SweepConstant,
  SweepDistribution,
  SweepRange,
} from 'app/third_party_types/model-overrides';

type ParamOption = {
  args: { id: string; label: string; default?: string }[];
  toSweepValues?: (
    ...args: string[]
  ) => SweepRange | SweepArray | SweepConstant | SweepDistribution;
  evalNumberOfValues?: (...args: string[]) => number;
};

export const ParamOptions: Record<string, ParamOption> = {
  Constant: {
    args: [{ id: 'value', label: 'Value', default: '0' }],
    toSweepValues: (value: string) => ({
      value,
      sweep_kind: 'constant_value',
    }),
    evalNumberOfValues: () => 1,
  },
  Range: {
    args: [
      { id: 'min', label: 'Min.', default: '0' },
      { id: 'max', label: 'Max.', default: '1' },
      { id: 'step', label: 'Step', default: '1' },
    ],
    toSweepValues: (min: string, max: string, step: string) => ({
      min,
      max,
      step,
      sweep_kind: 'range',
    }),
    evalNumberOfValues: (min: string, max: string, steps: string) =>
      Math.ceil((parseFloat(max) - parseFloat(min)) / parseFloat(steps)),
  },
  Array: {
    args: [{ id: 'values', label: 'Values', default: '[0]' }],
    // TODO: eval with pyodide?
    toSweepValues: (values: string) => ({
      values,
      sweep_kind: 'array',
    }),
    evalNumberOfValues: (value: string) => JSON.parse(value).length,
  },
  Uniform: {
    args: [
      { id: 'low', label: 'Low', default: '0' },
      { id: 'high', label: 'High', default: '1' },
    ],
    toSweepValues: (low: string, high: string) => ({
      sweep_kind: 'distribution',
      distribution_name: 'uniform',
      parameters: { low, high },
    }),
  },
  Normal: {
    args: [
      { id: 'mean', label: 'Mean', default: '0' },
      {
        id: 'stdDev',
        label: 'Standard Deviation',

        default: '1',
      },
    ],
    toSweepValues: (mean: string, stdDev: string) => ({
      sweep_kind: 'distribution',
      distribution_name: 'normal',
      parameters: { loc: mean, scale: stdDev },
    }),
  },
};
export type ParamTypes = keyof typeof ParamOptions;

// Map param name to expressions (not values)
type OverridenParamId = string;
export type OverridenParamSetting = { type: ParamTypes; params: string[] };
export type overridenParamsMap = Record<
  OverridenParamId,
  OverridenParamSetting
>;

export type EnsembleSimSpec = {
  overridenParams: overridenParamsMap;
  numSims?: number;
};
