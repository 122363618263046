import styled from '@emotion/styled/macro';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import {
  generatedApi,
  usePostChatSessionMutation,
  usePutChatSessionMutation,
} from 'app/apiGenerated/generatedApi';
import { ChatSession } from 'app/apiGenerated/generatedApiTypes';
import { makeTextContent } from 'app/chat/responseStreamParser';
import { useAppDispatch } from 'app/hooks';
import { uiFlagsActions } from 'app/slices/uiFlagsSlice';
import {
  Agent,
  ChatMessage,
  ChatMessageContent,
  ChatMessageRole,
} from 'app/third_party_types/chat-types';
import React, { ReactElement, useRef, useState } from 'react';
import { usePython } from 'ui/common/PythonProvider';
import { useWebSocket } from 'ui/common/WebSocketProvider';
import { useNotifications } from 'ui/common/notifications/useNotifications';
import { CHAT_PREFS_V1_KEY } from 'ui/userPreferences/chatPrefs';
import useEntityPreferences from 'ui/userPreferences/useEntityPreferences';
import { useAppParams } from 'util/useAppParams';
import AdvancedOptions from './assistant/AdvancedOptions';
import useCallChatCompletion from './assistant/CallCompletionController';
import ChatContent from './assistant/ChatContent';
import { useChatContext } from './assistant/ChatContextProvider';
import { CHAT_WIDTH, INPUT_BAR_HEIGHT } from './assistant/Sizings';
import UserInputBox from './assistant/UserInputBox';
import { useAuthorizationCheck } from './assistant/useAuthorizationCheck';

const Background = styled.div<{ hidden?: boolean }>`
  background-color: ${({ theme }) => theme.colors.grey[5]};
  isolation: isolate;
  position: relative;
  width: 100%;
  height: 100%;
  padding: ${({ theme }) => theme.spacing.large};
  margin: 0px;
  overflow: hidden;

  display: ${({ hidden }) => (hidden ? 'none' : 'flex')};
  flex-direction: column;
`;

const ChatGptWrapper = styled.div`
  overflow: auto;
  max-width: ${CHAT_WIDTH}px;
  width: calc(min(${CHAT_WIDTH}px, 100% - 40px));
  margin-left: calc(max((100% - ${CHAT_WIDTH}px) / 2, 20px));
  margin-bottom: ${INPUT_BAR_HEIGHT * 2}px;

  font-size: ${({ theme }) => theme.typography.font.standard.size};
  font-weight: ${({ theme }) => theme.typography.font.standard.weight};
  line-height: ${({ theme }) => theme.typography.font.standard.lineHeight};

  overflow-anchor: none;
`;

const OutputScrollAnchor = styled.div`
  height: ${INPUT_BAR_HEIGHT * 2}px;
  overflow-anchor: auto;
`;

const UserInputContainer = styled.div`
  position: absolute;
  max-width: ${CHAT_WIDTH}px;
  bottom: ${({ theme }) => theme.spacing.normal};
  left: calc(max((100% - ${CHAT_WIDTH}px) / 2, 20px));
  width: calc(min(${CHAT_WIDTH}px, 100% - 40px));

  display: flex;
  flex-direction: column;
`;

const GradientFade = styled.div`
  width: 100%;
  bottom: 0px;
  height: ${INPUT_BAR_HEIGHT}px;
  z-index: 0;

  background: linear-gradient(
    0deg,
    ${({ theme }) => theme.colors.grey[5]} 0%,
    #f1f3f300 100%
  );
`;

const makeUserPrompt = (
  prompt: string,
  imageUrl?: string,
  imageBase64?: string,
): ChatMessage => {
  const content: ChatMessageContent[] = [];
  if (imageUrl) {
    content.push({
      type: 'image_url',
      image_url: {
        url: imageUrl,
      },
    });
  }
  content.push(makeTextContent(prompt));
  return {
    role: ChatMessageRole.User,
    content,
    b64Images: imageBase64 ? [imageBase64] : undefined,
  };
};

const ChatGpt = ({
  forceShowAdvancedOptions,
  hidden,
}: {
  forceShowAdvancedOptions?: boolean;
  hidden?: boolean;
}): ReactElement => {
  /**
   * Local states
   */
  const scrollAnchor = useRef<HTMLDivElement | null>(null);
  const [autoScroll, setAutoScroll] = useState(true);
  const [showAdvancedOptions, setShowAdvancedOptions] = useState(
    forceShowAdvancedOptions ?? false,
  );

  const {
    agentRef,
    messages,
    plots,
    addMessage,
    setMessages,
    editMessage,
    removeMessages,
    removeAllMessages,
    addPlot,
    removePlot,
    setPlots,
    setAgent,
  } = useChatContext();

  const [input, setInput] = useState('');
  const [chatSession, setChatSession] = useState<ChatSession>();
  const [chatSessionId, setChatSessionId] = useState<string>();

  const [systemPrompt, setSystemPrompt] = useState<string | undefined>();
  const [tools, setTools] = useState<string | undefined>();

  const [isLoadingChatSession, setIsLoadingChatSession] = useState(false);
  const [isCreatingChatSession, setIsCreatingChatSession] = useState(false);
  const [abortController, setAbortController] = React.useState(
    new AbortController(),
  );

  /**
   * External states
   */
  const dispatch = useAppDispatch();
  const { modelId, projectId } = useAppParams();
  const webSocket = useWebSocket();
  const { isReady: pythonIsReady } = usePython();
  const { isAuthorized } = useAuthorizationCheck();

  useEntityPreferences(CHAT_PREFS_V1_KEY, modelId);

  /**
   * Callbacks
   * */
  const [getChatSessionLast] =
    generatedApi.endpoints.getChatSessionLast.useLazyQuery();
  const { showError } = useNotifications();
  const [callCreateChatSession] = usePostChatSessionMutation();
  const [callUpdateChatSession] = usePutChatSessionMutation();
  const [getChatSessionById] =
    generatedApi.endpoints.getChatSessionByUuid.useLazyQuery();

  const abortChatCompletion = React.useCallback(() => {
    abortController.abort();
    setAbortController(new AbortController());
  }, [abortController]);

  const { isCurrentlyCompleting } = useChatContext();

  const { openAiModelNotFound, callChatCompletion } = useCallChatCompletion({
    modelId,
    tools,
    onNewMessage: addMessage,
    onChunkReceived: editMessage,
    onError: showError,
    systemPrompt,
  });

  const createChatSession = React.useCallback(async () => {
    if (!projectId || !modelId) return;
    try {
      setIsCreatingChatSession(true);
      const { session_id } = await callCreateChatSession({
        chatSessionCreateRequest: {
          project_uuid: projectId,
          model_uuid: modelId,
        },
      }).unwrap();

      setChatSessionId(session_id);
      setIsCreatingChatSession(false);
    } catch (e) {
      showError(
        `Failed to create a chat session. Your conversation will not be saved.`,
      );
    }
  }, [callCreateChatSession, modelId, projectId, showError]);

  const fetchLastChatSessionOrCreate = React.useCallback(async () => {
    if (!projectId || !modelId) return;
    const { data, error } = await getChatSessionLast({
      projectUuid: projectId,
      modelUuid: modelId,
    });
    if ((error && (error as FetchBaseQueryError).status === 404) || !data) {
      createChatSession();
    } else {
      setChatSessionId(data.session_id);
    }
  }, [createChatSession, getChatSessionLast, modelId, projectId]);

  const sendPrompt = React.useCallback(
    async (
      prompt: string,
      imageUrl?: string,
      imageBase64?: string,
    ): Promise<void> => {
      setInput('');
      if (prompt === '') return;

      const lastPrompt: ChatMessage = makeUserPrompt(
        prompt,
        imageUrl,
        imageBase64,
      );
      addMessage(lastPrompt);
      const newMessages = [...messages, lastPrompt];
      await callChatCompletion(agentRef, newMessages, abortController.signal);
    },
    [
      abortController.signal,
      addMessage,
      agentRef,
      callChatCompletion,
      messages,
    ],
  );

  const saveChatSession = React.useCallback(
    async (
      sessionId: string,
      messages: ChatMessage[],
      plots: string[],
      isFinished?: boolean,
    ) => {
      if (!sessionId) return;
      await callUpdateChatSession({
        chatSession: {
          session_id: sessionId,
          messages,
          plots: plots.map((p, i) => ({ id: `${i}`, value: p })),
          is_finished: isFinished,
        },
      }).catch((e) => {
        showError(
          `Failed to update the chat session. Your conversation will not be saved.`,
        );
      });
    },
    [callUpdateChatSession, showError],
  );

  const restartConversation = React.useCallback(() => {
    if (chatSession?.session_id && messages.length > 0) {
      saveChatSession(chatSession?.session_id, messages, plots, true);
      setPlots([]);
      removeAllMessages();
      createChatSession();
    }
    setAgent(Agent.Main);
  }, [
    chatSession?.session_id,
    messages,
    setAgent,
    saveChatSession,
    plots,
    setPlots,
    removeAllMessages,
    createChatSession,
  ]);

  const repeatQuestion = React.useCallback(async () => {
    if (messages.length < 1) return;

    const indexes = [];
    let i = messages.length - 1;
    while (
      i > 0 &&
      (messages[i].role !== ChatMessageRole.User || messages[i].hidden)
    ) {
      indexes.push(i);
      i--;
    }
    removeMessages(indexes);

    const newMessages = messages.slice(0, i + 1);

    if (newMessages[newMessages.length - 1].role === ChatMessageRole.User) {
      await callChatCompletion(agentRef, newMessages, abortController.signal);
    }
  }, [
    messages,
    removeMessages,
    callChatCompletion,
    agentRef,
    abortController.signal,
  ]);

  const onRemoveButtonClick = React.useCallback(
    (index: number) => {
      const indexes = [index];
      if (messages[index].role === ChatMessageRole.Assistant) {
        const match = messages[index].content[0].text?.match(
          /\[\[plot_id:(\d+)\]\]/,
        );
        const plotIndex = match ? match[1] : undefined;
        if (plotIndex) {
          removePlot(parseInt(plotIndex));
        }

        // Remove the previous function calls and assistant self debug messages.
        index--;
        while (
          index > 0 &&
          ([ChatMessageRole.Tool, ChatMessageRole.Assistant].includes(
            messages[index].role,
          ) ||
            messages[index].hidden)
        ) {
          indexes.push(index);
          index--;
        }
      }
      removeMessages(indexes);
    },
    [messages, removeMessages, removePlot],
  );

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const target = e.currentTarget;
    const bottom =
      target.scrollHeight - target.scrollTop - target.clientHeight < 100;
    setAutoScroll(bottom);
  };

  /**
   * Effects
   * */
  React.useEffect(() => {
    dispatch(uiFlagsActions.requestLoadPython());
  }, [dispatch]);

  // Initialize with last chat session from the server
  React.useEffect(() => {
    if (!chatSessionId) return;
    setIsLoadingChatSession(true);
    getChatSessionById({ chatSessionUuid: chatSessionId })
      .unwrap()
      .then((data) => {
        setChatSession(data);
        // FIXME: rtk-query-codegen-openapi does not generate enum which triggers a
        // ts error here.
        // Remove this when the following PR is merged and we update reduxjs:
        // https://github.com/reduxjs/redux-toolkit/pull/2854
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        setMessages(data.messages);

        if (data.plots) {
          setPlots(data.plots.map((p) => p.value));
        }
        setIsLoadingChatSession(false);
      });
  }, [chatSessionId, getChatSessionById, setMessages, setPlots]);

  // Save the chat session when there is a new message or plot
  React.useEffect(() => {
    if (
      !chatSession?.session_id ||
      messages.length < 1 ||
      isCurrentlyCompleting
    )
      return;
    saveChatSession(chatSession?.session_id, messages, plots);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCurrentlyCompleting, messages, plots, saveChatSession]);

  // Fetch the last chat session when the model changes
  React.useEffect(() => {
    fetchLastChatSessionOrCreate();
  }, [fetchLastChatSessionOrCreate, modelId, projectId]);

  React.useEffect(() => {
    if (autoScroll && scrollAnchor.current !== null) {
      scrollAnchor.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [autoScroll]);

  React.useEffect(() => {
    if (autoScroll && scrollAnchor.current !== null) {
      scrollAnchor.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages, autoScroll]);

  // remove all messages when this component is unmounted
  React.useEffect(
    () => () => {
      removeAllMessages();
    },
    [removeAllMessages],
  );

  const isReady =
    chatSessionId !== undefined &&
    !isCreatingChatSession &&
    !isCurrentlyCompleting &&
    !isLoadingChatSession &&
    webSocket.isInit &&
    pythonIsReady;

  return (
    <Background hidden={hidden}>
      <ChatGptWrapper onScroll={handleScroll}>
        <ChatContent
          onRemoveButtonClick={onRemoveButtonClick}
          showAdvancedOptions={showAdvancedOptions}
          plots={plots}
          addPlot={addPlot}
          openAiModelNotFound={openAiModelNotFound}
        />
        <OutputScrollAnchor ref={scrollAnchor} />
        {/* <StayScrolledToBottom active={isCurrentlyCompleting} /> */}
      </ChatGptWrapper>
      <UserInputContainer>
        <GradientFade />
        <UserInputBox
          input={input}
          setInput={setInput}
          isReady={isReady && !!isAuthorized}
          onSubmit={sendPrompt}
          output={messages}
          onRepeatQuestion={repeatQuestion}
          onClickAbort={abortChatCompletion}
        />
        <AdvancedOptions
          showAdvancedOptions={showAdvancedOptions}
          setShowAdvancedOptions={setShowAdvancedOptions}
          restartConversationOnClick={restartConversation}
          setSystemPrompt={setSystemPrompt}
          systemPrompt={systemPrompt}
          setTools={setTools}
          tools={tools}
        />
      </UserInputContainer>
      {isReady && (
        <div data-test-id="python-ready" style={{ display: 'none' }} />
      )}
    </Background>
  );
};

export default ChatGpt;
