import styled from '@emotion/styled/macro';
import { t } from '@lingui/macro';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { selectEntityPrefs } from 'app/slices/entityPreferencesSlice';
import { modelActions } from 'app/slices/modelSlice';
import React from 'react';
import Button from 'ui/common/Button/Button';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import { Close, Search } from 'ui/common/Icons/Small';
import { Plus, Remove } from 'ui/common/Icons/Standard';
import Input from 'ui/common/Input/Input';
import { requiredRules } from 'ui/common/Input/inputValidation';
import { isValidParameterNameRuleSet } from 'ui/common/Input/inputValidationForModels';
import SectionHeading from 'ui/common/Inputs/SectionHeading';
import {
  DetailInputRowsSection,
  DetailsInput,
  DetailsRow,
  DetailsSection,
} from 'ui/modelEditor/DetailsComponents';
import { useModelPermission } from 'ui/permission/useModelPermission';
import {
  ENSEMBLE_SIM_PREFS_V1_KEY,
  EnsembleSimPrefsV1,
} from 'ui/userPreferences/ensembleSimModelPrefs';
import { useAppParams } from 'util/useAppParams';

const DarkenedInputWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.small};

  input,
  input:focus {
    background: #f1f3f3;
  }

  input::placeholder {
    color: #b6bbbb;
    opacity: 1;
  }

  svg {
    fill: #b6bbbb !important;
  }

  &:hover {
    svg {
      fill: #5c6f70 !important;
    }

    input::placeholder {
      color: #5c6f70 !important;
    }
  }

  svg.focused {
    fill: #082426 !important;
  }
  input.focused::placeholder {
    color: #082426 !important;
  }
`;

const ModelParameterDetails: React.FC = () => {
  const dispatch = useAppDispatch();
  const { projectId, modelId, versionId } = useAppParams();
  const { canEditCurrentModelVersion } = useModelPermission(
    projectId,
    modelId,
    versionId,
  );

  const [parameterSearchValue, setParameterSearchValue] = React.useState('');
  const modelParameters = useAppSelector(
    (state) => state.model.present?.parameters,
  );

  const filteredModelParameters = React.useMemo(() => {
    const trimmedSearchVal = parameterSearchValue.trim();

    if (trimmedSearchVal === '')
      return modelParameters.map((paramData) => ({ display: true, paramData }));

    const lowercaseSearchVal = trimmedSearchVal.toLowerCase();
    return modelParameters.map((paramData) => ({
      display: paramData.name.toLowerCase().indexOf(lowercaseSearchVal) !== -1,
      paramData,
    }));
  }, [modelParameters, parameterSearchValue]);

  // ref allows us to set this without needing an extra state tick.
  // the re-render happens as a result of adding another param.
  const autoFocusUnlocked = React.useRef(false);

  const addNewParameter = () => {
    if (!canEditCurrentModelVersion) return;

    autoFocusUnlocked.current = true;
    dispatch(modelActions.addModelParameter({}));
  };

  const changeParamValue = (index: number, value: string) => {
    dispatch(modelActions.setModelParameterValue({ index, value }));
  };

  const onParamDelete = (index: number) => {
    dispatch(modelActions.removeModelParameter({ index }));
  };

  const modelUuid = useAppSelector(
    (state) => state.modelMetadata.loadedModelId,
  );

  const ensemblePrefs: EnsembleSimPrefsV1 = useAppSelector((state) =>
    selectEntityPrefs(state, ENSEMBLE_SIM_PREFS_V1_KEY, modelUuid),
  ) as EnsembleSimPrefsV1;

  const changeParamName = (index: number) => (name: string) => {
    dispatch(modelActions.setModelParameterName({ index, name }));
  };

  return (
    <>
      <SectionHeading testId="model-parameters">
        {t({
          id: 'modelRenderer.propertiesSidebar.modelParameters.heading',
          message: 'Model parameters',
        })}
      </SectionHeading>
      <DetailInputRowsSection>
        {filteredModelParameters.length > 0 && (
          <DarkenedInputWrapper>
            <Input
              placeholder={t`Search`}
              value={parameterSearchValue}
              RightIcon={parameterSearchValue.trim() ? Close : undefined}
              noLeftIconColor
              LeftIcon={Search}
              onClickRightIcon={() => setParameterSearchValue('')}
              onChangeText={setParameterSearchValue}
            />
          </DarkenedInputWrapper>
        )}
        {filteredModelParameters.map((param, index, _array) => (
          <DetailsSection
            key={index}
            vertical
            style={{ display: param.display ? undefined : 'none' }}>
            <DetailsRow>
              <DetailsInput
                grow
                autoFocus={autoFocusUnlocked.current}
                testId={`model-param-name-${index}`}
                value={param.paramData.name}
                onSubmitValue={changeParamName(index)}
                disabled={!canEditCurrentModelVersion}
                validationRules={isValidParameterNameRuleSet(
                  modelParameters,
                  index,
                )}
              />
              {canEditCurrentModelVersion ? (
                <Button
                  testId={`model-param-remove-button-${index}`}
                  variant={ButtonVariants.LargeTertiary}
                  Icon={Remove}
                  onClick={() => onParamDelete(index)}
                />
              ) : null}
            </DetailsRow>
            <DetailsInput
              allowMultiline
              isMonospaced
              grow
              testId={`model-param-value-${index}`}
              value={param.paramData.value}
              onSubmitValue={(s) => changeParamValue(index, s)}
              disabled={!canEditCurrentModelVersion}
              hasBorder
              validationRules={requiredRules}
            />
          </DetailsSection>
        ))}
        {canEditCurrentModelVersion && (
          <Button
            variant={ButtonVariants.SmallTertiary}
            Icon={Plus}
            onClick={addNewParameter}>
            {t`Add parameter`}
          </Button>
        )}
      </DetailInputRowsSection>
    </>
  );
};

export default ModelParameterDetails;
