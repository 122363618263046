// !!!! -- DO NOT MANUALLY EDIT -- !!!!
// This file was generated by something in scripts/generateDefaultBlockData.js
// If you want to change the contents of this file, it should be automated
//
// (You can edit this file locally, but it may not be checked into the codebase -
// PRs with a manual edit of this file are forbidden from being merged.)

import { computationBlockDefaults } from 'app/generated_blocks/computationBlockDefaults';
import type { ComputationBlockClass } from 'app/generated_types/ComputationBlockClass';
import deepmerge from 'deepmerge';

export const Comparator: ComputationBlockClass = deepmerge(
  computationBlockDefaults,
  {
    "base": {
      "name": "Comparator",
      "namespace": "core",
      "description": "Compares two inputs using a given comparison operator and returns true or false.",
      "keywords": [
        "greater",
        "less",
        "equal"
      ],
      "help_url": "https://docs.collimator.ai/using-model-editors/block-library/agnostic-blocks#comparator"
    },
    "modes": {
      "time": "agnostic"
    },
    "events": {
      "count": 1
    },
    "ports": {
      "inputs": {
        "static": [
          {
            "name": "in_0"
          },
          {
            "name": "in_1"
          }
        ]
      },
      "outputs": {
        "static": [
          {
            "name": "out_0"
          }
        ]
      }
    },
    "parameter_definitions": [
      {
        "name": "operator",
        "description": "Indicates the comparison operator to use: input[0] <op> input[1].",
        "data_type": "string",
        "default_value": ">",
        "allowed_values": [
          "==",
          "!=",
          ">=",
          "<=",
          ">",
          "<"
        ]
      },
      {
        "name": "atol",
        "description": "Absolute tolerance for math.isclose ('==' and '!=' operators only)",
        "data_type": "float",
        "default_value": "1e-6"
      },
      {
        "name": "rtol",
        "description": "Relative tolerance for math.isclose ('==' and '!=' operators only)",
        "data_type": "float",
        "default_value": "1e-9"
      }
    ]
  }
);
