import { SubmodelInstance } from '@collimator/model-schemas-ts';
import {
  OptimizationAlgoName,
  OptimizationOptions,
  ParameterEstimationRequest,
} from 'app/api/custom_types/optimizations';
import { useProject } from 'app/api/useProject';
import { useGetFileReadByUuidQuery } from 'app/apiGenerated/generatedApi';
import { VersionTagValues } from 'app/apiTransformers/convertPostSubmodelsFetch';
import { useAppSelector } from 'app/hooks';
import { ModelParameters } from 'app/utils/modelDataUtils';
import { findNodeInDiagramByNamePath } from 'app/utils/modelDiagramUtils';
import { getSpecificReferenceSubmodelById } from 'app/utils/submodelUtils';
import React from 'react';
import EditableSelectInput from 'ui/common/EditableSelectInput';
import SectionHeading from 'ui/common/Inputs/SectionHeading';
import SelectInput, { SelectInputOption } from 'ui/common/SelectInput';
import DataFileParameter from 'ui/common/parameters/DataFileParameter';
import { useAppParams } from 'util/useAppParams';
import AlgorithmFields from './AlgorithmFields';
import ConstrainsSelector from './ConstraintsSelector';
import DesignParamFields from './DesignParamFields';
import { OptimizationAlgorithmsFields } from './OptimizerAlgoList';
import {
  CSVFileProps,
  OptimizationContentProps,
  OptimizerInputGroup,
  OptimizerInputLabel,
  filterConstraints,
  filterDesignParameters,
  filterOptions,
} from './optimizerModalUtils';

const ParamEstimationContent = ({
  request,
  nodeOptions,
  onUpdate,
  onUpdateValidRequest,
}: OptimizationContentProps) => {
  // A note about UX here:
  // This is quite awkward, because we select a submodel and actually we
  // ignore the rest of the model during the optimization loop. Probably needs
  // a new design where "parameter estimation" can only be done from inside the
  // submodel edit view. But this could cause issues with goapi where we need
  // a model to run simulation/optimization jobs. Also we could consider running
  // a parameter optimization over a single block without having to wrap it
  // inside a submodel. Or maybe a group (without a submodel).

  const { projectId } = useAppParams();
  const { project } = useProject({ projectId });
  const rootModel = useAppSelector((state) => state.model.present.rootModel);
  const modelSubdiagrams = useAppSelector(
    (state) => state.model.present.submodels,
  );

  const options = React.useMemo(() => request.options || {}, [request]);
  const algorithm = request.algorithm;
  const selectedSubmodel = React.useMemo(
    () =>
      request.submodel_path ??
      (nodeOptions.length === 1 ? nodeOptions[0].value : undefined),
    [request, nodeOptions],
  );
  const designParameters = React.useMemo(
    () => request.design_parameters || [{}],
    [request],
  );
  const constraintPortNames = React.useMemo(
    () => request.constraint_port_names || [undefined],
    [request],
  );
  const filteredConstraintPortNames = React.useMemo(
    () => filterConstraints(constraintPortNames),
    [constraintPortNames],
  );

  const dataFile = request.data_file;
  const timeColumn = React.useMemo(
    () => request.time_column || 'time',
    [request],
  );
  const inputSignals = React.useMemo(
    () => request.input_columns || {},
    [request],
  );
  const outputSignals = React.useMemo(
    () => request.output_columns || {},
    [request],
  );

  const setDataFile = React.useCallback(
    (newVal: string) => {
      if (newVal === dataFile) return;
      onUpdate({ ...request, data_file: newVal });
    },
    [dataFile, request, onUpdate],
  );

  const setInputSignals = React.useCallback(
    (newVal: Record<string, string>) => {
      if (newVal === inputSignals) return;
      onUpdate({ ...request, input_columns: newVal });
    },
    [inputSignals, request, onUpdate],
  );

  const setOutputSignals = React.useCallback(
    (newVal: Record<string, string>) => {
      if (newVal === outputSignals) return;
      onUpdate({ ...request, output_columns: newVal });
    },
    [outputSignals, request, onUpdate],
  );

  const setConstraintPortNames = (newVal: Array<string | undefined>) => {
    if (newVal === constraintPortNames) return;
    onUpdate({ ...request, constraint_port_names: newVal });
  };

  const setDesignParameters = (newVal: Array<Record<string, string>>) => {
    if (newVal === designParameters) return;
    onUpdate({ ...request, design_parameters: newVal });
  };

  const setAlgorithm = (newVal: string) => {
    if (newVal === algorithm) return;
    onUpdate({ ...request, algorithm: newVal as OptimizationAlgoName });
  };

  const setOptions = (newVal: OptimizationOptions) => {
    if (newVal === options) return;
    onUpdate({ ...request, options: newVal });
  };

  const setSelectedSubmodel = (newVal: string) => {
    if (newVal === selectedSubmodel) return;
    onUpdate({
      ...request,
      submodel_path: newVal,
    });
  };

  const setTimeColumn = (newVal: string) => {
    if (newVal === timeColumn) return;
    onUpdate({ ...request, time_column: newVal });
  };

  const selectedNode = React.useMemo(
    () =>
      selectedSubmodel
        ? findNodeInDiagramByNamePath(
            rootModel.nodes,
            modelSubdiagrams,
            selectedSubmodel.split('.'),
          )
        : null,
    [selectedSubmodel, rootModel, modelSubdiagrams],
  );
  const submodel = selectedNode as SubmodelInstance;

  const idToVersionIdToSubmodelInfo = useAppSelector(
    (state) => state.submodels.idToVersionIdToSubmodelInfo,
  );

  const submodelParameters: ModelParameters = React.useMemo(() => {
    if (!submodel || !submodel.submodel_reference_uuid) return [];
    const referenceSubmodel = getSpecificReferenceSubmodelById(
      submodel.submodel_reference_uuid,
      submodel.submodel_reference_version ?? VersionTagValues.LATEST_VERSION,
      idToVersionIdToSubmodelInfo,
    );
    if (!referenceSubmodel) return [];
    return (
      referenceSubmodel.parameterDefinitions.map((p) => ({
        name: p.name,
        value: p.default_value,
      })) || []
    );
  }, [idToVersionIdToSubmodelInfo, submodel]);

  const selectedDataFileUuid = project?.files?.find(
    (file) => file.name === dataFile,
  )?.uuid;

  const selectedDataFileProps = useGetFileReadByUuidQuery(
    {
      fileUuid: selectedDataFileUuid || '',
      projectUuid: projectId || '',
    },
    { skip: !dataFile || !projectId },
  )?.data?.properties as CSVFileProps | undefined;

  const columnOptions: Array<SelectInputOption> =
    selectedDataFileProps?.header?.map((header) => ({
      label: header,
      value: header,
    })) || [];

  const outputPortOptions = React.useMemo(
    () =>
      selectedNode?.outputs.map((port) => ({
        label: port.name,
        value: port.name,
      })) || [],
    [selectedNode],
  );

  React.useEffect(() => {
    if (dataFile) return;
    if (!project?.files) return;
    const firstCsvFile = project.files.find((file) =>
      file.name.endsWith('.csv'),
    );
    if (firstCsvFile) setDataFile(firstCsvFile.name);
  }, [setDataFile, dataFile, project]);

  const hasConstraintsButIsNotSupported = React.useMemo(
    () =>
      filteredConstraintPortNames.length > 0 &&
      !OptimizationAlgorithmsFields[algorithm].supportsConstraints,
    [filteredConstraintPortNames.length, algorithm],
  );

  const filteredParams = React.useMemo(
    () =>
      filterDesignParameters(
        designParameters,
        submodelParameters.map((p) => p.name),
      ),
    [designParameters, submodelParameters],
  );

  const filteredInputSignals = React.useMemo(
    () =>
      Object.keys(inputSignals).reduce(
        (acc, key) =>
          selectedNode?.inputs.find((port) => port.name === key)
            ? { ...acc, [key]: inputSignals[key] }
            : acc,
        {},
      ),
    [inputSignals, selectedNode?.inputs],
  );

  const filteredOutputSignals = React.useMemo(
    () =>
      Object.keys(outputSignals).reduce(
        (acc, key) =>
          selectedNode?.outputs.find((port) => port.name === key)
            ? { ...acc, [key]: outputSignals[key] }
            : acc,
        {},
      ),
    [outputSignals, selectedNode?.outputs],
  );

  const invalidReason = React.useMemo<string | undefined>(() => {
    if (!selectedSubmodel) return 'No submodel selected';
    if (!dataFile) return 'No data file selected';
    if (!timeColumn) return 'No time column selected';
    if (
      selectedNode?.inputs.length !== Object.keys(filteredInputSignals).length
    )
      return 'No input signals selected';
    if (
      selectedNode?.outputs.length !== Object.keys(filteredOutputSignals).length
    )
      return 'No output signals selected';
    if (filteredParams.length === 0) return 'No design parameter selected';
    if (hasConstraintsButIsNotSupported)
      return 'Constraints are not supported for this algorithm';
    return undefined;
  }, [
    selectedSubmodel,
    dataFile,
    timeColumn,
    selectedNode?.inputs.length,
    selectedNode?.outputs.length,
    filteredInputSignals,
    filteredOutputSignals,
    filteredParams.length,
    hasConstraintsButIsNotSupported,
  ]);

  const validRequest = React.useMemo<ParameterEstimationRequest | null>(() => {
    const isValid = !!(
      dataFile &&
      selectedSubmodel &&
      timeColumn &&
      selectedNode?.inputs.length ===
        Object.keys(filteredInputSignals).length &&
      selectedNode?.outputs.length ===
        Object.keys(filteredOutputSignals).length &&
      filteredParams.length > 0 &&
      !hasConstraintsButIsNotSupported
    );
    if (!isValid) return null;
    return {
      type: 'estimation',
      algorithm,
      data_file: dataFile,
      submodel_path: selectedSubmodel,
      time_column: timeColumn,
      input_columns: filteredInputSignals,
      output_columns: filteredOutputSignals,
      constraint_port_names: filteredConstraintPortNames,
      options: filterOptions(algorithm, options),
      design_parameters: filteredParams,
    };
  }, [
    dataFile,
    selectedSubmodel,
    timeColumn,
    selectedNode?.inputs.length,
    selectedNode?.outputs.length,
    filteredInputSignals,
    filteredOutputSignals,
    filteredParams,
    hasConstraintsButIsNotSupported,
    algorithm,
    filteredConstraintPortNames,
    options,
  ]);

  React.useEffect(
    () => onUpdateValidRequest(validRequest, invalidReason),
    [onUpdateValidRequest, validRequest, invalidReason],
  );

  return (
    <>
      <SectionHeading noBorder>Submodel</SectionHeading>
      <OptimizerInputGroup>
        <SelectInput
          placeholder="Select target submodel"
          options={nodeOptions}
          onSelectValue={setSelectedSubmodel}
          currentValue={selectedSubmodel}
        />
      </OptimizerInputGroup>
      <SectionHeading noBorder>Data file</SectionHeading>
      <OptimizerInputGroup>
        <DataFileParameter
          datafileType="ColumnarDataFile"
          currentValue={dataFile || ''}
          allowEmpty={false}
          onSelectValue={setDataFile}
        />
      </OptimizerInputGroup>
      <OptimizerInputGroup>
        <OptimizerInputLabel>Time column</OptimizerInputLabel>
        <EditableSelectInput
          currentValue={timeColumn}
          onSelectValue={setTimeColumn}
          options={columnOptions}
          placeholder="time"
        />
      </OptimizerInputGroup>
      <SectionHeading noBorder>Input signals</SectionHeading>
      {selectedNode?.inputs.map((port) => (
        <OptimizerInputGroup key={`input_${port.name}`}>
          <OptimizerInputLabel key={port.name}>{port.name}</OptimizerInputLabel>
          <EditableSelectInput
            currentValue={inputSignals[port.name]}
            onSelectValue={(newVal) =>
              setInputSignals({ ...inputSignals, [port.name]: newVal })
            }
            options={columnOptions}
            placeholder={port.name}
          />
        </OptimizerInputGroup>
      ))}
      <SectionHeading noBorder>Output signals</SectionHeading>
      {selectedNode?.outputs.map((port) => (
        <OptimizerInputGroup key={`output_${port.name}`}>
          <OptimizerInputLabel key={port.name}>{port.name}</OptimizerInputLabel>
          <EditableSelectInput
            currentValue={outputSignals[port.name]}
            onSelectValue={(newVal) =>
              setOutputSignals({ ...outputSignals, [port.name]: newVal })
            }
            options={columnOptions}
            placeholder={port.name}
          />
        </OptimizerInputGroup>
      ))}
      <ConstrainsSelector
        signalOptions={outputPortOptions}
        constraints={constraintPortNames}
        onUpdate={setConstraintPortNames}
      />
      <DesignParamFields
        designParams={designParameters}
        onUpdate={setDesignParameters}
        modelParameters={submodelParameters}
      />
      <AlgorithmFields
        algorithm={algorithm}
        fieldValues={options}
        setFields={setOptions}
        setAlgo={setAlgorithm}
        hasConstraints={filteredConstraintPortNames.length > 0}
      />
    </>
  );
};

export default ParamEstimationContent;
