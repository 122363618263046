import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ModelKind,
  SubmodelConfiguration,
  SubmodelFetchItem,
  SubmodelFull,
} from 'app/apiGenerated/generatedApiTypes';
import {
  convertAPISubmodelFullToSubmodelFullUI,
  SubmodelFullUI,
} from 'app/apiTransformers/convertGetSubmodel';
import { SubmodelInfoLiteUI } from 'app/apiTransformers/convertGetSubmodelsList';
import { SubmodelInfoUI } from 'app/apiTransformers/convertGetSubmodelsListForModelParent';
import {
  VersionedSubmodelsResponse,
  VersionTagValues,
} from 'app/apiTransformers/convertPostSubmodelsFetch';
import {
  getSpecificReferenceSubmodelById,
  getSubmodelsToFetchFromDiagrams,
} from 'app/utils/submodelUtils';

function addSubmodelToCache<TSubmodelUI extends SubmodelInfoLiteUI>(
  idToVersionIdToSubmodel: Record<string, Record<string, SubmodelInfoLiteUI>>,
  submodel: TSubmodelUI,
  versionId: string,
) {
  if (!idToVersionIdToSubmodel || !submodel || !versionId) {
    return;
  }

  const versionIdToSubmodel = idToVersionIdToSubmodel[submodel.id];
  if (versionIdToSubmodel) {
    versionIdToSubmodel[versionId] = submodel;
  } else {
    idToVersionIdToSubmodel[submodel.id] = {
      [versionId]: submodel,
    };
  }
}

function removeLatestSubmodelFromCache(
  idToVersionIdToSubmodel: Record<string, Record<string, SubmodelInfoLiteUI>>,
  submodelId: string,
) {
  const versionIdToSubmodel = idToVersionIdToSubmodel[submodelId];
  if (versionIdToSubmodel) {
    delete versionIdToSubmodel[VersionTagValues.LATEST_VERSION];
  }
}

function requestLoadSubmodels(
  idToVersionIdToSubmodelInfo: Record<string, Record<string, SubmodelInfoUI>>,
  idToLatestTaggedVersionId: Record<string, string>,
  allSubmodelsToFetch: SubmodelFetchRequest[],
  submodelsToRequest: SubmodelFetchItem[],
) {
  submodelsToRequest.forEach((submodelToRequest) => {
    const isAlreadyFetched = !!getSpecificReferenceSubmodelById(
      submodelToRequest.submodel_uuid,
      submodelToRequest.version,
      idToVersionIdToSubmodelInfo,
      idToLatestTaggedVersionId,
    );
    if (isAlreadyFetched) {
      return;
    }

    const isAlreadyFetching = allSubmodelsToFetch.find(
      (submodelToFetch) =>
        submodelToFetch.submodelId === submodelToRequest.submodel_uuid &&
        submodelToFetch.versionId === submodelToRequest.version,
    );
    if (isAlreadyFetching) {
      return;
    }

    allSubmodelsToFetch.push({
      submodelId: submodelToRequest.submodel_uuid,
      versionId: submodelToRequest.version,
    });
  });
}

function updateSubmodelItemFetchStatus(
  allSubmodelsToFetch: SubmodelFetchRequest[],
  submodelsToUpdateStatus: SubmodelFetchItem[],
  isFetching: boolean,
) {
  const idToVersionIdToIsFetching: Record<string, Record<string, boolean>> = {};

  submodelsToUpdateStatus.forEach((submodelItem: SubmodelFetchItem) => {
    if (!idToVersionIdToIsFetching[submodelItem.submodel_uuid]) {
      idToVersionIdToIsFetching[submodelItem.submodel_uuid] = {};
    }
    idToVersionIdToIsFetching[submodelItem.submodel_uuid][
      submodelItem.version
    ] = isFetching;
  });

  allSubmodelsToFetch.forEach((submodelToFetch) => {
    const versionIdToIsFetching =
      idToVersionIdToIsFetching[submodelToFetch.submodelId];

    // See if a submodel with this submodel id was fetched.
    if (versionIdToIsFetching) {
      // See if a submodel with this submodel id and version id was fetched.
      const isSubmodelVersionFetching =
        versionIdToIsFetching[submodelToFetch.versionId];

      if (isSubmodelVersionFetching) {
        submodelToFetch.isFetching = isFetching;
      }
    }
  });
}

export interface GroupBlockToConvert {
  id: string;
  name: string;
}

export interface SubmodelFetchRequest {
  submodelId: string;
  versionId: string;
  isFetching?: boolean;
  notFoundReason?: string;
}

interface SubmodelToPublish {
  projectId: string;
  name: string;
  submodelInstanceParentPath: string[];
  submodelInstanceId: string;
  requestSent?: boolean;
  submodelFull?: SubmodelFullUI;
  submodelConfiguration?: SubmodelConfiguration;
}

interface SubmodelsState {
  projectIdsToRefetchSubmodels: Record<string, boolean>;
  submodelToPublish?: SubmodelToPublish;
  submodelsToFetch: SubmodelFetchRequest[];
  idToVersionIdToSubmodelFull: Record<string, Record<string, SubmodelFullUI>>;
  idToVersionIdToSubmodelInfo: Record<string, Record<string, SubmodelInfoUI>>;
  idToVersionIdToSubmodelInfoLite: Record<
    string,
    Record<string, SubmodelInfoLiteUI>
  >;
  idToLatestTaggedVersionId: Record<string, string>;
  idToVersionIdToNotFoundReason: Record<string, Record<string, string>>;
  projectIdToSubmodelInfoLites: Record<string, SubmodelInfoLiteUI[]>;
  groupBlockToConvertToSubmodel?: GroupBlockToConvert;
  topLevelModelId: string;
  topLevelModelType: ModelKind | null;
}

const initialState: SubmodelsState = {
  projectIdsToRefetchSubmodels: {},
  submodelsToFetch: [],
  idToVersionIdToSubmodelFull: {},
  idToVersionIdToSubmodelInfo: {},
  idToVersionIdToSubmodelInfoLite: {},
  idToLatestTaggedVersionId: {},
  idToVersionIdToNotFoundReason: {},
  projectIdToSubmodelInfoLites: {},
  topLevelModelId: '',
  topLevelModelType: null,
};

const submodelsSlice = createSlice({
  name: 'SubmodelsSlice',
  initialState,
  reducers: {
    // FIXME move these properties to modelMetadataSlice
    setModelTypeForRequestedModel(
      state,
      action: PayloadAction<{
        topLevelModelId: string;
        topLevelModelType: ModelKind | null;
      }>,
    ) {
      const { topLevelModelId, topLevelModelType } = action.payload;
      if (
        state.topLevelModelId !== topLevelModelId ||
        state.topLevelModelType !== topLevelModelType
      ) {
        state.topLevelModelId = topLevelModelId;
        state.topLevelModelType = topLevelModelType;
      }
    },

    requestSubmodels(state, action: PayloadAction<string>) {
      const projectId = action.payload;
      if (projectId) {
        state.projectIdsToRefetchSubmodels[projectId] = true;
      }
    },

    clearRequestSubmodels(state, action: PayloadAction<string>) {
      const projectId = action.payload;
      if (projectId) {
        delete state.projectIdsToRefetchSubmodels[projectId];
      }
    },

    setSubmodels(state, action: PayloadAction<VersionedSubmodelsResponse>) {
      const idToVersionIdToIsFetched: Record<
        string,
        Record<string, boolean>
      > = {};

      const idToVersionIdToNotFoundReason: Record<
        string,
        Record<string, string>
      > = {};

      const { submodelItems, notFoundItems } = action.payload;
      submodelItems.forEach((submodelItem) => {
        addSubmodelToCache(
          state.idToVersionIdToSubmodelInfo,
          submodelItem.submodel,
          submodelItem.versionId,
        );
        addSubmodelToCache(
          state.idToVersionIdToSubmodelInfoLite,
          submodelItem.submodel,
          submodelItem.versionId,
        );
        if (!idToVersionIdToIsFetched[submodelItem.submodelId]) {
          idToVersionIdToIsFetched[submodelItem.submodelId] = {};
        }

        if (submodelItem.isLatestTaggedVersion) {
          state.idToLatestTaggedVersionId[submodelItem.submodelId] =
            submodelItem.versionId;
          // Latest tagged version request doesn't know which version ID it's requesting.
          idToVersionIdToIsFetched[submodelItem.submodelId][
            VersionTagValues.LATEST_TAGGED_VERSION
          ] = true;
        } else {
          idToVersionIdToIsFetched[submodelItem.submodelId][
            submodelItem.versionId
          ] = true;
        }
      });
      notFoundItems?.forEach((notFoundItem) => {
        if (!idToVersionIdToNotFoundReason[notFoundItem.submodel_uuid]) {
          idToVersionIdToNotFoundReason[notFoundItem.submodel_uuid] = {};
        }
        idToVersionIdToNotFoundReason[notFoundItem.submodel_uuid][
          notFoundItem.version
        ] = notFoundItem.reason;
      });

      state.submodelsToFetch = state.submodelsToFetch.filter(
        (submodelToFetch) => {
          const versionIdToIsFetched =
            idToVersionIdToIsFetched[submodelToFetch.submodelId];

          // See if a submodel with this submodel id was fetched.
          if (versionIdToIsFetched) {
            // See if a submodel with this submodel id and version id was fetched.
            const isSubmodelVersionFetched =
              versionIdToIsFetched[submodelToFetch.versionId];

            // Keep the item to fetch in the list if only if we didn't load the requested version
            // of that submodel.
            return !isSubmodelVersionFetched;
          }

          // Keep the item to fetch in the list since it was either not requested or not successfully fetched
          // as part of this bulk submodel load.
          return true;
        },
      );

      state.submodelsToFetch.forEach((submodelToFetch) => {
        const versionIdToNotFoundReason =
          idToVersionIdToNotFoundReason[submodelToFetch.submodelId];

        // See if a submodel with this submodel id was not found.
        if (versionIdToNotFoundReason) {
          // See if a submodel with this submodel id and version id was not found.
          const notFoundReason =
            versionIdToNotFoundReason[submodelToFetch.versionId];
          if (notFoundReason) {
            submodelToFetch.notFoundReason = notFoundReason;
          }

          if (state.idToVersionIdToNotFoundReason[submodelToFetch.submodelId]) {
            state.idToVersionIdToNotFoundReason[submodelToFetch.submodelId][
              submodelToFetch.versionId
            ] = notFoundReason;
          } else {
            state.idToVersionIdToNotFoundReason[submodelToFetch.submodelId] = {
              [submodelToFetch.versionId]: notFoundReason,
            };
          }
        }
      });
    },

    onStartFetchingSubmodels(
      state,
      action: PayloadAction<SubmodelFetchItem[]>,
    ) {
      const submodelsToFetch: SubmodelFetchItem[] = action.payload;
      updateSubmodelItemFetchStatus(
        state.submodelsToFetch,
        submodelsToFetch,
        true,
      );
    },

    onFetchingSubmodelsFailed(
      state,
      action: PayloadAction<SubmodelFetchItem[]>,
    ) {
      const submodelsToFetch: SubmodelFetchItem[] = action.payload;
      updateSubmodelItemFetchStatus(
        state.submodelsToFetch,
        submodelsToFetch,
        false,
      );
    },

    setSubmodel(
      state,
      action: PayloadAction<{
        submodel: SubmodelFullUI;
        loadReferencedSubmodelInfos: boolean;
        versionId: string;
      }>,
    ) {
      const { submodel, loadReferencedSubmodelInfos, versionId } =
        action.payload;
      addSubmodelToCache(
        state.idToVersionIdToSubmodelFull,
        submodel,
        versionId,
      );
      addSubmodelToCache(
        state.idToVersionIdToSubmodelInfo,
        submodel,
        versionId,
      );
      addSubmodelToCache(
        state.idToVersionIdToSubmodelInfoLite,
        submodel,
        versionId,
      );

      if (loadReferencedSubmodelInfos) {
        const referencedSubmodels = getSubmodelsToFetchFromDiagrams(
          submodel.diagram,
          submodel.submodels,
        );
        requestLoadSubmodels(
          state.idToVersionIdToSubmodelInfo,
          state.idToLatestTaggedVersionId,
          state.submodelsToFetch,
          referencedSubmodels,
        );
      }
    },

    setSubmodelInfos(state, action: PayloadAction<SubmodelInfoUI[]>) {
      const submodels = action.payload;
      submodels.forEach((submodel) => {
        addSubmodelToCache(
          state.idToVersionIdToSubmodelInfo,
          submodel,
          VersionTagValues.LATEST_VERSION,
        );
        addSubmodelToCache(
          state.idToVersionIdToSubmodelInfoLite,
          submodel,
          VersionTagValues.LATEST_VERSION,
        );

        const idIndex = state.submodelsToFetch.findIndex(
          (submodelToFetch) =>
            submodelToFetch.submodelId === submodel.id &&
            submodelToFetch.versionId === VersionTagValues.LATEST_VERSION,
        );
        if (idIndex > -1) {
          state.submodelsToFetch.splice(idIndex, 1);
        }
      });
    },

    setSubmodelInfoLites(
      state,
      action: PayloadAction<{
        projectId: string;
        submodels: SubmodelInfoLiteUI[];
      }>,
    ) {
      const { projectId, submodels } = action.payload;
      submodels.forEach((submodel) => {
        addSubmodelToCache(
          state.idToVersionIdToSubmodelInfoLite,
          submodel,
          VersionTagValues.LATEST_VERSION,
        );
      });

      state.projectIdToSubmodelInfoLites[projectId] = submodels;
    },

    clearCacheForSubmodel(state, action: PayloadAction<string>) {
      const submodelReferenceId = action.payload;
      if (submodelReferenceId) {
        removeLatestSubmodelFromCache(
          state.idToVersionIdToSubmodelFull,
          submodelReferenceId,
        );
        removeLatestSubmodelFromCache(
          state.idToVersionIdToSubmodelInfo,
          submodelReferenceId,
        );
        removeLatestSubmodelFromCache(
          state.idToVersionIdToSubmodelInfoLite,
          submodelReferenceId,
        );
      }
    },

    requestLoadSubmodelInfos(
      state,
      action: PayloadAction<SubmodelFetchItem[]>,
    ) {
      const submodelsToRequest = action.payload;

      requestLoadSubmodels(
        state.idToVersionIdToSubmodelInfo,
        state.idToLatestTaggedVersionId,
        state.submodelsToFetch,
        submodelsToRequest,
      );
    },

    setGroupToConvertToSubmodel(
      state,
      action: PayloadAction<GroupBlockToConvert>,
    ) {
      state.groupBlockToConvertToSubmodel = action.payload;
    },

    clearGroupToConvertToSubmodel(state) {
      state.groupBlockToConvertToSubmodel = undefined;
    },

    setSubmodelToPublish(state, action: PayloadAction<SubmodelToPublish>) {
      state.submodelToPublish = action.payload;
    },

    submodelPublishStarted(state) {
      if (state.submodelToPublish) {
        state.submodelToPublish.requestSent = true;
      }
    },

    submodelPublishCompleted(state, action: PayloadAction<SubmodelFull>) {
      const submodelFull = action.payload;
      const submodelFullUI =
        convertAPISubmodelFullToSubmodelFullUI(submodelFull);

      if (state.submodelToPublish) {
        state.submodelToPublish.submodelFull = submodelFullUI;
      }

      addSubmodelToCache(
        state.idToVersionIdToSubmodelFull,
        submodelFullUI,
        VersionTagValues.LATEST_VERSION,
      );
      addSubmodelToCache(
        state.idToVersionIdToSubmodelInfo,
        submodelFullUI,
        VersionTagValues.LATEST_VERSION,
      );
      addSubmodelToCache(
        state.idToVersionIdToSubmodelInfoLite,
        submodelFullUI,
        VersionTagValues.LATEST_VERSION,
      );
    },

    clearSubmodelToPublish(state) {
      state.submodelToPublish = undefined;
    },
  },
});

export const submodelsActions = submodelsSlice.actions;

export default submodelsSlice;
