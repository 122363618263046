import { PortSide } from 'app/common_types/PortTypes';

export interface PortIdentifier {
  side: PortSide;
  blockUuid: string;
  portId: number;
}

export enum FakeSegmentType {
  SStart,
  SMiddle,
  SEnd,
  Start,
  End,
}
