import { useFileProcessingStatus } from 'app/api/useFileProcessingStatus';
import React from 'react';

interface Props {
  projectId: string;
  fileIdToWatch: string;
}

const InProgressFileWatcher: React.FC<Props> = ({
  projectId,
  fileIdToWatch,
}) => {
  useFileProcessingStatus(projectId, fileIdToWatch);

  return null;
};

export default InProgressFileWatcher;
