import { AnyAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import Ajv, { Schema } from 'ajv';
import draft6MetaSchema from 'ajv/dist/refs/json-schema-draft-06.json';
import { LogLine } from 'app/apiGenerated/generatedApiTypes';
import { PortSide } from 'app/common_types/PortTypes';
import { ErrorLog as WildcatErrorObject } from 'app/generated_types/collimator/dashboard/serialization/ui_types.gen';
import errorLogSchema from 'app/generated_types/schemas/error_log.schema.json';
import { modelActions } from './modelSlice';

export type ErrorTreeNode = {
  errorKind?: string;
  inputPorts?: number[];
  outputPorts?: number[];
  children: {
    [blockUuid: string]: ErrorTreeNode | undefined;
  };
};

export interface ErrorsState {
  rootNode: ErrorTreeNode; // A fake node for a clean tree structure
}

export const ajvInstance: Ajv = new Ajv({ meta: draft6MetaSchema });
const ajvValidate = ajvInstance.compile(errorLogSchema as Schema);

const isValidErrorLog = (error: WildcatErrorObject): boolean => {
  if (!error) return false;
  const valid = ajvValidate(error);
  if (!valid) console.error('Error log validation failed:', ajvValidate.errors);
  return valid;
};

export function parseAllWildcatErrorsFromLogs(
  logs: LogLine[],
): WildcatErrorObject[] {
  const errors: WildcatErrorObject[] = [];
  for (let k = 0; k < logs.length; k++) {
    const wildcatError: WildcatErrorObject = logs[k].__error__;
    if (!isValidErrorLog(wildcatError)) continue;
    errors.push(wildcatError);
  }
  return errors;
}

const initialState: ErrorsState = { rootNode: { children: {} } };

const assembleErrorTreeAndGetFinalNode = (
  blockUuidPath: string[],
  rootNodeMut: ErrorTreeNode,
) => {
  // BEWARE! This function mutates the rootNode

  let currentNode: ErrorTreeNode = rootNodeMut;
  for (let nodeID of blockUuidPath) {
    const nextNode = currentNode.children[nodeID] || { children: {} };
    currentNode.children[nodeID] = nextNode;
    currentNode = nextNode;
  }

  return currentNode;
};

const removeErrorNodeFromTree = (
  blockUuidPath: string[],
  rootNodeMut: ErrorTreeNode,
) => {
  // BEWARE! This function mutates the rootNode

  for (let pathLen = blockUuidPath.length; pathLen > 0; pathLen--) {
    for (let currentNode = rootNodeMut, i = 0; i < pathLen; i++) {
      const nextNode = currentNode.children[blockUuidPath[i]];
      if (!nextNode) break;

      if (i === blockUuidPath.length - 1) {
        delete currentNode.children[blockUuidPath[i]];
      } else if (i === pathLen - 1) {
        // Clean up parent group error if no children
        if (Object.keys(nextNode.children).length === 0) {
          delete currentNode.children[blockUuidPath[i]];
        }
      }

      currentNode = nextNode;
    }
  }
};

const removeErrorPortFromTree = (
  blockUuidPath: string[],
  rootNodeMut: ErrorTreeNode,
  portSide: PortSide,
  portIndex: number,
) => {
  // BEWARE! This function mutates the rootNode

  for (let pathLen = blockUuidPath.length; pathLen > 0; pathLen--) {
    for (let currentNode = rootNodeMut, i = 0; i < pathLen; i++) {
      const nextNode = currentNode.children[blockUuidPath[i]];
      if (!nextNode) break;

      if (i === blockUuidPath.length - 1) {
        if (portSide === PortSide.Input) {
          nextNode.inputPorts = nextNode.inputPorts?.filter(
            (port) => port !== portIndex,
          );
          if (nextNode.inputPorts?.length === 0) {
            delete currentNode.children[blockUuidPath[i]];
          }
        } else {
          nextNode.outputPorts = nextNode.outputPorts?.filter(
            (port) => port !== portIndex,
          );
          if (nextNode.outputPorts?.length === 0) {
            delete currentNode.children[blockUuidPath[i]];
          }
        }
      } else if (i === pathLen - 1) {
        // Clean up parent group error if no children
        if (Object.keys(nextNode.children).length === 0) {
          delete currentNode.children[blockUuidPath[i]];
        }
      }

      currentNode = nextNode;
    }
  }
};

const errorsSlice = createSlice({
  name: 'errorsSlice',
  initialState,
  reducers: {
    setWildcatErrors(state, action: PayloadAction<WildcatErrorObject[]>) {
      const errors = action.payload;

      const allErrors: Array<WildcatErrorObject> = [];

      // flatten loop items as error objects
      for (let i = 0; i < errors.length; i++) {
        const error = errors[i];
        if (error.loop) {
          const loopItemsAsErrors: WildcatErrorObject[] = error.loop.map(
            (loopItem) => ({
              // Note: ts_type doesn't generate proper defs for optional fields
              parameter_name: null,
              port_name: null,
              loop: null,
              ...loopItem,
              kind: error.kind,
            }),
          );
          allErrors.push(...loopItemsAsErrors);
        } else {
          allErrors.push(error);
        }
      }

      const newRootNode: ErrorTreeNode = { children: {} };
      for (let i = 0; i < allErrors.length; i++) {
        const error = allErrors[i];
        const uuidPath = error.uuid_path;
        if (!uuidPath) continue;

        const node = assembleErrorTreeAndGetFinalNode(uuidPath, newRootNode);
        if (!node) continue;

        node.errorKind = error.kind;
        if (
          error.port_direction?.toLowerCase() === 'in' &&
          error.port_index !== undefined &&
          error.port_index !== null
        ) {
          node.inputPorts = [...(node.inputPorts || []), error.port_index];
        } else if (
          error.port_direction?.toLowerCase() === 'out' &&
          error.port_index !== undefined &&
          error.port_index !== null
        ) {
          node.outputPorts = [...(node.outputPorts || []), error.port_index];
        }
      }

      state.rootNode = newRootNode;
    },
  },

  extraReducers: (builder) => {
    // Generic error cleanup
    const modelActionMatcher = (action: AnyAction): boolean => {
      const allMatchers = [
        modelActions.loadModelContent,
        modelActions.loadSubmodelContent,

        modelActions.addPremadeEntitiesToModel,
        modelActions.removeEntitiesFromModel,

        modelActions.createSubdiagramFromSelection,
        modelActions.confirmReferenceSubmodelCreatedFromSelection,

        modelActions.disconnectNodeFromAllLinks,
        modelActions.insertNodeIntoLink,
        modelActions.disconnectLinkFromSourceOrDest,
        modelActions.connectTwoLinks,
        modelActions.insertNodeIntoLink,
        modelActions.disconnectLinkFromSourceOrDest,
      ];

      return allMatchers.find((matcher) => matcher.match(action)) !== undefined;
    };

    builder.addMatcher(modelActionMatcher, (state, action) => {
      state.rootNode = { children: {} };
      return state;
    });

    // Fine-grained error cleanup
    // 1. Ports
    builder.addMatcher(
      modelActions.connectNodesPorts.match,
      (state, action) => {
        const { parentPath, destNodeUuid, destNodeInputPortIDs } =
          action.payload;
        const blockUuidPath = [...parentPath, destNodeUuid];
        for (const index of destNodeInputPortIDs) {
          removeErrorPortFromTree(
            blockUuidPath,
            state.rootNode,
            PortSide.Input,
            index,
          );
        }
        return state;
      },
    );

    builder.addMatcher(
      modelActions.connectLinkToNode.match,
      (state, action) => {
        const { parentPath, linkPayload } = action.payload;
        if (!linkPayload.destination) return state;
        const blockUuidPath = [...parentPath, linkPayload.destination.node];
        removeErrorPortFromTree(
          blockUuidPath,
          state.rootNode,
          PortSide.Input,
          linkPayload.destination.port,
        );
        return state;
      },
    );

    builder.addMatcher(modelActions.removePort.match, (state, action) => {
      const { parentPath, nodeUuid, portSide } = action.payload;
      if (portSide !== PortSide.Input) return state;
      const blockUuidPath = [...parentPath, nodeUuid];
      // Note: because port indices are reassigned when removing ports other
      // than the last, we can't easily just call removeErrorPortFromTree.
      removeErrorNodeFromTree(blockUuidPath, state.rootNode);
      return state;
    });
  },
});

export const errorsActions = errorsSlice.actions;

export default errorsSlice;
