/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const ChevronUp: React.FC<Props> = (props) => (
  <svg viewBox="0 0 24 24" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M16.5303 13.5303C16.2374 13.8232 15.7626 13.8232 15.4697 13.5303L12 10.0607L8.53033 13.5303C8.23744 13.8232 7.76256 13.8232 7.46967 13.5303C7.17678 13.2374 7.17678 12.7626 7.46967 12.4697L11.2929 8.64644C11.6834 8.25592 12.3166 8.25591 12.7071 8.64644L16.5303 12.4697C16.8232 12.7626 16.8232 13.2374 16.5303 13.5303Z"
      fill={props.fill}
    />
  </svg>
);

ChevronUp.defaultProps = {
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default ChevronUp;
