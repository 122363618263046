import { t } from '@lingui/macro';
import { Monaco } from '@monaco-editor/react';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { generatedApi } from 'app/apiGenerated/generatedApi';
import { AutocompleteSuggestion } from 'app/apiGenerated/generatedApiTypes';
import { notificationsActions } from 'app/slices/notificationsSlice';
import { AppDispatch } from 'app/store';
import { editor, languages, Position } from 'monaco-editor';
import * as monaco from 'monaco-editor/esm/vs/editor/editor.api';
import { MutableRefObject } from 'react';
import { Exclamation, Information } from 'ui/common/Icons/Standard';
import { CodeLanguage } from './CodeEditor';

const TypeDelay = 0; // wait for TypeDelay ms after end of typing before sending the request
const ContextLength = 100; // number of lines before the current line to include in the prompt
const TriggerCharacters = new Set<string>([
  '[',
  '(',
  '{',
  '\n',
  ',',
  '.',
  '"',
  "'",
  ':',
  '-',
  '_',
  '=',
  ' ',
]);

const AutocompleteShortcut = monaco.KeyMod.CtrlCmd | monaco.KeyCode.Enter;

const NoSuggestionFoundMessage = t({
  message: 'No suggestions found.',
  id: 'pythonEditor.autocompleteNoSuggestionsFound',
});

const TryAnotherPromptMessage = t({
  message: 'Autocomplete failed: please try another prompt.',
  id: 'pythonEditor.autocompleteTryAnotherPrompt',
});

const TooManyRequestsMessage = t({
  message: 'Autocomplete failed: too many requests, please try again later.',
  id: 'pythonEditor.autocompleteTooManyRequests',
});

const RequestingSuggestionsMessage = t({
  message: 'Requesting suggestions...',
  id: 'pythonEditor.requestingSuggestions',
});

const getLastChar = (model: editor.ITextModel, position: Position) => {
  const lastChar = model.getValueInRange({
    startLineNumber: position.lineNumber - 1,
    endLineNumber: position.lineNumber,
    startColumn: 1,
    endColumn: position.column,
  });
  return lastChar.slice(-1);
};

const isPythonDocString = (line: string): boolean => {
  const trimmedLine = line.trim();
  return trimmedLine.startsWith("'''") || trimmedLine.startsWith('"""');
};

const skipWhitespaces = (model: editor.ITextModel, lineNum: number): number => {
  while (model.getLineContent(lineNum).trim().length == 0) {
    lineNum -= 1;
  }
  return lineNum;
};

const isLastLineComment = (
  model: editor.ITextModel,
  position: Position,
): boolean => {
  let lineNum = skipWhitespaces(model, position.lineNumber - 1);
  const lastLine = model.getLineContent(lineNum).trim();
  return isPythonDocString(lastLine) || lastLine[0] === '#';
};

// Assuming the previous line is a comment, get the entire comment block
const getPythonCommentBlock = (
  model: editor.ITextModel,
  position: Position,
) => {
  let lineNum = skipWhitespaces(model, position.lineNumber - 1);
  const lastLine = model.getLineContent(lineNum);
  let isDocString = isPythonDocString(lastLine);
  lineNum -= 1;
  const lines = [lastLine];
  while (lineNum > 0) {
    const line: string = model.getLineContent(lineNum);
    if (isDocString) {
      lines.push(line);
      if (isPythonDocString(line)) {
        break;
      }
    } else if (line.trim().startsWith('#')) {
      lines.push(line);
    } else {
      break;
    }

    lineNum -= 1;
  }
  return lines.reverse().join('\n');
};

const getContextBlock = (model: editor.ITextModel, position: Position) => {
  const lines = [];
  for (
    let i = Math.max(position.lineNumber - ContextLength, 0);
    i < position.lineNumber - 1;
    i++
  ) {
    const line: string = model.getLineContent(i + 1);
    lines.push(line);
  }
  return lines.join('\n');
};

const getPromptAndSuggestionType = (
  model: editor.ITextModel,
  position: Position,
): { suggestionType: 'line' | 'block'; prompt: string } => {
  let prompt = '';
  prompt = getContextBlock(model, position);

  prompt += model.getValueInRange({
    startLineNumber: position.lineNumber,
    endLineNumber: position.lineNumber,
    startColumn: 1,
    endColumn: position.column,
  });

  let suggestionType: 'line' | 'block' = 'line';
  if (model.getLineContent(position.lineNumber).trim().length == 0) {
    suggestionType = 'block';
  }

  return {
    suggestionType,
    prompt: `\n${prompt}`,
  };
};

export type AutocompleteModel = 'GPT3Dot5Turbo';

const showPopup = (
  dispatch: AppDispatch,
  message: string,
  icon: React.FC<any>,
) => {
  dispatch(
    notificationsActions.setCurrentMessage({
      message,
      icon,
      canClose: true,
    }),
  );
};

const clearPopup = (dispatch: AppDispatch) => {
  dispatch(notificationsActions.setCurrentMessage(undefined));
};

const makeSuggestion = (
  suggestion: AutocompleteSuggestion,
  word: editor.IWordAtPosition,
  position: Position,
) => ({
  label: word.word + suggestion.text,
  insertText: word.word + suggestion.text,
  text: word.word + suggestion.text,
  range: {
    startLineNumber: position.lineNumber,
    endLineNumber: position.lineNumber,
    startColumn: word.startColumn,
    endColumn: word.endColumn,
  },
});

const getSuggestions = (
  dispatch: AppDispatch,
  prompt: string,
  suggestionType: 'line' | 'block',
  model: editor.ITextModel,
  position: Position,
  timerId: MutableRefObject<NodeJS.Timeout | null>,
  autocompleteModel: AutocompleteModel,
  language: CodeLanguage,
): Promise<
  languages.ProviderResult<
    languages.InlineCompletions<languages.InlineCompletion>
  >
> => {
  if (timerId.current) {
    clearTimeout(timerId.current);
  }
  const word = model.getWordUntilPosition(position);
  showPopup(dispatch, RequestingSuggestionsMessage, Information);

  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (language !== 'python') {
        const msg = t`Language not supported for autocompletion: ${language}`;
        reject(new Error(msg));
        return;
      }

      dispatch(
        generatedApi.endpoints.postCodeAutocomplete.initiate({
          autocompleteRequest: {
            prompt,
            suggestionType,
            model: autocompleteModel,
            language,
          },
        }),
      )
        .then((data) => {
          if ('data' in data) {
            const suggestions = data.data.suggestions
              .filter((suggestion) => suggestion.text.trim().length > 0)
              .map((suggestion) => ({
                text: suggestion.text.trim(), // if the suggestion starts with a tab we can't select it in the editor
              }))
              .map((suggestion) => makeSuggestion(suggestion, word, position));
            if (suggestions.length == 0) {
              showPopup(dispatch, NoSuggestionFoundMessage, Exclamation);
            } else {
              clearPopup(dispatch);
            }

            resolve({
              items: suggestions ?? [{ insertText: '', text: '' }],
            });
          } else {
            const error = data.error as FetchBaseQueryError;
            const errorData = error.data as {
              error: string;
            };
            if (error.status == 500) {
              if (errorData.error.includes('status code: 429')) {
                showPopup(dispatch, TooManyRequestsMessage, Exclamation);
              } else {
                showPopup(dispatch, TryAnotherPromptMessage, Exclamation);
              }
            }
            reject(error);
          }
        })
        .catch((err) => {
          reject(err);
        });
    }, TypeDelay);
  });
};

const provideCompletionItems = async (
  model: editor.ITextModel,
  position: Position,
  context: languages.InlineCompletionContext,
  dispatch: AppDispatch,
  timerId: MutableRefObject<NodeJS.Timeout | null>,
  autocompleteModel: AutocompleteModel,
  language: CodeLanguage,
): Promise<
  languages.ProviderResult<
    languages.InlineCompletions<languages.InlineCompletion>
  >
> => {
  const noSuggestionResponse = { items: [{ insertText: '', text: '' }] };

  if (context.triggerKind != languages.InlineCompletionTriggerKind.Explicit) {
    return noSuggestionResponse;
  }

  const { prompt, suggestionType } = getPromptAndSuggestionType(
    model,
    position,
  );

  if (prompt.trim().length < 5) {
    return noSuggestionResponse;
  }

  return getSuggestions(
    dispatch,
    prompt,
    suggestionType,
    model,
    position,
    timerId,
    autocompleteModel,
    language,
  );
};

export const setupAutocomplete = (
  monaco: Monaco,
  dispatch: AppDispatch,
  timerId: MutableRefObject<NodeJS.Timeout | null>,
  autocompleteModel: AutocompleteModel,
  language: CodeLanguage,
) => {
  const provider: languages.InlineCompletionsProvider<
    languages.InlineCompletions<languages.InlineCompletion>
  > = {
    provideInlineCompletions: (
      model: editor.ITextModel,
      position: Position,
      context: languages.InlineCompletionContext,
    ) =>
      provideCompletionItems(
        model,
        position,
        context,
        dispatch,
        timerId,
        autocompleteModel,
        language,
      ).then((data) => data),
    freeInlineCompletions: () => {},
  };
  monaco.languages.registerInlineCompletionsProvider(
    language as string,
    provider,
  );
};

export const setupAutocompleteShortcut = (
  editor: monaco.editor.IStandaloneCodeEditor,
) => {
  // Note: must use addAction instead of addCommand. The addCommand is not
  // working because it is bound to only the latest Monaco instance. This is a
  // known bug: https://github.com/microsoft/monaco-editor/issues/2947
  editor.addAction({
    id: 'autocomplete',
    label: 'Autocomplete',
    keybindings: [AutocompleteShortcut],
    run: () => {
      editor.trigger('', 'editor.action.inlineSuggest.trigger', '');
    },
  });
};
