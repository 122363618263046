import { SignUp } from '@clerk/clerk-react';
import { t } from '@lingui/macro';
import React from 'react';
import styled from '@emotion/styled/macro';
import Button from 'ui/common/Button/Button';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import { NotSignedInWrapper } from './NotSignedInWrapper';
import { TierSelection } from './TierSelection';

const StudentNag = styled.div`
  position: absolute;
  bottom: 23px;
  height: 72px;
  padding: 16px;
  display: flex;
  align-items: center;
  background: rgba(0, 0, 0, 0.12);
  border: 1px solid ${({ theme }) => theme.colors.grey[50]};
  border-radius: 8px;
  color: ${({ theme }) => theme.colors.grey[2]};
  font-size: ${({ theme }) => theme.typography.font.standard.size};

  > strong {
    font-size: ${({ theme }) => theme.typography.font.heading3.size};
    font-weight: ${({ theme }) => theme.typography.font.heading3.weight};
    margin-right: ${({ theme }) => theme.spacing.large};
    position: relative;
    top: -1px;
  }

  button {
    height: 100%;
    margin-left: ${({ theme }) => theme.spacing.large};
  }
`;

const SignUpPage: React.FC = () => {
  const [tierIsSelected, setTierIsSelected] = React.useState<boolean>(false);

  const onTierSelected = (tierId: string) => {
    setTierIsSelected(true);
    localStorage.setItem('signupTier', tierId);
  };

  const verifyingClerkSession = React.useMemo(() => {
    const finalPath = window.location.pathname.split('/').pop();
    return finalPath === 'verify';
  }, []);

  return (
    <NotSignedInWrapper>
      {tierIsSelected || verifyingClerkSession ? (
        <SignUp
          redirectUrl="/login/sign-up-success"
          path="/login/sign-up"
          routing="path"
        />
      ) : (
        <TierSelection onSelectTier={onTierSelected} />
      )}
      {!verifyingClerkSession && (
        <StudentNag>
          <strong>Students:</strong>{' '}
          {t`If you have a valid academic email address, you can use Collimator for free.`}
          {!tierIsSelected && (
            <Button
              variant={ButtonVariants.LargePrimary}
              onClick={() => onTierSelected('student')}>
              {t`Sign up for free`}
            </Button>
          )}
        </StudentNag>
      )}
    </NotSignedInWrapper>
  );
};

export default SignUpPage;
