import styled from '@emotion/styled/macro';
import React from 'react';
import { MenuItemWrapper } from 'ui/common/Menu/items/MenuItemWrapper';
import { MenuItemShortcut } from 'ui/common/Menu/menuItemTypes';

const MenuItemButtonWrapper = styled(MenuItemWrapper)<{ disabled: boolean }>`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  &:hover {
    background-color: ${(props) => props.theme.colors.tint.tint2};
  }
  ${(props) =>
    props.disabled
      ? `
      cursor: auto;
      user-select: none;
      color: ${props.theme.colors.text.tertiary};
    `
      : ''};
`;

const Shortcut = styled.span`
  display: flex;
  color: ${(props) => props.theme.colors.text.tertiary};
`;

const ShortcutKeyNameCapsule = styled.span`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.colors.text.secondary};
  height: ${(props) => props.theme.spacing.large};
  margin-right: ${(props) => props.theme.spacing.small};
  padding: 0 ${(props) => props.theme.spacing.small};
  background-color: ${(props) => props.theme.colors.grey[10]};
  border-radius: 2px;

  &:last-child {
    margin-right: 0;
  }
`;

interface Props {
  content: React.ReactNode | string;
  shortcut?: MenuItemShortcut;
  disabled?: boolean;
  onClick?: (e?: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

const MenuItemButton: React.FC<Props> = ({
  shortcut = null,
  content,
  disabled = false,
  onClick = () => {},
}) => {
  const refEl = React.useRef<HTMLDivElement>(null);

  return (
    <MenuItemButtonWrapper
      onClick={onClick}
      ref={refEl}
      tabIndex={-1}
      disabled={!!disabled}>
      {content}
      {shortcut ? (
        <Shortcut>
          {shortcut.keyStrings.map((keyName) => (
            <ShortcutKeyNameCapsule key={keyName}>
              {keyName}
            </ShortcutKeyNameCapsule>
          ))}
        </Shortcut>
      ) : null}
    </MenuItemButtonWrapper>
  );
};

export default MenuItemButton;
