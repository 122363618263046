import { Global, Theme, css, useTheme } from '@emotion/react';
import { ReactElement } from 'react';

const GlobalStyles = (): ReactElement => {
  const theme = useTheme() as Theme;
  const globalCss = css`
    *:not([data-codeeditor] *) {
      box-sizing: border-box;
    }

    html,
    body {
      height: 100%;
      width: 100%;
      font-size: ${theme.typography.font.base.size};
      line-height: ${theme.typography.lineHeight};
      color: ${theme.colors.text.primary};
      background-color: ${theme.colors.grey[5]};
    }

    html,
    body,
    button,
    input,
    textarea {
      font-family: 'Barlow', sans-serif;
      font-size: ${theme.typography.font.base.size};
      text-overflow: ellipsis;
    }

    body {
      margin: 0;
    }

    * {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    code:not([data-codeeditor] *) {
      font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
    }

    #root {
      height: 100%;
      width: 100%;
    }

    li {
      list-style: none;
    }

    p {
      margin: 0;
    }
    h1,
    h2,
    h3,
    h4,
    h5 {
      margin: 0;
    }

    table {
      border-collapse: collapse;
    }

    th {
      font-weight: normal;
      text-align: inherit;
    }

    fieldset {
      border: 0;
      margin: 0;
      padding: 0;
    }
    legend {
      clip-path: inset(100%);
      clip: rect(1px, 1px, 1px, 1px);
      height: 1px;
      overflow: hidden;
      position: absolute;
      white-space: nowrap; /* added line */
      width: 1px;
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      /* display: none; <- Crashes Chrome on hover */
      -webkit-appearance: none;
      margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
    }
  `;
  return <Global styles={globalCss} />;
};

export default GlobalStyles;
