import styled from '@emotion/styled/macro';
import { t } from '@lingui/macro';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { RightSidebarSection, uiFlagsActions } from 'app/slices/uiFlagsSlice';
import React from 'react';
import { useDebounce } from 'react-use';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import {
  ChevronLeft,
  ChevronRight,
  History,
  Outputs,
  Properties,
  Simulations,
} from 'ui/common/Icons/Small';
import {
  RightExpandCollapseButton,
  RightSidebar,
  SidebarTabGroupWrapper,
} from 'ui/common/layout/appLayout';
import TabGroup from 'ui/common/tabs/TabGroup';
import { TabInfo } from 'ui/common/tabs/tabTypes';
import ModelOutputSidebar from 'ui/modelEditor/ModelOutputSidebar';
import ModelSimulationSidebar from 'ui/modelEditor/ModelSimulationSidebar';
import PropertiesSidebar from 'ui/modelEditor/PropertiesSidebar';
import { DiagramVersionsPane } from 'ui/versionHistory/DiagramVersionsPane';

export const SidebarContentWrapper = styled.div`
  overflow-x: hidden;
  overflow-y: auto;
  flex: 1;
`;

const DiagramRightSidebar: React.FC = () => {
  const dispatch = useAppDispatch();

  const isExpanded = useAppSelector(
    (state) => state.uiFlags.isRightSidebarOpen,
  );
  const setIsExpanded = (isExpanded: boolean) => {
    dispatch(uiFlagsActions.setUIFlag({ isRightSidebarOpen: isExpanded }));
  };

  // make sure to show the content while the closing animation runs (150ms)
  const [showContent, setShowContent] = React.useState(isExpanded);
  useDebounce(() => {
    setShowContent(isExpanded);
  }, 200);

  const section: RightSidebarSection = useAppSelector(
    (state) => state.uiFlags.currentRightSidebarSection,
  );

  const renderSection = () => {
    switch (section) {
      case RightSidebarSection.Properties:
        return <PropertiesSidebar />;
      case RightSidebarSection.Simulation:
        return <ModelSimulationSidebar />;
      case RightSidebarSection.Outputs:
        return <ModelOutputSidebar />;
      case RightSidebarSection.VersionHistory:
        return <DiagramVersionsPane />;
    }
  };

  const tabs = React.useMemo(() => {
    const tabBuilder: TabInfo[] = [
      {
        id: 'properties',
        Icon: Properties,
        displayName: t({
          id: 'modelEditor.rightSidebarTab.properties',
          message: 'Properties',
        }),
        onClick: () => {
          dispatch(
            uiFlagsActions.setRightSidebarTab(RightSidebarSection.Properties),
          );
        },
        isSelected: () => section === RightSidebarSection.Properties,
      },
      {
        id: 'simulation',
        Icon: Simulations,
        displayName: t({
          id: 'modelEditor.rightSidebarTab.simulation',
          message: 'Simulation',
        }),
        onClick: () =>
          dispatch(
            uiFlagsActions.setRightSidebarTab(RightSidebarSection.Simulation),
          ),
        isSelected: () => section === RightSidebarSection.Simulation,
      },
      {
        id: 'outputs',
        Icon: Outputs,
        displayName: t({
          id: 'modelEditor.rightSidebarTab.outputs',
          message: 'Outputs',
        }),
        onClick: () =>
          dispatch(
            uiFlagsActions.setRightSidebarTab(RightSidebarSection.Outputs),
          ),
        isSelected: () => section === RightSidebarSection.Outputs,
      },
      {
        id: 'versionHistory',
        Icon: History,
        displayName: t({
          id: 'modelEditor.rightSidebarTab.versionHistory',
          message: 'History',
        }),
        onClick: () => {
          dispatch(
            uiFlagsActions.setRightSidebarTab(
              RightSidebarSection.VersionHistory,
            ),
          );
        },
        isSelected: () => section === RightSidebarSection.VersionHistory,
      },
    ];

    return tabBuilder;
  }, [dispatch, section]);

  return (
    <>
      <RightSidebar isExpanded={isExpanded}>
        {(isExpanded || showContent) && (
          <>
            <SidebarTabGroupWrapper>
              <TabGroup
                className="tour-onboarding-5"
                tabs={tabs}
                compact
                selectedIsHighlighted
                testId="model-right-sidebar-tabs"
              />
            </SidebarTabGroupWrapper>
            <SidebarContentWrapper>{renderSection()}</SidebarContentWrapper>
          </>
        )}
      </RightSidebar>
      <RightExpandCollapseButton
        testId="toggle-model-right-sidebar-button"
        Icon={isExpanded ? ChevronRight : ChevronLeft}
        variant={ButtonVariants.LargeSecondary}
        onClick={() => setIsExpanded(!isExpanded)}
      />
    </>
  );
};

export default DiagramRightSidebar;
