/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const CoordinaterotationconversionQuaterniontorotationangles: React.FC<
  Props
> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M6.97285 13.01C6.97285 12.9767 6.98285 12.95 7.00285 12.93C7.02952 12.9033 7.05952 12.89 7.09285 12.89H8.01285C8.04619 12.89 8.07285 12.9033 8.09285 12.93C8.11952 12.95 8.13285 12.9767 8.13285 13.01V19.77C8.13285 19.8033 8.11952 19.83 8.09285 19.85C8.07285 19.8767 8.04619 19.89 8.01285 19.89H7.09285C7.05952 19.89 7.02952 19.8767 7.00285 19.85C6.98285 19.83 6.97285 19.8033 6.97285 19.77V17.6C6.97285 17.58 6.96618 17.57 6.95285 17.57C6.93952 17.5633 6.92619 17.5667 6.91285 17.58C6.61952 17.9133 6.24285 18.08 5.78285 18.08C5.28952 18.08 4.87952 17.9567 4.55285 17.71C4.22618 17.4633 3.98952 17.12 3.84285 16.68C3.72952 16.34 3.67285 15.9333 3.67285 15.46C3.67285 14.9933 3.73285 14.5767 3.85285 14.21C3.99952 13.77 4.23618 13.4267 4.56285 13.18C4.88952 12.9333 5.28952 12.81 5.76285 12.81C6.23619 12.81 6.61952 12.9833 6.91285 13.33C6.92619 13.35 6.93952 13.3567 6.95285 13.35C6.96618 13.3433 6.97285 13.33 6.97285 13.31V13.01ZM6.71285 16.64C6.87952 16.3667 6.96285 15.9667 6.96285 15.44C6.96285 14.94 6.88619 14.5533 6.73285 14.28C6.54619 13.96 6.28285 13.8 5.94285 13.8C5.56285 13.8 5.27952 13.9633 5.09285 14.29C4.93285 14.59 4.85285 14.9733 4.85285 15.44C4.85285 15.9467 4.95285 16.3533 5.15285 16.66C5.34618 16.94 5.61285 17.08 5.95285 17.08C6.27952 17.08 6.53285 16.9333 6.71285 16.64Z"
      fill={props.fill}
    />
    <path
      d="M12.5303 11.9697C12.2374 11.6768 11.7626 11.6768 11.4697 11.9697C11.1768 12.2626 11.1768 12.7374 11.4697 13.0303L13.9393 15.5L11.4697 17.9697C11.1768 18.2626 11.1768 18.7374 11.4697 19.0303C11.7626 19.3232 12.2374 19.3232 12.5303 19.0303L15.1768 16.3839C15.6649 15.8957 15.6649 15.1043 15.1768 14.6161L12.5303 11.9697Z"
      fill={props.fill}
    />
    <path
      d="M21.6562 5.5C21.6562 5.22386 21.4324 5 21.1562 5H19.6562C19.104 5 18.6562 5.44772 18.6562 6V26C18.6562 26.5523 19.104 27 19.6562 27H21.1562C21.4324 27 21.6562 26.7761 21.6562 26.5C21.6562 26.2239 21.4324 26 21.1562 26H19.6562V6H21.1562C21.4324 6 21.6562 5.77614 21.6562 5.5Z"
      fill={props.fill}
    />
    <path
      d="M25.5 5C25.2239 5 25 5.22386 25 5.5C25 5.77614 25.2239 6 25.5 6H27V26H25.5C25.2239 26 25 26.2239 25 26.5C25 26.7761 25.2239 27 25.5 27H27C27.5523 27 28 26.5523 28 26V6C28 5.44772 27.5523 5 27 5H25.5Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M22.9097 10.8384C22.6157 10.8384 22.3617 10.7632 22.1475 10.6128C21.9355 10.4624 21.7726 10.2528 21.6587 9.98389C21.5448 9.71273 21.4878 9.39714 21.4878 9.03711V8.96533C21.4878 8.57113 21.5448 8.22705 21.6587 7.93311C21.7726 7.63916 21.9355 7.41016 22.1475 7.24609C22.3617 7.08203 22.6169 7 22.9131 7C23.1318 7 23.321 7.04443 23.4805 7.1333C23.6423 7.22217 23.779 7.34863 23.8906 7.5127L23.959 7.07178H24.7007V9.65918C24.7007 9.83691 24.7223 9.9611 24.7656 10.0317C24.8112 10.1024 24.8727 10.1377 24.9502 10.1377C24.9661 10.1377 24.9821 10.1366 24.998 10.1343C25.014 10.132 25.0277 10.1274 25.0391 10.1206L25.1211 10.7393C25.0482 10.778 24.9764 10.8042 24.9058 10.8179C24.8374 10.8315 24.7645 10.8384 24.687 10.8384C24.5002 10.8384 24.3418 10.7939 24.2119 10.7051C24.0843 10.6139 23.9897 10.4749 23.9282 10.2881C23.8143 10.4681 23.6742 10.6048 23.5078 10.6982C23.3415 10.7917 23.1421 10.8384 22.9097 10.8384ZM23.1523 10.1753C23.3118 10.1753 23.4474 10.1366 23.5591 10.0591C23.673 9.97933 23.7664 9.86654 23.8394 9.7207V8.13818C23.7892 8.0402 23.7311 7.95589 23.665 7.88525C23.599 7.81462 23.5238 7.76107 23.4395 7.72461C23.3551 7.68587 23.2617 7.6665 23.1592 7.6665C22.9746 7.6665 22.8219 7.72233 22.7012 7.83398C22.5827 7.94336 22.495 8.09489 22.438 8.28857C22.381 8.48226 22.3525 8.70784 22.3525 8.96533V9.03711C22.3525 9.38346 22.4163 9.66032 22.5439 9.86768C22.6715 10.0728 22.8743 10.1753 23.1523 10.1753Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M21.6416 19.1133V14.147C21.6416 13.8758 21.7122 13.6331 21.8535 13.4189C21.9971 13.2025 22.1896 13.0316 22.4312 12.9062C22.6727 12.7809 22.9438 12.7183 23.2446 12.7183C23.5454 12.7183 23.8097 12.7752 24.0376 12.8892C24.2677 13.0008 24.4466 13.158 24.5742 13.3608C24.7041 13.5614 24.769 13.7949 24.769 14.0615C24.769 14.2803 24.7132 14.4797 24.6016 14.6597C24.4899 14.8374 24.3372 14.981 24.1436 15.0903C24.4238 15.1906 24.6392 15.3467 24.7896 15.5586C24.9399 15.7705 25.0151 16.0326 25.0151 16.3447C25.0151 16.6546 24.9525 16.9212 24.8271 17.1445C24.7018 17.3678 24.5241 17.5399 24.2939 17.6606C24.0661 17.7791 23.7983 17.8384 23.4907 17.8384C23.3107 17.8384 23.1353 17.8156 22.9644 17.77C22.7935 17.7244 22.6396 17.6549 22.5029 17.5615V19.1133H21.6416ZM23.2993 17.1753C23.5659 17.1753 23.7756 17.0967 23.9282 16.9395C24.0809 16.7822 24.1572 16.5726 24.1572 16.3105C24.1572 16.1533 24.1265 16.0109 24.0649 15.8833C24.0034 15.7534 23.9146 15.6509 23.7983 15.5757C23.6844 15.4982 23.5465 15.4595 23.3848 15.4595H22.9883V14.8306H23.207C23.4486 14.8306 23.6252 14.7679 23.7368 14.6426C23.8507 14.515 23.9077 14.3418 23.9077 14.123C23.9077 13.984 23.8804 13.8599 23.8257 13.7505C23.7733 13.6388 23.6958 13.55 23.5933 13.4839C23.493 13.4178 23.3711 13.3848 23.2275 13.3848C23.0885 13.3848 22.9644 13.4178 22.855 13.4839C22.7456 13.55 22.659 13.64 22.5952 13.7539C22.5337 13.8678 22.5029 13.9989 22.5029 14.147V16.895C22.6009 16.9839 22.7183 17.0534 22.855 17.1035C22.994 17.1514 23.1421 17.1753 23.2993 17.1753Z"
      fill={props.fill}
    />
    <path
      d="M22.814 24.082V25.6919H23.6753V24.1538L24.728 21.146L25.0322 21.0981V20.5684H23.46V21.0981L23.8701 21.1699L23.3301 23.0088L23.2788 23.2515H23.2583L23.2104 23.0088L22.5918 21.0469C22.5462 20.9079 22.4972 20.8008 22.4448 20.7256C22.3924 20.6504 22.3241 20.5991 22.2397 20.5718C22.1577 20.5444 22.0472 20.5308 21.9082 20.5308C21.8307 20.5308 21.7464 20.5376 21.6553 20.5513C21.5641 20.5649 21.4844 20.5832 21.416 20.606L21.4844 21.2588C21.5322 21.2497 21.5641 21.244 21.5801 21.2417C21.596 21.2394 21.6154 21.2383 21.6382 21.2383C21.7202 21.2383 21.7829 21.2542 21.8262 21.2861C21.8717 21.3158 21.9082 21.3761 21.9355 21.4673L22.814 24.082Z"
      fill={props.fill}
    />
  </svg>
);

CoordinaterotationconversionQuaterniontorotationangles.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default CoordinaterotationconversionQuaterniontorotationangles;
