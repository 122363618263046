// !!!! -- DO NOT MANUALLY EDIT -- !!!!
// This file was generated by something in scripts/generateDefaultBlockData.js
// If you want to change the contents of this file, it should be automated
//
// (You can edit this file locally, but it may not be checked into the codebase -
// PRs with a manual edit of this file are forbidden from being merged.)

import { computationBlockDefaults } from 'app/generated_blocks/computationBlockDefaults';
import type { ComputationBlockClass } from 'app/generated_types/ComputationBlockClass';
import deepmerge from 'deepmerge';

export const AcausalElectricalVoltageSource: ComputationBlockClass = deepmerge(
  computationBlockDefaults,
  {
    "base": {
      "name": "VoltageSource",
      "namespace": "acausal.electrical",
      "description": "Voltage source."
    },
    "modes": {
      "time": "agnostic"
    },
    "ports": {
      "inputs": {
        "static": [
          {
            "name": "p",
            "variant": {
              "variant_kind": "acausal",
              "acausal_domain": "electrical"
            }
          }
        ],
        "conditional": [
          {
            "name": "v",
            "condition_bool_parameter": "enable_voltage_port",
            "order": 1
          }
        ]
      },
      "outputs": {
        "static": [
          {
            "name": "n",
            "variant": {
              "variant_kind": "acausal",
              "acausal_domain": "electrical"
            }
          }
        ]
      }
    },
    "parameter_definitions": [
      {
        "name": "v",
        "data_type": "float",
        "default_value": "1.0",
        "description": "Constant voltage."
      },
      {
        "name": "enable_voltage_port",
        "data_type": "bool",
        "default_value": "false",
        "description": "User selection for enabling inport for voltage value."
      }
    ]
  }
);
