/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const DiscretepidP: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1 4.75C1.13807 4.75 1.25 4.86193 1.25 5V25.7927C1.32819 25.765 1.41234 25.75 1.5 25.75H2.75V24.75C2.75 24.1977 3.19772 23.75 3.75 23.75H4.75V11.75C4.75 11.1977 5.19771 10.75 5.75 10.75H6.75V6.25C6.75 5.69772 7.19771 5.25 7.75 5.25H9.25C9.80229 5.25 10.25 5.69772 10.25 6.25H11.25C11.8023 6.25 12.25 6.69772 12.25 7.25V8.75H13.25C13.8023 8.75 14.25 9.19772 14.25 9.75H14.75C14.75 9.19772 15.1977 8.75 15.75 8.75H16.8838C17.0567 8.4511 17.3799 8.25 17.75 8.25H30C30.4142 8.25 30.75 8.58579 30.75 9C30.75 9.41421 30.4142 9.75 30 9.75H18.1162C17.9433 10.0489 17.6201 10.25 17.25 10.25H16.25C16.25 10.8023 15.8023 11.25 15.25 11.25H13.75C13.1977 11.25 12.75 10.8023 12.75 10.25H11.75C11.1977 10.25 10.75 9.80228 10.75 9.25V7.75H9.75C9.19772 7.75 8.75 7.30228 8.75 6.75H8.25V11.25C8.25 11.8023 7.80228 12.25 7.25 12.25H6.25V24.25C6.25 24.8023 5.80228 25.25 5.25 25.25H4.25V26.25C4.25 26.4321 4.2013 26.6029 4.11622 26.75H31C31.1381 26.75 31.25 26.8619 31.25 27C31.25 27.1381 31.1381 27.25 31 27.25H0.75V5C0.75 4.86193 0.861929 4.75 1 4.75Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M19.6796 15.588C20.1836 15.588 20.6276 15.692 21.0116 15.9C21.3956 16.108 21.6916 16.404 21.8996 16.788C22.1156 17.164 22.2236 17.6 22.2236 18.096C22.2236 18.584 22.1156 19.016 21.8996 19.392C21.6836 19.768 21.3756 20.06 20.9756 20.268C20.5836 20.468 20.1276 20.568 19.6076 20.568H17.6876C17.6476 20.568 17.6276 20.588 17.6276 20.628V23.856C17.6276 23.896 17.6116 23.932 17.5796 23.964C17.5556 23.988 17.5236 24 17.4836 24H16.3796C16.3396 24 16.3036 23.988 16.2716 23.964C16.2476 23.932 16.2356 23.896 16.2356 23.856V15.732C16.2356 15.692 16.2476 15.66 16.2716 15.636C16.3036 15.604 16.3396 15.588 16.3796 15.588H19.6796ZM19.4756 19.44C19.8836 19.44 20.2116 19.32 20.4596 19.08C20.7076 18.84 20.8316 18.524 20.8316 18.132C20.8316 17.732 20.7076 17.408 20.4596 17.16C20.2116 16.912 19.8836 16.788 19.4756 16.788H17.6876C17.6476 16.788 17.6276 16.808 17.6276 16.848V19.38C17.6276 19.42 17.6476 19.44 17.6876 19.44H19.4756Z"
      fill={props.fill}
    />
  </svg>
);

DiscretepidP.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default DiscretepidP;
