import styled from '@emotion/styled/macro';

export const NavbarWrapper = styled.nav`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-shrink: 0;
  width: 100%;
  height: ${({ theme }) => theme.sizes.navbarHeight};
  z-index: 3;
  background: ${({ theme }) => theme.colors.brand.primary.darker};
`;

export const NavbarRegion = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
`;

export const LeftRegion = styled(NavbarRegion)`
  flex: 1 1 40px;
`;

export const RightRegion = styled(NavbarRegion)`
  flex: 1 1 40px;
  justify-content: flex-end;
`;
