import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { JobSummary, ModelOverrides } from 'app/apiGenerated/generatedApiTypes';

export enum SimulationResultType {
  CompileOnly,
  CompileAndRun,
  GenerateCode, // Covers also FMU export
}

export enum ModelSimulationRequest {
  None,
  Check,
  Run,
  RunEnsemble,
  Stop,
  GenerateCode,
  FMUExport,
}

export enum ModelSimulationState {
  Ready,
  StartingCheck,
  MonitoringCheck,
  StartingRun,
  MonitoringRun,
  StoppingRun,
  StartingCodeGeneration,
  MonitoringCodeGeneration,
}

interface RequestRunEvent {
  ignoreCache?: boolean;
  autoSwitchBottomTabOnSimDone?: boolean;
}

interface RequestRunEnsembleEvent {
  modelOverrides: ModelOverrides;
}

// FIXME: correlationId was wrongly used as a tracking ID when it is meant
// only for backend logging purposes.
interface SimulationProgressEvent {
  correlationId: string;
}

interface ProjectState {
  forceReloadModel: boolean;
  forceUpdateModel: boolean;
  forceUpdateFileStatus: boolean;
  forceRefetchProjects: boolean;
  forceRefetchProjectPermissions: boolean;
  forceRefetchSimulationSummary: boolean;
  forceRefetchDataIntegrations: boolean;
  forceReloadDataIntegrationObjects: boolean;
  modelSimulationState: ModelSimulationState;
  modelSimulationRequest: ModelSimulationRequest;
  // FIXME: correlationId has been misused as a tracking ID, leading to messy state handling.
  correlationId: string;
  autoSwitchBottomTabOnSimDone: boolean;
  simulationSummary?: JobSummary;
  simulationSummaryType?: SimulationResultType;
  waitingForModelUpdate: boolean;
  modelUpdatingId: string;
  simulationEnsembleRequest?: RequestRunEnsembleEvent;
}

const initialState: ProjectState = {
  // FIXME: This anti-pattern must die: setting flags in redux to request
  // refreshing data is complicated, fragile and full of bugs. Instead, just
  // call the RTK Query APIs directly. It will have valid data.
  forceRefetchProjects: false,
  forceUpdateModel: false,
  forceUpdateFileStatus: false,
  forceRefetchProjectPermissions: false,
  forceRefetchSimulationSummary: false,
  forceReloadModel: false,
  forceRefetchDataIntegrations: false,
  forceReloadDataIntegrationObjects: false,
  modelSimulationState: ModelSimulationState.Ready,
  modelSimulationRequest: ModelSimulationRequest.None,
  correlationId: '',
  autoSwitchBottomTabOnSimDone: true,
  waitingForModelUpdate: false,
  modelUpdatingId: '',
};

const projectSlice = createSlice({
  name: 'projectSlice',
  initialState,
  reducers: {
    resetProjectState: () => initialState,

    requestRefetchProjects(state) {
      state.forceRefetchProjects = true;
    },

    clearRequestRefetchProjects(state) {
      state.forceRefetchProjects = false;
    },

    requestRefetchProjectPermissions(state) {
      state.forceRefetchProjectPermissions = true;
    },

    clearRequestRefetchProjectPermissions(state) {
      state.forceRefetchProjectPermissions = false;
    },

    requestRefetchSimulationSummary(state) {
      state.forceRefetchSimulationSummary = true;
    },

    requestRefetchDataIntegrations(state) {
      state.forceRefetchDataIntegrations = true;
    },

    clearRequestRefetchDataIntegrations(state) {
      state.forceRefetchDataIntegrations = false;
    },

    requestRefetchDataIntegrationObjects(state) {
      state.forceReloadDataIntegrationObjects = true;
    },

    clearRequestRefetchDataIntegrationObjects(state) {
      state.forceReloadDataIntegrationObjects = false;
    },

    clearRequestSimulationSummary(state) {
      state.forceRefetchSimulationSummary = false;
    },

    requestReloadModel(state) {
      state.forceReloadModel = true;
    },

    clearRequestToReloadModel(state) {
      state.forceReloadModel = false;
    },

    requestUpdateModel(state) {
      state.forceUpdateModel = true;
    },

    clearRequestToUpdateModel(state) {
      state.forceUpdateModel = false;
    },

    requestFileStatusRefetch(state) {
      state.forceUpdateFileStatus = true;
    },

    clearFileStatusRefetch(state) {
      state.forceUpdateFileStatus = false;
    },

    setAutoSwitchBottomTabOnSimDone(state, action: PayloadAction<boolean>) {
      state.autoSwitchBottomTabOnSimDone = action.payload;
    },

    requestCheck(state, action?: PayloadAction<RequestRunEvent>) {
      state.modelSimulationRequest = ModelSimulationRequest.Check;
      state.autoSwitchBottomTabOnSimDone =
        action?.payload.autoSwitchBottomTabOnSimDone ?? true;
    },

    requestRun(state, action?: PayloadAction<RequestRunEvent>) {
      state.modelSimulationRequest = ModelSimulationRequest.Run;
      state.autoSwitchBottomTabOnSimDone =
        action?.payload.autoSwitchBottomTabOnSimDone ?? true;
    },

    requestRunEnsemble(state, action: PayloadAction<RequestRunEnsembleEvent>) {
      state.modelSimulationRequest = ModelSimulationRequest.RunEnsemble;
      state.simulationEnsembleRequest = action.payload;
    },

    requestGenerateCode(state) {
      state.modelSimulationRequest = ModelSimulationRequest.GenerateCode;
    },

    requestFMUExport(state) {
      state.modelSimulationRequest = ModelSimulationRequest.FMUExport;
    },

    requestStop(state) {
      if (
        state.modelSimulationState === ModelSimulationState.StartingRun ||
        state.modelSimulationState === ModelSimulationState.StartingCheck ||
        state.modelSimulationState === ModelSimulationState.MonitoringCheck ||
        state.modelSimulationState === ModelSimulationState.MonitoringRun
      ) {
        // If a simulation is running, prepare to send a stop API request.
        state.modelSimulationRequest = ModelSimulationRequest.Stop;
      } else {
        // If a run request has been made by the user,
        // but we haven't made the API call to start the run
        // because we are waiting on a model update,
        // cancel the run request before the API is sent.
        state.modelSimulationRequest = ModelSimulationRequest.None;
      }
    },

    checkModelRequestSent(
      state,
      action: PayloadAction<SimulationProgressEvent>,
    ) {
      state.simulationSummary = undefined;
      state.simulationSummaryType = undefined;
      state.modelSimulationRequest = ModelSimulationRequest.None;
      state.modelSimulationState = ModelSimulationState.StartingCheck;
      state.correlationId = action.payload.correlationId;
    },

    runModelRequestSent(state, action: PayloadAction<SimulationProgressEvent>) {
      state.simulationSummary = undefined;
      state.simulationSummaryType = undefined;
      state.modelSimulationRequest = ModelSimulationRequest.None;
      state.modelSimulationState = ModelSimulationState.StartingRun;
      state.correlationId = action.payload.correlationId;
    },

    runModelCodeGenerationSent(
      state,
      action: PayloadAction<SimulationProgressEvent>,
    ) {
      state.simulationSummary = undefined;
      state.simulationSummaryType = undefined;
      state.modelSimulationRequest = ModelSimulationRequest.None;
      state.modelSimulationState = ModelSimulationState.StartingCodeGeneration;
      state.correlationId = action.payload.correlationId;
    },

    stopSimulationRequestSent(state, action: PayloadAction<string>) {
      if (
        state.simulationSummary &&
        state.simulationSummary.uuid === action.payload &&
        (state.modelSimulationState === ModelSimulationState.MonitoringRun ||
          state.modelSimulationState === ModelSimulationState.StartingRun)
      ) {
        state.modelSimulationRequest = ModelSimulationRequest.None;
        state.modelSimulationState = ModelSimulationState.StoppingRun;
      }
    },

    simulationSummaryUpdated(state, action: PayloadAction<JobSummary>) {
      if (
        !state.simulationSummary ||
        state.simulationSummary.uuid === action.payload.uuid
      ) {
        if (state.modelSimulationState === ModelSimulationState.StartingCheck) {
          state.modelSimulationState = ModelSimulationState.MonitoringCheck;
          state.simulationSummaryType = SimulationResultType.CompileOnly;
        } else if (
          state.modelSimulationState ===
          ModelSimulationState.StartingCodeGeneration
        ) {
          state.modelSimulationState =
            ModelSimulationState.MonitoringCodeGeneration;
          state.simulationSummaryType = SimulationResultType.GenerateCode;
        } else if (
          state.modelSimulationState === ModelSimulationState.StartingRun
        ) {
          state.modelSimulationState = ModelSimulationState.MonitoringRun;
          state.simulationSummaryType = SimulationResultType.CompileAndRun;
        }
        state.simulationSummary = action.payload;
      }
    },

    stopSimulationCancelled(state, action: PayloadAction<string>) {
      if (
        state.simulationSummary?.uuid === action.payload &&
        state.modelSimulationState === ModelSimulationState.StoppingRun
      ) {
        state.modelSimulationState = ModelSimulationState.MonitoringRun;
      }
    },

    checkOrRunFinished(state, action: PayloadAction<JobSummary>) {
      if (state.simulationSummary?.uuid === action.payload.uuid) {
        state.modelSimulationState = ModelSimulationState.Ready;
        state.simulationSummary = action.payload;
      }
    },

    checkOrRunCancelled(state, action: PayloadAction<string>) {
      if (state.correlationId === action.payload) {
        state.modelSimulationState = ModelSimulationState.Ready;
      }
    },

    startWaitingForModelUpdate(state) {
      state.waitingForModelUpdate = true;
    },

    startModelUpdate(state, action: PayloadAction<string>) {
      state.waitingForModelUpdate = false;
      state.modelUpdatingId = action.payload;
    },

    completeModelUpdate(state, action: PayloadAction<string>) {
      if (state.modelUpdatingId === action.payload) {
        state.modelUpdatingId = '';
      }
    },
  },
});

export const projectActions = projectSlice.actions;

export default projectSlice;
