// !!!! -- DO NOT MANUALLY EDIT -- !!!!
// This file was generated by something in scripts/generateDefaultBlockData.js
// If you want to change the contents of this file, it should be automated
//
// (You can edit this file locally, but it may not be checked into the codebase -
// PRs with a manual edit of this file are forbidden from being merged.)

import { computationBlockDefaults } from 'app/generated_blocks/computationBlockDefaults';
import type { ComputationBlockClass } from 'app/generated_types/ComputationBlockClass';
import deepmerge from 'deepmerge';

export const RandomNumber: ComputationBlockClass = deepmerge(
  computationBlockDefaults,
  {
    "base": {
      "name": "RandomNumber",
      "namespace": "core",
      "description": "Generates normally distributed random numbers",
      "help_url": "https://docs.collimator.ai/using-model-editors/block-library/discrete-blocks#random-number"
    },
    "modes": {
      "time": "discrete"
    },
    "ports": {
      "outputs": {
        "static": [
          {
            "name": "out_0"
          }
        ]
      }
    },
    "parameter_definitions": [
      {
        "name": "distribution",
        "data_type": "string",
        "allowed_values": [
          "normal"
        ],
        "default_value": "normal",
        "description": "Distribution to sample from"
      },
      {
        "name": "seed",
        "data_type": "int",
        "description": "An integer seed for the random number generator. If empty, a random 32-bit seed will be generated.",
        "optional": true,
        "default_value": ""
      },
      {
        "name": "dtype",
        "data_type": "string",
        "default_value": "float64",
        "allowed_values": [
          "float16",
          "float32",
          "float64",
          "float128"
        ],
        "description": "Data type of the signal"
      },
      {
        "name": "shape",
        "data_type": "any",
        "default_value": "()",
        "description": "Array shape of the signal"
      }
    ]
  }
);
