/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  className?: string;
}

const ZoomReset: React.FC<Props> = (props) => (
  <svg viewBox="0 0 24 24" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.31068 7L11.5304 4.78033L10.4697 3.71967L6.43936 7.74999L10.4697 11.7803L11.5304 10.7197L9.31069 8.5L17.5 8.5V17.5H8.50002V13H7.00002V18C7.00002 18.5523 7.44774 19 8.00002 19H18C18.5523 19 19 18.5523 19 18V8C19 7.44771 18.5523 7 18 7L9.31068 7Z"
      fill={props.fill}
    />
  </svg>
);

ZoomReset.defaultProps = {
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  className: '',
};

export default ZoomReset;
