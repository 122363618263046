// Tree shaking imports available: https://echarts.apache.org/handbook/en/basics/import/#

import * as echarts from 'echarts';
import { memo, useEffect, useRef, useState } from 'react';
import { useEChartsHandler } from 'ui/dataExplorer/charts/chartHooks';

interface Props {
  setParentEChartsInstance: (c: echarts.ECharts) => void;
  options: echarts.EChartsCoreOption;
  width: number;
  height: number;
  // edit handlers
  toggleSeriesMarkPoint?: (e: any) => void;
  prepTraceDrag?: (
    traceId: string,
    displayName: string,
    color: string,
    startMouseX: number,
    startMouseY: number,
  ) => void;
  // display handlers
  resetScrollZoomTimeout: () => void;
  showContextMenu: (e: any) => void;
  restoreChartRange: (e: any) => void;
  setZoom: (e: any) => void;
  onHighlightEvent?: (e: any) => void;
}

/**
 * A synchronized React component that acts as a wrapper for an ECharts instance.
 */
const ReactEChartsChart = memo(
  ({
    setParentEChartsInstance,
    options,
    width,
    height,
    toggleSeriesMarkPoint,
    resetScrollZoomTimeout,
    prepTraceDrag,
    showContextMenu,
    restoreChartRange,
    setZoom,
    onHighlightEvent,
  }: Props) => {
    const chartRef = useRef<HTMLDivElement>(null);
    const [echartsInstance, setEChartsInstance] = useState<echarts.ECharts>();
    const echartsInstanceRef = useRef<echarts.ECharts | undefined>(
      echartsInstance,
    );

    const renderer = 'canvas';

    // Synchronize echart set up with the `option` prop
    useEffect(() => {
      if (echartsInstance !== undefined) {
        echartsInstance.setOption(options, { replaceMerge: ['series'] });
      }
    }, [options, echartsInstance]);

    // Resize to match wrapper size
    useEffect(() => {
      if (echartsInstance !== undefined) {
        echartsInstance?.resize({ width, height });
      }
    }, [width, height, echartsInstance]);

    /**
     * Attach the custom handlers to the ECharts instance here.
     * Use separate hooks so that the dependency array only contains relevant handler.
     */

    useEChartsHandler(echartsInstance, 'datazoom', resetScrollZoomTimeout);
    useEChartsHandler(echartsInstance, 'datazoom', setZoom);
    useEChartsHandler(echartsInstance, 'contextmenu', showContextMenu);
    useEChartsHandler(echartsInstance, 'restore', restoreChartRange);
    useEChartsHandler(echartsInstance, 'highlight', onHighlightEvent);

    // Doesn't fit `useEChartsHandler` due to second param 'series' (query string).
    useEffect(() => {
      if (!echartsInstance || !toggleSeriesMarkPoint) return;
      echartsInstance.on('click', 'series', toggleSeriesMarkPoint);
      return () => {
        echartsInstanceRef.current?.off('click', toggleSeriesMarkPoint);
      };
    }, [echartsInstance, toggleSeriesMarkPoint]);

    // There's extra logic for this handler attachment because it uses ECHarts internals to determine a legend item drag
    // Also why it's attached to the zrender; there is no echarts event equivalent.
    useEffect(() => {
      if (!echartsInstance || !prepTraceDrag) return;

      const zr = echartsInstance.getZr();
      if (!zr) return;

      const onMouseDown = (e: any) => {
        // This type is declared as any because ECharts doesn't expose all its types.
        // Detect if we are not using the left click
        if (e.which !== 1) return;
        const root: any = e.target?.parent;
        const option: any = echartsInstance.getOption();
        if (root?.__legendDataIndex !== undefined) {
          // Implementation detail of using `replaceMerge`. Some series entries are null
          const trace = option.series.filter(
            (traceInfo: any) => !!traceInfo && traceInfo.name,
          )[root.__legendDataIndex];
          const traceId = trace.id;
          // We have to do this here, because colors aren't always persisted.
          const color = trace.color;
          // If dragging a vector element, we're actually moving the entire vector:
          const displayName =
            trace.name && trace.name.indexOf('[') > 0
              ? trace.name.substring(0, trace.name.indexOf('['))
              : trace.name;
          prepTraceDrag(
            traceId,
            displayName,
            color,
            e.event.clientX,
            e.event.clientY,
          );
        }
      };

      zr.on('mousedown', onMouseDown);
      return () => {
        echartsInstanceRef.current?.getZr()?.off('mousedown', onMouseDown);
      };
    }, [echartsInstance, prepTraceDrag]);

    // Initialize echart on mount
    useEffect(() => {
      if (!!chartRef.current && !echartsInstance) {
        const chart = echarts.init(chartRef.current, undefined, { renderer });
        // echartsInstance stable after mount. Use it for other hooks.
        setEChartsInstance(chart);
        // Can we get rid of this? For ChartMenu sibling.
        setParentEChartsInstance(chart);
        echartsInstanceRef.current = chart;
      }
    }, [setParentEChartsInstance, echartsInstance]);

    // Dispose on container unmount
    // ! Keep this at the bottom, after all the handler useEffects.
    // Ensures the `dispose` is called after all the handlers are detached from the instance.
    // The reverse order results in many warnings and null errors about the disposed instance.
    useEffect(
      () => () => {
        if (!echartsInstance) return;
        echartsInstance.dispose();
        setEChartsInstance(undefined);
        echartsInstanceRef.current = undefined;
      },
      [echartsInstance],
    );

    return <div ref={chartRef} style={{ width, height }} />;
  },
);

export default ReactEChartsChart;
