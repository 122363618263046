/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  className?: string;
}

const Integrator: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M25 6.5C25 4.5 23 4 21.7692 4.00656C18.6439 4.02323 16.7551 10.1231 15.6792 13.5978C15.5102 14.1435 15.3613 14.6245 15.2308 15.0069C14.8033 16.2595 14.3834 17.7641 13.9727 19.2353C13.2862 21.6951 12.6257 24.0614 12 25C11 26.5 10 26.5 10 25.5C10 24.6716 9.32843 24 8.5 24C7.67157 24 7 24.6716 7 25.5C7 27.5 9 28 10.1346 27.9935C13.4045 27.9746 15.1331 22.1317 16.1702 18.6262C16.3591 17.9879 16.525 17.4271 16.6731 16.9931C17.0814 15.7967 17.5061 14.3633 17.925 12.9492C18.678 10.4079 19.4125 7.9289 20 7C20.7921 5.74768 22 5.5 22 6.5C22 7.32843 22.6716 8 23.5 8C24.3284 8 25 7.32843 25 6.5Z"
      fill={props.fill}
    />
  </svg>
);

Integrator.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  className: '',
};

export default Integrator;
