/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Interface: React.FC<Props> = (props) => (
  <svg viewBox="0 0 16 16" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M3.99999 2.66666C3.26361 2.66666 2.66666 3.26361 2.66666 3.99999V12C2.66666 12.7364 3.26361 13.3333 3.99999 13.3333H12C12.7364 13.3333 13.3333 12.7364 13.3333 12V3.99999C13.3333 3.26361 12.7364 2.66666 12 2.66666H3.99999ZM12 3.66666H3.99999C3.81589 3.66666 3.66666 3.81589 3.66666 3.99999V5.83332H9.45955L8.97977 5.35354C8.78451 5.15828 8.78451 4.8417 8.97977 4.64644C9.17503 4.45117 9.49161 4.45117 9.68688 4.64644L11.3738 6.33332L9.68688 8.02021C9.49161 8.21547 9.17503 8.21547 8.97977 8.02021C8.78451 7.82495 8.78451 7.50837 8.97977 7.3131L9.45955 6.83332H3.66666V12C3.66666 12.1841 3.81589 12.3333 3.99999 12.3333H12C12.1841 12.3333 12.3333 12.1841 12.3333 12V10.1667H6.54043L7.02021 10.6464C7.21547 10.8417 7.21547 11.1583 7.02021 11.3535C6.82495 11.5488 6.50837 11.5488 6.3131 11.3535L4.62622 9.66666L6.3131 7.97977C6.50837 7.78451 6.82495 7.78451 7.02021 7.97977C7.21547 8.17503 7.21547 8.49161 7.02021 8.68688L6.54043 9.16666H12.3333V3.99999C12.3333 3.81589 12.1841 3.66666 12 3.66666Z"
      fill={props.fill}
    />
  </svg>
);

Interface.defaultProps = {
  width: '16',
  height: '16',
  viewBox: '0 0 16 16',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Interface;
