import {
  GetSubmodelsListApiResponse,
  SubmodelInfoLite,
} from 'app/apiGenerated/generatedApiTypes';

export interface SubmodelInfoLiteUI extends Record<string, unknown> {
  id: string;
  // Another manual change to match openapi shape. Had to add to the 3 converters, too.
  // Please let's get rid of this pattern and let backend validate.
  isGlobal: boolean;
  name: string;
  description?: string;
  createdAt: string;
  updatedAt: string;
  editId: string;
  inputCount: number;
  outputCount: number;
}

function convertAPISubmodelToSubmodel(
  apiSubmodel: SubmodelInfoLite,
): SubmodelInfoLiteUI {
  return {
    id: apiSubmodel.uuid,
    isGlobal: apiSubmodel.is_global,
    name: apiSubmodel.name,
    description: apiSubmodel.description,
    createdAt: apiSubmodel.created_at || '',
    updatedAt: apiSubmodel.updated_at || '',
    editId: apiSubmodel.edit_id,
    inputCount: apiSubmodel.port_count.inputs,
    outputCount: apiSubmodel.port_count.outputs,
  };
}

/**
 * Sort submodels by created date so we can splice optimistic submodels at
 * the top of the list and be sure their position is correct.
 */
export function sortSubmodels<TSubmodel extends SubmodelInfoLiteUI>(
  submodels: TSubmodel[],
) {
  if (submodels) {
    return [...submodels].sort(
      (a, b) =>
        new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
    );
  }
  return [];
}

export function convertGetSubmodelsList(
  apiResponse: GetSubmodelsListApiResponse,
): SubmodelInfoLiteUI[] {
  const submodels = apiResponse.submodels?.map(convertAPISubmodelToSubmodel);
  return sortSubmodels(submodels);
}
