import styled from '@emotion/styled/macro';
import { t } from '@lingui/macro';
import { useGetSimulationProcessResultsReadByUuidQuery } from 'app/apiGenerated/generatedApi';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { modelActions } from 'app/slices/modelSlice';
import React from 'react';
import Button from 'ui/common/Button/Button';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import { Download } from 'ui/common/Icons/Standard';
import SectionHeading from 'ui/common/Inputs/SectionHeading';
import SelectInput from 'ui/common/SelectInput';
import { useModelPermission } from 'ui/permission/useModelPermission';
import { useAppParams } from 'util/useAppParams';
import {
  DetailInputRowsSection,
  DetailsDoubleRow,
  DetailsLabel,
  DetailsSection,
} from './DetailsComponents';

const ParameterWrapper = styled.div`
  margin: ${({ theme }) => `${theme.spacing.small}`} 0;
`;

const DownloadLink = styled.a`
  color: inherit;
  text-decoration: none;
`;

const recordModeOptions = [
  {
    value: 'all',
    label: 'All',
  },
  {
    value: 'selected',
    label: 'Only selected',
  },
];

const SimulationRecordingDetails: React.FC = () => {
  const { projectId, modelId, versionId } = useAppParams();
  const { canEditCurrentModelVersion } = useModelPermission(
    projectId,
    modelId,
    versionId,
  );

  const dispatch = useAppDispatch();
  const configuration = useAppSelector(
    (state) => state.model.present.configuration,
  );

  // FIXME: use something other custom hooks to fetch data to initiate fetch on button click
  const simulationSummary = useAppSelector(
    (state) => state.project.simulationSummary,
  );
  const { data: simData } = useGetSimulationProcessResultsReadByUuidQuery(
    {
      modelUuid: modelId || '',
      simulationUuid: simulationSummary?.uuid || '',
      signalNames: 'continuous_results.csv',
    },
    {
      skip:
        !simulationSummary?.uuid ||
        !modelId ||
        !simulationSummary.results_available,
    },
  );

  const changeRecordMode = (value: string) => {
    dispatch(
      modelActions.changeModelConfigurationValue({
        name: 'record_mode',
        value: value === 'selected' ? 'selected' : 'all',
      }),
    );
  };

  return (
    <>
      <SectionHeading testId="simulation-settings">
        {t({
          id: 'modelRenderer.simulationRecordingDetails.heading',
          message: 'Recording',
        })}
      </SectionHeading>
      <DetailInputRowsSection>
        <ParameterWrapper>
          <DetailsDoubleRow noMargin>
            <DetailsLabel>
              {t({
                id: 'modelRenderer.simulationRecordingDetails.recordMode.label',
                message: 'Record outputs',
              })}
            </DetailsLabel>
            <SelectInput
              options={recordModeOptions}
              currentValue={configuration.record_mode || 'all'}
              onSelectValue={changeRecordMode}
              isDisabled={!canEditCurrentModelVersion}
            />
          </DetailsDoubleRow>
        </ParameterWrapper>
        <DetailsSection>
          <DownloadLink
            download={simData?.s3_urls[0].name}
            href={simData?.s3_urls[0].url}>
            <Button
              variant={ButtonVariants.SmallTertiary}
              title="Download simulation data"
              Icon={Download}
              disabled={!simData?.s3_urls[0]}>
              Download simulation data
            </Button>
          </DownloadLink>
        </DetailsSection>
      </DetailInputRowsSection>
    </>
  );
};

export default SimulationRecordingDetails;
