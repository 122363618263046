import { Theme } from '@emotion/react';
import { BrandColors, CommonTheme } from './emotion';
import { generateColor, lighten } from './utils';

export type ThemeMapType = {
  base: Theme;
};

const commonTheme: CommonTheme = {
  tools: {
    darkest: (hex: string) => lighten(hex, -0.5),
    darker: (hex: string) => lighten(hex, -0.2),
    lighter: (hex: string) => lighten(hex, 0.2),
    lightest: (hex: string) => lighten(hex, 0.5),
  },
  spacing: {
    xsmall: '2px',
    small: '4px',
    normal: '8px',
    large: '16px',
    xlarge: '24px',
    xxlarge: '32px',
  },
  sizes: {
    leftSidebarWidth: '240px',
    rightSidebarWidth: '256px',
    notebookFooterHalfHeight: '348px',
    navbarHeight: '40px',
    footerBarHeight: '32px',
  },
  shadows: {
    standard:
      '0px 5px 11px rgba(0, 0, 0, 0.1), 0px 2.68057px 4.11875px rgba(0, 0, 0, 0.0544), 0px 1.77613px 1.4566px rgba(0, 0, 0, 0.0382)',
    higher:
      '0px 19px 30px rgba(0, 0, 0, 0.09), 0px 7.09px 14px rgba(0, 0, 0, 0.0425), 0px 3.33px 3.9px rgba(0, 0, 0, 0.0225)',
    highest:
      '0px 36px 51px rgba(0, 0, 0, 0.08), 0px 17.1393px 24.6693px rgba(0, 0, 0, 0.0432827), 0px 12.2945px 15.9527px rgba(0, 0, 0, 0.0246322)',
  },
};

const baseColors = {
  green: '#228784',
  purple: '#AD3FBB',
  blue: '#596AD8',
  yellow: '#83790F',
  red: '#D80B60',
  grey: '#737878',
};

const brandColors: BrandColors = {
  brand: {
    primary: {
      base: generateColor(baseColors.green, 70),
      darker: generateColor(baseColors.green, 50),
      lighter: generateColor(baseColors.green, 85),
      darkest: generateColor(baseColors.green, 40),
    },
    secondary: {
      base: generateColor(baseColors.yellow, 70),
      darker: generateColor(baseColors.yellow, 50),
      lighter: generateColor(baseColors.yellow, 85),
      darkest: generateColor(baseColors.yellow, 30),
    },
    tertiary: {
      base: generateColor(baseColors.blue, 50),
      darker: generateColor(baseColors.blue, 30),
      lighter: generateColor(baseColors.blue, 85),
      darkest: generateColor(baseColors.blue, 20),
    },
    quaternary: {
      base: generateColor(baseColors.red, 70),
      darker: generateColor(baseColors.red, 50),
      lighter: generateColor(baseColors.red, 85),
      darkest: generateColor(baseColors.red, 30),
    },
  },
  grey: {
    2: '#F9FAFA',
    5: '#F1F3F3',
    10: '#E4E7E7',
    30: '#ADB8B8',
    50: '#839595',
    70: '#445757',
    85: '#1D3131',
    98: '#020606',
  },
};

const baseTheme: Theme = {
  ...commonTheme,
  colors: {
    base: baseColors,
    brand: brandColors.brand, // TODO remove this so we don't use brand colors directly
    tint: {
      base: '#32BCB8',
      tint1: '#8FF5F2',
      tint2: '#8CE5E1',
      tint3: '#F3FCFC',
    },
    text: {
      primary: '#082426',
      secondary: '#5C6F70',
      tertiary: '#B6BBBB',
    },
    ui: {
      primary: brandColors.brand.primary.base,
      highlight: '#D6AA67',
      warning: '#F0F165',
      error: '#E6434D',
      background:
        'radial-gradient(83.39% 147.91% at 8.16% -2%, #1A4A56 0%, #052125 100%)',
      tint1: '#8FF5F2',
      tint2: '#8CE5E1',
      tint3: '#F3FCFC',
      blockBackground: '#FCFCFC',
    },
    grey: brandColors.grey, // TODO remove this so we don't use brand colors directly
  },
  typography: {
    font: {
      base: {
        size: '12px',
        weight: '400',
        lineHeight: '16px',
      },
      extraSmall: {
        size: '10px',
        weight: '500',
        lineHeight: '16px',
      },
      small: {
        size: '12px',
        weight: '400',
        lineHeight: '16px',
      },
      standard: {
        size: '14.4px',
        weight: '400',
        lineHeight: '22px',
      },
      large: {
        size: '14.4px',
        weight: '400',
        lineHeight: '22px',
      },
      code: {
        fontFamily: 'Inconsolata',
        weight: '400',
        size: '14.4px',
        lineHeight: '18px',
      },
      extraSmallCaps: {
        size: '10px',
        weight: '500',
        lineHeight: '16px',
        letterSpacing: '0.06em',
        textTransform: 'uppercase',
      },
      extraSmallCapsHighlight: {
        size: '10px',
        weight: '700',
        lineHeight: '16px',
        letterSpacing: '0.06em',
        textTransform: 'uppercase',
      },
      smallEmphasis: {
        size: '12px',
        weight: '500',
        lineHeight: '16px',
      },
      standardHighlight: {
        size: '14.4px',
        weight: '600',
        lineHeight: '22px',
      },
      smallHighlight: {
        size: '12px',
        weight: '600',
        lineHeight: '16px',
      },
      title: {
        size: '17.28px',
        weight: '400',
        lineHeight: '24px',
      },
      heading1: {
        size: '29.86px',
        weight: '600',
        lineHeight: '36px',
      },
      heading2: {
        size: '24.88px',
        weight: '600',
        lineHeight: '32px',
      },
      heading3: {
        size: '20.74px',
        weight: '500',
        lineHeight: '28px',
      },
      heading4: {
        size: '14.4px',
        weight: '600',
        lineHeight: '20px',
      },
      heading5: {
        size: '12px',
        weight: '700',
        lineHeight: '20px',
        letterSpacing: '0.05em',
        textTransform: 'uppercase',
      },
    },
    lineHeight: '1em',
  },
};

const themeMap: ThemeMapType = {
  base: baseTheme,
  // Other themes can be defined partially:
  // <theme name>: {
  //   ...baseTheme,
  //   colors: {
  //     ... <et cetera>
  //    }
  //  },
};

// TODO replace this with direct access to "themes"
const getTheme = (key: keyof ThemeMapType): Theme =>
  themeMap[key] || themeMap.base;

const getThemeValue = (val: string) => parseFloat(val.replace('px', ''));
const getCommonTheme = (): CommonTheme => commonTheme;

export { getCommonTheme, getTheme, getThemeValue, themeMap };
