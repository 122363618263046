import React from 'react';
import { t } from '@lingui/macro';
import { useNotifications } from 'ui/common/notifications/useNotifications';
import { useAppDispatch } from 'app/hooks';
import {
  usePostSnapshotCreateMutation,
  usePostSnapshotRestoreByUuidMutation,
  usePutSnapshotSummaryUpdateByUuidMutation,
} from 'app/apiGenerated/generatedApi';
import { versionHistoryActions } from 'app/slices/versionHistorySlice';
import {
  PostSnapshotRestoreByUuidApiArg,
  PostSnapshotCreateApiArg,
  PutSnapshotSummaryUpdateByUuidApiArg,
} from 'app/apiGenerated/generatedApiTypes';
import { modelMetadataActions } from 'app/slices/modelMetadataSlice';

export function useDiagramVersionActions() {
  const dispatch = useAppDispatch();

  const { showCompletion, createShowError } = useNotifications();

  const [callUpdateDiagramVersionApi] =
    usePutSnapshotSummaryUpdateByUuidMutation();

  const [callCreateDiagramVersionApi] = usePostSnapshotCreateMutation();

  const [callRestoreDiagramVersionApi] = usePostSnapshotRestoreByUuidMutation();

  const updateDiagramVersion = React.useCallback(
    (request: PutSnapshotSummaryUpdateByUuidApiArg) => {
      callUpdateDiagramVersionApi(request)
        .unwrap()
        .catch(
          createShowError(
            t({
              id: 'VersionHistoryApi.updateDiagramVersionError',
              message: 'Unable to update Diagram version.',
            }),
          ),
        )
        .finally(() => {
          // If there is a failure, make sure we clear out our optimistic updates
          // in the version graph client state.
          dispatch(versionHistoryActions.requestDiagramVersions());
        });
    },
    [callUpdateDiagramVersionApi, dispatch, createShowError],
  );

  const createDiagramVersion = React.useCallback(
    (request: PostSnapshotCreateApiArg) => {
      callCreateDiagramVersionApi(request)
        .unwrap()
        .catch(
          createShowError(
            t({
              id: 'VersionHistoryApi.createDiagramVersionError',
              message: 'Unable to create Diagram version.',
            }),
          ),
        )
        .finally(() => {
          // If there is a failure, make sure we clear out our optimistic updates
          // in the version graph client state.
          dispatch(versionHistoryActions.requestDiagramVersions());
        });
    },

    [callCreateDiagramVersionApi, dispatch, createShowError],
  );

  const restoreDiagramVersion = React.useCallback(
    (request: PostSnapshotRestoreByUuidApiArg) => {
      dispatch(modelMetadataActions.startRestoreOperation());
      callRestoreDiagramVersionApi(request)
        .unwrap()
        .then(() => {
          dispatch(versionHistoryActions.requestDiagramVersions());

          showCompletion(
            t({
              id: 'VersionHistoryApi.restoreDiagramVersionSuccess',
              message: 'Diagram version restored',
            }),
          );
        })
        .catch(
          createShowError(
            t({
              id: 'VersionHistoryApi.restoreDiagramVersionError',
              message: 'Unable to restore Diagram version.',
            }),
          ),
        )
        .finally(() => {
          dispatch(modelMetadataActions.triggerOpenRestoredModel());
        });
    },
    [callRestoreDiagramVersionApi, dispatch, createShowError, showCompletion],
  );

  return {
    updateDiagramVersion,
    createDiagramVersion,
    restoreDiagramVersion,
  };
}
