/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Comment: React.FC<Props> = (props) => (
  <svg viewBox="0 0 24 24" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M9.48177 17.7606C11.5 18.0933 13.2384 18.1987 14.9998 17.6157C16.8033 17.0187 18.2789 15.9045 19.151 14.4817C20.0232 13.0586 20.2317 11.4244 19.738 9.88487C19.2443 8.34543 18.0821 7.00557 16.4682 6.11632C14.8547 5.22723 12.9006 4.84968 10.9714 5.05435C9.04226 5.25903 7.2704 6.03191 5.9873 7.22828C4.70379 8.42504 3.99766 9.96318 4.00001 11.5546C4.00203 12.9266 4.53051 13.7579 5.50091 14.8642L5.50002 14.8656L5.50408 14.8678C5.73306 15.1285 6.00003 15.4102 6.00003 15.7521C6.00003 16.1309 5.84109 17.1095 5.4697 17.5147C5.44516 17.5415 5.4141 17.5768 5.38351 17.6175C5.3637 17.6438 5.29585 17.734 5.24881 17.8636C5.22513 17.9288 5.17907 18.0771 5.19962 18.2689C5.22458 18.5018 5.34052 18.7207 5.5303 18.8593C5.68701 18.9738 5.83931 18.9936 5.90798 18.9983C5.98557 19.0036 6.04853 18.9951 6.0838 18.989C6.19739 18.9695 6.30025 18.9233 6.33731 18.9066L6.3401 18.9054C6.39652 18.8801 6.4591 18.849 6.52032 18.8179C6.59201 18.7814 6.67028 18.7405 6.75502 18.6962C6.97539 18.581 7.23954 18.443 7.54547 18.3C7.96462 18.104 8.41691 17.9211 8.8371 17.8175C9.11277 17.7496 9.32056 17.7411 9.48177 17.7606ZM15.792 7.57726C14.4506 6.8381 12.7841 6.50642 11.1166 6.68334C9.44668 6.8605 7.97776 7.52522 6.96178 8.47253C5.95485 9.4114 5.49848 10.517 5.5 11.552C5.50134 12.4605 6 13 6.62561 13.7791C7.09855 14.368 7.50003 14.9297 7.50003 15.7521C7.50003 16.5745 7.495 16.4587 7.48504 16.5625C7.80967 16.4297 8.15802 16.307 8.50671 16.2211C9.00745 16.0977 9.52482 16.0956 9.90566 16.1888C11 16.4567 13.0842 16.5394 14.5643 16.0495C16.105 15.5395 17.2615 14.6203 17.9041 13.5719C18.5291 12.5521 18.6515 11.4525 18.3221 10.4254C17.9823 9.36576 17.1358 8.3177 15.792 7.57726Z"
      fill={props.fill}
    />
  </svg>
);

Comment.defaultProps = {
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Comment;
