import styled from '@emotion/styled/macro';
import { t } from '@lingui/macro';
import { Project } from 'app/apiTransformers/convertAPIProjectToProject';
import React from 'react';
import { Link } from 'react-router-dom';
import { Requirements } from 'ui/common/Icons/Standard';
import { Small } from 'ui/common/typography/Typography';
import {
  ProjectRightSidebarHeading,
  Section,
  SectionH4,
} from './ProjectRightSidebar';

const SectionItems = styled.div`
  > a {
    color: inherit;
    text-decoration: inherit;
  }
`;

const SectionItemWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0 ${({ theme }) => theme.spacing.normal};
  > svg:first-of-type {
    margin-right: ${({ theme }) => theme.spacing.small};
  }
`;

type ItemProps = {
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  /** Icon to be placed before the text. */
  Icon: React.FC<any>;

  children: React.ReactNode | string;
};

const SectionItem: React.FC<ItemProps> = ({ Icon, children, onClick }) => (
  <SectionItemWrapper onClick={onClick}>
    {Icon && <Icon />}
    {children}
  </SectionItemWrapper>
);

const ToolsSection = ({ project }: { project: Project }) => (
  <Section>
    <ProjectRightSidebarHeading>
      <SectionH4>
        {t({
          id: 'dashboard.rightSidebar.tools.title',
          message: 'Tools',
        })}
      </SectionH4>
    </ProjectRightSidebarHeading>

    <SectionItems>
      <Link to={`/projects/${project.uuid}/requirements`}>
        <SectionItem Icon={Requirements}>
          <Small>Requirements Manager</Small>
        </SectionItem>
      </Link>
    </SectionItems>
  </Section>
);
export default ToolsSection;
