/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  className?: string;
}

const Seconds: React.FC<Props> = (props) => (
  <svg viewBox="0 0 24 24" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.0856 16.988C11.5096 16.988 11.0008 16.9016 10.5592 16.7288C10.1272 16.556 9.79119 16.3208 9.55119 16.0232C9.31119 15.716 9.19119 15.38 9.19119 15.0152V14.8568C9.19119 14.7608 9.23919 14.7128 9.33519 14.7128H10.372C10.468 14.7128 10.516 14.7608 10.516 14.8568V14.9288C10.516 15.1976 10.66 15.4328 10.948 15.6344C11.2456 15.836 11.62 15.9368 12.0712 15.9368C12.5032 15.9368 12.8536 15.8456 13.1224 15.6632C13.3912 15.4712 13.5256 15.2312 13.5256 14.9432C13.5256 14.6648 13.4008 14.4584 13.1512 14.324C12.9016 14.18 12.4984 14.0312 11.9416 13.8776C11.3944 13.724 10.9336 13.5704 10.5592 13.4168C10.1944 13.2536 9.88239 13.028 9.62319 12.74C9.37359 12.4424 9.24879 12.0632 9.24879 11.6024C9.24879 10.9592 9.49839 10.4504 9.99759 10.076C10.5064 9.692 11.1784 9.5 12.0136 9.5C12.5704 9.5 13.06 9.5912 13.4824 9.7736C13.9144 9.9464 14.2456 10.1912 14.476 10.508C14.7064 10.8248 14.8216 11.18 14.8216 11.5736V11.6168C14.8216 11.7128 14.7736 11.7608 14.6776 11.7608H13.6696C13.5736 11.7608 13.5256 11.7128 13.5256 11.6168V11.5736C13.5256 11.2952 13.3864 11.0648 13.108 10.8824C12.8392 10.6904 12.4696 10.5944 11.9992 10.5944C11.5768 10.5944 11.2312 10.676 10.9624 10.8392C10.7032 11.0024 10.5736 11.2232 10.5736 11.5016C10.5736 11.78 10.6984 11.9912 10.948 12.1352C11.2072 12.2792 11.62 12.4328 12.1864 12.596C12.7432 12.7496 13.204 12.9032 13.5688 13.0568C13.9432 13.2008 14.26 13.4216 14.5192 13.7192C14.7784 14.0072 14.908 14.3864 14.908 14.8568C14.908 15.5096 14.6488 16.028 14.1304 16.412C13.6216 16.796 12.94 16.988 12.0856 16.988Z"
      fill={props.fill}
    />
  </svg>
);

Seconds.defaultProps = {
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  className: '',
};

export default Seconds;
