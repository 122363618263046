import React from 'react';
import { useAppDispatch } from 'app/hooks';
import { t } from '@lingui/macro';
import { useNotifications } from 'ui/common/notifications/useNotifications';
import { submodelsActions } from 'app/slices/submodelsSlice';
import { usePostSubmodelsFetchMutation } from 'app/apiGenerated/generatedApi';
import { SubmodelFetchItem } from 'app/apiGenerated/generatedApiTypes';
import { VersionedSubmodelsResponse } from 'app/apiTransformers/convertPostSubmodelsFetch';

export function useVersionedSubmodels() {
  const dispatch = useAppDispatch();

  const [fetchVersionedSubmodels] = usePostSubmodelsFetchMutation();

  const { showError } = useNotifications();

  const fetchSubmodels = React.useCallback(
    (submodelsToFetch: SubmodelFetchItem[]) => {
      if (submodelsToFetch.length === 0) {
        return;
      }

      fetchVersionedSubmodels({
        submodelFetchRequest: {
          submodels: submodelsToFetch,
        },
      })
        .unwrap()
        .then(
          ({ submodelItems, notFoundItems }: VersionedSubmodelsResponse) => {
            if (notFoundItems && notFoundItems.length > 0) {
              // Show the first error as a notification so the user knows that viewing one of their submodels
              // might not work right.
              // Only post one notification because this is all the notification component is able to display.
              showError(
                t({
                  id: 'submodelApi.unableToFetchSubmodel',
                  message: 'Unable to fetch submodel.',
                }),
                notFoundItems[0].reason,
              );

              // Write out more detailed errors to the console that include all not found items.
              notFoundItems.forEach((notFoundItem) => {
                console.error(
                  `Submodel version not found submodelId=${notFoundItem.submodel_uuid} versionId=${notFoundItem.version}: ${notFoundItem.reason}`,
                );
              });
            }
            dispatch(
              submodelsActions.setSubmodels({
                submodelItems,
                notFoundItems,
              }),
            );
          },
        )
        .catch((error) => {
          showError(
            t({
              id: 'submodelApi.unableToFetchSubmodels',
              message: 'Unable to fetch submodels.',
            }),
            error,
          );

          dispatch(
            submodelsActions.onFetchingSubmodelsFailed(submodelsToFetch),
          );
        });

      dispatch(submodelsActions.onStartFetchingSubmodels(submodelsToFetch));
    },
    [dispatch, fetchVersionedSubmodels, showError],
  );

  return {
    fetchSubmodels,
  };
}
