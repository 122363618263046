/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const MatrixoperatorTranspose: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M23.4921 8.64801C23.4681 8.61601 23.4361 8.60001 23.3961 8.60001H17.5521C17.5121 8.60001 17.4761 8.61601 17.4441 8.64801C17.4201 8.67201 17.4081 8.70401 17.4081 8.74401V9.66801C17.4081 9.70801 17.4201 9.74401 17.4441 9.77601C17.4761 9.80001 17.5121 9.81201 17.5521 9.81201H19.6761C19.7161 9.81201 19.7361 9.83201 19.7361 9.87201V16.856C19.7361 16.896 19.7481 16.932 19.7721 16.964C19.8041 16.988 19.8401 17 19.8801 17H20.9841C21.0241 17 21.0561 16.988 21.0801 16.964C21.1121 16.932 21.1281 16.896 21.1281 16.856V9.87201C21.1281 9.83201 21.1481 9.81201 21.1881 9.81201H23.3961C23.4361 9.81201 23.4681 9.80001 23.4921 9.77601C23.5241 9.74401 23.5401 9.70801 23.5401 9.66801V8.74401C23.5401 8.70401 23.5241 8.67201 23.4921 8.64801Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M15.4161 21.86C15.4441 21.9533 15.5047 22 15.5981 22H16.9981C17.1007 22 17.1521 21.958 17.1521 21.874C17.1521 21.846 17.1474 21.8227 17.1381 21.804L14.0861 12.34C14.0581 12.2467 13.9974 12.2 13.9041 12.2H12.1541C12.0607 12.2 12.0001 12.2467 11.9721 12.34L8.93405 21.804C8.91538 21.8693 8.92005 21.9207 8.94805 21.958C8.97605 21.986 9.01805 22 9.07405 22H10.4741C10.5674 22 10.6281 21.9533 10.6561 21.86L11.1181 20.362C11.1367 20.3247 11.1601 20.306 11.1881 20.306H14.8841C14.9121 20.306 14.9354 20.3247 14.9541 20.362L15.4161 21.86ZM11.6081 19.032C11.5614 19.032 11.5427 19.004 11.5521 18.948L12.9801 14.258C12.9894 14.2207 13.0034 14.202 13.0221 14.202C13.0407 14.202 13.0547 14.2207 13.0641 14.258L14.5061 18.948C14.5247 19.004 14.5061 19.032 14.4501 19.032H11.6081Z"
      fill={props.fill}
    />
  </svg>
);

MatrixoperatorTranspose.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default MatrixoperatorTranspose;
