import styled from '@emotion/styled/macro';
import { t } from '@lingui/macro';
import { useDiagramVersionActions } from 'app/api/useDiagramVersionActions';
import { useDiagramVersions } from 'app/api/useDiagramVersions';
import {
  DiagramAutoSaveGroup,
  DiagramVersion,
} from 'app/apiTransformers/convertGetSnapshotReadAll';
import { useAppSelector } from 'app/hooks';
import React from 'react';
import { Spinner, SpinnerWrapper } from 'ui/common/Spinner';
import { SidebarScrollContainer } from 'ui/common/layout/appLayout';
import { useModelPermission } from 'ui/permission/useModelPermission';
import { v4 as uuid } from 'uuid';
import DiagramVersionAutoSaveGroup from './DiagramVersionAutoSaveGroup';
import DiagramVersionItem from './DiagramVersionItem';
import { useLatestModel } from './useLatestModel';

const PaddedSidebarScrollContainer = styled(SidebarScrollContainer)({
  paddingBottom: '60px',
});

interface Props {
  versionSearchString: string;
  projectId: string;
  modelId: string;
  versionId?: string;
}

const DiagramVersionList: React.FC<Props> = ({
  versionSearchString,
  projectId,
  modelId,
  versionId,
}) => {
  const { isLoading, diagramVersions } = useDiagramVersions(modelId);
  const { updateDiagramVersion, createDiagramVersion, restoreDiagramVersion } =
    useDiagramVersionActions();

  const lowerCaseVersionSearchString = versionSearchString.toLowerCase();

  const [currentVersion, setCurrentVersion] =
    React.useState<DiagramVersion | null>(null);

  const { canEditModelMetadata } = useModelPermission(
    projectId,
    modelId,
    versionId,
  );

  const { latestEditId } = useLatestModel(projectId, modelId, versionId);

  const kind = useAppSelector((state) => state.submodels.topLevelModelType);

  const restoreVersion = React.useCallback(
    (modelUuid: string, versionUuid: string) => {
      if (kind) {
        restoreDiagramVersion({
          modelUuid,
          snapshotUuid: versionUuid,
          snapshotRestoreRequest: {},
          kind,
        });
      }
    },
    [restoreDiagramVersion, kind],
  );

  const bookmarkVersion = React.useCallback(
    (modelUuid: string, editId: string | number, newVersionName: string) => {
      if (kind) {
        createDiagramVersion({
          modelUuid,
          snapshotCreateRequest: {
            name: newVersionName,
            version: typeof editId === 'number' ? editId : undefined,
            edit_id: typeof editId === 'string' ? editId : undefined,
          },
          kind,
        });
      }
    },
    [createDiagramVersion, kind],
  );

  const renameVersion = React.useCallback(
    (modelUuid: string, versionUuid: string, newVersionName: string) => {
      if (kind) {
        updateDiagramVersion({
          modelUuid,
          snapshotUuid: versionUuid,
          snapshotSummaryUpdateRequest: {
            name: newVersionName,
          },
          kind,
        });
      }
    },
    [updateDiagramVersion, kind],
  );

  // Update the current version when it changes.
  React.useEffect(() => {
    if (modelId && latestEditId && kind) {
      if (latestEditId !== currentVersion?.editId) {
        setCurrentVersion({
          uuid: uuid(),
          model_uuid: modelId,
          name: t({
            id: 'diagramVersionHistory.workingVersion.displayName',
            message: 'Working version',
          }),
          createdAt: '',
          editId: latestEditId,
          source: 'edit',
          authorUuid: '',
          authorDisplayName: '',
          authorProfileImageUrl: '',
          isTagged: false,
        });
      }
    }
  }, [modelId, latestEditId, currentVersion, kind]);

  if (isLoading || !currentVersion) {
    return (
      <SpinnerWrapper>
        <Spinner />
      </SpinnerWrapper>
    );
  }

  return (
    <PaddedSidebarScrollContainer>
      {/* Current version */}
      {!versionSearchString && (
        // Use the next version as part of the key to ensure that the optimistic
        // update for bookmarking the working version gets cleared
        // when the new version is created
        <DiagramVersionItem
          key={`${currentVersion.uuid}-${diagramVersions[0]?.uuid}`}
          version={currentVersion}
          bookmarkVersion={bookmarkVersion}
          isWorkingVersion
          canEditModelMetadata={canEditModelMetadata}
          projectId={projectId}
          urlModelId={modelId}
          urlVersionId={versionId}
        />
      )}

      {/* Bookmarked and auto-saved versions */}
      {diagramVersions.map((version) => {
        if ((version as any).autoSaveVersions) {
          if (versionSearchString) return null;

          return (
            <DiagramVersionAutoSaveGroup
              key={`${version.uuid}-group`}
              versionGroup={version as DiagramAutoSaveGroup}
              restoreVersion={restoreVersion}
              renameVersion={renameVersion}
              canEditModelMetadata={canEditModelMetadata}
              projectId={projectId}
              urlModelId={modelId}
              urlVersionId={versionId}
            />
          );
        }

        if (
          versionSearchString &&
          !version.name.toLowerCase().includes(lowerCaseVersionSearchString)
        )
          return null;

        return (
          <DiagramVersionItem
            key={version.uuid}
            version={version as DiagramVersion}
            restoreVersion={restoreVersion}
            renameVersion={renameVersion}
            canEditModelMetadata={canEditModelMetadata}
            projectId={projectId}
            urlModelId={modelId}
            urlVersionId={versionId}
          />
        );
      })}
    </PaddedSidebarScrollContainer>
  );
};

export default DiagramVersionList;
