import {
  Agent,
  ChatMessage,
  ChatMessageContent,
  ToolCall,
} from 'app/third_party_types/chat-types';
import React from 'react';
import { usePlotCache } from './usePlotCache';

export type ChatMessageEdit = {
  content?: ChatMessageContent[];
  toolCalls?: ToolCall[];
};

export interface IChatContext {
  isCurrentlyCompletingRef: React.MutableRefObject<boolean>;
  isCurrentlyCompleting: boolean;
  agentRef: React.MutableRefObject<Agent>;
  messages: ChatMessage[];
  plots: string[];
  editIds: React.MutableRefObject<Map<string, number>>; // used for sequential model updates in chat where we don't want to wait on redux to update states
  setMessages: (messages: ChatMessage[]) => void;
  addMessage: (message: ChatMessage) => void;
  removeMessage: (index: number) => void;
  removeMessages: (indexes: number[]) => void;
  removeAllMessages: () => void;
  editMessage: (edit: ChatMessageEdit, index?: number) => void;
  addPlot: (plot: string) => number;
  removePlot: (index: number) => void;
  setPlots: (plots: string[]) => void;
  setAgent: (agentId: Agent) => void;
  setIsCurrentlyCompleting: (isCompleting: boolean) => void;
}

const ChatContext = React.createContext<IChatContext>({
  isCurrentlyCompletingRef: { current: false },
  isCurrentlyCompleting: false,
  agentRef: { current: Agent.Main },
  messages: [],
  plots: [],
  editIds: { current: new Map() },
  setMessages: () => {},
  addMessage: () => {},
  removeMessage: () => {},
  removeMessages: () => {},
  removeAllMessages: () => {},
  editMessage: () => {},
  addPlot: () => 0,
  removePlot: () => {},
  setPlots: () => {},
  setAgent: () => {},
  setIsCurrentlyCompleting: () => {},
});

interface ChatContextProviderProps {
  children: React.ReactNode;
}

export const ChatContextProvider: React.FC<ChatContextProviderProps> = ({
  children,
}) => {
  const isCurrentlyCompletingRef = React.useRef<boolean>(false);
  const [isCurrentlyCompleting, setIsCurrentlyCompletingState] =
    React.useState<boolean>(isCurrentlyCompletingRef.current);
  const [messages, setMessages] = React.useState<ChatMessage[]>([]);
  const { plots, addPlot, removePlot, setPlots } = usePlotCache();
  const agentRef = React.useRef(Agent.Main);
  const editIds = React.useRef(new Map());

  const setAgent = React.useCallback((agentId: Agent) => {
    agentRef.current = agentId;
  }, []);

  const addMessage = React.useCallback((message: ChatMessage) => {
    setMessages((prevMessages) => [...prevMessages, message]);
  }, []);

  const removeMessage = React.useCallback((index: number) => {
    setMessages((prevMessages) => prevMessages.filter((_, i) => i !== index));
  }, []);

  const removeMessages = React.useCallback((indexes: number[]) => {
    setMessages((prevMessages) =>
      prevMessages.filter((_, i) => !indexes.includes(i)),
    );
  }, []);

  const removeAllMessages = React.useCallback(() => {
    setMessages([]);
  }, []);

  const editMessage = React.useCallback(
    (edit: ChatMessageEdit, index?: number) => {
      setMessages((prevMessages) => {
        const msgIdx = index ?? prevMessages.length - 1;
        const oldMessage = prevMessages[msgIdx];
        const newMessages = [...prevMessages];
        newMessages[msgIdx] = {
          ...oldMessage,
          ...edit,
        };
        return newMessages;
      });
    },
    [],
  );

  const setIsCurrentlyCompleting = React.useCallback(
    (isCompleting: boolean) => {
      isCurrentlyCompletingRef.current = isCompleting;
      setIsCurrentlyCompletingState(isCompleting);
    },
    [],
  );

  const contextValue = React.useMemo(
    () => ({
      agentRef,
      messages,
      plots,
      isCurrentlyCompletingRef,
      isCurrentlyCompleting,
      editIds,
      setMessages,
      addMessage,
      removeMessage,
      removeMessages,
      removeAllMessages,
      editMessage,
      addPlot,
      removePlot,
      setPlots,
      setAgent,
      setIsCurrentlyCompleting,
    }),
    [
      agentRef,
      messages,
      plots,
      isCurrentlyCompletingRef,
      isCurrentlyCompleting,
      editIds,
      setMessages,
      addMessage,
      removeMessage,
      removeMessages,
      removeAllMessages,
      editMessage,
      addPlot,
      removePlot,
      setPlots,
      setAgent,
      setIsCurrentlyCompleting,
    ],
  );
  return (
    <ChatContext.Provider value={contextValue}>{children}</ChatContext.Provider>
  );
};

export const useChatContext = () => React.useContext(ChatContext);
