import styled from '@emotion/styled/macro';
import { t } from '@lingui/macro';
import React from 'react';
import { TooltipRenderProps } from 'react-joyride';
import Button from 'ui/common/Button/Button';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import { ArrowRight, Check, Close } from 'ui/common/Icons/Standard';
import { Standard, Title } from 'ui/common/typography/Typography';

const TooltipHeader = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
});

const TooltipBody = styled.div<{ withDrop?: boolean }>(
  ({ theme, withDrop }) => ({
    background: 'white',
    padding: theme.spacing.large,
    width: '300px',
    borderRadius: '2px',
    filter: withDrop
      ? 'drop-shadow(0px 19px 30px rgba(0, 0, 0, 0.09)) drop-shadow(0px 7.09px 14px rgba(0, 0, 0, 0.0425)) drop-shadow(0px 3.33px 3.9px rgba(0, 0, 0, 0.0225))'
      : '',
  }),
);

const TooltipTitle = styled(Title)(({ theme }) => ({
  marginBottom: theme.spacing.large,
}));

const FooterStepTracker = styled.div({
  width: '100%',
  position: 'relative',
  height: '24px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

const RightButton = styled(Button)({
  position: 'absolute',
  right: 0,
});

const LeftButton = styled(Button)({
  position: 'absolute',
  left: 0,
});

const TooltipFooter = styled.div<{ separate: boolean; onRight?: boolean }>(
  ({ theme, separate = true, onRight }) => ({
    display: 'flex',
    justifyContent: onRight ? 'flex-end' : '',
    paddingTop: theme.spacing.large,
    borderTop: separate ? `1px solid ${theme.colors.grey[10]}` : '',
    color: theme.colors.text.tertiary,
  }),
);

export const PseudoOnboardingTooltip = ({
  title,
  onClose,
  children,
}: React.PropsWithChildren<{
  title: string;
  onClose: () => void;
}>) => (
  <TooltipBody withDrop>
    {title && (
      <TooltipHeader>
        <TooltipTitle>{title}</TooltipTitle>
        <Button
          onClick={onClose}
          variant={ButtonVariants.LargeTertiary}
          Icon={Close}
        />
      </TooltipHeader>
    )}
    <Standard>{children}</Standard>
    <TooltipFooter separate={false} onRight>
      <Button
        onClick={onClose}
        variant={ButtonVariants.SmallPrimary}
        Icon={Check}>
        {t({ id: 'pseudoOnboarding.gotit', message: 'Got it' })}
      </Button>
    </TooltipFooter>
  </TooltipBody>
);

const PageIndicator = ({
  pageCount,
  current,
}: {
  pageCount: number;
  current: number;
}) => (
  <div>
    {t({
      id: 'tour.onboarding.stepCounts',
      message: '{current} of {total}',
      values: { current, total: pageCount },
    })}
  </div>
);

const OnboardingTooltip: React.FC<TooltipRenderProps> = ({
  step,
  primaryProps,
  skipProps,
  tooltipProps,
  closeProps,
  index,
  size,
}) => (
  <TooltipBody {...tooltipProps}>
    {step.title && (
      <TooltipHeader>
        <TooltipTitle>{step.title}</TooltipTitle>
      </TooltipHeader>
    )}
    <Standard>{step.content}</Standard>
    <TooltipFooter separate={false}>
      <FooterStepTracker>
        {index === 0 && size > 1 && (
          <LeftButton variant={ButtonVariants.SmallSecondary} {...skipProps}>
            {t({ id: 'tour.onboarding.skip', message: 'Skip' })}
          </LeftButton>
        )}
        {size > 1 && <PageIndicator pageCount={size} current={index + 1} />}
        {index === size - 1 ? (
          <RightButton
            {...closeProps}
            variant={ButtonVariants.SmallPrimary}
            Icon={Check}>
            {t({ id: 'tour.onboarding.gotIt', message: 'Got it' })}
          </RightButton>
        ) : (
          <RightButton
            {...primaryProps}
            variant={ButtonVariants.SmallPrimary}
            Icon={ArrowRight}>
            {t({ id: 'tour.onboarding.goNext', message: 'Next' })}
          </RightButton>
        )}
      </FooterStepTracker>
    </TooltipFooter>
  </TooltipBody>
);

export default OnboardingTooltip;
