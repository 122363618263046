/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const MatrixoperatorConcatenationhorizontal: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.75 10C3.75 9.30964 4.30964 8.75 5 8.75H7.5C7.91421 8.75 8.25 9.08579 8.25 9.5C8.25 9.91421 7.91421 10.25 7.5 10.25H5.25V22.75H7.5C7.91421 22.75 8.25 23.0858 8.25 23.5C8.25 23.9142 7.91421 24.25 7.5 24.25H5C4.30964 24.25 3.75 23.6904 3.75 23V10Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M13.916 21.36C13.944 21.4533 14.0047 21.5 14.098 21.5H15.498C15.6007 21.5 15.652 21.458 15.652 21.374C15.652 21.346 15.6473 21.3227 15.638 21.304L12.586 11.84C12.558 11.7467 12.4973 11.7 12.404 11.7H10.654C10.5607 11.7 10.5 11.7467 10.472 11.84L7.434 21.304C7.41533 21.3693 7.42 21.4207 7.448 21.458C7.476 21.486 7.518 21.5 7.574 21.5H8.974C9.06733 21.5 9.128 21.4533 9.156 21.36L9.618 19.862C9.63667 19.8247 9.66 19.806 9.688 19.806H13.384C13.412 19.806 13.4353 19.8247 13.454 19.862L13.916 21.36ZM10.108 18.532C10.0613 18.532 10.0427 18.504 10.052 18.448L11.48 13.758C11.4893 13.7207 11.5033 13.702 11.522 13.702C11.5407 13.702 11.5547 13.7207 11.564 13.758L13.006 18.448C13.0247 18.504 13.006 18.532 12.95 18.532H10.108Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M23.496 16.474C23.44 16.446 23.44 16.418 23.496 16.39C24.3547 16.0073 24.784 15.326 24.784 14.346C24.784 13.4593 24.5087 12.7967 23.958 12.358C23.4073 11.9193 22.6233 11.7 21.606 11.7H18.19C18.1433 11.7 18.1013 11.7187 18.064 11.756C18.036 11.784 18.022 11.8213 18.022 11.868V21.332C18.022 21.3787 18.036 21.4207 18.064 21.458C18.1013 21.486 18.1433 21.5 18.19 21.5H21.718C22.67 21.5 23.44 21.2713 24.028 20.814C24.6253 20.3473 24.924 19.6707 24.924 18.784C24.924 17.692 24.448 16.922 23.496 16.474ZM19.646 13.17C19.646 13.1233 19.6693 13.1 19.716 13.1H21.606C22.1007 13.1 22.488 13.226 22.768 13.478C23.0573 13.7207 23.202 14.0567 23.202 14.486C23.202 14.906 23.0573 15.2373 22.768 15.48C22.488 15.7133 22.1007 15.83 21.606 15.83H19.716C19.6693 15.83 19.646 15.8067 19.646 15.76V13.17ZM22.866 19.694C22.586 19.9553 22.2033 20.086 21.718 20.086H19.716C19.6693 20.086 19.646 20.0627 19.646 20.016V17.244C19.646 17.1973 19.6693 17.174 19.716 17.174H21.69C22.1847 17.174 22.5767 17.3047 22.866 17.566C23.1553 17.818 23.3 18.1727 23.3 18.63C23.3 19.078 23.1553 19.4327 22.866 19.694Z"
      fill={props.fill}
    />
    <path
      d="M27.5 8.75C28.1904 8.75 28.75 9.30964 28.75 10V23C28.75 23.6904 28.1904 24.25 27.5 24.25H25C24.5858 24.25 24.25 23.9142 24.25 23.5C24.25 23.0858 24.5858 22.75 25 22.75H27.25V10.25H25C24.5858 10.25 24.25 9.91421 24.25 9.5C24.25 9.08579 24.5858 8.75 25 8.75H27.5Z"
      fill={props.fill}
    />
  </svg>
);

MatrixoperatorConcatenationhorizontal.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default MatrixoperatorConcatenationhorizontal;
