import { defaultModelConfiguration } from '@collimator/model-schemas-ts';
import styled from '@emotion/styled/macro';
import { t } from '@lingui/macro';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { modelActions } from 'app/slices/modelSlice';
import {
  ModelConfigurationSolverType,
  ModelConfigurationType,
  buildNumericalChange,
} from 'app/utils/modelDataUtils';
import React from 'react';
import { TextInputAlign } from 'ui/common/Input/inputTypes';
import {
  nonNegativeNumberRules,
  positiveNumberRules,
} from 'ui/common/Input/inputValidation';
import SectionHeading from 'ui/common/Inputs/SectionHeading';
import SelectInput from 'ui/common/SelectInput';
import {
  DetailInputRowsSection,
  DetailsDoubleRow,
  DetailsInput,
  DetailsLabel,
  DetailsSingleRow,
} from 'ui/modelEditor/DetailsComponents';
import { useModelPermission } from 'ui/permission/useModelPermission';
import { useAppParams } from 'util/useAppParams';

const ParameterWrapper = styled.div`
  margin: ${({ theme }) => `${theme.spacing.small}`} 0;
`;

const methodOptions = [
  {
    value: 'auto',
    label: 'Auto',
  },
  {
    value: 'non-stiff',
    label: 'Non-stiff',
  },
  {
    value: 'stiff',
    label: 'Stiff',
  },
];

const SimulationSolverDetails: React.FC = () => {
  const dispatch = useAppDispatch();

  const { projectId, modelId, versionId } = useAppParams();
  const { canEditCurrentModelVersion } = useModelPermission(
    projectId,
    modelId,
    versionId,
  );

  const configuration = useAppSelector(
    (state) => state.model.present.configuration,
  );

  const onChangeNumericalConfigValue = (
    name: ModelConfigurationType,
    value: string | undefined,
  ) =>
    dispatch(
      modelActions.changeModelConfigurationValue({
        name,
        value: value !== undefined ? buildNumericalChange(value) : undefined,
      }),
    );

  const onChangeNumericalConfigSolverValue = (
    name: ModelConfigurationSolverType,
    value: string | undefined,
  ) =>
    dispatch(
      modelActions.changeModelConfigurationSolverValue({
        name,
        value: buildNumericalChange(value),
      }),
    );

  return (
    <>
      <SectionHeading testId="simulation-settings">
        {t({
          id: 'modelRenderer.simulationSolverDetails.discrete.heading',
          message: 'Discrete Solver',
        })}
      </SectionHeading>
      <DetailInputRowsSection>
        <ParameterWrapper>
          <DetailsSingleRow noMargin>
            <DetailsLabel stretch>
              {t({
                id: 'modelRenderer.simulationSolverDetails.discrete.globalDiscreteInterval.label',
                message: 'Global discrete interval',
              })}
            </DetailsLabel>
            <DetailsInput
              rightIconIsResetButton
              onSubmitValue={(value) =>
                onChangeNumericalConfigValue('sample_time', value)
              }
              onClickRightIcon={() =>
                onChangeNumericalConfigValue(
                  'sample_time',
                  String(defaultModelConfiguration.sample_time),
                )
              }
              value={configuration.sample_time ?? ''}
              defaultValue={defaultModelConfiguration.sample_time}
              align={TextInputAlign.Right}
              hasBorder
              grow
              validationRules={positiveNumberRules}
              disabled={!canEditCurrentModelVersion}
            />
          </DetailsSingleRow>
        </ParameterWrapper>
      </DetailInputRowsSection>
      <SectionHeading testId="simulation-settings">
        {t({
          id: 'modelRenderer.simulationSolverDetails.ode.heading',
          message: 'ODE Solver',
        })}
      </SectionHeading>
      <DetailInputRowsSection>
        <ParameterWrapper>
          <DetailsDoubleRow noMargin>
            <DetailsLabel>
              {t({
                id: 'modelRenderer.simulationSolverDetails.continuous.solverMethod.label',
                message: 'Solver method',
              })}
            </DetailsLabel>
            <SelectInput
              onSelectValue={(value) =>
                dispatch(
                  modelActions.changeModelConfigurationSolverValue({
                    name: 'method',
                    value,
                  }),
                )
              }
              currentValue={configuration.solver?.method}
              options={methodOptions}
              isDisabled={!canEditCurrentModelVersion}
            />
          </DetailsDoubleRow>
        </ParameterWrapper>
        <ParameterWrapper>
          <DetailsSingleRow noMargin>
            <DetailsLabel stretch>
              {t({
                id: 'modelRenderer.simulationSolverDetails.continuous.absoluteErrorTolerance.label',
                message: 'Absolute error tolerance',
              })}
            </DetailsLabel>
            <DetailsInput
              rightIconIsResetButton
              onSubmitValue={(value) =>
                onChangeNumericalConfigSolverValue('absolute_tolerance', value)
              }
              onClickRightIcon={() =>
                onChangeNumericalConfigSolverValue(
                  'absolute_tolerance',
                  String(defaultModelConfiguration.solver?.absolute_tolerance),
                )
              }
              value={configuration.solver?.absolute_tolerance ?? ''}
              defaultValue={
                defaultModelConfiguration.solver?.absolute_tolerance
              }
              align={TextInputAlign.Right}
              hasBorder
              grow
              validationRules={positiveNumberRules}
              disabled={!canEditCurrentModelVersion}
            />
          </DetailsSingleRow>
        </ParameterWrapper>
        <ParameterWrapper>
          <DetailsSingleRow noMargin>
            <DetailsLabel stretch>
              {t({
                id: 'modelRenderer.simulationSolverDetails.continuous.relativeErrorTolerance.label',
                message: 'Relative error tolerance',
              })}
            </DetailsLabel>
            <DetailsInput
              rightIconIsResetButton
              onSubmitValue={(value) =>
                onChangeNumericalConfigSolverValue('relative_tolerance', value)
              }
              onClickRightIcon={() =>
                onChangeNumericalConfigSolverValue(
                  'relative_tolerance',
                  String(defaultModelConfiguration.solver?.relative_tolerance),
                )
              }
              value={configuration.solver?.relative_tolerance ?? ''}
              defaultValue={
                defaultModelConfiguration.solver?.relative_tolerance
              }
              align={TextInputAlign.Right}
              hasBorder
              grow
              validationRules={positiveNumberRules}
              disabled={!canEditCurrentModelVersion}
            />
          </DetailsSingleRow>
        </ParameterWrapper>
        <ParameterWrapper>
          <DetailsSingleRow noMargin>
            <DetailsLabel stretch>
              {t({
                id: 'modelRenderer.simulationSolverDetails.continuous.minimumStepSize.label',
                message: 'Minimum step size',
              })}
            </DetailsLabel>
            <DetailsInput
              rightIconIsResetButton
              onSubmitValue={(value) =>
                onChangeNumericalConfigSolverValue('min_step', value)
              }
              onClickRightIcon={() =>
                onChangeNumericalConfigSolverValue(
                  'min_step',
                  String(defaultModelConfiguration.solver?.min_step),
                )
              }
              value={configuration.solver?.min_step ?? ''}
              defaultValue={defaultModelConfiguration.solver?.min_step}
              align={TextInputAlign.Right}
              hasBorder
              grow
              validationRules={nonNegativeNumberRules}
              disabled={!canEditCurrentModelVersion}
            />
          </DetailsSingleRow>
        </ParameterWrapper>
        <ParameterWrapper>
          <DetailsSingleRow noMargin>
            <DetailsLabel stretch>
              {t({
                id: 'modelRenderer.simulationSolverDetails.continuous.maximumStepSize.label',
                message: 'Maximum step size',
              })}
            </DetailsLabel>
            <DetailsInput
              rightIconIsResetButton
              onSubmitValue={(value) =>
                onChangeNumericalConfigSolverValue('max_step', value)
              }
              onClickRightIcon={() =>
                onChangeNumericalConfigSolverValue(
                  'max_step',
                  String(defaultModelConfiguration.solver?.max_step),
                )
              }
              value={configuration.solver?.max_step ?? ''}
              defaultValue={defaultModelConfiguration.solver?.max_step}
              align={TextInputAlign.Right}
              hasBorder
              grow
              validationRules={positiveNumberRules}
              disabled={!canEditCurrentModelVersion}
            />
          </DetailsSingleRow>
        </ParameterWrapper>
      </DetailInputRowsSection>
    </>
  );
};

export default SimulationSolverDetails;
