/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Crossproduct: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M28.2059 16.904C28.2059 16.1573 28.1206 15.512 27.9499 14.968C27.7259 14.264 27.3579 13.7147 26.8459 13.32C26.3339 12.9253 25.6779 12.728 24.8779 12.728C24.5152 12.728 24.1686 12.8027 23.8379 12.952C23.5179 13.0907 23.2406 13.2933 23.0059 13.56C22.9846 13.5813 22.9632 13.5867 22.9419 13.576C22.9312 13.5653 22.9259 13.5493 22.9259 13.528V9.96C22.9259 9.85334 22.8726 9.8 22.7659 9.8H21.5499C21.4432 9.8 21.3899 9.85334 21.3899 9.96V20.84C21.3899 20.9467 21.4432 21 21.5499 21H22.7659C22.8726 21 22.9259 20.9467 22.9259 20.84V20.312C22.9259 20.2907 22.9312 20.2747 22.9419 20.264C22.9632 20.2533 22.9846 20.2587 23.0059 20.28C23.2406 20.5467 23.5179 20.7547 23.8379 20.904C24.1579 21.0533 24.5046 21.128 24.8779 21.128C25.6459 21.128 26.2806 20.9413 26.7819 20.568C27.2939 20.184 27.6672 19.6453 27.9019 18.952C28.1046 18.3867 28.2059 17.704 28.2059 16.904ZM26.6539 16.936C26.6539 17.7573 26.5206 18.4187 26.2539 18.92C25.9446 19.5067 25.4379 19.8 24.7339 19.8C24.1152 19.8 23.6459 19.512 23.3259 18.936C23.0699 18.4667 22.9419 17.7947 22.9419 16.92C22.9419 16.0453 23.0806 15.3627 23.3579 14.872C23.6672 14.328 24.1206 14.056 24.7179 14.056C25.3579 14.056 25.8326 14.312 26.1419 14.824C26.4832 15.3573 26.6539 16.0613 26.6539 16.936Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M8.97404 13.08C8.49404 12.8453 7.92871 12.728 7.27804 12.728C6.39271 12.728 5.65671 12.9307 5.07004 13.336C4.48337 13.7307 4.14737 14.2427 4.06204 14.872C4.05137 14.936 4.09937 14.9787 4.20604 15L5.50204 15.176C5.59804 15.1867 5.66204 15.1387 5.69404 15.032C5.75804 14.7333 5.92871 14.4987 6.20604 14.328C6.49404 14.1467 6.86204 14.056 7.31004 14.056C7.86471 14.056 8.27537 14.1893 8.54204 14.456C8.81937 14.712 8.95804 15.0747 8.95804 15.544V15.976C8.95804 16.0187 8.93671 16.04 8.89404 16.04H7.10204C6.05671 16.04 5.24071 16.28 4.65404 16.76C4.07804 17.2293 3.79004 17.88 3.79004 18.712C3.79004 19.5227 4.04604 20.1307 4.55804 20.536C5.08071 20.9307 5.73671 21.128 6.52604 21.128C7.01671 21.128 7.45937 21.0533 7.85404 20.904C8.25937 20.744 8.60071 20.52 8.87804 20.232C8.93137 20.2 8.95804 20.2107 8.95804 20.264V20.84C8.95804 20.9467 9.01137 21 9.11804 21H10.334C10.4407 21 10.494 20.9467 10.494 20.84V15.432C10.494 14.9093 10.3607 14.4453 10.094 14.04C9.83804 13.624 9.46471 13.304 8.97404 13.08ZM8.33404 19.384C7.91804 19.6827 7.42737 19.832 6.86204 19.832C6.40337 19.832 6.03004 19.7307 5.74204 19.528C5.46471 19.3253 5.32604 19.0267 5.32604 18.632C5.32604 18.1947 5.51271 17.8533 5.88604 17.608C6.25937 17.3627 6.76071 17.24 7.39004 17.24H8.89404C8.93671 17.24 8.95804 17.2613 8.95804 17.304V18.248C8.95804 18.7067 8.75004 19.0853 8.33404 19.384Z"
      fill={props.fill}
    />
    <path
      d="M16 18.0084L17.9916 20L19 18.9916L17.0084 17L19 15.0084L17.9916 14L16 15.9916L14.0084 14L13 15.0084L14.9916 17L13 18.9916L14.0084 20L16 18.0084Z"
      fill={props.fill}
    />
  </svg>
);

Crossproduct.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Crossproduct;
