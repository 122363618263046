export function isSamePath(path1?: string[], path2?: string[]) {
  if (!path1 || !path2) {
    return false;
  }

  if (path1.length !== path2.length) {
    return false;
  }

  return path1.every(
    (pathElement, index): boolean => pathElement === path2[index],
  );
}

export function getSubpath(parentPath: string[], subpathParentNodeId: string) {
  if (!parentPath || !subpathParentNodeId) {
    return [];
  }

  const parentPathInSubmodel = [];
  let isWithinSubmodel = false;
  for (let i = 0; i < parentPath.length; i++) {
    const parentNodeId = parentPath[i];
    if (isWithinSubmodel) {
      parentPathInSubmodel.push(parentNodeId);
    } else if (parentNodeId === subpathParentNodeId) {
      isWithinSubmodel = true;
    }
  }
  return parentPathInSubmodel;
}
