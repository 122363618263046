import { API_BASE_URL } from 'app/config/globalApplicationConfig';
import React from 'react';

const POLL_INTERVAL_MS = 1000;

// RTK Query makes it practically impossible to fetch new logs with polling
// as it would return stale data from old queries. Disabling the cache
// (expiry 0s) yields other issues. So, using raw fetch for now.
async function callJobLogsFileGet(jobId: string, logFile: string) {
  const api = `${API_BASE_URL}/jobs/${jobId}/logs/${logFile}`;
  return fetch(api).then((res) => {
    if (res.ok && res.status === 200) return res.text();
    return undefined;
  });
}

export function useLiveLogs({
  logFile,
  jobId,
  skip,
}: {
  logFile: string;
  jobId?: string;
  skip?: boolean;
}) {
  const [data, setData] = React.useState<string | undefined>();
  const [dataJobId, setDataJobId] = React.useState<string | undefined>();
  const timerRef = React.useRef<any>(null);

  React.useEffect(() => {
    const fn = async () => {
      if (timerRef.current) clearTimeout(timerRef.current);
      if (skip || !jobId) return;

      const contents = await callJobLogsFileGet(jobId, logFile);
      setData(contents);
      setDataJobId(jobId);

      timerRef.current = setTimeout(fn, POLL_INTERVAL_MS);
    };
    fn();
  }, [jobId, logFile, skip]);

  React.useEffect(() => {
    if (skip) clearTimeout(timerRef.current);
  }, [skip]);

  React.useEffect(() => {
    if (!dataJobId) return;
    if (dataJobId !== jobId) setData(undefined);
  }, [jobId, dataJobId]);

  if (dataJobId === jobId) return { data };
  return { data: undefined };
}
