/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const BookmarkEmpty: React.FC<Props> = (props) => (
  <svg viewBox="0 0 24 24" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M8.75 6.25V17.6893L11.1161 15.3232C11.6043 14.8351 12.3957 14.8351 12.8839 15.3232L15.25 17.6893V6.25H8.75ZM7.25 6C7.25 5.30964 7.80964 4.75 8.5 4.75H15.5C16.1904 4.75 16.75 5.30964 16.75 6V18.2929C16.75 19.4065 15.4036 19.9642 14.6161 19.1768L12 16.5607L9.38388 19.1768C8.59643 19.9642 7.25 19.4065 7.25 18.2929V6Z"
      fill={props.fill}
    />
  </svg>
);

BookmarkEmpty.defaultProps = {
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default BookmarkEmpty;
