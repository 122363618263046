/* eslint-disable react/no-unescaped-entities */
import styled from '@emotion/styled/macro';
import { t, Trans } from '@lingui/macro';
import React from 'react';
import { Link } from 'react-router-dom';
import Button from 'ui/common/Button/Button';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import Input from 'ui/common/Input/Input';
import { validNameRules } from 'ui/common/Input/inputValidation';
import { ActionButtonContainer, ModalInputGroup } from 'ui/common/Modal/Modal';
import { useClerk } from '@clerk/clerk-react';
import { useModal } from 'ui/common/Modal/useModal';
import SelectInput from 'ui/common/SelectInput';
import { useAppDispatch } from 'app/hooks';
import { uiFlagsActions } from 'app/slices/uiFlagsSlice';
import { StripeLogo } from 'ui/common/Icons/Standard';
import { useTos } from './useTos';

const TosModalContents = styled.div`
  width: 368px;
  padding-top: ${({ theme }) => theme.spacing.normal};

  p {
    line-height: 1.6em;
    margin-bottom: ${({ theme }) => theme.spacing.large};
  }

  i {
    weight: 300;
  }

  a.dimcolor {
    color: ${({ theme }) => theme.colors.text.primary};
  }
`;

const WelcomeModal = ({
  closeModal,
  isExempt,
}: {
  closeModal: () => void;
  isExempt: boolean;
}) => {
  const dispatch = useAppDispatch();
  const { signOut } = useClerk();
  const onLogOut = () => {
    signOut();
  };

  const onContinue = () => {
    closeModal();
    dispatch(uiFlagsActions.setUIFlag({ onboardingStartedManually: true }));
  };

  return (
    <TosModalContents>
      <p>
        <Trans id="tosModal.AllGood">
          Your email address has been verified, and you're all set to start
          using Collimator.
        </Trans>
      </p>
      <p>
        <Trans id="tosModal.AnyQuestions">
          If you ever have any questions or issues, we're always available at
        </Trans>
        &nbsp;
        <a
          className="dimcolor"
          href="mailto:help@collimator.ai"
          rel="noreferrer"
          target="_blank">
          help@collimator.ai
        </a>
        .
      </p>
      <p>
        <Trans id="tosModal.enjoy">Enjoy!</Trans>
      </p>
      <p>
        <i>
          <Trans id="tosModal.collimatorTeam">The Collimator Team</Trans>
        </i>
      </p>
      <ActionButtonContainer>
        <Button variant={ButtonVariants.LargeSecondary} onClick={onLogOut}>
          <Trans id="tosModal.logout">Log out</Trans>
        </Button>
        <Button
          variant={ButtonVariants.LargePrimary}
          onClick={onContinue}
          Icon={isExempt ? undefined : StripeLogo}>
          {isExempt ? (
            <Trans id="tosModal.getStarted">Get started</Trans>
          ) : (
            <Trans id="tosModal.startTrialNormal">Start trial</Trans>
          )}
        </Button>
      </ActionButtonContainer>
    </TosModalContents>
  );
};

const InvalidStudentModal = ({ closeModal }: { closeModal: () => void }) => {
  const dispatch = useAppDispatch();
  const { signOut } = useClerk();
  const onLogOut = () => {
    signOut();
  };

  const onContinue = () => {
    closeModal();
    dispatch(uiFlagsActions.setUIFlag({ onboardingStartedManually: true }));
  };

  return (
    <TosModalContents>
      <p>
        <Trans id="tosModal.InvalidStudentStartApology">
          Sorry, our automatic email address verification didn’t recognize your
          email domain. We will review internally and grant access shortly. If
          you didn’t use your student email address, please log out and create a
          new account.
        </Trans>
      </p>
      <p>
        <Trans id="tosModal.InvalidStudentMeantime">
          In the meantime, feel free to use the trial of Collimator — you won’t
          be charged for access. For other issues, please
        </Trans>
        &nbsp;
        <a
          className="dimcolor"
          href="mailto:help@collimator.ai"
          rel="noreferrer"
          target="_blank">
          {t`contact us`}
        </a>
        .
      </p>
      <ActionButtonContainer>
        <Button variant={ButtonVariants.LargeSecondary} onClick={onLogOut}>
          <Trans id="tosModal.logout">Log out</Trans>
        </Button>
        <Button
          variant={ButtonVariants.LargePrimary}
          onClick={onContinue}
          Icon={StripeLogo}>
          <Trans id="tosModal.studentTrialStart">Start a free trial</Trans>
        </Button>
      </ActionButtonContainer>
    </TosModalContents>
  );
};

export const TermsOfServiceModal = ({
  closeModal,
  isExempt,
  markedSelfAsStudent,
}: {
  closeModal: () => void;
  isExempt: boolean;
  markedSelfAsStudent: boolean;
}) => {
  const { triggerModal } = useModal();
  const [sentAccept, setSentAccept] = React.useState(false);
  const { setAgreedTos } = useTos();
  const [organizationName, setOrganizationName] = React.useState('');

  const [isSelfDescribedStudent, setIsSelfDescribedStudent] =
    React.useState(markedSelfAsStudent);

  const advance = () => {
    setSentAccept(true);
    setAgreedTos(organizationName)
      .then(() => {
        if (isSelfDescribedStudent && !isExempt) {
          triggerModal(
            <InvalidStudentModal closeModal={closeModal} />,
            t`Email address unrecognized`,
            {
              disableNormalCloseControls: true,
            },
          );
        } else {
          triggerModal(
            <WelcomeModal closeModal={closeModal} isExempt={isExempt} />,
            t`Welcome to Collimator!`,
            { disableNormalCloseControls: true },
          );
        }
      })
      .catch(() => {
        setSentAccept(false);
      });
  };

  const { signOut } = useClerk();
  const onLogOut = () => {
    signOut();
  };

  return (
    <TosModalContents>
      <p>
        <Trans id="tosModal.beforeYouStart">
          Before you get started, we just need to let you know that your use of
          this application and associated services means you agree to our
        </Trans>
        &nbsp;
        <Link to="/tos" onClick={closeModal}>
          <Trans id="tosModal.tosLink">Terms of Service</Trans>
        </Link>
        .
      </p>
      <p>
        {t({
          id: 'tos.questionsOrIssues',
          message: `If you ever have any questions or issues, we're always available at `,
        })}
        &nbsp;
        <a href="mailto:help@collimator.ai" rel="noreferrer" target="_blank">
          help@collimator.ai
        </a>
        .
      </p>
      <ModalInputGroup>
        <SelectInput
          currentValue={isSelfDescribedStudent ? 'student' : 'pro'}
          onSelectValue={(newVal) => {
            if (newVal === 'student') setIsSelfDescribedStudent(true);
            else setIsSelfDescribedStudent(false);
          }}
          options={[
            { value: 'student', label: 'Student' },
            { value: 'pro', label: 'Professional' },
          ]}
        />
      </ModalInputGroup>
      <ModalInputGroup>
        <Input
          autoFocus
          hasBorder
          placeholder={
            isSelfDescribedStudent
              ? t({
                  message: 'University Name',
                  id: 'tosModal.universityNamePlaceholder',
                })
              : t({
                  message: 'Your Organization Name',
                  id: 'tosModal.organizationNamePlaceholder',
                })
          }
          onChangeText={setOrganizationName}
          value={organizationName}
          validationRules={validNameRules}
        />
      </ModalInputGroup>
      <ActionButtonContainer>
        <Button variant={ButtonVariants.LargeSecondary} onClick={onLogOut}>
          <Trans id="tosModal.logout">Log out</Trans>
        </Button>
        <Button
          variant={ButtonVariants.LargePrimary}
          onClick={advance}
          disabled={sentAccept || !organizationName.trim()}>
          <Trans id="tosModal.advance">Continue</Trans>
        </Button>
      </ActionButtonContainer>
    </TosModalContents>
  );
};
