import styled from '@emotion/styled/macro';
import { t } from '@lingui/macro';
import React from 'react';
import { SidebarScrollContainer } from 'ui/common/layout/appLayout';
import { SimulationDataContent } from 'ui/dataExplorer/simulationDataSidebar/SimulationDataContent';
import SectionSearch from 'ui/objectBrowser/sections/SectionSearch';

const SimulationDataScrollContainer = styled(SidebarScrollContainer)`
  overflow-x: auto;
  flex-direction: row;
`;

const SimulationDataContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

interface Props {
  projectId: string;
  explorationId: string;
  canEditProject: boolean;
}

export const SimulationDataSection: React.FC<Props> = ({
  projectId,
  explorationId,
  canEditProject,
}) => {
  const [searchString, setSearchString] = React.useState('');

  return (
    <>
      <SectionSearch
        placeholder={t({
          id: 'dataExplorer.simulationDataSection.searchPlaceholder',
          message: 'Search',
        })}
        onChangeText={setSearchString}
      />
      <SimulationDataScrollContainer>
        <SimulationDataContentContainer>
          <SimulationDataContent
            searchString={searchString}
            projectId={projectId}
            explorationId={explorationId}
            canEditProject={canEditProject}
          />
        </SimulationDataContentContainer>
      </SimulationDataScrollContainer>
    </>
  );
};
