/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  className?: string;
}

const RandomdistributionUniform: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path d="M2.25 25.75V6H1.75V26.25H30V25.75H2.25Z" fill={props.fill} />
    <path d="M6.25 25H3.75V15H6.25V25Z" fill={props.fill} />
    <path d="M8.25 25H10.75V15H8.25V25Z" fill={props.fill} />
    <path d="M15.25 25H12.75V15H15.25V25Z" fill={props.fill} />
    <path d="M17.25 25H19.75V15H17.25V25Z" fill={props.fill} />
    <path d="M24.25 25H21.75V15H24.25V25Z" fill={props.fill} />
    <path d="M26.25 25H28.75V15H26.25V25Z" fill={props.fill} />
  </svg>
);

RandomdistributionUniform.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  className: '',
};

export default RandomdistributionUniform;
