import { usePostDocumentationBlockSearchMutation } from 'app/apiGenerated/generatedApi';
import {
  GptFunction,
  ToolCompleteCallback,
} from 'app/chat/responseStreamParser';
import {
  ComputationBlockClass,
  DataType,
  Ports,
  PortsDefinition,
} from 'app/generated_types/ComputationBlockClass';
import React from 'react';
import BlockDocMap from './blockDocMap';

const getPortsDescription = (portsDefinition?: PortsDefinition) => {
  if (!portsDefinition) {
    return undefined;
  }

  if (portsDefinition.dynamic !== undefined) {
    const { min_count } = portsDefinition.dynamic;
    return `This block can have ${min_count} or more inputs named in_0, in_1, ...`;
  }

  const inputStaticPortNames =
    portsDefinition.static?.map((p) =>
      p.variant?.variant_kind === 'acausal' ? `${p.name} (acausal)` : p.name,
    ) || [];

  // TODO: add a decription on conditional ports to explain on what it is conditional
  const conditionalInputPortNames =
    portsDefinition.conditional?.map((p) => `${p.name} (conditional)`) || [];

  const allPortNames = [
    ...inputStaticPortNames,
    ...conditionalInputPortNames,
  ].join(', ');

  return `port names: ${allPortNames}`;
};

const getAllPortsDescription = (ports: Ports) => {
  const inputPortsDesc = getPortsDescription(ports.inputs);
  const outputPortDesc = getPortsDescription(ports.outputs);

  const portsDesc = `
##### Inputs
${inputPortsDesc || 'None'}

##### Outputs
${outputPortDesc || 'None'}
`;
  return portsDesc;
};

const formatBlockDoc = (blockDoc: ComputationBlockClass) => {
  if (!blockDoc) {
    return undefined;
  }

  // HACK: Product and Adder `operators` are treated differently in the chat.
  // https://collimator.atlassian.net/browse/DASH-1571
  if (blockDoc.base.name === 'Product') {
    blockDoc.parameter_definitions = [
      {
        name: 'operation',
        data_type: 'string' as DataType,
        default_value: 'None',
        description:
          'Operation to perform on the inputs. If the first operator is "/" it will invert the first input. Each operand name MUST start with "in_".',
      },
    ];
  } else if (blockDoc.base.name === 'Adder') {
    blockDoc.parameter_definitions = [
      {
        name: 'operation',
        data_type: 'string' as DataType,
        default_value: 'None',
        description:
          'Operation to perform on the inputs. Each operand name MUST start with "in_".',
      },
    ];
  }

  const fnArgs =
    blockDoc.parameter_definitions
      ?.map((arg) => {
        let defaultValue = arg.default_value;
        if (arg.data_type == 'string') {
          defaultValue = `"${defaultValue}"`;
        }
        return `${arg.name}: ${arg.data_type} = ${defaultValue}`;
      })
      .join(', ') || '';

  const fnSignature = `${blockDoc.base.name}(${fnArgs})`;

  const paramsDesc =
    blockDoc.parameter_definitions
      ?.map(
        (arg) => `* \`${arg.name}\`: ${arg.description_gpt ?? arg.description}`,
      )
      .join('\n') || 'None';

  const portsDesc = getAllPortsDescription(blockDoc.ports);

  const blockDocStr = `
### ${blockDoc.base.namespace}.${blockDoc.base.name}
#### Function signature
${fnSignature}

#### Description
${blockDoc.base.description_gpt ?? blockDoc.base.description}

#### Parameters
${paramsDesc}

#### Ports
${portsDesc}
`;
  return blockDocStr;
};

export const useSearchBlocks = (
  enableAcausalBlocks: boolean,
): { search_blocks: GptFunction } => {
  const [searchBlockDocTrigger] = usePostDocumentationBlockSearchMutation();
  const searchBlocksCallback = React.useCallback(
    async (args: any, onComplete: ToolCompleteCallback) => {
      const blockType = enableAcausalBlocks ? args.type : 'causal';

      const response = await searchBlockDocTrigger({
        documentationBlockSearchRequest: {
          queries: args.queries,
          type: blockType,
          domain: args.domain,
        },
      })
        .unwrap()
        .catch((e: Error) => {
          onComplete({
            error: `An error occured while searching for blocks: ${e}`,
          });
        });
      if (!response) {
        onComplete({
          error: `An error occured while searching for blocks`,
        });
        return;
      }
      const namespace = blockType === 'causal' ? 'core' : 'acausal';
      const blocksDoc = response.map(
        (blockName, i) =>
          formatBlockDoc(
            BlockDocMap[`${namespace}.${blockName}`] as ComputationBlockClass,
          ) || `### ${args.queries[i]}: Not found`,
      );
      onComplete({ result: blocksDoc.join('\n---\n') });
    },
    [enableAcausalBlocks, searchBlockDocTrigger],
  );

  return {
    search_blocks: searchBlocksCallback,
  };
};
