/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  className?: string;
}

const TrigAcosh: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.7356 27.9999L15.7426 16.25L0 16.25V15.75L15.7429 15.75L15.75 3.99988L16.25 4.00018L16.2429 15.75H17.2624L17.2667 15.6704C17.2682 15.6425 17.2713 15.6148 17.2759 15.5873L17.328 15.2754C17.3324 15.2489 17.3382 15.2226 17.3455 15.1967L17.4236 14.9178C17.4302 14.8944 17.4378 14.8714 17.4466 14.8488L17.5508 14.5803C17.5593 14.5582 17.5689 14.5366 17.5795 14.5154L17.7228 14.2297C17.7333 14.2087 17.7448 14.1882 17.7573 14.1683L17.9396 13.8766C17.9514 13.8576 17.9642 13.8391 17.9777 13.8212L18.1991 13.5291C18.212 13.512 18.2257 13.4955 18.24 13.4796L18.5135 13.1766C18.5271 13.1615 18.5413 13.1469 18.5561 13.133L18.8817 12.8265C18.8958 12.8132 18.9104 12.8004 18.9255 12.7883L19.3291 12.4633C19.3434 12.4518 19.3582 12.4408 19.3733 12.4303L19.8551 12.0976C19.8687 12.0882 19.8827 12.0792 19.897 12.0707L20.4439 11.7445C20.4565 11.737 20.4693 11.7298 20.4823 11.7231L21.0943 11.405C21.0993 11.4024 21.1044 11.3998 21.1096 11.3973C21.116 11.3941 21.1226 11.391 21.1291 11.388L21.8192 11.0736C21.8297 11.0688 21.8404 11.0643 21.8511 11.06L22.6323 10.7467C22.6418 10.7429 22.6514 10.7393 22.6611 10.7358L23.5335 10.4269C23.5421 10.4239 23.5507 10.421 23.5594 10.4183L24.536 10.112C24.5437 10.1095 24.5514 10.1072 24.5592 10.1051L25.653 9.80054C25.66 9.7986 25.667 9.79676 25.674 9.79501L26.911 9.48897L26.93 9.48454L28.3102 9.1808L28.3271 9.17727L29.8766 8.87364L29.8918 8.87083L31.0137 8.67493C31.4217 8.60368 31.8103 8.8767 31.8815 9.28474C31.9528 9.69278 31.6797 10.0813 31.2717 10.1526L30.1575 10.3471L28.6241 10.6476L27.2618 10.9474L26.0449 11.2485L24.9733 11.5469L24.0213 11.8454L23.1763 12.1447L22.4254 12.4458L21.7687 12.745L21.1935 13.0439L20.6869 13.3461L20.2483 13.649L19.8888 13.9384L19.6067 14.2041L19.3752 14.4606L19.1936 14.7003L19.0479 14.9334L18.9364 15.1559L18.8582 15.3574L18.8008 15.5624L18.7695 15.75L32 15.75V16.25L18.7073 16.25C18.5994 16.5559 18.2998 16.7673 17.9595 16.7489C17.6477 16.7321 17.3907 16.5271 17.2927 16.25H16.2426L16.2356 28.0002L15.7356 27.9999Z"
      fill={props.fill}
    />
  </svg>
);

TrigAcosh.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  className: '',
};

export default TrigAcosh;
