/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const CoordinaterotationconversionRotationanglestoquaternion: React.FC<
  Props
> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.4697 11.9697C15.7626 11.6768 16.2374 11.6768 16.5303 11.9697L19.1768 14.6161C19.6649 15.1043 19.6649 15.8957 19.1768 16.3839L16.5303 19.0303C16.2374 19.3232 15.7626 19.3232 15.4697 19.0303C15.1768 18.7374 15.1768 18.2626 15.4697 17.9697L17.9393 15.5L15.4697 13.0303C15.1768 12.7374 15.1768 12.2626 15.4697 11.9697Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M25.9729 13.01C25.9729 12.9767 25.9829 12.95 26.0029 12.93C26.0295 12.9033 26.0595 12.89 26.0929 12.89H27.0129C27.0462 12.89 27.0729 12.9033 27.0929 12.93C27.1195 12.95 27.1329 12.9767 27.1329 13.01V19.77C27.1329 19.8033 27.1195 19.83 27.0929 19.85C27.0729 19.8767 27.0462 19.89 27.0129 19.89H26.0929C26.0595 19.89 26.0295 19.8767 26.0029 19.85C25.9829 19.83 25.9729 19.8033 25.9729 19.77V17.6C25.9729 17.58 25.9662 17.57 25.9529 17.57C25.9395 17.5633 25.9262 17.5667 25.9129 17.58C25.6195 17.9133 25.2429 18.08 24.7829 18.08C24.2895 18.08 23.8795 17.9567 23.5529 17.71C23.2262 17.4633 22.9895 17.12 22.8429 16.68C22.7295 16.34 22.6729 15.9333 22.6729 15.46C22.6729 14.9933 22.7329 14.5767 22.8529 14.21C22.9995 13.77 23.2362 13.4267 23.5629 13.18C23.8895 12.9333 24.2895 12.81 24.7629 12.81C25.2362 12.81 25.6195 12.9833 25.9129 13.33C25.9262 13.35 25.9395 13.3567 25.9529 13.35C25.9662 13.3433 25.9729 13.33 25.9729 13.31V13.01ZM25.7129 16.64C25.8795 16.3667 25.9629 15.9667 25.9629 15.44C25.9629 14.94 25.8862 14.5533 25.7329 14.28C25.5462 13.96 25.2829 13.8 24.9429 13.8C24.5629 13.8 24.2795 13.9633 24.0929 14.29C23.9329 14.59 23.8529 14.9733 23.8529 15.44C23.8529 15.9467 23.9529 16.3533 24.1529 16.66C24.3462 16.94 24.6129 17.08 24.9529 17.08C25.2795 17.08 25.5329 16.9333 25.7129 16.64Z"
      fill={props.fill}
    />
    <path
      d="M5.65625 5.5C5.65625 5.22386 5.43239 5 5.15625 5H3.65625C3.10397 5 2.65625 5.44772 2.65625 6V26C2.65625 26.5523 3.10396 27 3.65625 27H5.15625C5.43239 27 5.65625 26.7761 5.65625 26.5C5.65625 26.2239 5.43239 26 5.15625 26H3.65625V6H5.15625C5.43239 6 5.65625 5.77614 5.65625 5.5Z"
      fill={props.fill}
    />
    <path
      d="M9.5 5C9.22386 5 9 5.22386 9 5.5C9 5.77614 9.22386 6 9.5 6H11V26H9.5C9.22386 26 9 26.2239 9 26.5C9 26.7761 9.22386 27 9.5 27H11C11.5523 27 12 26.5523 12 26V6C12 5.44772 11.5523 5 11 5H9.5Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M6.90967 10.8384C6.61572 10.8384 6.36165 10.7632 6.14746 10.6128C5.93555 10.4624 5.77262 10.2528 5.65869 9.98389C5.54476 9.71273 5.48779 9.39714 5.48779 9.03711V8.96533C5.48779 8.57113 5.54476 8.22705 5.65869 7.93311C5.77262 7.63916 5.93555 7.41016 6.14746 7.24609C6.36165 7.08203 6.61686 7 6.91309 7C7.13184 7 7.32096 7.04443 7.48047 7.1333C7.64225 7.22217 7.77897 7.34863 7.89062 7.5127L7.95898 7.07178H8.70068V9.65918C8.70068 9.83691 8.72233 9.9611 8.76562 10.0317C8.8112 10.1024 8.87272 10.1377 8.9502 10.1377C8.96615 10.1377 8.9821 10.1366 8.99805 10.1343C9.014 10.132 9.02767 10.1274 9.03906 10.1206L9.12109 10.7393C9.04818 10.778 8.9764 10.8042 8.90576 10.8179C8.8374 10.8315 8.76449 10.8384 8.68701 10.8384C8.50016 10.8384 8.3418 10.7939 8.21191 10.7051C8.08431 10.6139 7.98975 10.4749 7.92822 10.2881C7.81429 10.4681 7.67415 10.6048 7.50781 10.6982C7.34147 10.7917 7.14209 10.8384 6.90967 10.8384ZM7.15234 10.1753C7.31185 10.1753 7.44743 10.1366 7.55908 10.0591C7.67301 9.97933 7.76644 9.86654 7.83936 9.7207V8.13818C7.78922 8.0402 7.73112 7.95589 7.66504 7.88525C7.59896 7.81462 7.52376 7.76107 7.43945 7.72461C7.35514 7.68587 7.26172 7.6665 7.15918 7.6665C6.97461 7.6665 6.82194 7.72233 6.70117 7.83398C6.58268 7.94336 6.49496 8.09489 6.43799 8.28857C6.38102 8.48226 6.35254 8.70784 6.35254 8.96533V9.03711C6.35254 9.38346 6.41634 9.66032 6.54395 9.86768C6.67155 10.0728 6.87435 10.1753 7.15234 10.1753Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M5.6416 19.1133V14.147C5.6416 13.8758 5.71224 13.6331 5.85352 13.4189C5.99707 13.2025 6.18962 13.0316 6.43115 12.9062C6.67269 12.7809 6.94385 12.7183 7.24463 12.7183C7.54541 12.7183 7.80973 12.7752 8.0376 12.8892C8.26774 13.0008 8.44662 13.158 8.57422 13.3608C8.7041 13.5614 8.76904 13.7949 8.76904 14.0615C8.76904 14.2803 8.71322 14.4797 8.60156 14.6597C8.48991 14.8374 8.33724 14.981 8.14355 15.0903C8.42383 15.1906 8.63916 15.3467 8.78955 15.5586C8.93994 15.7705 9.01514 16.0326 9.01514 16.3447C9.01514 16.6546 8.95247 16.9212 8.82715 17.1445C8.70182 17.3678 8.52409 17.5399 8.29395 17.6606C8.06608 17.7791 7.79834 17.8384 7.49072 17.8384C7.31071 17.8384 7.13525 17.8156 6.96436 17.77C6.79346 17.7244 6.63965 17.6549 6.50293 17.5615V19.1133H5.6416ZM7.29932 17.1753C7.56592 17.1753 7.77555 17.0967 7.92822 16.9395C8.08089 16.7822 8.15723 16.5726 8.15723 16.3105C8.15723 16.1533 8.12646 16.0109 8.06494 15.8833C8.00342 15.7534 7.91455 15.6509 7.79834 15.5757C7.68441 15.4982 7.54655 15.4595 7.38477 15.4595H6.98828V14.8306H7.20703C7.44857 14.8306 7.62516 14.7679 7.73682 14.6426C7.85075 14.515 7.90771 14.3418 7.90771 14.123C7.90771 13.984 7.88037 13.8599 7.82568 13.7505C7.77328 13.6388 7.6958 13.55 7.59326 13.4839C7.493 13.4178 7.37109 13.3848 7.22754 13.3848C7.08854 13.3848 6.96436 13.4178 6.85498 13.4839C6.74561 13.55 6.65902 13.64 6.59521 13.7539C6.53369 13.8678 6.50293 13.9989 6.50293 14.147V16.895C6.60091 16.9839 6.71826 17.0534 6.85498 17.1035C6.99398 17.1514 7.14209 17.1753 7.29932 17.1753Z"
      fill={props.fill}
    />
    <path
      d="M6.81396 24.082V25.6919H7.67529V24.1538L8.72803 21.146L9.03223 21.0981V20.5684H7.45996V21.0981L7.87012 21.1699L7.33008 23.0088L7.27881 23.2515H7.2583L7.21045 23.0088L6.5918 21.0469C6.54622 20.9079 6.49723 20.8008 6.44482 20.7256C6.39242 20.6504 6.32406 20.5991 6.23975 20.5718C6.15771 20.5444 6.0472 20.5308 5.9082 20.5308C5.83073 20.5308 5.74642 20.5376 5.65527 20.5513C5.56413 20.5649 5.48438 20.5832 5.41602 20.606L5.48438 21.2588C5.53223 21.2497 5.56413 21.244 5.58008 21.2417C5.59603 21.2394 5.6154 21.2383 5.63818 21.2383C5.72021 21.2383 5.78288 21.2542 5.82617 21.2861C5.87174 21.3158 5.9082 21.3761 5.93555 21.4673L6.81396 24.082Z"
      fill={props.fill}
    />
  </svg>
);

CoordinaterotationconversionRotationanglestoquaternion.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default CoordinaterotationconversionRotationanglestoquaternion;
