import { t } from '@lingui/macro';
import { useDiagramVersionActions } from 'app/api/useDiagramVersionActions';
import { useAppSelector } from 'app/hooks';
import React from 'react';
import { History } from 'ui/common/Icons/Standard';
import { useModelPermission } from 'ui/permission/useModelPermission';
import { useAppParams } from 'util/useAppParams';
import { NavbarButton } from './NavbarButtons';

const RestoreButton: React.FC = () => {
  const loadedModelId = useAppSelector(
    (state) => state.modelMetadata.loadedModelId,
  );
  const { modelId, projectId, versionId } = useAppParams();
  const { canEditModelMetadata, arePermissionsLoaded } = useModelPermission(
    projectId,
    modelId,
    versionId,
  );

  const kind = useAppSelector((state) => state.submodels.topLevelModelType);

  const showRestoreButton =
    loadedModelId &&
    arePermissionsLoaded &&
    canEditModelMetadata &&
    loadedModelId === modelId &&
    versionId;

  const { restoreDiagramVersion } = useDiagramVersionActions();

  const restoreThisVersion = React.useCallback(() => {
    if (versionId && modelId && kind) {
      restoreDiagramVersion({
        modelUuid: modelId,
        snapshotUuid: versionId,
        snapshotRestoreRequest: {},
        kind,
      });
    }
  }, [restoreDiagramVersion, versionId, modelId, kind]);

  if (!showRestoreButton) return null;

  return (
    <NavbarButton
      tooltipText={t({
        id: 'navbar.modelEditor.restoreThisVersionButton.tooltip',
        message: 'Restore this version',
      })}
      Icon={History}
      isEnabled
      onClickHandler={restoreThisVersion}
      testId="navbar-restore-this-version-button"
    />
  );
};

export default RestoreButton;
