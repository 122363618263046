/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  cx?: any;
  cy?: any;
  r?: any;
  stroke?: any;
  strokeWidth?: any;
  strokeLinecap?: any;
  className?: string;
}

const AcausalElectricalVoltagesource: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <circle
      cx={props.cx}
      cy={props.cy}
      r={props.r}
      stroke={props.stroke}
      strokeWidth={props.strokeWidth}
    />
    <path
      d="M15.1812 22C15.0745 22 15.0052 21.9467 14.9732 21.84L11.6132 11.024L11.5972 10.96C11.5972 10.8533 11.6559 10.8 11.7732 10.8H13.3572C13.4745 10.8 13.5492 10.8533 13.5812 10.96L15.9812 19.312C15.9919 19.344 16.0079 19.36 16.0292 19.36C16.0505 19.36 16.0665 19.344 16.0772 19.312L18.4612 10.96C18.4932 10.8533 18.5679 10.8 18.6852 10.8H20.2372C20.3012 10.8 20.3492 10.8213 20.3812 10.864C20.4132 10.9067 20.4185 10.96 20.3972 11.024L16.9892 21.84C16.9572 21.9467 16.8879 22 16.7812 22H15.1812Z"
      fill={props.fill}
    />
    <path
      d="M2 16L4 16"
      stroke={props.stroke}
      strokeWidth={props.strokeWidth}
      strokeLinecap={props.strokeLinecap}
    />
    <path
      d="M28 16L30 16"
      stroke={props.stroke}
      strokeWidth={props.strokeWidth}
      strokeLinecap={props.strokeLinecap}
    />
  </svg>
);

AcausalElectricalVoltagesource.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  cx: '16',
  cy: '16',
  r: '11.25',
  stroke: '#1D3131',
  strokeWidth: '1.5',
  strokeLinecap: 'round',
  className: '',
};

export default AcausalElectricalVoltagesource;
