import styled from '@emotion/styled/macro';
import { t } from '@lingui/macro';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { ModelState } from 'app/modelState/ModelState';
import { dataExplorerActions } from 'app/slices/dataExplorerSlice';
import { entityPreferencesActions } from 'app/slices/entityPreferencesSlice';
import { uiFlagsActions } from 'app/slices/uiFlagsSlice';
import React from 'react';
import Button from 'ui/common/Button/Button';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import { Close, Plus } from 'ui/common/Icons/Standard';
import SectionHeading from 'ui/common/Inputs/SectionHeading';
import SelectInput from 'ui/common/SelectInput';
import { scanNodesAndSignals } from 'ui/modelEditor/optimizations/optimizerModalUtils';
import {
  CommonEnsembleSimPrefs,
  EnsembleTab,
} from 'ui/userPreferences/ensembleSimModelPrefs';
import { ActionButtonContainer } from './EnsembleConfigs';

const SignalsBox = styled.div`
  overflow-y: auto;
  max-height: 230px;
  flex-shrink: 0;
  padding: 1px 0 1px 1px;
`;

const SignalRowWrapper = styled.div`
  display: flex;
  margin-top: 4px;

  svg {
    cursor: pointer;
    opacity: 0.3;

    &:hover {
      opacity: 0.8;
    }
  }

  &:first-of-type {
    margin-top: 0;
  }
`;

const PlotOptionsContainer = styled.div`
  display: flex;
  margin-top: 8px;
  gap: 0 16px;
`;

const PlotOptionContainer = styled.div`
  flex-direction: column;
  display: flex;
  flex: 1;
  cursor: pointer;
`;
const PlotOptionThumb = styled.div<{ enabled: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  border: ${({ enabled }) =>
    enabled ? '2px solid #8CE5E1' : '1px solid #E4E7E7'};
  margin-bottom: 8px;
  border-radius: 4px;
  background: white;
  overflow: hidden;
  position: relative;

  > img {
    position: absolute;
    width: 100%;
  }
`;
const PlotOptionTitle = styled.div`
  font-weight: 600;
  padding-left: 8px;
`;

const PlotOption = ({
  title,
  enabled,
  onClick,
  imageUrl,
}: {
  title: string;
  enabled: boolean;
  onClick: () => void;
  imageUrl?: string;
}) => (
  <PlotOptionContainer onClick={onClick}>
    <PlotOptionThumb enabled={enabled}>
      {imageUrl && <img src={imageUrl} />}
    </PlotOptionThumb>
    <PlotOptionTitle>{title}</PlotOptionTitle>
  </PlotOptionContainer>
);

export const SignalsAndPlotsConfig = ({
  ensembleType,
  prefs,
}: {
  ensembleType: EnsembleTab;
  prefs: CommonEnsembleSimPrefs;
}) => {
  const dispatch = useAppDispatch();

  const idToTrace = useAppSelector((state) => state.dataExplorer.idToTrace);

  const modelId = useAppSelector((state) => state.modelMetadata.loadedModelId);

  const modelState: ModelState = useAppSelector((state) => state.model.present);
  const signalOptions = React.useMemo(() => {
    const { signalOptions } = scanNodesAndSignals(modelState);

    // HACK: cleanup idToTrace to remove any signals that are no longer in the model
    // This cleanup should not be necessary if the visualizer didn't have some bugs
    const invalidTracesId = Object.values(idToTrace)
      .filter(
        (trace) =>
          !signalOptions.some((op) => op.signalPathName === trace.signalPath),
      )
      .map((trace) => trace.id);
    dispatch(dataExplorerActions.removeTraces({ traceIds: invalidTracesId }));

    return signalOptions.map((op) => ({
      value: op.signalPathName,
      label: op.signalPathName,
      locationData: op.locationData,
    }));
  }, [dispatch, idToTrace, modelState]);

  const addSignalRow = () => {
    const signal = signalOptions.find((s) => !prefs.signals.includes(s.value));
    if (!signal) return;
    dispatch(
      entityPreferencesActions.onUserUpdatedEnsembleSimModalPrefs({
        modelId,
        tab: ensembleType,
        prefs: {
          signals: [...prefs.signals, signal.value],
        },
      }),
    );
  };

  const removeSignalRow = (index: number) => () => {
    dispatch(
      entityPreferencesActions.onUserUpdatedEnsembleSimModalPrefs({
        modelId,
        tab: ensembleType,
        prefs: {
          signals: prefs.signals.filter((_, i) => i !== index),
        },
      }),
    );
  };

  const selectSignal = (index: number) => (newVal: string) => {
    dispatch(
      entityPreferencesActions.onUserUpdatedEnsembleSimModalPrefs({
        modelId,
        tab: ensembleType,
        prefs: {
          signals: prefs.signals.map((signal, i) =>
            i === index ? newVal : signal,
          ),
        },
      }),
    );
  };

  const plotOptions = useAppSelector(
    (state) => state.uiFlags.ensemblePlotOptions,
  );

  const togglePlotOption = (
    optionName: 'overlay' | 'standard_deviations' | 'iqr_error',
  ) => {
    dispatch(
      uiFlagsActions.setUIFlag({
        ensemblePlotOptions: {
          ...plotOptions,
          [optionName]: !plotOptions[optionName],
        },
      }),
    );
  };

  return (
    <>
      <SectionHeading noBorder>{t`Plots`}</SectionHeading>
      <SignalsBox>
        {prefs.signals.map((signalVal, i) => (
          <SignalRowWrapper>
            <SelectInput
              placeholder="Choose a signal to plot"
              options={signalOptions}
              isOptionDisabled={(option) =>
                option.value !== signalVal &&
                prefs.signals.includes(option.value)
              }
              currentValue={signalVal}
              onSelectValue={selectSignal(i)}
            />
            <Close onClick={removeSignalRow(i)} />
          </SignalRowWrapper>
        ))}
      </SignalsBox>
      <ActionButtonContainer>
        <Button
          variant={ButtonVariants.SmallSecondary}
          Icon={Plus}
          onClick={addSignalRow}
          disabled={signalOptions.length <= prefs.signals.length}>
          {t`Add signal`}
        </Button>
      </ActionButtonContainer>
      <PlotOptionsContainer>
        <PlotOption
          title={t`Overlay`}
          imageUrl="/assets/overlay_plot_example.png"
          enabled={plotOptions.overlay}
          onClick={() => togglePlotOption('overlay')}
        />
        <PlotOption
          title={t`Standard deviations`}
          imageUrl="/assets/standard_deviation_plot_example.png"
          enabled={plotOptions.standard_deviations}
          onClick={() => togglePlotOption('standard_deviations')}
        />
        <PlotOption
          title={t`IQR error bars`}
          imageUrl="/assets/iqr_error_plot_example.png"
          enabled={plotOptions.iqr_error}
          onClick={() => togglePlotOption('iqr_error')}
        />
      </PlotOptionsContainer>
    </>
  );
};
