/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Data: React.FC<Props> = (props) => (
  <svg viewBox="0 0 24 24" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M16.8 9.25H7.2V16.75H16.8V9.25ZM7 8C6.44772 8 6 8.44772 6 9V17C6 17.5523 6.44772 18 7 18H17C17.5523 18 18 17.5523 18 17V9C18 8.44772 17.5523 8 17 8H7Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M7.2 8H16.8L17.4 10.5H14.4V11.75H17.4V13H14.4V14.25H17.4V15.5H14.4V17.375H13.2V15.5H10.8V17.375H9.6V15.5H6.6V14.25H9.6V13H6.6V11.75H9.6V10.5H6.6L7.2 8ZM10.8 10.5V11.75H13.2V10.5H10.8ZM13.2 13H10.8V14.25H13.2V13Z"
      fill={props.fill}
    />
  </svg>
);

Data.defaultProps = {
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Data;
