/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  className?: string;
}

const Running: React.FC<Props> = (props) => (
  <svg viewBox="0 0 16 16" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.96335 4.50019C8.74006 4.49206 9.4974 4.74253 10.1161 5.21215C10.5153 5.51517 10.8422 5.89833 11.0779 6.33361L10.384 5.93301C10.1448 5.79494 9.83905 5.87688 9.70098 6.11602C9.56291 6.35517 9.64484 6.66096 9.88399 6.79904L12.0491 8.04904L13.2991 5.88397C13.4371 5.64483 13.3552 5.33903 13.116 5.20096C12.8769 5.06289 12.5711 5.14483 12.433 5.38397L12.0523 6.04333C11.745 5.40675 11.2901 4.8478 10.7207 4.41562C9.92523 3.81182 8.9515 3.48979 7.95288 3.50025C6.95426 3.5107 5.98749 3.85305 5.20484 4.47338C4.55335 4.98974 4.06025 5.67429 3.77587 6.44863C3.68067 6.70784 3.8473 6.98 4.11547 7.04586C4.38364 7.11173 4.65114 6.94577 4.75452 6.68971C4.98048 6.13003 5.34855 5.63549 5.82599 5.25707C6.43472 4.7746 7.18665 4.50833 7.96335 4.50019Z"
      fill={props.fill}
    />
    <path
      d="M3.94767 9.9567L3.56701 10.616C3.42894 10.8552 3.12314 10.9371 2.88399 10.799C2.64485 10.661 2.56291 10.3552 2.70098 10.116L3.95098 7.95096L6.11605 9.20096C6.35519 9.33904 6.43713 9.64483 6.29906 9.88398C6.16099 10.1231 5.85519 10.2051 5.61605 10.067L4.92214 9.66636C5.14608 10.08 5.45259 10.447 5.82599 10.7429C6.43472 11.2254 7.18665 11.4917 7.96335 11.4998C8.74006 11.5079 9.4974 11.2575 10.1161 10.7879C10.6014 10.4195 10.9797 9.93279 11.2173 9.37798C11.3261 9.12414 11.597 8.96381 11.8637 9.03528C12.1304 9.10675 12.2913 9.38233 12.1907 9.6395C11.8902 10.4077 11.3829 11.0818 10.7207 11.5844C9.92523 12.1882 8.9515 12.5102 7.95288 12.4998C6.95426 12.4893 5.98749 12.1469 5.20484 11.5266C4.66955 11.1024 4.24119 10.5646 3.94767 9.9567Z"
      fill={props.fill}
    />
  </svg>
);

Running.defaultProps = {
  width: '16',
  height: '16',
  viewBox: '0 0 16 16',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  className: '',
};

export default Running;
