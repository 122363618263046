/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Block: React.FC<Props> = (props) => (
  <svg viewBox="0 0 16 16" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M4 6.14286V11.8571H12V6.14286H4ZM3.58307 5C3.26105 5 3 5.29834 3 5.66637V12.3336C3 12.7017 3.26105 13 3.58307 13H12.4169C12.7389 13 13 12.7017 13 12.3336V5.66637C13 5.29834 12.7389 5 12.4169 5H3.58307Z"
      fill={props.fill}
    />
  </svg>
);

Block.defaultProps = {
  width: '16',
  height: '16',
  viewBox: '0 0 16 16',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Block;
