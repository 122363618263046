import { t } from '@lingui/macro';
import {
  ParameterDefinition,
  SubmodelPortDefinition,
} from 'app/apiGenerated/generatedApiTypes';
import { Project } from 'app/apiTransformers/convertAPIProjectToProject';
import { SubmodelInfoLiteUI } from 'app/apiTransformers/convertGetSubmodelsList';
import { PortSide } from 'app/common_types/PortTypes';
import {
  LinkInstance,
  NodeInstance,
} from 'app/generated_types/SimulationModel';
import {
  nodeClassWithoutNamespace_displayOnly,
  nodeTypeIsCode,
} from 'app/helpers';
import { ModelParameters } from 'app/utils/modelDataUtils';
import { getPortNamesInScope } from 'app/utils/portNameUtils';
import {
  ValidationRule,
  isDuplicatedRuleSet,
  validIdentifierRules,
  validIndentifierNotRequiredRules,
  validNameRules,
} from 'ui/common/Input/inputValidation';

export function isValidModelOrSubmodelName(
  modelOrSubmodelId: string,
  project: Project,
  submodels: SubmodelInfoLiteUI[] | undefined,
): ValidationRule[] {
  if (!submodels) return validNameRules;

  const models = project.models.map((model) => ({
    name: model.name,
    id: model.uuid,
  }));
  const allModelsAndSubmodels = [...(submodels || []), ...(models || [])];
  const otherModelsAndSubmodels = allModelsAndSubmodels.filter(
    (model) => model.id !== modelOrSubmodelId,
  );

  return isDuplicatedRuleSet(
    otherModelsAndSubmodels,
    (submodel) => submodel.name,
  );
}

export function isValidBlockPortNameRuleSet(
  node: NodeInstance,
  portToExclude?: { id: number; side: PortSide },
): ValidationRule[] {
  const isUniqueBlockPortNameRule = {
    predicate: (value: string) => {
      const otherNames = getPortNamesInScope(node, portToExclude);
      return !otherNames.includes(value);
    },
    message: t({
      id: 'input.validationMessage.isUniqueBlockPortName',
      message: `Block port name must be unique`,
    }),
  };

  const doesNotMatchBlockParameterNameRule = {
    predicate: (value: string) => {
      if (!node || !node.parameters) return true;
      if (!nodeTypeIsCode(node.type)) return true;

      const blockParameterNames = Object.keys(node.parameters);
      return !blockParameterNames || !blockParameterNames.includes(value);
    },
    message: t({
      id: 'input.validationMessage.doesNotMatchBlockParameterName',
      message: `Block port name cannot match the name of a parameter of the same block`,
    }),
  };

  return [
    ...validIdentifierRules,
    isUniqueBlockPortNameRule,
    doesNotMatchBlockParameterNameRule,
  ];
}

export function isValidParameterNameRuleSet(
  parameters: ModelParameters,
  parameterIndex: number,
): ValidationRule[] {
  const isUniqueParameterNameRule = {
    predicate: (value: string) => {
      const existingNames = parameters
        .filter((p, index) => index !== parameterIndex)
        .map((p) => p.name);
      return !existingNames.includes(value);
    },
    message: t({
      id: 'input.validationMessage.isUniqueParameterName',
      message: `Parameter name must be unique`,
    }),
  };

  return [...validIdentifierRules, isUniqueParameterNameRule];
}

/**
 * @param parameterIndex Must be the index of the parameter in `paramNames` because paramNames is the saved list of names, not the working version that is to be validated.
 */
export function isValidBlockParameterNameRuleSet(
  paramNames: string[],
  parameterIndex: number,
  node: NodeInstance,
): ValidationRule[] {
  const isUniqueParameterNameRule = {
    predicate: (value: string) => {
      const otherNames = paramNames.filter(
        (p, index) => index !== parameterIndex,
      );
      return !otherNames.includes(value);
    },
    message: t({
      id: 'input.validationMessage.isUniqueBlockParameterName',
      message: `Block parameter name must be unique`,
    }),
  };

  const doesNotMatchBlockPortNameRule = {
    predicate: (value: string) => {
      if (!node) return true;
      if (!nodeTypeIsCode(node.type)) return true;

      const existingNames = getPortNamesInScope(node);
      return !existingNames.includes(value);
    },
    message: t({
      id: 'input.validationMessage.doesNotMatchBlockPortName',
      message: `Block parameter name cannot match a port name of the same block`,
    }),
  };

  return [
    ...validIdentifierRules,
    isUniqueParameterNameRule,
    doesNotMatchBlockPortNameRule,
  ];
}

export function isValidSubmodelParameterNameRuleSet(
  parameterDefinitions: ParameterDefinition[],
  portDefinitionsInputs: SubmodelPortDefinition[],
  portDefinitionsOutputs: SubmodelPortDefinition[],
  parameterDefinitionId: string,
): ValidationRule[] {
  const isUniqueParameterNameRule = {
    predicate: (value: string) =>
      !parameterDefinitions.some(
        (paramDef) =>
          paramDef.uuid !== parameterDefinitionId && paramDef.name === value,
      ),
    message: t({
      id: 'input.validationMessage.isUniqueSubmodelParameterName',
      message: `Submodel parameter name must be unique`,
    }),
  };

  const doesNotMatchSubmodelPortNameRule = {
    predicate: (value: string) => {
      const inputHasMatch = portDefinitionsInputs.some(
        (port) => port.name === value,
      );
      if (inputHasMatch) return false;

      const outputHasMatch = portDefinitionsOutputs.some(
        (port) => port.name === value,
      );
      if (outputHasMatch) return false;

      return true;
    },
    message: t({
      id: 'input.validationMessage.doesNotMatchSubmodelPortName',
      message: `Submodel parameter name cannot match a port name of the same submodel`,
    }),
  };

  return [
    ...validIdentifierRules,
    isUniqueParameterNameRule,
    doesNotMatchSubmodelPortNameRule,
  ];
}

export function isValidSubmodelPortNameRuleSet(
  parameterDefinitions: ParameterDefinition[],
  portId: string,
  modelNodes: NodeInstance[],
): ValidationRule[] {
  const isUniquePortNameRule = {
    predicate: (value: string) => {
      const foundMatchingNodeName = modelNodes.some(
        (port) => port.uuid !== portId && port.name === value,
      );
      if (foundMatchingNodeName) return false;

      return true;
    },

    message: t({
      id: 'input.validationMessage.isUniqueSubmodelPortName',
      message: `Submodel port name must be unique`,
    }),
  };

  const doesNotMatchSubmodelParameterNameRule = {
    predicate: (value: string) =>
      !parameterDefinitions.some((paramDef) => paramDef.name === value),
    message: t({
      id: 'input.validationMessage.doesNotMatchSubmodelPortName',
      message: `Submodel parameter name cannot match a port name of the same submodel`,
    }),
  };

  return [
    ...validIdentifierRules,
    isUniquePortNameRule,
    doesNotMatchSubmodelParameterNameRule,
  ];
}

export function isValidBlockNameRuleSet(
  nodes: NodeInstance[] | undefined,
  nodeId: string,
): ValidationRule[] {
  const isUniqueBlockNameRule = {
    predicate: (value: string) => {
      const otherNames = (nodes || [])
        .filter((n) => n.uuid !== nodeId)
        .map((n) => n.name || nodeClassWithoutNamespace_displayOnly(n.type));
      return !otherNames || !otherNames.includes(value);
    },
    message: t({
      id: 'input.validationMessage.isUniqueBlockName',
      message: `Block name must be unique`,
    }),
  };

  return [...validIdentifierRules, isUniqueBlockNameRule];
}

export function isValidSignalNameRuleSet(
  links: LinkInstance[] | undefined,
  parentLinkId: string,
): ValidationRule[] {
  const isUniqueSignalNameRule = {
    predicate: (value: string) => {
      const otherNames = (links || [])
        .filter((l) => l.uuid !== parentLinkId && l.name)
        .map((n) => n.name);
      return !otherNames.includes(value);
    },
    message: t({
      id: 'input.validationMessage.isUniqueSignalName',
      message: `Signal name must be unique`,
    }),
  };

  return [...validIndentifierNotRequiredRules, isUniqueSignalNameRule];
}
