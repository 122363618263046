/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Settings: React.FC<Props> = (props) => (
  <svg viewBox="0 0 16 16" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M5.83334 3.66667C5.83334 3.39053 5.60949 3.16667 5.33334 3.16667C5.0572 3.16667 4.83334 3.39053 4.83334 3.66667V4.33334C4.83334 4.35434 4.83464 4.37505 4.83715 4.39537C3.97261 4.61606 3.33334 5.40005 3.33334 6.33334C3.33334 7.26663 3.97261 8.05061 4.83715 8.27131C4.83464 8.29163 4.83334 8.31233 4.83334 8.33334V12.3333C4.83334 12.6095 5.0572 12.8333 5.33334 12.8333C5.60949 12.8333 5.83334 12.6095 5.83334 12.3333V8.33334C5.83334 8.31233 5.83205 8.29163 5.82953 8.27131C6.69408 8.05061 7.33334 7.26663 7.33334 6.33334C7.33334 5.40005 6.69408 4.61606 5.82953 4.39537C5.83205 4.37505 5.83334 4.35434 5.83334 4.33334V3.66667ZM6.33334 6.33334C6.33334 6.88562 5.88563 7.33334 5.33334 7.33334C4.78106 7.33334 4.33334 6.88562 4.33334 6.33334C4.33334 5.78105 4.78106 5.33334 5.33334 5.33334C5.88563 5.33334 6.33334 5.78105 6.33334 6.33334Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M12.6667 9.66667C12.6667 10.6 12.0274 11.3839 11.1629 11.6046C11.1654 11.625 11.1667 11.6457 11.1667 11.6667V12.3333C11.1667 12.6095 10.9428 12.8333 10.6667 12.8333C10.3905 12.8333 10.1667 12.6095 10.1667 12.3333V11.6667C10.1667 11.6457 10.168 11.625 10.1705 11.6046C9.30594 11.3839 8.66668 10.6 8.66668 9.66667C8.66668 8.73338 9.30594 7.9494 10.1705 7.7287C10.168 7.70838 10.1667 7.68768 10.1667 7.66667V3.66667C10.1667 3.39053 10.3905 3.16667 10.6667 3.16667C10.9428 3.16667 11.1667 3.39053 11.1667 3.66667V7.66667C11.1667 7.68768 11.1654 7.70838 11.1629 7.7287C12.0274 7.9494 12.6667 8.73338 12.6667 9.66667ZM11.6667 9.66667C11.6667 10.219 11.219 10.6667 10.6667 10.6667C10.1144 10.6667 9.66668 10.219 9.66668 9.66667C9.66668 9.11439 10.1144 8.66667 10.6667 8.66667C11.219 8.66667 11.6667 9.11439 11.6667 9.66667Z"
      fill={props.fill}
    />
  </svg>
);

Settings.defaultProps = {
  width: '16',
  height: '16',
  viewBox: '0 0 16 16',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Settings;
