import styled from '@emotion/styled/macro';
import { useProject } from 'app/api/useProject';
import { ModelKind } from 'app/apiGenerated/generatedApiTypes';
import { Project } from 'app/apiTransformers/convertAPIProjectToProject';
import { MouseActions } from 'app/common_types/MouseTypes';
import { PortSide } from 'app/common_types/PortTypes';
import { RENDERER_EXPERIMENTAL_ENABLE_CANVAS2D } from 'app/config/globalApplicationConfig';
import {
  AnnotationInstance,
  BlockClassName,
  LinkInstance,
  NodeInstance,
} from 'app/generated_types/SimulationModel';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { cameraActions } from 'app/slices/cameraSlice';
import { ErrorTreeNode, ErrorsState } from 'app/slices/errorsSlice';
import { selectCurrentSubdiagramType } from 'app/slices/modelSlice';
import { RCMenuState } from 'app/slices/rcMenuSlice';
import { UIFlagsState, uiFlagsActions } from 'app/slices/uiFlagsSlice';
import { UserOptions } from 'app/slices/userOptionsSlice';
import { getNamePath, getNestedNode } from 'app/utils/modelDiagramUtils';
import React, { MutableRefObject, ReactElement } from 'react';
import { shallowEqual } from 'react-redux';
import {
  NavigateFunction,
  URLSearchParamsInit,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { ActionCreators as UndoRedoActionCreators } from 'redux-undo';
import { CODE_EDITOR_BLOCK_QUERY_PARAM } from 'ui/codeEditor/CodeEditor';
import { DragContext } from 'ui/dragdrop/DragProvider';
import { useVisualizerPrefs } from 'ui/modelEditor/useVisualizerPrefs';
import { buildNodeBorderColorLUT } from 'ui/modelRendererInternals/drawNode';
import {
  SignalPlotterStatesLUT,
  buildSignalPlotterStatesLUT,
} from 'ui/modelRendererInternals/drawSignalPlotter';
import {
  PortConnLUTType,
  endNanovg,
  externallySetRendererTransform,
  initModelRenderer,
  rendererState,
  requestFrameRender,
  unregisterRendererEvents,
} from 'ui/modelRendererInternals/modelRenderer';
import { transitionMouseState } from 'ui/modelRendererInternals/transitionMouseState';
import { BlockDisplayData } from 'ui/objectBrowser/BlockDisplayData';
import {
  MultiplayerMouseTy,
  useMultiplayerMouses,
} from './useMultiplayerMouses';

const ModelRenderer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: ${({ theme }) => theme.colors.grey[5]};
  pointer-events: auto;

  > canvas {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
`;

export type TransformFunc = (transform: {
  x: number;
  y: number;
  zoom: number;
}) => void;

type SetSearchParamsFunctionSig = (
  nextInit: URLSearchParamsInit,
  navigateOptions?: { replace?: boolean | undefined; state?: any } | undefined,
) => void;

export type RendererRefsType = {
  parent: HTMLDivElement | null;
  externalOverlay: HTMLDivElement | null;

  // NanoVG canvas
  canvas: HTMLCanvasElement | null;
  initializingNvgContext?: Promise<void>;

  // Canvas 2d
  overlayCanvas2d: HTMLCanvasElement | null;
  overlayCanvas2dCtx: CanvasRenderingContext2D | null;

  undoFunc: () => void;
  redoFunc: () => void;
  nodes: NodeInstance[];
  links: LinkInstance[];
  annotations: AnnotationInstance[];

  // Indicates that nodes, links and annotations can be mutated (eg. when
  // dragging blocks). Call ensureEntitiesAreMutable to make them mutable.
  // When they are mutable, they are out-of-sync with the redux store.
  entitiesAreMutable: boolean;
  selectedNodeIds: string[];
  selectedLinkIds: string[];
  selectedAnnotationIds: string[];

  // Look up tables that speed up rendering
  nodesIndexLUT: { [k: string]: number };
  linksIndexLUT: { [k: string]: number };
  annotationsIndexLUT: { [k: string]: number };
  connectedPortLUT: PortConnLUTType;
  linksRenderingDependencyTree: {
    [uuid: string]: string[];
  };
  nodeBorderColorLUT: ReturnType<typeof buildNodeBorderColorLUT>;
  signalPlotterStatesLUT: SignalPlotterStatesLUT;

  codeEditorOpen: boolean;
  visualizerPrefs: ReturnType<typeof useVisualizerPrefs>;
  uiFlags: UIFlagsState;
  userOptions: UserOptions;
  modelKind: ModelKind | null;
  searchParams: URLSearchParams;
  setSearchParams: SetSearchParamsFunctionSig;
  navigate: NavigateFunction;
  errorsState: ErrorsState;
  currentSubdiagramType: BlockClassName | undefined;
  constantBlockDisplayValues: { [blockUuid: string]: string };
  rcMenuState: RCMenuState;
  multiplayerMouses: MultiplayerMouseTy;
  algebraicLoopLinkIdSet: Set<string>;
  subdocumentParentNode: NodeInstance | undefined;
  project: Project | undefined;
};

const constBlockValueMemory: { [nodeUuid: string]: string } = {};
const constBlockCleanedValueMemory: { [nodeUuid: string]: string } = {};

const cleanConstantBlockValue = (val: string) => val.replaceAll('\n', '');

const memoizedCleanedConstBlockValue = (node: NodeInstance) => {
  const paramVal = node.parameters.value?.value;
  if (node.type === 'core.Constant' && paramVal) {
    if (paramVal == constBlockValueMemory[node.uuid]) {
      return constBlockCleanedValueMemory[node.uuid] || '';
    }

    const cleanVal = cleanConstantBlockValue(paramVal);
    constBlockValueMemory[node.uuid] = paramVal;
    constBlockCleanedValueMemory[node.uuid] = cleanVal;
    return cleanVal;
  }

  return '';
};

const algebraicLoopSet = new Set<string>();

export const ModelRendererWrapper = ({
  setTransform,
  externalOverlayRef,
}: {
  setTransform: TransformFunc;
  externalOverlayRef: MutableRefObject<HTMLDivElement | null>;
}): ReactElement => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const visualizerPrefs = useVisualizerPrefs();

  const dragContextData = React.useContext(DragContext);

  const uiFlags = useAppSelector((state) => state.uiFlags);

  const [searchParams, setSearchParams] = useSearchParams();
  // just FYI this component is currently not even mounted when the code editor is open.
  // this is future-proofing for if we end up keeping the model editor mounted
  // underneath the code editor (to eventually save on re-instancing webgl etc.)
  const codeEditorOpen = !!searchParams.get(CODE_EDITOR_BLOCK_QUERY_PARAM);

  const errorsState = useAppSelector((state) => state.errorsSlice);

  const currentDiagram = useAppSelector(
    (state) => state.modelMetadata.currentDiagram,
  );

  // TODO figure out a way to do this without useMemo
  const rawModelNodes = React.useMemo(
    () => currentDiagram?.nodes || [],
    [currentDiagram?.nodes],
  );
  const rawModelLinks = React.useMemo(
    () => currentDiagram?.links || [],
    [currentDiagram?.links],
  );
  const rawModelAnnotations = React.useMemo(
    () => currentDiagram?.annotations || [],
    [currentDiagram?.annotations],
  );

  const selectedNodeIds = useAppSelector(
    (state) => state.model.present.selectedBlockIds,
  );

  const selectedLinkIds = useAppSelector(
    (state) => state.model.present.selectedLinkIds,
  );

  const selectedAnnotationIds = useAppSelector(
    (state) => state.model.present.selectedAnnotationIds,
  );

  const userOptions = useAppSelector((state) => state.userOptions.options);
  const modelKind = useAppSelector(
    (state) => state.submodels.topLevelModelType,
  );

  const currentSubdiagramType = useAppSelector((state) =>
    selectCurrentSubdiagramType(state.model.present),
  );

  const parentPath = useAppSelector(
    (state) => state.model.present.currentSubmodelPath,
  );
  const parentNamePath = useAppSelector(
    (state) =>
      getNamePath(
        state.model.present.rootModel.nodes,
        state.model.present.submodels,
        state.model.present.currentSubmodelPath,
        state.submodels.idToVersionIdToSubmodelFull,
        state.submodels.idToLatestTaggedVersionId,
      ),
    // since this is a string[] we can't use Object.is()
    shallowEqual,
  );

  const topLevelSubmodels = useAppSelector(
    (state) => state.model.present.submodels,
  );
  const topLevelNodes = useAppSelector(
    (state) => state.model.present.rootModel.nodes,
  );

  const rcMenuState = useAppSelector((state) => state.rcMenu);

  const multiplayerMouses = useMultiplayerMouses();

  const { project } = useProject();

  const refsObject = React.useRef<RendererRefsType>({
    parent: null,
    canvas: null,
    overlayCanvas2d: null,
    overlayCanvas2dCtx: null,
    externalOverlay: null,
    undoFunc: () => {},
    redoFunc: () => {},
    nodes: rawModelNodes,
    links: rawModelLinks,
    annotations: rawModelAnnotations,
    entitiesAreMutable: false,
    selectedNodeIds,
    selectedLinkIds,
    selectedAnnotationIds,
    nodesIndexLUT: {},
    linksIndexLUT: {},
    annotationsIndexLUT: {},
    connectedPortLUT: {},
    linksRenderingDependencyTree: {},
    nodeBorderColorLUT: {},
    signalPlotterStatesLUT: {},
    codeEditorOpen,
    visualizerPrefs,
    uiFlags,
    userOptions,
    modelKind,
    searchParams,
    setSearchParams,
    navigate,
    errorsState: { rootNode: { children: {} } },
    currentSubdiagramType,
    constantBlockDisplayValues: {},
    rcMenuState,
    multiplayerMouses,
    algebraicLoopLinkIdSet: algebraicLoopSet,
    subdocumentParentNode: undefined,
    project,
  });

  let subdocumentParentNode;
  if (parentPath.length > 0) {
    // might seem bad, but we also do this in BlockDetails.tsx, so it's fine for now.
    // unfortunately any technique for useMemo-ing this would actually worsen performance
    // or just not update when the parent node itself updates.
    subdocumentParentNode = getNestedNode(
      topLevelNodes,
      topLevelSubmodels,
      parentPath.slice(0, -1),
      parentPath.slice(-1)[0],
    );
  }
  refsObject.current.subdocumentParentNode = subdocumentParentNode;

  const updateAlgebraicLoopLinkIds = React.useCallback(
    (errors: ErrorsState, connectedPortLUT: PortConnLUTType) => {
      refsObject.current.algebraicLoopLinkIdSet = new Set<string>();

      const iteratingErrorNodes: ErrorTreeNode[] = [errors.rootNode];

      const loopSourceNodeIdToPortIndices: {
        [sourceNodeId: string]: number[];
      } = {};
      const loopDestinationNodeIdToPortIndices: {
        [destNodeId: string]: number[];
      } = {};

      while (iteratingErrorNodes.length) {
        const errorNode = iteratingErrorNodes.pop();
        if (!errorNode) continue;

        const childNodeIds = Object.keys(errorNode.children);
        if (childNodeIds.length) {
          for (let i = 0; i < childNodeIds.length; i++) {
            const nodeId = childNodeIds[i];
            const childNode = errorNode.children[nodeId];

            if (childNode) {
              iteratingErrorNodes.push(childNode);

              if (childNode.errorKind == 'AlgebraicLoopError') {
                if (childNode.outputPorts) {
                  loopSourceNodeIdToPortIndices[nodeId] = childNode.outputPorts;
                }
                if (childNode.inputPorts) {
                  loopDestinationNodeIdToPortIndices[nodeId] =
                    childNode.inputPorts;
                }
              }
            }
          }
        }
      }

      const allLoopSourceNodeIds = Object.keys(loopSourceNodeIdToPortIndices);

      // this may seem like overkill but we need to do this so that we don't highlight
      // links which are actually unrelated to the algebraic loop itself.
      // this makes sure all links we highlight (as a result of algebraic loops)
      // are actually connected to and from ports which are reported to be
      // part of an algebraic loop.
      // we could take more shortcuts or make more assumptions, but they would
      // all result in unrelated links being highlighted in some case.
      for (let i = 0; i < allLoopSourceNodeIds.length; i++) {
        const sourceNodeId = allLoopSourceNodeIds[i];
        const loopSourcePortIndices =
          loopSourceNodeIdToPortIndices[sourceNodeId];
        const sourceConnecionList = connectedPortLUT[sourceNodeId];
        for (let j = 0; j < sourceConnecionList.length; j++) {
          const sourceConnectionInfo = sourceConnecionList[j];
          if (
            !sourceConnectionInfo.fullyConnected ||
            sourceConnectionInfo.side !== PortSide.Output ||
            !loopSourcePortIndices.includes(sourceConnectionInfo.portId)
          ) {
            continue;
          }

          const loopLinkId = sourceConnectionInfo.linkUuid;
          const loopLink =
            refsObject.current.links[
              refsObject.current.linksIndexLUT[loopLinkId]
            ];
          if (!loopLink) continue;

          const iteratingLinks: LinkInstance[] = [loopLink];

          while (iteratingLinks.length) {
            const checkingLink = iteratingLinks.pop();

            if (!checkingLink || !checkingLink.src || !checkingLink.dst)
              continue;

            const dependentLinkIds =
              refsObject.current.linksRenderingDependencyTree[
                checkingLink.uuid
              ];

            if (dependentLinkIds) {
              for (let k = 0; k < dependentLinkIds.length; k++) {
                const addingLink =
                  refsObject.current.links[
                    refsObject.current.linksIndexLUT[dependentLinkIds[k]]
                  ];
                iteratingLinks.push(addingLink);
              }
            }

            const loopDestPortIndices =
              loopDestinationNodeIdToPortIndices[checkingLink.dst.node];
            if (
              !loopDestPortIndices ||
              !loopDestPortIndices.includes(checkingLink.dst.port)
            ) {
              continue;
            }

            refsObject.current.algebraicLoopLinkIdSet.add(checkingLink.uuid);
          }
        }
      }
    },
    [],
  );

  React.useEffect(() => {
    refsObject.current.multiplayerMouses = multiplayerMouses;
  }, [multiplayerMouses]);

  React.useEffect(() => {
    refsObject.current.rcMenuState = rcMenuState;
  }, [rcMenuState]);

  // FIXME: should be stable after v6.11 https://github.com/remix-run/react-router/issues/7634
  React.useEffect(() => {
    refsObject.current.navigate = navigate;
  }, [navigate]);

  React.useEffect(() => {
    refsObject.current.currentSubdiagramType = currentSubdiagramType;
  }, [currentSubdiagramType]);

  React.useEffect(() => {
    refsObject.current.setSearchParams = setSearchParams;
  }, [setSearchParams]);

  React.useEffect(() => {
    refsObject.current.searchParams = searchParams;
  }, [searchParams]);

  React.useEffect(() => {
    refsObject.current.errorsState = errorsState;

    updateAlgebraicLoopLinkIds(
      errorsState,
      refsObject.current.connectedPortLUT,
    );
  }, [errorsState, updateAlgebraicLoopLinkIds]);

  React.useEffect(() => {
    refsObject.current.modelKind = modelKind;
  }, [modelKind]);

  React.useEffect(() => {
    refsObject.current.visualizerPrefs = visualizerPrefs;
  }, [visualizerPrefs]);

  React.useEffect(() => {
    refsObject.current.userOptions = userOptions;
  }, [userOptions]);

  React.useEffect(() => {
    refsObject.current.codeEditorOpen = codeEditorOpen;
  }, [codeEditorOpen]);

  React.useEffect(() => {
    refsObject.current.selectedNodeIds = selectedNodeIds;
  }, [selectedNodeIds]);

  React.useEffect(() => {
    refsObject.current.selectedLinkIds = selectedLinkIds;
  }, [selectedLinkIds]);

  React.useEffect(() => {
    refsObject.current.selectedAnnotationIds = selectedAnnotationIds;
  }, [selectedAnnotationIds]);

  React.useEffect(() => {
    refsObject.current.uiFlags = uiFlags;
  }, [uiFlags]);

  React.useEffect(() => {
    if (!rendererState) return;

    if (uiFlags.addingAnnotation) {
      transitionMouseState(rendererState, {
        state: MouseActions.ReadyToDefineAnnotation,
      });
    } else {
      // remember that this only fires when 'addingAnnotation' changes,
      // so it's fine to do this here to ensure we return to 'Idle'
      // when the user manually toggles off the annotation mode.
      transitionMouseState(rendererState, {
        state: MouseActions.Idle,
      });
    }
  }, [uiFlags.addingAnnotation]);

  // just copying the above pattern for now
  React.useEffect(() => {
    if (!rendererState) return;

    if (uiFlags.addCommentToolActive) {
      transitionMouseState(rendererState, {
        state: MouseActions.ReadyToAddComment,
      });
    } else {
      // see above for caveat explanation
      transitionMouseState(rendererState, {
        state: MouseActions.Idle,
      });
    }
  }, [uiFlags.addCommentToolActive]);

  React.useEffect(() => {
    refsObject.current.nodes = rawModelNodes;
    refsObject.current.entitiesAreMutable = false;

    refsObject.current.nodesIndexLUT = rawModelNodes.reduce((acc, node, i) => {
      if (node.type === 'core.Constant') {
        refsObject.current.constantBlockDisplayValues[node.uuid] =
          memoizedCleanedConstBlockValue(node);
      }
      return { ...acc, [node.uuid]: i };
    }, {});
  }, [rawModelNodes]);

  React.useEffect(() => {
    refsObject.current.signalPlotterStatesLUT = rendererState
      ? buildSignalPlotterStatesLUT(
          rendererState,
          visualizerPrefs,
          rawModelNodes,
          parentNamePath,
        )
      : {};
  }, [rawModelNodes, parentNamePath, visualizerPrefs]);

  const signalsData = useAppSelector(
    (state) => state.compilationData.signalsData,
  );

  React.useEffect(() => {
    refsObject.current.nodeBorderColorLUT = rendererState
      ? buildNodeBorderColorLUT(
          rendererState,
          parentPath,
          parentNamePath,
          rawModelNodes,
          signalsData,
          uiFlags.showDatatypesInModel,
          uiFlags.showSignalDomainsInModel,
        )
      : {};
  }, [rawModelNodes, parentPath, parentNamePath, signalsData, uiFlags]);

  React.useEffect(() => {
    refsObject.current.annotations = rawModelAnnotations;
    refsObject.current.entitiesAreMutable = false;

    refsObject.current.annotationsIndexLUT = rawModelAnnotations.reduce(
      (acc, b, i) => ({ ...acc, [b.uuid]: i }),
      {},
    );
  }, [rawModelAnnotations]);

  React.useEffect(() => {
    refsObject.current.links = rawModelLinks;
    refsObject.current.entitiesAreMutable = false;

    refsObject.current.linksIndexLUT = {};
    refsObject.current.connectedPortLUT = {};
    refsObject.current.linksRenderingDependencyTree = {};

    for (let i = 0; i < rawModelLinks.length; i++) {
      const l = rawModelLinks[i];

      if (l.uiprops.link_type.connection_method == 'link_tap') {
        if (
          !refsObject.current.linksRenderingDependencyTree[
            l.uiprops.link_type.tapped_link_uuid
          ]
        ) {
          refsObject.current.linksRenderingDependencyTree[
            l.uiprops.link_type.tapped_link_uuid
          ] = [l.uuid];
        } else {
          refsObject.current.linksRenderingDependencyTree[
            l.uiprops.link_type.tapped_link_uuid
          ].push(l.uuid);
        }
      } else {
        if (!refsObject.current.linksRenderingDependencyTree.__no_dependency) {
          refsObject.current.linksRenderingDependencyTree.__no_dependency = [];
        }
        refsObject.current.linksRenderingDependencyTree.__no_dependency.push(
          l.uuid,
        );
      }

      if (
        l.src &&
        l.uiprops.link_type.connection_method === 'direct_to_block'
      ) {
        if (refsObject.current.connectedPortLUT[l.src.node]) {
          refsObject.current.connectedPortLUT[l.src.node].push({
            fullyConnected: Boolean(l.src && l.dst),
            side: l.src.port_side
              ? l.src.port_side === 'inputs'
                ? PortSide.Input
                : PortSide.Output
              : PortSide.Output,
            portId: l.src.port,
            linkUuid: l.uuid,
          });
        } else {
          refsObject.current.connectedPortLUT[l.src.node] = [
            {
              fullyConnected: Boolean(l.src && l.dst),
              side: l.src.port_side
                ? l.src.port_side === 'inputs'
                  ? PortSide.Input
                  : PortSide.Output
                : PortSide.Output,
              portId: l.src.port,
              linkUuid: l.uuid,
            },
          ];
        }
      }

      if (l.dst) {
        if (refsObject.current.connectedPortLUT[l.dst.node]) {
          refsObject.current.connectedPortLUT[l.dst.node].push({
            fullyConnected: Boolean(l.src && l.dst),
            side: l.dst.port_side
              ? l.dst.port_side === 'outputs'
                ? PortSide.Output
                : PortSide.Input
              : PortSide.Input,
            portId: l.dst.port,
            linkUuid: l.uuid,
          });
        } else {
          refsObject.current.connectedPortLUT[l.dst.node] = [
            {
              fullyConnected: Boolean(l.src && l.dst),
              side: l.dst.port_side
                ? l.dst.port_side === 'outputs'
                  ? PortSide.Output
                  : PortSide.Input
                : PortSide.Input,
              portId: l.dst.port,
              linkUuid: l.uuid,
            },
          ];
        }
      }

      refsObject.current.linksIndexLUT[l.uuid] = i;
    }
  }, [rawModelLinks, updateAlgebraicLoopLinkIds]);

  const { pastLength, futureLength } = useAppSelector((state) => ({
    pastLength: state.model.past.length,
    futureLength: state.model.future.length,
  }));

  React.useEffect(() => {
    refsObject.current.undoFunc = () => {
      if (pastLength > 0) {
        dispatch(UndoRedoActionCreators.undo());
      }
    };
  }, [pastLength, dispatch]);

  React.useEffect(() => {
    refsObject.current.redoFunc = () => {
      if (futureLength > 0) {
        dispatch(UndoRedoActionCreators.redo());
      }
    };
  }, [futureLength, dispatch]);

  React.useEffect(() => {
    if (refsObject.current.initializingNvgContext) return;
    if (refsObject.current.parent && refsObject.current.canvas) {
      refsObject.current.initializingNvgContext = initModelRenderer(
        refsObject,
        setTransform,
        dispatch,
      );
      refsObject.current.initializingNvgContext.finally(() => {
        refsObject.current.initializingNvgContext = undefined;
      });
    }
  }, [refsObject, setTransform, dispatch]);

  // cleanup on unmount
  React.useEffect(
    () => () => {
      if (refsObject.current.initializingNvgContext) return;
      unregisterRendererEvents();
      dispatch(uiFlagsActions.setUIFlag({ rendererStateInitialized: false }));
      endNanovg();
    },
    [dispatch],
  );

  const { coord, zoom, rerenderTransformData } = useAppSelector(
    (state) => state.camera,
  );

  React.useEffect(() => {
    if (rerenderTransformData) {
      externallySetRendererTransform(coord.x, coord.y, zoom);
      dispatch(cameraActions.unsetRerender());
    }
  }, [rerenderTransformData, coord, zoom, dispatch]);

  const setParentRef = React.useCallback(
    (el: HTMLDivElement) => (refsObject.current.parent = el),
    [],
  );

  const setCanvasRef = React.useCallback(
    (el: HTMLCanvasElement) => (refsObject.current.canvas = el),
    [],
  );

  const setOverlayCanvasRef = React.useCallback(
    (el: HTMLCanvasElement) => (refsObject.current.overlayCanvas2d = el),
    [],
  );

  const renderCanvas2d = useAppSelector(
    (state) => state.uiFlags.renderCanvas2d,
  );

  React.useEffect(() => {
    refsObject.current.externalOverlay = externalOverlayRef.current;
  }, [externalOverlayRef]);

  const handleMouseEnter = () => {
    dispatch(uiFlagsActions.setUIFlag({ hideLibraryDrag: true }));

    if (!rendererState) return;
    if (!dragContextData.state.dragging) return;

    const blockDisplayData: BlockDisplayData =
      dragContextData.state.dragPreviewCompProps;
    if (!blockDisplayData) return;

    if (rendererState.mouseState.state !== MouseActions.DragDropLibraryBlock) {
      transitionMouseState(rendererState, {
        state: MouseActions.DragDropLibraryBlock,
        blockClassName: blockDisplayData.blockClassName,
        referenceSubmodel: blockDisplayData.submodel,
        overridePropDefaults: blockDisplayData.overridePropDefaults,
        cursorOffset: dragContextData.state.cursorElementOffset,
      });
    }
  };

  // React on all relevant changes and request rendering
  React.useEffect(() => {
    requestFrameRender();
  }, [
    rawModelNodes,
    rawModelLinks,
    rawModelAnnotations,
    rcMenuState,
    multiplayerMouses,
    visualizerPrefs,
    userOptions,
    modelKind,
    currentSubdiagramType,
    errorsState,
    selectedNodeIds,
    selectedLinkIds,
    selectedAnnotationIds,
    uiFlags,
    codeEditorOpen,
    searchParams,
    project,
    rerenderTransformData,
    coord,
    zoom,
    pastLength,
    futureLength,
    signalsData,
  ]);

  const handleMouseLeave = () => {
    dispatch(uiFlagsActions.setUIFlag({ hideLibraryDrag: false }));

    if (!rendererState) return;
    if (!dragContextData.state.dragging) return;

    if (rendererState.mouseState.state === MouseActions.DragDropLibraryBlock) {
      transitionMouseState(rendererState, { state: MouseActions.Idle });
    }
  };

  return (
    <ModelRenderer
      data-test-id="model-renderer"
      ref={setParentRef}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}>
      <canvas
        id="model-renderer-canvas"
        data-test-id="model-renderer-canvas"
        style={{
          userSelect: 'none',
          outline: 'none',
        }}
        ref={setCanvasRef}
      />
      {RENDERER_EXPERIMENTAL_ENABLE_CANVAS2D && (
        <canvas
          id="model-renderer-overlay-canvas"
          data-test-id="model-renderer-canvas"
          style={{
            userSelect: 'none',
            outline: 'none',
            pointerEvents: 'none',
            opacity: renderCanvas2d ? 1 : 0,
          }}
          ref={setOverlayCanvasRef}
        />
      )}
    </ModelRenderer>
  );
};
