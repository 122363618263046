import styled from '@emotion/styled/macro';
import { t } from '@lingui/macro';
import { useProjects } from 'app/api/useProjects';
import { ModelKind } from 'app/apiGenerated/generatedApiTypes';
import { Project } from 'app/apiTransformers/convertAPIProjectToProject';
import { SubmodelInfoLiteUI } from 'app/apiTransformers/convertGetSubmodelsList';
import { VersionTagValues } from 'app/apiTransformers/convertPostSubmodelsFetch';
import { useAppSelector } from 'app/hooks';
import { getSpecificReferenceSubmodelById } from 'app/utils/submodelUtils';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from 'ui/common/Button/Button';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import { Model, Submodel } from 'ui/common/Icons/Standard';
import ArrowRight from 'ui/common/Icons/Standard/ArrowRight';
import {
  CloseButtonContainer,
  ModalBorderedContainer,
  ModalDisplayGroup,
  ModalMessageText,
  ModalScrollContainer,
} from 'ui/common/Modal/Modal';
import { useModal } from 'ui/common/Modal/useModal';
import { useAppParams } from 'util/useAppParams';

const SubmodelReferenceScrollContainer = styled(ModalScrollContainer)`
  width: 400px;
`;

const ReferenceModalMessageText = styled(ModalMessageText)`
  flex-grow: 1;
`;

const NavigateButton = styled(Button)`
  margin-right: ${({ theme }) => theme.spacing.large};
`;

export interface NamedSubmodelReferenceItem {
  kind: ModelKind;
  id: string;
  name: string;
}
export interface SubmodelReferenceItem {
  kind: ModelKind;
  uuid: string;
}

function findModelOrSubmodelName(
  projects?: Project[],
  idToVersionIdToSubmodelInfoLite?: Record<
    string,
    Record<string, SubmodelInfoLiteUI>
  >,
  item?: SubmodelReferenceItem,
): string {
  if (!projects || !idToVersionIdToSubmodelInfoLite || !item) {
    return '';
  }

  if (item.kind === 'Model') {
    for (let i = 0; i < projects.length; i++) {
      const project = projects[i];
      const model = project.models.find((model) => model.uuid === item.uuid);
      if (model) {
        return model.name;
      }
    }
  } else if (item.kind === 'Submodel') {
    const submodel = getSpecificReferenceSubmodelById(
      item.uuid,
      VersionTagValues.LATEST_VERSION,
      idToVersionIdToSubmodelInfoLite,
    );
    if (submodel) {
      return submodel.name;
    }
  }
  return '';
}

interface Props {
  references: SubmodelReferenceItem[];
}

const SubmodelReferenceModal: React.FC<Props> = ({ references }) => {
  const navigate = useNavigate();
  const { closeModal } = useModal();
  const { projectId } = useAppParams();

  const { projects } = useProjects();
  const idToVersionIdToSubmodelInfoLite = useAppSelector(
    (state) => state.submodels.idToVersionIdToSubmodelInfoLite,
  );

  const namedReferences: NamedSubmodelReferenceItem[] = references.map(
    (reference) => ({
      id: reference.uuid,
      kind: reference.kind,
      name: findModelOrSubmodelName(
        projects,
        idToVersionIdToSubmodelInfoLite,
        reference,
      ),
    }),
  );

  return (
    <>
      <SubmodelReferenceScrollContainer>
        <ModalDisplayGroup>
          <ModalMessageText>
            {t({
              id: 'submodelReferences.dialog.messageTextAboutDeletingReferences',
              message: `In order to delete this Submodel, you must delete all references to it in the following models and submodels:`,
            })}
          </ModalMessageText>
        </ModalDisplayGroup>
        <ModalBorderedContainer>
          {namedReferences.map((reference) => (
            <ModalDisplayGroup key={reference.id}>
              {reference.kind === 'Submodel' && <Submodel />}
              {reference.kind === 'Model' && <Model />}
              <ReferenceModalMessageText>
                {reference.name}
              </ReferenceModalMessageText>
              <NavigateButton
                variant={ButtonVariants.LargeTertiary}
                Icon={ArrowRight}
                onClick={() => {
                  navigate(`/projects/${projectId}/models/${reference.id}`);
                  closeModal();
                }}
              />
            </ModalDisplayGroup>
          ))}
        </ModalBorderedContainer>
      </SubmodelReferenceScrollContainer>

      <CloseButtonContainer>
        <Button
          type="button"
          variant={ButtonVariants.LargePrimary}
          onClick={closeModal}>
          {t({
            id: 'submodelReferences.dialog.closeButton.label',
            message: `Got it`,
          })}
        </Button>
      </CloseButtonContainer>
    </>
  );
};

export default SubmodelReferenceModal;
