import { v4 as uuid } from 'uuid';
import { t } from '@lingui/macro';
import {
  GetSnapshotReadAllApiResponse,
  SnapshotSummary,
  SnapshotCreationSource,
} from 'app/apiGenerated/generatedApiTypes';
import { areDatesOnTheSameDay, toDateStringOrNull } from 'util/dateUtils';

export type VersionSource = 'edit' | 'restore' | 'run' | 'bookmark';

export interface DiagramVersion {
  uuid: string;
  model_uuid: string;
  name: string;
  createdAt: string;
  editId: number | string;
  source: VersionSource;
  authorUuid: string;
  authorDisplayName: string;
  authorProfileImageUrl: string;
  isTagged: boolean;
}

export interface DiagramAutoSaveVersion {
  uuid: string;
  model_uuid: string;
  createdAt: string;
  editId: number | string;
  source: VersionSource;
}

export interface DiagramAutoSaveGroup {
  uuid: string;
  name: string;
  authorUuid: string;
  authorDisplayName: string;
  authorProfileImageUrl: string;
  autoSaveVersions: DiagramAutoSaveVersion[];

  /**
   * Version of most recent version in the group.
   */
  editId: number | string;
}

export type DiagramVersionItemData = DiagramVersion | DiagramAutoSaveGroup;

function convertCreationSouceToVersionSource(
  creationSource: SnapshotCreationSource,
): VersionSource {
  switch (creationSource) {
    case 'model_edited':
      return 'edit';
    case 'simulation_start':
      return 'run';
    case 'user_initiated':
      return 'bookmark';
    case 'snapshot_restored':
      return 'restore';
    default:
      return 'edit';
  }
}

function convertAPIProjectToProject(
  apiVersion: SnapshotSummary,
): DiagramVersion {
  return {
    uuid: apiVersion.uuid,
    model_uuid: apiVersion.model_uuid,
    name: apiVersion.name,
    createdAt: apiVersion.created_at,
    editId: apiVersion.model_version || '',
    source: convertCreationSouceToVersionSource(apiVersion.creation_source),
    authorUuid: apiVersion.owner?.uuid || '',
    authorDisplayName: apiVersion.owner?.display_name || '',
    authorProfileImageUrl: apiVersion.owner?.profile_image_url || '',
    isTagged: true,
  };
}

function createAutoSaveGroupName(autoSaveSnapshots: SnapshotSummary[]): string {
  const autoSaveDate = toDateStringOrNull(autoSaveSnapshots[0].created_at);

  if (autoSaveDate) {
    return t({
      id: 'diagramVersionHistory.autoSaveNameFromDateAndCount',
      message: '{formattedDate} ({numberOfAutoSaveVersions})',
      values: {
        formattedDate: autoSaveDate,
        numberOfAutoSaveVersions: autoSaveSnapshots.length,
      },
    });
  }

  if (autoSaveSnapshots.length === 1) {
    return t({
      id: 'diagramVersionHistory.singularAutoSaveName',
      message: '1 Autosave version',
    });
  }

  return t({
    id: 'diagramVersionHistory.pluralAutoSaveName',
    message: '{numberOfAutoSaveVersions} Autosave versions',
    values: { numberOfAutoSaveVersions: autoSaveSnapshots.length },
  });
}

function createAutoSaveGroup(
  autoSaveSnapshots: SnapshotSummary[],
): DiagramAutoSaveGroup {
  return {
    uuid: uuid(),
    name: createAutoSaveGroupName(autoSaveSnapshots),
    authorUuid: autoSaveSnapshots[0].owner?.uuid || '',
    authorDisplayName: autoSaveSnapshots[0].owner?.display_name || '',
    authorProfileImageUrl: autoSaveSnapshots[0].owner?.profile_image_url || '',
    autoSaveVersions: autoSaveSnapshots.map((snapshot) => ({
      uuid: snapshot.uuid,
      model_uuid: snapshot.model_uuid,
      createdAt: snapshot.created_at,
      editId: snapshot.model_version || '',
      source: convertCreationSouceToVersionSource(snapshot.creation_source),
    })),
    editId: autoSaveSnapshots[0].model_version || '',
  };
}

function shouldCompleteAutoSaveGroup(
  currentAutoSaveSnapshots: SnapshotSummary[],
  nextSnapshot: SnapshotSummary,
): boolean {
  if (currentAutoSaveSnapshots.length === 0) return false;

  if (nextSnapshot.creation_source === 'user_initiated') return true;

  // If the author changes, create a new autosave group for the new author.
  if (nextSnapshot.owner?.uuid !== currentAutoSaveSnapshots[0]?.owner?.uuid) {
    return true;
  }

  // If the day changes, create a new autosave group for the new day.
  const lastSnapshot =
    currentAutoSaveSnapshots[currentAutoSaveSnapshots.length - 1];
  if (!areDatesOnTheSameDay(nextSnapshot.created_at, lastSnapshot.created_at)) {
    return true;
  }

  return false;
}

export function convertGetSnapshotReadAll(
  apiResponse: GetSnapshotReadAllApiResponse,
): DiagramVersionItemData[] {
  const diagramVersionItemData: DiagramVersionItemData[] = [];

  // Combine autosaves into groups as part of the api data conversion process.
  let currentAutoSaveSnapshots: SnapshotSummary[] = [];
  apiResponse.snapshots?.forEach((snapshot) => {
    const completeAutoSaveGroup = shouldCompleteAutoSaveGroup(
      currentAutoSaveSnapshots,
      snapshot,
    );
    if (completeAutoSaveGroup) {
      diagramVersionItemData.push(
        createAutoSaveGroup(currentAutoSaveSnapshots),
      );
      currentAutoSaveSnapshots = [];
    }

    if (snapshot.creation_source === 'user_initiated') {
      diagramVersionItemData.push(convertAPIProjectToProject(snapshot));
    } else {
      currentAutoSaveSnapshots.push(snapshot);
    }
  });

  // Create the last autosave group, if needed.
  if (currentAutoSaveSnapshots.length) {
    diagramVersionItemData.push(createAutoSaveGroup(currentAutoSaveSnapshots));
  }

  return diagramVersionItemData;
}
