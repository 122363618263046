import { Model } from 'app/apiData';
import { ModelKind } from 'app/apiGenerated/generatedApiTypes';
import { Project } from 'app/apiTransformers/convertAPIProjectToProject';
import { SubmodelInfoLiteUI } from 'app/apiTransformers/convertGetSubmodelsList';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { submodelsActions } from 'app/slices/submodelsSlice';
import React from 'react';

interface Props {
  project: Project;
  modelId: string;
}

export function getTopLevelModelType(
  topLevelModel: Model | undefined,
  topLevelSubmodel: SubmodelInfoLiteUI | undefined,
): ModelKind | null {
  if (topLevelModel) {
    return topLevelModel.kind || 'Model';
  }
  if (topLevelSubmodel) {
    return 'Submodel';
  }
  return null;
}

export const TopLevelModelTracker: React.FC<Props> = ({ project, modelId }) => {
  const dispatch = useAppDispatch();

  // Determine whether the current model is a submodel or model.
  const submodels = useAppSelector(
    (state) => state.submodels.projectIdToSubmodelInfoLites[project.uuid],
  );
  const topLevelSubmodel = (submodels || []).find(
    (submodel) => submodel.id === modelId,
  );
  const topLevelModel = project.models.find((model) => model.uuid === modelId);

  const topLevelModelType = getTopLevelModelType(
    topLevelModel,
    topLevelSubmodel,
  );

  // Detect whether the top level model type has changed.
  React.useEffect(() => {
    dispatch(
      submodelsActions.setModelTypeForRequestedModel({
        topLevelModelId: modelId,
        topLevelModelType,
      }),
    );
  }, [dispatch, modelId, topLevelModelType]);

  return null;
};
