/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const PinEmpty: React.FC<Props> = (props) => (
  <svg viewBox="0 0 24 24" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M12.75 16.25H17.5C18.1442 16.25 18.8453 15.6926 18.6915 14.8655C18.3527 13.0446 16.6447 11.7038 14.6002 11.11L14.2824 7.75H15C15.9665 7.75 16.75 6.9665 16.75 6V5.5C16.75 4.5335 15.9665 3.75 15 3.75H9.00002C8.03353 3.75 7.25002 4.5335 7.25002 5.5V6C7.25002 6.9665 8.03353 7.75 9.00002 7.75H9.71763L9.39985 11.11C7.35532 11.7038 5.64733 13.0446 5.30859 14.8655C5.15473 15.6926 5.85586 16.25 6.50002 16.25H11.25V20.5C11.25 20.9142 11.5858 21.25 12 21.25C12.4142 21.25 12.75 20.9142 12.75 20.5V16.25ZM8.75002 5.5C8.75002 5.36193 8.86195 5.25 9.00002 5.25H15C15.1381 5.25 15.25 5.36193 15.25 5.5V6C15.25 6.13807 15.1381 6.25 15 6.25H14.0031C13.2581 6.25 12.6785 6.89758 12.7607 7.63804L13.1271 11.4355C13.1869 11.9732 13.5781 12.3857 14.0624 12.517C15.6748 12.954 16.7336 13.8302 17.1024 14.75H6.89764C7.26648 13.8302 8.32528 12.954 9.93768 12.517C10.4219 12.3857 10.8132 11.9732 10.8729 11.4355L11.2393 7.63804C11.3216 6.89758 10.742 6.25 9.99694 6.25H9.00002C8.86195 6.25 8.75002 6.13807 8.75002 6V5.5Z"
      fill={props.fill}
    />
  </svg>
);

PinEmpty.defaultProps = {
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default PinEmpty;
