import styled from '@emotion/styled';
import Button from 'ui/common/Button/Button';

import { keyframes, useTheme } from '@emotion/react';
import { useGetUserProfileQuery } from 'app/apiGenerated/generatedApi';
import { ReactElement } from 'react';
import { Account, AiAgent } from 'ui/common/Icons/Standard';
import { TEXT_WIDTH } from './Sizings';

export const ChatContentDiv = styled.div`
  white-space: pre-wrap;
  overflow-anchor: none;
  border: 1px solid ${({ theme }) => theme.colors.grey[10]};
`;

export const ShowContextButton = styled(Button)`
  align-items: center;
  width: 110px;
  margin-right: 10px;

  > span > span > label {
    // This v-aligns the label with the eye
    margin-bottom: 2px;
  }
`;

export const Row = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  padding: ${({ theme }) => theme.spacing.large};
  align-items: flex-start;
  width: 100%;
`;

export const UserRow = styled(Row)`
  // Note: should be #e3f7f6 to match design but the tint does not exist.
  // tint2 is a bit darker than #e3f7f6.
  background-color: ${({ theme }) => theme.colors.tint.tint2};
`;

export const AssistantRow = styled(Row)`
  background-color: ${({ theme }) => theme.colors.grey[2]};
`;

export const RemoveButton = styled(Button)`
  position: absolute;
  top: ${({ theme }) => theme.spacing.large};
  right: ${({ theme }) => theme.spacing.normal};
`;

export const MessageDiv = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: ${TEXT_WIDTH}px;
  line-height: ${({ theme }) => theme.typography.font.standard.lineHeight};
`;

const spin = keyframes`
from {
  transform: rotate(0deg);
}
to {
  transform: rotate(360deg);
}
`;

export const AvatarContainer = styled.div<{ solid?: boolean }>`
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin-right: 21px;
  background-color: ${({ solid, theme }) =>
    solid ? theme.colors.brand.tertiary.base : 'none'};
`;

export const UserAvatarImg = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 50%;
`;

export const MessageBlockDiv = styled.div`
  padding-bottom: ${({ theme }) => theme.spacing.large};
`;

export const SuggestionButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

export const SuggestionButton = styled(Button)`
  margin-right: ${({ theme }) => theme.spacing.large};
`;

export const PythonCodeInfoDiv = styled.div`
  font-style: italic;
`;

export const AiAgentSpinner = styled(AiAgent)`
  animation: ${spin} 1s infinite ease-in-out;
`;

export const AiAvatar = ({ spin }: { spin?: boolean }): ReactElement => {
  const theme = useTheme();
  return (
    <AvatarContainer>
      {spin ? (
        <AiAgentSpinner fill={theme.colors.brand.primary.base} />
      ) : (
        <AiAgent fill={theme.colors.brand.primary.base} />
      )}
    </AvatarContainer>
  );
};

export const UserAvatar = (): ReactElement => {
  const { data: userProfile } = useGetUserProfileQuery();
  const theme = useTheme();

  return (
    <AvatarContainer solid>
      {userProfile?.profile_image_url ? (
        <UserAvatarImg src={userProfile?.profile_image_url} />
      ) : (
        <Account fill={theme.colors.grey[2]} />
      )}
    </AvatarContainer>
  );
};
