import { useAppDispatch, useAppSelector } from 'app/hooks';
import { dataExplorerPlotDataActions } from 'app/slices/dataExplorerPlotDataSlice';
import { dataExplorerActions } from 'app/slices/dataExplorerSlice';
import { parseToNdArray } from 'app/utils/npy';
import { getDataBounds } from 'app/utils/visualizationUtils';

import React, { useEffect, useRef, useState } from 'react';
import { extractErrorMessage } from 'ui/common/notifications/errorFormatUtils';
import {
  PlotCellData,
  PlotDataRow,
  TraceContentResult,
  TraceLoadState,
  TraceResult,
} from 'ui/dataExplorer/dataExplorerTypes';
import { DataExplorerTraceLoader } from 'ui/dataExplorer/loaders/DataExplorerTraceLoader';

// FIXME: DASH-1751 remove this entire folder

/**
 * Checks if the trace's data changed by comparing the s3 keys.
 */
function traceLoadStatesEqual(
  prevTraceLoadStates: TraceLoadState[],
  traceLoadStates: TraceLoadState[],
) {
  if (prevTraceLoadStates.length !== traceLoadStates.length) {
    return false;
  }

  for (let i = 0; i < prevTraceLoadStates?.length; i++) {
    const prevTraceLoadState = prevTraceLoadStates[i];
    const keyExists = traceLoadStates.some(
      (traceLoadState) =>
        prevTraceLoadState.s3_url?.key === traceLoadState.s3_url?.key,
    );
    if (!keyExists) {
      return false;
    }
  }

  return true;
}

interface Props {
  cellId: string;
}

/**
 * A plot cell loader watches for changes in the plot cell and loads data accordingly.
 * The only exception is that it sets the plot cell's `initialBounds` so that the bounds are saved even when zoomed.
 */
export const DataExplorerPlotCellLoader: React.FC<Props> = ({ cellId }) => {
  const dispatch = useAppDispatch();

  const plotCell = useAppSelector(
    (state) => state.dataExplorer.idToPlotCell[cellId],
  );

  const prevTraceLoadStatesRef = useRef<TraceLoadState[]>();
  const initializedBoundsRef = useRef<boolean>(false);

  // ! We should not be fetching s3 files in a useEffect with raw `fetch`.
  // Rendering and the raw network calls result in repeated requests.
  // This is a bandaid. Attempt to use a fetching status ref.
  // Proper data cache needed.
  const fetchingTrace = useRef(false);

  const [prevTraceIds, setPrevTraceIds] = useState<string[]>();
  useEffect(() => {
    if (prevTraceIds !== plotCell.traceIds) {
      // Clear out the old data when the traces change to force a reload.
      // Does not trigger on a range change.
      dispatch(
        dataExplorerPlotDataActions.clearPlotCellData({
          plotCellId: plotCell.id,
        }),
      );
      setPrevTraceIds(plotCell.traceIds);
      initializedBoundsRef.current = false;
    }
  }, [dispatch, plotCell, prevTraceIds]);

  const idToTrace = useAppSelector((state) => state.dataExplorer.idToTrace);
  const plotCellData = useAppSelector(
    (state) => state.dataExplorerPlotData.idToPlotCellData[cellId],
  );
  const traceLoadStates = useAppSelector((state) => {
    if (plotCell) {
      const loadStates: TraceLoadState[] = [];
      plotCell.traceIds.forEach((traceId) => {
        const loadState =
          state.dataExplorerPlotData.idToTraceLoadState[traceId];
        if (loadState) {
          loadStates.push(loadState);
        }
      });
      if (loadStates.length > 0) {
        return loadStates;
      }
      return undefined;
    }
  });

  const [loadedData, setLoadedData] = React.useState<
    TraceContentResult[] | null
  >(null);

  // If we don't have plot cell data yet and the data is ready to fetch,
  // or if there is a change to the zoom bounds,
  // trigger the data fetch.
  React.useEffect(() => {
    // Due to how the data is fetched in multiple stages, we must check for the trace load state
    // The zoom bounds trigger a trace load state change, which then provides the new data points
    const zoomBoundsChanged = !traceLoadStatesEqual(
      prevTraceLoadStatesRef.current || [],
      traceLoadStates || [],
    );

    if (!(zoomBoundsChanged || !plotCellData)) {
      return;
    }

    if (zoomBoundsChanged) {
      prevTraceLoadStatesRef.current = traceLoadStates;
    }

    const areAllTracesReadyToFetch =
      traceLoadStates &&
      traceLoadStates.length === plotCell.traceIds.length &&
      traceLoadStates.every(
        (traceLoadState) =>
          traceLoadState.errorMessage || traceLoadState.s3_url,
      );

    if (areAllTracesReadyToFetch && !fetchingTrace.current) {
      // Fetch the csv files, tracking errors on a per trace basis.
      const tracePromises: Promise<TraceResult>[] = [];
      traceLoadStates.forEach((traceLoadState: TraceLoadState) => {
        if (traceLoadState.s3_url?.error) {
          tracePromises.push(
            Promise.resolve({
              traceId: traceLoadState.traceId,
              tracePath: traceLoadState.tracePath,
              errorMessage: extractErrorMessage(traceLoadState.s3_url?.error),
            }),
          );
        } else if (traceLoadState.s3_url?.url) {
          fetchingTrace.current = true;
          tracePromises.push(
            fetch(traceLoadState.s3_url.url)
              .then((data) => ({
                traceId: traceLoadState.traceId,
                tracePath: traceLoadState.tracePath,
                data,
              }))
              .catch((error) => ({
                traceId: traceLoadState.traceId,
                tracePath: traceLoadState.tracePath,
                errorMessage: extractErrorMessage(error),
              })),
          );
        } else if (traceLoadState.errorMessage) {
          tracePromises.push(
            new Promise((resolve, _) => {
              resolve({
                traceId: traceLoadState.traceId,
                tracePath: traceLoadState.tracePath,
                errorMessage: traceLoadState.errorMessage,
              });
            }),
          );
        }
      });

      // Read the csv files, tracking errors on a per trace basis.
      const allTraceContentPromise = Promise.all(tracePromises).then(
        (results) => {
          fetchingTrace.current = false;
          const traceContentPromises: Promise<TraceContentResult>[] = [];
          results.forEach((result: TraceResult) => {
            if (result.data) {
              traceContentPromises.push(
                result.data
                  .arrayBuffer()
                  .then((content) => parseToNdArray(content))
                  .then((content) => ({
                    traceId: result.traceId,
                    tracePath: result.tracePath,
                    content,
                  }))
                  .catch((error) => ({
                    traceId: result.traceId,
                    tracePath: result.tracePath,
                    errorMessage: extractErrorMessage(error),
                  })),
              );
            } else {
              traceContentPromises.push(
                Promise.resolve({
                  traceId: result.traceId,
                  tracePath: result.tracePath,
                  errorMessage: result.errorMessage,
                }),
              );
            }
          });
          return Promise.all(traceContentPromises);
        },
      );

      // Set the results so if this component is still alive when the load is complete,
      // we will update the redux store.
      allTraceContentPromise.then((contentResults: TraceContentResult[]) => {
        setLoadedData(contentResults);
      });
    }
  }, [plotCell, plotCellData, traceLoadStates]);

  // Update the redux store with the loaded data.
  React.useEffect(() => {
    if (loadedData) {
      const plotCellDataRaw: PlotCellData[] = loadedData
        .filter((data) => !!data.content)
        .map((data) => {
          const rows: PlotDataRow[] = [];
          if (data.content) {
            for (let i = 0; i < data.content.shape[0]; i++) {
              rows.push({
                time: data.content?.get(i, 0),
                [`${data.traceId}`]: data.content.get(i, 1),
              });
            }
          }
          return {
            traceId: data.traceId,
            rows,
            kind: 'rows',
          };
        });

      const rows = plotCellDataRaw.flatMap((data) =>
        data.kind === 'rows' ? data.rows : [],
      );
      const dataBounds = getDataBounds(rows);
      const plotCellDataErrors = loadedData
        .filter((data) => !!data.errorMessage)
        .map((data) => ({
          traceId: data.traceId,
          tracePath: data.tracePath,
          errorMessage: data.errorMessage || '',
        }));

      dispatch(
        dataExplorerPlotDataActions.updatePlotCellData({
          plotCellId: cellId,
          plotCellData: plotCellDataRaw,
          plotCellDataErrors,
        }),
      );
      // Only set the initial bounds if they are unset, aka the traces changed.
      // Do not do this on a zoom change.
      if (!initializedBoundsRef.current) {
        initializedBoundsRef.current = true;
        dispatch(
          dataExplorerActions.setPlotCellInitialBounds({
            cellId,
            bounds: dataBounds,
          }),
        );
      }
    }
  }, [dispatch, cellId, loadedData]);

  // Do initial load so the backend knows to create the results files in S3.
  return (
    <>
      {plotCell.traceIds.map((traceId) => {
        const trace = idToTrace[traceId];
        if (trace && trace.explorationSimId) {
          return (
            <DataExplorerTraceLoader
              key={traceId}
              modelId={trace.modelId}
              simulationId={trace.explorationSimId}
              traceId={traceId}
              tracePath={trace.tracePath}
              fromTime={plotCell.zoomBounds?.startX}
              toTime={plotCell.zoomBounds?.endX}
            />
          );
        }
        return null;
      })}
    </>
  );
};
