import { PortSide } from 'app/common_types/PortTypes';
import { blockClassLookup } from 'app/generated_blocks';
import {
  BlockClassName,
  BlockInstance,
  Parameter,
} from 'app/generated_types/SimulationModel';
import { useAppDispatch } from 'app/hooks';
import { ModelState } from 'app/modelState/ModelState';
import { modelActions } from 'app/slices/modelSlice';
import { Causality as FmiCausality } from 'app/third_party_types/fmi-2.0-modelDescription';
import {
  addNodeExtraParameter,
  removeNodeExtraParameter,
} from 'app/utils/parameterUtils';
import { addPort, removePort } from 'app/utils/portUtils';

/**
 * Returns ordered list of the extra block parameters since params are stored in an object, not an array.
 * Lifted from CodeBlockParameterDetails.tsx
 */
export function orderedExtraParameters(node: BlockInstance) {
  const blockClass = blockClassLookup(node.type);
  // Class-level parameters aren't allowed names
  // These are hidden from the user for the python script block.
  // FIXME: if we separate class and extra params, this may not be necessary
  const classParamNames =
    blockClass.parameter_definitions?.map((def) => def.name) || [];
  return Object.keys(node.parameters)
    .filter((paramName) => !classParamNames.includes(paramName))
    .sort((a: string, b: string): number => {
      // FIXME seriously, let's switch to arrays for (extra) params...
      const p1 = node.parameters[a] as Parameter;
      const p2 = node.parameters[b] as Parameter;
      // Ensure new parameters appear at the end of the list of params instead of alphabetically.
      if (p1.source === 'extra' && p2.source !== 'extra') return 1;
      if (p2.source === 'extra' && p1.source !== 'extra') return -1;
      if (p1.order === undefined || p2.order === undefined) {
        return a.localeCompare(b);
      }
      return p1.order - p2.order;
    });
}

const dynamicBlockTypes: BlockClassName[] = [
  'core.ModelicaFMU',
  'core.BusCreator',
  'core.BusSelector',
];

/**
 * Common interface for fmu and cpp function variables.
 * Only input, output, and parameter are implemented.
 * Block parameters don't differentiate display names and valid names, so we use the valid identifiers which are guaranteed unique.
 * The mangled `cml_name` for fmu, and `cname` for cpp function.
 */
export interface DynamicVariableCommon {
  id: string;
  causality?: FmiCausality;
  defaultValue?: string;
}

/**
 * Add ports and params according to the user uploaded configuration.
 */
export function configureDynamicBlock(
  state: ModelState,
  payload: {
    parentPath: string[];
    node: BlockInstance;
    dynamicVariables: DynamicVariableCommon[];
  },
) {
  const { parentPath, node, dynamicVariables: variables } = payload;
  if (!dynamicBlockTypes.includes(node.type)) {
    console.error(
      `configureDynamicBlock called on non-dynamic block ${
        node.uuid
      }} of type ${node.type} in ${parentPath.join()}`,
    );
    return;
  }

  const inputs = variables.filter(
    (variable: DynamicVariableCommon) => variable.causality === 'input',
  );
  const outputs = variables.filter(
    (variable: DynamicVariableCommon) => variable.causality === 'output',
  );

  // Insert in alphabetical order since we read them in alphabetical order from the object.
  // FIXME sort unnecessary once we use arrays for params.
  const params = variables
    .filter(
      (variable: DynamicVariableCommon) => variable.causality === 'parameter',
    )
    .sort((a, b) => a.id.localeCompare(b.id));

  params.forEach((param) => {
    addNodeExtraParameter(state, {
      parentPath,
      nodeUuid: node.uuid,
      name: param.id,
      defaultValue: param.defaultValue,
    });
  });

  inputs.forEach((input) => {
    addPort(state, {
      parentPath,
      nodeUuid: node.uuid,
      portSide: PortSide.Input,
      name: input.id,
    });
  });

  outputs.forEach((output) => {
    addPort(state, {
      parentPath,
      nodeUuid: node.uuid,
      portSide: PortSide.Output,
      name: output.id,
    });
  });
}

/**
 *  Remove all ports and params from the previous configuration
 */
export function resetDynamicBlock(
  state: ModelState,
  payload: {
    parentPath: string[];
    node: BlockInstance;
    portSide?: PortSide.Input | PortSide.Output;
  },
) {
  const { parentPath, node, portSide } = payload;
  if (!dynamicBlockTypes.includes(node.type)) {
    console.error(
      `resetDynamicBlock called on non-dynamic block ${node.uuid}} of type ${
        node.type
      } in ${parentPath.join()}`,
    );
    return;
  }

  if (portSide === undefined) {
    const extraParameterNames = orderedExtraParameters(node);
    extraParameterNames.forEach((paramName, _) => {
      removeNodeExtraParameter(state, {
        parentPath,
        nodeUuid: node.uuid,
        paramName,
      });
    });
  }

  // Ports only have index as an identifier, so we must delete from max index to min
  if (portSide === undefined || portSide === PortSide.Input) {
    for (let index = node.inputs.length - 1; index >= 0; index--) {
      removePort(state, {
        parentPath,
        nodeUuid: node.uuid,
        portSide: PortSide.Input,
        portId: index,
      });
    }
  }
  if (portSide === undefined || portSide === PortSide.Output) {
    for (let index = node.outputs.length - 1; index >= 0; index--) {
      removePort(state, {
        parentPath,
        nodeUuid: node.uuid,
        portSide: PortSide.Output,
        portId: index,
      });
    }
  }
}

export const updateExtraParameter =
  (
    dispatch: ReturnType<typeof useAppDispatch>,
    parentPath: string[],
    node: BlockInstance,
    paramName: string,
  ) =>
  (value: string) =>
    dispatch(
      modelActions.updateNodeExtraParameter({
        parentPath,
        nodeUuid: node.uuid,
        paramName,
        value,
      }),
    );
