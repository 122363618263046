/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const RunToHere: React.FC<Props> = (props) => (
  <svg viewBox="0 0 24 24" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M7 6.81435V17.6705L12.2239 12.0553L7 6.81435ZM5.5 6.2147C5.5 5.12476 6.86762 4.58849 7.64874 5.37215L13.4647 11.207C13.9215 11.6653 13.9343 12.3884 13.494 12.8616L7.67809 19.1132C6.91118 19.9376 5.5 19.4115 5.5 18.3012V6.2147Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M16 6.5C15.8619 6.5 15.75 6.61193 15.75 6.75V17.75C15.75 17.8881 15.8619 18 16 18H17C17.1381 18 17.25 17.8881 17.25 17.75V6.75C17.25 6.61193 17.1381 6.5 17 6.5H16ZM14.25 6.75C14.25 5.7835 15.0335 5 16 5H17C17.9665 5 18.75 5.7835 18.75 6.75V17.75C18.75 18.7165 17.9665 19.5 17 19.5H16C15.0335 19.5 14.25 18.7165 14.25 17.75V6.75Z"
      fill={props.fill}
    />
  </svg>
);

RunToHere.defaultProps = {
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default RunToHere;
