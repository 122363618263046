import { useModelSimulationStatus } from 'app/api/useModelSimulationStatus';
import React from 'react';

interface Props {
  modelId: string;
  correlationId: string;
  simulationId: string;
}

const SimulationStatusPoller: React.FC<Props> = ({
  modelId,
  correlationId,
  simulationId,
}) => {
  useModelSimulationStatus(modelId, correlationId, simulationId);
  return null;
};

export default SimulationStatusPoller;
