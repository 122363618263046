/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const RequestEdit: React.FC<Props> = (props) => (
  <svg viewBox="0 0 24 24" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M14.8107 6.5L11.7803 3.46967C11.4874 3.17678 11.0126 3.17678 10.7197 3.46967C10.4268 3.76256 10.4268 4.23744 10.7197 4.53033L11.9393 5.75H8.88555C8.56698 4.73572 7.61941 4 6.5 4C5.11929 4 4 5.11929 4 6.5C4 7.61941 4.73572 8.56698 5.75 8.88555V15.1145C4.73572 15.433 4 16.3806 4 17.5C4 18.8807 5.11929 20 6.5 20C7.88071 20 9 18.8807 9 17.5C9 16.3806 8.26428 15.433 7.25 15.1145V8.88555C8.02752 8.64135 8.64135 8.02752 8.88555 7.25H11.9393L10.7197 8.46967C10.4268 8.76256 10.4268 9.23744 10.7197 9.53033C11.0126 9.82322 11.4874 9.82322 11.7803 9.53033L14.8107 6.5ZM6.5 18.5C7.05228 18.5 7.5 18.0523 7.5 17.5C7.5 16.9477 7.05228 16.5 6.5 16.5C5.94772 16.5 5.5 16.9477 5.5 17.5C5.5 18.0523 5.94772 18.5 6.5 18.5ZM7.5 6.5C7.5 7.05228 7.05228 7.5 6.5 7.5C5.94772 7.5 5.5 7.05228 5.5 6.5C5.5 5.94772 5.94772 5.5 6.5 5.5C7.05228 5.5 7.5 5.94772 7.5 6.5Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M17.5 9C18.8807 9 20 7.88071 20 6.5C20 5.11929 18.8807 4 17.5 4C16.1193 4 15 5.11929 15 6.5C15 7.88071 16.1193 9 17.5 9ZM17.5 7.5C18.0523 7.5 18.5 7.05228 18.5 6.5C18.5 5.94772 18.0523 5.5 17.5 5.5C16.9477 5.5 16.5 5.94772 16.5 6.5C16.5 7.05228 16.9477 7.5 17.5 7.5Z"
      fill={props.fill}
    />
  </svg>
);

RequestEdit.defaultProps = {
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default RequestEdit;
