/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  className?: string;
}

const SimulationsIcon: React.FC<Props> = (props) => (
  <svg viewBox="0 0 24 24" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.22019 12.2539L10.25 10.4885L12.8862 15.0077C13.2721 15.6692 14.2279 15.6692 14.6138 15.0077L15.9308 12.75H16.5C16.9142 12.75 17.25 12.4142 17.25 12C17.25 11.5858 16.9142 11.25 16.5 11.25H15.6436C15.2879 11.25 14.959 11.4389 14.7798 11.7461L13.75 13.5115L11.1138 8.99229C10.7279 8.33078 9.7721 8.33078 9.38622 8.99229L8.06922 11.25H7.5C7.08579 11.25 6.75 11.5858 6.75 12C6.75 12.4142 7.08579 12.75 7.5 12.75H8.35641C8.71207 12.75 9.04098 12.5611 9.22019 12.2539Z"
      fill={props.fill}
    />
  </svg>
);

SimulationsIcon.defaultProps = {
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  className: '',
};

export default SimulationsIcon;
