/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Properties: React.FC<Props> = (props) => (
  <svg viewBox="0 0 16 16" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M4.5 6.5C3.67157 6.5 3 5.82843 3 5C3 4.17157 3.67157 3.5 4.5 3.5C5.32843 3.5 6 4.17157 6 5C6 5.82843 5.32843 6.5 4.5 6.5ZM4.5 5.5C4.22386 5.5 4 5.27614 4 5C4 4.72386 4.22386 4.5 4.5 4.5C4.77614 4.5 5 4.72386 5 5C5 5.27614 4.77614 5.5 4.5 5.5Z"
      fill={props.fill}
    />
    <path
      d="M13 5.5C13.2761 5.5 13.5 5.27614 13.5 5C13.5 4.72386 13.2761 4.5 13 4.5L7.5 4.5C7.22386 4.5 7 4.72386 7 5C7 5.27614 7.22386 5.5 7.5 5.5L13 5.5Z"
      fill={props.fill}
    />
    <path
      d="M13 9C13.2761 9 13.5 8.77614 13.5 8.5C13.5 8.22386 13.2761 8 13 8L7.5 8C7.22386 8 7 8.22386 7 8.5C7 8.77614 7.22386 9 7.5 9L13 9Z"
      fill={props.fill}
    />
    <path
      d="M13.5 12C13.5 12.2761 13.2761 12.5 13 12.5H7.5C7.22386 12.5 7 12.2761 7 12C7 11.7239 7.22386 11.5 7.5 11.5H13C13.2761 11.5 13.5 11.7239 13.5 12Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M3 8.5C3 9.32843 3.67157 10 4.5 10C5.32843 10 6 9.32843 6 8.5C6 7.67157 5.32843 7 4.5 7C3.67157 7 3 7.67157 3 8.5ZM4 8.5C4 8.77614 4.22386 9 4.5 9C4.77614 9 5 8.77614 5 8.5C5 8.22386 4.77614 8 4.5 8C4.22386 8 4 8.22386 4 8.5Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M4.5 13.5C3.67157 13.5 3 12.8284 3 12C3 11.1716 3.67157 10.5 4.5 10.5C5.32843 10.5 6 11.1716 6 12C6 12.8284 5.32843 13.5 4.5 13.5ZM4.5 12.5C4.22386 12.5 4 12.2761 4 12C4 11.7239 4.22386 11.5 4.5 11.5C4.77614 11.5 5 11.7239 5 12C5 12.2761 4.77614 12.5 4.5 12.5Z"
      fill={props.fill}
    />
  </svg>
);

Properties.defaultProps = {
  width: '16',
  height: '16',
  viewBox: '0 0 16 16',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Properties;
