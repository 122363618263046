import React from 'react';
import BackToCurrentButton from 'ui/navbar/BackToCurrentButton';
import RestoreButton from 'ui/navbar/RestoreButton';
import { useModelPermission } from 'ui/permission/useModelPermission';
import { useAppParams } from 'util/useAppParams';

export const ReadonlyControls: React.FC = () => {
  const { projectId, modelId, versionId } = useAppParams();
  const { canEditModelMetadata } = useModelPermission(
    projectId,
    modelId,
    versionId,
  );

  if (!versionId) return null;

  return (
    <>
      <BackToCurrentButton />
      {canEditModelMetadata && <RestoreButton />}
    </>
  );
};
