import styled from '@emotion/styled/macro';
import { SubmodelInfoUI } from 'app/apiTransformers/convertGetSubmodelsListForModelParent';
import { ComputationBlockClass } from 'app/generated_types/ComputationBlockClass';
import React from 'react';
import Button from 'ui/common/Button/Button';
import TooltipButton from 'ui/common/Button/TooltipButton';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import { Close, Plus } from 'ui/common/Icons/Standard';
import { BlockDocumentation } from 'ui/common/Inputs/BlockDocumentation';
import { Header, Title } from 'ui/common/Modal/Modal';
import PortalTooltip from 'ui/common/Tooltip/PortalTooltip';
import { TooltipPlacement } from 'ui/common/Tooltip/tooltipTypes';
import { H5 } from 'ui/common/typography/Typography';

interface Props {
  children: React.ReactNode;
  isButtonEnabled?: boolean;
  onButtonClick?: () => void;
  buttonTooltip?: string;
  helpUrl?: string;
  linkUrl?: string;
  blockDescription?: string;
  blockClass?: ComputationBlockClass;
  submodelReference?: SubmodelInfoUI;
  ButtonIcon?: React.FC<any>;
  buttonTint?: string;
  testId?: string;
  Action?: JSX.Element;
  noBorder?: boolean;
}

const SectionHeadingWrapper = styled.section<{ noBorder?: boolean }>`
  ${({ theme, noBorder }) => `
    display: flex;
    width: 100%;
    color: ${theme.colors.text.primary};
    margin-bottom: ${theme.spacing.normal};
    margin-left: ${theme.spacing.small};
    padding-left: ${theme.spacing.normal};
    padding-right: ${theme.spacing.normal};
    margin-top: ${theme.spacing.large};
    position: relative;

    ${
      noBorder
        ? ''
        : `
      &:not(:first-of-type) {
        &:before {
          content: '';
          position: absolute;
          height: 1px;
          top: -${theme.spacing.large};
          left: -${theme.spacing.normal};
          right: -${theme.spacing.normal};
          background: ${theme.colors.grey[5]};
        }
      }
    `
    }
  `}
`;
const SectionH5 = styled(H5)`
  flex: 1;
  display: flex;
  align-items: center;
`;

const TooltipWrapper = styled.div`
  width: 300px;
  padding: ${({ theme }) => theme.spacing.large};
  border: solid 1px ${({ theme }) => theme.colors.grey[10]};
`;

const SectionHeading: React.FC<Props> = ({
  children,
  isButtonEnabled = true,
  onButtonClick,
  buttonTooltip,
  helpUrl,
  linkUrl,
  blockDescription,
  ButtonIcon = Plus,
  buttonTint,
  testId,
  Action,
  blockClass,
  submodelReference,
  ...rest
}) => {
  const triggerElRef = React.useRef<HTMLButtonElement>(null);
  const [isTooltipOpen, setIsTooltipOpen] = React.useState<boolean>(false);

  const onClick =
    blockDescription || submodelReference
      ? () => setIsTooltipOpen(!isTooltipOpen)
      : onButtonClick;

  // The button itself can have a tooltip, which is different from the tooltip controlled by `isTooltipOpen`
  const showTooltipButton = !!(onClick && buttonTooltip);
  const showPlainButton = !!(onClick && !buttonTooltip);
  // All documentation will transition to a link: DASH-1528
  const showLink = !!linkUrl;

  return (
    <SectionHeadingWrapper {...rest}>
      <SectionH5 testId={`${testId}-heading-text`}>{children}</SectionH5>
      {showLink ? (
        <a target="_blank" rel="noopener noreferrer" href={linkUrl}>
          <Button
            variant={ButtonVariants.LargeTertiary}
            Icon={ButtonIcon}
            tint={buttonTint}
          />
        </a>
      ) : showTooltipButton ? (
        <TooltipButton
          testId={`${testId}-heading-button`}
          variant={ButtonVariants.LargeTertiary}
          Icon={ButtonIcon}
          onClick={onClick}
          disabled={!isButtonEnabled}
          tooltip={buttonTooltip}
        />
      ) : showPlainButton ? (
        <Button
          ref={triggerElRef}
          testId={`${testId}-heading-button`}
          variant={ButtonVariants.LargeTertiary}
          Icon={ButtonIcon}
          tint={buttonTint}
          onClick={onClick}
          disabled={!isButtonEnabled}
        />
      ) : null}
      {Action && Action}
      {isTooltipOpen && triggerElRef.current && (
        <PortalTooltip
          testId="block-info-tooltip"
          invertedColor={false}
          showBorder
          triggerEl={triggerElRef.current}
          placement={TooltipPlacement.BOTTOM_LEFT}>
          <TooltipWrapper>
            <Header>
              <Title>{children}</Title>
              <Button
                Icon={Close}
                onClick={onClick}
                testId="modal-close-button"
                variant={ButtonVariants.LargeTertiary}
              />
            </Header>

            <BlockDocumentation
              blockDescription={blockDescription}
              helpUrl={helpUrl}
              blockClass={blockClass}
              submodelReference={submodelReference}
            />
          </TooltipWrapper>
        </PortalTooltip>
      )}
    </SectionHeadingWrapper>
  );
};

export default SectionHeading;
