import { StateMachineDiagram } from '@collimator/model-schemas-ts';
import { PayloadAction } from '@reduxjs/toolkit';
import { Coordinate } from 'app/common_types/Coordinate';
import {
  StateLinkInstance,
  StateNodeInstance,
} from 'app/generated_types/SimulationModel';
import { ModelState } from 'app/modelState/ModelState';
import { modelActions } from 'app/slices/modelSlice';
import { uiFlagsActions } from 'app/slices/uiFlagsSlice';
import { AppDispatch, store } from 'app/store';
import { StateNodeSide } from 'state_machine_tempdir/SMETypes';
import {
  STATENODE_HEIGHT,
  STATENODE_WIDTH,
} from 'state_machine_tempdir/StateMachineNodeUI';
import { v4 as uuid } from 'uuid';
import { snapNumberToGrid } from './modelDataUtils';

export const addStateNode = (
  state: ModelState,
  action: PayloadAction<{
    stateMachineUuid: string;
    newStateNode: StateNodeInstance;
  }>,
) => {
  const { stateMachineUuid, newStateNode } = action.payload;
  const stateMachine = state.stateMachines?.[stateMachineUuid];
  if (!stateMachine) return;

  stateMachine.nodes.push(newStateNode);
};

type PriorityListSortData = { linkId: string; coord?: number };
type PriorityListSortDataList = Array<PriorityListSortData>;

const priorityListSortFunc = (
  a: PriorityListSortData,
  b: PriorityListSortData,
) => {
  const aCoord = a.coord ?? 0;
  const bCoord = b.coord ?? 0;
  if (aCoord < bCoord) return -1;
  if (aCoord > bCoord) return 1;
  return 0;
};

const getJustLinkId = (data: PriorityListSortData): string => data.linkId;

const calculateAndSetStateNodeExitPriority = (
  stateMachine: StateMachineDiagram,
  nodeId: string,
) => {
  const node = stateMachine.nodes.find((n) => n.uuid === nodeId);
  if (!node) return;

  const exitPriorityListTop: PriorityListSortDataList = [];
  const exitPriorityListRight: PriorityListSortDataList = [];
  const exitPriorityListDown: PriorityListSortDataList = [];
  const exitPriorityListLeft: PriorityListSortDataList = [];

  stateMachine.links.forEach((link) => {
    if (link.sourceNodeId === nodeId) {
      switch (link.uiprops.sourceSide) {
        case 'top':
          exitPriorityListTop.push({
            linkId: link.uuid,
            coord: link.uiprops.sourceCoord,
          });
          break;
        case 'right':
          exitPriorityListRight.push({
            linkId: link.uuid,
            coord: link.uiprops.sourceCoord,
          });
          break;
        case 'down':
          exitPriorityListDown.push({
            linkId: link.uuid,
            coord: link.uiprops.sourceCoord,
          });
          break;
        case 'left':
          exitPriorityListLeft.push({
            linkId: link.uuid,
            coord: link.uiprops.sourceCoord,
          });
          break;
      }
    }
  });

  if (node.exit_priority_list) {
    // TODO: remove this check eventually, its for outdated schema compat
    node.exit_priority_list = [
      ...exitPriorityListTop.sort(priorityListSortFunc).map(getJustLinkId),
      ...exitPriorityListRight.sort(priorityListSortFunc).map(getJustLinkId),
      ...exitPriorityListDown
        .sort(priorityListSortFunc)
        .map(getJustLinkId)
        .reverse(),
      ...exitPriorityListLeft
        .sort(priorityListSortFunc)
        .map(getJustLinkId)
        .reverse(),
    ];
  }
};

export const addStateLink = (
  state: ModelState,
  action: PayloadAction<{
    stateMachineUuid: string;
    newStateLink: StateLinkInstance;
  }>,
) => {
  const { stateMachineUuid, newStateLink } = action.payload;
  const stateMachine = state.stateMachines?.[stateMachineUuid];
  if (!stateMachine) return;

  stateMachine.links.push(newStateLink);

  if (newStateLink.sourceNodeId) {
    calculateAndSetStateNodeExitPriority(
      stateMachine,
      newStateLink.sourceNodeId,
    );
  }
};

export const repositionStateLink = (
  state: ModelState,
  action: PayloadAction<{
    stateMachineUuid: string;
    linkId: string;
    toNodeId: string;
    side: StateNodeSide;
    coord: number;
    forStart: boolean;
  }>,
) => {
  const { stateMachineUuid, linkId, toNodeId, side, coord, forStart } =
    action.payload;
  const stateMachine = state.stateMachines?.[stateMachineUuid];
  if (!stateMachine) return;

  const link = stateMachine.links.find((link) => link.uuid === linkId);
  if (!link) return;

  if (forStart) {
    link.sourceNodeId = toNodeId;
    link.uiprops.sourceSide = side;
    link.uiprops.sourceCoord = coord;
    calculateAndSetStateNodeExitPriority(stateMachine, toNodeId);
  } else {
    link.destNodeId = toNodeId;
    link.uiprops.destSide = side;
    link.uiprops.destCoord = coord;
  }
};

export const setStateMachineEntryPointConnection = (
  state: ModelState,
  action: PayloadAction<{
    stateMachineUuid: string;
    toNodeId: string;
    side: StateNodeSide;
    coord: number;
  }>,
) => {
  const { stateMachineUuid, toNodeId, side, coord } = action.payload;
  const stateMachine = state.stateMachines?.[stateMachineUuid];
  if (!stateMachine) return;

  stateMachine.entry_point = {
    dest_id: toNodeId,
    dest_side: side,
    dest_coord: coord,
  };
};

export const moveStateNodesByDelta = (
  state: ModelState,
  action: PayloadAction<{
    stateMachineUuid: string;
    nodeIds: Array<string>;
    delta: Coordinate;
  }>,
) => {
  const { stateMachineUuid, nodeIds, delta } = action.payload;
  const stateMachine = state.stateMachines?.[stateMachineUuid];
  if (!stateMachine) return;

  for (let i = 0; i < stateMachine.nodes.length; i++) {
    const node = stateMachine.nodes[i];
    if (nodeIds.includes(node.uuid)) {
      node.uiprops.x += delta.x;
      node.uiprops.y += delta.y;
    }
  }
};

export const snapStateNodesToGrid = (
  state: ModelState,
  action: PayloadAction<{
    stateMachineUuid: string;
  }>,
) => {
  const { stateMachineUuid } = action.payload;
  const stateMachine = state.stateMachines?.[stateMachineUuid];
  if (!stateMachine) return;

  for (let i = 0; i < stateMachine.nodes.length; i++) {
    const node = stateMachine.nodes[i];
    node.uiprops.x = snapNumberToGrid(node.uiprops.x);
    node.uiprops.y = snapNumberToGrid(node.uiprops.y);
  }
};

export const moveStateLinkCurveDeviationByDelta = (
  state: ModelState,
  action: PayloadAction<{
    stateMachineUuid: string;
    linkId: string;
    delta: Coordinate;
  }>,
) => {
  const { stateMachineUuid, linkId, delta } = action.payload;
  const stateMachine = state.stateMachines?.[stateMachineUuid];
  if (!stateMachine) return;

  const link = stateMachine.links.find((link) => link.uuid === linkId);
  if (!link) return;

  link.uiprops.curveDeviation.x += delta.x;
  link.uiprops.curveDeviation.y += delta.y;
};

export const setStateLinkGuard = (
  state: ModelState,
  action: PayloadAction<{
    stateMachineUuid: string;
    linkId: string;
    newGuard: string;
  }>,
) => {
  const { stateMachineUuid, linkId, newGuard } = action.payload;
  const stateMachine = state.stateMachines?.[stateMachineUuid];
  if (!stateMachine) return;

  const link = stateMachine.links.find((link) => link.uuid === linkId);
  if (!link) return;

  link.guard = newGuard;
};

export const setStateLinkAction = (
  state: ModelState,
  action: PayloadAction<{
    stateMachineUuid: string;
    linkId: string;
    newAction: string;
    actionIndex: number;
  }>,
) => {
  const { stateMachineUuid, linkId, newAction, actionIndex } = action.payload;
  const stateMachine = state.stateMachines?.[stateMachineUuid];
  if (!stateMachine) return;

  const link = stateMachine.links.find((link) => link.uuid === linkId);
  if (!link) return;

  if (!link.actions) link.actions = [newAction];
  link.actions[actionIndex] = newAction;
};

export const setStateMachineEntryPointAction = (
  state: ModelState,
  action: PayloadAction<{
    stateMachineUuid: string;
    newAction: string;
    actionIndex: number;
  }>,
) => {
  const { stateMachineUuid, newAction, actionIndex } = action.payload;
  const stateMachine = state.stateMachines?.[stateMachineUuid];
  if (!stateMachine) return;

  const link = stateMachine.entry_point;

  if (!link.actions) link.actions = [newAction];
  link.actions[actionIndex] = newAction;
};

export const deleteStateMachineEntities = (
  state: ModelState,
  action: PayloadAction<{
    stateMachineUuid: string;
    stateNodeIdsToDel: Array<string>;
    stateLinkIdsToDel: Array<string>;
  }>,
) => {
  const {
    stateMachineUuid,
    stateLinkIdsToDel: stateLinkIds,
    stateNodeIdsToDel: stateNodeIds,
  } = action.payload;
  const stateMachine = state.stateMachines?.[stateMachineUuid];
  if (!stateMachine) return;

  stateMachine.links = stateMachine.links.filter(
    (link) => !stateLinkIds.includes(link.uuid),
  );
  stateMachine.nodes = stateMachine.nodes.filter((node) => {
    const keep = !stateNodeIds.includes(node.uuid);

    if (keep) {
      node.exit_priority_list = node.exit_priority_list.filter(
        (prioLinkId) => !stateLinkIds.includes(prioLinkId),
      );
    }

    return keep;
  });
};

export const createNewStateMachineWithUuid = (
  state: ModelState,
  action: PayloadAction<string>,
) => {
  const newStateMachineUuid = action.payload;

  state.stateMachines = {
    ...state.stateMachines,
    [newStateMachineUuid]: {
      uuid: newStateMachineUuid,
      nodes: [],
      links: [],
      entry_point: {},
    },
  };
};

export const changeStateNodeName = (
  state: ModelState,
  action: PayloadAction<{
    stateMachineUuid: string;
    nodeId: string;
    newName: string;
  }>,
) => {
  const { stateMachineUuid, nodeId, newName } = action.payload;
  const stateMachine = state.stateMachines?.[stateMachineUuid];
  if (!stateMachine) return;

  const node = stateMachine.nodes.find((node) => node.uuid === nodeId);

  if (node) {
    node.name = newName;
  }
};

// The approach with dispatch((dispatch: AppDispatch, getState: GetStateFn)
// was taken from the other copy utils (model editor). I suppose it might be
// possible to avoid this via standard useSelector but this could trigger
// re-renders... maybe? Else I'm not sure why we used this for copy/paste.
type GetStateFn = typeof store.getState;

const copyStateMachineEntitiesInternal = (
  dispatch: AppDispatch,
  getState: typeof store.getState,
  payload: {
    stateMachineUuid: string;
    nodes: StateNodeInstance[];
    links: StateLinkInstance[];
    cut?: boolean;
  },
) => {
  const state = getState();
  const { stateMachineUuid, nodes, links, cut } = payload;

  dispatch(
    uiFlagsActions.setUIFlag({
      inAppClipboard: {
        ...state.uiFlags.inAppClipboard,
        stateMachineNodes: nodes,
        stateMachineLinks: links,
      },
    }),
  );

  if (cut) {
    dispatch(
      modelActions.deleteStateMachineEntities({
        stateMachineUuid,
        stateNodeIdsToDel: nodes.map((node) => node.uuid),
        stateLinkIdsToDel: links.map((link) => link.uuid),
      }),
    );
  }
};

export const copyStateMachineEntities = (
  dispatch: AppDispatch,
  payload: {
    stateMachineUuid: string;
    nodes: StateNodeInstance[];
    links: StateLinkInstance[];
    cut?: boolean;
  },
) => {
  dispatch((dispatch: AppDispatch, getState: GetStateFn) =>
    copyStateMachineEntitiesInternal(dispatch, getState, payload),
  );
};

export const pasteStateMachineEntities = (
  state: ModelState,
  action: PayloadAction<{
    stateMachineUuid: string;
    x: number;
    y: number;
    nodes: StateNodeInstance[];
    links: StateLinkInstance[];
  }>,
) => {
  const { stateMachineUuid, x, y, nodes, links } = action.payload;

  const smDiagram = state.stateMachines?.[stateMachineUuid];
  if (!smDiagram) return;

  let minX = Infinity;
  let minY = Infinity;
  let maxX = -Infinity;
  let maxY = -Infinity;
  for (let i = 0; i < nodes.length; i++) {
    const node = nodes[i];
    minX = Math.min(minX, node.uiprops.x);
    minY = Math.min(minY, node.uiprops.y);
    maxX = Math.max(maxX, node.uiprops.x + STATENODE_WIDTH);
    maxY = Math.max(maxY, node.uiprops.y + STATENODE_HEIGHT);
  }

  const dX = x - (maxX + minX) / 2;
  const dY = y - (maxY + minY) / 2;

  const nodeIdSet: Record<string, boolean> = nodes.reduce(
    (acc, node) => ({ ...acc, [node.uuid]: true }),
    { '': false },
  );

  // SM editor does not support hanging links.
  const validLinks = links.filter(
    (link) =>
      nodeIdSet[link.sourceNodeId ?? ''] && nodeIdSet[link.destNodeId ?? ''],
  );

  const newNodesIdMap: Record<string, string> = {};
  const newNodes: StateNodeInstance[] = [];
  const newLinks: StateLinkInstance[] = [];

  for (let i = 0; i < nodes.length; i++) {
    const node = nodes[i];
    const newNode = {
      ...node,
      uuid: uuid(),
      uiprops: {
        ...node.uiprops,
        x: node.uiprops.x + dX,
        y: node.uiprops.y + dY,
      },
    };
    newNodesIdMap[node.uuid] = newNode.uuid;
    newNodes.push(newNode);
  }

  for (let i = 0; i < validLinks.length; i++) {
    const link = validLinks[i];
    const newLink = { ...link, uuid: uuid() };
    newLink.sourceNodeId = newNodesIdMap[link.sourceNodeId ?? ''];
    newLink.destNodeId = newNodesIdMap[link.destNodeId ?? ''];
    newLinks.push(newLink);
  }

  smDiagram.nodes.push(...newNodes);
  smDiagram.links.push(...newLinks);
};

export const dispatchPasteStateMachineEntities = (
  dispatch: AppDispatch,
  payload: {
    stateMachineUuid: string;
    x: number;
    y: number;
  },
) => {
  const { stateMachineUuid, x, y } = payload;
  dispatch((dispatch: AppDispatch, getState: GetStateFn) => {
    const state = getState();
    const { stateMachineNodes, stateMachineLinks } =
      state.uiFlags.inAppClipboard;

    dispatch(
      modelActions.pasteStateMachineEntities({
        stateMachineUuid,
        x,
        y,
        nodes: stateMachineNodes,
        links: stateMachineLinks,
      }),
    );
  });
};
