import { useTheme } from '@emotion/react';
import styled from '@emotion/styled/macro';
import { t } from '@lingui/macro';
import { Project } from 'app/apiTransformers/convertAPIProjectToProject';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import TooltipButton from 'ui/common/Button/TooltipButton';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import { ChevronDown } from 'ui/common/Icons/Small';
import {
  Code,
  Model as ModelIcon,
  Plus,
  Submodel as SubmodelIcon,
} from 'ui/common/Icons/Standard';
import Menu from 'ui/common/Menu/Menu';
import { ItemType, MenuItemConfig } from 'ui/common/Menu/menuItemTypes';
import { useModal } from 'ui/common/Modal/useModal';
import AddReferenceSubmodelModal from 'ui/modelEditor/AddReferenceSubmodelModal';
import AddModelModal from '../AddModelModal';
import { CreateScriptModal } from '../CreateScriptModal';

const MenuItemIconGrouper = styled.div`
  display: flex;
  align-items: center;

  > svg:first-of-type {
    margin-right: ${({ theme }) => theme.spacing.normal};
  }
`;

interface Props {
  project: Project;
}

export const ProjectDetailNewItemButtons: React.FC<Props> = ({ project }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { triggerModal } = useModal();

  const onScriptCreated = (fileUuid: string) => {
    navigate(`/projects/${project.uuid}/code_editor/${fileUuid}`);
  };

  const openCreateScriptModal = () => {
    triggerModal(
      <CreateScriptModal
        projectId={project.uuid}
        onCreated={onScriptCreated}
      />,
      t({
        id: 'createScriptModal.title',
        message: 'Create new Python script',
      }),
    );
  };

  const openCreateModelModal = () => {
    triggerModal(
      <AddModelModal projectId={project.uuid} />,
      t`Create a new model`,
    );
  };

  const openCreateSubmodelModal = () => {
    triggerModal(
      <AddReferenceSubmodelModal projectId={project.uuid} />,
      t({
        id: 'dashboard.newSubmodelModal.title',
        message: 'Create a new submodel',
      }),
    );
  };

  const modelCreateMenuItems: MenuItemConfig[] = [
    {
      type: ItemType.Button,
      content: (
        <MenuItemIconGrouper>
          <ModelIcon fill={theme.colors.grey[50]} />
          {t({
            id: 'dashboard.projectsDetailHeader.newModelButton.Label',
            message: 'New Model',
          })}
        </MenuItemIconGrouper>
      ),

      onClick: openCreateModelModal,
    },
    {
      type: ItemType.Button,
      content: (
        <MenuItemIconGrouper>
          <SubmodelIcon fill={theme.colors.grey[50]} />
          {t({
            id: 'dashboard.projectsDetailHeader.newSubmodelButton.Label',
            message: 'New Submodel',
          })}
        </MenuItemIconGrouper>
      ),
      onClick: openCreateSubmodelModal,
    },
    {
      type: ItemType.Button,
      content: (
        <MenuItemIconGrouper>
          <Code fill={theme.colors.grey[50]} />
          {t({
            id: 'dashboard.projectsDetailHeader.newScriptButton.Label',
            message: 'New Script',
          })}
        </MenuItemIconGrouper>
      ),
      onClick: openCreateScriptModal,
    },
  ];

  const tooltip = t({
    id: 'dashboard.projectsDetailActionButtons.CreateButton.Label',
    message: 'Create',
  });

  return (
    <Menu items={modelCreateMenuItems}>
      <TooltipButton
        tooltip={tooltip}
        Icon={Plus}
        largeIcon
        variant={ButtonVariants.SmallPrimary}>
        <ChevronDown />
      </TooltipButton>
    </Menu>
  );
};
