import { useVersionedSubmodels } from 'app/api/useVersionedSubmodels';
import { SubmodelFetchItem } from 'app/apiGenerated/generatedApiTypes';
import { useAppSelector } from 'app/hooks';
import React from 'react';

export const ProjectSubmodelLoader: React.FC = () => {
  const { fetchSubmodels } = useVersionedSubmodels();

  const unfetchedSubmodels: SubmodelFetchItem[] = useAppSelector((state) =>
    state.submodels.submodelsToFetch
      .filter(
        (submodelToFetch) =>
          !submodelToFetch.isFetching && !submodelToFetch.notFoundReason,
      )
      .map((submodelToFetch) => ({
        submodel_uuid: submodelToFetch.submodelId,
        version: submodelToFetch.versionId,
      })),
  );

  React.useEffect(() => {
    if (unfetchedSubmodels.length) {
      fetchSubmodels(unfetchedSubmodels);
    }
  }, [unfetchedSubmodels, fetchSubmodels]);

  return null;
};
