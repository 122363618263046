/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const MatrixoperatorDotproduct: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M8.09805 22C8.00472 22 7.94405 21.9533 7.91605 21.86L7.45405 20.362C7.43539 20.3247 7.41205 20.306 7.38405 20.306H3.68805C3.66005 20.306 3.63672 20.3247 3.61805 20.362L3.15605 21.86C3.12805 21.9533 3.06738 22 2.97405 22H1.57405C1.51805 22 1.47605 21.986 1.44805 21.958C1.42005 21.9207 1.41538 21.8693 1.43405 21.804L4.47205 12.34C4.50005 12.2467 4.56072 12.2 4.65405 12.2H6.40405C6.49739 12.2 6.55805 12.2467 6.58605 12.34L9.63805 21.804C9.64739 21.8227 9.65205 21.846 9.65205 21.874C9.65205 21.958 9.60072 22 9.49805 22H8.09805ZM4.05205 18.948C4.04272 19.004 4.06138 19.032 4.10805 19.032H6.95005C7.00605 19.032 7.02472 19.004 7.00605 18.948L5.56405 14.258C5.55472 14.2207 5.54072 14.202 5.52205 14.202C5.50339 14.202 5.48939 14.2207 5.48005 14.258L4.05205 18.948Z"
      fill={props.fill}
    />
    <path
      d="M17.5001 17C17.5001 17.8284 16.8285 18.5 16.0001 18.5C15.1716 18.5 14.5001 17.8284 14.5001 17C14.5001 16.1716 15.1716 15.5 16.0001 15.5C16.8285 15.5 17.5001 16.1716 17.5001 17Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M28.4961 16.974C28.4401 16.946 28.4401 16.918 28.4961 16.89C29.3547 16.5073 29.7841 15.826 29.7841 14.846C29.7841 13.9593 29.5087 13.2967 28.9581 12.858C28.4074 12.4193 27.6234 12.2 26.6061 12.2H23.1901C23.1434 12.2 23.1014 12.2187 23.0641 12.256C23.0361 12.284 23.0221 12.3213 23.0221 12.368V21.832C23.0221 21.8787 23.0361 21.9207 23.0641 21.958C23.1014 21.986 23.1434 22 23.1901 22H26.7181C27.6701 22 28.4401 21.7713 29.0281 21.314C29.6254 20.8473 29.9241 20.1707 29.9241 19.284C29.9241 18.192 29.4481 17.422 28.4961 16.974ZM24.6461 13.67C24.6461 13.6233 24.6694 13.6 24.7161 13.6H26.6061C27.1007 13.6 27.4881 13.726 27.7681 13.978C28.0574 14.2207 28.2021 14.5567 28.2021 14.986C28.2021 15.406 28.0574 15.7373 27.7681 15.98C27.4881 16.2133 27.1007 16.33 26.6061 16.33H24.7161C24.6694 16.33 24.6461 16.3067 24.6461 16.26V13.67ZM27.8661 20.194C27.5861 20.4553 27.2034 20.586 26.7181 20.586H24.7161C24.6694 20.586 24.6461 20.5627 24.6461 20.516V17.744C24.6461 17.6973 24.6694 17.674 24.7161 17.674H26.6901C27.1847 17.674 27.5767 17.8047 27.8661 18.066C28.1554 18.318 28.3001 18.6727 28.3001 19.13C28.3001 19.578 28.1554 19.9327 27.8661 20.194Z"
      fill={props.fill}
    />
  </svg>
);

MatrixoperatorDotproduct.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default MatrixoperatorDotproduct;
