/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Discrete: React.FC<Props> = (props) => (
  <svg viewBox="0 0 24 24" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M8.25 6.75V17.5C8.25 18.1904 7.69036 18.75 7 18.75H4.5C4.08579 18.75 3.75 18.4142 3.75 18C3.75 17.5858 4.08579 17.25 4.5 17.25H6.75V6.5C6.75 5.80964 7.30964 5.25 8 5.25H10C10.6904 5.25 11.25 5.80964 11.25 6.5V17.25H12.75V6.5C12.75 5.80964 13.3096 5.25 14 5.25H16C16.6904 5.25 17.25 5.80964 17.25 6.5V17.25H19.5C19.9142 17.25 20.25 17.5858 20.25 18C20.25 18.4142 19.9142 18.75 19.5 18.75H17C16.3096 18.75 15.75 18.1904 15.75 17.5V6.75H14.25V17.5C14.25 18.1904 13.6904 18.75 13 18.75H11C10.3096 18.75 9.75 18.1904 9.75 17.5V6.75H8.25Z"
      fill={props.fill}
    />
  </svg>
);

Discrete.defaultProps = {
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Discrete;
