import { useTheme } from '@emotion/react';
import styled from '@emotion/styled/macro';
import React from 'react';
import { Exclamation } from 'ui/common/Icons/Standard';
import PortalTextTooltip from 'ui/common/Tooltip/PortalTextTooltip';
import { TooltipPlacement } from 'ui/common/Tooltip/tooltipTypes';
import { usePortalTooltip } from 'ui/common/Tooltip/usePortalTooltip';

interface Props {
  validationError: string;
  isInFocus: boolean;
  position?: 'absolute' | 'relative';
  tooltipPlacement?: TooltipPlacement;
}

const ErrorIconWrapper = styled.div<{ position: 'absolute' | 'relative' }>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: ${({ position }) => position};
  top: 0;
  aspect-ratio: 1;
  right: 0;
  margin-right: ${({ theme: { spacing } }) => spacing.small};
`;

const ErrorIconBackground = styled.div<{ padding: string }>`
  padding: ${({ padding }) => padding};
  height: 100%;
  width: 100%;
`;

const ErrorIcon = styled(Exclamation)`
  height: 20px;
  width: 20px;
`;

const ValidationError: React.FC<Props> = ({
  validationError,
  isInFocus,
  position = 'absolute',
  tooltipPlacement = TooltipPlacement.BOTTOM_LEFT,
}: Props) => {
  const theme = useTheme();

  const { triggerElRef, isTooltipOpen } = usePortalTooltip<HTMLDivElement>();

  const [shouldShowValidationError, setShouldShowValidationError] =
    React.useState<boolean>(false);

  React.useEffect(() => {
    setShouldShowValidationError(
      !!(
        triggerElRef.current &&
        validationError &&
        (isTooltipOpen || isInFocus)
      ),
    );
  }, [triggerElRef, isInFocus, validationError, isTooltipOpen]);

  return (
    <>
      <ErrorIconWrapper ref={triggerElRef} position={position}>
        <ErrorIconBackground padding={position === 'absolute' ? '2px' : '0px'}>
          <ErrorIcon fill={theme.colors.ui.error} />
        </ErrorIconBackground>
      </ErrorIconWrapper>
      {triggerElRef.current && shouldShowValidationError && (
        <PortalTextTooltip
          triggerEl={triggerElRef.current}
          contentText={validationError}
          placement={tooltipPlacement}
        />
      )}
    </>
  );
};

export default ValidationError;
