import { SubmodelInfoUI } from 'app/apiTransformers/convertGetSubmodelsListForModelParent';
import { ComputationBlockClass } from 'app/generated_types/ComputationBlockClass';
import React from 'react';
import { Information } from 'ui/common/Icons/Standard';
import SectionHeading from 'ui/common/Inputs/SectionHeading';

interface Props {
  blockTypeDisplayName: string;
  helpUrl?: string;
  description?: string;
  blockClass?: ComputationBlockClass;
  submodelReference?: SubmodelInfoUI;
}

export const BlockDetailTitle: React.FC<Props> = ({
  blockTypeDisplayName,
  helpUrl,
  description,
  blockClass,
  submodelReference,
}) => {
  if (helpUrl || description || submodelReference) {
    return (
      <SectionHeading
        testId="block-title"
        blockDescription={description}
        helpUrl={helpUrl}
        blockClass={blockClass}
        submodelReference={submodelReference}
        ButtonIcon={Information}>
        {blockTypeDisplayName}
      </SectionHeading>
    );
  }

  return (
    <SectionHeading testId="block-title">{blockTypeDisplayName}</SectionHeading>
  );
};
