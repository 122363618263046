/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  className?: string;
}

const Inport: React.FC<Props> = (props) => (
  <svg viewBox="0 0 16 16" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.08307 4.5C7.48491 4.5 7 4.98491 7 5.58307L7.58104 6.11585C7.74138 6.26287 8 6.14913 8 5.93158V5.58307C8 5.53719 8.03719 5.5 8.08307 5.5H11.9169C11.9628 5.5 12 5.53719 12 5.58307V11.4169C12 11.4628 11.9628 11.5 11.9169 11.5H8.08307C8.03719 11.5 8 11.4628 8 11.4169V11.0684C8 10.8509 7.74138 10.7371 7.58104 10.8842L7 11.4169C7 12.0151 7.48491 12.5 8.08307 12.5H11.9169C12.5151 12.5 13 12.0151 13 11.4169V5.58307C13 4.98491 12.5151 4.5 11.9169 4.5H8.08307Z"
      fill={props.fill}
    />
    <path
      d="M6.35355 6.14645C6.15829 5.95118 5.84171 5.95118 5.64645 6.14645C5.45118 6.34171 5.45118 6.65829 5.64645 6.85355L6.79289 8H3C2.72386 8 2.5 8.22386 2.5 8.5C2.5 8.77614 2.72386 9 3 9H6.79289L5.64645 10.1464C5.45118 10.3417 5.45118 10.6583 5.64645 10.8536C5.84171 11.0488 6.15829 11.0488 6.35355 10.8536L8.35355 8.85355C8.54882 8.65829 8.54882 8.34171 8.35355 8.14645L6.35355 6.14645Z"
      fill={props.fill}
    />
  </svg>
);

Inport.defaultProps = {
  width: '16',
  height: '16',
  viewBox: '0 0 16 16',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  className: '',
};

export default Inport;
