import {
  transformBackendConfiguration,
  transformBackendModelDiagram,
  transformBackendParameterDefinitions,
  transformBackendParameters,
  transformBackendStateMachines,
  transformBackendSubmodels as transformBackendSubdiagrams,
} from './simulationModelTransformers';
import {
  ModelConfiguration,
  ModelDiagram,
  ParameterDefinitions,
  Parameters,
  StateMachines,
  SubmodelsSection,
  VersionedModel,
} from './types';

export interface MigratedModelV0 extends VersionedModel {
  kind: 'Model' | 'Submodel';
  diagram: ModelDiagram;
  subdiagrams: SubmodelsSection;

  uuid?: string;
  project_uuid?: string;
  name?: string;
  description?: string;
  configuration?: ModelConfiguration;
  parameters?: Parameters;
  parameter_definitions?: ParameterDefinitions;
  state_machines?: StateMachines;
  submodel_configuration?: Parameters;
}

// Supports all kinds of legacy Model & Submodel JSONs
export const migrateModelDataNoneToV0 = (data: any): MigratedModelV0 => {
  if (!data) throw new Error('migrateModelDataNoneToV0: data is empty');

  const diagram = transformBackendModelDiagram(data.diagram ?? {});
  const subdiagrams: SubmodelsSection = ((data.subdiagrams ?? data.submodels) &&
    transformBackendSubdiagrams(data.subdiagrams ?? data.submodels)) || {
    diagrams: {},
    references: {},
  };
  const parameters =
    data.parameters && transformBackendParameters(data.parameters);
  const parameter_definitions =
    data.parameter_definitions &&
    transformBackendParameterDefinitions(data.parameter_definitions);
  const state_machines =
    (data.state_machines &&
      transformBackendStateMachines(data.state_machines)) ||
    {};
  const configuration =
    data.configuration && transformBackendConfiguration(data.configuration);

  // submodel.json contained 'SubmodelConfiguration' but API uses 'submodel_configuration'
  const rawSubConfig =
    data.submodel_configuration || data.SubmodelConfiguration;
  const submodel_configuration =
    rawSubConfig && transformBackendParameters(rawSubConfig);

  const detectedSubmodel =
    data.kind === 'Submodel' || rawSubConfig || parameter_definitions;
  const kind = data.kind ?? (detectedSubmodel ? 'Submodel' : 'Model');

  return {
    kind,
    diagram,
    uuid: data.uuid,
    name: data.name,
    project_uuid: data.project_uuid,
    description: data.description,
    configuration,
    parameters,
    subdiagrams,
    parameter_definitions,
    state_machines,
    submodel_configuration,
    schema_version: '0',
  };
};
