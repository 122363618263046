/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Experiment: React.FC<Props> = (props) => (
  <svg viewBox="0 0 24 24" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M10.5 14C9.39544 14 8.50001 14.8954 8.50001 16C8.50001 17.1046 9.39544 18 10.5 18C11.6046 18 12.5 17.1046 12.5 16C12.5 14.8954 11.6046 14 10.5 14ZM9.50001 16C9.50001 15.4477 9.94772 15 10.5 15C11.0523 15 11.5 15.4477 11.5 16C11.5 16.5523 11.0523 17 10.5 17C9.94772 17 9.50001 16.5523 9.50001 16Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M11 11.75C11 11.0596 11.5597 10.5 12.25 10.5C12.9404 10.5 13.5 11.0596 13.5 11.75C13.5 12.4404 12.9404 13 12.25 13C11.5597 13 11 12.4404 11 11.75ZM12.25 11.5C12.1119 11.5 12 11.6119 12 11.75C12 11.8881 12.1119 12 12.25 12C12.3881 12 12.5 11.8881 12.5 11.75C12.5 11.6119 12.3881 11.5 12.25 11.5Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M14.5 16C13.6716 16 13 16.6716 13 17.5C13 18.3284 13.6716 19 14.5 19C15.3284 19 16 18.3284 16 17.5C16 16.6716 15.3284 16 14.5 16ZM14 17.5C14 17.2239 14.2239 17 14.5 17C14.7761 17 15 17.2239 15 17.5C15 17.7761 14.7761 18 14.5 18C14.2239 18 14 17.7761 14 17.5Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M8.50001 3.25C8.08579 3.25 7.75001 3.58579 7.75001 4C7.75001 4.41421 8.08579 4.75 8.50001 4.75H9.25001V9.76393C9.25001 9.80274 9.24097 9.84102 9.22361 9.87574L5.05279 18.2174C4.47101 19.3809 5.31712 20.75 6.61804 20.75H17.382C18.6829 20.75 19.529 19.381 18.9472 18.2174L14.7764 9.87574C14.759 9.84102 14.75 9.80274 14.75 9.76393V4.75H15.5C15.9142 4.75 16.25 4.41421 16.25 4C16.25 3.58579 15.9142 3.25 15.5 3.25H8.50001ZM10.75 9.76393V4.75H13.25V9.76393C13.25 10.0356 13.3133 10.3036 13.4348 10.5466L17.6056 18.8882C17.6887 19.0544 17.5678 19.25 17.382 19.25H6.61804C6.4322 19.25 6.31132 19.0544 6.39443 18.8882L10.5653 10.5466C10.6868 10.3036 10.75 10.0356 10.75 9.76393Z"
      fill={props.fill}
    />
  </svg>
);

Experiment.defaultProps = {
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Experiment;
