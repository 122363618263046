/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  cx?: any;
  cy?: any;
  r?: any;
  stroke?: any;
  strokeWidth?: any;
  strokeLinecap?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const AcausalThermalTemperaturesensor: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <circle
      cx={props.cx}
      cy={props.cy}
      r="11.25"
      stroke={props.stroke}
      strokeWidth={props.strokeWidth}
    />
    <path
      d="M16 5.5V7.5"
      stroke={props.stroke}
      strokeWidth={props.strokeWidth}
      strokeLinecap={props.strokeLinecap}
    />
    <path
      d="M25.0938 10.75L23.3617 11.75"
      stroke={props.stroke}
      strokeWidth={props.strokeWidth}
      strokeLinecap={props.strokeLinecap}
    />
    <path
      d="M6.90625 10.75L8.6383 11.75"
      stroke={props.stroke}
      strokeWidth={props.strokeWidth}
      strokeLinecap={props.strokeLinecap}
    />
    <path
      d="M10.75 6.90673L11.75 8.63879"
      stroke={props.stroke}
      strokeWidth={props.strokeWidth}
      strokeLinecap={props.strokeLinecap}
    />
    <path
      d="M21.25 6.90673L20.25 8.63879"
      stroke={props.stroke}
      strokeWidth={props.strokeWidth}
      strokeLinecap={props.strokeLinecap}
    />
    <circle cx={props.cx} cy={props.cy} r={props.r} stroke={props.stroke} />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M14.7278 7.8181C14.6273 7.41625 14.2201 7.17193 13.8183 7.27239C13.4164 7.37285 13.1721 7.78006 13.2726 8.1819L14.8229 14.3831C15.1532 14.1422 15.5601 14 16.0002 14C16.0945 14 16.1872 14.0065 16.278 14.0191L14.7278 7.8181Z"
      fill={props.fill}
    />
    <path
      d="M2 16L4 16"
      stroke={props.stroke}
      strokeWidth={props.strokeWidth}
      strokeLinecap={props.strokeLinecap}
    />
    <path
      d="M28 16L30 16"
      stroke={props.stroke}
      strokeWidth={props.strokeWidth}
      strokeLinecap={props.strokeLinecap}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M13.7649 20.6281C13.4177 20.4022 13.3194 19.9376 13.5453 19.5904C13.7712 19.2432 14.2357 19.1449 14.5829 19.3708C14.8899 19.5705 15.1298 19.8195 15.2882 20.1209C15.446 20.4212 15.5004 20.7308 15.5004 21.0192C15.5004 21.5331 15.3207 22.0581 15.1889 22.4428L15.1719 22.4924C15.0164 22.9479 14.924 23.2434 14.924 23.4808C14.924 23.5791 14.9416 23.6388 14.964 23.6814C14.9858 23.7228 15.034 23.7903 15.1595 23.8719C15.5067 24.0978 15.605 24.5624 15.3791 24.9096C15.1532 25.2568 14.6886 25.3551 14.3414 25.1292C14.0345 24.9295 13.7945 24.6805 13.6361 24.3791C13.4784 24.0788 13.424 23.7692 13.424 23.4808C13.424 22.9669 13.6037 22.4419 13.7355 22.0572L13.7525 22.0076C13.908 21.5521 14.0004 21.2566 14.0004 21.0192C14.0004 20.9209 13.9828 20.8613 13.9604 20.8187C13.9386 20.7772 13.8904 20.7097 13.7649 20.6281ZM16.8414 20.6281C16.4942 20.4022 16.3959 19.9376 16.6218 19.5904C16.8477 19.2432 17.3123 19.1449 17.6595 19.3708C17.9664 19.5705 18.2064 19.8195 18.3648 20.1209C18.5226 20.4212 18.577 20.7308 18.577 21.0192C18.577 21.5331 18.3972 22.0581 18.2654 22.4428L18.2485 22.4924C18.0929 22.9479 18.0005 23.2434 18.0005 23.4808C18.0005 23.5791 18.0181 23.6388 18.0405 23.6814C18.0623 23.7228 18.1106 23.7903 18.236 23.8719C18.5832 24.0978 18.6815 24.5624 18.4557 24.9096C18.2298 25.2568 17.7652 25.3551 17.418 25.1292C17.1111 24.9295 16.8711 24.6805 16.7127 24.3791C16.5549 24.0788 16.5005 23.7692 16.5005 23.4808C16.5005 22.9669 16.6803 22.4419 16.812 22.0572L16.812 22.0572L16.829 22.0076C16.9846 21.5521 17.077 21.2566 17.077 21.0192C17.077 20.9209 17.0593 20.8613 17.0369 20.8187C17.0152 20.7772 16.9669 20.7097 16.8414 20.6281Z"
      fill={props.fill}
    />
  </svg>
);

AcausalThermalTemperaturesensor.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  cx: '16',
  cy: '16',
  r: '1.5',
  stroke: '#1D3131',
  strokeWidth: '1.5',
  strokeLinecap: 'round',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default AcausalThermalTemperaturesensor;
