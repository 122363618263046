import styled from '@emotion/styled/macro';
import React from 'react';

const CheckboxAnchor = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: ${({ theme }) => theme.spacing.small};
`;

const CheckboxWrapper = styled.div<{ isDisabled?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 18px;
  height: 18px;
  margin: 4px;
  ${({ isDisabled }) => (isDisabled ? 'opacity: 0.2' : '')};
`;

enum CheckboxState {
  Unchecked,
  Checked,
  Indeterminant,
}

type Props = {
  isDisabled?: boolean;
  value?: boolean | CheckboxState;
  onChange?: (b: boolean) => void;
  testId?: string;
};

export const Checkbox: React.FC<Props> = ({
  isDisabled,
  value,
  onChange,
  testId,
}: Props): React.ReactElement => {
  const clickHandler = React.useCallback(
    (e: React.MouseEvent) => {
      if (isDisabled) return;
      if (onChange) onChange(!value);
      e.stopPropagation();
    },
    [value, onChange, isDisabled],
  );

  const renderedIcon = () => {
    // typecast true and false to Checked and Unchecked
    switch (Number(value)) {
      case CheckboxState.Checked:
        return (
          <img src={`${process.env.PUBLIC_URL}/assets/checkbox_checked.svg`} />
        );
      case CheckboxState.Indeterminant:
        return (
          <img
            src={`${process.env.PUBLIC_URL}/assets/checkbox_indeterminate.svg`}
          />
        );
      case CheckboxState.Unchecked:
      default:
        return (
          <img
            src={`${process.env.PUBLIC_URL}/assets/checkbox_unchecked.svg`}
          />
        );
    }
  };

  return (
    <CheckboxAnchor>
      <CheckboxWrapper
        onClick={clickHandler}
        isDisabled={isDisabled}
        data-test-id={testId}>
        {renderedIcon()}
      </CheckboxWrapper>
    </CheckboxAnchor>
  );
};

export const CheckboxForTableCell = ({ cell }: { cell: any }) => {
  const CheckboxWrapper = styled.div`
    display: flex;
    height: 24px;
  `;
  return (
    <CheckboxWrapper>
      <Checkbox
        isDisabled={false}
        value={cell.row.isSelected}
        onChange={() => cell.row.toggleRowSelected()}
      />
    </CheckboxWrapper>
  );
};

export const CheckboxForTableHeader: React.FC<any> = (cell: any) => {
  const CheckboxWrapper = styled.div`
    padding-left: 2px;
  `;

  const hasRowSelected = cell.rows.some(
    (row: Record<string, unknown>) => row.isSelected,
  );
  return (
    <CheckboxWrapper>
      <Checkbox
        isDisabled={false}
        value={
          cell.isAllRowsSelected
            ? CheckboxState.Checked
            : hasRowSelected
            ? CheckboxState.Indeterminant
            : CheckboxState.Unchecked
        }
        onChange={() => cell.toggleAllRowsSelected(!hasRowSelected)}
      />
    </CheckboxWrapper>
  );
};
