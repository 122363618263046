import { useTheme } from '@emotion/react';
import styled from '@emotion/styled/macro';
import React, { RefObject } from 'react';
import { DropdownDefault, DropdownExpanded } from 'ui/common/Icons/Standard';
import PortalMenu, { PortalMenuBackground } from 'ui/common/Menu/PortalMenu';
import PortalTextTooltip from 'ui/common/Tooltip/PortalTextTooltip';
import { TooltipPlacement } from 'ui/common/Tooltip/tooltipTypes';
import { usePortalTooltip } from 'ui/common/Tooltip/usePortalTooltip';

const NavbarButtonWrapper = styled.button<{
  enabled: boolean;
  selected: boolean;
}>`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: ${({ enabled, theme }) =>
    enabled ? theme.colors.grey[10] : theme.colors.grey[30]};
  cursor: ${({ enabled }) => (enabled ? 'pointer' : 'not-allowed')};
  background: none;
  height: ${({ theme }) => theme.sizes.navbarHeight};
  white-space: nowrap;
  &:hover {
    ${({ selected, theme }) =>
      selected
        ? ''
        : `background-color: ${theme.colors.brand.primary.darkest};`}
  }
  ${({ selected, theme }) =>
    selected ? `background-color: ${theme.colors.grey[85]}` : ''};
  padding: 0 ${(props) => props.theme.spacing.small};
  border: none;
  font-size: ${(props) => props.theme.typography.font.base.size};

  > img {
    padding: 0 ${(props) => props.theme.spacing.small};
  }

  > span {
    padding: 0 ${(props) => props.theme.spacing.small};
  }
`;

export const NavbarSpace = styled.div`
  width: ${({ theme }) => theme.spacing.large};
  height: 100%;
`;
const AppendedDropdownExpanded = styled(DropdownExpanded)`
  margin: 0 -${({ theme }) => theme.spacing.small};
`;

const AppendedDropdownDefault = styled(DropdownDefault)`
  margin: 0 -${({ theme }) => theme.spacing.small};
`;

const NavbarSplitButtonWrapper = styled.div`
  display: flex;
  > div:first-of-type > button {
    padding-right: ${({ theme }) => theme.spacing.xsmall};
  }
  > div:last-of-type > button {
    padding: 0;
  }
`;

export const NavbarMenuBackground = styled(PortalMenuBackground)<{
  wide?: boolean;
}>`
  color-scheme: dark;
  background-color: ${(props) => props.theme.colors.grey[85]};
  width: ${({ wide }) => (wide ? '340px' : '256px')};
  padding-bottom: ${({ theme }) => theme.spacing.normal};
  border-radius: ${(props) => props.theme.spacing.xsmall};
`;

export const NavbarSplitButtonBackground = styled(PortalMenuBackground)`
  color-scheme: dark;
  background-color: ${(props) => props.theme.colors.grey[85]};
  border-radius: ${(props) => props.theme.spacing.xsmall};
  padding: ${({ theme }) => theme.spacing.small} 0;
  > li:not(:last-of-type) {
    margin-bottom: ${({ theme }) => theme.spacing.xsmall};
  }
`;

interface NavbarButtonInnerProps {
  buttonText?: string;
  tooltipText?: string;
  Icon?: React.FC<any>;
  isEnabled: boolean;
  onClickHandler: () => void;
  testId: string;
  highlighted?: boolean;
  wrapperRef: RefObject<HTMLDivElement>;
  isTooltipOpen: boolean;
  children?: React.ReactNode;
}

type NavbarButtonProps = Omit<
  NavbarButtonInnerProps,
  'wrapperRef' | 'isTooltipOpen'
>;

type NavbarDropdownButtonProps = Omit<NavbarSplitButtonProps, 'onClickHandler'>;

interface NavbarSplitButtonProps extends NavbarButtonProps {
  DropdownContent: React.FC<{ triggerClose: () => void }>;
  dropdownOffset?: number;
}

// Spoke w/ Kori about the wrapper refs that made extracting the button difficult.
// The bug reported (popup sticking) was not repro-able, so it's likely part of speculative fixes that can be cleaned up.
// - jj 2023-03-28

/* preserved old comment - Put a wrapper around the button because the button will stop registering mouse events if it becomes disabled and we need all the mouse events in order to have reliable tooltip showing and hiding behaviors. */
const NavbarButtonInner: React.FC<NavbarButtonInnerProps> = ({
  children,
  buttonText,
  tooltipText,
  Icon,
  isEnabled,
  onClickHandler,
  testId,
  highlighted,
  wrapperRef,
  isTooltipOpen,
}) => {
  const theme = useTheme();

  const triggerClickHandlerIfEnabled = () => {
    if (isEnabled) {
      onClickHandler();
    }
  };

  return (
    <NavbarButtonWrapper
      id={testId}
      data-test-id={testId}
      enabled={isEnabled}
      selected={!!highlighted}
      onClick={triggerClickHandlerIfEnabled}>
      {Icon && (
        <Icon
          fill={isEnabled ? theme.colors.grey[10] : theme.colors.grey[30]}
        />
      )}
      {buttonText && <span>{buttonText}</span>}
      {children}
      {wrapperRef.current && isTooltipOpen && tooltipText && (
        <PortalTextTooltip
          triggerEl={wrapperRef.current}
          contentText={tooltipText}
          placement={TooltipPlacement.BOTTOM_CENTER}
        />
      )}
    </NavbarButtonWrapper>
  );
};

export const NavbarButton: React.FC<NavbarButtonProps> = (props) => {
  const { triggerElRef, isTooltipOpen } = usePortalTooltip<HTMLDivElement>();

  return (
    <div ref={triggerElRef}>
      <NavbarButtonInner
        {...props}
        isTooltipOpen={isTooltipOpen}
        wrapperRef={triggerElRef}
      />
    </div>
  );
};

export const NavbarDropdownButton: React.FC<NavbarDropdownButtonProps> = ({
  isEnabled,
  highlighted,
  DropdownContent,
  dropdownOffset,
  ...restProps
}) => {
  const theme = useTheme();
  const [isMenuOpen, setIsMenuOpen] = React.useState<boolean>(false);
  const { triggerElRef, isTooltipOpen } = usePortalTooltip<HTMLDivElement>();

  return (
    <>
      <div ref={triggerElRef}>
        <NavbarButtonInner
          {...restProps}
          isEnabled={isEnabled}
          highlighted={highlighted || isMenuOpen}
          onClickHandler={() => setIsMenuOpen(!isMenuOpen)}
          wrapperRef={triggerElRef}
          isTooltipOpen={isTooltipOpen && !isMenuOpen}>
          {isMenuOpen ? (
            <AppendedDropdownExpanded
              fill={isEnabled ? theme.colors.grey[10] : theme.colors.grey[70]}
            />
          ) : (
            <AppendedDropdownDefault
              fill={isEnabled ? theme.colors.grey[10] : theme.colors.grey[70]}
            />
          )}
        </NavbarButtonInner>
      </div>
      {isMenuOpen && triggerElRef.current && DropdownContent && (
        <PortalMenu
          triggerEl={triggerElRef.current}
          triggerClose={() => setIsMenuOpen(false)}
          leftMargin={dropdownOffset}
          bottomMargin={theme.sizes.footerBarHeight}>
          <DropdownContent triggerClose={() => setIsMenuOpen(false)} />
        </PortalMenu>
      )}
    </>
  );
};

export const NavbarSplitButton: React.FC<NavbarSplitButtonProps> = ({
  isEnabled,
  DropdownContent,
  testId,
  dropdownOffset, // use a number to fine tune the placement to align with primary button
  ...restProps
}) => (
  <NavbarSplitButtonWrapper>
    <NavbarButton
      {...restProps}
      isEnabled={isEnabled}
      testId={`${testId}-primary`}
    />
    <NavbarDropdownButton
      DropdownContent={DropdownContent}
      isEnabled={isEnabled}
      testId={`${testId}-dropdown`}
      dropdownOffset={dropdownOffset}
    />
  </NavbarSplitButtonWrapper>
);
