import styled from '@emotion/styled';
import { useAppSelector } from 'app/hooks';
import { getNestedNode } from 'app/utils/modelDiagramUtils';
import {
  arrayToStringParam,
  setNavigationURLParams,
  setSelectionURLParams,
} from 'app/utils/URLParamsUtils';
import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { STATE_MACHINE_EDITOR_BLOCK_QUERY_PARAM } from 'state_machine_tempdir/StateMachineEditor';
import { CODE_EDITOR_BLOCK_QUERY_PARAM } from 'ui/codeEditor/CodeEditor';
import Breadcrumb, {
  BreadcrumbElement,
  BreadcrumbMenu,
} from 'ui/common/Breadcrumb/Breadcrumb';
import Button from 'ui/common/Button/Button';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import MenuIcon from 'ui/common/Icons/Small/Menu';
import { ItemType } from 'ui/common/Menu/menuItemTypes';
import { TooltipPlacement } from 'ui/common/Tooltip/tooltipTypes';
import ModelEditorBreadcrumbLink from 'ui/modelEditor/BreadcrumbLink';
import { useAppParams } from 'util/useAppParams';

interface LinkData {
  parentPath?: string[];
  nodeInstanceId?: string;
  knownDisplayName?: string;
  clickable?: boolean;
  onClick?: () => void;
}

const BreadcrumbWrapper = styled.div(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing.normal,
  left: theme.spacing.normal,
  zIndex: 1,
}));

const ModelEditorBreadcrumb: React.FC = () => {
  const { projectId } = useAppParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const codeEditorQuery = searchParams.get(CODE_EDITOR_BLOCK_QUERY_PARAM);
  const stateMachineQuery = searchParams.get(
    STATE_MACHINE_EDITOR_BLOCK_QUERY_PARAM,
  );
  const modelName = useAppSelector((state) => state.model.present.name);
  const submodels = useAppSelector((state) => state.model.present.submodels);
  const nodes = useAppSelector((state) => state.model.present.rootModel.nodes);
  const idToVersionIdToSubmodelFull = useAppSelector(
    (state) => state.submodels.idToVersionIdToSubmodelFull,
  );
  const idToLatestTaggedVersionId = useAppSelector(
    (state) => state.submodels.idToLatestTaggedVersionId,
  );

  const submodelPath = [
    ...useAppSelector((state) => state.model.present.currentSubmodelPath),
  ];
  const currentSubmodelPath = useAppSelector(
    (state) => state.model.present?.currentSubmodelPath,
  );

  const lastSubmodelPathItem = submodelPath.pop();

  const firstPathItem: LinkData = {
    knownDisplayName: modelName,
    clickable: true,
  };
  const linkDataItems: LinkData[] = [firstPathItem];

  let currentParentPath: string[] = [];
  if (submodelPath.length > 0) {
    submodelPath.forEach((nodeInstanceId) => {
      linkDataItems.push({
        parentPath: currentParentPath,
        nodeInstanceId,
        clickable: true,
      });
      currentParentPath = [...currentParentPath, nodeInstanceId];
    });
  }
  if (lastSubmodelPathItem) {
    linkDataItems.push({
      parentPath: currentParentPath,
      nodeInstanceId: lastSubmodelPathItem,
      clickable: Boolean(codeEditorQuery || stateMachineQuery),
    });
  }

  if (codeEditorQuery) {
    const [codeBlockId, paramName] = codeEditorQuery.split('.') || [];
    if (codeBlockId) {
      linkDataItems.push({
        parentPath: currentParentPath,
        nodeInstanceId: codeBlockId,
        clickable: true,
        onClick: () => {
          searchParams.delete(CODE_EDITOR_BLOCK_QUERY_PARAM);
          searchParams.set(
            CODE_EDITOR_BLOCK_QUERY_PARAM,
            `${codeBlockId}.${paramName}`,
          );
          setSearchParams(searchParams);
        },
      });
    }
  }

  if (stateMachineQuery) {
    const [stateMachineBlockId] = stateMachineQuery.split('.') || [];
    linkDataItems.push({
      parentPath: currentParentPath,
      nodeInstanceId: stateMachineBlockId,
      clickable: false,
    });
  }

  if (!projectId) {
    return null;
  }

  if (linkDataItems.length > 3) {
    const menuItems = linkDataItems.slice(0, linkDataItems.length - 3);
    const breadcrumbItems = linkDataItems.slice(-3);

    return (
      <BreadcrumbWrapper>
        <Breadcrumb>
          <BreadcrumbMenu
            placement={TooltipPlacement.BOTTOM_LEFT}
            items={menuItems.reverse().map((item) => {
              const node = getNestedNode(
                nodes,
                submodels,
                item.parentPath,
                item.nodeInstanceId,
                idToVersionIdToSubmodelFull,
                idToLatestTaggedVersionId,
              );

              return {
                type: ItemType.Button,
                onClick: () => {
                  const newSearchParams = new URLSearchParams();
                  const nextParentPath =
                    item.parentPath && item.nodeInstanceId
                      ? [...item.parentPath, item.nodeInstanceId]
                      : [];
                  setNavigationURLParams(newSearchParams, {
                    parentPath: nextParentPath,
                  });
                  setSelectionURLParams(newSearchParams, {
                    selectionParentPath: nextParentPath,
                    selectedBlockIds: [
                      currentSubmodelPath[nextParentPath.length],
                    ],
                  });
                  setSearchParams(newSearchParams);
                },
                content: item.knownDisplayName || node?.name || '',
              };
            })}>
            <Button variant={ButtonVariants.SmallTertiary} Icon={MenuIcon} />
          </BreadcrumbMenu>
          {breadcrumbItems.map((item) => (
            <BreadcrumbElement
              key={`${arrayToStringParam(item.parentPath || [])}-${
                item.nodeInstanceId
              }`}>
              <ModelEditorBreadcrumbLink
                key={`breadcrumb_${item.nodeInstanceId || 'root'}`}
                projectId={projectId}
                nodes={nodes}
                submodels={submodels}
                parentPath={item.parentPath}
                nodeInstanceId={item.nodeInstanceId}
                knownDisplayName={item.knownDisplayName}
                clickable={!!item.clickable}
                onClick={item.onClick}
              />
            </BreadcrumbElement>
          ))}
        </Breadcrumb>
      </BreadcrumbWrapper>
    );
  }

  return (
    <BreadcrumbWrapper>
      <Breadcrumb>
        {linkDataItems.map((linkDataItem) => (
          <ModelEditorBreadcrumbLink
            key={`breadcrumb_${linkDataItem.nodeInstanceId || 'root'}`}
            projectId={projectId}
            nodes={nodes}
            submodels={submodels}
            parentPath={linkDataItem.parentPath}
            nodeInstanceId={linkDataItem.nodeInstanceId}
            knownDisplayName={linkDataItem.knownDisplayName}
            clickable={!!linkDataItem.clickable}
            onClick={linkDataItem.onClick}
          />
        ))}
      </Breadcrumb>
    </BreadcrumbWrapper>
  );
};

export default ModelEditorBreadcrumb;
