import type { Coordinate } from 'app/common_types/Coordinate';
import { HoverEntityType, MouseActions } from 'app/common_types/MouseTypes';
import { nodeTypeIsCode, nodeTypeIsSubdiagram } from 'app/helpers';
import { getCurrentModelRef } from 'app/sliceRefAccess/CurrentModelRef';
import { modelActions } from 'app/slices/modelSlice';
import { rcMenuActions } from 'app/slices/rcMenuSlice';
import { uiFlagsActions } from 'app/slices/uiFlagsSlice';
import {
  setNavigationURLParams,
  setSelectionURLParams,
} from 'app/utils/URLParamsUtils';
import { specialGetStateMachineNodeInstanceId } from 'app/utils/insertNodeUtils';
import { snapNumberToGrid } from 'app/utils/modelDataUtils';
import { STATE_MACHINE_EDITOR_BLOCK_QUERY_PARAM } from 'state_machine_tempdir/StateMachineEditor';
import { CODE_EDITOR_BLOCK_QUERY_PARAM } from 'ui/codeEditor/CodeEditor';
import { fileFromPath } from 'ui/modelEditor/BlockParameterDetails/LeafSystemBlockParameterDetails';
import { convertZoomedScreenToWorldCoordinates } from '../convertScreenToWorldCoordinates';
import { getHoveringEntity } from '../getHoveringEntities';
import { RendererState } from '../modelRenderer';
import { isEntityInteractable } from './isEntityInteractable';
import { leaveDrawingLinkHangingHandler } from './leaveLinkHangingHandler';
import { tapFakeSegmentThunk, tapNormalSegment } from './linkTapping';

export const mouseInputDoubleClick = (
  rs: RendererState,
  coord: Coordinate,
): void => {
  rs.dispatch(rcMenuActions.close());

  const { x, y } = coord;
  const worldCursor = convertZoomedScreenToWorldCoordinates(rs.camera, {
    x,
    y,
  });

  // we don't use "rs.hoveringEntity" here
  // because this event uses the mouse coordinates
  // at the exact time of the click.
  // this event fires at some point after that click,
  // so we need to click where the mouse "previously was"
  const hoveringEntity = getHoveringEntity(
    rs.mouseState,
    worldCursor,
    rs.camera,
    rs.zoom,
    rs.refs.current.nodes,
    rs.refs.current.links,
    rs.refs.current.annotations,
    rs.refs.current.linksIndexLUT,
    rs.linksRenderFrameData,
    getCurrentModelRef().submodelPath,
  );

  if (!hoveringEntity) {
    if (rs.mouseState.state == MouseActions.Idle) {
      const unzoomedScreenCoord = { x: x * rs.zoom, y: y * rs.zoom };
      rs.dispatch(
        uiFlagsActions.setUIFlag({
          showingCommandPalette: rs.refs.current.uiFlags.canEditModel
            ? !rs.refs.current.uiFlags.showingCommandPalette
            : false,
          commandPaletteCoordScreenSpace: unzoomedScreenCoord,
          commandPaletteCoordWorldSpace: worldCursor,
        }),
      );
    }

    // FIXME: if drawing a link from start or end, we have already processed
    // a single left click, which created a segment. We should remove that
    // segment and replace it with just the hang point.

    leaveDrawingLinkHangingHandler(rs, worldCursor);
  } else {
    if (hoveringEntity && !isEntityInteractable(rs, hoveringEntity)) {
      return;
    }

    switch (hoveringEntity.entityType) {
      case HoverEntityType.TapPoint:
        tapNormalSegment(
          rs,
          hoveringEntity.tappedLinkUuid,
          hoveringEntity.tappedSegmentId,
          {
            x: snapNumberToGrid(worldCursor.x),
            y: snapNumberToGrid(worldCursor.y),
          },
        );
        break;

      case HoverEntityType.Node:
        const blockUuid = hoveringEntity.block.uuid;

        rs.dispatch(
          modelActions.setSelections({
            selectionParentPath: getCurrentModelRef().submodelPath,
            selectedBlockIds: [blockUuid],
            selectedLinkIds: [],
            selectedAnnotationIds: [],
          }),
        );

        const blockIndex = rs.refs.current.nodesIndexLUT[blockUuid];
        const block =
          blockIndex !== undefined
            ? rs.refs.current.nodes[blockIndex]
            : undefined;

        if (!block) {
          break;
        }

        if (nodeTypeIsSubdiagram(block.type)) {
          const nextParentPath = [
            ...getCurrentModelRef().submodelPath,
            block.uuid,
          ];
          const searchParams = rs.refs.current.searchParams;
          setNavigationURLParams(searchParams, { parentPath: nextParentPath });
          setSelectionURLParams(searchParams, {
            selectionParentPath: nextParentPath,
          });
          rs.refs.current.setSearchParams(searchParams);
        } else if (
          block.type === 'core.CustomLeafSystem' &&
          (!block.parameters.inline ||
            block.parameters.inline?.value === 'false')
        ) {
          if (!block.parameters.file_path?.value || !rs.refs.current.project) {
            break;
          }
          const file = fileFromPath(
            block.parameters.file_path.value,
            rs.refs.current.project,
          );
          if (file) {
            rs.refs.current.navigate(
              `/projects/${rs.refs.current.project.uuid}/code_editor/${file.uuid}`,
            );
          }
          break;
        } else if (nodeTypeIsCode(block.type)) {
          rs.refs.current.searchParams.append(
            CODE_EDITOR_BLOCK_QUERY_PARAM,
            blockUuid,
          );
          rs.refs.current.setSearchParams(rs.refs.current.searchParams);
        } else if (block.type === 'core.StateMachine') {
          const stateMachineId = specialGetStateMachineNodeInstanceId(block);
          if (stateMachineId) {
            rs.refs.current.searchParams.append(
              STATE_MACHINE_EDITOR_BLOCK_QUERY_PARAM,
              `${block.uuid}.${stateMachineId}`,
            );
            rs.refs.current.setSearchParams(rs.refs.current.searchParams);
          }
        }
        break;

      case HoverEntityType.NodeName:
        if (rs.refs.current.uiFlags.canEditModel) {
          rs.dispatch(
            uiFlagsActions.setUIFlag({
              editingNodeNameUUID: hoveringEntity.block.uuid,
            }),
          );
        }
        break;

      case HoverEntityType.AnnotationText:
        if (rs.refs.current.uiFlags.canEditModel) {
          rs.dispatch(
            uiFlagsActions.setUIFlag({
              editingAnnotationTextUUID: hoveringEntity.uuid,
            }),
          );
        }
        break;

      case HoverEntityType.FakeLinkSegment: {
        rs.dispatch(tapFakeSegmentThunk({ rs, hoveringEntity, worldCursor }));
        break;
      }

      case HoverEntityType.Link:
        tapNormalSegment(
          rs,
          hoveringEntity.linkUuid,
          hoveringEntity.segmentId,
          worldCursor,
        );
        break;
    }
  }
};
