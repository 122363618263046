/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  className?: string;
}

const Share: React.FC<Props> = (props) => (
  <svg viewBox="0 0 24 24" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.46967 8.46966C8.17678 8.76255 8.17678 9.23743 8.46967 9.53032C8.76256 9.82321 9.23744 9.82321 9.53033 9.53032L11.25 7.81065L11.25 15C11.25 15.4142 11.5858 15.75 12 15.75C12.4142 15.75 12.75 15.4142 12.75 15L12.75 7.81065L14.4697 9.53032C14.7626 9.82322 15.2374 9.82322 15.5303 9.53032C15.8232 9.23743 15.8232 8.76256 15.5303 8.46966L12 4.93933L8.46967 8.46966Z"
      fill={props.fill}
    />
    <path
      d="M6.75 14.5C6.75 14.0858 6.41421 13.75 6 13.75C5.58579 13.75 5.25 14.0858 5.25 14.5V18C5.25 18.6903 5.80964 19.25 6.5 19.25H17.5C18.1904 19.25 18.75 18.6903 18.75 18V14.5C18.75 14.0858 18.4142 13.75 18 13.75C17.5858 13.75 17.25 14.0858 17.25 14.5V17.75H6.75V14.5Z"
      fill={props.fill}
    />
  </svg>
);

Share.defaultProps = {
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  className: '',
};

export default Share;
