import styled from '@emotion/styled/macro';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
  OverridenParamSetting,
  ParamOptions,
  ParamTypes,
  overridenParamsMap,
} from 'app/slices/ensembleSimsSlice';
import { entityPreferencesActions } from 'app/slices/entityPreferencesSlice';
import React, { Fragment, useState } from 'react';
import Button from 'ui/common/Button/Button';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import { Close, Reset } from 'ui/common/Icons/Standard';
import Input from 'ui/common/Input/Input';
import ValidationError from 'ui/common/Input/ValidationError';
import { usePython } from 'ui/common/PythonProvider';
import SelectInput from 'ui/common/SelectInput';
import { EnsembleTab } from 'ui/userPreferences/ensembleSimModelPrefs';

const ParametersBox = styled.div<{
  noTopMargin?: boolean;
  noDisplay?: boolean;
}>`
  // scroll-behavior: smooth;
  overflow-y: auto;
  max-height: 230px;
  flex-shrink: 0;
  border: 1px solid ${({ theme }) => theme.colors.grey[10]};

  ${({ noTopMargin, theme }) =>
    noTopMargin ? '' : `margin-top: ${theme.spacing.large}`};
  ${({ noDisplay }) => (noDisplay ? 'display: none' : '')};
`;

const ParamRow = styled.div`
  display: flex;
  margin: ${({ theme }) => theme.spacing.small};
  background-color: #f1f3f3;
`;

const ParamOptionsContainer = styled.div`
  flex: 1;
  min-width: 0;
`;

const ParamOptionsRow = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  margin: ${({ theme }) => theme.spacing.small} 0;
  margin-left: ${({ theme }) => theme.spacing.small};
`;

const ParamInputLabel = styled.div`
  display: flex;
  align-items: center;
  margin-left: 12px;
  margin-right: 24px;
`;

const ParamInput = styled(Input)`
  flex: 1;
  background-color: ${({ theme }) => theme.colors.ui.blockBackground};
`;

const ParamInputSelect = styled(SelectInput)`
  background-color: ${({ theme }) => theme.colors.ui.blockBackground};
`;

const SelectInputType = styled(ParamInputSelect)`
  min-width: 96px;
  max-width: 96px;
`;

const ParamInputButton = styled(Button)`
  height: 100%;
  margin: 0 ${({ theme }) => theme.spacing.normal};
`;

const ValidationDiv = styled.div`
  position: relative;
  width: 20px;
`;

interface OverridesListProps {
  onNumSimsChange?: (numSims: number) => void;
  sweepTypes: ParamTypes[];
  ensembleType: EnsembleTab;
  defaultSetting: OverridenParamSetting;
  overrides: overridenParamsMap;
  doPythonEval?: boolean;
  noTopMargin?: boolean;
}

const OverridesList: React.FC<OverridesListProps> = ({
  onNumSimsChange,
  sweepTypes,
  ensembleType,
  defaultSetting,
  doPythonEval,
  noTopMargin,
  overrides,
}) => {
  const parameters = useAppSelector((state) => state.model.present.parameters);

  const overridesKeys = Object.keys(overrides ?? []);

  const dispatch = useAppDispatch();
  const { isReady: pythonIsReady } = usePython();

  const [exprErrors, setExprErrors] = React.useState<Record<string, string>>(
    {},
  );
  const [exprLengths, setExprLengths] = useState<Record<string, number>>({});
  const [isExprUpdating, setIsExprUpdating] = useState<Record<string, boolean>>(
    {},
  );

  const modelUuid = useAppSelector(
    (state) => state.modelMetadata.loadedModelId,
  );

  const unselectedParameters = parameters.filter(
    (parameter) => !overrides?.[parameter.name],
  );

  const evalExpr = React.useCallback(
    (
      overridenParamId: string,
      override: { type: string; params: string[] },
    ) => {
      const paramSweepOption = ParamOptions[override.type as ParamTypes];
      if (!paramSweepOption.toSweepValues) return;
      let exprLength = 0;
      try {
        exprLength =
          paramSweepOption.evalNumberOfValues?.apply(null, override.params) ??
          0;
      } catch (e) {
        setExprErrors((prev) => ({
          ...prev,
          [overridenParamId]: `${e}`,
        }));
        setIsExprUpdating((prev) => ({ ...prev, [overridenParamId]: false }));
        return;
      }

      if (exprLength !== undefined) {
        setExprLengths((prev) => ({
          ...prev,
          [overridenParamId]: exprLength,
        }));
      }
      setIsExprUpdating((prev) => ({ ...prev, [overridenParamId]: false }));
    },
    [],
  );

  // Re-evaluate the python expressions when the overrides change
  React.useEffect(() => {
    if (!doPythonEval) return;
    setExprLengths({});
    setExprErrors({});
    Object.entries(overrides).forEach(([overridenParamId, override]) => {
      setIsExprUpdating((prev) => ({ ...prev, [overridenParamId]: true }));
    });
    Object.entries(overrides).forEach(([overridenParamId, override]) =>
      evalExpr(overridenParamId, override),
    );
  }, [doPythonEval, evalExpr, overrides]);

  // Recaculate the number of simulations when the expression lengths change
  React.useEffect(() => {
    const isUpdating = Object.values(isExprUpdating).some((value) => value);
    if (isUpdating) return;

    const hasErrors = Object.values(exprErrors).some((value) => value);
    const hasExprs = Object.values(exprLengths).length > 0;
    if (hasErrors || !hasExprs) {
      onNumSimsChange?.(-1);
      return;
    }

    const allZero = Object.values(exprLengths).every((value) => value === 0);
    if (allZero) {
      onNumSimsChange?.(0);
      return;
    }

    const numSims = Object.values(exprLengths).reduce(
      (acc, curr) => acc * curr,
      1,
    );

    onNumSimsChange?.(numSims);
  }, [exprErrors, exprLengths, isExprUpdating, onNumSimsChange]);

  const onChangeParamValue =
    (overridenParamId: string, index: number) => (value: string) => {
      dispatch(
        entityPreferencesActions.onUserUpdatedEnsembleSimModalPrefs({
          modelId: modelUuid,
          tab: ensembleType,
          prefs: {
            overridenParams: {
              ...overrides,
              [overridenParamId]: {
                ...overrides[overridenParamId],
                params: overrides[overridenParamId].params.map((p, i) =>
                  i === index ? value : p,
                ),
              },
            },
          },
        }),
      );
    };

  const onChangeType = (overridenParamId: string) => (newType: string) => {
    dispatch(
      entityPreferencesActions.onUserUpdatedEnsembleSimModalPrefs({
        modelId: modelUuid,
        tab: ensembleType,
        prefs: {
          overridenParams: {
            ...overrides,
            [overridenParamId]: {
              ...overrides[overridenParamId],
              type: newType as ParamTypes,
              params: ParamOptions[newType as ParamTypes].args.map(
                (arg) => arg.default ?? '0',
              ),
            },
          },
        },
      }),
    );
  };

  const onChangeParamName = (
    newParamId: string,
    prevParamId: string | undefined,
  ) => {
    let existingOverrides = overrides;
    if (prevParamId) {
      const { [prevParamId]: _, ...remaingParams } = overrides;
      existingOverrides = remaingParams;
    } else {
    }
    dispatch(
      entityPreferencesActions.onUserUpdatedEnsembleSimModalPrefs({
        modelId: modelUuid,
        tab: ensembleType,
        prefs: {
          overridenParams: {
            ...existingOverrides,
            [newParamId]: defaultSetting,
          },
        },
      }),
    );
  };

  const onDeleteParam = (overridenParamId: string) => () => {
    const { [overridenParamId]: _, ...newOverrides } = overrides;
    dispatch(
      entityPreferencesActions.onUserUpdatedEnsembleSimModalPrefs({
        modelId: modelUuid,
        tab: ensembleType,
        prefs: {
          overridenParams: newOverrides,
        },
      }),
    );
  };

  const onResetParam = (overridenParamId: string) => () => {
    dispatch(
      entityPreferencesActions.onUserUpdatedEnsembleSimModalPrefs({
        modelId: modelUuid,
        tab: ensembleType,
        prefs: {
          overridenParams: {
            ...overrides,
            [overridenParamId]: {
              type: overrides[overridenParamId].type,
              params: ParamOptions[overrides[overridenParamId].type].args.map(
                (arg) => arg.default ?? '0',
              ),
            },
          },
        },
      }),
    );
  };

  return (
    <ParametersBox
      noTopMargin={noTopMargin}
      noDisplay={overridesKeys.length === 0}>
      {!overrides ||
        overridesKeys.map((overridenParamId, index) => (
          <ParamRow key={index}>
            <ParamOptionsContainer>
              <ParamOptionsRow>
                <ParamInputSelect
                  options={parameters.map((k) => ({
                    value: k.name,
                    label: k.name,
                  }))}
                  currentValue={overridenParamId}
                  onSelectValue={onChangeParamName}
                  isOptionDisabled={(param) =>
                    Boolean(overrides?.[param.value])
                  }
                />
                <ParamInputButton
                  Icon={Reset}
                  variant={ButtonVariants.SmallTertiary}
                  onClick={onResetParam(overridenParamId)}
                />
              </ParamOptionsRow>
              <ParamOptionsRow>
                <SelectInputType
                  options={sweepTypes.map((k) => ({
                    value: k,
                    label: k,
                  }))}
                  currentValue={overrides[overridenParamId]?.type}
                  onSelectValue={onChangeType(overridenParamId)}
                />
                {overrides[overridenParamId]?.params.map(
                  (_, paramIndex: number) => (
                    <Fragment key={paramIndex}>
                      <ParamInputLabel>
                        {
                          ParamOptions[overrides[overridenParamId].type].args[
                            paramIndex
                          ].label
                        }
                      </ParamInputLabel>
                      <ParamInput
                        value={overrides[overridenParamId].params[paramIndex]}
                        hasBorder
                        isMonospaced
                        disabled={!pythonIsReady && doPythonEval}
                        onSubmitValue={onChangeParamValue(
                          overridenParamId,
                          paramIndex,
                        )}
                      />
                    </Fragment>
                  ),
                )}
                <ValidationDiv>
                  {doPythonEval &&
                    pythonIsReady &&
                    exprErrors[overridenParamId] && (
                      <ValidationError
                        validationError="Not a valid expression"
                        isInFocus={false}
                        position="relative"
                      />
                    )}
                </ValidationDiv>
                <ParamInputButton
                  Icon={Close}
                  variant={ButtonVariants.SmallTertiary}
                  onClick={onDeleteParam(overridenParamId)}
                />
              </ParamOptionsRow>
            </ParamOptionsContainer>
          </ParamRow>
        ))}
    </ParametersBox>
  );
};

export default OverridesList;
