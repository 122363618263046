import { t } from '@lingui/macro';
import {
  useDeleteAdminGlobalProjectByUuidMutation,
  useDeleteAdminSimulationJobsByUuidMutation,
  useDeleteUserOptionOverridesByUuidMutation,
  useDeleteUserOptionsByUuidMutation,
  useGetAdminGlobalProjectsQuery,
  useGetAdminPlatformInfoQuery,
  useGetAdminSimulationJobsListQuery,
  useGetAdminUsersReadAllQuery,
  useGetUserOptionOverridesReadAllQuery,
  useGetUserOptionsReadAllQuery,
  usePatchAdminUserByUuidMutation,
  usePostAdminGlobalProjectCreateMutation,
  usePostUserOptionOverridesCreateMutation,
  usePostUserOptionsCreateMutation,
  usePutUserOptionOverridesUpdateByUuidMutation,
  usePutUserOptionsUpdateByUuidMutation,
} from 'app/apiGenerated/generatedApi';
import {
  PatchAdminUserByUuidApiArg,
  ProjectKind,
} from 'app/apiGenerated/generatedApiTypes';
import { isAdminUser } from 'ui/auth/utils';
import { useNotifications } from 'ui/common/notifications/useNotifications';

export function useAdminApis() {
  const { createShowError, showInfo, showError } = useNotifications();

  // Don't use developerModeEnabled here otherwise you wouldn't be able to
  // unset the user option back :)
  const skip = !isAdminUser();

  const {
    data: users,
    isFetching: isFetchingUsers,
    refetch,
  } = useGetAdminUsersReadAllQuery();

  const [callModifyAdminUserApi] = usePatchAdminUserByUuidMutation();
  const modifyAdminUser = (request: PatchAdminUserByUuidApiArg) =>
    callModifyAdminUserApi(request)
      .unwrap()
      .catch(
        createShowError(
          t({
            id: 'adminApi.modifyUser',
            message: 'Unable to modify user data.',
          }),
        ),
      );

  // User options APIs
  const { data: userOptions, refetch: refetchUserOptions } =
    useGetUserOptionsReadAllQuery(undefined, { skip });
  const [callUpdateUserOptionApi] = usePutUserOptionsUpdateByUuidMutation();
  const [callDeleteUserOptionApi] = useDeleteUserOptionsByUuidMutation();
  const [callCreateUserOptionApi] = usePostUserOptionsCreateMutation();

  // User options overrides APIs
  const { data: userOptionsOverrides, refetch: refetchUserOptionsOverrides } =
    useGetUserOptionOverridesReadAllQuery(undefined, { skip });
  const [callCreateUserOptionOverrideApi] =
    usePostUserOptionOverridesCreateMutation();
  const [callDeleteUserOptionOverrideApi] =
    useDeleteUserOptionOverridesByUuidMutation();
  const [callUpdateUserOptionOverridesApi] =
    usePutUserOptionOverridesUpdateByUuidMutation();

  // Simulations queue APIs
  const { data: platformInfo, refetch: refetchPlatformInfo } =
    useGetAdminPlatformInfoQuery(undefined, { skip });
  const { data: simulationJobs, refetch: refetchSimulationJobs } =
    useGetAdminSimulationJobsListQuery(undefined, { skip });
  const [callCreateGlobalProjectApi] =
    usePostAdminGlobalProjectCreateMutation();
  const [callDeletSimulationJobApi] =
    useDeleteAdminSimulationJobsByUuidMutation();

  // Global Projects APIs
  const {
    data: templateGlobalProjects,
    refetch: refetchTemplateGlobalProjects,
  } = useGetAdminGlobalProjectsQuery({ kind: 'Template' }, { skip });

  const { data: defaultGlobalProjects, refetch: refetchDefaultGlobalProjects } =
    useGetAdminGlobalProjectsQuery({ kind: 'Default' }, { skip });

  const [callDeleteGlobalProjectApi] =
    useDeleteAdminGlobalProjectByUuidMutation();

  // User options actions
  const updateUserOption = async (
    uuid: string,
    name: string,
    value: string,
    description: string,
  ) => {
    try {
      await callUpdateUserOptionApi({
        userOptionUuid: uuid,
        userOptionUpdateRequest: { value, name, description },
      });
      refetchUserOptions();
    } catch (e) {
      showError('There was an error updating the user option.', e);
    }
  };

  const deleteUserOption = async (uuid: string) => {
    try {
      await callDeleteUserOptionApi({ userOptionUuid: uuid }).unwrap();
      showInfo('User option deleted successfully');
      refetchUserOptions();
    } catch (e) {
      showError('There was an error deleting the user option.', e);
    }
  };

  const createUserOption = async (
    name: string,
    value: string,
    description: string,
  ) => {
    try {
      await callCreateUserOptionApi({
        userOptionCreateRequest: { name, value, description },
      }).unwrap();
      showInfo('User option created successfully');
      refetchUserOptions();
    } catch (e) {
      showError('There was an error creating the user option.', e);
    }
  };

  // User options overrides actions

  const deleteUserOptionOverride = async (uuid: string) => {
    try {
      await callDeleteUserOptionOverrideApi({
        userOptionOverrideUuid: uuid,
      }).unwrap();
      refetchUserOptionsOverrides();
      showInfo('Override deleted successfully');
    } catch (e) {
      showError('There was an error deleting the override.', e);
    }
  };

  const createUserOptionOverride = async (
    userUuid: string,
    optionUuid: string,
    value: string,
  ) => {
    try {
      await callCreateUserOptionOverrideApi({
        userOptionOverrideCreateRequest: {
          user_uuid: userUuid,
          user_option_uuid: optionUuid,
          value,
        },
      }).unwrap();
      showInfo('Override created successfully');
      refetchUserOptionsOverrides();
    } catch (e) {
      showError('There was an error creating the override.', e);
    }
  };

  const updateUserOptionOverride = async (uuid: string, value: string) => {
    try {
      await callUpdateUserOptionOverridesApi({
        userOptionOverrideUuid: uuid,
        userOptionOverrideUpdateRequest: {
          value,
        },
      });
      refetchUserOptionsOverrides();
    } catch (e) {
      showError('There was an error updating the user option, ', e);
    }
  };

  // Simulations queue actions
  const deleteSimulationJob = async (uuid: string) => {
    try {
      await callDeletSimulationJobApi({ simulationJobUuid: uuid }).unwrap();
      showInfo('Simulation job deleted successfully');
      refetchSimulationJobs();
      refetchPlatformInfo();
    } catch (e) {
      showError('There was an error deleting the simulation job.', e);
    }
  };

  // Global Projects actions
  const createGlobalProject = async (
    projectUuid: string,
    kind: ProjectKind,
  ) => {
    try {
      await callCreateGlobalProjectApi({
        globalProjectCreateRequest: {
          project_uuid: projectUuid,
          kind,
        },
      }).unwrap();
      showInfo('Global project was created successfully.');
      refetchDefaultGlobalProjects();
      refetchTemplateGlobalProjects();
    } catch (e) {
      showError('There was an error creating the global project.', e);
    }
  };

  const deleteGlobalProject = async (uuid: string, kind: ProjectKind) => {
    try {
      await callDeleteGlobalProjectApi({
        projectUuid: uuid,
        projectKind: kind,
      }).unwrap();
      showInfo('Global project deleted successfully');
      refetchDefaultGlobalProjects();
      refetchTemplateGlobalProjects();
    } catch (e) {
      showError('There was an error deleting the global project.', e);
    }
  };

  return {
    users,
    isFetchingUsers,
    refetch,
    modifyAdminUser,
    userOptions,
    createUserOption,
    updateUserOption,
    deleteUserOption,
    userOptionsOverrides,
    createUserOptionOverride,
    updateUserOptionOverride,
    deleteUserOptionOverride,
    simulationJobs,
    deleteSimulationJob,
    refetchSimulationJobs,
    refetchPlatformInfo,
    platformInfo,
    templateGlobalProjects,
    refetchTemplateGlobalProjects,
    defaultGlobalProjects,
    refetchDefaultGlobalProjects,
    createGlobalProject,
    deleteGlobalProject,
  };
}
