import styled from '@emotion/styled';
import { useProjects } from 'app/api/useProjects';
import { Project } from 'app/apiTransformers/convertAPIProjectToProject';
import React from 'react';
import { ProjectBadges } from 'ui/dashboard/projectsList/ProjectBadges';
import { TitleBlock } from 'ui/dashboard/projectsList/ProjectsList';
import NavSection from 'ui/dashboard/sidebar/NavItems/NavSection';
import NavSubSection from 'ui/dashboard/sidebar/NavItems/NavSubSection';
import {
  ProjectFilter,
  getProjectFilter,
  getProjectFilterLabel,
  getProjectFilterPath,
} from 'util/projectFilterUtils';

const ProjectNameWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  padding-right: ${({ theme }) => theme.spacing.small};
`;

type Props = {
  icon: React.FC<any>;
  projectFilter: ReturnType<typeof getProjectFilter>;
  currentFilter?: ProjectFilter;
  // TODO flip these booleans. They are default true and public section is the special case.
  showFavorites: boolean;
  showPublicBadge?: boolean;
};

export const SidebarSection: React.FC<Props> = ({
  icon,
  projectFilter,
  currentFilter,
  showFavorites,
  showPublicBadge,
}) => {
  const showFavoritesOnly = true;

  const { projects: sidebarProjects }: { projects: Project[] | undefined } =
    useProjects(projectFilter, showFavoritesOnly);

  return (
    <>
      <NavSection
        icon={icon}
        to={getProjectFilterPath(projectFilter)}
        testId={`dashboard-sidebar-projects-${projectFilter}-section`}
        isNestedProjectOpen={currentFilter === projectFilter}>
        {getProjectFilterLabel(projectFilter)}
      </NavSection>
      {showFavorites &&
        sidebarProjects &&
        sidebarProjects.map((p) => (
          <NavSubSection key={p.uuid} to={`/projects/${p.uuid}`}>
            <ProjectNameWrapper>
              <TitleBlock>{p.title}</TitleBlock>
              <ProjectBadges project={p} showPublicBadge={!!showPublicBadge} />
            </ProjectNameWrapper>
          </NavSubSection>
        ))}
    </>
  );
};
