import { t } from '@lingui/macro';
import { usePostProjectPermissionClaimMutation } from 'app/apiGenerated/generatedApi';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useNotifications } from 'ui/common/notifications/useNotifications';

const invalidTokenError = 'invalid invite token';
const invalidInvitationError = 'input validation failed';
const invalidPermissionError = 'permission does not exist';
const alreadyClaimedError = 'project invite has already been claimed';
const alreadyHasAccessError = 'user already has access to the given project';

function alreadyHasPermissionError(error: any): boolean {
  if (error.data?.code === 400 && typeof error.data?.message === 'string') {
    const message = error.data.message as string;
    return (
      message.startsWith(alreadyClaimedError) ||
      message.startsWith(alreadyHasAccessError)
    );
  }
  return false;
}

function isInvalidInvitationError(error: any): boolean {
  if (error.data?.code === 404 && typeof error.data?.message === 'string') {
    const message = error.data.message as string;
    return message.startsWith(invalidTokenError);
  }
  if (error.data?.code === 400 && typeof error.data?.message === 'string') {
    const message = error.data.message as string;
    return (
      message.startsWith(invalidPermissionError) ||
      message.startsWith(invalidInvitationError)
    );
  }
  return false;
}

export function useProjectInvitation() {
  const navigate = useNavigate();

  const { showCompletion, showError } = useNotifications();

  const [callAcceptProjectInviteApi] = usePostProjectPermissionClaimMutation();

  const acceptProjectInvite = React.useCallback(
    (projectUuid: string, permissionUuid: string, invite_token: string) => {
      callAcceptProjectInviteApi({
        projectUuid,
        permissionUuid,
        projectPermissionClaimRequest: {
          invite_token,
        },
      })
        .unwrap()
        .then(() => {
          showCompletion(
            t({
              id: 'projectApi.acceptProjectInviteSuccess',
              message: 'Accepted project invitation',
            }),
          );
          navigate(`/projects/${projectUuid}`);
        })
        .catch((e) => {
          if (alreadyHasPermissionError(e)) {
            navigate(`/projects/${projectUuid}`);
            return;
          }
          if (isInvalidInvitationError(e)) {
            navigate(`/projects`);
            showError(
              t({
                id: 'projectApi.projectInvitationWasInvalid',
                message: 'Project invitation was invalid',
              }),
            );
            return;
          }
          navigate(`/projects`);
          showError(
            t({
              id: 'projectApi.acceptProjectInviteError',
              message: 'Unable to accept project invitation',
            }),
          );
        });
    },
    [navigate, callAcceptProjectInviteApi, showError, showCompletion],
  );

  return {
    acceptProjectInvite,
  };
}
