/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const AddGroup: React.FC<Props> = (props) => (
  <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M4.25 6C4.25 5.0335 5.0335 4.25 6 4.25H17C17.9665 4.25 18.75 5.0335 18.75 6V13C18.75 13.4142 18.4142 13.75 18 13.75C17.5858 13.75 17.25 13.4142 17.25 13V6C17.25 5.86193 17.1381 5.75 17 5.75H6C5.86193 5.75 5.75 5.86193 5.75 6V17C5.75 17.1381 5.86193 17.25 6 17.25H13C13.4142 17.25 13.75 17.5858 13.75 18C13.75 18.4142 13.4142 18.75 13 18.75H6C5.0335 18.75 4.25 17.9665 4.25 17V6ZM18 15.25C18.4142 15.25 18.75 15.5858 18.75 16V17.25H20C20.4142 17.25 20.75 17.5858 20.75 18C20.75 18.4142 20.4142 18.75 20 18.75H18.75V20C18.75 20.4142 18.4142 20.75 18 20.75C17.5858 20.75 17.25 20.4142 17.25 20V18.75H16C15.5858 18.75 15.25 18.4142 15.25 18C15.25 17.5858 15.5858 17.25 16 17.25H17.25V16C17.25 15.5858 17.5858 15.25 18 15.25Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M12 8.5C12 7.94772 12.4477 7.5 13 7.5H15C15.5523 7.5 16 7.94772 16 8.5V10C16 10.5523 15.5523 11 15 11H13C12.4477 11 12 10.5523 12 10V8.5ZM15 8.5H13V10H15V8.5Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M12 13C12 12.4477 12.4477 12 13 12H15C15.5523 12 16 12.4477 16 13V14.5C16 15.0523 15.5523 15.5 15 15.5H13C12.4477 15.5 12 15.0523 12 14.5V13ZM15 13H13V14.5H15V13Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M7 9C7 8.72386 7.22386 8.5 7.5 8.5H11C11.2761 8.5 11.5 8.72386 11.5 9C11.5 9.27614 11.2761 9.5 11 9.5H7.5C7.22386 9.5 7 9.27614 7 9ZM7 10.5C7 10.2239 7.22386 10 7.5 10H11C11.2761 10 11.5 10.2239 11.5 10.5C11.5 10.7761 11.2761 11 11 11H7.5C7.22386 11 7 10.7761 7 10.5ZM7 12C7 11.7239 7.22386 11.5 7.5 11.5H9.5C9.77614 11.5 10 11.7239 10 12C10 12.2761 9.77614 12.5 9.5 12.5H7.5C7.22386 12.5 7 12.2761 7 12Z"
      fill={props.fill}
    />
  </svg>
);

AddGroup.defaultProps = {
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default AddGroup;
