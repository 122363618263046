import styled from '@emotion/styled';
import React from 'react';
import Select, { OnChangeValue, Options } from 'react-select';

export const DetailsSelectInputPlaceholder = styled.div`
  height: 24px;
`;

const SelectInputWrapper = styled.div<{
  isInvalid: boolean;
  isDisabled?: boolean;
}>`
  flex: 1;
  height: 24px;

  ${({ isDisabled }) => (isDisabled ? 'opacity: 0.5;' : '')}

  > * {
    width: 100%;
    flex: 1;
  }

  .selectinput__control {
    height: 24px;
    min-height: 24px;
    width: 100%;
    background-color: transparent;
    border: none;
    border-radius: 2px;
    box-shadow: inset 0px 0px 0px 1px #e4e7e7;
  }

  .selectinput__single-value {
    display: flex;
    align-items: center;
  }

  .selectinput__control--is-focused {
    box-shadow: ${({ theme }) =>
      `inset 0px 0px 0px 1px #e4e7e7, 0px 0px 0px 1px ${theme.colors.ui.tint2}`};
  }

  .selectinput__indicators {
    height: 24px;
    width: 20px;
  }

  .selectinput__indicator-separator {
    display: none;
  }

  .selectinput__dropdown-indicators {
    justify-content: center;
    align-items: center;
  }

  .selectinput__dropdown-indicator {
    padding: 0;
    position: absolute;
  }

  .selectinput__value-container {
    height: 24px;
    line-height: 24px;
    padding: 0 0 0 ${({ theme }) => theme.spacing.normal};
  }

  .selectinput__single-value {
    margin: 0;
    height: 24px;
    line-height: 24px;
    ${({ isInvalid, theme }) =>
      isInvalid ? `color: ${theme.colors.ui.error};` : ''}
  }

  .selectinput__input-container {
    padding: 0;
    margin: 0;
    line-height: 24px;
  }
`;

export type SelectInputOption = {
  value: string;
  label: string;
};

type Props = {
  placeholder?: string;
  options: SelectInputOption[];
  onSelectValue?: (newValue: string, prevValue?: string) => void;
  isDisabled?: boolean;
  isOptionDisabled?: (
    option: SelectInputOption,
    selectValue: Options<SelectInputOption>,
  ) => boolean;
  currentValue?: string;
  autoFocus?: boolean;
  testId?: string;
  isInvalid?: boolean;
  formatOptionLabel?: (o: SelectInputOption) => React.ReactNode;
  components?: any;
};

const SelectInput: React.FC<Props> = ({
  placeholder,
  options,
  onSelectValue,
  currentValue,
  isDisabled,
  isOptionDisabled,
  autoFocus,
  testId,
  isInvalid = false,
  formatOptionLabel,
  components,
  ...restProps
}: Props): React.ReactElement => {
  const [value, setValue] = React.useState<SelectInputOption | undefined>(
    () => options.find((option) => option.value === currentValue) || undefined,
  );

  // If there is a change coming from the server (or other part of the app),
  // update the currently displayed value.
  React.useEffect(() => {
    const updatedValue =
      options.find((option) => option.value === currentValue) || undefined;
    if (updatedValue !== value) {
      setValue(updatedValue);
    }
  }, [currentValue, value, options]);

  // If there is a change from the user,
  // update the currently displayed value.
  const onChange = React.useCallback(
    (newValue: OnChangeValue<SelectInputOption, false>) => {
      setValue((prevValue) => {
        if (onSelectValue && newValue) {
          onSelectValue(newValue.value, prevValue?.value);
        }
        return newValue || undefined;
      });
    },
    [onSelectValue, setValue],
  );

  // Track whether we need to force a rerender of the react component.
  // This is a workaround for what seems like a limitation in react-select.
  const [lastValue, setLastValue] = React.useState<string | undefined>(
    undefined,
  );
  React.useEffect(() => {
    if (lastValue !== currentValue) {
      setLastValue(currentValue);
    }
  }, [currentValue, lastValue]);

  // Force a rerender of the react-select component if the value
  // switches from selected to unselected in order to get the component to update.
  // Use a div with the same height as the select input to prevent flashing.
  if (lastValue && !currentValue) {
    return <DetailsSelectInputPlaceholder />;
  }

  return (
    <SelectInputWrapper
      data-test-id={testId}
      isInvalid={isInvalid}
      isDisabled={isDisabled}
      {...restProps}>
      <Select
        placeholder={placeholder}
        menuPortalTarget={document.body}
        isDisabled={isDisabled}
        isOptionDisabled={isOptionDisabled}
        value={value}
        options={options}
        formatOptionLabel={formatOptionLabel}
        onChange={onChange}
        classNamePrefix="selectinput"
        autoFocus={autoFocus}
        hideSelectedOptions={isInvalid}
        components={components}
      />
    </SelectInputWrapper>
  );
};

export default SelectInput;
