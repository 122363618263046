import React from 'react';
import { TooltipPlacement } from 'ui/common/Tooltip/tooltipTypes';
import { ValidationRule } from '../Input/inputValidation';

export enum ItemType {
  Button = 'Button',
  Input = 'Input',
  Select = 'Select',
  Separator = 'Separator',
  Submenu = 'Submenu',
  Title = 'Title',
}

export enum ShortcutModifier {
  SHIFT = 'shiftKey',
  META = 'metaKey',
  ALT = 'altKey',
  CTRL = 'ctrlKey',
}

interface ItemBaseConfig {
  type: ItemType;
}

export interface MenuItemShortcut {
  keyStrings: string[];
  code: string;
  modifier?: ShortcutModifier | ShortcutModifier[];
}

export interface ButtonConfig extends ItemBaseConfig {
  disabled?: boolean;
  onClick?: (e?: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  shortcut?: MenuItemShortcut;
  content: React.ReactNode | string;
  type: ItemType.Button;
}

export interface InputConfig extends ItemBaseConfig {
  type: ItemType.Input;
  id: string;
  text?: string;
  hasBorder?: boolean;
  pattern?: string;
  value?: string | number;
  validationRules?: ValidationRule[];
}

export interface SelectOptionConfig {
  id: string;
  content: React.ReactNode;
}

export interface SelectConfig extends ItemBaseConfig {
  type: ItemType.Select;
  options: SelectOptionConfig[];
  id: string;
  initialSelected?: string;
}

export interface SeparatorConfig extends ItemBaseConfig {
  type: ItemType.Separator;
  id?: string;
}

export interface SubmenuConfig extends ItemBaseConfig {
  type: ItemType.Submenu;
  text: string;
  items: MenuItemConfig[];
  onSubmitValue?: (changed: ChangedItem) => void;
}

export interface TitleConfig extends ItemBaseConfig {
  type: ItemType.Title;
  /** Text you want to show on the title */
  text: string;
}

export interface ChangedItem {
  [id: string]: (string | number)[];
}
export interface MenuConfig {
  /** List of items to appear inside the menu */
  items: MenuItemConfig[];
  /** Callback that will collect all the changed items on the menu */
  onSubmitValue?: (changed: ChangedItem) => void;
  /** Callback that will trigger when closing the menu */
  onClose?: () => void;
  className?: string;
  children?: React.ReactNode;
  /** Defines where do you want to orient the opened menu */
  placement?: TooltipPlacement;
  /** Whether to initially render the menu opened */
  startOpen?: boolean;
  /** Internal prop. Not meant to be used outside */
  onActive?: (isActive: boolean) => void;
  disabled?: boolean;
  noTooltipMargin?: boolean;
}

export type MenuItemConfig =
  | ButtonConfig
  | InputConfig
  | SeparatorConfig
  | TitleConfig
  | SelectConfig
  | SubmenuConfig;
