/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Labels: React.FC<Props> = (props) => (
  <svg viewBox="0 0 24 24" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M5.91421 8.25C5.91421 9.2165 6.69771 10 7.66421 10C8.63071 10 9.41421 9.2165 9.41421 8.25C9.41421 7.2835 8.63071 6.5 7.66421 6.5C6.69771 6.5 5.91421 7.2835 5.91421 8.25ZM6.91421 8.25C6.91421 8.66421 7.25 9 7.66421 9C8.07843 9 8.41421 8.66421 8.41421 8.25C8.41421 7.83579 8.07843 7.5 7.66421 7.5C7.25 7.5 6.91421 7.83579 6.91421 8.25Z"
      fill={props.fill}
    />
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M10.3333 4.25C10.7975 4.25 11.2426 4.43437 11.5708 4.76256L19.2374 12.4292C19.9209 13.1126 19.9209 14.2207 19.2374 14.9041L14.3183 19.8232C13.6349 20.5066 12.5269 20.5066 11.8434 19.8232L4.17678 12.1566C3.84859 11.8284 3.66421 11.3832 3.66421 10.9191V6C3.66421 5.0335 4.44771 4.25 5.41421 4.25H10.3333ZM10.5101 5.82322C10.4632 5.77634 10.3996 5.75 10.3333 5.75H5.41421C5.27614 5.75 5.16421 5.86193 5.16421 6V10.9191C5.16421 10.9854 5.19055 11.049 5.23744 11.0959L12.9041 18.7626C13.0017 18.8602 13.16 18.8602 13.2577 18.7626L18.1768 13.8434C18.2744 13.7458 18.2744 13.5875 18.1768 13.4899L10.5101 5.82322Z"
      fill={props.fill}
    />
  </svg>
);

Labels.defaultProps = {
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
  fill: '#020606',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Labels;
