/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  width?: any;
  height?: any;
  viewBox?: any;
  fill?: any;
  xmlns?: any;
  fillRule?: any;
  clipRule?: any;
  className?: string;
}

const Relay: React.FC<Props> = (props) => (
  <svg viewBox="0 0 32 32" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule={props.fillRule}
      clipRule={props.clipRule}
      d="M5.68934 20.75L16.4697 9.96967C16.6103 9.82902 16.8011 9.75 17 9.75H30C30.4142 9.75 30.75 10.0858 30.75 10.5C30.75 10.9142 30.4142 11.25 30 11.25H26.3107L15.5303 22.0303C15.3897 22.171 15.1989 22.25 15 22.25H2C1.58579 22.25 1.25 21.9142 1.25 21.5C1.25 21.0858 1.58579 20.75 2 20.75H5.68934ZM24.1893 11.25H17.3107L7.81066 20.75H14.6893L24.1893 11.25Z"
      fill={props.fill}
    />
  </svg>
);

Relay.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 32 32',
  fill: '#1D3131',
  xmlns: 'http://www.w3.org/2000/svg',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  className: '',
};

export default Relay;
