import styled from '@emotion/styled';
import React from 'react';
import Select, { OnChangeValue, Options } from 'react-select';
import { SelectInputOption } from './SelectInput';

const SelectInputWrapper = styled.div<{
  isInvalid: boolean;
  isDisabled?: boolean;
}>`
  flex: 1;
  height: 24px;

  ${({ isDisabled }) => (isDisabled ? 'opacity: 0.5;' : '')}

  > * {
    width: 100%;
    flex: 1;
  }

  .selectinput__control {
    height: 24px;
    min-height: 24px;
    width: 100%;
    background-color: transparent;
    border: none;
    border-radius: 2px;
    box-shadow: inset 0px 0px 0px 1px #e4e7e7;
  }

  .selectinput__single-value {
    display: flex;
    align-items: center;
  }

  .selectinput__control--is-focused {
    box-shadow: ${({ theme }) =>
      `inset 0px 0px 0px 1px #e4e7e7, 0px 0px 0px 1px ${theme.colors.ui.tint2}`};
  }

  .selectinput__indicators {
    height: 24px;
    width: 20px;
  }

  .selectinput__indicator-separator {
    display: none;
  }

  .selectinput__dropdown-indicators {
    justify-content: center;
    align-items: center;
  }

  .selectinput__dropdown-indicator {
    padding: 0;
    position: absolute;
  }

  .selectinput__value-container {
    height: 24px;
    line-height: 24px;
    padding: 0 0 0 ${({ theme }) => theme.spacing.normal};
  }

  .selectinput__single-value {
    margin: 0;
    height: 24px;
    line-height: 24px;
    ${({ isInvalid, theme }) =>
      isInvalid ? `color: ${theme.colors.ui.error};` : ''}
  }

  .selectinput__input-container {
    padding: 0;
    margin: 0;
    line-height: 24px;
  }
`;

type Props = {
  placeholder?: string;
  options: SelectInputOption[];
  onSelectValue: (newValue: string, prevValue?: string) => void;
  isDisabled?: boolean;
  isOptionDisabled?: (
    option: SelectInputOption,
    selectValue: Options<SelectInputOption>,
  ) => boolean;
  currentValue: string;
  autoFocus?: boolean;
  testId?: string;
  isInvalid?: boolean;
  formatOptionLabel?: (o: SelectInputOption) => React.ReactNode;
  components?: any;
};

// This is a quick hacky implementation of a select drop-down with editable input text field
const EditableSelectInput: React.FC<Props> = ({
  placeholder,
  options,
  onSelectValue,
  currentValue,
  isDisabled,
  isOptionDisabled,
  autoFocus,
  testId,
  isInvalid = false,
  formatOptionLabel,
  components,
  ...restProps
}: Props): React.ReactElement => {
  const ref = React.useRef<HTMLInputElement | null>(null);

  // Note: ugly to use a ref here, but this solves the problem that
  // onInputChange gets called with empty string, and overrides the value
  // set with onSelectValue.
  const hasFocus = React.useRef<boolean>(false);

  const [value, setValue] = React.useState<string>(currentValue);
  const currentOption = React.useMemo(() => ({ value, label: value }), [value]);
  const allOptions = React.useMemo(
    () =>
      options.find((option) => option.value === currentOption.value) ||
      !currentOption.value
        ? options
        : [currentOption, ...options],
    [options, currentOption],
  );

  const onSelectionChange = React.useCallback(
    (newValue: OnChangeValue<SelectInputOption, false>) => {
      if (newValue) {
        setValue(newValue.value);
        onSelectValue(newValue.value, currentValue);
      }
    },
    [onSelectValue, currentValue],
  );

  const onBlur = React.useCallback(() => {
    hasFocus.current = false;
  }, []);

  const onInputChange = React.useCallback(
    (newValue: string) => {
      if (hasFocus.current) setValue(newValue);
    },
    [setValue, hasFocus],
  );

  const onFocus = React.useCallback((e: React.FocusEvent<HTMLInputElement>) => {
    ref.current = e.target;
    hasFocus.current = true;
    // FIXME: I was not able to select the contents of the input field, like the
    // Input component does. No biggie but a bit annoying to use.
  }, []);

  const onKeyDown = React.useCallback(
    (e: React.KeyboardEvent) => {
      if (e.key === 'Enter') {
        ref.current?.blur();
        onSelectValue(value);
      }
    },
    [onSelectValue, value],
  );

  return (
    <SelectInputWrapper
      data-test-id={testId}
      isInvalid={isInvalid}
      isDisabled={isDisabled}
      {...restProps}>
      <Select
        placeholder={placeholder}
        menuPortalTarget={document.body}
        isDisabled={isDisabled}
        isOptionDisabled={isOptionDisabled}
        value={currentOption}
        options={allOptions}
        formatOptionLabel={formatOptionLabel}
        onChange={onSelectionChange}
        onInputChange={onInputChange}
        onBlur={onBlur}
        onFocus={onFocus}
        onKeyDown={onKeyDown}
        classNamePrefix="selectinput"
        autoFocus={autoFocus}
        hideSelectedOptions={isInvalid}
        components={components}
      />
    </SelectInputWrapper>
  );
};

export default EditableSelectInput;
