import { SubmodelInfoLiteUI } from 'app/apiTransformers/convertGetSubmodelsList';
import { BlockInstance } from 'app/generated_types/SimulationModel';
import { useAppSelector } from 'app/hooks';
import React from 'react';
import { useAppParams } from 'util/useAppParams';
import SelectInput, { SelectInputOption } from '../SelectInput';

type Props = {
  parentPath: string[];
  selectedBlock: BlockInstance;
  currentValue: string;
  onSelectValue: (newValue: string) => void;
};

/**
 * A drop-down parameter for selecting a submodel.
 *
 * This is NOT to configure a submodel's parameter but to select a submodel for
 * inclusion into a special block like KF (Kalman Filter) or MPC.
 */
const SubmodelSelectorParameter: React.FC<Props> = ({
  parentPath,
  selectedBlock,
  currentValue,
  onSelectValue,
}) => {
  const { projectId } = useAppParams();
  const submodels: SubmodelInfoLiteUI[] | undefined = useAppSelector(
    (state) => state.submodels.projectIdToSubmodelInfoLites[projectId || ''],
  );

  const options: SelectInputOption[] = React.useMemo(
    () =>
      submodels?.map((submodel) => ({
        value: submodel.id,
        label: submodel.name,
      })),
    [submodels],
  );

  const [isSelectedOptionValidOrEmpty, setIsSelectedOptionValidOrEmpty] =
    React.useState<boolean>(true);

  const onSelect = React.useCallback(
    (newVal: string) => {
      setIsSelectedOptionValidOrEmpty(!!newVal);
      onSelectValue(newVal);
    },
    [onSelectValue],
  );

  return (
    <SelectInput
      currentValue={currentValue}
      options={options}
      onSelectValue={onSelect}
      isInvalid={!isSelectedOptionValidOrEmpty}
    />
  );
};

export default SubmodelSelectorParameter;
