import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DownsamplingAlgorithm } from 'app/apiGenerated/generatedApiTypes';

interface VisualizerState {
  downsamplingAlgorithm: DownsamplingAlgorithm;
}

const initialState: VisualizerState = {
  downsamplingAlgorithm: 'LTTB',
};

const visualizerSlice = createSlice({
  name: 'visualizerSlice',
  initialState,
  reducers: {
    resetVisualizerState: () => initialState,

    setDownsamplingAlgorithm(
      state,
      action: PayloadAction<DownsamplingAlgorithm>,
    ) {
      state.downsamplingAlgorithm = action.payload;
    },
  },
});

export const visualizerActions = visualizerSlice.actions;

export default visualizerSlice;
